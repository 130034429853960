import { ServiceLevelService } from '../shared/services/service-level.service';
import { ServiceLevel } from '@enerkey/clients/facility';

export function hasServiceLevelFilter(serviceLevelService: ServiceLevelService) {
  return function(requiredServiceLevel?: ServiceLevel) {
    return !Number.isFinite(requiredServiceLevel) || serviceLevelService.hasAtLeastServiceLevel(requiredServiceLevel);
  };
}

hasServiceLevelFilter.$inject = ['serviceLevelService'];
