import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { LegendLabelsContentArgs } from '@progress/kendo-angular-charts';

import { SumConsumptionsWidgetService } from '../../services/sum-consumptions-widget.service';
import { SumConsumptionsTableRow } from '../../shared/sum-consumptions';
import { ServiceLevelService } from '../../../../shared/services/service-level.service';
import { ServiceLevel } from '@enerkey/clients/facility';

@Component({
  selector: 'sum-consumptions-pie-chart',
  templateUrl: './sum-consumptions-pie-chart.component.html',
  styleUrls: ['./sum-consumptions-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumConsumptionsPieChartComponent {
  @Input() public data: SumConsumptionsTableRow[];
  @Input() public isNormalized: boolean;

  public readonly hasServiceLevelMedium: boolean;

  public constructor(
    private readonly intl: IntlService,
    private readonly sumConsumptionsWidgetService: SumConsumptionsWidgetService,
    serviceLevelService: ServiceLevelService
  ) {
    this.hasServiceLevelMedium = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);
    this.labelContent = this.labelContent.bind(this);
  }

  public labelContent(args: LegendLabelsContentArgs): string {
    return `${this.intl.formatNumber(args.percentage, 'p1')}`;
  }

  public seriesClick(event: { dataItem: SumConsumptionsTableRow }): void {
    if (!this.hasServiceLevelMedium) {
      return;
    }

    this.sumConsumptionsWidgetService.chartClick({
      quantityId: event.dataItem.quantityId,
      facilityIds: event.dataItem.facilityIds,
      isNormalized: this.isNormalized
    });
  }

  public seriesOver(event: { originalEvent: { target: HTMLElement } }): void {
    if (!this.hasServiceLevelMedium) {
      return;
    }

    const target = event?.originalEvent?.target;
    target.style.cursor = 'pointer';
    target.parentElement.style.cursor = 'pointer';
  }
}
