export enum WidgetType {
  News = 'News',
  Comments = 'LatestComments',
  Actions = 'LatestActions',
  Consumptions = 'Consumptions',
  TopChangedConsumptions = 'TopConsumptions',
  SumConsumptions = 'SumConsumptions',
  Emissions = 'Emissions',
  GroupedConsumptions = 'TopGroupConsumptions',
  ErrorTicket = 'ErrorTicket',
  LatestAlarms = 'LatestAlarms',
  EmissionsFacilityWise = 'EmissionsOnFacility',
  FacilityConsumptions = 'FacilityConsumptions',
  FacilityEtCurve = 'FacilityEtCurve',
  FacilityLatestAlarms = 'FacilityLatestAlarms',
  FacilityComments = 'LatestFacilityComments',
  FacilityActions = 'FacilityActions',
  Power = 'Power'
}
