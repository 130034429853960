import { ComponentRef, Directive, ElementRef, HostListener, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { Subscription } from 'rxjs';

import { ValidationErrorsComponent } from '../components/validation-errors/validation-errors.component';

@Directive({
  selector: '[validationErrors]'
})
export class ValidationErrorsDirective implements OnInit, OnDestroy {

  private popupRef: PopupRef;
  private subscription: Subscription;
  private errors: ComponentRef<ValidationErrorsComponent>;

  /** HTML element hosting the directive. */
  private get host(): HTMLElement {
    return this.viewContainerRef.element.nativeElement;
  }

  public constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly elementRef: ElementRef<unknown>,
    private readonly control: NgControl,
    private readonly popupService: PopupService
  ) { }

  public ngOnInit(): void {
    this.errors = this.viewContainerRef.createComponent(ValidationErrorsComponent);
    this.errors.instance.control = this.control;
    this.subscription = this.control.statusChanges.subscribe(status => this.setVisibility(status === 'INVALID'));
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.setVisibility(false);
  }

  @HostListener('focusin')
  public focusIn(): void {
    this.setVisibility(this.control.status === 'INVALID');
  }

  @HostListener('focusout', ['$event'])
  public focusOut($event: FocusEvent): void {
    // relatedTarget is null when focus disappears, ie: current focused input gets disabled
    // in that case, check if host contains the previously focused element
    const focusLeft = $event.relatedTarget === null
      ? this.hostContains($event.target)
      : this.hostDoesNotContain($event.relatedTarget);

    if (focusLeft) {
      this.setVisibility(false);
    }
  }

  private hostContains(target: EventTarget): boolean {
    return target instanceof Node && this.host.contains(target);
  }

  private hostDoesNotContain(target: EventTarget): boolean {
    return target instanceof Node && !this.host.contains(target);
  }

  private setVisibility(visible: boolean): void {
    if (visible === !!this.popupRef) {
      return;
    }

    if (visible) {
      this.popupRef = this.popupService.open({
        anchor: this.elementRef,
        content: this.errors.instance.templateRef,
        animate: false,
      });
    } else {
      this.popupRef.close();
      this.popupRef = null;
    }
  }

}
