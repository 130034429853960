<modal-view-header
  [headingText]="(existingRoadmap ? 'SUSTAINABILITY.TARGETS.EDIT' : 'SUSTAINABILITY.TARGETS.CREATE') | translate"
></modal-view-header>

<div class="modal-body">
  <form [formGroup]="formGroup">
    <p>
      <kendo-formfield>
        <kendo-textbox
          *labelBefore="('SUSTAINABILITY.TARGETS.NAME' | translate); required: true"
          formControlName="name"
        ></kendo-textbox>
        <kendo-formerror>
          3-64 {{ 'CHARS' | translate }}
        </kendo-formerror>
      </kendo-formfield>
    </p>

    <div class="row">
      <div class="flex-item">
        <ek-combo
          *labelBefore="'SUSTAINABILITY.TARGETS.SCENARIO' | translate "
          [items]="scenarioOptions"
          formControlName="scenario"
        ></ek-combo>
      </div>

      <kendo-formfield class="flex-item year-input">
        <kendo-numerictextbox
          *labelBefore="('SUSTAINABILITY.TARGETS.BASE_YEAR' | translate); required: true"
          formControlName="baseYear"
          integerInput
        ></kendo-numerictextbox>
        <kendo-formerror>
          <ng-container *ngIf="formGroup.controls.baseYear.errors?.max">
            {{ 'SUSTAINABILITY.TARGETS.ERROR.YEAR_NOT_FUTURE' | translate }}
          </ng-container>
          <ng-container *ngIf="formGroup.controls.baseYear.errors?.required">
            {{ 'REQUIRED' | translate }}
          </ng-container>
        </kendo-formerror>
      </kendo-formfield>

      <kendo-formfield class="flex-item year-input">
        <kendo-numerictextbox
          *labelBefore="('SUSTAINABILITY.TARGETS.TARGET_YEAR' | translate); required: true"
          formControlName="targetYear"
          integerInput
        ></kendo-numerictextbox>
        <kendo-formerror>
          <ng-container *ngIf="formGroup.controls.targetYear.errors?.min">
            {{ targetYearErrorMsg.min | translate }}
          </ng-container>
          <ng-container *ngIf="formGroup.controls.targetYear.errors?.max">
            {{ targetYearErrorMsg.max | translate }}
          </ng-container>
          <ng-container *ngIf="formGroup.controls.targetYear.errors?.required">
            {{ 'REQUIRED' | translate }}
          </ng-container>
        </kendo-formerror>
      </kendo-formfield>
    </div>
    <hr>

    <!-- Base values -->
    <strong>{{ 'SUSTAINABILITY.TARGETS.BASE_VALUES' | translate }}</strong>
    <div class="row">
      <input
        kendoRadioButton
        type="radio"
        name="baseValueOption"
        value="useReport"
        [formControl]="baseValueOption"
        *labelWrap="'SUSTAINABILITY.TARGETS.VALUES_FROM_REPORT' | translate"
      >
      <input
        kendoRadioButton
        type="radio"
        name="baseValueOption"
        value="manual"
        [formControl]="baseValueOption"
        *labelWrap="'SUSTAINABILITY.TARGETS.VALUES_MANUALLY' | translate"
        class="margin-left"
      >
    </div>
    <div
      class="row"
      *ngIf="useReport$ | async"
    >
      <div class="flex-item">
        <target-report-select
          [reportId]="existingRoadmap?.baseReportId"
          (selectedReportChange)="patchBaseValues($event)"
        ></target-report-select>
      </div>
    </div>

    <div
      class="row"
      formGroupName="baseValues"
    >
      <div
        *ngFor="let scope of scopes"
        class="flex-item"
      >
        <kendo-numerictextbox
          *labelBefore="'SUSTAINABILITY.TARGETS.SCOPE_EMISSIONS' | translate : {
              scope: scope | griScopeName
            }"
          [formControlName]="scope"
          [readonly]="useReport$ | async"
          [ngClass]="{ 'readonly-input' : (useReport$ | async) }"
          integerInput
        ></kendo-numerictextbox>
      </div>
    </div>
    <hr>

    <!-- Target values -->
    <strong>{{ 'SUSTAINABILITY.TARGETS.TARGETS' | translate }}</strong>
    <div
      class="row"
      formGroupName="targetValues"
    >
      <div
        *ngFor="let scope of scopes"
        class="flex-item"
      >
        <kendo-numerictextbox
          *labelBefore="'SUSTAINABILITY.TARGETS.SCOPE_EMISSIONS' | translate : {
              scope: scope | griScopeName
            }"
          [formControlName]="scope"
          integerInput
        ></kendo-numerictextbox>
      </div>
    </div>

  </form>
</div>

<div class="modal-footer">
  <button
    class="button button--link button--negative"
    (click)="removeRoadmap()"
    [hidden]="!existingRoadmap"
  >
    {{ 'DELETE' | translate }}
  </button>
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--primary"
      type="button"
      (click)="submit()"
      [disabled]="!formGroup.valid"
      [loading]="loading$ | async"
    >
      {{ 'SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="cancelEdit()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
