import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'news-unread',
  templateUrl: './news-unread.component.html',
  styleUrls: ['./news-unread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsUnreadComponent {
}
