<ng-template
  #localeIdTemplate
  let-dataItem
>
  {{ localeNames[dataItem[propertyField].localeId] }}
</ng-template>

<ng-template
  #localeIdFilter
  let-filter
  let-filterService="filterService"
  let-column="column"
>
  <grid-multiselect-filter
    [field]="column.field"
    [filter]="filter"
    [filterService]="filterService"
    [dataSource]="gridData"
  >
    <span *gridMultiFilterTemplate="let value = value">
      {{ localeNames[value] }}
    </span>
  </grid-multiselect-filter>
</ng-template>

<ng-template
  #buildingClassIdTemplate
  let-dataItem
>
  {{ buildingClassNames[dataItem[propertyField].buildingClassId] }}
</ng-template>

<ng-template
  #buildingClassIdFilter
  let-filter
  let-filterService="filterService"
  let-column="column"
>
  <grid-multiselect-filter
    [field]="column.field"
    [filter]="filter"
    [filterService]="filterService"
    [dataSource]="gridData"
  >
    <span *gridMultiFilterTemplate="let value = value">
      {{ buildingClassNames[value] }}
    </span>
  </grid-multiselect-filter>
</ng-template>
