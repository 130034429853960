import { SearchCriteria } from '../../modules/manual-qa/shared/search-criteria';
import { SearchList } from '@enerkey/clients/manual-qa';

interface SearchListCriteria {
  _criteria: SearchCriteria;
}

/* eslint-disable no-invalid-this */

export function getSearchCriteria(
  this: SearchListCriteria & SearchList
): SearchCriteria {
  // Cache parsed value
  return this._criteria || (this._criteria = new SearchCriteria(this.searchCriteria));
}
