/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { assertUnreachable } from '@enerkey/ts-utils';

import { getMeterGridOptions } from './meter-grid-options';
import { getStatisticsGridOptions } from './statistics-grid-options';
import { getDefectGridOptions } from './defect-grid-options';
import { StorageSearchType } from '../../constants/search-types';
import { SearchItemType } from '../../constants/search-item-type';

const translations = [
  'MQA.RESULTS.GRID.METERING_POINT_NAME',
  'MQA.RESULTS.GRID.METER_ID',
  'MQA.RESULTS.GRID.DEFECT_TYPE',
  'MQA.RESULTS.GRID.ENEGIA_ID',
  'MQA.RESULTS.GRID.FACILITY_NAME',
  'MQA.RESULTS.GRID.QUANTITY',
  'MQA.RESULTS.GRID.READER_TYPE',
  'USAGE_PLACE_NUMBER',
  'SELLER_USAGE_PLACE_NUMBER',
  'EAN_CODE',
  'PROTOCOL_CODE',
  'AUTOMATIC_READING_START_TIME',
  'AUTOMATIC_READING_END_TIME',
  'METER_STORAGE_START_TIME',
  'DEACTIVATION_TIME',
  'MQA.RESULTS.GRID.AUTOMATIC_MODELING',
  'MQA.RESULTS.GRID.METER_IDENTIFIER',
  'MQA.RESULTS.GRID.READING_GROUP_IDENTIFIER',
  'MQA.DEFECT_TYPES.UNKNOWN_DEFECT_TYPE',
  'MQA.DEFECT_TYPES.READING_MISSING',
  'MQA.DEFECT_TYPES.PEAK',
  'MQA.DEFECT_TYPES.NEGATIVE_VALUE',
  'MQA.DEFECT_TYPES.EXCESSIVE_ZERO',
  'MQA.DEFECT_TYPES.TOO_LONG_INTERPOLATION',
  'MQA.DEFECT_TYPES.OPEN_END',
  'MQA.DEFECT_TYPES.OPEN_END_MISSING_VALUE',
  'MQA.RESULTS.GRID.TICKETS.TOTAL',
  'MQA.RESULTS.GRID.TICKETS.RESOLVED',
  'MQA.RESULTS.GRID.TICKETS.AUTO_RESOLVED',
  'MQA.RESULTS.GRID.TICKETS.MANUAL_RESOLVED',
  'MQA.RESULTS.GRID.TICKETS.OPEN',
  'MQA.RESULTS.GRID.TICKETS.RESOLVED_PERCENTUAL',
  'MQA.RESULTS.GRID.TICKETS.AUTO_RESOLVED_PERCENTUAL',
  'MQA.RESULTS.GRID.TICKETS.MANUAL_RESOLVED_PERCENTUAL',
  'MQA.RESULTS.GRID.DEFECTS.FROM',
  'MQA.RESULTS.GRID.DEFECTS.TO',
  'MQA.RESULTS.GRID.DEFECTS.DURATION',
  'MQA.RESULTS.GRID.DEFECTS.EXCEEDS_INSPECTED_RANGE',
  'MQA.RESULTS.GRID.DEFECTS.DEFECT_STATUS',
  'MQA.RESULTS.GRID.DEFECTS.OPEN',
  'MQA.RESULTS.GRID.DEFECTS.RESOLVED',
  'BUSINESS_IDENTITY_CODE'
] as const;

export type TranslationsMap = { [K in (typeof translations)[number]]: string };

export class ManualQaResultService {
  public static readonly $inject = ['utils'];

  public constructor(private utils: any) {
  }

  public getResultGridOptions(
    searchType: StorageSearchType,
    updateVisuals: Function,
    searchItemType = SearchItemType.Meters
  ): kendo.ui.GridOptions {
    const translated: TranslationsMap = this.utils.mapLocalizationTagArrayToLocalizedObject(translations);
    switch (searchItemType) {
      case SearchItemType.Meters:
        return getMeterGridOptions(translated, searchType);
      case SearchItemType.Statistics:
        return getStatisticsGridOptions(translated, updateVisuals);
      case SearchItemType.Defects:
        return getDefectGridOptions(translated);
      default:
        assertUnreachable(searchItemType);
    }
  }
}
