import 'angular-translate';
import '@uirouter/angular-hybrid';

import EnerKeyServicesmodule from '../../services';
import ConfigurationModelsModule from '../configuration/models';
import DocumentsModelsModule from '../documents/models';
import MomentModule from '../moment';
import ReportingObjectsServicesModule from './services';
import config from './config';
import { metertree } from './directives/metertree';

export default angular.module('ReportingObjects', [
  'ui.router',
  'pascalprecht.translate',
  'kendo.directives',
  ConfigurationModelsModule.name,
  DocumentsModelsModule.name,
  MomentModule.name,
  EnerKeyServicesmodule.name,
  ReportingObjectsServicesModule.name
])
  .config(config)
  .directive('metertree', metertree)
;
