import * as FetchType from '../components/manual-qa-fetch-type/manual-qa-fetch-type.constant';
import * as Configs from '../constants/configs';
import { TrendModelType } from '@enerkey/clients/manual-qa';
import { getTimeFrameQueryParameters } from '../shared/utils.functions';
import { Reading } from '../shared/reading';

/**
 * Service that handles all manual qa server calls.
 *
 * @returns {Object}
 *
 * @constructor
 */
class ManualQaApiRawReadings {
  public constructor(private ManualQaApiService: any) {}

  /**
   * Returns raw readings from backend
   *
   * @param {TimeFrame} timeFrame
   * @param {Object} data
   * @param {number} fetchType
   *
   * @returns {Object}
   */
  public getReadings(timeFrame: any, data: any, fetchType = FetchType.BOTH) {
    const { from, to } = getTimeFrameQueryParameters(timeFrame);

    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}raw/readings`,
      data: data,
      params: {
        start: from,
        end: to,
        readingFetchType: fetchType
      }
    }).then((result: any) => result.data);
  }

  /**
   * Sends raw readings to backend
   *
   * @param {Meter} meter
   * @param {Reading[]} readings
   * @param {Number} valueType
   *
   * @returns {Object}
   */
  public saveReadings(meter: any, readings: any, valueType: number) {
    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}raw/hourly/${meter.getId()}?valueType=${valueType}`,
      data: readings
        .map((reading: any) => Reading.getPreviewPayload(reading))
        .filter((reading: any) => reading.value !== null)
    }).then((result: any) => result.data);
  }

  /**
   * Locks meter readings within operation time frame
   *
   * @param {Meter} meter
   * @param {TimeFrame} timeFrame
   *
   * @returns {Object}
   */
  public lockReadings(meter: any, timeFrame: any) {
    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}raw/${meter.getId()}/lock`,
      params: getTimeFrameQueryParameters(timeFrame)
    }).then((result: any) => result.data);
  }

  /**
   * Unlocks meter readings within operation time frame
   *
   * @param {Meter} meter
   * @param {TimeFrame} timeFrame
   *
   * @returns {Object}
   */
  public unlockReadings(meter: any, timeFrame: any) {
    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}raw/${meter.getId()}/unlock`,
      params: getTimeFrameQueryParameters(timeFrame)
    }).then((result: any) => result.data);
  }

  /**
   * Deletes meter readings within given time frame
   *
   * @param {Meter} meter
   * @param {TimeFrame} timeFrame
   *
   * @returns {Object}
   */
  public deleteReadings(meter: any, timeFrame: any) {
    return this.ManualQaApiService.doRequest({
      method: 'DELETE',
      url: `${Configs.API_URL}raw/${meter.getId()}/modeled`,
      params: getTimeFrameQueryParameters(timeFrame)
    }).then((result: any) => result.data);
  }

  /**
   * Deletes consumptions within given time frame
   *
   * @param {Meter} meter
   * @param {TimeFrame} timeFrame
   *
   * @returns {Object}
   */
  public deleteConsumption(meter: any, timeFrame: any) {
    return this.ManualQaApiService.doRequest({
      method: 'DELETE',
      url: `${Configs.API_URL}raw/${meter.getId()}`,
      params: getTimeFrameQueryParameters(timeFrame)
    }).then((result: any) => result.data);
  }

  /**
   * Get backend calculated hourly readings for preview.
   *
   * @param {Meter} meter
   * @param {TimeFrame} timeFrame
   *
   * @returns {Object}
   */
  public getHourlyModel(meter: any, timeFrame: any, modelType: TrendModelType) {
    return this.ManualQaApiService.doRequest({
      method: 'GET',
      url: `${Configs.API_URL}raw/hourlyModelled/${meter.getId()}`,
      params: {
        start: timeFrame
          .getFromDate()
          .startOf('hour')
          .toISOString(),
        end: timeFrame
          .getToDate()
          .startOf('hour')
          .toISOString(),
        modelType
      }
    }).then((result: any) => result.data);
  }
}

ManualQaApiRawReadings.$inject = ['ManualQaApiService'];

export default ManualQaApiRawReadings;
