import _ from 'lodash';
import templateModule from 'raw-loader!../templates/mrc-datepicker.html';

mrcDatepicker.$inject = ['$timeout', '$filter', 'appStatusService'];

export function mrcDatepicker($timeout, $filter, appStatusService) {
  function link(scope, element) {

    scope.visible = false;
    scope.placeholderTxt = '';

    var datePicker = void 0;
    var pickerOptions = {
      format: scope.dateFormat,
      value: scope.dateObject,
      min: scope.minDate,
      max: scope.maxDate
    };

    function updatePlaceholderText() {
      scope.placeholderTxt = _.isString(scope.placeholder) &&
        scope.placeholder.length > 0 ? $filter('translate')(scope.placeholder) : '';
    }

    scope.init = function() {
      var kendoDatePicker = angular.element("#kDatePicker");
      kendoDatePicker.kendoDatePicker();
      datePicker = kendoDatePicker.data('kendoDatePicker');

      if (angular.isDefined(datePicker)) {
        updatePlaceholderText();
        pickerOptions = {
          format: scope.dateFormat,
          value: scope.dateObject,
          min: scope.minDate,
          max: scope.maxDate
        };
        datePicker.setOptions(pickerOptions);
        datePicker.bind('change', scope.onDateChanged);
        datePicker.bind('close', scope.onClose);
        datePicker.bind('open', scope.onOpen);
        datePicker.value(scope.dateObject);
        datePicker.open();
      }

      if (appStatusService.isMobile) { // Make input readonly on mobile devices so keyboard doesn't show up
        kendoDatePicker.attr('readonly', 'readonly');
      }
    };

    scope.onSelect = function() {
      if (!scope.disabled) {
        if (scope.visible) {
          if (angular.isDefined(datePicker)) {
            datePicker.close();
          }
        } else {
          scope.visible = true;
        }
      }
    };

    scope.onOpen = function() {
      angular.element("#kDatePicker").focus();
    };

    function remove() {
      if (angular.isDefined(datePicker)) {
        datePicker.destroy();
        $timeout(function() {
          scope.visible = false;
        }, 0);
      }
    }

    scope.onDateChanged = function() {
      scope.dateObject = datePicker.value();
      $timeout(function() {
        scope.$apply();
        scope.dateChanged();
      }, 0);
    };

    scope.onClose = function() {
      remove();
    };

    updatePlaceholderText();

    element.on('$destroy', function() {
      remove();
    });
  }

  return {
    restrict: 'E',
    scope: {
      dateFormat: "@",
      dateObject: "=",
      dateChanged: "&",
      minDate: "=",
      maxDate: "=",
      placeholder: "@",
      invalidValue: "=",
      disabled: "="
    },
    replace: true,
    template: templateModule,
    link: link
  };
}
