import { DashType } from '@progress/kendo-angular-charts';

import { EnergyTargetType } from '@enerkey/clients/reporting';
import { Quantities } from '@enerkey/clients/metering';

interface TargetSeriesDefinition {
  translationKey: string;
  color: string;
  dashType: DashType;
  applicableQuantities?: Quantities[];
  targetType: EnergyTargetType
}

export const targetSeriesDefinitions: Record<EnergyTargetType, TargetSeriesDefinition> = {
  [EnergyTargetType.TargetA]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.TARGET_A',
    color: '#DA1F1C',
    dashType: 'longDash',
    targetType: EnergyTargetType.TargetA
  },
  [EnergyTargetType.TargetB]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.TARGET_B',
    color: '#27B5FF',
    dashType: 'longDash',
    targetType: EnergyTargetType.TargetB
  },
  [EnergyTargetType.BaseLevel]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.BASE_LEVEL',
    color: '#EC8C00',
    dashType: 'longDash',
    targetType: EnergyTargetType.BaseLevel
  },
  [EnergyTargetType.PlannedLevel]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.PLANNED_LEVEL',
    color: '#20BE0D',
    dashType: 'longDash',
    targetType: EnergyTargetType.PlannedLevel
  },
  [EnergyTargetType.ActionsBased]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.ACTION_BASED',
    color: '#3b134c',
    dashType: 'longDash',
    applicableQuantities: [
      Quantities.Electricity,
      Quantities.Water,
      Quantities.HeatingEnergy,
      Quantities.TotalEnergy
    ],
    targetType: EnergyTargetType.ActionsBased
  },
  [EnergyTargetType.TargetATotalCost]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_A',
    color: '#0100da',
    dashType: 'longDashDot',
    targetType: EnergyTargetType.TargetATotalCost
  },
  [EnergyTargetType.TargetASales]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_A_SALES',
    color: '#a17600',
    dashType: 'longDashDot',
    targetType: EnergyTargetType.TargetASales
  },
  [EnergyTargetType.TargetADistribution]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_A_DISTR',
    color: '#a15600',
    dashType: 'longDashDot',
    targetType: EnergyTargetType.TargetADistribution
  },
  [EnergyTargetType.TargetBTotalCost]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_B',
    color: '#9c00ff',
    dashType: 'longDashDot',
    targetType: EnergyTargetType.TargetBTotalCost
  },
  [EnergyTargetType.TargetBSales]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_B_SALES',
    color: '#a17600',
    dashType: 'longDashDot',
    targetType: EnergyTargetType.TargetBSales
  },
  [EnergyTargetType.TargetBDistribution]: {
    translationKey: 'ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_B_DISTR',
    color: '#a15600',
    dashType: 'longDashDot',
    targetType: EnergyTargetType.TargetBDistribution
  },
};
