import _ from 'lodash';
import moment from 'moment';
import { costTargetTypes } from '../../../energy-management/constants/consumption-target-constant';

const $inject = ['utils', 'ERUtils', 'filterService', 'ColorService'];

// This service contains common methods for ERGridService and ERChartService
function ERDataFunctions(utils, ERUtils, filterService, ColorService) {

  function getAggregatedConsumptions(options, aggregateQuantityId) {
    const consumptions = [];

    _.each(_.get(options, 'api'), api => {
      const consumption = { Values: {} };
      switch (options.idType) {
        case 'Facility':
          _.each(getFacilityIds(options), facilityId => {
            const facility = options.cache.facilitiesById[facilityId];
            if (facility) {
              const facilityConsumptions = api.responseData[facilityId];
              const quantityConsumptions = _.find(
                _.get(facilityConsumptions, 'SubSeries'),
                { _Id: aggregateQuantityId, _IdType: 'Quantity' }
              );
              if (quantityConsumptions) {
                _.each(options.series.Start, start => {
                  if (!consumption.Values[start.key]) {
                    consumption.Values[start.key] = [];
                  }
                  consumption.Values[start.key].push(
                    _.extend({ Id: facilityId }, _.get(quantityConsumptions.Aggregates, start.key))
                  );
                });
              }
            }
          });
          break;
        case 'Meter':
          _.each(getMeterIds(options), meterId => {
            const meter = options.cache.metersById[meterId];
            if (meter) {
              const meterConsumptions = api.responseData[meterId];
              if (meterConsumptions) {
                _.each(options.series.Start, start => {
                  if (!consumption.Values[start.key]) {
                    consumption.Values[start.key] = [];
                  }
                  consumption.Values[start.key].push(
                    _.extend({ Id: meterId }, _.get(meterConsumptions.Aggregates, start.key))
                  );
                });
              }
            }
          });
          break;
        case 'Quantity':
          _.each(getQuantityIds(options), quantityId => {
            const quantity = options.cache.quantitiesById[quantityId];
            if (quantity) {
              const quantityConsumptions = api.responseData[quantityId];
              if (quantityConsumptions) {
                _.extend(consumption, _.pick(quantityConsumptions, ['FacilitiesIncluded', 'FacilitiesExcluded']));
                _.each(options.series.Start, start => {
                  if (!consumption.Values[start.key]) {
                    consumption.Values[start.key] = [];
                  }
                  consumption.Values[start.key].push(
                    _.extend({ Id: quantityId }, _.get(quantityConsumptions.Aggregates, start.key))
                  );
                });
              }
            }
          });
          break;
        default:
          break;
      }
      consumptions.push(consumption);
    });

    return consumptions;
  }

  function getDataForQuantity(options, data, quantityId, shouldReplaceNullsWithZeroes = false) {
    const quantity = options.cache.quantitiesById[quantityId];
    if (!quantity) {
      return {};
    }
    const unitDef = _.get(quantity, `Units.${options.params.unitKey}`, {});

    // consumptions for quantity
    let consumptions = [];
    let isAdditionalDigitsRequired = false;
    if (isAggregated(options)) {
      consumptions = getAggregatedConsumptions(options, quantityId);
    } else {
      // Additional digits for day and hour resolutions if not aggregated data
      isAdditionalDigitsRequired = isHourOrDayResolution(options);
      _.each(_.get(options, 'api'), api => {
        let facilityConsumptions;
        let meter;
        switch (options.idType) {
          case 'Facility':
            facilityConsumptions = api.responseData[data.facilityId];
            consumptions.push(_.get(facilityConsumptions, `SubSeries.${quantityId}`));
            break;
          case 'Meter':
            meter = options.cache.metersById[data.meterId];
            if (_.isEqual(_.get(meter, 'QuantityId'), quantityId)) {
              consumptions.push(api.responseData[data.meterId]);
            }
            break;
          case 'Quantity':
            consumptions.push(api.responseData[quantityId]);
            break;
          default:
            break;
        }
      });
    }

    const alteredConsumptions = shouldReplaceNullsWithZeroes ? _.cloneDeep(consumptions) : consumptions;
    const valueContainer = alteredConsumptions[0];
    if (shouldReplaceNullsWithZeroes && valueContainer) {
      replaceConsumptionNullsWithZeroes(valueContainer);
    }

    return _.extend({
      quantity: quantity,
      quantityId: quantityId,
      unit: _.get(unitDef, 'Unit'),
      format: utils.formatForQuantity(quantity, unitDef, isAdditionalDigitsRequired),
      colors: ColorService.getPrimaryGraphColors(quantityId, options.series.Start.length),
      consumptions: alteredConsumptions
    }, data);
  }

  function getDataForStart(options, data, startIndex, consumptionOptions, shouldReplaceNullsWithZeroes = false) {
    const start = _.get(options.series, `Start.${startIndex}`);
    if (!start) {
      return data;
    }
    const result = _.extend({
      startInfo: {
        index: startIndex,
        key: start.key,
        value: start.value,
        color: _.get(data, `colors.${startIndex}`)
      }
    }, data);

    const previousStart = _.get(options.series, `Start.${startIndex - 1}`);
    if (previousStart) {
      _.extend(result, {
        previousStartInfo: {
          index: startIndex - 1,
          key: previousStart.key,
          value: previousStart.value,
          color: _.get(data, `colors.${startIndex - 1}`)
        }
      });
    }

    const startLast = _.get(options.series, 'Start.length', 0) - 1;
    const controlStart = _.get(options.series, `Start.${startLast}`);
    if (controlStart) {
      _.extend(result, {
        controlStartInfo: {
          index: startLast,
          key: controlStart.key,
          value: controlStart.value,
          color: _.get(data, `colors.${startLast}`)
        }
      });
    }

    if (_.isObject(consumptionOptions)) {
      result.startInfo.consumptions = _.map(data.consumptions, consumption => {
        const consumptionForStart = _.get(consumption, `Values["${start.key}"]`);
        return consumptionOptions.index ?
          _.indexBy(consumptionForStart, isAggregated(options) ? 'Id' : 'Timestamp') :
          consumptionForStart;
      });
      result.startInfo.keys = _.reduce(
        result.startInfo.consumptions,
        (resultKeys, consumption) => _.extend(
          resultKeys,
          getKeys(options, consumptionOptions.index ? _.values(consumption) : consumption)
        ),
        {}
      );
      if (previousStart) {
        result.previousStartInfo.consumptions = _.map(data.consumptions, consumption => {
          const consumptionForStart = _.get(consumption, `Values["${previousStart.key}"]`);
          return consumptionOptions.index ?
            _.indexBy(consumptionForStart, isAggregated(options) ? 'Id' : 'Timestamp') :
            consumptionForStart;
        });
        result.previousStartInfo.keys = _.reduce(
          result.previousStartInfo.consumptions,
          (resultKeys, consumption) => _.extend(
            resultKeys,
            getKeys(options, consumptionOptions.index ? _.values(consumption) : consumption)
          )
        );
      }
    }

    const alteredResult = shouldReplaceNullsWithZeroes ? _.cloneDeep(result) : result;
    if (shouldReplaceNullsWithZeroes) {
      _.each(alteredResult.consumptions, valueContainer => {
        if (valueContainer) {
          replaceConsumptionNullsWithZeroes(valueContainer);
        }
      });
    }

    return alteredResult;
  }

  function getControlPeriodIndex(options) {
    return options.series.Start.length - 1;
  }

  function getFacilityIds(options) {
    return _.reduce(options.api, (result, api, apiIndex) => {
      if (isWeatherRequest(options, apiIndex)) {
        return result;
      }
      const facilityIds = _.get(api, 'requestData.FacilityId') ||
        filterService.getFilteredFacilityIds() ||
        _.map(_.keys(options.cache.facilitiesById), facilityId => parseInt(facilityId, 10))
      ;
      return _.uniq(result.concat(facilityIds));
    }, []);
  }

  function getForecastType(options, data) {
    const forecastTypeId = _.get(options, `api.${data.apiIndex}.requestData.ForecastType`);
    return _.get(options, `cache.forecastTypesById.${forecastTypeId}`);
  }

  function getKeys(options, values) {
    const result = {};

    if (isAggregated(options)) {
      _.each(values, value => {
        let key;
        const id = _.get(value, 'Id');
        let facility;
        let meter;
        let quantity;
        switch (options.idType) {
          case 'Facility':
            facility = options.cache.facilitiesById[id];
            key = _.get(facility, 'Name') || id;
            break;
          case 'Meter':
            meter = options.cache.metersById[id];
            key = _.get(meter, 'Name') || id;
            break;
          case 'Quantity':
            quantity = options.cache.quantitiesById[id];
            key = _.get(quantity, 'Name') || id;
            break;
          default:
            key = id;
            break;
        }
        result[id] = key;
      });
    } else {
      const longFormat = _.isArray(_.get(options, 'series.Start')) ?
        (options.series.Start.length > 1 || options.isWidget) :
        false;
      let dayIndex = 1;
      let currentDay;

      _.each(values, (value, index) => {
        const timestampAsDate = utils.utcDateToDate(value.Timestamp);
        const timestampDay = timestampAsDate.getDate();
        if (!currentDay) {
          currentDay = timestampDay;
        }
        if (timestampDay !== currentDay) {
          dayIndex++;
          currentDay = timestampDay;
        }
        result[value.Timestamp] = ERUtils.resolutionToString(
          timestampAsDate, options.series.Resolution, index, dayIndex, longFormat
        );
      });
    }

    return result;
  }

  function getMeterIds(options) {
    return _.reduce(options.api, (result, api) => {
      const meterIds = _.get(api, 'requestData.MeterId');
      return _.uniq(result.concat(meterIds));
    }, []);
  }

  function getQuantityIds(options) {
    return _.reduce(options.api, (result, api) => {
      const quantityIds = _.pluck(_.get(api, 'requestData.Quantities'), 'ID');
      return _.uniq(result.concat(quantityIds));
    }, []);
  }

  function getSort(options) {
    return _.get(options.chart, 'separateQuantityProperties.sort');
  }

  function getTargetSeriesType(options, data, apiIndex) {
    apiIndex = angular.isUndefined(apiIndex) ? data.apiIndex : apiIndex;
    let targetSeriesType = _.get(options, `api.${apiIndex}.requestData.TargetSeriesType`);
    const isBudget = _.get(options, `api.${apiIndex}.requestData.Budget`);
    if (isBudget) {
      targetSeriesType = costTargetTypes.find(targetType => targetType.target === targetSeriesType).cost;
    }
    return _.get(options, `cache.targetSeriesType.${targetSeriesType}`);
  }

  function isCostTarget(targetSeriesType) {
    return targetSeriesType && costTargetTypes.some(targetType => targetType.cost === targetSeriesType.Id);
  }

  function isAggregated(options) {
    const resolution = _.get(options, 'api.0.requestData.Resolution');
    return !(_.isString(resolution) && resolution.length);
  }

  function isHourOrDayResolution(options) {
    return (options.series.Resolution === 'PT1H' || options.series.Resolution === 'P1D');
  }

  function isPeriodReportWithMonthResolutionAndComparisionPeriod(options) {
    return (
      options.series.Resolution === 'P1M' &&
      options.series.Start.length > 1 &&
      !isAggregated(options) &&
      !getSort(options) &&
      isSeriesWithinFullYear(options)
    );
  }

  function isSeriesWithinFullYear(options) {
    const startDates = options.api[0].requestData.Start;
    const duration = moment.fromIsodurationCached(options.api[0].requestData.TimeFrame);

    // Check that all series start and end date are in same year.
    return _.filter(startDates, startDate => {
      const serieStartDate = moment(startDate.value);
      const serieEndDate = serieStartDate.clone().add(duration);

      return (serieStartDate.year() === serieEndDate.year());
    }).length > 0;
  }

  function isWeatherRequest(options, apiIndex) {
    return !!_.get(options, `api.${apiIndex}.requestData.PostalCodeCountry`);
  }

  function getReportType(options, apiIndex) {
    return _.get(options, `api.${apiIndex}.requestData.ReportType`);
  }

  function getApiIndexForReadingValues(options) {
    return _.findIndex(_.get(options, 'api'), api => !_.isNumber(_.get(api, 'requestData.TargetSeriesType')));
  }

  function addAggregates(options, data, column, format, aggregateFunctions) {
    aggregateFunctions = aggregateFunctions || {};
    column.aggregates = options.grid.aggregates;
    const footerTemplate = templateData => {
      let text = '<div style="text-align: right">';
      let first = true;
      _.each(options.grid.aggregates, aggregate => {
        text += first ? '' : '</br>';
        first = false;
        let value;
        if (angular.isFunction(aggregateFunctions[aggregate])) {
          value = aggregateFunctions[aggregate](templateData);
        } else {
          value = templateData[column.field] ? templateData[column.field][aggregate] : null;
        }
        if (value === null && templateData[aggregate] && format !== 'p1') {
          value = templateData[aggregate];
        }
        if (value !== null) {
          text += kendo.toString(value, format);
        }
      });
      text += '</div>';
      return text;
    };
    column.footerTemplate = footerTemplate;
    column.groupFooterTemplate = footerTemplate;
    _.each(column.aggregates, aggregate => {
      const addAggregate = {
        field: column.field,
        aggregate: aggregate
      };
      if (!_.find(data.gridOptions.dataSource.aggregate, addAggregate)) {
        data.gridOptions.dataSource.aggregate.push(addAggregate);
      }
    });
  }

  function getReadingField(options, data, startInfo, apiIndex, relationalValueId) {
    startInfo = startInfo || data.startInfo;
    apiIndex = _.isNumber(apiIndex) ? apiIndex : data.apiIndex;
    const isNormalized = getTargetSeriesType(options, data, apiIndex) ? false : data.normalized;
    const quantityId = data.isAggregatedQuantityGrid ? '' : `$${data.quantityId}`;
    const relationalValue = relationalValueId ? `$RelationalValues$${relationalValueId}` : '';
    const normalized = isNormalized ? '$Normalized' : '$';
    return `${quantityId}$${apiIndex}$${startInfo.index}${relationalValue}${normalized}Reading$Value`;
  }

  function getComparisonColumnSumFunction(column, key) {
    const sumFunction = data => {
      const serieSum = data[column.serie] ? data[column.serie].sum : 0;
      const previousSerieSum = data[column.previousSerie] ? data[column.previousSerie].sum : null;
      if (!angular.isNumber(previousSerieSum)) {
        return null;
      }
      const change = serieSum - previousSerieSum;
      const changePercent = utils.percentageChange(serieSum, previousSerieSum);
      return (key === 'Percent') ? changePercent : change;
    };
    return sumFunction;
  }

  function replaceConsumptionNullsWithZeroes(valueContainer) {
    const { Aggregates, Values } = valueContainer;
    _.each(_.keys(Aggregates), aggregateKey => {
      replaceNullsWithZeroes(Aggregates[aggregateKey]);
    });
    _.each(_.keys(Values), valueKey => {
      _.each(Values[valueKey], valueObject => {
        replaceNullsWithZeroes(valueObject);
      });
    });
  }

  function replaceNullsWithZeroes(valueContainer) {
    if (valueContainer.DistributionValues) {
      ['Day', 'Holiday', 'Night', 'WorkDay'].forEach(distributionKey => {
        const distribution = valueContainer.DistributionValues[distributionKey];
        if (distribution.Percent === null) {
          distribution.Percent = 0;
        }
        if (distribution.Reading.Value === null) {
          distribution.Reading.Value = 0;
        }
      });
    }

    _.each(_.keys(valueContainer.RelatedValues), relatedValueKey => {
      const relatedValue = valueContainer.RelatedValues[relatedValueKey];
      if (relatedValue !== null && relatedValue.Value === null) {
        relatedValue.Value = 0;
      }
    });

    _.each(_.keys(valueContainer.RelationalValues), relationalValueKey => {
      const relationalValueContainer = valueContainer.RelationalValues[relationalValueKey];
      replaceNullReadingsWithZeroes(relationalValueContainer);
    });

    replaceNullReadingsWithZeroes(valueContainer);
  }

  function replaceNullReadingsWithZeroes(valueContainer) {
    ['NormalizedReading', 'Reading'].forEach(readingKey => {
      if (valueContainer[readingKey]) {
        const reading = valueContainer[readingKey];
        if (reading !== null && reading.Value === null) {
          reading.Value = 0;
        }
      }
    });
  }

  return {
    getAggregatedConsumptions,
    getDataForQuantity,
    getDataForStart,
    getControlPeriodIndex,
    getFacilityIds,
    getForecastType,
    getMeterIds,
    getQuantityIds,
    getTargetSeriesType,
    isCostTarget,
    isAggregated,
    isPeriodReportWithMonthResolutionAndComparisionPeriod,
    isWeatherRequest,
    getReportType,
    getApiIndexForReadingValues,
    addAggregates,
    getReadingField,
    getComparisonColumnSumFunction
  };
}

ERDataFunctions.$inject = $inject;

export default ERDataFunctions;
