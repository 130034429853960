import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, map, switchMap, toArray } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { ModalBase } from '@enerkey/foundation-angular';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { NoReplyMessage, UserManagementClient } from '@enerkey/clients/user-management';
import { InspectionDefectItem } from '@enerkey/clients/manual-qa';
import {
  BucketContact,
  ConfigurationControlClient
} from '@enerkey/clients/configuration-control';
import { WizardStep } from '../../../../shared/interfaces/wizard-step';
import { indicate, prepare } from '@enerkey/rxjs';
import {
  EmailBaseForm,
  getEmailCommonValuesForm,
  getInfoSendForm,
  getSingleEmailForm,
  ReceiverProperty,
  SingleEmailForm
} from './email-functions';
import { ManualQaDefectEmailService } from '../../services/manual-qa-defect-email.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { TenantInfoService } from '../../../../shared/services/tenant-info.service';

export enum EmailSendStep {
  EmailBase,
  InfoToSend,
  Sending,
  Summary
}

export enum EmailSendStatus {
  Success,
  Fail,
  Skip
}

export interface EmailResult {
  email: string;
  status: EmailSendStatus;
  readingGroupIdentifier: string;
}

@Component({
  selector: 'email-send-modal',
  templateUrl: './email-send-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSendModalComponent extends ModalBase implements OnInit {
  @Input() public set defects(defects: InspectionDefectItem[]) {
    this.bucketDefects = defects
      .filter(defect => typeof defect.bucketId === 'number')
      .toGroupsBy('bucketId');
  }

  public readonly EmailSendStep = EmailSendStep;

  public readonly emailCommonValuesForm: UntypedFormGroup;
  public readonly infoToSendForm: UntypedFormGroup;
  public readonly singleEmailForm: UntypedFormGroup;

  public readonly loading$ = new ReplaySubject<boolean>(1);

  public activeStep: WizardStep;
  public readonly wizardSteps: WizardStep[];

  public emailResults: EmailResult[] = [];
  public readingGroupIdentifier$ = new BehaviorSubject<string>('');
  public currentBucketIndex: number = 0;

  private bucketDefects: Map<number, InspectionDefectItem[]>;
  private bucketIterator: IterableIterator<number>;

  public constructor(
    public readonly activeModal: NgfActiveModal,
    private readonly emailDefectService: ManualQaDefectEmailService,
    private readonly userManagementClient: UserManagementClient,
    private readonly ccClient: ConfigurationControlClient,
    private readonly toasterService: ToasterService,
    private readonly authenticationService: AuthenticationService,
    private readonly tenantInfoService: TenantInfoService,
    translate: TranslateService
  ) {
    super(activeModal);
    this.emailCommonValuesForm = getEmailCommonValuesForm(this.authenticationService.getUsername());
    this.infoToSendForm = getInfoSendForm();
    this.singleEmailForm = getSingleEmailForm();

    this.wizardSteps = [
      {
        id: EmailSendStep.EmailBase,
        text: translate.instant('MQA.EMAIL.WIZARD.EMAIL_BASE')
      },
      {
        id: EmailSendStep.InfoToSend,
        text: translate.instant('MQA.EMAIL.WIZARD.FIELDS_TO_SEND')
      },
      {
        id: EmailSendStep.Sending,
        text: translate.instant('MQA.EMAIL.WIZARD.SENDING'),
        onEnter: () => {
          this.bucketIterator = this.getBucketIterator();
          this.goToNextBucket();
        },
        canContinue: false,
        canReturn: false
      },
      {
        id: EmailSendStep.Summary,
        text: translate.instant('MQA.EMAIL.WIZARD.SUMMARY'),
        canReturn: false
      }
    ];

    this.activeStep = this.wizardSteps[0];
  }

  public ngOnInit(): void {
    this.userManagementClient.getUser(this.authenticationService.getUserId())
      .pipe(
        prepare(() => this.emailCommonValuesForm.disable()),
        indicate(this.loading$),
        map(user => `${user.firstName} ${user.lastName}`),
        catchError(() => of('')),
        finalize(() => this.emailCommonValuesForm.enable())
      )
      .subscribe(user => {
        this.setSignatureValue(user);
      });
  }

  public sendAndGoToNextBucket(): void {
    this.bccValue.pipe(
      switchMap(bccValue =>
        this.userManagementClient.sendMail(
          new NoReplyMessage({
            ...this.singleEmailFormValue,
            cc: this.singleEmailFormValue.cc || null,
            bcc: bccValue,
            replyTo: this.commonEmailValues.replyTo
          })
        )),
      indicate(this.loading$),
      finalize(() => {
        this.goToNextBucket();
      })
    )
      .subscribe({
        next: () => {
          this.setStatusOfCurrentEmail(EmailSendStatus.Success);
        },
        error: () => {
          this.setStatusOfCurrentEmail(EmailSendStatus.Fail);
        }
      });
  }

  public skipCurrentBucket(): void {
    this.setStatusOfCurrentEmail(EmailSendStatus.Skip);
    this.goToNextBucket();
  }

  public get bucketAmount(): number {
    return this.bucketDefects.size;
  }

  private setSignatureValue(user: string): void {
    const signatureControl = this.emailCommonValuesForm.get('signature');
    // because of kendo editor bug, visible value is not updated when value of disabled control is changed
    signatureControl.enable();
    signatureControl.setValue(
      `Ystävällisin terveisin,<br/>${user}<br/>EnerKey laadunvarmistus`
    );
  }

  private goToNextBucket(): void {
    const bucketId = this.bucketIterator.next().value;
    if (bucketId !== undefined && bucketId !== null) {
      this.resetEmailForm(bucketId);
    } else {
      this.activeStep = this.wizardSteps[this.wizardSteps.length - 1];
    }
  }

  private setStatusOfCurrentEmail(status: EmailSendStatus): void {
    this.emailResults.push({
      email: this.singleEmailFormValue.to,
      status: status,
      readingGroupIdentifier: this.readingGroupIdentifier$.value
    });
  }

  private *getBucketIterator(): IterableIterator<number> {
    for (const [index, bucketId] of [...this.bucketDefects.keys()].entries()) {
      this.currentBucketIndex = index + 1;
      yield bucketId;
    }
  }

  private resetEmailForm(bucketId: number): void {
    this.singleEmailForm.reset();
    this.singleEmailForm.disable();
    this.readingGroupIdentifier$.next(this.getBucketName(bucketId));
    this.getDefectBucketContact(bucketId);
  }

  private getDefectBucketContact(bucketId: number): void {
    this.ccClient.getBucket(bucketId)
      .pipe(
        indicate(this.loading$),
        switchMap(bucket => this.ccClient.getBucketContact(bucket.bucketContactId)),
        catchError(() => {
          this.toasterService.error(null, 'MQA.EMAIL.NO_BUCKET_CONTACT_FOUND');
          return of<BucketContact>({});
        })
      )
      .subscribe(contact => {
        this.setEmailFormValue(bucketId, contact);
      });
  }

  private setEmailFormValue(bucketId: number, contact: BucketContact): void {
    const defects = this.getBucketDefects(bucketId);

    const bodyText = this.emailDefectService.getBucketEmailBody(
      defects,
      this.infoToSendForm.value,
      this.commonEmailValues.coveringNote,
      this.commonEmailValues.signature
    );
    const toProperty: ReceiverProperty = this.commonEmailValues.to;
    this.singleEmailForm.enable();
    this.singleEmailForm.setValue({
      subject: this.commonEmailValues.subject,
      body: bodyText,
      to: contact[toProperty] || null,
      cc: null
    });
  }

  private getBucketName(bucketId: number): string {
    return this.getBucketDefects(bucketId)[0].readingGroupIdentifier;
  }

  private getBucketDefects(bucketId: number): InspectionDefectItem[] {
    return this.bucketDefects.get(bucketId);
  }

  private get commonEmailValues(): EmailBaseForm {
    return this.emailCommonValuesForm.value;
  }

  private get singleEmailFormValue(): SingleEmailForm {
    return this.singleEmailForm.value;
  }

  private get bccValue(): Observable<string> {
    const observables: Observable<string>[] = [];
    if (this.commonEmailValues.bcc) {
      observables.push(this.tenantInfoService.getAutomaticMissingReadingEmail());
    }
    if (this.commonEmailValues.bccUser) {
      observables.push(of(this.authenticationService.getUsername()));
    }
    return merge(...observables).pipe(
      toArray(),
      map(results => Array.hasItems(results) ? results.join(' ') : null)
    );
  }
}
