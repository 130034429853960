import { NgModule } from '@angular/core';

import { NgfTooltipDirective, NgfTooltipWindowComponent } from './tooltip';

export { NgfTooltipConfig, TooltipAlignment, TooltipPlacement } from './tooltip-config';
export { NgfTooltipDirective } from './tooltip';

@NgModule({
  declarations: [NgfTooltipDirective, NgfTooltipWindowComponent],
  exports: [NgfTooltipDirective],
})
export class NgfTooltipModule {
}
