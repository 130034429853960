import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';

import { NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkFormsModule } from '@enerkey/ek-forms';

import { EtCurveCalculationComponent } from '../../modules/analytics/components/et-curve-components/et-curve-calculation/et-curve-calculation.component';
import { EtCurveChartTooltipComponent } from '../../modules/analytics/components/et-curve-components/et-curve-chart-tooltip/et-curve-chart-tooltip.component';
import { EtCurveChartComponent } from '../../modules/analytics/components/et-curve-components/et-curve-chart/et-curve-chart.component';
import { EtCurveEnergyMeasurementComponent } from '../../modules/analytics/components/et-curve-components/et-curve-energy-measurement/et-curve-energy-measurement.component';
import { EtCurveFacilityListComponent } from '../../modules/analytics/components/et-curve-components/et-curve-facility-list/et-curve-facility-list.component';
import { EtCurvePeriodSelectionComponent } from '../../modules/analytics/components/et-curve-components/et-curve-period-selection/et-curve-period-selection.component';
import { EtCurveQuantityListComponent } from '../../modules/analytics/components/et-curve-components/et-curve-quantity-list/et-curve-quantity-list.component';
import { EtCurveService } from '../../modules/analytics/services/et-curve/et-curve.service';
import { ReportingModule } from '../../modules/reporting/reporting.module';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { EkInputsModule } from '../ek-inputs/ek-inputs.module';
import { EkKendoModule } from '../ek-kendo/ek-kendo.module';
import { EkLoadingModule } from '../ek-loading/ek-loading.module';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    EtCurveChartComponent,
    EtCurveChartTooltipComponent,
    EtCurveFacilityListComponent,
    EtCurveQuantityListComponent,
    EtCurveEnergyMeasurementComponent,
    EtCurveCalculationComponent,
    EtCurvePeriodSelectionComponent,
  ],
  imports: [
    CommonModule,
    CommonPipesModule,
    ChartsModule,
    TranslateModule,
    IntlModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    EkInputsModule,
    EkKendoModule,
    EkLoadingModule,
    EkFormsModule,
    DropDownsModule,
    DateInputsModule,
    NgfTooltipModule,
    SharedModule,
    ReportingModule
  ],
  providers: [
    EtCurveService
  ],
  exports: [
    EtCurveChartComponent,
    EtCurveChartTooltipComponent,
    EtCurveFacilityListComponent,
    EtCurveQuantityListComponent,
    EtCurveEnergyMeasurementComponent,
    EtCurveCalculationComponent,
    EtCurvePeriodSelectionComponent
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AnalyticsSharedModule {}
