import EnerKeyServicesModule from '../../../services';
import LodashModule          from '../../lodash';
import MomentModule          from '../../moment';

import EnergyManagementConstantsModule from '../constants';

import actions            from './actions';
import consumptionTargets from './consumption-targets';

export default angular.module('EnergyManagement.Models', [
  EnerKeyServicesModule.name,
  LodashModule.name,
  MomentModule.name,
  EnergyManagementConstantsModule.name
])
  .factory('actions', actions)
  .factory('consumptionTargets', consumptionTargets);
