import { INLINE_INDICATOR_SQUARE } from './inline-indicator.constants';

class InlineIndicatorController {
  $onInit() {
    this.visible = !!this.visible;
    this.type = this.type || INLINE_INDICATOR_SQUARE;
    this.color = this.color || '#000000';
  }
}

export default InlineIndicatorController;
