<modal-view-header [headingText]="selectedUser.email + ' (ID:' + selectedUser.id + ')'">
</modal-view-header>
<div class="modal-body">
  <h2>{{ 'ADMIN.NOTIFICATION_LOG' | translate }}</h2>
  <ek-combo
    *labelBefore="'ADMIN.EVENT' | translate"
    [items]="actionList"
    [formControl]="actionControl"
    [loading]="loading$ | async"
    [disableWhenLoading]="false"
  ></ek-combo>
  <kendo-grid
    [kendoGridBinding]="actionLog$ | async"
    [pageSize]="50"
    [pageable]="true"
    [sortable]="true"
    [resizable]="true"
    [loading]="loading$ | async"
    filterable="menu"
  >
    <kendo-grid-column
      [title]="'TIMESTAMP' | translate"
      format="g"
      filter="date"
      field="createdAt"
      [width]="200"
    ></kendo-grid-column>

    <kendo-grid-column
      [title]="'ADMIN.EVENT' | translate"
      field="notificationType"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
      >
        {{ dataItem.notificationType | translate }}
        <span *ngIf="dataItem.creatorUser.userName !== selectedUser.email">
          ({{ dataItem.creatorUser.userName }})
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
