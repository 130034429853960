<modal-view-header [headingText]="'EMAIL_NOTIFICATION.EMAIL_NOTIFICATION_TITLE' | translate"></modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
>
  <p
    class="info-text"
    [innerHTML]="'EMAIL_NOTIFICATION.EMAIL_NOTIFICATION_INFO_TEXT' | translate: infoTextTranslationParams"
  ></p>
  <section class="grid-section">
    <kendo-grid
      [kendoGridBinding]="gridView"
      [pageSize]="10"
      [pageable]="true"
      [sortable]="true"
      filterable="menu"
    >
      <ng-template kendoGridToolbarTemplate>
        <kendo-grid-grouped-column-chooser></kendo-grid-grouped-column-chooser>
      </ng-template>
      <kendo-grid-column
        field="Name"
        [title]="'FACILITY_NAME' | translate"
        [width]="200"
        filter="text"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="monthly"
        [title]="'EMAIL_NOTIFICATION.FREQUENCY.MONTHLY' | translate"
        [width]="100"
        filter="boolean"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
        >
          <input
            type="checkbox"
            kendoCheckBox
            [(ngModel)]="dataItem.monthly"
            (ngModelChange)="isConsumptionEdited = true"
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="weekly"
        [title]="'EMAIL_NOTIFICATION.FREQUENCY.WEEKLY' | translate"
        [width]="100"
        filter="boolean"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
        >
          <input
            type="checkbox"
            kendoCheckBox
            [(ngModel)]="dataItem.weekly"
            (ngModelChange)="isConsumptionEdited = true"
          />
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </section>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="button"
      [loading]="loading$ | async"
      (click)="submit()"
      [disabled]="!isConsumptionEdited"
    >
      {{ 'SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="close()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
