import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  EnerkeySearchable,
  SearchableBookmark,
  SearchableFacility,
  SearchableType
} from '../components/topbar-search-input/searchable-item-types';
import { Service } from '../constants/service';
import { Roles } from '../modules/admin/constants/roles';
import { ExtendedFacilityInformation } from '../shared/interfaces/extended-facility-information';
import { FacilityService } from '../shared/services/facility.service';
import { BookmarkService } from './bookmark.service';
import { UserService } from './user-service';

const facilityExtraFields = ['RealEstateId', 'City', 'StreetAddress'] as const;

@Injectable({
  providedIn: 'root'
})
export class TopbarSearchService {
  public searchItems$: Observable<EnerkeySearchable[]>;

  public constructor(
    facilityService: FacilityService,
    bookmarkService: BookmarkService,
    userService: UserService
  ) {
    const hasAccessToAllProfiles = userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES);

    const searchableFacilities: Observable<SearchableFacility[]> = facilityService.filteredProfileFacilities$.pipe(
      map(
        facilities => userService.hasService(Service.EnergyReporting)
          ? facilities
          : []
      ),
      map(facilities => facilities.map<SearchableFacility>(facility => ({
        searchableType: SearchableType.Facility,
        title: facility.Name,
        facilityId: facility.FacilityId,
        extraText: this.getFacilitySearchableTexts(facility, hasAccessToAllProfiles)
      })))
    );

    const searchableBookmarks: Observable<SearchableBookmark[]> = bookmarkService.bookmarks$.pipe(
      map(bookmarks => bookmarks.user.map<SearchableBookmark>(bookmark => ({
        searchableType: SearchableType.Bookmark,
        title: bookmark.title,
        state: bookmark.state,
        stateParams: bookmark.stateParams,
        modal: bookmark.modal
      })))
    );

    this.searchItems$ = combineLatest([
      searchableFacilities,
      searchableBookmarks
    ]).pipe(
      map(([facilities, bookmarks]) => [
        ...facilities,
        ...bookmarks,
      ].sortBy('title'))
    );
  }

  private getFacilitySearchableTexts(
    facility: ExtendedFacilityInformation,
    hasAccessToAllProfiles: boolean
  ): string {
    if (!facility.FacilityInformation) {
      return '';
    }

    const texts = [];
    for (const field of facilityExtraFields) {
      const fieldValue = facility.FacilityInformation[field];
      if (fieldValue) {
        texts.push(fieldValue);
      }
    }
    if (hasAccessToAllProfiles) {
      const enegiaIdValue = facility.FacilityInformation.EnegiaId;
      if (enegiaIdValue) {
        texts.push(enegiaIdValue);
      }
    }

    return texts.join(', ');
  }
}
