import ReportTrendSettingsController from './report-trend-settings.controller';
import ReportTrendSettingsTemplate from 'raw-loader!./report-trend-settings.html';

export default {
  template: ReportTrendSettingsTemplate,
  controller: ReportTrendSettingsController,
  controllerAs: 'vm',
  bindings: {
    reportTypeChanged: '&?'
  }
};
