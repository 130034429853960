import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';

/* eslint-disable @typescript-eslint/no-explicit-any */

/** Comma separated integers with lenient whitespace and extra comma rules. */
export const COMMA_SEPARATED_INTEGERS: RegExp = /^[\s,]*\d+(\s*,[\s,]*\d+)*[\s,]*$/;

/** Any type that can reasonably represent a form. */
type Formish = Record<string, any>;

export type ControlsOf<T extends Formish> = {
  readonly [K in keyof T]: AbstractControl;
}

type ValuesOrControlsOf<T extends Formish> = {
  readonly [K in keyof T]: AbstractControl | T[K]
};

type ValidatorsOf<T extends Formish> = {
  readonly [K in keyof T]?: (ValidatorFn | ValidatorFn[])
}

/**
 * Creates a FormControl-record with typed default values.
 * Existing controls are preserved and their validators merged with new ones.
 */
export function formControlsFrom<T extends Formish>(
  model: ValuesOrControlsOf<T>,
  validators?: ValidatorsOf<T>
): ControlsOf<T> {
  const formControls: Record<string, AbstractControl> = {};

  for (const [key, value] of Object.entries(model)) {
    const control = value instanceof AbstractControl
      ? value
      : new UntypedFormControl(value);

    mergeValidators(control, validators?.[key]);
    formControls[key] = control;
  }

  return formControls as ControlsOf<T>;
}

/** Creates a FormGroup with typed default values. */
export function formGroupFrom<T extends Formish>(
  model: ValuesOrControlsOf<T>,
  validators?: ValidatorsOf<T>
): UntypedFormGroup {
  return new UntypedFormGroup(formControlsFrom(model, validators));
}

/** Merge validator(s) with control's existing validators. */
function mergeValidators(
  controls: AbstractControl,
  validators: ValidatorFn | ValidatorFn[]
): void {
  if (!validators) {
    return;
  }

  const validatorArray = Array.isArray(validators)
    ? validators
    : [validators];
  controls.setValidators(
    Validators.compose([...validatorArray, controls.validator])
  );
}
