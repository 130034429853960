import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const newsTabState: UIStateNg2 = {
  name: 'news',
  data: {
    translationKey: 'ADMIN.TABS.NEWS',
    auth: {
      roles: [Roles.NEWS_REPORTER]
    }
  },
};
