const $inject = [
  '$modalInstance', 'readerTypeId', 'groupIdentifier', 'cronExpression'
];

function ConfigurationMassImportController(
  $modalInstance, readerTypeId, groupIdentifier, cronExpression
) {
  const vm = this;
  vm.readerTypeId = readerTypeId;
  vm.groupIdentifier = groupIdentifier;
  vm.cronExpression = cronExpression;

  vm.cancel = function() {
    $modalInstance.close();
  };
}

ConfigurationMassImportController.$inject = $inject;

export default ConfigurationMassImportController;
