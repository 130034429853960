actionField.$inject = ['$compile'];

export function actionField($compile) {
  function linker(scope, element) {
    scope.$watch('config', () => {
      if (angular.isObject(scope.config)) {
        var component = scope.config.component;
        var template = `<${component} config="config" on-change="onChange"></${component}>`;
        element.html(template).show();
        $compile(element.contents())(scope);
      }
    });
  }

  return {
    restrict: 'E',
    scope: {
      config: '<',
      onChange: '<'
    },
    link: linker
  };
}
