import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { HookTypeDto } from '@enerkey/clients/alarm';
import { AlarmService } from '../../services/alarm.service';

@Pipe({ name: 'hookTypeName' })
export class HookTypeNamePipe implements PipeTransform {

  public constructor(
    private alarmService: AlarmService
  ) { }

  public transform(hookType: HookTypeDto | number): Observable<string> {
    return this.alarmService.getHookTypeName(hookType);
  }
}
