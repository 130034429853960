import templateModule from 'raw-loader!../templates/mrc-factor-input.html';

mrcFactorInput.$inject = ['$timeout', '$filter'];

export function mrcFactorInput($timeout, $filter) {
  function link(scope, element, attrs) {

      scope.showInput = false;
      scope.inputValid = false;
      scope.inputValue = undefined;
      var $inputField = undefined;

      function closePopup() {
        scope.showInput = false;
        if(angular.isDefined($inputField)) {
          $inputField.off('focus');
        }
        window.onclick = null;
      }

      scope.editValue = function() {
        scope.inputValue = angular.copy(scope.value);
        scope.showInput = true;
        scope.inputValid = !_.isNaN(scope.inputValue);
        $timeout(function(){
          $inputField = $("input[name='factorInput']");
          if($inputField) {
            $inputField.on('focus', function(e) {
                $(this).select();
            });
            $inputField.focus();
          }
          window.onclick = function (event) {
            if(scope.showInput) {
              closePopup();
              scope.$apply();
            }
          };
        },100);
      }
      scope.cancel = function() {
        closePopup();
      }
      scope.save = function() {
        scope.value = angular.copy(scope.inputValue);
        closePopup();
        scope.onChange({value:scope.value});
      }

      scope.$on('inputValueChanged', function(e, params) {
          if(params.name==='factorInput') {
            if(_.isString(params.value)) {
              params.value = parseFloat(params.value.replace(',', scope.mrcState.radix));
            }
            scope.inputValue = params.value;
            scope.inputValid = params.valid && !_.isNaN(scope.inputValue);
          }
      });

      element.on('$destroy', function() {
        if(scope.showInput) {
          closePopup();
        }
      });
  }

  return {
    restrict: 'E',
    scope: {
        value: "=",
        mrcState: "=",
        onChange: "&",
        popupAlignment: "@"
    },
    replace: true,
    template: templateModule,
    link: link
  };
}
