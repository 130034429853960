<ek-dropdown>
  <ek-dropdown-heading>
    {{ 'DROPDOWN.ACTIONS' | translate }}
  </ek-dropdown-heading>
  <ng-container *ngIf="isMassImportRole">
    <ek-dropdown-item
      [disabled]="!selectedFacilityIds.length"
      (click)="openFacilityInformation()"
    >
      {{ 'ADMIN.MASS_IMPORT.FACILITY' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      [disabled]="!selectedFacilityIds.length"
      (click)="openFacilityProperties()"
    >
      {{ 'ADMIN.MASS_IMPORT.FACILITY_PROPERTY' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      [disabled]="!selectedFacilityIds.length"
      (click)="openCO2Factors()"
    >
      {{ 'ADMIN.MASS_IMPORT.FACILITY_CO2' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      [disabled]="!selectedFacilityIds.length"
      (click)="openShareOfTempFixing()"
    >
      {{ 'ADMIN.MASS_IMPORT_SHAREOFTEMPERATUREFIXING' | translate }}
    </ek-dropdown-item>
  </ng-container>
  <ng-container *ngIf="isOtherActionsAllowed">
    <ek-dropdown-item
      [disabled]="!selectedFacilityIds.length"
      (click)="inspectMeters()"
    >
      {{ 'ADMIN.MASS_IMPORT.FACILITY_TO_METER' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      [disabled]="!selectedFacilityIds.length"
      (click)="modifyFacilitiesFromProfiles()"
    >
      {{ 'ADMIN.MASS_IMPORT.EDIT_FACILITIES_PROFILES' | translate }}
    </ek-dropdown-item>
  </ng-container>
  <ek-dropdown-item
    *ngIf="isContactManager"
    [disabled]="!selectedFacilityIds.length"
    (click)="addFacilitiesForCompany()"
  >
    {{ 'ADMIN.MASS_IMPORT.FACILITIES_FOR_COMPANIES' | translate }}
  </ek-dropdown-item>
  <ek-dropdown-item
    *ngIf="isContractManager"
    [disabled]="!selectedFacilityIds.length"
    (click)="addContractsForFacilities()"
  >
    {{ 'ADMIN.CONTRACTS.ADD_CONTRACTS' | translate }}
  </ek-dropdown-item>
  <ek-dropdown-item
    *ngIf="isContractManager"
    [disabled]="!selectedFacilityIds.length"
    (click)="addFacilitiesForSubscription()"
  >
    {{ 'ADMIN.BULK_MODIFY_FACILITIES.ADD_TO_SUBSCRIPTION' | translate }}
    </ek-dropdown-item>
  <ek-dropdown-item
      (click)="updateCompanies()"
      [disabled]="!selectedFacilityIds.length"
    >
      {{ 'ADMIN.BULK_MODIFY_COMPANY.MODIFY_COMPANY_BUTTON' | translate }}
  </ek-dropdown-item>
</ek-dropdown>
    