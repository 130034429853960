import MomentToStringModule                from '../../../../filters/moment-to-string';
import MrcConstantModule                   from '../../../mrc/constants';
import ManualQaServiceModule               from '../../services';
import ManualQaMeterHistoryComponent       from './manual-qa-meter-history-component';
import ManualQaMeterHistoryModalController from './manual-qa-meter-history-modal-controller';
import ManualQaMeterHistoryService         from './manual-qa-meter-history-service';

const dependencies = [ManualQaServiceModule.name, MrcConstantModule.name, MomentToStringModule.name];

const ManualQaMeterHistoryModule = angular
  .module('app.modules.manual-qa.components.manual-qa-meter-history', dependencies)
  .component('manualQaMeterHistory', ManualQaMeterHistoryComponent)
  .service('ManualQaMeterHistoryService', ManualQaMeterHistoryService)
  .controller('ManualQaMeterHistoryModalController', ManualQaMeterHistoryModalController)
;

export default ManualQaMeterHistoryModule;
