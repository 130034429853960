import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TranslateModule } from '@ngx-translate/core';

import { NgfTooltipModule } from '@enerkey/foundation-angular';

import { InvestigationStatusIconComponent } from './components/investigation-status-icon/investigation-status-icon.component';
import { InvestigationStatusComponent } from './components/investigation-status/investigation-status.component';
import { InvestigationStatusNamePipe } from './pipes/investigation-status-name.pipe';
import { TimeFrameSelectionComponent } from './components/time-frame-selection/time-frame-selection.component';
import { EmService } from '../../modules/energy-management/services/em.service';
import { ActionLevelPipe } from './pipes/action-level.pipe';
import { ActionTypeNamePipe } from './pipes/action-type-name.pipe';

type Injector = ng.auto.IInjectorService;

function emActionEditServiceFactory($injector: Injector): void {
  return $injector.get('emActionEditService');
}

function emServiceFactory($injector: angular.auto.IInjectorService): EmService {
  return $injector.get('emService');
}

@NgModule({
  imports: [
    CommonModule,
    NgfTooltipModule,
    DropDownsModule,
    TranslateModule,
  ],
  declarations: [
    InvestigationStatusIconComponent,
    InvestigationStatusComponent,
    InvestigationStatusNamePipe,
    TimeFrameSelectionComponent,
    ActionLevelPipe,
    ActionTypeNamePipe,
  ],
  providers: [
    { provide: 'emActionEditService', useFactory: emActionEditServiceFactory, deps: ['$injector'] },
    { provide: 'emService', useFactory: emServiceFactory, deps: ['$injector'] },
  ],
  exports: [
    InvestigationStatusIconComponent,
    InvestigationStatusComponent,
    InvestigationStatusNamePipe,
    TimeFrameSelectionComponent,
    ActionLevelPipe,
    ActionTypeNamePipe,
  ],
})
export class EnergyManagementSharedModule { }
