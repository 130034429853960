import { Component, Input } from '@angular/core';

@Component({
  selector: 'info-grid-item',
  templateUrl: './info-grid-item.component.html',
  styleUrls: ['./info-grid-item.component.scss']
})
export class InfoGridItemComponent {
  @Input() public data: string | number;
  @Input() public title: string;
}
