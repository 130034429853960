import { hasChanges } from '@enerkey/angular-utils';

import * as Configs from '../../constants/configs';

const $inject = ['$scope', '$timeout', 'ManualQaConsumptionStatsService'];

function ManualQaConsumptionStatsController($scope, $timeout, ManualQaConsumptionStatsService) {
  let vm = this;

  vm.constants = Configs;
  vm.statistics = [];
  vm.toggleCollapse = toggleCollapse;

  vm.$onChanges = onChanges;

  $scope.$on('manual-qa-data-source-changed-stats', calculateStatistics);

  /**
   * Formats reading values for statistics table when readings has been changed
   *
   * @param {Object} changes
   */
  function onChanges(changes) {
    if (hasChanges(changes, 'operationTimeFrame')) {
      calculateStatistics();
    }
  }

  /**
   * Calculates statistics for ui
   */
  function calculateStatistics() {
    $timeout(() => {
      vm.statistics = ManualQaConsumptionStatsService.getStatistics(
        vm.dataSource.data(),
        vm.operationTimeFrame,
        vm.isPreviewMode,
        vm.useComparisonPeriod
      );
    }, 0);
  }

  function toggleCollapse() {
    vm.showAll = !vm.showAll;
    vm.broadcastResize();
  }
}

ManualQaConsumptionStatsController.$inject = $inject;

export default ManualQaConsumptionStatsController;
