<div class="content-header">
  <ul class="tabs">
    <li
      *ngFor="let item of tabs"
      class="tabs__item"
      uiSrefActive="tabs__item--active"
    >
      <a
        class="tabs__item-link"
        [uiSref]="item.state"
      >
        {{ item.translationKey | translate }}
      </a>
    </li>
  </ul>

  <div
    *ngIf="topRightContent"
    class="content-right"
  >
    <ng-container *ngTemplateOutlet="topRightContent"></ng-container>
  </div>
</div>

<div ui-view="sustainability"></div>
