import 'jquery';

// expose jQuery as both '$' and 'jQuery'
import 'expose-loader?exposes=$,jQuery!jquery';

import 'jszip';
import 'expose-loader?exposes=JSZip!jszip'; // for kendo

import 'angular';

import '@progress/kendo-angular-intl/locales/fi/all';
