export default function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      item: '='
    },
    template: [
      '<li id="{{item.state}}" class="menu-item menu__item" aria-haspopup="false" >',
        '<span class="menu-item-extra-text" ng-if="item.extraTextKey" translate="{{item.extraTextKey}}">{{item.extraText}}</span>',
        '<a class="menu__link" ui-sref="{{item.state}}({{item.stateParams}})" ui-sref-active="is-active" ui-sref-opts="{reload: {{item.selected ? \'true\' : \'false\'}}, inherit: false}" translate="{{item.titleKey}}"></a>',
      '</li>'
    ].join(''),
  };
}
