import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  asyncScheduler,
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  scheduled,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { IntlService } from '@progress/kendo-angular-intl';
import { TranslateService } from '@ngx-translate/core';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { Quantities } from '@enerkey/clients/metering';
import {
  Report,
  Row,
  RowMetadata,
  RowUnit,
  RowUnitType,
  SustainabilityClient
} from '@enerkey/clients/sustainability';
import { anyOf, indicate, LoadingSubject, switchJoin } from '@enerkey/rxjs';

import { GriImportService, GriMeterItem } from '../../services/gri-import.service';
import { GriMetadataGridComponent } from '../gri-metadata-grid/gri-metadata-grid.component';
import { getGriRowValuesFromMetadata, GriEditorRow } from '../../models/gri-report-row';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { GriReportService } from '../../services/gri-report.service';
import { quantityTranslations } from '../../../../constants/quantity.constant';

@Component({
  selector: 'gri-data-import-modal',
  templateUrl: './gri-data-import-modal.component.html',
  styleUrls: ['./gri-data-import-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ size: 'full' })
export class GriDataImportModalComponent extends ModalBase implements OnInit, OnDestroy {

  @ViewChild(GriMetadataGridComponent) public readonly metadataGrid: GriMetadataGridComponent;
  public existingRow: GriEditorRow;
  public isValid: boolean = false;
  public currentReport: Report;

  public readonly categoryControl = new FormControl<number>(null, Validators.required);
  public readonly quantityControl = new FormControl<Quantities>(null);
  public readonly facilityControl = new FormControl<number[]>([]);
  public readonly metersControl = new FormControl<number[]>([]);

  public readonly metadata$: Observable<RowMetadata>;
  public readonly loading$: Observable<boolean>;

  public readonly categoryOptions$: Observable<ComboItem<number>[]>;
  public readonly quantities$: Observable<Quantities[]>;
  public readonly meters$: Observable<GriMeterItem[]>;
  public readonly mainMeters$: Observable<GriMeterItem[]>;

  public readonly dataQuantityId$: Observable<Quantities>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();
  private readonly _metadata$ = new ReplaySubject<RowMetadata>(1);
  private readonly _dataQuantityId$ = new Subject<Quantities>();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly susClient: SustainabilityClient,
    private readonly inltService: IntlService,
    private readonly griService: GriReportService,
    private readonly importService: GriImportService,
    private readonly translate: TranslateService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    super(currentModal);

    this.metadata$ = this._metadata$.asObservable();
    this.loading$ = anyOf(this.importService.loading$, this._loading$);
    this.dataQuantityId$ = this._dataQuantityId$.asObservable();

    this.metersControl.disable();

    this.mainMeters$ = this.importService.mainMeters$;

    this.quantities$ = this.importService.mainMeters$.pipe(
      map(meters => meters.sortBy('quantityId').unique('quantityId')),
      takeUntil(this._destroy$)
    );

    const quantity$ = this.quantityControl.valueChanges.pipe(takeUntil(this._destroy$));

    quantity$.subscribe(quantityId => this.metersControl[Number.isFinite(quantityId) ? 'enable' : 'disable']());

    this.meters$ = combineLatest({
      meters: this.importService.mainMeters$,
      quantityId: quantity$,
      facilityIds: this.facilityControl.valueChanges.pipe(startWith([]), takeUntil(this._destroy$)),
    }).pipe(
      map(({ meters, quantityId, facilityIds }) => meters.filter(
        m => m.quantityId === quantityId && (facilityIds.length === 0 || facilityIds.includes(m.facilityId))
      )),
      takeUntil(this._destroy$)
    );

    this.categoryOptions$ = this.griService.categoryOptions$;

    // pick the first available qty by default
    scheduled(this.quantities$.pipe(take(1)), asyncScheduler).subscribe({
      next: quantities => this.quantityControl.setValue(quantities[0])
    });

    // for clearing old search result
    combineLatest([
      this.quantityControl.valueChanges,
      this.facilityControl.valueChanges,
      this.metersControl.valueChanges,
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      this.resetMetadata();
    });
  }

  public ngOnInit(): void {
    if (this.existingRow?.metadata) {
      this._metadata$.next(this.existingRow.metadata);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._metadata$.complete();
    this._dataQuantityId$.complete();
  }

  public createRows(): void {
    const controls = Object.values(this.metadataGrid.formControls);

    if (!this.categoryControl.valid ||
      !this.categoryControl.value ||
      !this.metadataGrid.sharedUnitControl.valid ||
      controls.some(c => !c.valid)) {
      return;
    }

    const unitsToCreate = controls
      .mapFilter(c => c.value, v => !!v)
      .toMap(factor => factor.facilityId, factor => new RowUnit({
        co2Eq: factor.unitScale,
        co2Factor: factor.value,
        name:
          `${this.translate.instant(quantityTranslations[factor.quantityId as Quantities])} (${factor.facilityName})`,
        quantityId: factor.quantityId,
        unit: factor.unit,
        source: `${factor.facilityName} - ${this.inltService.formatDate(factor.from)} (ID: ${factor.facilityId})`,
        reportId: this.currentReport.id,
        rowUnitType: RowUnitType.Facility
      }))
      .getEntries();

    const createdUnits$ = this.griService.report$.pipe(
      filter(report => !!report),
      take(1),
      switchMap(report => {
        for (const [, unit] of unitsToCreate) {
          unit.year = report.year;
        }

        return this.susClient.createRowUnits(
          this.currentReport.profileId,
          unitsToCreate.map(x => x[1])
        ).pipe(
          indicate(this._loading$),
          takeUntil(this._destroy$)
        );
      })
    );

    createdUnits$.pipe(
      switchJoin(() => this.griService.unitsChanged().pipe(take(1))),
      switchMap(([createdUnits, allUnits]) => {
        const unitIdByFacility = createdUnits
          .map((u, i) => [u, i] as const)
          .toRecord(([, i]) => unitsToCreate[i][0], ([u]) => u.id);

        const metas = this.metadataGrid.getAsMultipleMetadata();

        const rows = metas.map(item => new Row({
          categoryId: this.categoryControl.value,
          metadata: item.meta,
          rowUnitId: unitIdByFacility[item.facilityId] ?? this.metadataGrid.sharedUnitControl.value,
          facilityId: item.facilityId,
          values: getGriRowValuesFromMetadata(item.meta),
          description: `${item.facilityName}`,
          reportId: this.currentReport.id,
        }));

        return forkJoin({
          categories: this.griService.categories$.pipe(take(1)),
          rows: of(rows),
          units: of(allUnits),
        });
      }),
      indicate(this._loading$)
    ).subscribe(({ rows, categories, units }) => {
      this.griService.addRows(rows.map(row => new GriEditorRow(
        row,
        categories.find(c => c.id === row.categoryId),
        units.find(u => u.id === row.rowUnitId)
      )));
      super.closeModal();
    });
  }

  public searchConsumptions(): void {
    const meterIds = this.metersControl.value;
    const quantityId = this.quantityControl.value;

    if (meterIds.length && quantityId) {
      forkJoin({
        meters: this.importService.mainMeters$.pipe(take(1)),
        report: this.griService.report$.pipe(filter(r => !!r), take(1))
      }).pipe(
        switchMap(({ meters, report }) => this.importService.getMetadata(
          report.year,
          meters.filter(m => meterIds.includes(m.id)),
          quantityId,
          this.existingRow?.metadata ?? null
        )),
        takeUntil(this._destroy$)
      ).subscribe(result => {
        this._dataQuantityId$.next(quantityId);
        this._metadata$.next(result);
      });
    } else {
      this.resetMetadata();
    }
  }

  public dismiss(): void {
    super.dismissModal();
  }

  public updateValidator(event: boolean): void {
    this.isValid = event;
    this.changeDetector.detectChanges();
  }

  private resetMetadata(): void {
    this._metadata$.next(null);
    this._dataQuantityId$.next(null);
  }
}
