import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CheckableSettings, CheckedState, NodeClickEvent, TreeViewComponent } from '@progress/kendo-angular-treeview';
import {
  ColumnComponent,
  ColumnBase as GridColumnBase,
  GridComponent,
  FilterService as GridFilterService,
} from '@progress/kendo-angular-grid';
import { ColumnInfoService } from '@progress/kendo-angular-grid/common/column-info.service';
import {
  ColumnBase as TreeListColumnBase,
  TreeListComponent,
  FilterService as TreeListFilterService
} from '@progress/kendo-angular-treelist';
import {
  ColumnInfoService as TreeListColumnInfoService
} from '@progress/kendo-angular-treelist/common/column-info.service';

import { removeGridFilters, TreeItemLookupOf } from '@enerkey/ts-utils';

import { EkDropdownComponent } from '../../../ek-dropdown/ek-dropdown/ek-dropdown.component';

type ColumnBase = GridColumnBase | TreeListColumnBase;

interface TreeViewColumn {
  text: string;
  column: ColumnBase;
  children: TreeViewColumn[];
  checked: boolean;
}

/** Sets column visibility and returns list of columns that were hidden. */
function* setColumnVisibility(items: TreeViewColumn[]): Iterable<string> {
  for (const item of items) {
    // columns with items are always column groups, and their visibility is defined by children
    if (Array.hasItems(item.children)) {
      for (const hiddenColumn of setColumnVisibility(item.children)) {
        yield hiddenColumn;
      }
    } else {
      const isVisible = item.column.hidden !== true;
      const shouldBeVisible = item.checked;

      if (isVisible !== shouldBeVisible) {
        // return column only if its visiblity changed from visible to hidden
        if (isVisible && item.column instanceof ColumnComponent) {
          yield item.column.field;
        }

        item.column.hidden = !shouldBeVisible;
      }
    }
  }
}

/**
 * Set item checked state, or state of its' children if it has any.
 */
function setTreeViewItemState(item: TreeViewColumn, checked: boolean): void {
  if (Array.hasItems(item.children)) {
    for (const child of item.children) {
      setTreeViewItemState(child, checked);
    }
  } else {
    item.checked = checked;
  }
}

/**
 * If there are no children, returns current visibility. If children have mixed values,
 * returns indeterminate.
 */
function getCheckedState(checked: boolean, children: TreeViewColumn[]): CheckedState {
  if (!Array.hasItems(children)) {
    return checked ? 'checked' : 'none';
  }

  let aggregate: CheckedState = null;

  for (const child of children) {
    const state = getCheckedState(child.checked, child.children);

    if (!aggregate) {
      aggregate = state;
    } else if (aggregate !== state) {
      return 'indeterminate';
    }
  }

  return aggregate;
}

/**
 * Use in `kendoGridToolbarTemplate`. Columns with `locked` or `includeInChooser` are excluded.
 *
 * Relies on internal undocumented Kendo hyrskytin ColumnInfoService.
 */
@Component({
  selector: 'kendo-grid-grouped-column-chooser',
  templateUrl: './grouped-column-chooser.component.html',
  styleUrls: ['./grouped-column-chooser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedColumnChooserComponent implements OnDestroy {

  @Input() public showLockedColumns: boolean = false;

  public readonly checkableSettings: CheckableSettings = {
    enabled: true,
    mode: 'multiple',
    checkChildren: true,
    checkParents: true,
    checkOnClick: false, // done manually, see nodeClicked()
  };

  public treeViewColumns: TreeViewColumn[] = [];

  /** `this`-scope isn't captured correctly if this isn't a property */
  public readonly getCheckedState: (item: TreeViewColumn) => CheckedState;

  private allColumns: ColumnBase[] = [];
  private shouldApplyChanges: boolean = false;

  private readonly _subscription: Subscription;

  private readonly grid: GridComponent | TreeListComponent;
  private readonly columnInfoService: ColumnInfoService | TreeListColumnInfoService;
  private readonly kendoFilterService: GridFilterService | TreeListFilterService;

  @ViewChild(EkDropdownComponent, { static: true })
  private readonly dropdown: EkDropdownComponent;

  @ViewChild(TreeViewComponent, { static: true })
  private readonly treeview: TreeViewComponent;

  public constructor( // eslint-disable-next-line @typescript-eslint/indent
    @Optional() gridComponent: GridComponent,
    @Optional() treeListComponent: TreeListComponent,
    @Optional() gridFilterService: GridFilterService,
    @Optional() treeListFilterService: TreeListFilterService
  ) {
    this.grid = gridComponent ?? treeListComponent;
    this.columnInfoService = (this.grid as any)?.columnInfoService;
    this.kendoFilterService = gridFilterService ?? treeListFilterService;
    this.validateDependencies();

    this.getCheckedState = (item: TreeViewColumn) => getCheckedState(item.checked, item.children);

    // Hide and reset state so we do not need to do tracking for columns with *ngIf
    this._subscription = this.columnInfoService.columnsContainer.changes.subscribe({ next: () => this.onCancel() });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  /** Update visibility of columns if they have no children. */
  public onApply(): void {
    this.shouldApplyChanges = true;
    this.close();
  }

  public onCancel(): void {
    this.shouldApplyChanges = false; // don't apply if closed manually
    this.close();
  }

  public nodeClicked(event: NodeClickEvent): void {
    if (event.type === 'click') {
      const item = event.item;

      // Expand/Collapse tree nodes, toggle checked on non-tree nodes
      if (Array.hasItems((item.dataItem as TreeViewColumn).children)) {
        const expanded = this.treeview.isExpanded(item, item.index);

        if (expanded) {
          this.treeview.collapseNode(item, item.index);
        } else {
          this.treeview.expandNode(item, item.index);
        }
      } else {
        this.handleChecking({ item });
      }
    }
  }

  public handleChecking(lookup: TreeItemLookupOf<TreeViewColumn>): void {
    const item = lookup.item.dataItem;
    const state = getCheckedState(item.checked, item.children);

    setTreeViewItemState(item, state !== 'checked');
  }

  public visibleChanged(visible: boolean): void {
    if (visible) {
      // Init columns every time popup appears in case columns changed with *ngIf
      this.initialize();
    } else {
      if (this.shouldApplyChanges) {
        this.applyChanges();
      }
      this.onClose();
    }
  }

  private initialize(): void {
    // Cache columns to make updating their visibility simpler later
    this.allColumns = this.getFlatGridColumns();
    this.treeViewColumns = this.constructTree(this.allColumns.toGroupsBy('parent'), null);
    this.shouldApplyChanges = true;
  }

  /**
   * Creates the model for treeview recursively.
   * @param grouped all columns, grouped by their parent
   * @param currentParent current parent group, or `null` for root level columns/groups
   */
  private constructTree(
    grouped: Map<ColumnBase, ColumnBase[]>,
    currentParent: ColumnBase
  ): TreeViewColumn[] {
    const children = grouped.get(currentParent);

    // This parent has no children, or they are all excluded from picker
    if (!Array.hasItems(children)) {
      return [];
    }

    return children.map<TreeViewColumn>(child => {
      // Create children before returning as we need them for checkbox state
      const nestedChildren = this.constructTree(grouped, child);

      return {
        column: child,
        text: child.title ?? (child as ColumnComponent).field,
        checked: getCheckedState(!child.hidden, nestedChildren) !== 'none',
        children: nestedChildren
      };
    });
  }

  private getFlatGridColumns(): ColumnBase[] {
    const columns = this.columnInfoService.leafNamedColumns;
    return this.flattenColumns(
      (columns as ColumnBase[]).filter(
        (col: ColumnBase) => col.includeInChooser && (!col.isLocked || this.showLockedColumns)
      )
    );
  }

  /**
   * Returns the parameter columns and their parents recursively.
   * The order should be sensible but is not guaranteed.
   */
  private flattenColumns(columns: ColumnBase[]): ColumnBase[] {
    const value: ColumnBase[] = [];

    for (const column of columns) {
      if (column.parent) {
        value.push(...this.flattenColumns([column.parent]));
      }
      value.push(column);
    }

    return value.unique();
  }

  private close(): void {
    this.dropdown.hide();
    this.onClose();
  }

  private onClose(): void {
    this.allColumns = [];
    this.treeViewColumns = [];
    this.shouldApplyChanges = false;
  }

  private applyChanges(): void {
    const hiddenColumns = Array.from(setColumnVisibility(this.treeViewColumns));

    // Remove filters from columns that were hidden
    if (this.grid.filter && Array.hasItems(hiddenColumns)) {
      const filter = this.grid.filter;
      removeGridFilters(filter, f => hiddenColumns.includes(f.field as string));
      this.kendoFilterService.filter(filter);
    }

    this.columnInfoService.changeVisibility(this.allColumns);
  }

  private validateDependencies(): void {
    if (!this.grid) {
      throw new Error('Grid or TreeList component is not available');
    }
    if (!this.columnInfoService) {
      throw new Error('ColumnInfoService is not available');
    }
    if (!this.kendoFilterService) {
      throw new Error('FilterService is not available');
    }
  }
}
