import {
  AnalyticStatus,
  AnalyticsType,
  ElectricityDemandResponseData,
  ElectricityDemandResponseInsight,
  HeatBalanceData,
  HeatBalanceInsight,
  HeatingCoolingData,
  HeatingCoolingInsight,
  HeatingOptimizationData,
  HeatingOptimizationInsight,
  HeatingPowerData,
  HeatingPowerInsight,
  SolarPowerInsight,
  VentilationData,
  VentilationInsight,
} from '@enerkey/clients/ines';

import {
  ElectricityDemandResponseInsightWithCurrency,
  HeatBalanceInsightWithCurrency,
  HeatingCoolingInsightWithCurrency,
  HeatingOptimizationInsightWithCurrency,
  HeatingPowerInsightWithCurrency,
  SolarPowerInsightWithCurrency,
  VentilationInsightWithCurrency
} from './models/insight-model';

/**
 * Rounds given 'value' to interval by log 10.
 *
 * @param value value to round
 * @param roundUp whether to round away from zero
 * @param soft whether to round to lower interval less aggressively
 *
 * @example
 * roundAsIntervals(172, false) === 100
 * roundAsIntervals(1272, false) === 1000
 * roundAsIntervals(-472, true) === -500
 */
export function roundAsIntervals(
  value: number | undefined | null,
  roundUp: boolean = false
): number | null {
  if (!Number.isFinite(value)) {
    return null;
  }
  if (value === 0) {
    return 0;
  }
  const resultfn = roundUp ? Math.ceil : Math.floor;

  const abs = Math.abs(value);
  const log = Math.log10(abs);

  // 0...1 edge case
  if (log <= 0) {
    return resultfn(value);
  }

  const roundedLog = Math.round(log);

  let interval = Math.pow(10, roundedLog - 1);

  // ex: under 100 rounded to 10's, 100-300 rounded to 50's, 300-1000 rounded to 100's
  if (roundedLog < log) {
    interval *= 5;
  }

  return Math.sign(value) * interval * resultfn(abs / interval);
}

export function getAnalyticStatusColor(value: AnalyticStatus): string {
  switch (value) {
    case AnalyticStatus.InvestigationRequired:
      return '#cd1f3c';
    case AnalyticStatus.UnderInvestigation:
      return '#ffa500';
    case AnalyticStatus.InvestigationDone:
      return '#03b000';
    case AnalyticStatus.NoInvestigationRequired:
    default:
      return '#f0f0f0';
  }
}

type InsightByType = {
  [AnalyticsType.Ventilation]: VentilationInsight,
  [AnalyticsType.HeatBalance]: HeatBalanceInsight,
  [AnalyticsType.HeatingPower]: HeatingPowerInsight,
  [AnalyticsType.ElectricityDemandResponse]: ElectricityDemandResponseInsight,
  [AnalyticsType.SolarPower]: SolarPowerInsight,
  [AnalyticsType.HeatingOptimization]: HeatingOptimizationInsight,
  [AnalyticsType.HeatingCooling]: HeatingCoolingInsight
};

type InsightByTypeWithCurrency = {
  [AnalyticsType.Ventilation]: VentilationInsightWithCurrency,
  [AnalyticsType.HeatBalance]: HeatBalanceInsightWithCurrency,
  [AnalyticsType.HeatingPower]: HeatingPowerInsightWithCurrency,
  [AnalyticsType.ElectricityDemandResponse]: ElectricityDemandResponseInsightWithCurrency,
  [AnalyticsType.SolarPower]: SolarPowerInsightWithCurrency,
  [AnalyticsType.HeatingOptimization]: HeatingOptimizationInsightWithCurrency,
  [AnalyticsType.HeatingCooling]: HeatingCoolingInsightWithCurrency
}

export type InsightSavings = { EUR: number; CO2: number; MWh: number; };

export function getInsightSavings<K extends keyof(InsightByTypeWithCurrency | InsightByType)>(
  insight: InsightByTypeWithCurrency[K] | InsightByType[K]): InsightSavings | null {
  const data = insight.data;

  if (!data) {
    return null;
  }

  if (data instanceof VentilationData) {
    return {
      EUR: data.totalMonetarySavings,
      MWh: data.totalConsumptionSavings,
      CO2: (data.heatingSavingsCO2InKg ?? 0) + (data.electricitySavingsCO2InKg ?? 0),
    };
  }

  if (data instanceof HeatBalanceData) {
    return {
      EUR: (data.baseLoadRefDiffEur ?? 0) + (data.ventilationRefDiffEur ?? 0),
      MWh: (data.baseLoadRefDiffMwh ?? 0) + (data.ventilationRefDiffMwh ?? 0),
      CO2: null,
    };
  }

  if (data instanceof HeatingPowerData) {
    return {
      EUR: data.powerDemandSavingsEur,
      MWh: data.powerDemandSavingsMwh,
      CO2: null,
    };
  }

  if (data instanceof ElectricityDemandResponseData) {
    return {
      EUR: data.eleDrPotentialEur,
      MWh: null,
      CO2: null,
    };
  }

  if (data instanceof HeatingOptimizationData) {
    return {
      EUR: data.heatingMonetarySavings,
      MWh: data.heatingSavingsInMwh,
      CO2: null,
    };
  }

  if (data instanceof HeatingCoolingData) {
    return {
      EUR: data.totalMonetarySavings,
      MWh: data.totalConsumptionSavings,
      CO2: null,
    };
  }

  return null;
}

/** Behavior depends on insight type. Requires class instances from swagger client to work. */
export function insightHasSavings<K extends keyof(InsightByTypeWithCurrency | InsightByType)>(
  insight: InsightByTypeWithCurrency[K] | InsightByType[K]): boolean {
  const savings = getInsightSavings(insight);
  return !!(savings && (savings.EUR || savings.MWh || savings.CO2));
}
