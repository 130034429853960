import { Directive } from '@angular/core';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

@Directive({
  selector: 'kendo-numerictextbox[integerInput]'
})
export class IntegerInputDirective {
  public constructor(
    private readonly numericTextBox: NumericTextBoxComponent
  ) {
    this.numericTextBox.autoCorrect = true;
    this.numericTextBox.decimals = 0;
    this.numericTextBox.format = '#:n0';
    this.numericTextBox.step = 1;
    this.numericTextBox.spinners = false;
  }
}
