/* eslint-disable @typescript-eslint/no-explicit-any */

export type CompareObjByKeysSelector<X, Y> = (keyof X & keyof Y) & (string | number);

export type ExtractObjByKeysSelector<T> = (keyof T) & (string | number);

export function integerKeys(
  obj: Record<keyof any, any>
): number[] {
  return Object.keys(obj).mapFilter(
    key => +key, // parseInt can lead to unwanted false positives, ie: "10px" is parsed to 10
    key => Number.isInteger(key)
  );
}

export function integerEntries<T>(
  obj: Record<keyof any, T>
): [number, T][] {
  return Object.entries(obj).mapFilter<[number, T]>(
    ([key, value]) => ([+key, value]),
    ([key, _value]) => Number.isInteger(key)
  );
}

export function compareObjByKeys<X extends Record<keyof any, any>, Y extends Record<keyof any, any>>(
  obj1: X,
  obj2: Y,
  ...selectors: [CompareObjByKeysSelector<X, Y>, ...CompareObjByKeysSelector<X, Y>[]]
): boolean {

  if (!selectors.length) {
    return true;
  }

  return selectors.every(selector => obj1[selector] === obj2[selector]);
}

export function extractObjByKeys<T extends Record<keyof any, any>>(
  obj: T,
  ...selectors: [ExtractObjByKeysSelector<T>, ...ExtractObjByKeysSelector<T>[]]
): T {
  return Object.assign({}, ...selectors.map(prop => ({ [prop]: obj[prop] })));
}
