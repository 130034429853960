import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { UserManagementClient } from '@enerkey/clients/user-management';
import { Configuration, ConfigurationControlClient, ReadingStatus, Terminal }
  from '@enerkey/clients/configuration-control';
import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { Meter, MeteringClient } from '@enerkey/clients/metering';

import { MeterInterfaceService } from '../../../services/meter-interface-service';
import { ToasterService } from '../../services/toaster.service';
import { TerminalMeter } from './terminal-meter';

@Component({
  selector: 'terminal-status',
  templateUrl: './terminal-status.component.html',
  styleUrls: ['./terminal-status.component.scss']
})
export class TerminalStatusComponent implements OnInit {
  @Input() public terminalName: string;
  @Input() public terminal: Terminal;
  public terminalMeters$: Observable<TerminalMeter[]>;
  public terminalReadingStatusIndicator: string;
  public terminalReadingStatusText: string;

  public constructor(
    public readonly translate: TranslateService,
    private readonly meterInterfaceService: MeterInterfaceService,
    private readonly configurationControlClient: ConfigurationControlClient,
    private readonly meteringClient: MeteringClient,
    private readonly facilityClient: FacilityClient,
    private readonly toasterService: ToasterService,
    private readonly userManagementClient: UserManagementClient
  ) {
  }

  public ngOnInit(): void {
    this.terminalReadingStatusIndicator =
      this.getTerminalReadingStatusIndicator(this.terminal?.terminalStatus?.readingStatus);
    this.terminalReadingStatusText =
      this.getTerminalReadingStatusText(this.terminal?.terminalStatus?.readingStatus);
    this.terminalMeters$ = this.getTerminalMeters();
  }

  public onShowMeterInterfaceStatus(terminalMeter: TerminalMeter): void {
    this.meterInterfaceService.getModal(
      terminalMeter.facilityName,
      terminalMeter.meterId,
      terminalMeter.meterName
    );
  }

  public getTerminalMeters(): Observable<TerminalMeter[]> {
    const allMeterIds = this.terminal.terminalMeters.map(tm => tm.meterId);
    return this.meteringClient.getMeters(allMeterIds)
      .pipe(
        switchMap(meters => {
          const allFacilityIds = meters.map(meter => meter.reportingObjectId).unique();
          return forkJoin([
            of(meters),
            this.facilityClient.getFacilities(allFacilityIds),
            this.configurationControlClient.getConfigurationsForMeterIds(allMeterIds),
            this.userManagementClient.getTenantIdNamePairs()
          ]);
        }),
        map(([meters, facilities, configurations, tenants]) => {
          const allTerminalMeters: TerminalMeter[] = [];

          this.terminal.terminalMeters.forEach(input => {
            const currentMeter: Meter = meters.find(meter => meter.id === input.meterId) ?
              meters.find(meter => meter.id === input.meterId) :
              new Meter();
            const currentFacility: Facility = facilities.find(facility =>
              facility.id === currentMeter.reportingObjectId);
            const currentConfiguration: Configuration = Array.hasItems(configurations) ?
              configurations.find(config => config.meterId === currentMeter.id) :
              null;

            const terminalMeter = new TerminalMeter(input.meterId, input.ordinal);
            const currentTenant = tenants.find(tenant => tenant.id === input.tenantId);

            if (currentMeter) {
              terminalMeter.meterName = currentMeter.name;
              terminalMeter.externalMeterId = '-';
            }
            if (currentFacility) {
              terminalMeter.enegiaId = currentFacility.enegiaId;
              terminalMeter.facilityName = currentFacility.displayName;
            }
            if (currentConfiguration) {
              const readingStatus = currentConfiguration.readingStatus;
              const statusText = this.getTerminalReadingStatusText(readingStatus);
              const statusIndicator = this.getTerminalReadingStatusIndicator(readingStatus);
              terminalMeter.setConfigurationDetails(currentConfiguration, statusText, statusIndicator);
            }
            terminalMeter.tenantName = currentTenant?.name ?? '';
            allTerminalMeters.push(terminalMeter);
          });
          return allTerminalMeters.sortBy(tm => tm.ordinal);
        }),
        catchError(() => {
          this.handleError();
          return of([]);
        })
      );
  }

  private getTerminalReadingStatusText(readingStatus: ReadingStatus): string {
    const statusText = this.meterInterfaceService.getReadingStatusText(readingStatus);
    return statusText;
  }

  private getTerminalReadingStatusIndicator(readingStatus: ReadingStatus): string {
    const statusIndicator = 'indicator indicator--' +
      `${this.meterInterfaceService.getReadingStatusIndicator(readingStatus)}-text`;
    return statusIndicator;
  }

  private handleError(): void {
    this.toasterService.error('ADMIN.TERMINAL_NOT_FOUND.ERROR', '');
  }
}
