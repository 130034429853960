const $inject = ['ManualQaTabsService', 'appStatusService'];

class ManualQaIndexController {
  constructor(ManualQaTabsService, appStatusService) {
    this.ManualQaTabsService = ManualQaTabsService;
    this.appStatusService = appStatusService;

    this.tabItems = [];
    this.isMobile = false;
  }

  $onInit() {
    this.tabItems = this.ManualQaTabsService.getTabItems();
    this.isMobile = this.appStatusService.isMobile;
  }
}

ManualQaIndexController.$inject = $inject;

export default ManualQaIndexController;
