import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/core';
import { from, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

import { SettingsClient, SwaggerException } from '@enerkey/clients/settings';
import { IProfileViewModel } from '@enerkey/clients/user-management';

import { UserService } from '../../services/user-service';
import { ToasterService } from './toaster.service';
import { TelemetryService } from '../../services/telemetry.service';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  /** Emits initial profile load and profile changes with `shareReplay`. */
  public readonly profile$: Observable<IProfileViewModel>;

  /** Emits current profile ID. */
  public readonly profileId$: Observable<number>;

  /** Emits profile changes only, see `profile$`. */
  public readonly profileChange$: Observable<IProfileViewModel>;

  private readonly _profileChange$ = new Subject<IProfileViewModel>();

  public constructor(
    private readonly userService: UserService,
    private readonly stateService: StateService,
    private readonly toasterService: ToasterService,
    private readonly settingsClient: SettingsClient,
    private readonly telemetryService: TelemetryService
  ) {
    this.setInitialProfile();

    this.profile$ = merge(
      this._profileChange$,
      from(this.userService.isInitializedWithInitialProfileAsync())
    ).pipe(
      shareReplay(1)
    );

    this.profileId$ = this.profile$.pipe(
      map(profile => profile.id),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.profileChange$ = this._profileChange$.asObservable();
  }

  public changeProfile(profile: IProfileViewModel): void {
    this.settingsClient.setCurrentProfile(profile.id).subscribe({
      next: () => {
        this.userService.setCurrentProfile(profile);
        this.toasterService.info({
          key: 'SETTINGS.PROFILE_HAS_BEEN_CHANGED_NOTIFICATION',
          params: { profileName: profile.name },
        });
        this._profileChange$.next(profile);
        this.stateService.go('dashboard', null, { reload: true });
      }
    });
  }

  private setInitialProfile(): void {
    this.settingsClient.getCurrentProfile().pipe(
      switchMap(profileId => this.userService.setInitialProfileIdAsync(profileId > 0 ? profileId : 0))
    ).subscribe({
      next: currentProfile => {
        if (currentProfile?.id === 0) {
          this.toasterService.warning('NO_PROFILE_SET');
        }
      },
      error: (err: SwaggerException) => {
        this.toasterService.generalError('LOAD', 'PROFILES');
        this.telemetryService.trackError(err, 'Failed to load logged in user profiles');
      }
    });
  }
}
