import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { UserManagementClient, UserNotificationDto, UserViewModel } from '@enerkey/clients/user-management';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

type NotificationType =
  'VERIFICATIONMAILSENT' |
  'USERLOGIN' |
  'USERMODIFIED' |
  'USERPASSWORDCHANGED' |
  'USERSPROFILESBULKMODIFIED' |
  'USERSENDDATEBULKMODIFIED' |
  'USERSROLESBULKMODIFIED';

const comboItems: ComboItem<NotificationType>[] = [
  { value: null, text: 'ADMIN.NOTIFICATION_LOG_VIEW_ALL' },
  { value: 'VERIFICATIONMAILSENT', text: 'VERIFICATIONMAILSENT' },
  { value: 'USERLOGIN', text: 'USERLOGIN' },
  { value: 'USERMODIFIED', text: 'USERMODIFIED' },
  { value: 'USERPASSWORDCHANGED', text: 'USERPASSWORDCHANGED' },
  { value: 'USERSPROFILESBULKMODIFIED', text: 'USERSPROFILESBULKMODIFIED' },
  { value: 'USERSENDDATEBULKMODIFIED', text: 'USERSENDDATEBULKMODIFIED' },
  { value: 'USERSROLESBULKMODIFIED', text: 'USERSROLESBULKMODIFIED' },
];

@Component({
  selector: 'user-action-log-modal',
  templateUrl: './user-action-log-modal.component.html',
  styleUrls: ['./user-action-log-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ windowClass: 'fixed-height modal-dialog-scrollable' })
export class UserActionLogModalComponent extends ModalBase<void> implements OnInit, OnDestroy {

  public readonly actionList: ComboItem<string>[] = comboItems;
  public readonly loading$: Observable<boolean>;

  public actionLog$: Observable<UserNotificationDto[]>;

  public selectedUser: UserViewModel;
  public readonly actionControl = new UntypedFormControl(null);

  private readonly _loading$ = new LoadingSubject();
  private readonly _cancel$ = new Subject<void>();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly userManagementClient: UserManagementClient
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
  }

  public ngOnInit(): void {
    this.actionLog$ = this.actionControl.valueChanges.pipe(
      startWith<string>(null),
      switchMap(value => this.userManagementClient.getUserNotifications(
        this.selectedUser.id,
        value ?? undefined,
        500
      ).pipe(indicate(this._loading$))),
      takeUntil(this._cancel$)
    );
  }

  public ngOnDestroy(): void {
    this._cancel$.next();
    this._cancel$.complete();
    this._loading$.complete();
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
