import { IAngularStatic } from 'angular';

import { ReadingStatus } from '@enerkey/clients/configuration-control';

declare const angular: IAngularStatic;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export class PropertyService {
  public getReaderBaseProperties(jsonSchema: any): any[] {
    const readerBaseProperties = [];
    const baseProperties = jsonSchema.baseProperties;
    for (const baseProp in baseProperties) {
      if (baseProp !== 'tenantId' && Object.prototype.hasOwnProperty.call(baseProperties, baseProp)) {
        const uiBaseProperty: any = {
          // This check is needed here until reader base schema has terminalName property
          name: baseProp === 'meterGateway' ? 'terminalName' : baseProp,
          type: baseProperties[baseProp].type,
          translation: jsonSchema.propertyTranslations[baseProp],
          isRequired: baseProp === 'meterGateway' ? false : -1 < jsonSchema.required.indexOf(baseProp),
          description: angular.isDefined(jsonSchema.descriptions[baseProp]) ? jsonSchema.descriptions[baseProp] : '',
          value: null
        };
        readerBaseProperties.push(uiBaseProperty);
      }
    }
    return readerBaseProperties;
  }

  public getReaderSpecificProperties(jsonSchema: any): any[] {
    const readerSpecificProperties: any[] = [];
    const temp: any[] = [];
    const specificProperties = jsonSchema.specificProperties;
    for (const specificProp in specificProperties) {
      if (Object.prototype.hasOwnProperty.call(specificProperties, specificProp)) {
        const uiSpecificProperty: any = {
          name: specificProp,
          type: angular.isArray(specificProperties[specificProp].type) ?
            specificProperties[specificProp].type[0] :
            specificProperties[specificProp].type,
          translation: jsonSchema.propertyTranslations[specificProp],
          isRequired: -1 < jsonSchema.required.indexOf(specificProp),
          description: angular.isDefined(jsonSchema.descriptions[specificProp]) ?
            jsonSchema.descriptions[specificProp] :
            '',
          value: null
        };
        if (uiSpecificProperty.type !== 'boolean') {
          readerSpecificProperties.push(uiSpecificProperty);
        } else {
          uiSpecificProperty.value = 0;
          temp.push(uiSpecificProperty);
        }
      }
    }

    temp.forEach(property => {
      readerSpecificProperties.push(property);
    });

    return readerSpecificProperties;
  }

  // Extracts base and reader specific properties from configuration
  public extractBaseAndSpecificPropertyObjects(jsonSchema: any, configuration: any): any {
    const baseProperties = this.getReaderBaseProperties(jsonSchema);
    angular.forEach(baseProperties, property => {
      property.value = configuration[property.name];
    });
    const specificProperties = this.getReaderSpecificProperties(jsonSchema);
    angular.forEach(specificProperties, property => {
      property.value = configuration[property.name];
    });
    return {
      baseProperties, specificProperties
    };
  }

  public isFailStatus(statusCode: ReadingStatus): boolean {
    return (statusCode !== ReadingStatus.Success && statusCode !== ReadingStatus.NotRun);
  }

  public isPropertyValid(property: any): boolean {
    let isValid = true;
    const value = property.value;
    const isRequired = property.isRequired;
    const type = property.type;

    if (isRequired && type === 'integer' && value === null) {
      isValid = false;
    }

    if (isRequired && type === 'string' && (value === null || !(value.length > 0))) {
      isValid = false;
    }

    if (isRequired && type === 'boolean' && value === null) {
      isValid = false;
    }
    return isValid;
  }

  public isBasePropertiesGroupValid(baseProperties: any[]): boolean {
    return baseProperties.every(property => this.isPropertyValid(property));
  }

  public isSpecificPropertiesGroupValid(specificProperties: any[]): boolean {
    return specificProperties.every(property => this.isPropertyValid(property));
  }
}

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-explicit-any */
