import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { ConfigurationControlClient, Terminal } from '@enerkey/clients/configuration-control';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ToasterService } from '../../services/toaster.service';
import { ComboItem } from '../../ek-inputs/ek-combo/ek-combo.component';

@Component({
  selector: 'terminal-add-meter',
  templateUrl: './terminal-add-meter.component.html',
  styleUrls: ['./terminal-add-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminalAddMeterComponent extends ModalBase implements OnDestroy {
  public enegiaIds: number[];
  public meterIds: number[];

  public readonly terminals$: Observable<ComboItem<number>[]>;
  public readonly loading$: Observable<boolean>;

  public readonly terminalIdControl = new UntypedFormControl(null, Validators.required);
  public readonly isNewTerminalControl = new UntypedFormControl(false);
  public terminal: Terminal;

  private readonly _loading$ = new LoadingSubject();

  public constructor(
    activeModal: NgfActiveModal,
    private readonly configurationControlClient: ConfigurationControlClient,
    private readonly toasterService: ToasterService
  ) {
    super(activeModal);
    this.loading$ = this._loading$.asObservable();
    this.terminals$ = this.configurationControlClient.getTerminalNames()
      .pipe(
        indicate(this._loading$),
        map(terminals => terminals.sortBy('name').map(t => ({ text: t.name, value: t.id }))),
        catchError(() => {
          this.toasterService.error('ADMIN.TERMINAL.EDIT_FAILED');
          return of([]);
        })
      );

    this.isNewTerminalControl.valueChanges.subscribe(isNew => {
      if (isNew) {
        this.terminal = {
          name: '',
          identifier: 0,
          description: '',
          connectionInformation: '',
          userName: '',
          password: '',
          terminalMeters: this.meterIds.map(meterId => ({
            meterId: meterId, ordinal: null
          }))
        };
      }
    });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public save(): void {
    const request: Observable<unknown> = this.isNewTerminal
      ? this.configurationControlClient.createTerminal(this.terminal)
      : this.configurationControlClient.addMetersToTerminal(this.terminalId, this.meterIds)
    ;
    request.subscribe({
      next: () => {
        this.toasterService.success('ADMIN.TERMINAL.EDIT_SUCCESS');
        super.closeModal();
      },
      error: () => this.toasterService.error('ADMIN.TERMINAL.EDIT_FAILED')
    });
  }

  private get terminalId(): number {
    return this.terminalIdControl.value;
  }

  public get isNewTerminal(): boolean {
    return this.isNewTerminalControl.value;
  }
}
