import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { EnergyTargetType } from '@enerkey/clients/reporting';
import { getStringEnumValues } from '@enerkey/ts-utils';

import { EkMultiComponent } from '../../../../shared/ek-inputs/ek-multi/ek-multi.component';
import { targetSeriesDefinitions } from '../../shared/target-series-definitions';

@Component({
  selector: 'target-multi-select',
  templateUrl: '../../../../shared/ek-inputs/multi-select/multi-select.component.html',
  styleUrls: ['../../../../shared/ek-inputs/multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TargetMultiSelectComponent,
    multi: true
  }],
})
export class TargetMultiSelectComponent extends EkMultiComponent {

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);

    this.items = getStringEnumValues(EnergyTargetType).map(
      target => ({
        value: target,
        text: targetSeriesDefinitions[target].translationKey
      })
    );
  }
}
