import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, switchMap, take } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { indicate, LoadingSubject, ofVoid } from '@enerkey/rxjs';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';

import { MeteringClient, MeterTagMassOperationDTO } from '@enerkey/clients/metering';

import { MeterManagementClient } from '@enerkey/clients/meter-management';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ChangedTags } from '../../../../constants/facility.constant';
import { FacilityService } from '../../../../shared/services/facility.service';

import Meter from '../../../manual-qa/shared/meter';

export const METER_TAG_REGEX = /^[\p{L}\p{N}]+([_\-. ][\p{L}\p{N}]+)*$/u;

@Component({
  selector: 'meter-tag-edit-modal',
  templateUrl: './meter-tag-edit-modal.component.html',
  styleUrls: ['./meter-tag-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'tiny' })
export class MeterTagEditModalComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public selectedMeter: Partial<Meter>;

  public addedTags: string;
  public removedTags: string;

  public readonly tags: ComboItem<string>[] = [];
  public readonly tagEditForm: UntypedFormGroup;
  public readonly tagEditControl: UntypedFormControl;
  public readonly newTagField: UntypedFormControl = new UntypedFormControl('', [
    Validators.pattern(METER_TAG_REGEX),
    Validators.maxLength(50)
  ]);
  public readonly loading$: Observable<boolean>;
  public profileMeters$: Observable<number[]>;

  private readonly oldTags: string[] = [];
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    private readonly facilityService: FacilityService,
    private readonly toasterService: ToasterService,
    private readonly meterClient: MeteringClient,
    private readonly meterManagementClient: MeterManagementClient,
    private readonly translate: TranslateService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.tagEditControl = new UntypedFormControl([], Validators.maxLength(10));
    this.tagEditForm = new UntypedFormGroup({ tags: this.tagEditControl });
  }

  public ngOnInit(): void {
    this.tagEditControl.valueChanges.subscribe((value: string[]) => {
      if (this.oldTags && value) {
        const changes = this.getChangedMeterTags(this.oldTags, value);

        this.addedTags = changes.added.length
          ? changes.added.sort().join(', ')
          : null;
        this.removedTags = changes.removed.length
          ? changes.removed.sort().join(', ')
          : null;
      }
    });

    this.profileMeters$ = this.facilityService.profileFacilities$.pipe(
      take(1),
      switchMap(profileFacilities => {
        const profileFacilityIds = profileFacilities.map(f => f.facilityId);
        return this.meterManagementClient.getMetersByFacility(profileFacilityIds);
      }),
      map(meters => {
        const meterIds = Object.values(meters).flat().unique(m => m.id);
        return meterIds;
      })
    );

    this.profileMeters$.pipe(
      take(1),
      switchMap(profileMeters =>
        forkJoin([
          of(profileMeters),
          this.meterClient.getTagsForMeters(profileMeters),
          this.meterClient.getTagsForMeters([this.selectedMeter.id])
        ])),
      indicate(this._loading$)
    ).subscribe({
      next: ([profileMeters, allTags, currentTags]) => {
        // If meter tag isn't for current profile, don't show any suggestions
        const allTagNames: string[] = profileMeters.some(m => m === this.selectedMeter.id)
          ? allTags.map(all => all.tagName).unique()
          : [];

        const currentTagNames: string[] = currentTags.map(current => current.tagName);
        this.oldTags.push(...currentTagNames);
        this.tagEditForm.patchValue({ tags: currentTagNames });
        this.tags.push(...[...allTagNames, ...currentTagNames].unique().sort().map(tag => ({
          value: tag,
          text: tag
        })));
      },
      error: () => this.toasterService.error('TAGS.EDIT.ERROR')
    });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public addNewTag(): void {
    const tag = this.newTagField.value?.trim();
    if (this.newTagField.valid && tag.length) {
      const newTags: string[] = this.tagEditForm.value.tags;
      if (!newTags.includes(tag)) {
        newTags.push(tag);
        this.tags.push({
          value: tag,
          text: tag
        });
        this.tagEditForm.patchValue({
          tags: newTags
        });
        this.tagEditForm.get('tags').markAsDirty();
        this.newTagField.reset();
      } else {
        this.toasterService.warning(this.translate.instant('TAGS.DUPLICATETAG'));
      }
    }
  }

  public submit(): void {
    const changes = this.getChangedMeterTags(this.oldTags, this.tagEditForm.value.tags);

    if (!changes.anyChanges) {
      this.toasterService.info('TAGS.EDIT.NOCHANGES');
      super.dismissModal(); // dismiss to avoid grid reloading
      return;
    }

    const meterIds = [this.selectedMeter.id];

    const removeRequest = Array.hasItems(changes.removed)
      ? this.meterClient.removeMeterTags(new MeterTagMassOperationDTO({ meterIds, tagNames: changes.removed }))
      : ofVoid();

    removeRequest.pipe(
      concatMap(() => {
        if (Array.hasItems(changes.added)) {
          return this.meterClient.addMeterTags(new MeterTagMassOperationDTO({ meterIds, tagNames: changes.added }));
        }
        return ofVoid();
      })
    ).subscribe({
      next: () => {
        this.toasterService.success('TAGS.EDIT.SUCCESS');
        super.closeModal();
      },
      error: () => this.toasterService.error('TAGS.EDIT.ERROR')
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private getChangedMeterTags(before: string[], after: string[]): ChangedTags {
    const added = after.except(before);
    const removed = before.except(after);

    return {
      added,
      removed,
      anyChanges: added.length > 0 || removed.length > 0,
    };
  }
}
