import ManualQaSpreadsheetFilterTypeSelectController from './manual-qa-spreadsheet-filter-type-select-controller';
import template                                      from 'raw-loader!./manual-qa-spreadsheet-filter-type-select.html';

export default {
  template: template,
  controllerAs: 'vm',
  controller: ManualQaSpreadsheetFilterTypeSelectController,
  bindings: {
    selectedItem: '<',
    onChange: '&'
  }
};
