const $inject = ['modalOptions'];

export const defaultModalOptions = {
  isDelete: false,
  title: 'TITLE',
  text: 'TEXT'
};

class ModalController {
  constructor(modalOptions = defaultModalOptions) {
    this.modalOptions = { ...defaultModalOptions, ...modalOptions };
  }
}

ModalController.$inject = $inject;

export default ModalController;
