import template from 'raw-loader!./modal-content-header.html';

const ModalContentHeaderComponent = {
  template: template,
  bindings: {
    text: '<'
  }
};

export default ModalContentHeaderComponent;
