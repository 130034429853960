import DefectType from '../../shared/defect-type';
import SearchTypes from '../../constants/search-types';

const $inject = ['SearchTypeRadioService', 'ManualQaApiDefectIssue', 'utils'];

function SearchTypeRadioController(SearchTypeRadioService, ManualQaApiDefectIssue, utils) {
  let vm = this;

  vm.searchTypes = SearchTypeRadioService.getSearchTypes();
  vm.constants = SearchTypes;

  vm.$onInit = onInit;

  function onInit() {
    ManualQaApiDefectIssue.getDefectTypes().then((types) => {
      vm.allDefectTypes = types.map(type => ({
        id: type.id,
        name: utils.localizedString(DefectType.getDefectTypeNameTag(type.id))
      }));
    });
  }
}

SearchTypeRadioController.$inject = $inject;

export default SearchTypeRadioController;
