import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { SearchList } from '@enerkey/clients/manual-qa';

@Component({
  selector: 'manual-qa-search-lists-wrapper',
  templateUrl: './manual-qa-search-lists-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualQaSearchListsWrapperComponent {
  public constructor(
    @Inject('searchLists') public searchLists: SearchList[]
  ) {}
}
