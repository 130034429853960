import 'angular-translate';
import 'angular-translate-loader-partial';

config.$inject = ['$translatePartialLoaderProvider'];

function config($translatePartialLoaderProvider) {
  // translates
  $translatePartialLoaderProvider.addPart('configuration');
}

export default config;
