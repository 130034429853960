<modal-view-header [headingText]="'ADMIN.BULK_MODIFY_METERS.METER_HIERARCHY_EDIT' | translate">
</modal-view-header>
<div class="modal-body">
  <kendo-sortable
    [kendoSortableBinding]="meters"
    [itemClass]="'meter-reorder-item k-item'"
    [loadingSpinner]="loading$ | async"
    class="k-list"
  >
    <ng-template let-item="item">
      <div>
        {{ item.hierarchyName }}
      </div>
    </ng-template>
  </kendo-sortable>
</div>
<div
  class="modal-footer"
  *ngIf="!(loading$ | async)"
>
  <div>
    <button
      class="button button--secondary"
      (click)="resetOrder()"
    >
      {{ 'WIDGET.RESET' | translate }}
    </button>
    <button
      class="button button--secondary icon"
      (click)="orderAlphabetically()"
    >
      <i class="fas fa-sort-alpha-down"></i>
    </button>
  </div>
  <div>
    <button
      class="button button--secondary"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
    <button
      class="button"
      (click)="saveOrder()"
    >
      {{ 'MODALS.SAVE' | translate }}
    </button>
  </div>
</div>
