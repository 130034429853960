import { getBoolean, getDate, getFloat, getInteger, getMoment, getString } from './object.functions';
import { Quantity } from './quantity';
import Facility from './facility';
import { adjustByTimezoneOffset } from '../../../shared/date.functions';

function getAdjustedTime(value) {
  return value ? adjustByTimezoneOffset(value) : null;
}

export class Meter {
  constructor(values) {
    this.id = getInteger(values, 'id');
    this.name = getString(values, 'name');
    this.description = getString(values, 'description');
    this.meteringType = getInteger(values, 'meteringType');
    this.factor = getFloat(values, 'factor');
    this.isTwoTimeMeasurement = getBoolean(values, 'isTwoTimeMeasurement');
    this.automaticModeling = getBoolean(values, 'automaticModeling');
    this.customerMeterIdentifier = getString(values, 'customerMeterIdentifier');
    this.automaticReadingStartTime = getAdjustedTime(getDate(values, 'automaticReadingStartTime'));
    this.automaticReadingEndTime = getAdjustedTime(getDate(values, 'automaticReadingEndTime'));
    this.deactivationTime = getAdjustedTime(getDate(values, 'deactivationTime'));
    this.createdBy = getString(values, 'createdBy');
    this.created = getMoment(values, 'created');
    this.lastModifiedBy = getString(values, 'lastModifiedBy');
    this.lastModified = getMoment(values, 'lastModified');

    this.quantity = values?.quantity ? new Quantity(values.quantity) : null;
    this.facility = values?.facility ? new Facility(values.facility) : null;

    this.index = getInteger(values, 'index');
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getDescription() {
    return this.description;
  }

  getMeteringType() {
    return this.meteringType;
  }

  getQuantity() {
    return this.quantity;
  }

  getFactor() {
    return this.factor;
  }

  getIsTwoTimeMeasurement() {
    return this.isTwoTimeMeasurement;
  }

  getAutomaticModeling() {
    return this.automaticModeling;
  }

  getCustomerMeterIdentifier() {
    return this.customerMeterIdentifier;
  }

  getAutomaticReadingStartTime() {
    return this.automaticReadingStartTime;
  }

  getAutomaticReadingEndTime() {
    return this.automaticReadingEndTime;
  }

  getDeactivationTime() {
    return this.deactivationTime;
  }

  getCreatedBy() {
    return this.createdBy;
  }

  getCreated() {
    return this.created;
  }

  getLastModifiedBy() {
    return this.lastModifiedBy;
  }

  getLastModified() {
    return this.lastModified;
  }

  getFacility() {
    return this.facility;
  }

  getIndex() {
    return this.index;
  }
}

export default Meter;
