<table>
  <thead>
    <tr>
      <th>{{ 'FACILITIES.FACILITY' | translate }}</th>
      <th></th>
      <th></th>
      <th>{{ comparisonPeriodTitle }}</th>
      <th>{{ inspectionPeriodTitle }}</th>
      <th>{{ 'CHANGE.TITLE' | translate }}</th>
    </tr>
  </thead>
  <ng-container
    *ngFor="let rows of dataArray"
  >
    <tbody *ngIf="rows?.length > 0">
      <ng-container *ngFor="let row of rows">
        <tr *ngIf="row.facilityId | facilityInfo | async as facility">
          <td>
            <a [title]="facility.Name" (click)="rowClick.emit(row.facilityId)">{{ facility.Name }}</a>
          </td>
          <td>
            <i
              *ngIf="row.facilityId | topChangedActionsTitle:actions as titleText"
              [title]="titleText"
              class="ek-color ek-color--grey icon icon-icon-notes-content"
            ></i>
          </td>
          <td>{{ facility.FacilityInformation.RealEstateId }}</td>
          <td>{{ row.comparisonPeriod | kendoNumber:format }}</td>
          <td>{{ row.inspectionPeriod | kendoNumber:format }}</td>
          <td *ngIf="changeType === WidgetChangeOption.Relative">
            <span
              [ekValueChange]="row.relativeChange"
              [ngfTooltip]="row.absoluteChange | kendoNumber:format"
            ></span>
          </td>
          <td *ngIf="changeType === WidgetChangeOption.Absolute">
            <span
              [ekValueChange]="row.absoluteChange"
              [isAbsoluteChange]="true"
              [ngfTooltip]="row.relativeChange | kendoNumber:'#.# %'"
            ></span>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </ng-container>
</table>
