import { Pipe, PipeTransform } from '@angular/core';

import { VISUAL_COLORS } from '../constants/visualization-constant';

@Pipe({
  name: 'bookmarkIndicatorColor'
})
export class BookmarkIndicatorColorPipe implements PipeTransform {
  public transform(variableType: number): string {
    return variableType
      ? VISUAL_COLORS[variableType]
      : 'transparent'
    ;
  }
}
