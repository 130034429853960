const $inject = [
  '$modalInstance', 'utils', 'searchList',
  'ManualQaPopupService', 'mqaClient'
];

function ManualQaSearchListDeleteModalController(
  $modalInstance, utils, searchList,
  ManualQaPopupService, mqaClient
) {
  const vm = this;

  vm.searchList = angular.copy(searchList);

  vm.close = close;
  vm.dismiss = dismiss;
  vm.deleteSearchList = deleteSearchList;

  /**
   * Handles modal close.
   */
  function close() {
    $modalInstance.close(vm.searchList);
  }

  /**
   * Handles modal dismiss
   */
  function dismiss() {
    $modalInstance.dismiss();
  }

  /**
   * Deletes given search list
   *
   * @param {Object} searchList
   */
  function deleteSearchList(searchList) {
    return mqaClient
      .deleteSearchList(searchList.id)
      .subscribe({
        next: () => {
          onSuccess();
          close();
        },
        error: () => {
          onError();
        }
      });

    function onSuccess() {
      ManualQaPopupService.successPopUp('MQA.SUCCESS.SEARCH_LIST_DELETED');
    }

    function onError() {
      ManualQaPopupService.errorPopUp('MQA.ERRORS.SEARCH_LIST_DELETE_ERROR');
    }
  }
}

ManualQaSearchListDeleteModalController.$inject = $inject;

export default ManualQaSearchListDeleteModalController;
