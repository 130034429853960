import ManualQaFetchTypeModule               from '../manual-qa-fetch-type';
import ManualQaSpreadsheetCommandsModule     from './commands';
import ManualQaSpreadsheetComponent          from './manual-qa-spreadsheet-component';
import ManualQaSpreadsheetContextMenuService from './manual-qa-spreadsheet-context-menu-service';
import ManualQaSpreadsheetService            from './manual-qa-spreadsheet-service';

const dependencies = [ManualQaSpreadsheetCommandsModule.name, ManualQaFetchTypeModule.name];

const ManualQaSpreadsheetModule = angular
  .module('app.modules.manual-qa.components.manual-qa-spreadsheet', dependencies)
  .component('manualQaSpreadsheet', ManualQaSpreadsheetComponent)
  .service('ManualQaSpreadsheetService', ManualQaSpreadsheetService)
  .service('ManualQaSpreadsheetContextMenuService', ManualQaSpreadsheetContextMenuService)
;

export default ManualQaSpreadsheetModule;
