import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { Url } from '@enerkey/clients/facility';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { FacilityUrlService } from '../../services/facility-url.service';

@Component({
  selector: 'facilities-popup-external-links:not(p)',
  templateUrl: './facilities-popup-external-links.component.html',
  styleUrls: ['./facilities-popup-external-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilitiesPopupExternalLinksComponent implements OnInit, OnDestroy {
  @Input() public facility: ExtendedFacilityInformation;

  public readonly urls$: Observable<Url[]>;
  public readonly hasUrls$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;

  private readonly _facilityId$ = new ReplaySubject<number>(1);
  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    urlService: FacilityUrlService
  ) {
    this.loading$ = this._loading$.asObservable();
    this.urls$ = this._facilityId$.pipe(
      switchMap(facilityId => urlService.urlsByFacility$.pipe(
        take(1),
        switchMap(
          byFacility => facilityId in byFacility
            ? of(byFacility[facilityId])
            : urlService.getFacilityUrls(facilityId)
        ),
        indicate(this._loading$),
        takeUntil(this._destroy$)
      )),
      shareReplay(1)
    );

    this.hasUrls$ = this.urls$.pipe(
      map(urls => Array.hasItems(urls))
    );
  }

  public ngOnInit(): void {
    this._facilityId$.next(this.facility.facilityId);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._facilityId$.complete();
    this._loading$.complete();
  }
}
