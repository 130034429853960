import _ from 'lodash';

import TimeFrame from '../../../../services/time-frame-service';

const $inject = ['utils', 'ERDataFunctions', 'AdditionalSettingService'];

// This service contains common functions for ERGridService and ERChartService
function ERDataColumn(utils, ERDataFunctions, AdditionalSettingService) {
  return {
    getTitle: getTitle,
    getTitlePrefix: getTitlePrefix,
    getRelatedValueTitle: getRelatedValueTitle,
    getRelatedValueTooltipTitle: getRelatedValueTooltipTitle,
    getCompareTooltipTitle: getCompareTooltipTitle,
    getCompareHeaderTemplate: getCompareHeaderTemplate,
    getReadingHeaderTemplate: getReadingHeaderTemplate,
    getModelledCount: getModelledCount,
    getTypeColor: getTypeColor,
    getColorSquare: getColorSquare,
    hideMeasurement: hideMeasurement
  };

  /**
   * Returns time serie title
   *
   * Return for a single time serie it's prefix and formatted time range
   * Both prefix and formatted time range are optional
   * e.g.
   *       "Normalized 2014"  - Normalized serie start date 1.1.2014 time frame P1Y and resolution P1M
   *       "Plan A"           - Target serie Plan A title, no time range information used
   *       "2/18 - 1/19"      - Basic measured time frame, start date 1.2.2018 time frame P1Y and resolution P1M
   *
   * @param  {object} options - Contain time series time frame and resolution information
   * @param  {object} data - Contain time series type start date information
   * @param  {date} [start] - Start date information, over rules data.startInfo.value if used
   * @param  {boolean} [isOnlyRangeShown] - Used for forecast and target series comparison titles
   *
   * @returns {String} Prefix + time range formatted
   */
  function getTitle(options, data, start, isOnlyRangeShown) {
    const startDate = start ? start : data.startInfo.value;
    const prefix = isOnlyRangeShown ? '' : getTitlePrefix(options, data);

    const forecastType = ERDataFunctions.getForecastType(options, data);
    // only prefix is shown for forecast except when creating comparison part of title
    if (forecastType && !isOnlyRangeShown) {
      return prefix;
    }

    const range = TimeFrame.timeRangeFormat(startDate, options.series.TimeFrame, options.series.Resolution);

    return prefix ? `${prefix} ${range}` : range;
  }

  function getTitlePrefix(options, data) {
    const forecastType = ERDataFunctions.getForecastType(options, data);
    let prefix = '';

    prefix = forecastType ? _.get(forecastType, 'Name') : prefix;

    return prefix ? prefix : getNormalizedPrefix(data);
  }

  function getRelatedValueTitle(options, data) {
    const propertyData = data.propertyData || {};
    const prefix = getTitlePrefix(options, data);
    const output = '';

    if (_.get(options, 'chart.separateQuantityProperties', false)) {
      return output.concat(prefix ? `${prefix} ` : '').concat(data.startInfo.key);
    }

    const startString = data.startInfo.value
      ? TimeFrame.timeRangeFormat(data.startInfo.value, options.series.TimeFrame, options.series.Resolution)
      : ''
    ;

    return output
      .concat(prefix ? `${prefix} ` : '')
      .concat(propertyData.object.Name)
      .concat(startString ? ` ${startString}` : '')
      .concat(propertyData.unit ? ` [${propertyData.unit}]` : '');
  }

  function getRelatedValueTooltipTitle(options, data) {
    const prefix = getTitlePrefix(options, data);
    const propertyData = data.propertyData || {};
    const forecastType = ERDataFunctions.getForecastType(options, data);
    const output = '';

    if (forecastType) {
      // Don't return start info for forecast / target series.
      return prefix;
    }

    return output.concat(prefix ? `${prefix} ` : '').concat(propertyData.object.Name);
  }

  function getNormalizedPrefix(data) {
    const normalizedString = utils.localizedString('FACILITIES.NORMALIZED');

    return data.normalized ? normalizedString : '';
  }

  function getCompareTooltipTitle(options, data, isPercent, extraSuffix) {
    isPercent = isPercent || false;
    extraSuffix = extraSuffix ? ` ${extraSuffix} ` : '';

    const forecastType = ERDataFunctions.getForecastType(options, data);
    const prefix = getTitlePrefix(options, data);
    const suffix = extraSuffix + (isPercent ? '%' : '');

    let fromKey;
    let toKey;
    if (forecastType) {
      fromKey = getTitle(options, data, data.previousStartInfo.value, true);
      toKey = prefix;
    } else {
      fromKey = getTitle(options, data, data.previousStartInfo.value);
      toKey = getTitle(options, data, data.controlStartInfo.value);
    }
    const params = {
      fromKey: fromKey,
      toKey: toKey
    };

    const title = options.isWidget
      ? utils.localizedString('CHANGE.TITLE')
      : _.template('<%= fromKey %> ➜ <%= toKey %>')(params);
    return suffix ? `${title} ${suffix}` : title;
  }

  function getCompareHeaderTemplate(options, data, isPercent, extraSuffix) {
    isPercent = isPercent || false;

    extraSuffix = extraSuffix ? ` ${extraSuffix} ` : '';
    const suffix = extraSuffix + (isPercent ? '%' : '');
    let typeColor = getTypeColor(options, data);
    let targetType = '';

    if (options.show.allTargetSeriesPeriods && options.series.Start.length > 1) {
      targetType = ` ${getTitle(options, data, data.previousStartInfo.value)}`;
      typeColor = data.startInfo.color;
    }

    const toColor = data.controlStartInfo.color;
    const params = {
      fromColor: data.previousStartInfo.color,
      toColor: typeColor ? typeColor : toColor,
      targetType: targetType,
      title: getCompareTooltipTitle(options, data, isPercent, extraSuffix),
      suffix: suffix
    };

    const template = _.template(
      '<span tooltip-append-to-body="true" tooltip-placement="left" tooltip="<%= title %>">' +
        '<span style="color: <%= fromColor %>">■</span><%= targetType %> ➜ ' +
        '<span style="color: <%= toColor %>">■ </span>' +
        '<%= suffix %>' +
        '</span>'
    );

    return !options.isWidget ? _.wrap(template(params)) : null;
  }

  function getReadingHeaderTemplate(options, data) {
    const typeColor =
      !options.show.allTargetSeriesPeriods || options.series.Start.length <= 1 ? getTypeColor(options, data) : null;

    const params = {
      title: getTitle(options, data),
      color: typeColor ? typeColor : data.startInfo.color
    };

    const template = _.template('<span style="color: <%= color %>">■ </span> <%= title %>');

    return !options.isWidget ? _.wrap(template(params)) : null;
  }

  function getTypeColor(options, data) {
    const forecastType = ERDataFunctions.getForecastType(options, data);
    let color = '';

    color = forecastType ? _.get(forecastType, 'Color') : color;

    return color;
  }

  /**
   * Returns modelledCount for a column i.e. number of modelled hours within the serie timeFrame
   *
   * Consumptions array may not contain consumptions, aggregates or ModeledValueCount fields, in that case the
   * function return zero. Data array Aggregates may contain multiple series, modelledCount count information is
   * returned from aggregates item where TimeStamp matches with startInfo timestamp value.
   *
   * @param  {array} consumptions - Array containing data series information
   * @param  {date} startTime - Date object that contains serie start time.
   * @returns {number} Modelled count (hours)
   */
  function getModelledCount(consumptions, startTime) {
    let modelCount = 0;
    if (hasAggregatesAndStartTime(consumptions, startTime)) {
      const aggregate = _.find(
        consumptions[0].Aggregates,
        // Only YYYY.MM.DDThh.mm.ss part is compared, ms and z formatting may differ
        item => item.Timestamp.substring(0, 19) === startTime.toISOString().substring(0, 19)
      );
      if (angular.isDefined(aggregate) && angular.isDefined(aggregate.ModeledValueCount)) {
        modelCount = aggregate.ModeledValueCount;
      }
    }
    return modelCount;
  }

  function hasAggregatesAndStartTime(consumptions, startTime) {
    return (
      startTime instanceof Date && angular.isDefined(consumptions[0]) && angular.isDefined(consumptions[0].Aggregates)
    );
  }

  function getColorSquare(color) {
    return `<span style="color: ${color}">■ </span>`;
  }

  function hideMeasurement(options) {
    const relationalUnitsDisabled = AdditionalSettingService.getDisabledSettings().includes('relational-values');
    const hasRelationalUnits = !relationalUnitsDisabled && _.get(options, 'series.RelationalUnitIds', []).length;

    const targetsDisabled = AdditionalSettingService.getDisabledSettings().includes('consumption-targets');
    const hasTargets = !targetsDisabled && _.get(options, 'series.ConsumptionTargetSeriesTypes', []).length;

    return !(hasRelationalUnits || hasTargets) ? false : !_.get(options, 'params.showMeasurement', true);
  }
}

ERDataColumn.$inject = $inject;

export default ERDataColumn;
