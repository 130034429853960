const $inject = [
  '$modalInstance', 'utils', 'facilityIds', 'includeQuantityId', 'includeCustomType', 'title'
];

class FacilitySpreadsheetModalController {
  constructor($modalInstance, utils, facilityIds, includeQuantityId, includeCustomType, title) {
    this.$modalInstance = $modalInstance;
    this.title = title;
    this.spreadsheetReference = null;
    this.facilityIds = facilityIds;
    this.includeQuantityId = includeQuantityId;
    this.includeCustomType = includeCustomType;
  }

  /**
   * Handles modal dismiss
   */
  dismiss() {
    this.$modalInstance.dismiss(this.meterIds);
  }

  /**
   * Handles modal close
   */
  close() {
    this.$modalInstance.close(this.meterIds);
  }
  /**
   * Set spreadsheet
   */
  setSpreadsheet(spreadsheet) {
    this.spreadsheetReference = spreadsheet;
  }

  /**
   * Downloads excel
   */
  onExcelDownLoad() {
    this.spreadsheetReference.saveAsExcel();
  }

  metersCreated(meterIds) {
    this.meterIds = meterIds;
  }
}

FacilitySpreadsheetModalController.$inject = $inject;

export default FacilitySpreadsheetModalController;
