import moment from 'moment';

/* eslint-disable @typescript-eslint/no-explicit-any */

const durationCache: any = {};
moment.fromIsodurationCached = function(isoDuration: any) {
  let duration = durationCache[isoDuration];
  if (!duration) {
    duration = moment.duration(isoDuration);
    durationCache[isoDuration] = duration;
  }

  return duration;
};
