import FacilitiesReportController from './facilities-report.controller';
import FacilitiesReportTemplate from 'raw-loader!./facilities-report.html';

export default {
  template: FacilitiesReportTemplate,
  controller: FacilitiesReportController,
  controllerAs: 'vm',
  bindings: {
    isEnegiaIdShownInReportArea: '<',
    reportParams: '<',
    cache: '<'
  }
};
