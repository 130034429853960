<modal-view-header
  [headingText]="'ADMIN.METER_SEARCH.ADD_TO_METER.TITLE' | translate"
></modal-view-header>
<div class="modal-body no-border-on-invalid">
  <kendo-multicolumncombobox
    [data]="selectItems"
    [valueField]="'hierarchyId'"
    [textField]="'meterName'"
    [formControl]="meterControl"
    [valuePrimitive]="true"
    [kendoDropDownFilter]="filterSettings"
    autofocus
    *labelBefore="'ADMIN.METER_SEARCH.ADD_TO_METER.TEXT' | translate"
  >
    <kendo-combobox-column
      [field]="'meterId'"
      [title]="'ID'"
      [width]="50"
    >
    </kendo-combobox-column>
    <kendo-combobox-column
      [field]="'meterName'"
      [title]="'METER_NAME' | translate"
      [width]="200"
    >
    </kendo-combobox-column>
  </kendo-multicolumncombobox>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="modal-footer-buttons float-right">
      <button
        class="button"
        type="submit"
        [disabled]="!meterControl.valid"
        (click)="close()"
      >
        {{ 'CONTINUE' | translate }}
      </button>
    </div>
  </div>
</div>
