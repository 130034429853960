import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function translateValidator(key: string, validator: ValidatorFn): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => validator(control) ? { [key]: true } : null;
}

export function translateValidators(validators: Record<string, ValidatorFn>): ValidatorFn {
  const entries = Object.entries(validators);

  return (control: AbstractControl): ValidationErrors | null => {
    const errorList: string[] = [];

    for (const [key, validator] of entries) {
      const errors = validator(control);

      if (errors) {
        errorList.push(key);
      }
    }

    if (Array.hasItems(errorList)) {
      return errorList.toRecord(e => e, _ => true);
    }

    return null;
  };
}

export function isValidToolTypeUrl(control: AbstractControl): { invalidUrl: boolean } {
  const regex = /(^https:\/\/)([a-z\d\-/]+\.)*\/*[a-z\d\-/]+$/i;
  if (!control.value) {
    return null;
  }
  return regex.test(control.value as string)
    ? null
    : { invalidUrl: true };
}
