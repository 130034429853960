import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { generatePassword } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import {
  BulkCreateUserViewModel,
  UpsertUserDto,
  UserManagementClient,
} from '@enerkey/clients/user-management';

import { CompaniesService } from '../../../../shared/services/companies.service';
import { FileReaderService } from '../../../../shared/services/file-reader.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { Roles } from '../../constants/roles';
import { RoleService } from '../../services/role.service';

interface IMassUserData {
  firstName: string,
  lastName: string,
  email: string,
  company: string,
  profile: string,
  thirdParty: string,
  endDate: Date,
  comment: string
}

@Component({
  templateUrl: './user-mass-create-modal.component.html',
  styleUrls: ['./user-mass-create-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ windowClass: 'modal-dialog-scrollable' })
export class UserMassCreateModalComponent extends ModalBase<void> implements OnDestroy {

  public fileName: string;
  public importedUsers: UpsertUserDto[];
  public companyList: Record<number, string>;
  public profileList: Record<number, string>;
  public dataIsLoaded: boolean = false;
  public validationErrorFound: boolean = false;
  public sharedInformation: UntypedFormGroup;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject(false);
  private readonly _defaultRoles: string[] = [
    Roles.DOCUMENT_READ,
    Roles.DOCUMENT_WRITE
  ];

  public constructor(
    private readonly toasterService: ToasterService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly companiesService: CompaniesService,
    private readonly userManagementClient: UserManagementClient,
    private readonly fileReaderService: FileReaderService,
    private readonly translateService: TranslateService,
    private readonly roleService: RoleService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.sharedInformation = new UntypedFormGroup({
      preferredLanguage: new UntypedFormControl(null, Validators.required),
      roles: new UntypedFormControl([], Validators.required)
    });
    this.roleService.getRoles().subscribe(roles => {
      this.sharedInformation.patchValue({
        roles: roles.filterMap(
          r => this._defaultRoles.includes(r.name),
          r => r.id
        )
      });
    });
    this.companiesService.getCompanies().subscribe(companies => {
      this.companyList = companies.toRecord(c => c.id, c => c.name);
    });
    this.userManagementClient.getUserProfiles()
      .subscribe(profiles => {
        this.profileList = profiles.toRecord(p => p.id, p => p.name);
      });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public createUsers(): void {
    // Add Shared Information to users
    const sharedData = this.sharedInformation.value;
    for (const user of this.importedUsers) {
      user.preferredLanguage = sharedData.preferredLanguage;
      user.roleIds = sharedData.roles;
    }
    this.userManagementClient.bulkCreateUsers(
      this.importedUsers.map(user => (new BulkCreateUserViewModel({
        user: user
      })))
    ).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: warnings => {
        for (const error of warnings) {
          if (error.errorId === 5) {
            const user = error.errorMessage.split(' ')[1];
            const msg = this.translateService.instant(`ADMIN.BULK_SAVE_ERROR_${error.errorId}`);
            this.toasterService.warning(`${user}: ${msg}`);
          } else {
            this.toasterService.warning(error.errorMessage);
          }
        }
        super.closeModal();
        this.toasterService.success('ADMIN.BULK_CREATE_SUCCESS');
      },
      error: errors => {
        const json = JSON.parse(errors.response);
        for (const error of json) {
          if (error.ErrorId) {
            this.toasterService.warning(`ADMIN.BULK_SAVE_ERROR_${error.ErrorId}`);
          } else {
            this.toasterService.error('ADMIN.USERCREATED_ERROR');
          }
        }
      }
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  public pasteFromExcel(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    target.value = null;
    this.processContents(value, '\t');
  }

  public onFileChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.fileName = target.files[0]?.name;
    this.fileReaderService.readFile(target.files[0]).subscribe(
      contents => {
        this.processContents(contents, ',');
      }
    );
    target.value = null;
  }

  private processContents(contents: string, sep: string): void {
    const csvData = this.parseCSVFile(contents, sep);
    if (csvData.length) {
      this.importedUsers = this.processData(csvData);
      this.dataIsLoaded = true;
      if (this.validationErrorFound) {
        this.toasterService.error('ADMIN.BULK_CREATION_ERRORS');
      } else {
        this.toasterService.success('ADMIN.BULK_CREATE_IMPORT_SUCCESS');
      }
    } else {
      this.toasterService.error('ADMIN.BULK_CREATE_COLUMN_COUNT_MISMATCH_ERROR');
      this.importedUsers = [];
      this.dataIsLoaded = false;
    }
    this.changeDetectionRef.detectChanges();
  }

  private parseCSVFile(contents: string, sep: string = ','): IMassUserData[] {
    const users: IMassUserData[] = [];
    let lines = contents.toString().split('\n');
    lines = lines.filter(l => l.length);
    for (const line of lines) {
      const cols = line.split(sep);
      if (cols.length !== 8) {
        return [];
      }
      users.push({
        firstName: cols[0],
        lastName: cols[1],
        email: cols[2],
        company: cols[3],
        profile: cols[4],
        thirdParty: cols[5],
        endDate: cols[6] !== '' ? new Date(cols[6]) : null,
        comment: cols[7]
      });
    }
    return users;
  }

  private processData(result: IMassUserData[]): UpsertUserDto[] {
    this.validationErrorFound = false;
    return result.map(user => {
      const companyId = this.getCompanyId(user.company) || 0;
      const profiles = [];
      if (user.profile) {
        profiles.push(this.getProfileId(user.profile) || 0);
      }

      if (companyId === 0 || profiles[0] === 0 ||
        !user.email.includes('@')) {
        this.validationErrorFound = true;
      }

      return new UpsertUserDto({
        userName: user.email,
        password: generatePassword(),
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        companyId: companyId,
        thirdPartySystemName: user.thirdParty,
        accountEndDate: user.endDate,
        comment: user.comment,
        isHuman: true,
        profileIds: profiles,
        roleIds: []
      });
    });
  }

  private getCompanyId(name: string): number {
    for (const [id, cName] of Object.entries(this.companyList)) {
      if (cName.toLowerCase() === name?.toLowerCase()) {
        return parseInt(id);
      }
    }
  }

  private getProfileId(name: string): number {
    for (const [id, pName] of Object.entries(this.profileList)) {
      if (pName.toLowerCase() === name?.toLowerCase()) {
        return parseInt(id);
      }
    }
  }
}
