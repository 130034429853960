import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Host,
  Injector,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnBase } from '@progress/kendo-angular-grid';

import { FacilityColumnsProperties, FacilityProperty } from '../../../interfaces/facility-columns-properties';
import { CustomColumnGroupBase, ManualColumnDefinition } from '../custom-column-group-base/custom-column-group-base';

/* eslint-disable @angular-eslint/no-input-rename */

@Component({
  selector: 'facility-information-column-group',
  templateUrl: './facility-information-column-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: ColumnBase,
    useExisting: forwardRef(() => FacilityInformationColumnGroupComponent)
  }],
})
export class FacilityInformationColumnGroupComponent extends CustomColumnGroupBase implements OnInit {

  @Input('propertyGroup') public propertyGroup: FacilityColumnsProperties;
  @Input('facilityIdField') public facilityIdField: string = 'facilityId';
  @Input('facilityInformationField') public propertyField: string = 'facilityInformationField';

  protected columnDefinitions: Record<string, ManualColumnDefinition>;

  @ViewChild('booleanTemplate', { read: TemplateRef })
  private readonly _booleanTemplate: TemplateRef<unknown>;

  public constructor(
    translateService: TranslateService,
    viewContainerRef: ViewContainerRef,
    injector: Injector,
    @Optional() @SkipSelf() @Host() parent: ColumnBase
  ) {
    super(translateService, viewContainerRef, injector, parent);
  }

  public override ngOnInit(): void {
    this.title = this.propertyGroup.Name;
    this.columnDefinitions = this.propertyGroup.Items.toRecord<string, ManualColumnDefinition>(
      p => p.Property,
      p => ({
        title: p.Name,
        width: 150,
        filter: this.getFilter(p.Type),
        format: p.Type === 'date' ? 'd' : undefined,
        cellTemplatefn: p.Type === 'boolean'
          ? () => this._booleanTemplate
          : undefined,
      })
    );

    super.ngOnInit();
  }

  private getFilter(columnType: FacilityProperty['Type']): ManualColumnDefinition['filter'] {
    switch (columnType) {
      case 'string': return 'text';
      case 'number': return 'numeric';
      case 'date': return 'date';
      case 'boolean': return 'boolean';
      default: return undefined;
    }
  }
}
