<modal-view-header
  [headingText]="('PREVIEW' | translate) + ': ' + selectedDocument.fileNameOrUrl"
></modal-view-header>
<div class="modal-body">
  <div class="float-right">
    <button
      type="button"
      class="button"
      [loading]="loading$ | async"
      (click)="downloadDocument()"
      ngfNoAutoFocus
    >
      {{ 'DOCUMENTS.DOWNLOAD_DOCUMENT' | translate }}
    </button>
  </div>
  <div class="previewWindow">
    <ng-container *ngIf="!(previewDownloadFailed$ | async); else previewDownloadFailed">
      <ng-container *ngIf="hasPreview$ | async; else noPreview">
        <img
          class="imgPreview"
          *ngIf="previewURL$ | async as previewUrl"
          [src]="previewUrl"
          [alt]="this.selectedDocument.fileNameOrUrl"
          (error)="previewLoadFailed()"
        />
      </ng-container>
      <ng-template #noPreview>
        <div class="centeredDiv">
          {{ 'DOCUMENTS.NO_PREVIEW_DOWNLOAD_HERE' | translate }}
        </div>
      </ng-template>
    </ng-container>
    <ng-template #previewDownloadFailed>
      <div class="centeredDiv">
        {{ 'DOCUMENTS.PREVIEW_FAILED' | translate }} {{ 'DOCUMENTS.DOCUMENT_DOES_NOT_EXIST' | translate }}
      </div>
    </ng-template>
  </div>
</div>
