import { Transition } from '@uirouter/core';

import { UserService } from '../../services/user-service';
import { EnerkeyNg2StateDeclaration, UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';
import { provisioningState } from '../provisioning/provisioning.states';
import { IncomingMessagesComponent } from './components/incoming-messages/incoming-messages.component';
import { OutgoingMessagesComponent } from './components/outgoing-messages/outgoing-messages.component';
import { SupervisionComponent } from './components/supervision/supervision.component';

export const supervisionTopbarState: UIStateNg2 = {
  name: 'supervision',
  data: {
    auth: {
      roles: [Roles.SUPERVISOR]
    },
    translationKey: 'SUPERVISION.SUPERVISION'
  }
};

const supervisionState: EnerkeyNg2StateDeclaration = {
  ...supervisionTopbarState,
  parent: 'index',
  url: '/supervision',
  views: {
    'app@': {
      component: SupervisionComponent
    }
  },
  data: {
    translationKey: 'SUPERVISION.SUPERVISION',
  },
  redirectTo: SUPERVISION_REDIRECT,
};

const incomingMessagesState: EnerkeyNg2StateDeclaration = {
  name: 'supervision.incoming',
  url: '/incoming',
  data: {
    translationKey: 'SUPERVISION.INCOMING_MESSAGES',
  },
  views: {
    'supervision@supervision': {
      component: IncomingMessagesComponent
    }
  }
};

const outgoingMessagesState: EnerkeyNg2StateDeclaration = {
  name: 'supervision.outgoing',
  url: '/outgoing',
  data: {
    translationKey: 'SUPERVISION.OUTGOING_MESSAGES',
  },
  views: {
    'supervision@supervision': {
      component: OutgoingMessagesComponent
    }
  }
};

export const SUPERVISION_STATES = [supervisionState, incomingMessagesState, outgoingMessagesState];

export const SUPERVISION_CHILDREN = [provisioningState, incomingMessagesState, outgoingMessagesState];

function SUPERVISION_REDIRECT(transition: Transition): string {
  const svc: UserService = transition.injector().get('UserService');
  const foundState = SUPERVISION_CHILDREN.find(state => !state.data.auth || svc.hasAccess(state.data.auth));
  return foundState?.name ?? 'supervision.incoming';
}
