import controller from './meter-cost-mass-edit.controller';
import template from 'raw-loader!./meter-cost-mass-edit.component.html';

export default {
  bindings: {
    dismiss: '<',
    close: '<',
    selectedMeters: '<'
  },
  controller,
  controllerAs: 'vm',
  template
};
