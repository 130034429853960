import SelectPagingComponent from './select-paging-component';
import ServiceModule from '../../services';

const dependencies = [
  ServiceModule.name
];

const SelectPagingModule = angular
  .module('app.components.select-paging', dependencies)
  .component('selectPaging', SelectPagingComponent);

export default SelectPagingModule;