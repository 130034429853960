<ng-container [ngSwitch]="status">
  <i *ngSwitchCase="ProvisioningRuntimeStatus.Unknown" class="far fa-question-circle grey-icon"></i>
  <i *ngSwitchCase="ProvisioningRuntimeStatus.Running" class="fas fa-sync-alt blue-icon"></i>
  <i *ngSwitchCase="ProvisioningRuntimeStatus.Completed" class="fas fa-check-circle green-icon"></i>
  <i *ngSwitchCase="ProvisioningRuntimeStatus.ContinuedAsNew" class="fas fa-redo-alt blue-icon"></i>
  <i *ngSwitchCase="ProvisioningRuntimeStatus.Failed" class="fas fa-exclamation-triangle red-icon"></i>
  <i *ngSwitchCase="ProvisioningRuntimeStatus.Pending" class="far fa-clock blue-icon"></i>
  <i *ngSwitchCase="ProvisioningRuntimeStatus.Canceled" class="far fa-stop-circle grey-icon"></i>
  <i *ngSwitchCase="ProvisioningRuntimeStatus.Terminated" class="far fa-stop-circle grey-icon"></i>
</ng-container>
