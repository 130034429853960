<ng-template #tooltipTemplate let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>
<div
  kendoTooltip
  showOn="none"
  [tooltipTemplate]="tooltipTemplate"
  [offset]="0"
  (mouseover)="showTooltip($event)"
>
  <ng-content></ng-content>
</div>
