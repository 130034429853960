import { Observable, race, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import {
  DistributionType,
  DistributionTypesResponse,
  EnergyReportingClient,
  RelatedValue,
  RelatedValuesResponse,
} from '@enerkey/clients/energy-reporting';

import { LanguageChangeService } from '../shared/services/language-change.service';

interface IEnergyReportingClientCacheService {
  getRelatedValues(): Observable<{[key: string]: RelatedValue[]}>;
  getDistributionTypes(): Observable<DistributionType[]>;
  getDistributionType(findId: number): Observable<DistributionType>;
}

export class EnergyReportingClientCacheService implements IEnergyReportingClientCacheService {

  public static $inject = ['EnergyReportingClient', 'languageChangeService'];

  private readonly relatedValuesInterrupt$ = new Subject<RelatedValuesResponse>();
  private readonly distributionTypesInterrupt$ = new Subject<DistributionTypesResponse>();

  private relatedValues$: Observable<{[key: string]: RelatedValue[]}>;
  private distributionTypes$: Observable<DistributionType[]>;

  public constructor(
    private readonly energyReportingClient: EnergyReportingClient,
    languageChangeService: LanguageChangeService
  ) {
    languageChangeService.languageChangeStart.subscribe(() => this.onLocaleChangeStart());

    this.setRelatedValuesObservable();
    this.setDistributionTypesObservable();
  }

  public getRelatedValues(): Observable<{[key: string]: RelatedValue[]}> {
    return this.relatedValues$;
  }

  public getDistributionTypes(): Observable<DistributionType[]> {
    return this.distributionTypes$;
  }

  public getDistributionType(findId: number): Observable<DistributionType> {
    return this.getDistributionTypes().pipe(
      map(distributionTypes => distributionTypes.find(({ Id }) => Id === findId))
    );
  }

  private onLocaleChangeStart(): void {
    this.relatedValuesInterrupt$.next({ RelatedValues: {} });
    this.distributionTypesInterrupt$.next({ DistributionTypes: [] });
    this.setRelatedValuesObservable();
    this.setDistributionTypesObservable();
  }

  private setRelatedValuesObservable(): void {
    this.relatedValues$ = race(
      this.energyReportingClient.getRelatedValuesConfiguration(),
      this.relatedValuesInterrupt$
    ).pipe(
      catchError(error => {
        this.setRelatedValuesObservable();
        return throwError(error.status); // For backwards compatibility
      }),
      map(response => response.RelatedValues),
      shareReplay(1),
      map(relatedValues => cloneDeep(relatedValues))
    );
  }

  private setDistributionTypesObservable(): void {
    this.distributionTypes$ = race(
      this.energyReportingClient.getDistributionTypesConfiguration(),
      this.distributionTypesInterrupt$
    ).pipe(
      catchError(error => {
        this.setDistributionTypesObservable();
        return throwError(error.status); // For backwards compatibility
      }),
      map(response => response.DistributionTypes),
      shareReplay(1),
      map(distributionTypes => cloneDeep(distributionTypes))
    );
  }
}
