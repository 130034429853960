<div class="shared-tooltip">
  <div class="shared-tooltip-title">
    {{ category | quantityName | async }}
  </div>
  <div class="shared-tooltip-table">
    <table>
      <ng-container *ngFor="let point of points">
        <tr>
          <td>
            <span [style.color]="point.dataItem.color">
              <!-- ■ -->
              &#9632;
            </span>
            <span>{{ point.dataItem.year }}</span>
          </td>
          <td>{{ point.value | kendoNumber:'#,#' }}</td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>
