import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Injector,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';

import { Report, ReportSummary, Scope, SustainabilityClient } from '@enerkey/clients/sustainability';
import { anyOf, indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalService } from '@enerkey/foundation-angular';
import { getStringEnumValues } from '@enerkey/ts-utils';

import { GRI_REPORT_TOKEN } from '../../gri-report-token';
import { ProfileService } from '../../../../shared/services/profile.service';
import { GriReportEditorModalComponent } from '../gri-report-editor-modal/gri-report-editor-modal.component';
import { GriReportService } from '../../services/gri-report.service';
import { GriImportService } from '../../services/gri-import.service';
import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';
import { GriReportModalComponent } from '../gri-report-modal/gri-report-modal.component';
import { UserService } from '../../../../services/user-service';

@Component({
  selector: 'sustainability',
  templateUrl: './sustainability.component.html',
  styleUrls: ['./sustainability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: GRI_REPORT_TOKEN, useFactory: () => new ReplaySubject<Report>(1) },
    GriReportService,
    GriImportService,
  ]
})
export class SustainabilityComponent implements AfterViewInit, OnDestroy {
  public readonly formatTotal: string = '#,# tCO₂e';
  public readonly scopes = getStringEnumValues(Scope);

  public readonly loading$: Observable<boolean>;

  public readonly reports$: Observable<ReportSummary[]>;

  private readonly _refreshReports$ = new BehaviorSubject<void>(null);
  private readonly _loading$ = new LoadingSubject(true);
  private readonly _destroy$ = new Subject<void>();

  @ViewChild('topbarTemplate')
  private readonly topRightTemplate: TemplateRef<unknown>;

  public constructor(
    @Inject(GRI_REPORT_TOKEN) private readonly _report$: ReplaySubject<Report>,
    private readonly griService: GriReportService,
    private readonly susClient: SustainabilityClient,
    private readonly profileService: ProfileService,
    private readonly injector: Injector,
    private readonly modalService: ModalService,
    private readonly templateLifter: TemplateLifterService,
    private readonly userService: UserService
  ) {
    this.loading$ = anyOf(this._loading$, this.griService.loading$).pipe(takeUntil(this._destroy$));

    this.reports$ = combineLatest([
      this.profileService.profileId$,
      this._refreshReports$,
    ]).pipe(
      switchMap(([profileId]) => this.susClient
        .getReportSummaries(profileId)
        .pipe(indicate(this._loading$))),
      map(reports => reports.sortByMany(['year', 'descending'], 'description')),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._report$.complete();
    this._loading$.complete();
    this._refreshReports$.complete();
    this.templateLifter.template = null;
  }

  public openReportEditModal(report?: Report): void {
    const modal = this.modalService.open(GriReportEditorModalComponent, { injector: this.injector });
    modal.componentInstance.existingReport = report
      ? new Report({ ...report, profileId: this.userService.profileId })
      : undefined;
    modal.result.then(
      () => this._refreshReports$.next(),
      /* istanbul ignore next */ () => { }
    );
  }

  public viewReport(report: Report): void {
    const modal = this.modalService.open(GriReportModalComponent, { injector: this.injector });
    const reportItem = new Report({ ...report, profileId: this.userService.profileId });
    modal.componentInstance.report = reportItem;
    modal.componentInstance.displayMode = 'grid';
    this._report$.next(reportItem);
    modal.result.then(
      () => this.onReportModalCloseOrDismiss(),
      /* istanbul ignore next */
      () => this.onReportModalCloseOrDismiss()
    );
  }

  private onReportModalCloseOrDismiss(): void {
    this._report$.next(null);
    this._refreshReports$.next();
  }
}
