<form [formGroup]="formGroup">
  <quantity-dropdown
    formControlName="selectedQuantity"
    [quantities]="quantities$ | async"
    [grouped]="false"
    *labelBefore="'FACILITIES.QUANTITIES' | translate"
  ></quantity-dropdown>
  <widget-timeframe-select
    formControlName="timeFrameOption"
    *labelBefore="'TIMEFRAME_OPTIONS.TITLE' | translate"
  ></widget-timeframe-select>
  <widget-comparison-period-select
    formControlName="comparisonPeriodOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARISON_SERIE' | translate"
    [showNoneOption]="true"
  ></widget-comparison-period-select>
  <widget-change-select
    formControlName="change"
    *labelBefore="'CHANGE.TITLE' | translate"
  ></widget-change-select>
  <widget-related-value-select
    formControlName="minMaxAvg"
    *labelBefore="'FACILITIES_TOOLBAR.RELATEDVALUES' | translate"
  ></widget-related-value-select>
</form>
