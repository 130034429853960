import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { formControlsFrom } from '@enerkey/ts-utils';

import { GroupedConsumptionsWidgetOptions } from '../grouped-consumptions-widget/grouped-consumptions-widget.component';

type ChangeFn = (options: GroupedConsumptionsWidgetOptions) => void;

type OmittedOptions = 'selectedQuantity' | 'quantityId' | 'itemProperty' | 'groupProperty';

type FormValues = Omit<GroupedConsumptionsWidgetOptions, OmittedOptions> & {
  groupBy: {
    itemProperty: string;
    groupProperty: string;
  };
};

@Component({
  selector: 'grouped-consumptions-widget-options',
  templateUrl: './grouped-consumptions-widget-options.component.html',
  styleUrls: ['./grouped-consumptions-widget-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GroupedConsumptionsWidgetOptionsComponent),
    multi: true,
  }]
})
export class GroupedConsumptionsWidgetOptionsComponent implements OnDestroy, OnInit, ControlValueAccessor {
  @Input() public initialState: GroupedConsumptionsWidgetOptions;

  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  private readonly destroy$ = new Subject<void>();

  public ngOnInit(): void {
    const values = this.optionsToForm(this.initialState);
    const controls = formControlsFrom<FormValues>(
      values,
      Object.keys(values).toRecord(key => key, () => Validators.required)
    );
    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((formValue: FormValues) => {
        this._onChange?.(this.formToOptions(formValue));
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: GroupedConsumptionsWidgetOptions): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  private optionsToForm(options: GroupedConsumptionsWidgetOptions): FormValues {
    return {
      biggestFallers: options.biggestFallers,
      biggestGainers: options.biggestGainers,
      changeOption: options.changeOption,
      comparableOption: options.comparableOption,
      comparisonPeriodOption: options.comparisonPeriodOption,
      groupBy: { itemProperty: options.itemProperty, groupProperty: options.groupProperty },
      timeFrameOption: options.timeFrameOption,
      unitKey: options.unitKey,
      valueOption: options.valueOption,
      variableId: options.variableId,
    };
  }

  private formToOptions(value: FormValues): GroupedConsumptionsWidgetOptions {
    const { groupBy, ...formValue } = value;
    return {
      ...this.initialState,
      ...formValue,
      groupProperty: value.groupBy.groupProperty,
      itemProperty: value.groupBy.itemProperty,
    };
  }
}
