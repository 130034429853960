import { ReadingStatus, Terminal, TerminalMeter, TerminalStatus }
  from '@enerkey/clients/configuration-control';

class TerminalControlGridItem {

  public readonly id: number;

  // Terminal columns
  public readonly terminalIdentifier?: string;
  public readonly terminalName?: string;
  public readonly terminalComment?: string;
  public readonly terminalDescription?: string;
  public readonly terminalMeters?: TerminalMeter[];

  // Terminal facility columns
  public readonly companyNumbers?: string;
  public readonly enegiaIds?: string;
  public readonly facilityNames?: string;

  // TerminalStatus columns
  public readonly terminalStatusDescription?: string;
  public readonly terminalInspectorUserId?: number;
  public readonly terminalFaultDate?: Date;
  public readonly terminalStatusLastModified?: Date;
  public readonly terminalStatusLastInspectorModified?: Date;
  public readonly terminalReadingStatus?: ReadingStatus;

  public constructor(
    public readonly terminal: Terminal,
    public readonly terminalStatus: TerminalStatus,
    public readonly terminalInspectorName: string,
    public readonly readingStatusTextLocalized: string,
    public readonly readingStatusTextIndicator: string
  ) {
    this.id = terminal.id;
    this.terminalIdentifier = terminal.identifier.toString();
    this.terminalName = terminal.name;
    this.terminalComment = terminal.comment;
    this.terminalDescription = terminal.description;
    this.terminalMeters = terminal.terminalMeters;

    // Note! businessItentityCode is legacy yhtionumero. This will change in future
    this.companyNumbers = terminal.facilities?.map(f => f.businessIdentityCode).unique().join(', ');
    this.enegiaIds = terminal.facilities?.map(f => f.enegiaId).join(', ');
    this.facilityNames = terminal.facilities?.map(f => `"${f.facilityName}"`).join(', ');

    this.terminalStatusDescription = terminalStatus?.statusDescription,
    this.terminalInspectorUserId = terminalStatus?.inspectorUserId,
    this.terminalFaultDate =
      terminalStatus.faultDate ? new Date(terminalStatus.faultDate) : null;
    this.terminalStatusLastModified =
      terminalStatus.lastModified ? new Date(terminalStatus.lastModified) : null;
    this.terminalStatusLastInspectorModified =
      terminalStatus.lastInspectorModified ? new Date(terminalStatus.lastInspectorModified) : null;
    this.terminalReadingStatus = terminalStatus?.readingStatus;
  }
}

export default TerminalControlGridItem;
