import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { indicate } from '@enerkey/rxjs';

import { EkMultiComponent } from '../ek-multi/ek-multi.component';
import { Service, serviceNameTranslations } from '../../../constants/service';
import { ServicesService } from '../../services/services.service';

@Component({
  selector: 'service-multi-select',
  templateUrl: '../multi-select/multi-select.component.html',
  styleUrls: ['../multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ServiceMultiSelectComponent,
    multi: true,
  }]
})
export class ServiceMultiSelectComponent extends EkMultiComponent {
  public override overflowThreshold = 15;

  public constructor(
    servicesService: ServicesService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);

    servicesService.getServiceNames().pipe(
      indicate(this._loading)
    ).subscribe(services => {
      this.items = services.map(service => ({
        value: service,
        text: serviceNameTranslations[service as unknown as Service]
      }));
    });
  }
}
