import TimeFrame  from '../../../services/time-frame-service';
import DefectType from './defect-type';

export default class DefectIssue {
  constructor(data) {
    this.id = data && data.id ? parseInt(data.id) : null;
    this.meterId = data && data.meterId ? parseInt(data.meterId) : null;
    this.defectTypes = data && data.defectTypes ?
      data.defectTypes.map(defectId => new DefectType({ id: defectId })) : [];
    this.status = data && data.status ? parseInt(data.status) : 0;
    this.timeFrame = data && data.begin && data.end ? new TimeFrame(data.begin, data.end) : null;
    this.faults = data && data.faults ? data.faults : [];
  }

  /**
   * Returns defect issue id
   *
   * @returns {number|null}
   */
  getId() {
    return this.id;
  }

  /**
   * Return defect issue meter id
   *
   * @returns {number|null}
   */
  getMeterId() {
    return this.meterId;
  }

  /**
   * Returns defect issue types
   *
   * @returns {DefectType[]}
   */
  getDefectTypes() {
    return this.defectTypes;
  }

  /**
   * Returns defect issue status
   *
   * @returns {number}
   */
  getStatus() {
    return this.status;
  }

  /**
   * Returns defect issue time frame
   *
   * @returns {TimeFrame|null}
   */
  getTimeFrame() {
    return this.timeFrame;
  }

  /**
   * Returns defect issue faults
   *
   * @returns {Array}
   */
  getFaults() {
    return this.faults;
  }
}
