import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { map } from 'rxjs/operators';

import { Bookmark } from '@enerkey/clients/settings';

import { variableBookmarkTranslation } from '../constants/bookmark.constants';

@Pipe({
  name: 'bookmarkTitle'
})
export class BookmarkTitlePipe implements PipeTransform {
  public constructor(
    private readonly translateService: TranslateService,
    private readonly intlService: IntlService
  ) {
  }

  public transform(bookmark: Bookmark): Observable<string> {
    const rollingDateTranslationKey = variableBookmarkTranslation[bookmark.rollingDate];
    const createdString = bookmark.created
      ? `, ${this.intlService.formatDate(bookmark.created, 'd')}`
      : ''
    ;

    return this.translateService.get(rollingDateTranslationKey).pipe(
      map((translation: string) => `${bookmark.title}, ${translation}${createdString}`)
    );
  }
}
