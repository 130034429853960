// import MrcDataParser from '../../services/mrc-data-parser';
import moment from 'moment';

MrcInputHistoryController.$inject = ['mrcapi', 'mrcDataParser', 'MrcConstants'];

export function MrcInputHistoryController(mrcapi, mrcDataParser, MrcConstants) {
  var vm = this;
  vm.isLoading = true;
  vm.isManualMeter = false;
  vm.inputHistoryData = {};

  vm.$onInit = onInit;

  function onInit() {

    var endDate = moment.utc().endOf('month').format();
    var fromDate = moment.utc().startOf('month').subtract(13, 'months').format();

    vm.isManualMeter = vm.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION
      || vm.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING;

    var unitConversionFactor = 1;
    if (vm.options.meter.convertValues) {
      unitConversionFactor = 0.001;
    }

    mrcapi.getConsumptionInputHistory(vm.options.facilityId, vm.options.meter.Id, { from: fromDate, to: endDate } ).then(
      function(res) {

        mrcDataParser.parseConsumptionInputHistoryForListing(res, unitConversionFactor).then(
          function(parsedData) {
            vm.inputHistoryData = parsedData;
            vm.isLoading = false;
          }
        );
      }
    );
  }
}
