/* eslint-disable max-len */
import facilitiesSidebarAdditionalSettingsTemplate from 'ngtemplate-loader!raw-loader!../templates/facilities-sidebar-additional-settings.html';
import facilitiesSidebarMetertreeTemplate from 'ngtemplate-loader!raw-loader!../templates/facilities-sidebar-metertree.html';
import facilitiesSidebarPresentationTemplate from 'ngtemplate-loader!raw-loader!../templates/facilities-sidebar-presentation.html';
import facilitiesSidebarQuantitiesTemplate from 'ngtemplate-loader!raw-loader!../templates/facilities-sidebar-quantities.html';
import facilitiesSidebarTimePeriodTemplate from 'ngtemplate-loader!raw-loader!../templates/facilities-sidebar-time-period.html';
/* eslint-enable */

import {
  ErSidebarConfig,
  ErSidebarGroupConfig,
  ErSidebarLinksConfig
} from '../shared/er-sidebar-config';
import { FACILITY, METER } from './er-modal-states.constant';
import { ServiceLevel } from '@enerkey/clients/facility';

export const FACILITIES_ITEMS: ErSidebarConfig[] = [
  {
    titleKey: 'FACILITIES.QUANTITIES',
    template: facilitiesSidebarQuantitiesTemplate,
    isOpen: true
  },
  {
    titleKey: 'FACILITIES.TIME_PERIOD',
    template: facilitiesSidebarTimePeriodTemplate,
    isOpen: true
  },
  {
    titleKey: 'FACILITIES.ADDITIONAL_SETTINGS',
    template: facilitiesSidebarAdditionalSettingsTemplate,
    isOpen: true
  },
  {
    titleKey: 'FACILITIES.PRESENTATION',
    template: facilitiesSidebarPresentationTemplate,
    isOpen: true
  }
];

export const METERS_ITEMS: ErSidebarConfig[] = [
  {
    titleKey: 'METERS.METERS',
    template: facilitiesSidebarMetertreeTemplate,
    isOpen: true
  },
  {
    titleKey: 'FACILITIES.TIME_PERIOD',
    template: facilitiesSidebarTimePeriodTemplate,
    isOpen: true
  },
  {
    titleKey: 'FACILITIES.ADDITIONAL_SETTINGS',
    template: facilitiesSidebarAdditionalSettingsTemplate
  },
  {
    titleKey: 'FACILITIES.PRESENTATION',
    template: facilitiesSidebarPresentationTemplate,
    isOpen: false
  }
];

export const ITEM_GROUPS: ErSidebarGroupConfig[] = [
  {
    name: FACILITY.name,
    items: FACILITIES_ITEMS
  },
  {
    name: METER.name,
    items: METERS_ITEMS
  }
];

export const MODAL_LINKS: ErSidebarLinksConfig[] = [
  {
    titleKey: 'FACILITIES.QUANTITIES',
    params: {
      group: FACILITY,
      sidebarItems: FACILITIES_ITEMS
    }
  },
  {
    titleKey: 'METERS.METERS',
    params: {
      group: METER,
      sidebarItems: METERS_ITEMS,
      requiredServiceLevel: ServiceLevel.Medium
    }
  }
];
