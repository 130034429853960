<div [style.background]="(seriesCollection.quantityId | quantityColor)">
  <div class="quantity-icon-container">
    <div class="header-icon header-icon--bg-round header-icon--bg-white">
      <quantity-icon [quantity]="seriesCollection.quantityId"></quantity-icon>
    </div>
    <h3>
      <span>
        {{ seriesCollection.quantityId | quantityName | async }}
      </span>
      <span *ngIf="seriesCollection.derivedValueId">
        - {{ seriesCollection.derivedValueId | relationalValueName | async }}
      </span>
      <span *ngIf="seriesCollection.isNormalized">
        - {{ 'FACILITIES.NORMALIZED' | translate }}
      </span>
      <span>
        [{{ seriesCollection.unit }}]
      </span>
    </h3>
  </div>
  <h3>
    <ng-container *ngIf="seriesCollection.facilityAmounts as facilityAmounts; else change">
      <span class="facility-amounts">
        {{ facilityAmounts.applicable }}/{{ facilityAmounts.total }}
        <i class="fa fa-home"></i>
      </span>
    </ng-container>
    <ng-template #change>
      <span
        [ngfTooltip]="changeTooltip"
        [placement]="'left'"
      >
        {{ seriesCollection.relativeChange | kendoNumber:'p1' }}
      </span>
    </ng-template>
  </h3>
</div>

<ng-template #changeTooltip >
  <consumptions-change-tooltip [seriesCollection]="seriesCollection">
  </consumptions-change-tooltip>
</ng-template>
