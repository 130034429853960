import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComboItem, EkComboComponent } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

type ComparisonPeriod = 'Default' | 'None' | string;

@Component({
  selector: 'widget-comparison-period-select',
  templateUrl: '../../../../shared/ek-inputs/ek-combo/ek-combo.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WidgetComparisonPeriodSelectComponent),
    multi: true
  }]
})
export class WidgetComparisonPeriodSelectComponent extends EkComboComponent<ComparisonPeriod> implements OnInit {
  @Input() public showNoneOption: boolean;
  public override items: ComboItem<ComparisonPeriod>[];

  public constructor(changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public override ngOnInit(): void {
    this.items = this.getItems();
  }

  private getItems(): ComboItem<ComparisonPeriod>[] {
    const items: ComboItem<ComparisonPeriod>[] = [{ text: 'DEFAULT', value: 'Default' }];
    if (this.showNoneOption) {
      items.push({ text: 'FACILITIES.COMPARISON_PERIOD.NONE', value: 'None' });
    }
    const currentYear = new Date().getFullYear();
    for (let i = 1; i < 11; i++) {
      const year = currentYear - i;
      items.push({
        value: `${year}-01-01T00:00:00.000Z`,
        text: `${year}`
      });
    }

    return items;
  }
}
