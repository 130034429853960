import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { FacilityPropertyEnums } from '@enerkey/clients/facility';
import { ControlsOf, getEnumEntries } from '@enerkey/ts-utils';

import { ComboItem } from '../../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ExtendedFacilityInformation } from '../../../../../shared/interfaces/extended-facility-information';
import { FacilityService } from '../../../../../shared/services/facility.service';
import { EnergyMeasurementType, EtCurveAreaItem, ETCurveSearchParams } from '../../../models/et-curve.model';

@Component({
  selector: 'et-curve-energy-measurement',
  templateUrl: './et-curve-energy-measurement.component.html',
  styleUrls: ['./et-curve-energy-measurement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EtCurveEnergyMeasurementComponent implements OnDestroy, OnInit {

  private static readonly RELATIONAL_IDS: FacilityPropertyEnums[] = [
    FacilityPropertyEnums.GrossArea,
    FacilityPropertyEnums.GrossVolume,
    FacilityPropertyEnums.NetFloorArea,
    FacilityPropertyEnums.TotalVolume,
    FacilityPropertyEnums.HeatedArea,
    FacilityPropertyEnums.HeatedVolume,
    FacilityPropertyEnums.Area,
    FacilityPropertyEnums.Volume,
    FacilityPropertyEnums.TotalArea,
  ];

  private static initRelationalValues(
    facility: ExtendedFacilityInformation,
    onlyArea: boolean = false
  ): EtCurveAreaItem[] {
    if (facility.Properties === null) { return []; }
    return getEnumEntries(FacilityPropertyEnums)
      .filter(([name, value]) =>
        EtCurveEnergyMeasurementComponent.RELATIONAL_IDS.includes(value) &&
        (onlyArea ? name.toLowerCase().includes('area') : true))
      .map(([name, value]) => ({
        name,
        text: `PROPERTIES.${name.toUpperCase()}`,
        areaId: value,
        areaValue: facility.Properties[name],
      }))
      .filter(enumObj => {
        switch (enumObj.areaId) {
          case FacilityPropertyEnums.GrossArea: return facility.Properties['GrossArea'] !== undefined;
          case FacilityPropertyEnums.GrossVolume: return facility.Properties['GrossVolume'] !== undefined;
          case FacilityPropertyEnums.NetFloorArea: return facility.Properties['NetFloorArea'] !== undefined;
          case FacilityPropertyEnums.TotalVolume: return facility.Properties['TotalVolume'] !== undefined;
          case FacilityPropertyEnums.HeatedArea: return facility.Properties['HeatedArea'] !== undefined;
          case FacilityPropertyEnums.HeatedVolume: return facility.Properties['HeatedVolume'] !== undefined;
          case FacilityPropertyEnums.Area: return facility.Properties['Area'] !== undefined;
          case FacilityPropertyEnums.Volume: return facility.Properties['Volume'] !== undefined;
          case FacilityPropertyEnums.TotalArea: return facility.Properties['TotalArea'] !== undefined;
          default: return false;
        }
      });
  }

  public readonly energyMeasurementTypes: typeof EnergyMeasurementType = EnergyMeasurementType;

  public facilities$: Observable<ExtendedFacilityInformation[]>;
  public areaItems$: Observable<EtCurveAreaItem[]>;
  public areaTypes$: Observable<ComboItem<number>[]>;

  public controls: ControlsOf<ETCurveSearchParams>;

  private readonly _destroy = new Subject<void>();

  public constructor(
    public readonly formGroup: FormGroupDirective,
    private readonly facilityService: FacilityService
  ) {}

  public ngOnInit(): void {
    this.controls = this.formGroup.control.controls as ControlsOf<ETCurveSearchParams>;

    this.facilities$ = this.facilityService.filteredProfileFacilities$.pipe(
      take(1),
      takeUntil(this._destroy)
    );

    this.areaItems$ = combineLatest([
      this.controls.facilityId.valueChanges.pipe(
        startWith(this.controls.facilityId.value),
        filter(facilityId => Number.isFinite(facilityId))
      ),
      this.controls.datatype.valueChanges,
    ]).pipe(
      switchMap(([facilityId, dataType]) => {
        if (dataType !== EnergyMeasurementType.SPECIFIC_ENERGY) {
          this.controls.specificId.setValue(null);
          this.controls.specificValue.setValue(null);
          return of(null);
        }

        return this.facilities$.pipe(
          take(1),
          map(facilityList => facilityList.find(f => f.facilityId === facilityId))
        );
      }),
      map(facility => Number.isFinite(facility?.facilityId) ?
        EtCurveEnergyMeasurementComponent.initRelationalValues(facility, true) :
        null),
      shareReplay(1),
      takeUntil(this._destroy)
    );

    this.areaTypes$ = this.areaItems$.pipe(
      map(items => items?.map(area => ({ value: area.areaId, text: area.text })) ?? []),
      tap(areas => {
        const containsAreaType = areas.map(area => area.value).includes(this.controls.specificId.value);

        // Reset selected area type if active type is not valid for the current list
        if (!containsAreaType || (this.controls.specificId.value === null && areas.length)) {
          this.controls.specificId.setValue(areas.length ? areas[0].value : null);
        }
      }),
      shareReplay(1),
      takeUntil(this._destroy)
    );

    combineLatest([
      this.controls.specificId.valueChanges,
      this.areaItems$
    ]).pipe(
      tap(([specificId, areaItems]) => {
        if (!Number.isFinite(specificId)) {
          this.controls.specificValue.setValue(null);
          return;
        }

        const areaItem = areaItems.find(item => item.areaId === specificId);
        this.controls.specificValue.setValue(areaItem ? areaItem.areaValue : null);
      }),
      takeUntil(this._destroy)
    ).subscribe();

  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

}

