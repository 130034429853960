import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { take } from 'rxjs';

import { flattenGroupedData } from '@enerkey/ts-utils';

import { KendoGridService } from '../../services/kendo-grid.service';

@Component({
  selector: 'kendo-grid-group-header-checkbox',
  templateUrl: './kendo-grid-group-header-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KendoGridGroupHeaderCheckboxComponent<T, K extends keyof T> implements OnInit {
  @Input() public items: T[];

  public state: boolean = false;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly grid: GridComponent,
    private readonly gridService: KendoGridService<T, K>
  ) {
  }

  public ngOnInit(): void {
    this.gridService.selection$.subscribe(() => this.updateState());
  }

  public selectAllChange(checked: boolean): void {
    this.gridService.selection$.pipe(take(1)).subscribe({
      next: previousSelection => {
        const ids = flattenGroupedData(this.items).map(i => i[this.gridService.selectBy]);
        const newSelection = checked
          ? [...previousSelection, ...ids].unique()
          : previousSelection.filter(id => !ids.includes(id))
        ;
        this.gridService.setSelectedItems(newSelection);
      }
    });
  }

  private updateState(): void {
    const newState = this.gridService.getCheckboxState(this.items);
    if (this.state !== newState) {
      this.state = newState;
      this.detectChanges();
    }
  }

  private detectChanges(): void {
    // This whole code could possibly be moved into a zone.onStable-callback if desync
    // between grid UI and data state happens
    this.changeDetector.detectChanges();

    const gridCd: ChangeDetectorRef = this.grid['changeDetectorRef'];
    gridCd.detectChanges();
  }
}
