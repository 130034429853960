<div class="content-header">
  <ul class="tabs">
    <li
      *ngFor="let tab of tabs"
      class="tabs__item"
      uiSrefActive="tabs__item--active"
    >
      <a
        class="tabs__item-link"
        [uiSref]="tab.state"
      >
        {{ tab.name | translate }}
      </a>
    </li>
  </ul>
</div>
<div ui-view="supervision"></div>
