import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AxisDefaults, AxisLabelVisualArgs } from '@progress/kendo-angular-charts';
import { Element } from '@progress/kendo-drawing';

import { QuantityService } from '../../../../shared/services/quantity.service';
import { Quantities } from '@enerkey/clients/metering';
import { EmissionsAggregateValue } from '../../shared/emissions';
import { chartLineColor } from '../../../../constants/chart-constants';

@Component({
  selector: 'emissions-widget-chart',
  templateUrl: './emissions-widget-chart.component.html',
  styleUrls: ['./emissions-widget-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmissionsWidgetChartComponent implements OnInit {
  @Input() public data: EmissionsAggregateValue[][];

  public readonly blackColor = chartLineColor;

  public readonly valueField = 'value';
  public readonly categoryField = 'quantityId';

  public readonly axisDefaults: AxisDefaults = {
    majorGridLines: { visible: true },
    minorGridLines: { visible: false },
    line: {
      color: chartLineColor
    }
  };

  public categories: Quantities[];

  public constructor(private readonly quantityService: QuantityService) {
    this.contentVisual = this.contentVisual.bind(this);
  }

  public contentVisual(args: AxisLabelVisualArgs): Element {
    const defaultVisual = args.createVisual();
    return this.quantityService.getKendoChartQuantityIcon(
      args.dataItem.quantityId,
      args.dataItem.color,
      defaultVisual.bbox().topLeft()
    );
  }

  public ngOnInit(): void {
    this.categories = this.data[0].map(item => item.quantityId);
  }
}
