import _ from 'lodash';

const $inject = [
  '$scope', '$q', '$timeout', 'ERDataService2', 'meters', 'consumptions', 'utils',
  'ColorService', 'erParamsService', 'LoadingService'
];

function MetersReportComparisonController(
  $scope, $q, $timeout, ERDataService2, meters, consumptions, utils,
  ColorService, erParamsService, LoadingService
) {
  const vm = this;

  $scope.defaultTimer = 200;
  vm.isLoading = () => LoadingService.isLoading();

  // settings and data
  $scope.settings = {
    meters: [],
    selectedMeters: [],
    maxMetersCount: 15,
    savedData: {}
  };
  $scope.reportData = [];

  // indicators for highlighting
  const indicators = [
    'indicator--light-blue',
    'indicator--pink',
    'indicator--green',
    'indicator--lilac',
    'indicator--orange',
    'indicator--dark-blue',
    'indicator--light-lilac',
    'indicator--dark-green',
    'indicator--dark-yellow',
    'indicator--dark-pink',
    'indicator--yellow',
    'indicator--grey',
    'indicator--light-pink',
    'indicator--blue',
    'indicator--light-green'
  ];

  // localized texts for multiselect
  $scope.localizedMultiSelectTexts = angular.extend(
    utils.istevenMultiSelectTranslation(),
    { nothingSelected: utils.localizedString('FACILITIES_REPORT.CHOOSE_METERS') }
  );

  $scope.regenerateData = _.debounce(populateMultiselect => {
    getFacilityMeters()
      .then(facilityMeters => {
        if (populateMultiselect || (!$scope.settings.meters.length && $scope.params.meterId.length)) {
          populateMeterMultiselect(facilityMeters);
        }
        const series = getSeries();
        const payload = getPayload(series);

        if (
          erParamsService.validateSeries(series) &&
          payload &&
          !_.isEqual($scope.settings.savedData.payload, payload)
        ) {
          // save payload
          $scope.settings.savedData.payload = payload;
          highlightMultiselect(payload);
          return getData(series, payload, facilityMeters);
        }
      })
      .catch((error, title) => {
        utils.trackError(error, 'MetersReportComparisonController regenerate');
        erParamsService.handleError(error, title);
      });
  }, $scope.defaultTimer);

  function populateMeterMultiselect(facilityMeters) {
    $scope.settings.selectedMeters = _.filter(
      $scope.params.view.selectedMeters,
      meter => _.includes($scope.params.meterId, meter.Id)
    );
    const doAutomaticalSelection = !$scope.settings.selectedMeters.length;
    const selectedIds = _.pluck($scope.settings.selectedMeters, 'Id');
    $scope.settings.meters = _.reduce(facilityMeters, function(result, meter) {
      if (_.includes($scope.params.meterId, meter.Id)) {
        const clonedMeter = _.cloneDeep(meter);
        if (doAutomaticalSelection && $scope.settings.selectedMeters.length < $scope.settings.maxMetersCount) {
          clonedMeter.selected = true;
          $scope.settings.selectedMeters.push(clonedMeter);
        } else {
          clonedMeter.selected = _.includes(selectedIds, meter.Id);
        }
        clonedMeter.disabled =
          $scope.settings.selectedMeters.length >= $scope.settings.maxMetersCount && !clonedMeter.selected;
        result.push(clonedMeter);
      }
      return result;
    }, []);
  }

  function highlightMultiselect(payload) {
    _.each($scope.settings.meters, function(meter) {
      meter.icon = meter.selected ? kendo.format(
        '<span class="indicator {0}">{1}</span>',
        indicators[_.indexOf(payload.MeterId, meter.Id) % indicators.length],
        ' '
      ) : void 0;
    });
  }

  function getData(series, payload, facilityMeters) {
    const deferred = $q.defer();

    if (series && series.ExportOnly) {
      createReport({}, series, payload, facilityMeters);
      deferred.resolve();
    } else {
      getReportData(series, payload)
        .then(function(responseData) {
          if ($scope.settings.savedData.payload === payload) {
            createReport(responseData, series, payload, facilityMeters);
          }
        })
        .catch(function() {
          deferred.reject();
        })
        .finally(function() {
          deferred.resolve();
        })
      ;
    }

    return deferred.promise;
  }

  function getFacilityMeters() {
    if ($scope.params.facilityId.length > 0) {
      const facilityId = $scope.params.facilityId[0];
      return meters.getMetersForFacility(facilityId);
    }
  }

  function getReportData(series, payload) {
    return consumptions.getConsumptionsForMeters(payload);
  }

  function getSeries() {
    // meters does not want relational values
    return _.assign({}, $scope.params.series, { RelationalUnitIds: void 0 });
  }

  function getPayload(series) {
    // get all quantities determined in meters
    const quantityIds = _.uniq(_.pluck($scope.settings.selectedMeters, 'QuantityId'));
    const facilityIds = $scope.params.facilityId.length ? [$scope.params.facilityId[0]] : [];

    if (erParamsService.validateSeries(series) && facilityIds.length) {
      const result = {
        Quantities: _.chain(quantityIds)
          .reduce(function(result, quantityId) {
            const quantity = erParamsService.getQuantity($scope.cache, quantityId);
            if (quantity) {
              result.push({
                ID: quantityId,
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization && series.Normalized,
                RelatedValues: erParamsService
                  .filterRelatedValueIdsForQuantity($scope.cache, series.RelatedValues, quantityId)
              });
            }
            return result;
          }, [])
          .value(),
        Resolution: series.Resolution,
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        FacilityId: facilityIds,
        MeterId: _.pluck($scope.settings.selectedMeters, 'Id'),
        Unit: $scope.params.unitKey
      };

      return result;
    }
    return void 0;
  }

  function createReport(responseData, series, payload, facilityMeters) {
    const options = {
      idType: 'Meter',
      series: series,
      params: $scope.params,
      cache: _.extend($scope.cache, { metersById: _.indexBy(facilityMeters, 'Id') }),
      api: {
        requestData: payload,
        responseData: responseData
      },
      show: {
        distributionAndValue: false,
        relationalValueOnlyForFollowUp: false,
        relatedValueOnlyForFollowUp: false
      },
      chart: {
        serieClick: {
          enabled: true
        }
      }
    };

    // get charts for meters
    const charts = _.get(ERDataService2.getVisuals(options), 'charts', {});

    // combine same properties into same graph
    const reportData = [];

    _.each(payload.MeterId, function(meterId, meterIndex) {
      const meter = $scope.cache.metersById[meterId];
      if (meter && _.isArray(charts[meterId]) && charts[meterId].length) {
        const indicator = indicators[meterIndex % indicators.length];
        const color = utils.getStyleRuleValue('background-color', '.' + indicator, true) || '#000000';
        _.each(charts[meterId], function(graph) {
          // Meters w/ no consumption have no graph/chart data
          if (!graph || !graph.chartOptions) {
            return true; // returning false exits _.each loop
          }

          _.each(graph.chartOptions.series, function(serie) {
            const quantity = serie.quantity;
            if (quantity) {
              const criteria =
                serie.property === 'NormalizedReading.Value' ||
                serie.property === 'Reading.Value' ||
                _.startsWith(serie.property, 'DistributionValues') ?
                  { property: serie.property } :
                  { property: serie.property, quantity: quantity };
              let combinedGraph = _.find(reportData, criteria);
              if (!combinedGraph) {
                combinedGraph = _.extend({
                  title: (criteria.quantity ? criteria.quantity.Name + ' - ' : '') + serie.title,
                  chartOptions: _.extend(_.cloneDeep(graph.chartOptions), {
                    series: [],
                    valueAxis: [
                      {
                        name: 'Values',
                        narrowRange: false
                      }
                    ]
                  })
                }, criteria);
                reportData.push(combinedGraph);
              }
              const dashType = serie.startIndex === series.Start.length - 2 ? 'longDash' : 'dot';
              const newSerie = _.extend(_.cloneDeep(serie), {
                name: meter.Name + ' ' + serie.name,
                type: 'line',
                style: 'smooth',
                tooltip: serie.tooltip || graph.chartOptions.tooltip,
                axis: 'Values',
                color: color,
                dashType: serie.startIndex === series.Start.length - 1 ? 'solid' : dashType,
                visible: serie.startIndex === series.Start.length - 1,
                stack: false
              });
              // create axis if needed
              if (!criteria.quantity) {
                const removedAxis = _.remove(combinedGraph.chartOptions.valueAxis, { name: 'Values' });
                if (removedAxis.length) {
                  combinedGraph.chartOptions.categoryAxis.axisCrossingValues = [];
                }
                const axis = _.find(combinedGraph.chartOptions.valueAxis, { name: quantity.Key });
                if (!axis) {
                  combinedGraph.chartOptions.valueAxis.push({
                    name: quantity.Key,
                    color: ColorService.getMainPrimaryGraphColor(quantity.ID)
                  });
                  combinedGraph.chartOptions.categoryAxis.axisCrossingValues.push(0);
                }
                newSerie.axis = quantity.Key;
              }
              combinedGraph.chartOptions.series.push(newSerie);
            }
          });
        });
      }
    });

    // clean up graphs that have 0-1 series
    _.remove(reportData, function(graph) {
      return graph.chartOptions.series.length < 2;
    });

    // post process each graph height and add some space for legend
    _.each(reportData, function(graph) {
      // height based on character count of all series
      const seriesCharacterCount = _.reduce(graph.chartOptions.series, (result, serie) => {
        result += serie.name.length; return result;
      }, 0);
      graph.chartOptions.chartArea.height += Math.floor(seriesCharacterCount / 250) * 25;
    });

    // set report data
    $scope.reportData = reportData;
  }

  $scope.onSelectMeter = function() {
    $timeout(function() {
      const disableAllUnselected = $scope.settings.selectedMeters.length >= $scope.settings.maxMetersCount;
      if (disableAllUnselected) {
        _.each($scope.settings.meters, function(meter) {
          meter.disabled = !meter.selected;
        });
      } else {
        _.each(_.filter($scope.settings.meters, { disabled: true }), function(meter) {
          meter.disabled = false;
        });
      }
    });
  };

  $scope.onClosingSelectMeters = function() {
    const oldMeterId = $scope.settings.savedData.payload ? $scope.settings.savedData.payload.MeterId : void 0;
    const newMeterId = _.pluck($scope.settings.selectedMeters, 'Id');
    if (!_.isEqual(oldMeterId, newMeterId)) {
      $scope.params.view.selectedMeters = $scope.settings.selectedMeters;
      $scope.regenerateData();
    }
  };

  vm.$onChanges = () => {
    if (!$scope.params) {
      return;
    }
    const paramsData = erParamsService.getChangedParamsConfig(vm.reportParams, $scope.params);
    $scope.params = vm.reportParams;
    const interestedParams = _.without(_.get(paramsData, 'changedParams'), 'sections', 'view');
    if (interestedParams.length || _.get(paramsData, 'forceUpdate')) {
      $scope.regenerateData(_.includes(interestedParams, 'meterId'));
    }
  };

  vm.$onInit = () => {
    $scope.params = vm.reportParams;
    $scope.cache = vm.cache;
    $scope.regenerateData(true);
  };
}

MetersReportComparisonController.$inject = $inject;

export default MetersReportComparisonController;
