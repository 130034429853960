import { format } from 'date-fns';

import TimeFrame from '../../../../services/time-frame-service';
import SpreadsheetRange from '../../../../shared/spreadsheet-range';
import * as Colors from '../../constants/colors';
import * as Utils from '../../shared/utils.functions';
import * as FetchType from '../manual-qa-fetch-type/manual-qa-fetch-type.constant';
import * as FetchTypeFunctions from '../manual-qa-fetch-type/manual-qa-fetch-type.functions';
import * as SpreadsheetConstants from './manual-qa-spreadsheet.constants';

const columnMappings = {
  [FetchType.HOUR]: {
    [SpreadsheetConstants.COLUMNS.ACTUAL_TIMESTAMP]: 'A',
    [SpreadsheetConstants.COLUMNS.ACTUAL_CUMULATIVE]: 'B',
    [SpreadsheetConstants.COLUMNS.ACTUAL_CONSUMPTION]: 'B',
    [SpreadsheetConstants.COLUMNS.MODEL_CUMULATIVE]: 'C',
    [SpreadsheetConstants.COLUMNS.MODEL_CONSUMPTION]: 'C',
    [SpreadsheetConstants.COLUMNS.PREVIEW_CONSUMPTION]: 'D',
    [SpreadsheetConstants.COLUMNS.COMPARISON_CONSUMPTION]: 'E',
    [SpreadsheetConstants.COLUMNS.COMPARISON_TIMESTAMP]: 'F'
  },
  [FetchType.CUMULATIVE]: {
    [SpreadsheetConstants.COLUMNS.ACTUAL_TIMESTAMP]: 'A',
    [SpreadsheetConstants.COLUMNS.ACTUAL_CUMULATIVE]: 'B',
    [SpreadsheetConstants.COLUMNS.ACTUAL_CONSUMPTION]: 'B',
    [SpreadsheetConstants.COLUMNS.MODEL_CUMULATIVE]: 'C',
    [SpreadsheetConstants.COLUMNS.MODEL_CONSUMPTION]: 'C',
    [SpreadsheetConstants.COLUMNS.PREVIEW_CONSUMPTION]: 'D',
    [SpreadsheetConstants.COLUMNS.COMPARISON_CONSUMPTION]: 'E',
    [SpreadsheetConstants.COLUMNS.COMPARISON_TIMESTAMP]: 'F'
  },
  [FetchType.BOTH]: {
    [SpreadsheetConstants.COLUMNS.ACTUAL_TIMESTAMP]: 'A',
    [SpreadsheetConstants.COLUMNS.ACTUAL_CUMULATIVE]: 'B',
    [SpreadsheetConstants.COLUMNS.ACTUAL_CONSUMPTION]: 'C',
    [SpreadsheetConstants.COLUMNS.MODEL_CUMULATIVE]: 'D',
    [SpreadsheetConstants.COLUMNS.MODEL_CONSUMPTION]: 'E',
    [SpreadsheetConstants.COLUMNS.PREVIEW_CONSUMPTION]: 'F',
    [SpreadsheetConstants.COLUMNS.COMPARISON_CONSUMPTION]: 'G',
    [SpreadsheetConstants.COLUMNS.COMPARISON_TIMESTAMP]: 'H'
  }
};

function formatTimestamp(timestamp) {
  return timestamp instanceof Date
    ? format(timestamp, 'dd.MM.yyyy HH:mm')
    : ''
  ;
}

/**
 * Returns indexes of locked rows.
 *
 * @param {Reading[]} readings
 *
 * @returns {Array}
 */
export const getLockedRowIndexes = readings => readings.reduce(
  (result, reading) => reading.isLocked ? result.concat(reading.rowIndex) : result,
  []
);

/**
 * Returns column character for given column index
 *
 * @param {number} columnIndex
 * @param {number} [fetchType]
 *
 * @returns {string}
 */
export const getColumnCharacter = (columnIndex, fetchType = FetchType.BOTH) => columnMappings[fetchType][columnIndex];

/**
 * Returns spreadsheet row index for given row index
 *
 * @param {number} rowIndex
 *
 * @returns {number}
 */
export const getRowIndex = rowIndex => SpreadsheetConstants.READING_START_ROW + rowIndex;

/**
 * Returns operation range for readings
 *
 * @param {Reading[]} readings
 * @param {TimeFrame} timeFrame
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange}
 */
export const getRangeWithTimeFrame = (readings, timeFrame, fetchType = FetchType.BOTH) => {
  const { firstReading, lastReading } = getFirstAndLastReading(readings, timeFrame);

  return new SpreadsheetRange(
    getColumnCharacter(SpreadsheetConstants.COLUMNS.ACTUAL_TIMESTAMP, fetchType),
    getRowIndex(firstReading.rowIndex),
    getColumnCharacter(SpreadsheetConstants.COLUMNS.ACTUAL_TIMESTAMP, fetchType),
    getRowIndex(lastReading.rowIndex)
  );
};

/**
 * Returns first and last reading from given readings array so
 * that those readings are inside of given time frame.
 *
 * @param {Reading[]} readings
 * @param {TimeFrame} timeFrame
 *
 * @returns {{firstReading: Reading, lastReading: Reading}}
 */
export const getFirstAndLastReading = (readings, timeFrame) => {
  let readingsWithinTimeFrame = Utils.getReadingsWithinTimeFrame(readings, timeFrame);

  // If no readings are found within timeFrame, set whole data as range
  if (readingsWithinTimeFrame.length === 0) {
    readingsWithinTimeFrame = readings;
  }

  return {
    firstReading: readingsWithinTimeFrame[0],
    lastReading: readingsWithinTimeFrame[readingsWithinTimeFrame.length - 1]
  };
};

/**
 * Returns range for actual values
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange}
 */
export const getActualValuesRange = (readings, fetchType = FetchType.BOTH) => new SpreadsheetRange(
  getColumnCharacter(SpreadsheetConstants.COLUMNS.ACTUAL_TIMESTAMP, fetchType),
  getRowIndex(0),
  getColumnCharacter(SpreadsheetConstants.COLUMNS.ACTUAL_CONSUMPTION, fetchType),
  getRowIndex(readings.length - 1)
);

/**
 * Returns range for comparison values
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange}
 */
export const getComparisonValuesRange = (readings, fetchType = FetchType.BOTH) => new SpreadsheetRange(
  getColumnCharacter(SpreadsheetConstants.COLUMNS.COMPARISON_CONSUMPTION, fetchType),
  getRowIndex(0),
  getColumnCharacter(SpreadsheetConstants.COLUMNS.COMPARISON_TIMESTAMP, fetchType),
  getRowIndex(readings.length - 1)
);

/**
 * Returns range for whole spreadsheet
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange}
 */
export const getFullSpreadsheetRange = (readings, fetchType = FetchType.BOTH) => new SpreadsheetRange(
  getColumnCharacter(SpreadsheetConstants.COLUMNS.ACTUAL_TIMESTAMP, fetchType),
  getRowIndex(0),
  getColumnCharacter(SpreadsheetConstants.COLUMNS.COMPARISON_TIMESTAMP, fetchType),
  getRowIndex(readings.length - 1)
);

/**
 * Returns range for model values
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange}
 */
export const getModelValuesRange = (readings, fetchType = FetchType.BOTH) => new SpreadsheetRange(
  getColumnCharacter(SpreadsheetConstants.COLUMNS.MODEL_CUMULATIVE, fetchType),
  getRowIndex(0),
  getColumnCharacter(SpreadsheetConstants.COLUMNS.MODEL_CONSUMPTION, fetchType),
  getRowIndex(readings.length - 1)
);

/**
 * Returns range for preview values
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange}
 */
export const getPreviewValuesRange = (readings, fetchType = FetchType.BOTH) => new SpreadsheetRange(
  getColumnCharacter(SpreadsheetConstants.COLUMNS.PREVIEW_CONSUMPTION, fetchType),
  getRowIndex(0),
  getColumnCharacter(SpreadsheetConstants.COLUMNS.PREVIEW_CONSUMPTION, fetchType),
  getRowIndex(readings.length - 1)
);

/**
 * Returns array of spreadsheet ranges that contains defects
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange[]}
 */
export const getDefectRanges = (readings, fetchType = FetchType.BOTH) =>
  rangeArrayToSpreadsheetActualRanges(Utils.toRangeArrays(getDefectRowIndexes(readings)), fetchType);

/**
 * Returns array of spreadsheet ranges that contains faults
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange[]}
 */
export const getFaultRanges = (readings, fetchType = FetchType.BOTH) =>
  rangeArrayToSpreadsheetActualRanges(Utils.toRangeArrays(getFaultRowIndexes(readings)), fetchType);

/**
 * Returns row indexes that contains some kind of fault
 *
 * @param {Reading[]} readings
 *
 * @returns {Array}
 */
export const getFaultRowIndexes = readings => readings
  .reduce((result, reading) => reading.isFaulty ? result.concat(reading.rowIndex) : result, []);

/**
 * Returns row indexes that are inside of defects time frame
 *
 * @param {Reading[]} readings
 *
 * @returns {Array}
 */
export const getDefectRowIndexes = readings => readings
  .reduce((result, reading) => reading.belongsToDefectIssue ? result.concat(reading.rowIndex) : result, []);

/**
 * Maps range array to spreadsheet ranges
 *
 * @param {Array} ranges
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange[]}
 */
export const rangeArrayToSpreadsheetActualRanges = (ranges, fetchType = FetchType.BOTH) => ranges.map(range =>
  new SpreadsheetRange(
    getColumnCharacter(SpreadsheetConstants.COLUMNS.ACTUAL_CONSUMPTION, fetchType),
    getRowIndex(range[0]),
    getColumnCharacter(SpreadsheetConstants.COLUMNS.ACTUAL_CUMULATIVE, fetchType),
    getRowIndex(range[1])
  )
);

/**
 * Maps range array to spreadsheet ranges
 *
 * @param {Array} ranges
 * @param {number} [fetchType]
 *
 * @returns {SpreadsheetRange[]}
 */
export const rangeArrayToSpreadsheetModelRanges = (ranges, fetchType = FetchType.BOTH) => ranges.map(range =>
  new SpreadsheetRange(
    getColumnCharacter(SpreadsheetConstants.COLUMNS.MODEL_CUMULATIVE, fetchType),
    getRowIndex(range[0]),
    getColumnCharacter(SpreadsheetConstants.COLUMNS.MODEL_CONSUMPTION, fetchType),
    getRowIndex(range[1])
  )
);

/**
 * Returns array of spreadsheet ranges for locked modelled readings
 *
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const getLockedModelValuesRanges = (readings, fetchType = FetchType.BOTH) =>
  rangeArrayToSpreadsheetModelRanges(Utils.toRangeArrays(getLockedRowIndexes(readings)), fetchType);

/**
 * Returns spreadsheet rows count including headings and ONE EXTRA row which will
 * be located at the end of spreadsheet. This is needed when we hide all rows
 * from spreadsheet. Otherwise it will mess up the spreadsheet headers
 *
 * @param {Reading[]} readings
 *
 * @returns {number}
 */
export const getRowsCount = readings =>
  readings.length > 0 ?
    (readings.length - 1) + SpreadsheetConstants.READING_START_ROW :
    SpreadsheetConstants.DEFAULT_ROW_COUNT;

/**
 * Converts actual values to array so kendo spreadsheet
 * can handle it.
 *
 * @param {Reading} reading
 * @param {number} fetchType
 *
 * @returns {Array}
 */
export const getActualValuesArray = (reading, fetchType) => {
  const values = [];

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    values.push(reading.actualCumulative);
  }

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    values.push(reading.actualConsumption);
  }

  return [
    formatTimestamp(reading.timestamp),
    ...values
  ];
};

/**
 * Converts actual values to array so kendo spreadsheet
 * can handle it.
 *
 * @param {Reading} reading
 * @param {number} fetchType
 *
 * @returns {Array}
 */
export const getModelValuesArray = (reading, fetchType) => {
  const values = [];

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    values.push(reading.modelCumulative);
  }

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    values.push(reading.modelConsumption);
  }

  return values;
};

/**
 * Converts preview values to array so kendo spreadsheet
 * can handle it.
 *
 * @param {Reading} reading
 *
 * @returns {Array}
 */
export const getPreviewValuesArray = reading => [reading.previewConsumption];

/**
 * Converts comparison values to array so kendo spreadsheet
 * can handle it.
 *
 * @param {Reading} reading
 *
 * @returns {Array}
 */
export const getComparisonValuesArray = reading => [
  reading.comparisonConsumption,
  formatTimestamp(reading.comparisonTimestamp),
];

/**
 * Updates operation range background color for whole spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {TimeFrame} timeFrame
 * @param {number} [fetchType]
 */
export const updateOperationRangeBackground = (spreadsheet, readings, timeFrame, fetchType = FetchType.BOTH) => {
  const range = getRangeWithTimeFrame(readings, timeFrame, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .background(Colors.OPERATION_PERIOD_BACKGROUND)
  ;
};

/**
 * Clears background from every cell in spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const resetSpreadsheetBackground = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getFullSpreadsheetRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .background(Colors.DEFAULT_BACKGROUND)
    .borderBottom({ size: 0 })
    .borderLeft({ size: 0 })
    .borderRight({ size: 0 })
    .borderTop({ size: 0 })
    .bold(false)
  ;
};

/**
 * Updates actual values background color for whole spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateActualValuesBackground = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getActualValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .background(Colors.DEFAULT_BACKGROUND)
  ;
};

/**
 * Updates model values background color for whole spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateModelValuesBackground = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getModelValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .background(Colors.DEFAULT_BACKGROUND)
  ;
};

/**
 * Updates comparison values background color for whole spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateComparisonValuesBackground = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getComparisonValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .background(Colors.DEFAULT_BACKGROUND)
  ;
};

/**
 * Updates locked values background color for whole spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateLockedRangeBackground = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const ranges = getLockedModelValuesRanges(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  ranges.forEach(range => {
    activeSheet
      .range(range.toString())
      .background(Colors.LOCKED_READING_BACKGROUND)
    ;
  });
};

/**
 * Updates background for defect ranges
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateDefectRangeBackground = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const ranges = getDefectRanges(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  ranges.forEach(range => {
    const _range = activeSheet.range(range.toString());

    _range.leftColumn().borderLeft({ size: 1.5, color: Colors.DEFECTS_BORDER });
    _range.topRow().borderTop({ size: 1.5, color: Colors.DEFECTS_BORDER });
    _range.rightColumn().borderRight({ size: 1.5, color: Colors.DEFECTS_BORDER });
    _range.bottomRow().borderBottom({ size: 1.5, color: Colors.DEFECTS_BORDER });
  });
};

/**
 * Updates background for fault ranges
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateFaultRangeBackground = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const ranges = getFaultRanges(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  ranges.forEach(range => {
    activeSheet
      .range(range.toString())
      .background(Colors.DEFECTS_BACKGROUND)
      .bold(true)
    ;
  });
};

/**
 * Updates actual values to spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateActualValues = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getActualValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .values(readings.map(reading => getActualValuesArray(reading, fetchType)))
    .format(SpreadsheetConstants.DEFAULT_CELL_FORMAT)
  ;
};

/**
 * Updates model values to spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateModelValues = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getModelValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .values(readings.map(reading => getModelValuesArray(reading, fetchType)))
    .format(SpreadsheetConstants.DEFAULT_CELL_FORMAT)
  ;
};

/**
 * Updates preview values to spreadsheet
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updatePreviewValues = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getPreviewValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .values(readings.map(reading => getPreviewValuesArray(reading)))
    .format(SpreadsheetConstants.DEFAULT_CELL_FORMAT)
  ;
};

/**
 * Updates comparison values to spreadsheet and changes rows background color
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const updateComparisonValues = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const range = getComparisonValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(range.toString())
    .values(readings.map(reading => getComparisonValuesArray(reading)))
    .format(SpreadsheetConstants.DEFAULT_CELL_FORMAT)
  ;
};

/**
 * Disables editing on other columns than preview consumption
 *
 * @param {Object} spreadsheet
 * @param {Reading[]} readings
 * @param {number} [fetchType]
 */
export const lockSpreadsheet = (spreadsheet, readings, fetchType = FetchType.BOTH) => {
  const fullRange = getFullSpreadsheetRange(readings, fetchType);
  const previewRange = getPreviewValuesRange(readings, fetchType);
  const activeSheet = spreadsheet.activeSheet();

  activeSheet
    .range(fullRange.toString())
    .enable(false)
  ;

  activeSheet
    .range(previewRange.toString())
    .enable(true)
  ;
};

export const getHeaderCells = (translations, fetchType, useComparisonPeriod) =>
  [
    ...getActualHeaderCells(translations, fetchType),
    ...getModelHeaderCells(translations, fetchType),
    ...getPreviewHeaderCells(translations, fetchType),
    ...getComparisonHeaderCells(translations, fetchType, useComparisonPeriod)
  ];

export const getSecondaryHeaderCells = (translations, fetchType, useComparisonPeriod) =>
  [
    ...getActualSecondaryHeaderCells(translations, fetchType),
    ...getModelSecondaryHeaderCells(translations, fetchType),
    ...getPreviewSecondaryHeaderCells(translations, fetchType),
    ...getComparisonSecondaryHeaderCells(translations, fetchType, useComparisonPeriod)
  ];

export const getSpreadsheetColumnsWidth = (fetchType, useComparisonPeriod) => [
  ...getActualValuesColumnWidths(fetchType),
  ...getModelColumnWidths(fetchType),
  ...getPreviewColumnWidths(fetchType),
  ...getComparisonColumnWidths(fetchType, useComparisonPeriod)
];

export const getSpreadsheetMergedColumns = (fetchType, useComparisonPeriod) => {
  const fetchTypeBoth = FetchTypeFunctions.isFetchTypeBoth(fetchType);
  const basicColumns = fetchTypeBoth ? ['A1:C1', 'D1:E1'] : ['A1:B1'];
  const comparisonColumns = useComparisonPeriod && fetchTypeBoth ? ['G1:H1'] : ['E1:F1'];

  return FetchTypeFunctions.isFetchTypeCumulative(fetchType) || !useComparisonPeriod ?
    [...basicColumns] :
    [...basicColumns, ...comparisonColumns];
};

const getActualHeaderCells = (translations, fetchType) => {
  const defaultHeaderCells = [
    {
      value: translations['MQA.INSPECT.SHEET.INSPECTION_PERIOD'],
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false,
      bold: true,
      borderBottom: {
        size: SpreadsheetConstants.HEADER_BORDER_SIZE,
        color: Colors.SERIES_ACTUAL_CONSUMPTION
      }
    }
  ];

  const headerCells = [];

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    headerCells.push({
      borderBottom: {
        size: SpreadsheetConstants.HEADER_BORDER_SIZE,
        color: Colors.SERIES_ACTUAL_CONSUMPTION
      }
    });
  }

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    headerCells.push({
      borderBottom: {
        size: SpreadsheetConstants.HEADER_BORDER_SIZE,
        color: Colors.SERIES_ACTUAL_CONSUMPTION
      }
    });
  }

  return [...defaultHeaderCells, ...headerCells];
};

const getModelHeaderCells = (translations, fetchType) => {
  if (FetchTypeFunctions.isFetchTypeHour(fetchType) || FetchTypeFunctions.isFetchTypeCumulative(fetchType)) {
    return [{
      value: translations['MQA.INSPECT.SHEET.MODEL'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false,
      bold: true,
      borderBottom: {
        size: SpreadsheetConstants.HEADER_BORDER_SIZE,
        color: Colors.SERIES_MODEL_CONSUMPTION
      }
    }];
  }

  return [
    {
      value: translations['MQA.INSPECT.SHEET.MODEL'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false,
      bold: true,
      borderBottom: {
        size: SpreadsheetConstants.HEADER_BORDER_SIZE,
        color: Colors.SERIES_MODEL_CONSUMPTION
      }
    },
    {
      borderBottom: {
        size: SpreadsheetConstants.HEADER_BORDER_SIZE,
        color: Colors.SERIES_MODEL_CONSUMPTION
      }
    }
  ];
};

const getPreviewHeaderCells = (translations, fetchType) =>
  FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType) ? [
    {
      value: translations['MQA.INSPECT.SHEET.PREVIEW'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false,
      bold: true,
      borderBottom: {
        size: SpreadsheetConstants.HEADER_BORDER_SIZE,
        color: Colors.SERIES_PREVIEW_CONSUMPTION
      }
    }
  ] : [];

const getComparisonHeaderCells = (translations, fetchType, useComparisonPeriod) =>
  useComparisonPeriod && FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType) ?
    [
      {
        value: translations['MQA.INSPECT.SHEET.COMPARISON_PERIOD'],
        background: Colors.DEFAULT_BACKGROUND,
        textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
        color: Colors.DEFAULT,
        enable: false,
        bold: true,
        borderBottom: {
          size: SpreadsheetConstants.HEADER_BORDER_SIZE,
          color: Colors.SERIES_COMPARISON_CONSUMPTION
        }
      },
      {
        borderBottom: {
          size: SpreadsheetConstants.HEADER_BORDER_SIZE,
          color: Colors.SERIES_COMPARISON_CONSUMPTION
        }
      }
    ] :
    [];

const getActualSecondaryHeaderCells = (translations, fetchType) => {
  const defaultHeaderCells = [
    {
      value: translations['MQA.INSPECT.SHEET.TIMESTAMP'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false
    }
  ];

  const headerCells = [];

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    headerCells.push({
      value: translations['MQA.INSPECT.ABBREVIATIONS.CUMULATIVE'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false
    });
  }

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    headerCells.push({
      value: translations['MQA.INSPECT.ABBREVIATIONS.HOURLY'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false
    });
  }

  return [...defaultHeaderCells, ...headerCells];
};

const getModelSecondaryHeaderCells = (translations, fetchType) => {
  const headerCells = [];

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    headerCells.push({
      value: translations['MQA.INSPECT.ABBREVIATIONS.CUMULATIVE'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false
    });
  }

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    headerCells.push({
      value: translations['MQA.INSPECT.ABBREVIATIONS.HOURLY'],
      background: Colors.DEFAULT_BACKGROUND,
      textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
      color: Colors.DEFAULT,
      enable: false
    });
  }

  return headerCells;
};

const getPreviewSecondaryHeaderCells = (translations, fetchType) =>
  FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType) ?
    [
      {
        value: translations['MQA.INSPECT.ABBREVIATIONS.HOURLY'],
        background: Colors.DEFAULT_BACKGROUND,
        textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
        color: Colors.DEFAULT,
        enable: false
      }
    ] :
    [];

const getComparisonSecondaryHeaderCells = (translations, fetchType, useComparisonPeriod) =>
  FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType) && useComparisonPeriod ?
    [
      {
        value: translations['MQA.INSPECT.ABBREVIATIONS.HOURLY'],
        background: Colors.DEFAULT_BACKGROUND,
        textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
        color: Colors.DEFAULT,
        enable: false
      },
      {
        value: translations['MQA.INSPECT.SHEET.TIMESTAMP'],
        background: Colors.DEFAULT_BACKGROUND,
        textAlign: SpreadsheetConstants.DEFAULT_TEXT_ALIGN,
        color: Colors.DEFAULT,
        enable: false
      }
    ] :
    [];

const getActualValuesColumnWidths = fetchType => FetchTypeFunctions.isFetchTypeBoth(fetchType) ?
  [{ width: 100 }, { width: 60 }, { width: 60 }] : [{ width: 100 }, { width: 60 }];

const getModelColumnWidths = fetchType => FetchTypeFunctions.isFetchTypeBoth(fetchType) ?
  [{ width: 60 }, { width: 60 }] : [{ width: 60 }];

const getPreviewColumnWidths = fetchType => FetchTypeFunctions.isFetchTypeCumulative(fetchType) ?
  [] : [{ width: 60 }];

const getComparisonColumnWidths = (fetchType, useComparisonPeriod) =>
  !FetchTypeFunctions.isFetchTypeCumulative(fetchType) && useComparisonPeriod ?
    [{ width: 60 }, { width: 100 }] : [];
