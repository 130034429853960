import {
  Inject,
  Injectable,
} from '@angular/core';

import { NAVIGATOR } from '@enerkey/angular-utils';

@Injectable({ providedIn: 'root' })
export class ClipboardService {

  public constructor(
    @Inject(NAVIGATOR) private readonly _navigator: Navigator
  ) { }

  /**
   * Returns a promise that resolves if the copy event succeeds.
   */
  public copy(text: string): Promise<void> {
    if (!text) {
      return Promise.reject();
    }

    return this._navigator.clipboard.writeText(text);
  }
}
