import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiKeyInfo, UserManagementClient } from '@enerkey/clients/user-management';

import { ModalService, NgfActiveModal } from '@enerkey/foundation-angular';

import { ApiKeysComponent, ConfigurableApiKey } from '../api-keys/api-keys.component';
import { ToasterService } from '../../shared/services/toaster.service';
import { DialogService } from '../../shared/services/dialog.service';
import { UserService } from '../../services/user-service';
import { ProfileApiKeysCreateModalComponent } from '../profile-api-keys-create-modal/profile-api-keys-create-modal.component';

@Component({
  selector: 'profile-api-keys',
  templateUrl: '../api-keys/api-keys.component.html',
  styleUrls: ['../api-keys/api-keys.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileApiKeysComponent extends ApiKeysComponent {
  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly userService: UserService,
    toasterService: ToasterService,
    dialogService: DialogService,
    modalService: ModalService,
    currentModal: NgfActiveModal
  ) {
    super(toasterService, dialogService, modalService, currentModal);
  }

  public openCreateModal(keyType: ConfigurableApiKey, renew: boolean): void {
    const modal = this.modalService.open(ProfileApiKeysCreateModalComponent);
    modal.componentInstance.activeKeyType = keyType;
    modal.componentInstance.renewMode = renew;
    modal.componentInstance.refresh$ = this._refreshApiKeys$;
  }

  protected getKeys(): Observable<ApiKeyInfo[]> {
    return this.userManagementClient.get(
      this.userService.profileId
    );
  }

  protected deleteKey(key: ApiKeyInfo): Observable<unknown> {
    return this.userManagementClient.delete(
      this.userService.profileId,
      key.keyId
    );
  }
}
