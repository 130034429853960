import ManualQaSearchListEditModule       from '../manual-qa-search-list-edit';
import ManualQaSearchListButtonsComponent from './manual-qa-search-list-buttons.component';

const dependencies = [ManualQaSearchListEditModule.name];

const ManualQaSearchListButtonsModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-list-buttons', dependencies)
  .component('manualQaSearchListButtons', ManualQaSearchListButtonsComponent);

export default ManualQaSearchListButtonsModule;
