import ManualQaConsumptionInputController from './manual-qa-consumption-input-modal-controller';
import template                           from 'raw-loader!./manual-qa-consumption-input-modal.html';

const $inject = ['$modal'];

class ManualQaConsumptionInputModalService {
  constructor($modal) {
    this.$modal = $modal;
  }

  getModal(parameters) {
    return this.$modal.open({
      template: template,
      controller: ManualQaConsumptionInputController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'tiny',
      resolve: {
        parameters: () => parameters
      }
    });
  }
}

ManualQaConsumptionInputModalService.$inject = $inject;

export default ManualQaConsumptionInputModalService;

