import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { saveAs } from '@progress/kendo-file-saver';
import { GroupKey } from '@progress/kendo-angular-grid';
import { exportPDF, fit, geometry, Group } from '@progress/kendo-drawing';

import {
  ConfirmModalDismiss,
  ModalBase,
  ModalOptions,
  NgfActiveModal,
  NgfTabChangeEvent,
  NgfTabsetComponent
} from '@enerkey/foundation-angular';
import { confirmUnsavedChanges, getUnsavedChangesPromptFn } from '@enerkey/angular-utils';
import { Report } from '@enerkey/clients/sustainability';

import { GriReportService } from '../../services/gri-report.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { ProfileService } from '../../../../shared/services/profile.service';
import { CompaniesService } from '../../../../shared/services/companies.service';
import { GriChartsComponent } from '../gri-charts/gri-charts.component';

type DisplayMode = 'grid' | 'chart' | 'quantities';

@Component({
  selector: 'gri-report-modal',
  templateUrl: './gri-report-modal.component.html',
  styleUrls: ['./gri-report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  windowClass: 'semiFull modal-dialog-scrollable',
  keyboard: false,
})
export class GriReportModalComponent
  extends ModalBase
  implements AfterViewInit, OnDestroy, ConfirmModalDismiss {
  @ViewChild(NgfTabsetComponent) public readonly tabset: NgfTabsetComponent;

  public isReportModified: boolean = false;
  public displayMode: DisplayMode = 'grid';

  public report: Report;

  public expandedGroupKeys: GroupKey[] = [];

  public readonly loading$: Observable<boolean>;

  public readonly profileName$: Observable<string>;
  public readonly companyName$: Observable<string>;

  @ViewChild(GriChartsComponent) private readonly chartComponent: GriChartsComponent;

  private readonly _destroy$ = new Subject<void>();

  private readonly _unsavedChangeUnhookFn = confirmUnsavedChanges(() => this.isReportModified);
  private readonly _isModifiedPrompt = getUnsavedChangesPromptFn();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly griService: GriReportService,
    private readonly profileService: ProfileService,
    private readonly companiesService: CompaniesService,
    private readonly toaster: ToasterService
  ) {
    super(currentModal);
    this.loading$ = this.griService.loading$;

    this.profileName$ = this.profileService.profile$.pipe(
      map(profile => profile.name),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.companyName$ = this.companiesService.userCompany$.pipe(
      map(company => company?.name),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngAfterViewInit(): void {
    this.tabset.tabChange.subscribe({
      next: (tab: NgfTabChangeEvent) => {
        this.displayMode = tab.nextId as DisplayMode;
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._unsavedChangeUnhookFn();
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHandler($event: BeforeUnloadEvent): void {
    if (this.isReportModified) {
      $event.returnValue = '';
    }
  }

  public saveChanges(): void {
    this.griService.saveAllChanges().subscribe({
      next: () => {
        this.toaster.success('SUSTAINABILITY.GRI.SAVE_SUCCEEDED');
      },
      error: /* istanbul ignore next */() => {
        this.toaster.error('SUSTAINABILITY.GRI.SAVE_FAILED');
      },
    });
  }

  public closeReport(): void {
    if (this.confirmDismiss()) {
      super.closeModal();
    }
  }

  public confirmDismiss(): boolean {
    return !this.isReportModified || this._isModifiedPrompt();
  }

  public exportChart(): void {
    const filename = `${this.report.displayName}.png`;
    const chart = this.chartComponent.chart ?? this.chartComponent.totalsDonut.chart;
    chart.exportImage().then(dataURI => saveAs(dataURI, filename));
  }

  public exportSummaryPdf(): void {
    const filename = `${this.report.displayName}.pdf`;
    const page = new geometry.Rect([0, 0], [mm(297 - 10), mm(210 - 10)]);
    const content = new Group();
    this.chartComponent.pdfExportComponent.export()
      .then(component => {
        content.append(component);
        // eslint-disable-next-line jasmine/no-focused-tests
        fit(content, page);
        return exportPDF(content, {
          paperSize: 'A4',
          margin: '0.5cm',
          landscape: true,
        });
      })
      .then(dataURI => saveAs(dataURI, filename));
  }

}

/** Convert millimeters to points */
function mm(val: number): number {
  return val * 2.8347;
}
