import EmInputTextController from './em-input-text-controller';
import emInputTextTemplate   from 'raw-loader!./em-input-text.html';

export default {
  template: emInputTextTemplate,
  controller: EmInputTextController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    onChange: '<'
  }
};
