import { EventEmitter, Injector } from '@angular/core';
import { AbstractControlOptions, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { addHours, endOfDay, isSameDay } from 'date-fns';

import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import {
  delay, distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, switchMap, take, takeUntil, tap
} from 'rxjs/operators';

import { EtCurveAnalyticResult } from '@enerkey/clients/ines';
import { debounceTime } from '@enerkey/rxjs';
import { ControlsOf } from '@enerkey/ts-utils';

import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';
import { ISODuration } from '../../../shared/isoduration';
import { FacilityService } from '../../../shared/services/facility.service';
import { EtCurveService } from '../services/et-curve/et-curve.service';
import { EtCurveBaseUtil } from '../utils/et-curve-base.util';
import { EtCurveModelConverter } from '../utils/et-curve-model-converter.util';
import { EtCurveAdminParams, EtCurveDisplayType, ETCurveSearchParams, PeriodDisplayType } from './et-curve.model';

export abstract class EtCurveBase<T extends ETCurveSearchParams> {
  public readonly formGroup: UntypedFormGroup;
  public readonly resetChart: EventEmitter<void> = new EventEmitter<void>();

  public readonly facilities$: Observable<ExtendedFacilityInformation[]>;

  public readonly loading$: Observable<boolean>;
  public readonly loadingSavedEtCurves$: Observable<boolean>;
  public readonly loadingEtCurve$: Observable<boolean>;
  public readonly validatingDates$: Observable<boolean>;

  public readonly savedEtCurves$: Observable<{ savedEtCurves: EtCurveAnalyticResult[], timestamp: number }>;
  public readonly activeEtCurve$: Observable<Partial<EtCurveAdminParams> | null>;
  public readonly facilityEtCurveQuantities$: Observable<number[] | null>;

  public toDateMax: Date;
  public periodStartMaxDate: Date;

  protected readonly shouldConvertEtCurve$: Observable<boolean>;
  protected readonly etCurveDisplayType: typeof EtCurveDisplayType = EtCurveDisplayType;

  protected readonly _destroy = new Subject<void>();
  protected readonly _loading = new BehaviorSubject(false);
  protected readonly _loadingSavedEtCurves = new BehaviorSubject(false);
  protected readonly _loadingEtCurve = new BehaviorSubject(false);
  protected readonly _manuallyTriggeredDateValidation = new Subject<void>();
  protected readonly _validatingDates = new BehaviorSubject(false);
  protected readonly _prevLoadedEtCurveState = new BehaviorSubject<{
    timestamp: number,
    displayType: number,
    specificId: number,
    quantity: number,
    resolution: ISODuration,
    saveEvent: boolean
  }>({
    timestamp: 0,
    displayType: null,
    specificId: null,
    quantity: null,
    resolution: null,
    saveEvent: false
  });

  private readonly etCurveServiceBase: EtCurveService;
  private readonly facilityServiceBase: FacilityService;

  protected constructor(
    private readonly injector: Injector,
    public readonly controls: ControlsOf<T>,
    public readonly validatorFn: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null = null
  ) {
    this.etCurveServiceBase = this.injector.get(EtCurveService);
    this.facilityServiceBase = this.injector.get(FacilityService);

    this.toDateMax = new Date(this.etCurveServiceBase.yesterday);
    this.periodStartMaxDate = new Date(this.etCurveServiceBase.oneYearAgo);

    this.formGroup = new UntypedFormGroup(
      this.controls,
      this.validatorFn
    );

    this.controls.calculationTimeFrom.disable();
    this.controls.calculationTimeTo.disable();
    this.controls.showTimeFrom.disable();
    this.controls.showTimeTo.disable();

    this.facilities$ = this.facilityServiceBase.filteredProfileFacilities$.pipe(
      take(1),
      takeUntil(this._destroy)
    );

    this.loading$ = this._loading.asObservable();
    this.loadingSavedEtCurves$ = this._loadingSavedEtCurves.asObservable();
    this.loadingEtCurve$ = this._loadingEtCurve.asObservable();
    this.validatingDates$ = this._validatingDates.asObservable();

    this.savedEtCurves$ = combineLatest([
      this.etCurveServiceBase.saving$.pipe(
        startWith(false),
        filter(isSaving => {
          if (isSaving) {
            this._prevLoadedEtCurveState.next({ ...this._prevLoadedEtCurveState.value, saveEvent: true });
          }
          return !isSaving;
        })
      ),
      this.controls.facilityId.valueChanges.pipe(
        startWith(this.controls.facilityId.value),
        filter(facilityId => Number.isFinite(facilityId))
      )
    ]).pipe(
      tap(_ => this._loadingSavedEtCurves.next(true)),
      debounceTime(20),
      switchMap(([_, facilityId]) => this.etCurveServiceBase.getEtCurve({ facilityId, isActive: true })),
      map(savedEtCurves => ({ savedEtCurves, timestamp: Date.now() })),
      tap(_ => this._loadingSavedEtCurves.next(false)),
      shareReplay(1),
      takeUntil(this._destroy)
    );

    this.shouldConvertEtCurve$ = combineLatest([
      this.savedEtCurves$,
      this.controls.specificId.valueChanges.pipe(startWith(this.controls.specificId.value)),
      this.controls.resolution.valueChanges.pipe(startWith(this.controls.resolution.value)),
      this.controls.quantity.valueChanges.pipe(startWith(this.controls.quantity.value)),
      this.controls.etCurveDisplayType.valueChanges.pipe(startWith(this.controls.etCurveDisplayType.value))
    ]).pipe(
      debounceTime(10),
      distinctUntilChanged(),
      filter(_ => !this._loadingSavedEtCurves.value && !this._loadingEtCurve.value && !this._validatingDates.value),
      map(([{ savedEtCurves, timestamp }, specificId, resolution, quantity, displayType]) => {
        const {
          timestamp: prevTimestamp,
          displayType: prevDisplayType,
          specificId: prevSpecificId,
          quantity: prevQuantity,
          resolution: prevResolution
        } = this._prevLoadedEtCurveState.value;

        this.setPreviousEtCurveState(timestamp, displayType, specificId, quantity, resolution, false);

        return EtCurveBaseUtil.shouldConvertEtCurve(
          savedEtCurves,
          { current: displayType, prev: prevDisplayType },
          { current: specificId, prev: prevSpecificId },
          { current: quantity, prev: prevQuantity },
          { current: resolution, prev: prevResolution },
          { current: timestamp, prev: prevTimestamp }
        );
      }),
      shareReplay(1),
      takeUntil(this._destroy)
    );

    this.activeEtCurve$ = this.shouldConvertEtCurve$.pipe(
      switchMap(shouldConvertEtCurve => this.savedEtCurves$.pipe(
        map(savedEtCurvesRes => ({ shouldConvertEtCurve, savedEtCurves: savedEtCurvesRes.savedEtCurves }))
      )),
      switchMap(({ shouldConvertEtCurve, savedEtCurves }) => shouldConvertEtCurve ?
        this.getConvertedEtCurves(savedEtCurves) :
        of(savedEtCurves)),
      switchMap(etCurves => {
        const quantityId = this.controls.quantity.value;
        if (!Number.isFinite(quantityId)) { return of(null); }

        const activeEtCurve = etCurves.find(c => c.quantityId === quantityId);
        return of(activeEtCurve ? EtCurveModelConverter.etCurveAnalyticResultToLoadingParams(activeEtCurve) : null);
      }),
      switchMap(etCurve => etCurve !== null ? this.getValidatedEtCurve(etCurve) : of(etCurve)),
      shareReplay(1),
      takeUntil(this._destroy)
    );

    this.activeEtCurve$.pipe(
      switchMap(etCurve => (
        etCurve && this.controls.etCurveDisplayType.value === this.etCurveDisplayType.SAVED ?
          this.loadEtCurve(etCurve) :
          this.loadDefaults()
      )),
      takeUntil(this._destroy)
    ).subscribe();

    combineLatest([
      this.controls.period.valueChanges,
      this._manuallyTriggeredDateValidation.pipe(startWith(null)),
    ]).pipe(
      debounceTime(10),
      filter(_ => Number.isFinite(this.controls.facilityId.value) &&
        !this._loadingSavedEtCurves.value && !this._loadingEtCurve.value && !this._validatingDates.value),
      switchMap(_ => this.getValidatedEtCurve({ resolution: this.controls.resolution.value })),
      tap(_ => this._validatingDates.next(true)),
      tap(validatedEtCurve => this.formGroup.patchValue(validatedEtCurve)),
      delay(20),
      tap(_ => this._validatingDates.next(false)),
      takeUntil(this._destroy)
    ).subscribe();

    combineLatest([
      this.controls.specificId.valueChanges.pipe(startWith(this.controls.specificId.value)),
      this.controls.resolution.valueChanges.pipe(startWith(this.controls.resolution.value)),
      this.controls.quantity.valueChanges.pipe(startWith(this.controls.quantity.value)),
      this.controls.etCurveDisplayType.valueChanges.pipe(startWith(this.controls.etCurveDisplayType.value)),
    ]).pipe(
      debounceTime(50),
      distinctUntilChanged(),
      tap(_ => this.resetChart.emit()),
      takeUntil(this._destroy)
    ).subscribe();

    this.controls.resolution.valueChanges.pipe(
      debounceTime(10),
      filter(_ => !this._loadingEtCurve.value && !this._validatingDates.value),
      tap(resolution => {
        const isHourlyResolution = resolution === ISODuration.PT1H;
        const isMonthlyPeriod = this.controls.period.value === ISODuration.P1M;

        if (isHourlyResolution && !isMonthlyPeriod) {
          this.controls.period.setValue(ISODuration.P1M);
        } else if (!isHourlyResolution && isMonthlyPeriod) {
          this.controls.period.setValue(ISODuration.P1Y);
        }
      }),
      takeUntil(this._destroy)
    ).subscribe();

    this.controls.calculationTimeFrom.valueChanges.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this._destroy)
    ).subscribe(([prev, current]: [Date, Date]) => {
      if (current === null) { return; }
      if (current !== prev) {
        if (new Date(current).getHours() === 0) {
          this.controls.calculationTimeFromMin.setValue(null);
        } else {
          const dateTimeTo = new Date(this.controls.calculationTimeTo.value);

          if (dateTimeTo.getHours() !== 0) {
            const dateTimeFromMin = addHours(new Date(current), 1);
            this.controls.calculationTimeFromMin.setValue(dateTimeFromMin);
            this.controls.showTimeFromMin.setValue(dateTimeFromMin);

            const dateTimeToMax = addHours(new Date(dateTimeTo), -1);
            this.controls.calculationTimeToMax.setValue(dateTimeToMax);
            this.controls.showTimeToMax.setValue(dateTimeToMax);

            if (current >= dateTimeTo) {
              this.controls.calculationTimeTo.setValue(dateTimeFromMin);
            }
          }
        }

        if (this.controls.showTimeTo.enabled && this.controls.showTimeFrom.value !== current) {
          this.controls.showTimeFrom.setValue(current);
        }
      }
    });

    this.controls.calculationTimeTo.valueChanges.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this._destroy)
    ).subscribe(([prev, current]: [Date, Date]) => {
      if (current === null) { return; }
      if (current !== prev) {
        if (new Date(current).getHours() === 0) {
          this.controls.calculationTimeToMax.setValue(null);
        } else {
          const dateTimeFrom = new Date(this.controls.calculationTimeFrom.value);

          if (dateTimeFrom.getHours() !== 0) {
            const dateTimeToMax = addHours(new Date(current), -1);
            this.controls.calculationTimeToMax.setValue(dateTimeToMax);
            this.controls.showTimeToMax.setValue(dateTimeToMax);

            const dateTimeFromMin = addHours(new Date(dateTimeFrom), 1);
            this.controls.calculationTimeFromMin.setValue(dateTimeFromMin);
            this.controls.showTimeFromMin.setValue(dateTimeFromMin);

            if (current <= dateTimeFrom) {
              this.controls.calculationTimeFrom.setValue(dateTimeToMax);
            }
          }
        }
        if (this.controls.showTimeTo.enabled && this.controls.showTimeTo.value !== current) {
          this.controls.showTimeTo.setValue(current);
        }
      }

    });

    this.controls.calculationOpsHours.valueChanges.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this._destroy)
    ).subscribe(([prev, current]: [boolean, boolean]) => {
      if (current !== prev) {
        if (!current) {
          this.controls.calculationTimeFrom.setValue(new Date(new Date(new Date().setHours(0, 0, 0))));
          this.controls.calculationTimeTo.setValue(new Date(new Date(new Date().setHours(0, 0, 0))));
          this.controls.calculationInvert.setValue(false);
        }

        this.controls.opsHours.setValue(current);

        if (this.controls.calculationOpsHours.value) {
          this.controls.calculationTimeFrom.enable();
          this.controls.calculationTimeTo.enable();
        } else {
          this.controls.calculationTimeFrom.disable();
          this.controls.calculationTimeTo.disable();
        }
      }
    });

    this.controls.calculationInvert.valueChanges.pipe(takeUntil(this._destroy))
      .subscribe(current => {
        this.controls.invert.setValue(current);
      });

    this.controls.showTimeFrom.valueChanges.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this._destroy)
    ).subscribe(([prev, current]: [Date, Date]) => {
      if (current === null) { return; }
      if (current !== prev) {
        if (new Date(current).getHours() === 0) {
          this.controls.showTimeFromMin.setValue(null);
        } else {
          const dateTimeTo = new Date(this.controls.showTimeTo.value);

          if (dateTimeTo.getHours() !== 0) {
            const dateTimeFromMin = addHours(new Date(current), 1);
            this.controls.showTimeFromMin.setValue(dateTimeFromMin);

            if (current >= dateTimeTo) {
              this.controls.showTimeTo.setValue(dateTimeFromMin);
            }
          }
        }
      }
    });

    this.controls.showTimeTo.valueChanges.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this._destroy)
    ).subscribe(([prev, current]: [Date, Date]) => {
      if (current === null) { return; }
      if (current !== prev) {
        if (new Date(current).getHours() === 0) {
          this.controls.showTimeToMax.setValue(null);
        } else {
          const dateTimeFrom = new Date(this.controls.showTimeFrom.value);

          if (dateTimeFrom.getHours() !== 0) {
            const dateTimeToMax = addHours(new Date(current), -1);
            this.controls.showTimeToMax.setValue(dateTimeToMax);

            if (current <= dateTimeFrom) {
              this.controls.showTimeFrom.setValue(dateTimeToMax);
            }
          }
        }
      }
    });

    this.controls.opsHours.valueChanges.pipe(
      startWith(null),
      pairwise(),
      takeUntil(this._destroy)
    ).subscribe(([prev, current]: [boolean, boolean]) => {
      if (current !== prev) {
        if (!current) {
          this.controls.showTimeFrom.setValue(new Date(new Date(new Date().setHours(0, 0, 0))));
          this.controls.showTimeTo.setValue(new Date(new Date(new Date().setHours(0, 0, 0))));
          this.controls.invert.setValue(false);
        }

        if (this.controls.opsHours.value) {
          this.controls.showTimeFrom.enable();
          this.controls.showTimeTo.enable();
        } else {
          this.controls.showTimeFrom.disable();
          this.controls.showTimeTo.disable();
        }
      }
    });

    this.controls.periodDisplayType.valueChanges.pipe(
      debounceTime(10),
      filter(periodDisplayType => periodDisplayType === PeriodDisplayType.PREDEFINED),
      tap(_ => this._manuallyTriggeredDateValidation.next()),
      takeUntil(this._destroy)
    ).subscribe();

    this.facilityEtCurveQuantities$ = this.savedEtCurves$.pipe(
      map(savedEtCurvesRes => savedEtCurvesRes.savedEtCurves.map(etCurve => etCurve.quantityId)),
      shareReplay(1),
      takeUntil(this._destroy)
    );
  }

  protected loadEtCurve(etCurve: Partial<EtCurveAdminParams>): Observable<Partial<EtCurveAdminParams>> {
    return of(etCurve).pipe(
      tap(_ => this._loadingEtCurve.next(true)),
      delay(10),
      tap(_ => this.formGroup.patchValue(etCurve)),
      delay(10),
      tap(_ => this.setPreviousEtCurveState(
        this._prevLoadedEtCurveState.value.timestamp,
        this.controls.etCurveDisplayType.value,
        this.controls.specificId.value,
        this.controls.quantity.value,
        this.controls.resolution.value,
        false
      )),
      tap(_ => this._loadingEtCurve.next(false))
    );
  }

  protected loadDefaults(): Observable<Partial<EtCurveAdminParams>> {
    const defaultValues: Partial<EtCurveAdminParams> = {
      id: null,
      name: null,
      xAxis0: null,
      yAxis0: null,
      xAxis1: null,
      yAxis1: null,
      xAxis2: null,
      yAxis2: null,
      xAxis3: null,
      yAxis3: null,
      deviation: null,
      deviationType: null
    };

    return of(defaultValues).pipe(
      tap(_ => this._loadingEtCurve.next(true)),
      delay(10),
      switchMap(etCurve => this.getValidatedEtCurve(etCurve)),
      tap(validatedEtCurve => this.formGroup.patchValue(validatedEtCurve)),
      delay(10),
      tap(_ => this._loadingEtCurve.next(false))
    );
  }

  protected getValidatedEtCurve(etCurve: Partial<EtCurveAdminParams>): Observable<Partial<EtCurveAdminParams>> {
    return of(etCurve).pipe(
      map(validatedEtCurve => this.getValidatedPeriod(validatedEtCurve)),
      switchMap(validatedEtCurve => this.getValidatedDates(validatedEtCurve))
    );
  }

  protected onDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loadingSavedEtCurves.complete();
    this._loadingEtCurve.complete();
    this._loading.complete();
    this._manuallyTriggeredDateValidation.complete();
  }

  private getValidatedPeriod(etCurve: Partial<EtCurveAdminParams>): Partial<EtCurveAdminParams> {
    const currentPeriod = this.controls.period.value;
    const isResolutionChange = etCurve.resolution !== this.controls.resolution.value;
    const isHourlyResolution = etCurve.resolution === ISODuration.PT1H;
    const isWeeklyResolution = etCurve.resolution === ISODuration.P7D;
    const isYearlyPeriod = currentPeriod === ISODuration.P1Y;

    let period = currentPeriod;

    if (isHourlyResolution && isYearlyPeriod) {
      period = ISODuration.P1M;
    } else if (isResolutionChange && isWeeklyResolution && !isYearlyPeriod) {
      period = ISODuration.P1Y;
    }

    return { ...etCurve, period };
  }

  private getValidatedDates(etCurve: Partial<EtCurveAdminParams>): Observable<Partial<EtCurveAdminParams>> {
    const facilityId = this.controls.facilityId.value;
    const period = etCurve.period;
    const resolution = etCurve.resolution ?? this.controls.resolution.value;

    let validatedEtCurve: Partial<EtCurveAdminParams> = { ...etCurve };

    return this.etCurveServiceBase.getFacilityMeters(facilityId).pipe(
      switchMap(meters => this.etCurveServiceBase.getLatestConsumptionOnMeters(meters.map(m => m.Id))),
      map(latestConsumptions => {
        const latestConsumptionReadingDate: Date | null = !latestConsumptions.length ?
          null :
          latestConsumptions
            .map(c => c.timestamp)
            .reduce((acc, item) => acc.getTime() > item.getTime() ? acc : item);

        this.toDateMax = latestConsumptionReadingDate ? endOfDay(latestConsumptionReadingDate) : null;

        this.periodStartMaxDate = endOfDay(
          EtCurveBaseUtil.getValidMaxDate(latestConsumptionReadingDate, this.etCurveServiceBase.oneYearAgo)
        );

        if (!latestConsumptionReadingDate && period === ISODuration.P1Y && resolution !== ISODuration.P7D) {
          validatedEtCurve = { ...validatedEtCurve, resolution: ISODuration.P7D };
          return validatedEtCurve;
        }

        const validStartDate = EtCurveBaseUtil.getLatestDate(
          this.controls.startDate.value,
          this.periodStartMaxDate
        );

        const shouldUpdateStartDate = !etCurve?.id ?
          !isSameDay(validStartDate, this.controls.startDate.value) :
          this.controls.etCurveDisplayType.value === this.etCurveDisplayType.CALCULATED &&
            validStartDate.getTime() < this.controls.startDate.value.getTime();

        if (shouldUpdateStartDate) { validatedEtCurve = { ...validatedEtCurve, startDate: validStartDate }; }

        if (period === ISODuration.P1Y && resolution === ISODuration.PT1H) {
          validatedEtCurve = { ...validatedEtCurve, resolution: ISODuration.P7D };
        }

        validatedEtCurve = {
          ...validatedEtCurve,
          showFrom: EtCurveBaseUtil.getPeriodSelectionFromDateByMaxDate(
            latestConsumptionReadingDate, period, resolution
          ),
          showTo: EtCurveBaseUtil.getPeriodSelectionToDateByMaxDate(
            latestConsumptionReadingDate, period, resolution
          )
        };

        return validatedEtCurve;
      })
    );
  }

  private getConvertedEtCurves(savedEtCurves: EtCurveAnalyticResult[]): Observable<EtCurveAnalyticResult[]> {
    const { quantity, resolution, saveEvent } = this._prevLoadedEtCurveState.value;
    if (saveEvent) {
      const resValidation = EtCurveBaseUtil.validateCurrentResolution(savedEtCurves, quantity, resolution);
      if (!resValidation.isValid) {
        this._prevLoadedEtCurveState.next({ ...this._prevLoadedEtCurveState.value, saveEvent: false });
        this.formGroup.patchValue({ resolution: resValidation.value });
        return EMPTY;
      }
    }

    return this.etCurveServiceBase.getEtCurve({
      facilityId: this.controls.facilityId.value,
      isActive: true,
      etCurveConversionPropertyId: this.controls.specificId.value,
      etCurveConversionPropertyValue: this.controls.specificValue.value,
      etCurveConversionResolution: this.controls.resolution.value ?
        EtCurveModelConverter.isoDurationToResolution(this.controls.resolution.value) :
        undefined
    });
  }

  private setPreviousEtCurveState(
    timestamp: number,
    displayType: number,
    specificId: number,
    quantity: number,
    resolution: ISODuration,
    saveEvent: boolean
  ): void {
    this._prevLoadedEtCurveState.next({ timestamp, displayType, specificId, quantity, resolution, saveEvent });
  }
}
