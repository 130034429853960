import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { CompanyModel, ContactClient } from '@enerkey/clients/contact';
import { StringKeys } from '@enerkey/ts-utils';

import { SearchComponent } from '../../search/search.component';

@Component({
  selector: 'company-search',
  templateUrl: '../../search/search.component.html',
  styleUrls: ['../../search/search.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanySearchComponent),
    multi: true
  }]
})
export class CompanySearchComponent<B extends boolean = any> extends SearchComponent<CompanyModel, 'name', B> {
  public valueField: 'name' = 'name';
  public textField: StringKeys<CompanyModel> = 'name';

  public constructor(
    private contactClient: ContactClient,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  protected fetchItems(): Observable<CompanyModel[]> {
    return this.contactClient.getCompaniesByName(this.filterStringPrefix);
  }
}
