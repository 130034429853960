import _ from 'lodash';
import { costTargetTypes } from '../../energy-management/constants/consumption-target-constant';

ConsumptionTargetDataService.$inject = ['$q', 'consumptionTargets'];

export function ConsumptionTargetDataService($q, consumptionTargets) {
  return {
    getData: getData
  };

  function getData(series, payload, includeComparisonPeriods) {
    if (!payload.Quantities.length) {
      return $q.resolve();
    }

    const promises = [];
    const newPayload = getCommonPayload(series, payload);
    const consumptionTargetSeriesTypes = _.get(series, 'ConsumptionTargetSeriesTypes') || [];

    if (!includeComparisonPeriods) {
      newPayload.Start = [_.last(newPayload.Start)];
    }

    _.forEach(consumptionTargetSeriesTypes, seriesType => {
      promises.push(getConsumptionTargetData(seriesType, newPayload));
    });

    return promises;
  }

  function getCommonPayload(series, payload) {
    const seriesParams = ['Start', 'TimeFrame', 'Resolution'];
    const payloadParams = ['FacilityId', 'Quantities', 'ReportType', 'Unit', 'Cumulative', 'MeterId', 'AggregateType'];

    return _.merge({}, _.pick(series, seriesParams), _.pick(payload, payloadParams));
  }

  function getConsumptionTargetData(seriesType, payload) {
    const deferred = $q.defer();
    const targetPayload = getPayload(payload, seriesType);

    const onSuccess = function(result) {
      deferred.resolve({
        requestData: targetPayload,
        responseData: result.data
      });
    };

    const onError = function() {
      deferred.reject();
    };

    consumptionTargets
      .getConsumptionTargetsWithSeriesType(seriesType, targetPayload)
      .then(onSuccess)
      .catch(onError)
    ;

    return deferred.promise;
  }

  function getPayload(payload, seriesType) {
    const costType = costTargetTypes.find(({ cost }) => cost === seriesType);
    const targetSeries = costType
      ? {
        TargetSeriesType: costType.target,
        Budget: true,
      }
      : { TargetSeriesType: seriesType };
    return { ...payload, ...targetSeries };
  }
}
