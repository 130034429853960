const $inject = ['utils', 'ManualQaSpreadsheetService', 'MqaSpreadsheetService'];

class ManualQaAssignOperationPeriodCommand {
  constructor(utils, ManualQaSpreadsheetService, MqaSpreadsheetService) {
    this.utils = utils;
    this.ManualQaSpreadsheetService = ManualQaSpreadsheetService;
    this.MqaSpreadsheetService = MqaSpreadsheetService;
  }

  getCommand(controllerInstance) {
    const self = this;

    return {
      text: self.utils.localizedString('MQA.INSPECT.SHEET.MENU_ITEM.ASSIGN_OPERATION_PERIOD'),
      action: () => {
        const timeFrame = this.MqaSpreadsheetService.getSelectionTimeFrame(
          controllerInstance.spreadsheet,
          controllerInstance.dataSource.data()
        );

        controllerInstance.onTimeFrameChange({ timeFrame: timeFrame });
      }
    };
  }
}

ManualQaAssignOperationPeriodCommand.$inject = $inject;

export default ManualQaAssignOperationPeriodCommand;
