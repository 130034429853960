import { downgradeInjectable } from '@angular/upgrade/static';

import EnerKeyServicesModule from '../../services';
import { BookmarkService } from '../../services/bookmark.service';
import ModalButtonsComponent from './modal-buttons.component';

export default angular.module('app.components.modal-buttons', [
  EnerKeyServicesModule.name
])
  .component('modalButtons', ModalButtonsComponent)
  .service('BookmarkService', downgradeInjectable(BookmarkService));
