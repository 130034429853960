import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Valid for integers only, nulls not allowed. `min` and `max` -inputs are required.
 */
@Component({
  selector: 'ek-slider-number-box',
  templateUrl: './ek-slider-number-box.component.html',
  styleUrls: ['./ek-slider-number-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EkSliderNumberBoxComponent),
    multi: true,
  }],
})
export class EkSliderNumberBoxComponent implements OnInit, ControlValueAccessor {
  @Input() public min: number;
  @Input() public max: number;

  public value: number;
  public disabled: boolean = false;

  private _onChange: (n: number) => void;
  private _onTouch: () => void;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    if (!Number.isInteger(this.min) || !Number.isInteger(this.max) || this.min > this.max) {
      throw Error('Invalid/missing min/max inputs');
    }
  }

  public valueChanged(value: number): void {
    // Bound with ngModel, so this.value is already set correctly
    this._onChange?.(value);
  }

  public blur(): void {
    this._onTouch?.();
  }

  public writeValue(value: number): void {
    this.value = this.limitToMinmax(value);
  }

  public registerOnChange(fn: (n: number) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    const d = !!isDisabled;

    if (this.disabled !== d) {
      this.disabled = d;
      this.changeDetector.detectChanges();
    }
  }

  private limitToMinmax(value: number): number {
    if (!Number.isFinite(value)) {
      return this.min;
    }

    if (!Number.isInteger(value)) {
      value = Math.round(value);
    }

    return Math.min(
      Math.max(value, this.min),
      this.max
    );
  }
}

