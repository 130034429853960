<modal-view-header [headingText]="modalHeaderText"></modal-view-header>
<div class="modal-body">
  <div [loadingSpinner]="firstLoad">
      <button
        type="button"
        class="button button--primary float-right"
        (click)="copyMeterIds()"
      >
        <i class="fa fa-copy"></i>
        {{ 'ADMIN.VIRTUAL_METERS.EDITOR.COPY_METER_IDS' | translate }}
      </button>
    <info-grid>
      <info-grid-item [title]="'ADMIN.METER_NAME' | translate">
        <button
          *ngIf="(facility?.id | hasFacilityAccess | async); else noPermission"
          class="button button--link button--no-padding"
          kendoTooltip
          title="{{ 'OPEN_IN_NEW_TAB' | translate }}"
          (click)="openMeterClicked()"
        >
          {{ meterName }}
        </button>
        <ng-template #noPermission>
          <span>{{ meterName}}</span>
        </ng-template>
      </info-grid-item>
      <info-grid-item
        [title]="'ADMIN.METER_ID' | translate"
        [data]="resultMeterId"
      ></info-grid-item>
      <info-grid-item
        [title]="'ADMIN.FACILITY_NAME' | translate"
        [data]="facility?.displayName || '...'"
      ></info-grid-item>
      <info-grid-item
        [title]="'ADMIN.ENEGIAID' | translate"
        [data]="facility?.enegiaId || '...'"
      ></info-grid-item>
      <info-grid-item
        [title]="'ADMIN.VIRTUAL_METERS.EDITOR.ACTIVE' | translate"
      >
        <input
          type="checkbox"
          kendoCheckBox
          [(ngModel)]="formula.isActive"
        >
      </info-grid-item>
      <info-grid-item
        [title]="'COMMENT' | translate"
      >
        <input
          kendoTextBox
          type="text"
          [(ngModel)]="formula.description"
        >
      </info-grid-item>
      <info-grid-item *ngIf="facility?.id | hasFacilityAccess | async">
        <button
          class="button button--link button--no-padding button--external-link"
          kendoTooltip
          title="{{ 'OPEN_IN_NEW_TAB' | translate }}"
          (click)="openMetersReport()"
        >
          {{ 'ADMIN.VIRTUAL_METERS.EDITOR.METERS_REPORT' | translate }}
        </button>
      </info-grid-item>
    </info-grid>
    <h3>{{ 'ADMIN.VIRTUAL_METERS.EDITOR.EFFECT_TIME' | translate }}:</h3>
    <effect-time
      [fromDate]="(formulaData && formulaData[0]?.rootProperties?.from) | todate"
      [toDate]="(formulaData && formulaData[0]?.rootProperties?.to) | todate"
      (fromDateChange)="fromDateChange($event)"
      (toDateChange)="toDateChange($event)"
    ></effect-time>
    <warning-icon
      *ngIf="formula?.hasOverlapWithOtherFormula"
      [tooltip]="'ADMIN.VIRTUAL_METERS.EDITOR.FORMULA_OVERLAP_WARNING' | translate"
    ></warning-icon>
  </div>
  <formula-editor-treelist
    [formulaData]="formulaData"
    [loading]="loading"
    (addItem)="addItem($event)"
    (deleteItem)="deleteItem($event)"
    (editItem)="editItem($event)"
  ></formula-editor-treelist>
  <h3>{{ 'ADMIN.VIRTUAL_METERS.EDITOR.FULL_FORMULA' | translate }}:</h3>
  <p>{{ formulaData[0]?.readableContent }}</p>
</div>
<modal-footer>
  <button
    type="button"
    class="button"
    (click)="saveFormula()"
  >{{ 'ADMIN.SAVE' | translate }}</button>
  <button
    type="button"
    class="button button--secondary"
    (click)="cancelClicked()"
  >{{ 'CANCEL' | translate }}</button>
</modal-footer>
