BodyController.$inject = [
  '$scope', 'LoadingService'
];

function BodyController(
  $scope, LoadingService
) {
  // app status
  $scope.appStatus = {
    isLoading: LoadingService.isLoading(),
  };

  // watch for state changes to update isLoading property
  const loadingSubscription = LoadingService.isLoading$.subscribe((event, isLoading) => {
    $scope.appStatus.isLoading = isLoading;
  });

  // unbind on destroy
  $scope.$on('$destroy', () => {
    loadingSubscription.unsubscribe();
  });
}

export default BodyController;
