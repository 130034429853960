import { getInteger, getString } from './object.functions';
import { getInfoStringFromArray } from './utils.functions';

class Facility {
  constructor(values) {
    this.id = getInteger(values, 'id');
    this.enegiaId = getInteger(values, 'enegiaId');
    this.name = getString(values, 'name');
    this.address = getString(values, 'address');
    this.postalCode = getString(values, 'postalCode');
    this.city = getString(values, 'city');
    this.infoString = this.getInfoString();
  }

  getId() {
    return this.id;
  }

  getEnegiaId() {
    return this.enegiaId;
  }

  getName() {
    return this.name;
  }

  getAddress() {
    return this.address;
  }

  getPostalCode() {
    return this.postalCode;
  }

  getCity() {
    return this.city;
  }

  getInfoString() {
    return getInfoStringFromArray(
      [
        this.getAddress(),
        this.getCity(),
        this.getPostalCode()
      ]
    );
  }
}

export default Facility;
