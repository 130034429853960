import { Directive, TemplateRef } from '@angular/core';
import { DataBindingContext } from '@enerkey/ts-utils';

@Directive({
  selector: '[ekComboItemTemplate]',
})
export class EkComboItemTemplateDirective<T> {
  public constructor(
    public readonly ref: TemplateRef<DataBindingContext<T>>
  ) { }
}
