import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { QuantityService } from '../../../shared/services/quantity.service';

@Pipe({
  name: 'groupedConsumptionWidgetNumberFormat'
})
export class GroupedConsumptionWidgetNumberFormatPipe implements PipeTransform {
  public constructor(private readonly quantityService: QuantityService) {
  }

  public transform(
    quantityId: Quantities,
    isRelationalValue: boolean,
    unit: ReportingUnit = ReportingUnit.Default
  ): Observable<string> {
    if (isRelationalValue) {
      return of('#,#.0');
    }
    return this.quantityService.getQuantityById(quantityId).pipe(
      map(quantity => quantity.Units[unit].DecimalsToShow),
      map(decimals => decimals
        ? `#,#.${'0'.repeat(decimals)}`
        : '#,#')
    );
  }
}
