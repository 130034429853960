import { BehaviorSubject, Subject } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */

const _LOADING_SUBJECT_: unique symbol = Symbol();

/**
 * Subject that tracks the observables that have called `indicate` on it.
 * Should be used only with Observables that complete immediately after emitting.
 */
export class LoadingSubject extends BehaviorSubject<boolean> {
  public static isLoadingSubject(value: Subject<boolean>): value is LoadingSubject {
    return !!(value as any)[_LOADING_SUBJECT_];
  }

  protected readonly [_LOADING_SUBJECT_]: never = true as never;

  /** Contains hashes of active `indicate`-calls. */
  private readonly _hashSet = new Set<string>();

  public constructor(initialValue?: boolean) {
    super(initialValue);
  }

  /** @deprecated This should not be called manually, use `indicate` instead. */
  public override next(value: boolean, hash?: string): void {
    /* istanbul ignore else */
    if (hash) {
      if (value) {
        this._hashSet.add(hash);
      } else {
        this._hashSet.delete(hash);
      }
      super.next(this._hashSet.size !== 0);
    } else {
      super.next(value);
    }
  }

  /** The loading subject should be completed in the host component's `onDestroy` lifecycle hook. */
  /* istanbul ignore next */
  public override complete(): void {
    super.complete();
    this._hashSet.clear();
  }

  /** @deprecated This should not be called manually. */
  /* istanbul ignore next */
  public override error(err: never): void {
    super.error(err);
    this._hashSet.clear();
  }
}
