import 'angular-animate';
import '@uirouter/angular-hybrid';
import 'angular-translate';

import DocumentsModelsModule from '../documents/models';
import ReportingObjectsServicesModule from '../reportingobjects/services';
import LodashModule from '../lodash';
import JsZipModule from '../jszip';
import KendoModule from '../kendo';
import EnergyReportingComponentsModule from './components';
import EnergyReportingConstantsModule from './constants';
import EnergyManagementConstants from '../energy-management/constants';
import EnergyReportingModelsModule from './models';
import EnergyReportingServicesModule from './services';
import EnergyReportingControllersModels from './controllers';
import EnerKeyServices from '../../services';
import EnerKeyFilters from '../../filters';
import config from './config';
import run from './run';

export default angular.module('EnergyReporting', [
  'ngAnimate',
  'ui.router',
  'pascalprecht.translate',
  'kendo.directives',
  LodashModule.name,
  JsZipModule.name,
  ReportingObjectsServicesModule.name,
  DocumentsModelsModule.name,
  EnergyReportingComponentsModule.name,
  EnergyReportingConstantsModule.name,
  EnergyReportingModelsModule.name,
  EnergyReportingServicesModule.name,
  EnergyManagementConstants.name,
  EnergyReportingControllersModels.name,
  EnerKeyServices.name,
  EnerKeyFilters.name,
  KendoModule.name
])
  .config(config)
  .run(run);
