import { FaultType, InspectionDefectItem, Status } from '@enerkey/clients/manual-qa';
import { assertUnreachable } from '@enerkey/ts-utils';

import { formatDefectTypePlain, getCommonColumns, getCommonOptions } from './manual-qa-result.functions';
import { GRID_COLUMNS, groupHeaderTemplate } from './grid';
import DefectType from '../../shared/defect-type';
import { TranslationsMap } from './manual-qa-result-service';
import { integerFilterUi } from '../../../../shared/ek-kendo/kendo.functions';
import { ToggleableGridColumn } from '../../constants/toggleable-grid-column';

const commonColumnsToHide = [
  GRID_COLUMNS.USAGEPLACE_NUMBER
];

export function getDefectGridOptions(translations: TranslationsMap): kendo.ui.GridOptions {
  const commonColumns = getCommonColumns(translations);
  commonColumns.forEach(column => {
    if (commonColumnsToHide.includes(column.field)) {
      column.hidden = true;
    }
  });
  const baseOptions = getCommonOptions();
  const schemaFields = baseOptions.dataSource.schema.model.fields;
  baseOptions.dataSource.schema.model.fields = {
    ...schemaFields,
    [GRID_COLUMNS.FROM]: { type: 'date' },
    [GRID_COLUMNS.TO]: { type: 'date' },
    [GRID_COLUMNS.DURATION]: { type: 'number' }
  };
  return { ...baseOptions, columns: [...commonColumns, ...getDefectColumns(translations)] };
}

function getDefectColumns(translations: TranslationsMap): ToggleableGridColumn[] {
  return [
    {
      field: GRID_COLUMNS.FROM,
      title: translations['MQA.RESULTS.GRID.DEFECTS.FROM'],
      groupHeaderTemplate: groupHeaderTemplate,
      format: '{0:g}',
      filterable: {
        ui: 'datepicker'
      },
      width: 130,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.TO,
      title: translations['MQA.RESULTS.GRID.DEFECTS.TO'],
      groupHeaderTemplate: groupHeaderTemplate,
      format: '{0:g}',
      filterable: {
        ui: 'datepicker'
      },
      width: 130,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.DURATION,
      title: translations['MQA.RESULTS.GRID.DEFECTS.DURATION'],
      groupHeaderTemplate: groupHeaderTemplate,
      filterable: {
        ui: integerFilterUi
      },
      width: 100,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.FAULT_TYPES,
      title: translations['MQA.RESULTS.GRID.DEFECT_TYPE'],
      groupHeaderTemplate: (group: any) => formatDefectTypePlain(group.items[0].faultTypes, translations),
      template: (defect: InspectionDefectItem) => formatFaultType(defect, translations),
      filterable: {
        multi: true
      },
      width: 130,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.DEFECT_STATUS,
      title: translations['MQA.RESULTS.GRID.DEFECTS.DEFECT_STATUS'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (defect: InspectionDefectItem) => formatDefectStatus(defect, translations),
      hidden: true,
      filterable: {
        multi: true
      },
      width: 200,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.EXCEEDS_INSPECTED_RANGE,
      groupHeaderTemplate: groupHeaderTemplate,
      title: translations['MQA.RESULTS.GRID.DEFECTS.EXCEEDS_INSPECTED_RANGE'],
      hidden: true,
      filterable: {
        multi: true,
      },
      width: 200,
      toggleable: true
    },
  ];
}

function formatFaultType(defect: InspectionDefectItem, translations: TranslationsMap): string {
  const reducer = (result: string[], faultType: FaultType): string[] => {
    const name = translations[DefectType.getDefectTypeNameTag(faultType) as keyof TranslationsMap];

    return defect.exceedsInspectedRange
      ? result.concat(`<span class="ek-color ek-color--negative">${name}</span>`)
      : result.concat(`<span>${name}</span>`);
  };

  return defect.faultTypes.reduce(reducer, []).join(', ');
}

function formatDefectStatus(defect: InspectionDefectItem, translations: TranslationsMap): string {
  switch (defect.defectStatus) {
    case Status.Open:
      return translations['MQA.RESULTS.GRID.DEFECTS.OPEN'];
    case Status.Resolved:
      return translations['MQA.RESULTS.GRID.DEFECTS.RESOLVED'];
    default:
      assertUnreachable(defect.defectStatus);
  }
}
