import _ from 'lodash';

import menuTemplate from 'raw-loader!../templates/menu.html';

menu.$inject = ['$compile', '$window', '$timeout', 'appStatusService'];

function menu($compile, $window, $timeout, appStatusService) {
  const template = menuTemplate;
  return {
    restrict: 'E',
    replace: true,
    scope: {
      items: '='
    },
    controller: [
      '$scope', function($scope) {
        // watch for menu items changes
        $scope.$watchCollection('items', () => {
          // trigger refresh
          if (_.isFunction($scope.reCalculate)) {
            $scope.reCalculate();
          }
        });
      }
    ],
    link: function($scope, $element) {
      let debouncedOnResize = angular.noop();

      const windowElement = angular.element($window);
      const compiledElement = $compile(template)($scope);
      $element.replaceWith(compiledElement);

      const defaultDelay = 20;
      const recalcHelpers = {};
      const menuContainer = compiledElement.closest('.menu-container');
      $scope.inExtra = {
        titleKey: 'MORE',
        childs: [],
        extra: true
      };

      function getSortedItems() {
        return _.sortBy($scope.items, 'weight');
      }

      function findMenuItemContainer(item) {
        if (!item) {
          return null;
        }

        return menuContainer.find(`#${item.state}`);
      }

      function getWidths() {
        const result = {};

        // document width
        result.doc =
          _.get($window, 'document.documentElement.clientWidth') ||
          _.get($window, 'document.body.clientWidth') ||
          windowElement.width();

        // menu width with 60px offset
        result.menu = menuContainer.width() + 60;

        // siblings width
        const siblings = menuContainer.siblings();
        result.siblings = 0;
        $.each(siblings, function() {
          const el = angular.element(this); // eslint-disable-line no-invalid-this
          if (el.attr('id') !== 'menu-clone') {
            result.siblings += el.width();
          }
        });

        return result;
      }

      function hideMenu() {
        menuContainer.css({ position: 'absolute', visibility: 'hidden', display: 'block' });
      }

      function showMenu() {
        menuContainer.css({ position: '', visibility: '', display: '' });
      }

      function addCloneMenu() {
        removeCloneMenu();
        const pos = menuContainer.position();
        recalcHelpers.menuClone = menuContainer.clone();
        recalcHelpers.menuClone.css({
          'position': 'absolute',
          'marginLeft': 0,
          'marginTop': 0,
          'top': pos.top,
          'left': pos.left,
          'z-index': 1
        });
        recalcHelpers.menuClone.attr('id', 'menu-clone');
        menuContainer.parent().append(recalcHelpers.menuClone);
      }

      function removeCloneMenu() {
        angular.element('#menu-clone').remove();
      }

      $scope.collapseMenu = function() {
        menuContainer.find('.js-dropdown-active').removeClass('js-dropdown-active');
      };

      // recalculate menu width
      $scope.reCalculate = function() {
        // clone current menu to avoid flickering
        // and remove after recalculated
        addCloneMenu();

        // invalidate old show menu call and hide menu
        if (recalcHelpers.showMenuTimer) {
          clearTimeout(recalcHelpers.showMenuTimer);
        }
        hideMenu();

        // reset inExtra and call onresize
        _.each(getSortedItems(), item => {
          delete item.inExtra;
        });
        $scope.inExtra.childs = [];
        debouncedOnResize();

        // show menu after new calculations are made
        recalcHelpers.showMenuTimer = $timeout(() => {
          showMenu();
          delete recalcHelpers.showMenuTimer;
          removeCloneMenu();
        }, 5 * defaultDelay);
      };

      // handler for resize
      function onResize() {
        if (appStatusService.isMobileWidth) {
          // on mobile, remove every item from extra menu
          _.each(getSortedItems(), item => {
            delete item.inExtra;
          });
          $scope.inExtra.childs = [];
          return;
        }

        $scope.$evalAsync(() => {
          const sortedItems = getSortedItems();
          const widths = getWidths();

          if (widths.menu + widths.siblings > widths.doc) {
            // too many items visible, try to move items to extra menu
            while (widths.menu + widths.siblings > widths.doc || $scope.inExtra.childs.length <= 1) {
              const lastItemNotInExtra = _.findLast(sortedItems, item => !_.isNumber(item.inExtra));
              const li = findMenuItemContainer(lastItemNotInExtra);
              if (li && li.length) {
                lastItemNotInExtra.inExtra = widths.menu + widths.siblings;
                $scope.inExtra.childs.push(lastItemNotInExtra);
                if ($scope.inExtra.childs.length > 1) {
                  widths.menu -= li.width();
                }
              } else {
                // have to break so that we don't end up into endless loop
                break;
              }
            }
          } else {
            // check if some of the items can be removed from extra menu
            _.each(sortedItems, item => {
              if (item.inExtra && item.inExtra <= widths.doc) {
                delete item.inExtra;
                $scope.inExtra.childs = _.without($scope.inExtra.childs, item);
              }
            });
          }
        });
      }

      // watch for window resize
      debouncedOnResize = _.debounce(onResize, defaultDelay);
      $window.addEventListener('resize', debouncedOnResize);
      $scope.$on('$destroy', () => {
        $window.removeEventListener('resize', debouncedOnResize);
      });
    }
  };
}

export default menu;
