import moment from 'moment';

export function getHumanReadableCronFilter(cron: string, translations: {[key: string]: string}): string {
  if (cron) {
    if (isCustomCronFilter(cron)) {
      return cron;
    } else {
      const cronArray = cron.split(' ');
      const hours = parseHours(cronArray[1], translations);
      const weekdays = parseWeekdays(cronArray[4], translations);
      const months = parseMonths(cronArray[3], translations);
      return `${hours} ${weekdays} ${months}`;
    }
  }
  return '';
}

function isCustomCronFilter(cron: string): boolean {
  const cronArray = cron.split(' ');
  return cronArray[0] !== '0' || cronArray[2] !== '*'
    || cronArray[3].includes('-') || cronArray[4].includes('-')
    || cron.includes('/');
}

function parseHours(hours: string, translations: {[key: string]: string}): string {
  return hours === '*' ? translations['ALARM.CRON_FILTER.WHOLE_DAY'] : hours;
}

function parseWeekdays(weekdays: string, translations: {[key: string]: string}): string {
  if (weekdays === '*') {
    return translations['ALARM.CRON_FILTER.EVERY_WEEKDAY'];
  }
  const weekdayArray = weekdays
    .split(',')
    .map(weekday => weekday === '0' ? 7 : Number(weekday))
    .sort((first, second) => first > second ? 1 : -1);
  const localizedWeekdays = moment.weekdaysShort();
  localizedWeekdays.splice(6, 0, localizedWeekdays.splice(0, 1)[0]);
  return localizeDateItems(weekdayArray, localizedWeekdays);
}

function parseMonths(months: string, translations: {[key: string]: string}): string {
  if (months === '*') {
    return translations['ALARM.CRON_FILTER.EVERY_MONTH'];
  }
  const monthsArray = months
    .split(',')
    .map(value => Number(value))
    .sort((first, second) => first > second ? 1 : -1);
  const localizedMonths = moment.monthsShort();
  return localizeDateItems(monthsArray, localizedMonths);
}

function localizeDateItems(values: number[], localizedValues: string[]): string {
  const resultValues: string[] = [];
  let rangeOpen = false;
  values.forEach((value, index) => {
    if (rangeOpen) {
      if (value + 1 < values[index + 1] || index === values.length - 1) {
        rangeOpen = false;
        const range = `${resultValues[resultValues.length - 1]}-${localizedValues[value - 1]}`;
        resultValues[resultValues.length - 1] = range;
      }
    } else {
      if (value + 1 === values[index + 1]) {
        rangeOpen = true;
      }
      resultValues.push(localizedValues[value - 1]);
    }
  });
  return resultValues.join(',');
}
