export class FormulaNewItemId {
  public static resetNewItemId() {
    FormulaNewItemId.newFormulaItemId = FormulaNewItemId.initialNewItemId;
  }

  private static readonly initialNewItemId = -1;

  private static newFormulaItemId = FormulaNewItemId.initialNewItemId;

  /**
     Id of new formula item. Backend always returns positive integers as itemIds, so it is safe to use negative
     number here. Should be reseted every time when new formula is opened for editing.
  */
  public static get newItemId() {
    return FormulaNewItemId.newFormulaItemId--;
  }
}
