import { Quantities } from '@enerkey/clients/metering';
import {
  GenericTimeSeriesCollectionOfIReadingSet
} from '@enerkey/clients/energy-reporting';
import { percentageChange } from '@enerkey/ts-utils';

import { ConsumptionValueKey } from '../../../shared/energy-reporting-shared/shared/consumptions';
import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';

export interface EmissionsAggregateValue {
  quantityId: Quantities;
  value: number;
  color: string;
  year?: number | string;
}

export interface EmissionAggregates {
  totalEmissions: number;
  consumptionChange: number;
  emissionChange: number;
  chartData: EmissionsAggregateValue[];
}

export interface EmissionsWidgetData {
  readonly aggregateData: EmissionAggregates;
  readonly chartData: EmissionsAggregateValue[][];
  readonly hasData: boolean;
}

export class Emissions {
  public static getWidgetData(
    data: { [key: string]: GenericTimeSeriesCollectionOfIReadingSet },
    quantityIds: Quantities[],
    quantityColors: Map<Quantities, string[]>,
    readingKey: ConsumptionValueKey,
    start: TimeFrameResult,
    relationalValueId: number
  ): EmissionsWidgetData {
    return new Emissions(
      data,
      quantityIds,
      quantityColors,
      readingKey,
      start,
      relationalValueId
    ).getWidgetData();
  }

  private readonly amountOfPeriods: number;

  private constructor(
    private readonly data: { [key: string]: GenericTimeSeriesCollectionOfIReadingSet },
    private readonly quantityIds: Quantities[],
    private readonly quantityColors: Map<Quantities, string[]>,
    private readonly readingKey: ConsumptionValueKey,
    private readonly start: TimeFrameResult,
    private readonly relationalValueId: number
  ) {
    this.amountOfPeriods = this.start.Start.length;
  }

  private getWidgetData(): EmissionsWidgetData {
    const aggregateData = this.getPieChartData();
    return {
      aggregateData,
      chartData: this.getChartData(),
      hasData: aggregateData.chartData.some(year => year.value)
    };
  }

  private getPieChartData(): EmissionAggregates {
    const inspectionPeriodKey = this.getStartIndexKey(this.start, this.amountOfPeriods - 1);
    const comparisonPeriodKey = this.getStartIndexKey(this.start, this.amountOfPeriods - 2);

    const data = this.quantityIds
      .filter(quantityId => this.data[quantityId])
      .reduce((aggregatedData, quantityId) => {
        const emissionValue = this.getPeriodEmissionValue(quantityId, inspectionPeriodKey);

        if (Number.isFinite(emissionValue)) {
          const comparisonEmissionValue = this.getPeriodEmissionValue(quantityId, comparisonPeriodKey);
          const consumptionValue = this.getPeriodConsumptionValue(quantityId, inspectionPeriodKey);
          const comparisonConsumptionValue = this.getPeriodConsumptionValue(quantityId, comparisonPeriodKey);

          aggregatedData.chartData.push({
            quantityId: quantityId,
            value: emissionValue,
            color: this.quantityColors.get(quantityId)[this.amountOfPeriods - 1]
          });
          aggregatedData.totalEmissions += emissionValue;
          aggregatedData.comparisonEmission += comparisonEmissionValue;
          aggregatedData.totalConsumption += consumptionValue;
          aggregatedData.comparisonConsumption += comparisonConsumptionValue;
        }

        return aggregatedData;
      }, {
        chartData: [] as EmissionsAggregateValue[],
        totalEmissions: 0,
        comparisonEmission: 0,
        totalConsumption: 0,
        comparisonConsumption: 0,
      });

    return {
      chartData: data.chartData,
      totalEmissions: data.totalEmissions,
      emissionChange: percentageChange(data.totalEmissions, data.comparisonEmission),
      consumptionChange: percentageChange(data.totalConsumption, data.comparisonConsumption)
    };
  }

  private getStartIndexKey(start: TimeFrameResult, index: number): string {
    return start.Start[index].key;
  }

  private getPeriodEmissionValue(quantityId: Quantities, periodKey: string): number {
    return this.data[quantityId]
      .Aggregates[periodKey]
      .RelationalValues[this.relationalValueId]
      ?.[this.readingKey].Value ?? null;
  }

  private getPeriodConsumptionValue(
    quantityId: Quantities,
    periodKey: string
  ): number {
    return this.data[quantityId].Aggregates[periodKey][this.readingKey].Value;
  }

  private getChartData(
  ): EmissionsAggregateValue[][] {
    const visibleQuantities = this.quantityIds
      .filter(quantityId => this.start.Start.some(
        start => Number.isFinite(this.getPeriodEmissionValue(quantityId, start.key))
      ));
    return this.start.Start.map(
      (start, index) => {
        const values = visibleQuantities
          .filter(quantityId => Array.hasItems(this.data[quantityId].FacilitiesIncluded))
          .map(
            quantityId => ({
              value: this.getPeriodEmissionValue(quantityId, start.key),
              color: this.quantityColors.get(quantityId)[index],
              quantityId: quantityId,
              year: start.key
            })
          );
        return values;
      }
    );
  }
}
