import { Roles } from '../../../modules/admin/constants/roles';

meters.$inject = [
  '$q',
  '$http',
  '$timeout',
  'documents',
  'utils',
  'UserService',
  '_',
  'httpConfigService',
  'languageChangeService'
];

export function meters(
  $q,
  $http,
  $timeout,
  documents,
  utils,
  UserService,
  _,
  httpConfigService,
  languageChangeService
) {

  const metersForFacility = {
    facilityId: undefined,
    deferred: undefined,
  };

  let metersTypesDeferred;

  // watch for language change start
  languageChangeService.languageChangeStart.subscribe(() => {
    if (metersTypesDeferred) {
      metersTypesDeferred.resolve([]);
    }
    metersTypesDeferred = undefined;
  });

  function getMeterTypes() {
    if (!metersTypesDeferred) {
      metersTypesDeferred = $q.defer();
      $http.get(
        `${ENERKEY_CONFIG.apiEnergyreporting}/api/v1/configuration/meteringtypes`,
        httpConfigService.getExtendedHttpConfig()
      )
        .then(({ data }) => {
          if (metersTypesDeferred) {
            metersTypesDeferred.resolve(data.MeteringTypes);
          }
        })
        .catch(({ status }) => {
          if (metersTypesDeferred) {
            metersTypesDeferred.reject(status);
            $timeout(() => {
              metersTypesDeferred = undefined;
            });
          }
        });
    }
    return metersTypesDeferred.promise;
  }

  return {

    getMeters: function() {
      const deferred = $q.defer();
      $http.get(`${ENERKEY_CONFIG.apiEnergyreporting}/api/v1/meters`, httpConfigService.getExtendedHttpConfig())
        .then(({ data }) => deferred.resolve(data.Meters))
        .catch(({ status }) => deferred.reject(status));

      return deferred.promise;
    },

    getMetersForFacility: function(facilityId) {
      if (metersForFacility.facilityId !== facilityId) {
        metersForFacility.facilityId = facilityId;
        metersForFacility.deferred = $q.defer();

        $q.all([
          getMeterTypes(),
          $http.get(
            `${ENERKEY_CONFIG.apiEnergyreporting}/api/v1/meters/${facilityId}`,
            httpConfigService.getExtendedHttpConfig()
          )
        ]).then(results => {
          const isMeterManager = UserService.hasRole(Roles.METER_MANAGER_USER);

          const measureTwoTime = utils.localizedString('METERS.MEASUREMENT_METHOD.TWO_TIME');
          const measureOneTime = utils.localizedString('METERS.MEASUREMENT_METHOD.ONE_TIME');

          const fetchedMeterTypes = results[0];
          const originalMeters = _.get(results[1], 'data.Meters');

          const modifiedMeters = _.map(_.get(results[1], 'data.Meters'), meter => {
            const newMeter = _.cloneDeep(meter);

            // Ensure meter names are unique across quantities (ID always shown to meter manager)
            if (isMeterManager || originalMeters.some(
              m => m.Name === meter.Name && m.QuantityId === meter.QuantityId && m !== meter
            )) {
              newMeter.Name = `${meter.Name} (${meter.Id})`;
            }

            // add metering type and measument method
            newMeter.MeteringType = _.find(fetchedMeterTypes, { Id: meter.MeteringTypeId });
            newMeter.MeasurementMethod = meter.TwoTimeMeasurement ? measureTwoTime : measureOneTime;
            return newMeter;
          });

          metersForFacility.deferred.resolve(modifiedMeters);
        }).catch(
          () => metersForFacility.deferred.reject()
        );
      }

      return metersForFacility.deferred
        ? metersForFacility.deferred.promise
        : $q.resolve([]);
    },

    getMeterMapImages: function(meterId) {
      return documents.getMapImages({ meterId: meterId });
    },

    createOrUpdateMeter: function(_meters) {
      return $http.post(
        `${ENERKEY_CONFIG.apiMeterManagement}/api/v1/meter/upsert`,
        _meters,
        httpConfigService.getExtendedHttpConfig({ isCancellable: true })
      ).then(result => result.data);
    },

    createOrUpdateMeterHierarchies: function(meterhierarchies) {
      return $http.post(
        `${ENERKEY_CONFIG.apiMeterManagement}/api/v1/meterhierarchy/upsert`,
        meterhierarchies,
        httpConfigService.getExtendedHttpConfig({ isCancellable: true })
      ).then(result => result.data);
    },

    getFacilitiesMeters: function(ids) {
      return $http.post(
        `${ENERKEY_CONFIG.apiMeterManagement}/api/v1/meter`,
        ids,
        httpConfigService.getExtendedHttpConfig({ isCancellable: true })
      ).then(result => result.data);
    },

    getFacilitiesMeterHierarchies: function(ids) {
      return $http.post(
        `${ENERKEY_CONFIG.apiMeterManagement}/api/v1/meterhierarchy/facility`,
        ids,
        httpConfigService.getExtendedHttpConfig({ isCancellable: true })
      ).then(result => result.data);
    },

    deleteMeterHierarchy: function(meterHierarchyId) {
      return $http.delete(
        `${ENERKEY_CONFIG.apiMeterManagement}/api/v1/meterhierarchy/${meterHierarchyId}`,
        httpConfigService.getExtendedHttpConfig({ isCancellable: true })
      );
    }
  };
}
