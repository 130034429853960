import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class KendoMessageService extends MessageService {
  public constructor(
    private readonly translateService: TranslateService
  ) {
    super();
  }

  public override get(key: string): string {
    const translation = this.translateService.instant(key);

    return !translation || key === translation
      ? super.get(key)
      : translation;
  }
}
