import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'email-field-selection-form',
  templateUrl: './email-field-selection-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailFieldSelectionFormComponent {
  @Input() public form: UntypedFormGroup;
}
