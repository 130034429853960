import { tick } from '@angular/core/testing';
import { isObservable, Subscribable, Subscription } from 'rxjs';

import MatchersUtil = jasmine.MatchersUtil;
import CustomMatcherFactories = jasmine.CustomMatcherFactories;
import CustomMatcher = jasmine.CustomMatcher;
import CustomMatcherResult = jasmine.CustomMatcherResult;

/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */

declare const global: any;
type Spy$ = ReturnType<typeof spyOnObservable>;
type Subable = Subscribable<any>;

/** Contains the spy containers for tracked observables. Cleared in `afterEach` */
let _spyStorage: Map<Subscribable<any>, SpiedObservable>;

/** Contains all subscriptions to spied observables. Unsubscribed in `afterEach` */
let _rootSubscription: Subscription;

class SpiedObservable implements Spy$ {
  public values: any[] = [];
  public errored: { value?: any } = null;
  public completed: boolean = false;

  public get and(): any {
    return {
      tickAlways: (ms?: number) => {
        this._autoTick = typeof ms === 'number' ? ms : true;
        return this;
      }
    };
  }

  private _autoTick: boolean | number = false;

  public resetValues(): void {
    this.values.clear();
  }

  public autoTick(): Spy$ {
    if (this._autoTick === true) {
      tick();
    } else if (typeof this._autoTick === 'number') {
      tick(this._autoTick);
    }

    return this;
  }
}

/* istanbul ignore next */
function getSpied(target$: Subscribable<any>): SpiedObservable {
  if (!target$) {
    throw Error('Cannot expect() null Subscribable');
  }
  if (!_spyStorage) {
    throw Error('Observable matchers must be added for them to work');
  }
  const value = _spyStorage.get(target$);
  if (!value) {
    throw Error('Subscribable must be spied on using spyOnObservable()');
  }
  value.autoTick();
  return value;
}

const observableMatchers: CustomMatcherFactories = {
  toHaveEmitted: function(util: MatchersUtil): CustomMatcher {
    return {
      compare: (actual: Subscribable<unknown>, ...values: any[]) => _toHaveEmittedBase(
        getSpied(actual), true, values, util
      ),
      negativeCompare: (actual: Subscribable<unknown>, ...values: any[]) => _toHaveEmittedBase(
        getSpied(actual), false, values, util
      ),
    };
  },
  toHaveEmittedTimes: function(): CustomMatcher {
    return {
      compare: (actual: Subable, expected: number) => _toHaveEmittedTimes(getSpied(actual), true, expected),
      negativeCompare: (actual: Subable, expected: number) => _toHaveEmittedTimes(getSpied(actual), false, expected),
    };
  },
  toHaveSubscribers: function(): CustomMatcher {
    return {
      compare: (actual: Subable, expected: number) => _toHaveSubscribers(actual, true, expected),
      negativeCompare: (actual: Subable, expected: number) => _toHaveSubscribers(actual, false, expected),
    };
  },
  toHaveCompleted: function(): CustomMatcher {
    return {
      compare: (actual: Subable) => _toHaveCompleted(getSpied(actual), true),
      negativeCompare: (actual: Subable) => _toHaveCompleted(getSpied(actual), false)
    };
  },
  toHaveErrored: function(): CustomMatcher {
    return {
      compare: (actual: Subable) => _toHaveErrored(getSpied(actual), true),
      negativeCompare: (actual: Subable) => _toHaveErrored(getSpied(actual), false)
    };
  },
  toHaveErroredWith: function(util: MatchersUtil): CustomMatcher {
    return {
      compare: (actual: Subable, value: any) => _toHaveErroredWith(getSpied(actual), true, value, util),
      negativeCompare: (actual: Subable, value: any) => _toHaveErroredWith(getSpied(actual), false, value, util),
    };
  },
};

function _toHaveEmittedBase(
  opts: SpiedObservable,
  eq: boolean,
  values: any[],
  util: MatchersUtil
): CustomMatcherResult {
  if (values.length === 0) {
    return _toHaveEmittedAtAll(opts, eq);
  } else if (values.length === 1) {
    return _toHaveEmittedLast(opts, eq, values[0], util);
  } else {
    return _toHaveEmittedSequence(opts, eq, values, util);
  }
}

function _toHaveEmittedSequence(
  opts: SpiedObservable,
  eq: boolean,
  expected: any[],
  util: MatchersUtil
): CustomMatcherResult {
  if (expected.length > opts.values.length) {
    if (eq) {
      return {
        pass: false,
        message: `Expected observable to have emitted ${expected.length} values but it emitted ${opts.values.length}`
          + `, expected: ${util.pp(expected)}, actual: ${util.pp(opts.values)}`
          + _append(opts.errored, ' (and errored)') + _append(opts.completed, ' (and completed)')
      };
    } else {
      return { pass: true };
    }
  }

  const actual = opts.values.slice(-expected.length);
  const seqEq = util.equals(actual, expected);

  if (eq) {
    return {
      pass: seqEq,
      message: `Expected observable to have emitted sequence ${util.pp(expected)} but it emitted ${util.pp(actual)}`
        + _append(opts.errored, ' (and errored)') + _append(opts.completed, ' (and completed)'),
    };
  } else {
    return {
      pass: !seqEq,
      message: `Expected observable not to have emitted sequence ${util.pp(expected)} but it did`,
    };
  }
}

function _toHaveEmittedLast(
  opts: SpiedObservable,
  eq: boolean,
  expected: any,
  util: MatchersUtil
): CustomMatcherResult {
  const actual = opts.values[opts.values.length - 1];

  if (eq) {
    return {
      pass: util.equals(actual, expected),
      message: `Expected observable to have last emitted ${util.pp(expected)} but it emitted ${opts.values.length
        ? util.pp(actual)
        : 'nothing'}`
        + _append(opts.errored, ' (and errored)') + _append(opts.completed, ' (and completed)')
    };
  } else {
    return {
      pass: !util.equals(actual, expected),
      message: `Expected observable not to have last emitted ${util.pp(expected)}, but it did`
    };
  }
}

function _toHaveEmittedAtAll(
  opts: SpiedObservable,
  eq: boolean
): CustomMatcherResult {
  if (eq) {
    return {
      pass: opts.values.length > 0,
      message: 'Expected observable to have emitted but it never did'
        + _append(opts.errored, ', it errored instead')
        + _append(opts.completed, ', it completed instead')
    };
  } else {
    return {
      pass: opts.values.length === 0,
      message: `Expected observable not to have emitted, but it emitted ${opts.values.length} times`
    };
  }
}

function _toHaveEmittedTimes(
  opts: SpiedObservable,
  eq: boolean,
  expected: number
): CustomMatcherResult {
  const actual = opts.values.length;

  if (eq) {
    return {
      pass: actual === expected,
      message: `Expected observable to have emitted ${expected} times, but instead it emitted ${actual}`
        + _append(opts.errored, ' (and errored)')
        + _append(opts.errored, ' (and completed)')
    };
  } else {
    return {
      pass: actual !== expected,
      message: `Expected observable not to have emitted ${expected} times but it did`
    };
  }
}

function _toHaveSubscribers(
  source: Subable,
  eq: boolean,
  expected: number | undefined
): CustomMatcherResult {
  // minus 1 as we the spy is subscribed too
  const actual = (source as any).observers.length - 1;

  if (Number.isFinite(expected)) {
    if (eq) {
      return {
        pass: actual === expected,
        message: `Expected observable to have ${expected} subscribers, but it had ${actual}`
      };
    } else {
      return {
        pass: actual !== expected,
        message: `Expected observable not to have ${expected} but it did`
      };
    }
  } else {
    if (eq) {
      return {
        pass: actual > 0,
        message: 'Expected observable to have subscribers, but it didn\'t'
      };
    } else {
      return {
        pass: actual === 0,
        message: `Expected observable to not have subscribers, but it had ${actual}`
      };
    }
  }
}

function _toHaveErroredWith(
  opts: SpiedObservable,
  eq: boolean,
  value: any,
  util: MatchersUtil
): CustomMatcherResult {
  const erroredWithValue = opts.errored
    ? util.equals(value, opts.errored.value)
    : false;

  if (eq) {
    return {
      pass: erroredWithValue,
      message: `Expected observable to have errored with ${util.pp(value)}`
        + opts.errored ? ` but it errored with ${util.pp(opts.errored.value)}` : ' but it never errored'
        + _append(opts.completed, ', it completed instead'),
    };
  } else {
    return {
      pass: !erroredWithValue,
      message: `Expected observable to have errored with ${util.pp(value)} but it did`
    };
  }
}

function _toHaveErrored(
  opts: SpiedObservable,
  eq: boolean
): CustomMatcherResult {
  if (eq) {
    return {
      pass: !!opts.errored,
      message: 'Expected observable to have errored' + _append(opts.completed, ', it completed instead')
    };
  } else {
    return {
      pass: !opts.errored,
      message: 'Expected observable not to have errored, but it did'
    };
  }
}

function _toHaveCompleted(
  opts: SpiedObservable,
  eq: boolean
): CustomMatcherResult {
  if (eq) {
    return {
      pass: opts.completed,
      message: 'Expected observable to have completed' + _append(opts.errored, ', it errored instead')
    };
  } else {
    return {
      pass: !opts.completed,
      message: 'Expected observable not to have completed, but it did'
    };
  }
}

/** Appends the message if condition is truthy */
function _append(condition: any, message: string): string {
  return condition ? message : '';
}

/* istanbul ignore next */
function spyOnObservableImpl<T>(source$: Subscribable<T>): Spy$ {
  if (!isObservable(source$)) {
    throw Error(`Spied value is not an observable: ${Object.prototype.toString.apply(source$)}`);
  }
  if (_spyStorage.has(source$)) {
    throw Error('Parameter observable is already spied.');
  }

  const tracker = new SpiedObservable();

  const subscription = source$.subscribe({
    next: (value: any) => {
      tracker.values.push(value);
    },
    error: (err?: any) => {
      tracker.errored = { value: err };
    },
    complete: () => {
      tracker.completed = true;
    }
  });

  _rootSubscription.add(subscription);
  _spyStorage.set(source$, tracker);

  return tracker;
}

/**
 * Initializes matchers and functions required for observable testing.
 */
export function __setupObservableTesting(): any {
  /* istanbul ignore next */
  if (typeof spyOnObservable === 'function') {
    throw Error('spyOnObservable already initialized');
  }

  // Browser + Node
  (
    window || /* istanbul ignore next */ global
  ).spyOnObservable = spyOnObservableImpl;

  beforeEach(() => {
    _rootSubscription = new Subscription();
    _spyStorage = new Map<Subscribable<any>, SpiedObservable>();

    jasmine.addMatchers(observableMatchers);
  });

  afterEach(() => {
    _rootSubscription.unsubscribe();
    _rootSubscription = null;
    _spyStorage = null;
  });
}
