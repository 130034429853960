import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, take, takeUntil } from 'rxjs/operators';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { assertUnreachable, getStringEnumValues } from '@enerkey/ts-utils';

import { FacilityFilter, INumericFilter, NumericFilter } from '@enerkey/clients/settings';

import {
  FacilityColumnsPropertiesWithFields,
  FacilityPropertiesService,
  FacilityPropertyNames,
  FacilityPropertyWithField
} from '../../modules/energy-reporting/services/facility-properties.service';

import { FilterService } from '../../services/filter.service';
import { ComboItem } from '../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ExtendedFacilityInformation } from '../../shared/interfaces/extended-facility-information';
import { FacilityService } from '../../shared/services/facility.service';
import { ToasterService } from '../../shared/services/toaster.service';

interface FilterableGroup {
  property: string;
  name: string;
  items: FilterableProperty[];
}

interface FilterablePropertyBase {
  name: string;
  property: string;
  groupProperty: string;
  selectItems?: ComboItem<string>[];
}

enum FilterableType {
  String = 'string',
  Number = 'number'
}

interface FilterableStringProperty extends FilterablePropertyBase {
  type: FilterableType.String;
}

interface FilterableNumberProperty extends FilterablePropertyBase {
  type: FilterableType.Number;
}

type FilterableProperty = FilterableStringProperty | FilterableNumberProperty;

const filterablePropertyTypes: readonly string[] = getStringEnumValues(FilterableType);

@Component({
  selector: 'facility-filter-modal',
  templateUrl: './facility-filter-modal.component.html',
  styleUrls: ['./facility-filter-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  windowClass: 'modal-dialog-scrollable fixed-height'
})
export class FacilityFilterModalComponent extends ModalBase<void> implements OnDestroy {
  public readonly filterGroups$: Observable<FilterableGroup[]>;
  public readonly facilityPropertyNames$: Observable<FacilityPropertyNames>;

  // non breaking space
  public readonly defaultItemText = String.fromCharCode(0xa0);

  public readonly storedFilters$: Observable<FacilityFilter[]>;
  public readonly groupFilterCounts$: Observable<Record<string, number>>;
  public readonly isSaveEnabled$: Observable<boolean>;

  public filterValueForm = new UntypedFormGroup({});

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly filterService: FilterService,
    private readonly toasterService: ToasterService,
    facilityService: FacilityService,
    facilityPropertiesService: FacilityPropertiesService,
    activeModal: NgfActiveModal,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    super(activeModal);

    this.storedFilters$ = this.filterService.storedFilters$.pipe(
      map(v => v.sortBy(f => f.id ?? -1, 'desc')),
      takeUntil(this.destroy$)
    );

    this.filterGroups$ = combineLatest([
      facilityService.profileFacilities$,
      facilityPropertiesService.facilityProperties$,
    ]).pipe(
      map(([facilities, groups]) => groups.mapFilter(
        group => ({
          name: group.Name,
          property: group.Property,
          items: this.getGroupItems(group, facilities),
        }),
        group => Array.hasItems(group.items)
      )),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.groupFilterCounts$ = combineLatest([
      this.filterGroups$,
      this.filterValueForm.valueChanges.pipe(startWith({}))
    ]).pipe(
      map(([groups, _formValue]) => groups.toRecord(
        group => group.property,
        group => group.items.filter(i => this.propertyHasFilterValue(i, this.getPropertyFormValue(i))).length
      )),
      shareReplay(1),
      takeUntil(this.destroy$)
    );

    this.isSaveEnabled$ = this.groupFilterCounts$.pipe(
      map(counts => Object.values(counts).reduce((sum, current) => sum + current, 0) > 0)
    );

    combineLatest([
      this.filterGroups$,
      this.filterService.activeFilters$
    ])
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([groups, initialValue]) => {
        for (const group of groups) {
          this.filterValueForm.addControl(
            group.property,
            formBuilder.group(
              group.items.toRecord(
                item => item.property,
                item => this.getPropertyFormControl(item.type)
              )
            )
          );
        }
        this.filterValueForm.patchValue(initialValue?.textual ?? {});
        this.filterValueForm.patchValue(initialValue?.numeric ?? {});
      });

    this.facilityPropertyNames$ = facilityPropertiesService.facilityPropertyNames$.pipe(takeUntil(this.destroy$));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public filterBySelected(shouldSaveFilter = false): void {
    this.getFilterValue().subscribe(filterValue => {
      this.setFilterValue(filterValue, shouldSaveFilter);
    });
  }

  public removeFilter(filter: FacilityFilter): void {
    this.filterService.removeStoredFilter(filter.id).subscribe({
      error: () => {
        this.toasterService.generalError('SAVE', 'FILTERS');
      }
    });
  }

  public clearFilters(): void {
    this.filterValueForm.reset();
  }

  public setStoredFilter(filter: FacilityFilter): void {
    this.setFilterValue(filter);
  }

  public override dismissModal(): void {
    super.dismissModal();
  }

  private setFilterValue(filterValue: FacilityFilter, shouldSaveFilter = false): void {
    this.filterService.setCurrentFilter(filterValue).subscribe(isSuccessful => {
      if (!isSuccessful) {
        this.toasterService.warning('FACILITIES_FILTER.NO_FILTERED_FACILITIES');
        return;
      }
      if (shouldSaveFilter) {
        this.filterService.addFilter(filterValue).subscribe({
          next: () => {
            super.closeModal();
          },
          error: () => {
            this.toasterService.generalError('SAVE', 'FILTERS');
          }
        });
      } else {
        super.closeModal();
      }
    });
  }

  private getFilterValue(): Observable<FacilityFilter> {
    return this.filterGroups$.pipe(
      take(1),
      map(groups => groups
        .flatMap(group => group.items)
        .reduce(
          (filters, currentField) => {
            let value = this.getPropertyFormValue(currentField);
            if (!this.propertyHasFilterValue(currentField, value)) {
              return filters;
            }
            const filterKey = currentField.type === 'string'
              ? 'textual'
              : 'numeric'
              ;
            if (filterKey === 'numeric') {
              value = new NumericFilter(value as INumericFilter);
            }
            if (!filters[filterKey]) {
              filters[filterKey] = {};
            }
            if (!filters[filterKey][currentField.groupProperty]) {
              filters[filterKey][currentField.groupProperty] = {};
            }
            filters[filterKey][currentField.groupProperty][currentField.property] = value as string;
            return filters;
          },
          new FacilityFilter()
        ))
    );
  }

  private getPropertyFormValue(item: FilterableProperty): string | { min?: number, max?: number } {
    return this.filterValueForm.value[item.groupProperty]?.[item.property];
  }

  private propertyHasFilterValue(
    property: FilterableProperty,
    value: string | { min?: number, max?: number }
  ): boolean {
    switch (property.type) {
      case FilterableType.String:
        return !!value;
      case FilterableType.Number: {
        const { min, max } = (value as INumericFilter) ?? {};
        return Number.isFinite(min) || Number.isFinite(max);
      }
      /* istanbul ignore next */
      default:
        assertUnreachable(property);
    }
  }

  private getGroupItems(
    group: FacilityColumnsPropertiesWithFields,
    facilities: ExtendedFacilityInformation[]
  ): FilterableProperty[] {
    return group.Items.filter(item => filterablePropertyTypes.includes(item.Type)).map(item => {
      switch (item.Type) {
        case FilterableType.String:
          return this.getStringItem(item, group, facilities);
        case FilterableType.Number:
          return this.getNumberItem(item, group);
      }
    });
  }

  private getStringItem(
    item: FacilityPropertyWithField,
    group: FacilityColumnsPropertiesWithFields,
    facilities: ExtendedFacilityInformation[]
  ): FilterableStringProperty {
    return {
      name: item.Name,
      property: item.Property,
      groupProperty: group.Property,
      type: FilterableType.String,
      selectItems: facilities
        .unique(facility => (facility as Record<string, Record<string, string>>)[group.Property]?.[item.Property])
        .sort()
        .filterMap(
          value => value,
          value => ({
            text: value, value: value
          })
        )
    };
  }

  private getNumberItem(
    item: FacilityPropertyWithField,
    group: FacilityColumnsPropertiesWithFields
  ): FilterableNumberProperty {
    return {
      name: item.Name,
      property: item.Property,
      groupProperty: group.Property,
      type: FilterableType.Number,
    };
  }

  private getPropertyFormControl(
    propertyType: FilterableType
  ): UntypedFormGroup | UntypedFormControl {
    switch (propertyType) {
      case FilterableType.String:
        return this.formBuilder.control(null);
      case FilterableType.Number:
        return this.formBuilder.group({
          min: null,
          max: null
        });
      /* istanbul ignore next */
      default:
        assertUnreachable(propertyType);
    }
  }
}
