import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';

import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';

export type ErrorTicketWidgetOptions = {
  numberToShow: number;
  typeToShow: ErrorWidgetTicketType,
};

export enum ErrorWidgetTicketType {
  Terminal = 'Terminal',
  Facility = 'Facility',
  FacilityPT15M = 'FacilityPT15M'
}

type ChangeFn = (options: ErrorTicketWidgetOptions) => void;

@Component({
  selector: 'error-ticket-widget-options',
  templateUrl: './error-ticket-widget-options.component.html',
  styleUrls: ['./error-ticket-widget-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ErrorTicketWidgetOptionsComponent,
    multi: true,
  }]
})
export class ErrorTicketWidgetOptionsComponent implements OnInit, ControlValueAccessor {
  @Input() public initialState: ErrorTicketWidgetOptions;

  public readonly minCount: number = 5;
  public readonly maxCount: number = 200;

  public readonly ErrorWidgetTicketType = ErrorWidgetTicketType;

  public controls: ControlsOf<ErrorTicketWidgetOptions>;
  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public ngOnInit(): void {
    this.controls = formControlsFrom<ErrorTicketWidgetOptions>(this.optionsToForm(this.initialState));
    this.formGroup = new UntypedFormGroup(this.controls);

    this.formGroup.valueChanges.subscribe((formValue: ErrorTicketWidgetOptions) => {
      this._onChange?.(formValue);
    });
  }

  public writeValue(value: ErrorTicketWidgetOptions): void {
    this.formGroup.setValue(
      this.optionsToForm(value)
    );
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  private optionsToForm(options: ErrorTicketWidgetOptions): ErrorTicketWidgetOptions {
    return {
      typeToShow: options.typeToShow ?? ErrorWidgetTicketType.Facility,
      numberToShow: options.numberToShow ?? 20,
    };
  }
}
