import ReportModalController from './report-modal.controller';
import ReportModalTemplate   from 'raw-loader!./report-modal.html';

export default {
  template: ReportModalTemplate,
  controller: ReportModalController,
  controllerAs: 'vm',
  bindings: {
    modalClose: '<',
    modalDismiss: '<',
    modalParams: '<'
  }
};
