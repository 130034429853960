import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { SelectableSettings, SelectAllCheckboxState, SelectionEvent } from '@progress/kendo-angular-grid';
import { Observable, Subject } from 'rxjs';

export class KendoGridSelection<T, K extends keyof T> {

  public readonly selectable: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple'
  };

  public selectedItems: T[] = [];

  private _data: T[];
  private filter: CompositeFilterDescriptor;
  // Kendo selectionChange is also triggered on selectAllChange, this is used to cancel that behavior
  private skipNextChangeEvent = false;
  private readonly _selectionChange = new Subject<T[]>();

  public constructor(data: T[], public readonly kendoGridSelectBy: K) {
    this._data = data;
  }

  public get data(): T[] {
    return this._data;
  }

  public set data(data: T[]) {
    this._data = data;
    this.updateSelection(this.data);
  }

  public get selectedKeys(): T[K][] {
    return this.selectedItems.map(item => item[this.kendoGridSelectBy]);
  }

  public get selectionChange$(): Observable<T[]> {
    return this._selectionChange;
  }

  public gridSelectionChange(selection: SelectionEvent): void {
    if (!this.skipNextChangeEvent) {
      this.selectedItems.remove(...selection.deselectedRows.map(row => row.dataItem));
      this.selectedItems.push(...selection.selectedRows.map(row => row.dataItem));
      this._selectionChange.next(this.selectedItems);
    }
    this.skipNextChangeEvent = false;
  }

  public allSelectionChange(state: SelectAllCheckboxState): void {
    this.selectedItems.clear();
    if (state === 'checked') {
      this.selectedItems.push(...this.filteredItems);
    }
    this.skipNextChangeEvent = true;
    this._selectionChange.next(this.selectedItems);
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.updateSelection(this.filteredItems);
  }

  private get filteredItems(): T[] {
    return filterBy(this.data, this.filter);
  }

  private updateSelection(newItems: T[]): void {
    const oldSelectionLength = this.selectedItems.length;
    const selectedKeys = new Set(this.selectedItems.map(item => item[this.kendoGridSelectBy]));
    this.selectedItems = newItems.filter(item => selectedKeys.has(item[this.kendoGridSelectBy]));
    if (this.selectedItems.length !== oldSelectionLength) {
      this._selectionChange.next(this.selectedItems);
    }
  }
}
