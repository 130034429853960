import { ExecutionPhase } from '@enerkey/clients/attachments';
import { ComboItem } from '../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { getNumericEnumValues } from '@enerkey/ts-utils';

export const executionPhases = [
  { id: 0, key: 'ACTIONS.EXECUTIONPHASE_0', letterCode: 'E', name: 'suggestion' },
  { id: 1, key: 'ACTIONS.EXECUTIONPHASE_1', letterCode: 'H', name: 'considered' },
  { id: 2, key: 'ACTIONS.EXECUTIONPHASE_2', letterCode: 'P', name: 'implementationDecided' },
  { id: 3, key: 'ACTIONS.EXECUTIONPHASE_3', letterCode: 'T', name: 'implemented' },
  { id: 4, key: 'ACTIONS.EXECUTIONPHASE_4', letterCode: 'HY', name: 'rejected' }
];

export const executionPhaseTranslations: Readonly<Record<ExecutionPhase, string>> = {
  [ExecutionPhase.Suggestion]: 'ACTIONS.EXECUTIONPHASE_0', // suggestion
  [ExecutionPhase.Considered]: 'ACTIONS.EXECUTIONPHASE_1', // considered
  [ExecutionPhase.ImplementationDecided]: 'ACTIONS.EXECUTIONPHASE_2', // implementationDecided
  [ExecutionPhase.Implemented]: 'ACTIONS.EXECUTIONPHASE_3', // implemented
  [ExecutionPhase.Rejected]: 'ACTIONS.EXECUTIONPHASE_4', // rejecte
};

export function getExecutionPhaseComboItems(): ComboItem<ExecutionPhase>[] {
  return getNumericEnumValues(ExecutionPhase)
    .map(value => ({ value, text: executionPhaseTranslations[value] }));
}
