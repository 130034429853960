import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { endOfWeek, getWeek, isSameWeek, startOfDay } from 'date-fns';

import { ISODuration } from '../../../../../shared/isoduration';
import { EtCurveModel, ETCurvePoint } from '../../../models/et-curve.model';

@Component({
  selector: 'et-curve-chart-tooltip',
  templateUrl: './et-curve-chart-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EtCurveChartTooltipComponent implements OnChanges {
  private static getEndDate(date: Date, showTo: Date): Date {
    const isInLastWeek = isSameWeek(date, showTo, { weekStartsOn: 1 });
    return isInLastWeek ? startOfDay(showTo) : startOfDay(endOfWeek(date, { weekStartsOn: 1 }));
  }

  /** Chart model that contains the data item */
  @Input() public model: EtCurveModel;
  public week: number = null;
  public year: number = null;
  public weekStartDate: Date;
  public weekEndDate: Date;
  public time: string = null;
  /** Hovered chart data item */
  @Input() public dataItem: ETCurvePoint;

  public ngOnChanges(): void {
    if (this.model.resolution === ISODuration.P7D) {
      if (!this.dataItem.timestamp) {
        // week 1000 does not exist, used for template to hide week info text
        this.week = 1000;
        return;
      }

      const date = new Date(this.dataItem.timestamp);

      this.year = date.getFullYear();
      this.week = getWeek(date);
      this.weekStartDate = startOfDay(date);
      this.weekEndDate = EtCurveChartTooltipComponent.getEndDate(date, this.model.showTo);
    }
    if (this.model.resolution === ISODuration.PT1H) {
      this.time = `${
        (`0${ this.dataItem.timestamp.getHours()}`).slice(-2)} 
        - ${(`0${ this.dataItem.timestamp.getHours() + 1}`).slice(-2)}`;
    }
  }
}
