import DonutVisualsController from './donut-visuals-controller';
import donutVisualsTemplate from 'raw-loader!./donut-visuals.html';

export default {
  template: donutVisualsTemplate,
  controller: DonutVisualsController,
  controllerAs: 'vm',
  bindings: {
    viewData: '<',
    columns: '<',
    aggregates: '<',
    tooManyGroups: '<',
    config: '<',
    update: '&',
  }
};
