<kendo-grid
  [kendoGridBinding]="rows"
  [navigable]="true"
  [resizable]="true"
  [group]="gridGrouping"
  [sort]="gridSorting"
  [loading]="loading$ | async"
  (inCellTab)="cellClickHandler($any($event))"
  (cellClick)="cellClickHandler($event)"
  (cellClose)="cellCloseHandler($event)"
  [kendoGridExpandGroupBy]="groupKey"
  [expandedGroupKeys]="expandedGroupKeys"
  (expandedGroupKeysChange)="expandedChange($event)"
  [groupable]="false"
  [rowClass]="rowClassCallback"
  (excelExport)="onExcelExport($event)"
>
  <ng-template kendoGridNoRecordsTemplate>
    <h1
      *ngIf="!(loading$ | async)"
      class="clickable no-records-disclaimer"
      (click)="addRow()"
    >
      {{ 'SUSTAINABILITY.GRI.NO_RECORDS' | translate : { addrow: 'SUSTAINABILITY.GRI.ADD_ROW' | translate } }}
    </h1>
  </ng-template>

  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      type="button"
      icon="plus"
      size="medium"
      (click)="addRow()"
    >
      {{ 'SUSTAINABILITY.GRI.ADD_ROW' | translate }}
    </button>

    <button
      kendoButton
      type="button"
      icon="plus"
      size="medium"
      themeColor="base"
      (click)="openUnitEditModal()"
    >
      {{ 'SUSTAINABILITY.GRI.ADD_UNIT' | translate }}
    </button>

    <button
      kendoButton
      type="button"
      icon="import"
      (click)="openImportModal()"
    >
      {{ 'SUSTAINABILITY.GRI.IMPORT_CONSUMPTIONS' | translate }}
    </button>

    <button
      kendoGridExcelCommand
      [disabled]="rows.length === 0"
    >
      {{ 'ADMIN.GET_EXCEL' | translate }}
    </button>

    <kendo-switch
      [(ngModel)]="showExpandedColumns"
      size="small"
      [onLabel]="' '"
      [offLabel]="' '"
      *labelBefore="'SUSTAINABILITY.GRI.SHOW_MONTHS' | translate"
    ></kendo-switch>
  </ng-template>

  <!-- Imported consumption info button column -->
  <kendo-grid-column
    [width]="24"
    class="cell-relative-position"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        *ngIf="dataItem.metadata && dataItem.changes.metadata"
        class="k-dirty"
      ></span>
      <i
        *ngIf="dataItem.metadata"
        (click)="openMetadataGrid(dataItem)"
        class="clickable fas fa-info-circle"
        [ngfTooltip]="'SUSTAINABILITY.GRI.VIEW_IMPORTED_CONSUMPTIONS' | translate"
        [anchor]="'parent'"
        [placement]="'right'"
      ></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="category.scope"
    [hidden]="true"
    [editable]="false"
  >
    <div
      *kendoGridGroupHeaderTemplate="let group; let value = value"
      class="group-header-container"
      [ngClass]="'scope-header-' + value"
    >
      <strong>{{ value | griScopeName : true }}</strong>
      <strong class="co2-target float-right">
        {{ value | griScopeName }}
        {{ 'SUSTAINABILITY.GRI.TOTAL' | translate | lowercase }}
        {{ group | gridGroupAggregate : 'co2total' | kendoNumber : formatApprox }}
        tCO₂e
      </strong>
    </div>
  </kendo-grid-column>

  <kendo-grid-column
    field="category.id"
    [hidden]="true"
    [editable]="false"
  >
    <span *kendoGridCellTemplate="let dataItem">
      {{ dataItem.category?.name }}
    </span>
    <ng-template
      kendoGridGroupHeaderTemplate
      let-group
    >
      <div
        *ngIf="group.items?.[0]?.items?.[0]?.category as category"
        class="group-header-container"
      >
        <strong>{{ category.name | translate }}</strong>

        <div class="group-header-tools float-right">
          <strong class="co2-target">
            <span>
              {{ 'SUSTAINABILITY.GRI.TOTAL' | translate }}
              {{ group | gridGroupAggregate : 'co2total' | kendoNumber : formatApprox }}
              tCO₂e
            </span>
          </strong>
        </div>

      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="unit.quantityId"
    [hidden]="true"
    [editable]="false"
  >
    <ng-template
      kendoGridGroupHeaderTemplate
      let-group
    >
      <div class="full-width-header">
        <ng-container *ngIf="group.value as quantityId">
          <quantity-icon
            [quantity]="quantityId"
            [ngClass]="'quantity-grouping-' + quantityId"
          ></quantity-icon>
          {{ quantityId | quantityName | async }}
        </ng-container>

        <ng-container *ngIf="!group.value">
          {{ 'SUSTAINABILITY.GRI.NO_QUANTITY' | translate }}
        </ng-container>

        <div class="total float-right">
          <quantity-icon
            *ngIf="group.value"
            [quantity]="group.value"
          ></quantity-icon>
          {{ 'SUSTAINABILITY.GRI.TOTAL' | translate }}
          {{ group | gridGroupAggregate : 'co2total' | kendoNumber : formatApprox }}
          tCO₂e

          <div class="group-action-buttons">
            <button
              *ngIf="group.items | griHasImportedRows"
              [title]="'SUSTAINABILITY.GRI.UPDATE_CONSUMPTIONS' | translate "
              class="k-button-icon k-icon k-i-import"
              (click)="refreshRows(group.items)"
            ></button>

            <button
              [title]="'DELETE' | translate"
              class="k-button-icon k-icon k-i-trash red-color"
              (click)="removeGroup(group.items)"
            ></button>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="description"
    [title]="'SUSTAINABILITY.GRI.DESCRIPTION' | translate"
    class="cell-relative-position editable-cell"
    [width]="160"
    [editable]="true"
    editor="text"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        *ngIf="dataItem.changes.description"
        class="k-dirty"
      ></span>
      <span>
        {{ dataItem.description }}
      </span>
      <span
        *ngIf="dataItem.changes.isNew"
        class="new-indicator"
        [attr.title]="'NEW' | translate"
      >*</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="unit.id"
    [title]="'SUSTAINABILITY.GRI.UNIT_NAME' | translate"
    class="cell-relative-position"
    [editable]="false"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <div class="space-between">
        <span
          *ngIf="dataItem.changes.unit"
          class="k-dirty"
        ></span>
        <gri-unit-name
          [unit]="dataItem.unit"
          class="overflow-dots"
        ></gri-unit-name>
        <button
          type="button"
          class="unit-edit k-button-icon k-icon k-i-edit"
          [attr.title]="(dataItem.unit?.isDefault
          ? 'SUSTAINABILITY.GRI.INSPECT_UNIT'
          : 'SUSTAINABILITY.GRI.EDIT_UNIT') | translate"
          (click)="openUnitEditModal(dataItem.unit)"
        ></button>
      </div>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column
    field="unit.unit"
    [title]="'SUSTAINABILITY.GRI.UNIT' | translate"
    [editable]="false"
    [width]="100"
    [hidden]="showExpandedColumns"
  ></kendo-grid-column>

  <kendo-grid-column
    field="unit.co2Factor"
    [title]="'SUSTAINABILITY.GRI.CO2FACTOR' | translate"
    [editable]="false"
    [width]="150"
    [hidden]="showExpandedColumns"
    [format]="formatApprox"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <span>{{ dataItem.emissionFactor }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngFor="let field of valueFields; let month = index"
    [title]="month | monthName | titlecase"
    [field]="field"
    [format]="formatPrecise"
    [editable]="true"
    [hidden]="!showExpandedColumns"
    class="cell-relative-position editable-cell"
    editor="numeric"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
      let-column="column"
    >
      <span
        *ngIf="dataItem.changes[column.field]"
        class="k-dirty"
      ></span>

      <span>
        {{ dataItem[column.field] | kendoNumber : formatPrecise }}
      </span>
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-column="column"
      let-formGroup="formGroup"
    >
      <kendo-numerictextbox
        kendoAutoFocus
        [formControl]="formGroup.controls[column.field]"
        [format]="formatApprox"
        [spinners]="false"
      ></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [title]="'SUSTAINABILITY.GRI.TOTAL_CONSUMPTION' | translate"
    field="totalValue"
    class="cell-relative-position editable-cell"
    [format]="formatApprox"
    [editable]="true"
    editor="numeric"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        *ngIf="dataItem.changes.totalValue"
        class="k-dirty"
      ></span>
      <strong>
        {{ dataItem.totalValue | kendoNumber : formatApprox }} {{ dataItem.unit?.unit }}
      </strong>
    </ng-template>
    <ng-template
      kendoGridEditTemplate
      let-column="column"
      let-formGroup="formGroup"
    >
      <kendo-numerictextbox
        kendoAutoFocus
        [formControl]="formGroup.controls[column.field]"
        [spinners]="false"
        [format]="formatApprox"
        [decimals]="8"
      ></kendo-numerictextbox>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [title]="('SUSTAINABILITY.GRI.TOTAL' | translate) + ' CO₂'"
    field="co2total"
    class="cell-relative-position"
    [editable]="false"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <span
        *ngIf="dataItem.changes.co2total"
        class="k-dirty"
      ></span>
      <strong>
        {{ dataItem.co2total | griCo2total : Co2eq.Tonnes : '#,#.0' }}
      </strong>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [title]="'SUSTAINABILITY.GRI.COMMANDS.TITLE' | translate"
    [width]="120"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <button
        type="button"
        class="k-button-icon k-icon k-i-edit"
        [attr.title]="'SUSTAINABILITY.GRI.EDIT_ROW' | translate"
        (click)="editRow(dataItem)"
      ></button>
      <button
        type="button"
        class="k-button-icon k-icon k-i-reset"
        [attr.title]="'SUSTAINABILITY.GRI.COMMANDS.REVERT' | translate"
        [class.k-disabled]="!dataItem.changes.anyChanges"
        [disabled]="!dataItem.changes.anyChanges"
        (click)="resetRowValues(dataItem, false)"
      ></button>
      <button
        type="button"
        class="k-button-icon k-icon k-i-strip-all-formatting"
        [attr.title]="'SUSTAINABILITY.GRI.COMMANDS.CLEAR' | translate"
        (click)="resetRowValues(dataItem, true)"
      ></button>
      <button
        class="k-button-icon k-icon k-i-trash"
        [attr.title]="'DELETE' | translate"
        (click)="removeRow(dataItem)"
      ></button>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel
    [fileName]="excelFileName"
    [fetchData]="getExportData"
  >
    <!-- Columns for the exported excel -->
    <kendo-excelexport-column
      field="category.scope"
      [title]="'SUSTAINABILITY.GRI.SCOPE' | translate"
    ></kendo-excelexport-column>
    <kendo-excelexport-column
      field="category.name"
      [title]="'SUSTAINABILITY.GRI.CATEGORY' | translate"
    ></kendo-excelexport-column>
    <kendo-excelexport-column
      field="description"
      [title]="'SUSTAINABILITY.GRI.DESCRIPTION' | translate"
    ></kendo-excelexport-column>
    <!-- isDefault used only during excel creation to translate default unit names -->
    <kendo-excelexport-column field="unit.isDefault"></kendo-excelexport-column>
    <kendo-excelexport-column
      field="unit.name"
      [title]="'SUSTAINABILITY.GRI.UNIT_NAME' | translate"
    ></kendo-excelexport-column>
    <kendo-excelexport-column
      field="unit.unit"
      [title]="'SUSTAINABILITY.GRI.UNIT' | translate"
    ></kendo-excelexport-column>
    <kendo-excelexport-column
      field="emissionFactor"
      [title]="'SUSTAINABILITY.GRI.CO2FACTOR' | translate"
    ></kendo-excelexport-column>
    <ng-container *ngIf="showExpandedColumns">
      <kendo-excelexport-column
        *ngFor="let field of valueFields; let month = index"
        [field]="field"
        [title]="month | monthName | titlecase"
        [cellOptions]="{ format: '0.00' }"
      ></kendo-excelexport-column>
    </ng-container>
    <kendo-excelexport-column
      field="totalValue"
      [title]="'SUSTAINABILITY.GRI.TOTAL_CONSUMPTION' | translate"
      [cellOptions]="{ format: '0.00' }"
    ></kendo-excelexport-column>
    <kendo-excelexport-column
      field="co2total"
      [title]="('SUSTAINABILITY.GRI.TOTAL' | translate) + ' tCO₂e/' + ('YEAR_ABBR' | translate)"
      [cellOptions]="{ format: '0' }"
    ></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>

<ng-template
  #metadataGrid
  let-dataItem="dataItem"
>
  <gri-metadata-grid
    [metadata]="dataItem.metadata"
    [isReadOnly]="true"
    [quantityId]="dataItem.unit?.quantityId"
    [row]="dataItem"
    (refreshed)="refreshHasChanges()"
  ></gri-metadata-grid>
</ng-template>
