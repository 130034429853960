import { Injectable } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import {
  Configuration,
  IConfiguration,
  PortalSettings,
  SwaggerException,
  UserManagementClient,
} from '@enerkey/clients/user-management';

import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class TenantInfoService {
  public readonly tenantEditInfo$: Observable<Configuration>;
  private readonly tenantInfo$: Observable<Configuration>;

  private readonly tenantEdit$ = new Subject<Configuration>();

  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private toasterService: ToasterService
  ) {
    this.tenantInfo$ = merge(
      this.userManagementClient.getBasicTenantConfiguration(),
      this.tenantEdit$
    ).pipe(shareReplay(1));

    this.tenantEditInfo$ = merge(
      this.userManagementClient.getCompleteTenantConfiguration(),
      this.tenantEdit$
    ).pipe(shareReplay(1));

  }

  public getDefaultLanguage(): Observable<string> {
    return this.tenantInfo$.pipe(
      take(1),
      map(info => info.portalSettings.defaultLanguage)
    );
  }

  public getAutomaticMissingReadingEmail(): Observable<string> {
    return this.tenantInfo$.pipe(
      map(info => info?.systemEmailSettings?.missingAutomaticReadingsEmail)
    );
  }

  public getTenantLinkInfo(): Observable<PortalSettings> {
    return this.tenantInfo$.pipe(
      map(tenantInfo => tenantInfo.portalSettings)
    );
  }

  public getFeedbackEmail(): Observable<string> {
    return this.getTenantLinkInfo().pipe(
      map(linkInfo => linkInfo?.feedbackEmail || 'support@enerkey.com')
    );
  }

  public updateTenantInfo(config: IConfiguration): void {
    this.tenantEditInfo$
      .pipe(
        take(1),
        switchMap(tenantInfo => this.userManagementClient.editTenantConfiguration(
          Configuration.fromJS({ ...tenantInfo, ...config })
        ))
      ).subscribe({
        next: configuration => {
          this.toasterService.success(null, 'Tenant info updated');
          this.tenantEdit$.next(configuration);
        },
        error: (error: SwaggerException) => {
          this.toasterService.error(
            'Updating tenant information failed',
            `${error?.status} ${error?.message} ${error?.response}`
          );
        }
      });
  }
}
