mrcapi.$inject = ['$resource', '$translate', '$q', 'UserService', 'httpConfigService'];

function mrcapi($resource, $translate, $q, UserService, httpConfigService) {
  // Note: in order for $resource to resolve the parameters for each call the params need to be functions.
  // The httpConfigService.getExtendedHttpConfig is still used here to get the actual parameter values though so
  // that there is a single place where these parameters are actually created (easier maintainability).
  // $resource has to get the params every time because the reportingObjectSetId changes if profile is changed.
  const getLanguage = () => httpConfigService.getExtendedHttpConfig({}).params.lang;
  const getReportingObjectSetId = () => httpConfigService.getExtendedHttpConfig({}).params.ReportingObjectSetId;

  const getDefaultUrlParameters = () => ({
    lang: getLanguage,
    reportingObjectSetId: getReportingObjectSetId
  });

  // eslint-disable-next-line no-undef
  const apiUrl = `${ENERKEY_CONFIG.apiMrcOutput}/api/v1/`;
  // eslint-disable-next-line no-undef
  const sendEmailUrl = ENERKEY_CONFIG.apiUserManagement + '/api/email/sendmail';

  const outputRest = $resource(apiUrl, getDefaultUrlParameters(), {
    listFacilities: {
      method: 'GET',
      url: apiUrl + ':reportingObjectSetId/reportingobjects?lang=:lang',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    getQuantitiesAndMeters: {
      method: 'GET',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/meters?lang=:lang',
      isArray: true,
      cancellable: true,
      headers: UserService.getAuthorizationHeader()
    },
    getMeterReadings: {
      method: 'GET',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/readings?lang=:lang',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    getMeterHistory: {
      method: 'GET',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/history?lang=:lang',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    getMeter: {
      method: 'GET',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/meter',
      headers: UserService.getAuthorizationHeader()
    },
    getMonthValuesForMeters: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/monthValues?from=:from&to=:to&lang=:lang',
      isArray: false,
      cancellable: true,
      headers: UserService.getAuthorizationHeader()
    },
    getConsumptionRecordGapsForMeter: {
      method: 'GET',
      url: apiUrl +
        ':reportingObjectSetId/:reportingObjectId/:meterId/consumptions/recordGaps/days?from=:from&to=:to&lang=:lang',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    getEarliestInputDatesForMeters: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/consumptions/earliestDayForInput?lang=:lang',
      isArray: false,
      headers: UserService.getAuthorizationHeader()
    },
    getLatestReadingsForMeters: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/latestreadings?lang=:lang',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    getLatestReadingForMeter: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/latestreadings?meterId=:meterId&lang=:lang',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    getReadingsHistoryForMeters: {
      method: 'GET',
      url: apiUrl + ':reportingObjectSetId/readinghistory/:reportingObjectId?lang=:lang',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    getConsumptionInputHistory: {
      method: 'GET',
      url: apiUrl +
        ':reportingObjectSetId/:reportingObjectId/:meterId/consumptioninputhistory?from=:from&to=:to&lang=:lang',
      isArray: false,
      headers: UserService.getAuthorizationHeader()
    }
  });

  const inputRest = $resource(apiUrl, getDefaultUrlParameters(), {
    addMeterValues: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/readings',
      isArray: true,
      headers: UserService.getAuthorizationHeader()
    },
    addToHistory: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/reading',
      headers: UserService.getAuthorizationHeader()
    },
    deleteMeterReading: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/delete',
      headers: UserService.getAuthorizationHeader()
    },
    changeMeter: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/changemeter',
      headers: UserService.getAuthorizationHeader(),
      transformResponse: response => ({
        code: response
      })
    },
    updateReading: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/update',
      headers: UserService.getAuthorizationHeader()
    },
    changeFactor: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/changefactor',
      headers: UserService.getAuthorizationHeader()
    },
    changeFactorInHistory: {
      method: 'POST',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/changehistoryfactor',
      headers: UserService.getAuthorizationHeader()
    },
    addMonthlyConsumptionValues: {
      method: 'PUT',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/consumptions/monthly',
      headers: UserService.getAuthorizationHeader()
    },
    addMonthlyConsumptionValuesSilent: {
      method: 'PUT',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/consumptions/monthly',
      headers: UserService.getAuthorizationHeader(),
      silent: true
    },
    addConsumptionIntervalValues: {
      method: 'PUT',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/consumptions',
      headers: UserService.getAuthorizationHeader()
    },
    deleteMonthlyConsumptionValues: {
      method: 'DELETE',
      url: apiUrl + ':reportingObjectSetId/:reportingObjectId/:meterId/consumptions?from=:from&to=:to',
      headers: UserService.getAuthorizationHeader()
    }
  });

  const mailer = $resource(sendEmailUrl, {}, {
    sendEmail: { method: 'POST', url: sendEmailUrl, headers: UserService.getAuthorizationHeader() }
  });

  // Same as callGETApi except instead of returning $promise, returns
  // whole request. Must be used if request needs to be cancelled.
  const callGETApiFullRequest = (actualCall, options) => actualCall(options);

  const callGETApi = (actualCall, options) => UserService
    .isInitializedWithInitialProfileAsync()
    .then(() => actualCall(options).$promise);

  // Same as callPOSTApi except instead of returning $promise, returns
  // whole request. Must be used if request needs to be cancelled.
  const callPOSTApiFullRequest = (actualCall, options, payload) => actualCall(options, payload);

  const callPOSTApi = (actualCall, options, payload) => UserService
    .isInitializedWithInitialProfileAsync()
    .then(() => actualCall(options, payload).$promise);

  const callDELETEApi = (actualCall, options) => UserService
    .isInitializedWithInitialProfileAsync()
    .then(() => actualCall(options).$promise);

  return {
    // Output to UI
    listFacilities: function() {
      return callGETApi(outputRest.listFacilities, {});
    },
    getQuantitiesAndMeters: function(facilityId) {
      return callGETApiFullRequest(outputRest.getQuantitiesAndMeters, {
        reportingObjectId: facilityId
      });
    },
    getMeterReadings: function(facilityId, meterId) {
      return callGETApi(outputRest.getMeterReadings, {
        reportingObjectId: facilityId,
        meterId: meterId
      });
    },
    getMeterHistory: function(options) {
      return callGETApi(outputRest.getMeterHistory, {
        reportingObjectId: options.reportingObjectId,
        meterId: options.meterId
      });
    },
    getMeter: function(options) {
      return callGETApi(outputRest.getMeter, {
        reportingObjectId: options.reportingObjectId,
        meterId: options.meterId
      });
    },
    getMonthValuesForMeters: function(options, meterIds) {
      return callPOSTApiFullRequest(outputRest.getMonthValuesForMeters, {
        reportingObjectId: options.facilityId,
        from: options.fromDate,
        to: options.toDate
      }, meterIds);
    },
    getConsumptionRecordGapsForMeter: function(facilityId, meterId, timeframe) {
      return callGETApi(outputRest.getConsumptionRecordGapsForMeter, {
        reportingObjectId: facilityId,
        meterId: meterId,
        from: timeframe.from,
        to: timeframe.to
      });
    },
    getEarliestInputDatesForMeters: function(facilityId, meterIds) {
      return callPOSTApi(outputRest.getEarliestInputDatesForMeters, {
        reportingObjectId: facilityId
      }, meterIds);
    },

    getLatestReadingsForMeters: function(facilityIds) {
      return callPOSTApi(outputRest.getLatestReadingsForMeters, {}, facilityIds);
    },

    getLatestReadingForMeter: function(facilityId, meterId) {
      return callPOSTApi(outputRest.getLatestReadingForMeter, { meterId: meterId }, [facilityId]);
    },

    getReadingsHistoryForMeters: function(facilityId) {
      return callGETApi(outputRest.getReadingsHistoryForMeters, {
        reportingObjectId: facilityId
      });
    },

    // Input from UI
    saveMeterValues: function(facilityId, data) {
      return callPOSTApi(inputRest.addMeterValues, {
        reportingObjectId: facilityId
      }, data);
    },
    addToHistory: function(reportingObjectId, meter, readings) {
      return callPOSTApi(inputRest.addToHistory, {
        reportingObjectId: reportingObjectId,
        meterId: meter
      }, readings);
    },
    changeFactor: function(options) {
      return callPOSTApi(inputRest.changeFactor, {
        reportingObjectId: options.reportingObjectId,
        meterId: options.meterId
      }, { Factor: options.newFactor });
    },
    changeFactorInHistory: function(options) {
      return callPOSTApi(inputRest.changeFactorInHistory, {
        reportingObjectId: options.reportingObjectId,
        meterId: options.meterId
      }, { Factor: options.newFactor, Date: options.Date });
    },
    deleteMeterReading: function(options) {
      return callPOSTApi(inputRest.deleteMeterReading, {
        reportingObjectId: options.reportingObjectId,
        meterId: options.meterId
      }, { Date: options.readingDate });
    },
    changeMeter: function(reportingObjectId, data) {
      return callPOSTApi(inputRest.changeMeter, {
        reportingObjectId: reportingObjectId
      }, data);
    },
    updateReading: function(reportingObjectId, meterId, data) {
      return callPOSTApi(inputRest.updateReading, {
        reportingObjectId: reportingObjectId,
        meterId: meterId
      }, data);
    },
    addMonthlyConsumptionValues: function(reportingObjectId, meterId, data, silent) {
      const func = silent ? inputRest.addMonthlyConsumptionValuesSilent : inputRest.addMonthlyConsumptionValues;
      return callPOSTApi(func, {
        reportingObjectId: reportingObjectId,
        meterId: meterId
      }, data);
    },
    addConsumptionIntervalValues: function(reportingObjectId, meterId, data) {
      return callPOSTApi(inputRest.addConsumptionIntervalValues, {
        reportingObjectId: reportingObjectId,
        meterId: meterId
      }, data);
    },
    deleteMonthlyConsumptionValues: function(reportingObjectId, meterId, data) {
      return callDELETEApi(inputRest.deleteMonthlyConsumptionValues, {
        reportingObjectId: reportingObjectId,
        meterId: meterId,
        from: data.ValueDate,
        to: data.ValueEndDate
      });
    },
    sendMissingReadingsEmail: function(content) {
      return mailer.sendEmail({}, content).$promise;
    },
    getConsumptionInputHistory: function(facilityId, meterId, timeframe) {
      return callGETApi(outputRest.getConsumptionInputHistory, {
        reportingObjectId: facilityId,
        meterId: meterId,
        from: timeframe.from,
        to: timeframe.to
      });
    }
  };

}

export default mrcapi;
