<modal-view-header [headingText]="'TAGS.EDIT.SINGLE' | translate"></modal-view-header>

<div 
  class="modal-body" 
  [loadingSpinner]="loading$ | async" 
  [loadingOverlay]="true">
  <form
    [formGroup]="tagEditForm"
    (ngSubmit)="submit()"
    id="tagEditForm"
    (keydown.enter)="$event.preventDefault()"
  >
    <h2>{{ selectedFacility?.displayName }}</h2>

    <ek-multi
      *labelBefore="'TAGS.TITLE' | translate"
      formControlName="tags"
      [items]="tags"
      [overflowThreshold]="10"
    ></ek-multi>

    <hr>

    <kendo-textbox
      *labelBefore="'TAGS.ADD_NEW' | translate"
      [formControl]="newTagField"
      (keyup.enter)="addNewTag()"
    >
      <ng-template kendoTextBoxSuffixTemplate>
        <button
          type="button"
          kendoButton
          (click)="addNewTag()"
        >
          <i class="fas fa-plus"></i>
        </button>
      </ng-template>
    </kendo-textbox>

  </form>

  <hr>

  <div>
    <ul>
      <li>
        <i class="tags-added fas fa-plus-square"></i>
        {{ addedTags ?? ('TAGS.EDIT.NOCHANGES' | translate) }}
      </li>
      <li>
        <i class="tags-removed fas fa-minus-square"></i>
        {{ removedTags ?? ('TAGS.EDIT.NOCHANGES' | translate) }}
      </li>
    </ul>
  </div>

</div>

<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="tagEditForm"
      [disabled]="!tagEditForm.valid && !tagEditForm.dirty"
    >
      {{ 'MODALS.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
