import { AttachmentType, DocumentType } from '@enerkey/clients/attachments';

import { Period } from '../shared/ek-inputs/dateperiod-select/dateperiod-select.component';

type PeriodParams = {
  periodLength: string,
  periodStartTime: Date,
}

export const periodLengthToPeriodType: Record<string, number> = {
  P1M: 3,
  P3M: 4,
  P1Y: 5
};

export function mapPeriodLengthToFriendlyString(periodLenght: string, periodStartTime: Date): string {
  if (!periodStartTime) {
    return '';
  }
  const month = periodStartTime.getMonth();
  let quarter = '';
  switch (periodLenght) {
    case 'P1M':
      return kendo.toString(periodStartTime, 'yyyy/MM');
    case 'P1Y':
      return kendo.toString(periodStartTime, 'yyyy');
    case 'P3M':
      if (month < 3) {
        quarter = 'Q1';
      } else if (month < 6) {
        quarter = 'Q2';
      } else if (month < 9) {
        quarter = 'Q3';
      } else {
        quarter = 'Q4';
      }
      return `${quarter}/${periodStartTime.getFullYear()}`;
    default:
      return '';
  }
}

export function getDocumentTypeTranslateKey(documentType: number): string {
  const periodTypes = [3, 4, 5];
  if (periodTypes.includes(documentType)) {
    return `DOCUMENTS.SYNTHETIC_DOCUMENT_TYPE_${documentType}`;
  } else {
    return `DOCUMENTS.DOCUMENT_TYPE_${documentType}`;
  }
}

export function convertPeriodToBackendFormat(periodType: Period, value: string): PeriodParams {
  if (periodType === Period.Month) {
    const startTime = new Date(`${value}/02`);
    startTime.setUTCHours(0, 0, 0, 0);
    return {
      periodLength: 'P1M',
      periodStartTime: startTime
    };
  } else if (periodType === Period.Quarter) {
    const quarter = value.split('/')[0];
    const year = value.split('/')[1];
    let startTime = null;
    if (quarter === 'Q1') {
      startTime = new Date(`${year}/01/02`);
    } else if (quarter === 'Q2') {
      startTime = new Date(`${year}/04/02`);
    } else if (quarter === 'Q3') {
      startTime = new Date(`${year}/07/02`);
    } else {
      startTime = new Date(`${year}/10/02`);
    }
    startTime.setUTCHours(0, 0, 0, 0);
    return {
      periodLength: 'P3M',
      periodStartTime: startTime
    };
  } else if (periodType === Period.Year) {
    const startTime = new Date(`${value}/01/02`);
    startTime.setUTCHours(0, 0, 0, 0);
    return {
      periodLength: 'P1Y',
      periodStartTime: startTime
    };
  } else {
    return null;
  }
}

interface DocumentTypeItem {
  id: number,
  translateKey: string,
  attachmentType: AttachmentType
}

export function getDocumentTypeSelection(): DocumentTypeItem[] {
  const items: DocumentTypeItem[] = [
    {
      id: DocumentType.Other,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_0',
      attachmentType: null
    },
    {
      id: DocumentType.Contract,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_6',
      attachmentType: AttachmentType.Organization
    },
    {
      id: DocumentType.Map,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_1',
      attachmentType: AttachmentType.Meter
    },
    {
      id: DocumentType.Map,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_1',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.FacilityReview,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_7',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.FacilityAnalysis,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_8',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.MeteringDiagram,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_9',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.DistributionKeys,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_10',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.EnergyBill,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_12',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.EnergyCertificate,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_13',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.PowerOfAttorney,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_14',
      attachmentType: AttachmentType.ReportingObject
    },
    {
      id: DocumentType.Quide,
      translateKey: 'DOCUMENTS.DOCUMENT_TYPE_15',
      attachmentType: null
    },
    {
      id: 3,
      translateKey: 'DOCUMENTS.SYNTHETIC_DOCUMENT_TYPE_3',
      attachmentType: null
    },
    {
      id: 4,
      translateKey: 'DOCUMENTS.SYNTHETIC_DOCUMENT_TYPE_4',
      attachmentType: null
    },
    {
      id: 5,
      translateKey: 'DOCUMENTS.SYNTHETIC_DOCUMENT_TYPE_5',
      attachmentType: null
    }
  ];
  return items;
}
