import { Pipe, PipeTransform } from '@angular/core';

import { ActionSlimViewModel } from '@enerkey/clients/attachments';

@Pipe({
  name: 'actionLevel'
})
export class ActionLevelPipe implements PipeTransform {
  public transform(action: ActionSlimViewModel): string {
    if (Array.hasItems(action.meterIds)) {
      return 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_METER';
    } else if (Array.hasItems(action.quantityIds)) {
      return 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_QUANTITY';
    } else {
      return 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_FACILITY';
    }
  }
}
