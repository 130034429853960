<gridster [options]="options">
  <gridster-item
    *ngFor="let item of (widgets$ | async)"
    [item]="item"
  >
    <widget-container
      [widgetType]="item.widgetType"
      [title]="item.title"
      [dataModelOptions]="item.dataModelOptions"
      (widgetSettingsChange)="widgetSettingChange(item, $event)"
      (widgetDelete)="deleteWidget(item)"
    ></widget-container>
  </gridster-item>
</gridster>
