<modal-view-header [headingText]="'DATA_API_KEYS.CREATE_TITLE' | translate"></modal-view-header>
<div class="modal-body">
  <div class="small-6 columns">
    <form
      [formGroup]="createForm"
      (ngSubmit)="createApiKey()"
      id="createForm"
    >
      <h2>
        {{ 'DATA_API_KEYS.KEY' | translate }} {{ activeKeyType | apiKeyIndex }}
      </h2>
      <h3>{{ 'DATA_API_KEYS.KEY_VALIDITY_SELECTION' | translate }}</h3>
      <input
        type="radio"
        formControlName="keyValidityOption"
        kendoRadioButton
        [value]="KeyValidityOptions.NotRestricted"
        *labelWrap="'DATA_API_KEYS.CREATE_RADIO_VALID_NOT_SET' | translate"
      >
      <input
        type="radio"
        formControlName="keyValidityOption"
        kendoRadioButton
        [value]="KeyValidityOptions.ValidTo"
        *labelWrap="'DATA_API_KEYS.CREATE_RADIO_VALID_TO' | translate"
      >
      <br>
      <ng-container *ngIf="createForm.controls.keyValidityOption.value === KeyValidityOptions.ValidTo">
        <kendo-datepicker
          [min]="currentDate"
          formControlName="validTo"
        ></kendo-datepicker>
      </ng-container>
      <input
        type="text"
        kendoTextBox
        formControlName="email"
        *labelBefore="'ADMIN.EMAIL' | translate"
      >
      <input
        type="text"
        kendoTextBox
        formControlName="description"
        *labelBefore="'DATA_API_KEYS.KEY_DESCRIPTION' | translate"
      >
    </form>
  </div>
  <div class="small-6 columns">
    <ng-container *ngIf="generatedApiKey">
      <h2>
        {{ 'DATA_API_KEYS.KEY' | translate }} {{ activeKeyType | apiKeyIndex }}
      </h2>
      <p>
        <strong>{{ generatedApiKey }}</strong>
      </p>
      <button
        class="button"
        (click)="copyToClipboard()"
      >
        <i class="fa fa-copy"></i>
        {{ 'DATA_API_KEYS.COPY_KEY' | translate }}
      </button>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--secondary"
      type="button"
      (click)="close()"
    >
      {{ (generatedApiKey ? 'MODALS.CLOSE' : 'CANCEL') | translate }}
    </button>
    <button
      *ngIf="!generatedApiKey"
      class="button"
      type="submit"
      form="createForm"
      [disabled]="!createForm.valid"
      [loading]="loading$ | async"
    >
      {{ (this.renewMode ? 'DATA_API_KEYS.RENEW_KEY' : 'DATA_API_KEYS.GENERATE_KEY') | translate }}
    </button>
  </div>
</div>
