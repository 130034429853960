<kendo-dropdownbutton
  [data]="(histories$ | async)"
  themeColor="primary"
  iconClass="fa fa-history"
  buttonClass="btn-rounded"
  [disabled]="(histories$ | async).length < 2"
  [popupSettings]="{ popupClass: 'time-period-history-popup' }"
>
  <ng-template
    kendoDropDownButtonItemTemplate
    let-dataItem
  >
    <div (click)="setTimePeriodValue(dataItem)" class="time-period-history-item">
      <div>
        {{'FACILITIES_TOOLBAR.RESOLUTION' | translate}}: {{ dataItem.resolution | selectableResolutionTitle}}
      </div>
      <div> {{dataItem.periodName}} </div>
    </div>
  </ng-template>
</kendo-dropdownbutton>
