<div class="content-header">
  <admin-tabs></admin-tabs>
  <div
    *ngIf="topRightContent"
    class="content-right"
  >
    <ng-container *ngTemplateOutlet="topRightContent"></ng-container>
  </div>
</div>
<div class="row">
  <div ui-view="admin-content"></div>
</div>