<div class="facility-header-container rm-validation-styles">
  <facility-select
    [showDefaultText]="true"
    class="searchInput"
    [formControl]="facilityIdControl"
  ></facility-select>
  <ng-container *ngIf="showOpenReport">
    <button
      (click)="openFacilityReport()"
      class="button button--link button--external-link left-padding"
    >
      {{'FACILITIES.SHOW_FACILITY' | translate}}
    </button>
  </ng-container>
</div>
<ng-container  *ngIf="!showOpenReport">
  <h3>{{'DASHBOARD.NO_DATA' | translate}}</h3>
</ng-container>
