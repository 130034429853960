import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { ProfileViewModel, ServiceType, UserManagementClient } from '@enerkey/clients/user-management';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

export interface IProfileManagementSearchCriteria {
  profileName: string,
  profileId: number,
  facilityId: number,
  enegiaId: number,
  serviceType: ServiceType,
}

@Injectable()
export class ProfileManagementService implements OnDestroy {

  public readonly searchResult$: Observable<ProfileViewModel[]>;
  public readonly loading$: Observable<boolean>;

  private readonly _searchParams$ = new Subject<IProfileManagementSearchCriteria>();
  private readonly _loading$ = new LoadingSubject();
  private readonly _repeat$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly userManagementClient: UserManagementClient
  ) {
    this.loading$ = this._loading$.asObservable();

    this.searchResult$ = combineLatest([
      this._searchParams$,
      this._repeat$.pipe(startWith<void>(null))
    ]).pipe(
      switchMap(([params, _]) => this.searchProfiles(params)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._repeat$.complete();
    this._searchParams$.complete();
  }

  public repeat(): void {
    this._repeat$.next();
  }

  public search(params: IProfileManagementSearchCriteria): void {
    this._searchParams$.next(params);
  }

  private searchProfiles(params: IProfileManagementSearchCriteria): Observable<ProfileViewModel[]> {
    return this.userManagementClient.getAllProfiles(
      params.profileName ?? undefined,
      params.profileId ?? undefined,
      params.facilityId ?? undefined,
      params.enegiaId ?? undefined,
      params.serviceType ?? undefined
    ).pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$)
    );
  }
}
