<modal-view-header [headingText]="'CONFIGURATION_CONTROL.TERMINAL.FAULT_DESCRIPTION_INFO' | translate"></modal-view-header>
<div class="modal-body">
  <div class="row">
    <div class="small-6 columns">
      <label translate="CONFIGURATION_CONTROL.TERMINAL.FAULT_DESCRIPTION"></label>
      <input type="text" [(ngModel)]="inspectionDescription"/>
    </div>
    <div class="small-6 columns">
      <label translate="CONFIGURATION_CONTROL.TERMINAL.INSPECTOR"></label>
      <kendo-dropdownlist
        [data]="inspectorFilter"
        [valueField]="'id'"
        [textField]="'name'"
        [filterable]="true"
        [(ngModel)]="inspectorId"
        [kendoDropDownFilter]="filterSettings"
        [valuePrimitive]="true"
      >
      </kendo-dropdownlist>
    </div>
  </div>

  <kendo-grid
    [kendoGridBinding]="terminalGridItems"
    [pageSize]="30"
    [pageable]="true"
    [resizable]="true"
    [height]="300"
  >
    <kendo-grid-column
      field="terminal.identifier"
      title="{{ 'ADMIN.TERMINAL.IDENTIFIER' | translate }}"
      [width]="20"
    >
    </kendo-grid-column>
    <kendo-grid-column
      field="terminal.name"
      title="{{ 'ADMIN.TERMINAL.NAME' | translate }}"
      [width]="60"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="terminalStatus.statusDescription"
      title="{{ 'CONFIGURATION_CONTROL.TERMINAL.FAULT_DESCRIPTION' | translate }}"
      [width]="60"
    >
    </kendo-grid-column>

  </kendo-grid>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="modal-footer-buttons float-right">
      <button type="button" class="button" (click)="onSave()">{{ 'MODALS.SAVE' | translate }}</button>
    </div>
  </div>
</div>




