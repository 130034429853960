import { Ng2StateDeclaration, ParamType, Transition } from '@uirouter/angular';
import { Ng1StateDeclaration } from '@uirouter/angularjs';

import { Service } from '../constants/service';
import { Roles } from '../modules/admin/constants/roles';

type RedirectTarget<T extends string> = T | EnerkeyNg2StateDeclaration<T>;

export interface EnerkeyNg1StateDeclaration extends Ng1StateDeclaration {
  data?: {
    auth?: RouteAuth;
    export?: {
      enabled: boolean;
      askPrintOrExcel?: boolean;
      fileNameKey?: string;
      exportType?: 'server' | 'client';
    };
    bookmark?: {
      enabled?: boolean;
      refresh?: boolean;
    };
    modal?: {
      title?: string;
      titleKey?: string;
      template?: string;
      nestedContainers?: boolean;
    };
    sidebar?: unknown;
    translationKey?: string;
  };
}

// Use string union type for typed state names
export interface EnerkeyNg2StateDeclaration<T extends string = string> extends Ng2StateDeclaration {
  name: T;
  parent?: 'index' | T;
  redirectTo?: RedirectTarget<T> | ((t: Transition) => RedirectTarget<T>);
  data?: {
    auth?: RouteAuth;
    export?: {
      enabled: boolean;
    };
    bookmark?: {
      enabled: boolean;
      refresh?: boolean;
    };
    translationKey?: string;
  };
}

type RoleServiceArr = { roles: Roles[]; services: Service[]; };

export type RouteAuth =
  { /** Requires tenant to be 1 */ defaultTenantOnly?: boolean } &
  { roleLogic?: RouteAuthLogic; serviceLogic?: RouteAuthLogic; } &
  ((Partial<RoleServiceArr> & { logic?: RouteAuthLogic.All }) | (RoleServiceArr & { logic: RouteAuthLogic.Any }));

// ^ Type guarded to disallow types without both roles and services if "Any" is used
/*
{
  roles?: Roles[];
  roleLogic?: RouteAuthLogic;
  services?: Service[];
  serviceLogic?: RouteAuthLogic;
  logic?: RouteAuthLogic.All;
}
*/

type TopbarStateKeys = keyof Pick<(EnerkeyNg1StateDeclaration | EnerkeyNg2StateDeclaration), 'data' | 'name'>

type AllowOnlyKeys<T, E extends keyof T> = { [K in keyof T]: K extends E ? T[K] : never };

// eslint-disable-next-line max-len
export type UIStateNg2<StateName extends string = string> = AllowOnlyKeys<EnerkeyNg2StateDeclaration<StateName>, TopbarStateKeys>;
export type UIStateNg1 = AllowOnlyKeys<EnerkeyNg1StateDeclaration, TopbarStateKeys>;

export enum RouteAuthLogic {
  All,
  Any,
}

export function getUIState(state: EnerkeyNg1StateDeclaration): UIStateNg1 {
  const { views, ...uiState } = state;
  return uiState as UIStateNg1;
}

const intArraySplitter = ',';

export const intArrayParamType = new ParamType({
  encode: (array: number[]) => array?.sortBy(x => x).join(intArraySplitter) ?? '',
  decode: (value: string) => {
    if (!value) {
      return [];
    }
    return value.split(intArraySplitter).map(n => Number.parseInt(n, 10)).sortBy(x => x);
  },
  equals: (a: number[], b: number[]) => {
    if (!Array.hasItems(a) && !Array.hasItems(b)) {
      return true;
    }
    if (a?.length !== b?.length) {
      return false;
    }
    const aSorted = a.sortBy(x => x);
    const bSorted = b.sortBy(x => x);
    return aSorted.every((v, i) => v === bSorted[i]);
  },
  is: (value: unknown) => {
    if (value === undefined) {
      return true;
    }
    return Array.isArray(value) && value.every(v => Number.isInteger(v));
  }
});
