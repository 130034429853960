import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { Transition } from '@uirouter/core';
import jszip from 'jszip';
import { concat, EMPTY, forkJoin, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, delay, map, shareReplay, switchMap, take, tap, toArray } from 'rxjs/operators';
import { startOfYear, subYears } from 'date-fns';

import { Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { RequestResolution } from '@enerkey/clients/reporting';
import { percentageChange } from '@enerkey/ts-utils';

import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { ColorService } from '../../../../shared/services/color.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { FileDownloadService } from '../../../../shared/services/file-download.service';
import { TenantService } from '../../../../shared/services/tenant.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

import { PeriodReportService } from '../../../../modules/reporting/services/period-report.service';
import { getDefaultReportingParams } from '../../../../modules/reporting/services/reporting.search.functions';
import { ReportingSearchParams } from '../../../../modules/reporting/shared/reporting-search-params';
import { ReportingSeries } from '../../../../modules/reporting/shared/reporting-series';
import { ReportingSeriesByFacility } from '../../../../modules/reporting/shared/reporting-series-collection';
import { RelationalValueId } from '../../../../modules/reportingobjects/constants/facilities-properties';
import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';

export interface YearReadings {
  reading: number;
  normalizedReading: number;
  diffPercentage: number;
  specificConsumption: number;
  specificConsumptionPerPerson?: number;
  timestamp: Date;
}

export interface FacilityYearTrendForQuantities {
  [key: number]: YearReadings[];
}

type FacilityData = [ExtendedFacilityInformation, FacilityYearTrendForQuantities];

export const DEFAULT_KEY = 'Key';

export const reportQuantityIds = [Quantities.Electricity, Quantities.HeatingEnergy, Quantities.Water] as const;

@Component({
  selector: 'yearly-trend-report',
  templateUrl: './yearly-trend-report.component.html',
  styleUrls: ['./yearly-trend-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearlyTrendReportComponent implements OnDestroy {
  @ViewChild(PDFExportComponent) public pdf: PDFExportComponent;
  @ViewChild('nextButton') public nextButton: ElementRef;
  @ViewChild('previousButton') public previousButton: ElementRef;

  public readonly facilityIds: number[];

  public readonly usedQuantityId = reportQuantityIds;

  public readonly blackColor: string;
  public readonly firstYear: number;
  public readonly lastYear: number;
  public readonly downloadedDate: Date;
  public readonly isEnerkeyBrand: boolean;

  public readonly currentFacility$: Observable<FacilityData>;
  public readonly loading$: Observable<boolean>;

  private readonly _currentFacilityId$ = new ReplaySubject<number>(1);
  private readonly _currentFacilityByDownloadAll$ = new Subject<FacilityData>();
  private readonly _loading$ = new LoadingSubject();

  private readonly _facilities$: Observable<ExtendedFacilityInformation[]>;

  private readonly yearsToShow = 5;

  public constructor(
    private readonly periodReportService: PeriodReportService,
    private readonly facilityService: FacilityService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    public readonly tenantService: TenantService,
    colorService: ColorService,
    transition: Transition
  ) {
    this.blackColor = colorService.getCssProperty('--enerkey-black');
    this.facilityIds = transition.params().facilityId ?? transition.params().facilityIds;
    this.downloadedDate = new Date();
    this.lastYear = new Date().getFullYear() - this.yearsToShow;
    this.firstYear = this.lastYear + this.yearsToShow - 1;
    this._facilities$ = this.facilityService.getProfileFacilities().pipe(
      map(facilities => facilities.filter(facility => this.facilityIds?.includes(facility.facilityId)))
    ).pipe(
      take(1),
      shareReplay(1)
    );

    this.currentFacility$ = merge(
      this._currentFacilityId$.pipe(switchMap(id => this.getFacilityById(id))),
      this._currentFacilityByDownloadAll$
    );

    if (this.facilityIds?.length) {
      this._currentFacilityId$.next(this.facilityIds[0]);
    }
    this.isEnerkeyBrand = this.tenantService.isEnerkeyBrand;
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
    this._currentFacilityId$.complete();
    this._currentFacilityByDownloadAll$.complete();
  }

  public downloadAll(): void {
    const observableArray: Observable<File>[] = [];

    for (const facilityId of this.facilityIds) {
      observableArray.push(
        this.getFacilityById(facilityId).pipe(
          tap(facility => this._currentFacilityByDownloadAll$.next(facility)),
          delay(500),
          switchMap(() => this.fileDownloadService.exportKendoPdfToFile(this.pdf, `${facilityId}`))
        )
      );
    }
    concat(...observableArray)
      .pipe(
        toArray(),
        map(files => {
          const zip = new jszip();
          for (const file of files) {
            zip.file(file.name, file);
          }
          return zip;
        }),
        switchMap(zipFile => zipFile.generateAsync({ type: 'blob' }))
      )
      .subscribe(zipFile => {
        const fileName = this.translateService.instant('YEARLY_TREND.ZIP_FILE_NAME');
        this.fileDownloadService.downloadBlob(zipFile, `${fileName}.zip`);
      });
  }

  public pageSelect(page: number): void {
    this._currentFacilityId$.next(this.facilityIds[page]);
  }

  private getFacilityConsumptions(facilityId: number): Observable<FacilityYearTrendForQuantities> {
    return this.periodReportService.getData(
      new ReportingSearchParams({
        ...getDefaultReportingParams(),
        quantityIds: [...this.usedQuantityId],
        periods: [startOfYear(subYears(new Date(this.lastYear, 0, 1), 1))],
        durationName: 'years',
        durationLength: this.yearsToShow + 1,
        specificIds: [RelationalValueId.TotalVolume, RelationalValueId.Inhabitants],
        resolution: RequestResolution.P1Y,
        valueType: ValueType.Both
      }),
      [facilityId],
      0
    ).pipe(
      map(data => this.processFacilityConsumptions(data, facilityId)),
      indicate(this._loading$)
    );
  }

  private processFacilityConsumptions(
    data: ReportingSeriesByFacility, facilityId: number
  ): FacilityYearTrendForQuantities {
    const facilityQuantityMappedData = data[facilityId].toRecord(c => c.quantityId);
    return this.usedQuantityId.reduce<FacilityYearTrendForQuantities>((facilityData, quantityId) => {
      const facilityQuantityData = facilityQuantityMappedData[quantityId];
      if (!facilityQuantityData) {
        return facilityData;
      }
      const readings: YearReadings[] = [];

      const measuredSeries = this.getMeasuredSeries(facilityQuantityData.series);
      const normalizedSeries = this.getNormalizedSeries(facilityQuantityData.series);
      const volumeSeries = this.getDerivedValueSeries(facilityQuantityData.series, RelationalValueId.TotalVolume);
      const inhabitantsSeries = this.getDerivedValueSeries(facilityQuantityData.series, RelationalValueId.Inhabitants);

      for (let i = 0; i < measuredSeries.values.length; i++) {
        const measuredValue = measuredSeries.values[i];
        const reading: YearReadings = {
          timestamp: measuredValue.timestamp,
          reading: measuredValue.value,
          normalizedReading: normalizedSeries?.values[i].value,
          diffPercentage: percentageChange(measuredValue.value, measuredSeries.values[i - 1]?.value),
          specificConsumption: volumeSeries?.values[i].value,
          specificConsumptionPerPerson: inhabitantsSeries?.values[i].value,
        };
        readings.push(reading);
      }
      readings.shift();
      facilityData[quantityId] = readings;
      return facilityData;
    }, {});
  }

  private getFacilityById(
    facilityId: number
  ): Observable<FacilityData> {
    return forkJoin([
      this._facilities$.pipe(map(facilities => facilities.find(f => f.facilityId === facilityId))),
      this.getFacilityConsumptions(facilityId)
    ]).pipe(
      catchError(() => {
        this.toasterService.error(null, 'YEARLY_TREND.FAILED_TO_LOAD_FACILITY');
        return EMPTY;
      })
    );
  }

  private getMeasuredSeries(
    series: ReportingSeries[]
  ): ReportingSeries {
    return series.find(
      s => s.options.serieType === 'consumption' && s.options.isNormalized === false
    );
  }

  private getNormalizedSeries(
    series: ReportingSeries[]
  ): ReportingSeries {
    return series.find(
      s => s.options.serieType === 'consumption' && s.options.isNormalized === true
    );
  }

  private getDerivedValueSeries(series: ReportingSeries[], relationalValueId: RelationalValueId): ReportingSeries {
    return series.find(
      s => s.options.serieType === `derived${relationalValueId}` && !s.options.isNormalized
    );
  }
}
