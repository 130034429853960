<kendo-grid
  [kendoGridBinding]="meterHistory$ | async"
  [pageSize]="pageSize"
  [loading]="(loadingHistory$ | async) || (updating$ | async)"
  [sortable]="false"
  [pageable]="true"
  [resizable]="true"
  [navigable]="true"
  (edit)="editHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  (add)="addHandler($event)"
>
  <ng-template kendoGridToolbarTemplate>
    <button [primary]="true" kendoGridAddCommand>{{ 'ADD' | translate }}</button>
  </ng-template>
  <kendo-grid-column
    field="fromDate"
    title="{{ 'ADMIN.METER_HISTORY.GRID.START' | translate }}"
    [width]="120"
  >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.fromDate | todate | date:'short' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="date"
    title="{{ 'ADMIN.METER_HISTORY.GRID.END' | translate }}"
    editor="date"
    [width]="200"
  >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.date | todate | date:'short' }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <div style="display: flex; flex-direction: column">
        <kendo-datetimepicker
          [format]="datePickerFormat"
          [formControl]="$any(formGroup.controls.date)"
        ></kendo-datetimepicker>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="name"
    title="{{ 'METER_NAME' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="customerMeterIdentifier"
    title="{{ 'CUSTOMER_METER_IDENTIFIER' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="description"
    title="{{ 'COMMENT' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="factor"
    format="n"
    editor="numeric"
    title="{{ 'FACTOR' | translate }}"
    [width]="80"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="meteringType"
    title="{{ 'METER_TYPE' | translate }}"
  >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      {{ dataItem.meteringType | meteringType }}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <div style="display: flex; flex-direction: column">
        <kendo-combobox
          [data]="meteringTypes"
          [formControl]="$any(formGroup.controls.meteringType)"
        >
          <ng-template kendoComboBoxItemTemplate let-dataItem>
            {{ dataItem | meteringType }}
          </ng-template>
        </kendo-combobox>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column [width]="80">
    <ng-template kendoGridCellTemplate>
      <button kendoGridEditCommand></button>
      <button kendoGridRemoveCommand></button>
      <button kendoGridSaveCommand [disabled]="formGroup?.invalid"></button>
      <button kendoGridCancelCommand></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
