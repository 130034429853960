import ManualQaResultController from './manual-qa-result-controller';
import template from 'raw-loader!./manual-qa-result.html';

export default {
  template: template,
  controller: ManualQaResultController,
  controllerAs: 'vm',
  bindings: {
    items: '<',
    searchList: '<',
    searchItemType: '<',
    onInspect: '&',
    onSearchListChange: '&',
    onEditSearchList: '&',
    onViewVisuals: '&'
  }
};
