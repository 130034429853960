<span>
  <span
    *ngIf="showIcon"
    [className]="'meter-type-icon meter-type-' + meterType"
    [style.font-size.px]="iconSize"
    [ngfTooltip]="meterType | meteringType"
    [openDelay]="500"
  ></span>
  <span *ngIf="showIcon && showText">&nbsp;</span>
  <span *ngIf="showText">{{ meterType | meteringType }}</span>
</span>
