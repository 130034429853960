import { IEmailConsumptionReportClient } from './email-consumption-report-client';

export class EmailConsumptionReportClientMock implements IEmailConsumptionReportClient {
  public addOrUpdateEmailSubscription(): never {
    throw new Error('Method not implemented.');
  }
  public getEmailSubscription(): never {
    throw new Error('Method not implemented.');
  }
  public removeEmailSubscription(): never {
    throw new Error('Method not implemented.');
  }
}
