import { IAngularStatic } from 'angular';

import { isHourly, isManualConsumption, isManualReading } from '../../../mrc/shared/metering-type.functions';
import { MeterHistoryItem } from '../../shared/meter-history-item';
import ManualQaMeterHistoryModalController from './manual-qa-meter-history-modal-controller';
import template from 'raw-loader!./manual-qa-meter-history-modal.html';
import { ManualQaApiMeterService } from '../../services/manual-qa-api-meter-service';
import { ManualQaPopupService } from '../../services/manual-qa-popup.service';
import { AjsModalService } from '../../../../services/modal/modal.service';

declare const angular: IAngularStatic;

const $inject = [
  '$modal', 'kendo', 'utils', 'ManualQaApiMeterService',
  '$q', 'modalService', 'ManualQaPopupService'
];

class ManualQaMeterHistoryService {
  private factorValidation: { value: string; error: string }[] = [
    { value: '0', error: 'MQA.METER_HISTORY.GRID.FACTOR_CANNOT_BE_ZERO' },
    { value: '', error: 'MQA.METER_HISTORY.GRID.FACTOR_REQUIRED' }
  ];

  public constructor(
    private $modal: any,
    private kendo: any,
    private utils: any,
    private manualQaApiMeterService: ManualQaApiMeterService,
    private $q: any,
    private modalService: AjsModalService,
    private manualQaPopupService: ManualQaPopupService
  ) {
  }

  /**
   * Returns meter history modal
   *
   * @param {Object} meter
   *
   * @returns {Object}
   */
  public getModal(meter: any): any {
    return this.$modal.open({
      template: template,
      controller: ManualQaMeterHistoryModalController,
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        meter: () => meter
      }
    });
  }

  public hasRowFactorChanged(originalValue: MeterHistoryItem, newValue: MeterHistoryItem): boolean {
    return originalValue.factor !== newValue.factor;
  }

  public hasRowInfoChanged(originalValue: MeterHistoryItem, newValue: MeterHistoryItem): boolean {
    const infoFields: (keyof MeterHistoryItem)[] = ['name', 'customerMeterIdentifier', 'description'];

    return infoFields.some(field => originalValue[field] !== newValue[field]);
  }

  /**
   * Return new dataSource object
   *
   * @param {Object} scope current scope, needed for hasRowInfoChanged etc. variables
   *
   * @returns {Object} dataSource object
   */
  public getDataSource(scope: any): kendo.data.DataSource {
    const self = this;
    return new this.kendo.data.DataSource({
      transport: {
        read: angular.noop,
        update: (e: kendo.data.DataSourceTransportOptions) => {
          self.updateMeterChange(e, scope);
        },
        destroy: self.deleteMeterChange.bind(this)
      },
      schema: {
        model: {
          idField: 'id',
          fields: {
            fromDate: {
              editable: false
            },
            date: {
              editable: false
            },
            name: {
              editable: true,
              validation: {
                required: {
                  message: self.utils.localizedString('MQA.METER_HISTORY.GRID.NAME_REQUIRED')
                }
              }
            },
            meteringType: {
              editable: false
            },
            customerMeterIdentifier: {
              editable: true
            },
            description: {
              editable: true
            },
            factor: {
              editable: true,
              validation: {
                factorvalidation: self.validateFactor.bind(this)
              }
            }
          }
        }
      }
    });
  }

  public getGridOptions(): kendo.ui.GridOptions {
    return {
      sortable: false,
      pageable: false,
      reorderable: false,
      filterable: false,
      groupable: false,
      resizable: true,
      selectable: 'multiple cell',
      allowCopy: true,
      editable: {
        mode: 'inline',
        confirmation: false
      },
      columns: this.getColumns()
    };
  }

  private validateFactor(input: JQuery): boolean {
    if (input.is('[name=\'factor\']')) {
      for (const validationCriteria of this.factorValidation) {
        if (input.val() === validationCriteria.value) {
          input.attr(
            'data-factorvalidation-msg',
            this.utils.localizedString(validationCriteria.error)
          );
          return false;
        }
      }
    }
    return true;
  }

  private getTimestampFormatted(item: MeterHistoryItem): string {
    if (!item.date) {
      return this.utils.localizedString('MQA.METER_HISTORY.GRID.IN_EFFECT');
    }

    return kendo.format('{0:g}', item.date);
  }

  private getMeteringTypeFormatted(item: MeterHistoryItem): string {
    if (isHourly(item.meteringType)) {
      return this.utils.localizedString('MQA.METER_HISTORY.GRID.METERING_TYPE.AUTOMATIC');
    }

    if (isManualReading(item.meteringType) || isManualConsumption(item.meteringType)) {
      return this.utils.localizedString('MQA.METER_HISTORY.GRID.METERING_TYPE.MANUAL');
    }

    return '';
  }

  private getColumns(): kendo.ui.GridColumn[] {
    const self = this;

    return [
      {
        field: 'fromDate',
        title: this.utils.localizedString('MQA.METER_HISTORY.GRID.HEADING.STARTING_DATE'),
        width: 110,
        format: '{0:g}'
      },
      {
        field: 'date',
        title: this.utils.localizedString('MQA.METER_HISTORY.GRID.HEADING.ENDING_DATE'),
        width: 110,
        template: this.getTimestampFormatted.bind(this)
      },
      {
        field: 'name',
        title: this.utils.localizedString('MQA.METER_HISTORY.GRID.HEADING.NAME'),
        width: 200
      },
      {
        field: 'meteringType',
        title: this.utils.localizedString('MQA.METER_HISTORY.GRID.HEADING.METERING_TYPE'),
        width: 50,
        template: this.getMeteringTypeFormatted.bind(this)
      },
      {
        field: 'customerMeterIdentifier',
        title: this.utils.localizedString('MQA.METER_HISTORY.GRID.HEADING.IDENTIFIER'),
        width: 180
      },
      {
        field: 'description',
        title: this.utils.localizedString('MQA.METER_HISTORY.GRID.HEADING.COMMENT'),
        width: 350
      },
      {
        field: 'factor',
        title: this.utils.localizedString('MQA.METER_HISTORY.GRID.HEADING.FACTOR'),
        width: 100,
        format: '{0:0.########}',
        editor: (container: JQuery, options: kendo.ui.GridColumnEditorOptions) => {
          angular.element(`<input name="${options.field}"/>`)
            .appendTo(container)
            .kendoNumericTextBox({
              decimals: 8,
              restrictDecimals: true
            });
          angular.element(`<span class="k-invalid-msg" data-for="${options.field}"></span>`).appendTo(container);
        },
        editable: (item: MeterHistoryItem) => isHourly(item.meteringType)
      },
      {
        command: [
          {
            name: 'edit',
            text: {
              edit: '',
              cancel: '',
              update: ''
            }
          },
          {
            name: 'Delete',
            text: '',
            iconClass: 'k-icon k-i-close',
            // Meter type must be harcoded here, using constant doesn't work because of kendo
            visible: function(item: MeterHistoryItem) { // Kendo doesn't understand arrow function here
              return !(!item.date || item.meteringType !== 1);
            },
            click: (e: JQueryEventObject) => {
              e.preventDefault();

              const grid = angular.element(e.delegateTarget).data('kendoGrid');
              const tr = angular.element(e.target).closest('tr');
              const data = grid.dataItem(tr);
              const dataSource = grid.dataSource;

              self.modalService.getConfirmationModal(
                {
                  text: self.utils.localizedString('MQA.METER_HISTORY.CONFIRM_DELETE.CONFIRMATION_TEXT'),
                  title: self.utils.localizedString('MQA.METER_HISTORY.CONFIRM_DELETE.TITLE'),
                  isDelete: true
                }
              ).then(() => {
                dataSource.remove(data);
                dataSource.sync();
              });
            }
          }
        ],
        title: '&nbsp;',
        width: 80,
        selectable: false
      }
    ];
  }

  private updateMeterChange(e: kendo.data.DataSourceTransportOptions, scope: JQuery): void {
    const promises = this.getChangePromises(e, scope);
    if (promises.length) {
      this.$q.all(promises)
        .then((result: any) => {
          this.manualQaPopupService.successPopUp('MQA.SUCCESS.METER_CHANGE');
          e.success(result);
        })
        .catch(() => {
          this.manualQaPopupService.errorPopUp('MQA.ERRORS.METER_CHANGE');
          e.error('Failed to update');
        });
    }
  }

  private getChangePromises(e: kendo.data.DataSourceTransportOptions, scope: any): any[] {
    const promises = [];
    const meterHistoryItem = new MeterHistoryItem(e.data);

    if (scope.hasRowInfoChanged) {
      promises.push(this.manualQaApiMeterService.updateMeterChange(meterHistoryItem));
    }

    if (scope.hasRowFactorChanged) {
      promises.push(this.manualQaApiMeterService.updateMeterChangeFactor(meterHistoryItem));
    }

    return promises;
  }

  private deleteMeterChange(e: kendo.data.DataSourceTransportOptions): void {
    const meterHistoryItem = new MeterHistoryItem(e.data);

    this.manualQaApiMeterService.deleteMeterChange(meterHistoryItem)
      .then(() => {
        this.manualQaPopupService.successPopUp('MQA.SUCCESS.DELETE_METER_CHANGE');
        e.success();
      })
      .catch(() => {
        this.manualQaPopupService.errorPopUp('MQA.ERRORS.DELETE_METER_CHANGE');
        e.error('Failed to delete');
      });
  }

}

ManualQaMeterHistoryService.$inject = $inject;

export default ManualQaMeterHistoryService;
