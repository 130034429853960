export function filterYears() {
  return function(input, year) {
    console.dir(input);
    if (input && input.from && input.to && year) {
      var from = new Date(input.from);
      var to = new Date(input.to);
      if (from.getFullYear() == year || to.getFullYear() == year) {
        console.log("RETURNING:");
        console.dir(input);
        return input;
      }
    }
    return {};
  };
}
