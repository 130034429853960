import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  ColumnVisibilityChangeEvent,
  GridComponent, GridDataResult,
  SelectableSettings,
} from '@progress/kendo-angular-grid';
import {
  aggregateBy, AggregateDescriptor, AggregateResult, CompositeFilterDescriptor, filterBy, process, State
} from '@progress/kendo-data-query';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { anyOf, indicate, LoadingSubject, waitWhile } from '@enerkey/rxjs';
import { FacilityInformationGroup } from '@enerkey/clients/energy-reporting';
import { ModalService } from '@enerkey/foundation-angular';
import { Facility } from '@enerkey/clients/facility';

import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { TableReportDataService } from '../../services/table-report-data.service';
import {
  FacilityPropertiesService,
  FacilityPropertyWithField,
} from '../../../energy-reporting/services/facility-properties.service';
import {
  absoluteChangeKey,
  normalizedValuesPropertyKey,
  relativeChangeKey,
  valuesPropertyKey
} from '../../constants/table-report-constants';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import {
  FacilityQuantityValues,
  getTableReportColumns,
  getUnsupportedParamsInfo,
  mapQuantityDataByFacilities,
  QuantitySeriesByType,
  QuantityValuesBySerieType,
  sanitizeAggregateResult,
  SeriesByType
} from '../../shared/table-report-functions';
import { TableReportService } from '../../services/table-report.service';
import { FacilityEditModalComponent } from '../../../admin/components/facility-edit-modal/facility-edit-modal.component';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { ReportingSeries } from '../../shared/reporting-series';
import { FacilityTagEditModalComponent } from '../../../admin/components/facility-tag-edit-modal/facility-tag-edit-modal.component';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { UserService } from '../../../../services/user-service';
import { Roles } from '../../../admin/constants/roles';
import { FacilityColumnsProperties } from '../../../../shared/interfaces/facility-columns-properties';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';
import { ReportType } from '../../shared/report-type';

type RowSelectKey = 'FacilityId';

type FacilityRow = FacilityInformationGroup & Record<'values' | 'normalizedValues', QuantityValuesBySerieType>;

type FacilityPropertyColumn = FacilityPropertyWithField & { hidden: boolean };
type FacilityPropertyColumns = Omit<FacilityColumnsProperties, 'Items'> & { Items: FacilityPropertyColumn[] };

@Component({
  selector: 'table-report',
  templateUrl: './table-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService]
})
export class TableReportComponent implements AfterViewInit, OnDestroy {
  public readonly selectKey: RowSelectKey = 'FacilityId';
  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };

  public readonly absoluteChangeKey = absoluteChangeKey;
  public readonly relativeChangeKey = relativeChangeKey;
  public readonly visibleAggregates = ['sum', 'min', 'max', 'average'] as const;

  public selection: FacilityInformationGroup[RowSelectKey][] = [];

  public data: FacilityRow[] = [];

  @ViewChild(GridComponent, { static: true }) public readonly kendoGrid: GridComponent;

  public readonly facilityPropertyColumns$: Observable<FacilityPropertyColumns[]>;
  public readonly total$: Observable<AggregateResult>;
  public readonly gridView$: Observable<GridDataResult>;
  public readonly loading$: Observable<boolean>;
  public readonly searchParams$: Observable<ReportingSearchParams>;
  public readonly columns$: Observable<QuantitySeriesByType[]>;
  public readonly showOnlySelected$: Observable<boolean>;
  public readonly hasEditAccess: boolean = false;
  public readonly state$: Observable<State>;
  public readonly modalReportType: ReportType = ReportType.Table;
  public readonly currentDate = new Date();

  private readonly data$ = new ReplaySubject<FacilityRow[]>(1);
  private readonly facilities$: Observable<FacilityInformationGroup[]>;
  private readonly dataAndColumns$: Observable<{ data: FacilityQuantityValues, columns: QuantitySeriesByType[] }>;
  private readonly propertyAggregates$: Observable<AggregateDescriptor[]>;
  private readonly quantityAggregates$: Observable<AggregateDescriptor[]>;
  private readonly filteredData$: Observable<FacilityRow[]>;
  private readonly columnChange$: Observable<ColumnVisibilityChangeEvent>;
  private readonly aggregates$: Observable<AggregateDescriptor[]>;
  private readonly filter$: Observable<CompositeFilterDescriptor>;
  private readonly _showOnlySelected$ = new BehaviorSubject<boolean>(false);

  private readonly destroy$ = new Subject<void>();
  private readonly facilitiesLoading$ = new LoadingSubject();
  private readonly consumptionsLoading$ = new LoadingSubject();
  private readonly skipAggregates$ = new BehaviorSubject(false);

  public constructor(
    public readonly gridService: KendoGridService<FacilityInformationGroup, 'FacilityId'>,
    private readonly facilityService: FacilityService,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly tableReportDataService: TableReportDataService,
    private readonly facilityPropertiesService: FacilityPropertiesService,
    private readonly tableReportService: TableReportService,
    private readonly modalService: ModalService,
    private readonly thresholdService: ThresholdService,
    private readonly toasterService: ToasterService,
    private readonly userService: UserService
  ) {
    this.state$ = this.tableReportService.gridState$.pipe(takeUntil(this.destroy$));
    this.columnChange$ = this.tableReportService.gridColumnVisibility$.pipe(takeUntil(this.destroy$));
    this.filter$ = this.tableReportService.gridFilter$.pipe(takeUntil(this.destroy$));
    this.searchParams$ = this.reportingSearchService.searchParameters$.pipe(shareReplay(1));

    this.loading$ = anyOf(this.facilitiesLoading$, this.consumptionsLoading$);

    this.facilities$ = this.facilityService.filteredProfileFacilities$;

    this.showOnlySelected$ = this._showOnlySelected$.asObservable();

    this.facilityPropertyColumns$ = this.facilityPropertiesService.facilityProperties$.pipe(
      map(properties =>
        properties.map(property => ({
          ...property,
          Items: property.Items.map(item => ({
            ...item,
            Type: item.Property === 'BusinessIdentityCode' ? 'string' : item.Type, // required in ENE-3249
            hidden: !this.tableReportService.isColumnVisible(item.field)
          }))
        }))),
      take(1),
      indicate(this.facilitiesLoading$)
    );

    this.dataAndColumns$ = combineLatest([
      this.searchParams$,
      this.thresholdService.threshold$
    ]).pipe(
      tap(() => {
        this.skipAggregates$.next(true);
        this.resetColumnOrder();
      }),
      switchMap(([params, threshold]) => this.getConsumptions(params, threshold)),
      takeUntil(this.destroy$),
      startWith({ data: { values: null, normalizedValues: null }, columns: [] }),
      shareReplay(1)
    );

    this.columns$ = this.dataAndColumns$.pipe(
      map(c => c.columns.sortBy(x => x.quantityId))
    );

    this.propertyAggregates$ = this.columnChange$.pipe(
      switchMap(event => event ? this.facilityPropertiesService.getPropertyAggregates(event.columns) : []),
      startWith([])
    );

    this.quantityAggregates$ = this.columns$.pipe(
      map(columns => this.tableReportService.getQuantityAggregates(columns)),
      startWith([])
    );

    this.aggregates$ = combineLatest([this.propertyAggregates$, this.quantityAggregates$]).pipe(
      map(([properties, quantities]) => properties.concat(quantities)),
      shareReplay(1)
    );

    this.gridView$ = combineLatest([this.state$, this.aggregates$, this.data$]).pipe(
      tap(([state, aggregates]) => {
        state.group?.forEach(group => {
          group.aggregates = aggregates;
        });
      }),
      map(([state, _, data]) => process(data, state)),
      startWith({ data: [], total: 0 })
    );

    this.filteredData$ = combineLatest([this.data$, this.filter$]).pipe(
      map(([data, filter]) => filterBy(data, filter))
    );

    this.total$ = combineLatest([this.aggregates$, this.filteredData$]).pipe(
      waitWhile(this.skipAggregates$),
      map(([aggregates, data]) => aggregateBy(data, aggregates)),
      map(aggregatedData => sanitizeAggregateResult(aggregatedData)),
      map(this.hideAllAverageCostsTotals),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    combineLatest([
      combineLatest([
        this.facilities$,
        this._showOnlySelected$
      ]).pipe(
        map(([facilities, onlySelected]) => onlySelected
          ? facilities.filter(f => this.selection.includes(f.FacilityId))
          : facilities)
      ),
      this.dataAndColumns$.pipe(map(c => c.data))
    ])
      .pipe(
        map(([facilities, data]) => facilities.map(f => ({
          ...f,
          [valuesPropertyKey]: data.values?.[f.FacilityId],
          [normalizedValuesPropertyKey]: data.normalizedValues?.[f.FacilityId]
        }))),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.gridService.dataChanged(data);
        this.data = data;
        this.data$.next(data);
        this.skipAggregates$.next(false);
      });

    this.hasEditAccess = this.userService.hasRole(Roles.FACILITY_UPDATE) ||
      this.userService.hasRole(Roles.USER_ADMINSTRATOR);

    this._showOnlySelected$.subscribe(showOnlySelected => {
      if (showOnlySelected) {
        this.tableReportService.setGridState({ ...this.tableReportService.gridState, skip: 0 });
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.data$.complete();
    this.facilitiesLoading$.complete();
    this.consumptionsLoading$.complete();
    this.skipAggregates$.complete();
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(
      this.selectKey,
      this.kendoGrid,
      { keepSelectionOnDataChange: true }
    );
    this.gridService.dataChanged(this.data);
    this.reportingSearchService.facilityIds$.pipe(take(1)).subscribe({
      next: facilityIds => {
        this.gridService.setSelectedItems(facilityIds);
      }
    });
    this.gridService.selection$.subscribe(keys => {
      this.selection = keys;
      this.reportingSearchService.setFacilities(keys);
    });
  }

  public filterChange(): void {
    this.tableReportService.setGridFilter();
  }

  public dataStateChange(state: State): void {
    this.tableReportService.setGridState(state);
  }

  public columnChange(event: ColumnVisibilityChangeEvent): void {
    this.tableReportService.setGridColumnVisibility(event);
  }

  public editFacility(dataItem: ExtendedFacilityInformation): void {
    const modalRef = this.modalService.open(FacilityEditModalComponent);
    modalRef.componentInstance.facilityId = dataItem.FacilityId;
  }

  public editTags(dataItem: ExtendedFacilityInformation): void {
    const modalRef = this.modalService.open(FacilityTagEditModalComponent);
    modalRef.componentInstance.selectedFacility = new Facility({
      id: dataItem.FacilityId,
      displayName: dataItem.Name,
      localeId: null,
    });
  }

  public quantityTrackBy(_index: number, quantity: QuantitySeriesByType): string {
    return `${quantity.quantityId}${quantity.isNormalized}`;
  }

  public serieTypeTrackBy(_index: number, seriesByType: SeriesByType): string {
    return seriesByType.serieType;
  }

  public periodTrackBy(_index: number, serie: ReportingSeries): string {
    return `${serie.gridTitle}`;
  }

  public toggleShowSelected(): void {
    this._showOnlySelected$.next(!this._showOnlySelected$.value);
  }

  /**
   * Sanitize the aggregate result to ensure that all values are finite numbers.
   */

  private getConsumptions(
    params: ReportingSearchParams,
    incompleteThreshold: number
  ): Observable<{ data: FacilityQuantityValues, columns: QuantitySeriesByType[] }> {
    const unsupportedParamsInfo: string | null = getUnsupportedParamsInfo(params);
    if (unsupportedParamsInfo) {
      this.toasterService.info(unsupportedParamsInfo);
      return of({ data: { values: null, normalizedValues: null }, columns: [] });
    }
    return this.facilities$.pipe(
      switchMap(facilities => {
        const facilityIds = facilities.map(f => f.FacilityId);
        return this.tableReportDataService.getQuantitiesData(params, facilityIds, incompleteThreshold).pipe(
          indicate(this.consumptionsLoading$),
          map(facilityData => ({
            data: mapQuantityDataByFacilities(
              facilities, facilityData, params
            ),
            columns: getTableReportColumns(facilityData)
          }))
        );
      })
    );
  }

  private resetColumnOrder(): void {
    const columns = this.kendoGrid?.columns;
    if (columns?.length) {
      for (const column of columns) {
        column.orderIndex = 0;
      }
    }
  }

  private hideAllAverageCostsTotals(aggregatedValues: AggregateResult): AggregateResult {
    const obj: AggregateResult = {};
    for (const [key, value] of Object.entries(aggregatedValues)) {
      obj[key] = {};
      const [, , serieType, ,] = key.split('.');
      const isDerived = key.includes('derived');
      const derivedId = isDerived ? +serieType.split('derived')[1] : null ;
      const isMeterBasedAverageCost = derivedId === RelationalValueId.MeterBasedAverageCost;
      const isMeterBasedRetailerAverageCost = derivedId === RelationalValueId.MeterBasedRetailerAverageCost;
      const isMeterBasedDistributionAverageCost = derivedId === RelationalValueId.MeterBasedDistributionAverageCost;
      const isAverageCosts = isDerived && (
        isMeterBasedAverageCost ||
        isMeterBasedRetailerAverageCost ||
        isMeterBasedDistributionAverageCost
      );
      for (const [k, v] of Object.entries(value) as [AggregateDescriptor['aggregate'], number][]) {
        const shouldSetToNull = isAverageCosts && (k === 'sum');
        obj[key][k] = shouldSetToNull ? null : v;
      }
    }

    return obj;
  }
}
