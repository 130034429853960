import { map } from 'rxjs/operators';

import { CURRENT_PROFILE_SELECTION } from '../../constants/configs';
import { FacilitySearchType } from '../../constants/facility-search-type';
import { SearchCriteria } from '../../shared/search-criteria';
import {
  Bucket, ConfigurationControlClient
} from '@enerkey/clients/configuration-control';
import { ReadingSourceType } from '@enerkey/clients/manual-qa';

const profileSearchCriteriaParam = 'profileIds';

interface SearchTypeSelection {
  type: FacilitySearchType;
  searchCriteriaParam: string;
  labelText: string;
  emptyValue?: [] | '';
  initialValue?: string | any[];
}

class ManualQaSearchFacilitiesController {
  public static readonly $inject = ['ConfigurationControlClient'];
  public SearchType = FacilitySearchType;
  public searchTypes: SearchTypeSelection[];
  public searchType: FacilitySearchType;
  public searchCriteria: SearchCriteria;
  public buckets: Bucket[] = [];
  public readerTypes: ReadingSourceType[];
  public profileIds: number[] = [];
  public onChange: (param: any) => void;

  public readonly multiSelectOptions: kendo.ui.MultiSelectOptions = {
    filter: 'contains',
    autoClose: false
  };

  public readonly bucketLabel: keyof Bucket = 'groupIdentifier';
  public readonly bucketSelectedBy: keyof Bucket = 'id';
  public readonly readerTypeSelectedBy: keyof ReadingSourceType = 'id';

  public constructor(ccClient: ConfigurationControlClient) {
    ccClient.getBuckets(undefined).pipe(
      map(buckets => buckets.sortBy('groupIdentifier'))
    ).subscribe(buckets => {
      this.buckets = buckets;
    });

    this.searchTypes = [
      {
        type: this.SearchType.UseCurrentProfile,
        searchCriteriaParam: profileSearchCriteriaParam,
        labelText: 'MQA.SEARCH.FACILITIES.USE_CURRENT_PROFILE',
        initialValue: this.getCurrentProfileParamValue()
      },
      {
        type: this.SearchType.Profile,
        searchCriteriaParam: profileSearchCriteriaParam,
        labelText: 'MQA.SEARCH.FACILITIES.PROFILE',
        emptyValue: []
      },
      {
        type: this.SearchType.EnegiaIds,
        searchCriteriaParam: 'enegiaIds',
        labelText: 'MQA.SEARCH.FACILITIES.ENEGIAIDS',
        emptyValue: ''
      },
      {
        type: this.SearchType.FacilityName,
        searchCriteriaParam: 'facilityName',
        labelText: 'MQA.SEARCH.FACILITIES.FACILITYNAME',
        emptyValue: ''
      },
      {
        type: this.SearchType.Bucket,
        searchCriteriaParam: 'buckets',
        labelText: 'MQA.SEARCH.FACILITIES.BUCKET',
        emptyValue: []
      },
      {
        type: this.SearchType.ReaderType,
        searchCriteriaParam: 'readerTypes',
        labelText: 'MQA.SEARCH.FACILITIES.READER_TYPE',
        emptyValue: []
      }
    ];

  }

  public $onInit(): void {
    this.searchType = this.searchCriteria.getFacilitySearchType();
    this.profileIds = this.searchCriteria.getProfileIds().filterMap(
      p => Number.isFinite(p.ReportingObjectSetId) || Number.isFinite(p.id),
      p => p.ReportingObjectSetId ?? p.id
    );
  }

  public onProfileChange(): void {
    this.onChange({
      key: profileSearchCriteriaParam,
      value: this.profileIds.map(id => ({ id, ReportingObjectSetId: id }))
    });
  }

  private getCurrentProfileParamValue(): { id: number | string; ReportingObjectSetId: number | string }[] {
    return [{
      id: CURRENT_PROFILE_SELECTION,
      ReportingObjectSetId: CURRENT_PROFILE_SELECTION
    }];
  }
}

export default ManualQaSearchFacilitiesController;
