import { IAngularStatic } from 'angular';

import { Roles } from '../../../admin/constants/roles';
import { FacilityDropdownItem } from './facility-dropdown-item';
import { Utils } from '../../../../services/utils';
import { UserService } from '../../../../services/user-service';
import AdminModalController from '../../../admin/controllers/modals/admin-modal-controller';
import FacilityModalTemplate from 'raw-loader!../../../admin/components/admin-facility/admin-facility-modal.html';
import FacilityPropertiesModalTemplate from 'raw-loader!../../../admin/components/admin-spreadsheet/admin-spreadsheet-modal.html';
import { AjsModalService } from '../../../../services/modal/modal.service';
import { PropertyModalOptions } from '../../../admin/components/facility-management/facility-management.component';

declare const angular: IAngularStatic;

/* eslint-disable @typescript-eslint/no-explicit-any */

class FacilityDropDownController {
  public dropdownItems: FacilityDropdownItem[];
  public isDisabled = true;
  public onChange: any;
  public isMassImportRole: boolean;

  private dataSource: any;

  public constructor(
    private readonly $modal: AjsModalService,
    private readonly utils: Utils,
    private readonly userService: UserService
  ) {
    this.$modal = $modal;
    this.isMassImportRole = this.userService.hasRole(Roles.FACILITY_IMPORT);

    this.dropdownItems = [
      {
        text: this.utils.localizedString('ADMIN.MASS_IMPORT.FACILITY'),
        clickFn: this.getModal.bind(this),
        isDisabled: () => this.isDisabled
      },
      {
        text: utils.localizedString('ADMIN.MASS_IMPORT.FACILITY_PROPERTY'),
        clickFn: this.getPropertiesModal.bind(this),
        isDisabled: () => this.isDisabled
      },
      {
        text: utils.localizedString('ADMIN.MASS_IMPORT.FACILITY_CO2'),
        clickFn: this.getCo2PropertiesModal.bind(this),
        isDisabled: () => this.isDisabled
      },
      {
        text: utils.localizedString('ADMIN.MASS_IMPORT_SHAREOFTEMPERATUREFIXING'),
        clickFn: this.getCustomTypePropertiesModal.bind(this),
        isDisabled: () => this.isDisabled
      }
    ];
  }

  public toggleDisabled(): void {
    if (this.isDisabled) {
      this.dataSource = this.findGridInPage().dataSource;
    }
    this.isDisabled = (this.getCheckedFacilityIdsFromDataSource().length <= 0);
  }

  private findGridInPage(): kendo.ui.Grid {
    const kendoElements = '[kendo-grid]';
    const grids = angular.element(kendoElements, null);
    const grid = angular.element(grids[0]);
    return grid.data('kendoGrid');
  }

  private getModal(dropdownItem: FacilityDropdownItem): any {
    this.$modal.open({
      template: FacilityModalTemplate,
      controller: AdminModalController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'reveal--full-height',
      resolve: {
        facilityIds: () => this.getCheckedFacilityIdsFromDataSource(),
        includeQuantityId: () => {},
        includeCustomType: () => {},
        title: () => dropdownItem.text
      }
    }).result.then(() => this.onChanges());
  }

  private getPropertiesModal(dropdownItem: FacilityDropdownItem): any {
    this.openFacilityPropertiesModal({
      title: dropdownItem.text,
      facilityIds: this.getCheckedFacilityIdsFromDataSource(),
      includeQuantityId: false,
      includeCustomType: false
    }).then(() => this.onChanges());
  }

  private getCo2PropertiesModal(dropdownItem: FacilityDropdownItem): any {
    this.openFacilityPropertiesModal({
      title: dropdownItem.text,
      facilityIds: this.getCheckedFacilityIdsFromDataSource(),
      includeQuantityId: true,
      includeCustomType: false
    }).then(() => this.onChanges());
  }

  private getCustomTypePropertiesModal(dropdownItem: FacilityDropdownItem): any {
    this.openFacilityPropertiesModal({
      title: dropdownItem.text,
      facilityIds: this.getCheckedFacilityIdsFromDataSource(),
      includeQuantityId: false,
      includeCustomType: true
    }).then(() => this.onChanges());
  }

  private openFacilityPropertiesModal(options: PropertyModalOptions): Promise<unknown> {
    return this.$modal.open({
      template: FacilityPropertiesModalTemplate,
      controller: AdminModalController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'reveal--full-height',
      resolve: {
        facilityIds: () => options.facilityIds,
        includeQuantityId: () => options.includeQuantityId,
        includeCustomType: () => options.includeCustomType,
        title: () => options.title
      }
    }).result;
  }

  private onChanges(): void {
    this.onChange({ facilityIds: this.getCheckedFacilityIdsFromDataSource() });
  }

  private getCheckedFacilityIdsFromDataSource(): number[] {
    return this.dataSource.data()
      .reduce((accumulator: any, currentValue: any) => {
        if (currentValue.checked) {
          accumulator.push(currentValue.id || currentValue.Id);
        }
        return accumulator;
      }, []);
  }
}

FacilityDropDownController.$inject = [
  '$modal', 'utils', 'UserService',
];

export default FacilityDropDownController;
