import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EkDropdownItemComponent } from './ek-dropdown-item/ek-dropdown-item.component';
import { EkDropdownComponent } from './ek-dropdown/ek-dropdown.component';
import { EkDropdownHeadingComponent } from './ek-dropdown-heading/ek-dropdown-heading.component';
import { EkDropdownToggleDirective } from './ek-dropdown-toggle.directive';
import { EkDropdownContentComponent } from './ek-dropdown-content/ek-dropdown-content.component';

@NgModule({
  declarations: [
    EkDropdownComponent,
    EkDropdownContentComponent,
    EkDropdownHeadingComponent,
    EkDropdownItemComponent,
    EkDropdownToggleDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    EkDropdownComponent,
    EkDropdownContentComponent,
    EkDropdownHeadingComponent,
    EkDropdownItemComponent,
    EkDropdownToggleDirective,
  ],
})
export class EkDropdownModule { }
