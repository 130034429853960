import { EnerkeyNg2StateDeclaration } from '../../shared/routing';
import { DashboardRootComponent } from './components/dashboard-root/dashboard-root.component';

export const dashboardState: EnerkeyNg2StateDeclaration = {
  name: 'dashboard',
  url: '/dashboard',
  parent: 'index',
  views: {
    'app@': { component: DashboardRootComponent }
  }
};
