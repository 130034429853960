<modal-view-header [headingText]="'METER.EDIT.HEADER' | translate"></modal-view-header>

<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
  [loadingOverlay]="true"
>
  <form
    [formGroup]="meterEditForm"
    (ngSubmit)="submit()"
    id="meterEditForm"
  >
    <kendo-textbox
    *labelBefore="'METER.EDIT.METER_NAME' | translate"
      formControlName="meterNameField"
    >
    </kendo-textbox>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button [disabled]="meterEditForm.invalid"
      class="button"
      type="button"
      (click)="submit()"
    >
      {{ 'MODALS.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>