<legend class="header">
  <span *ngIf="header">{{ header }}</span>

  <div class="header-advanced" *ngIf="!(activeEtCurve | async) && advancedCheckboxEnabled">

    <label class="k-checkbox-label">
      {{ 'ANALYTICS.ETCURVE.ADVANCED' | translate }}
      <input
        type="checkbox"
        formControlName="advancedCalculation"
        kendoCheckBox
      >
    </label>
  </div>
</legend>

<p *ngIf="!(activeEtCurve | async) && (controls.advancedCalculation.value || !advancedCheckboxEnabled)">
  <kendo-datepicker
    *labelBefore="'ANALYTICS.ETCURVE.PERIOD_DATE' | translate"
    formControlName="startDate"
    [max]="maxDate"
  ></kendo-datepicker>
</p>

<p>
  <ek-combo
    *labelBefore="'ANALYTICS.ETCURVE.RESOLUTION' | translate"
    formControlName="resolution"
    [items]="resolutionOptions"
    [class.ng-invalid]="formGroup.errors?.invalidResolution"
  ></ek-combo>
</p>

<div *ngIf="controls.resolution.value === 'PT1H'">
  <p>
    <label>
      <input
        type="checkbox"
        kendoCheckBox
        formControlName="calculationOpsHours"
      /> {{ 'ANALYTICS.ETCURVE.OPERATIONAL_HOURS' | translate }}
    </label>
  </p>

  <div *ngIf="controls.calculationOpsHours.value">
    <div class="dual-datepicker-wrapper">
      <p>
        <kendo-timepicker
          formControlName="calculationTimeFrom"
          [max]="controls.calculationTimeToMax.value"
          [format]="'HH'"
          [steps]="{ minute: 60}"
        ></kendo-timepicker>
      </p>
      <span>
            <i class="fa fa-arrow-right"></i>
          </span>
      <p>
        <kendo-timepicker
          formControlName="calculationTimeTo"
          [min]="controls.calculationTimeFromMin.value"
          [format]="'HH'"
          [steps]="{ minute: 60}"
        ></kendo-timepicker>
      </p>
    </div>
    <p>
      <label>
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="calculationInvert"
        />
        {{ 'ANALYTICS.ETCURVE.INVERT' | translate }}
      </label>
    </p>
  </div>

</div>
<p>
  <ek-combo
    *labelBefore="'ANALYTICS.ETCURVE.SELECTION_OF_DAYS' | translate"
    formControlName="filterWeekend"
    [items]="filterWeekendOptions"
    [class.ng-invalid]="formGroup.errors?.invalidResolution"
  ></ek-combo>
</p>
