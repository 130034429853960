import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { StringKeys } from '@enerkey/ts-utils';

import { SelectComponent } from '../select/select.component';
import { FacilityService } from '../services/facility.service';

interface FacilitySelectItem {
  name: string;
  id: number;
}

@Component({
  selector: 'facility-select',
  templateUrl: '../select/select.component.html',
  styleUrls: ['../select/select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilitySelectComponent),
    multi: true
  }]
})
export class FacilitySelectComponent extends SelectComponent<FacilitySelectItem, 'id'> implements OnInit, OnDestroy {
  @Input() public defaultItemText: string;
  @Input() public showDefaultText: boolean = true;

  public textField: StringKeys<FacilitySelectItem> = 'name';
  public valueField: 'id' = 'id' as const;
  public defaultItem: FacilitySelectItem;

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private facilityService: FacilityService,
    private translateService: TranslateService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  public ngOnInit(): void {
    if (this.showDefaultText) {
      this.defaultItem = {
        id: null,
        name: this.defaultItemText ?? this.translateService.instant('SELECT_FACILITY')
      };
    }

    this.updateFacilities();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateFacilities(): void {
    this.facilityService.filteredProfileFacilities$
      .pipe(
        map(response => {
          const facilities = response.filter(item => item.facilityId);
          return facilities
            .map((item): FacilitySelectItem => ({
              id: item.facilityId,
              name: item.name
            }))
            .sort((facility1, facility2) => facility1.name.localeCompare(facility2.name));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: facilities => {
          this.sourceData = facilities;
          this.updateValueAfterFilterChange();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private updateValueAfterFilterChange(): void {
    const valueInDataSource = !!this.value && (
      this.sourceData.find(item => item[this.valueField] === this.value)
    );
    this.value = valueInDataSource?.[this.valueField] ?? null;
    this.updateValue?.(this.value);
  }
}
