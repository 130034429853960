import { KendoColumnConfig } from '../interfaces/kendo-column-config';

export const ACTION_OPERATIONS = {
  CREATE: 'createAction',
  UPDATE: 'updateAction',
  DELETE: 'deleteAction'
} as const;

export type ActionOperationType = typeof ACTION_OPERATIONS[keyof typeof ACTION_OPERATIONS];

const CATEGORY_FIELD_NAME = 'categoryName';

export const COLUMNS_PARAMETERS: KendoColumnConfig[] = [
  {
    title: 'ACTIONS.GRID.BASIC_INFO',
    hiddenInActions: false,
    hiddenInComments: false,
    columns: [
      {
        field: 'quantityName',
        title: 'ACTIONS.QUANTITYNAME',
        hiddenInActions: true,
        hiddenInComments: true
      },
      {
        field: 'meterName',
        title: 'ACTIONS.METERNAME',
        hiddenInActions: true,
        hiddenInComments: true
      },
      {
        field: 'id',
        title: 'ACTIONS.ID',
        hiddenInActions: true,
        hiddenInComments: true,
        width: 70
      },
      {
        field: 'externalActionId',
        title: 'ACTIONS.EXTERNALACTIONID',
        hiddenInActions: true,
        hiddenInComments: true,
        width: 70
      },
      {
        field: 'reportedDescription',
        title: 'ACTIONS.REPORTEDDESCRIPTION',
        width: 225,
      },
      {
        field: 'internalDescription',
        title: 'ACTIONS.INTERNALDESCRIPTION',
        width: 70,
        hiddenInActions: false,
      },
      {
        field: 'updatedAt',
        title: 'ACTIONS.UPDATEDAT',
        format: 'g',
        filter: 'date',
      },
      {
        field: 'createdAt',
        title: 'ACTIONS.CREATEDAT',
        format: 'g',
        filter: 'date',
      },
      {
        field: 'updaterName',
        title: 'ACTIONS.UPDATERNAME',
        hiddenInActions: true,
        hiddenInComments: true,
        filter: 'checkbox',
      },
      {
        field: 'creatorName',
        title: 'ACTIONS.CREATORNAME',
        hiddenInActions: true,
        hiddenInComments: true,
        filter: 'checkbox',
      },
      {
        field: 'actionTypeName',
        title: 'ACTIONS.ACTIONTYPE',
        filter: 'checkbox'
      },
      {
        field: 'executionPhaseName',
        title: 'ACTIONS.EXECUTIONPHASE',
        filter: 'checkbox'
      },
      {
        field: 'investigationName',
        title: 'ACTIONS.INVESTIGATION',
        width: 70,
        filter: 'checkbox',
      },
      {
        field: 'effectStartsAt',
        title: 'ACTIONS.EFFECTSTARTSAT',
        hiddenInActions: false,
        format: 'd',
        filter: 'date',
      },
      {
        field: 'effectStopsAt',
        title: 'ACTIONS.EFFECTSTOPSAT',
        hiddenInActions: true,
        format: 'd',
        filter: 'date',
      },
      {
        field: 'owner',
        title: 'ACTIONS.OWNER',
        width: 120,
        hiddenInActions: true,
        hiddenInComments: true,
      },
      {
        field: CATEGORY_FIELD_NAME,
        title: 'ACTIONS.CUSTOM_CATEGORY',
        filter: 'checkbox',
        disabledInComments: true
      },
      {
        field: 'actionGroupName',
        title: 'ACTIONS.ACTIONGROUP',
        hiddenInActions: true,
        hiddenInComments: true,
        filter: 'checkbox'
      },
      {
        field: 'actionBasisName',
        title: 'ACTIONS.ACTIONBASIS',
        hiddenInComments: true,
        filter: 'checkbox'
      },
      {
        field: 'actionClassName',
        title: 'ACTIONS.ACTIONCLASS',
        hiddenInActions: true,
        hiddenInComments: true,
        filter: 'checkbox'
      },
      {
        field: 'lifeTime',
        title: 'ACTIONS.LIFETIME',
        hiddenInComments: true,
        format: 'n1',
        filter: 'numeric',
        aggregate: 'average'
      },
      {
        field: 'investment',
        title: 'ACTIONS.INVESTMENT',
        hiddenInComments: true,
        format: 'n2',
        filter: 'numeric',
        aggregate: 'sum'
      },
      {
        field: 'paybackTime',
        title: 'ACTIONS.PAYBACKTIME',
        hiddenInComments: true,
        format: 'n1',
        filter: 'numeric',
        aggregate: 'average'
      },
      {
        field: 'investmentSupportName',
        title: 'ACTIONS.INVESTMENTSUPPORT',
        hiddenInActions: true,
        hiddenInComments: true,
        filter: 'checkbox'
      },
      {
        field: 'reportingYear',
        title: 'ACTIONS.REPORTINGYEAR',
        hiddenInActions: true,
        hiddenInComments: true,
        filter: 'checkbox',
        attributes: { class: 'cell-number' }
      },
      {
        field: 'executionYear',
        title: 'ACTIONS.EXECUTIONYEAR',
        hiddenInActions: true,
        hiddenInComments: true,
        filter: 'checkbox'
      },
      {
        field: 'executionPhaseUpdatedAt',
        title: 'ACTIONS.EXECUTIONPHASEUPDATEDAT',
        hiddenInActions: true,
        hiddenInComments: true,
        format: 'g',
        filter: 'date',
      },
      {
        field: 'reportedToMotivaAt',
        title: 'ACTIONS.REPORTEDTOMOTIVAAT',
        hiddenInActions: true,
        hiddenInComments: true,
        format: 'g',
        filter: 'date',
      }
    ]
  },
  {
    title: 'ACTIONS.GRID.INVESTMENTS_AND_SAVINGS',
    hiddenInActions: false,
    hiddenInComments: true,
    columns: [
      {
        field: 'electricitySavings',
        title: 'ACTIONS.ELECTRICITYSAVINGS',
        aggregate: 'sum',
        format: 'n1',
        filter: 'numeric',
      },
      {
        field: 'electricitySavingsEur',
        title: 'ACTIONS.ELECTRICITYSAVINGSEUR',
        hiddenInActions: true,
        aggregate: 'sum',
        format: 'n2',
        filter: 'numeric',
      },
      {
        field: 'electricitySavingsCo2',
        title: 'ACTIONS.ELECTRICITYSAVINGSCO2',
        hiddenInActions: true,
        aggregate: 'sum',
        format: 'n1',
        filter: 'numeric',
      },
      {
        field: 'heatingSavings',
        title: 'ACTIONS.HEATINGSAVINGS',
        aggregate: 'sum',
        format: 'n1'
      },
      {
        field: 'heatingSavingsEur',
        title: 'ACTIONS.HEATINGSAVINGSEUR',
        hiddenInActions: true,
        aggregate: 'sum',
        format: 'n2',
        filter: 'numeric',
      },
      {
        field: 'heatingSavingsCo2',
        title: 'ACTIONS.HEATINGSAVINGSCO2',
        hiddenInActions: true,
        aggregate: 'sum',
        format: 'n1',
        filter: 'numeric',
      },
      {
        field: 'fuelSavings',
        title: 'ACTIONS.FUELSAVINGS',
        aggregate: 'sum',
        format: 'n1',
        filter: 'numeric',
      },
      {
        field: 'fuelSavingsEur',
        title: 'ACTIONS.FUELSAVINGSEUR',
        hiddenInActions: true,
        aggregate: 'sum',
        format: 'n2',
        filter: 'numeric',
      },
      {
        field: 'fuelSavingsCo2',
        title: 'ACTIONS.FUELSAVINGSCO2',
        hiddenInActions: true,
        aggregate: 'sum',
        format: 'n1',
        filter: 'numeric',
      },
      {
        field: 'waterSavings',
        title: 'ACTIONS.WATERSAVINGS',
        aggregate: 'sum',
        format: 'n1',
        filter: 'numeric',
      },
      {
        field: 'waterSavingsEur',
        title: 'ACTIONS.WATERSAVINGSEUR',
        hiddenInActions: true,
        aggregate: 'sum',
        format: 'n2',
        filter: 'numeric',
      }
    ]
  }
];

export const CURRENCY_FIELDS = [
  'investment',
  'waterSavingsEur',
  'fuelSavingsEur',
  'heatingSavingsEur',
  'electricitySavingsEur'
];
