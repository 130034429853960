export enum RelationalValueId {
  GrossArea = 1,
  grossVolumeId = 2,
  NetFloorArea = 3,
  TotalVolume = 4,
  HeatedArea = 5,
  HeatedVolume = 6,
  Inhabitants = 7,
  heatingMethodId = 8,
  shareOfTemperatureFixingId = 9,
  renovationYearId = 10,
  Area = 11,
  Volume = 12,
  StoreArea = 13,
  ProductionAmountPieces = 14,
  ProductionAmountKg = 15,
  totalAreaId = 16,
  statusId = 17,
  co2Factor = 26,
  co2NationalReference = 1001,
  NationalCosts = 1002,
  NationalRetailerCosts = 1003,
  NationalDistributionCosts = 1004,
  Costs = 1007,
  RetailerCosts = 1008,
  DistributionCosts = 1009,
  RetailerMonthlyFees = 1010,
  DistributionMonthlyFees = 1011,
  RetailerCombinedCosts = 1012,
  DistributionCombinedCosts = 1013,
  DistributionTaxes = 1014,
  MeterBasedAverageCost = 1015,
  MeterBasedRetailerAverageCost = 1016,
  MeterBasedDistributionAverageCost = 1017
}

// TODO: fix casing here
