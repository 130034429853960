import { Component, Input } from '@angular/core';
import { GroupDescriptor } from '@progress/kendo-data-query';

import { Meter, MeterWithProperties } from '@enerkey/clients/metering';

import { AddMetersGrid } from '../../shared/add-meter-grid';

@Component({
  selector: 'add-meters-source-grid',
  templateUrl: './add-meters-source-grid.component.html',
})
export class AddMetersSourceGridComponent extends AddMetersGrid<Meter> {
  @Input() public loading = false;

  public nameField: keyof Meter = 'name';
  public quantityIdField: keyof Meter = 'quantityId';
  public resolutionField: keyof MeterWithProperties = 'resolution';
  public groups: GroupDescriptor[];

  public constructor() {
    super();
    this.groups = [{ field: this.quantityIdField }];
  }
}
