class EmInputDropdownController {
  $onInit() {
    this.touched = false;

    if (this.config.dependsOn) {
      const optionsKey = this.config.dependencyValue;
      this.options = this.config.options[optionsKey];
    } else {
      this.options = this.config.options;
    }
    this.model = this.config.initialValue;
    this.config.isValid = true;
  }

  onBlur() {
    this.touched = true;
    this.config.isValid = !this.isRequired();
  }

  onInputChange() {
    this.touched = true;
    this.config.initialValueVaries = false;
    this.config.isValid = !this.isRequired();
    this.onChange(this.config, this.model);
  }

  isRequired() {
    return this.config.mandatory && (this.model === null || angular.isUndefined(this.model));
  }
}

export default EmInputDropdownController;
