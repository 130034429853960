import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import * as jsonpatch from 'fast-json-patch';

import { MeteringClient, Operation, PoaService, RelatedMeter, SubMeter } from '@enerkey/clients/metering';
import { ofVoid } from '@enerkey/rxjs';
import { MeterManagementClient } from '@enerkey/clients/meter-management';
import { ServiceScopeType } from '@enerkey/clients/contract';
import { ModalService } from '@enerkey/foundation-angular';

import { MeterManagementService } from '../../services/meter-management.service';
import { MeterHierarchyTreelistItem } from '../../shared/meter-hierarchy-factory';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { SubmeterSelectModalComponent } from '../submeter-select-modal/submeter-select-modal.component';
import { MeterMassEditService } from '../meter-mass-edit/meter-mass-edit-service';
import { TerminalService } from '../../../../shared/services/terminal.service';
import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';
import { MassAddSubscriptionModalComponent } from '../mass-add-subscription-modal/mass-add-subscription-modal.component';
import { UserService } from '../../../../services/user-service';
import { Roles } from '../../constants/roles';
import { AddMetersToPoaModalComponent } from '../add-meters-to-poa-modal/add-meters-to-poa-modal.component';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'meter-management',
  templateUrl: './meter-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MeterManagementService]
})
export class MeterManagementComponent implements AfterViewInit, OnDestroy {
  public noMetersSelected$: Observable<boolean>;

  public readonly isContractManager: boolean;
  public readonly isPoaManager: boolean;

  @ViewChild('topbarTemplate')
  private readonly topRightTemplate: TemplateRef<unknown>;

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly meterManagementClient: MeterManagementClient,
    private readonly meterManagementService: MeterManagementService,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly meterMassEditService: MeterMassEditService,
    private readonly terminalService: TerminalService,
    private readonly templateLifter: TemplateLifterService,
    private readonly dialogService: DialogService,
    userService: UserService
  ) {
    this.noMetersSelected$ = this.meterManagementService.selectedMeters$.pipe(
      map(selection => !Array.hasItems(selection)),
      shareReplay(1)
    );
    this.isContractManager = userService.hasRole(Roles.CONTRACT_MANAGER);
    this.isPoaManager = userService.hasRole(Roles.POAMANAGER);
  }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
  }

  public addAsMainMeter(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters();
    const selectedMeterIds = selectedMeters.map(m => m.meter.id);

    const requests = selectedMeterIds.map(id => this.meteringClient.createNewHierarchyFromMeter(id).pipe(
      tap(() => {
        this.toasterService.success('ADMIN.METER_SEARCH.SAVING_HIERARCHIES.SUCCESS');
      }),
      this.catchRequestError(id)
    ));

    forkJoin(requests).subscribe(() => {
      this.meterManagementService.repeatSearch();
    });
  }

  public addForMeter(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters();

    const facilityIds = selectedMeters.unique(m => m.facility.id);
    const quantityIds = selectedMeters.filterMap(
      m => !m.isRelatedMeter,
      m => m.meter.quantityId
    ).unique();
    if (facilityIds.length > 1 || quantityIds.length > 1) {
      this.toasterService.error('ADMIN.METER_SEARCH.TOO_MANY_HIERARCHIES_SELECTED');
      return;
    }

    const selectedHierarchyIds = selectedMeters.map(m => m.hierarchyId);
    const meters = selectedMeters.filter(
      m => (m.isMainMeter || !m.hierarchyTree?.some(id => selectedHierarchyIds.includes(id)))
    );

    this.meterManagementService.currentHierarchy$.pipe(
      take(1)
    ).subscribe(allHierarchies => {
      let hierarchyWithSelectableMeters = allHierarchies
        .find(h => h.facilityId === facilityIds[0]);
      if (Array.hasItems(quantityIds)) {
        hierarchyWithSelectableMeters = hierarchyWithSelectableMeters.children.find(
          h => h.quantityId === quantityIds[0]
        );
      }

      const metersToDisable = meters
        .flatMap(m => this.getChildrenRecursive(m));
      const selectableMeters = this.getChildrenRecursive(hierarchyWithSelectableMeters)
        .filter(
          h => h.meter
            && !h.isFloatingMeter
            && !h.isRelatedMeter
            && !metersToDisable.find(m => m.hierarchyId === h.hierarchyId)
        );

      const modalRef = this.modalService.open(SubmeterSelectModalComponent);
      modalRef.componentInstance.meters = selectableMeters;
      modalRef.result
        .then((meter: MeterHierarchyTreelistItem) => {
          this.removeMeters().pipe(
            switchMap(() => this.addMeters(meters, meter))
          ).subscribe(() => {
            this.meterManagementService.repeatSearch();
          });
        })
        .catch(() => {});
    });
  }

  public removeMetersFromHierarchy(): void {
    this.removeMeters().subscribe(() => {
      this.meterManagementService.repeatSearch();
    });
  }

  public massEditMeters(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters();
    this.meterMassEditService.massEditMeters(selectedMeters.map(m => ({
      ...m.meter,
      enegiaId: m.facility.enegiaId,
      tags: m.tagNames
    })))
      .then(() => {
        this.meterManagementService.repeatSearch();
      })
      .catch(() => {});
  }

  public editMeterOrder(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters().filter(
      m => !m.isFloatingMeter && !m.isRelatedMeter
    );
    const facilityIds = selectedMeters.unique(m => m.facility.id);
    const quantityIds = selectedMeters.unique(m => m.meter.quantityId);

    const onlyMainMetersSelected = Array.hasItems(selectedMeters) && selectedMeters.every(m => m.isMainMeter);
    const parents = selectedMeters.unique(m => m.hierarchyTree[m.hierarchyTree.length - 1]);

    if (!onlyMainMetersSelected) {
      if (!Array.hasItems(parents)) {
        this.toasterService.error('ADMIN.METER_SEARCH.NO_HIERARCHY_SELECTED');
        return;
      } else if (parents.length > 1) {
        this.toasterService.error('ADMIN.METER_SEARCH.TOO_MANY_HIERARCHIES_SELECTED');
        return;
      }
    }

    this.meterManagementService.currentHierarchy$.pipe(
      take(1)
    ).subscribe(hierarchy => {
      let hierarchyToEdit = hierarchy
        .find(f => f.facilityId === facilityIds[0]).children
        .find(q => q.quantityId === quantityIds[0]);

      if (!onlyMainMetersSelected) {
        for (let i = 1; i < selectedMeters[0].hierarchyTree.length; i++) {
          hierarchyToEdit = hierarchyToEdit.children.find(h => h.hierarchyId === selectedMeters[0].hierarchyTree[i]);
        }
      }

      this.meterMassEditService.reorderMeters(
        hierarchyToEdit.children.filter(c => !c.isRelatedMeter && !c.isFloatingMeter)
      ).then(() => {
        this.meterManagementService.repeatSearch();
      });
    });
  }

  public massEditMeterCosts(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters().map(m => m.meter);
    this.meterMassEditService.massEditMeterCosts(selectedMeters)
      .then(() => {
        this.meterManagementService.repeatSearch();
      })
      .catch(() => {});
  }

  public addMetersToTerminal(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters();
    if (selectedMeters.some(m => m.meter.terminal)) {
      this.toasterService.error('ADMIN.TERMINAL_ALREADY_EXISTS.ERROR');
      return;
    }
    const meterIds = selectedMeters.map(m => m.meter.id);
    const enegiaIds = selectedMeters.map(m => m.facility.enegiaId).unique();
    this.terminalService.getAddMetersToTerminalModal(enegiaIds, meterIds)
      .then(() => {
        this.meterManagementService.repeatSearch();
      })
      .catch(() => {});
  }

  public removeMetersFromTerminal(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters();
    const distinctTerminals = selectedMeters
      .map(m => m.meter.terminal)
      .filter(t => !!t)
      .unique();
    if (!Array.hasItems(distinctTerminals)) {
      this.toasterService.info('ADMIN.METER_SEARCH.NO_SELECTED_TERMINAL.INFO');
      return;
    }
    if (distinctTerminals.length > 1) {
      this.toasterService.error('ADMIN.METER_SEARCH.NOT_SAME_TERMINAL.ERROR');
      return;
    }
    const meterIds = selectedMeters.map(m => m.meter.id);
    this.terminalService.getRemoveMetersFromTerminalModal(distinctTerminals[0], meterIds)
      .then(() => {
        this.meterManagementService.repeatSearch();
      })
      .catch(() => {});
  }

  public addMetersToSubscription(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters();
    const modalRef = this.modalService.open(MassAddSubscriptionModalComponent);
    modalRef.componentInstance.selectedItems = selectedMeters.map(m => ({
      id: m.meter.id,
      name: m.meter.name
    }));
    modalRef.componentInstance.scope = ServiceScopeType.Meter;
  }

  public addPoaToMeters(): void {
    const selectedMeters = this.meterManagementService.getSelectedMeters();
    const confirm = selectedMeters.some(m => m.poa)
      ? this.dialogService.getConfirmationModal({
        title: '',
        text: 'ADMIN.POA.CONFIRM_ADD_POA_TO_METER'
      })
      : ofVoid()
    ;

    confirm.subscribe({
      next: () => {
        const modalRef = this.modalService.open(AddMetersToPoaModalComponent);
        modalRef.componentInstance.meterIds = selectedMeters.map(m => m.meter.id);
        modalRef.result
          .then(() => {
            this.meterManagementService.repeatSearch();
          })
          .catch(() => {});
      }
    });
  }

  public removePoaFromMeters(): void {
    this.meterManagementService.removePoaFromMeters();
  }

  public authorizeMetersForFinnishDatahub(): void {
    this.meterManagementService.authorizeMetersForDataHub(PoaService.FinnishDataHub);
  }

  public authorizeMetersForDanishDatahub(): void {
    this.meterManagementService.authorizeMetersForDataHub(PoaService.DanishDataHub);
  }

  public createMeters(): void {
    this.meterMassEditService.createMeters()
      .then(meterIds => {
        if (Array.hasItems(meterIds)) {
          this.meterManagementService.search({
            meterIds
          });
        }
      })
      .catch(() => {});
  }

  private getSubMeterDeletions(subMeters: MeterHierarchyTreelistItem[]): Observable<unknown>[] {
    const requests: Observable<unknown>[] = [];

    subMeters.toGroupsBy(sm => sm.hierarchyTree[0]).forEach(
      (meters, id) => requests.push(this.meterManagementClient.getMeterHierarchy(id).pipe(
        switchMap(hierarchy => {
          const meterIdsToRemove = meters.map(m => m.meter.id);
          const modifiedHierarchy = hierarchy.clone();

          let hierarchyToEdit = modifiedHierarchy.mainMeter;
          for (let i = 2; i < meters[0].hierarchyTree.length; i++) {
            hierarchyToEdit = hierarchyToEdit.subMeters.find(h => h.id === meters[0].hierarchyTree[i]);
          }
          hierarchyToEdit.subMeters = hierarchyToEdit.subMeters.filter(h => !meterIdsToRemove.includes(h.meterId));
          hierarchyToEdit.relatedMeters = hierarchyToEdit.relatedMeters.filter(
            h => !meterIdsToRemove.includes(h.meterId)
          );
          const edits = jsonpatch.compare(hierarchy, modifiedHierarchy);
          return this.meteringClient.patchMeterHierarchy(
            hierarchy.id,
            edits.map(edit => new Operation(edit))
          ).pipe(
            this.catchRequestError(id)
          );
        })
      ))
    );

    return requests;
  }

  private removeMeters(): Observable<unknown> {
    const selectedMeters = this.meterManagementService.getSelectedMeters().filter(
      m => !m.isFloatingMeter
    );
    const selectedHierarchyIds = selectedMeters.map(m => m.hierarchyId);
    const mainMeters = selectedMeters.filter(m => m.isMainMeter);
    const mainMeterHierarchyIds = mainMeters.map(m => m.hierarchyId);
    const subMeters = selectedMeters
      .filter(m => !m.isMainMeter && !mainMeterHierarchyIds.includes(m.hierarchyTree[0]))
      .filter(sm => !sm.hierarchyTree.some(id => selectedHierarchyIds.includes(id)));

    const subMeterDeletions = this.getSubMeterDeletions(subMeters);

    const mainMeterDeletions = mainMeters.map(
      meter => this.meteringClient.removeMeterHierarchy(meter.hierarchyTree[0]).pipe(
        this.catchRequestError(meter.meter.id)
      )
    );

    const operations = [...mainMeterDeletions, ...subMeterDeletions];

    return Array.hasItems(operations) ? forkJoin(operations) : ofVoid();
  }

  private addMeters(
    metersToAdd: MeterHierarchyTreelistItem[],
    target: MeterHierarchyTreelistItem
  ): Observable<unknown> {
    return this.meterManagementClient.getMeterHierarchy(target.hierarchyTree[0]).pipe(
      switchMap(hierarchy => {
        const modifiedHierarchy = hierarchy.clone();

        let hierarchyToEdit = modifiedHierarchy.mainMeter;
        const hierarchyIds = [...target.hierarchyTree, target.hierarchyId];
        for (let i = 2; i < hierarchyIds.length; i++) {
          hierarchyToEdit = hierarchyToEdit.subMeters.find(h => h.id === hierarchyIds[i]);
        }
        const relatedMeters = metersToAdd.filter(m => m.isRelatedMeter);
        const subMeters = metersToAdd.filter(m => !m.isRelatedMeter);
        hierarchyToEdit.subMeters = [
          ...hierarchyToEdit.subMeters,
          ...subMeters.map(m => this.subMeterIntoHierarchy(m))
        ];
        hierarchyToEdit.relatedMeters = [
          ...hierarchyToEdit.relatedMeters,
          ...relatedMeters.map(m => new RelatedMeter({ meterId: m.meter.id }))
        ];
        const edits = jsonpatch.compare(hierarchy, modifiedHierarchy);
        return this.meteringClient.patchMeterHierarchy(
          hierarchy.id,
          edits.map(edit => new Operation(edit))
        );
      })
    );
  }

  private subMeterIntoHierarchy(hierarchy: MeterHierarchyTreelistItem): SubMeter {
    return new SubMeter({
      meterId: hierarchy.meter.id,
      subMeters: hierarchy.children?.filterMap(
        m => !m.isRelatedMeter,
        m => this.subMeterIntoHierarchy(m)
      ),
      relatedMeters: hierarchy.children?.filterMap(
        m => m.isRelatedMeter,
        m => new RelatedMeter({ meterId: m.meter.id })
      ),
    });
  }

  private getChildrenRecursive(
    meter: MeterHierarchyTreelistItem
  ): MeterHierarchyTreelistItem[] {
    const meters = [];
    meters.push(meter);
    if (meter.children) {
      for (const child of meter.children) {
        meters.push(...this.getChildrenRecursive(child));
      }
    }
    return meters;
  }

  private catchRequestError(
    meterId: number
  ): MonoTypeOperatorFunction<unknown> {
    return source => source.pipe(
      tap(() => {
        this.toasterService.success('ADMIN.METER_SEARCH.SAVING_HIERARCHIES.SUCCESS');
      }),
      catchError(() => {
        this.toasterService.error(
          'ADMIN.METER_SEARCH.SAVING_HIERARCHIES.ERROR',
          `${this.translateService.instant('ADMIN.METER_ID')}: ${meterId}`
        );
        return ofVoid();
      })
    );
  }
}
