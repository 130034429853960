import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { GridComponent, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { formGroupFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import {
  Facility,
  FacilityClient,
  ProfileFacilityConnectParameters,
} from '@enerkey/clients/facility';
import {
  IProfileViewModel,
  ProfileViewModel,
  UpdateProfileDto,
  UserManagementClient,
} from '@enerkey/clients/user-management';

import { DialogService } from '../../../../shared/services/dialog.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';

type EditableProfileFields = Pick<IProfileViewModel, 'name' | 'serviceLevel' | 'companyId' > & { services: number[] }

@Component({
  encapsulation: ViewEncapsulation.None,
  templateUrl: './profile-edit-modal.component.html',
  styleUrls: ['./profile-edit-modal.component.scss'],
  providers: [KendoGridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ windowClass: 'fixed-height modal-dialog-scrollable' })
export class ProfileEditModalComponent extends ModalBase<void> implements AfterViewInit, OnInit, OnDestroy {

  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };

  public readonly loading$: Observable<boolean>;
  public facilities$: Observable<Facility[]>;

  public profileUpdateForm: UntypedFormGroup;
  public profile: ProfileViewModel;
  public deletedFacilities: number[] = [];

  public readonly rowCallback: (context: RowClassArgs) => Record<string, boolean>;

  public readonly selectKey: keyof Pick<Facility, 'id'> = 'id';
  public selectedFacilities: number[] = [];

  private readonly _loading = new LoadingSubject(true);
  private readonly _destroy = new Subject<void>();

  @ViewChild(GridComponent, { static: true })
  private readonly kendoGrid: GridComponent;

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly toasterService: ToasterService,
    private readonly gridService: KendoGridService<Facility, ProfileEditModalComponent['selectKey']>,
    private readonly userManagementClient: UserManagementClient,
    private readonly dialogService: DialogService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);

    this.loading$ = this._loading.asObservable();

    this.rowCallback = (context: RowClassArgs): Record<string, boolean> => {
      const dataItem: ProfileViewModel = context.dataItem;
      const status = this.checkIfDeleted(dataItem.id);
      return {
        normal: !status,
        removed: status
      };
    };
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.gridService.selection$.subscribe(keys => {
      this.selectedFacilities = keys;
    });
  }

  public ngOnInit(): void {
    this.facilities$ = this.facilityClient.getProfileFacilities(this.profile.id).pipe(
      takeUntil(this._destroy),
      indicate(this._loading),
      tap(rows => this.gridService.dataChanged(rows))
    );

    this.profileUpdateForm = formGroupFrom<EditableProfileFields>({
      name: this.profile.name,
      serviceLevel: this.profile.serviceLevel,
      services: this.profile.serviceIds,
      companyId: this.profile.companyId
    });
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loading.complete();
  }

  public submit(): void {
    const data = this.profileUpdateForm.value;

    if (this.deletedFacilities.length === 0) {
      this.sendUpdateRequests(data);
    } else {
      this.dialogService.getConfirmationModal({
        text: 'ADMIN.REMOVE_FACILITIES_CONFIRMATION',
        title: 'ADMIN.REMOVE_FACILITIES_MODAL_TITLE',
        isDelete: true,
        translate: true,
      }).subscribe({
        next: () => this.sendUpdateRequests(data),
        error: () => { },
      });
    }
  }

  public dismiss(): void {
    super.dismissModal();
  }

  public checkIfDeleted(id: number): boolean {
    return this.deletedFacilities.includes(id);
  }

  public massRestore(): void {
    this.selectedFacilities.forEach(id => {
      this.deletedFacilities.remove(id);
    });
    this.gridService.selectAllChange(false);
  }

  public massRemove(): void {
    this.selectedFacilities.forEach(id => {
      if (!this.deletedFacilities.includes(id)) {
        this.deletedFacilities.push(id);
      }
    });
    this.gridService.selectAllChange(false);
  }

  public updateStatus(id: number): void {
    if (this.checkIfDeleted(id)) {
      this.deletedFacilities.remove(id);
    } else {
      this.deletedFacilities.push(id);
    }
  }

  public sendUpdateRequests(data: EditableProfileFields): void {
    const updateProfile = this.userManagementClient.updateProfile(
      this.profile.id,
      new UpdateProfileDto({
        name: data.name,
        serviceLevel: data.serviceLevel,
        serviceIds: data.services,
        companyId: data.companyId,
      })
    );

    const removeFacilities: Observable<void> = Array.hasItems(this.deletedFacilities)
      ? this.facilityClient.removeFacilitiesFromProfiles(
        new ProfileFacilityConnectParameters({
          facilityIds: this.deletedFacilities,
          profileIds: [this.profile.id],
        })
      )
      : of(null);

    forkJoin([updateProfile, removeFacilities]).pipe(
      indicate(this._loading)
    ).subscribe({
      next: () => {
        this.toasterService.success('ADMIN.PROFILE_UPDATE_SUCCESS');
        super.closeModal();
      },
      error: () => {
        this.toasterService.error('ADMIN.PROFILE_UPDATE_FAILED');
      },
    });
  }

  public deleteProfile(): void {
    this.dialogService.getConfirmationModal({
      title: 'ADMIN.PROFILE_DELETE_TITLE',
      text: 'ADMIN.PROFILE_DELETE_CONFIRMATION',
      isDelete: true,
      translate: true
    }).subscribe({
      next: () => {
        this.facilityClient.deleteProfile(this.profile.id).subscribe({
          next: () => {
            this.toasterService.success('ADMIN.PROFILE_DELETE_SUCCESS');
            super.closeModal();
          },
          error: () => {
            this.toasterService.error('ADMIN.PROFILE_DELETE_FAILED');
          }
        });
      }
    });
  }
}
