import {
  ComponentRef,
  Directive,
  HostBinding,
  Input,
  OnDestroy,
  Renderer2,
  ViewContainerRef
} from '@angular/core';

import { ContentLoaderComponent } from '../components/content-loader/content-loader.component';

@Directive({ selector: '[loadingSpinner]' })
export class LoadingSpinnerDirective implements OnDestroy {
  // content-loader only works for items that have position: relative
  @HostBinding('style.position') public readonly position = 'relative' as const;

  public get loadingSpinner(): boolean {
    return !!this.loadingComponent;
  }

  @Input()
  public set loadingSpinner(value: boolean) {
    if (value) {
      this.showSpinner();
    } else {
      this.hideSpinner();
    }
  }

  public get loadingOverlay(): boolean {
    return this.hasOverlay;
  }

  @Input() public set loadingOverlay(value: boolean) {
    this.hasOverlay = value;

    if (this.loadingComponent) {
      this.loadingComponent.instance.hasOverlay = value;
    }
  }

  private hasOverlay: boolean = false;
  private loadingComponent: ComponentRef<ContentLoaderComponent>;

  public constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly renderer: Renderer2
  ) { }

  public ngOnDestroy(): void {
    this.hideSpinner();
  }

  private showSpinner(): void {
    if (this.loadingComponent) {
      return;
    }

    this.loadingComponent = this.vcRef.createComponent(ContentLoaderComponent);

    this.loadingComponent.instance.hasOverlay = this.hasOverlay;

    this.renderer.appendChild(
      this.vcRef.element.nativeElement,
      this.loadingComponent.instance.vcRef.element.nativeElement
    );
  }

  private hideSpinner(): void {
    if (this.loadingComponent) {
      this.loadingComponent.destroy();
      this.loadingComponent = null;
    }
  }
}
