import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';

import FacilityCreateModalTemplate from 'raw-loader!../facility-create/facility-create-modal.html';

import { Facility } from '@enerkey/clients/facility';

import { AjsModalService } from '../../../../services/modal/modal.service';
import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';
import { FacilityManagementService } from '../../services/facility-management.service';
import FacilityCreateModalController from '../facility-create/facility-create-modal-controller.js';
import { FacilityManagementGridComponent } from '../facility-management-grid/facility-management-grid.component';

export type PropertyModalOptions = {
  title: string,
  facilityIds: number[],
  includeQuantityId: boolean,
  includeCustomType: boolean
}

@Component({
  selector: 'facility-management',
  templateUrl: './facility-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FacilityManagementService]
})
export class FacilityManagementComponent implements AfterViewInit, OnDestroy {
  public selectedFacilities$: Observable<{ id: number, name: string }[]>;
  public allFacilities$: Observable<Facility[]>;

  @ViewChild(FacilityManagementGridComponent) private readonly gridComponent: FacilityManagementGridComponent;
  @ViewChild('topbarTemplate') private readonly topRightTemplate: TemplateRef<unknown>;

  public constructor(
    private readonly templateLifter: TemplateLifterService,
    private readonly angularjsModalService: AjsModalService,
    private readonly facilityManagementService: FacilityManagementService
  ) {
  }

  public ngAfterViewInit(): void {
    this.allFacilities$ = this.gridComponent.facilities$.pipe(
      map(gridFacilities => gridFacilities.map(f => f.facility))
    );
    this.templateLifter.template = this.topRightTemplate;
    this.selectedFacilities$ = combineLatest([
      this.gridComponent.facilities$,
      this.gridComponent.selectedFacilities$
    ]).pipe(map(([facilities, selectedFacilities]) =>
      facilities.filterMap(
        f => selectedFacilities.includes(f.id),
        f => ({
          id: f.id,
          name: f.facility.displayName
        })
      )));
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
  }

  public openCreateModal(): void {
    this.angularjsModalService.open({
      template: FacilityCreateModalTemplate,
      controller: FacilityCreateModalController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'reveal--full-height',
    });
  }

  public repeatSearch(): void {
    this.facilityManagementService.repeatSearch();
  }
}
