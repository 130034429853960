import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { FacilityClient, Url } from '@enerkey/clients/facility';

import { FacilityService } from '../../../shared/services/facility.service';
import { ProfileService } from '../../../shared/services/profile.service';

@Injectable({ providedIn: 'root' })
export class FacilityUrlService {

  /** Profile facility urls */
  public readonly urlsByFacility$: Observable<Record<number, Url[]>>;

  public constructor(
    private readonly facilityClient: FacilityClient,
    profileService: ProfileService,
    facilityService: FacilityService
  ) {
    this.urlsByFacility$ = profileService.profile$.pipe( // reset urls on profile change
      switchMap(() => facilityService.profileFacilities$.pipe(take(1))),
      map(facilities => facilities.map(f => f.facilityId)),
      switchMap(ids => this.facilityClient.getFacilitiesUrls(ids)),
      shareReplay(1)
    );
  }

  public getFacilityUrls(facilityId: number): Observable<Url[]> {
    return this.facilityClient.getFacilityUrls(facilityId);
  }
}
