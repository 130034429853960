import template from 'raw-loader!./quantity-unit.html';

const QuantityUnitComponent = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    unit: '<'
  }
};

export default QuantityUnitComponent;
