import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'edit-factor',
  templateUrl: './edit-factor.component.html',
  styleUrls: ['./edit-factor.component.scss']
})
export class EditFactorComponent {
  public static factorDefaultValue = 1;

  @Input() buttonDisabled: boolean;
  @Output() factorChange = new EventEmitter<number>();

  public factor = EditFactorComponent.factorDefaultValue;

  public submitClicked() {
    this.factorChange.emit(this.factor);
    this.factor = EditFactorComponent.factorDefaultValue;
  }
}
