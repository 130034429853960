import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, shareReplay, switchMap } from 'rxjs/operators';

import { ofVoid } from '@enerkey/rxjs';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private _tokenRenewal$: Observable<OAuthEvent> = null;

  public constructor(
    private readonly oauthService: OAuthService
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError(
      (err: HttpErrorResponse) => {
        if (err?.status !== 401) {
          // TODO: when authorization comes from Angular2, check that 401 wasn't from Identity
          return throwError(err);
        }

        // Refresh token and re-send the request with the refreshed token
        return this.tokenRenewal$.pipe(
          switchMap(() => {
            request = this.setAccessToken(request);
            return next.handle(request);
          })
        );
      }
    ));
  }

  /**
   * Gets access token and sets it to the `Authorization`-header of the request if found.
   */
  private setAccessToken<T>(request: HttpRequest<T>): HttpRequest<T> {
    const accessToken: string = this.oauthService.getAccessToken();

    if (accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    }

    return request;
  }

  private get tokenRenewal$(): Observable<OAuthEvent> {
    // Use active shareReplay'd request if it exists
    if (!this._tokenRenewal$) {
      // Use ofVoid() and tap() to ensure renewal starts only after this observable is subscribed to
      // Reset the renewal variable after it completion.
      this._tokenRenewal$ = ofVoid().pipe(
        switchMap(() => this.oauthService.silentRefresh()),
        finalize(() => {
          this._tokenRenewal$ = null;
        }),
        shareReplay(1)
      );
    }

    return this._tokenRenewal$;
  }
}
