import { Renderer2 } from '@angular/core';
import { merge, Observable, SubscriptionLike } from 'rxjs';
import { delay, filter, map, share } from 'rxjs/operators';

export class Trigger {
  public constructor(public open: string, public close?: string) {
    if (!close) {
      this.close = open;
    }
  }

  public isManual(): boolean {
    return this.open === 'manual' || this.close === 'manual';
  }
}

const DEFAULT_ALIASES = {
  hover: ['mouseenter', 'mouseleave'],
  focus: ['focusin', 'focusout']
};

export function parseTriggers(triggers: string, aliases: any = DEFAULT_ALIASES): Trigger[] {
  const trimmedTriggers = (triggers || '').trim();

  if (trimmedTriggers.length === 0) {
    return [];
  }

  const parsedTriggers = trimmedTriggers.split(/\s+/).map(trigger => trigger.split(':')).map(triggerPair => {
    const alias: any[] = aliases[triggerPair[0]] || triggerPair;
    return new Trigger(alias[0], alias[1]);
  });

  const manualTriggers = parsedTriggers.filter(triggerPair => triggerPair.isManual());

  if (manualTriggers.length > 1) {
    throw new Error('Triggers parse error: only one manual trigger is allowed');
  }

  if (manualTriggers.length === 1 && parsedTriggers.length > 1) {
    throw new Error('Triggers parse error: manual trigger can\'t be mixed with other triggers');
  }

  return parsedTriggers;
}

export function observeTriggers(
  renderer: any,
  nativeElement: any,
  triggers: Trigger[],
  isOpenedFn: () => boolean
): Observable<boolean> {
  return new Observable<boolean>(subscriber => {
    const listeners: any[] = [];
    const openFn = (): void => subscriber.next(true);
    const closeFn = (): void => subscriber.next(false);
    const toggleFn = (): void => subscriber.next(!isOpenedFn());

    triggers.forEach((trigger: Trigger) => {
      if (trigger.open === trigger.close) {
        listeners.push(renderer.listen(nativeElement, trigger.open, toggleFn));
      } else {
        listeners.push(
          renderer.listen(nativeElement, trigger.open, openFn),
          renderer.listen(nativeElement, trigger.close, closeFn)
        );
      }
    });

    return () => {
      listeners.forEach(unsubscribeFn => unsubscribeFn());
    };
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const delayOrNoop = <T>(time: number) => time > 0 ? delay<T>(time) : (a: Observable<T>) => a;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function triggerDelay(openDelay: number, closeDelay: number, isOpenedFn: () => boolean) {
  return (input$: Observable<boolean>) => {
    let pending: any = null;
    const filteredInput$ = input$.pipe(
      map(open => ({ open })), filter(event => {
        const currentlyOpen = isOpenedFn();
        if (currentlyOpen !== event.open && (!pending || pending.open === currentlyOpen)) {
          pending = event;
          return true;
        }
        if (pending && pending.open !== event.open) {
          pending = null;
        }
        return false;
      }),
      share()
    );
    const delayedOpen$ = filteredInput$.pipe(filter(event => event.open), delayOrNoop(openDelay));
    const delayedClose$ = filteredInput$.pipe(filter(event => !event.open), delayOrNoop(closeDelay));
    return merge(delayedOpen$, delayedClose$)
      .pipe(
        filter(event => {
          if (event === pending) {
            pending = null;
            return event.open !== isOpenedFn();
          }
          return false;
        }),
        map(event => event.open)
      );
  };
}

export function listenToTriggers(
  renderer: Renderer2,
  nativeElement: HTMLElement,
  triggers: string,
  isOpenedFn: () => boolean,
  openFn: () => void,
  closeFn: () => void,
  openDelay: number = 0,
  closeDelay: number = 0
): SubscriptionLike {
  const parsedTriggers = parseTriggers(triggers);

  if (parsedTriggers.length === 1 && parsedTriggers[0].isManual()) {
    return { closed: true, unsubscribe: () => { } };
  }

  const subscription = observeTriggers(renderer, nativeElement, parsedTriggers, isOpenedFn)
    .pipe(triggerDelay(openDelay, closeDelay, isOpenedFn))
    .subscribe(open => (open ? openFn() : closeFn()));

  return subscription;
}
