<div>
  <strong *ngIf="!week">
    {{ dataItem.timestamp | kendoDate:'D' | titlecase }}
  </strong>
  <strong *ngIf="week && week !== 1000">
    {{ year }} {{ 'TIMESPAN.WEEK' | translate }} - {{ week }} ( {{ weekStartDate | kendoDate:'d' }} - {{ weekEndDate | kendoDate: 'd' }} )
  </strong>
</div>
<div *ngIf="time">
  <strong>
    {{ 'HOUR' | translate }}: {{time}}<br>
  </strong>
  {{ dataItem.value | kendoNumber:{ maximumFractionDigits: model.valueUnit.decimals } }}
  {{ model.valueUnit.unit }}
</div>
<div *ngIf="!time">
  {{ dataItem.value | kendoNumber:{ maximumFractionDigits: model.valueUnit.decimals } }}
  {{ model.valueUnit.unit }}/{{ model.resolution | isoDurationName | lowercase }}
</div>
<div>
  {{ dataItem.temperature | kendoNumber:{ maximumFractionDigits: model.temperatureUnit.decimals } }}
  {{ model.temperatureUnit.unit }}
</div>
