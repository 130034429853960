<ek-filterable-combo
  [items]="unitOptions$ | async"
  [virtual]="true"
  [disabled]="disabled"
  [filterable]="true"
  [noTranslate]="true"
  [(ngModel)]="value"
  (ngModelChange)="valueChanged($event)"
>
  <ng-template
    ekComboItemTemplate
    let-dataItem
  >
    <span *ngIf="dataItem.unit.quantityId; else name">
      {{ dataItem.unit.source }} |
      {{ dataItem.unit.year }} |
      <quantity-icon [quantity]="dataItem.unit.quantityId"></quantity-icon>
      {{ dataItem.unit.quantityId | quantityName | async }} |
      {{ dataItem.unit | griCo2factor }}
    </span>
    <ng-template #name>
      <span>
        {{ dataItem.unit?.name }} | {{ dataItem.unit | griCo2factor }}
      </span>
    </ng-template>
  </ng-template>
</ek-filterable-combo>
