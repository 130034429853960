import * as MeteringTypes from '../constants/metering-types';

const $inject = ['utils'];

class ManualQaMeteringService {
  constructor(utils) {
    this.utils = utils;
  }

  /**
   * Returns metering types
   *
   * * @returns {Array}
   */
  getMeteringTypes() {
    return [
      { id: MeteringTypes.AUTOMATIC, name: this.utils.localizedString('MRC.TYPES.1') },
      { id: MeteringTypes.MANUAL_READING, name: this.utils.localizedString('MRC.TYPES.2') },
      { id: MeteringTypes.CONSTANT, name: this.utils.localizedString('MRC.TYPES.3') },
      { id: MeteringTypes.VIRTUAL, name: this.utils.localizedString('MRC.TYPES.4') },
      { id: MeteringTypes.MANUAL_CONSUMPTION, name: this.utils.localizedString('MRC.TYPES.5') },
    ];
  }
}

ManualQaMeteringService.$inject = $inject;

export default ManualQaMeteringService;
