import moment from 'moment';

import mrcConsumptionCellTemplateModule from 'raw-loader!../templates/mrc-consumption-cell.html';
import mrcMonthlyConsumptionInputTemplateModule from 'raw-loader!../templates/mrc-monthly-consumption-input.html';

mrcConsumptionCell.$inject = [
  '$compile', '$rootScope', '$timeout', '$templateCache', 'mrcDataParser', '_',
  'MrcConstants'
];

export function mrcConsumptionCell(
  $compile, $rootScope, $timeout, $templateCache, mrcDataParser, _,
  MrcConstants
) {

  function link(scope, element) {
    scope.MrcConstants = MrcConstants;

    let $popupContainer = void 0;
    let $newValueInput = element.find("input[name='newValueInput']");
    let $firstElem = void 0;
    let $lastElem = void 0;
    let $inputField = void 0;
    let popupOpen = false;
    scope.cellIdx = scope.$index;
    let myIdx = scope.meter.Id + '_' + scope.cellIdx;
    let inputValid = true;
    let openNext = false;
    scope.unconfirmedInputValue = void 0;
    scope.inputVisible = false;
    scope.hasInputError = false;
    scope.selected = false;
    scope.inputValue = '';
    scope.newInputValue = '';
    scope.canSaveIntervals = false;
    scope.intervals = [];

    scope.popupState = {
      addMonthValueSwitch: false,
      monthValuesLoading: false,
      invalidInputValues: {},
      watchers: {
        top: void 0,
        left: void 0
      }
    };

    scope.getDateStringForMonth = function() {
      let d = new Date();
      let options = { month: 'long' };
      d.setDate(1);
      d.setMonth(scope.cellIdx);
      let month = d.toLocaleDateString(scope.mrcState.userLang, options);
      return _.capitalize(month);
    };

    function hideInputPopup() {
      window.onclick = null;
      if ($popupContainer) {
        $popupContainer.off('focus');
        $popupContainer.off('keyup');
      }
      if ($inputField) {
        $inputField.off('focus');
      }
      if ($firstElem) {
        $firstElem.off('focus');
      }
      if ($lastElem) {
        $lastElem.off('focus');
      }
      scope.popupState.watchers.top();
      scope.popupState.watchers.left();
      $popupContainer.remove();
      scope.inputValue = '';
      popupOpen = false;
      scope.selected = false;
      if (openNext) {
        $rootScope.$broadcast('popupPrompt', { meterId: scope.meter.Id, idx: scope.cellIdx + 1 });
        openNext = false;
      }
    }

    function positionChanged(n, o) {
      if (Math.abs(n - o) > 1 && $popupContainer) {
        let adjust = (parseInt($popupContainer.css('width'), 10) / 2) - (element[0].clientWidth / 2) + 5;
        let top = element.offset().top + parseInt(element.css('min-height'), 10);
        let left = element.offset().left - adjust;
        $timeout(function() {
          $popupContainer.css({ 'top': top + 'px', 'left': left + 'px' });
          scope.$apply();
        }, 300);
      }
    }

    function bindPositionWatchers() {
      scope.popupState.watchers.top = scope.$watch(function() {
        return element.offset().top;
      },
      function(n, o) {
        if (popupOpen) {
          positionChanged(n, o);
        }
      }
      );
      scope.popupState.watchers.left = scope.$watch(function() {
        return element.offset().left;
      },
      function(n, o) {
        if (popupOpen) {
          positionChanged(n, o);
        }
      }
      );
    }

    function showInputPopup() {
      popupOpen = true;
      scope.popupState.addMonthValueSwitch = false;
      scope.intervals = [];
      if (!scope.values[scope.cellIdx].IsCompleteMonth &&
        scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
        scope.popupState.monthValuesLoading = true;
        scope.collectMissingIntervalsForMonth(scope.cellIdx).then(function(intervals) {
          _.each(intervals, function(interval) {
            scope.intervals.push({ timef: interval, value: '' });
          });
          scope.popupState.monthValuesLoading = false;
        });
      }

      scope.inputValue = _.isNumber(scope.values[scope.cellIdx].Value) ?
        scope.values[scope.cellIdx].Value.toFixed(2) :
        null
      ;

      $popupContainer = angular.element(mrcMonthlyConsumptionInputTemplateModule);
      $compile($popupContainer)(scope);
      angular.element(document.body).append($popupContainer);
      let adjust = (parseInt($popupContainer.css('width'), 10) / 2) - (element[0].clientWidth / 2) + 5;
      let top = element.offset().top + parseInt(element.css('min-height'), 10);
      let left = element.offset().left - adjust;
      $popupContainer.css({ 'top': top + 'px', 'left': left + 'px' });
      $timeout(function() {
        $inputField = $popupContainer.find("input[name='valueInput']");
        if ($inputField) {
          $inputField.on('focus', function(e) {
            $(this).select();
          });
          if (scope.intervals.length === 0) {
            $inputField.focus();
          }
        }
        $firstElem = $('#firstElem');
        $lastElem = $('#lastElem');
        let $cancelButton = $("#cancelBtn");
        if (angular.isDefined($firstElem) && angular.isDefined($lastElem)) {
          $firstElem.on('focus', function() {
            if (angular.isDefined($cancelButton)) {
              $cancelButton.focus();
            }
          });
          $lastElem.on('focus', function() {
            if (angular.isDefined($inputField)) {
              $inputField.focus();
            }
          });
        }
        window.onclick = function() {
          if (popupOpen) {
            hideInputPopup();
            scope.$apply();
          }
        };
      }, 100);
      $popupContainer.on('keyup', e => {
        // enter
        if (e.keyCode === 13 && inputValid) {
          openNext = true;
          if (scope.intervals.length > 0 && !scope.popupState.addMonthValueSwitch) {
            scope.saveMonthlyIntervals();
          } else {
            scope.saveValue(scope.cellIdx);
          }
        } else {
          if (e.keyCode === 27) {
            scope.cancel();
          }
        }
      });
      bindPositionWatchers();
    }

    scope.cancel = function() {
      hideInputPopup();
    };
    scope.saveValue = function(cellIndex) {
      let parsed = angular.copy(scope.inputValue);

      if (angular.isString(parsed)) {
        parsed = parsed.trim().length <= 0 ? null : parsed;
        if (parsed !== null) {
          parsed = parseFloat(parsed.replace(',', scope.mrcState.radix));
        }
      }

      let vals = [{ idx: cellIndex, value: parsed }];
      let props = { meterId: scope.meter.Id, convert: scope.meter.convertValues };
      scope.hasInputError = false;
      let silent = scope.intervals.length === 0;
      if (silent) {
        scope.unconfirmedInputValue = parsed;
        scope.values[scope.getValueIndexByCellIndex(scope.cellIdx)].Value = scope.unconfirmedInputValue;
      }
      scope.$emit('monthlyValuesUpdate', { values: vals, properties: props, silent: silent });
      hideInputPopup();
    };

    scope.$watch('facility.selectedYear', function(n, o) {
      if (n !== o) {
        if ($newValueInput !== undefined && $newValueInput.val().length > 0) {
          $newValueInput.val("");
        }
        scope.unconfirmedInputValue = undefined;
      }
    });

    let promptListener = $rootScope.$on('popupPrompt', function(e, attr) {
      if (scope.meter.Id === attr.meterId && attr.idx === scope.cellIdx) {
        scope.select(scope.meter.Id, scope.cellIdx, false);
      }
    });

    scope.$watch('facility.selectedCell', function(n) {
      if (n !== myIdx) {
        scope.selected = false;
        scope.inputVisible = false;
        if (popupOpen) {
          hideInputPopup();
        }
      }
    });

    scope.$watch('popupState.addMonthValueSwitch', function(n) {
      if (n && $inputField) {
        $timeout(function() {
          $inputField.focus();
        }, 100);
      } else {
        if ($popupContainer) {
          $timeout(function() {
            let $intervalInputField = $popupContainer.find("input[name='intervalInput[0]']");
            if ($intervalInputField) {
              $intervalInputField.focus();
            }
          }, 100);
        }
      }
    });

    scope.$watch('popupState.monthValuesLoading', function(n, o) {
      if (!n && scope.intervals.length > 0) {
        $timeout(function() {
          let $intervalInputField = $popupContainer.find("input[name='intervalInput[0]']");
          if ($intervalInputField) {
            $intervalInputField.focus();
          }
        }, 100);
      }
    });

    scope.saveMonthlyIntervals = function() {
      if (scope.intervals.length > 0) {
        let vals = [];
        let props = { meterId: scope.meter.Id, convert: scope.meter.convertValues };
        _.each(scope.intervals, function(interval) {
          if (interval.value && (!angular.isString(interval.value) || interval.value.length > 0)) {
            let parsed = angular.copy(interval.value);
            if (angular.isString(parsed)) {
              parsed = parseFloat(parsed.replace(',', scope.mrcState.radix));
            }
            vals.push({ timeFrame: interval.timef, value: parsed });
          }
        });
        if (vals.length > 0) {
          scope.$emit('missingIntervalValuesUpdate', { values: vals, properties: props });
          // scope.$emit('updateIntervalsListing', {meterId:scope.meter.Id});
          hideInputPopup();
        }
      }
    };

    scope.resetInvalidInputValues = function() {
      scope.popupState.invalidInputValues = {};
    };

    scope.hasInvalidInputValues = function() {
      let ret = false;
      Object.keys(scope.popupState.invalidInputValues).forEach(function(key) {
        if (scope.popupState.invalidInputValues[key]) {
          ret = true;
          return ret;
        }
      });
      return ret;
    };

    scope.hasIntervalValues = function() {
      let ret = false;
      _.each(scope.intervals, function(interval) {
        if (interval.value && interval.value.length > 0) {
          ret = true;
          return ret;
        }
      });
      return ret;
    };

    scope.select = function(meterId, focused) {
      if (scope.dataReady && scope.values[scope.cellIdx].IsEditable) {
        scope.selected = true;
        scope.facility.selectedCell = myIdx;
        if (!popupOpen && scope.values[scope.cellIdx].Value !== void 0 &&
          scope.values[scope.cellIdx].Value !== null) {
          showInputPopup();
        } else {
          scope.inputVisible = true;
          if (!focused) {
            $newValueInput.focus();
          }
        }
      }
    };

    $newValueInput.on('keyup', function(evt) {
      if (evt.keyCode === 13) {
        $newValueInput.blur();
      }
    });

    $newValueInput.on('blur', function() {
      scope.selected = false;
      scope.inputVisible = false;
    });

    scope.$on('monthValueUpdateError', function(e, params) {
      if (params.meterId === scope.meter.Id && params.cellIdx === scope.cellIdx) {
        scope.hasInputError = true;
      }
    });

    scope.$on('unconfirmedValuesUpdate', function(e, params) {
      if (angular.isDefined(params.values) && params.values.length > 0) {
        scope.hasInputError = false;
        if (scope.meter.convertValues) {
          params.values[scope.cellIdx].value = mrcDataParser.convertReading(params.values[scope.cellIdx].value, 0.001);
        }
        scope.values[scope.cellIdx].Value = params.values[scope.cellIdx].value;
        scope.unconfirmedInputValue = params.values[scope.cellIdx].value;
      }
    });

    scope.$on('monthValueSaved', function(e, params) {
      if (params.meterId === scope.meter.Id &&
        params.cellIdx === scope.cellIdx &&
        params.targetYear === scope.facility.selectedYear) {
        if (angular.isDefined(scope.values[params.cellIdx])) {
          scope.values[params.cellIdx].Value = params.value;
          if (angular.isUndefined(scope.values[params.cellIdx].IsCompleteMonth) ||
            scope.values[params.cellIdx].IsCompleteMonth === false) {
            scope.values[params.cellIdx].IsCompleteMonth = true;
          }
          if (angular.isDefined(scope.unconfirmedInputValue)) {
            scope.unconfirmedInputValue = void 0;
          }
          if (angular.isDefined($newValueInput)) {
            $newValueInput.val("");
          }
        }
        scope.hasInputError = false;
      }
    });

    scope.$on('meterValuesReceived', function(e, params) {
      if (params.id === scope.meter.Id && params.values !== void 0) {
        scope.unconfirmedInputValue = void 0;
        scope.hasInputError = false;
      }
    });

    scope.$on('inputValueChanged', function(e, params) {
      if (params.name === 'newValueInput') {
        if (scope.hasInputError && scope.newInputValue.length == 0) {
          scope.hasInputError = false;
        }
        let validity = params.valid;
        if (validity || (validity !== inputValid)) {
          let data = { meterId: scope.meter.Id, cellIndex: scope.cellIdx, value: scope.newInputValue,
            convert: scope.meter.convertValues, valid: validity };
          scope.$emit('unsavedValueChange', data);
        }
        inputValid = validity;
      } else if (params.name === 'valueInput' || params.name.startsWith('intervalInput')) {
        if (params.name === 'valueInput' && params.value !== void 0 && popupOpen) {
          scope.inputValue = params.value;
          inputValid = params.valid;
        }
        if (params.name.startsWith('intervalInput') && params.value !== void 0) {
          scope.canSaveIntervals = scope.hasIntervalValues();
        }
        if (!params.valid) {
          scope.popupState.invalidInputValues[params.name] = true;
        } else {
          if (scope.popupState.invalidInputValues[params.name]) {
            scope.popupState.invalidInputValues[params.name] = false;
          }
        }
      }
    });

    scope.$on('intervalInputModeActivated', function() {
      if (angular.isDefined($newValueInput)) {
        $newValueInput.val("");
      }
    });

    scope.$on('$destroy', function() {
      if (popupOpen) {
        hideInputPopup();
      }
      if ($inputField) {
        $inputField.off('focus');
      }
      $newValueInput.off('blur');
      $newValueInput.off('keyup');
      promptListener();
    });

    scope.afterAutomaticReadingStartDate = function() {
      if (scope.meter.MeteringType === 1) {
        if (!scope.meter.AutomaticReadingStartTime) {
          return true;
        }
        let date1 = moment(scope.meter.AutomaticReadingStartTime).startOf('day');
        let date2 = moment().day(1).month(scope.cellIdx).year(scope.facility.selectedYear);
        return date2.isSameOrAfter(date1);
      }
      return false;
    };

  }

  return {
    template: mrcConsumptionCellTemplateModule,
    replace: true,
    controller: function() {},
    restrict: 'A',
    link: link
  };
}
