import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ColumnBase, ColumnComponent } from '@progress/kendo-angular-treelist';

import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility, ReportingSeriesCollection } from '../shared/reporting-series-collection';
import { ReportingData } from './reporting-data-service-base';
import { ReportingMeterDataService } from './reporting-meter-data.service';
import { ReportType } from '../shared/report-type';

export type MeterInfoColumnVisibilityByQuantity = {
  [quantityId: number]: {
    [field: string]: {
      hidden: boolean
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class MeterTableReportService {
  public readonly meterInfoColumnVisibility$: Observable<MeterInfoColumnVisibilityByQuantity>;
  public readonly gridFilters$: Observable<{[quantityId: number]: CompositeFilterDescriptor}>;
  public readonly gridSorts$: Observable<{[quantityId: number]: SortDescriptor[]}>;

  private readonly _meterInfoColumnVisibility$ = new BehaviorSubject<MeterInfoColumnVisibilityByQuantity>(null);
  private readonly _gridFilters$ = new BehaviorSubject<{[quantityId: number]: CompositeFilterDescriptor}>(null);
  private readonly _gridSorts$ = new BehaviorSubject<{[quantityId: number]: SortDescriptor[]}>(null);

  public constructor(
    private readonly reportingMeterDataService: ReportingMeterDataService
  ) {
    this.meterInfoColumnVisibility$ = this._meterInfoColumnVisibility$.asObservable();
    this.gridFilters$ = this._gridFilters$.asObservable();
    this.gridSorts$ = this._gridSorts$.asObservable();
  }

  public setGridMeterInfoColumns(quantityId: number, columns: ColumnBase[]): void {
    const cols = columns.filter(column => !column.isColumnGroup) as ColumnComponent[];
    this._meterInfoColumnVisibility$.next({
      ...this._meterInfoColumnVisibility$.value,
      [quantityId]: cols.toRecord(col => col.field, col => ({ hidden: col.hidden }))
    });
  }

  public setGridFilters(quantityId: number, filters: CompositeFilterDescriptor): void {
    this._gridFilters$.next({
      ...this._gridFilters$.value,
      [quantityId]: filters
    });
  }

  public setGridSorts(quantityId: number, sorts: SortDescriptor[]): void {
    this._gridSorts$.next({
      ...this._gridSorts$.value,
      [quantityId]: sorts
    });
  }

  public resetGridState(): void {
    this._meterInfoColumnVisibility$.next(null);
    this._gridFilters$.next(null);
    this._gridSorts$.next(null);
  }

  public getData(
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection
  ): Observable<ReportingSeriesByFacility> {
    return forkJoin({
      measured: forkJoin([
        this.reportingMeterDataService.getMeasuredValues(ReportType.Table, params, facilityId, meters),
      ]),
      normalized: forkJoin([
        this.reportingMeterDataService.getNormalizedValues(ReportType.Table, params, facilityId, meters),
      ]),
    }).pipe(
      map(({ measured, normalized }) => meters.meterIds.toRecord(
        mId => mId,
        mId => {
          const measuredValues = this.getSeriesValues({ id: mId, values: measured.flat(), isNormalized: false });
          const normalizedValues = this.getSeriesValues({ id: mId, values: normalized.flat(), isNormalized: true });
          return [...measuredValues, ...normalizedValues];
        }
      ))
    );
  }

  private getSeriesValues({
    id,
    values,
    isNormalized
  }: {
    id: number
    values: ReportingData[],
    isNormalized: boolean
  }): ReportingSeriesCollection[] {
    return values.filterMap(
      s => s.series[id],
      s => new ReportingSeriesCollection({
        quantityId: s.quantityId,
        series: s.series[id],
        isNormalized
      })
    );
  }
}
