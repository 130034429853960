import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import isMobile from 'ismobilejs';

import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';
import { ActionType } from '@enerkey/clients/attachments';

import { FacilityCommentsWidgetOptions } from '../facility-comments-widget/facility-comments-widget.component';

type ChangeFn = (options: FacilityCommentsWidgetOptions) => void;

type FacilityCommentsWidgetOptionsForm = {
  consumption: boolean;
  other: boolean;
  count: number;
  mobileCount: number;
  facilityId: number;
}

@Component({
  selector: 'facility-comments-widget-options',
  templateUrl: './facility-comments-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilityCommentsWidgetOptionsComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => FacilityCommentsWidgetOptionsComponent),
  }]
})
export class FacilityCommentsWidgetOptionsComponent implements ControlValueAccessor, OnInit, Validator {

  public readonly showMobileCount: boolean = isMobile().any;
  public readonly minCount: number = 10;
  public readonly maxCount: number = 200;

  @Input() public initialState: FacilityCommentsWidgetOptions;

  public controls: ControlsOf<FacilityCommentsWidgetOptionsForm>;
  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public ngOnInit(): void {
    this.controls = formControlsFrom<FacilityCommentsWidgetOptionsForm>(
      this.optionsToForm(this.initialState), {
        facilityId: Validators.required
      }
    );
    this.formGroup = new UntypedFormGroup(this.controls);

    this.formGroup.valueChanges.subscribe((formValue: FacilityCommentsWidgetOptionsForm) => {
      this._onChange?.(this.formToOptions(formValue));
    });
  }

  public writeValue(value: FacilityCommentsWidgetOptions): void {
    this.formGroup.setValue(
      this.optionsToForm(value)
    );
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.formGroup.invalid) {
      return {
        required: true
      };
    }
    return null;
  }

  private optionsToForm(options: FacilityCommentsWidgetOptions): FacilityCommentsWidgetOptionsForm {
    return {
      count: options.numberToShow,
      mobileCount: options.numberToShowMobile,
      consumption: options.selectedCommentTypes[ActionType.KE],
      other: options.selectedCommentTypes[ActionType.K],
      facilityId: options.facilityId
    };
  }

  private formToOptions(formValue: FacilityCommentsWidgetOptionsForm): FacilityCommentsWidgetOptions {
    return {
      typeToShow: 'comments',
      numberToShow: formValue.count,
      numberToShowMobile: formValue.mobileCount,
      selectedCommentTypes: {
        [ActionType.K]: !!formValue.other,
        [ActionType.KE]: !!formValue.consumption,
      },
      facilityId: formValue.facilityId
    };
  }
}
