<modal-view-header
  [headingText]="'ADMIN.BULK_SEND_EMAIL.BULK_SEND_EMAIL_BUTTON' | translate"
></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="massEmailForm"
    (ngSubmit)="submit()"
    id="massEmailForm"
  >
    <label class="k-label">
      <input
        formControlName="addressTypeSelect"
        type="radio"
        kendoRadioButton
        [value]="AddressTypes.cc"
      />
      <span translate="ADMIN.BULK_SEND_EMAIL.ADDRESS_TYPE_CC"></span>
    </label>
    <label class="k-label">
      <input
        formControlName="addressTypeSelect"
        type="radio"
        kendoRadioButton
        [value]="AddressTypes.bcc"
      />
      <span translate="ADMIN.BULK_SEND_EMAIL.ADDRESS_TYPE_BCC"></span>
    </label>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="massEmailForm"
    >
      {{ 'ADMIN.BULK_SEND_EMAIL.OK_BUTTON' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
