import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { NgfTabChangeEvent, NgfTabsetComponent } from '@enerkey/foundation-angular';

import { QuantityOrMeterReport, ReportModalService } from '../../services/report-modal.service';

@Component({
  selector: 'modal-report-type',
  templateUrl: './modal-report-type.component.html',
  styleUrls: ['./modal-report-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalReportTypeComponent implements AfterViewInit {
  @ViewChild(NgfTabsetComponent) public readonly tabset: NgfTabsetComponent;

  public readonly ReportType = QuantityOrMeterReport;

  public readonly reportType$: Observable<QuantityOrMeterReport>;

  public constructor(private readonly reportModalService: ReportModalService) {
    this.reportType$ = reportModalService.quantityOrMeterReport$;
  }

  public ngAfterViewInit(): void {
    this.tabset.tabChange.subscribe({
      next: (tab: NgfTabChangeEvent) => {
        this.reportModalService.changeMeterOrQuantityReport(tab.nextId as QuantityOrMeterReport);
      }
    });
  }
}
