import ManualQaChartController from './manual-qa-chart-controller';
import template                from 'raw-loader!./manual-qa-chart.html';

export default {
  template: template,
  controllerAs: 'vm',
  controller: ManualQaChartController,
  bindings: {
    dataSource: '<',
    viewTimeFrame: '<',
    meter: '<',
    defects: '<',
    isPreviewMode: '<',
    isLoading: '<',
    fetchType: '<',
    useComparisonPeriod: '<',
    onPlotAreaClick: '&'
  }
};
