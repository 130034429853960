import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { LoadingSubject } from '@enerkey/rxjs';

const LOADING_SINGLETON = new LoadingSubject();

export const LOADING_SUBJECT = new InjectionToken<LoadingSubject>(
  'Angular subject for web requests',
  {
    providedIn: 'root',
    factory: () => LOADING_SINGLETON,
  }
);

@Injectable()
export class LoadingService {
  public readonly isLoading$: Observable<boolean> = LOADING_SINGLETON.asObservable();
}
