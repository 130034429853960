import SearchTypeRadioController from './search-type-radio-controller';
import template                  from 'raw-loader!./search-type-radio.html';

export default {
  template: template,
  controller: SearchTypeRadioController,
  controllerAs: 'vm',
  bindings: {
    searchType: '<',
    subSearchType: '<',
    onChange: '&',
    defectTypes: '<'
  }
};
