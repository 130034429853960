import { ConsumptionItem, ConsumptionsGroupedResponse } from '@enerkey/clients/energy-reporting';
import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { Quantities } from '@enerkey/clients/metering';
import { percentageChange } from '@enerkey/ts-utils';
import { ChangeWidgetData, ChangeWidgetValueKey } from '../components/change-widget-base';

export type GroupedConsumptionsWidgetRow = {
  comparisonPeriod: number;
  inspectionPeriod: number;
  relativeChange: number;
  absoluteChange: number;
  name: string;
  facilitiesExcluded: number[];
  facilitiesIncluded: number[];
}

export class GroupedConsumptions {
  public static getWidgetRows(
    data: ConsumptionsGroupedResponse,
    quantityId: Quantities,
    valueKey: ChangeWidgetValueKey,
    variableId: number,
    start: TimeFrameResult
  ): ChangeWidgetData<GroupedConsumptionsWidgetRow> {
    return new GroupedConsumptions(data, quantityId, valueKey, variableId, start).widgetRows;
  }

  private readonly comparisonPeriodKey: string;
  private readonly inspectionPeriodKey: string;
  private readonly widgetRows: ChangeWidgetData<GroupedConsumptionsWidgetRow>;

  private constructor(
    private readonly data: ConsumptionsGroupedResponse,
    private readonly quantityId: Quantities,
    private readonly valueKey: ChangeWidgetValueKey,
    private readonly variableId: number,
    private readonly start: TimeFrameResult
  ) {
    this.comparisonPeriodKey = this.start.Start[1].key;
    this.inspectionPeriodKey = this.start.Start[0].key;

    this.widgetRows = this.getWidgetRows();
  }

  private getWidgetRows(): ChangeWidgetData<GroupedConsumptionsWidgetRow> {
    const gainers = this.dataToWidgetRows('Ascending');
    const fallers = this.dataToWidgetRows('Descending');
    return { gainers, fallers };
  }

  private dataToWidgetRows(ascOrDesc: 'Ascending' | 'Descending'): GroupedConsumptionsWidgetRow[] {
    const quantityData = this.data[ascOrDesc][this.quantityId];

    return Object.keys(quantityData).map(name => {
      const facilitiesExcluded = this.getFacilities(this.data.FacilitiesExcluded, name);
      const facilitiesIncluded = this.getFacilities(this.data.FacilitiesIncluded, name);

      const value = quantityData[name];
      const comparisonPeriod = this.getPeriodValue(value, this.comparisonPeriodKey);
      const inspectionPeriod = this.getPeriodValue(value, this.inspectionPeriodKey);
      return {
        name,
        comparisonPeriod,
        inspectionPeriod,
        facilitiesIncluded,
        facilitiesExcluded,
        relativeChange: percentageChange(inspectionPeriod, comparisonPeriod),
        absoluteChange: inspectionPeriod - comparisonPeriod
      };
    });
  }

  private getPeriodValue(
    data: { [key: string]: ConsumptionItem },
    periodKey: string
  ): number {
    return this.variableId
      ? data[periodKey]?.RelationalValues[this.variableId][this.valueKey] ?? null
      : data[periodKey]?.[this.valueKey] ?? null;
  }

  private getFacilities(
    data: ConsumptionsGroupedResponse['FacilitiesExcluded' | 'FacilitiesIncluded'],
    name: string
  ): number[] {
    return data[this.quantityId][name] ?? [];
  }
}
