import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { ControlsOf } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ComboItem } from '../../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ExtendedFacilityInformation } from '../../../../../shared/interfaces/extended-facility-information';
import { FacilityService } from '../../../../../shared/services/facility.service';
import { ETCurveSearchParams } from '../../../models/et-curve.model';

@Component({
  selector: 'et-curve-facility-list',
  templateUrl: './et-curve-facility-list.component.html',
  styleUrls: ['./et-curve-facility-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EtCurveFacilityListComponent implements OnInit, OnDestroy {
  @Input() public showFacilityLink = true;
  @Output() public readonly facilityLinkClick = new EventEmitter<void>();
  public controls: ControlsOf<ETCurveSearchParams>;

  public readonly facilities$: Observable<ExtendedFacilityInformation[]>;
  public readonly facilityOptions$: Observable<ComboItem<number>[]>;
  public readonly loadingFacilities$: Observable<boolean>;

  private readonly _loadingFacilities = new LoadingSubject();
  private readonly _destroy = new Subject<void>();

  public constructor(
    public readonly facilityService: FacilityService,
    public readonly formGroup: FormGroupDirective
  ) {
    this.loadingFacilities$ = this._loadingFacilities.asObservable();

    this.facilities$ = this.facilityService.filteredProfileFacilities$.pipe(
      take(1),
      takeUntil(this._destroy)
    );

    this.facilityOptions$ = this.facilities$.pipe(
      take(1),
      map(facilities => facilities.map(facility => ({
        text: facility.name,
        value: facility.facilityId,
      })).sortBy('text')),
      indicate(this._loadingFacilities),
      takeUntil(this._destroy)
    );
  }

  public ngOnInit(): void {
    this.controls = this.formGroup.control.controls as ControlsOf<ETCurveSearchParams>;
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loadingFacilities.complete();
  }
}
