import { isDevMode, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export abstract class AsyncEnumTranslatePipe<TEnum extends string | number> implements PipeTransform {

  protected readonly abstract translations: Record<TEnum, string>;

  public constructor(
    protected readonly translateService: TranslateService
  ) {
    /* istanbul ignore next */
    if (!this.translateService) {
      throw Error(
        'Got falsy TranslateService in AsyncEnumTranslatePipe constructor, did you forget to ' +
        'add a constructor that calls super()? (required by Angular DI)'
      );
    }
  }

  public transform(value: TEnum | null | undefined): Observable<string> {
    if (value !== null && value !== undefined) {
      if (value in this.translations) {
        return this.translateService.get(this.translations[value]);
      }
      if (isDevMode()) {
        // eslint-disable-next-line no-console
        console.warn(`Invalid ${typeof value} value for pipe ${this.constructor.name}:`, value);
      }
    }

    return of('');
  }
}
