import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { QuantityItem } from '@enerkey/clients/energy-reporting';
import { Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ReportingUnit } from '@enerkey/clients/reporting';

import TimeFrameOptions from '../../../../constants/time-frame';
import { Comparability } from '../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { QuantityService } from '../../../../shared/services/quantity.service';

import { ErTimeFrameService } from '../../../energy-reporting/services/er-time-frame.service';
import { WidgetChangeOption } from '../../../energy-reporting/shared/widget-constants';
import { GroupedConsumptionsService } from '../../services/grouped-consumptions.service';
import { GroupedConsumptionsWidgetRow } from '../../shared/grouped-consumptions';
import { WidgetBase } from '../../shared/widget-base.interface';
import { ChangeWidgetComponentBase, ChangeWidgetResponse } from '../change-widget-base';
import { FacilityService } from '../../../../shared/services/facility.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';

export interface GroupedConsumptionsWidgetOptions {
  biggestFallers: number;
  biggestGainers: number;
  changeOption: WidgetChangeOption;
  comparableOption: Comparability;
  comparisonPeriodOption: 'Default' | number;
  groupProperty: string;
  itemProperty: string;
  selectedQuantity: QuantityItem;
  timeFrameOption: TimeFrameOptions;
  unitKey: ReportingUnit;
  valueOption: ValueType;
  variableId: number;
  quantityId?: Quantities;
}

@Component({
  selector: 'grouped-consumptions-widget',
  templateUrl: './grouped-consumptions-widget.component.html',
  styleUrls: ['./grouped-consumptions-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GroupedConsumptionsService]
})
export class GroupedConsumptionsWidgetComponent
  extends ChangeWidgetComponentBase<GroupedConsumptionsWidgetOptions, GroupedConsumptionsWidgetRow>
  implements OnDestroy, OnInit, WidgetBase<GroupedConsumptionsWidgetOptions> {
  public error$: Observable<void>;
  public readonly loading$: Observable<boolean>;

  public override propertyTranslationKey: string;

  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();

  public constructor(
    private readonly widgetService: GroupedConsumptionsService,
    private readonly stateService: StateService,
    quantityService: QuantityService,
    erTimeFrameService: ErTimeFrameService,
    facilityService: FacilityService,
    thresholdService: ThresholdService
  ) {
    super(quantityService, erTimeFrameService, facilityService, thresholdService);

    this.error$ = this._error$.asObservable();
    this.loading$ = this._loading$.asObservable();
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.propertyTranslationKey =
      `${this.dataModelOptions.groupProperty}.${this.dataModelOptions.itemProperty}`.toUpperCase();
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._error$.complete();
    this._loading$.complete();
  }

  public onRowClick(facilityIds: number[]): void {
    this.stateService.go(
      'facilities.report-sum',
      {
        ...this.widgetService.getGroupedConsumptionsTransitionParams(this.dataModelOptions, this.start),
        facilityId: facilityIds
      }
    );
  }

  protected getData(
    quantity: QuantityItem,
    facilityIds: number[],
    threshold: number
  ): Observable<ChangeWidgetResponse<GroupedConsumptionsWidgetRow>> {
    return this.widgetService.getConsumptions(
      quantity, this.dataModelOptions, this.start, facilityIds, threshold
    ).pipe(
      indicate(this._loading$),
      catchError(() => {
        this._error$.next();
        return EMPTY;
      }),
      takeUntil(this._destroy$)
    );
  }
}
