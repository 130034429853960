import { startOfDay } from 'date-fns';

import { RootProperties, TreeViewItem } from '@enerkey/clients/metering';
import { AddOperationType } from '../../admin/shared/add-operation-type';
import { NewFormulaItemFactory, NewFormulaItems } from './new-formula-item-factory';
import { FormulaParen } from './formula-paren';
import { TargetMeter } from './target-meter';
import { FormulaItemSeeker } from './formula-item-seeker';
import { FormulaHelpers } from './formula-helpers';
import { FormulaParenMatchSeeker } from './formula-paren-match-seeker';
import { FormulaItemEdit } from './formula-item-edit';
import { FormulaItemAdd } from './formula-item-add';
import { localToUtc } from '../../../shared/date.functions';

export class Formula {
  private static formulaOverlapsErrorCode = 50;

  private static getNewItems(
    additionType: AddOperationType,
    itemAddContext: TreeViewItem,
    newMeters?: TargetMeter[]
  ): NewFormulaItems {
    return NewFormulaItemFactory.createNewItem(
      additionType,
      itemAddContext,
      newMeters
    );
  }

  public constructor(private formulaData: TreeViewItem[], isNewFormula = false) {
    if (isNewFormula) {
      this.setEffectTimeOfNewFormula();
      this.removeExistingIdsFromFormulaData();
    }
  }

  public get formula(): TreeViewItem[] {
    return this.formulaData;
  }

  public editItem(updated: FormulaItemEdit): void {
    this.formulaData = this.formulaData.map(item => {
      if (item.itemId === updated.itemId) {
        [...updated.params.keys()].forEach(property => {
          (item[property] as any) = updated.params.get(property);
        });
      }

      return item;
    });

    const editedItem = this.formula.find(currentItem => currentItem.itemId === updated.itemId);
    if (FormulaHelpers.isParen(editedItem)) {
      this.handleParenChange(editedItem);
    }
  }

  public deleteItem(itemToDelete: TreeViewItem): void {
    let itemIdsToDelete: number[] = [];
    if (FormulaHelpers.isParen(itemToDelete)) {
      itemIdsToDelete = [itemToDelete.itemId];
      this.outdentParenRelatedContent(itemToDelete);
    } else {
      itemIdsToDelete = FormulaItemSeeker.getDescendantItemIds(this.formulaData, itemToDelete);
    }
    this.formulaData = this.formulaData.filter(currentItem => !itemIdsToDelete.includes(currentItem.itemId));
  }

  public addItems(addition: FormulaItemAdd, newMeters?: TargetMeter[]): void {
    const newItemsInfo = Formula.getNewItems(addition.type, addition.item, newMeters);
    const newIndex = FormulaItemSeeker.indexForNewItem(
      this.formula,
      addition.item,
      newItemsInfo.placeToAdd
    );
    const newItems = newItemsInfo.getNewItems();
    const virtualMeterId = this.getFormulaVirtualMeterId();
    if (virtualMeterId || virtualMeterId === 0) {
      newItems.forEach(item => {
        item.virtualMeterId = virtualMeterId;
      });
    }
    this.formulaData.splice(newIndex, 0, ...newItems);
    if (FormulaHelpers.isParen(newItems[0])) {
      if (this.hasUnclosedParenBeforeItem(newIndex)) {
        newItems[0].content = FormulaParen.ClosedParen;
      }
      this.indentContentBetweenParens(newItems[0], newIndex);
    }
  }

  public get isActive(): boolean {
    return this.formulaData[0] ? this.formulaData[0].rootProperties.isActive : false;
  }

  public set isActive(newValue: boolean) {
    if (this.formulaData[0]) {
      this.formulaData[0].rootProperties.isActive = newValue;
    }
  }

  public get description(): string {
    return this.formulaData[0] ? this.formulaData[0].description : '';
  }

  public set description(value: string) {
    if (this.formulaData[0]) {
      this.formulaData[0].description = value;
    }
  }

  public get hasOverlapWithOtherFormula(): boolean {
    if (
      this.formulaData[0] &&
        this.formulaData[0].validationProperties &&
        this.formulaData[0].validationProperties.validationErrors
    ) {
      return this.formulaData[0].validationProperties.validationErrors.some(
        error => error.key === Formula.formulaOverlapsErrorCode
      );
    }

    return false;
  }

  private setEffectTimeOfNewFormula(): void {
    const firstFormulaItem = this.formulaData[0];
    if (firstFormulaItem) {
      if (!firstFormulaItem.rootProperties) {
        firstFormulaItem.rootProperties = new RootProperties();
      }
      firstFormulaItem.rootProperties.from = localToUtc(startOfDay(new Date())),
      firstFormulaItem.rootProperties.to = null;
    }
  }

  private removeExistingIdsFromFormulaData(): void {
    this.formulaData = this.formulaData.map(item => {
      const { operandId, virtualMeterId, ...itemWithoutVirtualMeterId } = item;

      return new TreeViewItem(itemWithoutVirtualMeterId);
    });
  }

  private handleParenChange(item: TreeViewItem): void {
    const newIndex = FormulaItemSeeker.itemIndexInFormula(item, this.formula);
    this.indentContentBetweenParens(item, newIndex);
  }

  private hasUnclosedParenBeforeItem(newIndex: number): boolean {
    let openParenAmount = 0;
    for (let i = 0; i < this.formula.length && i < newIndex; i++) {
      const currentItem = this.formula[i];
      if (FormulaHelpers.isClosingParen(currentItem)) {
        openParenAmount--;
      } else if (FormulaHelpers.isOpeningParen(currentItem)) {
        openParenAmount++;
      }
    }

    return openParenAmount > 0;
  }

  private indentContentBetweenParens(newItem: TreeViewItem, newIndex: number): void {
    const matchingParenIndex = FormulaParenMatchSeeker.findMatchingParenIndex(newItem, this.formula);
    if (matchingParenIndex < 0) {
      return;
    }
    const start = Math.min(newIndex, matchingParenIndex);
    const end = Math.max(newIndex, matchingParenIndex);
    const openingParensItem = this.formula[start];
    this.formula[end].parentId = openingParensItem.itemId;
    for (let i = start + 1; i < end; i++) {
      const currentItem = this.formula[i];
      if (currentItem.parentId === openingParensItem.parentId) {
        currentItem.parentId = openingParensItem.itemId;
      }
    }
  }

  private getFormulaVirtualMeterId(): number {
    return this.formula[0].virtualMeterId;
  }

  private outdentParenRelatedContent(parenItem: TreeViewItem): void {
    const itemIndex = FormulaItemSeeker.itemIndexInFormula(parenItem, this.formula);
    const matchingParenIndex = FormulaParenMatchSeeker.findMatchingParenIndex(parenItem, this.formula);
    if (matchingParenIndex < 0) {
      return;
    }
    const start = Math.min(itemIndex, matchingParenIndex);
    const openingParenItem = this.formula[start];
    for (let i = start + 1; i < this.formulaData.length; i++) {
      const currentItem = this.formula[i];
      if (currentItem.parentId === openingParenItem.itemId) {
        currentItem.parentId = openingParenItem.parentId;
      }
    }
  }
}
