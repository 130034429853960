import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable } from 'rxjs';

import { SimpleProfileViewModel, UserManagementClient } from '@enerkey/clients/user-management';

import { SearchComponent as SC } from '../../search/search.component';

@Component({
  selector: 'profile-search',
  templateUrl: '../../search/search.component.html',
  styleUrls: ['../../search/search.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProfileSearchComponent),
    multi: true
  }],
})
export class ProfileSearchComponent<B extends boolean = any> extends SC<SimpleProfileViewModel, 'id', B> {
  public valueField: 'id' = 'id';
  public textField: 'name' = 'name';

  public constructor(
    private readonly userManagementClient: UserManagementClient,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  protected fetchItems(): Observable<SimpleProfileViewModel[]> {
    return this.userManagementClient.getAllProfilesSimple(this.filterStringPrefix);
  }
}
