import MeterInterfaceStatusController from './meter-interface-status-controller';
import template                       from 'raw-loader!./meter-interface-status.html';

const MeterInterfaceStatusComponent = {
  template: template,
  controllerAs: 'vm',
  controller: MeterInterfaceStatusController,
  bindings: {
    facilityName: '@',
    meterId: '<',
    meteringPointName: '@'
  }
};

export default MeterInterfaceStatusComponent;
