import { ErReportTypeName } from '../constants/er-report-type-name';

// Functions that provide infomation about which report is currently visible
const $inject = ['$state', 'erReportSettingsService'];

class ErStateService {
  private modalOpen: boolean = false;
  private $state: any;
  private erReportSettingsService: any;

  public constructor($state: any, erReportSettingsService: any) {
    this.$state = $state;
    this.erReportSettingsService = erReportSettingsService;
  }

  public isModalOpen(): boolean {
    return this.modalOpen;
  }

  public openModal(): void {
    this.modalOpen = true;
  }

  public closeModal(): void {
    this.modalOpen = false;
  }

  public isFacilityGridReport(): boolean {
    return this.modalOpen ? false : this.$state.$current.name === 'facilities.grid';
  }

  public shouldMergeExcelSheetsToOneFile(): boolean {
    return this.modalOpen
      ? false
      : [
        'facilities.grid',
        'facilities.report-forecast',
        'facilities.report-trend'
      ].includes(this.$state.$current.name)
    ;
  }

  public isFacilityOverviewReport(): boolean {
    const erReportSettings = this.erReportSettingsService.getInstance();
    return this.modalOpen ? erReportSettings.getSettings().name === ErReportTypeName.FacilityOverview : false;
  }

  public isMeterTreelistReport(): boolean {
    const erReportSettings = this.erReportSettingsService.getInstance();
    return this.modalOpen ? erReportSettings.getSettings().name === ErReportTypeName.MeterTreelist : false;
  }

  public isComparisonReport(): boolean {
    const reports = [ErReportTypeName.MeterComparisonReport];
    const states = ['facilities.report-comparison'];
    return this.isReportOrState(reports, states);
  }

  public isForecastReport(): boolean {
    const reports = [
      ErReportTypeName.FacilityForecastReport,
      ErReportTypeName.MeterForecastReport,
      ErReportTypeName.MeterForecastSumReport
    ];
    const states = [
      'facilities.report-forecast',
      'facilities.report-forecast-sum'
    ];
    return this.isReportOrState(reports, states);
  }

  public isGridReport(): boolean {
    const reports = [ErReportTypeName.MeterTreelist];
    const states = ['facilities.grid'];
    return this.isReportOrState(reports, states);
  }

  public isMeterReport(): boolean {
    const reports = [
      ErReportTypeName.MeterTreelist,
      ErReportTypeName.MeterReport,
      ErReportTypeName.MeterSumReport,
      ErReportTypeName.MeterTrendReport,
      ErReportTypeName.MeterTrendSumReport,
      ErReportTypeName.MeterComparisonReport,
      ErReportTypeName.MeterForecastReport,
      ErReportTypeName.MeterForecastSumReport
    ];
    return this.isReportOrState(reports, []);
  }

  public isSumReport(): boolean {
    const reports = [
      ErReportTypeName.MeterSumReport,
      ErReportTypeName.MeterForecastSumReport,
      ErReportTypeName.MeterTrendSumReport
    ];
    const states = [
      'facilities.report-sum',
      'facilities.report-trend-sum',
      'facilities.report-forecast-sum'
    ];
    return this.isReportOrState(reports, states);
  }

  public isTrendReport(): boolean {
    const reports = [
      ErReportTypeName.FacilityTrendReport,
      ErReportTypeName.MeterTrendReport,
      ErReportTypeName.MeterTrendSumReport
    ];
    const states = [
      'facilities.report-trend',
      'facilities.report-trend-sum'
    ];
    return this.isReportOrState(reports, states);
  }

  private isReportOrState(reports: ErReportTypeName[], states: string[] = []) {
    const erReportSettings = this.erReportSettingsService.getInstance();
    const reportName = erReportSettings.getSettings().name;
    const stateName = this.$state.$current.name;
    return this.modalOpen ? reports.includes(reportName) : states.includes(stateName);
  }
}

ErStateService.$inject = $inject;

export default ErStateService;
