import { Pipe, PipeTransform } from '@angular/core';

import { OperationResult } from '@enerkey/clients/provisioning';

export const operationStatusTranslations: Record<OperationResult, string> = {
  [OperationResult.Failed]: 'FAILED',
  [OperationResult.Success]: 'SUCCESS',
  [OperationResult.PartialSuccess]: 'PARTIAL_SUCCESS',
};

@Pipe({
  name: 'operationResultStatus'
})
export class OperationResultStatusPipe implements PipeTransform {
  public transform(status: OperationResult): string {
    const prefix = 'PROVISIONING.OPERATION_STATUS.';
    const suffix = operationStatusTranslations[status] || 'UNKNOWN';
    return `${prefix}${suffix}`;
  }
}
