import { INewsClient } from './news-client';

export class NewsClientMock implements INewsClient {
  public getAllCategories(): never {
    throw new Error('Method not implemented.');
  }
  public getAllNews(): never {
    throw new Error('Method not implemented.');
  }
  public addNews(): never {
    throw new Error('Method not implemented.');
  }
  public getNewsById(): never {
    throw new Error('Method not implemented.');
  }
  public deleteNews(): never {
    throw new Error('Method not implemented.');
  }
  public updateNews(): never {
    throw new Error('Method not implemented.');
  }
  public getTenantedNews(): never {
    throw new Error('Method not implemented.');
  }
  public getUserNews(): never {
    throw new Error('Method not implemented.');
  }
  public getLoginNews(): never {
    throw new Error('Method not implemented.');
  }
  public getStatusNews(): never {
    throw new Error('Method not implemented.');
  }
  public markAsRead(): never {
    throw new Error('Method not implemented.');
  }
  public getContentById(): never {
    throw new Error('Method not implemented.');
  }
}
