import { reduce } from 'lodash';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  MeterHierarchy,
  MeteringType as MeterManagementMeteringType,
  SubMeter
} from '@enerkey/clients/meter-management';
import { MeteringType } from '@enerkey/clients/metering';

import { meterTypeTranslation } from '../../constants/meter.constant';

export interface IMeterService {
  translateMeteringTypeId(meteringTypeId: MeteringType): string;
  filterHierarchyMainMeterIds(hierarchy: {[key: string]: MeterHierarchy[]}): number[];
  filterHierarchySubMeterIds(hierarchy: {[key: string]: MeterHierarchy[]}): number[];
}

@Injectable()
export class MeterService implements IMeterService {

  public constructor(private translate: TranslateService) {}

  public translateMeteringTypeId(meteringType: MeteringType | MeterManagementMeteringType): string {
    return meterTypeTranslation[meteringType]
      ? this.translate.instant(meterTypeTranslation[meteringType])
      : '';
  }

  public filterHierarchyMainMeterIds(hierarchy: {[key: string]: MeterHierarchy[]}): number[] {
    return reduce(hierarchy, (ids, facility) => {
      ids.push(...facility.map(meterHierachy => meterHierachy.mainMeter.meterId));
      return ids;
    }, []);
  }

  public filterHierarchySubMeterIds(hierarchy: {[key: string]: MeterHierarchy[]}): number[] {
    const subMeterIds: number[] = reduce(hierarchy, (ids, facility) => {
      ids.push(...facility.reduce((mainMeterSubIds: number[], mainMeterHierachy) => {
        if (mainMeterHierachy.mainMeter.subMeters) {
          mainMeterHierachy.mainMeter.subMeters.forEach(subMeter => {
            mainMeterSubIds.push(...this.iterateSubMeters(subMeter));
          });
        }
        return mainMeterSubIds;
      }, []));
      return ids;
    }, []);
    return subMeterIds.unique();
  }

  private iterateSubMeters(subMeter: SubMeter): number[] {
    const meterIds: number[] = [subMeter.meterId];
    if (subMeter.subMeters) {
      subMeter.subMeters.forEach(subSubMeter => {
        meterIds.push(...this.iterateSubMeters(subSubMeter));
      });
    }
    return meterIds;
  }
}
