import EnerkeyServiceModule from '../../../../../services';
import ManualQaServiceModule from '../../../services';
import ManualQaChangeMeterServiceModule from '../../manual-qa-change-meter';
import ManualQaConsumptionInputModalModule from '../../manual-qa-consumption-input-modal';
import ManualQaAssignOperationPeriodCommand from './manual-qa-assing-operation-period-command';
import ManualQaFixedConsumptionCommand from './manual-qa-fixed-consumption-command';
import ManualQaHourlyConsumptionCommand from './manual-qa-hourly-consumption-command';
import ManualQaMonthlyConsumptionCommand from './manual-qa-monthly-consumption-command';
import ManualQaMeterChangeCommand from './manual-qa-meter-change-command';

const dependencies = [
  ManualQaServiceModule.name,
  ManualQaConsumptionInputModalModule.name,
  EnerkeyServiceModule.name,
  ManualQaChangeMeterServiceModule.name,
];

const ManualQaSpreadsheetCommandsModule = angular
  .module('app.modules.manual-qa.components.manual-qa-spreadsheet-commands', dependencies)
  .service('ManualQaAssignOperationPeriodCommand', ManualQaAssignOperationPeriodCommand)
  .service('ManualQaFixedConsumptionCommand', ManualQaFixedConsumptionCommand)
  .service('ManualQaHourlyConsumptionCommand', ManualQaHourlyConsumptionCommand)
  .service('ManualQaMonthlyConsumptionCommand', ManualQaMonthlyConsumptionCommand)
  .service('ManualQaMeterChangeCommand', ManualQaMeterChangeCommand)
  ;

export default ManualQaSpreadsheetCommandsModule;
