import templateModule from 'raw-loader!../templates/mrc-quantity.html';


export function mrcQuantity() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      facility: "=",
      quantity: "=",
      mrcState: "=",
      commonDate: "=",
      months: "="
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.$watch('quantity', function (n, o) {
          $scope.quantity.hasCumulativeMeters = false;
          $scope.meters = $scope.quantity.MainMeters;
        });
      }
    ]
  };
}
