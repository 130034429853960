'use strict';
import mrcDataParserImport from './mrc-data-parser';

function mrcInputValidation($q) {
  var mrcDataParser = mrcDataParserImport($q);
  function meterHasNewReadings(meter) {
    if (angular.isDefined(meter.newReading)) {
      if (angular.isDefined(meter.newReading.readingDate) && meter.newReading.readingDate !== null
          && angular.isDefined(meter.newReading.readingValues) && meter.newReading.readingValues !== null && meter.newReading.readingValues.length >= 1) {
        return true;
      }
    } else {
      return false;
    }
  }

  function allValuesAreNull(readingValues) {
    return $q(function (resolve, reject) {
      $q.all(
        _.map(readingValues, function (readingItem) {
          return $q(function (rslv, rjct) {
            if (angular.isDefined(readingItem.newValue)) {
              if (readingItem.newValue !== null) {
                rjct('notNull');
              } else {
                rslv('isNull');
              }
            } else {
              rjct('notNull');
            }
          });
        })
      ).then(
        function (res) {
          resolve('allNull');
        },
        function (err) {
          reject('notNull');
        }
      );
    });
  }

  function validateMeterReading(reading, hasSiblings) {
    return $q(function (resolve, reject) {
      if (angular.isDefined(reading.newValue) && reading.newValue === null) {
        if (hasSiblings) {
          reading.hasError = true;
        }

        resolve('isNull');
      } else if (angular.isDefined(reading.newValue) && reading.newValue !== null) {
        var newValue = mrcDataParser.convertStringToFloat(reading.newValue);
        if (angular.isDefined(reading.Value)) {
          if (newValue >= reading.Value) {
            reading.hasError = false;
            resolve({success: true});
          } else {
            reading.hasError = true;
            reject({success: false});
          }
        } else {
          if (newValue >= 0) {
            reading.hasError = false;
            resolve({success: true});
          } else {
            reading.hasError = true;
            reject({success: false});
          }
        }
      } else {
        reading.hasError = true;
        reject({success: false});
      }
    });
  }

  function validateMeterReadings(readings) {
    return $q(function (resolve, reject) {
      if (!angular.isDefined(readings) || readings.readingValues.length <= 1) {
        reject({success: false});
      } else {
        allValuesAreNull(readings.readingValues).then(
          function (res) {
            resolve(res);
          },
          function (res2) {
            $q.all(
              _.map(readings.readingValues, function (readingItem) {
                return validateMeterReading(readingItem, true);
              })
            ).then(
              function (res) {
                if (_.contains(res, "isNull")) {
                  reject({success: false});
                } else {
                  resolve({success: true});
                }
              },
              function (err) {
                reject({success: false});
              }
            );
          }
        );
      }
    });
  }

  function validateMeterReadingDate(newReading) {
    if (angular.isDefined(newReading.minDate) && newReading.minDate !== null) {
      if (angular.isDefined(newReading.readingDate) && newReading.readingDate !== null) {
        var readingDate = new Date(newReading.readingDate),
            minDate = new Date(newReading.minDate);
        if (readingDate < minDate) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      if (angular.isDefined(newReading.readingDate) && newReading.readingDate !== null) {
        return true;
      } else {
        return false;
      }
    }
  }

  function hasAllReadings(meter) {
    if (angular.isDefined(meter.TwoTimeMeasurement)
      && !meter.TwoTimeMeasurement
      && angular.isDefined(meter.newReading)
      && angular.isDefined(meter.newReading.readingValues)
      && meter.newReading.readingValues.length === 1) {
      return true;
    } else if (angular.isDefined(meter.TwoTimeMeasurement)
      && meter.TwoTimeMeasurement
      && angular.isDefined(meter.newReading)
      && angular.isDefined(meter.newReading.readingValues)
      && meter.newReading.readingValues.length > 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  function validateSingleMeterInput(meter) {
    return $q(function (resolve, reject) {
      if (meterHasNewReadings(meter)) {
        if (meter.TwoTimeMeasurement) {
          if (hasAllReadings(meter)) {
            validateMeterReadings(meter.newReading).then(
              function (res) {
                if (validateMeterReadingDate(meter.newReading)) {
                  meter.validationErrors.Date = false;
                  resolve({success: res !== 'allNull'});
                } else {
                  meter.validationErrors.Date = true;
                  reject({success: false});
                }
              },
              function (err) {
                reject({success: false});
              }
            );
          } else {
            reject({success: false});
          }
        } else {
          validateMeterReading(meter.newReading.readingValues[0], false).then(
            function (res) {
              if (validateMeterReadingDate(meter.newReading)) {
                meter.validationErrors.Date = false;
                resolve({success: res !== 'isNull'});
              } else {
                meter.validationErrors.Date = true;
                reject({success: false});
              }
            },
            function (err) {
              reject({success: false});
            }
          );
        }
      } else {
        reject({success: false});
      }
    });
  }

  function validateIntervalDateInput(picker, interval) {
    var ret = angular.copy(interval);
    ret.fromDateValid = ret.fromDate !== undefined;
    ret.toDateValid = ret.toDate !== undefined;
    if(ret.fromDateValid && ret.toDateValid) {
      if(ret.fromDate > ret.toDate) {
          if(picker==1) {
              ret.fromDateValid = false;
          } else {
              ret.toDateValid = false;
          }
      }
      var yearInMs = 31556952000;
      if(Math.abs(ret.toDate.getTime() - ret.fromDate.getTime()) > yearInMs) {
          ret.fromDateValid = false;
          ret.toDateValid = false;
      }
    }
    return ret;
  }

  function resetValidationErrors(meter) {
    if(meter.validationErrors) {
      meter.validationErrors.Date = false;
    }

    if(meter.newReading && meter.newReading.readingValues) {
      _.each(meter.newReading.readingValues, function(item) {
        item.hasError = false;
      });
    }
  }

  return {
    validateMeterInput: validateSingleMeterInput,
    validateMeterReadingDate: validateMeterReadingDate,
    validateIntervalDateInput: validateIntervalDateInput,
    resetValidationErrors: resetValidationErrors
  };
}

mrcInputValidation.$inject = ['$q'];

export default mrcInputValidation;
