import { IContactClient } from './contact-client';

export class ContactClientMock implements IContactClient {
  public getCompaniesSlim(): never {
    throw new Error('Method not implemented.');
  }
  public getCompany(): never {
    throw new Error('Method not implemented.');
  }
  public getTenantDefaultCompany(): never {
    throw new Error('Method not implemented.');
  }
  public getCompanies(): never {
    throw new Error('Method not implemented.');
  }
  public getAllCompaniesFlat(): never {
    throw new Error('Method not implemented.');
  }
  public searchCompaniesFlat(): never {
    throw new Error('Method not implemented.');
  }
  public getAllowedCompanies(): never {
    throw new Error('Method not implemented.');
  }
  public getIntegratableCompanyOvts(): never {
    throw new Error('Method not implemented.');
  }
  public getCompaniesByName(): never {
    throw new Error('Method not implemented.');
  }
  public getCompanyNames(): never {
    throw new Error('Method not implemented.');
  }
  public getAllCompanies(): never {
    throw new Error('Method not implemented.');
  }
  public createCompany(): never {
    throw new Error('Method not implemented.');
  }
  public getCompaniesByExternalIds(): never {
    throw new Error('Method not implemented.');
  }
  public createCompanies(): never {
    throw new Error('Method not implemented.');
  }
  public updateCompany(): never {
    throw new Error('Method not implemented.');
  }
  public patchUpdateCompany(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCompany(): never {
    throw new Error('Method not implemented.');
  }
  public massUpdateCompanies(): never {
    throw new Error('Method not implemented.');
  }
  public getProfileContactCompanies(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesContactInformation(): never {
    throw new Error('Method not implemented.');
  }
  public getCompanyFacilityFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public addCompanyFacilityFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCompanyFacilityFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public addCompanyPersonFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCompanyPersonFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public getPartnershipsForCompany(): never {
    throw new Error('Method not implemented.');
  }
  public getAllPartnerships(): never {
    throw new Error('Method not implemented.');
  }
  public postPartnership(): never {
    throw new Error('Method not implemented.');
  }
  public putPartnership(): never {
    throw new Error('Method not implemented.');
  }
  public getCurrentlyLoggedInUsersPartnerships(): never {
    throw new Error('Method not implemented.');
  }
  public deletePartnership(): never {
    throw new Error('Method not implemented.');
  }
  public getPerson(): never {
    throw new Error('Method not implemented.');
  }
  public getPersons(): never {
    throw new Error('Method not implemented.');
  }
  public getAllPersons(): never {
    throw new Error('Method not implemented.');
  }
  public createPerson(): never {
    throw new Error('Method not implemented.');
  }
  public getPersonsBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public getPersonsByEmails(): never {
    throw new Error('Method not implemented.');
  }
  public getPersonsByFacilityByFunctionType(): never {
    throw new Error('Method not implemented.');
  }
  public createPersons(): never {
    throw new Error('Method not implemented.');
  }
  public updatePerson(): never {
    throw new Error('Method not implemented.');
  }
  public deletePerson(): never {
    throw new Error('Method not implemented.');
  }
  public massUpdatePersons(): never {
    throw new Error('Method not implemented.');
  }
  public getPersonFacilityFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public addPersonFacilityFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public massAddPersonFacilityFunctionsWithEnegiaId(): never {
    throw new Error('Method not implemented.');
  }
  public deletePersonFacilityFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public massDeletePersonFacilityFunctions(): never {
    throw new Error('Method not implemented.');
  }
  public synchronizeContacts(): never {
    throw new Error('Method not implemented.');
  }

}
