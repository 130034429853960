import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { FacilityService } from '../../services/facility.service';
import { MultiSelectComponent } from '../multi-select/multi-select.component';
import { ExtendedFacilityInformation } from '../../interfaces/extended-facility-information';

@Component({
  selector: 'facility-multi-select',
  templateUrl: '../multi-select/multi-select.component.html',
  styleUrls: ['../multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilityMultiSelectComponent),
    multi: true
  }]
})
export class FacilityMultiSelectComponent extends
  MultiSelectComponent<ExtendedFacilityInformation, 'facilityId', 'name'>
  implements OnDestroy {
  @Input() public override filterable = true;
  @Input() public override translate = false;

  public readonly placeholderKey = 'SELECT_FACILITY';
  public readonly overflowKey = 'ERROR_CONTEXT.FACILITY';
  public readonly valueField = 'facilityId';
  public readonly textField = 'name';
  public readonly overflowThreshold = 2;
  protected readonly filterMinLength = 3;

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly facilityService: FacilityService,
    protected override readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
    this.facilityService.filteredProfileFacilities$
      .pipe(
        map(
          facilities => facilities
            .filter(item => item.facilityId)
            .sort((facility1, facility2) => facility1.name.localeCompare(facility2.name))
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(facilities => {
        this.items = facilities;
        this.updateValueAfterFilterChange();
        this.changeDetectorRef.markForCheck();
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateValueAfterFilterChange(): void {
    this.value = this.items.filterMap(
      facility => this.value.includes(facility.facilityId),
      facility => facility.facilityId
    );
    this._onChange?.(this.value);
  }
}
