import * as Configs              from '../../constants/configs';
import { ReaderTypeInformation } from '../../shared/reader-type-information.js';

const $inject = ['ManualQaApiService', '$q'];

class ManualQaApiReaderTypeService {
  constructor(ManualQaApiService, $q) {
    this.ManualQaApiService = ManualQaApiService;
    this.$q = $q;
  }

  getReaderTypeInformation(meter) {
    return this.ManualQaApiService.doRequest({
      method: 'GET',
      url: `${Configs.CC_API_URL}ReaderType/forMeter/${meter.getId()}`
    }).then(result => result.data.id === 0 ? null : new ReaderTypeInformation(result.data));
  }
}

ManualQaApiReaderTypeService.$inject = $inject;

export default ManualQaApiReaderTypeService;
