<kendo-combobox
  [data]="searchItems$ | async"
  [valueField]="titleField"
  [textField]="titleField"
  [filterable]="true"
  (filterChange)="filterChange($event)"
  (valueChange)="onItemSelect($event)"
  [listHeight]="400"
  [clearButton]="false"
>
  <ng-template kendoComboBoxItemTemplate let-dataItem>
    <span>{{ dataItem.title }}&nbsp;</span>
    <span *ngIf="dataItem.extraText">{{ dataItem.extraText }}</span>
  </ng-template>
  <ng-template
    kendoComboBoxFixedGroupTemplate
    kendoComboBoxGroupTemplate
    let-groupName
  >
    <span class="bold">{{ groupName | translate }}</span>
  </ng-template>
</kendo-combobox>
<button
  class="button icon"
  (click)="close()"
>
  <i class="fa fa-times"></i>
</button>
