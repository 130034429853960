import { Directive, HostBinding, Optional } from '@angular/core';
import { RemoveCommandDirective } from '@progress/kendo-angular-grid';

import { KendoGridButtonDirective } from './kendo-grid-button.directive';

@Directive({
  selector: '[kendoGridRemoveCommand]'
})
export class KendoGridRemoveCommandDirective extends KendoGridButtonDirective {
  @HostBinding('style.color') public negativeButtonClass = 'var(--enerkey-remove-action)';

  public constructor(@Optional() removeCommand: RemoveCommandDirective) {
    super(removeCommand);
    if (removeCommand) {
      removeCommand.iconClass = 'fa-1x fa fa-trash-alt';
    }
  }
}
