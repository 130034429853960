import templateModule from 'raw-loader!../templates/meter-details-list-item-template.html';

export default {
  template: templateModule,
  controller: 'MeterDetailsListItemController',
  controllerAs: 'vm',
  bindings: {
    title: '<',
    content: '<',
    isDate: '<',
  }
};
