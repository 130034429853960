const inject = [
  '$modalInstance', 'confirmationText'
];

class CcMassImportConfirmationController {

  public constructor(
    private $modalInstance: any,
    public confirmationText: string
  ) {
  }

  public onConfirmed() {
    this.$modalInstance.close();
  }

  public cancel() {
    this.$modalInstance.dismiss();
  }
}

CcMassImportConfirmationController.$inject = inject;

export default CcMassImportConfirmationController;
