import { ChangeDetectorRef, Inject, Injectable } from '@angular/core';
import { RawParams, StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { ActionOutViewModel, ActionType } from '@enerkey/clients/attachments';

import { actionTypeTranslations } from '../../energy-management/constants/em-action-types';
import { CommentsWidgetOptions } from '../components/comments-widget/comments-widget.component';
import { ActionsWidgetOptions } from '../components/actions-widget/actions-widget.component';
import { executionPhaseTranslations } from '../../energy-management/constants/em-execution-phases';
import { AjsModalService } from '../../../services/modal/modal.service';
import EmActionEditService from '../../energy-management/components/em-action-edit/em-action-edit-service';
import { FacilityCommentsWidgetOptions } from '../components/facility-comments-widget/facility-comments-widget.component';
import { FacilityActionsWidgetOptions } from '../components/facility-actions-widget/facility-actions-widget.component';
/**
 * Facilitates state changes and modal opening from dashboard.
 */
@Injectable({ providedIn: 'root' })
export class DashboardStateService {

  public constructor(
    private readonly translateService: TranslateService,
    private readonly stateService: StateService,
    private readonly angularjsModalService: AjsModalService,
    @Inject('emActionEditService') private readonly emActionEditService: EmActionEditService
  ) { }

  public openFacilityReport(facilityId: number): Promise<unknown> {
    return this.angularjsModalService.getModalWithComponent('report-modal', {
      reportType: 'modal.report',
      reportParams: { facilityId: [facilityId] }
    });
  }

  public openMeterReport(meterId: number, facilityId: number): Promise<unknown> {
    return this.angularjsModalService.getModalWithComponent('report-modal', {
      reportType: 'modal.metertree',
      reportParams: { meterId: [meterId], facilityId: [facilityId] }
    });
  }

  public openEmEditModal(model: ActionOutViewModel): Promise<unknown> {
    return this.emActionEditService.getModal(model);
  }

  public goToEnergyManagement(
    model: ActionsWidgetOptions | CommentsWidgetOptions | FacilityCommentsWidgetOptions | FacilityActionsWidgetOptions
  ): void {
    const gridFilter = model.typeToShow === 'actions'
      ? this.getEmActionsFilter(model)
      : this.getEmCommentsFilter(model);
    const params: RawParams = {
      gridState: {
        query: {
          filter: gridFilter,
          sort: [{ field: 'updatedAt', dir: 'desc' }]
        }
      },
      automaticSearch: true
    };

    if ('facilityId' in model) {
      params.reportingObjectId = model.facilityId;
    }

    this.stateService.go(`energy-management.${model.typeToShow}`, params);
  }

  public refreshReportData(
    sourceObj: ActionOutViewModel,
    response: { operation?: string, action?: ActionOutViewModel },
    sourceChangeDetectionRef: ChangeDetectorRef,
    _refresh$: BehaviorSubject<boolean>
  ): void {
    if (response?.operation === 'updateAction') {
      Object.assign(sourceObj, new ActionOutViewModel(response.action));
      sourceChangeDetectionRef.markForCheck();
    }
    if (response?.operation === 'deleteAction') {
      _refresh$.next(true);
    }
  }

  private getEmActionsFilter(model: ActionsWidgetOptions): kendo.data.DataSourceFilters {
    const actionTypeFilter = this.getActionTypeFilter(model.selectedActionTypes.map(x => x.id));

    const executionPhaseFilter: kendo.data.DataSourceFilterItem = {
      field: 'executionPhaseName',
      operator: 'eq',
      value: this.translateService.instant(executionPhaseTranslations[model.selectedExecutionPhaseId]),
    };

    return { logic: 'and', filters: [actionTypeFilter, executionPhaseFilter] };
  }

  private getEmCommentsFilter(model: CommentsWidgetOptions): kendo.data.DataSourceFilters {
    const actionTypes = Object.integerKeys(model.selectedCommentTypes)
      .filter((at: ActionType.K | ActionType.KE) => model.selectedCommentTypes[at]);

    const actionTypeFilter = this.getActionTypeFilter(actionTypes);

    // No extra filtering for comments
    return actionTypeFilter;
  }

  private getActionTypeFilter(types: ActionType[]): kendo.data.DataSourceFilters {
    return {
      logic: 'or',
      filters: types.map(type => ({
        field: 'actionTypeName',
        operator: 'eq',
        value: this.translateService.instant(actionTypeTranslations[type]),
      }))
    };
  }
}
