import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ConsumptionLike, ReportingSeries } from '../shared/reporting-series';
import { getIncompleteOrModeledAggregate } from '../shared/reporting.functions';

export const monthsToAddToRequest = 11;

/**
 * Extend request duration by 11 months so trend of 12 months can be calculated for every visible month
 * @param params searchParams
 * @returns request duration in months
 */
export function getRequestDurationInMonths(params: ReportingSearchParams): number {
  const originalDuration = params.formValue.durationName;
  if (originalDuration === 'months') {
    return params.formValue.durationLength + monthsToAddToRequest;
  } else if (originalDuration === 'years') {
    return params.formValue.durationLength * 12 + monthsToAddToRequest;
  }
}

export function getTrendSeries(series: ReportingSeries): ReportingSeries {
  const values: ConsumptionLike[] = [];

  for (let i = monthsToAddToRequest; i < series.values.length; i++) {
    const valueSlice = series.values.slice(i - monthsToAddToRequest, i + 1);
    const sliceHasValues = valueSlice.some(v => v.value !== null);

    const trendValue = sliceHasValues
      ? valueSlice.reduce((accum, current) => accum + current.value, 0)
      : null;

    let distributionTrendValue: number;
    if (series.options.isPercentSerie) {
      distributionTrendValue = valueSlice
        .reduce((accum, current) => accum + current.counterpartValue, 0);
    }
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const seriesYear = series.values[i].timestamp.getFullYear();
    const seriesMonth = series.values[i].timestamp.getMonth();
    if (seriesYear < currentYear || (seriesYear === currentYear && seriesMonth < currentMonth)) {
      values.push({
        value: trendValue,
        timestamp: series.values[i].timestamp,
        counterpartValue: distributionTrendValue,
        incomplete: getIncompleteOrModeledAggregate(valueSlice, 'incomplete'),
        modeled: getIncompleteOrModeledAggregate(valueSlice, 'modeled'),
      });
    }
  }

  return series.fromValues({
    consumptions: values,
    compareToPreviousMonth: series.gridOptions.hideInGrid === false,
  });
}
