import TimeFrameRadioComponent from './time-frame-radio-component';
import TimeFrameRadioService   from './time-frame-radio-service';

const dependencies = [];

const TimeFrameRadioModule = angular
  .module('app.modules.manual-qa.components.time-frame-radio', dependencies)
  .component('timeFrameRadio', TimeFrameRadioComponent)
  .service('TimeFrameRadioService', TimeFrameRadioService)
;

export default TimeFrameRadioModule;
