import { Inject, Injectable } from '@angular/core';

import { WINDOW } from '@enerkey/angular-utils';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  public constructor(
    @Inject(WINDOW) private readonly window: Window
  ) { }

  /**
   * Save value to localstorage. If value is string save it as given. If not stringify it first.
   *
   * @param key key for which to save value
   * @param value value to save
   */
  public saveValueByKey(key: string, value: unknown): void {
    this.window.localStorage.setItem(
      key,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
  }

  public getStringValueByKey(key: string): string {
    return this.window.localStorage.getItem(key);
  }

  public getValueByKey(key: string): unknown {
    return JSON.parse(this.window.localStorage.getItem(key));
  }

  public getValueByKeyAndRemove(key: string): unknown {
    const val = this.getValueByKey(key);
    this.removeValueBykey(key);

    return val;
  }

  public removeValueBykey(key: string): void {
    this.window.localStorage.removeItem(key);
  }
}
