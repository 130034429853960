<ng-template #topbarTemplate>
  <ek-dropdown
    [attr.title]="(noMetersSelected$ | async) ? ('ADMIN.METER_SEARCH.NO_SELECTED_METERS.INFO' | translate) : null"
  >
    <ek-dropdown-heading>
      {{ 'DROPDOWN.ACTIONS' | translate }}
    </ek-dropdown-heading>
    <ek-dropdown-item
      (click)="addAsMainMeter()"
      [disabled]="noMetersSelected$ | async"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.ADD_AS_MAIN_METERS' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      (click)="removeMetersFromHierarchy()"
      [disabled]="noMetersSelected$ | async"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.REMOVE_METER_FROM_HIERARCHY' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      (click)="addForMeter()"
      [disabled]="noMetersSelected$ | async"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.ADD_FOR_METER' | translate }}
    </ek-dropdown-item>
    <hr>
    <ek-dropdown-item
      (click)="massEditMeters()"
      [disabled]="noMetersSelected$ | async"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.MASS_EDIT_METERS' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      (click)="editMeterOrder()"
      [disabled]="noMetersSelected$ | async"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.METER_HIERARCHY_EDIT' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      (click)="massEditMeterCosts()"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.MASS_EDIT_METER_COST_FACTORS' | translate }}
    </ek-dropdown-item>
    <hr>
    <ek-dropdown-item
      (click)="addMetersToTerminal()"
      [disabled]="noMetersSelected$ | async"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.ADD_TO_TERMINAL' | translate }}
    </ek-dropdown-item>
    <ek-dropdown-item
      (click)="removeMetersFromTerminal()"
      [disabled]="noMetersSelected$ | async"
    >
      {{ 'ADMIN.BULK_MODIFY_METERS.REMOVE_FROM_TERMINAL' | translate }}
    </ek-dropdown-item>
    <ng-container *ngIf="isContractManager">
      <hr>
      <ek-dropdown-item
        (click)="addMetersToSubscription()"
        [disabled]="noMetersSelected$ | async"
      >
        {{ 'ADMIN.BULK_MODIFY_METERS.ADD_TO_SUBSCRIPTION' | translate }}
      </ek-dropdown-item>
    </ng-container>
    <ng-container *ngIf="isPoaManager">
      <hr>
      <ek-dropdown-item
        (click)="addPoaToMeters()"
        [disabled]="noMetersSelected$ | async"
      >
        {{ 'ADMIN.BULK_MODIFY_METERS.ADD_POA_TO_METERS' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        (click)="removePoaFromMeters()"
        [disabled]="noMetersSelected$ | async"
      >
        {{ 'ADMIN.BULK_MODIFY_METERS.REMOVE_POA_FROM_METERS' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        (click)="authorizeMetersForFinnishDatahub()"
        [disabled]="noMetersSelected$ | async"
      >
        {{ 'ADMIN.BULK_MODIFY_METERS.DATAHUB_AUTHORIZATION' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        (click)="authorizeMetersForDanishDatahub()"
        [disabled]="noMetersSelected$ | async"
      >
        {{ 'ADMIN.BULK_MODIFY_METERS.DATAHUB_AUTHORIZATION_DANISH' | translate }}
      </ek-dropdown-item>
    </ng-container>
  </ek-dropdown>
  <button class="button" (click)="createMeters()">
    <i class="fa fa-plus"></i> {{ 'ADMIN.CREATE_METERS' | translate }}
  </button>
</ng-template>
<div class="row meter-search">
  <div class="large-2 medium-3 columns">
    <meter-management-search></meter-management-search>
  </div>
  <div class="large-10 medium-9 columns">
    <meter-search-treelist></meter-search-treelist>
  </div>
</div>
