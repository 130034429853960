import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProvisioningRuntimeStatus } from '@enerkey/clients/provisioning';

@Component({
  selector: 'provisioning-status-icon',
  templateUrl: './provisioning-status-icon.component.html',
  styleUrls: ['./provisioning-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvisioningStatusIconComponent {
  public readonly ProvisioningRuntimeStatus = ProvisioningRuntimeStatus;

  @Input() public status: ProvisioningRuntimeStatus;
}
