import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { LabelBaseDirective, LabelPosition } from './label-base.directive';

/* eslint-disable @angular-eslint/no-input-rename */

@Directive({ selector: '[labelBefore]' })
export class LabelBeforeDirective extends LabelBaseDirective {

  @Input('labelBefore') public labelText: string;
  @Input('labelBeforeClass') public labelClass: string;
  @Input('labelBeforeTarget') public clickTarget: string;
  @Input('labelBeforeTooltip') public labelTooltip: string;
  @Input('labelBeforeRequired') public labelRequired: boolean;

  protected readonly labelPosition: LabelPosition = 'before';

  public constructor(
    template: TemplateRef<unknown>,
    viewContainer: ViewContainerRef
  ) {
    super(template, viewContainer);
  }
}
