import TimeFrameRadioController from './time-frame-radio-controller';
import template                 from 'raw-loader!./time-frame-radio.html';

export default {
  template: template,
  controller: TimeFrameRadioController,
  controllerAs: 'vm',
  bindings: {
    timeFrameSelection: '<',
    excludeDays: '<',
    dynamicTimeFrame: '<',
    fixedTimeFrame: '<',
    onChange: '&'
  }
};
