<div class="row">
  <div class="large-2 medium-3 columns">
    <form
      [formGroup]="provisioningSearchOptions"
      (ngSubmit)="search()"
    >
      <kendo-datepicker
        formControlName="beginDate"
        *labelBefore="'PROVISIONING.BEGIN_DATE' | translate"
      ></kendo-datepicker>
      <kendo-datepicker
        formControlName="endDate"
        *labelBefore="'PROVISIONING.END_DATE' | translate"
      ></kendo-datepicker>
      <input
        kendoTextBox
        formControlName="externalCustomerId"
        *labelBefore="'EXTERNAL_CUSTOMER_ID' | translate"
      >
      <ek-multi
        *labelBefore="'PROVISIONING.STATUS.LABEL' | translate"
        formControlName="statuses"
        [items]="statusSelectOptions"
      ></ek-multi>
      <ng-container *ngIf="showTenantIdField">
        <kendo-numerictextbox
          formControlName="tenantId"
          *labelBefore="'PROVISIONING.TENANT_ID' | translate"
          integerInput
          [min]="0"
          [spinners]="false"
        ></kendo-numerictextbox>
      </ng-container>
      <div id="provisioning-buttons">
        <button
          type="submit"
          class="button"
          [loading]="loading$ | async"
        >
          {{ 'SEARCH' | translate }}
        </button>
        <button
          type="button"
          class="button button--secondary"
          (click)="clear()"
        >
          {{ 'RESET' | translate }}
        </button>
      </div>
    </form>
  </div>
  <div class="large-10 medium-9 columns">
    <provisioning-grid
      [provisionings]="provisionings$ | async"
      [loading]="loading$ | async"
    ></provisioning-grid>
  </div>
</div>
