import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ContractClient } from '@enerkey/clients/contract';

type IdNamePair = { id: string; name: string };

@Injectable({ providedIn: 'root' })
export class ProductService {

  private readonly products$: Observable<Record<string, string>>;

  public constructor(
    contractClient: ContractClient
  ) {
    this.products$ = contractClient.getProductNames().pipe(shareReplay(1));
  }

  public getProductName(productId: string): Observable<string> {
    return this.products$.pipe(map(names => names[productId]));
  }

  public getProductNames(): Observable<Record<string, string>> {
    return this.products$;
  }

  public getIdNamePairs(): Observable<IdNamePair[]> {
    return this.products$.pipe(
      map(names => Object.entries(names).map(([id, name]) => ({ id, name }))),
      shareReplay(1)
    );
  }
}
