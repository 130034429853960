const $inject = ['utils'];

class EmInputTextController {
  constructor(utils) {
    this.utils = utils;
    this.touched = false;
  }

  $onInit() {
    this.model = this.config.initialValue;
    this.placeholder = this.config.initialValueVaries ? this.utils.localizedString('ACTIONS.MASS_EDIT.VARIES') : '';
    this.config.isValid = true;
  }

  onBlur() {
    this.touched = true;
    this.config.isValid = !this.isRequired();
  }

  onInputChange() {
    this.touched = true;
    this.config.isValid = !this.isRequired();
    this.onChange(this.config, this.model);
  }

  isRequired() {
    return this.config.mandatory && !this.model;
  }
}

EmInputTextController.$inject = $inject;

export default EmInputTextController;
