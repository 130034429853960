<td>
  <span [style.color]="color">
    <!-- ■ -->
    &#9632;
  </span>
  {{ relationalValueId | relationalValueName | async }}
</td>
<td>{{ time }}</td>
<td class="widget-tooltip-value">{{ value | number:'1.1-1' }}</td>
<td>{{ relationalValueId | relationalValueUnit:quantityId:unitKey | async }}</td>
