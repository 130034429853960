import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Scope } from '@enerkey/clients/sustainability';

const griScopeDescriptionTranslations: Record<Scope, string> = {
  [Scope.Scope1]: 'SUSTAINABILITY.GRI.SCOPE1_DESC',
  [Scope.Scope2]: 'SUSTAINABILITY.GRI.SCOPE2_DESC',
  [Scope.Scope3]: 'SUSTAINABILITY.GRI.SCOPE3_DESC',
};

const scopeIndex: Record<Scope, string> = {
  [Scope.Scope1]: '1',
  [Scope.Scope2]: '2',
  [Scope.Scope3]: '3',
};

@Pipe({ name: 'griScopeName' })
export class GriScopeNamePipe implements PipeTransform {

  public constructor(
    private readonly translateService: TranslateService
  ) { }

  public transform(value: Scope, includeDescription?: boolean): string {
    let scope = this.translateService.instant('SUSTAINABILITY.GRI.SCOPE');

    if (!(value in scopeIndex)) {
      return scope;
    }

    scope += ` ${scopeIndex[value]}`;

    if (!includeDescription || !(value in griScopeDescriptionTranslations)) {
      return scope;
    }

    const description = this.translateService.instant(griScopeDescriptionTranslations[value]);
    return `${scope} (${description})`;
  }

}
