import { UntypedFormGroup, Validators } from '@angular/forms';

import { InspectionDefectItem } from '@enerkey/clients/manual-qa';
import { formGroupFrom } from '@enerkey/ts-utils';
import { BucketContact } from '@enerkey/clients/configuration-control';
import { INoReplyMessage } from '@enerkey/clients/user-management';

import { Locale } from '../../../../shared/services/language.service';

export type ReceiverProperty = keyof Pick<BucketContact, 'email' | 'emailForMissingReading'>;

export type SingleEmailForm = Pick<INoReplyMessage, 'to' | 'body' | 'cc' | 'subject'>;

const defaultCoveringNote = 'Hei!<br/><br/>Lähettäisittekö meille seuraavat aikasarjat uudestaan:';

export interface EmailBaseForm {
  language: Locale;
  to: ReceiverProperty;
  replyTo: string;
  subject: string;
  bcc: boolean;
  bccUser: boolean;
  coveringNote: string;
  signature: string;
}

export const emailInfoProperties = [
  'facilityName',
  'meterId',
  'meteringPointName',
  'usageplaceNumber',
  'sellerUsageplaceNumber',
  'protocolCode',
  'eanCode'
] as const;

export type InfoToSendForm = {
  [K in keyof Pick<InspectionDefectItem, typeof emailInfoProperties[number]>]-?: boolean
}

export function getInfoSendForm(): UntypedFormGroup {
  return formGroupFrom<InfoToSendForm>({
    meterId: false,
    meteringPointName: false,
    usageplaceNumber: false,
    sellerUsageplaceNumber: false,
    protocolCode: true,
    eanCode: false,
    facilityName: false
  });
}

export function getEmailCommonValuesForm(replyTo: string): UntypedFormGroup {
  return formGroupFrom<EmailBaseForm>({
    language: 'fi-FI',
    to: 'email',
    replyTo: replyTo,
    subject: 'Uusintalähetyspyyntö energiaraportointiin',
    bcc: true,
    bccUser: false,
    coveringNote: defaultCoveringNote,
    signature: ''
  }, {
    to: Validators.required,
    replyTo: Validators.required
  });
}

export function getSingleEmailForm(): UntypedFormGroup {
  return formGroupFrom<SingleEmailForm>(
    {
      to: null,
      body: null,
      subject: null,
      cc: null
    },
    {
      to: [Validators.required, Validators.email],
      cc: [Validators.email],
      subject: Validators.required,
      body: Validators.required,
    }
  );
}
