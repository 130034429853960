import { QuantityItem } from '@enerkey/clients/energy-reporting';

export function sortOverviewQuantities(quantities: QuantityItem[]): QuantityItem[] {
  return [...quantities].sortBy(q => +(!!q.SumOf), 'desc');
}

/**
 * @param month numeric month starting from zero
 */
export function currentMonthToRequestResolution(month: number): string {
  return month >= 11 ? 'P1Y' : `P${month + 1}M`;
}
