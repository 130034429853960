import _ from 'lodash';
import moment from 'moment';

import { hasChanges } from '@enerkey/angular-utils';

import TimeFrame from '../../../../services/time-frame-service';
import * as Configs from '../../constants/configs';
import * as Chart from './manual-qa-chart.constants';
import * as ChartUtils from './manual-qa-chart.functions';

const $inject = [
  'ManualQaChartService', '$window', '$timeout',
  '$element', '$scope', 'KendoFunctions'
];

function ManualQaChartController(
  ManualQaChartService, $window, $timeout,
  $element, $scope, KendoFunctions
) {
  const vm = this;
  let resizeFn = () => {};

  vm.isCardExpanded = false;
  vm.state = { series: [] };
  vm.constants = Configs;
  vm.chart = null; // Chart instance
  vm.isComponentLoading = false;

  vm.$postLink = postLink;
  vm.$onChanges = onChanges;
  vm.$onDestroy = onDestroy;
  vm.toggleCardSize = toggleCardSize;

  $scope.$on('manual-qa-data-source-changed-chart', onDataSourceChange);
  $scope.$on('manual-qa-topbar-resized', handleResize);

  function postLink() {
    vm.chart = $element
      .find(Chart.CHART_SELECTOR)
      .data('kendoStockChart')
    ;

    vm.chart.setDataSource(vm.dataSource);

    resizeFn = _.debounce(handleResize, 200);

    angular.element($window).on('resize', resizeFn);

    $timeout(handleResize);
  }

  /**
   * Called when data source changed event has been triggered
   */
  function onDataSourceChange() {
    vm.isComponentLoading = true;

    $timeout(() => {
      updateChart();

      vm.isComponentLoading = false;
    }, 0);
  }

  /**
   * Sets given options to given chart
   *
   * @param {Object} chart
   * @param {Object} options
   */
  function updateOptions(chart, options) {
    if (!chart) {
      return;
    }

    chart.setOptions(options);
  }

  /**
   * Called when binding values are changed
   *
   * @param {Object} changes
   */
  function onChanges(changes) {
    if (
      changes.viewTimeFrame &&
      changes.viewTimeFrame.currentValue &&
      !changes.viewTimeFrame.currentValue.isSame(changes.viewTimeFrame.previousValue)
    ) {
      updateChart();
    }

    if (hasChanges(changes, 'fetchType') || hasChanges(changes, 'useComparisonPeriod')) {
      updateChart();
    }
  }

  /**
   * Updates chart with proper options and events.
   */
  function updateChart() {
    if (!vm.chart) {
      return;
    }

    setEvents(vm.chart);

    const options = ManualQaChartService.getStockChartOptions(
      vm.chart,
      vm.dataSource,
      vm.viewTimeFrame,
      vm.fetchType,
      vm.useComparisonPeriod
    );

    updateOptions(vm.chart, _.merge({}, options, vm.state));
  }

  /**
   * Toggles card size
   */
  function toggleCardSize() {
    vm.isCardExpanded = !vm.isCardExpanded;

    $timeout(handleResize, 100);
  }

  /**
   * Sets event listeners to given chart
   *
   * @see https://docs.telerik.com/kendo-ui/api/javascript/dataviz/ui/chart#events
   *
   * @param {Object} chart
   */
  function setEvents(chart) {
    if (!chart) {
      return;
    }

    chart.unbind('selectEnd').bind('selectEnd', selectEnd);
    chart.unbind('zoomStart').bind('zoomStart', zoomStart);
    chart.unbind('legendItemClick').bind('legendItemClick', onLegendItemClick);
    chart.unbind('plotAreaClick').bind('plotAreaClick', onPlotAreaClick);
  }

  function onPlotAreaClick(event) {
    $scope.$apply(() => {
      const date = moment(event.category[0]);
      const timeFrame = ChartUtils.getExtendedTimeFrameForNavigator(new TimeFrame(date, date));

      vm.onPlotAreaClick({ timeFrame });
    });
  }

  /**
   * Called when legend item is clicked
   *
   * @param {Object} event
   */
  function onLegendItemClick(event) {
    if (angular.isUndefined(vm.state.series[event.seriesIndex])) {
      vm.state.series[event.seriesIndex] = {};
    }

    // Save series visibility state
    vm.state.series[event.seriesIndex].visible = !event.series.visible;
  }

  /**
   * Called when selected time frame has been changed in chart with mouse.
   *
   * @param {Object} event
   */
  function selectEnd(event) {
    $scope.$apply(() => {
      if (!event.sender) {
        return;
      }

      vm.viewTimeFrame = new TimeFrame(event.from, event.to);

      updateChart();
    });
  }

  /**
   * Called when user zooms stock chart
   *
   * @param event
   */
  function zoomStart(event) {
    if (event.originalEvent.type === 'mousewheel') {
      event.preventDefault();
    }
  }

  function handleResize() {
    KendoFunctions.resizeKendoComponent(vm.chart, Configs.CARD_CLASS, updateChart, true);
  }

  function onDestroy() {
    vm.chart.destroy();
    angular.element($window).off('resize', resizeFn);
  }
}

ManualQaChartController.$inject = $inject;

export default ManualQaChartController;
