kendoFactory.$inject = ['$translate', '$rootScope', '$', '$q', '$window'];

function kendoFactory($translate, $rootScope, $, $q, $window) {
  var kendo = $window.kendo; // assumes kendo has already been loaded on the page

  /* ** patch: shared tooltip ***/
  var origSharedTooltipGetStyle = kendo.dataviz.SharedTooltip.fn.getStyle;
  kendo.dataviz.SharedTooltip.fn.getStyle = function(options, point) {
    var style = origSharedTooltipGetStyle.call(this, options, point);
    if (angular.isObject(this.options.margin)) {
      style.marginLeft = this.options.rightAligned ? -this.options.margin.horizontal : this.options.margin.horizontal;
      style.marginTop = this.options.margin.vertical;
    }
    return style;
  };

  var origSharedTooltipSlotAnchor = kendo.dataviz.SharedTooltip.fn._slotAnchor;
  kendo.dataviz.SharedTooltip.fn._slotAnchor = function(point, slot) {
    var slotAnchor = origSharedTooltipSlotAnchor.call(this, point, slot);
    var offsetPoint = kendo.dataviz.Chart.fn._toDocumentCoordinates.call(this.chartService.chart, point);
    var viewportWidth = $(window).outerWidth();
    if ((viewportWidth / 2) < offsetPoint.left) {
      slotAnchor.align.horizontal = 'right';
      this.options.rightAligned = true;
    } else {
      this.options.rightAligned = false;
    }
    return slotAnchor;
  };
  /* ** patch: shared tooltip ends ***/

  /* ** patch: kendo excel export ***/
  const origCell = kendo.excel.ExcelExporter.fn._cell;
  kendo.excel.ExcelExporter.fn._cell = (dataItem, column) => {
    var cell = origCell(dataItem, column);
    cell.extra = {};

    // get template data
    var template;
    switch (typeof column.template) {
      case 'function':
        template = column.template(dataItem);
        break;
      case 'string':
        template = kendo.template(column.template)(dataItem);
        break;
      default:
        break;
    }
    if (template) {
      cell.extra.template = template;
    }
    if (column.format) {
      cell.extra.format = column.format;
    }

    return cell;
  };
  /* ** patch: kendo excel export ends ***/

  /* ** patch: kendo paste spreadsheet ***/
  var origClipboardPaste = kendo.spreadsheet.Clipboard.fn.paste;
  kendo.spreadsheet.Clipboard.fn.paste = function() {
    if (angular.isObject(this._external)) {
      this._external.html = null;
    }
    if (angular.isObject(this._externalContent)) {
      this._externalContent.html = null;
      if (angular.isObject(this._content) && angular.isArray(this._content.data)) {
        var newData = [];
        this._content.data.forEach(function(dataItem) {
          if (angular.isArray(dataItem)) {
            newData.push(dataItem.map(function(obj) {
              return {
                value: obj.value
              };
            }));
          }
        });
        if (newData.length) {
          this._content.data = newData;
        }
      }
    }
    origClipboardPaste.call(this);
  };

  var origRangeAdjustRowHeight = kendo.spreadsheet.Range.fn._adjustRowHeight;
  kendo.spreadsheet.Range.fn._adjustRowHeight = function() {
    var options = this._sheet._workbook.options;
    if (options.autoAdjustRowHeight === false) {
      return;
    }
    origRangeAdjustRowHeight.call(this);
  };
  /* ** patch: kendo paste spreadsheet ends ***/


  /* ** patch: kendo spreadsheet focus steal ***/
  var origObjectAt = kendo.spreadsheet.Controller.fn.objectAt;
  kendo.spreadsheet.Controller.fn.objectAt = function(location) {
    if (angular.isObject(location)) {
      if ((location.target && $.contains(this.container[0], location.target)) || !location.target) {
        return origObjectAt.call(this, location);
      } else {
        return { type: 'outside' };
      }
    }
    return origObjectAt.call(this, location);
  };
  /* ** patch: kendo spreadsheet focus steal ends ***/

  return kendo;
}

export default kendoFactory;
