import EnerkeyServiceModule   from '../../../../services';
import ManualQaServiceModule  from '../../services';
import ManualQaChartComponent from './manual-qa-chart-component';
import ManualQaChartService   from './manual-qa-chart-service';

const dependencies = [EnerkeyServiceModule.name, ManualQaServiceModule.name];

const ManualQaChartModule = angular
  .module('app.modules.manual-qa.components.manual-qa-chart', dependencies)
  .component('manualQaChart', ManualQaChartComponent)
  .service('ManualQaChartService', ManualQaChartService)
;

export default ManualQaChartModule;
