export enum EmSearchTimeFrame {
  PreviousMonth,
  CurrentYear,
  CurrentAndPreviousYear,
  ThreeYears,
  FiveYears,
  TenYears,
  All
}

interface EmStartDateParam {
  NAME: string;
  RESOLUTION?: 'month' | 'year';
  ADD?: number;
  ALL?: boolean;
}

export const START_DATES: Record<EmSearchTimeFrame, EmStartDateParam> = {
  [EmSearchTimeFrame.PreviousMonth]: {
    NAME: 'ACTIONS.SETTINGS.PREVIOUS_MONTH',
    RESOLUTION: 'month',
    ADD: 1
  },
  [EmSearchTimeFrame.CurrentYear]: {
    NAME: 'ACTIONS.SETTINGS.CURRENT_YEAR',
    RESOLUTION: 'year',
    ADD: 0
  },
  [EmSearchTimeFrame.CurrentAndPreviousYear]: {
    NAME: 'ACTIONS.SETTINGS.CURRENT_AND_PREVIOUS_YEAR',
    RESOLUTION: 'year',
    ADD: 1
  },
  [EmSearchTimeFrame.ThreeYears]: {
    NAME: 'ACTIONS.SETTINGS.THREE_YEARS',
    RESOLUTION: 'year',
    ADD: 2
  },
  [EmSearchTimeFrame.FiveYears]: {
    NAME: 'ACTIONS.SETTINGS.FIVE_YEARS',
    RESOLUTION: 'year',
    ADD: 4
  },
  [EmSearchTimeFrame.TenYears]: {
    NAME: 'ACTIONS.SETTINGS.TEN_YEARS',
    RESOLUTION: 'year',
    ADD: 9
  },
  [EmSearchTimeFrame.All]: {
    NAME: 'ACTIONS.SETTINGS.ALL_TIME',
    ALL: true
  }
};
