export default {
  METERING_TYPE: {
    HOURLY: 1,
    MANUAL_READING: 2,
    CONSTANT: 3,
    VIRTUAL: 4,
    MANUAL_CONSUMPTION: 5
  },
  EXCLUDED_QUANTITY_IDS: [12, 14, 15, 84, 85, 86, 87]

};
