<div class="detail-row">
  <div class="columns small-12 medium-12 large-6">
    <h2>{{ 'ALARM.LOG.DETAILS.VALUES' | translate }}</h2>
    <table class="table">
      <tr>
        <td>{{ 'ALARM.LOG.DETAILS.TYPE' | translate }}</td>
        <td>{{ alarm.alarm.alarmTypeId | alarmTypeName }}</td>
      </tr>
      <tr>
        <td>{{ 'ALARM.LOG.DETAILS.TRIGGER_VALUE' | translate }}</td>
        <td>{{ alarm.inspectedValue | adaptiveDecimal }} {{ quantityUnit$ | async }}</td>
      </tr>

      <ng-container *ngIf="hasPercentValue$ | async">
        <tr>
          <td>{{ 'ALARM.LOG.DETAILS.COMPARISON_VALUE' | translate }}</td>
          <td>{{ alarm.comparisonValue | adaptiveDecimal }} {{ quantityUnit$ | async }}</td>
        </tr>
        <tr class="sum-row">
          <td>{{ 'ALARM.LOG.DETAILS.DIFFERENCE' | translate }}</td>
          <td>{{ alarm.valueDifference | adaptiveDecimal }} {{ quantityUnit$ | async }}</td>
        </tr>
      </ng-container>
    </table>
  </div>

  <div class="columns small-12 medium-12 large-6">
    <h2>{{ 'ALARM.LOG.DETAILS.THRESHOLDS' | translate }}</h2>
    <table class="table">
      <tr *ngIf="alarm.absoluteValue">
        <td>{{ 'ALARM.LOG.DETAILS.ABSOLUTE_VALUE' | translate }}</td>
        <td>{{ alarm.absoluteValue | adaptiveDecimal }} {{ quantityUnit$ | async }}</td>
      </tr>
      <tr *ngIf="hasPercentValue$ | async">
        <td>{{ 'ALARM.LOG.DETAILS.CHANGE_PERCENTAGE' | translate }}</td>
        <td>{{ alarm.changePercent | percent: '1.1' }}</td>
      </tr>
    </table>
  </div>
</div>

<div class="detail-row">

  <div class="columns small-12 medium-12 large-6">
    <h2>{{ 'ALARM.LOG.DETAILS.TIME_CONFIGURATION' | translate }}</h2>
    <table class="table">
      <tr>
        <td>{{ 'ALARM.LOG.DETAILS.EXECUTED_AT' | translate }}</td>
        <td>{{ alarm.executedAt | todate | date:'short' }}</td>
      </tr>
      <tr>
        <td>{{ 'ALARM.LOG.DETAILS.INSPECTION_PERIOD' | translate }}</td>
        <td>
          {{ alarm.inspectionPeriodBeginDate | todate | date:'short' }}
          -
          {{ alarm.inspectionPeriodEndDate | todate | date:'short' }}
        </td>
      </tr>
      <tr *ngIf="hasPercentValue$ | async">
        <td>{{ 'ALARM.LOG.DETAILS.COMPARISON_PERIOD' | translate }}</td>
        <td>
          {{ alarm.comparisonPeriodBeginDate | todate | date:'short' }}
          -
          {{ alarm.comparisonPeriodEndDate | todate | date:'short' }}
        </td>
      </tr>
    </table>
  </div>

  <div class="columns small-12 medium-12 large-6">
    <h2>{{ 'ALARM.LOG.ALARM_DETAILS' | translate }}</h2>
    <table class="table">
      <tr>
        <td>{{ 'ADMIN.CONTRACTS.MODIFIED_BY' | translate }}</td>
        <td *ngIf="alarm.externalEditor || alarm.lastModifiedBy; else empty">
          <last-modified-user
            [externalId]="alarm.externalEditor"
            [lastModifiedBy]="alarm.lastModifiedBy"
          ></last-modified-user>
        </td>
      </tr>
      <tr>
        <td>{{ 'ADMIN.CONTRACTS.MODIFIED_DATE' | translate }}</td>
        <td *ngIf="alarm.lastModified; else empty">
          {{ alarm.lastModified | todate | date:'short' }}
        </td>
      </tr>
    </table>
  </div>
</div>

<ng-template #empty>
  <td>-</td>
</ng-template>
