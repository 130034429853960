import { QuantityItem } from '@enerkey/clients/energy-reporting';
import { Facility } from '@enerkey/clients/facility';
import {
  MeterHierarchy,
  MeterManagementMeter,
  SubMeter
} from '@enerkey/clients/meter-management';
import { PoACompanyResponse } from '@enerkey/clients/metering';

import { MeterTag } from '../services/meter-management.service';

export interface MeterHierarchyTreelistItem {
  hierarchyName: string;
  children?: MeterHierarchyTreelistItem[];
  facility?: Facility;
  meter?: MeterManagementMeter;
  poa?: PoACompanyResponse;
  facilityId?: number;
  quantityId?: number;
  isMainMeter?: boolean;
  isRelatedMeter?: boolean;
  isFloatingMeter?: boolean;
  quantityName?: string;
  hierarchyId?: number;
  hierarchyTree?: number[];
  ordinal?: number;
  isHighlightedMeter?: boolean;
  tags?: { [key: string]: boolean };
  tagNames?: string[];
}

export class MeterHierarchyFactory {
  public static getFacilityHierarchy(
    facility: Facility,
    meters: MeterManagementMeter[],
    facilityHierarchies: MeterHierarchy[],
    quantities: Map<number, QuantityItem>,
    quantityNames: Map<number, string>,
    poas: { [key: string]: PoACompanyResponse },
    meterTags: MeterTag,
    highlightMeters: Set<number> = new Set()
  ): MeterHierarchyTreelistItem {
    const facilityMeters = meters.filter(m => m.facilityId === facility.id);
    return new MeterHierarchyFactory(
      facility,
      facilityMeters,
      facilityMeters.toMapBy('id'),
      facilityHierarchies,
      quantities,
      quantityNames,
      highlightMeters,
      poas,
      meterTags
    ).hierarchy;
  }

  private readonly hierarchy: MeterHierarchyTreelistItem;

  private readonly metersInHierarchy = new Set<number>();

  private constructor(
    private readonly facility: Facility,
    private readonly facilityMeters: MeterManagementMeter[],
    private readonly meterMap: Map<number, MeterManagementMeter>,
    private readonly facilityHierarchy: MeterHierarchy[],
    private readonly quantities: Map<number, QuantityItem>,
    private readonly quantityNames: Map<number, string>,
    private readonly highlightMeters: Set<number>,
    private readonly poas: { [key: string]: PoACompanyResponse },
    private readonly meterTags: MeterTag
  ) {
    this.hierarchy = this.getFacility();
  }

  private getFacility(): MeterHierarchyTreelistItem {
    const quantityIds = this.facilityMeters.unique(m => m.quantityId);
    const quantityHierarchyMap = this.getQuantityHierarchyMap(quantityIds);
    return {
      hierarchyName: `${this.facility.enegiaId} - ${this.facility.displayName}`,
      children: quantityIds.mapFilter(
        qId => this.getFacilityQuantity(qId, quantityHierarchyMap.get(qId)),
        q => Array.hasItems(q.children)
      ),
      facilityId: this.facility.id,
    };
  }

  private getQuantityHierarchyMap(quantityIds: number[]): Map<number, MeterHierarchyTreelistItem[]> {
    const quantityHierarchyMap = new Map<number, MeterHierarchyTreelistItem[]>();
    for (const quantityId of quantityIds) {
      const quantityHierarchy = this.facilityHierarchy.filterMap(
        h => this.meterMap.get(h.mainMeter?.meterId)?.quantityId === quantityId,
        h => this.getChildMeter(h.mainMeter, [h.id], true, this.quantities.get(quantityId).Related)
      );
      quantityHierarchyMap.set(quantityId, quantityHierarchy);
    }
    return quantityHierarchyMap;
  }

  private getFacilityQuantity(
    quantityId: number,
    quantityHierarchies: MeterHierarchyTreelistItem[]
  ): MeterHierarchyTreelistItem {
    const quantityName = this.quantityNames.get(quantityId);
    const floatingMeters: MeterHierarchyTreelistItem[] = this.facilityMeters
      .filterMap(
        m => m.quantityId === quantityId && this.meterMap.has(m.id) && !this.metersInHierarchy.has(m.id),
        m => ({
          meter: m,
          poa: this.poas[m.id],
          isFloatingMeter: true,
          facility: this.facility,
          hierarchyName: m.name,
          isRelatedMeter: this.quantities.get(m.quantityId).Related,
          quantityName,
          isHighlightedMeter: this.highlightMeters.has(m.id),
          tags: this.meterTags[m.id].tagIdentifier,
          tagNames: this.meterTags[m.id].tagNames
        })
      );
    return {
      children: [...floatingMeters, ...quantityHierarchies.sortBy(h => h.ordinal)],
      hierarchyName: quantityName,
      quantityId,
    };
  }

  private getChildMeter(
    hierarchyMeter: SubMeter,
    hierarchyTree: number[],
    isMainMeter: boolean,
    isRelatedMeter: boolean
  ): MeterHierarchyTreelistItem {
    const meter = this.meterMap.get(hierarchyMeter.meterId);
    this.metersInHierarchy.add(hierarchyMeter.meterId);
    const relatedMeters = hierarchyMeter.relatedMeters?.filterMap(
      rm => this.meterMap.has(rm.meterId),
      rm => this.getChildMeter(
        rm,
        [...hierarchyTree, hierarchyMeter.id],
        false,
        true
      )
    ) ?? [];
    const subMeters = hierarchyMeter.subMeters?.filterMap(
      sm => this.meterMap.has(sm.meterId),
      sm => this.getChildMeter(
        sm,
        [...hierarchyTree, hierarchyMeter.id],
        false,
        isRelatedMeter
      )
    ) ?? [];
    relatedMeters.forEach(m => {
      this.metersInHierarchy.add(m.meter.id);
    });
    subMeters.forEach(m => {
      this.metersInHierarchy.add(m.meter.id);
    });
    return {
      meter: meter,
      children: [
        ...subMeters.sortBy(h => h.ordinal),
        ...relatedMeters
      ],
      poa: this.poas[meter.id],
      tags: this.meterTags[meter.id].tagIdentifier,
      tagNames: this.meterTags[meter.id].tagNames,
      hierarchyName: meter.name,
      isFloatingMeter: false,
      hierarchyId: hierarchyMeter.id,
      facility: this.facility,
      ordinal: hierarchyMeter.ordinal ?? 0,
      hierarchyTree,
      isMainMeter,
      isRelatedMeter,
      quantityName: this.quantityNames.get(meter.quantityId),
      isHighlightedMeter: this.highlightMeters.has(meter.id)
    };
  }
}
