import templateModule from 'raw-loader!../templates/mrc-change-meter-confirm.html';

export function mrcChangeMeterConfirm() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      step: "=",
      options: "=",
      typeChange: "=",
      mrcState: "="
    },
    controller: [
      '$scope', '$filter', 'MrcConstants',
      function($scope, $filter, MrcConstants) {
        $scope.MrcConstants = MrcConstants;
        $scope.dateFormat = 'dd.MM.yyyy';
        $scope.confirmButtonTxt = $scope.typeChange ? $filter('translate')("MRC.READINGS.WIZARD.STEP_CONFIRM_CALLBACK_TYPE") : $filter('translate')("MRC.READINGS.WIZARD.STEP_CONFIRM_CALLBACK");
        $scope.oldInfoTitle = $filter('translate')('MRC.READINGS.WIZARD.STEP_OLD');
        $scope.newInfoTitle = $filter('translate')('MRC.READINGS.WIZARD.STEP_NEW');
        if($scope.typeChange) {
          $scope.oldInfoTitle = $scope.options.meters.new.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION && !$scope.options.meters.old.WithoutReading ? $filter('translate')('MRC.READINGS.WIZARD.LABELS.OLD_METER_INFO_TITLE') : $filter('translate')('MRC.READINGS.WIZARD.LABELS.OLD_TYPE_INFO_TITLE');
          $scope.newInfoTitle = $scope.options.meters.new.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION ? $filter('translate')('MRC.READINGS.WIZARD.LABELS.NEW_METER_INFO_TITLE') : $filter('translate')('MRC.READINGS.WIZARD.LABELS.NEW_METER_INFO_TITLE_READINGS');
        }
        $scope.meters = [];
        $scope.meters.push(angular.copy($scope.options.meters.old));
        $scope.meters.push(angular.copy($scope.options.meters.new));
      }
    ]
  };
}
