import _ from 'lodash';

const $inject = ['ManualQaSpreadsheetFilterTypeSelectService'];

function ManualQaSpreadsheetFilterTypeSelectController(ManualQaSpreadsheetFilterTypeSelectService) {
  let vm = this;

  vm.model = null;
  vm.items = ManualQaSpreadsheetFilterTypeSelectService.getItems();

  vm.$onInit = onInit;

  function onInit() {
    if (vm.selectedItem) {
      vm.model = _.find(vm.items, { id: vm.selectedItem.id });
    } else {
      vm.model = vm.items[0];
    }
  }
}

ManualQaSpreadsheetFilterTypeSelectController.$inject = $inject;

export default ManualQaSpreadsheetFilterTypeSelectController;
