import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { ModalService } from '@enerkey/foundation-angular';

import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';
import { ProfileManagementService } from '../../services/profile-management.service';
import { ProfileAddModalComponent } from '../profile-add-modal/profile-add-modal.component';
import { ProfileManagementGridComponent } from '../profile-management-grid/profile-management-grid.component';

@Component({
  selector: 'profile-management',
  templateUrl: './profile-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProfileManagementService]
})
export class ProfileManagementComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ProfileManagementGridComponent) public gridComponent: ProfileManagementGridComponent;
  @ViewChild('topbarTemplate')
  private readonly topRightTemplate: TemplateRef<unknown>;

  public constructor(
    private readonly modalService: ModalService,
    private readonly templateLifter: TemplateLifterService
  ) {}

  public addProfile(): void {
    this.modalService.open(ProfileAddModalComponent);
  }

  public updateServices(): void {
    this.gridComponent.massChangeServices();
  }
  public updateCompanies(): void {
    this.gridComponent.massChangeCompanies();
  }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
  }
}
