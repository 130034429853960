// source: http://stackoverflow.com/questions/12931369/click-everywhere-but-here-event
// NOTE: changed the original a bit, listen mousedown and touchstart events instead of click
clickAnywhereButHere.$inject = ['$document', 'clickAnywhereButHereService'];

function clickAnywhereButHere($document, clickAnywhereButHereService) {
  return {
    restrict: 'A',
    link: function(scope, elem, attr, ctrl) {
      var handler = function(e) {
        e.stopPropagation();
      };
      elem.on('click', handler);
      
      scope.$on('$destroy', function(){
        elem.off('click', handler);
      });
      
      clickAnywhereButHereService(scope, attr.clickAnywhereButHere, elem);
    }
  };
}

export default clickAnywhereButHere;
