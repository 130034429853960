import { Pipe, PipeTransform } from '@angular/core';

import { Scenario } from '@enerkey/clients/sustainability';

// https://sciencebasedtargets.org/resources/files/foundations-of-SBT-setting.pdf
const scenarios: Record<Scenario, string> = {
  [Scenario.None]: '-',
  [Scenario._15C]: 'SBTi - 1.5C',
  [Scenario.WB2C]: 'SBTi - WB2C',
  [Scenario.B2DS]: 'SBTi - B2DS',
  [Scenario._2C]: 'SBTi - 2C'
};

@Pipe({ name: 'targetScenarioName' })
export class TargetScenarioNamePipe implements PipeTransform {

  public transform(value: Scenario): string {
    return scenarios[value];
  }

}
