import { ChangeDetectorRef, Directive, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { addDays, addMonths, startOfDay } from 'date-fns';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ControlsOf } from '@enerkey/ts-utils';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';

import { ToasterService } from '../../shared/services/toaster.service';
import { ClipboardService } from '../../shared/services/clipboard.service';
import { ConfigurableApiKey } from '../api-keys/api-keys.component';
import { AuthenticationService } from '../../shared/services/authentication.service';

enum KeyValidityOptions {
  NotRestricted = 0,
  ValidTo = 1,
}

type KeyValidityForm = {
  validTo: Date;
  keyValidityOption: KeyValidityOptions;
  email: string;
  description: string;
};

export interface ApiKeyCreate {
  email: string;
  description: string;
  validTo: Date;
}

@Directive()
@ModalOptions({ size: 'small' })
export abstract class ApiKeyCreateComponent extends ModalBase<void> implements OnDestroy {
  protected abstract createKey(values: ApiKeyCreate): Observable<string>;

  public readonly KeyValidityOptions = KeyValidityOptions;
  public readonly currentDate = new Date();

  public readonly loading$: Observable<boolean>;

  public renewMode: boolean = false;
  public activeKeyType: ConfigurableApiKey;
  public refresh$: Subject<void> | null;

  public generatedApiKey: string = null;

  public readonly createForm: UntypedFormGroup;
  public readonly formControls: ControlsOf<KeyValidityForm>;

  private readonly _loading$ = new LoadingSubject();

  public constructor(
    ngfActiveModal: NgfActiveModal,
    authenticationService: AuthenticationService,
    private readonly toasterService: ToasterService,
    private readonly clipboardService: ClipboardService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngfActiveModal);
    this.loading$ = this._loading$.asObservable();

    const userName = authenticationService.getUsername();
    this.formControls = {
      keyValidityOption: new UntypedFormControl(KeyValidityOptions.ValidTo, Validators.required),
      validTo: new UntypedFormControl(addMonths(startOfDay(new Date()), 1)),
      email: new UntypedFormControl(userName?.includes('@') ? userName : null, Validators.required),
      description: new UntypedFormControl(null),
    };

    this.createForm = new UntypedFormGroup(this.formControls);

    this.formControls.keyValidityOption.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value: KeyValidityOptions) => {
        if (value === KeyValidityOptions.ValidTo) {
          this.formControls.validTo.setValidators([Validators.required]);
        } else {
          this.formControls.validTo.clearValidators();
        }
        this.formControls.validTo.updateValueAndValidity();
      });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public close(): void {
    if (this.generatedApiKey) {
      super.closeModal();
    } else {
      super.dismissModal();
    }
  }

  public createApiKey(): void {
    const formValue: KeyValidityForm = this.createForm.value;

    // 3000-01-01 === no restriction
    const keyValidTo = formValue.validTo && formValue.keyValidityOption === KeyValidityOptions.ValidTo
      ? addDays(formValue.validTo, 1)
      : new Date('3000-01-01');
    this.createKey({
      validTo: keyValidTo,
      email: formValue.email,
      description: formValue.description
    }).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: key => {
        this.toasterService.success('DATA_API_KEYS.KEY_GENERATED_SUCCESSFULLY');
        this.generatedApiKey = key;
        this.changeDetectorRef.detectChanges();
        this.refresh$?.next();
      },
      error: () => this.toasterService.error('DATA_API_KEYS.ERROR_CREATING_KEY')
    });
  }

  public copyToClipboard(): void {
    this.clipboardService.copy(this.generatedApiKey);
    this.toasterService.info('DATA_API_KEYS.KEY_COPIED');
  }
}
