import ManualQaActionsSelectController from './manual-qa-actions-select-controller';
import template from 'raw-loader!./manual-qa-actions-select.html';

export default {
  template: template,
  controller: ManualQaActionsSelectController,
  controllerAs: 'vm',
  bindings: {
    dataSource: '<',
    operationTimeFrame: '<',
    isPreviewMode: '<',
    isLoading: '<',
    fetchType: '<',
    useComparisonPeriod: '<',
    noDefect: '<',
    createPreview: '&',
    clearPreview: '&',
    saveChanges: '&',
    getHourlyModel: '&'
  }
};
