<kendo-chart
  [categoryAxis]="categoryAxis"
  [axisDefaults]="axisDefaults"
  [transitions]="false"
  (seriesClick)="seriesClicked($event)"
>
  <kendo-chart-legend
    [position]="'top'"
    [visible]="showLegend"
  ></kendo-chart-legend>
  <kendo-chart-tooltip [shared]="true">
    <ng-template
      kendoChartSharedTooltipTemplate
      let-category="category"
      let-points="points"
    >
      <widget-chart-tooltip
        [points]="points"
        [category]="category"
        [resolution]="resolution"
        [quantityId]="quantityData.quantityId"
        [isNormalized]="quantityData.isNormalized"
        [relationalValueIds]="quantityData.relationalValueIds"
        [unitKey]="unitKey"
      ></widget-chart-tooltip>
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <ng-container
      *ngFor="let series of quantityData.values; let index = index; let last = last"
    >
      <kendo-chart-series-item
        type="column"
        [data]="series"
        [color]="last ? (quantityData.quantityId | quantityColor) : comparisonPeriodColor"
        [opacity]="0.8"
        [border]="{ width: 0 }"
        [spacing]="0"
        [gap]="showGap ? 1 : 0"
        [field]="valueField"
        [axis]="ConsumptionWidgetAxisName.Consumption"
        [name]="periodTitles ? periodTitles[index] : ''"
      ></kendo-chart-series-item>
      <ng-container *ngIf="quantityData.relationalValueIds">
        <kendo-chart-series-item
          *ngFor="let relationalValueId of quantityData.relationalValueIds"
          type="line"
          [style]="'smooth'"
          [data]="series"
          [color]="relationalValueColors[relationalValueId][index]"
          [markers]="{ visible: false }"
          [field]="relationalValueId | consumptionChartRelationalValueField"
          [axis]="$any(relationalValueId)"
          [name]="(relationalValueId | relationalValueName | async) + ' ' + (periodTitles ? periodTitles[index] : '')"
        >
        </kendo-chart-series-item>
      </ng-container>
    </ng-container>
  </kendo-chart-series>
  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item
      [name]="ConsumptionWidgetAxisName.Consumption"
      [visible]="showAxis"
      [min]="valueAxisOptions.min"
      [max]="valueAxisOptions.max"
    >
    </kendo-chart-value-axis-item>
    <kendo-chart-value-axis-item
      *ngFor="let relationalValueId of quantityData.relationalValueIds"
      [name]="$any(relationalValueId)"
      [visible]="showAxis"
      [color]="relationalValueColors[relationalValueId][0]"
      [min]="valueAxisOptions.relationalMin[relationalValueId]"
      [max]="valueAxisOptions.relationalMax[relationalValueId] * 1.2"
    >
    </kendo-chart-value-axis-item>
  </kendo-chart-value-axis>
</kendo-chart>
