<div class="header">
  <h1 class="thin">
    {{ alarm.alarm.displayName || (('ALARM.TITLE' | translate) + ' (ID ' + alarm.id + ')') }}
  </h1>

  <ng-container *ngIf="userHasCommentsService">
    <button
      *ngIf="alarm.actionId; else showCreateButton"
      class="button button--link button--external-link"
      (click)="showComment(alarm.actionId)"
    >
      {{ 'ALARM.LOG.SHOW_COMMENT' | translate }}
    </button>
    <ng-template #showCreateButton>
      <button
        class="button button--link button--external-link"
        (click)="toComment()"
      >
        {{ 'ALARM.LOG.TO_COMMENT' | translate }}
      </button>
    </ng-template>
  </ng-container>
</div>

<ng-container *ngIf="isImageShown">
  <div *ngIf="(image$ | async) as image; else imageLoading">
    <img
      [src]="image | jpeg"
      alt="{{ 'ALARM.LOG.DETAILS.IMAGE_ALT' | translate }}"
    />
  </div>
  <ng-template #imageLoading>
    <placeholder-image
      *ngIf="!(loadingError$ | async)"
      [width]="1209"
      [height]="516"
      [loading]="true"
    ></placeholder-image>
  </ng-template>
</ng-container>

<alarm-log-entry-status [logItem]="alarm"></alarm-log-entry-status>

<hr>

<alarm-details [alarm]="alarm"></alarm-details>

<hr>

<h2>{{ 'ALARM.LOG.DETAILS.RECEIVERS' | translate }}</h2>
<div class="alarm-info-section">
  <ul *ngIf="alarm.alarm.hookTypeIds.length; else noReceivers">
    <li *ngFor="let hookTypeId of alarm.alarm.hookTypeIds">
      {{ hookTypeId | hookTypeName | async }}
    </li>
  </ul>
</div>

<ng-template #noReceivers>
  <p>{{ 'ALARM.LOG.DETAILS.NO_RECEIVERS' | translate }}</p>
</ng-template>

<hr>

<h2>{{ 'ALARM.LOG.DETAILS.METER_INFO' | translate }}</h2>
<div class="alarm-info-section">
  <info-grid>
    <info-grid-item
      [title]="'METER_ID' | translate"
      [data]="alarm?.meterId"
    ></info-grid-item>

    <info-grid-item
      [title]="'METER_NAME' | translate"
      [data]="alarm?.meterDisplayName"
    ></info-grid-item>

    <info-grid-item
      [title]="'QUANTITY' | translate"
      [data]="alarm?.quantityId | quantityName | async"
    ></info-grid-item>
  </info-grid>
</div>

<hr>

<h2>{{ 'ALARM.LOG.DETAILS.FACILITY_INFO' | translate }}</h2>
<div class="alarm-info-section">
  <info-grid>
    <info-grid-item
      [title]="'FACILITY_NAME' | translate"
      [data]="alarm?.facilityDisplayName"
    ></info-grid-item>
  </info-grid>
</div>
