import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ServiceLevel } from '@enerkey/clients/facility';
import { Dashboard } from '@enerkey/clients/settings';

import { DashboardService } from '../../services/dashboard.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ServiceLevelService } from '../../../../shared/services/service-level.service';
import { ProfileService } from '../../../../shared/services/profile.service';

@Component({
  selector: 'dashboard-root',
  templateUrl: './dashboard-root.component.html',
  styleUrls: ['./dashboard-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DashboardService]
})
export class DashboardRootComponent implements AfterViewInit, OnDestroy {

  public readonly showTabs$: Observable<boolean>;

  private dashboardComponentRef: ComponentRef<DashboardComponent>;

  @ViewChild('dashboardContainer', { read: ViewContainerRef })
  private readonly dashboardContainer: ViewContainerRef;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    profileService: ProfileService,
    serviceLevelService: ServiceLevelService,
    private readonly dashboardService: DashboardService
  ) {
    this.showTabs$ = profileService.profile$.pipe(
      map(() => serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium)),
      takeUntil(this._destroy$)
    );
  }

  public ngAfterViewInit(): void {
    this.dashboardService.activeDashboard$
      .pipe(takeUntil(this._destroy$))
      .subscribe(dashboard => {
        this.createDashboard(dashboard);
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.dashboardComponentRef?.destroy();
  }

  private createDashboard(dashboard: Dashboard): void {
    this.dashboardComponentRef?.destroy();

    this.dashboardComponentRef = this.dashboardContainer.createComponent(DashboardComponent);
    this.dashboardComponentRef.instance.dashboard = dashboard;

    this.dashboardComponentRef.changeDetectorRef.detectChanges();
  }
}
