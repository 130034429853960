import { ToasterType } from '../../../constants/toaster-type';

const $inject = ['utils'];

export class ManualQaPopupService {
  constructor(utils) {
    this.utils = utils;
  }

  /**
   * Shows error pop up
   *
   * @param {string} textTag
   */
  errorPopUp(textTag) {
    this.utils.popUp(ToasterType.ERROR, 'MQA.ERRORS.ERROR_TITLE', textTag, true);
  }

  /**
   * Shows success pop up
   *
   * @param {string} textTag
   */
  successPopUp(textTag) {
    this.utils.popUp(ToasterType.SUCCESS, null, textTag, true);
  }

  /**
   * Show info pop up
   *
   * @param {string} textTag
   */
  infoPopUp(textTag) {
    this.utils.popUp(ToasterType.INFO, null, textTag, true);
  }
}

ManualQaPopupService.$inject = $inject;

export default ManualQaPopupService;
