import _ from 'lodash';

function DropdownMenuItemController() {
  let vm = this;

  vm.handleClick = handleClick;

  function handleClick() {
    if (!vm.isDisabled) {
      _.isFunction(vm.onClick) ? vm.onClick() : _.noop();
    }
  }
}

export default DropdownMenuItemController;