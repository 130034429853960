import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';

import { ModalService } from '@enerkey/foundation-angular';

import { EmailNotificationSettingsComponent } from '../email-notification-settings/email-notification-settings.component';

@Component({
  selector: 'email-notification-unsubscribe',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class EmailNotificationUnsubscribeComponent implements OnInit {

  public constructor(
    private readonly modalService: ModalService,
    private readonly stateService: StateService
  ) {}

  public ngOnInit(): void {
    this.openEmailNotificationModal();
  }

  public openEmailNotificationModal(): void {
    const modal = this.modalService.open(EmailNotificationSettingsComponent);
    modal.result.finally(() => this.stateService.go('dashboard'));
  }
}
