import moment from 'moment';

import mrcConsumptionIntervalInputTemplate from 'ngtemplate-loader!raw-loader!../templates/mrc-consumptions-interval-input.html';
import mrcConsumptionMonthlyInputTemplate  from 'ngtemplate-loader!raw-loader!../templates/mrc-consumptions-monthly-input.html';

MrcConsumptionsController.$inject = [
  '$rootScope', '$scope', '$compile', '$templateCache', '$q', '$timeout', '$window', '$document',
  'utils', 'mrcapi', 'mrcModals', 'mrcDataParser', 'mrcInputValidation', '_', 'MrcConstants'
];

export function MrcConsumptionsController(
  $rootScope, $scope, $compile, $templateCache, $q, $timeout, $window, $document,
  utils, mrcapi, mrcModals, mrcDataParser, mrcInputValidation, _, MrcConstants
) {

  $scope.MrcConstants = MrcConstants;
  $scope.monthlyInputTemplate = mrcConsumptionMonthlyInputTemplate;
  $scope.intervalInputTemplate = mrcConsumptionIntervalInputTemplate;

  if (angular.isUndefined($scope.meter)) {
    $scope.meter = {};
  }
  $scope.meter.readingsTabOpen = false;

  $scope.values = [];
  $scope.meter.missingIntervals = {};
  $scope.meter.missingIntervalYears = [];
  $scope.selectedIntervalYear = 0;
  $scope.loadingIntervals = false;
  $scope.meter.canSaveMissingIntervals = false;
  $scope.locked = false;
  $scope.dataReady = false;
  $scope.valueSum = 0;
  $scope.emptyYear = true;

  $scope.intervalPages = {
    current: 0,
    last: 0,
    visibleSegment: [],
    maxVisible: 5,
    itemsOnPage: 10
  };

  $scope.meter.newIntervalValue = {
    fromDate: null,
    toDate: null,
    value: '',
    done: false,
    fromDateValid: true,
    toDateValid: true
  };

  $scope.intervalSorting = {
    param: 'interval.from',
    reverse: true
  };

  $scope.$watch('meter.tabs.intervals.active', function(n, o) {
    $scope.meter.readingsTabOpen = n;
    if (n !== o && n) {
      $scope.getAllYearsWithRecordingGaps();
    } else {
      $scope.meter.missingIntervals = {};
    }
    $scope.$emit('checkMissingIntervals');
  });

  $scope.$watch('meter.isOpen', function(n, o) {
    if (n !== o && n && $scope.meter.tabs.intervals.active) {
      $scope.getAllYearsWithRecordingGaps();
    } else {
      $scope.meter.missingIntervals = {};
      $scope.meter.canSaveMissingIntervals = $scope.hasIntervalValues();
    }
    $scope.$emit('checkMissingIntervals');
  });

  $scope.sortIntervalList = function(newParam) {
    var doReverse = false;
    if ($scope.intervalSorting.param === newParam) {
      $scope.intervalSorting.reverse = !$scope.intervalSorting.reverse;
      doReverse = true;
    } else {
      $scope.intervalSorting.param = newParam;
    }
    var temp = _.sortBy(angular.copy($scope.meter.missingIntervals[$scope.selectedIntervalYear]), $scope.intervalSorting.param);
    if (doReverse) {
      $scope.meter.missingIntervals[$scope.selectedIntervalYear] = temp.reverse();
    } else {
      $scope.meter.missingIntervals[$scope.selectedIntervalYear] = temp;
    }
    updateVisibleSegment();
  };

  $scope.viewReady = function() {
    $scope.facility.controlsReady = true;
  };

  function updateVisibleSegment() {
    var start = $scope.intervalPages.current - Math.floor($scope.intervalPages.maxVisible / 2);
    var add = 0;
    if (start < 0) {
      add = Math.abs(start);
      start = 0;
    }
    var end = $scope.intervalPages.current + Math.floor($scope.intervalPages.maxVisible / 2) + add;
    if (end > $scope.intervalPages.last) {
      add = end - $scope.intervalPages.last;
      start -= add;
      end = $scope.intervalPages.last;
      if (start < 0) {
        start = 0;
      }
    }
    $scope.intervalPages.visibleSegment = [];
    for (var i = start; i <= end; i++) {
      $scope.intervalPages.visibleSegment.push(i);
    }
  }

  $scope.initPagination = function() {
    $scope.intervalPages.current = 0;
    if ($scope.meter.missingIntervalYears.length > 0 && $scope.meter.missingIntervalYears.indexOf($scope.selectedIntervalYear) < 0) {
      $scope.selectedIntervalYear = $scope.meter.missingIntervalYears[0];
    }
    if (angular.isDefined($scope.meter.missingIntervals[$scope.selectedIntervalYear])) {
      var last = ($scope.meter.missingIntervals[$scope.selectedIntervalYear].length - 1) / $scope.intervalPages.itemsOnPage;
      $scope.intervalPages.last = last <= 0 ? 0 : Math.floor(last);
      updateVisibleSegment();
    }
  };

  $scope.switchPage = function(newPage) {
    if (newPage >= 0 && newPage <= $scope.intervalPages.last) {
      $scope.intervalPages.current = newPage;
      updateVisibleSegment();
    }
  };

  $scope.switchSelectedYear = function(newYear) {
    $scope.selectedIntervalYear = newYear;
    $scope.initPagination();
  };

  $scope.yearValueInputAllowed = function() {
    var found = _.find($scope.values, function(value) {
      return value.IsEditable === false;
    });
    return angular.isUndefined(found);
  };

  $scope.isEmptyYear = function() {
    var found = _.find($scope.values, function(value) {
      return value.Value !== null;
    });
    return angular.isUndefined(found);
  };

  $scope.getSumOfValues = function() {
    var filterNullValues = function(consumptionData) {
      return consumptionData.Value !== null;
    };

    var calculateTotal = function(total, consumptionData) {
      return total + consumptionData.Value;
    };

    var values = _($scope.values)
      .filter(filterNullValues)
      .value()
    ;

    return values.length !== 0 ? values.reduce(calculateTotal, 0) : null;
  };

  $scope.getRecordGapsForCurrentYear = function() {
    var timef = { from: new Date(Date.UTC($scope.facility.selectedYear, 0, 1, 0, 0, 0)).toJSON(),
                    to: new Date(Date.UTC($scope.facility.selectedYear, 11, 31, 23, 59, 59)).toJSON() };

    mrcapi.getConsumptionRecordGapsForMeter($scope.facility.FacilityId, $scope.meter.Id, timef).then(
        function(res) {
          if (res.length > 0) {
            res.forEach(function(gap) {
              if (angular.isUndefined($scope.meter.missingIntervals[$scope.facility.selectedYear])) {
                $scope.meter.missingIntervals[$scope.facility.selectedYear] = [];
              }
              $scope.meter.missingIntervals[$scope.facility.selectedYear].push({ from: gap.beginning, to: gap.end, value: '', done: false, disabled: false });
            });
          }
        },
        function() {
          utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_RECORD_GAPS', true);
        }
    );
  };

  $scope.getTimeFrameForCompleteMonth = function(monthIdx) {
    var timef = { from: new Date(Date.UTC($scope.facility.selectedYear, monthIdx, 1, 0, 0, 0)).toJSON(),
                to: new Date(Date.UTC($scope.facility.selectedYear, monthIdx + 1, 0, 0, 0, 0)).toJSON() };
    return timef;
  };

  $scope.getTimeFrameForAvailableYears = function() {
    var currentYear = new Date().getFullYear();
    var earliestYear = $scope.mrcState.earliestConsumptionYear;
    var timef = { from: new Date(Date.UTC(earliestYear, 0, 1, 0, 0, 0)).toJSON(),
                to: new Date(Date.UTC(currentYear, 11, 31, 23, 59, 59)).toJSON() };
    return timef;
  };

  $scope.getAllYearsWithRecordingGaps = function() {
    var newIntervals = {};
    $scope.facility.canSaveAllIntervals = false;
    $scope.loadingIntervals = true;
    var timef = $scope.getTimeFrameForAvailableYears();
    mrcapi.getConsumptionRecordGapsForMeter($scope.facility.FacilityId, $scope.meter.Id, timef).then(
        function(res) {
          if (res.length > 0) {
            res.forEach(function(gap) {
              var startYear = new Date(gap.beginning).getFullYear();
              if (angular.isUndefined(newIntervals[startYear])) {
                newIntervals[startYear] = [];
              }
              newIntervals[startYear].push({ from: gap.beginning, to: gap.end, value: '', done: false, disabled: false });
            });
          }
        },
        function() {
          utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_RECORD_GAPS', true);
        }
    ).finally(function() {
      $scope.loadingIntervals = false;
      $scope.meter.missingIntervals = angular.copy(newIntervals);
      $scope.meter.missingIntervalYears = Object.keys($scope.meter.missingIntervals).reverse();
      if ($scope.selectedIntervalYear === 0) {
        $scope.selectedIntervalYear = $scope.meter.missingIntervalYears[0];
      }
      $scope.initPagination();
    });
  };

  function hasMissingIntervalsForMonth(monthIdx) {
    var ret = false;
    if (angular.isDefined($scope.meter.missingIntervals[$scope.facility.selectedYear])) {
      _.each($scope.meter.missingIntervals[$scope.facility.selectedYear], function(interval) {
        var from = new Date(interval.from).getUTCMonth();
        var to = new Date(interval.to).getUTCMonth();
        if (monthIdx === from || monthIdx === to) {
          ret = true;
          return ret;
        }
      });
    }
    return ret;
  }

  $scope.getValueIndexByCellIndex = function(cellIdx) {
    var idx = -1;
    if ($scope.values.length > 0) {
      idx = _.findIndex($scope.values, function(value) {
        return cellIdx === moment(value.ValueDate).month();
      });
    }
    return idx;
  };

  $scope.collectMissingIntervalsForMonth = function(monthIdx) {
    return $q(function(resolve) {
      var monthly = [];
      if (hasMissingIntervalsForMonth(monthIdx)) {
        _.each($scope.meter.missingIntervals[$scope.facility.selectedYear], function(interval) {
          var b = new Date(interval.from);
          var e = new Date(interval.to);

          if (b.getUTCMonth() === monthIdx || e.getUTCMonth() === monthIdx) {
            var beginning = '';
            var end = '';
            if (b.getUTCMonth() !== e.getUTCMonth()) {
              var month = b.getUTCMonth() === monthIdx ? b.getUTCMonth() : e.getUTCMonth();
              if (month < e.getUTCMonth()) {
                // is beginning so cut ending
                beginning = interval.from;
                end = new Date(Date.UTC($scope.facility.selectedYear, (month + 1), 0)).toJSON();
              } else {
                // is the end so cut beginning
                beginning = new Date(Date.UTC($scope.facility.selectedYear, month, 1)).toJSON();
                end = interval.to;
              }
            } else {
              beginning = interval.from;
              end = interval.to;
            }
            monthly.push({ from: beginning, to: end, value: '', done: false, disabled: false });
          }
        });
        resolve(monthly);
      } else {
        // not found already, so fetch from backend
        monthly = $scope.getRecordGapsForMonth(monthIdx);
        resolve(monthly);
      }
    });
  };

  $scope.getRecordGapsForMonth = function(monthIdx) {
    return $q(function(resolve) {
      var monthly = [];
      var timef = $scope.getTimeFrameForCompleteMonth(monthIdx);

      mrcapi.getConsumptionRecordGapsForMeter($scope.facility.FacilityId, $scope.meter.Id, timef).then(
            function(res) {
              if (res.length > 0) {
                res.forEach(function(gap) {
                  var found = _.find($scope.meter.missingIntervals[$scope.facility.selectedYear], function(interval) {
                    return (interval.beginning === gap.beginning && interval.end === gap.end);
                  });
                  if (angular.isUndefined(found)) {
                    monthly.push({ from: gap.beginning, to: gap.end, value: '', done: false, disabled: false });
                  }
                });
              }
            },
            function() {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_RECORD_GAPS', true);
            }
        ).finally(function() {
          resolve(monthly);
        });
    });
  };

  $scope.hasIntervalValues = function() {
    var ret = false;
    _.each($scope.meter.missingIntervalYears, function(year) {
      _.each($scope.meter.missingIntervals[year], function(interval) {
        if (interval.value && interval.value.length > 0 && angular.isDefined(interval.from) && angular.isDefined(interval.to)) {
          ret = true;
          return ret;
        }
      });
      if (ret) {
        return false;
      }
    });
    return ret;
  };

  $scope.saveNewInterval = function() {
    var parsed = angular.copy($scope.meter.newIntervalValue.value);

    if (angular.isString(parsed)) {
      parsed = parsed.trim().length <= 0 ? null : parsed;
      if (parsed !== null) {
        parsed = parseFloat(parsed.replace(',', $scope.mrcState.radix));
      }
    }
    var timef = { from: new Date(Date.UTC($scope.meter.newIntervalValue.fromDate.getFullYear(), $scope.meter.newIntervalValue.fromDate.getMonth(), $scope.meter.newIntervalValue.fromDate.getDate())),
        to: new Date(Date.UTC($scope.meter.newIntervalValue.toDate.getFullYear(), $scope.meter.newIntervalValue.toDate.getMonth(), $scope.meter.newIntervalValue.toDate.getDate())) };
    var vals = [{ timeFrame: timef, value: parsed }];
    var props = { meterId: $scope.meter.Id, convert: $scope.meter.convertValues };
    $scope.$emit('newIntervalValuesUpdate', { values: vals, properties: props });
  };

  $scope.saveMissingInterval = function(index) {
    var parsed = angular.copy($scope.meter.missingIntervals[$scope.selectedIntervalYear][index].value);
    if (angular.isString(parsed)) {
      parsed = parseFloat(parsed.replace(',', $scope.mrcState.radix));
    }
    var timef = { from: $scope.meter.missingIntervals[$scope.selectedIntervalYear][index].from, to: $scope.meter.missingIntervals[$scope.selectedIntervalYear][index].to };
    var vals = [{ timeFrame: timef, value: parsed }];
    var props = { meterId: $scope.meter.Id, convert: $scope.meter.convertValues };
    $scope.$emit('missingIntervalValuesUpdate', { values: vals, properties: props });
  };

  $scope.onSaveValues = function(yearValues) {
    var props = { meterId: $scope.meter.Id, convert: $scope.meter.convertValues ? !$scope.meter.convertValues : $scope.meter.convertValues };

    $scope.$broadcast('unconfirmedValuesUpdate', { values: angular.copy(yearValues) });
    $scope.$emit('monthlyValuesUpdate', { values: yearValues, properties: props, silent: true });
  };

  $scope.dateChanged = function(attrs) {
    var previousValidation = $scope.meter.newIntervalValue.fromDateValid && $scope.meter.newIntervalValue.toDateValid;
    $scope.meter.newIntervalValue = mrcInputValidation.validateIntervalDateInput(attrs.picker, $scope.meter.newIntervalValue);
    if ((!$scope.meter.newIntervalValue.fromDateValid || !$scope.meter.newIntervalValue.toDateValid) && previousValidation) {
      $scope.facility.invalidDateValues += 1;
    } else {
      if ($scope.meter.newIntervalValue.fromDateValid && $scope.meter.newIntervalValue.toDateValid && !previousValidation) {
        $scope.facility.invalidDateValues -= 1;
      }
    }
    $scope.$emit('dateChanged', { meterId: $scope.meter.Id });
  };

  $scope.$on('inputValueChanged', function(e, params) {
    if (params.name.startsWith('inputField')) {
      $scope.meter.canSaveMissingIntervals = $scope.hasIntervalValues() && $scope.facility.invalidInputValues === 0;
    }
  });

  $scope.$on('meterValuesReceived', function(e, params) {
    if (params.id === $scope.meter.Id && angular.isDefined(params.values)) {
      $scope.values = params.values;
      $scope.valueSum = $scope.getSumOfValues();
      $scope.emptyYear = $scope.isEmptyYear();
      $scope.locked = !$scope.yearValueInputAllowed();
      $scope.dataReady = true;
    }
  });

  $scope.$on('monthValueSaved', function(e, params) {
    if (params.meterId === $scope.meter.Id && params.targetYear === $scope.facility.selectedYear) {
      if ($scope.meter.convertValues) {
        params.value = mrcDataParser.convertReading(params.value, 0.001);
      }
      if (angular.isDefined($scope.values[params.cellIdx])) {
        $scope.values[params.cellIdx].Value = params.value;
        $scope.valueSum = $scope.getSumOfValues();
        $scope.emptyYear = $scope.isEmptyYear();
      }
    }
  });

  $scope.$on('updateIntervalsListing', function(e, params) {
    if (params.meterId === $scope.meter.Id && $scope.meter.readingsTabOpen && $scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
      $scope.getAllYearsWithRecordingGaps();
    }
  });

  $scope.$watch('facility.selectedYear', function(n, o) {
    if (n !== o) {
      $scope.dataReady = false;
      $scope.values = [];
      $scope.valueSum = 0;
    }
  });

  $scope.$on('intervalInputModeActivated', function() {
    $scope.dataReady = false;
    $scope.values = [];
    $scope.valueSum = 0;
  });
}
