import { YearlyTrendReportComponent } from '../../shared/energy-reporting-shared/components/yearly-trend-report/yearly-trend-report.component';
import { EnerkeyNg2StateDeclaration } from '../../shared/routing';

type ReportingStateName = 'facilities.yearly-trend';

type ReportingStateDeclaration = EnerkeyNg2StateDeclaration<ReportingStateName>;

export const yearlyTrendReportState: ReportingStateDeclaration = {
  name: 'facilities.yearly-trend',
  views: {
    report: {
      component: YearlyTrendReportComponent
    }
  },
  url: '/report/yearly',
  data: {
    bookmark: {
      enabled: true
    }
  }
};
