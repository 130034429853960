import { ManualQaInspectModalService } from './manual-qa-inspect-modal.service';
import SearchTypeRadioModule from '../search-type-radio';

const dependencies = [SearchTypeRadioModule.name];

const ManualQaInspectModalModule = angular
  .module('app.modules.manual-qa.components.manual-qa-inspect-modal', dependencies)
  .service('ManualQaInspectModalService', ManualQaInspectModalService)
;

export default ManualQaInspectModalModule;
