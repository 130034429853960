import { UIStateNg1, UIStateNg2 } from '../../shared/routing';

export type TopbarState = UIStateNg2 | UIStateNg1;

type TopbarTabs = {
  visibleTabs: TopbarState[];
  overflowTabs: TopbarState[];
}

export function getVisibleAndOverflowTabs(
  containerWidth: number,
  overflowButtonWidth: number,
  topbarTabs: { state: TopbarState; width: number }[]
): TopbarTabs {
  const visibleTabs: TopbarState[] = [];
  const overflowTabs: TopbarState[] = [];

  let currentWidth = 0;
  let overflowing = false;

  for (const [index, tab] of topbarTabs.entries()) {
    if (
      !overflowing &&
      (((currentWidth + tab.width + overflowButtonWidth) < containerWidth) ||
        (index === topbarTabs.length - 1 && (currentWidth + tab.width) < containerWidth))
    ) {
      currentWidth += tab.width;
      visibleTabs.push(tab.state);
    } else {
      overflowing = true;
      overflowTabs.push(tab.state);
    }
  }
  return { visibleTabs, overflowTabs };
}
