import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { getNumericEnumValues } from '@enerkey/ts-utils';
import { RequestStatus } from '@enerkey/clients/webhook';

import { EkComboComponent } from '../ek-combo/ek-combo.component';

const requestStatusTranslations: Record<RequestStatus, string> = {
  [RequestStatus.Undefined]: 'SUPERVISION.REQUEST_STATUS.UNDEFINED',
  [RequestStatus.Success]: 'SUPERVISION.REQUEST_STATUS.SUCCESS',
  [RequestStatus.Failed]: 'SUPERVISION.REQUEST_STATUS.FAILED',
  [RequestStatus.Running]: 'SUPERVISION.REQUEST_STATUS.RUNNING',
};

@Component({
  selector: 'status-select',
  templateUrl: '../ek-combo/ek-combo.component.html',
  styleUrls: ['../ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => StatusSelectComponent),
    multi: true
  }]
})
export class StatusSelectComponent extends EkComboComponent<RequestStatus> {
  public override items = getNumericEnumValues(RequestStatus).map(option => ({
    value: option,
    text: requestStatusTranslations[option]
  }))
}
