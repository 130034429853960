/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ToasterType } from '../../../constants/toaster-type';
import { TerminalName } from './modal-configurations-controller';

const $inject = [
  '$modalInstance', 'utils', 'configurationService', 'readerTypeId', 'readerBaseProperties',
  'readerSpecificProperties', 'terminals'
];

const idUndefined = -1;

class ConfigurationEditInheritedController {
  public selectedTerminal: TerminalName;

  public constructor(
    private $modalInstance: any,
    private utils: any,
    private configurationService: any,
    private readerTypeId: number,
    private readerBaseProperties: any,
    private readerSpecificProperties: any,
    public terminals: TerminalName[]
  ) {
    this.initialize();
  }

  public save(): void {
    this.configurationService.save(this.readerTypeId, this.readerBaseProperties, this.readerSpecificProperties)
      .then(() => {
        this.utils.popUp(
          ToasterType.INFO,
          this.utils.localizedString('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_SAVED')
        );
        this.$modalInstance.close({ saved: true });
      })
      .catch(() => {
        this.utils.popUp(
          ToasterType.ERROR,
          this.utils.localizedString('CONFIGURATION_CONTROL.ERROR_TEXT_POST_CONFIGURATION_FAIL')
        );
      });
  }

  public cancel(): void {
    this.$modalInstance.dismiss();
  }

  public onTerminalNameSelected(terminal: TerminalName): void {
    this.setSelectedTerminal(terminal);
  }

  private initialize(): void {
    if (!this.terminals.find(t => t.id === idUndefined)) {
      const notSelectedOption = new TerminalName(idUndefined,
        this.utils.localizedString('CONFIGURATION_CONTROL.TERMINAL.NOT_SELECTED'));
      this.terminals?.splice(0, 0, notSelectedOption);
    }
    const terminalIdProperty = this.readerBaseProperties.find((p: any) => p.name === 'terminalId');
    const terminal = terminalIdProperty?.value
      ? this.terminals.find(t => t.id === terminalIdProperty.value)
      : this.terminals[0];
    this.setSelectedTerminal(terminal);
  }

  private setSelectedTerminal(terminal: TerminalName): void {
    const terminalNameProperty = this.readerBaseProperties.find((p: any) => p.name === 'terminalName');
    const terminalIdProperty = this.readerBaseProperties.find((p: any) => p.name === 'terminalId');
    if (terminal.id > 0 && terminalNameProperty && terminalIdProperty) {
      terminalNameProperty.value = terminal.name;
      terminalIdProperty.value = terminal.id;
    } else {
      terminalNameProperty.value = null;
      terminalIdProperty.value = null;
    }

    this.selectedTerminal = terminal;
  }
}

ConfigurationEditInheritedController.$inject = $inject;

export default ConfigurationEditInheritedController;
