import { DashType, LineStyle } from '@progress/kendo-angular-charts';

export enum ConsumptionTargetTypeId {
  TargetA = 'TargetA',
  TargetB = 'TargetB',
  BaseLevel = 'BaseLevel',
  PlannedLevel = 'PlannedLevel',
  ActionsBased = 'ActionsBased',
  CostTargetA = 'CostTargetA',
  CostTargetB = 'CostTargetB',
  CostTargetASales = 'TargetASales',
  CostTargetADistribution = 'TargetADistribution',
  CostTargetBSales = 'TargetBSales',
  CostTargetBDistribution = 'TargetBDistribution',
}

export const costTargetTypes = [
  {
    cost: ConsumptionTargetTypeId.CostTargetA,
    target: ConsumptionTargetTypeId.TargetA,
  },
  {
    cost: ConsumptionTargetTypeId.CostTargetB,
    target: ConsumptionTargetTypeId.TargetB,
  },
  {
    cost: ConsumptionTargetTypeId.CostTargetASales,
    target: ConsumptionTargetTypeId.CostTargetASales,
  },
  {
    cost: ConsumptionTargetTypeId.CostTargetADistribution,
    target: ConsumptionTargetTypeId.CostTargetADistribution,
  },
  {
    cost: ConsumptionTargetTypeId.CostTargetBSales,
    target: ConsumptionTargetTypeId.CostTargetBSales,
  },
  {
    cost: ConsumptionTargetTypeId.CostTargetBDistribution,
    target: ConsumptionTargetTypeId.CostTargetBDistribution,
  },
];

export interface ConsumptionTargetType {
  Id: ConsumptionTargetTypeId;
  Name: string;
  Color: string;
  DashType: DashType;
  Style: LineStyle;
  RequireConsumptionTargetsService: boolean;
  RequireActionsService: boolean;
}
