import { MeteringType } from '@enerkey/clients/metering';

export const meterTypeTranslation: Record<MeteringType, string> = {
  [MeteringType.Hourly]: 'METER_TYPES.HOURLY',
  [MeteringType.ManualReading]: 'METER_TYPES.MANUAL_READING',
  [MeteringType.Constant]: 'METER_TYPES.CONSTANT',
  [MeteringType.Virtual]: 'METER_TYPES.VIRTUAL',
  [MeteringType.ManualConsumption]: 'METER_TYPES.MANUAL_CONSUMPTION',
};

export enum MeterResolution {
  PT3M = 'PT3M',
  PT15M = 'PT15M',
  PT1H = 'PT1H',
  P1D = 'P1D',
  P1M = 'P1M',
  P1Y = 'P1Y',
}
