import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ServiceLevel } from '@enerkey/clients/facility';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { WidgetBase } from '../../shared/widget-base.interface';
import { Comparability } from '../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { TimeFrameOptions } from '../../../../constants/time-frame';
import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { ErTimeFrameService, TimeFrameResult } from '../../../energy-reporting/services/er-time-frame.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { ConsumptionsWidgetService } from '../../services/consumptions-widget.service';
import { QuantityChartData } from '../../../../shared/energy-reporting-shared/shared/consumptions';

import { ServiceLevelService } from '../../../../shared/services/service-level.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';

export interface ConsumptionsWidgetOptions {
  comparableOption: Comparability;
  comparisonPeriodOption: 'Default' | number;
  selectedQuantities: Quantities[];
  timeFrameOption: TimeFrameOptions;
  unitKey: ReportingUnit;
  valueOption: ValueType;
  variableId: number;
}

@Component({
  selector: 'consumptions-widget',
  templateUrl: './consumptions-widget.component.html',
  styleUrls: ['./consumptions-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConsumptionsWidgetService]
})
export class ConsumptionsWidgetComponent implements WidgetBase<ConsumptionsWidgetOptions>, OnDestroy, OnInit {
  public dataModelOptions: ConsumptionsWidgetOptions;
  public readonly dataModelChange$ = EMPTY;
  public readonly loading$: Observable<boolean>;
  public readonly data$: Observable<QuantityChartData[]>;
  public readonly error$: Observable<void>;

  public readonly hasServiceLevelMedium: boolean;

  public inspectionPeriodTitle: string;
  public comparisonPeriodTitle: string;
  public start: TimeFrameResult;

  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly consumptionsWidgetService: ConsumptionsWidgetService,
    private readonly stateService: StateService,
    serviceLevelService: ServiceLevelService,
    thresholdService: ThresholdService,
    facilityService: FacilityService,
    private readonly erTimeFrameService: ErTimeFrameService
  ) {
    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();

    this.hasServiceLevelMedium = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);

    this.data$ = combineLatest([
      facilityService.filteredProfileFacilityIds$,
      thresholdService.threshold$
    ]).pipe(
      switchMap(([facilityIds, threshold]) => this.consumptionsWidgetService.getData(
        this.dataModelOptions,
        this.start,
        facilityIds,
        threshold
      ).pipe(
        indicate(this._loading$),
        catchError(() => {
          this._error$.next();
          return EMPTY;
        })
      )),
      takeUntil(this._destroy$)
    );
  }

  public ngOnInit(): void {
    this.start = this.erTimeFrameService.getTimeFrameAndResParams(
      this.dataModelOptions.timeFrameOption,
      this.dataModelOptions.comparisonPeriodOption as string
    );

    this.comparisonPeriodTitle = this.start.Start[1]?.key;
    this.inspectionPeriodTitle = this.start.Start[0].key;
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._error$.complete();
    this._loading$.complete();
  }

  public openSumReport(quantityData: QuantityChartData): void {
    if (!this.hasServiceLevelMedium) {
      return;
    }

    const series = {
      Measured: !quantityData.isNormalized,
      Normalized: quantityData.isNormalized,
      RelationalUnitIds: quantityData.relationalValueIds?.[0] ? [quantityData.relationalValueIds[0]] : [],
      Comparables: this.dataModelOptions.comparableOption,
      TimeFrame: this.start.TimeFrame,
      Resolution: this.start.Resolution,
      Start: this.start.Start
    };
    this.stateService.go('facilities.report-sum', {
      quantityId: quantityData.quantityId,
      facilityId: quantityData.facilityIds,
      series: series,
      unitKey: this.dataModelOptions.unitKey
    });
  }
}
