import { Injectable, Injector } from '@angular/core';

import { ModalSize } from './modal-size';

/**
 * Options available when opening new modal windows with `NgfModal.open()` method.
 */
export interface NgfModalOptions {
  /**
   * `aria-labelledby` attribute value to set on the modal window.
   *
   */
  ariaLabelledBy?: string;

  /**
   * If `true`, the backdrop element will be created for a given modal.
   *
   * Alternatively, specify `'static'` for a backdrop which doesn't close the modal on click.
   *
   * Default value is `true`.
   */
  backdrop?: boolean | 'static';

  /**
   * Callback right before the modal will be dismissed.
   *
   * If this function returns:
   * * `false`
   * * a promise resolved with `false`
   * * a promise that is rejected
   *
   * then the modal won't be dismissed.
   */
  beforeDismiss?: () => boolean | Promise<boolean>;

  /**
   * If `true`, the modal will be centered vertically.
   *
   * Default value is `false`.
   *
   */
  centered?: boolean;

  /**
   * A selector specifying the element all new modal windows should be appended to.
   *
   * If not specified, will be `body`.
   */
  container?: string;

  /**
   * The `Injector` to use for modal content.
   */
  injector?: Injector;

  /**
   * If `true`, the modal will be closed when `Escape` key is pressed
   *
   * Default value is `true`.
   */
  keyboard?: boolean;

  /**
   * Size of a new modal window.
   */
  size?: ModalSize;

  /**
   * A custom class to append to the modal window.
   */
  windowClass?: string;

  /**
   * A custom class to append to the modal backdrop.
   *
   */
  backdropClass?: string;

  /**
   * Optional context used instead of builtin one if modal content is a TemplateRef
   */
  context?: Record<string, unknown>
}

/**
 * A configuration service for the [`ModalService`](#/components/modal/api#NgfModal) service.
 *
 * You can inject this service, typically in your root component, and customize the values of its properties in
 * order to provide default values for all modals used in the application.
 *
 */
@Injectable({ providedIn: 'root' })
export class NgfModalConfig implements NgfModalOptions {
  public backdrop: boolean | 'static' = true;
  public keyboard = true;
}
