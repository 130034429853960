<kendo-multiselect
  [data]="options"
  [filterable]="true"
  [disabled]="disabled"
  [textField]="textField"
  [valueField]="valueField"
  [kendoDropDownFilter]="filterSettings"
  [valuePrimitive]="true"
  [placeholder]="'ENERGY_MANAGEMENT.CONSUMPTION_TARGET.CHOOSE_QUANTITY' | translate"
  [autoClose]="false"
  [value]="value"
  [tagMapper]="tagMapper"
  [loading]="loading"
  [popupSettings]="{ animate: false }"
  (valueChange)="valueChanged($event)"
  (blur)="blur()"
>
  <span *kendoMultiSelectItemTemplate="let dataItem">
    <quantity-icon [quantity]="dataItem.value"></quantity-icon>&nbsp;
    {{ dataItem.key }}
  </span>
  <quantity-icon
    *kendoMultiSelectTagTemplate="let dataItem"
    [quantity]="dataItem.value"
  ></quantity-icon>
  <span *kendoMultiSelectGroupTagTemplate="let dataItems">
    <ng-container *ngFor="let dataItem of dataItems | slice:0:overflowThreshold">
      <quantity-icon [quantity]="$any(dataItem).value"></quantity-icon>&nbsp;
    </ng-container>
    + {{ dataItems.length - overflowThreshold }} {{ 'MORE' | translate | lowercase }}
  </span>
  <ng-template kendoMultiSelectHeaderTemplate>
    <div
      *ngIf="selectAllEnabled"
      class="multi-select-header"
    >
      <button
        (click)="selectAll()"
        kendoButton
        [disabled]="options.length === 0"
      >
        &#10003;&nbsp;{{ 'MULTISELECT.SELECT_ALL' | translate }}
      </button>
    </div>
  </ng-template>
</kendo-multiselect>
