import _ from 'lodash';

const cache = {}; // Cache for type and objectId
let id = 0; // Internal id for type and objectId -pairs

export function getSortNumber(type, id, apiIndex, modifier) {
  type = parseInt(type, 10) || 0;
  id = parseInt(id, 10) || 0;
  apiIndex = parseInt(apiIndex, 10) || 0;
  modifier = parseInt(modifier, 10) || 0;

  // Generate sort number that has four digits. e.g: 1000
  return ((type * 1000) + (getIdForType(type, id) * 100) + (apiIndex * 10) + modifier);
}

function getIdForType(type, objectId) {
  if (objectId === 0) {
    return 0;
  }

  if (!cache[type]) {
    cache[type] = {};
  }

  if (!cache[type][objectId]) {
    cache[type][objectId] = ++id;
  }

  return cache[type][objectId];
}

export function sort(columns) {
  const clonedColumns = _.cloneDeep(columns); // Don't mutate original array

  const sorter = function(columnA, columnB) {
    if (columnA.sort === columnB.sort) {
      const columnAIsBigger = columnA.sortDate > columnB.sortDate ? 1 : 0;
      return (columnA.sortDate < columnB.sortDate) ? -1 : columnAIsBigger;
    }

    return columnA.sort > columnB.sort ? 1 : -1;
  };

  return clonedColumns.sort(sorter);
}
