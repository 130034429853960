<facility-select-header [formControl]="facilityIdControl"></facility-select-header>
<ng-container *ngIf="(data$ | async) as data">
  <div
    *ngIf="data.hasData; else noData"
    class="emission-widget-pie-chart-section"
  >
    <div>
      <div>
        <h3>
          {{ relationalValueId$ | relationalValueName | async }}
        </h3>
        <div *ngIf="years$ | async as years">
          <span>{{ years.current }}</span>
          <span class="previous-year">&nbsp;({{ years.previous }})</span>
        </div>
      </div>
    </div>
    <emissions-widget-pie-chart [data]="data.aggregateData"></emissions-widget-pie-chart>
    <div class="chart-section-spread">
      <div>{{ emissionTitle$ | async | translate }}</div>
      <div>
        <div>{{ 'EMISSIONS_WIDGET.CONSUMPTION_CHANGE_TITLE' | translate }}</div>
        <div
          class="consumption-change"
          [ekValueChange]="(data$ | async)?.aggregateData.consumptionChange"
        ></div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noData>
  <div class="no-emissions">
    {{ 'EMISSIONS_WIDGET.NO_EMISSIONS' | translate }}
  </div>
</ng-template>
<div class="emission-widget-chart-section">
  <div class="emission-chart-header">
    <h3 *ngIf="years$ | async as years">
      {{ 'EMISSIONS_WIDGET.EMISSIONS_HISTORY_SECTION_TITLE' | translate }} {{ years.first }}-{{ years.current }}
    </h3>
  </div>
  <emissions-widget-chart
    *ngIf="(data$ | async) as data"
    [data]="data.chartData"
  ></emissions-widget-chart>
</div>
