/* eslint-disable @typescript-eslint/no-explicit-any */

type GetSet<T, K> = {
  get: (this: T) => K;
  set?: (this: T, value: K) => void;
}

/**
 * Creates a function to define properties to the target object.
 * Throws an error if the property already exists.
 */
export function extend<T extends object, K extends keyof T>(
  target: T,
  property: K,
  implementation: T[K] extends Function ? T[K] : GetSet<T, T[K]>
): void {
  checkValidity(target, property);

  /* istanbul ignore else : no property extensions currently */
  if (typeof implementation === 'function') {
    Object.defineProperty(target, property, {
      enumerable: false,
      configurable: false,
      writable: false,
      value: implementation
    });
  } else {
    Object.defineProperty(target, property, {
      enumerable: false,
      configurable: false,
      ...implementation
    });
  }

}

function checkValidity(target: object, property: keyof any): void {
  /* istanbul ignore if */
  if (target === Object.prototype) {
    throw Error('Object.prototype should never be extended');
  }
  if (Object.prototype.hasOwnProperty.call(target, property)) {
    /* istanbul ignore if */
    throw Error(`${String(property)} is already defined in ${target}`);
  }
}
