import { hasChanges } from '@enerkey/angular-utils';

/* eslint-disable prefer-object-spread */

function getItemIndex(array, item, field = 'id') {
  return array.findIndex(_item => _item[field] && item && item[field] && item[field] === _item[field]);
}

class SelectPagingController {
  constructor() {
    this.selectedIndex = 0;
    this.selectedItem = null;
    this.defaultOptions = {
      nameField: 'name',
      nameFormatter: null,
      idField: 'id'
    };
    this.defaultIndicatorOptions = {
      visible: false
    };
    this.itemNames = {};
  }

  $onInit() {
    this.items = angular.copy(this.items);
    this.options = Object.assign({}, this.defaultOptions, this.options);
    this.selectedItem = this.items[this.selectedIndex];
    this.indicator = this.indicator ? angular.copy(this.indicator) : this.defaultIndicatorOptions;
    this.updateItemNames();
  }

  $onChanges(changes) {
    if (hasChanges(changes, 'items')) {
      this.items = angular.copy(this.items);
      this.updateItemNames();
    }

    if (hasChanges(changes, 'selectedItem')) {
      const index = getItemIndex(this.items, this.selectedItem, this.options.idField);

      this.selectedIndex = index > 0 ? index : 0;
      this.selectedItem = this.items[this.selectedIndex];
    }
  }

  updateItemNames() {
    const selector = angular.isFunction(this.options.nameFormatter)
      ? (item => this.options.nameFormatter(item))
      : (item => item[this.options.nameField]);

    this.itemNames = {};
    for (const item of this.items) {
      this.itemNames[item[this.options.idField]] = selector(item);
    }
  }

  getItemName(item) {
    return angular.isFunction(this.options.nameFormatter) ?
      this.options.nameFormatter(item) :
      item[this.options.nameField];
  }

  selectNext() {
    if (this.selectedIndex + 1 >= this.items.length) {
      return;
    }

    this.selectedIndex++;
    this.selectedItem = this.items[this.selectedIndex];

    this.onChange(this.selectedItem);
  }

  selectPrevious() {
    if (this.selectedIndex - 1 < 0) {
      return;
    }

    this.selectedIndex--;
    this.selectedItem = this.items[this.selectedIndex];

    this.onChange(this.selectedItem);
  }

  handleChange(item) {
    if (!item) {
      return;
    }

    const index = getItemIndex(this.items, item, this.options.idField);

    this.selectedIndex = index > 0 ? index : 0;
    this.selectedItem = this.items[this.selectedIndex];

    this.onChange(this.selectedItem);
  }
}

export default SelectPagingController;
