import { Pipe, PipeTransform } from '@angular/core';

import { ProvisioningRuntimeStatus } from '@enerkey/clients/provisioning';

export const provisioningStatusTranslations: Record<ProvisioningRuntimeStatus, string> = {
  [ProvisioningRuntimeStatus.Running]: 'RUNNING',
  [ProvisioningRuntimeStatus.Completed]: 'COMPLETED',
  [ProvisioningRuntimeStatus.ContinuedAsNew]: 'CONTINUED_AS_NEW',
  [ProvisioningRuntimeStatus.Failed]: 'FAILED',
  [ProvisioningRuntimeStatus.Canceled]: 'CANCELED',
  [ProvisioningRuntimeStatus.Terminated]: 'TERMINATED',
  [ProvisioningRuntimeStatus.Pending]: 'PENDING',
  [ProvisioningRuntimeStatus.Unknown]: 'UNKNOWN'
};

@Pipe({
  name: 'provisioningStatusText'
})
export class ProvisioningStatusTextPipe implements PipeTransform {
  public transform(status: ProvisioningRuntimeStatus): string {
    const prefix = 'PROVISIONING.STATUS.';
    const suffix = provisioningStatusTranslations[status] ?? 'UNKNOWN';
    return `${prefix}${suffix}`;
  }
}
