
export type PropertyChangeOptions = {
  /** Notify of changes even when value reference does not change. */
  always?: boolean;
};

export interface OnPropertyChanged {
  onPropertyChanged(
    propertyName: any,
    oldValue: any,
    newValue: any
  ): void;
}

/**
 * Calls `onPropertyChanged()` on the instance when the decorated property's value changes.
 *
 * Creates a getter and setter for the property, so use only in templates when
 * component has `OnPush` change detection.
 */
export function OnPropertyChange(options?: PropertyChangeOptions): Function {
  return (
    target: any,
    propertyKey: string,
    descriptor?: PropertyDescriptor | undefined
  ): void => {
    if (descriptor) {
      throw Error('OnPropertyChange must be used on properties without getters and setters');
    } else if (typeof target !== 'object' || !target.constructor.name || !propertyKey) {
      throw Error('OnPropertyChange must be used on prototype properties');
    } else if (typeof target.onPropertyChanged !== 'function') {
      throw Error('OnPropertyChange must be used on classes implementing OnPropertyChanged');
    }

    const opts = options || {};
    const privateField = Symbol(`${target.constructor.name}.${propertyKey}`);

    Object.defineProperty(
      target,
      privateField,
      {
        writable: true,
        enumerable: false,
        configurable: false,
        value: target[propertyKey],
      }
    );

    Object.defineProperty(
      target,
      propertyKey,
      {
        configurable: false,
        enumerable: true,
        get: function() {
          return this[privateField];
        },
        set: function(newValue: any) {
          const oldValue = this[privateField];
          this[privateField] = newValue;

          if (oldValue !== newValue || opts.always) {
            this.onPropertyChanged(propertyKey, oldValue, newValue);
          }
        },
      }
    );
  };
}
