<kendo-grid
  *ngIf="gridColumns?.length"
  [kendoGridBinding]="data"
  [pageable]="pageSettings"
  [pageSize]="pageSize"
  [resizable]="true"
  [skip]="state.skip"
  [sort]="state.sort"
  [sortable]="true"
  (pageChange)="pageChange($event)"
>
  <kendo-grid-column
    [field]="'periodName'"
    [title]="''"
    [locked]="true"
    [width]="100"
  >
    <ng-template kendoGridFooterTemplate>
      <ng-container *ngIf="aggregates">
        <div>{{ 'FACILITIES_REPORT.SUM' | translate }}</div>
        <div>{{ 'FACILITIES_REPORT.MIN' | translate }}</div>
        <div>{{ 'FACILITIES_REPORT.MAX' | translate }}</div>
        <div>{{ 'FACILITIES_REPORT.AVERAGE' | translate }}</div>
      </ng-container>
    </ng-template>
  </kendo-grid-column>
  <ng-container *ngFor="let columnGroup of gridColumns">
    <kendo-grid-table-report-column-group
      *ngIf="columnGroup.series.length"
      [title]="columnGroup | reportingGridColumnGroupTitle"
    >
      <ng-container *ngFor="let group of columnGroup.series">
        <ng-container *ngFor="let serie of group">
          <kendo-grid-quantity-consumption-column
            [title]="serie.title"
            [fieldStart]="serie.field"
            [color]="serie.color"
            [format]="serie | reportingGridValueFormat:searchParams.resolution | async"
            [total]="aggregates"
            [modalReportType]="modalReportType"
          ></kendo-grid-quantity-consumption-column>
        </ng-container>
        <ng-container *ngFor="let serie of group">
          <ng-container *ngIf="serie.showChange">
            <kendo-grid-change-column
              *ngIf="searchParams?.change?.relative"
              [quantityId]="columnGroup.quantityId"
              [format]="'p1'"
              [field]="serie.field + '.' + relativeChangeKey"
              [changeKey]="'%'"
              [color]="serie.color"
              [comparisonColor]="serie.comparisonColor"
              [total]="aggregates"
              [modalReportType]="modalReportType"
            ></kendo-grid-change-column>
            <kendo-grid-change-column
              *ngIf="searchParams?.change?.absolute"
              [quantityId]="columnGroup.quantityId"
              [field]="serie.field + '.' + absoluteChangeKey"
              [color]="serie.color"
              [comparisonColor]="serie.comparisonColor"
              [format]="columnGroup.quantityId | valueFormat:serie.unitKey:searchParams.resolution:serie.derivedId | 
              async"
              [total]="aggregates"
              [modalReportType]="modalReportType"
            ></kendo-grid-change-column>
          </ng-container>
        </ng-container>
      </ng-container>
    </kendo-grid-table-report-column-group>
  </ng-container>
</kendo-grid>
