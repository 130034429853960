export default {
  SELECTOR: '#spreadsheet',
  READING_START_ROW: 2,
  COLUMNS: {
    ENEGIA_ID: 0,
    PROPERTY_ID: 1,
    QUANTITY_ID: 1, // There can only be other
    CUSTOM_TYPE: 1,
    VALID_FROM: 2,
    VALUE: 3,
    STATUS: 4
  },
  DEFAULT_ROW_COUNT: 1000,
  CUSTOM_TYPE_PROPERTY_ID: 9,
};
