export const VISUAL_COLORS = [
  '#ff66cc',
  '#3399ff',
  '#ff9933',
  '#660066',
  '#ffff66',
  '#339966',
  '#9900ff',
  '#0000ff',
  '#33cccc',
  '#ff5050',
  '#003366',
  '#990000',
  '#996633',
  '#99ccff',
  '#cc99ff',
  '#ff99cc',
  '#999966',
  '#006699',
  '#006600',
  '#66ff99',
  '#9999ff'
];
