import _ from 'lodash';

import { removeSpreadsheetOrphans } from '../../../../shared/ek-kendo/kendo.functions';
import { ToasterType } from '../../../../constants/toaster-type';
import { dateToOle, oleToDate } from '../../../../shared/date.functions';

MrcMassImportController.$inject = [
  '$scope', 'facilities', '$q', 'mrcapi', 'utils',
  '$timeout', '$element', 'KendoFunctions', '$window',
  'mrcModals', 'MrcMassImportConstants', 'MrcMassImportService'
];

function isValidId(input) {
  if (isNaN(input)) {
    return false;
  }
  input = parseFloat(input);
  if (!Number.isInteger(input)) {
    return false;
  }
  return input >= 0;
}

export function MrcMassImportController(
  $scope, facilities, $q, mrcapi, utils,
  $timeout, $element, KendoFunctions, $window,
  mrcModals, MrcMassImportConstants, MrcMassImportService
) {

  const vm = this;

  vm.currentStep = null;
  vm.spreadsheet = null;
  vm.isSaveInProgress = false;
  vm.shouldStopSaving = false;
  vm.rowCount = 1000;
  vm.isValidationInProgress = false;
  vm.constants = MrcMassImportConstants;
  vm.columns = MrcMassImportConstants.COLUMNS;
  vm.meterType = vm.constants.IMPORT_TYPE.CONSUMPTION;

  vm.localizations = {
    invalidEnegiaId: utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_ENEGIA_ID'),
    invalidMeterId: utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_METER_ID'),
    invalidDate: utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_DATE'),
    invalidValue: utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_VALUE'),
    processing: utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.PROCESSING'),
    saveFailed: utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.SAVE_FAILED'),
    done: utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.DONE')
  };

  vm.createSpreadsheet = createSpreadsheetWithTimeout;
  vm.fillStartDates = fillStartDates;
  vm.saveValues = saveValues;
  vm.stopSaving = stopSaving;
  vm.$onDestroy = onDestroy;
  vm.onMeterTypeChange = onMeterTypeChange;

  const meterTypeSelectStep = {
    text: utils.localizedString('MRC.MASS_IMPORT.WIZARD.TYPE_SELECT'),
    isReturnable: true,
    clickFn: vm.createSpreadsheet,
    stepDisabledFn: isSaveInProgress
  };

  const saveInProgressStep = {
    text: utils.localizedString('MRC.MASS_IMPORT.WIZARD.SAVE'),
    clickFn: vm.stopSaving,
    buttonDisabledFn: isSaveStopped,
    customButtonText: utils.localizedString('MRC.MASS_IMPORT.WIZARD.STOP_SAVING')
  };

  const readyStep = {
    text: utils.localizedString('MRC.MASS_IMPORT.WIZARD.READY'),
    hideButton: true
  };

  const consumptionImportSteps = [
    meterTypeSelectStep,
    {
      text: utils.localizedString('MRC.MASS_IMPORT.WIZARD.IMPORT'),
      clickFn: vm.fillStartDates
    },
    {
      text: utils.localizedString('MRC.MASS_IMPORT.WIZARD.FILL_START_DATES'),
      clickFn: vm.saveValues,
      buttonDisabledFn: cannotStartSaving,
      customButtonText: utils.localizedString('MRC.MASS_IMPORT.WIZARD.START_SAVING')
    },
    saveInProgressStep,
    readyStep
  ];

  const readingImportSteps = [
    meterTypeSelectStep,
    {
      text: utils.localizedString('MRC.MASS_IMPORT.WIZARD.IMPORT'),
      clickFn: vm.saveValues,
      buttonDisabledFn: cannotStartSaving,
      customButtonText: utils.localizedString('MRC.MASS_IMPORT.WIZARD.START_SAVING')
    },
    saveInProgressStep,
    readyStep
  ];

  vm.steps = consumptionImportSteps;

  function isSaveInProgress() {
    return vm.isSaveInProgress;
  }

  function cannotStartSaving() {
    if (!vm.spreadsheet) {
      return false;
    }
    return !MrcMassImportService.areAllRowsValid(getRowsWithData());
  }

  function isSaveStopped() {
    return vm.shouldStopSaving;
  }

  let resizeFn = angular.noop();

  function createSpreadsheetWithTimeout() {
    // Use timeout here to make sure that currentStep is set before creating spreadsheet
    vm.isValidationInProgress = true;
    $timeout(() => {
      createSpreadsheet();
    });
  }

  function createSpreadsheet() {
    vm.spreadsheetOptions = isConsumptionTypeImport() ?
      MrcMassImportService.getConsumptionSpreadsheetOptions() :
      MrcMassImportService.getReadingSpreadsheetOptions();

    vm.spreadsheetOptions.change = onChange;

    const element = $element.find('#massImportSheet');
    if (vm.spreadsheet) {
      vm.spreadsheet.destroy();
      element.empty();
    }
    element.kendoSpreadsheet(vm.spreadsheetOptions);
    vm.spreadsheet = element.data('kendoSpreadsheet');

    resizeFn = _.debounce(
      _.partial(
        KendoFunctions.resizeKendoComponent, vm.spreadsheet, '#massImportSheetContainer'
      ),
      200
    );
    angular.element($window).on('resize', resizeFn);

    resizeFn();

    const sheet = vm.spreadsheet.activeSheet();

    sheet.range(createColumnRange(vm.columns.ENEGIA_ID_COLUMN)).validation(
      MrcMassImportService.getSpreadsheetIdValidator(vm.localizations.invalidEnegiaId)
    );
    sheet.range(createColumnRange(vm.columns.METER_ID_COLUMN)).validation(
      MrcMassImportService.getSpreadsheetIdValidator(vm.localizations.invalidMeterId)
    );

    const dateValidator = MrcMassImportService.getSpreadsheetDateValidator(
      vm.localizations.invalidDate
    );

    const valueValidator = MrcMassImportService.getSpreadsheetValueValidator(
      vm.localizations.invalidValue
    );

    if (isConsumptionTypeImport()) {
      const startDateColumnRange = sheet.range(
        createColumnRange(vm.columns.START_DATE_COLUMN)
      );
      startDateColumnRange.validation(dateValidator);
      startDateColumnRange.format(MrcMassImportConstants.FORMATS.DATE);

      const endDateColumnRange = sheet.range(
        createColumnRange(vm.columns.END_DATE_COLUMN)
      );
      endDateColumnRange.validation({
        dataType: 'custom',
        from: `AND(NOT(ISERROR(DATEVALUE(TEXT(${
          vm.columns.END_DATE_COLUMN
        }1,"d.M.yyyy")))),IF(ISBLANK(${
          vm.columns.START_DATE_COLUMN
        }1),TRUE(),${ vm.columns.END_DATE_COLUMN }1 > ${
          vm.columns.START_DATE_COLUMN }1))`,
        allowNulls: true,
        messageTemplate: vm.localizations.invalidDate
      });
      endDateColumnRange.format(MrcMassImportConstants.FORMATS.DATE);

      const valueColumnRange = sheet.range(
        createColumnRange(vm.columns.VALUE_COLUMN)
      );
      valueColumnRange.validation(valueValidator);
      valueColumnRange.format(MrcMassImportConstants.FORMATS.VALUE);
    } else {
      const dateColumnRange = sheet.range(
        createColumnRange(vm.columns.DATE_COLUMN)
      );
      dateColumnRange.validation(dateValidator);
      dateColumnRange.format(MrcMassImportConstants.FORMATS.DATE);

      const firstValueColumnRange = sheet.range(
        createColumnRange(vm.columns.FIRST_VALUE_COLUMN)
      );
      firstValueColumnRange.validation(valueValidator);
      firstValueColumnRange.format(MrcMassImportConstants.FORMATS.VALUE);

      const secondValueColumnRange = sheet.range(
        createColumnRange(vm.columns.SECOND_VALUE_COLUMN)
      );
      secondValueColumnRange.validation(valueValidator);
      secondValueColumnRange.format(MrcMassImportConstants.FORMATS.VALUE);
    }

    const statusColumnRange = sheet.range(
      createColumnRange(vm.columns.STATUS_COLUMN)
    );
    statusColumnRange.color(vm.constants.COLORS.ERROR);
    statusColumnRange.enable(false);

    const headerRowRange = sheet.range(
      `A1:${ vm.columns.STATUS_COLUMN }1`
    );
    headerRowRange.validation(null);
    headerRowRange.format(null);
    headerRowRange.color(vm.constants.COLORS.DEFAULT);
    vm.isValidationInProgress = false;
  }

  function onDestroy() {
    vm.shouldStopSaving = true;
    if (vm.spreadsheet) {
      vm.spreadsheet.destroy();
    }
    angular.element($window).off('resize', resizeFn);
    removeSpreadsheetOrphans();
  }

  function onMeterTypeChange() {
    vm.steps = isConsumptionTypeImport() ?
      consumptionImportSteps :
      readingImportSteps;
  }

  function fillStartDates() {
    const rows = getRowsWithoutStartDate();
    getAndFillEarliestInputDates(rows);
  }

  function saveValues() {
    const rowsWithData = getRowsWithData();
    let facilityIds;
    let meterAndEnegiaIds;
    facilities.getFacilitiesInformation().then(facilitiesResponse => {
      facilityIds = MrcMassImportService.getFacilityIdsFromEnegiaIds(
        rowsWithData, facilitiesResponse
      );
      meterAndEnegiaIds = MrcMassImportService.getMeterIdsAndEnegiaIdsFromData(rowsWithData);
      const meterReadingRequests = isConsumptionTypeImport() ?
        [] :
        getMeterReadingRequests(meterAndEnegiaIds, facilityIds);
      return $q.all(meterReadingRequests);
    })
      .then(meterReadings => MrcMassImportService.mapMeterIdsAndReadings(meterReadings, meterAndEnegiaIds))
      .then(meterReadingsMapped => {
        if (MrcMassImportService.isAnyRowMissingValue(rowsWithData, isConsumptionTypeImport())) {
          mrcModals.showConfirmationModal(
            utils.localizedString('MRC.MASS_IMPORT.CONFIRM_DELETE')
          ).then(() => {
            doSave(rowsWithData, facilityIds, meterReadingsMapped);
          }).catch(() => {
            // Return to previous step if user clicked cancel in dialog
            vm.currentStep = vm.steps[vm.steps.indexOf(vm.currentStep) - 1];
          });
        } else {
          doSave(rowsWithData, facilityIds, meterReadingsMapped);
        }
      })
      .catch(() => {
        utils.popUp(
          ToasterType.ERROR,
          'MRC_ERRORS.ERROR_TITLE',
          'MRC_ERRORS.METER_OR_FACILITY_INFO_LOADING_FAILED',
          true
        );
      });
  }

  function getMeterReadingRequests(meterAndEnegiaIds, facilityIds) {
    const requests = [];
    meterAndEnegiaIds.forEach(ids => {
      const facilityId = facilityIds[ids.enegiaId];
      const meterId = ids.meterId;
      if (isValidId(facilityId) && isValidId(meterId)) {
        requests.push(mrcapi.getMeterReadings(facilityId, meterId));
      }
    });
    return requests;
  }

  function createSaveRequestParams(rowsWithData, facilityIds) {
    const saveParams = [];
    _.each(Object.keys(rowsWithData), rowIndex => {
      const rowNumber = parseInt(rowIndex) + 1;
      const row = rowsWithData[rowIndex];
      const enegiaId = MrcMassImportService.getRowEnegiaId(row);
      const facilityId = facilityIds[enegiaId];
      if (!facilityId) {
        setError(rowNumber, vm.localizations.invalidEnegiaId);
        return;
      }
      saveParams.push({
        row: row,
        rowNumber: rowNumber,
        facilityId: facilityId
      });
    });
    return saveParams;
  }

  function doSave(rows, facilityIds, meterReadings) {
    const saveRequestParams = createSaveRequestParams(rows, facilityIds);
    vm.shouldStopSaving = false;
    vm.isSaveInProgress = true;

    saveRequestParams.reduce((promise, item) => {
      const rowNumber = item.rowNumber;
      return promise.then(() => {
        if (vm.shouldStopSaving) {
          return false;
        }
        setStatus(rowNumber, vm.localizations.processing);
        const request = getRequest(item, meterReadings);
        return request.then(() => {
          setStatus(rowNumber, vm.localizations.done);
        }).catch(() => {
          setError(rowNumber, vm.localizations.saveFailed);
        });
      });
    }, $q.when()).finally(() => {
      vm.shouldStopSaving = false;
      vm.isSaveInProgress = false;
      vm.currentStep = vm.steps[vm.steps.length - 1];
    });
  }

  function stopSaving() {
    vm.shouldStopSaving = true;
  }

  function getRequest(item, meterReadings) {
    let request;
    const row = item.row;
    const facilityId = item.facilityId;
    if (isConsumptionTypeImport(row, facilityId)) {
      request = getConsumptionRequest(row, facilityId);
    } else {
      const meterId = MrcMassImportService.getRowMeterId(row);
      const currentMeterReadings = meterReadings[meterId];
      request = getReadingRequest(row, facilityId, currentMeterReadings);
    }
    return request;
  }

  function getConsumptionRequest(row, facilityId) {
    const meterId = MrcMassImportService.getRowMeterId(row);
    let request;
    const params = MrcMassImportService.getSaveRequestParamsConsumption(row, facilityId, meterId);
    if (params[0].Value || params[0].Value === 0) {
      request = mrcapi.addConsumptionIntervalValues(facilityId, meterId, params);
    } else {
      request = mrcapi.deleteMonthlyConsumptionValues(facilityId, meterId, params[0]);
    }
    return request;
  }

  function getReadingRequest(row, facilityId, meterReadings) {
    const meterId = MrcMassImportService.getRowMeterId(row);
    const date = oleToDate(MrcMassImportService.getRowDate(row));
    let request;
    if (MrcMassImportService.isDateAfterLatestReadingDate(meterReadings, date)) {
      request = mrcapi.saveMeterValues(facilityId, MrcMassImportService.getSaveRequestParamsNewReading(row));
    } else if (MrcMassImportService.hasReadingOnSameDate(meterReadings, date)) {
      request = mrcapi.updateReading(facilityId, meterId, MrcMassImportService.getSaveRequestParamsReading(row));
    } else {
      request = mrcapi.addToHistory(facilityId, meterId, MrcMassImportService.getSaveRequestParamsReading(row));
    }
    return request;
  }

  function onChange(arg) {
    let firstRow = -1;
    let lastRow = -1;
    // Changed area is either one cell (row and column available)
    // or an area (top left and bottom rigth coordinates available)
    if (arg.range._ref.row) {
      if (arg.range._ref.col === 9) {
        return;
      }
      firstRow = arg.range._ref.row;
      lastRow = arg.range._ref.row;
    } else if (arg.range._ref.topLeft) {
      firstRow = arg.range._ref.topLeft.row;
      lastRow = arg.range._ref.bottomRight.row;
    }
    if (firstRow === -Infinity || firstRow === -1) {
      return;
    }
    // Don't apply if spinner is already shown above spreadsheet
    if (!vm.isValidationInProgress) {
      vm.isValidationInProgress = true;
      $scope.$apply();
    }
    // Without timeout here, spinner is shown after a delay
    $timeout(() => {
      const errors = {};
      for (let row = firstRow; row <= lastRow; row++) {
        // Don't validate header row
        if (row === 0) {
          continue;
        }
        const rowData = getRowData(row);
        const error = isConsumptionTypeImport() ?
          MrcMassImportService.getConsumptionRowError(rowData) :
          MrcMassImportService.getReadingRowError(rowData);
        errors[row] = error;
      }
      updateErrorStatuses(errors);
      vm.isValidationInProgress = false;
    });
  }

  function updateErrorStatuses(errors) {
    vm.spreadsheet.activeSheet().batch(() => {
      Object.keys(errors).forEach(key => {
        const error = errors[key];
        if (error) {
          setError(key, error);
        } else {
          clearStatusText(key);
        }
      });
    });
  }

  function getStatusCellRange(rowIndex) {
    rowIndex = parseInt(rowIndex) + 1;
    return vm.spreadsheet.activeSheet().range(vm.columns.STATUS_COLUMN + rowIndex);
  }

  function clearStatusText(rowIndex) {
    const range = getStatusCellRange(rowIndex);
    range.clear({ contentsOnly: true });
  }

  function setStatus(rowIndex, status) {
    const range = getStatusCellRange(rowIndex);
    range.color(vm.constants.COLORS.DEFAULT);
    range.value(status);
  }

  function setError(rowIndex, error) {
    const range = getStatusCellRange(rowIndex);
    range.color(vm.constants.COLORS.ERROR);
    range.value(error);
  }

  function createColumnRange(column) {
    return `${column }:${ column}`;
  }

  function isRowEmpty(rowIndex) {
    const rowData = getRowData(rowIndex);
    return MrcMassImportService.isRowDataEmpty(rowData);
  }

  // Return rows before first empty row
  function getRowsWithData() {
    const sheet = vm.spreadsheet.activeSheet();
    let firstEmpty = -1;
    for (let i = 1; i < vm.rowCount; i++) {
      if (isRowEmpty(i)) {
        break;
      }
      firstEmpty = i;
    }
    if (firstEmpty === -1) {
      return [];
    }
    return sheet.range(
      `${vm.columns.ENEGIA_ID_COLUMN }2:${
        vm.columns.STATUS_COLUMN }${firstEmpty + 1}`
    ).values();
  }

  function getRowData(rowIndex) {
    rowIndex = parseInt(rowIndex) + 1;
    const rowIndexString = rowIndex.toString();
    const firstColumn = vm.columns.ENEGIA_ID_COLUMN;
    const lastColumn = isConsumptionTypeImport() ?
      vm.columns.VALUE_COLUMN :
      vm.columns.SECOND_VALUE_COLUMN;
    return vm.spreadsheet.activeSheet().range(
      `${firstColumn + rowIndexString }:${
        lastColumn }${rowIndexString}`
    ).values()[0];
  }

  function getRowsWithoutStartDate() {
    const rowsWithData = getRowsWithData();
    const rowsWithoutStartDates = {};
    for (let i = 0; i < rowsWithData.length; i++) {
      const rowData = rowsWithData[i];
      if (MrcMassImportService.getRowStartDate(rowData) === null) {
        rowsWithoutStartDates[i + 1] = rowData;
      }
    }
    return rowsWithoutStartDates;
  }

  function getAndFillEarliestInputDates(rows) {
    facilities.getFacilitiesInformation().then(
      facilitiesResponse => {
        const facilityIds = MrcMassImportService.getFacilityIdsFromEnegiaIds(rows, facilitiesResponse);
        const facilityMeters = {};
        _.each(Object.keys(rows), rowIndex => {
          const row = rows[rowIndex];
          const meterId = MrcMassImportService.getRowMeterId(row);
          if (!meterId) {
            return;
          }
          const enegiaId = MrcMassImportService.getRowEnegiaId(row);
          const facilityId = facilityIds[enegiaId];
          if (!facilityId) {
            setError(rowIndex, vm.localizations.invalidEnegiaId);
            return;
          }
          if (!facilityMeters[facilityId]) {
            facilityMeters[facilityId] = [];
          }
          // Don't add same meter Id to list twice, it will result in error 500
          if (facilityMeters[facilityId].indexOf(meterId) === -1) {
            facilityMeters[facilityId].push(meterId);
          }
        });
        const earliestInputDateQueries = [];
        _.each(Object.keys(facilityMeters), facilityId => {
          _.each(facilityMeters[facilityId], meterId => {
            earliestInputDateQueries.push(
              mrcapi.getEarliestInputDatesForMeters(facilityId, [meterId])
                .then(value => ({ value, state: 'resolved' }))
                .catch(() => ({ state: 'rejected' }))
            );
          });
        });
        const dates = {};
        $q.all(earliestInputDateQueries).then(
          values => {
            // Get failed requests to own array, only succeeded are left to values
            const failed = _.remove(values, { state: 'rejected' });
            values = _.map(values, 'value');
            _.each(values, res => {
              _.each(Object.keys(res), meterId => {
                dates[meterId] = res[meterId];
              });
            });
            if (failed.length > 0) {
              utils.popUp(
                ToasterType.ERROR,
                'MRC_ERRORS.ERROR_TITLE',
                'MRC_ERRORS.FAILED_UPDATING_METER_INFO',
                true
              );
            }
            setStartDatesToRows(rows, dates);
          }
        );
      }
    );
  }

  function setStartDatesToRows(rows, startDates) {
    const sheet = vm.spreadsheet.activeSheet();
    _.each(Object.keys(rows), row => {
      const meterId = MrcMassImportService.getRowMeterId(rows[row]);
      const startDate = startDates[meterId];
      if (!startDate) {
        return;
      }
      row = parseInt(row) + 1;
      // Use timeout here to prevent digest errors in validation
      $timeout(() => {
        // Convert date object to OLE when setting cell value
        sheet.range(vm.columns.START_DATE_COLUMN + row).value(dateToOle(startDate));
      });
    });
  }

  function isConsumptionTypeImport() {
    return vm.meterType === vm.constants.IMPORT_TYPE.CONSUMPTION;
  }
}
