import { ISustainabilityClient } from './sustainability-client';

export class SustainabilityClientMock implements ISustainabilityClient {
  public getCo2Factors(): never {
    throw new Error('Method not implemented.');
  }
  public deleteReport(): never {
    throw new Error('Method not implemented.');
  }
  public getDefaultCategories(): never {
    throw new Error('Method not implemented.');
  }
  public getRowCountForCategory(): never {
    throw new Error('Method not implemented.');
  }
  public getRowCountForUnit(): never {
    throw new Error('Method not implemented.');
  }
  public getCategories(): never {
    throw new Error('Method not implemented.');
  }
  public createCategories(): never {
    throw new Error('Method not implemented.');
  }
  public updateCategories(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCategories(): never {
    throw new Error('Method not implemented.');
  }
  public getTargets(): never {
    throw new Error('Method not implemented.');
  }
  public upsertTarget(): never {
    throw new Error('Method not implemented.');
  }
  public searchCompanies(): never {
    throw new Error('Method not implemented.');
  }
  public getLocalPowerFacilityConnection(): never {
    throw new Error('Method not implemented.');
  }
  public removeLocalPowerFacilityConnection(): never {
    throw new Error('Method not implemented.');
  }
  public upsertLocalPowerFacilityConnection(): never {
    throw new Error('Method not implemented.');
  }
  public searchReports(): never {
    throw new Error('Method not implemented.');
  }
  public getReport(): never {
    throw new Error('Method not implemented.');
  }
  public createReport(): never {
    throw new Error('Method not implemented.');
  }
  public getReportSummaries(): never {
    throw new Error('Method not implemented.');
  }
  public updateReport(): never {
    throw new Error('Method not implemented.');
  }
  public createRows(): never {
    throw new Error('Method not implemented.');
  }
  public createRow(): never {
    throw new Error('Method not implemented.');
  }
  public updateRow(): never {
    throw new Error('Method not implemented.');
  }
  public updateRows(): never {
    throw new Error('Method not implemented.');
  }
  public deleteRows(): never {
    throw new Error('Method not implemented.');
  }
  public getRows(): never {
    throw new Error('Method not implemented.');
  }
  public getRowUnits(): never {
    throw new Error('Method not implemented.');
  }
  public createRowUnits(): never {
    throw new Error('Method not implemented.');
  }
  public updateRowUnits(): never {
    throw new Error('Method not implemented.');
  }
  public deleteRowUnits(): never {
    throw new Error('Method not implemented.');
  }
  public getRoadmap(): never {
    throw new Error('Method not implemented.');
  }
  public getRoadmaps(): never {
    throw new Error('Method not implemented.');
  }
  public addRoadmaps(): never {
    throw new Error('Method not implemented.');
  }
  public updateRoadmaps(): never {
    throw new Error('Method not implemented.');
  }
  public deleteRoadmaps(): never {
    throw new Error('Method not implemented.');
  }

}
