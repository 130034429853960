import _ from 'lodash';

import { oleToDate } from '../../../../shared/date.functions';

MrcMassImportService.$inject = [
  'utils', 'MrcMassImportConstants'
];

function isValidId(input) {
  if (isNaN(input)) {
    return false;
  }
  input = parseFloat(input);
  if (!Number.isInteger(input)) {
    return false;
  }
  return input >= 0;
}

export function MrcMassImportService(
  utils, MrcMassImportConstants
) {
  return {
    getConsumptionSpreadsheetOptions: getConsumtionSpreadsheetOptions,
    getReadingSpreadsheetOptions: getReadingSpreadsheetOptions,
    isValidValue: isValidValue,
    getRowEnegiaId: getRowEnegiaId,
    getRowMeterId: getRowMeterId,
    getRowStartDate: getRowStartDate,
    getRowDate: getRowDate,
    getFacilityIdsFromEnegiaIds: getFacilityIdsFromEnegiaIds,
    getSpreadsheetDateValidator: getSpreadsheetDateValidator,
    getSpreadsheetIdValidator: getSpreadsheetIdValidator,
    getSpreadsheetValueValidator: getSpreadsheetValueValidator,
    getSaveRequestParamsNewReading: getSaveRequestParamsNewReading,
    getSaveRequestParamsReading: getSaveRequestParamsReading,
    getSaveRequestParamsConsumption: getSaveRequestParamsConsumption,
    getReadingRowError: getReadingRowError,
    getConsumptionRowError: getConsumptionRowError,
    isRowDataEmpty: isRowDataEmpty,
    isAnyRowMissingValue: isAnyRowMissingValue,
    areAllRowsValid: areAllRowsValid,
    getMeterIdsAndEnegiaIdsFromData: getMeterIdsAndEnegiaIdsFromData,
    hasReadingOnSameDate: hasReadingOnSameDate,
    isDateAfterLatestReadingDate: isDateAfterLatestReadingDate,
    mapMeterIdsAndReadings: mapMeterIdsAndReadings
  };

  /**
   * Return cell options for consumptions spreadsheet
   *
   * @returns {[Object]} Options
   */
  function getConsumptionCells() {
    var extraCell = getHeaderCell();
    return [
      extraCell,
      extraCell,
      extraCell,
      extraCell,
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.ENEGIA_ID'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.METER_ID'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.START_DATE'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.END_DATE'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.VALUE'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.STATUS')
    ];
  }

  /**
   * Return cell options for readings spreadsheet
   *
   * @returns {[Object]} Options
   */
  function getReadingCells() {
    var extraCell = getHeaderCell();
    return [
      extraCell,
      extraCell,
      extraCell,
      extraCell,
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.ENEGIA_ID'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.METER_ID'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.DATE'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.FIRST_VALUE'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.SECOND_VALUE'),
      getHeaderCell('MRC.MASS_IMPORT.SPREADSHEET.HEADERS.STATUS')
    ];
  }

  function getHeaderCell(headerText) {
    var cell = {
      background: MrcMassImportConstants.COLORS.HEADER_BACKGROUND,
      textAlign: "center",
      color: MrcMassImportConstants.COLORS.DEFAULT,
      enable: false
    };

    if (headerText) {
      cell.value = utils.localizedString(headerText);
    }

    return cell;
  }

  function getCommonSpreadsheetOptions() {
    return {
      toolbar: false,
      sheetsbar: false,
      activeSheet: 'data',
      rows: MrcMassImportConstants.ROW_COUNT,
      columns: 6
    };
  }

  function getConsumtionSpreadsheetOptions() {
    var options = getCommonSpreadsheetOptions();
    options.sheets = [{
      name: 'data',
      columns: [
        { width: 140 },
        { width: 140 },
        { width: 140 },
        { width: 140 },
        { width: 65 },
        { width: 65 },
        { width: 100 },
        { width: 100 },
        { width: 100 },
        { width: 200 }
      ],
      rows: [
        {
          height: 20,
          cells: getConsumptionCells()
        }
      ],
    }];

    return options;
  }

  function getReadingSpreadsheetOptions() {
    var options = getCommonSpreadsheetOptions();
    options.sheets = [{
      name: 'data',
      columns: [
        { width: 140 },
        { width: 140 },
        { width: 140 },
        { width: 140 },
        { width: 65 },
        { width: 65 },
        { width: 100 },
        { width: 100 },
        { width: 100 },
        { width: 200 }
      ],
      rows: [
        {
          height: 20,
          cells: getReadingCells()
        }
      ],
    }];

    return options;
  }

  /**
   * Returns boolean indicating if given value is valid
   *
   * @param {*} input value
   * @returns {Boolean} true if value is valid, otherwise false
   */
  function isValidValue(input) {
    if (isNaN(input)) {
      return false;
    }
    input = parseFloat(input);
    return input >= 0;
  }

  function getRowEnegiaId(row) {
    return row[MrcMassImportConstants.DATA_INDICES.ENEGIA_ID_INDEX];
  }

  function getRowMeterId(row) {
    return row[MrcMassImportConstants.DATA_INDICES.METER_ID_INDEX];
  }

  function getRowStartDate(row) {
    return row[MrcMassImportConstants.DATA_INDICES.START_DATE_INDEX];
  }

  function getRowEndDate(row) {
    return row[MrcMassImportConstants.DATA_INDICES.END_DATE_INDEX];
  }

  function getRowValue(row) {
    return row[MrcMassImportConstants.DATA_INDICES.VALUE_INDEX];
  }

  function getRowStatus(row) {
    return row[MrcMassImportConstants.DATA_INDICES.STATUS_INDEX];
  }

  function getRowDate(row) {
    return row[MrcMassImportConstants.DATA_INDICES.DATE_INDEX];
  }

  function getRowValueFirst(row) {
    return row[MrcMassImportConstants.DATA_INDICES.FIRST_VALUE_INDEX];
  }

  function getRowValueSecond(row) {
    return row[MrcMassImportConstants.DATA_INDICES.SECOND_VALUE_INDEX];
  }

  /**
   * Get matching facilityIds for enegiaIds
   *
   * @param {Array} rows array of spreadsheet data rows
   * @param {Array} facilities array of facilities
   * @returns {Object} Object that has enegiaIds as keys and facilityIds as values
   */
  function getFacilityIdsFromEnegiaIds(rows, facilities) {
    var enegiaIds = _.uniq(_.map(rows, function(row) {
      return getRowEnegiaId(row);
    }));
    _.remove(enegiaIds, _.isNull);
    var facilityIds = {};
    _.each(enegiaIds, function(enegiaId) {
      var facility = _.find(facilities, function(facility) {
        return facility.FacilityInformation.EnegiaId.toString() === enegiaId.toString();
      });
      if (facility) {
        facilityIds[enegiaId] = facility.FacilityId;
      }
    });
    return facilityIds;
  }

  function getSpreadsheetDateValidator(errorMessage) {
    return {
      dataType: "date",
      comparerType: "greaterThan",
      from: "DATEVALUE(\"1.1.1900\")",
      allowNulls: true,
      messageTemplate: errorMessage
    };
  }

  function getSpreadsheetIdValidator(errorMessage) {
    return {
      dataType: "number",
      comparerType: "greaterThan",
      from: 0,
      allowNulls: true,
      messageTemplate: errorMessage
    };
  }

  function getSpreadsheetValueValidator(errorMessage) {
    return {
      dataType: "number",
      comparerType: "greaterThanOrEqualTo",
      from: 0,
      allowNulls: true,
      messageTemplate: errorMessage
    };
  }

  function getSaveRequestParamsNewReading(row) {
    var readings = getSaveRequestParamsReading(row);
    var meterId = getRowMeterId(row);
    return [{
      Readings: readings,
      MeterId: meterId
    }];
  }

  function getSaveRequestParamsReading(row) {
    var date = getRowDate(row);
    date = oleToDate(date);
    var firstValue = getRowValueFirst(row);
    var secondValue = getRowValueSecond(row);
    var readings;
    if (secondValue === null) {
      readings = [{
        Type: 1,
        Date: date.toJSON(),
        Value: firstValue
      }];
    } else {
      readings = [
        {
          Type: 1,
          Date: date.toJSON(),
          Value: firstValue
        },
        {
          Type: 2,
          Date: date.toJSON(),
          Value: secondValue
        }
      ];
    }
    return readings;
  }

  function getSaveRequestParamsConsumption(row) {
    var from = getRowStartDate(row);
    var to = getRowEndDate(row);
    if (from === null || to === null) {
      return;
    }
    var value = getRowValue(row);
    var fromDate = oleToDate(from);
    var toDate = oleToDate(to);
    var params = [{
      "Value": value,
      "ValueEndDate": toDate.toJSON(),
      "ValueDate": fromDate.toJSON()
    }];
    return params;
  }

  function getDateError(date) {
    var dateString = date ? date.toString().trim() : '';
    var error = null;
    if (!dateString || isNaN(date)) {
      error = utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_DATE');
    }
    return error;
  }

  function getValueError(value) {
    var error = null;
    if (value !== null && !isValidValue(value)) {
      error = utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_VALUE');
    }
    return error;
  }

  /**
   * Get error for consumption row
   *
   * @param {Array} rowData
   * @returns {String|null} null if row is empty/valid, string if there is some error
   */
  function getConsumptionRowError(rowData) {
    if (isRowDataEmpty(rowData)) {
      return null;
    }
    var error = getRowErrorCommon(rowData);
    if (error) {
      return error;
    }
    var startDate = getRowStartDate(rowData);
    var startDateError = getDateError(startDate);
    if (startDateError) {
      return startDateError;
    }
    // Spreadsheet uses OLE automation format for dates, convert it to JS date object
    startDate = oleToDate(startDate);

    var endDate = getRowEndDate(rowData);
    var endDateError = getDateError(endDate);
    if (endDateError) {
      return endDateError;
    }
    // Spreadsheet uses OLE automation format for dates, convert it to JS date object
    endDate = oleToDate(endDate);

    if (endDate && startDate && startDate >= endDate) {
      return utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_DATE');
    }
    var value = getRowValue(rowData);
    var valueError = getValueError(value);
    if (valueError) {
      return valueError;
    }
    return null;
  }

  /**
   * Get error for reading row
   *
   * @param {Array} rowData
   * @returns {String|null} null if row is empty/valid, string if there is some error
   */
  function getReadingRowError(rowData) {
    if (isRowDataEmpty(rowData)) {
      return null;
    }
    var error = getRowErrorCommon(rowData);
    if (error) {
      return error;
    }
    var date = getRowDate(rowData);
    var dateError = getDateError(date);
    if (dateError) {
      return dateError;
    }

    var valueFirst = getRowValueFirst(rowData);
    var valueFirstError = getValueError(valueFirst);
    if (valueFirstError) {
      return valueFirstError;
    }

    var valueSecond = getRowValueSecond(rowData);
    var valueSecondError = getValueError(valueSecond);
    if (valueSecondError) {
      return valueSecondError;
    }
    return null;
  }

  function getRowErrorCommon(rowData) {
    var enegiaId = getRowEnegiaId(rowData);
    if (!isValidId(enegiaId)) {
      return utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_ENEGIA_ID');
    }
    var meterId = getRowMeterId(rowData);
    if (!isValidId(meterId)) {
      return utils.localizedString('MRC.MASS_IMPORT.SPREADSHEET.STATUS.INVALID_METER_ID');
    }
  }

  /**
   * Checks if row has any data
   *
   * @param {Array} rowData
   * @returns {Boolean} true if all values are null, otherwise false
   */
  function isRowDataEmpty(rowData) {
    return _.every(rowData, _.isNull);
  }

  /**
   * Return true if any row does not have value
   *
   * @param {Array} rows data to check empty values for
   * @param {Boolean} isConsumptionTypeImport
   * @returns {Boolean} true if any row is missing value, otherwise false
   */
  function isAnyRowMissingValue(rows, isConsumptionTypeImport) {
    if (!isConsumptionTypeImport) {
      return false;
    }
    return _.some(rows, function(row) {
      return _.isNull(getRowValue(row));
    });
  }

  /**
   * Check if all rows are valid
   *
   * @param {Array} rows
   * @returns {Boolean} true if all rows are valid, otherwise false
   */
  function areAllRowsValid(rows) {
    return _.every(_.map(rows, getRowStatus), _.isNull);
  }

  function getMeterIdsAndEnegiaIdsFromData(rowsWithData) {
    return _(rowsWithData)
      .map(getIdsObject)
      .sortBy('meterId')
      .uniq(true, 'meterId')
      .value()
    ;
  }

  function getIdsObject(row) {
    return {
      enegiaId: getRowEnegiaId(row),
      meterId: getRowMeterId(row)
    };
  }

  /**
   * Returns true if given list of readings contains reading on given date
   *
   * @param {Array} meterReadings list of readings
   * @param {Date} date date to check
   *
   * @returns {Boolean} true if any reading has same date as given date, otherwise false
   */
  function hasReadingOnSameDate(meterReadings, date) {
    return _.some(meterReadings, function(reading) {
      return new Date(reading.Date).getTime() === date.getTime();
    });
  }

  /**
   * Returns true if given date is after last readings date or given reading array is empty
   *
   * @param {Array} meterReadings list of readings
   * @param {Date} date date to check
   *
   * @returns {Boolean} true if date is after last readings date, otherwise false
   */
  function isDateAfterLatestReadingDate(meterReadings, date) {
    if (!Array.isArray(meterReadings) || meterReadings.length === 0) {
      return true;
    }
    return date > new Date(meterReadings[meterReadings.length - 1].Date);
  }

  /**
   * Returns meter readings mapped with meter ids
   *
   * @param {Array} meterReadings array of reading lists
   * @param {Array} ids array of objects containing meterId property
   *
   * @returns {Object} returns map that has meterIds as keys and readings arrays as values
   */
  function mapMeterIdsAndReadings(meterReadings, ids) {
    var meterReadingsMapped = {};
    if (
      meterReadings &&
        Array.isArray(meterReadings) &&
        meterReadings.length === ids.length
    ) {
      ids.forEach(function(id, index) {
        meterReadingsMapped[id.meterId] = meterReadings[index];
      });
    }
    return meterReadingsMapped;
  }
}
