import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AggregateResult } from '@progress/kendo-data-query';

import { ReportingAggregateType, ReportingGridService } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';

@Component({
  selector: 'reporting-grid-column-footer',
  templateUrl: './reporting-grid-column-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingGridColumnFooterComponent implements OnChanges {
  @Input() public field: string;
  @Input() public aggregates: AggregateResult;
  @Input() public format: string;
  @Input() public visibleAggregates: readonly ReportingAggregateType[];
  @Input() public modalReportType: ReportType;

  public constructor(private readonly reportingGridService: ReportingGridService) {}

  public ngOnChanges(): void {
    if (this.aggregates) {
      this.aggregates = this.reportingGridService.calculateChangeAggregates(this.aggregates, this.modalReportType);
    }
  }
}
