import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { RelationalValuesService } from '../services/relational-values.service';
import { RelationalValueId } from '../../modules/reportingobjects/constants/facilities-properties';

@Pipe({ name: 'relationalValueName' })
export class RelationalValueNamePipe implements PipeTransform {
  public constructor(
    private readonly relationalValuesService: RelationalValuesService
  ) { }

  /** Use with `async`-pipe. */
  public transform(relationalValueId: RelationalValueId | Observable<RelationalValueId>): Observable<string> {
    const valueId = isObservable(relationalValueId)
      ? relationalValueId
      : of(relationalValueId)
      ;
    return valueId.pipe(
      switchMap(id => this.relationalValuesService.getRelationalValueName(id))
    );
  }
}
