<facility-select-header [formControl]="facilityIdControl"></facility-select-header>
<div class="consumptions-widget-period-title">
  <span>
    {{ inspectionPeriodTitle }}
    <i class="fas fa-exchange-alt"></i>
    {{ comparisonPeriodTitle }}
  </span>
</div>
<div class="consumptions-chart-grid">
  <ng-container *ngIf="(data$ | async)?.length">
    <div class="chart-container">
      <div
        *ngFor="let quantity of (data$ | async); trackBy: trackByMethod"
        class="card"
      >
        <quantity-chart-header [seriesCollection]="quantity"></quantity-chart-header>
        <widget-consumption-chart
          (seriesClick)="openFacilityReport($event)"
          [series]="quantity.series"
          [quantityId]="quantity.quantityId"
          [labelSettings]="quantity.series | chartCategories:(params$ | async) | async"
        ></widget-consumption-chart>
      </div>
    </div>
  </ng-container>
</div>
