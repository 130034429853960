import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import {
  IOutgoingRequestLogSearchCriteria,
  OutgoingRequestLogSearchCriteria,
  RequestStatus
} from '@enerkey/clients/webhook';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { formGroupFrom } from '@enerkey/ts-utils';
import { requestStatusTranslationKeys } from '../../pipes/request-status-name.pipe';
import { OutgoingMessagesService } from '../../services/outgoing-messages.service';

@Component({
  selector: 'outgoing-messages-search',
  templateUrl: './outgoing-messages-search.component.html',
  styleUrls: ['./outgoing-messages-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OutgoingMessagesSearchComponent {
  public searchForm: UntypedFormGroup;

  public statusOptions: ComboItem<RequestStatus>[];

  public constructor(
    private readonly outgoingMessagesService: OutgoingMessagesService
  ) {
    this.searchForm = formGroupFrom<IOutgoingRequestLogSearchCriteria>({
      beginDate: null,
      endDate: null,
      status: null,
      message: null
    });
    this.statusOptions = getNumericEnumValues(RequestStatus).map(option => ({
      value: option,
      text: requestStatusTranslationKeys[option]
    }));
  }

  public search(): void {
    this.outgoingMessagesService.search(new OutgoingRequestLogSearchCriteria(this.searchForm.value));
  }
}
