import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VirtualMeter } from '@enerkey/clients/metering';

@Component({
  selector: 'virtual-meter-history',
  templateUrl: './virtual-meter-history.component.html'
})
export class VirtualMeterHistoryComponent {
  @Input() public history: VirtualMeter[];
  @Input() public loading: boolean;
  @Output() public editFormula = new EventEmitter<VirtualMeter>();
  @Output() public deleteFormula = new EventEmitter<VirtualMeter>();

  public pageSize = 10;

  public formulaEditClicked(formula: VirtualMeter) {
    this.editFormula.emit(formula);
  }

  public formulaDeleteClicked(formula: VirtualMeter) {
    this.deleteFormula.emit(formula);
  }
}
