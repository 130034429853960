import templateModule from 'raw-loader!../templates/mrc-year-selector.html';

export function mrcYearSelector() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      mrcState: "=",
      selectedYear: "="
    },
    controller: [
      '$scope', '$rootScope',
      function ($scope, $rootScope) {
        $scope.ready = false;
        $scope.years = [];
        var currentYear = new Date().getFullYear();
        for(var i=$scope.mrcState.earliestConsumptionYear; i<=currentYear; i++) {
          $scope.years.push(i);
        }
        $scope.updateSelectedYear = function(year) {
          if($scope.years.indexOf(year)>=0) {
            $rootScope.$broadcast('selectedYearChanged', {year:year});
          }
        }
      }
    ]
  };
}
