import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import {
  AxisLabelContentArgs,
  AxisNoteVisualArgs,
  CategoryAxisItemComponent,
  ChartComponent,
  LegendItemClickEvent,
  LegendLabels,
  NoteHoverEvent,
  PlotAreaClickEvent,
  PlotBand,
  SeriesClickEvent,
  ZoomEndEvent,
} from '@progress/kendo-angular-charts';
import { Element, geometry, Group, Layout, Text } from '@progress/kendo-drawing';
import { Align } from '@progress/kendo-angular-popup';

import { Quantities } from '@enerkey/clients/metering';
import { RequestResolution } from '@enerkey/clients/reporting';
import { LogLiteDto } from '@enerkey/clients/alarm';
import { AxisLabelVisualArgsOf, getStringEnumValues } from '@enerkey/ts-utils';

import { chartLineColor } from '../../../../constants/chart-constants';
import { ColorService } from '../../../../shared/services/color.service';
import {
  getActionIconColor,
  getAlarmIconColor,
  ReportEventContainer,
  ReportingActionEvent
} from '../../services/reporting-chart-event.functions';
import { ReportingSeries, ReportSeriesDataPoint } from '../../shared/reporting-series';
import { ReportingChartLabelSettings } from '../../../../shared/energy-reporting-shared/pipes/chart-categories.pipe';
import { PeriodLabelService } from '../../services/period-label.service';
import { ReportingChartMinMaxPipe } from '../../pipes/reporting-chart-min-max.pipe';

export enum NoteType {
  Comment = 'Comment',
  Action = 'Action',
  Alarm = 'Alarm',
}

const axisBackground = '#ffffffde';
const axisColor = '#232323';
const axisFont = '12px "Open Sans"';
const notesFont = '10px "Open Sans"';

@Component({
  selector: 'reporting-chart',
  templateUrl: './reporting-chart.component.html',
  styleUrls: ['./reporting-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReportingChartMinMaxPipe],
})
export class ReportingChartComponent implements OnChanges {
  @Input() public series: ReportingSeries[];
  @Input() public quantityId: Quantities;
  @Input() public notes: ReportEventContainer[];
  @Input() public labelSettings: ReportingChartLabelSettings;

  @Output() public readonly seriesClick = new EventEmitter<ReportSeriesDataPoint>();

  @ViewChild(ChartComponent) public chart: ChartComponent;
  @ViewChild(CategoryAxisItemComponent) public categoryAxisItemComponent: CategoryAxisItemComponent;

  public showTooltip = false;
  public tooltipContent: ReportEventContainer['events'];

  public anchorElement: ElementRef;

  public readonly chartLineColor = chartLineColor;
  public readonly RequestResolution = RequestResolution;
  public readonly axisColor = axisColor;
  public readonly axisFont = axisFont;
  public readonly axisBackground = axisBackground;

  public readonly popupAlign: Align = {
    horizontal: 'left',
    vertical: 'top'
  };

  public popupNoteType: NoteType;

  public readonly legendLabels: LegendLabels = {
    color: axisColor,
    font: axisFont,
  };

  public readonly noteVisualFn: (e: AxisNoteVisualArgs) => Group;

  public minMaxPlotBands: PlotBand[];
  public categoryAxis: { min: number, max: number } = { min: null, max: null };
  public chartZoomed = false;
  private showMinPlotBand: boolean = true;
  private showMaxPlotBand: boolean = true;

  private readonly noteTypes: readonly NoteType[];

  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly colorService: ColorService,
    private readonly periodLabelService: PeriodLabelService,
    private readonly minMaxPipe: ReportingChartMinMaxPipe
  ) {
    this.noteTypes = getStringEnumValues(NoteType);
    this.noteVisualFn = this._noteVisualFn.bind(this);
    this.labelContentFn = this.labelContentFn.bind(this);
    this.labelVisualFn = this.labelVisualFn.bind(this);
  }

  public ngOnChanges(): void {
    if (this.chartZoomed) {
      this.resetZoom();
    }
    this.showMinPlotBand = true;
    this.showMaxPlotBand = true;
    this.minMaxPlotBands = this.minMaxPipe.transform(this.series);
  }

  public mouseleave(): void {
    this.showTooltip = false;
  }

  public noteHover(e: NoteHoverEvent): void {
    this.tooltipContent = (e.dataItem as ReportEventContainer).events;
  }

  /* istanbul ignore next */
  public render(): void {
    this.chart.surface.bind('mouseenter', args => {
      const visualName = args.element.options.name;
      if (visualName && this.noteTypes.includes(visualName)) {
        this.anchorElement = args.originalEvent.srcElement;
        this.showTooltip = true;
        this.popupNoteType = visualName;
      } else {
        this.showTooltip = false;
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  public seriesClicked(event: SeriesClickEvent): void {
    if (!event.originalEvent.event.shiftKey) {
      this.seriesClick.emit(event.dataItem);
    }
  }

  public plotAreaClicked(event: PlotAreaClickEvent): void {
    if (event.originalEvent.event.shiftKey) {
      // Reset chart zoom
      this.resetZoom();
    }
  }

  public legendItemClicked(event: LegendItemClickEvent): void {
    if (this.chartZoomed && this.categoryAxis) {
      (event.sender as any).options.categoryAxis[0].min = this.categoryAxis.min;
      (event.sender as any).options.categoryAxis[0].max = this.categoryAxis.max;
    }
    const index = event.series.index;
    const serieType = this.series[index].options.serieType;

    if (['min', 'max'].includes(serieType)) {
      this.showMinPlotBand = serieType === 'min' ? !event.series.visible : this.showMinPlotBand;
      this.showMaxPlotBand = serieType === 'max' ? !event.series.visible : this.showMaxPlotBand;

      this.minMaxPlotBands = this.minMaxPipe.transform(this.series, this.showMinPlotBand, this.showMaxPlotBand);
    }
  }

  public onZoom(event: ZoomEndEvent): void {
    this.chartZoomed = true;
    this.categoryAxis = {
      min: event.axisRanges.categoryAxis?.min as number,
      max: event.axisRanges.categoryAxis?.max as number,
    };
  }

  public labelContentFn(e: AxisLabelContentArgs): string {
    const timestamps = this.series.map(s => s.values[e.value]?.timestamp).filter(t => t);
    return this.periodLabelService.getChartCategoryLabel({
      timestamps,
      resolution: this.labelSettings.resolution,
      amountOfPeriods: this.labelSettings.amountOfPeriods,
      specialDayLabelFormat: this.labelSettings.specialDayLabelFormat,
      index: e.value,
      useShortFormat: true,
    });
  }

  public labelVisualFn(e: AxisLabelVisualArgsOf<ReportSeriesDataPoint>): Element {
    if (![
      RequestResolution.P1D,
      RequestResolution.PT1H,
      RequestResolution.PT15M
    ].includes(this.labelSettings.resolution)) {
      return e.createVisual();
    }
    if (!this.labelSettings.specialDayLabelFormat) {
      return e.createVisual();
    }
    const isSunday = e.dataItem.timestamp.getDay() === 0;
    if (!isSunday) {
      return e.createVisual();
    }
    const layout = new Layout(e.rect, { orientation: 'vertical', alignContent: 'center' });
    layout.append(new Text(
      e.text,
      [0, 0],
      {
        ...e.options,
        fill: { color: 'red' }
      }
    ));
    layout.reflow();
    return layout;
  }

  private resetZoom(): void {
    this.chartZoomed = false;
    if (this.categoryAxisItemComponent) {
      this.categoryAxisItemComponent.notifyChanges({
        min: 0,
        max: null,
      });
    }
  }

  private _noteVisualFn(e: AxisNoteVisualArgs): Group {
    //  workaround for notes in charts that have negative values
    if (e.rect.origin.y < 60) {
      return;
    }
    const group = new Group();
    const dataItem: ReportEventContainer = (e as unknown as { dataItem: ReportEventContainer }).dataItem;
    const availableSpaceMidPointX = e.rect.size.width / 2;
    const notificationLeft = e.rect.origin.x;
    const notificationTop = e.rect.origin.y + 10;
    const iconHorizontalPosition = notificationLeft + availableSpaceMidPointX - 10 / 2;

    if (Array.hasItems(dataItem.events.actions)) {
      const position = new geometry.Point(iconHorizontalPosition, notificationTop + 12);
      group.append(this.getActionIcon(dataItem.events.actions, position, NoteType.Action));
    }
    if (Array.hasItems(dataItem.events.comments)) {
      const position = new geometry.Point(iconHorizontalPosition, notificationTop + 24);
      group.append(this.getActionIcon(dataItem.events.comments, position, NoteType.Comment));
    }
    if (Array.hasItems(dataItem.events.alarms)) {
      const position = new geometry.Point(iconHorizontalPosition, notificationTop + 36);
      group.append(this.getAlarmIcon(dataItem.events.alarms, position, NoteType.Alarm));
    }

    return group;
  }

  private getActionIcon(
    actions: ReportingActionEvent[],
    position: geometry.Point,
    noteType: NoteType
  ): Text {
    const character = actions.some(action => action.startMatch) ? '▶' : '◀';
    return new Text(character, position, {
      name: noteType,
      fill: {
        color: this.colorService.getCssProperty(getActionIconColor(actions)),
      },
      font: notesFont,
    });
  }

  private getAlarmIcon(
    alarms: LogLiteDto[],
    position: geometry.Point,
    noteType: NoteType
  ): Text {
    return new Text('▲', position, {
      name: noteType,
      fill: {
        color: this.colorService.getCssProperty(getAlarmIconColor(alarms)),
      },
      font: notesFont,
    });
  }
}
