import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ServiceLevel } from '@enerkey/clients/user-management';
import { getNumericEnumValues } from '@enerkey/ts-utils';

import { EkComboComponent } from '../ek-combo/ek-combo.component';

const serviceLevelTranslations: Record<ServiceLevel, string> = {
  [ServiceLevel.Large]: 'L',
  [ServiceLevel.Medium]: 'M',
  [ServiceLevel.Small]: 'S',
};

@Component({
  selector: 'service-level-select',
  templateUrl: '../ek-combo/ek-combo.component.html',
  styleUrls: ['../ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ServiceLevelSelectComponent),
    multi: true
  }]
})
export class ServiceLevelSelectComponent extends EkComboComponent<ServiceLevel> {
  public override readonly items = getNumericEnumValues(ServiceLevel).map(option => ({
    value: option,
    text: serviceLevelTranslations[option]
  }));
}
