<div>
  <h1 *ngIf="facility$ | async as facility">
    {{ facility.FacilityInformation.RealEstateId }} &ndash; {{ facility.Name }}
    <span class="latest-documents-container no-print">
      <facilities-popup-documents [facility]="facility"></facilities-popup-documents>
    </span>
  </h1>
  <div class="row">
    <div class="small-12 medium-7 columns">
      <div class="facility-overview-buttons">
        <input
          *labelWrap="(latestMonthName$ | async) + ' ' + (latestYear$ | async)"
          type="radio"
          kendoRadioButton
          name="timeFrame"
          [formControl]="timeFrameControl"
          [value]="monthKey"
        >
        <input
          *labelWrap="yearText$ | async"
          type="radio"
          kendoRadioButton
          name="timeFrame"
          [formControl]="timeFrameControl"
          [value]="yearKey"
        >
        <button
          class="button button--circle no-print"
          (click)="expandAll()"
          title="{{ 'FACILITIES_REPORT.OVERVIEW.EXPAND_ALL' | translate }}"
        >
          <i class="fas fa-expand-alt"></i>
        </button>
        <button
          class="button button--circle no-print"
          (click)="collapseAll()"
          title="{{ 'FACILITIES_REPORT.OVERVIEW.COLLAPSE_ALL' | translate }}"
        >
          <i class="fas fa-compress-alt"></i>
        </button>
      </div>
      <div #gridContainer>
        <gridster [options]="gridOptions">
          <gridster-item
            *ngFor="let quantityData of quantityData$ | async"
            [item]="quantityData"
            #gridsterItem
          >
            <div class="overview-quantity-card card">
              <div
                class="header-group"
                [ngClass]="'bg-enerkey-' + (quantityData.ID | quantityKey | async)"
              >
                <div class="header-icon header-icon--bg-round header-icon--bg-white">
                  <quantity-icon [quantity]="quantityData.ID"></quantity-icon>
                </div>
                <h3 class="header-group__label h-secondary">{{ quantityData.ID | quantityName | async }}</h3>
                <button
                  class="button button--secondary icon card-expand-button no-print"
                  (click)="changeCardSize(gridsterItem)"
                >
                  <i
                    class="fa"
                    [ngClass]="(quantityData.expanded$ | async) ? 'fa-compress-alt' : 'fa-expand-alt'"
                  ></i>
                </button>
              </div>
              <overview-card-content
                [timeRange]="timeFrameControl.value"
                [item]="quantityData"
                [expanded]="quantityData.expanded$ | async"
              ></overview-card-content>
              <consumption-chart
                *ngIf="quantityData.expanded$ | async"
                (seriesClick)="moveToReport(quantityData)"
                [quantityData]="quantityData.Values"
                [unitKey]="ReportingUnit.Default"
                [resolution]="'P1M'"
                [periodTitles]="periodTitles$ | async"
                [showAxis]="true"
                [showLegend]="true"
                [showGridLines]="true"
                [showGap]="true"
              ></consumption-chart>
            </div>
          </gridster-item>
        </gridster>
      </div>
    </div>
    <div
      class="small-12 medium-5 columns no-print"
      *ngIf="facility$ | async as facility"
    >
      <section *ngIf="actionsAndComments$ | async as actionsAndComments">
        <h2>{{ 'FACILITIES_REPORT.OVERVIEW.ACTIONS_TITLE' | translate }}</h2>
        <overview-report-actions
          *ngIf="actionsAndComments.actions"
          [actions]="actionsAndComments.actions"
          [facilityId]="facility.FacilityId"
          [defaultActionQuantity]="actionDefaultQuantity$ | async"
        ></overview-report-actions>
        <overview-report-comments
          *ngIf="actionsAndComments.comments"
          [actions]="actionsAndComments.comments"
          [facilityId]="facility.FacilityId"
          [defaultActionQuantity]="actionDefaultQuantity$ | async"
        ></overview-report-comments>
      </section>
      <section>
        <h2>{{ 'FACILITIES_REPORT.OVERVIEW.FACILITY_INFORMATION' | translate }}</h2>
        <overview-report-facility-info
          [facility]="facility"
        ></overview-report-facility-info>
      </section>
    </div>
  </div>
</div>
