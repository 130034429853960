import { Utils } from '../../../../services/utils';

AdminFacilitySpreadsheetSelectionService.$inject = ['AdminSpreadsheetTranslationsService', 'utils'];

type FieldDefinition = kendo.ui.SpreadsheetSheetRowCell & {
  name: string;
  text: string;
  type: 'string' | 'number' | 'custom' | 'date';
  width: number;
  arrayName?: string;
  validationPage?: unknown;
  required?: boolean;
  default?: string | number;
};

export default function AdminFacilitySpreadsheetSelectionService(
  AdminSpreadsheetTranslationsService: Record<string, Function>,
  utils: Utils
): { getUpdateFields(): FieldDefinition[] } {
  const vm = this; // eslint-disable-line @typescript-eslint/no-this-alias
  vm.translations = AdminSpreadsheetTranslationsService.getTranslations();

  return {
    getUpdateFields: getUpdateFields
  };

  function getUpdateFields(): FieldDefinition[] {
    return [
      {
        name: 'displayName',
        text: utils.localizedString('FACILITY.EDIT_FORM.DISPLAYNAME'),
        type: 'string',
        width: 165,
        required: true
      },
      {
        name: 'addresses[0].streetAddress',
        text: utils.localizedString('FACILITYINFORMATION.STREETADDRESS'),
        type: 'string',
        width: 165,
        arrayName: 'addresses',
        default: ''
      },
      {
        name: 'addresses[0].postalCode',
        text: utils.localizedString('FACILITYINFORMATION.POSTALCODE'),
        type: 'string',
        width: 165,
        arrayName: 'addresses',
        default: '',
        format: '@'
      },
      {
        name: 'addresses[0].city',
        text: utils.localizedString('FACILITYINFORMATION.CITY'),
        type: 'string',
        width: 165,
        arrayName: 'addresses',
        default: ''
      },
      {
        name: 'businessIdentityCodeId',
        text: utils.localizedString('FACILITYINFORMATION.BUSINESSIDENTITYCODE'),
        type: 'number',
        width: 100,
        required: true
      },
      {
        name: 'localeId',
        text: utils.localizedString('FACILITYINFORMATION.LOCALE'),
        type: 'number',
        width: 100,
        validationPage: vm.translations.sheets.localePage,
        required: true
      },
      {
        name: 'customerSpecifiedIdentifier',
        text: utils.localizedString('FACILITYINFORMATION.REALESTATEID'),
        type: 'string',
        width: 100,
        format: '@'
      },
      {
        name: 'buildingClassId',
        text: utils.localizedString('FACILITY.EDIT_FORM.BUILDINGCLASS'),
        type: 'number',
        width: 100,
        validationPage: vm.translations.sheets.buildingClassPage,
        required: false
      },
      {
        name: 'yearOfBuild',
        text: utils.localizedString('FACILITYINFORMATION.CONSTRUCTIONYEAR'),
        type: 'number',
        width: 100,
        default: null
      },
      {
        name: 'electricityTaxClass',
        text: utils.localizedString('FACILITYINFORMATION.ELECTRICITYTAXCLASS'),
        type: 'number',
        width: 100,
        validationPage: vm.translations.sheets.electricityTaxClassPage,
        default: 0
      },
      {
        name: 'geographicalArea',
        text: utils.localizedString('FACILITYINFORMATION.GEOGRAPHICALAREA'),
        type: 'string',
        width: 165,
        default: ''
      },
      {
        name: 'ownership',
        text: utils.localizedString('FACILITYINFORMATION.OWNERSHIP'),
        type: 'number',
        width: 100,
        validationPage: vm.translations.sheets.ownershipPage,
        default: 0
      },
      {
        name: 'managementResponsibility',
        text: utils.localizedString('FACILITYINFORMATION.MANAGEMENTRESPONSIBILITY'),
        type: 'custom',
        width: 100,
        default: null,
        validation: {
          dataType: 'list',
          showButton: true,
          allowNulls: true,
          type: 'reject',
          from: '{ true, false }',
        },
      },
      {
        name: 'enegiaDescription',
        text: utils.localizedString('FACILITY.EDIT_FORM.ENEGIA_DESCRIPTION'),
        type: 'string',
        width: 165,
        default: ''
      },
      {
        name: 'customerSetDescription',
        text: utils.localizedString('FACILITYINFORMATION.CUSTOMERSETDESCRIPTION'),
        type: 'string',
        width: 165,
        default: ''
      },
      {
        name: 'addresses[0].country',
        text: utils.localizedString('FACILITY.EDIT_FORM.COUNTRY'),
        type: 'string',
        width: 165,
        arrayName: 'addresses',
        default: ''
      },
      {
        name: 'externalIds.externalId',
        text: utils.localizedString('EXTERNALIDS.EXTERNALID'),
        type: 'string',
        width: 100,
        arrayName: 'externalIds',
        default: '',
        format: '@'
      },
      {
        name: 'externalIds.externalOrganizationId',
        text: utils.localizedString('EXTERNALIDS.EXTERNALORGANIZATIONID'),
        type: 'string',
        width: 100,
        arrayName: 'externalIds',
        default: '',
        format: '@'
      },
      {
        name: 'customBuildingClass',
        text: utils.localizedString('FACILITYINFORMATION.CUSTOMBUILDINGCLASS'),
        type: 'string',
        width: 100,
        required: false
      },
      {
        name: 'externalFacilityId',
        text: utils.localizedString('FACILITYINFORMATION.EXTERNALFACILITYID'),
        type: 'string',
        width: 100,
        default: null,
        format: '@'
      },
      {
        name: 'externalContractId',
        text: utils.localizedString('FACILITYINFORMATION.EXTERNALCONTRACTID'),
        type: 'string',
        width: 100,
        default: null,
        format: '@'
      },
      /*
       EDIT COMING SOON!
      {
        name: 'reportingEndDate',
        text: utils.localizedString('FACILITYINFORMATION.REPORTINGENDDATE'),
        type: 'date',
        width: 100,
        default: null,
        format: '{0:g}',
      },
      {
        name: 'billingEndDate',
        text: utils.localizedString('FACILITYINFORMATION.BILLINGENDDATE'),
        type: 'date',
        width: 100,
        default: null,
        format: '{0:g}',
      },
       */
      {
        name: 'powerOfAttorneyExpirationDate',
        text: utils.localizedString('FACILITYINFORMATION.POWEROFATTORNEYEXPIRATIONDATE'),
        type: 'date',
        width: 100,
        default: null,
        format: 'd.M.yyyy',
        validation: {
          dataType: 'date',
          comparerType: 'greaterThan',
          from: 'DATEVALUE("1.1.2000")',
          allowNulls: true,
          messageTemplate: vm.translations.validFrom.messageTemplate,
          showButton: true
        },
      },
      {
        name: 'technicalSpecifications.ventilationMethod',
        text: utils.localizedString('TECHNICALSPECIFICATIONS.VENTILATIONMETHOD'),
        type: 'number',
        width: 100,
        arrayName: 'technicalSpecifications',
        validationPage: utils.localizedString('TECHNICALSPECIFICATIONS.VENTILATIONMETHOD')
      },
      {
        name: 'technicalSpecifications.primaryVentilationSystem',
        text: utils.localizedString('TECHNICALSPECIFICATIONS.PRIMARYVENTILATIONSYSTEM'),
        type: 'number',
        width: 100,
        arrayName: 'technicalSpecifications',
        validationPage: utils.localizedString('TECHNICALSPECIFICATIONS.PRIMARYVENTILATIONSYSTEM')
      },
      {
        name: 'technicalSpecifications.secondaryVentilationSystem',
        text: utils.localizedString('TECHNICALSPECIFICATIONS.SECONDARYVENTILATIONSYSTEM'),
        type: 'number',
        width: 100,
        arrayName: 'technicalSpecifications',
        validationPage: utils.localizedString('TECHNICALSPECIFICATIONS.PRIMARYVENTILATIONSYSTEM')
      },
      {
        name: 'technicalSpecifications.efficiencyRatio',
        text: utils.localizedString('TECHNICALSPECIFICATIONS.EFFICIENCYRATIO'),
        type: 'number',
        width: 100,
        arrayName: 'technicalSpecifications',
      },
      {
        name: 'technicalSpecifications.heatDistributionSystem',
        text: utils.localizedString('TECHNICALSPECIFICATIONS.HEATDISTRIBUTIONSYSTEM'),
        type: 'number',
        width: 100,
        arrayName: 'technicalSpecifications',
        validationPage: utils.localizedString('TECHNICALSPECIFICATIONS.HEATDISTRIBUTIONSYSTEM')
      },
      {
        name: 'technicalSpecifications.automationSystem',
        text: utils.localizedString('TECHNICALSPECIFICATIONS.AUTOMATIONSYSTEM'),
        type: 'string',
        width: 100,
        arrayName: 'technicalSpecifications',
        format: '@'
      },
      {
        name: 'technicalSpecifications.refrigerant',
        text: utils.localizedString('TECHNICALSPECIFICATIONS.REFRIGERANT'),
        type: 'string',
        width: 100,
        arrayName: 'technicalSpecifications',
        format: '@'
      },
      {
        name: 'tags',
        text: utils.localizedString('TAGS.TITLE'),
        type: 'string',
        width: 300,
        default: '',
      },
    ];
  }
}
