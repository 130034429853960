<modal-view-header [headingText]="'TOPBAR.FEEDBACK_TITLE' | translate"></modal-view-header>

<div class="modal-body ek-form">
  <form id="feedbackForm" class="no-border-on-invalid" [formGroup]="feedbackContent" (ngSubmit)="sendFeedback()">
    <input
      id="feedbackEmailAddress"
      kendoTextBox
      type="email"
      *labelBefore="'TOPBAR.FEEDBACK_EMAIL_ADDRESS' | translate"
      formControlName="feedbackEmailAddress"
    />
    <input
      id="feedbackPhoneNumber"
      kendoTextBox
      type="text"
      *labelBefore="'TOPBAR.FEEDBACK_PHONE_NUMBER' | translate"
      formControlName="feedbackPhoneNumber"
    />
    <input
      id="feedbackSubject"
      kendoTextBox
      type="text"
      *labelBefore="'TOPBAR.FEEDBACK_SUBJECT' | translate"
      formControlName="feedbackSubject"
    />
    <textarea
      id="feedbackText"
      kendoTextArea
      *labelBefore="'TOPBAR.FEEDBACK_TEXT' | translate"
      formControlName="feedbackText"
    ></textarea>
  </form>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="modal-footer-buttons float-right">
      <button
        form="feedbackForm"
        class="button"
        type="submit"
        autofocus
        [disabled]="!feedbackContent.valid"
      >
        {{ 'TOPBAR.SEND_FEEDBACK_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
