import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalService } from '@enerkey/foundation-angular';

import { WidgetType } from '../../shared/widget-type';
import { EditableWidgetSettings } from '../../shared/editable-widget-settings';
import { DashboardService } from '../../services/dashboard.service';
import { WidgetBase } from '../../shared/widget-base.interface';
import { WidgetSettingsGeneralComponent } from '../widget-settings-general/widget-settings-general.component';
import { WidgetDefinitionsService } from '../../services/widget-definitions.service';

@Component({
  selector: 'widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetContainerComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('widgetContainer', { read: ViewContainerRef }) public widgetContainer: ViewContainerRef;
  @Output() public readonly widgetSettingsChange = new EventEmitter<EditableWidgetSettings>();
  @Output() public readonly widgetDelete = new EventEmitter<void>();

  public iconClass: string;
  public defaultTitle: string;

  public readonly loading$ = new BehaviorSubject<boolean>(false);
  public readonly error$ = new BehaviorSubject<boolean>(false);

  @Input() public widgetType: WidgetType;
  @Input() public title: string;
  @Input() public dataModelOptions: unknown;

  private readonly destroyParent$ = new Subject<void>();
  private readonly destroyChildComponent$ = new Subject<void>();

  private readonly destroy$: Observable<void>;

  private componentRef: ComponentRef<WidgetBase>;

  public constructor(
    private readonly modalService: ModalService,
    private readonly dashboardService: DashboardService,
    private readonly widgetDefinitionsService: WidgetDefinitionsService
  ) {
    this.destroy$ = merge(this.destroyParent$, this.destroyChildComponent$);
  }

  public ngOnInit(): void {
    const widget = this.widgetDefinitionsService.getWidgetDefinition(this.widgetType);
    this.iconClass = widget?.iconClass;
    this.defaultTitle = widget?.defaultTitle;
  }

  public ngAfterViewInit(): void {
    this.createComponent();
  }

  public ngOnDestroy(): void {
    this.destroyParent$.next();
    this.destroyParent$.complete();
    this.loading$.complete();
    this.error$.complete();

    this.destroyComponent();
  }

  public openWidgetSettings(): void {
    const widgetDefinition = this.widgetDefinitionsService.getWidgetDefinition(this.widgetType);
    const modalRef = this.modalService.open(WidgetSettingsGeneralComponent);
    modalRef.componentInstance.widgetType = this.widgetType;
    modalRef.componentInstance.title = this.title;
    modalRef.componentInstance.defaultTitle = widgetDefinition.defaultTitle;
    modalRef.componentInstance.dataModelOptions = this.dataModelOptions;

    modalRef.result
      .then(settings => {
        if (settings.deleteWidget) {
          this.widgetDelete.emit();
        } else {
          this.dataModelOptions = settings.dataModelOptions;
          // Recreate component
          this.reloadWidget();
          this.widgetSettingsChange.emit({
            title: settings.title,
            dataModelOptions: settings.dataModelOptions
          });
        }
      })
      .catch(() => { });
  }

  public reloadWidget(): void {
    this.error$.next(false);
    this.createComponent();
  }

  private createComponent(): void {
    const component = this.dashboardService.getWidgetComponent(this.widgetType);
    this.destroyComponent();

    this.componentRef = this.widgetContainer.createComponent(component);
    this.componentRef.instance.dataModelOptions = this.dataModelOptions;
    this.componentRef.instance.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      this.loading$.next(loading);
    });

    this.componentRef.instance.error$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.error$.next(true);
      this.destroyComponent();
    });

    this.componentRef.instance.dataModelChange$.pipe(takeUntil(this.destroy$)).subscribe(newDataModel => {
      this.widgetSettingsChange.emit({
        title: this.title,
        dataModelOptions: newDataModel
      });
      this.dataModelOptions = newDataModel;
      this.reloadWidget();
    });

    this.componentRef.changeDetectorRef.detectChanges();
  }

  private destroyComponent(): void {
    if (this.componentRef) {
      this.destroyChildComponent$.next();
      this.componentRef.destroy();
    }
  }
}
