import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  RendererFactory2,
  TemplateRef
} from '@angular/core';
import { Subject } from 'rxjs';

import { ngfFocusTrap } from '../util/focus-trap';
import { ContentRef } from '../util/popup';
import { ScrollBar } from '../util/scrollbar';
import { NgfActiveModal, NgfModalRef } from './modal-ref';
import { NgfModalWindow } from './modal-window';
import { NgfModalOptions } from './modal-config';

function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

type Class = { new(...args: any[]): any };
type ComponentClassType<T> = T extends Class ? InstanceType<T> : unknown;
type Classible<T> = T extends Class ? T : never;
type ModalContentType<T extends Class> = T | string | TemplateRef<any>;

@Injectable({ providedIn: 'root' })
export class NgfModalStack {
  private _activeWindowCmptHasChanged = new Subject<void>();
  private _ariaHiddenValues: Map<Element, string> = new Map();
  private _modalRefs: NgfModalRef<any>[] = [];
  private _windowCmpts: ComponentRef<NgfModalWindow>[] = [];
  private _windowAttributes = [
    'ariaLabelledBy', 'backdrop', 'centered',
    'keyboard', 'size',
    'windowClass', 'backdropClass'
  ] as const;

  public constructor(
    private _applicationRef: ApplicationRef, private _injector: Injector, @Inject(DOCUMENT) private _document: any,
    private _scrollBar: ScrollBar, private _rendererFactory: RendererFactory2
  ) {
    // Trap focus on active WindowCmpt
    this._activeWindowCmptHasChanged.subscribe(() => {
      if (this._windowCmpts.length) {
        const activeWindowCmpt = this._windowCmpts[this._windowCmpts.length - 1];
        ngfFocusTrap(activeWindowCmpt.location.nativeElement, this._activeWindowCmptHasChanged);
        this._revertAriaHidden();
        this._setAriaHidden(activeWindowCmpt.location.nativeElement);
      }
    });
  }

  public open<T, E extends ComponentClassType<T>>(
    moduleCFR: ComponentFactoryResolver,
    contentInjector: Injector,
    content: ModalContentType<Classible<T>>,
    options: NgfModalOptions
  ): NgfModalRef<E> {
    const containerEl = isDefined(options.container)
      ? this._document.querySelector(options.container)
      : this._document.body;
    const renderer = this._rendererFactory.createRenderer(null, null);

    const revertPaddingForScrollBar = this._scrollBar.compensate();
    const removeBodyClass = (): void => {
      if (!this._modalRefs.length) {
        renderer.removeClass(this._document.body, 'is-reveal-open');
        this._revertAriaHidden();
      }
    };

    if (!containerEl) {
      throw new Error(`The specified modal container "${options.container || 'body'}" was not found in the DOM.`);
    }

    const activeModal = new NgfActiveModal();
    const contentRef = this._getContentRef(
      moduleCFR,
      options.injector || contentInjector,
      content,
      options.context,
      activeModal
    );

    const windowCmptRef: ComponentRef<NgfModalWindow> = this._attachWindowComponent(moduleCFR, containerEl, contentRef);
    const ngfModalRef =
      new NgfModalRef(windowCmptRef, contentRef, options.beforeDismiss);

    this._registerModalRef(ngfModalRef);
    this._registerWindowCmpt(windowCmptRef);
    ngfModalRef.result.then(revertPaddingForScrollBar, revertPaddingForScrollBar);
    ngfModalRef.result.then(removeBodyClass, removeBodyClass);
    activeModal.close = (result: any) => {
      ngfModalRef.close(result);
    };
    activeModal.dismiss = (reason: any) => {
      ngfModalRef.dismiss(reason);
    };

    this._applyWindowOptions(windowCmptRef.instance, options);
    if (this._modalRefs.length === 1) {
      renderer.addClass(this._document.body, 'is-reveal-open');
    }

    return ngfModalRef as NgfModalRef<E>;
  }

  public dismissAll(reason?: any): void {
    this._modalRefs.forEach(ngfModalRef => ngfModalRef.dismiss(reason));
  }

  public hasOpenModals(): boolean {
    return this._modalRefs.length > 0;
  }

  private _attachWindowComponent(moduleCFR: ComponentFactoryResolver, containerEl: any, contentRef: any):
  ComponentRef<NgfModalWindow> {
    const windowFactory = moduleCFR.resolveComponentFactory(NgfModalWindow);
    const windowCmptRef = windowFactory.create(this._injector, contentRef.nodes);
    this._applicationRef.attachView(windowCmptRef.hostView);
    containerEl.appendChild(windowCmptRef.location.nativeElement);
    return windowCmptRef;
  }

  private _applyWindowOptions(windowInstance: NgfModalWindow, options: Record<string, any>): void {
    this._windowAttributes.forEach(optionName => {
      if (isDefined(options[optionName])) {
        (windowInstance[optionName] as any) = options[optionName];
      }
    });
  }

  private _getContentRef<T, E extends ComponentClassType<T>>(
    moduleCFR: ComponentFactoryResolver,
    contentInjector: Injector,
    content: ModalContentType<Classible<T>>,
    context: Record<string, unknown> | null,
    activeModal: NgfActiveModal
  ): ContentRef<E> {
    if (content instanceof TemplateRef) {
      return this._createFromTemplateRef(content, context, activeModal) as ContentRef<E>;
    } else if (typeof content === 'string') {
      return this._createFromString(content) as ContentRef<E>;
    } else {
      return this._createFromComponent(moduleCFR, contentInjector, content, activeModal) as unknown as ContentRef<E>;
    }
  }

  private _createFromTemplateRef(
    content: TemplateRef<any>,
    userSuppliedContext: Record<string, unknown> | null,
    activeModal: NgfActiveModal
  ): ContentRef {
    const context = {
      $implicit: activeModal,
      close(result: any) {
        activeModal.close(result);
      },
      dismiss(reason: any) {
        activeModal.dismiss(reason);
      },
      ...(userSuppliedContext ?? {})
    };
    const viewRef = content.createEmbeddedView(context);
    this._applicationRef.attachView(viewRef);
    return new ContentRef([viewRef.rootNodes], viewRef);
  }

  private _createFromString(content: string): ContentRef {
    const component = this._document.createTextNode(`${content}`);
    return new ContentRef([[component]]);
  }

  private _createFromComponent<T extends Class>(
    moduleCFR: ComponentFactoryResolver, contentInjector: Injector, content: T,
    context: NgfActiveModal
  ): ContentRef<InstanceType<T>> {
    const contentCmptFactory =
      moduleCFR.resolveComponentFactory(content) as ComponentFactory<InstanceType<typeof content>>;
    const modalContentInjector =
      Injector.create({ providers: [{ provide: NgfActiveModal, useValue: context }], parent: contentInjector });
    const componentRef = contentCmptFactory.create(modalContentInjector);
    this._applicationRef.attachView(componentRef.hostView);
    return new ContentRef([[componentRef.location.nativeElement]], componentRef.hostView, componentRef);
  }

  private _setAriaHidden(element: Element): void {
    const parent = element.parentElement;
    if (parent && element !== this._document.body) {
      Array.from(parent.children).forEach(sibling => {
        if (sibling !== element && sibling.nodeName !== 'SCRIPT') {
          this._ariaHiddenValues.set(sibling, sibling.getAttribute('aria-hidden'));
          sibling.setAttribute('aria-hidden', 'true');
        }
      });

      this._setAriaHidden(parent);
    }
  }

  private _revertAriaHidden(): void {
    this._ariaHiddenValues.forEach((value, element) => {
      if (value) {
        element.setAttribute('aria-hidden', value);
      } else {
        element.removeAttribute('aria-hidden');
      }
    });
    this._ariaHiddenValues.clear();
  }

  private _registerModalRef<T>(ngfModalRef: NgfModalRef<T>): void {
    const unregisterModalRef = (): void => {
      const index = this._modalRefs.indexOf(ngfModalRef);
      if (index > -1) {
        this._modalRefs.splice(index, 1);
      }
    };
    this._modalRefs.push(ngfModalRef);
    ngfModalRef.result.then(unregisterModalRef, unregisterModalRef);
  }

  private _registerWindowCmpt(ngfWindowCmpt: ComponentRef<NgfModalWindow>): void {
    this._windowCmpts.push(ngfWindowCmpt);
    this._activeWindowCmptHasChanged.next();

    ngfWindowCmpt.onDestroy(() => {
      const index = this._windowCmpts.indexOf(ngfWindowCmpt);
      if (index > -1) {
        this._windowCmpts.splice(index, 1);
        this._activeWindowCmptHasChanged.next();
      }
    });
  }
}
