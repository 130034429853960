import { RouteAuthLogic, UIStateNg2 } from '../../shared/routing';
import { Service } from '../../constants/service';
import { Roles } from '../admin/constants/roles';

export const reportingTopbarState: UIStateNg2<'reporting'> = {
  name: 'reporting',
  data: {
    translationKey: 'REPORTING.TAB_TITLE',
    auth: {
      logic: RouteAuthLogic.Any,
      services: [Service.EnergyReporting, Service.ReportingBeta],
      roles: [Roles.HAS_ACCESS_TO_ALL_PROFILES],
    },
  }
};
