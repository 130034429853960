import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { StateRegistry, StateService, Transition, UrlService } from '@uirouter/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { UserService } from '../../../../services/user-service';
import { EnerkeyNg2StateDeclaration } from '../../../../shared/routing';
import { ProfileService } from '../../../../shared/services/profile.service';
import type { ReportingParams, ReportingStateDeclaration } from '../../reporting.states';
import { ReportTypeOptionsByStateService } from '../../services/report-type-options-by-state.service';
import { ReportTypeOptionsService } from '../../services/report-type-options.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { TimePeriodHistoryService } from '../../services/time-period-history.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { Roles } from '../../../admin/constants/roles';
import { TableReportService } from '../../services/table-report.service';

const paramsToListenKeys = [
  'gridVisibility$',
  'chartVisibility$',
  'visibleSections$',
  'facilityIds$'
] as const;

const paramsToListen: Record<typeof paramsToListenKeys[number], keyof ReportingParams> = {
  ['gridVisibility$']: 'grids',
  ['chartVisibility$']: 'charts',
  ['visibleSections$']: 'sections',
  ['facilityIds$']: 'facilityIds',
};

interface ReportingTab {
  state: EnerkeyNg2StateDeclaration;
  enabled: boolean;
}

@Component({
  selector: 'reporting-base',
  templateUrl: './reporting-base.component.html',
  styleUrls: ['./reporting-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ReportingSearchService,
    ReportTypeOptionsByStateService,
    {
      provide: ReportTypeOptionsService,
      useClass: ReportTypeOptionsByStateService,
    },
    TimePeriodHistoryService
  ]
})
export class ReportingBaseComponent implements OnDestroy {
  public readonly tabs$: Observable<ReportingTab[]>;
  public readonly selectedFacilities$: Observable<{ id: number, name: string }[]>;
  public readonly requestedRoles: Roles[] = [Roles.FACILITY_IMPORT];
  public showFacilityActions: boolean;

  private readonly _destroy$ = new Subject<void>();
  private deregisterUrlChange: ReturnType<UrlService['onChange']>;

  public constructor(
    private readonly transition: Transition,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly stateService: StateService,
    stateRegistry: StateRegistry,
    private readonly userService: UserService,
    profileService: ProfileService,
    // initialize here to disable correct params initially
    _disabledParamsService: ReportTypeOptionsService,
    private readonly facilityService: FacilityService,
    private readonly urlService: UrlService,
    private readonly tableReportService: TableReportService
  ) {
    const reportingStates = (stateRegistry.get() as ReportingStateDeclaration[]).filter(
      state => state.name.startsWith('reporting.')
    );

    this.selectedFacilities$ = combineLatest([
      reportingSearchService.facilityIds$,
      this.facilityService.filteredProfileFacilities$
    ]).pipe(map(([facilityIds, facilities]) => facilities.filterMap(
      f => facilityIds.includes(f.FacilityId),
      f => ({
        id: f.FacilityId,
        name: f.Name
      })
    )));

    this.tabs$ = combineLatest([
      profileService.profile$,
      this.selectedFacilities$
    ]).pipe(
      map(([_profile, facilities]) => reportingStates.filterMap(
        state => userService.hasAccess(state.data.auth),
        state => ({
          state,
          enabled: state.name === 'reporting.table' || facilities.length > 0
        })
      ))
    );

    this.showFacilityActions = this.hasBulkUpdateAcess();

    this.setInitialParameters();
    this.setStateParamUpdaters();
    this.listenToRouteChange();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    this.deregisterUrlChange?.();
    this.tableReportService.resetGridState();
  }

  public trackBy(_index: number, tab: ReportingTab): string {
    return tab.state.name;
  }

  private setInitialParameters(): void {
    const initialParams = this.transition.params() as ReportingParams;

    this.reportingSearchService.search(this.reportingSearchService.paramsFromState(initialParams));
    if ('facilityIds' in initialParams) {
      this.reportingSearchService.setFacilities(initialParams.facilityIds ?? []);
    }
    if ('sections' in initialParams) {
      this.reportingSearchService.toggleSections(initialParams.sections ?? []);
    }
    if ('grids' in initialParams) {
      this.reportingSearchService.setGridsVisibility(initialParams.grids ?? true);
    }
    if ('charts' in initialParams) {
      this.reportingSearchService.setChartsVisibility(initialParams.charts ?? true);
    }
  }

  private setStateParamUpdaters(): void {
    for (const observableKey of paramsToListenKeys) {
      const observable = this.reportingSearchService[observableKey] as Observable<unknown>;
      observable
        .pipe(
          takeUntil(this._destroy$)
        ).subscribe({
          next: value => {
            this.stateService.go('.', { [paramsToListen[observableKey]]: value });
          }
        });
    }
    this.reportingSearchService.searchParameters$
      .pipe(
        takeUntil(this._destroy$)
      ).subscribe({
        next: value => {
          this.stateService.go('.', value.formValue);
        }
      });
  }

  private listenToRouteChange(): void {
    this.deregisterUrlChange = this.urlService.onChange(_ => {
      this.showFacilityActions = this.hasBulkUpdateAcess();
    });
  }

  private hasBulkUpdateAcess(): boolean {
    return this.urlService.path() === '/reporting/table' && this.userService.hasRole(Roles.FACILITY_IMPORT);
  }
}
