import moment from 'moment';

import { ToasterType } from '../../constants/toaster-type';

const $inject = ['utils', 'configurationService', 'MeterInterfaceService', '$interval', '$element'];

function MeterInterfaceStatusController(utils, configurationService, MeterInterfaceService, $interval, $element) {
  const vm = this;

  vm.meterId;
  vm.logUrl;
  vm.readingStatusLog;
  vm.readerTypeName;
  vm.readerTerminalName = '';
  vm.isReaderConfiguration = true;
  vm.readerMeterValueType;
  vm.dates = {
    readingDates: [],
    failureDates: []
  };
  vm.today = moment();
  const refreshInterval = 15000;
  const refreshTimer = $interval(() => {
    vm.getStatusLog(true);
  }, refreshInterval);

  vm.$onInit = onInit;
  vm.$onDestroy = onDestroy;

  function onInit() {
    getReaderTypeInformation();
    vm.getStatusLog(false);
  }

  function onDestroy() {
    $interval.cancel(refreshTimer);
  }

  function getReaderTypeInformation() {
    configurationService.getReaderTypeInformation(vm.meterId)
      .then(response => {
        vm.logUrl = response.data.logUrl;
        vm.readerTypeName = response.data.name;
        vm.readerTerminalName = response.data.terminal;
        vm.readerMeterValueType = response.data.meterValueType === 1 ?
          utils.localizedString('METER_INTERFACE.READER_TYPE_1') :
          utils.localizedString('METER_INTERFACE.READER_TYPE_2');
        vm.isReaderConfiguration = angular.isDefined(response.data.name);
      })
      .catch(() => {
        utils.popUp(ToasterType.ERROR,
          utils.localizedString('METER_INTERFACE.FAILED_TO_LOAD_CONFIGURATION'));
      });
  }

  vm.getStatusLog = function(isRefresh) {
    const from = moment().subtract(5, 'months').startOf('month').utc().format();
    const to = moment().endOf('month').utc().format();

    configurationService.getStatusLog(vm.meterId, { from: from, to: to })
      .then(response => {
        vm.readingStatusLog = response.data;
        getDistinctReadingDates(response.data);
        if (!isRefresh) {
          createCalendar();
        }
        const calendarData = getCalendarData();
        const calendarValue = angular.isDefined(calendarData) ? moment(calendarData.value()) : null;
        const today = moment(vm.today);
        // Refresh here only when we are watching the current date
        if (today.isSame(calendarValue)) {
          fillStatusHistoryList(calendarValue);
        }
      })
      .catch(errResponse => {
        utils.popUp(ToasterType.ERROR,
          utils.localizedString('CONFIGURATION_CONTROL.ERROR_TEXT_EXECUTE_FAIL'));
        utils.trackError('ConfigurationControl - Failed to fetch reading history', {
          status: errResponse.status,
          message: errResponse.data
        });
      });
  };

  function createCalendar() {
    const endOfMonth = moment().endOf('month').toDate();
    $element.find('#status-calendar').kendoCalendar({
      value: vm.today.toDate(),
      dates: vm.dates,
      month: {
        // Template for dates in month view
        content: '# if (data.dates.readingDates.indexOf(+data.date) !== -1) { #' +
          '# if (data.dates.failureDates.indexOf(+data.date) !== -1) { #' +
          '<div class="failure-event">#= data.value #</div>' +
          '# } else { #' +
          '<div class="success-event">#= data.value #</div>' +
          '<div class="success-indicator"></div>' +
          '# } #' +
          '# } else { #' +
          '#= data.value #' +
          '# } #'
      },
      weekNumber: false,
      footer: false,
      max: endOfMonth,
      change: function() {
        const calendarValue = getCalendarData().value();
        fillStatusHistoryList(moment(calendarValue));
      }
    });
  }

  function getCalendarData() {
    return $element.find('#status-calendar').data('kendoCalendar');
  }

  function getDistinctReadingDates(statusData) {
    const readingDates = [];
    const failureDates = [];
    statusData.forEach(status => {
      const date = moment(status.timestamp).toDate().setHours(0, 0, 0, 0);
      readingDates.push(date);
      if (MeterInterfaceService.isFailStatus(status.readingStatus)) {
        failureDates.push(date);
      }
    });

    vm.dates = {
      readingDates: readingDates.unique(),
      failureDates: failureDates.unique()
    };
  }

  function fillStatusHistoryList(date) {
    const readingStatuses = getReadingStatusesByDate(vm.readingStatusLog, date);
    const historyList = $element.find('#daily-status');
    historyList.empty();
    if (readingStatuses.length === 0) {
      historyList.append(utils.localizedString('METER_INTERFACE.NO_HISTORY_DATA'));
    } else {
      readingStatuses.forEach(statusItem => {
        const timestamp = moment(statusItem.timestamp).format('YYYY-MM-DD HH:mm:ss');
        const value = MeterInterfaceService.getReadingStatusText(statusItem.readingStatus);
        const cssIndicator = MeterInterfaceService.getReadingStatusIndicator(statusItem.readingStatus);
        const cssIndocatorElement = `<span class="indicator indicator--${cssIndicator}-text">${value}</span>`;
        let historyLine;
        if (vm.logUrl) {
          // LogEntries uses millisecond Unix timestamp --> format x
          const from = moment(statusItem.timestamp).subtract(1, 'minutes').format('x');
          const to = moment(statusItem.timestamp).add(1, 'hours').format('x');
          const logAddress = `${vm.logUrl}&from=${from}&to=${to}`;
          historyLine = `<a href="${logAddress}" target="_blank">${cssIndocatorElement}</a>`;
        } else {
          historyLine = `${cssIndocatorElement}`;
        }
        const statusLine = `<div class="status-history-item">${timestamp}${historyLine}</div>`;
        historyList.append(angular.element(statusLine));
      });
    }
  }

  function getReadingStatusesByDate(statusData, date) {
    return statusData.reduce((result, status) => {
      const temp = date.isSame(moment(status.timestamp), 'day') ? result.concat(status) : result;
      return temp;
    }, []);
  }

  vm.IsCurrentDateSelected = function() {
    const calendar = getCalendarData();
    if (angular.isDefined(calendar)) {
      const calendarValue = moment(calendar.value());
      const today = moment(vm.today);
      return today.isSame(calendarValue);
    } else {
      return false;
    }
  };
}

MeterInterfaceStatusController.$inject = $inject;

export default MeterInterfaceStatusController;
