import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';

import { BehaviorSubject, Observable, Subject, switchMap, take } from 'rxjs';
import { delay, filter, skip, takeUntil, tap } from 'rxjs/operators';

import { MeterGroupDto } from '@enerkey/clients/metering';
import { ModalService, NgfModalRef } from '@enerkey/foundation-angular';

import { TemplateLifterService } from '../../../../../shared/services/template-lifter.service';
import { MeterGroupsService } from '../../../services/meter-groups/meter-groups.service';
import { MeterGroupsCreateModalComponent } from '../meter-groups-create-modal/meter-groups-create-modal.component';
import { MeterGroupsGridComponent } from '../meter-groups-grid/meter-groups-grid.component';

@Component({
  selector: 'meter-groups',
  templateUrl: './meter-groups.component.html'
})
export class MeterGroupsComponent implements AfterViewInit, OnDestroy {
  public readonly selectedMeterGroup$: Observable<MeterGroupDto>;
  public readonly selectedFacility$: Observable<number>;
  public readonly editMode$: Observable<boolean>;

  @ViewChild('topbarTemplate') private readonly topRightTemplate: TemplateRef<unknown>;
  @ViewChild(MeterGroupsGridComponent) private readonly meterGroupsGrid: MeterGroupsGridComponent;

  private readonly _selectedMeterGroup$ = new BehaviorSubject<MeterGroupDto>(null);
  private readonly _selectedFacility$ = new BehaviorSubject<number>(null);
  private readonly _destroy$ = new Subject<void>();
  private readonly _editMode$ = new BehaviorSubject<boolean>(null);

  public constructor(
    private readonly templateLifter: TemplateLifterService,
    private readonly modalService: ModalService,
    private readonly meterGroupsService: MeterGroupsService
  ) {
    this.selectedMeterGroup$ = this._selectedMeterGroup$.asObservable();
    this.selectedFacility$ = this._selectedFacility$.asObservable();
    this.editMode$ = this._editMode$.asObservable();

    // Reload grid when edit mode changes
    this.editMode$.pipe(
      filter(editMode => editMode !== null),
      tap(editMode => this.meterGroupsGrid.reloadData(!editMode)),
      takeUntil(this._destroy$)
    ).subscribe();

    // Set edit mode to true when meter group is created, but after new meter group is selected
    this.meterGroupsService.meterGroupCreate$.pipe(
      switchMap(() => this.selectedMeterGroup$.pipe(skip(1), take(1))),
      delay(50),
      tap(() => this._editMode$.next(true)),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
    this._destroy$.next();
    this._destroy$.complete();
    this._selectedMeterGroup$.complete();
    this._selectedFacility$.complete();
    this._editMode$.complete();
  }

  public onMeterGroupChange(meterGroup: MeterGroupDto): void {
    this._selectedMeterGroup$.next(meterGroup);
  }

  public onFacilityChange(facilityId: number): void {
    this._selectedFacility$.next(facilityId);
  }

  public onEditMeterGroupMetersClick(): void {
    this._editMode$.next(true);
  }

  public onCreateMeterGroupClick(): void {
    this.openModal();
  }

  public onSearchClick(): void {
    const shouldFilterByActiveMeters = !this._editMode$.value;
    this.meterGroupsGrid.reloadData(shouldFilterByActiveMeters);
  }

  public onEditMeterGroupClick(): void {
    const { id, name, description, quantityGroupId } = this._selectedMeterGroup$.value;
    const modalRef = this.openModal();

    modalRef.componentInstance.formGroup.patchValue({ id, name, description, quantityGroupId });
  }

  public onGridSave(): void {
    this._editMode$.next(false);
  }

  public onGridCancel(): void {
    this._editMode$.next(false);
  }

  private openModal(): NgfModalRef<MeterGroupsCreateModalComponent> {
    const modalRef = this.modalService.open(MeterGroupsCreateModalComponent, {
      size: 'content',
      beforeDismiss: () => {
        if (modalRef.componentInstance.formGroup.pristine) { return true; }
        modalRef.componentInstance.showConfirmDialog = true;

        return false;
      }
    });

    return modalRef;
  }
}
