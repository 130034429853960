import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { Meter, MeteringClient } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ErrorTicket, FaultMeter } from '@enerkey/clients/error-ticket';

import { DashboardStateService } from '../../services/dashboard-state.service';
import { QuantityService } from '../../../../shared/services/quantity.service';

interface MeterFacility {
  name: string;
  id: number;
  enegiaId: number;
}

type ErrorTicketMeter = {
  meter: Meter,
  facility: MeterFacility,
  quantityName: string,
  faultBeginTime?: Date,
  faultEndTime?: Date,
  faultLengthInHours?: number
}

@Component({
  selector: 'error-ticket-meter-modal',
  templateUrl: './error-ticket-meter-modal.component.html',
  styleUrls: ['./error-ticket-meter-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  windowClass: 'fixed-height modal-dialog-scrollable'
})
export class ErrorTicketMeterModalComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public isFacilityError: boolean;
  public errorTicket: ErrorTicket;
  public modalHeaderText: string;
  public facilities: MeterFacility[] = [];

  public meters$: Observable<ErrorTicketMeter[]>;
  public loading$: Observable<boolean>;

  private _loading$ = new LoadingSubject(true);
  private _destroy$ = new Subject<void>();

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly dashboardStateService: DashboardStateService,
    ngfActiveModal: NgfActiveModal,
    private readonly quantityService: QuantityService
  ) {
    super(ngfActiveModal);

    this.loading$ = this._loading$.asObservable();
  }

  public ngOnInit(): void {
    const facilityMap = this.facilities.toMapBy('id');
    this.meters$ = this.meteringClient.getMeters(this.errorTicket.meterIds).pipe(
      takeUntil(this._destroy$),
      indicate(this._loading$),
      map(meters => meters.mapFilter(
        m => ({
          meter: m,
          facility: facilityMap.get(m.reportingObjectId),
          quantityName: this.quantityService.getQuantityNameForId(m.quantityId),
          faultBeginTime: this.getFaultBegin(m.id),
          faultEndTime: this.getFaultEnd(m.id),
          faultLengthInHours: this.getFaultLength(m.id)
        }),
        m => !!m.facility
      ))
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public openMeterReport(meter: ErrorTicketMeter): void {
    this.dashboardStateService.openMeterReport(meter.meter.id, meter.facility.id);
  }

  public openFacilityReport(meter: ErrorTicketMeter): void {
    this.dashboardStateService.openFacilityReport(meter.facility.id);
  }

  private getFaultBegin(meterId: number): Date {
    return this.findLatestFaultMeter(meterId)?.faultBeginTime;
  }

  private getFaultEnd(meterId: number): Date {
    return this.findLatestFaultMeter(meterId)?.faultEndTime;
  }

  private getFaultLength(meterId: number): number {
    const faultMeter = this.findLatestFaultMeter(meterId);
    if (faultMeter) {
      return (faultMeter?.faultEndTime?.valueOf() - faultMeter?.faultBeginTime?.valueOf()) / 3600000 + 1;
    } else {
      return null;
    }
  }

  private findLatestFaultMeter(meterId: number): FaultMeter {
    const faultMeters = this.errorTicket.faultMeters.filter(f => f.meterId === meterId);
    const latestFaultMeter = faultMeters.sortBy(m => m.faultBeginTime.getTime()).at(-1);
    return latestFaultMeter;
  }
}
