import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import angular, { ITimeoutService, IWindowService } from 'angular';

import {
  AddPersonValidationError,
  CreatePersonModel,
  IContactClient
} from '@enerkey/clients/contact';
import { $InjectArgs } from '@enerkey/ts-utils';

import { DEFAULT_COLOR, HEADER_BACKGROUND } from '../../../../shared/spreadsheet.functions';
import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import { ToasterType } from '../../../../constants/toaster-type';
import WizardStep from '../../../../components/wizard/wizard-step';
import { LanguageService } from '../../../../shared/services/language.service';
import { Utils } from '../../../../services/utils';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

class MassAddPersonsController extends MassImportSpreadsheet {
  public static override readonly $inject: $InjectArgs<typeof MassAddPersonsController> = [
    'utils', 'ContactClient', '$element', '$timeout', '$window', 'KendoFunctions', 'languageService',
  ];

  private static firstNameIndex: number = 0;
  private static lastNameIndex: number = 1;
  private static emailIndex: number = 2;
  private static phoneNumberIndex: number = 3;
  private static preferredLanguageIndex: number = 4;
  private static alarmsBlockedIndex: number = 5;

  public spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  public steps: WizardStep[];
  public currentStep: WizardStep;
  public functionTypes: ({ translation: string; value: number })[];
  public functionType: number;
  public spreadsheetElementId = 'spreadsheet';
  protected messages: SpreadsheetMessages;
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected numberOfColumns = 7;
  private processedIndexes: number[] = [];
  private subscription: Subscription;
  private readonly languages: string;

  public constructor(
    private utils: Utils,
    private contactClient: IContactClient,
    protected $element: JQuery,
    protected $timeout: ITimeoutService,
    protected $window: IWindowService,
    private kendoFunctions: any,
    languageService: LanguageService
  ) {
    super();
    const languagesString = languageService.getShortLanguages().map(lang => `"${lang}"`).join(', ');
    this.languages = `{${languagesString}}`;
  }

  public $onInit(): void {
    this.spreadsheetOptions = this.getSpreadsheetOptions(999);
    this.totalRowCount = this.spreadsheetOptions.rows;
    this.messages = {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE')
    };
    this.steps = [
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
        isReturnable: true,
        clickFn: this.saveValues.bind(this),
        rollbackFn: this.enableSheet.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
        isReturnable: false,
        clickFn: this.stopSaving.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.READY'),
        hideButton: true
      }
    ];

    this.$timeout(() => {
      this.resizeKendo();
    });
  }

  public override $onDestroy(): void {
    super.$onDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private resizeKendo(): void {
    const spreadsheet = this.getSpreadsheetInstance();

    const resizeFn = _.debounce(() => {
      this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
    }, 200);

    angular.element(this.$window).on('resize', resizeFn);

    this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
  }

  private stopSaving(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.processedIndexes.forEach(id => this.setCanceledStatus(id));
    }
  }

  private getCreatePersonModelArrayFromSpreadsheet(): CreatePersonModel[] {
    this.processedIndexes = [];
    const createPersonModelArray: CreatePersonModel[] = [];

    this.getDataRows().forEach((row, index) => {
      if (this.hasRequiredRowValues(row)) {
        const createPersonModel: CreatePersonModel = {
          firstName: row[MassAddPersonsController.firstNameIndex].toString(),
          lastName: row[MassAddPersonsController.lastNameIndex].toString(),
          email: row[MassAddPersonsController.emailIndex].toString(),
          phoneNumber: row[MassAddPersonsController.phoneNumberIndex] ?
            row[MassAddPersonsController.phoneNumberIndex].toString() :
            null,
          preferredLanguage: row[MassAddPersonsController.preferredLanguageIndex] ?
            row[MassAddPersonsController.preferredLanguageIndex].toString() :
            null,
          alarmsBlocked: this.parseAlarmsBlockedValue(row)
        };

        createPersonModelArray.push(createPersonModel);
        this.setProcessingStatus(index);
        this.processedIndexes.push(index);
      }
    });

    return createPersonModelArray;
  }

  private parseAlarmsBlockedValue(row: (string | number)[]): boolean {
    if (row[MassAddPersonsController.alarmsBlockedIndex] === null) {
      return false;
    }
    if (row[MassAddPersonsController.alarmsBlockedIndex] === this.utils.localizedString('YES')) {
      return true;
    }
    if (row[MassAddPersonsController.alarmsBlockedIndex] === this.utils.localizedString('NO')) {
      return false;
    }
  }

  private hasRequiredRowValues(row: (string | number)[]): boolean {
    return row.length > 1 &&
    row[MassAddPersonsController.firstNameIndex] !== null &&
    row[MassAddPersonsController.lastNameIndex] !== null &&
    row[MassAddPersonsController.emailIndex] !== null;
  }

  private saveValues(): void {
    if (this.validateSheet()) {
      this.processedIndexes = [];
      const createPersonModelArray = this.getCreatePersonModelArrayFromSpreadsheet();
      if (createPersonModelArray.length === 0) {
        this.utils.popUp(
          ToasterType.INFO,
          this.utils.localizedString('ADMIN.CONTACTS.ERRORS.EMPTY_SPREADSHEET')
        );
        return;
      }
      this.subscription = this.contactClient
        .createPersons(createPersonModelArray)
        .pipe(finalize(() => this.handleFinally()))
        .subscribe(
          () => this.handleSuccess(),
          error => this.handleError(error)
        );
    } else {
      this.utils.popUp(ToasterType.ERROR, this.utils.localizedString('ADMIN.CONTACTS.ERRORS.INVALID_SPREADSHEET'));
    }
  }

  private handleSuccess(): void {
    this.processedIndexes.forEach(id => this.setSuccessStatus(id));
    this.utils.popUp(
      ToasterType.SUCCESS,
      this.utils.localizedString('CONTACTMANAGER.SUCCESS.ADDED_PERSONS')
    );
  }

  private handleError(error: { [key: string]: AddPersonValidationError[] }): void {
    Object.keys(error).forEach(index => {
      this.setErrorStatus(Number(index), this.getErrorMessages(error[index]));
    });
    this.utils.popUpGeneralError('SAVE', 'CONTACTS');
  }

  private handleFinally(): void {
    this.setFinalStep();
  }

  private getErrorMessages(errors: number[]): string {
    return errors.map(error =>
      this.utils.localizedString(`ADMIN.CONTACTS.ERRORS.${AddPersonValidationError[error]}`))
      .toString();
  }

  private setFinalStep(): void {
    this.currentStep = this.steps[this.steps.length - 1];
  }

  private getSpreadsheetOptions(rowAmount: number): kendo.ui.SpreadsheetOptions {
    let headerCells: any[] = [];

    const textHeaderCells: kendo.ui.SpreadsheetSheetRowCell[] = this.createTextHeaderCells();
    headerCells = headerCells.concat(textHeaderCells);

    headerCells = headerCells.concat(this.createBooleanHeaderCells());

    const statusHeaderCell = {
      value: this.utils.localizedString('ADMIN.SPREADSHEET.STATUS.HEADING'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    };
    headerCells = headerCells.concat(statusHeaderCell);

    const spreadsheetOptions: kendo.ui.SpreadsheetOptions = {
      toolbar: false,
      sheetsbar: false,
      columns: this.numberOfColumns,
      excel: {
        fileName: 'mass_add_persons.xlsx'
      },
      rows: 999,
      defaultCellStyle: {
        background: 'rgba(255,255,255,0)'
      },
      sheets: [
        {
          name: 'default',
          columns: this.createColumnWidths(),
          rows: [{
            cells: headerCells
          }]
        }
      ]
    };

    for (let i = 0; i < rowAmount; i++) {
      const cells = this.createInputRow(textHeaderCells);
      spreadsheetOptions.sheets[0].rows.push({ cells: cells });
    }

    return spreadsheetOptions;
  }

  private createColumnWidths(): kendo.ui.SpreadsheetSheetColumn[] {
    const columns: kendo.ui.SpreadsheetSheetColumn[] = [];
    for (let i = 0; i < this.numberOfColumns; i++) {
      columns.push({ width: 200 });
    }
    return columns;
  }

  private createBooleanHeaderCells(): kendo.ui.SpreadsheetSheetRowCell[] {
    return [{
      value: this.utils.localizedString('CONTACTMANAGER.PERSON.PREFERRED_LANGUAGE'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.PERSON.ALARMS_BLOCKED'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }];
  }

  private createTextHeaderCells(): kendo.ui.SpreadsheetSheetRowCell[] {
    return [{
      value: this.utils.localizedString('CONTACTMANAGER.PERSON.FIRST_NAME'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.PERSON.LAST_NAME'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.PERSON.EMAIL'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.PERSON.PHONE_NUMBER'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }];
  }

  private createInputRow(textHeaderCells: kendo.ui.SpreadsheetSheetRowCell[]):
  ({ enable?: boolean; type: string; validation: kendo.ui.SpreadsheetSheetRowCellValidation }
  | { enable: boolean; type?: string; validation?: kendo.ui.SpreadsheetSheetRowCellValidation })[] {
    const cells = [];

    textHeaderCells.forEach(() => {
      cells.push({});
    });

    cells.push({
      type: 'text',
      validation: {
        dataType: 'list',
        showButton: true,
        from: this.languages,
        allowNulls: true,
        type: 'reject',
        messageTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.TITLE'),
        titleTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.MESSAGE')
      }
    });

    cells.push({
      type: 'text',
      validation: {
        dataType: 'list',
        showButton: true,
        from: `{ "${this.utils.localizedString('YES')}", "${this.utils.localizedString('NO')}" }`,
        allowNulls: true,
        type: 'reject',
        messageTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.TITLE'),
        titleTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.MESSAGE')
      }
    });

    // status should not be editable
    cells.push({
      enable: false
    });

    return cells;
  }
}

export default MassAddPersonsController;
