import EmInputMultiSelectController from './em-input-multi-select-controller';
import emInputMultiSelectTemplate   from 'raw-loader!./em-input-multi-select.html';

export default {
  template: emInputMultiSelectTemplate,
  controller: EmInputMultiSelectController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    onChange: '<'
  }
};
