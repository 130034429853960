<form [formGroup]="form">
  <div>
    <input
      type="text"
      formControlName="to"
      *labelBefore="'MQA.EMAIL.RECEIVER' | translate"
    />
  </div>
  <div>
    <input
      type="text"
      formControlName="cc"
      *labelBefore="'MQA.EMAIL.CC' | translate"
    />
  </div>
  <div>
    <input
      type="text"
      formControlName="subject"
      *labelBefore="'MQA.EMAIL.SUBJECT' | translate"
    />
  </div>
  <kendo-editor
    [ngStyle]="kendoEditorStyle"
    [iframe]="false"
    *labelBefore="'MQA.EMAIL.BODY' | translate; target: '[contenteditable=true]'"
    formControlName="body"
  ></kendo-editor>
</form>
