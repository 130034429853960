import template from 'raw-loader!./mass-add-companies-dialog.html';
import massAddCompaniesController from './mass-add-companies-dialog.controller';

export default {
  template,
  controller: massAddCompaniesController,
  controllerAs: 'vm',
  bindings: {
    close: '&'
  }
};
