<div
  class="chart-wrapper"
  [loadingSpinner]="loading$ | async"
>
  <kendo-chart
    [title]="{ text: title || ('ANALYTICS.ETCURVE.TITLE' | translate) }"
    [transitions]="false"
    [renderAs]="'canvas'"
    *ngIf="!(reset$ | async)"
  >
    <ng-container *ngIf="etCurveModel?.curves || etCurveModel?.values">
      <kendo-chart-series>
        <kendo-chart-series-item
          *ngFor="let serie of series"
          type="scatter"
          [name]="serie.name"
          [data]="serie.data"
          [color]="serie.color"
          xField="temperature"
          yField="value"
        >
          <kendo-chart-tooltip>
            <et-curve-chart-tooltip
              *kendoChartSeriesTooltipTemplate="let dataItem = dataItem"
              [model]="etCurveModel"
              [dataItem]="dataItem"
            ></et-curve-chart-tooltip>
          </kendo-chart-tooltip>
        </kendo-chart-series-item>
        <kendo-chart-series-item
          *ngFor="let curve of curves"
          type="scatterLine"
          [name]="curve.name"
          [data]="curve.points"
          [color]="curve.color"
          xField="temperature"
          yField="value"
          [width]="curve.width ?? 1"
          [markers]="{ visible: false }"
          [highlight]="{ visible: false }"
          [zIndex]="-1"
        ></kendo-chart-series-item>
      </kendo-chart-series>
      <kendo-chart-x-axis>
        <kendo-chart-x-axis-item
          [title]="{ text: '{0} [{1}]' | interpolate: etCurveModel.temperatureUnit.name : etCurveModel.temperatureUnit.unit }"
          [min]="undefined"
          [max]="undefined"
        >
          <kendo-chart-x-axis-item-labels
            [position]="'start'"
            [format]="'{0}'"
          ></kendo-chart-x-axis-item-labels>
        </kendo-chart-x-axis-item>
      </kendo-chart-x-axis>
      <kendo-chart-y-axis>
        <kendo-chart-y-axis-item
          [title]="{ text: '{name} [{unit}/{reso}]' | interpolate: {
            name: etCurveModel.valueUnit.name,
            unit: etCurveModel.valueUnit.unit,
            reso: etCurveModel.resolution | isoDurationName | lowercase
          } }"
          [min]="0"
          [max]="undefined"
        >
          <kendo-chart-y-axis-item-labels
            [position]="'start'"
            [format]="'{0}'"
          ></kendo-chart-y-axis-item-labels>
        </kendo-chart-y-axis-item>
      </kendo-chart-y-axis>

    </ng-container>
  </kendo-chart>

  <div>
    <input
      *labelWrap="'ANALYTICS.ETCURVE.HIGHLIGHT_LATEST' | translate"
      type="checkbox"
      kendoCheckBox
      [(ngModel)]="highlight"
      (ngModelChange)="highlightChanged()"
    >
  </div>
</div>
