import EnerKeyServicesModule from '../../../services';
import LodashModule from '../../lodash';
import MomentModule from '../../moment';

import { LegacyConsumptionsService } from './legacy-consumptions.service';
import forecastTypes from './forecast-types';

export default angular.module('EnergyReporting.Models', [
  EnerKeyServicesModule.name,
  LodashModule.name,
  MomentModule.name
])
  .factory('consumptions', LegacyConsumptionsService)
  .factory('forecastTypes', forecastTypes);
