<ek-dropdown class="links-container">
  <button
    *ekDropdownToggle="let toggle"
    class="button icon"
    [title]="'FACILITIES_REPORT.EXTERNAL_LINKS_POPUP_TITLE' | translate"
    [loading]="loading$ | async"
    (click)="toggle()"
    [disabled]="!(hasUrls$ | async)"
  >
    <i class="fas fa-link"></i>
  </button>
  <div class="heading">
    {{ 'FACILITIES_REPORT.EXTERNAL_LINKS_POPUP_TITLE' | translate }}
  </div>
  <div *ngIf="(urls$ | async) as urls" class="links">
    <ul *ngIf="urls.length; else noLinks">
      <li *ngFor="let url of urls" [title]="url.value">
        <a [href]="url.value" target="_blank">{{ url.label }}</a>
      </li>
    </ul>
    <ng-template #noLinks>
      <span>
        {{ 'FACILITIES_REPORT.EXTERNAL_LINKS_POPUP_NO_LINKS' | translate }}
      </span>
    </ng-template>
  </div>
</ek-dropdown>
