import { ToasterType } from '../../../constants/toaster-type';

const $inject = [
  '$modalInstance', 'utils', 'configurationControlApi',
  'bucketId', '$scope'
];

class DocumentationEditController {
  constructor(
    $modalInstance, utils, configurationControlApi,
    bucketId, $scope
  ) {
    this.$modalInstance = $modalInstance;
    this.utils = utils;
    this.configurationControlApi = configurationControlApi;
    this.bucketId = bucketId;
    this.bucketDocumentation = {
      id: 0,
      bucketId: bucketId,
      html: ''
    };
    this.onInit();

    $scope.$on('$destroy', () => {
      this.editor.destroy();
    });
  }

  onInit() {
    this.configurationControlApi.getBucketDocumentation(this.bucketId)
      .then(response => {
        this.bucketDocumentation = response.data;
      })
      .catch(errResponse => {
        if (errResponse.status !== 404) {
          this.showError(errResponse);
        }
      });
  }

  save() {
    const request = this.bucketDocumentation.id > 0 ?
      this.configurationControlApi.addBucketDocumentation(this.bucketDocumentation) :
      this.configurationControlApi.updateBucketDocumentation(this.bucketDocumentation);

    request
      .then(response => {
        this.bucketDocumentation = response.data;
      })
      .catch(this.showError);
  }

  cancel() {
    this.$modalInstance.dismiss();
  }

  showError(errResponse) {
    const text = `${this.utils.localizedString('CONFIGURATION_CONTROL.ERROR_TEXT_EXECUTE_FAIL')}
      ${errResponse.status}${errResponse.statusText}${errResponse.data}`;
    this.utils.popUp(ToasterType.ERROR, text);
    this.utils.trackError('ConfigurationControl - Failed to save bucket documentation', {
      status: errResponse.status,
      message: errResponse.data
    });
  }
}

DocumentationEditController.$inject = $inject;

export default DocumentationEditController;
