import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FilterDescriptor } from '@progress/kendo-data-query';

import { GridMultiselectFilterComponent } from '../grid-multiselect-filter/grid-multiselect-filter.component';

@Component({
  selector: 'grid-array-filter',
  templateUrl: '../grid-multiselect-filter/grid-multiselect-filter.component.html',
  styleUrls: ['../grid-multiselect-filter/grid-multiselect-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridArrayFilterComponent<T, U> extends GridMultiselectFilterComponent<T, U> {
  /**
   * If true, display grid rows that have field array as empty when filtering
   */
  @Input() public showEmptyItems: boolean = false;

  /**
   * Update the filter descriptor.
   * @param values Filter list of shown values for the field in grid.
   */
  protected override updateFilter(values: U[]): void {
    this.filterService.filter({
      logic: 'or',
      filters: values.map<FilterDescriptor>(
        value => ({
          field: this.field,
          operator: (rowValue: unknown[], selectedValue: unknown) =>
            rowValue.includes(selectedValue) || (this.showEmptyItems && !Array.hasItems(rowValue)),
          value: value,
          ignoreCase: false
        })
      )
    });
  }
}
