import { Directive, TemplateRef } from '@angular/core';

import { DataBindingContext } from '@enerkey/ts-utils';

export interface GridMultiFilterTemplateContext<T, U> extends DataBindingContext<T> {
  /** Complete dataItem. May be same as `value` */
  dataItem: T;

  /** Filter option value used in filtering */
  value: U;

  /** Custom text string or value cast to string, used in sorting */
  text: string | null;
}

@Directive({
  selector: '[gridMultiFilterTemplate]',
})
export class GridMultiFilterTemplateDirective {
  public constructor(
    public readonly ref: TemplateRef<GridMultiFilterTemplateContext<unknown, unknown>>
  ) { }
}
