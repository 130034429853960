<div>
  <div class="widget-link-container">
    <quantity-dropdown
      *ngIf="(quantities$ | async) as quantities"
      [(ngModel)]="quantityId"
      (ngModelChange)="quantityChange()"
      [quantities]="quantities"
      [grouped]="true"
    ></quantity-dropdown>
    <button
      (click)="goToFacilities()"
      class="button button--link button--external-link"
    >
      {{ 'FACILITIES_LINK' | translate }}
    </button>
  </div>
  <div class="widget-unit-info">
    <ng-container *ngIf="isRelationalValue; else quantityUnitInfo">
      {{ (dataModelOptions.variableId | relationalValueName) | async }}:
      {{ (dataModelOptions.variableId | relationalValueUnit : (quantity$ | async) : dataModelOptions.unitKey) | async }}
    </ng-container>
    <ng-template #quantityUnitInfo>
      {{ 'FACILITIES_REPORT.OVERVIEW.CONSUMPTION' | translate }}:
      {{ quantity$ | async | quantityUnit : dataModelOptions.unitKey | async }}
    </ng-template>
  </div>
</div>
<ng-container *ngIf="!(noData$ | async) && (actions$ | async) as actions; else noData">
  <top-changed-consumptions-table
    *ngIf="measured$ | async as data"
    [data]="data"
    [comparisonPeriodTitle]="comparisonPeriodTitle"
    [inspectionPeriodTitle]="inspectionPeriodTitle"
    [format]="(quantityId | groupedConsumptionWidgetNumberFormat : isRelationalValue : dataModelOptions.unitKey) | async"
    [actions]="actions"
    [changeType]="dataModelOptions.changeOption"
    (rowClick)="onRowClick($event)"
  ></top-changed-consumptions-table>
  <ng-container *ngIf="normalized$ | async as data">
    <h3>{{ 'FACILITIES.NORMALIZED' | translate }}:</h3>
    <top-changed-consumptions-table
      [data]="data"
      [comparisonPeriodTitle]="comparisonPeriodTitle"
      [inspectionPeriodTitle]="inspectionPeriodTitle"
      [format]="(quantityId | groupedConsumptionWidgetNumberFormat :isRelationalValue : dataModelOptions.unitKey) | async"
      [actions]="actions"
      [changeType]="dataModelOptions.changeOption"
      (rowClick)="onRowClick($event)"
    ></top-changed-consumptions-table>
  </ng-container>
</ng-container>
<ng-template #noData>
  <span>{{ 'WIDGET.NO_DATA' | translate }}</span>
</ng-template>
