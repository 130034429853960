import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmailResult } from '../email-send-modal/email-send-modal.component';

@Component({
  selector: 'email-summary',
  templateUrl: './email-summary.component.html',
  styleUrls: ['./email-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSummaryComponent {
  @Input() public results: EmailResult[];
}
