export enum ACTIONS {
  MODEL = 'Model',
  COPY = 'Copy',
  MANUAL = 'Manual',
  DEFAULT = 'Copy',
  CALCULATED = 'Calculated'
}

export enum MODEL_TYPE {
  UNIFORM = 'Uniform',
  ELECTRICITY_COMMON = 'ElectricityCommon'
}

export enum MODEL_TYPE_MODE {
  COMPARISON_PERIOD = 'ComparisonPeriod',
  CONSUMPTION_ESTIMATE = 'ConsumptionEstimate',
  STATISTICAL = 'Statistical'
}

export enum SAVE_TYPE {
  MODEL = 'Model',
  CONSUMPTION = 'Consumption'
}

export enum SAVE_OPTION {
  LOCK = 'Lock',
  NEXT = 'Next'
}
