/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { IAngularStatic, ITimeoutService } from 'angular';
import { finalize, switchMap } from 'rxjs/operators';
import moment from 'moment';

import { FacilityClient } from '@enerkey/clients/facility';
import {
  MeterCreateWithProperties,
  MeterManagementClient,
  MeterWithProperties,
} from '@enerkey/clients/meter-management';

import { ToasterType } from '../../../../constants/toaster-type';
import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import { MeterSpreadsheetField, MeterSpreadsheetFieldsService } from '../../services/meter-spreadsheet-fields-service';
import WizardStep from '../../../../components/wizard/wizard-step';
import { MeterSpreadsheetService } from '../../services/meter-spreadsheet-service';
import { oleToDate } from '../../../../shared/date.functions';

declare const angular: IAngularStatic;

enum MeterSpreadsheetColumns {
  METER_ID = 0,
  ENEGIA_ID = 1,
  METER_NAME = 2,
  METER_TYPE_ID = 3,
  QUANTITY_ID = 4,
  FACTOR = 5,
  USAGE_PLACE_NUMBER = 6,
  EAN_CODE = 7,
  PROTOCOL_CODE = 8,
  METER_IDENTIFIER = 9,
  AUTOMATIC_READING_START_TIME = 10,
  AUTOMATIC_READING_END_TIME = 11,
  DEACTIVATION = 12,
  QUALITY_ASSURANCE = 13,
  AUTOMATIC_MODELING = 14,
  DESCRIPTION = 15,
  TWO_TIME_MEASUREMENT = 16,
  COST_METER = 17,
  COST_FACTOR_SOURCE_METER_ID = 18,
  FIXED_COSTS = 19,
  ENERGY_COMPANY_USAGE_PLACE_NUMBER = 20,
  RESOLUTION = 21,
  TAGS = 22,
  STATUS = 23
}

type DataRow = [
  number,
  number,
  string,
  number,
  number,
  number,
  number,
  string,
  string,
  moment.Moment,
  string,
  string,
  string,
  string,
]

const inject = [
  '$element', '$timeout', '$window', 'KendoFunctions', 'MeterSpreadsheetService',
  'MeterSpreadsheetFieldsService', 'utils', 'MeterManagementClient', 'FacilityClient',
];

class MeterCreateController extends MassImportSpreadsheet<DataRow> {
  public currentStep: WizardStep;
  public firstStep: boolean;
  public spreadsheetElementId = 'spreadsheet';
  public inputLabel: string;
  public sheetInitialized = false;
  public onSpreadsheetCreate: any;
  public metersCreated: Function;
  public onClose: Function;
  public powerUserMode = false;
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  protected messages: SpreadsheetMessages;
  protected numberOfColumns: number;
  private fields: MeterSpreadsheetField[];
  private meterIdColumnIndex: number;
  private readonly rowAmount: number = 1;
  private readonly steps: WizardStep[];
  private isSpreadsheetInvalid: boolean = true;
  private initialized: boolean;
  private processingArray: number[];
  private resizeFn: (() => void) & _.Cancelable;
  private searchIds: number[];

  public constructor(
    protected $element: JQuery,
    protected $timeout: ITimeoutService,
    private readonly $window: any,
    private readonly KendoFunctions: any,
    private readonly meterSpreadsheetService: MeterSpreadsheetService,
    private readonly meterSpreadsheetFieldsService: MeterSpreadsheetFieldsService,
    private readonly utils: any,
    private readonly meterManagementClient: MeterManagementClient,
    private readonly facilityClient: FacilityClient
  ) {
    super();
    this.messages = this.getMessages();
    this.steps = this.getSteps();
  }

  public $onInit(): void {
    this.fields = this.meterSpreadsheetFieldsService.getFields();
    this.numberOfColumns = this.fields.length;
    this.meterIdColumnIndex = this.fields.findIndex((field: MeterSpreadsheetField) => field.value === 'id');
    this.inputLabel = this.utils.localizedString('ADMIN.SPREADSHEET.SETUP.ROW_AMOUNT_INPUT'),
    this.firstStep = true;
  }

  public override $onDestroy(): void {
    super.$onDestroy();
    angular.element(this.$window).off('resize', this.resizeFn);
  }

  public setPowerUserMode(): void {
    this.meterSpreadsheetService.setPowerUserMode(this.powerUserMode);
  }

  private getMessages(): SpreadsheetMessages {
    return {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE')
    };
  }

  private getSteps(): WizardStep[] {
    return [
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SETUP.TITLE'),
        isReturnable: true,
        clickFn: this.initializeSheet.bind(this),
        rollbackFn: this.enableFirstStep.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
        isReturnable: true,
        clickFn: this.doValidationForSheet.bind(this),
        buttonDisabledFn: this.isInitialized.bind(this),
        rollbackFn: this.enableSheet.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.VALIDATE'),
        isReturnable: false,
        clickFn: this.saveValues.bind(this),
        buttonDisabledFn: () => !this.isSavePossible(),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
        isReturnable: false,
        buttonDisabledFn: () => true,
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.READY'),
        clickFn: () => this.onClose(),
        customButtonText: this.utils.localizedString('WIDGET.CLOSE')

      }
    ];
  }

  private doValidationForSheet(): void {
    this.isSpreadsheetInvalid = !this.validateSheet();
    if (this.isSpreadsheetInvalid) {
      this.utils.popUp(
        ToasterType.ERROR,
        this.utils.localizedString('ADMIN.METERS.ERRORS.INVALID_SPREADSHEET')
      );
    }
  }

  private isSavePossible(): boolean {
    return !this.isSpreadsheetInvalid;
  }

  private isInitialized(): boolean {
    return !this.initialized;
  }

  private enableFirstStep(): void {
    this.firstStep = true;
  }

  private initializeSheet(): void {
    this.firstStep = false;
    this.meterSpreadsheetService.getSpreadsheetOptions(this.rowAmount + 1)
      .then(this.handleInitSuccess.bind(this));
  }

  private handleInitSuccess(result: kendo.ui.SpreadsheetOptions): void {
    this.spreadsheetOptions = result;
    this.sheetInitialized = true;
    this.totalRowCount = result.rows;
    this.spreadsheet = this.getSpreadsheetInstance();
    this.initialized = true;
    this.$timeout(() => {
      this.resizeKendo();
      this.onSpreadsheetCreate({ spreadsheet: this.spreadsheet });
      this.spreadsheet = null;
      this.lockRange(this.meterIdColumnIndex);
    });
  }

  private getMetersArray(dataRows: any, facilityIds: { [key: string]: number }): MeterCreateWithProperties[] {
    const metersArray: MeterCreateWithProperties[] = [];
    dataRows.forEach((dataRow: any, index: number) => {
      const facilityId = facilityIds[dataRow[MeterSpreadsheetColumns.ENEGIA_ID]];
      if (!facilityId) {
        return;
      }
      this.setProcessingStatus(index);
      this.processingArray.push(index);
      const meter = new MeterCreateWithProperties({
        name: dataRow[MeterSpreadsheetColumns.METER_NAME],
        facilityId: facilityId,
        meteringType: dataRow[MeterSpreadsheetColumns.METER_TYPE_ID],
        quantityId: dataRow[MeterSpreadsheetColumns.QUANTITY_ID],
        factor: dataRow[MeterSpreadsheetColumns.FACTOR],
        twoTimeMeasurement: dataRow[MeterSpreadsheetColumns.TWO_TIME_MEASUREMENT] ?? false,
        customerMeterIdentifier: dataRow[MeterSpreadsheetColumns.METER_IDENTIFIER],
        costMeter: dataRow[MeterSpreadsheetColumns.COST_METER] ?? false,
        costFactorSourceMeterId: dataRow[MeterSpreadsheetColumns.COST_FACTOR_SOURCE_METER_ID],
        linkMeterFixedCosts: dataRow[MeterSpreadsheetColumns.FIXED_COSTS] ?? false,
        qualityAssurance: dataRow[MeterSpreadsheetColumns.QUALITY_ASSURANCE] ?? true,
        automaticModeling: dataRow[MeterSpreadsheetColumns.AUTOMATIC_MODELING] ?? true,
        description: dataRow[MeterSpreadsheetColumns.DESCRIPTION],
        automaticReadingStartTime: oleToDate(dataRow[MeterSpreadsheetColumns.AUTOMATIC_READING_START_TIME]),
        automaticReadingEndTime: oleToDate(dataRow[MeterSpreadsheetColumns.AUTOMATIC_READING_END_TIME]),
        deactivationTime: oleToDate(dataRow[MeterSpreadsheetColumns.DEACTIVATION]),
        usagePlaceNumber: dataRow[MeterSpreadsheetColumns.USAGE_PLACE_NUMBER],
        energyCompanyUsagePlaceNumber: dataRow[MeterSpreadsheetColumns.ENERGY_COMPANY_USAGE_PLACE_NUMBER],
        eanCode: dataRow[MeterSpreadsheetColumns.EAN_CODE],
        protocolCode: dataRow[MeterSpreadsheetColumns.PROTOCOL_CODE],
        resolution: dataRow[MeterSpreadsheetColumns.RESOLUTION],
      });
      metersArray.push(meter);
    });
    return metersArray;
  }

  private saveValues(): void {
    if (!this.isSavePossible()) {
      this.currentStep = this.steps[2];
      this.utils.popUp(
        ToasterType.ERROR,
        this.utils.localizedString('ADMIN.METERS.ERRORS.INVALID_SPREADSHEET')
      );
      return;
    }
    this.processingArray = [];
    const dataRows = this.getDataRows().filter(row => row[1] !== null);
    const enegiaIds = dataRows.unique(row => row[1]);
    this.facilityClient.getFacilityIdsForEnegiaIds(enegiaIds).pipe(
      finalize(() => this.setFinalStep()),
      switchMap((facilityIds: { [key: string]: number }) => {
        const metersArray: MeterCreateWithProperties[] = this.getMetersArray(dataRows, facilityIds);
        return this.meterManagementClient.createMeters(metersArray);
      })
    ).subscribe(
      result => this.handleSaveSuccess(result),
      () => this.handleSaveError()
    );
  }

  private handleSaveSuccess(result: MeterWithProperties[]): void {
    this.steps[0].isReturnable = false;
    this.steps[1].isReturnable = false;
    this.searchIds = [];
    this.processingArray.forEach((id, index) => {
      this.setCellValue(index, this.meterIdColumnIndex, result[index].id.toString());
      this.setSuccessStatus(id);
      this.searchIds.push(result[index].id);
    });
    this.metersCreated({ meterIds: this.searchIds });
    this.utils.popUp(
      ToasterType.SUCCESS,
      this.utils.localizedString('ADMIN.METERS.SUCCESS.EDIT_SUCCESS')
    );
  }

  private handleSaveError(): void {
    this.processingArray.forEach(index => this.setErrorStatus(index));
    this.utils.popUpGeneralError('SAVE', 'METERS');
  }

  private setFinalStep(): void {
    this.currentStep = this.steps[this.steps.length - 1];
  }

  private resizeKendo(): void {
    this.spreadsheet = this.getSpreadsheetInstance();
    this.resizeFn = _.debounce(() => {
      this.KendoFunctions.resizeKendoComponent(this.spreadsheet, this.spreadSheetRowClass);
    }, 200);

    angular.element(this.$window).on('resize', this.resizeFn);

    this.KendoFunctions.resizeKendoComponent(this.spreadsheet, this.spreadSheetRowClass);
  }
}

MeterCreateController.$inject = inject;

export default MeterCreateController;
