import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

import { ServiceType } from '@enerkey/clients/user-management';

import { EkComboComponent } from '../ek-combo/ek-combo.component';
import { ServicesService } from '../../services/services.service';
import { getServiceGroup } from '../../../constants/service';

@Component({
  selector: 'service-select',
  templateUrl: '../ek-combo/ek-combo.component.html',
  styleUrls: ['../ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ServiceSelectComponent),
    multi: true
  }]
})
export class ServiceSelectComponent extends EkComboComponent<ServiceType> implements OnInit {
  public constructor(
    private readonly serviceService: ServicesService,
    private readonly translateService: TranslateService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.serviceService.getServices().subscribe(services => {
      this.items = services
        .map(option => {
          const groupKey = getServiceGroup(option.name);
          return {
            value: option.id,
            text: this.translateService.instant(`ADMIN.SERVICE_NAME_${option.name.toUpperCase()}`),
            group: this.translateService.instant(`ADMIN.SERVICE_GROUP_${groupKey}`),
            groupKey,
          };
        })
        .sortByMany(
          g => g.groupKey === 'OTHERS',
          g => g.group,
          g => g.text
        );
      this.groupedItems = groupBy(this.items, [{ field: 'group', dir: 'desc' }]) as GroupResult[];
    });
  }
}
