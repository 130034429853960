import template from 'raw-loader!./mass-add-persons-dialog.html';
import massAddPersonsController from './mass-add-persons-dialog.controller';

export default {
  template,
  controller: massAddPersonsController,
  controllerAs: 'vm',
  bindings: {
    close: '&'
  }
};
