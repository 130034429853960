import { hasChanges } from '@enerkey/angular-utils';

import { INLINE_INDICATOR_ROUND } from '../../../../components/inline-indicator/inline-indicator.constants';
import * as Colors from '../../constants/colors';
import DefectType from '../../shared/defect-type';
import * as TimeFrames from '../time-frame-radio/time-frame-radio-constant';

const $inject = ['utils'];

function ManualQaInspectSearchController(utils) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this;

  vm.constants = { ...Colors, ...TimeFrames };
  vm.indicatorType = INLINE_INDICATOR_ROUND;

  vm.$onInit = onInit;
  vm.$onChanges = onChanges;
  vm.getDefectNameForSelect = getDefectNameForSelect;
  vm.getMeterNameForSelect = getMeterNameForSelect;

  vm.selectNextFacility = selectNextFacility;
  vm.selectPreviousFacility = selectPreviousFacility;

  vm.uiStrings = {
    meterName: '',
    defectName: '',
    actualTimeFrame: '',
    operationTimeFrame: '',
    comparisonTimeFrame: '',
  };

  function onInit() {
    vm.actualTimeFrame = angular.copy(vm.actualTimeFrame);
    vm.operationTimeFrame = angular.copy(vm.operationTimeFrame);
    vm.comparisonTimeFrame = angular.copy(vm.comparisonTimeFrame);

    setMeterName();
    setDefectName();
  }

  function onChanges(changes) {
    if (hasChanges(changes, 'meter')) {
      setMeterName();
    }

    if (hasChanges(changes, 'defect')) {
      setDefectName();
    }

    if (
      hasChanges(changes, 'actualTimeFrame') ||
      hasChanges(changes, 'operationTimeFrame') ||
      hasChanges(changes, 'comparisonTimeFrame')
    ) {
      setTimeFrameStrings();
    }
  }

  function setMeterName() {
    vm.uiStrings.meterName = getMeterNameForSelect(vm.meter);
  }

  function setDefectName() {
    if (vm.defect) {
      vm.uiStrings.defectName = getDefectNameForSelect(vm.defect);
    }
  }

  function setTimeFrameStrings() {
    vm.uiStrings.actualTimeFrame = vm.actualTimeFrame.getAsString();
    vm.uiStrings.operationTimeFrame = vm.operationTimeFrame.getAsString();
    vm.uiStrings.comparisonTimeFrame = vm.comparisonTimeFrame.getAsString();
  }

  function selectNextFacility() {
    const meterIndex = vm.meters.findIndex(meter => meter === vm.meter);
    for (let i = meterIndex + 1; i < vm.meters.length; i++) {
      const currentMeter = vm.meters[i];
      if (vm.meter.facility.id !== currentMeter.facility.id) {
        vm.onChangeMeter(currentMeter);
        break;
      }
    }
  }

  function selectPreviousFacility() {
    const meterIndex = vm.meters.findIndex(meter => meter === vm.meter);
    for (let i = meterIndex - 1; i >= 0; i--) {
      const currentMeter = vm.meters[i];
      if (vm.meter.facility.id !== currentMeter.facility.id) {
        vm.onChangeMeter(currentMeter);
        break;
      }
    }
  }

  /**
   * Meter name formatter for select-paging component
   *
   * @param {Object} meter
   *
   * @returns {String}
   */
  function getMeterNameForSelect(meter) {
    if (!meter) {
      return '';
    }

    const id = meter.getId();
    const name = meter.getName();

    if (!id || !name) {
      return '';
    }

    return `${meter.getIndex()}. ${id} - ${name}`;
  }

  /**
   * Defect name formatter for select-paging component.
   *
   * @param {DefectIssue} defect
   *
   * @returns {String}
   */
  function getDefectNameForSelect(defect) {
    const defectNames = defect
      .getDefectTypes()
      .map(defectType => utils.localizedString(DefectType.getDefectTypeNameTag(defectType.getId())))
      .join(', ');

    return `${defect.getId()} - ${defectNames}`;
  }
}

ManualQaInspectSearchController.$inject = $inject;

export default ManualQaInspectSearchController;
