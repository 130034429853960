import { ParamDeclaration } from '@uirouter/core';
import { firstValueFrom } from 'rxjs';

import { EnerkeyNg2StateDeclaration, intArrayParamType } from '../../shared/routing';
import { ForecastReportComponent } from './components/forecast-report/forecast-report.component';
import { PeriodReportComponent } from './components/period-report/period-report.component';
import { ReportingBaseComponent } from './components/reporting-base/reporting-base.component';
import { SumReportComponent } from './components/sum-report/sum-report.component';
import { TableReportComponent } from './components/table-report/table-report.component';
import { TrendReportComponent } from './components/trend-report/trend-report.component';
import { reportingTopbarState } from './reporting-topbar.state';
import { ReportingSearchFormValue } from './shared/reporting-search-form-value';
import { YearlyTrendReportComponent } from '../../shared/energy-reporting-shared/components/yearly-trend-report/yearly-trend-report.component';
import { ThresholdService } from '../../shared/services/threshold.service';

type ReportingStateBaseName = 'reporting';
type ReportingStateChildName = 'table' | 'forecast' | 'trend' | 'period' | 'sum' | 'yearly-trend';

export type ReportingChildState = `${ReportingStateBaseName}.${ReportingStateChildName}`;

type ReportingStateName = ReportingStateBaseName | ReportingChildState;

export type ReportingStateDeclaration = EnerkeyNg2StateDeclaration<ReportingStateName>;

export interface ReportingParams extends Omit<ReportingSearchFormValue, 'periods'> {
  periods: string[],
  facilityIds: number[],
  sections: string[],
  grids: boolean,
  charts: boolean,
}

const reportingParams: Record<keyof ReportingParams, ParamDeclaration> = {
  facilityIds: { array: false, dynamic: true, type: intArrayParamType },
  quantityIds: { array: false, dynamic: true, type: intArrayParamType },
  periods: { array: true, dynamic: true, type: 'date' },
  resolution: { dynamic: true },
  durationName: { dynamic: true },
  durationLength: { dynamic: true, type: 'int' },
  change: { dynamic: true, type: 'json' },
  valueType: { dynamic: true },
  showConsumption: { dynamic: true, type: 'bool' },
  showSummedConsumption: { dynamic: true, type: 'bool' },
  specificIds: { array: false, dynamic: true, type: intArrayParamType },
  costIds: { array: false, dynamic: true, type: intArrayParamType },
  emissionIds: { array: false, dynamic: true, type: intArrayParamType },
  targetTypes: { array: true, dynamic: true },
  reportingUnit: { dynamic: true },
  distributionType: { dynamic: true, type: 'int' },
  sections: { array: true, dynamic: true },
  grids: { dynamic: true, type: 'bool' },
  charts: { dynamic: true, type: 'bool' },
  temperature: { dynamic: true, type: 'bool' },
  distributionAsPercent: { dynamic: true, type: 'bool' },
  comparability: { dynamic: true },
  minMaxAvg: { dynamic: true, type: 'json' },
};

const urlParams = Object.keys(reportingParams).join('&');

const reportingBaseState: ReportingStateDeclaration = {
  ...reportingTopbarState,
  url: `/reporting?${urlParams}`,
  parent: 'index',
  redirectTo: 'reporting.table',
  views: { 'app@': { component: ReportingBaseComponent } },
  params: reportingParams,
  resolve: [
    {
      token: 'calendarMode',
      deps: [ThresholdService],
      resolveFn: (thresholdService: ThresholdService) => firstValueFrom(thresholdService.calendarMode$),
    },
  ]
};

const tableReportState: ReportingStateDeclaration = {
  name: 'reporting.table',
  views: {
    report: {
      component: TableReportComponent
    }
  },
  url: '/table',
  data: {
    translationKey: 'FACILITIES.TREELIST'
  },
};

const forecastReportState: ReportingStateDeclaration = {
  name: 'reporting.forecast',
  views: {
    report: {
      component: ForecastReportComponent
    }
  },
  url: '/forecast',
  data: {
    translationKey: 'FACILITIES.FORECAST'
  },
};

const periodReportState: ReportingStateDeclaration = {
  name: 'reporting.period',
  views: {
    report: {
      component: PeriodReportComponent
    }
  },
  url: '/period',
  data: {
    translationKey: 'FACILITIES.REPORT'
  },
};

const trendReportState: ReportingStateDeclaration = {
  name: 'reporting.trend',
  views: {
    report: {
      component: TrendReportComponent
    }
  },
  url: '/trend',
  data: {
    translationKey: 'FACILITIES.TREND'
  },
};

const sumReportState: ReportingStateDeclaration = {
  name: 'reporting.sum',
  views: {
    report: {
      component: SumReportComponent
    }
  },
  url: '/sum',
  data: {
    translationKey: 'FACILITIES.SUM'
  },
};

const yearlyTrendReportState: ReportingStateDeclaration = {
  name: 'reporting.yearly-trend',
  views: {
    report: {
      component: YearlyTrendReportComponent
    }
  },
  url: '/yearly',
  data: {
    translationKey: 'YEARLY_TREND.TAB_TITLE'
  },
};

export const reportingStates = [
  reportingBaseState,
  tableReportState,
  periodReportState,
  sumReportState,
  trendReportState,
  forecastReportState,
  yearlyTrendReportState,
];
