import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@enerkey/foundation-angular';
import { ServiceScopeType } from '@enerkey/clients/contract';

import { Facility } from '@enerkey/clients/facility';

import FacilityModalTemplate
  from 'raw-loader!../../../../modules/admin/components/admin-facility/admin-facility-modal.html';
import FacilityPropertiesModalTemplate
  from 'raw-loader!../../../../modules/admin/components/admin-spreadsheet/admin-spreadsheet-modal.html';

import AdminModalController from '../../../../modules/admin/controllers/modals/admin-modal-controller';
import { UserService } from '../../../../services/user-service';
import { Roles } from '../../../../modules/admin/constants/roles';
import { AjsModalService } from '../../../../services/modal/modal.service';
import { PropertyModalOptions } from '../../../../modules/admin/components/facility-management/facility-management.component';
import { StateLocationService } from '../../../../services/state-location.service';
import { FacilityProfilesModalComponent } from '../../../../modules/admin/components/facility-profiles-modal/facility-profiles-modal.component';
import { FacilityCompaniesModalComponent } from '../../../../modules/admin/components/facility-companies-modal/facility-companies-modal.component';
import { ContractCreateModalComponent } from '../../../../modules/contracts/components/contract-create-modal/contract-create-modal.component';
import { MassAddSubscriptionModalComponent } from '../../../../modules/admin/components/mass-add-subscription-modal/mass-add-subscription-modal.component';

import { CompanyMassEditModelComponent as CompanyMassEditModelComponent } from '../company-mass-edit-model/company-mass-edit-model.component';

enum AdditionalRole {
  OTHER_ACTIONS = 'OtherActions'
}

@Component({
  selector: 'facility-action-popup',
  templateUrl: './facility-action-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityActionPopupComponent implements AfterViewInit {
  @Input() public allFacilities: Facility[];
  @Input() public selectedFacilities: { id: number, name: string }[];
  @Input() public requestedRoles: (Roles | AdditionalRole)[] =
    [Roles.CONTACT_MANAGER, Roles.FACILITY_IMPORT, Roles.CONTRACT_MANAGER, AdditionalRole.OTHER_ACTIONS];

  @Output() public readonly repeatSearch = new EventEmitter<void>();

  public isMassImportRole: boolean;
  public isContactManager: boolean;
  public isContractManager: boolean;
  public isOtherActionsAllowed: boolean;

  public constructor(
    private readonly angularjsModalService: AjsModalService,
    private readonly translateService: TranslateService,
    private readonly stateLocationService: StateLocationService,
    private readonly modalService: ModalService,
    private readonly userService: UserService
  ) { }

  public get selectedFacilityIds(): number[] {
    return this.selectedFacilities?.map(facilities => facilities.id) ?? [];
  }

  public ngAfterViewInit(): void {
    this.isContactManager = this.isAllowed(Roles.CONTACT_MANAGER);
    this.isMassImportRole = this.isAllowed(Roles.FACILITY_IMPORT);
    this.isContractManager = this.isAllowed(Roles.CONTRACT_MANAGER);
    this.isOtherActionsAllowed = this.isAllowed(AdditionalRole.OTHER_ACTIONS);
  }

  public isAllowed(role: Roles | AdditionalRole): boolean {
    if (role === AdditionalRole.OTHER_ACTIONS) {
      return this.requestedRoles.includes(role);
    }
    return this.userService.hasRole(role) && this.requestedRoles.includes(role);
  }

  public openFacilityInformation(): void {
    this.angularjsModalService.open({
      template: FacilityModalTemplate,
      controller: AdminModalController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'reveal--full-height',
      resolve: {
        facilityIds: () => this.selectedFacilityIds,
        includeQuantityId: () => { },
        includeCustomType: () => { },
        title: () => this.translateService.instant('ADMIN.MASS_IMPORT.FACILITY')
      }
    });
  }

  public openFacilityProperties(): void {
    this.openFacilityPropertiesModal({
      title: 'ADMIN.MASS_IMPORT.FACILITY_PROPERTY',
      facilityIds: this.selectedFacilityIds,
      includeQuantityId: false,
      includeCustomType: false
    });
  }

  public openCO2Factors(): void {
    this.openFacilityPropertiesModal({
      title: 'ADMIN.MASS_IMPORT.FACILITY_CO2',
      facilityIds: this.selectedFacilityIds,
      includeQuantityId: true,
      includeCustomType: false
    });
  }

  public openShareOfTempFixing(): void {
    this.openFacilityPropertiesModal({
      title: 'ADMIN.MASS_IMPORT_SHAREOFTEMPERATUREFIXING',
      facilityIds: this.selectedFacilityIds,
      includeQuantityId: false,
      includeCustomType: true
    });
  }

  public inspectMeters(): void {
    this.stateLocationService.openStateInNewTab('meter', {
      facilityIds: this.selectedFacilityIds
    });
  }

  public modifyFacilitiesFromProfiles(): void {
    const modalRef = this.modalService.open(FacilityProfilesModalComponent);
    modalRef.componentInstance.facilityIds = this.selectedFacilityIds;

    from(modalRef.result).subscribe({
      next: () => this.repeatSearch.emit()
    });
  }

  public addFacilitiesForCompany(): void {
    const modalRef = this.modalService.open(FacilityCompaniesModalComponent);
    modalRef.componentInstance.facilityIds = this.selectedFacilityIds;
  }

  public addContractsForFacilities(): void {
    const modalRef = this.modalService.open(ContractCreateModalComponent, { size: 'large' });
    modalRef.componentInstance.setFacilities(this.selectedFacilityIds);
  }

  public addFacilitiesForSubscription(): void {
    const modalRef = this.modalService.open(MassAddSubscriptionModalComponent);
    modalRef.componentInstance.selectedItems = this.selectedFacilities;
    modalRef.componentInstance.scope = ServiceScopeType.Facility;
  }
  public updateCompanies(): void {
    const modalRef = this.modalService.open(CompanyMassEditModelComponent);
    modalRef.componentInstance.selectedFacilities = this.allFacilities.filter(
      f => this.selectedFacilityIds.includes(f.id)
    );
    from(modalRef.result).subscribe({
      next: () => this.repeatSearch.emit()
    });
  }
  private openFacilityPropertiesModal(options: PropertyModalOptions): Promise<unknown> {
    return this.angularjsModalService.open({
      template: FacilityPropertiesModalTemplate,
      controller: AdminModalController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'reveal--full-height',
      resolve: {
        facilityIds: () => options.facilityIds,
        includeQuantityId: () => options.includeQuantityId,
        includeCustomType: () => options.includeCustomType,
        title: () => this.translateService.instant(options.title)
      }
    }).result;
  }
}
