import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable, of } from 'rxjs';

import { switchMap } from 'rxjs/operators';

import {
  Bucket,
  BucketContact,
  BucketUpdate,
  ConfigurationControlClient
} from '@enerkey/clients/configuration-control';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { formGroupFrom } from '@enerkey/ts-utils';

import { ToasterService } from '../../../shared/services/toaster.service';
import { isValidCron } from '../../../shared/alarms-shared/shared/is-valid-cron';

interface BucketEdit {
  groupIdentifier?: string,
  isActive?: boolean,
  terminalErrorExecutionCount?: number,
  cronExpression?: string,

}

interface BucketContactEdit {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  emailForMissingReading: string,
}

function cronValidator(control: AbstractControl): { invalidCron: boolean } {
  return isValidCron(control.value) ? null : { invalidCron: true };
}

function bucketContactValidator(control: AbstractControl): { missingContact: boolean } {
  return isContactInformationFilled(control.value) ? null : { missingContact: true };
}

function isContactInformationFilled(value: BucketContactEdit): boolean {
  if (value.firstName ||
    value.lastName ||
    value.email ||
    value.phoneNumber ||
    value.emailForMissingReading
  ) {
    return true;
  } else {
    return false;
  }
}

@Component({
  selector: 'edit-bucket-modal',
  templateUrl: './edit-bucket-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EditBucketModalComponent extends ModalBase<void> implements OnInit {
  public readonly bucketForm: UntypedFormGroup;
  public readonly contactForm: UntypedFormGroup;

  public readerTypeName: string;
  public bucket: Bucket;

  public constructor(
    ngfActiveModal: NgfActiveModal,
    private readonly toasterService: ToasterService,
    private readonly configurationControlClient: ConfigurationControlClient
  ) {
    super(ngfActiveModal);

    this.bucketForm = formGroupFrom<BucketEdit>(
      {
        groupIdentifier: null,
        isActive: null,
        terminalErrorExecutionCount: null,
        cronExpression: null,
      },
      {
        groupIdentifier: [Validators.required, Validators.minLength(5)],
        cronExpression: cronValidator,
      }
    );

    this.contactForm = formGroupFrom<BucketContactEdit>(
      {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        phoneNumber: undefined,
        emailForMissingReading: undefined,
      }
    );
  }

  public ngOnInit(): void {
    this.bucketForm.patchValue({
      groupIdentifier: this.bucket.groupIdentifier,
      isActive: this.bucket.isActive,
      terminalErrorExecutionCount: this.bucket.terminalErrorExecutionCount,
      cronExpression: this.bucket.cronExpression,
    });

    if (this.bucket.bucketContactId) {
      this.getBucketContact();
    }
  }

  public getBucketContact(): void {
    this.configurationControlClient.getBucketContact(this.bucket.bucketContactId)
      .subscribe({
        next: (bucketContact: BucketContact) => {
          const contactValue: BucketContactEdit = {
            firstName: bucketContact.firstName,
            lastName: bucketContact.lastName,
            email: bucketContact.email,
            phoneNumber: bucketContact.phoneNumber,
            emailForMissingReading: bucketContact.emailForMissingReading,
          };
          this.contactForm.setValidators(bucketContactValidator);
          this.contactForm.patchValue(contactValue);
        },
      });
  }

  public save(): void {
    const contactSaveRequest = this.saveBucketContact();
    contactSaveRequest.pipe(
      switchMap(bucketContact => this.saveBucket(bucketContact))
    ).subscribe({
      next: () => {
        this.toasterService.info('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_SAVED');
        super.closeModal();
      },
      error: () => {
        this.toasterService.error('CONFIGURATION_CONTROL.ERROR_TEXT_POST_CONFIGURATION_FAIL');
      }
    });
  }

  public cancel(): void {
    super.dismissModal();
  }

  private saveBucketContact(): Observable<BucketContact> {
    if (!isContactInformationFilled(this.contactFormValue)) {
      return of({ id: this.bucket.bucketContactId ?? null });
    }
    const bucketContact: BucketContact = {
      firstName: this.contactFormValue.firstName,
      lastName: this.contactFormValue.lastName,
      phoneNumber: this.contactFormValue.phoneNumber,
      email: this.contactFormValue.email,
      emailForMissingReading: this.contactFormValue.emailForMissingReading,
      id: this.bucket.bucketContactId
    };
    const request = !this.bucket.bucketContactId ?
      this.configurationControlClient.createBucketContact(bucketContact) :
      this.configurationControlClient.updateBucketContact(bucketContact);
    return request;
  }

  private saveBucket(bucketContact: BucketContact): Observable<void> {
    const bucketUpdate: BucketUpdate = {
      isActive: this.bucketFormValue.isActive,
      cronExpression: this.bucketFormValue.cronExpression,
      groupIdentifier: this.bucketFormValue.groupIdentifier,
      bucketContactId: bucketContact.id,
      terminalErrorExecutionCount: this.bucketFormValue.terminalErrorExecutionCount
    };
    return this.configurationControlClient.updateBucket(this.bucket.id, bucketUpdate);
  }

  private get bucketFormValue(): BucketEdit {
    return this.bucketForm.value;
  }

  private get contactFormValue(): BucketContactEdit {
    return this.contactForm.value;
  }
}
