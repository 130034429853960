/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IAngularStatic } from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { concat, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AllHtmlEntities } from 'html-entities';

import {
  BatchExecuteParams, Bucket, Configuration, ConfigurationControlClient, ReadingStatus
} from '@enerkey/clients/configuration-control';
import { ofVoid } from '@enerkey/rxjs';
import { $InjectArgs } from '@enerkey/ts-utils';

import { PropertyService } from '../services/property-service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { TerminalService } from '../../../shared/services/terminal.service';
import { AjsModalService } from '../../../services/modal/modal.service';
import { QuantityService } from '../../../shared/services/quantity.service';
import { ColorService } from '../../../shared/services/color.service';

declare const angular: IAngularStatic;
declare const kendo: any;

const htmlEntities = new AllHtmlEntities();

export class TerminalName {
  public constructor(public id: number, public name: string) {
  }
}

export class ConfigurationsController {
  public static $inject: $InjectArgs<typeof ConfigurationsController> = [
    '$scope', '$window', 'ConfigurationControlClient', 'PropertyService',
    'utils', 'MeterInterfaceService',
    'ConfigurationControlModalService', 'TerminalService',
    'ToasterService', 'modalService', 'quantities', 'ColorService',
    '$modalInstance', 'bucketId', 'readerTypeId', 'terminalName', 'readerTypeName'
  ];

  public schema: any = {};
  public connectionString: string;
  public cronExpression: string;
  public groupIdentifier: string;
  public terminals: TerminalName[] = [];
  public hasHistory: boolean = false;
  public dateFrom: string = moment().startOf('day').subtract(1, 'weeks').format();
  public dateTo: string = moment().startOf('hour').subtract(1, 'hours').format();
  public pickerOptions: any = {
    format: 'yyyy-MM-dd HH:mm',
    timeFormat: 'HH:mm',
    interval: 60
  };
  public selectionCount: number = 0;
  public hideSuccesStatus: boolean = false;
  public allSelected: boolean = false;
  public readingStatusLog: string[] = [];
  public readingDates: string[] = [];
  public successDates: string[] = [];
  public failureDates: string[] = [];
  public successRate: string = '';
  public bucket: any = null;
  public isActive: boolean = false;
  public isActivatedText: string = '';
  public gridOptions: any = {};
  private groupingOrder: string[] = [];

  public constructor(
    private $scope: any,
    private $window: any,
    private configurationControlClient: ConfigurationControlClient,
    private propertyService: PropertyService,
    private utils: any,
    private meterInterfaceService: any,
    private configurationControlModalService: any,
    private terminalService: TerminalService,
    private toasterService: ToasterService,
    private modalService: AjsModalService,
    private quantityService: QuantityService,
    private colorService: ColorService,
    private readonly modalInstance: any,
    public readonly bucketId: number,
    public readonly readerTypeId: number,
    public readonly terminalName: string,
    public readonly readerTypeName: string
  ) {
    this.initialize();
  }

  public showTerminalStatus(configuration: any): void {
    this.terminalService.getTerminalStatusModal(
      configuration.terminalName
    );
  }

  public showMeterInterfaceStatus(configuration: any): void {
    this.meterInterfaceService.getModal(
      configuration.facilityName,
      configuration.meterId,
      configuration.meterName
    );
  }

  public createDirectLink(configuration: any): void {
    if (configuration.connectionString.indexOf('ftp://') > -1) {
      this.configurationControlClient.getPassword(configuration.id)
        .subscribe(
          {
            next: (response: any) => {
              const credentials = `${configuration.userName}:${response}`;
              const folder = configuration.connectionString.substring(6);
              this.$window.open(`ftp://${credentials}@${folder}`, '_blank');
            },
            error: () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_PASSWORD_FAIL')
          }
        );
    }
  }

  public onSelectAllChanged(): void {
    const grid = angular.element('#configurationsGrid').data('kendoGrid');
    const filteredData = new kendo.data.Query(grid.dataSource.data()).filter(grid.dataSource.filter()).data;
    _.each(grid.dataSource.data(), dataItem => {
      // Do not select outside filtered items. Filtered data contains all items if there is no any filter set
      if (this.allSelected && filteredData.some((filteredItem: any) => filteredItem.id === dataItem.id)) {
        dataItem.selected = true;
        this.selectionCount += 1;
        // There might be selected item outside filters
      } else if (dataItem.selected) {
        dataItem.selected = false;
        this.selectionCount -= 1;
      } else {
        dataItem.selected = false;
      }
    });
  }

  public onDataItemSelectedChanged(dataItem: any): void {
    if (dataItem.selected) {
      this.selectionCount++;
    } else {
      this.selectionCount--;
    }
  }

  public onHideSuccesStatusChanged(): void {
    if (this.hideSuccesStatus) {
      this.gridOptions.dataSource.filter({ field: 'readingStatus', operator: 'neq', value: ReadingStatus.Success });
    } else {
      this.gridOptions.dataSource.filter({});
    }
  }

  public refresh(): void {
    const filtering = this.gridOptions.dataSource.filter();
    const grouping = this.gridOptions.dataSource.group();
    const sorting = this.gridOptions.dataSource.sort();
    this.initializeGrid();
    this.getBucketAndConfigurations();
    if (angular.isDefined(filtering)) {
      this.gridOptions.dataSource.filter(filtering);
    }
    if (angular.isDefined(grouping)) {
      this.gridOptions.dataSource.group(grouping);
    }
    if (angular.isDefined(sorting)) {
      this.gridOptions.dataSource.sort(sorting);
    }
  }

  public refreshGrid(): void {
    this.refresh();
  }

  public updateBucket(): void {
    const modalInstance = this.configurationControlModalService.updateBucket('', this.bucket);
    modalInstance.result.then((result: any) => {
      if (result?.saved) {
        this.cronExpression = result.bucket.cronExpression;
        this.groupIdentifier = result.bucket.groupIdentifier;
        this.isActive = result.bucket.isActive;
        this.refresh();
      }
    });
  }

  public editBucketDocumentation(): void {
    this.configurationControlModalService.getBucketDocumentationEditModal(this.bucketId, this.groupIdentifier);
  }

  public addConfiguration(): void {
    const newDataItem = this.gridOptions.dataSource.data()[0];
    newDataItem.terminalId = 0;
    newDataItem.terminalName = '';
    this.createCopyConfiguration(newDataItem);
  }

  public copyConfiguration(dataItem: any): void {
    this.createCopyConfiguration(dataItem);
  }

  public createCopyConfiguration(dataItem: any): void {
    const copyItem = angular.copy(dataItem);
    this.configurationControlClient.getPassword(dataItem.id)
      .subscribe(
        {
          next: (response: any) => {
            copyItem.id = 0;
            copyItem.meterId = null;
            copyItem.password = response;
            this.addCopyConfiguration(copyItem);
          },
          error: () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_PASSWORD_FAIL')
        }
      );
  }

  public addCopyConfiguration(copyItem: any): void {
    const {
      baseProperties, specificProperties
    } = this.propertyService.extractBaseAndSpecificPropertyObjects(this.schema, copyItem);
    const modalInstance = this.configurationControlModalService
      .addInheritedConfiguration(this.readerTypeId, this.terminals, baseProperties, specificProperties);
    modalInstance.result.then((result: any) => {
      if (result?.saved) {
        this.refresh();
      }
    });
  }

  public importConfigurations(): void {
    const modalInstance = this.configurationControlModalService.importConfigurations(
      this.readerTypeId, this.groupIdentifier, this.cronExpression
    );
    modalInstance.result.then(() => this.refresh());
  }

  public addToTerminal(): void {
    const selectedConfigurations = this.getSelectedConfigurations();

    if (selectedConfigurations.some(configuration => configuration.terminalId > 0)) {
      this.showError('ADMIN.TERMINAL_ALREADY_EXISTS.ERROR');
      return;
    }
    const enegiaIds = selectedConfigurations.map(configuration => configuration.enegiaId);
    const meterIds = selectedConfigurations.map(configuration => configuration.meterId);
    this.terminalService.getAddMetersToTerminalModal(enegiaIds, meterIds);
  }

  public showPassword(configurationId: number): void {
    this.configurationControlModalService.showPassword(configurationId);
  }

  public runConfigurations(): void {
    const selectedConfigurationIds = this.getSelectedConfigurations().map(c => c.id);
    const executeParams: BatchExecuteParams = {
      dateFrom: this.hasHistory ? moment(this.dateFrom).utc().toDate() : null,
      dateTo: this.hasHistory ? moment(this.dateTo).utc().toDate() : null,
      configurationIdList: selectedConfigurationIds
    };
    this.configurationControlClient.executePatch(this.bucketId, executeParams)
      .subscribe(
        {
          next: () => this.showInfo('CONFIGURATION_CONTROL.INFO_TEXT_TASK_SCHEDULED'),
          error: () => this.showError(this.utils.localizedString('CONFIGURATION_CONTROL.ERROR_TEXT_EXECUTE_FAIL'))
        }
      );
  }

  public isRunnable(): boolean {
    return (this.selectionCount > 0 && this.dateFrom !== null && this.dateTo !== null);
  }

  public isDataInitialized(): boolean {
    const grid = angular.element('#configurationsGrid').data('kendoGrid');
    return (angular.isDefined(grid) && grid.dataSource.total() > 0);
  }

  public getSelectedConfigurations(): Configuration[] {
    const selectedConfiguration: Configuration[] = [];
    const grid = angular.element('#configurationsGrid').data('kendoGrid');
    _.each(grid.dataSource.data(), dataItem => {
      if (dataItem.selected) {
        selectedConfiguration.push(dataItem);
      }
    });
    return selectedConfiguration;
  }

  public removeConfiguration(event: any): void {
    event.preventDefault();
    const grid = angular.element('#configurationsGrid').data('kendoGrid');
    const row = angular.element(event.currentTarget).closest('tr');
    const dataItem = grid.dataItem(row);
    const configurationId = dataItem.get('id');
    const terminalId = dataItem.get('terminalId');

    this.confirmConfigurationRemove()
      .then(() => {
        if (terminalId) {
          this.confirmTerminalRemove()
            .then(() => this.removeSingleConfiguration(configurationId, true))
            .catch(() => this.removeSingleConfiguration(configurationId, false));
        } else {
          this.removeSingleConfiguration(configurationId, false);
        }
      })
      .catch(
        () => {}
      );
  }

  public removeSelectedConfigurations(): void {
    const configurations = this.getSelectedConfigurations();
    const hasTerminals = configurations.some(configuration => configuration.terminalId !== null);

    this.confirmConfigurationRemove(configurations.length)
      .then(() => {
        const ids = configurations.map(configuration => configuration.id);
        if (hasTerminals) {
          this.confirmTerminalRemove()
            .then(() => this.removeMultipleConfigurations(ids, true))
            .catch(() => this.removeMultipleConfigurations(ids, false));
        } else {
          this.removeMultipleConfigurations(ids, false);
        }
      })
      .catch(
        () => {}
      );
  }

  public close(): void {
    this.modalInstance.close();
  }

  private removeSingleConfiguration(id: number, removeFromTerminal: boolean): void {
    this.configurationControlClient.removeConfiguration(id, removeFromTerminal)
      .subscribe(
        {
          next: () => this.showInfo('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_DELETED'),
          error: () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_DELETE_CONFIGURATION_FAIL'),
          complete: () => this.refresh()
        }
      );
  }

  private removeMultipleConfigurations(ids: number[], removeFromTerminal: boolean): void {
    const failedIds: number[] = [];
    const requests = ids.map(id =>
      this.configurationControlClient.removeConfiguration(id, removeFromTerminal).pipe(
        catchError(() => {
          failedIds.push(id);
          return ofVoid();
        })
      ));

    concat(...requests).subscribe(
      {
        complete: () => {
          if (failedIds.length === 0) {
            this.showInfo('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_DELETED');
            this.refresh();
          } else {
            this.showError(
              this.utils.localizedString(
                'CONFIGURATION_CONTROL.MASS_REMOVE_FAIL',
                { n: failedIds.length }
              )
            );
          }
        }
      }
    );
  }

  private initialize(): void {
    const schemaRequest = this.configurationControlClient.getSchema(this.readerTypeId);
    schemaRequest.subscribe(
      {
        next: schemaResponse => {
          this.handleSchema(schemaResponse);
          this.initializeGrid();
          this.getBucketAndConfigurations();
          this.setTerminalFilter();
        },
        error: () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_SCHEMA_FAIL')
      }
    );
  }

  private handleSchema(schemaResponse: any): void {
    this.schema = schemaResponse;
    this.hasHistory = this.schema.hasHistory;
  }

  private handleBucket(bucketResponse: Bucket): void {
    this.bucket = bucketResponse;
    this.isActivatedText = (this.bucket.isActive) ?
      this.utils.localizedString('CONFIGURATION_CONTROL.ACTIVE') :
      this.utils.localizedString('CONFIGURATION_CONTROL.INACTIVE');
    this.groupIdentifier = this.bucket.groupIdentifier;
    this.cronExpression = this.bucket.cronExpression;
  }

  private handleConfigurations(configurationResponse: Configuration[]): void {
    try {
      const extractedData = configurationResponse.reduce((result: any, value: any) => {
        result.push(_.merge({}, _.omit(value, 'jsonConfiguration'), JSON.parse(value.jsonConfiguration)));
        result.forEach((item: any) => {
          item.readingStatusLocalized = this.meterInterfaceService.getReadingStatusText(item.readingStatus);
          item.selected = false;
        });
        return result;
      }, []);
      this.listTerminalNames(extractedData);
      this.gridOptions.dataSource.data(extractedData);
    } catch {
      // No proper json there, just ignore additional properties
      this.gridOptions.dataSource.data(configurationResponse);
      this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_EXTRA_SETTINGS_FAIL');
    }
  }

  private getBucketAndConfigurations(): void {
    const bucketRequest = this.configurationControlClient.getBucket(this.bucketId);
    const configurationRequest = this.configurationControlClient.getConfigurationsForBucketId(this.bucketId);
    forkJoin([bucketRequest, configurationRequest]).subscribe(
      {
        next: responses => {
          this.handleBucket(responses[0]);
          this.handleConfigurations(responses[1]);
        },
        error: () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_DATA_FAIL')
      }
    );
  }

  private setTerminalFilter(): void {
    const terminalFilter = this.terminalName;
    if (terminalFilter) {
      this.gridOptions.dataSource.filter({ field: 'terminalName', operator: 'eq', value: terminalFilter });
    }
  }

  private initializeGrid(): void {
    // Base properties.
    // These columns are similar for all of the reader types
    const columns = [
      {
        field: 'selected',
        title: ' ',
        width: 30,
        headerTemplate: `<input id="select-all" type="checkbox" ng-model="vm.allSelected"
          ng-change="vm.onSelectAllChanged()" />`,
        template: `<input type="checkbox" class="checkbox" ng-model="dataItem.selected"
          ng-change="vm.onDataItemSelectedChanged(this.dataItem)" />`,
        filterable: false,
        sortable: false,
        groupable: false,
        locked: true,
        attributes: {
          class: 'cell-center'
        }
      },
      {
        field: 'id',
        width: 0,
        hidden: true,
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.ID'),
        filterable: false,
        locked: true
      },
      {
        field: 'meterId',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.METER_ID'),
        width: 60,
        type: 'number',
        filterable: {
          extra: false,
          operators: {
            string: {
              eq: this.utils.localizedString('GRID.EQ')
            }
          }
        },
        footerTemplate: this.utils.localizedString('CONFIGURATION_CONTROL.TOTAL')
      },
      {
        field: 'meterName',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.METER_NAME'),
        width: 250,
        filterable: {
          multi: true,
          search: true
        },
        footerTemplate: '#= count #',
        locked: true
      },
      {
        field: '',
        title: ' ',
        width: 30,
        headerTemplate: ' ',
        filterable: false,
        sortable: false,
        groupable: false,
        attributes: {
          class: 'table-cell',
          style: 'text-align: center;'
        },
        command: {
          id: 'edit',
          name: 'edit',
          text: '',
          fillMode: 'flat',
          size: 'small',
          className: 'ek-link-button',
          iconClass: 'fa-1x fas fa-pencil-alt',
        },
        locked: true
      },
      {
        field: 'externalMeterId',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.EXT_METER_ID'),
        width: 60,
        filterable: {
          multi: true,
          search: true
        }
      },
      {
        field: 'quantityId',
        title: this.utils.localizedString('QUANTITY'),
        width: 40,
        filterable: {
          multi: true,
          search: true
        },
        template: (data: any) =>
          `<span>${this.quantityService.getQuantityLocalizedName(data.quantityId)}</span>`
      },
      {
        field: 'isMeterHierarchyMeter',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.FLOATING'),
        width: 40,
        attributes: { class: 'cell-center' },
        template: (row: any) => row.isMeterHierarchyMeter
          ? '<i name="isMeterHierarchyMeter"></i>'
          : '<i name="isMeterHierarchyMeter" class="fa fa-check"></i>',
        filterable: {
          messages: {
            isTrue: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS.METERHIERARCHY_METERS'),
            isFalse: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS.FLOATING_METERS')
          }
        }
      },
      {
        field: 'enegiaId',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.ENEGIA_ID'),
        width: 60,
        filterable: {
          extra: false,
          operators: {
            string: {
              eq: this.utils.localizedString('GRID.EQ')
            }
          }
        }
      },
      {
        field: 'facilityName',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.FACILITY_NAME'),
        width: 110,
        filterable: {
          multi: true,
          search: true
        },
        template: (data: any) => {
          const facilityName = htmlEntities.encode(data.facilityName);
          return `<span ng-non-bindable title="${facilityName}">${facilityName}</span>`;
        }
      },
      {
        field: 'readingStatus',
        width: 0,
        hidden: true,
        template: (data: any) => `<div class="readingStatusHidden">${data.readingStatus}</div>`
      },
      {
        field: 'readingStatusLocalized',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.STATUS'),
        width: 80,
        filterable: {
          multi: true
        },
        template: (data: any) => {
          const cssIndicator =
            `indicator indicator--${this.meterInterfaceService.getReadingStatusIndicator(data.readingStatus)}-text`;
          const template = _.template(
            `<button
              name='readingStatusButton'
              class='<%= classes %>'
              ng-click='vm.showMeterInterfaceStatus(this.dataItem)'>
              <%= status %>
            </button>`
          )({
            classes: `readingStatusButton button button--link ${cssIndicator}`,
            status: data.readingStatusLocalized,
          });
          return template;
        },
        footerTemplate: '<div ng-model="vm.successRate">{{ ::vm.successRate }}</div>'
      },
      {
        field: 'successRate',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.SUCCESSRATE'),
        width: 65,
        template: '<span name="successRate">#=kendo.format("{0:p0}", successRate)#</span>',
        footerTemplate: '#= kendo.format("{0:p0}", average) #',
      },
      {
        field: 'terminalName',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.METERGATEWAY'),
        width: 30,
        groupHeaderTemplate: '#= value == null ? "-" : value #',
        filterable: {
          multi: true,
          search: true
        },
        template: (data: Configuration) => data.terminalName
          ?
          `<button
            name='terminalName'
            class='button button--link'
            ng-click='vm.showTerminalStatus(this.dataItem)'>
            <span ng-non-bindable title="${htmlEntities.encode(data.terminalName)}">T</span>
          </button>`
          : ''
      },
      {
        field: 'qaLink',
        title: ' ',
        width: 40,
        headerTemplate: '',
        template: '<manual-qa-inspect-button meter-ids="this.dataItem.meterId"></manual-qa-inspect-button>',
        filterable: false,
        sortable: false,
        groupable: false,
        attributes: {
          class: 'table-cell',
          style: 'text-align: center;'
        }
      },
      {
        field: 'automaticReadingStartTime',
        title: this.utils.localizedString('AUTOMATIC_READING_START_TIME'),
        width: 105,
        filterable: { extra: true },
        template: (data: any) => `<span
          name='automaticReadingStartTime'>${this.formatTimestamp(data.automaticReadingStartTime)}</span>`
      },
      {
        field: 'automaticReadingEndTime',
        title: this.utils.localizedString('AUTOMATIC_READING_END_TIME'),
        width: 44,
        filterable: { extra: true },
        template: (data: any) => `<span
          name='automaticReadingEndTime'>${this.formatTimestamp(data.automaticReadingEndTime)}</span>`
      },
      {
        field: 'deactivationTime',
        title: this.utils.localizedString('DEACTIVATION_TIME'),
        width: 44,
        filterable: { extra: true },
        template: (data: any) => `<span
          name='deactivationTime'>${this.formatTimestamp(data.deactivationTime)}</span>`
      },
      {
        field: 'hoursBackWards',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.HOURSBACK'),
        width: 60,
        filterable: {
          multi: true,
          search: true
        }
      },
      {
        field: 'connectionString',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.CONNECTIONSTRING'),
        width: 120,
        filterable: {
          multi: true,
          search: true
        },
        template: (data: Configuration) => {
          const connectionString = htmlEntities.encode(data.connectionString);
          if (connectionString.indexOf('ftp://') > -1) {
            return `
<button
  kendo-button
  k-options="{ fillMode: 'link', size: 'small' }"
  id="connectionString${data.id}"
  title="${connectionString}"
  ng-click="vm.createDirectLink(this.dataItem)"
  style="color: var(--enerkey-link)"
>
  <span ng-non-bindable>${connectionString}</span>
</button>`;
          }
          if (connectionString.indexOf('http://') > -1 || connectionString.indexOf('https://') > -1) {
            return _.template(
              `<a href="<%= link %>" title="${connectionString}" target=blank><%= link %> </a>`
            )({ link: connectionString });
          } else {
            return `<span ng-non-bindable>${connectionString}</span>`;
          }
        }
      },
      {
        field: 'userName',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.USERNAME'),
        width: 90,
        filterable: {
          multi: true,
          search: true
        },
        template: (data: Configuration) => {
          const userName = htmlEntities.encode(data.userName);
          return `<span ng-non-bindable title="${userName}">${userName}</span>`;
        }
      },
      {
        field: 'password',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.PASSWORD'),
        width: 65,
        filterable: false,
        template: (data: Configuration) => {
          if (!data.password) {
            return '';
          } else {
            return `<button
  kendo-button
  k-options="{ size: 'small', fillMode: 'link' }"
  id="password${data.id}"
  ng-click="vm.showPassword(this.dataItem.id)"
  style="font-size: 11px; color: var(--enerkey-link)"
>* * * * *</button>`;
          }
        }
      },
      {
        field: 'comment',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.COMMENT'),
        width: 150,
        filterable: {
          multi: true,
          search: true
        },
        template: (data: Configuration) => {
          const comment = htmlEntities.encode(data.comment);
          return `<span ng-non-bindable title="${comment}">${comment}</span>`;
        }
      },
      {
        field: 'allowNegative',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.ALLOWNEGATIVE'),
        width: 60,
        attributes: { class: 'cell-center' },
        template: (row: any) => row.allowNegative
          ? '<i name="allowNegative" class="fa fa-check"></i>'
          : '<i name="allowNegative"></i>',
        filterable: true
      },
      {
        field: 'allowZero',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.ALLOWZERO'),
        width: 60,
        attributes: { class: 'cell-center' },
        template: (row: any) => row.allowZero
          ? '<i name="allowZero" class="fa fa-check"></i>'
          : '<i name="allowZero"></i>',
        filterable: true
      },
      {
        field: 'copy',
        title: ' ',
        width: 35,
        headerTemplate: ' ',
        template: `<button class="button button--link button--no-focus icon"
          ng-click="vm.copyConfiguration(this.dataItem)"><i class="fa fa-copy"></i></button>`,
        filterable: false,
        sortable: false,
        groupable: false,
        attributes: {
          class: 'table-cell',
          style: 'text-align: center;'
        }
      },
      {
        field: '',
        title: ' ',
        width: 80,
        headerTemplate: ' ',
        filterable: false,
        sortable: false,
        groupable: false,
        attributes: {
          class: 'table-cell',
          style: 'text-align: center;'
        },
        command: {
          text: this.utils.localizedString('CONFIGURATION_CONTROL.GRID_TEXT.DELETE_CONFIGURATION'),
          size: 'small',
          className: 'ek-remove-button',
          iconClass: null as null,
          click: (data: Configuration) => this.removeConfiguration(data)
        }
      }
    ];

    // Reader specific properties. These can vary between the different reader types
    // Insert specific properties after the grid action buttons
    let propertyIndex = columns.length;
    const readerSpecificProperties = this.propertyService.getReaderSpecificProperties(this.schema);
    readerSpecificProperties.forEach((property: any) => {

      columns.splice(propertyIndex++, 0, {
        field: property.name,
        title: this.utils.localizedString(property.translation),
        width: 100,
        filterable: {
          multi: true,
          search: true
        },
      });
    });

    this.gridOptions = {
      toolbar: ['excel'],
      sortable: true,
      resizable: true,
      groupable: true,
      pageable: false,
      columns: columns,
      editable: 'popup',
      filterable: true,
      edit: (e: kendo.ui.GridEditEvent) => {
        const kendoWindow = e.container.data('kendoWindow');
        kendoWindow.setOptions({
          ...kendoWindow.options,
          maxHeight: '95vh'
        });
        kendoWindow.center();
        kendoWindow.wrapper.addClass('cc-kendowindow');

        const elementsToHide = [
          'input[name="selected"]',
          'label[for="readingStatus"]',
          '.readingStatusHidden',
          'label[for="isMeterHierarchyMeter"]',
          'i[name="isMeterHierarchyMeter"]',
          'label[for="automaticReadingStartTime"]',
          'span[name="automaticReadingStartTime"]',
          'label[for="automaticReadingEndTime"]',
          'span[name="automaticReadingEndTime"]',
          'label[for="deactivationTime"]',
          'span[name="deactivationTime"]',
          'button[name="readingStatusButton"]',
          'label[for="readingStatusLocalized"]',
          'button[name="terminalName"]',
          'label[for="terminalName"]',
          'input[name=qaLink]',
          'label[for="qaLink"]',
          'label[for="successRate"]',
          'span[name="successRate"]',
          'input[name="copy"]',
          'label[for="copy"]',
        ];

        for (const elementToHide of elementsToHide) {
          const elem = e.container.find(elementToHide);
          elem.hide();
          elem.parent('.k-edit-label, .k-edit-field').hide();
        }

        // Get password from backend
        this.configurationControlClient.getPassword(e.model.id)
          .subscribe(
            {
              next: (response: string) => {
                e.model.set('password', response);
              },
              error: () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_PASSWORD_FAIL')
            }
          );
      },
      dataSource: new kendo.data.DataSource({
        group: [{
          field: 'facilityName'
        }, {
          field: 'terminalName'
        }],
        transport: {
          read: function() { },
          update: (e: any) => {
            const {
              baseProperties, specificProperties
            } = this.propertyService.extractBaseAndSpecificPropertyObjects(this.schema, e.data);
            const configurationUpdate: any = {};
            angular.forEach(baseProperties, (value: any) => {
              configurationUpdate[value.name] = value.value;
            });

            const specificConfig: any = {};
            angular.forEach(specificProperties, (value: any) => {
              specificConfig[value.name] = value.value;
            });
            configurationUpdate['jsonConfiguration'] = angular.toJson(specificConfig);

            const configId = e.data.id;
            this.configurationControlClient.updateConfiguration(configId, configurationUpdate)
              .subscribe(
                {
                  next: () => {
                    this.showInfo('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_SAVED');
                    e.success();
                  },
                  error: () => {
                    this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_POST_CONFIGURATION_FAIL');
                    e.error();
                  }
                }
              );
          }
        },
        schema: {
          model: this.generateModel(readerSpecificProperties)
        },
        aggregate: [
          { field: 'meterName', aggregate: 'count' },
          { field: 'successRate', aggregate: 'average' }
        ],
        error: () => this.$scope.configurationsGrid.cancelChanges()
      }),
      dataBound: (event: kendo.ui.GridDataBoundEvent) => {
        this.calculateAggregates();
        this.setGroupingStyle(event);
      }
    };
  }

  private setGroupingStyle(event: kendo.ui.GridDataBoundEvent): void {
    const grid = angular.element('#configurationsGrid').data('kendoGrid');
    if (grid.dataSource.group()) {
      const groupedView = grid.dataSource.view().map(o => o);
      this.groupingOrder = [];

      for (const group of groupedView) {
        this.setGroupingOrder(group);
      }
      this.setGroupingColors(event);
    }
  }

  private setGroupingOrder(group: any): void {
    this.groupingOrder.push(`${group.field}-${group.value}`);

    if (group.hasSubgroups) {
      for (const subGroup of group.items) {
        this.setGroupingOrder(subGroup);
      }
    }
  }

  private setGroupingColors(event: kendo.ui.GridDataBoundEvent): void {
    const facilityColor = this.colorService.getCssProperty('--enerkey-light-grey', undefined);
    const terminalColor = this.colorService.getCssProperty('--enerkey-extra-light-grey', undefined);
    const terminalMissingColor = this.colorService.shadeColor(
      this.colorService.getCssProperty('--enerkey-primary', undefined), 30
    );

    const groupingHeaderRows = event.sender.wrapper
      .find('.k-grid-content-locked > table > tbody > .k-grouping-row');
    const groupingDataRows = event.sender.wrapper
      .find('.k-grid-content > table > tbody > .k-grouping-row');

    let index = 0;
    for (const groupingHeader of groupingHeaderRows) {
      if (!this.groupingOrder[index]) {
        continue;
      }
      const grouping = this.groupingOrder[index].split('-');
      if (grouping?.[0] === 'facilityName') {
        this.setRowColor(groupingHeader, groupingDataRows[index], facilityColor);
      }
      if (grouping?.[0] === 'terminalName' && grouping[1].length > 0) {
        this.setRowColor(groupingHeader, groupingDataRows[index], terminalColor);
      }
      if (grouping?.[0] === 'terminalName' && grouping[1].length === 0) {
        this.setRowColor(groupingHeader, groupingDataRows[index], terminalMissingColor);
      }
      index++;
    }
  }

  private setRowColor(
    groupingHeaderElement: HTMLElement,
    groupingDataElement: HTMLElement,
    color: string
  ): void {
    angular.element(groupingHeaderElement)
      .children('td:not(.k-group-cell)')
      .css('backgroundColor', color);
    angular.element(groupingDataElement)
      .children('td')
      .css('backgroundColor', color);
  }

  private generateModel(readerSpecificProperties: any[]): any {
    const model = {
      id: 'id',
      fields: {}
    };

    const fields: any = {
      checked: { type: 'boolean', editable: false },
      id: { editable: false },
      meterId: { editable: false, nullable: false },
      meterName: { editable: false },
      isMeterHierarchyMeter: { type: 'boolean', editable: false },
      enegiaId: { editable: false, nullable: false },
      facilityName: { editable: false },
      terminalName: { editable: false },
      readingStatus: { editable: false },
      readingStatusLocalized: { type: 'text', editable: false },
      successRate: { editable: false, hideOnEdit: true },
      hoursBackWards: {
        type: 'number', editable: true, nullable: false,
        validation: { required: true, min: 0 }
      },
      automaticReadingStartTime: { editable: false, type: 'date' },
      automaticReadingEndTime: { editable: false, type: 'date' },
      deactivationTime: { editable: false, type: 'date' },
      allowNegative: { type: 'boolean', editable: true },
      allowZero: { type: 'boolean', editable: true },
    };

    // Define schema model types for specific properties dynamically
    readerSpecificProperties.forEach((property: any) => {
      if (property.type === 'integer') {
        fields[property.name] = {
          type: 'number'
        };
      }
      if (property.type === 'boolean') {
        fields[property.name] = {
          type: 'boolean'
        };
      }
    });

    model.fields = fields;

    return model;
  }

  private calculateAggregates(): void {
    const grid = angular.element('#configurationsGrid').data('kendoGrid');
    let successCount = 0;
    _.each(grid.dataSource.data(), dataItem => {
      if (dataItem.readingStatus === ReadingStatus.Success) {
        successCount++;
      }
    });
    this.successRate = kendo.format('{0:p0}', successCount / grid.dataSource.total());
  }

  private listTerminalNames(data: any[]): void {
    const configurationsWithTerminals = data.filter(c => c.terminalId !== null && c.terminalName !== null);
    this.terminals = configurationsWithTerminals
      .map((dataItem: any) =>
        new TerminalName(dataItem.terminalId, dataItem.terminalName))
      .sortBy(t => t.name.toLowerCase())
      .uniqueBy('id');
  }

  private showInfo(text: string): void {
    this.toasterService.info(text);
  }

  private showError(text: string): void {
    this.toasterService.error(text);
  }

  private formatTimestamp(timestamp: string): string {
    return timestamp ? kendo.toString(kendo.parseDate(timestamp), 'dd.MM.yyyy') : '';
  }

  private confirmConfigurationRemove(count?: number): Promise<unknown> {
    if (count) {
      return this.modalService.getConfirmationModal({
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIRM_MASS_REMOVE'),
        text: this.utils.localizedString('CONFIGURATION_CONTROL.MASS_REMOVE_PROMPT', { n: count }),
        isDelete: true
      });
    } else {
      return this.modalService.getConfirmationModal({
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIRM_REMOVE'),
        text: this.utils.localizedString('CONFIGURATION_CONTROL.REMOVE_CONFIGURATION_PROMPT'),
        isDelete: true
      });
    }
  }

  private confirmTerminalRemove(): Promise<unknown> {
    return this.modalService.getConfirmationModal({
      title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIRM_REMOVE'),
      text: this.utils.localizedString('CONFIGURATION_CONTROL.TERMINAL_REMOVE_PROMPT'),
      isDelete: true
    });
  }
}

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
/* eslint-enable @typescript-eslint/no-explicit-any */

export default ConfigurationsController;
