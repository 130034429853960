import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FacilityPropertyEnums } from '@enerkey/clients/facility';
import { getEnumEntries } from '@enerkey/ts-utils';

@Pipe({ name: 'facilityPropertyName' })
export class FacilityPropertyNamePipe implements PipeTransform {

  public constructor(
    private readonly translateService: TranslateService
  ) { }

  public transform(value: FacilityPropertyEnums): string {
    const entry = getEnumEntries(FacilityPropertyEnums)
      .find(([_, enumValue]) => value === enumValue);

    return entry
      ? this.translateService.instant(`PROPERTIES.${entry[0].toUpperCase()}`)
      : '-';
  }

}
