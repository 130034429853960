import _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

timeSeries.$inject = [
  '$rootScope', '$q', '$http',
  '$timeout', 'UserService', 'thresholdService',
  'utils', 'httpConfigService', 'languageChangeService'
];

function timeSeries(
  $rootScope, $q, $http,
  $timeout, UserService, thresholdService,
  utils, httpConfigService, languageChangeService
) {

  let timeSeriesDeferreds = {};
  const timeSeriesTypes = [
    {
      id: 0,
    },
    {
      id: 1,
    }
  ];
  let selectedTimeSeriesTypeId;

  // watch for language change start
  languageChangeService.languageChangeStart.subscribe(() => {
    timeSeriesTypes.forEach(type => {
      if (timeSeriesDeferreds[type.id]) {
        timeSeriesDeferreds[type.id].resolve([]);
      }
    });
    timeSeriesDeferreds = {};
  });

  const getTimeSeries = function(typeId) {
    typeId = _.isNumber(typeId) ? typeId : selectedTimeSeriesTypeId;
    if (!timeSeriesDeferreds[typeId]) {
      timeSeriesDeferreds[typeId] = $q.defer();

      UserService.isInitializedWithInitialProfileAsync()
        .then(() => {
          const httpConfig = httpConfigService.getExtendedHttpConfig();
          httpConfig.params.type = typeId;
          $http.get(`${ENERKEY_CONFIG.apiEnergyreporting }/api/v1/configuration/timeseries`, httpConfig)
            .then(({ data }) => {
              if (timeSeriesDeferreds[typeId]) {
                timeSeriesDeferreds[typeId].resolve(data.Timeseries);
              }
            })
            .catch(({ status }) => {
              if (timeSeriesDeferreds[typeId]) {
                timeSeriesDeferreds[typeId].reject(status);
                $timeout(() => {
                  timeSeriesDeferreds[typeId] = undefined;
                });
              }
            })
          ;
        })
        .catch(() => {
          if (timeSeriesDeferreds[typeId]) {
            timeSeriesDeferreds[typeId].reject();
          }
        });

    }
    return timeSeriesDeferreds[typeId].promise;
  };

  const getDefaultFromToEndDate = function() {
    const now = new Date();
    let to = new Date(now.getFullYear() + 1, 0, 1);
    if (selectedTimeSeriesTypeId === 1) {
      to = new Date(now.getFullYear(), now.getMonth());
    }
    return to;
  };

  /**
   *
   * @param noComparison
   * @param typeId - for overriding settings of calendar or rolling value ids, which are 0 and 1 respectively
   */
  const getDefaultTimeSeries = function(noComparison, typeId) {
    const deferred = $q.defer();

    getTimeSeries(typeId).then(
      // success callback
      result => {
        const condition = noComparison ? { Abbr: '' } : { Default: true };
        let timeFrameConf = _.findWhere(result, condition);
        if (!timeFrameConf) {
          timeFrameConf = result[0];
        }
        if (timeFrameConf && timeFrameConf.Resolutions) {
          let resolutionConf = _.findWhere(timeFrameConf.Resolutions, { Default: true });
          if (!resolutionConf) {
            resolutionConf = timeFrameConf.Resolutions[0];
          }

          let defaultStart; let timeFrame;
          const defaultStartList = (resolutionConf.DefaultStart || timeFrameConf.DefaultStart);
          if (_.isArray(defaultStartList) && defaultStartList.length) {
            if (noComparison) {
              const utcStartDate = defaultStartList[0];
              const from = utils.utcDateToDate(utcStartDate);
              timeFrame = utils.isoDuration(from, getDefaultFromToEndDate(), resolutionConf.Abbr);
              defaultStart = [{
                key: utils.durationToString(from, timeFrame),
                value: utcStartDate
              }];
            } else {
              timeFrame = timeFrameConf.Abbr;
              defaultStart = _.map(defaultStartList, utcStartDate => ({
                key: utils.durationToString(utils.utcDateToDate(utcStartDate), timeFrame),
                value: utcStartDate
              }));
            }
          }
          if (resolutionConf && defaultStart) {
            deferred.resolve({
              TimeFrame: timeFrame,
              Resolution: resolutionConf.Abbr,
              Start: defaultStart
            });
          } else {
            deferred.reject();
          }
        } else {
          deferred.reject();
        }
      },
      () => {
        // error callback
        console.log('Error happened...');
        deferred.reject();
      }
    );

    return deferred.promise;
  };

  const getSupportedTimeFrames = function(typeId) {
    const deferred = $q.defer();

    getTimeSeries(typeId).then(
      // success callback
      result => {
        deferred.resolve(_.pluck(result, 'Abbr'));
      },
      // error callback
      () => {
        console.log('Error while getting supported timeframes...');
        deferred.reject();
      }
    );

    return deferred.promise;
  };

  const getTimeSeriesTypeId = function() {
    const promise = firstValueFrom(thresholdService.calendarMode$.pipe(take(1)));
    promise
      .then(result => {
        selectedTimeSeriesTypeId = result;
        if (!selectedTimeSeriesTypeId) {
          selectedTimeSeriesTypeId = timeSeriesTypes[0].id;
        }
      })
      .catch(() => {
        // utils.popUpGeneralError('LOAD', 'TIME_SERIES');
      })
    ;
    return promise;
  };

  const initialized = function() {
    const deferred = $q.defer();
    const promises = [];

    // get default time series type
    const promise = getTimeSeriesTypeId();
    promises.push(promise);

    timeSeriesTypes.forEach(type => {
      promises.push(getTimeSeries(type.id));
    });
    $q.all(promises).finally(() => { deferred.resolve(); });

    return deferred.promise;
  };

  return {
    getTimeSeries: getTimeSeries,
    getDefaultTimeSeries: getDefaultTimeSeries,
    getSupportedTimeFrames: getSupportedTimeFrames,
    getDefaultFromToEndDate: getDefaultFromToEndDate,
    initialized: initialized
  };
}

export default timeSeries;
