<kendo-dropdownlist
  [data]="data.length ? data : groupedData"
  [(ngModel)]="value"
  (ngModelChange)="valueChange($event)"
  [valuePrimitive]="true"
  [disabled]="disabled"
  [filterable]="true"
  [textField]="'value'"
  [valueField]="'value'"
  (filterChange)="handleFilter($event)"
></kendo-dropdownlist>