import ManualQaSearchListButtonsController from './manual-qa-search-list-buttons.controller';
import template from 'raw-loader!./manual-qa-search-list-buttons.html';

const ManualQaSearchListButtonsComponent = {
  template: template,
  controller: ManualQaSearchListButtonsController,
  controllerAs: 'vm',
  bindings: {
    grid: '<',
    searchList: '<',
    isDisabled: '<',
    onInspect: '&',
    onEmailSend: '&',
    onSearchListChange: '&',
    onEditSearchList: '&'
  }
};

export default ManualQaSearchListButtonsComponent;
