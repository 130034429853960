<modal-view-header headingText="{{ 'ADMIN.CONTRACTS.EDIT_MULTIPLE' | translate }}">
</modal-view-header>

<div
  [ngSwitch]="editedField"
  class="modal-body"
  (keydown.enter)="submit()"
>

  <ng-container *ngSwitchCase="'contractId'">
    <label>{{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}</label>
    <input
      [formControl]="control"
      type="text"
    >
  </ng-container>

  <ng-container *ngSwitchCase="'productId'">
    <label>{{ 'ADMIN.CONTRACTS.PRODUCT_ID' | translate }}</label>
    <product-input [formControl]="control"></product-input>
  </ng-container>

  <ng-container *ngSwitchCase="'unitCount'">
    <label>{{ 'ADMIN.CONTRACTS.UNIT_COUNT' | translate }}</label>
    <kendo-numerictextbox
      [formControl]="control"
      [decimals]="0"
      [format]="'0'"
      [step]="numberInputOpts.step"
      [autoCorrect]="numberInputOpts.autoCorrect"
      [spinners]="numberInputOpts.spinners"
      autofocus
    ></kendo-numerictextbox>
  </ng-container>

  <ng-container *ngSwitchCase="'unitPrice'">
    <label>{{ 'ADMIN.CONTRACTS.UNIT_PRICE' | translate }}</label>
    <kendo-numerictextbox
      [formControl]="control"
      [decimals]="numberInputOpts.decimals"
      [format]="numberInputOpts.format"
      [step]="numberInputOpts.step"
      [autoCorrect]="numberInputOpts.autoCorrect"
      [spinners]="numberInputOpts.spinners"
    ></kendo-numerictextbox>
  </ng-container>

  <ng-container *ngSwitchCase="'fromDate'">
    <label>{{ 'ADMIN.CONTRACTS.FROM_DATE' | translate }}</label>
    <kendo-datepicker [formControl]="control"></kendo-datepicker>
  </ng-container>

  <ng-container *ngSwitchCase="'toDate'">
    <label>{{ 'ADMIN.CONTRACTS.FROM_DATE' | translate }}</label>
    <kendo-datepicker [formControl]="control"></kendo-datepicker>
  </ng-container>

</div>

<div class="modal-footer">
  <div class="row float-right">
    <button
      class="button button--primary"
      type="button"
      (click)="submit()"
    >
      {{ 'ADMIN.EDIT' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="close()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
