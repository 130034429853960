import * as datefns from 'date-fns';

export enum ISODuration {
  /** Year */
  P1Y = 'P1Y',

  /** Quarter (3 months) */
  P3M = 'P3M',

  /** Month */
  P1M = 'P1M',

  /** Week (7 days) */
  P7D = 'P7D',

  /** Day */
  P1D = 'P1D',

  /** Hour */
  PT1H = 'PT1H',
}

export const isoDurationTranslations: Readonly<Record<ISODuration, string>> = {
  [ISODuration.P1Y]: 'TIMESPAN.YEAR',
  [ISODuration.P3M]: 'TIMESPAN.QUARTER',
  [ISODuration.P1M]: 'TIMESPAN.MONTH',
  [ISODuration.P7D]: 'TIMESPAN.WEEK',
  [ISODuration.P1D]: 'TIMESPAN.DAY',
  [ISODuration.PT1H]: 'TIMESPAN.HOUR',
};

type AddFn = (date: Date, increment: number) => Date;
type ClampFn = (date: Date) => Date;
type CompareFn = (later: Date, earlier: Date) => number;

const addFns: Record<ISODuration, AddFn> = {
  [ISODuration.P1Y]: datefns.addYears,
  [ISODuration.P3M]: datefns.addQuarters,
  [ISODuration.P1M]: datefns.addMonths,
  [ISODuration.P7D]: datefns.addWeeks,
  [ISODuration.P1D]: datefns.addDays,
  [ISODuration.PT1H]: datefns.addHours,
};

const startFns: Record<ISODuration, ClampFn> = {
  [ISODuration.P1Y]: datefns.startOfYear,
  [ISODuration.P3M]: datefns.startOfQuarter,
  [ISODuration.P1M]: datefns.startOfMonth,
  [ISODuration.P7D]: datefns.startOfWeek,
  [ISODuration.P1D]: datefns.startOfDay,
  [ISODuration.PT1H]: datefns.startOfHour,
};

const endFns: Record<ISODuration, ClampFn> = {
  [ISODuration.P1Y]: datefns.endOfYear,
  [ISODuration.P3M]: datefns.endOfQuarter,
  [ISODuration.P1M]: datefns.endOfMonth,
  [ISODuration.P7D]: datefns.endOfWeek,
  [ISODuration.P1D]: datefns.endOfDay,
  [ISODuration.PT1H]: datefns.endOfHour,
};

const compareFns: Record<ISODuration, CompareFn> = {
  [ISODuration.P1Y]: datefns.differenceInYears,
  [ISODuration.P3M]: datefns.differenceInQuarters,
  [ISODuration.P1M]: datefns.differenceInMonths,
  [ISODuration.P7D]: datefns.differenceInWeeks,
  [ISODuration.P1D]: datefns.differenceInDays,
  [ISODuration.PT1H]: datefns.differenceInHours,
};

class ISODurationsStatic {
  public static compare(a: ISODuration, b: ISODuration): number {
    return numeric(a) - numeric(b);
  }

  public static add(duration: ISODuration, date: Date, count?: number): Date {
    return addFns[duration]?.(date, count ?? 1) ?? null;
  }

  public static startOf(duration: ISODuration, date: Date): Date {
    return startFns[duration]?.(date) ?? null;
  }

  public static endOf(duration: ISODuration, date: Date): Date {
    return endFns[duration]?.(date) ?? null;
  }

  public static difference(duration: ISODuration, first: Date, second: Date): number {
    return compareFns[duration]?.(second, first) ?? null;
  }
}

type DurationStatic = {
  /** Compares length of durations */
  compare(a: ISODuration, b: ISODuration): number;

  /** Adds or subtracts (+1 default) of the duration to the date */
  add(duration: ISODuration, date: Date, count?: number): Date;

  /** Returns start of specified interval on the date */
  startOf(duration: ISODuration, date: Date): Date;

  /** Returns end of specified interval on the date */
  endOf(duration: ISODuration, date: Date): Date;

  /** Returns how many duration units `second` is after ` first` */
  difference(duration: ISODuration, first: Date, second: Date): number;
}

// Exporting like this strips out unnecessary functions and properties present in class ctor
/** Functions for handling `ISODuration` enums.  */
export const ISODurations: DurationStatic = ISODurationsStatic;

function numeric(duration: ISODuration): number {
  switch (duration) {
    case ISODuration.P1Y: return 6;
    case ISODuration.P3M: return 5;
    case ISODuration.P1M: return 4;
    case ISODuration.P7D: return 3;
    case ISODuration.P1D: return 2;
    case ISODuration.PT1H: return 1;
    default: return 0;
  }
}
