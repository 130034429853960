import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const contactManagerTabState: UIStateNg2 = {
  name: 'contact',
  data: {
    auth: {
      roles: [Roles.CONTACT_MANAGER],
    },
    translationKey: 'ADMIN.TABS.CONTACTS',
    export: {
      enabled: true
    }
  }
};
