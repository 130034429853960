<table>
  <thead>
    <tr>
      <th>{{ propertyTranslationKey | translate }}</th>
      <th></th>
      <th>{{ comparisonPeriodTitle }}</th>
      <th>{{ inspectionPeriodTitle }}</th>
      <th>{{ 'CHANGE.TITLE' | translate }}</th>
    </tr>
  </thead>
  <ng-container
    *ngFor="let rows of dataArray"
  >
    <tbody *ngIf="rows?.length > 0">
      <tr *ngFor="let row of rows">
        <td>
          <a [title]="row.name" (click)="rowClick.emit(row.facilitiesIncluded)">{{ row.name }}</a>
        </td>
        <td class="widget-facility-count">{{ row | groupedConsumptionsFacilityAmount }}</td>
        <td>{{ row.comparisonPeriod | kendoNumber:format }}</td>
        <td>{{ row.inspectionPeriod | kendoNumber:format }}</td>
        <td *ngIf="changeType === WidgetChangeOption.Relative">
          <span
            [ekValueChange]="row.relativeChange"
            [ngfTooltip]="row.absoluteChange | kendoNumber:'#,#'"
          ></span>
        </td>
        <td *ngIf="changeType === WidgetChangeOption.Absolute">
          <span
            [ekValueChange]="row.absoluteChange"
            [isAbsoluteChange]="true"
            [ngfTooltip]="row.relativeChange | kendoNumber:'#.# %'"
          ></span>
        </td>
      </tr>
    </tbody>
  </ng-container>
</table>
