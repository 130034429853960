import { Component } from '@angular/core';

import { PackageVersion } from '../package-version';
import { TenantService } from '../services/tenant.service';

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})

export class SiteFooterComponent {
  public readonly version: string;
  public readonly footerText: string;
  public readonly hideEnerkeyInFooter: boolean;

  public constructor(private readonly tenantService: TenantService) {
    this.footerText = this.getFooterText();
    this.hideEnerkeyInFooter = !tenantService.isEnerkeyBrand;
    this.version = PackageVersion.getVersion();
  }

  private getFooterText(): string {
    if (!this.tenantService.isEnerkeyBrand) {
      return '';
    }
    const footerText = this.tenantService.isCustomTenant
      ? 'Powered by \u00a9 EnerKey'
      : '\u00a9 EnerKey';

    return `${footerText}, `;
  }
}
