import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { QuantityService } from '../../../shared/services/quantity.service';
import { ReportingGridColumnGroup } from '../services/reporting-grid.service';

@Pipe({ name: 'reportingGridColumnGroupTitle' })
export class ReportingGridColumnGroupTitlePipe implements PipeTransform {

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly translateService: TranslateService
  ) {
  }

  public transform(column: ReportingGridColumnGroup): string {
    const columnName = column.quantityId !== null
      ? this.quantityService.getQuantityNameForId(column.quantityId)
      : this.translateService.instant('FACILITIES.SIDEBAR.TEMPERATURE');

    const suffix = column.isNormalized
      ? ` ${this.translateService.instant('FACILITIES.NORMALIZED')}`
      : '';

    return `${columnName} [${column.unit}]${suffix}`;
  }
}
