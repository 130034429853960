import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe } from '@progress/kendo-angular-intl';

@Pipe({
  name: 'consumptionPercent'
})
export class ConsumptionPercentPipe implements PipeTransform {
  public constructor(private kendoNumberPipe: NumberPipe) {
  }

  /**
   * @param divider if percent value is already in decimal format, use 1 as divider
   */
  public transform(percentValue: number, divider = 100): string {
    if (!Number.isFinite(percentValue)) {
      return '-';
    }

    const asDecimal = percentValue / divider;

    if (asDecimal > 10) {
      return '>+'.concat(this.kendoNumberPipe.transform(10, '#,# %'));
    } else if (asDecimal < -10) {
      return '<'.concat(this.kendoNumberPipe.transform(-10, '#,# %'));
    }

    const prefix = asDecimal > 0 ? '+' : '';

    const amountOfDecimals = asDecimal > -0.1 && asDecimal < 0.1
      ? 2
      : 0
    ;

    let decimalFormat = '0'.repeat(amountOfDecimals);
    decimalFormat = decimalFormat ? `.${decimalFormat}` : decimalFormat;

    return prefix.concat(this.kendoNumberPipe.transform(asDecimal, `#,#${decimalFormat} %`));
  }
}
