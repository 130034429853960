import downloadCommandTemplate from 'ngtemplate-loader!raw-loader!../../templates/download-command.html';

const $inject = ['$scope', 'BookmarkService'];

class ModalButtonsController {
  constructor($scope, BookmarkService) {
    this.$scope = $scope;
    this.$scope.downloadCommandTemplate = downloadCommandTemplate;
    this.BookmarkService = BookmarkService;
  }

  $onInit() {
    this.options = this.options || {};
    // $scope can probably be removed after someone refactors DownloadController
    this.handleModalChange();
    this.$scope.downloadParams = this.download;
    this.$scope.help = this.help;
  }

  $onChanges() {
    this.handleModalChange();
    this.$scope.downloadParams = this.download;
    this.$scope.help = this.help;
  }

  handleModalChange() {
    if (this.bookmark?.enableAdd) {
      const bookmark = this.bookmark.getBookmark();
      this.BookmarkService.handleModalChange({
        modal: true,
        name: bookmark.modal,
        stateParams: bookmark.stateParams
      });
    } else {
      this.BookmarkService.handleModalChange({
        modal: false
      });
    }
  }
}

ModalButtonsController.$inject = $inject;

export default ModalButtonsController;
