import { ChangeDetectionStrategy, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import { catchError, EMPTY, Observable, startWith, Subject, switchMap } from 'rxjs';

import { UntypedFormControl } from '@angular/forms';

import { AlarmClient, LogDto, LogSearchCriteriaDto } from '@enerkey/clients/alarm';
import { ModalService } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { UserService } from '../../../../services/user-service';
import { AlarmDetailsModalComponent } from '../../../../shared/alarms-shared/components/alarm-details-modal/alarm-details-modal.component';
import { ALARMS_LOG_GRID_REFRESH } from '../../../alarms-log/shared/alarms-log-grid-refresh';
import { DashboardStateService } from '../../services/dashboard-state.service';
import { WidgetBase } from '../../shared/widget-base.interface';

export type FacilityLatestAlarmsWidgetOptions = {
  numberToShow: number;
  facilityId: number
}
@Component({
  selector: 'facility-latest-alarms-widget',
  templateUrl: './facility-latest-alarms-widget.component.html',
  styleUrls: ['./facility-latest-alarms-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityLatestAlarmsWidgetComponent implements WidgetBase<FacilityLatestAlarmsWidgetOptions>,
  OnInit, OnDestroy {
  @Input() public dataModelOptions: FacilityLatestAlarmsWidgetOptions;

  public readonly dataModelChange$: Observable<FacilityLatestAlarmsWidgetOptions>;

  public readonly error$: Observable<void>;
  public readonly loading$: Observable<boolean>;
  public alarms$: Observable<LogDto[]>;
  public facilityIdControl: UntypedFormControl;

  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();

  private readonly _initialOptions$ = new Subject<FacilityLatestAlarmsWidgetOptions>();

  public constructor(
    private readonly dashboardStateService: DashboardStateService,
    private readonly modalService: ModalService,
    private readonly alarmClient: AlarmClient,
    private readonly userService: UserService
  ) {
    this.error$ = this._error$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.dataModelChange$ = this._initialOptions$.asObservable();

    this.alarms$ =
      this.dataModelChange$.pipe(startWith(this.dataModelOptions))
        .pipe(
          switchMap(_ => {
            if (!this.dataModelOptions.facilityId) {
              return EMPTY.pipe(indicate(this._loading$));
            }
            return this.alarmClient.getLogsBySearchCriteria(
              new LogSearchCriteriaDto({
                take: this.dataModelOptions?.numberToShow ?? 20,
                facilityIds: [this.dataModelOptions?.facilityId]
              }),
              this.userService.profileId
            ).pipe(indicate(this._loading$));
          }),
          catchError(() => {
            this._error$.next();
            return EMPTY;
          })
        );
  }

  public ngOnInit(): void {
    this.facilityIdControl = new UntypedFormControl(this.dataModelOptions.facilityId);
    this.facilityIdControl.valueChanges
      .subscribe((formControlValue: number) => {
        this.dataModelOptions.facilityId = formControlValue;
        this._initialOptions$.next({
          ...this.dataModelOptions
        });
      });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
    this._error$.complete();
    this._initialOptions$.complete();
  }

  public openMeterReport(log: LogDto): void {
    this.dashboardStateService.openMeterReport(log.alarm.meterId, log.facilityId);
  }

  public openAlarmModal(log: LogDto): void {
    const refreshToken = new Subject();
    const injector = Injector.create({
      providers: [
        {
          provide: ALARMS_LOG_GRID_REFRESH,
          useValue: refreshToken
        }
      ]
    });
    const modalInstance = this.modalService.open(AlarmDetailsModalComponent, { injector });
    modalInstance.componentInstance.getAlarmByLogId(log.id);
  }

}
