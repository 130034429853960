import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import {
  EmSearchTimeFrame,
  START_DATES
} from '../../../../modules/energy-management/constants/em-actions-search-params-constant';
import { getNumericEnumValues } from '@enerkey/ts-utils';

interface TimeFrameOption {
  value: EmSearchTimeFrame;
  text: string;
}

@Component({
  selector: 'time-frame-selection',
  templateUrl: './time-frame-selection.component.html',
  styleUrls: ['./time-frame-selection.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeFrameSelectionComponent),
    multi: true
  }]
})
export class TimeFrameSelectionComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean = false;
  @Input() public hiddenTimeFrames: EmSearchTimeFrame[] = [];

  public timeFrameOptions: TimeFrameOption[];
  public value: EmSearchTimeFrame;

  private updateValue: (param: EmSearchTimeFrame) => void;

  public constructor(private readonly translateService: TranslateService) {
  }

  public ngOnInit(): void {
    this.timeFrameOptions = getNumericEnumValues(EmSearchTimeFrame)
      .filter(value => !this.hiddenTimeFrames?.includes(value))
      .map(value => ({
        value: value,
        text: this.translateService.instant(START_DATES[value]?.NAME ?? '')
      }));
  }

  public writeValue(value: EmSearchTimeFrame): void {
    this.value = this.hiddenTimeFrames?.includes(value)
      ? null
      : value
    ;
  }

  public registerOnChange(fn: (param: EmSearchTimeFrame) => void): void {
    this.updateValue = fn;
  }

  public registerOnTouched(): void {}

  public valueChanged(item: EmSearchTimeFrame): void {
    this.value = item;
    this.updateValue?.(item);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
