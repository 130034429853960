import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ReportEventContainer } from '../../services/reporting-chart-event.functions';
import { NoteType } from '../reporting-chart/reporting-chart.component';

@Component({
  selector: 'chart-note-popup',
  templateUrl: './chart-note-popup.component.html',
  styleUrls: ['./chart-note-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartNotePopupComponent {
  @Input() public noteType: NoteType;
  @Input() public notes: ReportEventContainer['events'];

  public readonly NoteType = NoteType;
}
