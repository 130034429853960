import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { groupBy } from '@progress/kendo-data-query';

import { getServiceGroup } from '../../../constants/service';

import { ServicesService } from '../../services/services.service';
import { EkMultiComponent } from '../ek-multi/ek-multi.component';

@Component({
  selector: 'servicetype-multi-select',
  templateUrl: '../multi-select/multi-select.component.html',
  styleUrls: ['../multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ServiceTypeMultiSelectComponent,
    multi: true
  }]
})
export class ServiceTypeMultiSelectComponent extends EkMultiComponent implements OnInit {
  public override overflowThreshold = 15;
  public override translate: boolean = false;

  public constructor(
    private readonly serviceService: ServicesService,
    private readonly translateService: TranslateService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  public ngOnInit(): void {
    this.serviceService.getServices().subscribe(services => {
      this.items = services
        .map(option => {
          const groupKey = getServiceGroup(option.name);
          return {
            value: option.id,
            text: this.translateService.instant(`ADMIN.SERVICE_NAME_${option.name.toUpperCase()}`),
            group: this.translateService.instant(`ADMIN.SERVICE_GROUP_${groupKey}`),
            groupKey,
          };
        })
        .sortByMany(
          g => g.groupKey === 'OTHERS',
          g => g.group,
          g => g.text
        );
      this.groupedItems = groupBy(this.items, [{ field: 'group', dir: 'desc' }]);
      this.changeDetectorRef.detectChanges();
    });
  }

}
