import DropdownMenuItemController from './dropdown-menu-item-controller';
import template from 'raw-loader!./dropdown-menu-item.html';

const DropdownMenuItemComponent = {
  template: template,
  controllerAs: 'vm',
  controller: DropdownMenuItemController,
  transclude: true,
  require: {
    parent: '^dropdownMenu'
  },
  bindings: {
    text: '@',
    title: '@',
    isDisabled: '<',
    onClick: '&'
  }
};

export default DropdownMenuItemComponent;
