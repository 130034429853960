import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { UserManagementClient, UserViewModel } from '@enerkey/clients/user-management';

export interface IUserManagementSearchCriteria {
  userName?: string,
  firstname?: string,
  lastname?: string,
  email?: string,
  organization?: string,
  organizationId?: number,
  profileName?: string,
  excludeProfileName?: string,
  facilityId?: number,
  includeInActive?: boolean
}

@Injectable()
export class UserManagementService implements OnDestroy {

  public readonly searchResult$: Observable<UserViewModel[]>;
  public readonly loading$: Observable<boolean>;

  private readonly _searchParams$ = new Subject<IUserManagementSearchCriteria>();
  private readonly _refresh$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    private readonly userManagementClient: UserManagementClient
  ) {
    this.loading$ = this._loading$.asObservable();

    this.searchResult$ = combineLatest([
      this._searchParams$,
      this._refresh$.pipe(startWith<void>(null)),
    ]).pipe(
      switchMap(([params, _]) => this.getSearchedUsers(params)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._searchParams$.complete();
    this._refresh$.complete();
  }

  /** Repeat previous search. */
  public refresh(): void {
    this._refresh$.next();
  }

  public search(params: IUserManagementSearchCriteria): void {
    this._searchParams$.next(params);
  }

  private getSearchedUsers(params: IUserManagementSearchCriteria): Observable<UserViewModel[]> {
    return this.userManagementClient.getAllUsers(
      params.userName ?? undefined,
      params.firstname ?? undefined,
      params.lastname ?? undefined,
      params.organization ?? undefined,
      params.organizationId ?? undefined,
      params.includeInActive ?? false,
      params.email ?? undefined,
      params.profileName ?? undefined,
      params.excludeProfileName ?? undefined,
      params.facilityId ?? undefined
    ).pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$)
    );
  }
}
