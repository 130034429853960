import ManualQaApiService   from './manual-qa-api.service';
import EnerkeyServiceModule from '../../../../services';

const dependencies = [EnerkeyServiceModule.name];

const ManualQaApiServiceModule = angular
  .module('app.modules.manual-qa.services.manual-qa-api', dependencies)
  .service('ManualQaApiService', ManualQaApiService)
;

export default ManualQaApiServiceModule;
