import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Transition } from '@uirouter/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { formGroupFrom } from '@enerkey/ts-utils';

import { MeterManagementService, MeterSearchParameters } from '../../services/meter-management.service';

@Component({
  selector: 'meter-management-search',
  templateUrl: './meter-management-search.component.html',
  styleUrls: ['./meter-management-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterManagementSearchComponent implements AfterViewInit {
  public searchForm: UntypedFormGroup;

  public readonly loading$: Observable<boolean>;

  public constructor(
    private readonly meterManagementService: MeterManagementService,
    private readonly transition: Transition
  ) {
    this.loading$ = this.meterManagementService.loading$;
    this.searchForm = formGroupFrom<MeterSearchParameters>({
      currentProfile: false,
      profileIdsFromSelect: null,
      profileIds: null,
      facilityName: null,
      facilityIds: null,
      enegiaIds: null,
      meterIds: null,
      terminalIds: null,
      terminalName: null,
      eanCode: null,
      protocolCode: null,
      usagePlaceNumber: null,
      energyCompanyUsagePlaceNumbers: null
    });

    this.searchForm.valueChanges
      .pipe(filter((val: MeterSearchParameters) => val.currentProfile))
      .subscribe(() => this.search());
  }

  public ngAfterViewInit(): void {
    const initialFacilityIds: number[] = this.transition.params()?.facilityIds ?? null;

    if (Array.hasItems(initialFacilityIds)) {
      this.searchForm.reset();
      this.searchForm.patchValue({
        facilityIds: initialFacilityIds
      });
      this.search();
    }
  }

  public getCurrentProfile(): void {
    this.meterManagementService.search({ ...this.formValue, currentProfile: true });
  }

  public search(): void {
    this.meterManagementService.search(this.formValue);
  }

  private get formValue(): MeterSearchParameters {
    return this.searchForm.value;
  }
}
