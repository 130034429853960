/* eslint-disable @typescript-eslint/no-explicit-any */
import { isValidCron } from './is-valid-cron';

export function cronValidation(): any {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: true,
    link: (_scope: any, _element: any, _attributes: any, control: any) => {
      control.$validators.cronExpression = (
        _modelValue: string, viewValue: string
      ) => isValidCron(viewValue);
    }
  };
}

