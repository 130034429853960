import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  UntypedFormGroup, ValidationErrors, Validator, Validators
} from '@angular/forms';

import { formControlsFrom } from '@enerkey/ts-utils';

import { EmissionFactorType, EmissionsFacilityWiseWidgetOptions } from '../../services/emissions-facility-wise-widget.service';

type ChangeFn = (options: EmissionsFacilityWiseWidgetOptions) => void;

type FormValues = EmissionsFacilityWiseWidgetOptions;

@Component({
  selector: 'emissions-facility-wise-widget-options',
  templateUrl: './emissions-facility-wise-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmissionsFacilityWiseWidgetOptionsComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => EmissionsFacilityWiseWidgetOptionsComponent)
  }]

})
export class EmissionsFacilityWiseWidgetOptionsComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() public initialState: EmissionsFacilityWiseWidgetOptions;
  public readonly disabledEmissionFactor: EmissionFactorType[] = [EmissionFactorType.Location];
  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public ngOnInit(): void {
    const values = this.optionsToForm(this.initialState);
    const controls = formControlsFrom(
      values,
      Object.keys(values).toRecord(key => key, () => Validators.required)
    );
    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges
      .subscribe((formValue: FormValues) => {
        this._onChange?.(formValue);
      });
  }

  public writeValue(value: EmissionsFacilityWiseWidgetOptions): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.formGroup.invalid) {
      return { required: true };
    } return null;
  }

  private optionsToForm(options: EmissionsFacilityWiseWidgetOptions): FormValues {
    return { ...options };
  }

}
