import { Component, Input } from '@angular/core';

import { VirtualMeter } from '@enerkey/clients/metering';

@Component({
  selector: 'formula-effect-date',
  templateUrl: './formula-effect-date.component.html',
  styleUrls: ['./formula-effect-date.component.scss']
})
export class FormulaEffectDateComponent {
  @Input() public isToDate = false;
  @Input() public set formula(value: VirtualMeter) {
    this._isActive = value.isActive;
    this._fromDate = value.from;
    this._toDate = value.to;
  }

  private _isActive: boolean;
  private _fromDate: Date;
  private _toDate: Date;

  public get isActive(): boolean {
    return this._isActive;
  }

  public get dateToShow(): Date {
    return this.isToDate ? this._toDate : this._fromDate;
  }

  public get isInEffect() {
    return this.isToDate && !this._toDate;
  }
}
