import _ from 'lodash';
import { ActionsImpactType } from '../constants/actions-impact-type';

ERActionsImpactService.$inject = ['utils', 'kendo', 'ColorService', 'EnergyReportingConstants'];

export function ERActionsImpactService(utils, kendo, colorService, EnergyReportingConstants) {

  function createCategoryColumn(options, data) {
    const title = `${utils.localizedString('FACILITIES.SIDEBAR.ACTIONSIMPACT.ACTIONS') }: ${
      getEnergyTypesNames(options.series.ActionsImpactTypes).join(', ')}`
    ;
    const categoryColumn = {
      field: 'ActionsImpact',
      normalized: false,
      title: title,
      groupable: false,
      columns: [],
      headerAttributes: { title: title }
    };
    categoryColumn.columns = createChildColumns(options, data);
    return categoryColumn;
  }

  function getEnergyTypesNames(energyTypes) {
    const actionsImpactEnergyTypePrefix = 'FACILITIES.SIDEBAR.ACTIONSIMPACT.';
    const titleArr = _.reduce(energyTypes, (title, actionsImpactEnergyType) => {
      let typeName;
      switch (actionsImpactEnergyType) {
        case ActionsImpactType.Electricity:
          typeName = utils.localizedString(`${actionsImpactEnergyTypePrefix }ELECTRICITY`);
          break;
        case ActionsImpactType.Heat:
          typeName = utils.localizedString(`${actionsImpactEnergyTypePrefix }HEAT`);
          break;
        case ActionsImpactType.Fuel:
          typeName = utils.localizedString(`${actionsImpactEnergyTypePrefix }FUEL`);
          break;
        case ActionsImpactType.Water:
          typeName = utils.localizedString(`${actionsImpactEnergyTypePrefix }WATER`);
          break;
      }
      title.push(typeName);
      return title;
    }, []);
    return titleArr;
  }

  function getChildColumnTitle(options, action, energyType) {
    const titleBegin = action === 'implemented' ?
      (
        `${options.series.BeginEndDates.fromString } - ${
          options.series.BeginEndDates.toString}`
      ) :
      (
        utils.localizedString(`FACILITIES_GRID.ACTIONSIMPACT.${ action.toUpperCase()}`)
      );
    return `${titleBegin } ${ utils.localizedString(`FACILITIES_GRID.ACTIONSIMPACT.${ energyType.toUpperCase()}`)}`;
  }

  const getChildColumnTooltip = function(action, energyType, actionsImpactEnergyTypes) {
    let types;
    if (energyType === 'investment') {
      types = getEnergyTypesNames(actionsImpactEnergyTypes).join(', ');
      types = types.replace(/, ([^ ]+)$/, ` ${ utils.localizedString('AND') } $1`);
      return `${utils.localizedString('FACILITIES_GRID.ACTIONSIMPACT.INVESTMENTS_FOR') } ${ types}`;
    }
    if (energyType === 'm3') {
      types = utils.localizedString('FACILITIES.SIDEBAR.ACTIONSIMPACT.WATER');
    } else {
      const actionsImpactEnergyTypesWithoutWater = _.without(actionsImpactEnergyTypes, 3);
      types = getEnergyTypesNames(actionsImpactEnergyTypesWithoutWater).join(', ');
      types = types.replace(/, ([^ ]+)$/, ` ${ utils.localizedString('AND') } $1`);
    }
    return `${utils.localizedString('FACILITIES_GRID.ACTIONSIMPACT.SAVINGS_FOR') } ${ types}`;
  };

  function createChildColumns(options, data) {
    const childColumns = [];
    const actionCategories = ['implemented', 'decided', 'open'];
    const format = 'n0';
    const color = colorService.getCssProperty('--enerkey-secondary-light');

    const energyTypes = ['investment'];
    const actionsImpactEnergyTypes = options.series.ActionsImpactTypes;
    const waterSelected = _.includes(actionsImpactEnergyTypes, ActionsImpactType.Water);

    // Add m3 columns if water is selected
    if (waterSelected) {
      energyTypes.splice(0, 0, 'm3');
    }

    // Add MWh columns if electricity, fuel, or heating is selected
    if (actionsImpactEnergyTypes.length > 1 || !waterSelected) {
      energyTypes.splice(0, 0, 'mwh');
    }

    _.each(actionCategories, action => {
      _.each(energyTypes, energyType => {
        const field = `actionsImpactValues$${energyType}$${action}`;
        const maxValue = getColumnMaxValue(
          options.supplementaryApi.actionsImpact.responseData,
          `${energyType}.${action}`
        );
        const title = getChildColumnTitle(options, action, energyType);
        const tooltip = getChildColumnTooltip(action, energyType, actionsImpactEnergyTypes);
        const column = {
          field: field,
          sort: 0,
          title: title,
          headerTemplate: options.isWidget ? null : title,
          width: EnergyReportingConstants.Dimensions.gridColumn.value[data.wideColumns ? 'wide' : 'narrow'],
          attributes: {
            class: 'cell-actions-impact-number'
          },
          headerAttributes: { title: tooltip },
          template: function(row) {
            return getCellTemplate(row, field, format, maxValue, color);
          }
        };

        childColumns.push(column);
      });
    });
    return childColumns;
  }

  function getColumnMaxValue(valuesList, field) {
    return _.reduce(valuesList, (maxValue, values) => {
      const value = _.get(values, field);
      return value > maxValue ? value : maxValue;
    }, 0);
  }

  function getCellTemplate(row, field, format, maxValue, color) {
    const value = _.get(row, field);
    if (!angular.isNumber(value)) {
      return '';
    }

    const kTemplate = kendo.template('<span class="k-grid-cell-actions-impact" style="#= style #">#= content #</span>');
    const style = `background:${ colorService.getActionsImpactHighlightColor(color, value, maxValue) };`;
    return kTemplate({
      content: kendo.toString(value, format),
      style: style
    });
  }

  return {
    createCategoryColumn: createCategoryColumn
  };
}
