<a
  class="topbar-icon-item"
  [ngfTooltip]="'BOOKMARK.BOOKMARKS' | translate"
  [placement]="'bottom'"
  (click)="openBookmarkModal()"
>
  <i class="fa fa-bookmark"></i>
  <span
    *ngIf="isMobile"
    class="topbar-mobile-title"
  >
    {{'BOOKMARK.BOOKMARKS' | translate}}
  </span>
</a>
