import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { AttachmentsClient, DocumentCompleteViewModel } from '@enerkey/clients/attachments';

import { ToasterService } from '../../../../shared/services/toaster.service';
import { WINDOW } from '@enerkey/angular-utils';

@Component({
  selector: 'documents-preview',
  templateUrl: './documents-preview.component.html',
  styleUrls: ['./documents-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ windowClass: 'fixed-height modal-dialog-scrollable', backdrop: true })
export class DocumentsPreviewComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public selectedDocument: DocumentCompleteViewModel;

  public readonly fileURL$: Observable<string>;
  public readonly previewURL$: Observable<string>;
  public readonly hasPreview$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;
  public readonly previewDownloadFailed$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _selectedDocument$ = new ReplaySubject<DocumentCompleteViewModel>(1);
  private readonly _destroy$ = new Subject<void>();
  private readonly _previewDownloadFailed$ = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly attachmentClient: AttachmentsClient,
    private readonly toasterService: ToasterService,
    @Inject(WINDOW) private readonly window: Window,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);

    this.hasPreview$ = this._selectedDocument$.pipe(
      map(document => document.hasPreview)
    );

    this.loading$ = this._loading$.asObservable();
    this.previewDownloadFailed$ = this._previewDownloadFailed$.asObservable();

    this.previewURL$ = this._selectedDocument$.pipe(
      switchMap(document => this.attachmentClient.getDocumentPreviewSasUrl(document.id).pipe(
        indicate(this._loading$)
      )),
      map(file => file.previewUrl),
      catchError(() => {
        this._previewDownloadFailed$.next(true);
        return of(undefined);
      })
    );

    this.fileURL$ = this._selectedDocument$.pipe(
      switchMap(document => this.attachmentClient.getDocumentDownloadSasUrl(document.id).pipe(
        indicate(this._loading$)
      )),
      map(file => file.downloadUrl),
      catchError(() => {
        this.toasterService.error('DOCUMENTS.LOAD_ERROR');
        return throwError(null);
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnInit(): void {
    this._selectedDocument$.next(this.selectedDocument);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._selectedDocument$.complete();
    this._loading$.complete();
    this._previewDownloadFailed$.complete();
  }

  public previewLoadFailed(): void {
    this.toasterService.error('DOCUMENTS.IMAGE_LOAD_FAILED');
    this._previewDownloadFailed$.next(true);
  }

  public downloadDocument(): void {
    const timestamp = Date.now();
    this.fileURL$.pipe(take(1)).subscribe({
      next: url => {
        this.window.open(`${url}&xyz=${timestamp}`);
      },
      error: () => {
        this.toasterService.error('DOCUMENTS.LOAD_ERROR');
      }
    });
  }
}
