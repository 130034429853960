import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SelectableResolution } from '../../../modules/reporting/components/reporting-search-form/reporting-search-form.component';

import { ReportingGridColumnBase } from '../../../modules/reporting/services/reporting-grid.service';
import { ValueFormatPipe } from './value-format.pipe';

@Pipe({
  name: 'reportingGridValueFormat'
})
export class ReportingGridValueFormatPipe implements PipeTransform {
  public constructor(
    private readonly valueFormatPipe: ValueFormatPipe
  ) { }

  public transform(
    series: ReportingGridColumnBase<string>,
    resolution: SelectableResolution
  ): Observable<string> {
    if (series.isPercentSerie) {
      return of('p1');
    }
    if (series.isRelatedSerie) {
      return of('#,#.0');
    }
    return this.valueFormatPipe.transform(
      series.quantityId,
      series.unitKey,
      resolution,
      series.derivedId
    );
  }
}
