import { Pipe, PipeTransform } from '@angular/core';

type Primitive = string | number;
type ParamObj = Record<Primitive, Primitive>;

@Pipe({ name: 'interpolate' })
export class InterpolatePipe implements PipeTransform {

  public transform(template: string, param: ParamObj): string;
  public transform(template: string, ...args: Primitive[]): string;
  public transform(template: string, ...args: [ParamObj] | Primitive[]): string {
    if (this.isTemplateObject(args)) {
      for (const [key, value] of Object.entries(args[0])) {
        template = this.interpolate(template, key, value);
      }
    } else {
      for (let i = 0; i < args.length; i++) {
        template = this.interpolate(template, i, args[i]);
      }
    }

    return template;
  }

  private interpolate(template: string, slot: Primitive, value: Primitive): string {
    const pattern = new RegExp(`\\{${slot}\\}`, 'g');
    return template.replace(pattern, `${value}`);
  }

  private isTemplateObject(arg: [ParamObj] | Primitive[]): arg is [ParamObj] {
    return typeof arg[0] === 'object';
  }
}
