import ManualQaServiceModule                   from '../../services';
import ManualQaSearchListDeleteComponent       from './manual-qa-search-list-delete-component';
import ManualQaSearchListDeleteModalController from './manual-qa-search-list-delete-modal-controller';
import ManualQaSearchListDeleteService         from './manual-qa-search-list-delete-service';

const dependencies = [ManualQaServiceModule.name];

const ManualQaSearchListDeleteModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-list-delete', dependencies)
  .component('manualQaSearchListDelete', ManualQaSearchListDeleteComponent)
  .service('ManualQaSearchListDeleteService', ManualQaSearchListDeleteService)
  .controller('ManualQaSearchListDeleteModalController', ManualQaSearchListDeleteModalController)
;

export default ManualQaSearchListDeleteModule;
