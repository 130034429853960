import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, mergeMap, switchMap, tap } from 'rxjs/operators';

import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { Meter, MeteringClient } from '@enerkey/clients/metering';

import { TargetMeter } from '../../shared/target-meter';
import { ToasterService } from '../../../../shared/services/toaster.service';

interface MeterResponse {
  [key: string]: Meter[];
}

class AlreadyHandledError implements Error {
  public name: string;

  public constructor(public message: string) {
  }
}

@Component({
  selector: 'add-meters',
  templateUrl: './add-meters.component.html',
  styleUrls: ['./add-meters.component.scss']
})
export class AddMetersComponent implements OnInit {
  @Input() public initialEnegiaId: number;
  @Output() public readonly meterSelectionChange = new EventEmitter<TargetMeter[]>();

  public sourceMeters: Meter[] = [];
  public targetMeters: TargetMeter[] = [];
  public selectedSourceMeterIds: number[] = [];
  public selectedTargetMeterIds: number[] = [];
  public loading = false;
  public currentFacility: Facility;
  public enegiaId: number;

  private facilityToSearchWith: Facility;

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly facilityClient: FacilityClient,
    private readonly toaster: ToasterService
  ) {
  }

  public ngOnInit(): void {
    if (this.initialEnegiaId) {
      this.enegiaId = this.initialEnegiaId;
      this.searchMeters();
    }
  }

  public addSelectedMeters(): void {
    const selectedMeters = this.addTargetMeterDefaultPropertyValues(
      this.getSelectedMeters(this.sourceMeters, this.selectedSourceMeterIds)
    );
    this.sourceMeters = this.getUnselectedMeters(this.sourceMeters, this.selectedSourceMeterIds);
    this.targetMeters = this.targetMeters.concat(selectedMeters);
    this.selectedSourceMeterIds = [];
    this.meterSelectionChange.emit(this.targetMeters);
  }

  public removeSelectedMeters(): void {
    const selectedMeters = this.getSelectedMeters(this.targetMeters, this.selectedTargetMeterIds);
    this.targetMeters = this.getUnselectedMeters(this.targetMeters, this.selectedTargetMeterIds);
    this.sourceMeters = this.sourceMeters.concat(selectedMeters);
    this.selectedTargetMeterIds = [];
    this.meterSelectionChange.emit(this.targetMeters);
  }

  public changeSelectedTargetMeterFactor(factor: number): void {
    this.targetMeters.forEach(meter => {
      if (this.isMeterSelected(meter, this.selectedTargetMeterIds)) {
        meter.formulaFactor = factor;
      }
    });
  }

  public searchMeters(): void {
    this.loading = true;

    this.getFacilitySubscription()
      .pipe(
        tap(facility => (this.currentFacility = facility)),
        switchMap(facility => this.meteringClient.getMetersForFacilityIds([facility.id])),
        finalize(() => (this.loading = false))
      )
      .subscribe({
        next: (result: Record<string, any>) => this.setSourceMeters(result), // TODO: correct typing
        error: (err: unknown) => this.handleMeterRequestError(err)
      });
  }

  public setFacilityToSearchWith(facility: Facility): void {
    this.facilityToSearchWith = facility;
  }

  public isSearchEnabled(): boolean {
    return !this.loading && (this.isEnegiaIdSet() || !!this.facilityToSearchWith);
  }

  public isEnegiaIdSet(): boolean {
    return !!this.enegiaId || this.enegiaId === 0;
  }

  public sourceMeterSelectionChange(ids: number[]): void {
    this.selectedSourceMeterIds = ids;
  }

  public targetMeterSelectionChange(ids: number[]): void {
    this.selectedTargetMeterIds = ids;
  }

  private getFacilitySubscription(): Observable<Facility> {
    return this.isEnegiaIdSet() ?
      this.facilityClient.searchFacilitiesUsingEnegiaIds([this.enegiaId]).pipe(
        catchError(() => {
          throw new AlreadyHandledError('ADMIN.VIRTUAL_METERS.ADD_METERS.NO_FACILITY_WITH_ENEGIA_ID');
        }),
        mergeMap(facilities => of(facilities[0]))
      ) :
      of(this.facilityToSearchWith);
  }

  private setSourceMeters(meterResponse: MeterResponse): void {
    if (this.hasResponseMetersForCurrentFacility(meterResponse)) {
      this.sourceMeters = meterResponse[this.currentFacility.id];
    } else {
      this.sourceMeters = [];
      this.toaster.info('ADMIN.VIRTUAL_METERS.ADD_METERS.NO_METERS_FOR_FACILITY');
    }
  }

  private handleMeterRequestError(error: unknown): void {
    this.sourceMeters = [];
    this.toaster.error(this.getMessageForError(error));
  }

  private getMessageForError(error: unknown): string {
    return error instanceof AlreadyHandledError
      ? error.message
      : 'ADMIN.VIRTUAL_METERS.ADD_METERS.LOADING_METERS_FAILED';
  }

  private hasResponseMetersForCurrentFacility(response: MeterResponse): boolean {
    const facilityId = this.currentFacility.id;

    return Array.hasItems(response?.[facilityId]);
  }

  private addTargetMeterDefaultPropertyValues(meters: Meter[]): TargetMeter[] {
    return meters.map(meter => new TargetMeter(meter, {
      formulaFactor: 1,
      nullToZero: false,
      enegiaId: this.currentFacility.enegiaId,
      facilityName: this.currentFacility.displayName
    }));
  }

  private getSelectedMeters<T extends Meter>(meterList: T[], selectedIds: number[]): T[] {
    return meterList.filter(
      meter => selectedIds.indexOf(meter.id) > -1
    );
  }

  private getUnselectedMeters<T extends Meter>(meterList: T[], selectedIds: number[]): T[] {
    return meterList.filter(
      meter => selectedIds.indexOf(meter.id) === -1
    );
  }

  private isMeterSelected(meter: Meter, selectedMeterIds: number[]): boolean {
    return selectedMeterIds.indexOf(meter.id) > -1;
  }
}
