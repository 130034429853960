import { Injectable } from '@angular/core';

import { ServiceLevel } from '@enerkey/clients/facility';

import { UserService } from '../../services/user-service';
import { ServiceLevelNumeric } from '../service-level-numeric';

export interface IServiceLevelService {
  hasAtLeastServiceLevel: (serviceLevel: ServiceLevel) => boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ServiceLevelService implements IServiceLevelService {
  public constructor(
    private readonly userService: UserService
  ) {
  }

  public hasAtLeastServiceLevel(serviceLevel: ServiceLevel): boolean {
    if (!Number.isFinite(serviceLevel)) {
      return true;
    }

    const userServiceLevel = this.userService.getServiceLevel();

    if (!Number.isFinite(serviceLevel)) {
      return false;
    }

    return new ServiceLevelNumeric(userServiceLevel) >= new ServiceLevelNumeric(serviceLevel);
  }
}
