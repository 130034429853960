import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { Facility, FacilityClient, FacilitySearchCriteriaDto } from '@enerkey/clients/facility';

import { TelemetryService } from '../../../services/telemetry.service';
import { ToasterService } from '../../../shared/services/toaster.service';

export interface IFacilityManagementSearchCriteria {
  getWithoutProfile?: boolean,
  profileIds?: number[],
  facilityId?: number[],
  facilityName?: string,
  enegiaId?: number[],
  companyId?: number,
  realestateId?: string[],
  address?: string,
  postalCode?: string,
  city?: string,
  country?: string,
  getMeterCount?: boolean,
  getContactInformation?: boolean,
}

type SearchResult = {
  facilities: Facility[];
  params: IFacilityManagementSearchCriteria;
}

@Injectable()
export class FacilityManagementService implements OnDestroy {
  public readonly searchResult$: Observable<SearchResult>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _repeat$ = new Subject<void>();
  private readonly _searchParams$ = new Subject<IFacilityManagementSearchCriteria>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly telemetry: TelemetryService,
    private readonly toaster: ToasterService,
    private readonly facilityClient: FacilityClient
  ) {
    this.loading$ = this._loading$.asObservable();

    this.searchResult$ = combineLatest([
      this._searchParams$,
      this._repeat$.pipe(startWith(null)),
    ]).pipe(
      switchMap(([p, _]) => forkJoin({
        params: of(p),
        facilities: this.searchFacilities(p).pipe(
          tap(f => {
            if (!Array.hasItems(f)) {
              this.toaster.info('ADMIN.NO_RESULTS_WITH_CURRENT_CRITERIA');
            }
          }),
          indicate(this._loading$),
          takeUntil(this._destroy$),
          catchError(err => {
            this.toaster.generalError('LOAD', 'FACILITY');
            this.telemetry.trackError(err, 'FacilityManagementService.searchResult$');
            return of([]);
          })
        ),
      })),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._searchParams$.complete();
    this._repeat$.complete();
  }

  public repeatSearch(): void {
    this._repeat$.next();
  }

  public search(params: IFacilityManagementSearchCriteria): void {
    this._searchParams$.next(params);
  }

  private searchFacilities(params: IFacilityManagementSearchCriteria): Observable<Facility[]> {
    if (params.getWithoutProfile) {
      return this.facilityClient.getAllFacilitiesWithoutLinkedProfile();
    }
    if (params.facilityId) {
      return this.facilityClient.getFacilities(params.facilityId);
    }
    if (params.enegiaId) {
      return this.facilityClient.searchFacilitiesUsingEnegiaIds(params.enegiaId);
    }
    if (params.realestateId) {
      return this.facilityClient.getFacilitiesSpecificCustomerIdentifiers(params.realestateId);
    }
    if (params.companyId) {
      return this.facilityClient.getFacilitiesUsingCompanyids([params.companyId]);
    }
    if (params.facilityName) {
      return this.facilityClient.getFacilityIdsByNameContainsCaseInsensitive(
        params.facilityName
      ).pipe(switchMap(ids => this.facilityClient.getFacilities(ids)));
    }
    return this.facilityClient.getFacilitiesBySearchCriteria(
      new FacilitySearchCriteriaDto({
        profileIds: params.profileIds,
        streetAddress: params.address,
        postalCode: params.postalCode,
        city: params.city,
        country: params.country
      })
    );
  }
}
