<ek-dropdown
  [showOnHover]="true"
  position="left"
>
  <div
    *ekDropdownToggle
    class="topbar-icon-item"
  >
    <i class="fas fa-newspaper"></i>
    <span
      *ngIf="unreadCount$ | async as unreadCount"
      class="badge badge--topbar"
    >{{ unreadCount }}</span>
  </div>
  <ek-dropdown-heading>
    {{ 'NEWS_WIDGET.NEWS' | translate }}
  </ek-dropdown-heading>
  <ek-dropdown-item
    *ngFor="let entry of news$ | async"
    (click)="openNewsModal(entry)"
  >
    <div>
      <news-unread *ngIf="!entry.isRead"></news-unread>
      {{ entry.title }}
    </div>
    <small>{{ entry.published | date:'shortDate' }}</small>
  </ek-dropdown-item>
  <ek-dropdown-item
    *ngIf="!(news$ | async)?.length"
    text="NEWS_WIDGET.NO_NEWS"
  ></ek-dropdown-item>
</ek-dropdown>
