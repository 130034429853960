<ng-template #topbarTemplate>
  <div class="content-right float-right">
    <button
      class="button button--primary"
      (click)="openReportEditModal()"
    >
    <i class="fa fa-plus"></i> {{ 'SUSTAINABILITY.GRI.ADD_REPORT' | translate }}
  </button>
  </div>
</ng-template>

<kendo-grid
  [loadingSpinner]="loading$ | async"
  [kendoGridBinding]="reports$ | async"
  [pageSize]="50"
  [pageable]="true"
  [resizable]="true"
  [group]="[{ field: 'year', dir: 'desc' }]"
  class="grid-full-height"
>
  <kendo-grid-column
    field="year"
    [title]="'YEAR' | translate"
    [hidden]="true"
  >
    <ng-template kendoGridGroupHeaderTemplate let-value="value">
      <strong>{{ value }}</strong>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="description"
    [title]="'SUSTAINABILITY.GRI.REPORT' | translate"
    [width]="400"
  >
    <ng-template kendoGridCellTemplate let-dataItem >
      <a (click)="viewReport(dataItem)">
        {{ dataItem.description?.length ? dataItem.description : dataItem.year }}
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column
    [width]="40"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        kendoGridEditCommand
        (click)="openReportEditModal(dataItem)"
        [ngfTooltip]="'SUSTAINABILITY.GRI.EDIT_NAME_YEAR' | translate"
      ></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column
    *ngFor="let scope of scopes"
    [title]="scope | griScopeName : true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>
        {{ dataItem.emissions[scope] | kendoNumber : formatTotal }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="lastModified"
    [title]="'LAST_MODIFIED' | translate"
    [width]="150"
    format="g"
  ></kendo-grid-column>
</kendo-grid>