import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';
import {
  Border,
  ChartComponent,
  RenderEvent,
  SeriesLabelsContentArgs,
  SeriesVisualArgs
} from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { Element, geometry, Layout, Text } from '@progress/kendo-drawing';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SimpleChangesOf } from '@enerkey/ts-utils';

import { GriScopeNamePipe } from '../../pipes/gri-scope-name.pipe';
import { ChartModel } from '../gri-charts/gri-charts.component';

type DonutChartData = { scope: string, value: number, color: string }[];

const centerTextFont = 'bold 13px "Open Sans", sans-serif';

@Component({
  selector: 'gri-charts-totals-donut',
  templateUrl: './gri-charts-totals-donut.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GriChartsTotalsDonutComponent implements OnChanges {
  @Input() public data: ChartModel[];
  @Input() public title: string;

  public readonly series$: Observable<DonutChartData>;

  public readonly seriesBorder: Border = { width: 1, color: 'white', dashType: 'solid' };
  public readonly valueFormat: string = '#,# tCO2e';

  public readonly visualHandler = this.visual.bind(this);

  @ViewChild(ChartComponent) public readonly chart: ChartComponent;

  private readonly _series$ = new BehaviorSubject<DonutChartData>([]);
  private _total: number;
  private _center: geometry.Point;
  private _radius: number;

  public constructor(
    private readonly scopeNamePipe: GriScopeNamePipe,
    private readonly intlService: IntlService,
    private readonly translateService: TranslateService
  ) {
    this.series$ = this._series$.asObservable();
  }

  public ngOnChanges(changes: SimpleChangesOf<this>): void {
    if (changes.data) {
      this.calculateSeriesAndTotal();
    }
  }

  public visual(e: SeriesVisualArgs): Element {
    this._center = e.center;
    this._radius = e.innerRadius;
    return e.createVisual();
  }

  public onRender(e: RenderEvent): void {
    const circleGeometry = new geometry.Circle(
      [this._center.x, this._center.y],
      this._radius
    );
    const bbox = circleGeometry.bbox();

    const line1 = new Text(
      this.translateService.instant('SUSTAINABILITY.GRI.TOTAL'),
      [0, 0], { font: centerTextFont }
    );

    const line2 = new Text(
      this.intlService.formatNumber(this._total, this.valueFormat),
      [0, 0], { font: centerTextFont }
    );

    const layout = new Layout(bbox, {
      alignContent: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      spacing: 5,
    });

    layout.append(line1, line2);
    layout.reflow();

    e.sender.surface.draw(layout);
  }

  public labelContent = (e: SeriesLabelsContentArgs): string =>
    `${e.category}\n${this.intlService.formatNumber(e.value, this.valueFormat)}`;

  private calculateSeriesAndTotal(): void {
    const seriesData = this.data.toGroupsBy('scope').getEntries().map(([scope, data]) => ({
      scope: this.scopeNamePipe.transform(scope, true),
      value: data.reduce((a, b) => a + b.value, 0),
      color: data[0].color
    }));

    this._series$.next(seriesData);

    this._total = this._series$.value.reduce((a, b) => a + b.value, 0);
  }
}
