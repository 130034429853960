import _ from 'lodash';
import moment from 'moment';

import { Bucket } from '@enerkey/clients/configuration-control';
import { getNumericEnumValues, isNumericEnum } from '@enerkey/ts-utils';
import { QualityAssuranceFilter } from '@enerkey/clients/manual-qa';

import TimeFrame from '../../../services/time-frame-service';
import * as TimeFrames from '../components/time-frame-radio/time-frame-radio-constant';
import { CURRENT_PROFILE_SELECTION } from '../constants/configs';
import { FacilitySearchType } from '../constants/facility-search-type';
import * as MeteringTypes from '../constants/metering-types';
import { MeterHierarchyTypes } from '../constants/meter-hierarchy-types';
import SearchTypes from '../constants/search-types';
import { ViewSettings } from '../constants/view-settings';
import { SearchItemType } from '../constants/search-item-type';
import { MeterSearchType } from '../constants/meter-search-type';
import { DefectTypes } from '../constants/defect-types';

/* eslint-disable @typescript-eslint/no-explicit-any */

type SearchCriteriaBucket = Pick<Bucket, 'id'>;

const defaultDefectTypes = getNumericEnumValues(DefectTypes).filterMap(
  (id: DefectTypes) => ![
    DefectTypes.TooLongInterpolation,
    DefectTypes.OpenEnd,
    DefectTypes.OpenEndMissingValue
  ]
    .includes(id),
  (id: number) => ({ id })
);

export const qaFilterTranslations: Readonly<Record<QualityAssuranceFilter, string>> = {
  [QualityAssuranceFilter.OnlyQaMeters]: 'MQA.SEARCH.QA_FILTER.ONLY_QA',
  [QualityAssuranceFilter.OnlyNonQaMeters]: 'MQA.SEARCH.QA_FILTER.NOT_QA',
  [QualityAssuranceFilter.All]: 'MQA.SEARCH.QA_FILTER.ALL',
};

interface SearchCriteriaConstructorParams {
  viewSetting?: ViewSettings;
  timeFrameSelection?: string;
  excludeDays?: number;
  fixedTimeFrame?: { fromDate: string; toDate: string} | TimeFrame;
  dynamicTimeFrame?: { fromDate: string; toDate: string} | TimeFrame;
  searchType?: number;
  subSearchType?: number;
  enegiaIds?: string;
  profileIds?: { id: number | string; ReportingObjectSetId?: number }[];
  meterName?: string;
  meterIds?: string;
  readerTypes?: any[];
  defectTypes?: any[];
  facilityName?: string;
  buckets?: SearchCriteriaBucket[];
  quantities?: number[];
  meteringTypes?: { id: number }[];
  meterHierarchyTypes?: { id: MeterHierarchyTypes };
  /** Backwards compatibility parameter */
  viewStatistics?: boolean;
  selectedFacilitySearchType?: FacilitySearchType;
  searchItemType?: SearchItemType;
  qualityAssuranceFilter?: { id: QualityAssuranceFilter };
}

export interface SearchRequestParams {
  timeFrame: TimeFrame;
  meterIds: number[];
  enegiaIds: number[];
  profileIds: (number | string)[];
  meterName: string;
  searchType: number;
  readerTypes: number[];
  faultTypeIds: number[];
  facilityName: string;
  quantities: number[];
  meteringTypes: number[];
  meterHierarchyStatusType: number;
  bucketIds: number[];
  qualityAssuranceFilterType: QualityAssuranceFilter;
}

export class SearchCriteria {
  /**
   * Parses TimeFrame from saved object
   *
   * @param {Object} values
   * @param {String} key
   *
   * @returns {TimeFrame|null}
   */
  public static parseTimeFrame(values: any, key: string): TimeFrame {
    try {
      return TimeFrame.parse(values[key]);
    } catch (error) {
      return SearchCriteria.getDefaultTimeFrame();
    }
  }

  /**
   * Returns default time frame
   *
   * @returns {TimeFrame}
   */
  public static getDefaultTimeFrame(): TimeFrame {
    return new TimeFrame(
      moment().subtract(1, 'month').startOf('month'),
      moment().endOf('month')
    );
  }

  public viewSetting: ViewSettings;
  public timeFrameSelection: string;
  public excludeDays: number;
  public fixedTimeFrame: TimeFrame;
  public dynamicTimeFrame: TimeFrame;
  public searchType: number;
  public subSearchType: number;
  public enegiaIds: string;
  public profileIds: any[];
  public meterName: string;
  public meterIds: string;
  public readerTypes: any[];
  public defectTypes: any[];
  public facilityName: string;
  public buckets: SearchCriteriaBucket[];
  public quantities: number[];
  public meteringTypes: any[];
  public meterHierarchyTypes: { id: number };
  public selectedFacilitySearchType: FacilitySearchType;
  public searchItemType: SearchItemType;
  public qualityAssuranceFilter: { id: QualityAssuranceFilter };

  public constructor(data?: string | SearchCriteriaConstructorParams) {
    const values: SearchCriteriaConstructorParams = typeof data === 'string' ? JSON.parse(data) : data;

    this.viewSetting = values?.viewSetting || ViewSettings.FACILITIES;
    this.timeFrameSelection = values?.timeFrameSelection || TimeFrames.CURRENT_MONTH;
    this.excludeDays = values?.excludeDays || 0;
    this.fixedTimeFrame = SearchCriteria.parseTimeFrame(values, 'fixedTimeFrame');
    this.dynamicTimeFrame = SearchCriteria.parseTimeFrame(values, 'dynamicTimeFrame');
    this.searchType = values?.searchType || SearchTypes.STORAGE_TYPE.DEFECT;
    this.subSearchType = values?.subSearchType || SearchTypes.CONTENT_TYPE.NO_FILTER;
    this.enegiaIds = values?.enegiaIds || '';
    this.profileIds = values?.profileIds || this.getDefaultProfileIds();
    this.meterName = values?.meterName || '';
    this.meterIds = values?.meterIds || '';
    this.readerTypes = values?.readerTypes || [];
    this.defectTypes = values?.defectTypes || defaultDefectTypes;
    this.facilityName = values?.facilityName || '';
    this.buckets = values?.buckets || [];
    this.quantities = values?.quantities || [];
    this.meteringTypes = values?.meteringTypes || [{ id: MeteringTypes.AUTOMATIC }];
    this.meterHierarchyTypes = values?.meterHierarchyTypes || { id: MeterHierarchyTypes.ALL };
    this.selectedFacilitySearchType = values?.selectedFacilitySearchType || this.getFacilitySearchType();
    this.searchItemType = values?.searchItemType || this.getSearchItemType(values?.viewStatistics ?? false);
    this.qualityAssuranceFilter = this.parseQAFilterType(values?.qualityAssuranceFilter);
  }

  public getDefaultProfileIds(): [any] {
    return [{
      id: CURRENT_PROFILE_SELECTION,
      ReportingObjectSetId: CURRENT_PROFILE_SELECTION
    }];
  }

  public getViewSetting(): ViewSettings {
    return this.viewSetting;
  }

  public getTimeFrameSelection(): string {
    return this.timeFrameSelection;
  }

  public getExcludeDays(): number {
    return this.excludeDays;
  }

  public getFixedTimeFrame(): TimeFrame {
    return this.fixedTimeFrame;
  }

  public getDynamicTimeFrame(): TimeFrame {
    return this.dynamicTimeFrame;
  }

  public getSearchType(): number {
    return this.searchType;
  }

  public getSubSearchType(): number {
    return this.subSearchType;
  }

  public getEnegiaIds(): string {
    return this.enegiaIds;
  }

  public getProfileIds(): any[] {
    return this.profileIds;
  }

  public getMeterName(): string {
    return this.meterName;
  }

  public getMeterIds(): string {
    return this.meterIds;
  }

  public getReaderTypes(): any[] {
    return this.readerTypes;
  }

  public getDefectTypes(): any[] {
    return this.defectTypes;
  }

  public getFacilityName(): string {
    return this.facilityName;
  }

  public getQuantityTypes(): any[] {
    return this.quantities;
  }

  public getMeteringTypes(): any[] {
    return this.meteringTypes;
  }

  public getMeterHierarchyTypeForParams(): number {
    return this.meterHierarchyTypes.id;
  }

  public getMeterHierarchyTypes(): { id: number } {
    return this.meterHierarchyTypes;
  }

  public getBuckets(): SearchCriteriaBucket[] {
    return this.buckets;
  }

  /**
   * Returns parameters for http requests.
   *
   * @returns {Object}
   */
  public getRequestParameters(): SearchRequestParams {
    return {
      timeFrame: this.getTimeFrame(),
      meterIds: String.toIntegers(this.getMeterIds()),
      enegiaIds: this.getEnegiaIdsForRequest(),
      profileIds: this.getProfileIdsForRequest(),
      meterName: this.getMeterName(),
      searchType: this.getSearchType() === SearchTypes.STORAGE_TYPE.RAW
        ? this.getSubSearchType()
        : this.getSearchType(),
      readerTypes: this.getReaderTypes().map(readerType => readerType.id),
      faultTypeIds: this.getDefectTypes().map(defectType => defectType.id),
      facilityName: this.getFacilityNameForRequest(),
      quantities: this.getQuantityTypes(),
      meteringTypes: this.getMeteringTypes().map(meteringType => meteringType.id),
      meterHierarchyStatusType: this.getMeterHierarchyTypeForParams(),
      bucketIds: this.getBucketIdsForRequest(),
      qualityAssuranceFilterType: this.qualityAssuranceFilter.id,
    };
  }

  /**
   * Returns actual and comparison time frames using time frame selection
   *
   * @returns {Object}
   */
  public getTimeFrame(): TimeFrame {
    let actualTimeFrame;

    switch (this.getTimeFrameSelection()) {
      case TimeFrames.CURRENT_MONTH: {
        actualTimeFrame = TimeFrame.getCurrentMonthTillTomorrow();
        break;
      }
      case TimeFrames.CURRENT_QUARTER: {
        actualTimeFrame = TimeFrame.getCurrentQuarter();
        break;
      }
      case TimeFrames.CURRENT_YEAR: {
        actualTimeFrame = TimeFrame.getCurrentYear();
        break;
      }
      case TimeFrames.PREVIOUS_MONTH: {
        actualTimeFrame = TimeFrame.getPreviousMonth();
        break;
      }
      case TimeFrames.PREVIOUS_TWO_MONTHS: {
        actualTimeFrame = TimeFrame.getPreviousTwoMonths();
        break;
      }
      case TimeFrames.PREVIOUS_YEAR_ONE_MONTH: {
        actualTimeFrame = TimeFrame.getPreviousYearOneMonth();
        break;
      }
      case TimeFrames.PREVIOUS_QUARTER: {
        actualTimeFrame = TimeFrame.getPreviousQuarter().subtract(this.getExcludeDays(), 'days');
        break;
      }
      case TimeFrames.FIXED_TIME_FRAME: {
        actualTimeFrame = this.getFixedTimeFrame();
        break;
      }
      case TimeFrames.DYNAMIC_TIME_FRAME: {
        const secondsBetween = this.getDynamicTimeFrame().getUnitsBetween('seconds');
        actualTimeFrame = TimeFrame.getDynamicTimeFrame(secondsBetween);
        break;
      }
    }

    return actualTimeFrame;
  }

  public getFacilitySearchType(): FacilitySearchType {
    if (this.selectedFacilitySearchType) {
      return this.selectedFacilitySearchType;
    }
    let searchType: FacilitySearchType;
    if (this.isEnegiaIdSearchType()) {
      searchType = FacilitySearchType.EnegiaIds;
    } else if (this.isFacilityNameSearchType()) {
      searchType = FacilitySearchType.FacilityName;
    } else if (this.isBucketSearchType()) {
      searchType = FacilitySearchType.Bucket;
    } else if (this.isCurrentProfileSearchType()) {
      searchType = FacilitySearchType.UseCurrentProfile;
    } else if (this.isProfileSearchType()) {
      searchType = FacilitySearchType.Profile;
    } else if (this.isReaderTypeSearchType()) {
      searchType = FacilitySearchType.ReaderType;
    }

    return searchType;
  }

  public getMeterSearchType(): MeterSearchType {
    return this.meterIds
      ? MeterSearchType.MeterIds
      : MeterSearchType.MeterName
    ;
  }

  public get isDefectSearch(): boolean {
    return this.searchItemType === SearchItemType.Defects;
  }

  /**
   * Backwards compatibility function for getting search item type.
   * Without this old search lists that don't have searchItemType property don't work correctly.
   *
   * @param viewStatistics
   */
  public getSearchItemType(viewStatistics: boolean): SearchItemType {
    return viewStatistics ? SearchItemType.Statistics : SearchItemType.Meters;
  }

  private getEnegiaIdsForRequest(): number[] {
    const clearEnegiaIds = this.selectedFacilitySearchType !== FacilitySearchType.EnegiaIds;
    return String.toIntegers(clearEnegiaIds ? '' : this.getEnegiaIds());
  }

  private getFacilityNameForRequest(): string {
    const clearFacilityName = this.selectedFacilitySearchType !== FacilitySearchType.FacilityName;
    return clearFacilityName ? '' : this.getFacilityName();
  }

  private getProfileIdsForRequest(): number[] {
    const defaultProfileIds = this.selectedFacilitySearchType === FacilitySearchType.UseCurrentProfile;
    return _.map(defaultProfileIds ? this.getDefaultProfileIds() : this.getProfileIds(), 'ReportingObjectSetId');
  }

  private getBucketIdsForRequest(): number[] {
    const clearBucketIds = this.selectedFacilitySearchType !== FacilitySearchType.Bucket;
    return clearBucketIds ? [] : this.buckets.map(bucket => bucket.id);
  }

  private isEnegiaIdSearchType(): boolean {
    return !!this.enegiaIds;
  }

  private isFacilityNameSearchType(): boolean {
    return !!this.facilityName;
  }

  private isProfileSearchType(): boolean {
    return Array.hasItems(this.profileIds);
  }

  private isCurrentProfileSearchType(): boolean {
    return this.profileIds?.[0] && this.profileIds[0].id === CURRENT_PROFILE_SELECTION;
  }

  private isBucketSearchType(): boolean {
    return Array.hasItems(this.buckets);
  }

  private isReaderTypeSearchType(): boolean {
    return Array.hasItems(this.readerTypes);
  }

  private parseQAFilterType(value: { id: number | string }): { id: QualityAssuranceFilter } {
    const idProp = value?.id;

    const id = isNumericEnum(QualityAssuranceFilter, idProp)
      ? idProp
      : QualityAssuranceFilter.All;

    return { id };
  }
}
