import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

import { assertType } from '@enerkey/ts-utils';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { ICreateContractProduct } from '@enerkey/clients/contract';

import { kendoNumericTextboxOptions } from '../../models/constants';

export type ContractFields = {
  contractId: string;
  productId: string;
  unitPrice: number;
  unitCount: number;
  fromDate: Date;
  toDate: Date;
};

// Design time checking that property names match
type Shim = {
  [K in keyof ContractFields]: K extends keyof ICreateContractProduct ? true : false
}[keyof ContractFields];

/* istanbul ignore next */
assertType<Shim>();

@Component({
  selector: 'contract-input-dialog',
  templateUrl: './contract-input-dialog.component.html',
  styleUrls: ['./contract-input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'tiny' })
export class ContractInputDialogComponent<K extends keyof ContractFields>
  extends ModalBase<ContractFields[keyof ContractFields]>
  implements AfterViewInit {

  public readonly numberInputOpts: Partial<NumericTextBoxComponent> = kendoNumericTextboxOptions;

  public readonly control: UntypedFormControl;
  public editedField: K;

  public get value(): ContractFields[K] {
    return this.control.value;
  }

  public constructor(
    private readonly elementRef: ElementRef,
    activeModal: NgfActiveModal
  ) {
    super(activeModal);
    this.control = new UntypedFormControl(null);
  }

  /* istanbul ignore next */
  public ngAfterViewInit(): void {
    const element: HTMLElement = this.elementRef.nativeElement;
    const input = element.querySelector('input');

    if (input) {
      // Avoids "Expression changed after it was checked"-error for kendo components
      setTimeout(() => input.focus());
    }
  }

  public submit(): void {
    super.closeModal(this.value);
  }

  public close(): void {
    super.dismissModal();
  }

}
