import TimeFrameComponent from './time-frame-component';
import ServicesModule from '../../services';

const dependencies = [
  ServicesModule.name
];

const TimeFrameModule = angular
  .module('app.components.time-frame', dependencies)
  .component('timeFrame', TimeFrameComponent);

export default TimeFrameModule;
