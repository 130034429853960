import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { firstValueFrom } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { WINDOW } from '@enerkey/angular-utils';
import { Roles } from '../../modules/admin/constants/roles';
import { LocalStorageService } from './local-storage.service';
import { Service } from '../../constants/service';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  private _identity: { username: string; roles: Roles[]; userId: number; services: Service[]; };
  private _organizationId: number;
  private _tenantId: number;

  public constructor(
    private readonly oauthService: OAuthService,
    private readonly localStorageService: LocalStorageService,
    @Inject(WINDOW) private readonly window: Window
  ) {
  }

  public async getUserInfo(): Promise<void> {
    return firstValueFrom(this.oauthService.events
      .pipe(
        filter(e => e.type === 'token_received'),
        take(1),
        switchMap(() => this.oauthService.loadUserProfile()),
        map((userInfo: any) => userInfo.info)
      ))
      .then((userInfo: any) => {
        this.localStorageService.saveValueByKey('lang', userInfo.lang);
        this._identity = {
          username: userInfo.preferred_username,
          roles: userInfo.role,
          services: userInfo.service,
          userId: Number.parseInt(userInfo.sub, 10),
        };
        this._organizationId = Number.parseInt(userInfo.organization_id, 10);
        this._tenantId = Number.parseInt(userInfo.tenantid, 10);
      }).catch((err: HttpErrorResponse) => {
        if (err?.status === 401) {
          this.window.location.href = '/';
          return Promise.reject();
        }

        throw err;
      });
  }

  public isDefaultTenant(): boolean {
    return this.tenantId === 1;
  }

  public get isIdentityResolved(): boolean {
    return !!this._identity;
  }

  public getUsername(): string {
    return this._identity?.username;
  }

  public getUserId(): number {
    return this._identity?.userId;
  }

  public isInRole(role: Roles): boolean {
    return this._identity?.roles?.includes(role) ?? false;
  }

  public get organizationId(): number {
    return this._organizationId;
  }

  public get tenantId(): number {
    return this._tenantId;
  }
}
