import { Directive, HostBinding } from '@angular/core';

import { NgfModalOptions } from './modal-config';
import { NgfActiveModal } from './modal-ref';

type Constructor<T> = new (...args: any) => T;

/** Key of the properties for modal options. */
const MODAL_PROPS = Symbol('__MODALPROPS__');

type ModalBaseCtor = Constructor<ModalBase> & { [MODAL_PROPS]: NgfModalOptions };

/**
 * Options that override the defaults when opening using `ModalService`.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function ModalOptions(options: NgfModalOptions): Function {
  return (modalBase: ModalBaseCtor) => {
    /* istanbul ignore else */
    if (modalBase.prototype instanceof ModalBase) {
      modalBase[MODAL_PROPS] = options;
    } else {
      throw Error('ModalOptions needs to target a class inheriting ModalBase');
    }
  };
}

/**
 * Returns the overridden default modal options from class inheriting `ModalBase`.
 */
export function getModalOptions(param: any): NgfModalOptions | {} {
  if (typeof param === 'function' && MODAL_PROPS in param) {
    return param[MODAL_PROPS];
  }

  return {};
}

/** Allows preventing a modal from closing depending on the modal's state. */
export interface ConfirmModalDismiss {
  /** Prevents the modal from closing if the return value is `false` or a promise that resolves to `false`. */
  confirmDismiss(): boolean | Promise<boolean>;
}

/**
 * Base class for modals. Use `ModalOptions`-decorator to override default modal options.
 */
@Directive()
export abstract class ModalBase<TReturn = void> {
  @HostBinding('class.modal-content') public readonly modalContainerClass = true;

  public constructor(
    /** Reference of the current modal instance. */
    private readonly currentModal: NgfActiveModal
  ) { }

  /**
   * Closes the current modal and resolves its promise with the parameter value.
   */
  protected closeModal(result?: TReturn): void {
    this.currentModal.close(result);
  }

  /**
   * Dismisses the current modal and rejects its promise with the parameter value.
   */
  protected dismissModal(error?: any): void {
    this.currentModal.dismiss(error);
  }
}
