<div class="card facilities-card">
  <div class="header-group bg-enerkey-default">
    <h3 class="header-group__label h-secondary">
      {{ 'FACILITIES_REPORT.METERTREE' | translate }} ({{ meters.length }})
    </h3>
  </div>
  <div class="meters-grid">
    <span *ngFor="let meter of meters">
      {{ meter.name }} ({{ meter.id }})
    </span>
  </div>
</div>
