import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { getStringEnumValues } from '@enerkey/ts-utils';

import { EkComboComponent } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { WidgetRelatedValueOption } from '../../../energy-reporting/shared/widget-constants';

const relatedValueTranslationKeys: Record<WidgetRelatedValueOption, string> = {
  [WidgetRelatedValueOption.Max]: 'FACILITIES_REPORT.MAX',
  [WidgetRelatedValueOption.Min]: 'FACILITIES_REPORT.MIN',
  [WidgetRelatedValueOption.Average]: 'FACILITIES_REPORT.AVERAGE',
};

@Component({
  selector: 'widget-related-value-select',
  templateUrl: '../../../../shared/ek-inputs/ek-combo/ek-combo.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WidgetRelatedValueSelectComponent),
    multi: true
  }]
})
export class WidgetRelatedValueSelectComponent extends EkComboComponent<WidgetRelatedValueOption> {
  public override items = getStringEnumValues(WidgetRelatedValueOption).map(
    option => ({
      value: option,
      text: relatedValueTranslationKeys[option]
    })
  );
}
