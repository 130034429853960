import { Quantities } from '@enerkey/clients/metering';
import { AggregateCollection, IReading } from '@enerkey/clients/energy-reporting';
import { percentageChange } from '@enerkey/ts-utils';

import {
  Consumptions,
  ConsumptionValueKey,
  QuantityChartData,
} from '../../../shared/energy-reporting-shared/shared/consumptions';
import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';

export interface SumConsumptionsTableRow {
  quantityId: Quantities;
  comparison: {
    value: number;
    incomplete: boolean;
  };
  inspection: {
    value: number;
    incomplete: boolean;
  };
  change: number;
  facilityIds: number[];
  color: string;
}

export interface SumConsumptionValues {
  sumData: QuantityChartData;
  quantitiesData: SumConsumptionsTableRow[];
}

export interface SumConsumptionWidgetValues {
  normalized: SumConsumptionValues;
  measured: SumConsumptionValues;
}

export class SumConsumptions {
  public static getWidgetData(
    quantitiesData: { [key: string]: AggregateCollection },
    readingKey: ConsumptionValueKey,
    quantityIds: {
      sumQuantity: Quantities;
      sumOfQuantities: Quantities[];
      profileQuantities: Quantities[];
    },
    start: TimeFrameResult,
    quantityColors: Map<Quantities, string>
  ): SumConsumptionValues {
    return new SumConsumptions(
      quantitiesData,
      readingKey,
      quantityIds,
      start,
      quantityColors
    ).getWidgetData();
  }

  private readonly periodKeys: string[];

  private constructor(
    private readonly quantitiesData: { [key: string]: AggregateCollection },
    private readonly readingKey: ConsumptionValueKey,
    private readonly quantityIds: {
      sumQuantity: Quantities;
      sumOfQuantities: Quantities[];
      profileQuantities: Quantities[];
    },
    private readonly start: TimeFrameResult,
    private readonly quantityColors: Map<Quantities, string>
  ) {
    this.periodKeys = start.Start.map(s => s.key).reverse();
  }

  private getWidgetData(): SumConsumptionValues {
    if (!this.quantitiesData[this.quantityIds.sumQuantity]) {
      return null;
    }
    return {
      sumData: Consumptions.getWidgetData(
        this.quantitiesData[this.quantityIds.sumQuantity],
        this.quantityIds.sumQuantity,
        this.readingKey,
        this.start.Start
      ),
      quantitiesData: this.getQuantitiesData()
    };
  }

  private getQuantitiesData(): SumConsumptionsTableRow[] {
    return this.quantityIds.profileQuantities
      .filter(quantityId => this.quantityIds.sumOfQuantities.includes(quantityId))
      .filter(quantityId => this.quantitiesData[quantityId])
      .map(quantityId => {
        const quantityData = this.quantitiesData[quantityId];
        const values = this.periodKeys.map(
          key => this.getPeriodValue(quantityData.Aggregates[key][this.readingKey])
        );

        const inspection = values[values.length - 1];
        const comparison = values.length > 1 ? values[0] : null;
        const change = percentageChange(inspection.value, comparison?.value);

        return {
          quantityId,
          comparison,
          inspection,
          change,
          facilityIds: quantityData.FacilitiesIncluded,
          color: this.quantityColors.get(quantityId)
        };
      });
  }

  private getPeriodValue(reading: IReading): { value: number; incomplete: boolean } {
    return {
      value: reading.Value,
      incomplete: (reading.Flags as string[])?.includes('Incomplete') ?? false
    };
  }
}
