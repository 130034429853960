function authHttpResponseInterceptor(
  $q, HttpPendingRequestsService
) {

  return {
    request: function(config) {
      config = config || {};
      if (config.isCancellable) {
        config.timeout = HttpPendingRequestsService.newTimeout();
      }
      return config;
    }
  };
}

authHttpResponseInterceptor.$inject = [
  '$q', 'HttpPendingRequestsService'
];

export default authHttpResponseInterceptor;
