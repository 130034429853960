import {
  addDays, addWeeks, endOfDay, endOfWeek, getDay, isSunday, startOfDay, startOfWeek, subMonths, subWeeks, subYears
} from 'date-fns';

import { EtCurveAnalyticResult } from '@enerkey/clients/ines';

import { ISODuration } from '../../../shared/isoduration';
import { EtCurveDisplayType } from '../models/et-curve.model';
import { EtCurveModelConverter } from './et-curve-model-converter.util';

export class EtCurveBaseUtil {
  public static getPeriodSelectionFromDateByMaxDate(maxDate: Date, period: ISODuration, resolution: ISODuration): Date {
    const isWeekFrequency = resolution === ISODuration.P7D;

    switch (period) {
      case ISODuration.P1Y: {
        return EtCurveBaseUtil.getYearlyFromDateByMaxDate(maxDate, isWeekFrequency);
      }
      case ISODuration.P1M: {
        return EtCurveBaseUtil.getMonthlyFromDateByMaxDate(maxDate, isWeekFrequency);
      }
      case ISODuration.P7D: {
        return EtCurveBaseUtil.getWeeklyFromDateByMaxDate(maxDate);
      }
    }
  }

  public static getPeriodSelectionToDateByMaxDate(maxDate: Date, period: ISODuration, resolution: ISODuration): Date {
    if (resolution === ISODuration.P7D) { return EtCurveBaseUtil.getWeeklyToDateByMaxDate(maxDate); }
    switch (period) {
      case ISODuration.P7D: return EtCurveBaseUtil.getWeeklyToDateByMaxDate(maxDate);
      default: return endOfDay(maxDate);
    }
  }

  public static getValidMaxDate(latestConsumptionReadingDate: Date, maxDate: Date): Date {
    if (latestConsumptionReadingDate !== null) {
      const wantedStartDate = this.getWantedStartDate(latestConsumptionReadingDate);
      if (wantedStartDate.getTime() <= maxDate.getTime()) { return wantedStartDate; }
    }

    return maxDate;
  }
  public static getLatestDate(currentDate: Date, validMaxDate: Date): Date {
    return currentDate.getTime() <= validMaxDate.getTime() ? currentDate : validMaxDate;
  }

  public static validateCurrentResolution(
    etCurves: EtCurveAnalyticResult[], quantity: number, resolution: ISODuration
  ): { isValid: boolean, value: ISODuration } {
    const relevantEtCurve = this.getRelevantEtCurve(etCurves, quantity);
    return {
      isValid: relevantEtCurve ?
        relevantEtCurve.etCurveData.resolution === EtCurveModelConverter.isoDurationToResolution(resolution) :
        true,
      value: EtCurveModelConverter.resolutionToIsoDuration(relevantEtCurve.etCurveData.resolution)
    };
  }

  public static shouldConvertEtCurve(
    savedEtCurves: EtCurveAnalyticResult[],
    displayType: { current: EtCurveDisplayType, prev: EtCurveDisplayType },
    specificId: { current: number, prev: number },
    quantity: { current: number, prev: number },
    resolution: { current: ISODuration, prev: ISODuration },
    timestamp: { current: number, prev: number }
  ): boolean {
    const isTimestampChange = timestamp.current !== timestamp.prev || timestamp.prev === 0;
    const isCalculatedDisplayType = displayType.current === EtCurveDisplayType.CALCULATED;
    const isResolutionChange = EtCurveBaseUtil.isResolutionChange(resolution.prev, resolution.current);
    const displayTypeChangeCalcToSaved = EtCurveBaseUtil.isDisplayChangeFromCalcToSaved(
      displayType.current, displayType.prev
    );

    const isSpecificIdDefined = EtCurveBaseUtil.isSpecificIdDefined(specificId.current);

    if ((isCalculatedDisplayType || displayTypeChangeCalcToSaved || isTimestampChange) && !isSpecificIdDefined) {
      return false;
    }

    if (isResolutionChange || isSpecificIdDefined) { return true; }

    const relevantEtCurve = EtCurveBaseUtil.getRelevantEtCurve(savedEtCurves, quantity.current);
    const isInitialQuantity = EtCurveBaseUtil.isInitialQuantity(quantity.prev, quantity.current);
    const isQuantityChange = EtCurveBaseUtil.isQuantityChange(quantity.prev, quantity.current);
    const hasCurrentResolution = EtCurveBaseUtil.etCurveHasResolution(relevantEtCurve, resolution.current);

    return !(!relevantEtCurve || isInitialQuantity || isQuantityChange || hasCurrentResolution);
  }

  private static getRelevantEtCurve(
    savedEtCurves: EtCurveAnalyticResult[], quantityId: number
  ): EtCurveAnalyticResult | null {
    return Number.isFinite(quantityId) ?
      savedEtCurves.find(c => c.quantityId === quantityId) ?? null :
      null;
  }

  private static isSpecificIdDefined(specificId: number): boolean {
    return Number.isFinite(specificId) && specificId !== -1;
  }

  private static isInitialQuantity(prevQuantity: number, quantity: number): boolean {
    return !Number.isFinite(prevQuantity) && Number.isFinite(quantity);
  }

  private static isQuantityChange(prevQuantity: number, quantity: number): boolean {
    return prevQuantity !== quantity;
  }

  private static isResolutionChange(prevResolution: ISODuration, resolution: ISODuration): boolean {
    return prevResolution !== resolution;
  }

  private static etCurveHasResolution(etCurve: EtCurveAnalyticResult, resolution: ISODuration): boolean {
    return etCurve &&
      EtCurveModelConverter.resolutionToIsoDuration(etCurve.etCurveData.resolution) === resolution;
  }

  private static isDisplayChangeFromCalcToSaved(
    displayType: EtCurveDisplayType, prevDisplayType: EtCurveDisplayType
  ): boolean {
    return displayType === EtCurveDisplayType.SAVED &&
      prevDisplayType === EtCurveDisplayType.CALCULATED;
  }

  private static getYearlyFromDateByMaxDate(date: Date, weekFrequency: boolean = false): Date {
    const oneYearAgoPlusOneDay = subYears(addDays(new Date(date), 1), 1);
    if (weekFrequency) {
      const dayOfWeek = getDay(oneYearAgoPlusOneDay);
      return (dayOfWeek === 0 || dayOfWeek > 4) ?
        startOfWeek(addWeeks(oneYearAgoPlusOneDay, 1), { weekStartsOn: 1 }) :
        startOfWeek(oneYearAgoPlusOneDay, { weekStartsOn: 1 });
    }

    return oneYearAgoPlusOneDay;
  }

  private static getMonthlyFromDateByMaxDate(date: Date, weekFrequency: boolean = false): Date {
    const oneMonthAgoPlusOneDay = subMonths(addDays(new Date(date), 1), 1);
    if (weekFrequency) {
      const dayOfWeek = getDay(oneMonthAgoPlusOneDay);
      return (dayOfWeek === 0 || dayOfWeek > 4) ?
        startOfWeek(addWeeks(oneMonthAgoPlusOneDay, 1), { weekStartsOn: 1 }) :
        startOfWeek(oneMonthAgoPlusOneDay, { weekStartsOn: 1 });
    }
    return oneMonthAgoPlusOneDay;
  }

  private static getWeeklyFromDateByMaxDate(date: Date): Date {
    return isSunday(date) ?

      // Return monday of the same week by the given date
      startOfWeek(startOfDay(date), { weekStartsOn: 1 }) :

      // Return monday of the previous week by the given date
      startOfWeek(subWeeks(startOfDay(date), 1), { weekStartsOn: 1 });
  }

  private static getWeeklyToDateByMaxDate(date: Date): Date {
    return isSunday(date) ?

      // Return end of day by the given date
      endOfDay(date) :

      // Return sunday of the previous week by the given date
      endOfWeek(subWeeks(endOfDay(date), 1), { weekStartsOn: 1 });
  }

  private static getWantedStartDate(latestConsumptionReadingDate: Date): Date {
    return startOfDay(addDays(subYears(new Date(latestConsumptionReadingDate), 1), 1));
  }
}
