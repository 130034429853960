import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ModalBase } from '@enerkey/foundation-angular';
import { DialogService } from '../../../../shared/services/dialog.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import
{ BulkModifyRolesRequest,
  UserManagementClient } from '@enerkey/clients/user-management';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { NgfActiveModal } from '@enerkey/foundation-angular';

@Component({
  templateUrl: './user-role-edit-modal.component.html',
  styleUrls: ['./user-role-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRoleEditModalComponent extends ModalBase<void> implements OnDestroy {

  public selectedUsers: number[];
  public readonly massRoleEditForm: UntypedFormGroup;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject(false);

  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.massRoleEditForm = new UntypedFormGroup({
      addRoles: new UntypedFormControl([]),
      removeRoles: new UntypedFormControl([])
    });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public submit(): void {
    const data = this.massRoleEditForm.value;
    this.dialogService.getConfirmationModalPromise({
      text: 'ADMIN.BULK_MODIFY_ROLES.SAVE_CONFIRMATION',
      title: this.translateService.instant('ADMIN.BULK_MODIFY_ROLES.SAVE_BUTTON'),
      isDelete: false
    }).then(() => {
      this.userManagementClient.bulkModifyRoles(
        new BulkModifyRolesRequest({
          userIds: this.selectedUsers,
          roleIdsToAdd: data.addRoles,
          roleIdsToRemove: data.removeRoles
        })
      ).pipe(
        indicate(this._loading$)
      ).subscribe({
        next: () => {
          this.toasterService.success('ADMIN.BULK_MODIFY_ROLES.SAVE_SUCCESS');
          super.closeModal();
        },
        error: () => this.toasterService.error('ADMIN.BULK_MODIFY_ROLES.SAVE_ERROR')
      });
    }).catch(() => {});
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
