<form [formGroup]="searchForm" (ngSubmit)="search()">
  <div class="searchInputs">
    <kendo-datepicker
      formControlName="beginDate"
      *labelBefore="'SUPERVISION.BEGIN_DATE' | translate"
    ></kendo-datepicker>
    <kendo-datepicker
      formControlName="endDate"
      *labelBefore="'SUPERVISION.END_DATE' | translate"
    ></kendo-datepicker>
    <ek-combo
      formControlName="status"
      *labelBefore="'SUPERVISION.STATUS' | translate"
      [items]="statusOptions"
    ></ek-combo>
    <input
      formControlName="message"
      *labelBefore="'SUPERVISION.MESSAGE' | translate"
      type="text"
      kendoTextBox
    />
  </div>
  <button
    class="button"
    type="submit"
  >{{ 'SEARCH' | translate }}</button>
</form>
