import { Pipe, PipeTransform } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';

import { ColorService } from '../services/color.service';

@Pipe({ name: 'quantityColor' })
export class QuantityColorPipe implements PipeTransform {

  public constructor(
    private readonly colorService: ColorService
  ) { }

  public transform(quantityId: Quantities): string {
    return this.colorService.getQuantityColor(quantityId) || '#000000';
  }
}
