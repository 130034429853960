import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { normalizedValuesPropertyKey, valuesPropertyKey } from '../constants/table-report-constants';
import { ReportingSeries } from '../shared/reporting-series';

@Pipe({
  name: 'tableReportFieldName'
})
@Injectable({ providedIn: 'root' })
export class TableReportFieldNamePipe implements PipeTransform {
  public transform(serie: ReportingSeries): string {
    const valueKey = serie.options.isNormalized ? normalizedValuesPropertyKey : valuesPropertyKey;
    return `${valueKey}.${serie.options.quantityId}.${serie.options.serieType}.${serie.serieStart}`;
  }
}
