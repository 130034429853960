import EnerKeyServicesModule      from '../../../../services';
import ManualQaFetchTypeComponent from './manual-qa-fetch-type.component';
import ManualQaFetchTypeService   from './manual-qa-fetch-type.service';

const dependencies = [EnerKeyServicesModule.name];

const ManualQaFetchTypeModule = angular
  .module('app.modules.manual-qa.components.manual-qa-fetch-type', dependencies)
  .component('manualQaFetchType', ManualQaFetchTypeComponent)
  .service('ManualQaFetchTypeService', ManualQaFetchTypeService)
;

export default ManualQaFetchTypeModule;
