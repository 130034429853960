import WizardController from './wizard-controller';
import template from 'raw-loader!./wizard.html';

const WizardComponent = {
  template: template,
  controllerAs: 'vm',
  controller: WizardController,
  bindings: {
    steps: '<',
    currentStep: '='
  }
};

export default WizardComponent;
