kendoDate.$inject = ['kendo'];

function kendoDate(kendo) {
  return function(datestring, format) {
    if (datestring) {
      format = format || 'g';
      return kendo.toString(new Date(datestring), format);
    }
    return datestring;
  };
}

export default kendoDate;
