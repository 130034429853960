import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileReaderService {

  public readFile(file: File): Observable<string> {
    return new Observable<string>(observer => {
      if (!file) {
        observer.next('');
        observer.complete();
      } else {
        const reader = new FileReader();
        reader.onload = function() {
          observer.next(this.result.toString());
          observer.complete();
        };
        reader.readAsText(file, 'utf-8');
      }
    });
  }

  public readFileAsBlob(file: File): Observable<Blob> {
    return new Observable<Blob>(observer => {
      if (!file) {
        observer.next(null);
        observer.complete();
      } else {
        const reader = new FileReader();
        reader.onload = function() {
          const arrayBuffer = this.result as ArrayBuffer;
          observer.next(new Blob([arrayBuffer], { type: file.type }));
          observer.complete();
        };
        reader.readAsArrayBuffer(file);
      }
    });
  }
}
