import _ from 'lodash';

import {
  HeatDistributionSystem,
  VentilationMethod,
  VentilationSystem
} from '@enerkey/clients/facility';
import { getNumericEnumValues } from '@enerkey/ts-utils';

import {
  heatDistributionSystemTranslations,
  ventilationMethodTranslations,
  ventilationSystemTranslations
} from '../../../../constants/facility.constant';
import { getOwnershipSelectItems } from '../../services/ek-ownership-select.service';
import { DEFAULT_COLOR, DEFAULT_WIDTH, HEADER_BACKGROUND } from '../../../../shared/spreadsheet.functions';

const $inject = [
  '$timeout', 'AdminSpreadsheetTranslationsService', 'AdminFacilitySpreadsheetSelectionService', 'facilities',
  'utils', '$q'
];

class FacilityCreateSpreadsheetService {

  constructor($timeout, AdminSpreadsheetTranslationsService, AdminFacilitySpreadsheetSelectionService, facilities,
    utils, $q) {
    this.$timeout = $timeout;
    this.AdminSpreadsheetTranslationsService = AdminSpreadsheetTranslationsService;
    this.facilities = facilities;
    this.utils = utils;
    this.$q = $q;
    this.facilityFields = AdminFacilitySpreadsheetSelectionService.getUpdateFields();
    this.translations = AdminSpreadsheetTranslationsService.getTranslations();

    this.rows = 999;
    this.maxRowsInValidationPages = 999;
  }

  /**
   * Returns spreadsheet options for admin spreadsheet.
   *
   * @returns {*}
   */
  getCreateFacilitySpreadsheetOptions() {
    return this.$q.all([this.getFirstSheet(this.rows), this.getBuildingClassSheet(), this.getLocaleSheet(),
      this.getOwnershipSheet(),
      this.getElectricityTaxClassSheet(),
      this.getVentilationMethodSheet(),
      this.getVentilationSystemSheet(),
      this.getHeatDistributionSheet()])
      .then(result => angular.copy({
        toolbar: false,
        sheetsbar: false,
        activeSheet: this.translations.sheets.inputPage,
        columns: this.facilityFields.length + 1,
        rows: result[0].rows.length,
        sheets: result
      }));
  }

  getElectricityTaxClassSheet() {
    return {
      name: this.translations.sheets.electricityTaxClassPage,
      columns: [
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH }
      ],
      rows: [{
        cells: [{
          value: this.translations.electricityTaxClass.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: this.translations.electricityTaxClassName.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }]
      }, {
        cells: [{
          value: 0,
          enable: false
        }, {
          value: this.utils.localizedString('FACILITY.ELECTRICITY_TAX_CLASS.NONE'),
          enable: false
        }]
      }, {
        cells: [{
          value: 1,
          enable: false
        }, {
          value: this.utils.localizedString('FACILITY.ELECTRICITY_TAX_CLASS.TAX_CLASS_1'),
          enable: false
        }]
      }, {
        cells: [{
          value: 2,
          enable: false
        }, {
          value: this.utils.localizedString('FACILITY.ELECTRICITY_TAX_CLASS.TAX_CLASS_2'),
          enable: false
        }]
      }]
    };
  }

  getVentilationMethodSheet() {
    const name = this.utils.localizedString('TECHNICALSPECIFICATIONS.VENTILATIONMETHOD');
    return this.getEnumSheet(name, VentilationMethod, ventilationMethodTranslations);
  }

  getVentilationSystemSheet() {
    const name = this.utils.localizedString('TECHNICALSPECIFICATIONS.PRIMARYVENTILATIONSYSTEM');
    return this.getEnumSheet(name, VentilationSystem, ventilationSystemTranslations);
  }

  getHeatDistributionSheet() {
    const name = this.utils.localizedString('TECHNICALSPECIFICATIONS.HEATDISTRIBUTIONSYSTEM');
    return this.getEnumSheet(name, HeatDistributionSystem, heatDistributionSystemTranslations);
  }

  getEnumSheet(sheetName, enums, enumTranslations) {
    const sheet = {
      name: sheetName,
      columns: [
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH }
      ],
      rows: [{
        cells: [{
          value: 'value',
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: 'name',
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }]
      }]
    };
    const list = getNumericEnumValues(enums).map(value => ({
      value: value,
      translation: this.utils.localizedString(enumTranslations[value])
    }));
    list.forEach(enumClass => {
      sheet.rows.push({
        cells: [{
          value: enumClass.value
        }, {
          value: enumClass.translation
        }]
      });
    });
    return sheet;
  }

  getOwnershipSheet() {
    const deferred = this.$q.defer();
    const ownershipSheet = {
      name: this.translations.sheets.ownershipPage,
      columns: [
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH }
      ],
      rows: [{
        cells: [{
          value: this.translations.ownership.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: this.translations.ownershipName.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }]
      }]
    };
    getOwnershipSelectItems().forEach(ownership => {
      ownershipSheet.rows.push({
        cells: [{
          value: ownership.id
        }, {
          value: this.utils.localizedString(ownership.text)
        }]
      });
    });
    deferred.resolve(ownershipSheet);

    return deferred.promise;
  }

  getBuildingClassSheet() {
    const deferred = this.$q.defer();
    const buildingClassSheet = {
      name: this.translations.sheets.buildingClassPage,
      columns: [
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH }
      ],
      rows: [{
        cells: [{
          value: this.translations.buildingClassId.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: this.translations.buildingClassNumericName.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: this.translations.buildingClassName.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }]
      }]
    };
    this.facilities.getBuildingClassTypes().then(result => {
      result.forEach(buildingClass => {
        buildingClassSheet.rows.push({
          cells: [{
            value: buildingClass.id
          }, {
            value: buildingClass.numericName
          }, {
            value: buildingClass.translation
          }]
        });
      });
      deferred.resolve(buildingClassSheet);
    });
    return deferred.promise;
  }

  getLocaleSheet() {
    const deferred = this.$q.defer();
    const localeSheet = {
      name: this.translations.sheets.localePage,
      columns: [
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH }
      ],
      rows: [{
        cells: [{
          value: this.translations.localeId.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: this.translations.localeTimeZone.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: this.translations.localeCountryCode.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: this.translations.localeCurrency.heading,
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }]
      }]
    };
    this.facilities.getLocales().then(result => {
      result.forEach(locale => {
        localeSheet.rows.push({
          cells: [{
            value: locale.id
          }, {
            value: locale.timeZone
          }, {
            value: locale.countryCode
          }, {
            value: locale.currency
          }]
        });
      });
      deferred.resolve(localeSheet);
    });
    return deferred.promise;
  }

  getHeaderCells() {
    const array = [{
      value: this.translations.enegiaId.heading,
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }];
    this.facilityFields.forEach(result => {
      array.push({
        value: result.required ? `${result.text} *` : result.text,
        background: HEADER_BACKGROUND,
        textAlign: 'center',
        color: DEFAULT_COLOR,
        enable: false
      });
    });
    array.push({
      value: this.translations.status.heading,
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    });
    return array;
  }

  getColumns() {
    // First column is always EnegiaId
    const columns = [{ width: DEFAULT_WIDTH }];
    // Then actual facility related columns like name, city etc
    this.facilityFields.forEach(option => {
      columns.push({ width: option.width });
    });
    // Last we have status field
    columns.push({ width: 400 });
    return columns;
  }

  getFirstSheet(rows) {
    const sheet = {
      name: this.utils.localizedString('ADMIN.SPREADSHEET.INPUT_PAGE'),
      columns: this.getColumns(),
      frozenColumns: 2,
      rows: [{ cells: this.getHeaderCells() }]
    };
    for (let i = 0; i < rows; i++) {
      const cells = [{
        type: 'number',
        validation: null,
        enable: false
      }];
      this.facilityFields.forEach(option => {
        const cell = {
          type: 'text',
          validation: this.getOptionValidation(option)
        };
        if (option.format) {
          cell.format = option.format;
        }
        cells.push(cell);
      });
      // status should not be editable
      cells.push({
        enable: false
      });
      sheet.rows.push({ cells: cells });
    }
    return sheet;
  }

  getOptionValidation(option) {
    if (option.validation) {
      return option.validation;
    } else if (option.validationPage) {
      return {
        dataType: 'list',
        showButton: true,
        from: `'${option.validationPage}'!A$2:A${this.maxRowsInValidationPages}`,
        allowNulls: true,
        type: 'reject',
        titleTemplate: _.get(this.translations, option.name, '').titleTemplate,
        messageTemplate: _.get(this.translations, option.name, '').messageTemplate
      };
    }
    return null;
  }
}

FacilityCreateSpreadsheetService.$inject = $inject;

export default FacilityCreateSpreadsheetService;
