import { downgradeComponent } from '@angular/upgrade/static';

import CloseableModule from './closeable';
import ContentHeaderModule from './content-header';
import DonutVisualsModule from './donut-visuals';
import DropdownModule from './dropdown';
import DropdownMenuModule from './dropdown-menu';
import DropdownMenuItemModule from './dropdown-menu-item';
import DropdownMenuItemSeparatorModule from './dropdown-menu-item-separator';
import InlineIndicatorModule from './inline-indicator';
import LanguageDropdownModule from './language-dropdown';
import LinkUnlinkModule from './link-unlink';
import MeterInterfaceStatus from './meter-interface-status';
import ModalButtonsModule from './modal-buttons';
import ModalContentModule from './modal-content';
import ModalViewHeaderModule from './modal-view-header';
import MultiSelectModule from './multi-select';
import NumberInputFieldModule from './number-input-field';
import SelectPagingModule from './select-paging';
import TimeFrameModule from './time-frame';
import TimeFrameSelectModule from './time-frame-select';
import ToggleGroupModule from './toggle-group';
import WizardModule from './wizard';
import YearlyConsumptionInputModule from './yearly-consumption-input';
import QuantityUnitModule from './quantity-unit';
import SiteFooterModule from './site-footer';
import { TerminalEditComponent } from '../shared/terminal/terminal-edit/terminal-edit.component';
import { MeterTypeComponent } from '../shared/meter-type/meter-type.component';
import { SocialMediaButtonsComponent } from '../shared/social-media-buttons/social-media-buttons.component';
import { HelpComponent } from '../shared/help/help.component';
import { FacilitiesStateButtonComponent } from '../shared/facilities-state-button/facilities-state-button.component';
import { FacilitySelectComponent } from '../shared/facility-select/facility-select.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { TopbarNewsComponent } from '../shared/topbar-news/topbar-news.component';
import { TopbarComponent } from './topbar/topbar.component';
import downloadTopbarItem from './download-topbar-item';
import { ContentLoaderComponent } from '../shared/ek-loading/components/content-loader/content-loader.component';
import { CompanyDropdownComponent } from '../shared/ek-inputs/company-dropdown/company-dropdown.component';
import { InvestigationStatusComponent } from '../shared/energy-management-shared/components/investigation-status/investigation-status.component';
import { ProfileSearchSelectComponent } from '../shared/ek-inputs/profile-search-select/profile-search-select.component';
import { QuantityMultiSelectComponent } from '../shared/ek-inputs/quantity-multi-select/quantity-multi-select.component';
import { BookmarksTopbarItemComponent } from './bookmarks-topbar-item/bookmarks-topbar-item.component';

const ComponentsModule = angular.module('app.components', [
  InlineIndicatorModule.name,
  ContentHeaderModule.name,
  DonutVisualsModule.name,
  DropdownModule.name,
  DropdownMenuModule.name,
  DropdownMenuItemModule.name,
  DropdownMenuItemSeparatorModule.name,
  LanguageDropdownModule.name,
  LinkUnlinkModule.name,
  MeterInterfaceStatus.name,
  ModalButtonsModule.name,
  ModalContentModule.name,
  ModalViewHeaderModule.name,
  MultiSelectModule.name,
  NumberInputFieldModule.name,
  SelectPagingModule.name,
  TimeFrameModule.name,
  TimeFrameSelectModule.name,
  ToggleGroupModule.name,
  WizardModule.name,
  YearlyConsumptionInputModule.name,
  CloseableModule.name,
  QuantityUnitModule.name,
  SiteFooterModule.name
])
  .component('downloadTopbarItem', downloadTopbarItem)
  .directive('terminalEdit', downgradeComponent({ component: TerminalEditComponent }))
  .directive('meterType', downgradeComponent({ component: MeterTypeComponent }))
  .directive('socialMediaButtons', downgradeComponent({ component: SocialMediaButtonsComponent }))
  .directive('help', downgradeComponent({ component: HelpComponent }))
  .directive('facilitiesStateButton', downgradeComponent({ component: FacilitiesStateButtonComponent }))
  .directive('facilitySelect', downgradeComponent({ component: FacilitySelectComponent }))
  .directive('loadingIndicator', downgradeComponent({ component: LoadingIndicatorComponent }))
  .directive('topbarNews', downgradeComponent({ component: TopbarNewsComponent }))
  .directive('topbar', downgradeComponent({ component: TopbarComponent }))
  .directive('contentLoader', downgradeComponent({ component: ContentLoaderComponent }))
  .directive('companyDropdown', downgradeComponent({ component: CompanyDropdownComponent }))
  .directive('investigationStatus', downgradeComponent({ component: InvestigationStatusComponent }))
  .directive('profileSearchSelect', downgradeComponent({ component: ProfileSearchSelectComponent }))
  .directive('quantityMultiSelect', downgradeComponent({ component: QuantityMultiSelectComponent }))
  .directive('bookmarksTopbarItem', downgradeComponent({ component: BookmarksTopbarItemComponent }))
;

export default ComponentsModule;
