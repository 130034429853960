<form [formGroup]="formGroup">
  <fieldset>
    <et-curve-facility-list [showFacilityLink]="false" />
    <et-curve-quantity-list [filterByQuantityIds]="facilityEtCurveQuantities$" />
    <et-curve-energy-measurement />
    <et-curve-calculation
      [activeEtCurve]="activeEtCurve$"
      [advancedCheckboxEnabled]="false"
    />
  </fieldset>
</form>
