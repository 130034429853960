import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
  ViewContainerRef,
} from '@angular/core';

/**
 * Emits the event of focus leaving the scope of the host component (the host itself or its child).
 *
 * @example
 * ```html
 * <div
 *   (focusin)="buttonDisabled = false"
 *   (focusOutside)="buttonDisabled = true"
 * >
 *   <input type="text">
 *   <button [disabled]="buttonDisabled">Submit</button>
 * </div>
 * ```
 */
@Directive({ selector: '[focusOutside]' })
export class FocusOutsideDirective {

  @Output() public readonly focusOutside = new EventEmitter<FocusEvent>();

  /** HTML element hosting the directive. */
  private get host(): HTMLElement {
    return this.viewContainer.element.nativeElement;
  }

  public constructor(
    private readonly viewContainer: ViewContainerRef
  ) { }

  @HostListener('focusout', ['$event'])
  public focusOut($event: FocusEvent): void {
    // relatedTarget is null when focus disappears, ie: current focused input gets disabled
    // in that case, check if host contains the previously focused element
    const focusLeft = $event.relatedTarget === null
      ? this.hostContains($event.target)
      : this.hostDoesNotContain($event.relatedTarget);

    if (focusLeft) {
      this.focusOutside.emit($event);
    }
  }

  private hostContains(target: EventTarget): boolean {
    return target instanceof Node && this.host.contains(target);
  }

  private hostDoesNotContain(target: EventTarget): boolean {
    return target instanceof Node && !this.host.contains(target);
  }
}
