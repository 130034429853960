import ManualQaTabsService from './manual-qa-tabs-service';

const dependencies = [];

const ManualQaTabsServiceModule = angular
  .module('app.modules.manual-qa.services.manual-qa-tabs-service', dependencies)
  .service('ManualQaTabsService', ManualQaTabsService)
;

export default ManualQaTabsServiceModule;
