import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { formControlsFrom } from '@enerkey/ts-utils';
import { ConsumptionsWidgetOptions } from '../consumptions-widget/consumptions-widget.component';
import { QuantityService } from '../../../../shared/services/quantity.service';
import { Quantities } from '@enerkey/clients/metering';

type ChangeFn = (options: ConsumptionsWidgetOptions) => void;

type FormValues = ConsumptionsWidgetOptions;

@Component({
  selector: 'consumptions-widget-options',
  templateUrl: './consumptions-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ConsumptionsWidgetOptionsComponent),
    multi: true,
  }]
})
export class ConsumptionsWidgetOptionsComponent implements ControlValueAccessor, OnInit {
  @Input() public initialState: ConsumptionsWidgetOptions;

  public readonly quantities$: Observable<Quantities[]>;

  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public constructor(quantityService: QuantityService) {
    this.quantities$ = from(quantityService.getSignificantQuantitiesForProfile()).pipe(
      map(quantities => quantities.map(q => q.ID))
    );
  }

  public ngOnInit(): void {
    const values = this.optionsToForm(this.initialState);
    const controls = formControlsFrom<FormValues>(
      values,
      Object.keys(values).toRecord(key => key, () => Validators.required)
    );
    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges
      .subscribe((formValue: FormValues) => {
        this._onChange?.(formValue);
      });
  }

  public writeValue(value: ConsumptionsWidgetOptions): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  private optionsToForm(options: ConsumptionsWidgetOptions): FormValues {
    return { ...options };
  }
}
