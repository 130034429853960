import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'email-send-progress',
  templateUrl: './email-send-progress.component.html',
  styleUrls: ['./email-send-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailSendProgressComponent {
  @Input() public readingGroupIdentifier: string;
  @Input() public bucketAmount: number;
  @Input() public currentBucketIndex: number;
}
