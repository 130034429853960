import { StateService } from '@uirouter/core';

import { getNewActionDefaults } from '../../../energy-management/constants/em-shared.constant';
import { StateLocationService } from '../../../../services/state-location.service';
import EmActionEditService from '../../../energy-management/components/em-action-edit/em-action-edit-service';
import ErStateService from '../../services/er-state.service';
import ErReportSettingsService from '../../services/er-report-settings.service';
import { ActionType } from '@enerkey/clients/attachments';

export class ActionsPopupButtonsController {

  public static readonly $inject = [
    '$state',
    'StateLocationService',
    'emActionEditService',
    'erStateService',
    'erReportSettingsService'
  ];

  public type: string;
  public facilityId: number;
  public meter: any;
  public graphData: any;

  public constructor(
    private readonly $state: StateService,
    private readonly stateLocationService: StateLocationService,
    private readonly emActionEditService: EmActionEditService,
    private readonly erStateService: ErStateService,
    private readonly erReportSettingsService: ErReportSettingsService
  ) {}

  public goToEnergyManagement(): void {
    const targetStateName = this.type === 'actions' || this.type === 'action'
      ? 'energy-management.actions'
      : 'energy-management.comments';
    const params: any = {
      startDateIndex: 2,
      reportingObjectId: this.facilityId,
      automaticSearch: true
    };
    if (this.meter) {
      params.gridState = {
        columns: [
          {
            columns: [
              {
                field: 'meterName',
                hidden: false
              }
            ]
          }
        ]
      };
    }
    this.stateLocationService.openStateInNewTab(targetStateName, params);
  }

  // Give an action with default values as parameter. Default type for actions is energysaving and
  // for comments energy consumption comment. There are values for other fields as well, but those
  // are coerced out when saving so only valid fields get saved.
  public addAction(): void {
    const graphData = this.graphData;
    const actionType = this.type === 'actions' || this.type === 'action'
      ? ActionType.ES
      : ActionType.KE;
    const defaults = getNewActionDefaults(actionType);
    defaults.reportingObjectId = this.facilityId;
    defaults.quantityIds = graphData?.quantityId ? [graphData.quantityId] : [];
    defaults.meterIds = graphData?.id ? [graphData.id] : [];
    this.emActionEditService.getModal(defaults).then(() => {
      // $state.reload clears all data from a modal view, so a workaround is required.
      if (this.erStateService.isModalOpen()) {
        this.erReportSettingsService.getInstance('er-modal-report').reload();
      } else {
        this.$state.reload();
      }
    });
  }
}
