import { ValidatorFn, Validators } from '@angular/forms';

import { getEnumEntries } from '@enerkey/ts-utils';
import {
  ElectricityTaxClass,
  HeatDistributionSystem,
  HeatingMethod,
  Ownership,
  PropertyType,
  Unit,
  UsageStatus,
  VentilationMethod,
  VentilationSystem,
  WaterHeatingEnergyCalculation
} from '@enerkey/clients/facility';

/* eslint-disable max-len */

export const unitTranslations: Readonly<Record<Unit, string>> = {
  [Unit.None]: '',
  [Unit.M2]: 'm²',
  [Unit.M3]: 'm³',
  [Unit.Kg]: 'kg',
  [Unit.KWh]: 'kWh',
  [Unit.KW]: 'kW',
  [Unit.PersonPerDay]: 'ABBREVIATION.PERSON',
  [Unit.Pieces]: 'ABBREVIATION.PIECES',
  [Unit.CountAbbreviation]: '',
  [Unit.Percentage]: '%',
  [Unit.T]: '',
  [Unit.M]: ''
};

export const electricityTaxClassTranslations: Readonly<Record<number, string>> = {
  [ElectricityTaxClass.None]: 'FACILITY.ELECTRICITY_TAX_CLASS.NONE',
  [ElectricityTaxClass.ElectricityTaxClass1]: 'FACILITY.ELECTRICITY_TAX_CLASS.TAX_CLASS_1',
  [ElectricityTaxClass.ElectricityTaxClass2]: 'FACILITY.ELECTRICITY_TAX_CLASS.TAX_CLASS_2'
};

export const ventilationMethodTranslations: Readonly<Record<VentilationMethod, string>> = {
  [VentilationMethod.None]: 'FACILITY.VENTILATION_METHOD.NONE',
  [VentilationMethod.Automated]: 'FACILITY.VENTILATION_METHOD.AUTOMATED',
  [VentilationMethod.Manual]: 'FACILITY.VENTILATION_METHOD.MANUAL',
  [VentilationMethod.PartlyAutomated]: 'FACILITY.VENTILATION_METHOD.PARTLY_AUTOMATED'
};

export const ventilationSystemTranslations: Readonly<Record<VentilationSystem, string>> = {
  [VentilationSystem.None]: 'FACILITY.VENTILATION_SYSTEM.NONE',
  [VentilationSystem.PerRoomExhaust]: 'FACILITY.VENTILATION_SYSTEM.PER_ROOM_EXHAUST',
  [VentilationSystem.PerRoomIntakeAndExhaust]: 'FACILITY.VENTILATION_SYSTEM.PER_ROOM_INTAKE_AND_EXHAUST',
  [VentilationSystem.PerApartmentExhaust]: 'FACILITY.VENTILATION_SYSTEM.PER_APARTMENT_EXHAUST',
  [VentilationSystem.PerApartmentIntakeAndExhaust]: 'FACILITY.VENTILATION_SYSTEM.PER_APARTMENT_INTAKE_AND_EXHAUST',
  [VentilationSystem.CentralizedExhaust]: 'FACILITY.VENTILATION_SYSTEM.CENTRALIZED_EXHAUST',
  [VentilationSystem.CentralizedIntakeAndExhaust]: 'FACILITY.VENTILATION_SYSTEM.CENTRALIZED_INTAKE_AND_EXHAUST',
  [VentilationSystem.CentralizedIntakeAndExhaustHeatRecoveryVentilation]: 'FACILITY.VENTILATION_SYSTEM.CENTRALIZED_INTAKE_AND_EXHAUST_HEAT_RECOVERY_VENTILATION',
  [VentilationSystem.MechanicalExhaustAirHeatPump]: 'FACILITY.VENTILATION_SYSTEM.MECHANICAL_EXHAUST_AIR_HEAT_PUMP',
  [VentilationSystem.TopExtractorsWithFrequencyConverter]: 'FACILITY.VENTILATION_SYSTEM.TOP_EXTRACTORS_WITH_FREQUENCY_CONVERTER',
  [VentilationSystem.PerApartmentsAirSourceHeatPump]: 'FACILITY.VENTILATION_SYSTEM.PER_APARTMENTS_AIR_SOURCE_HEAT_PUMP',
  [VentilationSystem.GravityVentilation]: 'FACILITY.VENTILATION_SYSTEM.GRAVITY_VENTILATION',
};

export const heatDistributionSystemTranslations: Readonly<Record<HeatDistributionSystem, string>> = {
  [HeatDistributionSystem.None]: 'FACILITY.HEAT_DISTRIBUTION_SYSTEM.NONE',
  [HeatDistributionSystem.Radiator]: 'FACILITY.HEAT_DISTRIBUTION_SYSTEM.RADIATOR',
  [HeatDistributionSystem.Floor]: 'FACILITY.HEAT_DISTRIBUTION_SYSTEM.FLOOR',
  [HeatDistributionSystem.Ceiling]: 'FACILITY.HEAT_DISTRIBUTION_SYSTEM.CEILING',
  [HeatDistributionSystem.Air]: 'FACILITY.HEAT_DISTRIBUTION_SYSTEM.AIR',
};

export const ownershipTranslations: Readonly<Record<number, string>> = {
  [Ownership.None]: 'FACILITY.EDIT_FORM.OWNERSHIP_NONE',
  [Ownership.Own]: 'FACILITY.EDIT_FORM.OWNERSHIP_OWN',
  [Ownership.Rented]: 'FACILITY.EDIT_FORM.OWNERSHIP_RENTED',
  [Ownership.Share]: 'FACILITY.EDIT_FORM.OWNERSHIP_SHARE',
  [Ownership.Other]: 'FACILITY.EDIT_FORM.OWNERSHIP_OTHER',
};

const heatingMethodTranslations: Readonly<Record<HeatingMethod, string>> = {
  [HeatingMethod.None]: 'FACILITY.HEATING_METHOD.NONE',
  [HeatingMethod.DistrictHeating]: 'FACILITY.HEATING_METHOD.DISTRICT_HEATING',
  [HeatingMethod.ElectricHeating]: 'FACILITY.HEATING_METHOD.ELECTRIC_HEATING',
  [HeatingMethod.GasHeating]: 'FACILITY.HEATING_METHOD.GAS_HEATING',
  [HeatingMethod.GeothermalHeating]: 'FACILITY.HEATING_METHOD.GEOTHERMAL_HEATING',
  [HeatingMethod.WoodHeating]: 'FACILITY.HEATING_METHOD.WOOD_HEATING',
  [HeatingMethod.OilHeating]: 'FACILITY.HEATING_METHOD.OIL_HEATING',
  [HeatingMethod.AirWaterHeatPumpHeating]: 'FACILITY.HEATING_METHOD.AIR_WATER_HEAT_PUMP_HEATING',
  [HeatingMethod.OtherHeating]: 'FACILITY.HEATING_METHOD.OTHER_HEATING',
  [HeatingMethod.DistrictHeatingGeothermal]: 'FACILITY.HEATING_METHOD.DISTRICT_HEATING_GEOTHERMAL',
  [HeatingMethod.ElectricHeatingGeothermal]: 'FACILITY.HEATING_METHOD.ELECTRIC_HEATING_GEOTHERMAL',
  [HeatingMethod.NoOwnedPlan]: 'FACILITY.HEATING_METHOD.NO_OWNED_PLAN',
  [HeatingMethod.SolarCollectors]: 'FACILITY.HEATING_METHOD.SOLAR_COLLECTORS',
  [HeatingMethod.ExhaustAirHeatPump]: 'FACILITY.HEATING_METHOD.EXHAUST_AIR_HEAT_PUMP',
  [HeatingMethod.AirSourceHeatPump]: 'FACILITY.HEATING_METHOD.AIR_SOURCE_HEAT_PUMP',
  [HeatingMethod.WaterSourceHeatPump]: 'FACILITY.HEATING_METHOD.WATER_SOURCE_HEAT_PUMP',
  [HeatingMethod.Fireplace]: 'FACILITY.HEATING_METHOD.FIREPLACE',
};

const facilityUsageStatusTranslations: Readonly<Record<UsageStatus, string>> = {
  [UsageStatus.AtNormalUse]: 'FACILITY.STATUS.AT_NORMAL_USE',
  [UsageStatus.Empty]: 'FACILITY.STATUS.EMPTY',
  [UsageStatus.InRenovation]: 'FACILITY.STATUS.IN_RENOVATION',
  [UsageStatus.RentedBack]: 'FACILITY.STATUS.RENTED_BACK',
  [UsageStatus.UnderConstruction]: 'FACILITY.STATUS.UNDER_CONSTRUCTION',
  [UsageStatus.RentedBackHot]: 'FACILITY.STATUS.RENTED_BACK_HOT',
  [UsageStatus.RentedOut]: 'FACILITY.STATUS.RENTED_OUT',
  [UsageStatus.RentedOutHot]: 'FACILITY.STATUS.RENTED_OUT_HOT',
  [UsageStatus.Removed]: 'FACILITY.STATUS.REMOVED',
};

export const shareOfTemperatureFixingTranslations: Readonly<Record<WaterHeatingEnergyCalculation, string>> = {
  [WaterHeatingEnergyCalculation.None]: 'FACILITY.SHARE_OF_TEMPERATURE_FIXING.NONE',
  [WaterHeatingEnergyCalculation.PercentageFromHeating]: 'FACILITY.SHARE_OF_TEMPERATURE_FIXING.PERCENTAGE_FROM_HEATING',
  [WaterHeatingEnergyCalculation.ConstantFromHeating]: 'FACILITY.SHARE_OF_TEMPERATURE_FIXING.CONSTANT_FROM_HEATING',
  [WaterHeatingEnergyCalculation.ConstantShareMethod]: 'FACILITY.SHARE_OF_TEMPERATURE_FIXING.CONSTANT_SHARE_METHOD',
};

export const facilityPropertyEnumTranslations: Readonly<Record<string, Record<number, string>>> = {
  HeatingMethod: heatingMethodTranslations,
  SecondaryHeatingMethod: heatingMethodTranslations,
  Status: facilityUsageStatusTranslations
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const facilityPropertyEnums: Readonly<Record<string, Readonly<any[]>>> = {
  electricityTaxClass: getEnumEntries(ElectricityTaxClass),
  heatingMethod: getEnumEntries(HeatingMethod),
  secondaryHeatingMethod: getEnumEntries(HeatingMethod),
  ownership: getEnumEntries(Ownership),
  propertyType: getEnumEntries(PropertyType),
  status: getEnumEntries(UsageStatus),
  shareOfTemperatureFixing: getEnumEntries(WaterHeatingEnergyCalculation)
};

export const totalMeterCountColumns = [
  {
    field: 'meterCounts.mainMeterCounts.manualMetersTotalCount',
    title: 'METER_COUNTS.MAINMANUALMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.subMeterCounts.manualMetersTotalCount',
    title: 'METER_COUNTS.SUBMANUALMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.mainMeterCounts.automaticMetersTotalCount',
    title: 'METER_COUNTS.MAINAUTOMATICMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.subMeterCounts.automaticMetersTotalCount',
    title: 'METER_COUNTS.SUBAUTOMATICMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.mainMeterCounts.virtualMetersTotalCount',
    title: 'METER_COUNTS.MAINVIRTUALMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.subMeterCounts.virtualMetersTotalCount',
    title: 'METER_COUNTS.SUBVIRTUALMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.mainMeterCounts.metersTotalCount',
    title: 'METER_COUNTS.MAINMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.subMeterCounts.metersTotalCount',
    title: 'METER_COUNTS.SUBMETERSTOTALCOUNT'
  },
  {
    field: 'meterCounts.totalMeterCount',
    title: 'METER_COUNTS.TOTALMETERCOUNT'
  }
];

export const FACILITY_TAG_REGEX = /^[A-Za-zÄäÖö0-9_]+$/;
export const FACILITY_TAG_CSV_REGEX = /^([A-Za-zÄäÖö0-9_]+)([,][ ]?([A-Za-zÄäÖö0-9_]+))*$/;

export function parseFacilityTagsFromString(commaSeparated: string): string[] {
  if (!commaSeparated) {
    return [];
  }

  return commaSeparated
    .split(',')
    .mapFilter(
      tag => tag.trim(),
      tag => FACILITY_TAG_REGEX.test(tag)
    )
    .unique();
}

export function getChangedFacilityTags(before: string[], after: string[]): ChangedTags {
  const added = after.except(before);
  const removed = before.except(after);

  return {
    added,
    removed,
    anyChanges: added.length > 0 || removed.length > 0,
  };
}

export type ChangedTags = {
  added: string[];
  removed: string[];
  anyChanges: boolean;
};

export const facilityGridContactInfoColumns = [
  'Name',
  'Email',
  'Phone',
  'ContactName',
  'ContactEmail',
  'ContactPhone',
  'StreetAddress',
  'PostalCode',
  'City',
  'Country'
];

export enum FacilityInfoFieldTypes {
  Text,
  AutoComplete,
  Date,
  Number,
  Dropdown,
  TextArea,
  Boolean
}

type FacilityEditField = {
  title: string,
  formGroupName: string,
  formControlName: string,
  type: FacilityInfoFieldTypes,
  dropdownDataKey?: string,
  autoCompleteDataKey?: string,
  min?: number,
  max?: number,
  validation?: ValidatorFn
}

/*
  Facility-edit: FacilityInfo fields structure
  [
    [
      // Form row
      { left column field }, { right column field }
    ]
    ...
  ]
*/
export const facilityEditFacilityInfoFields: [FacilityEditField, FacilityEditField?][] = [
  [
    {
      title: 'FACILITY.EDIT_FORM.DISPLAYNAME',
      formGroupName: 'facilityInfo',
      formControlName: 'displayName',
      type: FacilityInfoFieldTypes.Text,
      validation: Validators.required
    },
    {
      title: 'FACILITY.EDIT_FORM.CUSTOMERSPECIFICIDENTIFER',
      formGroupName: 'facilityInfo',
      formControlName: 'customerSpecifiedIdentifier',
      type: FacilityInfoFieldTypes.Text
    }
  ], [
    {
      title: 'FACILITY.EDIT_FORM.STREETADDRESS',
      formGroupName: 'address',
      formControlName: 'streetAddress',
      type: FacilityInfoFieldTypes.Text
    },
    {
      title: 'FACILITY.EDIT_FORM.BUILDINGCLASS',
      formGroupName: 'facilityInfo',
      formControlName: 'buildingClassId',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'buildingClass'
    }
  ], [
    {
      title: 'FACILITY.EDIT_FORM.POSTALCODE',
      formGroupName: 'address',
      formControlName: 'postalCode',
      type: FacilityInfoFieldTypes.Text
    },
    {
      title: 'FACILITYINFORMATION.CUSTOMBUILDINGCLASS',
      formGroupName: 'facilityInfo',
      formControlName: 'customBuildingClass',
      type: FacilityInfoFieldTypes.AutoComplete,
      autoCompleteDataKey: 'customBuildingClass'
    }
  ], [
    {
      title: 'FACILITY.EDIT_FORM.CITY',
      formGroupName: 'address',
      formControlName: 'city',
      type: FacilityInfoFieldTypes.Text
    },
    {
      title: 'FACILITY.EDIT_FORM.YEAROFBUILD',
      formGroupName: 'facilityInfo',
      formControlName: 'yearOfBuild',
      type: FacilityInfoFieldTypes.Number,
      min: 1000,
      max: 9000
    }
  ], [
    {
      title: 'FACILITY.EDIT_FORM.GEOGRAPHICAREA',
      formGroupName: 'facilityInfo',
      formControlName: 'geographicalArea',
      type: FacilityInfoFieldTypes.Text
    },
    {
      title: 'FACILITY.EDIT_FORM.ELECTRICITY_TAX_CLASS',
      formGroupName: 'facilityInfo',
      formControlName: 'electricityTaxClass',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'electricityTaxClass'
    }
  ], [
    {
      title: 'FACILITY.EDIT_FORM.COUNTRY',
      formGroupName: 'address',
      formControlName: 'country',
      type: FacilityInfoFieldTypes.Text
    },
    {
      title: 'FACILITY.EDIT_FORM.OWNERSHIP',
      formGroupName: 'facilityInfo',
      formControlName: 'ownership',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'ownershipTypes'
    }
  ], [
    {
      title: 'FACILITY.EDIT_FORM.LOCALE',
      formGroupName: 'facilityInfo',
      formControlName: 'localeId',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'locales',
      validation: Validators.required
    },
    {
      title: 'EXTERNALIDS.EXTERNALORGANIZATIONID',
      formGroupName: 'facilityInfo',
      formControlName: 'externalOrganizationId',
      type: FacilityInfoFieldTypes.Text
    }
  ], [
    {
      title: 'FACILITYINFORMATION.BUSINESSIDENTITYCODE',
      formGroupName: 'facilityInfo',
      formControlName: 'businessIdentityCodeId',
      type: FacilityInfoFieldTypes.Number,
      min: 0
    },
    {
      title: 'EXTERNALIDS.EXTERNALID',
      formGroupName: 'facilityInfo',
      formControlName: 'externalId',
      type: FacilityInfoFieldTypes.Text
    }
  ], [
    {
      title: 'FACILITYINFORMATION.MANAGEMENTRESPONSIBILITY',
      formGroupName: 'facilityInfo',
      formControlName: 'managementResponsibility',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'managementResponsibility'
    }
  ],
  [
    {
      title: 'FACILITY.EDIT_FORM.DESCRIPTION',
      formGroupName: 'facilityInfo',
      formControlName: 'customerSetDescription',
      type: FacilityInfoFieldTypes.TextArea
    },
    {
      title: 'FACILITY.EDIT_FORM.ENEGIA_DESCRIPTION',
      formGroupName: 'facilityInfo',
      formControlName: 'enegiaDescription',
      type: FacilityInfoFieldTypes.TextArea
    }
  ],
  [
    {
      title: 'FACILITYINFORMATION.REPORTINGENDDATE',
      formGroupName: 'facilityInfo',
      formControlName: 'reportingEndDate',
      type: FacilityInfoFieldTypes.Date
    },
  ]
];

export const facilityEditTechnicalSpecsFields: [FacilityEditField, FacilityEditField?][] = [
  [
    {
      title: 'TECHNICALSPECIFICATIONS.PRIMARYVENTILATIONSYSTEM',
      formGroupName: 'technicalSpecifications',
      formControlName: 'primaryVentilationSystem',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'ventilationSystem'
    },
    {
      title: 'TECHNICALSPECIFICATIONS.SECONDARYVENTILATIONSYSTEM',
      formGroupName: 'technicalSpecifications',
      formControlName: 'secondaryVentilationSystem',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'ventilationSystem'
    }
  ], [
    {
      title: 'TECHNICALSPECIFICATIONS.VENTILATIONMETHOD',
      formGroupName: 'technicalSpecifications',
      formControlName: 'ventilationMethod',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'ventilationMethod'
    },
    {
      title: 'TECHNICALSPECIFICATIONS.HEATDISTRIBUTIONSYSTEM',
      formGroupName: 'technicalSpecifications',
      formControlName: 'heatDistributionSystem',
      type: FacilityInfoFieldTypes.Dropdown,
      dropdownDataKey: 'heatDistributionSystem'
    }
  ], [
    {
      title: 'TECHNICALSPECIFICATIONS.AUTOMATIONSYSTEM',
      formGroupName: 'technicalSpecifications',
      formControlName: 'automationSystem',
      type: FacilityInfoFieldTypes.Text
    },
    {
      title: 'TECHNICALSPECIFICATIONS.REFRIGERANT',
      formGroupName: 'technicalSpecifications',
      formControlName: 'refrigerant',
      type: FacilityInfoFieldTypes.Text
    }
  ], [
    {
      title: 'TECHNICALSPECIFICATIONS.EFFICIENCYRATIO',
      formGroupName: 'technicalSpecifications',
      formControlName: 'efficiencyRatio',
      type: FacilityInfoFieldTypes.Number
    }
  ]
];

// There is still plenty of facility stuff in reportingobjects
// If you need to edit them, please consider moving them here
