import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { AggregateResult } from '@progress/kendo-data-query';

import { KendoGridCustomColumnComponent } from '../../../../shared/base-classes/kendo-grid-custom-column.component';
import { ReportingAggregateType, supportedAggregateTypes } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';

@Component({
  selector: 'kendo-grid-quantity-consumption-column',
  templateUrl: './kendo-grid-quantity-consumption-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: ColumnBase,
    useExisting: KendoGridQuantityConsumptionColumnComponent
  }],
})
export class KendoGridQuantityConsumptionColumnComponent extends KendoGridCustomColumnComponent implements OnInit {
  public readonly supportedAggregateTypes = supportedAggregateTypes;

  @Input() public visibleAggregates: readonly ReportingAggregateType[] = supportedAggregateTypes;
  @Input() public fieldStart: string;
  @Input() public color: string;

  @Input() public total: AggregateResult;
  @Input() public modalReportType: ReportType;

  public constructor(
    public el: ElementRef,
    @Optional() @SkipSelf() @Host() parent: ColumnBase
  ) {
    super(parent);
    this.cssClass = 'cell-number';
    this.groupable = false;
    this.includeInChooser = false;
    this.format = '#,#';
    this.filter = 'numeric';
    this.width = 100;
  }

  public ngOnInit(): void {
    this.field = `${this.fieldStart}.visibleValue`;
  }
}
