import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup,
  ValidationErrors, Validator, Validators } from '@angular/forms';
import { from, map, Observable } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';
import { formControlsFrom } from '@enerkey/ts-utils';

import { QuantityService } from '../../../../shared/services/quantity.service';
import { ConsumptionsByFacilityWidgetOptions } from '../consumptions-by-facility-widget/consumptions-by-facility-widget.component';

type ChangeFn = (options: ConsumptionsByFacilityWidgetOptions) => void;

type FormValues = ConsumptionsByFacilityWidgetOptions;

@Component({
  selector: 'consumptions-by-facility-widget-options',
  templateUrl: './consumptions-by-facility-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ConsumptionsByFacilityWidgetOptionsComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => ConsumptionsByFacilityWidgetOptionsComponent),
  }]
})
export class ConsumptionsByFacilityWidgetOptionsComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() public initialState: ConsumptionsByFacilityWidgetOptions;

  public readonly quantities$: Observable<Quantities[]>;

  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public constructor(quantityService: QuantityService) {
    this.quantities$ = from(quantityService.getSignificantQuantitiesForProfile()).pipe(
      map(quantities => quantities.map(q => q.ID))
    );
  }

  public ngOnInit(): void {
    const values = this.optionsToForm(this.initialState);
    const controls = formControlsFrom<FormValues>(
      values,
      Object.keys(values).toRecord(key => key, () => Validators.required)
    );
    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges
      .subscribe((formValue: FormValues) => {
        this._onChange?.(formValue);
      });
  }

  public writeValue(value: ConsumptionsByFacilityWidgetOptions): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.formGroup.invalid) {
      return {
        required: true
      };
    }
    return null;
  }

  private optionsToForm(options: ConsumptionsByFacilityWidgetOptions): FormValues {
    return { ...options };
  }

}
