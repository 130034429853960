import { IAngularStatic } from 'angular';

import KendoModule from '../modules/kendo';
import LodashModule from '../modules/lodash';
import ReportingObjectsServicesModule from '../modules/reportingobjects/services';
import EnerKeyServicesModule from '../services';
import { DownloadController } from './download-controller';

declare const angular: IAngularStatic;

export default angular.module('EnerKey2.Controllers', [
  KendoModule.name,
  LodashModule.name,
  ReportingObjectsServicesModule.name,
  EnerKeyServicesModule.name
])
  .controller('DownloadController', DownloadController)
;
