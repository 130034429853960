import { Pipe, PipeTransform } from '@angular/core';

import { SelectableResolution } from '../../../modules/reporting/components/reporting-search-form/reporting-search-form.component';

import { PeriodLabelService } from '../../../modules/reporting/services/period-label.service';
import { TimeFrameString } from '../../../services/time-frame-service';

@Pipe({
  name: 'resolutionTitle'
})
export class ResolutionTitlePipe implements PipeTransform {
  public constructor(private readonly periodLabelService: PeriodLabelService) {
  }

  public transform(
    date: Date,
    resolution: TimeFrameString | SelectableResolution
  ): string {
    return this.periodLabelService.getChartCategoryLabel({
      timestamps: [date],
      resolution: resolution as SelectableResolution,
    });
  }
}
