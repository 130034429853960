import moment from 'moment';

import { BudgetType, CostFactorDto } from '@enerkey/clients/cost-factor';
import { FieldTypeSelection } from './field-type-selection';
import { Factor } from './factor';
import { SpreadsheetFactor } from './spreadsheet-factor';
import { dateToOle } from '../../../../shared/date.functions';

export function getRelevantFactors(factors: Factor[], selectedField: FieldTypeSelection): SpreadsheetFactor[] {
  return factors.reduce((accumulator, currentValue) => {
    if (selectedField.budgetType !== BudgetType.None) {
      return (currentValue.costFactorType === selectedField.fieldType &&
          'budgetType' in currentValue &&
          currentValue.budgetType === selectedField.budgetType)
        ? accumulator.concat(formatFactorDto(currentValue))
        : accumulator;
    } else {
      return currentValue.costFactorType === selectedField.fieldType
        ? accumulator.concat(formatFactorDto(currentValue))
        : accumulator;
    }
  }, [] as SpreadsheetFactor[]);
}

function formatFactorDto(dto: CostFactorDto): SpreadsheetFactor {
  return {
    meterId: dto.meterId,
    fromDate: dateToOle(moment.utc(dto.fromDate).toDate()),
    monthlyFeePerDay: Number(dto.monthlyFeePerDay),
    price: Number(dto.price)
  };
}
