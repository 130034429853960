<modal-view-header
  [headingText]="'ADMIN.MASS_IMPORT.FACILITIES_FOR_COMPANIES' | translate"
></modal-view-header>
<div class="modal-body">
  <form
    id="companyFacilitiesForm"
    [formGroup]="companyFacilitiesForm"
    (submit)="submit()"
  >
    <company-search
      formControlName="company"
      *labelBefore="'CONTACTMANAGER.COMPANY.NAME' | translate"
    ></company-search>
    <ek-multi
      [items]="availableFunctionsNames"
      formControlName="availableFunctions"
      *labelBefore="'CONTACTMANAGER.COMPANY.AVAILABLEFUNCTIONS' | translate"
      [overflowThreshold]="6"
    ></ek-multi>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="companyFacilitiesForm"
      [disabled]="!companyFacilitiesForm.valid"
      [loading]="loading$ | async"
    >
      {{ 'CONTACTMANAGER.FORM.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'ADMIN.FACILITIES_PROFILES.CANCEL' | translate }}
    </button>
  </div>
</div>
