import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { SharedModule } from '../../shared/shared.module';
import { AddMetersSourceGridComponent } from './components/add-meters-source-grid/add-meters-source-grid.component';
import { AddMetersTargetGridComponent } from './components/add-meters-target-grid/add-meters-target-grid.component';
import { AddMetersComponent } from './components/add-meters/add-meters.component';
import { EditFactorComponent } from './components/edit-factor/edit-factor.component';
import { MeterEditComponent } from './components/meter-edit/meter-edit.component';
import {
  VirtualMeterFormulaEditorComponent
} from './components/virtual-meter-formula-editor/virtual-meter-formula-editor.component';
import { VirtualMeterHistoryComponent } from './components/virtual-meter-history/virtual-meter-history.component';
import { FormulaEditorTreelistDirective } from './formula-editor-treelist.directive';
import { FormulaEditorService } from './services/formula-editor.service';
import { EffectTimeComponent } from './components/effect-time/effect-time.component';
import {
  FormulaEffectDateComponent
} from './components/formula-effect-date/formula-effect-date.component';
import { AddMetersModalComponent } from './components/add-meters-modal/add-meters-modal.component';
import { NgfTabsetModule } from '@enerkey/foundation-angular';
import { MeterInfoComponent } from './components/meter-info/meter-info.component';
import { MeterHistoryComponent } from './components/meter-history/meter-history.component';
import { EkInputsModule } from '../../shared/ek-inputs/ek-inputs.module';
import { WarningIconComponent } from './components/warning-icon/warning-icon.component';
import { AccordionModule } from '@enerkey/foundation-angular';
import { EkKendoModule } from '../../shared/ek-kendo/ek-kendo.module';
import { CommonPipesModule } from '../../shared/common-pipes/common-pipes.module';
import { EkLayoutModule } from '../../shared/ek-layout/ek-layout.module';

@NgModule({
  imports: [
    SharedModule,
    FormsModule,
    GridModule,
    InputsModule,
    DateInputsModule,
    NgfTabsetModule,
    EkInputsModule,
    AccordionModule,
    EkKendoModule,
    CommonPipesModule,
    EkLayoutModule,
  ],
  declarations: [
    MeterEditComponent,
    VirtualMeterHistoryComponent,
    AddMetersComponent,
    AddMetersSourceGridComponent,
    AddMetersTargetGridComponent,
    EditFactorComponent,
    VirtualMeterFormulaEditorComponent,
    FormulaEditorTreelistDirective,
    EffectTimeComponent,
    FormulaEffectDateComponent,
    AddMetersModalComponent,
    MeterInfoComponent,
    MeterHistoryComponent,
    WarningIconComponent,
  ],
  providers: [
    FormulaEditorService
  ],
  exports: [
    MeterEditComponent
  ],
})
export class VirtualMetersModule { }
