import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { combineLatest, Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

import { ofVoid } from '@enerkey/rxjs';

import { LoadingService } from '../../shared/services/loading.service';

@Component({
  selector: 'loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
  public readonly loading$: Observable<boolean>;

  public constructor(
    loadingService: LoadingService,
    @Inject('loadingServiceAjs') loadingServiceAjs: { readonly isLoading$: Observable<boolean> }
  ) {
    this.loading$ = combineLatest([
      loadingService.isLoading$.pipe(startWith(false)),
      loadingServiceAjs.isLoading$.pipe(startWith(false))
    ]).pipe(
      map(([angularLoading, angularJsLoading]) => angularLoading || angularJsLoading),
      distinctUntilChanged(),
      debounce(isLoading => isLoading ? ofVoid() : timer(30)),
      startWith(false),
      shareReplay(1)
    );

    // Debounce by 30ms when hiding loader, sometimes the animation gets janky if multiple
    // requests are chained and the element flickers out and resets animation after each one
  }
}
