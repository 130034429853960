import VirtualMeterEditorAddController from './virtual-meter-editor-add.controller';
import template from 'raw-loader!./virtual-meter-editor-add.html';

export default {
  template,
  controller: VirtualMeterEditorAddController,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    operationSelect: '&'
  }
};
