import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'placeholder-image',
  templateUrl: './placeholder-image.component.html',
  styleUrls: ['./placeholder-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderImageComponent {
  /** Expected width of the lazy loaded image. */
  @Input() public width: number = 400;

  /** Expected width of the lazy loaded image. */
  @Input() public height: number = 400;

  /** Whether to show a large spinner at the middle of the placeholder area. */
  @Input() public loading: boolean = false;
}
