<modal-view-header [headingText]="'FACILITIES_FILTER.FILTER_FACILITIES' | translate"></modal-view-header>
<div class="modal-body">
  <div class="row">
    <div class="medium-12 large-6 large-push-6 columns">
      <h2>{{ 'FACILITIES_FILTER.SAVED_FILTERS' | translate }}</h2>
      <div class="stored-filter-container" *ngFor="let filter of (storedFilters$ | async); let index = index">
        <a (click)="setStoredFilter(filter)">
          {{ filter | facilityFilterDisplayText:(facilityPropertyNames$ | async) }}
        </a>
        <button class="button button--negative button--link button--no-focus icon" (click)="removeFilter(filter)">
          <i class="fa fa-trash-alt"></i>
        </button>
      </div>
      <hr class="hide-for-large" />
    </div>
    <div class="medium-12 large-6 large-pull-6 columns">
      <accordion [formGroup]="filterValueForm">
        <accordion-group
          *ngFor="let group of filterGroups$ | async"
          #accordionGroup
        >
          <div accordion-heading>
            {{ group.name }}
            <ng-container *ngIf="(groupFilterCounts$ | async) as amounts">
              <span *ngIf="amounts[group.property] as amount" class="indicator indicator--info">
                {{ amount }}
              </span>
            </ng-container>
          </div>
          <div *ngIf="accordionGroup.isOpen" [formGroupName]="group.property">
            <ng-container *ngFor="let item of group.items">
              <div [ngSwitch]=item.type>
                <ng-container *ngSwitchCase="'string'">
                  <ek-combo
                    *labelBefore="item.name"
                    [items]="item.selectItems"
                    [formControlName]="item.property"
                    [showDefaultItem]="true"
                    [defaultItemText]="defaultItemText"
                  >
                  </ek-combo>
                </ng-container>
                <div *ngSwitchCase="'number'">
                  <label>
                    {{ item.name }}
                  </label>
                  <div class="filterable-numeric-field" [formGroupName]="item.property">
                    <kendo-numerictextbox
                      [placeholder]="'GRID.FROM' | translate"
                      [format]="'#.##'"
                      [formControlName]="'min'"
                    >
                    </kendo-numerictextbox>
                    <span [style.marginTop]="'3px'">&nbsp;-&nbsp;</span>
                    <kendo-numerictextbox
                      [placeholder]="'GRID.TO' | translate"
                      [format]="'#.##'"
                      [formControlName]="'max'"
                    >
                    </kendo-numerictextbox>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </accordion-group>
      </accordion>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="button" (click)="filterBySelected(true)" [disabled]="!(isSaveEnabled$ | async)">
    {{ 'WIDGET.OK_SAVE' | translate }}
  </button>
  <button class="button" (click)="filterBySelected()">
    {{ 'OK' | translate }}
  </button>
  <button class="button button--secondary" (click)="dismissModal()">
    {{ 'CANCEL' | translate }}
  </button>
  <button class="button button--secondary" (click)="clearFilters()">
    {{ 'FACILITIES_FILTER.CLEAR' | translate }}
  </button>
</div>
