import EmVisualsController from './em-visuals-controller';
import emVisualsTemplate from 'raw-loader!./em-visuals.html';

export default {
  template: emVisualsTemplate,
  controller: EmVisualsController,
  controllerAs: 'vm',
  bindings: {
    data: '<',
    groups: '<',
    columns: '<',
    aggregates: '<',
  }
};
