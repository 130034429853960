import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, share, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { MeteringClient, VirtualMeter } from '@enerkey/clients/metering';
import { ToDatePipe } from '../../../../shared/common-pipes/to-date.pipe';
import { DialogService } from '../../../../shared/services/dialog.service';
import {
  MeterManagementClient, MeterManagementMeter
} from '@enerkey/clients/meter-management';
import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { Helpers } from '@enerkey/ts-utils';
import {
  VirtualMeterFormulaEditorComponent
} from '../virtual-meter-formula-editor/virtual-meter-formula-editor.component';
import { ModalService } from '@enerkey/foundation-angular';
import { MeterUpdateProperties } from '../../shared/meter-update-properties';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'meter-edit',
  templateUrl: './meter-edit.component.html',
  styleUrls: ['./meter-edit.component.scss']
})
export class MeterEditComponent implements OnInit, OnDestroy {
  @Input() public meter: MeterManagementMeter;
  @Input() public meterUpdateProperties: MeterUpdateProperties;

  public virtualMeter: Readonly<VirtualMeter> = new VirtualMeter();
  public readonly history$: Observable<VirtualMeter[]>;
  public loading = false;
  public facility$: Observable<Facility>;

  private readonly refreshToken$ = new BehaviorSubject(undefined);
  private cancelSubscription = new Subject<void>();

  public constructor(
    private meteringClient: MeteringClient,
    private meterManagementClient: MeterManagementClient,
    private facilityClient: FacilityClient,
    private modalService: ModalService,
    private translate: TranslateService,
    private toDatePipe: ToDatePipe,
    private dialogService: DialogService,
    private toasterService: ToasterService,
    private injector: Injector
  ) {
    this.history$ = this.refreshToken$.pipe(
      tap(() => {
        this.loading = true;
      }),
      switchMap(() => this.getVirtualMeterInfoRequest()),
      share()
    );
  }

  public ngOnInit(): void {
    this.getMetersEnegiaId();
  }

  public ngOnDestroy(): void {
    this.cancelSubscription.next();
    this.cancelSubscription.unsubscribe();
  }

  public openModal(formula?: VirtualMeter): void {
    this.facility$.subscribe(facility => {
      const virtualMeterEditorModalRef = this.modalService.open(
        VirtualMeterFormulaEditorComponent,
        { injector: this.injector, size: 'full' }
      );
      const virtualMeterEditorComponent = virtualMeterEditorModalRef.componentInstance;

      virtualMeterEditorComponent.isNewFormula = !formula;
      virtualMeterEditorComponent.resultMeterId = this.meter.id;
      virtualMeterEditorComponent.meterName = this.meter.name;
      virtualMeterEditorComponent.virtualMeterId = this.getEditedFormulaId(formula);
      virtualMeterEditorComponent.facility = facility;

      virtualMeterEditorModalRef.result.finally(() => {
        this.refreshToken$.next(undefined);
      });
    });
  }

  public deleteFormula(formula: VirtualMeter): void {
    this.dialogService.getConfirmationModal({
      text: this.translate.instant('ADMIN.VIRTUAL_METERS.HISTORY_ITEM_DELETE.TEXT'),
      title: this.translate.instant('ADMIN.VIRTUAL_METERS.HISTORY_ITEM_DELETE.TITLE'),
      isDelete: true
    }).subscribe(
      () => {
        this.deleteHistoryItem(formula);
      },
      Helpers.noop
    );
  }

  private getEditedFormulaId(formula?: VirtualMeter): number {
    return formula ? formula.id : this.virtualMeter.id;
  }

  private getVirtualMeterInfoRequest(): Observable<VirtualMeter[]> {
    return this.meteringClient.getVirtualMeterHistory(this.meter.id)
      .pipe(
        catchError(error => {
          if (!(error && error.status === 404)) {
            this.toasterService.error('ADMIN.VIRTUAL_METERS.HISTORY.LOADING_FAILED');
          }
          this.virtualMeter = new VirtualMeter();
          return of([]);
        }),
        finalize(() => {
          this.loading = false;
        }),
        map(this.sortHistoryByFromDate.bind(this)),
        tap((history: VirtualMeter[]) => {
          if (history.length > 0) {
            this.virtualMeter = history[0];
          }
        })
      );
  }

  private sortHistoryByFromDate(history: VirtualMeter[]): VirtualMeter[] {
    history.sort(
      (item1: VirtualMeter, item2: VirtualMeter) => item2.from && item2.from ?
        this.toDatePipe.transform(item2.from).getTime() - this.toDatePipe.transform(item1.from).getTime() :
        0
    );
    return history;
  }

  private deleteHistoryItem(formula: VirtualMeter): void {
    this.loading = true;
    this.meteringClient.deleteVirtualMeter(formula.id)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        () => {
          this.refreshToken$.next(undefined);
        },
        () => {
          this.toasterService.error('ADMIN.VIRTUAL_METERS.HISTORY.ITEM_DELETION_FAILED');
        }
      );
  }

  private getMetersEnegiaId(): void {
    this.facility$ = this.meterManagementClient.getMetersById([this.meter.id]).pipe(
      switchMap(params => this.facilityClient.getFacilities([parseInt(Object.keys(params)[0])])),
      catchError(() => of(undefined)),
      shareReplay({
        bufferSize: 1,
        refCount: true
      }),
      map((facilities: Facility[]) => facilities[0]),
      takeUntil(this.cancelSubscription)
    );
  }
}
