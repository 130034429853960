import moment from 'moment';

import templateModule from 'raw-loader!../templates/mrc-meter-readings.html';

export function mrcMeterReadings() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      options: "=",
      refresh: "=",
      mrcState: "=",
      facility: "="
    },
    controller: [
      '$scope', '$filter', 'utils', 'mrcapi', 'mrcModals', 'mrcDataParser', '_',
      'MrcFilterService', 'MrcConstants',
      function(
        $scope, $filter, utils, mrcapi, mrcModals, mrcDataParser, _,
        MrcFilterService, MrcConstants
      ) {
        $scope.MrcConstants = MrcConstants;
        $scope.dateFormat = 'dd.MM.yyyy';
        $scope.loadingReadings = false;
        var newItem = {
          readingDate: new Date(),
          notValid: false,
          type1: null
        };

        $scope.lastIndicatorDate = undefined;

        // MRC-379
        $scope.onDateChange = function (event, row) {
          row.newItem.readingDate = event.sender.value();
          if(isLastReadingsRow(row)) {
            $scope.lastIndicatorDate = row.newItem.readingDate;
          }
        };

        var loadedAndGroupedReadings = [];

        function getRowIndex(row) {
          var rowIdx = -1;
          if (row.automatic) {
            rowIdx = _.findIndex(loadedAndGroupedReadings, { Date: row.Date, automatic: true });
          } else {
            rowIdx = _.findIndex(loadedAndGroupedReadings, { Date: row.Date, Flags: row.Flags, indicator: false });
          }
          return rowIdx;
        }

        function isLastReadingsRow(row) {
          var isLast = false;
          var rowIdx = getRowIndex(row) + 1;
          if(rowIdx < loadedAndGroupedReadings.length) {
            isLast = _.isBoolean(loadedAndGroupedReadings[rowIdx].lastIndicator) && loadedAndGroupedReadings[rowIdx].lastIndicator;
          }
          return isLast;
        }

        function filterReadingsForView() {
          if ($scope.paging.pages.length > 0) {
            var pageItems = [];
            _.each(angular.copy(loadedAndGroupedReadings), function (item) {
              if (item.automatic && !item.Date) {
                pageItems.push(item);
                return;
              }
              var itemDate = new Date(item.Date);
              if (itemDate.getFullYear() === $scope.paging.selected) {
                if(item.lastIndicator) {
                  $scope.lastIndicatorDate = item.Date;
                }
                pageItems.push(item);
              }
            });

            $scope.meterReadings = pageItems;
          } else {
            $scope.meterReadings = loadedAndGroupedReadings;
          }
        }

        var initialPagingFilter = new Date();
        $scope.paging = {
          selected: initialPagingFilter.getFullYear(),
          pages: [],
          filter: filterReadingsForView
        };

        function initFilters() {
          if (loadedAndGroupedReadings.length > 0) {
            $scope.paging.pages =  MrcFilterService.getSortedYearsArray(loadedAndGroupedReadings);
          }

          if ($scope.paging.pages.length > 0) {
            var historyPage = $scope.facility.readingsHistoryPages[$scope.options.meter.Id];
            if(historyPage!==undefined && historyPage >= 0 && _.includes($scope.paging.pages, historyPage)) {
              $scope.paging.selected = historyPage;
            }
            else {
              $scope.paging.selected = angular.copy($scope.paging.pages[$scope.paging.pages.length - 1]);
            }
          }

          filterReadingsForView();
        }

        function initReadings() {
          $scope.loadingReadings = true;
          mrcapi.getMeterReadings($scope.options.facilityId, $scope.options.meter.Id).then(
            function (res) {
              res = addAutomaticReadingStartRow(res);
              mrcDataParser.parseMeterReadingsForListing(res, $scope.options.meter.MeteringType).then(
                function (parsedData) {
                  loadedAndGroupedReadings = parsedData;
                  initFilters();
                }
              );
            },
            function () {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_METER_READINGS', true);
            }
          ).finally(function() {
            $scope.loadingReadings = false;
          });
        }

        function addAutomaticReadingStartRow(data) {
          if ($scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.HOURLY) {
            var date = null;
            if ($scope.options.meter.AutomaticReadingStartTime) {
              date = $scope.options.meter.AutomaticReadingStartTime;
            }
            var automaticStartRow = {
              Date: date,
              Flags: 0,
              Type: 1,
              automatic: true
            };
            data.push(automaticStartRow);
          }
          return data;
        }

        initReadings();

        $scope.$on('meterDataUpdated', function () {
          initReadings();
        });

        $scope.editDisabled = function(row) {
          var disabled = false;
          if(row!==undefined && row.Date!==undefined) {
            var d = new Date(row.Date);
            disabled = d.getTime() < $scope.mrcState.editableLimitDate.getTime();
          }
          return disabled;
        }
        $scope.addingDisabled = function(row) {
          var disabled = false;
          if(row!==undefined && row.Date!==undefined) {
            var d = new Date(row.Date);
            disabled = d.getTime() <= $scope.mrcState.editableLimitDate.getTime();
          }
          return disabled;
        }

        $scope.edit = function (row) {
          var indxInList = getRowIndex(row);

          if (angular.isUndefined(row.TypesBackUp) || row.TypesBackUp === null) {
            row.TypesBackUp = angular.copy(row.Types);
          }
          if (indxInList > 0) {
            var nextReadings = loadedAndGroupedReadings[indxInList - 1];
            if(!nextReadings.indicator) {
              _.each(row.Types, function (item) {
                // next reading type x value
                var nxtValue = _.find(nextReadings.Types, { Type: item.Type });
                item.maxValue = nxtValue ? nxtValue.Value : null;
              });
            }
            else {
              _.each(row.Types, function (item) {
                item.maxValue = null;
              });
            }
          } else {
            _.each(row.Types, function (item) {
              item.maxValue = null;
            });
          }

          if ($scope.canAddAfter(row)) {
            // previous reading type x value
            var previousReadings = loadedAndGroupedReadings[indxInList + 1];
            if (previousReadings && !previousReadings.indicator) {
              _.each(row.Types, function (item) {
                var prValue = _.find(previousReadings.Types, { Type: item.Type });
                item.minValue = prValue ? prValue.Value : 0;
              });
            } else {
              _.each(row.Types, function (item) {
                item.minValue = 0;
              });
            }

          } else {
            _.each(row.Types, function (item) {
              item.minValue = 0;
            });
          }
          row.inEdit = true;
        };

        $scope.cancelEdit = function (row) {
          if (angular.isDefined(row.TypesBackUp)) {
            row.Types = angular.copy(row.TypesBackUp);
          }

          row.notValid = false;

          row.inEdit = false;
        };

        $scope.save = function (row) {
          if (validateEditedRow(row)) {
            var readings = _.map(row.Types, function (reading) {
              return { Date: reading.Date, Flags: reading.Flags, Type: reading.Type, Value: mrcDataParser.convertStringToFloat(reading.Value) };
            });
            mrcapi.updateReading($scope.options.facilityId, $scope.options.meter.Id, readings).then(
              function () {
                if (angular.isDefined(row.TypesBackUp)) {
                  row.TypesBackUp = null;
                }

                row.notValid = false;

                row.inEdit = false;

                $scope.refresh();
              },
              function () {
                row.notValid = true;

                utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_UPDATING_READING', true);
              }
            );
          } else {
            row.notValid = true;
          }
        };

        $scope.delete = function (row) {
          if (row.Flags === 0 || row.firstItem) {
            //show warnings about possible meter type change when deleting readings
            var nextIdx = getRowIndex(row) + 1;
            var confirmationTxt = "";
            if(row.indicator) {
              confirmationTxt = $filter('translate')('MRC.READINGS.CONFIRM_METER_TYPE_CHANGE_TO_READING');
            }
            else if(row.Flags == 1 && nextIdx < loadedAndGroupedReadings.length && loadedAndGroupedReadings[nextIdx].indicator && !loadedAndGroupedReadings[nextIdx].lastIndicator) {
              confirmationTxt = $filter('translate')('MRC.READINGS.CONFIRM_METER_TYPE_CHANGE_TO_CONSUMPTION');
            }
            else {
              confirmationTxt = $filter('translate')('MRC.READINGS.CONFIRM_DELETE');
            }
            mrcModals.showConfirmationModal(confirmationTxt).then(
              function () {
                var options = {
                  reportingObjectId: $scope.options.facilityId,
                  meterId: $scope.options.meter.Id,
                  readingDate: row.Date
                };
                $scope.options.meter.blockingOperationInProgress = true;
                mrcapi.deleteMeterReading(options).then(
                  function () {
                    $scope.refresh();
                  },
                  function () {
                    utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_DELETING_METER_READING', true);
                    $scope.options.meter.blockingOperationInProgress = false;
                  }
                );
              },
              function () {}
            );
          } else {
            utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FORBIDDEN_ACTION', true);
          }
        };

        $scope.addItem = function (row) {

          if(row.Types.length>1) {
            newItem['type2'] = null;
          }

          row.newItem = angular.copy(newItem);

          var maxDate = moment.utc(angular.copy(row.Date)).subtract(1, 'days');
          row.newItem.readingDate = maxDate.toDate();
          row.newItem.maxDate = maxDate.toDate();

          var indxInList = getRowIndex(row);
          var nextReadings = loadedAndGroupedReadings[indxInList + 1];

          row.newItem.typeAmount = 0;

          var minDate = nextReadings.lastIndicator ? moment.utc($scope.mrcState.editableLimitDate) : moment.utc(angular.copy(nextReadings.Date)).add(1, 'days');
          row.newItem.minDate = minDate.toDate();
          _.each(row.Types, function(item) {
            row.newItem['type' + item.Type] = angular.copy(item.Value);
            row.newItem.typeAmount++;
            row.newItem['type' + item.Type + 'Max'] = angular.copy(item.Value);
            if (nextReadings.lastIndicator) {
              row.newItem['type' + item.Type + 'Min'] = 0;
              $scope.lastIndicatorDate = row.newItem.readingDate;
            }
          });

          if(!nextReadings.lastIndicator) {
            _.each(nextReadings.Types, function (item) {
              row.newItem['type' + item.Type + 'Min'] = angular.copy(item.Value);
            });
          }
          row.addNewItem = true;
        };

        $scope.saveNewItem = function (row) {
          if (validateNewReading(row)) {
            var readings = [];
            var readingDate = moment(row.newItem.readingDate).format('YYYY-MM-DD\THH:mm:ss');

            for (var i = 1; i <= row.newItem.typeAmount; i++) {
              var value = mrcDataParser.convertStringToFloat(row.newItem['type' + i]);
              readings.push({ "Type": i, "Value": value, "Date": readingDate, Flags: 0 });
            }

            mrcapi.addToHistory($scope.options.facilityId, $scope.options.meter.Id, readings).then(
              function () {
                row.addNewItem = false;
                row.newItem = null;
                $scope.refresh();
              },
              function () {
                utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_SAVING_MEASUREMENTS', true);
              }
            );
          } else {
            row.newItem.notValid = true;
          }
        };

        $scope.cancelAdd = function (row) {
          if(isLastReadingsRow(row)) {
            $scope.lastIndicatorDate = row.Date;
          }
          row.addNewItem = false;
          row.newItem = null;
        };

        $scope.canAddAfter = function (row, nextRow) {
          if(angular.isDefined(nextRow) && _.isBoolean(nextRow.lastIndicator) && nextRow.lastIndicator) {
            return true;
          }
          if(row.Flags !== 1) {
            var limit = moment($scope.mrcState.editableLimitDate).add(2, 'days').toDate();
            var inLimits = moment(row.Date).isSameOrAfter(limit);
            if(row.Date !== undefined && inLimits) {
              if(nextRow!==undefined && nextRow.Date!==undefined) {
                var dayInMs = 86400000;
                var difference = Math.abs(new Date(row.Date).getTime() - new Date(nextRow.Date).getTime());
                return difference > dayInMs;
              }
              else {
                return true;
              }
            }
            else {
              return false;
            }
          }
          else {
            return false;
          }
        };

        function validateNewReading(row) {
          var ni = row.newItem;
          if (ni.readingDate
            && ni.minDate <= ni.readingDate
            && ni.readingDate <= ni.maxDate) {
            var valuesValid = true;
            for (var i = 1; i < row.newItem.typeAmount; i++) {
              if (ni['type' + i]) {
                var targetValue = mrcDataParser.convertStringToFloat(ni['type' + i]);
                if (ni['type' + i + 'Min'] <= targetValue) {
                  var max = ni['type' + i + 'Max'];
                  // Don't check agains max value if it is not defined (for automatic reading start row)
                  if (angular.isDefined(max) && max !== null && targetValue > max) {
                    valuesValid = false;
                  }
                  // This value is valid
                } else {
                  valuesValid = false;
                }
              } else {
                valuesValid = false;
              }
            }

            return valuesValid;
          } else {
            return false;
          }
        }

        function validateEditedRow(row) {
          var isValid = true;

          _.each(row.Types, function (item) {
            if (angular.isDefined(item.Value) && item.Value !== null ) {
              var targetValue = mrcDataParser.convertStringToFloat(item.Value);

              if (targetValue >= 0) {
                if (item.Flags === 2) {
                  if ((item.minValue && item.minValue > targetValue)) {
                    isValid = false;
                  }
                } else if (item.Flags === 1) {
                  if ((item.maxValue && item.maxValue < targetValue)) {
                    isValid = false;
                  }
                } else {
                  if ((item.minValue && item.minValue > targetValue)
                    || (item.maxValue && item.maxValue < targetValue)) {
                    isValid = false;
                  }
                }
              } else {
                isValid = false;
              }
            } else {
              isValid = false;
            }
          });

          return isValid;
        }

        $scope.getMaxValue = function (type) {
          return type.maxValue && type.maxValue > 0 && type.maxValue > type.minValue ? type.maxValue : undefined;
        }
      }
    ]
  };
}
