import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  combineLatest,
  map,
  Observable,
  shareReplay,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs';

import { indicate } from '@enerkey/rxjs';

import { FacilityService } from '../../../../shared/services/facility.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportingSeriesByFacility } from '../../shared/reporting-series-collection';
import { ReportingGridConfig } from '../report-base';
import { ReportModalMetersService } from '../../services/report-modal-meters.service';
import { PeriodMeterReportService } from '../../services/period-meter-report.service';
import { MeterReportBase } from '../meter-report-base';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingGridService } from '../../services/reporting-grid.service';
import { ReportType } from '../../shared/report-type';

@Component({
  selector: 'period-meter-report',
  templateUrl: '../meter-report-base.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeriodMeterReportComponent extends MeterReportBase {
  public readonly consumptionData$: Observable<ReportingSeriesByFacility>;
  public readonly gridConfig$: Observable<Record<number, ReportingGridConfig>>;

  protected readonly reportType = ReportType.Period;
  protected override readonly allowZoom = true;

  public constructor(
    private readonly periodMeterReportService: PeriodMeterReportService,
    private readonly reportingGridService: ReportingGridService,
    reportModalMetersService: ReportModalMetersService,
    thresholdService: ThresholdService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    super(reportModalMetersService, facilityService, reportingSearchService, reportEventService);

    this.consumptionData$ = combineLatest({
      params: this.searchParams$,
      threshold: thresholdService.threshold$,
      meters: this.debouncedSelectedMeters$
    }).pipe(
      withLatestFrom(this.facilityIds$),
      switchMap(([{ params, threshold, meters }, facilityIds]) => this.periodMeterReportService.getData(
        params, facilityIds[0], meters, threshold
      ).pipe(
        indicate(this._loading$)
      )),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.gridConfig$ = this.consumptionData$.pipe(
      withLatestFrom(this.selectedMeters$, this.searchParams$),
      map(([data, meters, searchParams]) => {
        const meterIds = meters.meterIds;
        const gridColumns = this.reportingGridService.getColumns(data, meterIds);
        const gridData = this.reportingGridService.getData(data, searchParams, meterIds);
        const aggregates = this.reportingGridService.getAggregates(meterIds, gridColumns, gridData, ReportType.Period);
        return meterIds.toRecord(
          mId => mId,
          mId => ({
            data: gridData[mId],
            columns: gridColumns[mId]?.filter(c => c?.series && c.series?.length),
            aggregates: aggregates[mId]
          })
        );
      }),
      shareReplay(1)
    );
  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return params;
  }
}
