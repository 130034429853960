import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { OperationResult } from '@enerkey/clients/provisioning';

@Component({
  selector: 'provisioning-operation-status-icon',
  templateUrl: './provisioning-operation-status-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvisioningOperationStatusIconComponent {
  public readonly OperationResult = OperationResult;

  @Input() public status: OperationResult;
}
