export enum Roles {
  HAS_ACCESS_TO_ALL_PROFILES = 'HasAccessToAllProfiles',
  READINGS_IMPORT = 'ReadingsImport',
  USER_ADMINSTRATOR = 'UserAdministrator',
  DOCUMENT_READ = 'DocumentRead',
  DOCUMENT_WRITE = 'DocumentWrite',
  ACTION_READ = 'ActionRead',
  ACTION_WRITE = 'ActionWrite',
  METER_MANAGER_USER = 'MeterManagerUser',
  FACILITY_UPDATE = 'FacilityUpdate',
  FACILITY_CREATE_DELETE = 'FacilityCreateDelete',
  MIGRATOR = 'Migrator',
  DATA_API_KEY_MANAGER = 'DataAPIKeyManager',
  FACILITY_IMPORT = 'FacilityImport',
  VIRTUAL_METER_MANAGER_USER = 'VirtualMeterManagerUser',
  CONTACT_MANAGER = 'ContactManager',
  ALARM_MANAGER = 'AlarmManager',
  CONTRACT_MANAGER = 'ContractManager',
  COSTREADER = 'CostReader',
  COSTWRITER = 'CostWriter',
  TENANT_MANAGER = 'TenantManager',
  ENERGY_MANAGER = 'EnergyManager',
  QUALITY_MANAGER = 'QualityManager',
  CONFIGURATION_MANAGER = 'ConfigurationManager',
  AZURE_MANAGER = 'AzureManager',
  TOOL_MANAGER = 'ToolManager',
  PROVISIONING_MANAGER = 'ProvisioningManager',
  WEBHOOK_MANAGER = 'WebhookManager',
  NEWS_REPORTER = 'NewsReporter',
  SUPERVISOR = 'Supervisor',
  POAMANAGER = 'PoAManager',
  INES_MANAGER ='InesManager',
  SUSTAINABILITY_MANAGER = 'SustainabilityManager'
}
