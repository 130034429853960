import { Pipe, PipeTransform } from '@angular/core';
import { SeriesStack } from '@progress/kendo-angular-charts';

import { ReportingSeries } from '../shared/reporting-series';

@Pipe({
  name: 'chartSeriesStackGroup'
})
export class ChartSeriesStackGroupPipe implements PipeTransform {
  public transform(serie: ReportingSeries, allSeries: ReportingSeries[], index: number): false | SeriesStack {
    if (!allSeries.some(s => s.chartOptions.stackGroup && !s.chartOptions.hideInChart)) {
      return false;
    }
    if (serie.chartOptions.stackGroup) {
      return { group: serie.chartOptions.stackGroup };
    }
    return serie.chartOptions.serieType === 'column'
      ? { group: `group${index}` }
      : false;
  }
}
