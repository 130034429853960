
import { ChangeDetectionStrategy, Component, Input, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageComponent {

  @Input() public control: NgControl;

  public constructor(
    public readonly vcRef: ViewContainerRef
  ) { }
}
