import { Utils } from '../../../services/utils';
import { $InjectArgs } from '@enerkey/ts-utils';
import { MeterItem } from '@enerkey/clients/energy-reporting';

type ExtraColumn = kendo.ui.GridColumn & { toggleable: boolean };

type DetailItem = {
  title: string;
  variable: string;
  isDate?: boolean;
} | {
  title: string;
  range: [string, string];
}

export class MetersTreeListService {
  protected static readonly $inject: $InjectArgs<typeof MetersTreeListService> = ['utils'];

  public constructor(
    private readonly utils: Utils
  ) { }

  public getExtraColumns(): readonly ExtraColumn[] {
    return [
      {
        field: 'Description',
        width: 150,
        title: this.utils.localizedString('FACILITIES_REPORT.DESCRIPTION'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'MeteringType$Name',
        width: 150,
        title: this.utils.localizedString('FACILITIES_REPORT.METERINGTYPE'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'Factor',
        width: 150,
        title: this.utils.localizedString('FACILITIES_REPORT.METERINGFACTOR'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'Id',
        width: 150,
        title: this.utils.localizedString('METERS.ENEGIA_METER_ID'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'Name',
        width: 150,
        title: this.utils.localizedString('METERS.METERS'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'CustomerMeterIdentifier',
        width: 150,
        title: this.utils.localizedString('FACILITIES_REPORT.CUSTOMERMETERIDENTIFIER'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'MeasurementMethod',
        width: 150,
        title: this.utils.localizedString('METERS.MEASUREMENT_METHOD.TITLE'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'Level$Number',
        width: 100,
        title: this.utils.localizedString('FACILITIES_REPORT.LEVEL_NUMBER'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'Level$Parent',
        width: 150,
        title: this.utils.localizedString('FACILITIES_REPORT.LEVEL_PARENT'),
        toggleable: true,
        hidden: true
      },
      {
        field: 'AutomaticReadingStartTime',
        width: 120,
        title: this.utils.localizedString('AUTOMATIC_READING_START_TIME'),
        template: (dataItem: MeterItem) => this.dateFormat(dataItem, 'AutomaticReadingStartTime'),
        toggleable: true,
        hidden: true,
        filterable: false,
      },
      {
        field: 'AutomaticReadingEndTime',
        width: 120,
        title: this.utils.localizedString('AUTOMATIC_READING_END_TIME'),
        template: (dataItem: MeterItem) => this.dateFormat(dataItem, 'AutomaticReadingEndTime'),
        toggleable: true,
        hidden: true,
        filterable: false,
      },
      {
        field: 'DeactivationTime',
        width: 120,
        title: this.utils.localizedString('DEACTIVATION_TIME'),
        template: (dataItem: MeterItem) => this.dateFormat(dataItem, 'DeactivationTime'),
        toggleable: true,
        hidden: true,
        filterable: false,
      },
    ];
  }

  public getDetailsListItems(): readonly DetailItem[] {
    return [
      {
        title: 'FACILITIES_REPORT.DESCRIPTION',
        variable: 'Description'
      },
      {
        title: 'FACILITIES_REPORT.METERINGTYPE',
        variable: 'MeteringType.Name'
      },
      {
        title: 'FACILITIES_REPORT.METERINGFACTOR',
        variable: 'Factor'
      },
      {
        title: 'METERS.ENEGIA_METER_ID',
        variable: 'Id'
      },
      {
        title: 'FACILITIES_REPORT.CUSTOMERMETERIDENTIFIER',
        variable: 'CustomerMeterIdentifier'
      },
      {
        title: 'METERS.MEASUREMENT_METHOD.TITLE',
        variable: 'MeasurementMethod'
      },
      {
        title: 'AUTOMATIC_READING',
        range: ['AutomaticReadingStartTime', 'AutomaticReadingEndTime']
      },
      {
        title: 'DEACTIVATION_TIME',
        variable: 'DeactivationTime',
        isDate: true,
      },
    ];
  }

  private dateFormat(dataItem: MeterItem, field: keyof MeterItem): string {
    const value = dataItem[field];

    if (!value) {
      return '';
    }

    // TODO: fix after ER types changed to classes, dates are moment instead of string then
    return kendo.format('{0:d}', kendo.parseDate(value.toString()));
  }
}
