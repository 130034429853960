import { RouteAuthLogic, UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const powerOfAttorneyTabState: UIStateNg2 = {
  name: 'powerOfAttorney',
  data: {
    translationKey: 'ADMIN.TABS.POA',
    auth: {
      roles: [Roles.METER_MANAGER_USER, Roles.POAMANAGER],
      roleLogic: RouteAuthLogic.Any,
    }
  },
};
