import { StateProvider } from '@uirouter/angularjs';

import { Roles } from './constants/roles';
import { EnerkeyNg1StateDeclaration, getUIState, RouteAuthLogic } from '../../shared/routing';
import { adminTabStates } from '../../shared/admin-shared/components/admin-tabs/admin-tabs.component';
import { UserService } from '../../services/user-service';

config.$inject = ['$translatePartialLoaderProvider', '$stateProvider'];

const adminState: EnerkeyNg1StateDeclaration = {
  name: 'admin',
  parent: 'index',
  url: '/admin',
  data: {
    auth: {
      roles: [
        Roles.USER_ADMINSTRATOR,
        Roles.METER_MANAGER_USER,
        Roles.ALARM_MANAGER,
        Roles.POAMANAGER,
      ],
      roleLogic: RouteAuthLogic.Any
    },
    translationKey: 'ADMIN.ADMIN'
  },
  redirectTo: transition => {
    const userService: UserService = transition.injector().get('UserService');
    for (const state of adminTabStates) {
      if (userService.hasAccess(state.data?.auth)) {
        return state.name;
      }
    }
    return 'admin';
  }
};

const adminTopbarState = getUIState(adminState);

export { adminTopbarState };

const adminStates: { [key: string]: EnerkeyNg1StateDeclaration } = {
  admin: adminState
};

function config(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  $translatePartialLoaderProvider: any,
  $stateProvider: StateProvider
): void {
  // translates
  $translatePartialLoaderProvider.addPart('admin');
  $translatePartialLoaderProvider.addPart('contact-manager');

  Object.keys(adminStates).forEach(stateName => {
    $stateProvider.state(stateName, adminStates[stateName]);
  });
}

export default config;
