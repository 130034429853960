import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { getStringEnumValues } from '@enerkey/ts-utils';

import { EkComboComponent } from '../ek-combo/ek-combo.component';

export enum ValueType {
  Measured = 'Measured',
  Normalized = 'Normalized',
  Both = 'Both'
}

const valueTypeComboTranslations: Record<ValueType, string> = {
  [ValueType.Measured]: 'FACILITIES_TOOLBAR.MEASURED',
  [ValueType.Normalized]: 'FACILITIES_TOOLBAR.NORMALIZED',
  [ValueType.Both]: 'FACILITIES_TOOLBAR.BOTH',
};

@Component({
  selector: 'value-type-select',
  templateUrl: '../ek-combo/ek-combo.component.html',
  styleUrls: ['../ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ValueTypeSelectComponent),
    multi: true
  }]
})
export class ValueTypeSelectComponent extends EkComboComponent<ValueType> implements OnInit {
  @Input() public hideBothSelection = false;

  public override ngOnInit(): void {
    this.items = getStringEnumValues(ValueType)
      .filter(
        valueType => !(this.hideBothSelection && valueType === ValueType.Both)
      )
      .map(value => ({
        value,
        text: valueTypeComboTranslations[value]
      }));

    super.ngOnInit();
  }
}
