import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { getStringEnumValues } from '@enerkey/ts-utils';

import { EkComboComponent } from '../ek-combo/ek-combo.component';

export enum Comparability {
  All = 'None',
  ByQuantity = 'ByQuantity',
  ByQuantities= 'ByQuantities'
}

const comparabilityComboTranslations: Record<Comparability, string> = {
  [Comparability.All]: 'FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.NONE',
  [Comparability.ByQuantity]: 'FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.BY_QUANTITY',
  [Comparability.ByQuantities]: 'FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.BY_QUANTITIES'
};

@Component({
  selector: 'comparability-select',
  templateUrl: '../ek-combo/ek-combo.component.html',
  styleUrls: ['../ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComparabilitySelectComponent),
    multi: true
  }]
})
export class ComparabilitySelectComponent extends EkComboComponent<Comparability> {
  public override items = getStringEnumValues(Comparability).map(option => ({
    value: option,
    text: comparabilityComboTranslations[option]
  }));
}
