import EnerKeyServicesModule from '../../../services';
import EnergyManagementModelsModule from '../models';
import ReportingObjectsServicesModule from '../../reportingobjects/services';

import { ConsumptionTargetSeriesTypes } from './consumption-target-series-types';
import { emActionsFieldService } from './em-action-fields-service';
import { EmService } from './em.service';

export default angular.module('EnergyManagement.Services', [
  'ui.router',
  EnerKeyServicesModule.name,
  EnergyManagementModelsModule.name,
  ReportingObjectsServicesModule.name
])
  .service('ConsumptionTargetSeriesTypes', ConsumptionTargetSeriesTypes)
  .factory('emActionsFieldService', emActionsFieldService)
  .service('emService', EmService)
;
