import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MeterManagementMeter } from '@enerkey/clients/meter-management';

@Component({
  selector: 'sum-report-meters-card',
  templateUrl: './sum-report-meters-card.component.html',
  styleUrls: ['./sum-report-meters-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumReportMetersCardComponent {
  @Input() public meters: MeterManagementMeter[];
}
