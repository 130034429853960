import { cloneDeep } from 'lodash';
import { Injectable } from '@angular/core';
import { StateService, Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { isSameDay, startOfDay } from 'date-fns';
import {
  CompositeFilterDescriptor,
  GroupDescriptor,
  SortDescriptor
} from '@progress/kendo-data-query';
import { ColumnComponent } from '@progress/kendo-angular-treelist';

import {
  Bookmark,
  CreateBookmark,
  CreateReportingBookmark,
  GridFilter,
  GridState,
  Grouping,
  ReportingBookmark,
  ReportType,
  RollingDateType,
  SettingsClient,
  Sort,
  VisibleSections
} from '@enerkey/clients/settings';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalService } from '@enerkey/foundation-angular';

import { TimeFrame } from './time-frame-service';
import { ProfileService } from '../shared/services/profile.service';
import { UserService } from './user-service';
import {
  getBookmarkFromParams,
  getBookmarkReportState,
  getFilterDescriptorsRecursive,
  getReportingParams,
  mapGridStateForBookmark,
  previousPeriodStart
} from '../components/bookmarks-modal/reporting-bookmark.functions';
import { ReportingParams } from '../modules/reporting/reporting.states';
import { reportingBookmarkStateType, ReportName, staticReportTypes } from '../constants/reporting-bookmark.constants';
import { QuantityOrMeterReport } from '../modules/reporting/services/report-modal.service';
import { TableReportService } from '../modules/reporting/services/table-report.service';
import { MeterTableReportService } from '../modules/reporting/services/meter-table-report.service';
import { FilterService } from './filter.service';
import { AjsModalService } from './modal/modal.service';
import { ALL_REPORTS } from '../modules/energy-reporting/constants/er-modal-states.constant';
import { ToasterService } from '../shared/services/toaster.service';
import { ReportType as reportingReportType } from '../modules/reporting/shared/report-type';
import { ReportingSearchParams } from '../modules/reporting/shared/reporting-search-params';
import { ReportingModalsService } from '../modules/reporting/services/reporting-modals.service';

type ExtendedReportingParams = ReportingParams & { grouping?: Grouping, meterIds?: number[] };

export type State = {
  modal: boolean;
  name: string;
  stateParams: Record<string, unknown> | ExtendedReportingParams;
}

export type Bookmarks = {
  user: Bookmark[];
  sticky: Bookmark[];
  shared: Bookmark[];
  reporting: {
    user: ReportingBookmark[];
    shared: ReportingBookmark[];
  };
}

export type GridStateForBookmark = {
  group?: GroupDescriptor[];
  filter: CompositeFilterDescriptor;
  sort: SortDescriptor[];
  visibleColumns: string[];
}

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  public readonly bookmarks$: Observable<Bookmarks>;
  public readonly currentState$: Observable<State>;
  public readonly variableBookmarkType$: Observable<RollingDateType>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject(false);
  private readonly _variableBookmarkType$ = new ReplaySubject<RollingDateType>(1);
  private readonly _state$ = new ReplaySubject<State>(1);
  private readonly _stickyBookmarks$ = new BehaviorSubject<Bookmark[]>([]);
  private readonly _refresh$ = new Subject<void>();
  private readonly _reportingModalState$ = new BehaviorSubject<State>(null);
  private readonly _reportingModalGrouping$ = new BehaviorSubject<Grouping>(Grouping.Facility);

  private readonly profileBookmarks$: Observable<Bookmark[]>;
  private readonly reportingBookmarks$: Observable<ReportingBookmark[]>;

  public constructor(
    private readonly profileService: ProfileService,
    private readonly settingsClient: SettingsClient,
    private readonly userService: UserService,
    private readonly tableReportService: TableReportService,
    private readonly meterTableReportService: MeterTableReportService,
    private readonly filterService: FilterService,
    private readonly ajsModalService: AjsModalService,
    private readonly stateService: StateService,
    private readonly toasterService: ToasterService,
    private readonly modalService: ModalService,
    private readonly reportingModalsService: ReportingModalsService,
    transitionService: TransitionService
  ) {
    this.loading$ = this._loading$.asObservable();

    this.profileBookmarks$ = merge(
      this.profileService.profileId$,
      this._refresh$.pipe(
        switchMap(() => this.profileService.profileId$)
      )
    ).pipe(
      switchMap(profileId => this.settingsClient.getBookmarks(profileId).pipe(
        indicate(this._loading$)
      )),
      map(bookmarks => bookmarks.sortBy('created', 'desc'))
    );

    this.reportingBookmarks$ = merge(
      this.profileService.profileId$,
      this._refresh$.pipe(
        switchMap(() => this.profileService.profileId$)
      )
    ).pipe(
      switchMap(profileId => this.settingsClient.getReportingBookmarks(profileId)
        .pipe(indicate(this._loading$))),
      map(bookmarks => bookmarks.sortBy('created', 'desc'))
    );

    this.bookmarks$ = combineLatest([
      this._stickyBookmarks$,
      this.profileBookmarks$,
      this.reportingBookmarks$
    ]).pipe(
      map(([sticky, bookmarks, reportingBookmarks]) => ({
        sticky: sticky,
        user: bookmarks.filter(b => !b.shared),
        shared: bookmarks.filter(b => b.shared).sortBy('createdByCurrentUser', 'desc'),
        reporting: {
          user: reportingBookmarks.filter(b => !b.shared),
          shared: reportingBookmarks.filter(b => b.shared).sortBy('createdByCurrentUser', 'desc')
        }
      })),
      shareReplay(1)
    );

    this.currentState$ = combineLatest([this._state$, this._reportingModalState$]).pipe(
      switchMap(([state, reportingModalState]) => {
        if (reportingModalState) {
          return of(reportingModalState);
        } else {
          return of(state);
        }
      })
    );

    this.variableBookmarkType$ = this._variableBookmarkType$.asObservable();

    transitionService.onSuccess({}, transition => {
      this.handleStateChange(transition);
    });
  }

  public setStickyBookmarks(stickyBookmarks: Bookmark[]): void {
    this._stickyBookmarks$.next(stickyBookmarks);
  }

  public handleDateVariableBookmark(bookmark: Bookmark): Bookmark {
    if (bookmark.rollingDate === RollingDateType.Month) {
      bookmark.stateParams.series.Start = TimeFrame.setMonthVariableDates(
        bookmark.stateParams.series.TimeFrame,
        bookmark.stateParams.series.Resolution,
        bookmark.created.toISOString(), bookmark.stateParams.series.Start
      );
    } else if (bookmark.rollingDate === RollingDateType.Quarter) {
      bookmark.stateParams.series.Start = TimeFrame.setQuarterVariableDates(
        bookmark.stateParams.series.TimeFrame,
        bookmark.stateParams.series.Resolution,
        bookmark.created.toISOString(), bookmark.stateParams.series.Start
      );
    } else if (bookmark.rollingDate === RollingDateType.Year) {
      bookmark.stateParams.series.Start = TimeFrame.setYearVariableDates(
        bookmark.stateParams.series.TimeFrame,
        bookmark.stateParams.series.Resolution,
        bookmark.created.toISOString(), bookmark.stateParams.series.Start
      );
    }
    return bookmark;
  }

  public showBookmark(bookmark: Bookmark | ReportingBookmark): void {
    this.filterService.filteredFacilityIds$.pipe(
      take(1)
    ).subscribe(filteredFacilityIds => {
      if (bookmark instanceof ReportingBookmark) {
        this.showReportingBookmark(bookmark, filteredFacilityIds);
        return;
      }
      const clonedBookmark = cloneDeep(bookmark);
      this.handleSpecialStates(clonedBookmark);
      if (clonedBookmark.rollingDate) {
        this.handleDateVariableBookmark(clonedBookmark);
      }
      if (Array.hasItems(filteredFacilityIds) && Array.hasItems(clonedBookmark.stateParams?.facilityId)) {
        clonedBookmark.stateParams.facilityId = clonedBookmark.stateParams.facilityId.filter(
          (facilityId: number) => filteredFacilityIds.includes(facilityId)
        );
      }
      if (clonedBookmark.modal) {
        this.ajsModalService.getModalWithComponent(
          clonedBookmark.modal, clonedBookmark.stateParams
        );
      } else {
        this.stateService.go(
          clonedBookmark.state, clonedBookmark.stateParams, { reload: true }
        );
        this.handleReportingModalChange(null);
        this.modalService.dismissAll();
      }
    });
  }

  public createBookmark(name: string, state: State, dateAdjustable = false): Observable<unknown> {
    const dateVariableType = dateAdjustable
      ? this.initCurrentDateVariableType(state.stateParams)
      : RollingDateType.None;

    if (state?.name.startsWith('reporting')) {
      return this.createReportingBookmark(name, state, dateVariableType);
    }

    const newBookmark = new CreateBookmark({
      title: name,
      state: !state.modal ? state.name : undefined,
      modal: state.modal ? state.name : undefined,
      stateParams: state.stateParams,
      rollingDate: dateVariableType
    });
    return this.settingsClient.createBookmark(this.userService.profileId, newBookmark).pipe(
      tap(() => this._refresh$.next())
    );
  }

  public createReportingBookmark(name: string, state: State, dateVariableType: RollingDateType): Observable<unknown> {
    if (this._reportingModalGrouping$.value === Grouping.Meter
      && !Array.hasItems(state.stateParams.meterIds as number[])) {
      throw new Error('BOOKMARK.FAIL_NO_METERS_SELECTED');
    }

    const bookmarkReportType = reportingBookmarkStateType[state.name as ReportName];

    if (this._reportingModalGrouping$.value !== Grouping.Meter
      && !staticReportTypes.includes(bookmarkReportType)
      && !Array.hasItems(state.stateParams.quantityIds as number[])) {
      throw new Error('BOOKMARK.FAIL_NO_QUANTITIES_SELECTED');
    }

    const isTableReport = bookmarkReportType === ReportType.Table;
    const newBookmark = new CreateReportingBookmark({
      ...getBookmarkFromParams(state.stateParams as ReportingParams),
      isModal: state.modal,
      shared: false,
      gridState: isTableReport && this._reportingModalGrouping$.value === Grouping.Facility
        ? this.getGridState()
        : this.getEmptyGridState(),
      gridStatesByQuantity: isTableReport && this._reportingModalGrouping$.value === Grouping.Meter
        ? this.getGridStateByQuantity()
        : null,
      grouping: this._reportingModalGrouping$.value,
      reportType: bookmarkReportType,
      rollingDate: dateVariableType,
      meterIds: this._reportingModalGrouping$.value === Grouping.Meter
        ? state.stateParams.meterIds as number[]
        : [],
      title: name,
    });
    return this.settingsClient.createReportingBookmark(this.userService.profileId, newBookmark).pipe(
      tap(() => this._refresh$.next())
    );
  }

  public setShareStatus(bookmarkId: number, shared: boolean, isReportingBookmark: boolean): Observable<unknown> {
    if (isReportingBookmark) {
      return this.settingsClient.setReportingBookmarkSharing(this.userService.profileId, bookmarkId, shared).pipe(
        tap(() => this._refresh$.next())
      );
    } else {
      return this.settingsClient.setBookmarkSharing(this.userService.profileId, bookmarkId, shared).pipe(
        tap(() => this._refresh$.next())
      );
    }
  }

  public removeBookmark(bookmarkId: number, isReportingBookmark: boolean): Observable<unknown> {
    if (isReportingBookmark) {
      return this.settingsClient.deleteReportingBookmark(this.userService.profileId, bookmarkId).pipe(
        tap(() => this._refresh$.next())
      );
    } else {
      return this.settingsClient.deleteBookmark(this.userService.profileId, bookmarkId).pipe(
        tap(() => this._refresh$.next())
      );
    }
  }

  public handleModalChange(modal: State): void {
    if (modal.modal) {
      this._state$.next(modal);
      this._variableBookmarkType$.next(
        this.initCurrentDateVariableType(modal.stateParams)
      );
    } else {
      this._state$.next(null);
      this._variableBookmarkType$.next(RollingDateType.None);
    }
  }

  public handleReportingModalChange(modal: State | null, grouping?: QuantityOrMeterReport): void {
    this._reportingModalGrouping$.next(
      grouping === QuantityOrMeterReport.Meter ? Grouping.Meter : Grouping.Facility
    );
    if (modal && grouping) {
      const stateParams = { ...modal.stateParams, grouping: this._reportingModalGrouping$.value };
      this._reportingModalState$.next({
        modal: modal.modal,
        name: modal.name,
        stateParams
      });
    } else {
      this._reportingModalState$.next(modal);
    }
    this._variableBookmarkType$.next(
      modal ? this.initCurrentDateVariableType(modal.stateParams) : RollingDateType.None
    );

  }

  private handleStateChange(transition: Transition): void {
    if (transition.to()?.data?.bookmark?.enabled) {
      this._state$.next({
        modal: false,
        name: transition.to().name,
        stateParams: transition.params()
      });
      this._variableBookmarkType$.next(
        this.initCurrentDateVariableType(transition.params())
      );
    } else {
      this._state$.next(null);
      this._variableBookmarkType$.next(RollingDateType.None);
    }
  }

  private showReportingBookmark(bookmark: ReportingBookmark, filteredFacilityIds: number[]): void {
    const stateParams = getReportingParams(bookmark, filteredFacilityIds);
    const state = getBookmarkReportState(bookmark.reportType);
    if (!state) {
      this.toasterService.generalError('LOAD', 'BOOKMARK');
    } else if (bookmark.isModal) {
      this.showReportingModalBookmark(bookmark, state, stateParams);
    } else {
      this.stateService.go(state, { ...stateParams, collapseSidebar: true }, { reload: true }).then(() => {
        if (bookmark.reportType === ReportType.Table) {
          this.tableReportService.setGridState({
            ...this.tableReportService.gridState,
            filter: getFilterDescriptorsRecursive(bookmark.gridState?.gridFilters) as CompositeFilterDescriptor,
            group: bookmark.gridState?.groupedColumns?.map(column => ({
              field: column.columnIdentifier,
              dir: column.sort === Sort.Descending ? 'desc' : 'asc'
            })),
            sort: bookmark.gridState?.sortedColumns?.map(column => ({
              field: column.columnIdentifier,
              dir: column.sort === Sort.Descending ? 'desc' : 'asc'
            })),
          });
          this.tableReportService.setVisibleColumns(bookmark.gridState?.visibleColumns);
        }
      });
      this.handleReportingModalChange(null);
      this.modalService.dismissAll();
    }
  }

  private showReportingModalBookmark(bookmark: ReportingBookmark, state: string, stateParams: ReportingParams): void {
    const searchParams = new ReportingSearchParams({
      ...stateParams,
      periods: stateParams.periods.map(p => startOfDay(new Date(p)))
    });
    const modalReportType = state.split('.')[1] as reportingReportType;
    const presentation = {
      sections: stateParams.sections,
      charts: stateParams.charts,
      grids: stateParams.grids,
      meterInfo: bookmark.visibleSections?.includes(VisibleSections.MeterDetails)
    };

    if (bookmark.reportType === ReportType.Table && bookmark.gridStatesByQuantity) {
      for (const [quantityId, gridState] of Object.integerEntries(bookmark.gridStatesByQuantity)) {
        this.meterTableReportService.setGridFilters(
          quantityId,
          getFilterDescriptorsRecursive(gridState.gridFilters) as CompositeFilterDescriptor
        );
        this.meterTableReportService.setGridSorts(+quantityId, gridState.sortedColumns.map(column => ({
          field: column.columnIdentifier,
          dir: column.sort === Sort.Descending ? 'desc' : 'asc'
        })));
        this.meterTableReportService.setGridMeterInfoColumns(+quantityId, gridState.visibleColumns.map(
          column => ({ field: column, hidden: false } as ColumnComponent)
        ));
      }
    }

    this.reportingModalsService.openReport(
      stateParams.facilityIds[0],
      searchParams,
      modalReportType,
      bookmark.meterIds,
      presentation
    );
  }

  private handleSpecialStates(bookmark: Bookmark): void {
    // To make old bookmarks work with stateless energy-reporting modals
    // This was added in february 2019 and can be removed at some point in the future
    // when there are no more old bookmarks in users' computers
    if (ALL_REPORTS.some(report => report.name === bookmark.state)) {
      bookmark.modal = 'report-modal';
      bookmark.stateParams = {
        reportType: bookmark.state,
        reportParams: bookmark.stateParams
      };
    }
    // Handle EnergyManagement AutoLoad
    if (bookmark.state === 'energy-management.actions' || bookmark.state === 'energy-management.comments') {
      bookmark.stateParams.automaticSearch = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private initCurrentDateVariableType(stateParams: Record<string, any>): RollingDateType {

    // Reporting tab
    if (stateParams?.periods?.length) {
      const reportingParams = stateParams as ReportingParams;
      const inspectionPeriod = new Date(reportingParams.periods[0]);
      if (isSameDay(inspectionPeriod, previousPeriodStart('month'))
        && reportingParams.durationName === 'months'
        && reportingParams.durationLength === 1) {
        return RollingDateType.Month;
      }
      if (isSameDay(inspectionPeriod, previousPeriodStart('quarter'))
        && reportingParams.durationName === 'months'
        && reportingParams.durationLength === 3) {
        return RollingDateType.Quarter;
      }
      if (isSameDay(inspectionPeriod, previousPeriodStart('year'))
        && reportingParams.durationName === 'years'
        && reportingParams.durationLength === 1) {
        return RollingDateType.Year;
      }
    }

    // Facilities tab
    if (!stateParams?.series?.Start || !stateParams?.series?.TimeFrame) {
      return RollingDateType.None;
    }
    const lastIndex = stateParams.series.Start.length - 1;
    if (lastIndex < 0) {
      return RollingDateType.None;
    }
    const lastValue = stateParams.series.Start[lastIndex].value;
    if (TimeFrame.isPreviousMonthStart(stateParams.series.TimeFrame, lastValue)) {
      return RollingDateType.Month;
    }
    if (TimeFrame.isPreviousQuarterStart(stateParams.series.TimeFrame, lastValue)) {
      return RollingDateType.Quarter;
    }
    if (TimeFrame.isPreviousYearStart(stateParams.series.TimeFrame, lastValue)) {
      return RollingDateType.Year;
    }

    return RollingDateType.None;
  }

  private getGridState(): GridState {
    const gridState = this.tableReportService.getGridStateForBookmark();
    return mapGridStateForBookmark(gridState);
  }

  private getGridStateByQuantity(): {[quantity: string]: GridState} {
    const gridStateByQuantity = this.meterTableReportService.getGridStateForBookmark();
    const gridStatesMap = Object.entries(gridStateByQuantity);
    if (!gridStatesMap.length) {
      return null;
    }
    const gridStates: {[quantity: string]: GridState} = {};
    for (const [quantity, gridState] of gridStatesMap) {
      gridStates[quantity] = mapGridStateForBookmark(gridState);
    }
    return gridStates;
  }

  private getEmptyGridState(): GridState {
    return new GridState({
      groupedColumns: [],
      gridFilters: new GridFilter({})
    });
  }
}
