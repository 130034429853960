<div
  *ngIf="status != null"
  [ngfTooltip]="status | investigationStatusName"
  [placement]="'bottom'"
>
  <investigation-status-icon
    *ngFor="let iconStatus of statuses"
    [active]="status === iconStatus"
    [status]="iconStatus"
  ></investigation-status-icon>
</div>
