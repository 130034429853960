import ManualQaChangeTimeFramePresetsController from './manual-qa-change-time-frame-presets-controller';
import template from 'raw-loader!./manual-qa-change-time-frame-presets.html';

export default {
  template: template,
  controller: ManualQaChangeTimeFramePresetsController,
  controllerAs: 'vm',
  bindings: {
    isDisabled: '<',
    onChange: '&',
    previousPeriodLabel: '<',
    nextPeriodLabel: '<',
    showNextYear: '<'
  }
};
