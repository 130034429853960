<modal-view-header [headingText]="'ADMIN.BULK_MODIFY_PROFILES.MODIFY_PROFILES_BUTTON' | translate"></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="massProfileEditForm"
    (ngSubmit)="submit()"
    id="massProfileEditForm"
  >
    <ek-multi
      class="margin-bottom"
      formControlName="addProfiles"
      [placeholderKey]="'MULTISELECT.NOTHING_SELECTED'"
      [items]="allProfiles$ | async"
      [translateText]="false"
      *labelBefore="'ADMIN.BULK_MODIFY_PROFILES.ADD_PROFILES' | translate"
    ></ek-multi>
    <ek-multi
      formControlName="removeProfiles"
      [placeholderKey]="'MULTISELECT.NOTHING_SELECTED'"
      [items]="allProfiles$ | async"
      [translateText]="false"
      *labelBefore="'ADMIN.BULK_MODIFY_PROFILES.REMOVE_PROFILES' | translate"
    ></ek-multi>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="massProfileEditForm"
      [loading]="loading$ | async"
      [disabled]="!massProfileEditForm.value.addProfiles.length
      && !massProfileEditForm.value.removeProfiles.length"
    >
      {{ 'ADMIN.BULK_MODIFY_PROFILES.SAVE_BUTTON' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
