import { RollingDateType } from '@enerkey/clients/settings';

import { BookmarkPartition, Bookmarks } from '../components/bookmarks-modal/bookmarks-modal.component';

type BookmarkRow = {
  header: string,
  noBookmarkText: string,
  bookmarksKey: keyof Bookmarks['saved'],
  partitionKey: keyof BookmarkPartition
};

export const variableBookmarkTranslation: Record<RollingDateType, string> = {
  [RollingDateType.None]: 'BOOKMARK.DATE_ADJUSTABLE_BOOKMARK_NONE',
  [RollingDateType.Month]: 'BOOKMARK.DATE_ADJUSTABLE_BOOKMARK_MONTH',
  [RollingDateType.Quarter]: 'BOOKMARK.DATE_ADJUSTABLE_BOOKMARK_QUARTER',
  [RollingDateType.Year]: 'BOOKMARK.DATE_ADJUSTABLE_BOOKMARK_YEAR'
};

export const bookmarksRows: BookmarkRow[][] = [
  [
    {
      header: 'BOOKMARK.PAGE_USER_BOOKMARKS',
      noBookmarkText: 'BOOKMARK.NO_BOOKMARKS',
      bookmarksKey: 'user',
      partitionKey: 'page'
    },
    {
      header: 'BOOKMARK.OTHER_BOOKMARKS',
      noBookmarkText: 'BOOKMARK.NO_BOOKMARKS',
      bookmarksKey: 'user',
      partitionKey: 'other'
    }
  ],
  [
    {
      header: 'BOOKMARK.PAGE_SHARED_BOOKMARKS',
      noBookmarkText: 'BOOKMARK.NO_SHARED_BOOKMARKS',
      bookmarksKey: 'shared',
      partitionKey: 'page'
    },
    {
      header: 'BOOKMARK.OTHER_SHARED_BOOKMARKS',
      noBookmarkText: 'BOOKMARK.NO_SHARED_BOOKMARKS',
      bookmarksKey: 'shared',
      partitionKey: 'other'
    }
  ]
];
