import { Injectable, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { startOfDay, subDays, subYears } from 'date-fns';

import { combineLatest, EMPTY, forkJoin, Observable, of, Subject, take } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import {
  AdapterLatestConsumption, AdapterResolution, ConsumptionClient, MeasurementAdapterLatestRequestBulk
} from '@enerkey/clients/consumption';
import { EnergyReportingClient, MeterItem, SpecificConsumptionConfiguration } from '@enerkey/clients/energy-reporting';
import { FacilityClient, IFacility } from '@enerkey/clients/facility';
import { EtCurveAnalyticResult, InesClient, SaveDeleteEtCurveResponse } from '@enerkey/clients/ines';
import { EtCurveParametersCons, EtCurveRequest, InesReportsClient } from '@enerkey/clients/ines-reports';
import { indicate, LoadingSubject, shareUntil } from '@enerkey/rxjs';

import { UserService } from '../../../../services/user-service';
import { ISODuration, ISODurations } from '../../../../shared/isoduration';
import { CacheManager } from '../../../../shared/utils/cache/cache.manager.util';
import { ColorService } from '../../../../shared/services/color.service';
import { QuantityService } from '../../../../shared/services/quantity.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { EtCurveModelConverter } from '../../utils/et-curve-model-converter.util';
import {
  DeviationType, EnergyMeasurementType, EtCurveAdminParams, EtCurveDisplayType, ETCurveLine, EtCurveModel,
  ETCurveSearchParams, GetEtCurveInesParams, PeriodDisplayType, Point, UnitInfo
} from '../../models/et-curve.model';

@Injectable()
export class EtCurveService implements OnDestroy {

  private static getDeviationInPercentage(value: number, deviation: number, sign: 1 | -1 = 1): number {
    return value + (sign * value * deviation / 100);
  }

  private static getDeviationInSpecificConsumption(value: number, deviation: number, sign: 1 | -1 = 1): number {
    return value + (sign * deviation);
  }

  private static generateDeviationValue(points: Point[], inPercentage: boolean): number {
    if (inPercentage) { return 10; }
    if (!points.length) { return 0; }

    const percentage = 10;
    const decimals = Math.pow(10, 5);
    const average = points.reduce((acc, item) => acc + item.value, 0) / points.length;
    return Math.round((average * percentage) / 100 * decimals) / decimals;
  }

  /** Automatically completes when service is destroyed. */
  public loading$: Observable<boolean>;
  public saving$: Observable<boolean>;

  private readonly _relationalValues: Observable<SpecificConsumptionConfiguration[]>;

  private readonly _loading = new LoadingSubject();
  private readonly _saving = new LoadingSubject();
  private readonly _destroy = new Subject<void>();

  private readonly _facilityCache = new CacheManager<number, IFacility>();
  private readonly _facilityMeterCache = new CacheManager<number, MeterItem[]>();
  private readonly _latestConsumptionOnMetersCache = new CacheManager<string, AdapterLatestConsumption[]>();

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly erClient: EnergyReportingClient,
    private readonly inesReportsClient: InesReportsClient,
    private readonly inesClient: InesClient,
    private readonly consumptionClient: ConsumptionClient,
    private readonly quantityService: QuantityService,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    private readonly toaster: ToasterService,
    private readonly colorService: ColorService
  ) {
    this.loading$ = this._loading.asObservable();
    this.saving$ = this._saving.asObservable();

    this._relationalValues = this.erClient.getRelationalValuesConfiguration().pipe(
      map(x => x.RelationalValues),
      shareUntil(this._destroy)
    );
  }

  public defaultFormState(): ETCurveSearchParams {
    return {
      facilityId: null,
      quantity: null,
      period: ISODuration.P1Y,
      resolution: ISODuration.P7D,
      specificId: null,
      specificValue: null,
      startDate: ISODurations.startOf(ISODuration.P1D, this.oneYearAgo),
      filterWeekend: false,
      showFrom: this.oneYearAgo,
      showTo: this.yesterday,
      showTimeFrom: new Date(new Date().setHours(0, 0, 0, 0)),
      showTimeFromMin: null,
      showTimeTo: new Date(new Date().setHours(0, 0, 0, 0)),
      showTimeToMax: null,
      invert: false,
      opsHours: false,
      getCons: true,
      calculationTimeFrom: new Date(new Date().setHours(0, 0, 0, 0)),
      calculationTimeFromMin: null,
      calculationTimeTo: new Date(new Date().setHours(0, 0, 0, 0)),
      calculationTimeToMax: null,
      calculationInvert: false,
      calculationOpsHours: false,
      datatype: EnergyMeasurementType.ENERGY_MEASUREMENT,
      deviation: null,
      deviationType: DeviationType.PERCENT,
      etCurveDisplayType: EtCurveDisplayType.SAVED,
      periodDisplayType: PeriodDisplayType.PREDEFINED,
      advancedCalculation: false
    };
  }

  public get yesterday(): Date {
    return startOfDay(subDays(new Date(), 1));
  }

  public get oneYearAgo(): Date {
    return startOfDay(subYears(new Date(), 1));
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loading.complete();
    this._saving.complete();
  }

  public getFacilityMeters(facilityId: number): Observable<MeterItem[]> {
    const cachedFacilityMeters = this._facilityMeterCache.get(facilityId);
    return cachedFacilityMeters !== undefined ?
      of(cachedFacilityMeters) :
      this.erClient.getMeter(facilityId).pipe(
        map(res => res.Meters),
        tap(meters => {
          this._facilityMeterCache.add(facilityId, meters);
        })
      );
  }

  public getLatestConsumptionOnMeters(
    meterIds: number[],
    resolution: AdapterResolution = AdapterResolution.P1D
  ): Observable<AdapterLatestConsumption[]> {
    const key = JSON.stringify(({ meterIds, resolution }));
    const cachedReadings = this._latestConsumptionOnMetersCache.get(key);
    return cachedReadings !== undefined ?
      of(cachedReadings) :
      this.consumptionClient.getLatestConsumptions(new MeasurementAdapterLatestRequestBulk({
        meterIds,
        resolution
      })).pipe(
        map(res => Object.values(res)),
        tap(readings => {
          this._latestConsumptionOnMetersCache.add(key, readings);
        })
      );
  }

  public getChartData(
    params: ETCurveSearchParams,
    manualEtCurve?: EtCurveParametersCons
  ): Observable<EtCurveModel> {
    return this.getFacility(params.facilityId).pipe(
      switchMap(facility => forkJoin({
        readingUnit: this.getReadingUnit(params),
        consumptionData: this.getConsumptionData(params),
        etCurveData: manualEtCurve !== undefined ?
          of(manualEtCurve) :
          this.getEtCurveData({ ...params, period: ISODuration.P1Y, getCons: false })
      }).pipe(
        indicate(this._loading),
        switchMap(data => {
          if (data.consumptionData === null || data.etCurveData === null) {
            this.toaster.error('ANALYTICS.ETCURVE.ERRORS.CONSUMPTION');
            return EMPTY;
          }

          if (!data.consumptionData.consTemp?.length) {
            this.toaster.error('ANALYTICS.ETCURVE.ERRORS.CONSUMPTION_EMPTY');
            return EMPTY;
          }

          return this.handleResult(params, facility, data.readingUnit, data.consumptionData, data.etCurveData);
        }),
        takeUntil(this._destroy)
      ))
    );
  }

  public getEtCurve(
    params: Partial<GetEtCurveInesParams>
  ): Observable<EtCurveAnalyticResult[]> {
    return this.inesClient.getEtCurve(
      this.userService.profileId,
      params.facilityId,
      params.isActive,
      params.quantityId,
      params.filterWeekend,
      params.operationalHours,
      Number.isFinite(params.etCurveConversionPropertyId) ? params.etCurveConversionPropertyId : -1,
      params.etCurveConversionPropertyValue,
      params.etCurveConversionResolution
    );
  }

  public createEtCurve(params: EtCurveAdminParams): Observable<SaveDeleteEtCurveResponse> {
    return this.getEtCurveData(params).pipe(
      map(etCurveData => EtCurveModelConverter.etCurveAdminParamsToEtCurveResultDto(params, etCurveData.meta)),
      switchMap(payload => this.inesClient.saveEtCurveResult(payload)),
      indicate(this._saving)
    );
  }

  public updateEtCurve(params: EtCurveAdminParams): Observable<SaveDeleteEtCurveResponse> {
    if (!Number.isFinite(params.id)) {
      throw new Error(`Invalid value provided for property "id", value: ${params.id}`);
    }

    const profileId = this.userService.profileId;
    return this.getEtCurveData(params).pipe(
      map(etCurveData => EtCurveModelConverter.etCurveAdminParamsToEtCurveResultDto(params, etCurveData.meta)),
      switchMap(payload => this.inesClient.updateEtCurveResult(profileId, params.id, payload)),
      indicate(this._saving)
    );
  }

  public generateEtCurveName(facilityId: number, quantityId: number): Observable<string> {
    return combineLatest([
      this.getFacility(facilityId),
      this.quantityService.getQuantityById(quantityId)
    ]).pipe(
      map(([facility, quantity]) => `${facility.displayName} - ${quantity.Name}`)
    );
  }

  private handleResult(
    params: ETCurveSearchParams,
    facility: IFacility,
    readingUnit: UnitInfo,
    consumptionData?: EtCurveParametersCons,
    etCurveData?: EtCurveParametersCons
  ): Observable<EtCurveModel> {
    const points = EtCurveModelConverter.etCurveParameterConsToPoints(etCurveData);
    const isDeviationInPercent = params.deviationType !== DeviationType.KWH_M2;

    const deviation = params.deviation === null || params.deviation === undefined ?
      EtCurveService.generateDeviationValue(points, isDeviationInPercent) :
      params.deviation;

    const deviationString = `${deviation}${ isDeviationInPercent ?
      '%' :
      this.translateService.instant('ANALYTICS.ETCURVE.KWH_M2')}`;

    const consumptionValues = consumptionData.consTemp.map(ct => ({
      temperature: ct.temperature,
      value: ct.consumption,
      timestamp: ct.timestamp
    }));

    const etCurveLine: ETCurveLine = {
      name: this.translateService.instant('ANALYTICS.ETCURVE.TITLE'),
      color: this.colorService.getCssProperty('--enerkey-blue', undefined),
      width: 2,
      points: points,
    };

    const minusCurve: ETCurveLine = {
      name: `${this.translateService.instant('ANALYTICS.DEVIATION')} (-${deviationString})`,
      color: this.colorService.getCssProperty('--enerkey-green', undefined),
      points: etCurveLine.points.map(p => ({
        temperature: p.temperature,
        value: isDeviationInPercent ?
          EtCurveService.getDeviationInPercentage(p.value, deviation, -1) :
          EtCurveService.getDeviationInSpecificConsumption(p.value, deviation, -1)
      }))
    };

    const plusCurve: ETCurveLine = {
      name: `${this.translateService.instant('ANALYTICS.DEVIATION')} (+${deviationString})`,
      color: this.colorService.getCssProperty('--enerkey-red', undefined),
      points: etCurveLine.points.map(p => ({
        temperature: p.temperature,
        value: isDeviationInPercent ?
          EtCurveService.getDeviationInPercentage(p.value, deviation) :
          EtCurveService.getDeviationInSpecificConsumption(p.value, deviation)
      }))
    };

    return of({
      facility: facility,
      quantity: params.quantity,
      specificId: params.specificId ?? null,
      values: consumptionValues,
      period: params.period,
      resolution: params.resolution,
      temperatureUnit: {
        name: this.translateService.instant('ANALYTICS.ETCURVE.TEMPERATURE'),
        unit: '°C',
        decimals: 1,
      },
      curves: [etCurveLine, minusCurve, plusCurve],
      valueUnit: readingUnit,
      range: {
        start: params.startDate,
        end: subDays(ISODurations.add(params.period, params.startDate, 1), 1),
      },
      showFrom: params.showFrom,
      showTo: params.showTo,
      calculationTimeFrom: params.calculationTimeFrom,
      calculationTimeTo: params.calculationTimeTo,
      getCons: params.getCons,
      deviation
    } as EtCurveModel);
  }

  private getFacility(facilityId: number): Observable<IFacility> {
    const cachedFacility = this._facilityCache.get(facilityId);
    return cachedFacility !== undefined ?
      of(cachedFacility) :
      this.facilityClient.getFacilities([facilityId]).pipe(
        take(1),
        switchMap(facilities => {
          const facility = facilities?.length ? facilities[0] : null;
          if (!facility) {
            this.toaster.generalError('LOAD', 'FACILITY');
            return EMPTY;
          }

          this._facilityCache.add(facilityId, facility);
          return of(facility);
        })
      );
  }

  private getReadingUnit(params: ETCurveSearchParams): Observable<UnitInfo> {
    const quantityName = this.quantityService.getQuantityLocalizedName(params.quantity);
    if (Number.isInteger(params.specificId)) {
      return this._relationalValues.pipe(
        map(values => values.find(v => v.Id === params.specificId)),
        map((item): UnitInfo => ({
          unit: item.UnitsForQuantities['kWh'][params.quantity],
          decimals: 2,
          name: `${quantityName}/${item.Name}`, // should this be done in UI?
        }))
      );
    } else {
      return this.quantityService.getAllQuantities().pipe(
        map(quantities => quantities.find(q => q.ID === params.quantity).Units['kWh']),
        map((unitItem): UnitInfo => ({
          name: quantityName,
          unit: unitItem.Unit,
          decimals: unitItem.DecimalsToShow,
        }))
      );
    }
  }

  private getEtCurveData(params: ETCurveSearchParams): Observable<EtCurveParametersCons | null> {
    const request: EtCurveRequest = new EtCurveRequest({
      analysisFrom: params.startDate,
      analysisTo: subDays(ISODurations.add(params.period, params.startDate, 1), 1),
      quantityId: params.quantity,
      getCons: params.getCons,
      filterWeekend: params.filterWeekend,
      propertyId: params.specificId ? params.specificId : -1,
      resolution: EtCurveModelConverter.isoDurationToEtCurveRequestResolution(params.resolution),
      startHour: params.calculationTimeFrom?.toTimeString().slice(0, 8),
      endHour: params.calculationTimeTo?.toTimeString().slice(0, 8),
      invertHours: params.calculationInvert,
    });

    return this.inesReportsClient.getEtCurveParametersAndCons(request, params.facilityId).pipe(
      catchError(() => of(null))
    );
  }

  private getConsumptionData(params: ETCurveSearchParams): Observable<EtCurveParametersCons | null> {
    const request: EtCurveRequest = new EtCurveRequest({
      analysisFrom: params.showFrom,
      analysisTo: params.showTo,
      quantityId: params.quantity,
      getCons: params.getCons,
      filterWeekend: params.filterWeekend,
      propertyId: params.specificId ? params.specificId : -1,
      resolution: EtCurveModelConverter.isoDurationToEtCurveRequestResolution(params.resolution),
      startHour: params.showTimeFrom?.toTimeString().slice(0, 8),
      endHour: params.showTimeTo?.toTimeString().slice(0, 8),
      invertHours: params.invert,
    });

    return this.inesReportsClient.getEtCurveParametersAndCons(request, params.facilityId).pipe(
      catchError(() => of(null))
    );
  }
}
