import template from 'raw-loader!./language-dropdown.html';

const LanguageDropdownComponent = {
  template: template,
  transclude: true,
  controllerAs: 'vm',
  bindings: {
    language: '=',
    languages: '<',
    noLabel: '<'
  }
};

export default LanguageDropdownComponent;
