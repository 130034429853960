import { ChangeDetectionStrategy, Component, Inject, Input, OnDestroy, Optional, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import { CalendarView } from '@progress/kendo-angular-dateinputs';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { add, startOfMonth, startOfYear, sub } from 'date-fns';

import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';
import { DeepPartial } from '@enerkey/ts-utils';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ReportingSearchService } from '../../services/reporting-search.service';
import {
  DurationName,
  ReportingDistributionType,
  ReportingSearchFormValue,
} from '../../shared/reporting-search-form-value';

import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { getCalendarSettingsForResolution } from './reporting.search-form.functions';
import { Comparability } from '../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../report-modal/report-modal.component';
import { getDefaultReportingParams } from '../../services/reporting.search.functions';
import { ReportTypeOptionsService } from '../../services/report-type-options.service';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { TimePeriodHistoryDropdownComponent } from '../time-period-history-dropdown/time-period-history-dropdown.component';

export const PERIODS = [
  'years',
  'months',
  'weeks',
  'days',
] as const;

const periodTranslations: Record<typeof PERIODS[number], string> = {
  years: 'TIMESPAN.YEAR',
  months: 'TIMESPAN.MONTH',
  weeks: 'TIMESPAN.WEEK',
  days: 'TIMESPAN.DAY',
};

export const RESOLUTIONS = [
  RequestResolution.P1Y,
  RequestResolution.P3M,
  RequestResolution.P1M,
  RequestResolution.P7D,
  RequestResolution.P1D,
  RequestResolution.Pt1H,
  RequestResolution.Pt15M,
] as const;

export const resolutionTranslationkeys: Record<typeof RESOLUTIONS[number], string> = {
  [RequestResolution.P1Y]: 'TIMESPAN.YEAR',
  [RequestResolution.P3M]: 'TIMESPAN.QUARTER',
  [RequestResolution.P1M]: 'TIMESPAN.MONTH',
  [RequestResolution.P7D]: 'TIMESPAN.WEEK',
  [RequestResolution.P1D]: 'TIMESPAN.DAY',
  [RequestResolution.Pt1H]: 'TIMESPAN.HOUR',
  [RequestResolution.Pt15M]: 'TIMESPAN.FIFTEENMINUTE',
};

export type SelectableResolution = typeof RESOLUTIONS[number];

interface DurationResolutionConfig {
  readonly availableResolutions: ReadonlyArray<SelectableResolution>,
  readonly defaultResolution: SelectableResolution;
}

const availableResolutionForTimePeriods: Record<typeof PERIODS[number], DurationResolutionConfig> = {
  years: {
    availableResolutions: [...RESOLUTIONS].except([RequestResolution.Pt15M]),
    defaultResolution: RequestResolution.P1M,
  },
  months: {
    availableResolutions: [...RESOLUTIONS].except([
      RequestResolution.P1Y, RequestResolution.P3M, RequestResolution.Pt15M
    ]),
    defaultResolution: RequestResolution.P1D,
  },
  weeks: {
    availableResolutions: [
      RequestResolution.P7D,
      RequestResolution.P1D,
      RequestResolution.Pt1H,
      RequestResolution.Pt15M,
    ],
    defaultResolution: RequestResolution.P1D,
  },
  days: {
    availableResolutions: [RequestResolution.P1D, RequestResolution.Pt1H, RequestResolution.Pt15M],
    defaultResolution: RequestResolution.Pt1H,
  },
};

type CustomControlFields = 'change' | 'periods' | 'minMaxAvg';
export type ReportingSearchFormControls = {
  [K in keyof Omit<ReportingSearchFormValue, CustomControlFields>]: FormControl<ReportingSearchFormValue[K]>;
} & {
  change: FormGroup<{ absolute: FormControl<boolean>, relative: FormControl<boolean> }>;
  periods: FormArray<FormControl<Date>>;
  minMaxAvg: FormGroup<{
    min: FormControl<boolean>,
    max: FormControl<boolean>,
    average: FormControl<boolean>,
  }>
};

@Component({
  selector: 'reporting-search-form',
  templateUrl: './reporting-search-form.component.html',
  styleUrls: ['./reporting-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingSearchFormComponent implements OnDestroy {
  public static readonly debounceTime = 150;

  @Input() public isMeterReport: boolean;
  @Input() public isModal: boolean;

  @ViewChild('historyDropdown') public historyDropdown: TimePeriodHistoryDropdownComponent;

  public readonly formGroup: FormGroup<ReportingSearchFormControls>;
  public calendarView: CalendarView = 'decade';
  public bottomView: CalendarView = 'decade';
  public disabledDatesFn: (date: Date) => boolean;
  public focusedDate: Date;

  public readonly ReportingDistributionType = ReportingDistributionType;

  public readonly periodSelectOptions: ComboItem<DurationName>[];
  public readonly resolutionSelectOptions: ComboItem<SelectableResolution>[];
  public readonly ReportingUnit = ReportingUnit;
  public readonly Comparability = Comparability;

  public readonly disabledResolutions$: Observable<SelectableResolution[]>;
  public readonly showPresentation$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();
  private readonly _disabledResolutions$ = new BehaviorSubject<SelectableResolution[]>([]);

  public constructor(
    fb: FormBuilder,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly disabledParamsService: ReportTypeOptionsService,
    @Optional() @Inject(REPORT_MODAL_PARAMS) public readonly modalParams: ReportingModalParams
  ) {
    this.disabledResolutions$ = this._disabledResolutions$.asObservable();

    this.showPresentation$ = this.disabledParamsService.showPresentation$;

    this.periodSelectOptions = PERIODS.map(value => ({
      value,
      text: periodTranslations[value]
    }));
    this.resolutionSelectOptions = RESOLUTIONS.map(value => ({
      value,
      text: resolutionTranslationkeys[value]
    }));

    this.formGroup = fb.group<ReportingSearchFormControls>({
      quantityIds: fb.control([]),
      durationName: fb.control('years'),
      durationLength: fb.control(1, { updateOn: 'blur' }),
      resolution: fb.control(RequestResolution.P1M),
      periods: fb.array<Date[]>([]),
      change: fb.group<ReportingSearchFormValue['change']>({
        absolute: false,
        relative: false,
      }),
      valueType: fb.control(ValueType.Measured),
      showConsumption: fb.control(true),
      showSummedConsumption: fb.control(true),
      specificIds: fb.control([], { updateOn: 'blur' }),
      costIds: fb.control([], { updateOn: 'blur' }),
      emissionIds: fb.control([], { updateOn: 'blur' }),
      targetTypes: fb.control([], { updateOn: 'blur' }),
      reportingUnit: fb.control(ReportingUnit.Default),
      distributionType: fb.control(ReportingDistributionType.None),
      distributionAsPercent: fb.control(false),
      temperature: fb.control(false),
      comparability: fb.control(Comparability.All),
      minMaxAvg: fb.group<ReportingSearchFormValue['minMaxAvg']>({
        min: false,
        max: false,
        average: false,
      })
    });

    this.disabledParamsService.disabledParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: disabledFields => {
          this.formGroup.enable({ emitEvent: false });
          disabledFields.forEach(field => {
            this.formGroup.get(field)?.disable({ emitEvent: false });
          });
        }
      });

    this.reportingSearchService.searchParameters$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      this.setDisabledResolutions(params.formValue.durationName);
      this.setPeriods(params.periods);
      this.formGroup.patchValue(params.formValue, { emitEvent: false });
      this.updateCalendarView();
      this.setDistributionAsPercentEnabledState(params.distributionType);
      this.setShowConsumptionEnabledState(params);
      this.setShowMonthlyConsumptionEnabledState(params);
    });

    merge(
      this.formGroup.controls.durationName.valueChanges,
      this.formGroup.controls.durationLength.valueChanges
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateCalendarView();
      });

    this.formGroup.controls.durationName.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(durationName => {
        const durationResoConfig = availableResolutionForTimePeriods[durationName];
        const periods = this.reportingSearchService.getDefaultPeriodsForDuration(durationName);
        this.setDisabledResolutions(durationName);
        this.setPeriods(periods);
        this.formGroup.patchValue({
          resolution: durationResoConfig.defaultResolution,
          durationLength: 1,
          periods,
        });
      });

    this.formGroup.controls.resolution.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(resolution => {
        if (resolution === RequestResolution.P1Y || resolution === RequestResolution.P3M) {
          const periods = this.formGroup.controls.periods.value.map(p => startOfYear(p));
          this.formGroup.patchValue({ periods });
        } else if (resolution === RequestResolution.P1M) {
          const periods = this.formGroup.controls.periods.value.map(p => startOfMonth(p));
          this.formGroup.patchValue({ periods });
        }
      });

    // update the comparison period based on main period changes
    this.formGroup.controls.periods.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(values => this.comparisonPeriodFollowMainPeriod(values));

    this.formGroup.controls.durationLength.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(durationLength => this.handlePeriodDurationLengthChange(durationLength));

    this.formGroup.valueChanges
      .pipe(debounceTime(ReportingSearchFormComponent.debounceTime))
      .subscribe({
        next: values => {
          this.setShowConsumption(values);
          this.setShowMonthlyConsumption(values);
          this.reportingSearchService.search({
            ...getDefaultReportingParams(),
            ...this.formGroup.getRawValue(),
          });
        }
      });

    this.formGroup.controls.distributionType.valueChanges.subscribe({
      next: distributionType => {
        this.setDistributionAsPercentEnabledState(distributionType);
      }
    });

    this.disabledParamsService.disabledParams$.pipe(
      takeUntil(this.destroy$),
      map(params => params.includes('showSummedConsumption'))
    ).subscribe({
      next: disabled => {
        if (disabled) {
          this.formGroup.controls.showSummedConsumption.patchValue(true, { emitEvent: false });
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public get periods(): FormArray<FormControl<Date>> {
    return this.formGroup.controls.periods;
  }

  public get debounceTime(): number {
    return ReportingSearchFormComponent.debounceTime;
  }

  public addPeriod(date: Date): void {
    const formControl = new FormControl(date);
    // Manually added form should be always dirty. Dirty comparison control do not 'follow' main control
    formControl.markAsDirty();
    this.periods.push(formControl);
  }

  public deletePeriod(index: number): void {
    this.periods.removeAt(index);
  }

  public changePeriod(dir: 'previous' | 'next'): void {
    const periodValues = this.periods.value;
    const duration: Duration = { [this.formGroup.value.durationName]: this.formGroup.value.durationLength };
    const fn = dir === 'previous' ? sub : add;
    const newPeriods = periodValues.map(p => fn(p, duration));

    this.formGroup.patchValue({
      periods: newPeriods
    });
  }

  private handlePeriodDurationLengthChange(durationLength: number): void {
    const [mainPeriodControl, comparisonPeriodControl] = this.formGroup.controls.periods.controls;
    const durationName = this.formGroup.controls.durationName.value;
    const [mainPeriodStartDate] = this.reportingSearchService.getDefaultPeriodsForDuration(durationName);

    if (mainPeriodControl.dirty) {
      return;
    }
    const updatedMainPeriodStartDate = sub(
      mainPeriodStartDate,
      { [durationName]: durationLength - 1 }
    );
    mainPeriodControl.patchValue(updatedMainPeriodStartDate, { emitEvent: false });

    if (comparisonPeriodControl === undefined || comparisonPeriodControl.dirty) {
      return;
    }
    const updatedComparisonPeriodStartDate = sub(
      updatedMainPeriodStartDate,
      { years: durationName === 'years' ? durationLength : 1 }
    );
    comparisonPeriodControl.patchValue(updatedComparisonPeriodStartDate, { emitEvent: false });
  }

  private comparisonPeriodFollowMainPeriod(values: Date[]): void {
    if (this.formGroup.value.durationName === 'days') {
      return;
    }
    const dates = values.map(d => new Date(d));
    const isComparisonPeriodDirty = !this.formGroup.controls.periods.controls[1]?.dirty;

    if (dates[1] && isComparisonPeriodDirty) {
      dates[1] = sub(dates[0], { years: 1 });
      this.formGroup.controls.periods.patchValue(dates, { emitEvent: false });
    }
  }

  private updateCalendarView(): void {
    const resolution: SelectableResolution = this.formGroup.controls.resolution.value;
    const settings = getCalendarSettingsForResolution(resolution);

    this.calendarView = settings.calendarView;
    this.bottomView = settings.bottomView;
    this.disabledDatesFn = settings.disabledFn;
    this.focusedDate = settings.focusedDate;
  }

  private setPeriods(periods: Date[]): void {
    while (this.periods.length < periods.length) {
      this.periods.push(new FormControl(null), { emitEvent: false });
    }
    while (this.periods.length > periods.length) {
      this.periods.removeAt(-1, { emitEvent: false });
    }
  }

  private setDisabledResolutions(duration: DurationName): void {
    const durationResoConfig = availableResolutionForTimePeriods[duration];
    this._disabledResolutions$.next(RESOLUTIONS.filter(r => !durationResoConfig.availableResolutions.includes(r)));
  }

  private setDistributionAsPercentEnabledState(distributionType: ReportingDistributionType): void {
    this.disabledParamsService.disabledParams$.pipe(
      take(1),
      map(params => params.includes('distributionType') || params.includes('distributionAsPercent'))
    ).subscribe({
      next: distributionPercentageDisabled => {
        if (distributionPercentageDisabled || distributionType === ReportingDistributionType.None) {
          this.formGroup.controls.distributionAsPercent.disable({ emitEvent: false });
        } else {
          this.formGroup.controls.distributionAsPercent.enable({ emitEvent: false });
        }
      }
    });
  }

  private setShowConsumption(values: DeepPartial<ReportingSearchFormValue>): void {
    const showNonConsumptions = Array.hasItems(values.costIds)
      || Array.hasItems(values.emissionIds)
      || Array.hasItems(values.specificIds)
      || Array.hasItems(values.targetTypes);
    if (!showNonConsumptions) {
      this.formGroup.controls.showConsumption.patchValue(true, { emitEvent: false });
    }
  }

  private setShowConsumptionEnabledState(params: ReportingSearchParams): void {
    if (Array.hasItems(params.derivedIds) || Array.hasItems(params.targetTypes) || Array.hasItems(params.costIds)) {
      this.formGroup.controls.showConsumption.enable({ emitEvent: false });
    } else {
      this.formGroup.controls.showConsumption.disable({ emitEvent: false });
    }
  }

  private setShowMonthlyConsumption(values: DeepPartial<ReportingSearchFormValue>): void {
    const enableMonthlyConsumption = values.resolution === RequestResolution.P1M
      || values.resolution === RequestResolution.P3M;

    if (values.durationName !== 'years' || !enableMonthlyConsumption) {
      this.formGroup.controls.showSummedConsumption.patchValue(true, { emitEvent: false });
    }
  }

  private setShowMonthlyConsumptionEnabledState(params: ReportingSearchParams): void {
    const enableMonthlyConsumption = params.formValue.resolution === RequestResolution.P1M
      || params.formValue.resolution === RequestResolution.P3M;
    this.disabledParamsService.disabledParams$.pipe(
      take(1),
      map(disabledParams => disabledParams.includes('showSummedConsumption'))
    ).subscribe({
      next: disabled => {
        if (!disabled && params.formValue.durationName === 'years' && enableMonthlyConsumption) {
          this.formGroup.controls.showSummedConsumption.enable({ emitEvent: false });
        } else {
          this.formGroup.controls.showSummedConsumption.disable({ emitEvent: false });
        }
      }
    });
  }

}
