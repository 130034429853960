import { Injectable } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { map, shareReplay, skipWhile, startWith, switchMap, take, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { ofVoid } from '@enerkey/rxjs';
import { EnergyReportingClient, SpecificConsumptionConfiguration } from '@enerkey/clients/energy-reporting';
import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { ProfileService } from './profile.service';
import { LanguageChangeService } from './language-change.service';
import { isCost, isEmission } from '../../modules/reportingobjects/shared/relational-value-functions';
import { costsDropdownNames, nationalCostsIdMatch } from '../../modules/reporting/constants/costs-report-constants';
import { UserService } from '../../services/user-service';
import { Roles } from '../../modules/admin/constants/roles';

@Injectable({ providedIn: 'root' })
export class RelationalValuesService {
  public readonly relationalValues$: Observable<SpecificConsumptionConfiguration[]>;

  public readonly specificConsumptions$: Observable<SpecificConsumptionConfiguration[]>;
  public readonly costs$: Observable<SpecificConsumptionConfiguration[]>;
  public readonly emissions$: Observable<SpecificConsumptionConfiguration[]>;

  public constructor(
    private readonly erClient: EnergyReportingClient,
    languageChangeService: LanguageChangeService,
    profileService: ProfileService,
    userService: UserService,
    private translateService: TranslateService
  ) {
    let languageChangeInProgress = false;

    this.relationalValues$ = merge(
      profileService.profileChange$,
      languageChangeService.languageChangeStart.pipe(tap(() => {
        languageChangeInProgress = true;
      }))
    ).pipe(
      switchMap(
        () => languageChangeInProgress
          ? languageChangeService.languageChange
          : ofVoid()
      ),
      startWith(null),
      switchMap(() => this.erClient.getRelationalValuesConfiguration()),
      map(values => values.RelationalValues),
      tap(() => {
        languageChangeInProgress = false;
      }),
      shareReplay(1),
      skipWhile(() => languageChangeInProgress)
    );

    this.specificConsumptions$ = this.relationalValues$.pipe(
      map(values => values.filter(v => !isEmission(v.Id) && !isCost(v.Id))),
      shareReplay(1)
    );

    this.costs$ = of(
      userService.hasRole(Roles.COSTREADER)
        ? costsDropdownNames
        : costsDropdownNames.joinInner(
          nationalCostsIdMatch,
          a => a.Id,
          b => b.id,
          cost => cost
        )
    ).pipe(
      map(items => items.map(item => ({
        ...item,
        Name: this.translateService.instant(item.Name)
      })))
    );

    this.emissions$ = this.relationalValues$.pipe(
      map(values => values.filter(v => isEmission(v.Id))),
      shareReplay(1)
    );

    // Load relational values eagerly by subscribing.
    // Don't use take(1) to load them again when profile/language is changed
    this.relationalValues$.subscribe();
  }

  public getRelationalValueById(id: number): Observable<SpecificConsumptionConfiguration> {
    return this.relationalValues$.pipe(
      take(1),
      map(values => values.find(value => value.Id === id))
    );
  }

  public getRelationalValueQuantityUnit(
    id: number,
    quantityId: Quantities,
    unit: ReportingUnit = ReportingUnit.Default
  ): Observable<string> {
    return this.getRelationalValueById(id).pipe(
      map(value => value?.UnitsForQuantities[unit][quantityId] ?? '')
    );
  }

  public getRelationalValueName(id: number): Observable<string> {
    return this.getRelationalValueById(id).pipe(
      map(value => value?.Name ?? '')
    );
  }
}
