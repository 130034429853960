<div class="facility-header">
  <h1>{{ facility.FacilityInformation.RealEstateId }} - {{ facility.Name }}</h1>
  <div class="facility-header-buttons">
    <button
      *ngIf="showOpenFacilityButton"
      (click)="openReport()"
      class="button icon"
      [title]="'FACILITIES_REPORT.INSPECT' | translate"
    >
      <i class="far fa-eye"></i>
    </button>
    <facilities-popup-actions
      [actions]="facilityActions | slice:0:5"
      [actionType]="'action'"
      [moduleType]="'report'"
      [facilityId]="facility?.facilityId"
      (addedAction)="addedAction.emit()"
    ></facilities-popup-actions>
    <facilities-popup-actions
      [actions]="facilityComments | slice:0:5"
      [actionType]="'comment'"
      [moduleType]="'report'"
      [facilityId]="facility?.facilityId"
      (addedAction)="addedAction.emit()"
    ></facilities-popup-actions>
    <ng-container *ngIf="events?.alarms as alarms">
      <facilities-popup-alarms
        *ngIf="alarms.length"
        [alarms]="alarms | slice:0:5"
        [facilityId]="facility?.facilityId"
      ></facilities-popup-alarms>
    </ng-container>
    <facilities-popup-documents [facility]="facility"></facilities-popup-documents>
    <facilities-popup-external-links [facility]="facility"></facilities-popup-external-links>
  </div>
</div>
<facility-group-info
  *ngFor="let section of visibleSections$ | async"
  [groupName]="section"
  [facility]="facility"
></facility-group-info>
