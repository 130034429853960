import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { AggregateResult } from '@progress/kendo-data-query';

import { FacilityPropertyFilterType } from '../../pipes/facility-property-filter-type.pipe';
import { KendoGridCustomColumnComponent } from '../../../base-classes/kendo-grid-custom-column.component';
import { FacilityPropertyWithField } from '../../../../modules/energy-reporting/services/facility-properties.service';
import { ReportingAggregateType } from '../../../../modules/reporting/services/reporting-grid.service';
import { specialFormatingProperties } from '../../../../modules/reporting/constants/table-report-constants';

@Component({
  selector: 'kendo-grid-facility-property-column',
  templateUrl: './kendo-grid-facility-property-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => KendoGridFacilityPropertyColumnComponent)
    }
  ],
})
export class KendoGridFacilityPropertyColumnComponent extends KendoGridCustomColumnComponent implements OnInit {
  @Input() public property: FacilityPropertyWithField;
  @Input() public data: unknown[];
  @Input() public total: AggregateResult;
  @Input() public prefix: string;
  @Input() public isHidden: boolean;

  @Input() public visibleAggregates: readonly ReportingAggregateType[] = ['sum'];
  @Input() public groupVisibleAggregates: readonly ReportingAggregateType[] = ['sum'];

  public FacilityPropertyFilterType = FacilityPropertyFilterType;

  @ViewChild('booleanCellTemplate')
  public booleanTemplate: TemplateRef<unknown>;

  public constructor(
    public el: ElementRef,
    @Optional() @SkipSelf() @Host() parent: ColumnBase
  ) {
    super(parent);
    this.width = 100;
  }

  public ngOnInit(): void {
    this.field = this.prefix ? `${this.prefix}.${this.property.field}` : `${this.property.field}`;
    this.groupable = this.property.Groupable;
    this.title = this.property.Name;
    this.hidden = this.isHidden ?? true;
    if (this.property.Type === 'number') {
      this.format = '#,#.#';
      this.cssClass = 'cell-number';
    }
    if (this.property.Type === 'date') {
      this.format = 'g';
    }
    if (specialFormatingProperties[this.property.Property]) {
      this.format = specialFormatingProperties[this.property.Property];
    }
  }

  public override get templateRef(): TemplateRef<unknown> {
    if (this.property.Type === 'boolean') {
      return this.booleanTemplate;
    }
    return this.template?.templateRef;
  }
}
