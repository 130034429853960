import { ACTIONS, MODEL_TYPE, MODEL_TYPE_MODE, SAVE_OPTION, SAVE_TYPE } from './actions';
import { CONSUMPTION_TYPE } from '../../constants/consumption-types';

const $inject = ['utils'];

class ManualQaActionsSelectService {
  constructor(utils) {
    this.utils = utils;
  }

  getActions() {
    return [
      {
        id: ACTIONS.CALCULATED,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.CALCULATED'),
        iconClass: 'fa fa-calculator',
        isDisabled: false
      },
      {
        id: ACTIONS.COPY,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.COPY'),
        iconClass: 'fa fa-copy',
        isDisabled: false
      },
      {
        id: ACTIONS.MANUAL,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.MANUAL.ENTER'),
        iconClass: '',
        isDisabled: false
      }
    ];
  }

  getConsumptionTypes() {
    return [
      {
        id: CONSUMPTION_TYPE.HOURLY,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.MANUAL.HOURLY')
      },
      {
        id: CONSUMPTION_TYPE.TOTAL,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.MANUAL.TOTAL')
      }
    ];
  }

  getModelTypes() {
    return [
      {
        id: MODEL_TYPE.UNIFORM,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.UNIFORM')
      },
      {
        id: MODEL_TYPE.ELECTRICITY_COMMON,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.ELECTRICITY_COMMON')
      }
    ];
  }

  getModelTypeModes() {
    return [
      {
        id: MODEL_TYPE_MODE.COMPARISON_PERIOD,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.COMPARISON_PERIOD')
      },
      {
        id: MODEL_TYPE_MODE.CONSUMPTION_ESTIMATE,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.CONSUMPTION_ESTIMATE')
      },
      {
        id: MODEL_TYPE_MODE.STATISTICAL,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.STATISTICAL')
      }
    ];
  }

  getSaveTypes() {
    return [
      {
        id: SAVE_TYPE.MODEL,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.SAVE_SETTINGS.AS_MODEL')
      },
      {
        id: SAVE_TYPE.CONSUMPTION,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.SAVE_SETTINGS.AS_CONSUMPTION')
      }
    ];
  }

  getSaveOptions() {
    return [
      {
        id: SAVE_OPTION.LOCK,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.SAVE_SETTINGS.AS_LOCKED'),
        defaultValue: false
      },
      {
        id: SAVE_OPTION.NEXT,
        name: this.utils.localizedString('MQA.INSPECT.ACTIONS.SAVE_SETTINGS.MOVE_ON_SAVE'),
        defaultValue: true
      }
    ];
  }
}

ManualQaActionsSelectService.$inject = $inject;

export default ManualQaActionsSelectService;
