import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import angular, { ITimeoutService, IWindowService } from 'angular';

import {
  CompanyModel,
  FunctionType,
  IContactClient,
  PersonModel
} from '@enerkey/clients/contact';

import { DEFAULT_COLOR, HEADER_BACKGROUND } from '../../../../shared/spreadsheet.functions';
import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import { FacilityRow } from '../../../contact-manager/shared/facility-row';
import { ToasterService } from '../../../../shared/services/toaster.service';

interface CreateFacilityFunctionGeneric {
  facilityId: number;
  functionType: FunctionType;
}

const inject = ['utils', 'ContactClient', '$element', '$timeout', '$window', 'KendoFunctions', 'ToasterService'];

class MassReassignPersonFacilitiesController extends MassImportSpreadsheet {
  private static facilityIdIndex: number = 0;
  private static enegiaIdIndex: number = 1;
  private static facilityNameIndex: number = 2;
  private static superintendentIndex: number = 3;
  private static managerIndex: number = 4;
  private static serviceIndex: number = 5;
  private static ownerIndex: number = 6;
  private static alarmIndex: number = 7;
  private static columnIndexes: number[] = [];

  public spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  public functionTypes: ({ translation: string; value: number })[];
  public functionType: number;
  public spreadsheetElementId = 'spreadsheet';
  public sheetInitialized = false;
  public selectedFacilities: FacilityRow[];
  public originalPerson: PersonModel;
  public targetPersonId: number;
  public targetCompany: CompanyModel;
  public justAlarmFunction: boolean;
  public saveReady: Function;
  public startSave: Observable<void>;

  protected messages: SpreadsheetMessages;
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected numberOfColumns = 9;
  private processedIndexes: number[] = [];

  public constructor(
    private utils: any,
    private contactClient: IContactClient,
    protected $element: JQuery,
    protected $timeout: ITimeoutService,
    protected $window: IWindowService,
    private kendoFunctions: any,
    private toasterService: ToasterService
  ) {
    super();
  }

  public $onInit(): void {
    MassReassignPersonFacilitiesController.columnIndexes = [
      MassReassignPersonFacilitiesController.facilityIdIndex,
      MassReassignPersonFacilitiesController.enegiaIdIndex,
      MassReassignPersonFacilitiesController.facilityNameIndex,
      MassReassignPersonFacilitiesController.superintendentIndex,
      MassReassignPersonFacilitiesController.managerIndex,
      MassReassignPersonFacilitiesController.serviceIndex,
      MassReassignPersonFacilitiesController.ownerIndex,
      MassReassignPersonFacilitiesController.alarmIndex
    ];

    this.startSave.subscribe(() => {
      this.saveValues();
    });

    this.spreadsheetOptions = this.getSpreadsheetOptions();
    this.totalRowCount = this.spreadsheetOptions.rows;
    this.messages = {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE')
    };

    this.$timeout(() => {
      this.resizeKendo();
    });
    this.spreadsheet = this.getSpreadsheetInstance();
    this.sheetInitialized = true;
  }

  private resizeKendo(): void {
    const spreadsheet = this.getSpreadsheetInstance();

    const resizeFn = _.debounce(() => {
      this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
    }, 200);

    angular.element(this.$window).on('resize', resizeFn);

    this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
  }

  private getCreateFunctionModels(): CreateFacilityFunctionGeneric[] {
    this.processedIndexes = [];
    const createFunctionModels: CreateFacilityFunctionGeneric[] = [];

    this.getDataRows().forEach((row, index) => {
      if (this.hasRequiredRowValues(row)) {
        const createFunctionModel: CreateFacilityFunctionGeneric = {
          facilityId: Number(row[MassReassignPersonFacilitiesController.facilityIdIndex]),
          functionType: this.rowCellsToFunctionType(row)
        };

        createFunctionModels.push(createFunctionModel);
        this.setProcessingStatus(index);
        this.processedIndexes.push(index);
      }
    });

    return createFunctionModels;
  }

  private hasRequiredRowValues(rowCells: (string | number)[]): boolean {
    return rowCells.length > 1 &&
      MassReassignPersonFacilitiesController.columnIndexes.every(index => rowCells[index] !== null);
  }

  private rowCellsToFunctionType(row: (string | number)[]): FunctionType {
    let availableFunctions: FunctionType = null;

    if (row[MassReassignPersonFacilitiesController.superintendentIndex] === this.booleanToLocalizedString(true)) {
      availableFunctions |= FunctionType.Superintendent;
    }
    if (row[MassReassignPersonFacilitiesController.managerIndex] === this.booleanToLocalizedString(true)) {
      availableFunctions |= FunctionType.Manager;
    }
    if (row[MassReassignPersonFacilitiesController.serviceIndex] === this.booleanToLocalizedString(true)) {
      availableFunctions |= FunctionType.Service;
    }
    if (row[MassReassignPersonFacilitiesController.ownerIndex] === this.booleanToLocalizedString(true)) {
      availableFunctions |= FunctionType.Owner;
    }
    if (row[MassReassignPersonFacilitiesController.alarmIndex] === this.booleanToLocalizedString(true)) {
      availableFunctions |= FunctionType.Alarm;
    }

    return availableFunctions;
  }

  private saveValues(): void {
    if (this.validateSheet()) {
      this.processedIndexes = [];
      const genericCreateFunctionModels = this.getCreateFunctionModels();
      if (genericCreateFunctionModels.length === 0) {
        this.toasterService.info('ADMIN.CONTACTS.ERRORS.EMPTY_SPREADSHEET');
      } else {
        this.updateEntities(genericCreateFunctionModels);
      }
    } else {
      this.toasterService.error('ADMIN.CONTACTS.ERRORS.INVALID_SPREADSHEET');
    }
  }

  private updateEntities(genericCreateFunctionModels: CreateFacilityFunctionGeneric[]): void {
    // NOTE: if targetCompany doesn't have function available, reassign fails
    const filteredCreateFunctionModels = genericCreateFunctionModels.filter(item => item.functionType !== null);

    if (filteredCreateFunctionModels.length < genericCreateFunctionModels.length) {
      this.toasterService.warning('CONTACTMANAGER.WARNING.FACILITY_REASSIGN_WILL_FAIL');
    }

    const addPersonFunctions = this.contactClient
      .addPersonFacilityFunctions(this.targetPersonId, filteredCreateFunctionModels);

    if (!this.justAlarmFunction) {
      const addCompanyFunctions = this.contactClient
        .addCompanyFacilityFunctions(this.targetCompany.id, filteredCreateFunctionModels);
      forkJoin([addCompanyFunctions, addPersonFunctions]).subscribe({
        next: () => this.handleSuccess('CONTACTMANAGER.SUCCESS.FACILITIES_REASSIGNED'),
        error: () => this.handleError('CONTACTMANAGER.ERROR.FAILED_TO_REASSIGN_FACILITIES')
      });
    } else {
      addPersonFunctions
        .subscribe({
          next: () => this.handleSuccess('CONTACTMANAGER.SUCCESS.FACILITIES_REASSIGNED'),
          error: () => this.handleError('CONTACTMANAGER.ERROR.FAILED_TO_REASSIGN_FACILITIES')
        });
    }
  }

  private handleSuccess(message: string): void {
    this.toasterService.success(message);
  }

  private handleError(message: string): void {
    this.toasterService.error(message);
  }

  private getSpreadsheetOptions(): kendo.ui.SpreadsheetOptions {
    let headerCells: any[] = [];

    headerCells = headerCells.concat(this.createTextHeaderCells());

    const booleanHeaderCells: kendo.ui.SpreadsheetSheetRowCell[] = this.createBooleanHeaderCells();
    headerCells = headerCells.concat(booleanHeaderCells);

    const statusHeaderCell = {
      value: this.utils.localizedString('ADMIN.SPREADSHEET.STATUS.HEADING'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    };
    headerCells = headerCells.concat(statusHeaderCell);

    const spreadsheetOptions: kendo.ui.SpreadsheetOptions = {
      toolbar: false,
      sheetsbar: false,
      columns: this.numberOfColumns,
      excel: {
        fileName: 'mass_reassign_person_facilities.xlsx'
      },
      rows: 999,
      defaultCellStyle: {
        background: 'rgba(255,255,255,0)'
      },
      sheets: [
        {
          name: 'default',
          columns: this.createColumnWidths(),
          rows: [{
            cells: headerCells
          }]
        }
      ]
    };

    this.selectedFacilities.forEach(selectedFacility => {
      const cells = this.createInputRow(booleanHeaderCells, selectedFacility);
      spreadsheetOptions.sheets[0].rows.push({ cells: cells });
    });

    return spreadsheetOptions;
  }

  private createColumnWidths(): kendo.ui.SpreadsheetSheetColumn[] {
    const columns: kendo.ui.SpreadsheetSheetColumn[] = [];
    for (let i = 0; i < this.numberOfColumns; i++) {
      columns.push({ width: 150 });
    }
    return columns;
  }

  private booleanToLocalizedString(value: boolean): string {
    if (value) {
      return `${this.utils.localizedString('YES')}`;
    } else {
      return `${this.utils.localizedString('NO')}`;
    }
  }

  private originalPersonHasFunctionInFacility(facilityId: number, functionType: FunctionType): boolean {
    if (this.originalPerson.personFacilityFunctions[facilityId] & functionType) {
      return true;
    } else {
      return false;
    }
  }

  private cellIsForFunctionType(cell: kendo.ui.SpreadsheetSheetRowCell, functionType: FunctionType): boolean {
    return cell.value === this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[functionType]}`);
  }

  private getCellFunctionType(cell: kendo.ui.SpreadsheetSheetRowCell): FunctionType {
    if (this.cellIsForFunctionType(cell, FunctionType.Superintendent)) {
      return FunctionType.Superintendent;
    }
    if (this.cellIsForFunctionType(cell, FunctionType.Manager)) {
      return FunctionType.Manager;
    }
    if (this.cellIsForFunctionType(cell, FunctionType.Service)) {
      return FunctionType.Service;
    }
    if (this.cellIsForFunctionType(cell, FunctionType.Owner)) {
      return FunctionType.Owner;
    }
    if (this.cellIsForFunctionType(cell, FunctionType.Alarm)) {
      return FunctionType.Alarm;
    }
  }

  private parseFunctionCellValue(cell: kendo.ui.SpreadsheetSheetRowCell, selectedFacility: FacilityRow): string {
    if (this.justAlarmFunction) {
      return this.booleanToLocalizedString(this.getCellFunctionType(cell) === FunctionType.Alarm);
    }
    return this.booleanToLocalizedString(
      this.originalPersonHasFunctionInFacility(selectedFacility.facilityId, this.getCellFunctionType(cell)) &&
      this.targetCompanyHasFunctionAvailable(this.getCellFunctionType(cell))
    );
  }

  private targetCompanyHasFunctionAvailable(functionType: FunctionType): boolean {
    if (this.targetCompany.availableFunctions.superintendentFunction && functionType === FunctionType.Superintendent) {
      return true;
    }
    if (this.targetCompany.availableFunctions.managerFunction && functionType === FunctionType.Manager) {
      return true;
    }
    if (this.targetCompany.availableFunctions.serviceFunction && functionType === FunctionType.Service) {
      return true;
    }
    if (this.targetCompany.availableFunctions.ownerFunction && functionType === FunctionType.Owner) {
      return true;
    }
    if (this.targetCompany.availableFunctions.alarmFunction && functionType === FunctionType.Alarm) {
      return true;
    }
    return false;
  }

  private cellIsEnabled(cell: kendo.ui.SpreadsheetSheetRowCell): boolean {
    return !this.justAlarmFunction && this.targetCompanyHasFunctionAvailable(this.getCellFunctionType(cell));
  }

  private createBooleanHeaderCells(): kendo.ui.SpreadsheetSheetRowCell[] {
    return [{
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Superintendent]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Manager]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Service]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Owner]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Alarm]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }];
  }

  private createTextHeaderCells(): kendo.ui.SpreadsheetSheetRowCell[] {
    return [{
      value: this.utils.localizedString('ADMIN.ID'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    },
    {
      value: this.utils.localizedString('CONTACTMANAGER.FACILITY.ENEGIAID'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    },
    {
      value: this.utils.localizedString('CONTACTMANAGER.FACILITY.NAME'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }];
  }

  private createInputRow(booleanHeaderCells: kendo.ui.SpreadsheetSheetRowCell[],
    selectedFacility: FacilityRow): kendo.ui.SpreadsheetSheetRowCell[] {
    const cells = [];

    cells.push({
      value: selectedFacility.facilityId,
      enable: false
    });

    cells.push({
      value: selectedFacility.enegiaId,
      enable: false
    });

    cells.push({
      value: selectedFacility.name,
      enable: false
    });

    const roleValues: Array<string> = [];
    const yesValue = this.booleanToLocalizedString(true);
    const noValue = this.booleanToLocalizedString(false);
    booleanHeaderCells.forEach(booleanHeaderCell => {
      const value = this.parseFunctionCellValue(booleanHeaderCell, selectedFacility);
      roleValues.push(value);

      cells.push({
        type: 'text',
        value: value,
        enable: !this.justAlarmFunction && this.cellIsEnabled(booleanHeaderCell),
        validation: {
          dataType: 'list',
          showButton: true,
          from: `{"${yesValue}", "${noValue}"}`,
          allowNulls: true,
          type: 'reject',
          messageTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.TITLE'),
          titleTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.MESSAGE')
        }
      });
    });

    if (!roleValues.includes(this.utils.localizedString('YES'))) {
      cells.push({
        type: 'text',
        value: this.utils.localizedString('CONTACTMANAGER.WARNING.FACILITY_REASSIGN_WILL_FAIL')
      });
    }

    // status should not be editable
    cells.push({
      enable: false
    });

    return cells;
  }
}

MassReassignPersonFacilitiesController.$inject = inject;

export default MassReassignPersonFacilitiesController;

