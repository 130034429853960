import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';

import { EmissionsAggregateValue } from '../../shared/emissions';

interface EmissionPoint {
  dataItem: EmissionsAggregateValue;
  value: number;
}

@Component({
  selector: 'emissions-widget-chart-tooltip',
  templateUrl: './emissions-widget-chart-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmissionsWidgetChartTooltipComponent {
  @Input() public category: Quantities;
  @Input() public points: EmissionPoint[];
}
