/* eslint-disable @typescript-eslint/ban-types */

import { DeepPartial } from './types';

/**
 * Returns the parameter object. Use in place of `as T` or `as any` to get partial type safety.
 * @example
 * someApiCall(mockOf<SomeHugeObject>({ id: 1 }));
 *
 * Uses DeepPartial with default depth of 3 is used because
 * With infinite depth compiler becomes slow when used with complex types.
 * That means that first three levels are type safe and levels below that are any.
 */
export function mockOf<T, Depth extends number = 3>(arg: DeepPartial<T, Depth>): T {
  return arg as T;
}
