import FormulaEditorTreelistController from './formula-editor-treelist.controller';
import template from 'raw-loader!./formula-editor-treelist.html';

export default {
  template,
  controller: FormulaEditorTreelistController,
  controllerAs: 'vm',
  bindings: {
    formulaData: '<',
    loading: '<',
    addItem: '&',
    deleteItem: '&',
    editItem: '&'
  }
};
