import { oleToDate } from '../../../shared/date.functions';
import { FacilityCreateModel } from '../components/facility-create/facility-create-model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function setOleDatesToDates(facilityArray: FacilityCreateModel[]): FacilityCreateModel[] {
  return facilityArray.map(facility => {
    facility.reportingEndDate = facility.reportingEndDate ? oleToDate(facility.reportingEndDate) : null;
    facility.billingEndDate = facility.billingEndDate ? oleToDate(facility.billingEndDate) : null;
    facility.powerOfAttorneyExpirationDate =
      facility.powerOfAttorneyExpirationDate ? oleToDate(facility.powerOfAttorneyExpirationDate) : null;
    return facility;
  });
}

