import { sortQuantities } from '../../../configuration/shared/quantities.functions';
import { ViewSettings } from '../../constants/view-settings';
import { qaFilterTranslations, SearchCriteria } from '../../shared/search-criteria';
import { QualityAssuranceFilter, SearchList } from '@enerkey/clients/manual-qa';
import { FacilitySearchType } from '../../constants/facility-search-type';
import { SearchItemType } from '../../constants/search-item-type';
import { getNumericEnumValues } from '@enerkey/ts-utils';

const $inject = [
  'ManualQaApiQuantityService', 'ManualQaViewSettingService',
  'ManualQaMeteringService', 'ManualQaMeterHierarchyService', 'quantities', 'utils'
];

class ManualQaSearchCriteriaController {
  constructor(
    ManualQaApiQuantityService, ManualQaViewSettingService,
    ManualQaMeteringService, ManualQaMeterHierarchyService, quantities, utils
  ) {
    this.ManualQaApiQuantityService = ManualQaApiQuantityService;
    this.ManualQaViewSettingService = ManualQaViewSettingService;
    this.ManualQaMeteringService = ManualQaMeteringService;
    this.ManualQaMeterHierarchyService = ManualQaMeterHierarchyService;
    this.QuantitiesService = quantities;

    this.quantities = [];
    this.searchOptions = [];
    this.meteringTypes = null;
    this.meterHierarchyTypes = null;
    this.constants = ViewSettings;
    this.isFacilitiesView = true;
    this.searchType = FacilitySearchType;
    this.SearchItemType = SearchItemType;

    this.qaFilterOptions = getNumericEnumValues(QualityAssuranceFilter).map(value => ({
      id: value,
      name: utils.localizedString(qaFilterTranslations[value]),
    }));

    this.multiSelectOptions = {
      filter: 'contains',
      autoClose: false
    };
  }

  $onInit() {
    this.searchList = angular.copy(this.searchList);

    this.isFacilitiesView = this.searchList.getSearchCriteria().getViewSetting() === this.constants.FACILITIES;

    this.getQuantities();

    this.searchOptions = this.ManualQaViewSettingService.getViewSettings();
    this.meteringTypes = this.ManualQaMeteringService.getMeteringTypes();
    this.meterHierarchyTypes = this.ManualQaMeterHierarchyService.getMeterHierarchyTypes();

    this.activateButtonByViewSetting(this.searchList.getSearchCriteria().getViewSetting());
    this.searchItemType = this.searchList.getSearchCriteria().searchItemType;
  }

  getQuantities() {
    this.ManualQaApiQuantityService
      .getQuantities()
      .then(quantities => this.localizeQuantities(quantities))
      .then(sortQuantities)
      .then(quantities => {
        this.quantities = quantities.map(q => q.id);
      })
    ;
  }

  localizeQuantities(quantities) {
    return quantities.map(quantity => ({
      ...quantity,
      name: this.QuantitiesService.getQuantityLocalizedName(quantity.name)
    }));
  }

  activateButtonByViewSetting(viewSetting) {
    this.searchOptions = this.searchOptions.map(searchOption => {
      if (searchOption.id === viewSetting) {
        searchOption.active = true;
      }

      return searchOption;
    });
  }

  onSearchCriteriaValueChange(...changes) {
    const changedCriteriaValue = {};

    for (const item of changes) {
      changedCriteriaValue[item.key] = item.value;
    }

    const searchCriteria = new SearchCriteria({ ...this.searchList.getSearchCriteria(), ...changedCriteriaValue });

    this.searchList = SearchList.fromJS({ ...this.searchList, searchCriteria: JSON.stringify(searchCriteria) });

    this.isFacilitiesView = this.searchList.getSearchCriteria().getViewSetting() === this.constants.FACILITIES;
  }

  onSearchItemTypeChange() {
    this.onSearchCriteriaValueChange({
      key: 'searchItemType',
      value: this.searchItemType
    });
  }
}

ManualQaSearchCriteriaController.$inject = $inject;

export default ManualQaSearchCriteriaController;
