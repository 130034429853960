const $inject = ['$timeout', 'donutVisualsService'];

class DonutVisualsController {
  constructor($timeout, donutVisualsService) {
    this.$timeout = $timeout;
    this.donutVisualsService = donutVisualsService;

    this.chartDataAndOptions = [];
  }

  $onInit() {
    this.update({ func: this.init.bind(this) });
    this.init();
  }

  init() {
    this.$timeout(() => {
      this.createCharts(this.viewData, this.aggregates);
    });
  }

  createCharts(viewData, totalAggregates) {
    const chartsConfig = this.donutVisualsService
      .getChartsConfig(this.config, this.columns, viewData, totalAggregates);

    this.chartDataAndOptions = chartsConfig;
  }

  setChartOptions(index, viewPercentual) {
    const chartCategory = this.chartDataAndOptions[index];
    chartCategory.setViewPercentual(viewPercentual);
  }
}

DonutVisualsController.$inject = $inject;
export default DonutVisualsController;
