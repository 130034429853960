const $inject = ['$modalInstance', 'actionsList'];

class EmMassEditModalController {
  constructor($modalInstance, actionsList) {
    this.actionsList = actionsList;
    this.modalDismiss = $modalInstance.dismiss;
    this.modalClose = $modalInstance.close;
  }
}

EmMassEditModalController.$inject = $inject;

export default EmMassEditModalController;
