import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IRowUnit } from '@enerkey/clients/sustainability';

@Component({
  selector: 'gri-unit-name',
  templateUrl: './gri-unit-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GriUnitNameComponent {
  @Input() public unit: IRowUnit;
}
