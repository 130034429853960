/* eslint-disable @typescript-eslint/indent */
import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, forkJoin, map, Observable, shareReplay, Subject, switchMap, take, takeUntil } from 'rxjs';

import {
  MeterConsumptionRequest,
  ReportingClient,
  RequestDuration,
  RequestResolution
} from '@enerkey/clients/reporting';
import { IMeterWithProperties, MeterHierarchyMeter, MeteringClient, Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject, switchJoin } from '@enerkey/rxjs';
import { Co2eq, RowMetadata, SustainabilityClient } from '@enerkey/clients/sustainability';
import { FacilityClient, FacilityPropertyEnums } from '@enerkey/clients/facility';

import { FacilityService } from '../../../shared/services/facility.service';
import { localToUtc } from '../../../shared/date.functions';
import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';
import { ProfileService } from '../../../shared/services/profile.service';

export class GriMeterItem {
  public readonly id: number;
  public readonly name: string;
  public readonly quantityId: number;
  public readonly facilityId: number;
  public readonly facilityName: string;
  public readonly facilityCountryCode?: string | null;

  public readonly text: string;

  public constructor(meter: IMeterWithProperties | MeterHierarchyMeter, facility: ExtendedFacilityInformation) {
    this.id = meter.id;
    this.name = meter.name;
    this.quantityId = meter.quantityId;
    this.facilityId = facility.facilityId;
    this.facilityName = facility.name;
    this.facilityCountryCode = facility.FacilityInformation?.CountryCode;

    this.text = `${meter.name}\t${facility.name}\t${meter.id}`;
  }
}

export type GriFacilityCo2Factor = {
  from: Date;
  value: number;
  facilityId: number;
  facilityName: string;
  unit: string;
  unitScale?: Co2eq;
  quantityId: number;
}

export type QuantityWithUnitName = {
  id: number
  name: string
  description: string
  unitId: number
  quantityType: number
  unitName: string
  defaultUnit: string
}

export type GriCo2Factor = {
  quantityId: number;
  countryCode: string;
  from: Date;
  value: number;
}

@Injectable()
export class GriImportService implements OnDestroy {

  public readonly allMeters$: Observable<GriMeterItem[]>;
  public readonly mainMeters$: Observable<GriMeterItem[]>;
  public readonly co2factors$: Observable<GriFacilityCo2Factor[]>;
  public readonly countryBasedCo2Factors$: Observable<GriCo2Factor[]>;
  public readonly quantitiesThatHaveFactorsInKG$: Observable<number[]>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    meteringClient: MeteringClient,
    facilityClient: FacilityClient,
    profileService: ProfileService,
    facilityService: FacilityService,
    private readonly reportingClient: ReportingClient,
    private readonly sustainabilityClient: SustainabilityClient
  ) {
    this.loading$ = this._loading$.asObservable();

    this.allMeters$ = profileService.profile$.pipe(
      switchMap(() => facilityService.filteredProfileFacilities$.pipe(take(1))),
      switchJoin(facilities => meteringClient.getMetersForFacilityIds(
        facilities.map(f => f.facilityId)
      ).pipe(indicate(this._loading$))),
      map(([facilities, metersByFacility]) => Object.values(metersByFacility).flat().joinInner(
        facilities,
        m => m.facilityId,
        f => f.facilityId,
        (m, f) => new GriMeterItem(m, f)
      ).sortByMany('facilityName', 'name', 'id')),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.mainMeters$ = profileService.profile$.pipe(
      switchMap(() => facilityService.filteredProfileFacilities$.pipe(take(1))),
      switchJoin(facilities => meteringClient.getMeterHierarchyIdsForReportingObjects(
        facilities.map(f => f.facilityId)
      ).pipe(
        switchMap(meterHierarchies => meteringClient.getMainMetersForMeterHierarchies(
          meterHierarchies.map(h => h.meterHierarchyIds).flat()
        ).pipe(indicate(this._loading$)))
      )),
      map(([facilities, mainMeters]) => mainMeters.joinInner(
        facilities,
        m => m.reportingObjectId,
        f => f.facilityId,
        (m, f) => new GriMeterItem(m, f)
      ).sortByMany('facilityName', 'name', 'id')),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.quantitiesThatHaveFactorsInKG$ = combineLatest({
      mQuantities: meteringClient.getQuantities(),
      mUnits: meteringClient.getUnits(),
      defaultUnits: reportingClient.getQuantityUnits(),
    }).pipe(
      map(({ mQuantities, mUnits, defaultUnits }) => mQuantities.map(q => {
        const unitName = mUnits.find(u => u.id === q.unitId)?.name;
        const defaultUnit = defaultUnits.default[q.id];
        return ({
          ...q,
          unitName,
          defaultUnit,
        }) as QuantityWithUnitName;
      })
        .filter(x => this.isMWhActuallyKWh(x.unitName, x.defaultUnit))
        .map(x => x.id)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.co2factors$ = forkJoin({
      facilities: facilityService.filteredProfileFacilities$.pipe(take(1)),
      units: reportingClient.getQuantityUnits(),
      quantitiesUnits: this.quantitiesThatHaveFactorsInKG$,
    }).pipe(
      switchJoin(({ facilities }) => facilityClient
        .getFacilityProperties(facilities.map(f => f.facilityId), [FacilityPropertyEnums.Co2Factor])
        .pipe(indicate(this._loading$))),
      map((
        [{ facilities, units, quantitiesUnits }, propertiesByFacility]
      ) => Object.integerEntries(propertiesByFacility).flatMap(
        ([facilityId, props]) => props
          .mapFilter<GriFacilityCo2Factor>(
            p => ({
              facilityId,
              facilityName: facilities.find(f => f.facilityId === facilityId)?.name,
              quantityId: p.quantityId,
              value: Number(p.value),
              from: p.fromDate,
              unit: units.default[p.quantityId],
              unitScale: quantitiesUnits.includes(p.quantityId) ? Co2eq.Kilograms : Co2eq.Grams,
            }),
            x => Number.isFinite(x.value)
          ).sortByMany('facilityName', 'quantityId', 'from')
      )),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.countryBasedCo2Factors$ = this.sustainabilityClient.getCo2Factors().pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$),
      map(factors => factors.map<GriCo2Factor>(f => ({
            quantityId: f.quantityId,
            countryCode: f.countryCode,
            from: f.fromDate,
            value: f.value,
        })))
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public getMeters(): Observable<GriMeterItem[]> {
    return this.allMeters$.pipe(take(1));
  }

  public getMetadata(
    year: number,
    meters: GriMeterItem[],
    quantityId: Quantities,
    existing: RowMetadata = null
  ): Observable<RowMetadata> {
    return this.reportingClient.getConsumptionsForMeters(new MeterConsumptionRequest({
      quantityId,
      facilityIds: meters.unique('facilityId'),
      resolution: RequestResolution.P1M,
      start: localToUtc(new Date(year, 0, 1)),
      duration: new RequestDuration({ years: 1 })
    })).pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$),
      map(response => {
        const data = Object.values(response.consumptions)
          .map(values => Object.integerEntries(values))
          .flat()
          .filter(([meterId]) => meters.some(m => m.id === meterId))
          .toRecord(
            ([meterId]) => meterId,
            ([_, consumptions]) => consumptions.values.map(v => v.value)
          );

        return new RowMetadata({
          consumptions: data,
          excludedMeters: existing?.excludedMeters?.filter(id => id in data) ?? [],
          importedAt: new Date(),
          unit: response.unit,
        });
      })
    );
  }

  private isMWhActuallyKWh(unit: string, defaultUnit: string): boolean {
    return unit === 'kWh' && defaultUnit === 'MWh';
  }
}
