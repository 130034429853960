<ng-container [ngSwitch]="status">
  <i
    *ngSwitchCase="EmailSendStatus.Success"
    class="fas fa-check email-icon-success"
  ></i>
  <i
    *ngSwitchCase="EmailSendStatus.Fail"
    class="fas fa-exclamation-circle email-icon-fail"
  ></i>
  <i
    *ngSwitchCase="EmailSendStatus.Skip"
    class="fas fa-ban email-icon-skip"
  ></i>
</ng-container>
