import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { GridsterComponent, GridsterConfig, GridsterItem } from 'angular-gridster2';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';

import { Dashboard } from '@enerkey/clients/settings';
import { WINDOW } from '@enerkey/angular-utils';

import { EditableWidgetSettings } from '../../shared/editable-widget-settings';
import { DashboardService } from '../../services/dashboard.service';
import { EnerkeyWidget } from '../../shared/enerkey-widget';
import { WidgetQueueService } from '../../services/widget-queue.service';
import { WidgetDefinitionsService } from '../../services/widget-definitions.service';
import { WindowService } from '../../../../shared/services/window.service';
import { WidgetType } from '../../shared/widget-type';

interface WidgetDimensions {
  sizeX: number;
  sizeY: number;
  posX: number;
  posY: number;
}

const rowHeight = 265;

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WidgetQueueService],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @Input() public dashboard: Dashboard;
  @ViewChild(GridsterComponent) public gridster: GridsterComponent;

  public readonly widgets$ = new BehaviorSubject<EnerkeyWidget[]>([]);

  public readonly options: GridsterConfig;

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dashboardService: DashboardService,
    private readonly widgetDefinitionsService: WidgetDefinitionsService,
    private readonly windowService: WindowService,
    @Inject(WINDOW) private readonly windowObject: Window
  ) {
    const columns = this.getColumnAmount();

    this.options = {
      gridType: 'verticalFixed',
      setGridSize: false,
      fixedRowHeight: rowHeight,
      compactType: 'compactUp',
      itemChangeCallback: () => this.saveDashboard(),
      maxItemRows: 4,
      maxItemCols: 4,
      minItemRows: 1,
      minItemCols: 1,
      maxCols: columns,
      minCols: columns,
      margin: 10,
      scrollToNewItems: true,
      mobileBreakpoint: 800,
      draggable: {
        enabled: true,
        ignoreContent: true,
        dropOverItems: true,
        ignoreContentClass: 'widget-settings-button',
        start: (_, gridsterItemComponent) => {
          gridsterItemComponent.el.style.zIndex = '2';
        },
        stop: (_, gridsterItemComponent) => {
          gridsterItemComponent.el.style.zIndex = '1';
        },
      },
      disableScrollHorizontal: true,
      resizable: {
        enabled: true,
        handles: {
          se: true,
          sw: true,
          s: false,
          e: false,
          n: false,
          w: false,
          ne: false,
          nw: false,
        }
      },
      displayGrids: true,
      pushItems: true,
    };

    this.dashboardService.addWidget$
      .pipe(takeUntil(this.destroy$))
      .subscribe(newWidget => {
        this.widgets$.next([...this.widgets$.value, ...newWidget]);
        this.changeDetectorRef.detectChanges();
        this.saveDashboard();
      });
  }

  public ngOnInit(): void {
    this.widgetDefinitionsService.getAllowedWidgetTypes().pipe(
      map(allowedWidgets => this.dashboard?.widgets?.filter(
        widget => allowedWidgets.includes(widget.name as WidgetType)
      )),
      switchMap(
        allowedWidgets => Array.hasItems(allowedWidgets)
          ? forkJoin(allowedWidgets.map<Observable<EnerkeyWidget>>(
            widget => {
              const columns = this.getColumnAmount();
              const widgetPos = this.getWidgetDimensionsForColumns(widget, columns);
              // Get widget default options to fill possible missing dataModelOptions properties.
              // Widgets and settings modals don't work when widget dataModelOptions has missing properties.
              const defaultOptions = this.widgetDefinitionsService.getWidgetDefinition(widget.name).defaultOptions?.()
                ?? of({});
              return defaultOptions.pipe(
                map((options: any) => ({
                  ...widget,
                  dataModelOptions: {
                    ...options,
                    ...widget.dataModelOptions
                  },
                  widgetType: widget.name,
                  title: widget.title,
                  cols: widgetPos.sizeX,
                  rows: widgetPos.sizeY,
                  x: widgetPos.posX,
                  y: widgetPos.posY,
                }))
              );
            }
          ))
          : of([])
      ),
      switchMap(
        existingWidgets => {
          const setNewWidgets = this.dashboard.isDefault && !Array.hasItems(this.dashboard.widgets);
          const widgets = setNewWidgets
            ? this.dashboardService.getDefaultWidgets()
            : of(existingWidgets);
          return forkJoin([of(setNewWidgets), widgets]);
        }
      ),
      takeUntil(this.destroy$)
    ).subscribe(
      ([setNewWidgets, widgets]) => {
        this.widgets$.next(widgets);

        this.dashboardService.setUsedWidgetAmounts(widgets);

        this.changeDetectorRef.detectChanges();

        if (setNewWidgets) {
          this.saveDashboard();
        }
      }
    );

    this.windowService.resize$.pipe(
      map(() => this.getColumnAmount()),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(columns => {
      this.options.maxCols = columns;
      this.options.minCols = columns;
      this.options.api.optionsChanged();

      this.gridster.grid.forEach(widget => {
        const widgetPos = this.getWidgetDimensionsForColumns(widget.item, columns);
        widget.$item.x = widgetPos.sizeX + widgetPos.posX > columns ? 0 : widgetPos.posX;
        widget.$item.y = widgetPos.posY;
        widget.$item.cols = Math.min(widgetPos.sizeX, columns);
        widget.$item.rows = widgetPos.sizeY;
      });

      this.autoPosition();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.widgets$.complete();
  }

  public widgetSettingChange(item: EnerkeyWidget, settings: EditableWidgetSettings): void {
    item.title = settings.title;
    item.dataModelOptions = settings.dataModelOptions;
    this.saveDashboard();
  }

  public deleteWidget(item: EnerkeyWidget): void {
    this.widgets$.next(this.widgets$.value.filter(widget => widget !== item));
    this.saveDashboard();
  }

  private autoPosition(): void {
    this.gridster.grid.forEach(item => {
      this.gridster.getNextPossiblePosition(item.$item);
      item.checkItemChanges(item.$item, item.item);
    });
  }

  private getColumnAmount(): number {
    const elementWidth = this.windowObject.innerWidth;
    const columns = Math.floor((elementWidth + 10) / (rowHeight + 10));
    return columns - (columns % 2);
  }

  private getWidgetDimensionsForColumns(widget: GridsterItem, columns: number): WidgetDimensions {
    const sizeX = widget[`sizeX${columns}`] || 2;
    const sizeY = widget[`sizeY${columns}`] || 2;
    const posX = widget[`col${columns}`] ?? 0;
    const posY = widget[`row${columns}`] ?? 0;

    return {
      sizeX,
      sizeY,
      posX,
      posY,
    };
  }

  private setWidgetDimensionsForColumns(widget: GridsterItem, columns: number): void {
    widget[`sizeX${columns}`] = widget.cols;
    widget[`sizeY${columns}`] = widget.rows;
    widget[`col${columns}`] = widget.x;
    widget[`row${columns}`] = widget.y;
  }

  private saveDashboard(): void {
    const columns = this.getColumnAmount();
    this.dashboardService.setUsedWidgetAmounts(this.widgets$.value);
    this.widgets$.value.forEach((widget: GridsterItem) => {
      this.setWidgetDimensionsForColumns(widget, columns);
    });
    const editedDashboard = this.dashboard.clone();
    editedDashboard.widgets = this.widgets$.value.map((widget: GridsterItem) => {
      const { x, y, cols, rows, widgetType, ...propertiesToSave } = widget;
      return {
        ...propertiesToSave,
        name: widgetType,
      };
    });
    this.dashboardService.saveDashboard(editedDashboard);
  }
}
