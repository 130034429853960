import _ from 'lodash';

import AdminSpreadsheetConstants from './admin-spreadsheet-constants';
import { ToasterType } from '../../../../constants/toaster-type';
import { removeSpreadsheetOrphans } from '../../../../shared/ek-kendo/kendo.functions';
import { oleDateToSortableString, oleToDate } from '../../../../shared/date.functions';
import { FacilityPropertyEnums } from '@enerkey/clients/facility';

AdminSpreadsheetController.$inject = [
  '$element', '$q', '$window', '$timeout', 'AdminSpreadsheetService',
  'SpreadsheetService', 'HttpPendingRequestsService', 'KendoFunctions',
  'utils', 'facilities'
];

export default function AdminSpreadsheetController(
  $element, $q, $window, $timeout, AdminSpreadsheetService,
  SpreadsheetService, HttpPendingRequestsService, KendoFunctions,
  utils, facilities
) {

  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this;
  vm.colors = SpreadsheetService.getColors();
  vm.spreadsheet = null;
  vm.initialized = false;
  vm.isSpreadsheetInvalid = false;
  vm.firstDataRowChar = SpreadsheetService.indexToColumnCharacter(AdminSpreadsheetConstants.COLUMNS.ENEGIA_ID);
  vm.lastDataRowChar = SpreadsheetService.indexToColumnCharacter(AdminSpreadsheetConstants.COLUMNS.VALUE);

  let resizeFn = angular.noop();
  vm.$onInit = onInit;
  vm.$onDestroy = onDestroy;
  vm.saveValues = saveValues;
  vm.stopSaving = stopSaving;
  vm.validateSheet = validateSheet;

  vm.messages = {
    errorStatus: utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
    conflictStatus: utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
    savedStatus: utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
    savingStatus: utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
    canceledStatus: utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
    deletedStatus: utils.localizedString('ADMIN.SPREADSHEET.DELETED'),
    deletingStatus: utils.localizedString('ADMIN.SPREADSHEET.DELETING'),
  };
  vm.steps = [
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
      isReturnable: true,
      clickFn: vm.validateSheet,
      rollbackFn: rollback
    },
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.VALIDATE'),
      isReturnable: false,
      clickFn: vm.saveValues,
      buttonDisabledFn: isSavePossible,
      customButtonText: utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
    },
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
      isReturnable: false,
      clickFn: vm.stopSaving,
      buttonDisabledFn: isSaveStopped,
      customButtonText: utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING'),
    },
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.READY'),
      hideButton: true
    }
  ];

  function rollback() {
    vm.spreadsheet = getSpreadsheetInstance();
    vm.stop = false;
    vm.spreadsheet.activeSheet()
      .range(`${vm.firstDataRowChar }:${ vm.lastDataRowChar }${AdminSpreadsheetConstants.DEFAULT_ROW_COUNT}`)
      .enable(true);
  }

  function isSavePossible() {
    return vm.isSpreadsheetInvalid;
  }

  function isSaveStopped() {
    return vm.stop;
  }

  function onInit() {
    let promise = AdminSpreadsheetService.getSpreadsheetOptions(vm.facilityIds);
    if (vm.includeQuantityId) {
      promise = AdminSpreadsheetService.getCo2SpreadsheetOptions(vm.facilityIds);
    } else if (vm.includeCustomType) {
      promise = AdminSpreadsheetService.getCustomTypeSpreadsheetOptions(vm.facilityIds);
    }

    promise.then(handleSuccess);
  }

  function handleSuccess(result) {
    vm.spreadsheetOptions = result;
    vm.spreadsheet = getSpreadsheetInstance();
    vm.initialized = true;
    initialize();
  }

  function initialize() {
    $timeout(() => {
      resizeKendo();
      vm.onSpreadsheetCreate({ spreadsheet: vm.spreadsheet });
    });
  }

  function resizeKendo() {
    vm.spreadsheet = getSpreadsheetInstance();
    resizeFn = _.debounce(() => {
      KendoFunctions.resizeKendoComponent(vm.spreadsheet, '.spreadsheet-row');
    }, 200);

    angular.element($window).on('resize', resizeFn);

    KendoFunctions.resizeKendoComponent(vm.spreadsheet, '.spreadsheet-row');
  }

  function validateSheet() {
    vm.isSpreadsheetInvalid = false;
    vm.spreadsheet = getSpreadsheetInstance();
    vm.spreadsheet.activeSheet()
      .range(`${vm.firstDataRowChar }:${ vm.lastDataRowChar }${AdminSpreadsheetConstants.DEFAULT_ROW_COUNT}`)
      .enable(false);
    const sheet = vm.spreadsheet.activeSheet();
    const range = sheet.range(`${vm.firstDataRowChar + AdminSpreadsheetConstants.READING_START_ROW }:${
      vm.lastDataRowChar }${AdminSpreadsheetConstants.DEFAULT_ROW_COUNT}`);
    if (!getRowsWithData(vm.firstDataRowChar, vm.lastDataRowChar).values().length > 0) {
      vm.isSpreadsheetInvalid = true;
    } else {
      range.forEachCell((row, column, cell) => {
        if (cell.validation && !cell.validation.value) {
          vm.isSpreadsheetInvalid = true;
        }
      });
    }
  }

  function getColumnCharacter(columnIndex) {
    return SpreadsheetService.indexToColumnCharacter(columnIndex);
  }

  function saveValues() {
    vm.processingProperties = [];
    vm.spreadsheet = getSpreadsheetInstance();
    const properties = {};
    const toBeDeletedProperties = {};
    facilities.getFacilitiesDirect(vm.facilityIds).then(result => {
      const dataRows = getRowsWithData(vm.firstDataRowChar, vm.lastDataRowChar).values();
      vm.facilityIdsMap = getFacilityIdsFromEnegiaIds(dataRows, result);
      dataRows.forEach(row => {
        // value is null -> deleting
        if (getRowValue(row) === null) {
          const facilityId = vm.facilityIdsMap[getRowEnegiaId(row)];
          const property = getPropertyFromResult(row, result, facilityId);
          if (property && property.id) {
            pushToObjectArray(toBeDeletedProperties, facilityId, property);
          }
          // else saving
        } else {
          const property = getPropertyFromRow(row);
          const facilityId = vm.facilityIdsMap[getRowEnegiaId(row)];
          pushToObjectArray(properties, facilityId, property);
        }

      });
      let promises = saveFacilityProperties(properties);
      promises = [...promises, deleteFacilityProperties(toBeDeletedProperties)];

      $q.all(promises).finally(setFinalStep);
    });
  }

  function pushToObjectArray(array, id, pushValue) {
    array[id] = array[id] || [];
    array[id].push(pushValue);
    return array;
  }

  function getPropertyFromRow(row) {
    return {
      value: getRowValue(row),
      fromDate: oleToDate(getRowDate(row)),
      toDate: null,
      propertyId: getRowPropertyId(row),
      quantityId: getRowQuantityId(row),
      customType: getRowCustomType(row)
    };
  }

  function getPropertyFromResult(row, result, facilityId) {
    const facility = result.find(facilityResult => facilityResult.id === facilityId);
    const date = oleDateToSortableString(getRowDate(row));
    let properties = facility.properties.filter(property => property.propertyId === getRowPropertyId(row));
    if (vm.includeQuantityId) {
      properties = facility.properties.filter(property => property.quantityId === getRowQuantityId(row));
    }
    return properties.find(property => property.fromDate === date);
  }

  function saveFacilityProperties(properties) {
    const promises = [];
    _.forEach(properties, (propertiesArray, facilityId) => {
      if (vm.stop) {
        return false;
      }
      setProcessingStatus(facilityId);
      vm.processingProperties.push(facilityId);
      const promise = facilities
        .addProperties(facilityId, propertiesArray)
        .then(() => {
          utils.popUp(ToasterType.SUCCESS, utils.localizedString('FACILITY.PROPERTYADDED'));
          setSuccessStatus(facilityId);
        })
        .catch(response => {
          if (response.status === 409) {
            setConflictStatus(facilityId);
          } else {
            setErrorStatus(facilityId);
          }
          utils.popUpGeneralError('SAVE', 'PROPERTIES');
        })
        .finally(() => {
          vm.processingProperties = vm.processingProperties.filter(item => item !== facilityId);
        });
      promises.push(promise);
    });
    return promises;
  }

  function deleteFacilityProperties(toBeDeletedProperties) {
    const promises = [];
    _.forEach(toBeDeletedProperties, (propertiesArray, facilityId) => {
      if (vm.stop) {
        return false;
      }
      setProcessingDeleteStatus(facilityId);
      vm.processingProperties.push(facilityId);
      const promise = facilities
        .deleteProperties(facilityId, propertiesArray)
        .then(() => {
          utils.popUp(ToasterType.SUCCESS, utils.localizedString('FACILITY.PROPERTYDELETED'));
          setSuccessDeletedStatus(facilityId);
        })
        .catch(() => {
          setErrorDeleteStatus(facilityId);
          utils.popUpGeneralError('DELETE', 'PROPERTIES');
        })
        .finally(() => {
          vm.processingProperties = vm.processingProperties.filter(item => item !== facilityId);
        });
      promises.push(promise);
    });
    return promises;
  }

  function sendStatusBatch(facilityId, status, color) {
    const enegiaIdColumnChar = getColumnCharacter(AdminSpreadsheetConstants.COLUMNS.ENEGIA_ID);
    const range = getRowsWithData(enegiaIdColumnChar, enegiaIdColumnChar);
    const sheet = vm.spreadsheet.activeSheet();

    sheet.batch(() => {
      range.forEachCell((row, column, cell) => {
        const rowFacilityId = vm.facilityIdsMap[cell.value];
        if (parseInt(facilityId) === rowFacilityId) {
          const statusRange = getStatusCellRange(row);
          statusRange.value(status);
          statusRange.color(color);
        }
      });
    }, { layout: true });
  }

  function sendDeleteRelatedStatusBatch(facilityId, status, color) {
    const enegiaIdColumnChar = getColumnCharacter(AdminSpreadsheetConstants.COLUMNS.ENEGIA_ID);
    const range = getRowsWithData(enegiaIdColumnChar, enegiaIdColumnChar);
    const sheet = vm.spreadsheet.activeSheet();

    sheet.batch(() => {
      range.forEachCell((row, column, cell) => {
        const rowFacilityId = vm.facilityIdsMap[cell.value];
        if (parseInt(facilityId) === rowFacilityId) {
          const valueRange = getValueCellRange(row);
          // We only want to change status those we are deleting
          // and we are deleting those without value
          if (valueRange.value() === null) {
            const statusRange = getStatusCellRange(row);
            statusRange.value(status);
            statusRange.color(color);
          }
        }
      });
    }, { layout: true });
  }

  function setErrorStatus(facilityId) {
    sendStatusBatch(facilityId, vm.messages.errorStatus, vm.colors.error);
  }

  function setErrorDeleteStatus(facilityId) {
    sendDeleteRelatedStatusBatch(facilityId, vm.messages.errorStatus, vm.colors.error);
  }

  function setConflictStatus(facilityId) {
    sendStatusBatch(facilityId, vm.messages.conflictStatus, vm.colors.error);
  }

  function setSuccessStatus(facilityId) {
    sendStatusBatch(facilityId, vm.messages.savedStatus, vm.colors.success);
  }

  function setSuccessDeletedStatus(facilityId) {
    sendDeleteRelatedStatusBatch(facilityId, vm.messages.deletedStatus, vm.colors.success);
  }

  function setProcessingStatus(facilityId) {
    sendStatusBatch(facilityId, vm.messages.savingStatus, vm.colors.default);
  }

  function setProcessingDeleteStatus(facilityId) {
    sendDeleteRelatedStatusBatch(facilityId, vm.messages.deletingStatus, vm.colors.default);
  }

  function setCanceledStatus(facilityId) {
    sendStatusBatch(facilityId, vm.messages.canceledStatus, vm.colors.error);
  }

  function getStatusCellRange(rowIndex) {
    const actualIndex = getActualIndex(rowIndex);
    return vm.spreadsheet.activeSheet()
      .range(getColumnCharacter(AdminSpreadsheetConstants.COLUMNS.STATUS) + actualIndex);
  }

  function getValueCellRange(rowIndex) {
    const actualIndex = getActualIndex(rowIndex);
    return vm.spreadsheet.activeSheet()
      .range(getColumnCharacter(AdminSpreadsheetConstants.COLUMNS.VALUE) + actualIndex);
  }

  function getActualIndex(rowIndex) {
    return parseInt(rowIndex) + 1;
  }

  // Return rows before first empty row
  function getRowsWithData(leftestCell, rightestCell) {
    const sheet = vm.spreadsheet.activeSheet();
    let firstEmpty = -1;
    for (let i = 1; i < AdminSpreadsheetConstants.DEFAULT_ROW_COUNT; i++) {
      if (isRowEmpty(i)) {
        break;
      }
      firstEmpty = i;
    }
    if (firstEmpty === -1) {
      return [];
    }
    return sheet
      .range(`${leftestCell + AdminSpreadsheetConstants.READING_START_ROW }:${ rightestCell }${firstEmpty + 1}`);
  }

  function isRowEmpty(rowIndex) {
    const actualIndex = getActualIndex(rowIndex);
    const range = vm.spreadsheet.activeSheet()
      .range(`${vm.firstDataRowChar + actualIndex }:${ vm.lastDataRowChar }${actualIndex}`);
    const values = range.values();
    return _.every(values[0], _.isNull);
  }

  function getRowEnegiaId(row) {
    return row[AdminSpreadsheetConstants.COLUMNS.ENEGIA_ID];
  }

  function getRowPropertyId(row) {
    if (vm.includeQuantityId) {
      return FacilityPropertyEnums.Co2Factor;
    } else if (vm.includeCustomType) {
      return AdminSpreadsheetConstants.CUSTOM_TYPE_PROPERTY_ID;
    } else {
      return row[AdminSpreadsheetConstants.COLUMNS.PROPERTY_ID];
    }
  }

  function getRowDate(row) {
    return row[AdminSpreadsheetConstants.COLUMNS.VALID_FROM];
  }

  function getRowValue(row) {
    const value = row[AdminSpreadsheetConstants.COLUMNS.VALUE];
    if (value) {
      return value.replace(',', '.');
    }
    return null;
  }

  function getRowQuantityId(row) {
    return vm.includeQuantityId ? row[AdminSpreadsheetConstants.COLUMNS.QUANTITY_ID] : null;
  }

  function getRowCustomType(row) {
    return vm.includeCustomType
      ? row[AdminSpreadsheetConstants.COLUMNS.CUSTOM_TYPE]
      : null;
  }

  function getRowsEnegiaIds(rows) {
    return _(rows)
      .map(getRowEnegiaId)
      .uniq()
      .compact()
      .value();
  }

  function getFacilityIdsFromEnegiaIds(rows, result) {
    const enegiaIds = getRowsEnegiaIds(rows);
    const facilityIds = {};
    _.each(enegiaIds, enegiaId => {
      const facility = _.find(result, facilityResult => facilityResult.enegiaId === enegiaId);
      if (facility) {
        facilityIds[enegiaId] = facility.id;
      }
    });
    return facilityIds;
  }

  function stopSaving() {
    HttpPendingRequestsService.cancelAll();
    vm.stop = true;
    _.forEach(vm.processingProperties, facilityId => {
      setCanceledStatus(facilityId);
    });
    setFinalStep();
  }

  function setFinalStep() {
    vm.currentStep = vm.steps[vm.steps.length - 1];
  }

  function onDestroy() {
    if (vm.spreadsheet) {
      vm.spreadsheet.destroy();
    }
    angular.element($window).off('resize', resizeFn);
    removeSpreadsheetOrphans();
  }

  /**
   * Returns spreadsheet instance. Needed f.e after changing
   * spreadsheetOptions because k-rebind recreates the spreadsheet
   *
   * @return {*}
   */
  function getSpreadsheetInstance() {
    return $element
      .find(AdminSpreadsheetConstants.SELECTOR)
      .data('kendoSpreadsheet');
  }
}
