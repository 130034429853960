<td>
  <span [style.color]="color">
    <!-- ■ -->
    &#9632;
  </span>
  {{ (isNormalized ? 'FACILITIES.NORMALIZED' : 'FACILITIES.MEASURED') | translate }}
</td>
<td>{{ time }}</td>
<td class="widget-tooltip-value">{{ value | number:'1.1-1' }}</td>
<td>{{ quantityId | quantityUnit:unitKey | async}}</td>
