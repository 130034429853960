/**
 * Round number by decimal count
 */
export function roundByDecimals(value: number, decimals: number): number {
  const multiplier = Math.pow(10, decimals);
  return Math.round(value * multiplier) / multiplier;
}

/**
 * Round value by number of digits, additionally limit decimals
 */
export function roundByDigits(value: number, digits?: number, maxDecimals?: number): number {
  digits = typeof digits === 'number' ? digits : 4;
  if (value === null) {
    return NaN;
  }
  if (value * Math.pow(10, digits - 1) < 1) {
    const multiplier = Math.pow(10, Math.round(1 / value).toString().length);
    return Math.round(value * multiplier) / multiplier;
  } else {
    const valueDigits = Math.round(value).toString().length;
    let decimals = digits - valueDigits;
    decimals = decimals < 0 ? 0 : decimals;
    if (typeof maxDecimals === 'number') {
      decimals = decimals > maxDecimals ? maxDecimals : decimals;
    }
    const multiplier = Math.pow(10, decimals);
    return Math.round(value * multiplier) / multiplier;
  }
}

/**
 * Rounds chart y-axis to reasonable values
 * This is used to fix a bug in kendo chart value axis which occurs when min value is below 0
 */
export function roundDataSeries(min: number, max: number): {min: number; max: number} {
  const difference = max - min;
  const magnitude = Math.pow(10, Math.floor(Math.log10(Math.abs(difference))));
  const accuracy = magnitude / 10;

  return {
    min: Math.floor(min / accuracy) * accuracy,
    max: Math.ceil(max / accuracy) * accuracy
  };
}

/**
 * Parses number string to actual number and takes care of
 * spaces and commas.
 *
 * @note At some point we should use actual library to handle these kind of
 * parses but with our locales this will suffice.
 */
export function parseLocaleStringToNumber(value: string): number {
  if (value === null) {
    return 0;
  }

  const numberString = value
    .toString()
    .replace(' ', '')
    .replace(',', '.')
  ;

  const parsedNumber = Number.parseFloat(numberString);
  return isNaN(parsedNumber) ? 0 : parsedNumber;
}

/**
 * Concatenates array of numbers and returns the result as a number
 */
export function concateNumbers(numbers: number[], base: number): number {
  let result: number;
  numbers.forEach(num => {
    if (!isNaN(num)) {
      if (!result) {
        result = num;
      } else {
        if (base !== undefined && !isNaN(base)) {
          base = 10;
        }
        result = num === 0
          ? result * 10
          : result * Math.pow(base, Math.floor(Math.log(num) / Math.log(base)) + 1) + num;
      }
    }
  });

  return Number.isFinite(result) ? result : 0;
}

export function percentageChange(newValue: number, oldValue: number): number | null {
  if (!Number.isFinite(newValue) || !Number.isFinite(oldValue)) {
    return null;
  }
  if (newValue === 0) {
    return null;
  }
  return oldValue !== 0 ? ((newValue - oldValue) / Math.abs(oldValue)) : null;
}
