const $inject = [
  '$modalInstance',
  'ManualQaApiMeterService',
  'meter',
  'timeStamp',
  'effectPeriod',
  'ManualQaPopupService'
];

function ManualQaChangeMeterModalController(
  $modalInstance,
  ManualQaApiMeterService,
  meter,
  timeStamp,
  effectPeriod,
  ManualQaPopupService
) {
  const vm = this;

  vm.meter = angular.copy(meter);
  vm.timeStamp = angular.copy(timeStamp);
  vm.effectPeriod = angular.copy(effectPeriod);

  vm.dismiss = dismiss;
  vm.changeMeter = changeMeter;

  /**
   * Handles modal dismiss
   */
  function dismiss() {
    $modalInstance.dismiss();
  }

  /**
   * Saves meter changes
   *
   * @param {Meter} meter updated meter info
   * @param {Object} timeStamp timeStamp for meter change
   */
  function changeMeter(meter, timeStamp) {
    ManualQaApiMeterService.changeMeter(meter, timeStamp)
      .then(() => {
        ManualQaPopupService.successPopUp('MQA.SUCCESS.METER_CHANGE');
      })
      .then(close)
      .catch(() => {
        ManualQaPopupService.errorPopUp('MQA.ERRORS.METER_CHANGE');
      });
  }

  /**
   * Handles modal close
   */
  function close() {
    $modalInstance.close();
  }
}

ManualQaChangeMeterModalController.$inject = $inject;

export default ManualQaChangeMeterModalController;
