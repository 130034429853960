import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { PagerSettings } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ToasterService } from '../../../shared/services/toaster.service';
import { ModalService } from '@enerkey/foundation-angular';
import { ProvisioningDetailsComponent } from '../provisioning-details/provisioning-details.component';
import { ProvisioningClient, ProvisioningDto } from '@enerkey/clients/provisioning';

@Component({
  selector: 'provisioning-grid',
  templateUrl: './provisioning-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvisioningGridComponent implements OnDestroy {
  @Input() public provisionings: ProvisioningDto[];
  @Input() public loading: boolean;

  public readonly pagerSettings: PagerSettings = {
    pageSizes: [10, 20, 50, 100]
  };

  public readonly sort: SortDescriptor[] = [{
    field: 'created',
    dir: 'desc'
  }];

  private readonly cancel$ = new Subject<void>();

  public constructor(
    private readonly provisioningClient: ProvisioningClient,
    private readonly modalService: ModalService,
    private readonly toasterService: ToasterService
  ) {
  }

  public showProvisioningItem(item: ProvisioningDto): void {
    this.cancel$.next();
    this.provisioningClient.getProvisioningStatus(item.instanceId, true).pipe(takeUntil(this.cancel$))
      .subscribe({
        next: status => {
          const modalInstance = this.modalService.open(ProvisioningDetailsComponent);
          modalInstance.componentInstance.provisioning = status;
          modalInstance.componentInstance.tenantId = item.tenantId;
        },
        error: () => {
          this.toasterService.error(null, 'PROVISIONING.LOADING_PROVISIONING_STATUS_FAILED');
        }
      });
  }

  public ngOnDestroy(): void {
    this.cancel$.next();
    this.cancel$.complete();
  }
}
