import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IntlModule, NumberPipe } from '@progress/kendo-angular-intl';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { PopupModule } from '@progress/kendo-angular-popup';
import { GridsterModule } from 'angular-gridster2';

import { AccordionModule, CollapseModule, NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkFormsModule } from '@enerkey/ek-forms';

import { SharedModule } from '../../shared/shared.module';
import { OverviewCardFieldComponent } from './components/overview-card-field/overview-card-field.component';
import { OverviewCardContentComponent } from './components/overview-card-content/overview-card-content.component';
import { TimeFrameQuickSelectComponent } from './components/time-frame-quick-select/time-frame-quick-select.component';
import { ErTimeFrameService } from './services/er-time-frame.service';
import { yearlyTrendReportState } from './energy-reporting.states';
import { EkInputsModule } from '../../shared/ek-inputs/ek-inputs.module';
import { EkKendoModule } from '../../shared/ek-kendo/ek-kendo.module';
import { SimpleTableReportComponent } from './components/simple-table-report/simple-table-report.component';
import { SimpleReportHeaderComponent } from './components/simple-report-header/simple-report-header.component';
import { EkDropdownModule } from '../../shared/ek-dropdown/ek-dropdown.module';
import { CommonPipesModule } from '../../shared/common-pipes/common-pipes.module';
import { FacilityOverviewComponent } from './components/facility-overview/facility-overview.component';
import { EnergyReportingSharedModule } from '../../shared/energy-reporting-shared/energy-reporting-shared.module';
import { EnergyManagementSharedModule } from '../../shared/energy-management-shared/energy-management-shared.module';
import { OverviewReportActionsComponent } from './components/overview-report-actions/overview-report-actions.component';
import { OverviewReportFacilityInfoComponent } from './components/overview-report-facility-info/overview-report-facility-info.component';
import { OverviewReportCommentsComponent } from './components/overview-report-comments/overview-report-comments.component';
import { AlarmsSharedModule } from '../../shared/alarms-shared/alarms-shared.module';
import { FacilitiesPopupExternalLinksComponent } from './components/facilities-popup-external-links/facilities-popup-external-links.component';

function erTimeFrameServiceFactory($injector: angular.auto.IInjectorService): ErTimeFrameService {
  return $injector.get('erTimeFrameService');
}

function erUtilsFactory($injector: angular.auto.IInjectorService): unknown {
  return $injector.get('ERUtils');
}

@NgModule({
  declarations: [
    OverviewCardContentComponent,
    OverviewCardFieldComponent,
    TimeFrameQuickSelectComponent,
    SimpleTableReportComponent,
    SimpleReportHeaderComponent,
    FacilityOverviewComponent,
    OverviewReportActionsComponent,
    OverviewReportFacilityInfoComponent,
    OverviewReportCommentsComponent,
    FacilitiesPopupExternalLinksComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PDFExportModule,
    UIRouterUpgradeModule.forChild({ states: [yearlyTrendReportState] }),
    ChartsModule,
    CollapseModule,
    EkInputsModule,
    GridModule,
    EkKendoModule,
    IntlModule,
    ExcelModule,
    InputsModule,
    EkFormsModule,
    DateInputsModule,
    AccordionModule,
    EkDropdownModule,
    CommonPipesModule,
    GridsterModule,
    EnergyReportingSharedModule,
    EnergyManagementSharedModule,
    AlarmsSharedModule,
    NgfTooltipModule,
    PopupModule,
  ],
  exports: [
    OverviewCardContentComponent,
    TimeFrameQuickSelectComponent,
  ],
  providers: [
    { provide: ErTimeFrameService, useFactory: erTimeFrameServiceFactory, deps: ['$injector'] },
    { provide: 'erUtils', useFactory: erUtilsFactory, deps: ['$injector'] },
    DatePipe,
    NumberPipe
  ]
})
export class EnergyReportingModule { }
