import facilitiesSectionsTemplate from 'ngtemplate-loader!raw-loader!../../templates/facilities-sections.html';

import { Injector } from '@angular/core';
import _ from 'lodash';
import moment from 'moment';
import { firstValueFrom, forkJoin, of, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { Roles } from '../../../admin/constants/roles';
import * as modalStates from '../../constants/er-modal-states.constant';
import {
  ACTION_TYPE_IDS,
  COMMENT_TYPE_IDS,
  getNewActionDefaults
} from '../../../energy-management/constants/em-shared.constant';
import { ErReportType } from '../../constants/er-report-type';
import { Service } from '../../../../constants/service';
import { ErSidebarSection } from '../../constants/er-sidebar-section';
import { ActionType, ExecutionPhase } from '@enerkey/clients/attachments';
import { ServiceLevel } from '@enerkey/clients/facility';
import { ErReportTypeName } from '../../constants/er-report-type-name';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';
import {
  AlarmDetailsModalComponent
} from '../../../../shared/alarms-shared/components/alarm-details-modal/alarm-details-modal.component';
import { ALARMS_LOG_GRID_REFRESH } from '../../../alarms-log/shared/alarms-log-grid-refresh';

const $inject = [
  '$q', '$scope', '$rootScope', '$timeout', 'ERDataService2', 'consumptions', 'utils', '$state',
  'printingLimit', 'actions', 'UserService', 'ERUtils', 'ERReportType', 'forecastTypes',
  'ActionsToTimeseriesMapper', 'weather', 'AdditionalSettingService', 'ERDataChartService',
  'ConsumptionTargetDataService', '$window', 'appStatusService', 'emActionEditService',
  'erParamsService', 'erStateService', 'erReportSettingsService', 'modalService', 'AlarmClient', 'alarmService',
  'AttachmentsClient', 'ERUtils', 'serviceLevelService', 'modalServiceAngular', 'LoadingService'
];

function FacilitiesReportController(
  $q, $scope, $rootScope, $timeout, ERDataService2, consumptions, utils, $state,
  printingLimit, actionService, UserService, ERUtils, ERReportType, forecastTypes,
  ActionsToTimeseriesMapper, weather, AdditionalSettingService, ERDataChartService,
  ConsumptionTargetDataService, $window, appStatusService, emActionEditService,
  erParamsService, erStateService, erReportSettingsService, modalService, alarmClient, alarmService,
  attachmentsClient, erUtils, serviceLevelService, modalServiceAngular, LoadingService
) {
  const vm = this; // eslint-disable-line @typescript-eslint/no-this-alias

  const quickSelectionUpdate$ = new Subject();
  const quickSelectionSubscription = quickSelectionUpdate$
    .pipe(debounceTime(1000))
    .subscribe(() => $scope.regenerateData());

  vm.hasAccessToCosts = UserService.hasService(Service.CostReportingMeterBased) &&
    (UserService.hasRole(Roles.COSTREADER) || UserService.hasRole(Roles.COSTWRITER));
  vm.hasAccessToEmissions = UserService.hasService(Service.EmissionReporting);

  vm.facilitiesSectionsTemplate = facilitiesSectionsTemplate;
  vm.hasAtLeastServiceLevelMedium = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);
  vm.normalized = true;
  vm.costs = vm.hasAccessToCosts;
  vm.emissions = vm.hasAccessToEmissions;

  erUtils.getDefaultTimePeriod()
    .then(timePeriod => {
      vm.quickSelectionRange = timePeriod;
    });
  $scope.defaultTimer = 200;
  vm.selectedTimeFrame = null;

  vm.timeFrameChange = () => {
    const oldSettings = erReportSettingsService.getInstance();
    const oldSeries = oldSettings.getSettings().series;
    oldSettings.changeSetting('series', {
      ...oldSeries,
      Start: vm.selectedTimeFrame.Start,
      Resolution: vm.selectedTimeFrame.Resolution,
      TimeFrame: vm.selectedTimeFrame.TimeFrame
    });
  };

  vm.quickSelectionChange = () => {
    quickSelectionUpdate$.next();
  };

  const reportSettingCallbackUnbind = erReportSettingsService.getInstance().setCallback(() => {
    updateReportSections();
  });

  function updateReportSections() {
    $scope.reportSections = erParamsService.getFlatSectionConfigsWithTemplates(
      erReportSettingsService.getInstance().getSettings().sectionsConfig
    );
  }

  vm.inModal = erStateService.isModalOpen();
  vm.isLoading = () => LoadingService.isLoading();
  vm.getAlarmTypeName = alarmType => alarmService.getAlarmTypeName(alarmType);
  let reportType;

  $scope.reportData = [];

  $scope.actionsAllowed = UserService.hasService(Service.Actions);
  $scope.commentsAllowed = UserService.hasService(Service.Comments);
  $scope.alarmsAllowed = UserService.hasService(Service.Alarms);

  // Show enegiaId only if facilities period report, in modal enegiaId is shown on report type panel
  $scope.isEnegiaIdShown = UserService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES) &&
    vm.isEnegiaIdShownInReportArea;

  let actionChangedUnbind = null;

  if ($scope.actionsAllowed || $scope.commentsAllowed) {
    $scope.showAction = action => {
      $q.all([
        firstValueFrom(attachmentsClient.getAction(action.id)),
        actionService.getDocumentsForAction(action.id)
      ])
        .then(([fullAction, actionDocuments]) => {
          fullAction.documents = utils.keysToUpperCamelCase(actionDocuments.data);
          emActionEditService.getModal(fullAction).then(() => {
            // $state.reload clears all data from a modal view, so a workaround is required.
            if (erStateService.isModalOpen()) {
              erReportSettingsService.getInstance('er-modal-report').reload();
            } else {
              $state.reload();
            }
          });
        });
    };

    // Give an action with default values as parameter. By default use the energy consumption comment type.
    // There are values for other fields as well, but those are coerced out when saving so only valid fields
    // get saved.
    $scope.addAction = (facility, graphData) => {
      const actionType = $scope.commentsAllowed ? ActionType.KE : ActionType.ES;
      const defaults = getNewActionDefaults(actionType);
      defaults.reportingObjectId = facility.FacilityId;
      defaults.quantityIds = (graphData && graphData.id) ? [graphData.id] : [];
      emActionEditService.getModal(defaults, false).then(() => {
        // $state.reload clears all data from a modal view, so a workaround is required.
        if (erStateService.isModalOpen()) {
          erReportSettingsService.getInstance('er-modal-report').reload();
        } else {
          $state.reload();
        }
      });
    };

    actionChangedUnbind = $rootScope.$on('actions.actionChanged', (eventName, eventParams) => {
      const action = eventParams.action;
      if (!action) {
        return;
      }

      const reportDataForFacility = $scope.reportData.find({ Id: action.reportingObjectId });
      // If action defines no quantities then any currently visible quantity will do
      const relevantQuantityIds = action.quantityIds.length === 0
        ? $scope.params.quantityId
        : _.intersection($scope.params.quantityId, action.quantityIds);

      if (reportDataForFacility && !reportDataForFacility.obsolete) {
        const relevantGraphs = reportDataForFacility.graphData.filter(graph => relevantQuantityIds.includes(graph.id));
        const series = getSeries();
        const payload = getPayload(series);
        getNoteData(series, payload).then(noteDataByFacilityId => {
          const noteData = noteDataByFacilityId[action.reportingObjectId];
          reportDataForFacility.notes = ActionsToTimeseriesMapper.getNotesForPopups(noteData);

          relevantGraphs.forEach(graph => {
            addNotesToGraph(noteData, graph, graph.quantityId, $scope.params.series);
          });
        });
      }
    });
  }

  vm.openReport = facilityId => {
    const reportParams = {
      facilityId: [facilityId],
      series: $scope.params.series,
      unitKey: $scope.params.unitKey,
      changeType: $scope.params.changeType
    };
    const modalParams = {
      reportParams: reportParams,
      reportType: $scope.mordorState
    };
    modalService.getModalWithComponent('report-modal', modalParams);
  };

  $scope.regenerateData = _.debounce(direction => {
    $scope.regenerateDataNoBounce(direction, 0);
  }, $scope.defaultTimer);

  $scope.regenerateDataNoBounce = function(direction, printing) {
    erUtils.getDefaultTimePeriod().then(timePeriod => {
      vm.defaultTimePeriod = timePeriod;

      $scope.facilities = erParamsService.getSelectedFacilities($scope.cache, $scope.params.facilityId);

      const series = getSeries();
      vm.selectedTimeFrame = { TimeFrame: series.TimeFrame, Start: series.Start };
      const payload = getPayload(series, direction, printing);

      if (erParamsService.validateSeries(series) && payload) {
        return getData(series, payload)
          .then(data => {
            createReport(data, series, payload);
          });
      }

      return $q.resolve(); // Return resolved promise for historical reasons
    });
  };

  function getSeries() {
    let seriesForReport = _.cloneDeep($scope.params.series);
    switch (reportType) {
      case ERReportType.TREND_REPORT:
        _.extend(seriesForReport, { RelatedValues: undefined, Comparables: undefined });
        if (_.get($scope.params.series, 'Start', []).length) {
          _.extend(seriesForReport, { Start: [_.last($scope.params.series.Start)] });
        }
        break;
      case ERReportType.FORECAST_REPORT:
        _.extend(seriesForReport, { Comparables: 'ByProperty', Temperature: undefined });
        break;
      default:
        break;
    }

    if (!vm.hasAtLeastServiceLevelMedium) {
      if (vm.reportParams.name === ErReportTypeName.FacilityReport) {
        seriesForReport.Measured = true;
        seriesForReport.Normalized = vm.normalized;
        seriesForReport.RelationalUnitIds = getSPackageRelationalUnitIds();
      } else if (vm.reportParams.name === ErReportTypeName.FacilityForecastReport && vm.defaultTimePeriod) {
        seriesForReport = {
          ...seriesForReport,
          TimeFrame: 'P1Y',
          Resolution: 'P1M',
          Start: _.sortBy(vm.defaultTimePeriod, startItem => new Date(startItem.value)),
          // defaultTimePeriod is sometimes in descending order ie. [2015, 2014], which breaks forecast chart series
        };
      }
    }

    return AdditionalSettingService.filterDisabled(seriesForReport);
  }

  function getSPackageRelationalUnitIds() {
    const relationalUnitIds = [];
    if (vm.costs) {
      relationalUnitIds.push(RelationalValueId.Costs);
    }
    if (vm.emissions) {
      relationalUnitIds.push(RelationalValueId.co2Factor);
    }
    return relationalUnitIds;
  }

  function getPayload(series, direction, printing, getAll) {
    let facilityIds = _.map($scope.facilities, 'FacilityId');
    const ISO = $scope.infiniteScrollOptions;

    if (printing) {
      facilityIds = facilityIds.slice(0, printingLimit);
    } else if (!getAll) { // choose id's if we're not getting all
      const validData = _.filter($scope.reportData, { obsolete: false });
      const lastValidIndex = validData.length ? _.indexOf(facilityIds, _.last(validData).Id) : -1;
      const firstValidIndex = validData.length ? _.indexOf(facilityIds, _.first(validData).Id) : 0;

      switch (direction) {
        case -1:
          facilityIds = facilityIds.slice(Math.max(firstValidIndex - 1 - ISO.loadCount, 0), firstValidIndex);
          break;
        case 1:
          facilityIds = facilityIds.slice(lastValidIndex + 1, lastValidIndex + 1 + ISO.loadCount);
          break;
        default:
          facilityIds = facilityIds.slice(firstValidIndex, firstValidIndex + ISO.loadCount);
          break;
      }
    }

    if (erParamsService.validateSeries(series) && facilityIds.length) {
      const resultSeries = {
        Quantities: _.chain($scope.params.quantityId)
          .reduce((result, quantityId) => {
            const quantity = erParamsService.getQuantity($scope.cache, quantityId);
            if (quantity) {
              result.push({
                ID: quantityId,
                RelationalUnitIds: quantity.Relational ? series.RelationalUnitIds : [],
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization && series.Normalized,
                RelatedValues: erParamsService
                  .filterRelatedValueIdsForQuantity($scope.cache, series.RelatedValues, quantityId)
              });
            }
            return result;
          }, [])
          .value(),
        Resolution: series.Resolution,
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        FacilityId: facilityIds,
        Unit: $scope.params.unitKey,
        ReportType: reportType,
        Cumulative: reportType === ERReportType.FORECAST_REPORT
      };

      return resultSeries;
    }
    return undefined;
  }

  function getData(series, payload) {
    const deferred = $q.defer();
    let promises = [];

    $scope.errorTextKey = erParamsService.getSeriesErrorTextKey(series);

    promises.push(getNoteData(series, payload));

    if (!$scope.errorTextKey) {
      promises = promises
        .concat(getConsumptionData(series, payload))
        .concat(getWeatherData(series, payload))
        .concat(ConsumptionTargetDataService.getData(series, payload));
    }

    const onSuccess = function(result) {
      deferred.resolve(_.filter(result));
    };

    const onError = function() {
      deferred.reject();
    };

    $q.all(promises)
      .then(onSuccess)
      .catch(onError)
    ;

    return deferred.promise;
  }

  function getConsumptionData(series, payload) {
    if (!payload.Quantities.length) {
      return $q.resolve({
        requestData: payload,
        responseData: {}
      });
    }

    const promises = [];
    const consumptionPayload = _.extend({}, payload, {
      ReportType: reportType === ERReportType.FORECAST_REPORT ? undefined : reportType
    });
    const consumptionDeferred = $q.defer();

    function onSuccess(responseData) {
      consumptionDeferred.resolve({
        requestData: consumptionPayload,
        responseData: responseData
      });
    }

    consumptions
      .getConsumptionsForFacilities(consumptionPayload)
      .then(onSuccess);

    promises.push(consumptionDeferred.promise);

    let selectedForecastTypeIds;
    switch (reportType) {
      case ERReportType.FORECAST_REPORT:
        selectedForecastTypeIds = _.map(forecastTypes.getForecastTypes(), 'Id');
        _.each(selectedForecastTypeIds, forecastTypeId => {
          const forecastPayload = _.extend({}, payload, {
            ReportType: ERReportType.FORECAST_REPORT, ForecastType: forecastTypeId
          });
          const forecastDeferred = $q.defer();

          function onConsumptionsSuccess(responseData) {
            forecastDeferred.resolve({
              requestData: forecastPayload,
              responseData: responseData
            });
          }

          consumptions
            .getConsumptionsForFacilities(forecastPayload)
            .then(onConsumptionsSuccess);

          promises.push(forecastDeferred.promise);
        });
        break;
      default:
        break;
    }

    return promises;
  }

  function getNoteData(series, payload) {
    const deferred = $q.defer();
    forkJoin(
      getAlarmsData(series, payload),
      getActionsData(series, payload)
    ).pipe(
      map(([alarms, actions]) => payload.FacilityId.reduce((noteDataByFacilityId, facilityId) => {
        noteDataByFacilityId[facilityId] = {
          actions: actions.get(facilityId) || [],
          alarms: alarms.get(facilityId) || []
        };
        return noteDataByFacilityId;
      }, {}))
    ).subscribe(result => {
      deferred.resolve(result);
    });

    return deferred.promise;
  }

  function getAlarmsData(series, payload) {
    if (!$scope.alarmsAllowed) {
      return of(new Map());
    }

    const searchParams = {
      facilityIds: payload.FacilityId,
      beginDate: series.BeginEndDates.from,
      endDate: series.BeginEndDates.to,
      quantityIds: payload.Quantities.map(({ ID }) => ID)
    };
    return alarmClient.getLiteLogsBySearchCriteria(UserService.getCurrentProfile().id, searchParams).pipe(
      map(alarms => alarms.toGroupsBy('facilityId'))
    );
  }

  function getActionsData(series, payload) {
    if (!($scope.actionsAllowed || $scope.commentsAllowed)) {
      return of(new Map());
    }

    let actionTypes = [];

    if ($scope.actionsAllowed) {
      actionTypes = actionTypes.concat(ACTION_TYPE_IDS);
    }

    if ($scope.commentsAllowed) {
      actionTypes = actionTypes.concat(COMMENT_TYPE_IDS);
    }

    const searchParams = {
      facilityIds: payload.FacilityId,
      beginDate: series.BeginEndDates.from,
      endDate: series.BeginEndDates.to,
      includedActionTypes: actionTypes,
      includedExecutionPhases: [ExecutionPhase.Implemented, ExecutionPhase.ImplementationDecided]
    };
    return attachmentsClient.getActionsFlatList(undefined, searchParams).pipe(
      map(actions => actions.toGroupsBy('reportingObjectId'))
    );
  }

  function getWeatherData(series, payload) {
    const deferred = $q.defer();

    if (!payload.Quantities.length || !_.get(series, 'Temperature')) {
      return $q.resolve();
    }

    const facilities = _.map(payload.FacilityId, facilityId => $scope.cache.facilitiesById[facilityId]);

    const payloadForWeather = weather.getPayload(
      _.pick(series, ['TimeFrame', 'Resolution', 'Start']),
      facilities
    );

    if (!payloadForWeather) {
      return $q.resolve();
    }

    const quantities = _.map(_.map(payload.Quantities, 'ID'), quantityId => $scope.cache.quantitiesById[quantityId]);

    function onSuccess(response) {
      deferred.resolve({
        requestData: payloadForWeather,
        responseData: response.data
      });
    }

    weather
      .getWeatherByFacilities(payloadForWeather, facilities, quantities)
      .then(onSuccess)
    ;

    return deferred.promise;
  }

  function createReport(data, series, payload) {
    // graph help array
    const graphsCreatedOrUpdated = [];

    // options for er data service to create charts and grids
    const options = {
      idType: 'Facility',
      series: series,
      params: $scope.params,
      cache: $scope.cache,
      api: _.tail(data), // First element is actions so take the rest
      show: {
        distributionAndValue: false,
        relationalValueOnlyForFollowUp: false,
        relatedValueOnlyForFollowUp: false
      }
    };

    const sectionsConfig = erReportSettingsService.getInstance().params.sectionsConfig;
    const gridConfig = sectionsConfig.find(section => section.id === ErSidebarSection.Tables);
    if (gridConfig && gridConfig.selected) {
      options.grid = {
        getCurrentGrid: function(options2, data2) {
          const reportDataForFacility = _.find($scope.reportData, { Id: data2.facilityId });
          const tableData = _.get(reportDataForFacility, 'tableData');
          return tableData || {
            id: `${(appStatusService.inModal ? 'modal-' : '')}grid-${data2.facilityId}`,
            gridContent: {
              hidden: !_.includes($scope.params.sections, 'SERIES'),
              modified: false
            }
          };
        },
        aggregates: $scope.params.reportType === ErReportType.FORECAST_REPORT ? [] : ['sum', 'min', 'max', 'average'],
        excel: {
          getTitle: (options2, data2) => _.get(options2, `cache.facilitiesById.${data2.facilityId}.Name`)
        }
      };
    }

    const chartsConfig = sectionsConfig.find(section => section.id === ErSidebarSection.Graphs);
    if (chartsConfig && chartsConfig.selected) {
      options.chart = {
        getCurrentChart: (options2, data2) => {
          const reportDataForFacility = _.find($scope.reportData, { Id: data2.facilityId });
          const chart = ERDataChartService.getCurrentChart(reportDataForFacility, payload, options2, data2);
          const isAdded = ERDataChartService.findChart(reportDataForFacility, options2, data2);

          if (reportDataForFacility && !isAdded) {
            reportDataForFacility.graphData.push(chart);
          }

          graphsCreatedOrUpdated.push(chart);

          return chart;
        },
        serieClick: {
          enabled: !reportType
        },
        separateQuantityProperties: $scope.params.reportType === ErReportType.FORECAST_REPORT
      };
    }

    const visuals = ERDataService2.getVisuals(options);
    const charts = _.get(visuals, 'charts', {});
    const grids = _.get(visuals, 'grids', {});
    const noteDataByFacilityId = _.head(data);

    // create report data for each facility
    _.each(payload.FacilityId, facilityId => {
      const facility = $scope.cache.facilitiesById[facilityId];
      let reportDataForFacility = _.find($scope.reportData, { Id: facilityId });
      if (!reportDataForFacility) {
        reportDataForFacility = {
          Id: facility.FacilityId,
          facility: facility,
          graphData: charts[facilityId],
          tableData: grids[facilityId],
          notes: ActionsToTimeseriesMapper.getNotesForPopups(noteDataByFacilityId[facilityId])
        };
        $scope.reportData.push(reportDataForFacility);
      } else {
        // remove charts that where not created/updated
        if (options.chart.separateQuantityProperties) {
          reportDataForFacility.graphData = _.filter(
            reportDataForFacility.graphData,
            graph => _.includes(graphsCreatedOrUpdated, graph)
          );
        }
      }

      // clean up empty graphs
      reportDataForFacility.graphData = _.filter(
        reportDataForFacility.graphData,
        graph => _.has(graph, 'chartOptions')
      );

      // Add action data to graphs
      if ($scope.alarmsAllowed || $scope.actionsAllowed || $scope.commentsAllowed) {
        reportDataForFacility.graphData.forEach(graph => {
          addNotesToGraph(noteDataByFacilityId[facilityId], graph, graph.quantityId, series);
        });
      }

      // calculate correctly change percent when comparability setting is defined
      if (series.Comparables && payload.Start.length > 1) {
        const sumFunction = function(serie) {
          return _.reduce(serie.data, (result, dataItem) => result + _.get(dataItem, serie.field, 0), 0);
        };
        _.each(reportDataForFacility.graphData, graph => {
          const quantity = _.get(graph, 'chartOptions.series.0.quantity');
          const property = `${series.Normalized && _.get(quantity, 'Normalization') ? 'Normalized' : ''}Reading.Value`;
          const followUpSerie = _.find(graph.chartOptions.series, {
            startIndex: payload.Start.length - 1, property: property
          }) || {};
          const comparisonSerie = _.find(graph.chartOptions.series, {
            startIndex: payload.Start.length - 2, property: property
          }) || {};
          graph.change = utils.percentageChange(sumFunction(followUpSerie), sumFunction(comparisonSerie));
        });
      }

      // handle obsolete
      if (reportDataForFacility) {
        reportDataForFacility.obsolete = false;
      }
    });
  }

  function addNotesToGraph(noteData, quantityGraph, quantityId, series) {
    const notes = ActionsToTimeseriesMapper.getNotesRelevantToGraph(noteData, series, quantityId);
    quantityGraph.graphNotes = ActionsToTimeseriesMapper.getNotesForTable(notes);
    quantityGraph.graphAlarms = ActionsToTimeseriesMapper.getAlarmsForTable(notes);
    ActionsToTimeseriesMapper.appendNotesOnTimeline(notes, quantityGraph, quantityId, series);
  }

  function adjustInfiniteScrollLoadCount() {
    // check that we have series
    if (!erParamsService.validateSeries($scope.params.series)) {
      return;
    }

    const ISO = $scope.infiniteScrollOptions;
    if (!ISO.originalLoadCount) {
      ISO.originalLoadCount = ISO.loadCount;
    }
    let loadCount = ISO.originalLoadCount;
    const sectionsVisible = !!_.without($scope.params.sections, 'SERIES').length;
    if (!$scope.params.quantityId.length || !sectionsVisible) {
      loadCount = 2 * ISO.originalLoadCount;
    } else {
      const xAxisPointCount = ERUtils.timeFrameResolutionsCount(
        $scope.params.series.TimeFrame,
        $scope.params.series.Resolution
      );
      if (xAxisPointCount > 60) {
        loadCount = parseInt(($scope.params.quantityId.length > 3 ? 0.5 : 0.75) * ISO.originalLoadCount, 10);
      }
    }

    ISO.loadCount = loadCount;
  }

  vm.$onChanges = () => {
    if (!$scope.params) {
      return;
    }

    const paramsData = erParamsService.getChangedParamsConfig(vm.reportParams, $scope.params);
    $scope.params = vm.reportParams;
    const interestedParams = _.without(_.get(paramsData, 'changedParams'), 'sections');
    if (interestedParams.length || _.get(paramsData, 'forceUpdate')) {
      if (_.includes(interestedParams, 'quantityId')) {
        const currentQuantityCount = _.get($scope, 'params.quantityId', []).length;
        // remove unnecessary charts and columns
        _.each($scope.reportData, reportDataForFacility => {
          reportDataForFacility.graphData = _.filter(
            reportDataForFacility.graphData,
            chart => _.includes($scope.params.quantityId, chart.id)
          );
          if (_.has(reportDataForFacility, 'tableData.gridOptions.columns')) {
            reportDataForFacility.tableData.gridOptions.columns = _.filter(
              reportDataForFacility.tableData.gridOptions.columns,
              column => {
                if (column.field === 'Weather' && !currentQuantityCount) {
                  return false;
                }
                return column.quantityId ? _.includes($scope.params.quantityId, column.quantityId) : true;
              }
            );
          }
        });
        if (_.difference(_.get(paramsData, 'oldParams.quantityId'), $scope.params.quantityId).length) {
          // quantities was just removed, so nothing to do
          return;
        }
      }
      $scope.infiniteScrollOptions.resetFunc();
      $scope.regenerateData();
    }
  };

  const allFacilitiesPrintoutUnbind = $rootScope.$on('print.starting', () => {
    if ($scope.params.facilityId.length > $scope.reportData.length) {
      $scope.regenerateDataNoBounce(0, true).then(() => {

        // we need a timeout here because the loader bar is not removed until after a short timeout.
        // Without this timeout we would be seeing the loader while already on the print preview.
        $timeout($window.print, 500);

      });
    } else {
      // Use timeout here too, without this the printing won't catch all the data on the screen!
      $timeout($window.print, 500);
    }
  });

  const clientSideExportStartingUnbind = $rootScope.$on('client-side-export.starting', () => {
    if ($scope.facilities.length > $scope.reportData.length) {
      utils.popUp('info', null, 'FACILITIES_REPORT.ALL_FACILITIES_NOT_INCLUDED_IN_EXCEL', true, true);
    }
  });

  const exportStartingUnbind = $rootScope.$on('export.starting', () => {
    const series = $scope.params.series;
    const payload = getPayload(series, 0, false, true); // get all facilities

    if (payload.Quantities.length === 0) {
      utils.popUpGeneralError('DOWNLOAD', 'REPORT', utils.localizedString('EXCEL.NO_QUANTITIES_SELECTED_ERROR'));
      return;
    }

    const payloadCombined = {
      ConsumptionsRequest: payload,
      localizedStrings: ERDataService2.getLocalizedStringsForExcel(payload)
    };
    let filename = $scope.facilities[0].Name;

    if (payload.FacilityId.length > 1) {
      // if getting hour resolution for a period longer than one month stop and inform the user.
      if (moment.fromIsodurationCached(payload.Resolution).hours() &&
          (moment.fromIsodurationCached(payload.TimeFrame).years() ||
           moment.fromIsodurationCached(payload.TimeFrame).months() > 1)) {
        utils.popUpGeneralError('DOWNLOAD', 'REPORT', utils.localizedString(
          'EXCEL.FOR_LONG_HOURRESOLUTION_REPORTS_ONLY_ONE_FACILITY_ALLOWED'
        ));

        return;
      }

      filename = utils.localizedString('FACILITIES_REPORT.TITLE_AND_OTHERS', {
        title: filename,
        count: payload.FacilityId.length - 1
      });
    }

    consumptions.createExcelExport(payloadCombined, filename)
      .catch(() => {
        utils.popUp('error', 'EXCEL.EXCEL', 'EXCEL.EXCEL_REPORT_DOWNLOAD_FAILED', true);
      })
    ;
  });

  vm.openAlarmModal = function(alarm) {
    // Grid refresh subject must be provided to modal
    const refreshToken = new Subject();
    refreshToken.subscribe(() => $scope.regenerateData());

    const injector = Injector.create({ providers: [{ provide: ALARMS_LOG_GRID_REFRESH, useValue: refreshToken }] });

    const modalInstance = modalServiceAngular.open(AlarmDetailsModalComponent, { injector });
    modalInstance.componentInstance.getAlarmByLogId(alarm.id);
    modalInstance.result.finally(() => refreshToken.complete());
  };

  // infinite scroll options
  $scope.infiniteScrollOptions = {
    loadCount: 10,
    items: $scope.reportData,
    loadFunc: $scope.regenerateData,
    resetFunc: function() {
      adjustInfiniteScrollLoadCount();
    },
    itemPrefix: 'facility-'
  };

  vm.$onDestroy = () => {
    reportSettingCallbackUnbind();
    exportStartingUnbind();
    clientSideExportStartingUnbind();
    allFacilitiesPrintoutUnbind();
    quickSelectionSubscription.unsubscribe();

    if (actionChangedUnbind) {
      actionChangedUnbind();
    }

    quickSelectionUpdate$.complete();
  };

  vm.$onInit = () => {
    $scope.cache = vm.cache;
    $scope.params = vm.reportParams;
    vm.isFacilityReport = vm.reportParams.name === ErReportTypeName.FacilityReport;

    reportType = vm.reportParams.reportType;
    switch (reportType) {
      case ERReportType.TREND_REPORT:
        $scope.mordorState = modalStates.FACILITY_TREND.name;
        break;
      case ERReportType.FORECAST_REPORT:
        $scope.mordorState = modalStates.FACILITY_FORECAST.name;
        _.extend($scope.cache, { forecastTypesById: _.indexBy(forecastTypes.getForecastTypes(), 'Id') });
        break;
      default:
        $scope.mordorState = modalStates.FACILITY_REPORT.name;
        break;
    }

    updateReportSections();

    // initialize
    adjustInfiniteScrollLoadCount();
    $scope.regenerateData();
  };

  angular.element('#multiselect').kendoMultiSelect({
    dataTextField: 'text',
    dataValueField: 'value',
    dataSource: [
      { text: 'Item1', value: '1' },
      { text: 'Item2', value: '2' }
    ]
  });
}

FacilitiesReportController.$inject = $inject;

export default FacilitiesReportController;
