import _ from 'lodash';
import { ConsumptionTargetType, ConsumptionTargetTypeId } from '../constants/consumption-target-constant';
import { Service } from '../../../constants/service';
import { $InjectArgs } from '@enerkey/ts-utils';
import { Utils } from '../../../services/utils';
import { UserService } from '../../../services/user-service';

export class ConsumptionTargetSeriesTypes {

  protected static readonly $inject: $InjectArgs<typeof ConsumptionTargetSeriesTypes> = [
    'utils',
    'UserService'
  ];

  public constructor(
    private readonly utils: Utils,
    private readonly userService: UserService
  ) { }

  public getInputTargets(): ConsumptionTargetType[] {
    const targets = this.getTargets();
    _.remove(targets, { Id: ConsumptionTargetTypeId.ActionsBased });
    _.remove(targets, { Id: ConsumptionTargetTypeId.CostTargetA });
    _.remove(targets, { Id: ConsumptionTargetTypeId.CostTargetASales });
    _.remove(targets, { Id: ConsumptionTargetTypeId.CostTargetADistribution });
    _.remove(targets, { Id: ConsumptionTargetTypeId.CostTargetB });
    _.remove(targets, { Id: ConsumptionTargetTypeId.CostTargetBSales });
    _.remove(targets, { Id: ConsumptionTargetTypeId.CostTargetBDistribution });

    return targets;
  }

  public get(): ConsumptionTargetType[] {
    const targets = this.getTargets();
    if (!this.userService.hasService(Service.Actions)) {
      _.remove(targets, item => item.RequireActionsService);
    }
    if (!this.userService.hasService(Service.ConsumptionTargets)) {
      _.remove(targets, item => item.RequireConsumptionTargetsService);
    }
    return targets;
  }

  private getTargets(): ConsumptionTargetType[] {
    return [
      {
        Id: ConsumptionTargetTypeId.TargetA,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.TARGET_A'),
        Color: '#DA1F1C',
        DashType: 'longDash',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.TargetB,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.TARGET_B'),
        Color: '#27B5FF',
        DashType: 'longDash',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.BaseLevel,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.BASE_LEVEL'),
        Color: '#EC8C00',
        DashType: 'longDash',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.PlannedLevel,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.PLANNED_LEVEL'),
        Color: '#20BE0D',
        DashType: 'longDash',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.ActionsBased,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.ACTION_BASED'),
        Color: '#3b134c',
        DashType: 'longDash',
        Style: 'smooth',
        RequireConsumptionTargetsService: false,
        RequireActionsService: true
      },
      {
        Id: ConsumptionTargetTypeId.CostTargetA,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_A'),
        Color: '#0100da',
        DashType: 'longDashDot',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.CostTargetASales,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_A_SALES'),
        Color: '#a17600',
        DashType: 'longDashDot',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.CostTargetADistribution,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_A_DISTR'),
        Color: '#a15600',
        DashType: 'longDashDot',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.CostTargetB,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_B'),
        Color: '#9c00ff',
        DashType: 'longDashDot',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.CostTargetBSales,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_B_SALES'),
        Color: '#a17600',
        DashType: 'longDashDot',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
      {
        Id: ConsumptionTargetTypeId.CostTargetBDistribution,
        Name: this.utils.localizedString('ENERGY_MANAGEMENT.TARGET_SERIES.COST_TARGET_B_DISTR'),
        Color: '#a15600',
        DashType: 'longDashDot',
        Style: 'smooth',
        RequireConsumptionTargetsService: true,
        RequireActionsService: false
      },
    ];
  }
}
