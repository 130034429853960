import ModalButtonsController from './modal-buttons.controller';
import ModalButtonsTemplate   from 'raw-loader!./modal-buttons.html';

export default {
  template: ModalButtonsTemplate,
  controller: ModalButtonsController,
  controllerAs: 'vm',
  bindings: {
    bookmark: '<',
    download: '<',
    help: '<'
  }
};
