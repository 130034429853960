import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { COMMA_SEPARATED_INTEGERS } from '@enerkey/ts-utils';

/**
 * Provides integer parsing for text inputs. Use with `nullOrNotEmptyValidator`.
 *
 * @example
 * '1, 2, 3' -> [1, 2, 3]
 * 'a, b, c, 1' -> []
 * '' -> null
 */
@Directive({
  selector: 'input[type=text][integers]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IntegersDirective),
    multi: true,
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => IntegersDirective),
    multi: true,
  }]
})
export class IntegersDirective implements ControlValueAccessor, Validator {

  @HostBinding('disabled')
  public disabled: boolean = false;

  @Input() public emptyValueAsArray: boolean = false;

  private get inputElement(): HTMLInputElement {
    return this.elementRef.nativeElement;
  }

  private _onChange: (value: number[]) => void;
  private _onTouch: () => void;

  public constructor(
    private readonly elementRef: ElementRef<HTMLInputElement>,
    private readonly renderer: Renderer2,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  public writeValue(integers: number[]): void {
    this.setInputValue(integers?.join(', ') ?? '');
  }

  public registerOnChange(fn: (value: number[]) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = !!isDisabled;
    this.changeDetectorRef.detectChanges();
  }

  @HostListener('input', ['$event.target.value'])
  public onChange(value: string): void {
    this._onChange?.(this.getFormValue(value));
  }

  @HostListener('blur')
  public onTouch(): void {
    this._onTouch?.();

    // Reformat value when defocused
    if (COMMA_SEPARATED_INTEGERS.test(this.inputElement.value)) {
      this.writeValue(this.parse(this.inputElement.value));
    }
  }

  /**
   * Parses and interpolates pasted text into the existing values.
   */
  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData.getData('text/plain');
    const target = event.target;

    if (pastedData && target instanceof HTMLInputElement) {
      this.writeValue([
        ...this.parse(target.value.substring(0, target.selectionStart)),
        ...this.parse(pastedData),
        ...this.parse(target.value.substring(target.selectionEnd)),
      ]);
      this.onChange(this.inputElement.value);
      event.preventDefault();
    }
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return control.value === undefined
      ? { noArrayItems: true }
      : null;
  }

  private setInputValue(value: string): void {
    this.renderer.setProperty(this.inputElement, 'value', value);
  }

  private parse(value: string): number[] {
    return String.toIntegers(value, /[\s,]+/);
  }

  /** Empty value returns null, and an invalid value returns an empty array. */
  private getFormValue(value: string): number[] | null {
    if (!value) {
      return this.emptyValueAsArray ? [] : null;
    } else if (!COMMA_SEPARATED_INTEGERS.test(value)) {
      return undefined;
    } else {
      return this.parse(value);
    }
  }
}
