import { Pipe, PipeTransform } from '@angular/core';

import { GroupedConsumptionsWidgetRow } from '../shared/grouped-consumptions';

@Pipe({
  name: 'groupedConsumptionsFacilityAmount'
})
export class GroupedConsumptionsFacilityAmountPipe implements PipeTransform {
  public transform(row: GroupedConsumptionsWidgetRow): string {
    const excluded = row.facilitiesExcluded.length;
    const included = row.facilitiesIncluded.length;
    const total = excluded + included;
    return `${included}/${total}`;
  }
}
