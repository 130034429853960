import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { StateService } from '@uirouter/core';

import { UserService } from '../../../../services/user-service';
import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';

type TabItem = {
  readonly state: string;
  readonly translationKey: string;
};

@Component({
  selector: 'sustainability-base',
  templateUrl: './sustainability-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TemplateLifterService],
})
export class SustainabilityBaseComponent implements OnInit {

  public topRightContent: TemplateRef<unknown> = null;

  public readonly tabs: readonly TabItem[];

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly templateLifter: TemplateLifterService,
    stateService: StateService,
    userService: UserService
  ) {
    this.tabs = stateService.get().filter(state => state.name.startsWith('sustainability.')).filterMap<TabItem>(
      s => userService.hasAccess(s.data.auth),
      s => ({ state: s.name, translationKey: s.data.translationKey })
    );
  }

  public ngOnInit(): void {
    this.templateLifter.templateChanged$.subscribe(value => {
      this.topRightContent = value;
      this.changeDetector.detectChanges();
    });
  }
}
