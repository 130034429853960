class EmInputMultiSelectController {
  $onInit() {
    if (angular.isFunction(this.config.getOptions)) {
      this.config.getOptions(this.config.reportingObjectId).then(options => {
        this.options = options;
      });
    }

    // Map selected options for multi-select component
    this.initialValue = angular.isArray(this.config.initialValue) ?
      this.config.initialValue.map(id => ({ id: id })) : [];

    this.config.isValid = true;
  }

  onInputChange(value) {
    const mappedValue = value.map(val => val.id);
    this.config.initialValueVaries = false;
    this.onChange(this.config, mappedValue);
  }
}

export default EmInputMultiSelectController;
