import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

export interface IDateFormatService {
  fileNameFormattedDate: () => string;
}

@Injectable({
  providedIn: 'root'
})
export class DateFormatService implements IDateFormatService {
  public constructor(private datePipe: DatePipe) {
  }

  public fileNameFormattedDate(): string {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd_HH-mm-ss');
  }
}
