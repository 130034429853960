import { $InjectArgs } from '@enerkey/ts-utils';

import { Utils } from '../../../services/utils';

interface ConfigurationControlTab {
  name: string;
  state: string;
  active: boolean;
}

export class ConfigurationControlIndexController {
  public static readonly $inject: $InjectArgs<typeof ConfigurationControlIndexController> = [
    'utils'
  ];

  public readonly tabItems: ConfigurationControlTab[];

  public constructor(
    private utils: Utils
  ) {
    this.tabItems = [
      {
        name: this.utils.localizedString('CONFIGURATION_CONTROL.TAB.CONFIGURATIONS'),
        state: 'configuration-control.reader-groups',
        active: true
      },
      {
        name: this.utils.localizedString('CONFIGURATION_CONTROL.TAB.TERMINALS'),
        state: 'configuration-control.terminals',
        active: false
      },
    ];
  }
}
