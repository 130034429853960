import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';

import { LatestAlarmsWidgetOptions } from '../latest-alarms-widget/latest-alarms-widget.component';

type ChangeFn = (options: LatestAlarmsWidgetOptions) => void;
@Component({
  selector: 'latest-alarms-widget-options',
  templateUrl: './latest-alarms-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: LatestAlarmsWidgetOptionsComponent,
    multi: true,
  }]
})

export class LatestAlarmsWidgetOptionsComponent implements OnInit, ControlValueAccessor {
  @Input() public initialState: LatestAlarmsWidgetOptions;

  public readonly minCount: number = 5;
  public readonly maxCount: number = 200;

  public controls: ControlsOf<LatestAlarmsWidgetOptions>;
  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public ngOnInit(): void {
    this.controls = formControlsFrom<LatestAlarmsWidgetOptions>(this.optionsToForm(this.initialState));
    this.formGroup = new UntypedFormGroup(this.controls);

    this.formGroup.valueChanges.subscribe((formValue: LatestAlarmsWidgetOptions) => {
      this._onChange?.(formValue);
    });
  }

  public registerOnTouched(): void { }

  public writeValue(value: LatestAlarmsWidgetOptions): void {
    this.formGroup.setValue(
      this.optionsToForm(value)
    );
  }
  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  private optionsToForm(options: LatestAlarmsWidgetOptions): LatestAlarmsWidgetOptions {
    return {
      numberToShow: options.numberToShow ?? 20,
    };
  }
}
