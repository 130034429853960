export enum ErReportTypeName {
  FacilityOverview = 'modal.facility-overview',
  FacilityReport = 'modal.report',
  FacilityTrendReport = 'modal.report-trend',
  FacilityForecastReport = 'modal.report-forecast',
  MeterTreelist = 'modal.metertree-treelist',
  MeterReport = 'modal.metertree',
  MeterSumReport = 'modal.metertree-sum',
  MeterTrendReport = 'modal.metertree-trend',
  MeterTrendSumReport = 'modal.metertree-trend-sum',
  MeterComparisonReport = 'modal.metertree-comparison',
  MeterForecastReport = 'modal.metertree-forecast',
  MeterForecastSumReport = 'modal.metertree-forecast-sum'
}

export enum ErReportTypeGroupName {
  Facility = 'facility',
  Meter = 'meter'
}
