import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { indicate } from '@enerkey/rxjs';
import { FacilityClient, Url } from '@enerkey/clients/facility';
import { formGroupFrom } from '@enerkey/ts-utils';

import { FacilityEditService } from '../../services/facility-edit.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

type UrlForm = {
  label: string,
  value: string,
}

@Component({
  selector: 'facility-url-table',
  templateUrl: './facility-url-table.component.html',
  styleUrls: ['./facility-url-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityUrlTableComponent implements OnDestroy {
  public rowInEdit: number = null;

  public readonly urlForm: UntypedFormGroup;
  public readonly urls$: Observable<Url[]>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new BehaviorSubject<boolean>(true);
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly facilityEditService: FacilityEditService,
    private readonly facilityClient: FacilityClient,
    private readonly toasterService: ToasterService
  ) {
    this.loading$ = this._loading$.asObservable();
    this.urlForm = formGroupFrom<UrlForm>({
      label: new UntypedFormControl(null, Validators.required),
      value: new UntypedFormControl(null, Validators.required)
    });
    this.urls$ = this.facilityEditService.facilityId$.pipe(
      take(1),
      indicate(this._loading$),
      switchMap(facilityId => this.facilityClient.getFacilityUrls(facilityId)),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public addNewUrl(urls: Url[]): void {
    urls.splice(0, 0, new Url());
    this.rowInEdit = 0;
  }

  public saveChanges(url: Url): void {
    const data: UrlForm = this.urlForm.value;
    if (data.value.startsWith('http://') || data.value.startsWith('https://')) {
      url.label = data.label;
      url.value = data.value;
      this.facilityEditService.addNewUrl(url);
      this.rowInEdit = null;
      this.urlForm.patchValue({
        label: null,
        value: null
      });
    } else {
      this.toasterService.error('FACILITY.EDIT_FORM.INCORRECT_URL_FORMAT');
    }
  }

  public removeUrl(urls: Url[], index: number): void {
    this.facilityEditService.removeUrl(urls[index]);
    urls.remove(urls[index]);
  }

  public cancelEdit(urls: Url[]): void {
    // User cancel's new value creation, remove created row
    urls.splice(this.rowInEdit, 1);
    this.rowInEdit = null;
    this.urlForm.patchValue({
      label: null,
      value: null
    });
  }
}
