<ng-container *ngIf="!multiSelect">
  <ek-combo
    *labelBefore="'SUSTAINABILITY.TARGETS.SELECT_REPORT' | translate"
    [items]="reportItems$ | async"
    [formControl]="reportSelect"
    class="report-input"
  >
    <ng-template
      kendoDropDownListItemTemplate
      let-dataItem
    >
      {{ dataItem.description }} ({{ dataItem.year }})
    </ng-template>
  </ek-combo>
</ng-container>

<ng-container *ngIf="multiSelect">
  <ek-multi
    *labelBefore="'SUSTAINABILITY.TARGETS.REPORTS_TO_SHOW' | translate"
    [items]="reportItems$ | async"
    [formControl]="multiReportSelect"
    class="report-input"
  >
    <ng-template
      kendoDropDownListItemTemplate
      let-dataItem
    >
      {{ dataItem.description }} ({{ dataItem.year }})
    </ng-template>
  </ek-multi>
</ng-container>
