import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContentLoaderComponent } from './components/content-loader/content-loader.component';
import { LoadingSpinnerDirective } from './directives/loading-spinner.directive';
import { ButtonLoadingDirective } from './directives/button-loading.directive';

@NgModule({
  declarations: [
    ContentLoaderComponent,
    LoadingSpinnerDirective,
    ButtonLoadingDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ContentLoaderComponent,
    LoadingSpinnerDirective,
    ButtonLoadingDirective,
  ],
})
export class EkLoadingModule { }
