import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnChanges,
} from '@angular/core';

import { SimpleChangesOf } from '@enerkey/ts-utils';

@Component({
  selector: '[ekValueChange]',
  templateUrl: './value-change.component.html',
  styleUrls: ['./value-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueChangeComponent implements OnChanges {
  @HostBinding('class.negativeChange') public negativeChange = false;
  @HostBinding('class.positiveChange') public positiveChange = false;

  @Input() public ekValueChange: number;
  @Input() public contentAfter: boolean;
  @Input() public isAbsoluteChange: boolean;

  public ngOnChanges(changes: SimpleChangesOf<ValueChangeComponent>): void {
    if (changes.ekValueChange) {
      this.negativeChange = this.ekValueChange < 0;
      this.positiveChange = this.ekValueChange > 0;
    }
  }
}
