import template from 'raw-loader!./input-field.html';

export default {
  template: template,
  controllerAs: 'vm',
  bindings: {
    id: '@',
    label: '@',
    secondaryLabel: '@',
    model: '<',
    isDisabled: '<',
    onChange: '&',
    listPasteEnabled: '<',
    required: '<'
  }
};
