import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ModalViewHeaderService<T = void> implements OnDestroy {

  /** Modal download button clicked. Automatically completes when modal is closed. */
  public readonly download$: Observable<T>;

  private readonly _download = new Subject<T>();

  public constructor() {
    this.download$ = this._download.asObservable();
  }

  public get isDownloadEnabled(): boolean {
    return this._download.observers.length > 0;
  }

  public ngOnDestroy(): void {
    this._download.complete();
  }

  public onDownload(key: T): void {
    this._download.next(key);
  }

}
