import indicatorTemplate from 'raw-loader!../templates/mrc-invalid-input-indicator.html';

mrcReadingValidation.$inject = ['$compile', '$timeout', '$templateCache', '$window', '$filter', '$locale'];

export function mrcReadingValidation($compile, $timeout, $templateCache, $window, $filter, $locale) {
  function link(scope, element, attrs, ngModelCtrl) {
    ngModelCtrl.$formatters.push(formatAsNumber);

    var validity = true;
    var indicator = angular.element(indicatorTemplate);
    $compile(indicator)(scope);
    var top = 0;
    var left = 0;
    var watchers = {
      top: undefined,
      left: undefined
    };

    function positionChanged(n, o) {
      if(!validity && Math.abs(n-o)>1) {
        top = element.offset().top-30;
        left = element.offset().left;
        $timeout(function() {
          indicator.css({'top':top+'px', 'left':left+'px'});
          scope.$apply();
        }, 100);
      }
    }

    function bindPositionWatchers() {
      watchers.top = scope.$watch(function() {
        return element.offset().top;
      },
                                  function(n,o) { positionChanged(n,o); }
                                 );
      watchers.left = scope.$watch(function() {
        return element.offset().left;
      },
                                   function(n,o) { positionChanged(n,o); }
                                  );
    }
    function unbindPositionWatchers() {
      if(watchers.top !== undefined && watchers.left !== undefined) {
        watchers.top();
        watchers.left();
      }
    }

    angular.element($window).on('resize', function() {
      if(!validity) {
        top = element.offset().top-30;
        left = element.offset().left;
        $timeout(function() {
          indicator.css({'top':top+'px', 'left':left+'px'});
          scope.$apply();
        }, 100);
      }
    });

    scope.$watch(function() { return ngModelCtrl.$viewValue; }, function(newVal, oldVal) {
      if(newVal !== oldVal) {
        //first reset previous situation
        ngModelCtrl.$setValidity('inputValidity', true);
        if (newVal !==  null && newVal !== '') {
          var isInvalid = ngModelCtrl.$invalid;
          var inputValue = angular.copy(newVal);
          if (angular.isString(inputValue)) {
            inputValue = inputValue.replace(',', '.');
            inputValue = parseFloat(inputValue);
          }

          if (angular.isDefined(attrs.minValue) && attrs.minValue !== '') {
            var minValue = angular.copy(attrs.minValue);
            if (angular.isString(minValue)) {
              minValue = parseFloat(minValue);
            }

            if (inputValue < minValue) {
              isInvalid = true;
            }
          }

          if (angular.isDefined(attrs.maxValue) && attrs.maxValue !== '') {
            var maxValue = angular.copy(attrs.maxValue);
            if (angular.isString(maxValue)) {
              maxValue = parseFloat(maxValue);
            }

            if (inputValue > maxValue) {
              isInvalid = true;
            }
          }

          if (!isInvalid) {
            unbindPositionWatchers();
            indicator.remove();
            validity = true;
            if(angular.isDefined(scope.facility)) {
              scope.facility.invalidInputValues -= 1;
            }
            ngModelCtrl.$modelValue = inputValue;
            scope.ngModel = inputValue;
          }
          else if (isInvalid) {
            top = element.offset().top-30;
            left = element.offset().left;
            indicator.css({
              'top': top + 'px',
              'left': left + 'px'
            });
            angular.element(document.body).append(indicator);
            validity = false;
            if(angular.isDefined(scope.facility)) {
              scope.facility.invalidInputValues += 1;
            }
            bindPositionWatchers();
          }
        } else {
          unbindPositionWatchers();
          indicator.remove();
          if(!validity && angular.isDefined(scope.facility)) {
            scope.facility.invalidInputValues -= 1;
          }
          validity = true;
        }
        scope.$emit('inputValueChanged', {valid:validity, name:element[0].name, value: scope.ngModel });
      }
      //set model validity based on additional checks above
      ngModelCtrl.$setValidity('inputValidity', validity);
    });
    function cleanup() {
      unbindPositionWatchers();
      indicator.remove();
      angular.element($window).off('resize');
      if(!validity) {
        if(angular.isDefined(scope.facility)) {
          scope.facility.invalidInputValues -= 1;
        }
        validity = true;
        scope.$emit('inputValueChanged', {valid:!validity, name:element[0].name});
      }
    }
    element.on('$destroy', function () {
      cleanup();
    });
  }

  function formatAsNumber(value) {
    if (value) {
      const originalThousandSeparator = $locale.NUMBER_FORMATS.GROUP_SEP;
      $locale.NUMBER_FORMATS.GROUP_SEP = '';
      const formattedValue = $filter('number')(value);
      $locale.NUMBER_FORMATS.GROUP_SEP = originalThousandSeparator;
      return formattedValue;
    }
    return value;
  }

  return {
    require: 'ngModel',
    scope: {
      ngModel: '='
    },
    replace: true,
    controller: function() {},
    restrict: 'A',
    link: link
  };
}
