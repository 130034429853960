<kendo-grid
  [kendoGridBinding]="messages$ | async"
  [pageSize]="30"
  [pageable]="true"
  [kendoGridSelectBy]="selectKey"
  [selectable]="gridSelectableSettings"
  [selectedKeys]="selection"
  [sortable]="true"
  [resizable]="true"
  filterable="menu"
  (edit)="editHandler($event)"
  (save)="saveHandler($event)"
  (cancel)="cancelHandler($event)"
  (remove)="removeHandler($event)"
  class="grid-full-height"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      [primary]="true"
      (click)="retry()"
      [disabled]="!selection?.length"
    >
      {{ 'SUPERVISION.RETRY' | translate }}
    </button>
  </ng-template>
  <kendo-grid-checkbox-column [width]="26">
    <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
  </kendo-grid-checkbox-column>
  <kendo-grid-column
    field="created"
    format="g"
    [title]="'CREATED' | translate"
    filter="date"
    [width]="120"
  ></kendo-grid-column>
  <kendo-grid-column
    field="id"
    [title]="'ID'"
    [filterable]="false"
    [width]="160"
  ></kendo-grid-column>
  <kendo-grid-column
    field="status"
    [title]="'SUPERVISION.STATUS' | translate"
    [filterable]="false"
    [width]="120"
  >
  <ng-template
    kendoGridCellTemplate
    let-dataItem="dataItem"
  >
  {{ dataItem.status | requestStatusName | translate }}
  </ng-template>
  <status-select
    *kendoGridEditTemplate="let dataItem = dataItem; let formGroup = formGroup"
    [formControl]="formGroup.controls.status"
  ></status-select>
  </kendo-grid-column>
  <kendo-grid-column
    field="customerId"
    [title]="'SUPERVISION.CUSTOMER_ID' | translate"
    [width]="160"
  ></kendo-grid-column>
  <kendo-grid-column
    field="message"
    [title]="'SUPERVISION.MESSAGE' | translate"
  ></kendo-grid-column>
  <kendo-grid-column
    field="systemId"
    [title]="'SUPERVISION.SYSTEM_ID' | translate"
    [width]="100"
  ></kendo-grid-column>
  <kendo-grid-command-column [width]="100">
    <ng-template
      kendoGridCellTemplate
      let-dataItem="dataItem"
    >
      <button
        class="button button--link"
        (click)="downloadContent(dataItem)"
      >{{ 'PROVISIONING.DOWNLOAD' | translate }}</button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-command-column [width]="100">
    <ng-template kendoGridCellTemplate>
      <button
        kendoGridEditCommand
      ></button>
      <button
        kendoGridSaveCommand
      ></button>
      <button
        kendoGridCancelCommand
      ></button>
      <button
        kendoGridRemoveCommand
      ></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
