import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { SumConsumptionsWidgetService } from '../../services/sum-consumptions-widget.service';
import { TimeFrameString } from '../../../../services/time-frame-service';
import { SumConsumptionValues } from '../../shared/sum-consumptions';
import { ServiceLevelService } from '../../../../shared/services/service-level.service';
import { ServiceLevel } from '@enerkey/clients/facility';

@Component({
  selector: 'sum-consumptions-widget-content',
  templateUrl: './sum-consumptions-widget-content.component.html',
  styleUrls: ['./sum-consumptions-widget-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumConsumptionsWidgetContentComponent {
  @Input() public data: SumConsumptionValues;
  @Input() public isNormalized: boolean;

  public readonly hasServiceLevelMedium: boolean;

  public readonly resolution$: Observable<TimeFrameString>;
  public readonly hasComparisonPeriod$: Observable<boolean>;

  public constructor(
    private readonly sumConsumptionsWidgetService: SumConsumptionsWidgetService,
    serviceLevelService: ServiceLevelService
  ) {
    this.hasServiceLevelMedium = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);

    this.resolution$ = this.sumConsumptionsWidgetService.resolution$;
    this.hasComparisonPeriod$ = this.sumConsumptionsWidgetService.hasComparisonPeriod$;
  }

  public chartClick(): void {
    if (!this.hasServiceLevelMedium) {
      return;
    }

    this.sumConsumptionsWidgetService.chartClick({
      quantityId: this.data.sumData.quantityId,
      facilityIds: this.data.sumData.facilityIds,
      isNormalized: this.isNormalized
    });
  }
}
