import { EditProperties, TreeViewItem } from '@enerkey/clients/metering';
import { FormulaEditorService } from '../../../virtual-meters/services/formula-editor.service';
import { AddOperationType } from '../../shared/add-operation-type';

const inject = ['formulaEditorService'];

class VirtualMeterEditorAddController {
  public item: TreeViewItem;
  public operationSelect: (param: { operation: any; item: any}) => void;
  public operations = FormulaEditorService.operationTypeArray;
  public operationTranslations = FormulaEditorService.operationTypeTranslations;

  public constructor(public formulaEditorService: FormulaEditorService) {
  }

  public $onInit(): void {
    if (!this.item) {
      this.item = new TreeViewItem({
        editProperties: new EditProperties({})
      });
    }
  }

  public operationSelected(operation: AddOperationType): void {
    this.operationSelect({ operation, item: this.item });
  }

  public isOperationAllowed(item: TreeViewItem): (operation: AddOperationType) => boolean {
    return (operation: AddOperationType) => item.editProperties[operation];
  }
}

VirtualMeterEditorAddController.$inject = inject;

export default VirtualMeterEditorAddController;
