import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { isSameDay } from 'date-fns';
import { InspectionDefectItem } from '@enerkey/clients/manual-qa';
import { emailInfoProperties, InfoToSendForm } from '../components/email-send-modal/email-functions';
import { BucketDateDefectGroup } from './bucket-date-defect-group';

export interface IManualQaDefectEmailService {
  getBucketEmailBody(
    defects: InspectionDefectItem[],
    info: InfoToSendForm,
    body: string,
    signature: string
  ): string;
}

const sectionBreak = '<br/><br/>';

@Injectable()
export class ManualQaDefectEmailService implements IManualQaDefectEmailService {
  private static propertyTranslationKeys: Record<typeof emailInfoProperties[number], string> = {
    facilityName: 'FACILITY_NAME',
    meterId: 'METER_ID',
    meteringPointName: 'METER_NAME',
    protocolCode: 'PROTOCOL_CODE',
    eanCode: 'EAN_CODE',
    usageplaceNumber: 'USAGE_PLACE_NUMBER',
    sellerUsageplaceNumber: 'SELLER_USAGE_PLACE_NUMBER'
  }

  public constructor(
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {
  }

  public getBucketEmailBody(
    defects: InspectionDefectItem[],
    info: InfoToSendForm,
    body: string,
    signature: string
  ): string {
    const defectsGroupedByDates = defects.sortBy('from', 'desc').toGroupsBy(defect => this.getDefectDateString(defect));
    const fieldInfo = this.getFieldRow(info);

    const defectInfo = [...defectsGroupedByDates.entries()].map(entry => BucketDateDefectGroup.getEmailString(
      entry[0], entry[1], info
    ));

    return `${body}${sectionBreak}${fieldInfo}${defectInfo.join(sectionBreak)}${sectionBreak}${signature}`;
  }

  private getFieldRow(info: InfoToSendForm): string {
    const selectedFields = emailInfoProperties.reduce(
      (fields, property) => info[property]
        ? [...fields, (this.translateService.instant(ManualQaDefectEmailService.propertyTranslationKeys[property]))]
        : fields
      , []
    );
    return selectedFields.length > 1
      ? selectedFields.join(';').concat(sectionBreak)
      : ''
    ;
  }

  private getDefectDateString(defect: InspectionDefectItem): string {
    const fromString = this.datePipe.transform(defect.from, 'shortDate');
    const toString = defect.to ? this.datePipe.transform(defect.to, 'shortDate') : '';

    return isSameDay(defect.from, defect.to) ? fromString : `${fromString} - ${toString}`;
  }
}
