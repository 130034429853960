import template from 'raw-loader!./meter-cost-mass-edit-modal.component.html';

export default {
  bindings: {
    modalClose: '<',
    modalDismiss: '<',
    modalParams: '<'
  },
  controllerAs: 'vm',
  template
};
