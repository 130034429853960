import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ModalBase } from '@enerkey/foundation-angular';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { UserManagementClient } from '@enerkey/clients/user-management';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { NgfActiveModal } from '@enerkey/foundation-angular';

enum Fields {
  'ACCOUNT_END_DATE' = 1,
  'COMMENT' = 2,
  'ORGANIZATION' = 3,
  'LANGUAGE' = 4,
  'BULK_CREATE_THIRD_PARTY_SYSTEM_FIELD' = 5
}

@Component({
  templateUrl: './user-mass-edit-modal.component.html',
  styleUrls: ['./user-mass-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMassEditModalComponent extends ModalBase<void> implements OnDestroy {

  public selectedUsers: number[];
  public readonly massEditForm: UntypedFormGroup;
  public readonly fieldList: ComboItem<number>[] = getNumericEnumValues(Fields)
    .map(option => ({
      value: option,
      text: `ADMIN.${Fields[option]}`
    }));
  public readonly loading$: Observable<boolean>;
  public readonly Fields = Fields;

  private readonly _loading$ = new LoadingSubject(false);

  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly toasterService: ToasterService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.massEditForm = new UntypedFormGroup({
      field: new UntypedFormControl(1, Validators.required),
      value: new UntypedFormControl(null, Validators.required)
    });
    this.massEditForm.get('field').valueChanges.subscribe(() => {
      this.massEditForm.get('value').reset();
    });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public submit(): void {
    const data = this.massEditForm.value;
    this.userManagementClient.bulkUpdateUsers(
      data.field === Fields.BULK_CREATE_THIRD_PARTY_SYSTEM_FIELD ? data.value : undefined,
      data.field === Fields.LANGUAGE ? data.value : undefined,
      data.field === Fields.ORGANIZATION ? data.value : undefined,
      data.field === Fields.COMMENT ? data.value : undefined,
      data.field === Fields.ACCOUNT_END_DATE ? data.value : undefined,
      this.selectedUsers
    ).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('ADMIN.MODIFY_USERS.SAVE_SUCCESS');
        super.closeModal();
      },
      error: () => this.toasterService.error('ADMIN.MODIFY_USERS.SAVE_ERROR')
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
