import _ from 'lodash';
import { Service } from '../../../constants/service';
import { ActionsImpactType } from '../constants/actions-impact-type';
import { ServiceLevel } from '@enerkey/clients/facility';

const $inject = ['utils', 'erStateService', 'UserService'];
class AdditionalSettingService {

  constructor(utils, erStateService, UserService) {
    this.utils = utils;
    this.erStateService = erStateService;
    this.UserService = UserService;
  }

  getSettings() {
    return [
      {
        id: 'value-options',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.VALUE_OPTIONS.TITLE'),
        type: 'select',
        services: [],
        options: [
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.VALUE_OPTIONS.MEASURED'),
            Value: { Measured: true, Normalized: false }
          },
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.VALUE_OPTIONS.NORMALIZED'),
            Value: { Measured: false, Normalized: true }
          },
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.VALUE_OPTIONS.BOTH'),
            Value: { Measured: true, Normalized: true }
          }
        ],
        extendSeriesWithValue: true
      },
      {
        id: 'show-measurement',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.SHOW_MEASUREMENT'),
        type: 'checkbox',
        selected: true,
        services: [],
        param: 'showMeasurement'
      },
      {
        id: 'related-values',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.RELATED_VALUES'),
        type: 'multiselect',
        services: [],
        options: [], // filled later
        property: 'Id',
        param: 'series.RelatedValues',
        requiresServiceLevel: ServiceLevel.Large
      },
      {
        id: 'relational-values',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.DERIVED_VALUES'),
        type: 'multiselect',
        services: [],
        options: [], // filled later
        property: 'Id',
        param: 'series.RelationalUnitIds'
      },
      {
        id: 'actions-impact',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.ACTIONSIMPACT.ACTIONS'),
        type: 'multiselect',
        services: [Service.Actions],
        options: [
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.ACTIONSIMPACT.ELECTRICITY'),
            Id: ActionsImpactType.Electricity
          },
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.ACTIONSIMPACT.HEAT'),
            Id: ActionsImpactType.Heat
          },
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.ACTIONSIMPACT.FUEL'),
            Id: ActionsImpactType.Fuel
          },
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.ACTIONSIMPACT.WATER'),
            Id: ActionsImpactType.Water
          }
        ],
        property: 'Id',
        param: 'series.ActionsImpactTypes',
        requiresServiceLevel: ServiceLevel.Medium
      },
      {
        id: 'events',
        title: this.utils.localizedString('FACILITIES_REPORT.OVERVIEW.ACTIONS_TITLE'),
        type: 'multiselect',
        services: [Service.Actions, Service.Alarms, Service.Comments],
        options: [],
        property: 'Id',
        param: 'series.EventTypes',
        requiresServiceLevel: ServiceLevel.Medium
      },
      {
        id: 'consumption-target',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.CONSUMPTION_TARGET'),
        type: 'multiselect',
        services: [Service.Actions, Service.ConsumptionTargets],
        options: [],
        property: 'Id',
        param: 'series.ConsumptionTargetSeriesTypes',
        requiresServiceLevel: ServiceLevel.Medium
      },
      {
        id: 'distribution',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.DISTRIBUTION_TYPES'),
        type: 'select',
        services: [],
        options: [], // filled later
        property: 'Id',
        param: 'series.DistributionId',
        requiresServiceLevel: ServiceLevel.Large
      },
      {
        id: 'distribution-as-percent',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.DISTRIBUTION_AS_PERCENT'),
        type: 'select',
        services: [],
        options: [
          {
            Name: this.utils.localizedString('NO'),
            Value: false
          },
          {
            Name: this.utils.localizedString('YES'),
            Value: true
          }
        ],
        param: 'series.DistributionAsPercent',
        requiresServiceLevel: ServiceLevel.Large
      },
      {
        id: 'comparability',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.TITLE'),
        type: 'select',
        services: [],
        options: [
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.NONE'),
            Value: void 0
          },
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.BY_QUANTITY'),
            Value: 'ByQuantity'
          },
          {
            Name: this.utils.localizedString('FACILITIES.SIDEBAR.COMPARABLE_OPTIONS.BY_QUANTITIES'),
            Value: 'ByQuantities'
          }
        ],
        param: 'series.Comparables',
        requiresServiceLevel: ServiceLevel.Medium
      },
      {
        id: 'units',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.UNITS'),
        type: 'select',
        services: [],
        options: [], // filled later
        param: 'unitKey'
      },
      {
        id: 'change',
        title: this.utils.localizedString('CHANGE.TITLE'),
        type: 'select',
        services: [],
        options: [
          {
            Name: this.utils.localizedString('CHANGE.NONE'),
            Value: 'none'
          },
          {
            Name: this.utils.localizedString('CHANGE.RELATIVE'),
            Value: 'relative',
            Default: true
          },
          {
            Name: this.utils.localizedString('CHANGE.ABSOLUTE'),
            Value: 'absolute'
          },
          {
            Name: this.utils.localizedString('CHANGE.BOTH'),
            Value: 'both'
          }
        ],
        param: 'changeType',
        requiresServiceLevel: ServiceLevel.Large
      },
      {
        id: 'temperature',
        title: this.utils.localizedString('FACILITIES.SIDEBAR.TEMPERATURE'),
        type: 'select',
        services: [],
        options: [
          {
            Name: this.utils.localizedString('NO'),
            Value: false
          },
          {
            Name: this.utils.localizedString('YES'),
            Value: true
          }
        ],
        param: 'series.Temperature',
        requiresServiceLevel: ServiceLevel.Large
      }
    ];
  }

  getDisabledSettings(series) {
    const hasRelationalValues = series && Array.hasItems(series.RelationalUnitIds);
    const hasTargets = series && Array.hasItems(series.ConsumptionTargetSeriesTypes);
    const isOverview = this.erStateService.isFacilityOverviewReport();
    const isSum = this.erStateService.isSumReport();
    const isTrend = this.erStateService.isTrendReport();
    const isMeter = this.erStateService.isMeterReport();
    const isMeterCostsEnabled =
      this.UserService.hasService(Service.CostReportingMeterBased) ||
      this.UserService.hasService(Service.CostReportingLocationBased);
    const isForecast = this.erStateService.isForecastReport();
    const isComparison = this.erStateService.isComparisonReport();
    const isGrid = this.erStateService.isGridReport();
    const isFacilitiesGrid = this.erStateService.isFacilityGridReport();

    const settings = [
      {
        id: 'value-options',
        disabled: isOverview
      },
      {
        id: 'show-measurement',
        disabled: !(hasRelationalValues || hasTargets) || isMeter || isOverview
      },
      {
        id: 'related-values',
        disabled: isSum || isTrend || isOverview || isForecast
      },
      {
        id: 'distribution',
        disabled: isOverview || isForecast
      },
      {
        id: 'distribution-as-percent',
        disabled: isOverview || isForecast
      },
      {
        id: 'units',
        disabled: isOverview
      },
      {
        id: 'change',
        disabled: isOverview
      },
      {
        id: 'comparability',
        disabled: isTrend || isForecast || isOverview
      },
      {
        id: 'relational-values',
        disabled: (isMeter && !isMeterCostsEnabled) || isOverview
      },
      {
        id: 'temperature',
        disabled: isForecast || isComparison || isGrid || (isMeter && isSum) || isOverview
      },
      {
        id: 'consumption-target',
        disabled: isTrend || isComparison || isOverview
      },
      {
        id: 'actions-impact',
        disabled: !isFacilitiesGrid
      },
      {
        id: 'events',
        disabled: !isFacilitiesGrid
      }
    ];

    return settings.reduce((result, { disabled, id }) => disabled ? result.concat(id) : result, []);
  }

  filterDisabled(series) {
    const settings = this.getSettings();
    const disabledSettings = this.getDisabledSettings();

    const filteredSeries = {};
    _.each(series, (value, param) => {
      const foundSetting = settings.find(setting => setting.param === `series.${param}`);
      if (foundSetting ? !disabledSettings.includes(foundSetting.id) : true) {
        filteredSeries[param] = value;
      }
    });
    return filteredSeries;
  }
}

AdditionalSettingService.$inject = $inject;

export default AdditionalSettingService;
