<label>
  <ng-content></ng-content>
</label>
<a
  class="button button--no-padding button--link button--no-focus"
  title="{{ 'ADMIN.CONTRACTS.EDIT_MULTIPLE' | translate }}"
  [attr.disabled]="disabled ? true : null"
  (click)="onClick()"
>
  <i class="fas fa-edit"></i>
</a>
