import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { formGroupFrom } from '@enerkey/ts-utils';

import { IUserManagementSearchCriteria, UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'user-management-search',
  templateUrl: './user-management-search.component.html',
  styleUrls: ['./user-management-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserManagementSearchComponent {

  public readonly searchForm: UntypedFormGroup;
  public readonly loading$: Observable<boolean>;

  public constructor(
    private readonly userManagementService: UserManagementService
  ) {
    this.loading$ = this.userManagementService.loading$;
    this.searchForm = formGroupFrom<IUserManagementSearchCriteria>({
      userName: undefined,
      firstname: undefined,
      lastname: undefined,
      email: undefined,
      organization: undefined,
      organizationId: undefined,
      profileName: undefined,
      excludeProfileName: undefined,
      facilityId: undefined,
      includeInActive: false
    });
  }

  public search(): void {
    this.userManagementService.search(this.searchForm.value);
  }
}
