<div class="shared-tooltip">
  <div class="shared-tooltip-title">
    {{ title }}
  </div>
  <div class="shared-tooltip-table">
    <table>
      <ng-container *ngFor="let point of points">
        <tr
          widget-tooltip-consumption-row
          *ngIf="point.series.axis === ChartAxis.Consumption"
          [time]="point.dataItem.timestamp | resolutionTitle:resolution"
          [color]="point.series.color"
          [isNormalized]="isNormalized"
          [quantityId]="quantityId"
          [value]="point.value"
          [unitKey]="unitKey"
        ></tr>
        <ng-container *ngFor="let relationalValueId of relationalValueIds">
          <tr
            widget-tooltip-relational-value-row
            *ngIf="point.series.axis === relationalValueId"
            [time]="point.dataItem.timestamp | resolutionTitle:resolution"
            [color]="point.series.color"
            [isNormalized]="isNormalized"
            [quantityId]="quantityId"
            [value]="point.value"
            [relationalValueId]="relationalValueId"
            [unitKey]="unitKey"
          ></tr>
        </ng-container>
      </ng-container>
    </table>
  </div>
</div>
