import _ from 'lodash';

const $inject = ['utils', 'ERReportType'];

export class ErParamsService {
  constructor(utils, ERReportType) {
    this.utils = utils;
    this.ERReportType = ERReportType;
  }

  validateSeries(series) {
    return series && series.Resolution && series.TimeFrame && series.Start.length;
  }

  getSelectedFacilities(cache, facilityIds) {
    return _.chain(facilityIds)
      .map(facilityId => cache.facilitiesById[facilityId])
      .filter(facility => facility)
      .sortBy('Name')
      .value();
  }

  handleError(error, title) {
    if (!title) {
      title = this.utils.localizedString('FACILITIES.DOWNLOAD_ERROR');
    }
    this.utils.popUp('error', title, error);
  }

  getQuantity(cache, id) {
    return cache.quantitiesById[id];
  }

  filterRelatedValueIdsForQuantity(cache, relatedValueIds, quantityId) {
    const relatedValues = this.getRelatedValues(cache, quantityId);
    return _.filter(relatedValueIds, relatedValueId => _.find(relatedValues, { Id: relatedValueId }));
  }

  getRelatedValues(cache, quantityId) {
    return cache.relatedValuesByQuantityId[quantityId];
  }

  getSeriesErrorTextKey(series, reportType) {
    if (_.get(series, 'ExportOnly')) {
      return 'FACILITIES_REPORT.ONLY_DOWNLOAD';
    } else if (reportType === this.ERReportType.TREND_REPORT && series.Resolution !== 'P1M') {
      return 'FACILITIES_REPORT.NO_DATA_FOR_TREND_REPORT';
    }
    return null;
  }

  getFlatSectionConfigsWithTemplates(sectionConfigs) {
    return _.reduce(sectionConfigs, (result, sectionConfig) => {
      if (sectionConfig.template) {
        result.push(sectionConfig);
      }
      _.each(sectionConfig.sections, childSectionConfig => {
        if (childSectionConfig.template) {
          result.push(childSectionConfig);
        }
      });
      return result;
    }, []);
  }

  getChangedParamsConfig(newParams, oldParams) {
    const changedParams = [];
    _.each(newParams, (value, key) => {
      if (angular.toJson(newParams[key]) !== angular.toJson(oldParams[key])) {
        changedParams.push(key);
      }
    });
    return {
      changedParams: changedParams,
      oldParams: _.cloneDeep(oldParams)
    };
  }

}

ErParamsService.$inject = $inject;
