import EmInputDateController from './em-input-date-controller';
import emInputDateTemplate   from 'raw-loader!./em-input-date.html';

export default {
  template: emInputDateTemplate,
  controller: EmInputDateController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    onChange: '<'
  }
};
