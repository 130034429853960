import TimeFrameSelectController from './time-frame-select-controller';
import template from 'raw-loader!./time-frame-select.html';

const TimeFrameSelectComponent = {
  template: template,
  controllerAs: 'vm',
  controller: TimeFrameSelectController,
  bindings: {
    timeFrame: '<',
    isDisabled: '<',
    includeTime: '<',
    hideToDate: '<',
    min: '<',
    max: '<',
    interval: '<',
    useCustomTimeSelections: '<',
    onChange: '&'
  }
};

export default TimeFrameSelectComponent;
