import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, skip, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { ModalService } from '@enerkey/foundation-angular';
import { ConfigurationControlClient, Terminal } from '@enerkey/clients/configuration-control';
import {
  ErrorTicket,
  ErrorTicketClient,
  ErrorType,
  QueryCriteriaForFacilities,
  Resolution,
  StatusType
} from '@enerkey/clients/error-ticket';
import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { indicate, LoadingSubject, switchJoin } from '@enerkey/rxjs';

import { UserService } from '../../../../services/user-service';
import { DashboardStateService } from '../../services/dashboard-state.service';
import { WidgetBase } from '../../shared/widget-base.interface';
import { ErrorTicketMeterModalComponent } from '../error-ticket-meter-modal/error-ticket-meter-modal.component';
import {
  ErrorTicketWidgetOptions,
  ErrorWidgetTicketType
} from '../error-ticket-widget-options/error-ticket-widget-options.component';
import { FacilityService } from '../../../../shared/services/facility.service';
import { HelpService } from '../../../../shared/services/help.service';
import { TerminalCommentModalComponent }
  from '../../../../shared/terminal/terminal-comment-modal/terminal-comment-modal.component';
import { Roles } from '../../../admin/constants/roles';

type Ticket = {
  errorTicket: ErrorTicket,
  terminalName?: string;
  terminalComment?: string;
  facilities: { name: string, id: number, enegiaId: number }[];
  status: StatusType;
}

@Component({
  selector: 'error-ticket-widget',
  templateUrl: './error-ticket-widget.component.html',
  styleUrls: ['./error-ticket-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorTicketWidgetComponent implements OnInit, OnDestroy, WidgetBase<ErrorTicketWidgetOptions> {
  @Input() public dataModelOptions: ErrorTicketWidgetOptions;

  public readonly errorTypeField = 'errorType';

  public readonly ErrorWidgetTicketType = ErrorWidgetTicketType;
  public readonly StatusType = StatusType;

  public readonly dataModelChange$: Observable<ErrorTicketWidgetOptions>;
  public readonly error$: Observable<void>;
  public readonly loading$: Observable<boolean>;
  public readonly tickets$: Observable<Ticket[]>;
  public readonly isTerminalErrorType$: Observable<boolean>;

  public readonly errorTypeForm: UntypedFormGroup;

  public readonly measurementErrorHelpMainPageId: string = '55PlCnUpBc2U6OyHbPUqVo';
  public readonly terminalErrorMainPageId: string = '4I5BWJY9Y88jcKUSJKdFh7';
  public readonly facilityErrorMainPageId: string = '2e7lYtvGxUs7t1R59YbZqE';
  public readonly isUserAllowedToEdit: boolean;

  private readonly errorType$: Observable<ErrorWidgetTicketType>;
  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();
  private readonly _ticketsRefresh$ = new Subject<void>();

  public constructor(
    private readonly errorTicketClient: ErrorTicketClient,
    private readonly configurationControlClient: ConfigurationControlClient,
    private readonly facilityClient: FacilityClient,
    private readonly dashboardStateService: DashboardStateService,
    private readonly modalService: ModalService,
    private readonly userService: UserService,
    private readonly facilityService: FacilityService,
    private readonly helpService: HelpService,
    formBuilder: UntypedFormBuilder
  ) {
    this.isUserAllowedToEdit = this.userService.hasRole(Roles.CONFIGURATION_MANAGER)
      || this.userService.hasRole(Roles.ENERGY_MANAGER)
      || this.userService.hasRole(Roles.METER_MANAGER_USER)
      || this.userService.hasRole(Roles.QUALITY_MANAGER)
      || this.userService.hasRole(Roles.FACILITY_CREATE_DELETE)
      || this.userService.hasRole(Roles.FACILITY_UPDATE)
      || this.userService.hasRole(Roles.ALARM_MANAGER);

    this.errorTypeForm = formBuilder.group({
      [this.errorTypeField]: [null]
    });
    this.errorType$ = this.errorTypeForm.valueChanges.pipe(
      map(value => value[this.errorTypeField]),
      shareReplay(1)
    );

    this.dataModelChange$ = this.errorType$.pipe(
      skip(1),
      map(value => ({
        ...this.dataModelOptions,
        typeToShow: value
      }))
    );

    this.errorType$
      .pipe(takeUntil(this._destroy$))
      .subscribe(errorType => {
        this.dataModelOptions.typeToShow = errorType;
      });

    this.isTerminalErrorType$ = this.errorType$.pipe(
      map(errorType => errorType === ErrorWidgetTicketType.Terminal)
    );
    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();

    this.tickets$ = combineLatest([
      this.errorType$,
      this.facilityService.facilityIdsForSearch$,
      this.facilityService.filteredProfileFacilityIds$,
      this._ticketsRefresh$.pipe(startWith(undefined))
    ]).pipe(
      switchMap(([errorType, facilityIdsForSearch, filteredFacilityIds]) => this.getErrorTickets(
        errorType,
        facilityIdsForSearch,
        filteredFacilityIds
      )),
      catchError(() => {
        this._error$.next();
        return EMPTY;
      })
    );
  }

  public ngOnInit(): void {
    this.errorTypeForm.setValue({
      [this.errorTypeField]: this.dataModelOptions.typeToShow ?? ErrorWidgetTicketType.Facility
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._error$.complete();
    this._ticketsRefresh$.complete();
  }

  public openMeasurementErrorHelpMainPage(): void {
    this.helpService.openHelpEntry(this.measurementErrorHelpMainPageId);
  }

  public openFacilityReport(facilityId: number): void {
    this.dashboardStateService.openFacilityReport(facilityId);
  }

  public openMeterModal(ticket: Ticket): void {
    const modalHeaderText = this.dataModelOptions.typeToShow === ErrorWidgetTicketType.Terminal
      ? ticket.terminalName
      : ticket.facilities[0].name
    ;
    const modalRef = this.modalService.open(ErrorTicketMeterModalComponent);
    modalRef.componentInstance.isFacilityError = this.dataModelOptions.typeToShow !== ErrorWidgetTicketType.Terminal;
    modalRef.componentInstance.errorTicket = ticket.errorTicket;
    modalRef.componentInstance.modalHeaderText = modalHeaderText;
    modalRef.componentInstance.facilities = ticket.facilities;
  }

  public openHelpPageForTicket(ticket: Ticket): void {
    this.isTerminalErrorWidgetType().subscribe({
      next: isTerminalError => {
        if (isTerminalError) {
          this.openHelpPageForDevice(ticket);
        } else {
          this.openHelpPageForFacilities();
        }
      },
      error: () => { this.helpService.openHelpEntry(this.measurementErrorHelpMainPageId); }
    });
  }

  public editTerminalComment(ticket: Ticket): void {
    const terminalId = ticket.errorTicket.terminalId;
    const terminalName = ticket.terminalName;
    const text = ticket.terminalComment;

    const modalRef = this.modalService.open(TerminalCommentModalComponent);
    modalRef.componentInstance.terminalId = terminalId;
    modalRef.componentInstance.terminalName = terminalName;
    modalRef.componentInstance.text = text;
    modalRef.componentInstance.allowEdit = this.isUserAllowedToEdit;
    modalRef.result
      .then(() => this._ticketsRefresh$.next())
      .catch(() => {});
  }

  private isTerminalErrorWidgetType(): Observable<boolean> {
    return this.errorType$.pipe(
      take(1),
      map(errorType => errorType === ErrorWidgetTicketType.Terminal)
    );
  }

  private openHelpPageForDevice(ticket: Ticket): void {
    const deviceId = ticket.errorTicket.deviceId;
    if (deviceId && deviceId > 0) {
      this.errorTicketClient.getDevice(deviceId)
        .subscribe({
          next: device => {
            const errorType = ticket.errorTicket.errorType;
            const instruction = device.repairInstructions?.find(r => r.errorType === errorType)
              ?? device.repairInstructions?.find(r => r.errorType === ErrorType.Any);
            const helpPageId = instruction?.helpPageId ?? this.terminalErrorMainPageId;
            this.helpService.openHelpEntry(helpPageId);
          },
          error: () => { this.helpService.openHelpEntry(this.terminalErrorMainPageId); }
        });
    } else {
      this.helpService.openHelpEntry(this.terminalErrorMainPageId);
    }
  }

  private openHelpPageForFacilities(): void {
    this.helpService.openHelpEntry(this.facilityErrorMainPageId);
  }

  private getErrorTickets(
    errorType: ErrorWidgetTicketType,
    facilityIdsForSearch: number[],
    filteredFacilityIds: number[]
  ): Observable<Ticket[]> {

    let errorRequest;

    switch (errorType) {
      case ErrorWidgetTicketType.Terminal:
        errorRequest = this.getTerminalErrorTickets(filteredFacilityIds);
        break;
      case ErrorWidgetTicketType.Facility:
        errorRequest = this.getFacilityErrorTickets(facilityIdsForSearch, Resolution.PT1H);
        break;
      case ErrorWidgetTicketType.FacilityPT15M:
        errorRequest = this.getFacilityErrorTickets(filteredFacilityIds, Resolution.PT15M);
        break;
      default:
        errorRequest = this.getTerminalErrorTickets(filteredFacilityIds);
        break;
    }
    // note: no pooling here as this is the only widget using this api
    return errorRequest.pipe(indicate(this._loading$));
  }

  private getFacilityErrorTickets(facilityIds: number[], resolution: Resolution): Observable<Ticket[]> {
    const errorTypes = resolution === Resolution.PT1H
      ? [ErrorType.SingleMeasurementStoppedOpenEndZero, ErrorType.SingleMeasurementStoppedOpenEndMissingValue]
      : [ErrorType.SingleMeasurementStoppedOpenEndMissingValue];

    return this.errorTicketClient.getErrorTicketsForFacilities(
      new QueryCriteriaForFacilities({
        errorTypes: errorTypes,
        resolution: resolution,
        statusTypes: [StatusType.Open, StatusType.CustomerInformed],
        profileId: this.userService.profileId,
        resultCount: this.dataModelOptions.numberToShow,
        facilityIds: facilityIds
      })
    ).pipe(
      switchJoin(tickets => this.getTicketFacilities(tickets)),
      map(([tickets, facilities]) => tickets.map<Ticket>(ticket => {
        const ticketFacility = facilities.get(ticket.facilityId);
        return {
          errorTicket: ticket,
          facilities: [{
            id: ticketFacility.id,
            name: ticketFacility.displayName,
            enegiaId: ticketFacility.enegiaId,
          }],
          status: ticket.ticketStatuses[0].status
        };
      }))
    );
  }

  private getTicketFacilities(tickets: ErrorTicket[]): Observable<Map<number, Facility>> {
    return Array.hasItems(tickets)
      ? this.facilityClient.getFacilities(
        tickets.unique(t => t.facilityId).filter(f => f)
      ).pipe(
        map(facilities => facilities.toMapBy('id'))
      )
      : of(new Map())
    ;
  }

  private getTerminalErrorTickets(facilityIds: number[]): Observable<Ticket[]> {
    return this.errorTicketClient.getErrorTicketsForTerminals(
      new QueryCriteriaForFacilities({
        statusTypes: [StatusType.Open, StatusType.CustomerInformed],
        profileId: this.userService.profileId,
        resultCount: this.dataModelOptions.numberToShow,
        facilityIds: facilityIds
      })
    ).pipe(
      switchJoin(tickets => this.getTicketTerminals(tickets, facilityIds)),
      map(([tickets, terminals]) => tickets.filterMap<Ticket>(
        ticket => terminals.has(ticket.terminalId),
        ticket => {
          const ticketTerminal = terminals.get(ticket.terminalId);
          return {
            errorTicket: ticket,
            terminalName: ticketTerminal.name,
            terminalComment: ticketTerminal.comment,
            facilities: ticketTerminal.facilities.filterMap(
              // Configuration control api returns also facilities that don't belong to current profile,
              // filter to show only profile facilities
              f => facilityIds.includes(f.facilityId),
              f => ({
                id: f.facilityId,
                name: f.facilityName,
                enegiaId: f.enegiaId
              })
            ),
            status: ticket.ticketStatuses[0].status
          };
        }
      ))
    );
  }

  private getTicketTerminals(
    tickets: ErrorTicket[],
    facilityIds: number[]
  ): Observable<Map<number, Terminal>> {
    return Array.hasItems(tickets)
      ? this.configurationControlClient.getTerminalBaseInfoByFacilityTerminalDto(
        this.userService.profileId,
        {
          facilityIds: facilityIds,
          terminalIds: tickets.unique(t => t.terminalId).filter(id => id),
        }
      ).pipe(map(terminals => terminals.toMapBy('id')))
      : of(new Map())
    ;
  }
}

