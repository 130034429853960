import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';

import { groupBy } from '@progress/kendo-data-query';

import {
  IProfileViewModel,
  ProfileViewModel,
  ServiceViewModel,
} from '@enerkey/clients/user-management';
import { EditEventOf } from '@enerkey/ts-utils';
import { ModalService } from '@enerkey/foundation-angular';

import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';
import { ProfileService } from '../../../../shared/services/profile.service';
import { ServicesService } from '../../../../shared/services/services.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { KendoGridSelection } from '../../../alarm-management/shared/kendo-grid-selection';
import { ProfileManagementService } from '../../services/profile-management.service';
import { ProfileEditModalComponent } from '../profile-edit-modal/profile-edit-modal.component';
import { ProfileMassEditModalComponent } from '../profile-mass-edit-modal/profile-mass-edit-modal.component';
import { UserService } from '../../../../services/user-service';
import { getServiceGroup } from '../../../../constants/service';
import { CompaniesService } from '../../../../shared/services/companies.service';
import { CompanyMassEditModelComponent } from '../../../../shared/admin-shared/components/company-mass-edit-model/company-mass-edit-model.component';

interface IServiceColumn {
  field: string,
  title: string,
  abbreviation: string,
  group: string,
  groupKey: string,
}

interface IServiceGroup {
  aggregates: unknown
  field: string
  items: IServiceColumn[]
  value: string
}

type RowSelectKey = 'id';

export class ExtendedProfileViewModel extends ProfileViewModel {
  public companyName?: string | null;

  public constructor(profileViewModel: ProfileViewModel, companyName?: string | null) {
    super();
    Object.assign(this, profileViewModel);
    this.companyName = companyName;
  }
}

@Component({
  selector: 'profile-management-grid',
  templateUrl: './profile-management-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService],
})
export class ProfileManagementGridComponent implements OnDestroy, AfterViewInit {

  public readonly currentProfileId: number;
  public readonly loading$: Observable<boolean>;

  public profiles$: Observable<ProfileViewModel[]>;
  public serviceCols$: Observable<IServiceColumn[]>;
  public serviceGroups$: Observable<IServiceGroup[]>;
  public gridSelection: KendoGridSelection<ExtendedProfileViewModel, 'id'>;
  public selectedProfiles: number[] = [];
  public readonly selectKey: RowSelectKey = 'id';
  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };
  public get currentDate(): Date {
    return new Date();
  }

  @ViewChild(GridComponent) private readonly kendoGrid: GridComponent;
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    userService: UserService,
    private readonly profileManagementService: ProfileManagementService,
    private readonly toasterService: ToasterService,
    private readonly companiesService: CompaniesService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly servicesService: ServicesService,
    private readonly profileService: ProfileService,
    private readonly gridService: KendoGridService<ExtendedProfileViewModel, RowSelectKey>
  ) {
    this.currentProfileId = userService.profileId;
    this.loading$ = this.profileManagementService.loading$;

    this.profiles$ = combineLatest([
      this.profileManagementService.searchResult$,
      this.servicesService.getServices(),
      this.companiesService.getCompanies(),
    ]).pipe(
      map(([profiles, services, companies]) => {
        this.gridSelection = new KendoGridSelection([], 'id');
        return profiles.map(profile => {
          const companyName = companies.find(c => c.id === profile.companyId)?.name || null;
          const extendedProfile = new ExtendedProfileViewModel(profile, companyName);
          extendedProfile.availableServices = this.getProfileServices(profile, services);
          return extendedProfile;
        });
      }),
      catchError(() => {
        this.toasterService.error('ADMIN.PROFILE_SEARCH_FAILED');
        return of([]);
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.serviceGroups$ = this.servicesService.getServices().pipe(
      map(services => {
        const ss = services.map(option => {
          const groupKey = getServiceGroup(option.name);
          const title = this.translateService.instant(`ADMIN.SERVICE_NAME_${option.name.toUpperCase()}`);
          return {
            field: `availableServices.${option.id}`,
            title,
            abbreviation: String.abbreviate(title),
            group: this.translateService.instant(`ADMIN.SERVICE_GROUP_${groupKey}`),
            groupKey,
          };
        }).sortByMany(
          g => g.groupKey === 'OTHERS',
          g => g.group,
          g => g.title
        );
        return groupBy(ss, [{ field: 'group', dir: 'desc' }]) as IServiceGroup[];
      })
    );

    this.profiles$.subscribe(data => {
      this.gridService.dataChanged(data);
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.gridService.selection$.subscribe(keys => {
      this.selectedProfiles = keys;
    });
  }

  public editProfile({ dataItem }: EditEventOf<ProfileViewModel>): void {
    const modal = this.modalService.open(ProfileEditModalComponent);
    modal.componentInstance.profile = dataItem;
    modal.result
      .then(() => this.profileManagementService.repeat())
      .catch(() => { });
  }

  public changeProfile(profile: IProfileViewModel): void {
    this.profileService.changeProfile(profile);
  }

  public massChangeServices(): void {
    const modal = this.modalService.open(ProfileMassEditModalComponent);
    modal.componentInstance.selectedProfiles = this.selectedProfiles;
    modal.result
      .then(() => this.profileManagementService.repeat())
      .catch(() => { });
  }

  public massChangeCompanies(): void {
    const modal = this.modalService.open(CompanyMassEditModelComponent);
    modal.componentInstance.selectedProfiles = this.selectedProfiles;
    modal.result
      .then(() => this.profileManagementService.repeat())
      .catch(() => { });
  }

  private getProfileServices(profile: ProfileViewModel, serviceList: ServiceViewModel[]): Record<number, boolean> {
    return serviceList.toRecord(s => s.id, s => profile.serviceIds.includes(s.id));
  }
}
