import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { TreeViewItem } from '@enerkey/clients/metering';
import { FormulaItemEdit } from './shared/formula-item-edit';
import { FormulaItemAdd } from './shared/formula-item-add';

@Directive({
  selector: 'formula-editor-treelist'
})
export class FormulaEditorTreelistDirective extends UpgradeComponent {
  @Input() public formulaData: readonly TreeViewItem[];
  @Input() public loading: boolean;
  @Output() public addItem = new EventEmitter<FormulaItemAdd>();
  @Output() public deleteItem = new EventEmitter<TreeViewItem>();
  @Output() public editItem = new EventEmitter<FormulaItemEdit>();

  public constructor(elementRef: ElementRef, injector: Injector) {
    super('formulaEditorTreelist', elementRef, injector);
  }
}
