<form
  singleInputEnabled
  [formGroup]="searchForm"
  (ngSubmit)="search()"
  id="searchForm"
>
  <div>
    <div class="shortcuts">
      <label><strong>{{ 'GRID_SHORTCUTS.TITLE' | translate }}</strong></label>
      <button
        type="button"
        kendoButton
        [disabled]="loading$ | async"
        (click)="getCurrentProfile()"
      >
        {{ 'SETTINGS.CURRENT_PROFILE' | translate }}
      </button>
      <button
        type="button"
        kendoButton
        [disabled]="loading$ | async"
        (click)="getWithoutProfile()"
      >
        {{ 'ADMIN.FACILITIES_WITHOUT_PROFILE' | translate }}
      </button>
    <hr>
    </div>
    <profile-search-select
      *labelBefore="'ADMIN.PROFILENAME' | translate"
      formControlName="profilePickerIds"
    ></profile-search-select>
    <input
      *labelBefore="'ADMIN.PROFILEID' | translate"
      formControlName="profileIds"
      type="text"
      kendoTextBox
      integers
    >
    <input
      *labelBefore="'ADMIN.FACILITYID' | translate"
      formControlName="facilityId"
      type="text"
      kendoTextBox
      integers
    >
    <input
      *labelBefore="'ADMIN.FACILITY_NAME' | translate"
      formControlName="facilityName"
      type="text"
      kendoTextBox
    >
    <input
      *labelBefore="'ADMIN.ENEGIAID' | translate"
      formControlName="enegiaId"
      type="text"
      kendoTextBox
      integers
    >
    <kendo-numerictextbox
      *labelBefore="'BUSINESS_IDENTITY_CODE' | translate"
      formControlName="companyId"
      kendoTextBox
      integerInput
    ></kendo-numerictextbox>
    <input
      *labelBefore="'ADMIN.REALESTATE_ID' | translate"
      formControlName="realestateId"
      type="text"
      kendoTextBox
    >
    <input
      *labelBefore="'FACILITYINFORMATION.STREETADDRESS' | translate"
      formControlName="address"
      type="text"
      kendoTextBox
    >
    <input
      *labelBefore="'FACILITY.EDIT_FORM.POSTALCODE' | translate"
      formControlName="postalCode"
      type="text"
      kendoTextBox
    >
    <input
      *labelBefore="'FACILITY.EDIT_FORM.CITY' | translate"
      formControlName="city"
      type="text"
      kendoTextBox
    >
    <input
      *labelBefore="'FACILITY.EDIT_FORM.COUNTRY' | translate"
      formControlName="country"
      type="text"
      kendoTextBox
    >
  </div>
</form>
<form [formGroup]="extraOptionsForm">
  <hr>
  <p>
    <input
      *labelWrap="'ADMIN.GET_METER_COUNT' | translate"
      formControlName="getMeterCount"
      type="checkbox"
      kendoCheckBox
    >
  </p>
  <p>
    <input
      *labelWrap="'ADMIN.GET_CONTACT_INFORMATION' | translate"
      formControlName="getContactInformation"
      type="checkbox"
      kendoCheckBox
    >
  </p>
  <div class="buttons-container">
    <button
      class="button"
      type="submit"
      form="searchForm"
      [loading]="loading$ | async"
    >
      {{ 'SEARCH' | translate }}
    </button>
    <button
      class="button button--secondary float-right"
      type="button"
      (click)="searchForm.reset(); extraOptionsForm.reset()"
    >
      {{ 'RESET' | translate }}
    </button>
  </div>
</form>
