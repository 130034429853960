import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UserService } from '../../../../services/user-service';
import { SUPERVISION_CHILDREN } from '../../supervision.states';

@Component({
  selector: 'supervision',
  templateUrl: './supervision.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupervisionComponent {

  public readonly tabs: { state: string; name: string }[];

  public constructor(userService: UserService) {
    this.tabs = SUPERVISION_CHILDREN.filterMap(
      s => userService.hasAccess(s.data?.auth),
      s => ({ state: s.name, name: s.data?.translationKey })
    );
  }
}
