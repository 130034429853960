import * as FetchType from './manual-qa-fetch-type.constant';

/**
 * Checks if series mode is either hour or both
 *
 * @param {number} fetchType
 *
 * @returns {boolean}
 */
export const isFetchTypeHourOrBoth = fetchType => (
  isFetchTypeHour(fetchType) ||
  isFetchTypeBoth(fetchType)
);

/**
 * Checks if series mode is cumulative or both
 *
 * @param {number} fetchType
 *
 * @returns {boolean}
 */
export const isFetchTypeCumulativeOrBoth = fetchType => (
  isFetchTypeCumulative(fetchType) ||
  isFetchTypeBoth(fetchType)
);

/**
 * Checks if series mode is both
 *
 * @param {number} fetchType
 *
 * @returns {boolean}
 */
export const isFetchTypeBoth = fetchType => fetchType === FetchType.BOTH;

/**
 * Checks if series mode is hour
 *
 * @param {number} fetchType
 *
 * @returns {boolean}
 */
export const isFetchTypeHour = fetchType => fetchType === FetchType.HOUR;

/**
 * Checks if series mode is cumulative.
 *
 * @param {number} fetchType
 *
 * @returns {boolean}
 */
export const isFetchTypeCumulative = fetchType => fetchType === FetchType.CUMULATIVE;
