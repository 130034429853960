import _ from 'lodash';

import template from 'raw-loader!../templates/sidebar.html';
import { FACILITY_OVERVIEW } from '../modules/energy-reporting/constants/er-modal-states.constant';
import { ServiceLevel } from '@enerkey/clients/facility';

export default function() {
  return {
    restrict: 'E',
    replace: true,
    template: template,
    controller: controller
  };
}

controller.$inject = [
  '$scope', '$state', '$window', '$timeout', '$element', 'appStatusService',
  '$transitions', 'erReportSettingsService', 'serviceLevelService', '$attrs'
];

function controller(
  $scope, $state, $window, $timeout, $element, appStatusService,
  $transitions, erReportSettingsService, serviceLevelService, $attrs
) {
  let isFloating = false;
  let recreateState = getDefaultRecreateState();

  // sidebar config
  $scope.sidebarConfig = {
    initializing: false,
    disabled: false,
    collapsed: appStatusService.isMobileWidth,
    items: [],
    links: []
  };

  // default recreate state
  function getDefaultRecreateState() {
    return {
      executing: false,
      itemCount: 0
    };
  }

  // set items from current state
  function setSidebarItemsAndLinks() {
    // set init flag
    $scope.sidebarConfig.initializing = true;

    // items
    const newItems = getConfig('items');
    $scope.sidebarConfig.items = _.reduce(newItems, function(result, item) {
      return result.concat(_.find($scope.sidebarConfig.items, item) || item);
    }, []);

    // links
    const newLinks = getConfig('links');
    $scope.sidebarConfig.links = _.reduce(newLinks, function(result, link) {
      link.active = isLinkActive(link);
      return result.concat(_.find($scope.sidebarConfig.links, link) || link);
    }, []);

    // remove init flag
    if ($scope.sidebarConfig.initializing) {
      $timeout(function() {
        $scope.sidebarConfig.initializing = false;
      });
    }
  }

  function isLinkActive(link) {
    return _.isFunction(link.onClick) ? link.active : _.includes($state.current.name, link.state);
  }

  // It might be good idea to make this directive a component and get rid of $state stuff at some point
  function getConfig(type) {
    return $attrs[type]
      ? $scope.$eval($attrs[type])
      : _.get($state, `$current.data.sidebar.${type}`, [])
    ;
  }

  // listen state change success and start
  const stateChangeStartUnbind = $transitions.onStart({}, function(transition) {
    if (_.get(transition.options(), 'reload')) {
      // reloading view, items need to be recreated
      // --> set recreateState and handle it in stateChangeSuccess
      recreateState = {
        executing: true,
        itemCount: $scope.sidebarConfig.items.length
      };
      $scope.$evalAsync(function() {
        $scope.sidebarConfig.items = [];
      });
    }
  });

  const stateChangeSuccessUnbind = $transitions.onSuccess({}, function() {
    // handle recreate state by letting the digest loop clear the items
    // --> therefore delayed the item recreation
    validateSettingsForView();
    if (recreateState.executing) {
      $timeout(function() {
        recreateState = getDefaultRecreateState();
        setSidebarItemsAndLinks();
      });
    } else {
      setSidebarItemsAndLinks();
    }
  });

  // unbind on destroy
  $scope.$on('$destroy', function() {
    stateChangeStartUnbind();
    stateChangeSuccessUnbind();
  });

  $scope.toggleSidebar = function() {
    $scope.sidebarConfig.collapsed = !$scope.sidebarConfig.collapsed;
    // trigger resize if not floating, so that kendo redraws
    if (!$scope.isFloating()) {
      angular.element($window).trigger('resize');
    }
  };

  $scope.onClickElsewhere = function() {
    // if floating and not-collapsed --> do collapse
    if ($scope.isFloating() && !$scope.sidebarConfig.collapsed) {
      $scope.sidebarConfig.collapsed = true;
    }
  };

  $scope.isFloating = function() {
    const oldIsFloating = isFloating;
    isFloating = appStatusService.isMobileWidth;
    if (oldIsFloating !== isFloating) {
      angular.element($window).trigger('resize');
    }
    return isFloating;
  };

  $scope.isVisible = function() {
    return ($scope.sidebarConfig.items.length ||
      (recreateState.executing && recreateState.itemCount)) &&
      serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);
  };

  $scope.linkClicked = clickedLink => {
    if (_.isFunction(clickedLink.onClick)) {
      clickedLink.onClick(clickedLink.params);
      $scope.sidebarConfig.links.forEach(link => {
        link.active = link === clickedLink;
      });
    } else {
      const state = _.get(clickedLink, 'state');
      const stateParams = _.get(clickedLink, 'params') || $state.params;
      if ($state.get(state) && $state.current.name !== state) {
        $state.go(state, stateParams);
      }
    }
  };

  // initialize items and links
  setSidebarItemsAndLinks();

  const validateSettingsForView = function() {
    let isOverview = false;
    const erReportSettings = erReportSettingsService.getInstance();
    if (erReportSettings) {
      isOverview = erReportSettings.getSettings().name === FACILITY_OVERVIEW.name;
    }
    $scope.settingsDisabled = isOverview;
  };

  validateSettingsForView();

  $scope.$watch($attrs.items, () => {
    setSidebarItemsAndLinks();
    validateSettingsForView();
  });
}
