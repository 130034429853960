<kendo-grid
  [kendoGridBinding]="messages$ | async"
  [pageSize]="30"
  [pageable]="true"
  [kendoGridSelectBy]="selectKey"
  [selectable]="gridSelectableSettings"
  [selectedKeys]="selection"
  [sortable]="true"
  [resizable]="true"
  filterable="menu"
  class="grid-full-height"
>
  <ng-template kendoGridToolbarTemplate>
    <button
      kendoButton
      [primary]="true"
      (click)="retry()"
      [disabled]="!selection?.length"
    >
      {{ 'SUPERVISION.RETRY' | translate }}
    </button>
  </ng-template>
  <kendo-grid-checkbox-column [width]="26">
    <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
  </kendo-grid-checkbox-column>
  <kendo-grid-column
    field="timestamp"
    format="g"
    [title]="'SUPERVISION.TIMESTAMP' | translate"
    filter="date"
    [width]="120"
  ></kendo-grid-column>
  <kendo-grid-column
    field="id"
    [title]="'ID'"
    [filterable]="false"
    [width]="160"
  ></kendo-grid-column>
  <kendo-grid-column
    field="status"
    [title]="'SUPERVISION.STATUS' | translate"
    [filterable]="false"
    [width]="100"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem="dataItem"
    >
      {{ dataItem.status | requestStatusName | translate }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="webhookId"
    [title]="'SUPERVISION.WEBHOOK_ID' | translate"
    [width]="160"
  ></kendo-grid-column>
  <kendo-grid-column
    field="message"
    [title]="'SUPERVISION.MESSAGE' | translate"
  ></kendo-grid-column>
  <kendo-grid-command-column [width]="60">
    <ng-template
      kendoGridCellTemplate
      let-dataItem="dataItem"
    >
      <button
        class="button button--link"
        (click)="downloadContent(dataItem)"
      >{{ 'PROVISIONING.DOWNLOAD' | translate }}</button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
