import EnerKeyServices from '../../../services';
import DocumentsModels from '../../documents/models';
import EnergyReportingServices from '../services';
import ReportingObjectsServices from '../../reportingobjects/services';
import EnergyReportingModels from '../models';
import EnergyManagementModels from '../../energy-management/models';
import ConfigurationModels from '../../configuration/models';

import FacilitiesController from './facilities-controller';
import FacilitiesGridDesktopController from './facilities-grid-desktop-controller';
import FacilitiesReportComparisonController from './facilities-report-comparison-controller';
import FacilitiesReportSumController from './facilities-report-sum-controller';
import FacilitiesReportTypeController from './facilities-report-type-controller';
import FacilitiesSidebarAdditionalSettingsController from './facilities-sidebar-additional-settings-controller';
import FacilitiesSidebarMetertreeController from './facilities-sidebar-metertree-controller';
import FacilitiesSidebarPresentationController from './facilities-sidebar-presentation-controller';
import FacilitiesSidebarQuantitiesController from './facilities-sidebar-quantities-controller';
import MeterDetailsListController from './meter-details-list-controller';
import MeterDetailsListItemController from './meter-details-list-item-controller';
import ReportComponentAdapterController from './report-component-adapter.controller';
import FacilitiesSidebarTimePeriodController from './facilities-sidebar-time-period.controller';

export default angular.module('EnergyReporting.Controllers', [
  EnerKeyServices.name,
  DocumentsModels.name,
  EnergyReportingServices.name,
  ReportingObjectsServices.name,
  EnergyReportingModels.name,
  EnergyManagementModels.name,
  ConfigurationModels.name
])
  .controller('FacilitiesController', FacilitiesController)
  .controller('FacilitiesGridDesktopController', FacilitiesGridDesktopController)
  .controller('FacilitiesReportComparisonController', FacilitiesReportComparisonController)
  .controller('FacilitiesReportSumController', FacilitiesReportSumController)
  .controller('FacilitiesReportTypeController', FacilitiesReportTypeController)
  .controller('FacilitiesSidebarAdditionalSettingsController', FacilitiesSidebarAdditionalSettingsController)
  .controller('FacilitiesSidebarMetertreeController', FacilitiesSidebarMetertreeController)
  .controller('FacilitiesSidebarPresentationController', FacilitiesSidebarPresentationController)
  .controller('FacilitiesSidebarQuantitiesController', FacilitiesSidebarQuantitiesController)
  .controller('FacilitiesSidebarTimePeriodController', FacilitiesSidebarTimePeriodController)
  .controller('MeterDetailsListController', MeterDetailsListController)
  .controller('MeterDetailsListItemController', MeterDetailsListItemController)
  .controller('ReportComponentAdapterController', ReportComponentAdapterController)
;
