import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ISODuration, isoDurationTranslations } from '../isoduration';

@Pipe({ name: 'isoDurationName' })
export class IsoDurationNamePipe implements PipeTransform {

  public constructor(
    private readonly translateService: TranslateService
  ) { }

  public transform(value: ISODuration): string {
    return value in isoDurationTranslations
      ? this.translateService.instant(isoDurationTranslations[value])
      : '-';
  }

}
