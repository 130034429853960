import { mqaStates } from '../../manual-qa.states';

const $inject = ['utils'];

class ManualQaTabsService {
  constructor(utils) {
    this.utils = utils;
  }

  getTabItems() {
    return mqaStates.reduce((result, state) => {
      if (state.redirectTo) { // Don't include states that redirect
        return result;
      }

      return { ...result, [state.name]: { ...state, title: this.utils.localizedString(state.title) } };
    }, {});
  }
}

ManualQaTabsService.$inject = $inject;

export default ManualQaTabsService;
