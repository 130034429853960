// Use separate js file because of missing typings for KendoLicensing
// Can be changed to typescript when https://github.com/telerik/kendo-ui-core/issues/6850 is fixed

// eslint-disable-next-line no-undef
KendoLicensing.setScriptKey(
  '141j044b041h541j4i1d542e581e4i1i4g260i2k0f2e082607290727072d' +
    '16215d46011e4k234f1i4k2j5b2d5f2b4h1k532e5k4b5a335c365k3b603b' +
    '5j3b5h424i2h123d551g4c24511g572f562g614958315e3861395j396039' +
    '5f404g2f4g395108520d2i0e2h082e105836570j2k0a293i2a141c105739' +
    '4h064h3k28034i412c434f422d102f16254g1i4a512544013e1428024f2h' +
    '48124909263b600c315700265g354i2h'
);
