<kendo-dropdownlist
  [data]="groupedItems || items"
  [(ngModel)]="value"
  (ngModelChange)="valueChange($event)"
  (blur)="blur()"
  [valuePrimitive]="true"
  [textField]="'text'"
  [valueField]="'value'"
  [defaultItem]="showDefaultItem ? defaultItem : undefined"
  [loading]="loading"
  [disabled]="disabled || (disableWhenLoading && loading)"
  [virtual]="virtual"
  [popupSettings]="{ animate: false }"
  [itemDisabled]="isItemDisabled"
>
  <ng-template
    kendoDropDownListGroupTemplate
    kendoDropDownListFixedGroupTemplate
    let-groupName
  >
    <strong>
      {{ groupName }}
    </strong>
  </ng-template>

  <ng-template
    kendoDropDownListItemTemplate
    kendoDropDownListValueTemplate
    let-dataItem
  >
    <ng-container *ngIf="dataItem">
      <ng-container *ngTemplateOutlet="contentTemplate?.ref || itemTemplate; context: { $implicit: dataItem }"></ng-container>
    </ng-container>
  </ng-template>
</kendo-dropdownlist>

<ng-template
  #itemTemplate
  let-dataItem
>
  <span
    *ngIf="dataItem?.text"
    class="noselect"
  >
    {{ noTranslate ? dataItem.text : (dataItem.text | translate) }}
  </span>
</ng-template>
