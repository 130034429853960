import { ProvisioningComponent } from './provisioning/provisioning.component';
import { Roles } from '../admin/constants/roles';
import { EnerkeyNg2StateDeclaration, RouteAuthLogic } from '../../shared/routing';

export const provisioningState: EnerkeyNg2StateDeclaration = {
  name: 'supervision.provisioning',
  url: '/provisioning',
  data: {
    translationKey: 'PROVISIONING.PROVISIONING',
    auth: {
      roleLogic: RouteAuthLogic.Any,
      roles: [
        Roles.PROVISIONING_MANAGER,
        Roles.TENANT_MANAGER,
      ],
    }
  },
  views: {
    'supervision@supervision': {
      component: ProvisioningComponent
    }
  }
};
