const $inject = ['utils'];

class EmInputDateController {
  constructor(utils) {
    this.utils = utils;
    this.touched = false;

  }

  $onInit() {
    this.model = this.config.initialValue;
    this.placeholder = this.config.initialValueVaries ?
      this.utils.localizedString('ACTIONS.MASS_EDIT.VARIES') : '';
    this.config.isValid = true;
  }

  onInputChange() {
    this.touch();
    this.onChange(this.config, this.model);
  }

  onClose() {
    this.touch();
  }

  touch() {
    this.touched = true;
    this.placeholder = '';
    this.config.isValid = !(this.config.mandatory && !this.model);
  }
}

EmInputDateController.$inject = $inject;

export default EmInputDateController;
