import { Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { FacilityService } from '../../../shared/services/facility.service';
import { ReportEventService } from '../services/report-events.service';
import { ReportModalMetersService } from '../services/report-modal-meters.service';
import { ReportingSearchService } from '../services/reporting-search.service';
import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { ReportBase } from './report-base';

@Directive()
export abstract class MeterReportBase extends ReportBase {
  public override readonly pageOptions$: Observable<number>;
  public readonly selectedMeters$: Observable<ReportingMeterSelection>;
  public readonly debouncedSelectedMeters$: Observable<ReportingMeterSelection>;

  public constructor(
    reportModalMetersService: ReportModalMetersService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportEventService: ReportEventService
  ) {
    super(facilityService, reportingSearchService, reportEventService);

    this.selectedMeters$ = reportModalMetersService.selectedMeters$;
    this.debouncedSelectedMeters$ = this.selectedMeters$.pipe(
      debounceTime(100)
    );

    this.pageOptions$ = this.selectedMeters$.pipe(
      map(meters => Math.ceil(meters.meters.length / this.pageSize))
    );
  }
}
