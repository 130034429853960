import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SeriesMarkers } from '@progress/kendo-angular-charts';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF, fit, geometry, Group } from '@progress/kendo-drawing';
import saveAs from 'file-saver';

import { ReportSummary, Roadmap, Scope } from '@enerkey/clients/sustainability';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { getStringEnumValues } from '@enerkey/ts-utils';

import { GriScopeColorPipe } from '../../pipes/gri-scope-color.pipe';

type ScatterPointModel = {
  description: string;
  scope: Scope;
  color: string;
  year: Date;
  value: number;
}[];

type ProgressDataModel = {
  name: string;
  year: number;
  values: Record<Scope, number>;
  reductions: Record<Scope, number>;
  totalValue: number;
  totalReduction: number;
}[];

@Component({
  selector: 'target-roadmap-modal',
  templateUrl: './target-roadmap-modal.component.html',
  styleUrls: ['./target-roadmap-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GriScopeColorPipe]
})
@ModalOptions({
  size: 'large'
})
export class TargetRoadmapModalComponent extends ModalBase implements OnInit {

  public roadmap: Roadmap;
  public title: string;
  public reductions: { current: Record<Scope, number>, target: Record<Scope, number> };

  public roadmapLine: { scope: Scope, color: string, data: ScatterPointModel }[];
  public currentPoints: { data: ScatterPointModel, markers: SeriesMarkers }[];
  public progressPoints: { data: ScatterPointModel, markers: SeriesMarkers }[];
  public chartMin: Date;
  public chartMax: Date;

  public progressData: ProgressDataModel = [];

  public readonly scopes = getStringEnumValues(Scope);
  public readonly targetValueFormat = '#,# tCO2e';
  public readonly percentageFormat = '+# %;-# %;-# %';

  @ViewChild(PDFExportComponent) private readonly pdfExportComponent: PDFExportComponent;

  public constructor(
    currentModal: NgfActiveModal,
    private readonly scopeColorPipe: GriScopeColorPipe,
    private readonly translateService: TranslateService
  ) {
    super(currentModal);
  }

  public ngOnInit(): void {
    this.title = `${this.roadmap.name} (${this.roadmap.baseYear}-${this.roadmap.targetYear})`;

    this.reductions = {
      current: this.scopes.toRecord(
        scope => scope,
        scope => this.countReduction(this.roadmap.currentValues[scope], this.roadmap.baseValues[scope])
      ),
      target: this.scopes.toRecord(
        scope => scope,
        scope => this.countReduction(this.roadmap.targetValues[scope], this.roadmap.baseValues[scope])
      )
    };

    this.roadmapLine = this.scopes.map(scope => ({
      scope: scope,
      color: this.scopeColorPipe.transform(scope),
      data: [
        {
          // eslint-disable-next-line max-len
          description: `${this.translateService.instant('SUSTAINABILITY.TARGETS.BASE_VALUE')} (${this.roadmap.baseYear})`,
          scope: scope,
          color: this.scopeColorPipe.transform(scope),
          year: new Date(this.roadmap.baseYear, 0),
          value: this.roadmap.baseValues[scope]
        },
        {
          description: `${this.translateService.instant('SUSTAINABILITY.TARGETS.TARGET')} (${this.roadmap.targetYear})`,
          scope: scope,
          color: this.scopeColorPipe.transform(scope),
          year: new Date(this.roadmap.targetYear, 0),
          value: this.roadmap.targetValues[scope]
        },
      ]
    }));

    this.currentPoints = this.scopes.map(scope => ({
      data: [{
        description: `${this.translateService.instant('SUSTAINABILITY.TARGETS.CURRENT')} (${this.roadmap.currentYear})`,
        scope: scope,
        color: this.scopeColorPipe.transform(scope),
        year: new Date(this.roadmap.currentYear, 0),
        value: this.roadmap.currentValues[scope],
      }],
      markers: { background: this.scopeColorPipe.transform(scope), size: 9, border: { color: '#000' } }
    }));

    this.chartMin = new Date(this.roadmap.baseYear - 1, 11);
    this.chartMax = new Date(this.roadmap.targetYear, 1);
  }

  public showProgress(reports: ReportSummary[]): void {
    this.progressData = [];
    reports.sortBy('year').map(report => {
      this.progressData.push({
        name: report.description,
        year: report.year,
        values: this.scopes.toRecord(scope => scope, scope => report.emissions[scope] ?? null),
        reductions: this.scopes.toRecord(
          scope => scope,
          scope => this.countReduction(report.emissions[scope], this.roadmap.baseValues[scope])
        ),
        totalValue: this.countTotal(report.emissions),
        totalReduction: this.countReduction(this.countTotal(report.emissions), this.roadmap.baseTotal)
      });
    });

    this.progressPoints = [];
    this.progressPoints = this.scopes.map(scope => ({
      data: reports.map(report => ({
        description: report.description ? `${report.description} (${report.year})` : `${report.year}`,
        scope: scope,
        color: this.scopeColorPipe.transform(scope),
        year: new Date(report.year, 0),
        value: report.emissions[scope] ?? null
      })),
      markers: { background: this.scopeColorPipe.transform(scope) }
    }));
  }

  public downloadPdf(): void {
    const fileName = `${this.title}.pdf`;
    const page = new geometry.Rect([0, 0], [mm(297 - 20), mm(210 - 20)]);
    const content = new Group();
    this.pdfExportComponent.export()
      .then(component => {
        content.append(component);
        // eslint-disable-next-line jasmine/no-focused-tests
        fit(content, page);
        return exportPDF(content, {
          paperSize: 'A4',
          margin: '1cm',
          landscape: true,
        });
      })
      .then(dataURI => saveAs(dataURI, fileName));
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private countTotal(emissions: { [key: string]: number }): number {
    const values = Object.values(emissions);
    if (!emissions || !values.length) {
      return null;
    }
    return values.reduce((a, b) => a + b);
  }

  private countReduction(current: number, base: number): number {
    return Number.isFinite(current) && Number.isFinite(base)
      ? (current - base) / base
      : null;
  }
}

/** Convert millimeters to points */
function mm(val: number): number {
  return val * 2.8347;
}
