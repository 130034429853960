import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { groupBy, GroupResult } from '@progress/kendo-data-query';

export enum Period {
  Month = 3,
  Quarter = 4,
  Year = 5
}

type Data = {
  category?: string;
  value: string;
}

@Component({
  selector: 'dateperiod-select',
  templateUrl: './dateperiod-select.component.html',
  styleUrls: ['./dateperiod-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateperiodSelectComponent),
    multi: true
  }]
})
export class DateperiodSelectComponent implements ControlValueAccessor, OnChanges {
  @Input() public period = Period.Year;
  @Input() public minYear: number = 1900;
  @Input() public maxYear: number = 2100;

  public groupedData: GroupResult[] = [];
  public data: Data[] = [];
  public value: string = undefined;
  public disabled: boolean = false;

  private _original: Data[] = [];
  private _onChange: (value: string) => void;

  public constructor() {
    this.generateData();
  }

  public ngOnChanges(): void {
    this.generateData();
  }

  public handleFilter(value: string): void {
    let filtered = [];
    filtered = this._original.filter(
      data => data.value.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    if (this.period === Period.Year) {
      this.data = filtered;
    } else {
      this.groupedData = groupBy(filtered as Array<any>, [{ field: 'category' }]);
    }
  }

  public valueChange(value: string): void {
    this._onChange?.(value);
  }

  public writeValue(value: string): void {
    if (value !== undefined) {
      this.value = value;
    }
  }

  public registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void {}

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private generateData(): void {
    this.data.clear();
    this._original.clear();
    if (this.period === Period.Year) {
      this.getYearPeriodData();
    }
    if (this.period === Period.Quarter) {
      this.getQuarterPeriodData();
    }
    if (this.period === Period.Month) {
      this.getMonthPeriodData();
    }
  }

  private getYearPeriodData(): void {
    const data = [];
    for (let y = this.minYear; y <= this.maxYear; y++) {
      data.push({ value: y.toString() });
    }
    this._original = data;
    this.data = data;
  }

  private getQuarterPeriodData(): void {
    const data = [];
    for (let y = this.minYear; y <= this.maxYear; y++) {
      for (let q = 1; q <= 4; q++) {
        data.push({ category: y.toString(), value: `Q${q.toString()}/${y.toString()}` });
      }
    }
    this._original = data;
    this.groupedData = groupBy(data as Array<any>, [{ field: 'category' }]);
  }

  private getMonthPeriodData(): void {
    const data = [];
    for (let y = this.minYear; y <= this.maxYear; y++) {
      for (let m = 1; m <= 12; m++) {
        data.push({ category: y.toString(), value: `${y.toString()}/${m.toString().padStart(2, '0')}` });
      }
    }
    this._original = data;
    this.groupedData = groupBy(data as Array<any>, [{ field: 'category' }]);
  }
}
