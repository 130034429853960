import moment from 'moment';
import templateModule from 'raw-loader!../templates/no-comparison-presets-template.html';

noComparisonPresets.$inject = ['$rootScope', 'timeSeries', 'utils', '_'];

function noComparisonPresets($rootScope, timeSeries, utils, _) {

  return {
    restrict: 'E',
    scope: {
      options: '='
    },
    template: templateModule,
    controller: ['$scope',  function($scope) {
      $scope.options = $scope.options || {};
      $scope.presets = [];
      var configs = {};

      var getResolutionConf = function() {
        var timeFrameConf = configs.timeFrame || { Resolutions: [] };
        var resolutionConf = _.find(timeFrameConf.Resolutions, { Abbr: $scope.options.resolution });
        if (!resolutionConf) {
          resolutionConf = _.find(timeFrameConf.Resolutions, { Default: true });
        }
        if (!resolutionConf && timeFrameConf.Resolutions.length) {
          resolutionConf = timeFrameConf.Resolutions[0];
        }
        return resolutionConf;
      };

      var createPresets = function() {
        configs = {};
        var newPresets = [];

        timeSeries.getTimeSeries()
          .then(function(config) {
            var timeFrameConf = _.find(config, { Abbr: '' });
            if (timeFrameConf) {
              var defaultStart = timeFrameConf.DefaultStart;
              configs.timeFrame = timeFrameConf;
              configs.resolution = getResolutionConf();
              if (configs.resolution) {
                _.each(defaultStart, function(start) {
                  var from = utils.utcDateToDate(start);
                  var to = timeSeries.getDefaultFromToEndDate();
                  var isoDuration = utils.isoDuration(from, to, configs.resolution.Abbr);
                  var timeFrameObject = utils.durationToObject(from, isoDuration);
                  newPresets.push({
                    from: from,
                    timeFrameObject: timeFrameObject,
                    years: Math.round(moment.duration(to.getTime() - from.getTime()).asYears())
                  });
                });
              }
            }
          })
          .finally(function() {
            $scope.presets = newPresets;
            handleSelection();
          })
        ;
      };

      var handleSelection = function() {
        if ($scope.options.from && $scope.options.to) {
          _.each($scope.presets, function(preset) {
            preset.active = _.isEqual(preset.timeFrameObject.from, $scope.options.from) && _.isEqual(preset.timeFrameObject.to, $scope.options.to);
          });
        }
      };

      $scope.onPresetChanged = function(preset) {
        handleSelection();
        if (typeof $scope.options.onChange === 'function') {
          $scope.options.onChange(preset);
        }
      };

      $scope.$watchGroup(['options.resolution', 'options.from', 'options.to'], function(n, o) {
        var currentResolution = configs.resolution ? configs.resolution.Abbr : undefined;
        if (n[0] !== currentResolution) {
          // if resolution changes, create presets again
          createPresets();
        } else if ((n[1] && n[1] !== o[1]) || (n[2] && n[2] !== o[2])) {
          // if from or to changes, handle selection
          handleSelection();
        }
      });

      // initialize by creating presets once timeseries is initialized
      timeSeries.initialized()
        .then(function() {
          if (!configs.resolution) {
            createPresets();
          }
        })
      ;

      var timeSeriesTypeChangedUnbind = $rootScope.$on('timeSeries.typeChanged', function(event, type) {
        $scope.presets = [];
        createPresets();
      });

      $scope.$on('$destroy', function() {
        timeSeriesTypeChangedUnbind();
      });
    }]
  };

}

export default noComparisonPresets;
