import { AnalyticStatus, AnalyticsType, State, StateReason } from '@enerkey/clients/ines';

export const analyticsTypeTranslations: Readonly<Record<AnalyticsType, string>> = {
  [AnalyticsType.Ventilation]: 'ANALYTICS.ANALYTICSTYPE.VENTILATION',
  [AnalyticsType.HeatBalance]: 'ANALYTICS.ANALYTICSTYPE.HEATBALANCE',
  [AnalyticsType.HeatingPower]: 'ANALYTICS.ANALYTICSTYPE.HEATINGPOWER',
  [AnalyticsType.ElectricityDemandResponse]: 'ANALYTICS.ANALYTICSTYPE.ELECTRICITYDEMANDRESPONSE',
  [AnalyticsType.SolarPower]: 'ANALYTICS.ANALYTICSTYPE.SOLAR_POWER',
  [AnalyticsType.HeatingOptimization]: 'ANALYTICS.ANALYTICSTYPE.HEATINGOPTIMIZATION',
  [AnalyticsType.HeatingCooling]: 'ANALYTICS.ANALYTICSTYPE.HEATINGCOOLING'
};

export const analyticStatusTranslations: Readonly<Record<AnalyticStatus, string>> = {
  [AnalyticStatus.InvestigationDone]: 'ANALYTICS.VSEM.ANALYTICSTATUS.INVESTIGATIONDONE',
  [AnalyticStatus.InvestigationRequired]: 'ANALYTICS.VSEM.ANALYTICSTATUS.INVESTIGATIONREQUIRED',
  [AnalyticStatus.NoInvestigationRequired]: 'ANALYTICS.VSEM.ANALYTICSTATUS.NOINVESTIGATIONREQUIRED',
  [AnalyticStatus.UnderInvestigation]: 'ANALYTICS.VSEM.ANALYTICSTATUS.UNDERINVESTIGATION',
};

export const analyticStateTranslations: Readonly<Record<State, string>> = {
  [State.DataError]: 'ANALYTICS.VSEM.ANALYTICSTATE.DATAERROR',
  [State.InfrastructureError]: 'ANALYTICS.VSEM.ANALYTICSTATE.INFRASTRUCTUREERROR',
  [State.PartialSuccess]: 'ANALYTICS.VSEM.ANALYTICSTATE.PARTIALSUCCESS',
  [State.Succeeded]: 'ANALYTICS.VSEM.ANALYTICSTATE.SUCCEEDED',
  [State.Unknown]: 'ANALYTICS.VSEM.ANALYTICSTATE.UNKNOWN',
  [State.Uncertain]: 'ANALYTICS.INES.ERRORS.UNCERTAIN',
};

export const stateReasonTranslations: Readonly<Record<StateReason, string>> = {
  [StateReason.ApiException]: 'ANALYTICS.INES.ERRORS.APIEXCEPTION',
  [StateReason.MissingArea]: 'ANALYTICS.INES.ERRORS.MISSINGAREA',
  [StateReason.MissingAutomaticHeatingMeter]: 'ANALYTICS.INES.ERRORS.MISSINGAUTOMATICHEATINGMETER',
  [StateReason.MissingBuildingType]: 'ANALYTICS.INES.ERRORS.MISSINGBUILDINGTYPE',
  [StateReason.MissingData]: 'ANALYTICS.INES.ERRORS.MISSINGDATA',
  [StateReason.MissingHeatingConsumptions]: 'ANALYTICS.INES.ERRORS.MISSINGHEATINGCONSUMPTIONS',
  [StateReason.MissingVolume]: 'ANALYTICS.INES.ERRORS.MISSINGVOLUME',
  [StateReason.SerializationError]: 'ANALYTICS.INES.ERRORS.SERIALIZATIONERROR',
  [StateReason.Success]: 'ANALYTICS.INES.ERRORS.SUCCESS',
  [StateReason.Uncertain]: 'ANALYTICS.INES.ERRORS.UNCERTAIN',
  [StateReason.UnsupportedBuildingType]: 'ANALYTICS.INES.ERRORS.UNSUPPORTEDBUILDINGTYPE',
  [StateReason.InsufficientData]: 'ANALYTICS.INES.ERRORS.INSUFFICIENTDATA',
  [StateReason.PositiveTemperatureCorrelation]: 'ANALYTICS.INES.ERRORS.POSITIVETEMPERATURECORRELATION',
  [StateReason.TooMuchZeroConsumptions]: 'ANALYTICS.INES.ERRORS.TOOMUCHZEROCONSUMPTIONS',
  [StateReason.Unauthorized]: 'ANALYTICS.INES.ERRORS.UNAUTHORIZED',
  [StateReason.MissingRoofArea]: 'ANALYTICS.INES.ERRORS.MISSINGROOFAREA',
  [StateReason.BadQualityData]: 'ANALYTICS.INES.ERRORS.BADQUALITYDATA',
  [StateReason.MissingElectricityMeter]: 'ANALYTICS.INES.ERRORS.MISSINGELECTRICITYMETER',
  [StateReason.MissingMeter]: 'ANALYTICS.INES.ERRORS.MISSINGMETER',
};
