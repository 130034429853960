import { getFlagField } from './er-field.functions';
import { getSortNumber } from './er-data-grid-sorter';
import EnergyReportingConstants from '../../constants/report-constants';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';

const CONSUMPTION_TARGETS = {
  TARGET_A: 'TargetA',
  TARGET_B: 'TargetB',
  BASE_LEVEL: 'BaseLevel',
  PLANNED_LEVEL: 'PlannedLevel',
  ACTIONS_BASED: 'ActionsBased'
};

const $inject = ['ERDataColumn', 'ERDataFunctions'];

// This service is only for comsumption target columns in energy reporting grids
// Changing this shouldn't affect anything else
class ERConsumptionTargetColumn {
  constructor(ERDataColumn, ERDataFunctions) {
    this.ERDataColumn = ERDataColumn;
    this.ERDataFunctions = ERDataFunctions;
  }

  getColumns(options, data) {
    const apiIndex = data.apiIndex;
    const readingColumns = [];
    const compareColumns = [];
    const controlPeriodIndex = this.ERDataFunctions.getControlPeriodIndex(options);
    options.series.Start.forEach((start, startIndex) => {
      if (!options.series.DistributionId || data.normalized || options.show.distributionAndValue) {
        if (this.isTimeIndependentTarget(options, apiIndex)) {
          if (startIndex === controlPeriodIndex) {
            readingColumns.push(this.getColumn(options, data, apiIndex, controlPeriodIndex));
          }
        } else {
          readingColumns.push(this.getColumn(options, data, apiIndex, startIndex));
        }
        compareColumns.push(...this.getCompareColumns(options, data, apiIndex, startIndex));
      }
    });

    return { readingColumns, compareColumns };
  }

  getColumn(options, data, apiIndex, startIndex) {
    const field = this.getField(data, apiIndex, startIndex);
    const getTemplate = this.getTemplate;
    const startConfig = this.getStartConfig(options, data, startIndex);

    const column = {
      field: field,
      sort: getSortNumber(EnergyReportingConstants.ColumnTypeOrder.ReadingColumn, apiIndex),
      sortDate: startConfig.value,
      title: this.getTitle(options, apiIndex),
      headerTemplate: this.getHeaderTemplate(options, apiIndex, startConfig),
      width: EnergyReportingConstants.Dimensions.gridColumn.value[data.wideColumns ? 'wide' : 'narrow'],
      attributes: {
        class: 'cell-number'
      },
      template: row => getTemplate(row, field, data.format)
    };

    this.ERDataFunctions.addAggregates(options, data, column, data.format);

    return column;
  }

  getField(data, apiIndex, startIndex) {
    const quantityId = data.isAggregatedQuantityGrid ? '' : `$${data.quantityId}`;
    return `${quantityId}$${apiIndex}$${startIndex}$Reading$Value`;
  }

  getTitle(options, apiIndex) {
    const consumptionTargetType = this.ERDataFunctions.getTargetSeriesType(options, {}, apiIndex);
    return consumptionTargetType.Name;
  }

  getTemplate(row, field, format) {
    const value = row[field];
    return value === null ? '' : kendo.toString(value, format);
  }

  getHeaderTemplate(options, apiIndex, startConfig) {
    let template = null;
    if (!options.isWidget) {
      const typeColor = this.getTypeColor(options, apiIndex);
      const title = this.getTitle(options, apiIndex);
      const color = typeColor ? typeColor : startConfig.color;
      const square = this.ERDataColumn.getColorSquare(color);

      template = `${square} ${title}`;
    }

    return template;
  }

  getCompareColumns(options, data, apiIndex, startIndex) {
    const columns = [];
    const startConfig = this.getStartConfig(options, data, startIndex);
    const dataForStart = this.ERDataFunctions.getDataForStart(options, data, startIndex);
    const isCostTarget = this.ERDataFunctions.isCostTarget(
      this.ERDataFunctions.getTargetSeriesType(options, data, data.apiIndex)
    );

    const shouldShow = !isCostTarget ||
      (options.series.RelationalUnitIds && options.series.RelationalUnitIds.includes(RelationalValueId.Costs));
    const fields = [
      {
        key: 'Percent',
        enabled: shouldShow && options.params.changeType === 'both' || options.params.changeType === 'relative'
      },
      {
        key: 'Change',
        enabled: shouldShow && options.params.changeType === 'both' || options.params.changeType === 'absolute'
      }
    ];

    const width = EnergyReportingConstants.Dimensions.gridColumn.compare[data.wideColumns ? 'wide' : 'narrow'];
    fields.forEach(columnConfig => {
      if (columnConfig.enabled) {
        const isPercent = columnConfig.key === 'Percent';
        const field = this.getCompareField(data, apiIndex, startIndex, isPercent);

        const format = isPercent ? 'p1' : data.format;
        const getCompareTemplate = this.getCompareTemplate;
        const column = {
          field: field,
          title: this.getCompareTooltip(options, apiIndex, startConfig, isPercent),
          sort: getSortNumber(EnergyReportingConstants.ColumnTypeOrder.CompareColumn, apiIndex, 0, 1),
          sortDate: startConfig.value,
          headerTemplate: this.getCompareHeaderTemplate(options, apiIndex, startConfig, isPercent),
          template: row => getCompareTemplate(options, row, field, format),
          width: isPercent ? width + 10 : width,
          attributes: {
            class: 'cell-number'
          },
          format: `{0:${format}}`,
          serie: isCostTarget ?
            this.getCompareCostReadingField(data, startIndex) :
            this.getCompareReadingField(data, startIndex),
          previousSerie: this.getCompareTargetField(data, apiIndex, startIndex)
        };
        this.ERDataFunctions.addAggregates(options, dataForStart, column, format, {
          sum: this.ERDataFunctions.getComparisonColumnSumFunction(column, columnConfig.key)
        });
        columns.push(column);
      }
    });
    return columns;
  }

  getCompareField(data, apiIndex, startIndex, isPercent) {
    const quantityId = data.quantityId;
    const percent = isPercent ? 'Percent' : 'Change';
    const normalized = data.normalized ? 'Normalized' : '';
    return `$${quantityId}$${apiIndex}$${startIndex}$${normalized}Reading$Value${percent}`;
  }

  getCompareReadingField(data, startIndex) {
    const quantityId = data.quantityId;
    const normalized = data.normalized ? 'Normalized' : '';
    return `$${quantityId}$0$${startIndex}$${normalized}Reading$Value`;
  }

  getCompareCostReadingField(data, startIndex) {
    const quantityId = data.quantityId;
    return `$${quantityId}$0$${startIndex}$RelationalValues$${RelationalValueId.Costs}$Reading$Value`;
  }

  getCompareTargetField(data, apiIndex, startIndex) {
    const quantityId = data.quantityId;
    return `$${quantityId}$${apiIndex}$${startIndex}$Reading$Value`;
  }

  getCompareTemplate(options, row, field, format) {
    const value = row[field];
    const content = kendo.toString(value, format);
    const firstFlag = row[getFlagField(field)];
    let className = firstFlag ? `k-grid-cell-${firstFlag}` : '';

    if (options.isWidget && value) {
      className = `trend trend--${value >= 0 ? 'asc' : 'desc'}`;
    }

    return value === null ? '' : `<span class="${className}">${content}</span>`;
  }

  getCompareTooltip(options, apiIndex, startConfig, isPercent) {
    const target = this.getTitle(options, apiIndex);
    const start = startConfig.displayValue;
    const percent = isPercent ? ' %' : '';
    return `${target} ➜ ${start}${percent}`;
  }

  getCompareHeaderTemplate(options, apiIndex, startConfig, isPercent = false) {
    const target = this.getHeaderTemplate(options, apiIndex, startConfig);
    const start = this.ERDataColumn.getColorSquare(startConfig.color);
    const percent = isPercent ? ' %' : '';

    const header = `${target} ➜ ${start}${percent}`;
    const tooltipText = this.getCompareTooltip(options, apiIndex, startConfig, isPercent);

    return `<span
tooltip-append-to-body='true'
tooltip-placement='left'
tooltip='${tooltipText}'
>${header}</span>`;
  }

  getTypeColor(options, apiIndex) {
    const consumptionTargetType = this.ERDataFunctions.getTargetSeriesType(options, {}, apiIndex);
    return this.isTimeIndependentTarget(options, apiIndex) ||
      !options.show.allTargetSeriesPeriods ||
      options.series.Start.length <= 1 ?
      consumptionTargetType.Color :
      null;
  }

  isTimeIndependentTarget(options, apiIndex) {
    const targetId = this.ERDataFunctions.getTargetSeriesType(options, {}, apiIndex).Id;
    return targetId === CONSUMPTION_TARGETS.BASE_LEVEL ||
      targetId === CONSUMPTION_TARGETS.PLANNED_LEVEL;
  }

  getStartConfig(options, data, startIndex) {
    const start = options.series.Start[startIndex];
    return {
      index: startIndex,
      key: start.key,
      value: start.value,
      displayValue: start.valueStart,
      color: data.colors[startIndex]
    };
  }
}

ERConsumptionTargetColumn.$inject = $inject;

export default ERConsumptionTargetColumn;
