import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { LanguageService } from '../../services/language.service';
import { EkComboComponent } from '../ek-combo/ek-combo.component';

@Component({
  selector: 'language-select',
  templateUrl: '../ek-combo/ek-combo.component.html',
  styleUrls: ['../ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LanguageSelectComponent),
    multi: true
  }]
})
export class LanguageSelectComponent extends EkComboComponent<string> {
  public override items = LanguageService.locales.map(lang => ({
    value: lang,
    text: `SETTINGS.${lang.toUpperCase()}`
  }))
}
