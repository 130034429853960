import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DropDownFilterSettings, MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import * as dataQuery from '@progress/kendo-data-query';

import { ControlValueAccessorOf, SimpleChangesOf } from '@enerkey/ts-utils';
import { Quantities } from '@enerkey/clients/metering';

import { quantityGroupDefinitions, quantityTranslations } from '../../../constants/quantity.constant';

type QuantityOption = {
  readonly value: Quantities;
  readonly key: string;
  readonly group: string;
};

@Component({
  selector: 'quantity-multi-select',
  templateUrl: './quantity-multi-select.component.html',
  styleUrls: ['./quantity-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QuantityMultiSelectComponent),
    multi: true
  }],
})
export class QuantityMultiSelectComponent implements ControlValueAccessorOf<Quantities[]>, OnChanges {

  /** Possible values of the dropdown. */
  @Input() public quantities: Quantities[] = [];

  /** Whether to group the quantities (see `quantity.constant` for groups) */
  @Input() public grouped: boolean = false;

  /** Shows a spinner when true. Does not disable the dropdown. */
  @Input() public loading: boolean = false;

  @Input() public selectAllEnabled: boolean = true;
  @Input() public overflowThreshold: number = 3;

  public value: Quantities[] = [];
  public disabled: boolean = false;
  public options: QuantityOption[] | dataQuery.GroupResult[] = [];

  public readonly textField: keyof QuantityOption = 'key';
  public readonly valueField: keyof QuantityOption = 'value';

  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  @ViewChild(MultiSelectComponent)
  private readonly multiSelect: MultiSelectComponent;

  private _onChange: (value: Quantities[]) => void;
  private _onTouched: () => void;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) { }

  // ugly but works
  // eslint-disable-next-line no-invalid-this, @typescript-eslint/no-explicit-any
  public readonly tagMapper = (tags: any[]): any[] => tags.length <= this.overflowThreshold ? tags : [tags];

  /** Kendo callback */
  public valueChanged(value: Quantities[]): void {
    this._onChange?.(value);
  }

  /** Kendo callback */
  public blur(): void {
    this._onTouched?.();
  }

  /** ControlValueAccessor */
  public writeValue(value: Quantities[]): void {
    this.value = value ?? [];
    this.valueChanged(this.value);

    this.changeDetector.detectChanges();
  }

  /** ControlValueAccessor */
  public registerOnChange(fn: (value: Quantities[]) => void): void {
    this._onChange = fn;
  }

  /** ControlValueAccessor */
  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  /** ControlValueAccessor */
  public setDisabledState(isDisabled: boolean): void {
    if (!!this.disabled !== !!isDisabled) {
      this.disabled = isDisabled;
      this.changeDetector.detectChanges();
    }
  }

  public selectAll(): void {
    this.value = [...this.quantities];
    this._onChange?.(this.value);
    this.multiSelect.toggle(false);
  }

  public ngOnChanges(changes: SimpleChangesOf<QuantityMultiSelectComponent>): void {
    // Only do this if quantities or grouping-option changes
    if (changes.quantities || changes.grouped) {
      const result = (this.quantities ?? []).map<QuantityOption>(quantity => ({
        value: quantity,
        key: this.translateService.instant(quantityTranslations[quantity]), // done here for filtering to work
        group: this.getGroupName(quantity),
      }));

      this.options = this.grouped
        ? dataQuery.groupBy(result, [{ field: 'group' }])
        : result;
    }
  }

  private getGroupName(quantity: Quantities): string {
    return this.translateService.instant(
      quantityGroupDefinitions.find(g => g.quantityIds.includes(quantity))?.title
      ?? 'QUANTITY_GROUPS.UNDEFINED'
    );
  }
}
