import { Injectable } from '@angular/core';
import _ from 'lodash';
import { TinyColor } from '@ctrl/tinycolor';

import { Quantities } from '@enerkey/clients/metering';

import { RelationalValueId } from '../../modules/reportingobjects/constants/facilities-properties';

const _lightenPercent = 25;
const _darkenPercent = 25;

const relationalValueColors: Record<number, string> = {
  [RelationalValueId.GrossArea]: '#b24700',
  [RelationalValueId.grossVolumeId]: '#973689',
  [RelationalValueId.NetFloorArea]: '#e6b800',
  [RelationalValueId.TotalVolume]: '#d19166',
  [RelationalValueId.HeatedArea]: '#550b04',
  [RelationalValueId.HeatedVolume]: '#1f3d7a',
  [RelationalValueId.Inhabitants]: '#ff9900',
  [RelationalValueId.heatingMethodId]: '',
  [RelationalValueId.shareOfTemperatureFixingId]: '',
  [RelationalValueId.renovationYearId]: '',
  [RelationalValueId.Area]: '#29a329',
  [RelationalValueId.Volume]: '#009999',
  [RelationalValueId.StoreArea]: '#bea5be',
  [RelationalValueId.ProductionAmountPieces]: '#009999',
  [RelationalValueId.ProductionAmountKg]: '#99cc00',
  [RelationalValueId.totalAreaId]: '#b24700',
  [RelationalValueId.co2Factor]: '#550b04',
  [RelationalValueId.co2NationalReference]: '#973689',
  [RelationalValueId.Costs]: '#29a329',
  [RelationalValueId.RetailerCombinedCosts]: '#973689',
  [RelationalValueId.DistributionCombinedCosts]: '#550b04',
  [RelationalValueId.RetailerCosts]: '#8f9ebc',
  [RelationalValueId.RetailerMonthlyFees]: '#555555',
  [RelationalValueId.DistributionMonthlyFees]: '#b24700',
  [RelationalValueId.DistributionCosts]: '#ff9900',
  [RelationalValueId.NationalCosts]: '#99cc00',
  [RelationalValueId.NationalRetailerCosts]: '#bea5be',
  [RelationalValueId.NationalDistributionCosts]: '#d19166',
  [RelationalValueId.DistributionTaxes]: '#b24700',
  [RelationalValueId.MeterBasedAverageCost]: '#973689',
  [RelationalValueId.MeterBasedRetailerAverageCost]: '#550b04',
  [RelationalValueId.MeterBasedDistributionAverageCost]: '#ff9900'
};

@Injectable({ providedIn: 'root' })
export class ColorService {
  /**
   * Returns primary colors for graphs
   */
  public getPrimaryGraphColors(quantityId?: Quantities, count?: number): string[] {
    const quantityColor = this.getQuantityColor(quantityId);

    const colors = [
      quantityColor || '#000000',
      this.firstComparisonPeriodColor,
      this.secondComparisonPeriodColor
    ];

    return colors
      .splice(0, count || colors.length)
      .reverse();
  }

  /**
   * Returns main primary graph color
   */
  public getMainPrimaryGraphColor(quantityId?: Quantities): string {
    return _.last(this.getPrimaryGraphColors(quantityId));
  }

  /**
   * Returns alternative graph colors for graphs
   */
  public getAlternativeGraphColors(quantityId?: Quantities, count?: number): string[] {
    const quantityColor = this.getQuantityColor(quantityId);
    const baseColor = new TinyColor(quantityColor);
    let colors = [];

    switch (quantityId) {
      case Quantities.TotalEnergy:
      case Quantities.HeatingEnergy:
      case Quantities.Steam:
        colors = this.getDarkenedColors(baseColor); // Darken bright colors
        break;
      default:
        colors = this.getDefaultColors(baseColor);
        break;
    }

    return colors
      .splice(0, count || colors.length)
      .reverse();
  }

  /**
   * Returns lightened color depending on value / maxValue ratio
   */
  public getActionsImpactHighlightColor(colorStr: string, value: number, maxValue: number): string {
    if (!Number.isFinite(maxValue) || maxValue <= 0) {
      return '#ffffff';
    }

    const color = new TinyColor(colorStr);

    // Lightness multiplier which produces white
    const factor = (1 - color.toHsl().l) * - 100;

    return color.lighten((value / maxValue - 1) * factor).toHexString();
  }

  /**
   * Returns negative color darkened by given percentage
   */
  public getNegateAndDarkenColor(colorStr: string, percentage: number): string {
    const color = new TinyColor(colorStr);
    const inverted = new TinyColor({
      r: 255 - color.r,
      g: 255 - color.g,
      b: 255 - color.b,
    });
    return inverted.darken(percentage).toHexString();
  }

  public getQuantityColor(quantity: Quantities): string {
    return this.getCssProperty(`--enerkey-quantity-color-${quantity}`);
  }

  public getCssProperty(property: string, fallback = '#000000'): string {
    const styles = getComputedStyle(document.documentElement);
    const trimmedColor = styles.getPropertyValue(property).trim();
    return trimmedColor ? trimmedColor : fallback;
  }

  public get firstComparisonPeriodColor(): string {
    return this.getCssProperty('--enerkey-chart-first-comparison');
  }

  public get secondComparisonPeriodColor(): string {
    return this.getCssProperty('--enerkey-chart-second-comparison');
  }

  public colorsForRelationalValue(id?: number, count = 1): string[] {
    const colors = [];

    const baseColor = relationalValueColors[id] || '#000000';
    colors.push(baseColor);

    if (count > 1) {
      colors.splice(0, 0, this.shadeColor(relationalValueColors[id], -20));
    }
    if (count > 2) {
      colors.splice(0, 0, this.shadeColor(relationalValueColors[id], -40));
    }

    return colors;
  }

  public shadeColor(color: string, percent?: number): string {
    if (!color || !percent) {
      return color;
    }
    /*
      Source:
      http://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
    */
    /* eslint-disable no-bitwise, no-nested-ternary */
    const num = parseInt(color.slice(1), 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = (num >> 8 & 0x00FF) + amt;
    const B = (num & 0x0000FF) + amt;
    return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) *
      0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) *
      0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1)}`;
  }

  private isReallyDark(baseColor: TinyColor): boolean {
    return baseColor.getBrightness() < 5;
  }

  /**
   * Returns alternative colors that are darkened from base color.
   */
  private getDarkenedColors(baseColor: TinyColor): string[] {
    return [
      baseColor.toHexString(),
      baseColor.darken(_darkenPercent).toHexString(),
      baseColor.darken(2 * _darkenPercent).toHexString()
    ];
  }

  /**
   * Returns default alternative colors which are defined from base color.
   */
  private getDefaultColors(baseColor: TinyColor): string[] {
    const secondary = this.isReallyDark(baseColor)
      ? baseColor.lighten(_lightenPercent)
      : baseColor.darken(_darkenPercent);

    const tertiary = this.isReallyDark(baseColor)
      ? baseColor.lighten(2 * _lightenPercent)
      : baseColor.darken(2 * _darkenPercent);

    return [
      baseColor.toHexString(),
      secondary.toHexString(),
      tertiary.toHexString(),
    ];
  }
}
