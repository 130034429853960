import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiKeyInfo, UserManagementClient } from '@enerkey/clients/user-management';
import { ModalService, NgfActiveModal } from '@enerkey/foundation-angular';

import { ApiKeysComponent, ConfigurableApiKey } from '../api-keys/api-keys.component';
import { ToasterService } from '../../shared/services/toaster.service';
import { DialogService } from '../../shared/services/dialog.service';
import { TenantApiKeyCreateComponent } from '../tenant-api-key-create/tenant-api-key-create.component';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'tenant-api-keys',
  templateUrl: '../api-keys/api-keys.component.html',
  styleUrls: ['../api-keys/api-keys.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantApiKeysComponent extends ApiKeysComponent {
  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly authenticationService: AuthenticationService,
    currentModal: NgfActiveModal,
    toasterService: ToasterService,
    dialogService: DialogService,
    modalService: ModalService
  ) {
    super(toasterService, dialogService, modalService, currentModal);
  }

  public openCreateModal(keyType: ConfigurableApiKey, renew: boolean): void {
    const modal = this.modalService.open(TenantApiKeyCreateComponent);
    modal.componentInstance.activeKeyType = keyType;
    modal.componentInstance.renewMode = renew;
    modal.componentInstance.refresh$ = this._refreshApiKeys$;
  }

  public close(): void {
    super.closeModal();
  }

  protected getKeys(): Observable<ApiKeyInfo[]> {
    return this.userManagementClient.getTenantApiKeyInfo(
      this.authenticationService.tenantId
    );
  }

  protected deleteKey(key: ApiKeyInfo): Observable<unknown> {
    return this.userManagementClient.deleteTenantApiKey(
      this.authenticationService.tenantId,
      key.keyId
    );
  }
}
