const SearchTypes = {
  STORAGE_TYPE: {
    RAW: 3,
    DEFECT: 2
  },
  CONTENT_TYPE: {
    NO_FILTER: 0,
    HAS_MODEL: 1
  }
} as const;

export default SearchTypes;

export type StorageSearchType = typeof SearchTypes.STORAGE_TYPE[keyof typeof SearchTypes.STORAGE_TYPE];
