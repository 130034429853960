<modal-view-header [headingText]="terminalName | translate"></modal-view-header>
<div class="modal-body">

<textarea
  *labelBefore="'COMMENT' | translate"
  kendoTextArea
  type="text"
  [(ngModel)]="text"
  [readonly]="!allowEdit"
  [attr.rows]="10"
></textarea>
</div>
<div class="modal-footer"
  *ngIf="allowEdit">
  <div class="modal-footer-buttons float-right">
    <button type="button"
      class="button" 
      (click)="onSave()"
    >
      {{ "MODALS.SAVE" | translate }}
    </button>
  </div>
</div>
