import EnerkeyServiceModule                 from '../../../../services';
import ManualQaConsumptionInputController   from './manual-qa-consumption-input-modal-controller';
import ManualQaConsumptionInputModalService from './manual-qa-consumption-input-modal-service';

const dependencies = [EnerkeyServiceModule.name];

const ManualQaConsumptionInputModalModule = angular
  .module('app.modules.manual-qa.components.consumption-input-modal', dependencies)
  .service('ManualQaConsumptionInputModalService', ManualQaConsumptionInputModalService)
  .controller('ManualQaConsumptionInputController', ManualQaConsumptionInputController)
;

export default ManualQaConsumptionInputModalModule;
