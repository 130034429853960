<kendo-datepicker
  [value]="fromDate"
  [disabled]="loading"
  (valueChange)="onFromDateChange($event)"
></kendo-datepicker>
<span class="separator">-</span>
<span
  *ngIf="!toDate; then noEndDate; else endDatePicker"
></span>
<ng-template #noEndDate>
  <span>{{ 'ADMIN.VIRTUAL_METERS.EDITOR.IN_EFFECT_FOR_NOW' | translate }}</span>
  <button
    class="button button--link button--no-focus button--no-padding"
    (click)="onToDateChange(fromDate)"
  >
    {{ 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_END_DATE' | translate }}
  </button>
</ng-template>
<ng-template #endDatePicker>
  <kendo-datepicker
    [value]="toDate"
    [disabled]="loading"
    [min]="fromDate"
    (valueChange)="onToDateChange($event)"
  ></kendo-datepicker>
  <button
    class="button button--link button--no-focus button--no-padding"
    (click)="onToDateChange()"
  >{{ 'ADMIN.VIRTUAL_METERS.EDITOR.REMOVE_END_DATE' | translate }}</button>
</ng-template>
