import { Pipe, PipeTransform } from '@angular/core';

import { Terminal } from '@enerkey/clients/configuration-control';

@Pipe({ name: 'terminalMeterIds' })
export class TerminalMeterIdsPipe implements PipeTransform {
  public transform(terminal: Terminal): string {
    return terminal?.terminalMeters?.map(tm => tm.meterId).join(', ') ?? '';
  }
}
