resetChartOptions.$inject = ['utils', '_'];

function resetChartOptions(utils, _) {
  return {
    restrict: 'A',
    scope: false,
    link: function($scope, $element, $attrs) {
      var options = $scope.$eval($attrs.resetChartOptions);
      if (_.isObject(options) && _.has(options.object, options.property)) {
        utils.efficientWatch(options.property, options.object, function(options, oldOptions) {
          setTimeout(function() {
            if (JSON.stringify(options) !== JSON.stringify(oldOptions)) {
              var kendoChart = $element.data('kendoChart');
              if (kendoChart) {
                kendoChart.setOptions(options);
              }
            }
          });
        });
      }
    }
  };
}

export default resetChartOptions;