import { IAngularStatic } from 'angular';
import 'angular-recursion';
import 'angular-resource';
import 'angular-translate';
import '@uirouter/angular-hybrid';

import KendoModule from '../kendo';
import JQueryModule from '../jquery';
import LodashModule from '../lodash';
import MomentModule from '../moment';
import EnerKeyServiceModule from '../../services';
import EnerKeyComponentsModule from '../../components';

import MrcConstantsModule from './constants';
import MrcServicesModule from './services';
import MrcComponentsModule from './components';

import config from './config';
import { startFrom } from './filters/start-from';
import { filterYears } from './filters/filter-years';
import { mrcChangeMeterConfirm } from './controllers/mrc-change-meter-confirm';
import { mrcChangeMeter } from './controllers/mrc-change-meter';
import { mrcConsumptionCell } from './controllers/mrc-consumption-cell';
import { MrcConsumptionsController } from './controllers/mrc-consumptions-controller';
import { MrcController } from './controllers/mrc-controller';
import { mrcDatepicker } from './controllers/mrc-datepicker';
import { MrcFacilityInfoController } from './controllers/mrc-facility-info';
import { mrcFacility } from './controllers/mrc-facility';
import { mrcFactorInput } from './controllers/mrc-factor-input';
import { mrcMeterHistory } from './controllers/mrc-meter-history';
import { mrcMeterInfo } from './controllers/mrc-meter-info';
import { mrcMeterItem } from './controllers/mrc-meter-item';
import { mrcMeterReadings } from './controllers/mrc-meter-readings';
import { mrcMeter } from './controllers/mrc-meter';
import { mrcPagination } from './controllers/mrc-pagination';
import { mrcQuantity } from './controllers/mrc-quantity';
import { mrcReadingValidation } from './controllers/mrc-reading-validation';
import { MrcReadingsController } from './controllers/mrc-readings-controller';
import { MrcReadingsListingController } from './controllers/mrc-readings-listing-controller';
import { mrcSearchbar } from './controllers/mrc-searchbar';
import { mrcYearSelector } from './controllers/mrc-year-selector';

declare const angular: IAngularStatic;

export default angular.module('Mrc', [
  KendoModule.name,
  'kendo.directives',
  'pascalprecht.translate',
  'ui.router',
  JQueryModule.name,
  'RecursionHelper',
  LodashModule.name,
  MomentModule.name,
  EnerKeyServiceModule.name,
  EnerKeyComponentsModule.name,
  'ngResource',
  MrcServicesModule.name,
  MrcConstantsModule.name,
  MrcComponentsModule.name
])
  .filter('startFrom', startFrom)
  .filter('filterYears', filterYears)
  .directive('mrcChangeMeterConfirm', mrcChangeMeterConfirm)
  .directive('mrcChangeMeter', mrcChangeMeter)
  .directive('mrcConsumptionCell', mrcConsumptionCell)
  .controller('MrcConsumptionsController', MrcConsumptionsController)
  .controller('MrcController', MrcController)
  .directive('mrcDatepicker', mrcDatepicker)
  .controller('MrcFacilityInfoController', MrcFacilityInfoController)
  .directive('mrcFacility', mrcFacility)
  .directive('mrcFactorInput', mrcFactorInput)
  .directive('mrcMeterHistory', mrcMeterHistory)
  .directive('mrcMeterInfo', mrcMeterInfo)
  .directive('mrcMeterItem', mrcMeterItem)
  .directive('mrcMeterReadings', mrcMeterReadings)
  .directive('mrcMeter', mrcMeter)
  .directive('mrcPagination', mrcPagination)
  .directive('mrcQuantity', mrcQuantity)
  .directive('mrcReadingValidation', mrcReadingValidation)
  .controller('MrcReadingsController', MrcReadingsController)
  .controller('MrcReadingsListingController', MrcReadingsListingController)
  .directive('mrcSearchbar', mrcSearchbar)
  .directive('mrcYearSelector', mrcYearSelector)
  .config(config)
;
