import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { ReadingSourceType, SearchList } from '@enerkey/clients/manual-qa';

@Directive({
  selector: 'manual-qa-search'
})
export class ManualQaSearchDirective extends UpgradeComponent {
  @Input() public searchList: SearchList;
  @Input() public readerTypes: ReadingSourceType[];

  public constructor(elementRef: ElementRef, injector: Injector) {
    super('manualQaSearch', elementRef, injector);
  }
}
