import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { CreateProfileApiKeyModel, UserManagementClient } from '@enerkey/clients/user-management';
import { NgfActiveModal } from '@enerkey/foundation-angular';

import { UserService } from '../../services/user-service';
import { ClipboardService } from '../../shared/services/clipboard.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { ApiKeyCreate, ApiKeyCreateComponent } from '../api-key-create/api-key-create.component';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'profile-api-keys-create',
  templateUrl: '../api-key-create/api-key-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileApiKeysCreateModalComponent extends ApiKeyCreateComponent {
  public constructor(
    private readonly userService: UserService,
    private readonly userManagementClient: UserManagementClient,
    ngfActiveModal: NgfActiveModal,
    authenticationService: AuthenticationService,
    toasterService: ToasterService,
    clipboardService: ClipboardService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(ngfActiveModal, authenticationService, toasterService, clipboardService, changeDetectorRef);
  }

  protected createKey(values: ApiKeyCreate): Observable<string> {
    return this.userManagementClient.createProfileApiKey(new CreateProfileApiKeyModel({
      keyType: this.activeKeyType,
      from: new Date(),
      to: values.validTo,
      profileId: this.userService.profileId,
      emailAddress: values.email,
      description: values.description,
    }));
  }
}

