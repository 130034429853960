<ek-dropdown
  [showOnHover]="true"
  *ngIf="!isMobile; else mobileTemplate"
>
  <div
    *ekDropdownToggle
    class="topbar-icon-item"
  >
    <i class="fa fa-user"></i>
  </div>
  <ek-dropdown-heading>
    {{ userName }}
  </ek-dropdown-heading>
  <div>
    <div *ngIf="profileName$ | async as profileName; else loader">
      {{ profileName }}
    </div>
    <ng-template #loader>
      <div class="loading-container">
        <content-loader [size]="24"></content-loader>
      </div>
    </ng-template>
  </div>
  <hr />
  <ek-dropdown-item (click)="openProfileChangeModal()">
    <i class="fas fa-users"></i>
    {{ 'SETTINGS.CHANGE_PROFILE' | translate }}
  </ek-dropdown-item>
  <ek-dropdown-item (click)="openSettingsModal()">
    <i class="fas fa-cog"></i>
    {{ 'TOPBAR.SETTINGS' | translate }}
  </ek-dropdown-item>
  <ek-dropdown-item (click)="openFeedbackModal()">
    <i class="fas fa-comment-dots"></i>
    {{ 'TOPBAR.FEEDBACK_TITLE' | translate }}
  </ek-dropdown-item>
  <ek-dropdown-item
    *ngIf="showApiKeys$ | async"
    (click)="openDataApiKeysModal()"
  >
    <i class="fas fa-key"></i>
    {{ 'DATA_API_KEYS.TITLE' | translate }}
  </ek-dropdown-item>
  <ek-dropdown-item (click)="openEmailNotificationModal()" [hidden]="isProduction">
    <i class="fas fa-envelope"></i>
    {{ 'EMAIL_NOTIFICATION.EMAIL_NOTIFICATION_TITLE' | translate }}
  </ek-dropdown-item>
  <ek-dropdown-item (click)="logout()">
    <i class="fas fa-door-open"></i>
    {{ 'TOPBAR.LOGOUT' | translate }}
  </ek-dropdown-item>
</ek-dropdown>
<ng-template #mobileTemplate>
  <a
    class="topbar-icon-item"
    (click)="openProfileChangeModal()"
  >
    <i class="fas fa-users"></i>
    <span class="topbar-mobile-title">{{ 'SETTINGS.CHANGE_PROFILE' | translate }}</span>
  </a>
  <a
    class="topbar-icon-item"
    (click)="openSettingsModal()"
  >
    <i class="fas fa-cogs"></i>
    <span class="topbar-mobile-title">{{ 'TOPBAR.SETTINGS' | translate }}</span>
  </a>
  <a
    class="topbar-icon-item"
    (click)="openFeedbackModal()"
  >
    <i class="fas fa-comments"></i>
    <span class="topbar-mobile-title">{{ 'TOPBAR.FEEDBACK_TITLE' | translate }}</span>
  </a>
  <a
    class="topbar-icon-item"
    (click)="openFeedbackModal()"
  >
    <i class="fas fa-envelope"></i>
    <span class="topbar-mobile-title">{{ 'EMAIL_NOTIFICATION.EMAIL_NOTIFICATION_TITLE' | translate }}</span>
  </a>
  <a
    class="topbar-icon-item"
    (click)="logout()"
  >
    <span class="topbar-mobile-title">{{ userName }}</span>
  </a>
</ng-template>
