import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { TimeFrameOptions } from '../../../../constants/time-frame';
import { Comparability } from '../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { FacilityService } from '../../../../shared/services/facility.service';
import { SumConsumptionsWidgetService } from '../../services/sum-consumptions-widget.service';
import { WidgetBase } from '../../shared/widget-base.interface';
import { SumConsumptionWidgetValues } from '../../shared/sum-consumptions';
import { ThresholdService } from '../../../../shared/services/threshold.service';

export interface SumConsumptionsWidgetOptions {
  timeFrameOption: TimeFrameOptions;
  valueOption: ValueType;
  comparableOption: Comparability;
  comparisonPeriodOption: 'Default' | number;
  selectedQuantityId: Quantities;
  unitKey: ReportingUnit;
}

@Component({
  selector: 'sum-consumptions-widget',
  templateUrl: './sum-consumptions-widget.component.html',
  styleUrls: ['./sum-consumptions-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SumConsumptionsWidgetService]
})
export class SumConsumptionsWidgetComponent implements WidgetBase<SumConsumptionsWidgetOptions>, OnInit, OnDestroy {
  @Input() public dataModelOptions: SumConsumptionsWidgetOptions;

  /** This widget's options can't be changed from this component. */
  public readonly dataModelChange$: Observable<SumConsumptionsWidgetOptions> = EMPTY;

  public data$: Observable<SumConsumptionWidgetValues>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;

  private readonly _loading$ = new LoadingSubject(true);
  private readonly _destroy$ = new Subject<void>();
  private readonly _error$ = new Subject<void>();

  public constructor(
    private readonly sumConsumptionsWidgetService: SumConsumptionsWidgetService,
    facilityService: FacilityService,
    thresholdService: ThresholdService
  ) {
    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();

    this.data$ = combineLatest([
      facilityService.filteredProfileFacilityIds$,
      thresholdService.threshold$
    ]).pipe(
      switchMap(([facilityIds, threshold]) => this.sumConsumptionsWidgetService.getSumConsumptionsData(
        facilityIds, threshold
      ).pipe(
        indicate(this._loading$),
        catchError(() => {
          this._error$.next();
          return EMPTY;
        })
      )),
      takeUntil(this._destroy$),
      shareReplay(1)
    );
  }

  public ngOnInit(): void {
    this.sumConsumptionsWidgetService.setDataModelOptions(this.dataModelOptions);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._error$.complete();
  }
}
