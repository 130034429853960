<form [formGroup]="form">
  <div class="formRow">
    <div class="subFormRow">
      <div class="dropdownSelect">
        <kendo-dropdownlist
          formControlName="language"
          [data]="languages"
          *labelBefore="'SETTINGS.LANGUAGE' | translate"
          textField="translation"
          valueField="language"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
      </div>
      <div class="dropdownSelect">
        <kendo-dropdownlist
          formControlName="to"
          [data]="receiverProperties"
          *labelBefore="'MQA.EMAIL.RECEIVER' | translate"
          textField="translation"
          valueField="value"
          [valuePrimitive]="true"
        ></kendo-dropdownlist>
      </div>
      <div class="dropdownSelect">
        <kendo-dropdownlist
          class="emailDropdown"
          formControlName="replyTo"
          [data]="replyAddresses$ | async"
          *labelBefore="'MQA.EMAIL.REPLY_TO' | translate"
        ></kendo-dropdownlist>
      </div>
    </div>
    <div>
      <input
        type="checkbox"
        formControlName="bcc"
        *labelWrap="'MQA.EMAIL.BCC_REPLY_TO' | translate"
      />
      <input
        type="checkbox"
        formControlName="bccUser"
        *labelWrap="('MQA.EMAIL.BCC' | translate) + ' ' + userName"
      />
    </div>
  </div>
  <div>
    <input
      type="text"
      formControlName="subject"
      *labelBefore="'MQA.EMAIL.SUBJECT' | translate"
    />
  </div>
  <kendo-editor
    [ngStyle]="kendoEditorStyle"
    [iframe]="false"
    *labelBefore="'MQA.EMAIL.COVERING_NOTE' | translate; target: '[contenteditable=true]'"
    formControlName="coveringNote"
  ></kendo-editor>
  <kendo-editor
    [ngStyle]="kendoEditorStyle"
    [iframe]="false"
    *labelBefore="'MQA.EMAIL.SIGNATURE' | translate; target: '[contenteditable=true]'"
    formControlName="signature"
  ></kendo-editor>
</form>
