import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CheckableSettings } from '@progress/kendo-angular-treeview';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, take, takeUntil, tap } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import { MeteringType } from '@enerkey/clients/meter-management';

import { ReportingMeterTreeMeter, ReportModalMetersService } from '../../services/report-modal-meters.service';

@Component({
  selector: 'meter-tree',
  templateUrl: './meter-tree.component.html',
  styleUrls: ['./meter-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterTreeComponent implements OnDestroy {
  public readonly meterNameFilterControl = new FormControl('');
  public readonly meterTypeFilterControl = new FormControl<MeteringType | null>(null);
  public readonly meterTypes$: Observable<MeteringType[]>;
  public checkedKeys: number[] = [];
  public expandedKeys: number[] = [];
  public checkedSubmeterSelectors: { [id: number]: boolean } = {};

  public readonly checkableSettings: CheckableSettings = {
    checkParents: false,
    checkChildren: false,
    mode: 'multiple',
    checkOnClick: false,
  };

  public readonly meterTrees$: Observable<{ quantityId: Quantities, trees: ReportingMeterTreeMeter[] }[]>;

  private readonly allMeterIds$: Observable<number[]>;
  private readonly destroy$ = new Subject<void>();

  public constructor(private readonly reportModalMetersService: ReportModalMetersService) {
    this.reportModalMetersService.selectedMeters$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: meters => {
          this.checkedKeys = [...meters.meterIds];
        }
      });

    this.meterTypes$ = this.reportModalMetersService.metersFlat$.pipe(
      map(meters => meters.unique(m => m.meteringType).sort()),
      takeUntil(this.destroy$)
    );

    this.allMeterIds$ = this.reportModalMetersService.metersFlat$.pipe(
      take(1),
      map(meters => meters.map(m => m.id))
    );

    this.meterTrees$ = combineLatest([
      this.reportModalMetersService.meterTrees$,
      this.meterNameFilterControl.valueChanges.pipe(startWith('')),
      this.meterTypeFilterControl.valueChanges.pipe(startWith(null)),
    ]).pipe(
      map(([meterTrees, filterText, meterType]) => {
        const filteredMeterTrees = meterTrees.mapFilter(
          t => this.filterTree(t, filterText, meterType),
          t => t
        );
        return [...filteredMeterTrees.toGroupsBy(t => t.quantityId).entries()].map(([quantityId, trees]) => ({
          quantityId,
          trees
        })).sortBy('quantityId');
      }),
      tap(() => {
        this.expandAll();
      }),
      shareReplay(1),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleSubmetersSelection(meterTree: ReportingMeterTreeMeter, event: Event): void {
    const isChecked = Boolean((event.target as HTMLInputElement).checked);
    const meterIds = this.getSubmeterIds(meterTree);

    for (const meterId of [meterTree.id, ...meterIds]) {
      this.checkedSubmeterSelectors[meterId] = isChecked;
    }
    if (isChecked) {
      const ids = Object.integerKeys(this.checkedSubmeterSelectors)
        .filter(id => meterIds.includes(id))
        .filter(id => this.checkedSubmeterSelectors[id]);
      this.checkedKeys = [...this.checkedKeys, ...ids].unique();
    } else {
      this.checkedKeys = this.checkedKeys.filter(id => !meterIds.includes(id));
    }
    this.onCheck();
  }

  public getSubmeterIds(meterTree: ReportingMeterTreeMeter): number[] {
    const meterIds: number[] = [];
    if (meterTree.subMeters) {
      for (const subMeter of meterTree.subMeters) {
        meterIds.push(subMeter.id, ...this.getSubmeterIds(subMeter));
      }
    }
    return meterIds;
  }

  public onCheck(): void {
    this.reportModalMetersService.selectMeters(this.checkedKeys);
  }

  public selectAll(): void {
    this.allMeterIds$.pipe(take(1)).subscribe({
      next: meterIds => {
        this.checkedKeys = [...meterIds];
        for (const meterId of this.checkedKeys) {
          this.checkedSubmeterSelectors[meterId] = true;
        }
        this.onCheck();
      }
    });
  }

  public deselectAll(): void {
    this.checkedKeys = [];
    this.checkedSubmeterSelectors = {};
    this.onCheck();
  }

  public expandAll(): void {
    this.allMeterIds$.pipe(take(1)).subscribe({
      next: meterIds => {
        this.expandedKeys = [...meterIds];
      }
    });
  }

  public collapseAll(): void {
    this.expandedKeys = [];
  }

  private filterTree(
    tree: ReportingMeterTreeMeter,
    filterText: string,
    meterType: MeteringType | null
  ): ReportingMeterTreeMeter {
    const visibleChildren = tree.subMeters?.mapFilter(
      t => this.filterTree(t, filterText, meterType),
      t => t
    );
    if (
      Array.hasItems(visibleChildren)
        || this.meterMatchesFilters(tree, filterText, meterType)) {
      return new ReportingMeterTreeMeter(tree, visibleChildren);
    }
  }

  private meterMatchesFilters(
    meter: ReportingMeterTreeMeter,
    filterText: string,
    meterType: MeteringType | null
  ): boolean {
    if (meter.meterTreeVisibleName.toLowerCase().includes(filterText.toLowerCase().trim())) {
      return meterType === null || meter.meteringType === meterType;
    }
    return false;
  }
}
