import EnerKeyComponentsModule from '../../../components';
import EnerKeyDirectivesModule from '../../../directives';
import EnerKeyServicesModule from '../../../services';
import MrcConstantsModule from '../../mrc/constants';
import ManualQAServicesModule from '../services';
import InputFieldModule from './input-field';
import ManualQaActionsModule from './manual-qa-actions';
import ManualQaActionsOperationModule from './manual-qa-actions-operation';
import ManualQaActionsSelectModule from './manual-qa-actions-select';
import ManualQaChangeMeterModule from './manual-qa-change-meter';
import ManualQaChangeTimeFramePresetsModule from './manual-qa-change-time-frame-presets';
import ManualQaChartModule from './manual-qa-chart';
import ManualQaConsumptionInputModalModule from './manual-qa-consumption-input-modal';
import ManualQaConsumptionStatsModule from './manual-qa-consumption-stats';
import ManualQaConsumptionStatsCellModule from './manual-qa-consumption-stats-cell';
import ManualQaFetchTypeModule from './manual-qa-fetch-type';
import ManualQaIndexModule from './manual-qa-index';
import ManualQaInspectModule from './manual-qa-inspect';
import ManualQaInspectSearchModule from './manual-qa-inspect-search';
import ManualQaInspectSearchSectionBodyModule from './manual-qa-inspect-search-section-body';
import ManualQaInspectSearchSectionHeaderModule from './manual-qa-inspect-search-section-header';
import ManualQaMeterHistoryModule from './manual-qa-meter-history';
import ManualQaResultModule from './manual-qa-result';
import ManualQaSearchModule from './manual-qa-search';
import ManualQaSearchCriteriaModule from './manual-qa-search-criteria';
import ManualQaSearchFacilitiesModule from './manual-qa-search-facilities';
import ManualQaSearchListModule from './manual-qa-search-list';
import ManualQaSearchListButtonsModule from './manual-qa-search-list-buttons';
import ManualQaSearchListDeleteModule from './manual-qa-search-list-delete';
import ManualQaSearchListEditModule from './manual-qa-search-list-edit';
import ManualQaSearchMeteringPointsModule from './manual-qa-search-metering-points';
import ManualQaSearchQaModule from './manual-qa-search-qa';
import ManualQaSearchReadingApiModule from './manual-qa-search-reading-api';
import ManualQaSpreadsheetModule from './manual-qa-spreadsheet';
import ManualQaSpreadsheetFilterTypeSelectModule from './manual-qa-spreadsheet-filter-type-select';
import ManualQaModelPreviewModule from './manual-qa-model-preview';
import ManualQaMeterNameQuantityModule from './manual-qa-meter-name-quantity';
import ManualQaInspectModalModule from './manual-qa-inspect-modal';
import ManualQaMeterFacilityInfoModule from './manual-qa-meter-facility-info';
import ManualQaInspectButtonModule from './manual-qa-inspect-button';

export default angular.module('app.modules.manual-qa.components', [
  InputFieldModule.name,
  ManualQaActionsModule.name,
  ManualQaActionsOperationModule.name,
  ManualQaActionsSelectModule.name,
  ManualQaChangeMeterModule.name,
  ManualQaChangeTimeFramePresetsModule.name,
  ManualQaChartModule.name,
  ManualQaConsumptionInputModalModule.name,
  ManualQaConsumptionStatsModule.name,
  ManualQaConsumptionStatsCellModule.name,
  ManualQaIndexModule.name,
  ManualQaInspectModule.name,
  ManualQaInspectSearchModule.name,
  ManualQaInspectSearchSectionBodyModule.name,
  ManualQaInspectSearchSectionHeaderModule.name,
  ManualQaMeterHistoryModule.name,
  ManualQaResultModule.name,
  ManualQaSearchModule.name,
  ManualQaSearchCriteriaModule.name,
  ManualQaSearchFacilitiesModule.name,
  ManualQaSearchListModule.name,
  ManualQaSearchListButtonsModule.name,
  ManualQaSearchListDeleteModule.name,
  ManualQaSearchListEditModule.name,
  ManualQaSearchMeteringPointsModule.name,
  ManualQaSearchQaModule.name,
  ManualQaSearchReadingApiModule.name,
  ManualQaFetchTypeModule.name,
  ManualQaSpreadsheetModule.name,
  ManualQaSpreadsheetFilterTypeSelectModule.name,
  ManualQaInspectModalModule.name,
  EnerKeyComponentsModule.name,
  EnerKeyServicesModule.name,
  EnerKeyDirectivesModule.name,
  ManualQAServicesModule.name,
  MrcConstantsModule.name,
  ManualQaModelPreviewModule.name,
  ManualQaMeterNameQuantityModule.name,
  ManualQaMeterFacilityInfoModule.name,
  ManualQaInspectButtonModule.name,
]);
