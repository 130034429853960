import { FormulaOperator } from './formula-operator';
import { FormulaParen } from './formula-paren';
import { FormulaAddMeters } from './formula-add-meters';
import { TargetMeter } from './target-meter';
import { FormulaHelpers } from './formula-helpers';
import { PlaceToAdd } from './place-to-add';
import { AddOperationType } from '../../admin/shared/add-operation-type';
import { Helpers } from '@enerkey/ts-utils';
import { FieldType, TreeViewItem } from '@enerkey/clients/metering';
import { FormulaNewItemId } from './formula-new-item-id';

export interface NewFormulaItems {
  getNewItems: () => TreeViewItem[];
  readonly placeToAdd: PlaceToAdd;
}

abstract class NewItemBase implements NewFormulaItems {
  protected getNewItemParentId(): number {
    return this.placeToAdd === PlaceToAdd.Inside ?
      this.itemAddContext.itemId :
      this.itemAddContext.parentId
    ;
  }

  public abstract getNewItems(): TreeViewItem[];
  public abstract readonly placeToAdd: PlaceToAdd;
  protected abstract readonly itemAddContext: TreeViewItem;
}

abstract class NewSingleItemBase extends NewItemBase {
  public getNewItems(): TreeViewItem[] {
    return [new TreeViewItem({
      itemId: FormulaNewItemId.newItemId,
      parentId: this.getNewItemParentId(),
      content: this.newItemContent,
      itemFieldType: this.newItemFieldType
    })];
  }

  protected abstract newItemFieldType: FieldType;
  protected abstract newItemContent: string;

}

class NewOperator extends NewSingleItemBase {
  protected newItemFieldType = FieldType.Operator;

  public constructor(
    protected newItemContent: FormulaOperator | FormulaParen,
    protected itemAddContext: TreeViewItem,
    public placeToAdd: PlaceToAdd
  ) {
    super();
  }
}

class NewConstant extends NewSingleItemBase {
  protected newItemContent = '1';
  protected newItemFieldType = FieldType.Constant;

  public constructor(
    protected itemAddContext: TreeViewItem,
    public placeToAdd: PlaceToAdd
  ) {
    super();
  }

  public override getNewItems(): TreeViewItem[] {
    const newItems = super.getNewItems();
    newItems[0].constant = 1;
    return newItems;
  }
}

class NewMeters extends NewItemBase {
  public constructor(
    private meters: TargetMeter[],
    protected itemAddContext: TreeViewItem,
    public readonly placeToAdd: PlaceToAdd = PlaceToAdd.Inside
  ) {
    super();
  }

  public getNewItems(): TreeViewItem[] {
    return FormulaAddMeters.getFormulaFromMeterArray(this.meters, this.getNewItemParentId());
  }
}

export class NewFormulaItemFactory {
  public static createNewItem(
    additionType: AddOperationType,
    itemContext: TreeViewItem,
    meters?: TargetMeter[]
  ): NewFormulaItems {
    const placeToAdd = FormulaHelpers.getPlaceOfAddition(additionType);
    switch (additionType) {
      case AddOperationType.OperatorAfter:
      case AddOperationType.OperatorBefore:
      case AddOperationType.OperatorInside:
        return new NewOperator(FormulaOperator.Plus, itemContext, placeToAdd);
      case AddOperationType.ParenthesesAfter:
      case AddOperationType.ParenthesesBefore:
      case AddOperationType.ParenthesesInside:
        return new NewOperator(FormulaParen.OpenParen, itemContext, placeToAdd);
      case AddOperationType.ConstantAfter:
        return new NewConstant(itemContext, placeToAdd);
      case AddOperationType.OperandInside:
      case AddOperationType.OperandAfter:
      case AddOperationType.OperandBefore:
        return new NewMeters(meters, itemContext, placeToAdd);
      case AddOperationType.ParenthesesAround:
        throw new Error('Adding parentheses around is not implemented yet');
      default:
        Helpers.assertUnreachable(additionType);
    }
  }
}
