import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ReportingUnit } from '@enerkey/clients/reporting';

import { QuantityChartData } from '../../../../shared/energy-reporting-shared/shared/consumptions';

@Component({
  selector: 'quantity-info-header',
  templateUrl: './quantity-info-header.component.html',
  styleUrls: ['./quantity-info-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantityInfoHeaderComponent {
  @Input() public quantityData: QuantityChartData;
  @Input() public unitKey: ReportingUnit;
}
