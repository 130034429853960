import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { ControlValueAccessorOf } from '@enerkey/ts-utils';
import { RowUnitType } from '@enerkey/clients/sustainability';
import { Quantities } from '@enerkey/clients/metering';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { GriReportService } from '../../services/gri-report.service';
import { quantityTranslations } from '../../../../constants/quantity.constant';

/* eslint-disable @typescript-eslint/indent */

export type QuantitySortOptions = {
  countries?: string[],
  facilitySources?: string[],
  sources?: string[] // Only made available for rowUnitType === 'Custom'
}

@Component({
  selector: 'gri-unit-combo',
  templateUrl: './gri-unit-combo.component.html',
  styleUrls: ['./gri-unit-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GriUnitComboComponent),
    multi: true
  }],
})
export class GriUnitComboComponent implements ControlValueAccessorOf<number>, OnInit, OnDestroy {

  public unitOptions$: Observable<ComboItem<number>[]>;

  public get quantityId(): number {
    return this._quantity$.value;
  }

  @Input()
  public set quantityId(value: number) {
    this._quantity$.next(value);
  }

  @Input() public quantitySorter: QuantitySortOptions;
  @Input() public quantityFilter: string[] = [];
  @Input() public rowUnitTypes: RowUnitType[] = [];

  public value: number;
  public disabled: boolean;

  private _changefn: (value: number) => void;

  private readonly _quantity$ = new BehaviorSubject<number>(null);
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly service: GriReportService,
    private readonly translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.unitOptions$ = combineLatest({
      units: this.service.filteredUnits(...this.rowUnitTypes),
      quantityId: this._quantity$
    }).pipe(
      map(({ units, quantityId }) => {
        const customUnits = units.filter(u => u.rowUnitType === RowUnitType.Custom).uniqueBy('source');
        const otherUnits = units.filter(u => u.rowUnitType !== RowUnitType.Custom);

        const countries = this.quantitySorter?.countries?.sort();
        const facilitySources = this.quantitySorter?.facilitySources?.sort();
        const sources = this.quantitySorter?.sources?.sort();

        return [...customUnits, ...otherUnits]
        .filter(u => !quantityId || u.quantityId === quantityId)
        .filter(u => this.quantityFilter.length > 0 ? this.quantityFilter.find(c => c === u.source) : true)
          .map(u => {
            const name = `${u.source} ${u.year} 
          ${u.quantityId ? this.translateService.instant(quantityTranslations[u.quantityId as Quantities]) : ''}`;

            return {
              value: u.id,
              text: name,
              unit: u,
            };
          })
          .sortByMany(
            [x => !!countries?.find(y => y === x.unit.source), 'desc'],
            [x => !!facilitySources?.find(y => y === x.unit.source), 'desc'],
            [x => !!sources?.find(source => x.unit.rowUnitType === RowUnitType.Custom &&
              source === x.unit.source), 'desc'],
            [x => x.unit.source.toLowerCase() ===
              this.translateService.currentLang.toLowerCase().split('-')[0], 'desc'],
            [x => x.unit.rowUnitType === RowUnitType.Location, 'desc'],
            [x => x.unit.rowUnitType === RowUnitType.Facility, 'desc'],
            [x => x.unit.rowUnitType === RowUnitType.Custom, 'desc'],
            [x => x.unit.source],
            [x => x.unit],
            [x => x.unit.year, 'desc']
          );
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.unitOptions$.subscribe(options => {
      if (this.value && options.every(item => item.value !== this.value)) {
        this.value = null;
        this._changefn?.(null);
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._quantity$.complete();
  }

  public valueChanged(value: number): void {
    this._changefn?.(value);
  }

  public writeValue(value: number): void {
    this.value = value;
  }

  public registerOnChange(changefn: (value: number) => void): void {
    this._changefn = changefn;
  }

  public registerOnTouched(): void {
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
