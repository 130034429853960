import { ChangeDetectionStrategy, Component } from '@angular/core';

import { guid } from '@enerkey/ts-utils';

import { GridMultiFilterBase } from '../grid-multi-filter-base/grid-multi-filter-base';
import { GridMultiFilterTemplateContext } from '../grid-multi-filter-base/grid-multi-filter-template.directive';

type FilterModel<T, U> = GridMultiFilterTemplateContext<T, U> & { checked: boolean };

/**
 * Reusable checkbox multiselect filter. See base class for remarks and implementation details.
 *
 * @template T Grid data item type
 * @template U Primitive value of the field
 *
 * @example
 * ```html
 * <ng-template
 *   kendoGridFilterMenuTemplate
 *   let-filter="filter"
 *   let-filterService="filterService"
 * >
 *   <grid-checkbox-filter
 *     [filter]="filter"
 *     [filterService]="filterService"
 *     [field]="'userId'"
 *     [textSelector]="'userName'"
 *     [source]="gridRows"
 *   ></grid-checkbox-filter>
 * </ng-template>
 * ```
 */
@Component({
  selector: 'grid-checkbox-filter',
  templateUrl: './grid-checkbox-filter.component.html',
  styleUrls: ['./grid-checkbox-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridCheckboxFilterComponent<T, U> extends GridMultiFilterBase<T, U> {

  /** Id prefix for the DOM elements. */
  public readonly componentId: string = guid(true);

  /** Models for the template's checkbox list. */
  public options: FilterModel<T, U>[];

  public onCheckedChange(item: FilterModel<T, U>): void {
    item.checked = !item.checked;
    super.updateFilter(this.options.filterMap(
      option => option.checked,
      option => option.value
    ));
  }

  protected initialize(items: GridMultiFilterTemplateContext<T, U>[]): void {
    const activeItems = this.getActiveSelection();

    this.options = items.map<FilterModel<T, U>>(item => ({
      ...item,
      checked: activeItems.includes(item.value)
    }));
  }
}
