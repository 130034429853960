import { IDatahubClient } from './datahub-client';

export class DatahubClientMock implements IDatahubClient {
  public setCustomerAuthorization(): never {
    throw new Error('Method not implemented.');
  }
  public endCustomerAuthorization(): never {
    throw new Error('Method not implemented.');
  }
  public requestMeteringPointInfo(): never {
    throw new Error('Method not implemented.');
  }
  public requestMeteringPoints(): never {
    throw new Error('Method not implemented.');
  }
  public requestMeteringData(): never {
    throw new Error('Method not implemented.');
  }
  public requestCustomerDataUpdate(): never {
    throw new Error('Method not implemented.');
  }
  public requestUsagePlaceDataUpdate(): never {
    throw new Error('Method not implemented.');
  }
  public requestAuhtorizationsForMeters(): never {
    throw new Error('Method not implemented.');
  }
}
