<ul class="tabs" role="tablist">
  <li
    class="tabs-title"
    *ngFor="let tab of tabs"
    [class.is-active]="tab.id === activeId"
  >
    <a
      [id]="tab.id"
      [class.disabled]="tab.disabled"
      href
      (click)="select(tab.id); $event.preventDefault()"
      role="tab"
      [attr.tabindex]="(tab.disabled ? '-1': undefined)"
      [attr.aria-controls]="(!destroyOnHide || tab.id === activeId ? tab.id + '-panel' : null)"
      [attr.aria-selected]="tab.id === activeId"
      [attr.aria-disabled]="tab.disabled"
    >{{tab.title}}<ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef"></ng-template></a>

  </li>
</ul>
<!-- Display item count -->
<div class="transformed-position" *ngIf="showCountLabel">
  <span class="er-modal-report-type__meter-count er-object-count">{{itemCount}}</span>
</div>
<div class="tabs-content">
  <ng-content select="[ngfTabCommonContent]"></ng-content>
  <ng-template ngFor let-tab [ngForOf]="tabs">
    <div
      class="tabs-panel {{tab.id === activeId ? 'is-active' : null}}"
      *ngIf="!destroyOnHide || tab.id === activeId"
      role="tabpanel"
      [attr.aria-labelledby]="tab.id" id="{{tab.id}}-panel"
      [attr.aria-hidden]="tab.id !== activeId"
      [class.no-padding]="noPadding"
    >
      <ng-template [ngTemplateOutlet]="tab.contentTpl?.templateRef"></ng-template>
    </div>
  </ng-template>
</div>
