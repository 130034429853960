import ManualQaActionsOperationsController from './manual-qa-actions-operation-controller';
import template from 'raw-loader!./manual-qa-actions-operation.html';

export default {
  template: template,
  controller: ManualQaActionsOperationsController,
  controllerAs: 'vm',
  bindings: {
    operationTimeFrame: '<',
    actualTimeFrame: '<',
    meter: '<',
    isLoading: '<',
    getReadings: '&',
    lockModel: '&',
    deleteModel: '&',
    deleteConsumption: '&'
  }
};
