import AdminSpreadsheetComponent from './admin-spreadsheet-component';
import AdminSpreadsheetService from './admin-spreadsheet-service';

import AdminServicesModule from '../../services';

import EnerkeyServicesModule from '../../../../services';
import ReportingObjectsServicesModule from '../../../reportingobjects/services';
import KendoModule from '../../../kendo';

export default angular.module('app.modules.admin.components.admin-spreadsheet', [
  EnerkeyServicesModule.name,
  AdminServicesModule.name,
  ReportingObjectsServicesModule.name,
  KendoModule.name
])
  .component('adminSpreadsheet', AdminSpreadsheetComponent)
  .factory('AdminSpreadsheetService', AdminSpreadsheetService)
;
