import { cloneDeep } from 'lodash';
import moment, { Moment } from 'moment';

import {
  Timeseries,
  TimeseriesResolution,
  TimeseriesTypeId
} from '@enerkey/clients/energy-reporting';
import ErReportSettingsService, { ErReportSettings } from '../services/er-report-settings.service';
import ErStateService from '../services/er-state.service';
import ErTimeFrameService from '../services/er-time-frame.service';
import { BeginEndDates } from '../interfaces/actions-impact-dates';
import { ReportSettingsSeries } from '../interfaces/report-settings';
import TimeFrame, { TimeFrameString } from '../../../services/time-frame-service';
import { ComparisonStartDate } from '../shared/comparison-start-date';
import { FromStartDate, FromToStartDate, ToStartDate } from '../shared/from-to-start-date';

interface NoComparisonPreset {
  from: Date;
  timeFrameObject: any;
  years: number;
}

interface StartDatesConfig {
  showComparison: boolean;
  comparison: ComparisonStartDate[];
  fromTo: FromToStartDate[];
}

interface TimePeriodHistoryItem {
  timeFrameTypeid: number;
  resolutionAbbr: string;
  start: Moment[];
  noComparisonSum: boolean;
  selected: boolean;
  texts: { text: string }[];
}

const $inject = [
  '$scope', '$timeout', '$transitions', 'configurationApi', 'utils',
  'erReportSettingsService', 'erStateService', 'erTimeFrameService'
];

class FacilitiesSidebarTimePeriodController {

  public loading = false;
  public timeSeries: Timeseries[];

  public timeFrame: Timeseries;
  public timeFrameDisabled = false;
  public resolution: TimeseriesResolution;
  public resolutionDisabled = false;
  public startDates: StartDatesConfig = {
    comparison: [],
    fromTo: [],
    showComparison: true
  };
  public isNoComparisonSum = true;
  public noComparisonSumDisabled = false;
  public dpOptions: any;
  public onPresetChangeBinded: (preset: NoComparisonPreset) => void;
  public history: TimePeriodHistoryItem[] = [];
  public historyDisabled = false;
  public adjustButtonDisabled = false;

  private erReportSettings: ErReportSettings;
  private flagComparisonTouched = false;
  private readonly historyMax = 15;
  private changeInternallyInitiated = false;
  private reportChangeListenerUnbind: () => void;
  private timePeriodListenerUnbind: () => void;

  public constructor(
    private $scope: any,
    private $timeout: any,
    private $transitions: any,
    private configurationApi: any,
    private utils: any,
    private erReportSettingsService: ErReportSettingsService,
    private erStateService: ErStateService,
    private erTimeFrameService: ErTimeFrameService
  ) {
    this.loading = true;
    this.erReportSettings = this.erReportSettingsService.getInstance();
    this.configurationApi.timeSeries.initialized()
      .then(this.fetchTimeSeries.bind(this))
      .finally(() => {
        this.loading = false;
        this.setSettingsVisibilityByReportType();
        this.setInitialSettings();
        this.setupListeners();
        this.updateSeries = this.updateSeriesFn;
        this.updateSeries();
      });
    this.onPresetChangeBinded = this.onPresetChange.bind(this);
    this.$scope.$on('$destroy', () => {
      this.reportChangeListenerUnbind?.();
      this.timePeriodListenerUnbind?.();
    });
  }

  public onTimeFrameChange(): void {
    this.flagComparisonTouched = false;
    this.resolution = this.getResolution();
    this.setDatePickerOptions();
    this.startDates.showComparison = this.isComparison(this.timeFrame.Abbr);
    this.setDefaultStart();
    this.updateSeries();
  }

  public onResolutionChange(): void {
    this.setDatePickerOptions();
    this.updateStartDates();
    this.updateSeries();
  }

  public onNoComparisonSumChange(): void {
    this.updateSeries();
  }

  public onPresetChange(preset: NoComparisonPreset): void {
    this.startDates.fromTo = this.getFromToStartDates(
      this.utils.dateToUtcDate(preset.timeFrameObject.from),
      this.utils.dateToUtcDate(preset.timeFrameObject.to)
    );
    this.updateSeries();
  }

  public removeStart(start: ComparisonStartDate): void {
    const startIndex = this.startDates.comparison.indexOf(start);
    this.startDates.comparison[startIndex] = new ComparisonStartDate(null, this.timeFrame.Abbr as TimeFrameString, this.resolution.Abbr);
    this.updateSeries();
  }

  public selectTimeSeriesHistoryItem(selectedItem: TimePeriodHistoryItem): void {
    if (!selectedItem.selected) {
      this.timeFrame = this.timeSeries.find(timeSeriesItem => timeSeriesItem.TypeId === selectedItem.timeFrameTypeid);
      this.resolution = this.getResolution(selectedItem.resolutionAbbr);
      this.startDates.showComparison = selectedItem.start.length === 3;
      if (this.startDates.showComparison) {
        this.startDates.comparison = selectedItem.start
          .map(startDate => new ComparisonStartDate(startDate, this.timeFrame.Abbr as TimeFrameString, this.resolution.Abbr));
      } else {
        this.startDates.fromTo = this.getFromToStartDates(selectedItem.start[0], selectedItem.start[1]);
      }
      this.isNoComparisonSum = selectedItem.noComparisonSum;
      this.history.splice(this.history.indexOf(selectedItem), 1);
      this.history.push(selectedItem);
      this.history.forEach(historyItem => {
        historyItem.selected = historyItem === selectedItem;
      });
      this.updateSeries();
    }
  }

  public adjustComparisonDates(count: -1 | 1): void {
    let timeFrameAbbr = this.timeFrame.Abbr;
    if (!this.startDates.showComparison) {
      const from = this.startDates.fromTo[0].value;
      const to = this.startDates.fromTo[1].value;
      timeFrameAbbr = this.utils.isoDuration(from, to, this.resolution.Abbr);
    }
    const timeFrameDuration = moment.fromIsodurationCached(timeFrameAbbr);
    const starts: (ComparisonStartDate | FromToStartDate)[] = this.startDates.showComparison
      ? this.startDates.comparison
      : this.startDates.fromTo;
    const newStartDates = starts.reduce((clonedValues, start) => {
      if (start.value) {
        const clonedValue = start.value.clone();
        let adjustedCount = 0;
        while (adjustedCount !== count) {
          if (count > 0) {
            clonedValue.add(timeFrameDuration);
            adjustedCount++;
          } else if (count < 0) {
            clonedValue.subtract(timeFrameDuration);
            adjustedCount--;
          }
        }
        clonedValues.push(clonedValue);
      }
      return clonedValues;
    }, []);
    if (this.startDates.showComparison) {
      this.startDates.comparison = this.getComparisonStartDates(newStartDates);
    } else {
      this.startDates.fromTo = this.getFromToStartDates(newStartDates[0], newStartDates[1]);
    }
    this.updateSeries();
  }

  private fetchTimeSeries(): Promise<void> {
    return this.configurationApi.timeSeries.getTimeSeries().then((result: Timeseries[]) => {
      this.timeSeries = cloneDeep(result);
      const noComparison = this.timeSeries.find(timeSeriesItem => timeSeriesItem.Abbr === '');
      if (noComparison) {
        noComparison.Abbr = 'NoComparison';
      }
    });
  }

  private setupListeners(): void {
    this.reportChangeListenerUnbind = this.erStateService.isModalOpen()
      ? this.erReportSettings.setCallback(() => {
        this.setSettingsVisibilityByReportType();
      }, 'name')
      : this.$transitions.onSuccess({ entering: 'facilities.**' }, () => {
        this.setSettingsVisibilityByReportType();
      });

    this.timePeriodListenerUnbind = this.erReportSettings.setCallback(() => {
      if (!this.changeInternallyInitiated) {
        this.parseSettingsFromSeries(this.erReportSettings.getSettings().series);
        this.saveHistoryItem();
      } else {
        this.changeInternallyInitiated = false;
      }
    }, 'series');
  }

  private parseSettingsFromSeries(series: ReportSettingsSeries): void {
    if (this.isSeriesValid(series)) {
      this.startDates.showComparison = series.ShowComparison;
      if (this.startDates.showComparison) {
        this.timeFrame = this.findTimeFrameNotQuarter(series.TimeFrame);
        this.resolution = this.getResolution(series.Resolution);
        this.setDatePickerOptions();
        this.startDates.comparison = this.getComparisonStartDates(series.Start.map(start => start.value).reverse());
      } else {
        this.timeFrame = this.timeSeries.find(timeFrame => timeFrame.TypeId === TimeseriesTypeId._15); // No comparison
        this.resolution = this.getResolution(series.Resolution);
        this.setDatePickerOptions();
        if (series.Start.length === 1) {
          const timeFrame = TimeFrame.parse({
            fromDate: series.Start[0].value,
            durationTo: series.TimeFrame
          });
          timeFrame.fromDate.utc();
          timeFrame.toDate.utc();
          this.startDates.fromTo = this.getFromToStartDates(timeFrame.fromDate, timeFrame.toDate);
        } else {
          const toDate = TimeFrame.parse({
            fromDate: series.Start[series.Start.length - 1].value,
            durationTo: series.TimeFrame
          }).toDate;
          this.isNoComparisonSum = this.erStateService.isModalOpen() || false ;
          this.startDates.fromTo = this.getFromToStartDates(series.Start[0].value, toDate);
        }
      }
      this.saveHistoryItem();
    }
  }

  private setInitialSettings(): void {
    const series: ReportSettingsSeries = this.erReportSettings.getSettings().series;
    if (this.isSeriesValid(series)) {
      this.parseSettingsFromSeries(series);
    } else {
      const defaultTimeFrame = this.timeSeries.find(timeSeriesItem => timeSeriesItem.Default);
      this.timeFrame = defaultTimeFrame || this.timeSeries[0];
      this.onTimeFrameChange();
    }
  }

  private updateSeries: () => void = () => {};

  private updateSeriesFn(): void {
    const changedSeries = this.getSeries();
    const series: ReportSettingsSeries = this.erReportSettings.getSettings().series;
    this.changeInternallyInitiated = true;
    if (!this.startDates.showComparison && !this.isNoComparisonSum) {
      this.erReportSettings.changeSetting('changeType', 'none');
    }
    this.erReportSettings.changeSetting('series', { ...series, ...changedSeries });
    this.saveHistoryItem();
  }

  private getSeries(): Partial<ReportSettingsSeries> {
    let timeFrame: string;
    let resolution: string;
    let start: any[];
    let beginEndDates: BeginEndDates;
    if (this.startDates.showComparison) {
      timeFrame = this.timeFrame.Abbr;
      resolution = this.resolution.Abbr;
      start = this.startDates.comparison
        .filter(startDate => startDate.value)
        // TODO Other controllers should be refactored to support ComparisonStartDate interface
        // This mapping would be unnecessary after that
        .map(startDate => ({
          key: startDate.key,
          valueStart: startDate.formatted,
          value: startDate.value.toISOString()
        }))
        .reverse();
    } else {
      const from = this.startDates.fromTo[0];
      const to = this.startDates.fromTo[1];
      if (!this.isNoComparisonSum) {
        const result = this.erTimeFrameService.getStartFromResolution(this.resolution.Abbr as TimeFrameString, from.value, to.value);
        timeFrame = result.TimeFrame;
        resolution = result.Resolution;
        start = result.Start;
      } else {
        resolution = this.resolution.Abbr;
        timeFrame = this.utils.isoDuration(from.value, to.value, resolution);
        start = [{
          key: `${from.key} - ${to.key}`,
          value: from.valueDate
        }];
      }
    }

    if (this.startDates.showComparison) {
      const actionsImpactFrom = this.findFirstComparisonDate();
      const actionsImpactTo = this.findLastComparisonDate();
      beginEndDates = {
        from: actionsImpactFrom.value.toISOString(),
        fromString: actionsImpactFrom.fromKey,
        to: actionsImpactTo.to.toISOString(),
        toString: actionsImpactTo.toKey
      };
    } else {
      beginEndDates = {
        from: this.startDates.fromTo[0].value.toISOString(),
        fromString: this.startDates.fromTo[0].key,
        to: this.startDates.fromTo[1].value.toISOString(),
        toString: this.startDates.fromTo[1].key
      };
    }

    return {
      BeginEndDates: beginEndDates,
      Resolution: resolution,
      ExportOnly: this.resolution.ExportOnly,
      ShowComparison: this.startDates.showComparison,
      Start: start,
      TimeFrame: timeFrame
    };
  }

  private findFirstComparisonDate(): ComparisonStartDate {
    return this.startDates.comparison.reduce(
      (firstDate, startDate) => startDate.value && firstDate.value.valueOf() > startDate.value.valueOf()
        ? startDate
        : firstDate,
      this.startDates.comparison[0]
    );
  }

  private findLastComparisonDate(): ComparisonStartDate {
    return this.startDates.comparison.reduce(
      (lastDate, startDate) => startDate.to && lastDate.to.valueOf() < startDate.to.valueOf()
        ? startDate
        : lastDate,
      this.startDates.comparison[0]
    );
  }

  private saveHistoryItem(): void {
    const newHistoryItem: TimePeriodHistoryItem = this.getCurrentHistoryItem();
    const indenticalItemIndex = this.findIndenticalHistoryItem(newHistoryItem);
    if (indenticalItemIndex > -1) {
      this.history.splice(indenticalItemIndex, 1);
    }
    this.history.push(newHistoryItem);
    this.history = this.history.slice(-1 * this.historyMax);
    this.history.forEach(historyItem => {
      historyItem.selected = historyItem === newHistoryItem;
    });
  }

  private getCurrentHistoryItem(): TimePeriodHistoryItem {
    return {
      timeFrameTypeid: this.timeFrame.TypeId,
      resolutionAbbr: this.resolution.Abbr,
      start: this.startDates.showComparison
        ? this.startDates.comparison.map(startDate => startDate.value ? moment.utc(startDate.value) : null)
        : this.startDates.fromTo.map(startDate => moment.utc(startDate.value)),
      noComparisonSum: this.isNoComparisonSum,
      selected: true,
      texts: [
        { text: `${this.utils.localizedString('FACILITIES_TOOLBAR.RESOLUTION')}:` },
        { text: this.resolution.Name },
        {
          text: this.startDates.showComparison
            ? this.startDates.comparison
              .map(startDate => startDate.formatted)
              .filter(text => text !== '')
              .reverse()
              .join(', ')
            : this.startDates.fromTo.map(startDate => startDate.key).join(' - ')
        }
      ]
    };
  }

  private findIndenticalHistoryItem(newHistoryItem: TimePeriodHistoryItem): number {
    return this.history.findIndex(
      historyItem => JSON.stringify(historyItem.start) === JSON.stringify(newHistoryItem.start)
      && historyItem.timeFrameTypeid === newHistoryItem.timeFrameTypeid
      && historyItem.resolutionAbbr === newHistoryItem.resolutionAbbr
      && historyItem.noComparisonSum === newHistoryItem.noComparisonSum
    );
  }

  private getResolution(resolutionAbbr?: string): TimeseriesResolution {
    return this.timeFrame.Resolutions.find(resolution => resolution.Abbr === resolutionAbbr)
      || this.timeFrame.Resolutions.find(resolution => resolution.Default)
      || this.timeFrame.Resolutions[0];
  }

  private setDefaultStart(): void {
    let defaultStarts = this.resolution.DefaultStart;
    if (!defaultStarts) {
      defaultStarts = this.timeFrame.DefaultStart;
    }
    if (this.startDates.showComparison) {
      this.startDates.comparison = this.getComparisonStartDates(defaultStarts);
    } else {
      const toDate = moment.utc(this.utils.dateToUtcDate(this.configurationApi.timeSeries.getDefaultFromToEndDate()));
      const fromDate = defaultStarts && defaultStarts.length
        ? moment.utc(defaultStarts[0])
        : moment.utc([toDate.year() - 1, 0, 1]);
      this.startDates.fromTo = this.getFromToStartDates(fromDate, toDate);
    }
  }

  private getComparisonStartDates(startDates: Moment[]): ComparisonStartDate[] {
    return [0, 1, 2].map(
      index => new ComparisonStartDate(startDates[index], this.timeFrame.Abbr as TimeFrameString, this.resolution.Abbr)
    );
  }

  private getFromToStartDates(fromDate: Moment | Date, toDate: Moment | Date): FromToStartDate[] {
    return [
      new FromStartDate(fromDate),
      new ToStartDate(toDate)
    ];
  }

  private updateStartDates(): void {
    this.startDates.comparison = this.startDates.comparison
      .map(startDate => new ComparisonStartDate(startDate.value, this.timeFrame.Abbr as TimeFrameString, this.resolution.Abbr));
  }

  private setSettingsVisibilityByReportType(): void {
    const isOverview = this.erStateService.isFacilityOverviewReport();
    const isGrid = this.erStateService.isMeterTreelistReport() || this.erStateService.isGridReport();

    this.timeFrameDisabled = isOverview;
    this.resolutionDisabled = isOverview;
    this.historyDisabled = isOverview;
    this.adjustButtonDisabled = isOverview;
    this.noComparisonSumDisabled = !isGrid;

    if (!isGrid && !this.isNoComparisonSum) {
      this.isNoComparisonSum = true;
      this.updateSeries();
    }
  }

  private setDatePickerOptions(): void {
    const duration = moment.fromIsodurationCached(this.resolution.Abbr);
    if (duration.years()) {
      this.dpOptions = {
        start: 'decade',
        depth: 'decade',
        footer: false,
        change: this.onStartChange.bind(this)
      };
    } else if (duration.months()) {
      this.dpOptions = {
        start: 'year',
        depth: 'year',
        footer: false,
        change: this.onStartChange.bind(this)
      };
    } else {
      this.dpOptions = {
        start: 'month',
        depth: 'month',
        footer: false,
        change: this.onStartChange.bind(this)
      };
    }
  }

  private onStartChange(event: any): void {
    if (this.startDates.showComparison) {
      const changedStartDate: ComparisonStartDate = event.sender.$angular_scope.start;
      const duration = moment.fromIsodurationCached(this.resolution.Abbr);
      if (!duration.years()) { // Don't really know the point of this, just copied the old behaviour
        this.setAutomaticComparisonDates(changedStartDate);
      }
    } else {
      const changedStartDate: FromToStartDate = event.sender.$angular_scope.start;
      let fromDate = this.startDates.fromTo[0].value;
      let toDate = this.startDates.fromTo[1].value;
      if (fromDate.valueOf() > toDate.valueOf()) {
        if (this.startDates.fromTo[0] === changedStartDate) {
          toDate = fromDate.clone();
        } else {
          fromDate = toDate.clone();
        }
      }
      this.startDates.fromTo = this.getFromToStartDates(fromDate, toDate);
    }
    this.$timeout(() => {
      this.updateSeries();
    });
  }

  private setAutomaticComparisonDates(changedStartDate: ComparisonStartDate): void {
    if (changedStartDate === this.startDates.comparison[0] && !this.flagComparisonTouched) {
      [1, 2].forEach(index => {
        const comparisonStartDate = this.startDates.comparison[index];
        if (comparisonStartDate.value) {
          const timeFrameDuration = moment.fromIsodurationCached(this.timeFrame.Abbr);
          const newMomentValue = moment.utc(changedStartDate.value).subtract(timeFrameDuration);
          this.startDates.comparison[index]
            = new ComparisonStartDate(newMomentValue, this.timeFrame.Abbr as TimeFrameString, this.resolution.Abbr);
        }
      });
    } else {
      this.flagComparisonTouched = true;
    }
  }

  private findTimeFrameNotQuarter(timeFrameAbbr: string): Timeseries {
    return this.timeSeries.find(timeFrame => timeFrame.Abbr === timeFrameAbbr && !this.isQuarter(timeFrame));
  }

  private isComparison(timeFrameAbbr: string): boolean {
    return timeFrameAbbr && timeFrameAbbr !== 'NoComparison';
  }

  private isSeriesValid(series: ReportSettingsSeries): boolean {
    return !!(series && series.ShowComparison !== undefined && series.TimeFrame && series.Resolution && series.Start);
  }

  private isQuarter(timeFrame: Timeseries): boolean {
    return [
      TimeseriesTypeId._16, // Current quarter
      TimeseriesTypeId._17, // Previous quarter
      TimeseriesTypeId._18 // Full quarters
    ].includes(timeFrame.TypeId);
  }
}

FacilitiesSidebarTimePeriodController.$inject = $inject;

export default FacilitiesSidebarTimePeriodController;
