<modal-view-header [headingText]="'MQA.EMAIL.TITLE' | translate"></modal-view-header>

<div class="modal-body">
  <div class="wizard-wrapper">
    <wizard
      [steps]="wizardSteps"
      [(step)]="activeStep"
    ></wizard>
  </div>
  <div [ngSwitch]="activeStep.id">
    <email-common-values-form
      *ngSwitchCase="EmailSendStep.EmailBase"
      [form]="emailCommonValuesForm"
    ></email-common-values-form>
    <email-field-selection-form
      *ngSwitchCase="EmailSendStep.InfoToSend"
      [form]="infoToSendForm"
    ></email-field-selection-form>
    <div
      *ngSwitchCase="EmailSendStep.Sending"
    >
      <email-send-progress
        [readingGroupIdentifier]="readingGroupIdentifier$ | async"
        [bucketAmount]="bucketAmount"
        [currentBucketIndex]="currentBucketIndex"
      ></email-send-progress>
      <email-content-form
        [form]="singleEmailForm"
      ></email-content-form>
    </div>
    <email-summary
      *ngSwitchCase="EmailSendStep.Summary"
      [results]="emailResults"
    ></email-summary>
  </div>
</div>

<div class="modal-footer">
  <div class="float-right">
    <button
      *ngIf="activeStep.id === EmailSendStep.Sending"
      [disabled]="!singleEmailForm?.valid || (loading$ | async)"
      class="button button--primary"
      type="button"
      (click)="sendAndGoToNextBucket()"
    >
      {{ 'MQA.EMAIL.SEND_AND_NEXT' | translate }}
    </button>
    <button
      *ngIf="activeStep.id === EmailSendStep.Sending"
      [disabled]="loading$ | async"
      class="button button--secondary"
      type="button"
      (click)="skipCurrentBucket()"
    >
      {{ 'MQA.EMAIL.SKIP' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="activeModal.dismiss()"
    >
      {{ (activeStep.id === EmailSendStep.Summary ? 'MODALS.CLOSE' : 'CANCEL') | translate }}
    </button>
  </div>
</div>
