<kendo-grid
  [kendoGridBinding]="units$ | async"
  [loading]="loading$ | async"
  [resizable]="true"
  [group]="gridGrouping"
>

  <kendo-grid-column
    field="category.scope"
    [hidden]="true"
    [editable]="false"
  >
    <div *kendoGridGroupHeaderTemplate="let group; let value = value">
      <strong>{{ value | griScopeName : true }}</strong>
    </div>
  </kendo-grid-column>

  <kendo-grid-column
    field="category.id"
    [hidden]="true"
    [editable]="false"
  >
    <span *kendoGridCellTemplate="let dataItem">
      {{ dataItem.category?.name }}
    </span>
    <ng-template
      kendoGridGroupHeaderTemplate
      let-group
    >
      <div *ngIf="group.items?.[0]?.category as category">
        <strong>{{ category.name | translate }}</strong>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="unit.id"
    [title]="'SUSTAINABILITY.GRI.UNIT_NAME' | translate"
    [editable]="false"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <div class="space-between">
        <gri-unit-name
          [unit]="dataItem.unit"
          class="overflow-dots"
        ></gri-unit-name>
        <button
          type="button"
          class="k-button-icon k-icon k-i-edit"
          [attr.title]="(dataItem.unit.isDefault
            ? 'SUSTAINABILITY.GRI.INSPECT_UNIT'
            : 'SUSTAINABILITY.GRI.EDIT_UNIT') | translate"
          (click)="openUnitEditModal(dataItem.unit)"
        ></button>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="unit.unit"
    [title]="'SUSTAINABILITY.GRI.UNIT' | translate"
    [editable]="false"
    [width]="100"
  ></kendo-grid-column>

  <kendo-grid-column
    field="unit.co2Factor"
    [title]="'SUSTAINABILITY.GRI.CO2FACTOR' | translate"
    [editable]="false"
    [width]="200"
    [format]="'#,#.##'"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <span>{{ dataItem.unit | griCo2factor }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="unit.source"
    [title]="'SUSTAINABILITY.GRI.UNIT_SOURCE' | translate"
    [editable]="false"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="rowsCount"
    [title]="'SUSTAINABILITY.GRI.ROW_COUNT' | translate"
    [editable]="false"
    [width]="100"
  ></kendo-grid-column>

  <kendo-grid-column
    [title]="'SUSTAINABILITY.GRI.TOTAL' | translate"
    field="totalValue"
    [format]="formatApprox"
    [editable]="false"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <strong *ngIf="dataItem.totalValue as totalValue">
        {{ totalValue | kendoNumber : formatApprox }} {{ dataItem.unit.unit }}
      </strong>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [title]="'tCO₂e/' + ('YEAR_ABBR' | translate)"
    field="co2total"
    [format]="formatApprox"
    [editable]="false"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <strong *ngIf="dataItem.co2total as co2total">
        {{ co2total | griCo2total : Co2eq.Tonnes : '#,#.0'}}
      </strong>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
