import { getAverageConsumption, getModelledConsumption, round } from '../../../shared/utils.functions';
import { CONSUMPTION_TYPE } from '../../../constants/consumption-types';

const $inject = ['utils', 'ManualQaConsumptionInputModalService'];

class ManualQaHourlyConsumptionCommand {
  constructor(utils, ManualQaConsumptionInputModalService) {
    this.utils = utils;
    this.ManualQaConsumptionInputModalService = ManualQaConsumptionInputModalService;
  }

  getCommand(controllerInstance) {
    const self = this;

    return {
      text: self.utils.localizedString('MQA.INSPECT.SHEET.MENU_ITEM.HOURLY_CONSUMPTION'),
      action: () => {
        const { dataSource, timeFrame } = controllerInstance;
        const infoTextParameters = { timeFrame: timeFrame.getAsString() };
        const parameters = {
          title: self.utils.localizedString('MQA.HOURLY_CONSUMPTION.TITLE'),
          infoText: self.utils.localizedString('MQA.HOURLY_CONSUMPTION.INFO_TEXT', infoTextParameters),
          label: self.utils.localizedString('MQA.HOURLY_CONSUMPTION.CONSUMPTION'),
          consumption: round(getAverageConsumption(dataSource.data(), timeFrame)),
          timeFrame: timeFrame
        };

        const modalInstance = self.ManualQaConsumptionInputModalService.getModal(parameters);

        modalInstance.result.then(handleModelling);

        function handleModelling(result) {
          const values = getModelledConsumption(result.consumption, CONSUMPTION_TYPE.HOURLY, controllerInstance);
          controllerInstance.onValueChange({ values });
        }
      }
    };
  }
}

ManualQaHourlyConsumptionCommand.$inject = $inject;

export default ManualQaHourlyConsumptionCommand;
