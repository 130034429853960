import { subMonths } from 'date-fns';

import { RequestResolution } from '@enerkey/clients/reporting';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingData, SerieChartOptions } from './reporting-data-service-base';
import { ReportingSeriesService } from './reporting-series.service';
import { getRequestDurationInMonths, getTrendSeries, monthsToAddToRequest } from './trend-report.functions';

export abstract class TrendReportBaseService {
  public constructor(
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  protected getChartOptions(params: ReportingSearchParams): SerieChartOptions {
    return {
      serieSettings: params.periods.map(() => ({
        serieType: 'column', lineOpacity: 0.85
      })),
    };
  }

  protected getModifiedParams(params: ReportingSearchParams): ReportingSearchParams {
    const monthsToGet = getRequestDurationInMonths(params);
    return new ReportingSearchParams({
      ...params.formValue,
      durationName: 'months',
      durationLength: monthsToGet,
      periods: [subMonths(params.formValue.periods[0], monthsToAddToRequest)]
    });
  }

  protected getTitlePeriods(params: ReportingSearchParams): { duration: Duration, periods: Date[] } {
    return {
      duration: params.duration,
      periods: params.periods
    };
  }

  protected handleResponse(
    ids: number[],
    measured: ReportingData[][],
    normalized: ReportingData[][],
    temperatures: ReportingData[],
    nationalCosts: ReportingData[][],
    meterBasedCosts: ReportingData[][],
    emissions: ReportingData[][],
    periods?: Date[]
  ): ReportingSeriesByFacility {
    // eslint-disable-next-line max-len
    [measured.flat(), normalized.flat(), nationalCosts.flat(), meterBasedCosts.flat(), emissions.flat()].forEach(dataOfType => {
      dataOfType.forEach(data => {
        for (const id of ids) {
          const newSeries = data.series[id]?.map(s => getTrendSeries(s));
          if (newSeries) {
            data.series[id] = newSeries;
          }
        }
      });
    });
    return this.reportingSeriesService.mapValuesById({
      ids,
      temperatures,
      measured: measured.flat(),
      normalized: normalized.flat(),
      nationalCosts: nationalCosts.flat(),
      meterBasedCosts: meterBasedCosts.flat(),
      emissions: emissions.flat(),
      periods: periods
    });
  }

  protected hasUnsupportedParams(params: ReportingSearchParams): boolean {
    const unsupportedDurations = ['days', 'weeks'];
    const isUnsupportedDuration = Object.keys(params.duration).some(k => unsupportedDurations.includes(k));
    return isUnsupportedDuration || params.resolution !== RequestResolution.P1M;
  }
}
