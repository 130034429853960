import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RequestStatus } from '@enerkey/clients/webhook';
import { EnumTranslatePipe } from '@enerkey/ek-common';

export const requestStatusTranslationKeys: Record<RequestStatus, string> = {
  [RequestStatus.Failed]: 'SUPERVISION.REQUEST_STATUS.FAILED',
  [RequestStatus.Running]: 'SUPERVISION.REQUEST_STATUS.RUNNING',
  [RequestStatus.Success]: 'SUPERVISION.REQUEST_STATUS.SUCCESS',
  [RequestStatus.Undefined]: 'SUPERVISION.REQUEST_STATUS.UNDEFINED',
};

@Pipe({ name: 'requestStatusName' })
export class RequestStatusNamePipe extends EnumTranslatePipe<RequestStatus> implements PipeTransform {
  protected override translations: Record<RequestStatus, string> = requestStatusTranslationKeys;

  public constructor(translateService: TranslateService) {
    super(translateService);
  }
}
