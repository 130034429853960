import { AfterViewInit, Component, Injector, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { forkJoin, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

import { ModalService } from '@enerkey/foundation-angular';
import {
  ConfigurationControlClient, MeterReaderInformation, ReadingStatus, TerminalStatus, TerminalStatusCode
} from '@enerkey/clients/configuration-control';
import {
  SimpleUserViewModel, UserManagementClient, UserViewModel
} from '@enerkey/clients/user-management';

import { DialogService } from '../../../../shared/services/dialog.service';
import TerminalControlGridItem from './terminal-control-grid-item';
import { TerminalStatusModalComponent }
  from '../../../../shared/terminal/terminal-status-modal/terminal-status-modal.component';
import { TerminalStatusEditModalComponent } from '../terminal-status-edit-modal/terminal-status-edit-modal.component';
import { Roles } from '../../../admin/constants/roles';
import { UserService } from '../../../../services/user-service';
import { MeterInterfaceService } from '../../../../services/meter-interface-service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';
import { AjsModalService } from '../../../../services/modal/modal.service';

// eslint-disable-next-line import/order
import ModalConfigurationsController from '../../controllers/modal-configurations-controller';
// eslint-disable-next-line import/order
import ModalConfigurationsTemplate from 'raw-loader!../../templates/modal-configurations.html';

type RowSelectKey = 'id';

@Component({
  selector: 'terminal-control',
  templateUrl: './terminal-control.component.html',
  providers: [KendoGridService]
})
export class TerminalControlComponent implements AfterViewInit {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public readonly terminals$: Observable<TerminalControlGridItem[]>;
  public readonly pageSize: number = 30;
  public showOnlyErrorStatuses: boolean = true;
  public selectSettings: SelectableSettings;
  public readonly selectKey: RowSelectKey = 'id';
  public selection: TerminalControlGridItem[RowSelectKey][] = [];
  public selectedTerminals: TerminalControlGridItem[] = [];
  public readonly isUserAdministrator: boolean;

  @ViewChild(GridComponent) private readonly kendoGrid: GridComponent;
  private readonly currentUser$: Observable<UserViewModel>;
  private readonly configurationManagers$: Observable<SimpleUserViewModel[]>;
  private inspectors: SimpleUserViewModel[];
  private refreshGrid$ = new ReplaySubject<void>(1);

  public constructor(
    private readonly configurationControlClient: ConfigurationControlClient,
    private readonly modalService: ModalService,
    private readonly modalServiceAjs: AjsModalService,
    private readonly dialogService: DialogService,
    private readonly injector: Injector,
    private readonly toasterService: ToasterService,
    private readonly translate: TranslateService,
    private readonly authenticationService: AuthenticationService,
    private readonly userService: UserService,
    private readonly meterInterfaceService: MeterInterfaceService,
    private readonly userManagementClient: UserManagementClient,
    private readonly gridService: KendoGridService<TerminalControlGridItem, RowSelectKey>
  ) {
    this.selectSettings = {
      checkboxOnly: true,
      enabled: true,
      mode: 'multiple'
    };
    this.currentUser$ = this.getUserObservable();
    this.configurationManagers$ = this.getConfigurationManagersObservable();
    this.isUserAdministrator = this.userService.hasRole(Roles.CONFIGURATION_MANAGER);
    this.terminals$ = this.getTerminalsObservable();
    this.refreshGrid$.next();
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.gridService.selection$.subscribe(keys => {
      this.selection = keys;
    });
  }

  public selectTerminalBy(context: RowArgs): TerminalControlGridItem {
    return context.dataItem;
  }

  public onShowOnlyErrorStatusesChanged(): void {
    this.showOnlyErrorStatuses = !this.showOnlyErrorStatuses;
    this.refreshGrid$.next();
  }

  public onSetInspector(): void {
    forkJoin([this.currentUser$, this.configurationManagers$, this.terminals$.pipe(take(1))])
      .subscribe(([user, managers, terminals]) => {
        const configurationManagers = managers
          ?.sortBy(cm => cm.name)
          ?.filter(inspector => !inspector.name.toLowerCase().includes('admin'));

        const selectedTerminals = terminals.filter(gridItem => this.selection.includes(gridItem.id));
        const modalInstance = this.modalService.open(TerminalStatusEditModalComponent);
        modalInstance.componentInstance.user = user;
        modalInstance.componentInstance.inspectors = configurationManagers;
        modalInstance.componentInstance.terminalGridItems = selectedTerminals;
        modalInstance.result.then(() => this.onRefreshGrid());
      });
  }

  public onResolveSelected(): void {
    this.dialogService.getConfirmationModalPromise({
      title: this.translate.instant('CONFIGURATION_CONTROL.TERMINAL.FAULT_RESOLVE'),
      text: this.translate.instant('CONFIGURATION_CONTROL.TERMINAL.FAULT_RESOLVE_INFO')
    }).then(() => {
      this.terminals$.pipe(take(1)).subscribe(terminals => {
        this.selectedTerminals = terminals.filter(gridItem => this.selection.includes(gridItem.id));
        this.selectedTerminals.forEach(item => {
          item.terminalStatus.inspectorUserId = 0;
          item.terminalStatus.statusDescription = '';
          item.terminalStatus.readingStatus = ReadingStatus.NotRun;
          item.terminalStatus.lastModified = new Date();
          item.terminalStatus.lastInspectorModified = item.terminalStatus.lastModified;
          item.terminalStatus.faultDate = null;
          this.configurationControlClient.updateTerminalStatus(item.terminalStatus)
            .subscribe(
              {
                next: () => this.onRefreshGrid(),
                error: () => this.toasterService.error('CONFIGURATION_CONTROL.TERMINAL.FAILED_TO_UPDATE_TERMINAL')
              }
            );
        });
      });
    });
  }

  public onRefreshGrid(): void {
    this.gridService.selectAllChange(false);
    this.refreshGrid$.next();
  }

  public onShowTerminalStatus(dataItem: TerminalControlGridItem): void {
    const modalInstance = this.modalService.open(TerminalStatusModalComponent, { injector: this.injector });
    modalInstance.componentInstance.terminal = dataItem.terminal;
  }

  public onGoToBucket(dataItem: TerminalControlGridItem): void {
    const meterIds = dataItem.terminalMeters.map(tm => tm.meterId);
    const terminalName = dataItem.terminal.name;
    this.configurationControlClient.getReaderInformationForMeters(meterIds)
      .subscribe(
        {
          next: (readerInformation: { [key: string]: MeterReaderInformation }) => {
            const first = readerInformation[meterIds[0]];
            return this.modalServiceAjs.open({
              template: ModalConfigurationsTemplate,
              windowClass: 'semiFull',
              controller: ModalConfigurationsController,
              controllerAs: 'vm',
              bindToController: true,
              resolve: {
                bucketId: () => first.readingGroupId,
                readerTypeId: () => first.readerTypeId,
                readerTypeName: () => first.readingGroupName,
                terminalName: () => terminalName,
              }
            });
          },
          error: () => {}
        }
      );
  }

  public onRemoveTerminal(dataItem: TerminalControlGridItem): void {
    this.dialogService.getConfirmationModalPromise({
      title: this.translate.instant('CONFIGURATION_CONTROL.TERMINAL.DELETE_TERMINAL'),
      text: this.translate.instant('CONFIGURATION_CONTROL.TERMINAL.DELETE_TERMINAL_INFO')
    }).then(() => {
      this.configurationControlClient.removeTerminal(dataItem.terminal.id)
        .subscribe(
          {
            next: () => { },
            error: () => this.toasterService.error('CONFIGURATION_CONTROL.TERMINAL.FAILED_TO_DELETE_TERMINAL')
          }
        );
    });
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    if ((element.nodeName === 'TD' || element.nodeName === 'TH')
            && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  private getInpectorName(inspectorUserId: number): string {
    if (inspectorUserId && inspectorUserId > 0) {
      const terminalInspector = this.inspectors.find(inspector => inspector.id === inspectorUserId);
      return terminalInspector ? terminalInspector.name : '';
    } else {
      return '';
    }
  }

  private getUserObservable(): Observable<UserViewModel> {
    const userId = this.authenticationService.getUserId();
    return this.userManagementClient.getUser(userId).pipe(shareReplay(1));
  }

  private getConfigurationManagersObservable(): Observable<SimpleUserViewModel[]> {
    const tenantId = this.authenticationService.tenantId;
    return this.userManagementClient.getUsernamesInCertainRole(tenantId, Roles.CONFIGURATION_MANAGER)
      .pipe(shareReplay(1));
  }

  private getTerminalsObservable(): Observable<TerminalControlGridItem[]> {
    return this.refreshGrid$.pipe(
      switchMap(
        () => {
          const request = this.showOnlyErrorStatuses ?
            this.configurationControlClient.getTerminalStatusesByStatusCode(TerminalStatusCode.Error) :
            this.configurationControlClient.getTerminalStatuses();
          return request;
        }
      ),
      switchMap(response => {
        const inspectorIds = response.map(terminalStatus => terminalStatus.inspectorUserId);
        const terminalIds = response.map(terminalStatus => terminalStatus.terminalId);
        return forkJoin(
          this.isUserAdministrator ?
            this.userManagementClient.getUsernamesByIds(inspectorIds).pipe(
              catchError(() => {
                this.toasterService.error('CONFIGURATION_CONTROL.TERMINAL.FAILED_TO_LOAD_USERS');
                return of([]);
              })
            )
            :
            of([])
          ,
          this.configurationControlClient.getTerminalsById(terminalIds),
          of(response)
        );
      }),
      map(([inspectors, terminals, terminalStatuses]) => {
        this.inspectors = inspectors;
        let gridItems: TerminalControlGridItem[] = [];
        terminals.map(terminal => {
          const terminalStatus: TerminalStatus = terminalStatuses.find(status => status.terminalId === terminal.id);
          const gridItem = new TerminalControlGridItem(
            terminal,
            terminalStatus,
            this.getInpectorName(terminalStatus?.inspectorUserId),
            this.getReadingStatusTextLocalized(terminalStatus?.readingStatus),
            this.getReadingStatusTextIndicator(terminalStatus?.readingStatus)
          );
          gridItems.push(gridItem);
        });
        gridItems = gridItems.sortBy(item => item.terminalStatusLastModified, 'desc');
        return gridItems;
      }),
      shareReplay(1),
      catchError(() => {
        this.toasterService.error('CONFIGURATION_CONTROL.TERMINAL.DATA_FETCH_FAILED');
        return of([]);
      })
    );
  }

  private getReadingStatusTextLocalized(readingStatus: ReadingStatus): string {
    const statusText = this.meterInterfaceService.getReadingStatusText(readingStatus);
    return statusText;
  }

  private getReadingStatusTextIndicator(readingStatus: ReadingStatus): string {
    const statusIndicator = 'indicator indicator--' +
      `${this.meterInterfaceService.getReadingStatusIndicator(readingStatus)}-text`;
    return statusIndicator;
  }
}
