<ng-container *ngIf="profiles$ | async as profiles">
  <ul
    class="profileList"
    *ngIf="profiles.length"
  >
    <li *ngFor="let profile of profiles">
      <!-- • -->
      &#8226; {{ profile.name }}
      <button
        type="button"
        class="button button--link button--negative button--no-padding float-right"
        (click)="removeProfile(profile.id)"
      >
        <i class="fa fa-times"></i>
      </button>
    </li>
  </ul>
</ng-container>
<profile-search
  [valuePrimitive]="false"
  [formControl]="profileControl"
  [excludedItems]="excludedProfiles$ | async"
></profile-search>
