const $inject = ['$modalInstance', 'modalParams'];

class ModalWrapperController {
  constructor($modalInstance, modalParams) {
    this.modalParams = modalParams;
    this.modalDismiss = $modalInstance.dismiss;
    this.modalClose = $modalInstance.close;
  }
}

ModalWrapperController.$inject = $inject;

export default ModalWrapperController;
