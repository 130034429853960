export const CHART_SELECTOR = '#chart';

export const VALUE_AXIS = {
  FACTOR: 1.25, // Used to add 25% to maximum and minimum chart values.
  CUMULATIVE: 0,
  CONSUMPTION: 1
};

export const AXIS = {
  CONSUMPTION: 'ConsumptionAxis',
  CUMULATIVE: 'CumulativeAxis',
  HOUR: 'HourAxis',
  DATE: 'DateAxis'
};

export const NAVIGATOR = {
  EXPAND_AMOUNT: 12,
  EXPAND_UNIT: 'hours'
};

/**
 * Hides markers when view exceeds given number of data points.
 *
 * @type {number}
 */
export const HIDE_MARKERS_AFTER_DATA_POINTS = 200;

/**
 * Hides hour category axis when view exceeds given number of days
 *
 * @type {number}
 */
export const CATEGORY_AXIS_HOUR_HIDE_AFTER_DAYS = 60;
