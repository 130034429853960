import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AccordionComponent } from './accordion.component';
import { AccordionConfig } from './accordion.config';
import { AccordionPanelComponent } from './accordion-group.component';

import { CollapseModule } from '../collapse/collapse.module';

@NgModule({
  imports: [CommonModule, CollapseModule],
  declarations: [AccordionComponent, AccordionPanelComponent],
  exports: [AccordionComponent, AccordionPanelComponent],
  providers: [AccordionConfig]
})
export class AccordionModule {
  public static forRoot(): ModuleWithProviders<AccordionModule> {
    return { ngModule: AccordionModule, providers: [AccordionConfig] };
  }
}
