export function guid(short?: boolean): string {
  if (short) {
    return s4() + s4();
  }

  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

// source: http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
function s4(): string {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

export function generatePassword(): string {
  let password = Math.random().toString(36).slice(-9);
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  password = upperCaseLetters[Math.round(Math.random() * (upperCaseLetters.length - 1))] + password;
  password = password + Math.floor((Math.random() * 10) + 1);

  return password;
}
