import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe } from '@progress/kendo-angular-intl';

import { Co2eq, IRowUnit } from '@enerkey/clients/sustainability';

export function getCo2EqName(eq: Co2eq): string {
  switch (eq) {
    case Co2eq.Grams:
      return 'gCO₂e';
    case Co2eq.Kilograms:
      return 'kgCO₂e';
    case Co2eq.Tonnes:
    default:
      return 'tCO₂e';
  }
}

@Pipe({
  name: 'griCo2factor'
})
export class GriCo2factorPipe implements PipeTransform {

  public constructor(
    private readonly numberPipe: NumberPipe
  ) { }

  public transform(unit: IRowUnit): string {
    if (!Number.isFinite(unit?.co2Factor)) {
      return '';
    }

    const unitText = getCo2EqName(unit.co2Eq);
    return `${this.numberPipe.transform(unit.co2Factor)} ${unitText} / ${unit.unit}`;
  }
}
