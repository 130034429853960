import { isEqual } from 'lodash';

import { MeterManagementMeter } from '@enerkey/clients/meter-management';
import { MeterUpdateWithProperties } from '@enerkey/clients/metering';

export class MeterUpdateProperties {
  public readonly updatedProperties: MeterUpdateWithProperties;
  private originalMeterProperties: MeterUpdateWithProperties;
  private saved: boolean;

  public constructor(meter: MeterManagementMeter) {
    this.saved = false;

    const updatedProperties = {
      id: meter.id,
      name: meter.name,
      customerMeterIdentifier: meter.customerMeterIdentifier,
      description: meter.description,
      usagePlaceNumber: meter.usagePlaceNumber,
      energyCompanyUsagePlaceNumber: meter.energyCompanyUsagePlaceNumber,
      eanCode: meter.eanCode,
      protocolCode: meter.protocolCode,
      resolution: meter.resolution,
      facilityId: meter.facilityId,
      meteringType: meter.meteringType as number,
      quantityId: meter.quantityId,
      factor: meter.factor,
      twoTimeMeasurement: meter.twoTimeMeasurement,
      costFactorSourceMeterId: meter.costFactorSourceMeterId,
      automaticReadingStartTime: meter.automaticReadingStartTime,
      automaticReadingEndTime: meter.automaticReadingEndTime,
      deactivationTime: meter.deactivationTime,
      linkMeterFixedCosts: meter.linkMeterFixedCosts,
      costMeter: meter.costMeter,
      qualityAssurance: meter.qualityAssurance,
      automaticModeling: meter.automaticModeling
    };
    this.updatedProperties = new MeterUpdateWithProperties(updatedProperties);
    this.originalMeterProperties = new MeterUpdateWithProperties(updatedProperties);
  }

  public get hasChanges(): boolean {
    return !isEqual(this.updatedProperties, this.originalMeterProperties);
  }

  public hasSaved(): boolean {
    return this.saved;
  }

  public reinstateProperties(): void {
    this.saved = true;
    this.originalMeterProperties = new MeterUpdateWithProperties(this.updatedProperties);
  }
}
