import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigurationControlClient } from '@enerkey/clients/configuration-control';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ClipboardService } from '../../../shared/services/clipboard.service';
import { ToasterService } from '../../../shared/services/toaster.service';

@Component({
  selector: 'show-password-modal',
  templateUrl: './show-password-modal.component.html',
  styleUrls: ['./show-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  size: 'tiny'
})
export class ShowPasswordModalComponent extends ModalBase implements OnInit, OnDestroy {
  public configurationId: number;
  public password$: Observable<string>;
  public readonly loading$: Observable<boolean>;
  private readonly _loading = new LoadingSubject(true);

  public constructor(
    ngfActiveModal: NgfActiveModal,
    public readonly configurationControlClient: ConfigurationControlClient,
    public readonly clipboardService: ClipboardService,
    public readonly toasterService: ToasterService
  ) {
    super(ngfActiveModal);
    this.loading$ = this._loading.asObservable();
  }

  public ngOnInit(): void {
    this.password$ = this.configurationControlClient.getPassword(this.configurationId).pipe(
      indicate(this._loading)
    );
  }

  public copyToClipboard(password: string): void {
    this.clipboardService.copy(password);
    this.toasterService.info('CONFIGURATION_CONTROL.INFO_TEXT_COPIED_TO_CLIPBOARD');
  }

  public ngOnDestroy(): void {
    this._loading.complete();
  }
}
