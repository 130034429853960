import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import TerminalControlGridItem from '../terminal-control/terminal-control-grid-item';
import { ConfigurationControlClient } from '@enerkey/clients/configuration-control';
import { ISimpleUserViewModel, IUserViewModel } from '@enerkey/clients/user-management';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { ModalBase } from '@enerkey/foundation-angular';

@Component({
  selector: 'terminal-status-edit-modal',
  templateUrl: './terminal-status-edit-modal.component.html',
  styleUrls: ['./terminal-status-edit-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TerminalStatusEditModalComponent extends ModalBase implements OnInit {
  @Input() public user: IUserViewModel;
  @Input() public inspectors: ISimpleUserViewModel[];
  @Input() public terminalGridItems: TerminalControlGridItem[]

  public titleText: string;
  public inspector: ISimpleUserViewModel;
  public inspectorId: number;
  public inspectionDescription: string;
  public inspectorFilter: ISimpleUserViewModel[];
  public inspectorModified = new Date();

  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public constructor(
    activeModal: NgfActiveModal,
    private configurationControlClient: ConfigurationControlClient
  ) {
    super(activeModal);
  }

  public ngOnInit(): void {
    this.inspector = this.user;
    this.inspectorId = this.user.id;
    this.inspectorFilter = this.inspectors;
  }

  public onSave(): void {
    this.terminalGridItems.forEach(item => {
      item.terminalStatus.inspectorUserId = this.inspectorId;
      item.terminalStatus.statusDescription = this.inspectionDescription;
      item.terminalStatus.lastInspectorModified = this.inspectorModified;
    });
    const requests = this.terminalGridItems.map(item =>
      this.configurationControlClient.updateTerminalStatus(item.terminalStatus));
    forkJoin(requests).subscribe(() => this.closeModal());
  }
}

