import _ from 'lodash';

const $inject = ['$state', 'utils', 'erReportSettingsService'];

function FacilitiesSidebarMetertreeController($state, utils, erReportSettingsService) {
  const vm = this;

  const erReportSettings = erReportSettingsService.getInstance();

  vm.data = {
    facilityId: erReportSettings.getSettings().facilityId[0],
    meterId: _.cloneDeep(getMeterId())
  };

  function getMeterId() {
    return erReportSettings.getSettings().meterId || [];
  }

  function setMeterId(newValue) {
    erReportSettings.changeSetting('meterId', newValue);
  }

  utils.efficientWatch('meterId', vm.data, function(newValue) {
    setMeterId(newValue);
  });
}

FacilitiesSidebarMetertreeController.$inject = $inject;

export default FacilitiesSidebarMetertreeController;
