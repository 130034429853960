import ManualQaChangeMeterController from './manual-qa-change-meter-controller';
import template from 'raw-loader!./manual-qa-change-meter.html';

export default {
  template: template,
  controller: ManualQaChangeMeterController,
  controllerAs: 'vm',
  bindings: {
    meter: '<',
    timeStamp: '<',
    effectPeriod: '<',
    onSave: '&',
    onClose: '&'
  }
};
