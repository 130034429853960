<div
  class="reporting-sidebar"
  [ngClass]="{ 'isModal': isModal }"
  [@hostSize]="hostSizeAnimation"
>
  <button
    [attr.title]="((isCollapsed$ | async) ? 'SIDEBAR.SHOW' : 'SIDEBAR.HIDE') | translate"
    (click)="toggleSidebar()"
    class="button"
  >
    <i
      class="fa"
      [ngClass]="(isCollapsed$ | async) ? 'fa-angle-double-right' : 'fa-angle-double-left'"
    ></i>
  </button>
  <reporting-search-form
    [isMeterReport]="isMeterReport"
    [isModal]="isModal"
    class="reporting-sidebar-container"
    *ngIf="!(isCollapsed$ | async)"
    @fadeInOut
  ></reporting-search-form>
</div>
<div
  *ngIf="!(isCollapsed$ | async)"
  class="reporting-sidebar-overlay"
  @fadeInOut
  (click)="toggleSidebar()"
></div>
