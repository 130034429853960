import _ from 'lodash';

const $inject = ['utils', 'ERUtils', 'ERDataFunctions', 'ERGridService', 'ERChartService'];

function ERDataService2(utils, ERUtils, ERDataFunctions, ERGridService, ERChartService) {
  function getVisuals(options) {
    const result = {};
    const extendedOptions = getOptions(options);

    if (hasRequestsAndResponses(extendedOptions)) {
      if (_.has(options, 'chart')) {
        result.charts = ERChartService.getCharts(extendedOptions);
      }
      if (_.has(options, 'grid')) {
        result.grids = ERGridService.getGrids(extendedOptions);
      }
      popUpErrors(extendedOptions);
    } else if (_.get(options, 'params.quantityId', []).length) {
      utils.popUp('info', utils.localizedString('FACILITIES_REPORT.NO_DATA'));
    }

    return result;
  }

  function hasRequestsAndResponses(options) {
    let result = true;
    _.each(_.get(options, 'api'), function(api) {
      if (!(_.get(api, 'requestData') && _.get(api, 'responseData'))) {
        result = false;
        return false;
      }
    });
    return result;
  }

  function getOptions(options) {
    const optionsToExtend = _.pick(options, ['params', 'series', 'api', 'cache']);
    const optionsToMerge = _.omit(options, ['params', 'series', 'api', 'cache']);

    const mergedOptions = _.merge(getDefaultOptions(), optionsToMerge);
    _.extend(mergedOptions, optionsToExtend);
    mergedOptions.api = (_.isArray(options.api) ? options.api : [options.api]) || [];

    return mergedOptions;
  }

  function getDefaultOptions() {
    return {
      idType: 'Quantity',
      params: {},
      series: {},
      cache: {
        appStatus: {},
        facilitiesById: {},
        metersById: {},
        quantitiesById: {},
        relationalValuesById: {},
        distributionTypesById: {},
        relatedValuesByQuantityId: {},
        errorCodesByCode: {},
        forecastTypesById: {}
      },
      api: {
        requestData: {},
        responseData: {},
        savingsResponseData: {}
      },
      show: {},
      chart: {
        getCurrentChart: function() {
          return {};
        },
        serieClick: {
          enabled: false,
          getParams: function() {
            return {};
          }
        }
      },
      grid: {
        getCurrentGrid: function() {
          return {};
        },
        aggregates: [],
        excel: {
          getTitle: function() {
            return '';
          }
        },
        paging: true,
        removeEmptyColumns: true
      }
    };
  }

  function popUpErrors(options) {
    const errors = [];

    function addErrors(errorCodes) {
      _.each(errorCodes, function(errorCode) {
        const error = _.find(errors, { errorCode: errorCode });
        if (error) {
          error.count++;
        } else {
          errors.push({
            errorCode: errorCode,
            message: _.get(options.cache.errorCodesByCode[errorCode], 'Message'),
            count: 1
          });
        }
      });
    }

    _.each(_.get(options, 'api'), function(api) {
      switch (options.idType) {
        case "Facility":
          _.each(ERDataFunctions.getFacilityIds(options), function(facilityId) {
            addErrors(_.get(api, 'responseData.' + facilityId + '.ErrorCodes'));
          });
          break;
        case "Meter":
          _.each(ERDataFunctions.getMeterIds(options), function(meterId) {
            addErrors(_.get(api, 'responseData.' + meterId + '.ErrorCodes'));
          });
          break;
        case "Quantity":
          _.each(ERDataFunctions.getQuantityIds(options), function(quantityId) {
            addErrors(_.get(api, 'responseData.' + quantityId + '.ErrorCodes'));
          });
          break;
        default:
          break;
      }
    });

    _.each(errors, function(error) {
      if (error.message) {
        utils.popUp('info', error.message + (error.count > 1 ? ' (' + error.count + ')' : ''));
      }
    });
  }

  function getLocalizedStringsForExcel(payload) {
    const localizedStrings = {
      FOOTER_ROW_HEADER_SUM: utils.localizedString('FACILITIES_REPORT.SUM'),
      FOOTER_ROW_HEADER_MIN: utils.localizedString('FACILITIES_REPORT.MIN'),
      FOOTER_ROW_HEADER_MAX: utils.localizedString('FACILITIES_REPORT.MAX'),
      FOOTER_ROW_HEADER_AVG: utils.localizedString('FACILITIES_REPORT.AVERAGE'),
      METER_DESCRIPTION: utils.localizedString('FACILITIES_REPORT.DESCRIPTION'),
      METER_TYPE: utils.localizedString('FACILITIES_REPORT.METERINGTYPE'),
      METER_FACTOR: utils.localizedString('FACILITIES_REPORT.METERINGFACTOR'),
      METER_CUSTOMERMETERIDENTIFIER: utils.localizedString('FACILITIES_REPORT.CUSTOMERMETERIDENTIFIER'),
      METER_METHOD_ONE_TIME: utils.localizedString('METERS.MEASUREMENT_METHOD.ONE_TIME'),
      METER_METHOD_TWO_TIME: utils.localizedString('METERS.MEASUREMENT_METHOD.TWO_TIME'),
      METER_METHOD_TITLE: utils.localizedString('METERS.MEASUREMENT_METHOD.TITLE')
    };

    if (_.isObject(payload) && payload.Resolution) {
      localizedStrings.RESOLUTION = ERUtils.resolutionTitle(payload.Resolution);
    }

    return localizedStrings;
  }

  return {
    getVisuals: getVisuals,
    getLocalizedStringsForExcel: getLocalizedStringsForExcel
  };
}

ERDataService2.$inject = $inject;

export default ERDataService2;
