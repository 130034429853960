import ServicesModule from '../../services';
import LinkUnlinkModule from '../link-unlink';
import TimeFrameSelectComponent from './time-frame-select-component';

const dependencies = [
  ServicesModule.name,
  LinkUnlinkModule.name
];

const TimeFrameSelectModule = angular
  .module('app.components.time-frame-select', dependencies)
  .component('timeFrameSelect', TimeFrameSelectComponent);

export default TimeFrameSelectModule;
