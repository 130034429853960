import { IFacilityClient } from './facility-client';

export class FacilityClientMock implements IFacilityClient {
  public refreshAddressLocation(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityIdsByLocaleId(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityBasicInfo(): never {
    throw new Error('Method not implemented.');
  }
  public getAddresses(): never {
    throw new Error('Method not implemented.');
  }
  public addAddress(): never {
    throw new Error('Method not implemented.');
  }
  public updateAddress(): never {
    throw new Error('Method not implemented.');
  }
  public deleteAddress(): never {
    throw new Error('Method not implemented.');
  }
  public getBuildingClass(): never {
    throw new Error('Method not implemented.');
  }
  public getBuildingClasses(): never {
    throw new Error('Method not implemented.');
  }
  public updateCustomBuildingClass(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityCustomBuildingClasses(): never {
    throw new Error('Method not implemented.');
  }
  public addCustomBuildingClass(): never {
    throw new Error('Method not implemented.');
  }
  public removeCustomBuildingClassFromFacility(): never {
    throw new Error('Method not implemented.');
  }
  public addFacilityToCustomerField(): never {
    throw new Error('Method not implemented.');
  }
  public updateCustomerFieldForFacility(): never {
    throw new Error('Method not implemented.');
  }
  public updateCustomerFieldForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCustomerFieldsFromFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCustomerFieldFromFacility(): never {
    throw new Error('Method not implemented.');
  }
  public getCustomerFieldsForFacility(): never {
    throw new Error('Method not implemented.');
  }
  public getCustomerFields(): never {
    throw new Error('Method not implemented.');
  }
  public createCustomerFields(): never {
    throw new Error('Method not implemented.');
  }
  public createCustomerFieldTextValues(): never {
    throw new Error('Method not implemented.');
  }
  public createTaxonomies(): never {
    throw new Error('Method not implemented.');
  }
  public createTaxonomyValues(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesByExternalIds(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesExternalIds(): never {
    throw new Error('Method not implemented.');
  }
  public addOrUpdateExternalId(): never {
    throw new Error('Method not implemented.');
  }
  public deleteExternalIds(): never {
    throw new Error('Method not implemented.');
  }
  public searchFacilitiesUsingEnegiaIds(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesUsingCompanyids(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesSpecificCustomerIdentifiers(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityIdsForEnegiaIds(): never {
    throw new Error('Method not implemented.');
  }
  public createNewFacilityBulk(): never {
    throw new Error('Method not implemented.');
  }
  public updateFacility(): never {
    throw new Error('Method not implemented.');
  }
  public updateFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getProfileFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getAllFacilitiesWithoutLinkedProfile(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityIdsForProfileIds(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityIdsByNameContainsCaseInsensitive(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public getLocale(): never {
    throw new Error('Method not implemented.');
  }
  public getLocales(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesTimezone(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesLocale(): never {
    throw new Error('Method not implemented.');
  }
  public getProductIdsForAllFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityServiceLevels(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityDeletedState(): never {
    throw new Error('Method not implemented.');
  }
  public getProfile(): never {
    throw new Error('Method not implemented.');
  }
  public getProfiles(): never {
    throw new Error('Method not implemented.');
  }
  public syncTenantProfiles(): never {
    throw new Error('Method not implemented.');
  }
  public upsertProfiles(): never {
    throw new Error('Method not implemented.');
  }
  public createProfile(): never {
    throw new Error('Method not implemented.');
  }
  public updateProfile(): never {
    throw new Error('Method not implemented.');
  }
  public deleteProfile(): never {
    throw new Error('Method not implemented.');
  }
  public getProfileByName(): never {
    throw new Error('Method not implemented.');
  }
  public getProfilesBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public addFacilitiesForProfiles(): never {
    throw new Error('Method not implemented.');
  }
  public removeFacilitiesFromProfiles(): never {
    throw new Error('Method not implemented.');
  }
  public getProperty(): never {
    throw new Error('Method not implemented.');
  }
  public getProperties(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityProperties(): never {
    throw new Error('Method not implemented.');
  }
  public addFacilityProperties(): never {
    throw new Error('Method not implemented.');
  }
  public updateFacilityPropertiesValue(): never {
    throw new Error('Method not implemented.');
  }
  public deleteFacilityProperties(): never {
    throw new Error('Method not implemented.');
  }
  public deleteFacilityPropertyValues(): never {
    throw new Error('Method not implemented.');
  }
  public synchronize(): never {
    throw new Error('Method not implemented.');
  }
  public synchronizeFacilityProfiles(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityTags(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesTags(): never {
    throw new Error('Method not implemented.');
  }
  public addFacilityTags(): never {
    throw new Error('Method not implemented.');
  }
  public removeFacilityTags(): never {
    throw new Error('Method not implemented.');
  }
  public upsertTechnicalSpecifications(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityUrls(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesUrls(): never {
    throw new Error('Method not implemented.');
  }
  public addFacilityUrls(): never {
    throw new Error('Method not implemented.');
  }
  public removeFacilityUrls(): never {
    throw new Error('Method not implemented.');
  }
  public getUsagePlaces(): never {
    throw new Error('Method not implemented.');
  }
  public addUsagePlaces(): never {
    throw new Error('Method not implemented.');
  }
  public updateUsagePlaces(): never {
    throw new Error('Method not implemented.');
  }
  public deleteAllfacilityUsagePlaces(): never {
    throw new Error('Method not implemented.');
  }
  public deleteUsagePlace(): never {
    throw new Error('Method not implemented.');
  }
}
