import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PoaService } from '@enerkey/clients/metering';
import { EnumTranslatePipe } from '@enerkey/ek-common';

export const poaServiceTranslations: Record<PoaService, string> = {
  [PoaService.Undefined]: 'ADMIN.POA.SERVICE.NONE',
  [PoaService.FinnishDataHub]: 'ADMIN.POA.SERVICE.FINNISH',
  [PoaService.DanishDataHub]: 'ADMIN.POA.SERVICE.DANISH',
};

@Pipe({
  name: 'datahubServiceName'
})
export class DatahubServiceNamePipe extends EnumTranslatePipe<PoaService> implements PipeTransform {
  protected translations = poaServiceTranslations;

  public constructor(translateService: TranslateService) {
    super(translateService);
  }
}
