import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, map, Observable, ReplaySubject, switchMap, take } from 'rxjs';
import { addDays, startOfMonth, subMonths } from 'date-fns';

import { MeterManagementMeter } from '@enerkey/clients/meter-management';
import { AttachmentsClient, DocumentCompleteViewModel } from '@enerkey/clients/attachments';

import { ModalService } from '@enerkey/foundation-angular';

import { MeteringClient, MeterTagDTO } from '@enerkey/clients/metering';

import { MeterInterfaceService } from '../../../../services/meter-interface-service';
import { TimeFrame } from '../../../../services/time-frame-service';
import { UserService } from '../../../../services/user-service';
import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { Roles } from '../../../admin/constants/roles';
import { ManualQaInspectModalService } from '../../../manual-qa/components/manual-qa-inspect-modal/manual-qa-inspect-modal.service';
import SearchTypes from '../../../manual-qa/constants/search-types';
import { ReportPlainEvents } from '../../services/report-events.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportModalMetersService } from '../../services/report-modal-meters.service';
import { DocumentsPreviewComponent } from '../../../documents/components/documents-preview/documents-preview.component';
import { ReportModalService } from '../../services/report-modal.service';

@Component({
  selector: 'meter-report-header',
  templateUrl: './meter-report-header.component.html',
  styleUrls: ['./meter-report-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterReportHeaderComponent implements OnInit, OnDestroy {
  @Input() public meter: MeterManagementMeter;
  @Input() public events: ReportPlainEvents;
  @Input() public facility: ExtendedFacilityInformation;

  public readonly isMeterManager: boolean;
  public readonly isQaManager: boolean;
  public readonly meterMapImages$: Observable<DocumentCompleteViewModel[]>;
  public readonly meterInfoVisible$: Observable<boolean>;
  public readonly meterTags$: Observable<MeterTagDTO[]>;

  private readonly _meterId$ = new ReplaySubject<number>(1);

  public constructor(
    private readonly meterInterfaceService: MeterInterfaceService,
    private readonly manualQaInspectModalService: ManualQaInspectModalService,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly reportModalMetersService: ReportModalMetersService,
    private readonly modalService: ModalService,
    reportModalService: ReportModalService,
    attachmentsClient: AttachmentsClient,
    userService: UserService,
    meterClient: MeteringClient
  ) {
    this.isMeterManager = userService.hasRole(Roles.METER_MANAGER_USER);
    this.isQaManager = userService.hasRole(Roles.QUALITY_MANAGER);

    this.meterInfoVisible$ = this.reportingSearchService.meterInfoVisibility$;

    this.meterMapImages$ = forkJoin({
      meterMaps: this._meterId$.pipe(
        take(1),
        switchMap(meterId => attachmentsClient.getMetermap(userService.profileId, undefined, meterId))
      ),
      facilityMaps: reportModalService.facilityMeterMaps$.pipe(take(1))
    }).pipe(
      map(({ facilityMaps, meterMaps }) => Array.hasItems(meterMaps) ? meterMaps : facilityMaps)
    );

    this.meterTags$ = this._meterId$.pipe(
      take(1),
      switchMap(meterId => meterClient.getTagsForMeters([meterId]))
    );
  }

  public ngOnInit(): void {
    this._meterId$.next(this.meter.id);
  }

  public ngOnDestroy(): void {
    this._meterId$.complete();
  }

  public showMeterInterfaceStatus(): void {
    this.meterInterfaceService.getModal(
      this.facility.Name,
      this.meter.id,
      `${this.meter.name} (${this.meter.id})`
    );
  }

  public openMqaCurrentTime(): void {
    const timeFrame = new TimeFrame(
      startOfMonth(subMonths(new Date(), 1)),
      addDays(new Date(), 1)
    );
    this.openMqaInspectModal(timeFrame);
  }

  public openMqaSelectedTimeFrame(): void {
    this.reportingSearchService.searchParameters$.pipe(
      take(1),
      map(params => params.getInspectionPeriodInterval())
    ).subscribe({
      next: interval => {
        this.openMqaInspectModal(new TimeFrame(
          interval.start,
          interval.end
        ));
      }
    });
  }

  public showMeterImageMap(document: DocumentCompleteViewModel): void {
    const modal = this.modalService.open(DocumentsPreviewComponent);
    modal.componentInstance.selectedDocument = document;
  }

  private openMqaInspectModal(timeFrame: TimeFrame): void {
    this.getSelectedMeterIds().subscribe({
      next: meterIds => {
        this.manualQaInspectModalService.getInspectModal(
          [this.meter.id, ...meterIds].unique(),
          timeFrame,
          SearchTypes.STORAGE_TYPE.RAW,
          this.meter.id
        );
      }
    });
  }

  private getSelectedMeterIds(): Observable<number[]> {
    return this.reportModalMetersService.selectedMeters$.pipe(
      take(1),
      map(meters => meters.meterIds)
    );
  }
}
