import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import { SwaggerException } from '@enerkey/clients/metering';
import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';

import { ToasterService } from '../../../../../shared/services/toaster.service';
import { MeterGroup } from '../../../models/meter-groups.model';
import { MeterGroupsService } from '../../../services/meter-groups/meter-groups.service';

@Component({
  templateUrl: './meter-groups-create-modal.component.html',
  styleUrls: ['./meter-groups-create-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeterGroupsCreateModalComponent extends ModalBase implements OnInit, OnDestroy {
  public readonly loading$: Observable<boolean>;
  public readonly confirmDialogStatus$: Observable<boolean>;

  public readonly controls: ControlsOf<MeterGroup>;
  public readonly formGroup: UntypedFormGroup;

  public titleKey: string;
  public showConfirmDialog: boolean = false;

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();
  private readonly _confirmDialogStatus$ = new Subject<boolean>();

  private _isUpdate: boolean = false;

  public constructor(
    currentModal: NgfActiveModal,
    private readonly meterGroupsService: MeterGroupsService,
    private readonly toasterService: ToasterService,
    private readonly translateService: TranslateService
  ) {
    super(currentModal);

    this.loading$ = this._loading$.asObservable();
    this.confirmDialogStatus$ = this._confirmDialogStatus$.asObservable();

    this.controls = formControlsFrom<MeterGroup>(
      {
        id: undefined,
        name: undefined,
        description: undefined,
        quantityGroupId: undefined
      },
      { name: Validators.required }
    );

    this.formGroup = new UntypedFormGroup(this.controls);
  }

  public ngOnInit(): void {
    this._isUpdate = Number.isFinite(this.controls.id.value);
    this.titleKey = this.isUpdate ? 'ADMIN.METERGROUPS.EDIT_METER_GROUP' : 'ADMIN.METERGROUPS.NEW_METER_GROUP';

    this.confirmDialogStatus$.pipe(
      tap((shouldClose: boolean) => {
        shouldClose ? this.closeModal() : this.showConfirmDialog = false;
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public submit(): void {
    if (this.formGroup.invalid) {
      this.toasterService.error('ADMIN.METERGROUPS.INVALID_FORM_INPUT');
      return;
    }

    const meterGroup = this.formGroup.getRawValue();

    (this.isUpdate ?
      this.meterGroupsService.updateMeterGroup(meterGroup) :
      this.meterGroupsService.createMeterGroup(meterGroup)
    ).pipe(
      indicate(this._loading$),
      tap(() => {
        const successMessageKey = this.isUpdate ?
          'ADMIN.METERGROUPS.METER_GROUP_UPDATE_SUCCESS' :
          'ADMIN.METERGROUPS.METER_GROUP_CREATION_SUCCESS';

        this.toasterService.success(successMessageKey);
        this.closeModal();
      }),
      catchError((error: SwaggerException) => {
        const failureMessageKey = this.isUpdate ?
          'ADMIN.METERGROUPS.METER_GROUP_UPDATE_FAILURE' :
          'ADMIN.METERGROUPS.METER_GROUP_CREATION_FAILURE';

        this.toasterService.error(error.message, failureMessageKey);

        return EMPTY;
      })
    ).subscribe();
  }

  public onCloseModalClick(): void {
    this.formGroup.pristine ? this._confirmDialogStatus$.next(true) : this.showConfirmDialog = true;
  }

  public onConfirmDialogClick(isConfirmed: boolean): void {
    this._confirmDialogStatus$.next(isConfirmed);
  }

  private get isUpdate(): boolean {
    return this._isUpdate;
  }
}
