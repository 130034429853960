import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { indicate, LoadingSubject, ofVoid } from '@enerkey/rxjs';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { Facility, FacilityClient, TagList } from '@enerkey/clients/facility';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { FACILITY_TAG_REGEX, getChangedFacilityTags } from '../../../../constants/facility.constant';
import { FacilityService } from '../../../../shared/services/facility.service';

@Component({
  templateUrl: './facility-tag-edit-modal.component.html',
  styleUrls: ['./facility-tag-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ size: 'tiny' })
export class FacilityTagEditModalComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public selectedFacility: Facility;

  public addedTags: string;
  public removedTags: string;

  public readonly tags: ComboItem<string>[] = [];
  public readonly tagEditForm: UntypedFormGroup;
  public readonly tagEditControl: UntypedFormControl;
  public readonly newTagField: UntypedFormControl = new UntypedFormControl('', Validators.pattern(FACILITY_TAG_REGEX));
  public readonly loading$: Observable<boolean>;

  private readonly oldTags: string[] = [];
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly facilityService: FacilityService,
    private readonly toasterService: ToasterService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.tagEditControl = new UntypedFormControl([]);
    this.tagEditForm = new UntypedFormGroup({ tags: this.tagEditControl });
  }

  public ngOnInit(): void {
    this.tagEditControl.valueChanges.subscribe((value: string[]) => {
      if (this.oldTags && value) {
        const changes = getChangedFacilityTags(this.oldTags, value);

        this.addedTags = changes.added.length
          ? changes.added.sort().join(', ')
          : null;
        this.removedTags = changes.removed.length
          ? changes.removed.sort().join(', ')
          : null;
      }
    });

    forkJoin([
      this.facilityService.profileFacilities$.pipe(take(1)),
      this.facilityClient.getFacilityTags(this.selectedFacility.id)
    ]).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: ([profileFacilities, currentTags]) => {
        const facilityTags: Record<number, string[]> = {};

        for (const facility of profileFacilities) {
          facilityTags[facility.facilityId] = Object.entries(facility.Tags).filterMap(
            ([_, hasTag]) => hasTag,
            ([tagName, _]) => tagName
          );
        }

        // If facility isn't for current profile, don't show any suggestions
        const allTags = profileFacilities.some(f => f.facilityId === this.selectedFacility.id)
          ? Object.values(facilityTags).flat().unique()
          : [];

        this.oldTags.push(...currentTags);
        this.tagEditForm.patchValue({ tags: currentTags });

        this.tags.push(...[...allTags, ...currentTags].unique().sort().map(tag => ({
          value: tag,
          text: tag
        })));
      }
    });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public addNewTag(): void {
    const tag = this.newTagField.value?.trim();
    if (this.newTagField.valid && tag.length) {
      const newTags: string[] = this.tagEditForm.value.tags;
      newTags.push(tag);
      this.tags.push({
        value: tag,
        text: tag
      });
      this.tagEditForm.patchValue({
        tags: newTags
      });
      this.newTagField.reset();
    }
  }

  public submit(): void {
    const changes = getChangedFacilityTags(this.oldTags, this.tagEditForm.value.tags);

    if (!changes.anyChanges) {
      this.toasterService.info('TAGS.EDIT.NOCHANGES');
      super.dismissModal(); // dismiss to avoid grid reloading
      return;
    }

    const facilityIds = [this.selectedFacility.id];

    const addRequest = Array.hasItems(changes.added)
      ? this.facilityClient.addFacilityTags(new TagList({ facilityIds, tags: changes.added }))
      : ofVoid();

    const removeRequest = Array.hasItems(changes.removed)
      ? this.facilityClient.removeFacilityTags(new TagList({ facilityIds, tags: changes.removed }))
      : ofVoid();

    forkJoin([addRequest, removeRequest]).subscribe({
      next: () => {
        this.toasterService.success('TAGS.EDIT.SUCCESS');
        super.closeModal();
      },
      error: () => this.toasterService.error('TAGS.EDIT.ERROR')
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
