import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';

import { Meter } from '@enerkey/clients/metering';

@Directive()
export abstract class AddMetersGrid<T extends Meter> {
  @Input() public meters: T[] = [];
  @Input() public selectedMeterIds: number[] = [];
  @Output() public readonly selectionChange = new EventEmitter<number[]>();

  public pageSize = 20;
  public height = 650;
  public selectByProperty: keyof T = 'id';

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple'
  };

  public onSelect(): void {
    this.selectionChange.emit(this.selectedMeterIds);
  }
}
