import { Injectable, OnDestroy } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';

import {
  ConsumptionsRequest,
  ConsumptionsRequestQuantity,
  EnergyReportingClient,
  QuantityItem,
} from '@enerkey/clients/energy-reporting';
import { Quantities } from '@enerkey/clients/metering';

import { QuantityService } from '../../../shared/services/quantity.service';
import { ConsumptionsWidgetOptions } from '../components/consumptions-widget/consumptions-widget.component';
import { getValueTypeOptions } from '../../energy-reporting/shared/value-type-options';
import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { Consumptions, QuantityChartData } from '../../../shared/energy-reporting-shared/shared/consumptions';
import { WidgetQueueService } from './widget-queue.service';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable()
export class ConsumptionsWidgetService implements OnDestroy {

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly erClient: EnergyReportingClient,
    private readonly quantityService: QuantityService,
    private readonly widgetQueue: WidgetQueueService
  ) { }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getData(
    dataModelOptions: ConsumptionsWidgetOptions,
    start: TimeFrameResult,
    facilityIds: number[],
    threshold: number
  ): Observable<QuantityChartData[]> {
    return this.quantityService.getProfileQuantities().pipe(
      switchMap(quantities => {
        const quantityMap = quantities.toMapBy(q => q.ID);
        return forkJoin([
          of(quantityMap),
          of(quantities),
          this.erClient.postQuantitys(
            this.getRequestParams(dataModelOptions, start, facilityIds, quantityMap, threshold)
          ).pipe(this.widgetQueue.queue('energyreporting', this._destroy$))
        ]);
      }),
      map(([quantityMap, quantities, response]) => {
        const valueOptions = getValueTypeOptions(dataModelOptions.valueOption);
        return quantities.mapFilter(q => q.ID, id => dataModelOptions.selectedQuantities.includes(id)).reduce(
          (chartData, quantityId) => {
            const quantityData = response[quantityId];
            if (!quantityData) {
              return chartData;
            }
            const isNormalizedQuantity = quantityMap.get(quantityId).Normalization;
            if (valueOptions.measured || !isNormalizedQuantity) {
              chartData.push(Consumptions.getWidgetData(
                response[quantityId], quantityId, 'Reading', start.Start, [dataModelOptions.variableId]
              ));
            }
            if (valueOptions.normalized && isNormalizedQuantity) {
              chartData.push(Consumptions.getWidgetData(
                response[quantityId], quantityId, 'NormalizedReading', start.Start, [dataModelOptions.variableId]
              ));
            }
            return chartData;
          },
          []
        );
      })
    );
  }

  private getRequestParams(
    dataModelOptions: ConsumptionsWidgetOptions,
    start: TimeFrameResult,
    facilityIds: number[],
    quantities: Map<Quantities, QuantityItem>,
    thresholdForIncomplete: number
  ): ConsumptionsRequest {
    const isRelationalValue = !!dataModelOptions.variableId;
    const valueProperties = getValueTypeOptions(dataModelOptions.valueOption);

    const requestQuantities = dataModelOptions.selectedQuantities.filter(
      quantity => quantities.has(quantity)
    ).map<ConsumptionsRequestQuantity>(quantityId => ({
      Id: quantityId,
      Comparables: dataModelOptions.comparableOption,
      Normalisation: valueProperties.normalized && quantities.get(quantityId).Normalization,
      RelationalUnitIds: isRelationalValue ? [dataModelOptions.variableId] : []
    }));

    return {
      AggregateType: 'sum' as any,
      FacilityId: Array.hasItems(facilityIds) ? facilityIds : [],
      Start: start.Start as any,
      Quantities: requestQuantities,
      Unit: dataModelOptions.unitKey as any,
      ThresholdForIncomplete: thresholdForIncomplete,
      TimeFrame: start.TimeFrame,
      Resolution: start.Resolution as any
    };
  }
}
