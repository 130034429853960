import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { startOfMonth } from 'date-fns';
import { UntypedFormControl } from '@angular/forms';
import { StateService } from '@uirouter/core';

import { Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { WidgetBase } from '../../shared/widget-base.interface';
import { Comparability } from '../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { TimeFrameOptions } from '../../../../constants/time-frame';
import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { ErTimeFrameService, TimeFrameResult } from '../../../energy-reporting/services/er-time-frame.service';

import { PeriodReportService } from '../../../reporting/services/period-report.service';
import { ReportingSearchParams } from '../../../reporting/shared/reporting-search-params';
import { getDefaultReportingParams } from '../../../reporting/services/reporting.search.functions';
import { ReportingSeriesCollection } from '../../../reporting/shared/reporting-series-collection';
import { ReportingSearchService } from '../../../reporting/services/reporting-search.service';
import { DurationName } from '../../../reporting/shared/reporting-search-form-value';
import { SelectableResolution } from '../../../reporting/components/reporting-search-form/reporting-search-form.component';
import { ReportSeriesDataPoint } from '../../../reporting/shared/reporting-series';

export interface ConsumptionsByFacilityWidgetOptions {
  comparableOption: Comparability;
  comparisonPeriodOption: 'Default' | number;
  selectedQuantities: Quantities[];
  timeFrameOption: TimeFrameOptions;
  unitKey: ReportingUnit;
  valueOption: ValueType;
  variableId: number;
  facilityId: number;
}

@Component({
  selector: 'consumptions-by-facility-widget',
  templateUrl: './consumptions-by-facility-widget.component.html',
  styleUrls: ['./consumptions-by-facility-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReportingSearchService]
})
// eslint-disable-next-line max-len
export class ConsumptionsByFacilityWidgetComponent implements WidgetBase<ConsumptionsByFacilityWidgetOptions>, OnDestroy, OnInit {
  public dataModelOptions: ConsumptionsByFacilityWidgetOptions;
  public readonly dataModelChange$: Observable<ConsumptionsByFacilityWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;
  public inspectionPeriodTitle: string;
  public comparisonPeriodTitle: string;
  public start: TimeFrameResult;
  public params$: Observable<ReportingSearchParams>;
  public data$: Observable<ReportingSeriesCollection[]>;
  public quantityValues: ReportingSeriesCollection[] = [];
  public facilityIdControl: UntypedFormControl;

  private readonly _dataModelChange = new Subject<ConsumptionsByFacilityWidgetOptions>();
  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly erTimeFrameService: ErTimeFrameService,
    private readonly periodReportService: PeriodReportService,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly stateService: StateService
  ) {

    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();
    this.dataModelChange$ = this._dataModelChange.asObservable();
  }

  public ngOnInit(): void {
    this.facilityIdControl = new UntypedFormControl(this.dataModelOptions.facilityId);

    this.start = this.erTimeFrameService.getTimeFrameAndResParams(
      this.dataModelOptions.timeFrameOption,
      this.dataModelOptions.comparisonPeriodOption as string
    );

    this.comparisonPeriodTitle = this.start.Start[1]?.key;
    this.inspectionPeriodTitle = this.start.Start[0].key;

    this.facilityIdControl.valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((formControlValue: number) => {
        this.dataModelOptions.facilityId = formControlValue;
        this._dataModelChange.next({
          ...this.dataModelOptions
        });
      });

    this.params$ = this.dataModelChange$.pipe(
      startWith(this.dataModelOptions),
      switchMap(_ => of(this.getParams()))
    );

    this.data$ = this.dataModelChange$.pipe(
      startWith(this.dataModelOptions),
      switchMap(_ => this.getConsumptionData())
    );

  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._error$.complete();
    this._loading$.complete();
  }

  public trackByMethod(_index: number, quantity: ReportingSeriesCollection): number {
    return quantity.quantityId;
  }

  public openFacilityReport(quantity: ReportSeriesDataPoint): void {
    const series = {
      Measured: !quantity.isNormalized,
      Normalized: quantity.isNormalized,
      RelationalUnitIds: this.dataModelOptions?.variableId ? [this.dataModelOptions?.variableId] : [],
      Comparables: this.dataModelOptions.comparableOption,
      TimeFrame: this.start.TimeFrame,
      Resolution: this.start.Resolution,
      Start: this.start.Start
    };

    this.stateService.go('facilities.report', {
      quantityId: quantity.quantityId,
      facilityId: [this.dataModelOptions.facilityId],
      series: series,
      unitKey: this.dataModelOptions.unitKey
    });

  }

  private getParams(): ReportingSearchParams {
    return new ReportingSearchParams({
      ...this.reportingSearchService.getDefaultParams(),
      quantityIds: this.dataModelOptions.selectedQuantities,
      comparability: this.dataModelOptions.comparableOption,
      valueType: this.dataModelOptions.valueOption,
      reportingUnit: this.dataModelOptions.unitKey,
      specificIds: this.dataModelOptions.variableId === 0 ? [] : [this.dataModelOptions.variableId],
      periods: this.start.Start.map(start => startOfMonth(new Date(start.value))),
      durationName: Object.keys(this.start.Duration)[0] as DurationName,
      durationLength: Object.values(this.start.Duration)[0],
      resolution: this.start.Resolution as SelectableResolution
    });
  }

  private getConsumptionData(): Observable<ReportingSeriesCollection[]> {
    if (!this.dataModelOptions.facilityId) {
      return of([]).pipe(indicate(this._loading$));
    }
    return this.periodReportService.getData(
      new ReportingSearchParams({
        ...getDefaultReportingParams(),
        quantityIds: this.dataModelOptions.selectedQuantities,
        comparability: this.dataModelOptions.comparableOption,
        valueType: this.dataModelOptions.valueOption,
        reportingUnit: this.dataModelOptions.unitKey,
        specificIds: this.dataModelOptions.variableId === 0 ? [] : [this.dataModelOptions.variableId],
        periods: this.start.Start.map(start => startOfMonth(new Date(start.value))),
        durationName: Object.keys(this.start.Duration)[0] as DurationName,
        durationLength: Object.values(this.start.Duration)[0],
        resolution: this.start.Resolution as SelectableResolution
      }),
      [this.dataModelOptions.facilityId],
      0
    ).pipe(
      map(data => data[this.dataModelOptions?.facilityId]),
      indicate(this._loading$),
      takeUntil(this._destroy$)
    );
  }

}
