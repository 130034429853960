import { percentageChange } from '@enerkey/ts-utils';

import { ReportingGridColumnBase, ReportingGridColumnGroupBase } from '../services/reporting-grid.service';
import { ConsumptionLike, ReportingSeries, ReportingSerieType } from './reporting-series';
import { ReportingSeriesByFacility, ReportingSeriesCollection } from './reporting-series-collection';
import { getIncompleteOrModeledAggregate } from './reporting.functions';

type MeterTableReportField = `values.${ReportingSerieType}.${number}`;

export type MeterTableReportColumn = ReportingGridColumnBase<MeterTableReportField>;
export type MeterTableReportColumnGroup = ReportingGridColumnGroupBase<MeterTableReportField>;

export type MeterTableReportData = Partial<Record<ReportingSerieType, ConsumptionLike[]>>;

export function getMeterTableReportColumns(
  series: ReportingSeries[]
): MeterTableReportColumnGroup[] {
  const facilityData: MeterTableReportColumnGroup[] = [];

  const allSeries = series
    .toGroupsBy(s => s.options.serieType);
  for (const [serieType, seriesOfType] of allSeries.entries()) {
    const groups: MeterTableReportColumn[][] = [];

    groups.push(seriesOfType.map((groupSerie, index) => ({
      title: groupSerie.gridTitle,
      field: `values.${serieType}.${index}`,
      color: groupSerie.gridColor,
      comparisonColor: groupSerie.gridComparisonColor,
      showChange: groupSerie.isChangeVisible,
      derivedId: groupSerie.chartItemOptions?.derivedId,
      unitKey: groupSerie.options.unitKey,
      isPercentSerie: groupSerie.options.isPercentSerie,
      quantityId: groupSerie.options.quantityId,
      isRelatedSerie: groupSerie.isRelatedSerie,
    })));

    const options = seriesOfType[0].options;
    facilityData.push({
      quantityId: options.quantityId,
      isNormalized: options.isNormalized,
      unit: options.unit,
      series: groups
    });
  }
  return facilityData;
}

export function getTableReportGridData(
  consumptionData: ReportingSeriesByFacility,
  ids: number[]
): { measured: Record<number, MeterTableReportData>, normalized: Record<number, MeterTableReportData> } {
  if (!consumptionData) {
    return {
      measured: {},
      normalized: {}
    };
  }
  return {
    measured: ids.toRecord(
      id => id,
      id => getTableReportValues(consumptionData[id], false)
    ),
    normalized: ids.toRecord(
      id => id,
      id => getTableReportValues(consumptionData[id], true)
    ),
  };
}

function getTableReportValues(
  data: ReportingSeriesCollection[],
  isNormalized: boolean
): Partial<Record<ReportingSerieType, ConsumptionLike[]>> {
  const groups = data
    ?.filter(s => s.isNormalized === isNormalized)
    .flatMap(s => s.series)
    .filter(s => s.isShownInGrid)
    .toGroupsBy(s => s.options.serieType) ?? new Map<ReportingSerieType, ReportingSeries[]>();

  const values: Partial<Record<ReportingSerieType, ConsumptionLike[]>> = {};

  for (const [serieType, group] of groups) {
    values[serieType] = group.map(s => {
      const numericComparisonConsumptions = s.comparisonConsumptions?.filter(c => Number.isFinite(c.value));

      let comparisonValue: number = null;
      let absoluteChange: number = null;

      if (numericComparisonConsumptions?.length) {
        comparisonValue = numericComparisonConsumptions.reduce((total, v) => total + v.value, 0);
        absoluteChange = comparisonValue - s.aggregatedValue;
      }

      return {
        absoluteChange,
        relativeChange: percentageChange(comparisonValue, s.aggregatedValue),
        value: s.aggregatedValue,
        timestamp: null,
        incomplete: getIncompleteOrModeledAggregate(s.consumptions, 'incomplete'),
        modeled: getIncompleteOrModeledAggregate(s.consumptions, 'modeled'),
      };
    });
  }

  return values;
}
