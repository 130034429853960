import { ChangeDetectionStrategy, Component, ElementRef, Host, Optional, SkipSelf } from '@angular/core';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { take } from 'rxjs';

import { ReportingModalsService } from '../../services/reporting-modals.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { KendoGridCustomColumnComponent } from '../../../../shared/base-classes/kendo-grid-custom-column.component';
import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';

@Component({
  selector: 'table-report-facility-name-column',
  templateUrl: './table-report-facility-name-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: ColumnBase,
    useExisting: TableReportFacilityNameColumnComponent
  }],
})
export class TableReportFacilityNameColumnComponent extends KendoGridCustomColumnComponent {

  public constructor(
    public el: ElementRef,
    @Optional() @SkipSelf() @Host() parent: ColumnBase,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly reportingModalsService: ReportingModalsService
  ) {
    super(parent);
  }

  public openModal(dataItem: ExtendedFacilityInformation): void {
    this.reportingSearchService.searchParameters$.pipe(
      take(1)
    ).subscribe({
      next: params => {
        this.reportingModalsService.openReport(dataItem.FacilityId, params);
      }
    });
  }
}
