import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

import { ToasterService } from '../services/toaster.service';

const imageMaxSizeInBytes = 200000;

@Directive({
  selector: 'textarea[markdownImageInput]'
})
export class MarkdownImageInputDirective {
  public constructor(
    private readonly control: NgControl,
    private readonly toasterService: ToasterService
  ) {
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    if (event.clipboardData.files?.length) {
      const file = event.clipboardData.files[0];
      const reader = new FileReader();
      const target = event.target as HTMLInputElement;

      if (file.size > imageMaxSizeInBytes) {
        this.toasterService.warning('IMAGE_FILE_TOO_LARGE');
        return;
      }

      new Promise(resolve => {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      }).then(data => {
        const textBeforeSelection = target.value.substring(0, target.selectionStart);
        const textAfterSelection = target.value.substring(target.selectionEnd);
        this.control.control.setValue(
          this.getImageRef(data as string, textBeforeSelection, textAfterSelection)
        );
      });
    }
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    if (event.dataTransfer.files?.length) {
      const file = event.dataTransfer.files[0];

      if (file.size > imageMaxSizeInBytes) {
        this.toasterService.warning('IMAGE_FILE_TOO_LARGE');
        return;
      }

      const target = event.target as HTMLInputElement;
      const reader = new FileReader();

      new Promise(resolve => {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
      }).then(data => {
        this.control.control.setValue(
          this.getImageRef(data as string, target.value)
        );
      });
    }
  }

  private getImageRef(data: string, textBefore: string, textAfter = ''): string {
    const imageId = `[image_ref_${Date.now()}]`;
    return `${textBefore}\n![IMAGE]${imageId}\n${textAfter}\n\n${imageId}:${data}`;
  }
}
