const inject = ['$element'];

class ModalViewHeaderController {
  constructor($element) {
    this.$element = $element;
  }

  onCloseClick() {
    if (angular.isFunction(this.onClose)) {
      this.onClose();
    }
  }

  $onInit() {
    if (this.$element.attr("onClose") || this.$element.attr("on-close")) {
      this.isOnCloseDefined = true;
    }
  }
}

ModalViewHeaderController.$inject = inject;

export default ModalViewHeaderController;
