import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { anyOf, indicate, LoadingSubject } from '@enerkey/rxjs';
import { FacilityFrequencyBase } from '@enerkey/clients/email-consumption-report';

import { FacilityService } from '../../../../shared/services/facility.service';
import { EkKendoModule } from '../../../../shared/ek-kendo/ek-kendo.module';
import { SharedModule } from '../../../../shared/shared.module';
import { EmailConsumptionReportService } from '../../services/email-consumption-report.service';
import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'enerkey-email-notification-settings',
  templateUrl: './email-notification-settings.component.html',
  styleUrls: ['./email-notification-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [EkKendoModule, GridModule, SharedModule, InputsModule],
  providers: [EmailConsumptionReportService]
})
@ModalOptions({ size: 'tiny' })
export class EmailNotificationSettingsComponent extends ModalBase<void> implements OnDestroy {
  public readonly loading$: Observable<boolean>;

  public gridView: (ExtendedFacilityInformation & FacilityFrequencyBase)[];
  public isConsumptionEdited: boolean = false;

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly facilityService: FacilityService,
    private readonly emailConsumptionReportService: EmailConsumptionReportService,
    private readonly toasterService: ToasterService
  ) {
    super(currentModal);

    this.loading$ = anyOf(
      this._loading$.asObservable(),
      this.emailConsumptionReportService.consumptionSubscriptionLoading$
    );

    combineLatest([
      this.facilityService.profileFacilities$,
      this.emailConsumptionReportService.consumptionEmailSubscriptions$
    ]).pipe(
      map(([facilities, consumptionSubscriptions]) => facilities.map(f => {
        const facilityConsumptionSubscription = consumptionSubscriptions.find(sub => sub.facility === f.facilityId) ??
          { facility: f.facilityId, weekly: false, monthly: false };
        return { ...f, ...facilityConsumptionSubscription } as (ExtendedFacilityInformation & FacilityFrequencyBase);
      })),
      takeUntil(this._destroy$)
    ).subscribe({
      next: data => {
        this.gridView = data;
      }
    });
  }

  public get infoTextTranslationParams(): { userName: string, customerPrivacyUrl: string } {
    return { userName: this.emailConsumptionReportService.userName, customerPrivacyUrl: 'https://global.eg.dk/about-eg/treatment-of-data/' };
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public submit(): void {
    this.emailConsumptionReportService.addEmailConsumptionSubscription(this.gridView.map(g =>
      new FacilityFrequencyBase({
        facility: g.facilityId,
        monthly: g.monthly,
        weekly: g.weekly
      })))
      .pipe(
        indicate(this._loading$),
        takeUntil(this._destroy$)
      ).subscribe({
        next: () => {
          this.toasterService.success('EMAIL_NOTIFICATION.EMAIL_CONSUMPTION_UPDATE_SUCCESS');
          this.close();
        },
        error: () => {
          this.toasterService.error('EMAIL_NOTIFICATION.EMAIL_CONSUMPTION_UPDATE_ERROR');
          this.isConsumptionEdited = false;
        }
      });
  }

  public close(): void {
    super.closeModal();
  }
}
