<div class="facility-dropdown-wrapper">

  <ek-filterable-combo
    formControlName="facilityId"
    *labelBefore="'FACILITIES.FACILITY' | translate"
    [items]="facilityOptions$ | async"
    [loading]="loadingFacilities$ | async"
    [disableWhenLoading]="true"
    [virtual]="false"
    [filterable]="true"
    [noTranslate]="true"
  ></ek-filterable-combo>

  <button
    class="facility-link-button"
    type="button"
    (click)="facilityLinkClick.emit()"
    *ngIf="showFacilityLink && controls.facilityId.value !== null && controls.facilityId.value !== undefined"
  >
    {{'FACILITIES.SHOW_FACILITY' | translate}} >
  </button>
</div>
