<div *ngIf="quantityId | valueFormat | async as format">
  <p class="text--small">{{ title }}</p>
  <h3 class="text--large">
    <span
      *ngIf="value || value === 0 ; else invalidStateValue"
      [ngClass]="{ 'k-grid-cell-Incomplete': incomplete }"
    >
      {{ value | kendoNumber:format }}&nbsp;
    </span>
    <ng-template #invalidStateValue>
      <span>{{ 'FACILITIES_REPORT.NO_DATA' | translate }}&nbsp;</span>
    </ng-template>
  </h3>
  <ng-container *ngIf="!incomplete && showDiff">
    <p class="text--small">
      Δ <span *ngIf="differencePercent > 0">+</span>{{ difference | kendoNumber:format }} &nbsp;
    </p>
    <span class="differencePercent" [ekValueChange]="differencePercent"></span>
  </ng-container>
</div>
