<modal-view-header
  [headingText]="'ADMIN.FACILITIES_PROFILES.MODAL_TITLE' | translate: {
    count: facilityIds.length
  }"
></modal-view-header>
<div class="modal-body">
  <profile-search-select
    *labelBefore="'ADMIN.PROFILENAME' | translate"
    [formControl]="profileField"
  ></profile-search-select>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      type="button"
      class="button"
      (click)="addToProfile()"
      [disabled]="!profileField.value"
      [loading]="loading$ | async"
    >
      {{ 'ADMIN.FACILITIES_PROFILES.ADD' | translate }}
    </button>
    <button
      type="button"
      class="button button--negative"
      (click)="removeFromProfile()"
      [disabled]="!profileField.value"
      [loading]="loading$ | async"
    >
      {{ 'ADMIN.FACILITIES_PROFILES.REMOVE' | translate }}
    </button>
    <button
      type="button"
      class="button button--secondary"
      (click)="dismiss()"
    >
      {{ 'ADMIN.FACILITIES_PROFILES.CANCEL' | translate }}
    </button>
  </div>
</div>
