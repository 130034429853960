<sidebar></sidebar>
<div class="report-container">
  <div class="content-header">
    <ul class="tabs">
      <li
        *ngFor="let tab of tabs$ | async; trackBy: trackBy"
        class="tabs__item"
        [class.tabs__item--disabled]="!tab.enabled"
        uiSrefActive="tabs__item--active"
      >
        <a
          class="tabs__item-link"
          [uiSref]="tab.enabled ? tab.state.name : ''"
        >
          {{ tab.state.data.translationKey | translate }}
        </a>
      </li>
    </ul>
    <div class="flex-center-center">
      <facility-action-popup
        class="mr-1"
        [selectedFacilities]="selectedFacilities$ | async"
        [requestedRoles]="requestedRoles"
        *ngIf="showFacilityActions"
      ></facility-action-popup>
      <div
        class="selected-facility-amount"
        *ngIf="(selectedFacilities$ | async) as facilities"
      >
        {{ facilities.length }}
        <i class="fa fa-home"></i>
      </div>
    </div>
  </div>
  <div ui-view="report"></div>
</div>
