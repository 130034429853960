import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { ModalBase, ModalOptions } from '@enerkey/foundation-angular';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { MeterHierarchyTreelistItem } from '../../shared/meter-hierarchy-factory';

interface MeterSelectItem {
  meterId: number;
  meterName: string;
  hierarchyId: number;
}

@Component({
  selector: 'submeter-select-modal',
  templateUrl: './submeter-select-modal.component.html',
  styleUrls: ['./submeter-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  size: 'tiny'
})
export class SubmeterSelectModalComponent extends ModalBase<MeterHierarchyTreelistItem> implements OnInit {
  public readonly meterControl = new UntypedFormControl(null, Validators.required);

  public readonly filterSettings: DropDownFilterSettings = {
    fields: ['meterId', 'meterName'],
    caseSensitive: false,
    operator: 'contains'
  };

  public meters: MeterHierarchyTreelistItem[] = [];
  public selectItems: MeterSelectItem[];

  public constructor(activeModal: NgfActiveModal) {
    super(activeModal);
  }

  public ngOnInit(): void {
    this.selectItems = this.meters.map(m => ({
      hierarchyId: m.hierarchyId,
      meterId: m.meter.id,
      meterName: m.meter.name
    }));
  }

  public close(): void {
    const selectedItem = this.meters.find(m => m.hierarchyId === this.meterControl.value);
    this.closeModal(selectedItem);
  }
}
