import moment from 'moment';

export const getInteger = (object, key, defaultValue = null) => object && object[key] ?
  parseInt(object[key], 10) :
  defaultValue;

export const getFloat = (object, key, defaultValue = null) => object && object[key] ?
  parseFloat(object[key]) :
  defaultValue;

export const getMoment = (object, key, defaultValue = null) => object && object[key] ?
  moment(object[key]) :
  defaultValue;

export const getDate = (object, key, defaultValue = null) => object && object[key] ?
  new Date(object[key]) :
  defaultValue;

export const getString = (object, key, defaultValue = '') => object && object[key] ?
  object[key] :
  defaultValue;

export const getBoolean = (object, key, defaultValue = false) => object && object[key] ?
  !!object[key] :
  defaultValue;
