<a
  class="facilities-menu-filter-command topbar-icon-item"
  [ngfTooltip]="'FACILITIES_FILTER.FILTERING' | translate"
  [placement]="'bottom'"
  (click)="openFacilityFilterModal()"
>
  <i
    class="fa fa-filter"
    [ngClass]="{ filtered: isFiltered$ | async }"
  ></i>
  <span
    *ngIf="!(isFiltered$ | async)"
    class="badge badge--topbar"
  >
    {{ facilityCount$ | async }}
  </span>
  <span
    *ngIf="isFiltered$ | async"
    class="badge badge--topbar"
  >
    {{ ( filteredCount$ | async) || (facilityCount$ | async) }}/{{ facilityCount$ | async }}
  </span>
  <span *ngIf="isMobile" class="topbar-mobile-title">
    {{ 'FACILITIES_FILTER.FILTERING' | translate }}
  </span>
</a>
