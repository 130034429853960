<modal-view-header
  [headingText]="(existingReport ? 'SUSTAINABILITY.GRI.EDIT_REPORT' : 'SUSTAINABILITY.GRI.ADD_REPORT') | translate"
></modal-view-header>

<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
>
  <form [formGroup]="formGroup">
    <p>
      <kendo-numerictextbox
        *labelBefore="'YEAR' | translate"
        integerInput
        formControlName="year"
        [readonly]="loading$ | async"
      ></kendo-numerictextbox>
    </p>

    <p>
      <kendo-formfield>
        <kendo-textbox
          *labelBefore="'SUSTAINABILITY.GRI.DESCRIPTION' | translate"
          formControlName="description"
          [readonly]="loading$ | async"
        ></kendo-textbox>
        <kendo-formerror>
          {{ 'MAX_CHARS' | translate : { amount: 200 } }}
        </kendo-formerror>
      </kendo-formfield>
    </p>

    <gri-organization-info [showProfile]="true"></gri-organization-info>
  </form>
</div>

<div class="modal-footer">
  <div class="modal-footer-buttons">
    <button
      *ngIf="existingReport"
      class="button button--negative"
      (click)="deleteReport()"
    >
      {{ 'DELETE' | translate }}
    </button>

    <div class="float-right">
      <button
        class="button"
        type="button"
        (click)="submit()"
        [loading]="loading$ | async"
        [disabled]="!formGroup.dirty || !formGroup.valid"
      >
        {{ (existingReport ? 'EDIT': 'CREATE') | translate }}
      </button>
      <button
        class="button button--secondary"
        type="button"
        (click)="dismiss()"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
