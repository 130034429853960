import _ from 'lodash';

import { isAverageCost } from '../../../reportingobjects/shared/relational-value-functions';

function ERDataSumFunctions() {
  return {
    getSpecifiedConsumptionSum: getSpecifiedConsumptionSum,
    getFieldAverage: getFieldAverage,
  };

  /**
   * Calculates sum for specified consumptions using functions that are
   * defined in definitions array. Returns empty string for undefined specified consumptions.
   *
   * @param {Object} column
   * @param {Object} data
   * @param {Object} options
   * @param {Object} params
   *
   * @returns {Number}
   */
  function getSpecifiedConsumptionSum(column, data, options, params) {
    const gridData = params.groupItems.length ? params.groupItems : _.get(data, 'gridOptions.dataSource.data', []);

    return isAverageCost(params.property.Id)
      ? calculateAverage(gridData, params)
      : calculateWeightedSum(gridData, params)
    ;
  }

  function getFieldAverage(column, data, options, params) {
    const gridData = params.groupItems.length ? params.groupItems : _.get(data, 'gridOptions.dataSource.data', []);
    return calculateAverage(gridData, params);
  }

  /**
   * Calculates weighted sum
   *
   * @param {Object} data
   * @param {Object} params
   *
   * @returns {Number}
   */
  function calculateWeightedSum(data, params) {
    const values = getWeightedSumValues(data, params);

    return values.relationalSum > 0 ? values.readingSum / values.relationalSum : 0;
  }

  /**
   * Calculates average for field
   *
   * @param {Object} data
   * @param {Object} params
   *
   * @returns {Number}
   */
  function calculateAverage(data, params) {
    if (!data.length) {
      return 0;
    }
    const sum = data.reduce((sumValue, row) => sumValue + row[params.relationalValueField] || 0, 0);
    return sum / data.length;
  }

  /**
   * Calculates weighted sum for given relational field
   *
   * @param {Object} data
   * @param {Object} params
   *
   * @returns {Number}
   */
  function getWeightedSumValues(data, params) {
    const reducer = function(result, values) {
      const relationalValue = values[params.relationalValueField];
      const readingValue = values[params.readingField];
      const average = relationalValue > 0 ? readingValue / relationalValue : 0;

      return _.isNumber(relationalValue)
        ? {
          readingSum: result.readingSum + readingValue,
          relationalSum: result.relationalSum + average
        }
        : result;
    };

    return data.reduce(reducer, { readingSum: 0, relationalSum: 0 });
  }
}

export default ERDataSumFunctions;
