import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';

@Component({
  selector: 'report-change-column-header',
  templateUrl: './report-change-column-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [`
  :host {
    overflow: hidden;
    white-space: normal;
  }
  `]
})
export class ReportChangeColumnHeaderComponent {
  @Input() public color: string;
  @Input() public comparisonColor: string;
  @Input() public quantityId: Quantities;
  @Input() public title: string;
  @Input() public changeKey: string;
}
