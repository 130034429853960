<modal-view-header>
</modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
>
  <ng-container *ngIf="password$ | async as password">
    <div
      class="small-4 columns"
      id="passwordField"
    >{{ password }}</div>
    <button
      class="button icon small-2 columns"
      (click)="copyToClipboard(password)"
    >
      <i class=" fa fa-copy"></i>
    </button>
  </ng-container>

</div>
