import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MeterManagementMeter } from '@enerkey/clients/meter-management';
import { ModalService } from '@enerkey/foundation-angular';

import { AjsModalService } from '../../../../services/modal/modal.service';
import { MeterHierarchyTreelistItem } from '../../shared/meter-hierarchy-factory';
import { MeterMassEditMeter, MeterMassEditModalController } from './meter-mass-edit-modal-controller';
import MeterMassEditModalTemplate from 'raw-loader!./meter-mass-edit-modal.html';
import AdminModalController from '../../controllers/modals/admin-modal-controller';
import MeterCreateModalTemplate from 'raw-loader!../meter-create/meter-create-modal.html';
import { MeterReorderModalComponent } from '../meter-reorder-modal/meter-reorder-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MeterMassEditService {
  public constructor(
    private readonly ajsModalService: AjsModalService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService
  ) {
  }

  public createMeters(): Promise<number[]> {
    return this.ajsModalService.open({
      template: MeterCreateModalTemplate,
      controller: AdminModalController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'reveal--full-height',
      resolve: {
        facilityIds: () => {},
        includeQuantityId: () => {}, // NOTE: we have to resolve also this because we use shared controller
        includeCustomType: () => {}, // NOTE: we have to resolve also this because we use shared controller
        title: () => this.translateService.instant('ADMIN.MASS_IMPORT.METER_CREATE')
      }
    }).result as Promise<number[]>;
  }

  public massEditMeterCosts(meters: MeterManagementMeter[]): Promise<unknown> {
    return this.ajsModalService.getModalWithComponent('meter-cost-mass-edit-modal', { selectedMeters: meters });
  }

  public massEditMeters(selectedMeters: MeterMassEditMeter[]): Promise<unknown> {
    return this.ajsModalService.open({
      template: MeterMassEditModalTemplate,
      windowClass: 'full',
      controller: MeterMassEditModalController,
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
        selectedMeters: () => selectedMeters
      }
    }).result;
  }

  public reorderMeters(metersToEdit: MeterHierarchyTreelistItem[]): Promise<unknown> {
    const modalRef = this.modalService.open(MeterReorderModalComponent);
    modalRef.componentInstance.meters = metersToEdit;
    return modalRef.result;
  }
}
