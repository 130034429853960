<form
  [formGroup]="searchForm"
  (ngSubmit)="search()"
>
  <div>
    <input
      formControlName="userName"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.USERNAME' | translate"
    >
    <input
      formControlName="firstname"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.FIRSTNAME' | translate"
    >
    <input
      formControlName="lastname"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.LASTNAME' | translate"
    >
    <input
      formControlName="email"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.EMAIL' | translate"
    >
    <input
      formControlName="organization"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.ORGANIZATION' | translate"
    >
    <kendo-numerictextbox
      formControlName="organizationId"
      *labelBefore="'ADMIN.ORGANIZATIONID' | translate"
      integerInput
    ></kendo-numerictextbox>
    <input
      formControlName="profileName"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.PROFILENAME' | translate"
    >
    <input
      formControlName="excludeProfileName"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.EXCLUDED_PROFILENAME' | translate"
    >
    <kendo-numerictextbox
      formControlName="facilityId"
      *labelBefore="'ADMIN.FACILITYID' | translate"
      integerInput
    ></kendo-numerictextbox>
    <input
      formControlName="includeInActive"
      type="checkbox"
      kendoCheckBox
      *labelWrap="'ADMIN.INCLUDE_INACTIVE' | translate"
    >
  </div>
  <div class="buttons-container">
    <button
      class="button button--primary"
      type="submit"
      [loading]="loading$ | async"
    >
      {{ 'SEARCH' | translate }}
    </button>
    <button
      class="button button--secondary float-right"
      type="button"
      (click)="searchForm.reset()"
    >
      {{ 'RESET' | translate }}
    </button>
  </div>
</form>
