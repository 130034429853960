import { Component, Host, Optional, SkipSelf, TemplateRef, ViewChild } from '@angular/core';
import {
  CellTemplateDirective,
  ColumnBase,
  ColumnComponent,
  FilterCellTemplateDirective,
  FilterMenuTemplateDirective,
  FooterTemplateDirective,
  GroupFooterTemplateDirective,
  HeaderTemplateDirective
} from '@progress/kendo-angular-grid';

/* eslint-disable @typescript-eslint/ban-ts-comment */

@Component({
  template: ''
})
export class KendoGridCustomColumnComponent extends ColumnComponent {
  @ViewChild(CellTemplateDirective)
  public viewTemplate: CellTemplateDirective;

  @ViewChild(GroupFooterTemplateDirective)
  public viewGroupFooterTemplate: GroupFooterTemplateDirective;

  @ViewChild(FooterTemplateDirective)
  public viewFooterTemplate: FooterTemplateDirective;

  @ViewChild(HeaderTemplateDirective)
  public viewHeaderTemplate: HeaderTemplateDirective;

  @ViewChild(FilterCellTemplateDirective)
  public override filterCellTemplate: FilterCellTemplateDirective;

  @ViewChild(FilterMenuTemplateDirective)
  public override filterMenuTemplate: FilterMenuTemplateDirective;

  public constructor(@Optional() @SkipSelf() @Host() parent: ColumnBase) {
    super(parent);
  }

  public override get templateRef(): TemplateRef<unknown> {
    return this.template?.templateRef ?? this.viewTemplate?.templateRef;
  }

  public override get groupFooterTemplateRef(): TemplateRef<unknown> {
    return this.groupFooterTemplate?.templateRef ?? this.viewGroupFooterTemplate?.templateRef;
  }

  public override get footerTemplateRef(): TemplateRef<unknown> {
    return this.footerTemplate?.templateRef ?? this.viewFooterTemplate?.templateRef;
  }

  public override get headerTemplateRef(): TemplateRef<unknown> {
    return this.headerTemplates?.first?.templateRef ?? this.viewHeaderTemplate?.templateRef;
  }

  public override get filterMenuTemplateRef(): TemplateRef<unknown> {
    return this.filterMenuTemplate?.templateRef ?? this.filterMenuTemplate?.templateRef;
  }
}
