import { CUMULATIVE_AXIS, CUMULATIVE_AXIS_COLOR } from './em-yearly-visuals-constant';
import { VISUAL_COLORS } from '../../../../constants/visualization-constant';

const $inject = ['$window', 'utils', 'kendo'];

class EmYearlyVisualsService {
  constructor($window, utils, kendo) {
    this.$window = $window;
    this.utils = utils;
    this.kendo = kendo;
  }

  getChartOptions(groups) {
    const getKendoLabel = this.getKendoLabel.bind(this);
    return {
      legend: {
        position: 'top',
        item: {
          visual: getKendoLabel
        },
        spacing: 20
      },
      series: this.getOptionsSeries(groups),
      seriesDefaults: {
        overlay: {
          gradient: 'none'
        },
        type: 'column',
        stack: true
      },
      tooltip: {
        background: '#f9f9f9',
        border: {
          width: 1,
          color: '#8a91ae'
        },
        template: '#= kendo.format("{0:n1}", value)#',
        visible: true
      },
      categoryAxis: {
        name: 'year',
        field: 'year',
        majorGridLines: {
          visible: false
        },
        axisCrossingValues: [0, 20]
      },
      valueAxis: [
        { name: 'values' },
        { name: CUMULATIVE_AXIS,
          color: CUMULATIVE_AXIS_COLOR }
      ],
      transitions: false
    };
  }

  getOptionsSeries(groupsByKey) {
    const groups = Object.values(groupsByKey);
    const series = groups.map(group => ({
      field: group.field,
      color: group.color,
      name: group.name,
      axis: 'values'
    }));
    series.push({
      field: 'cumulative',
      type: 'line',
      style: 'smooth',
      color: CUMULATIVE_AXIS_COLOR,
      name: this.utils.localizedString('ACTIONS.VISUALS.CUMULATIVE'),
      axis: CUMULATIVE_AXIS,
      markers: {
        visible: false
      }
    });
    return series;
  }

  getKendoLabel(e) {
    const layout = new this.kendo.drawing.Layout(
      new this.kendo.geometry.Rect([0, 0], [250, 50]),
      {
        spacing: 5,
        alignItems: 'center'
      }
    );

    const rectSize = e.series.axis === CUMULATIVE_AXIS ? [15, 3] : [10, 10];
    const rect = new this.kendo.drawing.Rect(
      new this.kendo.geometry.Rect([0, 0], rectSize),
      {
        fill: {
          color: e.options.markers.background
        },
        stroke: {
          width: 0
        }
      }
    );

    const label = new this.kendo.drawing.Text(e.series.name, [0, 0], {
      fill: {
        color: e.options.labels.color
      }
    });

    layout.append(rect, label);
    layout.reflow();
    return layout;
  }

  getFirstActionYear(viewData) {
    return viewData.reduce(
      (currentMin, group) =>
        group.items.reduce((currentGroupMin, action) => {
          if (action.effectStartsAt) {
            const actionYear = action.effectStartsAt.getFullYear();
            return Math.min(currentGroupMin, actionYear);
          }
          return currentGroupMin;
        }, currentMin),
      Number.MAX_SAFE_INTEGER
    );
  }

  getLastActionYear(viewData) {
    return viewData.reduce(
      (currentMax, group) =>
        group.items.reduce((currentGroupMax, action) => {
          if (action.effectStartsAt) {
            const actionYear = action.effectStartsAt.getFullYear();
            return Math.max(currentGroupMax, actionYear);
          }
          return currentGroupMax;
        }, currentMax),
      Number.MIN_SAFE_INTEGER
    );
  }

  getSeries(viewData, fields, groups, firstYear, lastYear) {
    const seriesByYear = this.getEmptySeries(viewData, firstYear, lastYear);
    viewData.forEach(group => {
      const groupField = groups[group.value].field;
      group.items.forEach(item => {
        const year = item.effectStartsAt ? item.effectStartsAt.getFullYear() : null;
        if (seriesByYear[year]) {
          let sum = 0;
          let readingsFound = false;
          fields.forEach(field => {
            if (angular.isNumber(item[field])) {
              sum += item[field];
              readingsFound = true;
            }
          });
          if (readingsFound) {
            if (seriesByYear[year][groupField] === null) {
              seriesByYear[year][groupField] = 0;
            }
            if (seriesByYear[year].total === null) {
              seriesByYear[year].total = 0;
            }
            seriesByYear[year][groupField] += sum;
            seriesByYear[year].total += sum;
          }
        }
      });
    });

    const years = Object.keys(seriesByYear);
    years.forEach(year => {
      const yearSeries = seriesByYear[year];
      const readingKeys = Object.keys(yearSeries);
      readingKeys.forEach(field => {
        const reading = yearSeries[field];
        if (angular.isNumber(reading)) {
          seriesByYear[year][field] = Math.round(reading);
        }
      });
    });

    return seriesByYear;
  }

  getChart(seriesByYear) {
    // Work on a copy, original series is needed elsewhere later
    const chartSeriesByYear = angular.copy(seriesByYear);

    const years = Object.keys(chartSeriesByYear);
    let cumulative = 0;
    const mappedSeries = years.map(year => {
      const series = chartSeriesByYear[year];
      if (Number.isFinite(series.total)) {
        cumulative += series.total;
      }
      series.cumulative = cumulative;
      series.year = year;
      return series;
    });
    return { series: mappedSeries };
  }

  getTable(seriesByYear, groupsByKey) {
    const totals = Object.values(seriesByYear).map(({ total }) => total);
    const years = Object.keys(seriesByYear);

    let tableTotal = 0;
    const groups = Object.values(groupsByKey);
    const tableSeries = groups.map(group => {
      const tableGroup = {
        title: group.name,
        color: {
          'background-color': group.color
        },
        series: []
      };
      let total = null;
      const allSeries = Object.values(seriesByYear);
      allSeries.forEach(series => {
        tableGroup.series.push(series[group.field]);
        if (angular.isNumber(series[group.field])) {
          if (total === null) {
            total = 0;
          }
          total += series[group.field];
          tableTotal += series[group.field];
        }
      });
      tableGroup.total = total;
      return tableGroup;
    });

    return {
      titles: years,
      series: tableSeries,
      totals: totals,
      tableTotal: tableTotal
    };
  }

  getEmptySeries(viewData, firstYear, lastYear) {
    const minColumns = 5;
    const maxColumns = 20;

    let columnCount = lastYear - firstYear + 1;
    if (columnCount < minColumns) {
      columnCount = minColumns;
    } else if (columnCount > maxColumns) {
      columnCount = maxColumns;
    }

    const series = {};
    for (let i = 0; i < columnCount; i++) {
      series[lastYear - i] = this.getEmptyGroup(viewData);
    }
    return series;
  }

  getEmptyGroup(viewData) {
    const groups = {
      total: null,
      cumulative: null
    };
    for (let i = 0; i < viewData.length; i++) {
      groups[`value${i}`] = null;
    }
    return groups;
  }

  // Some fiddling with group names because Kendo cannot handle field names with special characters
  getGroups(viewData) {
    let colorIndex = 0;
    return viewData.reduce((groups, group, groupIndex) => {
      groups[group.value] = {
        field: `value${groupIndex}`,
        name: this.getGroupName(group.value),
        color: VISUAL_COLORS[colorIndex]
      };
      colorIndex++;
      colorIndex %= VISUAL_COLORS.length;
      return groups;
    }, {});
  }

  getGroupName(name) {
    if (name === undefined || name === null) {
      return this.utils.localizedString('ACTIONS.VISUALS.SERIE_TITLE_MISSING');
    }
    return name;
  }

  getActiveFields(gridColumns, fields) {
    return fields.filter(configField => gridColumns.find(({ field }) => field === configField));
  }

  getVisualsTitle(config, activeFields) {
    const prefix = this.utils.localizedString(`ACTIONS.VISUALS.${config.name}`);
    if (config.fields.length > 1) {
      const suffix = [];
      activeFields.forEach(field => {
        suffix.push(this.utils.localizedString(`ACTIONS.VISUALS.${field}`));
      });
      return `${prefix} (${suffix.join(', ')})`;
    } else {
      return prefix;
    }
  }
}

EmYearlyVisualsService.$inject = $inject;

export default EmYearlyVisualsService;
