import { InterfaceOf } from '@enerkey/ts-utils';

import { ErrorTicketClient } from './error-ticket-client';

export class ErrorTicketClientMock implements InterfaceOf<ErrorTicketClient> {
  public getDevices(): never {
    throw new Error('Method not implemented.');
  }
  public addDevice(): never {
    throw new Error('Method not implemented.');
  }
  public getDevice(): never {
    throw new Error('Method not implemented.');
  }
  public updateDevice(): never {
    throw new Error('Method not implemented.');
  }
  public deleteDevice(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTickets(): never {
    throw new Error('Method not implemented.');
  }
  public addErrorTicket(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicketsForBucketsByStatus(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicketsForBuckets(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicketsForTerminalsByStatus(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicketsForTerminals(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicketsForTerminalsByFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicketsForFacilitiesByStatus(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicketsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getErrorTicket(): never {
    throw new Error('Method not implemented.');
  }
  public updateErrorTicket(): never {
    throw new Error('Method not implemented.');
  }
  public deleteErrorTicket(): never {
    throw new Error('Method not implemented.');
  }
  public getAllRepairInstructions(): never {
    throw new Error('Method not implemented.');
  }
  public addRepairInstruction(): never {
    throw new Error('Method not implemented.');
  }
  public findByDeviceId(): never {
    throw new Error('Method not implemented.');
  }
  public getRepairInstruction(): never {
    throw new Error('Method not implemented.');
  }
  public updateRepairInstruction(): never {
    throw new Error('Method not implemented.');
  }
  public deleteRepairInstruction(): never {
    throw new Error('Method not implemented.');
  }
  public getTicketStatusesAll(): never {
    throw new Error('Method not implemented.');
  }
  public addTicketStatus(): never {
    throw new Error('Method not implemented.');
  }
  public findByTicket(): never {
    throw new Error('Method not implemented.');
  }
  public deleteTicketStatus(): never {
    throw new Error('Method not implemented.');
  }
}
