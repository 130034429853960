import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF } from '@progress/kendo-drawing';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  public downloadAsJsonFile(input: Record<keyof unknown, unknown>, fileName: string): void {
    const blob = new Blob([JSON.stringify(input, null, 2)]);
    this.downloadBlob(blob, fileName);
  }

  public openTextFileOnWindow(input: string, fileType: string): void {
    const blob = new Blob([input], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  public downloadBlob(blob: Blob, fileName: string): void {
    saveAs(blob, fileName);
  }

  /**
   * Get File object from kendo PDFExportComponent
   *
   * @param pdf kendo pdf component
   * @param fileName file name without extension
   */
  public async exportKendoPdfToFile(pdf: PDFExportComponent, fileName: string): Promise<File> {
    return pdf.export().then(group => exportPDF(group)).then(
      dataUri => this.dataURLtoFile(dataUri, `${fileName}.pdf`)
    );
  }

  private dataURLtoFile(dataurl: string, filename: string): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
