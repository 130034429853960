import { getDaysInMonth, getDaysInYear } from 'date-fns';

import { $InjectArgs } from '@enerkey/ts-utils';
import { Quantities } from '@enerkey/clients/metering';

import { CalculationMethod } from '../../../modules/configuration/constants/calculation-method';
import { QuantityService } from '../../../shared/services/quantity.service';

export class YearlyConsumptionService {
  public static readonly $inject: $InjectArgs<typeof YearlyConsumptionService> = ['mrcDataParser', 'quantities'];

  public constructor(
    private readonly mrcDataParser: any,
    private readonly quantities: QuantityService
  ) {
  }

  public calculateValuesForYear(
    inputValue: number | string,
    method: CalculationMethod,
    quantityId: Quantities,
    year: number,
    convertValues = false
  ): { idx: number; value: number }[] {
    const values = [];
    if (inputValue !== null) {
      let value = this.parseValue(inputValue);

      if (convertValues) {
        value = this.mrcDataParser.convertReading(value, 1000);
      }

      const coeff = this.quantities.getCoefficient(quantityId);

      for (let i = 0; i < 12; i++) {
        let v = 0;
        if (method === CalculationMethod.DISTRIBUTION && value !== null) {
          v = coeff[i] * value;
        } else if (method === CalculationMethod.EVEN && value !== null) {
          v = value * this.getEvenDistributionFactor(i, year);
        } else {
          v = value;
        }
        values.push({ idx: i, value: v });
      }
    }
    return values;
  }

  private parseValue(inputValue: number | string): number {
    if (typeof inputValue === 'number') {
      return inputValue;
    }
    inputValue = inputValue.trim();
    if (!inputValue.length) {
      return null;
    }
    return parseFloat(inputValue.replace(',', '.'));
  }

  private getEvenDistributionFactor(month: number, year: number): number {
    // Use year that is not a leap year if the year is missing, otherwise calculation will fail.
    if (!year) {
      year = 2100;
    }
    const daysInYear = getDaysInYear(new Date(year, 1, 1));
    const daysInMonth = getDaysInMonth(new Date(year, month, 1));
    return daysInMonth / daysInYear;
  }
}
