import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import isMobile from 'ismobilejs';

import {
  ActionOutViewModel,
  ActionsQueryParameters,
  ActionType,
  AttachmentsClient,
} from '@enerkey/clients/attachments';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { formGroupFrom, guid } from '@enerkey/ts-utils';

import { UserService } from '../../../../services/user-service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { WidgetBase } from '../../shared/widget-base.interface';
import { WidgetQueueService } from '../../services/widget-queue.service';
import { DashboardStateService } from '../../services/dashboard-state.service';

export type CommentsWidgetOptions = {
  typeToShow: 'comments'; // remove when usersettings changed?
  numberToShow: number;
  numberToShowMobile: number;
  selectedCommentTypes: {
    [ActionType.K]: boolean;
    [ActionType.KE]: boolean;
  };
};

type CommentsFilterForm = {
  consumption: boolean;
  other: boolean;
}

@Component({
  selector: 'comments-widget',
  templateUrl: './comments-widget.component.html',
  styleUrls: ['./comments-widget.component.scss', '../../styles/actions-comments-widget-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentsWidgetComponent implements WidgetBase<CommentsWidgetOptions>, OnInit, OnDestroy {
  @Input() public dataModelOptions: CommentsWidgetOptions;

  public readonly uniqueId: string = guid(true);

  public readonly facilityNames$: Observable<Readonly<Record<number, string>>>;
  public readonly dataModelChange$: Observable<CommentsWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;

  public readonly formGroup: UntypedFormGroup;

  public comments$: Observable<ActionOutViewModel[]>;

  private readonly _dataModelChange = new Subject<CommentsWidgetOptions>();
  private readonly _loading = new LoadingSubject(true);
  private readonly _destroy = new Subject<void>();
  private readonly _error$ = new Subject<void>();
  private readonly _refresh$ = new BehaviorSubject<boolean>(true);

  private readonly _isMobile: boolean = isMobile().any;

  public constructor(
    private readonly widgetQueue: WidgetQueueService,
    private readonly facilityService: FacilityService,
    private readonly attachmentsClient: AttachmentsClient,
    private readonly dashboardStateService: DashboardStateService,
    private readonly userService: UserService,
    private readonly changeDetection: ChangeDetectorRef
  ) {
    this.formGroup = formGroupFrom<CommentsFilterForm>({
      consumption: false,
      other: false,
    });

    this.error$ = this._error$.asObservable();
    this.loading$ = this._loading.asObservable();
    this.dataModelChange$ = this._dataModelChange.asObservable();
    this.facilityNames$ = this.facilityService.filteredProfileFacilities$.pipe(
      map(facilities => facilities.toRecord(f => f.facilityId, f => f.name)),
      shareReplay(1),
      takeUntil(this._destroy)
    );
  }

  public ngOnInit(): void {
    this.formGroup.patchValue({
      consumption: this.dataModelOptions.selectedCommentTypes[ActionType.KE],
      other: this.dataModelOptions.selectedCommentTypes[ActionType.K],
    });

    this.formGroup.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._destroy)
    ).subscribe((formValue: CommentsFilterForm) => {
      this._dataModelChange.next({
        ...this.dataModelOptions,
        selectedCommentTypes: {
          [ActionType.K]: formValue.other,
          [ActionType.KE]: formValue.consumption,
        },
      });
    });

    this.comments$ = combineLatest([
      this.facilityService.filteredProfileFacilityIds$,
      this.dataModelChange$.pipe(startWith(this.dataModelOptions)),
      this._refresh$.asObservable()
    ]).pipe(
      switchMap(args => this.getActions$(args[0], args[1]).pipe(
        indicate(this._loading),
        catchError(() => {
          this._error$.next();
          return EMPTY;
        })
      ))
    );
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loading.complete();
    this._error$.complete();
    this._dataModelChange.complete();
    this._refresh$.complete();
  }

  public openEditModal(comment: ActionOutViewModel): void {
    this.dashboardStateService.openEmEditModal(comment)
      .then((response: { operation?: string, action?: ActionOutViewModel }) =>
        this.dashboardStateService.refreshReportData(comment, response, this.changeDetection, this._refresh$));
  }

  public openFacilityReport(comment: ActionOutViewModel): void {
    this.dashboardStateService.openFacilityReport(comment.reportingObjectId);
  }

  public goToEnergyManagement(): void {
    this.dashboardStateService.goToEnergyManagement(this.dataModelOptions);
  }

  private getActions$(
    facilityIds: number[],
    model: CommentsWidgetOptions
  ): Observable<ActionOutViewModel[]> {
    if (!model || !Array.hasItems(facilityIds)) {
      return of([]);
    }

    const filterArray = Object.integerKeys(model.selectedCommentTypes)
      .filter((at: ActionType.K | ActionType.KE) => model.selectedCommentTypes[at]);

    if (!filterArray.hasItems()) {
      return of([]);
    }

    const profileId = this.userService.profileId;
    const params = new ActionsQueryParameters({
      actionTypes: filterArray,
      latestCount: this._isMobile ? model.numberToShowMobile : model.numberToShow,
      reportingObjectId: facilityIds,
    });

    return this.attachmentsClient.getActionsUsingPost(profileId, undefined, params).pipe(
      this.widgetQueue.queue('attachments', this._destroy),
      takeUntil(this._destroy)
    );
  }
}
