import meterMassEditTemplate from 'raw-loader!./meter-mass-edit.html';
import meterMassEditController from './meter-mass-edit.controller';

export default {
  template: meterMassEditTemplate,
  controller: meterMassEditController,
  controllerAs: 'vm',
  bindings: {
    selectedMeters: '<',
    dismiss: '<',
    close: '<',
    onSpreadsheetCreate: '&',
    onEditsDone: '&',
    handleModalClose: '&'
  }
};
