import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Investigation } from '@enerkey/clients/attachments';

@Component({
  selector: 'investigation-status-icon',
  templateUrl: './investigation-status-icon.component.html',
  styleUrls: ['./investigation-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestigationStatusIconComponent {
  public readonly Investigation: typeof Investigation = Investigation;

  @Input() public status: Investigation = null;
  @Input() public active: boolean = true;
  @Input() public small: boolean = false;
}
