<form [formGroup]="formGroup">
  <value-type-select
    formControlName="valueOption"
    *labelBefore="'FACILITIES_TOOLBAR.VALUE_OPTION' | translate"
  ></value-type-select>
  <quantity-dropdown
    formControlName="selectedQuantityId"
    [quantities]="quantities$ | async"
    *labelBefore="'FACILITIES.QUANTITIES' | translate"
  ></quantity-dropdown>
  <widget-timeframe-select
    formControlName="timeFrameOption"
    *labelBefore="'TIMEFRAME_OPTIONS.TITLE' | translate"
  ></widget-timeframe-select>
  <widget-comparison-period-select
    formControlName="comparisonPeriodOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARISON_SERIE' | translate"
    [showNoneOption]="true"
  ></widget-comparison-period-select>
  <comparability-select
    formControlName="comparableOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARABLE' | translate"
  ></comparability-select>
</form>
