import ManualQaServiceModule from '../../services';
import ManualQaSearchCriteriaComponent from './manual-qa-search-criteria.component';

const dependencies = [ManualQaServiceModule.name];

const ManualQaSearchCriteriaModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-criteria', dependencies)
  .component('manualQaSearchCriteria', ManualQaSearchCriteriaComponent)
;

export default ManualQaSearchCriteriaModule;
