<table>
  <colgroup>
    <!-- Make first column wider than the rest -->
    <col width="30%" >
  </colgroup>
  <ng-container *ngFor="let quantityId of quantityIds">
    <ng-container *ngIf="facilityData[quantityId] as quantityConsumptions">
      <thead>
        <tr>
          <th>{{ quantityId | quantityName | async }}</th>
          <th
            *ngFor="let item of quantityConsumptions"
          >
            {{ item.timestamp | date:'yyyy' }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let field of quantityTableFields[quantityId]">
          <td>{{ field | yearlyTrendReportTableCellHeader:quantityId | async }}</td>
          <td *ngFor="let item of quantityConsumptions">{{ item[field] | yearlyTrendReportTableCellValue:field }}</td>
        </tr>
      </tbody>
    </ng-container>
  </ng-container>
</table>
