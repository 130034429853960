import isMobileJs from 'ismobilejs';
import { StateService, TransitionService } from '@uirouter/angularjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { $InjectArgs } from '@enerkey/ts-utils';

export class AppStatusService {
  protected static readonly $inject: $InjectArgs<typeof AppStatusService> = [
    '$window',
    '$state',
    '$transitions',
  ];

  public readonly isMobile: boolean = isMobileJs().any;
  public readonly mobileBreakpoint = 'large';
  public readonly mobileBreakpointWidth = 768;

  public isMobileBreakpoint: boolean = false;
  public readonly isMobileWidth$: Observable<boolean>;
  public isMobileWidth: boolean = false;
  public inModal: boolean = false;

  private readonly _isMobileWidth$ = new BehaviorSubject<boolean>(false);
  private readonly _updateMobileStatus: () => void;

  public constructor(
    private readonly $window: ng.IWindowService,
    private readonly $state: StateService,
    private readonly $transitions: TransitionService
  ) {
    this.isMobileWidth$ = this._isMobileWidth$.pipe(
      distinctUntilChanged()
    );

    this._updateMobileStatus = () => {
      const width = this.$window.innerWidth;
      this.isMobileBreakpoint = width < this.mobileBreakpointWidth;
      this._isMobileWidth$.next(this.isMobileBreakpoint);
      this.isMobileWidth = width < 992;
    };

    this._updateMobileStatus();

    this.$window.addEventListener('resize', this._updateMobileStatus);

    this.$transitions.onSuccess({}, transition => {
      this.inModal = this.$state.includes('modal') || !!transition.to()?.data?.modal;
    });
  }
}
