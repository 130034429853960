import { Component, EventEmitter, HostBinding, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AccordionComponent } from './accordion.component';

/**
 * ### Accordion heading
 * Instead of using `heading` attribute on the `accordion-group`, you can use
 * an `accordion-heading` attribute on `any` element inside of a group that
 * will be used as group's header template.
 */
@Component({
  selector: 'accordion-group',
  templateUrl: './accordion-group.component.html'
})
export class AccordionPanelComponent implements OnInit, OnDestroy {
  /** turn on/off animation */
  public isAnimated = false;
  /** Clickable text in accordion's group header, check `accordion heading` below for using html in header */
  @Input() public heading: string;
  /** Provides an ability to use Bootstrap's contextual panel classes
   * (`panel-primary`, `panel-success`, `panel-info`, etc...).
   * List of all available classes [available here]
   * (https://getbootstrap.com/docs/3.3/components/#panels-alternatives)
   */
  @Input() public panelClass: 'panel-accordion-primary';
  /** if <code>true</code> — disables accordion group */
  @Input() public isDisabled: boolean;
  /** Emits when the opened state changes */
  @Output() public readonly isOpenChange: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('style.display') public display = 'block';

  // Questionable, maybe .panel-open should be on child div.panel element?
  /** Is accordion group open or closed. This property supports two-way binding */
  @HostBinding('class.panel-open')
  @Input()
  public get isOpen(): boolean {
    return this._isOpen;
  }

  public set isOpen(value: boolean) {
    if (value !== this.isOpen) {
      if (value) {
        this.accordion.closeOtherPanels(this);
      }
      this._isOpen = value;
      Promise.resolve(null).then(() => {
        this.isOpenChange.emit(value);
      }).catch(() => { });
    }
  }

  protected _isOpen = false;
  protected accordion: AccordionComponent;

  public constructor(@Inject(AccordionComponent) accordion: AccordionComponent) {
    this.accordion = accordion;
  }

  public ngOnInit(): void {
    this.accordion.addGroup(this);
  }

  public ngOnDestroy(): void {
    this.accordion.removeGroup(this);
  }

  public toggleOpen(): void {
    if (!this.isDisabled) {
      this.isOpen = !this.isOpen;
    }
  }
}
