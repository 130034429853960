import { RouteAuthLogic, UIStateNg2 } from '../../shared/routing';
import { Service } from '../../constants/service';

export const energyManagementTopbarState: UIStateNg2<'energy-management'> = {
  name: 'energy-management',
  data: {
    translationKey: 'ENERGY_MANAGEMENT.TITLE',
    auth: {
      services: [Service.Actions, Service.Comments, Service.ConsumptionTargets, Service.Alarms],
      serviceLogic: RouteAuthLogic.Any,
    },
  },
};
