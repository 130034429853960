import ManualQaServiceModule   from '../../services';
import ManualQaSearchComponent from './manual-qa-search-facilities-component';

const dependencies = [ManualQaServiceModule.name];

const ManualQaSearchFacilitiesModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-facilities', dependencies)
  .component('manualQaSearchFacilities', ManualQaSearchComponent)
;

export default ManualQaSearchFacilitiesModule;
