<modal-view-header
  [headingText]="'ADMIN.MODIFY_USERS.MODIFY_USERS_FORM_TITLE' | translate"
></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="massEditForm"
    (ngSubmit)="submit()"
    id="massEditForm"
  >
    <ek-combo
      class="customWidth marginBottom"
      formControlName="field"
      [items]="fieldList"
      *labelBefore="'ADMIN.MODIFY_USERS.SELECT_FIELD' | translate"
    ></ek-combo>
    <ng-container [ngSwitch]="massEditForm.controls.field.value">
      <ng-container *ngSwitchCase="Fields.ACCOUNT_END_DATE">
        <kendo-datepicker
          class="customWidth"
          formControlName="value"
          *labelBefore="'ADMIN.ACCOUNT_END_DATE' | translate"
        ></kendo-datepicker>
      </ng-container>
      <ng-container *ngSwitchCase="Fields.COMMENT">
        <textarea
          formControlName="value"
          type="text"
          kendoTextArea
          class="customWidth"
          rows="5"
          *labelBefore="'ADMIN.COMMENT' | translate"
        ></textarea>
      </ng-container>
      <ng-container *ngSwitchCase="Fields.ORGANIZATION">
        <company-dropdown
          class="customWidth"
          formControlName="value"
        ></company-dropdown>
      </ng-container>
      <ng-container *ngSwitchCase="Fields.LANGUAGE">
        <language-select
          class="customWidth"
          formControlName="value"
          *labelBefore="'ADMIN.LANGUAGE' | translate"
        >
      </language-select>
      </ng-container>
      <ng-container *ngSwitchCase="Fields.BULK_CREATE_THIRD_PARTY_SYSTEM_FIELD">
        <input
          formControlName="value"
          type="text"
          kendoTextBox
          class="customWidth"
          *labelBefore="'ADMIN.BULK_CREATE_THIRD_PARTY_SYSTEM_FIELD' | translate"
        />
      </ng-container>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="massEditForm"
      [loading]="loading$ | async"
      [disabled]="!massEditForm.valid"
    >
      {{ 'ADMIN.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
