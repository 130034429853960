import { guid } from '@enerkey/ts-utils';

export class MeterHistoryItem {
  public readonly id: string;
  public readonly meterId: number;
  public readonly created: Date;
  public readonly createdBy: string;
  public readonly customerMeterIdentifier: string;
  public readonly date: Date;
  public readonly fromDate: Date;
  public readonly description: string;
  public readonly factor: number;
  public readonly isTwoTimeMeasurement: boolean;
  public readonly lastModified: Date;
  public readonly lastModifiedBy: string;
  public readonly meteringType: number;
  public readonly name: string;
  public readonly quantityId: number;
  public readonly automaticReadingStartTime: Date;

  public constructor(values?: any) {
    this.id = guid();
    this.meterId = values && values.meterId ? parseInt(values.meterId, 10) : null;
    this.created = values && values.created ? new Date(values.created) : null;
    this.createdBy = values && values.createdBy ? values.createdBy : null;
    this.customerMeterIdentifier = values && values.customerMeterIdentifier ? values.customerMeterIdentifier : '';
    this.date = values && values.date ? new Date(values.date) : null;
    this.fromDate = values && values.fromDate ? new Date(values.fromDate) : null;
    this.description = values && values.description ? values.description : '';
    this.factor = values && values.factor ? parseFloat(values.factor) : null;
    this.isTwoTimeMeasurement = values && values.isTwoTimeMeasurement ? values.isTwoTimeMeasurement : false;
    this.lastModified = values && values.lastModified ? new Date(values.lastModified) : null;
    this.lastModifiedBy = values && values.lastModifiedBy ? values.lastModifiedBy : null;
    this.meteringType = values && values.meteringType ? parseInt(values.meteringType, 10) : null;
    this.name = values && values.name ? values.name : null;
    this.quantityId = values && values.quantityId ? values.quantityId : null;
    this.automaticReadingStartTime = values && values.automaticReadingStartTime ?
      new Date(values.automaticReadingStartTime) :
      null;
  }
}
