import Meter from '../../shared/meter';
import { getInfoStringFromArray } from '../../shared/utils.functions';

/**
 * Returns meter info string
 *
 * @param {Meter} meter
 * @param {Object} translations
 */
export const getMeterInfoString = (meter, translations) =>
  meter instanceof Meter
    ? getInfoStringFromArray([
      meter.getQuantity().getName(),
      getFactorInfoString(meter, translations),
      getMeterMeasurementString(meter, translations),
      getAutomaticModelingString(meter, translations)
    ])
    : [];

/**
 * Returns meter info factor string
 *
 * @param {Meter} meter
 * @param {Object} translations
 *
 * @returns {string}
 */
function getFactorInfoString(meter, translations) {
  return meter.getFactor() !== null ? `${meter.getFactor()} (${translations['MQA.INSPECT.FACTOR']})` : '';
}

/**
 * Returns meter info measurement string
 *
 * @param {Meter} meter
 * @param {Object} translations
 *
 * @returns {string}
 */
function getMeterMeasurementString(meter, translations) {
  return meter.getIsTwoTimeMeasurement() ? translations['MQA.INSPECT.TWO_TIME'] : translations['MQA.INSPECT.ONE_TIME'];
}

/**
 * Returns meter info measurement string
 *
 * @param {Meter} meter
 * @param {Object} translations
 *
 * @returns {string}
 */
function getAutomaticModelingString(meter, translations) {
  return meter.getAutomaticModeling()
    ? translations['MQA.INSPECT.AUTOMATIC_MODELING']
    : translations['MQA.INSPECT.NO_AUTOMATIC_MODELING'];
}
