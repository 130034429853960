import EnerkeyServiceModule           from '../../../../services';
import ManualQaSearchListDeleteModule from '../manual-qa-search-list-delete';
import ManualQaSearchListEditModule   from '../manual-qa-search-list-edit';
import SearchTypeRadioModule          from '../search-type-radio';
import TimeFrameRadioModule           from '../time-frame-radio';
import ManualQaSearchListComponent    from './manual-qa-search-list-component';
import ManualQaSearchListService      from './manual-qa-search-list-service';

const dependencies = [
  'kendo.directives',
  EnerkeyServiceModule.name,
  ManualQaSearchListDeleteModule.name,
  ManualQaSearchListEditModule.name,
  TimeFrameRadioModule.name,
  SearchTypeRadioModule.name
];

const ManualQaSearchListModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-list', dependencies)
  .component('manualQaSearchList', ManualQaSearchListComponent)
  .service('ManualQaSearchListService', ManualQaSearchListService)
;

export default ManualQaSearchListModule;
