import { Injectable, Injector } from '@angular/core';

import { ModalService } from '@enerkey/foundation-angular';

import { TerminalAddMeterComponent } from '../terminal/terminal-add-meter/terminal-add-meter.component';
import { TerminalRemoveMeterComponent } from '../terminal/terminal-remove-meter/terminal-remove-meter.component';
import { TerminalStatusModalComponent } from '../terminal/terminal-status-modal/terminal-status-modal.component';
import { TerminalEditModalComponent } from '../terminal/terminal-edit-modal/terminal-edit-modal.component';
import { ConfigurationControlClient, Terminal } from '@enerkey/clients/configuration-control';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class TerminalService {

  public constructor(
    private modalService: ModalService,
    private injector: Injector,
    private toasterService: ToasterService,
    private configurationControlClient: ConfigurationControlClient
  ) {
  }

  public getAddMetersToTerminalModal(enegiaIds: number[], meterIds: number[]): Promise<unknown> {
    const modalInstance = this.modalService.open(TerminalAddMeterComponent, { injector: this.injector });
    modalInstance.componentInstance.enegiaIds = enegiaIds;
    modalInstance.componentInstance.meterIds = meterIds;
    return modalInstance.result;
  }

  public getRemoveMetersFromTerminalModal(terminalName: string, meterIds: number[]): Promise<unknown> {
    const modalInstance = this.modalService.open(TerminalRemoveMeterComponent, { injector: this.injector });
    modalInstance.componentInstance.terminalName = terminalName;
    modalInstance.componentInstance.meterIds = meterIds;
    return modalInstance.result;
  }

  public getTerminalStatusModal(terminalName: string): void {
    this.configurationControlClient.getTerminalByName(terminalName)
      .subscribe(
        response => {
          const modalInstance = this.modalService.open(TerminalStatusModalComponent, { injector: this.injector });
          modalInstance.componentInstance.terminal = response;
        },
        () => this.showError('ADMIN.TERMINAL_NOT_FOUND.ERROR')
      );
  }

  public getTerminalEditModal(enegiaId: number, terminal: Terminal): void {
    const modalInstance = this.modalService.open(TerminalEditModalComponent, { injector: this.injector });
    modalInstance.componentInstance.enegiaId = enegiaId;
    modalInstance.componentInstance.terminal = terminal;
  }

  private showError(text: string): void {
    this.toasterService.error(text);
  }
}
