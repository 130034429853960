import { IAngularStatic } from 'angular';

import EnerKeyServicesModule from '../../../services';
import ManualQaMeteringService from './manual-qa-metering-service';
import ManualQaMeterHierarchyService from './manual-qa-meter-hierarchy-service';
import ManualQaPopupService from './manual-qa-popup.service';
import ManualQaTabsServiceModule from './manual-qa-tabs-service';
import ManualQaApiServiceModule from './manual-qa-api';
import ManualQaApiInfoServiceModule from './manual-qa-api-info';
import ManualQaApiQuantityModule from './manual-qa-api-quantity';
import ManualQaReaderTypeModule from './manual-qa-api-reader-type';
import ManualQaApiMeterService from './manual-qa-api-meter-service';
import ManualQaApiRawReadings from './manual-qa-api-raw-readings-service';
import { ManualQaSpreadsheetService } from './mqa-spreadsheet.service';
import { ManualQaApiSearchService } from './manual-qa-api-search-service';
import { ManualQaViewSettingService } from './manual-qa-view-setting-service';
import { ManualQaApiDefectIssue } from './manual-qa-api-defect-issue';
import { ManualQaMockService } from './manual-qa-mock-service';

declare const angular: IAngularStatic;

const dependencies = [
  EnerKeyServicesModule.name,
  ManualQaTabsServiceModule.name,
  ManualQaApiServiceModule.name,
  ManualQaApiInfoServiceModule.name,
  ManualQaApiQuantityModule.name,
  ManualQaReaderTypeModule.name
];

const ManualQaServiceModule = angular
  .module('ManualQA.Services', dependencies)
  .service('ManualQaPopupService', ManualQaPopupService)
  .service('ManualQaMeteringService', ManualQaMeteringService)
  .service('ManualQaMeterHierarchyService', ManualQaMeterHierarchyService)
  .service('MqaSpreadsheetService', ManualQaSpreadsheetService)
  .service('ManualQaApiMeterService', ManualQaApiMeterService)
  .service('ManualQaApiRawReadings', ManualQaApiRawReadings)
  .service('ManualQaApiSearchService', ManualQaApiSearchService)
  .factory('ManualQaViewSettingService', ManualQaViewSettingService)
  .factory('ManualQaApiDefectIssue', ManualQaApiDefectIssue)
  .provider('ManualQaMockService', ManualQaMockService)
;

export default ManualQaServiceModule;
