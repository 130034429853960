import { Directive, HostBinding, Optional } from '@angular/core';
import { ExcelCommandDirective as GridExcelCommandDirective } from '@progress/kendo-angular-grid';
import { ExcelCommandDirective as TreeListExcelCommandDirective } from '@progress/kendo-angular-treelist';

@Directive({
  selector: '[kendoGridExcelCommand],[kendoTreeListExcelCommand]'
})
export class KendoGridExcelCommandDirective {
  @HostBinding('type') public buttonType = 'button';

  public constructor(
  @Optional() gridExcelCommand: GridExcelCommandDirective,
    @Optional() treeListExcelCommand: TreeListExcelCommandDirective
  ) {
    const excelCommand = gridExcelCommand ?? treeListExcelCommand;
    if (excelCommand) {
      excelCommand.icon = 'excel';
    }
  }
}
