import ChartCategoryConfig from './chart-category-config';
import ChartConfig from './chart-config';
import ConfigWrapper from './config-wrapper';

const $inject = ['utils'];

class DonutVisualsService {
  constructor(utils) {
    this.utils = utils;
    this.chartTranslations = {
      sum: utils.localizedString('DONUT_VISUALS.SUM'),
      average: utils.localizedString('DONUT_VISUALS.AVERAGE'),
      missingSerie: utils.localizedString('DONUT_VISUALS.SERIE_TITLE_MISSING')
    };
  }

  getChartsConfig(configs, visibleColumns, viewData, totalAggregates) {
    const categories = [];

    configs
      .filter(conf => conf.columns.some(col => visibleColumns.find(v => v.field === col.field)))
      .forEach(config => {
        categories.push(this.getChartCategory(config, viewData, totalAggregates, visibleColumns));
      });

    return categories;
  }

  getChartCategory(config, viewData, totalAggregates, visibleColumns) {
    const charts = [];

    config.columns
      .filter(col => visibleColumns.find(v => v.field === col.field))
      .forEach(column => {
        const chartConfigs = new ConfigWrapper(column, visibleColumns, viewData, totalAggregates);
        charts.push(new ChartConfig(chartConfigs, this.chartTranslations));
      });

    const categoryTitle = this.utils.localizedString(config.title);
    return new ChartCategoryConfig(charts, categoryTitle);
  }
}

DonutVisualsService.$inject = $inject;

export default DonutVisualsService;
