/**
 * Status for private search lists
 *
 * @type {number}
 */
export const PRIVATE = 1;

/**
 * Status for public search lists
 *
 * @type {number}
 */
export const PUBLIC = 0;
