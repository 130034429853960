import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { EkDropdownComponent } from '../ek-dropdown/ek-dropdown.component';

/**
 * Use inside `ek-dropdown`.
 */
@Component({
  selector: 'ek-dropdown-item',
  templateUrl: './ek-dropdown-item.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkDropdownItemComponent {

  @Input() public disabled: boolean = false;
  @Output() public readonly click = new EventEmitter<MouseEvent>();

  public constructor(
    @Optional() private readonly hostDropdown: EkDropdownComponent
  ) { }

  public onClick($event: MouseEvent): void {
    $event.stopPropagation();

    if (!this.disabled) {
      this.click.emit($event);

      if (this.hostDropdown) {
        this.hostDropdown.hide();
      }
    }
  }

}
