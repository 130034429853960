import { Pipe, PipeTransform } from '@angular/core';

import { ValuePoint } from '../shared/consumptions';

type RelationalValuesField = keyof Pick<ValuePoint, 'relationalValues'>;

@Pipe({
  name: 'consumptionChartRelationalValueField'
})
export class ConsumptionChartRelationalValueFieldPipe implements PipeTransform {
  private readonly relationalValueField: RelationalValuesField = 'relationalValues';

  public transform(relationalValueId: number): string {
    return `${this.relationalValueField}.${relationalValueId}`;
  }
}
