<label
  *ngIf="!noLabel"
  [attr.for]="componentId"
>{{ 'DOCUMENTS.ORGANIZATION_NAME' |  translate }}</label>
<kendo-dropdownlist
  [attr.id]="componentId"
  [valuePrimitive]="true"
  [data]="companies$ | async"
  [valueField]="'id'"
  [textField]="'name'"
  [kendoDropDownFilter]="filterSettings"
  [(ngModel)]="companyId"
  (ngModelChange)="valueChange($event)"
  [loading]="loading$ | async"
  [virtual]="true"
  [disabled]="disabled$ | async"
></kendo-dropdownlist>
