<a
  class="topbar-icon-item"
  (click)="copyAccessToken()"
  [ngfTooltip]="'TOPBAR.COPY_ACCESS_TOKEN' | translate"
  [placement]="'bottom'"
  [alignment]="'left'"
>
  <i class="fa fa-copy"></i>
  <span *ngIf="isMobile" class="topbar-mobile-title">
    {{ 'TOPBAR.COPY_ACCESS_TOKEN' | translate }}
  </span>
</a>
