<div class="widget">
  <div class="facility-header-wrapper">
    <facility-select-header [formControl]="facilityId"></facility-select-header>
  </div>
  <form [formGroup]="formGroup">
    <ek-combo
      formControlName="executionPhase"
      [items]="executionPhaseOptions"
      *labelBefore="'ACTIONS.EXECUTIONPHASE' | translate"
    ></ek-combo>
    <button
      class="button button--link button--external-link"
      [disabled]="loading$ | async"
      (click)="goToEnergyManagement()"
    >
      {{ 'LATEST_ACTIONS_WIDGET.SHOW_ALL_ACTIONS' | translate }}
    </button>
  </form>

  <table class="table striped">
    <thead>
      <th style="width: 50%;">
        {{ 'ACTIONS.REPORTEDDESCRIPTION' | translate }}
      </th>
      <th style="width: 40%;">
        {{ 'LATEST_ACTIONS_WIDGET.UPDATED_AT' | translate }}
      </th>
      <th>
        {{ 'ACTIONS.INVESTIGATION' | translate }}
      </th>
    </thead>
    <tbody *ngIf="actions$ | async as actions">
      <tr *ngFor="let action of actions">
        <td class="ellipsis">
          <span title="{{ action.reportedDescription }}">
            <a
              class="reported-description"
              (click)="openEditModal(action)"
            >
              {{ action.reportedDescription }}
            </a>
          </span>
        </td>
        <td>
          {{ action.updatedAt | date }}
        </td>
        <td>
          <investigation-status [status]="action?.investigation"></investigation-status>
        </td>
      </tr>
    </tbody>
  </table>
</div>
