import ManualQaServiceModule         from '../../services';
import SearchTypeRadioModule         from '../search-type-radio';
import TimeFrameRadioModule          from '../time-frame-radio';
import ManualQaSearchListEdit        from './manual-qa-search-list-edit-component';
import ManualQaSearchListEditService from './manual-qa-search-list-edit-service';

const dependencies = [ManualQaServiceModule.name, TimeFrameRadioModule.name, SearchTypeRadioModule.name];

const ManualQaSearchListEditModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-list-edit', dependencies)
  .component('manualQaSearchListEdit', ManualQaSearchListEdit)
  .service('ManualQaSearchListEditService', ManualQaSearchListEditService)
;

export default ManualQaSearchListEditModule;
