import { NgModule } from '@angular/core';

import { ModalService } from './modal';
import { NgfModalWindow } from './modal-window';

export { ModalService } from './modal';
export { NgfModalConfig, NgfModalOptions } from './modal-config';
export { NgfModalRef, NgfActiveModal } from './modal-ref';
export { ModalDismissReasons } from './modal-dismiss-reasons';
export { ModalSize } from './modal-size';
export { ModalConfirmDismiss } from './modal-confirm-dismiss';
export { ConfirmModalDismiss, getModalOptions, ModalBase, ModalOptions } from './modal-base';
export { NgfModalRefMock, NgfActiveModalMock } from './modal-ref.mock';

@NgModule({
  declarations: [NgfModalWindow],
  providers: [ModalService]
})
export class NgfModalModule {
}
