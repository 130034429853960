import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { distinctUntilChanged, map, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';
import { GroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { UntypedFormBuilder } from '@angular/forms';
import {
  ExcelExportEvent,
  GroupKey,
  GroupRowArgs,
  RowClassArgs
} from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { TranslateService } from '@ngx-translate/core';

import { ModalService } from '@enerkey/foundation-angular';
import { Co2eq, IUpdateRowUnit, Report, RowMetadata, RowUnitType, Scope } from '@enerkey/clients/sustainability';
import { CellClickEventOf, CellCloseEventOf } from '@enerkey/ts-utils';
import { anyOf, indicate, LoadingSubject } from '@enerkey/rxjs';

import { GriReportService } from '../../services/gri-report.service';
import { GriUnitEditorModalComponent } from '../gri-unit-editor-modal/gri-unit-editor-modal.component';
import {
  getGriRowValuesFromMetadata,
  GRI_VALUE_FIELDS,
  GriEditorRow,
  IGriEditorRow,
  isGriMonthValueField,
  spreadTo12Months,
} from '../../models/gri-report-row';
import { GriRowEditModalComponent } from '../gri-row-edit-modal/gri-row-edit-modal.component';
import { DialogService } from '../../../../shared/services/dialog.service';
import { GriDataImportModalComponent } from '../gri-data-import-modal/gri-data-import-modal.component';
import { GriCo2factorPipe } from '../../pipes/gri-co2factor.pipe';
import { GriScopeNamePipe } from '../../pipes/gri-scope-name.pipe';
import { GriImportService } from '../../services/gri-import.service';
import { QuantitySortOptions } from '../gri-unit-combo/gri-unit-combo.component';

function getGroupDescriptors(): SortDescriptor[] {
  return [{ field: 'category.scope' }, { field: 'category.id' }, { field: 'unit.quantityId' }];
}

@Component({
  selector: 'gri-report-container',
  templateUrl: './gri-report-container.component.html',
  styleUrls: ['./gri-report-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GriCo2factorPipe]
})
export class GriReportContainerComponent implements OnInit, OnDestroy {

  public readonly formatPrecise: string = '#,#.00';
  public readonly formatApprox: string = '#,#';
  public gridGrouping: GroupDescriptor[];
  public readonly gridSorting: SortDescriptor[];
  public readonly valueFields = GRI_VALUE_FIELDS;
  public readonly Co2eq = Co2eq;

  public showExpandedColumns: boolean = false;
  public quantitySorter: QuantitySortOptions;

  @Input() public expandedGroupKeys: GroupKey[];
  @Output() public readonly expandedGroupKeysChange = new EventEmitter<GroupKey[]>();

  @Input() public report: Report;
  @Output() public readonly isModifiedChanged = new EventEmitter<boolean>();
  public rows: GriEditorRow[] = [];

  public get excelFileName(): string {
    return this.report ? `${this.report.displayName}.xlsx` : 'report.xlsx';
  }

  public readonly hasChanges$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;
  public readonly targetsByCategoryId$: Observable<Record<number, number>>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();
  private readonly _hasChanges$ = new Subject<boolean>();

  @ViewChild('metadataGrid')
  private readonly metadataGridTemplate: TemplateRef<unknown>;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modalService: ModalService,
    private readonly dialogService: DialogService,
    private readonly injector: Injector,
    private readonly service: GriReportService,
    private readonly scopeNamePipe: GriScopeNamePipe,
    private readonly translateService: TranslateService,
    private readonly importService: GriImportService,
    private readonly griCo2factorPipe: GriCo2factorPipe
  ) {
    this.gridGrouping = getGroupDescriptors();
    this.gridSorting = [{ field: 'category.name' }];
    this.getExportData = this.getExportData.bind(this);

    this.loading$ = anyOf(this._loading$, this.service.loading$).pipe(takeUntil(this._destroy$));

    this.targetsByCategoryId$ = this.service.targets$;

    this.hasChanges$ = anyOf(this._hasChanges$, this.service.changes$).pipe(takeUntil(this._destroy$));

    // sadly we need this value synchronously for transition/beforeunload handlers
    this.hasChanges$.pipe(distinctUntilChanged()).subscribe({
      next: changes => {
        this.isModifiedChanged.next(changes);
      },
    });
  }

  public ngOnInit(): void {
    this.service.rows$.subscribe(rows => {
      this.rows = rows.map(row => {
        if (row.category) {
          row.category.name = this.translateService.instant(row.category.name);
        }
        if (row.unit) {
          row.emissionFactor = this.griCo2factorPipe.transform(row.unit);
        }
        return row;
      });

      this.quantitySorter = this.processUnitSortOptions(this.rows);

      this.refreshHasChanges();
      this.changeDetector.detectChanges();
    });

    this.service.dataRefreshed$.subscribe(() => {
      this.changeDetector.detectChanges();
      // force re-grouping in case row category was changed
      this.gridGrouping = getGroupDescriptors();
    });

    this.service.units$.subscribe(units => {
      for (const row of this.rows) {
        row.unit = units.find(u => u.id === row.unit?.id);
      }
    });

    this.service.categories$.subscribe(categories => {
      for (const row of this.rows) {
        row.category = categories.find(u => u.id === row.category.id);
      }

      // force re-grouping of grid in case a category scope was changed
      this.gridGrouping = getGroupDescriptors();
      this.expandedGroupKeys = [...this.expandedGroupKeys];
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._hasChanges$.complete();
    this.isModifiedChanged.complete();
  }

  public addRow(categoryId?: number): void {
    const modal = this.modalService.open(GriRowEditModalComponent, { injector: this.injector });
    modal.componentInstance.categoryId = categoryId;
    modal.componentInstance.quantitySorter = this.quantitySorter;
    modal.result.then(
      () => this.refreshHasChanges(),
      /* istanbul ignore next */() => { }
    );
  }

  public editRow(row: GriEditorRow): void {
    const modal = this.modalService.open(GriRowEditModalComponent, { injector: this.injector });
    modal.componentInstance.existingRow = row;
    modal.componentInstance.quantitySorter = this.quantitySorter;
    modal.result.then(
      () => this.refreshHasChanges(),
      /* istanbul ignore next */() => { }
    );
  }

  public removeRow(row: GriEditorRow): void {
    this.dialogService.getConfirmationModal({
      title: 'CONFIRM_DELETE',
      text: 'SUSTAINABILITY.GRI.CONFIRM_DELETE_ROW',
      translate: true,
      isDelete: true,
    }).subscribe({
      next: () => this.service.removeRow(row),
      error: /* istanbul ignore next */ () => { },
    });
  }

  public refreshRows(rows: GriEditorRow[]): void {
    const importedRows = rows.filter(row => row?.metadata?.consumptions);
    if (importedRows.length === 0) {
      return;
    }
    this.dialogService.getConfirmationModal({
      title: 'RELOAD',
      text: this.translateService.instant(
        'SUSTAINABILITY.GRI.CONFIRM_RELOAD_GROUP',
        { rowsCount: importedRows.length }
      ),
      translate: true,
    }).subscribe({
      next: () => this.refreshRowsValues(importedRows),
      error: /* istanbul ignore next */ () => { },
    });

  }

  public hasImportedRows(rows: GriEditorRow[]): boolean {
    return rows.filter(row => row?.metadata?.consumptions).length > 0;
  }

  public removeGroup(rows: GriEditorRow[]): void {
    this.dialogService.getConfirmationModal({
      title: 'CONFIRM_DELETE',
      text: this.translateService.instant(
        'SUSTAINABILITY.GRI.CONFIRM_DELETE_GROUP',
        { rowsCount: rows.length }
      ),
      translate: true,
      isDelete: true,
    }).subscribe({
      next: () => {
        for (const row of rows) {
          this.service.removeRow(row);
        }
      },
      error: /* istanbul ignore next */ () => { },
    });

  }

  public openUnitEditModal(unit: IUpdateRowUnit = null): void {
    const modal = this.modalService.open(GriUnitEditorModalComponent, { injector: this.injector });
    modal.componentInstance.existingUnit = unit;
  }

  public openImportModal(): void {
    const modal = this.modalService.open(GriDataImportModalComponent, { injector: this.injector });
    modal.componentInstance.currentReport = this.report;
  }

  public resetRowValues(dataItem: GriEditorRow, zero: boolean): void {
    dataItem.changes.reset(zero);
    this.refreshHasChanges();
  }

  public openMetadataGrid(dataItem: GriEditorRow): void {
    this.modalService.open(this.metadataGridTemplate, { context: { dataItem }, backdrop: true });
  }

  public cellClickHandler(event: CellClickEventOf<GriEditorRow>): void {
    if (
      event.isEdited ||
      event.dataItem?.metadata // imported data should not be editable
    ) {
      return;
    }

    const field: keyof IGriEditorRow = event.column.field;

    let value: unknown = event.dataItem[field];

    if (field === 'co2total') {
      if (!Number.isFinite((event.dataItem as IGriEditorRow).unit?.co2Factor)) {
        return;
      }

      value = event.dataItem[field];
    }

    if (isEditableField(field)) {
      event.sender.editCell(
        event.rowIndex,
        event.columnIndex,
        this.formBuilder.group({ [field]: value })
      );
    }
  }

  public cellCloseHandler(args: CellCloseEventOf<GriEditorRow>): void {
    // Don't propagate changes if esc was used to close the editor
    if (args.originalEvent instanceof KeyboardEvent && args.originalEvent.key === 'Escape') {
      return;
    }

    const formGroup = args.formGroup;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
      return;
    }

    if (!formGroup.dirty) {
      return;
    }

    const field: keyof IGriEditorRow = args.column.field;

    if (isGriMonthValueField(field)) {
      args.dataItem[field] = formGroup.value[field];
    } else if (field === 'description') {
      args.dataItem[field] = formGroup.value[field];
    } else if (field === 'totalValue') {
      const total = Number(formGroup.value[field]);

      if (Number.isFinite(total)) {
        spreadTo12Months(total, args.dataItem);
      }
    } else if (field === 'co2total') {
      const total = Number(formGroup.value[field]);
      const co2factor = args.dataItem.unit?.co2Factor;

      if (Number.isFinite(total) && Number.isFinite(co2factor) && co2factor !== 0) {
        spreadTo12Months(total / co2factor, args.dataItem);
      }
    } else {
      return;
    }

    this.refreshHasChanges();
  }

  public refreshHasChanges(): void {
    this._hasChanges$.next(this.rows.length > 0 && this.rows.some(r => r.changes.anyChanges));
  }

  public groupKey = (groupRow: GroupRowArgs): string => {
    if (!groupRow) {
      return null;
    }
    return groupRow.groupIndex;
  };

  public expandedChange(keys: GroupKey[]): void {
    this.expandedGroupKeysChange.next(keys);
  }

  public rowClassCallback = (context: RowClassArgs): { [x: string]: boolean } =>
    ({ [`${context.dataItem.category.scope}-row`]: true });

  public getExportData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.rows, {
        sort: [
          { field: 'category.scope', dir: 'asc' },
          { field: 'category.id', dir: 'asc' },
          { field: 'unit.quantityId', dir: 'asc' }
        ],
      }).data
    };
    return result;
  }

  public onExcelExport(e: ExcelExportEvent): void {
    const rows = e.workbook.sheets[0].rows;
    rows.forEach((r: { type: string, cells: { value: string }[] }) => {
      if (r.type === 'data') {
        r.cells[0].value = this.scopeNamePipe.transform(r.cells[0].value as Scope, true);
        if (r.cells[3].value) {
          // translate default unit names
          r.cells[4].value = this.translateService.instant(r.cells[4].value);
        }
      }
      r.cells.splice(3, 1); // remove isDefault column
    });
  }

  private processUnitSortOptions(
    rows: GriEditorRow[]
  ): QuantitySortOptions {
    const countryCodes: string[] = [];
    const customSources: string[] = [];
    const facilitySources: string[] = [];

    for (const row of rows) {
      switch (row.unit?.rowUnitType) {
        case RowUnitType.Location:
          countryCodes.push(row.unit.source);
          break;
        case RowUnitType.Facility:
          facilitySources.push(row.unit.source);
          break;
        case RowUnitType.Custom:
          customSources.push(row.unit.source);
          break;
      }
    }

    return {
      countries: [...new Set(countryCodes)],
      facilitySources: [...new Set(facilitySources)],
      sources: [...new Set(customSources)]
    };
  }

  private refreshRowsValues(rows: GriEditorRow[]): void {
    const allMetersIds: number[] = [];
    for (const row of rows) {
      if (row?.metadata?.consumptions) {
        const metersIds = Object.integerKeys(row.metadata.consumptions);
        allMetersIds.push(...metersIds);
      }
    }

    this.importService.allMeters$.pipe(
      take(1),
      map(meters => meters.filter(m => allMetersIds.includes(m.id)))
    ).pipe(
      switchMap(meters => this.importService.getMetadata(
        this.report.year,
        meters,
        meters.unique('quantityId')[0],
        null
      ).pipe(indicate(this._loading$))),
      takeUntil(this._destroy$)
    ).subscribe({
      next: combinedMetaData => {
        // metadata to each row
        for (const row of rows) {
          const consumptions: RowMetadata['consumptions'] = {};
          for (const meterId of Object.integerKeys(row.metadata.consumptions)) {
            consumptions[meterId] = combinedMetaData.consumptions[meterId];
          }
          row.metadata = new RowMetadata({ ...combinedMetaData, consumptions });

          const values = getGriRowValuesFromMetadata(row.metadata);
          values.forEach((value, index) => {
            row[GRI_VALUE_FIELDS[index]] = value;
          });
        }
        this.refreshHasChanges();
      }
    });
  }
}

function isEditableField(field: keyof IGriEditorRow): boolean {
  return isGriMonthValueField(field)
    || field === 'description'
    || field === 'totalValue'
    || field === 'co2total';
}
