import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit } from '@enerkey/clients/reporting';

@Component({
  selector: 'tr[widget-tooltip-consumption-row]',
  templateUrl: './widget-tooltip-consumption-row.component.html',
  styleUrls: ['./widget-tooltip-consumption-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTooltipConsumptionRowComponent {
  @Input() public time: string;
  @Input() public color: string;
  @Input() public isNormalized: boolean;
  @Input() public quantityId: Quantities;
  @Input() public value: number;
  @Input() public unitKey: ReportingUnit;
}
