import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to suffix characters to a value with optional spacing.
 *
 * @example
 * // With spacing of 1 (default) and strict mode (default)
 * {{ 100 | suffix:'%' }} // Output: "100 %"
 *
 * @example
 * // With spacing of 0 and strict mode
 * {{ 100 | suffix:'%':0 }} // Output: "100%"
 *
 * @example
 * // With spacing of 1 and non-strict mode
 * {{ null | suffix:'%':1:false }} // Output: "null %"
 *
 * @example
 * // With spacing of 1 and strict mode
 * {{ null | suffix:'%' }} // Output: ""
 */
@Pipe({
  name: 'suffix'
})
export class SuffixPipe implements PipeTransform {

  /**
   * Transforms the input value by appending a character with optional spacing.
   *
   * @param value - The input value to transform.
   * @param suffix - The character(s) to append.
   * @param spacing - The number of spaces between the value and the character(s). Default is 1.
   * @param strict - If true, returns an empty string for undefined or null values. Default is true.
   * @returns The transformed string.
   */
  public transform(
    value: number | string,
    suffix: string,
    spacing: number = 1,
    strict: boolean = true
  ): string {
    const spaces = ' '.repeat(spacing);
    return strict && (value === undefined || value === null) ? '' : `${value}${spaces}${suffix}`;
  }
}

