import { ChangeDetectionStrategy, Component, Injector, Input, OnDestroy } from '@angular/core';
import { combineLatest, map, Observable, Subject, takeUntil } from 'rxjs';
import { GroupDescriptor } from '@progress/kendo-data-query';

import { Co2eq, ICategory, IUpdateRowUnit, Report } from '@enerkey/clients/sustainability';
import { ModalService } from '@enerkey/foundation-angular';

import { GriEditorRow } from '../../models/gri-report-row';
import { GriReportService } from '../../services/gri-report.service';
import { GriUnitEditorModalComponent } from '../gri-unit-editor-modal/gri-unit-editor-modal.component';

type UnitSummary = {
  unit: IUpdateRowUnit;
  category: ICategory;
  co2total: number;
  totalValue: number;
  rowsCount: number;
}

@Component({
  selector: 'gri-quantity-grid',
  templateUrl: './gri-quantity-grid.component.html',
  styleUrls: ['./gri-quantity-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GriQuantityGridComponent implements OnDestroy {
  @Input() public report: Report;

  public readonly units$: Observable<UnitSummary[]>;
  public readonly gridGrouping: GroupDescriptor[] = [{ field: 'category.scope' }, { field: 'category.id' }];
  public readonly formatApprox: string = '#,#';
  public readonly Co2eq = Co2eq;

  public readonly loading$: Observable<boolean>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly reportService: GriReportService,
    private readonly modalService: ModalService,
    private readonly injector: Injector
  ) {
    this.units$ = combineLatest([
      this.reportService.rows$,
      this.reportService.units$,
    ]).pipe(
      map(([rows, units]) => this.summarizeUnitsPerCategory(rows, units))
    );
    this.loading$ = this.reportService.loading$.pipe(takeUntil(this._destroy$));
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public openUnitEditModal(unit: IUpdateRowUnit = null): void {
    const modal = this.modalService.open(GriUnitEditorModalComponent, { injector: this.injector });
    modal.componentInstance.existingUnit = unit;
  }

  private summarizeUnitsPerCategory(
    rows: GriEditorRow[],
    units: IUpdateRowUnit[]
  ): UnitSummary[] {
    const unitsById = units.toRecord(u => u.id);
    const unitGroups = [...rows.toGroupsBy('category').values()].map(c => c.toGroupsBy('unit'));
    return unitGroups.flatMap(g => [...g].map(([unit, unitRows]) => ({
      unit: unitsById[unit.id],
      category: unitRows[0].category,
      co2total: unitRows.map(r => r.co2total).reduce((a, b) => a + b),
      totalValue: unitRows.map(r => r.totalValue).reduce((a, b) => a + b),
      rowsCount: unitRows.length
    })));
  }

}
