import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { GroupedConsumptionsWidgetRow } from '../../shared/grouped-consumptions';
import { ChangeWidgetData } from '../change-widget-base';
import { WidgetChangeOption } from '../../../energy-reporting/shared/widget-constants';

@Component({
  selector: 'grouped-consumptions-table',
  templateUrl: './grouped-consumptions-table.component.html',
  styleUrls: ['../change-component-table.scss', './grouped-consumptions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedConsumptionsTableComponent {
  @Input() public data: ChangeWidgetData<GroupedConsumptionsWidgetRow>;
  @Input() public propertyTranslationKey: string;
  @Input() public comparisonPeriodTitle: string;
  @Input() public inspectionPeriodTitle: string;
  @Input() public format: string;
  @Input() public changeType: WidgetChangeOption;

  @Output() public readonly rowClick = new EventEmitter<number[]>();

  public readonly WidgetChangeOption: typeof WidgetChangeOption = WidgetChangeOption;

  public get dataArray(): GroupedConsumptionsWidgetRow[][] {
    return [this.data.gainers, this.data.fallers];
  }
}
