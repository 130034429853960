export default function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      item: '='
    },
    template: [
      '<li id="{{item.guid}}" class="menu-item menu__item" aria-haspopup="false" ng-class="{ \'menu__item--highlight\': item.highlight, \'is-active\': item.selected}">',
        '<span class="menu-item-extra-text" ng-if="item.extraTextKey" translate="{{item.extraTextKey}}">{{item.extraText}}</span>',
        '<a class="menu__link" translate="{{item.titleKey}}"></a>',
        '<ul class="menu menu--child">',
          '<menu-item item="child" ng-if="!child.childs.length" ng-repeat-start="child in item.childs | orderBy:\'weight\'"></menu-item>',
          '<menu-item-with-sub-menu item="child" ng-if="child.childs.length" ng-repeat-end></menu-item-with-sub-menu>',
        '</ul>',
      '</li>'
    ].join(''),
  };
}
