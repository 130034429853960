import { Directive, Inject, Input } from '@angular/core';

import { StateLocationService } from '../../../services/state-location.service';
import { ActionOutViewModel, ActionType } from '@enerkey/clients/attachments';
import EmActionEditService from '../../energy-management/components/em-action-edit/em-action-edit-service';
import { getNewActionDefaults } from '../../energy-management/constants/em-shared.constant';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class OverviewReportActionsCommentsBaseComponent {
  public abstract readonly titleTranslationKey: string;
  public abstract readonly iconClass: string;
  protected abstract readonly newActionType: ActionType;
  protected abstract readonly actionsStateName: string;

  @Input() public actions: ActionOutViewModel[];
  @Input() public facilityId: number;
  @Input() public defaultActionQuantity: number;

  public constructor(
    @Inject('emActionEditService') private readonly emActionEditService: EmActionEditService,
    private readonly stateLocationService: StateLocationService
  ) {
  }

  public editAction(action: ActionOutViewModel): void {
    this.emActionEditService.getModal(action);
  }

  public showAll(): void {
    this.stateLocationService.openStateInNewTab(this.actionsStateName, {
      startDateIndex: 2,
      reportingObjectId: this.facilityId,
      automaticSearch: true
    });
  }

  public addNew(): void {
    const actionDefaults = getNewActionDefaults(this.newActionType);
    actionDefaults.reportingObjectId = this.facilityId;
    actionDefaults.quantityIds = this.defaultActionQuantity ? [this.defaultActionQuantity] : [];
    this.emActionEditService.getModal(actionDefaults);
  }
}
