import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import isMobile from 'ismobilejs';

import { ActionType, ExecutionPhase } from '@enerkey/clients/attachments';
import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';
import { ActionsWidgetOptions } from '../actions-widget/actions-widget.component';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { getExecutionPhaseComboItems } from '../../../energy-management/constants/em-execution-phases';
import { actionTypeTranslations } from '../../../energy-management/constants/em-action-types';
import { ACTION_TYPE_IDS } from '../../../energy-management/constants/em-shared.constant';

type ChangeFn = (options: ActionsWidgetOptions) => void;

type ActionsWidgetOptionsForm = {
  actionTypes: ActionType[];
  executionPhase: ExecutionPhase;
  count: number;
  mobileCount: number;
}

@Component({
  selector: 'actions-widget-options',
  templateUrl: './actions-widget-options.component.html',
  styleUrls: ['./actions-widget-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ActionsWidgetOptionsComponent),
    multi: true,
  }]
})
export class ActionsWidgetOptionsComponent implements ControlValueAccessor, OnInit {

  public readonly executionPhaseOptions: ComboItem<ExecutionPhase>[] = getExecutionPhaseComboItems();
  public readonly actionTypeOptions: ComboItem<ActionType>[];

  public readonly showMobileCount: boolean = isMobile().any;
  public readonly minCount: number = 10;
  public readonly maxCount: number = 200;

  @Input() public initialState: ActionsWidgetOptions;

  public controls: ControlsOf<ActionsWidgetOptionsForm>;
  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public constructor() {
    this.actionTypeOptions = ACTION_TYPE_IDS.map(value => ({ value, text: actionTypeTranslations[value] }));
  }

  public ngOnInit(): void {
    this.controls = formControlsFrom<ActionsWidgetOptionsForm>(this.optionsToForm(this.initialState));
    this.formGroup = new UntypedFormGroup(this.controls);

    this.formGroup.valueChanges.subscribe((formValue: ActionsWidgetOptionsForm) => {
      this._onChange?.(this.formToOptions(formValue));
    });
  }

  public writeValue(value: ActionsWidgetOptions): void {
    this.formGroup.setValue(
      this.optionsToForm(value)
    );
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  private optionsToForm(options: ActionsWidgetOptions): ActionsWidgetOptionsForm {
    return {
      count: options.numberToShow,
      mobileCount: options.numberToShowMobile,
      actionTypes: options.selectedActionTypes.map(x => x.id),
      executionPhase: options.selectedExecutionPhaseId ?? ExecutionPhase.Suggestion,
    };
  }

  private formToOptions(formValue: ActionsWidgetOptionsForm): ActionsWidgetOptions {
    return {
      typeToShow: 'actions',
      numberToShow: formValue.count,
      numberToShowMobile: formValue.mobileCount,
      selectedExecutionPhaseId: formValue.executionPhase,
      selectedActionTypes: formValue.actionTypes.map(id => ({ id })),
    };
  }
}
