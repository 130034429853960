export const mrcMassImportConstants = {
  IMPORT_TYPE: {
    CONSUMPTION: 'consumption',
    READING: 'reading'
  },
  ROW_COUNT: 1000,
  COLUMNS: {
    ENEGIA_ID_COLUMN: 'E',
    METER_ID_COLUMN: 'F',
    START_DATE_COLUMN: 'G',
    END_DATE_COLUMN: 'H',
    VALUE_COLUMN: 'I',
    STATUS_COLUMN: 'J',
    DATE_COLUMN: 'G',
    FIRST_VALUE_COLUMN: 'H',
    SECOND_VALUE_COLUMN: 'I'
  },
  FORMATS: {
    DATE: "d.M.yyyy",
    VALUE: "##0.0###"
  },
  COLORS: {
    ERROR: "#ff0000",
    HEADER_BACKGROUND: "#dddddd",
    DEFAULT: "#222222"
  },
  DATA_INDICES: {
    ENEGIA_ID_INDEX: 0,
    METER_ID_INDEX: 1,
    START_DATE_INDEX: 2,
    END_DATE_INDEX: 3,
    VALUE_INDEX: 4,
    STATUS_INDEX: 5,
    DATE_INDEX: 2,
    FIRST_VALUE_INDEX: 3,
    SECOND_VALUE_INDEX: 4
  }
};
