import { ViewSettings } from '../constants/view-settings';
export function ManualQaViewSettingService() {
  return {
    getViewSettings: getViewSettings
  };

  function getViewSettings() {
    return [
      {
        id: ViewSettings.FACILITIES,
        translationKey: 'MQA.SEARCH.TABS.FACILITIES',
        active: false
      },
      {
        id: ViewSettings.METERING_POINTS,
        translationKey: 'MQA.SEARCH.TABS.METERING_POINTS',
        active: false
      },
      {
        id: ViewSettings.READING_API,
        translationKey: 'MQA.SEARCH.TABS.READING_API',
        active: false,
        disabled: true
      },
      {
        id: ViewSettings.QA,
        translationKey: 'MQA.SEARCH.TABS.QA',
        active: false,
        disabled: true
      }
    ];
  }
}
