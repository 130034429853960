import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';

import { RoleModel, UserManagementClient } from '@enerkey/clients/user-management';

@Injectable({ providedIn: 'root' })
export class RoleService {
  public readonly roles$: Observable<RoleModel[]>;

  public constructor(userManagementClient: UserManagementClient) {
    this.roles$ = userManagementClient.getRoles().pipe(shareReplay(1));
  }

  public getRoles(): Observable<RoleModel[]> {
    return this.roles$.pipe(take(1));
  }
}
