<form [formGroup]="searchForm" (ngSubmit)="search()">
  <div class="searchInputs">
    <kendo-datepicker
      formControlName="beginDate"
      *labelBefore="'SUPERVISION.BEGIN_DATE' | translate"
    ></kendo-datepicker>
    <kendo-datepicker
      formControlName="endDate"
      *labelBefore="'SUPERVISION.END_DATE' | translate"
    ></kendo-datepicker>
    <ek-multi
      formControlName="statuses"
      *labelBefore="'SUPERVISION.STATUS' | translate"
      [items]="statusOptions"
    ></ek-multi>
    <input
      formControlName="customerId"
      type="text"
      kendoTextBox
      *labelBefore="'SUPERVISION.CUSTOMER_ID' | translate"
    />
    <input
      formControlName="systemId"
      type="text"
      kendoTextBox
      *labelBefore="'SUPERVISION.SYSTEM_ID' | translate"
    />
  </div>
  <button
    class="button"
    type="submit"
  >{{ 'SEARCH' | translate }}</button>
</form>
