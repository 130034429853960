import { FieldType, TreeViewItem } from '@enerkey/clients/metering';
import { FormulaOperator } from '../shared/formula-operator';
import { TargetMeter } from '../shared/target-meter';
import { FormulaNewItemId } from './formula-new-item-id';

export class FormulaAddMeters {
  public static getFormulaFromMeterArray(meters: TargetMeter[], parentId: number): TreeViewItem[] {
    return new FormulaAddMeters(meters, parentId).formula;
  }

  private formula: TreeViewItem[] = [];

  private constructor(private meters: TargetMeter[], private parentId: number) {
    this.formulaFromMeters();
  }

  private formulaFromMeters(): void {
    this.meters.forEach((meter, index) => {
      if (index !== 0) {
        this.addOperatorItem(FormulaOperator.Plus);
      }
      this.addMeterItem(meter);
    });
  }

  private getBaseItem(parentId: number = this.parentId): TreeViewItem {
    return new TreeViewItem({
      itemId: FormulaNewItemId.newItemId,
      parentId
    });
  }

  private addMeterItem(meter: TargetMeter): void {
    this.isMeterFactorOtherThanOne(meter) ?
      this.addMeterItemWithFactor(meter) :
      this.addMeterItemWithoutFactor(meter)
    ;
  }

  private isMeterFactorOtherThanOne(meter: TargetMeter): boolean {
    return meter.formulaFactor && meter.formulaFactor !== 1;
  }

  private getMeterItem(meter: TargetMeter): TreeViewItem {
    return new TreeViewItem({
      ...this.getBaseItem(),
      sourceMeterId: meter.id,
      nullToZero: meter.nullToZero,
      itemFieldType: FieldType.Source,
      content: `m${meter.id}`
    });
  }

  private addMeterItemWithoutFactor(meter: TargetMeter): void {
    this.formula.push(this.getMeterItem(meter));
  }

  private addMeterItemWithFactor(meter: TargetMeter): void {
    this.formula.push(new TreeViewItem({
      ...this.getMeterItem(meter),
      content: `m${meter.id}*${meter.formulaFactor}`,
      constant: meter.formulaFactor
    }));
  }

  private addOperatorItem(operator: FormulaOperator, parentId?: number): void {
    this.formula.push(new TreeViewItem({
      ...this.getBaseItem(parentId),
      content: operator,
      itemFieldType: FieldType.Operator
    }));
  }
}
