import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalViewHeaderComponent } from './components/modal-view-header/modal-view-header.component';

@NgModule({
  declarations: [
    ModalViewHeaderComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ModalViewHeaderComponent,
  ]
})
export class EkModalsModule { }
