import { IWebhookClient } from './webhook-client';

export class WebhookClientMock implements IWebhookClient {
  public updateIncomingRequestStatusByInstanceId(): never {
    throw new Error('Method not implemented.');
  }
  public deleteIncomingRequests(): never {
    throw new Error('Method not implemented.');
  }
  public getOutgoingRequestsGroupedByWebhooks(): never {
    throw new Error('Method not implemented.');
  }
  public retryOutgoingRequest(): never {
    throw new Error('Method not implemented.');
  }
  public retryOutgoingRequests(): never {
    throw new Error('Method not implemented.');
  }
  public getRelays(): never {
    throw new Error('Method not implemented.');
  }
  public insertRelay(): never {
    throw new Error('Method not implemented.');
  }
  public updateRelay(): never {
    throw new Error('Method not implemented.');
  }
  public deleteRelay(): never {
    throw new Error('Method not implemented.');
  }
  public getIncomingRequestsBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public updateIncomingRequestStatus(): never {
    throw new Error('Method not implemented.');
  }
  public retryIncomingRequests(): never {
    throw new Error('Method not implemented.');
  }
  public invoke(): never {
    throw new Error('Method not implemented.');
  }
  public getOutgoingRequestsBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public send(): never {
    throw new Error('Method not implemented.');
  }
  public getWebhook(): never {
    throw new Error('Method not implemented.');
  }
  public getWebhooks(): never {
    throw new Error('Method not implemented.');
  }
  public searchWebhooks(): never {
    throw new Error('Method not implemented.');
  }
  public addWebhooks(): never {
    throw new Error('Method not implemented.');
  }
  public updateWebhooks(): never {
    throw new Error('Method not implemented.');
  }
  public deleteWebhooks(): never {
    throw new Error('Method not implemented.');
  }
}
