<div class="drag-handler">
  <div class="widget-header">
    <h2
      class="widget-icon"
      [ngClass]="iconClass"
    ></h2>
    <h2 class="widget-title">{{ title || (defaultTitle | translate) }}</h2>
    <span
      class="widget-settings-button"
      (click)="openWidgetSettings()"
    >
      <i class="fa fa-cog"></i>
    </span>
  </div>
</div>
<div
  [loadingSpinner]="(loading$ | async)"
  [hidden]="error$ | async"
  class="widget-container-parent"
>
  <div
    class="widget-container"
    [hidden]="(loading$ | async)"
  >
    <ng-container #widgetContainer>
    </ng-container>
  </div>
</div>
<div
  class="widget-error-container"
  *ngIf="(error$ | async)"
>
  <div>
    <i class="fas fa-exclamation-triangle"></i>
    <span class="error-text">{{ 'DASHBOARD.DOWNLOAD_ERROR' | translate }}</span>
  </div>
  <button
    (click)="reloadWidget()"
    class="button"
  >{{ 'RELOAD' | translate }}</button>
</div>
