import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { Entry } from 'contentful';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ServiceLevel } from '@enerkey/clients/facility';
import { SimpleChangesOf } from '@enerkey/ts-utils';

import { UserService } from '../../services/user-service';
import { ContentfulService } from '../services/contentful.service';
import { LanguageChangeService } from '../services/language-change.service';
import { ProfileService } from '../services/profile.service';
import { ServiceLevelService } from '../services/service-level.service';
import { AuthenticationService } from '../services/authentication.service';
import { HelpService } from '../services/help.service';

export interface HelpEntry {
  id: string;
  title: string;
  level: number;
  states: string[];
}

type HelpItem = {
  title: string;
  tenant: string[];
  serviceLevel: string[];
  states: string[];
  subPages: Entry<HelpItem>[];
}

@Component({
  selector: 'help',
  templateUrl: './help.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpComponent implements OnDestroy, OnChanges {
  @Input() public help: { name: string };
  @Input() public mobile: boolean;
  public entries: HelpEntry[];
  public hasLargeServiceLevel = false;

  private readonly stateChangeSuccessUnbind: ReturnType<TransitionService['onSuccess']>;
  private readonly languageChangeStartSubscription: Subscription;
  private readonly destroy$ = new Subject<void>();

  private allEntries: HelpEntry[] = [];
  private ignoreStates = ['callback'];

  public constructor(
    private readonly contentfulService: ContentfulService,
    private readonly userService: UserService,
    private readonly languageChangeService: LanguageChangeService,
    private readonly serviceLevelService: ServiceLevelService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly helpService: HelpService,
    profileService: ProfileService,
    transitionService: TransitionService
  ) {
    this.stateChangeSuccessUnbind = transitionService.onSuccess({}, transition => {
      this.getHelpItems(transition.to().name);
    });

    // Brute force way to discard help entries that were prepared
    // based on the locale.  Now that it changes, we need to redo
    // that.
    this.languageChangeStartSubscription = this.languageChangeService.languageChangeStart.subscribe(() => {
      this.allEntries = [];
    });

    profileService.profile$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setServiceLevel();
      });
  }

  public ngOnChanges(changes: SimpleChangesOf<HelpComponent>): void {
    if (changes.help?.currentValue) {
      this.getHelpItems(this.help.name);
    }
  }

  public ngOnDestroy(): void {
    this.stateChangeSuccessUnbind();
    this.languageChangeStartSubscription.unsubscribe();
  }

  public openHelpEntry(entry?: HelpEntry): void {
    this.helpService.openHelpEntry(entry?.id ?? '');
  }

  private setServiceLevel(): void {
    this.hasLargeServiceLevel = this.serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Large);
    this.changeDetectorRef.markForCheck();
  }

  private validateEntry(entry: Entry<HelpItem>): boolean {
    return !!(entry?.fields?.title && entry?.sys?.id);
  }

  private hasAccessByTenant(entry: Entry<HelpItem>): boolean {
    if (!entry.fields.tenant?.length) {
      return true;
    }

    return entry.fields.tenant?.includes(`${this.authenticationService.tenantId}`) ?? false;
  }

  private hasAccessByServiceLevel(entry: Entry<HelpItem>, serviceLevel: ServiceLevel): boolean {
    if (serviceLevel === ServiceLevel.Large && !entry.fields.serviceLevel?.length) {
      return true;
    }
    return entry.fields.serviceLevel?.includes(serviceLevel.toString()) ?? false;
  }

  private mapEntry(entry: Entry<HelpItem>, level: number): HelpEntry {
    return {
      id: entry.sys.id,
      title: entry.fields.title,
      level: level,
      states: entry.fields.states
    };
  }

  private traverseArray(
    result: HelpEntry[],
    entry: Entry<HelpItem>,
    level?: number,
    serviceLevel?: ServiceLevel
  ): void {
    level = level || 0;
    if (
      this.validateEntry(entry) &&
        this.hasAccessByTenant(entry) &&
        this.hasAccessByServiceLevel(entry, serviceLevel)
    ) {
      result.push(this.mapEntry(entry, level));
      entry.fields.subPages?.forEach((childEntry: Entry<HelpItem>) => {
        this.traverseArray(result, childEntry, level + 1, serviceLevel);
      });
    }
  }

  private getHelpItems(state: string): void {
    if (this.allEntries.length) {
      this.filterHelp(state);
      return;
    }

    if (this.ignoreStates.includes(state)) {
      return;
    }

    forkJoin([
      this.userService.isInitializedWithInitialProfileAsync(),
      this.contentfulService.getHelp()
    ]).subscribe(([_, response]) => {
      const entries = response.items;
      const sortedEntries = entries.sortBy(entry => entry?.fields?.sortOrder);
      const flatList: HelpEntry[] = [];
      sortedEntries.forEach(entry => {
        this.traverseArray(flatList, entry, undefined, this.userService.getServiceLevel());
      });
      this.allEntries = flatList.sortBy('level');
      this.filterHelp(state);
    });
  }

  private filterHelp(state: string): void {
    this.entries = this.allEntries.filter(entry => entry?.states?.includes(state));
  }
}
