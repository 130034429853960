import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'enerkey-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationErrorsComponent {
  @Input() public control: NgControl;

  @ViewChild('templateRef', { static: true }) public templateRef: TemplateRef<unknown>;
}
