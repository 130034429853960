<modal-view-header [headingText]="'ALARM.LOG.ALARM_DETAILS' | translate"></modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading"
>
  <alarm-info
    *ngIf="alarm"
    [alarm]="alarm"
  ></alarm-info>
</div>
