import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LogStatus } from '@enerkey/clients/alarm';

@Component({
  selector: 'alarm-log-status-icon',
  templateUrl: './alarm-log-status-icon.component.html',
  styleUrls: ['./alarm-log-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmLogStatusIconComponent {
  public readonly LogStatus: typeof LogStatus = LogStatus;

  @Input() public status: LogStatus = null;
  @Input() public active: boolean = true;
  @Input() public small: boolean = false;
}
