import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ReportingUnit } from '@enerkey/clients/reporting';

import { QuantityService } from '../services/quantity.service';

@Pipe({
  name: 'quantityUnit'
})
export class QuantityUnitPipe implements PipeTransform {
  public constructor(private readonly quantityService: QuantityService) {
  }

  public transform(quantityId: number | Observable<number>, unit = ReportingUnit.Default): Observable<string> {
    const observable = isObservable(quantityId) ? quantityId : of(quantityId);
    return observable.pipe(
      switchMap(id => this.quantityService.getQuantityUnit(id, unit))
    );
  }
}
