import { SortOrder } from '../';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */

type Selector<T, U> = ((item: T) => U);
type SelectorOrKey<T, U> = keyof T | Selector<T, U>;

type SortFn = (a: any, b: any) => -1 | 0 | 1;
const ascSort: SortFn = (a, b) => a > b ? 1 : a < b ? -1 : 0;
const descSort: SortFn = (a, b) => a > b ? -1 : a < b ? 1 : 0;

function identity<T>(value: T): T {
  return value;
}

function getSortFn(order?: SortOrder): SortFn {
  switch (order) {
    case 'desc':
    case 'descending':
      return descSort;
    case 'asc':
    case 'ascending':
    default:
      return ascSort;
  }
}

function getSelectFn<T, U>(selector: SelectorOrKey<T, U>): Selector<T, U> {
  switch (typeof selector) {
    case 'function':
      return selector;
    case 'string':
    case 'number':
    case 'symbol':
      return (item: T): U => item[selector] as any;
    /* istanbul ignore next */
    default:
      throw Error(`Expected a selector but got ${String(selector)}`);
  }
}

/* eslint-disable no-invalid-this */

//#region Mutating functions

export function clear<T>(this: T[]): void {
  this.splice(0, this.length);
}

export function remove<T>(
  this: T[],
  ...items: readonly T[]
): void {
  if (items.length === 0 || this.length === 0) {
    return;
  }
  if (items.length === 1) {
    const index = this.indexOf(items[0]);
    this.removeAt(index);
    return;
  }

  const removeSet = new Set<T>(items);

  for (let index = this.length - 1; index >= 0; index--) {
    if (removeSet.has(this[index])) {
      this.splice(index, 1);
    }
  }
}

export function removeBy<T>(
  this: T[],
  predicate: (item: T) => boolean
): void {
  for (let index = this.length - 1; index >= 0; index--) {
    if (predicate(this[index])) {
      this.splice(index, 1);
    }
  }
}

export function removeAt<T>(
  this: T[],
  index: number
): T {
  if (Number.isInteger(index) && index >= 0 && index < this.length) {
    return this.splice(index, 1)[0];
  }

  return undefined;
}

//#endregion Mutating functions

//#region Non-mutating functions

export function hasItems(this: readonly any[]): boolean {
  return this.length !== 0;
}

export function sortBy<T, K>(
  this: readonly T[],
  selector: SelectorOrKey<T, K>,
  order?: SortOrder
): T[] {
  const sortfn = getSortFn(order);
  const fn = getSelectFn(selector);
  return Array.from(this).sort(
    (a, b) => sortfn(fn(a), fn(b))
  );
}

export function sortByMany<T>(
  this: readonly T[],
  ...keys: (SelectorOrKey<T, any> | [SelectorOrKey<T, any>, SortOrder?])[]
): T[] {
  if (keys.length === 0) {
    return Array.from(this).sort();
  }

  const sortFunctions = keys.map(
    sortOpts => Array.isArray(sortOpts)
      ? [getSelectFn(sortOpts[0]), getSortFn(sortOpts[1])] as const
      : [getSelectFn(sortOpts), getSortFn()] as const
  );

  return Array.from(this).sort((a, b): number => {
    for (const [fn, sortfn] of sortFunctions) {
      const order = sortfn(fn(a), fn(b));

      if (order) {
        return order;
      }
    }

    return 0;
  });
}

export function unique<T>(
  this: readonly T[],
  selector?: SelectorOrKey<T, any>
): T[] {
  if (selector !== undefined) {
    const fn = getSelectFn(selector);
    return Array.from(new Set(this.map(fn)));
  } else {
    return Array.from(new Set(this));
  }
}

export function uniqueBy<T, U>(
  this: readonly T[],
  selector: SelectorOrKey<T, U>
): T[] {
  const output: T[] = [];
  const existing = new Set<U>();
  const fn = getSelectFn(selector);

  for (const item of this) {
    const value = fn(item);

    if (!existing.has(value)) {
      existing.add(value);
      output.push(item);
    }
  }

  return output;
}

export function uniqueByMany<T>(
  this: T[],
  ...selectors: SelectorOrKey<T, any>[]
): T[] {
  if (!selectors.length) {
    return Array.from(this);
  }

  const selectorFns = selectors.map(getSelectFn);

  const output: T[] = [];
  const existing: any[][] = [];

  for (const item of this) {
    const uniqueValue: any[] = selectorFns.map(fn => fn(item));

    if (!existing.some(u => u.every((val, index) => val === uniqueValue[index]))) {
      existing.push(uniqueValue);
      output.push(item);
    }
  }

  return output;
}

export function filterMap<T, U>(
  this: readonly T[],
  predicate: (item: T) => boolean,
  selector: (item: T) => U
): U[] {
  const output: U[] = [];

  for (const item of this) {
    if (predicate(item)) {
      output.push(selector(item));
    }
  }

  return output;
}

export function mapFilter<T, U>(
  this: readonly T[],
  selector: (item: T) => U,
  predicate: (item: U) => boolean
): U[] {
  const output: U[] = [];

  for (const item of this) {
    const value = selector(item);

    if (predicate(value)) {
      output.push(value);
    }
  }

  return output;
}

export function toMapBy<T, U>(
  this: readonly T[],
  keySelector: SelectorOrKey<T, U>
): Map<U, T> {
  return this.toMap(getSelectFn(keySelector), identity);
}

export function toMap<T, K, V>(
  this: readonly T[],
  keySelector: SelectorOrKey<T, K>,
  valueSelector: SelectorOrKey<T, V>
): Map<K, V> {
  const keyfn = getSelectFn(keySelector);
  const valuefn = getSelectFn(valueSelector);
  const map = new Map<K, V>();

  for (const value of this) {
    const key = keyfn(value);

    if (key !== null && key !== undefined && !map.has(key)) {
      map.set(key, valuefn(value));
    }
  }

  return map;
}

export function toGroupsBy<T, U, V>(
  this: readonly T[],
  selector: SelectorOrKey<T, U>,
  valueSelector?: SelectorOrKey<T, V>
): Map<U, V[]> {
  const map = new Map<U, V[]>();
  const keyfn = getSelectFn(selector);
  const valuefn = valueSelector
    ? getSelectFn(valueSelector)
    : identity as any
  ;

  for (const item of this) {
    map.getOrAdd(keyfn(item), () => []).push(valuefn(item));
  }

  return map;
}

export function except<T>(
  this: T[],
  other: T[]
): T[] {
  if (this === other) {
    return [];
  } else if (other.length === 0) {
    return [...this];
  }

  const set = new Set(other);
  return this.filter(item => !set.has(item));
}

export function count<T>(
  this: readonly T[],
  predicate: (item: T) => boolean
): number {
  let _count = 0;

  for (const item of this) {
    if (predicate(item)) {
      _count += 1;
    }
  }

  return _count;
}

export function toRecord<T, K extends keyof any, V>(
  this: readonly T[],
  keySelector: (item: T) => K,
  valueSelector?: (item: T) => V
): Record<K, V> {
  const result = {} as Record<K, V>;

  if (this.length === 0) {
    return result;
  }

  if (!valueSelector) {
    valueSelector = identity as any;
  }

  for (const item of this) {
    const key = keySelector(item);

    if (key === null || key === undefined || key in result) {
      continue;
    }

    result[key] = valueSelector(item);
  }

  return result;
}

export function joinLeft<T, U, P, R>(
  this: readonly T[],
  other: U[],
  thisSelector: (item: T) => P,
  otherSelector: (item: U) => P,
  resultSelector: (a: T, b: U) => R
): R[] {
  if (this.length === 0) {
    return [];
  }

  const result: R[] = [];
  const otherMap = other.toMapBy(otherSelector);

  for (const item of this) {
    const key = thisSelector(item);

    if (key !== null && key !== undefined) {
      result.push(resultSelector(item, otherMap.get(key) ?? null));
    }
  }

  return result;
}

export function joinInner<T, U, P, R>(
  this: readonly T[],
  other: U[],
  thisSelector: (item: T) => P,
  otherSelector: (item: U) => P,
  resultSelector: (a: T, b: U) => R
): R[] {
  if (this.length === 0 || other.length === 0) {
    return [];
  }

  const result: R[] = [];
  const otherMap = other.toMapBy(otherSelector);

  for (const item of this) {
    const key = thisSelector(item);

    if (key !== null && key !== undefined && otherMap.has(key)) {
      result.push(resultSelector(item, otherMap.get(key)));
    }
  }

  return result;
}

//#endregion Non-mutating functions

//#region Static functions

export function hasItemsStatic(array: any[]): boolean {
  return !!array?.length;
}

//#endregion Static functions
