<div class="accordion-item" [ngClass]="{ 'is-active': isOpen }" [class]="panelClass">
  <div
    class="accordion-title"
    role="tab"
    (click)="toggleOpen()"
    [ngClass]="{ 'disabled': isDisabled }"
    [attr.aria-expanded]="isOpen"
  >
    <span *ngIf="heading">
      {{ heading }}
    </span>
    <ng-content select="[accordion-heading]"></ng-content>
  </div>
  <div class="accordion-content" role="tabpanel" [collapse]="!isOpen" [isAnimated]="isAnimated">
    <ng-content></ng-content>
  </div>
</div>
