import 'angular-hotkeys';

import ManualQaInspectComponent from './manual-qa-inspect-component';
import ManualQaServiceModule from '../../services';

const dependencies = [ManualQaServiceModule.name, 'cfp.hotkeys'];

const ManualQaInspectModule = angular
  .module('app.modules.manual-qa.components.manual-qa-inspect', dependencies)
  .component('manualQaInspect', ManualQaInspectComponent)
;

export default ManualQaInspectModule;
