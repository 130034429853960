import moment, { Moment } from 'moment';

export class FromToStartDate {
  public get value(): Moment {
    return this._value;
  }
  public set value(date: Moment) {
    this._value = date;
    this.setKey();
  }

  public get valueDate(): Date { // Do not use! Only for kendo-date-picker
    return this._value.toDate();
  }
  public set valueDate(date: Date) {
    this._value = this.adjustDate(date);
    this.setKey();
  }

  public get valueDateLocal(): Date { // Do not use! Only for no-comparison-presets
    return moment([this._value.year(), this._value.month(), this._value.date()]).toDate();
  }
  public set valueDateLocal(date: Date) {
    this._value = this.adjustDate(date);
    this.setKey();
  }

  public get key(): string {
    return this._key;
  }

  protected _value: Moment;
  protected _key: string;

  public constructor(date: Moment | Date | string) {
    this._value = moment.utc(date);
    this.setKey();
  }

  protected setKey(): void {}

  private adjustDate(date: Date): Moment {
    return moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
  }
}

export class FromStartDate extends FromToStartDate {
  protected override setKey(): void {
    this._key = this._value.format('D.M.Y');
  }
}

export class ToStartDate extends FromToStartDate {
  protected override setKey(): void {
    this._key = this._value.clone().subtract(1, 'seconds').format('D.M.Y');
  }
}
