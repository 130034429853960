import ManualQaApiInfoService    from './manual-qa-api-info.service';
import ManualQaApiServiceModule  from '../manual-qa-api';
import ManualQaApiQuantityModule from '../manual-qa-api-quantity';

const dependencies = [ManualQaApiServiceModule.name, ManualQaApiQuantityModule.name];

const ManualQaApiInfoServiceModule = angular
  .module('app.modules.manual-qa.services.manual-qa-api-info', dependencies)
  .service('ManualQaApiInfoService', ManualQaApiInfoService)
;

export default ManualQaApiInfoServiceModule;
