import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { GridMultiFilterBase } from '../grid-multi-filter-base/grid-multi-filter-base';
import { GridMultiFilterTemplateContext } from '../grid-multi-filter-base/grid-multi-filter-template.directive';

@Component({
  selector: 'grid-multiselect-filter',
  templateUrl: './grid-multiselect-filter.component.html',
  styleUrls: ['./grid-multiselect-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridMultiselectFilterComponent<T, U> extends GridMultiFilterBase<T, U> {

  public options: GridMultiFilterTemplateContext<T, U>[];
  public selection: U[];

  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public valueChanged(): void {
    this.updateFilter(this.selection);
  }

  protected initialize(values: GridMultiFilterTemplateContext<T, U>[]): void {
    this.options = values;
    this.selection = this.getActiveSelection();
  }

}
