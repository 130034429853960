import ManualQaSearchFacilitiesController from './manual-qa-search-facilities-controller';
import template from 'raw-loader!./manual-qa-search-facilities.html';

export default {
  template: template,
  controllerAs: 'vm',
  controller: ManualQaSearchFacilitiesController,
  bindings: {
    searchCriteria: '<',
    onChange: '&',
    readerTypes: '<'
  }
};
