import { ICostFactorClient } from './cost-factor-client';

export class CostFactorClientMock implements ICostFactorClient {
  public getMetersYearlyBudgetFactors(): never {
    throw new Error('Method not implemented.');
  }
  public getMetersBaseValues(): never {
    throw new Error('Method not implemented.');
  }
  public syncBudgetFactors(): never {
    throw new Error('Method not implemented.');
  }
  public getBudgetFactors(): never {
    throw new Error('Method not implemented.');
  }
  public deleteBudgetFactors(): never {
    throw new Error('Method not implemented.');
  }
  public bulkAddOrUpdateBudgetFactors(): never {
    throw new Error('Method not implemented.');
  }
  public retrieveManyCostFactors(): never {
    throw new Error('Method not implemented.');
  }
  public getCostFactors(): never {
    throw new Error('Method not implemented.');
  }
  public addCostFactor(): never {
    throw new Error('Method not implemented.');
  }
  public updateCostFactor(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCostFactor(): never {
    throw new Error('Method not implemented.');
  }
  public deleteCostFactors(): never {
    throw new Error('Method not implemented.');
  }
  public bulkAddCostFactors(): never {
    throw new Error('Method not implemented.');
  }
  public getLatestCostFactors(): never {
    throw new Error('Method not implemented.');
  }
}
