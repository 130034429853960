import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ErrorType } from '@enerkey/clients/error-ticket';
import { EnumTranslatePipe } from '@enerkey/ek-common';

const translationKeys: Record<ErrorType, string> = {
  [ErrorType.None]: 'ERROR_WIDGET.ERROR_TYPE.NONE',
  [ErrorType.Any]: 'ERROR_WIDGET.ERROR_TYPE.ANY',
  [ErrorType.AllMeasurementsStopped]: 'ERROR_WIDGET.ERROR_TYPE.ALL_MEASUREMENTS_STOPPED',
  [ErrorType.InvalidData]: 'ERROR_WIDGET.ERROR_TYPE.INVALID_DATA',
  [ErrorType.IncompleteData]: 'ERROR_WIDGET.ERROR_TYPE.INCOMPLETE_DATA',
  [ErrorType.SingleMeasurementStopped]: 'ERROR_WIDGET.ERROR_TYPE.SINGLE_MEASUREMENT_STOPPED',
  [ErrorType.ChannelDataStopped]: 'ERROR_WIDGET.ERROR_TYPE.CHANNEL_DATA_STOPPED',
  [ErrorType.SingleMeasurementStoppedOpenEndZero]: 'ERROR_WIDGET.ERROR_TYPE.SINGLE_MEASUREMENT_STOPPED_OPEN_END_ZERO',
  [ErrorType.SingleMeasurementStoppedOpenEndMissingValue]:
    'ERROR_WIDGET.ERROR_TYPE.SINGLE_MEASUREMENT_STOPPED_OPEN_END_MISSING_VALUE',
};

@Pipe({
  name: 'errorTicketTypeName'
})
export class ErrorTicketTypeNamePipe extends EnumTranslatePipe<ErrorType> implements PipeTransform {
  protected readonly translations: Record<ErrorType, string> = translationKeys;

  public constructor(translateService: TranslateService) {
    super(translateService);
  }
}
