<modal-view-header headingText="{{ 'ALARM.LOG.STATUS.EDIT' | translate }}">
</modal-view-header>

<div class="modal-body">
  <table class="table">
    <thead>
      <tr>
        <td>{{ 'ALARM.LOG.STATUS.TITLE' | translate }}</td>
        <td colspan="3">{{ 'ALARM.LOG.STATUS.COUNT' | translate }}</td>
      </tr>
    </thead>
    <tr
      *ngFor="let status of statuses"
      [class.no-changes]="!changesByStatus[status].changes"
    >
      <td>
        <alarm-log-status-label [status]="status"></alarm-log-status-label>
      </td>
      <td>
        {{ changesByStatus[status].before }}
      </td>
      <td>
        <i class="fas fa-long-arrow-alt-right"></i>
      </td>
      <td>
        {{ changesByStatus[status].after }}
      </td>
    </tr>
  </table>

  <hr>

  <label>{{ 'ALARM.LOG.STATUS.NEW_STATUS' | translate }}</label>
  <alarm-log-status-dropdown [formControl]="formControl"></alarm-log-status-dropdown>
</div>


<div class="modal-footer">
  <div class="row">
    <div class="modal-footer-butns float-right">
      <button
        class="button button--primary"
        [disabled]="!formControl.valid || formControl.disabled || !anyChanges"
        [loading]="loading$ | async"
        (click)="onSubmit()"
      >
        {{ 'ADMIN.SAVE' | translate }}
      </button>
      <button
        class="button button--secondary"
        type="button"
        (click)="dismiss()"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
