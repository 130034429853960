import EmValidationService from '../../services/em-validations-service';

const $inject = ['$q', 'emActionsFieldService', 'actions', 'utils'];

class EmMassEditController {
  constructor($q, emActionsFieldService, actions, utils) {
    this.$q = $q;
    this.emActionsFieldService = emActionsFieldService;
    this.actions = actions;
    this.utils = utils;

    this.onInputChange = this.inputChange.bind(this);

    // Displayed action field extends selected with dynamic properties
    this.selectedActionField = null;
    this.displayedActionField = null;

    this.dependencyError = false;
    this.dependencyErrorText = '';
    this.mandatoryVaries = false;
    this.saveDisabled = true;

  }

  $onInit() {
    this.selectedActions = this.actionsList;
    this.actionFields = this.emActionsFieldService.getFieldsByName(
      EmValidationService.isComment(this.actionsList[0])
    );
  }

  onActionFieldChange() {
    const selectedField = angular.copy(this.selectedActionField);

    // Some fields depends on values of other fields
    // Check that all actions have the same value in those fields
    let dependenciesOk = true;
    if (selectedField.dependsOn) {
      dependenciesOk =
        EmValidationService.areAllDependenciesSame(this.selectedActions, selectedField);
      selectedField.dependencyValue =
        EmValidationService.getDependencyValue(this.selectedActions, selectedField);
      this.dependencyErrorText = this.getDependencyErrorText(selectedField.dependsOn);
    }
    this.dependencyError = !dependenciesOk;
    selectedField.visible = dependenciesOk;

    // Check if the field is mandatory when it is not explicitly stated in field config
    // Also handle a situation when the field is mandatory only in some of the actions
    this.mandatoryVaries = false;
    if (angular.isUndefined(selectedField.mandatory) && dependenciesOk) {
      const { some, varies } =
        EmValidationService.areSomeMandatory(this.selectedActions, selectedField);
      selectedField.mandatory = some;
      this.mandatoryVaries = varies;
    }

    const { initialValue, initialValueVaries } =
      this.getInitialValue(this.selectedActions, selectedField);
    selectedField.initialValue = initialValue;
    selectedField.initialValueVaries = initialValueVaries;

    // Set reporting object id
    selectedField.reportingObjectId = this.selectedActions[0].reportingObjectId;

    this.saveDisabled = !dependenciesOk || initialValueVaries;
    this.displayedActionField = selectedField;
  }

  getDependencyErrorText(fieldName) {
    const field = this.actionFields[fieldName];
    const localizedFieldName = this.utils.localizedString(field.localization);
    return this.utils.localizedString('ACTIONS.MASS_EDIT.DEPENDENCY_ERROR', {
      fieldName: localizedFieldName
    });
  }

  getInitialValue(actions, field) {
    const firstValue = actions[0][field.name];
    const allHaveSameValue = actions.every(action => angular.equals(firstValue, action[field.name]));
    return {
      initialValue: allHaveSameValue ? firstValue : null,
      initialValueVaries: !allHaveSameValue
    };
  }

  inputChange(currentConfig, value) {
    this.saveDisabled = !currentConfig.isValid;
    this.newValue = value;
  }

  saveActions() {
    const field = this.displayedActionField.name;
    const requests = [];
    const actionsToSave = angular.copy(this.selectedActions);
    actionsToSave.forEach(action => {
      action[field] = this.newValue;
    });
    const invalidActions = EmValidationService.getInvalidActions(actionsToSave);

    if (invalidActions.length === 0) {
      actionsToSave.forEach(action => {
        requests.push(this.actions.updateAction(action, []));
      });

      this.$q.all(requests)
        .then(responses => {
          const translationKey = responses.length > 1
            ? 'ACTIONS.MASS_EDIT.MULTIPLE_CHANGED'
            : 'ACTIONS.MASS_EDIT.SINGLE_CHANGED';
          this.utils.popUp(
            'success',
            this.utils.localizedString('ACTIONS.MASS_EDIT.SAVE_SUCCESSFUL'),
            this.utils.localizedString(translationKey, { count: responses.length })
          );
          const responseActions = responses.map(response => response.data);
          this.modalClose(responseActions);
        })
        .catch(() => {
          this.utils.popUp(
            'error',
            this.utils.localizedString('ACTIONS.MASS_EDIT.SAVE_FAILED'),
            this.utils.localizedString('ACTIONS.MASS_EDIT.SOME_CHANGED')
          );
          this.modalClose();
        });

    } else {
      this.utils.popUp(
        'error',
        this.utils.localizedString('ACTIONS.MASS_EDIT.INVALID_ACTIONS'),
        this.utils.localizedString('ACTIONS.MASS_EDIT.NONE_CHANGED')
      );
      this.modalDismiss();
    }
  }
}

EmMassEditController.$inject = $inject;

export default EmMassEditController;
