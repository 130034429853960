import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { GroupResult } from '@progress/kendo-data-query';

import { EkComboItemTemplateDirective } from '../ek-combo-item-template.directive';

export type ComboItem<T> = { readonly text: string; readonly value: T; readonly group?: string };

@Component({
  selector: 'ek-combo',
  templateUrl: './ek-combo.component.html',
  styleUrls: ['./ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: EkComboComponent,
    multi: true
  }],
})
export class EkComboComponent<T = unknown> implements ControlValueAccessor, OnInit {

  @Input() public items: ComboItem<T>[] = [];
  @Input() public showDefaultItem: boolean = false;
  @Input() public defaultItemText: string;
  @Input() public loading: boolean = false;
  @Input() public noTranslate: boolean = false;
  @Input() public disableWhenLoading: boolean = false;
  @Input() public virtual: boolean = false;
  @Input() public disabledItems: T[];

  public groupedItems: GroupResult[];

  @ContentChild(EkComboItemTemplateDirective)
  public readonly contentTemplate: EkComboItemTemplateDirective<ComboItem<T>>;

  public value: T = undefined;
  public disabled: boolean = false;

  public defaultItem: ComboItem<T> = { text: '—', value: null };

  /** CVA Hander */ private _onChange: (value: T) => void;
  /** CVA Hander */ private _onTouch: () => void;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.isItemDisabled = this.isItemDisabled.bind(this);
  }

  public isItemDisabled(item: { dataItem: ComboItem<T> }): boolean {
    return this.disabledItems?.includes(item.dataItem.value);
  }

  public ngOnInit(): void {
    if (this.defaultItemText) {
      this.defaultItem = {
        ...this.defaultItem,
        text: this.defaultItemText,
      };
    }
  }

  /** Kendo component event */
  public valueChange(value: T | null): void {
    this._onChange?.(value);
  }

  /** Kendo component event */
  public blur(): void {
    this._onTouch?.();
  }

  /** ControlValueAccessor interface */
  public writeValue(value: T | null): void {
    this.value = value;
    this.changeDetector.markForCheck();
  }

  /** ControlValueAccessor interface */
  public registerOnChange(fn: (value: T) => void): void {
    this._onChange = fn;
  }

  /** ControlValueAccessor interface */
  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  /** ControlValueAccessor interface */
  public setDisabledState(isDisabled: boolean): void {
    if (!!this.disabled !== !!isDisabled) {
      this.disabled = isDisabled;
      this.changeDetector.detectChanges();
    }
  }
}
