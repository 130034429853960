import { Directive, Input, Optional } from '@angular/core';
import { ExcelComponent, GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Directive({
  selector: 'kendo-grid-excel[excelDataSource]'
})
export class KendoGridExcelDirective {
  @Input() public excelDataSource: any[] = [];

  public constructor(@Optional() excelComponent: ExcelComponent) {
    if (excelComponent) {
      excelComponent.fetchData = (gridComponent: GridComponent): ExcelExportData =>
        ({
          data: process(this.excelDataSource || [], {
            group: gridComponent.group || [],
            sort: gridComponent.sort || [],
            filter: gridComponent.filter
          }).data,
          group: gridComponent.group || []
        })
      ;
    }
  }
}
