import ToggleGroupController from './toggle-group-controller';
import template from 'raw-loader!./toggle-group.html';

const ToggleGroupComponent = {
  template: template,
  bindings: {
    buttons: '<',
    tabLike: '<',
    kendoColorsForTabs: '<',
    onToggle: '&'
  },
  controller: ToggleGroupController
};

export default ToggleGroupComponent;
