import moment from 'moment';

import templateModule from 'raw-loader!../templates/mrc-meter-item.html';

export function mrcMeterItem() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      step: "=",
      meter: "=",
      readingDate: "=",
      minDate: "=",
      mrcState: "=",
      facility: "=",
      typeChange: "="
    },
    controller: controller
  };
}

controller.$inject = ['$scope', '$filter', 'MrcConstants', '_'];

function controller($scope, $filter, MrcConstants, _) {
  $scope.MrcConstants = MrcConstants;
  $scope.dateFormat = 'dd.MM.yyyy';

  $scope.meter.MeasurementType = $scope.meter.TwoTimeMeasurement ? 'multi' : 'single';
  $scope.lockFinalReadingValues = false;

  $scope.typeChangePickerOptions = {
    start: "year",
    depth: "year"
  };

  function updateTypeChangeDescription() {
    $scope.meter.Description = $scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION ? $filter('translate')('MRC.READINGS.WIZARD.LABELS.METER_CHANGED_TO_CONSUMPTION') : $filter('translate')('MRC.READINGS.WIZARD.LABELS.METER_CHANGED_TO_READING');
    $scope.meter.Description += ' ' + moment($scope.readingDate).format('DD.M.YYYY');
  }

  if ($scope.typeChange) {
    $scope.readingDate = moment($scope.readingDate).endOf('month').toDate();
    if ($scope.meter.Type === 'new') {
      updateTypeChangeDescription();
    }
  } else {
    if ($scope.meter.Type === 'old' && $scope.meter.Readings[0].Flags === 2) {
      $scope.readingDate = $scope.minDate;
      $scope.lockFinalReadingValues = true;
    }
  }

  _.each($scope.meter.Readings, function(readingItem) {
    if (angular.isDefined(readingItem.Value) && readingItem.Value !== null && readingItem.Value >= 0) {
      readingItem.minValue = angular.copy(readingItem.Value);
    } else {
      readingItem.minValue = 0;
    }
  });

  $scope.$watch('meter.MeasurementType', function(n) {
    if (n === 'multi') {
      $scope.meter.TwoTimeMeasurement = true;
      if ($scope.meter.Readings.length === 1) {
        $scope.meter.Readings.push({ Type: 2, Value: 0 });
      }
    } else {
      $scope.meter.TwoTimeMeasurement = false;
      if ($scope.meter.Readings.length > 1) {
        $scope.meter.Readings = $scope.meter.Readings.splice(0,1);
      }
    }
  });

  $scope.isDisabled = function() {
    if ($scope.meter && $scope.meter.Factor > 0) {
      var rDate = new Date($scope.readingDate), mDate = new Date($scope.minDate);

      if (angular.isDefined(rDate) && rDate >= mDate) {
        var readings = $scope.meter.Readings;
        var hasInvalidReadings = false;
        _.each(readings, function(reading) {
          if (angular.isUndefined(reading.Value) || reading.Value === null ||
              reading.Value === "" || reading.Value.toString().replace(',', '.') < reading.minValue) {
            hasInvalidReadings = true;
          }
        });
        return hasInvalidReadings;
      } else {
        return true;
      }
    }

    return true;
  };

    // MRC-379
    // For some unknown reason kendo datepicker does not manipulate $scope.readingDate that is defined in the
    // k-ng-model attribute and needs this specific k-on-change function to do that.
    // Direct manipulation is working at this point in the other mrc directives, but for the consistency of code
    // will be changed to use k-on-change event. (2016-09-15)
  $scope.onDateChange = function(event) {
    $scope.readingDate = event.sender.value();
  };

  $scope.onTypeChangeDateChange = function(evt) {
    var original = evt.sender.value();
    var modified = moment(original).endOf('month').toDate();
    $scope.readingDate = modified;
      // for some reason the visible value is not updated unless it's explicitly set.
    angular.element('#typeChangePicker').data('kendoDatePicker').value(modified);
    if ($scope.meter.Type === 'new') {
      updateTypeChangeDescription();
    }
  };
}
