import SearchTypes, { StorageSearchType } from '../../constants/search-types';
import { getCommonColumns, getCommonOptions, getDefectTypesColumn } from './manual-qa-result.functions';
import { GRID_COLUMNS } from './grid';
import { ToggleableGridColumn } from '../../constants/toggleable-grid-column';
import { TranslationsMap } from './manual-qa-result-service';

export function getMeterGridOptions(
  translations: TranslationsMap,
  searchType: StorageSearchType
): kendo.ui.GridOptions {
  const baseOptions = getCommonOptions();
  baseOptions.dataSource.group = [{ field: GRID_COLUMNS.FACILITY_NAME }, { field: GRID_COLUMNS.QUANTITY_NAME }];
  return {
    ...baseOptions,
    columns: [...getCommonColumns(translations), ...getMeterColumns(translations, searchType)]
  };
}

function getMeterColumns(
  translations: TranslationsMap,
  searchType: StorageSearchType
): ToggleableGridColumn[] {
  return searchType === SearchTypes.STORAGE_TYPE.DEFECT
    ? [getDefectTypesColumn(translations)]
    : []
  ;
}
