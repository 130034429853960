import MomentToStringFilter from './moment-to-string';

const dependencies = [];

const MomentToStringModule = angular
  .module('app.filters.moment-to-string', dependencies)
  .filter('momentToString', MomentToStringFilter)
;

export default MomentToStringModule;
