import { Pipe, PipeTransform } from '@angular/core';
import { PlotBand } from '@progress/kendo-angular-charts';

import { ReportingSeries } from '../shared/reporting-series';

@Pipe({
  name: 'reportingChartMinMax'
})
export class ReportingChartMinMaxPipe implements PipeTransform {
  public transform(series: ReportingSeries[], includeMin = true, includeMax = true): PlotBand[] {
    const minSerie = series?.find(s => s.options.serieType === 'min');
    const maxSerie = series?.find(s => s.options.serieType === 'max');

    if (!minSerie || !maxSerie) {
      return [];
    }

    const minValue = minSerie.values.filter(v => Number.isFinite(v.value))[0]?.value;
    const maxValue = maxSerie.values.filter(v => Number.isFinite(v.value))[0]?.value;

    if (!Number.isFinite(minValue) || !Number.isFinite(maxValue)) {
      return [];
    }

    const borderThickness = Math.abs(Math.max(maxValue, 0) - Math.min(minValue, 0)) / 150.0;

    const plotBands: PlotBand[] = [];

    if (includeMin) {
      const minPlotBand: PlotBand = {
        from: minValue - 0.5 * borderThickness,
        to: minValue + 0.5 * borderThickness,
        color: 'green',
        opacity: 0.75,
      };
      plotBands.push(minPlotBand);
    }

    if (includeMax) {
      const maxPlotBand: PlotBand = {
        from: maxValue - 0.5 * borderThickness,
        to: maxValue + 0.5 * borderThickness,
        color: 'red',
        opacity: 0.75,
      };
      plotBands.push(maxPlotBand);
    }

    return plotBands;
  }
}
