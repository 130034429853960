<div class="add-meters-container">
  <div>
    <div>
      <form
        (ngSubmit)="searchMeters()"
      >
        <label for="enegiaIdSearch">
          {{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.ENEGIA_ID' | translate }}
        </label>
        <id-input
          [attr.id]="'enegiaIdSearch'"
          [(id)]="enegiaId"
          [disabled]="loading"
        ></id-input>
        <label for="facilitySearch">
          {{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.FACILITY_NAME' | translate }}
        </label>
        <facility-search
          id="facilitySearch"
          (itemSelect)="setFacilityToSearchWith($any($event))"
          [disabled]="loading || isEnegiaIdSet()"
        ></facility-search>
        <button
          type="submit"
          class="button"
          id="searchButton"
          [disabled]="!isSearchEnabled()"
        >{{ 'SEARCH' | translate }}</button>
      </form>
      <span>
        {{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.SEARCH_RESULTS' | translate }}
        <span *ngIf="currentFacility">
          ({{ currentFacility?.displayName }}
          <span class="secondary">{{ currentFacility?.enegiaId }}</span>)
        </span>:
      </span>
    </div>
    <div></div>
    <div id="targetMetersHeaderElement">
      <div class="target-meters-header-row">
        <span>{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.SOURCE_METERS' | translate }} ({{targetMeters.length}} kpl):</span>
        <edit-factor
          [buttonDisabled]="selectedTargetMeterIds.length === 0"
          (factorChange)="changeSelectedTargetMeterFactor($event)"
        ></edit-factor>
      </div>
    </div>
  </div>
  <div>
    <add-meters-source-grid
      [meters]="sourceMeters"
      [selectedMeterIds]="selectedSourceMeterIds"
      [loading]="loading"
      (selectionChange)="sourceMeterSelectionChange($event)"
    ></add-meters-source-grid>
    <div>
      <div class="button-container">
        <button
          [disabled]="selectedSourceMeterIds.length === 0"
          class="button button--circle"
          (click)="addSelectedMeters()"
        >
          <i class="fa fa-chevron-right"></i>
        </button>
        <button
          [disabled]="selectedTargetMeterIds.length === 0"
          class="button button--circle"
          (click)="removeSelectedMeters()"
        >
          <i class="fa fa-chevron-left"></i>
        </button>
      </div>
    </div>
    <add-meters-target-grid
      [meters]="targetMeters"
      [selectedMeterIds]="selectedTargetMeterIds"
      (selectionChange)="targetMeterSelectionChange($event)"
    ></add-meters-target-grid>
  </div>
</div>
