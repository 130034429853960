<div class="wizard-content">
  <div class="step" *ngFor="let step of steps">
    <button
      class="button button--circle wizard-button"
      [disabled]="isStepDisabled(step)"
      (click)="setStep(step)"
    >
      <i *ngIf="isStepVisited(step); else stepNumber" class="fa fa-check"></i>
      <ng-template #stepNumber>{{ getStepNumber(step) }}</ng-template>
    </button>
    <label [ngClass]="{'disabled': isStepDisabled(step)}">{{ step.text }}</label>
    <hr *ngIf="getStepNumber(step) < steps.length" class="separator" />
  </div>
  <button
    class="button"
    *ngIf="isNextVisible()"
    [disabled]="isNextDisabled()"
    (click)="nextClicked()"
  >{{ currentStep.buttonText || 'WIZARD.NEXT' | translate }}</button>
</div>
