import { ColumnIndex } from '../constants/column-indices';

ccSpreadsheetService.$inject = ['utils', 'ColorService'];

export function ccSpreadsheetService(utils, colorService) {

  const createHeader = (headerCells, colors, translationkey) => (
    {
      background: colors.headerBackground,
      textAlign: 'center',
      color: colors.default,
      enable: false,
      value: utils.localizedString(translationkey)
    }
  );

  const getSpreadsheetOptions = function(readerBaseProperties, readerSpecificProperties, rowCount) {

    const colors = {
      error: colorService.getCssProperty('--enerkey-red'),
      headerBackground: colorService.getCssProperty('--enerkey-light-grey'),
      default: colorService.getCssProperty('--enerkey-black')
    };

    const headerCells = [];
    // Base properties
    readerBaseProperties.forEach(property => {
      // Temporary fix. This property is mandatory and needs to be fixed in backend side
      if (property.name === 'hoursBackWards') {
        property.isRequired = true;
      }

      headerCells.push({
        background: colors.headerBackground,
        textAlign: 'center',
        color: colors.default,
        enable: false,
        value: (property.isRequired ? '*' : '') + utils.localizedString(property.translation)
      });
    });

    // Specific properties
    readerSpecificProperties.forEach(property => {
      headerCells.push({
        background: colors.headerBackground,
        textAlign: 'center',
        color: colors.default,
        enable: false,
        value: (property.isRequired ? '*' : '') + utils.localizedString(property.translation)
      });
    });

    // Terminal information
    headerCells.push(createHeader(headerCells, colors, 'ADMIN.TERMINAL.IDENTIFIER'));
    headerCells.push(createHeader(headerCells, colors, 'ADMIN.TERMINAL.NAME'));
    headerCells.push(createHeader(headerCells, colors, 'ADMIN.TERMINAL.DESCRIPTION'));
    headerCells.push(createHeader(headerCells, colors, 'ADMIN.TERMINAL.CONNECTION_INFORMATION'));
    headerCells.push(createHeader(headerCells, colors, 'ADMIN.TERMINAL.USERNAME'));
    headerCells.push(createHeader(headerCells, colors, 'ADMIN.TERMINAL.PASSWORD'));

    // Import status fields
    headerCells.push(createHeader(headerCells, colors,
      'CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.CONFIGURATION_STATUS'));
    headerCells.push(createHeader(headerCells, colors,
      'CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.TERMINAL_STATUS'));

    // Additional columns
    headerCells.splice(ColumnIndex.FACILITY_NAME, 0, {
      background: colors.headerBackground,
      textAlign: 'center',
      color: colors.default,
      enable: false,
      value: utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.FACILITY_NAME')
    });
    headerCells.splice(ColumnIndex.METER_NAME, 0, {
      background: colors.headerBackground,
      textAlign: 'center',
      color: colors.default,
      enable: false,
      value: utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.METER_NAME')
    });
    headerCells.splice(ColumnIndex.ENEGIA_ID, 0, {
      background: colors.headerBackground,
      textAlign: 'center',
      color: colors.default,
      enable: false,
      value: utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.ENEGIA_ID')
    });
    headerCells.splice(ColumnIndex.TREE_ID, 0, {
      background: colors.headerBackground,
      textAlign: 'center',
      color: colors.default,
      enable: false,
      value: utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.TREE_ID')
    });
    headerCells.splice(ColumnIndex.NODE_ID, 0, {
      background: colors.headerBackground,
      textAlign: 'center',
      color: colors.default,
      enable: false,
      value: utils.localizedString('CONFIGURATION_CONTROL.MASS_IMPORT.WIZARD.NODE_ID')
    });

    const columns = [];
    headerCells.forEach(() => {
      columns.push({ width: 100 });
    });
    columns.push({ width: 140 }); // for row status text column

    const spreadsheetOptions = {
      toolbar: false,
      sheetsbar: false,
      activeSheet: 'data',
      rows: rowCount,
      sheets: [
        {
          name: 'data',
          columns: columns,
          rows: [
            {
              height: 20,
              cells: headerCells
            }
          ]
        },
        {
          name: 'BooleanValues',
          // A1:B1
          rows: [
            {
              cells: [
                {
                  value: true
                },
                {
                  value: false
                }
              ]
            }
          ]
        }
      ]
    };
    return spreadsheetOptions;
  };

  return {
    getSpreadsheetOptions: getSpreadsheetOptions
  };
}
