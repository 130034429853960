import { process } from '@progress/kendo-data-query';

import { DONUT_CONFIG, YEARLY_CONFIG } from './em-visuals-constants';

const $inject = ['$state', '$timeout'];

class EmVisualsController {
  constructor($state, $timeout) {
    this.$state = $state;
    this.$timeout = $timeout;

    this.maxGridGroups = 30;
    this.tooManyGroups = false;
    this.donutConfig = DONUT_CONFIG;
    this.yearlyVisualsConfig = YEARLY_CONFIG;
    this.viewDataProcessed = null;
  }

  $onChanges() {
    if (this.groups && this.data && this.columns && this.aggregates) {
      this.viewDataProcessed = process(
        this.data,
        { group: this.groups }
      ).data;
      this.update();
    }
  }

  setUpdateDonutVisuals(func) {
    this.updateDonutVisuals = func; // This is defined in emDonutVisuals controller
  }

  setUpdateYearlyVisuals(func) {
    this.updateYearlyVisuals = func; // This is defined in emDonutVisuals controller
  }

  update() {
    if (this.viewDataProcessed.length <= this.maxGridGroups) {
      this.$timeout(() => {
        this.updateDonutVisuals();
        this.updateYearlyVisuals();
      });
      this.tooManyGroups = false;
    } else {
      this.tooManyGroups = true;
    }
  }
}

EmVisualsController.$inject = $inject;
export default EmVisualsController;
