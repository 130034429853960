import { Pipe, PipeTransform } from '@angular/core';

import { flattenGroupedData, GridData } from '@enerkey/ts-utils';

import { PropertyPathPipe } from '../../../shared/common-pipes/property-path.pipe';

@Pipe({ name: 'gridGroupAggregate' })
export class GridGroupAggregatePipe implements PipeTransform {

  public constructor(
    private readonly ppp: PropertyPathPipe
  ) { }

  public transform(group: { items: GridData<unknown> }, field: string): number {
    return flattenGroupedData(group.items).reduce(
      (sum: number, item: unknown) => {
        const value = this.ppp.transform(item, field) as number;
        return Number.isFinite(value) ? (value + sum) : sum;
      },
      0
    );
  }

}
