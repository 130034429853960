import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LogStatus } from '@enerkey/clients/alarm';

@Component({
  selector: 'alarm-log-status-label',
  templateUrl: './alarm-log-status-label.component.html',
  styleUrls: ['./alarm-log-status-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlarmLogStatusLabelComponent {
  @Input() public status: LogStatus;
  @Input() public small: boolean = false;
}
