import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ReportingUnit } from '@enerkey/clients/reporting';
import { getStringEnumValues } from '@enerkey/ts-utils';

import { EkComboComponent } from '../ek-combo/ek-combo.component';

const unitComboTranslations: Record<ReportingUnit, string> = {
  [ReportingUnit.Default]: 'DEFAULT',
  [ReportingUnit.KWh]: 'kWh',
  [ReportingUnit.MWh]: 'MWh',
};

@Component({
  selector: 'unit-select',
  templateUrl: '../ek-combo/ek-combo.component.html',
  styleUrls: ['../ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UnitSelectComponent),
    multi: true
  }]
})
export class UnitSelectComponent extends EkComboComponent<ReportingUnit> {
  public override items = getStringEnumValues(ReportingUnit).map(option => ({
    value: option,
    text: unitComboTranslations[option]
  }));
}
