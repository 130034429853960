import { ReportNote } from '../../shared/report-note';
import { AlarmService } from '../../../../shared/services/alarm.service';
import { $InjectArgs } from '@enerkey/ts-utils';
import {
  AlarmDetailsModalComponent
} from '../../../../shared/alarms-shared/components/alarm-details-modal/alarm-details-modal.component';
import { ModalService } from '@enerkey/foundation-angular';

export class AlarmsPopupTableController {

  public static readonly $inject: $InjectArgs<typeof AlarmsPopupTableController> = [
    'alarmService',
    'modalServiceAngular',
  ];

  public notes: ReportNote[];
  public limitCount: number;

  public constructor(
    private readonly alarmService: AlarmService,
    private readonly modalService: ModalService
  ) { }

  public getAlarmTypeName(typeId: number): string {
    // Alarm type keys are in Alarms translation module, so can't use them straight in template
    return this.alarmService.getAlarmTypeName(typeId);
  }

  public openAlarmModal(logId: number): void {
    const modalInstance = this.modalService.open(AlarmDetailsModalComponent);
    modalInstance.componentInstance.getAlarmByLogId(logId);
  }
}
