import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import angular from 'angular';
import { DEFAULT_COLOR, HEADER_BACKGROUND } from '../../../../shared/spreadsheet.functions';
import {
  AddPersonValidationError,
  CreateCompanyModel,
  FunctionType,
  IContactClient
} from '@enerkey/clients/contact';
import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import { ToasterType } from '../../../../constants/toaster-type';
import WizardStep from '../../../../components/wizard/wizard-step';

const inject = ['utils', 'ContactClient', '$element', '$timeout', '$window', 'KendoFunctions'];

class MassAddCompaniesController extends MassImportSpreadsheet {
  private static nameIndex: number = 0;
  private static companyOvtIndex: number = 1;
  private static streetAddressIndex: number = 2;
  private static postalCodeIndex: number = 3;
  private static cityIndex: number = 4;
  private static countryIndex: number = 5;
  private static emailIndex: number = 6;
  private static phoneNumberIndex: number = 7;
  private static superintendentIndex: number = 8;
  private static managerIndex: number = 9;
  private static serviceIndex: number = 10;
  private static ownerIndex: number = 11;
  private static alarmIndex: number = 12;

  public spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  public steps: WizardStep[];
  public currentStep: WizardStep;
  public functionTypes: ({ translation: string; value: number })[];
  public functionType: number;
  public spreadsheetElementId = 'spreadsheet';
  protected messages: SpreadsheetMessages;
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected numberOfColumns = 14;
  private processedIndexes: number[] = [];
  private subscription: Subscription;

  public constructor(
    private utils: any,
    private contactClient: IContactClient,
    protected $element: JQuery,
    protected $timeout: any,
    protected $window: any,
    protected kendoFunctions: any
  ) {
    super();
  }

  public $onInit(): void {
    this.spreadsheetOptions = this.getSpreadsheet(999);
    this.totalRowCount = this.spreadsheetOptions.rows;
    this.messages = {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE')
    };
    this.steps = [
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
        isReturnable: true,
        clickFn: this.saveValues.bind(this),
        rollbackFn: this.enableSheet.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
        isReturnable: false,
        clickFn: this.stopSaving.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.READY'),
        hideButton: true
      }
    ];

    this.$timeout(() => {
      this.resizeKendo();
    });
  }

  public override $onDestroy(): void {
    super.$onDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private resizeKendo(): void {
    const spreadsheet = this.getSpreadsheetInstance();

    const resizeFn = _.debounce(() => {
      this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
    }, 200);

    angular.element(this.$window).on('resize', resizeFn);

    this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
  }

  private stopSaving(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.processedIndexes.forEach(id => this.setCanceledStatus(id));
    }
  }

  private getCreateCompanyModelArrayFromSpreadsheet(): CreateCompanyModel[] {
    this.processedIndexes = [];
    const createCompanyModelArray: CreateCompanyModel[] = [];

    this.getDataRows().forEach((row, index) => {
      if (row.length > 1 && row[0] !== null) {
        const createPersonModel: CreateCompanyModel = {
          name: row[MassAddCompaniesController.nameIndex].toString(),
          companyOvt: row[MassAddCompaniesController.companyOvtIndex] ?
            row[MassAddCompaniesController.companyOvtIndex].toString() :
            null,
          streetAddress: row[MassAddCompaniesController.streetAddressIndex] ?
            row[MassAddCompaniesController.streetAddressIndex].toString() :
            null,
          postalCode: row[MassAddCompaniesController.postalCodeIndex] ?
            row[MassAddCompaniesController.postalCodeIndex].toString() :
            null,
          city: row[MassAddCompaniesController.cityIndex] ?
            row[MassAddCompaniesController.cityIndex].toString() :
            null,
          country: row[MassAddCompaniesController.countryIndex] ?
            row[MassAddCompaniesController.countryIndex].toString() :
            null,
          email: row[MassAddCompaniesController.emailIndex] ?
            row[MassAddCompaniesController.emailIndex].toString() :
            null,
          phoneNumber: row[MassAddCompaniesController.phoneNumberIndex] ?
            row[MassAddCompaniesController.phoneNumberIndex].toString() :
            null,
          availableFunctions: this.rowCellsToAvailableFunctions(row)
        };

        createCompanyModelArray.push(createPersonModel);
        this.setProcessingStatus(index);
        this.processedIndexes.push(index);
      }
    });

    return createCompanyModelArray;
  }

  private rowCellsToAvailableFunctions(row: (string | number)[]): FunctionType {
    let availableFunctions: FunctionType | number = 0;
    if (row[MassAddCompaniesController.superintendentIndex] === this.utils.localizedString('YES')) {
      availableFunctions |= FunctionType.Superintendent;
    }
    if (row[MassAddCompaniesController.managerIndex] === this.utils.localizedString('YES')) {
      availableFunctions |= FunctionType.Manager;
    }
    if (row[MassAddCompaniesController.serviceIndex] === this.utils.localizedString('YES')) {
      availableFunctions |= FunctionType.Service;
    }
    if (row[MassAddCompaniesController.ownerIndex] === this.utils.localizedString('YES')) {
      availableFunctions |= FunctionType.Owner;
    }
    if (row[MassAddCompaniesController.alarmIndex] === this.utils.localizedString('YES')) {
      availableFunctions |= FunctionType.Alarm;
    }
    return availableFunctions;
  }

  private saveValues(): void {
    if (this.validateSheet()) {
      this.processedIndexes = [];
      const createCompanyModelArray = this.getCreateCompanyModelArrayFromSpreadsheet();
      if (createCompanyModelArray.length === 0) {
        this.utils.popUp(
          ToasterType.INFO,
          this.utils.localizedString('ADMIN.CONTACTS.ERRORS.EMPTY_SPREADSHEET')
        );
        return;
      }
      this.subscription = this.contactClient
        .createCompanies(createCompanyModelArray)
        .pipe(finalize(() => this.handleFinally()))
        .subscribe(
          () => this.handleSuccess(),
          error => this.handleError(error)
        );
    } else {
      this.utils.popUp(ToasterType.ERROR, this.utils.localizedString('ADMIN.CONTACTS.ERRORS.INVALID_SPREADSHEET'));
    }
  }

  private handleSuccess(): void {
    this.processedIndexes.forEach(id => this.setSuccessStatus(id));
    this.utils.popUp(
      ToasterType.SUCCESS,
      this.utils.localizedString('CONTACTMANAGER.SUCCESS.ADDED_COMPANIES')
    );
  }

  private handleError(error: { [key: string]: AddPersonValidationError[] }): void {
    Object.keys(error).forEach(index => {
      this.setErrorStatus(Number(index), this.getErrorMessages(error[index]));
    });
    this.utils.popUpGeneralError('SAVE', 'CONTACTS');
  }

  private handleFinally(): void {
    this.setFinalStep();
  }

  private getErrorMessages(errors: number[]): string {
    return errors.map(error =>
      this.utils.localizedString(`ADMIN.CONTACTS.ERRORS.${AddPersonValidationError[error]}`))
      .toString();
  }

  private setFinalStep(): void {
    this.currentStep = this.steps[this.steps.length - 1];
  }

  private getSpreadsheet(rowAmount: number): kendo.ui.SpreadsheetOptions {
    let headerCells: any[] = [];

    const textHeaderCells = this.createTextHeaderCells();
    headerCells = headerCells.concat(textHeaderCells);

    const booleanHeaderCells = this.createBooleanHeaderCells();
    headerCells = headerCells.concat(booleanHeaderCells);

    const statusHeaderCell = {
      value: this.utils.localizedString('ADMIN.SPREADSHEET.STATUS.HEADING'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    };

    headerCells = headerCells.concat(statusHeaderCell);

    const spreadsheetOptions: kendo.ui.SpreadsheetOptions = {
      toolbar: false,
      sheetsbar: false,
      columns: this.numberOfColumns,
      excel: {
        fileName: 'mass_add_companies.xlsx'
      },
      rows: 999,
      defaultCellStyle: {
        background: 'rgba(255,255,255,0)'
      },
      sheets: [
        {
          name: 'default',
          columns: this.createColumnWidths(),
          rows: [{
            cells: headerCells
          }]
        }
      ]
    };

    for (let i = 0; i < rowAmount; i++) {
      const cells = this.createInputRow(textHeaderCells, booleanHeaderCells);
      spreadsheetOptions.sheets[0].rows.push({ cells: cells });
    }

    return spreadsheetOptions;
  }

  private createColumnWidths(): kendo.ui.SpreadsheetSheetColumn[] {
    const columns: kendo.ui.SpreadsheetSheetColumn[] = [];
    for (let i = 0; i < this.numberOfColumns; i++) {
      columns.push({ width: 200 });
    }
    return columns;
  }

  private createInputRow(textHeaderCells: kendo.ui.SpreadsheetSheetRowCell[],
    booleanHeaderCells: kendo.ui.SpreadsheetSheetRowCell[]): kendo.ui.SpreadsheetSheetRowCell[] {
    const cells = [];

    textHeaderCells.forEach(() => {
      cells.push({});
    });

    booleanHeaderCells.forEach(() => {
      cells.push({
        type: 'text',
        validation: {
          dataType: 'list',
          showButton: true,
          from: `{ "${this.utils.localizedString('YES')}", "${this.utils.localizedString('NO')}" }`,
          allowNulls: true,
          type: 'reject',
          messageTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.TITLE'),
          titleTemplate: this.utils.localizedString('ADMIN.SPREADSHEET.VALUE.MESSAGE')
        }
      });
    });

    // status should not be editable
    cells.push({
      enable: false
    });

    return cells;
  }

  private createBooleanHeaderCells(): kendo.ui.SpreadsheetSheetRowCell[] {
    return [{
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Superintendent]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Manager]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Service]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Owner]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString(`CONTACTMANAGER.FUNCTION.${FunctionType[FunctionType.Alarm]}`),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }];
  }

  private createTextHeaderCells(): kendo.ui.SpreadsheetSheetRowCell[] {
    return [{
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.NAME'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.OVT'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.STREETADDRESS'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.POSTALCODE'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.CITY'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.COUNTRY'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.EMAIL'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }, {
      value: this.utils.localizedString('CONTACTMANAGER.COMPANY.PHONENUMBER'),
      background: HEADER_BACKGROUND,
      textAlign: 'center',
      color: DEFAULT_COLOR,
      enable: false
    }];
  }
}

MassAddCompaniesController.$inject = inject;

export default MassAddCompaniesController;

