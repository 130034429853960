import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Directive, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * When placed on a form with `formGroup` set, allows for only one control to
 * have a values at a time.
 * @example
 * <form
 *   singleInputEnabled
 *   [formGroup]="group"
 *   (isFormEmpty)="submitDisabled = $event">
 */
@Directive({ selector: 'form[singleInputEnabled]' })
export class SingleInputEnabledDirective implements OnDestroy {

  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  @Input()
  public set formGroup(value: UntypedFormGroup) {
    if (this._formGroup !== value && value instanceof UntypedFormGroup) {
      this.subscription.unsubscribe();

      this._formGroup = value;

      this.subscription = this.formGroup.valueChanges.subscribe(
        formValues => this.onValueChanges(formValues)
      );
    }
  }

  @Input() public exclude: string[] = [];

  @Output() public readonly isFormEmpty = new EventEmitter<boolean>();

  private _formGroup: UntypedFormGroup = null;
  private subscription: Subscription = new Subscription();

  /* istanbul ignore next */
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private onValueChanges(formValues: { [key: string]: unknown }): void {
    const params = Object.entries(this.formGroup.controls).filterMap(
      ([key]) => !this.exclude.includes(key),
      ([key, control]) => ({ key, control })
    );

    // Note: checkboxes are treated as active when they are checked
    const activeParams = params.filter(param => {
      const value = formValues[param.key];
      return Array.isArray(value)
        ? Array.hasItems(value)
        : value || value === 0
      ;
    });

    const formEmpty = (activeParams.length === 0);

    if (formEmpty) {
      this.changeControlStates('enable', params);
    } else {
      this.changeControlStates('enable', activeParams);
      this.changeControlStates('disable', params.except(activeParams));
    }

    this.isFormEmpty.emit(formEmpty);
  }

  private changeControlStates(
    action: 'enable' | 'disable',
    params: { control: AbstractControl }[]
  ): void {
    const valueShouldChange = action === 'enable';

    for (const param of params) {
      if (param.control.disabled === valueShouldChange) {
        param.control[action]();
      }
    }
  }
}
