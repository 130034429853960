<form [formGroup]="formGroup">
  <kendo-numerictextbox
    formControlName="biggestGainers"
    *labelBefore="'TOPCONSUMPTIONS_WIDGET.BIGGEST_GAINERS' | translate"
    integerInput
  ></kendo-numerictextbox>
  <kendo-numerictextbox
    formControlName="biggestFallers"
    *labelBefore="'TOPCONSUMPTIONS_WIDGET.BIGGEST_FALLERS' | translate"
    integerInput
  ></kendo-numerictextbox>
  <widget-variable-select
    formControlName="variableId"
    *labelBefore="'TOPCONSUMPTIONS_WIDGET.VARIABLE' | translate"
  ></widget-variable-select>
  <value-type-select
    formControlName="valueOption"
    *labelBefore="'FACILITIES_TOOLBAR.VALUE_OPTION' | translate"
  ></value-type-select>
  <widget-change-select
    formControlName="changeOption"
    *labelBefore="'CHANGE.TITLE' | translate"
  ></widget-change-select>
  <unit-select
    formControlName="unitKey"
    *labelBefore="'FACILITIES.UNIT' | translate"
  ></unit-select>
  <widget-timeframe-select
    formControlName="timeFrameOption"
    *labelBefore="'TIMEFRAME_OPTIONS.TITLE' | translate"
  ></widget-timeframe-select>
  <widget-comparison-period-select
    formControlName="comparisonPeriodOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARISON_SERIE' | translate"
  ></widget-comparison-period-select>
  <comparability-select
    formControlName="comparableOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARABLE' | translate"
  ></comparability-select>
</form>
