<kendo-dropdownlist
  [(ngModel)]="value"
  [data]="options"
  [valuePrimitive]="true"
  [valueField]="'value'"
  [textField]="'key'"
  [loading]="loading"
  [disabled]="disabled || (loading && disableWhenLoading)"
  [popupSettings]="{ animate: false }"
  (ngModelChange)="valueChanged($event)"
  (blur)="blur()"
>
  <ng-template
    kendoDropDownListValueTemplate
    let-dataItem
  >
    <span class="noselect">
      <ng-container *ngIf="dataItem; else noItem">
        <span class="icon-span {{ dataItem.iconClass }}"></span>&nbsp;
        {{ dataItem.key | translate }}
      </ng-container>
    </span>
  </ng-template>

  <ng-template
    kendoDropDownListItemTemplate
    let-dataItem
  >
    <span class="noselect">
      <span class="icon-span {{ dataItem.iconClass }}"></span>&nbsp;
      {{ dataItem.key | translate }}
    </span>
  </ng-template>
</kendo-dropdownlist>

<ng-template #noItem>
  {{ 'ENERGY_MANAGEMENT.CONSUMPTION_TARGET.CHOOSE_QUANTITY' | translate }}
  <span class="icon-preload"></span> <!-- Hack to preload the icon font before dropdown opens -->
</ng-template>
