import { Pipe, PipeTransform } from '@angular/core';

import { ActionSlimViewModel } from '@enerkey/clients/attachments';
import { MeterManagementMeter } from '@enerkey/clients/meter-management';

@Pipe({
  name: 'meterReportActions'
})
export class MeterReportActionsPipe implements PipeTransform {
  public transform(
    actions: ActionSlimViewModel[],
    meter: MeterManagementMeter
  ): ActionSlimViewModel[] {
    return actions?.filter(
      a => (!Array.hasItems(a.quantityIds) || a.quantityIds.includes(meter.quantityId))
        && (!Array.hasItems(a.meterIds) || a.meterIds.includes(meter.id))
    ) ?? [];
  }
}
