import { Injectable, OnDestroy } from '@angular/core';
import { merge, MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

import { ObservablePool } from '@enerkey/rxjs';

import { FacilityService } from '../../../shared/services/facility.service';

type WidgetApi = 'energyreporting' | 'attachments';

@Injectable()
export class WidgetQueueService implements OnDestroy {

  public static readonly threshold = 1000 as const;

  private readonly _pools: Record<WidgetApi, ObservablePool>;
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly facilityService: FacilityService
  ) {
    this._pools = {
      energyreporting: new ObservablePool(1),
      attachments: new ObservablePool(1),
    };
  }

  public queue<T>(api: WidgetApi, cancel$: Observable<unknown>): MonoTypeOperatorFunction<T> {
    return source => this.facilityService.filteredProfileFacilities$.pipe(
      take(1),
      switchMap(profileFacilities => {
        if (!profileFacilities || profileFacilities.length < WidgetQueueService.threshold) {
          return source;
        }
        return this._pools[api].queue(source);
      }),
      takeUntil(merge(this._destroy$, cancel$))
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._pools.energyreporting.destroy();
    this._pools.attachments.destroy();
  }
}
