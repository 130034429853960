import { IAngularStatic, ITimeoutService } from 'angular';
import { aggregateBy, AggregateResult, process } from '@progress/kendo-data-query';

import SearchTypes, { StorageSearchType } from '../../constants/search-types';
import { GRID_SELECTOR, kendoGridHeaderCheckboxClass } from './grid';
import { RESULT_CONTAINER } from './manual-qa-result.constants';
import { getVisualizationConfig } from './manual-qa-result.functions';
import { selectOrUnselectGroupItems } from '../../../../shared/ek-kendo/kendo.functions';
import { SearchItemType } from '../../constants/search-item-type';
import { InspectionDefectItem, InspectionItem, SearchList } from '@enerkey/clients/manual-qa';
import { ManualQaResultService } from './manual-qa-result-service';
import { ModalService } from '@enerkey/foundation-angular';
import { EmailSendModalComponent } from '../../components/email-send-modal/email-send-modal.component';

const $inject = [
  '$element', '$timeout', 'ManualQaResultService',
  'KendoFunctions', 'Scroll', 'modalServiceAngular'
];

declare const angular: IAngularStatic;

class ManualQaResultController {
  public gridOptions = {};
  public grid: kendo.ui.Grid = {} as any;
  public selectedItems: InspectionItem[] | InspectionDefectItem[] = [];
  public viewVisuals = false;
  public SearchItemType = SearchItemType;
  public searchList: SearchList;
  public searchItemType: SearchItemType;
  public visualizationConfig: any;
  public onInspect: Function;
  public viewDataProcessed: any[] = [];
  public columns: any[] = [];
  public aggregates: AggregateResult;
  private items: InspectionItem[] | InspectionDefectItem[];
  private searchType: number = SearchTypes.STORAGE_TYPE.DEFECT;
  private allSelected = false;
  private updateVisualizationCallback: Function;

  public constructor(
    private $element: JQuery,
    private $timeout: ITimeoutService,
    private manualQaResultService: ManualQaResultService,
    private KendoFunctions: any,
    private Scroll: any,
    private modalServiceAngular: ModalService
  ) {
  }

  public $onInit(): void {
    this.items = angular.copy(this.items);
    this.searchList = angular.copy(this.searchList);
    this.searchType = this.searchList.getSearchCriteria().getSearchType();
    this.gridOptions = this.manualQaResultService.getResultGridOptions(
      this.searchType as StorageSearchType, this.updateVisualization.bind(this), this.searchItemType
    );
    this.visualizationConfig = getVisualizationConfig();
  }

  public $postLink(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    this.grid = this.$element
      .find(GRID_SELECTOR)
      .data('kendoGrid')
    ;

    this.grid.dataSource.data(this.items);

    this.selectAll();

    this.KendoFunctions.resizeKendoComponent(this.grid, null, () => {
      self.Scroll.scrollTo(RESULT_CONTAINER);
    });
  }

  public onEmailSend(): void {
    const modal = this.modalServiceAngular.open(
      EmailSendModalComponent,
      { windowClass: 'fixed-height modal-dialog-scrollable' }
    );
    const componentInstance = modal.componentInstance;
    componentInstance.defects = this.selectedItems as InspectionDefectItem[];
  }

  public setVisualizationUpdater(func: Function): void {
    this.updateVisualizationCallback = func;
  }

  public select(item: any): void {
    if (!item) {
      return;
    }

    this.updateSelectedItems();
  }

  public selectOrUnselectGroup(event: any, dataItem: any): void {
    selectOrUnselectGroupItems(event, dataItem);
    this.updateSelectedItems();
  }

  /**
   * Selects or deselects all values from grid
   */
  public selectAll(): void {
    this.checkOrUncheckGroupHeaderCheckboxes(this.allSelected);

    // Select only filtered items if data is filtered
    this.getItems()
      .map((item: any) => {
        item.selected = this.allSelected;
      });

    this.updateSelectedItems();
  }

  public inspect(): void {
    this.onInspect({ items: this.getItems().filter((item: any) => item.selected) });
  }

  private updateVisualization(): void {
    if (this.grid?.dataSource) {
      this.viewVisuals = this.grid.dataSource.group().length === 1;
    }
    if (this.viewVisuals) {
      this.columns = this.grid.columns.filter(col => !col.hidden);

      const aggregates = this.visualizationConfig[0].columns;
      const group = this.grid.dataSource.group().map((g: any) => ({
        ...g,
        aggregates
      }));

      this.viewDataProcessed = process(
        this.grid.dataSource.data() as unknown as any[],
        {
          group: group,
          filter: this.grid.dataSource.filter() as any
        }
      ).data;
      this.aggregates = aggregateBy(this.viewDataProcessed.flatMap(g => g.items), aggregates);
      this.$timeout(() => {
        if (angular.isFunction(this.updateVisualizationCallback)) {
          this.updateVisualizationCallback();
        }
      });
    }
  }

  private getItems(): any {
    return this.KendoFunctions.isDataFiltered(this.grid.dataSource) ?
      this.KendoFunctions.getFilteredRows(this.grid.dataSource) :
      this.grid.dataSource.data();
  }

  private checkOrUncheckGroupHeaderCheckboxes(checked: boolean): void {
    this.$element.find(`.${kendoGridHeaderCheckboxClass}`).each((_index, checkbox) => {
      (checkbox as any).checked = checked;
    });
  }

  private updateSelectedItems(): void {
    this.selectedItems = this.getItems()
      .filter((item: any) => item.selected);
  }
}

ManualQaResultController.$inject = $inject;

export default ManualQaResultController;
