import { GridReportNotesPopupController } from './grid-report-notes-popup.controller';
import gridReportNotesPopupTemplate from 'raw-loader!./grid-report-notes-popup.html';

export const gridReportNotesPopupComponent = {
  template: gridReportNotesPopupTemplate,
  controller: GridReportNotesPopupController,
  controllerAs: 'vm',
  bindings: {
    row: '<',
    type: '<'
  }
};
