const itemsSortingArray = [
  'RealEstateId',
  'StreetAddress',
  'PostalCode',
  'City',
  'Country',
  'GeographicalArea',
  'Locale',
  'Currency',
  'CountryCode',
  'FacilityType',
  'CustomBuildingClass',
  'ConstructionYear',
  'DecadeOfBuild',
  'RenovationYear',
  'Status',
  'Ownership',
  'ElectricityTaxClass',
  'CustomerSetDescription',
  'BusinessIdentityCode',
  'OwnerCompanyName',
  'EnegiaId',
  'ManagementResponsibility',
  'ExternalFacilityId',
  'ExternalContractId',
  'PowerOfAttorneyExpirationDate',
  'BillingEndDate',
  'ReportingEndDate',
  'Created',
  'LastModified',
  // technical specifications
  'HeatingMethod',
  'SecondaryHeatingMethod',
  'HeatDistributionSystem',
  'AutomationSystem',
  'EfficiencyRatio',
  'PrimaryVentilationSystem',
  'SecondaryVentilationSystem',
  'VentilationMethod',
  'Refrigerant',
  'ShareOfTemperatureFixing',
  'RatedPower',
  // properties
  'Volume',
  'TotalVolume',
  'HeatedVolume',
  'GrossArea',
  'NetFloorArea',
  'Area',
  'HeatedArea',
  'GrossFloorArea',
  'TotalLivingArea',
  'RentedArea',
  'StoreArea',
  'Inhabitants',
  'AmountPersons',
  'AccommodationNights',
  'AmountPieces',
  'AmountVolume',
  'AmountKg',
  'UtilizationRate',
  // co2factors
  'Electricity',
  'ReactivePower',
  'Water',
  'HotWater',
  'DistrictHeating',
  'DistrictHeatingWater',
  'DistrictHeatingOutflowWater',
  'DistrictHeatingInflowWater',
  'Steam',
  'Oil',
  'NaturalGas',
  'DistrictCooling',
  'DistrictCoolingWater',
  'IceAgeWater',
  'WasteWater',
  'CoolingWater',
  'CompressedAir',
  'LiquefiedPetroleumGas',
  'CoolingOwnProduction',
  'HeatingOwnProduction',
  'NaturalGas',
  'BioGas',
  'Pellet',
  'WoodChips',
  'HeavyOil',
  'CarbonDioxide',
  'Oxygen',
  'Nitrogen',
  'DryIce',
  'ReactivePowerPlus',
  'ReactivePowerMinus',
  // Energy Company
  'ElectricityDistributionCompany',
  'ElectricityUsagePlace',
  'DistrictHeatingDistributionCompany',
  'DistrictHeatingUsagePlace',
  'WaterDistributionCompany',
  'WaterUsagePlace',
  // Building Maintenance company
  'maintenanceCompanyName',
  'maintenanceCompanyEmail',
  'maintenanceCompanyPhone',
  'maintenanceCompanyContactName',
  'maintenanceCompanyContactEmail',
  'maintenanceCompanyContactPhone',
  // Building Manager company
  'managerCompanyName',
  'managerCompanyEmail',
  'managerCompanyPhone',
  'managerCompanyContactName',
  'managerCompanyContactEmail',
  'managerCompanyContactPhone',
  // Building Owner company
  'ownerCompanyName',
  'ownerCompanyEmail',
  'ownerCompanyPhone',
  'ownerCompanyContactName',
  'ownerCompanyContactEmail',
  'ownerCompanyContactPhone',
  // Building FacilityManagement company
  'facilityManagementCompanyName',
  'facilityManagementCompanyEmail',
  'facilityManagementCompanyPhone',
  'facilityManagementCompanyContactName',
  'facilityManagementCompanyContactEmail',
  'facilityManagementCompanyContactPhone',
  // External Ids
  'ExternalOrganizationId',
  'ExternalId',
  // Meters
  // Electrivity
  'ElectricityHourlyMetersCount',
  'ElectricityManualReadingsMetersCount',
  'ElectricityConstantMetersCount',
  'ElectricityVirtualMetersCount',
  'ElectricityManualConsumptionsMetersCount',
  // Natural Gas
  'NaturalGasHourlyMetersCount',
  'NaturalGasManualReadingsMetersCount',
  'NaturalGasConstantMetersCount',
  'NaturalGasVirtualMetersCount',
  'NaturalGasManualConsumptionsMetersCount',
  // Distict Heating
  'DistrictHeatingHourlyMetersCount',
  'DistrictHeatingManualReadingsMetersCount',
  'DistrictHeatingConstantMetersCount',
  'DistrictHeatingVirtualMetersCount',
  'DistrictHeatingManualConsumptionsMetersCount',
  // Water
  'WaterHourlyMetersCount',
  'WaterManualReadingsMetersCount',
  'WaterConstantMetersCount',
  'WaterVirtualMetersCount',
  'WaterManualConsumptionsMetersCount',
  // Oil
  'OilHourlyMetersCount',
  'OilManualReadingsMetersCount',
  'OilConstantMetersCount',
  'OilVirtualMetersCount',
  'OilManualConsumptionsMetersCount',
  // Hot Water
  'HotWaterHourlyMetersCount',
  'HotWaterManualReadingsMetersCount',
  'HotWaterConstantMetersCount',
  'HotWaterVirtualMetersCount',
  'HotWaterManualConsumptionsMetersCount',
  // Ice Age Water
  'IceAgeWaterHourlyMetersCount',
  'IceAgeWaterManualReadingsMetersCount',
  'IceAgeWaterConstantMetersCount',
  'IceAgeWaterVirtualMetersCount',
  'IceAgeWaterManualConsumptionsMetersCount',
  // Waste Water
  'WasteWaterHourlyMetersCount',
  'WasteWaterManualReadingsMetersCount',
  'WasteWaterConstantMetersCount',
  'WasteWaterVirtualMetersCount',
  'WasteWaterManualConsumptionsMetersCount',
  // Cooling Water
  'CoolingWaterHourlyMetersCount',
  'CoolingWaterManualReadingsMetersCount',
  'CoolingWaterConstantMetersCount',
  'CoolingWaterVirtualMetersCount',
  'CoolingWaterManualConsumptionsMetersCount',
  // Compressed Air
  'CompressedAirHourlyMetersCount',
  'CompressedAirManualReadingsMetersCount',
  'CompressedAirConstantMetersCount',
  'CompressedAirVirtualMetersCount',
  'CompressedAirManualConsumptionsMetersCount',
  // District Cooling
  'DistrictCoolingHourlyMetersCount',
  'DistrictCoolingManualReadingsMetersCount',
  'DistrictCoolingConstantMetersCount',
  'DistrictCoolingVirtualMetersCount',
  'DistrictCoolingManualConsumptionsMetersCount',
  // Steam
  'SteamHourlyMetersCount',
  'SteamManualReadingsMetersCount',
  'SteamConstantMetersCount',
  'SteamVirtualMetersCount',
  'SteamManualConsumptionsMetersCount',
  // Liquefied Petroleum Gas
  'LiquefiedPetroleumGasHourlyMetersCount',
  'LiquefiedPetroleumGasManualReadingsMetersCount',
  'LiquefiedPetroleumGasConstantMetersCount',
  'LiquefiedPetroleumGasVirtualMetersCount',
  'LiquefiedPetroleumGasManualConsumptionsMetersCount',
  // Biogas
  'BioGasCumulativeReadingsMetersCount',
  'BioGasConstantReadingsMetersCount',
  'BioGasHourlyMetersCount',
  'BioGasManualReadingsMetersCount',
  'BioGasVirtualReadingsMetersCount',
  // Carbon Dioxide
  'CarbonDioxideCumulativeReadingsMetersCount',
  'CarbonDioxideConstantReadingsMetersCount',
  'CarbonDioxideHourlyMetersCount',
  'CarbonDioxideManualReadingsMetersCount',
  'CarbonDioxideVirtualReadingsMetersCount',
  // Cooling own production
  'CoolingOwnProductionCumulativeReadingsMetersCount',
  'CoolingOwnProductionConstantReadingsMetersCount',
  'CoolingOwnProductionHourlyMetersCount',
  'CoolingOwnProductionManualReadingsMetersCount',
  'CoolingOwnProductionVirtualReadingsMetersCount',
  // Dry Ice
  'DryIceCumulativeReadingsMetersCount',
  'DryIceConstantReadingsMetersCount',
  'DryIceHourlyMetersCount',
  'DryIceManualReadingsMetersCount',
  'DryIceVirtualReadingsMetersCount',
  // Heating own production
  'HeatingOwnProductionCumulativeReadingsMetersCount',
  'HeatingOwnProductionConstantReadingsMetersCount',
  'HeatingOwnProductionHourlyMetersCount',
  'HeatingOwnProductionManualReadingsMetersCount',
  'HeatingOwnProductionVirtualReadingsMetersCount',
  // Heavy oil
  'HeavyOilCumulativeReadingsMetersCount',
  'HeavyOilConstantReadingsMetersCount',
  'HeavyOilHourlyMetersCount',
  'HeavyOilManualReadingsMetersCount',
  'HeavyOilVirtualReadingsMetersCount',
  // Natural gas
  'NaturalGasOtherCumulativeReadingsMetersCount',
  'NaturalGasOtherConstantReadingsMetersCount',
  'NaturalGasOtherHourlyMetersCount',
  'NaturalGasOtherManualReadingsMetersCount',
  'NaturalGasOtherVirtualReadingsMetersCount',
  // Nitrogen
  'NitrogenCumulativeReadingsMetersCount',
  'NitrogenConstantReadingsMetersCount',
  'NitrogenHourlyMetersCount',
  'NitrogenManualReadingsMetersCount',
  'NitrogenVirtualReadingsMetersCount',
  // Oxygen
  'OxygenCumulativeReadingsMetersCount',
  'OxygenConstantReadingsMetersCount',
  'OxygenHourlyMetersCount',
  'OxygenManualReadingsMetersCount',
  'OxygenVirtualReadingsMetersCount',
  // Pellet
  'PelletCumulativeReadingsMetersCount',
  'PelletConstantReadingsMetersCount',
  'PelletHourlyMetersCount',
  'PelletManualReadingsMetersCount',
  'PelletVirtualReadingsMetersCount',
  // Reactive minus
  'ReactivePowerMinusCumulativeReadingsMetersCount',
  'ReactivePowerMinusConstantReadingsMetersCount',
  'ReactivePowerMinusHourlyMetersCount',
  'ReactivePowerMinusManualReadingsMetersCount',
  'ReactivePowerMinusVirtualReadingsMetersCount',
  // Reactive plus
  'ReactivePowerPlusCumulativeReadingsMetersCount',
  'ReactivePowerPlusConstantReadingsMetersCount',
  'ReactivePowerPlusHourlyMetersCount',
  'ReactivePowerPlusManualReadingsMetersCount',
  'ReactivePowerPlusVirtualReadingsMetersCount',
  // Wood Chips
  'WoodChipsCumulativeReadingsMetersCount',
  'WoodChipsConstantReadingsMetersCount',
  'WoodChipsHourlyMetersCount',
  'WoodChipsManualReadingsMetersCount',
  'WoodChipsVirtualReadingsMetersCount'
];

const groupNameSortArray = [
  'FacilityInformation',
  'TechnicalSpecifications',
  'Properties',
  'Co2Factors',
  'EnergyCompany',
  'managerCompany',
  'facilityManagementCompany',
  'maintenanceCompany',
  'ownerCompany',
  'ExternalIds',
  'MainMeterCounts',
  'SubMeterCounts',
];

/**
 *  indexOf returns -1 when not found
 *  This function orders not found items to last
 *
 * @param {number} firstIndex
 * @param {number} secondIndex
 *
 * @return {number}
 */
function compareWithNotFoundToLast(firstIndex, secondIndex) {
  if (firstIndex < 0) {
    if (secondIndex < 0) {
      return 0;
    }
    return 1;
  }
  if (secondIndex < 0) {
    return -1;
  }
  if (firstIndex < secondIndex) {
    return -1;
  }
  if (firstIndex > secondIndex) {
    return 1;
  }
}

/**
 * sort array using ItemsSortingArray
 * usage: array.sort(compareItems)
 *
 * @param {number} first
 * @param {number} second
 *
 * @return {number}
 */
function compareItems(first, second) {
  const firstIndex = itemsSortingArray.indexOf(first.Property);
  const secondIndex = itemsSortingArray.indexOf(second.Property);
  return compareWithNotFoundToLast(firstIndex, secondIndex);
}

/**
 * sort array using groupNameSortArray
 * usage: array.sort(compareGroupName)
 *
 * @param {number} first    first index
 * @param {number} second   second index
 *
 * @return {number}
 */
function compareGroupName(first, second) {
  const firstIndex = groupNameSortArray.indexOf(first.Property);
  const secondIndex = groupNameSortArray.indexOf(second.Property);
  return compareWithNotFoundToLast(firstIndex, secondIndex);
}

/**
 * Sorts facilityGroups
 *
 * @param facilityGroups
 * @returns facilityGroups
 */
export const sortFacilityGroups = facilityGroups => {
  facilityGroups.Groups.sort(compareGroupName);
  facilityGroups.Groups.forEach(group => group.Items.sort(compareItems));
  return facilityGroups;
};
