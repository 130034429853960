import * as FilterType from './manual-qa-spreadsheet-filter-type.constant';

const $inject = ['utils'];

class ManualQaSpreadsheetFilterTypeSelectService {

  constructor(utils) {
    this.utils = utils;
  }

  getItems() {
    const self = this;

    return [
      {
        id: FilterType.HOUR,
        name: self.utils.localizedString('MQA.INSPECT.SHEET.HOURLY')
      },
      {
        id: FilterType.CUMULATIVE,
        name: self.utils.localizedString('MQA.INSPECT.SHEET.CUMULATIVE')
      },
      {
        id: FilterType.BOTH,
        name: self.utils.localizedString('MQA.INSPECT.SHEET.BOTH')
      }
    ];
  }
}

ManualQaSpreadsheetFilterTypeSelectService.$inject = $inject;

export default ManualQaSpreadsheetFilterTypeSelectService;
