import { ChangeDetectionStrategy, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin, map, Observable, shareReplay, Subject, take, takeUntil } from 'rxjs';

import { ICategory, IUpdateRowUnit, RowMetadata, RowUnitType }
  from '@enerkey/clients/sustainability';
import { ModalBase, ModalOptions, ModalService, NgfActiveModal } from '@enerkey/foundation-angular';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { GriReportService } from '../../services/gri-report.service';
import { getGriRowValuesFromMetadata, GriEditorRow } from '../../models/gri-report-row';
import { GriUnitEditorModalComponent } from '../gri-unit-editor-modal/gri-unit-editor-modal.component';
import { QuantitySortOptions } from '../gri-unit-combo/gri-unit-combo.component';

type RowEditForm = {
  description: FormControl<string>;
  categoryId: FormControl<number>;
  rowUnitId: FormControl<number>;
};

@Component({
  selector: 'gri-row-edit-modal',
  templateUrl: './gri-row-edit-modal.component.html',
  styleUrls: ['./gri-row-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  size: 'tiny',
  windowClass: 'modal-dialog-scrollable griRowEditModal'
})
export class GriRowEditModalComponent extends ModalBase implements OnInit, OnDestroy {

  public existingRow: GriEditorRow;
  public categoryId: number;
  public metadata: RowMetadata;
  public quantitySorter: QuantitySortOptions;

  public formGroup: FormGroup<RowEditForm>;

  public readonly categoriesById$: Observable<Record<number, ICategory>>;
  public readonly unitsById$: Observable<Record<number, IUpdateRowUnit>>;

  public readonly categoryOptions$: Observable<ComboItem<number>[]>;
  public readonly rowUnitTypes = [RowUnitType.Location, RowUnitType.Custom];

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly service: GriReportService,
    private readonly modalService: ModalService,
    private readonly injector: Injector
  ) {
    super(currentModal);

    this.categoriesById$ = this.service.categories$.pipe(
      map(categories => categories.toRecord(c => c.id)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.unitsById$ = this.service.units$.pipe(
      map(units => units.toRecord(c => c.id)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.categoryOptions$ = this.service.categoryOptions$;
  }

  public ngOnInit(): void {
    this.formGroup = new FormGroup<RowEditForm>({
      description: new FormControl<string>(this.existingRow?.description ?? '', Validators.required),
      rowUnitId: new FormControl<number>(this.existingRow?.unit?.id ?? null, Validators.required),
      categoryId: new FormControl<number>(
        this.existingRow?.category?.id ?? this.categoryId ?? null, Validators.required
      ),
    });

    /**
     * ---- Temporary fix ----
     * Will be update when Market based row units gets clear picture.
     */
    if (this.existingRow?.unit?.rowUnitType === RowUnitType.Facility) {
      this.rowUnitTypes.push(RowUnitType.Facility);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public addUnit(): void {
    const modal = this.modalService.open(GriUnitEditorModalComponent, { injector: this.injector });
    modal.result.then(
      result => {
        if (result) {
          this.formGroup.get('rowUnitId').setValue(result.id);
        }
        this.service.unitsChanged();
      },
      /* istanbul ignore next */() => { }
    );
  }

  public submit(): void {
    if (!this.formGroup.valid) {
      return;
    }

    forkJoin([
      this.categoriesById$.pipe(take(1)),
      this.unitsById$.pipe(take(1)),
    ]).subscribe({
      next: ([categoriesById, unitsById]) => {
        const value = this.formGroup.value;

        if (!this.existingRow) {
          const row = new GriEditorRow(
            {
              categoryId: value.categoryId,
              description: value.description,
              id: 0,
              rowUnitId: value.rowUnitId,
              values: getGriRowValuesFromMetadata(this.metadata),
              metadata: this.metadata ?? null,
            },
            categoriesById[value.categoryId],
            unitsById[value.rowUnitId]
          );

          this.service.addRow(row);
        } else {
          this.existingRow.description = value.description;
          this.service.rowChanged(this.existingRow, value.categoryId, value.rowUnitId);
        }
        super.closeModal();
      }
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

}
