import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Service, serviceNameTranslations } from '../../constants/service';

@Pipe({ name: 'serviceName' })
export class ServiceNamePipe implements PipeTransform {

  public constructor(
    private readonly translateService: TranslateService
  ) { }

  public transform(service: Service): string {
    if (service in serviceNameTranslations) {
      return this.translateService.instant(serviceNameTranslations[service]);
    }
  }
}
