import { Component, HostBinding, Input } from '@angular/core';

import { AccordionPanelComponent } from './accordion-group.component';
import { AccordionConfig } from './accordion.config';

/** Displays collapsible content panels for presenting information in a limited amount of space. */
@Component({
  selector: 'accordion',
  template: '<ng-content></ng-content>'
})
export class AccordionComponent {
  /** turn on/off animation */
  @Input() public isAnimated = false;
  /** if `true` expanding one item will close all others */
  @HostBinding('attr.data-multi-expand')
  @Input() public closeOthers: boolean;

  @HostBinding('attr.role') public role = 'tablist';
  @HostBinding('class.accordion') public accordionClass = true;
  @HostBinding('style.display') public display = 'block';

  protected groups: AccordionPanelComponent[] = [];

  public constructor(config: AccordionConfig) {
    Object.assign(this, config);
  }

  public closeOtherPanels(openGroup: AccordionPanelComponent): void {
    if (!this.closeOthers) {
      return;
    }

    this.groups.forEach((group: AccordionPanelComponent) => {
      if (group !== openGroup) {
        group.isOpen = false;
      }
    });
  }

  public addGroup(group: AccordionPanelComponent): void {
    group.isAnimated = this.isAnimated;
    this.groups.push(group);
  }

  public removeGroup(group: AccordionPanelComponent): void {
    const index = this.groups.indexOf(group);
    if (index !== -1) {
      this.groups.splice(index, 1);
    }
  }
}
