import {
  ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mergeWith, takeUntil, tap } from 'rxjs/operators';

import { MeterGroupDto } from '@enerkey/clients/metering';
import { StringKeys } from '@enerkey/ts-utils';

import { SelectComponent } from '../../../../../shared/select/select.component';
import { MeterGroup } from '../../../models/meter-groups.model';
import { MeterGroupsService } from '../../../services/meter-groups/meter-groups.service';

@Component({
  selector: 'meter-groups-dropdown-list',
  templateUrl: 'meter-groups-dropdown-list.component.html',
  styleUrls: ['meter-groups-dropdown-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MeterGroupsDropdownListComponent),
    multi: true
  }]
})
export class MeterGroupsDropdownListComponent extends SelectComponent<MeterGroup, 'id'> implements OnInit, OnDestroy {
  @Input() public defaultItemText: string;
  @Input() public showDefaultText: boolean = true;
  @Output() public readonly meterGroupChange = new EventEmitter<MeterGroup>();

  public override textField: StringKeys<MeterGroup> = 'name';
  public override valueField: 'id' = 'id' as const;
  public override defaultItem: MeterGroup;

  public meterGroups$: Observable<MeterGroupDto[]>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _meterGroups$ = new BehaviorSubject<MeterGroupDto[]>(null);

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private meterGroupsService: MeterGroupsService
  ) {
    super(changeDetectorRef);
    this.meterGroups$ = this._meterGroups$.asObservable();
  }

  public ngOnInit(): void {
    if (this.showDefaultText) {
      this.defaultItem = {
        id: undefined,
        name: this.defaultItemText ?? this.translateService.instant('ADMIN.METERGROUPS.SELECT_A_METER_GROUP'),
        quantityGroupId: undefined,
        description: ''
      };
    }

    this.meterGroups$.pipe(
      tap((meterGroups: MeterGroupDto[]) => {
        this.sourceData = meterGroups?.map((meterGroup: MeterGroupDto) => ({
          id: meterGroup.id,
          name: meterGroup.name,
          description: meterGroup.description,
          quantityGroupId: meterGroup.quantityGroupId
        })) ?? [];

        if (Number.isFinite(this.value)) { this.valueChanged(this.value); }

        this.changeDetectorRef.markForCheck();
      }),
      takeUntil(this._destroy$)
    ).subscribe();

    this.meterGroupsService.meterGroupCreate$.pipe(
      mergeWith(this.meterGroupsService.meterGroupUpdate$),
      tap((meterGroup: MeterGroupDto) => this.loadMeterGroups(meterGroup)),
      takeUntil(this._destroy$)
    ).subscribe();

    this.loadMeterGroups();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public override valueChanged(item: MeterGroup['id']): void {
    super.valueChanged(item);
    const meterGroup = this.sourceData.find((sourceItem: MeterGroup) => sourceItem.id === item);
    this.meterGroupChange.emit(meterGroup);
  }

  private loadMeterGroups(activeMeterGroup?: MeterGroupDto): void {
    this.meterGroupsService.getMeterGroups().pipe(
      tap((meterGroups: MeterGroupDto[]) => {
        if (Number.isFinite(activeMeterGroup?.id)) { this.value = activeMeterGroup.id; }
        this._meterGroups$.next(meterGroups);
      }),
      takeUntil(this._destroy$)
    ).subscribe();
  }
}
