/* eslint-disable @typescript-eslint/no-explicit-any */
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { AllHtmlEntities } from 'html-entities';

import {
  Bucket, BucketSearchOption, ConfigurationControlClient, ReaderType
} from '@enerkey/clients/configuration-control';

import { ToasterType } from '../../../constants/toaster-type';
import { Utils } from '../../../services/utils';
import ConfigurationControlModalService from '../services/configuration-control-modal-service';
import { AjsModalService } from '../../../services/modal/modal.service';

// eslint-disable-next-line import/order
import ModalConfigurationsController from './modal-configurations-controller';
// eslint-disable-next-line import/order
import ModalConfigurationsTemplate from 'raw-loader!../templates/modal-configurations.html';

const inject = [
  'ConfigurationControlClient', 'utils', 'OAuthService',
  'ConfigurationControlModalService', '$scope', 'modalService'
];

interface SearchOptionSelection {
  readonly id: BucketSearchOption;
  readonly name: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const HTMLEntities = new AllHtmlEntities();

const readersToNotSelect: string[] = [
  'EDI',
  'EDI CUM',
  'DataHub',
  'Danish DataHub'
];

class ConfigurationControlController {

  public gridOptions: any = {};
  public searchOptionSelections: SearchOptionSelection[] = [];
  public searchOption: SearchOptionSelection;
  public searchParams: string;
  public readerTypes: ReaderType[] = [];

  public readonly multiSelectOptions = {
    filter: 'contains',
    autoClose: false
  };

  private readerTypeSelections: any[] = [];

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private configurationControlClient: ConfigurationControlClient,
    private utils: Utils,
    private oauthService: OAuthService,
    private configurationControlModalService: ConfigurationControlModalService,
    private readonly $scope: ng.IScope,
    private readonly modalService: AjsModalService
  ) {
    this.searchOptionSelections = [
      {
        id: BucketSearchOption.EnegiaId,
        name: this.utils.localizedString('CONFIGURATION_CONTROL.SEARCH_OPTION_ENEGIA_ID')
      },
      {
        id: BucketSearchOption.MeterId,
        name: this.utils.localizedString('CONFIGURATION_CONTROL.SEARCH_OPTION_METER_ID')
      },
      {
        id: BucketSearchOption.TerminalIdentifier,
        name: this.utils.localizedString('CONFIGURATION_CONTROL.SEARCH_OPTION_TERMINAL_ID')
      },
      {
        id: BucketSearchOption.TerminalName,
        name: this.utils.localizedString('CONFIGURATION_CONTROL.SEARCH_OPTION_TERMINAL_NAME')
      },
      {
        id: BucketSearchOption.GroupIdentifier,
        name: this.utils.localizedString('CONFIGURATION_CONTROL.SEARCH_OPTION_GROUP_ID')
      },
      {
        id: BucketSearchOption.ExternalMeterId,
        name: this.utils.localizedString('CONFIGURATION_CONTROL.SEARCH_OPTION_EXTERNAL_ID')
      }
    ];
    this.initializeSearchOptions();
    this.initializeGrid();
    this.initializeData();
    this.$scope.$on('$destroy', () => {
      this._destroy$.next();
      this._destroy$.complete();
    });
  }

  public refreshGrid(): void {
    this.initializeSearchOptions();
    this.initializeGrid();
    this.initializeData();
  }

  public canSearch(): boolean {
    return this.searchParams.length > 0;
  }

  public search(): void {
    this.configurationControlClient.searchBuckets(this.searchOption.id, this.searchParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        response => {
          const selectedTypes = this.readerTypeSelections.map(r => r.id);
          const filtered = response.filter((bucket: Bucket) => selectedTypes.indexOf(bucket.readerType.id) !== -1);
          this.gridOptions.dataSource.data(filtered);
        },
        () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_DATA_FAIL')
      );
  }

  public addConfiguration(): void {
    const modalInstance = this.configurationControlModalService.addConfiguration();
    modalInstance.result.then((result: any) => {
      if (result?.saved) {
        this.getBuckets();
      }
    });
  }

  public openBucket(dataItem: Bucket): void {
    this.modalService.open({
      template: ModalConfigurationsTemplate,
      windowClass: 'semiFull',
      controller: ModalConfigurationsController,
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
        bucketId: () => dataItem.id,
        readerTypeId: () => dataItem.readerType.id,
        terminalName: () => '',
        readerTypeName: () => dataItem.readerType.name
      }
    });
  }

  public updateBucket(dataItem: Bucket): void {
    const modalInstance = this.configurationControlModalService
      .updateBucket(dataItem.readerType.name, dataItem);
    modalInstance.result.then(() => {
      this.getBuckets();
    });
  }

  public editBucketDocumentation(dataItem: Bucket): void {
    this.configurationControlModalService
      .getBucketDocumentationEditModal(dataItem.id, dataItem.groupIdentifier);
  }

  public getBuckets(): void {
    this.configurationControlClient.getBuckets(this.readerTypeSelections.map(r => r.id))
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        response => {
          this.gridOptions.dataSource.data(response);
        },
        () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_DATA_FAIL')
      );
  }

  public initializeBucketsAndReaderTypeSelections(): void {
    this.configurationControlClient.getBuckets(this.readerTypes.map(r => r.id))
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        response => {
          const tenantReaderTypeNames = response.unique(r => r.readerType.name).sort();
          this.readerTypes = this.readerTypes.filter(r => tenantReaderTypeNames.includes(r.name));
          this.readerTypeSelections = this.readerTypes.filter(r => !readersToNotSelect.includes(r.name));
          this.gridOptions.dataSource.data(response.filter(b => !readersToNotSelect.includes(b.readerType.name)));
        },
        () => this.showError('CONFIGURATION_CONTROL.ERROR_TEXT_GET_DATA_FAIL')
      );
  }

  private initializeData(): void {
    this.configurationControlClient.getReaderTypes()
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this.readerTypes = response.sortBy('name');
        this.initializeBucketsAndReaderTypeSelections();
      });
  }

  private initializeSearchOptions(): void {
    this.searchOption = this.searchOptionSelections[1];
    this.searchParams = '';
  }

  private initializeGrid(): void {
    const columns = [
      {
        field: 'id',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.ID'),
        width: 50,
        filterable: false,
        footerTemplate: this.utils.localizedString('CONFIGURATION_CONTROL.TOTAL'),
        locked: true
      },
      {
        field: 'groupIdentifier',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.GROUP_IDENTIFIER'),
        width: 500,
        filterable: {
          extra: false,
          operators: {
            string: {
              contains: this.utils.localizedString('GRID.CONTAINS'),
              startswith: this.utils.localizedString('GRID.STARTS_WITH'),
              isnull: this.utils.localizedString('GRID.ISNULL'),
              isnotnull: this.utils.localizedString('GRID.ISNOTNULL')
            }
          }
        },
        template: (data: Bucket) => {
          const groupIdentifier = HTMLEntities.encode(data.groupIdentifier);
          return `<button class="button button--link" ng-click="vm.openBucket(this.dataItem)">
<span ng-non-bindable>${groupIdentifier}</span></button>`;
        },
        locked: true
      },
      {
        field: 'readerType.name',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID_TITLE.TYPE'),
        width: 200,
        filterable: {
          extra: false,
          operators: {
            string: {
              contains: this.utils.localizedString('GRID.CONTAINS'),
              startswith: this.utils.localizedString('GRID.STARTS_WITH')
            }
          }
        },
        footerTemplate: '#= count #'
      },
      {
        field: '',
        width: 30,
        filterable: false,
        template: () =>
          `<button
            ng-click="vm.updateBucket(this.dataItem)"
            class="button button--no-padding button--link">
              <i class="fas fa-pencil-alt"></i>
          </button>`
      },
      {
        field: '',
        width: 30,
        filterable: false,
        template: () =>
          `<button
             ng-click="vm.editBucketDocumentation(this.dataItem)"
             class="button button--no-padding button--link">
               <i class="fas fa-info-circle"></i>
          </button>`,
      },
      {
        field: 'bucketStatus',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID_TITLE.BUCKETSTATUS'),
        width: 160,
        filterable: {
          extra: false,
          operators: {
            string: {
              contains: this.utils.localizedString('GRID.CONTAINS'),
              startswith: this.utils.localizedString('GRID.STARTS_WITH')
            }
          }
        },
        template: (data: any) => {
          let statusText = '';
          let cssIndicator = '';
          if (data.bucketStatus === 'Succeeded') {
            statusText = this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID.BUCKETSTATUS_SUCCESS');
            cssIndicator = 'success';
          } else if (data.bucketStatus === 'Failed') {
            statusText = this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID.BUCKETSTATUS_FAIL');
            cssIndicator = 'fail';
          } else {
            statusText = data.bucketStatus.toLowerCase();
            cssIndicator = 'nodata';
          }
          const classes = `indicator indicator--${cssIndicator}-text`;
          return `<div class="${classes}">${statusText}</div>`;
        }
      },
      {
        field: 'countOfFailedReadings',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID_TITLE.METERSTATUS'),
        width: 60,
        filterable: false,
        footerTemplate: '#= sum #'
      },
      {
        field: 'configurations',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID_TITLE.COUNT'),
        width: 60,
        filterable: false,
        footerTemplate: '#= sum #'
      },
      {
        field: 'successRate',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.SUCCESSRATE'),
        width: 80,
        template: '#= successRate !== null ? kendo.format("{0:p0}", successRate) : "-" #',
        footerTemplate: '#= kendo.format("{0:p0}", average) #'
      },
      {
        field: 'lastRun',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID_TITLE.LAST_RUN'),
        width: 120,
        filterable: false,
        template: (data: Bucket) => kendo.toString(
          kendo.parseDate(data.lastRun as unknown as string), 'dd.MM.yyyy HH:mm'
        ),
      },
      {
        field: 'cronExpression',
        title: this.utils.localizedString('CONFIGURATION_CONTROL.BUCKET_GRID_TITLE.CRON'),
        width: 100,
        filterable: false
      },
      {
        field: 'hangfireJobUrl',
        title: '',
        width: 35,
        filterable: false,
        template: (data: Bucket) => {
          const accessToken = this.oauthService.getAccessToken();
          const hangfireJobUrl = HTMLEntities.encode(data.hangfireJobUrl);
          const result = `<a href="${hangfireJobUrl}?bearer_token=${accessToken}" target="_blank">H</a>`;
          return result;
        }
      },
      {
        field: 'bucketContactId',
        hidden: true,
      }

    ];
    this.gridOptions = {
      sortable: true,
      resizable: true,
      groupable: true,
      pageable: false,
      columns: columns,
      filterable: true,
      dataSource: new kendo.data.DataSource({
        aggregate: [
          { field: 'readerType.name', aggregate: 'count' },
          { field: 'countOfFailedReadings', aggregate: 'sum' },
          { field: 'configurations', aggregate: 'sum' },
          { field: 'successRate', aggregate: 'average' }
        ],
        sort: { field: 'groupIdentifier', dir: 'asc' }
      })
    };
  }

  private showError(text: string): void {
    this.utils.popUp(ToasterType.ERROR, null, text, true);
  }
}

ConfigurationControlController.$inject = inject;

export default ConfigurationControlController;
