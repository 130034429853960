import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputsModule, SliderModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CompanySearchComponent } from './company-search/company-search.component';
import { FacilitySearchComponent } from './facility-search/facility-search.component';
import { IdInputComponent } from './id-input/id-input.component';
import { ProfileSearchComponent } from './profile-search/profile-search.component';
import { QuantityDropdownComponent } from './quantity-dropdown/quantity-dropdown.component';
import { EkComboComponent } from './ek-combo/ek-combo.component';
import { FacilityMultiSelectComponent } from './facility-multi-select/facility-multi-select.component';
import { EkMultiComponent } from './ek-multi/ek-multi.component';
import { EkComboItemTemplateDirective } from './ek-combo-item-template.directive';
import { QuantityIconComponent } from './quantity-icon/quantity-icon.component';
import { ComparabilitySelectComponent } from './comparability-select/comparability-select.component';
import { UnitSelectComponent } from './unit-select/unit-select.component';
import { ValueTypeSelectComponent } from './value-type-select/value-type-select.component';
import { IntegerInputDirective } from './integer-input.directive';
import { EkSliderNumberBoxComponent } from './ek-slider-number-box/ek-slider-number-box.component';
import { QuantityMultiSelectComponent } from './quantity-multi-select/quantity-multi-select.component';
import { MarkdownImageInputDirective } from './markdown-image-input.directive';
import { ProfileSearchSelectComponent } from './profile-search-select/profile-search-select.component';
import { ServiceMultiSelectComponent } from './service-multi-select/service-multi-select.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { CompanyDropdownComponent } from './company-dropdown/company-dropdown.component';
import { StatusSelectComponent } from './status-select/status-select.component';
import { ServiceSelectComponent } from './service-select/service-select.component';
import { ServiceTypeMultiSelectComponent } from './servicetype-multi-select/servicetype-multi-select.component';
import { ServiceLevelSelectComponent } from './service-level-select/service-level-select.component';
import { RoleMultiSelectComponent } from './role-multi-select/role-multi-select.component';
import { EkFilterableComboComponent } from './ek-filterable-combo/ek-filterable-combo.component';
import { DateperiodSelectComponent } from './dateperiod-select/dateperiod-select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputsModule,
    DropDownsModule,
    TranslateModule,
    SliderModule,
    ButtonsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CompanySearchComponent,
    FacilitySearchComponent,
    IdInputComponent,
    ProfileSearchComponent,
    QuantityDropdownComponent,
    EkComboComponent,
    FacilityMultiSelectComponent,
    EkMultiComponent,
    EkComboItemTemplateDirective,
    QuantityIconComponent,
    ComparabilitySelectComponent,
    UnitSelectComponent,
    ValueTypeSelectComponent,
    IntegerInputDirective,
    EkSliderNumberBoxComponent,
    QuantityMultiSelectComponent,
    MarkdownImageInputDirective,
    ProfileSearchSelectComponent,
    ServiceMultiSelectComponent,
    LanguageSelectComponent,
    CompanyDropdownComponent,
    StatusSelectComponent,
    ServiceSelectComponent,
    ServiceTypeMultiSelectComponent,
    ServiceLevelSelectComponent,
    RoleMultiSelectComponent,
    EkFilterableComboComponent,
    DateperiodSelectComponent,
  ],
  exports: [
    CompanyDropdownComponent,
    CompanySearchComponent,
    FacilitySearchComponent,
    IdInputComponent,
    ProfileSearchComponent,
    QuantityDropdownComponent,
    EkComboComponent,
    FacilityMultiSelectComponent,
    EkMultiComponent,
    EkComboItemTemplateDirective,
    QuantityIconComponent,
    ComparabilitySelectComponent,
    UnitSelectComponent,
    ValueTypeSelectComponent,
    IntegerInputDirective,
    EkSliderNumberBoxComponent,
    QuantityMultiSelectComponent,
    MarkdownImageInputDirective,
    ProfileSearchSelectComponent,
    ServiceMultiSelectComponent,
    LanguageSelectComponent,
    StatusSelectComponent,
    ServiceSelectComponent,
    ServiceTypeMultiSelectComponent,
    ServiceLevelSelectComponent,
    RoleMultiSelectComponent,
    EkFilterableComboComponent,
    DateperiodSelectComponent
  ],
})
export class EkInputsModule { }
