import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';

import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup,
  ValidationErrors, Validators
} from '@angular/forms';

import { formControlsFrom } from '@enerkey/ts-utils';

import { FacilityLatestAlarmsWidgetOptions } from '../facility-latest-alarms-widget/facility-latest-alarms-widget.component';

type ChangeFn = (options: FacilityLatestAlarmsWidgetOptions) => void;

@Component({
  selector: 'facility-latest-alarms-widget-options',
  templateUrl: './facility-latest-alarms-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FacilityLatestAlarmsWidgetOptionsComponent,
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => FacilityLatestAlarmsWidgetOptionsComponent)
  }]
})
export class FacilityLatestAlarmsWidgetOptionsComponent implements OnInit, ControlValueAccessor {
  @Input() public initialState: FacilityLatestAlarmsWidgetOptions;

  public readonly minCount: number = 5;
  public readonly maxCount: number = 200;

  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public ngOnInit(): void {
    const values = this.optionsToForm(this.initialState);
    const controls = formControlsFrom(
      values,
      Object.keys(values).toRecord(key => key, () => Validators.required)
    );
    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges.subscribe((formValue: FacilityLatestAlarmsWidgetOptions) => {
      this._onChange?.(formValue);
    });
  }

  public registerOnTouched(): void { }

  public writeValue(value: FacilityLatestAlarmsWidgetOptions): void {
    this.formGroup.setValue(
      this.optionsToForm(value)
    );
  }
  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }
  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.formGroup.invalid) {
      return { required: true };
    } return null;
  }

  private optionsToForm(options: FacilityLatestAlarmsWidgetOptions): FacilityLatestAlarmsWidgetOptions {
    return { ...options };
  }
}
