import { Transition } from '@uirouter/angular';

import { Service } from '../../constants/service';
import { UserService } from '../../services/user-service';
import { EnerkeyNg2StateDeclaration, UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';
import { SustainabilityBaseComponent } from './components/sustainability-base/sustainability-base.component';
import { SustainabilityComponent } from './components/sustainability/sustainability.component';
import { TargetsGridComponent } from './components/targets-grid/targets-grid.component';

type SustainabilityRootState = 'sustainability';
type SustainabilityChildState = 'grieditor' | 'targets';

type SustainabilityStateName = SustainabilityRootState | `${SustainabilityRootState}.${SustainabilityChildState}`;

export const sustainabilityTopbarState: UIStateNg2<SustainabilityRootState> = {
  name: 'sustainability',
  data: {
    auth: {
      services: [Service.Sustainability],
      roles: [Roles.SUSTAINABILITY_MANAGER]
    },
    translationKey: 'SUSTAINABILITY.TITLE'
  }
};

const SUSTAINABILITY_ROOT: EnerkeyNg2StateDeclaration = {
  ...sustainabilityTopbarState,
  parent: 'index',
  url: '/sustainability',
  redirectTo: findApplicableState,
  views: { 'app@': { component: SustainabilityBaseComponent } },
};

const SUSTAINABILITY_CHILDREN: EnerkeyNg2StateDeclaration<SustainabilityStateName>[] = [
  {
    name: 'sustainability.grieditor',
    url: '/grieditor',
    views: { sustainability: { component: SustainabilityComponent } },
    data: {
      translationKey: 'SUSTAINABILITY.GRI.TITLE',
    },
  },
  {
    name: 'sustainability.targets',
    url: '/targets',
    views: { sustainability: { component: TargetsGridComponent } },
    data: {
      translationKey: 'SUSTAINABILITY.TARGETS.TITLE',
    },
  },
];

function findApplicableState(transition: Transition): SustainabilityStateName {
  const svc: UserService = transition.injector().get('UserService');
  const foundState = SUSTAINABILITY_CHILDREN.find(state => !state.data.auth || svc.hasAccess(state.data.auth));
  return foundState?.name ?? 'sustainability.grieditor';
}

export const SUSTAINABILITY_STATES = [SUSTAINABILITY_ROOT, ...SUSTAINABILITY_CHILDREN];
