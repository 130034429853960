import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SynchronizationResult } from '@enerkey/clients/provisioning';

@Component({
  selector: 'operation-result',
  templateUrl: './operation-result.component.html',
  styleUrls: ['./operation-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperationResultComponent {
  @Input() public status: SynchronizationResult;
}
