import ModalTemplate from 'raw-loader!../components/meter-interface-status/meter-interface-status-modal.html';
import ModalController from '../components/meter-interface-status/meter-interface-status-modal-controller';
import { ReadingStatus } from '@enerkey/clients/configuration-control';
import { readingStatusIndicators, readingStatusTranslations } from '../constants/reading-status-constants';

const $inject = ['$modal', 'utils'];

/* eslint-disable @typescript-eslint/no-explicit-any */

export class MeterInterfaceService {
  public constructor(
    private $modal: any,
    private utils: any
  ) {
  }

  public getModal(facilityName: string, meterId: number, meteringPointName: string): any {
  /* eslint-enable @typescript-eslint/no-explicit-any */
    return this.$modal.open({
      template: ModalTemplate,
      controller: ModalController,
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      windowClass: 'small',
      resolve: {
        facilityName: function() {
          return facilityName;
        },
        meterId: function() {
          return meterId;
        },
        meteringPointName: function() {
          return meteringPointName;
        }
      }
    });
  }

  public getReadingStatusText(status: ReadingStatus): string {
    return status in readingStatusTranslations
      ? this.utils.localizedString(readingStatusTranslations[status])
      : '?';
  }

  public getReadingStatusIndicator(status: ReadingStatus): string {
    return status in readingStatusIndicators
      ? readingStatusIndicators[status]
      : '';
  }

  public isFailStatus(statusCode: ReadingStatus): boolean {
    return (statusCode !== ReadingStatus.Success && statusCode !== ReadingStatus.NotRun
            && statusCode !== ReadingStatus.ManuallyTriggered);
  }
}

MeterInterfaceService.$inject = $inject;
