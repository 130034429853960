import EmActionEditController from './em-action-edit-controller';
import emActionEditTemplate from 'raw-loader!./em-action-edit.html';

export default {
  template: emActionEditTemplate,
  controller: EmActionEditController,
  controllerAs: 'vm',
  bindings: {
    action: '<',
    hasCategoryData: '<',
    modalDismiss: '<',
    modalClose: '<',
  }
};
