enum CronValueType {
  Minute,
  Hour,
  Day,
  Month,
  Weekday
}

const cronRegex = getCronRegex();

const cronValueTypeOrder: Record<number, CronValueType> = {
  0: CronValueType.Minute,
  1: CronValueType.Hour,
  2: CronValueType.Day,
  3: CronValueType.Month,
  4: CronValueType.Weekday
};

export function isValidCron(cron: string): boolean {
  if (cron) {
    const trimmedCron = cron.trim();
    if (trimmedCron) {
      return cronRegex.test(trimmedCron) && validateCronValues(trimmedCron);
    }
  }
  return false;
}

function getCronRegex(): RegExp {
  const cronValuePart = '(\\*|\\d{1,2}(-\\d{1,2})?)(/\\d+)?';
  const cronValue = `${cronValuePart}(,${cronValuePart})*`;
  return new RegExp(`^(${cronValue} ){4}${cronValue}$`);
}

function validateCronValues(cron: string): boolean {
  return cron.split(' ').every((cronValueList, index) => {
    const cronValueType = cronValueTypeOrder[index];
    return cronValueList.split(',').every(cronFullValue => {
      const dividerIndex = cronFullValue.indexOf('/');
      const cronRange = dividerIndex > 0 ? cronFullValue.substr(0, dividerIndex) : cronFullValue;
      const [cronStart, cronEnd] = cronRange.split('-').map(value => value === '*' ? '*' : Number(value));
      const startIsValid = cronStart === '*' || validateCronValue(cronStart, cronValueType);
      let endIsValid = true;
      if (typeof cronEnd === 'number' && Number.isFinite(cronEnd)) {
        endIsValid = +cronStart < cronEnd && validateCronValue(cronEnd, cronValueType);
      }
      return startIsValid && endIsValid;
    });
  });
}

function validateCronValue(value: number, cronValueType: CronValueType): boolean {
  switch (cronValueType) {
    case CronValueType.Minute:
      return inRange(value, 0, 59);
    case CronValueType.Hour:
      return inRange(value, 0, 23);
    case CronValueType.Day:
      return inRange(value, 1, 31);
    case CronValueType.Month:
      return inRange(value, 1, 12);
    case CronValueType.Weekday:
      return inRange(value, 0, 6);
    default:
      return false;
  }
}

function inRange(value: number, min: number, max: number): boolean {
  return Number.isFinite(value) && value >= min && value <= max;
}
