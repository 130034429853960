import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { WidgetBase } from '../../shared/widget-base.interface';
import { FacilityService } from '../../../../shared/services/facility.service';
import { EmissionsWidgetOptions, EmissionsWidgetService } from '../../services/emissions-widget.service';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';
import { EmissionsWidgetData } from '../../shared/emissions';
import { ThresholdService } from '../../../../shared/services/threshold.service';

@Component({
  selector: 'emissions-widget',
  templateUrl: './emissions-widget.component.html',
  styleUrls: ['./emissions-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmissionsWidgetService]
})
export class EmissionsWidgetComponent implements OnDestroy, OnInit, WidgetBase<EmissionsWidgetOptions> {
  public dataModelOptions: EmissionsWidgetOptions;
  public readonly dataModelChange$ = EMPTY;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;

  public readonly data$: Observable<EmissionsWidgetData>;
  public readonly emissionTitle$: Observable<string>;
  public readonly relationalValueId$: Observable<RelationalValueId>;
  public readonly years$: Observable<{ current: string; previous: string; first: string }>;
  public readonly isNormalized$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly emissionsWidgetService: EmissionsWidgetService,
    thresholdService: ThresholdService,
    facilityService: FacilityService
  ) {
    this.emissionTitle$ = this.emissionsWidgetService.emissionTitle$;
    this.relationalValueId$ = this.emissionsWidgetService.relationalValueId$;
    this.years$ = this.emissionsWidgetService.years$;
    this.isNormalized$ = this.emissionsWidgetService.isNormalized$;
    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();
    this.data$ = combineLatest([
      facilityService.filteredProfileFacilityIds$,
      thresholdService.threshold$
    ]).pipe(
      switchMap(([facilityIds, threshold]) => this.emissionsWidgetService.getEmissionsData(
        facilityIds, threshold
      ).pipe(
        indicate(this._loading$),
        catchError(() => {
          this._error$.next();
          return EMPTY;
        })
      )),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this._destroy$)
    );
  }

  public ngOnInit(): void {
    this.emissionsWidgetService.setDataModelOptions(this.dataModelOptions);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._error$.complete();
    this._loading$.complete();
  }
}
