import * as fileSaver from 'file-saver';
import moment from 'moment';
import _ from 'lodash';
import { TransitionService } from '@uirouter/core';
import { combineLatest, firstValueFrom, MonoTypeOperatorFunction, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { forkThrottle, ObservablePool } from '@enerkey/rxjs';
import { $InjectArgs } from '@enerkey/ts-utils';
import {
  ConsumptionsGroupedResponse,
  ConsumptionsRequest,
  EnergyReportingClient,
  SwaggerException,
  TimeSerieCollection,
  TopConsumptionResponse,
} from '@enerkey/clients/energy-reporting';

import { HttpConfigService } from '../../../services/http-config.service';
import { ThresholdService } from '../../../shared/services/threshold.service';
import { FacilityService } from '../../../shared/services/facility.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/** Split request into multiple clones, with `action` callback invoked on all of them. */
function _getRequestParts(
  request: ConsumptionsRequest,
  count: number,
  action: (req: ConsumptionsRequest) => void
): ConsumptionsRequest[] {
  return Array.from({ length: count }).map(() => {
    const cloned = _.cloneDeep<ConsumptionsRequest>(request);
    action(cloned);
    return cloned;
  });
}

function _splitByFacility(request: ConsumptionsRequest, count: number): ConsumptionsRequest[] {
  const facilityIds = [...request.FacilityId];
  const requestParts = _getRequestParts(request, count, r => (r.FacilityId = []));

  let increment = 0;

  for (const facilityId of facilityIds) {
    requestParts[increment % requestParts.length].FacilityId.push(facilityId);
    increment++;
  }

  return requestParts.filter(r => r.FacilityId.length);
}

function _splitByQuantities(request: ConsumptionsRequest, count: number): ConsumptionsRequest[] {
  const quantities = _.cloneDeep(request.Quantities);
  const requestParts = _getRequestParts(request, count, r => (r.Quantities = []));

  let increment = 0;

  for (const quantity of quantities) {
    requestParts[increment % requestParts.length].Quantities.push(quantity);
    increment++;
  }

  return requestParts.filter(r => r.Quantities.length);
}

function _getFacilitySplitCount(request: ConsumptionsRequest): number {
  const baseline = request.FacilityId.length;
  const startCount = request.Start.length;
  const quantityWeight = _weighQuantities(request.Quantities);

  const ratio = (baseline * startCount * quantityWeight) / LegacyConsumptionsService.splitThreshold;

  return ratio <= 1.0 ? 1 : Math.ceil(ratio);
}

// shut up linter
_splitByFacility || _splitByFacility;
_splitByQuantities || _splitByQuantities;
_getFacilitySplitCount || _getFacilitySplitCount;

function _weighQuantities(quantities: ConsumptionsRequest['Quantities']): number {
  return quantities.map(item => (item.Id ?? (item as any).ID) >= 1000 ? 5 : 1).reduce((a, b) => a + b, 0);
}

type TimeSerieResult = { [key: string]: TimeSerieCollection };

export class LegacyConsumptionsService {

  public static readonly throttleThreshold = 1_000 as const;

  /** Quantities * facilities * series to split on */
  public static readonly splitThreshold = 10_000 as const;

  public static $inject: $InjectArgs<typeof LegacyConsumptionsService> = [
    '$q',
    '$http',
    'httpConfigService',
    'thresholdService',
    'EnergyReportingClient',
    'FacilityService',
    '$transitions',
  ];

  private readonly _pool = new ObservablePool(1);
  private readonly _cancel = new Subject<void>();
  private readonly _unhook: Function;

  public constructor(
    private readonly $q: ng.IQService,
    private readonly $http: ng.IHttpService,
    private readonly httpConfigService: HttpConfigService,
    private readonly thresholdService: ThresholdService,
    private readonly erClient: EnergyReportingClient,
    private readonly facilityService: FacilityService,
    $transitions: TransitionService
  ) {
    this._unhook = $transitions.onSuccess({ exiting: 'facilities.**' }, () => this._cancel.next());
  }

  /* istanbul ignore next */
  public $onDestroy(): void {
    this._cancel.next();
    this._cancel.complete();
    this._pool.destroy();
    this._unhook();
  }

  public getAggregateConsumptions(payload: ConsumptionsRequest, type?: string | number): ng.IPromise<TimeSerieResult> {
    if (!Array.hasItems(payload.Quantities)) {
      return Promise.resolve({});
    }

    // Keep this here for backward compatibility
    payload.AggregateType = payload.AggregateType || (type as any);

    return firstValueFrom(this.getThreshold().pipe(
      switchMap(t => {
        payload.ThresholdForIncomplete = t;
        return this.erClient.postQuantitys(payload);
      }),
      this.throttle()
    ));
  }

  public getConsumptionsForFacilities(payload: ConsumptionsRequest): ng.IPromise<TimeSerieResult> {
    return firstValueFrom(this.getThreshold().pipe(
      switchMap(t => {
        payload.ThresholdForIncomplete = t;
        return this.erClient.postFacilityConsumptions(payload);
      }),
      this.throttle()
    ));
  }

  public getConsumptionsForMeters(payload: ConsumptionsRequest): ng.IPromise<TimeSerieResult> {
    return firstValueFrom(this.getThreshold().pipe(
      switchMap(t => {
        payload.ThresholdForIncomplete = t;
        return this.erClient.postMeterConsumptions(payload);
      }),
      this.throttle()
    ));
  }

  /** Not in use anymore */
  /* istanbul ignore next */
  public getTopConsumption(payload: ConsumptionsRequest): ng.IPromise<TopConsumptionResponse> {
    return firstValueFrom(this.getThreshold().pipe(
      switchMap(t => {
        payload.ThresholdForIncomplete = t;
        return this.erClient.postTopConsumptions(payload);
      }),
      this.throttle()
    ));
  }

  /** Not in use anymore */
  /* istanbul ignore next */
  public getGroupedConsumptions(payload: ConsumptionsRequest): ng.IPromise<ConsumptionsGroupedResponse> {
    return firstValueFrom(this.getThreshold().pipe(
      switchMap(t => {
        payload.ThresholdForIncomplete = t;
        return this.erClient.consumptionsGroupedResponse(payload);
      }),
      this.throttle()
    ));
  }

  /* istanbul ignore next */
  public async createExcelExport(payload: any, filename: string): Promise<unknown> {
    const threshold = await this.getThresholdPromise();
    return this.getExcel(
      '/api/v1/energyreporting/reportingobjects/consumptions/excel',
      filename,
      this.getExtendedPayload(payload, threshold)
    );
  }

  /* istanbul ignore next */
  public async createMetersExcelExport(payload: any, filename: string): Promise<unknown> {
    const threshold = await this.getThresholdPromise();
    return this.getExcel(
      '/api/v1/energyreporting/reportingobject/meterconsumptions/excel',
      filename,
      this.getExtendedPayload(payload, threshold)
    );
  }

  protected getConsumptions<T extends TimeSerieResult>(
    payload: ConsumptionsRequest,
    callback: (req: ConsumptionsRequest) => Observable<T>,
    countfn: (req: ConsumptionsRequest) => number,
    splitfn: (req: ConsumptionsRequest, count: number) => ConsumptionsRequest[]
  ): Observable<T> {
    return combineLatest([
      this.getThreshold(),
      this.getFacilityIds$(payload),
    ]).pipe(
      take(1),
      switchMap(([threshold, facilityIds]) => {
        payload.FacilityId = facilityIds;
        payload.ThresholdForIncomplete = threshold;

        const count = countfn(payload);
        const requests = count !== 1 ? splitfn(payload, count) : [payload];

        const apicalls = requests.map(req => callback(req).pipe(this.throttle()));
        return forkThrottle(apicalls, 1);
      }),
      map(results => results.reduce((a, b) => ({ ...a, ...b }))),
      takeUntil(this._cancel)
    );
  }

  // This can be removed if backend stupidity is fixed
  /* istanbul ignore next */
  protected catchSwaggerEx<T, V extends T>(defaultParam: V): MonoTypeOperatorFunction<T> {
    return source => source.pipe(catchError(err => {
      if (SwaggerException.isSwaggerException(err) && err.response === 'No meters found.') {
        return of(defaultParam);
      }
      return throwError(err);
    }));
  }

  private throttle<T>(): MonoTypeOperatorFunction<T> {
    return source => this.facilityService.filteredProfileFacilityIds$.pipe(
      take(1),
      switchMap(facilities => {
        if (facilities.length >= LegacyConsumptionsService.throttleThreshold) {
          return this._pool.queue(source);
        }
        return source;
      }),
      takeUntil(this._cancel)
    );
  }

  private getFacilityIds$(request: ConsumptionsRequest): Observable<number[]> {
    return request.FacilityId?.length
      ? of(request.FacilityId)
      : this.facilityService.filteredProfileFacilityIds$.pipe(take(1));
  }

  private getThreshold(): Observable<number> {
    return this.thresholdService.threshold$.pipe(take(1));
  }

  //#region Legacy

  /* istanbul ignore next */
  private getThresholdPromise(): ng.IPromise<number> {
    return firstValueFrom(this.getThreshold());
  }

  /* istanbul ignore next */
  private getExtendedPayload(
    payload: ConsumptionsRequest,
    threshold: number
  ): ConsumptionsRequest {
    if (_.isObject(payload)) {
      payload.ThresholdForIncomplete = threshold;
    }
    return payload;
  }

  /* istanbul ignore next */
  private getExcel(
    url: string,
    filename: string,
    payload: any
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const params = this.httpConfigService.getExtendedHttpConfig();

    params.responseType = 'arraybuffer';
    params.timeout = 50000;

    this.$http.post(ENERKEY_CONFIG.apiEnergyreporting + url, payload, params)
      .then(({ data }) => {
        this.downloadExcel(data, filename);
        deferred.resolve(data);
      })
      .catch(({ status }) => {
        deferred.reject(status);
      });

    return deferred.promise;
  }

  /* istanbul ignore next */
  private downloadExcel(data: any, filename: string): void {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    filename = `${filename} ${moment().format('L')}.xlsx`;

    fileSaver.saveAs(blob, filename);
  }

  //#endregion

}
