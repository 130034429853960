import * as FetchType from './manual-qa-fetch-type.constant';

const $inject = ['utils'];

class ManualQaFetchTypeService {
  constructor(utils) {
    this.utils = utils;
  }

  getItems() {
    return [
      {
        id: FetchType.HOUR,
        name: this.utils.localizedString('MQA.INSPECT.SHEET.HOURLY')
      },
      {
        id: FetchType.CUMULATIVE,
        name: this.utils.localizedString('MQA.INSPECT.SHEET.CUMULATIVE')
      },
      {
        id: FetchType.BOTH,
        name: this.utils.localizedString('MQA.INSPECT.SHEET.BOTH')
      }
    ];
  }
}

ManualQaFetchTypeService.$inject = $inject;

export default ManualQaFetchTypeService;
