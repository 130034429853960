<modal-view-header [headingText]="readerTypeName"></modal-view-header>
<ng-container>
  <div>
    <form
      [formGroup]="bucketForm"
      class="small-6 columns"
    >
      <input
        kendoTextBox
        type="text"
        *labelBefore="'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.GROUP_IDENTIFIER' | translate"
        formControlName="groupIdentifier"
      />
      <input
        kendoCheckBox
        type="checkbox"
        *labelBefore="'CONFIGURATION_CONTROL.ACTIVE' | translate"
        formControlName="isActive"
      />
      <input
        kendoTextBox
        type="text"
        *labelBefore="'CONFIGURATION_CONTROL.TERMINAL.TERMINAL_ERROR_COUNT' | translate"
        formControlName="terminalErrorExecutionCount"
      />
      <input
        kendoTextBox
        type="text"
        *labelBefore="'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.CRON' | translate"
        formControlName="cronExpression"
      />
    </form>
    <form
      [formGroup]="contactForm"
      class="small-6 columns"
    >
      <input
        kendoTextBox
        type="text"
        *labelBefore="'CONFIGURATION_CONTROL.FIRSTNAME' | translate"
        formControlName="firstName"
      />
      <input
        kendoTextBox
        type="text"
        *labelBefore="'CONFIGURATION_CONTROL.LASTNAME' | translate"
        formControlName="lastName"
      />
      <input
        kendoTextBox
        type="email"
        *labelBefore="'CONFIGURATION_CONTROL.EMAIL' | translate"
        formControlName="email"
      />
      <input
        kendoTextBox
        type="tel"
        *labelBefore="'CONFIGURATION_CONTROL.PHONE_NUMBER' | translate"
        formControlName="phoneNumber"
      />
      <input
        kendoTextBox
        type="email"
        *labelBefore="'CONFIGURATION_CONTROL.MISSING_READING_EMAIL' | translate"
        formControlName="emailForMissingReading"
      />
    </form>
  </div>
</ng-container>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      (click)="save()"
      [disabled]="!bucketForm.valid || !contactForm.valid"
      type="button"
      class="button"
      translate="CONFIGURATION_CONTROL.SAVE"
    ></button>
    <button
      (click)="cancel()"
      type="button"
      class="button button--secondary"
      translate="CONFIGURATION_CONTROL.CANCEL"
    ></button>
  </div>
</div>
