import _ from 'lodash';
import { map } from 'rxjs/operators';
import { forkJoin, Observable, throwError } from 'rxjs';

import * as Configs from '../constants/configs';
import { FacilitySearchType } from '../constants/facility-search-type';
import { ViewSettings } from '../constants/view-settings';
import { getTimeFrameQueryParameters } from '../shared/utils.functions';
import {
  Errors,
  InspectionDefectItem,
  InspectionDefectItems,
  InspectionItem,
  InspectionItems,
  InspectionStatisticsItems,
  MqaClient,
  Statistics
} from '@enerkey/clients/manual-qa';
import { SearchCriteria } from '../shared/search-criteria';
import { assertUnreachable } from '@enerkey/ts-utils';
import { SearchItemType } from '../constants/search-item-type';
import { MeterSearchType } from '../constants/meter-search-type';
import { NotImplementedError } from '../shared/not-implemented-error';
import SearchTypes from '../constants/search-types';

type InspectionItemsResponse = Observable<{
  errors: Errors;
  items: InspectionItem[] | InspectionDefectItem[];
}>;

/**
 * Service that handles all manual qa server calls.
 *
 * @returns {Object}
 *
 * @constructor
 */
export class ManualQaApiSearchService {
  public static readonly $inject = ['UserService', 'mqaClient']

  public constructor(
    private userService: any,
    private mqaClient: MqaClient
  ) {
  }

  /**
   * Returns metering points using given parameters
   *
   * @param {SearchCriteria} searchCriteria
   * @param {boolean}  statistics
   *
   * @returns {Object}
   */
  public getInspectionItems(
    searchCriteria: SearchCriteria,
    readerTypes: any[] = []
  ): InspectionItemsResponse {
    return searchCriteria.isDefectSearch
      ? this.getDefectInspectionItems(searchCriteria, readerTypes)
      : this.getMeterOrStatisticsInspectionItems(searchCriteria, readerTypes)
    ;
  }

  private getMeterOrStatisticsInspectionItems(
    searchCriteria: SearchCriteria,
    readerTypes: any[]
  ): Observable<{ errors: Errors; items: InspectionItem[] | InspectionDefectItem[] }> {
    const params = this.getSearchParams(searchCriteria);
    const request = this.getMeterOrStatisticsRequest(searchCriteria);
    return forkJoin([
      request.call(this.mqaClient, params),
      this.mqaClient.getQuantities()
    ]).pipe(
      map(([inspectionItems, quantities]) => {
        this.addReaderAndQuantityNames(inspectionItems, quantities, readerTypes);
        if (searchCriteria.searchItemType === SearchItemType.Defects) {
          (inspectionItems.itemsFound as InspectionItem[]).forEach(item => {
            item.defectTypeIdsString = item.defectTypes
              ? item.defectTypes.map(defectType => defectType.value).join(',')
              : ''
            ;
          });
        }
        return {
          errors: inspectionItems.errors,
          items: inspectionItems.itemsFound,
        };
      })
    );
  }

  private getDefectInspectionItems(
    searchCriteria: SearchCriteria,
    readerTypes: any[]
  ): Observable<{ errors: Errors; items: InspectionDefectItem[] }> {
    if (searchCriteria.searchType !== SearchTypes.STORAGE_TYPE.DEFECT) {
      return throwError(new NotImplementedError('MQA.ERRORS.CANNOT_SEARCH_DEFECTS_FROM_RAW_STORAGE'));
    }
    const params = this.getSearchParams(searchCriteria);
    const request = this.getDefectSearchEndpoint(searchCriteria);
    return forkJoin([
      request.call(this.mqaClient, params),
      this.mqaClient.getQuantities()
    ]).pipe(
      map(([inspectionItems, quantities]) => {
        this.addReaderAndQuantityNames(inspectionItems, quantities, readerTypes);
        inspectionItems.itemsFound.forEach(item => {
          item.duration = item.from && item.to
            ? Math.floor((item.to.getTime() - item.from.getTime()) / 3600000)
            : null
          ;
          item.defectTypeIdsString = item.faultTypes
            ? item.faultTypes.join(',')
            : '';
        });
        return {
          errors: inspectionItems.errors,
          items: inspectionItems.itemsFound,
        };
      })
    );
  }

  private addReaderAndQuantityNames(
    items: InspectionItems | InspectionDefectItems,
    quantities: { id: number; name: string }[],
    readerTypes: any[]
  ): void {
    items.itemsFound.forEach((item: InspectionItem | InspectionDefectItem) => {
      (item as InspectionItem).statistics = (item as InspectionItem).statistics || new Statistics();
      const quantity = quantities.find(q => q.id === item.quantityId);
      item.quantityName = quantity.name;
      const readerType = readerTypes.find(r => r.id === item.readerTypeId);
      const meterReaderTypeName = readerType ? readerType.name : '';
      item.readerTypeName = meterReaderTypeName;
    });
  }

  private getMeterOrStatisticsRequest(
    searchCriteria: SearchCriteria
  ): (params: any) => Observable<InspectionItems | InspectionDefectItems> {
    switch (searchCriteria.searchItemType) {
      case SearchItemType.Meters:
        return this.getMeterSeachEndpoint(searchCriteria);
      case SearchItemType.Statistics:
        return this.getStatisticsSeachEndpoint(searchCriteria);
    }
  }

  /**
   * Returns parameters for inspection items request
   */
  private getSearchParams(searchCriteria: SearchCriteria): any {
    const parameters = searchCriteria.getRequestParameters();

    let data: any = {};

    const defaultParameters = {
      searchType: parameters.searchType,
      ...getTimeFrameQueryParameters(parameters.timeFrame),
      faultTypeIds: parameters.faultTypeIds,
      qualityAssuranceFilterType: parameters.qualityAssuranceFilterType,
    };

    switch (searchCriteria.getViewSetting()) {
      case ViewSettings.FACILITIES: {
        const facilitySearchType = searchCriteria.getFacilitySearchType();
        switch (facilitySearchType) {
          case FacilitySearchType.EnegiaIds:
            data = {
              enegiaIds: parameters.enegiaIds
            };
            break;
          case FacilitySearchType.Profile:
          case FacilitySearchType.UseCurrentProfile:
            if (facilitySearchType === FacilitySearchType.UseCurrentProfile) {
              parameters.profileIds = _.uniq(this.addCurrentProfileIdToRequestParameters(parameters.profileIds));
            }
            data = {
              profileIds: parameters.profileIds
            };
            break;
          case FacilitySearchType.FacilityName: {
            data = {
              facilityNameSearchString: parameters.facilityName
            };
            break;
          }
          case FacilitySearchType.Bucket:
            data = {
              bucketIds: parameters.bucketIds
            };
            break;
          case FacilitySearchType.ReaderType:
            data = {
              readerTypeIds: parameters.readerTypes
            };
            break;
          default:
            assertUnreachable(facilitySearchType);
        }
        data.readingSourceTypeIds = parameters.readerTypes;
        data.quantityTypeIds = parameters.quantities;
        data.meteringTypes = parameters.meteringTypes;
        data.meterHierarchyStatusType = parameters.meterHierarchyStatusType;
        break;
      }
      case ViewSettings.METERING_POINTS: {
        const meterSearchType = searchCriteria.getMeterSearchType();
        switch (meterSearchType) {
          case MeterSearchType.MeterIds: {
            data = {
              measurementPointIds: parameters.meterIds
            };
            break;
          }
          case MeterSearchType.MeterName: {
            data = {
              measurementPointNameSearchString: parameters.meterName
            };
            break;
          }
        }
        break;
      }
    }

    return { ...defaultParameters, ...data };
  }

  private addCurrentProfileIdToRequestParameters(profiles: any[]): any[] {
    const currentProfileIndex = profiles.indexOf(Configs.CURRENT_PROFILE_SELECTION);
    if (currentProfileIndex >= 0) {
      profiles[currentProfileIndex] = this.userService.getCurrentProfile().reportingObjectSetId;
    }
    return profiles;
  }

  private getStatisticsSeachEndpoint(
    searchCriteria: SearchCriteria
  ): (...params: any) => Observable<InspectionStatisticsItems> {
    switch (searchCriteria.getViewSetting()) {
      case ViewSettings.FACILITIES: {
        const facilitySearchType = searchCriteria.getFacilitySearchType();
        switch (facilitySearchType) {
          case FacilitySearchType.EnegiaIds:
            return this.mqaClient.getInspectionItemStatisticsForEnegiaIds;
          case FacilitySearchType.Profile:
          case FacilitySearchType.UseCurrentProfile:
            return this.mqaClient.getInspectionItemStatisticsForProfileIds;
          case FacilitySearchType.FacilityName:
          case FacilitySearchType.Bucket:
          case FacilitySearchType.ReaderType:
            return this.statisticsSearchNotImplementedError();
          default:
            assertUnreachable(facilitySearchType);
        }
        break;
      }
      case ViewSettings.METERING_POINTS: {
        const meterSearchType = searchCriteria.getMeterSearchType();
        switch (meterSearchType) {
          case MeterSearchType.MeterIds:
            return this.mqaClient.getInspectionItemStatisticsForMeterIds;
          case MeterSearchType.MeterName:
            return this.statisticsSearchNotImplementedError();
          default:
            assertUnreachable(meterSearchType);
        }
        break;
      }
    }
  }

  private statisticsSearchNotImplementedError(): () => Observable<any> {
    return () => throwError(new NotImplementedError('MQA.ERRORS.STATISTICS_SEARCH_WITH_PARAMS_NOT_IMPLEMENTED'));
  }

  private getMeterSeachEndpoint(
    searchCriteria: SearchCriteria
  ): (...params: any) => Observable<InspectionItems> {
    switch (searchCriteria.getViewSetting()) {
      case ViewSettings.FACILITIES: {
        const facilitySearchType = searchCriteria.getFacilitySearchType();
        switch (facilitySearchType) {
          case FacilitySearchType.EnegiaIds:
            return this.mqaClient.getInspectionItemsForEnegiaIds;
          case FacilitySearchType.Profile:
          case FacilitySearchType.UseCurrentProfile:
            return this.mqaClient.getInspectionItemsForProfileIds;
          case FacilitySearchType.FacilityName:
            return this.mqaClient.getInspectionItemsForFacilityName;
          case FacilitySearchType.Bucket:
            return this.mqaClient.getInspectionItemsForBucketIds;
          case FacilitySearchType.ReaderType:
            return this.mqaClient.getInspectionItemsForReaderTypeIds;
          default:
            assertUnreachable(facilitySearchType);
        }
        break;
      }
      case ViewSettings.METERING_POINTS: {
        const meterSearchType = searchCriteria.getMeterSearchType();
        switch (meterSearchType) {
          case MeterSearchType.MeterIds:
            return this.mqaClient.getInspectionItemsForMeterIds;
          case MeterSearchType.MeterName:
            return this.mqaClient.getInspectionItemsForMeasurementPointName;
          default:
            assertUnreachable(meterSearchType);
        }
        break;
      }
    }
  }

  private getDefectSearchEndpoint(searchCriteria: SearchCriteria): (params: any) => Observable<InspectionDefectItems> {
    switch (searchCriteria.viewSetting) {
      case ViewSettings.FACILITIES: {
        const facilitySearchType = searchCriteria.getFacilitySearchType();
        switch (facilitySearchType) {
          case FacilitySearchType.Bucket:
            return this.mqaClient.getDefectIssuesForBucketIds;
          case FacilitySearchType.EnegiaIds:
            return this.mqaClient.getDefectIssuesForEnegiaIds;
          case FacilitySearchType.FacilityName:
            return this.mqaClient.getDefectIssuesForFacilityName;
          case FacilitySearchType.ReaderType:
            return this.mqaClient.getDefectIssuesForReaderTypeIds;
          case FacilitySearchType.Profile:
          case FacilitySearchType.UseCurrentProfile:
            return this.mqaClient.getDefectIssuesForProfileIds;
          default:
            assertUnreachable(facilitySearchType);
        }
        break;
      }
      case ViewSettings.METERING_POINTS: {
        const meterSearchType = searchCriteria.getMeterSearchType();
        switch (meterSearchType) {
          case MeterSearchType.MeterIds:
            return this.mqaClient.getDefectIssuesForMeterIds;
          case MeterSearchType.MeterName:
            return this.mqaClient.getDefectIssuesForMeasurementPointName;
          default:
            assertUnreachable(meterSearchType);
        }
      }
    }
  }
}
