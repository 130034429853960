import { Injectable } from '@angular/core';

export type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left';
export type TooltipAlignment = 'center' | 'top' | 'right' | 'bottom' | 'left';

/**
 * A configuration service for the [`NgfTooltip`](#/components/tooltip/api#NgfTooltip) component.
 *
 * You can inject this service, typically in your root component, and customize the values of its properties in
 * order to provide default values for all the tooltips used in the application.
 */
@Injectable({ providedIn: 'root' })
export class NgfTooltipConfig {
  public autoClose: boolean | 'inside' | 'outside' = true;
  public placement: TooltipPlacement = 'top';
  public alignment: TooltipAlignment = 'center';
  public triggers = 'hover focus';
  public container: 'body' | undefined;
  public disableTooltip = false;
  public tooltipClass: string;
  public openDelay = 0;
  public closeDelay = 0;
  public readonly pipSizeInPx: number;
  protected pipSizeInRem = 0.75 * 0.866; // should be same as $tooltip-pip-height in foundations _settings.scss

  public constructor() {
    this.pipSizeInPx = this.convertRemToPixels(this.pipSizeInRem) + 1;
    this.container = 'body';
  }

  private convertRemToPixels(rem: number): number {
    return Math.ceil(rem * parseFloat(getComputedStyle(document.documentElement).fontSize));
  }
}
