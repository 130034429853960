import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComboBoxComponent, MultiColumnComboBoxComponent } from '@progress/kendo-angular-dropdowns';

import {
  MeteringClient,
  PowerOfAttorney,
} from '@enerkey/clients/metering';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { formGroupFrom } from '@enerkey/ts-utils';
import { CompanyModel, ContactClient } from '@enerkey/clients/contact';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { PowerOfAttorneySearchService } from '../../../power-of-attorney/services/power-of-attorney-search.service';

interface PoaSearch {
  poaId: number;
  poaName: string;
  company: CompanyModel;
  companyOvt: string;
}

interface PoaCompany {
  poaCompanyId: number;
  companyName: string;
  companyOvt: string;
}

@Component({
  selector: 'add-meters-to-poa-modal',
  templateUrl: './add-meters-to-poa-modal.component.html',
  styleUrls: ['./add-meters-to-poa-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  size: 'small'
})
export class AddMetersToPoaModalComponent extends ModalBase<void> implements OnDestroy {
  @ViewChild(ComboBoxComponent) public poaSelect: ComboBoxComponent;
  @ViewChild(MultiColumnComboBoxComponent) public companySelect: MultiColumnComboBoxComponent;

  public meterIds: number[];
  public readonly poas$: Observable<PowerOfAttorney[]>;
  public readonly poaSearchForm = formGroupFrom<PoaSearch>({
    poaId: null,
    poaName: null,
    company: null,
    companyOvt: null,
  });
  public readonly selectableCompanies$: Observable<PoaCompany[]>;
  public readonly poaCompanyControl = new UntypedFormControl(null, [Validators.required]);
  public readonly poaControl = new UntypedFormControl(null);
  public readonly companiesLoading$: Observable<boolean>;
  public readonly poasLoading$: Observable<boolean>;

  private readonly poaSearchParams$ = new Subject<PoaSearch>();
  private readonly _companiesLoading$ = new LoadingSubject();
  private readonly _poasLoading$ = new LoadingSubject();
  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly contactClient: ContactClient,
    poaSearchService: PowerOfAttorneySearchService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.companiesLoading$ = this._companiesLoading$.asObservable();
    this.poasLoading$ = this._poasLoading$.asObservable();

    this.poaCompanyControl.disable();

    this.poas$ = this.poaSearchParams$.pipe(
      switchMap(searchParams => poaSearchService.getPowerOfAttorneys({
        poaId: searchParams.poaId,
        poaName: searchParams.poaName,
        companyOvt: searchParams.companyOvt,
        companyId: searchParams.company?.id
      }).pipe(
        indicate(this._poasLoading$)
      )),
      tap(() => {
        this.poaSelect.toggle(true);
        this.poaSelect.focus();
      }),
      shareReplay(1)
    );

    this.selectableCompanies$ = this.poaControl.valueChanges.pipe(
      switchMap(
        (poa: PowerOfAttorney) => Array.hasItems(poa?.poACompanies)
          ? forkJoin([
            of(poa.poACompanies),
            this.contactClient.getCompanies(poa.poACompanies.map(pc => pc.companyId)).pipe(
              indicate(this._companiesLoading$),
              map(companies => companies.toMapBy('id'))
            )
          ]).pipe(
            map(([poaCompanies, companies]) => poaCompanies.map(pc => {
              const company = companies.get(pc.companyId);
              return {
                companyName: company.name,
                companyOvt: company.companyOvt,
                poaCompanyId: pc.id
              };
            }))
          )
          : of(null)
      ),
      shareReplay(1)
    );

    this.selectableCompanies$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: poaCompanies => {
          this.poaCompanyControl.setValue(null);
          if (poaCompanies) {
            this.poaCompanyControl.enable();
            setTimeout(() => {
              this.companySelect.toggle(true);
              this.companySelect.focus();
            });
          } else {
            this.poaCompanyControl.disable();
          }
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public searchPoa(): void {
    this.poaSearchParams$.next(this.poaSearchForm.value);
    this.poaControl.setValue(null);
  }

  public get selectedPoa(): PowerOfAttorney {
    return this.poaControl.value;
  }

  public save(): void {
    this.meteringClient.upsertMeterToPoACompany(
      this.poaCompanyControl.value,
      this.meterIds
    ).subscribe({
      next: () => {
        super.closeModal();
      }
    });
  }
}
