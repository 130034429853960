import EmInputFacilityController from './em-input-facility-controller';
import emInputFacilityTemplate   from 'raw-loader!./em-input-facility.html';

export default {
  template: emInputFacilityTemplate,
  controller: EmInputFacilityController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    onChange: '<'
  }
};
