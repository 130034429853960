import ReportForecastSettingsController from './report-forecast-settings.controller';
import ReportForecastSettingsTemplate   from 'raw-loader!./report-forecast-settings.html';

export default {
  template: ReportForecastSettingsTemplate,
  controller: ReportForecastSettingsController,
  controllerAs: 'vm',
  bindings: {
    reportTypeChanged: '&?'
  }
};
