import JQueryModule from '../jquery';

import kendo     from './kendo';
import numeral   from './numeral';
import kendoDate from './kendo-date';

export default angular.module('kendoModule', [
  JQueryModule.name
])
  .factory('kendo', kendo)
  .filter('numeral', numeral)
  .filter('kendoDate', kendoDate);
