import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Investigation } from '@enerkey/clients/attachments';

@Component({
  selector: 'investigation-status',
  templateUrl: './investigation-status.component.html',
  styleUrls: ['./investigation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvestigationStatusComponent {
  public readonly statuses = [
    Investigation.InvestigationRequired,
    Investigation.UnderInvestigation,
    Investigation.InvestigationDone,
  ] as const;

  @Input() public status: Investigation;

}
