import EnerKeyServicesModule from '../services';
import LodashModule from '../modules/lodash';
import clickAnywhereButHere from './click-anywhere-but-here';
import confirmOnExit from './confirm-on-exit';
import documentUpload from './document-upload';
import gridFullHeight from './grid-full-height';
import gridGroupHandler from './grid-group-handler';
import infiniteScroll from './infinite-scroll';
import listPaste from './list-paste';
import menuItem from './menu-item';
import menuItemWithSubMenu from './menu-item-with-sub-menu';
import menu from './menu';
import onImgLoad from './on-img-load';
import resetChartOptions from './reset-chart-options';
import responsiveKendo from './responsive-kendo';
import sidebarContainer from './sidebar-container';
import sidebarItemLoading from './sidebar-item-loading';
import { toggleGridColumns, toggleGridColumnsButtonOnly } from './toggle-grid-columns';
import toggleGridContent from './toggle-grid-content';

export default angular.module('EnerKey2.Directives', [
  EnerKeyServicesModule.name,
  LodashModule.name
])
  .directive('clickAnywhereButHere', clickAnywhereButHere)
  .directive('confirmOnExit', confirmOnExit)
  .directive('documentUpload', documentUpload)
  .directive('gridFullHeight', gridFullHeight)
  .directive('gridGroupHandler', gridGroupHandler)
  .directive('infiniteScroll', infiniteScroll)
  .directive('listPaste', listPaste)
  .directive('menuItem', menuItem)
  .directive('menuItemWithSubMenu', menuItemWithSubMenu)
  .directive('menu', menu)
  .directive('onImgLoad', onImgLoad)
  .directive('resetChartOptions', resetChartOptions)
  .directive('responsiveKendo', responsiveKendo)
  .directive('sidebarContainer', sidebarContainer)
  .directive('sidebarItemLoading', sidebarItemLoading)
  .directive('toggleGridColumns', toggleGridColumns)
  .directive('toggleGridColumnsButtonOnly', toggleGridColumnsButtonOnly)
  .directive('toggleGridContent', toggleGridContent);
