import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { PeriodReportService } from './period-report.service';

@Injectable({
  providedIn: 'root'
})
export class TableReportDataService {
  public constructor(
    private readonly periodReportService: PeriodReportService
  ) {
  }

  public getQuantitiesData(
    params: ReportingSearchParams,
    facilityIds: number[],
    incompleteThreshold: number
  ): Observable<ReportingSeriesByFacility> {
    return this.periodReportService.getData(
      new ReportingSearchParams({
        ...params.formValue,
        resolution: params.formValue.showSummedConsumption ? null : params.formValue.resolution,
        temperature: false,
        showMeasuredValues: true,
      }),
      facilityIds,
      incompleteThreshold
    );
  }
}
