import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { QuantityService } from '../services/quantity.service';

@Pipe({ name: 'quantityKey' })
export class QuantityKeyPipe implements PipeTransform {
  public constructor(private readonly quantityService: QuantityService) {
  }

  public transform(quantityId: number): Observable<string> {
    return this.quantityService.getQuantityById(quantityId)
      .pipe(map(quantity => quantity.Key.toLowerCase()));
  }
}
