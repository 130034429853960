import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER, DoBootstrap, ElementRef, ErrorHandler, LOCALE_ID, NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ChartsModule, StockChartModule } from '@progress/kendo-angular-charts';
import { CldrIntlService, IntlModule, IntlService } from '@progress/kendo-angular-intl';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { POPUP_CONTAINER } from '@progress/kendo-angular-popup';
import { NgHybridStateDeclaration, UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ToastrModule } from 'ngx-toastr';
import { MarkdownModule } from 'ngx-markdown';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import 'hammerjs';
import '@progress/kendo-ui';
import '@progress/kendo-ui/js/cultures/kendo.culture.fi-FI';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { MessageService } from '@progress/kendo-angular-l10n';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';

import { AccordionModule, NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkFormsModule } from '@enerkey/ek-forms';

import './kendo-ui-licensing';
import EnerKeyModule from './app.module.ajs';
import { MrcModule } from './modules/mrc/mrc.module';
import { VirtualMetersModule } from './modules/virtual-meters/virtual-meters.module';
import { HeaderParamsInterceptor } from './shared/interceptors/header-params-interceptor';
import { SharedModule } from './shared/shared.module';
import { LoadingService } from './shared/services/loading.service';
import { AdminModule } from './modules/admin/admin.module';
import { KendoMessageService } from './shared/services/kendo-message.service';
import { ManualQaModule } from './modules/manual-qa/manual-qa.module';
import { TenantService } from './shared/services/tenant.service';
import { LoadingInterceptor } from './shared/interceptors/loading-interceptor';
import { ConfigurationControlModule } from './modules/configuration-control/configuration-control.module';
import { EnergyReportingModule } from './modules/energy-reporting/energy-reporting.module';
import { AuthenticationService } from './shared/services/authentication.service';
import { TOASTR_DEFAULT_CONFIG } from './shared/services/toaster.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { defaultTranslationLocale } from './shared/services/language-change.service';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { UserSettingsModalComponent } from './components/user-settings-modal/user-settings-modal.component';
import { EkInputsModule } from './shared/ek-inputs/ek-inputs.module';
import { TopbarComponent } from './components/topbar/topbar.component';
import { TopbarTabComponent } from './components/topbar-tab/topbar-tab.component';
import { EkDropdownModule } from './shared/ek-dropdown/ek-dropdown.module';
import { TopbarSettingsComponent } from './components/topbar-settings/topbar-settings.component';
import { ProfileChangeModalComponent } from './components/profile-change-modal/profile-change-modal.component';
import { AccessTokenCopyComponent } from './components/access-token-copy/access-token-copy.component';
import { TopbarSearchComponent } from './components/topbar-search/topbar-search.component';
import { TopbarSearchInputComponent } from './components/topbar-search-input/topbar-search-input.component';
import { FacilityFilterModalComponent } from './components/facility-filter-modal/facility-filter-modal.component';
import { TopbarFacilityFilterComponent } from './components/topbar-facility-filter/topbar-facility-filter.component';
import { FacilityFilterDisplayTextPipe } from './pipes/facility-filter-display-text.pipe';
import { ProfileApiKeysComponent } from './components/profile-api-keys/profile-api-keys.component';
import { ProfileApiKeysModalComponent } from './components/profile-api-keys-modal/profile-api-keys-modal.component';
import { ProfileApiKeysCreateModalComponent } from './components/profile-api-keys-create-modal/profile-api-keys-create-modal.component';
import { ClientProvidersModule } from './client-providers';
import { BookmarksModalComponent } from './components/bookmarks-modal/bookmarks-modal.component';
import { BookmarksTopbarItemComponent } from './components/bookmarks-topbar-item/bookmarks-topbar-item.component';
import { TelemetryService } from './services/telemetry.service';
import { authCodeFlowConfig } from './auth-config';
import { UnauthorizedInterceptor } from './shared/interceptors/unauthorized-interceptor';
import { TenantApiKeysModalComponent } from './components/tenant-api-keys-modal/tenant-api-keys-modal.component';
import { TenantApiKeyCreateComponent } from './components/tenant-api-key-create/tenant-api-key-create.component';
import { TenantApiKeysComponent } from './components/tenant-api-keys/tenant-api-keys.component';
import { BookmarkIndicatorColorPipe } from './pipes/bookmark-indicator-color.pipe';
import { BookmarkTitlePipe } from './pipes/bookmark-title.pipe';
import { ApiKeyIndexPipe } from './pipes/api-key-index.pipe';
import { ReportingModule } from './modules/reporting/reporting.module';
import { EmailNotificationUnsubscribeComponent } from './modules/email-notification/components/email-notification-unsubscribe/email-notification-unsubscribe.component';

function authFactory(oauthService: OAuthService): () => Promise<unknown> {
  return async () => {
    let targetUrl = window.location.pathname + window.location.search;
    targetUrl = targetUrl === '/' ? undefined : targetUrl;
    oauthService.configure(authCodeFlowConfig);

    const oidConfigUrl = `${ENERKEY_CONFIG.idSrv}/.well-known/openid-configuration`;
    await oauthService.loadDiscoveryDocument(oidConfigUrl);
    const isLoggedIn = await oauthService.tryLogin();
    if (isLoggedIn) {
      oauthService.setupAutomaticSilentRefresh(undefined, 'access_token');
    } else {
      oauthService.initLoginFlow(targetUrl);
    }
  };
}

function tenantInitializerFactory(tenantService: TenantService): () => Promise<unknown> {
  return () => tenantService.setTenantSpecificStyles()
  // Catch to make sure that promise is always resolved
  // Rejected promise prevents app from bootstrapping
    .catch(() => Promise.resolve());
}

function userInfoFactory(authenticationService: AuthenticationService): () => Promise<unknown> {
  return () => authenticationService.getUserInfo();
}

function localeIdFactory(localStorageService: LocalStorageService): string {
  return localStorageService.getStringValueByKey('lang') || defaultTranslationLocale;
}

const supervisionFutureState: NgHybridStateDeclaration = {
  name: 'supervision.**',
  url: '/supervision',
  loadChildren: () => import('./modules/supervision/supervision.module').then(m => m.SupervisionModule)
};

const analyticsFutureState: NgHybridStateDeclaration = {
  name: 'ines.**',
  url: '/ines',
  loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule)
};

const energyManagementFutureState: NgHybridStateDeclaration = {
  name: 'energy-management.**',
  url: '/energy-management',
  loadChildren: () => import('./modules/energy-management/energy-management.module').then(m => m.EnergyManagementModule)
};

const webhookFutureState: NgHybridStateDeclaration = {
  name: 'webhook.**',
  url: '/webhook',
  loadChildren: () => import('./modules/webhook/webhook.module').then(m => m.WebhookModule)
};

const newsFutureState: NgHybridStateDeclaration = {
  name: 'news.**',
  url: '/admin/news',
  loadChildren: () => import('./modules/news-management/news-management.module').then(m => m.NewsManagementModule)
};

const alarmFutureState: NgHybridStateDeclaration = {
  name: 'alarm.**',
  url: '/admin/alarm/',
  loadChildren: () => import('./modules/alarm-management/alarm-management.module').then(m => m.AlarmManagementModule),
};

const alarmLogFutureState: NgHybridStateDeclaration = {
  name: 'energy-management.alarms.**',
  url: '/alarm-service/',
  loadChildren: () => import('./modules/alarm-management/alarm-management.module').then(m => m.AlarmManagementModule),
};

const tenantFutureState: NgHybridStateDeclaration = {
  name: 'tenant.**',
  url: '/admin/tenants',
  loadChildren: () => import('./modules/tenant-manager/tenant-manager.module').then(m => m.TenantManagerModule),
};

const contractFutureState: NgHybridStateDeclaration = {
  name: 'contract.**',
  url: '/admin/contract/',
  loadChildren: () => import('./modules/contracts/contracts.module').then(m => m.ContractsModule),
};

const contactFutureState: NgHybridStateDeclaration = {
  name: 'contact.**',
  url: '/admin/contact/',
  loadChildren: () => import('./modules/contact-manager/contact-manager.module').then(m => m.ContactManagerModule),
};

const documentsFutureState: NgHybridStateDeclaration = {
  name: 'documents.**',
  url: '/documents/',
  loadChildren: () => import('./modules/documents/document.module').then(m => m.DocumentsModule),
};

const poaFutureState: NgHybridStateDeclaration = {
  name: 'powerOfAttorney.**',
  url: '/admin/powerOfAttorney',
  loadChildren: () => import('./modules/power-of-attorney/power-of-attorney.module').then(m => m.PowerOfAttorneyModule)
};

const sustainabilityFutureState: NgHybridStateDeclaration = {
  name: 'sustainability.**',
  url: '/sustainability',
  loadChildren: () => import('./modules/sustainability/sustainability.module').then(m => m.SustainabilityModule)
};

const apiExportFutureState: NgHybridStateDeclaration = {
  name: 'api-export.**',
  url: '/api-export',
  loadChildren: () => import('./modules/api-export/api-export.module').then(m => m.ApiExportModule)
};

const emailConsumptionSubscriptionFutureState: NgHybridStateDeclaration = {
  name: 'email-notification-unsubscribe.**',
  url: '/email-notification-unsubscribe',
  views: { 'app@': { component: EmailNotificationUnsubscribeComponent } }
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: 'assets/languages/', suffix: '.json' },
    { prefix: 'assets/languages/contact-manager/', suffix: '.json' },
    { prefix: 'assets/languages/admin/', suffix: '.json' },
    { prefix: 'assets/languages/reportingobjects/', suffix: '.json' },
    { prefix: 'assets/languages/configuration/', suffix: '.json' },
    { prefix: 'assets/languages/alarm-management/', suffix: '.json' },
    { prefix: 'assets/languages/energy-management/', suffix: '.json' },
    { prefix: 'assets/languages/energy-reporting/', suffix: '.json' },
    { prefix: 'assets/languages/kendo/', suffix: '.json' },
    { prefix: 'assets/languages/configuration-control/', suffix: '.json' },
    { prefix: 'assets/languages/manual-qa/', suffix: '.json' },
    { prefix: 'assets/languages/documents/', suffix: '.json' },
    { prefix: 'assets/languages/provisioning/', suffix: '.json' },
    { prefix: 'assets/languages/analytics/', suffix: '.json' },
    { prefix: 'assets/languages/mrc/', suffix: '.json' },
    { prefix: 'assets/languages/dashboard/', suffix: '.json' },
    { prefix: 'assets/languages/news-management/', suffix: '.json' },
    { prefix: 'assets/languages/supervision/', suffix: '.json' },
    { prefix: 'assets/languages/webhook/', suffix: '.json' },
    { prefix: 'assets/languages/reporting/', suffix: '.json' },
    { prefix: 'assets/languages/sustainability/', suffix: '.json' },
    { prefix: 'assets/languages/api-export/', suffix: '.json' },
    { prefix: 'assets/languages/email-notification/', suffix: '.json' },
  ]);
}

@NgModule({
  imports: [
    ClientProvidersModule,
    AdminModule,
    BrowserModule,
    UpgradeModule,
    HttpClientModule,
    MrcModule,
    VirtualMetersModule,
    ManualQaModule,
    EnergyReportingModule,
    BrowserAnimationsModule,
    StockChartModule,
    ChartsModule,
    SharedModule,
    DashboardModule,
    EkInputsModule,
    EkFormsModule,
    ReactiveFormsModule,
    InputsModule,
    ConfigurationControlModule,
    EkDropdownModule,
    DateInputsModule,
    IntlModule,
    LabelModule,
    NgfTooltipModule,
    AccordionModule,
    ToastrModule.forRoot(TOASTR_DEFAULT_CONFIG),
    MarkdownModule.forRoot(),
    PDFExportModule,
    ReportingModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
        allowedUrls: [
          ENERKEY_CONFIG.portalProxyUrl,
          `${ENERKEY_CONFIG.idSrv}/connect/userinfo`,
        ]
      }
    }),
    UIRouterUpgradeModule.forRoot({
      states: [
        supervisionFutureState,
        analyticsFutureState,
        energyManagementFutureState,
        webhookFutureState,
        newsFutureState,
        alarmFutureState,
        alarmLogFutureState,
        tenantFutureState,
        contractFutureState,
        contactFutureState,
        documentsFutureState,
        poaFutureState,
        sustainabilityFutureState,
        apiExportFutureState,
        emailConsumptionSubscriptionFutureState
      ]
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    TenantService,
    LoadingService,
    HeaderParamsInterceptor,
    LoadingInterceptor,
    CldrIntlService,
    { provide: IntlService, useExisting: CldrIntlService },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderParamsInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [LocalStorageService]
    },
    { provide: MessageService, useClass: KendoMessageService },
    { provide: APP_INITIALIZER, useFactory: authFactory, deps: [OAuthService], multi: true },
    { provide: APP_INITIALIZER, useFactory: tenantInitializerFactory, deps: [TenantService], multi: true },
    { provide: APP_INITIALIZER, useFactory: userInfoFactory, deps: [AuthenticationService], multi: true },
    { provide: POPUP_CONTAINER, useFactory: (): ElementRef => ({ nativeElement: document.body }) },
    { provide: ErrorHandler, useClass: TelemetryService },
  ],
  declarations: [
    LoadingIndicatorComponent,
    UserSettingsModalComponent,
    TopbarComponent,
    TopbarTabComponent,
    TopbarSettingsComponent,
    ProfileChangeModalComponent,
    AccessTokenCopyComponent,
    TopbarSearchComponent,
    TopbarSearchInputComponent,
    FacilityFilterModalComponent,
    TopbarFacilityFilterComponent,
    FacilityFilterDisplayTextPipe,
    ProfileApiKeysComponent,
    ProfileApiKeysModalComponent,
    ProfileApiKeysCreateModalComponent,
    BookmarksModalComponent,
    BookmarksTopbarItemComponent,
    TenantApiKeysModalComponent,
    TenantApiKeyCreateComponent,
    TenantApiKeysComponent,
    BookmarkIndicatorColorPipe,
    BookmarkTitlePipe,
    ApiKeyIndexPipe,
  ],
})

export class AppModule implements DoBootstrap {
  public constructor(private upgrade: UpgradeModule) {
  }

  public ngDoBootstrap(): void {
    document.body.style.cssText = '';
    this.upgrade.bootstrap(document.body, [EnerKeyModule.name], { strictDi: true });
  }
}
