import angular from 'angular';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { DEFAULT_COLOR, HEADER_BACKGROUND } from '../../../../shared/spreadsheet.functions';
import {
  EnegiaIdPersonEmailPair,
  FunctionType,
  IContactClient,
  MassAddPersonFacilityFunctionValidationError
} from '@enerkey/clients/contact';
import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import { ToasterType } from '../../../../constants/toaster-type';
import WizardStep from '../../../../components/wizard/wizard-step';

const inject = ['utils', 'ContactClient', 'KendoFunctions', '$element', '$timeout', '$window'];

class MassAddFacilityPersonsController extends MassImportSpreadsheet {
  public spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  public steps: WizardStep[];
  public currentStep: WizardStep;
  public functionTypes: ({ translation: string; value: number })[];
  public functionType: number;
  public spreadsheetElementId = 'spreadsheet';
  protected messages: SpreadsheetMessages;
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected numberOfColumns = 3;
  private selectionDone = false;
  private processedIndexes: number[] = [];
  private subscription: Subscription;

  public constructor(
    private utils: any,
    private contactClient: IContactClient,
    private kendoFunctions: any,
    protected $element: JQuery,
    protected $timeout: any,
    protected $window: ng.IWindowService
  ) {
    super();
  }

  public $onInit(): void {
    this.spreadsheetOptions = this.getSpreadsheet();
    this.totalRowCount = this.spreadsheetOptions.rows;
    this.messages = {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE')
    };
    this.steps = [
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.TYPE_SELECT'),
        buttonDisabledFn: this.isInitialized.bind(this),
        isReturnable: true,
        clickFn: this.selectionDoneChange.bind(this),
        rollbackFn: this.unInitialize.bind(this)
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
        isReturnable: true,
        clickFn: this.saveValues.bind(this),
        rollbackFn: this.enableSheet.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
        isReturnable: false,
        clickFn: this.stopSaving.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.READY'),
        hideButton: true
      }
    ];

    this.functionTypes = [{
      value: FunctionType.Superintendent,
      translation: this.utils.localizedString(`ADMIN.CONTACTS.FUNCTION.${FunctionType[FunctionType.Superintendent]}`)
    }, {
      value: FunctionType.Manager,
      translation: this.utils.localizedString(`ADMIN.CONTACTS.FUNCTION.${FunctionType[FunctionType.Manager]}`)
    }, {
      value: FunctionType.Service,
      translation: this.utils.localizedString(`ADMIN.CONTACTS.FUNCTION.${FunctionType[FunctionType.Service]}`)
    }, {
      value: FunctionType.Owner,
      translation: this.utils.localizedString(`ADMIN.CONTACTS.FUNCTION.${FunctionType[FunctionType.Owner]}`)
    }, {
      value: FunctionType.Alarm,
      translation: this.utils.localizedString(`ADMIN.CONTACTS.FUNCTION.${FunctionType[FunctionType.Alarm]}`)
    }];
    this.functionType = this.functionTypes[0].value;

    this.$timeout(() => {
      this.resizeKendo();
    });
  }

  public override $onDestroy(): void {
    super.$onDestroy();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private resizeKendo(): void {
    const spreadsheet = this.getSpreadsheetInstance();

    const resizeFn = _.debounce(() => {
      this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
    }, 200);

    angular.element(this.$window).on('resize', resizeFn);

    this.kendoFunctions.resizeKendoComponent(spreadsheet, this.spreadSheetRowClass);
  }

  private stopSaving(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.processedIndexes.forEach(id => this.setCanceledStatus(id));
    }
  }

  private getEnegiaIdPersonEmailPairArrayFromSpreadsheet(): EnegiaIdPersonEmailPair[] {
    this.processedIndexes = [];
    const enegiaIdPersonEmailPairArray: EnegiaIdPersonEmailPair[] = [];
    this.getDataRows().forEach((row, index) => {
      if (row.length > 1 && row[0] !== null || row[1] !== null) {
        const enegiaIdPersonEmailPair: EnegiaIdPersonEmailPair = {
          enegiaId: Number(row[0]),
          email: row[1] ? row[1].toString() : null
        };
        enegiaIdPersonEmailPairArray.push(enegiaIdPersonEmailPair);
        this.setProcessingStatus(index);
        this.processedIndexes.push(index);
      }
    });
    return enegiaIdPersonEmailPairArray;
  }

  private saveValues(): void {
    if (this.validateSheet()) {
      this.processedIndexes = [];
      const enegiaIdPersonEmailPairArray = this.getEnegiaIdPersonEmailPairArrayFromSpreadsheet();
      if (enegiaIdPersonEmailPairArray.length === 0) {
        this.utils.popUp(
          ToasterType.INFO,
          this.utils.localizedString('ADMIN.CONTACTS.ERRORS.EMPTY_SPREADSHEET')
        );
        return;
      }
      this.subscription = this.contactClient
        .massAddPersonFacilityFunctionsWithEnegiaId(this.functionType, enegiaIdPersonEmailPairArray)
        .pipe(finalize(() => this.handleFinally()))
        .subscribe(
          () => this.handleSuccess(),
          error => this.handleError(error)
        );
    } else {
      this.utils.popUp(ToasterType.ERROR, this.utils.localizedString('ADMIN.CONTACTS.ERRORS.INVALID_SPREADSHEET'));
    }
  }

  private handleSuccess(): void {
    this.processedIndexes.forEach(id => this.setSuccessStatus(id));
    this.utils.popUp(
      ToasterType.SUCCESS,
      this.utils.localizedString('ADMIN.CONTACTS.UPDATE_CONTACTS_SUCCESS')
    );
  }

  private handleError(error: { [key: string]: MassAddPersonFacilityFunctionValidationError[] }): void {
    Object.keys(error).forEach(index => {
      this.setErrorStatus(Number(index), this.getErrorMessages(error[index]));
    });
    this.utils.popUpGeneralError('SAVE', 'CONTACTS');
  }

  private handleFinally(): void {
    this.setFinalStep();
  }

  private getErrorMessages(errors: number[]): string {
    return errors.map(error =>
      this.utils.localizedString(`ADMIN.CONTACTS.ERRORS.${MassAddPersonFacilityFunctionValidationError[error]}`))
      .toString();
  }

  private unInitialize(): void {
    this.spreadsheet = undefined;
    this.selectionDone = false;
  }

  private setFinalStep(): void {
    this.currentStep = this.steps[this.steps.length - 1];
  }

  private isInitialized(): boolean {
    return this.selectionDone;
  }

  private selectionDoneChange(): void {
    this.selectionDone = true;
    this.$timeout(() => {
      this.lockRange(2);
    });
  }

  private getSpreadsheet(): kendo.ui.SpreadsheetOptions {
    return {
      toolbar: false,
      sheetsbar: false,
      columns: this.numberOfColumns,
      excel: {
        fileName: 'mass_add_facility_persons.xlsx'
      },
      rows: 999,
      sheets: [
        {
          name: 'default',
          columns: [
            { width: 200 },
            { width: 240 },
            { width: 300 }
          ],
          rows: [{
            cells: [
              {
                value: this.utils.localizedString('ADMIN.SPREADSHEET.ENEGIA_ID.HEADING'),
                background: HEADER_BACKGROUND,
                textAlign: 'center',
                color: DEFAULT_COLOR,
                enable: false
              }, {
                value: this.utils.localizedString('ADMIN.EMAIL'),
                background: HEADER_BACKGROUND,
                textAlign: 'center',
                color: DEFAULT_COLOR,
                enable: false
              }, {
                value: this.utils.localizedString('ADMIN.SPREADSHEET.STATUS.HEADING'),
                background: HEADER_BACKGROUND,
                textAlign: 'center',
                color: DEFAULT_COLOR,
                enable: false
              }
            ]
          }]
        }
      ]
    };
  }
}

MassAddFacilityPersonsController.$inject = inject;

export default MassAddFacilityPersonsController;

