import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { WizardStep } from '../interfaces/wizard-step';

@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html'
})
export class WizardComponent implements OnInit {

  @Input() public steps: WizardStep[] = [];
  @Input() public enableLastAction: boolean;

  @Input()
  public set step(step: WizardStep<any>) {
    if (step) {
      this.changeStep(step);
    }
  }

  @Output() public readonly stepChange = new EventEmitter<WizardStep<any>>();
  @Output() public readonly lastAction = new EventEmitter<void>();

  public currentStep: WizardStep<any>;

  public ngOnInit(): void {
    this.currentStep = this.steps[0];
  }

  public isStepVisited(step: WizardStep): boolean {
    return this.steps.indexOf(step) < this.steps.indexOf(this.currentStep);
  }

  public isStepDisabled(step: WizardStep): boolean {
    if (step.isDisabled) {
      return true;
    } else if (this.steps.indexOf(step) > this.steps.indexOf(this.currentStep)) {
      return true;
    } else if (this.getValue(this.currentStep.canReturn) === false) {
      return this.steps.indexOf(step) < this.steps.indexOf(this.currentStep);
    }
    return false;
  }

  public setStep(step: WizardStep): void {
    if (step !== this.currentStep) {
      if (this.currentStep.onExit) {
        this.currentStep.onExit();
      }
      if (this.currentStep.onReturn) {
        this.currentStep.onReturn().subscribe(() => this.changeStep(step));
      } else {
        this.changeStep(step);
      }
    }
  }

  public getStepNumber(step: WizardStep): number {
    return this.steps.indexOf(step) + 1;
  }

  public nextClicked(): void {
    if (this.currentStep.onExit) {
      this.currentStep.onExit();
    }
    if (this.currentStep !== this.steps[this.steps.length - 1]) {
      const nextStep = this.steps[this.steps.indexOf(this.currentStep) + 1];
      if (this.currentStep.onContinue) {
        this.currentStep.onContinue().subscribe(
          () => this.changeStep(nextStep),
          /* istanbul ignore next */() => { }
        );
      } else {
        this.changeStep(nextStep);
      }
    } else if (this.enableLastAction) {
      this.lastAction.emit();
    }
  }

  public isNextDisabled(): boolean {
    if (this.getValue(this.currentStep.canContinue) === false) {
      return true;
    }

    return false;
  }

  public isNextVisible(): boolean {
    return this.currentStep !== this.steps[this.steps.length - 1] || this.enableLastAction;
  }

  private changeStep(step: WizardStep): void {
    if (step === this.currentStep) {
      return;
    }

    this.currentStep = step;
    this.stepChange.emit(this.currentStep);
    if (this.currentStep.onEnter) {
      this.currentStep.onEnter();
    }
  }

  private getValue(valueOrFn: boolean | (() => boolean)): boolean {
    return typeof valueOrFn === 'function' ? valueOrFn() : valueOrFn;
  }
}
