import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Investigation } from '@enerkey/clients/attachments';
import { investigationTranslations } from '../../../modules/energy-management/constants/em-investigations';

@Pipe({
  name: 'investigationStatusName'
})
export class InvestigationStatusNamePipe implements PipeTransform {

  public constructor(
    private readonly translateService: TranslateService
  ) { }

  public transform(value: Investigation): string {
    return value in investigationTranslations
      ? this.translateService.instant(investigationTranslations[value])
      : '';
  }

}
