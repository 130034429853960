import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { NewsClient, ReadOnlyNews } from '@enerkey/clients/news';

import { UserService } from '../../services/user-service';
import { ToasterService } from '../services/toaster.service';

@Component({
  selector: 'news-modal',
  templateUrl: './news-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ size: 'small', backdrop: true })
export class NewsModalComponent extends ModalBase<void> implements OnInit, OnDestroy {

  public entry: ReadOnlyNews;

  public content$: Observable<string>;
  public readonly loading$: Observable<boolean>;
  public readonly read$: Observable<void>;

  private readonly _loading = new LoadingSubject(true);
  private readonly _read = new Subject<void>();
  private readonly _destroy = new Subject<void>();

  public constructor(
    modal: NgfActiveModal,
    private readonly newsClient: NewsClient,
    private readonly userService: UserService,
    private readonly toaster: ToasterService
  ) {
    super(modal);
    this.loading$ = this._loading.asObservable();
    this.read$ = this._read.asObservable();
  }

  public ngOnInit(): void {
    if (!this.entry) {
      throw Error('NewsEntry must be set');
    }

    this.content$ = this.newsClient.getContentById(
      this.entry.id,
      this.userService.profileId
    ).pipe(
      map(newsItem => newsItem.text),
      takeUntil(this._destroy),
      catchError(() => {
        this.toaster.error('DASHBOARD.DOWNLOAD_ERROR');
        return EMPTY;
      }),
      tap(() => this._read.next()),
      indicate(this._loading)
    );
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loading.complete();
    this._read.complete();
  }
}
