import facilityDropdownTemplate from 'raw-loader!./facility-dropdown.html';
import facilityDropdownController from './facility-dropdown-controller';

export default {
  template: facilityDropdownTemplate,
  controller: facilityDropdownController,
  controllerAs: 'vm',
  bindings: {
    onChange: '&'
  }
};
