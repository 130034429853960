import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { GridsterComponent, GridsterConfig, GridsterItemComponentInterface } from 'angular-gridster2';
import { BehaviorSubject, combineLatest, from, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs/operators';

import {
  FacilityInformationGroup,
} from '@enerkey/clients/energy-reporting';
import { Quantities } from '@enerkey/clients/metering';
import { ServiceLevel } from '@enerkey/clients/facility';
import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';
import { WINDOW } from '@enerkey/angular-utils';

import { QuantityService } from '../../../../shared/services/quantity.service';
import { FacilityInfoPipe } from '../../../../shared/pipes/facility-info.pipe';
import { ServiceLevelService } from '../../../../shared/services/service-level.service';
import { ReportEvents, ReportEventService } from '../../services/report-events.service';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { MonthNamePipe } from '../../../../shared/common-pipes/month-name.pipe';
import { getLatestReportingMonthNumeric } from '../../../../shared/date.functions';
import { Comparability } from '../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ReportingSeriesCollection } from '../../shared/reporting-series-collection';
import { ReportingSeries, ReportSeriesDataPoint } from '../../shared/reporting-series';
import { ReportingSearchFormValue } from '../../shared/reporting-search-form-value';
import { durationToString } from '../../shared/duration-to-string';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { OverviewReportService } from '../../services/overview-report.service';
import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../report-modal/report-modal.component';
import { ReportModalService } from '../../services/report-modal.service';
import { ReportType } from '../../shared/report-type';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';

interface OverviewValue {
  value: number;
  difference: number;
  differencePercent: number;
  showDiff?: boolean;
  costData?: OverviewValue;
  emissionData?: OverviewValue;
  isIncomplete?: boolean;
}

export interface OverviewQuantity {
  quantityId: number,
  monthData: OverviewValue,
  yearData: OverviewValue,
  series: ReportingSeries[],
  unit: string,
  x: number,
  y: number,
  cols: number,
  rows: number,
  expanded$: Observable<boolean>,
  _expanded$: BehaviorSubject<boolean>,
}

enum DataType {
  Month = 'm',
  Year = 'y'
}

const gridsterMargin = 10;

type MonthKey = keyof Pick<OverviewQuantity, 'monthData'>;
type YearKey = keyof Pick<OverviewQuantity, 'yearData'>;

const gridsterMobileBreakpoint = 500;

@Component({
  selector: 'facility-overview',
  templateUrl: './facility-overview.component.html',
  styleUrls: ['./facility-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityOverviewComponent implements AfterViewInit, OnDestroy {
  @ViewChild(GridsterComponent) public gridster: GridsterComponent;
  @ViewChild('gridContainer') public gridContainer: ElementRef<HTMLElement>;

  public readonly timeFrameControl = new FormControl<MonthKey | YearKey>('monthData');

  public readonly facility$: Observable<FacilityInformationGroup>;
  public readonly quantityData$: Observable<OverviewQuantity[]>;
  public readonly actionDefaultQuantity$: Observable<Quantities>;

  public readonly ReportingUnit = ReportingUnit;

  public readonly hasServiceLevelM: boolean;

  public readonly yearKey: YearKey = 'yearData';
  public readonly monthKey: MonthKey = 'monthData';
  public readonly notes$: Observable<ReportEvents>;
  public readonly params$: Observable<ReportingSearchParams>;

  public readonly currentYear: number;
  public readonly latestMonthName: string;
  public readonly yearText: string;

  public readonly gridOptions: GridsterConfig = {
    gridType: 'verticalFixed',
    setGridSize: true,
    fixedRowHeight: 200,
    outerMarginTop: 0,
    maxItemRows: 2,
    maxItemCols: 2,
    minItemRows: 1,
    minItemCols: 1,
    maxCols: 3,
    minCols: 3,
    margin: gridsterMargin,
    mobileBreakpoint: gridsterMobileBreakpoint
  };

  private readonly widthChange$ = new Subject<number>();
  private resizeObserver: ResizeObserver;

  private readonly facilityQuantities$: Observable<number[]>;
  private readonly duration: Pick<ReportingSearchFormValue, 'durationName' | 'durationLength'>;
  private readonly facilityId: number;
  private readonly latestMonth: number;

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly facilityInfoPipe: FacilityInfoPipe,
    private readonly reportEventService: ReportEventService,
    private readonly reportingSearchService: ReportingSearchService,
    private readonly monthNamePipe: MonthNamePipe,
    private readonly overviewReportService: OverviewReportService,
    private readonly thresholdService: ThresholdService,
    private readonly reportModalService: ReportModalService,
    serviceLevelService: ServiceLevelService,
    @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams,
    @Inject(WINDOW) window: Window
  ) {
    this.facilityId = modalParams.facilityId;

    this.hasServiceLevelM = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);

    fromEvent(window, 'beforeprint').subscribe(() => {
      this.gridOptions.maxCols = 2;
      this.gridOptions.minCols = 2;
      this.gridOptions.margin = 0;
      this.gridOptions.api.optionsChanged();
      this.autoPosition();
      this.gridOptions.api.resize();
    });

    this.currentYear = this.reportingSearchService.getDefaultYear();
    this.latestMonth = getLatestReportingMonthNumeric(this.currentYear);
    this.latestMonthName = this.monthNamePipe.transform(this.latestMonth, true).capitalize();

    this.duration = this.latestMonth === 11
      ? { durationName: 'years', durationLength: 1 } as const
      : { durationName: 'months', durationLength: this.latestMonth + 1 } as const
    ;

    this.yearText = durationToString(
      new Date(this.currentYear, 0, 1),
      { [this.duration.durationName]: this.duration.durationLength },
      0
    );

    this.widthChange$.pipe(
      debounceTime(50),
      distinctUntilChanged()
    ).subscribe(width => {
      const columns = Math.floor(width / (gridsterMobileBreakpoint / 2));
      this.gridOptions.minCols = columns;
      this.gridOptions.maxCols = columns;
      this.gridOptions.api.optionsChanged();
      this.autoPosition();
      this.gridOptions.api.resize();
    });

    this.facilityQuantities$ = from(this.quantityService.getSignificantQuantitiesForFacility(this.facilityId)).pipe(
      map(quantities => quantities.map(q => q.ID)),
      shareReplay(1)
    );

    this.facility$ = this.facilityInfoPipe.transform(this.facilityId).pipe(
      take(1)
    );

    this.params$ = this.facilityQuantities$.pipe(
      map(quantities => this.getParams(quantities)),
      shareReplay(1)
    );

    this.notes$ = this.params$.pipe(
      take(1),
      switchMap(params => this.reportEventService.getEvents([this.facilityId], params)),
      shareReplay(1)
    );

    this.quantityData$ = combineLatest({
      params: this.params$,
      threshold: this.thresholdService.threshold$
    }).pipe(
      switchMap(({ params, threshold }) => this.getFacilityValues(params, threshold))
    );

    this.actionDefaultQuantity$ = this.facilityQuantities$.pipe(
      map(quantities => quantities[0]),
      shareReplay(1)
    );
  }

  public ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      this.widthChange$.next(entries[0].target.clientWidth);
    });

    this.resizeObserver.observe(this.gridContainer.nativeElement);
  }

  public ngOnDestroy(): void {
    this.resizeObserver?.unobserve(this.gridContainer.nativeElement);
    this.widthChange$.complete();
  }

  public moveToReport(dataItem: ReportSeriesDataPoint): void {
    if (!this.hasServiceLevelM) {
      return;
    }
    this.params$.pipe(
      take(1)
    ).subscribe({
      next: params => {
        this.reportingSearchService.search({
          ...params.formValue,
          quantityIds: [dataItem.quantityId]
        });
        this.reportModalService.moveToReportOfType(ReportType.Period);
      }
    });
  }

  public changeCardSize(item: GridsterItemComponentInterface): void {
    const expanded = !(item.item as OverviewQuantity)._expanded$.value;
    this.resizeCard(item, expanded);
    this.autoPosition();
  }

  public expandAll(): void {
    this.gridster.grid.forEach(item => {
      this.resizeCard(item, true);
    });
    this.autoPosition();
  }

  public collapseAll(): void {
    this.gridster.grid.forEach(item => {
      this.resizeCard(item, false);
    });
    this.autoPosition();
  }

  private resizeCard(item: GridsterItemComponentInterface, expanded: boolean): void {
    const size = expanded ? 2 : 1;
    item.$item.rows = size;
    item.$item.cols = size;
    item.item._expanded$.next(expanded);
  }

  private autoPosition(): void {
    this.gridster.grid.forEach(item => {
      item.$item.x = 100000;
      item.$item.y = 100000;
      // Don't ever set cols to larger than gridsters maxCols, that freezes browser
      item.$item.cols = Math.min(item.$item.rows, this.gridOptions.maxCols);
    });
    this.gridster.grid.forEach(item => {
      this.gridster.autoPositionItem(item);
      item.checkItemChanges(item.$item, item.item);
    });
  }

  private getParams(quantities: number[]): ReportingSearchParams {
    return new ReportingSearchParams({
      ...this.reportingSearchService.getDefaultParams(),
      ...this.duration,
      periods: [
        new Date(this.currentYear, 0, 1),
        new Date(this.currentYear - 1, 0, 1),
      ],
      quantityIds: quantities,
      comparability: Comparability.ByQuantity,
      resolution: RequestResolution.P1M,
      emissionIds: [RelationalValueId.co2Factor]
    });
  }

  private getFacilityValues(
    params: ReportingSearchParams,
    incompleteThreshold: number
  ): Observable<OverviewQuantity[]> {
    return this.overviewReportService.getData(params, [this.facilityId], incompleteThreshold).pipe(
      map(data => data[this.facilityId].mapFilter(
        s => this.handleQuantityValues(s, incompleteThreshold),
        q => q
      ))
    );
  }

  private handleQuantityValues(
    quantityData: ReportingSeriesCollection,
    threshold: number
  ): OverviewQuantity {
    const inspectionPeriod = quantityData.inspectionPeriod;
    if (!inspectionPeriod) {
      return null;
    }

    const comparisonPeriod = quantityData.series.find(s => s.options.isComparisonPeriod);

    const latestMonth = inspectionPeriod.values[inspectionPeriod.values.length - 1];
    const comparisonMonth = comparisonPeriod?.values[comparisonPeriod.values.length - 1];

    const inspectionYearHasIncompleteValues = inspectionPeriod.values.some(
      v => this.isIncomplete(v.incomplete, threshold)
    );
    const comparisonYearHasIncompleteValues = comparisonPeriod?.values.some(
      v => this.isIncomplete(v.incomplete, threshold)
    );

    const expanded$ = new BehaviorSubject(false);

    return {
      quantityId: quantityData.quantityId,
      monthData: {
        difference: comparisonMonth?.absoluteChange,
        differencePercent: comparisonMonth?.relativeChange,
        value: latestMonth.value,
        costData: null,
        emissionData: this.getEmissionDataForGridster(quantityData.series, DataType.Month),
        isIncomplete: this.isIncomplete(latestMonth.incomplete, threshold),
        showDiff: ![latestMonth, comparisonMonth].some(v => this.isIncomplete(v?.incomplete, threshold))
      },
      yearData: {
        difference: quantityData.absoluteChange,
        differencePercent: quantityData.relativeChange,
        value: inspectionPeriod.aggregatedValue,
        costData: null,
        emissionData: this.getEmissionDataForGridster(quantityData.series, DataType.Year),
        isIncomplete: inspectionYearHasIncompleteValues,
        showDiff: !(inspectionYearHasIncompleteValues || comparisonYearHasIncompleteValues),
      },
      series: quantityData.series,
      unit: quantityData.unit,
      x: 0,
      y: 0,
      cols: 1,
      rows: 1,
      expanded$: expanded$.pipe(
        switchMap(
          exp => exp
            ? of(exp).pipe(delay(300))
            : of(exp)
        )
      ),
      _expanded$: expanded$,
    };
  }

  private isIncomplete(incomplete: number, threshold: number): boolean {
    return incomplete > threshold;
  }

  private getEmissionDataForGridster(series: ReportingSeries[], dataType: string): OverviewValue | null {
    const inspectionPeriod = series.find(s =>
      s.options.isInspectionPeriod && s.chartItemOptions.derivedId === RelationalValueId.co2Factor);
    if (!inspectionPeriod) {
      return null;
    }

    const comparisonPeriod = series.find(s =>
      s.options.isComparisonPeriod && s.chartItemOptions.derivedId === RelationalValueId.co2Factor);

    const latestMonth = inspectionPeriod.values[inspectionPeriod.values.length - 1];
    const comparisonMonth = comparisonPeriod?.values[comparisonPeriod.values.length - 1];
    const value = dataType === DataType.Month ? latestMonth.value : inspectionPeriod.aggregatedValue;
    return value > 0
      ? {
        value: value,
        difference: dataType === DataType.Month ? comparisonMonth?.absoluteChange : latestMonth?.absoluteChange,
        differencePercent: dataType === DataType.Month ? comparisonMonth?.relativeChange : latestMonth?.relativeChange,
      }
      :
      null;
  }
}
