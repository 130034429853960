import _ from 'lodash';

import { METER_TREND, METER_TREND_SUM } from '../../constants/er-modal-states.constant';
import EnergyReportingConstants from '../../constants/report-constants';

const $inject = ['$scope', '$state', 'ReportSettingService', 'utils', 'erReportSettingsService'];

function ReportTrendSettingsController($scope, $state, ReportSettingService, utils, erReportSettingsService) {
  const vm = this; // eslint-disable-line @typescript-eslint/no-this-alias
  const erReportSettings = erReportSettingsService.getInstance();

  // settings
  $scope.params = erReportSettings.getSettings();
  $scope.settings = {
    types: ReportSettingService.getSettingsTypes(),
    timeFrames: ReportSettingService.getTimeFrames()
  };

  const settingType = $scope.settings.types[_.includes(getStateName(), 'trend-sum') ? 1 : 0];
  settingType.active = true;

  checkSelectedTimeFrame();

  function getStateName() {
    return erReportSettings.getSettings().name || $state.$current.name;
  }

  function getRolling36MonthsTimeSeries() {
    // time frame
    const timeFrame = 'P3Y';

    // figure out start
    const from = new Date();
    from.setHours(0, 0, 0, 0);
    from.setDate(1);
    from.setFullYear(from.getFullYear() - 3);

    return {
      ShowComparison: false,
      TimeFrame: timeFrame,
      Resolution: 'P1M',
      Start: [
        {
          key: utils.durationToString(from, timeFrame),
          value: utils.dateToUtcDate(from)
        }
      ]
    };
  }

  function isRolling() {
    const rollingTimeSeries = getRolling36MonthsTimeSeries();
    return _.isEqual(
      _.map(_.get($scope.params, 'series.Start'), 'key'),
      _.map(_.get(rollingTimeSeries, 'Start'), 'key')
    ) &&
      _.isEqual(
        _.pick($scope.params.series, ['TimeFrame', 'Resolution', 'ShowComparison']),
        _.pick(rollingTimeSeries, ['TimeFrame', 'Resolution', 'ShowComparison'])
      )
    ;
  }

  function checkSelectedTimeFrame() {
    $scope.settings.timeFrames = _.map($scope.settings.timeFrames, timeFrame => {
      timeFrame.active = false;
      timeFrame.disabled = false;
      return timeFrame;
    });

    const currentTimeSettings = _.find($scope.settings.timeFrames, { key: 'current_time_settings' });
    if (currentTimeSettings) {
      const rolling = isRolling();
      const setting = !rolling ? currentTimeSettings : _.find($scope.settings.timeFrames, { key: 'rolling_36_months' });

      setting.active = true;

      if (rolling) {
        currentTimeSettings.disabled = true;
      }
    }
  }

  function changeModalReportType(selectedType) {
    let reportType;
    switch (selectedType.key) {
      case EnergyReportingConstants.SettingType.BySum:
        reportType = METER_TREND_SUM;
        break;
      case EnergyReportingConstants.SettingType.ByObject:
        reportType = METER_TREND;
        break;
    }
    if (reportType) {
      vm.reportTypeChanged({ reportType: reportType });
    }
  }

  $scope.onSelectedTimeFrameChanged = function(selectedTimeFrame) {
    if (selectedTimeFrame.key === 'rolling_36_months') {
      const currentSeries = _.cloneDeep($scope.params.series) || {};
      const newParamValue = _.extend(currentSeries, getRolling36MonthsTimeSeries());
      erReportSettings.changeSetting('series', newParamValue);
    }
  };

  $scope.onSelectedTypeChanged = function(selectedType) {
    if (_.isFunction(vm.reportTypeChanged)) {
      changeModalReportType(selectedType);
    } else {
      ReportSettingService.changeStateBySettingType(selectedType, 'trend');
    }
  };

  const seriesChangeUnbind = erReportSettings.setCallback(() => {
    checkSelectedTimeFrame();
  }, 'series');

  vm.$onDestroy = seriesChangeUnbind;
}

ReportTrendSettingsController.$inject = $inject;

export default ReportTrendSettingsController;
