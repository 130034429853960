import template from 'raw-loader!./manual-qa-model-preview.html';

const ManualQaModelPreviewComponent = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    isPreviewMode: '<'
  }
};

export default ManualQaModelPreviewComponent;
