import { Pipe, PipeTransform } from '@angular/core';

import { GriEditorRow } from '../models/gri-report-row';

@Pipe({ name: 'griHasImportedRows' })
export class GriHasImportedRowsPipe implements PipeTransform {

  public transform(rows: GriEditorRow[]): boolean {
    return rows.filter(row => row?.metadata?.consumptions).length > 0;
  }

}
