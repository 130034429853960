import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, Observable, Subject, switchMap, take, takeUntil } from 'rxjs';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { IPutReport, IReport, PutReport, SustainabilityClient } from '@enerkey/clients/sustainability';

import { ProfileService } from '../../../../shared/services/profile.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { DialogService } from '../../../../shared/services/dialog.service';

type ReportForm = { [K in keyof IPutReport]-?: FormControl<IPutReport[K]> };

@Component({
  selector: 'gri-report-editor-modal',
  templateUrl: './gri-report-editor-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'tiny' })
export class GriReportEditorModalComponent extends ModalBase<number> implements OnInit, OnDestroy {

  public existingReport: IReport;

  public readonly formGroup: FormGroup<ReportForm>;

  public readonly loading$: Observable<boolean>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly susClient: SustainabilityClient,
    private readonly profileService: ProfileService,
    private readonly toaster: ToasterService,
    private readonly dialogService: DialogService
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();

    this.formGroup = new FormGroup<ReportForm>({
      description: new FormControl<string>('', Validators.maxLength(200)),
      year: new FormControl<number>(
        new Date().getFullYear(),
        [Validators.required, Validators.min(1900), Validators.max(3000)]
      ),
    });
  }

  public ngOnInit(): void {
    if (this.existingReport) {
      this.formGroup.patchValue({
        description: this.existingReport.description,
        year: this.existingReport.year,
      });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public deleteReport(): void {
    this.dialogService.getConfirmationModal({
      title: 'CONFIRM_DELETE',
      text: 'SUSTAINABILITY.GRI.CONFIRM_REPORT_DELETION',
      isDelete: true,
      translate: true
    }).subscribe({
      next: () => {
        this.susClient.deleteReport(
          this.existingReport.profileId,
          this.existingReport.id
        ).subscribe({
          next: (reportId: number) => {
            this.toaster.success(null, 'SUSTAINABILITY.GRI.REPORT_DELETION_SUCCEEDED');
            super.closeModal(reportId);
          },
          error: () => {
            this.toaster.error(null, 'SUSTAINABILITY.GRI.REPORT_DELETION_FAILED');
          },
        });
      }
    });
  }

  public submit(): void {
    if (!this.formGroup.valid) {
      return;
    }

    let request$: Observable<number>; // emits report ID

    const payload = new PutReport({ ...this.formGroup.value });

    if (this.existingReport) {
      request$ = this.susClient.updateReport(
        this.existingReport.profileId,
        this.existingReport.id,
        payload
      ).pipe(map(() => this.existingReport.id));
    } else {
      request$ = this.profileService.profileId$.pipe(
        take(1),
        switchMap(profileId => this.susClient.createReport(profileId, payload))
      );
    }

    request$.pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$)
    ).subscribe({
      next: reportId => {
        this.toaster.success('SUSTAINABILITY.GRI.SAVE_SUCCEEDED');
        super.closeModal(reportId);
      },
      error: /* istanbul ignore next */ () => {
        this.toaster.error('SUSTAINABILITY.GRI.SAVE_FAILED');
      },
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

}
