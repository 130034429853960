import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { DropDownFilterSettings, DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { formGroupFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { SettingsClient } from '@enerkey/clients/settings';
import { SimpleProfileViewModel, UserManagementClient } from '@enerkey/clients/user-management';

import { UserService } from '../../services/user-service';
import { ProfileService } from '../../shared/services/profile.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { Roles } from '../../modules/admin/constants/roles';

interface ProfileSearchParams {
  enegiaId: number;
  profileName: string;
  showSingleFacilityProfiles: boolean;
}

function profileSearchParamValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    const value = control.value;
    return (value.enegiaId || value.profileName) ? null : { requiredValue: true };
  };
}

@Component({
  selector: 'profile-change-modal',
  templateUrl: './profile-change-modal.component.html',
  styleUrls: ['./profile-change-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileChangeModalComponent extends ModalBase<void> implements OnDestroy {
  @ViewChild(DropDownListComponent) public profilesDropdown: DropDownListComponent;

  public readonly profiles$: Observable<SimpleProfileViewModel[]>;
  public readonly loading$: Observable<boolean>
  public readonly lastTenProfiles$: Observable<SimpleProfileViewModel[]>;
  public readonly lastTenProfilesLoading$: Observable<boolean>
  public readonly filterProfiles$: Observable<boolean>;

  public readonly profileFilterForm: UntypedFormGroup;

  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public readonly hasAccessToAllProfiles: boolean;
  public readonly currentProfileId: number;
  public readonly currentProfileName: string;

  public selectedProfileId: number = null;

  private readonly _filterProfiles$ = new BehaviorSubject<boolean>(false);
  private readonly _loading$ = new LoadingSubject(false);
  private readonly _lastTenProfilesLoading$ = new LoadingSubject(true);
  private readonly _destroy$ = new Subject<void>();
  private readonly _loadAllProfiles$ = new Subject<void>();

  private readonly _collator = new Intl.Collator(undefined, {
    numeric: false,
    sensitivity: 'base',
    usage: 'sort',
  });

  private get defaultFormValue(): ProfileSearchParams {
    return { // getter as formcontrol mutates the values
      enegiaId: null,
      profileName: null,
      showSingleFacilityProfiles: true
    };
  }

  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly profileService: ProfileService,
    private readonly toasterService: ToasterService,
    public readonly userService: UserService,
    settingsClient: SettingsClient,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);

    this.profileFilterForm = formGroupFrom<ProfileSearchParams>(this.defaultFormValue);
    this.profileFilterForm.setValidators(profileSearchParamValidator());

    this.loading$ = this._loading$.asObservable();
    this.lastTenProfilesLoading$ = this._lastTenProfilesLoading$.asObservable();
    this.filterProfiles$ = this._filterProfiles$.asObservable();

    this.hasAccessToAllProfiles = this.userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES);
    this.currentProfileId = this.userService.profileId;
    this.currentProfileName = this.userService.getCurrentProfile().name;

    this.lastTenProfiles$ = settingsClient.getProfileHistory().pipe(
      takeUntil(this._destroy$),
      switchMap(lastTenProfiles => this.userManagementClient.getProfilesIfAccessibleToUser(lastTenProfiles).pipe(
        map(profiles => profiles
          .filter(p => p.id !== this.currentProfileId)
          .sortBy(p => lastTenProfiles.findIndex(profileId => profileId === p.id)))
      )),
      indicate(this._lastTenProfilesLoading$),
      catchError(() => {
        this.toasterService.error('FAILED_TO_LOAD_ALL_LAST_USED_PROFILE');
        return of([]);
      })
    );

    const allProfiles$ = this._loadAllProfiles$.pipe(
      take(1),
      switchMap(() => this.userManagementClient.getUserProfiles().pipe(
        indicate(this._loading$),
        takeUntil(this._destroy$)
      )),
      map(profiles => this.sortProfilesByName(profiles)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.profiles$ = this._filterProfiles$.pipe(
      tap(isFiltered => {
        if (isFiltered) {
          this.profileFilterForm.disable();
        } else {
          this.profileFilterForm.enable();
          this.profileFilterForm.setValue(this.defaultFormValue);
        }
      }),
      switchMap(isFiltered => isFiltered ? this.getFilteredProfiles() : allProfiles$),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._filterProfiles$.complete();
    this._loading$.complete();
    this._lastTenProfilesLoading$.complete();
    this._loadAllProfiles$.complete();
  }

  public changeProfile(profileId: number): void {
    if (profileId === this.userService.profileId) {
      super.dismissModal();
      return;
    }

    this.userManagementClient.getProfile(profileId).pipe(indicate(this._loading$)).subscribe({
      next: profile => {
        this.profileService.changeProfile(profile);
        super.closeModal();
      },
      error: () => {
        this.toasterService.error('SETTINGS.ERROR_CHANGING_PROFILE_NOTIFICATION');
      }
    });
  }

  public filterProfiles(): void {
    this._filterProfiles$.next(true);
  }

  public clearProfileFilters(): void {
    this.profilesDropdown.toggle(false);
    this._filterProfiles$.next(false);
  }

  public loadProfiles(): void {
    this._loadAllProfiles$.next();
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private getFilteredProfiles(): Observable<SimpleProfileViewModel[]> {
    const params: ProfileSearchParams = this.profileFilterForm.value;

    return this.userManagementClient.getAllProfiles(
      params.profileName ?? undefined,
      undefined,
      undefined,
      params.enegiaId ?? undefined,
      undefined,
      !params.showSingleFacilityProfiles
    ).pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$),
      map(profiles => this.sortProfilesByName(profiles)),
      catchError(() => of([])),
      finalize(() => this.profilesDropdown.toggle(true))
    );
  }

  private sortProfilesByName<T extends { name?: string }>(profiles: T[]): T[] {
    return profiles.sort((a, b) => this._collator.compare(a.name, b.name));
  }
}
