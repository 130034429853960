import { add, format, formatISODuration, getQuarter, subDays } from 'date-fns';

export function durationToString(
  startDate: Date,
  duration: Duration,
  index: number,
  longFormat = false
): string {
  const endDate = subDays(add(startDate, duration), 1);
  if (duration.years) {
    if (duration.years === 1) {
      return startDate.getMonth() === 0
        ? startDate.getFullYear().toString()
        : `${format(startDate, 'M/yy')} - ${format(endDate, 'M/yy')}`;
    }
    const endYear = startDate.getFullYear() + duration.years - 1;
    return `${startDate.getFullYear()} - ${endYear}`;
  }
  if (duration.months) {
    if (startDate.getDate() === 1) {
      if (duration.months === 1) {
        return longFormat ? format(startDate, 'M/yy') : (startDate.getMonth() + 1).toString();
      } else if (duration.months === 3 && (startDate.getMonth() % 3) === 0) {
        const quarter = getQuarter(startDate);
        const quarterInfo = longFormat ? `${quarter}/${format(startDate, 'yy')}` : quarter;
        return `Q${quarterInfo}`;
      } else {
        return `${format(startDate, 'M/yy')} - ${format(endDate, 'M/yy')}`;
      }
    }
    return `${format(startDate, 'd.M.yy')} - ${format(endDate, 'd.M.yy')}`;
  }
  if (duration.weeks) {
    return `${format(startDate, 'd.M.yy')} - ${format(endDate, 'd.M.yy')}`;
  }
  if (duration.days) {
    if (!longFormat) {
      return startDate.getDate().toString();
    }
    return duration.days === 1
      ? `${format(startDate, 'd.M.yy')}`
      : `${format(startDate, 'd.M.yy')} - ${format(endDate, 'd.M.yy')}`;
  }

  return (index + 1).toString();
}

export function durationToISOString(duration: Duration): string {
  if (duration.years) {
    return `P${duration.years}Y`;
  }
  if (duration.months) {
    return `P${duration.months}M`;
  }
  if (duration.days) {
    return `P${duration.days}D`;
  }
  return formatISODuration(duration);
}
