export default {
  SettingType: {
    BySum: 'by_sum',
    ByObject: 'by_object'
  },
  ColumnTypeOrder: { // Affects on report grid column order
    ReadingColumn: 1,
    CompareColumn: 1, // Should be same with reading column
    TrendCompareColumns: 2,
    DistributionTrendCompareColumn: 3,
    RelationalValueColumn: 4,
    DistributionColumns: 5,
    DistributionCompareColumns: 6,
    RelatedValueColumn: 7,
    TemperatureColumn: 8
  },
  Opacity: {
    Normal: 0.85,
    Light: 0.60
  },
  SerieTypes: {
    Area: 'area'
  },
  Defaults: {
    Series: {
      DashType: 'solid',
      Style: 'normal',
      Width: 1.35
    }
  },
  Dimensions: {
    chartHeight: {
      low: 350,
      high: 500,
      print: 220 // Coupled with graph resizing in `src/app/app.js`
    },
    gridColumn: {
      value: {
        narrow: 90,
        wide: 115
      },
      compare: {
        narrow: 70,
        wide: 90
      }
    }
  }
};
