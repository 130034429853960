<modal-view-header [headingText]="'TOPBAR.SETTINGS' | translate"></modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
>
  <form
    *ngIf="formGroup"
    [formGroup]="formGroup"
    class="bold-labels"
  >
    <fieldset class="fieldset">
      <legend>{{ 'SETTINGS.USER_SETTINGS' | translate }}</legend>
      <p>
        <language-select
          *labelBefore="'SETTINGS.LANGUAGE' | translate"
          formControlName="locale"
        ></language-select>
      </p>
      <p *ngIf="showReportingOptions$ | async">
        <ek-combo
          *labelBefore="'SETTINGS.TIME_SERIES_TYPE' | translate"
          formControlName="timeSeriesTypeId"
          [items]="timeSeriesOptions"
        ></ek-combo>
      </p>
    </fieldset>

    <fieldset
      *ngIf="showReportingOptions$ | async"
      class="fieldset"
    >
      <legend>{{ 'SETTINGS.PROFILE_SETTINGS_GROUP_HEADER' | translate }}</legend>

      <p>
        <span *labelBefore="'SETTINGS.CURRENT_PROFILE' | translate">
          {{ profileName$ | async }}
        </span>
      </p>

      <p>
        <kendo-numerictextbox
          *labelBefore="'SETTINGS.THRESHOLD' | translate"
          formControlName="threshold"
          [autoCorrect]="true"
          [decimals]="3"
          [format]="'p1'"
          [spinners]="false"
          [step]="0.005"
          [min]="0"
          [max]="1"
        ></kendo-numerictextbox>
      </p>
    </fieldset>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="button"
      [loading]="loading$ | async"
      (click)="submit()"
    >
      {{ 'OK' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
