import { downgradeInjectable } from '@angular/upgrade/static';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import 'ngtouch';

import EnerKeyServicesModule from '../../services';
import EnerKeyComponentsModule from '../../components';
import KendoModule from '../kendo';

import AdminComponentsModule from './components';
import AdminServicesModule from './services';

import config from './config';
import { UserManagementClient } from '@enerkey/clients/user-management';

export default angular.module('app.modules.admin', [
  'ui.router',
  'pascalprecht.translate',
  'kendo.directives',
  'ngCookies',
  'jquery',
  AdminServicesModule.name,
  AdminComponentsModule.name,
  EnerKeyServicesModule.name,
  EnerKeyComponentsModule.name,
  KendoModule.name
])
  .factory('userManagementClient', downgradeInjectable(UserManagementClient))
  .config(config)
;
