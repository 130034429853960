import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { take } from 'rxjs/operators';
import { from, ReplaySubject } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';

import { QuantityService } from '../../../../shared/services/quantity.service';
import { QUANTITY_ICON_CLASSES, quantityGroupDefinitions } from '../../../../constants/quantity.constant';

type QuantityGroup = {
  readonly key: string;
  readonly quantities: Quantities[];
  readonly sumQuantities: Quantities[];
  selection: boolean;
  collapsed: boolean;
};

@Component({
  selector: 'sidebar-quantity-picker',
  templateUrl: './sidebar-quantity-picker.component.html',
  styleUrls: ['./sidebar-quantity-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SidebarQuantityPickerComponent),
    multi: true,
  }],
})
export class SidebarQuantityPickerComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @Input() public facilityId: number;

  public readonly iconClasses: Readonly<Record<number, string>> = QUANTITY_ICON_CLASSES;

  public disabled: boolean = false;

  public groupedQuantities: QuantityGroup[] = [];
  public states: Record<number, boolean> = {};

  private _profileQuantities: Quantities[] = [];
  private _quantitiesInitialized$ = new ReplaySubject<void>(1);

  private _onChange: (value: Quantities[]) => void;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly quantityService: QuantityService
  ) { }

  public ngOnInit(): void {
    const quantities$ = this.facilityId
      ? this.quantityService.getSignificantQuantitiesForFacility(this.facilityId)
      : this.quantityService.getSignificantQuantitiesForProfile()
    ;
    from(quantities$).pipe(take(1)).subscribe(result => {
      this._profileQuantities = result.map(item => item.ID);

      this.groupedQuantities = quantityGroupDefinitions
        .map(group => ({
          key: group.title,
          quantities: group.quantityIds.filter(q => this._profileQuantities.includes(q)),
          sumQuantities: group.sumQuantityIds.filter(q => this._profileQuantities.includes(q)),
          selection: false,
          collapsed: true
        }))
        .filter(group => group.quantities.hasItems());

      if (Array.hasItems(this.groupedQuantities)) {
        this.groupedQuantities[0].collapsed = false;
      }

      this._quantitiesInitialized$.next();

      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this._quantitiesInitialized$.complete();
  }

  public toggled(quantity: Quantities): void {
    this.runOnChange(this.findChangedGroup(quantity));
  }

  public groupToggled(group: QuantityGroup): void {
    for (const quantity of mergeIter(group.quantities, group.sumQuantities)) {
      this.states[quantity] = group.selection;
    }

    this.runOnChange(group);
  }

  public writeValue(value: Quantities[] | null): void {
    if (!value) {
      value = [];
    }

    this._quantitiesInitialized$.pipe(take(1)).subscribe(() => {
      for (const quantity of this._profileQuantities) {
        this.states[quantity] = value.includes(quantity);
      }

      this.groupedQuantities.forEach(group => {
        this.runOnChange(group, true);
      });

      this.changeDetector.detectChanges();
    });
  }

  public registerOnChange(fn: (value: Quantities[]) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled = !!isDisabled;
    if (this.disabled !== isDisabled) {
      this.disabled = isDisabled;
    }
  }

  private findChangedGroup(quantity: Quantities): QuantityGroup {
    const isSumQuantity = quantity >= 1000;

    return this.groupedQuantities.find(
      g => isSumQuantity ? g.sumQuantities.includes(quantity) : g.quantities.includes(quantity)
    );
  }

  /**
   * @param onlySelf when true, changeDetection is not triggered and controlValueAccessor onChange is not called
   * Should be used when updating values for multiple groups and detectChanged and onChange are called manually
   */
  private runOnChange(
    group: QuantityGroup,
    onlySelf: boolean = false
  ): void {
    let count = 0;

    for (const quantity of mergeIter(group.quantities, group.sumQuantities)) {
      if (this.states[quantity]) {
        count++;
      }
    }

    if (count === 0) {
      group.selection = false;
    } else if (count === (group.quantities.length + group.sumQuantities.length)) {
      group.selection = true;
    } else {
      group.selection = undefined;
    }

    if (onlySelf) {
      return;
    }

    this._onChange?.(this._profileQuantities.filter(q => !!this.states[q]));
    this.changeDetector.detectChanges();
  }
}

function* mergeIter<T>(...iterables: Iterable<T>[]): Iterable<T> {
  for (const iterable of iterables) {
    for (const item of iterable) {
      yield item;
    }
  }
}
