import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ServiceLevel } from '@enerkey/clients/facility';

import { Service } from '../../constants/service';
import { AppStatusService } from '../../services/app-status-service';
import { EnvironmentService } from '../../services/environment-service';
import { TopbarService } from '../../services/topbar.service';
import { UserService } from '../../services/user-service';
import { LanguageChangeService } from '../../shared/services/language-change.service';
import { ProfileService } from '../../shared/services/profile.service';
import { ServiceLevelService } from '../../shared/services/service-level.service';
import { TenantService } from '../../shared/services/tenant.service';
import { WindowService } from '../../shared/services/window.service';
import { TopbarTabComponent } from '../topbar-tab/topbar-tab.component';
import { getVisibleAndOverflowTabs, TopbarState } from './topbar.functions';

@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarComponent implements AfterViewInit {
  @HostBinding('class.no-print') public readonly noPrintClass = true;
  @ViewChildren('hiddenTab') public readonly tabs: QueryList<TopbarTabComponent>;
  @ViewChild('overflowButton', { read: ElementRef }) public readonly overflowButton: ElementRef<HTMLElement>;
  @ViewChild('tabContainer', { read: ElementRef }) public readonly tabContainer: ElementRef<HTMLElement>;
  @ViewChild('mobileTopbarOverflow', { read: ElementRef }) public readonly mobileTopbarOverflow: ElementRef;

  public readonly logoTitle: string;
  public readonly isDev: boolean;

  public readonly tabs$: Observable<TopbarState[]>;
  public readonly visibleTabs$: Observable<TopbarState[]>;
  public readonly overflowTabs$: Observable<TopbarState[]>;
  public readonly isMobile$: Observable<boolean>;
  public readonly mobileTopbarVisible$: Observable<boolean>;

  public readonly showFacilityFilter$: Observable<boolean>;
  public readonly hasServiceLevelMedium$: Observable<boolean>;

  private readonly _mobileTopbarVisible$ = new BehaviorSubject<boolean>(false);
  private readonly _visibleTabs$ = new BehaviorSubject<TopbarState[]>([]);
  private readonly _overflowTabs$ = new BehaviorSubject<TopbarState[]>([]);

  public constructor(
    topbarService: TopbarService,
    tenantService: TenantService,
    profileService: ProfileService,
    serviceLevelService: ServiceLevelService,
    userService: UserService,
    environmentService: EnvironmentService,
    appStatusService: AppStatusService,
    private readonly windowService: WindowService,
    private readonly languageChangeService: LanguageChangeService
  ) {
    this.isMobile$ = appStatusService.isMobileWidth$;

    this.isDev = environmentService.isDevelopment();
    this.logoTitle = tenantService.tenantTitle;

    this.tabs$ = topbarService.tabs$;

    this.hasServiceLevelMedium$ = profileService.profile$.pipe(
      map(() => serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium)),
      shareReplay(1)
    );

    this.showFacilityFilter$ = profileService.profile$.pipe(
      map(
        () => userService.hasService(Service.EnergyReporting)
          && serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Large),
        shareReplay(1)
      )
    );

    this.mobileTopbarVisible$ = this._mobileTopbarVisible$.asObservable();
    this.visibleTabs$ = this._visibleTabs$.asObservable();
    this.overflowTabs$ = this._overflowTabs$.asObservable();

    this.isMobile$.subscribe(() => {
      // Make sure that mobileTopbar is collapsed when mobile state changes
      this._mobileTopbarVisible$.next(false);
    });
  }

  @HostListener('click', ['$event.target'])
  public topbarClick(targetElement: ElementRef): boolean {
    if (this.mobileTopbarOverflow?.nativeElement.contains(targetElement)) {
      this._mobileTopbarVisible$.next(false);
    }
    // Return true to not prevent event default
    return true;
  }

  public ngAfterViewInit(): void {
    merge(
      this.tabs.changes,
      this.windowService.resize$,
      this.languageChangeService.languageChange
    ).subscribe(() => {
      const { visibleTabs, overflowTabs } = getVisibleAndOverflowTabs(
        this.tabContainer?.nativeElement.clientWidth ?? 0,
        this.overflowButton?.nativeElement.clientWidth ?? 0,
        this.tabs.toArray().map(tab => ({ width: tab.el.nativeElement.clientWidth, state: tab.state }))
      );
      this._visibleTabs$.next(visibleTabs);
      this._overflowTabs$.next(overflowTabs);
    });
  }

  public toggleTopbar(): void {
    this._mobileTopbarVisible$.next(!this._mobileTopbarVisible$.value);
  }
}
