import { hasChanges } from '@enerkey/angular-utils';

const $inject = ['TimeFrameRadioService'];

function TimeFrameRadioController(TimeFrameRadioService) {
  let vm = this;

  vm.timeFrames = TimeFrameRadioService.getTimeFrames();

  vm.$onInit = onInit;
  vm.$onChanges = onChanges;
  vm.getCustomTemplate = getCustomTemplate;

  function onInit() {
    vm.dynamicTimeFrame = angular.copy(vm.dynamicTimeFrame);
    vm.fixedTimeFrame = angular.copy(vm.fixedTimeFrame);
  }

  function onChanges(changes) {
    if (hasChanges(changes, 'dynamicTimeFrame')) {
      vm.dynamicTimeFrame = angular.copy(vm.dynamicTimeFrame);
    }

    if (hasChanges(changes, 'fixedTimeFrame')) {
      vm.fixedTimeFrame = angular.copy(vm.fixedTimeFrame);
    }
  }

  function getCustomTemplate(option) {
    return option.customTemplate ? option.customTemplate : '';
  }
}

TimeFrameRadioController.$inject = $inject;

export default TimeFrameRadioController;
