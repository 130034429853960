import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EmailSendStatus } from '../components/email-send-modal/email-send-modal.component';
import { assertUnreachable } from '@enerkey/ts-utils';

@Pipe({
  name: 'emailResult'
})
export class EmailResultPipe implements PipeTransform {
  public constructor(private translateService: TranslateService) {
  }

  public transform(value: EmailSendStatus): string {
    const translationKey = this.getEmailStatusTranslationKey(value);
    return this.translateService.instant(translationKey);
  }

  private getEmailStatusTranslationKey(value: EmailSendStatus): string {
    switch (value) {
      case EmailSendStatus.Success:
        return 'MQA.EMAIL.STATUS.SUCCESS';
      case EmailSendStatus.Fail:
        return 'MQA.EMAIL.STATUS.FAIL';
      case EmailSendStatus.Skip:
        return 'MQA.EMAIL.STATUS.SKIP';
      default:
        assertUnreachable(value);
    }
  }
}
