import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AxisLabelContentArgs,
  CategoryAxisItemComponent,
  ChartComponent,
  SeriesLabels,
  SeriesLabelsContentArgs,
  ZoomEndEvent
} from '@progress/kendo-angular-charts';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { groupBy, GroupDescriptor, GroupResult } from '@progress/kendo-data-query';
import { CldrIntlService } from '@progress/kendo-angular-intl';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { TranslateService } from '@ngx-translate/core';

import { getStringEnumValues } from '@enerkey/ts-utils';
import { ICategory, IRowUnit, Report, Scope } from '@enerkey/clients/sustainability';

import { GriScopeColorPipe } from '../../pipes/gri-scope-color.pipe';
import { GRI_VALUE_FIELDS, IGriEditorRow, tonnesEquivalent } from '../../models/gri-report-row';
import { GriReportService } from '../../services/gri-report.service';
import { GriScopeNamePipe } from '../../pipes/gri-scope-name.pipe';
import { MonthNamePipe } from '../../../../shared/common-pipes/month-name.pipe';
import { GriChartsTotalsDonutComponent } from '../gri-charts-totals-donut/gri-charts-totals-donut.component';

export class ChartModel {
  public readonly value: number;
  public readonly category: ICategory;
  public readonly unit: IRowUnit;
  public readonly scope: Scope;
  public readonly description: string;

  public readonly categoryName: string;
  public readonly color: string;
  public readonly monthlyValues?: number[];
  public readonly month?: number;

  public constructor(
    row: IGriEditorRow,
    color: string
  ) {
    this.description = row.description;
    this.value = row.co2total;
    this.category = row.category;
    this.categoryName = row.category.name;
    this.scope = row.category.scope;
    this.unit = row.unit;
    this.color = color;
    this.monthlyValues = GRI_VALUE_FIELDS.map(key => {
      const factor = row.unit?.co2Factor;
      const value = row[key] ?? null;
      return value && Number.isFinite(factor) ? (factor * value / tonnesEquivalent(row.unit?.co2Eq)) : value;
    });
  }
}

type GridRow = { scope: string, category: string, value: number };

@Component({
  selector: 'gri-charts',
  templateUrl: './gri-charts.component.html',
  styleUrls: ['./gri-charts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GriChartsComponent implements OnDestroy {

  public readonly scopes = getStringEnumValues(Scope);

  @Input() public report: Report;

  @ViewChild(CategoryAxisItemComponent)
  public categoryAxisItemComponent: CategoryAxisItemComponent;

  @ViewChild(ChartComponent) public readonly chart: ChartComponent;
  @ViewChild(GriChartsTotalsDonutComponent) public readonly totalsDonut: GriChartsTotalsDonutComponent;
  @ViewChild(PDFExportComponent) public readonly pdfExportComponent: PDFExportComponent;

  public readonly scopeFormGroup: FormGroup<Record<Scope, FormControl<boolean>>>;
  public readonly optionsFormGroup = new FormGroup({
    period: new FormControl('fullYear'),
    grouping: new FormControl('scope')
  });

  public readonly rows$: Observable<ChartModel[]>;
  public readonly monthly$: Observable<GroupResult[]>;
  public readonly allRows$: Observable<ChartModel[]>;
  public readonly showMonthly$: Observable<boolean>;
  public readonly grouping$: Observable<string>;

  public readonly gridData$: Observable<GridRow[]>;
  public readonly valueFormat: string = '#,# tCO2e';
  public readonly showByScope$: Observable<boolean>;
  public gridGrouping: GroupDescriptor[];

  public readonly showTotal$: Observable<boolean>;
  public readonly showTotalControl: FormControl<boolean> = new FormControl(false);

  public readonly loading$: Observable<boolean>;

  public chartZoomed$ = new BehaviorSubject<boolean>(false);

  public seriesLabels: SeriesLabels = {
    visible: true,
    format: 'n0',
    padding: 2,
  };

  private readonly _zoomMax$: Observable<number>;
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly reportService: GriReportService,
    private readonly intlService: CldrIntlService,
    private readonly scopeNamePipe: GriScopeNamePipe,
    private readonly scopeColorPipe: GriScopeColorPipe,
    private readonly translate: TranslateService,
    private readonly monthNamePipe: MonthNamePipe
  ) {
    this.loading$ = this.reportService.loading$.pipe(takeUntil(this._destroy$));
    this.scopeFormGroup = new FormGroup(this.scopes.toRecord(s => s, () => new FormControl<boolean>(true)));

    this.allRows$ = this.reportService.rows$.pipe(
      map(rows => rows
        .mapFilter(r => new ChartModel(r, this.scopeColorPipe.transform(r.category?.scope)), m => !!m.value)
        .sortByMany('scope', ['value', 'desc'], 'categoryName')),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    const visibleScopes$ = this.scopeFormGroup.valueChanges.pipe(
      tap(() => this.resetZoom()),
      startWith(this.scopes.toRecord(s => s, () => true)),
      takeUntil(this._destroy$)
    );

    this.rows$ = combineLatest([visibleScopes$, this.allRows$]).pipe(
      map(([visibleScopes, rows]) => rows.filter(r => visibleScopes[r.scope])
        .map(r => ({
          ...r,
          categoryName: this.translate.instant(r.categoryName)
        }))),
      map(rows => this.handleDuplicateDescriptions(rows)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.grouping$ = this.optionsFormGroup.valueChanges.pipe(
      tap(() => this.resetZoom()),
      map(options => options.grouping),
      startWith('scope'),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.showByScope$ = this.grouping$.pipe(
      map(grouping => grouping === 'scope'),
      startWith(true),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.gridData$ = combineLatest([this.rows$, this.grouping$]).pipe(
      tap(([_, grouping]) => {
        grouping === 'scope'
          ? this.gridGrouping = undefined
          : this.gridGrouping = [{ field: 'scope', aggregates: [{ field: 'value', aggregate: 'sum' }] }];
      }),
      map(([rows, grouping]) => {
        let gridData: GridRow[] = [];
        if (grouping === 'scope') {
          gridData = rows.toGroupsBy('scope').getEntries().map(([scope, data]) => ({
            scope: this.scopeNamePipe.transform(scope, true),
            category: '',
            value: data.reduce((a, b) => a + b.value, 0)
          }));
        } else {
          gridData = rows.toGroupsBy('category').getEntries().map(([category, data]) => ({
            scope: this.scopeNamePipe.transform(category.scope, true),
            category: this.translate.instant(category.name),
            value: data.reduce((a, b) => a + b.value, 0)
          }));
        }
        return gridData;
      }),
      takeUntil(this._destroy$)
    );

    this.showTotal$ = this.showTotalControl.valueChanges.pipe(
      tap(show => show
        ? this.optionsFormGroup.controls.period.disable({ emitEvent: false })
        : this.optionsFormGroup.controls.period.enable({ emitEvent: false })),
      map(show => show),
      takeUntil(this._destroy$)
    );

    this.monthly$ = combineLatest([this.rows$, this.grouping$]).pipe(
      map(([rows, grouping]) => {
        const monthly: ChartModel[] = [];
        for (const row of rows) {
          for (let i = 0; i < 12; i++) {
            const data: ChartModel = {
              month: i,
              description: row.description,
              value: row.monthlyValues[i],
              category: row.category,
              categoryName: row.categoryName,
              scope: row.scope,
              unit: row.unit,
              color: row.color,
            };
            monthly.push(data);
          }
        }
        return groupBy(monthly, [{ field: grouping }]) as GroupResult[];
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.showMonthly$ = this.optionsFormGroup.valueChanges.pipe(
      map(options => options.period === 'monthly'),
      startWith(false),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this._zoomMax$ = combineLatest([this.showMonthly$, this.grouping$, this.rows$]).pipe(
      map(([showMonthly, grouping, rows]) => {
        if (showMonthly) {
          return 12;
        } else {
          switch (grouping) {
            case 'scope':
              return new Set(rows.map(r => r.scope)).size;
            case 'categoryName':
              return new Set(rows.map(r => r.categoryName)).size;
            case 'description':
              return rows.length;
          }
        }
      }),
      startWith(3),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public catAxisLabels = (e: AxisLabelContentArgs): string => {
    if (e.value >= 0 && e.value <= 11) {
      return this.monthNamePipe.transform(e.value, true).capitalize();
    }
    return e.value === e.dataItem?.scope
      ? this.scopeNamePipe.transform(e.dataItem.scope)
      : `${e.value}\n(${this.scopeNamePipe.transform(e.dataItem?.scope)})`;
  };

  public monthlySeriesLabels = (e: SeriesLabelsContentArgs): string =>
    `${e.series.name}: ${this.intlService.formatNumber(e.value, 'n0')}`;

  public zoomHandler(e: ZoomEndEvent): void {
    const { min, max } = e.axisRanges['categoryAxis'];
    if (min !== 0) {
      this.chartZoomed$.next(true);
    } else {
      this._zoomMax$.subscribe(
        zoomMax => {
          this.chartZoomed$.next(max !== zoomMax);
        }
      ).unsubscribe();
    }
  }

  public resetZoom(): void {
    if (this.showTotalControl.value) { return; }
    this.categoryAxisItemComponent.notifyChanges({});
    this.chartZoomed$.next(false);
  }

  private handleDuplicateDescriptions(rows: ChartModel[]): ChartModel[] {
    const groups = rows.toGroupsBy('description').toRecord();
    rows = rows.map(row => {
      if (groups[row.description]?.length > 1) {
        return ({ ...row, description: `${row.description} (${row.categoryName})` });
      } else {
        return row;
      }
    });
    return rows;
  }
}
