import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate } from '@enerkey/rxjs';
import { ContactClient, CreateCompanyFacilityFunctionModel, FunctionType } from '@enerkey/clients/contact';

import { FunctionTypeService } from '../../../contact-manager/shared/function-type.service';
import { FunctionTypeMultiselectItem } from '../../../contact-manager/shared/function-type-multiselect-item';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'facility-companies',
  templateUrl: './facility-companies-modal.component.html'
})

export class FacilityCompaniesModalComponent extends ModalBase<void> implements OnDestroy {
  public facilityIds: number[];
  public availableFunctionsNames: FunctionTypeMultiselectItem[];

  public readonly companyFacilitiesForm: UntypedFormGroup;
  public readonly loading$: Observable<boolean>;

  private selectedCompanyId: number;

  private readonly _loading$ = new BehaviorSubject(false);

  public constructor(
    private readonly contactClient: ContactClient,
    private readonly functionTypeService: FunctionTypeService,
    private readonly toasterService: ToasterService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.companyFacilitiesForm = new UntypedFormGroup({
      company: new UntypedFormControl(null, Validators.required),
      availableFunctions: new UntypedFormControl(
        { value: null, disabled: true }, Validators.required
      ),
    });
    this.companyFacilitiesForm.get('company').valueChanges
      .subscribe(company => {
        this.selectedCompanyId = company ? company.id : undefined;

        if (this.selectedCompanyId) {
          this.companyFacilitiesForm.controls.availableFunctions.enable();
          const functionTypes = this.functionTypeService.arrayFromObject(company.availableFunctions);
          this.availableFunctionsNames = this.functionTypeService.getSelectItems(functionTypes);
        } else {
          this.companyFacilitiesForm.controls.availableFunctions.disable();
        }
      });
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public submit(): void {
    const functionTypes: FunctionType[] = this.companyFacilitiesForm.value.availableFunctions;
    const functionType = functionTypes.reduce((x, y) => x + y);
    const companyFacilityFunctionModelArray: CreateCompanyFacilityFunctionModel[] =
      this.facilityIds.map(facilityId => ({
        facilityId: facilityId,
        functionType: functionType,
      }));
    this.contactClient.addCompanyFacilityFunctions(
      this.selectedCompanyId,
      companyFacilityFunctionModelArray
    ).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('CONTACTMANAGER.SUCCESS.UPDATED_COMPANY_FACILITIES');
        super.closeModal();
      },
      error: () => {
        this.toasterService.error('CONTACTMANAGER.ERROR.FAILED_TO_UPDATE_COMPANY');
      }
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
