import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropDownFilterSettings, PopupSettings } from '@progress/kendo-angular-dropdowns';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ProductService } from '../../services/product.service';

type DataItem = { id: string; name: string };

@Component({
  selector: 'product-input',
  templateUrl: './product-input.component.html',
  styleUrls: ['./product-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProductInputComponent),
    multi: true
  }]
})
export class ProductInputComponent implements ControlValueAccessor, OnDestroy {

  public readonly popupSettings: PopupSettings = { width: 320, animate: false };
  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };

  public readonly data$: Observable<DataItem[]>;
  public readonly loading$: Observable<boolean>;

  public value: string;
  public disabled: boolean = false;

  private _onChange: (productId: string) => void;
  private _onTouch: () => void;

  private readonly _loading = new LoadingSubject();

  public constructor(
    productService: ProductService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.data$ = productService.getIdNamePairs().pipe(
      indicate(this._loading),
      map(items => items.map(item => ({
        id: item.id,
        name: `${item.id}: ${item.name}`,
      })))
    );
  }

  public ngOnDestroy(): void {
    this._loading.complete();
  }

  /** ComboBox event */
  public valueChanged(value: string): void {
    this._onChange?.(value);
  }

  /** ComboBox event */
  public blur(): void {
    this._onTouch?.();
  }

  /** ControlValueAccessor method */
  public writeValue(value: string): void {
    this.value = value;

    // Required for kendo-combobox to update
    this.changeDetector.markForCheck();
  }

  /** ControlValueAccessor method */
  public registerOnChange(fn: (productId: string) => void): void {
    this._onChange = fn;
  }

  /** ControlValueAccessor method */
  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  /** ControlValueAccessor method */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;

    // Required for kendo-combobox to update
    this.changeDetector.markForCheck();
  }
}
