import { IAngularStatic } from 'angular';

import TimeFrame from '../../../services/time-frame-service';
import * as SpreadsheetConstants from '../components/manual-qa-spreadsheet/manual-qa-spreadsheet.constants';
import * as Utils from '../shared/utils.functions';

declare const angular: IAngularStatic;

interface SpreadsheetSelection {
  startIndex: number;
  endIndex: number;
}

export class ManualQaSpreadsheetService {

  /**
   * Returns time frame for selected values in spreadsheet
   *
   */
  public getSelectionTimeFrame(spreadsheet: any, readings: any[]): any {
    const selection = this.getSelection(spreadsheet);

    const fromDate = this.getSelectionFromDate(readings, selection);
    const toDate = this.getSelectionToDate(readings, selection);

    return new TimeFrame(fromDate, toDate);
  }

  /**
   * Returns current selection from given spreadsheet
   *
   */
  public getSelection(spreadsheet: any): SpreadsheetSelection {
    const sheet = spreadsheet.activeSheet();
    const selection = sheet.selection();

    const start = selection.topRow().topLeft();
    const end = selection.bottomRow().topLeft();

    return {
      startIndex: this.getDataIndex(start.row + 1),
      endIndex: this.getDataIndex(end.row + 1)
    };
  }

  /**
   * Returns selection from date (aka start date)
   *
   * @returns moment object
   */
  private getSelectionFromDate(readings: any[], selection: any): any {
    try {
      const previousHourlyReading = Utils.findPreviousHourlyReading(readings, selection.startIndex);

      return angular.copy(previousHourlyReading.timestamp);
    } catch (exception) {
      return angular.copy(readings[0].timestamp);
    }
  }

  /**
   * Returns selection to date (aka end date)
   *
   * @returns moment
   */
  private getSelectionToDate(readings: any[], selection: any): any {
    try {
      const nextHourlyReading = Utils.findNextHourlyReading(readings, selection.endIndex + 1);

      return angular.copy(readings[nextHourlyReading.rowIndex - 1].timestamp);
    } catch (exception) {
      return angular.copy(readings[readings.length - 1].timestamp);
    }
  }

  /**
   * Returns row index in actual data array
   *
   */
  private getDataIndex(rowIndex: number): number {
    const index = rowIndex - SpreadsheetConstants.READING_START_ROW;

    return index > 0 ? index : null;
  }
}
