import templateModule from 'raw-loader!../templates/metertree.html';

export function metertree() {
  return {
    restrict: 'E',
    template: templateModule,
    scope: {
      options: '='
    },
    controller: controller
  };
}

controller.$inject = ['$rootScope', '$scope', '$q', 'facilities', 'quantities', 'meters', '_'];

function controller($rootScope, $scope, $q, facilities, quantities, meters, _) {
  // meters
  $scope.meterData = {
    allQuantities: $scope.options.quantities || [],
    meters: [],
    metertree: [],
    facilityMeteringTypes: [],
    meterFilter: undefined,
    metertreeCollapsed: false,
    loading: false
  };

  var initialize = function() {
    if ($scope.options.showLoading) {
      $scope.meterData.loading = true;
    }
    $q.all([
      getQuantities(),
      getMeters()
    ])
      .then(getMetertree)
      .catch(function() {
        console.log('Error while initializing metertree...');
      })
      .finally(function() {
        if ($scope.options.showLoading) {
          $scope.meterData.loading = false;
        }
      })
    ;
  };

  var getQuantities = function() {
    if ($scope.meterData.allQuantities.length) {
      return $q.resolve();
    }

    return quantities.getQuantitiesForFacility($scope.options.facilityId).then(
      // success callback
      function(result) {
        $scope.meterData.allQuantities = result;
      }
    );
  };

  // get meters
  var getMeters = function() {
    return meters.getMetersForFacility($scope.options.facilityId).then(
      // success callback
      function(result) {
        $scope.meterData.meters = result;
      }
    );
  };

  // get metertree
  var getMetertree = function(id) {
    return facilities.getMetertree($scope.options.facilityId).then(
      // success callback
      function(result) {
        var metertree = result.MeterTrees ? _.cloneDeep(result.MeterTrees.Default) : undefined;

        _.each(metertree, function(quantity) {
          fillMetertree(quantity);
        });

        var array = [];
        _.each(metertree, function(quantity) {
          if(!quantity.length) {
            return; // Skip quantities with no meters
          }
          quantity.quantityId = quantity[0].data.QuantityId;
          array.push(quantity);
        });
        $scope.meterData.metertree = array;
      }
    );
  };

  var getSelectedMeterIds = function() {
    var setter = $scope.options.meterIdSetter;
    return _.isObject(setter) ? setter.object[setter.property] : [];
  };

  var addSelectedMeterId = function(meterId) {
    var setter = $scope.options.meterIdSetter;
    if (_.isObject(setter) && !_.contains(getSelectedMeterIds(), meterId)) {
      setter.object[setter.property] = setter.object[setter.property].concat([meterId]);
    }
  };

  var removeSelectedMeterId = function(meterId) {
    var setter = $scope.options.meterIdSetter;
    if (_.isObject(setter) && _.contains(getSelectedMeterIds(), meterId)) {
      setter.object[setter.property] = _.without(getSelectedMeterIds(), meterId);
    }
  };

  // fill metertree
  var fillMetertree = function(tree, parentMeterId) {
    _.each(tree, function(meter) {
      var m = _.findWhere($scope.meterData.meters, { Id: meter.Id });
      if(m) {
        meter.data = m;
        meter.selected = _.contains(getSelectedMeterIds(), meter.Id);
        meter.parentMeterId = parentMeterId;

        // Fill meteringType for filters
        if (!_.find($scope.meterData.facilityMeteringTypes, { Id: m.MeteringTypeId}) && m.MeteringType) {
          $scope.meterData.facilityMeteringTypes.push(m.MeteringType);
        }
      }
      if(meter.Navigation) {
        fillMetertree(meter.Navigation.Children, meter.Id);
      }
    });
  };

  $scope.getQuantity = function(quantityId) {
    return _.find($scope.meterData.allQuantities, function(quantity) {
      return quantity.ID === parseInt(quantityId, 10);
    });
  };

  $scope.showHideMeters = function(meter) {
    meter.collapsed = !meter.collapsed;
    if(!meter.Navigation) return;
    _.each(meter.Navigation.Children, function(m) {
      m.visible = !m.visible;
    });
  };

  $scope.collapseMeters = function(show, tree) {
    $scope.meterData.metertreeCollapsed = show;

    if(!tree) {
      _.each($scope.meterData.metertree, function(quantity) {
        $scope.collapseMeters(show, quantity);
      });
    } else {
      _.each(tree, function(meter) {
        meter.visible = show;

        if(meter.Navigation) {
          meter.collapsed = show;

          $scope.collapseMeters(show, meter.Navigation.Children);
        }
      });
    }
  };

  $scope.handleMeterSelection = function(meter) {
    if (meter.selected) {
      addSelectedMeterId(meter.Id);
    } else {
      removeSelectedMeterId(meter.Id);
    }
  };

  $scope.selectMeters = function(select, tree, parentMeter) {
    if (select === null) {
      select = _.isBoolean(parentMeter.treeselected) ? parentMeter.treeselected : null;
    }
    if (parentMeter && parentMeter.treeselected !== select) {
      parentMeter.treeselected = select;
    }
    if (!tree) {
      _.each($scope.meterData.metertree, function(tree) {
        // Only select valid quantities
        if($scope.getQuantity(tree[0].data.QuantityId)) {
          $scope.selectMeters(select, tree);
        }
      });
    } else {
      _.each(tree, function(meter) {
        if(select === null) {
          select = !meter.selected;
        }

        if(!meter.filtered || select === false) {
          meter.selected = select;
        }

        if(meter.Navigation) {
          $scope.selectMeters(select, meter.Navigation.Children, meter);
        }

        $scope.handleMeterSelection(meter);
      });
    }

    //$scope.meterToggled();
  };

  var isMeterChildVisible = function(tree) {
    if(!tree) {
      _.each($scope.meterData.metertree, function(quantity) {
        isMeterChildVisible(quantity);
      });
    } else {
      var visible = false;
      _.each(tree, function(meter) {
        var visibleChildren = meter.Navigation ? isMeterChildVisible(meter.Navigation.Children) : false;
        var isUserFiltered = $scope.meterData.meterFilter ? ( meter.data.Name.toLowerCase().indexOf($scope.meterData.meterFilter.toLowerCase()) === -1 ) : false;
        var isTypeFiltered = $scope.meterData.meterTypeFilter ? meter.data.MeteringTypeId !== $scope.meterData.meterTypeFilter : false;
        meter.filtered = (isUserFiltered || isTypeFiltered) && !visibleChildren;
        if(!meter.filtered) {
          visible = true;
        }
      });
      return visible;
    }
  };

  $scope.setMeterTypeFilter = function(id) {
    if($scope.meterData.meterTypeFilter == id) {
      $scope.meterData.meterTypeFilter = null;
    } else {
      $scope.meterData.meterTypeFilter = id;
      $scope.collapseMeters(true);
    }

    isMeterChildVisible();
  };

  $scope.handleMeterClick = function(meter) {
    if (meter.selected) {
      $rootScope.$emit('meters.meterClicked', meter);
    }
  };

  // ng-change listener for meterfilter change
  $scope.onMeterFilterChanged = function() {
    if (angular.isDefined($scope.meterData.meterFilter)) {
      $scope.collapseMeters(true);
      isMeterChildVisible();
    }
  };

  // initialize
  initialize();
}
