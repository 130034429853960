import template from 'raw-loader!./manual-qa-meter-name-quantity.html';

const ManualQaMeterNameQuantityComponent = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    meter: '<',
    defaultTitle: '@'
  }
};

export default ManualQaMeterNameQuantityComponent;
