import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmailSendStatus } from '../email-send-modal/email-send-modal.component';

@Component({
  selector: 'email-status-icon',
  templateUrl: './email-status-icon.component.html',
  styleUrls: ['./email-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailStatusIconComponent {
  public EmailSendStatus = EmailSendStatus;

  @Input() public status: EmailSendStatus;
}
