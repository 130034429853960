import { roundDataSeries } from '@enerkey/ts-utils';

import TimeFrame from '../../../../services/time-frame-service';
import * as Colors from '../../constants/colors';
import * as Reading from '../../shared/reading';
import * as Utils from '../../shared/utils.functions';
import * as FetchTypeFunctions from '../manual-qa-fetch-type/manual-qa-fetch-type.functions';
import { getDefectRowIndexes } from '../manual-qa-spreadsheet/manual-qa-spreadsheet.functions';
import * as Constants from './manual-qa-chart.constants';

/**
 * Pad orphan hourly readings so that they can be displayed on the stock chart.
 * Chart won't display an orphan point that has no value on either side.
 * Therefore consumption readings are padded by zeroes. Preview and modeled consumptions
 * are padded by zeroes or by adjacent consumption readings if available.
 *
 * @param {(Reading.Reading)[]} readings
 */
export function padOrphanReadings(readings) {
  readings.forEach((reading, index) => {
    const before = readings[index - 1];
    const after = readings[index + 1];

    if (hasPreview(reading) && !hasPreview(before) && !hasPreview(after)) {
      if (hasActual(before)) {
        padPreview(before);
      } else if (hasActual(after)) {
        padPreview(after);
      } else {
        padPreview(before);
        padPreview(after);
      }
    }

    if (hasModel(reading) && !hasModel(before) && !hasModel(after)) {
      if (hasActual(before)) {
        padModel(before);
      } else if (hasActual(after)) {
        padModel(after);
      } else {
        padModel(before);
        padModel(after);
      }
    } else if (hasActual(reading) && !hasActual(before) && !hasActual(after)) {
      padActual(before);
      padActual(after);
    }
  });
}

/**
 * @param {Reading.Reading} reading
 */
function hasPreview(reading) {
  return reading && reading.previewConsumption !== null;
}

/**
 * @param {Reading.Reading} reading
 */
function hasActual(reading) {
  return reading && reading.actualConsumption !== null;
}

/**
 * @param {Reading.Reading} reading
 */
function hasModel(reading) {
  return reading && reading.modelConsumption !== null;
}

/**
 * @param {Reading.Reading} reading
 */
function padPreview(reading) {
  if (reading) {
    reading.previewConsumptionChart = reading.actualConsumption || 0;
  }
}

/**
 * @param {Reading.Reading} reading
 */
function padActual(reading) {
  if (reading) {
    reading.actualConsumptionChart = 0;
  }
}

/**
 * @param {Reading.Reading} reading
 */
function padModel(reading) {
  if (reading) {
    reading.modelConsumptionChart = reading.actualConsumption || 0;
  }
}

/**
 * Returns initial aggregate values for readings that can be used with
 * reduce call.
 *
 * @returns {Object}
 */
export const getInitialAggregateValuesForReadings = () => ({
  consumption: {
    min: Number.MAX_SAFE_INTEGER,
    max: Number.MIN_SAFE_INTEGER
  },
  cumulative: {
    min: Number.MAX_SAFE_INTEGER,
    max: Number.MIN_SAFE_INTEGER
  }
});

/**
 * Returns aggregate values for readings array. Basically this returns minimum and maximum values
 * for consumption and cumulative series.
 *
 * @param {Array} readings
 */
export const getAggregatesForReadings = readings =>
  readings.reduce(getMinMaxValues, getInitialAggregateValuesForReadings());

/**
 * Returns minimum ja maximum values for given reading
 *
 * @param {Object} result
 * @param {Reading} reading
 *
 * @returns {Object}
 */
function getMinMaxValues(result, reading) {
  const consumptionValues = [
    reading.actualConsumption,
    reading.comparisonConsumption,
    reading.modelConsumption,
    reading.previewConsumption
  ];

  const cumulativeValues = [reading.actualCumulative, reading.modelCumulative];

  const consumptionMin = Math.min(...consumptionValues);
  const consumptionMax = Math.max(...consumptionValues);
  const cumulativeMin = Math.min(...cumulativeValues);
  const cumulativeMax = Math.max(...cumulativeValues);

  return {
    consumption: {
      min: Math.min(consumptionMin, result.consumption.min),
      max: Math.max(consumptionMax, result.consumption.max)
    },
    cumulative: {
      min: Math.min(cumulativeMin, result.cumulative.min),
      max: Math.max(cumulativeMax, result.cumulative.max)
    }
  };
}

/**
 * Returns factor for minimum value that can be used
 * to calculate minimum value for axes
 *
 * @param {Number} minValue
 *
 * @returns {Number}
 */
export const getMinValueFactor = minValue =>
  minValue < 0 ? Constants.VALUE_AXIS.FACTOR : Constants.VALUE_AXIS.FACTOR - 1;

/**
 * Returns factor for maximum value that can be used
 * to calculate maximum value for axes
 *
 * @param {Number} maxValue
 *
 * @returns {Number}
 */
export const getMaxValueFactor = maxValue =>
  maxValue > 0 ? Constants.VALUE_AXIS.FACTOR : Constants.VALUE_AXIS.FACTOR - 1;

/**
 * Converts array of defect issues to plot band objects for kendo chart
 *
 * @param {Reading[]} readings
 *
 * @returns {Array}
 */
export const defectIssuesToPlotBands = readings => {
  const ranges = Utils.toRangeArrays(getDefectRowIndexes(readings));

  return ranges.map(range => {
    const startReading = readings[range[0]];
    const endReading = readings[range[1]];

    return {
      from: startReading.timestamp,
      to: endReading.timestamp,
      color: Colors.DEFECTS_BACKGROUND
    };
  });
};

/**
 * Returns extended time frame for navigator
 *
 * @param {TimeFrame} timeFrame
 *
 * @returns {TimeFrame}
 */
export const getExtendedTimeFrameForNavigator = timeFrame =>
  timeFrame instanceof TimeFrame
    ? angular.copy(timeFrame).expand(Constants.NAVIGATOR.EXPAND_AMOUNT, Constants.NAVIGATOR.EXPAND_UNIT)
    : null;

/**
 * Returns default options for stock chart
 *
 * @returns {Object}
 */
export const getStockChartDefaultOptions = () => ({
  autoBind: false,
  dateField: Reading.TIMESTAMP,
  renderAs: 'canvas',
  transitions: false,
  pannable: {
    lock: 'y'
  },
  zoomable: {
    mousewheel: false,
    selection: {
      lock: 'y'
    }
  },
  legend: {
    visible: true,
    position: 'bottom',
    labels: {
      margin: {
        right: 30
      }
    }
  },
  legendItemHover: e => e.preventDefault()
});

/**
 * Returns series default options
 *
 * @see https://docs.telerik.com/kendo-ui/api/javascript/dataviz/ui/stock-chart/configuration/seriesdefaults
 *
 * @returns {Object}
 */
export const getSeriesDefaults = () => ({
  seriesDefaults: {
    type: 'line',
    style: 'smooth',
    markers: {
      visible: false
    },
    missingValues: 'gap',
    unit: '',
    overlay: {
      gradient: null
    }
  }
});

/**
 * Returns series options for stock chart
 *
 * @see https://docs.telerik.com/kendo-ui/api/javascript/dataviz/ui/stock-chart/configuration/series
 *
 * @param {Object} translations
 * @param {number} fetchType
 * @param {boolean} useComparisonPeriod
 *
 * @returns {Object}
 */
export const getSeries = (translations, fetchType, useComparisonPeriod) => ({
  series: [
    ...getActualSeries(translations, fetchType),
    ...getModelSeries(translations, fetchType),
    ...getPreviewSeries(translations, fetchType),
    ...getComparisonSeries(translations, fetchType, useComparisonPeriod)
  ]
});

export const getCategoryAxis = (chart, timeFrame, plotBands, fetchType) => ({
  categoryAxis: [
    {
      name: Constants.AXIS.CONSUMPTION,
      justified: true,
      visible: false,
      min: 1,
      max: 100,
      maxDateGroups: 10000,
      baseUnit: 'hours',
      baseUnitStep: 'auto',
      labels: {
        visible: false
      },
      axisCrossingValues: FetchTypeFunctions.isFetchTypeBoth(fetchType)
        ? [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
        : [0, 0]
    },
    {
      name: Constants.AXIS.CUMULATIVE,
      justified: true,
      visible: false,
      min: 1,
      max: 100,
      maxDateGroups: 10000,
      baseUnit: 'hours',
      baseUnitStep: 'auto',
      labels: {
        visible: false
      },
      axisCrossingValues: FetchTypeFunctions.isFetchTypeBoth(fetchType)
        ? [Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]
        : [0, 0]
    },
    {
      name: Constants.AXIS.DATE,
      justified: true,
      visible: true,
      type: 'date',
      maxDateGroups: chart ? chart.getSize().width / 20 : 1000,
      baseUnit: 'days',
      baseUnitStep: 'auto',
      min: 1,
      max: 100,
      labels: {
        dateFormats: {
          hours: 'd.M',
          days: 'd.M',
          months: 'd.M'
        }
      },

      majorTicks: {
        visible: true,
        step: 1
      },
      field: Reading.TIMESTAMP
    },
    {
      name: Constants.AXIS.HOUR,
      justified: true,
      visible:
        timeFrame instanceof TimeFrame
          ? timeFrame.getUnitsBetween('days') <= Constants.CATEGORY_AXIS_HOUR_HIDE_AFTER_DAYS
          : false,
      type: 'date',
      maxDateGroups: chart ? chart.getSize().width / 10 : 1000,
      baseUnit: 'hours',
      baseUnitStep: 'auto',
      min: 1,
      max: 100,
      labels: {
        dateFormats: {
          hours: 'HH',
          days: 'HH',
          months: 'HH'
        }
      },
      majorGridLines: {},
      majorTicks: {
        visible: true,
        step: 1
      },
      field: Reading.TIMESTAMP,
      plotBands: plotBands
    }
  ]
});

const getMinMaxArray = object => [
  object.min !== 0 ? object.min * getMinValueFactor(object.min) : 0,
  object.max !== 0 ? object.max * getMaxValueFactor(object.max) : 100
];

export const getValueAxis = (readings, timeFrame, fetchType) => {
  const modifiedTimeFrame = getExtendedTimeFrameForNavigator(timeFrame);
  const timeFrameData = Utils.getReadingsWithinTimeFrame(readings, modifiedTimeFrame);
  const result = getAggregatesForReadings(timeFrameData);
  const valueAxis = [];

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    valueAxis.push({
      name: Constants.AXIS.CONSUMPTION,
      labels: {
        format: '{0}'
      },
      ...roundDataSeries(...getMinMaxArray(result.consumption)),
      axisCrossingValue: Number.MIN_SAFE_INTEGER
    });
  }

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    valueAxis.push({
      name: Constants.AXIS.CUMULATIVE,
      labels: {
        format: '{0}'
      },
      ...roundDataSeries(...getMinMaxArray(result.cumulative)),
      axisCrossingValue: Number.MIN_SAFE_INTEGER
    });
  }

  return {
    valueAxis: valueAxis
  };
};

export const getNavigator = (readings, timeFrame, plotBands, fetchType) => ({
  navigator: {
    series: {
      type: 'area',
      field: FetchTypeFunctions.isFetchTypeCumulative(fetchType)
        ? Reading.ACTUAL_CUMULATIVE
        : Reading.ACTUAL_CONSUMPTION_CHART,
      missingValues: 'gap',
      aggregate: 'avg',
      tooltip: {
        visible: false
      },
      color: Colors.SERIES_ACTUAL_CONSUMPTION
    },
    pane: {
      height: 50
    },
    categoryAxis: {
      maxDateGroups: 10000,
      baseUnit: 'hours',
      baseUnitStep: 'auto',
      labels: {
        visible: false
      },
      majorTicks: {
        visible: false
      },
      minorTicks: {
        visible: false
      },
      plotBands: plotBands,
      min: readings.length > 0 ? readings[0].timestamp : null,
      max: readings.length > 0 ? readings[readings.length - 1].timestamp : null
    },
    select: {
      mousewheel: false,
      from: timeFrame ? timeFrame.getFromDate().toDate() : null,
      to: timeFrame ? timeFrame.getToDate().toDate() : null
    },
    hint: {
      format: '{0:dd.MM.yyyy HH:mm} - {1:dd.MM.yyyy HH:mm}'
    }
  }
});

function getActualSeries(translations, fetchType) {
  const series = [];

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    series.push({
      name: translations['MQA.INSPECT.GRAPH.SERIES.ACTUAL_CUMULATIVE'],
      axis: Constants.AXIS.CUMULATIVE,
      zIndex: 1,
      field: Reading.ACTUAL_CUMULATIVE,
      type: 'line',
      aggregate: 'avg',
      line: {
        width: 2,
        style: 'smooth'
      },
      dashType: 'solid',
      missingValues: 'gap',
      markers: {
        visible: true,
        size: 1
      },
      categoryAxis: Constants.AXIS.CUMULATIVE,
      color: Colors.SERIES_ACTUAL_CUMULATIVE,
      axisCrossingValue: 0
    });
  }

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    series.push({
      name: translations['MQA.INSPECT.GRAPH.SERIES.ACTUAL_CONSUMPTION'],
      axis: Constants.AXIS.CONSUMPTION,
      zIndex: 8,
      type: 'line',
      field: Reading.ACTUAL_CONSUMPTION_CHART,
      aggregate: 'avg',
      line: {
        width: 2,
        style: 'smooth'
      },
      dashType: 'solid',
      missingValues: 'gap',
      markers: {
        visible: e => {
          const { length } = e.series.data;
          const readings = e.dataItem;

          return length <= Constants.HIDE_MARKERS_AFTER_DATA_POINTS && readings && readings.isFaulty;
        },
        background: Colors.FAULT_BACKGROUND,
        size: 6
      },
      categoryAxis: Constants.AXIS.CONSUMPTION,
      color: Colors.SERIES_ACTUAL_CONSUMPTION,
      axisCrossingValue: 0
    });
  }

  return series;
}

function getComparisonSeries(translations, fetchType, useComparisonPeriod) {
  return useComparisonPeriod && FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)
    ? [
      {
        name: translations['MQA.INSPECT.GRAPH.SERIES.COMPARISON_CONSUMPTION'],
        axis: Constants.AXIS.CONSUMPTION,
        zIndex: 3,
        type: 'line',
        aggregate: 'avg',
        line: {
          width: 2,
          color: Colors.SERIES_COMPARISON_CONSUMPTION,
          style: 'smooth'
        },
        field: Reading.COMPARISON_CONSUMPTION,
        missingValues: 'gap',
        markers: {
          visible: false,
          size: 2
        },
        categoryAxis: Constants.AXIS.CONSUMPTION,
        color: Colors.SERIES_COMPARISON_CONSUMPTION
      }
    ]
    : [];
}

function getPreviewSeries(translations, fetchType) {
  return FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)
    ? [
      {
        name: translations['MQA.INSPECT.GRAPH.SERIES.PREVIEW_CONSUMPTION'],
        axis: Constants.AXIS.CONSUMPTION,
        type: 'area',
        zIndex: 7,
        aggregate: 'avg',
        line: {
          width: 2,
          style: 'smooth',
          dashType: 'Solid'
        },
        field: Reading.PREVIEW_CONSUMPTION_CHART,
        missingValues: 'gap',
        markers: {
          visible: false,
          size: 2
        },
        labels: {
          visible: false
        },
        opacity: 1,
        categoryAxis: Constants.AXIS.CONSUMPTION,
        color: Colors.SERIES_PREVIEW_CONSUMPTION
      }
    ]
    : [];
}

function getModelSeries(translations, fetchType) {
  const series = [];

  if (FetchTypeFunctions.isFetchTypeCumulativeOrBoth(fetchType)) {
    series.push({
      name: translations['MQA.INSPECT.GRAPH.SERIES.MODEL_CUMULATIVE'],
      axis: Constants.AXIS.CUMULATIVE,
      zIndex: 4,
      type: 'line',
      field: Reading.MODEL_CUMULATIVE,
      missingValues: 'gap',
      aggregate: 'avg',
      line: {
        width: 2,
        style: 'smooth'
      },
      dashType: 'Solid',
      markers: {
        visible: true,
        size: 1
      },
      labels: {
        visible: false
      },
      categoryAxis: Constants.AXIS.CUMULATIVE,
      color: Colors.SERIES_MODEL_CUMULATIVE
    });
  }

  if (FetchTypeFunctions.isFetchTypeHourOrBoth(fetchType)) {
    series.push({
      name: translations['MQA.INSPECT.GRAPH.SERIES.MODEL_CONSUMPTION'],
      axis: Constants.AXIS.CONSUMPTION,
      type: 'area',
      zIndex: 5,
      aggregate: 'avg',
      line: {
        width: 2,
        style: 'smooth',
        dashType: 'Solid'
      },
      field: Reading.MODEL_CONSUMPTION_CHART,
      missingValues: 'gap',
      markers: {
        visible: false,
        size: 2
      },
      labels: {
        visible: false
      },
      categoryAxis: Constants.AXIS.CONSUMPTION,
      color: Colors.SERIES_MODEL_CONSUMPTION
    });
  }

  return series;
}
