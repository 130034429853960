import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { RelationalValuesService } from '../services/relational-values.service';

@Pipe({ name: 'relationalValueUnit' })
export class RelationalValueUnitPipe implements PipeTransform {
  public constructor(
    private readonly relationalValuesService: RelationalValuesService
  ) { }

  /** Use with `async`-pipe. */
  public transform(
    relationalValueId: number,
    quantityId: Quantities,
    unit = ReportingUnit.Default
  ): Observable<string> {
    return this.relationalValuesService.getRelationalValueQuantityUnit(
      relationalValueId,
      quantityId,
      unit
    );
  }
}
