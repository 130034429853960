<div
  *ngIf="(loading$ | async) as loading"
  class="loading"
  [loadingSpinner]="loading"
></div>
<ng-container *ngIf="(urls$ | async) as urls">
  <div class="buttonBar">
    <button
      type="button" class="button button--primary"
      [disabled]="rowInEdit === 0"
      (click)="addNewUrl(urls)"
    >
      {{ 'FACILITIES_REPORT.ADD_NEW' | translate }}
    </button>
  </div>
  <form *ngIf="urls.length" [formGroup]="urlForm">
    <table>
      <tr>
        <th>{{ 'ADMIN.NAME' | translate }}</th>
        <th>{{ 'FACILITY.VALUE' | translate }}</th>
        <th class="actionsCol">{{ 'GROUPS.FUNCTIONS' | translate }}</th>
      </tr>
      <tr *ngFor="let url of urls let index = index">
        <td>
          <ng-container *ngIf="rowInEdit === index else readonlyLabel">
            <input
              type="text"
              formControlName="label"
              kendoTextBox
            >
          </ng-container>
          <ng-template #readonlyLabel>
            {{ url.label }}
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="rowInEdit === index else readonlyValue">
            <input
              type="text"
              formControlName="value"
              kendoTextBox
            >
          </ng-container>
          <ng-template #readonlyValue>
            {{ url.value }}
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="rowInEdit === index; else normalActions">
            <button
              type="submit" class="button"
              [disabled]="!urlForm.valid"
              (click)="saveChanges(url)"
            >
              <i class="fas fa-check"></i>
            </button>
            <button
              type="button" class="button button--negative"
              (click)="cancelEdit(urls)"
            >
              <i class="fas fa-ban"></i>
            </button>
          </ng-container>
          <ng-template #normalActions>
            <button
              type="button" class="button button--negative"
              [disabled]="rowInEdit !== null"
              (click)="removeUrl(urls, index)"
            >
              <i class="fa fa-trash-alt"></i>
            </button>
          </ng-template>
        </td>
      </tr>
    </table>
  </form>
  <div *ngIf="!urls.length && !(loading$ | async)" class="noData">
    {{ 'FACILITIES_REPORT.EXTERNAL_LINKS_POPUP_NO_LINKS' | translate }}
  </div>
</ng-container>
