import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { ActionSlimViewModel } from '@enerkey/clients/attachments';
import EmValidationService from '../../energy-management/services/em-validations-service';

@Pipe({ name: 'topChangedActionsTitle' })
export class TopChangedActionsTitlePipe implements PipeTransform {

  public constructor(
    private readonly translateService: TranslateService,
    private readonly datePipe: DatePipe
  ) { }

  public transform(facilityId: number, actions: Map<number, ActionSlimViewModel[]>): string {
    const facilityActions = actions?.get(facilityId) ?? [];

    switch (facilityActions.length) {
      case 0:
        return '';
      case 1:
        return this.getInformationToolTip(facilityActions[0]);
      default:
        return this.getCountToolTip(facilityActions);
    }
  }

  private getInformationToolTip(action: ActionSlimViewModel): string {
    const dateLabel = this.translateService.instant('ACTIONS.EFFECTSTARTSAT');

    const date = action.effectStartsAt
      ? this.datePipe.transform(action.effectStartsAt, 'shortDate')
      : '-';

    const typeLabel = this.translateService.instant(
      EmValidationService.isComment(action)
        ? 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_COMMENT'
        : 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_ACTION'
    );

    return `${typeLabel}: ${action.reportedDescription}\n${dateLabel}: ${date}`;
  }

  private getCountToolTip(actions: ActionSlimViewModel[]): string {
    const areComments = EmValidationService.isComment(actions[0]);

    const translationKey = areComments
      ? 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_COMMENTS_COUNT'
      : 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ACTIONS_COUNT';

    return this.translateService.instant(translationKey, { count: actions.length });
  }
}
