<kendo-grid
  class="noGroupingHeader"
  [kendoGridBinding]="meters"
  [pageSize]="pageSize"
  [pageable]="true"
  [sortable]="true"
  [selectable]="selectableSettings"
  filterable="menu"
  [groupable]="true"
  [resizable]="true"
  [kendoGridSelectBy]="selectByProperty"
  [(selectedKeys)]="selectedMeterIds"
  (selectionChange)="onSelect()"
  [group]="groups"
  [loading]="loading"
  [height]="height"
>
  <kendo-grid-checkbox-column
    [showSelectAll]="true"
    [width]="35"
  ></kendo-grid-checkbox-column>
  <kendo-grid-column
    [field]="nameField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.METER_NAME' | translate }}"
    filter="text"
    [groupable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="selectByProperty"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.METER_ID' | translate }}"
    filter="numeric"
    [width]="120"
    [groupable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="resolutionField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.RESOLUTION' | translate }}"
    [width]="120"
    [groupable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="quantityIdField"
    [hidden]="true"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.QUANTITY' | translate }}"
  >
    <ng-template kendoGridGroupHeaderTemplate let-value="value">
      {{ value | quantityName | async }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
