<div
  *ngIf="news$ | async as news"
  class="news-content"
>
  <div
    *ngIf="news.length; else noNews"
    class="news-grid"
  >
    <ng-container *ngFor="let entry of news">
      <div>
        <news-unread *ngIf="!entry.isRead"></news-unread>
      </div>
      <div class="news-publish-time">{{ entry.published | date:'dd.MM.' }}</div>
      <div>
        <a
          class="news-link"
          (click)="openNewsModal(entry)"
        >{{ entry.title }}</a>
      </div>
    </ng-container>
  </div>
  <ng-template #noNews>
    <div class="no-news">{{ 'NEWS_WIDGET.NO_NEWS' | translate }}</div>
  </ng-template>
</div>

<social-media-buttons justify="center"></social-media-buttons>
