export enum AddOperationType {
  OperandBefore = 'allowAddOperandBefore',
  OperandAfter = 'allowAddOperandAfter',
  OperandInside = 'allowAddOperandInside',
  OperatorBefore = 'allowAddOperatorBefore',
  OperatorAfter = 'allowAddOperatorAfter',
  OperatorInside = 'allowAddOperatorInside',
  ConstantAfter = 'allowConstantAfter',
  ParenthesesAround = 'allowAddParenthesesAround',
  ParenthesesInside = 'allowAddParenthesesInside',
  ParenthesesAfter = 'allowAddParenthesesAfter',
  ParenthesesBefore = 'allowAddParenthesesBefore'
}
