<ng-container *ngIf="unit">
  <span *ngIf="unit.quantityId; else name">
    <quantity-icon [quantity]="unit.quantityId"></quantity-icon>
    <ng-container *ngIf="unit.isDefault; else name">
      {{ unit.quantityId | quantityName | async }}
    </ng-container>
  </span>
  <ng-template #name>
    {{ unit.name }}
  </ng-template>
  <span *ngIf="unit.year">({{ unit.year }})</span>
</ng-container>
