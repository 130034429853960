class EmInputFacilityController {
  constructor() {
    this.onInputChange = this.inputChange.bind(this);

  }

  $onInit() {
    if (angular.isFunction(this.config.getOptions)) {
      this.config.getOptions(this.config.reportingObjectId).then(options => {
        this.options = options;
      });
    }
    this.initialValues = angular.isNumber(this.config.initialValue) ? [this.config.initialValue] : [];
    this.config.isValid = true;
  }

  inputChange(value) {
    const facilityId = value[0].FacilityId;
    this.config.initialValueVaries = false;
    this.onChange(this.config, facilityId);
  }
}

export default EmInputFacilityController;
