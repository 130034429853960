import {
  AfterContentChecked,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef
} from '@angular/core';

import { NgfTabsetConfig } from './tabset-config';

let nextId = 0;

/**
 * A directive to wrap tab titles that need to contain HTML markup or other directives.
 *
 * Alternatively you could use the `NgfTab.title` input for string titles.
 */
@Directive({ selector: 'ng-template[ngfTabTitle]' })
export class NgfTabTitleDirective {
  public constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * A directive to wrap the static content that is displayed on all tabs
 */
@Directive({ selector: '[ngfTabCommonContent]' })
export class NgfTabCommonContentDirective {
}

/**
 * A directive to wrap content to be displayed in a tab.
 */
@Directive({ selector: 'ng-template[ngfTabContent]' })
export class NgfTabContentDirective {
  public constructor(public templateRef: TemplateRef<any>) {}
}

/**
 * A directive representing an individual tab.
 */
@Directive({ selector: 'ngf-tab' })
export class NgfTabDirective implements AfterContentChecked {
  /**
   * The tab identifier.
   *
   * Must be unique for the entire document for proper accessibility support.
   */
  @Input() public id = `ngf-tab-${nextId++}`;

  /**
   * The tab title.
   *
   * Use the [`NgfTabTitle`](#/components/tabset/api#NgfTabTitle) directive for non-string titles.
   */
  @Input() public title: string;

  /**
   * If `true`, the current tab is disabled and can't be toggled.
   */
  @Input() public disabled = false;

  public titleTpl: NgfTabTitleDirective | null;
  public contentTpl: NgfTabContentDirective | null;

  @ContentChildren(NgfTabTitleDirective, { descendants: false }) public titleTpls: QueryList<NgfTabTitleDirective>;
  @ContentChildren(
    NgfTabContentDirective, { descendants: false }
  ) public contentTpls: QueryList<NgfTabContentDirective>;

  public ngAfterContentChecked(): void {
    // We are using @ContentChildren instead of @ContentChild as in the Angular version being used
    // only @ContentChildren allows us to specify the {descendants: false} option.
    // Without {descendants: false} we are hitting bugs described in:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/2240
    this.titleTpl = this.titleTpls.first;
    this.contentTpl = this.contentTpls.first;
  }
}

/**
 * The payload of the change event fired right before the tab change.
 */
export interface NgfTabChangeEvent {
  /**
   * The id of the currently active tab.
   */
  activeId: string;

  /**
   * The id of the newly selected tab.
   */
  nextId: string;

  /**
   * Calling this function will prevent tab switching.
   */
  preventDefault: () => void;
}

/**
 * A component that makes it easy to create tabbed interface.
 */
@Component({
  selector: 'ngf-tabset',
  exportAs: 'ngfTabset',
  templateUrl: './tabset.html',
  styleUrls: ['./tabset.css'],
})
export class NgfTabsetComponent implements AfterContentChecked, OnDestroy {

  @ContentChildren(NgfTabDirective) public tabs: QueryList<NgfTabDirective>;

  /**
   * The identifier of the tab that should be opened **initially**.
   *
   * For subsequent tab switches use the `.select()` method and the `(tabChange)` event.
   */
  @Input() public activeId: string;

  /**
   * The number of selected items to be displayed in the tabset
   */
  @Input() public itemCount?: number;

  /**
   * If `true`, the itemCount will be displayed in a extreme right orientation.
   */
  @Input() public showCountLabel: boolean;

  /**
   * If `true`, non-visible tabs content will be removed from DOM. Otherwise it will just be hidden.
   */
  @Input() public destroyOnHide = true;

  /**
   * The orientation of the tabset.
   */
  @Input() public orientation: 'horizontal' | 'vertical';

  /**
   * When true, there is no horizontal padding for tab container
   */
  @Input() public noPadding: boolean;

  /**
   * A tab change event emitted right before the tab change happens.
   *
   * See [`NgfTabChangeEvent`](#/components/tabset/api#NgfTabChangeEvent) for payload details.
   */
  @Output() public readonly tabChange = new EventEmitter<NgfTabChangeEvent>();

  public constructor(config: NgfTabsetConfig) {
    this.orientation = config.orientation;
  }

  /**
   * Selects the tab with the given id and shows its associated content panel.
   *
   * Any other tab that was previously selected becomes unselected and its associated pane is removed from DOM or
   * hidden depending on the `destroyOnHide` value.
   */
  public select(tabId: string): void {
    const selectedTab = this._getTabById(tabId);
    if (selectedTab && !selectedTab.disabled && this.activeId !== selectedTab.id) {
      let defaultPrevented = false;

      this.tabChange.emit({
        activeId: this.activeId,
        nextId: selectedTab.id,
        preventDefault: () => {
          defaultPrevented = true;
        },
      });

      if (!defaultPrevented) {
        this.activeId = selectedTab.id;
      }
    }
  }

  public ngAfterContentChecked(): void {
    // auto-correct activeId that might have been set incorrectly as input
    const activeTab = this._getTabById(this.activeId);
    // eslint-disable-next-line no-nested-ternary
    this.activeId = activeTab ? activeTab.id : (this.tabs.length ? this.tabs.first.id : null);
  }

  public ngOnDestroy(): void {
    this.tabChange.complete();
  }

  private _getTabById(id: string): NgfTabDirective {
    const tabsWithId: NgfTabDirective[] = this.tabs.filter(tab => tab.id === id);
    return tabsWithId.length ? tabsWithId[0] : null;
  }
}
