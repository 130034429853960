// This enum can probably be removed after er swagger client is generated
export enum ErResolutions {
  P1Y = 'P1Y',
  P11M = 'P11M',
  P10M = 'P10M',
  P9M = 'P9M',
  P8M = 'P8M',
  P7M = 'P7M',
  P6M = 'P6M',
  P5M = 'P5M',
  P4M = 'P4M',
  P3M = 'P3M',
  P2M = 'P2M',
  P1M = 'P1M',
  P7D = 'P7D',
  P1D = 'P1D',
  PT1H = 'PT1H'
}

export const resolutionsFromShortestToLongest = [
  ErResolutions.PT1H,
  ErResolutions.P1D,
  ErResolutions.P7D,
  ErResolutions.P1M,
  ErResolutions.P2M,
  ErResolutions.P3M,
  ErResolutions.P4M,
  ErResolutions.P5M,
  ErResolutions.P6M,
  ErResolutions.P7M,
  ErResolutions.P8M,
  ErResolutions.P9M,
  ErResolutions.P10M,
  ErResolutions.P11M,
  ErResolutions.P1Y
];
