import { ActionType } from '@enerkey/clients/attachments';

export const actionTypes = [
  { id: ActionType.ES, key: 'ACTIONS.ACTIONTYPE_0', letterCode: 'ES', name: 'energySaving' },
  { id: ActionType.MI, key: 'ACTIONS.ACTIONTYPE_1', letterCode: 'MI', name: 'otherThanEnergySaving' },
  { id: ActionType.YM, key: 'ACTIONS.ACTIONTYPE_2', letterCode: 'YM', name: 'environmentalInvestment' },
  { id: ActionType.O, key: 'ACTIONS.ACTIONTYPE_3', letterCode: 'O', name: 'otherAction' },
  { id: ActionType.K, key: 'ACTIONS.ACTIONTYPE_4', letterCode: 'K', name: 'otherComment' },
  { id: ActionType.KE, key: 'ACTIONS.ACTIONTYPE_5', letterCode: 'KE', name: 'energyConsumptionComment' },
  { id: ActionType.KAT, key: 'ACTIONS.ACTIONTYPE_6', letterCode: 'KAT', name: 'inspection' },
];

export const actionTypeTranslations: Readonly<Record<ActionType, string>> = {
  [ActionType.ES]: 'ACTIONS.ACTIONTYPE_0', // energySaving
  [ActionType.MI]: 'ACTIONS.ACTIONTYPE_1', // otherThanEnergySaving
  [ActionType.YM]: 'ACTIONS.ACTIONTYPE_2', // environmentalInvestment
  [ActionType.O]: 'ACTIONS.ACTIONTYPE_3', // otherAction
  [ActionType.K]: 'ACTIONS.ACTIONTYPE_4', // otherComment
  [ActionType.KE]: 'ACTIONS.ACTIONTYPE_5', // energyConsumptionComment
  [ActionType.KAT]: 'ACTIONS.ACTIONTYPE_6', // inspection
};
