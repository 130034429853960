import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';

export function getValueTypeOptions(valueType: ValueType): { normalized: boolean; measured: boolean } {
  switch (valueType) {
    case ValueType.Normalized:
      return { normalized: true, measured: false };
    case ValueType.Both:
      return { normalized: true, measured: true };
    default:
      return { normalized: false, measured: true };
  }
}
