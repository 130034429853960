import {
  ApplicationRef,
  ComponentRef,
  Injector,
  Renderer2,
  TemplateRef,
  Type,
  ViewContainerRef,
  ViewRef
} from '@angular/core';

export class ContentRef<T = void> {
  public constructor(public nodes: any[], public viewRef?: ViewRef, public componentRef?: ComponentRef<T>) { }
}

export class PopupService<T, E extends new (...args: any) => any> {
  private _windowRef: ComponentRef<T>;
  private _contentRef: ContentRef<E>;

  public constructor(
    private readonly _type: Type<T>,
    private readonly _injector: Injector,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _renderer: Renderer2,
    private readonly _applicationRef: ApplicationRef
  ) { }

  public open(content?: string | TemplateRef<any>, context?: any): ComponentRef<T> {
    if (!this._windowRef) {
      this._contentRef = this._getContentRef(content, context);
      this._windowRef = this._viewContainerRef.createComponent(
        this._type,
        {
          index: 0,
          injector: this._injector,
          projectableNodes: this._contentRef.nodes,
        }
      );
    }

    return this._windowRef;
  }

  public close(): void {
    if (this._windowRef) {
      this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._windowRef.hostView));
      this._windowRef = null;

      if (this._contentRef.viewRef) {
        this._applicationRef.detachView(this._contentRef.viewRef);
        this._contentRef.viewRef.destroy();
        this._contentRef = null;
      }
    }
  }

  private _getContentRef(content: string | TemplateRef<any>, context?: any): ContentRef<E> {
    if (!content) {
      return new ContentRef([]);
    } else if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(context);
      this._applicationRef.attachView(viewRef);
      return new ContentRef([viewRef.rootNodes], viewRef);
    } else {
      return new ContentRef([[this._renderer.createText(`${content}`)]]);
    }
  }
}
