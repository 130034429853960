import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';
import isMobile from 'ismobilejs';

import {
  ActionOutViewModel,
  ActionsQueryParameters,
  ActionType,
  AttachmentsClient,
} from '@enerkey/clients/attachments';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { guid } from '@enerkey/ts-utils';

import { UserService } from '../../../../services/user-service';
import { WidgetBase } from '../../shared/widget-base.interface';
import { WidgetQueueService } from '../../services/widget-queue.service';
import { DashboardStateService } from '../../services/dashboard-state.service';

export type FacilityCommentsWidgetOptions = {
  typeToShow: 'comments'; // remove when usersettings changed?
  numberToShow: number;
  numberToShowMobile: number;
  selectedCommentTypes: {
    [ActionType.K]: boolean;
    [ActionType.KE]: boolean;
  };
  facilityId: number;
};

type FacilityCommentsFilterFormValue = {
  consumption: boolean;
  other: boolean;
  facilityId: number;
};

type FacilityCommentsFilterForm = {
  consumption: FormControl<boolean>;
  other: FormControl<boolean>;
  facilityId: FormControl<number>;
};
@Component({
  selector: 'facility-comments-widget',
  templateUrl: './facility-comments-widget.component.html',
  styleUrls: ['./facility-comments-widget.component.scss', '../../styles/actions-comments-widget-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityCommentsWidgetComponent implements WidgetBase<FacilityCommentsWidgetOptions>, OnInit, OnDestroy {
  @Input() public dataModelOptions: FacilityCommentsWidgetOptions;

  public readonly uniqueId: string = guid(true);

  public readonly dataModelChange$: Observable<FacilityCommentsWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;

  public readonly formGroup: FormGroup<FacilityCommentsFilterForm>;

  public comments$: Observable<ActionOutViewModel[]>;

  private readonly _dataModelChange = new Subject<FacilityCommentsWidgetOptions>();
  private readonly _loading$ = new LoadingSubject(true);
  private readonly _destroy$ = new Subject<void>();
  private readonly _error$ = new Subject<void>();
  private readonly _refresh$ = new BehaviorSubject<boolean>(true);

  private readonly _isMobile: boolean = isMobile().any;

  public constructor(
    private readonly widgetQueue: WidgetQueueService,
    private readonly attachmentsClient: AttachmentsClient,
    private readonly dashboardStateService: DashboardStateService,
    private readonly userService: UserService,
    private readonly fb: FormBuilder,
    private readonly changeDetection: ChangeDetectorRef
  ) {
    this.formGroup = this.fb.group<FacilityCommentsFilterForm>({
      consumption: new FormControl(false),
      other: new FormControl(false),
      facilityId: new FormControl(null)
    });

    this.error$ = this._error$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.dataModelChange$ = this._dataModelChange.asObservable();
  }

  public ngOnInit(): void {

    this.formGroup.patchValue({
      consumption: this.dataModelOptions.selectedCommentTypes[ActionType.KE],
      other: this.dataModelOptions.selectedCommentTypes[ActionType.K],
      facilityId: this.dataModelOptions.facilityId
    });

    this.formGroup.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._destroy$)
    ).subscribe((formValue: Partial<FacilityCommentsFilterFormValue>) => {
      this._dataModelChange.next({
        ...this.dataModelOptions,
        selectedCommentTypes: {
          [ActionType.K]: formValue.other,
          [ActionType.KE]: formValue.consumption,
        },
        facilityId: formValue.facilityId
      });
    });

    this.comments$ = combineLatest([
      this.dataModelChange$.pipe(startWith(this.dataModelOptions)),
      this._refresh$.asObservable()
    ]).pipe(
      switchMap(args => this.getActions$(args[0]).pipe(
        indicate(this._loading$),
        catchError(() => {
          this._error$.next();
          return EMPTY;
        })
      ))
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._error$.complete();
    this._dataModelChange.complete();
    this._refresh$.complete();
  }

  public get facilityId(): FormControl {
    return this.formGroup.get('facilityId') as FormControl;
  }

  public openEditModal(comment: ActionOutViewModel): void {
    this.dashboardStateService.openEmEditModal(comment)
      .then((response: { operation?: string, action?: ActionOutViewModel }) =>
        this.dashboardStateService.refreshReportData(comment, response, this.changeDetection, this._refresh$));
  }

  public goToEnergyManagement(): void {
    this.dashboardStateService.goToEnergyManagement(this.dataModelOptions);
  }

  private getActions$(
    model: FacilityCommentsWidgetOptions
  ): Observable<ActionOutViewModel[]> {
    if (!model?.facilityId) {
      return of([]);
    }

    const filterArray = Object.integerKeys(model.selectedCommentTypes)
      .filter((at: ActionType.K | ActionType.KE) => model.selectedCommentTypes[at]);

    if (!filterArray.hasItems()) {
      return of([]);
    }

    const profileId = this.userService.profileId;
    const params = new ActionsQueryParameters({
      actionTypes: filterArray,
      latestCount: this._isMobile ? model.numberToShowMobile : model.numberToShow,
      reportingObjectId: [model.facilityId],
    });

    return this.attachmentsClient.getActionsUsingPost(profileId, undefined, params).pipe(
      this.widgetQueue.queue('attachments', this._destroy$),
      takeUntil(this._destroy$)
    );
  }
}
