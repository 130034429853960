import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { groupBy } from '@progress/kendo-data-query';

import { SimpleChangesOf } from '@enerkey/ts-utils';

import { MultiSelectComponent } from '../../../../shared/ek-inputs/multi-select/multi-select.component';
import { GriMeterItem } from '../../services/gri-import.service';

@Component({
  selector: 'gri-meter-select',
  templateUrl: '../../../../shared/ek-inputs/multi-select/multi-select.component.html',
  styleUrls: ['../../../../shared/ek-inputs/multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GriMeterSelectComponent),
    multi: true
  }]
})
export class GriMeterSelectComponent extends MultiSelectComponent<GriMeterItem, 'id', 'text'> implements OnChanges {

  @Input() public meters: GriMeterItem[] = [];

  public placeholderKey: string = 'SEARCH';
  public overflowKey: string = 'SUSTAINABILITY.GRI.METERS_COUNT';
  public valueField = 'id' as const;
  public textField = 'text' as const;
  public overflowThreshold: number = 20;
  public override translate = false;

  private _currentFilter: string = '';

  public ngOnChanges(changes: SimpleChangesOf<this>): void {
    if (changes.meters) {
      this.updateItems();
      this.writeValue(this.value.filter(id => this.meters.some(m => m.id === id)));
    }
  }

  public override filterChanged(filter: string): void {
    this._currentFilter = filter?.toLowerCase() ?? '';
    this.updateItems();
  }

  private updateItems(): void {
    this.items = this._currentFilter
      ? this.meters.filter(item => item.text.toLowerCase().includes(this._currentFilter))
      : [...(this.meters ?? [])];
    this.groupedItems = groupBy(this.items, [{ field: 'facilityName' }]);
  }
}
