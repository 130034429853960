import { Injectable } from '@angular/core';
import { ContentfulClientApi, createClient, EntryCollection } from 'contentful';

import { EnvironmentService } from '../../services/environment-service';

import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private readonly helpClient: ContentfulClientApi;

  public constructor(
    private languageService: LanguageService,
    private environmentService: EnvironmentService
  ) {
    const isDev = this.environmentService.isDevelopment();

    this.helpClient = createClient({
      host: isDev ? 'preview.contentful.com' : 'cdn.contentful.com',
      space: 'd5y2pp46l9fr',
      accessToken: isDev
        ? '24a8cadf3212e1a95b8cc6892b62cd635bb4d48bd88ac0f56f18b99cd26d5234'
        : '60d2c59f9d368b7743f2331454c91da26dd2f6fc8fa02e18ef03fc5ae651f139'
    });
  }

  public getHelp(): Promise<EntryCollection<any>> {
    const helpLocale = this.languageService.getHelpLocale();
    return this.helpClient.getEntries({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      content_type: 'rootHelp',
      include: 9,
      locale: helpLocale
    });
  }
}
