export const DEFAULT_COLOR = 'rgba(44,44,44,1)';
export const ERROR_COLOR = 'rgba(205,31,60,1)';
export const HEADER_BACKGROUND = 'rgba(211,211,211,1)';
export const SUCCESS_COLOR = 'rgba(48,176,0,0.5)';
export const DEFAULT_WIDTH = 165;

// Source:
// http://cwestblog.com/2013/09/05/javascript-snippet-convert-number-to-column-name/
export function indexToColumnCharacter(index: number): string {
  let num: number = index;
  let ret: string = '';

  // There are 26 letters (A to Z) and upper case A's index is 65
  for (let a = 1, b = 26; num >= 0; a = b, b *= 26, num -= a) {
    ret = String.fromCharCode(Math.floor((num % b) / a) + 65) + ret;
  }

  return ret;
}

export function cellName(row: number, column: number): string {
  return `${indexToColumnCharacter(column)}${row + 1}`;
}
