import { cloneDeep } from 'lodash';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { IntlService } from '@progress/kendo-angular-intl';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { Bookmark, RollingDateType } from '@enerkey/clients/settings';

import { BookmarkService, State } from '../../services/bookmark.service';
import { AjsModalService } from '../../services/modal/modal.service';
import { ALL_REPORTS } from '../../modules/energy-reporting/constants/er-modal-states.constant';
import { DialogService } from '../../shared/services/dialog.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { bookmarksRows, variableBookmarkTranslation } from '../../constants/bookmark.constants';
import { FilterService } from '../../services/filter.service';

export type BookmarkPartition = {
  page: Bookmark[],
  other: Bookmark[]
}

export type Bookmarks = {
  sticky: Bookmark[],
  saved: {
    user: BookmarkPartition,
    shared: BookmarkPartition,
  }
}

@Component({
  templateUrl: './bookmarks-modal.component.html',
  styleUrls: ['./bookmarks-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarksModalComponent extends ModalBase<void> implements OnDestroy {
  public readonly currentState$: Observable<State>;
  public readonly bookmarks$: Observable<Bookmarks>;
  public readonly variableBookmarkType$: Observable<RollingDateType>;
  public readonly bookmarkInputField: UntypedFormControl = new UntypedFormControl(null);
  public readonly dateAdjustableField: UntypedFormControl = new UntypedFormControl(false);
  public readonly VariableBookmarkTranslation = variableBookmarkTranslation;
  public readonly bookmarksRows = bookmarksRows;

  // Loading...
  public readonly loading$: Observable<boolean>;
  public readonly createLoading$: Observable<boolean>;
  public readonly removeLoading$: Observable<boolean>;
  public readonly shareLoading$: Observable<boolean>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _createLoading$ = new LoadingSubject();
  private readonly _removeLoading$ = new LoadingSubject();
  private readonly _shareLoading$ = new LoadingSubject();

  public constructor(
    private readonly bookmarkService: BookmarkService,
    private readonly ajsModalService: AjsModalService,
    private readonly stateService: StateService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly toasterService: ToasterService,
    private readonly intlService: IntlService,
    private readonly filterService: FilterService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this.bookmarkService.loading$.pipe(takeUntil(this._destroy$));
    this.createLoading$ = this._createLoading$.asObservable();
    this.removeLoading$ = this._removeLoading$.asObservable();
    this.shareLoading$ = this._shareLoading$.asObservable();
    this.setNameSuggestion();

    this.variableBookmarkType$ = this.bookmarkService.variableBookmarkType$.pipe(takeUntil(this._destroy$));
    this.currentState$ = this.bookmarkService.currentState$.pipe(takeUntil(this._destroy$));
    this.bookmarks$ = combineLatest([
      this.bookmarkService.currentState$,
      this.bookmarkService.bookmarks$
    ]).pipe(
      map(([state, bookmarks]) => {
        const userPart = _.partition(bookmarks.user, { state: state?.name });
        const sharedPart = _.partition(bookmarks.shared, { state: state?.name });
        return {
          sticky: bookmarks.sticky.filter(b => b.state === state?.name),
          saved: {
            user: {
              page: userPart[0],
              other: userPart[1],
            },
            shared: {
              page: sharedPart[0],
              other: sharedPart[1],
            }
          }
        };
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._createLoading$.complete();
    this._removeLoading$.complete();
    this._shareLoading$.complete();
  }

  public showBookmark(bookmark: Bookmark): void {
    this.filterService.filteredFacilityIds$.pipe(
      take(1)
    ).subscribe(filteredFacilityIds => {
      const clonedBookmark = cloneDeep(bookmark);
      this.handleSpecialStates(clonedBookmark);
      if (clonedBookmark.rollingDate) {
        this.bookmarkService.handleDateVariableBookmark(clonedBookmark);
      }
      if (Array.hasItems(filteredFacilityIds) && Array.hasItems(clonedBookmark.stateParams?.facilityId)) {
        clonedBookmark.stateParams.facilityId = clonedBookmark.stateParams.facilityId.filter(
          (facilityId: number) => filteredFacilityIds.includes(facilityId)
        );
      }
      if (clonedBookmark.modal) {
        this.ajsModalService.getModalWithComponent(
          clonedBookmark.modal, clonedBookmark.stateParams
        );
      } else {
        this.stateService.go(
          clonedBookmark.state, clonedBookmark.stateParams, { reload: true }
        );
        super.dismissModal();
      }
    });
  }

  public createBookmark(): void {
    this.currentState$.pipe(
      take(1),
      switchMap(state => this.bookmarkService.createBookmark(
        this.bookmarkInputField.value, state, this.dateAdjustableField.value
      ).pipe(
        indicate(this._createLoading$)
      ))
    ).subscribe({
      next: () => {
        this.toasterService.success('BOOKMARK.BOOKMARK_SAVED');
        this.bookmarkInputField.reset();
        this.dateAdjustableField.setValue(false);
      },
      error: () => this.toasterService.error('BOOKMARK.BOOKMARK_SAVE_FAILED')
    });
  }

  public shareBookmark(bookmark: Bookmark): void {
    this.bookmarkService.setShareStatus(bookmark.id, true).pipe(
      indicate(this._shareLoading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('BOOKMARK_WIDGET.SHARE_SUCCESSFULL');
      },
      error: () => this.toasterService.error('BOOKMARK_WIDGET.SHARE_FAILED')
    });
  }

  public unShareBookmark(bookmark: Bookmark): void {
    this.bookmarkService.setShareStatus(bookmark.id, false).pipe(
      indicate(this._shareLoading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('BOOKMARK_WIDGET.UNSHARE_SUCCESSFULL');
      },
      error: () => this.toasterService.error('BOOKMARK_WIDGET.UNSHARE_FAILED')
    });
  }

  public removeBookmark(bookmark: Bookmark): void {
    this.dialogService.getConfirmationModal({
      title: 'BOOKMARK_WIDGET.REMOVE_BOOKMARK_TITLE',
      text: this.translateService.instant(
        'BOOKMARK_WIDGET.REMOVE_BOOKMARK',
        { title: bookmark.title }
      ),
      isDelete: true,
      translate: true
    }).subscribe({
      next: () => {
        this.bookmarkService.removeBookmark(bookmark.id).pipe(
          indicate(this._removeLoading$)
        ).subscribe({
          next: () => {
            this.toasterService.success('BOOKMARK_WIDGET.REMOVE_SUCCESSFULL');
          },
          error: () => {
            this.toasterService.error('BOOKMARK_WIDGET.REMOVE_FAILED');
          }
        });
      }
    });
  }

  public setNameSuggestion(): void {
    this.bookmarkService.bookmarks$.subscribe(bookmarks => {
      const base = this.translateService.instant('BOOKMARK.BOOKMARK');
      const date = this.intlService.formatDate(new Date(), 'd');
      let suggestion = `${base} ${date}`;
      const duplicates = bookmarks.user.filter(b => b.title.includes(suggestion));
      if (duplicates.length) {
        suggestion += ` (${duplicates.length})`;
      }
      this.bookmarkInputField.setValue(suggestion);
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private handleSpecialStates(bookmark: Bookmark): void {
    // To make old bookmarks work with stateless energy-reporting modals
    // This was added in february 2019 and can be removed at some point in the future
    // when there are no more old bookmarks in users' computers
    if (ALL_REPORTS.some(report => report.name === bookmark.state)) {
      bookmark.modal = 'report-modal';
      bookmark.stateParams = {
        reportType: bookmark.state,
        reportParams: bookmark.stateParams
      };
    }
    // Handle EnergyManagement AutoLoad
    if (bookmark.state === 'energy-management.actions' || bookmark.state === 'energy-management.comments') {
      bookmark.stateParams.automaticSearch = true;
    }
  }
}
