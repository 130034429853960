import SearchTypes from '../../constants/search-types';

const $inject = ['utils'];

class SearchTypeRadioService {
  constructor(utils) {
    this.utils = utils;
  }

  getSearchTypes() {
    const self = this;

    return [
      {
        id: SearchTypes.STORAGE_TYPE.RAW,
        name: self.utils.localizedString('MQA.SEARCH.SEARCH_TYPE.RAW_STORAGE'),
        subOptions: [
          {
            id: SearchTypes.CONTENT_TYPE.NO_FILTER,
            name: self.utils.localizedString('MQA.SEARCH.SEARCH_TYPE.NO_DEFINING')
          },
          {
            id: SearchTypes.CONTENT_TYPE.HAS_MODEL,
            name: self.utils.localizedString('MQA.SEARCH.SEARCH_TYPE.HAS_MODEL')
          }
        ]
      },
      {
        id: SearchTypes.STORAGE_TYPE.DEFECT,
        name: self.utils.localizedString('MQA.SEARCH.SEARCH_TYPE.DEFECT_STORAGE'),
        subOptions: []
      }
    ];
  }
}

SearchTypeRadioService.$inject = $inject;

export default SearchTypeRadioService;
