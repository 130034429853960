import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { AdminTabsComponent } from './components/admin-tabs/admin-tabs.component';
import { DatahubServiceNamePipe } from './pipes/datahub-service-name.pipe';
import { FacilityActionPopupComponent } from './components/facility-action-popup/facility-action-popup.component';
import { EkDropdownModule } from '../ek-dropdown/ek-dropdown.module';
import { CompanyMassEditModelComponent } from './components/company-mass-edit-model/company-mass-edit-model.component';
import { EkInputsModule } from '../ek-inputs/ek-inputs.module';
import { SharedModule } from '../shared.module';

@NgModule({
  declarations: [
    AdminTabsComponent,
    DatahubServiceNamePipe,
    FacilityActionPopupComponent,
    CompanyMassEditModelComponent
  ],
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forChild(),
    TranslateModule,
    EkDropdownModule,
    DropDownsModule,
    EkInputsModule,
    SharedModule
  ],
  exports: [
    AdminTabsComponent,
    DatahubServiceNamePipe,
    FacilityActionPopupComponent,
    CompanyMassEditModelComponent,
  ],
})
export class AdminSharedModule { }
