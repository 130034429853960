<ul *ngIf="options?.length; else noData">
  <li
    *ngFor="let item of options; let index = index"
    (click)="onCheckedChange(item)"
    [ngClass]="{ 'k-state-selected': item.checked }"
  >
    <input
      type="checkbox"
      kendoCheckBox
      id="{{ componentId }}-{{ index }}"
      [checked]="item.checked"
    />
    <label
      class="k-multiselect-checkbox"
      for="{{ componentId }}-{{ index }}"
    >
      <ng-container *ngIf="contentTemplate?.ref; else textOnly">
        <ng-template *ngTemplateOutlet="contentTemplate.ref; context: item"></ng-template>
      </ng-container>
      <ng-template #textOnly>
        {{ item.text }}
      </ng-template>
    </label>
  </li>
</ul>

<ng-template #noData>
  <label class="no-data">
    {{ 'GRID_SHORTCUTS.NO_DATA' | translate }}
  </label>
</ng-template>
