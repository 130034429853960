import ManualQaSearchListDeleteModalController from './manual-qa-search-list-delete-modal-controller';

const $inject = ['$modal'];

class ManualQaSearchListDeleteService {
  constructor($modal) {
    this.$modal = $modal;
  }

  /**
   * Returns search list delete modal instance
   *
   * @param {Object} searchList
   *
   * @returns {Object}
   */
  getModal(searchList) {
    return this.$modal.open({
      template: `
          <manual-qa-search-list-delete 
            search-list="vm.searchList"
            on-delete="vm.deleteSearchList(searchList)" 
            on-close="vm.dismiss()"
          ></manual-qa-search-list-delete>
        `,
      controller: ManualQaSearchListDeleteModalController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'tiny',
      resolve: {
        searchList: () => searchList
      }
    });
  }
}

ManualQaSearchListDeleteService.$inject = $inject;

export default ManualQaSearchListDeleteService;
