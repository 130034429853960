import _ from 'lodash';

import { ErReportType } from '../../constants/er-report-type';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';

const $inject = [
  '$q', '$scope', '$rootScope', '$state', '$log', 'ERDataService2', 'facilities', 'meters',
  'consumptions', 'documents', 'utils', 'forecastTypes', 'ERUtils', 'ConsumptionTargetDataService',
  'erParamsService', 'LoadingService'
];

function MetersReportSumController(
  $q, $scope, $rootScope, $state, $log, ERDataService2, facilities, meters,
  consumptions, documents, utils, forecastTypes, ERUtils, ConsumptionTargetDataService,
  erParamsService, LoadingService
) {
  const vm = this;

  $scope.defaultTimer = 200;
  $scope.errorTextKey = 'FACILITIES_REPORT.NO_DATA_FOR_METER_SUM_REPORT';
  $scope.facility = {};
  $scope.reportData = {
    graphData: []
  };
  vm.isLoading = LoadingService.isLoading;

  $scope.regenerateData = _.debounce(direction => {
    getFacility()
      .then(getFacilityMeters)
      .then(() => {
        const series = getSeries();
        const payload = getPayload(series, direction);

        if (erParamsService.validateSeries(series) && payload) {
          return getData(series, payload);
        }
      })
      .catch((error, title) => {
        utils.trackError(error, 'MetersReportSumController regenerate');
        erParamsService.handleError(error, title);
      })
    ;
  }, $scope.defaultTimer);

  function getData(series, payload) {
    const deferred = $q.defer();
    $scope.errorTextKey = erParamsService.getSeriesErrorTextKey(series);

    if (!$scope.errorTextKey) {
      getReportData(series, payload)
        .then(responseData => {
          createSumReport(responseData, series);
        })
        .catch(() => {
          deferred.reject();
        })
        .finally(() => {
          deferred.resolve();
        })
      ;
    }

    return deferred.promise;
  }

  function getFacility() {
    if ($scope.facility || $scope.params.facilityId.length === 0) {
      return $q.resolve();
    }

    const facilityId = $scope.params.facilityId[0];
    return facilities.getFacility(facilityId)
      .then(facility => {
        $scope.facility = _.cloneDeep(facility);

        documents.getMapImages({ reportingObjectId: facilityId })
          .then(mapImages => {
            $scope.facility.mapImages = mapImages;
          })
          .catch(() => {
            // This probably needs some actual error handling
            $log.error('Error getting map images...');
          })
        ;
      })
      .catch(() => {
        // This probably needs some actual error handling
        $log.error('Error getting facility...');
      })
    ;
  }

  function getFacilityMeters() {
    if (_.get($scope.facility, 'meters') || !$scope.facility) {
      return $q.resolve();
    }

    return meters.getMetersForFacility($scope.params.facilityId[0])
      .then(result => {
        $scope.facility.meters = result;
      })
      .catch(() => {
        // This probably needs some actual error handling
        $log.error('Error getting facility meters...');
      })
    ;
  }

  function getReportData(series, payload) {
    const responseData = [];
    const deferred = $q.defer();

    let promises = $scope.params.reportType === ErReportType.FORECAST_REPORT ?
      getMeterForecastData(payload, responseData) :
      getMeterData(payload, responseData)
    ;

    promises = promises.concat(ConsumptionTargetDataService.getData(series, payload));

    function onSuccess(result) {
      deferred.resolve(result);
    }

    function onError() {
      deferred.reject();
    }

    $q.all(promises)
      .then(onSuccess)
      .catch(onError)
    ;

    return deferred.promise;
  }

  function getMeterData(payload) {
    return [getConsumptionData(payload)];
  }

  function getMeterForecastData(originalPayload) {
    const promises = [];
    const payload = _.merge({}, originalPayload, { Cumulative: true });

    promises.push(getConsumptionData(payload));

    _.each(forecastTypes.getForecastTypeIds(), forecastTypeId => {
      promises.push(getMeterForecastDataByTypeId(payload, forecastTypeId));
    });

    return promises;
  }

  function getMeterForecastDataByTypeId(originalPayload, forecastTypeId) {
    const parameters = {
      ForecastType: forecastTypeId
    };

    const payload = _.merge({}, originalPayload, parameters);

    return getConsumptionData(payload);
  }

  function getConsumptionData(payload) {
    const deferred = $q.defer();

    consumptions
      .getConsumptionsForMeters(payload)
      .then(response => {
        deferred.resolve({
          requestData: payload,
          responseData: response
        });
      })
    ;

    return deferred.promise;
  }

  function getSeries() {
    // meters does not want relational values
    // HACK: ...except costs!
    const relationalIds = _.get($scope, 'params.series.RelationalUnitIds', []);
    return _.assign({}, $scope.params.series, {
      RelationalUnitIds: relationalIds.includes(RelationalValueId.Costs)
        ? [RelationalValueId.Costs]
        : undefined
    });
  }

  function getPayload(series) {
    // quantityIds and facilityIds
    const quantityIds = getQuantityIds($scope.params.meterId);
    const facilityIds = $scope.params.facilityId.length ? [$scope.params.facilityId[0]] : [];

    if (erParamsService.validateSeries(series) && facilityIds.length) {
      const result = {
        Quantities: _.chain(quantityIds)
          .reduce((accum, quantityId) => {
            const quantity = erParamsService.getQuantity($scope.cache, quantityId);
            if (quantity) {
              accum.push({
                ID: quantityId,
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization && series.Normalized,
                Comparables: series.Comparables || 'None',
                RelationalUnitIds: series.RelationalUnitIds,
                RelatedValues: erParamsService.filterRelatedValueIdsForQuantity(
                  $scope.cache,
                  series.RelatedValues,
                  quantityId
                ),
              });
            }
            return accum;
          }, [])
          .value(),
        Resolution: series.Resolution,
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        FacilityId: facilityIds,
        MeterId: $scope.params.meterId,
        Unit: $scope.params.unitKey
      };

      return result;
    }
    return void 0;
  }

  function getQuantityIds(meterIds) {
    // get all quantities determined in meters
    const filteredMeters = _.filter($scope.facility.meters, meter => _.includes(meterIds, meter.Id));
    return _.uniq(_.pluck(filteredMeters, 'QuantityId'));
  }

  // Merge nested objects and if the properties are numbers then add them together, else
  // fallback to jQuery.extend() result
  // Source: https://gist.github.com/greenafrican/19bbed3d8baceb0a15fd
  function mergeObjectsAdd(firstObject, secondObject) {
    // need to merge arrays to an array
    function createBase(object) {
      return _.isArray(object) ? [] : {};
    }

    const result = angular.element.extend(true, createBase(firstObject), firstObject, secondObject);
    for (const k in result) {
      if (angular.isObject(result[k]) && result[k] !== null) {
        // Ensure both objects have respective values, just for simplicity.
        // If first didn't have a value for k then the second hand (and other way round).
        firstObject[k] = firstObject[k] || createBase(secondObject[k]);
        secondObject[k] = secondObject[k] || createBase(firstObject[k]);
        result[k] = mergeObjectsAdd(firstObject[k], secondObject[k]);
      } else {
        firstObject[k] = firstObject[k] || 0;
        secondObject[k] = secondObject[k] || 0;
        result[k] = (angular.isNumber(firstObject[k]) && angular.isNumber(secondObject[k]))
          ? (firstObject[k] + secondObject[k])
          : result[k];
      }
    }
    return result;
  }

  function getEmptyQuantityResponseObject(response) {
    function reducer(result, quantityId) {
      result[quantityId] = {
        _Id: quantityId,
        _IdType: 'Quantity',
        MetersIncluded: []
      };
      return result;
    }

    return _.reduce(_.pluck(response.requestData.Quantities, 'ID'), reducer, {});
  }

  function createSumReportData(responseData) {
    const metersIncluded = [];
    let quantityResponseData = [];

    // calculate sum of quantities + add meters
    _.forEach(responseData, response => {
      quantityResponseData = getEmptyQuantityResponseObject(response);

      _.each(response.requestData.MeterId, meterId => {
        const meter = _.find($scope.facility.meters, { Id: meterId }) || {};
        const quantityId = meter.QuantityId;
        const meterConsumption = response.responseData[meterId];

        if (quantityId && quantityResponseData[quantityId] && meterConsumption) {
          quantityResponseData[quantityId] = mergeObjectsAdd(
            quantityResponseData[quantityId],
            _.pick(meterConsumption, ['Values', 'Aggregates', 'ErrorCodes'])
          );

          metersIncluded.push(meter);
          quantityResponseData[quantityId].MetersIncluded.push(meter.Id);
        }
      });

      response.responseData = quantityResponseData;
    });

    $scope.facility.metersIncluded = _.uniq(metersIncluded);

    return responseData;
  }

  function createSumReport(responseData, series) {
    const options = {
      idType: 'Quantity',
      series: series,
      params: $scope.params,
      cache: $scope.cache,
      api: createSumReportData(responseData),
      show: {
        distributionAndValue: false
      },
      chart: {
        getCurrentChart: function(_options, data) {
          let chart = _.find($scope.reportData.graphData, { id: data.quantityId });
          if (!chart) {
            chart = {
              id: data.quantityId
            };
            $scope.reportData.graphData.push(chart);
          }
          _.extend(chart, {
            title: `${_.get(data, 'quantity.Name')} [${data.unit}]`,
            unit: data.unit,
            quantity: data.quantity,
            format: data.format,
            count: (_.get(data, 'consumptions.0.MetersIncluded') || []).length
          });
          return chart;
        },
        serieClick: {
          enabled: true
        },
        separateQuantityProperties: $scope.params.reportType === ErReportType.FORECAST_REPORT
      },
      grid: {
        getCurrentGrid: () => {
          if (!$scope.reportData.tableData) {
            $scope.reportData.tableData = {
              gridContent: {
                hidden: false,
                modified: false
              }
            };
          }
          return $scope.reportData.tableData;
        },
        aggregates: $scope.params.reportType === ErReportType.FORECAST_REPORT ? [] : ['sum', 'min', 'max', 'average'],
        excel: {} // todo
      }
    };
    ERDataService2.getVisuals(options);

    // post process and remove empty graphs
    $scope.reportData.graphData = _.filter(
      $scope.reportData.graphData,
      graph => _.get(graph, 'chartOptions.series', []).length
    );
  }

  const exportStartingUnbind = $rootScope.$on('export.starting', () => {
    const series = $scope.params.series;
    const payload = getPayload(series, 0);

    if (payload.MeterId.length === 0) {
      utils.popUpGeneralError('DOWNLOAD', 'REPORT', utils.localizedString('EXCEL.NO_METERS_SELECTED_ERROR'));
      return;
    }

    const payloadCombined = {
      ConsumptionsRequest: payload,
      localizedStrings: ERDataService2.getLocalizedStringsForExcel(payload)
    };

    const filename = $scope.facility.Name;

    consumptions.createMetersExcelExport(payloadCombined, filename)
      .catch(() => {
        utils.popUp('error', 'EXCEL.EXCEL', 'EXCEL.EXCEL_REPORT_DOWNLOAD_FAILED', true);
      })
    ;
  });

  vm.$onChanges = () => {
    if (!$scope.params) {
      return;
    }
    const paramsData = erParamsService.getChangedParamsConfig(vm.reportParams, $scope.params);
    $scope.params = vm.reportParams;
    const interestedParams = _.without(_.get(paramsData, 'changedParams'), 'sections');
    if (interestedParams.length || _.get(paramsData, 'forceUpdate')) {
      if (_.includes(interestedParams, 'meterId')) {
        const newQuantityIds = getQuantityIds($scope.params.meterId);
        // remove unnecessary charts and columns
        $scope.reportData.graphData = _.filter(
          $scope.reportData.graphData,
          chart => _.includes(newQuantityIds, chart.id)
        );
        $scope.reportData.tableData.gridOptions.columns = _.filter(
          $scope.reportData.tableData.gridOptions.columns,
          column => column.quantityId ? _.includes(newQuantityIds, column.quantityId) : true
        );
      }
      $scope.regenerateData();
    }
  };

  // initialize
  vm.$onInit = () => {
    $scope.params = vm.reportParams;
    $scope.cache = vm.cache;
    $scope.regenerateData();
  };

  vm.$onDestroy = () => {
    exportStartingUnbind();
  };
}

MetersReportSumController.$inject = $inject;

export default MetersReportSumController;
