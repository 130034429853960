export class Quantity {
  private id: number;
  private name: string;
  private key: string;
  private unit: string;

  public constructor(values?: any) {
    this.id = values && values.id ? parseInt(values.id, 10) : null;
    this.name = values && values.name ? values.name : '';
    this.key = values && values.name ? values.name : '';
    this.unit = values && values.unit ? values.unit : '';
  }

  /**
   * Returns quantity id
   *
   * @returns {Number|null}
   */
  public getId(): number {
    return this.id;
  }

  /**
   * Returns quantity name
   *
   * @returns {String}
   */
  public getName(): string {
    return this.name;
  }

  /**
   * Returns quantity key
   *
   * @returns {String}
   */
  public getKey(): string {
    return this.key;
  }

  /**
   * Returns quantity unit
   *
   * @returns {String}
   */
  public getUnit(): string {
    return this.unit;
  }
}
