import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { forkJoin, Observable } from 'rxjs';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import {
  BulkModifyProfilesCompanyRequest,
  UserManagementClient
} from '@enerkey/clients/user-management';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { Facility, FacilityClient, IUpdateFacilities, UpdateFacilities } from '@enerkey/clients/facility';

import { ToasterService } from '../../../services/toaster.service';
import { DialogService } from '../../../services/dialog.service';

export class FacilityUpdateCompany extends Facility implements IUpdateFacilities {

  public constructor(facility: Facility, companyId?: number | null) {
    super();
    Object.assign(this, facility);
    this.companyId = companyId;
  }
}

@Component({
  selector: 'enerkey-profile-company-mass-edit-model',
  templateUrl: './company-mass-edit-model.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'small' })
export class CompanyMassEditModelComponent extends ModalBase<void> implements OnDestroy {

  public updateForm: UntypedFormGroup;
  public selectedProfiles: number[] = [];
  public selectedFacilities: Facility[] = [];
  private readonly _loading = new LoadingSubject(true);

  public constructor(
    private readonly toasterService: ToasterService,
    private readonly userManagementClient: UserManagementClient,
    private readonly facilityClient: FacilityClient,
    private readonly dialogService: DialogService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.updateForm = new UntypedFormGroup({
      companyId: new UntypedFormControl()
    });
  }

  public getIsFacility(): boolean {
    return !this.getIsProfile() && this.selectedFacilities?.length > 0;
  }

  public getIsProfile(): boolean {
    return this.selectedProfiles?.length > 0;
  }

  public ngOnDestroy(): void {
    this._loading.complete();
  }

  public submit(): void {
    const companyId = this.updateForm.value.companyId;
    if (companyId) {
      this.dialogService.getConfirmationModal({
        text: this.getUpdateConfirmationText(),
        title: 'ADMIN.BULK_MODIFY_COMPANY.MODIFY_COMPANY_BUTTON',
        isDelete: false,
        translate: true,
      }).subscribe({
        next: () => this.sendUpdateRequest(companyId),
        error: () => { },
      });
    }
  }

  public sendUpdateRequest(companyId: number): void {

    let updated: Observable<void>;
    if (this.getIsProfile()) {
      updated = this.userManagementClient.bulkModifyProfilesCompany(
        new BulkModifyProfilesCompanyRequest({ companyId: companyId, profileIds: this.selectedProfiles })
      );
    }
    if (this.getIsFacility()) {
      const updateFacilities = this.selectedFacilities.map(facility => {
        const updateFacility = new UpdateFacilities(new FacilityUpdateCompany(facility, companyId));
        return updateFacility;
      });
      updated = this.facilityClient.updateFacilities(updateFacilities);
    }

    forkJoin([updated]).pipe(
      indicate(this._loading)
    ).subscribe({
      next: () => {
        this.toasterService.success(this.getToasterTextSuccess());
        super.closeModal();
      },
      error: () => {
        this.toasterService.error(this.getToasterTextError());
      },
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private getUpdateConfirmationText(): string {
    if (this.getIsProfile()) {
      return 'ADMIN.BULK_MODIFY_COMPANY.SAVE_PROFILES_CONFIRMATION';
    } else if (this.getIsFacility()) {
      return 'ADMIN.BULK_MODIFY_COMPANY.SAVE_FACILITIES_CONFIRMATION';
    }
    return '';
  }

  private getToasterTextSuccess(): string {
    if (this.getIsProfile()) {
      return 'ADMIN.BULK_MODIFY_COMPANY.SAVE_PROFILES_SUCCESS';
    } else if (this.getIsFacility()) {
      return 'ADMIN.BULK_MODIFY_COMPANY.SAVE_FACILITIES_SUCCESS';
    }
    return '';
  }

  private getToasterTextError(): string {
    if (this.getIsProfile()) {
      return 'ADMIN.BULK_MODIFY_COMPANY.SAVE_PROFILES_ERROR';
    } else if (this.getIsFacility()) {
      return 'ADMIN.BULK_MODIFY_COMPANY.SAVE_FACILITIES_ERROR';
    }
    return '';
  }
}
