import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { EkComboComponent } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { WidgetChangeOption } from '../../../energy-reporting/shared/widget-constants';
import { getStringEnumValues } from '@enerkey/ts-utils';

const changeTranslationKeys: Record<WidgetChangeOption, string> = {
  [WidgetChangeOption.Absolute]: 'CHANGE.ABSOLUTE',
  [WidgetChangeOption.Relative]: 'CHANGE.RELATIVE',
};

@Component({
  selector: 'widget-change-select',
  templateUrl: '../../../../shared/ek-inputs/ek-combo/ek-combo.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WidgetChangeSelectComponent),
    multi: true
  }]
})
export class WidgetChangeSelectComponent extends EkComboComponent<WidgetChangeOption> {
  public override items = getStringEnumValues(WidgetChangeOption).map(
    option => ({
      value: option,
      text: changeTranslationKeys[option]
    })
  );
}
