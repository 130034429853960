<div *ngIf="options?.length; else noData">
  <kendo-multiselect
    [data]="options"
    [(ngModel)]="selection"
    (valueChange)="valueChanged()"
    textField="text"
    valueField="value"
    [kendoDropDownFilter]="filterSettings"
    [valuePrimitive]="true"
  >
    <ng-template
      kendoMultiSelectItemTemplate
      let-dataItem
    >
      <ng-container *ngTemplateOutlet="textTemplate; context: { $implicit: dataItem }"></ng-container>
    </ng-template>
    <ng-template
      kendoMultiSelectTagTemplate
      let-dataItem
    >
      <ng-container *ngTemplateOutlet="textTemplate; context: { $implicit: dataItem }"></ng-container>
    </ng-template>
  </kendo-multiselect>
</div>

<ng-template
  #textTemplate
  let-dataItem
>
  <ng-container *ngIf="contentTemplate?.ref; else textOnly">
    <ng-template *ngTemplateOutlet="contentTemplate.ref; context: dataItem"></ng-template>
  </ng-container>
  <ng-template #textOnly>
    {{ dataItem.text }}
  </ng-template>
</ng-template>

<ng-template #noData>
  <label class="no-data">
    {{ 'GRID_SHORTCUTS.NO_DATA' | translate }}
  </label>
</ng-template>
