import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ValueFormatPipe } from '../../../shared/energy-reporting-shared/pipes/value-format.pipe';
import { ReportingSeries } from '../shared/reporting-series';

@Pipe({
  name: 'tableReportValueFormat'
})
export class TableReportValueFormatPipe implements PipeTransform {
  public constructor(
    private readonly valueFormatPipe: ValueFormatPipe
  ) { }

  public transform(serie: ReportingSeries, isChange?: boolean): Observable<string> {
    if (serie.options.isPercentSerie && !isChange) {
      return of('p1');
    }
    if (serie.isRelatedSerie) {
      return of('#,#.0');
    }
    return this.valueFormatPipe.transform(
      serie.options.quantityId,
      serie.options.unitKey,
      null,
      serie.chartItemOptions.derivedId
    );
  }
}
