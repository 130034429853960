import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { catchError, map, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { AlarmLogGridItem } from '../../shared/alarm-log-grid-item';
import { AlarmService } from '../../../../shared/services/alarm.service';

@Component({
  selector: 'alarm-details',
  templateUrl: './alarm-details.component.html',
  styleUrls: ['./alarm-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmDetailsComponent implements OnInit {

  @Input() public alarm: AlarmLogGridItem;

  public quantityUnit$: Observable<string>;
  public hasPercentValue$: Observable<boolean>;

  public constructor(
    private readonly alarmService: AlarmService
  ) { }

  public ngOnInit(): void {
    this.quantityUnit$ = this.alarmService.getUnitForAlarmLog(this.alarm).pipe(
      catchError(() => of('')),
      take(1)
    );

    this.hasPercentValue$ = this.alarmService.getAllAlarmTypes().pipe(
      map(types => types.find(t => t.id === this.alarm.alarm.alarmTypeId)),
      map(t => t?.hasPercentValue ?? false),
      catchError(() => of(false)),
      take(1)
    );
  }
}
