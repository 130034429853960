<div class="widget">
  <table class="table striped">
    <thead>
      <th>
        {{ 'ALARM.ALARM_GRID.ALARM_TYPE' | translate }}
      </th>
      <th class="show-for-medium">
        {{ 'ALARM.LOG.FACILITY_NAME' | translate }}
      </th>
      <th>
        {{ 'ALARM.LOG.METER_NAME' | translate }}
      </th>
      <th>
        {{ 'ALARM.LOG.STATUS.TITLE' | translate }}
      </th>
      <th>
        {{ 'ALARM.LOG.EXECUTED_AT' | translate }}
      </th>
    </thead>
    <tbody *ngIf="alarms$ | async as alarms">
      <tr *ngIf="alarms.length === 0">
        <td><strong>{{ 'GRID_SHORTCUTS.NO_DATA' | translate }}</strong></td>
      </tr>
      <tr *ngFor="let alarm of alarms">
        <td>
          <a (click)="openAlarmModal(alarm)">
            {{ alarm.alarmTypeId | alarmTypeName }}
          </a>
        </td>
        <td class="show-for-medium">
          <a (click)="openFacilityReport(alarm)">
            {{ alarm.facilityDisplayName }}
          </a>
        </td>
        <td>
          <a (click)="openMeterReport(alarm)">
            {{ alarm.meterDisplayName }}
          </a>
        </td>
        <td>
          <alarm-log-status [status]="alarm.status"></alarm-log-status>
        </td>
        <td>
          {{ alarm.executedAt | date: 'short' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
