<kendo-chart [transitions]="false">
  <kendo-chart-area [margin]="3">
  </kendo-chart-area>
  <kendo-chart-legend
    position="right"
    [spacing]="3"
    orientation="vertical"
    [labels]="legendLabels"
    [margin]="{ left: 5 }"
    [padding]="0"
  >
  </kendo-chart-legend>
  <kendo-chart-title
    [color]="blackColor"
    [text]="quantityId | quantityName | async"
    [font]="boldFont"
    [margin]="0"
    [padding]="0"
  ></kendo-chart-title>
  <kendo-chart-series>
    <kendo-chart-series-item
      [name]="readingField | yearlyTrendReportTableCellHeader:quantityId | async"
      type="column"
      [data]="consumptions"
      [field]="readingField"
      [categoryField]="categoryField"
      [color]="quantityId | quantityColor"
      [highlight]="{ visible: false }"
    >
    </kendo-chart-series-item>
    <kendo-chart-series-item
      *ngIf="quantityId === Quantities.HeatingEnergy"
      [name]="normalizedReadingField | yearlyTrendReportTableCellHeader:quantityId | async"
      type="column"
      [data]="consumptions"
      [field]="normalizedReadingField"
      [categoryField]="categoryField"
      [color]="normalizedBarColor"
      [highlight]="{ visible: false }"
    >
    </kendo-chart-series-item>
    <kendo-chart-series-item
      [name]="specificConsumptionField | yearlyTrendReportTableCellHeader:quantityId | async"
      type="line"
      [data]="consumptions"
      [field]="specificConsumptionField"
      [categoryField]="categoryField"
      [color]="specificConsumptionColor"
      [highlight]="{ visible: false }"
      [axis]="specificConsumptionAxis"
    >
    </kendo-chart-series-item>
  </kendo-chart-series>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item
      [labels]="categoryAxisLabels"
      [axisCrossingValue]="[0, 32]"
    >
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item
      [narrowRange]="false"
      [labels]="valueAxisLabels"
    >
    </kendo-chart-value-axis-item>
    <kendo-chart-value-axis-item
      [narrowRange]="false"
      [labels]="specificConsumptionValueAxisLabels"
      [name]="specificConsumptionAxis"
      [color]="specificConsumptionColor"
    >
    </kendo-chart-value-axis-item>
  </kendo-chart-value-axis>
</kendo-chart>
