import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, firstValueFrom, forkJoin, merge, Observable, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { cloneDeep as _cloneDeep } from 'lodash';
import { geometry, Text } from '@progress/kendo-drawing';

import { MeteringClient, Quantities } from '@enerkey/clients/metering';
import { EnergyReportingClient, QuantityItem } from '@enerkey/clients/energy-reporting';
import { isEnumKey, isNumericEnum } from '@enerkey/ts-utils';
import { switchJoin } from '@enerkey/rxjs';

import { ReportingUnit } from '@enerkey/clients/reporting';

import { CoefficientMethods } from '../../modules/configuration/constants/coefficient-methods';
import { UserService } from '../../services/user-service';
import { LanguageChangeService } from './language-change.service';
import { ProfileService } from './profile.service';
import { ColorService } from './color.service';
import { FacilityService } from './facility.service';
import {
  coefficientArray,
  coeffMethodsPerQuantity,
  conditionallyDisplayedQuantities,
  IQuantityGroup,
  quantityDecimals,
  QuantityGroup,
  quantityGroupDefinitions,
  quantityTranslations,
} from '../../constants/quantity.constant';

function hasOneSummableQuantity(ids: number[], allQuantityIds: number[]): boolean {
  return ids.some(id => allQuantityIds.includes(id));
}

function shouldShowGroupingQuantity(
  quantityId: Quantities,
  quantities: QuantityItem[]
): boolean {
  return !conditionallyDisplayedQuantities.has(quantityId)
    || conditionallyDisplayedQuantities
      .get(quantityId)
      .some(q => quantities.some(quantity => quantity.ID === q));
}

@Injectable({ providedIn: 'root' })
export class QuantityService {
  public readonly allQuantities$: Observable<QuantityItem[]>;
  public readonly profileQuantities$: Observable<QuantityItem[]>;
  public readonly normalizedQuantityIds$: Observable<Quantities[]>;

  private readonly firstSumQuantityId = 1000;
  private readonly refreshProfileQuantities$: Observable<unknown>;
  private readonly refreshAllQuantities$: Observable<unknown>;

  private readonly refreshOnGoing$ = new BehaviorSubject<boolean>(false);
  private readonly profileQuantitiesRefreshOngoing$ = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly translate: TranslateService,
    private readonly meteringClient: MeteringClient,
    private readonly energyReportingClient: EnergyReportingClient,
    private readonly languageChangeService: LanguageChangeService,
    private readonly profileService: ProfileService,
    private readonly facilityService: FacilityService,
    private readonly userService: UserService,
    private readonly colorService: ColorService
  ) {
    this.refreshProfileQuantities$ = this.profileService.profile$;
    this.refreshAllQuantities$ = merge(
      this.languageChangeService.languageChange,
      this.refreshProfileQuantities$
    );

    this.allQuantities$ = this.refreshAllQuantities$.pipe(
      switchMap(() => this.energyReportingClient.getQuantityForReportObjectSetId()),
      map(quantities => quantities.map(quantity => this.getQuantityWithLocalizedName(quantity))),
      catchError(error => throwError(error.status)),
      tap(() => this.refreshOnGoing$.next(false)),
      shareReplay(1),
      skipWhile(() => this.refreshOnGoing$.value)
    );

    this.normalizedQuantityIds$ = this.allQuantities$.pipe(
      map(quantities => quantities.filterMap(q => q.Normalization, q => q.ID)),
      shareReplay(1)
    );

    this.refreshAllQuantities$.subscribe(() => {
      this.refreshOnGoing$.next(true);
    });

    this.refreshProfileQuantities$.subscribe(() => {
      this.profileQuantitiesRefreshOngoing$.next(true);
    });

    this.profileQuantities$ = this.facilityService.profileFacilities$.pipe(
      switchJoin(() => this.allQuantities$.pipe(take(1))),
      switchMap(([facilities, quantityData]) => forkJoin([
        of(quantityData),
        this.meteringClient.getFacilitiesMainMeterQuantities(facilities.map(f => f.facilityId))
      ])),
      map(([items, enums]) => items.filter(x => x.ID >= 1000 || enums.includes(x.ID))),
      map(quantities => this.filterOnlyAllowedQuantities(quantities)),
      tap(() => this.profileQuantitiesRefreshOngoing$.next(false)),
      shareReplay(1),
      skipWhile(() => this.profileQuantitiesRefreshOngoing$.value)
    );

  }

  /**
   * Get all quantities as an observable that always completes after first emit
   */
  public getAllQuantities(): Observable<QuantityItem[]> {
    return this.allQuantities$.pipe(take(1));
  }

  /**
   * Get profile quantities as an observable that always completes after first emit
   */
  public getProfileQuantities(): Observable<QuantityItem[]> {
    return this.profileQuantities$.pipe(take(1));
  }

  public getQuantityTranslation(quantity: Quantities): Observable<string> {
    return this.translate.get(quantityTranslations[quantity] ?? quantityTranslations[Quantities.Undefined]);
  }

  /**
   * @param quantity Quantity ID, Key, or whole QuantityItem
   * @deprecated Use getQuantityTranslation instead if possible.
   */
  public getQuantityLocalizedName(quantity: Quantities | number | string | QuantityItem): string {
    let quantityId: Quantities;

    switch (typeof quantity) {
      case 'object':
        quantityId = quantity?.ID ?? undefined;
        break;
      case 'string':
        quantityId = isEnumKey(Quantities, quantity) ? Quantities[quantity] : undefined;
        break;
      case 'number':
        quantityId = quantity;
        break;
      default:
        break;
    }

    if (quantityId === undefined || !isNumericEnum(Quantities, quantityId)) {
      return '';
    }

    return this.translate.instant(quantityTranslations[quantityId]);
  }

  public getQuantityNameForId(quantityId: number): string {
    if (!quantityId) {
      return '';
    }
    return this.getQuantityLocalizedName(quantityId);
  }

  public getAllNamesById(): Observable<Record<number, string>> {
    return this.allQuantities$.pipe(
      take(1),
      map(allQuantities => allQuantities.toRecord(
        q => q.ID,
        q => this.translate.instant(
          quantityTranslations[q.ID as Quantities]
          ?? quantityTranslations[Quantities.Undefined]
        )
      ))
    );
  }

  public getCoefficientMethodForQuantity(quantityId: number): number {
    if (coeffMethodsPerQuantity.get(quantityId) === undefined) {
      return CoefficientMethods.FLAT;
    }

    return coeffMethodsPerQuantity.get(quantityId);
  }

  public getCoefficient(quantityId: number): readonly number[] {
    if (coeffMethodsPerQuantity.get(quantityId) === undefined) {
      return coefficientArray.get(CoefficientMethods.FLAT);
    } else {
      return coefficientArray.get(coeffMethodsPerQuantity.get(quantityId));
    }
  }

  public getQuantityById(quantityId: number): Observable<QuantityItem> {
    return this.getAllQuantities().pipe(
      map(quantities => quantities.find(quantity => quantity.ID === quantityId))
    );
  }

  /**
   * @param quantityId Quantity ID as number or string eg. 15 or "15"
   * @deprecated Used by legacy code
   */
  public getQuantity(quantityId: number | string): Promise<QuantityItem> {
    const parsedQuantityId = parseInt(quantityId as string, 10);
    return firstValueFrom(this.getQuantityById(parsedQuantityId));
  }

  /**
   * Returns quantities for current profile excluding sum quantities
   */
  public getProfileQuantitiesExcludingSumQuantities(): Observable<QuantityItem[]> {
    return this.getProfileQuantities()
      .pipe(
        map(quantities => quantities.filter(q => !this.isSumQuantity(q))),
        shareReplay(1)
      );
  }

  /**
   * Returns sum quantities for current profile
   */
  public getProfileSumQuantities(): Observable<QuantityItem[]> {
    return this.getProfileQuantities()
      .pipe(
        map(quantities => this.filterOutUselessSumQuantities(quantities)),
        map(quantities => quantities.filter(q => this.isSumQuantity(q)))
      );
  }

  /**
   * Returns all quantities for current profile.
   * @deprecated
   */
  public getQuantities(): Promise<QuantityItem[]> {
    return firstValueFrom(this.getProfileQuantities());
  }

  /**
   * Get quantities for facility excluding sum quantities that don't have 2 or more summable quantitities present
   *
   * @param facilityId
   */
  public async getSignificantQuantitiesForFacility(facilityId: number): Promise<QuantityItem[]> {
    return this.getQuantitiesForFacility(facilityId)
      .then(quantities => this.filterOutUselessSumQuantities(quantities));
  }

  public getSignificantQuantitiesForProfile(): Promise<QuantityItem[]> {
    return firstValueFrom(this.getProfileQuantities().pipe(
      map(q => this.filterOutUselessSumQuantities(q))
    ));
  }

  /**
   * @deprecated Used by legacy code
   */
  public getQuantitiesForFacility(facilityId: number): Promise<QuantityItem[]> {
    return firstValueFrom(this.energyReportingClient.getQuantitiesForReportingObject(facilityId)
      .pipe(
        map(quantities => quantities.map(quantity => this.getQuantityWithLocalizedName(quantity))),
        map(quantities => this.filterOnlyAllowedQuantities(quantities))
      ));
  }

  public isSumQuantity(quantity: QuantityItem | number): boolean {
    const quantityId = typeof quantity === 'number' ? quantity : quantity.ID;
    return quantityId >= this.firstSumQuantityId;
  }

  public async getGroupedQuantities(): Promise<IQuantityGroup[]> {
    return this.getQuantities().then(quantities => this.groupQuantities(quantities));
  }

  public async getGroupedQuantitiesForFacility(facilityId: number): Promise<IQuantityGroup[]> {
    return this.getQuantitiesForFacility(facilityId).then(quantities => this.groupQuantities(quantities));
  }

  public isQuantityNormalized(quantityId: Quantities): Observable<boolean> {
    return this.normalizedQuantityIds$.pipe(
      take(1),
      map(normalizedQuantityIds => normalizedQuantityIds.includes(quantityId))
    );
  }

  public getKendoChartQuantityIcon(quantityId: Quantities, color: string, position: geometry.Point): Text {
    return new Text(
      this.getQuantityIcon(quantityId),
      position,
      {
        font: '13px Enerkey2',
        fill: {
          color: color
        }
      }
    );
  }

  public getQuantityUnit(quantityId: Quantities, unitKey = ReportingUnit.Default): Observable<string> {
    return this.getQuantityById(quantityId).pipe(
      map(quantity => quantity.Units?.[unitKey]?.Unit ?? '')
    );
  }

  public getQuantityDecimals(quantityId: Quantities, unitKey = ReportingUnit.Default): Observable<number> {
    const quantityDecimalValue = quantityDecimals[quantityId]?.[unitKey]?.decimalValue ?? 0;
    return of(quantityDecimalValue);
  }

  /**
   * Returns icon charcode for quantity. Must be used with font-family: Enerkey2 to make icons work.
   */
  private getQuantityIcon(quantityId: number): string {
    const iconCharacter = this.colorService.getCssProperty(`--enerkey-quantity-icon-${quantityId}`, '');
    // Firefox incorrectly returns a string with forward slash escaped
    if (iconCharacter.startsWith('\\')) {
      const charCodeAsString = iconCharacter.replace('\\', '');
      return String.fromCharCode(parseInt(charCodeAsString, 16));
    }
    return iconCharacter;
  }

  private filterOnlyAllowedQuantities(quantities: QuantityItem[]): QuantityItem[] {
    return quantities.filter(quantity => {
      const group = quantityGroupDefinitions.find(
        g => g.quantityIds.includes(quantity.ID) || g.sumQuantityIds.includes(quantity.ID)
      );
      return !group || this.userService.hasService(group.service);
    });
  }

  private getQuantityWithLocalizedName(quantity: QuantityItem): QuantityItem {
    const localizedName = this.getQuantityLocalizedName(quantity);
    if (localizedName) {
      quantity.Name = localizedName;
    }
    return quantity;
  }

  private filterOutUselessSumQuantities(quantities: QuantityItem[]): QuantityItem[] {
    const nonSumQuantityIds = quantities.filterMap(
      q => !Array.hasItems(q.SumOf),
      q => q.ID
    );

    return quantities
      .filter(quantity => !quantity.SumOf || hasOneSummableQuantity(quantity.SumOf, nonSumQuantityIds))
      .filter(quantity => shouldShowGroupingQuantity(quantity.ID, quantities));
  }

  private groupQuantities(allQuantities: QuantityItem[]): IQuantityGroup[] {
    const remainingQuantities = _cloneDeep(allQuantities);
    const byIds = (ids: number[]): QuantityItem[] => ids.reduce(
      (result, id) => {
        const index = remainingQuantities.findIndex(({ ID }) => ID === id);
        const quantity = index >= 0 ? remainingQuantities.splice(index, 1) : null;
        return result.concat(quantity || []);
      },
      []
    );

    const nonSumQuantityIds = allQuantities.filterMap(quantity => !quantity.SumOf, quantity => quantity.ID);

    const quantityGroups = quantityGroupDefinitions.map(({ title, quantityIds, sumQuantityIds, service }) => {
      const hasService = this.userService.hasService(service);
      const groupQuantities = byIds(quantityIds);
      const groupSumQuantities = byIds(sumQuantityIds)
        .filter(quantity => hasOneSummableQuantity(quantity.SumOf, nonSumQuantityIds))
        .filter(quantity => shouldShowGroupingQuantity(quantity.ID, groupQuantities))
        ;

      return {
        title,
        quantities: hasService ? groupQuantities : [],
        sumQuantities: hasService ? groupSumQuantities : []
      };
    });

    if (Array.hasItems(remainingQuantities)) {
      const undefinedQuantities = remainingQuantities.reduce(
        ({ quantities, sumQuantities }, quantity) => {
          if (quantity.SumOf) {
            return hasOneSummableQuantity(quantity.SumOf, nonSumQuantityIds)
              ? { quantities, sumQuantities: [...sumQuantities, quantity] }
              : { quantities, sumQuantities };
          }
          return { quantities: [...quantities, quantity], sumQuantities };
        }
        ,
        { quantities: [], sumQuantities: [] }
      );

      quantityGroups.push({ title: QuantityGroup.Undefined, ...undefinedQuantities });
    }

    return quantityGroups;
  }
}
