import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, map, Observable, switchMap } from 'rxjs';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import {
  AddOrUpdateEmailSubscriptionSettingsRequest,
  EmailConsumptionReportClient,
  FacilityFrequencyBase
} from '@enerkey/clients/email-consumption-report';

import { AuthenticationService } from '../../../shared/services/authentication.service';

@Injectable()
export class EmailConsumptionReportService {
  public readonly consumptionEmailSubscriptions$: Observable<FacilityFrequencyBase[]>;
  public readonly refreshConsumption$ = new BehaviorSubject<boolean>(true);
  public readonly consumptionSubscriptionLoading$: Observable<boolean>;
  public readonly userName: string;

  private readonly _consumptionSubscriptionLoading$ = new LoadingSubject();

  public constructor(
    private readonly emailConsumptionReportClient: EmailConsumptionReportClient,
    private readonly authenticationService: AuthenticationService
  ) {
    this.consumptionSubscriptionLoading$ = this._consumptionSubscriptionLoading$.asObservable();

    this.userName = this.authenticationService.getUsername();

    this.consumptionEmailSubscriptions$ = this.refreshConsumption$.pipe(
      switchMap(() => this.emailConsumptionReportClient.getEmailSubscription(this.userId)
        .pipe(
          indicate(this._consumptionSubscriptionLoading$),
          map(result => result?.facilities)
        ))
    );
  }

  private get userId(): number {
    return this.authenticationService.getUserId();
  }

  public addEmailConsumptionSubscription(subscriptions: FacilityFrequencyBase[]): Observable<void> {
    return this.emailConsumptionReportClient
      .addOrUpdateEmailSubscription(new AddOrUpdateEmailSubscriptionSettingsRequest({
        facilities: subscriptions,
        userId: this.userId
      })).pipe(catchError(() => {
        this.refreshConsumption$.next(true);
        return EMPTY;
      }));
  }
}
