import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { startOfYear } from 'date-fns';
import { StateService } from '@uirouter/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Quantities } from '@enerkey/clients/metering';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ReportingUnit } from '@enerkey/clients/reporting';
import { FacilityInformationGroup } from '@enerkey/clients/energy-reporting';
import { percentageChange } from '@enerkey/ts-utils';

import { WidgetBase } from '../../shared/widget-base.interface';
import { TimeFrameOptions } from '../../../../constants/time-frame';
import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { ErTimeFrameService, TimeFrameResult } from '../../../energy-reporting/services/er-time-frame.service';

import { ReportingSearchParams } from '../../../reporting/shared/reporting-search-params';
import { PeriodReportService } from '../../../reporting/services/period-report.service';
import { ReportingSearchService } from '../../../reporting/services/reporting-search.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { DurationName } from '../../../reporting/shared/reporting-search-form-value';
import { getDefaultReportingParams } from '../../../reporting/services/reporting.search.functions';
import { FacilityQuantityValues, mapQuantityDataByFacilities }
  from '../../../reporting/shared/table-report-functions';
import { WidgetChangeOption, WidgetRelatedValueOption } from '../../../energy-reporting/shared/widget-constants';
import { AjsModalService } from '../../../../services/modal/modal.service';
import { TableSortOption } from '../../directives/table-sort.directive';
import { getValueTypeOptions } from '../../../energy-reporting/shared/value-type-options';
import { localToUtc } from '../../../../shared/date.functions';

enum RelatedSerieTypes {
  Min = 'relatedMin',
  Max = 'relatedMax',
  Average = 'relatedAverage'
}

export interface PowerWidgetOptions {
  comparisonPeriodOption: 'Default' | number;
  selectedQuantity: Quantities;
  timeFrameOption: TimeFrameOptions;
  valueOption: ValueType;
  minMaxAvg: MinMaxAvg;
  change: {
    absolute: boolean,
    relative: boolean,
  }
}

export class MinMaxAvg {
  public min: boolean;
  public max: boolean;
  public average: boolean;

  public constructor(min: boolean = false, max: boolean = false, average: boolean = false) {
    this.min = min;
    this.max = max;
    this.average = average;
  }
}

export type FacilityRow = FacilityInformationGroup &
{
  values: Record<'comparison' | 'inspection', Partial<number>> &
  { change: number }
};

type FilterForm = {
  selectedQuantity: FormControl<Quantities> | Quantities;
  minMaxAvg: FormControl<WidgetRelatedValueOption> | WidgetRelatedValueOption;
}

@Component({
  selector: 'power-widget',
  templateUrl: './power-widget.component.html',
  styleUrls: ['./power-widget.component.scss', '../change-component-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ReportingSearchService]
})
export class PowerWidgetComponent implements WidgetBase<PowerWidgetOptions>, OnDestroy, OnInit {
  public readonly WidgetRelatedValueOption: typeof WidgetRelatedValueOption = WidgetRelatedValueOption;
  public readonly dataModelChange$: Observable<PowerWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;
  public readonly quantities$: Observable<Quantities[]>;
  public readonly filterFormGroup: FormGroup;

  public dataModelOptions: PowerWidgetOptions;
  public inspectionPeriodTitle: string;
  public comparisonPeriodTitle: string;
  public start: TimeFrameResult;
  public params$: Observable<ReportingSearchParams>;
  public data$: Observable<FacilityRow[]>;
  public sortOptions$: Observable<TableSortOption>;
  public unitKey: ReportingUnit;

  private readonly _cache: Map<string, FacilityRow[]> = new Map();

  private readonly _dataModelChange = new Subject<PowerWidgetOptions>();
  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();
  private readonly _refresh$ = new BehaviorSubject<boolean>(true);
  private readonly _sortOptions$ = new BehaviorSubject<TableSortOption>(undefined);

  public constructor(
    private readonly fb: FormBuilder,
    private readonly erTimeFrameService: ErTimeFrameService,
    private readonly periodReportService: PeriodReportService,
    private readonly facilityService: FacilityService,
    private readonly stateService: StateService,
    private readonly angularjsModalService: AjsModalService
  ) {

    this.filterFormGroup = this.fb.group<FilterForm>({
      selectedQuantity: this.fb.control(null),
      minMaxAvg: this.fb.control(null),
    });

    this.quantities$ = of([Quantities.Electricity, Quantities.DistrictHeating]);

    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();
    this.dataModelChange$ = this._dataModelChange.asObservable();
    this.sortOptions$ = this._sortOptions$.asObservable();
  }

  public ngOnInit(): void {

    this.filterFormGroup.patchValue({
      selectedQuantity: this.dataModelOptions.selectedQuantity,
      minMaxAvg: this.patchMinMaxAvgVal(this.dataModelOptions.minMaxAvg)
    });

    this.filterFormGroup.valueChanges.pipe(
      debounceTime(500),
      takeUntil(this._destroy$)
    ).subscribe((formValue: Partial<FilterForm>) => {
      this._dataModelChange.next({
        ...this.dataModelOptions,
        minMaxAvg: {
          min: formValue.minMaxAvg === WidgetRelatedValueOption.Min,
          max: formValue.minMaxAvg === WidgetRelatedValueOption.Max,
          average: formValue.minMaxAvg === WidgetRelatedValueOption.Average
        },
        selectedQuantity: formValue.selectedQuantity as Quantities
      });
    });

    this.start = this.erTimeFrameService.getTimeFrameAndResParams(
      this.dataModelOptions.timeFrameOption,
      this.dataModelOptions.comparisonPeriodOption as string
    );

    this.comparisonPeriodTitle = this.start.Start[1]?.key;
    this.inspectionPeriodTitle = this.start.Start[0].key;

    this.unitKey = this.getParams().unit;

    this.params$ = this.dataModelChange$.pipe(
      startWith(this.dataModelOptions),
      switchMap(_ => of(this.getParams()))
    );

    this.data$ = combineLatest([
      this.facilityService.filteredProfileFacilities$,
      this.dataModelChange$.pipe(startWith(this.dataModelOptions)),
      this._sortOptions$,
      this._refresh$.asObservable().pipe(tap(() => this._cache.clear())),
    ]).pipe(
      switchMap(([facilities, data, sortOptions]) =>
        this.getPowerData(facilities, data).pipe(map(result => this.sortTable(result, sortOptions)))),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._cache.clear();

    this._destroy$.next();
    this._destroy$.complete();
    this._error$.complete();
    this._loading$.complete();
    this._refresh$.complete();
    this._sortOptions$.complete();
  }

  public goToFacilities(): void {
    this.stateService.go(
      'facilities.grid',
      this.getDefaultStateParameters(this.dataModelOptions)
    );
  }

  public onRowClick(facilityId: number): void {
    this.angularjsModalService.getModalWithComponent(
      'report-modal',
      {
        reportParams: {
          ...this.getParams(),
          facilityId: [facilityId]
        }
      }
    ).then(_ => this._refresh$.next(true));
  }

  public sortRows(sortEvent: TableSortOption): void {
    this._sortOptions$.next(sortEvent);
  }

  private sortTable(data: FacilityRow[], sortOptions?: TableSortOption): FacilityRow[] {
    const sortField = sortOptions?.field;
    const sortOrder = sortOptions?.order ?? (this.dataModelOptions.minMaxAvg.max ? 'descending' : 'ascending');

    return data.sortBy(f => {
      switch (sortField) {
        case 'name':
          return f.Name;
        case 'comparison':
          return f.values.comparison;
        case 'change':
          return f.values.change;
        case 'inspection':
        default:
          return f.values.inspection;
      }
    }, sortOrder);
  }

  private getPowerData(
    facilities: FacilityInformationGroup[],
    options: PowerWidgetOptions
  ): Observable<FacilityRow[]> {
    const phase = `${options.selectedQuantity}${options.minMaxAvg}`;
    const fromCache = this._cache.get(phase);

    if (fromCache) {
      return of(fromCache);
    }

    const facilityIds = facilities.map(f => f.FacilityId);

    return this.periodReportService.getData(
      this.getParams(),
      facilityIds,
      0
    ).pipe(
      map(facilityData => mapQuantityDataByFacilities(
        facilities, facilityData, this.getParams()
      )),
      map(data => this.processTableData(facilities, data)),
      tap(result => this._cache.set(phase, result)),
      indicate(this._loading$),
      takeUntil(this._destroy$)
    );
  }

  private processTableData(facilities: FacilityInformationGroup[], data: FacilityQuantityValues): FacilityRow[] {
    return facilities.map(f => {
      const values = data.values?.[f.FacilityId]?.[this.dataModelOptions.selectedQuantity]
        ?.[RelatedSerieTypes[this.filterFormGroup.value.minMaxAvg as WidgetRelatedValueOption]];

      const inspectionYearStart = localToUtc(startOfYear(new Date(this.start.Start[0]?.value))).getTime();
      const comparisonYearStart = localToUtc(startOfYear(new Date(this.start.Start[1]?.value))).getTime();

      const inspection = values?.[inspectionYearStart]?.value;
      const comparison = values?.[comparisonYearStart]?.value;

      const change = this.dataModelOptions.change.absolute
        ? (inspection - comparison)
        : percentageChange(+Number(inspection).toFixed(2), +Number(comparison).toFixed(2));

      return {
        ...f,
        values: {
          inspection,
          comparison,
          change
        }
      };
    }).filter(f =>
      Number.isFinite(f.values.comparison) ||
      Number.isFinite(f.values.inspection));
  }

  private getParams(): ReportingSearchParams {
    return new ReportingSearchParams({
      ...getDefaultReportingParams(),
      quantityIds: [this.dataModelOptions.selectedQuantity],
      valueType: this.dataModelOptions.valueOption,
      periods: this.start.Start.map(start => startOfYear(new Date(start.value))),
      durationName: Object.keys(this.start.Duration)[0] as DurationName,
      durationLength: Object.values(this.start.Duration)[0],
      minMaxAvg: this.dataModelOptions.minMaxAvg,
      change: this.dataModelOptions.change,
      resolution: null,
      temperature: false,
      reportingUnit: ReportingUnit.KWh
    });
  }

  private patchMinMaxAvgVal(data: MinMaxAvg): WidgetRelatedValueOption {
    if (data.min) {
      return WidgetRelatedValueOption.Min;
    } else if (data.max) {
      return WidgetRelatedValueOption.Max;
    }
    return WidgetRelatedValueOption.Average;
  }

  private getDefaultStateParameters(
    dataModelOptions: PowerWidgetOptions
  ): Record<string, unknown> {
    const valueOptions = getValueTypeOptions(dataModelOptions.valueOption);

    return {
      quantityId: [dataModelOptions.selectedQuantity],
      series: {
        Measured: valueOptions.measured,
        Normalized: valueOptions.normalized,
        RelationalUnitIds: []
      },
      unitKey: this.getParams().unit,
      changeType: (dataModelOptions.change.absolute ?
        WidgetChangeOption.Absolute
        : WidgetChangeOption.Relative).toLowerCase()
    };
  }

}

