import MeterCreateComponent from './meter-create-component';

import AdminServicesModule from '../../services';
import ReportingObjectsServicesModule from '../../../reportingobjects/services';
import EnerkeyServicesModule from '../../../../services';
import KendoModule from '../../../kendo';

export default angular.module('app.modules.admin.components.meter-create', [
  AdminServicesModule.name,
  ReportingObjectsServicesModule.name,
  EnerkeyServicesModule.name,
  KendoModule.name
])
  .component('meterCreate', MeterCreateComponent)
;
