import template from 'raw-loader!./mass-reassign-personfacilities-dialog.html';
import massReassignPersonFacilitiesController from './mass-reassign-personfacilities-dialog.controller';

export default {
  template,
  controller: massReassignPersonFacilitiesController,
  controllerAs: 'vm',
  bindings: {
    close: '&',
    saveReady: '&',
    selectedFacilities: '<',
    startSave: '<'
  }
};
