import ManualQaConsumptionStatsCellComponent from './manual-qa-consumption-stats-cell-component';

const dependencies = [];

const ManualQaConsumptionStatsCellModule = angular
  .module('app.modules.manual-qa.components.consumption-stats-cell', dependencies)
  .component('manualQaConsumptionStatsCell', ManualQaConsumptionStatsCellComponent)
;

export default ManualQaConsumptionStatsCellModule;
