import { startOfHour } from 'date-fns';

import * as FetchType from '../components/manual-qa-fetch-type/manual-qa-fetch-type.constant';
import ValueTypes from '../constants/value-types';
import Reading from './reading';
import { padOrphanReadings } from '../components/manual-qa-chart/manual-qa-chart.functions';

const HOURLY_REGEX = /:00:00(\.000)?Z$/;

export class ReadingConverter {
  /**
   * Converts raw readings data from backend to readings array.
   *
   * @param {Object} rawReadings
   * @param {DefectIssue[]} defects
   * @param {number} [fetchType]
   *
   * @returns {Reading[]}
   */
  static convert(rawReadings, defects, fetchType = FetchType.BOTH) {
    const actualRawReadingsTmp = getActualRawReadings(rawReadings.actual, fetchType);
    const comparisonRawReadingsTmp = getComparisonRawReadings(rawReadings.comparison);
    const formattedData = formatDefectData(defects);

    const readings = actualRawReadingsTmp.map((values, rowIndex) => {
      const isModelled = getValue(values, 'valueType') === ValueTypes.MODELLED;
      const isLocked = getValue(values, 'locked', false);

      const date = new Date(values.timestampUtc);
      const isHourly = isHourlyReading(values);
      const hourlyTimestamp = (isHourly ? date : startOfHour(date)).toISOString();
      const faults = formattedData.faults[date.toISOString()];
      const belongsToDefectIssue = !!formattedData.defects[hourlyTimestamp];

      const reading = new Reading();
      reading.setTimestamp(date);
      reading.setActualConsumption(getValue(values, 'hourValue'));
      reading.setActualCumulative(getValue(values, 'value'));
      Reading.setValueType(reading, getValue(values, 'valueType'));
      reading.setLocked(isLocked);
      reading.setDirty(false);
      reading.setHourly(isHourly);
      reading.setRowIndex(rowIndex);
      reading.setBelongsToDefectIssue(belongsToDefectIssue);
      reading.setFaults(faults);

      if (isModelled) {
        reading.setModelConsumption(getValue(values, 'hourValue'));
        reading.setModelCumulative(getValue(values, 'value'));
      }

      if (isHourly && comparisonRawReadingsTmp.length > 0) {
        const currentComparisonRawReading = comparisonRawReadingsTmp.shift();
        reading.setComparisonTimestamp(new Date(getValue(currentComparisonRawReading, 'timestampUtc')));
        reading.setComparisonConsumption(getValue(currentComparisonRawReading, 'hourValue'));
      }

      return reading;
    });

    padOrphanReadings(readings);

    return readings;
  }
}

/**
 * Checks if raw reading is hourly reading
 *
 * @param readingData
 *
 * @returns {boolean}
 */
function isHourlyReading(readingData) {
  // Hack to increase perf, especially with large datasets.
  // Relies on the fact that timestampUtc is UTC formatted string
  return HOURLY_REGEX.test(readingData.timestampUtc);
}

/**
 * Returns list of actual raw readings
 *
 * @param {Array} readingsData
 * @param {number} fetchType
 *
 * @returns {Array}
 */
function getActualRawReadings(readingsData, fetchType = FetchType.HOUR) {
  return fetchType === FetchType.HOUR ?
    readingsData.filter(isHourlyReading) :
    readingsData;
}

/**
 * Returns list of comparison raw readings
 *
 * @param {Array} readingsData
 *
 * @returns {Array}
 */
function getComparisonRawReadings(readingsData) {
  return readingsData.filter(isHourlyReading);
}

/**
 *
 * @param object
 * @param key
 * @param defaultValue
 * @returns {*}
 */
function getValue(object, key, defaultValue = null) {
  return angular.isDefined(object) && angular.isDefined(object[key])
    ? object[key]
    : defaultValue;
}

/**
 * Formats defect data so we can access the data with O(1) complexity.
 *
 * @param {DefectIssue[]} defects
 *
 * @returns {Object}
 */
function formatDefectData(defects) {
  return defects.reduce((result, defect) => {
    const arrayOfDefectTimestamps = defect.getTimeFrame().toDatesObject('hour', 1);
    const output = {};

    defect.getFaults().forEach(fault => {
      output[fault.getTimestamp().toISOString()] = fault.getFaultTypes();
    });

    return {
      defects: { ...result.defects, ...arrayOfDefectTimestamps },
      faults: { ...result.faults, ...output }
    };
  }, { defects: {}, faults: {} });
}
