import { Configuration } from '@enerkey/clients/configuration-control';

export class TerminalMeter {
  public meterId: number;
  public ordinal: number;
  public configurationId: number;
  public meterName: string;
  public enegiaId: number;
  public facilityName: string;
  public externalMeterId: string;
  public statusText: string;
  public statusIndicator: string;
  public tenantName: string;

  public constructor(meterId: number, ordinal: number) {
    this.meterId = meterId;
    this.ordinal = ordinal;
    this.statusText = 'Not configured';
    this.statusIndicator = null;
  }

  public setConfigurationDetails(values: Configuration, statusText: string, statusIndicator: string): void {
    this.configurationId = values.id;
    this.meterName = values.meterName;
    this.enegiaId = values.enegiaId;
    this.facilityName = values.facilityName;
    this.externalMeterId = values.externalMeterId;
    this.statusText = statusText;
    this.statusIndicator = statusIndicator;
  }
}
