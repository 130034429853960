import { VISUAL_COLORS } from '../../constants/visualization-constant';
import { roundByDigits } from '@enerkey/ts-utils';

export default class ChartConfig {
  constructor(configs, translations) {
    this.translations = translations;
    this.viewData = configs.viewData;
    this.gridColumn = configs.gridColumn;
    this.totalAggregates = configs.totalAggregates;
    this.field = this.gridColumn.field;

    const aggregate = Object.keys(configs.totalAggregates[configs.gridColumn.field])[0];
    this.aggregate = aggregate;

    const { serie, visible } = this.getChartData();
    this.serie = serie;
    this.visible = visible;

    this.title = `${this.gridColumn.title} - ${translations[aggregate]}`;
  }

  getChartData() {
    const colors = VISUAL_COLORS;
    const serie = [];
    let colorIndex = 0;
    let atLeastOneSerieExist = false;
    this.viewData.forEach(group => {
      const aggregateValue = this.getAggregateValue(group.aggregates);

      if (aggregateValue > 0 || aggregateValue < 0) {
        atLeastOneSerieExist = true;
        serie.push({
          color: colors[colorIndex],
          key: this.getSeriesTitle(group),
          value: aggregateValue
        });
      }

      colorIndex++;
      if (colorIndex >= colors.length) {
        colorIndex = 0;
      }
    });

    // Set total aggregate in the first array item, this way kendo does not lose the value
    if (serie[0]) {
      serie[0].centerValue = this.getCenterValue();
    }

    return {
      visible: atLeastOneSerieExist,
      serie: serie
    };
  }

  getCenterValue() {
    const chartTitle = this.gridColumn.title;
    const value = this.getAggregateValue(this.totalAggregates);
    const formattedValue = roundByDigits(value, 5, 3);
    let quantityType = chartTitle.match(/\([^)]+\)/);
    if (quantityType) {
      quantityType = quantityType[0];
      quantityType = quantityType.substring(1, quantityType.length - 1);
      return `${formattedValue} ${quantityType}`;
    }
    return formattedValue;
  }

  getAggregateValue(aggregates) {
    const value = aggregates && aggregates[this.field] && aggregates[this.field][this.aggregate];
    return angular.isDefined(value) ? value : null;
  }

  getSeriesTitle(group) {
    if (angular.isString(group.value)) {
      return group.value;
    } else if (angular.isNumber(group.value)) {
      return group.value.toString();
    } else {
      return this.translations.missingSerie;
    }
  }
}
