import { Injectable } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import moment from 'moment';
import { startOfDay } from 'date-fns';

import {
  EditProperties,
  FieldType,
  MeterWithProperties,
  RootProperties,
  TreeViewItem,
  ValidationProperties,
} from '@enerkey/clients/metering';

import { AddOperationType } from '../../admin/shared/add-operation-type';
import { ToDatePipe } from '../../../shared/common-pipes/to-date.pipe';
import { ChangedParamsMap } from '../shared/formula-item-edit';
import { localToUtc } from '../../../shared/date.functions';

@Injectable()
export class FormulaEditorService {
  public static readonly operationTypeArray: ReadonlyArray<AddOperationType> = Object.values(AddOperationType);
  public static readonly operationTypeTranslations: ReadonlyMap<AddOperationType, string> = new Map([
    [AddOperationType.OperandBefore, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_OPERAND_BEFORE'],
    [AddOperationType.OperandAfter, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_OPERAND_AFTER'],
    [AddOperationType.OperandInside, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_OPERAND_INSIDE'],
    [AddOperationType.OperatorBefore, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_OPERATOR_BEFORE'],
    [AddOperationType.OperatorAfter, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_OPERATOR_AFTER'],
    [AddOperationType.OperatorInside, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_OPERATOR_INSIDE'],
    [AddOperationType.ConstantAfter, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_CONSTANT_AFTER'],
    [AddOperationType.ParenthesesBefore, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_PARENTHESES_BEFORE'],
    [AddOperationType.ParenthesesAfter, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_PARENTHESES_AFTER'],
    [AddOperationType.ParenthesesInside, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_PARENTHESES_INSIDE'],
    [AddOperationType.ParenthesesAround, 'ADMIN.VIRTUAL_METERS.EDITOR.ADD_PARENTHESES_AROUND']
  ]);

  public constructor(
    private intlService: IntlService,
    private toDatePipe: ToDatePipe
  ) {
  }

  public getNullToZeroColumnTemplate(dataItem: TreeViewItem): string {
    return `<div ng-if="dataItem.editProperties.allowNullToZero">
<input type="checkbox" \
id="null${dataItem.itemId}" \
kendo-checkbox \
ng-model="dataItem.nullToZero" \
ng-change="vm.nullChange(dataItem)">
</div>`;
  }

  public getContentColumnTemplate(dataItem: TreeViewItem): string {
    const errorClass = this.hasRowError(dataItem) ? ' formulaError' : '';
    const template = Number.isInteger(dataItem.parentId) ? `<span class="depthIndicator${errorClass}"></span>` : '';

    return template + this.getContentWithFormat(dataItem);
  }

  public getFormulaDataWithMeterNames(
    formulaData: TreeViewItem[],
    meters: Partial<MeterWithProperties>[]
  ): TreeViewItem[] {
    const meterMap = meters.toMapBy('id');

    return formulaData.map((item: TreeViewItem) => {
      if (item.sourceMeterId) {
        const meter = meterMap.get(item.sourceMeterId);
        if (meter) {
          item.meterName = meter.name;
          item.resolution = meter.resolution;
          item.readingStartTime = meter.automaticReadingStartTime ?
            item.readingStartTime = this.toDatePipe.transform(
              moment(meter.automaticReadingStartTime)
            ) :
            null;
        }
      }

      return item;
    });
  }

  public isAddPossible(item: TreeViewItem): boolean {
    return item?.editProperties && FormulaEditorService.operationTypeArray.some(
      property => item.editProperties[property]
    );
  }

  public getValidationErrorTooltipText(validationProperties: ValidationProperties): string {
    let tooltipError = '';
    if (this.hasValidationErrors(validationProperties)) {
      const errorList = validationProperties.validationErrors.map(error => `<li>${error.value}</li>`).join('');
      tooltipError = `<ul>${errorList}</ul>`;
    }

    return tooltipError;
  }

  public getRowChangedPropertyAndValue(row: TreeViewItem): ChangedParamsMap {
    const changed: ChangedParamsMap = new Map();
    switch (row.itemFieldType) {
      case FieldType.Operator:
        changed.set('content', row.content);
        break;
      case FieldType.Source:
        changed.set('nullToZero', row.nullToZero);
        changed.set('constant', row.constant);
        break;
      case FieldType.Constant:
        changed.set('constant', row.constant);
        break;
    }
    return changed;
  }

  public isRowContentEditable(row: TreeViewItem): boolean {
    return row.itemFieldType === FieldType.Operator;
  }

  public isRowConstantEditable(row: TreeViewItem): boolean {
    return row.itemFieldType === FieldType.Source || row.itemFieldType === FieldType.Constant;
  }

  public getEmptyFormula(resultMeterId: number): TreeViewItem[] {
    return [
      new TreeViewItem({
        itemId: 0,
        itemFieldType: FieldType.Formula,
        parentId: null,
        content: 'New formula',
        editProperties: new EditProperties({
          allowAddOperandInside: true
        }),
        rootProperties: new RootProperties({
          from: startOfDay(localToUtc(new Date())),
          resultMeterId: resultMeterId,
          isActive: true
        })
      })
    ];
  }

  private hasRowError(item: TreeViewItem): boolean {
    return item?.validationProperties && !item.validationProperties.isValid;
  }

  private getContentWithFormat(item: TreeViewItem): string {
    switch (item.itemFieldType) {
      case FieldType.Constant:
        return this.intlService.formatNumber(item.constant, '#.##########');
      default:
        return item.content;
    }
  }

  private hasValidationErrors(validationProperties: ValidationProperties): boolean {
    return !!validationProperties?.validationErrors?.length;
  }
}
