export class NgfActiveModalMock {
  public close(): never {
    throw new Error('Method not implemented: close');
  }
  public dismiss(): never {
    throw new Error('Method not implemented: dismiss');
  }
}

export class NgfModalRefMock {
  public get componentInstance(): never {
    throw new Error('Method not implemented: componentInstance');
  }
  public close(): never {
    throw new Error('Method not implemented: close');
  }
  public dismiss(): never {
    throw new Error('Method not implemented: dismiss');
  }
}
