<modal-view-header [headingText]="'MQA.LEVEL_SHIFT_HISTORY.LOADING' | translate"></modal-view-header>

<div class="modal-body">
  <content-loader></content-loader>
</div>

<div class="modal-footer">
  <div class="row">
    <div class="modal-footer-buttons float-right">
      <button
        class="button button--secondary"
        type="button"
        (click)="dismiss()"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
