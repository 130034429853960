import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { StateService } from '@uirouter/core';
import { from, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../../../../services/user-service';
import { EnerkeyNg2StateDeclaration } from '../../../../shared/routing';
import { ProfileService } from '../../../../shared/services/profile.service';
import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';

@Component({
  selector: 'em-index',
  templateUrl: './em-index.component.html',
  styleUrls: ['./em-index.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TemplateLifterService]
})
export class EmIndexComponent implements OnInit {
  public readonly tabs$: Observable<EnerkeyNg2StateDeclaration[]>;

  public topRightContent: TemplateRef<unknown> = null;

  public constructor(
    private readonly stateService: StateService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly templateLifter: TemplateLifterService,
    private readonly userService: UserService,
    profileService: ProfileService
  ) {
    this.tabs$ = merge(
      profileService.profileChange$,
      from(this.userService.isInitializedWithInitialProfileAsync())
    ).pipe(
      map(() => this.getEmStates())
    );
  }

  public ngOnInit(): void {
    this.templateLifter.templateChanged$.subscribe(value => {
      this.topRightContent = value;
      this.changeDetector.detectChanges();
    });
  }

  private getEmStates(): EnerkeyNg2StateDeclaration[] {
    return (this.stateService.get() as EnerkeyNg2StateDeclaration[])
      .filter(
        state => state.name.startsWith('energy-management.') &&
          this.userService.hasAccess(state.data?.auth)
      );
  }
}
