import { inject } from '@angular/core';
import { TransitionService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';

import { WINDOW } from './injection-tokens';

/**
 * Hooks a confirmation modal to transition (leaving the current view) that prompts the user that they
 * have unsaved changes on the page.
 *
 * @param hasChangesFn Function to determine if the confirmation alert should be shown.
 * @returns Function to unhook the transition listener. Remember to invoke this in `ngOnDestroy`.
 *
 * @example
 * this._unhookConfirmation = confirmUnsavedChanges(() => this.hasChanges);
 * this._destroy$.subscribe(() => this._unhookConfirmation());
 */
export function confirmUnsavedChanges(hasChangesFn: () => (boolean | Promise<boolean>)): () => void {
  const transitionService = inject(TransitionService);
  const prompt = getUnsavedChangesPromptFn();

  return transitionService.onStart(
    null,
    async transition => {
      if (await hasChangesFn() && !prompt()) {
        transition.abort();
      }
    }
  ) as unknown as () => void;
}

export function getUnsavedChangesPromptFn(): () => boolean {
  const translateService = inject(TranslateService);
  const windowObject = inject(WINDOW);

  return () => windowObject.confirm(translateService.instant('UNSAVED_CHANGES_CONFIRMATION'));
}

