import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { RelationalValuesService } from '../../../../shared/services/relational-values.service';
import { EkComboComponent } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

@Component({
  selector: 'widget-variable-select',
  templateUrl: '../../../../shared/ek-inputs/ek-combo/ek-combo.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WidgetVariableSelectComponent),
    multi: true
  }]
})
export class WidgetVariableSelectComponent extends EkComboComponent<number> implements OnDestroy {
  public override disableWhenLoading = true;
  public override noTranslate = true;

  private readonly itemsLoaded$ = new ReplaySubject<void>(1);
  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly relationalValues: RelationalValuesService,
    private readonly translateService: TranslateService
  ) {
    super(changeDetectorRef);

    this.loading = true;

    this.relationalValues.relationalValues$.pipe(takeUntil(this.destroy$)).subscribe(values => {
      const items = [{ text: this.translateService.instant('TOPCONSUMPTIONS_WIDGET.VALUE'), value: 0 }];
      values.forEach(value => {
        items.push({ text: value.Name, value: value.Id });
      });

      this.items = items;
      this.loading = false;
      this.itemsLoaded$.next();
      this.changeDetectorRef.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public override writeValue(value: number): void {
    this.itemsLoaded$.pipe(take(1)).subscribe(() => {
      this.value = value;
      this.changeDetectorRef.markForCheck();
    });
  }
}
