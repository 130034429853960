import { IAngularStatic } from 'angular';

import EnerKeyServicesmodule from '../../../services';
import LodashModule from '../../lodash';
import { LegacyFacilityService } from '../models/facilities';
import { meters } from '../models/meters';
import DocumentsModelsModule from '../../documents/models';

declare const angular: IAngularStatic;

export default angular.module('ReportingObjects.Services', [
  EnerKeyServicesmodule.name,
  LodashModule.name,
  DocumentsModelsModule.name
])
  .service('facilities', LegacyFacilityService)
  .factory('meters', meters)
;
