import EmActionEditModalController from './em-action-edit-modal-controller';
import emActionEditModalTemplate from 'raw-loader!./em-action-edit-modal.html';

const $inject = ['$modal'];

export class EmActionEditService {
  constructor($modal) {
    this.$modal = $modal;
  }
  /**
   * Returns action edit modal ($q promise)
   *
   * @param {Object} action
   * @returns {any}
   */
  getModal(action, hasCategoryData = false) {
    return this.$modal.open({
      template: emActionEditModalTemplate,
      windowClass: 'modal-dialog-scrollable',
      size: 'large',
      controller: EmActionEditModalController,
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        action: () => action,
        hasCategoryData: () => hasCategoryData,
      }
    }).result;
  }
}

EmActionEditService.$inject = $inject;

export default EmActionEditService;
