<form
  singleInputEnabled
  [formGroup]="searchForm"
  (ngSubmit)="search()"
>
  <div class="shortcuts">
    <label><strong>{{ 'GRID_SHORTCUTS.TITLE' | translate }}</strong></label>
    <button
      type="button"
      kendoButton
      [disabled]="loading$ | async"
      (click)="getCurrentProfile()"
    >
      {{ 'SETTINGS.CURRENT_PROFILE' | translate }}
    </button>
    <hr>
  </div>
  <profile-search-select
    formControlName="profileIdsFromSelect"
    *labelBefore="'ADMIN.PROFILENAME' | translate"
  ></profile-search-select>
  <input
    type="text"
    integers
    formControlName="profileIds"
    *labelBefore="'ADMIN.PROFILEID' | translate"
  >
  <input
    type="text"
    formControlName="facilityName"
    *labelBefore="'FACILITY_NAME' | translate"
  >
  <input
    type="text"
    integers
    formControlName="facilityIds"
    *labelBefore="'FACILITY_ID' | translate"
  >
  <input
    type="text"
    integers
    formControlName="enegiaIds"
    *labelBefore="'ENEGIA_ID' | translate"
  >
  <input
    type="text"
    integers
    formControlName="meterIds"
    *labelBefore="'METER_ID' | translate"
  >
  <input
    type="text"
    integers
    formControlName="terminalIds"
    *labelBefore="'ADMIN.TERMINAL_ID' | translate"
  >
  <input
    type="text"
    formControlName="terminalName"
    *labelBefore="'ADMIN.TERMINAL_NAME' | translate"
  >
  <input 
    type="text" 
    formControlName="eanCode"
    *labelBefore="'EAN_CODE' | translate"
  >
  <input
    type="text"
    formControlName="protocolCode"
    *labelBefore="'PROTOCOL_CODE' | translate"
  >
  <input
    type="text"
    formControlName="usagePlaceNumber"
    *labelBefore="'USAGE_PLACE_NUMBER' | translate"
  >
  <input
    type="text"
    formControlName="energyCompanyUsagePlaceNumbers"
    *labelBefore="'ADMIN.METER.ENERGY_COMPANY_USAGE_PLACE_NUMBER' | translate"
  >
  <div class="buttons-container">
    <button
      type="submit"
      class="button"
      [loading]="loading$ | async"
    >
      {{ 'SEARCH' | translate }}
    </button>
    <button
      class="button button--secondary float-right"
      type="button"
      (click)="searchForm.reset()"
    >
      {{ 'RESET' | translate }}
    </button>
  </div>
</form>
