/* istanbul ignore file */

export class Helpers {
  public static assertUnreachable(param: never): never {
    throw new Error(`Should not get here with param ${param}`);
  }

  public static identity<T>(param: T): T {
    return param;
  }

  public static noop(): void { }

  private constructor() { }
}

export function assertUnreachable(param: never): never {
  return Helpers.assertUnreachable(param);
}
