<table
  *ngIf="data"
  class="table"
>
  <thead>
    <tr>
      <th></th>
      <th>{{ 'FACILITIES.QUANTITIES' | translate }}</th>
      <th *ngIf="hasComparisonPeriod">{{ comparisonPeriodTitle$ | async }}</th>
      <th>{{ inspectionPeriodTitle$ | async }}</th>
      <th *ngIf="hasComparisonPeriod">{{ 'CHANGE.TITLE' | translate }}</th>
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let row of data">
      <tr>
        <td [style.background-color]="row.quantityId | quantityColor"></td>
        <td>{{ row.quantityId | quantityName | async }}</td>
        <td
          *ngIf="hasComparisonPeriod"
          [ngClass]="{ 'k-grid-cell-Incomplete': row.comparison.incomplete }"
        >
          {{ row.comparison.value | kendoNumber:'#,#.0' }}
        </td>
        <td [ngClass]="{ 'k-grid-cell-Incomplete': row.inspection.incomplete }">
          {{ row.inspection.value | kendoNumber:'#,#.0' }}
        </td>
        <td *ngIf="hasComparisonPeriod">{{ row.change | kendoNumber:'#.0 %' }}</td>
      </tr>
    </ng-container>
    <tr class="total-row">
      <td [style.background-color]="total.quantityId | quantityColor"></td>
      <td>
        {{ total.isNormalized ? ('FACILITIES.NORMALIZED' | translate) : '' }}
        {{ total.quantityId | quantityName | async }}
      </td>
      <td *ngIf="hasComparisonPeriod">{{ total.comparisonValue | kendoNumber:'#,#.0' }}</td>
      <td>{{ total.value | kendoNumber:'#,#.0' }}</td>
      <td *ngIf="hasComparisonPeriod">{{ total.relativeChange | kendoNumber:'#.0 %' }}</td>
    </tr>
  </tbody>
</table>
