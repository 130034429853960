import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors, Validator, Validators
} from '@angular/forms';
import { Observable, of } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';

import { PowerWidgetOptions } from '../power-widget/power-widget.component';
import { WidgetChangeOption, WidgetRelatedValueOption } from '../../../energy-reporting/shared/widget-constants';

type ChangeFn = (options: PowerWidgetOptions) => void;

type FormValues = Omit<PowerWidgetOptions, 'change' | 'minMaxAvg'> &
{ change: WidgetChangeOption, minMaxAvg: WidgetRelatedValueOption };

@Component({
  selector: 'power-widget-options',
  templateUrl: './power-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PowerWidgetOptionsComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => PowerWidgetOptionsComponent),
  }]
})
export class PowerWidgetOptionsComponent implements ControlValueAccessor, OnInit, Validator {

  @Input() public initialState: PowerWidgetOptions;

  public readonly quantities$: Observable<Quantities[]>;
  public formGroup: FormGroup;

  private _onChange: ChangeFn;

  public constructor(private readonly fb: FormBuilder) {
    this.quantities$ = of([Quantities.Electricity, Quantities.DistrictHeating]);
  }

  public ngOnInit(): void {

    const values = this.optionsToForm(this.initialState);

    this.formGroup = this.fb.group({
      comparisonPeriodOption: ['', Validators.required],
      selectedQuantity: [null, Validators.required],
      timeFrameOption: ['', Validators.required],
      valueOption: ['', Validators.required],
      minMaxAvg: this.fb.control<WidgetRelatedValueOption>(null, [Validators.required]),
      change: this.fb.control<WidgetChangeOption>(null, [Validators.required])
    });

    this.formGroup.setValue(values);

    this.formGroup.valueChanges
      .subscribe((formValue: FormValues) => {
        this._onChange?.(this.formToOptions(formValue));
      });
  }

  public writeValue(value: PowerWidgetOptions): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }

  public setDisabledState(): void { }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.formGroup.invalid) {
      return {
        required: true
      };
    }
    return null;
  }

  private optionsToForm(options: PowerWidgetOptions): FormValues {
    return {
      ...options,
      change: options.change.absolute ? WidgetChangeOption.Absolute : WidgetChangeOption.Relative,
      // eslint-disable-next-line no-nested-ternary
      minMaxAvg: options.minMaxAvg.average ?
        WidgetRelatedValueOption.Average :
        options.minMaxAvg.min ? WidgetRelatedValueOption.Min : WidgetRelatedValueOption.Max
    };
  }

  private formToOptions(formValue: FormValues): PowerWidgetOptions {
    return {
      ...formValue,
      change: {
        absolute: formValue.change === WidgetChangeOption.Absolute,
        relative: formValue.change === WidgetChangeOption.Relative
      },
      minMaxAvg: {
        average: formValue.minMaxAvg === WidgetRelatedValueOption.Average,
        min: formValue.minMaxAvg === WidgetRelatedValueOption.Min,
        max: formValue.minMaxAvg === WidgetRelatedValueOption.Max
      }
    };
  }

}
