import facilityCreateTemplate from 'raw-loader!./facility-create.html';
import facilityCreateController from './facility-create-controller';

export default {
  template: facilityCreateTemplate,
  controllerAs: 'vm',
  controller: facilityCreateController,
  bindings: {
    onClose: '&',
    onSpreadsheetCreate: '&'
  }
};
