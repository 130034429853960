import ManualQaMeterFacilityInfoComponent from './manual-qa-meter-facility-info.component';
import ManualQaReaderTypeModule from '../../services/manual-qa-api-reader-type';
import servicesModule from '../../../../services';

const dependencies = [ManualQaReaderTypeModule.name, servicesModule.name];

const ManualQaMeterFacilityInfoModule = angular
  .module('app.modules.manual-qa.components.manual-qa-meter-facility-info', dependencies)
  .component('manualQaMeterFacilityInfo', ManualQaMeterFacilityInfoComponent)
;

export default ManualQaMeterFacilityInfoModule;
