import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoGridComponent } from './components/info-grid/info-grid.component';
import { InfoGridItemComponent } from './components/info-grid-item/info-grid-item.component';

@NgModule({
  declarations: [
    InfoGridComponent,
    InfoGridItemComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InfoGridComponent,
    InfoGridItemComponent,
  ]
})
export class EkLayoutModule { }
