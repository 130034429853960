<span
  [ngClass]="consumption | consumptionValueFlagClass | async"
  [ngfTooltip]="cellTooltip"
  [disableTooltip]="!consumption.incomplete && !consumption.modeled"
  [context]="{ $implicit: consumption }"
>
  {{ visibleValue | kendoNumber:format }}
</span>

<ng-template
  #cellTooltip
  let-consumption
>
  <table
    *ngIf="consumption"
    class="table table--no-margin table--no-stripes table--no-background"
  >
    <tbody>
      <tr *ngIf="consumption.incomplete">
        <td><strong>{{ 'EXCEL.INCOMPLETE_COLUMN_NAME' | translate }}</strong></td>
        <td>{{ consumption.incomplete | kendoNumber:tooltipNumberFormat }}</td>
      </tr>
      <tr *ngIf="consumption.modeled">
        <td><strong>{{ 'EXCEL.MODELLED_COLUMN_NAME' | translate }}</strong></td>
        <td>{{ consumption.modeled | kendoNumber:tooltipNumberFormat }}</td>
      </tr>
    </tbody>
  </table>
</ng-template>