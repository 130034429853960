import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import {
  EkDropdownToggleContext,
  EkDropdownToggleDirective,
} from '../ek-dropdown-toggle.directive';

type EkDropdownPosition = 'left' | 'right' | 'auto';

/**
 * Provides a toggleable dropdown.
 * Use `ek-dropdown-item` and `ek-dropdown-heading`, or `ek-dropdown-content`.
 * Custom toggle button can be provided with structural directive `ekDropdownToggle`.
 *
 * @example
 * ```html
 * <ek-dropdown>
 *  <button *ekDropdownToggle="let toggle" (click)="toggle()">...</button>
 *  <ek-dropdown-heading>{{ 'TEST' | translate }}</ek-dropdown-heading>
 *  <ek-dropdown-item (click)="doThing()">Test 1</ek-dropdown-item>
 *  <ek-dropdown-item (click)="doThing()" [disabled]="true">Test 2</ek-dropdown-item>
 * </ek-dropdown>
 * ```
 */
@Component({
  selector: 'ek-dropdown',
  templateUrl: './ek-dropdown.component.html',
  styleUrls: ['./ek-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkDropdownComponent implements AfterViewInit, OnDestroy {
  @Input() public width: number = 270;
  @Input() public position: EkDropdownPosition = 'auto';
  @Input() public showOnHover = false;

  public margin: number;

  @Output() public readonly isVisibleChanged = new EventEmitter<boolean>();

  public get hasTemplateButton(): boolean {
    return !!this.toggleTemplate;
  }

  public readonly hasBeenExpanded$: Observable<boolean>;

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  public set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;

      if (value) {
        this.visible = false;
      }

      if (this.embeddedView) {
        this.embeddedView.context.disabled = this._disabled;
      }

      this.changeDetector.markForCheck();
    }
  }

  @Output()
  public get isVisible(): boolean {
    return this._visible;
  }

  private get visible(): boolean {
    return this._visible;
  }

  private set visible(value: boolean) {
    const newValue = this.disabled ? false : value;

    if (value !== this._visible) {
      this._visible = newValue;

      if (this._visible) {
        this.initOffsetParent();
        this.updateMargin();
      }

      if (!this._visible && !this.embeddedView) {
        this.removeOffsetParent();
      }

      if (this.embeddedView) {
        this.embeddedView.context.visible = this._visible;
      }

      this.isVisibleChanged.emit(this._visible);
      this.changeDetector.markForCheck();
    }
  }

  private get container(): HTMLDivElement {
    return this.dropdownContainer.element.nativeElement;
  }

  @ContentChild(EkDropdownToggleDirective)
  private toggleTemplate: EkDropdownToggleDirective;

  @ViewChild('dropdownContainer', { read: ViewContainerRef })
  private dropdownContainer: ViewContainerRef;

  @ViewChild('toggleContainer', { read: ViewContainerRef })
  private toggleContainer: ViewContainerRef;

  private embeddedView: EmbeddedViewRef<EkDropdownToggleContext>;

  private readonly destroy$ = new Subject<void>();

  private _disabled: boolean = false;
  private _visible: boolean = false;

  private offsetParent: HTMLElement;
  // Not useful right as I cross verified throughought the app currently not overriding the position anywhere
  private offsetParentOriginalPosition: string = '';

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.hasBeenExpanded$ = this.isVisibleChanged.pipe(
      filter(visible => visible),
      take(1),
      takeUntil(this.destroy$)
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    const templateRef = this.toggleTemplate?.templateRef;
    const container = this.toggleContainer;

    if (templateRef && container) {
      const context: EkDropdownToggleContext = {
        $implicit: /* istanbul ignore next */ () => this.toggle(),
        toggle: () => this.toggle(),
        show: () => this.show(),
        hide: () => this.hide(),
        disabled: this.disabled,
        visible: this.visible,
      };

      this.embeddedView = templateRef.createEmbeddedView(context);

      container.insert(this.embeddedView);
      this.changeDetector.detectChanges();
    }
  }

  public hoverChange(hover: boolean): void {
    if (this.showOnHover) {
      this.visible = hover;
    }
  }

  public show(): void {
    this.visible = true;
  }

  public hide(): void {
    this.visible = false;
  }

  public toggle(): void {
    this.visible = !this.visible;
  }

  @HostListener('document:click', ['$event.target'])
  public clickOutside(targetElement: ElementRef): void {
    if (this.isVisible && !this.elementRef.nativeElement.contains(targetElement)) {
      this.hide();
    }
  }

  private updateMargin(): void {
    let leftAligned: boolean;

    if (this.position === 'auto') {
      const documentWidth = this.document.documentElement.clientWidth;
      const elementLeft = this.container.getBoundingClientRect().left;

      leftAligned = (elementLeft + this.width) > documentWidth;
    } else {
      leftAligned = this.position === 'left';
    }

    this.margin = leftAligned
      ? this.container.offsetWidth - this.width
      : 0;
  }

  private initOffsetParent(): void {
    this.offsetParent = null;
    this.offsetParentOriginalPosition = null;

    let parent = this.container.parentElement;

    while (parent) {
      const overflowValue = getComputedStyle(parent).overflowY;

      if (overflowValue === 'auto' || overflowValue === 'scroll' || parent === this.document.body) {
        this.offsetParent = parent;
        this.offsetParentOriginalPosition = parent.style.position;
        parent.style.position = 'relative';
        break;
      }

      parent = parent.parentElement;
    }
  }

  private removeOffsetParent(): void {
    if (this.offsetParent) {
      this.offsetParent.style.position = this.offsetParentOriginalPosition;
    }
  }
}
