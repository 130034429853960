import { FaultType } from '@enerkey/clients/manual-qa';
import { assertType, TypeEq } from '@enerkey/ts-utils';

import { DefectTypes } from '../constants/defect-types';

interface DefectTypeInfo {
  id: DefectTypes | string;
  exceedsInspectedRange?: boolean;
}

const faultTypeTranslation: Record<FaultType, string> = {
  [FaultType.UnknownDefectType]: 'MQA.DEFECT_TYPES.UNKNOWN_DEFECT_TYPE',
  [FaultType.ReadingMissing]: 'MQA.DEFECT_TYPES.READING_MISSING',
  [FaultType.Peak]: 'MQA.DEFECT_TYPES.PEAK',
  [FaultType.NegativeValue]: 'MQA.DEFECT_TYPES.NEGATIVE_VALUE',
  [FaultType.ExcessiveZero]: 'MQA.DEFECT_TYPES.EXCESSIVE_ZERO',
  [FaultType.TooLongInterpolation]: 'MQA.DEFECT_TYPES.TOO_LONG_INTERPOLATION',
  [FaultType.OpenEnd]: 'MQA.DEFECT_TYPES.OPEN_END',
  [FaultType.OpenEndMissingValue]: 'MQA.DEFECT_TYPES.OPEN_END_MISSING_VALUE',
};

// Contingency for MQA enum types
assertType<TypeEq<keyof typeof FaultType, keyof typeof DefectTypes>>();

type IsNumericEnum = typeof FaultType[keyof typeof FaultType] extends number ? true : false;
assertType<IsNumericEnum>();

export default class DefectType {
  public static getDefectTypeNameTag(id: FaultType): string {
    return faultTypeTranslation[id] ?? `DEFECT_TYPE_${id}`;
  }

  private exceedsInspectedRange: boolean = false;
  private id: DefectTypes = DefectTypes.UnknownDefectType;

  public constructor(data?: DefectTypeInfo) {
    if (data) {
      this.id = typeof data.id === 'string'
        ? parseInt(data.id)
        : data.id
      ;
      if (data.exceedsInspectedRange) {
        this.exceedsInspectedRange = !!data.exceedsInspectedRange;
      }
    }
  }

  public getId(): number {
    return this.id;
  }

  public isOverInspectedRange(): boolean {
    return this.exceedsInspectedRange;
  }
}
