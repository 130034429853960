import { upgradeModule } from '@uirouter/angular-hybrid';

import 'angular-animate';
import 'angular-cookies';
import 'angular-dynamic-locale';
import 'angular-foundation-6';
import 'angular-recursion';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'ngtouch';

// SC5 fork of the original multiselect.
// There is a problem with the forked version and NPM, so use locally instead
import './modules/isteven-angular-multiselect';

import config from './app-config';
import BodyController from './app-controller';
import run from './app-run';
import EnerKeyComponentsModule from './components';
import EnerKeyControllersModule from './controllers';
import EnerKeyDirectivesModule from './directives';
import EnerKeyFiltersModule from './filters';
import AdminModule from './modules/admin';
import ConfigurationControlModule from './modules/configuration-control';
import DocumentsModule from './modules/documents';
import EnergyManagementModule from './modules/energy-management';
import EnergyReportingModule from './modules/energy-reporting';
import JQueryModule from './modules/jquery';
import KendoModule from './modules/kendo';
import LodashModule from './modules/lodash';
import ManualQAModule from './modules/manual-qa';
import MrcModule from './modules/mrc';
import TemplatesModule from './modules/templates'; // Is this actually used somewhere?
import ConfigurationModule from './modules/configuration';
import ReportingObjectsModule from './modules/reportingobjects';
import EnerKeyServicesmodule from './services';
import { TelemetryService } from './services/telemetry.service';

declare const angular: ng.IAngularStatic;

const EnerKeyModule = angular.module('EnerKey2', [
  'ui.router',
  upgradeModule.name,
  'mm.foundation',
  'ngResource',
  'isteven-multi-select',
  'ngAnimate',
  'RecursionHelper',
  'ngSanitize',
  'tmh.dynamicLocale',
  'pascalprecht.translate',
  LodashModule.name,
  JQueryModule.name,
  KendoModule.name,
  EnerKeyServicesmodule.name,
  EnerKeyControllersModule.name,
  EnerKeyFiltersModule.name,
  EnerKeyComponentsModule.name,
  EnerKeyDirectivesModule.name,
  // EnerKey2 modules
  AdminModule.name,
  ConfigurationControlModule.name,
  DocumentsModule.name,
  EnergyManagementModule.name,
  EnergyReportingModule.name,
  ManualQAModule.name,
  MrcModule.name,
  TemplatesModule.name,
  ConfigurationModule.name,
  ReportingObjectsModule.name
])
  .constant('printingLimit', 40)
  .config(config)
  .run(run)
  .value('$anchorScroll', angular.noop) // perf: removes unused watcher in rootScope
  .controller('BodyController', BodyController)
  .factory('$exceptionHandler', ['TelemetryService', errorHandlerFactory])
  ;

function errorHandlerFactory(ts: TelemetryService): ng.IExceptionHandlerService {
  return (error, cause) => ts.handleAjsError(error, cause);
}

export default EnerKeyModule;
