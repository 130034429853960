<kendo-combobox
  [data]="data$ | async"
  valueField="id"
  textField="name"
  [valuePrimitive]="true"
  [(ngModel)]="value"
  (ngModelChange)="valueChanged($event)"
  (blur)="blur()"
  [kendoDropDownFilter]="filterSettings"
  [popupSettings]="popupSettings"
  [virtual]="true"
  [filterable]="true"
  [disabled]="disabled"
  [loading]="loading$ | async"
></kendo-combobox>
