import moment from 'moment';
import _ from 'lodash';
import { defer, firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { forkThrottle } from '@enerkey/rxjs';

// Throttle individual requests to avoid DDoSing our backend until mass endpoint is hopefully available
const MAX_CONCURRENT_REQUESTS = 20;

MrcReadingsListingController.$inject = [
  '$scope', '$filter', 'utils', 'mrcapi', 'mrcModals', 'authenticationService',
  '$timeout', '$q', 'MrcConstants',
  '$transitions', 'mrcReadingsListingService', 'companiesService'
];

export function MrcReadingsListingController(
  $scope, $filter, utils, mrcapi, mrcModals, authenticationService,
  $timeout, $q, MrcConstants,
  $transitions, mrcReadingsListingService, companiesService
) {

  const gridDataSource = {
    data: [],
    pageSize: 100,
    serverPaging: false,
    serverSorting: false,
    schema: {
      model: {
        fields: {
          ReadingDate: { type: 'date' },
          NewReadingDate: { type: 'date' }
        }
      }
    }
  };

  $scope.localizedMultiSelectTexts = {
    selectAll: $filter('translate')('MRC.READINGS_LISTING.SELECT_ALL'),
    selectNone: $filter('translate')('MRC.READINGS_LISTING.SELECT_NONE'),
    reset: $filter('translate')('MRC.READINGS_LISTING.RESET_SELECTION'),
    search: $filter('translate')('MRC.READINGS_LISTING.SEARCH_ITEMS'),
    nothingSelected: $filter('translate')('MRC.READINGS_LISTING.NOTHING_SELECTED')
  };
  $scope.localizedSingleSelectTexts = {
    search: $filter('translate')('MRC.READINGS_LISTING.SEARCH_ITEMS'),
    nothingSelected: $filter('translate')('MRC.READINGS_LISTING.NOTHING_SELECTED_SINGLE')
  };

  $scope.recipientInformationLabels = {
    ManagerCompanyContactEmail: $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.MANAGER_COMPANY'),
    MaintenanceCompanyContactEmail: $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.MAINTENANCE_COMPANY'),
    OwnerCompanyContactEmail: $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.OWNER_COMPANY'),
    FacilityManagementCompanyContactEmail:
    $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.FACILITY_MANAGEMENT_COMPANY')
  };

  $scope.localizedMeterTypeTexts = {
    manualReading: utils.localizedString(`MRC.METERING_TYPES.${ MrcConstants.METERING_TYPE.MANUAL_READING}`),
    manualConsumption: utils.localizedString(`MRC.METERING_TYPES.${ MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION}`),
    automatic: utils.localizedString(`MRC.METERING_TYPES.${ MrcConstants.METERING_TYPE.HOURLY}`)
  };

  $scope.readingsListingGrid = null;

  $scope.dataReady = false;
  $scope.searchType = 'latest';

  // selectedFacilities is held inside an object, otherwise output-model of multi select won't work correctly
  $scope.selectedFacilities = {
    list: []
  };
  $scope.searchResults = [];
  $scope.allResultsSelected = false;
  $scope.hasSelectedResults = false;
  $scope.searchOptions = {
    listReadingInputMeters: true,
    listConsumptionInputMeters: false,
    listAutomaticMeters: false
  };

  $scope.mailSendingResults = [];

  $scope.selectAll = function(forced) {
    const select = forced ? forced : !$scope.allResultsSelected;
    $scope.allResultsSelected = select;
    _.each($scope.searchResults, item => {
      item.selected = $scope.allResultsSelected;
    });
    gridDataSource.data = $scope.searchResults;
    _.each($scope.readingsListingGrid._data, item => {
      item.selected = $scope.allResultsSelected;
    });
    $scope.hasSelectedResults = $scope.allResultsSelected;
  };
  function countSelections() {
    let count = 0;
    _.each($scope.searchResults, result => {
      if (result.selected) {
        count += 1;
      }
    });
    return count;
  }

  $scope.doSelection = function(evt, item) {
    const resultItem = _.find($scope.searchResults, obj => obj.originalIndex === item.originalIndex);
    if (angular.isDefined(resultItem)) {
      resultItem.selected = item.selected;
    }
    $scope.hasSelectedResults = countSelections() > 0;
  };

  function buildMeteringPointInformationForFacilities(facilities, recipientInfoStr) {
    let meteringPointInformation = '';
    _.each(Object.keys(facilities), facilityId => {
      const item = facilities[facilityId];
      meteringPointInformation += _.isString(recipientInfoStr) && recipientInfoStr.length > 0 ? recipientInfoStr : '';
      const addrStr = (!_.isString(item[0].StreetAddress) ? '' : `${item[0].StreetAddress }, `) +
        ((_.isNull(item[0].PostalCode) || _.isUndefined(item[0].PostalCode)) ? '' : item[0].PostalCode) +
        (!_.isString(item[0].City) ? '' : `  ${ item[0].City}`);
      const facilityIdentifier = _.isString(item[0].FacilityCustomerSpecifiedIdentifier) ?
        (`${item[0].FacilityCustomerSpecifiedIdentifier }&nbsp;&nbsp;`) :
        ''
      ;
      const facility = `<br/>${ $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.FACILITY') }: ${
        facilityIdentifier }${item[0].FacilityName }<br/>`;
      const address = `${$filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.ADDRESS') }: ${ addrStr }<br/>`;
      const meteringPointInfo =
        `${$filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.METERING_POINT_INFORMATION')}: <br/>`;
      let metersInfo = '';
      let isConsumptionMeterTypeRemarkNeeded = false;
      _.each(item, meteringPoint => {
        const date = _.isNull(meteringPoint.ReadingDate) ? '-' : moment(meteringPoint.ReadingDate).format('DD.MM.YYYY');
        let value = '*'; // for consumption type meters * is shown instead of real value
        if (meteringPoint.MeterTypeId === MrcConstants.METERING_TYPE.MANUAL_READING) {
          value = _.isNull(meteringPoint.ReadingValue) ?
            $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.NO_PREVIOUS_READINGS') :
            meteringPoint.ReadingValue;
        } else {
          isConsumptionMeterTypeRemarkNeeded = true;
        }
        const meterInfo = `&nbsp;&nbsp;&nbsp;&nbsp;${ meteringPoint.MeterName
        }: &nbsp;&nbsp;(${ date }) &nbsp;&nbsp;${ value }<br/>`;
        metersInfo += meterInfo;
      });
      const consumptionMeterTypeRemark = isConsumptionMeterTypeRemarkNeeded ?
        `<br/>${$filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.CONSUMPTION_TYPE_REMARK')}` :
        ''
      ;
      meteringPointInformation = meteringPointInformation.concat(facility, address, meteringPointInfo, metersInfo,
        consumptionMeterTypeRemark, '<br/><br/>');
    });
    return meteringPointInformation;
  }

  function createEmails(params, user, selected) {
    const emails = [];
    if (_.isArray(selected) && selected.length > 0) {
      const recipientInfoKeys = [params.recipients, params.recipients.replace('ContactEmail', 'ContactName'),
        params.recipients.replace('ContactEmail', 'Name')];
      const allRecipients = {};
      if (params.recipients !== 'None') {
        const groupedByEmail = _.groupBy(selected, recipientInfoKeys[0]);
        let groupedByContactName = {};
        let groupedByName = {};
        _.each(Object.keys(groupedByEmail), addr => {
          if (_.isString(addr) && angular.isDefined(addr)) {
            allRecipients[addr] = groupedByEmail[addr];
          } else {
            groupedByContactName = _.groupBy(groupedByEmail[addr], recipientInfoKeys[1]);
            _.each(Object.keys(groupedByContactName), cName => {
              if (_.isString(cName) && angular.isDefined(cName)) {
                allRecipients[cName] = groupedByContactName[cName];
              } else {
                groupedByName = _.groupBy(groupedByContactName[cName], recipientInfoKeys[2]);
                _.each(Object.keys(groupedByName), name => {
                  const key = angular.isDefined(name) ? 'none' : name;
                  allRecipients[key] = groupedByName[name];
                });
              }
            });
          }
        });
      } else {
        allRecipients['none'] = selected;
      }
      _.each(Object.keys(allRecipients), recipient => {
        const facilities = _.groupBy(allRecipients[recipient], 'FacilityId');
        const mail = {
          recipient: '',
          content: {}
        };
        if (angular.isUndefined(recipient) || recipient === 'none') {
          mail.recipient = '';
          mail.content['to'] = '';
        } else {
          mail.recipient = recipient;
          mail.content['to'] = recipient;
        }
        mail.content['replyTo'] = params.replyTo;
        if (params.bccReplyTo || params.bccUser) {
          let bcc = params.bccReplyTo ? params.replyTo : '';
          if (params.bccUser) {
            bcc += (params.bccReplyTo ? ' ' : '') + user.userName;
          }
          mail.content['bcc'] = bcc;
        }
        mail.content['subject'] = params.topic;

        let recipientInfoStr = '';
        if (params.recipients !== 'None') {
          recipientInfoStr = `${$scope.recipientInformationLabels[params.recipients] }: `;
          let recipientInfo = '-';
          _.each(Object.keys(facilities), key => {
            const item = facilities[key];
            // omit email and search for contact name or name
            for (let i = 1; i < recipientInfoKeys.length; i++) {
              if (_.isString(item[0][recipientInfoKeys[i]]) && item[0][recipientInfoKeys[i]].length > 0) {
                recipientInfo = item[0][recipientInfoKeys[i]];
                return false;
              }
            }
          });
          recipientInfoStr += recipientInfo;

        }
        const meteringPointInformationStr = buildMeteringPointInformationForFacilities(facilities, recipientInfoStr);
        mail.content['body'] = params.coverTxt.concat(meteringPointInformationStr, params.signatureTxt);
        emails.push(mail);
      });
    }
    return emails;
  }

  function updateEmailStatus(index, to, status) {
    const found = _.find($scope.mailSendingResults, result => result.recipient === to && result.index === index);
    if (angular.isDefined(found)) {
      found.status = status;
    } else {
      $scope.mailSendingResults.push({ index: index, recipient: to, status: status });
    }
  }

  $scope.sendEmail = function(index, email, skip) {
    if (angular.isDefined(email) && !skip) {
      updateEmailStatus(index, email.to, 'sending');
      mrcapi.sendMissingReadingsEmail(email).then(
        () => {
          let txt = $filter('translate')('MRC.READINGS_LISTING.EMAIL_SENDING_SUCCEEDED');
          txt = txt.concat(' ', email.to);
          utils.popUp('success', txt);
          updateEmailStatus(index, email.to, 'sent');
        },
        () => {
          let txt = $filter('translate')('MRC_ERRORS.FAILED_SENDING_EMAIL');
          txt = txt.concat(' (', email.to, ')');
          utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', txt, true);
          updateEmailStatus(index, email.to, 'failed');
        }
      );
    } else {
      updateEmailStatus(index, email.to, 'skipped');
    }
  };

  function getFilteredAndSelectedResults() {
    let selectedResults = [];
    const dataSource = angular.element('#readingsListingGrid').data('kendoGrid').dataSource;
    const filteredDataSource = new kendo.data.DataSource({
      data: dataSource.data(),
      filter: dataSource.filter()
    });
    if (angular.isDefined(filteredDataSource)) {
      filteredDataSource.read();
      const filteredViewData = filteredDataSource.view();
      selectedResults = _.filter(filteredViewData, result => result.selected);
    }
    return selectedResults;
  }

  $scope.prepareEmailSending = function() {
    const selectedResults = getFilteredAndSelectedResults();
    if (selectedResults.length > 0) {
      let isEnerkeyUser = false;
      let userName = '';
      firstValueFrom(companiesService.isSuperCompanyUser$.pipe(take(1))).then(
        isSuperCompanyUser => {
          isEnerkeyUser = isSuperCompanyUser;
          userName = authenticationService.getUsername();
        }
      ).finally(() => {
        const user = {
          userName: userName,
          enerkeyUser: isEnerkeyUser,
          lang: $scope.mrcState.userLang
        };
        mrcModals.showEmailSettingsModal(user).then(
          settings => {
            const emails = createEmails(settings, user, selectedResults);
            if (_.isArray(emails) && emails.length > 0) {
              $scope.mailSendingResults = [];
              mrcModals.showEmailSendingModal(emails, $scope.sendEmail).then(
                () => {
                  mrcModals.showEmailSendingResultsModal($scope.mailSendingResults).then(
                    () => {
                    },
                    () => {
                    }
                  ).finally(() => {
                    $scope.mailSendingResults = [];
                  });
                },
                () => {
                  // rejected. nothing to do here.
                }
              );
            }
          },
          () => {
            // rejected. nothing to do here.
          }
        );
      });
    }
  };

  $scope.readingsListingGridOptions = {
    dataSource: gridDataSource,
    sortable: true,
    pageable: true,
    reorderable: true,
    filterable: true,
    groupable: true,
    resizable: true,
    dataBound: function() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const vm = this;
      vm.expandRow(vm.tbody.find('tr.k-master-row').first());
    },
    excel: {
      fileName: '',
      allPages: true
    },
    toolbar: '<toggle-grid-columns-button-only grid="readingsListingGrid"></toggle-grid-columns-button-only>',
    columns: [{
      field: 'selected',
      title: ' ',
      groupable: false,
      sortable: false,
      filterable: false,
      hidden: true,
      locked: true,
      width: 30,
      headerTemplate: '<div><input style="cursor:pointer;' +
        '" id="select-all-results" type="checkbox" ng-model="allResultsSelected" ng-change="selectAll()" /></div>',
      template: '<input style="cursor:pointer;' +
        '" type="checkbox" class="grid-checkbox" ng-model="dataItem.selected" ' +
        'ng-change="doSelection($event, this.dataItem)" />',
      attributes: {
        class: 'cell-center'
      }
    }, {
      field: 'FacilityName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.FACILITYNAME'),
      locked: true,
      width: 200
    }, {
      field: 'MeterName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.METERNAME'),
      width: 150
    }, {
      field: 'MeterId',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.METERID'),
      width: 150,
      hidden: true
    }, {
      field: 'MeterType',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.METERTYPE'),
      filterable: {
        multi: true,
        extra: false
      },
      width: 150,
      hidden: true
    }, {
      field: 'ReadingType',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.READINGTYPE'),
      filterable: {
        multi: true,
        extra: false
      },
      width: 200
    }, {
      field: 'ReadingDate',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.READINGDATE'),
      type: 'date',
      format: '{0: d.M.yyyy}',
      width: 150
    }, {
      field: 'ReadingValue',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.READINGVALUE'),
      type: 'number',
      width: 150
    }, {
      field: 'Factor',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.FACTOR'),
      type: 'number',
      width: 150
    }, {
      field: 'NewReadingDate',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.NEW_READINGDATE'),
      type: 'date',
      format: '{0: d.M.yyyy}',
      hidden: true,
      width: 150
    }, {
      field: 'NewReadingValue',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.NEW_READINGVALUE'),
      type: 'number',
      hidden: true,
      width: 150
    }, {
      field: 'AvgConsumptionDay',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.AVG_CONSUMPTION_DAY'),
      type: 'number',
      hidden: true,
      width: 150
    }, {
      field: 'AvgConsumptionMonth',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.AVG_CONSUMPTION_MONTH'),
      type: 'number',
      hidden: true,
      width: 150
    }, {
      field: 'OwnerCompanyName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.OWNER_COMPANY_NAME'),
      hidden: true,
      width: 150
    }, {
      field: 'OwnerCompanyContactName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.OWNER_PERSON_NAME'),
      hidden: true,
      width: 150
    }, {
      field: 'OwnerCompanyContactEmail',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.OWNER_PERSON_MAIL'),
      hidden: true,
      width: 150
    }, {
      field: 'ManagerCompanyName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.MANAGER_COMPANY_NAME'),
      hidden: true,
      width: 150
    }, {
      field: 'ManagerCompanyContactName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.MANAGER_PERSON_NAME'),
      hidden: true,
      width: 150
    }, {
      field: 'ManagerCompanyContactEmail',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.MANAGER_PERSON_MAIL'),
      hidden: true,
      width: 150
    }, {
      field: 'MaintenanceCompanyName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.MAINTENANCE_COMPANY_NAME'),
      hidden: true,
      width: 150
    }, {
      field: 'MaintenanceCompanyContactName',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.MAINTENANCE_PERSON_NAME'),
      hidden: true,
      width: 150
    }, {
      field: 'MaintenanceCompanyContactEmail',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.MAINTENANCE_PERSON_MAIL'),
      hidden: true,
      width: 150
    }, {
      field: 'StreetAddress',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.STREET_ADDRESS'),
      hidden: true,
      width: 150
    }, {
      field: 'City',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.CITY'),
      hidden: true,
      width: 150
    }, {
      field: 'FacilityCustomerSpecifiedIdentifier',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.REALESTATEID'),
      hidden: true,
      width: 150
    }, {
      field: 'EnegiaId',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.ENEGIAID'),
      hidden: true,
      width: 150
    }, {
      field: 'CustomerMeterIdentifier',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.CUSTOMERMETERIDENTIFIER'),
      hidden: true,
      width: 150
    }, {
      field: 'AutomaticReadingStartTime',
      title: $filter('translate')('MRC.READINGS_LISTING.GRID.AUTOMATICREADINGSTARTTIME'),
      type: 'date',
      format: '{0: d.M.yyyy}',
      hidden: true,
      width: 150
    }],
    hiddenColumnIndicesForExport: [],
    meterInputFormOptions: {
      shownFields: [
        'ReadingType',
        'ReadingDate',
        'ReadingValue',
        'NewReadingDate',
        'NewReadingValue'
      ],
      customColumnWidths: {
        ReadingType: 20,
        NewReadingDate: 150
      },
      customColumnTitles: {
        // Set title of G/Y/P column to be " ", empty string cannot be set because then field name will be used
        ReadingType: ' ',
        ReadingDate: utils.localizedString('MRC.READINGS_LISTING.GRID.PREV_READINGDATE')
      },
      disableAutoFitForColumns: ['NewReadingValue'],
      // Set text of group headers to be only value (not field name)
      groupHeaderTemplates: {
        FacilityName: '#= value #',
        MeterName: '#= value #'
      },
      grouping: [
        { field: 'FacilityName', dir: 'asc' },
        { field: 'MeterName', dir: 'asc' }
      ],
      // Sort by reading type (P before Y)
      sorting: [
        { field: 'ReadingType', dir: 'asc' }
      ],
      fileName: function() {
        return `${utils.localizedString('DOWNLOAD.METER_INPUT_FORM').replace(/\s/g, '_').toLowerCase() }_${
          kendo.toString(new Date(), 'd') }.xlsx`;
      }
    },
    massImportFormOptions: {
      shownFields: [
        'FacilityName',
        'MeterName',
        'MeterType',
        'CustomerMeterIdentifier',
        'EnegiaId',
        'MeterId',
        'ReadingDate',
        'NewReadingDate',
        'NewReadingValue'
      ],
      customColumnTitles: {
        ReadingDate: utils.localizedString('MRC.READINGS_LISTING.GRID.START_DATE'),
        NewReadingDate: utils.localizedString('MRC.READINGS_LISTING.GRID.END_DATE'),
        NewReadingValue: utils.localizedString('MRC.READINGS_LISTING.GRID.VALUE')
      },
      fileName: function() {
        return `${utils.localizedString('DOWNLOAD.SHORT_REPORT').replace(/\s/g, '_').toLowerCase() }_${
          kendo.toString(new Date(), 'd') }.xlsx`;
      }
    },
    exportExcludedColumnIndices: [0],
    excelExport: function(evt) {
      if ($scope.searchType === 'latest') {
        const sheet = evt.workbook.sheets[0];
        if (angular.isDefined(sheet) && sheet !== null) {
          sheet.frozenRows = 1;
          // Get fields that are shown in grid, only these fields will be taken to excel file
          const shownFields = _
            .filter($scope.readingsListingGrid.columns, column => !column.hidden)
            .map(column => column.field)
          ;
          // Get amount of grouping cells (not listed in shownFields but will be included in Excel)
          const cellAmount = sheet.columns.length;
          const groupingColumns = cellAmount - shownFields.length;
          const fieldMap = {};
          // Create map with field name as key and index as value
          for (let i = 0; i < shownFields.length; i++) {
            fieldMap[shownFields[i]] = i + groupingColumns;
          }
          const cellIndexes = {
            newReadingDate: {
              index: fieldMap.NewReadingDate
            }
          };
          // Check if all fields to calculate average day and month consumptions are shown
          // eslint-disable-next-line no-redeclare
          for (let i = 0; i < sheet.rows.length; i++) {
            const row = sheet.rows[i];
            if (i === 0) {
              _.each(row.cells, cell => {
                cell.hAlign = 'right';
              });
              continue;
            }
            _.each(row.cells, cell => {
              cell.vAlign = 'center';
            });
            if (row.type !== 'group-header') {
              row.height = 35;
              if (angular.isDefined(cellIndexes.newReadingDate.index) && cellIndexes.newReadingDate.index !== null) {
                row.cells[cellIndexes.newReadingDate.index].format = 'd.M.yyyy';
              }
            }
          }
        }
      }
    }
  };

  function updateExportOptions() {
    let str = '';
    if ($scope.searchType === 'latest') {
      str = $filter('translate')('MRC.READINGS_LISTING.LATEST');
      $scope.exportExcludedColumnIndices = [0];
    } else if ($scope.searchType === 'history' && $scope.selectedFacilities.list.length > 0) {
      str = $scope.selectedFacilities.list[0].Name;
    }
    $scope.readingsListingGridOptions.excel.fileName = `${str.replace(/\s/g, '_').toLowerCase() }_${
      kendo.toString(new Date(), 'd') }.xlsx`;
  }

  $scope.selectionChanged = function() {
    updateExportOptions();
  };

  function updateGrid() {
    $timeout(() => {
      gridDataSource.data = $scope.searchResults;
      if ($scope.readingsListingGrid) {
        const oldColumns = angular.copy($scope.readingsListingGrid.columns);
        $scope.readingsListingGridOptions.columns = oldColumns;
      }
      $scope.readingsListingGridOptions.dataSource = gridDataSource;
      $scope.readingsListingGrid = angular.element('#readingsListingGrid').data('kendoGrid');
      if (angular.isDefined($scope.readingsListingGrid)) {
        $scope.readingsListingGrid.setOptions($scope.readingsListingGridOptions);
        $scope.readingsListingGrid.refresh();
      }
    }, 100);
  }

  this.$onInit = () => {
    init();
  };

  function init() {
    $timeout(() => {
      $scope.searchType = $scope.mrcState.activeTab === 'mrc.readings_listing.readings_history' ? 'history' : 'latest';
      _.each($scope.readingsListingGridOptions.columns, column => {
        column.toggleable = true;
        if (column.field === 'selected' || column.field === 'NewReadingDate' || column.field === 'NewReadingValue' ||
            column.field === 'AvgConsumptionDay' || column.field === 'AvgConsumptionMonth') {
          column.toggleable = false;
        }
        if ($scope.mrcState.activeTab !== 'mrc.readings_listing.readings_history' &&
            (column.field === 'FacilityCustomerSpecifiedIdentifier' || column.field === 'selected')) {
          column.hidden = false;
        }
      });

      if ($scope.searchType === 'latest') {
        _.each($scope.facilities, facility => {
          facility.ticked = true;
        });
      } else {
        _.each($scope.facilities, facility => {
          facility.ticked = false;
        });
      }

      $scope.searchResults = [];
      $scope.allResultsSelected = false;

      updateExportOptions();
      updateGrid();
    });
  }

  function parseResults() {
    let idx = 0;
    _.each($scope.searchResults, result => {
      result.originalIndex = idx;
      if (result.ReadingDate !== null) {
        const dateObj = moment(angular.copy(result.ReadingDate)).local().startOf('day').toDate();
        result.ReadingDate = dateObj;
      }
      if (result.AutomaticReadingStartTime !== null && angular.isDefined(result.AutomaticReadingStartTime)) {
        const dateObj2 = moment(angular.copy(result.AutomaticReadingStartTime)).local().startOf('day').toDate();
        result.AutomaticReadingStartTime = dateObj2;
      }
      const facility = _.find($scope.selectedFacilities.list, fac => fac.Name === result.FacilityName);
      if (angular.isDefined(facility)) {
        if (facility.ownerCompany !== null) {
          result.OwnerCompanyName = facility.ownerCompany.ownerCompanyName;
          result.OwnerCompanyContactName = facility.ownerCompany.ownerCompanyContactName;
          result.OwnerCompanyContactEmail = facility.ownerCompany.ownerCompanyContactEmail;
        }
        if (facility.managerCompany !== null) {
          result.ManagerCompanyName = facility.managerCompany.managerCompanyName;
          result.ManagerCompanyContactName = facility.managerCompany.managerCompanyContactName;
          result.ManagerCompanyContactEmail = facility.managerCompany.managerCompanyContactEmail;
        }
        if (facility.maintenanceCompany !== null) {
          result.MaintenanceCompanyName = facility.maintenanceCompany.maintenanceCompanyName;
          result.MaintenanceCompanyContactName = facility.maintenanceCompany.maintenanceCompanyContactName;
          result.MaintenanceCompanyContactEmail = facility.maintenanceCompany.maintenanceCompanyContactEmail;
        }
        if (facility.facilityManagementCompany !== null) {
          result.FacilityManagementCompanyName = facility.facilityManagementCompany.facilityManagementCompanyName;
          result.FacilityManagementCompanyContactName =
            facility.facilityManagementCompany.facilityManagementCompanyContactName;
          result.FacilityManagementCompanyContactEmail =
            facility.facilityManagementCompany.facilityManagementCompanyContactEmail;
        }
        if (facility.FacilityInformation !== null) {
          result.StreetAddress = facility.FacilityInformation.StreetAddress;
          result.City = facility.FacilityInformation.City;
        }
      }
      idx += 1;
    });
    if ($scope.searchType === 'latest') {
      $scope.selectAll(true);
    }
    $scope.hasSelectedResults = countSelections() > 0;
  }

  function doSorting() {
    gridDataSource.sort = {
      field: 'FacilityName',
      dir: 'asc'
    };
  }

  $scope.searchDisabled = function() {
    if ($scope.searchType === 'history') {
      return $scope.selectedFacilities.list.length === 0;
    }
    return ($scope.selectedFacilities.list.length === 0 ||
            !($scope.searchOptions.listAutomaticMeters ||
              $scope.searchOptions.listConsumptionInputMeters ||
              $scope.searchOptions.listReadingInputMeters));
  };

  $scope.doSearch = function() {
    const ids = _.map($scope.selectedFacilities.list, 'FacilityId');
    if ($scope.searchType === 'latest') {
      doSearchLatest(ids);
    } else if ($scope.searchType === 'history') {
      doSearchHistory(ids);
    }
  };

  function doSearchLatest(ids) {
    const meterRequests$ = [];
    $scope.searchResults = [];

    if ($scope.searchOptions.listReadingInputMeters) {
      meterRequests$.push(defer(() => mrcapi.getLatestReadingsForMeters(ids)));
    }

    if ($scope.searchOptions.listConsumptionInputMeters || $scope.searchOptions.listAutomaticMeters) {
      meterRequests$.push(...ids.map(id => defer(() => mrcapi.getQuantitiesAndMeters(id).$promise)));
    }

    firstValueFrom(forkThrottle(meterRequests$, MAX_CONCURRENT_REQUESTS)).then(results => {
      parseReadingMeterResults(results);
      const consumptionMeterResults = {};
      const inputDateRequests = [];
      // Combine selected facilities and quantities to handle consumption meter results
      const combinedResults = _.zipWith(
        $scope.selectedFacilities.list, results, (facility, result) => ({ facility: facility, quantities: result })
      );
      parseConsumptionMeterResults(combinedResults, inputDateRequests, consumptionMeterResults);
      requestInputDates(inputDateRequests, consumptionMeterResults);
    }).catch(() => {
      utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_READINGS', true);
    });
  }

  function requestInputDates(inputDateRequests, meterResults) {
    $q.all(inputDateRequests).then(dateResults => {
      addReadingDatesToConsumptionMeters(dateResults, meterResults);
      $scope.searchResults = $scope.searchResults.concat(Object.values(meterResults));
      parseResults();
    }).catch(() => {
      utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_READINGS', true);
    }).finally(() => {
      doSorting();
      updateGrid();
    });
  }

  function parseConsumptionMeterResults(results, inputDateRequests, consumptionMeterResults) {
    _.each(results, result => {
      const facilityMeters = parseMetersFromQuantities(result.quantities);
      const consumptionMeters = filterMetersByType(facilityMeters, MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION);
      const automaticMeters = filterMetersByType(facilityMeters, MrcConstants.METERING_TYPE.HOURLY);
      let inputDateQueryMeters = [];

      if (consumptionMeters.length > 0 && $scope.searchOptions.listConsumptionInputMeters) {
        inputDateQueryMeters = inputDateQueryMeters.concat(consumptionMeters);
        addConsumptionMetersToResults(consumptionMeters, result.facility, consumptionMeterResults);
      }

      if (automaticMeters.length > 0 && $scope.searchOptions.listAutomaticMeters) {
        inputDateQueryMeters = inputDateQueryMeters.concat(automaticMeters);
        addAutomaticMetersToResults(automaticMeters, result.facility, consumptionMeterResults);
      }
      if (inputDateQueryMeters.length > 0) {
        inputDateRequests.push(createInputDateRequest(result.facility.FacilityId, inputDateQueryMeters));
      }
    });
  }

  function addConsumptionMetersToResults(meters, facility, meterResults) {
    addMetersOfTypeToResults(meters, facility, MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION, meterResults);
  }

  function addAutomaticMetersToResults(meters, facility, meterResults) {
    addMetersOfTypeToResults(meters, facility, MrcConstants.METERING_TYPE.HOURLY, meterResults);
  }

  function addMetersOfTypeToResults(meters, facility, type, meterResults) {
    _.each(meters, meter => {
      // Add results to map that has meter id as key, that can be used later
      // when reading dates are added to results
      meterResults[meter.Id] = createSingleMeterResult(meter, facility, type);
    });
  }

  function createInputDateRequest(facilityId, meters) {
    const meterIds = _.map(meters, 'Id');
    return mrcapi.getEarliestInputDatesForMeters(
      facilityId,
      meterIds
    );
  }

  function parseReadingMeterResults(results) {
    if ($scope.searchOptions.listReadingInputMeters) {
      // If reading meter readings are requested, they are always in the first index of results
      const readingsMeterReadings = results.splice(0, 1)[0];
      _.each(readingsMeterReadings, reading => {
        reading.MeterType = $scope.localizedMeterTypeTexts.manualReading;
        reading.MeterTypeId = MrcConstants.METERING_TYPE.MANUAL_READING;
      });
      $scope.searchResults = readingsMeterReadings;
    }
  }

  function doSearchHistory(ids) {
    mrcapi.getReadingsHistoryForMeters(ids[0]).then(
      res => {
        if (angular.isDefined(res)) {
          $scope.searchResults = res;
          parseResults();
        }
      },
      () => {
        utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_READINGS', true);
      }
    ).finally(() => {
      doSorting();
      updateGrid();
    });
  }

  function addReadingDatesToConsumptionMeters(inputDateResults, consumptionMeterResults) {
    _.each(inputDateResults, facilityMeterInputDates => {
      _.each(Object.keys(facilityMeterInputDates), meterId => {
        const meterResult = consumptionMeterResults[meterId];
        if (meterResult) {
          meterResult.ReadingDate = facilityMeterInputDates[meterId];
        }
      });
    });
  }

  function parseMetersFromQuantities(quantities = []) {
    let meters = [];
    quantities.forEach(quantity => {
      quantity.MainMeters.forEach(mainMeter => {
        meters = [...meters, ...mrcReadingsListingService.getSubMetersRecursive(mainMeter)];
      });
    });
    return meters;
  }

  function filterMetersByType(meters, type) {
    return _.filter(meters, meter => meter.MeteringType === type);
  }

  function createSingleMeterResult(meter, facility, type) {
    const meterType = type === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION ?
      $scope.localizedMeterTypeTexts.manualConsumption :
      $scope.localizedMeterTypeTexts.automatic
    ;
    return {
      EnegiaId: facility.FacilityInformation.EnegiaId,
      FacilityCustomerSpecifiedIdentifier: facility.FacilityInformation.RealEstateId,
      FacilityId: facility.FacilityId,
      FacilityName: facility.Name,
      Factor: meter.Factor,
      MeterId: meter.Id,
      MeterName: meter.Name,
      MeterType: meterType,
      MeterTypeId: type,
      ReadingDate: null,
      AutomaticReadingStartTime: meter.AutomaticReadingStartTime
    };
  }

  $scope.$watch('readingsListingGridOptions.height', (n, o) => {
    if (n !== o) {
      updateGrid();
    }
  });

  $scope.$on('facilitiesChanged', () => {
    if (!$scope.dataReady) {
      $scope.dataReady = true;
      init();
    }
  });

  const transitionSuccessUnbind = $transitions.onSuccess({}, transition => {
    if (
      transition.to().name === 'mrc.readings_listing.latest_readings' ||
      transition.to().name === 'mrc.readings_listing.readings_history'
    ) {
      $scope.selectedFacilities.list = [];
      _.each($scope.facilities, facility => {
        facility.ticked = false;
      });
      init();
    }
  });

  $scope.$on('$destroy', () => {
    transitionSuccessUnbind();
  });
}
