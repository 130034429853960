<kendo-dropdownlist
  [data]="data"
  [(ngModel)]="value"
  (ngModelChange)="valueChange($event)"
  (blur)="blur()"
  [valuePrimitive]="valuePrimitive"
  [filterable]="filterable"
  (filterChange)="filterChange($event)"
  [textField]="'text'"
  [valueField]="'value'"
  [defaultItem]="showDefaultItem ? defaultItem : undefined"
  [loading]="loading"
  [disabled]="disabled || (disableWhenLoading && loading)"
  [virtual]="virtual"
  [popupSettings]="{ animate: false }"
  [readonly]="readonly"
>
  <ng-template
    kendoDropDownListItemTemplate
    kendoDropDownListValueTemplate
    let-dataItem
  >
    <ng-container *ngIf="dataItem">
      <ng-container *ngTemplateOutlet="contentTemplate?.ref || itemTemplate; context: { $implicit: dataItem }"></ng-container>
    </ng-container>
  </ng-template>
</kendo-dropdownlist>

<ng-template
  #itemTemplate
  let-dataItem
>
  <span
    *ngIf="dataItem?.text"
    class="noselect"
  >
    {{ noTranslate ? dataItem.text : (dataItem.text | translate) }}
  </span>
</ng-template>
