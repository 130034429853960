infiniteScroll.$inject = [
  '$rootScope',
  '$state',
  '$window',
  '$document',
  '$timeout',
  '_',
  'appStatusService',
  '$transitions',
  'erStateService'
];

function infiniteScroll(
  $rootScope,
  $state,
  $window,
  $document,
  $timeout,
  _,
  appStatusService,
  $transitions,
  erStateService
) {
  let stateName;
  let noCheck = true;

  /* Source: http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
      + modified: check only top instead (in our case, it is not needed to be fully on viewport) */
  const isElementInViewport = function(el, page) {
    // special bonus for those using jQuery
    if (angular.isFunction(jQuery) && el instanceof jQuery) {
      el = el[0];
    }
    if (angular.isFunction(jQuery) && page instanceof jQuery) {
      page = page[0];
    }

    const pageYOffset = page.scrollTop || page.pageYOffset || 0;
    const pageXOffset = page.scrollLeft || page.pageXOffset || 0;
    const innerWidth = page.clientWidth || page.innerWidth;
    const innerHeight = page.clientHeight || page.innerHeight;

    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < pageYOffset + innerHeight &&
      left < pageXOffset + innerWidth &&
      top + height > pageYOffset &&
      left + width > pageXOffset
    );
  };

  function percentageLimit(doc, page) {
    let result = 50;
    result += parseInt((doc.height() / page.height()) * 5);
    result = Math.min(95, result);
    return result;
  }

  function doCheck(options, doc, page) {
    const heightDiff = doc.height() - page.height();
    const scrollPercent = heightDiff ? (100 * page.scrollTop()) / heightDiff : 100;

    if (!noCheck && _.isArray(options.items) && options.items.length) {
      if (scrollPercent > percentageLimit(doc, page)) {
        options.loadFunc(1);
      } else {
        let direction = -1;
        options.items.forEach(item => {
          if (item.obsolete) {
            const element = angular.element(`#${options.itemPrefix}${item.Id}`)[0];
            if (element && isElementInViewport(element, page)) {
              options.loadFunc(direction);
              return false;
            }
          } else {
            direction = 1;
          }
        });
      }
    }
  }

  function doCheckAll(options, doc, page) {
    if (!noCheck && _.isArray(options.items) && options.items.length) {
      let fetchMore = true;
      options.items.forEach(item => {
        const element = angular.element(`#${options.itemPrefix}${item.Id}`)[0];
        if (element && !isElementInViewport(element, page)) {
          fetchMore = false;
          return false;
        }
      });
      if (fetchMore) {
        options.loadFunc(1);
      }
    }
  }

  const doCheckDebounced = _.debounce(doCheck, 250);

  return {
    scope: {
      options: '=infiniteScroll'
    },
    link: function(scope, element, attrs) {
      // resolve state name
      stateName = $state.current.name;

      // scroll, page and doc elements
      const elements = {
        page: erStateService.isModalOpen()
          ? angular.element(element).closest('.modal-state-body')
          : angular.element($window),
        doc: erStateService.isModalOpen()
          ? angular.element(element).closest('.modal-report')
          : angular.element($document)
      };

      // do a wrapper for original reset function
      const originalResetFunc = scope.options.resetFunc;
      scope.options.resetFunc = function() {
        // call original reset
        if (angular.isFunction(originalResetFunc)) {
          originalResetFunc.apply(this, arguments);
        }

        // obsolete data if needed
        const options = scope.options;
        if (!options.doNotObsolete) {
          // find new current index
          let currentIndex = 0;
          if (_.isArray(options.items) && options.items.length > options.loadCount) {
            for (let i = 0; i < options.items.length; i++) {
              const id = scope.options.items[i].Id;
              const element = angular.element(`#${options.itemPrefix}${id}`)[0];
              if (element && isElementInViewport(element, elements.page)) {
                currentIndex = i;
                break;
              }
            }
          }
          // obsolete data
          _.each(scope.options.items, (item, index) => {
            item.obsolete = index < currentIndex || index >= currentIndex + options.loadCount;
          });
        }
      };

      // watch for noCheck
      attrs.$observe('noCheck', value => {
        noCheck = scope.$eval(value);
        if (!noCheck) {
          $timeout(() => {
            doCheckAll(scope.options, elements.doc, elements.page);
          }, 250);
        }
      });

      // handle scroll
      const handleScroll = function() {
        doCheckDebounced(scope.options, elements.doc, elements.page);
      };
      elements.page.bind('scroll', handleScroll);

      // handle state change
      const stateChangeSuccessUnbind = $transitions.onSuccess({}, transitions => {
        // need to set noCheck to false when entering to modal
        if (transitions.to().name !== stateName) {
          noCheck = false;
        }
      });

      // handle destroy
      scope.$on('$destroy', () => {
        elements.page.unbind('scroll', handleScroll);
        stateChangeSuccessUnbind();
      });
    }
  };
}

export default infiniteScroll;
