import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';

import { UserService } from '../../services/user-service';
import { Roles } from '../../modules/admin/constants/roles';
import { LegacyFacilityService } from '../../modules/reportingobjects/models/facilities';

@Pipe({ name: 'hasFacilityAccess' })
export class HasFacilityAccessPipe implements PipeTransform {

  public constructor(
    private readonly userService: UserService,
    private readonly facilityService: LegacyFacilityService
  ) { }

  /** Use with `async`-pipe. */
  public transform(value: number): Observable<boolean> {
    if (!Number.isInteger(value)) {
      return of(false);
    }

    if (this.userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES)) {
      return of(true);
    }

    // Debounce fixes "Expression changed..."-error, probably caused by $q promises
    return from(
      this.facilityService.getFacilities().then(
        facilities => facilities.some(f => f.FacilityId === value)
      )
    ).pipe(
      catchError(() => of(false)),
      debounceTime(10)
    );
  }

}
