import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { ModalService } from '@enerkey/foundation-angular';

import { StateLocationService } from '../../../../services/state-location.service';
import { UserService } from '../../../../services/user-service';

import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { Roles } from '../../../../modules/admin/constants/roles';
import {
  DocumentsOperationModalComponent
} from '../../../../modules/documents/components/documents-operation-modal/documents-operation-modal.component';
import { FacilitiesPopupDocumentsService } from './facilities-popup-documents.service';

@Component({
  selector: 'facilities-popup-documents',
  templateUrl: './facilities-popup-documents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilitiesPopupDocumentsComponent implements OnInit {
  @Input() public facility: ExtendedFacilityInformation;
  @Input() public meter: { Id: number; Name: string };

  public toggleTitle: string;
  public documents$: Observable<any[]>;

  public readonly allowToAddDocument: boolean;

  public constructor(
    private readonly popupDocumentsService: FacilitiesPopupDocumentsService,
    private readonly userService: UserService,
    private readonly modalService: ModalService,
    private readonly stateLocationService: StateLocationService
  ) {
    this.allowToAddDocument = this.userService.hasRole(Roles.DOCUMENT_WRITE);
  }

  public ngOnInit(): void {
    this.toggleTitle = this.meter
      ? 'FACILITIES_REPORT.LATEST_METER_DOCUMENTS_POPUP_TITLE'
      : 'FACILITIES_REPORT.LATEST_FACILITY_DOCUMENTS_POPUP_TITLE'
    ;

    this.documents$ = this.meter
      ? this.popupDocumentsService.getMeterDocuments(this.meter.Id, this.facility.FacilityId)
      : this.popupDocumentsService.getFacilityDocuments(this.facility.FacilityId)
    ;
  }

  public addDocument(): void {
    const documentCreateParams = this.popupDocumentsService.getDocumentsEditStateParams(this.facility, this.meter);
    const modal = this.modalService.open(DocumentsOperationModalComponent);
    modal.componentInstance.facilityId = documentCreateParams.facilityId;
    modal.componentInstance.meter = documentCreateParams.meter;
    modal.componentInstance.defaultType = documentCreateParams.attachmentType;
  }

  public goToDocuments(): void {
    const stateParams = this.popupDocumentsService.getDocumentsStateParams(this.facility, this.meter);
    const newState = this.meter ? 'documents.meter' : 'documents.facility';
    this.stateLocationService.openStateInNewTab(newState, stateParams);
  }
}
