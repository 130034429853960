import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const alarmManagementTabState: UIStateNg2 = {
  name: 'alarm',
  data: {
    translationKey: 'ADMIN.TABS.ALARM_MANAGEMENT',
    auth: {
      roles: [Roles.ALARM_MANAGER],
    },
  }
};
