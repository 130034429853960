import template from 'raw-loader!./manual-qa-search-reading-api.html';

export default {
  template: template,
  controllerAs: 'vm',
  bindings: {
    searchCriteria: '<',
    onChange: '&'
  }
};
