import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Used for lifting components and elements into a parent component.
 *
 * This service should be scoped to have one instance shared by only two components (parent and child).
 */
@Injectable()
export class TemplateLifterService implements OnDestroy {

  public readonly templateChanged$: Observable<TemplateRef<unknown>>;

  /** Returns the current template. Changing this value emits `templateChanged$`. */
  public get template(): TemplateRef<unknown> {
    return this._templateChanged$.value;
  }

  public set template(value: TemplateRef<unknown>) {
    this._templateChanged$.next(value ?? null);
  }

  private readonly _templateChanged$ = new BehaviorSubject<TemplateRef<unknown>>(null);

  public constructor() {
    this.templateChanged$ = this._templateChanged$.pipe(distinctUntilChanged());
  }

  public ngOnDestroy(): void {
    // Clear possible leftover subscriptions
    this._templateChanged$.complete();
  }
}
