import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import {
  IIncomingRequestSearchCriteria,
  IncomingRequestSearchCriteria,
  RequestStatus
} from '@enerkey/clients/webhook';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { formGroupFrom } from '@enerkey/ts-utils';
import { requestStatusTranslationKeys } from '../../pipes/request-status-name.pipe';
import { IncomingMessagesService } from '../../services/incoming-messages.service';

@Component({
  selector: 'incoming-messages-search',
  templateUrl: './incoming-messages-search.component.html',
  styleUrls: ['./incoming-messages-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncomingMessagesSearchComponent {
  public searchForm: UntypedFormGroup;

  public statusOptions: ComboItem<RequestStatus>[];

  public constructor(
    private readonly incomingMessagesService: IncomingMessagesService
  ) {
    this.searchForm = formGroupFrom<IIncomingRequestSearchCriteria>({
      beginDate: null,
      endDate: null,
      statuses: [],
      customerId: null,
      systemId: null,
    });

    this.statusOptions = getNumericEnumValues(RequestStatus).map(option => ({
      value: option,
      text: requestStatusTranslationKeys[option]
    }));
  }

  public search(): void {
    this.incomingMessagesService.search(new IncomingRequestSearchCriteria(this.searchForm.value));
  }
}
