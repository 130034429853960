import _ from 'lodash';

import { CalculationMethod } from '../../modules/configuration/constants/calculation-method';
import { CoefficientMethods } from '../../modules/configuration/constants/coefficient-methods';
import template from 'raw-loader!../../modules/mrc/templates/mrc-confirmation-modal.html';

const $inject = ['$modal', '$element', '$timeout', 'utils', 'YearlyConsumptionService', 'quantities'];

const KeyCodes = {
  ENTER: 13,
  ESC: 27,
};

function YearlyConsumptionInputController($modal, $element, $timeout, utils, YearlyConsumptionService, quantities) {
  const vm = this;

  vm.regexPattern = '-?[0-9]+([,.][0-9]+)?';
  vm.numberOfDecimals = vm.numberOfDecimals || 0;
  vm.CalculationMethod = CalculationMethod;

  vm.isDistributionMethodEnabled = function() {
    return quantities.getCoefficientMethodForQuantity(vm.quantityId) !== CoefficientMethods.FLAT;
  };

  const initialiseInputValue = function() {
    vm.inputValue = _.isNumber(vm.valueSum) ? vm.valueSum.toFixed(2) : null;
  };

  vm.focusOnInput = function() {
    const inputField = $element.find('.yearly-consumption-input__input-field');
    if (inputField) {
      $timeout(() => {
        inputField.focus();
      });
    }

    return true;
  };

  vm.$onInit = function() {
    vm.calculationMethodGroupId = utils.guid();

    if (vm.isDistributionMethodEnabled() === false) {
      vm.calculationMethod = CalculationMethod.EVEN;
    } else {
      vm.calculationMethod = CalculationMethod.DISTRIBUTION;
    }

    initialiseInputValue();
  };

  vm.$postLink = function() {
    $element.on('keyup', '.yearly-consumption-input__input-field', e => {
      // enter, escape
      if (e.keyCode === KeyCodes.ENTER) {
        vm.saveValues();
      } else if (e.keyCode === KeyCodes.ESC) {
        vm.close();
      }
    });

    // TODO: This doesn't work, popup closes when user tries to click Save button etc
    /* $element.on('blur', '.yearly-consumption-input', function() {
     vm.close();
     }); */
  };

  vm.rejectChanges = function() {
    initialiseInputValue();

    return true;
  };

  vm.close = function() {
    $timeout(() => {
      $element.find('#close-button').click();
    });
  };

  const showConfirmationModal = function() {
    const modalInstance = $modal.open({
      template: template,
      windowClass: 'tiny',
      controller: ['$scope', function($scope) {
        $scope.confirmationText = utils.localizedString('MRC.CONSUMPTIONS.CONFIRM_RECALCULATION');
      }],
      resolve: {
        confirmation: function() {
          return 0;
        }
      }
    });

    return modalInstance.result;
  };

  vm.saveValues = function() {
    function save() {
      if (angular.isString(vm.valueSum)) {
        vm.valueSum = parseFloat(vm.inputValue.replace(',', '.'));
      }

      const yearValues = YearlyConsumptionService.calculateValuesForYear(
        vm.inputValue, vm.calculationMethod, vm.quantityId, vm.selectedYear, vm.convertValues
      );

      if (angular.isFunction(vm.onSaveValues)) {
        vm.onSaveValues({ yearValues: yearValues, extraParams: vm.saveValuesExtraParams });
      }

      vm.close();
    }

    if (!vm.isEmptyYear) {
      showConfirmationModal().then(
        () => {
          save();
        },
        () => {
          // No ops
        }
      );
    } else {
      save();
    }
  };
}

YearlyConsumptionInputController.$inject = $inject;

export default YearlyConsumptionInputController;
