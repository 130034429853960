import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';

import { ExtendedFacilityInformation } from '../../../../shared/interfaces/extended-facility-information';
import { ReportingModalsService } from '../../services/reporting-modals.service';
import { ReportingSearchService } from '../../services/reporting-search.service';

@Component({
  selector: 'sum-report-facilities-card',
  templateUrl: './sum-report-facilities-card.component.html',
  styleUrls: ['./sum-report-facilities-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumReportFacilitiesCardComponent {
  @Input() public facilities: ExtendedFacilityInformation[];

  public constructor(
    private readonly reportingModalsService: ReportingModalsService,
    private readonly reportingSearchService: ReportingSearchService
  ) {
  }

  public openModal(facilityId: number): void {
    this.reportingSearchService.searchParameters$.pipe(
      take(1)
    ).subscribe({
      next: params => {
        this.reportingModalsService.openReport(
          facilityId,
          params
        );
      }
    });
  }
}
