import { SimpleChangesOf } from '@enerkey/ts-utils';

/* eslint-disable @typescript-eslint/no-explicit-any */
type Obj = Record<keyof any, unknown>;

/**
 * Helper method to check whether changes object has changes for given key or not.
 *
 * @param {Object} changes
 * @param {string} key
 *
 * @return {boolean}
 */
export function hasChanges<T extends Obj = any>(
  changes: SimpleChangesOf<T>,
  key: keyof T
): boolean {
  return !!changes[key] && changes[key].currentValue !== changes[key].previousValue;
}

/**
 * Helper method to check whether changes object has first change for given key
 *
 * @param {Object} changes
 * @param {string} key
 *
 * @returns {boolean}
 */
export function isFirstChange<T extends Obj = any>(
  changes: SimpleChangesOf<T>,
  key: keyof T
): boolean {
  return !!changes[key] && changes[key].isFirstChange();
}
