<modal-view-header
  [headingText]="(title || (defaultTitle | translate)) + ' - ' + ('WIDGET.SETTINGS' | translate)"
></modal-view-header>
<div class="modal-body widget-settings-container">
  <input
    type="text"
    [placeholder]="defaultTitle | translate"
    [formControl]="titleControl"
    *labelBefore="'WIDGET.TITLE' | translate"
  />
  <ng-container [ngSwitch]="widgetType">
    <comments-widget-options
      *ngSwitchCase="WidgetTypeEnum.Comments"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></comments-widget-options>
    <actions-widget-options
      *ngSwitchCase="WidgetTypeEnum.Actions"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></actions-widget-options>
    <grouped-consumptions-widget-options
      *ngSwitchCase="WidgetTypeEnum.GroupedConsumptions"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></grouped-consumptions-widget-options>
    <top-changed-consumptions-widget-options
      *ngSwitchCase="WidgetTypeEnum.TopChangedConsumptions"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></top-changed-consumptions-widget-options>
    <consumptions-widget-options
      *ngSwitchCase="WidgetTypeEnum.Consumptions"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></consumptions-widget-options>
    <sum-consumptions-widget-options
      *ngSwitchCase="WidgetTypeEnum.SumConsumptions"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></sum-consumptions-widget-options>
    <emissions-widget-options
      *ngSwitchCase="WidgetTypeEnum.Emissions"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></emissions-widget-options>
    <error-ticket-widget-options
      *ngSwitchCase="WidgetTypeEnum.ErrorTicket"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></error-ticket-widget-options>
    <latest-alarms-widget-options
      *ngSwitchCase="WidgetTypeEnum.LatestAlarms"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></latest-alarms-widget-options>
    <emissions-facility-wise-widget-options
      class="rm-validation-styles"
      *ngSwitchCase="WidgetTypeEnum.EmissionsFacilityWise"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    > </emissions-facility-wise-widget-options>
    <facility-latest-alarms-widget-options
      class="rm-validation-styles"
      *ngSwitchCase="WidgetTypeEnum.FacilityLatestAlarms"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    ></facility-latest-alarms-widget-options>
    <facility-et-curve-widget-options
      class="rm-validation-styles"
      *ngSwitchCase="WidgetTypeEnum.FacilityEtCurve"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)">
    </facility-et-curve-widget-options>
    <consumptions-by-facility-widget-options
      class="rm-validation-styles"
      *ngSwitchCase="WidgetTypeEnum.FacilityConsumptions"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    > </consumptions-by-facility-widget-options>
    <facility-comments-widget-options
      class="rm-validation-styles"
      *ngSwitchCase="WidgetTypeEnum.FacilityComments"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    > </facility-comments-widget-options>
    <facility-actions-widget-options
      class="rm-validation-styles"
      *ngSwitchCase="WidgetTypeEnum.FacilityActions"
      [formControl]="settingsControl"
    > </facility-actions-widget-options>
    <power-widget-options
      class="rm-validation-styles"
      *ngSwitchCase="WidgetTypeEnum.Power"
      [formControl]="settingsControl"
      [initialState]="$any(dataModelOptions)"
    > </power-widget-options>
  </ng-container>
</div>
<div class="modal-footer">
  <button
    *ngIf="!isNew && canDeleteWidget"
    class="button button--link button--negative"
    (click)="deleteWidget()"
  >
    {{ 'DELETE' | translate }}
  </button>
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      (click)="saveWidget()"
      [disabled]="!titleControl?.valid || !settingsControl?.valid"
    >
      {{ 'MODALS.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      (click)="cancel()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
