import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  CreateFacilityCustomerField,
  FacilityClient,
  FacilityCustomerFieldConnectParameters,
  FacilityProperty,
  Property,
  UpdateFacilityCustomerField,
  Url,
  UrlList,
  WaterHeatingEnergyCalculation
} from '@enerkey/clients/facility';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { FacilityService } from '../../../shared/services/facility.service';
import { ToasterService } from '../../../shared/services/toaster.service';

@Injectable()
export class FacilityEditService implements OnDestroy {
  public readonly facilityProperties$: Observable<FacilityProperty[]>;
  public readonly allProperties$: Observable<Record<number, Property>>;
  public readonly modifiedPropertyIds$: Observable<number[]>;
  public readonly addedCustomerFieldIds$: Observable<number[]>;
  public readonly updatedCustomerFieldIds$: Observable<number[]>;
  public readonly deletedCustomerFieldIds$: Observable<number[]>;
  public readonly facilityId$: Observable<number>;
  public readonly facilityLoading$: Observable<boolean>;
  public readonly propertiesLoading$: Observable<boolean>;

  private readonly _facilityId$ = new ReplaySubject<number>(1);
  private readonly _facilityProperties$ = new BehaviorSubject<FacilityProperty[]>([]);
  private readonly _modifiedPropertyIds$ = new BehaviorSubject<number[]>([]);
  private readonly _removedPropertyIds$ = new BehaviorSubject<number[]>([]);
  private readonly _addedThirdPartyUrls$ = new BehaviorSubject<Url[]>([]);
  private readonly _removedThirdPartyUrls$ = new BehaviorSubject<Url[]>([]);

  private readonly _addedCustomerFields$ = new BehaviorSubject<CreateFacilityCustomerField[]>([]);
  private readonly _addedCustomerFieldIds$ = new BehaviorSubject<number[]>([]);
  private readonly _updatedCustomerFields$ = new BehaviorSubject<UpdateFacilityCustomerField[]>([]);
  private readonly _updatedCustomerFieldIds$ = new BehaviorSubject<number[]>([]);
  private readonly _deletedCustomerFields$ = new BehaviorSubject<FacilityCustomerFieldConnectParameters[]>([]);
  private readonly _deletedCustomerFieldIds$ = new BehaviorSubject<number[]>([]);

  private readonly _destroy$ = new Subject<void>();
  private readonly _facilityLoading$ = new LoadingSubject(true);
  private readonly _propertiesLoading$ = new LoadingSubject(true);

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly facilityService: FacilityService,
    private readonly toasterService: ToasterService
  ) {
    this.facilityLoading$ = this._facilityLoading$.asObservable();
    this.propertiesLoading$ = this._propertiesLoading$.asObservable();
    this.facilityId$ = this._facilityId$.asObservable();
    this.facilityProperties$ = this._facilityProperties$.asObservable();
    this.modifiedPropertyIds$ = this._modifiedPropertyIds$.asObservable();
    this.addedCustomerFieldIds$ = this._addedCustomerFieldIds$.asObservable();
    this.updatedCustomerFieldIds$ = this._updatedCustomerFieldIds$.asObservable();
    this.deletedCustomerFieldIds$ = this._deletedCustomerFieldIds$.asObservable();
    this.allProperties$ = this.facilityService.allProperties$.pipe(
      take(1),
      indicate(this._propertiesLoading$),
      map(properties => properties.toRecord(p => p.id, p => p)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
    this._facilityId$.pipe(
      switchMap(facilityId => this.facilityClient.getFacilityProperties(
        [facilityId]
      ).pipe(
        indicate(this._facilityLoading$),
        map(properties => properties[facilityId])
      )),
      takeUntil(this._destroy$)
    ).subscribe(properties => this._facilityProperties$.next(properties));
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._facilityId$.complete();
    this._facilityProperties$.complete();
    this._modifiedPropertyIds$.complete();
    this._removedPropertyIds$.complete();
    this._addedThirdPartyUrls$.complete();
    this._removedThirdPartyUrls$.complete();
    this._addedCustomerFields$.complete();
    this._addedCustomerFieldIds$.complete();
    this._updatedCustomerFields$.complete();
    this._updatedCustomerFieldIds$.complete();
    this._deletedCustomerFields$.complete();
    this._deletedCustomerFieldIds$.complete();
    this._facilityLoading$.complete();
    this._propertiesLoading$.complete();
  }

  public setFacilityId(id: number): void {
    this._facilityId$.next(id);
  }

  public addNewProperty(property: FacilityProperty): void {
    let data = this._facilityProperties$.value;
    data.push(property);
    data = data.sortBy('propertyId');
    this._facilityProperties$.next(data);
  }

  public editPropertyValue(
    property: FacilityProperty, value: string,
    customType: WaterHeatingEnergyCalculation = null
  ): void {
    const data = this._facilityProperties$.value;
    const item = data[data.indexOf(property)];
    if (customType) {
      item.customType = customType;
    }
    item.value = value;
    if (property.id) {
      const ids = this._modifiedPropertyIds$.value;
      ids.push(property.id);
      this._modifiedPropertyIds$.next(ids);
    }
    this._facilityProperties$.next(data);
  }

  public removeProperty(property: FacilityProperty): void {
    const data = this._facilityProperties$.value;
    if (property.id) {
      const removed = this._removedPropertyIds$.value;
      removed.push(property.id);
      this._removedPropertyIds$.next(removed);
    }
    data.remove(property);
    this._facilityProperties$.next(data);
  }

  public addNewUrl(url: Url): void {
    const urls = this._addedThirdPartyUrls$.value;
    urls.push(url);
    this._addedThirdPartyUrls$.next(urls);
  }

  public removeUrl(url: Url): void {
    const addedUrls = this._addedThirdPartyUrls$.value;
    const urls = this._removedThirdPartyUrls$.value;
    const exists = addedUrls.indexOf(url);
    if (exists === -1) {
      urls.push(url);
    } else {
      addedUrls.removeAt(exists);
      this._addedThirdPartyUrls$.next(addedUrls);
    }
    this._removedThirdPartyUrls$.next(urls);
  }

  public addFacilityCustomerField(field: CreateFacilityCustomerField): void {
    const addedFields = this._addedCustomerFields$.value;
    const addedIds = this._addedCustomerFieldIds$.value;
    const deletedIds = this._deletedCustomerFieldIds$.value;
    if (deletedIds.includes(field.customerFieldId)) {
      // Update instead of adding if field was just deleted but not yet saved
      const deletedFields = this._deletedCustomerFields$.value;
      const deletedIndex = deletedFields.findIndex(f => f.customerFieldId === field.customerFieldId);
      this.updateFacilityCustomerField(new UpdateFacilityCustomerField({
        customerFieldId: field.customerFieldId,
        value: deletedFields[deletedIndex].value,
        updateValue: field.value
      }));
      deletedFields.removeAt(deletedIndex);
      this._deletedCustomerFields$.next(deletedFields);
      deletedIds.remove(field.customerFieldId);
      this._deletedCustomerFieldIds$.next(deletedIds);
    } else {
      if (addedIds.includes(field.customerFieldId)) {
        // Remove previous entry to prevent adding duplicates
        addedFields.removeAt(addedFields.findIndex(f => f.customerFieldId === field.customerFieldId));
      } else {
        addedIds.push(field.customerFieldId);
        this._addedCustomerFieldIds$.next(addedIds);
      }
      addedFields.push(field);
      this._addedCustomerFields$.next(addedFields);
    }
  }

  public updateFacilityCustomerField(field: UpdateFacilityCustomerField): void {
    const updatedFields = this._updatedCustomerFields$.value;
    const addedIds = this._addedCustomerFieldIds$.value;
    const updatedIds = this._updatedCustomerFieldIds$.value;
    if (addedIds.includes(field.customerFieldId)) {
      const addedFields = this._addedCustomerFields$.value;
      addedFields[addedFields.findIndex(f => f.customerFieldId === field.customerFieldId)].value = field.updateValue;
      this._addedCustomerFields$.next(addedFields);
    } else if (updatedIds.includes(field.customerFieldId)) {
      // Prevent double updates
      const updatedIndex = updatedFields.findIndex(f => f.customerFieldId === field.customerFieldId);
      updatedFields[updatedIndex].updateValue = field.updateValue;
      this._updatedCustomerFields$.next(updatedFields);
    } else {
      updatedFields.push(field);
      this._updatedCustomerFields$.next(updatedFields);
      updatedIds.push(field.customerFieldId);
      this._updatedCustomerFieldIds$.next(updatedIds);
    }
  }

  public deleteFacilityCustomerField(field: FacilityCustomerFieldConnectParameters): void {
    const deletedFields = this._deletedCustomerFields$.value;
    const updatedIds = this._updatedCustomerFieldIds$.value;
    if (updatedIds.includes(field.customerFieldId)) {
      // Do not update if already deleted
      const updatedFields = this._updatedCustomerFields$.value;
      const updatedIndex = updatedFields.findIndex(f => f.customerFieldId === field.customerFieldId);
      field.value = updatedFields[updatedIndex].value;
      updatedFields.removeAt(updatedIndex);
      this._updatedCustomerFields$.next(updatedFields);
      updatedIds.remove(field.customerFieldId);
      this._updatedCustomerFieldIds$.next(updatedIds);
    }
    const addedIds = this._addedCustomerFieldIds$.value;
    if (addedIds.includes(field.customerFieldId)) {
      // Do not add new field if already deleted
      const addedFields = this._addedCustomerFields$.value;
      addedFields.removeAt(addedFields.findIndex(f => f.customerFieldId === field.customerFieldId));
      this._addedCustomerFields$.next(addedFields);
      addedIds.remove(field.customerFieldId);
      this._addedCustomerFieldIds$.next(addedIds);
    } else {
      deletedFields.push(field);
      this._deletedCustomerFields$.next(deletedFields);
      const deletedIds = this._deletedCustomerFieldIds$.value;
      deletedIds.push(field.customerFieldId);
      this._deletedCustomerFieldIds$.next(deletedIds);
    }
  }

  public savePropertyChanges(): Observable<void> {
    const newFacilities = this._facilityProperties$.value.filter(p => p.id === null);
    newFacilities.forEach(item => { item.id = 0; });
    const updateFacilities = this._facilityProperties$.value.filter(
      p => this._modifiedPropertyIds$.value.includes(p.id)
    );
    const removeProperties = this._removedPropertyIds$.value;
    const createRequest = newFacilities.length
      ? this._facilityId$.pipe(
        take(1),
        switchMap(facilityId => this.facilityClient.addFacilityProperties(
          facilityId, newFacilities
        )),
        tap({
          next: () => {
            this.toasterService.success('FACILITY.PROPERTYADDED');
          },
          error: () => this.toasterService.generalError('SAVE', 'PROPERTIES')
        })
      )
      : of(null);
    const updateRequest = updateFacilities.length
      ? this._facilityId$.pipe(
        take(1),
        switchMap(facilityId => this.facilityClient.updateFacilityPropertiesValue(
          facilityId, updateFacilities
        )),
        tap({
          next: () => {
            this._modifiedPropertyIds$.next([]);
            this.toasterService.success('FACILITY.PROPERTYUPDATED');
          },
          error: () => this.toasterService.generalError('UPDATE', 'PROPERTIES')
        })
      )
      : of(null);
    const removeRequest = removeProperties.length
      ? this._facilityId$.pipe(
        take(1),
        switchMap(facilityId => this.facilityClient.deleteFacilityProperties(
          facilityId, removeProperties
        )),
        tap({
          next: () => {
            this._removedPropertyIds$.next([]);
            this.toasterService.success('FACILITY.PROPERTYDELETED');
          },
          error: () => this.toasterService.generalError('DELETE', 'PROPERTIES')
        })
      )
      : of(null);
    return createRequest.pipe(
      switchMap(() => updateRequest.pipe(
        switchMap(() => removeRequest)
      ))
    );
  }

  public saveUrlChanges(): Observable<[void, void]> {
    const createRequest = this._addedThirdPartyUrls$.value.length
      ? this._facilityId$.pipe(
        take(1),
        switchMap(facilityId => this.facilityClient.addFacilityUrls(new UrlList({
          facilityIds: [facilityId],
          urls: this._addedThirdPartyUrls$.value
        }))),
        tap({
          next: () => {
            this.toasterService.success('FACILITY.THIRDPARTYLINK_ADDED');
          },
          error: () => this.toasterService.generalError('SAVE', 'LINKS')
        })
      )
      : of(null);
    const removeRequest = this._removedThirdPartyUrls$.value.length
      ? this._facilityId$.pipe(
        take(1),
        switchMap(facilityId => this.facilityClient.removeFacilityUrls(new UrlList({
          facilityIds: [facilityId],
          urls: this._removedThirdPartyUrls$.value
        }))),
        tap({
          next: () => {
            this._removedThirdPartyUrls$.next([]);
            this.toasterService.success('FACILITY.THIRDPARTYLINK_DELETED');
          },
          error: () => this.toasterService.generalError('DELETE', 'LINKS')
        })
      )
      : of(null);
    return forkJoin([
      createRequest,
      removeRequest
    ]);
  }

  public saveFacilityCustomerFieldChanges(): Observable<void> {
    const addedFields = this._addedCustomerFields$.value;
    const addRequest = addedFields.length
      ? this._facilityId$.pipe(
        take(1),
        tap(facilityId => addedFields.forEach(f => { f.facilityId = facilityId; })),
        switchMap(() => this.facilityClient.addFacilityToCustomerField(addedFields)),
        tap({
          next: () => {
            this._addedCustomerFields$.next([]);
            this._addedCustomerFieldIds$.next([]);
            this.toasterService.success('CUSTOMERFIELDS.ADDED');
          },
          error: () => this.toasterService.generalError('SAVE', 'CUSTOMER_FIELDS')
        })
      )
      : of(null);
    const updatedFields = this._updatedCustomerFields$.value;
    const updateRequest = updatedFields.length
      ? this._facilityId$.pipe(
        take(1),
        tap(facilityId => updatedFields.forEach(f => { f.facilityId = facilityId; })),
        switchMap(() => this.facilityClient.updateCustomerFieldForFacilities(updatedFields)),
        tap({
          next: () => {
            this._updatedCustomerFields$.next([]);
            this._updatedCustomerFieldIds$.next([]);
            this.toasterService.success('CUSTOMERFIELDS.UPDATED');
          },
          error: () => this.toasterService.generalError('UPDATE', 'CUSTOMER_FIELDS')
        })
      )
      : of(null);
    const deletedFields = this._deletedCustomerFields$.value;
    const deleteRequest = deletedFields.length
      ? this.facilityId$.pipe(
        take(1),
        tap(facilityId => deletedFields.forEach(f => { f.facilityId = facilityId; })),
        switchMap(() => this.facilityClient.deleteCustomerFieldsFromFacilities(deletedFields)),
        tap({
          next: () => {
            this._deletedCustomerFields$.next([]);
            this._deletedCustomerFieldIds$.next([]);
            this.toasterService.success('CUSTOMERFIELDS.REMOVED');
          },
          error: () => this.toasterService.generalError('DELETE', 'CUSTOMER_FIELDS')
        })
      )
      : of(null);
    return addRequest.pipe(
      switchMap(() => updateRequest.pipe(
        switchMap(() => deleteRequest)
      ))
    );
  }
}
