/* eslint-disable @typescript-eslint/no-explicit-any */
import { StateProvider } from '@uirouter/angularjs';

import IndexTemplate from 'raw-loader!./templates/configuration-control-index.html';
import ConfigurationControlTemplate from 'raw-loader!./templates/configuration-control.html';
import TerminalControlTemplate from 'raw-loader!./templates/terminal-control.html';

import { ConfigurationControlIndexController } from './controllers/configuration-control-index-controller';
import ConfigurationControlController from './controllers/configuration-control-controller';
import { Roles } from '../admin/constants/roles';
import { EnerkeyNg1StateDeclaration, getUIState } from '../../shared/routing';

config.$inject = ['$translatePartialLoaderProvider', '$stateProvider'];

const configurationControlState: EnerkeyNg1StateDeclaration = {
  name: 'configuration-control',
  parent: 'index',
  url: '/configuration-control',
  views: {
    'app@': {
      template: IndexTemplate,
      controller: ConfigurationControlIndexController,
      controllerAs: 'vm',
    }
  },
  data: {
    auth: {
      roles: [Roles.CONFIGURATION_MANAGER]
    },
    translationKey: 'CONFIGURATION_CONTROL.TOPBAR_MENUITEM'
  },
  redirectTo: 'configuration-control.reader-groups'
};

const ccTopbarState = getUIState(configurationControlState);

export {
  ccTopbarState
};

function config($translatePartialLoaderProvider: any, $stateProvider: StateProvider): void {
  $translatePartialLoaderProvider.addPart('configuration-control');

  const configurationControlStates: { [key: string]: EnerkeyNg1StateDeclaration } = {
    'configuration-control': configurationControlState,
    'configuration-control.reader-groups': {
      url: '/reader-groups',
      views: {
        content: {
          template: ConfigurationControlTemplate,
          controller: ConfigurationControlController,
          controllerAs: 'vm',
        }
      }
    },
    'configuration-control.terminals': {
      url: '/terminals',
      views: {
        content: {
          template: TerminalControlTemplate,
        }
      }
    },
  };

  Object.keys(configurationControlStates).forEach(stateName => {
    $stateProvider.state(stateName, configurationControlStates[stateName]);
  });
}

export default config;
