import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

interface HeadElement {
  id: string;
  fileName: string;
}

export const faviconHrefs: HeadElement[] = [
  { id: 'appleIcon', fileName: 'apple-touch-icon.png' },
  { id: 'mediumIcon', fileName: 'favicon-32x32.png' },
  { id: 'smallIcon', fileName: 'favicon-16x16.png' },
  { id: 'siteManifest', fileName: 'site.webmanifest' },
  { id: 'maskIcon', fileName: 'safari-pinned-tab.svg' },
  { id: 'browserConfig', fileName: 'browserconfig.xml' },
  { id: 'favicon', fileName: 'favicon.ico' }
];

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  public constructor(
    @Inject(DOCUMENT) private readonly document: Document
  ) {
  }

  public setTenantFaviconHrefs(tenantName: string): void {
    const tenantThemePath = `/assets/themes/${tenantName}/`;
    faviconHrefs.forEach(({ id, fileName }) => {
      this.setFaviconHref(id, `${tenantThemePath}${fileName}`);
    });
  }

  private setFaviconHref(id: string, href: string): void {
    const element: HTMLLinkElement = this.document.head.querySelector(`#${id}`);
    if (element) {
      element.href = href;
    }
  }
}
