import {
  AggregateCollection,
  IReadingSet,
  TimeSerieCollection
} from '@enerkey/clients/energy-reporting';
import { StartValue } from '../../../modules/energy-reporting/services/er-time-frame.service';
import { percentageChange } from '@enerkey/ts-utils';
import { Quantities } from '@enerkey/clients/metering';

export type ConsumptionValueKey = keyof Pick<IReadingSet, 'Reading' | 'NormalizedReading'>;

export interface ValuePoint {
  relationalValues: Record<number, number>;
  value: number;
  timestamp: Date;
  incomplete?: boolean;
}

export interface QuantityChartData {
  quantityId: Quantities;
  /** Determines which relational value series are shown in chart */
  relationalValueIds?: number[];
  value: number;
  comparisonValue: number;
  relativeChange: number;
  facilityIds: number[];
  facilityCount: number;
  isNormalized: boolean;
  values: ValuePoint[][];
}

export class Consumptions {
  public static getWidgetData(
    data: AggregateCollection | TimeSerieCollection,
    quantityId: Quantities,
    valueKey: ConsumptionValueKey,
    start: StartValue[],
    relationalValueIds?: number[]
  ): QuantityChartData {
    return new Consumptions(data, quantityId, valueKey, relationalValueIds, start).chartData;
  }

  private readonly periodKeys: string[];
  private readonly chartData: QuantityChartData;

  private constructor(
    private readonly data: AggregateCollection | TimeSerieCollection,
    private readonly quantityId: Quantities,
    private readonly valueKey: ConsumptionValueKey,
    private readonly relationalValueIds: number[],
    start: StartValue[]
  ) {
    this.relationalValueIds = this.relationalValueIds ?? [];
    this.periodKeys = start.map(s => s.key).reverse();
    this.chartData = this.getWidgetData();
  }

  private getWidgetData(): QuantityChartData {
    const values = this.periodKeys.map(key => this.getPeriodValues(key));

    const relationalValuesWithData = this.relationalValueIds.filter(
      relationalValueId => values.some(
        periodValues => periodValues.some(value => Number.isFinite(value.relationalValues[relationalValueId]))
      )
    );

    const aggregatedValues = this.periodKeys.map(key => this.data.Aggregates[key][this.valueKey].Value);

    const inspectionPeriodValue = aggregatedValues[aggregatedValues.length - 1];
    const comparisonPeriodValue = aggregatedValues.length > 1 ? aggregatedValues[0] : null;

    const data: QuantityChartData = {
      quantityId: this.quantityId,
      relationalValueIds: relationalValuesWithData,
      value: inspectionPeriodValue,
      comparisonValue: comparisonPeriodValue,
      relativeChange: percentageChange(inspectionPeriodValue, comparisonPeriodValue),
      facilityIds: [],
      facilityCount: 0,
      isNormalized: this.valueKey === 'NormalizedReading',
      values: values
    };

    if (this.isAggregateCollection(this.data)) {
      data.facilityIds = this.data?.FacilitiesIncluded;
      data.facilityCount = this.data.FacilityCount + this.data.FacilitiesExcluded.length;
    }
    return data;
  }

  private getPeriodValues(periodKey: string): ValuePoint[] {
    return this.data.Values[periodKey].map(value => ({
      relationalValues: this.relationalValueIds.reduce<Record<number, number>>((values, relationalValueId) => {
        values[relationalValueId] = value.RelationalValues[relationalValueId]?.[this.valueKey]?.Value ?? null;
        return values;
      }, {}),
      value: value[this.valueKey].Value,
      timestamp: new Date(value.Timestamp as unknown as string),
      incomplete: (value[this.valueKey].Flags as string[])?.includes('Incomplete') || false
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isAggregateCollection(value: any): value is AggregateCollection {
    return value.FacilitiesIncluded || value.FaciliytCount || value.FacilitiesExcluded;
  }
}
