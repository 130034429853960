import defectIssues from '../fixtures/defect-issues.json';
import faults from '../fixtures/faults.json';
import actualReadings from '../fixtures/readings_actual.json';
import comparisonReadings from '../fixtures/readings_comparison.json';
import { ReadingConverter } from '../shared/reading-converter';
import { combineDefectIssuesAndFaults } from '../shared/utils.functions';

export function ManualQaMockService() {
  let kendo;

  this.$get = [
    'kendo',
    // eslint-disable-next-line angular/di
    function mockServiceFactory(_kendo_) {
      kendo = _kendo_;

      return {
        getDataSource: getDataSource,
        getDefectIssues: getDefectIssues,
      };
    }
  ];

  /**
   * Returns kendo data source for readings
   *
   * @returns {Object}
   */
  function getDataSource() {
    const dataSource = new kendo.data.DataSource();
    const defectIssues = getDefectIssues();
    const rawReadings = { actual: actualReadings, comparison: comparisonReadings };

    const readings = ReadingConverter.convert(rawReadings, defectIssues);

    dataSource.data(readings);

    return dataSource;
  }

  /**
   * Returns defect issues
   *
   * @returns {Array}
   */
  function getDefectIssues() {
    return combineDefectIssuesAndFaults(defectIssues, faults);
  }
}
