<kendo-grid
  [kendoGridBinding]="users$ | async"
  [loading]="loading$ | async"
  [pageSize]="50"
  [pageable]="true"
  [sortable]="true"
  [resizable]="true"
  [selectable]="gridSelectableSettings"
  [selectedKeys]="selectedUsers"
  [kendoGridSelectBy]="selectKey"
  filterable="menu"
  class="grid-full-height"
>
  <kendo-grid-excel
    [fileName]="'ADMIN.USERS' | translate | excelFileName:currentDate"
    [excelDataSource]="users$ | async"
  ></kendo-grid-excel>
  <ng-template kendoGridToolbarTemplate>
    <button kendoGridExcelCommand>{{ 'ADMIN.GET_EXCEL' | translate }}</button>
  </ng-template>
  <kendo-grid-checkbox-column
    [showSelectAll]="true"
    [locked]="true"
    [width]="28"
  >
    <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
  </kendo-grid-checkbox-column>
  <kendo-grid-column
    field="id"
    [title]="'ADMIN.ID' | translate"
    [filterable]="true"
    [locked]="true"
    [width]="55"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-command-column
    [locked]="true"
    [width]="80"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        kendoButton
        [primary]="true"
        [look]="'flat'"
        [iconClass]="'fa-1x fas fa-history'"
        [title]="'ADMIN.NOTIFICATION_LOG' | translate"
        [size]="'small'"
        (click)="openActionLog(dataItem)"
      >
      </button>
      <button
        kendoGridEditCommand
        [title]="'ADMIN.MODIFY_USERS.MODIFY_USERS_BUTTON' | translate"
        (click)="editUser(dataItem)"
      ></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column
    field="userName"
    [title]="'ADMIN.USERNAME' | translate"
    [filterable]="true"
    [locked]="true"
    [width]="210"
  ></kendo-grid-column>
  <kendo-grid-column
    field="firstName"
    [title]="'ADMIN.FIRSTNAME' | translate"
    [filterable]="true"
    [width]="150"
  ></kendo-grid-column>
  <kendo-grid-column
    field="lastName"
    [title]="'ADMIN.LASTNAME' | translate"
    [filterable]="true"
    [width]="150"
  ></kendo-grid-column>
  <kendo-grid-column
    field="lastLoginTime"
    format="d"
    [title]="'ADMIN.LASTLOGIN' | translate"
    [filterable]="true"
    [width]="90"
    filter="date"
  ></kendo-grid-column>
  <kendo-grid-column
    field="profileCount"
    [title]="'ADMIN.COUNT_PROFILE' | translate"
    [filterable]="true"
    [width]="70"
    filter="numeric"
  ></kendo-grid-column>
  <kendo-grid-column
    field="nameOfFirstProfile"
    [title]="'ADMIN.FIRST_PROFILE' | translate"
    [filterable]="true"
    [width]="400"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="tenants"
    [title]="'ADMIN.TENANTS' | translate"
    [filterable]="true"
    [width]="120"
  >
    <ng-template
      kendoGridFilterMenuTemplate
      let-column="column"
      let-filter="filter"
      let-filterService="filterService"
    >
      <grid-array-filter
        [filter]="filter"
        [field]="column.field"
        [filterService]="filterService"
        [dataSource]="tenantsDataSource$ | async"
        [rawValues]="true"
      >
        <span *gridMultiFilterTemplate="let value = value">
          {{ value }}
        </span>
      </grid-array-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="companyName"
    [title]="'ADMIN.ORGANIZATION' | translate"
    [filterable]="true"
    [width]="120"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="accountEndDate"
    format="d"
    [title]="'ADMIN.ACCOUNT_END_DATE' | translate"
    [filterable]="true"
    [width]="90"
    filter="date"
  ></kendo-grid-column>
  <kendo-grid-column
    field="thirdPartySystemName"
    [title]="'ADMIN.BULK_CREATE_THIRD_PARTY_SYSTEM_FIELD' | translate"
    [filterable]="true"
    [width]="120"
  ></kendo-grid-column>
  <kendo-grid-column
    field="preferredLanguage"
    [title]="'ADMIN.LANGUAGE' | translate"
    [filterable]="true"
    [width]="100"
  ></kendo-grid-column>
  <kendo-grid-column
    field="comment"
    [title]="'ADMIN.COMMENT' | translate"
    [filterable]="true"
    [width]="400"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [title]="dataItem.comment">{{ dataItem.comment }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngFor="let col of roleCols$ | async"
    [field]="col.field"
    [title]="col.title"
    [filterable]="true"
    [width]="65"
    filter="boolean"
  >
    <span
      *kendoGridHeaderTemplate
      [title]="col.title">
      {{ col.abbreviation }}
    </span>
    <ng-template
      kendoGridCellTemplate
      let-dataItem
      let-column="column"
    >
      <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
