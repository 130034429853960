import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, shareReplay, Subject, takeUntil } from 'rxjs';

import { AttachmentsClient, DocumentCompleteViewModel } from '@enerkey/clients/attachments';

import { REPORT_MODAL_PARAMS, ReportingModalParams } from '../components/report-modal/report-modal.component';
import { ReportType } from '../shared/report-type';
import { UserService } from '../../../services/user-service';

export enum QuantityOrMeterReport {
  Meter = 'meter',
  Quantity = 'quantity'
}

@Injectable()
export class ReportModalService implements OnDestroy {
  public readonly reportType$: Observable<ReportType>;
  public readonly meterReportType$: Observable<ReportType>;
  public readonly quantityOrMeterReport$: Observable<QuantityOrMeterReport>;
  public readonly facilityMeterMaps$: Observable<DocumentCompleteViewModel[]>;

  private readonly _reportType$ = new ReplaySubject<ReportType>(1);
  private readonly _meterReportType$ = new BehaviorSubject<ReportType>(ReportType.Period);
  private readonly _quantityOrMeterReport$ = new BehaviorSubject(QuantityOrMeterReport.Quantity);
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    attachmentsClient: AttachmentsClient,
    userService: UserService,
    @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams
  ) {
    this.reportType$ = this._reportType$.asObservable();
    this.meterReportType$ = this._meterReportType$.asObservable();
    this.quantityOrMeterReport$ = this._quantityOrMeterReport$.asObservable();

    this.facilityMeterMaps$ = attachmentsClient.getMetermap(userService.profileId, modalParams.facilityId).pipe(
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this._reportType$.next(modalParams.initialReportType);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public moveToReportOfType(reportType: ReportType): void {
    this._reportType$.next(reportType);
  }

  public moveToMeterReportOfType(reportType: ReportType): void {
    this._meterReportType$.next(reportType);
  }

  public changeMeterOrQuantityReport(reportType: QuantityOrMeterReport): void {
    this._quantityOrMeterReport$.next(reportType);
  }
}
