import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';

import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';

@Component({
  selector: 'admin-base',
  templateUrl: './admin-base.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TemplateLifterService]
})
export class AdminBaseComponent implements OnInit {
  public topRightContent: TemplateRef<unknown> = null;

  public constructor(
    private readonly templateLifter: TemplateLifterService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.templateLifter.templateChanged$.subscribe(value => {
      this.topRightContent = value;
      this.changeDetector.detectChanges();
    });
  }
}
