<ng-container *ngIf="(data$ | async) as data">
  <ng-container *ngIf="data.measured || data.normalized; else noData">
    <sum-consumptions-widget-content
      *ngIf="data.measured as measuredData"
      [data]="measuredData"
      [isNormalized]="false"
    ></sum-consumptions-widget-content>
    <sum-consumptions-widget-content
      *ngIf="data.normalized as normalizedData"
      [data]="normalizedData"
      [isNormalized]="true"
    ></sum-consumptions-widget-content>
  </ng-container>
  <ng-template #noData>
    <span>{{ 'WIDGET.NO_DATA' | translate }}</span>
  </ng-template>
</ng-container>
