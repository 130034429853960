<ng-template
  kendoGridFilterMenuTemplate
  let-column="column"
  let-filter="filter"
  let-filterService="filterService"
>
  <ng-container [ngSwitch]="property | facilityPropertyFilterType">
    <kendo-grid-numeric-filter-menu
      *ngSwitchCase="FacilityPropertyFilterType.Numeric"
      [filter]="filter"
      [column]="column"
      [filterService]="filterService"
      [operator]="'gte'"
    >
      <kendo-filter-gte-operator></kendo-filter-gte-operator>
      <kendo-filter-gt-operator></kendo-filter-gt-operator>
      <kendo-filter-lte-operator></kendo-filter-lte-operator>
      <kendo-filter-lt-operator></kendo-filter-lt-operator>
      <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
      <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
    </kendo-grid-numeric-filter-menu>

    <kendo-grid-boolean-filter-menu
      *ngSwitchCase="FacilityPropertyFilterType.Boolean"
      [filter]="filter"
      [column]="column"
      [filterService]="filterService"
    ></kendo-grid-boolean-filter-menu>

    <kendo-grid-date-filter-menu
      *ngSwitchCase="FacilityPropertyFilterType.Date"
      [filter]="filter"
      [column]="column"
      [filterService]="filterService"
      [operator]="'gte'"
    >
      <kendo-filter-after-eq-operator></kendo-filter-after-eq-operator>
      <kendo-filter-after-operator></kendo-filter-after-operator>
      <kendo-filter-before-eq-operator></kendo-filter-before-eq-operator>
      <kendo-filter-before-operator></kendo-filter-before-operator>
      <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
      <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
    </kendo-grid-date-filter-menu>

    <kendo-grid-string-filter-menu
      *ngSwitchDefault
      [column]="column"
      [filter]="filter"
      [filterService]="filterService"
      [extra]="false"
      [operator]="'contains'"
    >
      <kendo-filter-contains-operator></kendo-filter-contains-operator>
      <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
      <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
      <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
    </kendo-grid-string-filter-menu>

  </ng-container>
</ng-template>

<ng-template
  kendoGridGroupFooterTemplate
  let-column="column"
  let-aggregates
>
  <div
    *ngFor="let aggregateType of groupVisibleAggregates"
    class="cell-number"
  >
    <ng-container>
      {{ aggregates && aggregates[field]?.[aggregateType] | kendoNumber: format}}
    </ng-container>
  </div>
</ng-template>

<ng-template
  kendoGridFooterTemplate
  let-column="column"
>
  <div
    *ngFor="let aggregateType of visibleAggregates"
    class="cell-number"
  >
    <ng-container>
      {{ total && total[field]?.[aggregateType] | kendoNumber: format}}
    </ng-container>
  </div>
</ng-template>

<ng-template
  #booleanCellTemplate
  let-dataItem
  let-column="column"
>
  <boolean-mark [value]="dataItem | propertyPath:column.field"></boolean-mark>
</ng-template>
