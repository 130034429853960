import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ControlsOf } from '@enerkey/ts-utils';

import { ComboItem } from '../../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ISODuration } from '../../../../../shared/isoduration';
import { ETCurveSearchParams, PeriodDisplayType } from '../../../models/et-curve.model';

@Component({
  selector: 'et-curve-period-selection',
  templateUrl: './et-curve-period-selection.component.html',
  styleUrls: ['./et-curve-period-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EtCurvePeriodSelectionComponent implements OnInit, OnDestroy {
  private static readonly PERIODS: ISODuration[] = [
    ISODuration.P1Y,
    ISODuration.P1M,
    ISODuration.P7D,
  ];

  @Input() public toDateMax: Date = null;

  public periodOptions: ComboItem<ISODuration>[] = this.getDefaultPeriods();
  public controls: ControlsOf<ETCurveSearchParams>;
  public readonly periodDisplayTypes = PeriodDisplayType;

  private readonly _destroy = new Subject<void>();

  public constructor(
    public translateService: TranslateService,
    public readonly formGroup: FormGroupDirective
  ) {}

  public ngOnInit(): void {
    this.controls = this.formGroup.control.controls as ControlsOf<ETCurveSearchParams>;
    this.controls.resolution.valueChanges
      .pipe(
        tap(resolution => {
          const isHourlyResolution = resolution === ISODuration.PT1H;
          this.periodOptions = isHourlyResolution ? this.getHourlyPeriods() : this.getDefaultPeriods();

          if (!isHourlyResolution) {
            if (this.controls.calculationOpsHours.value) { this.controls.calculationOpsHours.setValue(false); }
            if (this.controls.opsHours.value) { this.controls.opsHours.setValue(false); }
          }
        }),
        takeUntil(this._destroy)
      ).subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  private getDefaultPeriods(): ComboItem<ISODuration>[] {
    return EtCurvePeriodSelectionComponent.PERIODS.map(value => {
      switch (value) {
        case ISODuration.P1Y:
          return { value, text: this.translateService.instant('ANALYTICS.ETCURVE.LAST_12_MONTHS') };
        case ISODuration.P1M:
          return { value, text: this.translateService.instant('ANALYTICS.ETCURVE.LAST_30_DAYS') };
        case ISODuration.P7D:
          return { value, text: this.translateService.instant('ANALYTICS.ETCURVE.LAST_WEEK') };
        default:
          return { value, text: value };
      }
    });
  }

  private getHourlyPeriods(): ComboItem<ISODuration>[] {
    return [
      { value: ISODuration.P1M, text: this.translateService.instant('ANALYTICS.ETCURVE.LAST_30_DAYS') },
      { value: ISODuration.P7D, text: this.translateService.instant('ANALYTICS.ETCURVE.LAST_WEEK') },
    ];
  }
}

