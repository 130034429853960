<div class="consumption-widget-chart-header">
  <h2>
    {{ quantityData.isNormalized ? ('FACILITIES.NORMALIZED' | translate) : '' }}
    {{ quantityData.quantityId | quantityName | async }}
  </h2>
  <span
    *ngIf="quantityData.relativeChange as change"
    [ekValueChange]="change"
    class="bold"
  >
    <i
      *ngIf="change > 0"
      class="fas fa-long-arrow-alt-up"
    ></i>
    <i
      *ngIf="change < 0"
      class="fas fa-long-arrow-alt-down"
    ></i>
  </span>
</div>
<div class="consumption-widget-chart-header secondary-consumption-info">
  <span>
    {{ quantityData.value | kendoNumber:'#,#.0' }} {{ quantityData.quantityId | quantityUnit:unitKey | async }}
  </span>
  <span class="chart-facilities">
    {{ quantityData.facilityIds?.length }}/{{ quantityData.facilityCount }}
    <i class="fa fa-home"></i>
  </span>
</div>
