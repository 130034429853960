/**
 * Default container class for mqa 'cards'
 *
 * @type {string}
 */
export const CARD_CLASS = '.card';

/**
 * Default number of digits in mqa
 *
 * @type {number}
 */
export const NUMBER_OF_DIGITS = 4;

/**
 * Alternative, more coarse number of digits in mqa
 *
 * @type {number}
 */
export const COARSE_NUMBER_OF_DIGITS = 2;

/**
 * Api url for mqa
 *
 * @type {string}
 */
export const API_URL = ENERKEY_CONFIG.apiManualQA + '/api/v1/'; // eslint-disable-line no-undef

/**
 * Api url for cc
 *
 * @type {string}
 */
export const CC_API_URL = ENERKEY_CONFIG.apiConfiguration + '/api/'; // eslint-disable-line no-undef

/**
 * Special id for searching with current profile
 *
 * @type {string}
 */
export const CURRENT_PROFILE_SELECTION = 'currentProfile';
