
import { IInesReportsClient } from './ines-reports-client';

export class InesReportsClientMock implements IInesReportsClient {
  public getHeatingPeakShavingReport(): never {
    throw new Error('Method not implemented.');
  }
  public getEleShavingKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getElectricityPeakShavingReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingCoolingReport(): never {
    throw new Error('Method not implemented.');
  }
  public getLeanheatReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingCoolingFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingCoolingFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatShavingKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatShavingFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public keyfigures(): never {
    throw new Error('Method not implemented.');
  }
  public getPowerShavingFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getLeanheatPredictionKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getLeanheatPredictionFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getEtCurveParametersAndCons(): never {
    throw new Error('Method not implemented.');
  }
  public getYearlyBenchmark(): never {
    throw new Error('Method not implemented.');
  }
  public getBenchmarkModelsAvailability(): never {
    throw new Error('Method not implemented.');
  }
  public getVentilationKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getVentilationFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getVentilationReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingPowerKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingPowerFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getHeatingPowerReport(): never {
    throw new Error('Method not implemented.');
  }
  public getDemandResponseKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getDemandResponseFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getDemandResponseReport(): never {
    throw new Error('Method not implemented.');
  }
  public getSolarPowerKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getSolarPowerFullReport(): never {
    throw new Error('Method not implemented.');
  }
  public getSolarPowerReport(): never {
    throw new Error('Method not implemented.');
  }
  public getCoolingHeatingKeyFigures(): never {
    throw new Error('Method not implemented.');
  }
  public getCoolingHeatingFullReport(): never {
    throw new Error('Method not implemented.');
  }
}
