import { NgModule } from '@angular/core';

import { ConsumptionTargetSeriesTypes } from '../modules/energy-management/services/consumption-target-series-types';
import { ErCacheService } from '../modules/energy-reporting/services/er-cache-service';
import { ManualQaInspectModalService } from '../modules/manual-qa/components/manual-qa-inspect-modal/manual-qa-inspect-modal.service';
import { LegacyFacilityService } from '../modules/reportingobjects/models/facilities';
import { AppStatusService } from '../services/app-status-service';
import { MeterInterfaceService } from '../services/meter-interface-service';
import { AjsModalService } from '../services/modal/modal.service';
import { StateLocationService } from '../services/state-location.service';

type Injector = ng.auto.IInjectorService;

function modalServiceFactory($injector: Injector): void {
  return $injector.get('modalService');
}

function stateLocationServiceFactory($injector: Injector): void {
  return $injector.get('StateLocationService');
}

function facilitiesFactory($injector: Injector): void {
  return $injector.get('facilities');
}

function meterInterfaceServiceFactory($injector: Injector): void {
  return $injector.get('MeterInterfaceService');
}

function translateFactory($injector: Injector): void {
  return $injector.get('$translate');
}

function tmhDynamicLocaleFactory($injector: Injector): void {
  return $injector.get('tmhDynamicLocale');
}

function consumptionTargetSeriesTypesFactory($injector: Injector): ConsumptionTargetSeriesTypes {
  return $injector.get('ConsumptionTargetSeriesTypes');
}

function erCacheServiceFactory($injector: Injector): void {
  return $injector.get('erCacheService');
}

function loadingServiceFactory($injector: Injector): void {
  return $injector.get('LoadingService');
}

function appStatusServiceFactory($injector: Injector): void {
  return $injector.get('appStatusService');
}

function manualQaInspectModalServiceFactory($injector: Injector): void {
  return $injector.get('ManualQaInspectModalService');
}

function actionsFactory($injector: Injector): void {
  return $injector.get('actions');
}

export function documentsServiceFactory($injector: Injector): void {
  return $injector.get('documents');
}

@NgModule({
  providers: [
    { provide: AjsModalService, useFactory: modalServiceFactory, deps: ['$injector'] },
    { provide: StateLocationService, useFactory: stateLocationServiceFactory, deps: ['$injector'] },
    { provide: LegacyFacilityService, useFactory: facilitiesFactory, deps: ['$injector'] },
    { provide: MeterInterfaceService, useFactory: meterInterfaceServiceFactory, deps: ['$injector'] },
    { provide: '$translate', useFactory: translateFactory, deps: ['$injector'] },
    { provide: 'tmhDynamicLocale', useFactory: tmhDynamicLocaleFactory, deps: ['$injector'] },
    { provide: ConsumptionTargetSeriesTypes, useFactory: consumptionTargetSeriesTypesFactory, deps: ['$injector'] },
    { provide: ErCacheService, useFactory: erCacheServiceFactory, deps: ['$injector'] },
    { provide: 'loadingServiceAjs', useFactory: loadingServiceFactory, deps: ['$injector'] },
    { provide: AppStatusService, useFactory: appStatusServiceFactory, deps: ['$injector'] },
    { provide: ManualQaInspectModalService, useFactory: manualQaInspectModalServiceFactory, deps: ['$injector'] },
    { provide: 'actions', useFactory: actionsFactory, deps: ['$injector'] },
    { provide: 'documents', useFactory: documentsServiceFactory, deps: ['$injector'] },
  ]
})
export class UpgradedProvidersModule {
}
