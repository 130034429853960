<div class="terminal-remove-meter-editor">
  <modal-view-header [headingText]="'ADMIN.TERMINAL.REMOVE_METERS_FROM_TERMINAL' | translate"></modal-view-header>
  <div class="modal-body">
    <div class="selected-meters">
      <div class="selected-meters-text">{{ 'ADMIN.TERMINAL.SELECTED_METERS_COUNT' | translate:{count:meterIds.length} }}</div>
    </div>
      <div class="terminal-remove-confirmation">{{ 'ADMIN.TERMINAL.CONFIRM_REMOVE_METERS_FROM_TERMINAL' | translate }}</div>
      <div class="terminal-name">{{ terminalName }}</div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="modal-footer-buttons float-right">
        <button type="button" class="button" (click)="save()" [disabled]="!isValid()" translate="ADMIN.SAVE"></button>
      </div>
    </div>
  </div>
</div>
