import { getCommonColumns, getCommonOptions, getDefectTypesColumn } from './manual-qa-result.functions';
import { COLUMN_AGGREGATES, COLUMN_WIDTH, footerTemplate, GRID_COLUMNS, groupHeaderTemplate } from './grid';
import { ToggleableGridColumn } from '../../constants/toggleable-grid-column';
import { InspectionItem } from '@enerkey/clients/manual-qa';
import { roundCoarse } from '../../shared/utils.functions';
import { TranslationsMap } from './manual-qa-result-service';

function getResolvedPercentage(row: any): number {
  return getPercentage(row[GRID_COLUMNS.STATISTICS_RESOLVED].sum, row[GRID_COLUMNS.STATISTICS_TOTAL].sum);
}

function getAutoResolvedPercentage(row: any): number {
  return getPercentage(row[GRID_COLUMNS.STATISTICS_AUTO_RESOLVED].sum, row[GRID_COLUMNS.STATISTICS_TOTAL].sum);
}

function getManualResolvedPercentage(row: any): number {
  return getPercentage(row[GRID_COLUMNS.STATISTICS_MANUAL_RESOLVED].sum, row[GRID_COLUMNS.STATISTICS_TOTAL].sum);
}

function getPercentage(a: number, b: number): number {
  return roundCoarse(100 * (a / b) || 0);
}

function calculatePercentualValue(valueToCompare: number | null, total: number): number {
  if (!total || !valueToCompare) {
    return 0;
  }

  return 100 * (valueToCompare / total);
}

export function getStatisticsGridOptions(translations: TranslationsMap, updateVisuals: Function): kendo.ui.GridOptions {
  const baseOptions = getCommonOptions();
  baseOptions.dataSource.group = [{ field: GRID_COLUMNS.DEFECT_TYPES }];
  baseOptions.dataSource.aggregate = COLUMN_AGGREGATES;
  baseOptions.dataSource.change = updateVisuals;
  return {
    ...baseOptions,
    columns: [...getCommonColumns(translations), ...getStatisticsColumns(translations)]
  };
}

function getStatisticsColumns(translations: TranslationsMap): ToggleableGridColumn[] {
  return [
    getDefectTypesColumn(translations),
    {
      field: GRID_COLUMNS.STATISTICS_TOTAL,
      title: translations['MQA.RESULTS.GRID.TICKETS.TOTAL'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => roundCoarse(meter.statistics.total),
      width: COLUMN_WIDTH,
      footerTemplate: footerTemplate,
      groupable: false,
      aggregates: 'sum',
      toggleable: true
    },
    {
      field: GRID_COLUMNS.STATISTICS_RESOLVED,
      title: translations['MQA.RESULTS.GRID.TICKETS.RESOLVED'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => roundCoarse(meter.statistics.resolvedTotal),
      width: COLUMN_WIDTH,
      footerTemplate: footerTemplate,
      groupable: false,
      aggregates: 'sum',
      toggleable: true
    },
    {
      field: GRID_COLUMNS.STATISTICS_AUTO_RESOLVED,
      title: translations['MQA.RESULTS.GRID.TICKETS.AUTO_RESOLVED'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => roundCoarse(meter.statistics.autoResolved),
      width: COLUMN_WIDTH,
      footerTemplate: footerTemplate,
      groupable: false,
      aggregates: 'sum',
      toggleable: true
    },
    {
      field: GRID_COLUMNS.STATISTICS_MANUAL_RESOLVED,
      title: translations['MQA.RESULTS.GRID.TICKETS.MANUAL_RESOLVED'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => roundCoarse(meter.statistics.manualResolved),
      width: COLUMN_WIDTH,
      footerTemplate: footerTemplate,
      groupable: false,
      aggregates: 'sum',
      toggleable: true
    },
    {
      field: GRID_COLUMNS.STATISTICS_OPEN,
      title: translations['MQA.RESULTS.GRID.TICKETS.OPEN'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => roundCoarse(meter.statistics.open),
      width: COLUMN_WIDTH,
      footerTemplate: footerTemplate,
      groupable: false,
      aggregates: 'sum',
      toggleable: true
    },
    {
      field: GRID_COLUMNS.STATISTICS_RESOLVED_PERCENTUAL,
      title: translations['MQA.RESULTS.GRID.TICKETS.RESOLVED_PERCENTUAL'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => {
        const percentValue = calculatePercentualValue(meter.statistics.resolvedTotal, meter.statistics.total);
        return `${roundCoarse(percentValue)} %`;
      },
      width: COLUMN_WIDTH,
      footerTemplate: (row: any) => `${getResolvedPercentage(row)} %`,
      groupable: false,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.STATISTICS_AUTO_RESOLVED_PERCENTUAL,
      title: translations['MQA.RESULTS.GRID.TICKETS.AUTO_RESOLVED_PERCENTUAL'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => {
        const percentValue = calculatePercentualValue(meter.statistics.autoResolved, meter.statistics.total);
        return `${roundCoarse(percentValue)} %`;
      },
      width: COLUMN_WIDTH,
      footerTemplate: (row: any) => `${getAutoResolvedPercentage(row)} %`,
      groupable: false,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.STATISTICS_MANUAL_RESOLVED_PERCENTUAL,
      title: translations['MQA.RESULTS.GRID.TICKETS.MANUAL_RESOLVED_PERCENTUAL'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (meter: InspectionItem) => {
        const percentValue = calculatePercentualValue(meter.statistics.manualResolved, meter.statistics.total);
        return `${roundCoarse(percentValue)} %`;
      },
      width: COLUMN_WIDTH,
      footerTemplate: (row: any) => `${getManualResolvedPercentage(row)} %`,
      groupable: false,
      toggleable: true
    }
  ];
}
