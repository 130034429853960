import { ReportSettingsSeries } from '../interfaces/report-settings';

export class ReportSettings {
  public name: string;
  public reportType: number | undefined;
  public changeType: string;
  public facilityId: number[];
  public meterId: number[];
  public quantityId: number[];
  public sections: string[];
  public sectionsConfig: any[];
  public visibleSections: string[];
  public initialSections: string[] | undefined;
  public series: ReportSettingsSeries;
  public view: any;
  public unitKey: string;
  public showMeasurement: boolean;
  public gridState: any;

  public constructor(...settingsList: Partial<ReportSettings>[]) {
    const settings = Object.assign({}, ...settingsList);

    this.name = settings.name;
    this.reportType = Number.isInteger(settings.reportType)
      ? settings.reportType
      : undefined
    ;
    this.changeType = settings.changeType || null;
    this.facilityId = settings.facilityId || [];
    this.meterId = settings.meterId || [];
    this.quantityId = settings.quantityId || [];
    this.sections = settings.sections || [];
    this.sectionsConfig = settings.sectionsConfig || null;
    this.visibleSections = settings.visibleSections || null;
    this.initialSections = settings.initialSections || null;
    this.series = settings.series
      ? { ...settings.series }
      : {};
    this.view = settings.view || {};
    this.unitKey = settings.unitKey || null;
    this.showMeasurement = typeof settings.showMeasurement === 'boolean' ? settings.showMeasurement : true;
    this.gridState = settings.gridState || {};
  }
}
