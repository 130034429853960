<modal-view-header
  [headingText]="facility?.displayName"
></modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
  [loadingOverlay]="true"
>
  <form id="facilityEdit" [formGroup]="facilityEditForm" (submit)="submit()">
    <ng-container *ngIf="facility">
      <accordion>
        <!-- 1st group : facility information -->
        <accordion-group
          [isOpen]="true"
          [heading]="'FACILITY.EDIT_FORM.FACILITYINFO' | translate"
        >
          <div *ngFor="let row of FacilityInfoFields" class="row">
            <div *ngFor="let field of row" class="large-6 columns">
              <ng-container [ngSwitch]="field.type" [formGroupName]="field.formGroupName">
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.Text">
                  <input
                    type="text"
                    [formControlName]="field.formControlName"
                    *labelBefore="field.title | translate"
                  />
                </ng-container>
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.AutoComplete">
                  <kendo-autocomplete
                    [data]="autoCompleteData[field.autoCompleteDataKey] | async"
                    [formControlName]="field.formControlName"
                    [loading]="dropdownLoading$ | async"
                    *labelBefore="field.title | translate"
                  ></kendo-autocomplete>
                </ng-container>
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.Number">
                  <kendo-numerictextbox
                    [formControlName]="field.formControlName"
                    [decimals]="0"
                    [format]="'#'"
                    [autoCorrect]="true"
                    [min]="field.min"
                    [max]="field.max"
                    *labelBefore="field.title | translate"
                  ></kendo-numerictextbox>
                </ng-container>
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.Date">
                  <kendo-datepicker
                    [formControlName]="field.formControlName"
                    *labelBefore="field.title | translate"
                  ></kendo-datepicker>
                </ng-container>
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.Dropdown">
                  <ek-combo
                    [items]="dropdownData[field.dropdownDataKey] | async"
                    [formControlName]="field.formControlName"
                    [loading]="dropdownLoading$ | async"
                    *labelBefore="field.title | translate"
                  ></ek-combo>
                </ng-container>
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.TextArea">
                  <kendo-textarea
                    [formControlName]="field.formControlName"
                    [rows]="3"
                    *labelBefore="field.title | translate"
                  ></kendo-textarea>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </accordion-group>
        <!-- 2nd group : facility properties -->
        <accordion-group
          [isOpen]="true"
          [heading]="'FACILITY.EDIT_FORM.FACILITYPROPERTIES' | translate"
        >
          <facility-property-table></facility-property-table>
        </accordion-group>
        <!-- 3rd group : facility co2 factors -->
        <accordion-group
          [isOpen]="true"
          [heading]="'FACILITY.EDIT_FORM.CO2FACTORS' | translate"
        >
          <facility-property-table
            [dataType]="FacilityDataType.CO2Factor"
          ></facility-property-table>
        </accordion-group>
        <!-- 4th group : Local Power Api for heating power -->
        <accordion-group
          [isOpen]="true"
          [heading]="'GROUPS.LOCALPOWER' | translate"
        >
          <facility-localpower
            [facilityId]="facility?.id"
          ></facility-localpower>
        </accordion-group>
        <!-- 5th group : facility technical specifications -->
        <accordion-group
          [isOpen]="true"
          [heading]="'GROUPS.TECHNICALSPECIFICATIONS' | translate"
        >
          <div *ngFor="let row of FacilityTechnicalSpecFields" class="row">
            <div *ngFor="let field of row" class="large-6 columns">
              <ng-container [ngSwitch]="field.type" [formGroupName]="field.formGroupName">
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.Text">
                  <input
                    type="text"
                    [formControlName]="field.formControlName"
                    *labelBefore="field.title | translate"
                  />
                </ng-container>
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.Number">
                  <kendo-numerictextbox
                    [formControlName]="field.formControlName"
                    [decimals]="0"
                    [format]="'#'"
                    [autoCorrect]="true"
                    [min]="field.min"
                    [max]="field.max"
                    *labelBefore="field.title | translate"
                  ></kendo-numerictextbox>
                </ng-container>
                <ng-container *ngSwitchCase="FacilityInfoFieldTypes.Dropdown">
                  <ek-combo
                    [items]="dropdownData[field.dropdownDataKey] | async"
                    [formControlName]="field.formControlName"
                    [loading]="dropdownLoading$ | async"
                    *labelBefore="field.title | translate"
                  ></ek-combo>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </accordion-group>
        <!-- 6th group : 3rd party links -->
        <accordion-group
          [isOpen]="true"
          [heading]="'GROUPS.URLS' | translate"
        >
          <facility-url-table></facility-url-table>
        </accordion-group>
        <!-- 7th group : facility customer specific fields -->
        <accordion-group
          [isOpen]="true"
          [heading]="'GROUPS.CUSTOMERFIELDS' | translate"
        >
        <facility-customer-fields-table></facility-customer-fields-table>
        </accordion-group>
      </accordion>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="facilityEdit"
      [disabled]="!facilityEditForm.valid"
      [loading]="loading$ | async"
    >
      {{ 'MODALS.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>