import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ColumnComponent } from '@progress/kendo-angular-treelist';
import { AggregateResult } from '@progress/kendo-data-query';

import { MeterTableReportService } from '../../services/meter-table-report.service';

@Component({
  selector: 'meter-table-report-aggregates',
  templateUrl: './meter-table-report-aggregates.component.html',
  styleUrls: ['./meter-table-report-aggregates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterTableReportAggregatesComponent implements OnChanges {
  @Input() public aggregates: AggregateResult;
  @Input() public column: ColumnComponent;
  @Input() public parentItem: boolean;

  public constructor(private meterTableReportService: MeterTableReportService) {}

  public ngOnChanges(): void {
    if (this.aggregates) {
      this.aggregates = this.meterTableReportService.calculateChangeAggregates(this.aggregates);
    }
  }
}
