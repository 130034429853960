import { add, subMinutes } from 'date-fns';

import { ConsumptionLike } from './reporting-series';

export function getRequestStartAndEnd(
  periodStart: Date,
  duration: Duration
): { start: Date, end: Date } {
  const start = periodStart;
  let end = add(periodStart, duration);
  const startOffset = start.getTimezoneOffset();
  const endOffset = end.getTimezoneOffset();
  if (startOffset !== endOffset && end.getHours() !== 0) {
    // Fix issue where adding duration changes timezone because of daylight saving time
    end = subMinutes(end, endOffset - startOffset);
  }

  return {
    start,
    end
  };
}

/** Calculates the aggregate incomplete or modeled value from an array of consumptions */
export function getIncompleteOrModeledAggregate(
  consumption: ConsumptionLike[],
  type: 'incomplete' | 'modeled'
): number {
  const numbers: number[] = consumption.mapFilter(
    c => c?.[type],
    c => Number.isFinite(c)
  );
  return numbers.length > 0 ? numbers.reduce((total, v) => total + v, 0) / numbers.length : null;
}
