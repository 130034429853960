<ng-container *ngIf="pageOptions$ | async as pages">
  <page-changer
    *ngIf="pages > 1"
    [pages]="pages"
    (pageChange)="pageChange($event)"
  ></page-changer>
</ng-container>
<div *ngFor="let meter of (selectedMeters$ | async).meters | slice : (skipStart$ | async) : (skipEnd$ | async)">
  <meter-report-header
    [meter]="meter"
    [events]="(notes$ | async)?.plain"
    [facility]="(facilities$ | async)?.[0]"
  ></meter-report-header>
  <div
    *ngIf="(consumptionData$ | async)?.[meter.id] as meterValues"
    [loadingSpinner]="loading$ | async"
    [loadingOverlay]="true"
  >
    <report-content
      [quantityValues]="meterValues"
      [gridsVisible]="gridsVisible$ | async"
      [chartsVisible]="chartsVisible$ | async"
      [searchParams]="searchParams$ | async"
      [notes]="notes$ | async"
      [id]="meter.id"
      [gridConfig]="(gridConfig$ | async)?.[meter.id]"
      [isMeterReport]="true"
      [reportName]="meter.name + ' (' + meter.id + ')'"
      (seriesClick)="seriesClick($event)"
    ></report-content>
  </div>
</div>
