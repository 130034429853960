import SpreadsheetCell from './spreadsheet-cell';

export default class SpreadsheetRange {
  /**
   * Class that handles spreadsheet ranges.
   *
   * @param {string} startColumn  Range start column
   * @param {number} startRow     Range start row
   * @param {string} endColumn    Range end column
   * @param {number} endRow       Range end row
   *
   * @constructor
   */
  constructor(startColumn, startRow, endColumn, endRow) {
    this.start = new SpreadsheetCell(startColumn, startRow);
    this.end = new SpreadsheetCell(endColumn, endRow);
  }

  /**
   * Overrides default toString method.
   *
   * @returns {string}
   */
  toString() {
    const startColumn = this.getStart().getColumn();
    const startRow = this.getStart().getRow();
    const endColumn = this.getEnd().getColumn();
    const endRow = this.getEnd().getRow();

    return `${startColumn}${startRow}:${endColumn}${endRow}`;
  }

  getStart() {
    return this.start;
  }

  getEnd() {
    return this.end;
  }
}
