import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'boolean-mark',
  templateUrl: './boolean-mark.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanMarkComponent {
  @Input() public value: boolean;
  @Input() public showIconOnFalse: boolean = false;
}
