import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';

import { DashboardStateService } from '../../services/dashboard-state.service';

type ChangeFn = (values: number) => void;

@Component({
  selector: 'facility-select-header',
  templateUrl: './facility-select-header.component.html',
  styleUrls: ['./facility-select-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilitySelectHeaderComponent),
    multi: true,
  }]
})
export class FacilitySelectHeaderComponent implements ControlValueAccessor, OnInit {
  public facilityIdControl: UntypedFormControl;
  public showOpenReport: boolean = true;

  private _onChange: ChangeFn;

  public constructor(private readonly dashboardStateService: DashboardStateService) {
    this.facilityIdControl = new UntypedFormControl(null, [Validators.required]);
  }

  public ngOnInit(): void {
    this.facilityIdControl.valueChanges
      .subscribe((formValue: number) => {
        this.showOpenReport = !(formValue === null);
        this._onChange?.(formValue);
      });
  }

  public openFacilityReport(): void {
    this.dashboardStateService.openFacilityReport(this.facilityIdControl.value);
  }

  public writeValue(value: number): void {
    this.facilityIdControl.setValue(value);
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

}
