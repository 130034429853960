import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { addYears, endOfYear, startOfYear, subYears } from 'date-fns';
import moment from 'moment';

import { localToUtc } from '../../../../shared/date.functions';
import ErTimeFrameService, { StartValue, TimeFrameResult } from '../../services/er-time-frame.service';
import { LanguageService } from '../../../../shared/services/language.service';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface TimeFrameQuickOptions extends TimeFrameResult {
  label: string;
}

@Component({
  selector: 'time-frame-quick-select',
  templateUrl: './time-frame-quick-select.component.html',
  styleUrls: ['./time-frame-quick-select.component.scss']
})
export class TimeFrameQuickSelectComponent implements OnInit, ControlValueAccessor {
  public value: TimeFrameQuickOptions;

  @Input() public range: StartValue[];

  public dataSource: TimeFrameQuickOptions[] = [];
  public defaultItem: TimeFrameQuickOptions;
  public readonly textField: keyof Pick<TimeFrameQuickOptions, 'label'> = 'label';

  private _onChange: (value: any) => void;
  private _onTouch: () => void;

  public constructor(
    private readonly erTimeFrameService: ErTimeFrameService,
    private readonly datePipe: DatePipe,
    private readonly translateService: TranslateService,
    private readonly langService: LanguageService
  ) { }

  public writeValue(newValue: any): void {
    if (!newValue) {
      this.value = null;
    } else {
      const newDateTrimmed = this.trimStartDate(newValue);
      const selectedNewValue = this.dataSource.find(
        item => {
          const itemDateTrimmed = this.trimStartDate(item);
          return item.TimeFrame === newValue.TimeFrame && itemDateTrimmed === newDateTrimmed;
        }
      );
      this.value = selectedNewValue;
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  public ngOnInit(): void {
    this.setDataSourceOptions();
  }

  public touched(): void {
    if (this._onTouch) {
      this._onTouch();
    }
  }

  public valueChanged(item: TimeFrameQuickOptions): void {
    this.value = item;
    this._onChange(item);
  }

  private trimStartDate(value: TimeFrameResult): string {
    const newStartValue = value.Start[0].value;
    return newStartValue.substr(0, newStartValue.indexOf('T'));
  }

  private setDataSourceOptions(): void {
    const startDate = moment(this.range[1].value).subtract(2, 'year');
    const endDate = this.getRangeEndDate();

    const yearOptions = this.erTimeFrameService.getStartFromResolution('P1Y', startDate, moment(endDate));
    const monthOptions = this.erTimeFrameService.getStartFromResolution('P1M', startDate, moment(endDate));

    const yearSelectOptions: TimeFrameQuickOptions[] = yearOptions.Start.reverse().map(
      start => this.getYearOption(start.value)
    );

    const monthSelectOptions: TimeFrameQuickOptions[] = monthOptions.Start.reverse().map(start => ({
      Start: [start],
      label: this.getMonthLabel(start.value),
      TimeFrame: 'P1M',
      Resolution: 'PT1H'
    }));

    this.defaultItem = yearSelectOptions.shift();
    this.dataSource = [...yearSelectOptions, ...monthSelectOptions];
  }

  private getRangeEndDate(): Date {
    const endDate = endOfYear(new Date(this.range[0].value));
    const now = new Date();

    // Dev (and test) use always year 2015
    if (endDate.getFullYear() === 2015) {
      return endDate;
    }

    return endDate > now ? endDate : now;
  }

  private getMonthLabel(value: string): string {
    const date = new Date(value);
    const monthName = this.datePipe.transform(date, 'LLLL', undefined, this.langService.getLocale());
    const year = date.getFullYear();
    return `${monthName} ${year}`;
  }

  private getYearOption(dateValue: string): TimeFrameQuickOptions {
    const date = new Date(dateValue);
    const dateEnd = localToUtc(startOfYear(addYears(date, 1)));
    const dateStart = localToUtc(startOfYear(subYears(date, 1)));
    const start = this.erTimeFrameService.getStartFromResolution('P1Y', moment(dateStart), moment(dateEnd));
    const yearTranslated = this.translateService.instant('YEAR');
    return {
      TimeFrame: 'P1Y',
      Resolution: 'P1M',
      Start: start.Start,
      label: `${yearTranslated} ${date.getFullYear()}`
    };
  }
}
