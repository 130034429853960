import ManualQaMeterHistoryModule from '../manual-qa-meter-history';
import ManualQaActionsOperationComponent from './manual-qa-actions-operation-component';
import ManualQaActionsOperationService from './manual-qa-actions-operation-service';

const dependencies = [ManualQaMeterHistoryModule.name];

const ManualQaActionsOperationModule = angular
  .module('app.modules.manual-qa.components.manual-qa-actions-operation', dependencies)
  .component('manualQaActionsOperation', ManualQaActionsOperationComponent)
  .service('ManualQaActionsOperationService', ManualQaActionsOperationService)
;

export default ManualQaActionsOperationModule;
