import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { ModalService } from '@enerkey/foundation-angular';
import { Roadmap, SustainabilityClient } from '@enerkey/clients/sustainability';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';
import { TargetEditModalComponent } from '../target-edit-modal/target-edit-modal.component';
import { TargetStatusEditModalComponent } from '../target-status-edit-modal/target-status-edit-modal.component';
import { ProfileService } from '../../../../shared/services/profile.service';
import { TargetRoadmapModalComponent } from '../target-roadmap-modal/target-roadmap-modal.component';

@Component({
  selector: 'targets-grid',
  templateUrl: './targets-grid.component.html',
  styleUrls: ['./targets-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetsGridComponent implements AfterViewInit, OnDestroy {

  public roadmaps$: Observable<Roadmap[]>;
  public targetValueFormat = '# tCO₂e';
  public percentageFormat = '+# %;-# %;-# %';

  public readonly loading$: Observable<boolean>;

  private readonly _refresh$ = new BehaviorSubject<void>(null);
  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  @ViewChild('topbarTemplate')
  private readonly topRightTemplate: TemplateRef<unknown>;

  public constructor(
    private readonly modalService: ModalService,
    private readonly profileService: ProfileService,
    private readonly susClient: SustainabilityClient,
    private readonly templateLifter: TemplateLifterService
  ) {
    this.loading$ = this._loading$.asObservable();
    this.roadmaps$ = combineLatest([
      this.profileService.profileId$,
      this._refresh$
    ]).pipe(
      switchMap(([id]) => this.susClient.getRoadmaps(id).pipe(
        indicate(this._loading$),
        map(roadmaps => roadmaps.sortByMany('baseYear', 'targetYear'))
      )),
      takeUntil(this._destroy$)
    );
  }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._refresh$.complete();
  }

  public openRoadmapEditModal(roadmap?: Roadmap): void {
    const modal = this.modalService.open(TargetEditModalComponent);
    modal.componentInstance.existingRoadmap = roadmap;
    modal.result.then(
      () => this._refresh$.next(),
      /* istanbul ignore next */
      () => {}
    );
  }

  public openCurrentEditModal(roadmap: Roadmap): void {
    const modal = this.modalService.open(TargetStatusEditModalComponent);
    modal.componentInstance.roadmap = roadmap;
    modal.result.then(
      () => this._refresh$.next(),
      /* istanbul ignore next */
      () => {}
    );
  }

  public openRoadmapVisualizationModal(roadmap: Roadmap): void {
    const modal = this.modalService.open(TargetRoadmapModalComponent);
    modal.componentInstance.roadmap = roadmap;
  }
}
