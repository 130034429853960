/**
 * Courtesy of http://stackoverflow.com/questions/17547917/angularjs-image-onload-event
 */
onImgLoad.$inject = ['$parse'];

function onImgLoad($parse) {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      var fn = $parse(attrs.onImgLoad);
      elem.on('load', function (event) {
        scope.$evalAsync(function() {
          fn(scope, { $event: event });
        });
      });
    }
  };
}

export default onImgLoad;
