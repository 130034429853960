import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

import { StringTypeKey } from '@enerkey/ts-utils';

/* eslint-disable @typescript-eslint/no-explicit-any */

type FilterDescriptorItem = CompositeFilterDescriptor | FilterDescriptor;

//#region Kendo Angular

function isCompositeFilterDescriptor(param: FilterDescriptorItem): param is CompositeFilterDescriptor {
  return !!(param as any).filters;
}

export function flattenKendoFilter(filter: CompositeFilterDescriptor): FilterDescriptor[] {
  const filters = filter ? filter.filters : null;
  if (filters) {
    return filters.reduce(
      (acc: FilterDescriptor[], curr: FilterDescriptorItem): FilterDescriptor[] =>
        acc.concat(
          isCompositeFilterDescriptor(curr) ?
            flattenKendoFilter(curr) :
            [curr]
        ),
      []
    );
  }
  return [];
}

//#endregion Kendo Angular

//#region Kendo JQuery

/**
 * Remove extra junk that kendo.ui.Spreadsheet leaves to `body` even after calling `destroy()`.
 */
export function removeSpreadsheetOrphans(): void {
  // Non-destroyed kendo elements are left as direct children of body.
  jQuery('body').children('[class*="k-spreadsheet-"]').remove();
}

export function integerFilterUi(element: JQuery): void {
  element.kendoNumericTextBox({
    format: '#',
    decimals: 0,
    restrictDecimals: true
  });
}

export function selectOrUnselectGroupItems(
  event: Event,
  dataItem: kendo.data.DataSourceGroup
): void {
  const checked = (event.target as HTMLInputElement).checked;
  changeSelectionStatus(checked, dataItem.items);
}

function changeSelectionStatus(checked: boolean, items: unknown[]): void {
  items.forEach((item: any) => {
    if (item.items) {
      changeSelectionStatus(checked, item.items);
    } else {
      item.selected = checked;
    }
  });
}

interface CustomSpreadsheetEditorOptions<T> {
  editorName: string;
  data: T[];
  valueField: StringTypeKey<T>;
  textField: StringTypeKey<T>;
}

export function registerCustomSpreadsheetEditor<T>(editorOptions: CustomSpreadsheetEditorOptions<T>): void {
  (kendo.spreadsheet as any).registerEditor(editorOptions.editorName, () => {
    let context: any;
    let dialog: kendo.ui.Popup;
    let select: kendo.ui.DropDownList;

    function create(): void {
      if (!dialog) {
        select = $('<input style="width: 250px;"/>').kendoDropDownList({
          dataSource: editorOptions.data.sortBy(editorOptions.textField),
          dataTextField: editorOptions.textField,
          dataValueField: editorOptions.valueField,
          valuePrimitive: true,
          height: 500,
          select: event => {
            context.callback(event.dataItem[editorOptions.valueField]);
          }
        }).data('kendoDropDownList');
        dialog = select.popup;
      }
    }

    function open(): void {
      create();
      const value = context.range.value();
      if (value !== null) {
        select.value(value);
      }
      dialog.setOptions({
        anchor: $('.k-spreadsheet-editor-button')
      });
      dialog.open();
    }

    return {
      edit: function(options: any) {
        context = options;
        open();
      },
      icon: 'k-i-collapse'
    };
  });
}

//#endregion Kendo JQuery
