import { Inject, Injectable, OnDestroy } from '@angular/core';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';
import { subYears } from 'date-fns';

import {
  FacilityConsumptionRequest,
  FacilityResponse,
  ReportingClient,
  RequestDuration,
} from '@enerkey/clients/reporting';
import { Quantities } from '@enerkey/clients/metering';
import { percentageChange } from '@enerkey/ts-utils';
import { ObservablePool } from '@enerkey/rxjs';

import { ErUtils } from '../interfaces/er-utils';
import { getLatestReportingMonthNumeric, localToUtc } from '../../../shared/date.functions';
import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';
import { ToasterService } from '../../../shared/services/toaster.service';
import { durationToString } from '../../reporting/shared/duration-to-string';

export const simpleReportKeys = {
  value: 'value',
  change: 'change',
  isIncomplete: 'isIncomplete',
  facilityName: 'facilityName',
  values: 'values'
} as const;

export const simpleReportQuantityIds = [Quantities.Electricity, Quantities.DistrictHeating, Quantities.Water] as const;
export type SimpleTableReportQuantity = typeof simpleReportQuantityIds[number];

interface QuantityValue {
  [simpleReportKeys.value]: number;
  [simpleReportKeys.change]: number;
  [simpleReportKeys.isIncomplete]: boolean;
}

export interface ReportRow {
  facilityId: number;
  [simpleReportKeys.facilityName]: string;
  [simpleReportKeys.values]: Record<SimpleTableReportQuantity, QuantityValue>;
  realEstateId: string;
}

export interface SimpleReportTimeframeOption {
  timeFrame: RequestDuration;
  year: number;
  month: number;
  text: string;
}

type QuantityRequests = Record<SimpleTableReportQuantity, Observable<FacilityResponse[]>>;
type QuantityResponses = Record<SimpleTableReportQuantity, FacilityResponse[]>;

@Injectable()
export class SimpleTableReportService implements OnDestroy {
  private readonly reportCache = new Map<string, Observable<ReportRow[]>>();

  private readonly _destroy$ = new Subject<void>();
  private readonly _pool = new ObservablePool(2);

  public constructor(
    private readonly reportingClient: ReportingClient,
    private readonly toasterService: ToasterService,
    @Inject('erUtils') private readonly erUtils: ErUtils
  ) { }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._pool.destroy();
  }

  public getTimeFrameSelections(): Observable<SimpleReportTimeframeOption[]> {
    return from(this.erUtils.getDefaultTimePeriod()).pipe(
      map(currentPeriod => {
        const date = new Date(currentPeriod[0].value);
        const year = date.getFullYear();
        const currentMonth = getLatestReportingMonthNumeric(year);
        const yearDuration = new RequestDuration({ months: currentMonth + 1 });
        return [
          {
            timeFrame: yearDuration,
            text: durationToString(new Date(year, 0, 1), yearDuration, 0),
            month: 0,
            year: year
          },
          {
            timeFrame: new RequestDuration({ months: 1 }),
            text: `${this.erUtils.getLatestMonth(year)} ${year}`,
            month: currentMonth,
            year: year
          }
        ];
      })
    );
  }

  public getConsumptions(
    timeFrameSelection: SimpleReportTimeframeOption,
    facilities: ExtendedFacilityInformation[]
  ): Observable<ReportRow[]> {
    return this.reportCache.getOrAdd(timeFrameSelection.text, () => {
      const { year, month } = timeFrameSelection;
      const inspectionPeriod = new Date(year, month, 1);
      const comparisonPeriod = subYears(inspectionPeriod, 1);

      const facilityIds = facilities.map(f => f.facilityId);

      const requests: QuantityRequests = simpleReportQuantityIds.toRecord(
        quantityId => quantityId,
        quantityId => forkJoin([comparisonPeriod, inspectionPeriod].map(
          period => {
            const request$ = this.reportingClient.getConsumptionsForFacilities(new FacilityConsumptionRequest({
              facilityIds,
              quantityId,
              start: localToUtc(period),
              duration: timeFrameSelection.timeFrame,
            })).pipe(
              takeUntil(this._destroy$),
              catchError(() => {
                this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
                this.reportCache.delete(timeFrameSelection.text);
                return of(new FacilityResponse());
              })
            );

            return facilityIds.length > 500
              ? this._pool.queue(request$)
              : request$;
          }
        ))
      );

      return forkJoin(requests).pipe(
        map(consumptions => this.transformConsumptionsToGrid(consumptions, facilities)),
        shareReplay(1),
        takeUntil(this._destroy$)
      );
    });
  }

  private transformConsumptionsToGrid(
    consumptions: QuantityResponses,
    facilities: ExtendedFacilityInformation[]
  ): ReportRow[] {
    return facilities.map(facility => ({
      facilityName: facility.name,
      facilityId: facility.facilityId,
      realEstateId: facility.FacilityInformation.RealEstateId,
      values: simpleReportQuantityIds.toRecord(
        quantityId => quantityId,
        quantityId => this.getRowValue(quantityId, consumptions, facility.facilityId)
      )
    }));
  }

  private getRowValue(
    quantityId: SimpleTableReportQuantity,
    consumptions: QuantityResponses,
    facilityId: number
  ): QuantityValue {
    const quantityValues = consumptions[quantityId];

    if (quantityValues.every(x => !x.consumptions?.[facilityId])) {
      return this.getEmptyRowValue();
    }

    const isComparable = quantityValues.every(x => x.consumptions?.[facilityId]?.incomplete === 0);
    const isIncomplete = quantityValues[1].consumptions?.[facilityId]?.incomplete > 0;
    const comparisonPeriodValue = quantityValues[0].consumptions?.[facilityId]?.values?.[0]?.value;
    const inspectionPeriodValue = quantityValues[1].consumptions?.[facilityId]?.values?.[0]?.value;

    return {
      value: inspectionPeriodValue,
      change: isComparable
        ? percentageChange(inspectionPeriodValue, comparisonPeriodValue)
        : null,
      isIncomplete: isIncomplete
    };
  }

  private getEmptyRowValue(): QuantityValue {
    return {
      value: null,
      change: null,
      isIncomplete: false
    };
  }
}
