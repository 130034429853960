import { ActionType, ActionViewModel } from '@enerkey/clients/attachments';

export const ACTION_TYPE_IDS = [
  ActionType.ES,
  ActionType.MI,
  ActionType.YM,
  ActionType.O,
  ActionType.KAT
];

export const COMMENT_TYPE_IDS = [
  ActionType.K,
  ActionType.KE
];

export function getNewActionDefaults(actionType?: ActionType): Partial<ActionViewModel> & { new: boolean } {
  return {
    new: true,
    actionType: actionType ?? ActionType.ES,
    executionPhase: 0,
    actionGroup: null,
    actionBasis: null,
    actionClass: null,
    investmentSupport: null,
    investigation: 0
  };
}
