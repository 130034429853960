import templateModule from 'raw-loader!../templates/mrc-meter-history.html';

export function mrcMeterHistory() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      facility: "=",
      meter: "=",
      mrcState: "=",
      refresh: "="
    },
    controller: [
      '$scope', 'mrcapi', 'utils', 'mrcModals', 'MrcFilterService', 'MrcConstants',
      function ($scope, mrcapi, utils, mrcModals, MrcFilterService, MrcConstants) {
        $scope.MrcConstants = MrcConstants;
        $scope.loadingHistory = false;
        $scope.historyItems = [];
        var loadedHistoryItems = [];

        var initialPagingFilter = new Date();
        $scope.paging = {
          selected: initialPagingFilter.getFullYear(),
          pages: [],
          filter: filterItemsForView
        };

        $scope.currentItem = {
          Readings: [],
          Factor: angular.copy($scope.meter.Factor)
        };

        function filterItemsForView() {
          if ($scope.paging.pages.length > 0) {
            var pageItems = [];
            _.each(angular.copy(loadedHistoryItems), function (item) {
              var itemDate = new Date(item.Date);
              if (itemDate.getFullYear() === $scope.paging.selected) {
                pageItems.push(item);
              }
            });

            $scope.historyItems = pageItems;
          } else {
            $scope.historyItems = loadedHistoryItems;
          }
        }

        function initFilters() {
          if (loadedHistoryItems.length > 0) {
            $scope.paging.pages = MrcFilterService.getSortedYearsArray(loadedHistoryItems);
          }

          if ($scope.paging.pages.length > 0) {
            $scope.paging.selected = angular.copy($scope.paging.pages[$scope.paging.pages.length - 1]);
          }

          filterItemsForView();
        }

        function getHistory(options) {
          mrcapi.getMeterHistory(options).then(
            function (res) {
              loadedHistoryItems = _.sortByOrder(res, ['Date'], ['desc']);
              loadedHistoryItems.forEach(function(item) {
                item.newFactor = angular.copy(item.Factor);
              });
              initFilters();
            },
            function (err) {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_METER_HISTORY', true);
            }
          ).finally(function() {
            $scope.loadingHistory = false;
          });
        }

        function init() {
          var options = {
            reportingObjectId: $scope.facility.FacilityId,
            meterId: $scope.meter.Id
          };
          $scope.loadingHistory = true;
          $scope.currentItem.Readings = [];
          $scope.currentItem.Factor = angular.copy($scope.meter.Factor);
          if($scope.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING) {
            //First get latest initial readings for current status
            mrcapi.getMeterReadings($scope.facility.FacilityId, $scope.meter.Id).then(
              function (res) {
                if(_.isArray(res) && res.length > 0) {
                  var sorted = _.sortByOrder(res, ['Date', 'Flags', 'Type'], ['desc', 'asc', 'asc']);
                  var latestIdx = _.findIndex(sorted, function(item) {
                    return item.Flags === 1 || item.Flags === 2;
                  });
                  if(latestIdx >= 0 && sorted[latestIdx].Flags === 1) {
                    $scope.currentItem.Readings.push(sorted[latestIdx]);
                    latestIdx += 1;
                    if($scope.meter.TwoTimeMeasurement && (sorted.length > latestIdx && sorted[latestIdx].Type === 2)) {
                      $scope.currentItem.Readings.push(sorted[latestIdx]);
                    }
                  }
                }
              },
              function () {
                utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_LOADING_METER_READINGS', true);
              }
            ).finally(function() {
              getHistory(options);
            });
          }
          else {
            getHistory(options);
          }
        }

        init();

        $scope.$on('meterDataUpdated', function () {
          init();
        });

        $scope.changeCurrentFactor = function(newValue) {
          mrcModals.showConfirmationModal('MRC.READINGS.CONFIRM_FACTOR_CHANGE').then(
            function () {
              var options = {
                reportingObjectId: $scope.facility.FacilityId,
                meterId: $scope.meter.Id,
                newFactor: newValue
              };

              mrcapi.changeFactor(options).then(
                function (res) {
                  $scope.refresh();
                },
                function (err) {
                  utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER_FACTOR', true);
                }
              );
            },
            function () {
              $scope.currentItem.Factor = angular.copy($scope.meter.Factor);
            }
          );
        }

        $scope.save = function (item) {
          mrcModals.showConfirmationModal('MRC.READINGS.CONFIRM_FACTOR_CHANGE').then(
            function () {
              var options = {
                reportingObjectId: $scope.facility.FacilityId,
                meterId: $scope.meter.Id,
                newFactor: item.newFactor,
                Date: item.Date
              };
              mrcapi.changeFactorInHistory(options).then(
                function (res) {
                  init();
                },
                function (err) {
                  utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER_FACTOR', true);
                }
              );
            },
            function () {
              item.newFactor = angular.copy(item.Factor);
            }
          );
        };

      }
    ]
  };
}
