import { registerLocaleData } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CldrIntlService } from '@progress/kendo-angular-intl';
import { catchError, tap } from 'rxjs/operators';
import localeFi from '@angular/common/locales/fi';
import { firstValueFrom, forkJoin, Observable, Subject, throwError } from 'rxjs';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { StateService } from '@uirouter/angular';

import { ofVoid, prepare } from '@enerkey/rxjs';
import { UserManagementClient } from '@enerkey/clients/user-management';

import { LanguageService, Locale, LocaleInfo } from './language.service';

export const defaultTranslationLocale: Locale = 'en-GB';

declare const require: NodeRequire;

type TMHDynamicLocale = { set(locale: string): Promise<void> };

type AngularJsTranslateService = {
  preferredLanguage(): Locale;
  storage(): { get(lang: 'lang'): Locale };
  use(locale: Locale): Promise<void>;
};

@Injectable({ providedIn: 'root' })
export class LanguageChangeService {

  public readonly languageChange: Observable<void>;
  public readonly languageChangeStart: Observable<void>;

  private readonly _languageChange$ = new Subject<void>();
  private readonly _languageChangeStart$ = new Subject<void>();

  public constructor(
    @Inject('$translate') private readonly ajsTranslateService: AngularJsTranslateService,
    @Inject('tmhDynamicLocale') private readonly tmhDynamicLocale: TMHDynamicLocale,
    private readonly translateService: TranslateService,
    private readonly intlService: CldrIntlService,
    private readonly stateService: StateService,
    private readonly userManagementClient: UserManagementClient,
    private readonly languageService: LanguageService
  ) {
    const defaultAngularLocale: Locale = 'fi-FI';
    registerLocaleData(localeFi, defaultAngularLocale);

    this.languageChange = this._languageChange$.asObservable();
    this.languageChangeStart = this._languageChangeStart$.asObservable();
  }

  public changeLocale(locale: Locale): Promise<unknown> {
    return firstValueFrom(forkJoin([
      this.userManagementClient.changeSignedInUserLanguage(locale).pipe(catchError(() => ofVoid())),
      this.getLocaleChangeObservable(locale)
    ]).pipe(
      prepare(() => {
        this._languageChangeStart$.next();
      }),
      tap(() => {
        this._languageChange$.next();
        this.stateService.reload();
      })
    ));
  }

  public async setLocaleInitial(): Promise<void> {
    this.translateService.setDefaultLang(defaultTranslationLocale);
    const localStorageLocale = this.ajsTranslateService.storage().get('lang');
    const initialLocale: Locale = LanguageService.locales.includes(localStorageLocale)
      ? localStorageLocale
      : this.ajsTranslateService.preferredLanguage()
      ;

    return firstValueFrom(this.getLocaleChangeObservable(initialLocale)).then();
  }

  private getLocaleChangeObservable(locale: Locale): Observable<unknown> {
    const currentLocale = this.getLocaleInfo(locale);
    if (!currentLocale) {
      return throwError(() => new Error(`Couldn't find locale info for "${locale}"`));
    }
    this.languageService.setLocale(locale);
    this.changeMomentLocale(locale);
    return forkJoin([
      this.ajsTranslateService.use(locale) as Promise<void>,
      this.tmhDynamicLocale.set(locale.toLowerCase()) as Promise<void>,
      this.importAngularLocaleData(locale, currentLocale.angularLocale),
      this.translateService.use(locale),
      this.importKendoJqueryLocales(locale),
      this.importKendoJqueryTranslations(currentLocale.kendoJqueryTranslations),
      this.importKendoAngularLocalizations(locale, currentLocale.kendoAngularLocale),
    ]);
  }

  private getLocaleInfo(locale: Locale): LocaleInfo {
    return LanguageService.localeMap[locale];
  }

  private changeMomentLocale(locale: Locale): void {
    moment.locale(locale);
  }

  private async importAngularLocaleData(locale: Locale, angularLocale: string): Promise<void> {
    const localeToImport = angularLocale;

    // TODO: Fix path when https://github.com/angular/angular-cli/issues/22088 is fixed
    return import(
      /* webpackInclude: /(nb|sv|fi|en-GB|de|da)\.mjs$/ */
      `node_modules/@angular/common/locales/${localeToImport}`
    ).then(importedLocale => {
      registerLocaleData(importedLocale.default, locale);
    });
  }

  private async importKendoAngularLocalizations(locale: Locale, kendoAngularLocale: string): Promise<void> {
    const localeToImport = kendoAngularLocale;
    return import(
      /*
        Accept both backslash and forward slash when path contains slashes.
        If only forward slash is allowed build may not work correctly on Windows.
       */
      /* webpackInclude: /(nb|sv|fi|en-GB|de|da)[\/\\]all\.js$/ */
      `@progress/kendo-angular-intl/locales/${localeToImport}/all`
    ).then(() => {
      this.intlService.localeId = locale;
    });
  }

  private async importKendoJqueryLocales(locale: Locale): Promise<void> {
    const localeToImport = this.getLocaleInfo(locale).kendoJqueryLocale;

    return import(
      /* webpackInclude: /(nb-NO|sv-SE|fi-FI|en-GB|de-DE|da-DK)\.js$/ */
      `@progress/kendo-ui/js/cultures/kendo.culture.${localeToImport}`
    ).then(() => {
      this.modifyCulture(localeToImport);
      kendo.culture(localeToImport);
    });
  }

  private async importKendoJqueryTranslations(kendoJqueryTranslations: string): Promise<void> {
    const localeToImport = kendoJqueryTranslations;
    /*
      Delete messages file from cache to force reloading it,
      otherwise language will update only first time when it is loaded
    */
    delete require.cache[require.resolve(`@progress/kendo-ui/js/messages/kendo.messages.${localeToImport}`)];
    return import(
      /* webpackInclude: /(nb-NO|sv-SE|fi-FI|en-GB|de-DE|da-DK)\.js$/ */
      `@progress/kendo-ui/js/messages/kendo.messages.${localeToImport}`
    );
  }

  // Use finnish number and calendar formats in all other locales but keep locales original decimal separator
  private modifyCulture(locale: string): void {
    const finnishCulture = kendo.cultures['fi-FI'];
    const cultureToModify = kendo.cultures[locale];
    const originalDecimalSeparator = cultureToModify.numberFormat['.'];
    cultureToModify.numberFormat = cloneDeep(finnishCulture.numberFormat);
    cultureToModify.numberFormat['.'] = originalDecimalSeparator;
    cultureToModify.calendars.standard.patterns = finnishCulture.calendars.standard.patterns;
  }
}
