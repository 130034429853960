import _ from 'lodash';

errorCodes.$inject = ['$q', '$http', '$timeout', 'httpConfigService', 'languageChangeService'];

function errorCodes($q, $http, $timeout, httpConfigService, languageChangeService) {

  var errorCodesDeferred;

  // watch for language change start
  languageChangeService.languageChangeStart.subscribe(() => {
    if (errorCodesDeferred) {
      errorCodesDeferred.resolve([]);
    }
    errorCodesDeferred = undefined;
  });

  return {

    getErrorCode: function(code) {
      var deferred = $q.defer();

      this.getErrorCodes().then(
        function(result) {
          var errorCode = _.find(errorCodes, function(errorCode) {
            return errorCode.Code === code;
          });
          deferred.resolve(errorCode);
        },
        function() {
          deferred.reject();
        }
      );
      
      return deferred.promise;
    },

    getErrorCodes: function() {
      if (!errorCodesDeferred) {
        errorCodesDeferred = $q.defer();
        $http.get(ENERKEY_CONFIG.apiEnergyreporting + '/api/v1/configuration/errorCodes', httpConfigService.getExtendedHttpConfig())
          .then(function({ data }) {
            if (errorCodesDeferred) {
              errorCodesDeferred.resolve(data.ErrorCodes);
            }
          })
          .catch(function({ status }) {
            if (errorCodesDeferred) {
              errorCodesDeferred.reject(status);
              $timeout(function() {
                errorCodesDeferred = undefined;
              });
            }
          })
        ;
      }
      return errorCodesDeferred.promise;
    }

  };
}

export default errorCodes;
