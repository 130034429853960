<ng-container *ngIf="contentAfter">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-content></ng-content>
<ng-container *ngIf="!contentAfter">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>

<ng-template #content>
  <ng-container *ngIf="!isAbsoluteChange">
    {{ ekValueChange | kendoNumber:'#.0 %' }}
  </ng-container>
  <ng-container *ngIf="isAbsoluteChange">
    {{ ekValueChange | kendoNumber:'#,#' }}
  </ng-container>
</ng-template>
