import { GridReportActionsPopupController } from './grid-report-actions-popup.controller';
import gridReportActionsPopupTemplate from 'raw-loader!./grid-report-actions-popup.html';

export const gridReportActionsPopupComponent = {
  template: gridReportActionsPopupTemplate,
  controller: GridReportActionsPopupController,
  controllerAs: 'vm',
  bindings: {
    row: '<',
    type: '<',
    notes: '<'
  }
};
