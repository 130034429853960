import moment, { Moment } from 'moment';
import TimeFrame, { TimeFrameString } from '../../../services/time-frame-service';

export class ComparisonStartDate {
  public get value(): Moment {
    return this._value;
  }
  public set value(date: Moment) {
    this.setProperties(date);
  }
  public get valueDate(): Date {
    return this._value ? this._value.toDate() : null;
  }
  public set valueDate(date: Date) { // Do not use! Only for kendo date picker
    const momentDate = this.adjustDate(date);
    this.setProperties(momentDate);
  }
  public get to(): Moment {
    return this._to;
  }
  public get key(): string {
    return this.value && this.value.valueOf() !== this.to.valueOf()
      ? `${this.fromKey} - ${this.toKey}`
      : this.fromKey;
  }
  public get fromKey(): string {
    return this._fromKey;
  }
  public get toKey(): string {
    return this._toKey;
  }
  public get formatted(): string {
    return this._formatted;
  }

  private _value: Moment;
  private _to: Moment;
  private _fromKey: string;
  private _toKey: string;
  private _formatted: string;

  private timeFrameAbbr: TimeFrameString;
  private resolutionAbbr: string;

  public constructor(date: Moment | Date | string, timeFrameAbbr: TimeFrameString, resolutionAbbr: string) {
    this.timeFrameAbbr = timeFrameAbbr;
    this.resolutionAbbr = resolutionAbbr;
    if (date) {
      this.setProperties(date);
    } else {
      this._value = null;
      this._to = null;
      this._fromKey = '';
      this._toKey = '';
      this._formatted = '';
    }
  }

  private adjustDate(date: Date): Moment {
    const momentDate = moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]);
    const duration = moment.fromIsodurationCached(this.resolutionAbbr);
    if (duration.months()) {
      momentDate.date(1);
    } else if (duration.years()) {
      momentDate.month(0);
      momentDate.date(1);
    }
    return momentDate;
  }

  private setProperties(date?: Moment | Date | string): void {
    const timeFrame = TimeFrame.parse({
      fromDate: date,
      durationTo: this.timeFrameAbbr
    });
    timeFrame.fromDate.utc();
    timeFrame.toDate.utc();
    this._value = timeFrame.fromDate;
    this._to = timeFrame.toDate;
    this._fromKey = timeFrame.fromDate.format('D.M.Y');
    this._toKey = timeFrame.toDate.clone().subtract(1, 'seconds').format('D.M.Y');
    this._formatted = TimeFrame.timeRangeFormat(timeFrame.fromDate, this.timeFrameAbbr, this.resolutionAbbr);
  }
}
