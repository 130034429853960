import ManualQaChangeTimeFramePresetsComponent from './manual-qa-change-time-frame-presets-component';

const dependencies = [];

const ManualQaChangeTimeFramePresetsModule = angular
  .module('app.modules.manual-qa.components.manual-qa-change-time-frame-presets', dependencies)
  .component('manualQaChangeTimeFramePreset', ManualQaChangeTimeFramePresetsComponent)
;

export default ManualQaChangeTimeFramePresetsModule;
