import _ from 'lodash';

const $inject = ['$filter', 'utils'];

class EmFacilitySelectController {
  constructor($filter, utils) {
    this.$filter = $filter;
    this.utils = utils;

    this.selectedFacilities = [];

    this.localizedFacilityMultiSelectTexts = _.clone(utils.istevenMultiSelectTranslation());
    this.localizedFacilityMultiSelectTexts.nothingSelected = utils.localizedString(
      'ENERGY_MANAGEMENT.CONSUMPTION_TARGET.CHOOSE_FACILITY'
    );
  }

  $onInit() {
    this.facilitySelectHelperElements = `filter${this.selectionMode === 'multiple' ? ' all none' : ''}`;
  }

  $onChanges() {
    if (this.facilities) {
      this.facilities = this.$filter('orderBy')(_.cloneDeep(this.facilities), 'Name');

      // Add option "all" to begin of dropdown menu
      if (this.optionAll) {
        this.facilities = [{
          FacilityId: 'all',
          Name: this.utils.localizedString('ACTIONS.SETTINGS.ALL_FACILITIES')
        }].concat(this.facilities);
      }

      // Select default facility if id has been given
      if (angular.isArray(this.selectedFacilityIds)) {
        this.selectedFacilities = [];
        this.selectedFacilityIds.forEach(facilityId => {
          const selectedFacility = this.facilities.find(facility => facility.FacilityId === facilityId);
          if (angular.isObject(selectedFacility)) {
            selectedFacility.ticked = true;
            this.selectedFacilities.push(selectedFacility);
          }
        });
      }
    }
  }

  onSelectionChange() {
    if (this.facilities) {
      this.onChange(this.selectedFacilities);
    }
  }

  onSelectNone() {
    this.selectedFacilities = [];
    this.onChange(this.selectedFacilities);
  }

  onSelectAll() {
    this.selectedFacilities = angular.copy(this.facilities);
    this.onChange(this.selectedFacilities);
  }
}

EmFacilitySelectController.$inject = $inject;
export default EmFacilitySelectController;
