import EditConfigurationTemplate from 'raw-loader!../templates/modal-edit-configuration.html';
import ConfigurationEditController from '../controllers/modal-edit-configuration-controller';
import EditInheritedConfigurationTemplate from 'raw-loader!../templates/modal-edit-inherited-configuration.html';
import ConfigurationEditInheritedController from '../controllers/modal-edit-inherited-configuration-controller';
import ConfigurationMassImportTemplate from 'raw-loader!../templates/modal-mass-import.html';
import ConfigurationMassImportController from '../controllers/modal-mass-import-controller';
import DocumentationEditTemplate from 'raw-loader!../templates/modal-edit-documentation.html';
import DocumentationEditController from '../controllers/modal-edit-documentation-controller';
import { EditBucketModalComponent } from '../edit-bucket-modal/edit-bucket-modal.component';
import { ShowPasswordModalComponent } from '../show-password-modal/show-password-modal.component';

const $inject = ['$modal', 'modalServiceAngular'];

class ConfigurationControlModalService {
  constructor($modal, modalService) {
    this.$modal = $modal;
    this.modalService = modalService;
  }

  addConfiguration() {
    const modalInstance = this.$modal.open({
      template: EditConfigurationTemplate,
      controller: ConfigurationEditController,
      controllerAs: 'vm',
      bindToController: true,
    });
    return modalInstance;
  }

  addInheritedConfiguration(readerTypeId, terminals, baseProperties, specificProperties) {
    const modalInstance = this.$modal.open({
      template: EditInheritedConfigurationTemplate,
      controller: ConfigurationEditInheritedController,
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
        readerTypeId: function() {
          return readerTypeId;
        },
        readerBaseProperties: function() {
          return baseProperties;
        },
        readerSpecificProperties: function() {
          return specificProperties;
        },
        terminals: function() {
          return terminals;
        }
      }
    });
    return modalInstance;
  }

  importConfigurations(readerTypeId, groupIdentifier, cronExpression) {
    const modalInstance = this.$modal.open({
      template: ConfigurationMassImportTemplate,
      controller: ConfigurationMassImportController,
      controllerAs: 'vm',
      bindToController: true,
      windowClass: 'large',
      resolve: {
        readerTypeId: function() {
          return readerTypeId;
        },
        groupIdentifier: function() {
          return groupIdentifier;
        },
        cronExpression: function() {
          return cronExpression;
        }
      }
    });
    return modalInstance;
  }

  getBucketDocumentationEditModal(bucketId, groupIdentifier) {
    const modalInstance = this.$modal.open({
      template: DocumentationEditTemplate,
      controller: DocumentationEditController,
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
        bucketId: function() {
          return bucketId;
        },
        groupIdentifier: function() {
          return groupIdentifier;
        },
      }
    });
    return modalInstance;
  }

  showPassword(configurationId) {
    const modalInstance = this.modalService.open(
      ShowPasswordModalComponent
    );
    modalInstance.componentInstance.configurationId = configurationId;
    return modalInstance;
  }

  updateBucket(readerTypeName, bucket) {
    const modalInstance = this.modalService.open(
      EditBucketModalComponent
    );
    modalInstance.componentInstance.bucket = bucket;
    modalInstance.componentInstance.readerTypeName = readerTypeName;
    return modalInstance;
  }
}

ConfigurationControlModalService.$inject = $inject;

export default ConfigurationControlModalService;
