import _ from 'lodash';

GridStateService.$inject = ['$state'];

export function GridStateService($state) {
  function saveState(grid, extraState, saveTo) {
    // Default $state.params.gridState always refers to the currently
    // selected (visible) view, so works in modal situations too.
    saveTo = saveTo || $state.params;

    const dataSource = grid.dataSource ? grid.dataSource : void 0;
    const data = dataSource ? dataSource.data() : [];
    if (data.length) {
      const newState = {
        query: {},
        columns: []
      };
      newState.query.sort = dataSource.sort();
      newState.query.group = dataSource.group();
      newState.query.filter = dataSource.filter();
      newState.query.page = dataSource.page();
      newState.columns = grid.columns;

      if (_.isObject(extraState)) {
        _.extend(newState, extraState);
      }

      _.set(saveTo, 'gridState', newState);
    }
  }

  function doQuery(query, grid, pageSize) {
    query = query || {};
    if (pageSize && query.page > grid.dataSource._data.length / pageSize || !query.page) {
      query.page = 1;
    }
    if (!query.pageSize && pageSize) {
      query.pageSize = pageSize;
    }
    query.aggregate = grid.dataSource.aggregate();
    grid.dataSource.query(query);
  }

  function loadState(state, gridOptions, grid, pageSize) {
    // TODO: Handle default state

    if (_.isObject(state)) {
      const columnPropertiesChanged = mergeColumns(gridOptions.columns, state.columns);
      const columnOrderChanged = gridOptions.reorderable ? reorderColumns(gridOptions.columns, state.columns) : false;

      if (grid) {
        if (columnPropertiesChanged || columnOrderChanged) {
          grid.setOptions(gridOptions);
        }

        doQuery(state.query, grid, pageSize);
      }
    }
  }

  function reorderColumns(oldColumns, newColumns) {
    _.each(newColumns, (newColumn, newIndex) => {
      const oldIndex = _.findIndex(oldColumns, { field: newColumn.field });

      if (oldIndex !== newIndex) {
        const toBeRelocated = _.pullAt(oldColumns, oldIndex);

        oldColumns.splice(newIndex, 0, toBeRelocated[0]);
      }
    });
  }

  function mergeColumns(oldColumns, newColumns) {
    const columnsChangedArray = [];
    _.each(oldColumns, oldColumn => {
      const newColumn = _.find(newColumns, { field: oldColumn.field });
      if (newColumn) {
        columnsChangedArray.push(mergeColumn(oldColumn, newColumn));

        _.each(oldColumn.columns, oldChildColumn => {
          const newChildColumn = _.find(newColumn.columns, { field: oldChildColumn.field });
          columnsChangedArray.push(mergeColumn(oldChildColumn, newChildColumn));
        });
      }
    });
    return !!_.compact(columnsChangedArray).length;
  }

  function mergeColumn(oldColumn, newColumn) {
    let changed = false;
    if (oldColumn && newColumn) {
      const interestedProperties = ['width', 'hidden'];
      if (angular.isUndefined(oldColumn.hidden)) {
        oldColumn.hidden = false;
      }
      _.each(interestedProperties, property => {
        if (angular.isDefined(oldColumn[property]) && newColumn[property] !== oldColumn[property]) {
          changed = true;
          oldColumn[property] = newColumn[property];
        }
      });
    }
    return changed;
  }

  return {
    saveState: saveState,
    loadState: loadState
  };
}
