/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { IAngularStatic, IPromise, IQService } from 'angular';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { CostFactorType } from '@enerkey/clients/cost-factor';
import { MeteringType, MeterManagementMeter } from '@enerkey/clients/meter-management';
import { $InjectArgs, getNumericEnumValues } from '@enerkey/ts-utils';

import { SpreadsheetFactor } from '../components/meter-cost-mass-edit/spreadsheet-factor';
import { QuantityService } from '../../../shared/services/quantity.service';
import MeterSpreadsheetConstants from '../constants/meter-spreadsheet-constants';
import { MeterService } from '../../../shared/services/meter.service';
import { LegacyFacilityService } from '../../reportingobjects/models/facilities';

declare const angular: IAngularStatic;

export class MeterSpreadsheetService {
  protected static $inject: $InjectArgs<typeof MeterSpreadsheetService> = [
    '$q',
    'AdminSpreadsheetTranslationsService',
    'facilities',
    'SpreadsheetService',
    'MeterSpreadsheetFieldsService',
    'quantities',
    'meterService',
  ];

  private skipValidations = false;
  private forceRowCount = false;
  private rowCount = MeterSpreadsheetConstants.DEFAULT_ROW_COUNT;
  private defaults = { width: 165 };
  private colors: any;
  private translations: any;
  private defaultCellFormat = '@';

  public constructor(
    private $q: IQService,
    adminSpreadsheetTranslationsService: any,
    private facilities: LegacyFacilityService,
    spreadsheetService: any,
    private meterSpreadsheetFieldsService: any,
    private quantities: QuantityService,
    private readonly meterService: MeterService
  ) {
    this.colors = spreadsheetService.getColors();
    this.translations = adminSpreadsheetTranslationsService.getTranslations();
  }

  public setPowerUserMode(value: boolean): void {
    this.skipValidations = value;
    this.forceRowCount = value;
  }

  public getSpreadsheetEditOptions(facilityMeters: MeterManagementMeter[]): IPromise<kendo.ui.SpreadsheetOptions> {
    this.rowCount = facilityMeters.length + 1;
    return this.getSpreadsheetOptionsBase(facilityMeters);
  }

  public getSpreadsheetOptions(count: number): IPromise<kendo.ui.SpreadsheetOptions> {
    this.rowCount = count;
    return this.getSpreadsheetOptionsBase();
  }

  public getCostSpreadsheetOptions(
    fieldType: CostFactorType,
    meters: SpreadsheetFactor[]
  ): IPromise<kendo.ui.SpreadsheetOptions> {
    this.rowCount = MeterSpreadsheetConstants.DEFAULT_ROW_COUNT;
    return this.getCostSpreadsheetOptionsBase(meters.length && meters, fieldType);
  }

  private getCostSpreadsheetOptionsBase(
    facilityMeters?: any[],
    fieldType?: CostFactorType
  ): IPromise<kendo.ui.SpreadsheetOptions> {
    const deferred = this.$q.defer();

    this.getFirstSheet(facilityMeters, fieldType).then((result: any) => {
      const spreadsheetOptions = angular.copy({
        toolbar: false,
        sheetsbar: false,
        activeSheet: this.translations.sheets.inputPage,
        columns: 4,
        rows: this.rowCount,
        sheets: [result],
      });
      deferred.resolve(spreadsheetOptions);
    });

    return deferred.promise;
  }

  private getSpreadsheetOptionsBase(
    facilityMeters?: any[],
    fieldType?: CostFactorType,
    overrideRowcountCalculation?: boolean
  ): IPromise<kendo.ui.SpreadsheetOptions> {
    const deferred = this.$q.defer();

    this.$q.all([
      this.getFacilityInformationSheet(facilityMeters),
      this.getQuantityDataSheet(),
      this.getMeterTypeSheet()
    ])
      .then((result: [any, any, any]) => {
        // Create at most 300 rows when explicit rowCount is not used
        // With thousands of rows spreadsheet becomes unusable
        this.rowCount = overrideRowcountCalculation || this.forceRowCount
          ? this.rowCount
          : Math.min(Math.max(...result.map(sheet => sheet.rows.length), this.rowCount), 300);

        this.$q.resolve(this.getFirstSheet(facilityMeters, fieldType)).
          then(firstSheetResult => {
            const spreadsheetOptions = angular.copy({
              toolbar: false,
              sheetsbar: false,
              activeSheet: this.translations.sheets.inputPage,
              columns: 4,
              rows: this.rowCount,
              sheets: [firstSheetResult, ...result],
            });
            deferred.resolve(spreadsheetOptions);
          });
      });

    return deferred.promise;
  }

  private getFirstSheet(facilityMeters: any[], fieldType: CostFactorType): IPromise<kendo.ui.SpreadsheetOptions> {
    const deferred = this.$q.defer();
    const columns: any[] = [];
    this.meterSpreadsheetFieldsService.getFields(fieldType).forEach((field: any) => {
      columns.push({
        width: field.width
      });
    });
    deferred.resolve({
      name: this.translations.sheets.inputPage,
      columns: columns,
      rows: this.getRows(facilityMeters, fieldType)
    });
    return deferred.promise;
  }

  private getRows(facilityMeters: any[], fieldType: CostFactorType): kendo.ui.SpreadsheetSheetRow[] {
    const rows = [];
    rows.push({ cells: this.getHeaderCells(fieldType) });

    const getCells = fieldType
      ? this.getCostInputPageDataCells.bind(this)
      : this.getInputPageDataCells.bind(this);

    if (facilityMeters) {
      facilityMeters.forEach((facilityMeter: any) => {
        rows.push({ cells: getCells(facilityMeter) });
      });
      for (let i = 1; i < this.rowCount - facilityMeters.length; i++) {
        rows.push({ cells: getCells() });
      }
    } else {
      for (let i = 1; i < this.rowCount; i++) {
        rows.push({ cells: getCells() });
      }
    }

    return rows;
  }

  private getHeaderCells(fieldType: CostFactorType): kendo.ui.SpreadsheetSheetRowCell[] {
    const headerCells: kendo.ui.SpreadsheetSheetRowCell[] = [];
    this.meterSpreadsheetFieldsService.getFields(fieldType).forEach((field: any) => {
      headerCells.push({
        value: field.required ? `${field.text} *` : field.text,
        background: this.colors.headerBackground,
        textAlign: 'center',
        color: this.colors.default,
        enable: false
      });
    });
    return headerCells;
  }

  private getCostInputPageDataCells(meter?: any): kendo.ui.SpreadsheetSheetRowCell[] {
    const validations = [
      this.getMeterIdValidation(),
      this.getDateValidation(),
      this.getNumberValidationAllowNegative(),
      this.getNumberValidationAllowNegative(),
      this.getStatusValidation()
    ];
    if (meter) {
      const values = this.meterSpreadsheetFieldsService.CostFieldNames.map((value: any) => ({ value: meter[value] }));
      return _.zipWith(validations, values, _.assign);
    }
    return _.zipWith(validations, _.assign);
  }

  private getInputPageDataCells(facilityMeter?: any): kendo.ui.SpreadsheetSheetRowCell[] {
    const format = this.defaultCellFormat;
    if (facilityMeter) {
      return [
        { value: facilityMeter.id },
        { ...this.getNumberValidation(true), value: facilityMeter.enegiaId },
        { value: facilityMeter.name, format },
        { ...this.getMeterTypeValidation(), value: facilityMeter.meteringType },
        { ...this.getQuantityIdValidation(), value: facilityMeter.quantityId },
        { value: facilityMeter.factor },
        { value: facilityMeter.usagePlaceNumber, format },
        { value: facilityMeter.eanCode, format },
        { value: facilityMeter.protocolCode, format },
        { value: facilityMeter.customerMeterIdentifier, format },
        {
          ...this.getDateValidation(),
          value: facilityMeter.automaticReadingStartTime,
        },
        {
          ...this.getDateValidation(),
          value: facilityMeter.automaticReadingEndTime,
        },
        {
          ...this.getDateValidation(),
          value: facilityMeter.deactivationTime,
        },
        { ...this.getBooleanValidation(), value: facilityMeter.qualityAssurance },
        { ...this.getBooleanValidation(), value: facilityMeter.automaticModeling },
        { value: facilityMeter.description, format },
        { ...this.getBooleanValidation(), value: facilityMeter.twoTimeMeasurement },
        { ...this.getBooleanValidation(), value: facilityMeter.costMeter },
        { value: facilityMeter.costFactorSourceMeterId, format },
        { ...this.getBooleanValidation(), value: facilityMeter.linkMeterFixedCosts },
        { value: facilityMeter.energyCompanyUsagePlaceNumber, format },
        { ...this.getResolutionValidation(), value: facilityMeter.resolution, format },
        { value: this.getMeterTags(facilityMeter.tags), format },
        { ...this.getStatusValidation(), format }
      ];
    } else {
      return [
        {}, // Meter Id
        { ...this.getNumberValidation(true) }, // Enegia Id
        { format }, // Meter name
        { ...this.getMeterTypeValidation() }, // Meter type Id
        { ...this.getQuantityIdValidation() }, // Quantity Id
        {}, // Factor
        { format }, // Usage place number
        { format }, // EAN
        { format }, // Protocol code
        { format }, // Meter identifier
        { ...this.getDateValidation() }, // Automatic reading start time
        { ...this.getDateValidation() }, // Automatic reading end time
        { ...this.getDateValidation() }, // Deactivation time
        { ...this.getBooleanValidation() }, // Quality assurance
        { ...this.getBooleanValidation() }, // Automatic modeling
        { format }, // Description
        { ...this.getBooleanValidation() }, // Two-time metering
        { ...this.getBooleanValidation() }, // Cost meter
        { format }, // Cost source meter
        { ...this.getBooleanValidation() }, // Fixed costs
        { format }, // Energy company usage place number
        { ...this.getResolutionValidation(), format }, // Resolution
        { format }, // Tags
        { ...this.getStatusValidation(), format } // Status
      ];
    }
  }

  private getFacilityInformationSheet(facilityMeters: any[]): IPromise<kendo.ui.SpreadsheetSheet> {
    const deferred = this.$q.defer();
    const facilityData: kendo.ui.SpreadsheetSheet = {
      name: this.translations.sheets.facilityPage,
      columns: [
        { width: this.defaults.width },
        { width: this.defaults.width }
      ],
      rows: [{
        cells: [{
          value: this.translations.enegiaId.heading,
          background: this.colors.headerBackground,
          textAlign: 'center',
          color: this.colors.default,
          enable: false
        }, {
          value: this.translations.facilityName.heading,
          background: this.colors.headerBackground,
          textAlign: 'center',
          color: this.colors.default,
          enable: false
        }]
      }]
    };
    this.facilities.getFacilitiesInformation()
      .then((result: any[]) => {
        result.forEach((facility: any) => {
          facilityData.rows.push({
            cells: [{
              // format: 'number', // TODO: Check if this works
              value: parseInt(facility.FacilityInformation.EnegiaId)
            }, { value: facility.Name }]
          });
        });
        if (facilityMeters) {
          facilityMeters.forEach((facility: any) => {
            if (!(facilityData.rows.some(row => row.cells[0].value === parseInt(facility.enegiaId)))) {
              facilityData.rows.push({
                cells: [{
                  // format: 'number', // TODO: Check if this works
                  value: parseInt(facility.enegiaId)
                }, { value: facility.name }]
              });
            }
          });
        }
        deferred.resolve(facilityData);
      });
    return deferred.promise;
  }

  private getMeterTags(tags: string[]): string {
    return tags.join(', ');
  }

  private getQuantityDataSheet(): IPromise<kendo.ui.SpreadsheetSheet> {
    const quantityData: kendo.ui.SpreadsheetSheet = {
      name: this.translations.sheets.quantityPage,
      columns: [
        { width: 165 },
        { width: 165 }
      ],
      rows: [{
        cells: [{
          value: this.translations.quantityId.heading,
          background: this.colors.headerBackground,
          textAlign: 'center',
          color: this.colors.default,
          enable: false
        }, {
          value: this.translations.quantityName.heading,
          background: this.colors.headerBackground,
          textAlign: 'center',
          color: this.colors.default,
          enable: false
        }]
      }]
    };

    return firstValueFrom(this.quantities.getAllQuantities().pipe(
      map(quantities => {
        quantities
          .sortBy(quantity => quantity.ID)
          .forEach(quantity => {
            quantityData.rows.push({ cells: [{ value: quantity.ID }, { value: quantity.Name }] });
          });
        return quantityData;
      })
    ));
  }

  private getMeterTypeSheet(): IPromise<kendo.ui.SpreadsheetSheet> {
    const deferred = this.$q.defer();
    const meterTypeSheet: kendo.ui.SpreadsheetSheet = {
      name: this.translations.sheets.meterTypePage,
      columns: [
        { width: 165 },
        { width: 165 }
      ],
      rows: [{
        cells: [{
          value: this.translations.meterTypeId.heading,
          background: this.colors.headerBackground,
          textAlign: 'center',
          color: this.colors.default,
          enable: false
        }, {
          value: this.translations.meterTypeName.heading,
          background: this.colors.headerBackground,
          textAlign: 'center',
          color: this.colors.default,
          enable: false
        }]
      }]
    };

    getNumericEnumValues(MeteringType).map(meteringType => {
      meterTypeSheet.rows.push({
        cells: [
          { value: meteringType },
          { value: this.meterService.translateMeteringTypeId(meteringType) }
        ]
      });
    });

    deferred.resolve(meterTypeSheet);
    return deferred.promise;
  }

  private getMeterIdValidation(): kendo.ui.SpreadsheetSheetRowCell {
    const allowZero = false;
    return this.getNumberValidation(allowZero);
  }

  private getNumberValidation(allowZero: boolean): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    const comparerType = allowZero ? 'greaterThanOrEqualTo' : 'greaterThan';
    return {
      validation: {
        allowNulls: true,
        comparerType,
        from: '0'
      }
    };
  }

  private getNumberValidationAllowNegative(): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    return {
      validation: {
        allowNulls: true,
        comparerType: 'custom',
        from: 'ISNUMBER(R[0]C[0])',
      }
    };
  }

  private getDateValidation(): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    return {
      format: 'd.M.yyyy', // DateOfBirth
      validation: {
        dataType: 'date',
        comparerType: 'greaterThan',
        from: 'DATEVALUE("1.1.1900")',
        allowNulls: true,
        messageTemplate: this.translations.validFrom.messageTemplate,
        showButton: true
      }
    };
  }

  private getBooleanValidation(): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    return {
      validation: {
        dataType: 'number',
        showButton: false,
        from: '0',
        to: '1',
        type: 'reject',
        allowNulls: true,
        comparerType: 'between'
      }
    };
  }

  private getQuantityIdValidation(): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    return {
      validation: {
        dataType: 'list',
        showButton: true,
        from: `'${this.translations.sheets.quantityPage}'!A$2:A${this.rowCount}`,
        allowNulls: true,
        type: 'reject',
        titleTemplate: this.translations.quantityId.titleTemplate,
        messageTemplate: this.translations.quantityId.messageTemplate
      }
    };
  }

  private getMeterTypeValidation(): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    return {
      validation: {
        dataType: 'list',
        showButton: true,
        from: `'${this.translations.sheets.meterTypePage}'!A$2:A${this.rowCount}`,
        allowNulls: true,
        type: 'reject',
        titleTemplate: this.translations.meterTypeId.titleTemplate,
        messageTemplate: this.translations.meterTypeId.messageTemplate
      }
    };
  }

  private getResolutionValidation(): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    return {
      validation: {
        dataType: 'list',
        showButton: true,
        from: '{ "PT3M", "PT15M", "PT1H", "P1D", "P1M" , "P1Y" }',
        allowNulls: true,
        type: 'reject'
      }
    };
  }

  private getStatusValidation(): kendo.ui.SpreadsheetSheetRowCell {
    if (this.skipValidations) {
      return null;
    }
    return {
      enable: false
    };
  }
}
