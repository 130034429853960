import { Injectable } from '@angular/core';
import _ from 'lodash';
import { from, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { ofVoid, switchJoin } from '@enerkey/rxjs';
import { FacilityFilter, SettingsClient } from '@enerkey/clients/settings';

import { ExtendedFacilityInformation } from '../shared/interfaces/extended-facility-information';
import { LegacyFacilityService } from '../modules/reportingobjects/models/facilities';
import { ToasterService } from '../shared/services/toaster.service';
import { UserService } from './user-service';
import { ProfileService } from '../shared/services/profile.service';

interface FilteredFacilities {
  ids: number[];
}

@Injectable({ providedIn: 'root' })
export class FilterService {
  public readonly filteredFacilityIds$: Observable<number[]>;
  public readonly isFiltered$: Observable<boolean>;
  public readonly activeFilters$: Observable<FacilityFilter>;
  public readonly storedFilters$: Observable<FacilityFilter[]>;

  private filteredFacilities: FilteredFacilities = {
    ids: undefined,
  };

  private readonly _refreshStoredFilters$ = new Subject<void>();
  private readonly _refreshActiveFilter$ = new Subject<void>();

  public constructor(
    private readonly facilities: LegacyFacilityService,
    private readonly toasterService: ToasterService,
    private readonly settingsClient: SettingsClient,
    private readonly userService: UserService,
    private readonly profileService: ProfileService
  ) {
    this.activeFilters$ = merge(
      this.profileService.profile$,
      this._refreshActiveFilter$
    ).pipe(
      switchMap(() => this.settingsClient.getActiveFilter(this.userService.profileId)),
      shareReplay(1)
    );

    this.filteredFacilityIds$ = this.activeFilters$.pipe(
      switchJoin(() => from(this.facilities.getFacilities())),
      map(([filter, profileFacilities]) => {
        const facilityIds = this.getFacilityIdsForTheFilter(profileFacilities, filter);
        return facilityIds?.length === profileFacilities.length ? undefined : facilityIds;
      }),
      map(newFilters => this.applyFilter(newFilters)),
      catchError(() => {
        this.toasterService.generalError('LOAD', 'FILTERS');
        return of(undefined);
      }),
      shareReplay(1)
    );

    this.isFiltered$ = this.activeFilters$.pipe(
      map(filter => !!(filter.textual || filter.numeric))
    );

    this.storedFilters$ = merge(
      this.profileService.profile$,
      this._refreshStoredFilters$
    ).pipe(
      switchMap(() => this.settingsClient.getSavedFilters(this.userService.profileId)),
      shareReplay(1)
    );
  }

  public getFilteredFacilityIds(): number[] {
    return this.filteredFacilities.ids
      ? [...this.filteredFacilities.ids]
      : undefined
    ;
  }

  public setCurrentFilter(filter: FacilityFilter): Observable<boolean> {
    filter = filter ?? new FacilityFilter();
    // In case the user does not have any filter data in settingsapi for this profile then
    // we get undefined here. Turn that into an empty object instead. This causes the empty object
    // to get saved as current filter. Otherwise we might get existing filter from previous profile
    // being applied and that always causes an error.
    return this.activeFilters$.pipe(
      take(1),
      switchMap(oldFilter => {
        // either improper filter or same filter or empty filter
        if (_.isEqual(oldFilter, filter) || (_.isEmpty(filter) && _.isEmpty(oldFilter))) {
          return of(true);
        } else {
          return from(this.facilities.getFacilities()).pipe(
            take(1),
            switchMap(allFacilities => {
              const newFacilityIds = this.getFacilityIdsForTheFilter(allFacilities, filter);
              if (!newFacilityIds.length) {
                // no facilities, resolve as false
                return of(false);
              } else {
                return this.changeFilter(filter).pipe(
                  map(() => true)
                );
              }
            }),
            catchError(() => {
              this.toasterService.generalError('SAVE', 'FILTERS');
              return of(false);
            })
          );
        }
      })
    );
  }

  public addFilter(filter: FacilityFilter): Observable<unknown> {
    return this.storedFilters$.pipe(
      take(1),
      switchMap(filters => {
        const found = filters
          .find(f => _.isEqual(f.textual, filter.textual) && _.isEqual(f.numeric, filter.numeric));
        if (found) { // Don't store same filter again
          return ofVoid();
        }

        return this.settingsClient.saveFilter(this.userService.profileId, filter).pipe(
          tap(() => {
            this._refreshStoredFilters$.next();
          })
        );
      })
    );
  }

  public removeStoredFilter(filterId: number): Observable<unknown> {
    return this.settingsClient.deleteSavedFilter(this.userService.profileId, filterId).pipe(
      tap(() => {
        this._refreshStoredFilters$.next();
      })
    );
  }

  private changeFilter(filter: FacilityFilter): Observable<FacilityFilter> {
    return this.settingsClient.setActiveFilter(this.userService.profileId, filter).pipe(
      tap(() => {
        this._refreshActiveFilter$.next();
      })
    );
  }

  private getFacilityIdsForTheFilter(
    facilitiesList: ExtendedFacilityInformation[],
    filter: FacilityFilter
  ): number[] {
    let facilitiesFiltered = facilitiesList;

    if (filter.textual) {
      facilitiesFiltered = _.filter(facilitiesList, filter.textual);
    }

    if (filter.numeric) {
      facilitiesFiltered = facilitiesFiltered.reduce((result, facility) => {
        let append = true;
        const keys = Object.keys(filter.numeric) as (keyof ExtendedFacilityInformation)[];
        keys.forEach(key => {
          const filterValue = filter.numeric[key];
          if (!facility[key]) {
            append = false;
            return;
          }
          const childKeys = Object.keys(filterValue);
          childKeys.forEach(childKey => {
            const childValue = filterValue[childKey];
            if (!(facility[key] as any)[childKey]) {
              append = false;
              return;
            }
            if (Number.isFinite(childValue.min) || Number.isFinite(childValue.max)) {
              const minValue = childValue.min ?? Number.MIN_VALUE;
              const maxValue = childValue.max ?? Number.MAX_VALUE - 1;
              const facilityValue = (facility[key] as any)[childKey];
              if (!(facilityValue >= minValue && facilityValue <= maxValue)) {
                append = false;
                return;
              }
            }
          });
        });
        if (append) {
          result.push(facility);
        }
        return result;
      }, [] as ExtendedFacilityInformation[]);
    }

    return facilitiesFiltered.map(facility => facility.FacilityId);
  }

  private applyFilter(newFacilityIds: number[]): number[] {
    const filteredIds = newFacilityIds?.length ? newFacilityIds : undefined;

    this.filteredFacilities = {
      ids: newFacilityIds,
    };
    return filteredIds;
  }
}
