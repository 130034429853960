<modal-view-header
  [headingText]="headingText$ | async"
  [bookmarkEnabled]="true"
></modal-view-header>
<sidebar
  [isModal]="true"
  [isMeterReport]="isMeterReport$ | async"
></sidebar>
<div class="modal-body">
  <modal-report-type></modal-report-type>
</div>
