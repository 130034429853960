<div class="widget">
  <button
    class="button button--link button--external-link float-right"
    [disabled]="loading$ | async"
    (click)="goToEnergyManagement()"
  >
    {{ 'LATEST_ACTIONS_WIDGET.SHOW_ALL_ACTIONS' | translate }}
  </button>

  <form [formGroup]="formGroup">
    <ek-combo
      formControlName="executionPhase"
      [items]="executionPhaseOptions"
      *labelBefore="'ACTIONS.EXECUTIONPHASE' | translate"
    ></ek-combo>
  </form>

  <table
    *ngIf="facilityNames$ | async as facilityNames"
    class="table striped"
  >
    <thead>
      <th style="width: 30%;">
        {{ 'LATEST_ACTIONS_WIDGET.REPORTING_OBJECT' | translate }}
      </th>
      <th class="show-for-medium">
        {{ 'LATEST_ACTIONS_WIDGET.UPDATED_AT' | translate }}
      </th>
      <th style="width: 40%;">
        {{ 'ACTIONS.REPORTEDDESCRIPTION' | translate }}
      </th>
      <th style="width: 1%">
        {{ 'ACTIONS.INVESTIGATION' | translate }}
      </th>
    </thead>
    <tbody *ngIf="actions$ | async as actions">
      <tr *ngFor="let action of actions">
        <td>
          <a (click)="openFacilityReport(action)">
            {{ facilityNames[action.reportingObjectId] }}
          </a>
        </td>
        <td class="show-for-medium">
          {{ action.updatedAt | date }}
        </td>
        <td class="ellipsis">
          <span title="{{ action.reportedDescription }}">
            <a
              class="reported-description"
              (click)="openEditModal(action)"
            >
              {{ action.reportedDescription }}
            </a>
          </span>
        </td>
        <td>
          <investigation-status [status]="action.investigation"></investigation-status>
        </td>
      </tr>
    </tbody>
  </table>
</div>
