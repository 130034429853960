import 'angular-foundation-6';
import EnerkeyServicesModule from '../../../../services';
import MrcConstantsModule from '../../../mrc/constants';
import ManualQaServicesModule from '../../services';
import ManualQaMeterChangeComponent from './manual-qa-change-meter-component';
import ManualQaChangeMeterController from './manual-qa-change-meter-controller';
import ManualQaChangeMeterService from './manual-qa-change-meter-service';

const dependencies = [
  'mm.foundation',
  EnerkeyServicesModule.name,
  ManualQaServicesModule.name,
  MrcConstantsModule.name
];

const ManualQaChangeMeterModule = angular
  .module('app.modules.manual-qa.components.manual-qa-meter-change', dependencies)
  .component('manualQaChangeMeter', ManualQaMeterChangeComponent)
  .controller('ManualQaChangeMeterController', ManualQaChangeMeterController)
  .service('ManualQaChangeMeterService', ManualQaChangeMeterService)
;

export default ManualQaChangeMeterModule;
