import { Roles } from '../admin/constants/roles';
import { UIStateNg2 } from '../../shared/routing';
import { Service } from '../../constants/service';

export const documentsTopbarState: UIStateNg2<'documents'> = {
  name: 'documents',
  data: {
    translationKey: 'DOCUMENTS.DOCUMENTS',
    auth: {
      services: [Service.Documents],
      roles: [Roles.DOCUMENT_READ]
    },
  }
};
