import { Quantities } from '@enerkey/clients/metering';

export type MeterGroup = {
  id: number;
  name: string;
  description: string;
  quantityGroupId: number;
}

export enum MeterGroupCategory {
  ENERGY
}

export class MeterGroupCategoryQuantities {
  private static ENERGY_QUANTITIES = new MeterGroupCategoryQuantities(MeterGroupCategory.ENERGY, [
    Quantities.Electricity,
    Quantities.DistrictHeating,
    Quantities.Steam,
    Quantities.DistrictCooling,
    Quantities.CoolingOwnProduction,
    Quantities.HeatingOwnProduction,
    Quantities.OwnElectricity,
    Quantities.PelletMWh,
    Quantities.NaturalGasMWh,
    Quantities.OilMWh,
    Quantities.LiquidGasMWh,
    Quantities.NaturalGasOtherMWh,
    Quantities.BiogasMWh,
    Quantities.WoodChipsMWh,
    Quantities.HeavyFuelOilMWh
  ]);

  public static getByCategory(category: MeterGroupCategory): Quantities[] {
    switch (category) {
      case MeterGroupCategory.ENERGY: { return MeterGroupCategoryQuantities.ENERGY_QUANTITIES.quantities; }
      default: throw new Error('Unknown category');
    }
  }

  private constructor(
    public readonly category: MeterGroupCategory,
    public readonly quantities: Quantities[]
  ) {}
}

export class MeterGroupFormValidationError extends Error {}
