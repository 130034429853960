import DropdownController from './dropdown-controller';
import template from 'raw-loader!./dropdown.html';

const DropdownComponent = {
  template: template,
  transclude: true,
  controllerAs: 'vm',
  controller: DropdownController,
  bindings: {
    showOnHover: '<',
    settings: '<',
    noOffset: '<',
    itemsDisabled: '<'
  }
};

export default DropdownComponent;
