import EnerkeyServiceModule from '../../../../services';
import ManualQaServiceModule from '../../services';
import ManualQaConsumptionStatsComponent from './manual-qa-consumption-stats-component';
import ManualQaConsumptionStatsService from './manual-qa-consumption-stats-service';

const dependencies = [EnerkeyServiceModule.name, ManualQaServiceModule.name];

const ManualQaConsumptionStatsModule = angular
  .module('app.modules.manual-qa.components.consumption-stats', dependencies)
  .component('manualQaConsumptionStats', ManualQaConsumptionStatsComponent)
  .service('ManualQaConsumptionStatsService', ManualQaConsumptionStatsService);

export default ManualQaConsumptionStatsModule;
