import TimeFrame             from '../../../../services/time-frame-service';
import * as Colors           from '../../constants/colors';
import { getHourlyReadings } from '../../shared/utils.functions';

export const calculateStatistics = (readings, timeFrame) => {
  if (readings.length === 0 || !(timeFrame instanceof TimeFrame)) {
    return getEmptyResult();
  }

  return getHourlyReadings(readings).reduce((result, reading) => {
    const isInOperationTimeFrame = timeFrame.contains(reading.timestamp);

    if (isInOperationTimeFrame) {
      result.operation.push(reading.actualConsumption);
      result.model.push(reading.modelConsumption);
      result.comparison.push(reading.comparisonConsumption);
      result.preview.push(reading.previewConsumption);
    }

    result.actual.push(reading.actualConsumption);

    return result;
  }, getEmptyResult());
};

export const formatStatistics = (translations, statistics, isPreview, useComparisonPeriod) => [
  getActualPeriod(translations, statistics.actual),
  getModelPeriod(translations, statistics.model),
  getPreviewPeriod(translations, statistics.preview, isPreview),
  getComparisonPeriod(translations, statistics.comparison, useComparisonPeriod),
  getOperationPeriod(translations, statistics.operation)
];

const getActualPeriod = (translations, statistics) => ({
  title: translations['MQA.INSPECT.ACTIONS.INSPECTION_PERIOD'],
  color: Colors.SERIES_ACTUAL_CONSUMPTION,
  ...getSumResult(statistics),
  totalSum: null,
  show: false
});

const getOperationPeriod = (translations, statistics) => ({
  title: translations['MQA.INSPECT.ACTIONS.OPERATION_PERIOD'],
  color: Colors.OPERATION_PERIOD_BACKGROUND,
  ...getSumResult(statistics),
  show: true
});

const getPreviewPeriod = (translations, statistics, isPreview) => ({
  title: translations['MQA.INSPECT.ACTIONS.PREVIEW'],
  color: Colors.SERIES_PREVIEW_CONSUMPTION,
  ...getSumResult(statistics),
  ...(isPreview ? {} : getEmptySumResult()),
  show: true
});

const getComparisonPeriod = (translations, statistics, useComparisonPeriod) => ({
  title: translations['MQA.INSPECT.ACTIONS.COMPARISON_PERIOD'],
  color: Colors.SERIES_COMPARISON_CONSUMPTION,
  ...getSumResult(statistics),
  ...(useComparisonPeriod ? {} : getEmptySumResult()),
  show: false
});

const getModelPeriod = (translations, statistics) => ({
  title: translations['MQA.INSPECT.ACTIONS.MODEL'],
  color: Colors.SERIES_MODEL_CONSUMPTION,
  ...getSumResult(statistics),
  show: false
});

const getSumResult = values => {
  const totalSum = values.reduce((result, value) => result + value, 0);
  const daySum = calculateDaySum(totalSum, values.length);
  const hourSum = calculateHourSum(totalSum, values.length);

  return { totalSum, daySum, hourSum };
};

const getEmptyResult = () => ({ actual: [], operation: [], comparison: [], preview: [], model: [] });
const getEmptySumResult = () => ({ totalSum: null, daySum: null, hourSum: null });
const calculateDaySum = (totalSum, length) => length > 0 ? (totalSum / length) * 24 : 0;
const calculateHourSum = (totalSum, length) => length > 0 ? (totalSum / length) : 0;
