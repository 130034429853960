import template from 'raw-loader!./manual-qa-consumption-stats-cell.html';

export default {
  template: template,
  controllerAs: 'vm',
  bindings: {
    value: '<',
    decimals: '<'
  }
};
