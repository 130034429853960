import moment from 'moment';
import { format, getQuarter } from 'date-fns';

import { RequestResolution } from '@enerkey/clients/reporting';

import { TimeFrameString } from '../../../services/time-frame-service';

export function resolutionToString(
  startDate: Date,
  resolution: TimeFrameString | RequestResolution,
  index: number,
  longFormat = false
): string {
  const resolutionDuration = moment.fromIsodurationCached(resolution);

  if (resolutionDuration.years()) {
    const years = resolutionDuration.years();
    if (years === 1) {
      return startDate.getFullYear().toString();
    }
    const endYear = startDate.getFullYear() + years - 1;
    return `${startDate.getFullYear()} - ${endYear}`;
  }
  if (resolutionDuration.months()) {
    const months = resolutionDuration.months();
    if (months === 1) {
      return longFormat ? format(startDate, 'M/yy') : (startDate.getMonth() + 1).toString();
    } else if (months === 3) {
      const quarter = getQuarter(startDate);
      const quarterInfo = longFormat ? `${quarter}/${format(startDate, 'yy')}` : quarter;
      return `Q${quarterInfo}`;
    } else {
      return (index + 1).toString();
    }
  }

  return (index + 1).toString();
}

export function getCategoryLabels(
  resolution: TimeFrameString | RequestResolution,
  dataArrays: { timestamp: Date }[][]
): (string | number)[] {
  const labels = dataArrays.map(data => data.map((value, index) => resolutionToString(
    value.timestamp,
    resolution,
    index
  )));

  if (labels.length === 1) {
    return labels[0];
  }

  return labels[0].map((_value, index) => {
    const allLabels = labels.map(periodLabels => periodLabels[index]).unique();
    if (allLabels.length === 1) {
      return allLabels[0];
    }

    return allLabels.reduce(
      (categoryValue, label, labelIndex) => {
        const labelValue = getLabelValue(label, labelIndex === allLabels.length - 1);
        return `${categoryValue}${labelValue}`;
      }
      , ''
    );
  });

  function getLabelValue(label: string, isLast: boolean): string {
    if (!label) {
      return '';
    }
    return !isLast ? `(${label}) ` : `${label}`;
  }
}
