import ManualQaApiReaderTypeService from './manual-qa-api-reader-type.service';
import ManualQaApiServiceModule     from '../manual-qa-api';

const dependencies = [ManualQaApiServiceModule.name];

const ManualQaReaderTypeModule = angular
  .module('app.modules.manual-qa.services.manual-qa-api-reader-type', dependencies)
  .service('ManualQaApiReaderTypeService', ManualQaApiReaderTypeService)
;

export default ManualQaReaderTypeModule;
