import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'report-column-header',
  templateUrl: './report-column-header.component.html',
  styleUrls: ['./report-column-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportColumnHeaderComponent {
  @Input() public title: string;
  @Input() public color: string;
}
