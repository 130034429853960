import InlineIndicatorController from './inline-indicator.controller';
import template                  from 'raw-loader!./inline-indicator.html';

const InlineIndicatorComponent = {
  template: template,
  controller: InlineIndicatorController,
  controllerAs: 'vm',
  bindings: {
    type: '<',
    color: '<',
    visible: '<'
  }
};

export default InlineIndicatorComponent;
