export default class ConfigWrapper {
  static findGridColumn(gridColumns, configColumn) {
    return gridColumns.find(column => column.field === configColumn.field);
  }

  constructor(configColumn, gridColumns, viewData, totalAggregates) {
    gridColumns = gridColumns || [];
    this.viewData = viewData || [];
    this.totalAggregates = totalAggregates || {};
    this.gridColumn = ConfigWrapper.findGridColumn(gridColumns, configColumn) || {};
  }
}
