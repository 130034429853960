import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { fromEvent, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { SimpleChangesOf } from '@enerkey/ts-utils';

@Component({
  selector: 'page-changer',
  templateUrl: './page-changer.component.html',
  styleUrls: ['./page-changer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageChangerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('nextButton') public nextButton: ElementRef;
  @ViewChild('previousButton') public previousButton: ElementRef;

  @Input() public pages: number;
  @Input() public disabled: boolean;
  @Output() public readonly pageChange = new EventEmitter<number>();

  public readonly pageNumberControl = new UntypedFormControl(1);

  public readonly nextButtonDisabled$: Observable<boolean>;
  public readonly previousButtonDisabled$: Observable<boolean>;

  private readonly _nextButtonDisabled$ = new ReplaySubject<boolean>(1);
  private readonly _previousButtonDisabled$ = new ReplaySubject<boolean>(1);
  private readonly destroy$ = new Subject<void>();

  public constructor() {
    this.nextButtonDisabled$ = this._nextButtonDisabled$.asObservable();
    this.previousButtonDisabled$ = this._previousButtonDisabled$.asObservable();

    this.pageNumberControl.valueChanges.pipe(
      debounceTime(300),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.disableOrEnableButtons();
      this.pageChange.emit(this.currentPage - 1);
    });
  }

  public ngOnInit(): void {
    this.setInitialPage();
  }

  public ngOnChanges(changes: SimpleChangesOf<PageChangerComponent>): void {
    if (changes.pages) {
      this.setInitialPage();
    }
  }

  public ngAfterViewInit(): void {
    fromEvent(this.nextButton.nativeElement, 'click')
      .pipe(
        filter(() => this.currentPage < this.pages),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.currentPage++;
      });

    fromEvent(this.previousButton.nativeElement, 'click')
      .pipe(
        filter(() => this.currentPage > 1),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.currentPage--;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._nextButtonDisabled$.complete();
    this._previousButtonDisabled$.complete();
  }

  private setInitialPage(): void {
    if (this.pages > 0) {
      this.currentPage = 1;
    } else {
      this.currentPage = 0;
    }
  }

  private set currentPage(index: number) {
    this.pageNumberControl.setValue(index);
    this.disableOrEnableButtons();
  }

  private get currentPage(): number {
    return this.pageNumberControl.value;
  }

  private disableOrEnableButtons(): void {
    this._previousButtonDisabled$.next(this.currentPage < 2);
    this._nextButtonDisabled$.next(this.currentPage >= this.pages);
  }
}
