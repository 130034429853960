<ek-dropdown
  [width]="580"
  class="latest-documents-container"
  #dropdown
>
  <button
    *ekDropdownToggle="let toggle"
    (click)="toggle()"
    class="button icon"
    [title]="toggleTitle | translate"
  >
    <i class="fa fa-file-alt"></i>
  </button>
  <div *ngIf="dropdown.hasBeenExpanded$ | async" class="documents-icon-container latest-documents-dropdown">
    <div class="title-row">
      {{ toggleTitle | translate }}
    </div>
    <ng-container *ngIf="documents$ | async as documents">
      <ng-container *ngIf="documents?.length else noDocuments">
        <div class="table-header">
          {{ 'FACILITIES_REPORT.LATEST_DOCUMENTS_POPUP_TABLE_TITLE' | translate }}
        </div>
        <table class="documents-table">
          <tr>
            <th class="updated">
              {{ 'FACILITIES_REPORT.ACTIONS_POPUP_UPDATED' | translate}}
            </th>
            <th class="no-mobile">
              {{ 'DOCUMENTS.DOCUMENT_TYPE' | translate}}
            </th>
            <th>
              {{ 'DOCUMENTS.FILENAME' | translate }}
            </th>
          </tr>
          <tr *ngFor="let document of documents">
            <td>{{document.updatedAt}}</td>
            <td class="no-mobile">{{document.DocumentTypeName}}</td>
            <td class="ellipsis" title="{{document.fileNameOrUrl}}">
              <span class="document-filename">{{document.fileNameOrUrl}}</span>
            </td>
          </tr>
        </table>
      </ng-container>
      <ng-template #noDocuments>
        <div class="table-header">
          {{ 'FACILITIES_REPORT.LATEST_DOCUMENTS_EMPTY' | translate }}
        </div>
      </ng-template>
    </ng-container>

    <div class="actions-to-perform">
      <div class="action-to-perform">
        <button class="button button--link button--external-link" (click)="goToDocuments()">
          {{ 'FACILITIES_REPORT.GO_TO_DOCUMENTS' | translate  }}
        </button>
      </div>
      <div class="action-to-perform">
        <div class="button" (click)="addDocument()" *ngIf="allowToAddDocument">
          <i class="fa fa-plus icon"></i>
          <span>{{ 'FACILITIES_REPORT.ADD_NEW' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</ek-dropdown>
