import _ from 'lodash';
import {
  IAngularStatic,
  IHttpResponse,
} from 'angular';

import { Facility, IFacilityClient, TagList } from '@enerkey/clients/facility';

import { MassImportSpreadsheet } from '../../../../shared/base-classes/mass-import-spreadsheet';
import { SpreadsheetMessages } from '../../../../shared/interfaces/spreadsheet-messages';
import WizardStep from '../../../../components/wizard/wizard-step';
import { FacilityCreateModel } from './facility-create-model';
import { Utils } from '../../../../services/utils';
import FacilityCreateSpreadsheetService from './facility-create-spreadsheet-service';
import { parseFacilityTagsFromString } from '../../../../constants/facility.constant';
import { LegacyFacilityService } from '../../../reportingobjects/models/facilities';
import { setOleDatesToDates } from '../../shared/facility-date-functions';
import { firstValueFrom } from 'rxjs';

/* eslint-disable @typescript-eslint/no-explicit-any */

declare const angular: IAngularStatic;

interface FacilityUpdateFields {
  name: string;
  text: string;
  type: string;
  width: number;
  validationPage: string;
  required: boolean;
  default: string;
}

const enumValuedTypes = ['electricityTaxClass', 'ownership'];

const $inject = [
  '$element', '$window', '$q', 'AdminFacilitySpreadsheetSelectionService', 'facilities',
  'FacilityCreateSpreadsheetService', 'HttpPendingRequestsService', 'KendoFunctions', 'utils', 'FacilityClient'
];

class FacilityCreateController extends MassImportSpreadsheet implements ng.IOnInit, ng.IOnDestroy {

  public spreadsheetElementId: string = 'spreadsheet';
  public currentStep: WizardStep;
  public spreadsheetOptions: kendo.ui.SpreadsheetOptions;
  public title: string;
  public enegiaIds: number[];
  protected messages: SpreadsheetMessages;
  protected numberOfColumns: number;
  protected spreadsheet: kendo.ui.Spreadsheet;
  protected override totalRowCount: number;
  private resizeFn: (() => void) & _.Cancelable;
  private readonly steps: WizardStep[];
  private readonly enegiaIdColumn = 0;
  private facilityFields: FacilityUpdateFields[];
  private onSpreadsheetCreate: any;

  public constructor(
    protected $element: JQuery,
    private $window: ng.IWindowService,
    private $q: ng.IQService,
    private AdminFacilitySpreadsheetSelectionService: any,
    private facilities: LegacyFacilityService,
    private facilityCreateSpreadsheetService: FacilityCreateSpreadsheetService,
    private HttpPendingRequestsService: any,
    private KendoFunctions: any,
    private utils: Utils,
    private FacilityClient: IFacilityClient
  ) {
    super();
    this.steps = this.getSteps();

    this.enegiaIds = [];
    this.messages = this.getMessages();
  }

  public createFacilities(): void {
    if (this.validateSheet()) {
      this.saveFacilityBase(this.getFacilitiesFromDataRows())
        .then(this.saveFacilityAddresses.bind(this))
        .then(this.saveFacilityExternalIds.bind(this))
        .then(this.saveTechnicalSpecifications.bind(this))
        .then(this.saveFacilityTags.bind(this))
        .then(this.handleSuccess.bind(this))
        .finally(this.setFinalStep.bind(this));
    }
  }

  public $onInit(): void {
    this.title = this.utils.localizedString('ADMIN.CREATE_FACILITIES');
    this.facilityFields = this.AdminFacilitySpreadsheetSelectionService.getUpdateFields();
    this.numberOfColumns = this.facilityFields.length + 2;

    this.facilityCreateSpreadsheetService
      .getCreateFacilitySpreadsheetOptions()
      .then((result: kendo.ui.SpreadsheetOptions) => {
        result.render = (e: kendo.ui.SpreadsheetRenderEvent) => {
          e.sender.unbind('render');
          this.resizeKendo();
          this.lockRange(0);
          this.lockRange(this.numberOfColumns - 1);
          this.onSpreadsheetCreate({ spreadsheet: this.spreadsheet });
        };
        this.spreadsheetOptions = result;
        this.totalRowCount = result.rows;
      });
  }

  public override $onDestroy(): void {
    super.$onDestroy();
    angular.element(this.$window).off('resize', this.resizeFn);
  }

  private getMessages(): SpreadsheetMessages {
    return {
      errorStatus: this.utils.localizedString('ADMIN.SPREADSHEET.ERROR'),
      conflictStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CONFLICT'),
      savedStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVED'),
      savingStatus: this.utils.localizedString('ADMIN.SPREADSHEET.SAVING'),
      canceledStatus: this.utils.localizedString('ADMIN.SPREADSHEET.CANCELED'),
      noChange: this.utils.localizedString('ADMIN.SPREADSHEET.NO_CHANGE')
    };
  }

  private getSteps(): WizardStep[] {
    return [
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
        isReturnable: true,
        clickFn: this.createFacilities.bind(this),
        rollbackFn: this.enableSheet.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
        isReturnable: false,
        clickFn: this.stopSaving.bind(this),
        customButtonText: this.utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING')
      },
      {
        text: this.utils.localizedString('ADMIN.SPREADSHEET.READY'),
        hideButton: true
      }
    ];
  }

  private setFinalStep(): void {
    this.currentStep = this.steps[this.steps.length - 1];
  }

  private stopSaving(): void {
    this.HttpPendingRequestsService.cancelAll();
    // TODO: Set cancelled status
    this.setFinalStep();
  }

  private getFacilitiesFromDataRows(): any {
    const dataRows = this.getDataRows();
    const facilityArray: FacilityCreateModel[] = [];
    dataRows.forEach((row, idx) => {
      if (row.some(this.isNotNull)) {
        facilityArray.push(this.getFacilityFromRow(row, idx));
      }
    });
    return facilityArray;
  }

  private getFacilityFromRow(row: (string | number)[], idx: number): FacilityCreateModel {
    const facility: Partial<FacilityCreateModel> = {};
    this.facilityFields.forEach((value, rowIndex: number) => {
      // We don't have enegiaId in facility fields, but we have it on dataRows.
      // First value.name contains addresses[0].city and we have it on second column, so we sift by one.
      // -> we can create facility object using facility fields using lodash set
      const rowValue = row[rowIndex + 1] !== null ? row[rowIndex + 1] : value.default; // EnegiaId is first column
      // Backend set enums types to default when they are not set
      if (rowValue || !enumValuedTypes.includes(value.name)) {
        _.set(facility, value.name, rowValue);
      }
    });
    facility.rowNumberInSheet = idx;
    return facility as FacilityCreateModel;
  }

  private saveFacilityBase(toBeCreatedFacilities: FacilityCreateModel[]): any {
    setOleDatesToDates(toBeCreatedFacilities);
    return this.facilities.createFacilities(toBeCreatedFacilities)
      .then((result: Facility[]) => {
        this.setIds(toBeCreatedFacilities, result);
        this.setEnegiaIdColumns(toBeCreatedFacilities, result);
        return toBeCreatedFacilities;
      })
      .catch((result: IHttpResponse<any>) => {
        if (result.status === 400) {
          Object.keys(result.data).forEach((key: any) => {
            // Example for key -> [0].yearOfBuild
            const faultyFacility = _.get(toBeCreatedFacilities, key.split('.')[0], null);
            this.setErrorStatus(faultyFacility.rowNumberInSheet, result.data[key].toString());
          });
        } else {
          toBeCreatedFacilities.forEach(facility => {
            this.setErrorStatus(facility.rowNumberInSheet);
          });
        }
      });
  }

  private setIds(facilitiesFromRows: FacilityCreateModel[], result: Facility[]): void {
    result.forEach((_facility, index) => {
      facilitiesFromRows[index].id = result[index].id;
      facilitiesFromRows[index].enegiaId = result[index].enegiaId;
    });
  }

  private setEnegiaIdColumns(facilitiesFromRows: FacilityCreateModel[], result: Facility[]): void {
    result.forEach((facility, index) => {
      this.enegiaIds.push(facility.enegiaId);
      this.setEnegiaIdColumn(facilitiesFromRows[index].rowNumberInSheet, facility.enegiaId);
    });
  }

  private saveFacilityAddresses(facilitiesFromRows: FacilityCreateModel[]): ng.IPromise<FacilityCreateModel[]> {
    const promises: ng.IPromise<unknown>[] = [];
    facilitiesFromRows.forEach(facility => {
      if (this.shouldSaveAddresses(facility)) {
        this.setProcessingStatus(facility.rowNumberInSheet);
        promises.push(
          this.facilities.addAddress(facility.id, facility.addresses[0])
            .catch((response: IHttpResponse<string>) => this.handleError(response, facility, response.data))
        );
      }
    });
    return this.$q.all(promises).then(() => facilitiesFromRows);
  }

  private saveFacilityTags(facilitiesFromRows: FacilityCreateModel[]): ng.IPromise<FacilityCreateModel[]> {
    const promises: Promise<any>[] = [];

    for (const facility of facilitiesFromRows) {
      const tags = parseFacilityTagsFromString(facility.tags);

      if (Array.hasItems(tags)) {
        promises.push(
          firstValueFrom(this.FacilityClient.addFacilityTags(new TagList({
            facilityIds: [facility.id],
            tags: tags,
          })))
        );
      }
    }

    return this.$q.all(promises).then(() => facilitiesFromRows);
  }

  private saveFacilityExternalIds(facilitiesFromRows: FacilityCreateModel[]): ng.IPromise<FacilityCreateModel[]> {
    const promises: ng.IPromise<void>[] = [];
    facilitiesFromRows.forEach(facility => {
      if (this.shouldSaveExternalIds(facility)) {
        this.setProcessingStatus(facility.rowNumberInSheet);
        promises.push(
          this.facilities.addOrUpdateExternalIds(facility.id, facility.externalIds)
            .catch((response: IHttpResponse<string>) => this.handleError(response, facility, response.data))
        );
      }
    });
    return this.$q.all(promises).then(() => facilitiesFromRows);
  }

  private saveTechnicalSpecifications(facilitiesFromRows: FacilityCreateModel[]): ng.IPromise<FacilityCreateModel[]> {
    const promises: Promise<void>[] = [];
    facilitiesFromRows.forEach(facility => {
      if (this.shouldSaveTechincalSpecifications(facility)) {
        this.setProcessingStatus(facility.rowNumberInSheet);
        promises.push(
          firstValueFrom(this.FacilityClient.upsertTechnicalSpecifications(facility.id, facility.technicalSpecifications))
            .catch((response: IHttpResponse<string>) => this.handleError(response, facility, response.data))
        );
      }
    });
    return this.$q.all(promises).then(() => facilitiesFromRows);
  }

  private handleSuccess(facilitiesFromRows: FacilityCreateModel[]): void {
    facilitiesFromRows.forEach(facility => {
      this.setSuccessStatus(facility.rowNumberInSheet);
    });
  }

  private handleError(response: IHttpResponse<any>, facility: FacilityCreateModel, message: string): void {
    if (response.status === 409) {
      this.setConflictStatus(facility.rowNumberInSheet);
    } else {
      this.setErrorStatus(facility.rowNumberInSheet, message);
    }
  }

  private shouldSave(value: Record<string, any>): boolean {
    return Object.values(value).some(x => !!x);
  }

  private shouldSaveAddresses(facility: FacilityCreateModel): boolean {
    return this.shouldSave(facility.addresses[0]);
  }

  private shouldSaveExternalIds(facility: FacilityCreateModel): boolean {
    return this.shouldSave(facility.externalIds);
  }

  private shouldSaveTechincalSpecifications(facility: FacilityCreateModel): boolean {
    return this.shouldSave(facility.technicalSpecifications);
  }

  private isNotNull(element: any): boolean {
    return element !== null;
  }

  private setEnegiaIdColumn(index: number, enegiaId: number): void {
    this.setCellValue(index, this.enegiaIdColumn, enegiaId.toString());
  }

  private resizeKendo(): void {
    this.spreadsheet = this.getSpreadsheetInstance();
    this.resizeFn = _.debounce(() => {
      this.KendoFunctions.resizeKendoComponent(this.spreadsheet, this.spreadSheetRowClass);
    }, 200);

    angular.element(this.$window).on('resize', this.resizeFn);

    this.KendoFunctions.resizeKendoComponent(this.spreadsheet, this.spreadSheetRowClass);
  }
}

FacilityCreateController.$inject = $inject;

export default FacilityCreateController;
