import { Co2eq, ICategory, IRowWithId, IUpdateRowUnit, RowMetadata } from '@enerkey/clients/sustainability';

export type IGriEditorRow = {
  id: number | null;
  description: string;
  order: number;
  category?: ICategory;
  unit?: IUpdateRowUnit;
  metadata: RowMetadata,
  readonly totalValue?: number;
  readonly co2total?: number;
  readonly changes: GriRowChanges;
} & Record<GriValueField, number | null>;

export const GRI_VALUE_FIELDS = ['m0', 'm1', 'm2', 'm3', 'm4', 'm5', 'm6', 'm7', 'm8', 'm9', 'm10', 'm11'] as const;
export type GriValueField = typeof GRI_VALUE_FIELDS[number];

export class GriEditorRow implements IGriEditorRow {
  public readonly changes: GriRowChanges;

  public id: number;
  public description: string;
  public order: number;
  public created: Date;
  public facilityId: number;
  public category?: ICategory;
  public unit?: IUpdateRowUnit;

  public m0: number;
  public m1: number;
  public m2: number;
  public m3: number;
  public m4: number;
  public m5: number;
  public m6: number;
  public m7: number;
  public m8: number;
  public m9: number;
  public m10: number;
  public m11: number;

  public metadata: RowMetadata;

  public emissionFactor?: string;

  public get totalValue(): number {
    return GRI_VALUE_FIELDS.reduce(
      (total, field) => Number.isFinite(this[field]) ? ((total ?? 0) + this[field]) : total,
      null as number
    );
  }

  public get co2total(): number {
    const factor = this.unit?.co2Factor;
    const total = this.totalValue;
    if (total === null || !Number.isFinite(factor)) {
      return null;
    }
    return factor * total / tonnesEquivalent(this.unit.co2Eq);
  }

  public constructor(row: IRowWithId, category: ICategory, unit: IUpdateRowUnit) {
    this.id = row.id;
    this.description = row.description;
    this.order = row.ordinal ?? 0;
    this.category = category;
    this.unit = unit;
    this.facilityId = row.facilityId;

    this.m0 = row.values?.[0] ?? null;
    this.m1 = row.values?.[1] ?? null;
    this.m2 = row.values?.[2] ?? null;
    this.m3 = row.values?.[3] ?? null;
    this.m4 = row.values?.[4] ?? null;
    this.m5 = row.values?.[5] ?? null;
    this.m6 = row.values?.[6] ?? null;
    this.m7 = row.values?.[7] ?? null;
    this.m8 = row.values?.[8] ?? null;
    this.m9 = row.values?.[9] ?? null;
    this.m10 = row.values?.[10] ?? null;
    this.m11 = row.values?.[11] ?? null;

    this.metadata = row.metadata;
    this.changes = new GriRowChanges(this);
  }

  public getPayload(): IRowWithId {
    return {
      id: this.id,
      categoryId: this.category.id,
      description: this.description,
      rowUnitId: this.unit.id,
      values: GRI_VALUE_FIELDS.map(field => this[field]),
      metadata: this.metadata ?? null,
      ordinal: 0,
      facilityId: this.facilityId,
    };
  }
}

export function tonnesEquivalent(eq: Co2eq): number {
  switch (eq) {
    case Co2eq.Grams:
      return 1_000_000;
    case Co2eq.Kilograms:
      return 1_000;
    case Co2eq.Tonnes:
    default:
      return 1;
  }
}

export class GriRowChanges implements Record<GriValueField, boolean> {
  private static readonly CHANGE_FIELDS: (keyof GriRowChanges)[] = [
    ...GRI_VALUE_FIELDS,
    'description',
    'category',
    'unit',
    'metadata',
  ];

  public get anyChanges(): boolean {
    return GriRowChanges.CHANGE_FIELDS.some(field => this[field]);
  }

  public get m0(): boolean { return this.row.m0 !== this._originalValues[0]; }
  public get m1(): boolean { return this.row.m1 !== this._originalValues[1]; }
  public get m2(): boolean { return this.row.m2 !== this._originalValues[2]; }
  public get m3(): boolean { return this.row.m3 !== this._originalValues[3]; }
  public get m4(): boolean { return this.row.m4 !== this._originalValues[4]; }
  public get m5(): boolean { return this.row.m5 !== this._originalValues[5]; }
  public get m6(): boolean { return this.row.m6 !== this._originalValues[6]; }
  public get m7(): boolean { return this.row.m7 !== this._originalValues[7]; }
  public get m8(): boolean { return this.row.m8 !== this._originalValues[8]; }
  public get m9(): boolean { return this.row.m9 !== this._originalValues[9]; }
  public get m10(): boolean { return this.row.m10 !== this._originalValues[10]; }
  public get m11(): boolean { return this.row.m11 !== this._originalValues[11]; }

  public get description(): boolean { return this.row.description !== this._originalDesc; }
  public get category(): boolean { return this.row.category.id !== this._originalCategory; }
  public get unit(): boolean { return this.row.unit?.id !== this._originalUnit; }
  public get metadata(): boolean { return (this.row.metadata ?? null) !== this._originalMetadata; }

  public get totalValue(): boolean { return GRI_VALUE_FIELDS.some(field => this[field]); }
  public get co2total(): boolean { return this.unit || this.totalValue; }

  public get isNew(): boolean { return !this.row.id || this.row.id < 1; }

  private readonly _originalValues: number[];
  private readonly _originalDesc: string;
  private readonly _originalCategory: number;
  private readonly _originalUnit: number;
  private readonly _originalMetadata: RowMetadata;

  public constructor(
    private readonly row: IGriEditorRow
  ) {
    this._originalValues = Array.from({ length: GRI_VALUE_FIELDS.length });

    for (let i = 0; i < GRI_VALUE_FIELDS.length; i++) {
      this._originalValues[i] = row[GRI_VALUE_FIELDS[i]];
    }

    this._originalDesc = row.description;
    this._originalCategory = row.category?.id;
    this._originalUnit = row.unit?.id;
    this._originalMetadata = row.metadata ?? null;
  }

  public reset(zero: boolean): void {
    for (let i = 0; i < GRI_VALUE_FIELDS.length; i++) {
      this.row[GRI_VALUE_FIELDS[i]] = (!this.isNew && !zero)
        ? this._originalValues[i]
        : null;
    }

    // simply clearing values preserves description; reverting them restores original
    if (!zero) {
      this.row.description = this._originalDesc;
      this.row.metadata = this._originalMetadata;
    } else {
      this.row.metadata = null;
    }
  }
}

export function isGriMonthValueField(field: unknown): field is GriValueField {
  return GRI_VALUE_FIELDS.includes(field as GriValueField);
}

export function spreadTo12Months(value: number, target: IGriEditorRow): void {
  const perMonth = Number.isFinite(value) ? value / 12 : null;

  for (const field of GRI_VALUE_FIELDS) {
    target[field] = perMonth;
  }
}

export function getGriRowValuesFromMetadata(metadata: RowMetadata | null): number[] {
  if (!metadata) {
    return Array.from<number>({ length: 12 }).fill(null);
  }

  return Object.integerEntries(metadata.consumptions ?? {})
    .filter(([meterId]) => !(metadata.excludedMeters ?? []).includes(meterId))
    .map(([_, values]) => values)
    .reduce(
      (sums, current) => {
        for (let i = 0; i < 12; i++) {
          sums[i] += (current[i] ?? 0);
        }
        return sums;
      },
      Array.from<number>({ length: 12 }).fill(0)
    );
}
