relatedValues.$inject = ['$q', '$http', '$timeout', 'httpConfigService', 'languageChangeService'];

function relatedValues($q, $http, $timeout, httpConfigService, languageChangeService) {

  var relatedValuesDeferred;

  // watch for language change start
  languageChangeService.languageChangeStart.subscribe(() => {
    if (relatedValuesDeferred) {
      relatedValuesDeferred.resolve([]);
    }
    relatedValuesDeferred = undefined;
  });

  return {

    getRelatedValues: function() {
      if (!relatedValuesDeferred) {
        relatedValuesDeferred = $q.defer();
        $http.get(ENERKEY_CONFIG.apiEnergyreporting + '/api/v1/configuration/relatedValues', httpConfigService.getExtendedHttpConfig())
          .then(function({ data }) {
            if (relatedValuesDeferred) {
              relatedValuesDeferred.resolve(data.RelatedValues);
            }
          })
          .catch(function({ status }) {
            if (relatedValuesDeferred) {
              relatedValuesDeferred.reject(status);
              $timeout(function() {
                relatedValuesDeferred = undefined;
              });
            }
          })
        ;
      }
      return relatedValuesDeferred.promise;
    }

  };
}

export default relatedValues;
