import { Pipe, PipeTransform } from '@angular/core';

import { ProvisioningResult, SynchronizationResult } from '@enerkey/clients/provisioning';

const resultKey = ['meterResults', 'facilityResults', 'alarmResults', 'configurationResults'] as const;

const operationTranslationKeys: Record<typeof resultKey[number], string> = {
  meterResults: 'PROVISIONING.METER_RESULTS',
  facilityResults: 'PROVISIONING.FACILITY_RESULTS',
  alarmResults: 'PROVISIONING.ALARM_RESULTS',
  configurationResults: 'PROVISIONING.CONFIGURATION_RESULTS'
};

@Pipe({
  name: 'availableOperationResults'
})
export class AvailableOperationResultsPipe implements PipeTransform {
  public transform(provisioning: ProvisioningResult): { key: string; value: SynchronizationResult }[] {
    if (!provisioning) {
      return [];
    }
    return resultKey.filterMap(
      key => provisioning[key],
      key => ({ key: operationTranslationKeys[key], value: provisioning[key] })
    );
  }
}
