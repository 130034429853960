import ManualQaResultComponent from './manual-qa-result-component';
import { ManualQaResultService } from './manual-qa-result-service';

const dependencies = [];

const ManualQaResultModule = angular
  .module('app.modules.manual-qa.components.manual-qa-result', dependencies)
  .component('manualQaResult', ManualQaResultComponent)
  .service('ManualQaResultService', ManualQaResultService);

export default ManualQaResultModule;
