import _ from 'lodash';
import moment from 'moment';

import actionEditModel from '../constants/em-action-edit-model-constant';
import { Service } from '../../../constants/service';
import EmValidationService from '../services/em-validations-service';

actions.$inject = ['$q', '$http', 'utils', 'UserService', 'httpConfigService'];

function actions($q, $http, utils, UserService, httpConfigService) {
  function hasAccessToActionsOrComments() {
    return UserService.hasService(Service.Actions) || UserService.hasService(Service.Comments);
  }

  // Enumerations
  const enumIdFields = [
    'actionType', 'actionBasis', 'executionPhase',
    'actionClass', 'actionGroup', 'investmentSupport',
    'investigation'
  ];

  function getFileUploadConfig() {
    const basicConfig = httpConfigService.getExtendedHttpConfig();

    basicConfig.headers = _.extend(UserService.getAuthorizationHeader(), {
      'Content-Type': () => void 0
    });
    basicConfig.timeout = 60000; // longer timeout
    basicConfig.transformRequest = data => {
      const formData = new FormData();
      // need to convert our json object to a string version of json otherwise
      // the browser will do a 'toString()' on the object which will result
      // in the value '[Object object]' on the server.
      formData.append('value', angular.toJson(data.action));

      for (let i = 0; i < data.documents.length; i++) {
        formData.append('files', data.documents[i]);
      }
      return formData;
    };

    return basicConfig;
  }

  // schema.model.fields for Kendo datasource listing actions
  const actionFields = {
    actionClass: { type: 'number', isInt: true },
    actionGroup: { type: 'number', isInt: true },
    investmentSupport: { type: 'number', isInt: true },
    updatedAt: { type: 'date' },
    createdAt: { type: 'date' },
    effectStartsAt: { type: 'date' },
    effectStopsAt: { type: 'date' },
    lifeTime: { type: 'number', isInt: true },
    investment: { type: 'number' },
    paybackTime: { type: 'number' },
    reportingYear: { type: 'number', isInt: true },
    executionYear: { type: 'number', isInt: true },
    executionPhaseUpdatedAt: { type: 'date' },
    reportedToMotivaAt: { type: 'date' },
    electricitySavings: { type: 'number' },
    electricitySavingsEur: { type: 'number' },
    electricitySavingsCo2: { type: 'number' },
    heatingSavings: { type: 'number' },
    heatingSavingsEur: { type: 'number' },
    heatingSavingsCo2: { type: 'number' },
    fuelSavings: { type: 'number' },
    fuelSavingsEur: { type: 'number' },
    fuelSavingsCo2: { type: 'number' },
    waterSavings: { type: 'number' },
    waterSavingsEur: { type: 'number' }
  };

  function filterAction(action, params) {
    const filteredAction = {};
    params = params || actionEditModel;
    params.forEach(field => {
      const value = action[field.name];
      if (field.type === 'array') {
        if (value && value.length) {
          filteredAction[field.name] = filterArrayTypeValue(field, value);
        }
      } else {
        if (angular.isDefined(value)) {
          filteredAction[field.name] = value;
        }
      }
    });
    return filteredAction;
  }

  function filterArrayTypeValue(field, values) {
    const filteredValue = [];
    for (let i = 0; i < values.length; i++) {
      const value = field.structure ? filterAction(values[i], field.structure) : values[i];
      filteredValue.push(value);
    }
    return filteredValue;
  }

  function getActions(payload) {
    const deferred = $q.defer();

    if (hasAccessToActionsOrComments()) {
      $http.post(
        // eslint-disable-next-line no-undef
        `${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1.1/actionsquery`,
        payload,
        httpConfigService.getExtendedHttpConfig({ isCancellable: true })
      )
        .then(response => {
          deferred.resolve(response.data);
        })
        .catch(response => {
          deferred.reject({
            status: response.status,
            message: _.isObject(response.data) ? response.data.exceptionMessage : response.statusText
          });
        });
    } else {
      deferred.resolve([]);
    }

    return deferred.promise;
  }

  function getActionsFlatlist(params) {
    const deferred = $q.defer();
    const config = _.merge(httpConfigService.getExtendedHttpConfig({ isCancellable: true }), { params: params });

    if (hasAccessToActionsOrComments()) {
      // eslint-disable-next-line no-undef
      $http.get(`${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/actionsFlatList`, config)
        .then(response => {
          deferred.resolve(response.data);
        })
        .catch(response => {
          deferred.reject({
            status: response.status,
            message: _.isObject(response.data) ? response.data.exceptionMessage : response.statusText
          });
        });
    } else {
      deferred.resolve([]);
    }

    return deferred.promise;
  }

  function getActionsFlatlistPost(params) {
    const config = httpConfigService.getExtendedHttpConfig({ isCancellable: true });

    if (hasAccessToActionsOrComments()) {
      return $http.post(
        // eslint-disable-next-line no-undef
        `${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/actionsFlatList`,
        params,
        config
      );
    }

    return $q.resolve({ data: [] });
  }

  const actionAndCommentFieldsPerSection = {
    basicInfo: {
      // Lists of fields to consider when counting how many form
      // fields don't pass validation.  Also used to determine which
      // fields aren't relevant for comments (those listed for
      // other, but not for comments).
      fields: {
        comment: [
          'reportingObjectName',
          'quantityName',
          'meterName',
          'actionType',
          'investigation',
          'effectStartsAt',
          'effectStopsAt',
          'reportedDescription',
          'internalDescription'
        ],
        other: [
          'reportingObjectName',
          'quantityName',
          'meterName',
          'actionType',
          'executionPhase',
          'investigation',
          'effectStartsAt',
          'effectStopsAt',
          'reportedDescription',
          'internalDescription'
        ]
      }
    },
    classification: {
      fields: {
        comment: [],
        other: ['actionGroup', 'actionBasis', 'actionClass', 'lifeTime']
      }
    },
    investmentAndSavings: {
      fields: {
        comment: [],
        other: [
          'investment',
          'paybackTime',
          'investmentSupport',
          'electricitySavings',
          'heatingSavings',
          'fuelSavings',
          'waterSavings',
          'electricitySavingsEur',
          'heatingSavingsEur',
          'fuelSavingsEur',
          'waterSavingsEur',
          'electricitySavingsCo2',
          'heatingSavingsCo2',
          'fuelSavingsCo2'
        ]
      }
    },
    reporting: {
      fields: {
        comment: [],
        other: ['reportingYear', 'reportedToMotivaAt', 'executionYear']
      }
    },
    irrCalculations: {
      fields: {
        comment: [],
        other: []
      }
    },
    documents: {
      fields: {
        comment: [],
        other: []
      }
    }
  };

  function stripAwayNonCommentFields(action) {
    if (EmValidationService.isComment(action)) {
      const fieldsToDrop = _.flatten(_.map(
        actionAndCommentFieldsPerSection,
        section => _.difference(section.fields.other, section.fields.comment)
      ));

      return _.omit(action, fieldsToDrop);
    }

    return action;
  }

  function hasFacility(action) {
    return !!action.reportingObjectId; // cast to boolean
  }

  function createAction(payload, documents) {
    if (hasAccessToActionsOrComments()) {
      if (documents.length > 0) {
        const fileuploadConfig = getFileUploadConfig();

        // eslint-disable-next-line no-undef
        return $http.post(`${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/actionwithfiles`, {
          action: payload,
          documents: documents
        }, fileuploadConfig);
      } else {
        return $http.post(
          // eslint-disable-next-line no-undef
          `${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/action`,
          payload,
          httpConfigService.getExtendedHttpConfig()
        );
      }
    } else {
      return $q.resolve();
    }
  }

  function updateAction(payload, documents) {
    const action = filterAction(payload);
    if (hasAccessToActionsOrComments()) {
      _.remove(action.documents, doc => doc.removed);

      if (documents.length > 0) {
        const fileuploadConfig = getFileUploadConfig();

        // eslint-disable-next-line no-undef
        return $http.put(`${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/actionwithfiles/${action.id}`, {
          action: action,
          documents: documents
        }, fileuploadConfig);
      } else {
        return $http.put(
          // eslint-disable-next-line no-undef
          `${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/action/${action.id}`,
          action,
          httpConfigService.getExtendedHttpConfig()
        );
      }
    } else {
      return $q.resolve();
    }
  }

  /**
   * Convert datatypes of all the fields based on the defined
   * Kendo datasource schema (schema.model.fields).  Return a new
   * object with converted values. An optional callback can be
   * provided that gets called when coercion fails (a number ends up
   * as NaN or a date ends up as null). Callback parameters:
   *  - action
   *  - field
   *  - error message.
   * The callback only gets called when the value is other than empty string.
   *
   *  Numbers:
   *   coerce({lifeTime: "1,2"})  // => { lifeTime: 1.2 }
   *   coerce({lifeTime: "asdf"}) // => { lifeTime: NaN }
   *   coerce({lifeTime: null})   // => { lifeTime: null }
   *   coerce({foo: "1,2"})       // => { foo: "1,2" }
   *  Dates:
   *   Uses moment.js to parse dates using two formats: DD.MM.YYYY and MM/DD/YYYY. It will choose the
   *   format that gives the first valid date. Returns null for invalid dates and a Date object for valid ones.
   *
   *   coerce({reportedToMotivaAt: "1.5.2016"})  // => { reportedToMotivaAt: 01.05.2016 }
   *   coerce({reportedToMotivaAt: "kissa"})  // => { reportedToMotivaAt: null }
   */
  function coerce(action, errorCallback) {
    return _.mapValues(action, (value, field) => {
      if (value === null || angular.isUndefined(value)) {
        return value;
      }
      if (actionFields[field]) {
        if (actionFields[field].type === 'number' && !angular.isNumber(value)) {
          let numberOrNaN = value.toString().replace(',', '.');

          if (actionFields[field].isInt) {
            numberOrNaN = parseInt(numberOrNaN, 10);
          } else {
            numberOrNaN = parseFloat(numberOrNaN);
          }

          if (errorCallback && value !== '' && isNaN(numberOrNaN)) {
            errorCallback(action, field, 'invalid number');
          }

          return numberOrNaN;
        } else if (actionFields[field].type === 'date' && !value.getMonth) {
          const mObj = moment(value, [moment.ISO_8601, 'DD.MM.YYYY', 'MM/DD/YYYY']);

          if (mObj.isValid()) {
            return mObj.toDate();
          }

          if (errorCallback && value !== '') {
            errorCallback(action, field, 'invalid date');
          }

          return null;
        }
      }

      return value;
    });
  }

  /**
   * Convenience function for cases when you get the actual
   * enumeration values in respective name-fields
   * (i.e. executionPhaseName is 0 instead of "Suggestion") and
   * want to rename them.  Modifies the argument!
   *
   *   renameEnumFieldsFromNames({executionPhaseName: 0}) // => { executionPhase: 0 }
   *
   */
  function renameEnumFieldsFromNames(action) {
    _.each(enumIdFields, idFieldName => {
      const readableFieldName = `${idFieldName}Name`; // actionType vs. actionTypeName
      if (_.has(action, readableFieldName)) {
        action[idFieldName] = action[readableFieldName];
        delete action[readableFieldName];
      }
    });

    return action;
  }

  /**
   * Convenience function for cases when you need human readable
   * names for enumeration values.  Modifies the argument!
   *
   *   updateFriendlyNamesForEnums({executionPhase: 0})
   *   // => { executionPhase: 0, executionPhaseName: "Suggestion" }
   *
   */
  function updateFriendlyNamesForEnums(action) {
    _.each(enumIdFields, idFieldName => {
      const readableFieldName = `${idFieldName}Name`; // actionType vs. actionTypeName
      if (_.isNumber(action[idFieldName]) && _.isFinite(action[idFieldName])) {
        // Localization key for readable field value
        const l10nKey = `ACTIONS.${idFieldName.toUpperCase()}_${action[idFieldName]}`;
        action[readableFieldName] = utils.localizedString(l10nKey);
      }
    });

    return action;
  }

  function getMeterName(action, metersById) {
    metersById = metersById || {};
    return _.chain(action.meterIds).map(
      meterId => (metersById[meterId] || utils.getDummyEntity(meterId)).Name
    ).join(', ').value();
  }

  function deleteAction(actionId) {
    if (hasAccessToActionsOrComments()) {
      return $http.delete(
        // eslint-disable-next-line no-undef
        `${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/action/${actionId}`,
        httpConfigService.getExtendedHttpConfig()
      );
    } else {
      return $q.resolve();
    }
  }

  function getDocumentsForAction(actionId) {
    if (hasAccessToActionsOrComments() && UserService.hasService(Service.Documents)) {
      return $http.get(
        // eslint-disable-next-line no-undef
        `${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/action/${actionId}/documents`,
        httpConfigService.getExtendedHttpConfig()
      );
    } else {
      return $q.resolve();
    }
  }

  function getActionsImpact(params) {
    const deferred = $q.defer();
    if (hasAccessToActionsOrComments()) {
      const requestConfig = httpConfigService.getExtendedHttpConfig();
      requestConfig.params.SavingsFilter = _.get(params, 'filters');
      requestConfig.params.from = _.get(params, 'from');
      requestConfig.params.to = _.get(params, 'to');
      // eslint-disable-next-line no-undef
      $http.get(`${ENERKEY_CONFIG.apiAttachmentManagement}/api/v1/actionsimpact`, requestConfig)
        .then(response => {
          deferred.resolve(response.data);
        })
        .catch(() => {
          deferred.reject();
        });
    } else {
      deferred.resolve([]);
    }
    return deferred.promise;
  }

  return {
    getActions: getActions,
    getActionsFlatlist: getActionsFlatlist,
    getActionsFlatlistPost: getActionsFlatlistPost,
    actionAndCommentFieldsPerSection: actionAndCommentFieldsPerSection,
    stripAwayNonCommentFields: stripAwayNonCommentFields,
    hasFacility: hasFacility,
    createAction: createAction,
    updateAction: updateAction,
    coerce: coerce,
    renameEnumFieldsFromNames: renameEnumFieldsFromNames,
    updateFriendlyNamesForEnums: updateFriendlyNamesForEnums,
    getMeterName: getMeterName,
    deleteAction: deleteAction,
    getDocumentsForAction: getDocumentsForAction,
    getActionsImpact: getActionsImpact
  };
}

export default actions;
