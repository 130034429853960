/**
 * Class that handles spreadsheet cell
 *
 * @param {string} column
 * @param {number} row
 *
 * @constructor
 */
export default class SpreadsheetCell {
  constructor(column, row) {
    this.column = column;
    this.row = row;
  }

  getColumn() {
    return this.column;
  }

  getRow() {
    return this.row;
  }
}
