<modal-view-header
  [headingText]="'ADMIN.BULK_MODIFY_ROLES.MODIFY_ROLES_BUTTON' | translate"
></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="massRoleEditForm"
    (ngSubmit)="submit()"
    id="massRoleEditForm"
  >
    <role-multi-select
      class="margin-bottom"
      *labelBefore="'ADMIN.BULK_MODIFY_ROLES.ADD_ROLES' | translate"
      formControlName="addRoles">
    </role-multi-select>
    <role-multi-select
      *labelBefore="'ADMIN.BULK_MODIFY_ROLES.REMOVE_ROLES' | translate"
      formControlName="removeRoles">
    </role-multi-select>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="massRoleEditForm"
      [loading]="loading$ | async"
      [disabled]="!massRoleEditForm.value.addRoles.length
      && !massRoleEditForm.value.removeRoles.length"
    >
      {{ 'ADMIN.BULK_MODIFY_ROLES.SAVE_BUTTON' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>