import {
  ITopConsumptionItem,
  TopConsumptionResponse
} from '@enerkey/clients/energy-reporting';
import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { Quantities } from '@enerkey/clients/metering';
import { percentageChange } from '@enerkey/ts-utils';
import { ChangeWidgetData, ChangeWidgetValueKey } from '../components/change-widget-base';

export type TopChangedWidgetRow = {
  comparisonPeriod: number;
  inspectionPeriod: number;
  relativeChange: number;
  absoluteChange: number;
  facilityId: number;
}

type RelationalValueKey = keyof Pick<ITopConsumptionItem, 'RelationalValues' | 'RelationalValuesNormalized'>

export class TopChangedConsumptions {
  public static getWidgetRows(
    data: TopConsumptionResponse,
    quantityId: Quantities,
    valueKey: ChangeWidgetValueKey,
    variableId: number,
    start: TimeFrameResult
  ): ChangeWidgetData<TopChangedWidgetRow> {
    return new TopChangedConsumptions(data, quantityId, valueKey, variableId, start).widgetRows;
  }

  private readonly comparisonPeriodKey: string;
  private readonly inspectionPeriodKey: string;
  private readonly relationalValueKey: RelationalValueKey;
  private readonly widgetRows: ChangeWidgetData<TopChangedWidgetRow>;

  private constructor(
    private readonly data: TopConsumptionResponse,
    private readonly quantityId: Quantities,
    private readonly valueKey: ChangeWidgetValueKey,
    private readonly variableId: number,
    private readonly start: TimeFrameResult
  ) {
    this.comparisonPeriodKey = this.start.Start[1].key;
    this.inspectionPeriodKey = this.start.Start[0].key;

    this.relationalValueKey = this.getRelationalValueKey();

    this.widgetRows = this.getWidgetRows();
  }

  private getWidgetRows(): ChangeWidgetData<TopChangedWidgetRow> {
    const gainers = this.dataToWidgetRows('Ascending');
    const fallers = this.dataToWidgetRows('Descending');
    return { gainers, fallers };
  }

  private dataToWidgetRows(ascOrDesc: 'Ascending' | 'Descending'): TopChangedWidgetRow[] {
    const quantityData = this.data[ascOrDesc][this.quantityId];
    if (!quantityData) {
      return [];
    }

    const facilitiesIncluded = this.data.FacilitiesIncluded[this.quantityId];

    return quantityData
      .filter(facilityData => facilitiesIncluded.includes(facilityData.Id))
      .map(facilityData => {
        const value = facilityData.Values;
        const comparisonPeriod = this.getPeriodValue(value, this.comparisonPeriodKey);
        const inspectionPeriod = this.getPeriodValue(value, this.inspectionPeriodKey);

        return {
          comparisonPeriod,
          inspectionPeriod,
          relativeChange: percentageChange(inspectionPeriod, comparisonPeriod),
          absoluteChange: inspectionPeriod - comparisonPeriod,
          facilityId: facilityData.Id,
        };
      }).filter(row => Number.isFinite(row.relativeChange));
  }

  private getPeriodValue(
    data: { [key: string]: ITopConsumptionItem },
    periodKey: string
  ): number {
    return this.variableId
      ? data[periodKey]?.[this.relationalValueKey][0] ?? null
      : data[periodKey]?.[this.valueKey] ?? null;
  }

  private getRelationalValueKey(
  ): RelationalValueKey {
    return this.valueKey === 'NormalisationValue' ? 'RelationalValuesNormalized' : 'RelationalValues';
  }
}
