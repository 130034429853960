import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  startWith,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import isMobile from 'ismobilejs';

import {
  ActionOutViewModel,
  ActionsQueryParameters,
  ActionType,
  AttachmentsClient,
  ExecutionPhase,
} from '@enerkey/clients/attachments';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { WidgetBase } from '../../shared/widget-base.interface';
import { UserService } from '../../../../services/user-service';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { getExecutionPhaseComboItems } from '../../../energy-management/constants/em-execution-phases';
import { DashboardStateService } from '../../services/dashboard-state.service';

export type FacilityActionsWidgetOptions = {
  facilityId: number;
  typeToShow: 'actions';
  numberToShow: number;
  numberToShowMobile: number;
  selectedActionTypes: { id: ActionType }[];
  selectedExecutionPhaseId: ExecutionPhase;
}

type FacilityActionsFilterForm = {
  executionPhase: FormControl<ExecutionPhase>;
  facilityId: FormControl<number>
}
type FacilityActionsFilterFormValue = {
  executionPhase: ExecutionPhase;
  facilityId: number
}

@Component({
  selector: 'facility-actions-widget',
  templateUrl: './facility-actions-widget.component.html',
  styleUrls: [
    './facility-actions-widget.component.scss',
    '../../styles/actions-comments-widget-table.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityActionsWidgetComponent implements WidgetBase<FacilityActionsWidgetOptions>, OnInit, OnDestroy {
  @Input() public dataModelOptions: FacilityActionsWidgetOptions;

  public readonly facilityNames$: Observable<Readonly<Record<number, string>>>;
  public readonly dataModelChange$: Observable<FacilityActionsWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;
  public readonly executionPhaseOptions: ComboItem<ExecutionPhase>[] = getExecutionPhaseComboItems();

  public readonly formGroup: FormGroup<FacilityActionsFilterForm>;

  public actions$: Observable<ActionOutViewModel[]>;

  private readonly _dataModelChange = new Subject<FacilityActionsWidgetOptions>();
  private readonly _loading$ = new LoadingSubject(true);
  private readonly _destroy$ = new Subject<void>();
  private readonly _error$ = new Subject<void>();
  private readonly _refresh$ = new BehaviorSubject<boolean>(true);

  private readonly _isMobile: boolean = isMobile().any;

  public constructor(
    private readonly attachmentsClient: AttachmentsClient,
    private readonly dashboardStateService: DashboardStateService,
    private readonly userService: UserService,
    private readonly fb: FormBuilder,
    private readonly changeDetection: ChangeDetectorRef
  ) {
    this.formGroup = this.fb.group<FacilityActionsFilterForm>({
      executionPhase: new FormControl(ExecutionPhase.Suggestion),
      facilityId: new FormControl(null)
    });

    this.error$ = this._error$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.dataModelChange$ = this._dataModelChange.asObservable();
  }

  public ngOnInit(): void {
    this.formGroup.patchValue({
      executionPhase: this.dataModelOptions.selectedExecutionPhaseId ?? ExecutionPhase.Suggestion,
      facilityId: this.dataModelOptions.facilityId
    });
    this.formGroup.valueChanges.pipe(
      takeUntil(this._destroy$)
    ).subscribe((formValue: Partial<FacilityActionsFilterFormValue>) => {
      this._dataModelChange.next({
        ...this.dataModelOptions,
        selectedExecutionPhaseId: formValue.executionPhase,
        facilityId: formValue.facilityId
      });
    });

    this.actions$ = combineLatest([
      this.dataModelChange$.pipe(startWith(this.dataModelOptions)),
      this._refresh$.asObservable()
    ]).pipe(
      switchMap(args => this.getActions$(args[0]).pipe(
        indicate(this._loading$),
        catchError(() => {
          this._error$.next();
          return EMPTY;
        })
      )),
      takeUntil(this._destroy$)
    );
  }

  public get facilityId(): FormControl {
    return this.formGroup.get('facilityId') as FormControl;
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._error$.complete();
    this._dataModelChange.complete();
    this._refresh$.complete();
  }

  public openEditModal(action: ActionOutViewModel): void {
    this.dashboardStateService.openEmEditModal(action)
      .then((response: { operation?: string, action?: ActionOutViewModel }) =>
        this.dashboardStateService.refreshReportData(action, response, this.changeDetection, this._refresh$));
  }

  public goToEnergyManagement(): void {
    this.dashboardStateService.goToEnergyManagement(this.dataModelOptions);
  }

  private getActions$(
    model: FacilityActionsWidgetOptions
  ): Observable<ActionOutViewModel[]> {

    if (!model?.selectedActionTypes.hasItems() || !model?.facilityId) {
      return of([]);
    }

    const profileId = this.userService.profileId;
    const params = new ActionsQueryParameters({
      actionTypes: model.selectedActionTypes.map(x => x.id),
      latestCount: this._isMobile ? model.numberToShowMobile : model.numberToShow,
      reportingObjectId: [model.facilityId],
      executionPhase: [model.selectedExecutionPhaseId],
    });
    return this.attachmentsClient.getActionsUsingPost(profileId, undefined, params);
  }

}
