import { Pipe, PipeTransform } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { assertType } from '@enerkey/ts-utils';
import { MeteringType } from '@enerkey/clients/metering';

import { QuantityService } from '../services/quantity.service';
import { MeterService } from '../services/meter.service';

// If this breaks, meteringTypePipe.transform needs to be looked at
type IsNumberEnum = MeteringType extends number ? true : false;
assertType<IsNumberEnum>();

@Pipe({ name: 'quantityMeteringType' })
export class QuantityMeteringTypePipe implements PipeTransform {

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly meterService: MeterService
  ) { }

  public transform(value: string): Observable<string> {
    if (typeof value === 'string') {
      const split = value.split(',');

      if (split.length === 2) {
        const meteringType = this.meterService.translateMeteringTypeId(Number.parseInt(split[1]));

        return forkJoin([
          this.quantityService.getQuantityTranslation(Number.parseInt(split[0]))
        ]).pipe(
          map(([quantity]) => `${quantity}, ${meteringType}`)
        );
      }
    }
    return of('');
  }

}
