import { Pipe, PipeTransform } from '@angular/core';

import { ColorService } from '../../../shared/services/color.service';

@Pipe({ name: 'periodIndicatorColor' })
export class PeriodIndicatorColorPipe implements PipeTransform {

  private readonly defaultColor: string;

  public constructor(
    private readonly colorService: ColorService
  ) {
    this.defaultColor = this.colorService.getCssProperty('--enerkey-black');
  }

  public transform(periodIndex: number, periods: number): string {
    if (periodIndex === periods - 2) {
      return this.colorService.firstComparisonPeriodColor;
    }
    if (periodIndex === periods - 3) {
      return this.colorService.secondComparisonPeriodColor;
    }
    return this.defaultColor;
  }
}
