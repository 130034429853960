// source: http://stackoverflow.com/questions/12931369/click-everywhere-but-here-event
// NOTE: changed the original a bit, listen mousedown and touchstart events instead of click
function clickAnywhereButHereService($document, $) {
  var tracker = [];

  function isInsideMeOrKendoElement(me, target) {
    var isInside = false;
    if (me.contains(target)) {
      isInside = true;
    }
    if (!isInside) {
      // let's check that if it is inside kendo animation container
      // for example datepicker uses containers outside its context
      // (ie. appends absolute positioned elements in body)
      $('.k-animation-container').each(function() {
        if ($(this)[0].contains(target)) {
          isInside = true;
          return false;
        }
      });
    }
    return isInside;
  }

  return function($scope, expr, elem) {
    var i, t, len;
    for(i = 0, len = tracker.length; i < len; i++) {
      t = tracker[i];
      if(t.expr === expr && t.scope === $scope) {
        return t;    
      }
    }
    var handler = function(e) {
      if (isInsideMeOrKendoElement(elem[0], e.target)) {
        e.stopPropagation();
        return;
      }
      $scope.$evalAsync(expr);
    };

    $document.on('mousedown', handler);
    $document.on('touchstart', handler);

    // IMPORTANT! Tear down this event handler when the scope is destroyed.
    $scope.$on('$destroy', function(){
      $document.off('mousedown', handler);
      $document.off('touchstart', handler);
    });

    t = { scope: $scope, expr: expr };
    tracker.push(t);
    return t;
  };
}

clickAnywhereButHereService.$inject = ['$document', '$'];

export default clickAnywhereButHereService;
