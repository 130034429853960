'use strict';
import mrcWarningModalTemplateModule       from 'raw-loader!../templates/mrc-warning-modal.html';
import mrcFacilityInfoModalTemplateModule  from 'raw-loader!../templates/mrc-facility-info-modal.html';
import mrcConfirmationModalTemplateModule  from 'raw-loader!../templates/mrc-confirmation-modal.html';
import mrcEmailSettingsModalTemplateModule from 'raw-loader!../templates/mrc-email-settings-modal.html';
import mrcEmailSendingModalTemplateModule  from 'raw-loader!../templates/mrc-email-sending-modal.html';
import mrcEmailSendingResultsModalTemplateModule from 'raw-loader!../templates/mrc-email-sending-results-modal.html';

import moment from 'moment';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs';

function mrcModals($modal, $filter, $window, utils) {

  function showUnsavedWarningModal(text) {
    var modalInstance = $modal.open({
      template: mrcWarningModalTemplateModule,
      windowClass: 'tiny',
      controller: ['$scope', function($scope) {
        $scope.confirmationText = text;
        $scope.isOverwriteWarning = false;
      }],
      resolve: {
        confirmation: function() {
          return 0;
        }
      }
    });
    return modalInstance.result;
  }

  function showConsumptionsOverwriteWarningModal(from, to) {
    var modalInstance = $modal.open({
      template: mrcWarningModalTemplateModule,
      windowClass: 'tiny',
      controller: ['$scope', '$filter', 'fromDate', 'toDate', function($scope, $filter, fromDate, toDate) {
        $scope.confirmationText = 'MRC.CONSUMPTIONS.VALUE_OVERWRITE_CONFIRMATION';
        $scope.isOverwriteWarning = true;
        $scope.fromDate = $filter('date')(fromDate, 'dd.MM');
        $scope.toDate = $filter('date')(toDate, 'dd.MM.yyyy');
      }],
      resolve: {
        confirmation: function() {
          return 0;
        },
        fromDate: function() {
          return from;
        },
        toDate: function() {
          return to;
        }
      }
    });
    return modalInstance.result;
  }

  function showFacilityInfoModal(fac, prop) {
    var modalInstance = $modal.open({
      template: mrcFacilityInfoModalTemplateModule,
      controller: 'MrcFacilityInfoController',
      resolve: {
        facility: function() {
          return fac;
        },
        properties: function() {
          return prop;
        }
      },
      windowClass: 'default-modal-container'
    });
    return modalInstance.result;
  }

  function showConfirmationModal(msg) {
    var modalInstance = $modal.open({
      template: mrcConfirmationModalTemplateModule,
      windowClass: 'tiny',
      controller: ['$scope', function($scope) {
        $scope.confirmationText = msg;
      }],
      resolve: {
        confirmation: function() {
          return 0;
        }
      }
    });
    return modalInstance.result;
  }

  function showEmailSettingsModal(user) {
    var modalInstance = $modal.open({
      template: mrcEmailSettingsModalTemplateModule,
      windowClass: 'small',
      controller: ['$scope', 'SettingsClient', 'currentUser', 'UserService', function($scope, SettingsClient, currentUser, UserService) {

        var enerkeyFrom = "lukemat@enerkey.com";
        var enerkeyCoverTxt = "Tervehdys,<br/><br/>Seuraavissa asiakkaamme energiamittauksissa on havaittu puutteita mittarilukemien toimittamisessa/tallentamisessa. Emme ole saaneet alla listattuja mittareiden lukemia tähän päivään mennessä. Toivomme, että pystyisitte toimittamaan/tallentamaan puuttuvien kuukausien lukemat mahdollisimman pian.<br/><br/>";
        var enerkeySignatureTxt = "<br/><br/>Muistattehan, että tarkkojen kohteen nimi- ja osoitetietojen ilmoittaminen lukemien toimituksen yhteydessä helpottaa kaikkien osapuolten työtä.<br/><br/><br/>Energiaseurantaterveisin<br/><br/><br/>----------------------------<br/><br/>Enerkey Helpdesk<br/><br/><br/>T +358 20 799 2101 | F +358 20 799 2102<br/><br/>lukemat@enerkey.com<br/><br/><br/>EnerKey | Lutakonaukio 7 | FI-40500 Jyväskylä<br/><br/>www.enerkey.com | portal.enerkey.com";
        $scope.languageOptionTexts = {
          'fi': $filter('translate')('SETTINGS.FI-FI'),
          'en': $filter('translate')('SETTINGS.EN-GB'),
          'sv': $filter('translate')('SETTINGS.SV-SE')
        };
        $scope.recipientOptionTexts = {
          'None': $filter('translate')('MRC.READINGS_LISTING.EMAIL_SETTINGS_MODAL.RECIPIENTS.NONE'),
          'ManagerCompanyContactEmail': $filter('translate')('MRC.READINGS_LISTING.EMAIL_SETTINGS_MODAL.RECIPIENTS.MANAGERS'),
          'MaintenanceCompanyContactEmail': $filter('translate')('MRC.READINGS_LISTING.EMAIL_SETTINGS_MODAL.RECIPIENTS.MAINTENANCE'),
          'OwnerCompanyContactEmail': $filter('translate')('MRC.READINGS_LISTING.EMAIL_SETTINGS_MODAL.RECIPIENTS.OWNERS'),
          'FacilityManagementCompanyContactEmail': $filter('translate')('MRC.READINGS_LISTING.EMAIL_SETTINGS_MODAL.RECIPIENTS.FACILITY_MANAGEMENT'),
        };
        var defaultEmailSettings = {
          'fi': {
            recipients: "None",
            replyTo: currentUser.userName,
            bcc: "",
            topic: "",
            showTopicDate: true,
            bccReplyTo: true,
            bccUser: false,
            coverTxt: "",
            signatureTxt: ""
          },
          'en': {
            recipients: "None",
            replyTo: currentUser.userName,
            bcc: "",
            topic: "",
            showTopicDate: true,
            bccReplyTo: true,
            bccUser: false,
            coverTxt: "",
            signatureTxt: ""
          },
          'sv': {
            recipients: "None",
            replyTo: currentUser.userName,
            bcc: "",
            topic: "",
            showTopicDate: true,
            bccReplyTo: true,
            bccUser: false,
            coverTxt: "",
            signatureTxt: ""
          }
        };
        $scope.replyAddresses = [currentUser.userName];
        $scope.selectedLang = currentUser.lang;
        $scope.userAddr = currentUser.userName;
        defaultEmailSettings[$scope.selectedLang].topic = $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.DEFAULT_TOPIC');
        if (currentUser.enerkeyUser) {
          $scope.replyAddresses.push(enerkeyFrom);
          defaultEmailSettings[$scope.selectedLang].replyTo = enerkeyFrom;
          defaultEmailSettings[$scope.selectedLang].coverTxt = enerkeyCoverTxt;
          defaultEmailSettings[$scope.selectedLang].signatureTxt = enerkeySignatureTxt;
        }
        $scope.settings = defaultEmailSettings;

        function getSettings() {
          firstValueFrom(SettingsClient.getNotificationEmailPreferences(UserService.profileId))
            .then(function(res) {
              if (Array.hasItems(Object.keys(res))) {
                $scope.settings = res;
              }
            }).catch(function() {
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.EMAIL_SETTINGS_LOADING_FAILED', true);
            }).finally(function() {
              updateTopic();
            });
        }

        function updateTopic() {
          var dateStr = ' - ' + moment().subtract(1, 'month').format('MMMM YYYY');
          if ($scope.settings[$scope.selectedLang].showTopicDate) {
            if (!$scope.settings[$scope.selectedLang].topic.includes(dateStr)) {
              $scope.settings[$scope.selectedLang].topic += dateStr;
            }
          } else {
            $scope.settings[$scope.selectedLang].topic = $scope.settings[$scope.selectedLang].topic.replace(dateStr, "");
          }
        }

        $scope.selectedLanguageChanged = function(newValue) {
          $scope.selectedLang = newValue;
          if (currentUser.enerkeyUser) {
            $scope.settings[$scope.selectedLang].replyTo = enerkeyFrom;
          }
          updateTopic();
        };

        $scope.toggleTopicDate = function() {
          updateTopic();
        };

        $scope.saveSettings = function() {
          firstValueFrom(SettingsClient.setNotificationEmailPreferences(UserService.profileId, $scope.settings))
            .then(
              function() {
                var txt = $filter('translate')('MRC.READINGS_LISTING.EMAIL_SETTINGS_SAVED');
                utils.popUp('success', txt);
              })
            .catch(
              function() {
                utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.EMAIL_SETTINGS_SAVING_FAILED', true);
              }
            );
        };

        $scope.resetDefaults = function() {
          $scope.settings[$scope.selectedLang] = defaultEmailSettings[$scope.selectedLang];
          $scope.saveSettings();
          updateTopic();
        };

        getSettings();

        $scope.$on('$destroy', function() {
        });

      }],
      resolve: {
        currentUser: function() {
          return user;
        }
      }
    });
    return modalInstance.result;
  }

  function showEmailSendingModal(emails, sendingCallback) {
    var modalInstance = $modal.open({
      template: mrcEmailSendingModalTemplateModule,
      windowClass: 'small',
      controller: ['$scope', '$modalInstance', 'mails', function($scope, $modalInstance, mails) {
        $scope.currentIdx = 0;
        $scope.mails = mails;

        function initAddressFields() {
          if (angular.isUndefined($scope.mails[$scope.currentIdx].content.to)) {
            $scope.mails[$scope.currentIdx].content.to = "";
          }
          $scope.mails[$scope.currentIdx].ccField = "";
        }

        function finished() {
          $modalInstance.close();
        }

        function gotToNext(currIdx, skipped) {
          if ($scope.mails.length > currIdx) {
            $scope.currentIdx += 1;
            $scope.mails[currIdx].content.to = $scope.mails[currIdx].content.to.replace(",", " ");
            if (_.isString($scope.mails[currIdx].ccField) && $scope.mails[currIdx].ccField.length > 0) {
              $scope.mails[currIdx].content.cc = $scope.mails[currIdx].ccField.replace(",", " ");
            }
            sendingCallback(currIdx, $scope.mails[currIdx].content, skipped);
            if ($scope.currentIdx >= $scope.mails.length) {
              finished();
            } else {
              initAddressFields();
            }
          }
        }

        initAddressFields();

        $scope.sendEmail = function(idx) {
          gotToNext(idx, false);
        };
        $scope.dismissEmail = function(idx) {
          gotToNext(idx, true);
        };
        $scope.$on('$destroy', function() {
        });
      }],
      resolve: {
        mails: function() {
          return emails;
        }
      }
    });
    return modalInstance.result;
  }

  function showEmailSendingResultsModal(results) {
    var modalInstance = $modal.open({
      template: mrcEmailSendingResultsModalTemplateModule,
      windowClass: 'tiny',
      controller: ['$scope', function($scope) {
        $scope.resultStatuses = {
          'sending': $filter('translate')('MRC.READINGS_LISTING.EMAIL_STATUS.SENDING'),
          'sent': $filter('translate')('MRC.READINGS_LISTING.EMAIL_STATUS.SENT'),
          'failed': $filter('translate')('MRC.READINGS_LISTING.EMAIL_STATUS.FAILED'),
          'skipped': $filter('translate')('MRC.READINGS_LISTING.EMAIL_STATUS.SKIPPED')
        };
        $scope.noRecipient = $filter('translate')('MRC.READINGS_LISTING.EMAIL_TEMPLATE.NO_RECIPIENT');
        $scope.results = results;
      }]
    });
    return modalInstance.result;
  }

  return {
    showUnsavedWarning: showUnsavedWarningModal,
    showConsumptionsOverwriteWarning: showConsumptionsOverwriteWarningModal,
    showFacilityInfo: showFacilityInfoModal,
    showConfirmationModal: showConfirmationModal,
    showEmailSettingsModal: showEmailSettingsModal,
    showEmailSendingModal: showEmailSendingModal,
    showEmailSendingResultsModal: showEmailSendingResultsModal
  };
}

mrcModals.$inject = ['$modal', '$filter', '$window', 'utils'];

export default mrcModals;
