import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LogStatus } from '@enerkey/clients/alarm';

const logStatusNameTranslations: Readonly<Record<LogStatus, string>> = {
  [LogStatus.InvestigationRequired]: 'ALARM.LOG.STATUS.REQUIRED',
  [LogStatus.UnderInvestigation]: 'ALARM.LOG.STATUS.ONGOING',
  [LogStatus.InvestigationDone]: 'ALARM.LOG.STATUS.DONE',
  [LogStatus.NoInvestigationRequired]: 'ALARM.LOG.STATUS.NOT_REQUIRED',
};

@Pipe({ name: 'logStatusName' })
export class LogStatusNamePipe implements PipeTransform {

  public constructor(
    private readonly translateService: TranslateService
  ) { }

  public transform(value: LogStatus): string {
    return value in logStatusNameTranslations
      ? this.translateService.instant(logStatusNameTranslations[value])
      : '';
  }

}
