<ng-container *ngIf="item">
  <div>
    <overview-card-field
      [value]="item[timeRange]?.value"
      [title]="item.unit"
      [quantityId]="item.quantityId"
      [difference]="item[timeRange]?.difference"
      [differencePercent]="item[timeRange]?.differencePercent"
      [incomplete]="item[timeRange]?.isIncomplete"
      [showDiff]="item[timeRange]?.showDiff"
    >
    </overview-card-field>
  </div>
  <ng-container *ngIf="!item[timeRange]?.isIncomplete" >
    <div *ngIf="item[timeRange]?.costData">
      <overview-card-field
        [value]="item[timeRange].costData.value"
        [title]="'&#8364;'"
        [quantityId]="item.quantityId"
        [difference]="item[timeRange].costData.difference"
        [differencePercent]="item[timeRange].costData.differencePercent"
      >
      </overview-card-field>
    </div>
    <div *ngIf="item[timeRange]?.emissionData && expanded">
      <overview-card-field
        [value]="item[timeRange].emissionData.value"
        [title]="'tCO&#178;'"
        [quantityId]="item.quantityId"
        [difference]="item[timeRange].emissionData.difference"
        [differencePercent]="item[timeRange].emissionData.differencePercent"
      >
      </overview-card-field>
    </div>
  </ng-container>
</ng-container>
