export default class ChartCategoryConfig {
  constructor(charts = [], title = '', viewPercentual = false) {
    this.chartData = charts;
    this.title = title;
    this.viewPercentual = viewPercentual;
    this.chartOptions = this.getChartOptions(this.viewPercentual);
  }

  setViewPercentual(viewPercentual) {
    this.viewPercentual = viewPercentual || false;
    this.chartOptions = this.getChartOptions(this.viewPercentual);
  }

  getChartOptions(viewPercentual) {
    const renderCenterValue = this.renderCenterValue.bind(this);
    let center;
    let radius;
    const { labelTemplate, tooltipTemplate } = this.getTemplates(viewPercentual);
    return {
      chartArea: {
        background: 'transparent',
        height: 300
      },
      legend: {
        position: 'bottom',
        visible: true
      },
      series: [
        {
          categoryField: 'key',
          field: 'value'
        }
      ],
      seriesDefaults: {
        donut: {
          labels: {
            background: 'transparent',
            position: 'outsideEnd',
            template: labelTemplate,
            visible: true
          }
        },
        overlay: {
          gradient: 'none'
        },
        type: 'donut',
        holeSize: 60,
        visual: e => {
          center = e.center;
          radius = e.radius;
          return e.createVisual();
        }
      },
      tooltip: {
        background: '#f9f9f9',
        border: {
          width: 1,
          color: '#8a91ae'
        },
        template: tooltipTemplate,
        visible: true
      },
      transitions: false,
      render: e => {
        renderCenterValue(e, center, radius);
      }
    };
  }

  getTemplates(viewPercentual) {
    let labelTemplate;
    let tooltipTemplate;
    if (viewPercentual) {
      labelTemplate = '#= kendo.format("{0:p1}", percentage)#';
      tooltipTemplate = '#= category # - #= kendo.format("{0:p1}", percentage)#';
    } else {
      labelTemplate = '#= kendo.format("{0:n1}", value)#';
      tooltipTemplate = '#= category # - #= kendo.format("{0:n1}", value)#';
    }
    return {
      labelTemplate: labelTemplate,
      tooltipTemplate: tooltipTemplate
    };
  }

  renderCenterValue(e, center, radius) {
    const circleGeometry = new kendo.geometry.Circle(center, radius);
    const bbox = circleGeometry.bbox();

    const value = e.sender.dataSource.data()[0].centerValue;
    const text = new kendo.drawing.Text(value, [0, 0], {
      font: '14px Open Sans'
    });

    kendo.drawing.align([text], bbox, 'center');
    kendo.drawing.vAlign([text], bbox, 'center');

    e.sender.surface.draw(text);
  }
}
