<div
  *ngFor="let quantity of (meters$ | async); trackBy: trackByMethod"
  class="card"
>
  <div
    class="quantity-icon-container"
    [style.background]="quantity.quantityId | quantityColor"
  >
    <div class="header-icon header-icon--bg-round header-icon--bg-white">
      <quantity-icon [quantity]="quantity.quantityId"></quantity-icon>
    </div>
    <h3>
      <span *ngIf="quantity.isNormalized">
        {{ 'FACILITIES.NORMALIZED' | translate  }}&nbsp;
      </span>
      <span>
        {{ quantity.quantityId | quantityName | async }}
      </span>
      <span *ngIf="searchParams$ | async as params">
        ({{ quantity.quantityId | quantityUnit:$any(params.unit) | async }})
      </span>
    </h3>
  </div>
  <kendo-treelist
    [kendoTreeListHierarchyBinding]="quantity.treeListConfig.treeListData"
    kendoTreeListExpandable
    [expandedKeys]="collapsedMeters"
    [initiallyExpanded]="true"
    childrenField="subMeters"
    idField="id"
    [sortable]="true"
    [sort]="(gridSorts$ | async)?.[quantity.quantityId]"
    [resizable]="true"
    [filterable]="'menu'"
    [filter]="(gridFilters$ | async)?.[quantity.quantityId]"
    [aggregate]="quantity.treeListConfig.aggregates"
    (columnVisibilityChange)="columnChange($event, quantity.quantityId)"
    (filterChange)="filterChange($event, quantity.quantityId)"
    (sortChange)="sortChange($event, quantity.quantityId)"
  >
    <ng-template kendoTreeListToolbarTemplate>
      <kendo-grid-grouped-column-chooser></kendo-grid-grouped-column-chooser>
      <button kendoTreeListExcelCommand>{{ 'ADMIN.GET_EXCEL' | translate }}</button>
    </ng-template>
    <kendo-treelist-column
      field="meterTreeVisibleName"
      [expandable]="true"
      [width]="270"
      [locked]="true"
      [includeInChooser]="false"
      [title]="'METERS.METERS' | translate"
    >
      <ng-template
        kendoTreeListFooterTemplate
        let-aggregates="aggregates"
        let-column="column"
        let-parentItem="parentItem"
      >
        <div class="aggregate-titles">
          <div>{{ 'FACILITIES_REPORT.SUM' | translate }} ({{ aggregates[column.field].count }})</div>
          <ng-container *ngIf="!parentItem">
            <div>{{ 'FACILITIES_REPORT.MIN' | translate }}</div>
            <div>{{ 'FACILITIES_REPORT.MAX' | translate }}</div>
            <div>{{ 'FACILITIES_REPORT.AVERAGE' | translate }}</div>
          </ng-container>
        </div>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column
      [title]="''"
      [width]="hasMeterUpdateAccess?48:26"
      [resizable]="false"
      [includeInChooser]="false"
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <button *ngIf="hasMeterUpdateAccess" 
          (click)="editMeter(dataItem)" 
          class="button button--no-padding button--link" 
          [style.margin-right.px]="5">
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button (click)="editTags(dataItem)" class="button button--no-padding button--link">
          <i class="fas fa-tag"></i>
        </button>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column
      field="description"
      [width]="150"
      [title]="'FACILITIES_REPORT.DESCRIPTION' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.description?.hidden ?? true"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="meterTypeName"
      [width]="150"
      [title]="'FACILITIES_REPORT.METERINGTYPE' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.meterTypeName?.hidden ?? true"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="factor"
      [width]="150"
      [title]="'FACILITIES_REPORT.METERINGFACTOR' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.factor?.hidden ?? true"
      [filter]="'numeric'"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="id"
      [width]="150"
      [title]="'METERS.ENEGIA_METER_ID' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.id?.hidden ?? true"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="name"
      [width]="150"
      [title]="'METERS.METERS' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.name?.hidden ?? true"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="customerMeterIdentifier"
      [width]="150"
      [title]="'FACILITIES_REPORT.CUSTOMERMETERIDENTIFIER' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.customerMeterIdentifier?.hidden ?? true"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="measurementMethod"
      [width]="150"
      [title]="'METERS.MEASUREMENT_METHOD.TITLE' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.measurementMethod?.hidden ?? true"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="automaticReadingStartTime"
      [width]="120"
      [title]="'AUTOMATIC_READING_START_TIME' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.automaticReadingStartTime?.hidden ?? true"
      format="d"
      filter="date"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="automaticReadingEndTime"
      [width]="120"
      [title]="'AUTOMATIC_READING_END_TIME' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.automaticReadingEndTime?.hidden ?? true"
      format="d"
      filter="date"
    ></kendo-treelist-column>
    <kendo-treelist-column
      field="deactivationTime"
      [width]="120"
      [title]="'DEACTIVATION_TIME' | translate"
      [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.deactivationTime?.hidden ?? true"
      format="d"
      filter="date"
    ></kendo-treelist-column>
    <kendo-treelist-column-group *ngIf="quantity.treeListConfig.uniqueTags.length" [title]="'TAGS.TITLE' | translate">
      <ng-container *ngFor="let tag of quantity.treeListConfig.uniqueTags">
        <kendo-treelist-column
          [width]="120"
          filter="boolean"
          [field]="'tags.'+tag.tagId"
          [title]="tag.tagName"
          [hidden]="(meterInfoColumns$ | async)?.[quantity.quantityId]?.['tags.'+tag.tagId]?.hidden ?? true"
        >
          <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
            <boolean-mark  [value]="dataItem | propertyPath: column?.field"></boolean-mark>
          </ng-template>
        </kendo-treelist-column>
      </ng-container>
    </kendo-treelist-column-group>
    <ng-container *ngFor="let columnGroup of quantity.treeListConfig.treeListColumns">
      <ng-container *ngFor="let group of columnGroup.series">
        <kendo-treelist-column
          *ngFor="let serie of group"
          [title]="serie.title"
          [field]="serie.field + '.value'"
          [format]="serie | reportingGridValueFormat:(searchParams$ | async)?.resolution | async"
          [width]="120"
          [includeInChooser]="false"
          [class]="'cell-number'"
          [footerClass]="'cell-number'"
          [filter]="'numeric'"
        >
          <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
            <ng-container *ngIf="dataItem | propertyPath:serie.field as consumption">
              <value-flag-cell
                [consumption]="consumption"
                [visibleValue]="consumption.value"
                [format]="column.format"
              ></value-flag-cell>
            </ng-container>
          </ng-template>
          <ng-template
            kendoTreeListHeaderTemplate
            let-column="column"
          >
            <report-column-header
              [title]="column.title"
              [color]="serie.color"
            ></report-column-header>
          </ng-template>
          <ng-template
            kendoTreeListFooterTemplate
            let-aggregates="aggregates"
            let-column="column"
            let-parentItem="parentItem"
          >
          <meter-table-report-aggregates [aggregates]="aggregates" [column]="column" [parentItem]="parentItem">
          </meter-table-report-aggregates>
          </ng-template>
        </kendo-treelist-column>
        <ng-container *ngIf="searchParams$ | async as params">
          <ng-container *ngFor="let serie of group">
            <ng-container *ngIf="serie.showChange">
              <kendo-treelist-column
                *ngIf="params?.change?.relative"
                [format]="'p1'"
                [field]="serie.field + '.' + relativeChangeKey"
                [width]="120"
                [includeInChooser]="false"
                [class]="'cell-number'"
                [filter]="'numeric'"
                [footerClass]="'cell-number'"
              >
                <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
                  <ng-container *ngIf="dataItem | changeValueFlagAggregate:column.field:2 as flagAggregates">
                    <value-flag-cell
                      [consumption]="flagAggregates"
                      [visibleValue]="dataItem | propertyPath:column.field"
                      [format]="column.format"
                    ></value-flag-cell>
                  </ng-container>
                </ng-template>
                <report-change-column-header
                  *kendoTreeListHeaderTemplate="let column = column"
                  [color]="serie.color"
                  [comparisonColor]="serie.comparisonColor"
                  [quantityId]="serie.quantityId"
                  [title]="column.title"
                  [changeKey]="'%'"
                ></report-change-column-header>
                <ng-template
                  kendoTreeListFooterTemplate
                  let-aggregates="aggregates"
                  let-column="column"
                  let-parentItem="parentItem"
                >
                <meter-table-report-aggregates [aggregates]="aggregates" [column]="column" [parentItem]="parentItem">
                </meter-table-report-aggregates>
                </ng-template>
              </kendo-treelist-column>
              <kendo-treelist-column
                *ngIf="params?.change?.absolute"
                [field]="serie.field + '.' + absoluteChangeKey"
                [width]="120"
                [format]="serie.quantityId | valueFormat:serie.unitKey:params.resolution:serie.derivedId | async"
                [includeInChooser]="false"
                [class]="'cell-number'"
                [footerClass]="'cell-number'"
                [filter]="'numeric'"
              >
                <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
                  <ng-container *ngIf="dataItem | changeValueFlagAggregate:column.field:2 as flagAggregates">
                    <value-flag-cell
                      [consumption]="flagAggregates"
                      [visibleValue]="dataItem | propertyPath:column.field"
                      [format]="column.format"
                    ></value-flag-cell>
                  </ng-container>
                </ng-template>
                <report-change-column-header
                  *kendoTreeListHeaderTemplate="let column = column"
                  [color]="serie.color"
                  [comparisonColor]="serie.comparisonColor"
                  [quantityId]="serie.quantityId"
                  [title]="column.title"
                ></report-change-column-header>
                <ng-template
                  kendoTreeListFooterTemplate
                  let-aggregates="aggregates"
                  let-column="column"
                  let-parentItem="parentItem"
                >
                  <meter-table-report-aggregates [aggregates]="aggregates" [column]="column" [parentItem]="parentItem">
                  </meter-table-report-aggregates>
                </ng-template>
              </kendo-treelist-column>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <kendo-treelist-excel fileName="meters.xlsx"></kendo-treelist-excel>
  </kendo-treelist>
</div>