<modal-view-header
  [headingText]="propertyName"
></modal-view-header>
<div
  *ngIf="(facilityProperties$ | async) as facilityProperties"
  class="modal-body"
>
  <div class="buttonBar">
    <button
      type="button" class="button button--primary"
      [disabled]="rowInEdit === 0"
      (click)="addNewProperty(facilityProperties)"
    >
      {{ 'FACILITY.EDIT_FORM.ADDNEWESTPROPERTY' | translate }}
    </button>
  </div>
  <div class="container">
    <form [formGroup]="propertyForm">
      <table>
        <tr>
          <th>{{ 'FACILITY.STARTING' | translate }}</th>
          <th class="valueCol">{{ 'FACILITY.VALUE' | translate }}</th>
          <th class="actionsCol">{{ 'GROUPS.FUNCTIONS' | translate }}</th>
        </tr>
        <tr *ngFor="let property of facilityProperties; let index = index">
          <td>
            <ng-container *ngIf="rowInEdit === index && !property.id; else readonlyDate">
              <kendo-datepicker
                [min]="getMinDate(facilityProperties, index)"
                [max]="getMaxDate(facilityProperties, index)"
                formControlName="date"
              ></kendo-datepicker>
            </ng-container>
            <ng-template #readonlyDate>
              {{ property?.fromDate | kendoDate: 'd' }}
            </ng-template>
          </td>
          <ng-container *ngIf="(modifiedPropertyIds$ | async) as modifiedIds">
            <td [class.modifiedProperty]="(!property?.id || modifiedIds[property?.id])">
              <div class="valueContainer" *ngIf="rowInEdit === index; else readonlyValue">
                <ng-container *ngIf="propertyInfo?.type !== PropertyType.Enum">
                  <kendo-numerictextbox
                    [placeholder]="'FACILITY.EDIT_FORM.ADDNEWVALUE' | translate"
                    formControlName="value"
                  ></kendo-numerictextbox>
                </ng-container>
                <ng-container *ngIf="propertyInfo?.type === PropertyType.Enum">
                  <ek-combo
                    [items]="enumDropdown"
                    formControlName="value"
                  ></ek-combo>
                </ng-container>
                <ng-container *ngIf="propertyInfo?.type === PropertyType.CustomType">
                  <ek-combo
                    [items]="customTypeDropdown"
                    formControlName="dropdown"
                  ></ek-combo>
                </ng-container>
              </div>
              <ng-template #readonlyValue>
                <ng-container
                  *ngIf="propertyInfo?.type === PropertyType.Decimal ||
                        propertyInfo?.type === PropertyType.QuantityDecimal"
                >
                  {{ property?.value }}
                </ng-container>
                <ng-container *ngIf="propertyInfo?.type === PropertyType.Enum">
                  {{ enumTranslations[property?.value] | translate }}
                </ng-container>
                <ng-container *ngIf="propertyInfo?.type === PropertyType.CustomType">
                  {{ property?.value }}, {{ shareOfTemperatureFixingTranslations[property.customType] | translate }}
                </ng-container>
              </ng-template>
            </td>
          </ng-container>
          <td>
            <ng-container *ngIf="rowInEdit === index; else normalActions">
              <button
                type="submit" class="button"
                [disabled]="!propertyForm.valid"
                (click)="property.value ? saveChanges(property) : saveNewProperty()"
              >
                <i class="fas fa-check"></i>
              </button>
              <button
                type="button" class="button button--negative"
                (click)="cancelOperation(facilityProperties, property.value !== null)"
              >
                <i class="fas fa-ban"></i>
              </button>
            </ng-container>
            <ng-template #normalActions>
              <button
                type="button" class="button"
                [disabled]="rowInEdit !== undefined"
                (click)="editProperty(property, index)"
              >
                <i class="fas fa-pencil-alt"></i>
              </button>
              <button
                type="button" class="button button--negative"
                (click)="removeProperty(facilityProperties, index)"
              >
                <i class="fa fa-trash-alt"></i>
              </button>
              <button
                type="button" class="button"
                [disabled]="rowInEdit !== undefined || !checkDateRange(facilityProperties, index)"
                (click)="addNewProperty(facilityProperties, index + 1)"
              >
                <i class="fa fa-plus"></i>
              </button>
            </ng-template>
          </td>
        </tr>
      </table>
    </form>
  </div>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'MODALS.CLOSE' | translate }}
    </button>
  </div>
</div>