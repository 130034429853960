import _ from 'lodash';
import { from } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { ServiceLevel } from '@enerkey/clients/facility';

const $inject = [
  '$scope', 'quantities', 'appStatusService', 'erReportSettingsService', 'erStateService', 'serviceLevelService'
];

function FacilitiesSidebarQuantitiesController(
  $scope, quantities, appStatusService, erReportSettingsService, erStateService, serviceLevelService
) {
  const vm = this;
  const hasServiceLevelMedium = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);

  vm.data = {
    loading: false,
    groupedQuantities: []
  };

  const erReportSettings = erReportSettingsService.getInstance();
  erReportSettings.setCallback(() => {
    vm.selectedQuantityId = erReportSettings.getSettings().quantityId;
  });

  function initialize() {
    vm.data.loading = true;
    getSelectedQuantityIds()
      .pipe(
        finalize(() => {
          vm.data.loading = false;
        })
      ).subscribe(quantityIds => {
        vm.selectedQuantityId = quantityIds;
        vm.onSelectedQuantitiesChanged();
      });
  }

  function _inModal_() {
    // appStatusService.inModal check might be unnecessary in the future
    return erStateService.isModalOpen() || appStatusService.inModal;
  }

  function getSelectedQuantityIds() {
    const facilityIds = _.get(erReportSettings.getSettings(), 'facilityId', []);
    const quantityIds = _.get(erReportSettings.getSettings(), 'quantityId', []);
    if (
      _inModal_() &&
        (facilityIds.length === 1 || !hasServiceLevelMedium) &&
        !Array.hasItems(quantityIds)
    ) {
      const facilityId = facilityIds[0];
      return from(quantities.getGroupedQuantitiesForFacility(facilityId)).pipe(
        map(groups => groups.flatMap(group => group.quantities)),
        map(facilityQuantities => facilityQuantities.map(q => q.ID))
      );
    } else {
      // HACK: get all quantities to ensure that loading spinner is shown on sidebar
      return quantities.getAllQuantities().pipe(
        map(() => quantityIds)
      );
    }
  }

  vm.onSelectedQuantitiesChanged = function() {
    erReportSettings.changeSetting('quantityId', vm.selectedQuantityId);
  };

  // watch for facilityId change in modal and initialize after that
  // otherwise just init right away
  const facilityIds = _.get(erReportSettings.getSettings(), 'facilityId', []);
  if (_inModal_()) {
    if (facilityIds.length === 1) {
      vm.facilityId = facilityIds[0];
    }
    if (facilityIds.length === 0) {
      $scope.stateParams = erReportSettings.getSettings();
      const facilityIdListener = $scope.$watch('stateParams.facilityId', facilityId => {
        if (_.isArray(facilityId) && facilityId.length) {
          initialize();
          facilityIdListener();
        }
      });
    } else {
      initialize();
    }
  } else {
    initialize();
  }
}

FacilitiesSidebarQuantitiesController.$inject = $inject;

export default FacilitiesSidebarQuantitiesController;
