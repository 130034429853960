import _ from 'lodash';
import { ConsumptionTargetTypeId } from '../constants/consumption-target-constant';

consumptionTargets.$inject = ['$q', '$http', 'utils', 'httpConfigService'];

function consumptionTargets($q, $http, utils, httpConfigService) {

  var getYear = function(yearObj, seriesType) {
    if (seriesType === ConsumptionTargetTypeId.BaseLevel ||
        seriesType === ConsumptionTargetTypeId.PlannedLevel) {
      // For planned and base level the year does not matter, it just uses the same API as other series types.
      // Set the year to the maximum allowed to make this pass the validation on the server. Could this be done
      // otherwise? Yeah, you could have different endpoints for targeta/b and planned/base level, but in the end
      // they are saved to time series database which requires some timestamp anyway. And also that would mean switching
      // the endpoint based on what user has selected. Also, there will be other types as well so those might need
      // endpoints of their own, etc. I think this is a good compromise for now.
      return 2262;
    } else if (_.has(yearObj, 'year')) {
      return yearObj.year;
    } else {
      utils.popUp('info', 'choose year');

      throw new Error('Year was not selected');
    }
  };

  var getConsumptionTargets = function(yearObj, meterIds, seriesType) {
    var deferred = $q.defer();
    // eslint-disable-next-line no-undef
    $http.post(ENERKEY_CONFIG.apiEnergyreporting + '/api/v1/energymanagement/targets/' + getYear(yearObj, seriesType) + '/' + seriesType,
        meterIds, httpConfigService.getExtendedHttpConfig())
      .then(function(response) {
        deferred.resolve(_.get(response, 'data'));
      })
      .catch(function(response) {
        deferred.resolve(_.get(response, 'status'));
      })
    ;
    return deferred.promise;
  };

  var setConsumptionTargets = function(yearObj, seriesType, payload) {
    // eslint-disable-next-line no-undef
    return $http.post(ENERKEY_CONFIG.apiEnergyreporting + '/api/v1/energymanagement/targets/persist/' + getYear(yearObj, seriesType) + '/' + seriesType,
      payload, httpConfigService.getExtendedHttpConfig());
  };

  var getConsumptionTargetsWithSeriesType = function(seriesType, payload) {
    return $http.post(
    // eslint-disable-next-line no-undef
      ENERKEY_CONFIG.apiEnergyreporting + '/api/v1/energymanagement/targets/?targetSeriesType=' + seriesType,
      payload,
      httpConfigService.getExtendedHttpConfig()
    );
  };

  return {
    getConsumptionTargets: getConsumptionTargets,
    setConsumptionTargets: setConsumptionTargets,
    getConsumptionTargetsWithSeriesType: getConsumptionTargetsWithSeriesType
  };
}

export default consumptionTargets;
