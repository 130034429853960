import MultiSelectComponent from './multi-select-component';
import ServiceModule from '../../services';

const dependencies = [
  ServiceModule.name
];

const MultiSelectModule = angular
  .module('app.components.multi-select', dependencies)
  .component('multiSelect', MultiSelectComponent);

export default MultiSelectModule;
