import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const apiExportTopbarState: UIStateNg2 = {
  name: 'api-export',
  data: {
    auth: {
      defaultTenantOnly: true,
      roles: [Roles.TOOL_MANAGER],
    },
    translationKey: 'Api Export',
  }
};
