import _ from 'lodash';
import { ErReportType } from '../constants/er-report-type';

const $inject = [
  '$q', '$scope', '$state', 'ERDataService2', 'ERReportType', 'ERUtils', 'consumptions', 'weather',
  'utils', 'forecastTypes', 'ERDataChartService', 'AdditionalSettingService',
  'ConsumptionTargetDataService', 'erReportSettingsService', 'modalService'
];

function FacilitiesReportSumController(
  $q, $scope, $state, ERDataService2, ERReportType, ERUtils, consumptions, weather,
  utils, forecastTypes, ERDataChartService, AdditionalSettingService,
  ConsumptionTargetDataService, erReportSettingsService, modalService
) {

  $scope.errorTextKey = 'FACILITIES_REPORT.NO_DATA_FOR_SUM_REPORT';
  $scope.reportData = {
    graphData: [],
    tableData: {
      gridOptions: {},
      gridContent: {
        hidden: false,
        modified: false
      }
    }
  };

  $scope.regenerateData = _.debounce(() => {
    $scope.parentInitialized()
      .then(() => {
        $scope.facilities = $scope.getSelectedFacilities();
        const series = getSeries();
        const payload = getPayload(series);
        return getData(series, payload);
      })
      .catch(err => {
        utils.trackError(err, 'FacilitiesReportSumController regenerate');
        $scope.handleError(err);
      })
    ;
  }, $scope.defaultTimer);

  $scope.openModal = facilityId => {
    const reportParams = {
      facilityId: [facilityId],
      quantityId: $scope.params.quantityId,
      series: $scope.series,
      unitKey: $scope.params.unitKey,
      cahngeType: $scope.params.changeType
    };
    const modalParams = {
      reportParams: reportParams
    };
    erReportSettingsService.setActiveInstance('er-modal-report');
    modalService.getModalWithComponent('report-modal', modalParams);
  };

  function getSeries() {
    // sum does not want relational and related
    const seriesForReport = _.assign({}, $scope.params.series, {});
    const start = _.get($scope.params, 'series.Start');
    if ($state.$current.data.reportType === ERReportType.TREND_REPORT && start.length) {
      seriesForReport.Start = [_.last(start)];
    }
    return AdditionalSettingService.filterDisabled(seriesForReport);
  }

  function getPayload(series) {

    if ($scope.validateSeries(series) && $scope.params.facilityId.length && $scope.params.quantityId.length) {
      return {
        Quantities: _.chain($scope.params.quantityId)
          .reduce((result, quantityId) => {
            const quantity = $scope.getQuantity(quantityId);
            if (quantity) {
              result.push({
                ID: quantityId,
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization,
                Comparables: series.Comparables || 'None',
                RelatedValues: series.RelatedValues,
                RelationalUnitIds: series.RelationalUnitIds
              });
            }
            return result;
          }, [])
          .value(),
        Resolution: series.Resolution,
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        FacilityId: $scope.params.facilityId,
        Unit: $scope.params.unitKey,
        AggregateType: 'sum'
      };
    }

    return undefined;
  }

  function getData(series, payload) {
    const deferred = $q.defer();
    $scope.errorTextKey = $scope.getSeriesErrorTextKey(series);

    if (!$scope.errorTextKey) {
      getSumReportData(series, payload)
        .then(data => {
          createSumReport(data, series, payload);
        })
        .catch(() => {
          deferred.reject();
        })
        .finally(() => {
          deferred.resolve();
        })
      ;
    }

    return deferred.promise;
  }

  function getSumReportData(series, payload) {
    if (!payload) {
      return $q.resolve({});
    }

    const deferred = $q.defer();
    let promises = [];

    if ($scope.params.reportType !== ErReportType.FORECAST_REPORT) {
      promises.push(getConsumptionData(series, _.merge({}, payload,
        { ReportType: $state.$current.data.reportType })));
    } else { // Forecast sum report
      promises.push(getConsumptionData(series, _.merge({}, payload, { Cumulative: true })));

      _.each(forecastTypes.getForecastTypeIds(), forecastTypeId => {
        const parameters = {
          ReportType: $state.$current.data.reportType,
          ForecastType: forecastTypeId,
          Cumulative: true
        };

        promises.push(getConsumptionData(series, _.merge({}, payload, parameters)));
      });
    }

    if (_.get(series, 'Temperature') && $scope.params.reportType !== ErReportType.FORECAST_REPORT) {
      promises.push(getWeatherData(series, payload));
    }

    promises = promises.concat(ConsumptionTargetDataService.getData(
      series,
      _.merge({}, payload, { Cumulative: $scope.params.reportType === ErReportType.FORECAST_REPORT })
    ));

    function onSuccess(result) {
      deferred.resolve(result);
    }

    function onError() {
      deferred.reject();
    }

    $q.all(promises)
      .then(onSuccess)
      .catch(onError)
    ;

    return deferred.promise;
  }

  function getConsumptionData(series, payload) {
    const deferred = $q.defer();

    consumptions.getAggregateConsumptions(payload)
      .then(response => {
        deferred.resolve({
          requestData: payload,
          responseData: response
        });
      })
      .catch(() => {
        deferred.reject();
      })
    ;

    return deferred.promise;
  }

  function getWeatherData(series, payload) {
    const deferred = $q.defer();

    const facilities = _.map(payload.FacilityId, facilityId => $scope.cache.facilitiesById[facilityId]);

    const payloadForWeather = weather.getPayload(
      _.pick(series, ['TimeFrame', 'Resolution', 'Start']),
      facilities
    );

    if (!payloadForWeather) {
      return $q.resolve();
    }

    const quantities = _.map(_.map(payload.Quantities, 'ID'), quantityId => $scope.cache.quantitiesById[quantityId]);

    weather.getWeatherByQuantities(payloadForWeather, quantities)
      .then(response => {
        deferred.resolve({
          requestData: payloadForWeather,
          responseData: response.data
        });
      })
    ;

    return deferred.promise;
  }

  function createSumReport(data, series, payload) {
    const graphsCreatedOrUpdated = [];

    const options = {
      idType: 'Quantity',
      series: series,
      params: $scope.params,
      cache: $scope.cache,
      api: data,
      show: {
        distributionAndValue: false
      },
      chart: {
        getCurrentChart: function(options2, data2) {
          const chart = ERDataChartService.getCurrentChart($scope.reportData, payload, options2, data2);
          const isAdded = ERDataChartService.findChart($scope.reportData, options2, data2);

          if (!isAdded) {
            $scope.reportData.graphData.push(chart);
          }

          graphsCreatedOrUpdated.push(chart); // Copied from normal reports

          return chart;
        },
        serieClick: {
          enabled: true
        },
        separateQuantityProperties: $scope.params.reportType === ErReportType.FORECAST_REPORT
      },
      grid: {
        getCurrentGrid: function() {
          return $scope.reportData.tableData;
        },
        aggregates: $scope.params.reportType === ErReportType.FORECAST_REPORT ? [] : ['sum', 'min', 'max', 'average'],
        excel: {
          getTitle: function() {
            return utils.localizedString('FACILITIES.SUM_REPORT');
          },
          postProcessWorkbook: function(workbook) {
            const sheets = _.get(workbook, 'sheets');
            let sheet;
            if (_.isArray(sheets) && sheets.length) {
              sheet = workbook.sheets[0];
              const facilityIds = _.get(payload, 'FacilityId');
              const newRows = [
                {
                  cells: [
                    { value: ERUtils.getLocalizedReportNameByReportType($state.$current.data.reportType),
                      bold: true, fontSize: 13 }
                  ]
                },
                {
                  cells: [
                    {
                      value: `${utils.localizedString('FACILITIES.FACILITIES')} (${_.get(facilityIds, 'length')})`,
                      bold: true
                    },
                    {
                      value: _.chain(facilityIds)
                        .reduce((result, facilityId) => {
                          const facility = _.get($scope.cache.facilitiesById, facilityId);
                          if (facility) {
                            result.push(_.get(facility, 'Name'));
                          }
                          return result;
                        }, [])
                        .value()
                        .join(', ')
                    },
                  ]
                },
                {
                  cells: [
                    { value: '' }
                  ]
                },
              ];

              sheet.rows = newRows.concat(sheet.rows);
            }

            // modify freezepane and check width for first column
            sheet.freezePane = {
              rowSplit: 1,
              colSplit: 1
            };
            if (sheet.columns.length) {
              sheet.columns[0].width = Math.max(sheet.columns[0].width, 150);
            }
          }
        }
      }
    };

    ERDataService2.getVisuals(options);

    // remove charts that where not created/updated
    if (options.chart.separateQuantityProperties) {
      $scope.reportData.graphData = _.filter(
        $scope.reportData.graphData,
        graph => _.includes(graphsCreatedOrUpdated, graph)
      );
    }

    // post process and remove empty graphs
    $scope.reportData.graphData = _.filter(
      $scope.reportData.graphData,
      graph => _.get(graph, 'chartOptions.series', []).length
    );
  }

  $scope.$on('facilities.paramsChanged', (event, paramsData) => {
    const interestedParams = _.without(_.get(paramsData, 'changedParams'), 'sections');
    if (interestedParams.length || _.get(paramsData, 'forceUpdate')) {
      if (_.includes(interestedParams, 'quantityId')) {
        // remove unnecessary charts and columns
        const currentQuantityCount = _.get($scope, 'params.quantityId', []).length;
        $scope.reportData.graphData = _.filter(
          $scope.reportData.graphData,
          chart => _.includes($scope.params.quantityId, chart.id)
        );
        $scope.reportData.tableData.gridOptions.columns = _.filter($scope.reportData.tableData.gridOptions.columns,
          column => {
            if (column.field === 'Weather' && !currentQuantityCount) {
              return false;
            }
            return column.quantityId ? _.includes($scope.params.quantityId, column.quantityId) : true;
          });
        if (_.difference(_.get(paramsData, 'oldParams.quantityId'), $scope.params.quantityId).length) {
          // quantities was just removed, so nothing to do
          return;
        }
      }

      $scope.$evalAsync($scope.regenerateData);
    }
  });

  // initialize
  $scope.regenerateData();
}

FacilitiesReportSumController.$inject = $inject;

export default FacilitiesReportSumController;
