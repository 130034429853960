import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalBase, ModalOptions } from '@enerkey/foundation-angular';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { OperationResult, ProvisioningStatus } from '@enerkey/clients/provisioning';
import { FileDownloadService } from '../../../shared/services/file-download.service';

@Component({
  selector: 'provisioning-details',
  templateUrl: './provisioning-details.component.html',
  styleUrls: ['./provisioning-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ windowClass: 'semiFull' })
export class ProvisioningDetailsComponent extends ModalBase {
  @Input() public provisioning: ProvisioningStatus;
  @Input() public tenantId: number;

  public readonly OperationResult = OperationResult;

  public constructor(
    private fileDownloadService: FileDownloadService,
    activeModal: NgfActiveModal
  ) {
    super(activeModal);
  }

  public downloadJson(): void {
    const fileName = `provisioning-${this.provisioning.instanceId}.json`;
    this.fileDownloadService.downloadAsJsonFile(this.provisioning.inputData, fileName);
  }
}
