import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { indicate } from '@enerkey/rxjs';

import { SpecificConsumptionConfiguration } from '@enerkey/clients/energy-reporting';

import { EkMultiComponent } from '../../../../shared/ek-inputs/ek-multi/ek-multi.component';
import { RelationalValuesService } from '../../../../shared/services/relational-values.service';

type DerivedValueType = 'specificConsumption' | 'cost' | 'emission';

@Component({
  selector: 'derived-value-select',
  templateUrl: '../../../../shared/ek-inputs/multi-select/multi-select.component.html',
  styleUrls: ['../../../../shared/ek-inputs/multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DerivedValueSelectComponent,
    multi: true
  }]
})
export class DerivedValueSelectComponent extends EkMultiComponent implements OnDestroy, OnInit {
  @Input() public derivedValueType: DerivedValueType;
  public disableWhenLoading = true;
  public noTranslate = true;

  private readonly itemsLoaded$ = new ReplaySubject<void>(1);
  private readonly destroy$ = new Subject<void>();

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private readonly relationalValues: RelationalValuesService
  ) {
    super(changeDetectorRef);

  }

  public ngOnInit(): void {
    this.getDerivedValues()
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        indicate(this._loading)
      )
      .subscribe({
        next: values => {
          this.items = values.map(v => ({
            text: v.Name,
            value: v.Id
          }));

          this.itemsLoaded$.next();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getDerivedValues(): Observable<SpecificConsumptionConfiguration[]> {
    switch (this.derivedValueType) {
      case 'specificConsumption':
        return this.relationalValues.specificConsumptions$;
      case 'cost':
        return this.relationalValues.costs$;
      case 'emission':
        return this.relationalValues.emissions$;
      default:
        return this.relationalValues.relationalValues$;
    }
  }
}
