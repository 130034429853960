import MultiSelectController from './multi-select-controller';
import template              from 'raw-loader!./multi-select.html';

const MultiSelectComponent = {
  template: template,
  controllerAs: 'vm',
  controller: MultiSelectController,
  bindings: {
    items: '<',
    selectedItems: '<',
    selectionMode: '@',
    onChange: '&',
    markSelectedBy: '@',
    buttonLabel: '@',
    inModal: '<',
    helperElements: '@'
  }
};

export default MultiSelectComponent;
