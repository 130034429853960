export const SPREADSHEET_SELECTOR = '#spreadsheet';
export const READING_START_ROW = 3;
export const DEFAULT_ROW_COUNT = 1000;
export const DEFAULT_SHEET_NAME = 'data';
export const FROZEN_ROWS = 2;
export const DEFAULT_CELL_FORMAT = '0.0000';
export const COLUMNS = {
  ACTUAL_TIMESTAMP: 0,
  ACTUAL_CUMULATIVE: 1,
  ACTUAL_CONSUMPTION: 2,
  MODEL_CUMULATIVE: 3,
  MODEL_CONSUMPTION: 4,
  PREVIEW_CUMULATIVE: 5,
  PREVIEW_CONSUMPTION: 6,
  COMPARISON_CONSUMPTION: 7,
  COMPARISON_TIMESTAMP: 8
};

export const HEADER_BORDER_SIZE = 3;
export const DEFAULT_TEXT_ALIGN = 'left';
