import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { mergeWith, take, takeUntil, tap } from 'rxjs/operators';

import {
  MeterGroupCreateDto, MeterGroupDto, MeterGroupMetadataUpdateDto, MeterGroupMetersUpdateDto, MeterGroupMeterUpdateDto,
  MeteringClient
} from '@enerkey/clients/metering';

import { UserService } from '../../../../services/user-service';
import { CacheManagerObservable } from '../../../../shared/utils/cache/cache.manager.util';
import { MeterGroup } from '../../models/meter-groups.model';

@Injectable({ providedIn: 'root' })
export class MeterGroupsService implements OnDestroy {
  public invalidateCache$: Observable<void>;
  public meterGroupCreate$: Observable<MeterGroupDto>;
  public meterGroupUpdate$: Observable<MeterGroupDto>;

  private readonly _meterGroupsCache: CacheManagerObservable<string, MeterGroupDto[]>;

  private readonly _invalidateCache$ = new Subject<void>();
  private readonly _meterGroupCreate$ = new Subject<MeterGroupDto>();
  private readonly _meterGroupUpdate$ = new Subject<MeterGroupDto>();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly userService: UserService
  ) {
    this._meterGroupsCache = new CacheManagerObservable<string, MeterGroupDto[]>(60 * 5);

    this.invalidateCache$ = this._invalidateCache$.asObservable();
    this.meterGroupCreate$ = this._meterGroupCreate$.asObservable();
    this.meterGroupUpdate$ = this._meterGroupUpdate$.asObservable();

    this.invalidateCache$.pipe(
      tap(_ => this._meterGroupsCache.clear()),
      takeUntil(this._destroy$)
    ).subscribe();

    this.meterGroupCreate$.pipe(
      mergeWith(this.meterGroupUpdate$),
      tap(_ => this.invalidateCache()),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getMeterGroups(meterGroupIds?: number[]): Observable<MeterGroupDto[]> {
    const cacheKey = meterGroupIds?.length ? meterGroupIds?.sort((a, b) => a - b).join(',') : 'all';
    return this._meterGroupsCache.getOrFetch(cacheKey, () => this.meteringClient.getMeterGroups(meterGroupIds));
  }

  public createMeterGroup(meterGroup: MeterGroup): Observable<MeterGroupDto> {
    const data = new MeterGroupCreateDto({
      name: meterGroup.name,
      description: meterGroup.description,
      quantityGroupId: 1,
      owningProfileId: this.userService.profileId
    });

    return this.meteringClient.createMeterGroup(data).pipe(
      take(1),
      tap(createdMeterGroup => this._meterGroupCreate$.next(createdMeterGroup)),
      takeUntil(this._destroy$)
    );
  }

  public updateMeterGroup(meterGroup: MeterGroup): Observable<MeterGroupDto> {
    return this.meteringClient.updateMeterGroupMetadata(meterGroup.id, new MeterGroupMetadataUpdateDto({
      name: meterGroup.name,
      description: meterGroup.description,
      quantityGroupId: meterGroup.quantityGroupId
    })).pipe(
      take(1),
      tap(updatedMeterGroup => this._meterGroupUpdate$.next(updatedMeterGroup)),
      takeUntil(this._destroy$)
    );
  }

  public updateMeterGroupMeters(meterGroupId: number, meters: MeterGroupMeterUpdateDto[]): Observable<MeterGroupDto> {
    return this.meteringClient.updateMeterGroupMeters(meterGroupId, new MeterGroupMetersUpdateDto({ meters })).pipe(
      take(1),
      tap(_ => this.invalidateCache()),
      takeUntil(this._destroy$)
    );
  }

  public invalidateCache(): void {
    this._invalidateCache$.next();
  }
}
