import _ from 'lodash';
import moment from 'moment';

import { roundDataSeries } from '@enerkey/ts-utils';

import TimeFrame from '../../../../services/time-frame-service';
import { ErResolutions, resolutionsFromShortestToLongest } from '../../constants/er-resolutions';
import { isAverageCost, isCost, isEmission } from '../../../reportingobjects/shared/relational-value-functions';
import { localToUtc } from '../../../../shared/date.functions';

const consumptionSeriesPaths = ['Reading.Value', 'NormalizedReading.Value', 'DistributionValues'];

const ER_CHART_VALUE_AXES = {
  DEFAULT: 'Values',
  NORMALIZED: 'Normalized',
  COST: 'cost',
  AVERAGE_COST: 'averageCost',
  EMISSION: 'co2'
};

const $inject = [
  '$rootScope', '$state', 'utils', 'timeSeries', 'ERDataFunctions', 'ERDataColumn',
  'EnergyReportingConstants', 'ColorService', 'ERUtils', 'appStatusService',
  'erReportSettingsService', 'LoadingService'
];

function ERChartService(
  $rootScope, $state, utils, timeSeries, ERDataFunctions, ERDataColumn,
  EnergyReportingConstants, ColorService, ERUtils, appStatusService,
  erReportSettingsService, LoadingService
) {

  function getCharts(options) {
    if (ERDataFunctions.isAggregated(options)) {
      if (options.chart.separateQuantityProperties) {
        return _.reduce(ERDataFunctions.getQuantityIds(options), (result, quantityId) => {
          const dataForQuantity = ERDataFunctions.getDataForQuantity(options, {}, quantityId, true);
          result[quantityId] = getQuantityCharts(options, dataForQuantity);
          return result;
        }, {});
      } else {
        return getAggregatedChartsForAllQuantities(options);
      }
    } else {
      switch (options.idType) {
        case 'Facility':
          return _.reduce(ERDataFunctions.getFacilityIds(options), (result, facilityId) => {
            result[facilityId] = [];
            _.each(ERDataFunctions.getQuantityIds(options), quantityId => {
              const dataForQuantity = ERDataFunctions
                .getDataForQuantity(options, { facilityId }, quantityId, true);
              result[facilityId].push(...getQuantityCharts(options, dataForQuantity));
            });
            return result;
          }, {});
        case 'Meter':
          return _.reduce(ERDataFunctions.getMeterIds(options), (result, meterId) => {
            result[meterId] = [];
            const quantityId = _.get(options.cache.metersById[meterId], 'QuantityId');
            if (quantityId) {
              const dataForQuantity = ERDataFunctions
                .getDataForQuantity(options, { meterId }, quantityId, true);
              result[meterId].push(...getQuantityCharts(options, dataForQuantity));
            }
            return result;
          }, {});
        case 'Quantity':
          return _.reduce(ERDataFunctions.getQuantityIds(options), (result, quantityId) => {
            const dataForQuantity = ERDataFunctions.getDataForQuantity(options, {}, quantityId, true);
            result.push(...getQuantityCharts(options, dataForQuantity));
            return result;
          }, []);
        default:
          break;
      }
    }
    return [];
  }

  function getAggregatedChartsForAllQuantities(options) {
    const consumptions = ERDataFunctions.getAggregatedConsumptions(options);
    const quantityIds = ERDataFunctions.getQuantityIds(options);
    let seriesDefinitions = [];

    _.each(_.get(options, 'api'), (api, apiIndex) => {
      seriesDefinitions = _.chain(quantityIds)
        .reduce((result, quantityId) => {
          const quantity = options.cache.quantitiesById[quantityId];
          return result.concat(quantity ? getChartSeriesDefinitions(options, quantityId, apiIndex) : []);
        }, seriesDefinitions)
        .uniq('propertyData.path')
        .value()
      ;
    });
    const data = {
      consumptions: consumptions,
      colors: ColorService.getPrimaryGraphColors(null, options.series.Start.length)
    };

    return _.reduce(seriesDefinitions, (result, seriesDefinition) => {
      const chart = createChartWithSeries(
        options, _.extend({ normalized: seriesDefinition.normalized }, data), [seriesDefinition]
      );
      if (chart.chartOptions) {
        _.each(chart.chartOptions.series, serie => {
          serie.type = 'donut';
          serie.holeSize = _.get(chart, 'chartOptions.chartArea.height') / 4;
          serie.tooltip = {
            template: "#= category # - #= kendo.format('{0:p1}', percentage)#"
          };
        });
      }
      return result.concat(chart);
    }, []);
  }

  function getQuantityCharts(options, data) {
    const charts = [];
    const seriesDefinitions = [];

    // create series definitions
    _.each(options.api, (api, apiIndex) => {
      seriesDefinitions.push(...getChartSeriesDefinitions(options, data.quantityId, apiIndex));
    });

    if (options.chart.separateQuantityProperties) {
      const seriesDefinitionsByProperty = _.reduce(seriesDefinitions, (result, seriesDefinition) => {
        const property = _.get(seriesDefinition, 'propertyData.path');
        const seriesDefinitionsForProperty = _.find(result, { property });
        if (!seriesDefinitionsForProperty) {
          result.push({
            property: property,
            seriesDefinitions: [seriesDefinition]
          });
        } else {
          seriesDefinitionsForProperty.seriesDefinitions.push(seriesDefinition);
        }
        return result;
      }, []);

      _.each(seriesDefinitionsByProperty, seriesDefinitionsForProperty => {
        const sortedConsumptions = getSortedConsumptions(options, data, seriesDefinitionsForProperty.property);
        charts.push(createChartWithSeries(
          options,
          _.extend({}, data, { consumptions: sortedConsumptions }),
          seriesDefinitionsForProperty.seriesDefinitions
        ));
      });
    } else if (options.chart.separateNormalized) {
      const measuredSeriesDefinitions = _.filter(seriesDefinitions, { normalized: false });
      if (measuredSeriesDefinitions.length) {
        charts.push(createChartWithSeries(options, _.extend({ normalized: false }, data), measuredSeriesDefinitions));
      }
      const normalizedSeriesDefinitions = _.filter(seriesDefinitions, { normalized: true });
      if (normalizedSeriesDefinitions.length) {
        charts.push(createChartWithSeries(options, _.extend({ normalized: true }, data), normalizedSeriesDefinitions));
      }
    } else {
      charts.push(createChartWithSeries(options, data, seriesDefinitions));
    }

    return charts;
  }

  function getSortedConsumptions(options, data, property) {
    let sortedConsumptions = data.consumptions;
    const sort = _.get(options.chart, 'separateQuantityProperties.sort');
    let dataFound = !sort;

    if (sort) {
      const key = ERDataFunctions.isAggregated(options) ? 'Id' : 'Timestamp';
      const sortOrder = _.get(sort, 'order') || 'asc';
      const sortByStartKey = _.get(sort, 'startKey') || _.get(_.last(options.series.Start), 'key');
      sortedConsumptions = [];
      _.each(data.consumptions, consumption => {
        const consumptionForSortedStart = _.sortBy(
          _.get(consumption, `Values["${sortByStartKey}"]`),
          c => (sortOrder === 'desc' ? -1 : 1) * _.get(c, property)
        );
        const keyOrder = _.pluck(consumptionForSortedStart, key);
        const sortedConsumption = _.reduce(options.series.Start, (result, start) => {
          if (start.key === sortByStartKey) {
            result.Values[sortByStartKey] = consumptionForSortedStart;
            dataFound = dataFound || (consumptionForSortedStart || []).length;
          } else {
            result.Values[start.key] = _.sortBy(
              _.get(consumption, `Values["${start.key}"]`),
              c => _.indexOf(keyOrder, _.get(c, key))
            );
            dataFound = dataFound || (result.Values[start.key] || []).length;
          }
          return result;
        }, { Values: {} });
        sortedConsumptions.push(sortedConsumption);
      });
    }

    return dataFound ? sortedConsumptions : [undefined];
  }

  // helper function to create chart with defined series
  function createChartWithSeries(options, data, seriesDefinitions) {

    // get data for chart and current chart
    const dataForChart = _.extend({ propertiesData: _.pluck(seriesDefinitions, 'propertyData') }, data);
    dataForChart.unit = _.get(dataForChart, 'propertiesData.0.unit') || data.unit;
    const chart = options.chart.getCurrentChart(options, dataForChart);
    const previouslyHadSeries = _.get(chart, 'chartOptions.series', []).length;

    // if there is data for chart or previously had series --> create new options
    if (hasConsumptionData(data.consumptions) || previouslyHadSeries) {
      // create chartOptions
      const chartOptions = getChartOptions(options, dataForChart, chart);

      // Change color palette for line graphs
      const forecastType = ERDataFunctions.getForecastType(options, data);
      const targetSeriesType = ERDataFunctions.getTargetSeriesType(options, data);
      if (
        (isHourResolution(options) || options.chart.separateQuantityProperties || targetSeriesType) &&
        (options.series.Start.length - 1 !== 0 || forecastType || targetSeriesType)
      ) {
        data.colors = ColorService.getAlternativeGraphColors(
          data.quantityId,
          options.series.Start.length
        );
      }

      // create series
      _.each(options.series.Start, (start, startIndex) => {
        const dataForStart = ERDataFunctions.getDataForStart(options, data, startIndex, { index: false }, true);
        _.each(seriesDefinitions, seriesDefinition => {
          const isFollowUp = startIndex === options.series.Start.length - 1;
          if (_.get(seriesDefinition, 'propertyData.followUpOnly') && !isFollowUp) {
            return;
          }
          const visible = !(
            !_.get(options, 'params.showMeasurement', true) && isReadingSeries(options, seriesDefinition)
          );
          if (visible) {
            chartOptions.series.push(...seriesDefinition.fn(options, _.extend({
              chartOptions: chartOptions,
              normalized: seriesDefinition.normalized,
              propertyData: seriesDefinition.propertyData,
              apiIndex: seriesDefinition.apiIndex
            }, dataForStart)));
          } else {
            chartOptions.valueAxis[0].labels = { color: 'transparent' };
          }
        });
      });

      // modify chartOptions once we have all the series
      postProcessChartOptions(chartOptions, options, dataForChart);

      // set chartOptions and chart to charts array
      chart[options.chart.chartOptionsProperty || 'chartOptions'] = chartOptions;
      chart.quantityId = data.quantityId;
    }

    return chart;
  }

  function isReadingSeries(options, seriesDefinition) {
    return consumptionSeriesPaths.some(path => seriesDefinition.propertyData.path.startsWith(path))
      && !options.api[seriesDefinition.apiIndex].requestData.TargetSeriesType;
  }

  function hasConsumptionData(consumptions) {
    return consumptions.some(consumption => {
      if (consumption) {
        return _.some(consumption.Values, values => values.length > 0);
      }
      return false;
    });
  }

  function getChartOptions(options, data) {
    const sharedTooltip = _.has(options, 'chart.sharedTooltip') ? options.chart.sharedTooltip : true;
    const printHeight = options.chart.printHeight || EnergyReportingConstants.Dimensions.chartHeight.print;
    const chartOptions = _.merge({
      chartArea: {
        height: options.isWidget ?
          options.chartHeight :
          EnergyReportingConstants.Dimensions.chartHeight.low,
        printHeight: printHeight,
        background: 'transparent'
      },
      seriesDefaults: {
        type: 'column',
        overlay: {
          gradient: 'none'
        },
        line: {
          markers: {
            visible: false
          },
          style: 'smooth',
          missingValues: 'gap'
        },
        area: {
          line: {
            style: 'smooth'
          },
          missingValues: 'gap'
        },
        donut: {
          labels: {
            template: '#= kendo.format("{0:p1}", percentage)#',
            position: 'outsideEnd',
            visible: true,
            background: 'transparent'
          }
        }
      },
      valueAxis: [
        {
          name: ER_CHART_VALUE_AXES.DEFAULT,
          narrowRange: false,
          visible: !options.isWidget,
          majorGridLines: {
            width: options.isWidget ? 0 : 1
          }
        }
      ],
      categoryAxis: {
        name: 'Categories',
        labels: {
          background: 'rgba(255, 255, 255, 0.75)'
        },
        axisCrossingValues: [0],
        majorGridLines: {
          width: options.isWidget ? 0 : 1
        }
      },
      tooltip: {
        visible: true,
        template: chartTooltip(options, data, data.unit, data.format, sharedTooltip),
        shared: sharedTooltip,
        background: '#f9f9f9',
        border: {
          width: 1,
          color: '#8a91ae'
        },
        margin: {
          horizontal: 50
        },
        sharedTemplate: categoryData => {
          const template = kendo.template(
            `<div class="shared-tooltip">
              <div class="shared-tooltip-title">#= number ##= title #</div>
              <table class="shared-tooltip-table">#= pointsData #</table>
            </div>`
          );
          const templateData = {
            number: '',
            title: getChartSharedTooltipTitle(categoryData),
            pointsData: ''
          };
          // sort category points so that follow up period is first
          const sortedPoints = _.sortBy(categoryData.points, point => -1 * _.get(point, 'series.index', 0));
          // add points
          _.each(sortedPoints, point => {
            if (_.isEmpty(templateData.number) && options.chart.showRunningNumberInTooltip) {
              templateData.number = `${point.categoryIx + 1}. `;
            }
            templateData.pointsData += point.series.tooltip.template(point);
          });
          return template(templateData);
        }
      },
      legend: {
        visible: !options.isWidget,
        position: 'top',
        inactiveItems: {
          markers: {
            color: '#ffffff'
          },
          labels: {
            color: '#8b92ae'
          }
        }
      },
      series: [],
      transitions: false,
      seriesClick: event => {
        let chart;
        if (LoadingService.isLoading() || !options.chart.serieClick.enabled) {
          return;
        }
        if (_.get(event, 'originalEvent.event.shiftKey')) {
          chart = event.sender;
          chart.setOptions({
            ranges: {
              categoryAxis: {
                min: chart.options.categoryAxis.min,
                max: chart.options.categoryAxis.max
              }
            }
          });
          chart.redraw();
          return;
        }
        const timeSeriesData = event.dataItem && event.dataItem.value ? event.dataItem.value.timeSeries : undefined;
        if (timeSeriesData) {
          const stateName = options.chart.serieClick.state || $state.current.name;
          timeSeries.getSupportedTimeFrames()
            .then(list => {
              if (_.contains(list, timeSeriesData.TimeFrame)) {
                const nextSeries = _.extend(_.cloneDeep(options.params.series), timeSeriesData);
                if (stateName !== $state.current.name) {
                  const stateParams = _.extend(
                    options.chart.serieClick.getParams(options, data, chart),
                    { series: nextSeries }
                  );
                  $state.go(stateName, stateParams);
                } else {
                  const erReportSettings = erReportSettingsService.getInstance();
                  erReportSettings.changeSetting('series', nextSeries);
                  $rootScope.$emit('chart.drilling', event.sender);
                }
              }
            });
        }
      },
      plotAreaClick: event => {
        if (_.get(event, 'originalEvent.event.shiftKey')) {
          const chart = event.sender;
          chart.setOptions({
            ranges: {
              categoryAxis: {
                min: chart.options.categoryAxis.min,
                max: chart.options.categoryAxis.max
              }
            }
          });
          chart.redraw();
        }
      },
      legendItemClick: event => {
        const field = event.series.field;
        if (field === 'minimum' || field === 'maximum') {
          const chart = event.sender;
          const color = field === 'minimum' ? 'green' : 'red';
          const plotBand = chart.options.valueAxis.plotBands.find(band => band.name === field);
          // Feature in Kendo: visible value is wrong way around
          plotBand.color = event.series.visible ? 'transparent' : color;
        }
      },
      pannable: {
        lock: options.isWidget ? 'none' : 'y'
      },
      zoomable: {
        mousewheel: false,
        selection: {
          lock: options.isWidget ? 'none' : 'y'
        }
      }
    }, options.chart.extraChartOptions);

    if (ERDataFunctions.isAggregated(options)) {
      chartOptions.categoryAxis.labels.rotation = 315;
      chartOptions.categoryAxis.labels.template = row => {
        let result = row.value;
        let facility;
        let meter;
        switch (options.idType) {
          case 'Facility':
            facility = options.cache.facilitiesById[_.get(row, 'dataItem.value.FacilityId')];
            if (facility) {
              const realEstateId = _.get(facility, 'FacilityInformation.RealEstateId');
              result = (realEstateId ? `${realEstateId} ` : '') + facility.Name;
            }
            break;
          case 'Meter':
            meter = options.cache.metersById[_.get(row, 'dataItem.value.FacilityId')];
            if (meter) {
              const customerMeterIdentifier = _.get(meter, 'CustomerMeterIdentifier');
              result = (customerMeterIdentifier ? `${customerMeterIdentifier} ` : '') + meter.Name;
            }
            break;
          default:
            break;
        }
        return result;
      };
    } else {
      const format = getXAxisLabelFormat(options.series);
      chartOptions.categoryAxis.labels.visual = !format ?
        undefined :
        e => {
          let text = e.text;
          let textOptions;
          const timeSeriesObject = _.get(e, 'dataItem.value.timeSeriesObject');
          if (_.isObject(timeSeriesObject)) {
            text = kendo.toString(timeSeriesObject.from, format).trim();
            if (timeSeriesObject.from.getDay() === 0) {
              textOptions = { fill: { color: 'red' } };
            }
          }
          const layout = new kendo.drawing.Layout(e.rect, {
            orientation: 'vertical',
            alignContent: 'center'
          });
          layout.append(new kendo.drawing.Text(text || '', [0, 0], textOptions));
          layout.reflow();
          return layout;
        };
    }

    if (options.chart.showAverageLine) {
      chartOptions.render = e => {
        const chart = e.sender;
        const axis = chart.getAxis(ER_CHART_VALUE_AXES.DEFAULT);
        const group = new kendo.drawing.Group();

        // Locate right-most category slot
        const categoryAxis = chart.getAxis('Categories');
        if (!categoryAxis) {
          return;
        }
        const lastCategoryIndex = Math.max(1, categoryAxis.range().max - 1);
        const categorySlot = categoryAxis.slot(lastCategoryIndex);

        // loop every visible serie
        const chartSeries = _.filter(chart.options.series, { visible: true });
        _.each(chartSeries, (serie, serieIndex) => {
          const aggrValues = _.reduce(serie.data, (result, item) => {
            const value = _.get(item, serie.field);
            if (_.isNumber(value)) {
              result.count++;
              result.sum += value;
              result.min = Math.min(result.min, value);
              result.max = Math.max(result.max, value);
            }
            return result;
          }, { min: 0, max: 0, count: 0, sum: 0 });

          aggrValues.avg = aggrValues.count ? aggrValues.sum / aggrValues.count : undefined;
          if (aggrValues.avg) {
            const slot = axis.slot(aggrValues.avg);
            // Render a line element
            const line = new kendo.drawing.Path({
              stroke: {
                color: serie.color,
                width: 2
              }
            });
            line.moveTo(slot.origin).lineTo([categorySlot.origin.x + categorySlot.size.width, slot.origin.y]);
            group.append(line);

            // Render a text element for last serie
            if (serieIndex === chartSeries.length - 1) {
              const labelPos = [categorySlot.origin.x + categorySlot.size.width - 60, slot.origin.y - 20];
              const label = new kendo.drawing.Text(utils.localizedString('FACILITIES_REPORT.AVERAGE'), labelPos, {
                fill: {
                  color: serie.color
                }
              });
              const rect = new kendo.drawing.Rect(label.bbox()).fill('white').stroke('transparent', 1);
              group.append(rect, label);
            }
          }
        });

        chart.surface.draw(group);
      };
    }

    return chartOptions;
  }

  function getChartSharedTooltipTitle(categoryData) {
    let title = categoryData.categoryText || categoryData.category;
    const duration = moment.fromIsodurationCached(
      _.get(categoryData, 'points.0.dataItem.value.timeSeries.TimeFrame')
    );
    if (duration.months() === 1) {
      const monthName = _.get(kendo.culture(), `calendar.months.names.${parseInt(title, 10) - 1}`);
      if (monthName) {
        title = _.capitalize(monthName);
      }
    } else if (duration.hours() > 0 && duration.hours() < 24 && categoryData.points.length === 1) {
      title = title.split(' ')[0];
    }
    return title;
  }

  function getXAxisLabelFormat(series) {
    const timeframeDuration = moment.fromIsodurationCached(series.TimeFrame);
    const resolutionDuration = moment.fromIsodurationCached(series.Resolution);

    // special case (check also getSkip and getStep)
    if (resolutionDuration.asHours() >= 1 && resolutionDuration.asHours() <= 24 && timeframeDuration.asDays() <= 31) {
      return ' d';
    }
    return undefined;
  }

  function postProcessChartOptions(chartOptions, options, data) {
    const isResultAggregated = ERDataFunctions.isAggregated(options);
    const majorSkip = isResultAggregated ? 0 : getSkip(chartOptions, options);
    const majorStep = getStep(chartOptions, options);
    const minorSkip = isResultAggregated ? 0 : getSkip(chartOptions, options, true);
    const minorStep = getStep(chartOptions, options, true);

    // check categoryAxis label visibilities
    let labelsVisible = true;
    if (isResultAggregated) {
      let keysCount = 0;
      _.each(chartOptions.series, serie => {
        keysCount = Math.max(keysCount, serie.data.length);
      });
      labelsVisible = keysCount <= 30 && !options.isWidget;
      if (labelsVisible) {
        chartOptions.chartArea.height = EnergyReportingConstants.Dimensions.chartHeight.high;
      }
    }

    // merge categoryAxis changes
    _.merge(chartOptions.categoryAxis, {
      majorGridLines: {
        skip: majorSkip,
        step: majorStep
      },
      majorTicks: {
        skip: majorSkip,
        step: majorStep
      },
      labels: {
        skip: appStatusService.isMobile ? majorSkip : minorSkip,
        step: appStatusService.isMobile ? majorStep : minorStep,
        visible: labelsVisible
      }
    });

    // category order in month case
    if (ERDataFunctions.isPeriodReportWithMonthResolutionAndComparisionPeriod(options)) {
      _.each(chartOptions.series, serie => {
        // fill empty serie.data item, if the serie doesn't
        // have data for the key that other series have.
        const otherSeriesKeys = _.chain(chartOptions.series)
          .without(serie)
          .pluck('data')
          .flatten()
          .pluck('key')
          .uniq()
          .value();
        _.each(otherSeriesKeys, key => {
          const dataItem = _.find(serie.data, { key });
          if (!dataItem) {
            serie.data.push({
              flags: [],
              key: key,
              value: {}
            });
          }
        });
        // sort alphabetically
        serie.data = _.sortBy(serie.data, dataItem => parseInt(dataItem.key, 10));
      });
    }

    // set gap and spacing for widget charts
    if (options.isWidget) {
      _.each(chartOptions.series, serie => {
        _.extend(serie, {
          gap: 0,
          spacing: 0
        });
      });
    }

    // set justified when area type series specified
    const types = _.pluck(chartOptions.series, 'type');
    _.set(chartOptions, 'categoryAxis.justified', _.includes(types, 'area'));

    // add some space for legend too
    addLegendHeightToChartHeight(chartOptions);

    // handle comparability
    handleComparabilityForChart(chartOptions, options, data);
  }

  function getSkip(chartOptions, options, minor) {
    let skip = 0;
    const followUp = _.last(options.series.Start);
    const timeframeDuration = moment.fromIsodurationCached(options.series.TimeFrame);
    const resolutionDuration = moment.fromIsodurationCached(options.series.Resolution);
    const xAxisPointCount = ERUtils.timeFrameResolutionsCount(options.series.TimeFrame, options.series.Resolution);

    // special case for minor
    if (
      minor &&
      resolutionDuration.asHours() >= 1 &&
      resolutionDuration.asHours() <= 24 &&
      timeframeDuration.asDays() <= 31
    ) {
      return parseInt(12 / resolutionDuration.asHours());
    }

    if (followUp) {
      const date = moment(utils.utcDateToDate(followUp.value));

      if (resolutionDuration.months() && xAxisPointCount > 12) {
        while (date.month() !== 0 && date.month() !== 6) {
          skip++;
          if (skip >= xAxisPointCount) {
            break;
          }
          date.add(resolutionDuration);
        }
      } else if ((resolutionDuration.days() && xAxisPointCount > 7) || resolutionDuration.hours()) {
        while (date.weekday() !== 0) {
          skip++;
          if (skip >= xAxisPointCount) {
            break;
          }
          date.add(resolutionDuration);
        }
      }
    }
    return skip >= xAxisPointCount ? 0 : skip;
  }

  function getStep(chartOptions, options, minor) {
    let step = 1;

    if (ERDataFunctions.isAggregated(options)) {
      let keysCount = 0;
      _.each(chartOptions.series, serie => {
        keysCount = Math.max(keysCount, serie.data.length);
      });
      while (keysCount / step > 30) {
        step++;
      }
      return step;
    } else {
      const timeframeDuration = moment.fromIsodurationCached(options.series.TimeFrame);
      const resolutionDuration = moment.fromIsodurationCached(options.series.Resolution);
      const xAxisPointCount = ERUtils.timeFrameResolutionsCount(options.series.TimeFrame, options.series.Resolution);

      // special case for minor
      if (
        minor &&
        resolutionDuration.asHours() >= 1 &&
        resolutionDuration.asHours() <= 24 &&
        timeframeDuration.asDays() <= 31
      ) {
        return parseInt(24 / resolutionDuration.asHours());
      }

      if (resolutionDuration.years()) {
        while (xAxisPointCount / step > 12) {
          step = step + 5;
        }
      } else if (resolutionDuration.months()) {
        while (xAxisPointCount / step > 12) {
          step = step + Math.max(Math.floor(6 / resolutionDuration.months()), 1);
        }
      } else if (resolutionDuration.days()) {
        while (xAxisPointCount / step > 8) {
          step = step + Math.max(Math.floor(7 / resolutionDuration.days()), 1);
        }
      } else if (resolutionDuration.hours()) {
        const addition = (xAxisPointCount > 168 ? 7 : 1) * 24 / resolutionDuration.hours();
        while (xAxisPointCount / step > 6) {
          step = step + addition;
        }
      }

      return Math.max(step - 1, 1);
    }
  }

  function addLegendHeightToChartHeight(chartOptions) {
    // height based on character count of all series
    if (chartOptions.legend && chartOptions.legend.visible) {
      const seriesCharacterCount = _.reduce(chartOptions.series, (result, serie) => {
        result += serie.name.length;
        return result;
      }, 0);
      chartOptions.chartArea.height += Math.floor(seriesCharacterCount / 250) * 25;
    }
  }

  function handleComparabilityForChart(chartOptions, options, data) {
    const getFlags = object => {
      const flags = [];
      _.each(object, (value, key) => {
        if (_.isObject(value) && !_.isArray(value)) {
          flags.push(...getFlags(value));
        } else {
          if (key === 'Flags' && _.isArray(value)) {
            _.reduce(value, (result, flag) => {
              if (!_.includes(result, flag)) {
                result.push(flag);
              }
              return result;
            }, flags);
          }
        }
      });
      return flags;
    };

    let keysWithFlags = [];
    let aggregated;
    switch (options.series.Comparables) {
      case 'ByQuantity':
        keysWithFlags = _.chain(chartOptions.series)
          .reduce((result, serie) => result.concat(serie.data), [])
          .filter(dataItem => _.isArray(dataItem.flags) && dataItem.flags.length)
          .pluck('key')
          .uniq()
          .value()
        ;

        break;
      case 'ByQuantities':
        aggregated = ERDataFunctions.isAggregated(options);
        _.each(ERDataFunctions.getQuantityIds(options), quantityId => {
          const dataForQuantity = ERDataFunctions
            .getDataForQuantity(options, _.pick(data, ['facilityId', 'meterId']), quantityId, true);
          _.each(dataForQuantity.consumptions, (consumption, apiIndex) => {
            _.each(options.series.Start, (start, startIndex) => {
              const dataForStart = ERDataFunctions
                .getDataForStart(options, dataForQuantity, startIndex, { index: false }, true);
              _.each(dataForStart.startInfo.keys, (key, value) => {
                const predicate = aggregated ? { Id: value } : { Timestamp: value };
                const valueForKey = _.find(_.get(dataForStart, `startInfo.consumptions.${apiIndex}`), predicate);
                const flagsForKey = getFlags(valueForKey);
                if (flagsForKey.length && !_.includes(keysWithFlags, key)) {
                  keysWithFlags.push(key);
                }
              });
            });
          });
        });
        break;
      default:
        // do nothing
        break;
    }

    if (keysWithFlags.length) {
      _.each(chartOptions.series, serie => {
        serie.data = _.map(serie.data, dataItem => {
          if (_.includes(keysWithFlags, _.get(dataItem, 'key'))) {
            _.set(dataItem, 'value.y', undefined);
          }
          return dataItem;
        });
      });
    }
  }

  function getChartSeriesDefinitions(options, quantityId, apiIndex) {
    const quantity = options.cache.quantitiesById[quantityId];
    if (!quantity) {
      return [];
    }

    if (ERDataFunctions.isWeatherRequest(options, apiIndex)) {
      return quantity.TemperatureInformation ? getWeatherChartSeriesDefinitions(options, quantity, apiIndex) : [];
    } else {
      return getConsumptionsChartSeriesDefinitions(options, quantity, apiIndex);
    }
  }

  function getConsumptionsChartSeriesDefinitions(options, quantity, apiIndex) {
    const seriesDefinitions = [];

    /** Measured **/
    if (options.series.Measured || (options.series.Normalized && !quantity.Normalization)) {
      // reading series
      if (!options.series.DistributionId || options.show.distributionAndValue) {
        seriesDefinitions.push({
          apiIndex: apiIndex,
          fn: getReadingChartSeries,
          normalized: false,
          propertyData: {
            path: 'Reading.Value',
            object: {
              Name: utils.localizedString('FACILITIES_TOOLBAR.MEASURED')
            }
          }
        });
      }
      // distribution series
      if (options.series.DistributionId && quantity.Distribution) {
        const distributionType = options.cache.distributionTypesById[options.series.DistributionId];
        if (distributionType) {
          _.each(distributionType.Distributions, (distribution, index) => {
            seriesDefinitions.push({
              apiIndex: apiIndex,
              fn: getDistributionChartSeries,
              normalized: false,
              propertyData: {
                path: `DistributionValues.${
                  distribution.ValueProperty
                }${options.series.DistributionAsPercent ? '.Percent' : '.Reading.Value'}`,
                object: distribution,
                index: index
              }
            });
          });
        }
      }
      // relational value series
      if (
        _.isArray(options.series.RelationalUnitIds) &&
        options.series.RelationalUnitIds.length &&
        (quantity.Relational || quantity.Emission)
      ) {
        _.each(options.series.RelationalUnitIds, (relationalValueId, index) => {
          const relationalValue = options.cache.relationalValuesById[relationalValueId];
          if (relationalValue) {
            const unit = _.get(relationalValue, `UnitsForQuantities.Default.${_.get(quantity, 'ID')}`);
            seriesDefinitions.push({
              apiIndex: apiIndex,
              fn: getRelationalValueChartSeries,
              normalized: false,
              propertyData: {
                path: `RelationalValues.${relationalValueId}.Reading.Value`,
                unit: unit !== relationalValue.Name ? unit : undefined,
                object: relationalValue,
                index: index,
                followUpOnly: options.show.relationalValueOnlyForFollowUp
              }
            });
          }
        });
      }
      // related value series
      if (_.isArray(options.series.RelatedValues) && options.series.RelatedValues.length) {
        _.each(options.cache.relatedValuesByQuantityId[quantity.ID], (relatedValue, index) => {
          if (_.includes(options.series.RelatedValues, relatedValue.Id)) {
            seriesDefinitions.push({
              apiIndex: apiIndex,
              fn: getRelatedValueChartSeries,
              normalized: false,
              propertyData: {
                path: `RelatedValues.${relatedValue.Id}.Value`,
                unit: _.get(relatedValue, 'UnitName'),
                object: relatedValue,
                index: index,
                followUpOnly: options.show.relatedValueOnlyForFollowUp
              }
            });
          }
        });
      }
    }
    /** Normalized **/
    if (options.series.Normalized && quantity.Normalization) {
      // reading series
      seriesDefinitions.push({
        apiIndex: apiIndex,
        fn: getReadingChartSeries,
        normalized: true,
        propertyData: {
          path: 'NormalizedReading.Value',
          object: {
            Name: utils.localizedString('FACILITIES_TOOLBAR.NORMALIZED')
          }
        }
      });
      // relational value series
      if (
        _.isArray(options.series.RelationalUnitIds) &&
        options.series.RelationalUnitIds.length &&
        (quantity.Relational || quantity.Emission)
      ) {
        _.each(options.series.RelationalUnitIds, (relationalValueId, index) => {
          const relationalValue = options.cache.relationalValuesById[relationalValueId];
          if (relationalValue) {
            seriesDefinitions.push({
              apiIndex: apiIndex,
              fn: getRelationalValueChartSeries,
              normalized: true,
              propertyData: {
                path: `RelationalValues.${relationalValue.Id}.NormalizedReading.Value`,
                object: options.cache.relationalValuesById[relationalValueId],
                unit: _.get(relationalValue, `UnitsForQuantities.Default.${_.get(quantity, 'ID')}`),
                index: index,
                followUpOnly: options.show.relationalValueOnlyForFollowUp
              }
            });
          }
        });
      }
    }
    return seriesDefinitions;
  }

  function getWeatherChartSeriesDefinitions(options, quantity, apiIndex) {
    return [
      {
        apiIndex: apiIndex,
        fn: getWeatherChartSeries,
        propertyData: {
          path: 'Value',
          object: {
            Name: utils.localizedString('FACILITIES.SIDEBAR.TEMPERATURE')
          }
        }
      }
    ];
  }

  function getReadingChartSeries(options, data) {
    const result = [];
    const values = getChartQuantityPropertyValues(options, data);

    let valueAxisName = ER_CHART_VALUE_AXES.DEFAULT;
    if (data.normalized && options.series.DistributionAsPercent && options.series.DistributionId) {
      // special case when distribution is represented as percents in Values axis, so
      // new axis is needed for normalisation values
      valueAxisName = ER_CHART_VALUE_AXES.NORMALIZED;
    }
    if (options.api[data.apiIndex].requestData.Budget && !!ERDataFunctions.getTargetSeriesType(options, data)) {
      valueAxisName = ER_CHART_VALUE_AXES.COST;
    }
    createValueAxis(data, values, valueAxisName, {});

    const seriesName = ERDataColumn.getTitle(options, data);
    const color = getSeriesColor(options, data);

    const forecastType = ERDataFunctions.getForecastType(options, data);
    const targetSeriesType = ERDataFunctions.getTargetSeriesType(options, data); // TODO korjaa targetin haku
    if (!_.isEmpty(values)) {
      let type = 'column';

      if (isHourResolution(options) || options.chart.separateQuantityProperties || targetSeriesType) {
        type = data.startInfo.index !== options.series.Start.length - 1 || forecastType || targetSeriesType ?
          'line' :
          'area';
      }

      const titleLocalization = data.normalized ? 'FACILITIES_TOOLBAR.NORMALIZED' : 'FACILITIES_TOOLBAR.MEASURED';
      const serie = {
        name: targetSeriesType ? `${targetSeriesType.Name} ${seriesName}` : seriesName,
        title: (targetSeriesType || forecastType)
          ? ERDataColumn.getTitlePrefix(options, data)
          : utils.localizedString(titleLocalization),
        startIndex: data.startInfo.index,
        type: type,
        property: data.propertyData.path,
        quantity: data.quantity,
        data: values,
        width: EnergyReportingConstants.Defaults.Series.Width,
        spacing: 0,
        field: 'value.y',
        categoryField: 'key',
        axis: valueAxisName,
        color: color,
        border: {
          width: 0
        },
        opacity: getSeriesOpacity(type, options),
        style: getSeriesStyle(options, data),
        dashType: getSeriesDashType(options, data)
      };
      if (ERDataFunctions.isCostTarget(targetSeriesType)) {
        let facilityId;

        if (data.facilityId) {
          facilityId = data.facilityId;
        } else if (data.meterId) {
          facilityId = options.cache.metersById[data.meterId].ReportingObjectId;
        } else if (options.params.facilityId) {
          facilityId = options.params.facilityId[0];
        } else if (data.quantityId) {
          facilityId = data.consumptions[0].FacilitiesIncluded[0];
        }

        const unit = options.cache.facilitiesById[facilityId].FacilityInformation.Currency;

        serie.tooltip = {
          template: chartTooltip(
            options,
            data,
            unit,
            'n1',
            data.chartOptions.tooltip.shared
          )
        };
      }

      if (data.normalized && !options.chart.separateQuantityProperties && serie.axis !== 'cost') {
        serie.stack = `normalized_${data.startInfo.index}`;
      }
      result.push(serie);

      // if there is only one start, then add also min and max except if yearly resolution
      // NOTE: not added to measured if normalization is supported and wanted or separated graph properties  ???
      if (
        options.series.Start.length === 1 &&
        options.series.Resolution !== 'P1Y' &&
        values.length > 1 &&
        !options.chart.separateQuantityProperties &&
        !options.isWidget &&
        !targetSeriesType
      ) {
        // minValue skips null value (daylight saving at spring returns values[].y as a null for
        // 03:00:00 timestamp, see ENER-271)
        const minValue = getMinValue(values);
        const maxValue = getMaxValue(values);
        if (minValue && maxValue && minValue.minimum !== maxValue.maximum) {
          result.push({
            name: utils.localizedString('FACILITIES_REPORT.MIN'),
            title: utils.localizedString('FACILITIES_REPORT.MIN'),
            type: 'line',
            data: [minValue],
            field: 'minimum',
            categoryField: 'key',
            axis: valueAxisName,
            color: 'green',
            markers: {
              visible: true
            }
          });
          result.push({
            name: utils.localizedString('FACILITIES_REPORT.MAX'),
            title: utils.localizedString('FACILITIES_REPORT.MAX'),
            type: 'line',
            data: [maxValue],
            field: 'maximum',
            categoryField: 'key',
            axis: valueAxisName,
            color: 'red',
            markers: {
              visible: true
            }
          });
          const borderThickness = (maxValue.maximum - Math.min(0, minValue.minimum)) / 150.0;
          const axis = data.chartOptions.valueAxis.find(({ name }) => name === valueAxisName);
          axis.plotBands = [
            {
              from: minValue.minimum - 0.5 * borderThickness,
              to: minValue.minimum + 0.5 * borderThickness,
              color: 'green',
              opacity: 0.75,
              name: 'minimum' // Non-standard property to identify this band later
            },
            {
              from: maxValue.maximum - 0.5 * borderThickness,
              to: maxValue.maximum + 0.5 * borderThickness,
              color: 'red',
              opacity: 0.75,
              name: 'maximum' // Non-standard property to identify this band later
            }
          ];
        }
      }
    }
    return result;
  }

  function getMinValue(values) {
    // minValue skips null value (daylight saving at spring returns values[].y as a null for
    // 03:00:00 timestamp, see ENER-271)
    const minValue = values.reduce(
      (min, current) => min.value.y < current.value.y || current.value.y === null ? min : current,
      values[0]
    );

    // Change minimum serie field name so it can be distinguished from other series
    return {
      flags: minValue.flags,
      key: minValue.key,
      minimum: minValue.value.y,
      value: { timeSeriesObject: minValue.value.timeSeriesObject }
    };
  }

  function getMaxValue(values) {
    const maxValue = values.reduce(
      (max, current) => current.value.y > max.value.y ? current : max,
      values[0]
    );
    // Change maximum serie field name so it can be distinguished from other series
    return {
      flags: maxValue.maximum,
      key: maxValue.key,
      maximum: maxValue.value.y,
      value: { timeSeriesObject: maxValue.value.timeSeriesObject }
    };
  }

  function getChartQuantityPropertyValues(options, data) {
    const maxEndDate = utils.getMomentEndDate(utils.utcDateToDate(data.startInfo.value), options.series.TimeFrame);

    const getFlagPath = path => {
      let result;
      const splitted = path.split('.');
      if (splitted.length) {
        splitted[splitted.length - 1] = 'Flags';
        result = splitted.join('.');
      }
      return result;
    };

    // HACK: normalized targets must use different path
    const isTarget = !!ERDataFunctions.getTargetSeriesType(options, data);
    const propertyPath = (isTarget && data.propertyData.path === 'NormalizedReading.Value')
      ? 'Reading.Value'
      : data.propertyData.path;

    return _.reduce(_.get(data, `startInfo.consumptions.${data.apiIndex}`), (result, item) => {
      let valueY = _.get(item, propertyPath, null);
      let flags = _.get(item, getFlagPath(data.propertyData.path), []);

      /**
       * Currently normalized value doesn't have any flags so we need to check reading value flags
       * to make sure that ui shows graphs and calculations correctly. This row can be deleted after
       * flag support has been implemented for normalized values.
       */
      flags = flags.length === 0 ? _.get(item, getFlagPath('Reading.Value'), []) : flags;

      if (valueY !== null) {
        valueY = ((valueY === 0 || _.get(options, 'series.Comparables') === 'ByProperty') &&
          _.includes(flags, 'Incomplete')) ?
          null :
          valueY;
        if (ERDataFunctions.isAggregated(options)) {
          let color;
          if (options.idType === 'Quantity') {
            color = ColorService.getMainPrimaryGraphColor(item.Id, 'Key');
          }
          result.push({
            key: data.startInfo.keys[item.Id],
            value: {
              y: valueY,
              Id: item.Id
            },
            flags: flags,
            color: color
          });
        } else {
          result.push({
            key: data.startInfo.keys[item.Timestamp],
            value: {
              y: valueY,
              timeSeries: getTimeSeriesForDrilling(options.series, item),
              timeSeriesObject: utils.durationToObject(
                utils.utcDateToDate(item.Timestamp),
                options.series.Resolution,
                maxEndDate
              )
            },
            flags: flags
          });
        }
      }
      return result;
    }, []);
  }

  function getTimeSeriesForDrilling(series, item) {
    if (series.Resolution !== ErResolutions.PT1H) {
      const timeFrame = TimeFrame.parse({
        fromDate: item.Timestamp,
        durationTo: series.Resolution
      });
      return {
        TimeFrame: series.Resolution,
        Resolution: getDrillingResolution(series),
        Start: [{
          key: `${timeFrame.fromDate.format('D.M.Y')} - ${timeFrame.toDate.subtract(1, 'seconds').format('D.M.Y')}`,
          value: item.Timestamp
        }]
      };
    }
  }

  function getDrillingResolution(series) {
    const monthIndex = resolutionsFromShortestToLongest.indexOf(ErResolutions.P1M);
    const resolutionIndex = resolutionsFromShortestToLongest.indexOf(series.Resolution);
    return monthIndex < resolutionIndex || !series.ShowComparison ? ErResolutions.P1M : ErResolutions.PT1H;
  }

  function getDistributionChartSeries(options, data) {
    const result = [];

    // set axis properties
    if (options.series.DistributionAsPercent) {
      _.extend(data.chartOptions.valueAxis[0], {
        min: 0,
        max: 1,
        labels: {
          format: '{0:p0}'
        }
      });
    }

    const values = getChartQuantityPropertyValues(options, data);
    if (!_.isEmpty(values)) {
      const serieColor = ColorService.shadeColor(data.startInfo.color, data.propertyData.index * 25);
      const format = options.series.DistributionAsPercent ? 'p1' : data.format;
      let type = 'column';
      if (isHourResolution(options) || options.chart.separateQuantityProperties) {
        const startCount = options.series.Start.length;
        // show only one line serie (due to kendo can stack only one line serie)
        const line = data.startInfo.index !== options.series.Start.length - 2 && startCount > 2 ? undefined : 'line';
        type = data.startInfo.index === startCount - 1 ? 'area' : line;
      }
      if (type) {
        result.push({
          name: `${data.startInfo.key} ${_.get(data, 'propertyData.object.Name')}`,
          title: _.get(data, 'propertyData.object.Name'),
          startIndex: data.startInfo.index,
          type: type,
          property: data.propertyData.path,
          quantity: data.quantity,
          data: values,
          spacing: 0,
          field: 'value.y',
          categoryField: 'key',
          axis: ER_CHART_VALUE_AXES.DEFAULT,
          stack: isHourResolution(options) ? false : data.startInfo.key + data.startInfo.index,
          color: type === 'line' ? ColorService.getNegateAndDarkenColor(serieColor, 20) : serieColor,
          opacity: type === 'line' ? 1.0 : 0.8,
          tooltip: {
            template: chartTooltip(
              options,
              data,
              options.series.DistributionAsPercent ? '' : data.unit,
              format,
              data.chartOptions.tooltip.shared
            )
          }
        });
      }
    }
    return result;
  }

  function chartTooltip(options, data, unit, format, shared) {
    unit = unit || '';
    format = format || 'n0';
    const aggregated = ERDataFunctions.isAggregated(options);

    const getTitle = dataItem => {
      let result;

      if (aggregated) {
        let facility;
        let meter;
        let quantity;
        switch (options.idType) {
          case 'Facility':
            facility = options.cache.facilitiesById[_.get(dataItem, 'value.FacilityId')];
            if (facility) {
              const realEstateId = _.get(facility, 'FacilityInformation.RealEstateId');
              result = (realEstateId ? `${realEstateId} ` : '') + facility.Name;
            }
            break;
          case 'Meter':
            meter = options.cache.metersById[_.get(dataItem, 'value.Id')];
            if (meter) {
              const customerMeterIdentifier = _.get(meter, 'CustomerMeterIdentifier');
              result = (customerMeterIdentifier ? `${customerMeterIdentifier} ` : '') + meter.Name;
            }
            break;
          case 'Quantity':
            quantity = options.cache.quantitiesById[_.get(dataItem, 'value.Id')];
            if (quantity) {
              result = quantity.Name;
            }
            break;
          default:
            break;
        }
      } else {
        const titleTimeSeries = _.get(dataItem, 'value.timeSeries');
        const timeSeriesObject = _.get(dataItem, 'value.timeSeriesObject');
        if (_.isObject(titleTimeSeries) && _.isObject(timeSeriesObject)) {
          const durationAsHours = moment.fromIsodurationCached(titleTimeSeries.TimeFrame).asHours();
          const prefix = durationAsHours <= 24 ? kendo.toString(timeSeriesObject.from, 'ddd ') : '';
          result = prefix + (durationAsHours <= 24 ?
            timeSeriesObject.fromString :
            TimeFrame.timeRangeFormat(
              localToUtc(new Date(timeSeriesObject.from.toDateString())),
              titleTimeSeries.TimeFrame, titleTimeSeries.Resolution
            )
          );
        }
      }
      return result;
    };

    const getTemplate = dataItem => {
      if (shared) {
        const series = (aggregated || options.idType === 'Meter') ? 'series.name' : 'series.title# #= title';
        return kendo.template(
          !dataItem.value ?
            '' :
            `<tr><td><span style="color: #= series.color #">■</span> #= ${series}#&nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td class="cell-number">#= kendo.toString(value, "${format}") # </td><td>${unit}</td></tr>`
        );
      }
      return kendo.template(`${'<b>#= series.name #</b><br />' +
        '#= (title ? title + "<br />" : "")#' +
        '#= kendo.toString(value, "'}${format}") # ${unit}`);
    };

    return templateData => {
      const dataItem = templateData.dataItem;
      templateData.title = getTitle(dataItem) || '';
      return getTemplate(dataItem)(templateData);
    };
  }

  function getRelationalValueChartSeries(options, data) {
    const result = [];
    const relationalValue = data.propertyData.object || {};
    const forecastType = ERDataFunctions.getForecastType(options, data);

    const values = getChartQuantityPropertyValues(options, data);
    if (!_.isEmpty(values)) {
      let type = 'line';

      if (options.chart.separateQuantityProperties) {
        type = data.startInfo.index !== options.series.Start.length - 1 || forecastType ? type : 'area';
      }

      // set axis properties
      let valueAxisName = relationalValue.Name;
      if (isEmission(relationalValue.Id)) {
        valueAxisName = ER_CHART_VALUE_AXES.EMISSION;
      }
      if (isCost(relationalValue.Id)) {
        valueAxisName = ER_CHART_VALUE_AXES.COST;
      }
      if (isAverageCost(relationalValue.Id)) {
        valueAxisName = ER_CHART_VALUE_AXES.AVERAGE_COST;
      }
      valueAxisName = options.chart.separateQuantityProperties ? ER_CHART_VALUE_AXES.DEFAULT : valueAxisName;

      const colors = ColorService.colorsForRelationalValue(relationalValue.Id, options.series.Start.length);
      const additionalProperties = {
        color: colors[data.startInfo.index],
        visible: !options.isWidget
      };

      createValueAxis(data, values, valueAxisName, additionalProperties);

      result.push({
        name: ERDataColumn.getRelatedValueTitle(options, data),
        title: ERDataColumn.getRelatedValueTooltipTitle(options, data),
        startIndex: data.startInfo.index,
        property: data.propertyData.path,
        quantity: data.quantity,
        data: values,
        spacing: 0,
        field: 'value.y',
        categoryField: 'key',
        color: colors[data.startInfo.index],
        type: type,
        style: 'smooth',
        axis: valueAxisName,
        tooltip: {
          template: chartTooltip(options, data, data.propertyData.unit, 'n1', data.chartOptions.tooltip.shared)
        }
      });
    }

    return result;
  }

  function getRelatedValueChartSeries(options, data) {
    const result = [];
    const relatedValue = data.propertyData.object;

    const values = getChartQuantityPropertyValues(options, data);
    if (!_.isEmpty(values)) {
      // type
      let type = 'line';
      if (isHourResolution(options) || options.chart.separateQuantityProperties) {
        type = data.startInfo.index !== options.series.Start.length - 1 ? 'line' : 'area';
      }

      const valueAxisName = relatedValue.UsePrimaryAxis || options.chart.separateQuantityProperties
        ? ER_CHART_VALUE_AXES.DEFAULT
        : relatedValue.UnitName;
      const color = data.startInfo.index === options.series.Start.length - 1
        ? ColorService.shadeColor(data.colors[data.startInfo.index], -(data.propertyData.index + 1) * 10)
        : data.colors[data.startInfo.index];

      createValueAxis(data, values, valueAxisName, { color });

      // prefix
      const prefix = (data.normalized ? (`${utils.localizedString('FACILITIES.NORMALIZED')} `) : '');

      result.push({
        name: prefix + (options.chart.separateQuantityProperties ?
          data.startInfo.key :
          relatedValue.Name + (!options.relatedValueOnlyForFollowUp ? ` ${data.startInfo.key}` : '')),
        title: relatedValue.Name,
        startIndex: data.startInfo.index,
        property: data.propertyData.path,
        quantity: data.quantity,
        data: values,
        spacing: 0,
        field: 'value.y',
        categoryField: 'key',
        color: color,
        type: type,
        style: 'smooth',
        axis: valueAxisName,
        tooltip: {
          template: chartTooltip(options, data, relatedValue.UnitName, 'n1', data.chartOptions.tooltip.shared)
        }
      });
    }

    return result;
  }

  function getWeatherChartSeries(options, data) {
    const result = [];
    const values = getChartQuantityPropertyValues(options, data);

    const valueAxisName = 'Temperature';
    createValueAxis(data, values, valueAxisName, {});

    if (!_.isEmpty(values)) {
      // get weather station
      const weatherStation = _.get(
        data,
        `consumptions.${data.apiIndex}.Values["${_.get(data, 'startInfo.key')}"][0].WeatherStation`
      );
      const unit = '°C';
      const type = 'line';
      const color = ColorService.shadeColor('#4ddd4d', data.startInfo.index * -20);
      const title = utils.localizedString('FACILITIES.SIDEBAR.TEMPERATURE');
      const name = `${weatherStation ? `${weatherStation} ${unit}` : title} ${data.startInfo.key}`;
      const serie = {
        name: name,
        title: title,
        startIndex: data.startInfo.index,
        type: type,
        property: data.propertyData.path,
        quantity: data.quantity,
        data: values,
        spacing: 0,
        field: 'value.y',
        categoryField: 'key',
        axis: valueAxisName,
        color: color,
        opacity: 0.75,
        tooltip: {
          template: chartTooltip(options, data, unit, 'n1', data.chartOptions.tooltip.shared)
        }
      };
      result.push(serie);
    }

    return result;
  }

  function createValueAxis(data, values, axisName, additionalProperties) {
    let valueAxis = data.chartOptions.valueAxis.find(({ name }) => name === axisName);
    if (!valueAxis) {
      valueAxis = {
        ...additionalProperties,
        name: axisName
      };
      data.chartOptions.valueAxis.push(valueAxis);
      const axisValuesLocation = axisName === ER_CHART_VALUE_AXES.DEFAULT ? 0 : Number.MAX_VALUE;
      data.chartOptions.categoryAxis.axisCrossingValues.push(axisValuesLocation);
    }

    if (axisName !== ER_CHART_VALUE_AXES.DEFAULT) {
      // Calculate axis min and max values manually for there is a bug in kendo's own algorithm
      // that causes really long decimals e.g. 4.3284923984732
      const minItem = _.min(values, item => item && item.value ? item.value.y : 0);
      const maxItem = _.max(values, item => item && item.value ? item.value.y : 0);
      const minValue = minItem.value ? 0.95 * minItem.value.y : 0;
      const maxValue = maxItem.value ? 1.05 * maxItem.value.y : 1;

      const actualMin = Math.min(valueAxis.min || 0, minValue);
      const actualMax = Math.max(valueAxis.max || 0, maxValue);
      const { min, max } = actualMin === actualMax
        ? getMinMaxForConstantValue(actualMax)
        : roundDataSeries(actualMin, actualMax);
      valueAxis.min = min;
      valueAxis.max = max;
    }
  }

  function getMinMaxForConstantValue(value) {
    return value >= 0
      ? {
        min: 0,
        max: value || 1
      }
      : {
        min: value || -1,
        max: 0
      };
  }

  function getSeriesColor(options, data) {
    const color = getSeriesVariable(options, data, 'Color');

    return color ? color : ColorService.shadeColor(data.startInfo.color, data.normalized ? 10 : 0);
  }

  function getSeriesDashType(options, data) {
    const dashType = getSeriesVariable(options, data, 'DashType');

    return dashType ? dashType : EnergyReportingConstants.Defaults.Series.DashType;
  }

  function getSeriesStyle(options, data) {
    const style = getSeriesVariable(options, data, 'Style');

    return style ? style : EnergyReportingConstants.Defaults.Series.Style;
  }

  function getSeriesOpacity(type, options) {
    return type === EnergyReportingConstants.SerieTypes.Area && isHourResolution(options) ?
      EnergyReportingConstants.Opacity.Light :
      EnergyReportingConstants.Opacity.Normal
    ;
  }

  function isHourResolution(options) {
    return (options.series.Resolution === 'PT1H');
  }

  function getSeriesVariable(options, data, variableName) {
    const forecastType = ERDataFunctions.getForecastType(options, data);
    const targetSeriesType = ERDataFunctions.getTargetSeriesType(options, data);

    let variable = null;

    variable = forecastType ? _.get(forecastType, variableName) : variable;
    variable = targetSeriesType ? _.get(targetSeriesType, variableName) : variable;

    return variable;
  }

  return {
    getCharts
  };
}

ERChartService.$inject = $inject;

export default ERChartService;
