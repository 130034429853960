import MetersReportTreelistController from './meters-report-treelist.controller';
import MetersReportTreelistTemplate from 'raw-loader!./meters-report-treelist.html';

export default {
  template: MetersReportTreelistTemplate,
  controller: MetersReportTreelistController,
  controllerAs: 'vm',
  bindings: {
    reportParams: '<',
    cache: '<'
  }
};
