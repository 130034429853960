<form [formGroup]="formGroup">
  <ek-slider-number-box
    formControlName="numberToShow"
    [min]="minCount"
    [max]="maxCount"
    *labelBefore="'ERROR_WIDGET.AMOUNT_TO_SHOW' | translate"
  ></ek-slider-number-box>
  <input
    kendoRadioButton
    type="radio"
    [value]="ErrorWidgetTicketType.Terminal"
    formControlName="typeToShow"
    *labelWrap="'ERROR_WIDGET.BY_TERMINAL' | translate"
  >
  <input
    kendoRadioButton
    type="radio"
    [value]="ErrorWidgetTicketType.Facility"
    formControlName="typeToShow"
    *labelWrap="'ERROR_WIDGET.BY_FACILITY' | translate"
  >
  <input
    kendoRadioButton
    type="radio"
    [value]="ErrorWidgetTicketType.FacilityPT15M"
    formControlName="typeToShow"
    *labelWrap="'ERROR_WIDGET.BY_FACILITY_PT15M' | translate"
  >
</form>
