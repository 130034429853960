export default [
  { name: 'id' },
  { name: 'externalActionId' },
  { name: 'reportingYear' },
  { name: 'reportedDescription' },
  { name: 'internalDescription' },
  { name: 'owner' },
  { name: 'actionType' },
  { name: 'investment' },
  { name: 'paybackTime' },
  { name: 'electricitySavings' },
  { name: 'heatingSavings' },
  { name: 'fuelSavings' },
  { name: 'waterSavings' },
  { name: 'electricitySavingsEur' },
  { name: 'heatingSavingsEur' },
  { name: 'fuelSavingsEur' },
  { name: 'waterSavingsEur' },
  { name: 'electricitySavingsCo2' },
  { name: 'heatingSavingsCo2' },
  { name: 'fuelSavingsCo2' },
  { name: 'executionPhase' },
  { name: 'executionYear' },
  { name: 'lifeTime' },
  { name: 'actionBasis' },
  { name: 'effectStartsAt' },
  { name: 'effectStopsAt' },
  { name: 'investmentSupport' },
  { name: 'reportedToMotivaAt' },
  { name: 'investigation' },
  { name: 'organizationId' },
  { name: 'reportingObjectId' },
  { name: 'enegiaId' },
  { name: 'meterIds', type: 'array' },
  { name: 'quantityIds', type: 'array' },
  { name: 'referenceId' },
  { name: 'referenceSource' },
  { name: 'actionGroup' },
  { name: 'actionClass' },
  { name: 'documents', type: 'array', structure: [
    { name: 'id' },
    { name: 'filename' },
    { name: 'hasPreview' },
    { name: 'removed' }
  ] },
  { name: 'category' }
];
