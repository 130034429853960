import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';

import { CompanyFlatResponse, ContactClient } from '@enerkey/clients/contact';

import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  /** Companies the current user has access to. All companies if in `UserAdministrator`-role. */
  public readonly companies$: Observable<CompanyFlatResponse[]>;

  /** Current user's company, may be null */
  public readonly userCompany$: Observable<CompanyFlatResponse>;

  public readonly isSuperCompanyUser$: Observable<boolean>;

  public constructor(
    private readonly contactClient: ContactClient,
    private readonly authenticationService: AuthenticationService
  ) {
    this.companies$ = this.contactClient.getAllowedCompanies();

    this.isSuperCompanyUser$ = this.contactClient.getTenantDefaultCompany().pipe(
      map(company => company.id === this.authenticationService.organizationId),
      catchError(() => of(false)),
      shareReplay(1)
    );

    this.companies$ = this.contactClient.getAllowedCompanies().pipe(
      shareReplay(1)
    );

    this.userCompany$ = this.companies$.pipe(
      map(companies => companies.find(c => c.id === this.authenticationService.organizationId)),
      shareReplay(1)
    );
  }

  public getCompanies(): Observable<CompanyFlatResponse[]> {
    return this.companies$.pipe(take(1));
  }
}
