import { Injectable } from '@angular/core';

import { Environment } from '../../config/api-url-config.interface';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  /**
   * Returns true on development environment.
   */
  public isDevelopment(): boolean {
    return this.isEnvironment('development');
  }

  /**
   * Returns true on production environment.
   */
  public isProduction(): boolean {
    return this.isEnvironment('production');
  }

  /**
   * Returns true on test environment.
   */
  public isTest(): boolean {
    return this.isEnvironment('testing');
  }

  /**
   * Checks if ENERKEY_CONFIG.env matches given string.
   */
  private isEnvironment(environmentName: Environment): boolean {
    return ENERKEY_CONFIG.env === environmentName;
  }
}
