import moment from 'moment';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { formGroupFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import {
  ContractClient,
  FacilitySubscriptionService,
  MeterSubscriptionService,
  ServiceScopeType
} from '@enerkey/clients/contract';

import { ToasterService } from '../../../../shared/services/toaster.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { CompaniesService } from '../../../../shared/services/companies.service';

type SubscriptionForm = {
  service: number,
  services: number[],
  startDate: Date,
  endDate: Date,
  subscription: number,
}

type SelectedItems = {
  id: number,
  name: string,
}

@Component({
  selector: 'mass-add-subscription-modal',
  templateUrl: './mass-add-subscription-modal.component.html',
  styleUrls: ['./mass-add-subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'tiny' })
export class MassAddSubscriptionModalComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public selectedItems: SelectedItems[];
  public scope: ServiceScopeType = null;

  public readonly subscriptionForm: UntypedFormGroup;
  public readonly loading$: Observable<boolean>;
  public readonly companyField: UntypedFormControl;
  public readonly subscriptions$: Observable<ComboItem<number>[]>;
  public readonly services$: Observable<ComboItem<number>[]>;
  public readonly ServiceScopeType = ServiceScopeType;

  private readonly _scope$ = new ReplaySubject<ServiceScopeType>(1);
  private readonly _companyId$: Observable<number>;
  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly contractClient: ContractClient,
    private readonly toasterService: ToasterService,
    private readonly companiesService: CompaniesService,
    authenticationService: AuthenticationService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.companyField = new UntypedFormControl(authenticationService.organizationId);
    this.subscriptionForm = formGroupFrom<SubscriptionForm>({
      service: new UntypedFormControl(null, Validators.required),
      services: new UntypedFormControl(null, Validators.required),
      startDate: new UntypedFormControl(null, Validators.required),
      endDate: new UntypedFormControl(null, Validators.required),
      subscription: new UntypedFormControl(null, Validators.required)
    });
    this._companyId$ = this.companyField.valueChanges.pipe(
      startWith(authenticationService.organizationId),
      filter(value => !!value),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
    this.subscriptions$ = this.getSubscriptions();
    this.services$ = this._scope$.pipe(
      switchMap(scope => this.contractClient.getServices(scope).pipe(
        indicate(this._loading$),
        map(services => services.map(s => ({
          value: s.id,
          text: s.name
        })))
      )),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnInit(): void {
    if (this.scope === ServiceScopeType.Meter) {
      this.subscriptionForm.get('service').disable();
    } else if (this.scope === ServiceScopeType.Facility) {
      this.subscriptionForm.get('services').disable();
    } else {
      throw Error('Scope needs to be set to Facility or Meter');
    }
    this._scope$.next(this.scope);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._scope$.complete();
  }

  public submitForMeter(): void {
    const data: SubscriptionForm = this.subscriptionForm.value;
    const subscriptionServices = data.services.map((s: number) => (new MeterSubscriptionService({
      serviceId: s,
      startDate: moment(data.startDate),
      endDate: moment(data.endDate),
      meterIds: this.selectedItems.map(i => i.id),
      subscriptionId: data.subscription
    })));
    this.contractClient.upsertSubscriptionServicesForMeters(
      subscriptionServices
    ).pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$)
    ).subscribe({
      next: () => {
        this.toasterService.success('ADMIN.BULK_MODIFY_METERS.ADD_TO_SUBSCRIPTION_SUCCESS');
        super.closeModal();
      },
      error: () => this.toasterService.error('ADMIN.BULK_MODIFY_METERS.ADD_TO_SUBSCRIPTION_FAILED')
    });
  }

  public submitForFacility(): void {
    const data: SubscriptionForm = this.subscriptionForm.value;
    const subscriptionServices = this.selectedItems.map(item => (new FacilitySubscriptionService({
      serviceId: data.service,
      startDate: moment(data.startDate),
      endDate: moment(data.endDate),
      facilityId: item.id,
      subscriptionId: data.subscription
    })));
    this.contractClient.upsertSubscriptionServiceForFacilities(
      subscriptionServices
    ).pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$)
    ).subscribe({
      next: () => {
        this.toasterService.success('ADMIN.BULK_MODIFY_FACILITIES.ADD_TO_SUBSCRIPTION_SUCCESS');
        super.closeModal();
      },
      error: () => this.toasterService.error('ADMIN.BULK_MODIFY_FACILITIES.ADD_TO_SUBSCRIPTION_FAILED')
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private getSubscriptions(): Observable<ComboItem<number>[]> {
    return this._companyId$.pipe(
      switchMap(companyId => forkJoin([
        this.contractClient.getSubscriptions(companyId).pipe(indicate(this._loading$)),
        this.companiesService.companies$.pipe(take(1))
      ]).pipe(
        map(([subscriptions, companies]) => {
          if (subscriptions.length) {
            this.subscriptionForm.patchValue({
              subscription: subscriptions[0].id
            });
            return subscriptions.map(s => ({
              value: s.id,
              text: `${companies.find(c => c.id === s.companyId)?.name} (${s.priceListUrl})`
            }));
          }
          this.subscriptionForm.patchValue({ subscription: null });
          this.toasterService.error('ADMIN.BULK_MODIFY_METERS.NO_SUBSCRIPTIONS');
          return [];
        }),
        catchError(() => {
          this.toasterService.error('ADMIN.BULK_MODIFY_METERS.GET_SUBSCRIPTIONS_FAILED');
          return of([]);
        })
      )),
      takeUntil(this._destroy$)
    );
  }
}
