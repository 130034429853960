import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ReportEvents } from '../../services/report-events.service';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportSeriesDataPoint } from '../../shared/reporting-series';
import { ReportingSeriesCollection } from '../../shared/reporting-series-collection';
import { ReportingGridConfig } from '../report-base';
import { ReportType } from '../../shared/report-type';

@Component({
  selector: 'report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['../report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportContentComponent {
  @Output() public readonly seriesClick = new EventEmitter<ReportSeriesDataPoint>();

  @Input() public quantityValues: ReportingSeriesCollection[];
  @Input() public gridsVisible: boolean;
  @Input() public chartsVisible: boolean;
  @Input() public searchParams: ReportingSearchParams;
  @Input() public notes: ReportEvents;
  @Input() public id: number;
  @Input() public gridConfig: ReportingGridConfig;
  @Input() public isMeterReport: boolean;
  @Input() public modalReportType: ReportType;

  public trackByMethod(_index: number, quantity: ReportingSeriesCollection): number {
    return quantity.quantityId;
  }

  public onSeriesClick(dataItem: ReportSeriesDataPoint): void {
    this.seriesClick.next(dataItem);
  }
}
