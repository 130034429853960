const $inject = ['$modalInstance', 'meter', 'utils', 'ManualQaApiMeterService', 'ManualQaPopupService'];

class ManualQaMeterHistoryModalController {
  constructor($modalInstance, meter, utils, ManualQaApiMeterService, ManualQaPopupService) {
    this.$modalInstance = $modalInstance;
    this.ManualQaApiMeterService = ManualQaApiMeterService;
    this.ManualQaPopupService = ManualQaPopupService;

    this.meterHistory = [];
    this.meter = meter;
    this.headerText = utils.localizedString('MQA.METER_HISTORY.MODAL_HEADER');

    this.getData();
  }

  getData() {
    this.ManualQaApiMeterService
      .getMeterHistory(this.meter)
      .then(meterHistory => this.meterHistory = meterHistory)
      .catch(() => {
        this.ManualQaPopupService.errorPopUp('MQA.ERRORS.METER_HISTORY');
      })
    ;
  }

  /**
   * Handles modal dismiss
   */
  dismiss() {
    this.$modalInstance.dismiss();
  }
}

ManualQaMeterHistoryModalController.$inject = $inject;

export default ManualQaMeterHistoryModalController;

