import { Pipe, PipeTransform } from '@angular/core';

import { RequestResolution } from '@enerkey/clients/reporting';

import TimeFrame from '../../../services/time-frame-service';

@Pipe({
  name: 'timeFrameTitle'
})
export class TimeFrameTitlePipe implements PipeTransform {
  public transform(date: Date, timeFrame: RequestResolution): string {
    return TimeFrame.timeRangeFormat(date, timeFrame);
  }
}
