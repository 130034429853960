import { ControlValueAccessor } from '@angular/forms';
import { ChangeDetectorRef, ContentChild, Directive, Input, OnDestroy, ViewChild } from '@angular/core';
import { DropDownFilterSettings, MultiSelectComponent as KendoMultiSelect } from '@progress/kendo-angular-dropdowns';
import { GroupResult } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';

import { LoadingSubject } from '@enerkey/rxjs';

import { EkComboItemTemplateDirective } from '../ek-combo-item-template.directive';

/* eslint-disable @angular-eslint/directive-class-suffix */

/**
 * @template T Source data type
 * @template V Value key
 * @template K Text key
 */
@Directive()
export abstract class MultiSelectComponent<T, V extends keyof T, K extends keyof T> implements
  ControlValueAccessor, OnDestroy {
  /** Displayed text for empty control */
  public abstract readonly placeholderKey: string;

  /** Displayed text for grouped items */
  public abstract readonly overflowKey: string;

  /** Key of form control value */
  public abstract readonly valueField: V;

  /** Key for displayed item text */
  public abstract readonly textField: K;

  /** Max items before they are grouped */
  public abstract overflowThreshold: number;

  public readonly loading$: Observable<boolean>;

  @Input() public selectAllEnabled = true;
  @Input() public translate = true;
  @Input() public filterable = false;

  @ViewChild(KendoMultiSelect) public multiSelect: KendoMultiSelect;

  @ContentChild(EkComboItemTemplateDirective)
  public readonly contentTemplate: EkComboItemTemplateDirective<T>;

  public items: T[] = [];
  public groupedItems: T[] | GroupResult[];
  public disabled = false;
  public value: T[V][] = [];
  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  protected readonly _loading = new LoadingSubject();

  protected _onChange: (value: T[V][]) => void;
  private _onTouched: () => void;

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.loading$ = this._loading.asObservable();
  }

  // eslint-disable-next-line no-invalid-this, @typescript-eslint/no-explicit-any
  public readonly tagMapper = (tags: any[]): any[] => tags.length < this.overflowThreshold ? tags : [tags];

  public ngOnDestroy(): void {
    this._loading.complete();
  }

  /** Kendo callback */
  public valueChanged(value: T[V][]): void {
    this.value = value;
    this._onChange?.(value);
  }

  /** Kendo callback */
  public blur(): void {
    this._onTouched?.();
  }

  /** ControlValueAccessor */
  public writeValue(value: T[V][]): void {
    this.value = value ?? [];
    this.changeDetectorRef.markForCheck();
  }

  /** ControlValueAccessor */
  public registerOnChange(fn: (value: T[V][]) => void): void {
    this._onChange = fn;
  }

  /** ControlValueAccessor */
  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  /** ControlValueAccessor */
  public setDisabledState?(isDisabled: boolean): void {
    if (!!this.disabled !== !!isDisabled) {
      this.disabled = isDisabled;
    }
  }

  public selectAll(): void {
    this.value = this.items.map(item => item[this.valueField]);
    this._onChange?.(this.value);
    this.multiSelect.toggle(false);
  }

  public filterChanged(_filter: string): void {
  }
}
