forecastTypes.$inject = ['utils', '_'];

function forecastTypes(utils, _) {

  function getForecastTypes() {
    return [
      {
        Id: 1,
        Key: 'LastYearActuals',
        Name: utils.localizedString('FACILITIES.FORECAST_TYPES.LAST_YEAR_ACTUALS'),
        Color: '#704bcb',
        DashType: 'longDash',
        Style: 'normal'
      },
      {
        Id: 2,
        Key: 'RealizedChange',
        Name: utils.localizedString('FACILITIES.FORECAST_TYPES.REALIZED_CHANGE'),
        Color: '#30b000',
        DashType: 'longDash',
        Style: 'normal'
      }
    ];
  }

  function getForecastTypeIds() {
    return _.pluck(getForecastTypes(), 'Id');
  }

  return { getForecastTypes, getForecastTypeIds };
}

export default forecastTypes;
