import templateModule from 'raw-loader!../templates/mrc-pagination.html';

export function mrcPagination() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      paginationOptions: "=",
      mrcState: "=",
      options: "=",
      facility: "="
    },
    controller: [
      '$scope',
      function ($scope) {
        $scope.select = function (page) {
          if (page !== $scope.paginationOptions.selected) {
            if($scope.facility!==undefined) {
              $scope.facility.readingsHistoryPages[$scope.options.meter.Id] = page;
            }
            $scope.paginationOptions.selected = page;
            $scope.paginationOptions.filter();
          }
        };
      }
    ]
  };
}
