import { PeriodReportActionsPopupController } from './period-report-actions-popup.controller';
import periodReportActionsPopupTemplate from 'raw-loader!./period-report-actions-popup.html';

export const periodReportActionsPopupComponent = {
  template: periodReportActionsPopupTemplate,
  controller: PeriodReportActionsPopupController,
  controllerAs: 'vm',
  bindings: {
    actionsAllowed: '<',
    item: '<',
    type: '<'
  }
};
