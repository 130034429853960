import { TinyColor } from '@ctrl/tinycolor';

import { indexToColumnCharacter } from '../shared/spreadsheet.functions';
import { ColorService } from '../shared/services/color.service';

interface SpreadsheetColors {
  error: string;
  success: string;
  default: string;
  headerBackground: string;
}

export class SpreadsheetService {
  public static readonly $inject = ['ColorService'];
  private colors: SpreadsheetColors = null;

  public constructor(private colorService: ColorService) {
  }

  public indexToColumnCharacter(index: number): string {
    return indexToColumnCharacter(index);
  }

  public getColors(): SpreadsheetColors {
    if (this.colors !== null) {
      return this.colors;
    }

    this.colors = {
      error: new TinyColor(this.colorService.getCssProperty('--enerkey-red')).toRgbString(),
      success: new TinyColor(this.colorService.getCssProperty('--enerkey-green')).setAlpha(0.5).toRgbString(),
      default: new TinyColor(this.colorService.getCssProperty('--enerkey-black')).toRgbString(),
      headerBackground: new TinyColor(this.colorService.getCssProperty('--enerkey-light-grey')).toRgbString()
    };

    return this.colors;
  }
}
