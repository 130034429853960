import { ENERKEY_CONFIG } from './config';

const isLocalHost = window.location.hostname === 'localhost';

const urlPrefix = isLocalHost
  ? 'portal-dev'
  : window.location.hostname.split('.', 1)[0];

const urlSuffix = isLocalHost
  ? 'enerkey.com'
  : window.location.hostname.slice(urlPrefix.length + 1);

(window as any).ENERKEY_CONFIG = Object.freeze(ENERKEY_CONFIG(urlPrefix, urlSuffix));
