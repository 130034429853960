import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { StateService } from '@uirouter/core';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import { QuantityItem } from '@enerkey/clients/energy-reporting';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ActionSlimViewModel } from '@enerkey/clients/attachments';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { WidgetBase } from '../../shared/widget-base.interface';
import { ValueType } from '../../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { TimeFrameOptions } from '../../../../constants/time-frame';
import { Comparability } from '../../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { WidgetChangeOption } from '../../../energy-reporting/shared/widget-constants';
import { TopChangedConsumptionsService } from '../../services/top-changed-consumptions.service';
import { QuantityService } from '../../../../shared/services/quantity.service';
import ErTimeFrameService from '../../../energy-reporting/services/er-time-frame.service';
import { TopChangedWidgetRow } from '../../shared/top-changed-consumptions';
import { AjsModalService } from '../../../../services/modal/modal.service';
import { ChangeWidgetComponentBase, ChangeWidgetResponse } from '../change-widget-base';
import { FacilityService } from '../../../../shared/services/facility.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';

export interface TopChangedConsumptionsWidgetOptions {
  biggestFallers: number;
  biggestGainers: number;
  changeOption: WidgetChangeOption;
  comparableOption: Comparability;
  comparisonPeriodOption: 'Default' | number;
  selectedQuantity: QuantityItem;
  timeFrameOption: TimeFrameOptions;
  unitKey: ReportingUnit;
  valueOption: ValueType;
  variableId: number;
  quantityId?: Quantities;
}

@Component({
  selector: 'top-changed-consumptions-widget',
  templateUrl: './top-changed-consumptions-widget.component.html',
  styleUrls: ['./top-changed-consumptions-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TopChangedConsumptionsService]
})
export class TopChangedConsumptionsWidgetComponent
  extends ChangeWidgetComponentBase<TopChangedConsumptionsWidgetOptions, TopChangedWidgetRow>
  implements OnDestroy, WidgetBase<TopChangedConsumptionsWidgetOptions> {

  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;

  public readonly actions$: Observable<Map<number, ActionSlimViewModel[]>>;

  private readonly _dataLoading$ = new LoadingSubject(true);
  private readonly _actionsLoading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();

  public constructor(
    private readonly topChangedConsumptionsService: TopChangedConsumptionsService,
    private readonly stateService: StateService,
    private readonly angularjsModalService: AjsModalService,
    quantityService: QuantityService,
    erTimeFrameService: ErTimeFrameService,
    facilityService: FacilityService,
    thresholdService: ThresholdService
  ) {
    super(quantityService, erTimeFrameService, facilityService, thresholdService);

    this.error$ = this._error$.asObservable();

    this.actions$ = this.data$.pipe(
      switchMap(data => this.topChangedConsumptionsService.getActions(data, this.start, this.quantityId).pipe(
        indicate(this._actionsLoading$)
      ))
    );
    this.loading$ = combineLatest([this._dataLoading$, this._actionsLoading$]).pipe(
      map(loadings => loadings.some(loading => loading))
    );
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._error$.complete();
    this._dataLoading$.complete();
    this._actionsLoading$.complete();
  }

  public onRowClick(facilityId: number): void {
    this.angularjsModalService.getModalWithComponent(
      'report-modal',
      {
        reportParams: {
          ...this.topChangedConsumptionsService.getDefaultStateParameters(this.dataModelOptions),
          facilityId: [facilityId]
        }
      }
    );
  }

  public goToFacilities(): void {
    this.stateService.go(
      'facilities.grid',
      {
        ...this.topChangedConsumptionsService.getDefaultStateParameters(this.dataModelOptions),
      }
    );
  }

  protected getData(
    quantity: QuantityItem,
    facilityIds: number[],
    threshold: number
  ): Observable<ChangeWidgetResponse<TopChangedWidgetRow>> {
    return this.topChangedConsumptionsService.getConsumptions(
      quantity, this.dataModelOptions, this.start, facilityIds, threshold
    ).pipe(
      indicate(this._dataLoading$),
      catchError(() => {
        this._error$.next();
        return EMPTY;
      })
    );
  }
}
