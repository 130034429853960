import ManualQaSearchListEditController from './manual-qa-search-list-edit-controller';
import template                         from 'raw-loader!./manual-qa-search-list-edit.html';

export default {
  template: template,
  controller: ManualQaSearchListEditController,
  controllerAs: 'vm',
  bindings: {
    searchList: '<',
    onSave: '<',
    onClose: '<'
  }
};
