import _ from 'lodash';

const $inject = ['$filter', 'MetersTreeListService'];

function MeterDetailsListController($filter, MetersTreeListService) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this;

  vm.items = MetersTreeListService.getDetailsListItems();

  vm.$onInit = onInit;

  function onInit() {
    vm.title = $filter('translate')('FACILITIES_REPORT.METER_DETAILS');
  }

  vm.getContent = getContent;

  function getContent(item) {
    if (item.range) {
      return formatRange(...item.range);
    }

    return _.get(vm.meter, item.variable);
  }

  function formatRange(startKey, endKey) {
    const start = _.get(vm.meter, startKey);
    const end = _.get(vm.meter, endKey);

    if (!start && !end) {
      return '';
    }

    const startDate = $filter('date')(start) || '';
    const endDate = $filter('date')(end) || '';

    return `${startDate}—${endDate}`.trim();
  }
}

MeterDetailsListController.$inject = $inject;

export default MeterDetailsListController;
