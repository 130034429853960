import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { BehaviorSubject, Observable, of, PartialObserver, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import jsonpatch from 'fast-json-patch';
import moment from 'moment';
import { startOfHour } from 'date-fns';

import {
  IMeterHistoryEntry,
  MeterHistory,
  MeterHistoryEntry,
  MeterHistoryEntryCreate,
  MeteringClient,
  MeteringType,
  Operation
} from '@enerkey/clients/metering';
import { formGroupFrom } from '@enerkey/ts-utils';
import { indicate } from '@enerkey/rxjs';
import { assertType, TypeEq, ValueCast } from '@enerkey/ts-utils';
import {
  AddEventOf,
  CancelEventOf,
  EditEventOf,
  RemoveEventOf,
  SaveEventOf
} from '@enerkey/ts-utils';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { MeterManagementMeter } from '@enerkey/clients/meter-management';

type PatchableProperties =
  Partial<Pick<MeterHistory, 'description' | 'name' | 'factor' | 'date' | 'customerMeterIdentifier' | 'meteringType'>>;

type PatchablePropertiesForm = ValueCast<PatchableProperties, moment.Moment, Date>;

const patchableProperties = [
  'date', 'description', 'factor', 'name', 'customerMeterIdentifier', 'meteringType'
] as const;

type PatchableProperty = typeof patchableProperties[number];

assertType<TypeEq<keyof PatchableProperties & keyof MeterHistory, PatchableProperty>>();

@Component({
  selector: 'meter-history',
  templateUrl: './meter-history.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterHistoryComponent implements OnInit {
  public readonly pageSize = 10;
  public readonly meterHistory$: Observable<MeterHistoryEntry[]>;
  public readonly refreshHistory$: ReplaySubject<void> = new ReplaySubject(1);
  public readonly datePickerFormat = 'y.M.d H' as const;
  public loadingHistory$ = new BehaviorSubject<boolean>(true);
  public updating$ = new BehaviorSubject<boolean>(false);
  public formGroup: UntypedFormGroup;
  public readonly meteringTypes: readonly number[];

  @Input() public meter: MeterManagementMeter;

  private editedRowIndex: number;
  private firstItem: MeterHistoryEntry;

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly toasterService: ToasterService
  ) {
    this.meterHistory$ = this.refreshHistory$.pipe(
      switchMap(
        () => this.meteringClient.getMeterHistoryEntries(this.meter.id)
          .pipe(indicate(this.loadingHistory$))
      ),
      map(history => this.setFromDatesToHistory(history)),
      tap(history => {
        this.firstItem = history[0] || this.getFirstItemInfoFromMeter();
      }),
      catchError(() => {
        this.toasterService.error('ADMIN.METER_HISTORY.REQUEST_FAILED');
        return of([]);
      })
    );
    this.meteringTypes = getNumericEnumValues(MeteringType);
  }

  public ngOnInit(): void {
    this.fetchHistory();
  }

  public addHandler({ sender }: AddEventOf<MeterHistoryEntry>): void {
    this.closeEditor(sender);
    sender.addRow(this.getFormGroup());
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEventOf<MeterHistoryEntry>): void {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.getFormGroup(dataItem));
  }

  public cancelHandler({ sender, rowIndex }: CancelEventOf<MeterHistoryEntry>): void {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler(
    { sender, rowIndex, formGroup, isNew, dataItem }: SaveEventOf<MeterHistoryEntry>
  ): void {
    this.removeMinutesAndSeconds(formGroup);
    const updateSubscription = isNew ?
      this.meteringClient.createMeterHistoryEntry(this.meter.id, (formGroup.value as MeterHistoryEntryCreate)) :
      this.meteringClient.patchMeterHistoryEntry(
        this.meter.id,
        dataItem.id,
        this.getChangesForPatch(dataItem, formGroup)
      );

    updateSubscription
      .pipe(indicate(this.updating$))
      .subscribe(
        this.getRequestCallback(
          isNew ?
            'ADMIN.METER_HISTORY.CREATE_FAILED' :
            'ADMIN.METER_HISTORY.UPDATE_FAILED'
        )
      );

    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }: RemoveEventOf<MeterHistoryEntry>): void {
    this.meteringClient.deleteMeterHistoryEntry(this.meter.id, dataItem.id)
      .pipe(indicate(this.updating$))
      .subscribe(
        this.getRequestCallback('ADMIN.METER_HISTORY.DELETE_FAILED')
      );
  }

  private getFirstItemInfoFromMeter(): MeterHistoryEntry {
    return new MeterHistoryEntry({
      description: this.meter.description ?? '',
      name: this.meter.name,
      customerMeterIdentifier: this.meter.customerMeterIdentifier,
      factor: this.meter.factor,
      meteringType: this.meter.meteringType as number,
    } as IMeterHistoryEntry);
  }

  private setFromDatesToHistory(history: MeterHistoryEntry[]): MeterHistoryEntry[] {
    return history.map((historyEntry, index) => {
      const nextItem = history[index + 1];
      historyEntry.fromDate = nextItem ? nextItem.date : undefined;
      return historyEntry;
    });
  }

  private getFormGroup(dataItem?: MeterHistoryEntry): UntypedFormGroup {
    const initialDate: Date = dataItem?.date ?? new Date();
    if (!dataItem) {
      dataItem = this.firstItem;
    }
    this.formGroup = formGroupFrom<PatchablePropertiesForm>({
      name: dataItem.name,
      factor: dataItem.factor,
      description: dataItem.description,
      date: initialDate,
      customerMeterIdentifier: dataItem.customerMeterIdentifier,
      meteringType: dataItem.meteringType,
    }, {
      name: Validators.required,
      factor: Validators.required,
      meteringType: Validators.required,
    });

    return this.formGroup;
  }

  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private fetchHistory(): void {
    this.refreshHistory$.next();
  }

  private getChangesForPatch(originalItem: MeterHistoryEntry, newValues: UntypedFormGroup): Operation[] {
    const originalValues = this.getPatchablePropertiesValues(originalItem);
    return this.getPatch(originalValues, newValues);
  }

  private removeMinutesAndSeconds(formGroup: UntypedFormGroup): void {
    const dateFormControl = formGroup.get('date');
    if (dateFormControl?.value) {
      const dateValue: Date = dateFormControl.value;
      dateFormControl.setValue(startOfHour(dateValue));
    }
  }

  private getPatchablePropertiesValues(originalItem: MeterHistoryEntry): PatchableProperties {
    const originalValues: PatchableProperties = {};

    const setValue = <K extends PatchableProperty>(key: K): void => {
      originalValues[key] = originalItem[key];
    };

    for (const key of patchableProperties) {
      setValue(key);
    }

    return originalValues;
  }

  private getPatch(originalValues: PatchableProperties, newValues: UntypedFormGroup): Operation[] {
    return jsonpatch.compare(this.getRawValue(originalValues), this.getRawValue(newValues.value))
      .map(operation => Operation.fromJS(operation));
  }

  /**
   * Get raw value which has it's dates converted to strings
   * Date objects cannot be patched
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getRawValue(value: any): any {
    return JSON.parse(JSON.stringify(value));
  }

  private getRequestCallback(errorMessage: string): PartialObserver<unknown> {
    return {
      next: () => this.fetchHistory(),
      error: () => this.toasterService.error(errorMessage)
    };
  }
}
