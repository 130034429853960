import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

import { indicate } from '@enerkey/rxjs';
import {
  CreateFacilityCustomerField,
  FacilityClient,
  FacilityCustomerFieldConnectParameters,
  Taxonomy,
  UpdateFacilityCustomerField
} from '@enerkey/clients/facility';

import { FacilityEditService } from '../../services/facility-edit.service';

interface FacilityField {
  id: number;
  name: string;
  taxonomy: string;
  value: string;
  isOnFacility: boolean;
}

@Component({
  selector: 'facility-customer-fields-table',
  templateUrl: './facility-customer-fields-table.component.html',
  styleUrls: ['./facility-customer-fields-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityCustomerFieldsTableComponent implements OnDestroy {
  public rowInEdit: number = null;
  public showTaxonomyCol: boolean;

  public readonly customerFieldsForm: FormGroup;
  public readonly combinedCustomerFields$: Observable<FacilityField[]>;
  public readonly addedCustomerFieldIds$: Observable<Record<number, boolean>>;
  public readonly updatedCustomerFieldIds$: Observable<Record<number, boolean>>;
  public readonly deletedCustomerFieldIds$: Observable<Record<number, boolean>>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new BehaviorSubject<boolean>(true);

  private readonly _collator = new Intl.Collator();

  public constructor(
    private readonly facilityEditService: FacilityEditService,
    private readonly facilityClient: FacilityClient
  ) {
    this.loading$ = this._loading$.asObservable();
    this.addedCustomerFieldIds$ = this.facilityEditService.addedCustomerFieldIds$.pipe(
      map(ids => ids.toRecord(id => id, _ => true))
    );
    this.updatedCustomerFieldIds$ = this.facilityEditService.updatedCustomerFieldIds$.pipe(
      map(ids => ids.toRecord(id => id, _ => true))
    );
    this.deletedCustomerFieldIds$ = this.facilityEditService.deletedCustomerFieldIds$.pipe(
      map(ids => ids.toRecord(id => id, _ => true))
    );

    this.customerFieldsForm = new FormGroup({
      value: new FormControl('', Validators.required)
    });

    this.combinedCustomerFields$ = this.facilityEditService.facilityId$.pipe(
      take(1),
      switchMap(facilityId => forkJoin([
        this.facilityClient.getFacilities([facilityId]).pipe(
          switchMap(facilities => facilities[0].companyId
            ? this.facilityClient.getCustomerFields(facilities[0].companyId)
            : of([]))
        ),
        this.facilityClient.getCustomerFieldsForFacility(facilityId)
      ])),
      indicate(this._loading$),
      map(([companyFields, facilityFields]) => companyFields.map(
        companyField => {
          const id = companyField.id;
          const name = companyField.name;
          const taxonomy = companyField.taxonomies ? this.taxonomyParser(companyField.taxonomies) : '';
          const facilityField = facilityFields.find(f => f.customerFieldId === id);
          const value = facilityField?.value ?? '';
          const isOnFacility = !!facilityField;
          return { id: id, name: name, taxonomy: taxonomy, value: value, isOnFacility: isOnFacility };
        }
      ).sort((a, b) =>
        this.compareBool(a.isOnFacility, b.isOnFacility) ||
        this._collator.compare(a.taxonomy, b.taxonomy) ||
        this._collator.compare(a.name, b.name))),
      tap(fields => (this.showTaxonomyCol = fields.map(f => !!f.taxonomy).some(t => t)))
    );
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public editField(field: FacilityField, index: number): void {
    this.rowInEdit = index;
    this.customerFieldsForm.patchValue({ value: field.value });
  }

  public saveField(field: FacilityField): void {
    if (field.value !== this.newValue) {
      if (field.isOnFacility) {
        const updateData = new UpdateFacilityCustomerField({
          customerFieldId: field.id,
          value: field.value,
          updateValue: this.newValue
        });
        this.facilityEditService.updateFacilityCustomerField(updateData);
        field.value = this.newValue;
      } else {
        const createData = new CreateFacilityCustomerField({
          customerFieldId: field.id,
          value: this.newValue
        });
        this.facilityEditService.addFacilityCustomerField(createData);
        field.value = this.newValue;
        field.isOnFacility = true;
      }
    }
    this.rowInEdit = null;
  }

  public deleteField(field: FacilityField): void {
    this.facilityEditService.deleteFacilityCustomerField(
      new FacilityCustomerFieldConnectParameters({
        customerFieldId: field.id,
        value: field.value
      })
    );
    field.value = null;
    field.isOnFacility = false;
    this.rowInEdit = null;
  }

  public cancelEdit(): void {
    this.rowInEdit = null;
  }

  private get newValue(): string {
    return this.customerFieldsForm.value.value;
  }

  private taxonomyParser(taxonomies: Taxonomy[]): string {
    let taxonomyString: string = '';
    let currentParent: number = null;
    taxonomies.forEach(() => {
      const taxonomy = taxonomies.find(t => t.parentId === currentParent);
      taxonomyString += `${taxonomy.name} / `;
      currentParent = taxonomy.id;
    });
    return taxonomyString;
  }

  private compareBool(a: boolean, b: boolean): number {
    return Number(b) - Number(a);
  }
}
