import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Host, Optional, SkipSelf } from '@angular/core';
import { ColumnBase, ColumnGroupComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'kendo-grid-table-report-column-group',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: ColumnBase,
      useExisting: forwardRef(() => KendoGridTableReportColumnGroupComponent)
    }
  ],
})
export class KendoGridTableReportColumnGroupComponent extends ColumnGroupComponent {
  public constructor(
    public el: ElementRef,
    @Optional() @SkipSelf() @Host() parent: ColumnBase
  ) {
    super(parent);
    this.headerClass = 'table-report-group-header';
  }
}
