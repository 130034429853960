import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  MeteringClient,
  PowerOfAttorney,
  PowerOfAttorneySearchCriteria,
  SwaggerException
} from '@enerkey/clients/metering';

import { PoaSearchParams } from './power-of-attorney.service';

@Injectable({ providedIn: 'root' })
export class PowerOfAttorneySearchService {
  public constructor(
    private readonly meteringClient: MeteringClient
  ) {
  }

  public getPowerOfAttorneys(searchParams: PoaSearchParams): Observable<PowerOfAttorney[]> {
    return searchParams.poaId
      ? this.getPoaById(searchParams.poaId)
      : this.getPoaBySearchParams(searchParams)
    ;
  }

  private getPoaById(poaId: number): Observable<PowerOfAttorney[]> {
    return this.meteringClient.getPowerOfAttorneyById(poaId).pipe(
      map(poa => [poa]),
      catchError(
        (error: SwaggerException) => error.status === 404
          ? of([])
          : throwError(error)
      )
    );
  }

  private getPoaBySearchParams(searchParams: PoaSearchParams): Observable<PowerOfAttorney[]> {
    return this.meteringClient.getPowerOfAttorneyBySearchCriteria(
      new PowerOfAttorneySearchCriteria({
        companyId: searchParams.companyId ?? undefined,
        companyOvt: searchParams.companyOvt ?? undefined,
        name: searchParams.poaName ?? undefined,
      })
    );
  }
}
