import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const manualQaTopbarState: UIStateNg2<'mqa'> = {
  name: 'mqa',
  data: {
    auth: {
      roles: [Roles.QUALITY_MANAGER]
    },
    translationKey: 'MQA.TITLE'
  },
};
