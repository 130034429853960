import { parseLocaleStringToNumber } from '@enerkey/ts-utils';

const $inject = ['utils', '$modalInstance', 'parameters'];

function ManualQaConsumptionInputController(utils, $modalInstance, parameters) {
  const vm = this;

  vm.parameters = angular.copy(parameters);

  vm.close = close;
  vm.dismiss = dismiss;
  vm.onChange = onChange;

  function close() {
    $modalInstance.close({
      timeFrame: vm.parameters.timeFrame,
      consumption: parseLocaleStringToNumber(vm.parameters.consumption)
    });
  }

  function dismiss() {
    $modalInstance.dismiss();
  }

  /**
   * Handles consumption value change
   *
   * @param {Number} consumption
   */
  function onChange(consumption) {
    vm.parameters.consumption = consumption;
  }
}

ManualQaConsumptionInputController.$inject = $inject;

export default ManualQaConsumptionInputController;
