import 'angular-translate';

import YearlyConsumptionInputComponent from './yearly-consumption-input-component';
import EnerkeyServicesModule           from '../../services';
import ConfigurationServicesModule     from '../../modules/configuration/services';
import ConfigurationModelsModule       from '../../modules/configuration/models';

const dependencies = [
  EnerkeyServicesModule.name,
  ConfigurationServicesModule.name,
  ConfigurationModelsModule.name,
  'pascalprecht.translate'
];

const YearlyConsumptionInputModule = angular.module('app.components.yearly-consumption-input', dependencies)
  .component('yearlyConsumptionInput', YearlyConsumptionInputComponent);

export default YearlyConsumptionInputModule;