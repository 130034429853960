<kendo-chart
  [transitions]="false"
  [categoryAxis]="{ visible: false }"
  [valueAxis]="{ visible: false }"
  [legend]="{ visible: false }"
>
  <ng-template kendoChartDonutCenterTemplate>
    <h2>{{ data.totalEmissions | kendoNumber:'#,#' }}</h2>
    <span
      class="emission-change"
      [ekValueChange]="data.emissionChange"
    ></span>
  </ng-template>
  <kendo-chart-area [margin]="0"></kendo-chart-area>
  <kendo-chart-tooltip>
    <ng-template kendoChartSeriesTooltipTemplate
      let-category="category"
      let-percentage="percentage"
    >
      {{ category | quantityName | async }} - {{ percentage | kendoNumber:'#.0 %' }}
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <kendo-chart-series-item
      [autoFit]="true"
      type="donut"
      [data]="data.chartData"
      [field]="'value'"
      [opacity]="0.8"
      [categoryField]="'quantityId'"
      [colorField]="'color'"
      [holeSize]="65"
    >
      <kendo-chart-series-item-labels
        position="outsideEnd"
        [visual]="contentVisual"
      >
      </kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
