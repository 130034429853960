import { AppStatusService } from '../../../../services/app-status-service';
import { ReportNote } from '../../shared/report-note';
import { PeriodReportItem } from '../../interfaces/period-report-item';

export class PeriodReportAlarmsPopupController {

  public static readonly $inject = ['utils', 'appStatusService'];

  public alarmsAllowed: boolean;
  public item: PeriodReportItem;

  public notes: ReportNote[];
  public title: string;
  public isMobile: boolean;

  public constructor(
    private readonly utils: any,
    private readonly appStatusService: AppStatusService
  ) {}

  public $onInit(): void {
    this.notes = this.item.notes.alarms;
    this.title = this.getTitle();
    this.isMobile = this.appStatusService.isMobile;
  }

  private getTitle(): string {
    const meter = this.item.meter ? '_METER' : '';
    const translationKey = `FACILITIES_REPORT.LATEST${meter}_ALARMS_POPUP_TITLE`;
    return this.utils.localizedString(translationKey);
  }
}
