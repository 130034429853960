import _ from 'lodash';

const $inject = [
  'utils',
  '$rootScope', 'ManualQaAssignOperationPeriodCommand',
  'ManualQaMeterChangeCommand', 'ManualQaFixedConsumptionCommand', 'ManualQaHourlyConsumptionCommand',
  'ManualQaMonthlyConsumptionCommand'
];

class ManualQaSpreadsheetContextMenuService {
  constructor(
    utils,
    $rootScope, ManualQaAssignOperationPeriodCommand,
    ManualQaMeterChangeCommand, ManualQaFixedConsumptionCommand, ManualQaHourlyConsumptionCommand,
    ManualQaMonthlyConsumptionCommand
  ) {
    this.utils = utils;
    this.$rootScope = $rootScope;
    this.ManualQaAssignOperationPeriodCommand = ManualQaAssignOperationPeriodCommand;
    this.ManualQaMeterChangeCommand = ManualQaMeterChangeCommand;
    this.ManualQaFixedConsumptionCommand = ManualQaFixedConsumptionCommand;
    this.ManualQaHourlyConsumptionCommand = ManualQaHourlyConsumptionCommand;
    this.ManualQaMonthlyConsumptionCommand = ManualQaMonthlyConsumptionCommand;
  }

  /**
   * Creates cell context menu for spreadsheet
   *
   * @param {Object} controllerInstance
   */
  createCellContextMenu(controllerInstance) {
    const self = this;

    const cellContextMenu = controllerInstance.spreadsheet.cellContextMenu();
    const commands = this.getCommands(controllerInstance);

    cellContextMenu.remove('li'); // Remove all items
    cellContextMenu.append(commands); // Append own commands
    cellContextMenu.unbind('select');
    cellContextMenu.bind('select', onSelect);

    function onSelect(event) {
      const selectedCommand = angular.element(event.item);
      const command = _.find(commands, { text: selectedCommand.text() });

      if (command) {
        // Needs $apply -call so angular knows when variables changes
        self.$rootScope.$apply(() => {
          command.action();
        });
      }
    }
  }

  /**
   * Returns custom commands for context menu.
   *
   * @param {Object} controllerInstance
   *
   * @returns {Array}
   */
  getCommands(controllerInstance) {
    const commands = [
      this.ManualQaAssignOperationPeriodCommand.getCommand(controllerInstance),
      this.ManualQaMeterChangeCommand.getCommand(controllerInstance),
      this.ManualQaFixedConsumptionCommand.getCommand(controllerInstance),
      this.ManualQaHourlyConsumptionCommand.getCommand(controllerInstance),
      this.ManualQaMonthlyConsumptionCommand.getCommand(controllerInstance),
    ];

    return commands;
  }
}

ManualQaSpreadsheetContextMenuService.$inject = $inject;

export default ManualQaSpreadsheetContextMenuService;
