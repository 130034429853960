<div class="terminal-info">
    <div class="row">
      <span class="property-label">{{ 'ADMIN.TERMINAL.IDENTIFIER' | translate }}</span>
      <div class="property-text-singleline">{{ terminal?.identifier }}</div>
    </div>
    <div class="row">
      <span class="property-label">{{ 'ADMIN.TERMINAL.NAME' | translate }}</span>
      <div class="property-text-singleline">{{ terminal?.name }}</div>
    </div>
    <div class="row">
      <span class="property-label">{{ 'CONFIGURATION_CONTROL.TERMINAL.STATUS' | translate }}</span>
      <div class="property-text-singleline">
        <span class="{{ terminalReadingStatusIndicator }}">{{ terminalReadingStatusText  | translate  }}</span>
      </div>
    </div>
    <div class="row">
      <span class="property-label">{{ 'ADMIN.TERMINAL.DESCRIPTION' | translate }}</span>
      <div class="property-text-multiline">{{ terminal?.description }}</div>
    </div>
    <div class="row">
      <span class="property-label">{{ 'ADMIN.TERMINAL.CONNECTION_INFORMATION' | translate }}</span>
      <div class="property-text-multiline">{{ terminal?.connectionInformation }}</div>
    </div>
    <div class="row">
      <span class="property-label">{{ 'ADMIN.TERMINAL.USERNAME' | translate }}</span>
      <div class="property-text-singleline">{{ terminal?.userName }}</div>
    </div>
    <div class="row">
      <span class="property-label">{{ 'ADMIN.TERMINAL.PASSWORD' | translate }}</span>
      <div class="property-text-singleline">{{ terminal?.password }}</div>
    </div>
    <div class="row">
      <span class="property-label">{{ 'COMMENT' | translate }}</span>
      <div class="property-text-singleline">{{ terminal?.comment }}</div>
    </div>
  </div> 
<br>

<terminal-status-grid
  [terminalName]="terminalName"
  [terminalMeters]="terminalMeters$ | async"
  (showInterfaceStatus)="onShowMeterInterfaceStatus($event)"
  >
</terminal-status-grid>
