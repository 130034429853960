<form [formGroup]="formGroup">
  <div class="formpart">
    <input
      id="comments-widget-options-consumption"
      type="checkbox"
      formControlName="consumption"
      kendoCheckBox
    >
    <label for="comments-widget-options-consumption">
      {{ 'LATEST_ACTIONS_WIDGET.COMMENTTYPE_CONSUMPTION' | translate }}
    </label>

    <input
      id="comments-widget-options-other"
      type="checkbox"
      formControlName="other"
      kendoCheckBox
    >
    <label for="comments-widget-options-other">
      {{ 'LATEST_ACTIONS_WIDGET.COMMENTTYPE_OTHER' | translate }}
    </label>
  </div>

  <div class="formpart">
    <ng-container *ngIf="!showMobileCount">
      <ek-slider-number-box
        formControlName="count"
        [min]="minCount"
        [max]="maxCount"
        *labelBefore="'LATEST_ACTIONS_WIDGET.NUMBER_OF_COMMENTS_TO_SHOW' | translate"
      ></ek-slider-number-box>
    </ng-container>

    <ng-container *ngIf="showMobileCount">
      <label>{{ 'LATEST_ACTIONS_WIDGET.TYPES_TO_SHOW' | translate }}</label>
      <ek-slider-number-box
        formControlName="mobileCount"
        [min]="minCount"
        [max]="maxCount"
        *labelBefore="'LATEST_ACTIONS_WIDGET.NUMBER_OF_COMMENTS_TO_SHOW' | translate"
      ></ek-slider-number-box>
    </ng-container>
  </div>
</form>
