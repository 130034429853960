<kendo-chart
  [legend]="{ visible: false }"
  [transitions]="false"
  (render)="onRender($event)"
>
  <kendo-chart-title [text]="title"></kendo-chart-title>
  <kendo-chart-series>
    <kendo-chart-series-item
      type="donut"
      [data]="series$ | async"
      field="value"
      categoryField="scope"
      colorField="color"
      [border]="seriesBorder"
      [autoFit]="true"
      [visual]="visualHandler"
    >
      <kendo-chart-series-item-labels
        position="outsideEnd"
        color="#0a0a0a"
        [content]="labelContent"
      ></kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
