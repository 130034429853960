import templateModule from 'raw-loader!../templates/mrc-meter-info.html';

export function mrcMeterInfo() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      options: "=",
      refresh: "=",
      mrcState: "="
    },
    controller: [
      '$scope', '$translate', 'mrcapi', 'mrcModals', 'utils', 'MrcConstants', 'StateLocationService',
      ($scope, $translate, mrcapi, mrcModals, utils, MrcConstants, StateLocationService) => {
        $scope.MrcConstants = MrcConstants;
        $scope.loadingData = false;
        const twoTimeMeasurementTranslationToken = $scope.options.meter.TwoTimeMeasurement ?
          'MRC.READINGS.WIZARD.LABELS.MULTI' :
          'MRC.READINGS.WIZARD.LABELS.SINGLE';
        $scope.meterTypeValue = $translate.instant('MRC.METERING_TYPES.' + $scope.options.meter.MeteringType);
        $scope.meteringTypeValue = $translate.instant(twoTimeMeasurementTranslationToken);

        function init() {
          $scope.meterInfo = [
            { Property: 'Name', Value: $scope.options.meter.Name,
              TranslationToken: 'MRC.READINGS.WIZARD.LABELS.METER_NAME' },
            { Property: 'Description', Value: $scope.options.meter.Description,
              TranslationToken: 'MRC.READINGS.WIZARD.LABELS.DESCRIPTION' },
            { Property: 'MeteringType',
              Value: $translate.instant('MRC.METERING_TYPES.' + $scope.options.meter.MeteringType),
              TranslationToken: 'MRC.READINGS.WIZARD.LABELS.METER_TYPE' },
            { Property: 'CustomerMeterIdentifier', Value: $scope.options.meter.CustomerMeterIdentifier,
              TranslationToken: 'MRC.READINGS.WIZARD.LABELS.IDENTIFICATION' },
            { Property: 'TwoTimeMeasurement', Value: $translate.instant(twoTimeMeasurementTranslationToken),
              TranslationToken: 'MRC.READINGS.WIZARD.LABELS.METERING_TYPE' }
          ];
          if ($scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING) {
            $scope.meterInfo.push({ Property: 'Factor', Value: $scope.options.meter.Factor,
                                    TranslationToken: 'MRC.READINGS.WIZARD.LABELS.FACTOR' });
          } else {
            $scope.meterInfo.push({ Property: '', Value: '' });
          }
          $scope.options.meter.newFactor = angular.copy($scope.options.meter.Factor);
        }

        init();

        $scope.$on('meterDataUpdated', () =>{
          init();
        });

        $scope.showMeterReport = (facilityId, meterId) => {
          const stateParams = {
            modalParams: {
              reportType: 'modal.metertree',
              reportParams: { facilityId: [facilityId], meterId: [meterId] }
            }
          };
          StateLocationService.openStateInNewTab('facilities.grid', stateParams);
        };

        $scope.save = newValue => {
          mrcModals.showConfirmationModal('MRC.READINGS.CONFIRM_FACTOR_CHANGE').then(() => {
            const options = {
              reportingObjectId: $scope.options.facilityId,
              meterId: $scope.options.meter.Id,
              newFactor: newValue
            };
            mrcapi.changeFactor(options).then( () => {
              $scope.refresh();
            }, () =>{
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER_FACTOR', true);
            });
          }, () => {
            $scope.options.meter.newFactor = angular.copy($scope.options.meter.Factor);
          });
        };
      }
    ]
  };
}
