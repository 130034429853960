import ManualQaSearchCriteriaController from './manual-qa-search-criteria.controller';
import template                         from 'raw-loader!./manual-qa-search-criteria.html';

const ManualQaSearchCriteriaComponent = {
  template: template,
  controller: ManualQaSearchCriteriaController,
  controllerAs: 'vm',
  bindings: {
    searchList: '<',
    readerTypes: '<',
    isLoading: '<',
    onSearch: '&',
    onReset: '&'
  }
};

export default ManualQaSearchCriteriaComponent;
