import moment   from 'moment';
import { guid } from '@enerkey/ts-utils';

export default class Fault {
  constructor(data) {
    this.id = data && data.id ? data.id : guid();
    this.defectIssueId = data && data.defectIssueId ? data.defectIssueId : null;
    this.timestamp = data && data.timestamp ? moment(data.timestamp) : null;
    this.faultTypes = data && data.faultTypes ? data.faultTypes : [];
  }

  /**
   * Returns fault id
   *
   * @returns {string}
   */
  getId() {
    return this.id;
  }

  /**
   * Returns defect issue id
   *
   * @returns {number|null}
   */
  getDefectIssueId() {
    return this.defectIssueId;
  }

  /**
   * Returns defect issue timestamp
   *
   * @returns {moment|null}
   */
  getTimestamp() {
    return this.timestamp;
  }

  /**
   * Returns fault types
   *
   * @returns {number[]}
   */
  getFaultTypes() {
    return this.faultTypes;
  }
}
