import { StateService } from '@uirouter/angularjs';
import _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { $InjectArgs } from '@enerkey/ts-utils';

import { TelemetryService } from '../../../services/telemetry.service';
import { isCost } from '../../reportingobjects/shared/relational-value-functions';
import { ReportSettings } from '../shared/report-settings';

interface ReportSettingsCallback {
  func: () => void;
  param: string;
}

type ChangeCallback = (arg: ReportSettings) => void;

export interface IErReportSettingsService {
  createNewInstance(instanceName: string): ErReportSettings;
  getInstance(instanceName?: string): ErReportSettings;
  setActiveInstance(instanceName: string): void;
}

export class ErReportSettings { // TODO merge this and ReportSettings class

  private callbacks: ReportSettingsCallback[] = [];
  private params: ReportSettings = new ReportSettings();

  private reportTypes: any[];
  private changeReportType: any;

  public constructor(
    private readonly $timeout: ng.ITimeoutService,
    private readonly settingsChanged?: ChangeCallback
  ) { }

  public setSettings(params: ReportSettings): void {
    this.params = params;
    this.callbacks.forEach(callback => {
      callback.func();
    });
    this.settingsChanged?.(this.params);
  }

  // TODO return new instance every time. Might save from lots of problems in the future
  public getSettings(): ReportSettings {
    return this.params;
  }

  public changeSetting<T extends keyof ReportSettings>(param: T, value: ReportSettings[T]): void {
    _.set(this.params, param, value);
    if (this.params.series?.Resolution === 'PT1H' && Array.hasItems(this.params?.series.RelationalUnitIds)) {
      this.params.series.RelationalUnitIds = this.params.series.RelationalUnitIds.filter(unit => !isCost(unit));
    }
    this.callbacks.forEach(callback => {
      if (callback.param === param || callback.param === undefined) {
        callback.func();
      }
    });
    this.settingsChanged?.(this.params);
  }

  public resetSettings(): void {
    this.setSettings(new ReportSettings());
  }

  public setCallback(func: () => void, watchParam: string): () => void {
    if (_.isFunction(func)) {
      const callback = {
        func: func,
        param: watchParam
      };
      this.callbacks.push(callback);
      return () => {
        this.removeCallback(callback);
      };
    }
  }

  public removeCallback(callback: ReportSettingsCallback): void {
    const indexToBeRemoved = this.callbacks.indexOf(callback);
    this.callbacks.splice(indexToBeRemoved, 1);
  }

  /**
   * A workaround for refreshing ER modal report state after adding an action.
   * Reload is implemented by quickly cycling between a non-existing report type
   * and the currently selected one.
   */
  public reload(): void {
    const reportType = this.reportTypes.find(report => report.selected);
    this.changeReportType({ reportType: { name: '' } });
    this.$timeout(() => this.changeReportType({ reportType }), 0);
  }

  public setChangeReportType(changeReportType: any): void {
    this.changeReportType = changeReportType;
  }

  public setReportTypes(reportTypes: any[]): void {
    this.reportTypes = reportTypes || [];
  }
}

export default class ErReportSettingsService implements IErReportSettingsService, ng.IOnDestroy {

  public static readonly $inject: $InjectArgs<typeof ErReportSettingsService> = [
    '$timeout',
    '$state',
    'TelemetryService',
  ];

  private instances: { [key: string]: ErReportSettings } = {};
  private instanceName = '';

  private readonly _change$ = new Subject<ReportSettings>();
  private readonly _subscription: Subscription;

  public constructor(
    private readonly $timeout: ng.ITimeoutService,
    private readonly stateService: StateService,
    private readonly telemetry: TelemetryService
  ) {
    this._subscription = this._change$.pipe(
      debounceTime(5_000),
      filter(r => r?.series && Object.keys(r.series).length !== 0)
    ).subscribe({
      next: r => {
        const stateName = this.stateService.current.name;

        // check if user already left ER or closed the facility/meter modal
        if (stateName?.startsWith('facilities.') || r.name?.startsWith('modal.')) {
          this.sendTelemetry(stateName, r);
        }
      },
    });
  }

  public createNewInstance(instanceName: string): ErReportSettings {
    const erSettings = new ErReportSettings(this.$timeout, s => this._change$.next(s));
    this.instances[instanceName] = erSettings;
    return erSettings;
  }

  public getInstance(instanceName?: string): ErReportSettings {
    instanceName = instanceName || this.instanceName;
    return this.instances[instanceName];
  }

  public setActiveInstance(instanceName: string): void {
    this.instanceName = instanceName;
  }

  public $onDestroy(): void {
    this._change$.complete();
    this._subscription.unsubscribe();
  }

  private sendTelemetry(state: string, params: ReportSettings): void {
    const payload: Record<string, unknown> = {
      stateName: state,
      settingsName: params.name ?? '',
      quantityIds: params.quantityId,
      facilityIds: params.facilityId,
      meterIds: params.meterId,
      displayUnit: params.unitKey ?? 'Default',
      changeType: params.changeType,
      showMeasurement: !!params.showMeasurement,
      showComparison: !!params.series.ShowComparison,
      showTemperature: !!params.series.Temperature,
      showMeasured: !!params.series.Measured,
      showNormalized: !!params.series.Normalized,
      resolution: params.series.Resolution,
      timeframe: params.series.TimeFrame,
      relatedIds: params.series.RelatedValues ?? [], // min/max and related
      relationalIds: params.series.RelationalUnitIds ?? [], // specific consumptions, costs, emissions
      distributionId: params.series.DistributionId ?? null, // 1=workday/holiday, 2=day/night
      distributionPercent: params.series.DistributionAsPercent, // show distributions as % and not eg. kWh
      eventTypes: params.series.EventTypes ?? [], // actions, comments, alarms
      actionsImpactQuantities: params.series.ActionsImpactTypes ?? [],
      targetTypes: params.series.ConsumptionTargetSeriesTypes ?? [],
      comparability: params.series.Comparables ?? '',
      totalTimeRange: { from: params.series.BeginEndDates.from, to: params.series.BeginEndDates.to },
      series: params.series.Start.map(x => x.value),
    };

    this.telemetry.trackEvent('sidebar-settings', payload);
  }
}
