import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { EMPTY, forkJoin, map, Observable, shareReplay, Subject, take, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { getStringEnumValues } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import {
  Co2eq,
  IUpdateRowUnit,
  Report,
  RowUnit,
  RowUnitType,
  SustainabilityClient
} from '@enerkey/clients/sustainability';
import { Quantities } from '@enerkey/clients/metering';

import { GriReportService } from '../../services/gri-report.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { quantityTranslations } from '../../../../constants/quantity.constant';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { getCo2EqName } from '../../pipes/gri-co2factor.pipe';

type UnitEditorForm = {
  name: FormControl<string>;
  unit: FormControl<string>;
  source: FormControl<string>;
  co2factor: FormControl<number>;
  co2eq: FormControl<Co2eq>;
};

@Component({
  selector: 'gri-unit-editor-modal',
  templateUrl: './gri-unit-editor-modal.component.html',
  styleUrls: ['./gri-unit-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ size: 'tiny' })
export class GriUnitEditorModalComponent extends ModalBase<IUpdateRowUnit> implements OnInit, OnDestroy {

  public readonly co2eqOptions: ComboItem<Co2eq>[] = getStringEnumValues(Co2eq)
    .map<ComboItem<Co2eq>>(eq => ({ value: eq, text: getCo2EqName(eq) }));

  public get modalTitle(): string {
    if (this.existingUnit) {
      return this.existingUnit.isDefault
        ? 'SUSTAINABILITY.GRI.INSPECT_UNIT'
        : 'SUSTAINABILITY.GRI.EDIT_UNIT';
    }

    return 'SUSTAINABILITY.GRI.ADD_UNIT';
  }

  public existingUnit: IUpdateRowUnit;
  public currentReport: Report;
  public isNew: boolean;
  public counts$: Observable<{ rows: number, reports: number }>;

  public formGroup: FormGroup<UnitEditorForm>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly service: GriReportService,
    private readonly susClient: SustainabilityClient,
    private readonly toaster: ToasterService,
    private readonly translateService: TranslateService
  ) {
    super(currentModal);

    this.loading$ = this._loading$.asObservable();

    this.service.report$.pipe(
      shareReplay(1),
      takeUntil(this._destroy$)
    ).subscribe(report => {
      this.currentReport = report;
    });
  }

  public ngOnInit(): void {
    if (this.existingUnit) {
      this.counts$ = forkJoin({
        reportId: this.service.report$.pipe(take(1), map(r => r.id)),
        counts: this.susClient.getRowCountForUnit(this.currentReport.profileId, this.existingUnit.id),
      }).pipe(
        map(({ counts, reportId }) => ({
          rows: counts[reportId] ?? 0,
          reports: Object.keys(counts).length,
        }))
      );
    } else {
      this.counts$ = EMPTY;
    }

    const name = this.existingUnit?.isDefault && this.existingUnit.quantityId
      ? this.translateService.instant(quantityTranslations[this.existingUnit.quantityId as Quantities])
      : this.existingUnit?.name;

    this.formGroup = new FormGroup<UnitEditorForm>({
      name: new FormControl<string>(name ?? '', [Validators.required, Validators.minLength(3)]),
      unit: new FormControl<string>(this.existingUnit?.unit ?? '', Validators.required),
      source: new FormControl<string>(this.existingUnit?.source ?? ''),
      co2factor: new FormControl<number>(
        this.existingUnit?.co2Factor,
        Validators.required
      ),
      co2eq: new FormControl<Co2eq>(this.existingUnit?.co2Eq ?? Co2eq.Tonnes),
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public submit(): void {
    if (!this.formGroup.valid || this.existingUnit?.isDefault) {
      return;
    }

    const value = this.formGroup.value;
    const payload = new RowUnit({
      name: value.name,
      unit: value.unit,
      co2Factor: value.co2factor,
      source: value.source,
      co2Eq: value.co2eq,
      isDefault: false,
      reportId: this.currentReport.id,
      rowUnitType: RowUnitType.Custom
    });

    const request$: Observable<unknown> = this.existingUnit
      ? this.susClient.updateRowUnits(this.currentReport.profileId, this.existingUnit.id, payload)
      : this.susClient.createRowUnits(this.currentReport.profileId, [payload]);

    request$.pipe(indicate(this._loading$)).subscribe({
      next: response => {
        this.toaster.success('SUSTAINABILITY.GRI.SAVE_SUCCEEDED');
        this.service.unitsChanged();
        const unit: IUpdateRowUnit = this.existingUnit ?
          { ...payload, id: this.existingUnit.id }
          : (response as IUpdateRowUnit[])[0];
        super.closeModal(unit);
      },
      error: /* istanbul ignore next */ () => {
        this.toaster.error('SUSTAINABILITY.GRI.SAVE_FAILED');
      },
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

}
