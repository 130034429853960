import { NgModule } from '@angular/core';

import { PropertyPathPipe } from './property-path.pipe';
import { ExcelFileNamePipe } from './excel-file-name.pipe';
import { MonthNamePipe } from './month-name.pipe';
import { RelationalValueNamePipe } from './relational-value-name.pipe';
import { RelationalValueUnitPipe } from './relational-value-unit.pipe';
import { QuantityColorPipe } from './quantity-color.pipe';
import { QuantityKeyPipe } from './quantity-key.pipe';
import { QuantityUnitPipe } from './quantity-unit.pipe';
import { QuantityNamePipe } from './quantity-name.pipe';
import { MeteringTypePipe } from './metering-type.pipe';
import { QuantityMeteringTypePipe } from './quantity-metering-type.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ToCurrencyPipe } from './to-currency.pipe';

@NgModule({
  declarations: [
    PropertyPathPipe,
    ExcelFileNamePipe,
    MonthNamePipe,
    RelationalValueNamePipe,
    RelationalValueUnitPipe,
    QuantityColorPipe,
    QuantityKeyPipe,
    QuantityUnitPipe,
    QuantityNamePipe,
    MeteringTypePipe,
    QuantityMeteringTypePipe,
    ToDatePipe,
    ToCurrencyPipe
  ],
  exports: [
    PropertyPathPipe,
    ExcelFileNamePipe,
    MonthNamePipe,
    RelationalValueNamePipe,
    RelationalValueUnitPipe,
    QuantityColorPipe,
    QuantityKeyPipe,
    QuantityUnitPipe,
    QuantityNamePipe,
    MeteringTypePipe,
    QuantityMeteringTypePipe,
    ToDatePipe,
    ToCurrencyPipe
  ],
  providers: [
    PropertyPathPipe,
    MonthNamePipe,
    QuantityUnitPipe,
    QuantityNamePipe,
    ToDatePipe,
  ]
})
export class CommonPipesModule { }
