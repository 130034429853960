<div class="widget">

  <div class="facility-header-wrapper">
    <facility-select-header [formControl]="facilityId"></facility-select-header>
  </div>

  <button
    class="button button--link button--external-link float-right"
    [disabled]="loading$ | async"
    (click)="goToEnergyManagement()"
  >
    {{ 'LATEST_ACTIONS_WIDGET.SHOW_ALL_COMMENTS' | translate }}
  </button>

  <form [formGroup]="formGroup">
    <span>
      <input
        id="comments-widget-consumption-{{ uniqueId }}"
        type="checkbox"
        formControlName="consumption"
        kendoCheckBox
      >
      <label for="comments-widget-consumption-{{ uniqueId }}">
        {{ 'LATEST_ACTIONS_WIDGET.COMMENTTYPE_CONSUMPTION' | translate }}
      </label>

      <input
        id="comments-widget-other-{{ uniqueId }}"
        type="checkbox"
        formControlName="other"
        kendoCheckBox
      >
      <label for="comments-widget-other-{{ uniqueId }}">
        {{ 'LATEST_ACTIONS_WIDGET.COMMENTTYPE_OTHER' | translate }}
      </label>
    </span>
  </form>

  <table class="table striped">
    <thead>
      <th style="width: 50%;">
        {{ 'ACTIONS.REPORTEDDESCRIPTION' | translate }}
      </th>
      <th class="show-for-medium">
        {{ 'LATEST_ACTIONS_WIDGET.UPDATED_AT' | translate }}
      </th>
      <th style="width: 1%">
        {{ 'ACTIONS.INVESTIGATION' | translate }}
      </th>
    </thead>
    <tbody *ngIf="comments$ | async as comments">
      <tr *ngFor="let comment of comments">
        <td class="ellipsis">
          <span title="{{ comment.reportedDescription }}">
            <a
              class="reported-description"
              (click)="openEditModal(comment)"
            >
              {{ comment.reportedDescription }}
            </a>
          </span>
        </td>
        <td class="show-for-medium">
          {{ comment.updatedAt | date }}
        </td>
        <td>
          <investigation-status [status]="comment.investigation"></investigation-status>
        </td>
      </tr>
    </tbody>
  </table>
</div>
