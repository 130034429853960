import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  NgfTabCommonContentDirective, NgfTabContentDirective, NgfTabDirective, NgfTabsetComponent, NgfTabTitleDirective
} from './tabset';

export {
  NgfTabsetComponent, NgfTabDirective, NgfTabContentDirective, NgfTabTitleDirective, NgfTabChangeEvent
} from './tabset';
export { NgfTabsetConfig } from './tabset-config';

const NGF_TABSET_DIRECTIVES = [
  NgfTabsetComponent,
  NgfTabDirective,
  NgfTabContentDirective,
  NgfTabCommonContentDirective,
  NgfTabTitleDirective
];

@NgModule({ declarations: NGF_TABSET_DIRECTIVES, exports: NGF_TABSET_DIRECTIVES, imports: [CommonModule] })
export class NgfTabsetModule {
}
