<ng-container
  *ngIf="notes"
  [ngSwitch]="noteType"
>
  <ng-container *ngSwitchCase="NoteType.Action">
    <span *ngIf="notes.actions.length > 1; else oneAction">
      {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ACTIONS_COUNT' | translate: { count: notes.actions.length } }}
    </span>
    <ng-template #oneAction>
      <ng-container *ngTemplateOutlet="actionDetails; context: { action: notes.actions[0], isComment: false }">
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="NoteType.Comment">
    <span *ngIf="notes.comments.length > 1; else oneComment">
      {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_COMMENTS_COUNT' | translate: { count: notes.comments.length } }}
    </span>
    <ng-template #oneComment>
      <ng-container *ngTemplateOutlet="actionDetails; context: { action: notes.comments[0], isComment: true }">
      </ng-container>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="NoteType.Alarm">
    <span *ngIf="notes.alarms.length < 1; else oneAlarm">
      {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ALARMS_COUNT' | translate: { count: notes.alarms.length } }}
    </span>
    <ng-template #oneAlarm>
      <ng-container *ngIf="notes.alarms[0] as alarm">
        <div>
          <span>
            {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_ALARM' | translate }}:
            {{ alarm.alarmTypeId | alarmTypeName }}
          </span>
        </div>
        <div>
          <span>
            {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ALARM_EXECUTED_AT' | translate }}:
            {{ alarm.executedAt | date:'shortDate' }}
          </span>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
</ng-container>
<ng-template
  #actionDetails
  let-action="action"
  let-isComment="isComment"
>
  <div *ngIf="action">
    <div>
      <span *ngIf="!isComment; else commentTitle">
        {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_ACTION' | translate }}:
      </span>
      <ng-template #commentTitle>
        <span>
          {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_COMMENT' | translate }}:
        </span>
      </ng-template>
      {{ action.reportedDescription }}
    </div>
    <div>
      <span>
        {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE' | translate }}:
        {{ action.actionType | actionTypeName | translate }}
      </span>
    </div>
    <div>
      <span>
        {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_EFFECT_START_STOP_COLUMN' | translate }}:
        {{ action.effectStartsAt | date:'shortDate' }} - {{ action.effectStopsAt | date:'shortDate' }}
      </span>
    </div>
    <div>
      <span>
        {{ 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_COLUMN' | translate }}:
        {{ action | actionLevel | translate }}
      </span>
    </div>
  </div>
</ng-template>
