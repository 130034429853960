import { Injectable, Type } from '@angular/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ofVoid } from '@enerkey/rxjs';
import { ActionType, ExecutionPhase } from '@enerkey/clients/attachments';
import { Quantities } from '@enerkey/clients/metering';
import { ServiceLevel } from '@enerkey/clients/facility';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { TimeFrameOptions } from '../../../constants/time-frame';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { Service } from '../../../constants/service';
import { RouteAuth } from '../../../shared/routing';
import { UserService } from '../../../services/user-service';
import { QuantityService } from '../../../shared/services/quantity.service';
import { ProfileService } from '../../../shared/services/profile.service';
import { FacilityPropertiesService } from '../../energy-reporting/services/facility-properties.service';
import { ServiceLevelService } from '../../../shared/services/service-level.service';
import { WidgetBase } from '../shared/widget-base.interface';
import { WidgetType } from '../shared/widget-type';
import { NewsWidgetComponent } from '../components/news-widget/news-widget.component';
import { ActionsWidgetComponent, ActionsWidgetOptions } from '../components/actions-widget/actions-widget.component';
import {
  CommentsWidgetComponent,
  CommentsWidgetOptions
} from '../components/comments-widget/comments-widget.component';
import { ACTION_TYPE_IDS } from '../../energy-management/constants/em-shared.constant';
import {
  ConsumptionsWidgetComponent,
  ConsumptionsWidgetOptions
} from '../components/consumptions-widget/consumptions-widget.component';
import {
  SumConsumptionsWidgetComponent,
  SumConsumptionsWidgetOptions,
} from '../components/sum-consumptions-widget/sum-consumptions-widget.component';
import {
  GroupedConsumptionsWidgetComponent,
  GroupedConsumptionsWidgetOptions,
} from '../components/grouped-consumptions-widget/grouped-consumptions-widget.component';
import { WidgetChangeOption } from '../../energy-reporting/shared/widget-constants';
import {
  TopChangedConsumptionsWidgetComponent,
  TopChangedConsumptionsWidgetOptions,
} from '../components/top-changed-consumptions-widget/top-changed-consumptions-widget.component';
import { EmissionsWidgetComponent } from '../components/emissions-widget/emissions-widget.component';
import {
  FacilityEtCurveWidgetComponent
} from '../components/facility-et-curve/facility-et-curve-widget/facility-et-curve-widget.component';
import {
  FacilityEtCurveWidgetOptions
} from '../components/facility-et-curve/facility-et-curve-widget-options/facility-et-curve-widget-options.component';
import { EmissionFactorType, EmissionsWidgetOptions } from '../services/emissions-widget.service';
import { ErrorTicketWidgetComponent } from '../components/error-ticket-widget/error-ticket-widget.component';
import {
  ErrorTicketWidgetOptions,
  ErrorWidgetTicketType,
} from '../components/error-ticket-widget-options/error-ticket-widget-options.component';
import {
  LatestAlarmsWidgetComponent,
  LatestAlarmsWidgetOptions,
} from '../components/latest-alarms-widget/latest-alarms-widget.component';
import { EmissionsFacilityWiseWidgetComponent } from '../components/emissions-facility-wise-widget/emissions-facility-wise-widget.component';
import { EmissionsFacilityWiseWidgetOptions } from './emissions-facility-wise-widget.service';
import {
  ConsumptionsByFacilityWidgetComponent, ConsumptionsByFacilityWidgetOptions
} from '../components/consumptions-by-facility-widget/consumptions-by-facility-widget.component';
import {
  FacilityActionsWidgetComponent, FacilityActionsWidgetOptions
} from '../components/facility-actions-widget/facility-actions-widget.component';
import {
  FacilityLatestAlarmsWidgetComponent,
  FacilityLatestAlarmsWidgetOptions
} from '../components/facility-latest-alarms-widget/facility-latest-alarms-widget.component';
import { FacilityCommentsWidgetComponent, FacilityCommentsWidgetOptions }
  from '../components/facility-comments-widget/facility-comments-widget.component';
import { PowerWidgetComponent, PowerWidgetOptions } from '../components/power-widget/power-widget.component';

export enum WidgetGroup {
  General = 'General',
  AllFacilities = 'All Facilities',
  OneFacility = 'One Facility'
}
export interface WidgetDefinition<Options, WidgetName extends WidgetType = WidgetType> {
  readonly widgetType: WidgetName;
  readonly widgetGroupType: WidgetGroup;
  /** Translation key of for widget title when there is no user defined title */
  readonly defaultTitle: string;
  readonly component: Type<WidgetBase<Options>>;
  /** Class(es) to apply to an element to get this widget's icon. */
  readonly iconClass: string;
  defaultOptions(): Observable<Options>;
  /** When default dashboard is empty add widgets where this property is true */
  readonly addByDefault?: boolean;
  readonly requiredServiceLevel?: ServiceLevel;
  readonly auth?: RouteAuth;
  readonly defaultSize?: { readonly x: number; readonly y: number; };
}

@Injectable({ providedIn: 'root' })
export class WidgetDefinitionsService {
  /**
   * Array of widget definitions that are allowed by current user roles, services and servicelevel
   */
  public readonly widgetDefinitions$: Observable<WidgetDefinition<unknown>[]>;

  private newsWidget: WidgetDefinition<void, WidgetType.News>;
  private actionsWidget: WidgetDefinition<ActionsWidgetOptions, WidgetType.Actions>;
  private commentsWidget: WidgetDefinition<CommentsWidgetOptions, WidgetType.Comments>;
  private consumptionsWidget: WidgetDefinition<ConsumptionsWidgetOptions, WidgetType.Consumptions>;
  private sumConsumptionsWidget: WidgetDefinition<SumConsumptionsWidgetOptions, WidgetType.SumConsumptions>;
  private groupedConsumptionsWidget: WidgetDefinition<GroupedConsumptionsWidgetOptions, WidgetType.GroupedConsumptions>;
  // eslint-disable-next-line max-len
  private topChangedConsumptionsWidget: WidgetDefinition<TopChangedConsumptionsWidgetOptions, WidgetType.TopChangedConsumptions>;
  private emissionsWidget: WidgetDefinition<EmissionsWidgetOptions, WidgetType.Emissions>;
  private facilityEtCurveWidget: WidgetDefinition<FacilityEtCurveWidgetOptions, WidgetType.FacilityEtCurve>;
  private errorTicketWidget: WidgetDefinition<ErrorTicketWidgetOptions, WidgetType.ErrorTicket>;
  private latestAlarmsWidget: WidgetDefinition<LatestAlarmsWidgetOptions, WidgetType.LatestAlarms>;
  // eslint-disable-next-line max-len
  private emissionsFacilityWidget: WidgetDefinition<EmissionsFacilityWiseWidgetOptions, WidgetType.EmissionsFacilityWise>;
  // eslint-disable-next-line max-len
  private facilityConsumptionsWidget: WidgetDefinition<ConsumptionsByFacilityWidgetOptions, WidgetType.FacilityConsumptions>;
  private facilityActionsWidget: WidgetDefinition<FacilityActionsWidgetOptions, WidgetType.FacilityActions>;
  private facilityLatestAlarms: WidgetDefinition<FacilityLatestAlarmsWidgetOptions, WidgetType.FacilityLatestAlarms>;
  private facilityCommentsWidget: WidgetDefinition<FacilityCommentsWidgetOptions, WidgetType.FacilityComments>;
  private powerWidget: WidgetDefinition<PowerWidgetOptions, WidgetType.Power>;

  private readonly allWidgetDefinitions: Record<WidgetType, WidgetDefinition<unknown>>;

  public constructor(
    private readonly userService: UserService,
    private readonly serviceLevelService: ServiceLevelService,
    quantityService: QuantityService,
    profileService: ProfileService,
    facilityPropertiesService: FacilityPropertiesService
  ) {
    this.newsWidget = {
      defaultTitle: 'NEWS_WIDGET.NEWS',
      widgetType: WidgetType.News,
      widgetGroupType: WidgetGroup.General,
      component: NewsWidgetComponent,
      addByDefault: true,
      iconClass: 'fas fa-newspaper shrink',
      defaultOptions: () => ofVoid(),
    };
    this.actionsWidget = {
      widgetType: WidgetType.Actions,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'LATEST_ACTIONS_WIDGET.TITLE_FOR_ACTIONS',
      component: ActionsWidgetComponent,
      auth: { services: [Service.Actions] },
      iconClass: 'fas fa-comment-alt shrink',
      defaultOptions: () => of({
        typeToShow: 'actions',
        numberToShow: 100,
        numberToShowMobile: 20,
        selectedActionTypes: ACTION_TYPE_IDS.map(actionType => ({ id: actionType })),
        selectedExecutionPhaseId: ExecutionPhase.Considered
      })
    };
    this.commentsWidget = {
      widgetType: WidgetType.Comments,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'LATEST_ACTIONS_WIDGET.TITLE_FOR_COMMENTS',
      component: CommentsWidgetComponent,
      auth: { services: [Service.Comments] },
      iconClass: 'fas fa-comment',
      defaultOptions: () => of({
        typeToShow: 'comments',
        numberToShow: 100,
        numberToShowMobile: 20,
        selectedCommentTypes: {
          [ActionType.K]: true,
          [ActionType.KE]: true,
        },
      })
    };
    this.consumptionsWidget = {
      widgetType: WidgetType.Consumptions,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'CONSUMPTIONS_WIDGET.CONSUMPTIONS',
      component: ConsumptionsWidgetComponent,
      addByDefault: true,
      auth: { services: [Service.EnergyReporting] },
      iconClass: 'fas fa-chart-bar',
      defaultOptions: () => from(quantityService.getSignificantQuantitiesForProfile()).pipe(
        map(quantities => ({
          comparableOption: Comparability.ByQuantity,
          comparisonPeriodOption: 'Default',
          selectedQuantities: quantities.map(q => q.ID),
          timeFrameOption: TimeFrameOptions.YEAR_BY_YEAR_CALENDAR,
          unitKey: ReportingUnit.Default,
          variableId: 0,
          valueOption: ValueType.Measured
        }))
      )
    };
    this.sumConsumptionsWidget = {
      widgetType: WidgetType.SumConsumptions,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'SUMCONSUMPTIONS_WIDGET.SUMCONSUMPTIONS',
      component: SumConsumptionsWidgetComponent,
      addByDefault: true,
      auth: { services: [Service.EnergyReporting] },
      iconClass: 'fas fa-chart-pie',
      defaultOptions: () => quantityService.getProfileSumQuantities().pipe(
        map(quantities => ({
          comparableOption: Comparability.ByQuantity,
          comparisonPeriodOption: 'Default',
          timeFrameOption: TimeFrameOptions.YEAR_BY_YEAR_CALENDAR,
          unitKey: ReportingUnit.Default,
          valueOption: ValueType.Measured,
          // Use TotalEnergy as default quantity if it is available for profile
          selectedQuantityId: (quantities.find(q => q.ID === Quantities.TotalEnergy) ?? quantities[0])?.ID
        }))
      )
    };
    this.groupedConsumptionsWidget = {
      widgetType: WidgetType.GroupedConsumptions,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'TOPGROUPCONSUMPTIONS_WIDGET.TOPGROUPCONSUMPTIONS',
      component: GroupedConsumptionsWidgetComponent,
      auth: { services: [Service.EnergyReporting] },
      requiredServiceLevel: ServiceLevel.Medium,
      iconClass: 'fas fa-city shrink',
      defaultOptions: () => forkJoin([
        from(quantityService.getSignificantQuantitiesForProfile()).pipe(
          map(quantities => quantities[0])
        ),
        facilityPropertiesService.groupableFacilityProperties$.pipe(
          take(1),
          map(properties => properties.find(
            // Use FacilityType as default grouping property if it is available for profile
            property => property.itemProperty === 'FacilityType' && property.groupProperty === 'FacilityInformation'
          ) ?? properties[0])
        )
      ]).pipe(
        map(([quantity, property]) => ({
          comparableOption: Comparability.ByQuantity,
          comparisonPeriodOption: 'Default',
          timeFrameOption: TimeFrameOptions.YEAR_BY_YEAR_CALENDAR,
          unitKey: ReportingUnit.Default,
          valueOption: ValueType.Measured,
          biggestFallers: 5,
          biggestGainers: 5,
          variableId: 0,
          changeOption: WidgetChangeOption.Relative,
          groupProperty: property.groupProperty,
          itemProperty: property.itemProperty,
          selectedQuantity: quantity,
          quantityId: quantity.ID
        }))
      )
    };
    this.topChangedConsumptionsWidget = {
      widgetType: WidgetType.TopChangedConsumptions,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'TOPCONSUMPTIONS_WIDGET.TOPCONSUMPTIONS',
      component: TopChangedConsumptionsWidgetComponent,
      addByDefault: true,
      auth: { services: [Service.EnergyReporting] },
      iconClass: 'fas fa-chart-line',
      defaultOptions: () => from(quantityService.getSignificantQuantitiesForProfile()).pipe(
        map(quantities => ({
          comparableOption: Comparability.ByQuantity,
          comparisonPeriodOption: 'Default',
          timeFrameOption: TimeFrameOptions.YEAR_BY_YEAR_CALENDAR,
          unitKey: ReportingUnit.Default,
          valueOption: ValueType.Measured,
          biggestFallers: 5,
          biggestGainers: 5,
          variableId: 0,
          changeOption: WidgetChangeOption.Relative,
          selectedQuantity: quantities[0],
          quantityId: quantities[0]?.ID
        }))
      )
    };
    this.emissionsWidget = {
      widgetType: WidgetType.Emissions,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'EMISSIONS_WIDGET.EMISSIONS',
      component: EmissionsWidgetComponent,
      addByDefault: true,
      auth: { services: [Service.EnergyReporting, Service.EmissionReporting] },
      iconClass: 'fas fa-smog shrink',
      defaultOptions: () => of({
        valueOption: ValueType.Measured,
        comparableOption: Comparability.ByQuantity,
        factorOption: EmissionFactorType.Facility
      })
    };
    this.errorTicketWidget = {
      widgetType: WidgetType.ErrorTicket,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'ERROR_WIDGET.WIDGET_TITLE',
      component: ErrorTicketWidgetComponent,
      iconClass: 'fas fa-exclamation-triangle shrink',
      defaultSize: {
        x: 3,
        y: 3
      },
      defaultOptions: () => of({
        typeToShow: ErrorWidgetTicketType.Facility,
        numberToShow: 20,
      }),
    };
    this.latestAlarmsWidget = {
      widgetType: WidgetType.LatestAlarms,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'LATEST_ALARMS_WIDGET.WIDGET_TITLE',
      component: LatestAlarmsWidgetComponent,
      defaultSize: {
        x: 3,
        y: 2
      },
      auth: { services: [Service.Alarms] },
      iconClass: 'fas fa-bell',
      defaultOptions: () => of({
        numberToShow: 20,
      })
    };
    this.emissionsFacilityWidget = {
      widgetType: WidgetType.EmissionsFacilityWise,
      widgetGroupType: WidgetGroup.OneFacility,
      defaultTitle: 'EMISSIONS_WIDGET.FACILITY_WISE_EMISSIONS',
      component: EmissionsFacilityWiseWidgetComponent,
      auth: { services: [Service.EnergyReporting, Service.EmissionReporting] },
      iconClass: 'fas fa-smog shrink',
      defaultOptions: () => of({ factorOption: EmissionFactorType.Facility, facilityId: null })
    };
    this.facilityEtCurveWidget = {
      widgetType: WidgetType.FacilityEtCurve,
      widgetGroupType: WidgetGroup.OneFacility,
      defaultTitle: 'FACILITY_ETCURVE_WIDGET.WIDGET_TITLE',
      defaultSize: { x: 3, y: 2 },
      component: FacilityEtCurveWidgetComponent,
      auth: { services: [Service.EtCurveAnalytics] },
      iconClass: 'fas fa-chart-line',
      defaultOptions: () => of({
        facilityId: null,
        quantity: null
      })
    };
    this.facilityConsumptionsWidget = {
      widgetType: WidgetType.FacilityConsumptions,
      widgetGroupType: WidgetGroup.OneFacility,
      defaultTitle: 'FACILITY_CONSUMPTIONS_WIDGET.FACILITY_CONSUMPTIONS',
      component: ConsumptionsByFacilityWidgetComponent,
      auth: { services: [Service.EnergyReporting] },
      iconClass: 'fas fa-chart-bar',
      defaultOptions: () => from(quantityService.getSignificantQuantitiesForProfile()).pipe(
        map(quantities => ({
          comparableOption: Comparability.ByQuantity,
          comparisonPeriodOption: 'Default',
          selectedQuantities: quantities.map(q => q.ID),
          timeFrameOption: TimeFrameOptions.YEAR_BY_YEAR_CALENDAR,
          unitKey: ReportingUnit.Default,
          variableId: 0,
          valueOption: ValueType.Measured,
          facilityId: null
        }))
      )
    };
    this.facilityLatestAlarms = {
      widgetType: WidgetType.FacilityLatestAlarms,
      widgetGroupType: WidgetGroup.OneFacility,
      defaultTitle: 'FACILITY_LATEST_ALARMS_WIDGET.WIDGET_TITLE',
      component: FacilityLatestAlarmsWidgetComponent,
      auth: { services: [Service.Alarms] },
      iconClass: 'fas fa-bell',
      defaultOptions: () => of({
        numberToShow: 20,
        facilityId: null
      })
    };
    this.facilityCommentsWidget = {
      widgetType: WidgetType.FacilityComments,
      widgetGroupType: WidgetGroup.OneFacility,
      defaultTitle: 'FACILITY_LATEST_COMMENTS_WIDGET.FACILITY_LATEST_COMMENTS',
      component: FacilityCommentsWidgetComponent,
      auth: { services: [Service.Comments] },
      iconClass: 'fas fa-comment',
      defaultOptions: () => of({
        typeToShow: 'comments',
        numberToShow: 100,
        numberToShowMobile: 20,
        selectedCommentTypes: {
          [ActionType.K]: true,
          [ActionType.KE]: true,
        },
        facilityId: null
      })
    };
    this.facilityActionsWidget = {
      widgetType: WidgetType.FacilityActions,
      widgetGroupType: WidgetGroup.OneFacility,
      defaultTitle: 'FACILITY_LATEST_ACTIONS_WIDGET.TITLE_FOR_ACTIONS',
      component: FacilityActionsWidgetComponent,
      auth: { services: [Service.Actions] },
      iconClass: 'fas fa-comment-alt shrink',
      defaultOptions: () => of({
        facilityId: null,
        typeToShow: 'actions',
        numberToShow: 100,
        numberToShowMobile: 20,
        selectedActionTypes: ACTION_TYPE_IDS.map(actionType => ({ id: actionType })),
        selectedExecutionPhaseId: ExecutionPhase.Considered
      })
    };
    this.powerWidget = {
      widgetType: WidgetType.Power,
      widgetGroupType: WidgetGroup.AllFacilities,
      defaultTitle: 'POWER_WIDGET.POWER_WIDGET_TITLE',
      component: PowerWidgetComponent,
      addByDefault: true,
      auth: { services: [Service.EnergyReporting] },
      iconClass: 'fas fa-chart-bar',
      defaultOptions: () => of({
        comparisonPeriodOption: 'Default',
        selectedQuantity: Quantities.Electricity,
        timeFrameOption: TimeFrameOptions.YEAR_BY_YEAR_CALENDAR,
        valueOption: ValueType.Measured,
        change: {
          absolute: true,
          relative: false
        },
        minMaxAvg: {
          max: true,
          min: false,
          average: false
        }
      })
    };

    // Order here defines default widget add order and order in add widgets menu
    this.allWidgetDefinitions = {
      [WidgetType.News]: this.newsWidget,
      [WidgetType.Comments]: this.commentsWidget,
      [WidgetType.Actions]: this.actionsWidget,
      [WidgetType.Consumptions]: this.consumptionsWidget,
      [WidgetType.TopChangedConsumptions]: this.topChangedConsumptionsWidget,
      [WidgetType.SumConsumptions]: this.sumConsumptionsWidget,
      [WidgetType.Emissions]: this.emissionsWidget,
      [WidgetType.GroupedConsumptions]: this.groupedConsumptionsWidget,
      [WidgetType.ErrorTicket]: this.errorTicketWidget,
      [WidgetType.LatestAlarms]: this.latestAlarmsWidget,
      [WidgetType.FacilityConsumptions]: this.facilityConsumptionsWidget,
      [WidgetType.EmissionsFacilityWise]: this.emissionsFacilityWidget,
      [WidgetType.FacilityLatestAlarms]: this.facilityLatestAlarms,
      [WidgetType.FacilityComments]: this.facilityCommentsWidget,
      [WidgetType.FacilityActions]: this.facilityActionsWidget,
      [WidgetType.FacilityEtCurve]: this.facilityEtCurveWidget,
      [WidgetType.Power]: this.powerWidget
    };

    this.widgetDefinitions$ = profileService.profile$.pipe(map(() => this.getAllowedWidgetDefinitions()));
  }

  public getAllowedWidgetTypes(): Observable<WidgetType[]> {
    return this.widgetDefinitions$.pipe(
      take(1),
      map(widgetDefs => widgetDefs.map(def => def.widgetType))
    );
  }

  public getWidgetDefinition(widgetType: WidgetType): WidgetDefinition<unknown> {
    return this.allWidgetDefinitions[widgetType];
  }

  public getDefaultWidgetDefinitions(): Observable<WidgetDefinition<unknown>[]> {
    return this.widgetDefinitions$.pipe(
      take(1),
      map(widgetDefs => widgetDefs.filter(def => def.addByDefault))
    );
  }

  private getAllowedWidgetDefinitions(): WidgetDefinition<unknown>[] {
    return Object.values(this.allWidgetDefinitions)
      .filter(widget => this.hasAccessToWidget(widget));
  }

  private hasAccessToWidget(widgetDefinition: WidgetDefinition<unknown>): boolean {
    return this.userService.hasAccess(widgetDefinition.auth ?? {}) &&
      this.serviceLevelService.hasAtLeastServiceLevel(widgetDefinition.requiredServiceLevel);
  }

}
