import EmYearlyVisualsController from './em-yearly-visuals-controller';
import emYearlyVisualsTemplate from 'raw-loader!./em-yearly-visuals.html';

export default {
  template: emYearlyVisualsTemplate,
  controller: EmYearlyVisualsController,
  controllerAs: 'vm',
  bindings: {
    groupedData: '<',
    columns: '<',
    config: '<',
    update: '&',
  }
};
