import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import {
  BulkModifyProfilesServicesRequest,
  UserManagementClient
} from '@enerkey/clients/user-management';

import { DialogService } from '../../../../shared/services/dialog.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  templateUrl: './profile-mass-edit-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMassEditModalComponent extends ModalBase<void> {

  public selectedProfiles: number[] = [];
  public readonly serviceMassEditForm: UntypedFormGroup;

  public constructor(
    private readonly toasterService: ToasterService,
    private readonly userManagementClient: UserManagementClient,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.serviceMassEditForm = new UntypedFormGroup({
      addServices: new UntypedFormControl(),
      removeServices: new UntypedFormControl()
    });
  }

  public submit(): void {
    this.dialogService.getConfirmationModal({
      text: 'ADMIN.BULK_MODIFY_SERVICES.SAVE_CONFIRMATION',
      title: this.translateService.instant('ADMIN.BULK_MODIFY_SERVICES.MODIFY_SERVICES_BUTTON'),
      isDelete: false
    }).subscribe({
      next: () => {
        const data = this.serviceMassEditForm.value;
        this.userManagementClient.bulkModifyProfilesServices(
          new BulkModifyProfilesServicesRequest({
            profileIds: this.selectedProfiles,
            serviceIdsToAdd: data.addServices,
            serviceIdsToRemove: data.removeServices
          })
        ).subscribe({
          next: (() => {
            this.closeModal();
            this.toasterService.success('ADMIN.BULK_MODIFY_SERVICES.SAVE_SUCCESS');
          }),
          error: (() => {
            this.toasterService.error('ADMIN.BULK_MODIFY_SERVICES.SAVE_ERROR');
          })
        });
      }
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
