const $inject = [
  '$modalInstance', 'meterIds', 'timeFrame',
  'searchType', 'utils', 'SearchTypeRadioService',
  'defaultMeterId'
];

class ManualQaInspectModalController {
  constructor(
    $modalInstance, meterIds, timeFrame,
    searchType, utils, SearchTypeRadioService,
    defaultMeterId
  ) {
    this.meterIds = meterIds;
    this.timeFrame = timeFrame;
    this.searchType = searchType;
    this.defaultMeterId = defaultMeterId;
    this.$modalInstance = $modalInstance;
    this.utils = utils;

    const searchTypes = SearchTypeRadioService.getSearchTypes();
    const searchTypeObj = searchTypes.find(_searchType => _searchType.id === searchType);
    const title = utils.localizedString('MQA.INSPECT.MODAL_TITLE');

    this.titleText = `${title} [${searchTypeObj.name}]`;
  }
}

ManualQaInspectModalController.$inject = $inject;

export default ManualQaInspectModalController;
