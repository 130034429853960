configurationControlApi.$inject = ['UserService', '$http', '$httpParamSerializer'];

export function configurationControlApi(UserService, $http, $httpParamSerializer) {
  const getBucket = function(bucketId) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/bucket/${bucketId}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const getBuckets = function() {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/bucket/`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const searchBuckets = function(searchOption, searchParams) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/bucket` +
      `?searchOption=${searchOption}&searchParams=${searchParams}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const updateBucket = function(bucketId, bucketUpdate) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/bucket/${bucketId}`;
    return $http.put(route, bucketUpdate, { headers: UserService.getAuthorizationHeader() });
  };

  const getConfigurations = function(bucketId) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/configuration/${bucketId}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const getReaderTypes = function() {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/readerType/`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const getReaderTypeInformation = function(meterId) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/readerType/forMeter/${meterId}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const getConfigurationPassword = function(configurationId) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/password/${configurationId}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const getReaderSchema = function(readerTypeId) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/schema/${readerTypeId}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const postConfiguration = function(readerTypeId, configuration) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/configuration/${readerTypeId}`;
    return $http.post(route, configuration, { headers: UserService.getAuthorizationHeader() });
  };

  const deleteConfiguration = function(id) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/configuration/${id}`;
    return $http.delete(route, { headers: UserService.getAuthorizationHeader() });
  };

  const updateConfiguration = function(configId, configuration) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/configuration/${configId}`;
    return $http.put(route, configuration, { headers: UserService.getAuthorizationHeader() });
  };

  const executeBatch = function(bucketId, executeParams) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/execute/${bucketId}`;
    return $http.post(route, executeParams, { headers: UserService.getAuthorizationHeader() });
  };

  const getLegacyMappings = function(legacyMappings) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/legacymapping/mappings/`;
    return $http.post(route, legacyMappings, { headers: UserService.getAuthorizationHeader() });
  };

  const getStatusLog = function(meterId, timeFrame) {
    const queryParams = $httpParamSerializer(timeFrame); // Note. Not in  ISO 8601 format
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/configuration/statuslog/forMeter/` +
      `${meterId}?${queryParams}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const getBucketDocumentation = function(bucketId) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/BucketDocumentation/${bucketId}`;
    return $http.get(route, { headers: UserService.getAuthorizationHeader() });
  };

  const addBucketDocumentation = function(bucketDocumentation) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/BucketDocumentation/`;
    return $http.post(route, bucketDocumentation, { headers: UserService.getAuthorizationHeader() });
  };

  const updateBucketDocumentation = function(bucketDocumentation) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/BucketDocumentation/`;
    return $http.post(route, bucketDocumentation, { headers: UserService.getAuthorizationHeader() });
  };

  const deleteBucketDocumentation = function(bucketId) {
    // eslint-disable-next-line no-undef
    const route = `${ENERKEY_CONFIG.apiConfiguration}/api/BucketDocumentation/${bucketId}`;
    return $http.delete(route, { headers: UserService.getAuthorizationHeader() });
  };

  return {
    getBucket: getBucket,
    getBuckets: getBuckets,
    searchBuckets: searchBuckets,
    updateBucket: updateBucket,
    getConfigurations: getConfigurations,
    getReaderTypes: getReaderTypes,
    getReaderTypeInformation: getReaderTypeInformation,
    getConfigurationPassword: getConfigurationPassword,
    getReaderSchema: getReaderSchema,
    postConfiguration: postConfiguration,
    deleteConfiguration: deleteConfiguration,
    updateConfiguration: updateConfiguration,
    executeBatch: executeBatch,
    getLegacyMappings: getLegacyMappings,
    getStatusLog: getStatusLog,
    getBucketDocumentation: getBucketDocumentation,
    addBucketDocumentation: addBucketDocumentation,
    updateBucketDocumentation: updateBucketDocumentation,
    deleteBucketDocumentation: deleteBucketDocumentation
  };
}
