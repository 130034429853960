import { Pipe, PipeTransform } from '@angular/core';

import { ApiKeyType } from '@enerkey/clients/user-management';

import { ConfigurableApiKey } from '../components/api-keys/api-keys.component';

const apiKeyIndexes: Record<ConfigurableApiKey, number> = {
  [ApiKeyType.Primary]: 1,
  [ApiKeyType.Secondary]: 2,
  [ApiKeyType.Tertiary]: 3,
  [ApiKeyType.Quaternary]: 4,
};

@Pipe({
  name: 'apiKeyIndex'
})
export class ApiKeyIndexPipe implements PipeTransform {
  public transform(value: ConfigurableApiKey): number {
    return apiKeyIndexes[value];
  }
}
