import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Consumption } from '@enerkey/clients/reporting';

import { ThresholdService } from '../../../shared/services/threshold.service';

const flags = ['incomplete', 'modeled'] as const;

const cellClasses: Record<typeof flags[number], string> = {
  incomplete: 'k-grid-cell-Incomplete',
  modeled: 'k-grid-cell-Modelled',
};

@Pipe({ name: 'consumptionValueFlagClass' })
export class ConsumptionValueFlagClassPipe implements PipeTransform {
  public constructor(
    private readonly thresholdService: ThresholdService
  ) {
  }

  public transform(consumption: Consumption): Observable<string[]> {
    return this.thresholdService.threshold$.pipe(
      map(threshold => flags.filterMap(
        flag => consumption?.[flag] > threshold,
        flag => cellClasses[flag]
      ))
    );
  }
}
