import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'monthName' })
export class MonthNamePipe implements PipeTransform {
  public constructor(
    private readonly intlService: IntlService
  ) {
  }

  /**
   * Return localized month name in short format (eg. tammi in Finnish)
   *
   * @param month month starting from 0, so January is 0 and December is 11
   */
  public transform(month: number, long = false): string {
    const date = new Date(2019, month, 15);
    date.setMonth(month);
    return this.intlService.formatDate(date, long ? 'LLLL' : 'LLL');
  }
}
