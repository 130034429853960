import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import isMobile from 'ismobilejs';

import {
  ActionOutViewModel,
  ActionsQueryParameters,
  ActionType,
  AttachmentsClient,
  ExecutionPhase,
} from '@enerkey/clients/attachments';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { formGroupFrom } from '@enerkey/ts-utils';

import { WidgetBase } from '../../shared/widget-base.interface';
import { FacilityService } from '../../../../shared/services/facility.service';
import { UserService } from '../../../../services/user-service';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { getExecutionPhaseComboItems } from '../../../energy-management/constants/em-execution-phases';
import { WidgetQueueService } from '../../services/widget-queue.service';
import { DashboardStateService } from '../../services/dashboard-state.service';

export type ActionsWidgetOptions = {
  typeToShow: 'actions'; // remove when usersettings changed?
  numberToShow: number;
  numberToShowMobile: number;
  selectedActionTypes: { id: ActionType }[];
  selectedExecutionPhaseId: ExecutionPhase;
};

type ActionsFilterForm = {
  executionPhase: ExecutionPhase;
}

@Component({
  selector: 'actions-widget',
  templateUrl: './actions-widget.component.html',
  styleUrls: ['./actions-widget.component.scss', '../../styles/actions-comments-widget-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsWidgetComponent implements WidgetBase<ActionsWidgetOptions>, OnInit, OnDestroy {
  @Input() public dataModelOptions: ActionsWidgetOptions;

  public readonly facilityNames$: Observable<Readonly<Record<number, string>>>;
  public readonly dataModelChange$: Observable<ActionsWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;
  public readonly executionPhaseOptions: ComboItem<ExecutionPhase>[] = getExecutionPhaseComboItems();

  public readonly formGroup: UntypedFormGroup;

  public actions$: Observable<ActionOutViewModel[]>;

  private readonly _dataModelChange = new Subject<ActionsWidgetOptions>();
  private readonly _loading = new LoadingSubject(true);
  private readonly _destroy = new Subject<void>();
  private readonly _error$ = new Subject<void>();
  private readonly _refresh$ = new BehaviorSubject<boolean>(true);

  // note: if widgets aren't recreated w/ settings modal, this needs clearing logic
  private readonly _cache: Map<ExecutionPhase, ActionOutViewModel[]> = new Map();
  private readonly _isMobile: boolean = isMobile().any;

  public constructor(
    private readonly widgetQueue: WidgetQueueService,
    private readonly facilityService: FacilityService,
    private readonly attachmentsClient: AttachmentsClient,
    private readonly dashboardStateService: DashboardStateService,
    private readonly userService: UserService,
    private readonly changeDetection: ChangeDetectorRef
  ) {
    this.formGroup = formGroupFrom<ActionsFilterForm>({ executionPhase: ExecutionPhase.Suggestion });

    this.error$ = this._error$.asObservable();
    this.loading$ = this._loading.asObservable();
    this.dataModelChange$ = this._dataModelChange.asObservable();
    this.facilityNames$ = this.facilityService.filteredProfileFacilities$.pipe(
      map(facilities => facilities.toRecord(f => f.facilityId, f => f.name)),
      shareReplay(1),
      takeUntil(this._destroy)
    );
  }

  public ngOnInit(): void {
    this.formGroup.patchValue({
      executionPhase: this.dataModelOptions.selectedExecutionPhaseId ?? ExecutionPhase.Suggestion
    });

    this.formGroup.valueChanges.pipe(
      takeUntil(this._destroy) // debounce not needed due to it being a dropdown
    ).subscribe((formValue: ActionsFilterForm) => {
      this._dataModelChange.next({
        ...this.dataModelOptions,
        selectedExecutionPhaseId: formValue.executionPhase,
      });
    });

    this.actions$ = combineLatest([
      this.facilityService.filteredProfileFacilityIds$.pipe(tap(() => this._cache.clear())),
      this.dataModelChange$.pipe(startWith(this.dataModelOptions)),
      this._refresh$.asObservable().pipe(tap(() => this._cache.clear())),
    ]).pipe(
      switchMap(args => this.getActions$(args[0], args[1]).pipe(
        indicate(this._loading),
        catchError(() => {
          this._error$.next();
          return EMPTY;
        })
      ))
    );
  }

  public ngOnDestroy(): void {
    this._cache.clear();

    this._destroy.next();
    this._destroy.complete();
    this._loading.complete();
    this._error$.complete();
    this._dataModelChange.complete();
    this._refresh$.complete();
  }

  public openEditModal(action: ActionOutViewModel): void {
    this.dashboardStateService.openEmEditModal(action)
      .then((response: { operation?: string, action?: ActionOutViewModel }) =>
        this.dashboardStateService.refreshReportData(action, response, this.changeDetection, this._refresh$));
  }

  public openFacilityReport(action: ActionOutViewModel): void {
    this.dashboardStateService.openFacilityReport(action.reportingObjectId);
  }

  public goToEnergyManagement(): void {
    this.dashboardStateService.goToEnergyManagement(this.dataModelOptions);
  }

  private getActions$(
    facilityIds: number[],
    model: ActionsWidgetOptions
  ): Observable<ActionOutViewModel[]> {
    if (!model || !Array.hasItems(facilityIds) || !model.selectedActionTypes.hasItems()) {
      return of([]);
    }

    const phase = model.selectedExecutionPhaseId;
    const fromCache = this._cache.get(phase);

    if (fromCache) {
      return of(fromCache);
    }

    const profileId = this.userService.profileId;
    const params = new ActionsQueryParameters({
      actionTypes: model.selectedActionTypes.map(x => x.id),
      latestCount: this._isMobile ? model.numberToShowMobile : model.numberToShow,
      reportingObjectId: facilityIds,
      executionPhase: [phase],
    });

    return this.attachmentsClient.getActionsUsingPost(profileId, undefined, params).pipe(
      this.widgetQueue.queue('attachments', this._destroy),
      tap(result => this._cache.set(phase, result)),
      takeUntil(this._destroy)
    );
  }

}
