import _ from 'lodash';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';

import { ServiceLevel } from '@enerkey/clients/facility';
import { IProfileViewModel, UserManagementClient } from '@enerkey/clients/user-management';

import { Roles } from '../modules/admin/constants/roles';
import { Service } from '../constants/service';
import { RouteAuth, RouteAuthLogic } from '../shared/routing';
import { AuthenticationService } from '../shared/services/authentication.service';
import { ToasterService } from '../shared/services/toaster.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  public get profileId(): number {
    return this.currentProfile.id;
  }

  private currentProfile: IProfileViewModel;
  private readonly isInitializedWithInitialProfile = new ReplaySubject<IProfileViewModel>(1);

  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly toaster: ToasterService,
    private readonly userManagementClient: UserManagementClient,
    private readonly oauthService: OAuthService
  ) {
    this.currentProfile = {} as IProfileViewModel;
  }

  public hasService(serviceName: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.currentProfile.services?.some(s => s.name === serviceName || (s as any).Name === serviceName);
  }

  public hasRole(role: Roles): boolean {
    return this.authenticationService.isInRole(role);
  }

  public getServiceLevel(): ServiceLevel {
    return this.currentProfile.serviceLevel;
  }

  public isInitializedWithInitialProfileAsync(): Promise<IProfileViewModel> {
    return firstValueFrom(this.isInitializedWithInitialProfile);
  }

  public getCurrentProfile(): IProfileViewModel {
    return this.currentProfile;
  }

  /**
   * Sets the given profile object as the current profile synchronously
   *
   * @param profile A full profile object (with services etc)
   */
  public setCurrentProfile(profile: IProfileViewModel): void {
    this.currentProfile = profile;
  }

  /**
   * Sets the initial profile based on the given profile id. If zero is given assumes this is a user who
   * only has access to Old EnerKey and creates a dummy profile object. Otherwise gets the data of the given
   * profile and sets the current profile data.
   *
   * This only gets called once when the user logs in (or refreshes the portal page).
   *
   * @param profileId The profile id to set as the initial profile when the portal starts up
   */
  public setInitialProfileIdAsync(profileId: number): Promise<IProfileViewModel> {
    if (profileId === 0) {
      // If there is no current profile create a dummy profile. This is a valid situation for users who only use
      // the old enerkey, embedded inside the new enerkey. They don't (necessarily) have any profiles to access
      // modules in the new enerkey. In that case this dummy profile makes sure that everything works, mainly
      // user setting fetching. It will make API calls using the profile id 0 which is fine. Without this dummy
      // profile we would need lots of code skipping logic in user settings service and elsewhere.
      this.currentProfile = {
        id: 0,
        services: [],
        serviceIds: [],
        reportingObjectSetId: 0,
      };

      this.isInitializedWithInitialProfile.next(_.cloneDeep(this.currentProfile));
      this.isInitializedWithInitialProfile.complete();
    } else {
      this.userManagementClient.getProfile(profileId).subscribe({
        next: profile => {
          this.currentProfile = profile;
          this.isInitializedWithInitialProfile.next(_.cloneDeep(this.currentProfile));
          this.isInitializedWithInitialProfile.complete();
        },
        error: errResponse => {
          this.toaster.toast({
            type: 'error',
            message: 'FAILED_TO_GET_LAST_USED_PROFILE',
            disableTimeOut: true,
            translate: true,
          });
          this.isInitializedWithInitialProfile.next(errResponse);
          this.isInitializedWithInitialProfile.complete();
        }
      });
    }

    return firstValueFrom(this.isInitializedWithInitialProfile);
  }

  public hasAccess(requirements: RouteAuth | null | undefined): boolean {
    if (!requirements) {
      return true;
    }

    if (requirements.defaultTenantOnly && !this.authenticationService.isDefaultTenant()) {
      return false;
    }

    const logic = requirements.logic ?? RouteAuthLogic.All;

    const byRole = this.hasAccessByRoles(requirements.roles, requirements.roleLogic);
    const byService = this.hasAccessByServices(requirements.services, requirements.serviceLogic);

    return logic === RouteAuthLogic.Any
      ? (byRole || byService)
      : (byRole && byService);
  }

  /**
   * Returns the authorization header required by all authenticated API requests. Used in places where
   * the complete $http configuration object is not required, such as when using Angular $resource service.
   * @returns An authorization header with the bearer access token. The return value is an object
   * which has a function called 'Authorization' which returns the header.
   */
  public getAuthorizationHeader(): { Authorization: () => string } {
    return {
      Authorization: () => `Bearer ${this.oauthService.getAccessToken()}`
    };
  }

  private hasAccessByRoles(roles: Roles[], logic = RouteAuthLogic.All): boolean {
    if (!roles) {
      return true;
    }
    if (logic === RouteAuthLogic.Any) {
      return roles.some(role => this.hasRole(role));
    }
    return roles.every(role => this.hasRole(role));
  }

  private hasAccessByServices(services: Service[], logic = RouteAuthLogic.All): boolean {
    if (!services) {
      return true;
    }
    if (logic === RouteAuthLogic.Any) {
      return services.some(service => this.hasService(service));
    }
    return services.every(service => this.hasService(service));
  }

}
