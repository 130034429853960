<div class="shared-tooltip">
  <div class="shared-tooltip-title">
    {{ title }}
  </div>
  <div class="shared-tooltip-table">
    <table>
      <ng-container *ngFor="let point of points">
        <tr
          chart-tooltip-row
          [resolution]="resolution"
          [point]="point"
        ></tr>
      </ng-container>
    </table>
  </div>
</div>
