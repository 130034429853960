<div class="card">
  <div class="header-group bg-enerkey-facilityinformation">
    <div class="header-icon header-icon--bg-round header-icon--bg-white">
      <i class="fa fa-home"></i>
    </div>
    <h3 class="header-group__label h-secondary">{{ 'FACILITIES_REPORT.METER_DETAILS' | translate }}</h3>
  </div>
  <div class="meter-info-grid">
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'FACILITIES_REPORT.DESCRIPTION' | translate}}</span>
      </div>
      <span>
        {{ meter.description }}
      </span>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'FACILITIES_REPORT.METERINGTYPE' | translate }}</span>
      </div>
      <span>
        {{ meter.meteringType | meteringType }}
      </span>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'FACILITIES_REPORT.METERINGFACTOR' | translate }}</span>
      </div>
      <span>
        {{ meter.factor }}
      </span>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'METERS.ENEGIA_METER_ID' | translate }}</span>
      </div>
      <span>
        {{ meter.id }}
      </span>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'FACILITIES_REPORT.CUSTOMERMETERIDENTIFIER' | translate }}</span>
      </div>
      <span>
        {{ meter.customerMeterIdentifier }}
      </span>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'METERS.MEASUREMENT_METHOD.TITLE' | translate }}</span>
      </div>
      <span *ngIf="meter.twoTimeMeasurement; else oneTimeMeasurement">
        {{ 'METERS.MEASUREMENT_METHOD.TWO_TIME' | translate }}
      </span>
      <ng-template #oneTimeMeasurement>
        <span>
          {{ 'METERS.MEASUREMENT_METHOD.ONE_TIME' | translate }}
        </span>
      </ng-template>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'AUTOMATIC_READING' | translate }}</span>
      </div>
      <span>
        {{ meter.automaticReadingStartTime | kendoDate: 'd' }}—{{  meter.automaticReadingEndTime | kendoDate: 'd' }}
      </span>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'DEACTIVATION_TIME' | translate }}</span>
      </div>
      <span>
        {{ meter.deactivationTime | kendoDate: 'd' }}
      </span>
    </div>
    <div class="meter-info-grid-item">
      <div>
        <span class="bold">{{ 'TAGS.TITLE' | translate }}</span>
      </div>
      <kendo-chiplist>
        <kendo-chip
          *ngFor="let tag of meterTags"
          [label]="tag.tagName"
        >
        </kendo-chip>
      </kendo-chiplist>
    </div>
  </div>
</div>
