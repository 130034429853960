import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { LanguageSelectItem, LanguageService } from '../../../../shared/services/language.service';
import { ReceiverProperty } from '../../components/email-send-modal/email-functions';
import { TenantInfoService } from '../../../../shared/services/tenant-info.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';

interface ReceiverSelection {
  value: ReceiverProperty;
  translation: string;
}

@Component({
  selector: 'email-common-values-form',
  templateUrl: './email-common-values-form.component.html',
  styleUrls: ['./email-common-values-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailCommonValuesFormComponent {
  @Input() public form: UntypedFormGroup;

  public readonly kendoEditorStyle = { height: '300px' };
  public readonly replyAddresses$: Observable<string[]>;
  public readonly languages: LanguageSelectItem[];
  public readonly userName: string;

  public readonly receiverProperties: readonly ReceiverSelection[];
  public constructor(
    private readonly languageService: LanguageService,
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly tenantInfoService: TenantInfoService
  ) {
    this.languages = this.languageService.getLanguageSelectItems();
    this.userName = this.authenticationService.getUsername();

    this.receiverProperties = [
      {
        value: 'email',
        translation: this.translateService.instant('MQA.EMAIL.RECEIVER_SELECTION.CONTACT')
      },
      {
        value: 'emailForMissingReading',
        translation: this.translateService.instant('MQA.EMAIL.RECEIVER_SELECTION.COMPANY')
      },
    ];

    this.replyAddresses$ =
      this.tenantInfoService.getAutomaticMissingReadingEmail().pipe(
        take(1),
        map(missingReadingEmail => [this.userName, missingReadingEmail])
      );
  }
}
