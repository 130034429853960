import ManualQaApiQuantityService from './manual-qa-api-quantity.service';
import ManualQaApiServiceModule   from '../manual-qa-api';

const dependencies = [ManualQaApiServiceModule.name];

const ManualQaApiQuantityModule = angular
  .module('app.modules.manual-qa.services.manual-qa-api-quantity', dependencies)
  .service('ManualQaApiQuantityService', ManualQaApiQuantityService)
;

export default ManualQaApiQuantityModule;
