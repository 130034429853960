import {
  ComponentRef,
  Directive,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ErrorMessageComponent } from '../components/error-message/error-message.component';

@Directive({
  selector: '[errorMessage]',
})
export class ErrorMessageDirective implements OnDestroy, OnInit {

  private errorElement: HTMLElement;
  private errorComponent: ComponentRef<ErrorMessageComponent>;
  private subscription: Subscription;

  public constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly control: NgControl,
    private readonly renderer: Renderer2
  ) { }

  public ngOnInit(): void {
    this.subscription = this.control.statusChanges.subscribe(status => this.setVisibility(status === 'INVALID'));
  }

  public ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.setVisibility(false);
  }

  @HostListener('focusout')
  public showError(): void {
    this.setVisibility(this.control.status === 'INVALID');
  }

  private setVisibility(visible: boolean): void {
    this.errorElement?.remove();
    this.errorComponent = null;
    this.errorElement = null;

    if (visible) {
      const nativeEl: HTMLElement = this.vcRef.element.nativeElement;
      this.errorComponent = this.vcRef.createComponent(ErrorMessageComponent);
      this.errorComponent.instance.control = this.control;
      this.errorElement = this.errorComponent.instance.vcRef.element.nativeElement;

      this.renderer.appendChild(
        nativeEl.parentElement,
        this.errorComponent.instance.vcRef.element.nativeElement
      );
    }
  }

}
