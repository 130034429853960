<div
  *ngIf="(loading$ | async) as loading"
  class="loading"
  [loadingSpinner]="loading"
></div>
<ng-container *ngIf="(combinedCustomerFields$ | async) as customerFields">
  <form
    *ngIf="customerFields.length"
    [formGroup]="customerFieldsForm"
  >
    <table>
      <tr>
        <th class="mark-col">{{ 'CUSTOMERFIELDS.ON_FACILITY' | translate }}</th>
        <th 
          *ngIf="showTaxonomyCol"
          class="taxonomy-col"
        >
          {{ 'CUSTOMERFIELDS.TAXONOMY' | translate }}
        </th>
        <th>{{ 'ADMIN.NAME' | translate }}</th>
        <th [ngClass]="showTaxonomyCol ? 'value-col' : 'value-col-wider'">
          {{ 'FACILITY.VALUE' | translate }}
        </th>
        <th class="actions-col">{{ 'GROUPS.FUNCTIONS' | translate }}</th>
      </tr>
      <tr *ngFor="let field of customerFields; let index = index">
        <td [ngClass]="(addedCustomerFieldIds$ | async)[field.id] ? 'mark-col added-field' : 'mark-col'">
          <ng-container *ngIf="field.isOnFacility">
            <i class="fa fa-check"></i>
          </ng-container>
          <ng-container 
            *ngIf="(deletedCustomerFieldIds$ | async)[field.id]"
          >
            <i class="fa fa-times deleted-field"></i>
          </ng-container>
        </td>
        <td
          *ngIf="showTaxonomyCol"
          class="taxonomy-col"
        >
          {{ field.taxonomy }}
        </td>
        <td>
          {{ field.name }}
        </td>
        <td 
          [ngClass]="{ 
            'added-field' : (addedCustomerFieldIds$ | async)[field.id],
            'updated-field' : (updatedCustomerFieldIds$ | async)[field.id]
          }"
        >
          <ng-container *ngIf="rowInEdit === index; else readonlyValue">
            <input
              type="text"
              formControlName="value"
              kendoTextBox
            >
          </ng-container>
          <ng-template #readonlyValue>
            {{ field.value }}
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="rowInEdit === index; else defaultActions">
            <button
              type="submit"
              class="button"
              (click)="saveField(field)"
              [disabled]="!customerFieldsForm.valid"
              title="{{ 'SAVE' | translate }}"
            >
              <i class="fas fa-check"></i>
            </button>
            <button
              *ngIf="field.isOnFacility"
              type="button"
              class="button button--negative"
              (click)="deleteField(field)"
              title="{{ 'CUSTOMERFIELDS.REMOVE_FROM_FACILITY' | translate }}" 
            >
              <i class="fa fa-trash-alt"></i>
            </button>
            <button
              type="button"
              class="button button--negative"
              (click)="cancelEdit()"
              title="{{ 'CANCEL' | translate }}"
            >
              <i class="fas fa-ban"></i>
            </button>
          </ng-container>
          <ng-template #defaultActions>
            <button
              type="button"
              class="button"
              (click)="editField(field, index)"
              [title]="(field.isOnFacility ? 'ADMIN.EDIT' : 'CUSTOMERFIELDS.ADD_TO_FACILITY') | translate"
            >
              <i class="fas fa-pencil-alt"></i>
            </button>
          </ng-template>
        </td>
      </tr>
    </table>
  </form>
  <div
    *ngIf="!customerFields.length && !(loading$ | async)"
    class="no-data"
  >    
    {{ 'CUSTOMERFIELDS.NOFIELDS' | translate }}
  </div>
</ng-container>
