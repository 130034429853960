import { calculateStatistics, formatStatistics } from './manual-qa-consumption-stats.functions';

const translations = [
  'MQA.INSPECT.ACTIONS.INSPECTION_PERIOD',
  'MQA.INSPECT.ACTIONS.COMPARISON_PERIOD',
  'MQA.INSPECT.ACTIONS.OPERATION_PERIOD',
  'MQA.INSPECT.ACTIONS.PREVIEW',
  'MQA.INSPECT.ACTIONS.MODEL'
];

const $inject = ['utils'];

class ManualQaConsumptionStatsService {
  constructor(utils) {
    this.utils = utils;
    this.translations = utils.mapLocalizationTagArrayToLocalizedObject(translations);
  }

  getStatistics(readings, timeFrame, isPreview, useComparisonPeriod) {
    const statistics = calculateStatistics(readings, timeFrame);

    return formatStatistics(this.translations, statistics, isPreview, useComparisonPeriod);
  }
}

ManualQaConsumptionStatsService.$inject = $inject;

export default ManualQaConsumptionStatsService;
