import _ from 'lodash';

import { METER_FORECAST, METER_FORECAST_SUM } from '../../constants/er-modal-states.constant';
import EnergyReportingConstants from '../../constants/report-constants';

const $inject = ['$state', 'ReportSettingService', 'erReportSettingsService'];

class ReportForecastSettingsController {
  constructor($state, ReportSettingService, erReportSettingsService) {
    this.$state = $state;
    this.ReportSettingService = ReportSettingService;
    this.erReportSettings = erReportSettingsService.getInstance();
  }

  $onInit() {
    this.settingTypes = this.ReportSettingService.getSettingsTypes();
    const settingType = this.settingTypes[_.includes(this.getStateName(), 'forecast-sum') ? 1 : 0];
    settingType.active = true;
  }

  getStateName() {
    return this.erReportSettings.getSettings().name || this.$state.$current.name;
  }

  onSelectedTypeChanged(settingType) {
    if (_.isFunction(this.reportTypeChanged)) {
      this.changeModalReportType(settingType);
    } else {
      this.ReportSettingService.changeStateBySettingType(settingType, 'forecast');
    }
  }

  changeModalReportType(settingType) {
    let reportType;
    switch (settingType.key) {
      case EnergyReportingConstants.SettingType.BySum:
        reportType = METER_FORECAST_SUM;
        break;
      case EnergyReportingConstants.SettingType.ByObject:
        reportType = METER_FORECAST;
        break;
    }
    if (reportType) {
      this.reportTypeChanged({ reportType: reportType });
    }
  }
}

ReportForecastSettingsController.$inject = $inject;

export default ReportForecastSettingsController;
