const $inject = ['$http', 'UserService'];

/**
 * Service that handles all manual qa server calls.
 *
 * @returns {Object}
 *
 * @constructor
 */
class ManualQaApiService {
  constructor($http, UserService) {
    this.$http = $http;
    this.userService = UserService;
  }

  /**
   * Handles actual http call to backend with given parameters.
   *
   * @param {Object} parameters
   *
   * @returns {Object}
   */
  doRequest(parameters) {
    const defaultParameters = {
      headers: this.userService.getAuthorizationHeader(),
      isCancellable: true
    };

    return this.$http(Object.assign({}, parameters, defaultParameters));
  }
}

ManualQaApiService.$inject = $inject;

export default ManualQaApiService;
