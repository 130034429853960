import _ from 'lodash';

import { hasChanges } from '@enerkey/angular-utils';

import * as Configs from '../../constants/configs';
import * as SpreadsheetConstants from './manual-qa-spreadsheet.constants';
import * as SpreadsheetFunctions from './manual-qa-spreadsheet.functions';
import { removeSpreadsheetOrphans } from '../../../../shared/ek-kendo/kendo.functions';

const $inject = [
  '$scope', '$timeout', '$window',
  '$element', '$document', 'KendoFunctions',
  'ManualQaSpreadsheetContextMenuService', 'ManualQaSpreadsheetService'
];

function ManualQaSpreadsheetController(
  $scope, $timeout, $window,
  $element, $document, KendoFunctions,
  ManualQaSpreadsheetContextMenuService, ManualQaSpreadsheetService
) {
  let vm = this;

  let resizeFn = () => {};

  vm.isCardExpanded = false;
  vm.constants = Configs;
  vm.spreadsheet = null; // Spreadsheet instance
  vm.isComponentLoading = false;

  vm.$onInit = onInit;
  vm.$postLink = postLink;
  vm.$onChanges = onChanges;
  vm.$onDestroy = onDestroy;
  vm.selectOperationRange = selectOperationRange;
  vm.toggleCardSize = toggleCardSize;
  vm.handleResize = handleResize;

  function onInit() {
    vm.timeFrame = angular.copy(vm.timeFrame);
    vm.viewTimeFrame = angular.copy(vm.viewTimeFrame);
  }

  $scope.$on('manual-qa-data-source-changed-spreadsheet', () => {
    vm.isComponentLoading = true;

    $timeout(() => {
      vm.spreadsheet = getSpreadsheetInstance();

      KendoFunctions.resizeKendoComponent(vm.spreadsheet, Configs.CARD_CLASS, () => {
        updateSpreadsheet();
        vm.isComponentLoading = false;
      }, true);
    });
  });

  $scope.$on('manual-qa-topbar-resized', handleResize);

  function postLink() {
    vm.spreadsheet = getSpreadsheetInstance();

    resizeFn = _.debounce(handleResize, 200);

    angular.element($window).on('resize', resizeFn);

    handleResize();
  }

  /**
   * Returns spreadsheet instance. Needed f.e after changing
   * spreadsheetOptions because k-rebind recreates the spreadsheet
   *
   * @return {*}
   */
  function getSpreadsheetInstance() {
    return $element
      .find(SpreadsheetConstants.SPREADSHEET_SELECTOR)
      .data('kendoSpreadsheet');
  }

  /**
   * Called when readings values are changed
   *
   * @param {Object} changes
   */
  function onChanges(changes) {
    if (
      changes.timeFrame &&
      changes.timeFrame.currentValue &&
      !changes.timeFrame.currentValue.isSame(changes.timeFrame.previousValue)
    ) {
      vm.timeFrame = angular.copy(vm.timeFrame);

      selectOperationRange({ focusOnOperationRange: true });
    }

    if (
      changes.viewTimeFrame &&
      changes.viewTimeFrame.currentValue &&
      !changes.viewTimeFrame.currentValue.isSame(changes.viewTimeFrame.previousValue)
    ) {
      vm.viewTimeFrame = angular.copy(vm.viewTimeFrame);

      scrollToViewTimeFrame();
    }

    if (
      hasChanges(changes, 'fetchType') ||
      hasChanges(changes, 'useComparisonPeriod') ||
      hasChanges(changes, 'isLoading')
    ) {
      vm.spreadsheetOptions = ManualQaSpreadsheetService.getSpreadsheetOptions(
        vm.dataSource.data(),
        vm.fetchType,
        vm.useComparisonPeriod
      );

      $timeout(() => {
        vm.spreadsheet = getSpreadsheetInstance();
      });
    }
  }

  function onDestroy() {
    vm.spreadsheet.destroy();
    angular.element($window).off('resize', resizeFn);
    removeSpreadsheetOrphans();
  }

  /**
   * Updates spreadsheet with given values.
   */
  function updateSpreadsheet() {
    if (!vm.spreadsheet || vm.dataSource.total() <= 0) {
      return;
    }

    const readings = vm.dataSource.data();

    ManualQaSpreadsheetContextMenuService.createCellContextMenu(vm);

    vm.spreadsheet
      .activeSheet()
      .batch(() => {
        updateSpreadsheetValues();
        SpreadsheetFunctions.lockSpreadsheet(vm.spreadsheet, readings, vm.fetchType);
      }, { layout: true })
    ;

    setEvents();

    selectOperationRange();
    scrollToViewTimeFrame();
  }

  /**
   * Selects operation range from spreadsheet.
   */
  function selectOperationRange() {
    if (!vm.spreadsheet) {
      return;
    }

    const activeSheet = vm.spreadsheet.activeSheet();

    activeSheet.batch(() => {
      updateBackgroundColors();
    }, { layout: true });
  }

  function scrollToViewTimeFrame() {
    if (!vm.spreadsheet || vm.dataSource.total() <= 0) {
      return;
    }

    const readings = vm.dataSource.data();
    const range = SpreadsheetFunctions.getRangeWithTimeFrame(readings, vm.viewTimeFrame, vm.fetchType);

    vm.spreadsheet
      .activeSheet()
      .range(range.toString())
      .select();

    scrollToOperationRange();
  }

  /**
   * Toggles card size
   */
  function toggleCardSize() {
    vm.isCardExpanded = !vm.isCardExpanded;

    resizeFn();
  }

  function setEvents() {
    if (!vm.spreadsheet) {
      return;
    }

    vm.spreadsheet.unbind('change').bind('change', event => {
      const range = event.range;
      const values = {};

      range.forEachRow(row => {
        const rowIndex = row.topLeft().row - 2;
        const rowValue = row.value();

        values[rowIndex] = { row: rowIndex, value: rowValue };
      });

      vm.onValueChange({ values: values });
    });
  }

  /**
   * Scrolls selected operation range to the top of spreadsheet. Note that .k-spreadsheet-selection is not
   * available all the time so this has to be done with timeout and if user has scrolled operation timeframe
   * out of the visible area of the spreadsheet then this will not work. This function is called after operation
   * range has been selected and focused so it will not cause any problems (hopefully).
   */
  function scrollToOperationRange() {
    $timeout(() => {
      const scroller = angular.element('.k-spreadsheet-scroller');
      const selection = angular.element('.k-spreadsheet-selection');

      if (scroller.length && selection.length) {
        scroller.scrollTop(scroller.scrollTop() + selection.position().top);
      }
      // Blur active element to prevent spreadsheet from stealing focus
      $timeout(() => {
        $document[0].activeElement.blur();
      }, 500);
    });
  }

  /**
   * Updates background colors for whole spreadsheet
   */
  function updateBackgroundColors() {
    if (!vm.spreadsheet || vm.dataSource.total() <= 0) {
      return;
    }

    const readings = vm.dataSource.data();

    SpreadsheetFunctions.resetSpreadsheetBackground(vm.spreadsheet, readings, vm.fetchType);

    SpreadsheetFunctions.updateActualValuesBackground(vm.spreadsheet, readings, vm.fetchType);
    SpreadsheetFunctions.updateModelValuesBackground(vm.spreadsheet, readings, vm.fetchType);
    SpreadsheetFunctions.updateOperationRangeBackground(vm.spreadsheet, readings, vm.timeFrame, vm.fetchType);
    SpreadsheetFunctions.updateLockedRangeBackground(vm.spreadsheet, readings, vm.fetchType);
    SpreadsheetFunctions.updateDefectRangeBackground(vm.spreadsheet, readings, vm.fetchType);
    SpreadsheetFunctions.updateFaultRangeBackground(vm.spreadsheet, readings, vm.fetchType);

    if (vm.useComparisonPeriod) {
      SpreadsheetFunctions.updateComparisonValuesBackground(vm.spreadsheet, readings, vm.fetchType);
    }
  }

  /**
   * Handles batch update for kendo spreadsheet. Currently updates
   * actual and comparison values.
   */
  function updateSpreadsheetValues() {
    if (!vm.spreadsheet || vm.dataSource.total() <= 0) {
      return;
    }

    const readings = vm.dataSource.data();

    SpreadsheetFunctions.updateActualValues(vm.spreadsheet, readings, vm.fetchType);
    SpreadsheetFunctions.updateModelValues(vm.spreadsheet, readings, vm.fetchType);
    SpreadsheetFunctions.updatePreviewValues(vm.spreadsheet, readings, vm.fetchType);

    if (vm.useComparisonPeriod) {
      SpreadsheetFunctions.updateComparisonValues(vm.spreadsheet, readings, vm.fetchType);
    }
  }

  function handleResize() {
    KendoFunctions.resizeKendoComponent(getSpreadsheetInstance(), Configs.CARD_CLASS, null, true);
  }
}

ManualQaSpreadsheetController.$inject = $inject;

export default ManualQaSpreadsheetController;
