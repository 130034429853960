import template from 'raw-loader!./content-header.html';

const ContentHeaderComponent = {
  template: template,
  transclude: true,
  controllerAs: 'vm',
  bindings: {
    noMargin: '<',
    noBorder: '<'
  }
};

export default ContentHeaderComponent;
