import _ from 'lodash';
import { merge } from 'rxjs';

import { $InjectArgs, isEnumKey } from '@enerkey/ts-utils';

import {
  FacilityInformationGroup,
  FacilityMeterCountGroup,
  MeterTreeResponse,
} from '@enerkey/clients/energy-reporting';
import { FacilityContactInformation } from '@enerkey/clients/contact';
import {
  Address,
  BuildingClass,
  CreateFacilityWithOutEnegiaId,
  ExternalIds,
  Facility,
  FacilityProperty,
  Locale,
  Property,
  UpdateFacilities,
  UpdateFacility,
} from '@enerkey/clients/facility';
import { Quantities } from '@enerkey/clients/metering';

import { sortFacilityGroups } from '../shared/facility-sort';
import { Utils } from '../../../services/utils';
import { UserService } from '../../../services/user-service';
import { LanguageChangeService } from '../../../shared/services/language-change.service';
import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';
import { ProfileService } from '../../../shared/services/profile.service';
import { quantityTranslations } from '../../../constants/quantity.constant';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

enum MeterType {
  None = 0,
  Hourly = 1,
  ManualReadings = 2,
  Constant = 3,
  Virtual = 4,
  ManualConsumptions = 5
}

class LegacyFacilityService {
  public static readonly $inject: $InjectArgs<typeof LegacyFacilityService> = [
    '$httpParamSerializer',
    '$q',
    '$http',
    '$timeout',
    'utils',
    'UserService',
    'httpConfigService',
    'languageChangeService',
    'profileService',
    '$translate'
  ];

  private facilitiesDeferred: ng.IDeferred<any>;
  private facilityGroupingDataForCurrentProfileDeferred: ng.IDeferred<any>;
  private metertreeDeferred: ng.IDeferred<any> & { id?: number };
  private facilityLocalesDeferred: ng.IDeferred<Locale[]>;
  private facilityPropertyTypesDirectDeferred: ng.IDeferred<Property[]>;
  private buildingClassTypesDirectDeferred: ng.IDeferred<BuildingClass[]>;
  private facilitiesInformationDeferred: ng.IDeferred<any>;
  private facilitiesContactsDeferred: ng.IDeferred<any>;
  private facilitiesMetersInformationDeferred: ng.IDeferred<any>;
  private facilityInformationGroupingDataForCurrentProfileDeferred: ng.IDeferred<any>;
  private facilityContactsGroupingDataForCurrentProfileDeferred: ng.IDeferred<any>;
  private readonly dateFields = [
    'Created', 'LastModified', 'PowerOfAttorneyExpirationDate', 'ReportingEndDate', 'BillingEndDate'
  ];

  public constructor(
    private readonly $httpParamSerializer: ng.IHttpParamSerializer,
    private readonly $q: ng.IQService,
    private readonly $http: ng.IHttpService,
    private readonly $timeout: ng.ITimeoutService,
    private readonly utils: Utils,
    private readonly userService: UserService,
    private readonly httpConfigService: any,
    private readonly languageChangeService: LanguageChangeService,
    private readonly profileService: ProfileService,
    private readonly $translate: any
  ) {
    merge(
      this.languageChangeService.languageChangeStart,
      this.profileService.profileChange$
    ).subscribe(() => {
      this.doTotalCacheCleanUp();
    });
  }

  public clearContactsInformationCache(): void {
    if (this.facilitiesContactsDeferred) {
      this.facilitiesContactsDeferred.resolve([]);
    }
    this.facilitiesContactsDeferred = null;
  }

  public clearContactsGroupingCache(): void {
    if (this.facilityContactsGroupingDataForCurrentProfileDeferred) {
      this.facilityContactsGroupingDataForCurrentProfileDeferred.resolve([]);
    }
    this.facilityContactsGroupingDataForCurrentProfileDeferred = null;
  }

  public clearInformationCache(): void {
    if (this.facilitiesInformationDeferred) {
      this.facilitiesInformationDeferred.resolve([]);
    }
    this.facilitiesInformationDeferred = null;
  }

  public clearInformationGroupingCache(): void {
    if (this.facilityInformationGroupingDataForCurrentProfileDeferred) {
      this.facilityInformationGroupingDataForCurrentProfileDeferred.resolve([]);
    }
    this.facilityInformationGroupingDataForCurrentProfileDeferred = null;
  }

  public clearMeterCountCache(): void {
    if (this.facilitiesMetersInformationDeferred) {
      this.facilitiesMetersInformationDeferred.resolve([]);
    }
    this.facilitiesMetersInformationDeferred = null;
  }

  public clearPropertyTypesDirectCache(): void {
    if (this.facilityPropertyTypesDirectDeferred) {
      this.facilityPropertyTypesDirectDeferred.resolve([]);
    }
    this.facilityPropertyTypesDirectDeferred = null;
  }

  public clearBuildingClassTypesDirectCache(): void {
    if (this.buildingClassTypesDirectDeferred) {
      this.buildingClassTypesDirectDeferred.resolve([]);
    }
    this.buildingClassTypesDirectDeferred = null;
  }

  public clearFacilitiesCache(): void {
    if (this.facilitiesDeferred) {
      this.facilitiesDeferred.resolve([]);
    }
    this.facilitiesDeferred = null;
    if (this.facilityGroupingDataForCurrentProfileDeferred) {
      this.facilityGroupingDataForCurrentProfileDeferred.resolve([]);
    }
    this.facilityGroupingDataForCurrentProfileDeferred = null;
  }

  public clearMetertreeCache(): void {
    if (this.metertreeDeferred) {
      this.metertreeDeferred.resolve([]);
    }
    this.metertreeDeferred = null;
  }

  public clearPropertiesRelatedCache(): void {
    this.clearFacilitiesCache();
    this.clearInformationRelatedCache();
  }

  public clearContactsRelatedCache(): void {
    this.clearContactsInformationCache();
    this.clearContactsGroupingCache();
  }

  public clearInformationRelatedCache(): void {
    this.clearInformationCache();
    this.clearInformationGroupingCache();
  }

  public clearEnergyReportingApiRelatedCache(): void {
    this.clearFacilitiesCache();
    this.clearMetertreeCache();
  }

  public doTotalCacheCleanUp(): void {
    this.clearEnergyReportingApiRelatedCache();
    this.clearPropertyTypesDirectCache();
    this.clearBuildingClassTypesDirectCache();
    this.clearInformationRelatedCache();
    this.clearContactsRelatedCache();
    this.clearMeterCountCache();
  }

  public clearFacilityInformationCache(): void {
    this.clearFacilitiesCache();
    this.clearInformationRelatedCache();
  }

  public doTotalCacheCleanUpIfNeeded(profileIds: number[]): void {
    const currentProfileId = this.userService.getCurrentProfile().reportingObjectSetId;
    if (profileIds.some(profileId => currentProfileId === profileId)) {
      this.doTotalCacheCleanUp();
    }
  }

  public getFacility(id: number): ng.IPromise<ExtendedFacilityInformation> {
    const deferred = this.$q.defer();

    this.getFacilities().then(
      response => {
        const foundFacility = _.find(response,
          (facility: any) => facility.FacilityId === id);
        if (!foundFacility) {
          this.$http.get(`${ENERKEY_CONFIG.apiEnergyreporting}/api/v1/facilityinformation/id/${id}`,
            this.httpConfigService.getExtendedHttpConfig())
            .then(result => {
              deferred.resolve(result.data);
            })
            .catch(result => {
              deferred.reject(result.status);
            });
        } else {
          deferred.resolve(foundFacility);
        }
      },
      () => {
        deferred.reject();
      }
    );

    return deferred.promise;
  }

  public getFacilities(): ng.IPromise<ExtendedFacilityInformation[]> {
    if (!this.facilitiesDeferred) {
      this.facilitiesDeferred = this.$q.defer();
      this.facilityGroupingDataForCurrentProfileDeferred = this.$q.defer();
      this.$q.all([
        this.getFacilitiesInformation(),
        this.getFacilitiesContacts(),
        this.getFacilitiesMetersInformation(),
        // Ensure that translation json files are loaded
        this.$translate('GROUPS.FACILITYINFORMATION')
      ])
        .then(results => {
          const information = results.shift();
          const contacts = results.shift();
          const meterInformation = results.shift();
          const merged = _(information)
            .concat(contacts, meterInformation)
            .groupBy('FacilityId')
            .map(_.spread(_.merge))
            .value();
          let groups = this.makeGroups(merged);
          groups = sortFacilityGroups(groups);
          groups.Groups.forEach(g => {
            g.Items.forEach((i: any) => {
              if (i.Type === 'date') {
                merged.forEach((facility: any) => {
                  if (facility?.[g.Property]?.[i.Property]) {
                    facility[g.Property][i.Property] = new Date(facility[g.Property][i.Property]);
                  }
                });
              }
            });
          });
          this.facilityGroupingDataForCurrentProfileDeferred.resolve(groups);
          if (this.facilitiesDeferred) {
            this.facilitiesDeferred.resolve(merged);
          }
        })
        .catch(() => {
          this.facilitiesDeferred.reject();
          this.$timeout(() => {
            this.facilitiesDeferred = null;
          });
        });
    }
    return this.facilitiesDeferred.promise;
  }

  public getFacilitiesInformation(): ng.IPromise<FacilityInformationGroup[]> {
    if (!this.facilitiesInformationDeferred) {
      this.facilitiesInformationDeferred = this.$q.defer();
      this.$http.get(`${ENERKEY_CONFIG.apiEnergyreporting}/api/v1/facilityinformation`,
        this.httpConfigService.getExtendedHttpConfig({}))
        .then(response => {
          if (this.facilitiesInformationDeferred) {
            this.facilitiesInformationDeferred.resolve(response.data);
          }
        })
        .catch(response => {
          if (this.facilitiesInformationDeferred) {
            this.facilitiesInformationDeferred.reject(response.status);
            this.$timeout(() => {
              this.facilitiesInformationDeferred = null;
            });
          }
        });
    }
    return this.facilitiesInformationDeferred.promise;
  }

  public getFacilitiesContacts(): ng.IPromise<FacilityContactInformation[]> {
    if (!this.facilitiesContactsDeferred) {
      const profileId = this.userService.getCurrentProfile().reportingObjectSetId;

      this.facilitiesContactsDeferred = this.$q.defer();
      this.$http.get(
        `${ENERKEY_CONFIG.apiContact}/api/Company/ProfileContactCompanies/${profileId}`,
        this.httpConfigService.getExtendedHttpConfig({})
      )
        .then(response => {
          if (this.facilitiesContactsDeferred) {
            this.facilitiesContactsDeferred.resolve(this.changeFacilityIdPropertyNameCasing(response));
          }
        })
        .catch(() => {
          if (this.facilitiesContactsDeferred) {
            this.facilitiesContactsDeferred.resolve([]);
            this.$timeout(() => {
              this.facilitiesContactsDeferred = null;
            });
          }
        });
    }
    return this.facilitiesContactsDeferred.promise;
  }

  public getFacilitiesMetersInformation(): ng.IPromise<FacilityMeterCountGroup[]> {
    if (!this.facilitiesMetersInformationDeferred) {
      this.facilitiesMetersInformationDeferred = this.$q.defer();
      this.$http.get(`${ENERKEY_CONFIG.apiEnergyreporting}/api/v1/metersinformation`,
        this.httpConfigService.getExtendedHttpConfig({}))
        .then(response => {
          if (this.facilitiesMetersInformationDeferred) {
            this.facilitiesMetersInformationDeferred.resolve(response.data);
          }
        })
        .catch(response => {
          if (this.facilitiesMetersInformationDeferred) {
            this.facilitiesMetersInformationDeferred.reject(response.status);
            this.$timeout(() => {
              this.facilitiesMetersInformationDeferred = null;
            });
          }
        });
    }
    return this.facilitiesMetersInformationDeferred.promise;
  }

  public getFacilityGroupingDataForCurrentProfile(): ng.IPromise<any> {
    return this.getFacilities().then(() => this.facilityGroupingDataForCurrentProfileDeferred.promise);
  }

  public getFacilityInformationGroupingDataForCurrentProfile(): ng.IPromise<any> {
    if (!this.facilityInformationGroupingDataForCurrentProfileDeferred) {
      this.facilityInformationGroupingDataForCurrentProfileDeferred = this.$q.defer();
      this.getFacilitiesInformation()
        .then(response => {
          let groups = this.makeGroups(response);
          groups = sortFacilityGroups(groups);
          this.facilityInformationGroupingDataForCurrentProfileDeferred.resolve(groups);
        });
    }
    return this.facilityInformationGroupingDataForCurrentProfileDeferred.promise;
  }

  public getFacilityContactsGroupingDataForCurrentProfile(): ng.IPromise<any> {
    if (!this.facilityContactsGroupingDataForCurrentProfileDeferred) {
      this.facilityContactsGroupingDataForCurrentProfileDeferred = this.$q.defer();
      this.getFacilitiesContacts()
        .then(response => {
          let groups = this.makeGroups(response);
          groups = sortFacilityGroups(groups);
          this.facilityContactsGroupingDataForCurrentProfileDeferred.resolve(groups);
        });
    }
    return this.facilityContactsGroupingDataForCurrentProfileDeferred.promise;
  }

  public getMetertree(id: number): ng.IPromise<MeterTreeResponse> {
    if (!this.metertreeDeferred || this.metertreeDeferred.id !== id) {
      this.metertreeDeferred = this.$q.defer();
      this.metertreeDeferred.id = id;
      this.$http.get(`${ENERKEY_CONFIG.apiEnergyreporting}/api/v1/facilities/${id}/metertree`,
        this.httpConfigService.getExtendedHttpConfig({}))
        .then(({ data }) => {
          if (this.metertreeDeferred) {
            this.metertreeDeferred.resolve(data);
          }
        })
        .catch(({ status }) => {
          if (this.metertreeDeferred) {
            this.metertreeDeferred.reject(status);
            this.$timeout(() => {
              this.metertreeDeferred = null;
            });
          }
        });
    }
    return this.metertreeDeferred.promise;
  }

  // Directly from FacilityApi
  public getFacilityPropertyTypes(): ng.IPromise<Property[]> {
    if (!this.facilityPropertyTypesDirectDeferred) {
      this.facilityPropertyTypesDirectDeferred = this.$q.defer();
      this.$http.get<Property[]>(
        `${ENERKEY_CONFIG.apiFacility}/api/v1/property`,
        this.httpConfigService.getExtendedHttpConfig({})
      )
        .then(response => {
          if (this.facilityPropertyTypesDirectDeferred) {
            this.facilityPropertyTypesDirectDeferred.resolve(response.data);
          }
        })
        .catch(response => {
          if (this.facilityPropertyTypesDirectDeferred) {
            this.facilityPropertyTypesDirectDeferred.reject(response.status);
            this.$timeout(() => {
              this.facilityPropertyTypesDirectDeferred = null;
            });
          }
        });
    }
    return this.facilityPropertyTypesDirectDeferred.promise;
  }

  // Directly from FacilityApi
  public getBuildingClassTypes(): ng.IPromise<BuildingClass[]> {
    if (!this.buildingClassTypesDirectDeferred) {
      this.buildingClassTypesDirectDeferred = this.$q.defer();
      this.$http.get<BuildingClass[]>(
        `${ENERKEY_CONFIG.apiFacility}/api/v1/buildingclass`,
        this.httpConfigService.getExtendedHttpConfig({})
      ).then(response => {
        if (this.buildingClassTypesDirectDeferred) {
          this.buildingClassTypesDirectDeferred.resolve(response.data);
        }
      }).catch(response => {
        if (this.buildingClassTypesDirectDeferred) {
          this.buildingClassTypesDirectDeferred.reject(response.status);
          this.$timeout(() => {
            this.buildingClassTypesDirectDeferred = null;
          });
        }
      });
    }
    return this.buildingClassTypesDirectDeferred.promise;
  }

  // Directly from FacilityApi
  public getLocales(): ng.IPromise<Locale[]> {
    if (!this.facilityLocalesDeferred) {
      this.facilityLocalesDeferred = this.$q.defer();
      this.$http.get<Locale[]>(
        `${ENERKEY_CONFIG.apiFacility}/api/v1/locale`,
        this.httpConfigService.getExtendedHttpConfig({})
      )
        .then(response => {
          if (this.facilityLocalesDeferred) {
            this.facilityLocalesDeferred.resolve(response.data);
          }
        })
        .catch(response => {
          if (this.facilityLocalesDeferred) {
            this.facilityLocalesDeferred.reject(response.status);
            this.$timeout(() => {
              this.facilityLocalesDeferred = null;
            });
          }
        });
    }
    return this.facilityLocalesDeferred.promise;
  }

  // Directly from FacilityApi
  public updateFacility(facility: { id: number } & UpdateFacility): ng.IPromise<void> {
    return this.$http.put(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facility.id}`,
      facility,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true }))
      .then(() => {
        this.clearFacilityInformationCache();
      });
  }

  // Directly from FacilityApi
  public updateFacilities(toBeUpdatedFacilities: UpdateFacilities[]): ng.IPromise<void> {
    return this.$http.put(
      `${ENERKEY_CONFIG.apiFacility}/api/v1/facility/`,
      toBeUpdatedFacilities,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true })
    )
      .then(() => {
        this.clearFacilityInformationCache();
      });
  }

  // Directly from FacilityApi
  public createFacilities(toBeCreatedFacilities: CreateFacilityWithOutEnegiaId[]): ng.IPromise<Facility[]> {
    return this.$http.post<Facility[]>(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/create/bulk/`,
      toBeCreatedFacilities,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true }))
      .then(response => response.data);
  }

  public addProperties(facilityId: string, payload: FacilityProperty[]): ng.IPromise<FacilityProperty[]> {
    return this.$http.post<FacilityProperty[]>(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facilityId}/property`,
      payload,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true }))
      .then(response => {
        this.clearPropertiesRelatedCache();
        return response.data;
      });
  }

  public deleteProperties(facilityId: number, properties: { id: number }[]): ng.IPromise<void> {
    const preSerializedProperties = { facilityPropertyId: _.map(properties, 'id') };
    const props = this.$httpParamSerializer(preSerializedProperties);
    return this.$http.delete(
      `${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facilityId}/facilityproperty?${props}`,
      this.httpConfigService.getExtendedHttpConfig()
    )
      .then(() => {
        this.clearPropertiesRelatedCache();
      });
  }

  public deleteAddress(facilityId: number, address: Address): ng.IPromise<void> {
    return this.$http.delete(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facilityId}/address/${address.id}`,
      this.httpConfigService.getExtendedHttpConfig({}))
      .then(() => {
        this.clearFacilityInformationCache();
      });
  }

  public updateAddress(facilityId: number, address: Address): ng.IPromise<void> {
    return this.$http.put(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facilityId}/address/${address.id}`,
      address,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true }))
      .then(() => {
        this.clearFacilityInformationCache();
      });
  }

  public addAddress(facilityId: number, address: Address): ng.IPromise<void> {
    return this.$http.post(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facilityId}/address/`,
      address,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true }))
      .then(() => {
        this.clearFacilityInformationCache();
      });
  }

  public addOrUpdateExternalIds(facilityId: number, externalIds: ExternalIds): ng.IPromise<void> {
    return this.$http.post(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facilityId}/externalid/`,
      externalIds,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true }))
      .then(() => {
        this.clearFacilityInformationCache();
      });
  }

  public deleteExternalIds(facilityId: number): ng.IPromise<void> {
    return this.$http.delete(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility/${facilityId}/externalid/`,
      this.httpConfigService.getExtendedHttpConfig({ isCancellable: true }))
      .then(() => {
        this.clearFacilityInformationCache();
      });
  }

  public getFacilitiesDirect(ids: number[]): ng.IPromise<Facility[]> {
    return this.$http.post<Facility[]>(`${ENERKEY_CONFIG.apiFacility}/api/v1/facility`,
      ids.map(x => Number(x)),
      this.httpConfigService.getExtendedHttpConfig({}))
      .then(response => response.data);
  }

  private makeGroups(facilitiesArray: any[]): { Groups: any[] } {
    type GroupType = {
      Property: string;
      Items: any[];
      Name: string;
      TagGroup?: boolean;
    };

    let grouping: any[] = [];
    _.forEach(facilitiesArray, facility => {
      _.forOwn(facility, (items, propertyName) => {
        if (_.isObject(items) && !_.isEmpty(items)) {
          // Adds group if not existing
          if (!_.find(grouping, { Property: propertyName })) {
            const group: GroupType = {
              Property: propertyName,
              Items: [],
              Name: this.isTagGroup(propertyName)
                ? this.utils.localizedString('TAGS.TITLE')
                : this.utils.localizedString(`GROUPS.${propertyName}`),
            };

            if (this.isTagGroup(propertyName)) {
              group.TagGroup = true;
            }

            grouping.push(group);
          }

          // Adds group items for the group
          grouping = this.setGroupItems(grouping, propertyName, items);
        }
      });
    });
    return { Groups: grouping };
  }

  private setGroupItems(
    grouping: any,
    propertyName: any,
    items: any
  ): any {
    type GroupItemType = {
      Aggregates: any;
      Name: string;
      Property: any;
      Type: string;
      Groupable: boolean;
      IsTag?: boolean;
    };

    const meterGroups = [
      'SubMeterCounts',
      'MainMeterCounts'
    ];

    _.forEach(items, (value, property) => {
      const group = _getGrouping(grouping, propertyName, property);
      // Removes need to have duplicate translations
      const translationKey = _getTranslationGroupKeyForMainGroups(propertyName);
      // Most of the translations are not using snake case (they should)
      // so we can't use snake case for every property
      let translatedName = '';
      if (translationKey === 'MeterCounts') {
        translatedName = this.getTranslationForMeterCounts(property);
      } else if (propertyName === 'CustomerFields') {
        translatedName = property;
      } else {
        const translationProperty = translationKey === 'QUANTITIES' ? _.snakeCase(property) : property;
        translatedName = this.utils.localizedString(`${translationKey}.${translationProperty}`);
      }

      let isDate = false;
      if (this.dateFields.includes(property)) {
        isDate = true;
      }

      const item: GroupItemType = {
        Aggregates: _getAggregate(group.Property),
        Name: translatedName,
        Property: property,
        Type: isDate ? 'date' : typeof value,
        Groupable: this.isGroupable(group.Property, property),
      };

      if (this.isTagGroup(propertyName)) {
        item.IsTag = true;
        item.Name = property;
      }

      if (!_.find(group.Items, { Property: property })) {
        group.Items.push(item);
      }
    });
    return grouping;

    function _getGrouping(currentGrouping: any, propName: string, property: string): any {
      if (property === 'status') {
        return _.find(currentGrouping, { Property: 'FacilityInformation' });
      }
      return _.find(currentGrouping, { Property: propName });
    }

    function _getAggregate(groupName: string): string[] {
      if (_.includes(meterGroups, groupName)) {
        return ['sum'];
      }
      return null;
    }
    function _getTranslationGroupKeyForMainGroups(type: string): string {
      if (type === 'Co2Factors') {
        return 'QUANTITIES';
      }
      return _.includes(meterGroups, type) ? 'MeterCounts' : type;
    }
  }

  private isGroupable(groupName: string, property: string): boolean {
    if (groupName === 'FacilityInformation') {
      return (
        property !== 'EnegiaId' &&
        property !== 'CustomerSetDescription' &&
        property !== 'Created' &&
        property !== 'LastModified'
      );
    }
    return true;
  }

  private isTagGroup(property: string): boolean {
    return property === 'Tags' || property === 'tags';
  }

  private changeFacilityIdPropertyNameCasing(response: any): ng.IPromise<any> {
    return response.data.map((contact: any) => {
      contact.FacilityId = contact.facilityId;
      return contact;
    });
  }

  //#region Meter Name stuff

  private getTranslationForMeterCounts(translationProperty: string): string {
    const energyTypeAndMeterType = translationProperty.slice(0, -'MetersCount'.length);
    if (energyTypeAndMeterType.endsWith(MeterType[MeterType.Hourly])) {

      return this.getTranslation(energyTypeAndMeterType, MeterType.Hourly);
    }
    if (energyTypeAndMeterType.endsWith(MeterType[MeterType.ManualReadings])) {

      return this.getTranslation(energyTypeAndMeterType, MeterType.ManualReadings);
    }
    if (energyTypeAndMeterType.endsWith(MeterType[MeterType.Constant])) {

      return this.getTranslation(energyTypeAndMeterType, MeterType.Constant);
    }
    if (energyTypeAndMeterType.endsWith(MeterType[MeterType.Virtual])) {

      return this.getTranslation(energyTypeAndMeterType, MeterType.Virtual);
    }
    if (energyTypeAndMeterType.endsWith(MeterType[MeterType.ManualConsumptions])) {

      return this.getTranslation(energyTypeAndMeterType, MeterType.ManualConsumptions);
    }

    return translationProperty;
  }

  private getTranslation(energyTypeAndMeterType: string, meterType: MeterType): string {
    const energyType = energyTypeAndMeterType.slice(0, -MeterType[meterType].length);
    const translatedEnergyType = this.utils.localizedString(
      isEnumKey(Quantities, energyType)
        ? quantityTranslations[Quantities[energyType]]
        : `QUANTITIES.${_.snakeCase(energyType).toUpperCase()}`
    );
    const translatedMeterType = this.utils.localizedString(
      `METER_COUNTS.${_.snakeCase(MeterType[meterType]).toUpperCase()}`
    );

    return `${translatedEnergyType}, ${translatedMeterType}`;
  }

  //#endregion Meter Name stuff
}

export { LegacyFacilityService };
