import _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttachmentType } from '@enerkey/clients/attachments';

documents.$inject = [
  'utils', 'UserService', 'AttachmentsClient'
];

function documents(
  utils, UserService, attachmentsClient
) {
  const OTHER = 0;
  const METER_MAP = 1;
  const PERIODICAL_DOCUMENT = 2;

  const MONTHLY_REPORT = 3;
  const QUARTERLY_REPORT = 4;
  const YEARLY_REPORT = 5;

  const CONTRACT = 6;
  const FACILITY_REWIEW = 7;
  const FACILITY_ANALYSIS = 8;
  const MEASUREMENT_DIAGRAM = 9;
  const COST_SPLIT_DOCUMENT = 10;
  const ACTION_ATTACHMENT = 11;
  const ENERGY_BILL = 12;
  const ENERGY_PERFORMANCE_CERTIFICATE = 13;
  const POWER_OF_ATTORNEY = 14;
  const GUIDE = 15;

  const documentTypes = [
    { id: OTHER, key: 'DOCUMENTS.DOCUMENT_TYPE_0' },
    { id: METER_MAP, key: 'DOCUMENTS.DOCUMENT_TYPE_1' },
    { id: METER_MAP, key: 'DOCUMENTS.DOCUMENT_TYPE_1' },
    { id: PERIODICAL_DOCUMENT, key: 'DOCUMENTS.DOCUMENT_TYPE_2' },
    { id: CONTRACT, key: 'DOCUMENTS.DOCUMENT_TYPE_6' },
    { id: FACILITY_REWIEW, key: 'DOCUMENTS.DOCUMENT_TYPE_7' },
    { id: FACILITY_ANALYSIS, key: 'DOCUMENTS.DOCUMENT_TYPE_8' },
    { id: MEASUREMENT_DIAGRAM, key: 'DOCUMENTS.DOCUMENT_TYPE_9' },
    { id: COST_SPLIT_DOCUMENT, key: 'DOCUMENTS.DOCUMENT_TYPE_10' },
    { id: ACTION_ATTACHMENT, key: 'DOCUMENTS.DOCUMENT_TYPE_11' },
    { id: ENERGY_BILL, key: 'DOCUMENTS.DOCUMENT_TYPE_12' },
    { id: ENERGY_PERFORMANCE_CERTIFICATE, key: 'DOCUMENTS.DOCUMENT_TYPE_13' },
    { id: POWER_OF_ATTORNEY, key: 'DOCUMENTS.DOCUMENT_TYPE_14' },
    { id: GUIDE, key: 'DOCUMENTS.DOCUMENT_TYPE_15' },
    { id: MONTHLY_REPORT, key: 'DOCUMENTS.SYNTHETIC_DOCUMENT_TYPE_3' },
    { id: QUARTERLY_REPORT, key: 'DOCUMENTS.SYNTHETIC_DOCUMENT_TYPE_4' },
    { id: YEARLY_REPORT, key: 'DOCUMENTS.SYNTHETIC_DOCUMENT_TYPE_5' }
  ];

  const documentTypesById = _.indexBy(documentTypes, 'id');

  const getAttachmentType = function(document) {
    if (document.meterIds && document.meterIds.length > 0) {
      return AttachmentType.Meter;
    } else if (document.reportingObjectId) {
      return AttachmentType.ReportingObject;
    } else if (document.organizationId) {
      return AttachmentType.Organization;
    } else {
      return AttachmentType.General;
    }
  };

  const updateAttachmentType = function(document) {
    document.AttachmentType = getAttachmentType(document);
  };

  function updateFriendlyNameFields(document) {
    if (_.isNumber(document.documentType)) {
      const docType = documentTypesById[document.documentType];
      if (docType) {
        document.DocumentTypeName = utils.localizedString(docType.key);
      }
    }

    if (!_.isNumber(document.AttachmentType)) {
      updateAttachmentType(document);
    }
  }

  return {
    updateFriendlyNameFields: updateFriendlyNameFields,
    getMapImages: function(extraParams) {
      return firstValueFrom(attachmentsClient.getMetermap(
        UserService.profileId,
        extraParams.reportingObjectId ?? undefined,
        extraParams.meterId ?? undefined
      ).pipe(
        map(result => utils.keysToUpperCamelCase(result))
      ));
    },

    getDocuments: function(searchCriteria) {
      return attachmentsClient.getDocuments(
        UserService.profileId,
        undefined,
        searchCriteria.attachmentType ?? undefined,
        undefined,
        undefined,
        searchCriteria.reportingObjectId ? [searchCriteria.reportingObjectId] : undefined
      )
        .pipe(
          map(docs => {
            docs.forEach(doc => {
              updateFriendlyNameFields(doc);
            });
            return docs;
          })
        );
    }
  };
}

export default documents;
