import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { ModalBase, ModalOptions } from '@enerkey/foundation-angular';
import { NgfActiveModal } from '@enerkey/foundation-angular';

@Component({
  templateUrl: './number-input-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'small' })
export class NumberInputModalComponent extends ModalBase<number> implements AfterViewInit {
  public form: UntypedFormGroup;
  public headingText: string;
  public format: NumberFormatOptions;
  public label: string;

  @ViewChild(NumericTextBoxComponent) public input: NumericTextBoxComponent;

  public constructor(
    activeModal: NgfActiveModal,
    formBuilder: UntypedFormBuilder
  ) {
    super(activeModal);
    this.form = formBuilder.group({
      value: [null, Validators.required]
    });
  }

  public close(): void {
    super.closeModal(this.form.value.value);
  }

  public ngAfterViewInit(): void {
    this.input.focus();
  }
}
