import { AfterViewInit, Directive, Optional } from '@angular/core';
import { NumericTextBoxComponent, TextBoxComponent } from '@progress/kendo-angular-inputs';

@Directive({
  selector: '[kendoAutoFocus]'
})
export class KendoAutoFocusDirective implements AfterViewInit {

  public constructor(
    @Optional() private readonly textbox: TextBoxComponent,
    @Optional() private readonly numericTextbox: NumericTextBoxComponent
  ) { }

  public ngAfterViewInit(): void {
    (this.textbox || this.numericTextbox)?.focus();
  }

}
