import { Service } from '../../constants/service';

run.$inject = [
  '$rootScope', 'UserService', 'facilities',
  'energyReportingStates', '$transitions', 'erReportSettingsService', 'modalService',
  'profileService'
];

function run(
  $rootScope, UserService, facilities,
  energyReportingStates, $transitions, erReportSettingsService, modalService,
  profileService
) {
  function runInitializationFn() {
    if (!UserService.hasService(Service.EnergyReporting)) {
      return;
    }

    // from Cinia© with love
    erReportSettingsService.createNewInstance('er-report');
    erReportSettingsService.createNewInstance('er-modal-report');
    erReportSettingsService.setActiveInstance('er-report');
  }

  UserService.isInitializedWithInitialProfileAsync().then(() => {
    runInitializationFn();
  });

  profileService.profileChange$.subscribe(() => {
    runInitializationFn();
  });
}

export default run;
