<kendo-dropdownlist
  [defaultItem]="defaultItem"
  [data]="sourceData"
  [valueField]="valueField"
  [textField]="textField"
  [kendoDropDownFilter]="filterSettings"
  [disabled]="disabled"
  (valueChange)="valueChanged($event)"
  [valuePrimitive]="true"
  [value]="value"
>
</kendo-dropdownlist>

