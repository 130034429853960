import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GroupedConsumptionsWidgetOptions
} from '../components/grouped-consumptions-widget/grouped-consumptions-widget.component';
import { getValueTypeOptions } from '../../energy-reporting/shared/value-type-options';
import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import {
  ConsumptionsGroupedResponse,
  ConsumptionsRequest,
  EnergyReportingClient
} from '@enerkey/clients/energy-reporting';
import {
  GroupedConsumptions,
  GroupedConsumptionsWidgetRow,
} from '../shared/grouped-consumptions';
import { ChangeWidgetData, ChangeWidgetValueKey } from '../components/change-widget-base';
import { ChangeWidgetServiceBase } from './change-widget-service-base';

@Injectable()
export class GroupedConsumptionsService extends ChangeWidgetServiceBase<
GroupedConsumptionsWidgetOptions, GroupedConsumptionsWidgetRow, ConsumptionsGroupedResponse
> {
  public constructor(
    private readonly erClient: EnergyReportingClient
  ) {
    super();
  }

  public getGroupedConsumptionsTransitionParams(
    dataModelOptions: GroupedConsumptionsWidgetOptions,
    start: TimeFrameResult
  ): any {
    const valueOptions = getValueTypeOptions(dataModelOptions.valueOption);

    return {
      quantityId: this.getQuantityId(dataModelOptions),
      series: {
        Start: start.Start,
        Resolution: start.Resolution,
        TimeFrame: start.TimeFrame,
        Measured: valueOptions.measured,
        Normalized: valueOptions.normalized,
        RelationalUnitIds: dataModelOptions.variableId ? [dataModelOptions.variableId] : []
      },
      unitKey: dataModelOptions.unitKey,
      changeType: dataModelOptions.changeOption.toLowerCase(),
      timeFrameOption: dataModelOptions.timeFrameOption
    };
  }

  protected requestData(params: ConsumptionsRequest): Observable<ConsumptionsGroupedResponse> {
    return this.erClient.consumptionsGroupedResponse(params);
  }

  protected transformData(
    consumptions: ConsumptionsGroupedResponse,
    valueKey: ChangeWidgetValueKey,
    dataModelOptions: GroupedConsumptionsWidgetOptions,
    start: TimeFrameResult
  ): ChangeWidgetData<GroupedConsumptionsWidgetRow> {
    return GroupedConsumptions.getWidgetRows(
      consumptions,
      this.getQuantityId(dataModelOptions),
      valueKey,
      dataModelOptions.variableId,
      start
    );
  }

  protected override getExtraParams(dataModelOptions: GroupedConsumptionsWidgetOptions): Partial<ConsumptionsRequest> {
    return {
      GroupingOptions: {
        GroupProperty: dataModelOptions.groupProperty,
        ItemProperty: dataModelOptions.itemProperty,
      }
    };
  }
}
