import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { WidgetBase } from '../../shared/widget-base.interface';
import { NewsService } from '../../../../shared/services/news.service';
import { ReadOnlyNews } from '@enerkey/clients/news';

@Component({
  selector: 'news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsWidgetComponent implements WidgetBase<never> {
  @Input() public dataModelOptions: never;
  public readonly dataModelChange$ = EMPTY;
  public readonly error$ = new Subject<void>();

  public readonly news$: Observable<ReadOnlyNews[]>;
  public readonly loading$: Observable<boolean>

  public constructor(private readonly newsService: NewsService) {
    this.loading$ = this.newsService.newsLoading$;
    this.news$ = this.newsService.news$.pipe(
      catchError(() => {
        this.error$.next();
        return of([]);
      })
    );
  }

  public openNewsModal(entry: ReadOnlyNews): void {
    this.newsService.openNewsModal(entry);
  }
}
