import { LogLiteDto } from '@enerkey/clients/alarm';
import { ActionSlimViewModel } from '@enerkey/clients/attachments';

import EmValidationService from '../../energy-management/services/em-validations-service';

export type NoteType = 'alarm' | 'comment' | 'action';

type MatchedDate = 'effectStartsAt' | 'effectStopsAt';

export interface NoteDataContainer {
  alarms: LogLiteDto[];
  actions: ActionSlimViewModel[];
}

export interface NoteContainer {
  alarms: ReportNote[];
  comments: ReportNote[];
  actions: ReportNote[];
}

export function alarmStatusToActionStatus(status: number): number {
  return Number.isInteger(status) ? (status + 1) % 4 : null;
}

export interface IReportNote {
  readonly type: NoteType;
  readonly alarm?: LogLiteDto;
  readonly action?: ActionSlimViewModel;
  readonly effectStartsAt: Date;
  readonly effectStopsAt?: Date;
  readonly matchedDate: MatchedDate;
  readonly dateName: string;
  readonly status: number;
  readonly level: string;
  readonly index?: number;
}

export class ReportNote implements IReportNote {

  public static parse(
    noteData: LogLiteDto | ActionSlimViewModel,
    matchedDate?: MatchedDate,
    index?: number
  ): ReportNote {
    if (noteData instanceof LogLiteDto) {
      return new ReportNote({
        type: 'alarm',
        alarm: noteData,
        effectStartsAt: noteData.executedAt,
        matchedDate: 'effectStartsAt',
        dateName: kendo.toString(noteData.executedAt, 'd') || '',
        status: alarmStatusToActionStatus(noteData.status),
        level: 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_METER',
        index
      });
    } else {
      const type = EmValidationService.isComment(noteData) ? 'comment' : 'action';
      let level: string;
      if (noteData.meterIds && noteData.meterIds.length > 0) {
        level = 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_METER';
      } else if (noteData.quantityIds && noteData.quantityIds.length > 0) {
        level = 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_QUANTITY';
      } else {
        level = 'FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_FACILITY';
      }
      return new ReportNote({
        type,
        action: noteData,
        effectStartsAt: noteData.effectStartsAt,
        effectStopsAt: noteData.effectStopsAt,
        matchedDate,
        dateName: this.getActionDateName(noteData.effectStartsAt, noteData.effectStopsAt),
        status: noteData.investigation,
        level,
        index
      });
    }
  }

  private static getActionDateName(effectStartsAt: Date, effectStopsAt: Date): string {
    const effectStartsAtFormatted = kendo.toString(effectStartsAt, 'd') || '';
    const effectStopsAtFormatted = kendo.toString(effectStopsAt, 'd') || '';
    return `${effectStartsAtFormatted} - ${effectStopsAtFormatted}`;
  }

  public readonly type: NoteType;
  public readonly alarm: LogLiteDto;
  public readonly action: ActionSlimViewModel;

  public readonly effectStartsAt: Date;
  public readonly effectStopsAt: Date;
  public readonly matchedDate: MatchedDate;
  public readonly dateName: string;

  public readonly status: number;
  public readonly level: string;

  public readonly index: number;

  public constructor(noteData: IReportNote) {
    this.type = noteData.type;
    this.alarm = noteData.alarm;
    this.action = noteData.action;
    this.effectStartsAt = noteData.effectStartsAt;
    this.effectStopsAt = noteData.effectStopsAt;
    this.matchedDate = noteData.matchedDate;
    this.dateName = noteData.dateName;
    this.status = noteData.status;
    this.level = noteData.level;
    this.index = noteData.index;
  }
}
