import { Component, Injector, Input, NgZone, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { MeteringClient, MeterWithProperties, TreeViewItem } from '@enerkey/clients/metering';
import { Facility } from '@enerkey/clients/facility';
import { Helpers } from '@enerkey/ts-utils';
import { ModalBase, ModalService, NgfActiveModal } from '@enerkey/foundation-angular';

import { FormulaEditorService } from '../../services/formula-editor.service';
import { FormulaItemEdit } from '../../shared/formula-item-edit';
import { TargetMeter } from '../../shared/target-meter';
import { AddOperationType } from '../../../admin/shared/add-operation-type';
import { FormulaItemAdd } from '../../shared/formula-item-add';
import { Formula } from '../../shared/formula';
import { FormulaNewItemId } from '../../shared/formula-new-item-id';
import { StateLocationService } from '../../../../services/state-location.service';
import { AddMetersParams } from '../../shared/add-meters-params';
import { AddMetersModalComponent } from '../add-meters-modal/add-meters-modal.component';
import { DialogService } from '../../../../shared/services/dialog.service';
import { ClipboardService } from '../../../../shared/services/clipboard.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'virtual-meter-formula-editor',
  templateUrl: './virtual-meter-formula-editor.component.html',
  styleUrls: ['./virtual-meter-formula-editor.component.scss']
})
export class VirtualMeterFormulaEditorComponent extends ModalBase implements OnInit {
  @Input() public virtualMeterId: number;
  @Input() public resultMeterId: number;
  @Input() public isNewFormula = false;
  @Input() public facility: Facility;
  @Input() public meterName: string;

  public targetMeters: TargetMeter[] = [];
  public loading = false;
  public firstLoad = true;
  public formula: Formula = new Formula([]);
  public modalHeaderText = '';

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly zone: NgZone,
    private readonly modalService: ModalService,
    private readonly toaster: ToasterService,
    private readonly formulaEditorService: FormulaEditorService,
    private readonly injector: Injector,
    private readonly translate: TranslateService,
    private readonly dialogService: DialogService,
    private readonly clipboardService: ClipboardService,
    private readonly stateLocationService: StateLocationService,
    activeModal: NgfActiveModal
  ) {
    super(activeModal);
    FormulaNewItemId.resetNewItemId();
  }

  public get formulaData(): ReadonlyArray<TreeViewItem> {
    return this.formula.formula;
  }

  public get meterIds(): ReadonlyArray<number> {
    return [
      this.resultMeterId,
      ...this.getMeterIdsFromFormula(this.formulaData)
    ];
  }

  public ngOnInit(): void {
    if (this.virtualMeterId !== undefined) {
      this.getFormulaData();
    } else {
      this.updateFormula(new Formula(this.formulaEditorService.getEmptyFormula(this.resultMeterId), true).formula);
    }

    this.setModalHeaderText();
  }

  public addItem(addition: FormulaItemAdd): void {
    switch (addition.type) {
      case AddOperationType.OperandAfter:
      case AddOperationType.OperandBefore:
      case AddOperationType.OperandInside:
        this.addMeters(addition);
        break;
      default:
        this.formula.addItems(addition);
        this.updateFormula(this.formulaData);
    }
  }

  public deleteItem(item: TreeViewItem): void {
    this.formula.deleteItem(item);
    this.updateFormula(this.formulaData);
  }

  public editItem(item: FormulaItemEdit): void {
    this.formula.editItem(item);
    this.updateFormula(this.formulaData);
  }

  public openMeterClicked(): void {
    this.openNewMetersTab(this.resultMeterId);
  }

  public openMetersReport(): void {
    this.openNewMetersTab(...this.meterIds);
  }

  public copyMeterIds(): void {
    this.clipboardService.copy(
      this.meterIds.join(',')
    ).resolved(() => this.toaster.info('ADMIN.VIRTUAL_METERS.EDITOR.METER_IDS_COPIED'));
  }

  public saveFormula(): void {
    const confirm = this.formula.hasOverlapWithOtherFormula ?
      this.dialogService.getConfirmationModalPromise(
        {
          title: this.translate.instant('ADMIN.VIRTUAL_METERS.EDITOR.CONFIRM_SAVE'),
          text: this.translate.instant('ADMIN.VIRTUAL_METERS.EDITOR.FORMULA_OVERLAP_CONFIRMATION')
        }
      ) :
      Promise.resolve()
      ;
    confirm
      .then(() => {
        this.loadingPipe(this.getUpdateRequest(this.formulaData, true))
          .subscribe(
            () => super.closeModal(),
            formula => {
              this.loadingPipe(this.addMeterNamesToFormula(formula))
                .subscribe(this.updateFormulaDataAfterRequest.bind(this));
            }
          );
      })
      .catch(Helpers.noop);
  }

  public cancelClicked(): void {
    super.dismissModal();
  }

  public fromDateChange(date: Date): void {
    this.formulaData[0].rootProperties.from = date;
    this.updateFormula(this.formulaData);
  }

  public toDateChange(date: Date): void {
    this.formulaData[0].rootProperties.to = date ?? null;
    this.updateFormula(this.formulaData);
  }

  private addMeters(addition: FormulaItemAdd): void {
    const addMetersModalRef = this.modalService.open(
      AddMetersModalComponent,
      { injector: this.injector, size: 'large' }
    );
    addMetersModalRef.componentInstance.initialEnegiaId = this.facility ? this.facility.enegiaId : null;
    addMetersModalRef.componentInstance.itemAddContext = addition.item;
    addMetersModalRef.componentInstance.additionType = addition.type;
    addMetersModalRef.result.then(this.addMetersFromModal.bind(this)).catch(Helpers.identity);
  }

  private addMetersFromModal(params: AddMetersParams): void {
    this.formula.addItems({ type: params.additionType, item: params.itemAddContext }, params.meters);
    this.updateFormula(this.formulaData);
  }

  private updateFormula(data: ReadonlyArray<TreeViewItem>): void {
    this.loadingPipe(this.getUpdateRequest(data))
      .pipe(switchMap(this.addMeterNamesToFormula.bind(this)))
      .subscribe(this.updateFormulaDataAfterRequest.bind(this));
  }

  private getUpdateRequest(formulaData: ReadonlyArray<TreeViewItem>, save = false): Observable<TreeViewItem[]> {
    return this.isNewFormula ?
      this.meteringClient.saveFormulaModel(!save, save, [...formulaData]) :
      this.meteringClient.updateFormulaModel(!save, save, [...formulaData]);
  }

  private updateFormulaDataAfterRequest(formula: TreeViewItem[]): void {
    this.zone.run(() => {
      this.formula = new Formula(formula);
    });
  }

  private getFormulaData(): void {
    this.loadingPipe(
      this.meteringClient.getFormulaModel(this.virtualMeterId)
        .pipe(
          map(formula => new Formula(formula, this.isNewFormula).formula),
          switchMap(result => this.addMeterNamesToFormula(result))
        )
    ).subscribe(result => this.updateFormulaDataAfterRequest(result));
  }

  private getMeterIdsFromFormula(formula: ReadonlyArray<TreeViewItem>): number[] {
    return formula.mapFilter(
      item => item.sourceMeterId,
      id => typeof id === 'number'
    ).unique();
  }

  private addMeterNamesToFormula(formula: TreeViewItem[]): Observable<TreeViewItem[]> {
    const meterIds = this.getMeterIdsFromFormula(formula);
    const meterRequest = meterIds.length > 0 ? this.meteringClient.getMetersWithProperties(meterIds) : of([]);
    return meterRequest.pipe(
      map((meters: MeterWithProperties[]) => this.formulaEditorService.getFormulaDataWithMeterNames(formula, meters))
    );
  }

  private openNewMetersTab(...meterIds: number[]): void {
    const params = {
      modalParams: {
        reportType: 'modal.metertree',
        reportParams: {
          facilityId: [this.facility.id],
          meterId: meterIds
        }
      }
    };

    this.stateLocationService.openStateInNewTab('facilities.grid', params);
  }

  private loadingPipe<T>(observable: Observable<T>): Observable<T> {
    this.loading = true;
    return observable.pipe(finalize(() => {
      this.loading = false;
      this.firstLoad = false;
    }));
  }

  private setModalHeaderText(): void {
    const translationKey = !this.isNewFormula ?
      'ADMIN.VIRTUAL_METERS.EDIT_FORMULA' :
      'ADMIN.VIRTUAL_METERS.CREATE_NEW_FORMULA';
    this.modalHeaderText = this.translate.instant(translationKey);
  }
}
