import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { ToasterService } from '../../../shared/services/toaster.service';
import { formGroupFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import {
  IProvisioningSearchCriteria,
  ProcessStatus,
  ProvisioningClient,
  ProvisioningDto,
  ProvisioningSearchCriteria,
} from '@enerkey/clients/provisioning';
import { UserService } from '../../../services/user-service';
import { Roles } from '../../admin/constants/roles';
import { ComboItem } from '../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { processStatusTranslationKeys } from '../pipes/process-status-text.pipe';

@Component({
  selector: 'provisioning',
  templateUrl: './provisioning.component.html',
  styleUrls: ['./provisioning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvisioningComponent implements OnDestroy {
  public readonly showTenantIdField: boolean = false;

  public readonly provisionings$: Observable<ProvisioningDto[]>;
  public readonly loading$: Observable<boolean>;

  public readonly provisioningSearchOptions: UntypedFormGroup;

  public readonly statusSelectOptions: ComboItem<ProcessStatus>[];

  private readonly _search$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    private readonly provisioningClient: ProvisioningClient,
    private readonly toaster: ToasterService,
    userService: UserService
  ) {
    this.loading$ = this._loading$.asObservable();

    this.statusSelectOptions = getNumericEnumValues(ProcessStatus).map(status => ({
      value: status,
      text: processStatusTranslationKeys[status]
    }));

    this.provisioningSearchOptions = formGroupFrom<IProvisioningSearchCriteria>({
      externalCustomerId: null,
      beginDate: null,
      endDate: null,
      tenantId: null,
      statuses: null
    });

    if (userService.hasRole(Roles.TENANT_MANAGER)) {
      this.showTenantIdField = true;
    }

    this.provisionings$ = this._search$.pipe(
      debounceTime(300),
      switchMap(() => this.provisioningClient.getProvisionings(
        new ProvisioningSearchCriteria(this.provisioningSearchOptions.value)
      ).pipe(indicate(this._loading$))),
      takeUntil(this._destroy$),
      catchError(() => {
        this.toaster.error('FAILED TO LOAD PROVISIONINGS');
        return of([]);
      })
    );

    this.loading$.subscribe(loading => {
      this.provisioningSearchOptions[loading ? 'disable' : 'enable']();
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._search$.complete();
    this._loading$.complete();
  }

  public search(): void {
    this._search$.next();
  }

  public clear(): void {
    this.provisioningSearchOptions.reset();
  }
}
