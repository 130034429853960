import { UntypedFormGroup } from '@angular/forms';
import { AxisLabelVisualArgs } from '@progress/kendo-angular-charts';
import {
  AddEvent,
  CancelEvent,
  CellClickEvent,
  CellCloseEvent,
  EditEvent,
  RemoveEvent,
  RowArgs,
  SaveEvent,
  SelectionEvent,
} from '@progress/kendo-angular-grid';
import { ItemLookup, TreeItem, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

/* eslint-disable @typescript-eslint/no-explicit-any */

//#region Grid

export type GridData<T> = T[] | { items: GridData<T> }[]

export function isGroupedData<T>(data: GridData<T>): data is ({ items: GridData<T> }[]) {
  return (data as any[]).every((x: any) => Array.isArray(x?.items));
}

/**
 * Flatten kendo grid grouped data.
 * @example const visibleRows = flattenGroupedData<Model>(dataQuery.process(rows, state))
 */
export function flattenGroupedData<T>(data: GridData<T>): T[] {
  if (!Array.hasItems(data)) {
    return [];
  } else if (isGroupedData(data)) {
    return flattenGroupedData(data.flatMap(x => x.items as any));
  } else {
    return data;
  }
}

/**
 * **Mutates** the parameter filter, removing all filter descriptors that match the predicate.
 */
export function removeGridFilters(
  filter: CompositeFilterDescriptor,
  predicate: (x: FilterDescriptor) => boolean
): void {
  if (!filter.filters) {
    return;
  }

  for (let index = filter.filters.length - 1; index >= 0; index--) {
    const inner = filter.filters[index];

    if ('filters' in inner) {
      removeGridFilters(inner, predicate);

      // If the filter group was cleared, remove it completely
      if (!Array.hasItems(inner.filters)) {
        filter.filters.removeAt(index);
      }
    } else {
      if (predicate(inner)) {
        filter.filters.removeAt(index);
      }
    }
  }
}

export interface AxisLabelVisualArgsOf<T> extends AxisLabelVisualArgs {
  dataItem?: T;
}

export interface AddEventOf<T> extends AddEvent {
  dataItem: T;
}

export interface CancelEventOf<T> extends CancelEvent {
  dataItem: T;
}

export interface EditEventOf<T> extends EditEvent {
  dataItem: T;
}

export interface RemoveEventOf<T> extends RemoveEvent {
  dataItem: T;
}

export interface SaveEventOf<T> extends SaveEvent {
  dataItem: T;
}

export interface SelectionEventOf<T> extends SelectionEvent {
  selectedRows: RowArgsOf<T>[];
  deselectedRows: RowArgsOf<T>[];
}

interface RowArgsOf<T> extends RowArgs {
  dataItem: T;
}

export interface CellCloseEventOf<T> extends CellCloseEvent {
  dataItem: T;
  formGroup: UntypedFormGroup;
}

export interface CellClickEventOf<T> extends CellClickEvent {
  dataItem: T;
}

//#endregion Grid

//#region TreeView

export interface TreeItemOf<T> extends TreeItem {
  dataItem: T;
}

export interface ItemLookupOf<T> extends ItemLookup {
  item: TreeItemOf<T>;
  children?: TreeItemOf<T>[];
  parent?: ItemLookupOf<T>;
}

export interface TreeItemLookupOf<T> extends TreeItemLookup {
  item: TreeItemOf<T>;
  parent?: ItemLookupOf<T>;
  children?: TreeItemLookupOf<T>[];
}

//#endregion TreeView
