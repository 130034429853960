import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

interface EkDropdownHeading {
  text: string;
  type: 'heading';
}

interface EkDropdownItem {
  text: string;
  click: () => void;
  isDisabled?: () => boolean;
  type?: 'item';
}

export type EkDropdownModel = EkDropdownItem | EkDropdownHeading;

/**
 * Projects the `items`-property to dropdown items and headers.
 *
 * @example
 * ```html
 * <ek-dropdown>
 *   <ek-dropdown-content [items]="dropdownItems"></ek-dropdown-content>
 * </ek-dropdown>
 * ```
 */
@Component({
  selector: 'ek-dropdown-content',
  templateUrl: './ek-dropdown-content.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EkDropdownContentComponent {
  @Input() public items: EkDropdownModel[] = [];

  public isHeading(model: EkDropdownModel): model is EkDropdownHeading {
    return model.type === 'heading';
  }
}
