<modal-view-header [headingText]="profile.name + ' (ID: ' + profile.id + ')'"></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="profileUpdateForm"
    (ngSubmit)="submit()"
    id="updateForm"
  >
    <div class="formInputs">
      <input
        formControlName="name"
        type="text"
        kendoTextBox
        *labelBefore="'ADMIN.MODIFY_PROFILE.NAME_CHANGE_LABEL' | translate"
      />
      <div class="displayHorizontally">
        <div>
          <service-level-select 
            class="serviceLevel"
            *labelBefore="'ADMIN.ADD_PROFILE_SERVICE_LEVEL_LABEL' | translate"
            formControlName="serviceLevel"
          ></service-level-select>
        </div>
        <div>
          <company-dropdown
            formControlName="companyId"
          ></company-dropdown>
        </div>
      </div>
      <servicetype-multi-select
        *labelBefore="'ADMIN.ADD_PROFILE.SERVICES' | translate"
        formControlName="services"
        class="services"
      >
</servicetype-multi-select>
    </div>
  </form>
  <span>{{'ADMIN.FACILITIES' | translate}}</span>
  <kendo-grid
    [kendoGridBinding]="facilities$ | async"
    [selectable]="gridSelectableSettings"
    [navigable]="true"
    [pageable]="true"
    [pageSize]="50"
    [selectedKeys]="selectedFacilities"
    [kendoGridSelectBy]="selectKey"
    [rowClass]="rowCallback"
    [loading]="loading$ | async"
  >
    <kendo-grid-checkbox-column [width]="24">
      <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
    </kendo-grid-checkbox-column>
    <kendo-grid-column
      field="id"
      [title]="'ADMIN.ID' | translate"
      [width]="60"
    ></kendo-grid-column>
    <kendo-grid-column
      field="displayName"
      [title]="'ADMIN.NAME' | translate"
    ></kendo-grid-column>
    <kendo-grid-column
      field="enegiaId"
      [title]="'ADMIN.ENEGIAID' | translate"
      [width]="100"
    ></kendo-grid-column>
    <kendo-grid-column
      field="customerSpecifiedIdentifier"
      [title]="'ADMIN.REALESTATE_ID' | translate"
      [width]="100"
    ></kendo-grid-column>
    <kendo-grid-column
      field="customerSetDescription"
      [title]="'ADMIN.CUSTOMER_SET_DESCRIPTION' | translate"
    ></kendo-grid-column>
    <kendo-grid-column
      field="enegiaDescription"
      [title]="'ADMIN.ENEGIA_DESCRIPTION' | translate"
      [width]="400"
    ></kendo-grid-column>
    <kendo-grid-column
      [title]="'DROPDOWN.ACTIONS' | translate"
      [width]="80"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
      >
        <button
          type="button"
          class="actionButton"
          (click)="updateStatus(dataItem.id)"
        >
          <strong
            *ngIf="checkIfDeleted(dataItem.id); else deleteAction"
            class="restore-color"
          >
            {{ 'ADMIN.RESTORE_TO_PROFILE' | translate | uppercase }}
          </strong>
          <ng-template #deleteAction>
            <strong class="remove-color">
              {{ 'ADMIN.REMOVE' | translate | uppercase}}
            </strong>
          </ng-template>
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div>
    <button
      class="button selectionOperation"
      type="button"
      [disabled]="!selectedFacilities.length"
      (click)="massRestore()"
    >
      {{'ADMIN.RESTORE_SELECTED_TO_PROFILE' | translate}}
    </button>
    <button
      class="button selectionOperation button--negative"
      type="button"
      [disabled]="!selectedFacilities.length"
      (click)="massRemove()"
    >
      {{'ADMIN.REMOVE_SELECTED_FROM_PROFILE' | translate}}
    </button>
  </div>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="updateForm"
    >
      {{ 'ADMIN.SAVE' | translate }}
    </button>
    <button
      *ngIf="profile"
      class="button button--negative"
      (click)="deleteProfile()"
    >
      {{ 'DELETE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
