import { IConsumptionClient } from './consumption-client';

export class ConsumptionClientMock implements IConsumptionClient {
  public delete15MinConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public deleteConsumptionsAdapter(): never {
    throw new Error('Method not implemented.');
  }

  public deleteDistributions(): never {
    throw new Error('Method not implemented.');
  }

  public deleteHourlyConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public deleteNonLockedModeledConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public deleteNormalizations(): never {
    throw new Error('Method not implemented.');
  }

  public get15MinConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public getConsumptionValues(): never {
    throw new Error('Method not implemented.');
  }

  public getCumulativeValues(): never {
    throw new Error('Method not implemented.');
  }

  public getFaultsForDefectIssues(): never {
    throw new Error('Method not implemented.');
  }

  public getFileNamesByMeterId(): never {
    throw new Error('Method not implemented.');
  }

  public getHourlyConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public getLatestConsumption(): never {
    throw new Error('Method not implemented.');
  }

  public getLatestConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public getMeterIdsWithSpecificValueTypes(): never {
    throw new Error('Method not implemented.');
  }

  public getNormalizedValues(): never {
    throw new Error('Method not implemented.');
  }

  public lock(): never {
    throw new Error('Method not implemented.');
  }

  public refreshAggregates(): never {
    throw new Error('Method not implemented.');
  }

  public replayByFiles(): never {
    throw new Error('Method not implemented.');
  }

  public replayByMeterId(): never {
    throw new Error('Method not implemented.');
  }

  public saveConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public saveDistributions(): never {
    throw new Error('Method not implemented.');
  }

  public saveNormalizations(): never {
    throw new Error('Method not implemented.');
  }

  public searchConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public searchDistributions(): never {
    throw new Error('Method not implemented.');
  }

  public searchNormalizations(): never {
    throw new Error('Method not implemented.');
  }

  public unlock(): never {
    throw new Error('Method not implemented.');
  }

  public upsert15MinConsumptions(): never {
    throw new Error('Method not implemented.');
  }

  public upsertFaultsForDefectIssue(): never {
    throw new Error('Method not implemented.');
  }

  public upsertHourlyConsumptions(): never {
    throw new Error('Method not implemented.');
  }

}
