import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReadOnlyNews } from '@enerkey/clients/news';

import { NewsService } from '../services/news.service';

@Component({
  selector: 'topbar-news',
  templateUrl: './topbar-news.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarNewsComponent {
  public readonly news$: Observable<ReadOnlyNews[]>;
  public readonly unreadCount$: Observable<number>;

  public constructor(private readonly newsService: NewsService) {
    this.news$ = this.newsService.news$;
    this.unreadCount$ = this.news$.pipe(
      map(news => news.count(entry => !entry.isRead))
    );
  }

  public openNewsModal(entry: ReadOnlyNews): void {
    this.newsService.openNewsModal(entry);
  }
}
