import { StateService } from '@uirouter/core';
import { indexBy, isArray, isNumber } from 'lodash';
import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MeterItem } from '@enerkey/clients/energy-reporting';
import { ActionOutViewModel, ActionViewModel } from '@enerkey/clients/attachments';

import { ACTION_TYPE_IDS, COMMENT_TYPE_IDS } from '../constants/em-shared.constant';
import { QuantityService } from '../../../shared/services/quantity.service';
import { ExtendedFacilityInformation } from '../../../shared/interfaces/extended-facility-information';
import { customCategories } from '../constants/em-custom-categories';
import { AugmentedAction } from '../interfaces/em-augmented-action';
import { EmSearchTimeFrame, START_DATES } from '../constants/em-actions-search-params-constant';
import { Utils } from '../../../services/utils';
import { LegacyFacilityService } from '../../reportingobjects/models/facilities';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface EmRelatedValuesResponse {
  relatedValues: EmRelatedValuesById;
}

interface EmRelatedValuesById {
  facilitiesById: Record<number, ExtendedFacilityInformation>;
  metersById: Record<number, MeterItem>;
}

export class EmService {

  public static readonly $inject = [
    '$state',
    'utils',
    'actions',
    'facilities',
    'quantities',
    'meters',
    'filterService'
  ];

  public constructor(
    private $state: StateService,
    private utils: Utils,
    private actions: any,
    private facilities: LegacyFacilityService,
    private quantities: QuantityService,
    private meters: any,
    private filterService: any
  ) { }

  public fetchRelatedValues(): Observable<EmRelatedValuesResponse> {
    return forkJoin([
      this.facilities.getFacilities(),
      this.meters.getMeters() as Promise<MeterItem[]>
    ]).pipe(
      map(([resultFacilities, resultMeters]) => {
        const filteredFacilityIds: number[] = this.filterService.getFilteredFacilityIds();
        const hasFilteredIds = Array.hasItems(filteredFacilityIds);

        const facilities = hasFilteredIds
          ? resultFacilities.filter(facility => filteredFacilityIds.includes(facility.FacilityId))
          : resultFacilities;
        const meters = hasFilteredIds
          ? resultMeters.filter(meter => filteredFacilityIds.includes(meter.ReportingObjectId))
          : resultMeters;
        return {
          relatedValues: {
            facilitiesById: indexBy(facilities, 'FacilityId'),
            metersById: indexBy(meters, 'Id'),
          }
        };
      })
    );
  }

  /**
   * Returns a new action object which is an augmented version of the action passed in as parameter.
   *
   * The augmentations:
   *  - fix datatype issues (string ISO dates to date objects etc)
   *  - augment the action with facility data
   *  - add localized friendly name properties for enumerated values
   *  - add localized friendly name for quantities and meters
   */
  public getAugmentedRow(
    action: ActionViewModel | ActionOutViewModel,
    relatedValues: EmRelatedValuesById,
    columnGroupProperties: (keyof ExtendedFacilityInformation)[]
  ): AugmentedAction {
    const augmentedAction: AugmentedAction = this.actions.coerce(action);

    this.actions.updateFriendlyNamesForEnums(augmentedAction);
    augmentedAction.categoryName = this.getCategoryName(augmentedAction);

    augmentedAction.meterName = this.actions.getMeterName(augmentedAction, relatedValues.metersById);
    augmentedAction.quantityName = augmentedAction.quantityIds
      .map(quantityId => this.quantities.getQuantityLocalizedName(quantityId))
      .join(', ');

    const facility = relatedValues.facilitiesById[augmentedAction.reportingObjectId] || {};
    columnGroupProperties.forEach(columnGroupProperty => {
      (facility as any)[columnGroupProperty] = facility[columnGroupProperty] || {};
    });

    return { ...augmentedAction, reportingObject: facility };
  }

  public getPayload(
    startDateParam: EmSearchTimeFrame,
    facilityId: number,
    stateName: string,
    dateFilterField?: string
  ): any {
    const payload: Record<string, any> = {};

    payload.actionTypes = stateName === 'energy-management.actions' ? ACTION_TYPE_IDS : COMMENT_TYPE_IDS;

    const fromDate = this.getStartDate(startDateParam);
    if (fromDate) {
      payload[dateFilterField || 'updatedAfter'] = fromDate;
    }

    const filteredFacilityIds: number[] = this.filterService.getFilteredFacilityIds();
    if (isNumber(facilityId)) { // Facility selected from dropdown
      payload.reportingObjectId = [facilityId];
    } else if (isArray(filteredFacilityIds)) { // All facilities selected but app-wide filter is set
      payload.reportingObjectId = filteredFacilityIds;
    }

    if (this.$state.params.meterId) {
      payload.MeterId = [this.$state.params.meterId];
    }

    return payload;
  }

  // Get actual start date by start date parameters
  public getStartDate(startDateParam: EmSearchTimeFrame): Date {
    if (startDateParam === EmSearchTimeFrame.All) {
      return null;
    } else {
      const param = START_DATES[startDateParam];
      return moment()
        .startOf(param.RESOLUTION)
        .subtract(param.ADD, param.RESOLUTION)
        .toDate();
    }
  }

  private getCategoryName(action: AugmentedAction): string {
    if (!Number.isFinite(action.category)) {
      return '';
    }
    const foundCategory = customCategories.find(({ id }) => id === action.category);
    return foundCategory ? this.utils.localizedString(foundCategory.key) : String(action.category);
  }
}
