import { IAngularStatic } from 'angular';
import { mrcInputHistoryComponent } from './mrc-input-history/';
import { mrcMassImport } from './mrc-mass-import';
import MrcConstantsModule from '../constants';
import MrcServicesModule from '../services';
import EnerKeyServiceModule from '../../../services';
import { MrcMassImportService } from './mrc-mass-import/mrc-mass-import-service';
import { mrcMassImportConstants } from './mrc-mass-import/mrc-mass-import-constant';

declare const angular: IAngularStatic;

export default angular.module('Mrc.Components', [
  MrcConstantsModule.name,
  MrcServicesModule.name,
  EnerKeyServiceModule.name
])
  .component('mrcInputHistory', mrcInputHistoryComponent)
  .component('mrcMassImport', mrcMassImport)
  .factory('MrcMassImportService', MrcMassImportService)
  .constant('MrcMassImportConstants', mrcMassImportConstants)
;
