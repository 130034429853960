import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { formGroupFrom } from '@enerkey/ts-utils';

import { CreateProfile, FacilityClient, ICreateProfile } from '@enerkey/clients/facility';
import {
  UpdateProfileDto,
  UserManagementClient,
} from '@enerkey/clients/user-management';

type EditableProfileFields = Pick<ICreateProfile, 'name' | 'serviceLevel'> & { services: string[] }

@Component({
  templateUrl: './profile-add-modal.component.html',
  styleUrls: ['./profile-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileAddModalComponent extends ModalBase<void> implements OnInit {

  public profileAddForm: UntypedFormGroup;

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly userManagementClient: UserManagementClient,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
  }

  public ngOnInit(): void {
    this.profileAddForm = formGroupFrom<EditableProfileFields>({
      name: new UntypedFormControl(null, Validators.required),
      services: [],
      serviceLevel: new UntypedFormControl(0, Validators.required),
    });
  }

  public submit(): void {
    const data = this.profileAddForm.value;
    this.facilityClient.createProfile(
      new CreateProfile({
        name: data.name,
        serviceLevel: data.serviceLevel
      })
    ).subscribe(profile => {
      this.userManagementClient.updateProfile(
        profile.id,
        new UpdateProfileDto({
          name: data.name,
          serviceLevel: data.serviceLevel,
          serviceIds: data.services
        })
      ).subscribe(() => {
        this.closeModal();
      });
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
