import { momentToString } from '../../../../shared/date.functions';
import { Publicity } from '@enerkey/clients/manual-qa';

const $inject = ['utils', 'kendo'];

class ManualQaSearchListService {
  static getDataSourceOptions(data) {
    return {
      data: data,
      pageSize: 20,
      serverPaging: false,
      serverSorting: false
    };
  }

  constructor(utils, kendo) {
    this.utils = utils;
    this.kendo = kendo;
  }

  getGridOptions() {
    const self = this;

    return {
      dataSource: ManualQaSearchListService.getDataSourceOptions([]),
      sortable: true,
      pageable: true,
      reorderable: false,
      filterable: true,
      groupable: true,
      resizable: true,
      columns: [
        {
          field: 'id',
          title: self.utils.localizedString('MQA.SEARCH_LIST.ID'),
          width: 50,
          editable: false,
          groupable: false
        },
        {
          field: 'name',
          title: self.utils.localizedString('MQA.SEARCH_LIST.NAME'),
          width: 450,
          template: '<a href="" ng-click="vm.handleSearchListChange(this.dataItem)">#: name # </a>',
          editable: true,
          groupable: false
        },
        {
          field: 'description',
          title: self.utils.localizedString('MQA.SEARCH_LIST.DESCRIPTION'),
          width: 450,
          editable: true,
          groupable: false
        },
        {
          field: 'publicityStatus',
          title: self.utils.localizedString('MQA.SEARCH_LIST.PUBLICITY_STATUS'),
          template: searchList => {
            switch (searchList.publicityStatus) {
              case Publicity.OWN:
                return self.utils.localizedString('MQA.SEARCH_LIST.EDIT.SEARCH_LIST_OWN');
              case Publicity.PUBLIC:
                return self.utils.localizedString('MQA.SEARCH_LIST.EDIT.SEARCH_LIST_PUBLIC');
              default:
                return self.utils.localizedString('MQA.SEARCH_LIST.EDIT.SEARCH_LIST_UNKNOWN');
            }
          },
          width: 100,
          editable: true,
          groupable: true
        },
        {
          field: 'lastModified',
          title: self.utils.localizedString('MQA.SEARCH_LIST.LAST_MODIFIED'),
          template: searchList => momentToString(searchList.lastModified),
          width: 150,
          editable: false,
          groupable: false
        },
        {
          field: 'lastModifiedBy',
          title: self.utils.localizedString('MQA.SEARCH_LIST.LAST_MODIFIED_BY'),
          width: 200,
          editable: false,
          groupable: false
        },
        {
          command: [
            {
              id: 'edit',
              title: '',
              width: 50,
              template: `
<button
  kendo-button
  k-options="{ themeColor: 'primary', fillMode: 'flat', iconClass: 'fa-1x fas fa-pencil-alt', size: 'small' }"
  ng-click="vm.openSaveSearchListModal(this.dataItem)"
></button>
`
            },
            {
              id: 'delete',
              title: '',
              width: 50,
              template: `
<button
  kendo-button
  style="color: var(--enerkey-remove-action)"
  k-options="{ fillMode: 'flat', iconClass: 'fa-1x fa fa-trash-alt', size: 'small' }"
  ng-click="vm.openDeleteSearchListModal(this.dataItem)"
></button>
`
            }
          ]
        }
      ]
    };
  }

  /**
   * Returns new dataSource object with given array as data
   *
   * @param {Array} data
   *
   * @returns {Object} dataSource object
   */
  getDataSource(data) {
    return new this.kendo.data.DataSource(ManualQaSearchListService.getDataSourceOptions(data));
  }
}

ManualQaSearchListService.$inject = $inject;

export default ManualQaSearchListService;
