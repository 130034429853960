import template from 'raw-loader!./modal-content-item.html';

const ModalContentItemComponent = {
  template: template,
  transclude: true,
  require: {
    parent: '^modalContentItemContainer'
  }
};

export default ModalContentItemComponent;
