import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { ServiceLevel } from '@enerkey/clients/facility';

import { EditableWidgetSettings, WidgetEditResult } from '../../shared/editable-widget-settings';
import { WidgetType } from '../../shared/widget-type';
import { ServiceLevelService } from '../../../../shared/services/service-level.service';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'widget-settings-general',
  templateUrl: './widget-settings-general.component.html',
  styleUrls: ['./widget-settings-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'tiny' })
export class WidgetSettingsGeneralComponent<TOptions = never>
  extends ModalBase<WidgetEditResult<TOptions>>
  implements OnInit, EditableWidgetSettings<TOptions> {

  public readonly WidgetTypeEnum: typeof WidgetType = WidgetType;
  public readonly canDeleteWidget: boolean = false;

  @Input() public title: string;
  @Input() public defaultTitle: string;
  @Input() public isNew: boolean;

  public widgetType: WidgetType = null;
  public dataModelOptions: TOptions = null;

  public titleControl: UntypedFormControl;
  public settingsControl: UntypedFormControl;

  private get formValue(): TOptions {
    return this.settingsControl.value;
  }

  public constructor(
    modalRef: NgfActiveModal,
    serviceLevelService: ServiceLevelService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService
  ) {
    super(modalRef);
    this.canDeleteWidget = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);
  }

  public ngOnInit(): void {
    if (!this.widgetType) {
      throw Error('Undefined widget type');
    }

    this.titleControl = new UntypedFormControl(this.title);
    this.settingsControl = new UntypedFormControl(this.dataModelOptions);
  }

  public saveWidget(): void {
    this.closeModal({
      title: this.titleControl.value,
      dataModelOptions: this.formValue,
    });
  }

  public deleteWidget(): void {
    this.dialogService.getConfirmationModal({
      isDelete: true,
      translate: false,
      title: this.translateService.instant('DASHBOARD.REMOVE_WIDGET'),
      text: this.translateService.instant(
        'DASHBOARD.REMOVE_WIDGET_TEXT',
        { title: this.title || this.translateService.instant(this.defaultTitle) }
      ),
    }).subscribe({
      next: () => {
        this.closeModal({
          deleteWidget: true,
          title: this.titleControl.value,
          dataModelOptions: this.formValue,
        });
      },
      error: () => { },
    });
  }

  public cancel(): void {
    this.dismissModal();
  }
}
