import { forkJoin, Observable, of } from 'rxjs';

import { map } from 'rxjs/operators';

import {
  ConsumptionsRequest,
  ConsumptionsRequestQuantity,
  QuantityItem
} from '@enerkey/clients/energy-reporting';
import { Quantities } from '@enerkey/clients/metering';

import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { getValueTypeOptions } from '../../energy-reporting/shared/value-type-options';
import { WidgetChangeOption } from '../../energy-reporting/shared/widget-constants';
import {
  ChangeWidgetData,
  ChangeWidgetOptions,
  ChangeWidgetResponse,
  ChangeWidgetValueKey
} from '../components/change-widget-base';

/* eslint-disable @typescript-eslint/no-explicit-any */

interface RequestOptions {
  normalization: boolean;
  valueKey: ChangeWidgetValueKey;
  show: boolean;
}

export abstract class ChangeWidgetServiceBase<Options extends ChangeWidgetOptions, WidgetRow, ResponseType> {
  protected abstract requestData(params: ConsumptionsRequest): Observable<ResponseType>;

  protected abstract transformData(
    consumptions: ResponseType,
    valueKey: ChangeWidgetValueKey,
    dataModelOptions: Options,
    start: TimeFrameResult
  ): ChangeWidgetData<WidgetRow>

  public getConsumptions(
    quantity: QuantityItem,
    dataModelOptions: Options,
    start: TimeFrameResult,
    facilityIds: number[],
    thresholdForIncomplete: number
  ): Observable<ChangeWidgetResponse<WidgetRow>> {
    const valueProperties = getValueTypeOptions(dataModelOptions.valueOption);
    const requestOptions: Record<keyof ChangeWidgetResponse<WidgetRow>, RequestOptions> = {
      measured: {
        normalization: false,
        valueKey: 'Value',
        show: valueProperties.measured || !quantity.Normalization
      },
      normalized: {
        normalization: true,
        valueKey: 'NormalisationValue',
        show: valueProperties.normalized && quantity.Normalization
      }
    } as const;

    return forkJoin({
      measured: this.getRequestWithOptions(
        requestOptions.measured,
        dataModelOptions,
        start,
        facilityIds,
        thresholdForIncomplete
      ),
      normalized: this.getRequestWithOptions(
        requestOptions.normalized,
        dataModelOptions,
        start,
        facilityIds,
        thresholdForIncomplete
      )
    });
  }

  protected getRequestWithOptions(
    requestOptions: RequestOptions,
    dataModelOptions: Options,
    start: TimeFrameResult,
    facilityIds: number[],
    thresholdForIncomplete: number
  ): Observable<ChangeWidgetData<WidgetRow>> {
    if (!requestOptions.show) {
      return of(null);
    }
    const valueKey = requestOptions.valueKey;
    const params = {
      ...this.getCommonRequestParams(
        dataModelOptions,
        start,
        requestOptions.normalization,
        requestOptions.valueKey,
        facilityIds,
        thresholdForIncomplete
      ),
      ...this.getExtraParams(dataModelOptions)
    };

    return this.requestData(params).pipe(
      map(values => this.transformData(
        values, valueKey, dataModelOptions, start
      ))
    );
  }

  protected getExtraParams(_dataModelOptions: Options): Partial<ConsumptionsRequest> {
    return {};
  }

  protected getQuantityId(options: Options): Quantities {
    return options.quantityId ?? options.selectedQuantity.ID;
  }

  private getCommonRequestParams(
    dataModelOptions: Options,
    start: TimeFrameResult,
    normalization: boolean,
    valueKey: ChangeWidgetValueKey,
    facilityIds: number[],
    thresholdForIncomplete: number
  ): ConsumptionsRequest {
    const isRelationalValue = !!dataModelOptions.variableId;

    const quantities: ConsumptionsRequestQuantity[] = [{
      Id: this.getQuantityId(dataModelOptions),
      Comparables: dataModelOptions.comparableOption,
      Normalisation: normalization,
      RelationalUnitIds: isRelationalValue ? [dataModelOptions.variableId] : []
    }];

    return {
      FacilityId: facilityIds ?? null,
      Start: start.Start as any,
      Quantities: quantities,
      Unit: dataModelOptions.unitKey as any,
      SortingOptions: {
        CalculationMethod: dataModelOptions.changeOption === WidgetChangeOption.Absolute
          ? 'Change'
          : 'ChangePercent' as any,
        LimitAscending: dataModelOptions.biggestGainers,
        LimitDescending: dataModelOptions.biggestFallers,
        Key: isRelationalValue ? 'RelationalValues' : valueKey as any
      },
      ThresholdForIncomplete: thresholdForIncomplete,
      TimeFrame: start.TimeFrame
    };
  }
}
