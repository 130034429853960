import { combineLatest, EMPTY, endWith, from, interval, map, Observable, scheduled, SchedulerLike, startWith } from 'rxjs';
import { debounce } from 'rxjs/operators';

/**
 * Returns a completed Observable with no return value. Required because `of<void>()` never completes,
 * and `empty()` completes without emitting a value.
 *
 * @param scheduler (optional) Scheduler passed to `from()`
 * @example
 * void$.pipe(catchError(err => {
 *   console.log(err);
 *   return ofVoid();
 * }).subscribe()
 */
export function ofVoid(scheduler?: SchedulerLike): Observable<void> {
  return scheduler
    ? scheduled(Promise.resolve<void>(undefined), scheduler)
    : from(Promise.resolve<void>(undefined));
}

/**
 * Combines parameter observables to a stream that emits true if any of the observables' last value was true.
 *
 * Main use case is combining multiple loading indicators.
 *
 * @example this.loading$ = anyOf(this._loadingUsers$, this._loadingServices$);
 */
export function anyOf(...observables: Observable<boolean>[]): Observable<boolean> {
  if (observables.length === 0) {
    return EMPTY;
  }

  // default to false on start, and to false on completion (e.g. one completes before the other)
  return combineLatest(observables.map(o => o.pipe(startWith(false), endWith(false)))).pipe(
    map(values => values.some(x => !!x))
  );
}

/**
 * Emits a value after a given time has passed since the last emission.
 *
 * Workaround for `debounceTime` in rxjs v7 not emitting in unit tests
 * see rxjs issue: https://github.com/ReactiveX/rxjs/issues/6382
 * see angular issue: https://github.com/angular/angular/issues/44351
 * @param time
 * @param scheduler
 */
export function debounceTime<T>(time: number, scheduler?: SchedulerLike): (source: Observable<T>) => Observable<T> {
  return source => source.pipe(debounce(() => interval(time, scheduler)));
}
