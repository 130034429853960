import ManualQaInspectButtonComponent from './manual-qa-inspect-button.component';
import ManualQaInspectModalModule     from '../manual-qa-inspect-modal';

const dependencies = [ManualQaInspectModalModule.name];

const ManualQaInspectButtonModule = angular
  .module('app.modules.manual-qa.components.manual-qa-inspect-button', dependencies)
  .component('manualQaInspectButton', ManualQaInspectButtonComponent)
;

export default ManualQaInspectButtonModule;
