import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ProvisioningRuntimeStatus } from '@enerkey/clients/provisioning';

@Component({
  selector: 'provisioning-status',
  templateUrl: './provisioning-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvisioningStatusComponent {
  @Input() public status: ProvisioningRuntimeStatus;
}
