import { ServiceLevel } from '@enerkey/clients/facility';
import { assertUnreachable } from '@enerkey/ts-utils';

export class ServiceLevelNumeric {
  public readonly serviceLevel: number;

  public constructor(serviceLevel: ServiceLevel) {
    this.serviceLevel = this.getNumericServiceLevel(serviceLevel);
  }

  public valueOf(): number {
    return this.serviceLevel;
  }

  private getNumericServiceLevel(serviceLevel: ServiceLevel | keyof typeof ServiceLevel): number {
    // TODO: temporary fix
    switch (serviceLevel) {
      case ServiceLevel.Small:
      case 'Small':
        return 1;
      case ServiceLevel.Medium:
      case 'Medium':
        return 2;
      case ServiceLevel.Large:
      case 'Large':
        return 3;
      /* istanbul ignore next */
      default:
        assertUnreachable(serviceLevel);
    }
  }
}
