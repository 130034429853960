<div class="content-header">
  <ul class="tabs">
    <li
      [uiSrefActive]="'tabs__item--active'"
      [uiSref]="tab.name"
      *ngFor="let tab of tabs$ | async"
      class="tabs__item"
    >
      <a
        class="tabs__item-link"
      >{{ tab.data.translationKey | translate }}</a>
    </li>
  </ul>
  <div
    *ngIf="topRightContent"
    class="content-right"
  >
    <ng-container *ngTemplateOutlet="topRightContent"></ng-container>
  </div>
</div>
<div ui-view="content"></div>
