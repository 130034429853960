gridGroupHandler.$inject = ['$parse', '$rootScope', '$', '_', '$transitions'];

function gridGroupHandler($parse, $rootScope, $, _, $transitions) {

  var groups = [];

  return {
    restrict: 'A',
    scope: false,
    link: function (scope, element, attrs) {
      var getter = $parse(attrs.gridGroupHandler);
      var setter = getter.assign;
      var $element = $(element);

      var gridCreated = false;

      // get group by columns as array from grid
      var groupByColumns = function(grid) {
        var result = [];
        if (!grid) {
          grid = $parse(attrs.kendoGrid)(scope);
        }
        if (grid) {
          result = _.chain(grid.dataSource._group)
            .map(function(item) {
              var column = _.findDeep(grid.columns, { field: item.field });
              return column ? column.title : undefined;
            })
            .compact()
            .value()
          ;
        }
        return result;
      };

      // get group by columns for item
      var groupByColumnsForItem = function(text, groupBy) {
        var result = [];
        var splitted = text.split(':');
        if (splitted.length >= 2) {
          var by = splitted[0];
          var value = splitted[1];
          var index = groupBy.indexOf(by);
          if (index !== -1) {
            result = groupBy.slice(0, index + 1);
          }

        }
        return result;
      }

      // listen expand/collapse
      var listenGroupClicks = function() {
        _.each(groups, function(group) {
          $(group.element).on('click', function() {
            setTimeout(function() {
              setCollapsed(group);
            }, 100);
          });
        })
      };

      // create groups
      var createGroups = function() {
        var result = [];
        var grid = $parse(attrs.kendoGrid)(scope);
        if (grid && grid.element) {
          var groupBy = groupByColumns(grid);
          var groupByValues = [];
          grid.element.find('.k-reset a').each(function(index) {
            var parent = $(this).parent();
            if (parent && parent[0]) {
              var text = parent[0].textContent;
              var groupByCols = groupByColumnsForItem(text, groupBy);
              groupByValues[groupByCols.length - 1] = text;
              var item = { element: this, groupBy: _.object(groupByCols, groupByValues.slice(0, groupByCols.length)) };
              setCollapsed(item);
              result.push(item);
            }
          });
        }
        return result;
      }

      // create groups by storing them and listen for collapse/expand
      var createAndListenGroups = function() {
        groups = createGroups();
        listenGroupClicks();
      };

      // set collapsed groups
      var setCollapsed = function(group) {
        if ($(group.element).hasClass('k-i-collapse')) {
          group.collapsed = false;
        } else if ($(group.element).hasClass('k-i-expand')) {
          group.collapsed = true;
        }
      };

      // find expand/collapse buttons for collapsed groups and trigger click event
      var doCollapseGroups = function() {
        var newGroups = createGroups();
        var collapsedGroups = _.filter(groups, { collapsed: true });
        if (collapsedGroups.length) {
          _.each(newGroups, function(group) {
            var isCollapsed = _.find(collapsedGroups, function(collapsedGroup) {
              return _.isEqual(collapsedGroup.groupBy, group.groupBy);
            });
            if (isCollapsed) {
              $(group.element).trigger('click');
            }
          });
        }
      };

      // do a wrapper for original dataBound handler
      var originalDataBound = getter(scope);
      setter(scope, function() {
        doCollapseGroups();
        if (originalDataBound) {
          originalDataBound.apply(this, arguments);
        }
        if (gridCreated) {
          createAndListenGroups();
        }
      });

      // listen for grid create
      scope.$on('kendoWidgetCreated', function(event, widget) {
        var grid = $parse(attrs.kendoGrid)(scope);
        if (widget === grid) {
          doCollapseGroups();
          createAndListenGroups();
          gridCreated = true;
          $(grid.element).on('remove', function () {
            gridCreated = false;
          });
        }
      });

      var stateChangeSuccessUnbind = $transitions.onSuccess({}, function() {
        groups = [];
      });

      scope.$on('$destroy', function() {
        stateChangeSuccessUnbind();
      });
    }
  };

}

export default gridGroupHandler;
