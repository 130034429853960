import { IProvisioningClient } from './provisioning-client';

export class ProvisioningClientMock implements IProvisioningClient {
  public upsertCustomers(): never {
    throw new Error('Method not implemented.');
  }

  public getProvisionings(): never {
    throw new Error('Method not implemented.');
  }

  public getCustomerImports(): never {
    throw new Error('Method not implemented.');
  }

  public updateProvisioningStatus(): never {
    throw new Error('Method not implemented.');
  }

  public synchronizeCustomers(): never {
    throw new Error('Method not implemented.');
  }

  public startProvisioning(): never {
    throw new Error('Method not implemented.');
  }

  public getProvisioningStatus(): never {
    throw new Error('Method not implemented.');
  }

  public validateProvisioningData(): never {
    throw new Error('Method not implemented.');
  }

}
