import { Quantities } from '@enerkey/clients/metering';
import { percentageChange } from '@enerkey/ts-utils';

import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { ReportingSeriesByFacility } from '../../reporting/shared/reporting-series-collection';
import { EmissionsFacilityWiseWidgetOptions } from '../services/emissions-facility-wise-widget.service';

export interface EmissionsAggregateValue {
  quantityId: Quantities;
  value: number;
  color: string;
  year?: number | string;
}

export interface EmissionAggregates {
  totalEmissions: number;
  consumptionChange: number;
  emissionChange: number;
  chartData: EmissionsAggregateValue[];
}

export interface EmissionsWidgetData {
  readonly aggregateData: EmissionAggregates;
  readonly chartData: EmissionsAggregateValue[][];
  readonly hasData: boolean;
}

export class FacilityWiseEmissions {
  public static getWidgetData(
    reportingData: ReportingSeriesByFacility,
    quantityColors: Map<Quantities, string[]>,
    start: TimeFrameResult,
    options: EmissionsFacilityWiseWidgetOptions
  ): EmissionsWidgetData {
    return new FacilityWiseEmissions(
      reportingData,
      quantityColors,
      start,
      options
    ).getWidgetData();
  }

  private readonly amountOfPeriods: number;

  private constructor(
    private readonly reportingData: ReportingSeriesByFacility,
    private readonly quantityColors: Map<Quantities, string[]>,
    private readonly start: TimeFrameResult,
    private readonly options: EmissionsFacilityWiseWidgetOptions
  ) {
    this.amountOfPeriods = this.start.Start.length;
  }
  private getWidgetData(): EmissionsWidgetData {
    const aggregateData = this.getPieChartData();

    return {
      aggregateData,
      chartData: this.getChartData(),
      hasData: aggregateData.chartData.some(year => year.value)
    };
  }

  private getChartData(): EmissionsAggregateValue[][] {
    const chartData = this.getEmissions(true);
    return Object.values(chartData.reduce((emission:
    { [year: string]: EmissionsAggregateValue[] }, data) => {
      (emission[data.year] = emission[data.year] || []).push(data);
      return emission;
    }, {}));
  }

  private getPieChartData(): EmissionAggregates {
    const inspectionPeriodKey = this.getStartIndexKey(this.start, this.amountOfPeriods - 1);
    const comparisonPeriodKey = this.getStartIndexKey(this.start, this.amountOfPeriods - 2);

    const emissionValue = this.getPeriodEmissionValue(inspectionPeriodKey);
    const comparasionEmissionValue = this.getPeriodEmissionValue(comparisonPeriodKey);
    const consumptionValue = this.getPeriodConsumptionValue(inspectionPeriodKey);
    const comparasionConsumptionValue = this.getPeriodConsumptionValue(comparisonPeriodKey);

    const totalEmission = this.calcuateSum(emissionValue);
    const comparisonEmission = this.calcuateSum(comparasionEmissionValue);
    const totalConsumption = this.calcuateSum(consumptionValue);
    const comparisonConsumption = this.calcuateSum(comparasionConsumptionValue);

    return {
      chartData: emissionValue,
      totalEmissions: totalEmission,
      emissionChange: percentageChange(totalEmission, comparisonEmission),
      consumptionChange: percentageChange(totalConsumption, comparisonConsumption)
    };
  }

  private getPeriodEmissionValue(periodKey: string): EmissionsAggregateValue[] {

    return this.getEmissions(true)
      .filter(periodEmission => periodEmission.year === periodKey && periodEmission.value !== null)
      .map(data => ({
        value: data.value,
        quantityId: data.quantityId,
        color: data.color
      }));
  }

  private getEmissions(isEmission: boolean): EmissionsAggregateValue[] {

    return this.reportingData[this.options.facilityId].flatMap(reporting =>
      reporting.series
        .filter(series =>
          series.chartItemOptions.hasOwnProperty('derivedId') === isEmission &&
          series.values.some(quantity => quantity.value !== null))
        .flatMap(series =>
          series.values.map(({ value, timestamp }, index: number) =>
            ({
              value,
              quantityId: reporting.quantityId,
              year: timestamp.getFullYear().toString(),
              color: isEmission ?
                this.quantityColors.get(reporting.quantityId)[index] :
                this.quantityColors.get(reporting.quantityId)[this.amountOfPeriods - 1],
            }))));
  }

  private getPeriodConsumptionValue(periodKey: string): EmissionsAggregateValue[] {
    return this.getEmissions(false)
      .filter(periodConsumption => periodConsumption.year === periodKey && periodConsumption.value !== null);
  }

  private getStartIndexKey(start: TimeFrameResult, index: number): string {
    return start.Start[index].key;
  }

  private calcuateSum(data: any[]): number {
    return data.reduce((acc: any, curr: any) => acc + curr.value, 0);
  }

}
