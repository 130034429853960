ReportSettingService.$inject = ['$state', 'ERUtils', 'EnergyReportingConstants'];

export function ReportSettingService($state, ERUtils, EnergyReportingConstants) {
  function getSettingsTypes() {
    return [
      {
        key: EnergyReportingConstants.SettingType.ByObject,
        translationKey: `FACILITIES_REPORT.BY_${ERUtils.isMeter() ? 'METER' : 'FACILITY'}`
      },
      {
        key: EnergyReportingConstants.SettingType.BySum,
        translationKey: 'FACILITIES_REPORT.BY_SUM'
      }
    ];
  }

  function getTimeFrames() {
    return [
      {
        key: 'current_time_settings',
        translationKey: 'FACILITIES_REPORT.CURRENT_TIME_SETTINGS'
      },
      {
        key: 'rolling_36_months',
        translationKey: 'FACILITIES_REPORT.ROLLING_36_MONTHS'
      }
    ];
  }

  function changeStateBySettingType(settingType, reportIdentifier) {
    reportIdentifier = reportIdentifier || 'trend';

    const suffix = ERUtils.isMeter() ? 'metertree-' : 'report-';

    switch (settingType.key) {
      case EnergyReportingConstants.SettingType.BySum:
        if ($state.get(`^.${suffix}${reportIdentifier}-sum`)) {
          $state.go(`^.${suffix}${reportIdentifier}-sum`, $state.params);
        }
        break;
      case EnergyReportingConstants.SettingType.ByObject:
        if ($state.get(`^.${suffix}${reportIdentifier}`)) {
          $state.go(`^.${suffix}${reportIdentifier}`, $state.params);
        }
        break;
    }
  }

  return {
    getSettingsTypes: getSettingsTypes,
    getTimeFrames: getTimeFrames,
    changeStateBySettingType: changeStateBySettingType
  };
}
