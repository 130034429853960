import { Pipe, PipeTransform } from '@angular/core';

import { FacilityFilter, NumericFilter } from '@enerkey/clients/settings';

import {
  FacilityPropertyName,
  FacilityPropertyNames
} from '../modules/energy-reporting/services/facility-properties.service';

@Pipe({
  name: 'facilityFilterDisplayText'
})
export class FacilityFilterDisplayTextPipe implements PipeTransform {
  public transform(filter: FacilityFilter, propertyNames: FacilityPropertyNames): string {
    const resultArray: string[] = [];

    for (const typeKey of ['textual', 'numeric'] as const) {
      const typeValues = filter[typeKey];
      for (const groupKey of Object.keys(typeValues ?? {})) {
        const groupValues = typeValues[groupKey];
        for (const itemKey of Object.keys(groupValues)) {
          const displayValue = this.getItemDisplayValue(
            typeKey,
            propertyNames?.[groupKey]?.[itemKey],
            groupValues[itemKey]
          );
          resultArray.push(displayValue);
        }
      }
    }

    return resultArray.join(', ');
  }

  private getItemDisplayValue(
    type: 'textual' | 'numeric',
    property: FacilityPropertyName,
    value: string | NumericFilter
  ): string {
    const propertyName = property ? ` (${property.groupName} - ${property.propertyName})` : '';

    switch (type) {
      case 'textual':
        return `${value}${propertyName}`;
      case 'numeric':
        if (typeof value === 'object') {
          const start = Number.isFinite(value.min) ? value.min : '-∞';
          const end = Number.isFinite(value.max) ? value.max : '∞';
          return `${start} - ${end}${propertyName}`;
        }
    }
  }
}
