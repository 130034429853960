<ng-container *ngIf="pageOptions$ | async as pages">
  <page-changer
    *ngIf="pages > 1"
    [pages]="pages"
    (pageChange)="pageChange($event)"
  ></page-changer>
</ng-container>
<div *ngFor="let facility of facilities$ | async | slice : (skipStart$ | async) : (skipEnd$ | async); let last = last">
  <facility-report-header
    [events]="(notes$ | async)?.plain"
    [facility]="facility"
    [modalReportType]="modalReportType"
    (addedAction)="onActionUpdate()"
  ></facility-report-header>
  <div
    *ngIf="(consumptionData$ | async)?.[facility.FacilityId] as facilityQuantityValues"
    [loadingSpinner]="loading$ | async"
    [loadingOverlay]="true"
  >
    <report-content
      [quantityValues]="facilityQuantityValues"
      [gridsVisible]="gridsVisible$ | async"
      [chartsVisible]="chartsVisible$ | async"
      [searchParams]="searchParams$ | async"
      [notes]="notes$ | async"
      [id]="facility.FacilityId"
      [gridConfig]="(gridConfig$ | async)?.[facility.FacilityId]"
      (seriesClick)="seriesClick($event)"
      [modalReportType]="modalReportType"
    ></report-content>
  </div>
  <hr
    *ngIf=!last
    style="max-width: 100%;"
  />
</div>
