<modal-view-header [headingText]="title + ' - ' + ('WIDGET.SETTINGS' | translate)"></modal-view-header>
<div class="modal-body rm-validation-styles">
  <form [formGroup]="formGroup">
    <p>
      <input
        type="text"
        formControlName="title"
        kendoTextBox
        *labelBefore="'WIDGET.TITLE' | translate"
      />
    </p>
    <ng-container *ngIf="!isSettings">
      <p>
        <ek-combo
          formControlName="dashboardTypes"
          [items]="dashboardTypeOptions"
          *labelBefore="'LATEST_ACTIONS_WIDGET.TYPES_TO_SHOW' | translate"
        ></ek-combo>
      </p>
      <ng-container *ngIf="showFacilityDropdown">
        <p>
          <facility-select
            class="searchInput"
            formControlName="facilityId"
            *labelBefore="('FACILITIES.FACILITY'| translate);required: true"
          ></facility-select>
        </p>
      </ng-container>
    </ng-container>
  </form>
</div>
<div class="modal-footer">
  <button
    *ngIf="isSettings"
    class="button button--link button--negative"
    (click)="confirmDelete()"
  >
    {{ 'DELETE' | translate }}
  </button>
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--secondary"
      (click)="cancel()"
    >
      {{ 'CANCEL' | translate }}
    </button>
    <button
      class="button"
      (click)="close()"
      [disabled]="!formGroup.valid"
    >
      {{ 'SAVE' | translate }}
    </button>
  </div>
</div>
