import { IAngularStatic } from 'angular';

import {
  DEFAULT_COLOR,
  ERROR_COLOR,
  HEADER_BACKGROUND,
  indexToColumnCharacter,
  SUCCESS_COLOR,
} from '../spreadsheet.functions';
import { SpreadsheetMessages } from '../interfaces/spreadsheet-messages';
import { removeSpreadsheetOrphans } from '../ek-kendo/kendo.functions';

declare const angular: IAngularStatic;

export abstract class MassImportSpreadsheet<T = (string | number)[]> {
  public abstract spreadsheetElementId: string;
  protected abstract $element: JQuery;
  protected abstract spreadsheet: kendo.ui.Spreadsheet;
  protected abstract messages: SpreadsheetMessages;
  protected abstract numberOfColumns: number; // Note: last column is status
  protected headerRowCount = 1;
  protected totalRowCount: number;
  protected spreadSheetRowClass: '.spreadsheet-row'
  private firstColumnLetter: string = 'A';

  public $onDestroy(): void {
    const spreadsheet = this.spreadsheet || this.findSpreadsheet().data('kendoSpreadsheet');

    if (spreadsheet) {
      spreadsheet.destroy();
      this.spreadsheet = null;
    }

    removeSpreadsheetOrphans();
  }

  protected getSpreadsheetInstance(): kendo.ui.Spreadsheet {
    const spreadsheet = this.findSpreadsheet()
      .data('kendoSpreadsheet')
    ;
    this.fixSpreadsheetListHeight(spreadsheet);
    return spreadsheet;
  }

  protected downloadExcel(): void {
    this.getSpreadsheetInstance().saveAsExcel();
  }

  protected validateSheet(): boolean {
    type ValidatedCell = kendo.ui.SpreadsheetSheetRowCell & { validation?: { value: boolean } };

    this.lockSheet(false);
    let validation = true;
    this.dataRange.forEachCell((_row: number, _column: number, cell: ValidatedCell) => {
      if (cell.validation && !cell.validation.value) {
        validation = false;
      }
    });
    return validation;
  }

  protected enableSheet(): void {
    this.lockSheet(true);
  }

  protected lockRange(index: number): void {
    const range = this.sheet
      .range(`${indexToColumnCharacter(index)
      }${this.offSetBetweenDataAndRows}:${indexToColumnCharacter(index)}${this.totalRowCount}`);
    range.enable(false);
    range.background(HEADER_BACKGROUND);
  }

  protected unlockRange(index: number): void {
    const range = this.sheet
      .range(`${indexToColumnCharacter(index)
      }${this.offSetBetweenDataAndRows}:${indexToColumnCharacter(index)}${this.totalRowCount}`);
    range.enable(true);
    range.background(DEFAULT_COLOR);
  }

  protected lockRows(startRowIndex: number, endRowIndex: number, paintRows = true): void {
    const range = this.sheet
      .range(`${this.firstColumnLetter}${startRowIndex
      }:${this.statusColumnLetter}${endRowIndex}`);
    range.enable(false);
    paintRows && range.background(HEADER_BACKGROUND);
  }

  protected setErrorStatus(index: number, extraMessage?: string): void {
    this.sendStatusBatch(
      index,
      `${this.messages.errorStatus} ${extraMessage ? extraMessage : ''}`,
      ERROR_COLOR
    );
  }

  protected setSuccessStatus(index: number): void {
    this.sendStatusBatch(index, this.messages.savedStatus, SUCCESS_COLOR);
  }

  protected setDeleteSuccessStatus(index: number): void {
    this.sendStatusBatch(index, this.messages.deletedStatus, SUCCESS_COLOR);
  }

  protected setProcessingStatus(index: number): void {
    this.sendStatusBatch(index, this.messages.savingStatus, DEFAULT_COLOR);
  }

  protected setCanceledStatus(index: number): void {
    this.sendStatusBatch(index, this.messages.canceledStatus, ERROR_COLOR);
  }

  protected setNoChanges(index: number): void {
    this.sendStatusBatch(index, this.messages.noChange, DEFAULT_COLOR);
  }

  protected setConflictStatus(index: number): void {
    this.sendStatusBatch(index, this.messages.conflictStatus, ERROR_COLOR);
  }

  protected getDataRows(): T[] {
    return this.dataRange.values();
  }

  protected setCellValue(rowIndex: number, columnIndex: number, value: string): void {
    const columnLetter = indexToColumnCharacter(columnIndex);
    this.sendStatusBatch(rowIndex, value, SUCCESS_COLOR, columnLetter);
  }

  protected setCellErrorValue(rowIndex: number, columnIndex: number, value: string): void {
    const columnLetter = indexToColumnCharacter(columnIndex);
    this.sendStatusBatch(rowIndex, value, SUCCESS_COLOR, columnLetter);
  }

  protected setTooltipMessage(row: number, column: number, message: string): void {
    const actualRowIndex = this.headerRowCount + row;
    const nth = this.getCellOrderIndexFromRowAndCellIndex(actualRowIndex, column);
    this.findSpreadsheet()
      .kendoTooltip({
        filter: `.k-spreadsheet-cell:eq(${nth})`,
        position: 'right',
        content: () => message
      }).data('kendoTooltip');
  }

  private sendStatusBatch(
    rowIndex: number,
    status: string,
    color: string,
    columnLetter = this.statusColumnLetter
  ): void {
    this.sheet.batch(() => {
      const actualIndex = rowIndex + this.offSetBetweenDataAndRows;
      const statusRange = this.sheet.range(
        columnLetter + actualIndex
      );
      statusRange.color(color);
      statusRange.value(status);

    }, { layout: true });
  }

  private get dataRange(): kendo.spreadsheet.Range {
    return this.sheet.range(
      `${this.firstColumnLetter +
      this.offSetBetweenDataAndRows
      }:${
        this.lastDataColumnLetter
      }${this.totalRowCount}`
    );
  }

  private get sheet(): kendo.spreadsheet.Sheet {
    if (!this.spreadsheet) {
      this.spreadsheet = this.getSpreadsheetInstance();
    }
    return this.spreadsheet.activeSheet();
  }

  private get offSetBetweenDataAndRows(): number {
    return this.headerRowCount + 1;
  }

  private get lastDataColumnLetter(): string {
    return indexToColumnCharacter(this.numberOfColumns - 2);
  }

  private get statusColumnLetter(): string {
    return indexToColumnCharacter(this.numberOfColumns - 1);
  }

  private lockSheet(enabled: boolean): void {
    this.sheet.range(`${this.firstColumnLetter }:${
      this.lastDataColumnLetter }${this.totalRowCount}`).enable(enabled);
  }

  private findSpreadsheet(): JQuery {
    return this.$element
      .find(`#${this.spreadsheetElementId}`);
  }

  private getCellOrderIndexFromRowAndCellIndex(rowIndex: number, columnIndex: number): number {
    return (this.numberOfColumns * rowIndex) + columnIndex;
  }

  /**
   * Fix for overflowing k-list,
   * Source https://www.telerik.com/forums/k-animation-container-1384e3db9853#_ByLlOAMCEWr31LQl_EA3w
   */
  private fixSpreadsheetListHeight(spreadsheet: kendo.ui.Spreadsheet): void {
    if (!spreadsheet) {
      return;
    }
    spreadsheet.bind('render', (e: kendo.ui.SpreadsheetRenderEvent) => {
      e.sender.element.on('click', '.k-spreadsheet-editor-button', () => {
        const animationContainer = angular.element('.k-animation-container').last();
        animationContainer.children('.k-popup').css('max-height', 500);
        animationContainer.children('.k-popup').css('overflow-y', 'auto');
      });
      e.sender.unbind('render');
    });
  }
}
