<ng-container *ngIf="externalId; then external else internal"></ng-container>

<ng-template #external>
  <span>
    {{ externalId }}
    <i
      class="fas fa-external-link-square-alt external-id"
      [ngfTooltip]="'EXTERNAL_CUSTOMER_ID' | translate"
    ></i>
  </span>
</ng-template>
<ng-template #internal>
  <span>
    {{ lastModifiedBy }}
  </span>
</ng-template>
