import { IAngularStatic } from 'angular';

import MeterMassEditComponent from './meter-mass-edit-component';

declare const angular: IAngularStatic;

export default angular.module('app.modules.admin.components.meter-mass-edit', [
])
  .component('meterMassEdit', MeterMassEditComponent)
;
