import { Directive, Optional } from '@angular/core';
import { EditCommandDirective } from '@progress/kendo-angular-grid';

import { KendoGridButtonDirective } from './kendo-grid-button.directive';

@Directive({
  selector: '[kendoGridEditCommand]'
})
export class KendoGridEditCommandDirective extends KendoGridButtonDirective {
  public constructor(@Optional() editCommand: EditCommandDirective) {
    super(editCommand);
    if (editCommand) {
      editCommand.primary = true;
      editCommand.iconClass = 'fa-1x fas fa-pencil-alt';
    }
  }
}
