function NumberInputFieldController() {
  let vm = this;

  vm.inputChanged = inputChanged;

  vm.number = angular.copy(vm.model);
  vm.validationPattern = '[0-9]+([.,][0-9]+)?';

  function inputChanged() {
    vm.number = parseNumber(vm.model);
    if (!isNaN(vm.number)) {
      vm.onChange({ key: vm.id, value: vm.number });
    }
  }

  function parseNumber(input) {
    if (angular.isUndefined(input)) {
      return;
    }
    input = input.replace(',', '.');
    return parseFloat(input);
  }
}

export default NumberInputFieldController;
