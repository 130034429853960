import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'topbar-search',
  templateUrl: './topbar-search.component.html',
  styleUrls: ['./topbar-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarSearchComponent implements OnDestroy {
  @Input() public isMobile: boolean;

  public readonly showSearch$: Observable<boolean>;

  private readonly _showSearch$ = new BehaviorSubject(false);

  public constructor() {
    this.showSearch$ = this._showSearch$.asObservable();
  }

  public ngOnDestroy(): void {
    this._showSearch$.complete();
  }

  public toggleSearch(): void {
    this._showSearch$.next(!this._showSearch$.value);
  }

  public closeSearch(): void {
    this._showSearch$.next(false);
  }
}
