import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';

import { flattenGroupedData, SelectionEventOf } from '@enerkey/ts-utils';

export interface KendoGridServiceOptions {
  keepSelectionOnDataChange: boolean
}

@Injectable()
export class KendoGridService<TModel, TKey extends keyof TModel> implements OnDestroy {

  /** Identifier property name, eg: `'id'`. Value should be unique and defined across all items. */
  public get selectBy(): TKey {
    return this._selectBy;
  }

  /**
   * Emits selected keys when grid selection changes.
   * Completes itself when providing component is destroyed.
   */
  public readonly selection$: Observable<TModel[TKey][]>;

  /**
   * Emits whether grid has visible data when grid data-source or filters change.
   * Completes itself when providing component is destroyed.
   */
  public readonly visibleData$: Observable<TModel[]>;

  private get selectedKeys(): TModel[TKey][] {
    return this._selection.value;
  }

  private grid: GridComponent = null;
  private dataSource: TModel[] = [];
  private visibleData: TModel[] = [];
  private _subscription = new Subscription();
  private _selectBy: TKey = undefined;

  private keepSelectionOnDataChange: boolean;

  private readonly _dataChanged = new Subject<TModel[]>();
  private readonly _selection = new BehaviorSubject<TModel[TKey][]>([]);
  private readonly _visibleDataChanged = new BehaviorSubject<TModel[]>([]);

  public constructor() {
    this.selection$ = this._selection.asObservable();
    this.visibleData$ = this._visibleDataChanged.asObservable();

    this._dataChanged.subscribe(data => this.handleDataChange(data));
  }

  public ngOnDestroy(): void {
    this._dataChanged.complete();
    this._selection.complete();
    this._visibleDataChanged.complete();
    this._subscription.unsubscribe();
  }

  /** Call this in `ngOnInit` or `ngAfterViewInit` of the grid's host component. */
  public initialize(selectBy: TKey, grid: GridComponent, options?: KendoGridServiceOptions): void {
    this._selectBy = selectBy;
    this.grid = grid;

    this._subscription.unsubscribe();
    this._subscription = new Subscription();

    // Remove selection from items that were hidden by filtering. Items on different pages should be retained.
    this._subscription.add(
      grid.filterChange.subscribe((filter: CompositeFilterDescriptor) => this.handleFilterChange(filter))
    );

    // Manually handle clicking checkboxes in the selection column
    this._subscription.add(
      grid.selectionChange.subscribe((event: SelectionEventOf<TModel>) => this.handleSelectionChange(event))
    );

    this.keepSelectionOnDataChange = options?.keepSelectionOnDataChange ?? false;

    this._selection.next([]);
  }

  /** Call manually when grid data changes. `dataSource` should be a flat array. */
  public dataChanged(dataSource: TModel[]): void {
    this._dataChanged.next(dataSource ?? []);
  }

  public setSelectedItems(items: TModel[TKey][]): void {
    this._selection.next(items);
  }

  public getSelectAllState(): boolean {
    return this.getCheckboxState(this.visibleData);
  }

  /** Returns `true` if all visible items are selected, `false` if none, and `undefined` otherwise. */
  public getCheckboxState(data: TModel[]): boolean {
    const subsetKeys = flattenGroupedData(data).map(item => item[this.selectBy]);

    if (subsetKeys.length === 0) {
      return false;
    }

    const included = subsetKeys.filter(key => this.selectedKeys.includes(key));

    if (included.length === 0) {
      return false;
    } else if (included.length === subsetKeys.length) {
      return true;
    } else {
      return undefined;
    }
  }

  /** Selects or deselects all visible items. */
  public selectAllChange(allSelected: boolean): void {
    const newKeys = allSelected
      ? this.visibleData.map(dataItem => dataItem[this.selectBy])
      : [];

    this._selection.next(newKeys);
  }

  private handleDataChange(dataSource: TModel[]): void {
    this.dataSource = dataSource;

    // As this is called manually by host component, we can end up here before `initialize()` is called.
    if (!this.grid) {
      return;
    }

    this.updateVisibleData();
    if (this.keepSelectionOnDataChange) {
      this.updateSelection();
    } else {
      this.selectAllChange(false);
    }
  }

  private handleSelectionChange(selection: SelectionEventOf<TModel>): void {
    const removedKeys = selection.deselectedRows.map(row => row.dataItem[this.selectBy]);
    const addedKeys = selection.selectedRows.map(row => row.dataItem[this.selectBy]);

    const keys = this.selectedKeys.except(removedKeys).concat(addedKeys).unique();

    this._selection.next(keys);
  }

  private handleFilterChange(filter: CompositeFilterDescriptor): void {
    this.updateVisibleData(filter);
    this.updateSelection();
  }

  private updateVisibleData(filter?: CompositeFilterDescriptor): void {
    this.visibleData = filterBy(this.dataSource, filter ?? this.grid.filter);
    this._visibleDataChanged.next(this.visibleData);
  }

  private updateSelection(): void {
    // Nothing selected
    if (this.selectedKeys.length === 0) {
      return;
    }

    const validKeys = this.selectedKeys.filter(
      value => this.visibleData.some(item => item[this.selectBy] === value)
    );

    // Nothing selected was hidden
    if (validKeys.length === this.selectedKeys.length) {
      return;
    }

    this._selection.next(validKeys);
  }
}
