import { NgModule } from '@angular/core';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { CheckBoxModule, InputsModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule as TreeListExcelModule, TreeListModule } from '@progress/kendo-angular-treelist';
import { SortableModule } from '@progress/kendo-angular-sortable';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { ExcelModule as GridExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IntlModule } from '@progress/kendo-angular-intl';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { EkFormsModule } from '@enerkey/ek-forms';
import { AccordionModule } from '@enerkey/foundation-angular';

import { SharedModule } from '../../shared/shared.module';
import { EkInputsModule } from '../../shared/ek-inputs/ek-inputs.module';
import { MeterGroupsDropdownListComponent } from './components/meter-group-components/meter-group-dropdown-list/meter-groups-dropdown-list.component';
import { MeterGroupsCreateModalComponent } from './components/meter-group-components/meter-groups-create-modal/meter-groups-create-modal.component';
import { MeterGroupsGridComponent } from './components/meter-group-components/meter-groups-grid/meter-groups-grid.component';
import { MeterGroupsSidebarComponent } from './components/meter-group-components/meter-groups-sidebar/meter-groups-sidebar.component';
import { MeterGroupsComponent } from './components/meter-group-components/meter-groups/meter-groups.component';
import { MeterSearchTreelistComponent } from './components/meter-search-treelist/meter-search-treelist.component';
import { EkKendoModule } from '../../shared/ek-kendo/ek-kendo.module';
import { CommonPipesModule } from '../../shared/common-pipes/common-pipes.module';
import { EkDropdownModule } from '../../shared/ek-dropdown/ek-dropdown.module';
import { MeterManagementComponent } from './components/meter-management/meter-management.component';
import {
  adminBaseState,
  facilityManagementState,
  meterGroupsManagementState,
  meterManagementState,
  profileManagementState,
  userManagementState
} from './admin.states';
import { SubmeterSelectModalComponent } from './components/submeter-select-modal/submeter-select-modal.component';
import { MeterEditModalComponent } from './components/meter-edit-modal/meter-edit-modal.component';
import { VirtualMetersModule } from '../virtual-meters/virtual-meters.module';
import { MeterManagementSearchComponent } from './components/meter-management-search/meter-management-search.component';
import { AdminSharedModule } from '../../shared/admin-shared/admin-shared.module';
import { MeterReorderModalComponent } from './components/meter-reorder-modal/meter-reorder-modal.component';
import { ProfileManagementComponent } from './components/profile-management/profile-management.component';
import { ProfileManagementSearchComponent } from './components/profile-management-search/profile-management-search.component';
import { ProfileManagementGridComponent } from './components/profile-management-grid/profile-management-grid.component';
import { ProfileEditModalComponent } from './components/profile-edit-modal/profile-edit-modal.component';
import { ProfileAddModalComponent } from './components/profile-add-modal/profile-add-modal.component';
import { ProfileMassEditModalComponent } from './components/profile-mass-edit-modal/profile-mass-edit-modal.component';
import { AdminBaseComponent } from './components/admin-base/admin-base.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserManagementSearchComponent } from './components/user-management-search/user-management-search.component';
import { UserManagementGridComponent } from './components/user-management-grid/user-management-grid.component';
import { UserActionLogModalComponent } from './components/user-action-log-modal/user-action-log-modal.component';
import { UserOperationModalComponent } from './components/user-operation-modal/user-operation-modal.component';
import { UserMassEditModalComponent } from './components/user-mass-edit-modal/user-mass-edit-modal.component';
import { UserMassEmailModalComponent } from './components/user-mass-email-modal/user-mass-email-modal.component';
import { UserRoleEditModalComponent } from './components/user-role-edit-modal/user-role-edit-modal.component';
import { UserProfileMassEditModalComponent } from './components/user-profile-mass-edit-modal/user-profile-mass-edit-modal.component';
import { UserSettingsCopyModalComponent } from './components/user-settings-copy-modal/user-settings-copy-modal.component';
import { UserMassCreateModalComponent } from './components/user-mass-create-modal/user-mass-create-modal.component';
import { FacilityManagementComponent } from './components/facility-management/facility-management.component';
import { FacilityManagementGridComponent } from './components/facility-management-grid/facility-management-grid.component';
import { FacilityManagementSearchComponent } from './components/facility-management-search/facility-management-search.component';
import { FacilityTagEditModalComponent } from './components/facility-tag-edit-modal/facility-tag-edit-modal.component';
import { FacilityProfilesModalComponent } from './components/facility-profiles-modal/facility-profiles-modal.component';
import { FacilityCompaniesModalComponent } from './components/facility-companies-modal/facility-companies-modal.component';
import { MassAddSubscriptionModalComponent } from './components/mass-add-subscription-modal/mass-add-subscription-modal.component';
import { FacilityEditModalComponent } from './components/facility-edit-modal/facility-edit-modal.component';
import { FacilityPropertyTableComponent } from './components/facility-property-table/facility-property-table.component';
import { FacilityPropertyHistoryModalComponent } from './components/facility-property-history-modal/facility-property-history-modal.component';
import { FacilityUrlTableComponent } from './components/facility-url-table/facility-url-table.component';
import { AddMetersToPoaModalComponent } from './components/add-meters-to-poa-modal/add-meters-to-poa-modal.component';
import { FacilityLocalpowerComponent } from './components/facility-localpower/facility-localpower.component';
import { FacilityCustomerFieldsTableComponent } from './components/facility-customer-fields-table/facility-customer-fields-table.component';
import { MeterTagEditModalComponent } from './components/meter-tag-edit-modal/meter-tag-edit-modal.component';

@NgModule({
  declarations: [
    FacilityCompaniesModalComponent,
    MeterSearchTreelistComponent,
    MeterManagementComponent,
    MeterGroupsComponent,
    MeterGroupsCreateModalComponent,
    MeterGroupsSidebarComponent,
    MeterGroupsDropdownListComponent,
    MeterGroupsGridComponent,
    SubmeterSelectModalComponent,
    MeterEditModalComponent,
    MeterManagementSearchComponent,
    MeterReorderModalComponent,
    ProfileManagementComponent,
    ProfileManagementSearchComponent,
    ProfileManagementGridComponent,
    ProfileEditModalComponent,
    ProfileAddModalComponent,
    ProfileMassEditModalComponent,
    AdminBaseComponent,
    UserManagementComponent,
    UserManagementSearchComponent,
    UserManagementGridComponent,
    UserActionLogModalComponent,
    UserOperationModalComponent,
    UserMassEditModalComponent,
    UserMassEmailModalComponent,
    UserRoleEditModalComponent,
    UserProfileMassEditModalComponent,
    UserSettingsCopyModalComponent,
    UserMassCreateModalComponent,
    FacilityManagementComponent,
    FacilityManagementGridComponent,
    FacilityManagementSearchComponent,
    FacilityTagEditModalComponent,
    FacilityProfilesModalComponent,
    MassAddSubscriptionModalComponent,
    FacilityEditModalComponent,
    FacilityPropertyTableComponent,
    FacilityPropertyHistoryModalComponent,
    FacilityUrlTableComponent,
    AddMetersToPoaModalComponent,
    FacilityLocalpowerComponent,
    FacilityCustomerFieldsTableComponent,
    MeterTagEditModalComponent,
  ],
  imports: [
    UIRouterUpgradeModule.forChild({ states: [
      adminBaseState,
      userManagementState,
      meterManagementState,
      meterGroupsManagementState,
      profileManagementState,
      facilityManagementState
    ] }),
    SharedModule,
    CheckBoxModule,
    TextBoxModule,
    DateInputsModule,
    DropDownsModule,
    InputsModule,
    EkInputsModule,
    TreeListModule,
    EkKendoModule,
    CommonPipesModule,
    EkDropdownModule,
    EkFormsModule,
    VirtualMetersModule,
    TreeListExcelModule,
    GridExcelModule,
    AdminSharedModule,
    SortableModule,
    GridModule,
    IntlModule,
    ButtonsModule,
    AccordionModule,
    DialogsModule
  ],
})
export class AdminModule { }
