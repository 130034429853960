<ng-container *ngFor="let model of items">
  <ek-dropdown-heading *ngIf="isHeading(model); else dropdownItem">
    {{ model.text }}
  </ek-dropdown-heading>
  <ng-template #dropdownItem>
    <ek-dropdown-item
      [disabled]="$any(model).isDisabled && $any(model).isDisabled()"
      (click)="$any(model).click()"
    >
      {{ model.text }}
    </ek-dropdown-item>
  </ng-template>
</ng-container>
