<form
  [formGroup]="searchForm"
  (ngSubmit)="search()"
>
  <div>
    <div class="shortcuts">
      <label><strong>{{ 'GRID_SHORTCUTS.TITLE' | translate }}</strong></label>
      <button
        type="button"
        kendoButton
        [disabled]="loading$ | async"
        (click)="searchCurrentProfile()"
      >
        {{ 'SETTINGS.CURRENT_PROFILE' | translate }}
      </button>
      <hr>
    </div>

    <input
      formControlName="profileName"
      type="text"
      kendoTextBox
      *labelBefore="'ADMIN.PROFILENAME' | translate"
    >
    <kendo-numerictextbox
      formControlName="profileId"
      kendoTextBox
      *labelBefore="'ADMIN.PROFILEID' | translate"
      integerInput
    ></kendo-numerictextbox>
    <kendo-numerictextbox
      formControlName="facilityId"
      kendoTextBox
      *labelBefore="'ADMIN.FACILITYID' | translate"
      integerInput
    ></kendo-numerictextbox>
    <kendo-numerictextbox
      formControlName="enegiaId"
      kendoTextBox
      *labelBefore="'ADMIN.TERMINAL.ENEGIA_ID' | translate"
      integerInput
    ></kendo-numerictextbox>
    <service-select
      formControlName="serviceType"
      *labelBefore="'ADMIN.SERVICES' | translate"
    ></service-select>
  </div>
  <div class="buttons-container">
    <button
      class="button"
      type="submit"
      [loading]="loading$ | async"
    >
      {{ 'SEARCH' | translate }}
    </button>
    <button
      class="button button--secondary float-right"
      type="button"
      (click)="searchForm.reset()"
    >
      {{ 'RESET' | translate }}
    </button>
  </div>
</form>
