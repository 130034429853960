import { Component, inject, isDevMode, OnDestroy, ViewContainerRef } from '@angular/core';
import { isObservable } from 'rxjs';

/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/ban-types */

type Canary = {
  readonly key: string;
  completed?: boolean;
};

@Component({ template: '' })
class LifecycleComponent implements OnDestroy {
  public callback: () => void;

  public ngOnDestroy(): void {
    this.callback();
  }
}

export function ensureObservablesComplete(
  component: object,
  timeout: number = 1000
): void {
  if (!isDevMode()) {
    throw Error(`${ensureObservablesComplete.name}() should be only called in dev mode`);
  }

  const viewContainerRef = inject(ViewContainerRef);
  const componentRef = viewContainerRef.createComponent(LifecycleComponent);
  componentRef.location.nativeElement.remove();
  componentRef.instance.callback = ensureCompletion;

  function ensureCompletion(): void {
    const name = (component as unknown).constructor.name;

    const all: Canary[] = [];

    for (const [key, value] of Object.entries(component)) {
      if (isObservable(value)) {
        const canary: Canary = { key };
        value.subscribe({ complete: () => { canary.completed = true; } });
        all.push(canary);
      }
    }

    if (!all.length) {
      console.log(`${name} has no observables`);
    } else {
      setTimeout(() => {
        const notCompleted = all.filterMap(x => !x.completed, x => x.key);

        if (notCompleted.length) {
          console.warn(
            `${notCompleted.length} of ${all.length} observables of ${name} did not complete ` +
            `in ${timeout}ms: ${notCompleted.join(', ')}`
          );
        } else {
          console.log(
            `All ${all.length} observables of ${name} completed successfully in ${timeout}ms`
          );
        }
      }, timeout);
    }
  }
}
