import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { PortalSettings } from '@enerkey/clients/user-management';

import { LanguageService } from './language.service';
import { TenantInfoService } from './tenant-info.service';

export interface SocialMediaLink {
  icon: string;
  href: string;
}

const links = ['home', 'twitter', 'linkedIn'] as const;

type LinkKey = typeof links[number];

interface LinkInfo {
  icon: string;
  localeAddFn?: (lang: string) => string;
}

const linkProperties: Record<LinkKey, LinkInfo> = {
  home: { icon: 'fa fa-globe' },
  twitter: { icon: 'fab fa-twitter', localeAddFn: (lang: string) => `?lang=${lang}` },
  linkedIn: { icon: 'fab fa-linkedin' },
};

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {
  public constructor(
    private languageService: LanguageService,
    private tenantInfoService: TenantInfoService
  ) {
  }

  public getLinks(): Observable<SocialMediaLink[]> {
    const currentLanguage = this.languageService.getLanguage();
    return this.tenantInfoService.getTenantLinkInfo().pipe(
      take(1),
      map(tenantLinks => links.reduce((linkHrefs, link) => {
        const linkHref = this.getLinkHref(tenantLinks, link, currentLanguage);
        return linkHref
          ? [...linkHrefs, { href: linkHref, icon: linkProperties[link].icon }]
          : linkHrefs;
      }, []))
    );
  }

  private getLinkHref(
    linkInfo: PortalSettings,
    link: LinkKey,
    lang: string
  ): string {
    if (!linkInfo[link]) {
      return null;
    }

    const langPrefix = linkProperties[link]?.localeAddFn?.(lang) ?? '';
    return `${linkInfo[link]}${langPrefix}`;
  }
}
