import { downgradeInjectable } from '@angular/upgrade/static';

import 'angular-recursion';
import 'angular-translate';
import '@uirouter/angular-hybrid';

import ComponentsModule from '../../components';
import ConfigurationModelsModule from '../configuration/models';

import ManualQAComponentsModule from './components';
import config from './config';
import ManualQAServicesModule from './services';
import { MqaClient } from '@enerkey/clients/manual-qa';

const dependencies = [
  'kendo.directives',
  'pascalprecht.translate',
  'ui.router',
  'RecursionHelper',
  ConfigurationModelsModule.name,
  ManualQAComponentsModule.name,
  ManualQAServicesModule.name,
  ComponentsModule.name
];

const ManualQaModule = angular
  .module('app.modules.manual-qa', dependencies)
  .factory('mqaClient', downgradeInjectable(MqaClient))
  .config(config)
;

export default ManualQaModule;
