import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { combineLatest, defer, EMPTY, forkJoin, merge, Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, take, takeLast, takeUntil } from 'rxjs/operators';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { ServiceLevel } from '@enerkey/clients/facility';
import { CalendarMode } from '@enerkey/clients/settings';
import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { Service } from '../../constants/service';
import { UserService } from '../../services/user-service';
import { ComboItem } from '../../shared/ek-inputs/ek-combo/ek-combo.component';
import { LanguageService, Locale } from '../../shared/services/language.service';
import { LanguageChangeService } from '../../shared/services/language-change.service';
import { ServiceLevelService } from '../../shared/services/service-level.service';
import { ToasterService } from '../../shared/services/toaster.service';
import { ThresholdService } from '../../shared/services/threshold.service';

type UserSettingsForm = {
  locale: Locale;
  timeSeriesTypeId: CalendarMode;
  threshold: number;
};

@Component({
  selector: 'user-settings-modal',
  templateUrl: './user-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'tiny' })
export class UserSettingsModalComponent extends ModalBase implements OnInit, OnDestroy {

  public readonly timeSeriesOptions: ComboItem<number>[] = [
    { value: CalendarMode.CalendarYear, text: 'SETTINGS.CALENDAR' },
    { value: CalendarMode.CurrentMonth, text: 'SETTINGS.ROLLING' },
  ];

  public formGroup: UntypedFormGroup;
  public controls: ControlsOf<UserSettingsForm>;

  public get formValue(): UserSettingsForm {
    return this.formGroup.value;
  }

  public readonly profileName$: Observable<string>;
  public readonly loading$: Observable<boolean>;
  public readonly showReportingOptions$: Observable<boolean>;

  private _originalValues: UserSettingsForm;

  private readonly _destroy = new Subject<void>();
  private readonly _loading = new LoadingSubject();

  public constructor(
    currentModal: NgfActiveModal,
    serviceLevelService: ServiceLevelService,
    userService: UserService,
    private readonly toaster: ToasterService,
    private readonly languageService: LanguageService,
    private readonly languageChangeService: LanguageChangeService,
    private readonly thresholdService: ThresholdService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(currentModal);

    const profileInitialized$ = defer(() => userService.isInitializedWithInitialProfileAsync());

    this.showReportingOptions$ = profileInitialized$.pipe(
      map(profile =>
        profile.id !== 0 &&
        serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Large) &&
        userService.hasService(Service.EnergyReporting)),
      takeUntil(this._destroy)
    );

    this.loading$ = combineLatest([
      this._loading.asObservable(),
      this.thresholdService.loading$
    ]).pipe(
      map(([x, y]) => x || y),
      takeUntil(this._destroy)
    );

    this.profileName$ = profileInitialized$.pipe(
      map(() => userService.getCurrentProfile().name),
      takeUntil(this._destroy)
    );
  }

  public ngOnInit(): void {
    this.showReportingOptions$.pipe(take(1)).subscribe(
      show => {
        if (show) {
          forkJoin([
            this.thresholdService.calendarMode$.pipe(take(1)),
            this.thresholdService.threshold$.pipe(take(1)),
          ]).pipe(
            indicate(this._loading),
            takeUntil(this._destroy)
          ).subscribe(([calendarMode, threshold]) => {
            this._originalValues = {
              locale: this.languageService.getLocale(),
              timeSeriesTypeId: calendarMode,
              threshold: threshold,
            };
            this.createForm();
          });
        } else {
          this._originalValues = {
            locale: this.languageService.getLocale(),
            timeSeriesTypeId: null,
            threshold: null,
          };
          this.createForm();
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loading.complete();
  }

  public dismiss(): void {
    this._destroy.next();
    super.dismissModal();
  }

  public submit(): void {
    this.formGroup.disable();

    this.getChanges().pipe(
      indicate(this._loading),
      finalize(() => super.closeModal())
    ).subscribe({
      next: () => this.toaster.success('SETTINGS.CHANGES_SAVED'),
      error: () => this.toaster.error('SETTINGS.CHANGES_FAILED'),
    });
  }

  private createForm(): void {
    this.controls = formControlsFrom<UserSettingsForm>(this._originalValues);
    this.formGroup = new UntypedFormGroup(this.controls);
    this.changeDetectorRef.detectChanges();
  }

  /** Emits once if any settings were changed, otherwise completes without emitting. */
  private getChanges(): Observable<unknown> {
    return merge(
      this.getLocaleChange(),
      this.getTimeSeriesTypeId(),
      this.getThreshold()
    ).pipe(takeLast(1));
  }

  private getLocaleChange(): Observable<unknown> {
    const locale = this.formValue.locale;

    if (this._originalValues.locale === locale) {
      return EMPTY;
    }

    return defer(() => this.languageChangeService.changeLocale(locale));
  }

  private getTimeSeriesTypeId(): Observable<unknown> {
    return this.showReportingOptions$.pipe(switchMap(show => {
      if (!show) {
        return EMPTY;
      }

      const timeSeriesType = this.formValue.timeSeriesTypeId;

      if (this._originalValues.timeSeriesTypeId === timeSeriesType) {
        return EMPTY;
      }

      return this.thresholdService.setCalendarMode(timeSeriesType);
    }));
  }

  private getThreshold(): Observable<unknown> {
    return this.showReportingOptions$.pipe(switchMap(show => {
      if (!show) {
        return EMPTY;
      }

      const threshold = this.formValue.threshold;

      if (this._originalValues.threshold === threshold) {
        return EMPTY;
      }

      return this.thresholdService.setThreshold(threshold);
    }));
  }
}
