import { Injectable } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { CalendarMode, SettingsClient } from '@enerkey/clients/settings';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ProfileService } from './profile.service';

@Injectable({ providedIn: 'root' })
export class ThresholdService {
  public readonly threshold$: Observable<number>;
  public readonly calendarMode$: Observable<CalendarMode>;
  public readonly loading$: Observable<boolean>;
  public currentCalendarMode: CalendarMode;

  private readonly updatedThreshold$ = new Subject<number>();
  private readonly updatedCalendarMode$ = new Subject<CalendarMode>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    private readonly settingsClient: SettingsClient,
    private readonly profileService: ProfileService
  ) {
    this.loading$ = this._loading$.asObservable();
    this.threshold$ = merge(
      this.profileService.profileId$.pipe(
        switchMap(profileId => this.settingsClient.getThreshold(profileId).pipe(
          indicate(this._loading$)
        ))
      ),
      this.updatedThreshold$
    ).pipe(
      shareReplay(1)
    );

    this.calendarMode$ = merge(
      this.settingsClient.getCalendarMode(),
      this.updatedCalendarMode$
    ).pipe(
      shareReplay(1)
    );
    this.calendarMode$.subscribe(calendarMode => {
      this.currentCalendarMode = calendarMode;
    });
  }

  public setThreshold(threshold: number): Observable<number> {
    return this.profileService.profileId$.pipe(
      take(1),
      switchMap(profileId => this.settingsClient.setThreshold(profileId, threshold).pipe(
        indicate(this._loading$)
      )),
      tap(newThreshold => {
        this.updatedThreshold$.next(newThreshold);
      })
    );
  }

  public setCalendarMode(calendarMode: CalendarMode): Observable<CalendarMode> {
    return this.settingsClient.setCalendarMode(calendarMode).pipe(
      tap(newCalendarMode => {
        this.updatedCalendarMode$.next(newCalendarMode);
      })
    );
  }
}
