import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('Window object', {
  providedIn: 'root',
  factory: () => window
});

export const NAVIGATOR = new InjectionToken<Navigator>('Navigator object', {
  providedIn: 'root',
  factory: () => navigator
});

