import { ChangeDetectionStrategy, Component } from '@angular/core';

import { OutgoingMessagesService } from '../../services/outgoing-messages.service';

@Component({
  selector: 'outgoing-messages',
  templateUrl: './outgoing-messages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [OutgoingMessagesService]
})
export class OutgoingMessagesComponent {
}
