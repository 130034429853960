<ngf-tabset
  [activeId]="reportType$ | async"
  [noPadding]="true"
  [showCountLabel]="false"
>
  <ngf-tab
    [title]="'FACILITIES.QUANTITIES' | translate"
    [id]="ReportType.Quantity"
  >
    <ng-template ngfTabContent>
      <modal-quantity-reports></modal-quantity-reports>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES_REPORT.METERTREE' | translate"
    [id]="ReportType.Meter"
  >
    <ng-template ngfTabContent>
      <modal-meter-reports></modal-meter-reports>
    </ng-template>
  </ngf-tab>
</ngf-tabset>
