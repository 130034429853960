import { getInfoStringFromArray } from './utils.functions.js';
import { getInteger, getString } from './object.functions';

export const NOT_SET = 0;
export const CUMULATIVE = 1;
export const CONSUMPTION = 2;

export const CONSUMPTION_STRING = '(C)';
export const CUMULATIVE_STRING = '(H)';
export const NOT_SET_STRING = '';

export class ReaderTypeInformation {
  constructor(values) {
    this.id = getInteger(values, 'id');
    this.name = getString(values, 'name');
    this.terminal = getString(values, 'terminal');
    this.logUrl = getString(values, 'logUrl');
    this.meterValueType = getInteger(values, 'meterValueType', NOT_SET);
    this.meterValueTypeString = this.getMeterValueTypeString();
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getTerminal() {
    return this.terminal;
  }

  getLogUrl() {
    return this.logUrl;
  }

  getMeterValueType() {
    return this.meterValueType;
  }

  getMeterValueTypeString() {
    if (this.getMeterValueType() === CUMULATIVE) {
      return CUMULATIVE_STRING;
    }

    if (this.getMeterValueType() === CONSUMPTION) {
      return CONSUMPTION_STRING;
    }

    return NOT_SET_STRING;
  }

  getInfoString() {
    return getInfoStringFromArray(
      [
        this.getName(),
        this.getMeterValueTypeString(),
        this.getTerminal()
      ]
    );
  }
}
