import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { UserManagementClient } from '@enerkey/clients/user-management';
import { formGroupFrom } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { CopySettingOptions, SettingsClient } from '@enerkey/clients/settings';

import { ToasterService } from '../../../../shared/services/toaster.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

type UserCopyFormValue = {
  organization: number;
  user: number;
  whatToCopy: Pick<CopySettingOptions, 'dashboards' | 'bookmarks'>,
  sharedProfile: number;
}

function whatToCopyValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: UserCopyFormValue['whatToCopy'] = control.value;
    const oneSelected = value.dashboards || value.bookmarks;
    return oneSelected ? null : { mustSelectOne: { oneSelected } };
  };
}

@Component({
  selector: 'user-settings-copy-modal',
  templateUrl: './user-settings-copy-modal.component.html',
  styleUrls: ['./user-settings-copy-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsCopyModalComponent extends ModalBase<void> implements OnInit, OnDestroy {

  public selectedUsers: number[];
  public readonly userList$: Observable<ComboItem<number>[]>;
  public readonly sharedProfileList$: Observable<ComboItem<number>[]>;
  public readonly copyUserSettingsForm: UntypedFormGroup;
  public readonly loadingUsers$: Observable<boolean>;
  public readonly loadingProfileList$: Observable<boolean>;

  private readonly _selectedUsers$ = new ReplaySubject<number[]>(1);
  private readonly _loadingUsers = new LoadingSubject();
  private readonly _loadingProfileList = new LoadingSubject();
  private readonly _destroy = new Subject<void>();

  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly settingsClient: SettingsClient,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly toasterService: ToasterService,
    authenticationService: AuthenticationService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    const organizationId = authenticationService.organizationId;
    const userId = authenticationService.getUserId();
    this.copyUserSettingsForm = formGroupFrom<UserCopyFormValue>({
      organization: organizationId,
      user: userId,
      whatToCopy: formGroupFrom<UserCopyFormValue['whatToCopy']>({
        dashboards: true,
        bookmarks: false,
      }),
      sharedProfile: null
    }, {
      user: Validators.required,
      whatToCopy: whatToCopyValidator(),
      sharedProfile: Validators.required
    });

    this.userList$ = this.copyUserSettingsForm.get('organization').valueChanges.pipe(
      startWith(organizationId),
      switchMap(id => this.getOrganizationUsers(id))
    );
    this.sharedProfileList$ = combineLatest([
      this.copyUserSettingsForm.get('user').valueChanges.pipe(
        startWith(userId)
      ),
      this._selectedUsers$.pipe(
        switchMap(selectedUsers => this.userManagementClient.getProfilesForUsers(selectedUsers))
      )
    ])
      .pipe(
        switchMap(([uId, profileIds]) => this.getProfileList(uId, profileIds))
      );
    this.loadingProfileList$ = this._loadingProfileList.asObservable();
    this.loadingUsers$ = this._loadingUsers.asObservable();
  }

  public ngOnInit(): void {
    this._selectedUsers$.next(this.selectedUsers);
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loadingUsers.complete();
    this._loadingProfileList.complete();
    this._selectedUsers$.complete();
  }

  public submit(): void {
    const data: UserCopyFormValue = this.copyUserSettingsForm.value;
    this.dialogService.getConfirmationModalPromise({
      text: 'ADMIN.COPY_USERSETTINGS.SAVE_CONFIRMATION',
      title: this.translateService.instant('ADMIN.COPY_USERSETTINGS.BUTTON_TITLE'),
      isDelete: false
    }).then(() => {
      this.settingsClient.copySettings(new CopySettingOptions({
        execute: true,
        profileId: data.sharedProfile,
        sourceUserId: data.user,
        targetUserIds: this.selectedUsers,
        bookmarks: data.whatToCopy.bookmarks,
        dashboards: data.whatToCopy.dashboards,
      })).subscribe({
        next: () => {
          this.toasterService.success('ADMIN.COPY_USERSETTINGS.SAVE_SUCCESS');
          super.closeModal();
        },
        error: () => this.toasterService.error('ADMIN.COPY_USERSETTINGS.SAVE_ERROR')
      });
    }).catch(() => {});
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private getOrganizationUsers(organizationId: number): Observable<ComboItem<number>[]> {
    return this.userManagementClient.getAllUsers(
      undefined, undefined, undefined,
      undefined, organizationId, false
    ).pipe(
      take(1),
      map(users => users.map(user => ({
        value: user.id,
        text: user.userName
      }))),
      indicate(this._loadingUsers),
      takeUntil(this._destroy)
    );
  }

  private getProfileList(userId: number, profileIds: {[key: string]: number[]}): Observable<ComboItem<number>[]> {
    this.copyUserSettingsForm.patchValue({ sharedProfile: null });
    return this.userManagementClient.getUsersProfiles(
      userId
    ).pipe(
      take(1),
      map(profiles => {
        const userProfileIds = Object.values(profileIds);
        const commonProfiles = profiles.profiles.filter(
          profile => userProfileIds.every(userprofiles => userprofiles.includes(profile.id))
        );
        if (!commonProfiles.length) {
          this.toasterService.warning('ADMIN.COPY_USERSETTINGS.NO_SHARED_PROFILES');
        }
        return commonProfiles.map(p => ({
          value: p.id,
          text: p.name
        }));
      }),
      indicate(this._loadingProfileList),
      takeUntil(this._destroy)
    );
  }
}
