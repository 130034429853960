import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MeterManagementMeter } from '@enerkey/clients/meter-management';
import { MeterTagDTO } from '@enerkey/clients/metering';

@Component({
  selector: 'meter-info',
  templateUrl: './meter-info.component.html',
  styleUrls: ['./meter-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterInfoComponent {
  @Input() public meter: MeterManagementMeter;
  @Input() public meterTags: MeterTagDTO[];
}
