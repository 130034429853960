import YearlyConsumptionInputController from './yearly-consumption-input-controller';
import template from 'raw-loader!./yearly-consumption-input.html';

const YearlyConsumptionInputComponent = {
  template: template,
  controllerAs: 'vm',
  transclude: true,
  controller: YearlyConsumptionInputController,
  bindings: {
    valueSum: '<',
    quantityUnit: '<',
    numberOfDecimals: '<',
    quantityId: '<',
    isEmptyYear: '<',
    selectedYear: '<',
    onCancel: '&',
    onSaveValues: '&',
    saveValuesExtraParams: '<',
    convertValues: '<',
    isLocked: '<'
  }
};

export default YearlyConsumptionInputComponent;
