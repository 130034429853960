import { Component } from '@angular/core';
import { AddOperationType } from '../../../admin/shared/add-operation-type';
import { TreeViewItem } from '@enerkey/clients/metering';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { AddMetersParams } from '../../shared/add-meters-params';
import { TargetMeter } from '../../shared/target-meter';
import { ModalBase } from '@enerkey/foundation-angular';

@Component({
  selector: 'add-meters-modal',
  templateUrl: './add-meters-modal.component.html'
})
export class AddMetersModalComponent extends ModalBase<AddMetersParams> {
  public initialEnegiaId: number;
  public additionType: AddOperationType;
  public itemAddContext: TreeViewItem;

  private newMeters: TargetMeter[] = [];

  public constructor(activeModal: NgfActiveModal) {
    super(activeModal);
  }

  public meterSelectionChanged(meters: TargetMeter[]): void {
    this.newMeters = meters;
  }

  public saveClicked(): void {
    super.closeModal({
      meters: this.newMeters,
      additionType: this.additionType,
      itemAddContext: this.itemAddContext,
    });
  }

  public cancelClicked(): void {
    super.dismissModal();
  }
}
