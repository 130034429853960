<modal-view-header [headingText]="entry.title"></modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
>
  <markdown
    *ngIf="content$ | async as content"
    [data]="content"
  ></markdown>
</div>
<div class="modal-footer">
  {{ entry.published | date:'shortDate' }}
</div>
