<kendo-grid
  [kendoGridBinding]="terminalMeters"
  [pageSize]="pageSize"
  [pageable]="true"
  [sortable]="true"
  [resizable]="true"
  [height]="800"
  filterable="menu"
>
  <ng-template kendoGridToolbarTemplate>
    <kendo-grid-grouped-column-chooser></kendo-grid-grouped-column-chooser>
    <button kendoGridExcelCommand>{{ 'ADMIN.GET_EXCEL' | translate }}</button>
  </ng-template>

  <kendo-grid-column
    field="ordinal"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.CHANNEL' | translate }}"
    [width]="15"
    [filterable]="false"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="meterId"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.METER_ID' | translate }}"
    [width]="20"
    [filterable]="false"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="meterName"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.METER_NAME' | translate }}"
    [width]="35"
    [filterable]="false"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="enegiaId"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.ENEGIA_ID' | translate }}"
    [width]="20"
    [filterable]="false"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="facilityName"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.FACILITY_NAME' | translate }}"
    [width]="35"
    [filterable]="false"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="externalMeterId"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.EXT_METER_ID' | translate }}"
    [width]="30"
    [filterable]="false"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="tenantName"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.TENANT_NAME' | translate }}"
    [width]="30"
    [filterable]="false"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="statusText"
    title="{{ 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID_TITLE.STATUS' | translate }}"
    [width]="20"
    [filterable]="false"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem="dataItem"
    >
      <button
        *ngIf="dataItem.statusIndicator !== null"
        class="button button--link button--no-focus {{ dataItem.statusIndicator }}"
        (click)="showMeterInterfaceStatus(dataItem)"
      >
        {{ dataItem.statusText }}
      </button>
      <div *ngIf="dataItem.statusIndicator === null">{{ dataItem.statusText }}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel
    [fileName]="excelFileName"
    [excelDataSource]="terminalMeters"
  ></kendo-grid-excel>
</kendo-grid>
