<div (clickOutside)="closeSearch()">
  <a
    class="topbar-icon-item"
    [ngfTooltip]="'TOPBAR.SEARCH' | translate"
    [placement]="'bottom'"
    [alignment]="'left'"
    (click)="toggleSearch()"
  >
    <i class="fa fa-search"></i>
  </a>
  <topbar-search-input
    *ngIf="showSearch$ | async"
    (closeClick)="closeSearch()"
    [class.isMobile]="isMobile"
  ></topbar-search-input>
</div>
