<modal-view-header [headingText]="'ADMIN.BULK_MODIFY_SERVICES.MODIFY_SERVICES_BUTTON' | translate"></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="serviceMassEditForm"
    (ngSubmit)="submit()"
    id="updateForm">
    <div class="formInputs">
      <servicetype-multi-select
        *labelBefore="'ADMIN.BULK_MODIFY_SERVICES.ADD_SERVICES' | translate"
        formControlName="addServices">
      </servicetype-multi-select>
      <servicetype-multi-select
        *labelBefore="'ADMIN.BULK_MODIFY_SERVICES.REMOVE_SERVICES' | translate"
        formControlName="removeServices">
      </servicetype-multi-select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="updateForm"
    >
      {{ 'ADMIN.BULK_MODIFY_SERVICES.SAVE_BUTTON' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>