import { AggregateDescriptor, AggregateResult } from '@progress/kendo-data-query';

import { FacilityInformationGroup } from '@enerkey/clients/energy-reporting';
import { RequestResolution } from '@enerkey/clients/reporting';

import { Quantities } from '@enerkey/clients/metering';

import {
  normalizedValuesPropertyKey,
  TableReportValueKey,
  tableReportValueKeys,
} from '../constants/table-report-constants';
import { ReportingSearchParams } from './reporting-search-params';
import { ReportingSeriesByFacility } from './reporting-series-collection';
import { ConsumptionLike, ReportingSeries, ReportingSerieType } from './reporting-series';

export type QuantityValuesBySerieType = { [key: number]: { [key: string]: Record<string, ConsumptionLike> } };
export type FacilitySingleTypeQuantityValues = { [key: string]: QuantityValuesBySerieType };

export type FacilityQuantityValues = Record<TableReportValueKey, FacilitySingleTypeQuantityValues>;

export interface SeriesByType {
  serieType: ReportingSerieType,
  series: ReportingSeries[],
}

export interface QuantitySeriesByType {
  quantityId: Quantities,
  isNormalized: boolean,
  unit: string,
  series: SeriesByType[]
}

export function mapQuantityDataByFacilities(
  facilities: FacilityInformationGroup[],
  facilityData: ReportingSeriesByFacility,
  params: ReportingSearchParams
): FacilityQuantityValues {
  return tableReportValueKeys.reduce((dataOfType, dataKey) => {
    dataOfType[dataKey] = groupQuantityValuesByFacilities(
      facilities, params, facilityData, dataKey === normalizedValuesPropertyKey
    );
    return dataOfType;
  }, {} as FacilityQuantityValues);
}

export function getTableReportColumns(facilityData: ReportingSeriesByFacility): QuantitySeriesByType[] {
  const seriesByQuantity = Object.values(facilityData).flat().toGroupsBy(c => c.quantityId);

  const columnsByQuantityAndSerieType: QuantitySeriesByType[] = [];

  for (const [quantityId, series] of seriesByQuantity.entries()) {
    const unit = series[0].unit;
    const visibleSeries = series
      .flatMap(c => c.series)
      .filter(s => s.isShownInTable);

    [false, true].forEach(isNormalized => {
      const columns = getMeasuredOrNormalizedSeries(visibleSeries, isNormalized);
      if (Array.hasItems(columns)) {
        columnsByQuantityAndSerieType.push({ quantityId, series: columns, isNormalized: isNormalized, unit });
      }
    });
  }
  return columnsByQuantityAndSerieType;
}

function getMeasuredOrNormalizedSeries(
  series: ReportingSeries[], isNormalized: boolean
): { serieType: ReportingSerieType, series: ReportingSeries[] }[] {
  const filteredSeries = series.filter(s => !!s.options.isNormalized === isNormalized);
  const quantitySeriesByType = filteredSeries.toGroupsBy(s => s.options.serieType);
  const seriesByType: { serieType: ReportingSerieType, series: ReportingSeries[] }[] = [];
  for (const [serieType, seriesOfType] of quantitySeriesByType) {
    seriesByType.push({
      serieType,
      series: seriesOfType.sortByMany(s => s.serieStart, [s => s.isChangeVisible, 'desc']).uniqueBy(s => s.gridTitle)
    });
  }
  return seriesByType;
}

function groupQuantityValuesByFacilities(
  facilities: FacilityInformationGroup[],
  params: ReportingSearchParams,
  dataByFacilities: ReportingSeriesByFacility,
  isNormalized: boolean
): FacilitySingleTypeQuantityValues {
  return facilities.reduce<FacilitySingleTypeQuantityValues>((data, f) => {
    data[f.FacilityId] ??= {};
    const current = data[f.FacilityId];

    const quantityMappedDataForFacility = dataByFacilities?.[f.FacilityId]?.toRecord(c => c.quantityId);
    for (const q of params.quantityIds) {
      current[q] = {};
      const seriesByType = quantityMappedDataForFacility?.[q]?.series
        .filter(s => s.isShownInTable)
        .filter(s => !!s.options.isNormalized === isNormalized)
        .toGroupsBy(s => s.options.serieType) ?? new Map<ReportingSerieType, ReportingSeries[]>();
      for (const [serieType, series] of seriesByType.entries()) {
        current[q][serieType] = series.toRecord(s => s.serieStart, s => s.values[0]);
      }
    }
    return data;
  }, {});
}

export function requestResolutionByCurrentMonth(currentMonth: number): RequestResolution {
  switch (currentMonth) {
    case 0: return RequestResolution.P1M;
    case 1: return RequestResolution.P2M;
    case 2: return RequestResolution.P3M;
    case 3: return RequestResolution.P4M;
    case 4: return RequestResolution.P5M;
    case 5: return RequestResolution.P6M;
    case 6: return RequestResolution.P7M;
    case 7: return RequestResolution.P8M;
    case 8: return RequestResolution.P9M;
    case 9: return RequestResolution.P10M;
    case 10: return RequestResolution.P11M;
    default: return RequestResolution.P1Y;
  }
}

export function getUnsupportedParamsInfo({ duration, periods }: ReportingSearchParams): string {
  // If period is year or month backend requires start date to be month first
  const isYearOrMonthPeriod = !!(duration.years || duration.months);
  if (isYearOrMonthPeriod && periods?.some(date => date.getDate() !== 1)) {
    return 'REPORTING.ERRORS.UNSUPPORTED_TABLE_REPORT_START_DATE';
  }
  return null;
}

/**
  * Sanitize the aggregate result to ensure that all values are finite numbers.
  */
export function sanitizeAggregateResult(aggregatedValues: AggregateResult): AggregateResult {
  const obj: AggregateResult = {};
  for (const [key, value] of Object.entries(aggregatedValues)) {
    obj[key] = {};
    for (const [k, v] of Object.entries(value) as [AggregateDescriptor['aggregate'], number][]) {
      obj[key][k] = Number.isFinite(v) ? v : 0;
    }
  }
  return obj;
}
