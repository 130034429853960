import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Quantities } from '@enerkey/clients/metering';

import { ReportingSeries, ReportSeriesDataPoint } from '../shared/reporting-series';
import { ReportingSeriesByFacility, ReportingSeriesCollection } from '../shared/reporting-series-collection';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ReportingData } from './reporting-data-service-base';

@Injectable({
  providedIn: 'root'
})
export class ReportingSeriesService {

  public constructor(
    private readonly translateService: TranslateService
  ) {
  }

  public mapValuesById({
    ids,
    measured,
    normalized,
    nationalCosts,
    meterBasedCosts,
    emissions,
    targets,
    temperatures,
    comparability,
    incompleteThreshold,
    facilityAmountsPerQuantity,
    periods
  }: {
    ids: number[],
    measured: ReportingData[],
    normalized: ReportingData[],
    targets?: ReportingData[],
    temperatures?: ReportingData[],
    nationalCosts?: ReportingData[],
    meterBasedCosts?: ReportingData[],
    emissions?: ReportingData[],
    comparability?: Comparability,
    incompleteThreshold?: number,
    periods?: Date[]
    /** Amount of total and applicable facilities, used only in sum reports */
    facilityAmountsPerQuantity?: Partial<Record<Quantities, { total: number; applicable: number }>>,
  }): ReportingSeriesByFacility {
    return ids.toRecord(
      id => id,
      id => this.getSeriesValues({
        id,
        incompleteThreshold,
        comparability,
        values: [...measured, ...normalized],
        targets: targets ?? [],
        temperatures: temperatures ?? [],
        nationalCosts: nationalCosts ?? [],
        meterBasedCosts: meterBasedCosts ?? [],
        emissions: emissions ?? [],
        facilityAmountsPerQuantity: facilityAmountsPerQuantity,
        periods
      }).sortBy('quantityId')
    );
  }

  private getSeriesValues({
    id,
    incompleteThreshold,
    values,
    targets,
    temperatures,
    nationalCosts,
    meterBasedCosts,
    emissions,
    comparability,
    facilityAmountsPerQuantity,
    periods
  }: {
    id: number
    incompleteThreshold: number,
    values: ReportingData[],
    targets: ReportingData[],
    temperatures: ReportingData[],
    nationalCosts: ReportingData[],
    meterBasedCosts: ReportingData[],
    emissions: ReportingData[],
    comparability: Comparability,
    facilityAmountsPerQuantity: Partial<Record<Quantities, { total: number; applicable: number }>>,
    periods?: Date[]
  }): ReportingSeriesCollection[] {
    const allSeries: { quantityId: Quantities, series: ReportingSeries[] }[] = [];
    for (const [quantityId, series] of values.toGroupsBy(v => v.quantityId).entries()) {
      const facilitySeries = series.flatMap(s => s.series[id]).filter(s => s);
      const facilityTargets = targets.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      const facilityTemperatures = temperatures.flatMap(s => s.series[id] ?? []);
      const facilityCosts = nationalCosts.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      const facilityMeterBasedCosts = meterBasedCosts.find(t => t.quantityId === quantityId)?.series?.[id] ?? [];
      const facilityEmissions = emissions.filterMap(
        t => t.quantityId === quantityId,
        t => t.series?.[id] ?? []
      ).flat();
      const inspectionPeriod = facilitySeries.find(s => s.options.isInspectionPeriod);
      if (inspectionPeriod) {
        const inspectionPeriodValues = inspectionPeriod.values;
        for (const serie of facilityTargets) {
          serie.calculateChanges(inspectionPeriodValues, true);
        }
      }

      const minMaxSeries = this.getMinMaxSeries(facilitySeries);
      if (Array.hasItems(facilitySeries)) {
        allSeries.push(new ReportingSeriesCollection({
          quantityId,
          series: [
            ...facilitySeries.sortBy(s => s.options.isComparisonPeriod ? -1 : 1),
            ...minMaxSeries,
            ...facilityTargets,
            ...facilityTemperatures,
            ...facilityCosts,
            ...facilityMeterBasedCosts,
            ...facilityEmissions
          ],
          facilityAmounts: facilityAmountsPerQuantity?.[quantityId],
        }));
      }
    }

    let seriesWithoutIncompletes = this.getSeriesWithoutIncompleteValues(
      allSeries,
      comparability,
      incompleteThreshold
    );

    if (Array.isArray(periods) && periods.every(period => period instanceof Date)) {
      seriesWithoutIncompletes = seriesWithoutIncompletes.map(s => ({
        ...s,
        series: this.sortByComsumptionType(s.series, periods)
      }));
    }

    return seriesWithoutIncompletes.map(s => new ReportingSeriesCollection({
      quantityId: s.quantityId,
      series: s.series,
      facilityAmounts: facilityAmountsPerQuantity?.[s.quantityId]
    }));
  }

  private getMinMaxSeries(series: ReportingSeries[]): ReportingSeries[] {
    if (series.some(s => s.options.isComparisonPeriod)) {
      return [];
    }
    const inspectionSeries = series.filter(s => s.isMinMaxApplicableSeries);

    if (!Array.hasItems(inspectionSeries)) {
      return [];
    }

    const minValueCandidates = inspectionSeries.map(s => s.minValue);
    const minValue = Math.min(...minValueCandidates);
    const minValueSerieIndex = inspectionSeries.findIndex(
      s => s.minValue === minValue
    );
    const minValueSerie = inspectionSeries[minValueSerieIndex];

    const maxValueCandidates = inspectionSeries.map(s => s.maxValue);
    const maxValue = Math.max(...maxValueCandidates);
    const maxValueSerieIndex = inspectionSeries.findIndex(
      s => s.maxValue === maxValue
    );
    const maxValueSerie = inspectionSeries[maxValueSerieIndex];

    return [
      new ReportingSeries({
        chartOptions: { serieType: 'line', showMarkers: true },
        gridOptions: { hideInGrid: true },
        options: {
          quantityId: maxValueSerie.options.quantityId,
          color: 'red',
          unit: maxValueSerie.options.unit,
          serieTitle: this.translateService.instant('FACILITIES_REPORT.MAX'),
          serieType: 'max',
        },
        consumptions: maxValueSerie.consumptions.map((v, index) => ({
          value: index === maxValueSerie.maxValueIndex ? v.value : null,
          timestamp: v.timestamp
        }))
      }),
      new ReportingSeries({
        chartOptions: { serieType: 'line', showMarkers: true },
        gridOptions: { hideInGrid: true },
        options: {
          quantityId: minValueSerie.options.quantityId,
          color: 'green',
          unit: minValueSerie.options.unit,
          serieTitle: this.translateService.instant('FACILITIES_REPORT.MIN'),
          serieType: 'min',
        },
        consumptions: minValueSerie.consumptions.map((v, index) => ({
          value: index === minValueSerie.minValueIndex ? v.value : null,
          timestamp: v.timestamp
        }))
      })
    ];
  }

  private getSeriesWithoutIncompleteValues(
    series: { quantityId: Quantities, series: ReportingSeries[] }[],
    comparabilityOption: Comparability = Comparability.All,
    threshold: number
  ): { quantityId: Quantities, series: ReportingSeries[] }[] {
    switch (comparabilityOption) {
      case Comparability.All:
        return series;
      case Comparability.ByQuantity: {
        return series.map(s => {
          const incompleteIndexes = this.getIncompleteValueIndexes(s.series, threshold);
          return {
            quantityId: s.quantityId,
            series: s.series.map(serie =>
              serie.fromValues({
                consumptions: serie.values.map((v, index) =>
                  incompleteIndexes.has(index) || this.isComparableData(v, s.series)
                    ? { ...v, value: null }
                    : v)
              }))
          };
        });
      }
      case Comparability.ByQuantities: {
        const incompleteIndexes = this.getIncompleteValueIndexes(series.flatMap(s => s.series), threshold);

        return series.map(s => ({
          quantityId: s.quantityId,
          series: s.series.map(serie => serie.fromValues({
            consumptions: serie.values.map((v, index) =>
              incompleteIndexes.has(index) || this.isComparableData(v, series.flatMap(ser => ser.series))
                ? { ...v, value: null }
                : v)
          }))
        }));
      }
    }
  }

  private getIncompleteValueIndexes(series: ReportingSeries[], threshold: number): Set<number> {
    const incompleteIndexes = new Set<number>();
    series.forEach(serie => {
      for (const [index, value] of serie.values.entries()) {
        if (value.incomplete > threshold) {
          incompleteIndexes.add(index);
        }
      }
    });

    return incompleteIndexes;
  }

  private isComparableData(seriesValue: ReportSeriesDataPoint, series: ReportingSeries[]): boolean {
    const targetMonth = new Date(seriesValue.timestamp).getMonth();

    return series[1].values.some(val =>
      new Date(val.timestamp).getMonth() === targetMonth && val.value === null);
  }

  private sortByComsumptionType(series: ReportingSeries[], selectedPeriods: Date[]): ReportingSeries[] {
    const years = selectedPeriods.map(date => date.getFullYear()).sort((a, b) => a - b);
    return series.sortBy(s => {
      const year = new Date(s.values[0]?.timestamp).getFullYear();
      return years.indexOf(year);
    });
  }

}
