import ManualQaActionsController from './manual-qa-actions-controller';
import template from 'raw-loader!./manual-qa-actions.html';

export default {
  template: template,
  controller: ManualQaActionsController,
  controllerAs: 'vm',
  bindings: {
    dataSource: '<',
    operationTimeFrame: '<',
    actualTimeFrame: '<',
    meter: '<',
    isPreviewMode: '<',
    isLoading: '<',
    fetchType: '<',
    useComparisonPeriod: '<',
    noDefect: '<',
    getReadings: '&',
    createPreview: '&',
    clearPreview: '&',
    saveChanges: '&',
    lockModel: '&',
    deleteModel: '&',
    deleteConsumption: '&',
    getHourlyModel: '&'
  }
};
