<kendo-dropdownlist
  [data]="statuses"
  [valueField]="'value'"
  [textField]="'value'"
  [valuePrimitive]="true"
  [loading]="loading"
  [disabled]="loading"
  [(ngModel)]="value"
  [readonly]="readonly"
  (ngModelChange)="valueChanged($event)"
  (blur)="blur()"
  [popupSettings]="{ animate: false }"
>
  <alarm-log-status-label
    *kendoDropDownListItemTemplate="let dataItem"
    [status]="dataItem.value"
    [small]="true"
  ></alarm-log-status-label>
  <!-- dataItem is null for a brief moment before initialization -->
  <alarm-log-status-label
    *kendoDropDownListValueTemplate="let dataItem"
    [status]="dataItem?.value"
    [small]="true"
  ></alarm-log-status-label>
</kendo-dropdownlist>
