<ng-template #topbarTemplate>
  <div class="content-right float-right">
    <facility-action-popup
      [allFacilities]="allFacilities$ | async"
      [selectedFacilities]="selectedFacilities$ | async"
      (repeatSearch)="repeatSearch()"
    ></facility-action-popup>
    <button
      class="button"
      (click)="openCreateModal()"
    >
      <i class="fa fa-plus"></i> {{ 'ADMIN.CREATE_FACILITIES' | translate }}
    </button>
  </div>
</ng-template>
<div class="row">
  <div class="large-2 medium-3 columns">
    <facility-management-search></facility-management-search>
  </div>
  <div class="large-10 medium-9 columns">
    <facility-management-grid></facility-management-grid>
  </div>
</div>
