import { ComponentFactoryResolver, Injectable, Injector, TemplateRef } from '@angular/core';

import { NgfModalConfig, NgfModalOptions } from './modal-config';
import { NgfModalRef } from './modal-ref';
import { NgfModalStack } from './modal-stack';
import { getModalOptions } from './modal-base';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

type Class = { new(...args: any[]): any };
type ComponentClassType<T> = T extends Class ? InstanceType<T> : unknown;
type Classible<T> = T extends Class ? T : never;
type ModalContentType<T extends Class> = T | string | TemplateRef<any>;

/**
 * A service for opening modal windows.
 *
 * Creating a modal is straightforward: create a component or a template and pass it as an argument to
 * the `.open()` method.
 */
@Injectable({ providedIn: 'root' })
export class ModalService {
  public constructor(
    private readonly _moduleCFR: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _modalStack: NgfModalStack,
    private readonly _config: NgfModalConfig
  ) { }

  /**
   * Opens a new modal window with the specified content and supplied options.
   *
   * Content can be provided as a `TemplateRef` or a component type. If you pass a component type as content,
   * then instances of those components can be injected with an instance of the `NgfActiveModal` class. You can then
   * use `NgfActiveModal` methods to close / dismiss modals from "inside" of your component.
   *
   * Also see the [`NgfModalOptions`](#/components/modal/api#NgfModalOptions) for the list of supported options.
   */
  public open<T, E extends ComponentClassType<T>>(
    content: ModalContentType<Classible<T>>,
    options: NgfModalOptions = {}
  ): NgfModalRef<E> {
    const combinedOptions = {
      ...this._config,
      ...getModalOptions(content),
      ...(options ?? {}),
    };

    return this._modalStack.open(this._moduleCFR, this._injector, content, combinedOptions);
  }

  /**
   * Dismisses all currently displayed modal windows with the supplied reason.
   */
  public dismissAll(reason?: any): void {
    this._modalStack.dismissAll(reason);
  }

  /**
   * Indicates if there are currently any open modal windows in the application.
   */
  public hasOpenModals(): boolean {
    return this._modalStack.hasOpenModals();
  }
}
