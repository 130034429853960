<div class="dual-radiobutton-wrapper">
  <label>
    <input
      [value]="energyMeasurementTypes.ENERGY_MEASUREMENT"
      formControlName="datatype"
      type="radio"
      kendoRadioButton
      required
      checked
    />
    {{ 'ANALYTICS.ETCURVE.ENERGY_MEASUREMENT' | translate }}
  </label>
  <label>
    <input
      [value]="energyMeasurementTypes.SPECIFIC_ENERGY"
      formControlName="datatype"
      type="radio"
      kendoRadioButton
      required
    />
    {{ 'ANALYTICS.ETCURVE.SPECIFIC_CONSUMPTION' | translate }}
  </label>
</div>

<div *ngIf="areaTypes$ | async as areaTypes">
  <div *ngIf="controls.datatype.value === energyMeasurementTypes.SPECIFIC_ENERGY">
    <p>
      <ek-combo
        [items]="areaTypes"
        *labelBefore="'ANALYTICS.ETCURVE.AREA_TYPE' | translate"
        formControlName="specificId"
      ></ek-combo>
    </p>
  </div>
</div>
