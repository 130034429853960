import _ from 'lodash';

import * as ERField from './er-field.functions';
import { getSortNumber } from './er-data-grid-sorter';
import EnergyReportingConstants from '../../constants/report-constants';
import { isAverageCost, isCost, isEmission } from '../../../reportingobjects/shared/relational-value-functions';

const $inject = [
  'utils',
  'ERReportType',
  'ERDataFunctions',
  'ERDataColumn',
  'ERDataSumFunctions',
  'ERConsumptionTargetColumn',
  'erStateService',
  'ColorService'
];

class ErGridQuantityColumns {
  constructor(
    utils,
    ERReportType,
    ERDataFunctions,
    ERDataColumn,
    ERDataSumFunctions,
    ERConsumptionTargetColumn,
    erStateService,
    colorService
  ) {
    this.utils = utils;
    this.ERReportType = ERReportType;
    this.ERDataFunctions = ERDataFunctions;
    this.ERDataColumn = ERDataColumn;
    this.ERDataSumFunctions = ERDataSumFunctions;
    this.ERConsumptionTargetColumn = ERConsumptionTargetColumn;
    this.erStateService = erStateService;
    this.colorService = colorService;

    this.emptyStringSumAggregate = {
      sum: function() {
        return '';
      }
    };
  }

  createQuantityCategoryColumn(options, data) {
    const field = ERField.getQuantityCategoryField(data);
    let categoryColumn = _.find(data.gridOptions.columns, { field: field });
    if (!categoryColumn) {
      const title = this.getQuantityCategoryColumnTitle(data);
      categoryColumn = {
        field: field,
        normalized: data.normalized,
        title: title,
        groupable: false,
        columns: [],
        quantityId: data.quantityId,
        headerAttributes: { title: title }
      };
      data.gridOptions.columns.push(categoryColumn);
    }
    this.createQuantityCategoryChildColumns(options, data, categoryColumn);
  }

  createWeatherCategoryColumn(options, data) {
    const field = ERField.getWeatherCategoryField();
    let categoryColumn = _.find(data.gridOptions.columns, { field: field });
    if (!categoryColumn) {
      const title = `${this.utils.localizedString('FACILITIES.SIDEBAR.TEMPERATURE')} [°C]`;
      categoryColumn = {
        field: field,
        title: title,
        groupable: false,
        columns: [],
        headerAttributes: { title: title }
      };
      data.gridOptions.columns.push(categoryColumn);
    }

    // add child columns
    if (options.series.Temperature) {
      _.each(options.series.Start, (start, startIndex) => {
        // data for start
        const dataForStart = this.ERDataFunctions.getDataForStart(options, data, startIndex);
        categoryColumn.columns.push(this.getTemperatureColumn(options, dataForStart));
      });
    }
  }

  getQuantityCategoryColumnTitle(data) {
    const prefix = data.normalized ? `${this.utils.localizedString('FACILITIES.NORMALIZED')} ` : '';
    return `${prefix}${data.quantity.Name} [${data.unit}]`;
  }

  createQuantityCategoryChildColumns(options, data, categoryColumn) {
    // child columns
    let readingColumns = [];
    let compareColumns = [];
    let relationalValueColumns = [];
    let distributionColumns = [];
    let relatedValueColumns = [];

    // report type
    const reportType = this.ERDataFunctions.getReportType(options, data.apiIndex);

    if (options.api[data.apiIndex].requestData.TargetSeriesType) {
      // CONSUMPTION TARGET
      const consumptionTargets = this.ERConsumptionTargetColumn.getColumns(options, data);
      readingColumns.push(...consumptionTargets.readingColumns);
      compareColumns.push(...consumptionTargets.compareColumns);
    } else {
      // READING
      _.each(options.series.Start, (start, startIndex) => {
        if (!options.series.DistributionId || data.normalized || options.show.distributionAndValue) {
          const dataForStart = this.ERDataFunctions.getDataForStart(options, data, startIndex);

          // reading columns
          readingColumns.push(this.getReadingColumn(options, dataForStart));

          // compare columns
          switch (reportType) {
            case this.ERReportType.TREND_REPORT:
              // please note that trend compare columns are pushed to readingColumns (because of ordering)
              readingColumns = readingColumns.concat(this.getTrendCompareColumns(options, dataForStart));
              break;
            case this.ERReportType.FORECAST_REPORT:
            // let this fall into default
            // eslint-disable-next-line no-fallthrough
            default:
              compareColumns = compareColumns.concat(this.getCompareColumns(options, dataForStart));
              break;
          }
        }
      });
    }

    // Create supplementary columns only for default readings (i.e. not for consumption targets)
    if (this.ERDataFunctions.getApiIndexForReadingValues(options) === data.apiIndex) {
      // DISTRIBUTION
      if (options.series.DistributionId && options.cache.distributionTypesById) {
        const distributionType = options.cache.distributionTypesById[options.series.DistributionId];
        const distributions = distributionType ? distributionType.Distributions : [];
        _.each(distributions, (distribution, index) => {
          let distributionCompareColumns = [];
          _.each(options.series.Start, (start, startIndex) => {
            // data for start
            const dataForStart = _.extend(
              {
                propertyData: {
                  object: distribution,
                  index: index
                }
              },
              this.ERDataFunctions.getDataForStart(options, data, startIndex)
            );

            // distribution type columns
            distributionColumns = distributionColumns.concat(this.getDistributionColumns(options, dataForStart));

            // distribution compare columns
            switch (reportType) {
              case this.ERReportType.TREND_REPORT:
                distributionCompareColumns = distributionCompareColumns.concat(
                  this.getDistributionTrendCompareColumns(options, dataForStart)
                );
                break;
              case this.ERReportType.FORECAST_REPORT:
              // let this fall into default
              // eslint-disable-next-line no-fallthrough
              default:
                distributionCompareColumns = distributionCompareColumns.concat(
                  this.getDistributionCompareColumns(options, dataForStart)
                );
                break;
            }
          });
          distributionColumns = distributionColumns.concat(distributionCompareColumns);
        });
      }

      // RELATIONAL VALUE
      if (_.isArray(options.series.RelationalUnitIds) && (data.quantity.Relational || data.quantity.Emission)) {
        _.each(options.series.RelationalUnitIds, (relationalValueId, index) => {
          const relationalValue = options.cache.relationalValuesById[relationalValueId];
          let dataForStart;
          if (relationalValue) {
            _.each(options.series.Start, (start, startIndex) => {
              const isFollowUp = startIndex === options.series.Start.length - 1;
              if (
                (options.show.relationalValueOnlyForFollowUp && isFollowUp) ||
                !options.show.relationalValueOnlyForFollowUp
              ) {
                // data for start
                dataForStart = _.extend(
                  {
                    propertyData: {
                      object: relationalValue,
                      index: index,
                      unit: _.get(relationalValue, `UnitsForQuantities.Default.${data.quantityId}`)
                    }
                  },
                  this.ERDataFunctions.getDataForStart(options, data, startIndex)
                );

                // relational value column
                relationalValueColumns =
                  relationalValueColumns.concat(this.getRelationalValueColumn(options, dataForStart));
                compareColumns =
                  compareColumns.concat(this.getCompareColumns(options, dataForStart, relationalValueId));
              }
            });
          }
        });
      }

      // RELATED VALUE
      if (_.isArray(options.series.RelatedValues) && !data.normalized) {
        _.each(options.cache.relatedValuesByQuantityId[data.quantityId], (relatedValue, index) => {
          if (_.includes(options.series.RelatedValues, relatedValue.Id)) {
            _.each(options.series.Start, (start, startIndex) => {
              const isFollowUp = startIndex === options.series.Start.length - 1;
              if (
                (options.show.relatedValueOnlyForFollowUp && isFollowUp) ||
                !options.show.relatedValueOnlyForFollowUp
              ) {
                // data for start
                const dataForStart = _.extend(
                  {
                    propertyData: {
                      object: relatedValue,
                      index: index,
                      unit: relatedValue.UnitName
                    }
                  },
                  this.ERDataFunctions.getDataForStart(options, data, startIndex)
                );

                // related value column
                relatedValueColumns = relatedValueColumns.concat(this.getRelatedValueColumn(options, dataForStart));
              }
            });
          }
        });
      }
    }

    // append all columns to category
    const allColumns = readingColumns
      .concat(compareColumns)
      .concat(distributionColumns)
      .concat(relationalValueColumns)
      .concat(relatedValueColumns);

    // remove duplicate columns
    categoryColumn.columns = _.reduce(
      allColumns,
      (result, column) => result.concat(!_.find(result, { field: column.field }) ? [column] : []),
      categoryColumn.columns
    );
  }

  getReadingColumn(options, data) {
    const field = this.ERDataFunctions.getReadingField(options, data);
    const title = this.ERDataColumn.getTitle(options, data);
    const getTemplate = this.getTemplate;

    const column = {
      field: field,
      hidden: this.ERDataColumn.hideMeasurement(options),
      sort: getSortNumber(EnergyReportingConstants.ColumnTypeOrder.ReadingColumn, data.apiIndex),
      sortDate: data.startInfo.value,
      title: title,
      groupable: false,
      headerAttributes: {
        class: this.ERDataColumn.getModelledCount(data.consumptions, data.startInfo.value)
          ? 'k-grid-column-Modelled'
          : ''
      },
      headerTemplate: this.ERDataColumn.getReadingHeaderTemplate(options, data),
      width: EnergyReportingConstants.Dimensions.gridColumn.value[data.wideColumns ? 'wide' : 'narrow'],
      attributes: {
        class: 'cell-number'
      },
      template: function(row) {
        return getTemplate(options, row, field, data.format);
      }
    };

    this.ERDataFunctions.addAggregates(options, data, column, data.format);

    return column;
  }

  getCompareColumns(options, data, relationalValueId) {
    const columns = [];
    const fields = {
      Percent: options.params.changeType === 'both' || options.params.changeType === 'relative',
      Change: options.params.changeType === 'both' || options.params.changeType === 'absolute'
    };
    // Use different sorting for consumption and relational value comparison columns.
    const sortParams = relationalValueId
      ? [
        EnergyReportingConstants.ColumnTypeOrder.RelationalValueColumn,
        data.propertyData.object.Id,
        data.apiIndex,
        1
      ]
      : [
        EnergyReportingConstants.ColumnTypeOrder.CompareColumn,
        data.apiIndex,
        0,
        1
      ];

    if (data.previousStartInfo) {
      const width = EnergyReportingConstants.Dimensions.gridColumn.compare[data.wideColumns ? 'wide' : 'narrow'];
      _.each(fields, (value, key) => {
        if (value) {
          const isPercent = key === 'Percent';
          const field = ERField.getCompareField(data, key, relationalValueId);
          const hidden = relationalValueId ? false : this.ERDataColumn.hideMeasurement(options);
          const title = this.ERDataColumn.getCompareTooltipTitle(options, data, isPercent);
          const format = isPercent ? 'p1' : data.format;
          const getTemplate = this.getTemplate;
          const column = {
            field: field,
            hidden: hidden,
            title: title,
            sort: getSortNumber(...sortParams),
            groupable: false,
            sortDate: data.startInfo.value,
            headerTemplate: this.ERDataColumn.getCompareHeaderTemplate(options, data, isPercent),
            template: function(row) {
              return getTemplate(options, row, field, format);
            },
            width: isPercent ? width + 10 : width,
            attributes: {
              class: 'cell-number'
            },
            format: `{0:${format}}`,
            serie: this.ERDataFunctions.getReadingField(
              options,
              data,
              data.controlStartInfo,
              data.apiIndex,
              relationalValueId
            ),
            previousSerie: this.ERDataFunctions.getReadingField(
              options,
              data,
              data.previousStartInfo,
              data.apiIndex,
              relationalValueId
            )
          };
          this.ERDataFunctions.addAggregates(options, data, column, format, {
            sum: this.ERDataFunctions.getComparisonColumnSumFunction(column, key)
          });
          columns.push(column);
        }
      });
    }
    return columns;
  }

  getTrendCompareColumns(options, data) {
    const columns = [];
    const fields = {
      Percent: options.params.changeType === 'both' || options.params.changeType === 'relative',
      Change: options.params.changeType === 'both' || options.params.changeType === 'absolute'
    };

    _.each(fields, (value, key) => {
      if (value) {
        const field = ERField.getTrendCompareField(options, data, key);
        const text = this.utils.localizedString('CHANGE.TITLE') + (key === 'Percent' ? ' %' : '');
        const title = `${data.startInfo.key} ${text}`;
        const format = key === 'Percent' ? 'p1' : data.format;
        const column = {
          hidden: this.ERDataColumn.hideMeasurement(options),
          field: field,
          title: title,
          sort: getSortNumber(
            EnergyReportingConstants.ColumnTypeOrder.TrendCompareColumns,
            data.apiIndex,
            0,
            key === 'Percent' ? 1 : 2
          ),
          sortDate: data.startInfo.value,
          headerTemplate: options.isWidget
            ? void 0
            : function() {
              return (
                `<span tooltip-append-to-body='true' tooltip-placement='left' tooltip='${title}'>` +
                  `<span style='color:${data.startInfo.color}'>■ </span>${text}</span>`
              );
            },
          width: EnergyReportingConstants.Dimensions.gridColumn.compare[data.wideColumns ? 'wide' : 'narrow'],
          attributes: {
            class: 'cell-number'
          },
          format: `{0:${format}}`
        };
        this.ERDataFunctions.addAggregates(options, data, column, format);
        columns.push(column);
      }
    });

    return columns;
  }

  getDistributionColumns(options, data) {
    const columns = [];
    const fieldsForAggregate = [];
    const distribution = _.get(data, 'propertyData.object');

    _.each(['Reading$Value', 'Percent'], type => {
      const field = ERField.getDistributionField(data, type);
      const isPercent = type === 'Percent';
      if (!isPercent) {
        fieldsForAggregate.push(field);
      }
      const suffix = isPercent ? ' %' : '';
      const title = `${data.startInfo.key} ${distribution.Name}${suffix}`;
      const format = isPercent ? 'p1' : data.format;
      const getTemplate = this.getTemplate;
      const column = {
        field: field,
        title: title,
        sort: getSortNumber(
          EnergyReportingConstants.ColumnTypeOrder.DistributionColumns,
          data.propertyData.index,
          data.startInfo.key,
          0
        ),
        sortDate: data.startInfo.value,
        headerTemplate: options.isWidget
          ? void 0
          : function() {
            return (
              `<span tooltip-append-to-body='true' tooltip-placement='left' tooltip='${title}'>` +
                `<span style='color:${data.startInfo.color}'>■ </span>${distribution.Name}${suffix}</span>`
            );
          },
        width: EnergyReportingConstants.Dimensions.gridColumn.value[data.wideColumns ? 'wide' : 'narrow'],
        attributes: {
          class: 'cell-number'
        },
        hidden:
          (isPercent && !options.series.DistributionAsPercent) ||
          (!isPercent && options.series.DistributionAsPercent) ||
          this.ERDataColumn.hideMeasurement(options),
        template: function(row) {
          return getTemplate(options, row, field, format);
        }
      };
      const aggregateFunctions = !isPercent
        ? void 0
        : {
          sum: function(aggregateData) {
            const sum = _.get(aggregateData, `${ERField.getDistributionField(data, 'Reading$Value')}.sum`);
            const allSum = _.reduce(
              fieldsForAggregate,
              (r, f) => {
                r += aggregateData[f] ? aggregateData[f].sum : 0;
                return r;
              },
              0
            );
            return sum && allSum ? sum / allSum : void 0;
          }
        };
      this.ERDataFunctions.addAggregates(options, data, column, format, aggregateFunctions);
      columns.push(column);
    });
    return columns;
  }

  getDistributionCompareColumns(options, data) {
    const columns = [];
    const distribution = _.get(data, 'propertyData.object');
    const fields = {
      Percent: options.params.changeType === 'both' || options.params.changeType === 'relative',
      Change: options.params.changeType === 'both' || options.params.changeType === 'absolute'
    };

    if (data.previousStartInfo) {
      _.each(fields, (value, key) => {
        if (value) {
          const field = ERField.getDistributionCompareField(data, key);
          const isPercent = key === 'Percent';
          const title = this.ERDataColumn.getCompareTooltipTitle(options, data, isPercent, distribution.Name);
          const format = key === 'Percent' ? 'p1' : data.format;
          const column = {
            hidden: this.ERDataColumn.hideMeasurement(options),
            field: field,
            title: title,
            sort: getSortNumber(
              EnergyReportingConstants.ColumnTypeOrder.DistributionColumns,
              data.propertyData.index,
              data.startInfo.key,
              1
            ),
            sortDate: data.startInfo.value,
            headerTemplate: options.isWidget
              ? void 0
              : this.ERDataColumn.getCompareHeaderTemplate(options, data, isPercent, distribution.Name),
            width: EnergyReportingConstants.Dimensions.gridColumn.compare[data.wideColumns ? 'wide' : 'narrow'],
            attributes: {
              class: 'cell-number'
            },
            format: `{0:${format}}`,
            serie: ERField.getDistributionField(data, 'Reading$Value', data.controlStartInfo),
            previousSerie: ERField.getDistributionField(data, 'Reading$Value', data.previousStartInfo)
          };
          this.ERDataFunctions.addAggregates(options, data, column, format, {
            sum: this.ERDataFunctions.getComparisonColumnSumFunction(column, key)
          });
          columns.push(column);
        }
      });
    }
    return columns;
  }

  getDistributionTrendCompareColumns(options, data) {
    const columns = [];
    const distribution = _.get(data, 'propertyData.object');
    const fields = {
      Percent: options.params.changeType === 'both' || options.params.changeType === 'relative',
      Change: options.params.changeType === 'both' || options.params.changeType === 'absolute'
    };

    _.each(fields, (value, key) => {
      if (value) {
        const field = ERField.getDistributionTrendCompareField(data, key);
        const text = this.utils.localizedString('CHANGE.TITLE') + (key === 'Percent' ? ' %' : '');
        const title = `${distribution.Name} ${text}`;
        const format = key === 'Percent' ? 'p1' : data.format;
        const column = {
          hidden: this.ERDataColumn.hideMeasurement(options),
          field: field,
          title: title,
          sort: getSortNumber(
            EnergyReportingConstants.ColumnTypeOrder.DistributionTrendCompareColumn,
            data.apiIndex,
            0,
            key === 'Percent' ? 1 : 2
          ),
          sortDate: data.startInfo.value,
          headerTemplate: options.isWidget
            ? void 0
            : function() {
              return (
                `<span tooltip-append-to-body='true' tooltip-placement='left' tooltip='${
                  data.startInfo.key
                } ${title}'>` + `<span style='color:${data.startInfo.color}'>■ </span>${text}</span>`
              );
            },
          width: EnergyReportingConstants.Dimensions.gridColumn.compare[data.wideColumns ? 'wide' : 'narrow'],
          attributes: {
            class: 'cell-number'
          },
          format: `{0:${format}}`
        };
        this.ERDataFunctions.addAggregates(options, data, column, format);
        columns.push(column);
      }
    });
    return columns;
  }

  getRelationalValueColumn(options, data) {

    const field = ERField.getRelationalValueField(data);
    const relationalValueName = _.get(data, 'propertyData.object.Name');
    const unit = _.get(data, 'propertyData.unit');
    const text = unit ? ` [${unit}]` : '';
    const title = `${this.ERDataColumn.getTitle(options, data)} ${relationalValueName}${text}`;
    const prefix = this.ERDataColumn.getTitlePrefix(options, data);
    const id = _.get(data, 'propertyData.object.Id');
    const getTemplate = this.getTemplate;
    const format = isAverageCost(id) ? 'n4' : 'n1';

    const column = {
      field: field,
      title: title,
      sort: getSortNumber(EnergyReportingConstants.ColumnTypeOrder.RelationalValueColumn, id, data.apiIndex),
      sortDate: data.startInfo.value,
      headerTemplate: options.isWidget
        ? void 0
        : function() {
          return (
            `<span tooltip-append-to-body='true' tooltip-placement='left' tooltip='${title}'>` +
              `<span style='color:${_.get(data, 'startInfo.color')}'>■ </span>${prefix} ${relationalValueName}${
                unit ? ` [${unit}]` : ''
              }</span>`
          );
        },
      width: EnergyReportingConstants.Dimensions.gridColumn.value[data.wideColumns ? 'wide' : 'narrow'],
      attributes: {
        class: 'cell-number'
      },
      template: function(row) {
        return getTemplate(options, row, field, format);
      }
    };

    let aggregateFunctions;
    const relationalValueId = data.propertyData.object.Id;
    const isFacilityReport = Number.isFinite(data.facilityId);
    if (
      !isFacilityReport
        && !isEmission(relationalValueId)
        && (!isCost(relationalValueId) || isAverageCost(relationalValueId))
    ) {
      aggregateFunctions = {
        sum: aggregate => {
          const readingField = this.ERDataFunctions.getReadingField(options, data);
          const items = _.get(aggregate, `${readingField}.group.items`, []);

          const params = {
            property: _.get(data, 'propertyData.object', null),
            readingField: readingField,
            relationalValueField: ERField.getRelationalValueField(data),
            groupItems: this.flattenItems(items)
          };
          return this.ERDataSumFunctions.getSpecifiedConsumptionSum(column, data, options, params);
        }
      };
    }

    this.ERDataFunctions.addAggregates(options, data, column, format, aggregateFunctions);

    return column;
  }

  /**
   * Flattens grouped items object to array
   *
   * @param {Array} items
   *
   * @returns {Array}
   */
  flattenItems(items) {
    let output = [];

    items.forEach(item => {
      if (item.items && item.items.length > 0) {
        output = output.concat(this.flattenItems(item.items));
      } else {
        output = output.concat(item);
      }
    });

    return output;
  }

  getRelatedValueColumn(options, data) {
    const field = ERField.getRelatedValueField(data);
    const relatedValue = _.get(data, 'propertyData.object');
    const unit = _.get(data, 'propertyData.unit');
    const format = 'n1';
    const title = _.get(relatedValue, 'Name') + (unit ? ` [${unit}]` : '');
    const getTemplate = this.getTemplate;
    const column = {
      field: field,
      title: title,
      sort: getSortNumber(EnergyReportingConstants.ColumnTypeOrder.RelatedValueColumn, data.apiIndex, relatedValue.Id),
      sortDate: data.startInfo.value,
      headerTemplate: options.isWidget
        ? void 0
        : function() {
          return (
            `<span tooltip-append-to-body='true' tooltip-placement='left' tooltip='${data.startInfo.key} ${title}'>` +
              `<span style='color:${data.startInfo.color}'>■ </span>${title}</span>`
          );
        },
      width: EnergyReportingConstants.Dimensions.gridColumn.value[data.wideColumns ? 'wide' : 'narrow'],
      attributes: {
        class: 'cell-number'
      },
      template: function(row) {
        return getTemplate(options, row, field, format);
      }
    };

    const aggregateFunctions = {
      sum: aggregate => {
        const readingField = this.ERDataFunctions.getReadingField(options, data);
        const items = _.get(aggregate, `${readingField}.group.items`, []);

        const params = {
          property: _.get(data, 'propertyData.object', null),
          readingField: readingField,
          relationalValueField: ERField.getRelatedValueField(data),
          groupItems: this.flattenItems(items)
        };
        return this.ERDataSumFunctions.getFieldAverage(column, data, options, params);
      }
    };
    this.ERDataFunctions.addAggregates(options, data, column, format, aggregateFunctions);
    return column;
  }

  getTemperatureColumn(options, data) {
    const field = ERField.getTemperatureField(data);
    const format = 'n1';
    const title = data.startInfo.key;
    const color = this.colorService.shadeColor('#4ddd4d', data.startInfo.index * -20);
    const getTemplate = this.getTemplate;
    const column = {
      field: field,
      title: title,
      sort: getSortNumber(EnergyReportingConstants.ColumnTypeOrder.TemperatureColumn, data.apiIndex),
      sortDate: data.startInfo.value,
      headerTemplate: options.isWidget
        ? void 0
        : function() {
          return `<span style='color:${color}'>■ </span>${title}`;
        },
      width: EnergyReportingConstants.Dimensions.gridColumn.value[data.wideColumns ? 'wide' : 'narrow'],
      attributes: {
        class: 'cell-number'
      },
      template: function(row) {
        return getTemplate(options, row, field, format);
      }
    };

    this.ERDataFunctions.addAggregates(options, data, column, format, this.emptyStringSumAggregate);

    return column;
  }

  getTemplate(options, row, field, format) {
    const value = _.get(row, field);
    const firstFlag = _.get(row, ERField.getFlagField(field));
    const className = firstFlag ? `k-grid-cell-${firstFlag}` : '';

    return value === null ?
      '' :
      `<span class="${className}">${kendo.toString(value, format)}</span>`
    ;
  }
}

ErGridQuantityColumns.$inject = $inject;

export default ErGridQuantityColumns;
