<kendo-grid
  [kendoGridBinding]="provisionings"
  [pageSize]="50"
  [pageable]="pagerSettings"
  [sortable]="true"
  [groupable]="true"
  [resizable]="true"
  [sort]="sort"
  [loading]="loading"
  filterable="menu"
  class="grid-full-height"
>
  <kendo-grid-column
    field="instanceId"
    [title]="'PROVISIONING.INSTANCE_ID' | translate"
    [width]="120"
  >
    <ng-template kendoGridCellTemplate let-dataItem >
      <button class="button button--link button--no-focus" (click)="showProvisioningItem(dataItem)" >
        {{ dataItem?.instanceId }}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="externalCustomerId"
    [title]="'EXTERNAL_CUSTOMER_ID' | translate"
    [width]="100"
  ></kendo-grid-column>
  <kendo-grid-column
    [title]="'PROVISIONING.CREATED' | translate"
    field="created"
    format="G"
    filter="date"
    [width]="80"
  ></kendo-grid-column>
  <kendo-grid-column
    field="status"
    [title]="'PROVISIONING.STATUS.LABEL' | translate"
    [width]="60"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-column="column">
      {{ dataItem[column.field] | processStatusText }}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-value="value">
      {{ 'PROVISIONING.STATUS.LABEL' | translate }}: {{ value | processStatusText }}
    </ng-template>
    <ng-template
      kendoGridFilterMenuTemplate
      let-column="column"
      let-filter="filter"
      let-filterService="filterService"
    >
      <grid-checkbox-filter
        [filter]="filter"
        [field]="column.field"
        [filterService]="filterService"
        [dataSource]="provisionings"
      >
        <span *gridMultiFilterTemplate="let value = value">
          {{ value | processStatusText }}
        </span>
      </grid-checkbox-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="tenantId"
    [title]="'PROVISIONING.TENANT_ID' | translate"
    [width]="60"
  >
    <ng-template
      kendoGridFilterMenuTemplate
      let-column="column"
      let-filter="filter"
      let-filterService="filterService"
    >
      <kendo-grid-multi-select-primitive-filter
        [filter]="filter"
        [field]="column.field"
        [filterService]="filterService"
        [data]="provisionings"
      >
      </kendo-grid-multi-select-primitive-filter>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
