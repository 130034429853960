import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';

/**
 * Use inside `ek-dropdown`.
 */
@Component({
  selector: 'ek-dropdown-heading',
  templateUrl: './ek-dropdown-heading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./ek-dropdown-heading.component.scss']
})
export class EkDropdownHeadingComponent { }
