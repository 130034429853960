import { Investigation } from '@enerkey/clients/attachments';

export const investigations = [
  { id: 0, key: 'ACTIONS.INVESTIGATION_0' },
  { id: 1, key: 'ACTIONS.INVESTIGATION_1' },
  { id: 2, key: 'ACTIONS.INVESTIGATION_2' },
  { id: 3, key: 'ACTIONS.INVESTIGATION_3' }
];

export const investigationTranslations: Readonly<Record<Investigation, string>> = {
  [Investigation.NoInvestigationRequired]: 'ACTIONS.INVESTIGATION_0',
  [Investigation.InvestigationRequired]: 'ACTIONS.INVESTIGATION_1',
  [Investigation.UnderInvestigation]: 'ACTIONS.INVESTIGATION_2',
  [Investigation.InvestigationDone]: 'ACTIONS.INVESTIGATION_3',
};
