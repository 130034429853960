import { ReadingStatus } from '@enerkey/clients/configuration-control';

export const readingStatusTranslations: Readonly<Record<ReadingStatus, string>> = {
  [ReadingStatus.NotRun]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_NOTRUN',
  [ReadingStatus.Fail]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_FAIL',
  [ReadingStatus.NoData]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_NODATA',
  [ReadingStatus.Success]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_SUCCESS',
  [ReadingStatus.PartlySuccess]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_PARTLY_SUCCESS',
  [ReadingStatus.ConnectionError]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_CONNECTION_ERROR',
  [ReadingStatus.MissingFile]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_MISSING_FILE',
  [ReadingStatus.UnknownFormat]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_UNKNOWN_FORMAT',
  [ReadingStatus.ServiceBusError]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_SERVICEBUS_ERROR',
  [ReadingStatus.Unauthorized]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_UNAUTHORIZED',
  [ReadingStatus.BadRequest]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_BAD_REQUEST',
  [ReadingStatus.ParsingError]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_PARSING_ERROR',
  [ReadingStatus.TargetingError]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_TARGETING_ERROR',
  [ReadingStatus.ManuallyTriggered]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_MANUALLY_TRIGGERED',
  [ReadingStatus.UnknownError]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_UNKNOWN_ERROR',
  [ReadingStatus.MeterInactive]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_METER_INACTIVE',
  [ReadingStatus.ConfigurationError]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_CONFIGURATION_ERROR',
  [ReadingStatus.FunctionTimeout]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_FUNCTION_TIMEOUT',
  [ReadingStatus.TerminalError]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_TERMINAL_ERROR',
  [ReadingStatus.NotConfigured]: 'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_NOT_CONFIGURED',
  [ReadingStatus.DataHubRequestFailed]:
    'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_DATAHUB_REQUEST_FAILED',
  [ReadingStatus.WaitingDataHubResponse]:
    'CONFIGURATION_CONTROL.CONFIGURATIONS_GRID.READINGSTATUS_WAITING_DATAHUB_RESPONSE',
};

type ReadingStatusStyle = 'notrun' | 'success' | 'nodata' | 'fail' | 'warning';

export const readingStatusIndicators: Readonly<Record<ReadingStatus, ReadingStatusStyle>> = {
  [ReadingStatus.NotRun]: 'notrun',
  [ReadingStatus.ManuallyTriggered]: 'notrun', // Technically this isn't "notrun" but for css
  [ReadingStatus.Success]: 'success',
  [ReadingStatus.NoData]: 'nodata',
  [ReadingStatus.MissingFile]: 'nodata',
  [ReadingStatus.Fail]: 'fail',
  [ReadingStatus.ConnectionError]: 'fail',
  [ReadingStatus.UnknownFormat]: 'fail',
  [ReadingStatus.ServiceBusError]: 'fail',
  [ReadingStatus.BadRequest]: 'fail',
  [ReadingStatus.UnknownError]: 'fail',
  [ReadingStatus.MeterInactive]: 'fail',
  [ReadingStatus.ConfigurationError]: 'fail',
  [ReadingStatus.FunctionTimeout]: 'fail',
  [ReadingStatus.PartlySuccess]: 'warning',
  [ReadingStatus.Unauthorized]: 'warning',
  [ReadingStatus.ParsingError]: 'warning',
  [ReadingStatus.TargetingError]: 'warning',
  [ReadingStatus.TerminalError]: 'fail',
  [ReadingStatus.NotConfigured]: 'nodata',
  [ReadingStatus.DataHubRequestFailed]: 'fail',
  [ReadingStatus.WaitingDataHubResponse]: 'warning'
};
