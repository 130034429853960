import { AjsModalService } from './modal.service';

const dependencies = ['mm.foundation'];

const ModalServiceModule = angular
  .module('app.services.modal', dependencies)
  .service('modalService', AjsModalService)
;

export default ModalServiceModule;
