const $inject = ['$modalInstance', 'action', 'hasCategoryData'];

class EmActionEditModalController {
  constructor($modalInstance, action, hasCategoryData) {
    this.action = action;
    this.hasCategoryData = hasCategoryData;
    this.modalDismiss = $modalInstance.dismiss;
    this.modalClose = $modalInstance.close;
  }
}

EmActionEditModalController.$inject = $inject;

export default EmActionEditModalController;
