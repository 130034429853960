import ModalContentHeaderModule        from './modal-content-header';
import ModalContentItemModule          from './modal-content-item';
import ModalContentItemActionsModule   from './modal-content-item-actions';
import ModalContentItemContainerModule from './modal-content-item-container';
import ModalContentComponent           from './modal-content.component';

const dependencies = [
  ModalContentHeaderModule.name,
  ModalContentItemModule.name,
  ModalContentItemActionsModule.name,
  ModalContentItemContainerModule.name
];

const ModalContentModule = angular
  .module('app.components.modal-content', dependencies)
  .component('modalContent', ModalContentComponent)
;

export default ModalContentModule;
