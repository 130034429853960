export enum WidgetChangeOption {
  Relative = 'Relative',
  Absolute = 'Absolute'
}

export enum WidgetRelatedValueOption {
  Max = 'Max',
  Min = 'Min',
  Average = 'Average'
}
