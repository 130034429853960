configurationService.$inject = ['utils', 'configurationControlApi'];

export function configurationService(utils, configurationControlApi) {
  var save = function(readerTypeId, readerBaseProperties, readerSpecificProperties) {
    var config = {};
    angular.forEach(readerBaseProperties, function(value) {
      config[value.name] = value.value;
    });

    var specificConfig = {};
    angular.forEach(readerSpecificProperties, function(value) {
      if (value.type === "boolean" && value.value === null) {
        value.value = false;
      }
      specificConfig[value.name] = value.value;
    });
    config['jsonConfiguration'] = angular.toJson(specificConfig);

    return postConfiguration(readerTypeId, config);
  };

  function postConfiguration(readerTypeId, configuration) {
    return configurationControlApi.postConfiguration(readerTypeId, configuration);
  }

  var update = function(configId, readerBaseProperties, readerSpecificProperties) {
    var config = {};
    angular.forEach(readerBaseProperties, function(value) {
      config[value.name] = value.value;
    });

    var specificConfig = {};
    angular.forEach(readerSpecificProperties, function(value) {
      specificConfig[value.name] = value.value;
    });
    config['jsonConfiguration'] = angular.toJson(specificConfig);
    return configurationControlApi.updateConfiguration(configId, config);
  };

  var deleteConfiguration = function(configId) {
    return configurationControlApi.deleteConfiguration(configId);
  };

  var getConfigurationPassword = function(configId) {
    return configurationControlApi.getConfigurationPassword(configId);
  };

  var getReaderTypeInformation = function(meterId) {
    return configurationControlApi.getReaderTypeInformation(meterId);
  };

  var getStatusLog = function(meterId, timeFrame) {
    return configurationControlApi.getStatusLog(
      meterId, timeFrame);
  };

  return {
    save: save,
    update: update,
    deleteConfiguration: deleteConfiguration,
    getConfigurationPassword: getConfigurationPassword,
    getReaderTypeInformation: getReaderTypeInformation,
    getStatusLog: getStatusLog
  };
}
