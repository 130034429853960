<modal-view-header [headingText]="modalTitle | translate"></modal-view-header>

<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
>
  <form [formGroup]="formGroup">
    <p>
      <kendo-textbox
        *labelBefore="'SUSTAINABILITY.GRI.UNIT_NAME' | translate"
        formControlName="name"
        [readonly]="existingUnit?.isDefault"
      ></kendo-textbox>
    </p>

    <p>
      <kendo-textbox
        *labelBefore="'SUSTAINABILITY.GRI.UNIT' | translate"
        formControlName="unit"
        [readonly]="existingUnit?.isDefault"
      ></kendo-textbox>
    </p>

    <p *ngIf="existingUnit?.isDefault && existingUnit?.quantityId; else co2editor">
      <kendo-textbox
        *labelBefore="'SUSTAINABILITY.GRI.CO2FACTOR' | translate"
        [readonly]="true"
        [value]="existingUnit | griCo2factor"
      ></kendo-textbox>
    </p>

    <ng-template #co2editor>
      <p class="co2container">
        <span>
          <kendo-numerictextbox
            *labelBefore="'SUSTAINABILITY.GRI.CO2FACTOR' | translate"
            formControlName="co2factor"
            [format]="{ style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 8 }"
            [readonly]="existingUnit?.isDefault"
            [spinners]="false"
            [selectOnFocus]="false"
          ></kendo-numerictextbox>
        </span>
        <ek-combo
          [items]="co2eqOptions"
          formControlName="co2eq"
        ></ek-combo>
      </p>
    </ng-template>

    <p>
      <kendo-textarea
        *labelBefore="'SUSTAINABILITY.GRI.UNIT_SOURCE' | translate"
        formControlName="source"
        [readonly]="existingUnit?.isDefault"
      ></kendo-textarea>
    </p>

    <gri-organization-info *ngIf="!existingUnit?.isDefault"></gri-organization-info>
  </form>
</div>

<div class="modal-footer">
  <div *ngIf="existingUnit && !existingUnit.isDefault && counts$ | async as counts">
    {{ 'SUSTAINABILITY.GRI.IN_USE_ROWS' | translate : {
    item: ('SUSTAINABILITY.GRI.UNIT_NAME' | translate),
    rowCount: counts.rows,
    reportCount: counts.reports
    } }}
    <br>
    {{ 'SUSTAINABILITY.GRI.CHANGE_WARNING' | translate }}
  </div>

  <div class="modal-footer-buttons float-right">
    <button
      *ngIf="!(existingUnit?.isDefault)"
      class="button"
      type="button"
      (click)="submit()"
      [loading]="loading$ | async"
      [disabled]="!formGroup.dirty || !formGroup.valid"
    >
      {{ (existingUnit ? 'SAVE': 'CREATE') | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ (existingUnit?.isDefault ? 'MODALS.CLOSE' : 'CANCEL') | translate }}
    </button>
  </div>
</div>
