import templateModule from 'raw-loader!../templates/meter-details-list-template.html';

export default {
  template: templateModule,
  controller: 'MeterDetailsListController',
  controllerAs: 'vm',
  bindings: {
    meter: '<'
  }
};
