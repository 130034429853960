import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'effect-time',
  templateUrl: './effect-time.component.html',
  styleUrls: ['./effect-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EffectTimeComponent {
  @Input() public fromDate: Date;
  @Input() public toDate: Date;
  @Input() public loading = false;
  @Output() public fromDateChange = new EventEmitter<Date>();
  @Output() public toDateChange = new EventEmitter<Date>();

  public onFromDateChange(date: Date): void {
    this.fromDateChange.emit(this.dateToUtcAndStartOfDay(date));
  }

  public onToDateChange(date?: Date): void {
    this.toDateChange.emit(date ? this.dateToUtcAndStartOfDay(date) : null);
  }

  private dateToUtcAndStartOfDay(date: Date): Date {
    return moment.utc(date).startOf('day').toDate();
  }
}
