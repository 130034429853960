import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { IntlService } from '@progress/kendo-angular-intl';

import { Observable, Subject, take } from 'rxjs';

import { FacilityPropertyEnums } from '@enerkey/clients/facility';
import { getEnumEntries } from '@enerkey/ts-utils';
import { EtCurveParametersCons } from '@enerkey/clients/ines-reports';

import { EtCurveService } from '../../../services/et-curve/et-curve.service';
import { ETCurveLine, EtCurveModel, ETCurvePoint, ETCurveSearchParams } from '../../../models/et-curve.model';
import { QuantityService } from '../../../../../shared/services/quantity.service';
import { ColorService } from '../../../../../shared/services/color.service';

export enum ETCurveHighlight {
  None,
  TenPercent,
  Last,
}

type ETCurveSerie = {
  readonly type: ETCurveHighlight;
  readonly name: string;
  readonly color: string;
  readonly data: ETCurvePoint[];
}

@Component({
  selector: 'et-curve-chart',
  templateUrl: './et-curve-chart.component.html',
  styleUrls: ['./et-curve-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EtCurveChartComponent implements OnInit {
  public etCurveModel: EtCurveModel = null;
  public series: ETCurveSerie[] = [];
  public title: string = null;
  public curves: ETCurveLine[] = [];
  public highlight: boolean = true;
  public showFrom: Date = new Date();
  public showTo: Date = new Date();

  @Output() public readonly etCurveLoaded = new EventEmitter<{ curves: ETCurveLine[], deviation?: number }>();
  public readonly loading$: Observable<boolean>;
  public readonly reset$: Observable<boolean>;

  private serieColors: Record<ETCurveHighlight, string>;
  private _reset = new Subject<boolean>();
  private _dataLoaded: boolean = false;

  public constructor(
    private readonly etCurveService: EtCurveService,
    private readonly quantityService: QuantityService,
    private readonly translateService: TranslateService,
    private readonly intlService: IntlService,
    private readonly colorService: ColorService,
    private ref: ChangeDetectorRef
  ) {
    this.loading$ = this.etCurveService.loading$;
    this.reset$ = this._reset.asObservable();
  }

  public ngOnInit(): void {
    this.serieColors = {
      [ETCurveHighlight.None]: this.colorService.getCssProperty('--enerkey-primary-active', undefined),
      [ETCurveHighlight.TenPercent]: this.colorService.getCssProperty('--enerkey-warning', undefined),
      [ETCurveHighlight.Last]: this.colorService.getCssProperty('--enerkey-red', undefined),
    };

    this.updateTitle();
  }

  public highlightChanged(): void {
    this.updateSeries();
  }

  public update(params: ETCurveSearchParams, manualEtCurve?: EtCurveParametersCons): void {
    this.etCurveService.getChartData(params, manualEtCurve)
      .pipe(take(1))
      .subscribe({
        next: (value: EtCurveModel) => {
          this.etCurveModel = value;
          this.showFrom = value.showFrom;
          this.showTo = value.showTo;

          this.updateTitle();
          this.updateSeries();
          this.updateTrendline();
          this._dataLoaded = true;
          this.etCurveLoaded.emit({ curves: this.curves, deviation: value.deviation });
        },
        complete: () => {
          if (!this._dataLoaded) {
            this.etCurveLoaded.emit({ curves: [], deviation: undefined });
          }
        }
      });
  }

  public reset(): void {
    if (this._dataLoaded === true) {
      this.showFrom = new Date();
      this.showTo = new Date();
      this.series = [];
      this.curves = [];
      this.title = null;
      this.etCurveModel = null;
      this._reset.next(true);

      setTimeout(() => {
        this._reset.next(false);
        this._dataLoaded = false;
      });
    }
  }

  private updateTrendline(): void {
    this.curves = this.etCurveModel ? this.etCurveModel.curves.slice(0) : [];
  }

  private updateSeries(): void {
    this.series = [];

    if (!this.etCurveModel.values) {
      return;
    }

    let data: ETCurvePoint[];
    if (this.highlight) {
      // Split values to three parts
      data = this.etCurveModel.values.sortBy(point => point.timestamp);

      const tenth = Math.round(data.length / 10);

      this.series.unshift({
        name: this.translateService.instant('ANALYTICS.ETCURVE.LAST_VALUE'),
        color: this.serieColors[ETCurveHighlight.Last],
        type: ETCurveHighlight.Last,
        data: data.splice(-1),
      });

      this.series.unshift({
        name: this.translateService.instant('ANALYTICS.ETCURVE.LAST_N', { n: '10%' }),
        color: this.serieColors[ETCurveHighlight.TenPercent],
        type: ETCurveHighlight.TenPercent,
        data: data.splice(-tenth),
      });
    } else {
      // Use raw data
      data = this.etCurveModel.values
        .filter(point => point.timestamp >= this.showFrom && point.timestamp <= this.showTo);
    }

    this.series.unshift({
      name: this.etCurveModel.valueUnit.name,
      color: this.serieColors[ETCurveHighlight.None],
      type: ETCurveHighlight.None,
      data: data,
    });
    this.ref.markForCheck();
  }

  private updateTitle(): void {
    if (!this.etCurveModel) {
      this.title = null;
      return;
    }

    this.title = [
      this.etCurveModel.facility.displayName,
      this.formatQuantity(),
      this.formatDateInterval(),
      this.formatAddress(),
    ].filter(s => !!s).join(' — ');
  }

  private formatQuantity(): string {
    const { quantity, specificId } = this.etCurveModel;
    const quantityName = this.quantityService.getQuantityLocalizedName(quantity);

    if (!Number.isInteger(specificId)) {
      return quantityName;
    }

    const entry = getEnumEntries(FacilityPropertyEnums).find(([_, enumValue]) => enumValue === specificId);
    const relationalName = this.translateService.instant(`PROPERTIES.${entry[0].toUpperCase()}`);
    return `${quantityName}/${relationalName}`;
  }

  private formatDateInterval(): string {
    const { showFrom: fromDate, showTo: toDate } = this.etCurveModel;
    return `${this.intlService.formatDate(fromDate)} - ${this.intlService.formatDate(toDate)}`;
  }

  private formatAddress(): string {
    const address = this.etCurveModel.facility.addresses[0];
    return [address.postalCode, address.city, address.country]
      .filter(s => !!s)
      .join(', ');
  }
}
