import { Injectable } from '@angular/core';

import { combineLatest, from, Observable, switchMap } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';

import { bignumber } from 'mathjs';

import { MeterGroupDto, MeterGroupMeterUpdateDto } from '@enerkey/clients/metering';

import { MeterManagementClient, MeterManagementMeter } from '@enerkey/clients/meter-management';

import { FacilityService } from '../../../../shared/services/facility.service';
import { CacheManagerObservable } from '../../../../shared/utils/cache/cache.manager.util';
import {
  FacilityGridItem, MeterGridItem, MeterGroupGridItem, MeterGroupGridItemBase, MeterGroupMeter, MeterGroupWithMeters
} from '../../models/meter-groups-grid.model';
import { MeterGroupsService } from '../meter-groups/meter-groups.service';

@Injectable({ providedIn: 'root' })
export class MeterGroupsGridService {

  private static filterByActiveMeters(treeList: MeterGroupGridItemBase[]): MeterGroupGridItemBase[] {
    return treeList.map(node => {
      if (node.children?.length) {
        const filteredChildren = this.filterByActiveMeters(node.children);
        if (filteredChildren.length) { return node.clone(filteredChildren); }
      } else if (node.isActive) {
        return node;
      }

      return null;
    }).filter(item => item !== null);
  }

  private static flattenToMeterGroupsWithMeters(rootItems: MeterGroupGridItemBase[]): MeterGroupWithMeters[] {
    if (!rootItems?.length) { return null; }

    const firstRootItem = rootItems[0];
    if (firstRootItem instanceof MeterGroupGridItem) {
      return MeterGroupsGridService.flattenToMeterGroupsWithMetersByMeterGroupRoot(rootItems as MeterGroupGridItem[]);
    } else if (firstRootItem instanceof FacilityGridItem) {
      return MeterGroupsGridService.flattenToMeterGroupsWithMetersByFacilityRoot(rootItems as FacilityGridItem[]);
    }

    throw new Error('Unsupported rootItem type');
  }

  private static flattenToMeterGroupsWithMetersByMeterGroupRoot(
    rootItems: MeterGroupGridItem[]
  ): MeterGroupWithMeters[] {
    return rootItems.map(rootItem => new MeterGroupWithMeters(
      rootItem.id,
      rootItem.children
        .flatMap(facility => (facility as FacilityGridItem).children)
        .map(meter => ({
          id: meter.id,
          weight: MeterGroupsGridService.getMeterWeight(meter as MeterGridItem),
          active: meter.isActive
        }))
    ));
  }

  private static flattenToMeterGroupsWithMetersByFacilityRoot(
    rootItems: FacilityGridItem[]
  ): MeterGroupWithMeters[] {
    return rootItems
      .flatMap(facility => (facility as FacilityGridItem).children)
      .map(meterGroup => new MeterGroupWithMeters(
        meterGroup.id,
        meterGroup.children.map(meter => ({
          id: meter.id,
          weight: MeterGroupsGridService.getMeterWeight(meter as MeterGridItem),
          active: meter.isActive
        }))
      ));
  }

  private static getMeterWeight(meter: MeterGridItem): number {
    if (!Number.isFinite(meter.percentage)) { return undefined; }
    if (meter.percentage === 0) { return 0; }

    const value = (meter.subtract ? -1 : 1) * meter.percentage;
    const result = bignumber(value).div(100);

    const truncatedString = result
      .toNumber()
      .toString()
      .split('.')
      .map((part, index) => index === 1 ? part.substring(0, 5) : part)
      .join('.');

    return Number(truncatedString);
  }

  private static areMetersEqual(currentMeters: MeterGroupMeter[], previousMeters: MeterGroupMeter[]): boolean {
    if (currentMeters.length !== previousMeters.length) { return false; }

    const currentMeterSet = new Set(currentMeters.map(m => `${m.id}-${m.weight}`));
    const previousMeterSet = new Set(previousMeters.map(m => `${m.id}-${m.weight}`));

    return [...currentMeterSet].every(meterId => previousMeterSet.has(meterId));
  }

  private readonly _metersCache = new CacheManagerObservable<string, Record<string, MeterManagementMeter[]>>(60 * 5);

  public constructor(
    private readonly facilityService: FacilityService,
    private readonly meterGroupsService: MeterGroupsService,
    private readonly meterManagementClient: MeterManagementClient
  ) {}

  public getMeterGroupsTreeList(
    facilityId?: number,
    meterGroupId?: number,
    filterByActiveMeters: boolean = false
  ): Observable<MeterGroupGridItemBase[]> {
    const isFacilityIdDefined = Number.isFinite(facilityId);
    return combineLatest([
      this.meterGroupsService.getMeterGroups().pipe(
        map(meterGroups => Number.isFinite(meterGroupId) ?
          meterGroups.filter(meterGroup => meterGroup.id === meterGroupId) :
          meterGroups)
      ),
      this.facilityService.profileFacilities$.pipe(
        map(facilities => isFacilityIdDefined ?
          facilities.filter(facility => facility.facilityId === facilityId) :
          facilities)
      )
    ]).pipe(
      mergeMap(([meterGroups, facilities]) => {
        const facilityIds = facilities.map(facility => facility.facilityId);
        return this.getMetersByFacilityIds(facilityIds).pipe(
          map(meters => ({ meterGroups, facilities, meters }))
        );
      }),
      map(({ meterGroups, facilities, meters }) => isFacilityIdDefined ?
        FacilityGridItem.generateTreeList(facilities, meterGroups, meters) :
        MeterGroupGridItem.generateTreeList(meterGroups, facilities, meters)),
      map((tree: FacilityGridItem[] | MeterGroupGridItem[]) => filterByActiveMeters ?
        MeterGroupsGridService.filterByActiveMeters(tree) :
        tree)
    );
  }

  public saveMeterGroupsWithMeters(
    items: MeterGroupGridItemBase[],
    meterGroupId?: number,
    facilityId?: number
  ): Observable<MeterGroupDto[]> {
    const hasFacilityId = Number.isFinite(facilityId);
    return (hasFacilityId ?
      this.getMeterGroupsWithMetersFromFacilityRootItems(items, meterGroupId) :
      this.getMeterGroupsWithMetersFromMeterGroupRootItems(items)
    ).pipe(
      switchMap(meterGroupsWithMeters => from(meterGroupsWithMeters).pipe(
        mergeMap(mgm => this.meterGroupsService.updateMeterGroupMeters(
          mgm.meterGroupId,
          mgm.meters.map(m => new MeterGroupMeterUpdateDto({ meterId: m.id, weight: m.weight }))
        )),
        toArray()
      ))
    );
  }

  private getMeterGroupsWithMetersFromFacilityRootItems(
    items: MeterGroupGridItemBase[],
    meterGroupId?: number
  ): Observable<MeterGroupWithMeters[]> {
    const meterGroupsWithMeters = MeterGroupsGridService.flattenToMeterGroupsWithMeters(items);

    return this.meterGroupsService.getMeterGroups().pipe(
      map(mgs => Number.isFinite(meterGroupId) ? mgs.filter(mg => mg.id === meterGroupId) : mgs),
      map(mgs => mgs.map(meterGroup => {
        const currentMeterGroup = meterGroupsWithMeters.find(mg => mg.meterGroupId === meterGroup.id);
        const currentMeterIds = currentMeterGroup.meters.map(m => m.id);
        const currentMeters = currentMeterGroup?.withActiveMetersOnly()?.meters ?? [];

        const metersNotInFacility: MeterGroupMeter[] = meterGroup.meters
          .filter(m => !currentMeterIds.includes(m.meterId))
          .map(m => ({ id: m.meterId, weight: m.weight, active: true }));

        const previousMeters: MeterGroupMeter[] = meterGroup.meters.map(m => ({
          id: m.meterId, weight: m.weight, active: true
        }));

        return {
          meterGroupId: meterGroup.id,
          currentMeters: [...currentMeters, ...metersNotInFacility],
          previousMeters: previousMeters
        };
      })),
      map(mgm => mgm
        .filter(({ currentMeters, previousMeters }) =>
          !MeterGroupsGridService.areMetersEqual(currentMeters, previousMeters))
        .map(({ meterGroupId: mgId, currentMeters }) => new MeterGroupWithMeters(mgId, currentMeters)))
    );
  }

  private getMeterGroupsWithMetersFromMeterGroupRootItems(
    items: MeterGroupGridItemBase[]
  ): Observable<MeterGroupWithMeters[]> {
    const meterGroupsWithMeters = MeterGroupsGridService.flattenToMeterGroupsWithMeters(items);
    const meterGroupIds = meterGroupsWithMeters.map(mg => mg.meterGroupId);

    return this.meterGroupsService.getMeterGroups().pipe(
      map(meterGroups => meterGroups.filter(mg => meterGroupIds.includes(mg.id))),
      map(meterGroups => meterGroups.map(meterGroup => {
        const currentMeterGroup = meterGroupsWithMeters.find(mg => mg.meterGroupId === meterGroup.id);
        const currentMeters = currentMeterGroup?.withActiveMetersOnly()?.meters ?? [];
        const previousMeters: MeterGroupMeter[] = meterGroup.meters.map(m => ({ id: m.meterId, weight: m.weight }));

        return { meterGroupId: meterGroup.id, currentMeters: currentMeters, previousMeters: previousMeters };
      })),
      map(mgm => mgm
        .filter(({ currentMeters, previousMeters }) =>
          !MeterGroupsGridService.areMetersEqual(currentMeters, previousMeters))
        .map(({ meterGroupId, currentMeters }) => new MeterGroupWithMeters(meterGroupId, currentMeters)))
    );
  }

  private getMetersByFacilityIds(facilityIds: number[]): Observable<Record<string, MeterManagementMeter[]>> {
    const cacheKey = facilityIds.sort((a, b) => a - b).join(',');
    return this._metersCache.getOrFetch(cacheKey, () => this.meterManagementClient.getMetersByFacility(facilityIds));
  }
}
