import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CompanyFlatResponse } from '@enerkey/clients/contact';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { guid } from '@enerkey/ts-utils';

import { CompaniesService } from '../../services/companies.service';

@Component({
  selector: 'company-dropdown',
  templateUrl: './company-dropdown.component.html',
  styleUrls: ['./company-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CompanyDropdownComponent),
    multi: true
  }],
})
export class CompanyDropdownComponent implements ControlValueAccessor, OnDestroy {
  @Input() public noLabel: boolean;

  /** Id prefix for the DOM elements. */
  public readonly componentId: string = guid(true);

  public readonly companies$: Observable<CompanyFlatResponse[]>;

  public companyId: number;

  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public disabled$: Observable<boolean>;
  public loading$: Observable<boolean>;

  private _onChange: (value: number) => void;

  private readonly _loading$ = new LoadingSubject(false);
  private readonly _disabled$ = new BehaviorSubject(false);

  public constructor(
    companiesService: CompaniesService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.loading$ = this._loading$.asObservable();
    this.disabled$ = combineLatest([this.loading$, this._disabled$]).pipe(
      map(([loading, disabled]) => loading || disabled)
    );

    this.companies$ = companiesService.companies$.pipe(
      take(1),
      indicate(this._loading$)
    );
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
    this._disabled$.complete();
  }

  public writeValue(id: number): void {
    this.companyId = id;
    this.changeDetectorRef.detectChanges();
  }

  public registerOnChange(fn: (value: number) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void {
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._disabled$.next(isDisabled);
  }

  public valueChange(id: number): void {
    this._onChange?.(id);
  }
}
