import { Pipe, PipeTransform } from '@angular/core';

import { Consumption } from '@enerkey/clients/reporting';

import { getIncompleteOrModeledAggregate } from '../shared/reporting.functions';
import { PropertyPathPipe } from '../../../shared/common-pipes/property-path.pipe';
import { ConsumptionLike } from '../shared/reporting-series';

/**
 * Returns the aggregates of incomplete and modeled flags by path.
 */
@Pipe({
  name: 'changeValueFlagAggregate'
})
export class ChangeValueFlagAggregatePipe implements PipeTransform {

  public constructor(private propertyPathPipe: PropertyPathPipe) {}

  /**
   * @param dataItem Dataitem object in the grid or treelist
   * @param valueArrayPath Path to the array of consumptions in the dataitem object, e.g. `column.field`
   * @param slicePathEnd Number of property names to cut from the end of the `valueArrayPath`
   */
  public transform(dataItem: unknown, valueArrayPath: string, slicePathEnd?: number): Consumption {
    if (Number.isFinite(slicePathEnd)) {
      valueArrayPath = valueArrayPath.split('.').slice(0, -slicePathEnd).join('.');
    }
    const consumptions = this.propertyPathPipe.transform(dataItem, valueArrayPath) as ConsumptionLike[];

    if (consumptions) {
      const values = Object.values(consumptions);
      const incompleteAggr = getIncompleteOrModeledAggregate(values, 'incomplete');
      const modeledAggr = getIncompleteOrModeledAggregate(values, 'modeled');
      return { incomplete: incompleteAggr, modeled: modeledAggr, timestamp: null } as Consumption;
    }
  }
}
