import { Pipe, PipeTransform } from '@angular/core';

import { AlarmTypeDto, AlarmTypeEnum } from '@enerkey/clients/alarm';

import { AlarmService } from '../../services/alarm.service';

@Pipe({ name: 'alarmTypeName' })
export class AlarmTypeNamePipe implements PipeTransform {

  public constructor(
    private readonly alarmService: AlarmService
  ) { }

  public transform(value: AlarmTypeDto | AlarmTypeEnum): string {
    return this.alarmService.getAlarmTypeName(value) || '';
  }
}
