import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { LabelBaseDirective, LabelPosition } from './label-base.directive';

/* eslint-disable @angular-eslint/no-input-rename */

@Directive({ selector: '[labelWrap]' })
export class LabelWrapDirective extends LabelBaseDirective {

  @Input('labelWrap') public labelText: string;
  @Input('labelWrapClass') public labelClass: string;
  @Input('labelWrapTarget') public clickTarget: string;
  @Input('labelWrapTooltip') public labelTooltip: string;
  @Input('labelWrapRequired') public labelRequired: boolean;

  protected readonly labelPosition: LabelPosition = 'wrap';

  public constructor(
    template: TemplateRef<unknown>,
    viewContainer: ViewContainerRef
  ) {
    super(template, viewContainer);
  }
}
