import _ from 'lodash';

import { actionBasis } from '../constants/em-action-basis';
import { actionClasses } from '../constants/em-action-classes';
import { actionGroups } from '../constants/em-action-groups';
import { actionTypes } from '../constants/em-action-types';
import { executionPhases } from '../constants/em-execution-phases';
import { investigations } from '../constants/em-investigations';
import { investmentSupports } from '../constants/em-investment-supports';

emActionsFieldService.$inject = ['facilities', 'quantities', 'meters'];

export function emActionsFieldService(facilities, quantities, meters) {
  return {
    getFields: getFields,
    getFieldsByName: getFieldsByName
  };

  function getFields(onlyComments) {
    let actionFields = [
      {
        name: 'reportingObjectId',
        component: 'em-input-facility',
        localization: 'ACTIONS.REPORTINGOBJECTNAME',
        getOptions: () => facilities.getFacilities(),
        actionsOnly: false
      },
      {
        name: 'quantityIds',
        component: 'em-input-multi-select',
        localization: 'ACTIONS.QUANTITYNAME',
        getOptions: getQuantities,
        dependsOn: 'reportingObjectId',
        actionsOnly: false
      },
      {
        name: 'meterIds',
        component: 'em-input-multi-select',
        localization: 'ACTIONS.METERNAME',
        getOptions: getMeters,
        dependsOn: 'reportingObjectId',
        actionsOnly: false
      },
      {
        name: 'actionType',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.ACTIONTYPE',
        options: actionTypes,
        actionsOnly: false
      },
      {
        name: 'executionPhase',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.EXECUTIONPHASE',
        options: executionPhases,
        actionsOnly: true
      },
      {
        name: 'investigation',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.INVESTIGATION',
        options: investigations,
        actionsOnly: false
      },
      {
        name: 'effectStartsAt',
        component: 'em-input-date',
        localization: 'ACTIONS.EFFECTSTARTSAT',
        actionsOnly: false
      },
      {
        name: 'effectStopsAt',
        component: 'em-input-date',
        localization: 'ACTIONS.EFFECTSTOPSAT',
        actionsOnly: false
      },
      {
        name: 'reportedDescription',
        component: 'em-input-text',
        localization: 'ACTIONS.REPORTEDDESCRIPTION',
        mandatory: true,
        actionsOnly: false
      },
      {
        name: 'internalDescription',
        component: 'em-input-text',
        localization: 'ACTIONS.INTERNALDESCRIPTION',
        multiline: true,
        actionsOnly: false
      },
      {
        name: 'owner',
        component: 'em-input-text',
        localization: 'ACTIONS.OWNER',
        actionsOnly: false
      },
      {
        name: 'actionGroup',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.ACTIONGROUP',
        options: actionGroups,
        actionsOnly: true
      },
      {
        name: 'actionBasis',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.ACTIONBASIS',
        options: actionBasis,
        actionsOnly: true
      },
      {
        name: 'actionClass',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.ACTIONCLASS',
        options: actionClasses,
        dependsOn: 'actionBasis',
        actionsOnly: true
      },
      {
        name: 'lifeTime',
        component: 'em-input-number',
        localization: 'ACTIONS.LIFETIME',
        integer: true,
        actionsOnly: true,
        min: 0,
        max: 99
      },
      {
        name: 'category',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.CUSTOM_CATEGORY',
        actionsOnly: true
      },

      {
        name: 'investment',
        component: 'em-input-number',
        localization: 'ACTIONS.INVESTMENT',
        actionsOnly: true
      },
      {
        name: 'investmentSupport',
        component: 'em-input-dropdown',
        localization: 'ACTIONS.INVESTMENTSUPPORT',
        options: investmentSupports,
        actionsOnly: true
      },
      {
        name: 'paybackTime',
        component: 'em-input-number',
        localization: 'ACTIONS.PAYBACKTIME',
        actionsOnly: true
      },
      {
        name: 'electricitySavings',
        component: 'em-input-number',
        localization: 'ACTIONS.ELECTRICITYSAVINGS',
        actionsOnly: true
      },
      {
        name: 'heatingSavings',
        component: 'em-input-number',
        localization: 'ACTIONS.HEATINGSAVINGS',
        actionsOnly: true
      },
      {
        name: 'fuelSavings',
        component: 'em-input-number',
        localization: 'ACTIONS.FUELSAVINGS',
        actionsOnly: true
      },
      {
        name: 'waterSavings',
        component: 'em-input-number',
        localization: 'ACTIONS.WATERSAVINGS',
        actionsOnly: true
      },
      {
        name: 'electricitySavingsEur',
        component: 'em-input-number',
        localization: 'ACTIONS.ELECTRICITYSAVINGSEUR',
        actionsOnly: true
      },
      {
        name: 'heatingSavingsEur',
        component: 'em-input-number',
        localization: 'ACTIONS.HEATINGSAVINGSEUR',
        actionsOnly: true
      },
      {
        name: 'fuelSavingsEur',
        component: 'em-input-number',
        localization: 'ACTIONS.FUELSAVINGSEUR',
        actionsOnly: true
      },
      {
        name: 'waterSavingsEur',
        component: 'em-input-number',
        localization: 'ACTIONS.WATERSAVINGSEUR',
        actionsOnly: true
      },
      {
        name: 'electricitySavingsCo2',
        component: 'em-input-number',
        localization: 'ACTIONS.ELECTRICITYSAVINGSCO2',
        actionsOnly: true
      },
      {
        name: 'heatingSavingsCo2',
        component: 'em-input-number',
        localization: 'ACTIONS.HEATINGSAVINGSCO2',
        actionsOnly: true
      },
      {
        name: 'fuelSavingsCo2',
        component: 'em-input-number',
        localization: 'ACTIONS.FUELSAVINGSCO2',
        actionsOnly: true
      },

      {
        name: 'reportingYear',
        component: 'em-input-number',
        localization: 'ACTIONS.REPORTINGYEAR',
        actionsOnly: true,
        integer: true,
        min: 1979,
        max: 9999
      },
      {
        name: 'reportedToMotivaAt',
        component: 'em-input-date',
        localization: 'ACTIONS.REPORTEDTOMOTIVAAT',
        actionsOnly: true
      },
      {
        name: 'executionYear',
        component: 'em-input-number',
        localization: 'ACTIONS.EXECUTIONYEAR',
        integer: true,
        actionsOnly: true,
        min: 1979,
        max: 9999
      }
    ];

    if (onlyComments) {
      actionFields = actionFields.filter(field => !field.actionsOnly);
    }
    return actionFields;
  }

  function getFieldsByName(onlyComments) {
    const actionFields = getFields(onlyComments);
    const fieldsByName = {};
    actionFields.forEach(field => {
      fieldsByName[field.name] = field;
    });
    return fieldsByName;
  }

  function getQuantities(reportingObjectId) {
    return quantities.getQuantitiesForFacility(reportingObjectId).then(resultQuantities => {
      const mappedQuantities = resultQuantities.map(quantity => ({
        id: quantity.ID,
        name: quantity.Name
      }));
      return mappedQuantities;
    });
  }

  function getMeters(reportingObjectId) {
    const deferred = new Promise(resolve => {
      Promise.all([
        quantities.getQuantitiesForFacility(reportingObjectId),
        meters.getMetersForFacility(reportingObjectId)
      ]).then(deferredResult => {
        const resultMeters = [];
        const quantityList = deferredResult[0];
        const meterList = deferredResult[1];
        const groupedMeters = _.groupBy(meterList, 'QuantityId');

        _.each(groupedMeters, (group, quantityId) => {
          const quantity = _.find(quantityList, { ID: Number(quantityId) });
          resultMeters.push({
            group: true,
            name: quantity.Name
          });
          group.forEach(meter => {
            resultMeters.push({
              name: `${meter.CustomerMeterIdentifier} - ${meter.Name}`,
              id: meter.Id
            });
          });
          resultMeters.push({ group: false });
        });

        resolve(resultMeters);
      });
    });

    return deferred;
  }
}
