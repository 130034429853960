import EnerkeyServiceModule           from '../../services/';
import ManualQaInspectSearchComponent from './manual-qa-inspect-search-component';

const dependencies = [EnerkeyServiceModule.name];

const ManualQaInspectSearchModule = angular
  .module('app.modules.manual-qa.components.manual-qa-inspect-search', dependencies)
  .component('manualQaInspectSearch', ManualQaInspectSearchComponent)
;

export default ManualQaInspectSearchModule;
