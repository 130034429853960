import ConfirmationModalTemplate from 'raw-loader!../../templates/confirm-modal.html';
import ConfimationModalController from './confirmation-modal.controller';
import WrapperModalController from './wrapper-modal.controller';
import { $InjectArgs } from '@enerkey/ts-utils';

export interface ConfirmDialogOptions {
  text: string;
  title: string;
  isDelete?: boolean;
  translate?: boolean;
}

export class AjsModalService {
  public static readonly $inject: $InjectArgs<typeof AjsModalService> = ['$modal'];

  public constructor(private readonly $modal: { open: (...args: unknown[]) => { result: Promise<unknown> } }) {
  }

  public open(params: unknown): { result: Promise<unknown> } {
    return this.$modal.open(params);
  }

  public getConfirmationModal(modalOptions: ConfirmDialogOptions): Promise<unknown> {
    return this.$modal.open({
      template: ConfirmationModalTemplate,
      windowClass: 'tiny',
      controller: ConfimationModalController,
      controllerAs: 'vm',
      resolve: {
        modalOptions: () => modalOptions
      }
    }).result;
  }

  public getModalWithComponent(
    component: string,
    modalParams?: unknown,
    options?: Record<string, unknown>
  ): Promise<unknown> {
    const template = `
      <${component}
        class="modal-content"
        modal-params="vm.modalParams"
        modal-dismiss="vm.modalDismiss"
        modal-close="vm.modalClose">
      </${component}>`;

    const isReport = (component === 'report-modal');

    const modalOptions = {
      template: template,
      windowClass: 'modal-dialog-scrollable',
      size: isReport ? 'semiFull' : 'large',
      controller: WrapperModalController,
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        modalParams: () => modalParams
      },
      ...options
    };

    return this.$modal.open(modalOptions).result;
  }
}
