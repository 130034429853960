import { getAmendedConsumption } from '../../../shared/utils.functions';
import TimeFrame from '../../../../../services/time-frame-service';

const $inject = ['utils', 'ManualQaConsumptionInputModalService', 'MqaSpreadsheetService'];

class ManualQaMonthlyConsumptionCommand {
  constructor(utils, ManualQaConsumptionInputModalService, MqaSpreadsheetService) {
    this.utils = utils;
    this.ManualQaConsumptionInputModalService = ManualQaConsumptionInputModalService;
    this.MqaSpreadsheetService = MqaSpreadsheetService;
  }

  getCommand(controllerInstance) {
    const self = this;

    return {
      text: self.utils.localizedString('MQA.INSPECT.SHEET.MENU_ITEM.MONTHLY_CONSUMPTION'),
      action: () => {
        const target = controllerInstance.timeFrame.getToDate();
        const monthStart = target.clone().startOf('month');
        const monthEnd = target.clone().endOf('month');
        const monthTimeFrame = new TimeFrame(monthStart, monthEnd);

        const originalActualTimeFrame = angular.copy(controllerInstance.actualTimeFrame);
        const actualTimeFrame = originalActualTimeFrame.adjustToContain(monthTimeFrame);

        const infoTextParameters = { timeFrame: monthTimeFrame.getAsString() };
        const parameters = {
          title: self.utils.localizedString('MQA.MONTHLY_CONSUMPTION.TITLE'),
          infoText: self.utils.localizedString('MQA.MONTHLY_CONSUMPTION.INFO_TEXT', infoTextParameters),
          label: self.utils.localizedString('MQA.MONTHLY_CONSUMPTION.CONSUMPTION'),
          consumption: 0,
          timeFrame: monthTimeFrame
        };

        controllerInstance.onTimeFrameChange({ timeFrame: monthTimeFrame });
        if (!actualTimeFrame.isSame(originalActualTimeFrame)) {
          controllerInstance.onActualTimeFrameChange({ timeFrame: actualTimeFrame });
        }

        const modalInstance = self.ManualQaConsumptionInputModalService.getModal(parameters);
        modalInstance.result.then(handleModelling);

        function handleModelling(result) {
          const values = getAmendedConsumption(result.consumption, controllerInstance.dataSource.data(), monthTimeFrame);
          controllerInstance.onValueChange({ values });
        }
      }
    };
  }
}

ManualQaMonthlyConsumptionCommand.$inject = $inject;

export default ManualQaMonthlyConsumptionCommand;
