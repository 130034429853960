/**
 * @name confirmOnExit
 *
 * @description
 * Prompts user while he navigating away from the current route (or, as long as this directive
 * is not destroyed) if any unsaved form changes present.
 *
 * @element Attribute
 * @scope
 * @param confirmOnExitFn Scope function which will be called on window refresh/close or AngularS $route change to
 *                          decide whether to display the prompt or not.
 * @param doConfirmOnRefresh boolean that defines whether confirmation is shown on
 *                          page refresh/tab close/browser close or not
 * @param confirmMessage Custom message to display before navigating elsewhere etc.
 *
 * @example
 * Usage:
 * Example Controller: (using controllerAs syntax in this example)
 *
 *      angular.module('AppModule', []).controller('pageCtrl', [function () {
 *          this.isDirty = function () {
 *              // do your logic and return 'true' to display the prompt, or 'false' otherwise.
 *              return true;
 *          };
 *      }]);
 *
 * Template:
 *
 *      <div confirm-on-exit confirm-on-exit-fn="pageCtrl.isDirty()"
 *          confirm-message="All your changes will be lost. Are you sure you want to do this?">
 *
 * @see http://stackoverflow.com/a/28905954/340290
 *
 * @author Manikanta G
 */
export default function() {
  return {
    scope: {
      confirmOnExitFn: '&',
      doConfirmOnRefresh: '<',
      confirmMessage: '@'
    },
    controllerAs: 'confirmOnExit',
    controller: controller
  };
}

controller.$inject = ['$scope', '$window', '$rootScope', 'utils', '$transitions'];

function controller($scope, $window, $rootScope, utils, $transitions) {
  var vm = this;

  vm.onRefresh = function(e) {
    if ($scope.confirmOnExitFn()) {
      e.returnValue = true;
      return true;
    }
  };

  if ($scope.doConfirmOnRefresh) {
    $window.addEventListener('beforeunload', vm.onRefresh);
  }

  vm.confirmChanges = function(confirmMessage) {
    confirmMessage = confirmMessage || utils.localizedString('UNSAVED_CHANGES_CONFIRMATION');

    if ($scope.confirmOnExitFn()) {
      return $window.confirm(confirmMessage);
    }

    return true;
  };

  var onCloseModalStartUnbind = $rootScope.$on('onCloseModalStart', function(event, onCloseModalStartOptions) {
    onCloseModalStartOptions.canClose = vm.confirmChanges($scope.confirmMessage);

    // If we allow the modal to close then remember it so we don't ask for confirmation
    // anymore when state changes.
    if (onCloseModalStartOptions.canClose) {
      vm.modalClosing = true;
    }
  });

  var $stateChangeStartUnbind = $transitions.onStart({}, function(transition) {
    if (!vm.modalClosing && !vm.confirmChanges($scope.confirmMessage)) {
      transition.abort();
    }
  });

  $scope.$on('$destroy', function() {
    $stateChangeStartUnbind();
    onCloseModalStartUnbind();
    if ($scope.doConfirmOnRefresh) {
      $window.removeEventListener('beforeunload', vm.onRefresh);
    }
  });
}
