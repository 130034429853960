<td>
  <span [style.color]="point.series.color">
    <!-- ■ -->
    &#9632;
  </span>
  <ng-container *ngIf="point.dataItem.title ?? point.series.name as title">
    {{ point.dataItem.translateTitle ? (title | translate) : title }}
  </ng-container>
</td>
<td>{{ point.dataItem.timestamp | resolutionTitle: resolution }}</td>
<ng-container *ngIf="point.dataItem.isPercentSerie; else consumptionValue">
  <td style="text-align: right;">
    {{ (point.dataItem.visibleValue * 100) | kendoNumber:'#.#'  }}
  </td>
  <td>%</td>
</ng-container>
<ng-template #consumptionValue>
  <td
    *ngIf="point.dataItem.quantityId | valueFormat: point.dataItem.unitKey : resolution : point.dataItem.derivedId | async as format"
    style="text-align: right;"
  >
    {{ point.dataItem.visibleValue | kendoNumber : format }}
  </td>
  <td>
    {{ point.dataItem.unit }}
  </td>
</ng-template>
