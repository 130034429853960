import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AlarmClient, LogDto } from '@enerkey/clients/alarm';

import { AlarmService } from '../../services/alarm.service';
import { QuantityService } from '../../services/quantity.service';
import { ExtendedFacilityInformation } from '../../interfaces/extended-facility-information';
import { AlarmLogGridItem } from '../shared/alarm-log-grid-item';
import { FacilityService } from '../../services/facility.service';

@Injectable({ providedIn: 'root' })
export class AlarmLogGridItemService {

  public constructor(
    private readonly alarmService: AlarmService,
    private readonly quantityService: QuantityService,
    private readonly alarmClient: AlarmClient,
    private readonly facilityService: FacilityService
  ) { }

  public getItemById(logId: number): Observable<AlarmLogGridItem> {
    return forkJoin([
      this.alarmClient.getLogById(logId),
      this.facilityService.filteredProfileFacilities$.pipe(take(1))
    ]).pipe(
      map(([logItem, facilities]) => new AlarmLogGridItem(
        logItem,
        facilities.find(f => f.facilityId === logItem.facilityId),
        this.alarmService.getAlarmTypeName(logItem.alarmTypeId),
        this.quantityService.getQuantityNameForId(logItem.quantityId)
      ))
    );
  }

  public getItems(alarms: LogDto[], facilities: ExtendedFacilityInformation[]): AlarmLogGridItem[] {
    return alarms.joinInner(
      facilities,
      a => a.facilityId,
      f => f.facilityId,
      (alarm, facility) => new AlarmLogGridItem(
        alarm,
        facility,
        this.alarmService.getAlarmTypeName(alarm.alarmTypeId),
        this.quantityService.getQuantityNameForId(alarm.quantityId)
      )
    );
  }
}
