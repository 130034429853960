import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { SpecificConsumptionConfiguration } from '@enerkey/clients/energy-reporting';

import { RelationalValuesService } from '../../../../shared/services/relational-values.service';
import { EkComboComponent } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

type DerivedValueType = 'specificConsumption' | 'cost' | 'emission';

@Component({
  selector: 'derived-value-single-select',
  templateUrl: '../../../../shared/ek-inputs/ek-combo/ek-combo.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DerivedValueSingleSelectComponent,
    multi: true
  }]
})
export class DerivedValueSingleSelectComponent extends EkComboComponent<number> implements OnDestroy, OnInit {
  @Input() public derivedValueType: DerivedValueType;
  public override disableWhenLoading = true;
  public override noTranslate = true;

  private readonly itemsLoaded$ = new ReplaySubject<void>(1);
  private readonly destroy$ = new Subject<void>();

  public constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private readonly relationalValues: RelationalValuesService,
    private readonly translateService: TranslateService
  ) {
    super(changeDetectorRef);

  }

  public override ngOnInit(): void {
    this.getDerivedValues()
      .pipe(
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: values => {
          const items = [{
            text: this.translateService.instant('ENERGY_MANAGEMENT.CONSUMPTION_TARGET.CHOOSE_PROPERTY'),
            value: 0
          }];
          values.forEach(value => {
            items.push({ text: value.Name, value: value.Id });
          });

          this.items = items;
          this.itemsLoaded$.next();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getDerivedValues(): Observable<SpecificConsumptionConfiguration[]> {
    switch (this.derivedValueType) {
      case 'specificConsumption':
        return this.relationalValues.specificConsumptions$;
      case 'cost':
        return this.relationalValues.costs$;
      case 'emission':
        return this.relationalValues.emissions$;
      default:
        return this.relationalValues.relationalValues$;
    }
  }
}
