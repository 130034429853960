import * as SpreadsheetConstants from './manual-qa-spreadsheet.constants';
import {
  getHeaderCells,
  getRowsCount,
  getSecondaryHeaderCells,
  getSpreadsheetColumnsWidth,
  getSpreadsheetMergedColumns
}                                from './manual-qa-spreadsheet.functions';

const translations = [
  'MQA.INSPECT.SHEET.INSPECTION_PERIOD',
  'MQA.INSPECT.SHEET.MODEL',
  'MQA.INSPECT.SHEET.PREVIEW',
  'MQA.INSPECT.SHEET.COMPARISON_PERIOD',
  'MQA.INSPECT.SHEET.TIMESTAMP',
  'MQA.INSPECT.ABBREVIATIONS.CUMULATIVE',
  'MQA.INSPECT.ABBREVIATIONS.HOURLY'
];

const $inject = ['utils', '$log'];

class ManualQaSpreadsheetService {
  constructor(utils, $log) {
    this.$log = $log;
    this.translations = utils.mapLocalizationTagArrayToLocalizedObject(translations);
  }

  /**
   * Returns spreadsheet options for mqa spreadsheet.
   *
   * @param {Reading[]} readings
   * @param {string} fetchType
   * @param {boolean} useComparisonPeriod
   *
   * @returns {*}
   */
  getSpreadsheetOptions(readings, fetchType, useComparisonPeriod) {
    const columns = getSpreadsheetColumnsWidth(fetchType, useComparisonPeriod);
    const mergedColumns = getSpreadsheetMergedColumns(fetchType, useComparisonPeriod);
    const headerCells = getHeaderCells(this.translations, fetchType, useComparisonPeriod);
    const secondaryHeaderCells = getSecondaryHeaderCells(this.translations, fetchType, useComparisonPeriod);

    return angular.copy({
      toolbar: false,
      sheetsbar: false,
      columnWidth: 70,
      activeSheet: SpreadsheetConstants.DEFAULT_SHEET_NAME,
      rows: getRowsCount(readings),
      defaultCellStyle: {
        fontFamily: 'Open Sans'
      },
      sheets: [
        {
          name: SpreadsheetConstants.DEFAULT_SHEET_NAME,
          frozenRows: SpreadsheetConstants.FROZEN_ROWS,
          columns: columns,
          mergedCells: mergedColumns,
          rows: [
            {
              height: 25,
              cells: headerCells
            },
            {
              height: 20,
              cells: secondaryHeaderCells
            }
          ]
        }
      ],
      columns: columns.length,
      autoAdjustRowHeight: true
    });
  }
}

ManualQaSpreadsheetService.$inject = $inject;

export default ManualQaSpreadsheetService;
