<modal-view-header [headingText]="'ADMIN.ADD_PROFILE.MODAL_TITLE' | translate"></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="profileAddForm"
    (ngSubmit)="submit()"
    id="updateForm"
  >
    <div class="formInputs">
      <input
        formControlName="name"
        type="text"
        kendoTextBox
        *labelBefore="'ADMIN.ADD_PROFILE.PROFILE_NAME' | translate"
      />
      <servicetype-multi-select
        *labelBefore="'ADMIN.ADD_PROFILE.SERVICES' | translate"
        class="services"
        formControlName="services"
      >
      </servicetype-multi-select>
      <service-level-select
        class="serviceLevel"
        *labelBefore="'ADMIN.ADD_PROFILE_SERVICE_LEVEL_LABEL' | translate"
        formControlName="serviceLevel"
      ></service-level-select>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="updateForm"
      [disabled]="!profileAddForm.valid"
    >
      {{ 'ADMIN.SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
