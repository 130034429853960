<form [formGroup]="formGroup">
  <div class="formpart">
    <ek-multi
      formControlName="actionTypes"
      [items]="actionTypeOptions"
      *labelBefore="'LATEST_ACTIONS_WIDGET.TYPES_TO_SHOW' | translate"
    ></ek-multi>
  </div>

  <div class="formpart">
    <ek-combo
      formControlName="executionPhase"
      [items]="executionPhaseOptions"
      *labelBefore="'ACTIONS.EXECUTIONPHASE' | translate"
    ></ek-combo>
  </div>

  <div class="formpart">
    <ng-container *ngIf="!showMobileCount">
      <ek-slider-number-box
        formControlName="count"
        [min]="minCount"
        [max]="maxCount"
        *labelBefore="'LATEST_ACTIONS_WIDGET.NUMBER_OF_ACTIONS_TO_SHOW' | translate"
      ></ek-slider-number-box>
    </ng-container>

    <ng-container *ngIf="showMobileCount">
      <label>{{ 'LATEST_ACTIONS_WIDGET.TYPES_TO_SHOW' | translate }}</label>
      <ek-slider-number-box
        formControlName="mobileCount"
        [min]="minCount"
        [max]="maxCount"
        *labelBefore="'LATEST_ACTIONS_WIDGET.NUMBER_OF_ACTIONS_TO_SHOW' | translate"
      ></ek-slider-number-box>
    </ng-container>
  </div>
</form>
