import { map, Observable, shareReplay } from 'rxjs';

import { ReportType } from '../shared/report-type';
import { ReportingSearchFormValue } from '../shared/reporting-search-form-value';

type SearchParam = keyof ReportingSearchFormValue;

type ReportTypeOptions = {
  disabledParams?: SearchParam[],
  hidePresentation?: boolean;
  hideMeterOrFacilityInfo?: boolean;
}

export const reportTypeOptions: Record<ReportType, ReportTypeOptions> = {
  [ReportType.Table]: {
    disabledParams: ['temperature'],
    hidePresentation: true,
  },
  [ReportType.Period]: {
    disabledParams: ['showSummedConsumption']
  },
  [ReportType.Trend]: {
    disabledParams: ['minMaxAvg', 'comparability', 'targetTypes', 'showSummedConsumption']
  },
  [ReportType.Sum]: {
    disabledParams: ['minMaxAvg', 'temperature', 'showSummedConsumption'],
    hideMeterOrFacilityInfo: true
  },
  [ReportType.Forecast]: {
    disabledParams: [
      'minMaxAvg',
      'comparability',
      'distributionType',
      'distributionAsPercent',
      'comparability',
      'temperature',
      'showSummedConsumption'
    ]
  },
  [ReportType.Overview]: {
    disabledParams: ['showSummedConsumption']
  },
  [ReportType.YearlyTrend]: {
    hideMeterOrFacilityInfo: true,
    hidePresentation: true
  }
};

export abstract class ReportTypeOptionsService {
  public readonly disabledParams$: Observable<(keyof ReportingSearchFormValue)[]>;
  public readonly showPresentation$: Observable<boolean>;
  public readonly showMeterOrFacilityInfo$: Observable<boolean>;

  private readonly reportTypeOptions$: Observable<ReportTypeOptions>;

  public constructor(reportType$: Observable<ReportType>) {
    this.reportTypeOptions$ = reportType$.pipe(
      map(report => reportTypeOptions[report]),
      shareReplay(1)
    );

    this.showPresentation$ = this.reportTypeOptions$.pipe(
      map(options => !options.hidePresentation)
    );

    this.showMeterOrFacilityInfo$ = this.reportTypeOptions$.pipe(
      map(options => !options.hideMeterOrFacilityInfo)
    );

    this.disabledParams$ = this.reportTypeOptions$.pipe(
      map(options => options.disabledParams ?? [])
    );
  }
}
