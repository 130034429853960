const $inject = ['ManualQaInspectModalService'];

class ManualQaInspectButtonController {
  constructor(ManualQaInspectModalService) {
    this.ManualQaInspectModalService = ManualQaInspectModalService;
  }

  openInspectModal() {
    return this.ManualQaInspectModalService.getInspectModal(this.meterIds, this.timeFrame, this.searchType);
  }
}

ManualQaInspectButtonController.$inject = $inject;

export default ManualQaInspectButtonController;
