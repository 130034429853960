import { Pipe, PipeTransform } from '@angular/core';
import { CldrIntlService } from '@progress/kendo-angular-intl';

@Pipe({
  name: 'toCurrency'
})
export class ToCurrencyPipe implements PipeTransform {

  public constructor(
    private intlService: CldrIntlService
  ) { }

  public transform(
    value?: number | null,
    currency: string | null = null,
    maximumFractionDigits?: number | null,
    minimumFractionDigits?: number | null
  ): string {
    if (!Number.isFinite(value)) {
      return '';
    }
    const formatted = this.intlService.formatNumber(value, {
      currency: currency,
      currencyDisplay: 'symbol',
      style: currency ? 'currency' : 'decimal',
      useGrouping: true,
      maximumFractionDigits: maximumFractionDigits ? maximumFractionDigits : 0,
      minimumFractionDigits: minimumFractionDigits ? minimumFractionDigits : 0,
    });

    return formatted;
  }
}
