import TimeFrameController from './time-frame-controller';
import template from 'raw-loader!./time-frame.html';

const TimeFrameComponent = {
  template: template,
  controller: TimeFrameController,
  controllerAs: 'vm',
  bindings: {
    timeFrame: '<',
    format: '@'
  }
};

export default TimeFrameComponent;
