
import { InterfaceOf } from '@enerkey/ts-utils';

import { ReportingClient } from '.';

export class ReportingClientMock implements InterfaceOf<ReportingClient> {
  public getNationalCostsByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterBasedCostsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterBasedCostsByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getNationalCostsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterBasedCosts(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterBasedDistributionTaxCosts(): never {
    throw new Error('Method not implemented.');
  }
  public getSumConsumptions(): never {
    throw new Error('Method not implemented.');
  }
  public getQuantityUnits(): never {
    throw new Error('Method not implemented.');
  }
  public getDistributedConsumptionsForMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getDistributedConsumptions(): never {
    throw new Error('Method not implemented.');
  }
  public getGroupedConsumptionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getGroupedNormalizedConsumptionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getConsumptionsForMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizedConsumptionsForMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getEnergyTargets(): never {
    throw new Error('Method not implemented.');
  }
  public getConsumptionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizedConsumptionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getDerivedConsumptionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizedDerivedConsumptionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getSumConsumptionsGroupedByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizedSumConsumptionsGroupedByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getDistributedConsumptionsByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getDerivedConsumptionsByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizedDerivedConsumptionsByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getEnergyTargetsForQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getCo2EmissionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizedCo2EmissionsForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public getCo2EmissionsByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizedCo2EmissionsByQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getNationalCostsForMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getEnergyTargetForMeters(): never {
    throw new Error('Method not implemented.');
  }
}
