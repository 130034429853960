import 'ngtouch';
import 'angular-foundation-6';
import 'angular-translate';
import 'angular-translate-storage-local';
import 'angular-translate-storage-cookie';
import { downgradeInjectable } from '@angular/upgrade/static';

import EnerKeyServicesModule from '../../../services';
import LodashModule from '../../lodash';

import configurationApi from './configuration-api';
import distributionTypes from './distributiontypes';
import errorCodes from './errorcodes';
import relatedValues from './relatedvalues';
import timeSeries from './timeseries';
import { Weather } from './weather';

// TODO: split reportingObject so that facilities can be injected separately
// import ReportingObjectsModule from '../../reportingobjects';

import { QuantityService } from '../../../shared/services/quantity.service';

export default angular.module('Configuration.Models', [
  EnerKeyServicesModule.name,
  LodashModule.name,
  // ReportingObjectsModule.name,
  'mm.foundation'
])
  .service('quantities', downgradeInjectable(QuantityService))
  .factory('configurationApi', configurationApi)
  .factory('distributionTypes', distributionTypes)
  .factory('errorCodes', errorCodes)
  .factory('relatedValues', relatedValues)
  .factory('timeSeries', timeSeries)
  .service('weather', Weather)
;
