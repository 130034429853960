import { downgradeInjectable } from '@angular/upgrade/static';

import mrcDataParser                 from './mrc-data-parser';
import MrcFilterService              from './mrc-filter-service';
import mrcInputValidation            from './mrc-input-validation';
import mrcModals                     from './mrc-modals';
import mrcapi                        from './mrcapi';
import { MrcReadingsListingService } from './mrc-readings-listing-service.ts';

export default angular.module('Mrc.Services', [])
  .factory('mrcDataParser', mrcDataParser)
  .factory('MrcFilterService', MrcFilterService)
  .factory('mrcInputValidation', mrcInputValidation)
  .factory('mrcModals', mrcModals)
  .factory('mrcapi', mrcapi)
  .factory('mrcReadingsListingService', downgradeInjectable(MrcReadingsListingService))
;
