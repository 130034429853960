import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { EkComboItemTemplateDirective } from '../ek-combo-item-template.directive';
import { ComboItem } from '../ek-combo/ek-combo.component';

@Component({
  selector: 'ek-filterable-combo',
  templateUrl: './ek-filterable-combo.component.html',
  styleUrls: ['./ek-filterable-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EkFilterableComboComponent),
    multi: true
  }],
})
export class EkFilterableComboComponent<T = unknown> implements ControlValueAccessor, OnInit, OnChanges {

  @Input() public items: ComboItem<T>[] = [];
  @Input() public showDefaultItem: boolean = false;
  @Input() public loading: boolean = false;
  @Input() public noTranslate: boolean = false;
  @Input() public disableWhenLoading: boolean = false;
  @Input() public virtual: boolean = false;
  @Input() public filterable: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public threshold: number = 1;
  @Input() public valuePrimitive: boolean = true;

  @ContentChild(EkComboItemTemplateDirective)
  public readonly contentTemplate: EkComboItemTemplateDirective<ComboItem<T>>;

  public data: ComboItem<T>[];
  public value: T = undefined;
  public disabled: boolean = false;

  public readonly defaultItem: ComboItem<T> = { text: '—', value: null };

  /** CVA Hander */ private _onChange: (value: T) => void;
  /** CVA Hander */ private _onTouch: () => void;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.data = this.items;
  }

  // Keep data updated with items
  public ngOnChanges(): void {
    this.data = this.items;
  }

  /** Kendo component event */
  public valueChange(value: T | null): void {
    this._onChange?.(value);
  }

  /** Kendo component event */
  public blur(): void {
    this._onTouch?.();
  }

  /** ControlValueAccessor interface */
  public writeValue(value: T | null): void {
    this.value = value;
    this.changeDetector.markForCheck();
  }

  /** ControlValueAccessor interface */
  public registerOnChange(fn: (value: T) => void): void {
    this._onChange = fn;
  }

  /** ControlValueAccessor interface */
  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  /** ControlValueAccessor interface */
  public setDisabledState(isDisabled: boolean): void {
    if (!!this.disabled !== !!isDisabled) {
      this.disabled = isDisabled;
      this.changeDetector.detectChanges();
    }
  }

  public filterChange(filter: string): void {
    if (filter?.length >= this.threshold) {
      filter = filter.toLowerCase();
      this.data = this.items.filter(i => i.text.toLowerCase().includes(filter));
    } else {
      this.data = this.items;
    }
    this.changeDetector.detectChanges();
  }
}
