import EnerkeyServiceModule     from '../../../../services';
import ManualQaServiceModule    from '../../services';
import SearchTypeRadioComponent from './search-type-radio-component';
import SearchTypeRadioService   from './search-type-radio-service';

const dependencies = [EnerkeyServiceModule.name, ManualQaServiceModule.name];

const SearchTypeRadioModule = angular
  .module('app.modules.manual-qa.components.search-type-radio', dependencies)
  .component('searchTypeRadio', SearchTypeRadioComponent)
  .service('SearchTypeRadioService', SearchTypeRadioService)
;

export default SearchTypeRadioModule;
