import _ from 'lodash';
import { firstValueFrom } from 'rxjs';

import { TagList } from '@enerkey/clients/facility';
import { ObservablePool } from '@enerkey/rxjs';

import { removeSpreadsheetOrphans } from '../../../../shared/ek-kendo/kendo.functions';
import { getChangedFacilityTags, parseFacilityTagsFromString } from '../../../../constants/facility.constant';
import { setOleDatesToDates } from '../../shared/facility-date-functions';

AdminFacilityController.$inject = [
  '$element', '$q', '$state',
  '$timeout', '$window', 'facilities',
  'HttpPendingRequestsService', 'KendoFunctions', 'utils',
  'UserService', 'SpreadsheetService', 'AdminFacilitySpreadsheetService',
  'AdminFacilitySpreadsheetSelectionService', 'AdminSpreadsheetTranslationsService', 'FacilityClient'
];

/* eslint-disable prefer-template */

export default function AdminFacilityController(
  $element, $q, $state,
  $timeout, $window, facilities,
  HttpPendingRequestsService, KendoFunctions, utils,
  UserService, SpreadsheetService, AdminFacilitySpreadsheetService,
  AdminFacilitySpreadsheetSelectionService, AdminSpreadsheetTranslationsService, FacilityClient
) {
  const vm = this;

  const pool = new ObservablePool(1);

  vm.$onInit = onInit;
  vm.$onDestroy = onDestroy;
  let resizeFn = angular.noop();
  vm.facilityInformationSelection = AdminFacilitySpreadsheetSelectionService.getUpdateFields();
  vm.localizedMultiSelectTexts = utils.istevenMultiSelectTranslation();
  vm.firstDataRowChar = SpreadsheetService.indexToColumnCharacter(0);
  vm.lastDataRowChar = SpreadsheetService.indexToColumnCharacter(0);
  vm.colors = SpreadsheetService.getColors();

  vm.messages = AdminSpreadsheetTranslationsService.getTranslations().messages;

  vm.steps = [
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.TYPE_SELECT'),
      buttonDisabledFn: isInitialized,
      isReturnable: true,
      clickFn: selectionDone,
      rollbackFn: unInitialize
    },
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.IMPORT'),
      isReturnable: true,
      clickFn: validateSheet,
      rollbackFn: enableSheet
    },
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.VALIDATE'),
      isReturnable: false,
      clickFn: saveValues,
      buttonDisabledFn: isSavePossible,
      customButtonText: utils.localizedString('ADMIN.SPREADSHEET.START_SAVING')
    },
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.SAVE'),
      isReturnable: false,
      clickFn: stopSaving,
      customButtonText: utils.localizedString('ADMIN.SPREADSHEET.STOP_SAVING')
    },
    {
      text: utils.localizedString('ADMIN.SPREADSHEET.READY'),
      hideButton: true
    }
  ];

  function unInitialize() {
    vm.initialized = false;
  }

  function enableSheet() {
    vm.spreadsheet = getSpreadsheetInstance();
    vm.spreadsheet.activeSheet().range(vm.firstDataRowChar + ':' + vm.lastDataRowChar + vm.rowCount).enable(true);
  }

  function saveValues() {
    vm.processingFacilities = [];
    firstValueFrom(FacilityClient.getFacilities(vm.facilityIds))
      .then(result => {
        let promises = [$q.resolve()];
        const updatedFacilities = [];
        const facilityMassUpdateDefer = $q.defer();
        const sheet = vm.spreadsheet.activeSheet();
        const dataRows = sheet.range(SpreadsheetService.indexToColumnCharacter(0) + 2 + ':' +
          SpreadsheetService.indexToColumnCharacter(vm.selectedInformation.length) + vm.rowCount).values();
        dataRows.forEach((dataRow, index) => {
          const facility = _.find(result, { enegiaId: dataRow[0] });
          if (!facility) {
            return;
          }

          const changes = checkRowForChanges(facility, dataRow);
          const rowPromises = getRowPromises(changes, facility, facilityMassUpdateDefer);

          if (changes.facility || changes.address || changes.externalId || changes.tags) {
            vm.processingFacilities.push(facility.enegiaId);
            setProcessingStatus(index);
          }
          if (changes.facility) {
            updatedFacilities.push(facility);
          }

          if (rowPromises.length > 0) {
            promises = promises.concat(rowPromises);
            $q.all(rowPromises)
              .then(() => {
                setSuccessStatus(index);
              })
              .catch(() => {
                setErrorStatus(index);
              })
              .finally(() => {
                _.remove(vm.processingFacilities, facility.enegiaId);
              });
          } else {
            setNoChanges(index);
          }
        });
        if (updatedFacilities.length > 0) {
          setOleDatesToDates(updatedFacilities);
          promises = promises.concat(updateFacilities(updatedFacilities, facilityMassUpdateDefer));
        }
        $q.all(promises).finally(setFinalStep);
      });
  }

  function handleTechnicalSpecifications(facility) {
    return firstValueFrom(FacilityClient.upsertTechnicalSpecifications(facility.id, facility.technicalSpecifications))
      .then(() => facilities.clearPropertiesRelatedCache());
  }

  function handleTags(facility, changes) {
    const facilityChanges = changes.tagMap[facility.enegiaId];
    const facilityIdParam = [facility.id];
    const promiseArray = [];

    if (Array.hasItems(facilityChanges.added)) {
      promiseArray.push(
        firstValueFrom(pool.queue(FacilityClient.addFacilityTags(new TagList({
          facilityIds: facilityIdParam,
          tags: facilityChanges.added,
        }))))
          .then(() => facilities.clearPropertiesRelatedCache())
      );
    }

    if (Array.hasItems(facilityChanges.removed)) {
      promiseArray.push(
        firstValueFrom(pool.queue(FacilityClient.removeFacilityTags(new TagList({
          facilityIds: facilityIdParam,
          tags: facilityChanges.removed,
        }))))
          .then(() => facilities.clearPropertiesRelatedCache())
      );
    }

    return promiseArray;
  }

  function getRowPromises(changes, facility, facilityMassUpdatePromise) {
    const rowPromises = [];
    if (changes.externalId) {
      rowPromises.push(handleExternalChangeSave(facility));
    }
    if (changes.facility) {
      rowPromises.push(facilityMassUpdatePromise.promise);
    }
    if (changes.address) {
      rowPromises.push(handleAddressChange(facility));
    }
    if (changes.technicalSpecifications) {
      rowPromises.push(handleTechnicalSpecifications(facility));
    }
    if (changes.tags) {
      rowPromises.push(...handleTags(facility, changes));
    }
    return rowPromises;
  }

  function checkRowForChanges(facility, dataRow) {
    const changes = {
      facility: false,
      address: false,
      externalId: false,
      tags: false,
      tagMap: {}, // changed tags by enegia id,
    };

    vm.selectedInformation.forEach((option, index) => {
      const rowIndex = index + 1;

      // tags need some parsing and foreplay to work
      if (option.name === 'tags') {
        const inSheet = parseFacilityTagsFromString(dataRow[rowIndex]);
        const inFacility = _.get(facility, option.name, []);
        const tagChanges = getChangedFacilityTags(inFacility, inSheet);

        if (tagChanges.anyChanges) {
          const currentEnegiaId = dataRow[0]; // first column is enegia id
          changes.tags = true;
          changes.tagMap[currentEnegiaId] = tagChanges;
        }
      } else if (_.get(facility, option.name, '') !== dataRow[rowIndex]) {
        switch (option.arrayName) {
          case 'addresses':
            changes.address = true;
            break;
          case 'externalIds':
            changes.externalId = true;
            break;
          case 'technicalSpecifications':
            changes.technicalSpecifications = true;
            break;
          default:
            changes.facility = true;
            break;
        }
        _.set(facility, option.name, dataRow[rowIndex]);
      }
    });

    return changes;
  }

  function handleExternalChangeSave(facility) {
    if (facility.externalIds.externalId || facility.externalIds.externalOrganizationId) {
      return facilities.addOrUpdateExternalIds(facility.id, facility.externalIds);
    } else {
      return facilities.deleteExternalIds(facility.id);
    }
  }

  function handleAddressChange(facility) {
    if (facility.addresses[0].id) {
      return facilities.updateAddress(facility.id, facility.addresses[0]);
    } else {
      return facilities.addAddress(facility.id, facility.addresses[0]);
    }
  }

  function updateFacilities(updatedFacilities, facilityMassUpdatePromise) {
    return facilities.updateFacilities(updatedFacilities)
      .then(() => {
        facilityMassUpdatePromise.resolve();
      })
      .catch(() => {
        facilityMassUpdatePromise.reject();
      });
  }

  function setFinalStep() {
    vm.currentStep = vm.steps[vm.steps.length - 1];
  }

  function validateSheet() {
    vm.isSpreadsheetInvalid = false;
    vm.spreadsheet = getSpreadsheetInstance();
    vm.spreadsheet.activeSheet().range(vm.firstDataRowChar + ':' + vm.lastDataRowChar + vm.rowCount).enable(false);
    const sheet = vm.spreadsheet.activeSheet();
    const range = sheet.range(vm.firstDataRowChar + 2 + ':' + vm.lastDataRowChar + vm.rowCount);
    range.forEachCell((row, column, cell) => {
      if (cell.validation && !cell.validation.value) {
        vm.isSpreadsheetInvalid = true;
      }
    });
  }

  function setErrorStatus(index) {
    sendStatusBatch(index, vm.messages.errorStatus, vm.colors.error);
  }

  function setSuccessStatus(index) {
    sendStatusBatch(index, vm.messages.savedStatus, vm.colors.success);
  }

  function setProcessingStatus(index) {
    sendStatusBatch(index, vm.messages.savingStatus, vm.colors.default);
  }

  function setCanceledStatus(index) {
    sendStatusBatch(index, vm.messages.canceledStatus, vm.colors.error);
  }

  function setNoChanges(index) {
    sendStatusBatch(index, vm.messages.noChange, vm.colors.default);
  }

  function sendStatusBatch(index, status, color) {
    const sheet = vm.spreadsheet.activeSheet();

    sheet.batch(() => {
      const actualIndex = index + 2;
      const statusRange = sheet.range(
        SpreadsheetService.indexToColumnCharacter(vm.selectedInformation.length + 1) + actualIndex
      );
      statusRange.value(status);
      statusRange.color(color);

    }, { layout: true });
  }

  function isSavePossible() {
    return vm.isSpreadsheetInvalid;
  }

  function selectionDone() {
    vm.lastDataRowChar = SpreadsheetService.indexToColumnCharacter(vm.selectedInformation.length + 1);
    const promise =
      AdminFacilitySpreadsheetService.getFacilitySpreadsheetOptions(vm.selectedInformation, vm.facilityIds);
    promise.then(handleSuccess);
  }

  function handleSuccess(result) {
    vm.spreadsheetOptions = result;
    vm.rowCount = result.rows;
    vm.spreadsheet = getSpreadsheetInstance();
    vm.initialized = true;
    $timeout(() => {
      resizeKendo();
      vm.onSpreadsheetCreate({ spreadsheet: vm.spreadsheet });
    });
  }

  function stopSaving() {
    HttpPendingRequestsService.cancelAll();
    vm.stop = true;
    _.forEach(vm.processingFacilities, enegiaId => {
      setCanceledStatus(enegiaId);
    });
    setFinalStep();
  }

  function isInitialized() {
    return vm.selectedInformation && vm.selectedInformation.length < 1;
  }

  function onInit() {
    $timeout(() => {
      resizeKendo();
    });
  }

  function onDestroy() {
    if (vm.spreadsheet) {
      vm.spreadsheet.destroy();
    }
    angular.element($window).off('resize', resizeFn);
    removeSpreadsheetOrphans();
    pool.destroy();
  }

  /**
   * Returns spreadsheet instance. Needed f.e after changing
   * spreadsheetOptions because k-rebind recreates the spreadsheet
   *
   * @return {*}
   */
  function getSpreadsheetInstance() {
    return $element
      .find('#spreadsheet')
      .data('kendoSpreadsheet');
  }

  function resizeKendo() {
    vm.spreadsheet = getSpreadsheetInstance();
    resizeFn = _.debounce(() => {
      KendoFunctions.resizeKendoComponent(vm.spreadsheet, '.spreadsheet-row');
    }, 200);

    angular.element($window).on('resize', resizeFn);

    KendoFunctions.resizeKendoComponent(vm.spreadsheet, '.spreadsheet-row');
  }

}
