AdminSpreadsheetTranslationsService.$inject = ['utils'];

export default function AdminSpreadsheetTranslationsService(utils) {
  const vm = this;

  vm.translations = {
    enegiaId: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.ENEGIA_ID.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.ENEGIA_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.ENEGIA_ID.TITLE")
    },
    propertyId: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.PROPERTY_ID.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.PROPERTY_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.PROPERTY_ID.TITLE")
    },
    validFrom: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.VALID_FROM.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.VALID_FROM.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.VALID_FROM.TITLE")
    },
    value: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.VALUE.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.VALUE.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.VALUE.TITLE")
    },
    quantityId: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.QUANTITY_ID.HEADING"),
      message: utils.localizedString("ADMIN.SPREADSHEET.QUANTITY_ID.MESSAGE_ON_EXCEL"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.QUANTITY_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.QUANTITY_ID.TITLE")
    },
    shareOfTemperatureFixing: {
      heading: utils.localizedString('PROPERTIES.SHAREOFTEMPERATUREFIXING'),
      title: utils.localizedString('ADMIN.SPREADSHEET.SHAREOFTEMPERATUREFIXING.TITLE'),
      message: utils.localizedString('ADMIN.SPREADSHEET.SHAREOFTEMPERATUREFIXING.MESSAGE')
    },
    quantityName: { heading: utils.localizedString("ADMIN.SPREADSHEET.QUANTITY_NAME.HEADING") },
    status: { heading: utils.localizedString("ADMIN.SPREADSHEET.STATUS.HEADING") },
    facilityName: { heading: utils.localizedString("ADMIN.SPREADSHEET.FACILITY_NAME.HEADING") },
    reportingObjectId: { heading: utils.localizedString("ADMIN.SPREADSHEET.FACILITY_ID.HEADING") },
    propertyName: { heading: utils.localizedString("ADMIN.SPREADSHEET.PROPERTY_NAME.HEADING") },
    propertyType: { heading: utils.localizedString("ADMIN.SPREADSHEET.PROPERTY_TYPE.HEADING") },
    buildingClassId: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.BUILDING_CLASS_ID.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.BUILDING_CLASS_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.BUILDING_CLASS_ID.TITLE")
    },
    buildingClassNumericName: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.BUILDING_CLASS_NUMERIC_NAME.HEADING")
    },
    buildingClassName: { heading: utils.localizedString("ADMIN.SPREADSHEET.BUILDING_CLASS_NAME.HEADING") },
    localeId: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.LOCALE_ID.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.LOCALE_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.LOCALE_ID.TITLE")
    },
    localeTimeZone: { heading: utils.localizedString("ADMIN.SPREADSHEET.LOCALE_TIME_ZONE.HEADING") },
    localeCurrency: { heading: utils.localizedString("ADMIN.SPREADSHEET.LOCALE_CURRENCY.HEADING") },
    localeCountryCode: { heading: utils.localizedString("ADMIN.SPREADSHEET.LOCALE_COUNTRY_CODE.HEADING") },
    ownership: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.OWNERSHIP_ID.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.OWNERSHIP_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.OWNERSHIP_ID.TITLE")
    },
    ownershipName: { heading: utils.localizedString("ADMIN.SPREADSHEET.OWNERSHIP_NAME.HEADING") },
    electricityTaxClass: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.ELECTRICITY_TAX_CLASS_ID.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.ELECTRICITY_TAX_CLASS_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.ELECTRICITY_TAX_CLASS_ID.TITLE")
    },
    meterTypeId: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.METER_TYPE_ID.HEADING"),
      messageTemplate: utils.localizedString("ADMIN.SPREADSHEET.METER_TYPE_ID.MESSAGE"),
      titleTemplate: utils.localizedString("ADMIN.SPREADSHEET.METER_TYPE_ID.TITLE")
    },
    meterTypeName: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.METER_TYPE_NAME.HEADING")
    },
    meterId: { heading: utils.localizedString("ADMIN.SPREADSHEET.METER_ID.HEADING") },
    meterName: { heading: utils.localizedString("ADMIN.SPREADSHEET.METER_NAME.HEADING") },
    meterDescription: { heading: utils.localizedString("ADMIN.SPREADSHEET.METER_DESCRIPTION.HEADING") },
    meterFactor: { heading: utils.localizedString("ADMIN.SPREADSHEET.FACTOR.HEADING") },
    twoTimeMeasurement: { heading: utils.localizedString("ADMIN.SPREADSHEET.TWO_TIME_MEASUREMENT.HEADING") },
    floating: { heading: utils.localizedString("ADMIN.SPREADSHEET.FLOATING.HEADING") },
    related: { heading: utils.localizedString("ADMIN.SPREADSHEET.RELATED.HEADING") },
    hierarchy: { heading: utils.localizedString("ADMIN.SPREADSHEET.HIERARCHY.HEADING") },
    selectAllSubmeters: { heading: utils.localizedString("ADMIN.SPREADSHEET.SELECT_ALL_SUBMETERS.HEADING") },
    customerMeterIdentifier: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.CUSTOMER_METER_IDENTIFIER.HEADING")
    },
    automaticReadingStartTime: { heading: utils.localizedString("AUTOMATIC_READING_START_TIME") },
    parentId: { heading: utils.localizedString("ADMIN.SPREADSHEET.PARENT_ID.HEADING") },
    electricityTaxClassName: {
      heading: utils.localizedString("ADMIN.SPREADSHEET.ELECTRICITY_TAX_CLASS_NAME.HEADING")
    },
    heatingMethodName: { heading: utils.localizedString("ADMIN.SPREADSHEET.HEATING_METHOD_NAME.HEADING") },
    heatingMethodId: { heading: utils.localizedString("ADMIN.SPREADSHEET.HEATING_METHOD_ID.HEADING") },
    sheets: {
      inputPage: utils.localizedString("ADMIN.SPREADSHEET.INPUT_PAGE"),
      propertyPage: utils.localizedString("ADMIN.SPREADSHEET.PROPERTY_PAGE"),
      facilityPage: utils.localizedString("ADMIN.SPREADSHEET.FACILITY_PAGE"),
      buildingClassPage: utils.localizedString("ADMIN.SPREADSHEET.BUILDING_CLASS_PAGE"),
      localePage: utils.localizedString("ADMIN.SPREADSHEET.LOCALE_PAGE"),
      ownershipPage: utils.localizedString("ADMIN.SPREADSHEET.OWNERSHIP_PAGE"),
      electricityTaxClassPage: utils.localizedString("ADMIN.SPREADSHEET.ELECTRICITY_TAX_CLASS_PAGE"),
      quantityPage: utils.localizedString("ADMIN.SPREADSHEET.QUANTITY_PAGE"),
      heatingTypesPage: utils.localizedString("ADMIN.SPREADSHEET.HEATING_TYPES_PAGE"),
      meterTypePage: utils.localizedString("ADMIN.SPREADSHEET.METER_TYPES_PAGE"),
      shareOfTemperatureFixingPage: utils.localizedString('ADMIN.SPREADSHEET.SHAREOFTEMPERATUREFIXING_PAGE')
    },
    messages: {
      errorStatus: utils.localizedString("ADMIN.SPREADSHEET.ERROR"),
      conflictStatus: utils.localizedString("ADMIN.SPREADSHEET.CONFLICT"),
      savedStatus: utils.localizedString("ADMIN.SPREADSHEET.SAVED"),
      savingStatus: utils.localizedString("ADMIN.SPREADSHEET.SAVING"),
      canceledStatus: utils.localizedString("ADMIN.SPREADSHEET.CANCELED"),
      noChange: utils.localizedString("ADMIN.SPREADSHEET.NO_CHANGE")
    }
  };

  return {
    getTranslations: getTranslations
  };

  function getTranslations() {
    return vm.translations;
  }
}
