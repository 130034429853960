import { Pipe, PipeTransform } from '@angular/core';

import { LogLiteDto } from '@enerkey/clients/alarm';

@Pipe({
  name: 'meterReportAlarms'
})
export class MeterReportAlarmsPipe implements PipeTransform {
  public transform(alarms: LogLiteDto[], meterId: number): LogLiteDto[] {
    return alarms?.filter(a => a.meterId === meterId);
  }
}
