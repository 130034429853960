import _ from 'lodash';
import { Subject } from 'rxjs';

function LoadingService($rootScope, $http, $timeout) {
  const isLoading$ = new Subject();

  const status = {
    loading: false,
    force: false,
    pendingHttpRequestsCount: 0,
    pendingPromisesCount: 0
  };

  function checkIfLoading() {
    const wasLoading = status.loading;
    status.loading = status.pendingHttpRequestsCount > 0 || status.pendingPromisesCount > 0 || status.force;
    if (status.loading !== wasLoading) {
      isLoading$.next(status.loading);
    }
  }

  function isLoading() {
    return status.loading;
  }

  function forceLoadingUntil(timeout) {
    timeout = timeout || 750;
    status.force = true;
    checkIfLoading();

    $timeout(() => {
      status.force = false;
      checkIfLoading();
    }, timeout);
  }

  function setLoadingUntilResolved(promise) {
    let promiseResolved = false;

    status.pendingPromisesCount++;
    checkIfLoading();

    function pendingPromiseFinished() {
      if (!promiseResolved) {
        promiseResolved = true;
        status.pendingPromisesCount--;
        checkIfLoading();
      }
    }

    // handle pending promise (not later than 30s)
    promise.finally(pendingPromiseFinished);
    $timeout(pendingPromiseFinished, 30000);
  }

  // watch for pending requests
  const $scope = $rootScope.$new();
  $scope.$http = $http;
  $scope.$watchCollection(
    '$http.pendingRequests',
    pendingRequests => {
      status.pendingHttpRequestsCount = _.filter(pendingRequests, request => {
        // filter out silent http requests
        if (request.silent) {
          return false;
        }
        // filter out assets and templates
        if (_.startsWith(request.url, 'assets') || _.startsWith(request.url, 'template')) {
          return false;
        }
        return true;
      }).length;
      checkIfLoading();
    }
  );

  function setLoading(boolean) {
    isLoading$.next(boolean);
    status.loading = boolean;
  }

  function forceLoading(value) {
    status.force = !!value;
    checkIfLoading();
  }

  return {
    isLoading: isLoading,
    setLoading: setLoading,
    forceLoadingUntil: forceLoadingUntil,
    setLoadingUntilResolved: setLoadingUntilResolved,
    forceLoading: forceLoading,
    isLoading$: isLoading$
  };
}

LoadingService.$inject = ['$rootScope', '$http', '$timeout'];

export default LoadingService;
