<kendo-slider
  [(ngModel)]="value"
  [tickPlacement]="'none'"
  [min]="min"
  [max]="max"
  [smallStep]="1"
  [showButtons]="false"
  [disabled]="disabled"
  (ngModelChange)="valueChanged($event)"
  (blur)="blur()"
></kendo-slider>
<kendo-numerictextbox
  [(ngModel)]="value"
  [min]="min"
  [max]="max"
  [step]="1"
  [decimals]="0"
  [format]="'n0'"
  [autoCorrect]="true"
  [disabled]="disabled"
  (ngModelChange)="valueChanged($event)"
  (blur)="blur()"
></kendo-numerictextbox>
