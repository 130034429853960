import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'jpeg'
})
export class JpegPipe implements PipeTransform {
  public constructor(private sanitizer: DomSanitizer) {
  }

  public transform(value: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpg;base64,${value}`);
  }
}
