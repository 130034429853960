import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, map, Observable, of, tap } from 'rxjs';

import { ColorService } from '../../../shared/services/color.service';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ForecastReportBaseService } from './forecast-report-base.service';
import { ReportingDataService } from './reporting-data.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class ForecastReportService extends ForecastReportBaseService {
  public constructor(
    private readonly reportingDataService: ReportingDataService,
    translateService: TranslateService,
    colorService: ColorService,
    private readonly toaster: ToasterService
  ) {
    super(translateService, colorService);
  }

  public getForecastData(
    params: ReportingSearchParams,
    threshold: number,
    ids: number[]
  ): Observable<ReportingSeriesByFacility> {
    if (!Array.hasItems(params.quantityIds)) {
      return of(null);
    }
    if (this.hasUnsupportedParams(params)) {
      this.toaster.info('REPORTING.ERRORS.UNSUPPORTED_FORECAST_TIMEPARAMS');
      return of(null);
    }
    return forkJoin({
      consumptions: params.showConsumption
        ? forkJoin({
          measured: this.reportingDataService.getMeasuredValues(
            ReportType.Forecast,
            params,
            ids,
            true,
            this.consumptionSerieSettings
          ),
          normalized: this.reportingDataService.getNormalizedValues(
            ReportType.Forecast,
            params,
            ids,
            true,
            this.consumptionSerieSettings
          ),
        }).pipe(
          tap(series => {
            [series.measured, series.normalized].forEach(serie => {
              this.addForecastSeries(serie, ids, threshold, params.unit, false);
            });
          })
        )
        : super.getEmptySeries(),
      derivedValues: forkJoin({
        measured: this.reportingDataService.getMeasuredDerivedValues(
          ReportType.Forecast,
          params,
          ids,
          true,
          this.derivedSerieSettings
        ),
        normalized: this.reportingDataService.getNormalizedDerivedValues(
          ReportType.Forecast,
          params,
          ids,
          true,
          this.derivedSerieSettings
        ),
      }).pipe(
        tap(series => [series.measured, series.normalized].forEach(serie => {
          this.addForecastSeries(serie, ids, threshold, params.unit, true);
        }))
      ),
      targets: this.reportingDataService.getTargets(params, ids, true)
    }).pipe(
      map(({ consumptions, derivedValues, targets }) => this.mapValuesByIds({
        values: consumptions.measured,
        normalizedValues: consumptions.normalized,
        measuredDerivedValues: derivedValues.measured,
        normalizedDerivedValues: derivedValues.normalized,
        targets,
        ids
      }))
    );
  }
}
