import _ from 'lodash';

const $inject = ['$scope', '$state', 'erReportSettingsService'];

class ReportComponentAdapterController {
  constructor($scope, $state, erReportSettingsService) {
    this.$scope = $scope;
    this.$state = $state;
    this.erReportSettingsService = erReportSettingsService;

    this.parentInitialized = false;
    this.erReportSettings = this.erReportSettingsService.getInstance();
    this.initialize();
  }

  initialize() {
    this.optionsChangeUnbind = this.erReportSettings.setCallback(this.updateReportParams.bind(this));
    this.erReportSettings.changeSetting('reportType', this.$state.$current.data.reportType);
    this.reportParams = this.erReportSettings.getSettings();

    this.$scope.parentInitialized().then(() => {
      this.parentInitialized = true;
      this.cache = this.$scope.cache;
    });

    this.isEnegiaIdShownInReportArea = true;

    this.$scope.$on('$destroy', this.optionsChangeUnbind);
  }

  updateReportParams() {
    this.reportParams = _.cloneDeep(this.erReportSettings.getSettings());
  }
}

ReportComponentAdapterController.$inject = $inject;

export default ReportComponentAdapterController;
