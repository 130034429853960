import { ActionsPopupButtonsController } from './actions-popup-buttons.controller';
import actionsPopupButtonsTemplate from 'raw-loader!./actions-popup-buttons.html';

export const actionsPopupButtonsComponent = {
  template: actionsPopupButtonsTemplate,
  controller: ActionsPopupButtonsController,
  controllerAs: 'vm',
  bindings: {
    type: '<',
    facilityId: '<',
    meter: '<',
    graphData: '<'
  }
};
