import EnergyReportingConstants from '../../constants';
import EnerKeyServicesModule from '../../../../services';
import ConfigurationModelsModule from '../../../configuration/models';
import ERUtils from './er-utils';

export default angular.module('app.modules.energy-reporting.services.er-utils', [
  EnergyReportingConstants.name,
  EnerKeyServicesModule.name,
  ConfigurationModelsModule.name
])
  .factory('ERUtils', ERUtils);
