<ng-container *ngIf="quantityValues?.length; else noData">
  <div
    *ngIf="chartsVisible"
    class="chart-container"
  >
    <div
      *ngFor="let quantity of quantityValues; trackBy: trackByMethod"
      class="card"
    >
      <quantity-chart-header
        [seriesCollection]="quantity"
      ></quantity-chart-header>
      <reporting-chart
        [series]="quantity.series"
        [quantityId]="quantity.quantityId"
        [notes]="notes?.chartEvents?.[id]?.[quantity.quantityId]"
        [labelSettings]="quantity.series | chartCategories | async"
        (seriesClick)="onSeriesClick($event)"
      ></reporting-chart>
    </div>
  </div>
  <ng-container *ngIf="gridsVisible && gridConfig">
    <reporting-grid
      [data]="gridConfig.data"
      [gridColumns]="gridConfig.columns"
      [searchParams]="searchParams"
      [aggregates]="gridConfig.aggregates"
      [modalReportType]="modalReportType"
      [reportName]="reportName"
    ></reporting-grid>
  </ng-container>
</ng-container>
<ng-template #noData>
  <div style="padding-bottom: 1.25rem">
    {{ (isMeterReport ? 'FACILITIES_REPORT.NO_DATA_FOR_METER' : 'FACILITIES_REPORT.NO_DATA_FOR_FACILITY') | translate }}
  </div>
</ng-template>
