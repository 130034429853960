import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { NgfActiveModal } from '@enerkey/foundation-angular';
import { AlarmClient, ILogDto, LogStatus } from '@enerkey/clients/alarm';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, ModalOptions } from '@enerkey/foundation-angular';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { ALARMS_LOG_GRID_REFRESH } from '../../../../modules/alarms-log/shared/alarms-log-grid-refresh';

type StatusChanges = {
  before: number;
  after: number;
  changes: boolean;
};

@Component({
  selector: 'alarm-log-status-edit-modal',
  templateUrl: './alarm-log-status-edit-modal.component.html',
  styleUrls: ['./alarm-log-status-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ size: 'tiny' })
export class AlarmLogStatusEditModalComponent extends ModalBase implements OnDestroy {
  public static get defaultChanges(): Readonly<Record<number, StatusChanges>> {
    return Object.fromEntries<LogStatus, StatusChanges>(getNumericEnumValues(LogStatus).map(status => [
      status,
      { before: 0, after: 0, changes: false }
    ]));
  }

  public readonly statuses: LogStatus[] = [
    LogStatus.NoInvestigationRequired,
    LogStatus.InvestigationRequired,
    LogStatus.UnderInvestigation,
    LogStatus.InvestigationDone,
  ];

  public readonly loading$: Observable<boolean>;
  public readonly formControl: UntypedFormControl;

  public logsByStatus: ReadonlyMap<LogStatus, ILogDto[]> = new Map();
  public anyChanges: boolean = true;
  public changesByStatus: Record<number, StatusChanges>; // If this is typed by enum, first compile breaks in template

  private _logItems: ILogDto[] = [];

  private readonly _loading = new LoadingSubject();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly alarmClient: AlarmClient,
    private readonly toaster: ToasterService,
    @Inject(ALARMS_LOG_GRID_REFRESH) private readonly _refresh: Subject<void>
  ) {
    super(currentModal);

    this.changesByStatus = AlarmLogStatusEditModalComponent.defaultChanges;
    this.loading$ = this._loading.asObservable();
    this.formControl = new UntypedFormControl(LogStatus.NoInvestigationRequired, Validators.required);
    this.formControl.valueChanges.subscribe(() => this.updateChanges());
  }

  public ngOnDestroy(): void {
    this._loading.complete();
  }

  public setLogItems(logItems: ILogDto[]): void {
    this._logItems = logItems;

    const grouped = this._logItems.toGroupsBy('status');

    this.logsByStatus = this.statuses.toMap(
      status => status,
      status => grouped.get(status) ?? []
    );

    this.updateChanges();
  }

  public onSubmit(): void {
    const status: LogStatus = this.formControl.value;
    this.formControl.disable();

    this.alarmClient.updateLogsStatus(status, this._logItems.map(i => i.id)).pipe(
      indicate(this._loading)
    ).subscribe({
      next: () => {
        this.toaster.success('ALARM.LOG.STATUS.CHANGE_SUCCESS');
        this._refresh.next();
        super.closeModal();
      },
      error: () => {
        this.toaster.error('ALARM.LOG.STATUS.CHANGE_ERROR');
      },
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private updateChanges(): void {
    this.anyChanges = false;

    const current: LogStatus = this.formControl.value;

    for (const status of this.statuses) {
      const before = this.logsByStatus.get(status).length;
      const after = (current === status) ? this._logItems.length : 0;
      const changes = before !== after;

      this.changesByStatus[status] = { before, after, changes };

      if (changes) {
        this.anyChanges = true;
      }
    }
  }
}
