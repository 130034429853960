distributionTypes.$inject = ['$rootScope', '$q', '$http', '$timeout', 'httpConfigService', 'languageChangeService'];

function distributionTypes($rootScope, $q, $http, $timeout, httpConfigService, languageChangeService) {

  var distributionTypesDeferred;

  // watch for language change start
  languageChangeService.languageChangeStart.subscribe(() => {
    if (distributionTypesDeferred) {
      distributionTypesDeferred.resolve([]);
    }
    distributionTypesDeferred = undefined;
  });

  return {

    getDistributionType: function(id) {
      var deferred = $q.defer();

      this.getdistributionTypes().then(
        function(result) {
          var distributionType = _.find(result, function(item) {
            return item.Id == id;
          });
          deferred.resolve(distributionType);
        },
        function() {
          deferred.reject();
        }
      );

      return deferred.promise;
    },

    getDistributionTypes: function() {
      if (!distributionTypesDeferred) {
        distributionTypesDeferred = $q.defer();
        $http.get(ENERKEY_CONFIG.apiEnergyreporting + '/api/v1.1/configuration/distributionTypes', httpConfigService.getExtendedHttpConfig())
          .then(function({ data }) {
            if (distributionTypesDeferred) {
              distributionTypesDeferred.resolve(data.DistributionTypes);
            }
          })
          .catch(function({ status }) {
            if (distributionTypesDeferred) {
              distributionTypesDeferred.reject(status);
              $timeout(function() {
                distributionTypesDeferred = undefined;
              });
            }
          })
        ;
      }
      return distributionTypesDeferred.promise;
    }

  };
}

export default distributionTypes;
