<modal-view-header [headingText]="headingText | translate"></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="form"
    id="valueForm"
    (ngSubmit)="close()"
  >
    <kendo-numerictextbox
      *labelBefore="label | translate"
      formControlName="value"
      [format]="format"
      [spinners]="false"
    ></kendo-numerictextbox>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      type="submit"
      form="valueForm"
      class="button button--primary"
      [disabled]="!form.valid"
    >
      {{ 'DONE' | translate }}
    </button>
  </div>
</div>
