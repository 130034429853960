<modal-view-header
  [headingText]="'ADMIN.TERMINAL.ADD_METERS_TO_TERMINAL' | translate"
></modal-view-header>
<div class="modal-body">
  <div class="selected-meters">
    {{ 'ADMIN.TERMINAL.SELECTED_METERS_COUNT' | translate:{count:meterIds.length} }}
  </div>
  <div class="add-or-update-selection">
    <input
      *labelWrap="'ADMIN.TERMINAL.SELECT_TERMINAL' | translate"
      [value]="false"
      type="radio"
      kendoRadioButton
      [formControl]="isNewTerminalControl"
    >
    <input
      *labelWrap="'ADMIN.TERMINAL.CREATE_TERMINAL' | translate"
      [value]="true"
      type="radio"
      kendoRadioButton
      [formControl]="isNewTerminalControl"
    >
  </div>
  <br />
  <div
    *ngIf="!isNewTerminal"
    class="terminal-selection bold-labels"
  >
    <ek-filterable-combo
      *labelBefore="'ADMIN.TERMINAL.TERMINAL' | translate"
      [items]="terminals$ | async"
      [formControl]="terminalIdControl"
      [loading]="loading$ | async"
      [filterable]="true"
      [virtual]="true"
      [noTranslate]="true"
    ></ek-filterable-combo>
  </div>
  <terminal-edit
    *ngIf="isNewTerminal"
    [enegiaIds]="enegiaIds"
    [terminal]="terminal"
  ></terminal-edit>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      type="button"
      class="button"
      (click)="save()"
      [disabled]="!isNewTerminal ? !terminalIdControl.valid : false"
    >{{ 'ADMIN.SAVE' | translate }}</button>
  </div>
</div>
