import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { EnumTranslatePipe } from '@enerkey/ek-common';
import { MeteringType } from '@enerkey/clients/metering';
import { MeteringType as MeterManagementMeteringType } from '@enerkey/clients/meter-management';
import { MeteringType as MqaMeteringType } from '@enerkey/clients/manual-qa';

import { meterTypeTranslation } from '../../constants/meter.constant';

type MType = MeteringType | MqaMeteringType | MeterManagementMeteringType;

@Pipe({ name: 'meteringType' })
export class MeteringTypePipe extends EnumTranslatePipe<MType> implements PipeTransform {
  protected readonly translations: Record<MType, string> = meterTypeTranslation;

  public constructor(translateService: TranslateService) {
    super(translateService);
  }
}
