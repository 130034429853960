import _ from 'lodash';

import { hasChanges } from '@enerkey/angular-utils';

const $inject = ['$window', '$element', 'utils'];

function MultiSelectController($window, $element, utils) {
  const vm = this;

  vm.translations = utils.istevenMultiSelectTranslation();
  vm.outputModel = [];

  vm.$onChanges = onChanges;
  vm.$onInit = onInit;
  vm.onOpen = onOpen;

  vm.onSelectionChange = function() {
    vm.onChange({ value: vm.outputModel });
  };

  vm.onSelectNone = function() {
    vm.outputModel = [];
    vm.onChange({ value: vm.outputModel });
  };

  vm.onSelectAll = function() {
    vm.outputModel = vm.items.map(item => ({
      [vm.markSelectedBy]: item[vm.markSelectedBy]
    }));
    vm.onChange({ value: vm.outputModel });
  };

  function onInit() {
    vm.buttonLabel = vm.buttonLabel ? vm.buttonLabel : 'name';
    vm.items = angular.copy(vm.items);
    vm.selectedItems = angular.copy(vm.selectedItems);
    vm.items = markItemSelected(vm.items, vm.selectedItems);
    vm.helperElements = vm.helperElements || '';
  }

  function onOpen() {
    if (vm.inModal) {
      setMaxHeight();
    }
  }

  function setMaxHeight() {
    const marginToViewportEdge = 20;
    const maxHeight = `${Math.floor(getFreeHeightBelow() - marginToViewportEdge)}px`;

    // isteven's max-height can't be changed after init so it has to be done the hard way
    const checkboxLayer = $element.find('.checkboxLayer')[0];
    checkboxLayer.style.maxHeight = maxHeight;
  }

  function getFreeHeightBelow() {
    const boundingRect = $element[0].getBoundingClientRect();
    const windowHeight = $window.innerHeight;
    return windowHeight - boundingRect.bottom;
  }

  function onChanges(changes) {
    if (hasChanges(changes, 'items') || hasChanges(changes, 'selectedItems')) {
      if (hasChanges(changes, 'items')) {
        vm.items = angular.copy(vm.items);
      }
      if (hasChanges(changes, 'selectedItems')) {
        vm.selectedItems = angular.copy(vm.selectedItems);
      }
      vm.items = markItemSelected(vm.items, vm.selectedItems);
    }
  }

  function markItemSelected(items, selectedItems) {
    if (items) {
      return items.map(item => {
        if (isItemInSelectedItems(item, selectedItems)) {
          if (!item.ticked) {
            item.ticked = true;
          }
        } else {
          if (item.ticked) {
            item.ticked = false;
          }
        }
        return item;
      });
    }
    return items;
  }

  function isItemInSelectedItems(itemToCheck, selectedItems) {
    return _.find(selectedItems, item => item[vm.markSelectedBy] === itemToCheck[vm.markSelectedBy]);
  }
}

MultiSelectController.$inject = $inject;

export default MultiSelectController;
