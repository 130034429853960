import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { take } from 'rxjs/operators';

import { getAllFocusableBoundaryElements } from '../util/focus-trap';
import { ModalDismissReasons } from './modal-dismiss-reasons';
import { ModalSize } from './modal-size';

@Component({
  selector: 'ngf-modal-window',
  template: `
<div
  [class]="'reveal' + (windowClass ? ' ' + windowClass: '' ) + (size ? ' ' + size : '')"
  role="document"
  tabindex="-1"
  (keyup.esc)="escKey($event)"
>
  <ng-content></ng-content>
</div>
`
})

/* eslint-disable @angular-eslint/component-class-suffix */
export class NgfModalWindow implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('tabindex') public hostTabIndex = -1;
  @HostBinding('attr.role') public role = 'dialog';
  @HostBinding('attr.aria-modal') public ariaModal = true;
  @HostBinding('attr.aria-labelledby') @Input() public ariaLabelledBy: string;
  @Input() public backdrop: boolean | string = true;
  @Input() public centered: string;
  @Input() public keyboard = true;
  @Input() public size: ModalSize;
  @Input() public windowClass: string;
  @Input() public backdropClass: string;

  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('dismiss') public readonly dismissEvent = new EventEmitter();

  private _elWithFocus: HTMLElement; // element that is focused prior to modal opening

  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _elRef: ElementRef<HTMLElement>,
    private readonly zone: NgZone
  ) { }

  @HostBinding('class') public get hostClasses(): string {
    const customBackdropClass = this.backdropClass ? this.backdropClass : '';
    const backdropClass = this.backdrop ? 'reveal-overlay' : '';
    return `${backdropClass} fade show d-black ${customBackdropClass}`;
  }

  @HostListener('click', ['$event']) public backdropClick($event: MouseEvent): void {
    if (this.backdrop === true && this._elRef.nativeElement === $event.target) {
      this.dismiss(ModalDismissReasons.BACKDROP_CLICK);
    }
  }

  @HostListener('focus') public backdropFocused(): void {
    /*
      Pass focus to actual modal element when backdrop is focused
      That way backdrop does not need own esc key handler
    */
    this.getModalElement().focus();
  }

  public dismiss(reason: ModalDismissReasons): void {
    this.dismissEvent.emit(reason);
  }

  public ngOnInit(): void {
    this._elWithFocus = this._document.activeElement as HTMLElement;
  }

  public escKey($event: Event): void {
    if (this.keyboard && !$event.defaultPrevented) {
      this.dismiss(ModalDismissReasons.ESC);
    }
  }

  public ngAfterViewInit(): void {
    if (!this._elRef.nativeElement.contains(document.activeElement)) {
      let autoFocusable = this._elRef.nativeElement.querySelector<HTMLElement>('[ngfAutofocus]');

      // Fallback to first focusable element
      if (!autoFocusable) {
        autoFocusable = getAllFocusableBoundaryElements(this._elRef.nativeElement)
          .find(element => !element.hasAttribute('ngfNoAutoFocus'));
      }

      const elementToFocus = autoFocusable || this._elRef.nativeElement;

      // Run in onStable in case autofocused element has event watchers for focused-state
      this.zone.onStable.pipe(take(1)).subscribe(() => {
        elementToFocus?.focus();
      });
    }
  }

  public ngOnDestroy(): void {
    const body = this._document.body;
    const elWithFocus = this._elWithFocus;

    const elementToFocus = elWithFocus && (elWithFocus as any)['focus'] && body.contains(elWithFocus)
      ? elWithFocus
      : body;
    elementToFocus.focus();
    this._elWithFocus = null;
  }

  private getModalElement(): HTMLElement {
    return this._elRef.nativeElement.querySelector('.reveal');
  }
}
