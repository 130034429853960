import ManualQaSearchReadingApiComponent from './manual-qa-search-reading-api-component';

const dependencies = [];

const ManualQaSearchReadingApiModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-api', dependencies)
  .component('manualQaSearchReadingApi', ManualQaSearchReadingApiComponent)
;

export default ManualQaSearchReadingApiModule;
