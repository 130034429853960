import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';

import { QUANTITY_ICON_CLASSES } from '../../../constants/quantity.constant';

@Component({
  selector: 'quantity-icon',
  templateUrl: './quantity-icon.component.html',
  styleUrls: ['./quantity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuantityIconComponent {

  public readonly iconClasses: Readonly<Record<number, string>> = QUANTITY_ICON_CLASSES;

  @Input() public quantity: Quantities = null;
}
