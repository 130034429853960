<div
  class="wrapper"
  [style.max-width.px]="width"
  [style.max-height.px]="height"
  [loadingSpinner]="loading"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    [attr.viewBox]="'0 0 ' + width + ' ' + height"
  ></svg>
</div>
