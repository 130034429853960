import { Pipe, PipeTransform } from '@angular/core';

import { FacilityProperty } from '../../../shared/interfaces/facility-columns-properties';

export enum FacilityPropertyFilterType {
  String,
  Numeric,
  CheckBox,
  Boolean,
  Date
}

const propertiesUsingCheckBoxFilter = [
  'HeatingMethod',
  'ManagementResponsibility'
];

@Pipe({
  name: 'facilityPropertyFilterType'
})
export class FacilityPropertyFilterTypePipe implements PipeTransform {
  public transform(property: FacilityProperty): FacilityPropertyFilterType {
    if (propertiesUsingCheckBoxFilter.includes(property.Property)) {
      return FacilityPropertyFilterType.CheckBox;
    }
    if (property.Type === 'boolean') {
      return FacilityPropertyFilterType.Boolean;
    }
    if (property.Type === 'date') {
      return FacilityPropertyFilterType.Date;
    }
    return property.Type === 'number' ? FacilityPropertyFilterType.Numeric : FacilityPropertyFilterType.String;
  }
}
