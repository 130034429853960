import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import {
  CancelEvent,
  EditEvent,
  GridComponent,
  SaveEvent,
  SelectableSettings
} from '@progress/kendo-angular-grid';
import { Observable, of, Subject } from 'rxjs';
import { catchError, repeatWhen, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { IncomingRequestDto, WebhookClient } from '@enerkey/clients/webhook';
import { RemoveEventOf } from '@enerkey/ts-utils';

import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';
import { FileDownloadService } from '../../../../shared/services/file-download.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { IncomingMessagesService } from '../../services/incoming-messages.service';
import { DialogService } from '../../../../shared/services/dialog.service';

type RowSelectKey = 'id';

@Component({
  selector: 'incoming-messages-grid',
  templateUrl: './incoming-messages-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService],
})
export class IncomingMessagesGridComponent implements AfterViewInit, OnDestroy {
  public readonly messages$: Observable<IncomingRequestDto[]>;

  public readonly selectKey: RowSelectKey = 'id';

  public selection: IncomingRequestDto[RowSelectKey][] = [];

  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };

  @ViewChild(GridComponent) private readonly kendoGrid: GridComponent;

  private readonly destroy$ = new Subject<void>();
  private readonly replay$ = new Subject<void>();

  public constructor(
    private readonly incomingMessagesService: IncomingMessagesService,
    private readonly webhookClient: WebhookClient,
    private readonly gridService: KendoGridService<IncomingRequestDto, RowSelectKey>,
    private readonly fileDownloadService: FileDownloadService,
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService,
    private readonly translate: TranslateService
  ) {
    this.messages$ = this.incomingMessagesService.searchParams$.pipe(
      switchMap(params => this.webhookClient.getIncomingRequestsBySearchCriteria(params)
        .pipe(repeatWhen(() => this.replay$))),
      catchError(() => {
        this.toasterService.error('SUPERVISION.FAILED_TO_GET_MESSAGES');
        return of([]);
      }),
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    this.messages$.subscribe(data => {
      this.gridService.dataChanged(data);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.replay$.complete();
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.gridService.selection$.subscribe(keys => {
      this.selection = keys;
    });
  }

  public retry(): void {
    this.webhookClient.retryIncomingRequests(this.selection).subscribe({
      next: retryResults => {
        const failedIds = retryResults.filterMap(
          result => !result.success,
          result => result.requestId
        );
        if (failedIds.length) {
          this.toasterService.warning(
            {
              key: 'SUPERVISION.RETRY_FAILED_FOR_REQUESTS',
              params: { requests: failedIds.join(', ') }
            }
          );
        } else {
          this.toasterService.success('SUPERVISION.RETRY_SUCCESSFUL');
        }
      },
      error: () => {
        this.toasterService.error('SUPERVISION.RETRY_FAILED');
      }
    });
  }

  public downloadContent(request: IncomingRequestDto): void {
    const fileName = `incoming-${request.id}.json`;
    this.fileDownloadService.downloadAsJsonFile(JSON.parse(request.content), fileName);
  }

  public editHandler({ sender, rowIndex, dataItem }: EditEvent): void {
    const group = new UntypedFormGroup({
      status: new UntypedFormControl(dataItem.status)
    });
    sender.editRow(rowIndex, group);
  }

  public saveHandler({ sender, rowIndex, formGroup, dataItem }: SaveEvent): void {
    const oldStatus = dataItem.status;
    this.webhookClient.updateIncomingRequestStatus(
      dataItem.id,
      formGroup.value.status,
      dataItem.message
    ).subscribe({
      next: () => {
        this.toasterService.success('SUPERVISION.UPDATE_SUCCESSFUL');
      },
      error: () => {
        dataItem.status = oldStatus;
        this.toasterService.error('SUPERVISION.UPDATE_FAILED');
      }
    });
    dataItem.status = formGroup.value.status;
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }: RemoveEventOf<IncomingRequestDto>): void {
    this.dialogService.getConfirmationModalPromise({
      text: 'SUPERVISION.DELETE_MODAL.TEXT',
      title: this.translate.instant('SUPERVISION.DELETE_MODAL.TITLE'),
      isDelete: true
    }).then(() => {
      this.webhookClient.deleteIncomingRequests(
        [dataItem.id]
      ).subscribe({
        next: () => {
          this.replay$.next();
          this.toasterService.success('SUPERVISION.DELETE_SUCCESSFUL');
        },
        error: () => {
          this.toasterService.error('SUPERVISION.DELETE_FAILED');
        }
      });
    }).catch(() => {});
  }

  public cancelHandler({ sender, rowIndex }: CancelEvent): void {
    sender.closeRow(rowIndex);
  }
}
