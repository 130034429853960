import { Directive, HostBinding } from '@angular/core';
import { Button } from '@progress/kendo-angular-buttons';

@Directive()
export class KendoGridButtonDirective {
  @HostBinding('type') public buttonType = 'button';

  public constructor(command: Button) {
    if (command) {
      command.fillMode = 'flat';
      command.size = 'small';
    }
  }
}
