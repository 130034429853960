/* eslint-disable @typescript-eslint/no-explicit-any */
import addOperationsTemplate from 'raw-loader!./add-operations-template.html';
import moveAndDeleteOperationsTemplate from 'raw-loader!./move-and-delete-operations-template.html';

import { hasChanges } from '@enerkey/angular-utils';
import { TreeViewItem } from '@enerkey/clients/metering';

import { FormulaEditorService } from '../../../virtual-meters/services/formula-editor.service';
import { FormulaItemEdit } from '../../../virtual-meters/shared/formula-item-edit';
import { AddOperationType } from '../../shared/add-operation-type';
import { FormulaItemAdd } from '../../../virtual-meters/shared/formula-item-add';
import { FormulaOperator } from '../../../virtual-meters/shared/formula-operator';
import { FormulaParen } from '../../../virtual-meters/shared/formula-paren';

declare const kendo: any;

const inject = ['$element', 'utils', 'formulaEditorService', 'KendoFunctions'];

interface FormulaEditorTreeListColumn extends kendo.ui.TreeListColumn {
  field?: keyof TreeViewItem;
}

class FormulaEditorTreelistController {
  private static ParenthesesOperators = Object.values(FormulaParen);
  private static CalculationOperators = Object.values(FormulaOperator);

  public formulaData: TreeViewItem[] = [];
  public loading = false;
  public addItem: (addition: FormulaItemAdd) => void;
  public deleteItem: (item: TreeViewItem) => void;
  public editItem: (param: FormulaItemEdit) => void;
  public treeListOptions: kendo.ui.TreeListOptions;
  public addOperationTypes = AddOperationType;

  private dataSource: kendo.data.TreeListDataSource;

  public constructor(
    private $element: any,
    private utils: any,
    public formulaEditorService: FormulaEditorService,
    private kendoFunctions: any
  ) {
  }

  public $onInit(): void {
    this.dataSource = this.getDataSource();
    this.treeListOptions = this.getTreeListOptions();

    this.getTreeListElement().kendoTooltip({
      filter: 'td:nth-child(1)',
      width: 250,
      show: this.showTooltip.bind(this),
      hide: this.hideTooltip.bind(this),
      content: this.getTooltip.bind(this)
    });
  }

  public $onChanges(changes: any): void {
    if (hasChanges(changes, 'formulaData') && this.dataSource) {
      this.dataSource.data(this.formulaData);
    }
    if (
      hasChanges(changes, 'loading') &&
        !!changes.loading.currentValue !== !!changes.loading.previousValue
    ) {
      this.setLoading(changes.loading.currentValue);
    }
  }

  public getTooltip(e: kendo.ui.TooltipEvent): string {
    const dataItem: TreeViewItem = this.kendoFunctions.getTreeListItemFromEvent(e);

    return dataItem
      ? this.formulaEditorService.getValidationErrorTooltipText(dataItem.validationProperties)
      : '';
  }

  public showTooltip(e: kendo.ui.TooltipEvent): void {
    const content: JQuery = this.kendoFunctions.getTooltipContentFromEvent(e);

    if (content.text().length > 0) {
      content.parent().css('visibility', 'visible');
    }
  }

  public hideTooltip(e: kendo.ui.TooltipEvent): void {
    const content: JQuery = this.kendoFunctions.getTooltipContentFromEvent(e);

    content.parent().css('visibility', 'hidden');
  }

  public nullChange(item: TreeViewItem): void {
    this.indicateRowChange(item);
  }

  public addToFormula(addType: AddOperationType, item: TreeViewItem): void {
    this.addItem({
      item: item,
      type: addType
    });
  }

  public deleteClicked(item: TreeViewItem): void {
    this.deleteItem(item);
  }

  private getTreeListElement(): JQuery {
    return this.$element.find('#formulaEditorTreeList');
  }

  private getDataSource(): kendo.data.TreeListDataSource {
    const dataSourceOptions: kendo.data.DataSourceOptions = {
      data: this.formulaData,
      schema: this.getSchema()
    };

    return new kendo.data.TreeListDataSource(dataSourceOptions);
  }

  private getSchema(): kendo.data.DataSourceSchema {
    const schema: kendo.data.DataSourceSchema = {
      model: {
        id: 'itemId',
        expanded: true,
        fields: {
          content: {
            type: 'string',
            editable: true
          },
          sourceMeterId: {
            type: 'number',
            editable: false
          },
          resolution: {
            type: 'string',
            editable: false
          },
          constant: {
            type: 'number',
            editable: true
          },
          meterName: {
            type: 'string',
            editable: false
          },
          readingStartTime: {
            type: 'date',
            editable: false
          },
          nullToZero: {
            type: 'boolean',
            editable: false
          }
        }
      }
    };

    return schema;
  }

  private getTreeListOptions(): kendo.ui.TreeListOptions {
    const columns: FormulaEditorTreeListColumn[] = [
      {
        field: 'content',
        // .bind is needed because kendo is messing with this scope
        template: this.formulaEditorService.getContentColumnTemplate.bind(this.formulaEditorService),
        title: this.utils.localizedString('ADMIN.VIRTUAL_METERS.EDITOR.OPERATION'),
        editable: this.formulaEditorService.isRowContentEditable,
        editor: this.contentEditor.bind(this)
      },
      {
        field: 'sourceMeterId',
        title: this.utils.localizedString('ADMIN.VIRTUAL_METERS.EDITOR.METER_ID'),
        width: 120
      },
      {
        field: 'resolution',
        title: this.utils.localizedString('ADMIN.VIRTUAL_METERS.EDITOR.RESOLUTION'),
        width: 120
      },
      {
        field: 'constant',
        title: this.utils.localizedString('ADMIN.VIRTUAL_METERS.EDITOR.FACTOR'),
        format: '{0:0.########}',
        editable: this.formulaEditorService.isRowConstantEditable,
        editor: this.constantEditor.bind(this),
        width: 100
      },
      {
        field: 'meterName',
        title: this.utils.localizedString('ADMIN.VIRTUAL_METERS.EDITOR.METER_NAME')
      },
      {
        field: 'readingStartTime',
        title: this.utils.localizedString('ADMIN.VIRTUAL_METERS.EDITOR.READING_START_DATE'),
        format: '{0:d}',
        width: 250
      },
      {
        field: 'nullToZero',
        template: this.formulaEditorService.getNullToZeroColumnTemplate,
        title: this.utils.localizedString('ADMIN.VIRTUAL_METERS.EDITOR.NULL_TO_ZERO'),
        width: 100
      },
      {
        template: addOperationsTemplate,
        title: ' ',
        width: 100
      },
      {
        template: moveAndDeleteOperationsTemplate,
        title: ' ',
        width: 70
      }
    ];
    const treeListOptions: kendo.ui.TreeListOptions = {
      dataSource: this.dataSource,
      dataBound: event => {
        this.setLoading(this.loading);
        event.sender.unbind('dataBound');
      },
      height: 540,
      columns,
      editable: {
        mode: 'incell'
      },
      cellClose: (e: kendo.ui.TreeListCellCloseEvent) => {
        this.indicateRowChange(e.model as unknown as TreeViewItem);
      }
    };

    return treeListOptions;
  }

  private indicateRowChange(row: TreeViewItem): void {
    const changeParams = this.formulaEditorService.getRowChangedPropertyAndValue(row);
    this.editItem({ params: changeParams, itemId: row.itemId });
  }

  private constantEditor(container: JQuery, options: kendo.ui.TreeListEditorOptions): JQuery {
    const input: JQuery = kendo.jQuery(`<input name="${options.field}" />`);
    input.appendTo(container);
    input.kendoNumericTextBox({
      decimals: 8,
      format: '#.########'
    });
    return input;
  }

  private contentEditor(container: JQuery, options: kendo.ui.TreeListEditorOptions): JQuery {
    const dataItem: TreeViewItem = (options.model as unknown as TreeViewItem);
    const input: JQuery = kendo.jQuery(`<input required name="${options.field}" />`);
    input.appendTo(container);
    const dataSource = FormulaEditorTreelistController.ParenthesesOperators.includes(dataItem.content as any)
      ? FormulaEditorTreelistController.ParenthesesOperators
      : FormulaEditorTreelistController.CalculationOperators;

    input.kendoDropDownList({
      autoBind: false,
      dataSource: dataSource
    });
    return input;
  }

  private setLoading(loadingStatus: boolean): void {
    const treelistElement = this.getTreeListElement().data('kendoTreeList');
    if (treelistElement) {
      kendo.ui.progress(this.getTreeListElement().data('kendoTreeList').element, loadingStatus);
    }
  }
}

FormulaEditorTreelistController.$inject = inject;

export default FormulaEditorTreelistController;
