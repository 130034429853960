import _ from 'lodash';
import { firstValueFrom } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { AllHtmlEntities } from 'html-entities';

import { Facility } from '@enerkey/clients/facility';

import { FacilityEditModalComponent } from '../../admin/components/facility-edit-modal/facility-edit-modal.component';
import { ACTION_TYPE_IDS, COMMENT_TYPE_IDS } from '../../energy-management/constants/em-shared.constant';
import { EventType } from '../constants/event-type';
import { FacilityTagEditModalComponent } from '../../admin/components/facility-tag-edit-modal/facility-tag-edit-modal.component';

const $inject = [
  '$q', '$rootScope', '$scope', '$timeout', '$state', 'filterService', 'ERDataService2', 'LoadingService',
  'consumptions', 'actions', 'utils', 'ERUtils', 'UserService', 'ConsumptionTargetDataService', 'appStatusService',
  'erReportSettingsService', 'modalService', 'AlarmClient', 'EREventsService', 'modalServiceAngular',
  '$translate'
];

function FacilitiesGridDesktopController(
  $q, $rootScope, $scope, $timeout, $state, filterService, ERDataService2, LoadingService,
  consumptions, actions, utils, ERUtils, UserService, ConsumptionTargetDataService, appStatusService,
  erReportSettingsService, modalService, alarmClient, erEventsService, modalServiceAngular,
  $translate
) {
  const vm = this;
  let forceNotAllComparable = false;
  const defaultPageSize = 100;

  const erReportSettings = erReportSettingsService.getInstance();

  if (appStatusService.isMobile) {
    $scope.sidebarConfig.collapsed = true;
  }

  function getPayload(series) {
    let payload = null;
    const quantities = $scope.params.quantityId;

    if ($scope.validateSeries(series)) {
      // Create payload for post
      payload = {
        Quantities: _.chain(quantities)
          .reduce((result, quantityId) => {
            const quantity = $scope.getQuantity(quantityId);
            if (quantity) {
              result.push({
                ID: quantityId,
                RelationalUnitIds: quantity.Relational || quantity.Emission ? series.RelationalUnitIds : [],
                DistriputionId: quantity.Distribution ? series.DistributionId : 0,
                Flags: true,
                Normalisation: quantity.Normalization && series.Normalized,
                RelatedValues: $scope.filterRelatedValueIdsForQuantity(series.RelatedValues, quantityId)
              });
            }
            return result;
          }, [])
          .value(),
        TimeFrame: series.TimeFrame,
        Start: _.clone(series.Start).reverse(),
        Unit: $scope.params.unitKey
      };
      // Set facilities
      const filteredFacilityIds = filterService.getFilteredFacilityIds();
      if (filteredFacilityIds) {
        payload.FacilityId = filteredFacilityIds;
      }
    }
    return payload;
  }

  function getIncludedActionTypes(eventTypes) {
    return eventTypes.reduce((result, eventType) => {
      if (eventType === EventType.Actions) {
        return [...result, ...ACTION_TYPE_IDS];
      }
      if (eventType === EventType.Comments) {
        return [...result, ...COMMENT_TYPE_IDS];
      }
      return result;
    }, []);
  }

  function refreshSelections() {
    // need to wrap timeout, see:
    // http://stackoverflow.com/questions/37004171/which-is-the-last-event-in-kendo-grid-after-databound
    if ($scope.grid) {
      $timeout(() => {
        const dataSource = $scope.grid.dataSource;
        let skipDataSet = false;
        _.each(dataSource._data, dataItem => {
          const row = angular.element($scope.grid.element).find(`[data-uid="${dataItem.uid}"]`);
          if (_.contains($scope.params.facilityId, dataItem.FacilityId)) {
            dataItem.checked = true;
            row.addClass('k-state-selected');
          } else {
            dataItem.checked = false;
            row.removeClass('k-state-selected');
          }
          if (dataItem.forceNotComparable) {
            angular
              .element(row)
              .find('.fa-toggle-on')
              .removeClass('fa-toggle-on')
              .addClass('fa-toggle-off');
            angular.element(row).css({ opacity: '0.5' });
            skipDataSet = true;
          }
        });
        if (!skipDataSet) {
          $scope.grid.setDataSource(dataSource);
        }
      });
    }
  }

  function getGridState() {
    // backwards compatibility:
    // remove _ from the key start and change sort/group
    // fields to flattened by replacing dot as dollar
    return _.reduce(
      $scope.params.gridState,
      (result, value, key) => {
        key = _.last(key.split('_'));
        switch (key) {
          case 'sort':
          case 'group':
            if (_.isArray(value)) {
              value = _.map(value, item => ({
                field: item.field.replace('.', '$'),
                dir: item.dir,
                aggregates: _.get($scope, 'gridOptions.dataSource.aggregate')
              }));
            }
            break;
          case 'filter':
            if (_.isObject(value) && _.isArray(_.get(value, 'filters'))) {
              value = {
                logic: value.logic,
                filters: _.map(value.filters, item => ({
                  field: item.field.replace('.', '$'),
                  operator: item.operator,
                  value: item.value
                }))
              };
            }
            break;
          default:
            break;
        }
        result[key] = value;
        return result;
      },
      {}
    );
  }

  function mergeColumn(oldColumn, newColumn, isValueColumn) {
    if (oldColumn && newColumn) {
      const interestedProperties = isValueColumn ? ['width'] : ['width', 'hidden'];
      _.each(interestedProperties, property => {
        if (angular.isDefined(oldColumn[property]) && newColumn[property] !== oldColumn[property]) {
          newColumn[property] = oldColumn[property];
        }
      });
    }
  }

  function mergeColumns(oldColumns) {
    // merge same columns
    const sameColumns = _.reduce(
      oldColumns,
      (result, oldColumn) => {
        const newColumn = _.find($scope.gridOptions.columns, { field: oldColumn.field });
        if (newColumn) {
          const isValueColumn = !_.isUndefined(newColumn.quantityId);
          mergeColumn(oldColumn, newColumn);
          _.each(oldColumn.columns, oldChildColumn => {
            const newChildColumn = _.find(newColumn.columns, { field: oldChildColumn.field });
            mergeColumn(oldChildColumn, newChildColumn, isValueColumn);
          });
          result.push(newColumn);
        }
        return result;
      },
      []
    );

    // find new columns
    const newAddedColumns = _.reduce(
      $scope.gridOptions.columns,
      (result, column) => {
        const sameColumn = _.find(sameColumns, { field: column.field });
        if (!sameColumn) {
          result.push(column);
        }
        return result;
      },
      []
    );

    // concat same columns and new added columns
    let newColumns = sameColumns.concat(newAddedColumns);

    // move commands to last
    const commands = _.reduce(
      newColumns,
      (result, column) => {
        if (column.command) {
          result.push(column);
        }
        return result;
      },
      []
    );
    _.each(commands, command => {
      newColumns.splice(newColumns.indexOf(command), 1)[0];
      newColumns.push(command);
    });

    // sort by quantity id
    newColumns = _.sortBy(newColumns, column => {
      if (column.command) {
        return Number.MAX_VALUE;
      }
      return column.quantityId ? column.quantityId : 0;
    });

    // set new columns
    $scope.gridOptions.columns = newColumns;
  }

  function loadGridState() {
    const state = getGridState();
    if (!state.sort) {
      state.sort = [{ field: 'key', dir: 'asc' }];
    }
    if ($scope.gridOptions.dataSource) {
      $scope.gridOptions.dataSource = _.extend(
        $scope.gridOptions.dataSource,
        _.pick(state, ['sort', 'group', 'filter', 'page', 'pageSize'])
      );
    }
    mergeColumns(state.columns);
    return state;
  }

  function refreshGrid() {
    if ($scope.gridOptions && $scope.gridOptions.columns) {
      loadGridState();
    }
    if ($scope.grid) {
      // remove events because kendo is not handling those in setOptions, which is weird?!
      $scope.grid._events = {};
      $scope.grid.setOptions($scope.gridOptions);
      refreshSelections();
    }
    removeTooltips();
  }

  /**
   * Under certain conditions during a grid refresh, the cell tooltips linger obstructing the view.
   * This function makes sure any unnecessary tooltips are cleared after a refresh.
   */
  function removeTooltips() {
    $timeout(() => Array.from(document.querySelectorAll('.tooltip')).forEach(element => {
      element.parentNode.removeChild(element);
    }), 0);
  }

  function setForceNotComparableForRows(config) {
    config = _.isObject(config) ? config : {};
    config.gridOptions = config.gridOptions || $scope.gridOptions;
    config.rows = config.rows || [];
    config.refreshGrid = config.refreshGrid || true;

    if (config.rows.length && _.has(config, 'newValue')) {
      const numberFields = _.reduce(
        _.get(config.gridOptions, 'dataSource.schema.model.fields'),
        (result, fieldModel, field) => {
          if (_.get(fieldModel, 'type') === 'number' && field !== 'Id') {
            result.push(field);
          }
          return result;
        },
        []
      );

      _.each(config.rows, row => {
        row.forceNotComparable = config.newValue;
        _.each(numberFields, field => {
          const tempField = `temp_${field}`;
          if (!_.get(row, tempField)) {
            _.set(row, tempField, _.get(row, field));
          }
          _.set(row, field, config.newValue ? void 0 : _.get(row, tempField));
        });
      });
      if (config.refreshGrid) {
        refreshGrid();
      }
    }
  }

  function setGridState(newState) {
    const oldState = getGridState();
    if (!_.isEqual(angular.toJson(newState), angular.toJson(oldState))) {
      erReportSettings.changeSetting('gridState', newState);
    }
  }

  /* ** State ***/
  function saveGridState(grid) {
    const dataSource = grid ? grid.dataSource : void 0;
    if (dataSource) {
      const newState = {};
      if (dataSource.sort()) {
        newState.sort = dataSource.sort();
      }
      if (dataSource.group() && dataSource.group().length) {
        newState.group = dataSource.group();
      }
      if (dataSource.filter()) {
        newState.filter = dataSource.filter();
      }
      if (dataSource.page()) {
        newState.page = dataSource.page();
      }
      newState.pageSize = defaultPageSize;
      newState.columns = grid.columns;
      setGridState(newState);
    }
  }

  function recreateGrid(responseData, supplementaryData, series) {
    const options = {
      idType: 'Facility',
      series: series,
      params: $scope.params,
      cache: $scope.cache,
      api: responseData,
      supplementaryApi: {
        actionsImpact: {
          responseData: supplementaryData ? supplementaryData.actionsImpactData : {}
        },
        latestAlarms: {
          responseData: supplementaryData ? supplementaryData.latestAlarmsData : {}
        },
        latestActions: {
          responseData: supplementaryData ? supplementaryData.actionsData.latestActions : {}
        },
        latestComments: {
          responseData: supplementaryData ? supplementaryData.actionsData.latestComments : {}
        }
      },
      show: {
        distributionAndValue: true,
        relationalValueOnlyForFollowUp: false,
        relatedValueOnlyForFollowUp: false,
        allTargetSeriesPeriods: true
      },
      grid: {
        aggregates: ['sum'],
        excel: {},
        paging: {
          pageSize: defaultPageSize
        },
        removeEmptyColumns: false,
        extraColumns: _.map($scope.cache.facilitiesProperties, category => ({
          field: category.Property,
          title: category.Name,
          groupable: false,
          toggleable: true,
          hidden: true,
          headerAttributes: { title: category.Name },
          columns: _.map(category.Items, (item, index) => {
            const field = `${category.Property}$${item.Property}`;

            const column = {
              field: field,
              title: item.Name,
              format: '{0:g}',
              sort: index,
              groupable: true,
              // eslint-disable-next-line eqeqeq, no-eq-null
              groupHeaderTemplate: data => (data.value == null ? '-' : data.value),
              toggleable: true,
              hidden: true,
              aggregates: ['sum'],
              width: 150,
              filterable: getColumnFilterable(item),
              attributes: {
                class: item.Type === 'number' ? 'cell-number' : void 0
              },
              type: item.Type,
            };

            if (category.Property === 'Tags') {
              const encodedName = AllHtmlEntities.encode(item.Name);
              const hasTag = '<i class="fa fa-check"></i>';
              const hasNoTag = '<i class="fa fa-times"></i>';
              column.groupHeaderTemplate = data => `
                <span ng-non-bindable>
                  ${utils.localizedString('TAGS.TAG')}:
                  ${encodedName}
                  ${data.value ? hasTag : hasNoTag}
                </span>`;
              column.headerTemplate = () => `<span ng-non-bindable>${encodedName}</span>`;
              column.template = value => value[field] ? hasTag : '';
            }

            return column;
          }),
        })),
        extraGridOptions: {
          height: void 0,
          scrollable: {
            virtual: false
          },
          sortable: true,
          groupable: true,
          reorderable: true,
          resizable: true,
          filterable: {
            extra: false,
            operators: {
              string: {
                contains: $translate.instant('kendo.grid.filterContainsOperator'),
                startswith: $translate.instant('kendo.grid.filterStartsWithOperator'),
                isnull: $translate.instant('kendo.grid.filterIsEmptyOperator'),
                isnotnull: $translate.instant('kendo.grid.filterIsNotEmptyOperator'),
              },
              number: {
                gte: $translate.instant('kendo.grid.filterGteOperator'),
                gt: $translate.instant('kendo.grid.filterGtOperator'),
                lte: $translate.instant('kendo.grid.filterLteOperator'),
                lt: $translate.instant('kendo.grid.filterLtOperator'),
                isnull: $translate.instant('kendo.grid.filterIsEmptyOperator'),
                isnotnull: $translate.instant('kendo.grid.filterIsNotEmptyOperator'),
              }
            }
          },
          columnMenu: false,
          group: function(event) {
            $timeout(() => {
              saveGridState(event.sender);
            });
          },
          filter: function(event) {
            $timeout(() => {
              saveGridState(event.sender);
            });
          },
          sort: function(event) {
            $timeout(() => {
              saveGridState(event.sender);
            });
          },
          columnHide: function(event) {
            $timeout(() => {
              saveGridState(event.sender);
            });
          },
          columnShow: function(event) {
            $timeout(() => {
              saveGridState(event.sender);
            });
          },
          columnReorder: function(event) {
            $timeout(() => {
              saveGridState(event.sender);
            });
          },
          columnResize: function(event) {
            $timeout(() => {
              saveGridState(event.sender);
            });
          }
        }
      }
    };

    vm.editFacility = facilityId => {
      const modalRef = modalServiceAngular.open(FacilityEditModalComponent);
      modalRef.componentInstance.facilityId = facilityId;
      modalRef.result.resolved(() => $state.reload);
    };

    vm.editFacilityTags = facilityId => {
      const modal = modalServiceAngular.open(FacilityTagEditModalComponent);
      modal.componentInstance.selectedFacility = new Facility({ id: facilityId });
      modal.result.resolved(() => $state.reload);
    };

    const gridOptions = _.get(ERDataService2.getVisuals(options), 'grids.gridOptions', { columns: [] });

    // Edit facility button
    if (UserService.hasRole('FacilityUpdate')) {
      // Setting static place for edit button (kendogrids showColumn will break functionality without this)
      gridOptions.columns.splice(1, 0, {
        field: 'Edit',
        locked: true,
        width: 46,
        title: ' ',
        groupable: false,
        filterable: false,
        template: data =>
          `<div class="grid-edit-button">
    <button
       ng-click="vm.editFacility(${data.Id || data.id})"
       class="button button--no-padding button--link"
       title="{{ 'ADMIN.EDIT' | translate }}"
       style="margin-right: 3px;"
    >
      <i class="fas fa-pencil-alt"></i>
    </button>
    <button
      ng-click="vm.editFacilityTags(${data.Id || data.id})"
      class="button button--no-padding button--link"
      title="{{ 'TAGS.EDIT.SINGLE' | translate }}"
    >
      <i class="fas fa-tag"></i>
    </button>
  </div>`,
      });
    }

    // add checked and id columns
    gridOptions.dataBound = () => $timeout(() => $scope.updateClasses(null));

    gridOptions.toolbar =
      '<toggle-grid-columns-button-only grid="grid" static="true"></toggle-grid-columns-button-only>';

    gridOptions.columns.splice(0, 0, {
      field: 'checked',
      title: ' ',
      filterable: {
        messages: {
          isFalse: utils.localizedString('GRID.NOT_SELECTED'),
          isTrue: utils.localizedString('GRID.SELECTED')
        },
      },
      groupable: false,
      sortable: false,
      locked: true,
      headerTemplate: `<input
        id="select-all-facilities"
        type="checkbox"
        kendo-checkbox
        style="margin: 0 0 0 0.5rem"
        ng-model="gridOptions.allSelected"
        ng-change="toggleAll(gridOptions.allSelected)" />`,
      template: `<input
        type="checkbox"
        kendo-checkbox
        ng-model="dataItem.checked"
        ng-change="selectionChanged(this.dataItem)" />`,
      attributes: {
        class: 'cell-center'
      },
      width: 65
    });
    gridOptions.columns.splice(1, 0, {
      field: 'Id',
      title: 'Id',
      hidden: true,
      locked: true,
      groupable: false,
      width: 80,
      attributes: {
        class: 'cell-number'
      }
    });
    _.set(gridOptions, 'dataSource.schema.model.fields.checked', { type: 'boolean' });
    _.set(gridOptions, 'dataSource.schema.model.fields.Id', { type: 'number' });

    // add forceNotComparable column
    gridOptions.columns.push({
      field: 'forceNotComparable',
      title: ' ',
      headerTemplate: function() {
        const toggle = forceNotAllComparable ? 'off' : 'on';
        return `<i
            id="forceNotAllComparable"
            class="fa fa-lg fa-toggle-${toggle}"
            ng-click="toggleForceNotAllComparable()">
          </i>
          <i
            class="fa fa-question-circle"
            tooltip-append-to-body="true"
            tooltip-placement="left"
            tooltip="{{ 'FACILITIES_GRID.TOGGLE_ON_OFF' | translate }}">
          </i>`;
      },
      command: {
        name: 'forceNotComparable',
        fillMode: 'flat',
        iconClass: 'fa-1x fa fa-toggle-on',
        className: 'ek-link-button',
        text: '',

        click: function(e) {
          e.preventDefault();
          const tr = angular.element(e.target).closest('tr');
          // eslint-disable-next-line
          const dataItem = this.dataItem(tr);
          const row = _.find(_.get($scope.gridOptions, 'dataSource.data'), { key: _.get(dataItem, 'key') });
          if (row) {
            setForceNotComparableForRows({
              rows: [row],
              newValue: !row.forceNotComparable
            });
          }
        }
      },
      width: 45
    });

    // handle forceNotComparable data
    if ($scope.gridOptions) {
      const forceNotComparableRows = _.filter(_.get($scope.gridOptions, 'dataSource.data'), {
        forceNotComparable: true
      });
      const keys = _.pluck(forceNotComparableRows, 'key');
      setForceNotComparableForRows({
        gridOptions: gridOptions,
        rows: _.filter(_.get(gridOptions, 'dataSource.data'), row => _.includes(keys, row.key)),
        newValue: true,
        refreshGrid: false
      });
    }

    $scope.gridOptions = gridOptions;
    refreshGrid();
  }

  function getGridData() {
    let promises = [];
    let consumptionsRequested = false;
    const series = $scope.params.series;
    const payload = getPayload(series) || {};

    const { actionsImpactRequested, actionsImpactPromise } = getActionsImpact(series);
    if (actionsImpactRequested) {
      promises.push(actionsImpactPromise);
    }

    const { latestAlarmsRequested, latestAlarmsPromise } = getLatestAlarms(series, payload);
    if (latestAlarmsRequested) {
      promises.push(latestAlarmsPromise);
    }

    const { actionsRequested, actionsPromise } = getActions(series, payload);
    if (actionsRequested) {
      promises.push(actionsPromise);
    }

    // Send comsumptions request
    if (_.get(payload, 'Quantities.length', 0) > 0) {
      let date = payload.Start[0].value;
      if (angular.isString(date)) {
        date = new Date(date);
      }
      const dayOfMonth = date.getDate();
      // grid does not support other than full months or years
      if (dayOfMonth === 1 && ERUtils.isYearOrMonthTimeFrame(payload.TimeFrame)) {
        consumptionsRequested = true;
        promises.push(consumptions.getConsumptionsForFacilities(payload));
        // Send target consumptions request
        const selectedTargets = _.get(series, 'ConsumptionTargetSeriesTypes', []);
        if (selectedTargets.length > 0) {
          const targets = ConsumptionTargetDataService.getData(series, payload, true);
          promises = promises.concat(targets);
        }
      } else {
        utils.popUp('error', utils.localizedString('FACILITIES_GRID.NON_SUPPORTED_TIME_SELECTION'));
      }
    }

    const responseDataEmpty = [
      {
        requestData: {},
        responseData: {}
      }
    ];

    if (promises.length > 0) {
      $q.all(promises)
        .then(responseData => {

          // Actions impact is handled differently because it is independent of other data displayed
          const actionsImpactData = actionsImpactRequested ? responseData.splice(0, 1)[0] : {};

          const latestAlarmsData = latestAlarmsRequested ? responseData.splice(0, 1)[0] : {};

          const actionsData = actionsRequested ? responseData.splice(0, 1)[0] : {};

          // Consumption response is returned in different format than target consumptions
          if (consumptionsRequested) {
            responseData[0] = {
              requestData: payload,
              responseData: responseData[0]
            };
          } else {
            // This is for the case when only actions impact is requested
            responseData = responseDataEmpty;
          }

          recreateGrid(responseData, { actionsImpactData, latestAlarmsData, actionsData }, series);
        })
        .catch(err => {
          utils.trackError(err, 'FacilitiesGridDesktopController creating grid');
          $scope.handleError();
        });
    } else {
      recreateGrid(responseDataEmpty, null, series);
    }
  }

  function getActionsImpact(series) {
    const actionsImpactEnergyTypes = series.ActionsImpactTypes || [];
    const beginEndDates = series.BeginEndDates;
    return actionsImpactEnergyTypes.length > 0
      ? {
        actionsImpactRequested: true,
        actionsImpactPromise: actions
          .getActionsImpact({
            filters: actionsImpactEnergyTypes,
            from: beginEndDates.from,
            to: beginEndDates.to
          })
          .then(actionsImpacts => _.mapKeys(actionsImpacts, actionsImpact => actionsImpact.reportingObjectId))
      }
      : { actionsImpactRequested: false };
  }

  function getColumnFilterable(item) {
    if (item.IsTag || item.Property === 'HeatingMethod' || item.Property === 'ManagementResponsibility') {
      return { multi: true };
    } else {
      return { extra: item.Type === 'number' || item.Type === 'date' };
    }
  }

  function getLatestAlarms(series, payload) {
    const searchParams = {
      facilityIds: payload.FacilityId,
      quantityIds: payload.Quantities.map(({ ID }) => ID),
      beginDate: series.BeginEndDates.from,
      endDate: series.BeginEndDates.to
    };
    const profileId = UserService.getCurrentProfile().id;
    return series.EventTypes && series.EventTypes.includes(EventType.Alarms)
      ? {
        latestAlarmsRequested: true,
        latestAlarmsPromise: firstValueFrom(alarmClient
          .getLiteLogsBySearchCriteria(profileId, searchParams)
          .pipe(
            map(alarms => alarms
              .sortBy('executedAt', 'desc')
              .reduce((result, alarm) => {
                const facilityId = alarm.facilityId;
                if (!result[facilityId]) {
                  result[facilityId] = [];
                }
                result[facilityId].push(alarm);
                return result;
              }, {}))
          ))
      }
      : { latestAlarmsRequested: false };
  }

  function getActions(series, payload) {
    const searchParams = {
      facilityIds: payload.FacilityId,
      includedActionTypes: getIncludedActionTypes(series.EventTypes || []),
      beginDate: series.BeginEndDates.from,
      endDate: series.BeginEndDates.to
    };
    return searchParams.includedActionTypes.length > 0
      ? {
        actionsRequested: true,
        actionsPromise: actions.getActionsFlatlistPost(searchParams).then(
          ({ data }) => data
            .filter(action => erEventsService.hasSelectedOrNoQuantities(action))
            .sortBy('effectStartsAt', 'descending')
            .reduce((result, current) => {
              const { latestActions, latestComments } = result;
              if (ACTION_TYPE_IDS.includes(current.actionType)) {
                const actionList = latestActions[current.reportingObjectId] || [];
                actionList.push(current);
                latestActions[current.reportingObjectId] = actionList;
              } else if (COMMENT_TYPE_IDS.includes(current.actionType)) {
                const commentList = latestComments[current.reportingObjectId] || [];
                commentList.push(current);
                latestComments[current.reportingObjectId] = commentList;
              }
              return { latestActions, latestComments };
            }, { latestActions: {}, latestComments: {} })
        )
      }
      : { actionsRequested: false };
  }

  const regenerateData = _.debounce(() => {
    $scope
      .parentInitialized()
      .then(getGridData)
      .catch(err => {
        utils.trackError(err, 'FacilitiesGridDesktopController regenerate');
        $scope.handleError();
      });
  }, $scope.defaultTimer);

  const setFacilityId = function(ids) {
    erReportSettings.changeSetting('facilityId', _.uniq(ids));
  };

  // watch for filtered facilities change
  const facilitiesChangedSubscription = filterService.filteredFacilityIds$.pipe(
    skip(1)
  ).subscribe(() => {
    let gridPage = _.get($scope.params, 'gridState._page');
    if (gridPage) {
      gridPage = 1;
    }
    $state.go($state.current.name, $scope.params, { reload: true });
  });

  $scope.toggleForceNotAllComparable = function() {
    // force loading indicator
    LoadingService.forceLoadingUntil();
    // toggle the state
    forceNotAllComparable = !forceNotAllComparable;
    // set rows data
    setForceNotComparableForRows({
      rows: _.get($scope.gridOptions, 'dataSource.data'),
      newValue: forceNotAllComparable
    });
  };

  $scope.openModal = function(facilityId) {
    const reportParams = {
      facilityId: [facilityId],
      series: $scope.params.series,
      unitKey: $scope.params.unitKey,
      changeType: $scope.params.changeType
    };
    const modalParams = {
      reportParams: reportParams
    };
    modalService.getModalWithComponent('report-modal', modalParams);
  };

  $scope.$watch('gridOptions.height', newValue => {
    if (newValue) {
      refreshGrid();
    }
  });

  $scope.$on('facilities.paramsChanged', (event, paramsData) => {
    // check if grid is initialized and not going to refresh data
    const forceUpdate = _.get(paramsData, 'forceUpdate');
    if ($scope.grid && !forceUpdate) {
      const interestedParams = _.without(_.get(paramsData, 'changedParams'), 'facilityId', 'gridState');
      if (interestedParams.length) {
        if (
          _.includes(interestedParams, 'quantityId') &&
          _.difference(_.get(paramsData, 'oldParams.quantityId'), $scope.params.quantityId).length
        ) {
          // quantities was just removed, so remove unnecessary columns
          $scope.gridOptions.columns = _.filter($scope.gridOptions.columns, column =>
            column.quantityId ? _.includes($scope.params.quantityId, column.quantityId) : true);
          regenerateData();
          return;
        }
        regenerateData();
      }
    } else if (forceUpdate) {
      regenerateData();
    }
  });

  $scope.selectionChanged = function(dataItem) {
    const selection = dataItem.checked
      ? [...$scope.params.facilityId, dataItem.FacilityId]
      : $scope.params.facilityId.filter(i => i !== dataItem.FacilityId);

    setFacilityId(selection);
    $scope.updateClasses([dataItem]);
  };

  $scope.toggleAll = function(checked) {
    $timeout(() => {
      if ($scope.grid) {
        if (checked) {
          const data = $scope.grid.dataSource.data();
          const filters = $scope.grid.dataSource.filter();
          const items = new kendo.data.Query(data).filter(filters).data;

          setFacilityId(items.map(x => x.Id));
          items.forEach(item => (item.checked = true));
        } else {
          setFacilityId([]);
          $scope.grid.dataSource.data().forEach(item => (item.checked = false));
        }

        $scope.updateClasses(null);
      }
    });
  };

  $scope.updateClasses = function(itemsToUpdate) {
    const items = itemsToUpdate || $scope.grid.dataSource.view();
    const gridEl = angular.element($scope.grid.element);

    for (const dataItem of items) {
      const row = gridEl.find(`[data-uid="${dataItem.uid}"]`);

      if (row) {
        if (dataItem.checked) {
          row.addClass('k-state-selected');
        } else {
          row.removeClass('k-state-selected');
        }
      }
    }
  };

  $scope.$on('$destroy', () => {
    facilitiesChangedSubscription.unsubscribe();
    if ($scope.grid) {
      $scope.grid.destroy();
    }
  });
  // initialize by getting data
  regenerateData();
}

FacilitiesGridDesktopController.$inject = $inject;

export default FacilitiesGridDesktopController;
