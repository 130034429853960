export function base64toBytes(b64: string): Uint8Array {
  if (!b64) {
    return new Uint8Array(0);
  }

  const binaryString = atob(b64);
  const size = binaryString.length;
  const bytes = new Uint8Array(size);

  for (let i = 0; i < size; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
}

export function htmlToFile(html: string, fileName: string, lastModified?: number): File {
  if (!fileName.endsWith('.html')) {
    fileName += '.html';
  }

  return new File(
    [html],
    fileName,
    {
      type: 'text/html',
      lastModified: lastModified ?? Date.now()
    }
  );
}
