<div class="dual-radiobutton-wrapper">
  <label>
    <input
      [value]="periodDisplayTypes.PREDEFINED"
      formControlName="periodDisplayType"
      type="radio"
      kendoRadioButton
      required
    />
    {{ 'ANALYTICS.ETCURVE.PREDEFINED' | translate }}
  </label>
  <label>
    <input
      [value]="periodDisplayTypes.CUSTOM"
      formControlName="periodDisplayType"
      type="radio"
      kendoRadioButton
      required
    />
    {{ 'ANALYTICS.ETCURVE.CUSTOM' | translate }}
  </label>
</div>

<p *ngIf="controls.periodDisplayType.value === periodDisplayTypes.PREDEFINED">
  <ek-combo
    formControlName="period"
    [items]="periodOptions"
  ></ek-combo>
</p>

<div
  class="dual-datepicker-wrapper"
  *ngIf="controls.periodDisplayType.value === periodDisplayTypes.CUSTOM"
>
  <p>
    <kendo-datepicker
      formControlName="showFrom"
      [max]="controls.showTo.value"
    ></kendo-datepicker>
  </p>
  <span>
        <i class="fa fa-arrow-right"></i>
      </span>
  <p>
    <kendo-datepicker
      formControlName="showTo"
      [min]="controls.showFrom.value"
      [max]="toDateMax"
    ></kendo-datepicker>
  </p>

</div>

<div *ngIf="controls.resolution.value === 'PT1H'">
  <p>
    <label>
      <input
        formControlName="opsHours"
        type="checkbox"
        kendoCheckBox
      /> {{ 'ANALYTICS.ETCURVE.OPERATIONAL_HOURS' | translate }}
    </label>
  </p>

  <div *ngIf="controls.opsHours.value">
    <div class="dual-datepicker-wrapper">
      <p>
        <kendo-timepicker
          formControlName="showTimeFrom"
          [max]="controls.showTimeToMax.value"
          [format]="'HH'"
          [steps]="{ minute: 60 }"
        ></kendo-timepicker>
      </p>
      <span>
            <i class="fa fa-arrow-right"></i>
          </span>
      <p>
        <kendo-timepicker
          formControlName="showTimeTo"
          [min]="controls.showTimeFromMin.value"
          [format]="'HH'"
          [steps]="{ minute: 60 }"
        ></kendo-timepicker>
      </p>
    </div>
    <p>
      <label>
        <input
          formControlName="invert"
          type="checkbox"
          kendoCheckBox
        />
        {{ 'ANALYTICS.ETCURVE.INVERT' | translate }}
      </label>
    </p>
  </div>
</div>
