import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';

@Component({
  selector: 'column-tooltips',
  templateUrl: './column-tooltips.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnTooltipsComponent {
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;

    if (
      (element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.innerText
      && element.offsetWidth < element.scrollWidth
    ) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }
}
