import { IContractClient } from './contract-client';

/** Mock class implementing the client interface. Use in tests with `spyOn`. */
export class ContractClientMock implements IContractClient {

  public getSubscriptions(): never {
    throw new Error('Method not implemented.');
  }

  public createSubscription(): never {
    throw new Error('Method not implemented.');
  }

  public getServices(): never {
    throw new Error('Method not implemented.');
  }

  public getSubscriptionsServices(): never {
    throw new Error('Method not implemented.');
  }

  public deleteSubscriptionServices(): never {
    throw new Error('Method not implemented.');
  }

  public upsertSubscriptionServicesForSubscriptions(): never {
    throw new Error('Method not implemented.');
  }

  public updateSubscriptions(): never {
    throw new Error('Method not implemented.');
  }

  public upsertSubscriptionServiceForFacilities(): never {
    throw new Error('Method not implemented.');
  }

  public upsertSubscriptionServicesForMeters(): never {
    throw new Error('Method not implemented.');
  }

  public synchronizeContracts(): never {
    throw new Error('Method not implemented.');
  }

  public getContractsByFacilityIds(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public getContractProductsByIds(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public getContractProductsByContractIds(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public addContracts(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public updateContractProducts(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public deleteContractProducts(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public terminateContractProducts(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public terminateAndRecreateContractProducts(..._args: never): never {
    throw new Error('Method not implemented.');
  }

  public getProductNames(..._args: never): never {
    throw new Error('Method not implemented.');
  }

}
