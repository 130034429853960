export const LOCK_MODEL = 'LOCK_MODEL';
export const UNLOCK_MODEL = 'UNLOCK_MODEL';
export const DELETE_MODEL = 'DELETE_MODEL';
export const LOCK_CONSUMPTION = 'LOCK_CONSUMPTION';
export const UNLOCK_CONSUMPTION = 'UNLOCK_CONSUMPTION';
export const DELETE_CONSUMPTION = 'DELETE_CONSUMPTION';
export const QA_COMMENT = 'QA_COMMENT';
export const JIRA_TICKET = 'JIRA_TICKET';
export const METER_HISTORY = 'METER_HISTORY';
export const METER_INTERFACE_STATUS = 'METER_INTERFACE_STATUS';
