import _ from 'lodash';
import { firstValueFrom } from 'rxjs';

import { ServiceLevel } from '@enerkey/clients/facility';

import * as modalStates from '../../constants/er-modal-states.constant';
import { ITEM_GROUPS, MODAL_LINKS } from '../../constants/er-sidebar.constant';
import { ReportSettings } from '../../shared/report-settings';

const $inject = [
  '$element',
  'erReportSettingsService',
  'erStateService',
  'erCacheService',
  'serviceLevelService',
  'EnergyReportingClient',
];

class ReportModalController {
  constructor($element, erReportSettingsService, erStateService, erCacheService, serviceLevelService, erClient) {
    this.$element = $element;
    this.erReportSettingsService = erReportSettingsService;
    this.erStateService = erStateService;
    this.erCacheService = erCacheService;
    this.serviceLevelService = serviceLevelService;
    this.erClient = erClient;

    this.initialized = false;
    this.meterCount = 0;
    erReportSettingsService.setActiveInstance('er-modal-report');
    this.erReportSettings = this.erReportSettingsService.getInstance('er-modal-report');
  }

  $onInit() {
    this.erStateService.openModal();
    this.onSettingsChangeUnbind = this.erReportSettings.setCallback(this.onSettingsChange.bind(this));

    this.setInitialReportType();
    this.setSidebarConfig();

    this.bookmarkOptions = {
      enableAdd: true,
      getBookmark: () => ({
        modal: 'report-modal',
        stateParams: {
          reportType: this.reportTypeName,
          reportParams: this.erReportSettings.getSettings()
        }
      })
    };

    this.reportParams = this.getInitialReportParams();
    this.erReportSettings.setSettings(this.reportParams);
    this.isEnegiaIdShownInReportArea = false;

    this.erCacheService.getCache()
      .then(result => {
        this.cache = result;

        const facilityId = this.reportParams.facilityId[0];

        // Try to request facility if not in cache (facility not in profile, but user might have access anyway)
        if (!(facilityId in this.cache.facilitiesById)) {
          return firstValueFrom(this.erClient.getFacilityInformationById(facilityId)).then(facilityInfo => {
            this.cache.facilitiesById[facilityId] = facilityInfo;
          });
        }
      })
      .then(() => {
        this.initialized = true;
        this.modalTitle = this.cache.facilitiesById[this.reportParams.facilityId[0]].Name;
        this.enegiaId = this.getEnegiaId();
      });
  }

  getEnegiaId() {
    return this.cache.facilitiesById[this.reportParams.facilityId[0]].FacilityInformation.EnegiaId;
  }

  setInitialReportType() {
    if (!this.serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium)) {
      this.reportGroup = modalStates.FACILITY;
      this.setReportType(modalStates.FACILITY_OVERVIEW);
    } else if (this.modalParams.reportType) {
      this.reportGroup = modalStates.REPORT_GROUPS.find(
        group => group.reports.some(report => report.name === this.modalParams.reportType)
      );
      const reportType = this.reportGroup.reports.find(report => report.name === this.modalParams.reportType);
      this.setReportType(reportType);
    } else {
      this.reportGroup = modalStates.FACILITY;
      this.setReportType(this.reportGroup.defaultReport);
    }
  }

  setReportType(reportType) {
    this.reportTypeName = reportType.name;
    this.downloadOptions = reportType.download;
    this.downloadOptions.element = this.$element[0];
    this.helpOptions = { name: reportType.name };
  }

  setSidebarConfig() {
    this.sidebarItems = _.cloneDeep(ITEM_GROUPS.find(group => group.name === this.reportGroup.name).items);
    this.sidebarItems.forEach(item => {
      _.set(item, 'isHidden', false);
    });
    const sidebarLinks = _.cloneDeep(MODAL_LINKS);
    this.sidebarLinks = sidebarLinks
      .filter(linkConfig => this.serviceLevelService.hasAtLeastServiceLevel(linkConfig.params.requiredServiceLevel))
      .map(linkConfig => {
        linkConfig.onClick = this.onReportGroupChange.bind(this);
        linkConfig.active = linkConfig.params.group.name === this.reportGroup.name;
        return linkConfig;
      });
  }

  onReportGroupChange(params) {
    this.sidebarItems = params.sidebarItems;
    this.reportGroup = params.group;
    this.setReportType(this.reportGroup.defaultReport);
    this.updateReportSettings(this.getReportTypeParams());
    if (params.group.name === modalStates.METER.name) {
      this.meterReportsDisabled = true;
    }
  }

  onReportTypeChange(reportType) {
    const reportTypeParams = this.getReportTypeParams(reportType);
    this.setReportType(reportTypeParams);
    this.updateReportSettings(reportTypeParams);
    if (this.erReportSettings.getSettings().name === modalStates.FACILITY_OVERVIEW.name) {
      this.sidebarItems = [null];
      this.sidebarLinks = [];
    } else {
      this.setSidebarConfig();
    }
  }

  getInitialReportParams() {
    // Get params from the state where modal was opened
    const parentErOptions = this.erReportSettingsService.getInstance('er-report');
    const parentParams = parentErOptions.getSettings();
    // They are overwritten by params specific to selected report type
    const reportTypeParams = this.getReportTypeParams();
    // And finally they are overwritten by params with which the modal was called
    const modalParams = this.getModalParams();

    return new ReportSettings(parentParams, { quantityId: [] }, reportTypeParams, modalParams);
  }

  updateReportSettings(reportTypeParams) {
    this.reportParams = new ReportSettings(this.reportParams, reportTypeParams);
    this.erReportSettings.setSettings(this.reportParams);
  }

  getReportTypeParams(reportType) {
    const reportTypeName = _.get(reportType, 'name') || this.reportTypeName;
    const groupParams = this.reportGroup;
    const reportTypeParams = this.reportGroup.reports.find(report => report.name === reportTypeName);
    return { ...groupParams, ...reportTypeParams, ...reportType };
  }

  getModalParams() {
    // It seems some older bookmarks contain broken values. Remove those.
    const modalParams = this.modalParams.reportParams;
    if (!modalParams.unitKey) {
      delete modalParams.unitKey;
    }
    if (!modalParams.changeType) {
      delete modalParams.changeType;
    }
    if (typeof modalParams.reportType !== 'number' && modalParams.reportType !== undefined) {
      delete modalParams.reportType;
    }
    return modalParams;
  }

  onSettingsChange() {
    this.reportParams = new ReportSettings(this.erReportSettings.getSettings());
    this.meterCount = this.reportParams.meterId.length;
  }

  $onDestroy() {
    this.erStateService.closeModal();
    this.onSettingsChangeUnbind();
    this.erReportSettingsService.setActiveInstance('er-report');
  }
}

ReportModalController.$inject = $inject;
export default ReportModalController;
