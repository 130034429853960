import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  TemplateRef,
  ViewChild
} from '@angular/core';

import {
  LabelPosition,
} from '../directives/label-base.directive';

type LabelDirectiveProps = {
  /** `textContent` of the label */
  labelText: string;

  /** `[ngClass]` of the label */
  labelClass: string;

  /** Selector for the focused element when label is clicked. Use `null` to bypass. */
  target: string | null;

  /** Optional tooltip that will be appended to label if truthy. */
  tooltip: string | null;

  /** Variable for optional span with *, which indicate that this value is required */
  required: boolean;
};

@Component({
  selector: 'label-directive-component',
  templateUrl: './label-directive.component.html',
  styleUrls: ['./label-directive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelDirectiveComponent {
  public position: LabelPosition;
  public template: TemplateRef<unknown>;

  @HostBinding('class')
  public labelClass: string;

  public wrapTemplate: TemplateRef<unknown> | null;
  public beforeTemplate: TemplateRef<unknown> | null;

  public labelText: string;
  public tooltip: string;

  public required: boolean = false;
  private _clickTarget: string = '';

  @ViewChild('labelElement', { static: true })
  private readonly labelElement: ElementRef<HTMLLabelElement>;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  public setProps(props: LabelDirectiveProps): void {
    this.required = props.required;
    this.labelText = props.labelText;
    this.labelClass = props.labelClass;
    this.tooltip = props.tooltip;
    this._clickTarget = props.target;

    if (this.position === 'before') {
      this.beforeTemplate = this.template;
      this.wrapTemplate = null;
    } else {
      this.beforeTemplate = null;
      this.wrapTemplate = this.template;
    }

    this.changeDetector.markForCheck();
  }

  public labelClick($event: MouseEvent): void {
    if (this._clickTarget !== null && this.position !== 'wrap') {
      const parent = this.labelElement.nativeElement.parentElement;

      if (parent) {
        const element = parent.querySelector(this._clickTarget || 'input');

        if (this.focusInput(element) || this.focusElement(element)) {
          $event.preventDefault();
        }
      }
    }
  }

  private focusInput(input: Element): boolean {
    if (input instanceof HTMLInputElement) {
      switch (input.type) {
        case 'checkbox':
          input.dispatchEvent(new MouseEvent('click', { bubbles: true }));
          break;
        case 'radio':
          input.dispatchEvent(new MouseEvent('click', { bubbles: true }));
          break;
        default:
          input.focus();
          break;
      }

      return true;
    } else {
      return false;
    }
  }

  private focusElement(element: Element): boolean {
    if (element instanceof HTMLElement) {
      if (element.isContentEditable) {
        // Click handlers don't work with contentEditable's focus without setTimeout for some reason
        setTimeout(() => element.focus());
      } else {
        element.focus();
      }

      return true;
    } else {
      return false;
    }
  }
}
