<quantity-info-header
  [quantityData]="data.sumData"
></quantity-info-header>
<div class="sum-consumption-charts-container">
  <consumption-chart
    [quantityData]="data.sumData"
    [resolution]="resolution$ | async"
    [style.cursor]="hasServiceLevelMedium ? 'pointer' : 'initial'"
    (click)="chartClick()"
  ></consumption-chart>
  <sum-consumptions-pie-chart
    [data]="data.quantitiesData"
    [isNormalized]="isNormalized"
  ></sum-consumptions-pie-chart>
</div>
<sum-consumptions-table
  [data]="data.quantitiesData"
  [total]="data.sumData"
  [hasComparisonPeriod]="hasComparisonPeriod$ | async"
></sum-consumptions-table>
