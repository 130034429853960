import { ControlValueAccessor } from '@angular/forms';

import MatchersUtil = jasmine.MatchersUtil;
import CustomMatcherFactories = jasmine.CustomMatcherFactories;
import CustomMatcher = jasmine.CustomMatcher;
import CustomMatcherResult = jasmine.CustomMatcherResult;

/* eslint-disable multiline-ternary */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jasmine/no-unsafe-spy */

declare const global: any;

type CVAShim = {
  change: (value: any) => void;
  touch: () => void;
  changespy: jasmine.Spy<CVAShim['change']>;
  touchspy: jasmine.Spy<CVAShim['touch']>;
}

let _shimStorage: Map<ControlValueAccessor, CVAShim>;

/* istanbul ignore next */
function getSpied(component: ControlValueAccessor): CVAShim {
  if (!component) {
    throw Error('Cannot expect() null ControlValueAccessor');
  }
  if (!_shimStorage) {
    throw Error('ControlValueAccessor matchers not initialized');
  }
  const value = _shimStorage.get(component);
  if (!value) {
    throw Error('ControlValueAccessor must be spied on using spyOnControlValueAccessor()');
  }
  return value;
}

const controlValueAccessorMatchers: CustomMatcherFactories = {
  toHaveBeenTouched: function(): CustomMatcher {
    return {
      compare: (target: ControlValueAccessor) => _toHaveBeenTouchedBase(target, false),
      negativeCompare: (target: ControlValueAccessor) => _toHaveBeenTouchedBase(target, true),
    };
  },
  toHaveChangedValue: function(): CustomMatcher {
    return {
      compare: (target: ControlValueAccessor) => _toHaveChangedValueAnyBase(target, false),
      negativeCompare: (target: ControlValueAccessor) => _toHaveChangedValueAnyBase(target, true),
    };
  },
  toHaveChangedValueTo: function(util: MatchersUtil): CustomMatcher {
    return {
      compare: (target: ControlValueAccessor, ...values: any[]) => _toHaveChangedValueToBase(
        target, values, false, util
      ),
      negativeCompare: (target: ControlValueAccessor, ...values: any[]) => _toHaveChangedValueToBase(
        target, values, true, util
      ),
    };
  }
};

function _toHaveChangedValueAnyBase(
  target: ControlValueAccessor,
  negative: boolean
): CustomMatcherResult {
  const { changespy } = getSpied(target);

  return {
    pass: changespy.calls.any() !== negative,
    message: `Expected CVA ${negative ? 'not ' : ''}to have changed value, but it did${negative ? '' : ' not'}.`
  };
}

function _toHaveChangedValueToBase(
  target: ControlValueAccessor,
  values: any[],
  negative: boolean,
  util: MatchersUtil
): CustomMatcherResult {
  const { changespy } = getSpied(target);

  if (!changespy.calls.any()) {
    return {
      pass: negative, // no calls, pass only if "not"ed
      message: `Expected CVA ${negative ? 'not ' : ''}to have changed value, but it ${negative ? '' : 'never '}did.`,
    };
  }

  const expected = values[0];
  const actual = changespy.calls.mostRecent().args[0];

  return {
    pass: util.equals(expected, actual) !== negative,
    message: `Expected CVA ${negative ?
      'not ' : ''}to have emitted ${util.pp(expected)}, but it${
      negative ? ' did' : ` emitted: ${util.pp(actual)}`}`,
  };
}

function _toHaveBeenTouchedBase(
  target: ControlValueAccessor,
  negative: boolean
): CustomMatcherResult {
  const shim = getSpied(target);

  const touched = shim.touchspy.calls.any();
  shim.touchspy.calls.reset();

  return {
    pass: touched !== negative,
    message: `Expected CVA ${negative ? 'not ' : ''}to have been touched, but it was${negative ? '' : ' not'}.`,
  };
}

function spyOnCvaImpl(component: ControlValueAccessor): void {
  if (_shimStorage.has(component)) {
    throw Error('ControlValueAccessor already spied');
  }

  const shim: CVAShim = {
    change: (_x: any) => { },
    touch: (..._args: any[]) => { },
    changespy: null,
    touchspy: null,
  };

  shim.changespy = spyOn(shim, 'change');
  shim.touchspy = spyOn(shim, 'touch');

  component.registerOnChange(shim.change);
  component.registerOnTouched(shim.touch);

  _shimStorage.set(component, shim);
}

/**
 * Initializes matchers for ControlValueAccessor testing.
 */
export function __setupControlValueAccessorTesting(): any {
  /* istanbul ignore next */
  if (typeof spyOnControlValueAccessor === 'function') {
    throw Error('spyOnObservable already initialized');
  }

  // Browser + Node
  (
    window || /* istanbul ignore next */ global
  ).spyOnControlValueAccessor = spyOnCvaImpl;

  beforeEach(() => {
    _shimStorage = new Map<ControlValueAccessor, CVAShim>();
    jasmine.addMatchers(controlValueAccessorMatchers);
  });

  afterEach(() => {
    _shimStorage = null;
  });
}
