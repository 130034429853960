import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ConfigurationControlClient } from '@enerkey/clients/configuration-control';
import { Facility, FacilityClient, FacilitySearchCriteriaDto } from '@enerkey/clients/facility';
import { MeterManagementClient, MeterManagementMeter } from '@enerkey/clients/meter-management';
import { MeteringClient, MeterSearchCriteria, MeterTagDTO, PoaService } from '@enerkey/clients/metering';
import { DatahubClient } from '@enerkey/clients/datahub';
import { indicate, LoadingSubject, switchJoin } from '@enerkey/rxjs';

import { ToasterService } from '../../../shared/services/toaster.service';
import { QuantityService } from '../../../shared/services/quantity.service';
import { UserService } from '../../../services/user-service';
import { MeterHierarchyFactory, MeterHierarchyTreelistItem } from '../shared/meter-hierarchy-factory';
import { TelemetryService } from '../../../services/telemetry.service';
import { DialogService } from '../../../shared/services/dialog.service';

type SearchMethod = (params: MeterSearchParameters) => Observable<MeterHierarchyTreelistItem[]>;

type AuthorizablePoaService = Extract<PoaService, PoaService.DanishDataHub | PoaService.FinnishDataHub>;

type TagInfo = {
  tagIdentifier: Record<string, boolean>;
  tagNames: string[];
};

export type MeterTag = Record<string, TagInfo>;

export type MeterTags = Record<string, MeterTag>;

export interface MeterSearchParameters {
  currentProfile?: boolean;
  profileIdsFromSelect?: number[];
  profileIds?: number[];
  facilityName?: string;
  facilityIds?: number[];
  enegiaIds?: number[];
  meterIds?: number[];
  terminalIds?: number[];
  terminalName?: string;
  eanCode?: string;
  protocolCode?: string;
  usagePlaceNumber?: string;
  energyCompanyUsagePlaceNumbers?: string;
}

@Injectable()
export class MeterManagementService implements OnDestroy {
  public readonly selectedMeters$: Observable<MeterHierarchyTreelistItem[]>;
  public readonly currentHierarchy$: Observable<MeterHierarchyTreelistItem[]>;
  public readonly flatMeters$: Observable<MeterHierarchyTreelistItem[]>;
  public readonly loading$: Observable<boolean>;
  public readonly uniqueTags$: Observable<{ tagName: string, id: string }[]>;

  private readonly searchMethods: Record<keyof MeterSearchParameters, SearchMethod>;

  private readonly _selectedMeters$ = new BehaviorSubject<MeterHierarchyTreelistItem[]>([]);
  private readonly _uniqueTags$ = new BehaviorSubject<{ tagName: string, id: string }[]>([]);
  private readonly _previousSelectedMeters$ = new BehaviorSubject<MeterHierarchyTreelistItem[]>([]);
  private readonly _searchParams$ = new BehaviorSubject<MeterSearchParameters>(null);
  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    telemetry: TelemetryService,
    private readonly toaster: ToasterService,
    private readonly facilityClient: FacilityClient,
    private readonly meterManagementClient: MeterManagementClient,
    private readonly meteringClient: MeteringClient,
    private readonly configurationControlClient: ConfigurationControlClient,
    private readonly quantityService: QuantityService,
    private readonly userService: UserService,
    private readonly dialogService: DialogService,
    private readonly datahubClient: DatahubClient
  ) {
    this.loading$ = this._loading$.asObservable();
    this.searchMethods = {
      currentProfile: _ => this.searchWithCurrentProfile(),
      profileIdsFromSelect: arg => this.searchWithProfileIdsFromSelect(arg),
      profileIds: arg => this.searchWithProfileIds(arg),
      facilityName: arg => this.searchWithFacilityName(arg),
      facilityIds: arg => this.searchWithFacilityIds(arg),
      enegiaIds: arg => this.searchWithEnegiaIds(arg),
      meterIds: arg => this.searchWithMeterIds(arg),
      terminalIds: arg => this.searchWithTerminalIds(arg),
      terminalName: arg => this.searchWithTerminalName(arg),
      eanCode: arg => this.searchWithEanCode(arg),
      protocolCode: arg => this.searchWithProtocolCode(arg),
      usagePlaceNumber: arg => this.searchWithUsagePlaceNumber(arg),
      energyCompanyUsagePlaceNumbers: arg => this.searchWithEnergyCompanyUsagePlaceNumber(arg),
    };
    this.selectedMeters$ = this._selectedMeters$.asObservable();
    this.uniqueTags$ = this._uniqueTags$.asObservable();
    this.currentHierarchy$ = this._searchParams$.pipe(
      filter(value => !!value),
      switchMap(params => this.doSearch(params).pipe(
        indicate(this._loading$),
        tap(meters => {
          if (!Array.hasItems(meters)) {
            this.toaster.info('ADMIN.NO_RESULTS_WITH_CURRENT_CRITERIA');
          }
        }),
        catchError(err => {
          telemetry.trackError(err, 'MeterManagementService.currentHierarchy$');
          this.toaster.generalError('LOAD', 'METERS');
          return of([]);
        }),
        takeUntil(this._destroy$)
      )),
      startWith([]),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.flatMeters$ = this.currentHierarchy$.pipe(
      map(meters => this.getFlatData(meters)),
      takeUntil(this._destroy$),
      shareReplay(1)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._selectedMeters$.complete();
    this._previousSelectedMeters$.complete();
    this._loading$.complete();
    this._searchParams$.complete();
    this._uniqueTags$.complete();
  }

  public search(params: MeterSearchParameters): void {
    this._searchParams$.next(params);
  }

  public setSelectedMeters(meters: MeterHierarchyTreelistItem[]): void {
    this._selectedMeters$.next(meters);
  }

  public getSelectedMeters(): MeterHierarchyTreelistItem[] {
    return this._selectedMeters$.value;
  }

  public removePoaFromMeters(): void {
    const selectedMeters = this.getSelectedMeters();
    const meterIds = selectedMeters.map(m => m.meter.id);

    this.dialogService.getConfirmationModal({
      title: 'CONFIRM_DELETE',
      text: 'ADMIN.POA.REMOVE_POA_FROM_METERS_CONFIRM',
      isDelete: true,
      translate: true
    })
      .pipe(
        switchMap(() => this.meteringClient.removeMetersFromPoACompany(meterIds).pipe(
          indicate(this._loading$)
        ))
      )
      .subscribe({
        next: () => {
          this.repeatSearch();
        },
        error: () => {
          this.toaster.error('ADMIN.POA.FAILED_TO_REMOVE_METERS_FROM_POA');
        }
      });
  }

  public repeatSearch(): void {
    this._previousSelectedMeters$.next(this.getSelectedMeters());
    this._searchParams$.next(this._searchParams$.value);
  }

  public authorizeMetersForDataHub(poaService: AuthorizablePoaService): void {
    const meterIds = this.getSelectedMeters().map(m => m.meter.id);
    const endpoint = poaService === PoaService.DanishDataHub
      ? 'requestAuhtorizationsForMeters'
      : 'setCustomerAuthorization'
    ;

    this.datahubClient[endpoint](meterIds).pipe(indicate(this._loading$)).subscribe({
      next: response => {
        if (!response.every(r => r.accepted)) {
          const errorToastContent = response.filterMap(
            r => !r.accepted,
            r => `${r.meterId}: ${r.eventReasonText}`
          );
          this.toaster.toast({
            type: 'error',
            message: errorToastContent.join('</br></br>'),
            title: 'ADMIN.POA.DATAHUB_AUTHORIZATION_FAILED_FOR_SOME_METERS',
            translate: true,
            wider: true,
            allowHtml: true,
            disableTimeOut: true
          });
        } else {
          this.toaster.success('ADMIN.POA.DATAHUB_AUTHORIZATION_SUCCESSFUL');
        }
        this.repeatSearch();
      },
      error: () => {
        this.toaster.error('ADMIN.POA.DATAHUB_AUTHORIZATION_FAILED');
      }
    });
  }

  private getFlatData(data: MeterHierarchyTreelistItem[]): MeterHierarchyTreelistItem[] {
    return data.flatMap(row => [row, ...this.getFlatData(row.children ?? [])]).filter(row => row.meter);
  }

  private doSearch(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    this.setSelectedMeters([]);
    const searchKey = Object.keys(params)[0] as keyof MeterSearchParameters;
    return this.searchMethods[searchKey](params);
  }

  private searchWithCurrentProfile(): Observable<MeterHierarchyTreelistItem[]> {
    return this.profileIdSearch([this.userService.profileId]);
  }

  private searchWithProfileIds(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.profileIdSearch(params.profileIds);
  }

  private searchWithProfileIdsFromSelect(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.profileIdSearch(params.profileIdsFromSelect);
  }

  private searchWithFacilityIds(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.getHierarchiesForFacilityIds(params.facilityIds);
  }

  private searchWithFacilityName(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.facilityClient.getFacilityIdsByNameContainsCaseInsensitive(params.facilityName).pipe(
      switchMap(ids => this.getHierarchiesForFacilityIds(ids))
    );
  }

  private getHierarchiesForFacilityIds(ids: number[]): Observable<MeterHierarchyTreelistItem[]> {
    if (!Array.hasItems(ids)) {
      return of([]);
    }
    return this.facilityClient.getFacilities(ids.unique()).pipe(
      switchMap(facilities => this.getMetersForFacilities(facilities))
    );
  }

  private searchWithEnegiaIds(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.facilityClient.searchFacilitiesUsingEnegiaIds(params.enegiaIds).pipe(
      switchMap(facilities => this.getMetersForFacilities(facilities))
    );
  }

  private searchWithMeterIds(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.getHierarchiesForMeterIds(params.meterIds, true, true);
  }

  private getHierarchiesForMeterIds(
    ids: number[],
    highlight = false,
    select = false
  ): Observable<MeterHierarchyTreelistItem[]> {
    if (!Array.hasItems(ids)) {
      return of([]);
    }
    return this.meterManagementClient.getMetersById(ids).pipe(
      switchMap(meters => this.facilityClient.getFacilities(Object.keys(meters).map(id => Number.parseInt(id)))),
      switchMap(facilities => this.getMetersForFacilities(
        facilities,
        highlight ? new Set(ids) : new Set()
      )),
      tap(hierarchy => {
        if (select && !Array.hasItems(this.getSelectedMeters())) {
          this.setSelectedMeters(this.getFlatData(hierarchy).filter(i => ids.includes(i.meter.id)));
        }
      })
    );
  }

  private searchWithTerminalIds(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.configurationControlClient.searchTerminalsByIdentifierList(params.terminalIds).pipe(
      switchMap(terminals => this.getHierarchiesForMeterIds(
        terminals.flatMap(t => t.terminalMeters.map(tm => tm.meterId)).unique(), true
      ))
    );
  }

  private searchWithTerminalName(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.configurationControlClient.searchTerminalsByName(params.terminalName).pipe(
      switchMap(terminals => this.getHierarchiesForMeterIds(
        terminals.flatMap(t => t.terminalMeters.map(tm => tm.meterId)).unique(), true
      ))
    );
  }

  private searchWithEanCode(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.meteringClient.searchMeters(new MeterSearchCriteria({
      eanCodes: [params.eanCode]
    })).pipe(
      switchMap(meters => this.getHierarchiesForMeterIds(meters.unique('id'), true, true))
    );
  }

  private searchWithProtocolCode(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.meteringClient.searchMeters(new MeterSearchCriteria({
      protocolCodes: [params.protocolCode]
    })).pipe(
      switchMap(meters => this.getHierarchiesForMeterIds(meters.unique('id'), true, true))
    );
  }

  private searchWithUsagePlaceNumber(params: MeterSearchParameters): Observable<MeterHierarchyTreelistItem[]> {
    return this.meteringClient.searchMeters(new MeterSearchCriteria({
      usagePlaceNumbers: [params.usagePlaceNumber]
    })).pipe(
      switchMap(meters => this.getHierarchiesForMeterIds(meters.unique('id'), true, true))
    );
  }

  private searchWithEnergyCompanyUsagePlaceNumber(
    params: MeterSearchParameters
  ): Observable<MeterHierarchyTreelistItem[]> {
    return this.meteringClient.searchMeters(new MeterSearchCriteria({
      energyCompanyUsagePlaceNumbers: [params.energyCompanyUsagePlaceNumbers]
    })).pipe(
      switchMap(meters => this.getHierarchiesForMeterIds(meters.unique('id'), true, true))
    );
  }

  private processMeterTags(tags: MeterTagDTO[],
    facilityMeters: { [key: string]: MeterManagementMeter[]
    }): MeterTags {
    const uniqueTags = tags.map(tag => ({ tagName: tag.tagName, id: tag.tagId })).uniqueBy('id');
    this._uniqueTags$.next(uniqueTags);

    const tagsByMeterId = tags.reduce((acc: { [key: number]: MeterTagDTO[] }, tag) => {
      if (!acc[tag.meterId]) {
        acc[tag.meterId] = [];
      }
      acc[tag.meterId].push(tag);
      return acc;
    }, {});

    const tagTemplate = uniqueTags.reduce((acc: { [key: string]: boolean }, tag) => {
      acc[tag.id] = false;
      return acc;
    }, {});
    return this.mapFacilityMetersToTags(facilityMeters, tagsByMeterId, tagTemplate, uniqueTags);
  }

  private mapFacilityMetersToTags(
    facilityMeters: { [key: string]: MeterManagementMeter[] },
    tagsByMeterId: { [key: number]: MeterTagDTO[] },
    tagTemplate: { [key: string]: boolean },
    uniqueTags: { tagName: string, id: string }[]
  ): MeterTags {
    return Object.entries(facilityMeters).reduce((
      acc: { [key: string]: { [key: string]: { tagIdentifier: {
        [key: string]: boolean }, tagNames: string[] } } }, [facilityId, meters]
    ) => {
      acc[facilityId] = meters.reduce((meterAcc: { [key: string]: { tagIdentifier: {
        [key: string]: boolean }, tagNames: string[] } }, meter) => {
        const meterTags = tagsByMeterId[meter.id] || [];
        const tagIdentifier = { ...tagTemplate };
        const uniqueIds = new Set(uniqueTags.map(tag => tag.id));
        const tagNames: string[] = [];

        meterTags.forEach(tag => {
          if (uniqueIds.has(tag.tagId)) {
            tagIdentifier[tag.tagId] = true;
            tagNames.push(tag.tagName);
          }
        });
        meterAcc[meter.id] = { tagIdentifier, tagNames };
        return meterAcc;
      }, {});
      return acc;
    }, {});
  }

  private profileIdSearch(profileIds: number[]): Observable<MeterHierarchyTreelistItem[]> {
    return this.facilityClient.getFacilitiesBySearchCriteria(new FacilitySearchCriteriaDto({
      profileIds
    })).pipe(
      switchMap(facilities => this.getMetersForFacilities(facilities))
    );
  }

  private getMetersForFacilities(
    facilities: Facility[],
    highlightedMeterIds?: Set<number>
  ): Observable<MeterHierarchyTreelistItem[]> {
    if (!Array.hasItems(facilities)) {
      return of([]);
    }

    const facilityIds = facilities.map(f => f.id);

    const facilityMeters$ = this.meterManagementClient.getMetersByFacility(facilityIds).pipe(
      shareReplay(1)
    );

    const meters$ = facilityMeters$.pipe(
      switchJoin(facilityMeters => {
        const meterIds = Object.values(facilityMeters).flat().unique(m => m.id);
        return this.meteringClient.getPoACompaniesForMeterIds(meterIds);
      })
    );

    const meterTags$ = facilityMeters$.pipe(
      switchMap(facilityMeters => {
        const allMeterId = Object.values(facilityMeters).flat().unique(m => m.id);
        return forkJoin({
          tags: this.meteringClient.getTagsForMeters(allMeterId),
          meters: of(facilityMeters)
        }).pipe(
          map(({ tags, meters: meters }) => this.processMeterTags(tags, meters))
        );
      })
    );

    const meterHierarchies$ = this.meterManagementClient.getMeterHierarchiesForFacilities(facilityIds);

    return forkJoin([
      meters$,
      meterHierarchies$,
      this.quantityService.getAllQuantities(),
      meterTags$
    ]).pipe(
      map(([[meters, poas], hierarchies, quantities, meterTags]) => {
        const quantityMap = quantities.toMapBy('ID');
        const quantityNames = quantities.toMap(
          q => q.ID,
          q => this.quantityService.getQuantityNameForId(q.ID)
        );
        return facilities.mapFilter(
          facility => MeterHierarchyFactory.getFacilityHierarchy(
            facility,
            meters[facility.id],
            hierarchies[facility.id],
            quantityMap,
            quantityNames,
            poas,
            meterTags[facility.id],
            highlightedMeterIds
          ),
          treeListItem => Array.hasItems(treeListItem.children)
        );
      }),
      tap(hierarchy => this.repeatSelection(hierarchy))
    );
  }

  private repeatSelection(hierarchy: MeterHierarchyTreelistItem[]): void {
    if (!Array.hasItems(this._previousSelectedMeters$.value)) {
      return;
    }
    const selectedIds: number[] = [];
    for (const m of this._previousSelectedMeters$.value) {
      selectedIds.push(m.meter.id);
    }
    this.setSelectedMeters(this.getFlatData(hierarchy).filter(i => selectedIds.includes(i.meter.id)));
    this._previousSelectedMeters$.next([]);
  }
}
