import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import jsonpatch from 'fast-json-patch';
import { forkJoin, Observable } from 'rxjs';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import {
  MeterHierarchy,
  MeterManagementClient,
  Operation,
  SubMeter
} from '@enerkey/clients/meter-management';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { MeterHierarchyTreelistItem } from '../../shared/meter-hierarchy-factory';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'meter-reorder-modal',
  templateUrl: './meter-reorder-modal.component.html',
  styleUrls: ['./meter-reorder-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterReorderModalComponent extends ModalBase implements OnInit, OnDestroy {
  public meters: MeterHierarchyTreelistItem[];
  public readonly loading$: Observable<boolean>;

  private originalOrder: MeterHierarchyTreelistItem[];
  private readonly _loading$ = new LoadingSubject(false);

  public constructor(
    private readonly meterManagementClient: MeterManagementClient,
    private readonly toasterService: ToasterService,
    ngfActiveModal: NgfActiveModal
  ) {
    super(ngfActiveModal);
    this.loading$ = this._loading$.asObservable();
  }

  public ngOnInit(): void {
    this.originalOrder = [...this.meters];
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public orderAlphabetically(): void {
    this.meters = this.meters.sortBy('hierarchyName');
  }

  public resetOrder(): void {
    this.meters = [...this.originalOrder];
  }

  public saveOrder(): void {
    if (!this.hasOrderChanged()) {
      super.dismissModal();
      return;
    }

    this.getReorderRequest()
      .pipe(indicate(this._loading$))
      .subscribe({
        next: () => {
          this.toasterService.success('ADMIN.METER_SEARCH.SAVING_HIERARCHIES.SUCCESS');
          super.closeModal();
        },
        error: () => {
          this.toasterService.error('ADMIN.METER_SEARCH.SAVING_HIERARCHIES.ERROR');
        }
      });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private getReorderRequest(): Observable<unknown> {
    const facilityId = this.meters[0].facility.id;
    return this.meterManagementClient.getMeterHierarchiesForFacilities([facilityId]).pipe(
      map(hierarchies => hierarchies[facilityId]),
      switchMap(result => this.changeOrder(result))
    );
  }

  private hasOrderChanged(): boolean {
    return this.meters.some(
      (meter, index) => meter.hierarchyId !== this.originalOrder[index].hierarchyId
    );
  }

  private changeOrder(hierarchies: MeterHierarchy[]): Observable<MeterHierarchy[]> {
    const observers = hierarchies.map(hierarchy => jsonpatch.observe<MeterHierarchy>(hierarchy));
    this.meters.forEach((meter, index) => {
      const hierarchyToEdit = hierarchies.find(
        hierarchy => hierarchy.id === meter.hierarchyTree[0]
      );
      this.changeOrdinal(hierarchyToEdit.mainMeter, meter.meter.id, index);
    });

    const patches = observers
      .mapFilter(
        observer => {
          const jsonPatches = jsonpatch.generate(observer);
          return {
            hierarchyId: observer.object.id,
            patches: jsonPatches.map(p => new Operation(p))
          };
        },
        fh => Array.hasItems(fh.patches)
      );

    return forkJoin(patches.map(
      patch => this.meterManagementClient.patchMeterHierarchy(patch.hierarchyId, patch.patches)
    ));
  }

  private changeOrdinal(subMeter: SubMeter, meterId: number, ordinal: number): void {
    if (subMeter.meterId === meterId) {
      subMeter.ordinal = ordinal;
    } else {
      subMeter.subMeters.forEach(subSubMeter => this.changeOrdinal(subSubMeter, meterId, ordinal));
    }
  }
}
