import {
  animate,
  animateChild,
  AnimationMetadata,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

const expandTransition: AnimationMetadata[] = [
  animate('250ms ease-in'),
  query('@fadeInOut', group([
    animateChild()
  ]), { optional: true }),
];

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease-in', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('100ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('hostSize', [
      state('collapsed', style({
        width: 'calc(40px + var(--spacing-default))'
      })),
      state('expanded', style({
        width: 'calc(var(--sidebar-section-width) * 3)'
      })),
      state('expanded-meters', style({
        width: 'calc(var(--sidebar-section-width) * 2)'
      })),
      transition('* => expanded', expandTransition),
      transition('* => expanded-meters', expandTransition),
      transition('* => collapsed', [
        group([
          query('@fadeInOut', group([
            animateChild()
          ]), { optional: true }),
        ]),
        animate('250ms ease-in'),
      ]),
    ])
  ]
})
export class SidebarComponent implements OnDestroy, OnInit {
  @Input() public isModal: boolean;
  @Input() public isMeterReport: boolean;

  public hostSizeAnimation: 'expanded' | 'collapsed' | 'expanded-meters';

  public readonly isCollapsed$: Observable<boolean>;

  private readonly _isCollapsed$ = new BehaviorSubject(false);
  private readonly destroy$ = new Subject<void>();

  public constructor() {
    this.isCollapsed$ = this._isCollapsed$.asObservable();
  }

  @HostListener('document:keydown.escape', ['$event'])
  public escPress(event: KeyboardEvent): void {
    if (!this._isCollapsed$.value && !event.defaultPrevented) {
      this._isCollapsed$.next(true);
      event.preventDefault();
    }
  }

  public ngOnInit(): void {
    if (this.isModal) {
      this._isCollapsed$.next(true);
    }

    this._isCollapsed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(collapsed => {
      // eslint-disable-next-line no-nested-ternary
        this.hostSizeAnimation = collapsed
          ? 'collapsed'
          : (this.isMeterReport ? 'expanded-meters' : 'expanded');
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this._isCollapsed$.complete();
  }

  public toggleSidebar(): void {
    this._isCollapsed$.next(!this._isCollapsed$.value);
  }
}
