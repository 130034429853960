<ng-template #topbarTemplate>
  <div class="content-right float-right">
    <ek-dropdown>
      <ek-dropdown-heading>
        {{ 'DROPDOWN.ACTIONS' | translate }}
      </ek-dropdown-heading>
      <ek-dropdown-item
        (click)="updateServices()"
        [disabled]="!gridComponent.selectedProfiles.length"
      >
        {{ 'ADMIN.BULK_MODIFY_SERVICES.MODIFY_SERVICES_BUTTON' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        (click)="updateCompanies()"
        [disabled]="!gridComponent.selectedProfiles.length"
      >
      {{ 'ADMIN.BULK_MODIFY_COMPANY.MODIFY_COMPANY_BUTTON' | translate }}
      </ek-dropdown-item>
    </ek-dropdown>
    <button class="button" (click)="addProfile()">
      <i class="fa fa-plus"></i> {{ 'ADMIN.ADD_PROFILE.OPEN_MODAL' | translate }}
    </button>
  </div>
</ng-template>
<div class="row">
  <div class="large-2 medium-3 columns">
    <profile-management-search></profile-management-search>
  </div>
  <div class="large-10 medium-9 columns">
    <profile-management-grid></profile-management-grid>
  </div>
</div>
