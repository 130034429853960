import meterCreateController from './meter-create-controller';
import meterCreateTemplate from 'raw-loader!./meter-create.html';

export default {
  template: meterCreateTemplate,
  controllerAs: 'vm',
  controller: meterCreateController,
  bindings: {
    onClose: '&',
    onSpreadsheetCreate: '&',
    metersCreated: '&'
  }
};
