import { PeriodReportAlarmsPopupController } from './period-report-alarms-popup.controller';
import periodReportAlarmsPopupTemplate from 'raw-loader!./period-report-alarms-popup.html';

export const periodReportAlarmsPopupComponent = {
  template: periodReportAlarmsPopupTemplate,
  controller: PeriodReportAlarmsPopupController,
  controllerAs: 'vm',
  bindings: {
    alarmsAllowed: '<',
    item: '<'
  }
};
