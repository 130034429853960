import MetersReportController from './meters-report.controller';
import MetersReportTemplate from 'raw-loader!./meters-report.html';

export default {
  template: MetersReportTemplate,
  controller: MetersReportController,
  controllerAs: 'vm',
  bindings: {
    reportParams: '<',
    cache: '<'
  }
};
