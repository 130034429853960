import controller from './admin-spreadsheet-controller';
import template from 'raw-loader!./admin-spreadsheet.html';

export default {
  template: template,
  controllerAs: 'vm',
  controller: controller,
  bindings: {
    includeQuantityId: '<',
    includeCustomType: '<',
    facilityIds: '<',
    onSpreadsheetCreate: '&'
  }
};
