import ManualQaConsumptionStatsController from './manual-qa-consumption-stats-controller';
import template                           from 'raw-loader!./manual-qa-consumption-stats.html';

export default {
  template: template,
  controller: ManualQaConsumptionStatsController,
  controllerAs: 'vm',
  bindings: {
    dataSource: '<',
    meter: '<',
    operationTimeFrame: '<',
    isPreviewMode: '<',
    useComparisonPeriod: '<',
    broadcastResize: '<',
    showAll: '<'
  }
};
