import ManualQaSearchController from './manual-qa-search-controller';
import template                 from 'raw-loader!./manual-qa-search.html';

export default {
  template: template,
  controller: ManualQaSearchController,
  controllerAs: 'vm',
  bindings: {
    searchList: '<',
    readerTypes: '<'
  }
};
