import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';

@Component({
  selector: 'simple-report-header',
  templateUrl: './simple-report-header.component.html',
  styleUrls: ['./simple-report-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleReportHeaderComponent {
  @Input() public quantityId: Quantities;
}
