/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming

import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';

export const EMAIL_CONSUMPTION_REPORT_API_BASE_URL = new InjectionToken<string>('EMAIL_CONSUMPTION_REPORT_API_BASE_URL');

export interface IEmailConsumptionReportClient {
    /**
     * @param body (optional) 
     * @return Success
     */
    addOrUpdateEmailSubscription(body?: AddOrUpdateEmailSubscriptionSettingsRequest | undefined): Observable<void>;
    /**
     * @param userId (optional) 
     * @return Success
     */
    getEmailSubscription(userId?: number | undefined): Observable<SubscriptionInfoResponse>;
    /**
     * @param id (optional) 
     * @return Success
     */
    removeEmailSubscription(id?: number | undefined): Observable<void>;
}

@Injectable({
    providedIn: 'root'
})
export class EmailConsumptionReportClient implements IEmailConsumptionReportClient {
    private http: HttpClient;
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(EMAIL_CONSUMPTION_REPORT_API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    /**
     * @param body (optional) 
     * @return Success
     */
    addOrUpdateEmailSubscription(body?: AddOrUpdateEmailSubscriptionSettingsRequest | undefined): Observable<void> {
        let url_ = this.baseUrl + "/api/v1/consumptionsubscriptions";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(body);

        let options_ : any = {
            body: content_,
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Content-Type": "application/json",
            })
        };

        return this.http.request("post", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processAddOrUpdateEmailSubscription(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processAddOrUpdateEmailSubscription(response_ as any);
                } catch (e) {
                    return _observableThrow(e) as any as Observable<void>;
                }
            } else
                return _observableThrow(response_) as any as Observable<void>;
        }));
    }

    protected processAddOrUpdateEmailSubscription(response: HttpResponseBase): Observable<void> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (response as any).error instanceof Blob ? (response as any).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            return _observableOf(null as any);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(null as any);
    }

    /**
     * @param userId (optional) 
     * @return Success
     */
    getEmailSubscription(userId?: number | undefined): Observable<SubscriptionInfoResponse> {
        let url_ = this.baseUrl + "/api/v1/consumptionsubscriptions?";
        if (userId === null)
            throw new Error("The parameter 'userId' cannot be null.");
        else if (userId !== undefined)
            url_ += "userId=" + encodeURIComponent("" + userId) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
                "Accept": "application/json"
            })
        };

        return this.http.request("get", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processGetEmailSubscription(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processGetEmailSubscription(response_ as any);
                } catch (e) {
                    return _observableThrow(e) as any as Observable<SubscriptionInfoResponse>;
                }
            } else
                return _observableThrow(response_) as any as Observable<SubscriptionInfoResponse>;
        }));
    }

    protected processGetEmailSubscription(response: HttpResponseBase): Observable<SubscriptionInfoResponse> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (response as any).error instanceof Blob ? (response as any).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            let result200: any = null;
            let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result200 = SubscriptionInfoResponse.fromJS(resultData200);
            return _observableOf(result200);
            }));
        } else if (status === 404) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            let result404: any = null;
            let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result404 = ProblemDetails.fromJS(resultData404);
            return throwException("Not Found", status, _responseText, _headers, result404);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(null as any);
    }

    /**
     * @param id (optional) 
     * @return Success
     */
    removeEmailSubscription(id?: number | undefined): Observable<void> {
        let url_ = this.baseUrl + "/api/v1/consumptionsubscriptions?";
        if (id === null)
            throw new Error("The parameter 'id' cannot be null.");
        else if (id !== undefined)
            url_ += "id=" + encodeURIComponent("" + id) + "&";
        url_ = url_.replace(/[?&]$/, "");

        let options_ : any = {
            observe: "response",
            responseType: "blob",
            headers: new HttpHeaders({
            })
        };

        return this.http.request("delete", url_, options_).pipe(_observableMergeMap((response_ : any) => {
            return this.processRemoveEmailSubscription(response_);
        })).pipe(_observableCatch((response_: any) => {
            if (response_ instanceof HttpResponseBase) {
                try {
                    return this.processRemoveEmailSubscription(response_ as any);
                } catch (e) {
                    return _observableThrow(e) as any as Observable<void>;
                }
            } else
                return _observableThrow(response_) as any as Observable<void>;
        }));
    }

    protected processRemoveEmailSubscription(response: HttpResponseBase): Observable<void> {
        const status = response.status;
        const responseBlob =
            response instanceof HttpResponse ? response.body :
            (response as any).error instanceof Blob ? (response as any).error : undefined;

        let _headers: any = {}; if (response.headers) { for (let key of response.headers.keys()) { _headers[key] = response.headers.get(key); }}
        if (status === 200) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            return _observableOf(null as any);
            }));
        } else if (status === 404) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            let result404: any = null;
            let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
            result404 = ProblemDetails.fromJS(resultData404);
            return throwException("Not Found", status, _responseText, _headers, result404);
            }));
        } else if (status === 500) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            return throwException("Server Error", status, _responseText, _headers);
            }));
        } else if (status !== 200 && status !== 204) {
            return blobToText(responseBlob).pipe(_observableMergeMap((_responseText: string) => {
            return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            }));
        }
        return _observableOf(null as any);
    }
}

export class AddOrUpdateEmailSubscriptionSettingsRequest implements IAddOrUpdateEmailSubscriptionSettingsRequest {
    facilities?: FacilityFrequencyBase[] | null;
    userId?: number;

    constructor(data?: IAddOrUpdateEmailSubscriptionSettingsRequest) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["facilities"])) {
                this.facilities = [] as any;
                for (let item of _data["facilities"])
                    this.facilities!.push(FacilityFrequencyBase.fromJS(item));
            }
            else {
                this.facilities = <any>null;
            }
            this.userId = _data["userId"] !== undefined ? _data["userId"] : <any>null;
        }
    }

    static fromJS(data: any): AddOrUpdateEmailSubscriptionSettingsRequest {
        data = typeof data === 'object' ? data : {};
        let result = new AddOrUpdateEmailSubscriptionSettingsRequest();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.facilities)) {
            data["facilities"] = [];
            for (let item of this.facilities)
                data["facilities"].push(item.toJSON());
        }
        data["userId"] = this.userId !== undefined ? this.userId : <any>null;
        return data;
    }

    clone(): AddOrUpdateEmailSubscriptionSettingsRequest {
        const json = this.toJSON();
        let result = new AddOrUpdateEmailSubscriptionSettingsRequest();
        result.init(json);
        return result;
    }
}

export interface IAddOrUpdateEmailSubscriptionSettingsRequest {
    facilities?: FacilityFrequencyBase[] | null;
    userId?: number;
}

export class FacilityFrequencyBase implements IFacilityFrequencyBase {
    facility?: number;
    monthly?: boolean;
    weekly?: boolean;

    constructor(data?: IFacilityFrequencyBase) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.facility = _data["facility"] !== undefined ? _data["facility"] : <any>null;
            this.monthly = _data["monthly"] !== undefined ? _data["monthly"] : <any>null;
            this.weekly = _data["weekly"] !== undefined ? _data["weekly"] : <any>null;
        }
    }

    static fromJS(data: any): FacilityFrequencyBase {
        data = typeof data === 'object' ? data : {};
        let result = new FacilityFrequencyBase();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["facility"] = this.facility !== undefined ? this.facility : <any>null;
        data["monthly"] = this.monthly !== undefined ? this.monthly : <any>null;
        data["weekly"] = this.weekly !== undefined ? this.weekly : <any>null;
        return data;
    }

    clone(): FacilityFrequencyBase {
        const json = this.toJSON();
        let result = new FacilityFrequencyBase();
        result.init(json);
        return result;
    }
}

export interface IFacilityFrequencyBase {
    facility?: number;
    monthly?: boolean;
    weekly?: boolean;
}

export class ProblemDetails implements IProblemDetails {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;

    [key: string]: any;

    constructor(data?: IProblemDetails) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            for (var property in _data) {
                if (_data.hasOwnProperty(property))
                    this[property] = _data[property];
            }
            this.type = _data["type"] !== undefined ? _data["type"] : <any>null;
            this.title = _data["title"] !== undefined ? _data["title"] : <any>null;
            this.status = _data["status"] !== undefined ? _data["status"] : <any>null;
            this.detail = _data["detail"] !== undefined ? _data["detail"] : <any>null;
            this.instance = _data["instance"] !== undefined ? _data["instance"] : <any>null;
        }
    }

    static fromJS(data: any): ProblemDetails {
        data = typeof data === 'object' ? data : {};
        let result = new ProblemDetails();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        for (var property in this) {
            if (this.hasOwnProperty(property))
                data[property] = this[property];
        }
        data["type"] = this.type !== undefined ? this.type : <any>null;
        data["title"] = this.title !== undefined ? this.title : <any>null;
        data["status"] = this.status !== undefined ? this.status : <any>null;
        data["detail"] = this.detail !== undefined ? this.detail : <any>null;
        data["instance"] = this.instance !== undefined ? this.instance : <any>null;
        return data;
    }

    clone(): ProblemDetails {
        const json = this.toJSON();
        let result = new ProblemDetails();
        result.init(json);
        return result;
    }
}

export interface IProblemDetails {
    type?: string | null;
    title?: string | null;
    status?: number | null;
    detail?: string | null;
    instance?: string | null;

    [key: string]: any;
}

export class SubscriptionInfoResponse implements ISubscriptionInfoResponse {
    facilities?: FacilityFrequencyBase[] | null;
    userId?: number;

    constructor(data?: ISubscriptionInfoResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["facilities"])) {
                this.facilities = [] as any;
                for (let item of _data["facilities"])
                    this.facilities!.push(FacilityFrequencyBase.fromJS(item));
            }
            else {
                this.facilities = <any>null;
            }
            this.userId = _data["userId"] !== undefined ? _data["userId"] : <any>null;
        }
    }

    static fromJS(data: any): SubscriptionInfoResponse {
        data = typeof data === 'object' ? data : {};
        let result = new SubscriptionInfoResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        if (Array.isArray(this.facilities)) {
            data["facilities"] = [];
            for (let item of this.facilities)
                data["facilities"].push(item.toJSON());
        }
        data["userId"] = this.userId !== undefined ? this.userId : <any>null;
        return data;
    }

    clone(): SubscriptionInfoResponse {
        const json = this.toJSON();
        let result = new SubscriptionInfoResponse();
        result.init(json);
        return result;
    }
}

export interface ISubscriptionInfoResponse {
    facilities?: FacilityFrequencyBase[] | null;
    userId?: number;
}

export class SwaggerException extends Error {
    override message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
    return _observableThrow(new SwaggerException(message, status, response, headers, result));
}

function blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = event => {
                observer.next((event.target as any).result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
}