/* eslint-disable no-invalid-this */
/* eslint-disable @typescript-eslint/no-explicit-any */

//#region Mutating functions

export function getOrAdd<K, V>(
  this: Map<K, V>,
  key: K,
  valueFactory: () => V
): V {
  if (this.has(key)) {
    return this.get(key);
  }

  const value = valueFactory();

  this.set(key, value);
  return value;
}

export function toRecord<K extends keyof any, V>(
  this: Map<K, V>
): Record<K, V> {
  const result = {} as Record<K, V>;

  if (this.size === 0) {
    return result;
  }

  for (const [key, value] of this) {
    if (key === null || key === undefined) {
      continue;
    }

    result[key] = value;
  }

  return result;
}

//#endregion Mutating functions

//#region Non-mutating functions

export function getKeys<K>(
  this: ReadonlyMap<K, any>
): K[] {
  return Array.from(this.keys());
}

export function getEntries<K, V>(
  this: ReadonlyMap<K, V>
): [K, V][] {
  return Array.from(this.entries());
}

//#region Non-mutating functions
