import * as Operations from './operations';

const $inject = ['utils'];

class ManualQaActionsOperationService {
  constructor(utils) {
    this.utils = utils;
  }

  getOperations() {
    return [
      [
        {
          id: Operations.LOCK_MODEL,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.LOCK_MODEL'),
          isDisabled: false
        },
        {
          id: Operations.UNLOCK_MODEL,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.UNLOCK_MODEL'),
          isDisabled: false
        },
        {
          id: Operations.DELETE_MODEL,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.DELETE_MODEL'),
          isDisabled: false,
          customClass: 'button--negative'
        }
      ],
      [
        {
          id: Operations.LOCK_CONSUMPTION,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.LOCK_CONSUMPTION'),
          isDisabled: true
        },
        {
          id: Operations.UNLOCK_CONSUMPTION,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.UNLOCK_CONSUMPTION'),
          isDisabled: true
        },
        {
          id: Operations.DELETE_CONSUMPTION,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.DELETE_CONSUMPTION'),
          isDisabled: false,
          customClass: 'button--negative'
        }
      ],
      [
        /* {
         id: Operations.QA_COMMENT,
         name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.QA_COMMENT'),
         isDisabled: true
         },
         {
         id: Operations.JIRA_TICKET,
         name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.JIRA_TICKET'),
         isDisabled: true
         }, */
        {
          id: Operations.METER_HISTORY,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.METER_HISTORY'),
          isDisabled: false
        },
        {
          id: Operations.METER_INTERFACE_STATUS,
          name: this.utils.localizedString('MQA.INSPECT.ACTIONS.BUTTONS.METER_INTERFACE_STATUS'),
          isDisabled: false
        }
      ]
    ];
  }
}

ManualQaActionsOperationService.$inject = $inject;

export default ManualQaActionsOperationService;
