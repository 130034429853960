import { Injectable, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/core';
import { forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import {
  AggregateCollection,
  ConsumptionsRequest,
  ConsumptionsRequestQuantity,
  EnergyReportingClient,
  QuantityItem,
} from '@enerkey/clients/energy-reporting';

import { SumConsumptionsWidgetOptions } from '../components/sum-consumptions-widget/sum-consumptions-widget.component';
import ErTimeFrameService, { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { getValueTypeOptions } from '../../energy-reporting/shared/value-type-options';
import { QuantityService } from '../../../shared/services/quantity.service';
import { ColorService } from '../../../shared/services/color.service';
import { TimeFrameString } from '../../../services/time-frame-service';
import { SumConsumptions, SumConsumptionWidgetValues } from '../shared/sum-consumptions';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { WidgetQueueService } from './widget-queue.service';

@Injectable()
export class SumConsumptionsWidgetService implements OnDestroy {

  public readonly start$: Observable<TimeFrameResult>;
  public readonly resolution$: Observable<TimeFrameString>;
  public readonly comparisonPeriodKey$: Observable<string>;
  public readonly inspectionPeriodKey$: Observable<string>;
  public readonly hasComparisonPeriod$: Observable<boolean>;

  private readonly _dataModelOptions$ = new ReplaySubject<SumConsumptionsWidgetOptions>(1);
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly erClient: EnergyReportingClient,
    private readonly quantityService: QuantityService,
    private readonly stateService: StateService,
    private readonly colorService: ColorService,
    private readonly widgetQueue: WidgetQueueService,
    private readonly timeFrameService: ErTimeFrameService
  ) {
    this.start$ = this._dataModelOptions$.pipe(
      map(dataModelOptions => this.timeFrameService.getTimeFrameAndResParams(
        dataModelOptions.timeFrameOption,
        dataModelOptions.comparisonPeriodOption as string
      )),
      shareReplay(1)
    );
    this.inspectionPeriodKey$ = this.start$.pipe(map(start => start.Start[0].key));
    this.comparisonPeriodKey$ = this.start$.pipe(map(start => start.Start[1]?.key || ''));
    this.resolution$ = this.start$.pipe(map(start => start.Resolution));
    this.hasComparisonPeriod$ = this.start$.pipe(map(start => start.Start.length > 1));
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._dataModelOptions$.complete();
  }

  public setDataModelOptions(options: SumConsumptionsWidgetOptions): void {
    this._dataModelOptions$.next(options);
  }

  public chartClick(params: {
    quantityId: Quantities;
    facilityIds: number[];
    isNormalized: boolean;
  }): void {
    forkJoin([
      this._dataModelOptions$.pipe(take(1)),
      this.start$.pipe(take(1))
    ]).subscribe(([dataModelOptions, start]) => {
      this.stateService.go(
        'facilities.report-sum',
        {
          quantityId: [params.quantityId],
          facilityId: params.facilityIds,
          series: {
            Measured: !params.isNormalized,
            Normalized: params.isNormalized,
            Comparables: dataModelOptions.comparableOption,
            TimeFrame: start.TimeFrame,
            Resolution: start.Resolution,
            Start: start.Start
          }
        }
      );
    });
  }

  public getSumConsumptionsData(
    facilityIds: number[] | null,
    threshold: number
  ): Observable<SumConsumptionWidgetValues> {
    return forkJoin([
      this._dataModelOptions$.pipe(take(1)),
      this.start$.pipe(take(1)),
      this.quantityService.getProfileQuantities()
    ]).pipe(
      switchMap(([dataModelOptions, start, profileQuantities]) => {
        const {
          selectedQuantityId,
          comparableOption,
          valueOption,
        } = dataModelOptions;

        const profileQuantityIds = profileQuantities.map(q => q.ID);
        const quantitiesMap = profileQuantities.toMapBy('ID');
        const sumQuantity = quantitiesMap.get(selectedQuantityId);

        const summedQuantityIds = sumQuantity.SumOf;
        const valueOptions = getValueTypeOptions(valueOption);

        const quantityColors = summedQuantityIds.toMap(
          quantityId => quantityId,
          quantityId => this.colorService.getQuantityColor(quantityId)
        );

        const quantityIds = {
          sumQuantity: sumQuantity.ID,
          profileQuantities: profileQuantityIds,
          sumOfQuantities: summedQuantityIds
        };

        // Includes sum quantity ID and it's summed quantities
        const allQuantities = [
          sumQuantity,
          ...summedQuantityIds.mapFilter(quantityId => quantitiesMap.get(quantityId), q => !!q),
        ];

        return this.getQuantitiesData(
          allQuantities,
          facilityIds,
          start,
          sumQuantity.Units.Default.Unit,
          valueOptions.normalized,
          comparableOption,
          threshold
        ).pipe(
          map(quantitiesData => ({
            measured: valueOptions.measured
              ? SumConsumptions.getWidgetData(
                quantitiesData,
                'Reading',
                quantityIds,
                start,
                quantityColors
              )
              : null,
            normalized: valueOptions.normalized
              ? SumConsumptions.getWidgetData(
                quantitiesData,
                'NormalizedReading',
                quantityIds,
                start,
                quantityColors
              )
              : null,
          }))
        );
      }),
      takeUntil(this._destroy$)
    );
  }

  private getQuantitiesData(
    quantities: QuantityItem[],
    facilityIds: number[],
    start: TimeFrameResult,
    unit: string,
    isNormalized: boolean,
    comparableOption: Comparability,
    threshold: number
  ): Observable<{ [key: string]: AggregateCollection }> {
    const quantityParam = quantities.map<ConsumptionsRequestQuantity>(quantity => ({
      Id: quantity.ID,
      Comparables: comparableOption,
      Normalisation: isNormalized && quantity.Normalization,
    }));

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const params: ConsumptionsRequest = {
      Quantities: quantityParam,
      FacilityId: facilityIds,
      ThresholdForIncomplete: threshold,
      Start: start.Start as any,
      TimeFrame: start.TimeFrame as any,
      // start.Timeframe can be used as resolution if issue ENER-7019 gets fixed
      Resolution: start.Resolution as any,
      Unit: unit as any,
      AggregateType: 'sum' as any,
    };
    /* eslint-enable @typescript-eslint/no-explicit-any */

    return this.erClient.postQuantitys(params).pipe(this.widgetQueue.queue('energyreporting', this._destroy$));
  }
}
