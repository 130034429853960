/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefectType, FaultType, InspectionItem } from '@enerkey/clients/manual-qa';

import DefectTypeStatic from '../../shared/defect-type';
import {
  COLUMN_AGGREGATES, GRID_COLUMNS, groupHeaderTemplate, kendoGridHeaderCheckboxClass
} from './grid';
import { ToggleableGridColumn } from '../../constants/toggleable-grid-column';
import { integerFilterUi } from '../../../../shared/ek-kendo/kendo.functions';
import { TranslationsMap } from './manual-qa-result-service';

export function getCommonColumns(translations: TranslationsMap): ToggleableGridColumn[] {
  return [
    {
      field: GRID_COLUMNS.SELECTED,
      title: ' ',
      groupable: false,
      sortable: false,
      locked: true,
      filterable: {
        multi: true
      },
      width: 55,
      headerTemplate: `
        <input
          type="checkbox"
          ng-change="vm.selectAll()"
          ng-model="vm.allSelected"
        />
      `,
      template: `
        <input
          type="checkbox"
          ng-model="dataItem.selected"
          ng-change="vm.select(this.dataItem)"
        />
      `,
      attributes: {
        class: 'cell-center'
      }
    },
    {
      field: GRID_COLUMNS.METERING_POINT_NAME,
      title: translations['MQA.RESULTS.GRID.METERING_POINT_NAME'],
      locked: true,
      filterable: {
        multi: true,
        search: true
      },
      width: 250,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.METER_ID,
      title: translations['MQA.RESULTS.GRID.METER_ID'],
      locked: true,
      filterable: {
        extra: false,
        operators: {
          number: {
            eq: kendo.ui.FilterMenu.fn.options.operators.number.eq
          }
        },
        ui: integerFilterUi
      },
      width: 100,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.ENEGIA_ID,
      title: translations['MQA.RESULTS.GRID.ENEGIA_ID'],
      locked: true,
      filterable: {
        extra: false,
        operators: {
          number: {
            eq: kendo.ui.FilterMenu.fn.options.operators.number.eq
          }
        },
        ui: integerFilterUi
      },
      width: 100,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.FACILITY_NAME,
      title: translations['MQA.RESULTS.GRID.FACILITY_NAME'],
      groupHeaderTemplate: getGroupHeaderCheckboxTemplate,
      filterable: {
        multi: true,
        search: true
      },
      width: 250,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.QUANTITY_NAME,
      title: translations['MQA.RESULTS.GRID.QUANTITY'],
      groupHeaderTemplate: getGroupHeaderCheckboxTemplate,
      filterable: {
        multi: true,
        search: true
      },
      width: 150,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.READER_TYPE,
      title: translations['MQA.RESULTS.GRID.READER_TYPE'],
      groupHeaderTemplate: groupHeaderTemplate,
      hidden: true,
      filterable: {
        multi: true,
        search: true
      },
      width: 150,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.READING_GROUP_IDENTIFIER,
      title: translations['MQA.RESULTS.GRID.READING_GROUP_IDENTIFIER'],
      groupHeaderTemplate: groupHeaderTemplate,
      filterable: {
        multi: true,
        search: true
      },
      width: 170,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.USAGEPLACE_NUMBER,
      title: translations['USAGE_PLACE_NUMBER'],
      groupHeaderTemplate: groupHeaderTemplate,
      hidden: true,
      filterable: {
        multi: true,
        search: true
      },
      width: 200,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.SELLER_USAGEPLACE_NUMBER,
      title: translations['SELLER_USAGE_PLACE_NUMBER'],
      groupHeaderTemplate: groupHeaderTemplate,
      hidden: true,
      filterable: {
        multi: true,
        search: true
      },
      width: 200,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.EAN_CODE,
      title: translations['EAN_CODE'],
      groupHeaderTemplate: groupHeaderTemplate,
      filterable: {
        multi: true,
        search: true
      },
      width: 200,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.PROTOCOL_CODE,
      title: translations['PROTOCOL_CODE'],
      groupHeaderTemplate: groupHeaderTemplate,
      hidden: false,
      filterable: {
        multi: true,
        search: true
      },
      width: 200,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.AUTOMATIC_READING_START_TIME,
      title: translations['AUTOMATIC_READING_START_TIME'],
      groupHeaderTemplate: groupHeaderTemplate,
      format: '{0:d}',
      filterable: true,
      width: 100,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.AUTOMATIC_READING_END_TIME,
      title: translations['AUTOMATIC_READING_END_TIME'],
      groupHeaderTemplate: groupHeaderTemplate,
      hidden: false,
      format: '{0:d}',
      filterable: true,
      width: 100,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.METER_STORAGE_START_TIME,
      title: translations['METER_STORAGE_START_TIME'],
      groupHeaderTemplate: groupHeaderTemplate,
      format: '{0:d}',
      hidden: true,
      filterable: true,
      width: 200,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.DEACTIVATION_TIME,
      title: translations['DEACTIVATION_TIME'],
      groupHeaderTemplate: groupHeaderTemplate,
      format: '{0:d}',
      hidden: true,
      filterable: true,
      width: 200,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.METER_IDENTIFIER,
      title: translations['MQA.RESULTS.GRID.METER_IDENTIFIER'],
      groupHeaderTemplate: groupHeaderTemplate,
      hidden: true,
      filterable: {
        multi: true,
        search: true
      },
      width: 150,
      toggleable: true
    },
    {
      field: GRID_COLUMNS.BUSINESS_IDENTITY_CODE,
      title: translations['BUSINESS_IDENTITY_CODE'],
      groupHeaderTemplate: groupHeaderTemplate,
      filterable: {
        extra: false,
        operators: {
          number: {
            eq: kendo.ui.FilterMenu.fn.options.operators.number.eq
          }
        },
        ui: integerFilterUi
      },
      width: 150,
      toggleable: true,
      hidden: false
    },
    {
      field: GRID_COLUMNS.AUTOMATIC_MODELING,
      title: translations['MQA.RESULTS.GRID.AUTOMATIC_MODELING'],
      groupHeaderTemplate: groupHeaderTemplate,
      template: (dataItem: any) =>
        dataItem.automaticModeling
          ? '<i class="fa fa-check" ></i>'
          : '<i></i>',
      hidden: true,
      width: 150,
      toggleable: true
    },
  ];
}

export function getDefectTypesColumn(translations: TranslationsMap): ToggleableGridColumn {
  return {
    field: GRID_COLUMNS.DEFECT_TYPES,
    title: translations['MQA.RESULTS.GRID.DEFECT_TYPE'],
    groupHeaderTemplate: (group: any) => formatDefectTypePlain(
      group.items[0].defectTypes.map((d: DefectType) => d.value), translations
    ),
    template: (meter: InspectionItem) => formatDefectType(meter, translations),
    filterable: {
      multi: true
    },
    width: 150,
    toggleable: true
  };
}

export function getCommonOptions(): kendo.ui.GridOptions {
  const options: kendo.ui.GridOptions = {
    dataSource: {
      pageSize: 25,
      serverPaging: false,
      serverSorting: false,
      schema: {
        model: {
          fields: {
            [GRID_COLUMNS.ENEGIA_ID]: { type: 'number' },
            [GRID_COLUMNS.METER_ID]: { type: 'number' },
            [GRID_COLUMNS.BUSINESS_IDENTITY_CODE]: { type: 'number' },
            [GRID_COLUMNS.AUTOMATIC_READING_START_TIME]: { type: 'date' },
            [GRID_COLUMNS.AUTOMATIC_READING_END_TIME]: { type: 'date' },
            [GRID_COLUMNS.METER_STORAGE_START_TIME]: { type: 'date' },
            [GRID_COLUMNS.DEACTIVATION_TIME]: { type: 'date' },
            [GRID_COLUMNS.AUTOMATIC_MODELING]: { type: 'boolean' },
          }
        }
      }
    },
    sortable: true,
    pageable: true,
    reorderable: true,
    filterable: true,
    groupable: true,
    resizable: true,
    columns: [],
    excel: {
      allPages: true
    },
    toolbar: '<toggle-grid-columns-button-only grid="vm.grid"></toggle-grid-columns-button-only>'
  };
  return options;
}

function formatDefectType(meter: InspectionItem, translations: TranslationsMap): string {
  const { defectTypes } = meter;

  const reducer = (result: string[], defectType: DefectType): string[] => {
    const name = translations[DefectTypeStatic.getDefectTypeNameTag(defectType.value) as keyof TranslationsMap];

    return defectType.exceedsInspectedRange
      ? result.concat(`<span class="ek-color ek-color--negative">${name}</span>`)
      : result.concat(`<span>${name}</span>`);
  };

  const title = formatDefectTypePlain(defectTypes.map(t => t.value), translations);
  return `<span title="${title}">${defectTypes.reduce(reducer, []).join(', ')}</span>`;
}

export function formatDefectTypePlain(faultTypes: FaultType[], translations: TranslationsMap): string {
  return faultTypes
    .map(faultType => translations[DefectTypeStatic.getDefectTypeNameTag(faultType) as keyof TranslationsMap])
    .join(', ');
}

export const getVisualizationConfig = (): any => [{
  title: 'MQA.VISUALS.CATEGORY_TITLE',
  columns: COLUMN_AGGREGATES
}];

function getGroupHeaderCheckboxTemplate(group: any): string {
  return `<input
    class="${kendoGridHeaderCheckboxClass}"
    type="checkbox"
    ng-click="vm.selectOrUnselectGroup($event, this.dataItem)"
  />
  <span class="kendo-grid-header-checkbox-text">${group.value}</span>`;
}
