import {
  Directive,
  TemplateRef,
} from '@angular/core';
import { DataBindingContext } from '@enerkey/ts-utils';

/**
 * Context for custom `ek-dropdown` toggle button.
 */
export interface EkDropdownToggleContext extends DataBindingContext<() => void> {
  toggle: () => void;
  show: () => void;
  hide: () => void;
  disabled: boolean;
  visible: boolean;
}

/**
 * Use to create a custom toggle button for `ek-dropdown`.
 *
 * @example
 * ```html
 * <button
 *   *ekDropdownToggle="let toggle; let isDisabled = disabled; let isVisible = visible"
 *   (click)="toggle()"
 *   [disabled]="isDisabled"
 * >
 *   {{ isVisible ? 'visible' : 'hidden' }}
 * </button>
 * ```
 */
@Directive({
  selector: '[ekDropdownToggle]'
})
export class EkDropdownToggleDirective {

  public constructor(
    public readonly templateRef: TemplateRef<EkDropdownToggleContext>
  ) { }

}
