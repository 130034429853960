import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';

import { assertUnreachable, KeysOfType } from '@enerkey/ts-utils';
import { Quantities } from '@enerkey/clients/metering';

import { YearReadings } from '../components/yearly-trend-report/yearly-trend-report.component';
import { QuantityUnitPipe } from '../../common-pipes/quantity-unit.pipe';
import { RelationalValueId } from '../../../modules/reportingobjects/constants/facilities-properties';
import { RelationalValuesService } from '../../services/relational-values.service';

@Pipe({ name: 'yearlyTrendReportTableCellHeader' })
export class YearlyTrendReportTableCellHeaderPipe implements PipeTransform {
  public constructor(
    private readonly quantityUnitPipe: QuantityUnitPipe,
    private readonly translateService: TranslateService,
    private readonly relationalValueService: RelationalValuesService
  ) {
  }

  public transform(field: KeysOfType<YearReadings, number>, quantityId: Quantities): Observable<string> {
    switch (field) {
      case 'reading':
        return forkJoin([
          this.translateService.get('YEARLY_TREND.CONSUMPTION'),
          this.quantityUnitPipe.transform(quantityId)
        ]).pipe(map(
          ([translation, unit]) => `${translation} ${unit}`
        ));
      case 'normalizedReading':
        return forkJoin([
          this.translateService.get('YEARLY_TREND.NORMALIZED'),
          this.quantityUnitPipe.transform(quantityId)
        ]).pipe(map(
          ([translation, unit]) => `${translation} ${unit}`
        ));
      case 'diffPercentage':
        return this.translateService.get('YEARLY_TREND.CHANGE_PERCENTAGE');
      case 'specificConsumption':
        return forkJoin([
          this.translateService.get('YEARLY_TREND.SPECIFIC_CONSUMPTION'),
          this.relationalValueService.getRelationalValueQuantityUnit(
            RelationalValueId.TotalVolume, quantityId
          )
        ]).pipe(map(
          ([translation, unit]) => `${translation} ${unit}`
        ));
      case 'specificConsumptionPerPerson':
        return forkJoin([
          this.translateService.get('YEARLY_TREND.SPECIFIC_CONSUMPTION'),
          this.relationalValueService.getRelationalValueQuantityUnit(
            RelationalValueId.Inhabitants, quantityId
          )
        ]).pipe(map(
          ([translation, unit]) => `${translation} ${unit}`
        ));
      /* istanbul ignore else */
      default:
        return assertUnreachable(field);
    }
  }
}
