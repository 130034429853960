<kendo-grid
  [kendoGridBinding]="facilities$ | async"
  [loading]="loading$ | async"
  [pageSize]="50"
  [pageable]="true"
  [sortable]="true"
  [resizable]="true"
  [groupable]="true"
  [selectable]="gridSelectableSettings"
  [selectedKeys]="selectedFacilities$ | async"
  [kendoGridSelectBy]="selectKey"
  filterable="menu"
  class="grid-full-height"
>
  <kendo-grid-excel
    [fileName]="'ADMIN.FACILITIES' | translate | excelFileName:currentDate"
    [excelDataSource]="facilities$ | async"
  ></kendo-grid-excel>
  <ng-template kendoGridToolbarTemplate>
    <kendo-grid-grouped-column-chooser></kendo-grid-grouped-column-chooser>
    <button kendoGridExcelCommand>{{ 'ADMIN.GET_EXCEL' | translate }}</button>
  </ng-template>
  <kendo-grid-checkbox-column
    [showSelectAll]="true"
    [width]="28"
    [locked]="true"
  >
    <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
  </kendo-grid-checkbox-column>
  <kendo-grid-column
    field="facility.displayName"
    [locked]="true"
    [width]="220"
    [includeInChooser]="false"
    [title]="'ADMIN.FACILITY_NAME' | translate"
  >
    <ng-template *ngIf="showMeterCount" kendoGridFooterTemplate>
      {{ 'FACILITIES.SUM' | translate }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [locked]="true"
    [width]="70"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        kendoGridEditCommand
        (click)="openEditModal(dataItem)"
      ></button>
      <button
        kendoButton
        [primary]="true"
        [iconClass]="'fa-1x fas fa-tag'"
        [fillMode]="'flat'"
        [size]="'small'"
        [title]="'TAGS.EDIT.SINGLE' | translate"
        (click)="openTagEditor(dataItem)"
      >
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column-group
    [title]="'DOCUMENTS.INVOICE_ARCHIVE.GRID.FACILITY_INFO.TITLE' | translate"
  >
    <kendo-grid-column
      field="facility.id"
      [title]="'ADMIN.ID' | translate"
      [width]="65"
      [hidden]="true"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.enegiaId"
      [title]="'ADMIN.ENEGIAID' | translate"
      [hidden]="false"
      [width]="defaultColumnWidth"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.customerSpecifiedIdentifier"
      [title]="'ADMIN.REALESTATE_ID' | translate"
      [hidden]="false"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.companyName"
      [title]="'ADMIN.COMPANY' | translate"
      [filterable]="true"
      [width]="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.firstAddress.streetAddress"
      [title]="'FACILITYINFORMATION.STREETADDRESS' | translate"
      [hidden]="false"
      [width]="140"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.firstAddress.postalCode"
      [title]="'FACILITYINFORMATION.POSTALCODE' | translate"
      [hidden]="false"
      [width]="120"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.firstAddress.city"
      [title]="'FACILITYINFORMATION.CITY' | translate"
      [hidden]="false"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.firstAddress.country"
      [title]="'FACILITYINFORMATION.COUNTRY' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.geographicalArea"
      [title]="'FACILITY.EDIT_FORM.GEOGRAPHICAREA' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.localeId"
      [title]="'ADMIN.SPREADSHEET.LOCALE_PAGE' | translate"
      [hidden]="true"
      [width]="110"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="(locales$ | async) as locales">
          {{ locales[dataItem.facility.localeId] }}
        </ng-container>
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-multiselect-filter
          [filter]="filter"
          [field]="column.field"
          [filterService]="filterService"
          [dataSource]="facilities$ | async"
        >
          <span *gridMultiFilterTemplate="let value = value">
            <ng-container *ngIf="(locales$ | async) as locales">
              {{ locales[value] }}
            </ng-container>
          </span>
        </grid-multiselect-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="facility.buildingClassId"
      [title]="'ADMIN.SPREADSHEET.BUILDING_CLASS_PAGE' | translate"
      [hidden]="true"
      [width]="170"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="(buildingClasses$ | async) as bClasses">
          {{ bClasses[dataItem.facility.buildingClassId] }}
        </ng-container>
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-multiselect-filter
          [filter]="filter"
          [field]="column.field"
          [filterService]="filterService"
          [dataSource]="facilities$ | async"
        >
          <span *gridMultiFilterTemplate="let value = value">
            <ng-container *ngIf="(buildingClasses$ | async) as bClasses">
              {{ bClasses[value] }}
            </ng-container>
          </span>
        </grid-multiselect-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="facility.customBuildingClass"
      [title]="'FACILITYINFORMATION.CUSTOMBUILDINGCLASS' | translate"
      [hidden]="true"
      [width]="140"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.yearOfBuild"
      [title]="'FACILITIES.CONSTRUCTION_YEAR' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.ownership"
      [title]="'FACILITYINFORMATION.OWNERSHIP' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ OwnershipTypes[dataItem.facility.ownership] | translate }}
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-multiselect-filter
          [filter]="filter"
          [field]="column.field"
          [filterService]="filterService"
          [dataSource]="facilities$ | async"
        >
          <span *gridMultiFilterTemplate="let value = value">
              {{ OwnershipTypes[value] | translate }}
          </span>
        </grid-multiselect-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="facility.managementResponsibility"
      [title]="'FACILITYINFORMATION.MANAGEMENTRESPONSIBILITY' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
      filter="boolean"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ (dataItem.managementResponsibility ? 'YES' : '') | translate }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="facility.electricityTaxClass"
      [title]="'ADMIN.SPREADSHEET.ELECTRICITY_TAX_CLASS_PAGE' | translate"
      [hidden]="true"
      [width]="150"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ ElectricityTaxClassTypes[dataItem.facility.electricityTaxClass] | translate }}
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-multiselect-filter
          [filter]="filter"
          [field]="column.field"
          [filterService]="filterService"
          [dataSource]="facilities$ | async"
        >
          <span *gridMultiFilterTemplate="let value = value">
            {{ ElectricityTaxClassTypes[value] | translate }}
          </span>
        </grid-multiselect-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="facility.customerSetDescription"
      [title]="'ADMIN.CUSTOMER_SET_DESCRIPTION' | translate"
      [hidden]="true"
      [width]="180"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.enegiaDescription"
      [title]="'ADMIN.ENEGIA_DESCRIPTION' | translate"
      [hidden]="true"
      [width]="180"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.businessIdentityCodeId"
      [title]="'BUSINESS_IDENTITY_CODE' | translate"
      [hidden]="false"
      [width]="125"
      filter="numeric"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.externalIds.externalId"
      [title]="'EXTERNALIDS.EXTERNALID' | translate"
      [hidden]="true"
      [width]="125"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.externalIds.externalOrganizationId"
      [title]="'EXTERNALIDS.EXTERNALORGANIZATIONID' | translate"
      [hidden]="true"
      [width]="125"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.externalFacilityId"
      [title]="'FACILITYINFORMATION.EXTERNALFACILITYID' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.externalContractId"
      [title]="'FACILITYINFORMATION.EXTERNALCONTRACTID' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.reportingEndDate"
      format="d"
      [title]="'FACILITYINFORMATION.REPORTINGENDDATE' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.billingEndDate"
      format="d"
      [title]="'FACILITYINFORMATION.BILLINGENDDATE' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.powerOfAttorneyExpirationDate"
      format="d"
      [title]="'FACILITYINFORMATION.POWEROFATTORNEYEXPIRATIONDATE' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.createdBy"
      [title]="'CREATED_BY' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{
          dataItem.facility.createdBy === "UNKNOWN"
            ? ('QUANTITIES.UNKNOWN' | translate)
            : dataItem.facility.createdBy
        }}
      </ng-template>
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-multiselect-filter
          [filter]="filter"
          [field]="column.field"
          [filterService]="filterService"
          [dataSource]="facilities$ | async"
        >
          <span *gridMultiFilterTemplate="let value = value">
            {{
              value === "UNKNOWN"
                ? ('QUANTITIES.UNKNOWN' | translate)
                : value
            }}
          </span>
        </grid-multiselect-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="facility.created"
      format="g"
      [title]="'CREATED' | translate"
      [hidden]="true"
      [width]="115"
    ></kendo-grid-column>
    <kendo-grid-column
      field="facility.lastModifiedBy"
      [title]="'LAST_MODIFIED_BY' | translate"
      [hidden]="true"
      [width]="defaultColumnWidth"
    >
      <ng-template
        kendoGridFilterMenuTemplate
        let-column="column"
        let-filter="filter"
        let-filterService="filterService"
      >
        <grid-multiselect-filter
          [filter]="filter"
          [field]="column.field"
          [filterService]="filterService"
          [dataSource]="facilities$ | async"
        >
          <span *gridMultiFilterTemplate="let value = value">
            {{ value }}
          </span>
        </grid-multiselect-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="facility.lastModified"
      format="g"
      [title]="'LAST_MODIFIED' | translate"
      [hidden]="true"
      [width]="115"
    ></kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    [title]="'GROUPS.FUNCTIONS' | translate"
  >
    <kendo-grid-column
      [title]="'ADMIN.METER_SEARCH.LINKS_HEADING' | translate"
      [hidden]="false"
      [width]="80"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoButton
          [primary]="true"
          [fillMode]="'flat'"
          [size]="'small'"
          (click)="openFacilityReport(dataItem)"
        >
          FR
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showMeterCount"
    [title]="'GROUPS.METER_COUNT_TOTALS' | translate"
  >
    <kendo-grid-column
      *ngFor="let col of totalMeterCountColumns"
      [field]="col.field"
      [title]="col.title | translate"
      [width]="70"
      [hidden]="true"
      filter="numeric"
    >
      <span
        *kendoGridHeaderTemplate
        [title]="col.title | translate">
        {{ (col.title | translate) | abbreviate }}
      </span>
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-column="column">
        {{ dataItem | propertyPath:column?.field }}
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        {{ aggregates.totalMeter[col.field].sum }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showMeterCount"
    [title]="'GROUPS.MAINMETERCOUNTS' | translate"
  >
    <kendo-grid-column-group
      *ngFor="let group of MainMeterCountColumns"
      [title]="(group.title | translate)"
    >
      <kendo-grid-column
        *ngFor="let col of group.meterTypes"
        [field]="'meterCounts.mainMeterCounts.meterCountsByType.' + col.id + '.' + col.meterType"
        [title]="col.title | translate"
        [width]="70"
        [hidden]="true"
        filter="numeric"
      >
        <span
          *kendoGridHeaderTemplate
          [title]="col.title">
          {{ col.title | abbreviate }}
        </span>
        <ng-template kendoGridFooterTemplate>
          {{ aggregates.mainMeterCounts['meterCounts.mainMeterCounts.meterCountsByType.' + col.id + '.' +
          col.meterType].sum }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showMeterCount"
    [title]="'GROUPS.SUBMETERCOUNTS' | translate"
  >
    <kendo-grid-column-group
      *ngFor="let group of SubMeterCountColumns"
      [title]="(group.title | translate)"
    >
      <kendo-grid-column
        *ngFor="let col of group.meterTypes"
        [field]="'meterCounts.subMeterCounts.meterCountsByType.' + col.id + '.' + col.meterType"
        [title]="col.title | translate"
        [width]="70"
        [hidden]="true"
        filter="numeric"
      >
        <span
          *kendoGridHeaderTemplate
          [title]="col.title">
          {{ col.title | abbreviate }}
        </span>
        <ng-template kendoGridFooterTemplate>
          {{ aggregates.subMeterCounts['meterCounts.subMeterCounts.meterCountsByType.' + col.id + '.' +
          col.meterType].sum }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showMeterCount"
    [title]="'GROUPS.QUANTITIES_METER_COUNTS' | translate"
  >
    <kendo-grid-column
      *ngFor="let col of QuantityColumns"
      [field]="'meterCounts.meterCountsPerQuantity.' + col.id"
      [title]="col.title | translate"
      [width]="70"
      [hidden]="true"
      filter="numeric"
    >
      <span
        *kendoGridHeaderTemplate
        [title]="col.title | translate">
        {{ (col.title | translate) | abbreviate }}
      </span>
      <ng-template kendoGridFooterTemplate>
        {{ aggregates.quantityMeter['meterCounts.meterCountsPerQuantity.' + col.id].sum }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showContactInfo"
    [title]="'GROUPS.MAINTENANCECOMPANY' | translate"
  >
    <kendo-grid-column
      *ngFor="let column of facilityGridContactInfoColumns"
      [field]="'contactInfo.maintenanceCompany.maintenanceCompany' + column"
      [title]="('MAINTENANCECOMPANY.MAINTENANCECOMPANY' + column) | uppercase | translate"
      [width]="160"
      [hidden]="true"
    ></kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showContactInfo"
    [title]="'GROUPS.OWNERCOMPANY' | translate"
  >
    <kendo-grid-column
      *ngFor="let column of facilityGridContactInfoColumns"
      [field]="'contactInfo.ownerCompany.ownerCompany' + column"
      [title]="('OWNERCOMPANY.OWNERCOMPANY' + column) | uppercase | translate"
      [width]="160"
      [hidden]="true"
    ></kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showContactInfo"
    [title]="'GROUPS.FACILITYMANAGEMENTCOMPANY' | translate"
  >
    <kendo-grid-column
      *ngFor="let column of facilityGridContactInfoColumns"
      [field]="'contactInfo.facilityManagementCompany.facilityManagementCompany' + column"
      [title]="('FACILITYMANAGEMENTCOMPANY.FACILITYMANAGEMENTCOMPANY' + column) | uppercase | translate"
      [width]="160"
      [hidden]="true"
    ></kendo-grid-column>
  </kendo-grid-column-group>
  <kendo-grid-column-group
    *ngIf="showContactInfo"
    [title]="'GROUPS.MANAGERCOMPANY' | translate"
  >
    <kendo-grid-column
      *ngFor="let column of facilityGridContactInfoColumns"
      [field]="'contactInfo.managerCompany.managerCompany' + column"
      [title]="('MANAGERCOMPANY.MANAGERCOMPANY' + column) | uppercase | translate"
      [width]="160"
      [hidden]="true"
    ></kendo-grid-column>
  </kendo-grid-column-group>
</kendo-grid>
