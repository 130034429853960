<ng-template
  kendoGridGroupFooterTemplate
  let-group="group"
  let-column="column"
  let-aggregates
>
  <reporting-grid-column-footer
    [aggregates]="aggregates"
    [field]="column.field"
    [format]="format"
    [visibleAggregates]="['sum']"
    [modalReportType]="modalReportType"
  ></reporting-grid-column-footer>
</ng-template>
<ng-template kendoGridHeaderTemplate let-column="column">
  <report-change-column-header
    [color]="color"
    [comparisonColor]="comparisonColor"
    [quantityId]="quantityId"
    [title]="column.title"
    [changeKey]="changeKey"
  ></report-change-column-header>
</ng-template>
<ng-template kendoGridFooterTemplate let-column="column">
  <reporting-grid-column-footer
    [aggregates]="total"
    [field]="column.field"
    [format]="format"
    [visibleAggregates]="visibleAggregates"
    [modalReportType]="modalReportType"
  ></reporting-grid-column-footer>
</ng-template>
<ng-template
  kendoGridCellTemplate
  let-dataItem
  let-column="column"
>
  <ng-container *ngIf="dataItem | changeValueFlagAggregate:column.field:2 as flagAggregates">
    <value-flag-cell
      [consumption]="flagAggregates"
      [visibleValue]="dataItem | propertyPath:column.field"
      [format]="column.format"
    ></value-flag-cell>
  </ng-container>
</ng-template>
