import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'contract-editable-label',
  templateUrl: './contract-editable-label.component.html',
  styleUrls: ['./contract-editable-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractEditableLabelComponent {
  @Input() public disabled: boolean = false;
  @Output() public readonly open: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.open.emit();
  }
}
