import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionType } from '@enerkey/clients/attachments';

import { OverviewReportActionsCommentsBaseComponent } from '../overview-report-actions-comments-base.component';

@Component({
  selector: 'overview-report-comments',
  templateUrl: '../overview-report-actions-comments-base.component.html',
  styleUrls: ['../overview-report-actions-comments-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewReportCommentsComponent extends OverviewReportActionsCommentsBaseComponent {
  public readonly titleTranslationKey = 'FACILITIES_REPORT.OVERVIEW.COMMENTS';
  public readonly iconClass = 'icon-icon-notes-content';
  protected newActionType = ActionType.KE;
  protected actionsStateName = 'energy-management.comments';
}
