<modal-view-header headingText="{{ 'ADMIN.CONTRACTS.ADD_CONTRACTS' | translate }}">
</modal-view-header>
<div class="modal-body">
  <form
    #createForm="ngForm"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()"
  >
    <div class="grid-wrapper">
      <div>
        <label>{{ 'ADMIN.FACILITIES' | translate }}</label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('contractId')"
          [disabled]="isLoading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.CONTRACT_ID' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('productId')"
          [disabled]="isLoading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.PRODUCT_ID' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('unitCount')"
          [disabled]="isLoading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.UNIT_COUNT' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('unitPrice')"
          [disabled]="isLoading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.UNIT_PRICE' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('fromDate')"
          [disabled]="isLoading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.FROM_DATE' | translate }}
        </contract-editable-label>
      </div>
      <div>
        <contract-editable-label
          (open)="rowEdit('toDate')"
          [disabled]="isLoading$ | async"
        >
          {{ 'ADMIN.CONTRACTS.TO_DATE' | translate }}
        </contract-editable-label>
      </div>
      <div>
      </div>

      <ng-container
        *ngFor="let row of formArray.controls; let idx = index"
        formArrayName="rows"
      >
        <ng-container [formGroupName]="idx">
          <div>
            <kendo-multiselect
              formControlName="facilityIds"
              [data]="facilities"
              [textField]="'displayName'"
              [valueField]="'id'"
              [valuePrimitive]="true"
              [loading]="isLoading$ | async"
            ></kendo-multiselect>
          </div>
          <div>
            <input
              type="text"
              formControlName="contractId"
            >
          </div>
          <div>
            <product-input formControlName="productId"></product-input>
          </div>
          <div>
            <kendo-numerictextbox
              formControlName="unitCount"
              [decimals]="0"
              [format]="'0'"
              [step]="numberInputOpts.step"
              [autoCorrect]="numberInputOpts.autoCorrect"
              [spinners]="numberInputOpts.spinners"
            ></kendo-numerictextbox>
          </div>
          <div>
            <kendo-numerictextbox
              formControlName="unitPrice"
              [decimals]="numberInputOpts.decimals"
              [format]="numberInputOpts.format"
              [step]="numberInputOpts.step"
              [autoCorrect]="numberInputOpts.autoCorrect"
              [spinners]="numberInputOpts.spinners"
            ></kendo-numerictextbox>
          </div>
          <div>
            <kendo-datepicker formControlName="fromDate"></kendo-datepicker>
          </div>
          <div>
            <kendo-datepicker formControlName="toDate"></kendo-datepicker>
          </div>
          <div>
            <button
              type="button"
              class="button button--link button--negative icon"
              title="{{ 'ADMIN.CONTRACTS.REMOVE_ROW' | translate }}"
              (click)="removeRow(idx)"
              [disabled]="(isLoading$ | async) || (idx === 0 && formArray.length === 1)"
            >
              <i class="far fa-trash-alt"></i>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="modal-footer-buttons">
      <button
        type="button"
        class="button button--link"
        (click)="addRow()"
        [disabled]="(isLoading$ | async) || formArray.length >= 10"
      >
        <i class="fas fa-plus"></i>
        {{ 'ADMIN.CONTRACTS.INSERT_ROW' | translate }}
      </button>
      <div class="float-right">
        <button
          class="button button--primary"
          type="button"
          [disabled]="(isLoading$ | async) || !formGroup.valid"
          (click)="createForm.ngSubmit.emit()"
        >
          {{ 'ADD' | translate }}
        </button>
        <button
          class="button button--secondary"
          type="button"
          (click)="dismiss()"
        >
          {{ 'CANCEL' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
