import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColumnBase, GridComponent } from '@progress/kendo-angular-grid';

import { KendoGridService } from '../../services/kendo-grid.service';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  selector: 'kendo-grid-checkbox-header',
  templateUrl: './kendo-grid-checkbox-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KendoGridCheckboxHeaderComponent<T, K extends keyof T> implements OnInit {

  public state: boolean = false;

  public readonly gridHasData$: Observable<boolean>;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly grid: GridComponent,
    private readonly gridService: KendoGridService<T, K>,
    private readonly parentColumn: ColumnBase
  ) {
    merge(
      this.gridService.selection$,
      this.gridService.visibleData$
    ).subscribe(() => this.updateState());

    this.gridHasData$ = this.gridService.visibleData$.pipe(map(data => Array.hasItems(data)));
  }

  public ngOnInit(): void {
    type Styles = Record<string, string>;
    const clip = (arg: Styles): Styles => ({ ...arg, 'text-overflow': 'clip' });

    // HACK: Remove ellipsis from checkboxes
    this.parentColumn.style = clip(this.parentColumn.style);
    this.parentColumn.headerStyle = clip(this.parentColumn.headerStyle);
  }

  public selectAllChange(checked: boolean): void {
    this.gridService.selectAllChange(checked);
  }

  private updateState(): void {
    const newState = this.gridService.getSelectAllState();

    if (this.state !== newState) {
      this.state = newState;
      this.detectChanges();
    }
  }

  private detectChanges(): void {
    // This whole code could possibly be moved into a zone.onStable-callback if desync
    // between grid UI and data state happens
    this.changeDetector.detectChanges();

    const gridCd: ChangeDetectorRef = (this.grid as any).changeDetectorRef;
    gridCd.detectChanges();
  }
}
