import * as Configs from '../../constants/configs';
import { Quantity } from '../../shared/quantity';

const $inject = ['ManualQaApiService'];

/**
 * Service that handles manual qa quantity searches.
 *
 * @returns {Object}
 *
 * @constructor
 */
class ManualQaApiQuantityService {
  constructor(ManualQaApiService) {
    this.manualQaApiService = ManualQaApiService;
  }

  /**
   * Returns all quantities from backend
   *
   * @returns {Quantity[]}
   */
  getQuantities() {
    return this.manualQaApiService.doRequest({
      method: 'GET',
      url: `${Configs.API_URL}quantity`
    }).then(result => result.data.map(values => new Quantity(values)));
  }
}

ManualQaApiQuantityService.$inject = $inject;

export default ManualQaApiQuantityService;
