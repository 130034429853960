import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { AggregateDescriptor, GroupDescriptor } from '@progress/kendo-data-query';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import {
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';

import { Co2eq, RowMetadata, RowUnitType } from '@enerkey/clients/sustainability';
import { SimpleChangesOf } from '@enerkey/ts-utils';
import { anyOf, indicate, LoadingSubject } from '@enerkey/rxjs';
import { QuantityUnits, ReportingClient } from '@enerkey/clients/reporting';

import { getGriRowValuesFromMetadata, GRI_VALUE_FIELDS, GriEditorRow } from '../../models/gri-report-row';
import { GriFacilityCo2Factor, GriImportService, GriMeterItem } from '../../services/gri-import.service';
import { GriReportService } from '../../services/gri-report.service';

type MonthKey = typeof GRI_VALUE_FIELDS[number];

type RowMetaItem = {
  meter: GriMeterItem;
  included: boolean;
} & { [K in MonthKey]: number | null; };

@Component({
  selector: 'gri-metadata-grid',
  templateUrl: './gri-metadata-grid.component.html',
  styleUrls: ['./gri-metadata-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GriMetadataGridComponent implements OnChanges, OnInit, OnDestroy {

  public readonly Co2eq = Co2eq;
  public readonly valueFields = GRI_VALUE_FIELDS;
  public readonly numberFormat = '#,#.00';
  public grouping: GroupDescriptor[] = [{
    field: 'meter.facilityName',
    aggregates: GRI_VALUE_FIELDS.map<AggregateDescriptor>(field => ({ field, aggregate: 'sum' }))
  }];

  public readonly units$: Observable<QuantityUnits>;
  public readonly co2factors$: Observable<Record<number, GriFacilityCo2Factor[]>>;

  public formControls: Record<number, FormControl<GriFacilityCo2Factor>> = {};
  public sharedUnitControl = new FormControl<number>(
    null,
    Validators.required
  );

  @Input() public metadata: RowMetadata;
  @Input() public row: GriEditorRow | null;
  @Input() public quantityId: number | null;
  @Input() public loading: boolean;

  @Input() public isReadOnly: boolean;

  @Output() public readonly isValidChanged = new EventEmitter<boolean>();
  @Output() public readonly isGridDataSelected = new EventEmitter<boolean>();
  @Output() public readonly refreshed = new EventEmitter<void>();

  public meters: GriMeterItem[];

  public gridData: RowMetaItem[] = [];
  public aggregates: Record<number | 'total', Record<MonthKey, number>> = { total: emptyValues() };
  public availableFacilityCountryCodes: string[] = [];

  public readonly loading$: Observable<boolean>;

  public readonly rowUnitTypes = [RowUnitType.Location];

  private readonly _quantityId$ = new ReplaySubject<number>(1);
  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  @ViewChild(GridComponent)
  private readonly kendoGrid: GridComponent;

  private _statusChangeSubscription: Subscription;
  private _valueChangeSubscription: Subscription;

  private areControlsValid: boolean = false;

  public constructor(
    reportingClient: ReportingClient,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly importService: GriImportService,
    private readonly griService: GriReportService,
    private readonly zone: NgZone
  ) {
    this.loading$ = anyOf(this._loading$, importService.loading$, griService.loading$);
    this.units$ = reportingClient.getQuantityUnits().pipe(
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.co2factors$ = combineLatest({
      quantityId: this._quantityId$.pipe(filter(q => !!q)),
      factors: this.importService.co2factors$.pipe(indicate(this._loading$), takeUntil(this._destroy$)),
    }).pipe(
      map(({ quantityId, factors }) => factors
        .filter(f => f.quantityId === quantityId)
        .sortBy(f => f.from, 'desc')
        .toGroupsBy(f => f.facilityId)
        .toRecord()),
      takeUntil(this._destroy$)
    );
  }

  public ngOnChanges(changes: SimpleChangesOf<this>): void {
    if (changes.metadata) {
      this.refreshGridData();

      if (!this.isReadOnly) {
        this.initializeCo2FactorForms();
      }
    }

    if (changes.quantityId) {
      this._quantityId$.next(this.quantityId);
    }
  }

  public ngOnInit(): void {
    this.importService.allMeters$.pipe(takeUntil(this._destroy$)).subscribe(meters => {
      this.meters = meters;
      this.refreshGridData();
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._quantityId$.complete();
    this.refreshed.complete();
  }

  public rowCallback(context: RowClassArgs): Record<string, boolean> {
    return { 'excluded-row': !context.dataItem.included };
  }

  public refreshConsumptions(): void {
    if (!this.row?.metadata) {
      return;
    }

    const includedIds = Object.integerKeys(this.row.metadata.consumptions);

    forkJoin({
      report: this.griService.report$.pipe(filter(r => !!r), take(1)),
      meters: this.importService.allMeters$.pipe(
        take(1),
        map(meters => meters.filter(m => includedIds.includes(m.id)))
      ),
    }).pipe(
      switchMap(({ meters, report }) => this.importService.getMetadata(
        report.year,
        meters,
        meters.unique('quantityId')[0],
        this.row.metadata
      ).pipe(indicate(this._loading$))),
      takeUntil(this._destroy$)
    ).subscribe({
      next: result => {
        this.metadata = result;
        this.refreshGridData();
        this.row.metadata = this.getAsMetadata();
        const values = getGriRowValuesFromMetadata(this.row.metadata);

        values.forEach((value, index) => {
          this.row[GRI_VALUE_FIELDS[index]] = value;
        });

        this.refreshed.emit();
      },
    });
  }

  public getAsMetadata(): RowMetadata {
    return new RowMetadata({
      ...(this.metadata ?? {}),
      excludedMeters: this.gridData.filterMap(item => !item.included, item => item.meter.id),
      consumptions: this.gridData.toRecord(
        item => item.meter.id,
        item => GRI_VALUE_FIELDS.map(field => item[field])
      )
    });
  }

  public getAsMultipleMetadata(): { facilityId: number, facilityName: string, meta: RowMetadata }[] {
    return this.gridData
      .filter(data => data.included)
      .toGroupsBy(d => d.meter.facilityId)
      .getEntries()
      .map(([facilityId, rows]) => ({
        facilityId,
        facilityName: rows[0].meter.facilityName,
        meta: new RowMetadata({
          ...(this.metadata ?? {}),
          excludedMeters: rows.filterMap(item => !item.included, item => item.meter.id),
          consumptions: rows.toRecord(
            item => item.meter.id,
            item => GRI_VALUE_FIELDS.map(field => item[field])
          )
        })
      }));
  }

  public sharedUnitActuallyUsed(): boolean {
    if (this.hasEmptyCO2Factors()) {
      return true;
    }
    if (Object.integerKeys(this.formControls).length) {
      return !!this.getAsMultipleMetadata()
        .filter(f => this.formControls[f.facilityId]?.value?.from === null)
        .find(f => Object.integerKeys(f.meta.consumptions)
          .find(mId => !f.meta.excludedMeters.includes(mId)));
    }
    return true;
  }

  public hasEmptyCO2Factors(): boolean {
    return this.gridData.some(data => !this.formControls[data.meter.facilityId]);
  }

  public refreshGridData(): void {
    if (!this.metadata || !this.meters) {
      this.gridData = [];
      this.aggregates = { total: emptyValues() };
      this.changeDetector.markForCheck();
      return;
    }

    this.gridData = this.meters.joinInner(
      Object.integerEntries(this.metadata.consumptions),
      meter => meter.id,
      ([meterId]) => meterId,
      (meter, [meterId, consumptions]): RowMetaItem => ({
        meter,
        included: !this.metadata.excludedMeters?.includes(meterId),
        ...consumptions.reduce(
          ((obj, value, index) => ({ ...obj, [GRI_VALUE_FIELDS[index]]: value ?? null })),
          {} as { [K in MonthKey]: number | null; }
        )
      }) as RowMetaItem
    );

    this.availableFacilityCountryCodes = this.gridData.map(data => data.meter.facilityCountryCode).unique();

    this.refreshAggregates();

    if (!this.isReadOnly) {
      // onStable ensures grid is rendered before collapse
      this.zone.onStable.pipe(take(1)).subscribe(() => this.setGroupExpanded(false));
    }
  }

  public refreshAggregates(): void {
    this.aggregates = this.gridData.reduce((byFacility, row) => {
      // eslint-disable-next-line no-multi-assign
      const values = (byFacility[row.meter.facilityId] ??= emptyValues());

      if (row.included) {
        for (const field of GRI_VALUE_FIELDS) {
          values[field] += (row[field] ?? 0);
        }
      }

      return byFacility;

    }, {} as GriMetadataGridComponent['aggregates']);

    const total = emptyValues();

    for (const group of Object.values(this.aggregates)) {
      for (const field of GRI_VALUE_FIELDS) {
        total[field] += (group[field] ?? 0);
      }
    }

    this.aggregates.total = total;

    this.isValidChanged.emit(this.isValidToImport());

    this.changeDetector.markForCheck();
  }

  /* istanbul ignore next */
  public setGroupExpanded(expanded: boolean): void {
    const method = expanded ? 'expandGroup' : 'collapseGroup';

    const items = this.kendoGrid.data as { data: { items: unknown[] }[] };
    for (let x = 0; x < items.data.length; x++) {
      this.kendoGrid[method](x.toString());
    }

    this.changeDetector.detectChanges();
  }

  public sharedUnitToAll(): void {
    for (const fc of Object.values(this.formControls)) {
      fc.patchValue({ from: null } as GriFacilityCo2Factor);
    }
  }

  public get haveFormControls(): boolean {
    return Object.values(this.formControls).length === 0;
  }

  private get nonAllSharedUnit(): boolean {
    return Object.values(this.formControls).every(fc => fc.value?.from);
  }

  private initializeCo2FactorForms(): void {
    this.isValidChanged.next(false);

    // clear shareUnitControl completely on init
    this.sharedUnitControl.reset();
    this.sharedUnitControl.setValidators(Validators.required);
    this.sharedUnitControl.updateValueAndValidity();

    this.formControls = {};
    this._statusChangeSubscription?.unsubscribe();
    this._valueChangeSubscription?.unsubscribe();

    if (!Array.hasItems(this.meters)) {
      return;
    }

    const facilitiesWithData = this.gridData.unique(x => x.meter.facilityId);

    forkJoin({
      year: this.griService.report$.pipe(filter(r => !!r), take(1), map(r => r.year)),
      factors: this.co2factors$.pipe(take(1)),
    }).pipe(takeUntil(this._destroy$)).subscribe(({ year, factors }) => {
      const controls: AbstractControl[] = [];

      for (const [facilityId, facilityFactors] of Object.integerEntries(factors)) {
        if (Array.hasItems(facilityFactors) && facilitiesWithData.includes(facilityId)) {
          this.formControls[facilityId] = new FormControl(
            this.findActiveFactor(year, facilityFactors),
            Validators.required
          );
          controls.push(this.formControls[facilityId]);
        }
      }

      // for formcontrol of shareCo2Factor
      this._valueChangeSubscription = this.sharedUnitControl.valueChanges.pipe(
        takeUntil(this._destroy$)
      ).subscribe(() => {
        this.areControlsValid = [...controls, this.sharedUnitControl].every(c => c.valid);
        this.isValidChanged.emit(this.isValidToImport());
      });

      // for facility co2factors formcontrols
      this._statusChangeSubscription = combineLatest(
        controls.map(c => c.statusChanges.pipe(startWith(c.status)))
      ).subscribe(() => {

        if (this.nonAllSharedUnit) {
          this.sharedUnitControl.clearValidators();
        } else {
          this.sharedUnitControl.setValidators(Validators.required);
        }
        this.sharedUnitControl.updateValueAndValidity();

        this.areControlsValid = [...controls, this.sharedUnitControl].every(c => c.valid);
        this.isValidChanged.emit(this.isValidToImport());
      });

      this.changeDetector.detectChanges();
    });
  }

  private isValidToImport(): boolean {
    return this.areControlsValid && this.gridData.count(i => i.included) > 0 &&
    (this.sharedUnitActuallyUsed() ? !!this.sharedUnitControl.value : true);
  }

  private findActiveFactor(year: number, factors: GriFacilityCo2Factor[]): GriFacilityCo2Factor {
    const date = new Date(year, 0, 1);
    return factors.find(f => f?.from < date) ?? { from: null } as GriFacilityCo2Factor;
  }
}

function emptyValues(): Record<MonthKey, number> {
  return GRI_VALUE_FIELDS.toRecord(f => f, () => 0);
}
