import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const webhookTopbarState: UIStateNg2 = {
  name: 'webhook',
  data: {
    auth: {
      roles: [Roles.WEBHOOK_MANAGER]
    },
    translationKey: 'WEBHOOK.TITLE'
  }
};
