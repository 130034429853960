<div class="root-container">
  <div class="option-container">
    <h2>
      {{ 'SHOW' | translate }}
      &nbsp;<i
        class="fa fa-info-circle"
        [ngfTooltip]="'CHART_ZOOM_HELP' | translate"
        placement="right"
        [hidden]="showTotal$ | async"
      ></i>
    </h2>
    <hr>
    <form [formGroup]="scopeFormGroup">
      <p *ngFor="let scope of scopes">
        <label class="noselect">
          <input
            kendoCheckBox
            type="checkbox"
            [formControlName]="scope"
            [ngClass]="scope"
          >
          {{ scope | griScopeName }}
        </label>
      </p>
    </form>
    <p>
      <label class="noselect">
        <input
          kendoCheckBox
          type="checkbox"
          [formControl]="showTotalControl"
        >
        {{ 'SUSTAINABILITY.GRI.TOTAL' | translate }}
      </label>
    </p>
    <hr>
    <form [formGroup]="optionsFormGroup">
      <input
        type="radio"
        kendoRadioButton
        value="fullYear"
        formControlName="period"
        *labelWrap="'SUSTAINABILITY.GRI.CHART_OPTS.FULL_YEAR' | translate"
      >
      <input
        type="radio"
        kendoRadioButton
        value="monthly"
        formControlName="period"
        *labelWrap="'SUSTAINABILITY.GRI.CHART_OPTS.MONTHLY' | translate"
      >
      <hr>
      <input
        type="radio"
        kendoRadioButton
        value="scope"
        formControlName="grouping"
        *labelWrap="'SUSTAINABILITY.GRI.CHART_OPTS.BY_SCOPE' | translate"
      >
      <input
        type="radio"
        kendoRadioButton
        value="categoryName"
        formControlName="grouping"
        *labelWrap="'SUSTAINABILITY.GRI.CHART_OPTS.BY_CATEGORY' | translate"
      >
      <input
        type="radio"
        kendoRadioButton
        value="description"
        formControlName="grouping"
        *labelWrap="'SUSTAINABILITY.GRI.CHART_OPTS.BY_ROW' | translate"
      >
    </form>
    <hr>
    <button
      class="resetBtn"
      kendoButton
      [disabled]="!(chartZoomed$ | async)"
      (click)="resetZoom()"
    >
      {{ 'RESET' | translate }} zoom
    </button>
  </div>

  <kendo-pdf-export>
    <div class="chart-container">
      <gri-charts-totals-donut
        *ngIf="showTotal$ | async"
        [data]="rows$ | async"
        [title]="report.displayName"
      ></gri-charts-totals-donut>

      <kendo-chart
        *ngIf="!(showTotal$ | async)"
        [transitions]="false"
        [legend]="{ visible: false }"
        [pannable]="{ lock: 'y' }"
        [zoomable]="{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }"
        (zoomEnd)="zoomHandler($event)"
      >
        <kendo-chart-area [margin]="16">
        </kendo-chart-area>

        <kendo-chart-title [text]="report.displayName"></kendo-chart-title>
        <kendo-chart-value-axis>
          <kendo-chart-value-axis-item [title]="{ text: 'tCO2e' }"></kendo-chart-value-axis-item>
        </kendo-chart-value-axis>
        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item name="categoryAxis">
            <kendo-chart-category-axis-item-labels
              [position]="'start'"
              [rotation]="'auto'"
              [content]="catAxisLabels"
            ></kendo-chart-category-axis-item-labels>
          </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>

        <kendo-chart-series>
          <kendo-chart-series-item
            *ngIf="!(showMonthly$ | async) else monthly"
            [data]="rows$ | async"
            type="column"
            field="value"
            colorField="color"
            [categoryField]="grouping$ | async"
            [labels]="seriesLabels"
            aggregate="sum"
            [border]="{width: 0}"
          >
          </kendo-chart-series-item>
          <ng-template #monthly>
            <kendo-chart-series-item
              *ngFor="let item of monthly$ | async"
              [data]="item.items"
              type="column"
              field="value"
              colorField="color"
              categoryField="month"
              aggregate="sum"
              [name]="item.value"
              [border]="{width: 0}"
            >
              <kendo-chart-series-item-labels
                [content]="monthlySeriesLabels"
                position="insideBase"
                [rotation]="-90"
                background="transparent"
                color="var(--enerkey-black)"
                [padding]="{left: 5}"
              ></kendo-chart-series-item-labels>
            </kendo-chart-series-item>
          </ng-template>
        </kendo-chart-series>

        <kendo-chart-tooltip *ngIf="grouping$ | async as grouping">
          <table
            *kendoChartSeriesTooltipTemplate="let dataItem = dataItem"
            class="tooltip-table"
          >
            <tbody>
              <tr *ngIf="grouping === 'scope'">
                <td colspan="2">{{ dataItem.scope | griScopeName:true }}</td>
              </tr>
              <tr *ngIf="grouping === 'description'">
                <td>{{ 'SUSTAINABILITY.GRI.DESCRIPTION' | translate }}</td>
                <td>{{ dataItem.description }}</td>
              </tr>
              <tr *ngIf="grouping !== 'scope'">
                <td>{{ 'SUSTAINABILITY.GRI.CATEGORY' | translate }}</td>
                <td>{{ dataItem.categoryName }} ({{ dataItem.scope | griScopeName }})</td>
              </tr>
              <tr *ngIf="grouping === 'description'">
                <td>{{ 'SUSTAINABILITY.GRI.UNIT_NAME' | translate }}</td>
                <td>{{ dataItem.unit.name | translate }} ({{ dataItem.unit.unit }})</td>
              </tr>
              <tr>
                <td>tCO<sub>2</sub>e</td>
                <td>{{ dataItem.value | kendoNumber:'n0' }}</td>
              </tr>
            </tbody>
          </table>
        </kendo-chart-tooltip>

      </kendo-chart>

      <kendo-grid
        [kendoGridBinding]="gridData$ | async"
        [group]="gridGrouping"
      >
        <kendo-grid-column
          field="scope"
          [title]="'SUSTAINABILITY.GRI.SCOPE' | translate"
          [hidden]="!(showByScope$ | async)"
        >
          <ng-template
            kendoGridGroupHeaderTemplate
            let-group
            let-value="value"
          >
            <strong>{{ value }}</strong>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="category"
          [title]="'SUSTAINABILITY.GRI.CATEGORY' | translate"
          [hidden]="showByScope$ | async"
        ></kendo-grid-column>
        <kendo-grid-column
          field="value"
          [title]="'SUSTAINABILITY.GRI.TOTAL' | translate"
          [format]="valueFormat"
        >
          <ng-template
            kendoGridGroupHeaderColumnTemplate
            let-group="group"
            let-aggregates="aggregates"
          >
            <strong>{{ aggregates.value.sum | kendoNumber : valueFormat }}</strong>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </kendo-pdf-export>
</div>
