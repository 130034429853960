import AdminFacility from './admin-facility-component';
import AdminFacilitySpreadsheetService from './admin-facility-spreadsheet-service';
import AdminFacilitySpreadsheetSelectionService from './admin-facility-spreadsheet-selection-service';

import ServicesModule from '../../../../services';
import AdminServicesModule from '../../services';
import KendoModule from '../../../kendo';

export default angular.module('app.modules.admin.components.admin-facility', [
  ServicesModule.name,
  AdminServicesModule.name,
  KendoModule.name
])
  .factory('AdminFacilitySpreadsheetService', AdminFacilitySpreadsheetService)
  .factory('AdminFacilitySpreadsheetSelectionService', AdminFacilitySpreadsheetSelectionService)
  .component('adminFacility', AdminFacility)
;
