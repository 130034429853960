import { downgradeInjectable } from '@angular/upgrade/static';

import AdminSpreadsheetTranslationsService from './admin-spreadsheet-translations-service';
import { MeterSpreadsheetService } from './meter-spreadsheet-service';
import MeterSpreadsheetFieldsService from './meter-spreadsheet-fields-service';

import ServicesModules from '../../../services';
import ReportingObjectsServicesModule from '../../reportingobjects/services';
import ConfigurationModelsModule from '../../configuration/models';
import { FormulaEditorService } from '../../virtual-meters/services/formula-editor.service.ts';

export default angular.module('app.modules.admin.services', [
  ServicesModules.name,
  ReportingObjectsServicesModule.name,
  ConfigurationModelsModule.name
])
  .factory('AdminSpreadsheetTranslationsService', AdminSpreadsheetTranslationsService)
  .service('MeterSpreadsheetService', MeterSpreadsheetService)
  .factory('formulaEditorService', downgradeInjectable(FormulaEditorService))
  .service('MeterSpreadsheetFieldsService', MeterSpreadsheetFieldsService)
;
