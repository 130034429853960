<div
  class="container"
  #dropdownContainer
  (mouseenter)="hoverChange(true)"
  (mouseleave)="hoverChange(false)"
>
  <ng-container #toggleContainer></ng-container>
  <button
    *ngIf="!hasTemplateButton"
    class="button icon"
    [disabled]="disabled"
    (click)="!showOnHover && toggle()"
  >
    <i class="fa fa-ellipsis-h"></i>
  </button>
  <div
    *ngIf="isVisible && !disabled"
    class="ek-dropdown-menu"
    [style.width.px]="width"
    [style.margin-left.px]="margin"
  >
    <ng-content></ng-content>
  </div>
</div>
