import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { ModalBase } from '@enerkey/foundation-angular';
import { WindowService } from '../../../../shared/services/window.service';
import { NgfActiveModal } from '@enerkey/foundation-angular';

enum AddressTypes {
  'cc' = 1,
  'bcc' = 2
}

@Component({
  templateUrl: './user-mass-email-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMassEmailModalComponent extends ModalBase<void> {

  public emails: string[];
  public readonly massEmailForm: UntypedFormGroup;
  public readonly AddressTypes = AddressTypes;

  public constructor(
    private readonly windowService: WindowService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.massEmailForm = new UntypedFormGroup({
      addressTypeSelect: new UntypedFormControl(AddressTypes.cc)
    });
  }

  public submit(): void {
    this.windowService.navigate(this.generateMailLink());
    super.closeModal();
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private generateMailLink(): string {
    const addressTypeSelect = this.massEmailForm.value.addressTypeSelect;
    const emails = this.emails.join(';');
    const signature = encodeURIComponent(
      '\n\n\n' +
      'EnerKey Support \n' +
      'arkisin klo 9-15\n' +
      '+358 20 799 2101\n' +
      'support@enerkey.com'
    );
    let mailLink = '';
    switch (addressTypeSelect) {
      case AddressTypes.cc:
        mailLink = `mailto:${emails}?`;
        break;
      case AddressTypes.bcc:
        mailLink = `mailto:?bcc=${emails}&`;
        break;
    }
    mailLink += `body=${signature}`;

    return mailLink;
  }
}
