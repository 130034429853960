import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { formGroupFrom } from '@enerkey/ts-utils';

import { IProfileManagementSearchCriteria, ProfileManagementService } from '../../services/profile-management.service';
import { UserService } from '../../../../services/user-service';

@Component({
  selector: 'profile-management-search',
  templateUrl: './profile-management-search.component.html',
  styleUrls: ['./profile-management-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileManagementSearchComponent {

  public readonly searchForm: UntypedFormGroup;
  public readonly loading$: Observable<boolean>;

  public constructor(
    private readonly profileManagementService: ProfileManagementService,
    private readonly userService: UserService
  ) {
    this.loading$ = this.profileManagementService.loading$;
    this.searchForm = formGroupFrom<IProfileManagementSearchCriteria>({
      profileName: undefined,
      profileId: undefined,
      facilityId: undefined,
      enegiaId: undefined,
      serviceType: undefined,
    });
  }

  public search(): void {
    this.profileManagementService.search(this.searchForm.value);
  }

  public searchCurrentProfile(): void {
    this.profileManagementService.search({
      profileId: this.userService.profileId,
      enegiaId: null,
      facilityId: null,
      profileName: null,
      serviceType: null,
    });
  }
}
