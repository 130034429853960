import { Injectable, OnDestroy } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';

import { ReportingChildState } from '../reporting.states';
import { ReportType } from '../shared/report-type';
import { ReportTypeOptionsService } from './report-type-options.service';

const reportTypeByStateName: Record<ReportingChildState, ReportType> = {
  'reporting.table': ReportType.Table,
  'reporting.forecast': ReportType.Forecast,
  'reporting.trend': ReportType.Trend,
  'reporting.period': ReportType.Period,
  'reporting.sum': ReportType.Sum,
  'reporting.yearly-trend': ReportType.YearlyTrend,
};

@Injectable()
export class ReportTypeOptionsByStateService extends ReportTypeOptionsService implements OnDestroy {
  private readonly reportType$: BehaviorSubject<ReportType>;
  private readonly transitionUnbind: ReturnType<TransitionService['onSuccess']>;

  public constructor(
    transitionService: TransitionService,
    transition: Transition
  ) {
    const initialState = transition.to().name as ReportingChildState;
    const reportType$ = new BehaviorSubject(reportTypeByStateName[initialState]);

    super(reportType$);

    this.reportType$ = reportType$;
    this.transitionUnbind = transitionService.onEnter({ to: 'reporting.**' }, trans => {
      const reportType = reportTypeByStateName[trans.to().name as ReportingChildState];
      this.reportType$.next(reportType);
    });
  }

  public ngOnDestroy(): void {
    this.transitionUnbind?.();
    this.reportType$.complete();
  }
}
