import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, shareReplay, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { ReportSummary, Roadmap, Scope, SustainabilityClient } from '@enerkey/clients/sustainability';
import { getStringEnumValues } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ProfileService } from '../../../../shared/services/profile.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

type RoadmapStatusEditForm = {
  currentReportId: FormControl<number>;
  currentYear: FormControl<number>;
  currentValues: FormGroup;
}

@Component({
  selector: 'target-status-edit-modal',
  templateUrl: './target-status-edit-modal.component.html',
  styleUrls: ['./target-status-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  size: 'small'
})
export class TargetStatusEditModalComponent extends ModalBase implements OnDestroy, OnInit {

  public roadmap: Roadmap;
  public yearOptions: ComboItem<number>[];

  public readonly formGroup: FormGroup<RoadmapStatusEditForm>;
  public readonly valueOption: FormControl<'useReport' | 'manual'>;
  public readonly scopes = getStringEnumValues(Scope);

  public useReport$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly profileService: ProfileService,
    private readonly susClient: SustainabilityClient,
    private readonly toasterService: ToasterService
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();

    this.formGroup = new FormGroup({
      currentReportId: new FormControl(null),
      currentYear: new FormControl(new Date().getFullYear() - 1),
      currentValues: new FormGroup(
        this.scopes.reduce((a, scope) => ({ ...a, [scope]: new FormControl(null) }), {})
      )
    });

    this.valueOption = new FormControl('useReport');
  }

  public ngOnInit(): void {
    this.yearOptions = this.getYearOptions(this.roadmap);

    this.formGroup.setValue({
      currentReportId: this.roadmap.currentReportId ?? null,
      currentYear: this.roadmap.currentYear ?? new Date().getFullYear() - 1,
      currentValues: this.scopes.reduce((a, scope) => ({
        ...a,
        [scope]:
          Number.isFinite(this.roadmap.currentValues?.[scope])
            ? Math.round(this.roadmap.currentValues?.[scope])
            : null
      }), {}),
    });

    this.valueOption.patchValue(this.hasManualValues() ? 'manual' : 'useReport');

    this.useReport$ = this.valueOption.valueChanges.pipe(
      tap(option => {
        if (option === 'manual') {
          this.formGroup.patchValue({ currentReportId: null });
        }
      }),
      map(option => option === 'useReport'),
      startWith(!this.hasManualValues()),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public patchCurrentValues(report: ReportSummary): void {
    this.formGroup.patchValue({
      currentReportId: report.id,
      currentYear: report.year,
      currentValues: this.scopes.reduce((a, scope) => ({
        ...a,
        [scope]: Number.isFinite(report.emissions?.[scope])
          ? Math.round(report.emissions?.[scope])
          : null
      }), {})
    });
  }

  public saveStatus(): void {
    const data = { ...this.roadmap, ...this.formGroup.value };

    const request$ = this.profileService.profileId$.pipe(
      switchMap(id => this.susClient.updateRoadmaps(id, [data as Roadmap]))
    );

    request$.pipe(indicate(this._loading$)).subscribe({
      next: () => {
        this.toasterService.success('SUSTAINABILITY.TARGETS.STATUS_UPDATED');
        super.closeModal();
      },
      error: () => this.toasterService.generalError('UPDATE', 'TARGET')
    });
  }

  public cancel(): void {
    super.dismissModal();
  }

  private getYearOptions(roadmap: Roadmap): ComboItem<number>[] {
    const options: ComboItem<number>[] = [];
    for (let y = roadmap.baseYear; y <= roadmap.targetYear; y++) {
      options.push({ text: y.toString(), value: y });
    }
    return options;
  }

  private hasManualValues(): boolean {
    return !this.roadmap.currentReportId && Object.values(this.roadmap.currentValues).some(v => Number.isFinite(v));
  }

}
