import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit, RequestResolution } from '@enerkey/clients/reporting';

import { SelectableResolution } from '../../../modules/reporting/components/reporting-search-form/reporting-search-form.component';
import { QuantityService } from '../../services/quantity.service';
import { RelationalValueId } from '../../../modules/reportingobjects/constants/facilities-properties';

const extraDecimalResolutions = [RequestResolution.Pt1H, RequestResolution.Pt15M, RequestResolution.P1D];

const avgCostIds = [RelationalValueId.MeterBasedAverageCost,
  RelationalValueId.MeterBasedRetailerAverageCost,
  RelationalValueId.MeterBasedDistributionAverageCost];

@Pipe({ name: 'valueFormat' })
export class ValueFormatPipe implements PipeTransform {

  public static readonly DEFAULT_FORMAT = '#,#.0';
  public static readonly AVERAGE_COST_FORMAT = '#,#.0000';

  public constructor(
    private readonly quantityService: QuantityService
  ) { }

  public transform(
    quantityId: Quantities,
    unitKey: ReportingUnit = ReportingUnit.Default,
    resolution?: SelectableResolution,
    derivedId?: number
  ): Observable<string> {
    // e.g. temperature has no quantity ID
    if (derivedId || !quantityId) {
      return avgCostIds.includes(derivedId) ?
        of(ValueFormatPipe.AVERAGE_COST_FORMAT)
        :
        of(ValueFormatPipe.DEFAULT_FORMAT);
    }
    return this.quantityService.getQuantityDecimals(quantityId, unitKey).pipe(
      map(decimals => this.getFormat(extraDecimalResolutions.includes(resolution)
        ? decimals + 2
        : decimals))
    );
  }

  private getFormat(decimals: number): string {
    return decimals > 0
      ? `#,#.${'0'.repeat(decimals)}`
      : '#,#';
  }
}
