const $inject = ['utils'];

function ManualQaSearchListDeleteController(utils) {
  const vm = this;

  vm.$onInit = onInit;

  function onInit() {
    vm.searchList = angular.copy(vm.searchList);
    vm.headerText = utils.localizedString('MQA.SEARCH_LIST.DELETE.TITLE_DELETE_SEARCH_LIST');
    vm.confirmationText = utils.localizedString(
      'MQA.SEARCH_LIST.DELETE.DELETE_INFO_TEXT',
      { name: vm.searchList.name }
    );
  }
}

ManualQaSearchListDeleteController.$inject = $inject;

export default ManualQaSearchListDeleteController;
