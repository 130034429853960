import EnerkeyServicesModule from '../../../../services';
import ManualQaTabsServiceModule from '../../services/manual-qa-tabs-service';
import ManualQaIndexComponent from './manual-qa-index-component';

const dependencies = [EnerkeyServicesModule.name, ManualQaTabsServiceModule.name];

const ManualQaIndexModule = angular
  .module('app.modules.manual-qa.components.manual-qa-index', dependencies)
  .component('manualQaIndex', ManualQaIndexComponent)
;

export default ManualQaIndexModule;
