import _ from 'lodash';

import { hasChanges } from '@enerkey/angular-utils';

import TimeFrame from '../../../../services/time-frame-service';
import { momentToString } from '../../../../shared/date.functions';
import { getModelledConsumption } from '../../shared/utils.functions';
import VALUE_TYPES from '../../constants/value-types';
import { TrendModelType } from '@enerkey/clients/manual-qa';
import { isFetchTypeCumulative } from '../manual-qa-fetch-type/manual-qa-fetch-type.functions';
import { ACTIONS, SAVE_OPTION, SAVE_TYPE } from './actions';
import { CONSUMPTION_TYPE } from '../../constants/consumption-types';

const $inject = ['ManualQaActionsSelectService', 'utils'];

function ManualQaActionsSelectController(ManualQaActionsSelectService, utils) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this;

  vm.consumptionAmount = 0;
  vm.consumptionTypes = ManualQaActionsSelectService.getConsumptionTypes();
  vm.consumptionType = {};
  vm.actions = ManualQaActionsSelectService.getActions();
  vm.action = {};
  vm.modelType = TrendModelType.NearestNeighbour;
  vm.modelTypes = ManualQaActionsSelectService.getModelTypes();
  vm.modelTypeModes = ManualQaActionsSelectService.getModelTypeModes();
  vm.saveTypes = ManualQaActionsSelectService.getSaveTypes();
  vm.saveType = {};
  vm.saveOptions = ManualQaActionsSelectService.getSaveOptions();
  vm.saveOption = {};

  vm.$onInit = onInit;
  vm.$onChanges = onChanges;
  vm.getTemplate = getTemplate;
  vm.setInfoTooltipContent = setInfoTooltipContent;
  vm.onChangeAction = onChangeAction;
  vm.handlePreview = handlePreview;
  vm.handleSave = handleSave;
  vm.isButtonsDisabled = () => !vm.operationTimeFrame || isFetchTypeCumulative(vm.fetchType) || vm.isLoading;
  vm.isSaveTypeConsumption = isSaveTypeConsumption;
  vm.disableMoveToNextIssue = disableMoveToNextIssue;
  vm.disableSaveAsLocked = disableSaveAsLocked;
  vm.infoTooltipContent = '';

  function onInit() {
    vm.operationTimeFrame = angular.copy(vm.operationTimeFrame);
    vm.action = _.find(vm.actions, { id: ACTIONS.CALCULATED });
    vm.consumptionType = _.find(vm.consumptionTypes, { id: CONSUMPTION_TYPE.HOURLY });
    vm.saveType = _.find(vm.saveTypes, { id: SAVE_TYPE.MODEL });
    vm.saveOption = vm.saveOptions.reduce((result, current) => ({ ...result, [current.id]: current.defaultValue }), {});
    vm.setInfoTooltipContent();
  }

  /**
   * Handles changes from parent component
   *
   * @param {Object} changes
   */
  function onChanges(changes) {
    if (hasChanges(changes, 'operationTimeFrame')) {
      vm.operationTimeFrame = angular.copy(vm.operationTimeFrame);
      vm.setInfoTooltipContent();
    }
  }

  /**
   * Returns custom template for given action
   *
   * @param {Object} action
   *
   * @returns {String}
   */
  function getTemplate(action) {
    return action.template ? action.template : '';
  }

  /**
   * Returns content for info tooltip near heading.
   *
   * @param {Object} timeFrame
   *
   * @returns {String}
   */
  function setInfoTooltipContent() {
    if (!(vm.operationTimeFrame instanceof TimeFrame)) {
      vm.infoTooltipContent = '';
      return;
    }

    const params = {
      text: utils.localizedString('MQA.INSPECT.ACTIONS.SELECT_ACTION_TOOLTIP'),
      fromDate: momentToString(vm.operationTimeFrame.getFromDate()),
      toDate: momentToString(vm.operationTimeFrame.getToDate())
    };

    vm.infoTooltipContent = _.template('<%= text %>: <%= fromDate %> - <%= toDate %>')(params);
  }

  function handlePreview() {
    vm.timeFrame = vm.operationTimeFrame;
    const action = vm.action;
    if (action.id === ACTIONS.CALCULATED) {
      vm.getHourlyModel({ modelType: vm.modelType });
    } else {
      const values = getModelledConsumption(vm.consumptionAmount, vm.consumptionType.id, vm);
      vm.createPreview({ action, parameters: { values } });
    }
  }

  function handleSave() {
    const lock = vm.saveOption[SAVE_OPTION.LOCK] && !isSaveTypeConsumption();
    const valueType = vm.saveType.id === SAVE_TYPE.MODEL ? VALUE_TYPES.MODELLED : VALUE_TYPES.RAW_READING;
    const moveToNextDefectIssue = vm.saveOption[SAVE_OPTION.NEXT] && !vm.noDefect;
    vm.saveChanges({ lock, valueType, moveToNextDefectIssue });
  }

  function onChangeAction(action) {
    if (action.id === ACTIONS.MODEL) {
      vm.action.modelType = vm.modelTypes[0];
      vm.action.modelTypeMode = vm.modelTypeModes[0];
    }
  }

  function isSaveTypeConsumption() {
    return vm.saveType.id === SAVE_TYPE.CONSUMPTION;
  }

  function disableSaveAsLocked() {
    return !vm.isPreviewMode || isSaveTypeConsumption();
  }

  function disableMoveToNextIssue() {
    return !vm.isPreviewMode || vm.noDefect;
  }
}

ManualQaActionsSelectController.$inject = $inject;

export default ManualQaActionsSelectController;
