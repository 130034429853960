import { METER } from '../../constants/er-modal-states.constant';
import { ErReportTypeConfig, ErReportTypeGroupConfig } from '../../shared/er-report-type-config';
import ErReportSettingsService from '../../services/er-report-settings.service';
import { ServiceLevelService } from '../../../../shared/services/service-level.service';

const $inject = ['erReportSettingsService', 'serviceLevelService'];

class ErModalReportTypeController {
  public meterCount: number;
  public isMeterReport: boolean;
  public reports: (ErReportTypeConfig & { disabled?: boolean; selected?: boolean })[];
  public reportGroup: ErReportTypeGroupConfig;
  public typeChanged: any;
  public reportType: ErReportTypeConfig;
  public reportTypeName: string;

  public constructor(
    private erReportSettingsService: ErReportSettingsService,
    private serviceLevelService: ServiceLevelService
  ) {
  }

  public $onInit(): void {
    this.updateSelection();
    this.erReportSettingsService.getInstance('er-modal-report').setChangeReportType(this.typeChanged);
  }

  public updateSelection(): void {
    const meterReportsDisabled = this.meterCount === 0;
    this.isMeterReport = this.reportGroup.name === METER.name;
    this.reports = this.reportGroup.reports
      .filter(reportType => reportType.titleKey)
      .filter(reportType => this.serviceLevelService.hasAtLeastServiceLevel(reportType.requiresServiceLevel))
    ;
    this.reportType = this.reports.find(report => report.name === this.reportTypeName);
    this.reports.forEach(report => {
      report.selected = this.reportType && report.name === this.reportType.name;
    });
    if (this.isMeterReport) {
      this.reports.forEach(report => {
        report.disabled = !report.selected && meterReportsDisabled;
      });
    }
    this.erReportSettingsService.getInstance('er-modal-report').setReportTypes(this.reports);
  }

  public onClick(selectedType: ErReportTypeConfig): void {
    this.reports.forEach(report => {
      report.selected = report.name === selectedType.name;
    });
    this.typeChanged({ reportType: selectedType });
    this.reportTypeName = selectedType.name;
  }

  public $onChanges(): void {
    this.updateSelection();
  }
}

ErModalReportTypeController.$inject = $inject;

export default ErModalReportTypeController;
