import moment, { Moment } from 'moment';

import { Helpers } from '@enerkey/ts-utils';

import { TimeFrameOptions } from '../../../constants/time-frame';
import TimeFrame, { TimeFrameString } from '../../../services/time-frame-service';

export interface TimeFrameResult {
  Start?: StartValue[];
  TimeFrame?: TimeFrameString;
  Resolution?: TimeFrameString;
  Duration?: Duration;
}

export interface StartValue {
  value: string;
  key: string;
}

export class ErTimeFrameService {
  /**
   * Returns start query options based on  timeframe and comparison options
   * E.g
   *  timeFrameOption = 'YearFullQuarters',
   *  comparisonPeriodOption = 'Default'
   *
   * current time is 23.7.2016
   *
   * returns: {
   *   Start
   *     [1]: { value : '01.01.2015:00:00:00Z', key : 'Q1/15 - Q2/15 }
   *     [2]: { value : '01.01.2014:00:00:00Z', key : 'Q1/14 - Q2/14 }
   *    timeFrame = 'P6M';
   *   resolution = 'P3M';
   * }
   *
   * @param timeFrameOption - TimeFrameOptions
   * @param comparisonPeriodOption - values 'default' or ISO date string
   *
   * @return start array, time frame and resolution, values are in utc format regardless of local
   */
  public getTimeFrameAndResParams(timeFrameOption: TimeFrameOptions, comparisonPeriodOption: string): TimeFrameResult {
    const result: TimeFrameResult = { Start: [{ value: 'v1', key: 'k1' }] };

    const isComparison = comparisonPeriodOption !== 'None';
    const now = moment.utc();
    const isDefaultComparison = comparisonPeriodOption === 'Default';
    let comparisonYearStart;

    const currentMonthStart = moment.utc(now).startOf('month');
    const currentQuarterStart = moment.utc(now).startOf('quarter');
    const currentYearStart = moment.utc(now).startOf('year');
    if (isComparison) {
      result.Start.push({ value: 'v2', key: 'k2' });
      comparisonYearStart = isDefaultComparison ?
        moment.utc(currentYearStart).subtract(1, 'years') :
        moment.utc(comparisonPeriodOption);
    }
    switch (timeFrameOption) {
      case TimeFrameOptions.QUARTER_BY_QUARTER_MATCH_CURRENT:
        result.Start[0].value = currentQuarterStart.toISOString();
        if (isComparison) {
          result.Start[1].value = comparisonYearStart.add(currentQuarterStart.quarter() - 1, 'quarters').toISOString();
        }
        result.TimeFrame = 'P3M';
        result.Resolution = 'P1M';
        result.Duration = { months: 3 };
        break;
      case TimeFrameOptions.QUARTER_BY_QUARTER_MATCH_PREVIOUS:
        result.Start[0].value = currentQuarterStart.subtract(1, 'quarters').toISOString();
        if (isComparison) {
          result.Start[1].value = isDefaultComparison ?
            currentQuarterStart.subtract(1, 'years').toISOString() :
            comparisonYearStart.add(currentQuarterStart.quarter() - 1, 'quarters').toISOString();
        }
        result.TimeFrame = 'P3M';
        result.Resolution = 'P1M';
        result.Duration = { months: 3 };
        break;
      case TimeFrameOptions.QUARTER_BY_QUARTER_LAST_CURRENT:
        result.Start[0].value = currentQuarterStart.toISOString();
        if (isComparison) {
          result.Start[1].value = currentQuarterStart.subtract(1, 'quarters').toISOString();
        }
        result.TimeFrame = 'P3M';
        result.Resolution = 'P1M';
        result.Duration = { months: 3 };
        break;
      case TimeFrameOptions.QUARTER_BY_QUARTER_LAST_PREVIOUS:
        result.Start[0].value = currentQuarterStart.subtract(1, 'quarters').toISOString();
        if (isComparison) {
          result.Start[1].value = currentQuarterStart.subtract(1, 'quarters').toISOString();
        }
        result.TimeFrame = 'P3M';
        result.Resolution = 'P1M';
        result.Duration = { months: 3 };
        break;
      case TimeFrameOptions.MONTH_BY_MONTH_MATCH_CURRENT:
        result.Start[0].value = currentMonthStart.toISOString();
        if (isComparison) {
          result.Start[1].value = isDefaultComparison ?
            currentMonthStart.subtract(1, 'years').toISOString() :
            result.Start[1].value = comparisonYearStart.add(currentMonthStart.month(), 'months').toISOString();
        }
        result.TimeFrame = 'P1M';
        result.Resolution = 'P1M';
        result.Duration = { months: 1 };
        break;
      case TimeFrameOptions.MONTH_BY_MONTH_MATCH_PREVIOUS:
        result.Start[0].value = currentMonthStart.subtract(1, 'months').toISOString();
        if (isComparison) {
          result.Start[1].value = isDefaultComparison ?
            currentMonthStart.subtract(1, 'years').toISOString() :
            result.Start[1].value = comparisonYearStart.add(currentMonthStart.month(), 'months').toISOString();
        }
        result.TimeFrame = 'P1M';
        result.Resolution = 'P1M';
        result.Duration = { months: 1 };
        break;
      case TimeFrameOptions.MONTH_BY_MONTH_LAST_CURRENT:
        result.Start[0].value = currentMonthStart.toISOString();
        if (isComparison) {
          result.Start[1].value = currentMonthStart.subtract(1, 'months').toISOString();
        }
        result.TimeFrame = 'P1M';
        result.Resolution = 'P1M';
        result.Duration = { months: 1 };
        break;
      case TimeFrameOptions.MONTH_BY_MONTH_LAST_PREVIOUS:
        result.Start[0].value = currentMonthStart.subtract(1, 'months').toISOString();
        if (isComparison) {
          result.Start[1].value = currentMonthStart.subtract(1, 'months').toISOString();
        }
        result.TimeFrame = 'P1M';
        result.Resolution = 'P1M';
        result.Duration = { months: 1 };
        break;
      case TimeFrameOptions.YEAR_FULL_QUARTERS:
        if (currentQuarterStart.quarter() === 1) {
          currentYearStart.subtract(1, 'years');
          result.TimeFrame = 'P1Y';
          result.Duration = { years: 1 };
          if (isComparison && isDefaultComparison) {
            comparisonYearStart.subtract(1, 'years');
          }
        } else {
          const months = (now.quarter() - 1) * 3;
          result.TimeFrame = `P${months}M` as TimeFrameString;
          result.Duration = { months: months };
        }
        result.Start[0].value = currentYearStart.toISOString();
        if (isComparison) {
          result.Start[1].value = comparisonYearStart.toISOString();
        }
        result.Resolution = 'P3M';
        break;
      case TimeFrameOptions.YEAR_BY_YEAR_FLOATING:
        if (now.date() < 5) {
          currentMonthStart.subtract(1, 'months');
        }
        result.Start[0].value = currentMonthStart.subtract(12, 'months').toISOString();
        if (isComparison) {
          result.Start[1].value = comparisonYearStart.subtract(12 - currentMonthStart.month(), 'months').toISOString();
        }
        result.TimeFrame = 'P1Y';
        result.Duration = { years: 1 };
        result.Resolution = 'P1M';
        break;
      case TimeFrameOptions.YEAR_BY_YEAR_CALENDAR:
        if (now.dayOfYear() < 37) {
          currentYearStart.subtract(1, 'years');
          result.TimeFrame = 'P1Y';
          result.Duration = { years: 1 };
          if (isComparison && isDefaultComparison) {
            comparisonYearStart.subtract(1, 'years');
          }
        } else {
          result.TimeFrame = 'P1Y';
          result.Duration = { years: 1 };
        }
        result.Start[0].value = currentYearStart.toISOString();
        if (isComparison) {
          result.Start[1].value = comparisonYearStart.toISOString();
        }
        result.Resolution = 'P1M';
        break;
      default:
        Helpers.assertUnreachable(timeFrameOption);
    }

    result.Start.forEach(item => {
      item.key = TimeFrame.timeRangeFormat(item.value, result.TimeFrame, result.Resolution);
    });

    return result;
  }

  /**
   * Returns start query options based on resolution and from - to parameters.
   * from - to period is sliced to Start array items by resolution
   * E.g
   *  resolution = 'P1M',
   *  from = '01.01.2019T00:00:00.000Z'
   *  to = '01.04.2020T00:00:00.000Z'
   *
   * returns: {
   *   Start
   *     [1]: { value : '01.01.2019:00:00:00Z', key : '1/19' }
   *     [2]: { value : '01.01.2019:00:00:00Z', key : '2/19' }
   *     [1]: { value : '01.01.2019:00:00:00Z', key : '2/19' }
   *    TimeFrame = 'P1M';
   *    Resolution = 'P1M';
   * }
   * In the case where the from-to division by resolution results non-interger value and resolution is not year based,
   * the division return is ceiled up i.e. returning additional Start array item. In the case of year the the value
   * is rounded (in order to avoid leap year effect).
   *
   * @param resolution - ISO abbrevation e.g. P1M
   * @param from - from date in ISO string format
   * @param to - to date in ISO string format
   *
   * @return start array, time frame and resolution, values are in utc format regardless of local
   */
  public getStartFromResolution(resolution: TimeFrameString, from: Moment, to: Moment): TimeFrameResult {
    const result: TimeFrameResult = {};

    result.Start = [];
    const duration = moment.duration(to.diff(from));
    const unit = resolution.substr(-1);
    const unitMultiplier = parseInt(resolution.substr(1, resolution.length - 1));
    const unitParamsArray = [
      { ISOChar: 'D', unitAdd: 'Days', func: duration.asDays() },
      { ISOChar: 'M', unitAdd: 'Months', func: duration.asMonths() },
      { ISOChar: 'Y', unitAdd: 'Years', func: duration.asYears() }
    ];
    const unitParams = unitParamsArray.find(x => x.ISOChar === unit);
    const startDate = from.clone();

    let value = '';
    let wasWinter = false;

    const iterations = unitParams.ISOChar === 'D' ?
      Math.ceil(unitParams.func / unitMultiplier) :
      Math.round(unitParams.func / unitMultiplier);

    for (let i = 1; i <= iterations; i++) {
      value = startDate.toISOString();
      result.Start.push({
        key: TimeFrame.timeRangeFormat(value, resolution, resolution),
        value: value
      });
      wasWinter = !startDate.isDST();
      startDate.add(unitMultiplier, unitParams.unitAdd);
      this.handleDST(startDate, wasWinter);
    }
    result.TimeFrame = `P${unitMultiplier}${unit}` as TimeFrameString;
    result.Resolution = result.TimeFrame;

    return result;
  }

  private handleDST(startDate: moment.Moment, wasWinter: boolean): void {
    if (startDate.isDST() && wasWinter) {
      startDate.add(1, 'hours');
    } else if (!startDate.isDST() && !wasWinter) {
      startDate.subtract(1, 'hours');
    }
  }

}

export default ErTimeFrameService;
