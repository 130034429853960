<div class="row">
  <div class="small-6 columns">
    <ng-container *ngIf="enegiaId > 0">
      <b translate="ADMIN.TERMINAL.FACILITY_INFORMATION"></b>
      <input
        *labelBefore="'ADMIN.TERMINAL.ENEGIA_ID' | translate"
        kendoTextBox
        type="number"
        [readonly]="true"
        [ngModel]="enegiaId"
      >
    </ng-container>
    <b translate="ADMIN.TERMINAL.TERMINAL_INFORMATION"></b>
  </div>
</div>
<div class="row">
  <div class="small-6 columns">
    <input
      *labelBefore="'ADMIN.TERMINAL.IDENTIFIER' | translate"
      kendoTextBox
      type="number"
      [(ngModel)]="terminal.identifier"
    >
    <input
      *labelBefore="('ADMIN.TERMINAL_NAME' | translate) + '*'"
      kendoTextBox
      type="text"
      [(ngModel)]="terminal.name"
    >
    <textarea
      *labelBefore="'ADMIN.TERMINAL.DESCRIPTION' | translate"
      kendoTextArea
      type="text"
      [(ngModel)]="terminal.description"
      [attr.rows]="10"
    ></textarea>
    <textarea
      *labelBefore="'ADMIN.TERMINAL.CONNECTION_INFORMATION' | translate"
      kendoTextArea
      type="text"
      [(ngModel)]="terminal.connectionInformation"
      [attr.rows]="5"
    ></textarea>
  </div>
  <div class="small-6 columns">
    <input
      *labelBefore="'ADMIN.TERMINAL.USERNAME' | translate"
      kendoTextBox
      type="text"
      [(ngModel)]="terminal.userName"
    >
    <input
      *labelBefore="'ADMIN.TERMINAL.PASSWORD' | translate"
      kendoTextBox
      type="text"
      [(ngModel)]="terminal.password"
    >
    <textarea
      *labelBefore="'COMMENT' | translate"
      kendoTextArea
      type="text"
      [(ngModel)]="terminal.comment"
      [attr.rows]="10"
    ></textarea>

    <textarea
      *labelBefore="'ADMIN.TERMINAL.METERS' | translate"
      kendoTextArea
      type="text"
      [readonly]="true"
      [ngModel]="terminal | terminalMeterIds"
      rows="10"
    ></textarea>
  </div>
</div>
