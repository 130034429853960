import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {
  public constructor(
    private readonly oauthService: OAuthService,
    private readonly languageService: LanguageService
  ) {
  }

  public logout(): void {
    const tenant = window.location.hostname.split('.', 1)[0];

    this.oauthService.logOut({
      state: this.languageService.getLocale(),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      acr_values: `tenant:${tenant}`
    });
  }
}
