import { ISchedulerClient } from './scheduler-client';

export class SchedulerClientMock implements ISchedulerClient {
  public execute(): never {
    throw new Error('Method not implemented.');
  }
  public getBuckets(): never {
    throw new Error('Method not implemented.');
  }
  public postBucket(): never {
    throw new Error('Method not implemented.');
  }
  public putBucket(): never {
    throw new Error('Method not implemented.');
  }
  public deleteBucket(): never {
    throw new Error('Method not implemented.');
  }
  public getSingleBucket(): never {
    throw new Error('Method not implemented.');
  }
  public getBucketDocumentations(): never {
    throw new Error('Method not implemented.');
  }
  public postBucketDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public putBucketDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public deleteBucketDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public getSingleBucketDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public getDocumentationForBucket(): never {
    throw new Error('Method not implemented.');
  }
  public getConfigurations(): never {
    throw new Error('Method not implemented.');
  }
  public postConfiguration(): never {
    throw new Error('Method not implemented.');
  }
  public putConfiguration(): never {
    throw new Error('Method not implemented.');
  }
  public deleteConfiguration(): never {
    throw new Error('Method not implemented.');
  }
  public getConfiguration(): never {
    throw new Error('Method not implemented.');
  }
  public getConfigurationsByBucket(): never {
    throw new Error('Method not implemented.');
  }
  public getStatusLogs(): never {
    throw new Error('Method not implemented.');
  }
  public postStatusLogEntry(): never {
    throw new Error('Method not implemented.');
  }
  public putStatusLogEntry(): never {
    throw new Error('Method not implemented.');
  }
  public deleteStatusLogEntry(): never {
    throw new Error('Method not implemented.');
  }
  public getStatusLogForConfiguration(): never {
    throw new Error('Method not implemented.');
  }
  public getStatusLogByConfigurationId(): never {
    throw new Error('Method not implemented.');
  }
  public getToolTypes(): never {
    throw new Error('Method not implemented.');
  }
  public postToolType(): never {
    throw new Error('Method not implemented.');
  }
  public putToolType(): never {
    throw new Error('Method not implemented.');
  }
  public deleteToolType(): never {
    throw new Error('Method not implemented.');
  }
  public getSingleToolType(): never {
    throw new Error('Method not implemented.');
  }
  public getToolTypeDocumentations(): never {
    throw new Error('Method not implemented.');
  }
  public postToolTypeDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public putToolTypeDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public deleteToolTypeDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public getSingleToolTypeDocumentation(): never {
    throw new Error('Method not implemented.');
  }
  public getDocumentationForToolType(): never {
    throw new Error('Method not implemented.');
  }
}
