<ng-template
  kendoGridFilterMenuTemplate
  let-column="column"
  let-filter="filter"
  let-filterService="filterService"
>
  <kendo-grid-string-filter-menu
    [column]="column"
    [filter]="filter"
    [filterService]="filterService"
    [extra]="false"
    [operator]="'contains'"
  >
    <kendo-filter-contains-operator></kendo-filter-contains-operator>
    <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
    <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
    <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
  </kendo-grid-string-filter-menu>
</ng-template>

<ng-template
  kendoGridCellTemplate
  let-dataItem
>
  <a
    title="{{dataItem.FacilityInformation.RealEstateId}} - {{dataItem.Name}} - {{dataItem.FacilityInformation.EnegiaId}}"
    (click)="openModal(dataItem)"
  >
    {{ dataItem.Name }}
  </a>
</ng-template>

<ng-template
  kendoGridGroupFooterTemplate
  let-column="column"
>
  <div>{{ 'FACILITIES_REPORT.SUM' | translate }}</div>
</ng-template>

<ng-template
  kendoGridFooterTemplate
  let-column="column"
>
  <div>{{ 'FACILITIES_REPORT.SUM' | translate }}</div>
  <div>{{ 'FACILITIES_REPORT.MIN' | translate }}</div>
  <div>{{ 'FACILITIES_REPORT.MAX' | translate }}</div>
  <div>{{ 'FACILITIES_REPORT.AVERAGE' | translate }}</div>
</ng-template>
