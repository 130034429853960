import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { LOADING_SUBJECT } from '../services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  public constructor(
    @Inject(LOADING_SUBJECT) private readonly loading$: LoadingSubject
  ) { }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(indicate(this.loading$));
  }
}
