import moment from 'moment';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttachmentType } from '@enerkey/clients/attachments';

import { ExtendedFacilityInformation } from '../../../interfaces/extended-facility-information';

@Injectable({
  providedIn: 'root'
})
export class FacilitiesPopupDocumentsService {
  public constructor(
    @Inject('documents') private readonly documents: any
  ) {
  }

  public getMeterDocuments(meterId: number, facilityId: number): Observable<any[]> {
    return this.documents.getDocuments({
      reportingObjectId: facilityId, // TODO: drop this once getDocuments obeys meterIds
      meterIds: [meterId],
      attachmentType: AttachmentType.Meter
    })
      .pipe(map((docs: any[]) => this.preProcessMeterDocuments(docs, meterId)));
  }

  public getFacilityDocuments(facilityId: number): Observable<any[]> {
    return this.documents.getDocuments({
      attachmentType: AttachmentType.ReportingObject,
      reportingObjectId: facilityId
    }).pipe(map((docs: any) => this.preProcessFacilityDocuments(docs)));
  }

  public getDocumentsStateParams(
    facility: ExtendedFacilityInformation,
    meter?: { Id: number }
  ): { facilityName: string, meterId: number } {
    return {
      facilityName: facility.Name,
      meterId: meter?.Id
    };
  }

  public getDocumentsEditStateParams(
    facility: ExtendedFacilityInformation,
    meter?: { Id: number; Name: string }
  ): { facilityId: number; attachmentType: AttachmentType; meter?: { id: number; name: string }; } {
    return meter
      ? {
        facilityId: facility.FacilityId,
        meter: { id: meter.Id, name: meter.Name },
        attachmentType: AttachmentType.Meter,
      }
      : {
        facilityId: facility.FacilityId,
        attachmentType: AttachmentType.ReportingObject,
      };
  }

  private preProcessFacilityDocuments(docs: any[]): any[] {
    docs = this.preProcessDocuments(docs, (doc: any) => doc.AttachmentType === AttachmentType.ReportingObject);
    docs.forEach(doc => {
      doc.updatedAt = moment(doc.updatedAt).format('D.M.YYYY');
    });
    return docs;
  }

  private preProcessMeterDocuments(docs: any[], meterId: number): any[] {
    docs = this.preProcessDocuments(docs,
      (doc: any) => doc.AttachmentType === AttachmentType.Meter && doc.meterIds.includes(meterId));
    docs.forEach(doc => {
      doc.updatedAt = moment(doc.updatedAt).format('D.M.YYYY');
    });
    return docs;
  }

  private preProcessDocuments(docs: any[], filterFn: (value: any) => boolean): any[] {
    docs = docs
      .filter(filterFn)
      .sort((a, b) => b.updatedAt - a.updatedAt)
      .slice(0, 5);
    return docs;
  }
}
