import { Component } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { NgfActiveModal } from '@enerkey/foundation-angular';
import { AlarmLogGridItem } from '../../shared/alarm-log-grid-item';
import { ModalBase } from '@enerkey/foundation-angular';
import { AlarmLogGridItemService } from '../../services/alarm-log-grid-item.service';
import { prepare } from '@enerkey/rxjs';

@Component({
  selector: 'alarm-details-modal',
  templateUrl: './alarm-details-modal.component.html',
})
export class AlarmDetailsModalComponent extends ModalBase {
  public alarm: AlarmLogGridItem;
  public loading: boolean = false;

  public constructor(
    activeModal: NgfActiveModal,
    private readonly alarmLogItemService: AlarmLogGridItemService
  ) {
    super(activeModal);
  }

  /** Used to load alarm details if not already available (ex: modal opened from ER and not grid). */
  public getAlarmByLogId(logId: number): void {
    this.loading = true;

    this.alarmLogItemService.getItemById(logId).pipe(
      prepare(() => (this.loading = true)),
      finalize(() => (this.loading = false))
    ).subscribe(value => {
      this.alarm = value;
    });
  }
}
