<div class="card facilities-card">
  <div class="header-group bg-enerkey-default">
    <h3 class="header-group__label h-secondary">
      {{ 'FACILITIES.FACILITIES' | translate }} ({{ facilities.length }})
    </h3>
  </div>
  <div class="facilities-grid">
    <a
      *ngFor="let facility of facilities"
      (click)="openModal(facility.FacilityId)"
    >
      {{ facility.Name }}
    </a>
  </div>
</div>
