import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { groupBy } from '@progress/kendo-data-query';

import { indicate } from '@enerkey/rxjs';
import { RoleGroups } from '@enerkey/clients/user-management';

import { EkMultiComponent } from '../ek-multi/ek-multi.component';
import { RoleService } from '../../../modules/admin/services/role.service';

const roleGroupTranslations: Record<RoleGroups, string> = {
  [RoleGroups.AdditionalServices]: 'ADMIN.ROLEGROUPS.ADDITIONALSERVICES',
  [RoleGroups.MainUserRoles]: 'ADMIN.ROLEGROUPS.MAINUSERROLES',
  [RoleGroups.UserManagement]: 'ADMIN.ROLEGROUPS.USERMANAGEMENT',
  [RoleGroups.MeterManagement]: 'ADMIN.ROLEGROUPS.METERMANAGEMENT',
  [RoleGroups.NewsManagement]: 'ADMIN.ROLEGROUPS.NEWSMANAGEMENT',
  [RoleGroups.Internal]: 'ADMIN.ROLEGROUPS.INTERNAL',
  [RoleGroups.InternalQualityManagement]: 'ADMIN.ROLEGROUPS.INTERNALQUALITYMANAGEMENT',
  [RoleGroups.InternalTenantManagement]: 'ADMIN.ROLEGROUPS.INTERNALTENANTMANAGEMENT',
  [RoleGroups.Misc]: 'ADMIN.ROLEGROUPS.MISC',
};

@Component({
  selector: 'role-multi-select',
  templateUrl: '../multi-select/multi-select.component.html',
  styleUrls: ['../multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RoleMultiSelectComponent,
      multi: true
    }
  ]
})
export class RoleMultiSelectComponent extends EkMultiComponent implements AfterViewInit {
  @Input() public override filterable = true;
  public override overflowThreshold = 15;
  public override readonly placeholderKey = 'MULTISELECT.NOTHING_SELECTED';

  public constructor(
    roleService: RoleService,
    changeDetectorRef: ChangeDetectorRef,
    translateService: TranslateService
  ) {
    super(changeDetectorRef);

    roleService.getRoles().pipe(
      indicate(this._loading)
    ).subscribe(roles => {
      this.items = roles.map(role => ({
        value: role.id,
        text: translateService.instant(`ADMIN.ROLE_NAME_${role.name.toUpperCase()}`),
        group: translateService.instant(roleGroupTranslations[role.roleGroup])
      })).sortByMany(
        'group',
        'text'
      );
      this.groupedItems = groupBy(this.items, [{ field: 'group', dir: 'desc' }]);
    });
  }

  public ngAfterViewInit(): void {
    this.multiSelect.filterChange.subscribe(value => {
      const filtered = this.items.filter(item => item.text.toLowerCase().includes(value.toLowerCase()));
      this.groupedItems = groupBy(filtered, [{ field: 'group', dir: 'desc' }]);
    });
  }
}
