import './array';
import './string';
import './map';
import './object';
import './promise';
import './moment';

/**
 * Used in Array sort functions.
 */
export type SortOrder = 'ascending' | 'asc' | 'descending' | 'desc';
