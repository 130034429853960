import LinkUnlinkController from './link-unlink-controller';
import template             from 'raw-loader!./link-unlink.html';

const LinkUnlinkComponent = {
  template: template,
  controller: LinkUnlinkController,
  controllerAs: 'vm',
  bindings: {
    isLinked: '<',
    onChange: '&'
  }
};

export default LinkUnlinkComponent;
