import { FacilityPropertyEnums, IFacility } from '@enerkey/clients/facility';
import { OperationalHours, Resolution } from '@enerkey/clients/ines';
import { Quantities } from '@enerkey/clients/metering';

import { ISODuration } from '../../../shared/isoduration';

export type Point = {
  readonly temperature: number;
  readonly value: number;
}

export type UnitInfo = {
  readonly name: string;
  readonly unit: string;
  readonly decimals: number;
}

export type ETCurveLine = {
  readonly name: string;
  readonly color?: string;
  readonly width?: number;
  readonly points: Point[];
}

export type ETCurvePoint = Point & { readonly timestamp: Date };

export interface EtCurveModel {
  readonly facility?: IFacility;
  readonly quantity?: Quantities;
  readonly specificId?: FacilityPropertyEnums | null;

  readonly range?: { start: Date; end: Date };
  readonly period?: ISODuration;
  readonly resolution?: ISODuration;

  readonly valueUnit?: UnitInfo;
  readonly temperatureUnit?: UnitInfo;

  readonly showFrom?: Date | null;
  readonly showTo?: Date | null;
  readonly calculationTimeFrom?: Date | null;
  readonly calculationTimeTo?: Date | null;

  readonly getCons?: boolean;
  readonly curves: ETCurveLine[];
  readonly values: ETCurvePoint[];
  readonly deviation: number | null;
}

export enum EnergyMeasurementType {
  SPECIFIC_ENERGY = 'specificenergy',
  ENERGY_MEASUREMENT = 'energymeasurement',
}

export enum DeviationType {
  PERCENT,
  KWH_M2
}

export enum EtCurveDisplayType {
  CALCULATED,
  SAVED
}

export enum PeriodDisplayType {
  PREDEFINED,
  CUSTOM
}

export type ETCurveSearchParams = {
  readonly facilityId: number;
  readonly quantity: Quantities;
  readonly specificId: number | undefined;
  readonly specificValue: number | undefined;
  readonly period: ISODuration;
  readonly resolution: ISODuration;
  readonly startDate: Date;
  readonly filterWeekend: boolean;
  readonly showFrom: Date;
  readonly showTo: Date;
  readonly showTimeFrom: Date;
  readonly showTimeFromMin: Date;
  readonly showTimeTo: Date;
  readonly showTimeToMax: Date;
  readonly invert: boolean;
  readonly opsHours: boolean;
  readonly getCons: boolean;
  readonly calculationTimeTo: Date;
  readonly calculationTimeFrom: Date;
  readonly calculationTimeFromMin: Date;
  readonly calculationTimeToMax: Date,
  readonly calculationInvert: boolean;
  readonly calculationOpsHours: boolean;
  readonly datatype: EnergyMeasurementType;
  readonly deviation: number;
  readonly deviationType: number;
  readonly etCurveDisplayType: number;
  readonly periodDisplayType: PeriodDisplayType;
  readonly advancedCalculation: boolean;
};

export type EtCurveAdminParams = ETCurveSearchParams & {
  id: number | undefined,
  name: string,
  xAxis0: number,
  xAxis1: number,
  xAxis2: number,
  xAxis3: number,
  yAxis0: number,
  yAxis1: number,
  yAxis2: number,
  yAxis3: number
};

export type GetEtCurveInesParams = {
  profileId: number,
  facilityId: number,
  isActive?: boolean | null | undefined,
  quantityId?: number | null | undefined,
  filterWeekend?: boolean | null | undefined,
  operationalHours?: OperationalHours | null | undefined,
  etCurveConversionPropertyId?: number | null | undefined,
  etCurveConversionPropertyValue?: number | null | undefined,
  etCurveConversionResolution?: Resolution | null | undefined
};

export interface EtCurveCoordinate {
  index: number,
  values: [number | null | undefined, number | null | undefined]
}

export interface EtCurveAreaItem {
  name: string;
  text: string;
  areaId: number;
  areaValue: number;
}

