import { NgModule } from '@angular/core';

import { ALARM_API_BASE_URL } from '@enerkey/clients/alarm';
import { ATTACHMENTS_API_BASE_URL } from '@enerkey/clients/attachments';
import { CONFIGURATION_CONTROL_API_BASE_URL } from '@enerkey/clients/configuration-control';
import { CONSUMPTION_API_BASE_URL } from '@enerkey/clients/consumption';
import { CONTACT_API_BASE_URL } from '@enerkey/clients/contact';
import { CONTRACT_API_BASE_URL } from '@enerkey/clients/contract';
import { COST_FACTOR_BASE_URL } from '@enerkey/clients/cost-factor';
import { EMAIL_CONSUMPTION_REPORT_API_BASE_URL } from '@enerkey/clients/email-consumption-report';
import { ENERGY_REPORTING_API_BASE_URL } from '@enerkey/clients/energy-reporting';
import { ERRORTICKET_API_BASE_URL } from '@enerkey/clients/error-ticket';
import { FACILITY_API_BASE_URL } from '@enerkey/clients/facility';
import { INES_API_BASE_URL } from '@enerkey/clients/ines';
import { INES_REPORTS_BASE_URL } from '@enerkey/clients/ines-reports';
import { METER_MANAGEMENT_BASE_URL } from '@enerkey/clients/meter-management';
import { METERING_API_BASE_URL } from '@enerkey/clients/metering';
import { MQA_API_BASE_URL } from '@enerkey/clients/manual-qa';
import { NEWS_API_BASE_URL } from '@enerkey/clients/news';
import { REPORTING_API_BASE_URL } from '@enerkey/clients/reporting';
import { USER_MANAGEMENT_API_BASE_URL } from '@enerkey/clients/user-management';
import { WEATHER_API_BASE_URL } from '@enerkey/clients/weather';
import { WEBHOOK_API_BASE_URL } from '@enerkey/clients/webhook';
import { SUSTAINABILITY_API_BASE_URL } from '@enerkey/clients/sustainability';
import { SETTINGS_API_BASE_URL } from '@enerkey/clients/settings';
import { DATAHUB_API_BASE_URL } from '@enerkey/clients/datahub';
import { SCHEDULER_API_BASE_URL } from '@enerkey/clients/scheduler';

@NgModule({
  providers: [
    { provide: ALARM_API_BASE_URL, useValue: ENERKEY_CONFIG.apiAlarm },
    { provide: ATTACHMENTS_API_BASE_URL, useValue: ENERKEY_CONFIG.apiAttachmentManagement },
    { provide: CONFIGURATION_CONTROL_API_BASE_URL, useValue: ENERKEY_CONFIG.apiConfiguration },
    { provide: CONSUMPTION_API_BASE_URL, useValue: ENERKEY_CONFIG.apiConsumption },
    { provide: CONTACT_API_BASE_URL, useValue: ENERKEY_CONFIG.apiContact },
    { provide: CONTRACT_API_BASE_URL, useValue: ENERKEY_CONFIG.apiContract },
    { provide: COST_FACTOR_BASE_URL, useValue: ENERKEY_CONFIG.apiCostFactor },
    { provide: EMAIL_CONSUMPTION_REPORT_API_BASE_URL, useValue: ENERKEY_CONFIG.apiEmailConsumptionReport },
    { provide: ENERGY_REPORTING_API_BASE_URL, useValue: ENERKEY_CONFIG.apiEnergyreporting },
    { provide: ERRORTICKET_API_BASE_URL, useValue: ENERKEY_CONFIG.apiErrorTicket },
    { provide: FACILITY_API_BASE_URL, useValue: ENERKEY_CONFIG.apiFacility },
    { provide: INES_API_BASE_URL, useValue: ENERKEY_CONFIG.apiInes },
    { provide: INES_REPORTS_BASE_URL, useValue: ENERKEY_CONFIG.apiInesReports },
    { provide: METER_MANAGEMENT_BASE_URL, useValue: ENERKEY_CONFIG.apiMeterManagement },
    { provide: METERING_API_BASE_URL, useValue: ENERKEY_CONFIG.apiMetering },
    { provide: MQA_API_BASE_URL, useValue: ENERKEY_CONFIG.apiManualQA },
    { provide: NEWS_API_BASE_URL, useValue: ENERKEY_CONFIG.apiNews },
    { provide: REPORTING_API_BASE_URL, useValue: ENERKEY_CONFIG.apiReporting },
    { provide: USER_MANAGEMENT_API_BASE_URL, useValue: ENERKEY_CONFIG.apiUserManagement },
    { provide: WEATHER_API_BASE_URL, useValue: ENERKEY_CONFIG.apiWeather },
    { provide: WEBHOOK_API_BASE_URL, useValue: ENERKEY_CONFIG.apiWebhook },
    { provide: SETTINGS_API_BASE_URL, useValue: ENERKEY_CONFIG.apiSettings },
    { provide: DATAHUB_API_BASE_URL, useValue: ENERKEY_CONFIG.apiDatahub },
    { provide: SUSTAINABILITY_API_BASE_URL, useValue: ENERKEY_CONFIG.apiSustainability },
    { provide: SCHEDULER_API_BASE_URL, useValue: ENERKEY_CONFIG.apiScheduler },
  ]
})
export class ClientProvidersModule { }
