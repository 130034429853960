import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { formControlsFrom } from '@enerkey/ts-utils';
import { TopChangedConsumptionsWidgetOptions } from '../top-changed-consumptions-widget/top-changed-consumptions-widget.component';

type ChangeFn = (options: TopChangedConsumptionsWidgetOptions) => void;

type OmittedOptions = 'selectedQuantity' | 'quantityId' | 'itemProperty' | 'groupProperty';

type FormValues = Omit<TopChangedConsumptionsWidgetOptions, OmittedOptions>;

@Component({
  selector: 'top-changed-consumptions-widget-options',
  templateUrl: './top-changed-consumptions-widget-options.component.html',
  styleUrls: ['./top-changed-consumptions-widget-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TopChangedConsumptionsWidgetOptionsComponent),
    multi: true,
  }]
})
export class TopChangedConsumptionsWidgetOptionsComponent implements OnInit, ControlValueAccessor {
  @Input() public initialState: TopChangedConsumptionsWidgetOptions;

  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public ngOnInit(): void {
    const values = this.optionsToForm(this.initialState);
    const controls = formControlsFrom<FormValues>(
      values,
      Object.keys(values).toRecord(key => key, () => Validators.required)
    );
    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges
      .subscribe((formValue: FormValues) => {
        this._onChange?.(this.formToOptions(formValue));
      });
  }

  public writeValue(value: TopChangedConsumptionsWidgetOptions): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  private optionsToForm(options: TopChangedConsumptionsWidgetOptions): FormValues {
    const { quantityId, selectedQuantity, ...value } = options;
    return value;
  }

  private formToOptions(value: FormValues): TopChangedConsumptionsWidgetOptions {
    return {
      ...this.initialState,
      ...value
    };
  }
}
