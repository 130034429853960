import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ThresholdService } from '../../../../shared/services/threshold.service';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';
import { EmissionsFacilityWiseWidgetOptions, EmissionsFacilityWiseWidgetService }
  from '../../services/emissions-facility-wise-widget.service';
import { WidgetBase } from '../../shared/widget-base.interface';
import { EmissionsWidgetData } from '../../shared/facility-wise-emissions';

@Component({
  selector: 'emissions-facility-wise-widget',
  templateUrl: './emissions-facility-wise-widget.component.html',
  styleUrls: ['./emissions-facility-wise-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EmissionsFacilityWiseWidgetService]
})
export class EmissionsFacilityWiseWidgetComponent implements OnDestroy, OnInit,
  WidgetBase<EmissionsFacilityWiseWidgetOptions> {
  public dataModelOptions: EmissionsFacilityWiseWidgetOptions;
  public readonly dataModelChange$: Observable<EmissionsFacilityWiseWidgetOptions>;
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<void>;
  public facilityIdControl: UntypedFormControl;

  public readonly data$: Observable<EmissionsWidgetData>;
  public readonly emissionTitle$: Observable<string>;
  public readonly relationalValueId$: Observable<RelationalValueId>;
  public readonly years$: Observable<{ current: string; previous: string; first: string }>;
  private readonly _loading$ = new LoadingSubject(false);
  private readonly _error$ = new Subject<void>();
  private readonly _destroy$ = new Subject<void>();
  private readonly _dataModelChange = new Subject<EmissionsFacilityWiseWidgetOptions>();

  public constructor(
    private readonly emissionsWidgetService: EmissionsFacilityWiseWidgetService,
    private readonly thresholdService: ThresholdService
  ) {
    this.emissionTitle$ = this.emissionsWidgetService.emissionTitle$;
    this.relationalValueId$ = this.emissionsWidgetService.relationalValueId$;
    this.years$ = this.emissionsWidgetService.years$;
    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();
    this.dataModelChange$ = this._dataModelChange.asObservable();

    this.data$ = combineLatest([
      this.thresholdService.threshold$,
      this.dataModelChange$.pipe(startWith(this.dataModelOptions))
    ])
      .pipe(
        switchMap(([incompleteThreshold, _]) => this.emissionsWidgetService.getEmissionsData(
          incompleteThreshold
        ).pipe(
          indicate(this._loading$),
          catchError(() => {
            this._error$.next();
            return EMPTY;
          })
        )),
        shareReplay({ bufferSize: 1, refCount: true }),
        takeUntil(this._destroy$)
      );
  }

  public ngOnInit(): void {
    this.facilityIdControl = new UntypedFormControl(this.dataModelOptions.facilityId);
    this.emissionsWidgetService.setDataModelOptions(this.dataModelOptions);
    this.facilityIdControl.valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((formControlValue: number) => {
        this.dataModelOptions.facilityId = formControlValue;
        this._dataModelChange.next({
          ...this.dataModelOptions
        });
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._error$.complete();
    this._loading$.complete();
  }

}
