<ng-container *ngIf="facilityIds?.length; else noFacilities">
  <div class="yearly-trend-report-buttons">
    <div>
      <button class="button" [disabled]="loading$ | async" (click)="downloadAll()">
        {{ 'YEARLY_TREND.DOWNLOAD_ALL' | translate }}
      </button>
      <button class="button" [disabled]="loading$ | async" (click)="pdf.saveAs('yearlyTrend.pdf')">
        {{ 'YEARLY_TREND.DOWNLOAD' | translate }}
      </button>
    </div>
    <page-changer
      [pages]="facilityIds?.length"
      [disabled]="loading$ | async"
      (pageChange)="pageSelect($event)"
    >
    </page-changer>
  </div>
  <kendo-pdf-export #pdf paperSize="A4" margin="1cm">
    <div class="yearly-trend-report" *ngIf="(currentFacility$ | async) as facilityData">
      <div class="yearly-report-header">
        <p class="bold">{{ 'YEARLY_TREND.YEARS' | translate }} {{ this.lastYear }} - {{ this.firstYear }}</p>
        <p>{{ 'YEARLY_TREND.DOWNLOADED' | translate }} {{ downloadedDate | date:'shortDate' }} </p>
      </div>
      <h4>{{ facilityData[0].Name }}</h4>
      <p>{{ facilityData[0].FacilityInformation?.RealEstateId }} {{ facilityData[0].FacilityInformation?.City }}</p>
      <div>
        <ng-container *ngFor="let quantityId of usedQuantityId; last as isLast">
          <ng-container *ngIf="facilityData[1][quantityId] as quantityConsumptions">
            <yearly-trend-chart
              [consumptions]="quantityConsumptions"
              [blackColor]="blackColor"
              [quantityId]="quantityId"
            ></yearly-trend-chart>
            <hr *ngIf="!isLast" />
          </ng-container>
        </ng-container>
      </div>
      <yearly-trend-report-table
        [facilityData]="facilityData[1]"
        [quantityIds]="usedQuantityId"
      ></yearly-trend-report-table>
    </div>
    <ng-template *ngIf="isEnerkeyBrand" kendoPDFTemplate>
      <p style="position: absolute; bottom: 0; left: 0; width: 100%; text-align: center;">
        Powered by EnerKey &#169;
      </p>
    </ng-template>
  </kendo-pdf-export>
</ng-container>
<ng-template #noFacilities>
  {{ 'YEARLY_TREND.NO_FACILITIES_SELECTED' | translate }}
</ng-template>
