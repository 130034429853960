import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LogStatus } from '@enerkey/clients/alarm';

@Component({
  selector: 'alarm-log-status',
  templateUrl: './alarm-log-status.component.html',
  styleUrls: ['./alarm-log-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlarmLogStatusComponent {
  public readonly statuses = [
    LogStatus.InvestigationRequired,
    LogStatus.UnderInvestigation,
    LogStatus.InvestigationDone,
  ] as const;

  @Input() public status: LogStatus;
}
