import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FacilityInformationGroup } from '@enerkey/clients/energy-reporting';

import {
  FacilityColumnsPropertiesWithFields,
  FacilityPropertiesService
} from '../../services/facility-properties.service';

@Component({
  selector: 'overview-report-facility-info:not(p)',
  templateUrl: './overview-report-facility-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewReportFacilityInfoComponent {
  @Input() public facility: FacilityInformationGroup;

  public readonly facilityProperties$: Observable<FacilityColumnsPropertiesWithFields[]>;

  public constructor(facilityPropertiesService: FacilityPropertiesService) {
    this.facilityProperties$ = facilityPropertiesService.facilityProperties$.pipe(
      map(groups => groups.filter(group => group.Property !== 'MainMeterCounts' && group.Property !== 'SubMeterCounts'))
    );
  }
}
