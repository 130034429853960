import _ from 'lodash';

import { ACTIONS } from '../components/manual-qa-actions-select/actions';
import ValueTypes from '../constants/value-types';
import { Reading } from './reading';
import { getHourlyReadings, getReadingsWithinTimeFrame } from './utils.functions';

export const getModelingFunction = action => {
  let modelFn;

  const actionType = action && action.id ? action.id : ACTIONS.DEFAULT;

  switch (actionType) {
    case ACTIONS.CALCULATED:
      modelFn = calculated;
      break;
    case ACTIONS.COPY:
      modelFn = copyComparison;
      break;
    case ACTIONS.MANUAL:
      modelFn = manualEdit;
      break;
    default:
      throw new Error(`Could not create model function for action = ${actionType}`);
  }

  return _.partialRight(modelFn);
};

/**
 * Use backend calculated values for preview readings.
 *
 * @param {Reading[]} readings
 * @param {TimeFrame} timeFrame
 * @param {Object} parameters
 */
export function calculated(readings, timeFrame, parameters) {
  getHourlyReadings(getReadingsWithinTimeFrame(readings, timeFrame))
    .forEach((reading, index) => {
      Reading.setPreviewConsumption(reading, parameters.values[index].value);
      Reading.setValueType(reading, ValueTypes.MODELLED);
      reading.isDirty = true;
    });
}

/**
 * Copies reading data from comparison readings.
 *
 * @param {Reading[]} readings
 * @param {Object} timeFrame
 */
export function copyComparison(readings, timeFrame) {
  let consumption = null;

  readings.forEach(reading => {
    if (timeFrame.contains(reading.timestamp)) {
      if (reading.isHourly) {
        consumption = reading.comparisonConsumption || 0;
      }

      Reading.setPreviewConsumption(reading, consumption);
      Reading.setValueType(reading, ValueTypes.MODELLED);
      reading.isDirty = true;
    }
  });
}

/**
 * Uses given parameters to create preview readings
 *
 * @param {Reading[]} readings
 * @param {Object} timeFrame
 * @param {Object} parameters
 */
export function manualEdit(readings, timeFrame, parameters) {
  let consumption = null;

  readings.forEach(reading => {
    if (timeFrame.contains(reading.timestamp)) {
      if (reading.isHourly) {
        const rowReading = parameters.values[reading.rowIndex];
        consumption = rowReading ? rowReading.value : reading.previewConsumption;
      }

      Reading.setPreviewConsumption(reading, consumption);
      Reading.setValueType(reading, ValueTypes.MODELLED);
      reading.isDirty = true;
    }
  });
}

/**
 * Clears modelled values from given readings
 *
 * @param {Readings[]} readings
 */
export const clearPreviewValues = readings => {
  for (const reading of readings) {
    Reading.setPreviewConsumption(reading, null);
    reading.isDirty = false;
  }
};
