import { AllHtmlEntities } from 'html-entities';

import templateModule from 'raw-loader!../templates/mrc-searchbar.html';
import { Roles } from '../../admin/constants/roles';

mrcSearchbar.$inject = [
  '$window', '$location', '$timeout', '$filter', 'kendo', '_', 'UserService', '$rootScope'
];

export function mrcSearchbar(
  $window, $location, $timeout, $filter, kendo, _, UserService, $rootScope
) {
  function link(scope, element) {

    scope.searchables = [];

    scope.selected = "";

    var facilityHeaderRow = void 0;

    scope.$watch('facilities', function() {
      if (scope.facilities && scope.facilities.length > 0) {
        scope.searchables = [];
        _.each(scope.facilities, function(facility) {
          var item = {
            Id: facility.FacilityId,
            Name: facility.Name,
            RealEstateId: null,
            City: null,
            EnegiaId: null
          };

          item.dataTextField = item.Name;
          if (angular.isDefined(facility.FacilityInformation.RealEstateId)) {
            item.RealEstateId = facility.FacilityInformation.RealEstateId;
            item.dataTextField = item.dataTextField.concat(" ", item.RealEstateId);
          } else {
            item.RealEstateId = "";
          }
          if (angular.isDefined(facility.FacilityInformation.City)) {
            item.City = facility.FacilityInformation.City;
            item.dataTextField = item.dataTextField.concat(" ", item.City);
          } else {
            item.City = "";
          }
          if (angular.isDefined(facility.FacilityInformation.EnegiaId) && UserService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES)) {
            item.EnegiaId = facility.FacilityInformation.EnegiaId;
            item.dataTextField = item.dataTextField.concat(" ", item.EnegiaId);
          }
          scope.searchables.push(item);

        });

        scope.options.dataSource.data(scope.searchables);
      }
    });

    scope.$on('removeControls', function() {
      if (angular.isDefined(scope.activeFacility)) {
        scope.activeFacility.controlsVisible = false;
        scope.activeFacility.controlsReady = false;
      }

      facilityHeaderRow = void 0;
    });

    scope.$watch('mrcState.intervalInputSwitch', function(n, o) {
      if (n !== o) {
        if (angular.isDefined(scope.activeFacility)) {
          scope.activeFacility.controlsVisible = false;
        }
      }
    });

    scope.$watch('activeFacility.controlsReady', function(n, o) {
      if (n !== o && n) {
        if (angular.isUndefined(facilityHeaderRow) || facilityHeaderRow.length === 0) {
          facilityHeaderRow = angular.element('#facilityHeaderRow_' + scope.activeFacility.FacilityId);
        }
      }
    });

    scope.options = {
      dataTextField: 'dataTextField',
      height: 400,
      filter: 'contains',
      highlightFirst: true,
      template: function(data) {
        const infoText = [
          data.RealEstateId || '-',
          data.City || '-',
          data.EnegiaId,
        ].join(', ');
        return `
          <span ng-non-bindable>
            ${AllHtmlEntities.encode(data.Name)}
          <span>
          <span ng-non-bindable class="search-item-text">
            ${AllHtmlEntities.encode(infoText)}
          </span>
        `;
      },
      placeholder: $filter('translate')("MRC.SEARCH_FACILITIES"),
      noDataTemplate: $filter('translate')("MRC.NO_SEARCH_RESULTS"),
      dataSource: new kendo.data.DataSource({}),
      select: function(e) {
        scope.facilitySelected(e.dataItem.Id);
      },
      change: function(e) {
        e.sender.value('');
      }
    };

    scope.facilitySelected = function(facilityId) {
      scope.selected = "";
      var hash = facilityId.toString();
      var facilityElement = angular.element('#' + hash.toString()).parentsUntil('.metering-target').parent()[0];
      var facilityElementOffset = facilityElement.offsetTop - facilityElement.parentNode.offsetTop;
      $window.scrollTo(0, facilityElementOffset);
      var facility = _.find(scope.facilities, function(f) {
        return f.FacilityId === facilityId;
      });
      if (angular.isDefined(facility)) {
        $rootScope.$broadcast('facilitySelected', facility);
      }
    };

    scope.onKeyPress = function(event) {
      switch (event.keyCode) {
        case 13: // Enter
          break;
        case 27: // ESC
          break;
        default:
          break;
      }
    };

    if (!scope.mrcState.isMobile) {
      scope.scrolling = false;
      var elemTop = element.offset().top;
      var topBarHeight = angular.element('#top-bar-container').height();
      var top = topBarHeight >= 0 ? topBarHeight : 0;
      angular.element($window).bind("scroll", function() {
        var scrollTop = $window.pageYOffset;
        if (scrollTop >= elemTop && !scope.scrolling) {
          scope.scrolling = true;
          element.css({ 'position': 'fixed', 'top': top + 'px','left': '0px', "box-shadow": "0px 2px 2px 4px rgba(0,0,0,0.2)" });
        } else if ((scrollTop + element.height()) < elemTop && scope.scrolling) {
          scope.scrolling = false;
          element.css({ 'position': 'static', "box-shadow": "none" });
        }

        if (angular.isDefined(scope.activeFacility) && scope.activeFacility.isOpen && angular.isDefined(facilityHeaderRow) && facilityHeaderRow.length > 0) {
          var rowTop = facilityHeaderRow.offset().top;
          if (scrollTop > rowTop && !scope.activeFacility.controlsVisible) {
            scope.activeFacility.controlsVisible = true;
            scope.$apply();
          } else if (scrollTop < rowTop && scope.activeFacility.controlsVisible) {
            scope.activeFacility.controlsVisible = false;
            scope.$apply();
          }
        }
      });
      element.on('$destroy', function() {
        angular.element($window).unbind("scroll");
      });
    }
  }

  return {
    restrict: 'E',
    template: templateModule,
    link: link
  };

}
