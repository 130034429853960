<form [formGroup]="formGroup">
  <facility-select
    class="searchInput"
    formControlName="facilityId"
    *labelBefore="'FACILITIES.FACILITY'| translate"
  ></facility-select>
  <emissions-type-select
    formControlName="factorOption"
    [disabledItems]="disabledEmissionFactor"
    *labelBefore="'EMISSIONS_WIDGET.FACTOR_CHANGE' | translate"
  ></emissions-type-select>
</form>
