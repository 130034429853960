import template from 'raw-loader!./manual-qa-inspect-search.html';

import ManualQaInspectSearchController from './manual-qa-inspect-search-controller';

export default {
  template: template,
  controller: ManualQaInspectSearchController,
  controllerAs: 'vm',
  bindings: {
    actualTimeFrame: '<',
    operationTimeFrame: '<',
    comparisonTimeFrame: '<',
    meters: '<',
    meter: '<',
    defects: '<',
    defect: '<',
    isLoading: '<',
    searchType: '<',
    useCompactView: '<',
    confirmMarkAsDone: '<',
    focusOnMissingReadings: '<',
    onChangeDefect: '<',
    onChangeMeter: '<',
    onChangeComparisonTimeFrame: '<',
    onChangeActualTimeFrame: '<',
    onChangeOperationTimeFrame: '<',
    onChangeTimeFramePreset: '<',
    onChangeActualTimeFramePreset: '<',
    onChangeUseComparisonPeriod: '<',
    useComparisonPeriod: '<',
    dataSource: '<',
    isPreviewMode: '<',
    broadcastResize: '<',
  }
};
