import EnerKeyServicesModule from '../../../../services';
import ReportingObjectsServicesModule from '../../../reportingobjects/services';
import ConfigurationModelsModule from '../../../configuration/models';
import EnergyReportingConstantsModule from '../../constants';
import ERUtilsModule from '../er-utils';
import ERDataColumn from './er-data-column';
import ERDataFunctions from './er-data-functions';
import ERChartService from './er-chart-service';
import ERDataService2 from './er-data-service2';
import ErGridQuantityColumns from './er-grid-quantity-column.service';
import ERDataSumFunctions from './er-data-sum-functions';
import ERConsumptionTargetColumn from './er-consumption-target-column.service';
import { ERGridService } from './er-grid-service';

export default angular.module('app.modules.energy-reporting.services.er-data-service', [
  EnerKeyServicesModule.name,
  ReportingObjectsServicesModule.name,
  ConfigurationModelsModule.name,
  EnergyReportingConstantsModule.name,
  ERUtilsModule.name
])
  .factory('ERDataColumn', ERDataColumn)
  .factory('ERDataFunctions', ERDataFunctions)
  .factory('ERChartService', ERChartService)
  .factory('ERDataService2', ERDataService2)
  .service('ErGridQuantityColumns', ErGridQuantityColumns)
  .factory('ERDataSumFunctions', ERDataSumFunctions)
  .service('ERConsumptionTargetColumn', ERConsumptionTargetColumn)
  .factory('ERGridService', ERGridService);
