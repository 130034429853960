import { Consumption } from '@enerkey/clients/reporting';

import { ConsumptionLike } from '../shared/reporting-series';

export function getForecastSeriesValues(
  inspectionValues: ConsumptionLike[],
  comparisonValues: ConsumptionLike[],
  thresholdForIncomplete: number,
  method: 'factor' | 'difference'
): ConsumptionLike[] {
  let firstIncompleteIndex = inspectionValues.findIndex(
    c => c.incomplete > thresholdForIncomplete || c.incomplete === null
  );
  if (firstIncompleteIndex < 0) {
    firstIncompleteIndex = 12;
  }
  const completeYearValues = inspectionValues.slice(0, firstIncompleteIndex);
  const inspectionYearValue = completeYearValues
    .reduce((sum, value) => sum + value.value, 0);
  const comparisonYearValue = comparisonValues
    .slice(0, firstIncompleteIndex)
    .reduce((sum, value) => sum + value.value, 0);

  const factor = method === 'factor'
    ? (inspectionYearValue / comparisonYearValue) || 1
    : 1
  ;

  const forecastedYearValues: Consumption[] = [];
  for (let i = firstIncompleteIndex; i < inspectionValues.length; i++) {
    const value = comparisonValues[i].value * factor;
    const timestamp = comparisonValues[i].timestamp;

    forecastedYearValues.push(new Consumption({
      value,
      timestamp,
      incomplete: 0,
      modeled: 1
    }));
  }

  const forecastSeries = [
    ...completeYearValues,
    ...forecastedYearValues
  ];
  return forecastSeries;
}
