import { IMeteringClient } from './metering-client';

export class MeteringClientMock implements IMeteringClient {

  public update(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterForExternalId(): never {
    throw new Error('Method not implemented.');
  }
  public synchronizeMetersForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public searchMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterLocale(): never {
    throw new Error('Method not implemented.');
  }
  public deleteMeter(): never {
    throw new Error('Method not implemented.');
  }
  public createMetersBulk(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterTenant(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHistoryFromTimePeriod(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHistory(): never {
    throw new Error('Method not implemented.');
  }
  public getMeter(): never {
    throw new Error('Method not implemented.');
  }
  public getMetersForFacilityIds(): never {
    throw new Error('Method not implemented.');
  }
  public getMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getMetersWithProperties(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterIdsByNameBeginingCaseInsensitive(): never {
    throw new Error('Method not implemented.');
  }
  public removeLatestMeterHistory(): never {
    throw new Error('Method not implemented.');
  }
  public removeMeterHistory(): never {
    throw new Error('Method not implemented.');
  }
  public updateMeter(): never {
    throw new Error('Method not implemented.');
  }
  public createMeters(): never {
    throw new Error('Method not implemented.');
  }
  public updateMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getMetersHierarchyStatuses(): never {
    throw new Error('Method not implemented.');
  }
  public changeMetersFacility(): never {
    throw new Error('Method not implemented.');
  }
  public changeMetersQuantity(): never {
    throw new Error('Method not implemented.');
  }
  public getProperties(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterIdentificationProperties(): never {
    throw new Error('Method not implemented.');
  }
  public addProperty(): never {
    throw new Error('Method not implemented.');
  }
  public updateProperties(): never {
    throw new Error('Method not implemented.');
  }
  public removeLatestMeterProperty(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterPropertiesByValues(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterCounts(): never {
    throw new Error('Method not implemented.');
  }
  public validateSynchronizePayload(): never {
    throw new Error('Method not implemented.');
  }
  public synchronizeHierarchiesForFacilities(): never {
    throw new Error('Method not implemented.');
  }
  public createNewHierarchyFromMeter(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesMainMeterQuantities(): never {
    throw new Error('Method not implemented.');
  }
  public upsertMeterHierarchies(): never {
    throw new Error('Method not implemented.');
  }
  public patchMeterHierarchy(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHierarchy(): never {
    throw new Error('Method not implemented.');
  }
  public removeMeterHierarchy(): never {
    throw new Error('Method not implemented.');
  }
  public updateMeterHierarchyOrdinals(): never {
    throw new Error('Method not implemented.');
  }
  public removeMeterHierarchies(): never {
    throw new Error('Method not implemented.');
  }
  public importMeterHierarchies(): never {
    throw new Error('Method not implemented.');
  }
  public getMainMetersForMeterHierarchies(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHierarchyIdsForReportingObjects(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHierarchies(): never {
    throw new Error('Method not implemented.');
  }
  public getSubMetersForMeterHierarchies(): never {
    throw new Error('Method not implemented.');
  }
  public getRelatedMetersForMeterHierarchies(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHistoryEntries(): never {
    throw new Error('Method not implemented.');
  }
  public createMeterHistoryEntry(): never {
    throw new Error('Method not implemented.');
  }
  public deleteMeterHistoryEntry(): never {
    throw new Error('Method not implemented.');
  }
  public patchMeterHistoryEntry(): never {
    throw new Error('Method not implemented.');
  }
  public importMeters(): never {
    throw new Error('Method not implemented.');
  }
  public getMeters2(): never {
    throw new Error('Method not implemented.');
  }
  public meterHierarchyWithReportingObjectIdContainsMeter(): never {
    throw new Error('Method not implemented.');
  }
  public meterHierarchiesWithReportingObjectIdContainsAllGivenMeters(): never {
    throw new Error('Method not implemented.');
  }
  public changeMeter(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHistory2(): never {
    throw new Error('Method not implemented.');
  }
  public importMeterHistory(): never {
    throw new Error('Method not implemented.');
  }
  public changeFactor(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHistoryBeginningFrom(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHistoryEndingTo(): never {
    throw new Error('Method not implemented.');
  }
  public getPowerOfAttorneyById(): never {
    throw new Error('Method not implemented.');
  }
  public deletePowerOfAttorney(): never {
    throw new Error('Method not implemented.');
  }
  public getPowerOfAttorneyBySearchCriteria(): never {
    throw new Error('Method not implemented.');
  }
  public getPowerOfAttorneysForMeterIds(): never {
    throw new Error('Method not implemented.');
  }
  public getMetersIdsByPoACompany(): never {
    throw new Error('Method not implemented.');
  }
  public getMetersByPoACompanyIds(): never {
    throw new Error('Method not implemented.');
  }
  public getPoACompaniesForMeterIds(): never {
    throw new Error('Method not implemented.');
  }
  public createPowerOfAttorneys(): never {
    throw new Error('Method not implemented.');
  }
  public updatePowerOfAttorney(): never {
    throw new Error('Method not implemented.');
  }
  public addCompaniesToPowerOfAttorney(): never {
    throw new Error('Method not implemented.');
  }
  public upsertMeterToPoACompany(): never {
    throw new Error('Method not implemented.');
  }
  public removeMetersFromPoACompany(): never {
    throw new Error('Method not implemented.');
  }
  public removeCompanyFromPoA(): never {
    throw new Error('Method not implemented.');
  }
  public getQuantities(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityQuantities(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityGroupedQuantities(): never {
    throw new Error('Method not implemented.');
  }
  public getQuantityTypes(): never {
    throw new Error('Method not implemented.');
  }
  public getUnits(): never {
    throw new Error('Method not implemented.');
  }
  public getRelatedVirtualMeters(): never {
    throw new Error('Method not implemented.');
  }
  public postVirtualMeter(): never {
    throw new Error('Method not implemented.');
  }
  public putVirtualMeter(): never {
    throw new Error('Method not implemented.');
  }
  public getVirtualMeterHistory(): never {
    throw new Error('Method not implemented.');
  }
  public getFormulaModel(): never {
    throw new Error('Method not implemented.');
  }
  public saveFormulaModel(): never {
    throw new Error('Method not implemented.');
  }
  public updateFormulaModel(): never {
    throw new Error('Method not implemented.');
  }
  public getVirtualMeter(): never {
    throw new Error('Method not implemented.');
  }
  public deleteVirtualMeter(): never {
    throw new Error('Method not implemented.');
  }
  public getOperand(): never {
    throw new Error('Method not implemented.');
  }
  public deleteOperand(): never {
    throw new Error('Method not implemented.');
  }
  public addOperands(): never {
    throw new Error('Method not implemented.');
  }
  public putOperand(): never {
    throw new Error('Method not implemented.');
  }
  public getTagsForMeter(): never {
    throw new Error('Method not implemented.');
  }
  public getTagsForMeters(): never {
    throw new Error('Method not implemented.');
  }
  public addMeterTags(): never {
    throw new Error('Method not implemented.');
  }
  public removeMeterTags(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterGroupById(): never {
    throw new Error('Method not implemented.');
  }
  public updateMeterGroupMetadata(): never {
    throw new Error('Method not implemented.');
  }
  public deleteMeterGroup(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterGroups(): never {
    throw new Error('Method not implemented.');
  }
  public createMeterGroup(): never {
    throw new Error('Method not implemented.');
  }
  public updateMeterGroupMeters(): never {
    throw new Error('Method not implemented.');
  }
}
