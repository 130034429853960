
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { UserService } from '../../services/user-service';
import { WINDOW } from '@enerkey/angular-utils';
import { LanguageService } from './language.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})

export class HelpService {

  public constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly languageService: LanguageService,
    private readonly tenantService: TenantService,
    private readonly userService: UserService,
    private readonly oauthService: OAuthService
  ) {
  }

  /**
   * Opens help page with given id.
   */
  public openHelpEntry(id: string): void {
    const urlParams = new HttpParams({ fromObject: {
      locale: this.languageService.getHelpLocale(),
      id: id ?? '',
      tenant: this.tenantService.tenantName,
      serviceLevel: this.userService.getServiceLevel()?.toString(),
      access_token: this.oauthService.getAccessToken(),
    } });
    this.window.open(`${ENERKEY_CONFIG.helpUrl}?${urlParams}`, '_blank');
  }
}
