import _ from 'lodash';
import { IHttpPromise, IHttpService } from 'angular';
import { HttpConfigService } from '../../../services/http-config.service';
import { Utils } from '../../../services/utils';

interface Location {
  PostalCode: string;
  Country: string;
}

export class Weather {
  public static $inject = ['$http', 'httpConfigService', 'utils'];

  public constructor(
    private $http: IHttpService,
    private httpConfigService: HttpConfigService,
    private utils: Utils
  ) {
  }

  public getWeatherAverage(payload: any): IHttpPromise<any> {
    return this.$http.post(
      `${ENERKEY_CONFIG.apiWeather }/api/v1/weather/average/temperature`,
      payload,
      this.httpConfigService.getExtendedHttpConfig()
    ).then((response: any) => {
      response.data = this.utils.keysToUpperCamelCase(response.data);
      return response;
    });
  }

  public getPayload(timeSeries: any, facilities: any[]): any {
    const postalCodes = facilities
      .map((facility: any) => ({
        PostalCode: facility.FacilityInformation.PostalCode,
        Country: facility.FacilityInformation.CountryCode
      }) as Location)
      .filter(item => item.PostalCode)
      .uniqueBy(postalCode => postalCode.PostalCode + postalCode.Country);
    if (!Array.hasItems(postalCodes)) {
      return undefined;
    }

    return { ...timeSeries, PostalCodeCountry: postalCodes };
  }

  public getWeatherByQuantities(payload: any, quantities: any): IHttpPromise<any> {
    return this.getWeatherAverage(payload)
      .then(response => {
        const dataByQuantities = {};
        _.each(quantities, quantity => {
          _.set(dataByQuantities, quantity.ID, _.extend(_.get(response, 'data.AVERAGE'), {
            _Id: quantity.ID,
            _IdType: 'Quantity'
          }));
        });
        return _.extend(response, { data: dataByQuantities });
      })
    ;
  }

  public getWeatherByFacilities(payload: any, facilities: any, quantities: any): IHttpPromise<any> {
    return this.getWeather(payload)
      .then(response => {
        const dataByFacilities = {};
        _.each(facilities, facility => {
          const location = this.getLocationCode(facility);
          const dataForFacility = _.get(response.data, location);
          const subSeries = {};
          _.each(quantities, quantity => {
            _.set(subSeries, quantity.ID, _.extend(dataForFacility, {
              _Id: quantity.ID,
              _IdType: 'Quantity'
            }));
          });
          _.set(dataByFacilities, facility.FacilityId, {
            SubSeries: subSeries,
            ErrorCodes: _.get(dataForFacility, 'ErrorCodes'),
            _Id: facility.FacilityId,
            _IdType: 'Facility'
          });
        });
        return _.extend(response, { data: dataByFacilities });
      });
  }

  public getWeatherByMeters(payload: any, facility: any, meters: any): IHttpPromise<any> {
    return this.getWeather(payload)
      .then(response => {
        const location = this.getLocationCode(facility);
        const dataForFacility = _.get(response.data, location);
        const dataByMeters = {};
        _.each(meters, meter => {
          _.set(dataByMeters, meter.Id, _.extend({}, dataForFacility, {
            _Id: meter.Id,
            _IdType: 'Meter'
          }));
        });
        return _.extend(response, { data: dataByMeters });
      })
    ;
  }

  private getLocationCode(facility: any): string {
    const postalCode = _.get(facility, 'FacilityInformation.PostalCode');
    const countryCode = _.get(facility, 'FacilityInformation.CountryCode');
    return `${countryCode}${postalCode}`;
  }

  private getWeather(payload: any): IHttpPromise<any> {
    return this.$http.post(
      `${ENERKEY_CONFIG.apiWeather }/api/v1/weather/temperature/smart`,
      payload,
      this.httpConfigService.getExtendedHttpConfig()
    ).then((response: any) => {
      response.data = this.utils.keysToUpperCamelCase(response.data);
      return response;
    });
  }
}
