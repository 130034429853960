import template from 'raw-loader!./manual-qa-inspect-search-section-body.html';

export default {
  template: template,
  controllerAs: 'vm',
  transclude: true,
  bindings: {
    collapse: '<'
  }
};
