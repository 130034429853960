import EmMassEditController from './em-mass-edit-controller';
import emMassEditTemplate   from 'raw-loader!./em-mass-edit.html';

export default {
  template: emMassEditTemplate,
  controller: EmMassEditController,
  controllerAs: 'vm',
  bindings: {
    actionsList: '<',
    modalDismiss: '<',
    modalClose: '<'
  }
};
