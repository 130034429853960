import { Injectable } from '@angular/core';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

import { ModalService } from '@enerkey/foundation-angular';

import { NewsClient, ReadOnlyNews } from '@enerkey/clients/news';
import { ProfileService } from './profile.service';
import { indicate, LoadingSubject, switchJoin } from '@enerkey/rxjs';
import { LanguageChangeService } from './language-change.service';
import { NewsModalComponent } from '../news-modal/news-modal.component';

@Injectable({ providedIn: 'root' })
export class NewsService {
  public readonly news$: Observable<ReadOnlyNews[]>;
  public readonly newsLoading$: Observable<boolean>;

  private readonly _news$: Observable<ReadOnlyNews[]>;
  private readonly _newsLoading$ = new LoadingSubject(true);
  private readonly newsRead$ = new Subject<number>();

  public constructor(
    private readonly newsClient: NewsClient,
    private readonly modalService: ModalService,
    profileService: ProfileService,
    languageChangeService: LanguageChangeService
  ) {
    this.newsLoading$ = this._newsLoading$.asObservable();

    const language$ = languageChangeService.languageChange.pipe(startWith(null));

    this._news$ = combineLatest([
      profileService.profile$,
      language$
    ]).pipe(
      switchMap(([profile]) => this.newsClient.getUserNews(profile.id).pipe(
        indicate(this._newsLoading$)
      )),
      map(news => news.sortBy('published', 'desc'))
    );

    this.news$ = merge(
      this._news$,
      this.newsRead$.pipe(
        switchJoin(() => this.news$.pipe(take(1))),
        map(([readEntryId, news]) => {
          const readEntry = news.find(entry => entry.id === readEntryId);

          if (readEntry) {
            readEntry.isRead = true;
          }

          return news;
        })
      )
    ).pipe(shareReplay(1));
  }

  public openNewsModal(entry: ReadOnlyNews): void {
    const modalRef = this.modalService.open(NewsModalComponent);
    modalRef.componentInstance.entry = entry;

    if (!entry.isRead) {
      // Mark as read after content loaded successfully
      modalRef.componentInstance.read$.subscribe(() => {
        this.newsRead$.next(entry.id);
        this.newsClient.markAsRead(entry.id).subscribe();
      });
    }
  }
}
