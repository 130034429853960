import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkCommonModule } from '@enerkey/ek-common';

import { IntegersDirective } from './directives/integers.directive';
import { LabelBeforeDirective } from './directives/label-before.directive';
import { LabelDirectiveComponent } from './components/label-directive.component';
import { LabelWrapDirective } from './directives/label-wrap.directive';
import { SingleInputEnabledDirective } from './directives/single-input-enabled.directive';
import { ValidationErrorsDirective } from './directives/validation-errors.directive';
import { ValidationErrorsComponent } from './components/validation-errors/validation-errors.component';
import { ErrorMessageDirective } from './directives/error-message.directive';
import { ErrorMessageComponent } from './components/error-message/error-message.component';

@NgModule({
  imports: [
    CommonModule,
    NgfTooltipModule,
    EkCommonModule,
    TranslateModule,
  ],
  declarations: [
    IntegersDirective,
    LabelBeforeDirective,
    LabelDirectiveComponent,
    LabelWrapDirective,
    SingleInputEnabledDirective,
    ValidationErrorsDirective,
    ValidationErrorsComponent,
    ErrorMessageDirective,
    ErrorMessageComponent,
  ],
  exports: [
    IntegersDirective,
    LabelBeforeDirective,
    LabelWrapDirective,
    SingleInputEnabledDirective,
    ValidationErrorsDirective,
    ErrorMessageDirective,
  ],
})
export class EkFormsModule { }
