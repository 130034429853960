<form [formGroup]="formGroup">
  <facility-select
    class="searchInput"
    formControlName="facilityId"
    *labelBefore="'FACILITIES.FACILITY' | translate"
  ></facility-select>
  <ek-slider-number-box
    formControlName="numberToShow"
    [min]="minCount"
    [max]="maxCount"
    *labelBefore="'LATEST_ALARMS_WIDGET.AMOUNT_TO_SHOW' | translate"
  ></ek-slider-number-box>
</form>
