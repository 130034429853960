<kendo-grid
  [data]="gridView$ | async"
  [sortable]="true"
  [pageSize]="(state$ | async).take"
  [pageable]="true"
  [reorderable]="true"
  [skip]="(state$ | async).skip"
  [group]="(state$ | async).group"
  [sort]="(state$ | async).sort"
  [filter]="(state$ | async).filter"
  [filterable]="'menu'"
  [groupable]="true"
  [selectable]="gridSelectableSettings"
  [resizable]="true"
  [kendoGridSelectBy]="selectKey"
  [selectedKeys]="selection"
  [loading]="loading$ | async"
  (filterChange)="filterChange()"
  (dataStateChange)="dataStateChange($event)"
  (columnVisibilityChange)="columnChange($event)"
  class="grid-full-height"
>
  <kendo-grid-messages
    [filterIsNullOperator]="'kendo.grid.filterIsEmptyOperator' | translate"
    [filterIsNotNullOperator]="'kendo.grid.filterIsNotEmptyOperator' | translate"
  >
  </kendo-grid-messages>

  <kendo-grid-excel
    [fileName]="'ADMIN.FACILITIES' | translate | excelFileName:currentDate"
    [excelDataSource]="data"
  ></kendo-grid-excel>
  <ng-template kendoGridToolbarTemplate>
    <kendo-grid-grouped-column-chooser></kendo-grid-grouped-column-chooser>
    <button kendoGridExcelCommand>{{ 'ADMIN.GET_EXCEL' | translate }}</button>
    <button
      type="button"
      icon="eye"
      kendoButton
      (click)="toggleShowSelected()"
      [disabled]="selection.length === 0 && !(showOnlySelected$ | async)"
    >
      {{ ((showOnlySelected$ | async) ? 'REPORTING.GRID_FILTER.SHOW_ALL' : 'REPORTING.GRID_FILTER.SHOW_SELECTED') |
      translate }}
    </button>
  </ng-template>

  <kendo-grid-checkbox-column
    [width]="24"
    [resizable]="false"
    [locked]="true"
  >
    <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
    <ng-template kendoGridFooterTemplate>
      <div *ngFor="let _ of visibleAggregates">&nbsp;</div>
    </ng-template>
  </kendo-grid-checkbox-column>


  <table-report-facility-name-column
    field="Name"
    [title]="'FACILITY_NAME' | translate"
    [width]="250"
    [groupable]="false"
    [includeInChooser]="false"
    [locked]="true"
  ></table-report-facility-name-column>

  <kendo-grid-column
    [title]="''"
    [width]="48"
    [resizable]="false"
    [groupable]="false"
    [includeInChooser]="false"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <button
        (click)="editFacility(dataItem)"
        class="button button--no-padding button--link"
        [style.margin-right.px]="5"
        *ngIf="hasEditAccess"
      >
        <i class="fas fa-pencil-alt"></i>
      </button>
      <button
        (click)="editTags(dataItem)"
        class="button button--no-padding button--link"
      >
        <i class="fas fa-tag"></i>
      </button>
    </ng-template>

    <ng-template kendoGridFooterTemplate>
      <div *ngFor="let _ of visibleAggregates">&nbsp;</div>
    </ng-template>
  </kendo-grid-column>

  <!-- FACILITYPROPERTIES COLUMNS -->
  <kendo-grid-table-report-column-group
    *ngFor="let group of (facilityPropertyColumns$ | async)"
    [title]="group.Name"
  >
    <kendo-grid-facility-property-column
      *ngFor="let property of group.Items"
      [property]="property"
      [total]="total$ | async"
      [data]="data"
      [isHidden]="property.hidden"
      [width]="150"
      [visibleAggregates]="visibleAggregates"
    >
      <ng-template
        kendoGridGroupHeaderTemplate
        let-group="group"
      >
        <kendo-grid-group-header-checkbox [items]="group.items"></kendo-grid-group-header-checkbox>
        <span>&nbsp;{{ property.Name }}: {{ group.value ?? '-' }}</span>
      </ng-template>
    </kendo-grid-facility-property-column>
  </kendo-grid-table-report-column-group>

  <!-- QUANTITY COLUMNS -->
  <kendo-grid-table-report-column-group
    *ngFor="let quantity of (columns$ | async); trackBy: quantityTrackBy"
    [resizable]="false"
    [title]="quantity | quantityTitle | async"
  >
    <ng-container *ngIf="quantity | quantityTitle | async as title">
      <span
        *kendoGridHeaderTemplate
        [title]="title"
      >
        {{ title }}
      </span>
    </ng-container>
    <ng-container *ngFor="let seriesOfType of quantity.series; trackBy: serieTypeTrackBy">
      <ng-container *ngFor="let serie of seriesOfType.series; trackBy: periodTrackBy; let index=index">
        <kendo-grid-quantity-consumption-column
          [title]="serie.gridTitle"
          [fieldStart]="serie | tableReportFieldName"
          [total]="total$ | async"
          [color]="serie.gridColor"
          [visibleAggregates]="visibleAggregates"
          [width]="130"
          [format]="serie | tableReportValueFormat | async"
          [modalReportType]="modalReportType"
        >
        </kendo-grid-quantity-consumption-column>
      </ng-container>
      <ng-container *ngFor="let serie of seriesOfType.series; let index=index">
        <ng-container *ngIf="serie.isChangeVisible">
          <kendo-grid-change-column
            *ngIf="(searchParams$ | async)?.change?.relative"
            [quantityId]="quantity.quantityId"
            [total]="total$ | async"
            [format]="'p1'"
            [field]="(serie | tableReportFieldName) + '.' + relativeChangeKey"
            [changeKey]="'%'"
            [title]="serie.gridTitle"
            [color]="serie.gridColor"
            [comparisonColor]="serie.gridComparisonColor"
            [visibleAggregates]="visibleAggregates"
            [width]="130"
            [modalReportType]="modalReportType"
          ></kendo-grid-change-column>
          <kendo-grid-change-column
            *ngIf="(searchParams$ | async)?.change?.absolute"
            [quantityId]="quantity.quantityId"
            [total]="total$ | async"
            [field]="(serie | tableReportFieldName) + '.' + absoluteChangeKey"
            [title]="serie.gridTitle"
            [color]="serie.gridColor"
            [comparisonColor]="serie.gridComparisonColor"
            [visibleAggregates]="visibleAggregates"
            [width]="130"
            [format]="serie | tableReportValueFormat:true | async"
            [modalReportType]="modalReportType"
          ></kendo-grid-change-column>
        </ng-container>
      </ng-container>
    </ng-container>
  </kendo-grid-table-report-column-group>
</kendo-grid>
