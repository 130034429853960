import tooltipTemplate from 'raw-loader!./manual-qa-meter-history-tooltip.html';

import { hasChanges } from '@enerkey/angular-utils';

const GRID_SELECTOR = '#meter-history-grid';

const $inject = [
  '$element', '$compile', '$rootScope', 'utils',
  'ManualQaMeterHistoryService'
];

function ManualQaMeterHistoryController(
  $element, $compile, $rootScope, utils,
  ManualQaMeterHistoryService
) {
  const vm = this;

  vm.$postLink = postLink;
  vm.$onDestroy = onDestroy;
  vm.$onChanges = onChanges;

  vm.grid = null;
  vm.hasRowFactorChanged = false;
  vm.hasRowInfoChanged = false;

  function postLink() {
    // Initialize grid here, having k-options and k-rebind in template causes problems
    vm.grid = $element.find(GRID_SELECTOR).data('kendoGrid');
    vm.grid.setOptions(ManualQaMeterHistoryService.getGridOptions());
    vm.grid.setDataSource(ManualQaMeterHistoryService.getDataSource(vm));

    createTooltips();
    bindEvents();

    vm.grid.dataSource.data(vm.meterHistory);
  }

  function createTooltips() {
    vm.grid.element.kendoTooltip({
      filter: 'td:nth-child(1)',
      position: 'bottom',
      content: event => {
        const scope = $rootScope.$new();
        scope.meterHistoryItem = vm.grid.dataItem(event.target.closest('tr'));
        const content = $compile(tooltipTemplate)(scope);
        scope.$digest();

        return content;
      }
    }).data('kendoTooltip');
  }

  function onChanges(changes) {
    if (hasChanges(changes, 'meterHistory') && vm.grid) {
      vm.grid.dataSource.data(vm.meterHistory);
    }
  }

  function onDestroy() {
    if (vm.grid) {
      vm.grid.destroy();
    }
  }

  function bindEvents() {
    vm.grid.dataSource.bind('error', gridErrorFunction);
    vm.grid.bind('save', gridSaveFunction);
  }

  /**
   * Prevent items from being deleted from dataSource when deleting row fails
   *
   */
  function gridErrorFunction() {
    vm.grid.cancelChanges();
  }

  /**
   * Save factor and/or other info change statuses to vm
   *
   * @param {Object} e kendo event
   */
  function gridSaveFunction(e) {
    const originalValue = e.sender.dataSource._pristineForModel(e.model);
    vm.hasRowFactorChanged = ManualQaMeterHistoryService.hasRowFactorChanged(originalValue, e.model);
    vm.hasRowInfoChanged = ManualQaMeterHistoryService.hasRowInfoChanged(originalValue, e.model);
  }
}

ManualQaMeterHistoryController.$inject = $inject;

export default ManualQaMeterHistoryController;
