import { Injectable } from '@angular/core';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';

import { ModalService } from '@enerkey/foundation-angular';

import { AjsModalService, ConfirmDialogOptions } from '../../services/modal/modal.service';
import { NumberInputModalComponent } from '../number-input-modal/number-input-modal.component';

interface NumberDialogOptions {
  headingText: string;
  label: string;
  format?: NumberFormatOptions;
}

@Injectable({ providedIn: 'root' })
export class DialogService {

  public constructor(
    private readonly legacyModalService: AjsModalService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService
  ) { }

  public getConfirmationModal(options: ConfirmDialogOptions): Observable<unknown> {
    return from(
      this.getConfirmationModalPromise(options)
    ).pipe(catchError(() => EMPTY));
  }

  /** @deprecated prefer Observable version `getConfirmationModal` */
  public getConfirmationModalPromise(options: ConfirmDialogOptions): Promise<unknown> {
    if (options.translate) {
      if (options.title) {
        options.title = this.translateService.instant(options.title);
      }
      if (options.text) {
        options.text = this.translateService.instant(options.text);
      }
    }

    return this.legacyModalService.getConfirmationModal(options);
  }

  public getNumberInputDialog(options: NumberDialogOptions): Observable<number> {
    const modalRef = this.modalService.open(NumberInputModalComponent);
    const component = modalRef.componentInstance;
    component.headingText = options.headingText;
    component.label = options.label;
    component.format = options.format;
    return from(modalRef.result).pipe(catchError(() => EMPTY));
  }
}
