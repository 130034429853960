import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';

import {
  BuildingClass,
  FacilityClient,
  Locale,
  Property
} from '@enerkey/clients/facility';

import { ExtendedFacilityInformation } from '../interfaces/extended-facility-information';
import { FilterService } from '../../services/filter.service';
import { ProfileService } from './profile.service';
import { LegacyFacilityService } from '../../modules/reportingobjects/models/facilities';
import { LanguageChangeService } from './language-change.service';

@Injectable({ providedIn: 'root' })
export class FacilityService {
  public readonly profileFacilities$: Observable<ExtendedFacilityInformation[]>;
  public readonly filteredProfileFacilities$: Observable<ExtendedFacilityInformation[]>;
  public readonly filteredProfileFacilityIds$: Observable<number[]>;

  public readonly locales$: Observable<Locale[]>;
  public readonly localeNames$: Observable<Record<number, string>>;
  public readonly buildingClasses$: Observable<BuildingClass[]>;
  public readonly allProperties$: Observable<Property[]>;

  /**
   * Facility ids to be used with search.
   * Contains ids of filtered facilities if filters are in use, otherwise undefined.
   */
  public readonly facilityIdsForSearch$: Observable<number[]>;

  private readonly profileChangeInProgress$ = new BehaviorSubject(false);

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly profileService: ProfileService,
    private readonly facilities: LegacyFacilityService,
    private readonly filterService: FilterService,
    private readonly languageChangeService: LanguageChangeService
  ) {
    this.facilityIdsForSearch$ = combineLatest([
      this.filterService.filteredFacilityIds$,
      this.filterService.isFiltered$
    ]).pipe(
      map(
        ([facilityIds, isFiltered]) => isFiltered
          ? facilityIds
          : undefined
      ),
      shareReplay(1)
    );

    this.profileFacilities$ = this.profileService.profile$.pipe(
      tap(() => {
        this.profileChangeInProgress$.next(true);
      }),
      switchMap(() => this.getProfileFacilities()),
      tap(() => {
        this.profileChangeInProgress$.next(false);
      }),
      shareReplay(1),
      filter(() => !this.profileChangeInProgress$.value)
    );

    this.filteredProfileFacilities$ = combineLatest([
      this.filterService.filteredFacilityIds$,
      this.profileFacilities$
    ]).pipe(
      map(
        ([facilityIds, profileFacilities]) => facilityIds
          ? profileFacilities.filter(facility => facilityIds.includes(facility.facilityId))
          : profileFacilities
      ),
      shareReplay(1)
    );

    this.filteredProfileFacilityIds$ = this.filteredProfileFacilities$.pipe(
      map(fs => fs.map(f => f.facilityId)),
      shareReplay(1)
    );

    this.locales$ = this.facilityClient.getLocales().pipe(shareReplay(1));
    this.localeNames$ = this.locales$.pipe(
      map(locales => locales.toRecord(x => x.id, x => x.timeZone)),
      shareReplay(1)
    );

    this.buildingClasses$ = this.languageChangeService.languageChange.pipe(
      startWith(null),
      switchMap(() => this.facilityClient.getBuildingClasses()),
      shareReplay(1)
    );

    this.allProperties$ = this.facilityClient.getProperties().pipe(
      shareReplay(1)
    );
  }

  public getProfileFacilities(): Observable<ExtendedFacilityInformation[]> {
    return from(this.facilities.getFacilities());
  }

  public getFacilityById(id: number): Observable<ExtendedFacilityInformation> {
    return from(this.facilities.getFacility(id));
  }
}
