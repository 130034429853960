const $inject = ['utils'];

class EmInputNumberController {
  constructor(utils) {
    this.utils = utils;
    this.touched = false;
  }

  $onInit() {
    this.type = this.config.integer ? 'number' : 'text';
    this.model = this.config.initialValue;
    this.placeholder = this.config.initialValueVaries
      ? this.utils.localizedString('ACTIONS.MASS_EDIT.VARIES')
      : '';
    this.config.isValid = true;
  }

  onBlur() {
    this.touched = true;
    this.config.isValid = !this.isRequired() && this.isValid();
  }

  onInputChange() {
    this.touched = true;

    if (this.model === null || this.model === '') {
      this.value = null;
    } else {
      const parsedValue = Number(this.model);
      this.value = Number.isNaN(parsedValue) ? undefined : parsedValue;
    }

    this.config.isValid = !this.isRequired() && this.isValid();
    this.onChange(this.config, this.value);
  }

  isRequired() {
    return this.config.mandatory && (this.value !== 0 && !this.value);
  }

  isValid() {
    // undefined -> field not empty nor a number
    if (this.value === undefined) {
      return false;
    }
    if (!this.config.mandatory && this.value === null) {
      return true;
    }

    let minOk = true;
    let maxOk = true;
    if (angular.isDefined(this.config.min)) {
      minOk = this.value >= this.config.min;
    }
    if (angular.isDefined(this.config.max)) {
      maxOk = this.value <= this.config.max;
    }
    return minOk && maxOk;
  }
}

EmInputNumberController.$inject = $inject;

export default EmInputNumberController;
