/**
   Don't try to load a custom css theme in these urls
 */
export const noCustomThemeUrls = [
  'localhost',
  'portal',
  'portal-dev',
  'portal-test',
  'portal-beta',
  'portal-sandbox',
  'portal-sandbox2',
  'testing',
  'testing-dev',
  'testing-test'
];
