import MetersReportComparisonController from './meters-report-comparison.controller';
import MetersReportComparisonTemplate from 'raw-loader!./meters-report-comparison.html';

export default {
  template: MetersReportComparisonTemplate,
  controller: MetersReportComparisonController,
  controllerAs: 'vm',
  bindings: {
    reportParams: '<',
    cache: '<'
  }
};
