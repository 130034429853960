import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { StateLocationService } from '../../services/state-location.service';

@Component({
  selector: 'facilities-state-button',
  templateUrl: './facilities-state-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilitiesStateButtonComponent {
  @Input() public items: { facilityId?: number }[] = [];

  public constructor(
    private readonly stateLocationService: StateLocationService
  ) {
  }

  public goToFacilities(): void {
    const facilityIds = this.items.unique('facilityId');
    this.stateLocationService.openStateInNewTab('facilities.grid', {
      facilityId: facilityIds,
      gridState: {
        filter: {
          field: 'checked',
          operator: 'eq',
          value: true
        }
      }
    });
  }
}
