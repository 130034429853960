import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate } from '@enerkey/rxjs';

import { FacilityClient, ProfileFacilityConnectParameters } from '@enerkey/clients/facility';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  templateUrl: './facility-profiles-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityProfilesModalComponent extends ModalBase<void> implements OnDestroy {
  public facilityIds: number[] = [];

  public readonly profileField: UntypedFormControl = new UntypedFormControl(null);
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new Subject<boolean>();

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly toasterService: ToasterService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public addToProfile(): void {
    this.facilityClient.addFacilitiesForProfiles(
      new ProfileFacilityConnectParameters({
        facilityIds: this.facilityIds,
        profileIds: this.profileField.value
      })
    ).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('ADMIN.FACILITIES_PROFILES.LINK_SUCCESSFUL');
        super.closeModal();
      },
      error: () => this.toasterService.error('ADMIN.FACILITIES_PROFILES.LINK_FAILED')
    });
  }

  public removeFromProfile(): void {
    this.facilityClient.removeFacilitiesFromProfiles(
      new ProfileFacilityConnectParameters({
        facilityIds: this.facilityIds,
        profileIds: this.profileField.value
      })
    ).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: () => {
        this.toasterService.success('ADMIN.FACILITIES_PROFILES.UNLINK_SUCCESSFUL');
        super.closeModal();
      },
      error: () => {
        this.toasterService.error('ADMIN.FACILITIES_PROFILES.UNLINK_FAILED');
      }
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
