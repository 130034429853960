<div class="simple-report-timeframe-selection">
  <ek-dropdown
    *ngIf="selectedTimeFrame$ | async as selectedTimeFrame"
    position="left"
  >
    <button
      class="button button--link button--secondary button--no-focus button--compact"
      *ekDropdownToggle="let toggle"
      (click)="toggle()"
    >
      {{ selectedTimeFrame.text }}
      <i class="fa fa-chevron-down"></i>
    </button>
    <ek-dropdown-item
      *ngFor="let selection of timeFrameSelections$ | async"
      (click)="setTimeFrame(selection)"
    >
      <button class="button button--link button--no-padding">{{ selection.text }}</button>
      &nbsp;
      <i
        class="fa fa-check"
        *ngIf="selectedTimeFrame === selection"
      ></i>
    </ek-dropdown-item>
  </ek-dropdown>
</div>
<kendo-grid
  [kendoGridBinding]="consumptions$ | async"
  [sortable]="true"
  [pageSize]="50"
  [pageable]="pagerSettings$ | async"
  [filterable]="false"
  [groupable]="false"
  [selectable]="false"
  [resizable]="false"
  [sort]="sort"
  [loading]="loading$ | async"
  class="simple-table-report"
>
  <ng-template kendoGridNoRecordsTemplate></ng-template>
  <kendo-grid-column
    [field]="facilityNameKey"
    [title]="'FACILITIES.FACILITY' | translate"
    [class]="{ 'simple-report-cell': true, 'simple-report-allow-overflow': (isMobileView$ | async) }"
    [headerClass]="'simple-report-header-name-cell'"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="openModal(dataItem)">{{ dataItem[facilityNameKey] }}</a>
      <br/>
      <span class="real-estate-id-container">{{ dataItem.realEstateId || '-' }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngFor="let quantityId of quantityIds"
    [title]="quantityId | quantityName | async"
    [field]="valuesKey + '.' + quantityId + '.' + changeKey"
    [format]="'#,#'"
    [width]="quantityColumnWidth$ | async"
    [headerClass]="'simple-report-header-cell'"
    [class]="['simple-report-cell', 'simple-report-value-cell']"
  >
    <ng-template kendoGridHeaderTemplate>
      <simple-report-header [quantityId]="quantityId"></simple-report-header>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span
        [hidden]="isMobileView$ | async"
        [class.k-grid-cell-Incomplete]="dataItem[valuesKey][quantityId][isIncompleteKey]"
      >
        {{ dataItem[valuesKey][quantityId][valueKey] | consumptionValue }}
      </span>
      <br/>
      <div
        [ngClass]="{ 'bad': dataItem[valuesKey][quantityId][changeKey] > 0, 'good': dataItem[valuesKey][quantityId][changeKey] < 0 }"
        class="simple-report-change-value"
      >
        {{ dataItem[valuesKey][quantityId][changeKey] | consumptionPercent : 1 }}
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
