function ToggleGroupController() {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this;

  this.onClick = function(button) {
    if (button.active) {
      return;
    } else {
      this.buttons.forEach(btn => {
        btn.active = false;
      });

      button.active = true;
    }

    vm.onToggle({ button: button });
  };
}

export default ToggleGroupController;
