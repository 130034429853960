export class NotImplementedError extends Error {
  public override name = 'NotImplementedError';

  public constructor(message: string) {
    super(message);

    // Set the prototype explicitly so error type can be detected with instanceof
    Object.setPrototypeOf(this, NotImplementedError.prototype);
  }
}
