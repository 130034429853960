import { ToasterType } from '../../../constants/toaster-type';

const $inject = [
  '$modalInstance', 'utils', 'configurationControlApi', 'PropertyService',
  'configurationService'
];

function ConfigurationEditController(
  $modalInstance, utils, configurationControlApi, PropertyService,
  configurationService
) {
  /* eslint-disable-next-line */
  const vm = this;
  vm.readerTypes = [];
  vm.readerType = {};
  vm.readerBaseProperties = [];
  vm.readerSpecificProperties = [];
  vm.readerTypeSelected = false;
  vm.initialCron = '* * * * *';
  vm.excludedInputs = ['terminalName', 'terminalId'];

  vm.save = function() {
    configurationService.save(vm.readerType.id, vm.readerBaseProperties, vm.readerSpecificProperties)
      .then(() => {
        utils.popUp(ToasterType.INFO, utils.localizedString('CONFIGURATION_CONTROL.INFO_TEXT_CONFIGURATION_SAVED'));
        $modalInstance.close({ saved: true });
      })
      .catch(errResponse => {
        utils.popUp(ToasterType.ERROR,
          utils.localizedString('CONFIGURATION_CONTROL.ERROR_TEXT_POST_CONFIGURATION_FAIL'));
        utils.trackError('ConfigurationControl - Failed to post new configuration', {
          status: errResponse.status,
          message: errResponse.data
        });
      });
  };

  vm.cancel = function() {
    $modalInstance.dismiss();
  };

  function cleanReaderProperties() {
    vm.readerBaseProperties = [];
    vm.readerSpecificProperties = [];
  }

  vm.onReaderTypeSelected = function(readerType) {
    vm.readerType = readerType;
    if (readerType.id > 0) {
      vm.readerTypeSelected = true;
      cleanReaderProperties();
      configurationControlApi.getReaderSchema(readerType.id)
        .then(response => {
          const schema = response.data;
          vm.readerBaseProperties = PropertyService.getReaderBaseProperties(schema);
          vm.readerSpecificProperties = PropertyService.getReaderSpecificProperties(schema);

          // Initialize cron value to matching property
          const cron = vm.readerBaseProperties.find(property => (property.name.localeCompare('cronExpression') === 0));
          cron.value = vm.initialCron;
        })
        .catch(() => {
          utils.popUp(
            ToasterType.ERROR,
            'CONFIGURATION_CONTROL.ERROR_TEXT_GET_SCHEMA_FAIL',
            null,
            true
          );
        });
    } else {
      vm.readerTypeSelected = false;
    }
  };

  function getReaderTypes() {
    configurationControlApi.getReaderTypes()
      .then(response => {
        vm.readerTypes = response.data.sortBy('name');
      })
      .catch(() => {
        utils.popUp(ToasterType.ERROR, utils.localizedString('CONFIGURATION_CONTROL.ERROR_TEXT_GET_READERTYPES_FAIL'));
      });
  }

  getReaderTypes();
}

ConfigurationEditController.$inject = $inject;

export default ConfigurationEditController;

