<div *ngIf="serviceEnabled; else contactSales">
  <div *ngIf="facilityConnection$ | async as facilityConnection">
    <div class="flex-container">
      <div>
        <label>{{ 'FACILITY.EDIT_FORM.LP_USE_ADDRESS' | translate }}</label>
        <kendo-switch
          [(ngModel)]="usesAddress"
          (valueChange)="onUseAddress($event, facilityId)"
        >
        </kendo-switch>
      </div>
      <div>
        <label>{{ 'FACILITY.EDIT_FORM.LP_USE_COMPANY' | translate }}</label>
        <kendo-switch
          [(ngModel)]="usesCompany"
          (valueChange)="onUseCompany($event, facilityId)"
        >
        </kendo-switch>
        <span class="existing-company">{{ existingCompanyNetwork }}</span>
      </div>
    </div>
    <div *ngIf="usesCompany">
      <div>
        <form>
          <label>{{ 'FACILITY.EDIT_FORM.DISTRICT_HEATING_COMPANY' | translate }}</label>
          <input
            type="text"
            #searchString
          >
          <button
            type="submit"
            class="button button--primary"
            (click)="searchCompany(searchString.value)"
          >
            {{ 'FACILITY.EDIT_FORM.SEARCH_COMPANY' | translate }}
          </button>
        </form>
        <br />
        <div *ngIf="searchResults$ | async as searchResults">
          <div *ngFor="let company of searchResults">
            <h2>{{ company.companyName }}</h2>
            <table class="company-table">
              <thead>
                <th>{{ 'FACILITY.EDIT_FORM.NETWORK_NAME' | translate }}</th>
                <th>{{ 'FACILITY.EDIT_FORM.AVAILABLE_YEARS' | translate }}</th>
                <th><!--Button column--></th>
              </thead>
              <tbody>
                <tr *ngFor="let network of company.networks">
                  <td>
                    {{ network.name }}
                  </td>
                  <td>
                    {{ network.yearsAvailable }}
                  </td>
                  <td>
                    <form>
                      <button
                      type="input"
                      class="button button--primary"                 
                      (click)="useNetwork(company.companyName, network.name, network.networkId, facilityId)"
                      >
                        {{ 'FACILITY.EDIT_FORM.SELECT_ME' | translate }}
                      </button>
                    </form>                  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contactSales>
  {{ 'FACILITY.EDIT_FORM.PURCHASE_LOCALPOWER' | translate }}
</ng-template>