/**
 * Sorts quantities using local compare
 *
 * @param {Array} quantities
 *
 * @returns {Array}
 */
export const sortQuantities = quantities => (
  [...quantities].sort((quantityA, quantityB) => quantityA.name.localeCompare(quantityB.name))
);
