import template from 'raw-loader!./dropdown-menu-item-separator.html';

const DropdownMenuItemSeparatorComponent = {
  template: template,
  controllerAs: 'vm',
  require: {
    parent: '^dropdownMenu'
  }
};

export default DropdownMenuItemSeparatorComponent;
