import ModalViewHeaderController from './modal-view-header-controller';
import template from 'raw-loader!./modal-view-header.html';

const ModalViewHeaderComponent = {
  transclude: true,
  template: template,
  bindings: {
    headingText: '<',
    additionalClass: '@',
    alignDynamicsRight: '<',
    onClose: '&?'
  },
  controller: ModalViewHeaderController
};

export default ModalViewHeaderComponent;
