import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, map, Observable, shareReplay, Subject, switchMap, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';

import { ReportSummary, SustainabilityClient } from '@enerkey/clients/sustainability';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ProfileService } from '../../../../shared/services/profile.service';

@Component({
  selector: 'target-report-select',
  templateUrl: './target-report-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TargetReportSelectComponent implements OnDestroy, OnInit {

  public readonly reportSelect = new FormControl<number>(null);
  public readonly multiReportSelect = new FormControl<number[]>(null);
  public readonly reports$: Observable<ReportSummary[]>;
  public readonly reportItems$: Observable<ComboItem<number>[]>;

  @Input() public multiSelect: boolean = false;
  @Input() public reportId: number;
  @Input() public yearRange?: { min: number, max: number } = { min: 1900, max: 3000 };

  @Output() public readonly selectedReportChange = new EventEmitter<ReportSummary>();
  @Output() public readonly multiReportSelectionChange = new EventEmitter<ReportSummary[]>();

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly profileService: ProfileService,
    private readonly susClient: SustainabilityClient
  ) {
    this.reports$ = this.profileService.profileId$.pipe(
      switchMap(profileId => this.susClient.getReportSummaries(profileId)),
      map(reports => reports
        .filter(r => r.year >= this.yearRange.min && r.year <= this.yearRange.max)
        .sortByMany(['year', 'desc'], 'description')),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.reportItems$ = this.reports$.pipe(
      map(reports => reports.map<ComboItem<number>>(
        report => ({ text: `${report.description ?? ''} (${report.year})`, value: report.id })
      ))
    );
  }

  public ngOnInit(): void {
    if (this.reportId) {
      this.reportSelect.patchValue(this.reportId);
    }

    if (this.multiSelect) {
      combineLatest([this.reports$, this.multiReportSelect.valueChanges]).pipe(
        takeUntil(this._destroy$)
      ).subscribe(([reports, ids]) => {
        const selectedReports: ReportSummary[] = [];
        for (const id of ids) {
          const report = reports.find(r => r.id === id);
          selectedReports.push(report);
        }
        this.multiReportSelectionChange.emit(selectedReports);
      });
    } else {
      combineLatest([this.reports$, this.reportSelect.valueChanges]).pipe(
        takeUntil(this._destroy$)
      ).subscribe(([reports, id]) => {
        const report = reports.find(r => r.id === id);
        this.selectedReportChange.emit(report);
      });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
