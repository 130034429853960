<modal-view-header
  *ngIf="scope === ServiceScopeType.Facility"
  [headingText]="('ADMIN.BULK_MODIFY_FACILITIES.ADD_TO_SUBSCRIPTION' | translate) + ' (' +
    ('FACILITIES_REPORT.COUNT_OF_FACILITIES' | translate: { count: this.selectedItems.length }) + ')'"
></modal-view-header>
<modal-view-header
  *ngIf="scope === ServiceScopeType.Meter"
  [headingText]="('ADMIN.BULK_MODIFY_METERS.ADD_TO_SUBSCRIPTION' | translate) + ' (' +
    ('FACILITIES_REPORT.COUNT_OF_METERS' | translate: { count: this.selectedItems.length }) + ')'"
></modal-view-header>
<div
  class="modal-body"
  [loadingSpinner]="loading$ | async"
  [loadingOverlay]="true"
>
  <form
    id="subscriptionForm"
    [formGroup]="subscriptionForm"
    (submit)="scope === ServiceScopeType.Facility ? submitForFacility() : submitForMeter()"
  >
    <div class="row">
      <div class="large-12 columns">
        <company-dropdown
          [formControl]="companyField"
        ></company-dropdown>
      </div>
    </div>
    <div class="row">
      <div class="large-12 columns">
        <ek-combo
          [loading]="loading$ | async"
          [items]="subscriptions$ | async"
          formControlName="subscription"
          *labelBefore="'ADMIN.SUBSCRIPTION' | translate"
        ></ek-combo>
      </div>
    </div>
    <div class="row">
      <div class="large-12 columns">
        <ng-container *ngIf="scope === ServiceScopeType.Facility">
          <ek-combo
            [items]="services$ | async"
            formControlName="service"
            *labelBefore="'ADMIN.SERVICES' | translate"
          ></ek-combo>
        </ng-container>
        <ng-container *ngIf="scope === ServiceScopeType.Meter">
          <ek-multi
            [items]="services$ | async"
            formControlName="services"
            *labelBefore="'ADMIN.SERVICES' | translate"
          ></ek-multi>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="large-6 columns">
        <kendo-datepicker
          formControlName="startDate"
          [max]="subscriptionForm.controls.endDate.value"
          *labelBefore="'ADMIN.CONTRACTS.FROM_DATE' | translate"
        ></kendo-datepicker>
      </div>
      <div class="large-6 columns float-left">
        <kendo-datepicker
          formControlName="endDate"
          [min]="subscriptionForm.controls.startDate.value"
          *labelBefore="'ADMIN.CONTRACTS.TO_DATE' | translate"
        ></kendo-datepicker>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="large-12 columns">
      <kendo-grid
        [kendoGridBinding]="selectedItems"
        *labelBefore="(scope === ServiceScopeType.Facility
          ? 'ADMIN.FACILITIES'
          : 'ADMIN.METERS_MASS_TOOLS') | translate"
      >
        <kendo-grid-column
          field="id"
          [width]="80"
          [title]="'ADMIN.ID' | translate"
        ></kendo-grid-column>
        <kendo-grid-column
          field="name"
          [title]="'ADMIN.NAME' | translate"
        ></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="subscriptionForm"
      [loading]="loading$ | async"
      [disabled]="!subscriptionForm.valid"
    >
      {{ 'SUBMIT' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
