import '../polyfills';
import '../vendor';
import '../config/env';

import '@enerkey/extensions';
import './extensions/swagger';

import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UIRouter, UrlService } from '@uirouter/core';

import { AppModule } from './app.module';
import AppModuleAngularJS from './app.module.ajs';

if (!['development', 'testing'].includes(ENERKEY_CONFIG.env) && !/localhost/.test(document.location.host)) {
  enableProdMode();
}

AppModuleAngularJS.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);

platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
  const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;

  // Instruct UIRouter to listen to URL changes
  function startUIRouter(): void {
    urlService.listen();
    urlService.sync();
  }

  platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
});
