import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { EnumTranslatePipe } from '@enerkey/ek-common';
import { ActionType } from '@enerkey/clients/attachments';

import { actionTypeTranslations } from '../../../modules/energy-management/constants/em-action-types';

@Pipe({ name: 'actionTypeName' })
export class ActionTypeNamePipe extends EnumTranslatePipe<ActionType> implements PipeTransform {
  protected readonly translations: Record<ActionType, string> = actionTypeTranslations;

  public constructor(translateService: TranslateService) {
    super(translateService);
  }
}
