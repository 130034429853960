<ng-template
  kendoGridFilterMenuTemplate
  let-column="column"
  let-filter="filter"
  let-filterService="filterService"
>
  <kendo-grid-numeric-filter-menu
    [filter]="filter"
    [column]="column"
    [filterService]="filterService"
    [operator]="'gte'"
  >
    <kendo-filter-gte-operator></kendo-filter-gte-operator>
    <kendo-filter-gt-operator></kendo-filter-gt-operator>
    <kendo-filter-lte-operator></kendo-filter-lte-operator>
    <kendo-filter-lt-operator></kendo-filter-lt-operator>
    <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
    <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
  </kendo-grid-numeric-filter-menu>
</ng-template>

<ng-template
  kendoGridGroupFooterTemplate
  let-column="column"
  let-aggregates
>
  <reporting-grid-column-footer
    *ngIf="format"
    [aggregates]="aggregates"
    [field]="column.field"
    [format]="format"
    [visibleAggregates]="['sum']"
    [modalReportType]="modalReportType"
  ></reporting-grid-column-footer>
</ng-template>

<ng-template
  kendoGridHeaderTemplate
  let-column="column"
>
  <report-column-header
    [title]="column.title"
    [color]="color"
  ></report-column-header>
</ng-template>

<ng-template
  kendoGridFooterTemplate
  let-column="column"
  *ngIf="format"
>
  <reporting-grid-column-footer
    [aggregates]="total"
    [field]="column.field"
    [format]="format"
    [visibleAggregates]="visibleAggregates"
    [modalReportType]="modalReportType"
  ></reporting-grid-column-footer>
</ng-template>

<ng-template
  kendoGridCellTemplate
  let-dataItem
  let-column="column"
>
  <ng-container *ngIf="dataItem | propertyPath:fieldStart as consumption">
    <value-flag-cell
      [consumption]="consumption"
      [visibleValue]="dataItem | propertyPath:column.field"
      [format]="format"
    ></value-flag-cell>
  </ng-container>
</ng-template>
