import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { BulkModifyProfilesRequest, UserManagementClient } from '@enerkey/clients/user-management';

import { ToasterService } from '../../../../shared/services/toaster.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

type ProfileEditForm = {
  addProfiles: number[];
  removeProfiles: number[];
}

@Component({
  templateUrl: './user-profile-mass-edit-modal.component.html',
  styleUrls: ['./user-profile-mass-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileMassEditModalComponent extends ModalBase<void> implements OnDestroy {

  public selectedUsers: number[];
  public readonly massProfileEditForm: UntypedFormGroup;

  public readonly allProfiles$: Observable<ComboItem<number>[]>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject(false);

  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.massProfileEditForm = new UntypedFormGroup({
      addProfiles: new UntypedFormControl([]),
      removeProfiles: new UntypedFormControl([])
    });

    this.allProfiles$ = this.userManagementClient.getAllProfilesSimple().pipe(
      map(profiles => profiles.map((p): ComboItem<number> => ({ text: p.name, value: p.id }))),
      indicate(this._loading$)
    );
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public submit(): void {
    const data: ProfileEditForm = this.massProfileEditForm.value;
    this.dialogService.getConfirmationModal({
      text: 'ADMIN.BULK_MODIFY_PROFILES.SAVE_CONFIRMATION',
      title: 'ADMIN.BULK_MODIFY_PROFILES.SAVE_BUTTON',
      translate: true,
      isDelete: false
    }).subscribe(() => {
      this.userManagementClient.bulkModifyProfiles(
        new BulkModifyProfilesRequest({
          userIds: this.selectedUsers,
          profileIdsToAdd: data.addProfiles,
          profileIdsToRemove: data.removeProfiles
        })
      ).pipe(indicate(this._loading$)).subscribe({
        next: () => {
          this.toasterService.success('ADMIN.BULK_MODIFY_PROFILES.SAVE_SUCCESS');
          super.closeModal();
        },
        error: () => this.toasterService.error('ADMIN.BULK_MODIFY_PROFILES.SAVE_ERROR')
      });
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
