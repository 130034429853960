import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';

import { indicate } from '@enerkey/rxjs';

import { FacilityService } from '../../../../shared/services/facility.service';
import { ThresholdService } from '../../../../shared/services/threshold.service';
import { ForecastReportService } from '../../services/forecast-report.service';
import { ReportEventService } from '../../services/report-events.service';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportingSeriesByFacility } from '../../shared/reporting-series-collection';
import { ReportBase, ReportingGridConfig } from '../report-base';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { ReportingDistributionType } from '../../shared/reporting-search-form-value';
import { ReportType } from '../../shared/report-type';
import { ReportingGridService } from '../../services/reporting-grid.service';

@Component({
  selector: 'forecast-report',
  templateUrl: '../report-base.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForecastReportComponent extends ReportBase {
  public readonly consumptionData$: Observable<ReportingSeriesByFacility>;
  public readonly gridConfig$: Observable<Record<number, ReportingGridConfig>>;

  public override readonly modalReportType = ReportType.Forecast;

  protected readonly reportType = ReportType.Forecast;

  public constructor(
    private readonly forecastReportService: ForecastReportService,
    private readonly reportingGridService: ReportingGridService,
    private readonly thresholdService: ThresholdService,
    facilityService: FacilityService,
    reportingSearchService: ReportingSearchService,
    reportActionsService: ReportEventService
  ) {
    super(facilityService, reportingSearchService, reportActionsService);

    this.consumptionData$ = combineLatest([
      this.searchParams$,
      this.thresholdService.threshold$
    ]).pipe(
      withLatestFrom(this.facilityIds$),
      switchMap(([[params, threshold], facilityIds]) => this.forecastReportService.getForecastData(
        params, threshold, facilityIds
      ).pipe(
        indicate(this._loading$)
      )),
      takeUntil(this._destroy$),
      shareReplay(1)
    );

    this.gridConfig$ = this.consumptionData$.pipe(
      withLatestFrom(this.facilityIds$, this.searchParams$),
      map(([data, facilityIds, searchParams]) => {
        const gridColumns = this.reportingGridService.getColumns(data, facilityIds);
        const gridData = this.reportingGridService.getData(data, searchParams, facilityIds);
        return facilityIds.toRecord(
          fId => fId,
          fId => ({
            data: gridData[fId],
            columns: gridColumns[fId],
            aggregates: undefined,
          })
        );
      })
    );
  }

  protected override transformParams(params: ReportingSearchParams): ReportingSearchParams {
    return new ReportingSearchParams({
      ...params.formValue,
      distributionType: ReportingDistributionType.None,
    });
  }
}
