import template from 'raw-loader!./modal-content-item-actions.html';

const ModalContentItemActionsComponent = {
  template: template,
  transclude: true,
  require: {
    parent: '^modalContentItem'
  }
};

export default ModalContentItemActionsComponent;
