<column-tooltips>
  <kendo-treelist
    [kendoTreeListHierarchyBinding]="meters$ | async"
    [childrenField]="'children'"
    [selectable]="selectableSettings"
    [(selectedItems)]="selected"
    (selectionChange)="selectionChange()"
    [initiallyExpanded]="true"
    [pageable]="pagerSettings"
    [pageSize]="500"
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="filterChange($event)"
    [rowClass]="rowCallback"
    [loading]="loading$ | async"
    [resizable]="true"
    kendoTreeListSelectable
    kendoTreeListExpandable
    class="grid-full-height"
    #meterTreelist
  >
    <ng-template kendoTreeListNoRecordsTemplate>
    </ng-template>
    <ng-template kendoTreeListToolbarTemplate>
      <kendo-grid-grouped-column-chooser></kendo-grid-grouped-column-chooser>
      <ek-dropdown>
        <button
          *ekDropdownToggle="let toggle"
          (click)="toggle()"
          kendoButton
          [icon]="'excel'"
        >
          {{ 'ADMIN.GET_EXCEL' | translate }}
        </button>
        <ek-dropdown-item (click)="$any(fullExcel).save(meterTreelist)">
          {{ 'DOWNLOAD.FULL_REPORT' | translate }}
        </ek-dropdown-item>
        <ek-dropdown-item (click)="$any(flatExcel).save(meterTreelist)">
          {{ 'DOWNLOAD.FLATTENED_METER_REPORT' | translate }}
        </ek-dropdown-item>
      </ek-dropdown>
      <div class="k-separator"></div>
      <span *ngIf="visibleMeters$ | async as visibleMeters">
        {{ 'ADMIN.METER_SEARCH.METERS' | translate }}: {{ visibleMeters.length }}
      </span>
    </ng-template>
    <kendo-treelist-checkbox-column [width]="35" [checkChildren]="true" [showSelectAll]="true">
    </kendo-treelist-checkbox-column>
    <kendo-treelist-column
      [expandable]="true"
      field="hierarchyName"
      [width]="300"
      [class]="'meterTreelistHierarchyCell'"
      [title]="'ADMIN.SPREADSHEET.HIERARCHY.HEADING' | translate"
      [includeInChooser]="false"
    >
      <ng-template kendoTreeListCellTemplate let-dataItem>
        <i *ngIf="dataItem.isRelatedMeter" class="related-meter-marker fas fa-link"></i>
        {{ dataItem.hierarchyName }}
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column
      [width]="40"
    >
      <ng-template
        kendoTreeListCellTemplate
        let-dataItem
      >
        <button
          *ngIf="dataItem.meter"
          class="button button--link button--no-focus"
          (click)="openMeterEditModal(dataItem)"
          [style.margin-right.px]="5"
        >
          <i class="fas fa-pencil-alt"></i>
        </button>
        <button
          *ngIf="dataItem.meter"
          class="button button--no-padding button--link"
          (click)="editTags(dataItem)"
        >
          <i class="fas fa-tag"></i>
        </button>
      </ng-template>
    </kendo-treelist-column>
    <kendo-treelist-column-group [title]="'GROUPS.METER_INFORMATION' | translate">
      <kendo-treelist-column
        field="meter.quantityId"
        [width]="100"
        [class]="'meterTreelistQuantityCell'"
        [title]="'QUANTITY' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <span *ngIf="dataItem.meter?.quantityId">
            {{ dataItem.meter.quantityId | quantityName | async }}
          </span>
        </ng-template>
        <ng-template
          kendoTreeListFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-multiselect-filter
            [filter]="filter"
            [field]="column.field"
            [filterService]="filterService"
            [dataSource]="quantities$ | async"
            [textSelector]="getQuantityName"
            [rawValues]="true"
          >
          </grid-multiselect-filter>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.name"
        [width]="90"
        [hidden]="true"
        [title]="'METER_NAME' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="ordinal"
        [width]="50"
        filter="numeric"
        [hidden]="true"
        [title]="'ADMIN.SPREADSHEET.ORDINAL.HEADING' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="isFloatingMeter"
        [width]="50"
        filter="boolean"
        [title]="'ADMIN.SPREADSHEET.FLOATING.HEADING' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="isRelatedMeter"
        [width]="50"
        filter="boolean"
        [title]="'ADMIN.SPREADSHEET.RELATED.HEADING' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.factor"
        [width]="60"
        filter="numeric"
        [title]="'FACTOR' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.meteringType"
        [width]="60"
        filter="numeric"
        [title]="'METER_TYPE' | translate"
      >
        <meter-type
          *kendoTreeListCellTemplate="let dataItem; let column = column"
          [meterType]="dataItem | propertyPath:column.field"
          [showText]="false"
        ></meter-type>
        <ng-template
          kendoTreeListFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter
            [filter]="filter"
            [field]="column.field"
            [filterService]="filterService"
            [dataSource]="$any(meteringTypes)"
            [rawValues]="true"
          >
            <span *gridMultiFilterTemplate="let value = value">
              {{ value | meteringType }}
            </span>
          </grid-checkbox-filter>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.twoTimeMeasurement"
        [width]="50"
        filter="boolean"
        [hidden]="true"
        [title]="'ADMIN.SPREADSHEET.TWO_TIME_MEASUREMENT.HEADING' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.automaticReadingStartTime"
        [width]="72"
        format="d"
        filter="date"
        [title]="'AUTOMATIC_READING_START_TIME' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.automaticReadingEndTime"
        [width]="72"
        format="d"
        filter="date"
        [title]="'AUTOMATIC_READING_END_TIME' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.deactivationTime"
        [width]="72"
        format="d"
        filter="date"
        [hidden]="true"
        [title]="'DEACTIVATION_TIME' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.customerMeterIdentifier"
        [width]="80"
        [hidden]="true"
        [title]="'CUSTOMER_METER_IDENTIFIER' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.id"
        [width]="60"
        filter="numeric"
        [title]="'METER_ID' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.costMeter"
        [width]="60"
        filter="boolean"
        [hidden]="true"
        [title]="'ADMIN.SPREADSHEET.COST_METER.HEADING' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.costFactorSourceMeterId"
        [width]="60"
        filter="numeric"
        [hidden]="true"
        [title]="'ADMIN.METER.COST_FACTOR_SOURCE' | translate"
      >
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.linkMeterFixedCosts"
        [width]="70"
        filter="boolean"
        [hidden]="true"
        [title]="'ADMIN.SPREADSHEET.LINK_METER_FIXED_COSTS.HEADING' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.qualityAssurance"
        [width]="70"
        filter="boolean"
        [hidden]="true"
        [title]="'ADMIN.SPREADSHEET.QUALITY_ASSURANCE.HEADING' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.automaticModeling"
        [width]="70"
        filter="boolean"
        [hidden]="true"
        [title]="'ADMIN.SPREADSHEET.AUTOMATIC_MODELING.HEADING' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.terminal"
        [width]="100"
        [title]="'ADMIN.METER_SEARCH.READER_TERMINAL' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.usagePlaceNumber"
        [width]="250"
        [hidden]="true"
        [title]="'USAGE_PLACE_NUMBER' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.energyCompanyUsagePlaceNumber"
        [width]="250"
        [hidden]="true"
        [title]="'ADMIN.METER.ENERGY_COMPANY_USAGE_PLACE_NUMBER' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.eanCode"
        [width]="54"
        [title]="'EAN_CODE' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.protocolCode"
        [width]="120"
        [title]="'PROTOCOL_CODE' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.resolution"
        [width]="80"
        [hidden]="true"
        [title]="'ADMIN.METER.RESOLUTION' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.createdBy"
        [width]="80"
        [hidden]="true"
        filter="numeric"
        [title]="'CREATED_BY' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.created"
        [width]="80"
        [hidden]="true"
        format="d"
        filter="date"
        [title]="'CREATED' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.lastModifiedBy"
        [width]="80"
        [hidden]="true"
        filter="numeric"
        [title]="'LAST_MODIFIED_BY' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="meter.lastModified"
        [width]="80"
        [hidden]="true"
        format="d"
        filter="date"
        [title]="'LAST_MODIFIED' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column-group [title]="'ADMIN.SPREADSHEET.LATESTCOSTFACTORS' | translate">
        <kendo-treelist-column
          field="meter.lastConsumptionCostFactor.fromDate"
          [width]="80"
          [hidden]="true"
          format="d"
          filter="date"
          [title]="'ADMIN.SPREADSHEET.LATESTCONSUMPTIONCOSTFACTOR.FROMDATE' | translate"
        ></kendo-treelist-column>
        <kendo-treelist-column
          field="meter.lastConsumptionCostFactor.monthlyFeePerDay"
          [width]="80"
          [hidden]="true"
          filter="numeric"
          [title]="'ADMIN.SPREADSHEET.LATESTCONSUMPTIONCOSTFACTOR.MONTHLYPRICE' | translate"
        ></kendo-treelist-column>
        <kendo-treelist-column
          field="meter.lastConsumptionCostFactor.price"
          [width]="80"
          [hidden]="true"
          filter="numeric"
          [title]="'ADMIN.SPREADSHEET.LATESTCONSUMPTIONCOSTFACTOR.PRICE' | translate"
        ></kendo-treelist-column>
        <kendo-treelist-column
          field="meter.lastDistributionCostFactor.fromDate"
          [width]="80"
          [hidden]="true"
          format="d"
          filter="date"
          [title]="'ADMIN.SPREADSHEET.LATESTDISTRIBUTIONCOSTFACTOR.FROMDATE' | translate"
        ></kendo-treelist-column>
        <kendo-treelist-column
          field="meter.lastDistributionCostFactor.monthlyFeePerDay"
          [width]="80"
          [hidden]="true"
          filter="numeric"
          [title]="'ADMIN.SPREADSHEET.LATESTDISTRIBUTIONCOSTFACTOR.MONTHLYPRICE' | translate"
        ></kendo-treelist-column>
        <kendo-treelist-column
          field="meter.lastDistributionCostFactor.price"
          [width]="80"
          [hidden]="true"
          filter="numeric"
          [title]="'ADMIN.SPREADSHEET.LATESTDISTRIBUTIONCOSTFACTOR.PRICE' | translate"
        ></kendo-treelist-column>
      </kendo-treelist-column-group>
    </kendo-treelist-column-group>
      <kendo-treelist-column-group
      *ngIf="(uniqueTags$ | async) as uniqueTags"
        [title]="'TAGS.TITLE' | translate"
      >
        <ng-container *ngFor="let tag of uniqueTags">
          <kendo-treelist-column
            [width]="120"
            filter="boolean"
            [field]="'tags.'+tag.id"
            [title]="tag.tagName"
            [hidden]="true"
          >
            <ng-template
              kendoTreeListCellTemplate
              let-dataItem
              let-column="column"
            >
              <boolean-mark [value]="dataItem | propertyPath: column?.field"></boolean-mark>
            </ng-template>
          </kendo-treelist-column>
        </ng-container>
      </kendo-treelist-column-group>
    <kendo-treelist-column-group [title]="'GROUPS.FACILITYINFORMATION' | translate">
      <kendo-treelist-column
        field="facility.description"
        [width]="150"
        [hidden]="true"
        [title]="'ADMIN.SPREADSHEET.METER_DESCRIPTION.HEADING' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.enegiaId"
        [width]="60"
        [hidden]="true"
        filter="numeric"
        [title]="'ENEGIA_ID' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.id"
        [width]="60"
        [hidden]="true"
        filter="numeric"
        [title]="'FACILITY_ID' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.displayName"
        [width]="150"
        [hidden]="true"
        [title]="'FACILITY_NAME' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.addresses.0.streetAddress"
        [width]="150"
        [hidden]="true"
        [title]="'FACILITYINFORMATION.STREETADDRESS' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.addresses.0.city"
        [width]="80"
        [hidden]="true"
        [title]="'FACILITYINFORMATION.CITY' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.addresses.0.postalCode"
        [width]="80"
        [hidden]="true"
        [title]="'FACILITYINFORMATION.POSTALCODE' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.addresses.0.country"
        [width]="80"
        [hidden]="true"
        [title]="'FACILITY.EDIT_FORM.COUNTRY' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="facility.businessIdentityCodeId"
        [width]="80"
        [hidden]="true"
        [title]="'BUSINESS_IDENTITY_CODE' | translate"
      ></kendo-treelist-column>
    </kendo-treelist-column-group>
    <kendo-treelist-column-group [title]="'ADMIN.POA.POA' | translate">
      <kendo-treelist-column
        field="poa.companyName"
        [width]="80"
        [hidden]="true"
        [title]="'CONTACT_MANAGER.COMPANY_NAME' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="poa.companyOvt"
        [width]="80"
        [hidden]="true"
        [title]="'ADMIN.POA.COMPANY_OVT' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="poa.name"
        [width]="80"
        [hidden]="true"
        [title]="'ADMIN.POA.POA_NAME' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="poa.externalLocationLink"
        [width]="80"
        [hidden]="true"
        [title]="'ADMIN.POA.EXTERNAL_LOCATION_LINK' | translate"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
          <a
            [attr.href]="dataItem | propertyPath:column.field"
            target="_blank"
          >
            {{ dataItem | propertyPath:column.field }}
          </a>
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="poa.powerOfAttorneyId"
        [width]="80"
        [hidden]="true"
        [title]="'ADMIN.POA.POA_ID' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="poa.validFrom"
        [width]="80"
        format="d"
        filter="date"
        [hidden]="true"
        [title]="'ADMIN.POA.VALID_FROM' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="poa.validTo"
        [width]="80"
        format="d"
        filter="date"
        [hidden]="true"
        [title]="'ADMIN.POA.VALID_TO' | translate"
      ></kendo-treelist-column>
      <kendo-treelist-column
        field="poa.externalService"
        [title]="'ADMIN.POA.EXTERNAL_SERVICE' | translate"
        [width]="200"
        [hidden]="true"
      >
        <ng-template
          kendoTreeListFilterMenuTemplate
          let-column="column"
          let-filter="filter"
          let-filterService="filterService"
        >
          <grid-checkbox-filter
            [filter]="filter"
            [field]="column.field"
            [filterService]="filterService"
            [dataSource]="poaServices"
            [rawValues]="true"
          >
            <span *gridMultiFilterTemplate="let value = value">
              {{ value | datahubServiceName }}
            </span>
          </grid-checkbox-filter>
        </ng-template>
        <ng-template
          kendoTreeListCellTemplate
          let-dataItem="dataItem"
          let-column="column"
        >
          {{ dataItem | propertyPath:column.field | datahubServiceName }}
        </ng-template>
      </kendo-treelist-column>
      <kendo-treelist-column
        field="meter.dataHubCustomerAuthorizationCode"
        [width]="80"
        [hidden]="true"
        [title]="'ADMIN.POA.AUTHORIZATION_STATUS' | translate"
      ></kendo-treelist-column>
    </kendo-treelist-column-group>
    <kendo-treelist-column-group [title]="'DROPDOWN.ACTIONS' | translate">
      <kendo-treelist-column
        [title]="'ADMIN.METER_SEARCH.LINKS_HEADING' | translate"
        [width]="80"
      >
        <ng-template
          kendoTreeListCellTemplate
          let-dataItem
        >
          <div
            *ngIf="dataItem.meter"
            class="meter-treelist-links"
          >
            <a
              [title]="'CONFIGURATION_CONTROL.TOPBAR_MENUITEM' | translate"
              (click)="ccPopUp(dataItem)"
            >
              CC
            </a>
            <a
              [title]="'MQA.TITLE' | translate"
              (click)="goToMQA(dataItem)"
            >
              QA
            </a>
            <a
              [title]="'ADMIN.VIRTUAL_METERS.EDITOR.METERS_REPORT' | translate"
              (click)="goToFacilityMeterReport(dataItem)"
            >
              MR
            </a>
            <a
              *ngIf="dataItem.meter.terminal"
              [title]="'ADMIN.TERMINAL.TERMINAL' | translate"
              (click)="goToTerminal(dataItem)"
            >
              T
            </a>
          </div>
        </ng-template>
      </kendo-treelist-column>
    </kendo-treelist-column-group>
    <kendo-treelist-excel fileName="meters.xlsx" #fullExcel>
    </kendo-treelist-excel>
    <kendo-treelist-excel
      #flatExcel
      [fetchData]="$any(fetchExcelData)"
      fileName="meters-flat.xlsx"
    >
      <kendo-excelexport-column
        *ngFor="let column of flatExportFields"
        [field]="column.field"
        [title]="column.title | translate"
      ></kendo-excelexport-column>
    </kendo-treelist-excel>
  </kendo-treelist>
</column-tooltips>
