// If you fix a bug here, please check if divFullHeight needs fixing too.
gridFullheight.$inject = ['$parse', '$timeout', '_', 'appStatusService'];

function gridFullheight($parse, $timeout, _, appStatusService) {

  var getModalBodyCSSVal = function(el, css) {
    var modalBody = el.closest('.reveal .modal-body');
    return parseFloat(modalBody.css(css) || 0);
  };

  return {
    restrict: 'A',
    scope: false,
    link: function(scope, element, attrs) {
      var page = appStatusService.inModal ? element.closest('.reveal') : angular.element(window);
      var pagePaddingBottom = appStatusService.inModal ? getModalBodyCSSVal(element, 'padding-bottom') : 0;
      var minHeight = attrs.gridMinHeight ? parseInt(attrs.gridMinHeight, 10) : 250;
      var offset = attrs.gridOffset ? parseInt(attrs.gridOffset, 10) : 0;

      var getter = $parse(attrs.gridFullHeight);
      var setter = getter.assign;
      var footerHeight = (appStatusService.inModal ? 0 : angular.element('footer').height()) || 0;
      var elementOffset;
      var listener;

      function getNewGridHeight() {
        // getClientRects doesn't exist on window, only on element
        var pageOffset = page.getClientRects ? page.offset().top : 0;

        return elementOffset ?
          Math.max(
            page.height() + pageOffset - pagePaddingBottom - elementOffset - footerHeight - offset,
            minHeight
          ) :
          getter(scope);
      }

      function getElementOffset() {
        return element[0].getBoundingClientRect().top;
      }

      var setNewHeight = function() {
        $timeout(function() {
          setter(scope, getNewGridHeight());
          if (angular.isFunction(listener)) {
            listener();
            listener = void 0;
          }
        });
      };

      var debouncedSetHeight = _.debounce(setNewHeight, 250);

      var windowResizeHandler = function() {
        var oldGridHeight = getter(scope);
        var newGridHeight = getNewGridHeight();
        if (oldGridHeight !== newGridHeight) {
          debouncedSetHeight();
        }
      };

      angular.element(window).on('resize', windowResizeHandler);

      scope.$on('$destroy', function() {
        angular.element(window).off('resize', windowResizeHandler);
        setter(scope, void 0);
        element.remove();
      });

      listener = scope.$watch(
        function() {
          elementOffset = getElementOffset();
          return elementOffset;
        },
        function(newValue) {
          if (!getter(scope)) {
            if (newValue) {
              debouncedSetHeight();
            } else {
              debouncedSetHeight.cancel();
            }
          }
        }
      );
    }
  };

}

export default gridFullheight;
