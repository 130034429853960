import EnerKeyServicesModule from '../../services';
import DonutVisualComponent from './donut-visuals-component';
import DonutVisualsService  from './donut-visuals-service';

const DonutVisualsModule = angular
  .module('app.components.donut-visuals', [
    EnerKeyServicesModule.name
  ])
  .component('donutVisuals', DonutVisualComponent)
  .service('donutVisualsService', DonutVisualsService);

export default DonutVisualsModule;
