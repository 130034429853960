import { Directive, Optional } from '@angular/core';
import { CancelCommandDirective } from '@progress/kendo-angular-grid';

import { KendoGridButtonDirective } from './kendo-grid-button.directive';

@Directive({
  selector: '[kendoGridCancelCommand]'
})
export class KendoGridCancelCommandDirective extends KendoGridButtonDirective {
  public constructor(@Optional() cancelCommand: CancelCommandDirective) {
    super(cancelCommand);
    if (cancelCommand) {
      cancelCommand.iconClass = 'fa-1x fa fa-ban';
    }
  }
}
