import ManualQaMeterHistoryController from './manual-qa-meter-history-controller';
import template                       from 'raw-loader!./manual-qa-meter-history.html';

export default {
  template: template,
  controller: ManualQaMeterHistoryController,
  controllerAs: 'vm',
  bindings: {
    meterHistory: '<'
  }
};
