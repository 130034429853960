import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalService } from '@enerkey/foundation-angular';
import { FilterService } from '../../services/filter.service';
import { FacilityService } from '../../shared/services/facility.service';
import { FacilityFilterModalComponent } from '../facility-filter-modal/facility-filter-modal.component';

@Component({
  selector: 'topbar-facility-filter',
  templateUrl: './topbar-facility-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopbarFacilityFilterComponent {
  @Input() public isMobile: boolean;

  public readonly facilityCount$: Observable<number>;
  public readonly filteredCount$: Observable<number>;
  public readonly isFiltered$: Observable<boolean>;

  public constructor(
    private readonly modalService: ModalService,
    facilityService: FacilityService,
    filterService: FilterService
  ) {
    this.isFiltered$ = filterService.isFiltered$;

    this.facilityCount$ = facilityService.profileFacilities$.pipe(
      map(facilities => facilities.length)
    );

    this.filteredCount$ = facilityService.filteredProfileFacilityIds$.pipe(
      map(facilities => facilities.length)
    );
  }

  public openFacilityFilterModal(): void {
    this.modalService.open(FacilityFilterModalComponent);
  }
}
