import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Renders `externalId` if applicable, `lastModifiedBy` otherwise. */
@Component({
  selector: 'last-modified-user',
  templateUrl: './last-modified-user.component.html',
  styleUrls: ['./last-modified-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LastModifiedUserComponent {
  @Input() public lastModifiedBy: string;
  @Input() public externalId: string;
}
