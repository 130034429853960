import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import {
  RESOLUTIONS,
  resolutionTranslationkeys,
  SelectableResolution,
} from '../components/reporting-search-form/reporting-search-form.component';

@Pipe({
  name: 'selectableResolutionTitle'
})
export class SelectableResolutionTitlePipe implements PipeTransform {
  private resolutionTranslation: Record<typeof RESOLUTIONS[number], string> = resolutionTranslationkeys;

  public constructor(private translate: TranslateService) { }

  public transform(resolution: SelectableResolution): Observable<string> {
    return this.translate.instant(this.resolutionTranslation[resolution]);
  }
}
