import { ILogDto, LogDto } from '@enerkey/clients/alarm';
import { ExtendedFacilityInformation } from '../../interfaces/extended-facility-information';

export class AlarmLogGridItem extends LogDto {
  public override alarmTypeName: string;
  public override quantityName: string;

  public constructor(
    alarm: ILogDto,
    public readonly reportingObject: ExtendedFacilityInformation,
    alarmTypeName: string,
    quantityName: string
  ) {
    super(alarm);
    if (this.lastModified?.valueOf() <= 0) {
      // Instead of default date, show empty on grid
      this.lastModified = null;
    }

    this.alarmTypeName = alarmTypeName;
    this.quantityName = quantityName;
  }

  public get meterId(): number {
    return this.alarm.meterId;
  }

  public get displayName(): string {
    return this.alarm.displayName;
  }

  public get active(): boolean {
    return this.alarm.active;
  }
}
