import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { ModalBase } from '@enerkey/foundation-angular';
import { ConfigurationControlClient, Terminal, TerminalStatus } from '@enerkey/clients/configuration-control';

@Component({
  selector: 'terminal-edit-modal',
  templateUrl: './terminal-edit-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminalEditModalComponent extends ModalBase {
  @Input() public enegiaId: number
  @Input() public terminal: Terminal
  @Input() public terminalStatus: TerminalStatus

  public constructor(
    activeModal: NgfActiveModal,
    private readonly configurationControlClient: ConfigurationControlClient
  ) {
    super(activeModal);
  }

  public onSave(): void {
    this.configurationControlClient.updateTerminal(this.terminal)
      .subscribe();
  }
}
