import ManualQaSpreadsheetController from './manual-qa-spreadsheet-controller';
import template from 'raw-loader!./manual-qa-spreadsheet.html';

export default {
  template: template,
  controllerAs: 'vm',
  controller: ManualQaSpreadsheetController,
  bindings: {
    dataSource: '<',
    timeFrame: '<',
    viewTimeFrame: '<',
    actualTimeFrame: '<',
    meter: '<',
    isPreviewMode: '<',
    isLoading: '<',
    fetchType: '<',
    useComparisonPeriod: '<',
    getReadings: '&',
    onTimeFrameChange: '&',
    onActualTimeFrameChange: '&',
    onValueChange: '&',
    onFetchTypeChange: '&'
  }
};
