import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { QuantityChartData } from '../../../../shared/energy-reporting-shared/shared/consumptions';
import { SumConsumptionsWidgetService } from '../../services/sum-consumptions-widget.service';
import { SumConsumptionsTableRow } from '../../shared/sum-consumptions';

@Component({
  selector: 'sum-consumptions-table',
  templateUrl: './sum-consumptions-table.component.html',
  styleUrls: ['./sum-consumptions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SumConsumptionsTableComponent {
  @Input() public data: SumConsumptionsTableRow[];
  @Input() public total: QuantityChartData;
  @Input() public hasComparisonPeriod: boolean;

  public comparisonPeriodTitle$: Observable<string>;
  public inspectionPeriodTitle$: Observable<string>;

  public constructor(sumConsumptionsWidgetService: SumConsumptionsWidgetService) {
    this.comparisonPeriodTitle$ = sumConsumptionsWidgetService.comparisonPeriodKey$;
    this.inspectionPeriodTitle$ = sumConsumptionsWidgetService.inspectionPeriodKey$;
  }
}
