import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Observable } from 'rxjs';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { map, take } from 'rxjs/operators';

import { FacilityPropertiesService } from '../../../energy-reporting/services/facility-properties.service';

type ChangeFn = (options: WidgetGroupByOption) => void;

export interface WidgetGroupByOption {
  itemProperty: string;
  groupProperty: string;
}

interface GroupingPropertyOption {
  itemName: string;
  itemProperty: string;
  groupName: string;
  groupProperty: string;
  id: string;
}

@Component({
  selector: 'widget-group-by-select',
  templateUrl: './widget-group-by-select.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WidgetGroupBySelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WidgetGroupBySelectComponent),
      multi: true,
    }
  ]
})
export class WidgetGroupBySelectComponent implements ControlValueAccessor, Validator {
  public readonly _groupProperties$: Observable<GroupingPropertyOption[]>;
  public readonly groupProperties$: Observable<GroupResult[] | GroupingPropertyOption[]>;
  public value: GroupingPropertyOption;

  public readonly textField: keyof GroupingPropertyOption = 'itemName';
  public readonly valueField: keyof GroupingPropertyOption = 'id';

  private _onChange: ChangeFn;

  public constructor(
    private readonly facilityPropertiesService: FacilityPropertiesService
  ) {
    this._groupProperties$ = this.facilityPropertiesService.groupableFacilityProperties$;

    this.groupProperties$ = this._groupProperties$.pipe(
      map(items => groupBy(items, [{ field: 'groupName' }]))
    );
  }

  public writeValue(value: WidgetGroupByOption): void {
    if (!value) {
      this.value = null;
      return;
    }
    this._groupProperties$.pipe(take(1)).subscribe(properties => {
      this.value = properties.find(
        property => property.itemProperty === value.itemProperty && property.groupProperty === value.groupProperty
      );
    });
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  /* istanbul ignore next */
  public registerOnTouched(): void { }

  /* istanbul ignore next */
  public setDisabledState?(): void { }

  public valueChange(value: GroupingPropertyOption): void {
    this._onChange?.({
      itemProperty: value.itemProperty,
      groupProperty: value.groupProperty,
    });
  }

  public validate(control: AbstractControl): ValidationErrors {
    const value = control.value as GroupingPropertyOption;
    return value?.groupProperty && value.itemProperty
      ? null
      : { noPropertySelected: true }
    ;
  }
}
