<span>
  {{ title }}
  <span
    [ngStyle]="{ 'color': color }"
  >
    <!-- ■ -->
    &#9632;
  </span>
  <!-- ➜ -->
  &#10140;
  <span
    [ngStyle]="{ 'color': comparisonColor ?? (quantityId | quantityColor) }"
  >
    <!-- ■ -->
    &#9632;
  </span>
  {{ changeKey }}
</span>
