import { Component, Input, Optional } from '@angular/core';

import { NgfActiveModal } from '@enerkey/foundation-angular';
import { ModalViewHeaderService } from './modal-view-header.service';

@Component({
  selector: 'modal-view-header',
  templateUrl: './modal-view-header.component.html',
  styleUrls: ['./modal-view-header.component.scss']
})
export class ModalViewHeaderComponent {
  @Input() public headingText: string;

  public constructor(
    @Optional() public readonly activeModal: NgfActiveModal,
    @Optional() public readonly modalViewHeaderService: ModalViewHeaderService
  ) { }
}
