<kendo-chart
  [axisDefaults]="axisDefaults"
  [valueAxis]="{ min: 0 }"
  [transitions]="false"
>
  <kendo-chart-tooltip [shared]="true">
    <ng-template
      kendoChartSharedTooltipTemplate
      let-category="category"
      let-points="points"
    >
      <emissions-widget-chart-tooltip
        [points]="points | reverse"
        [category]="category"
      ></emissions-widget-chart-tooltip>
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <ng-container
      *ngFor="let series of data"
    >
      <kendo-chart-series-item
        type="column"
        [data]="series"
        [colorField]="'color'"
        [opacity]="0.8"
        [gap]="0.3"
        [spacing]="0"
        [field]="valueField"
        [categoryField]="categoryField"
      >
      </kendo-chart-series-item>
    </ng-container>
  </kendo-chart-series>
  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item
      [majorGridLines]="{ visible: false }"
      [majorTicks]="{ size: 4, width: 1 }"
    >
      <kendo-chart-category-axis-item-labels
        [visual]="contentVisual"
        [position]="'onAxis'"
      ></kendo-chart-category-axis-item-labels>
    </kendo-chart-category-axis-item>
  </kendo-chart-category-axis>
</kendo-chart>
