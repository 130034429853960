numeral.$inject = ['kendo'];

function numeral(kendo) {
  return function(input, format) {
    if (input && format) {
      return kendo.toString(input, format);
    }
    return input;
  };
}

export default numeral;
