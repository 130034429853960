import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

import { NgfTooltipModule } from '@enerkey/foundation-angular';

import { AlarmDetailsModalComponent } from './components/alarm-details-modal/alarm-details-modal.component';
import { AlarmInfoComponent } from './components/alarm-info/alarm-info.component';
import { AlarmDetailsComponent } from './components/alarm-details/alarm-details.component';
import { HookTypeNamePipe } from './pipes/hook-type-name.pipe';
import { AlarmLogEntryStatusComponent } from './components/alarm-log-entry-status/alarm-log-entry-status.component';
import { AlarmLogStatusLabelComponent } from './components/alarm-log-status-label/alarm-log-status-label.component';
import { AlarmLogStatusIconComponent } from './components/alarm-log-status-icon/alarm-log-status-icon.component';
import {
  AlarmLogStatusDropdownComponent
} from './components/alarm-log-status-dropdown/alarm-log-status-dropdown.component';
import { PlaceholderImageComponent } from './components/placeholder-image/placeholder-image.component';
import { JpegPipe } from './pipes/jpeg.pipe';
import { AlarmTypeNamePipe } from './pipes/alarm-type-name.pipe';
import { LastModifiedUserComponent } from './components/last-modified-user/last-modified-user.component';
import { AlarmLogStatusEditModalComponent } from './components/alarm-log-status-edit-modal/alarm-log-status-edit-modal.component';
import { AlarmLogStatusComponent } from './components/alarm-log-status/alarm-log-status.component';
import { LogStatusNamePipe } from './pipes/log-status-name.pipe';
import { AdaptiveDecimalPipe } from './pipes/adaptive-decimal.pipe';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { EkLayoutModule } from '../ek-layout/ek-layout.module';
import { EkLoadingModule } from '../ek-loading/ek-loading.module';
import { EkModalsModule } from '../ek-modals/ek-modals.module';

@NgModule({
  declarations: [
    AlarmDetailsModalComponent,
    AlarmDetailsComponent,
    AlarmInfoComponent,
    AlarmLogEntryStatusComponent,
    AlarmLogStatusLabelComponent,
    AlarmLogStatusDropdownComponent,
    AlarmLogStatusIconComponent,
    HookTypeNamePipe,
    PlaceholderImageComponent,
    AlarmTypeNamePipe,
    LastModifiedUserComponent,
    AlarmLogStatusComponent,
    AlarmLogStatusEditModalComponent,
    LogStatusNamePipe,
    JpegPipe,
    AdaptiveDecimalPipe,
  ],
  imports: [
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgfTooltipModule,
    DropDownsModule,
    CommonPipesModule,
    EkLayoutModule,
    EkLoadingModule,
    EkModalsModule,
  ],
  exports: [
    HookTypeNamePipe,
    AlarmTypeNamePipe,
    AlarmLogStatusComponent,
    AlarmLogStatusLabelComponent,
    LastModifiedUserComponent,
    LogStatusNamePipe,
    AlarmLogStatusIconComponent,
  ]
})
export class AlarmsSharedModule { }
