const $inject = ['emYearlyVisualsService'];

class EmYearlyVisualsController {
  constructor(emYearlyVisualsService) {
    this.emYearlyVisualsService = emYearlyVisualsService;
    this.visualsData = [];
    this.chartOptions = {};
    this.datastate = null;
    this.data = [];
  }

  $onInit() {
    this.update({ func: this.createVisuals.bind(this) });
    this.createVisuals();
  }

  createVisuals() {
    const visualsData = [];

    const groups = this.emYearlyVisualsService.getGroups(this.groupedData);
    this.chartOptions = this.emYearlyVisualsService.getChartOptions(groups);

    this.config.forEach(config => {
      const fields = this.emYearlyVisualsService.getActiveFields(this.columns, config.fields);

      if (fields.length > 0) {
        const lastYear = this.emYearlyVisualsService.getLastActionYear(this.groupedData);
        const firstYear = this.emYearlyVisualsService.getFirstActionYear(this.groupedData);
        const series = this.emYearlyVisualsService.getSeries(this.groupedData, fields, groups, firstYear, lastYear);
        const chart = this.emYearlyVisualsService.getChart(series);
        const table = this.emYearlyVisualsService.getTable(series, groups);

        visualsData.push({
          title: this.emYearlyVisualsService.getVisualsTitle(config, fields),
          chart: chart,
          table: table
        });
      }
    });
    this.visualsData = visualsData;
  }
}

EmYearlyVisualsController.$inject = $inject;
export default EmYearlyVisualsController;
