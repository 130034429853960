<modal-view-header [headingText]="'SUSTAINABILITY.TARGETS.SET_STATUS' | translate"></modal-view-header>

<div class="modal-body">
  <p>
    <strong>{{ 'SUSTAINABILITY.TARGETS.TARGET' | translate }}:</strong>
    {{ roadmap.name }}
  </p>
  <p>
    <strong>{{ 'SUSTAINABILITY.TARGETS.SCENARIO' | translate }}:</strong>
    {{ roadmap.scenario | targetScenarioName }}
  </p>

  <form [formGroup]="formGroup">
    <div class="row">
      <input
        kendoRadioButton
        type="radio"
        name="valueOption"
        value="useReport"
        [formControl]="valueOption"
        *labelWrap="'SUSTAINABILITY.TARGETS.VALUES_FROM_REPORT' | translate"
      >
      <input
        kendoRadioButton
        type="radio"
        name="valueOption"
        value="manual"
        [formControl]="valueOption"
        *labelWrap="'SUSTAINABILITY.TARGETS.VALUES_MANUALLY' | translate"
        class="margin-left"
      >
    </div>

    <div class="row">
      <div class="flex-item">
        <target-report-select
          *ngIf="useReport$ | async else yearInput"
          [reportId]="roadmap.currentReportId"
          [yearRange]="{ min: roadmap.baseYear, max: roadmap.targetYear }"
          (selectedReportChange)="patchCurrentValues($event)"
        ></target-report-select>
      </div>
      <ng-template #yearInput>
        <div class="flex-item year-input">
          <ek-combo
            *labelBefore="'YEAR' | translate"
            [items]="yearOptions"
            formControlName="currentYear"
          ></ek-combo>
        </div>
      </ng-template>
    </div>

    <div
      class="row"
      formGroupName="currentValues"
    >
      <div
        *ngFor="let scope of scopes"
        class="flex-item"
      >
        <kendo-numerictextbox
          *labelBefore="'SUSTAINABILITY.TARGETS.SCOPE_EMISSIONS' | translate : {
              scope: scope | griScopeName
            }"
          [formControlName]="scope"
          [readonly]="useReport$ | async"
          [ngClass]="{ 'readonly-input' : (useReport$ | async) }"
          integerInput
        ></kendo-numerictextbox>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--primary"
      type="button"
      (click)="saveStatus()"
      [disabled]="!formGroup.valid"
      [loading]="loading$ | async"
    >
      {{ 'SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="cancel()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
