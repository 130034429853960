import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';

export type TableSortOption = { field?: string, order?: TableSortOrder };

type TableSortOrder = 'asc' | 'desc' | 'ascending' | 'descending';

type SortIconPlacement = 'left' | 'right';

@Directive({
  selector: '[enerkeyTableSort]'
})
export class TableSortDirective implements AfterViewInit, OnDestroy {
  @Input() public sortOption?: TableSortOption;
  @Output() public readonly sort: EventEmitter<TableSortOption> = new EventEmitter();

  // eslint-disable-next-line @typescript-eslint/ban-types
  public sortFieldListens: Function[] = [];

  public constructor(
    private eleRef: ElementRef,
    public renderer2: Renderer2
  ) { }

  public ngAfterViewInit(): void {
    this.initSort();
  }

  public ngOnDestroy(): void {
    this.sortFieldListens.forEach(field => field());
  }

  private initSort(): void {
    const sortTableElem = this.eleRef.nativeElement;
    const sortFields = sortTableElem.querySelectorAll('thead > tr > th[sortField]');

    for (const field of sortFields) {
      const sortIconPlacement: SortIconPlacement = field.getAttribute('sortIconPlacement') ?? 'left';

      this.renderer2.setStyle(field, 'cursor', 'pointer');

      if (sortIconPlacement === 'right') {
        this.renderer2.appendChild(field, this.getSortIcon());
      } else {
        this.renderer2.insertBefore(field, this.getSortIcon(), field.firstChild);
      }

      const listner = this.renderer2.listen(field, 'click', _ => this.onSortFieldClick(field));
      this.sortFieldListens.push(listner);
    }
  }

  private onSortFieldClick(field: HTMLElement): void {
    const sortFieldName = field.getAttribute('sortField');

    // removing pre-selcted sorting icon if available
    const existingIcons = this.eleRef.nativeElement.querySelectorAll('.enerkey-sort-icon.show');
    for (const icon of existingIcons) {
      this.setIconVisibility(icon, 'hide', undefined);
    }

    if (this.sortOption?.field === sortFieldName) {
      // assigning sort order
      this.sortOption = this.sortOption.order === 'asc' ? { order: 'desc', field: sortFieldName } : undefined;

      if (this.sortOption?.order) {
        this.setIconVisibility(field.querySelector('.enerkey-sort-icon'), 'show', this.sortOption.order);
      }

    } else {
      this.sortOption = { field: sortFieldName, order: 'asc' };
      this.setIconVisibility(field.querySelector('.enerkey-sort-icon'), 'show', 'asc');
    }

    this.sort.emit(this.sortOption);
  }

  private getSortIcon(): string {
    const span = this.renderer2.createElement('span');
    this.renderer2.setStyle(span, 'color', 'green');
    this.renderer2.setStyle(span, 'margin-left', '5px');
    this.renderer2.setStyle(span, 'visibility', 'hidden');
    this.renderer2.setAttribute(span, 'class', 'enerkey-sort-icon k-icon k-font-icon');
    return span;
  }

  private setIconVisibility(
    iconElem: HTMLElement,
    visibility: 'hide' | 'show',
    type: TableSortOrder | undefined
  ): void {

    if (visibility === 'hide') {
      this.renderer2.setStyle(iconElem, 'visibility', 'hidden');
      this.renderer2.removeClass(iconElem, 'show');
    } else {
      this.renderer2.setStyle(iconElem, 'visibility', 'visible');
      this.renderer2.addClass(iconElem, 'show');
    }

    if (type) {
      this.renderer2.setAttribute(iconElem, 'class', `enerkey-sort-icon k-icon k-font-icon k-i-sort-${type}-sm show`);
    }
  }

}
