import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { AppStatusService } from '../services/app-status-service';

responsiveKendo.$inject = ['appStatusService'];

function responsiveKendo(
  appStatusService: AppStatusService
): unknown {

  let previousWidth = window.innerWidth;

  return {
    restrict: 'A',
    scope: false,
    link: function() {
      const refreshKendoElements = function(): void {
        if (previousWidth === window.innerWidth) {
          return;
        }
        previousWidth = window.innerWidth;

        const container = appStatusService.inModal
          ? document.querySelector('.reveal')
          : document;

        container.querySelectorAll('[kendo-grid]').forEach(el => $(el).data('kendoGrid')?.refresh());
        container.querySelectorAll('[kendo-treelist]').forEach(el => $(el).data('kendoTreeList')?.refresh());
        container.querySelectorAll('[kendo-chart]').forEach(el => $(el).data('kendoChart')?.redraw());
      };

      fromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe(() => refreshKendoElements());
    }
  };
}

export default responsiveKendo;
