import moment from 'moment';
import { addMinutes, subMinutes } from 'date-fns';

import TimeFrame from '../services/time-frame-service';

/**
 * Default format for date time object
 */
export const DEFAULT_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

/** For perf optimizations */
const MS_IN_HOUR = 3600 * 1000;

/**
 * Formats moment timestamp to given format or defaults to DEFAULT_DATE_TIME_FORMAT.
 * Return empty string if timestamp is not valid moment object
 */
export function momentToString(timestamp: moment.Moment, format = DEFAULT_DATE_TIME_FORMAT): string {
  return (moment.isMoment(timestamp) && timestamp.isValid())
    ? timestamp.format(format)
    : '';
}

/**
 * Returns true if timestamp is hourly
 */
export function isHourly(timestamp: Date | moment.Moment): boolean {
  return (timestamp?.valueOf() % MS_IN_HOUR) === 0;
}

/**
 * Checks if given date / time frame is in given time frame
 */
export function isInTimeFrame(timeFrame: TimeFrame, objectToCheck: moment.Moment | TimeFrame): boolean {
  return timeFrame instanceof TimeFrame && timeFrame.contains(objectToCheck);
}

/**
 * Return elapsed full months from begin of year
 * Note that meter reading sum is not considered full until 5. day
 */
export function getMeterFullMonths(date: string | Date): number {
  const currentDate = moment.utc(date);
  // Magic date: Date when january is considered to be over, year is not relevant
  const endOfJanuary = moment.utc('2000-02-05T00:00:00:000Z').year(currentDate.year());
  if (currentDate.diff(endOfJanuary) > 0) {
    return currentDate.subtract(5, 'days').month();
  }
  return 0;
}

/**
 * Return a new date that is adjusted by the amount of time zone offset
 *
 * Eg. 3 hours is subtracted from a date with timezone GMT+0300
 * Eg. 6 hours is added to a date with timezone GMT-0600
 */
export function adjustByTimezoneOffset(date: Date): Date {
  return addMinutes(date, date.getTimezoneOffset());
}

/**
 * Returns a date with equivalent time in UTC as the parameter date has in local.
 * Use when you want to get the absolute date as UTC.
 *
 * @example
 * localToUtc(new Date("2015-01-01T00:00:00.000+02:00")).toISOString() === "2015-01-01T00:00:00.000Z";
 */
export function localToUtc(date: Date): Date {
  return subMinutes(date, date.getTimezoneOffset());
}

/**
 * Get latest month of year that should have all consumptions reported
 *
 * Month starts from zero (return value for February is 1)
 */
export function getLatestReportingMonthNumeric(year: number): number {
  const currentDate = new Date();
  if (currentDate.getFullYear() !== year && year) {
    return 11;
  }

  return currentDate.getDate() < 5 ? currentDate.getMonth() - 2 : currentDate.getMonth() - 1;
}

/**
 * Converts date or ISO string to an OLE value.
 */
export function dateToOle(value: string | Date): number {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Date.parse(value as any) / 1000 / 3600 / 24 + 25569;
}

/**
 * Converts OLE date number to a date instance.
 */
export function oleToDate(
  value: number | Date | moment.Moment,
  utcTime: boolean = false
): Date | null {
  if (value === null || Number.isNaN(+value)) {
    return null;
  }
  if (value instanceof Date) {
    return value;
  }
  if (isMoment(value)) {
    return value.toDate();
  }
  const date = new Date();
  if (utcTime) {
    date.setTime(((value - 25569) * 24 * 3600 * 1000) - (date.getTimezoneOffset() * 60 * 1000));
  } else {
    date.setTime((value - 25569) * 24 * 3600 * 1000);
  }
  return date;
}

/**
 * Converts Ole date to Sortable("s") ISO 8601 string
 * "yyyy'-'MM'-'dd'T'HH':'mm':'ss"
 * https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-date-and-time-format-strings#Sortable
 */
export function oleDateToSortableString(oleDate: number): string {
  const date = oleToDate(oleDate);
  return date?.toISOString().slice(0, 19);
}

function isMoment(value: unknown): value is moment.Moment {
  return moment.isMoment(value);
}
