import { Injectable } from '@angular/core';
import { MeterReadingMeter } from '../shared/meter-reading-meter';

@Injectable()
export class MrcReadingsListingService {
  public getSubMetersRecursive(meter: MeterReadingMeter): MeterReadingMeter[] {
    const { SubMeters, ...parentMeter } = meter;
    let subMeters: MeterReadingMeter[] = [];
    if (SubMeters) {
      SubMeters.forEach(subMeter => {
        subMeters = [...subMeters, ...this.getSubMetersRecursive(subMeter)];
      });
    }

    return [parentMeter, ...subMeters];
  }
}
