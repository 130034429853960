import { NgModule } from '@angular/core';

import { MrcReadingsListingService } from './services/mrc-readings-listing-service';

@NgModule({
  providers: [
    MrcReadingsListingService
  ]
})
export class MrcModule {
}
