<ng-container *ngIf="apiKeys$ | async as keys">
  <div class="small-12 columns">
    <p>{{ 'DATA_API_KEYS.DESCRIPTION' | translate }}</p>
  </div>
  <hr>
  <div class="apiKeysGrid">
    <div *ngFor="let keyType of configurableapikey">
      <h2>
        {{ 'DATA_API_KEYS.KEY' | translate }} {{ keyType | apiKeyIndex }}
      </h2>
      <ng-container *ngIf="keys[keyType] as currentKey else keyNotDefined">
        <ng-container *ngIf="currentKey.validTo else notRestricted">
          <span>
            {{ 'DATA_API_KEYS.VALID_TO' | translate: { validity: currentKey.validTo | kendoDate:{ date: 'long' } } }}
          </span>
          <span
            *ngIf="currentKey.validTo < currentDate"
            class="ek-color--negative"
          >
            &nbsp;
            {{ 'DATA_API_KEYS.EXPIRED' | translate }}
          </span>
        </ng-container>
        <ng-template #notRestricted>
          <span>{{ 'DATA_API_KEYS.NOT_RESTRICTED' | translate }}</span>
        </ng-template>
        <label>
          {{ 'ADMIN.EMAIL' | translate }}:
          <span *ngIf="currentKey.keyEmailAddress; else noValueForField">
            {{ currentKey.keyEmailAddress }}
          </span>
        </label>
        <label>
          {{ 'DATA_API_KEYS.KEY_DESCRIPTION' | translate }}:
          <span *ngIf="currentKey.keyDescription; else noValueForField">
            {{ currentKey.keyDescription }}
          </span>
          <ng-template #noValueForField>
            <span class="no-value-for-field">
              {{ '-' }}
            </span>
          </ng-template>
        </label>
        <div class="button-container">
          <button
            class="button button--negative"
            (click)="deleteApiKey(currentKey)"
          >
            {{ 'DATA_API_KEYS.DELETE' | translate }}
          </button>
          <button
            class="button marginLeft"
            (click)="openCreateModal(keyType, true)"
          >
            {{ 'DATA_API_KEYS.RENEW' | translate }}
          </button>
        </div>
      </ng-container>

      <ng-template #keyNotDefined>
        <span>{{ 'DATA_API_KEYS.KEY_NOT_DEFINED' | translate }}</span>
        <br><br>
        <button
          class="button"
          (click)="openCreateModal(keyType, false)"
        >
          {{ 'DATA_API_KEYS.CREATE' | translate }}
        </button>
      </ng-template>
    </div>
  </div>
</ng-container>
