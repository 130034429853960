<modal-view-header [headingText]="'ADMIN.VIRTUAL_METERS.ADD_METERS.ADD_METERS' | translate"></modal-view-header>
<div class="modal-body">
  <add-meters
    [initialEnegiaId]="initialEnegiaId"
    (meterSelectionChange)="meterSelectionChanged($event)"
  >
  </add-meters>
</div>
<modal-footer>
  <button
    type="button"
    class="button"
    (click)="saveClicked()"
  >{{ 'ADMIN.SAVE' | translate }}</button>
  <button
    type="button"
    class="button button--secondary"
    (click)="cancelClicked()"
  >{{ 'CANCEL' | translate }}</button>
</modal-footer>
