import { Quantities } from '@enerkey/clients/metering';

import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ConsumptionLike, ReportingSeries } from '../shared/reporting-series';
import { ReportingData } from './reporting-data-service-base';
import { sumKey } from './sum-report.service';

export type FacilityAmountsPerQuantity = Partial<Record<Quantities, { total: number; applicable: number }>>;

export function getQuantitySums({
  measured,
  normalized,
  targets,
  comparability,
  incompletes,
  ids,
  meterBasedCosts
}: {
  measured: ReportingData[],
  normalized: ReportingData[],
  targets: ReportingData[],
  comparability: Comparability,
  incompletes: Record<number, Set<Quantities>>,
  ids: number[],
  meterBasedCosts: ReportingData[]
}): {
    measured: ReportingData[],
    normalized: ReportingData[],
    targets: ReportingData[],
    meterBasedCosts: ReportingData[]
  } {
  return {
    measured: getSumOfValues(measured, ids, incompletes, comparability),
    normalized: getSumOfValues(normalized, ids, incompletes, comparability),
    targets: getSumOfValues(targets, ids, incompletes, comparability),
    meterBasedCosts: getSumOfValues(meterBasedCosts, ids, incompletes, comparability)
  };
}

export function getIncompleteFacilitiesAndQuantities(
  data: ReportingData[],
  facilityIds: number[],
  incompleteThreshold: number
): Record<number, Set<Quantities>> {
  return facilityIds.toRecord(
    fId => fId,
    fId => {
      const incompleteQuantities = new Set<Quantities>();
      for (const quantity of data) {
        const maxIncomplete = Math.max(...quantity.series[fId]?.map(s => Math.max(
          ...s.values.map(v => v.incomplete ?? 0)
        )) ?? [Infinity]);
        if (maxIncomplete > incompleteThreshold) {
          incompleteQuantities.add(quantity.quantityId);
        }
      }
      return incompleteQuantities;
    }
  );
}

export function getFacilitiesPerQuantity(
  data: ReportingData[],
  facilityIds: number[],
  incompletes: Record<number, Set<Quantities>>,
  comparability: Comparability
): FacilityAmountsPerQuantity {
  const quantityFacilityAmounts: FacilityAmountsPerQuantity = {};

  for (const [quantity, quantityData] of data.toGroupsBy(d => d.quantityId)) {
    const applicable = facilityIds.count(
      fId => quantityData.some(d => isFacilityQuantitySumApplicable(d, fId, comparability, incompletes))
        && !!quantityData.some(d => d.series[fId])
    );
    const total = facilityIds.count(fId => quantityData.some(d => d.series[fId]?.some(s => s.hasValues)));

    quantityFacilityAmounts[quantity] = { total: total, applicable };
  }

  return quantityFacilityAmounts;
}

function getSumOfValues(
  data: ReportingData[],
  facilityIds: number[],
  incompletes: Record<number, Set<Quantities>>,
  comparability: Comparability
): ReportingData[] {
  if (!Array.hasItems(data)) {
    return data;
  }

  return data.map(d => {
    const allSeries = facilityIds.filterMap(
      fId => isFacilityQuantitySumApplicable(d, fId, comparability, incompletes),
      fId => d.series[fId] ?? []
    );
    const quantityData = allSeries.flat();

    const quantitySumSeries: ReportingSeries[] = [];
    for (const series of quantityData.toGroupsBy(x => `${x.options.serieType}${x.options.serieTitle}`).values()) {
      const values: ConsumptionLike[] = [];
      const comparisonValues: ConsumptionLike[] = [];
      for (let i = 0; i < series[0].values.length; i++) {
        values.push({
          ...series.reduce((accum, current) => ({
            value: accum.value + (current.values[i].value ?? 0),
            incomplete: Math.max(accum.incomplete, current.values[i].incomplete ?? 0)
          }), { value: 0, incomplete: 0 }),
          timestamp: series[0].values[i].timestamp
        });
        if (series[0].comparisonConsumptions) {
          comparisonValues.push({
            value: series.reduce((accum, current) => accum + (current.comparisonConsumptions[i].value ?? 0), 0),
            timestamp: series[0].comparisonConsumptions[i].timestamp
          });
        }
      }

      quantitySumSeries.push(series[0].fromValues({
        consumptions: values,
        comparisonConsumptions: comparisonValues
      }));
    }

    return {
      quantityId: d.quantityId,
      derivedId: d.derivedId,
      series: {
        [sumKey]: quantitySumSeries
      }
    };
  });
}

function isFacilityQuantitySumApplicable(
  data: ReportingData,
  facilityId: number,
  comparability: Comparability,
  incompletes: Record<number, Set<Quantities>>
): boolean {
  if (comparability === Comparability.ByQuantities && (incompletes[facilityId].size > 0)) {
    return false;
  }
  if (comparability === Comparability.ByQuantity && incompletes[facilityId].has(data.quantityId)) {
    return false;
  }
  return true;
}
