import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';
import { KeysOfType } from '@enerkey/ts-utils';

import {
  FacilityYearTrendForQuantities, reportQuantityIds, YearReadings
} from '../yearly-trend-report/yearly-trend-report.component';

@Component({
  selector: 'yearly-trend-report-table',
  templateUrl: './yearly-trend-report-table.component.html',
  styleUrls: ['./yearly-trend-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearlyTrendReportTableComponent {
  @Input() public quantityIds: typeof reportQuantityIds;
  @Input() public facilityData: FacilityYearTrendForQuantities;

  public readonly quantityTableFields: Record<typeof reportQuantityIds[number], KeysOfType<YearReadings, number>[]> = {
    [Quantities.Electricity]: ['reading', 'specificConsumption', 'diffPercentage'],
    [Quantities.HeatingEnergy]: ['reading', 'normalizedReading', 'specificConsumption', 'diffPercentage'],
    [Quantities.Water]: ['reading', 'specificConsumptionPerPerson', 'specificConsumption', 'diffPercentage'],
  };
}
