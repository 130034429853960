<ng-template #topbarTemplate>
  <div class="content-right float-right">
    <ek-dropdown>
      <ek-dropdown-heading>
        {{ 'DROPDOWN.ACTIONS' | translate }}
      </ek-dropdown-heading>
      <ek-dropdown-item
        (click)="createMultipleUsers()"
      >
        {{ 'ADMIN.BULK_CREATE' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="sendNewWelcomeEmail()"
      >
        {{ 'ADMIN.BULK_SEND_WELCOMEMAIL' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="editRoles()"
      >
        {{ 'ADMIN.BULK_MODIFY_ROLES.MODIFY_ROLES_BUTTON' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="editProfiles()"
      >
        {{ 'ADMIN.BULK_MODIFY_PROFILES.MODIFY_PROFILES_BUTTON' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="copyUserSettings()"
      >
        {{ 'ADMIN.COPY_USERSETTINGS.BUTTON_TITLE' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="sendBulkEmail()"
      >
        {{ 'ADMIN.BULK_SEND_EMAIL.BULK_SEND_EMAIL_BUTTON' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="massEditUsers()"
      >
        {{ 'ADMIN.MODIFY_USERS.MODIFY_USERS_BUTTON' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="addUsersToTenant()"
      >
        {{ 'ADMIN.MODIFY_USERS.ADD_USERS_TO_TENANT' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        [disabled]="!gridComponent.selectedUsers.length"
        (click)="removeUsersFromTenant()"
      >
        {{ 'ADMIN.MODIFY_USERS.REMOVE_USERS_FROM_TENANT' | translate }}
      </ek-dropdown-item>
    </ek-dropdown>
    <button class="button" (click)="createUser()">
      <i class="fa fa-plus"></i> {{ 'ADMIN.CREATEUSER' | translate }}
    </button>
  </div>
</ng-template>
<div class="row">
  <div class="large-2 medium-3 columns">
    <user-management-search></user-management-search>
  </div>
  <div class="large-10 medium-9 columns">
    <user-management-grid></user-management-grid>
  </div>
</div>
