import lodash from 'lodash';

export default angular.module('lodash', [])
  .factory('_', function() {

    /* Source: http://stackoverflow.com/a/17580000 */
    function findDeep(items, attrs) {
      function match(value) {
        for (var key in attrs) {
          if(!_.isUndefined(value) && !_.isNull(value)) {
            if (attrs[key] !== value[key]) {
              return false;
            }
          }
        }

        return true;
      }
    }

    lodash.mixin({
      findDeep: findDeep
    });

    return lodash;
  })
;
