import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import {
  CellClickEvent, ColumnBase, ColumnComponent, GridComponent, NavigationCell
} from '@progress/kendo-angular-grid';

@Directive({
  selector: 'kendo-grid[inCellTab]'
})
export class InCellTabDirective {
  @Output() public inCellTab = new EventEmitter<Partial<CellClickEvent>>();

  public constructor(private grid: GridComponent) {
  }

  @HostListener('keydown.shift.tab', ['$event'])
  @HostListener('keydown.tab', ['$event']) public onKeyDown(e: KeyboardEvent): void {
    const activeRow = this.grid.activeRow;
    if (!activeRow?.dataItem) {
      // Not on an editable row
      return;
    }

    if (this.grid.isEditingCell() && !this.grid.closeCell()) {
      // Content validation failed, keep focus in cell
      e.preventDefault();
      return;
    }

    const activeCell = this.grid.activeCell;

    let nav: NavigationCell;
    let column: ColumnBase;
    const focusMethod = e.shiftKey
      ? 'focusPrevCell'
      : 'focusNextCell'
    ;

    // Loop until editable cell is found or there are no more cells to edit
    do {
      nav = this.grid[focusMethod]();
      column = this.grid.visibleColumns.find((_item, index) => index === nav?.colIndex);
    } while (nav && !(column as ColumnComponent)?.editable);

    if (!nav) {
      // No next cell to navigate to
      e.preventDefault();
      this.grid.focusCell(activeCell.rowIndex, activeCell.colIndex);
      return;
    }

    const dataItem = nav.dataItem;

    if (dataItem) {
      // Prevent the focus from moving to the next element
      e.preventDefault();
      this.inCellTab.emit({
        dataItem,
        column,
        columnIndex: nav.colIndex,
        isEdited: false,
        sender: this.grid,
        rowIndex: nav.dataRowIndex
      });
    }
  }
}
