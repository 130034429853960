<modal-view-header [headingText]="'PROVISIONING.DETAILS' | translate"></modal-view-header>
<div class="modal-body">
  <info-grid>
    <info-grid-item
      [title]="'PROVISIONING.STATUS.LABEL' | translate"
    >
      <provisioning-status [status]="provisioning.runtimeStatus"></provisioning-status>
    </info-grid-item>
    <info-grid-item
      [title]="'PROVISIONING.CREATED' | translate"
      [data]="provisioning.createdTime | date:'short'"
    ></info-grid-item>
    <info-grid-item
      [title]="'PROVISIONING.LAST_UPDATED' | translate"
      [data]="provisioning.lastUpdatedTime | date:'short'"
    ></info-grid-item>
    <info-grid-item
      [title]="'PROVISIONING.INPUT_DATA' | translate"
    >
      <button class="button button--link button--no-padding" (click)="downloadJson()">
        {{ 'PROVISIONING.DOWNLOAD' | translate }}
      </button>
    </info-grid-item>
  </info-grid>
  <accordion>
    <accordion-group #resultGroup *ngFor="let item of provisioning?.results | availableOperationResults">
      <h2 accordion-heading>
        {{ item.key | translate }}
        <provisioning-operation-status-icon
          *ngIf="!resultGroup.isOpen && item.value.operationResult !== OperationResult.Success"
          [status]="item.value.operationResult"
        ></provisioning-operation-status-icon>
      </h2>
      <operation-result [status]="item.value"></operation-result>
    </accordion-group>
  </accordion>
</div>
