import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ReportingUnit } from '@enerkey/clients/reporting';

import { QuantityChartData } from '../../../../shared/energy-reporting-shared/shared/consumptions';
import { TimeFrameString } from '../../../../services/time-frame-service';

@Component({
  selector: 'consumptions-widget-chart',
  templateUrl: './consumptions-widget-chart.component.html',
  styleUrls: ['./consumptions-widget-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumptionsWidgetChartComponent {
  @Input() public quantityData: QuantityChartData;
  @Input() public unitKey = ReportingUnit.Default;
  @Input() public resolution: TimeFrameString;
}
