import { ComponentRef } from '@angular/core';

import { NgfModalWindow } from './modal-window';

import { ContentRef } from '../util/popup';
import { ModalBase } from './modal-base';

function identity<T>(param: T): T {
  return param;
}

/**
 * A reference to the currently opened (active) modal.
 *
 * Instances of this class can be injected into your component passed as modal content.
 * So you can `.close()` or `.dismiss()` the modal window from your component.
 */
export class NgfActiveModal {
  /**
   * Closes the modal with an optional `result` value.
   *
   * The `NgfMobalRef.result` promise will be resolved with the provided value.
   */
  public close(result?: any): void {
    identity(result);
  }

  /**
   * Dismisses the modal with an optional `reason` value.
   *
   * The `NgfModalRef.result` promise will be rejected with the provided value.
   */
  public dismiss(reason?: any): void {
    identity(reason);
  }
}

/**
 * A reference to the newly opened modal returned by the `NgfModal.open()` method.
 */
export class NgfModalRef<T = void> {
  /**
   * The promise that is resolved when the modal is closed and rejected when the modal is dismissed.
   */
  public result: Promise<T extends ModalBase<infer U> ? U : any>;

  private _resolve: (result?: any) => void;
  private _reject: (reason?: any) => void;

  /**
   * The instance of a component used for the modal content.
   *
   * When a `TemplateRef` or `string` is used as the content, will return `undefined`.
   */
  public get componentInstance(): T {
    return this._contentRef.componentRef ?
      this._contentRef.componentRef.instance :
      undefined;
  }

  public constructor(
    private _windowCmptRef: ComponentRef<NgfModalWindow>,
    private _contentRef: ContentRef<T>,
    private _beforeDismiss?: () => boolean | Promise<boolean>
  ) {
    _windowCmptRef.instance.dismissEvent.subscribe((reason: any) => {
      this.dismiss(reason);
    });

    this.result = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this.result.then(null, () => { });
  }

  /**
   * Closes the modal with an optional `result` value.
   *
   * The `NgfMobalRef.result` promise will be resolved with the provided value.
   */
  public close(result?: any): void {
    if (this._windowCmptRef) {
      this._resolve(result);
      this._removeModalElements();
    }
  }

  /**
   * Dismisses the modal with an optional `reason` value.
   *
   * The `NgfModalRef.result` promise will be rejected with the provided value.
   */
  public dismiss(reason?: any): void {
    if (this._windowCmptRef) {
      const dismissFn = this._beforeDismiss ||
        (this.componentInstance as any)?.confirmDismiss?.bind(this.componentInstance);

      if (!dismissFn) {
        this._dismiss(reason);
      } else {
        const dismiss = dismissFn();
        if (dismiss && typeof dismiss.then === 'function') {
          dismiss.then(
            (result: any) => {
              if (result !== false) {
                this._dismiss(reason);
              }
            },
            () => { }
          );
        } else if (dismiss !== false) {
          this._dismiss(reason);
        }
      }
    }
  }

  private _dismiss(reason?: any): void {
    this._reject(reason);
    this._removeModalElements();
  }

  private _removeModalElements(): void {
    const windowNativeEl = this._windowCmptRef.location.nativeElement;
    windowNativeEl.parentNode.removeChild(windowNativeEl);
    this._windowCmptRef.destroy();

    this._contentRef?.viewRef?.destroy();

    this._windowCmptRef = null;
    this._contentRef = null;
  }
}
