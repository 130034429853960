<modal-view-header [headingText]="terminal.name"></modal-view-header>
<div class="modal-body">
  <terminal-edit [enegiaId]="enegiaId" [terminal]="terminal"></terminal-edit>
</div>
<div class="modal-footer">
    <div class="row">
      <div class="modal-footer-buttons float-right">
        <button type="button" class="button" (click)="onSave()">{{ "MODALS.SAVE" | translate }}</button>
      </div>
    </div>
</div>
