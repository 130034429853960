import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingDataService } from './reporting-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class OverviewReportService {
  public constructor(
    private readonly reportingDataService: ReportingDataService,
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  public getData(
    params: ReportingSearchParams,
    facilityIds: number[],
    incompleteThreshold: number
  ): Observable<ReportingSeriesByFacility> {
    return forkJoin({
      measured: forkJoin([
        this.reportingDataService.getMeasuredValues(
          ReportType.Overview,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'column', lineOpacity: 0.85
            })),
          }
        ),
        this.reportingDataService.getMeasuredDerivedValues(
          ReportType.Overview,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        ),
      ]),
      emissions: forkJoin([
        this.reportingDataService.getMeasuredEmissionsValues(
          ReportType.Overview,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        ),
        this.reportingDataService.getNormalizedEmissionsValues(
          ReportType.Overview,
          params,
          facilityIds,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        )
      ])
    }).pipe(
      map(({
        measured, emissions
      }) => this.reportingSeriesService.mapValuesById({
        ids: facilityIds,
        measured: measured.flat(),
        emissions: emissions.flat(),
        normalized: [],
        incompleteThreshold,
        comparability: params.comparability,
        periods: params.periods,
        searchPeriods: params.searchPeriods,
        resolution: params.resolution,
      }))
    );
  }
}
