<div>
  <div
    class="widget-filter-container"
    [formGroup]="filterFormGroup"
  >
    <div>
      <quantity-dropdown
        *ngIf="(quantities$ | async) as quantities"
        formControlName="selectedQuantity"
        [quantities]="quantities"
      ></quantity-dropdown>
      <button
        (click)="goToFacilities()"
        class="button button--link button--external-link"
      >
        {{ 'FACILITIES_LINK' | translate }}
      </button>
    </div>
    <div class="radio-button-area">
      <input
        type="radio"
        [value]="WidgetRelatedValueOption.Max"
        kendoRadioButton
        formControlName="minMaxAvg"
        *labelWrap="'FACILITIES_REPORT.MAX' | translate"
      >
      <input
        type="radio"
        [value]="WidgetRelatedValueOption.Min"
        kendoRadioButton
        formControlName="minMaxAvg"
        *labelWrap="'FACILITIES_REPORT.MIN' | translate"
      >
      <input
        type="radio"
        [value]="WidgetRelatedValueOption.Average"
        kendoRadioButton
        formControlName="minMaxAvg"
        *labelWrap="'FACILITIES_REPORT.AVERAGE' | translate"
      >
    </div>
  </div>

  <ng-container *ngIf="(data$ | async) as rows">
    <table
      enerkeyTableSort
      (sort)="sortRows($event)"
      *ngIf="rows.length > 0; else noData"
    >
      <thead>
        <tr>
          <th sortField="name" sortIconPlacement="right">{{ 'FACILITIES.FACILITY' | translate }}</th>
          <th
            sortField="comparison"
            *ngIf="comparisonPeriodTitle"
          >{{ comparisonPeriodTitle }} [{{ filterFormGroup.get('selectedQuantity')?.value | quantityUnit : unitKey | async }}]</th>
          <th sortField="inspection"> {{ inspectionPeriodTitle }} [{{ filterFormGroup.get('selectedQuantity')?.value | quantityUnit : unitKey | async }}]</th>
          <th
            sortField="change"
            *ngIf="comparisonPeriodTitle"
          > {{ 'CHANGE.TITLE' | translate }} </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of rows">
          <tr>
            <td>
              <a
                [title]="row.Name"
                (click)="onRowClick(row.FacilityId)"
              >{{ row.Name }}</a>
            </td>
            <td *ngIf="comparisonPeriodTitle">{{ row.values.comparison | kendoNumber : '#,#.0' }}</td>
            <td> {{ row.values.inspection | kendoNumber : '#,#.0'}} </td>
            <td
              *ngIf="comparisonPeriodTitle"
              [ngClass]="row.values.change <= 0 ? 'change-positive' : 'change-negative'"
            >
              {{ dataModelOptions.change.absolute ? (row.values.change | kendoNumber:'#,#.0') :
              (row.values.change | kendoNumber:'#.0 %') }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
  <ng-template #noData>
    <span>{{ 'WIDGET.NO_DATA' | translate }}</span>
  </ng-template>
</div>
