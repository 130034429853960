import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe } from '@progress/kendo-angular-intl';

@Pipe({
  name: 'consumptionValue'
})
export class ConsumptionValuePipe implements PipeTransform {
  public constructor(private numberPipe: NumberPipe) {
  }

  public transform(value: number, decimalsToShow?: number): string {
    if (!Number.isFinite(value)) {
      return '-';
    }

    const amountOfDecimals = (Math.abs(value) < 100 && decimalsToShow) ? decimalsToShow : 0 ;

    let decimalFormat = '0'.repeat(amountOfDecimals);
    decimalFormat = decimalFormat ? `.${decimalFormat}` : decimalFormat;
    return this.numberPipe.transform(value, `#,#${decimalFormat}`);
  }
}
