import { Component, Input } from '@angular/core';
import { startOfDay } from 'date-fns';

import { MeteringType, MeterManagementMeter } from '@enerkey/clients/meter-management';
import { Facility } from '@enerkey/clients/facility';
import { MeteringClient } from '@enerkey/clients/metering';
import { getStringEnumValues } from '@enerkey/ts-utils';

import { MeterUpdateProperties } from '../../shared/meter-update-properties';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { MeterResolution } from '../../../../constants/meter.constant';
import { localToUtc } from '../../../../shared/date.functions';

const DATE_PROPS = ['automaticReadingStartTime', 'automaticReadingEndTime', 'deactivationTime'] as const;

@Component({
  selector: 'meter-info',
  templateUrl: './meter-info.component.html',
  styleUrls: ['./meter-info.component.scss']
})
export class MeterInfoComponent {
  public readonly MeteringType = MeteringType;
  public readonly meterResolutions: readonly MeterResolution[];

  @Input() public meter: MeterManagementMeter;
  @Input() public meterUpdateProperties: MeterUpdateProperties;
  @Input() public facility: Facility;

  public constructor(
    private readonly meteringClient: MeteringClient,
    private readonly toasterService: ToasterService
  ) {
    this.meterResolutions = getStringEnumValues(MeterResolution);
  }

  public changeDateValue(
    value: Date,
    property: typeof DATE_PROPS[number]
  ): void {
    this.meterUpdateProperties.updatedProperties[property] = value as any;
  }

  public save(): void {
    if (!this.meterUpdateProperties.hasChanges) {
      this.toasterService.info('ADMIN.METER_EDIT.NO_CHANGES');
      return;
    }

    for (const prop of DATE_PROPS) {
      const val = this.meterUpdateProperties.updatedProperties[prop];

      // eslint-disable-next-line no-nested-ternary
      const dateVal = val instanceof Date ? val : null;

      if (dateVal instanceof Date) {
        this.meterUpdateProperties.updatedProperties[prop] = localToUtc(startOfDay(dateVal)) as any;
      }
    }

    this.meteringClient.updateMeters(undefined, [this.meterUpdateProperties.updatedProperties])
      .subscribe({
        next: () => {
          this.meterUpdateProperties.reinstateProperties();
          this.toasterService.success('ADMIN.METER_EDIT.SAVE_SUCCESS');
        },
        error: () => {
          this.toasterService.error('ADMIN.METER_EDIT.SAVE_ERROR');
        }
      });
  }
}
