<div class="meter-groups-grid-container">
  <column-tooltips>
    <kendo-treelist
      [kendoTreeListHierarchyBinding]="meterGroupTreeList$ | async"
      [initiallyExpanded]="true"
      [selectable]="selectableSettings"
      [isSelected]="isItemSelected"
      [resizable]="true"
      [rowClass]="rowCssClassFn"
      [loading]="loading$ | async"
      [scrollable]="'virtual'"
      [rowHeight]="29"
      [pageSize]="500"
      (selectionChange)="onSelectionChange($event)"
      (cellClick)="onCellClick($event)"
      (cellClose)="onCellClose($event)"
      class="meter-groups-grid"
      filterable="menu"
      childrenField="children"
      kendoTreeListSelectable
      kendoTreeListExpandable
    >
      <ng-template kendoTreeListNoRecordsTemplate></ng-template>

      <kendo-treelist-checkbox-column
        [width]="30"
        [checkChildren]="true"
        [showSelectAll]="true"
        *ngIf="editMode"
      >
      </kendo-treelist-checkbox-column>

      <kendo-treelist-column
        field="meterGroupName"
        [expandable]="true"
        [minResizableWidth]="300"
        [class]="'meterTreelistHierarchyCell'"
        [title]="'ADMIN.SPREADSHEET.HIERARCHY.HEADING' | translate"
        [includeInChooser]="false"
        [editable]="false"
      >
        <ng-template kendoTreeListCellTemplate let-dataItem>
          {{ dataItem.name }}
        </ng-template>
      </kendo-treelist-column>

      <kendo-treelist-column-group [title]="'GROUPS.METER_INFORMATION' | translate">
        <kendo-treelist-column
          field="quantity"
          [title]="'ADMIN.METERGROUPS.GRID.QUANTITY' | translate"
          [width]="200"
          [minResizableWidth]="200"
          [editable]="false"
        >
          <ng-template kendoTreeListCellTemplate let-dataItem>
            {{ dataItem.quantity | translate }}
          </ng-template>
        </kendo-treelist-column>

        <kendo-treelist-column
          field="meterTypeId"
          filter="numeric"
          class="text-center"
          [title]="'ADMIN.METERGROUPS.GRID.METER_TYPE' | translate"
          [width]="120"
          [editable]="false"
        >
          <meter-type
            *kendoTreeListCellTemplate="let dataItem; let column = column"
            [meterType]="dataItem | propertyPath: column.field"
            [showText]="false"
          ></meter-type>
        </kendo-treelist-column>

        <kendo-treelist-column
          field="meterId"
          class="text-right"
          [title]="'ADMIN.METERGROUPS.GRID.METER_ID' | translate"
          [width]="120"
          [editable]="false"
        ></kendo-treelist-column>

        <kendo-treelist-column
          field="percentage"
          editor="numeric"
          class="text-right"
          [title]="'ADMIN.METERGROUPS.GRID.PERCENTAGE' | translate"
          [width]="120"
        >
          <ng-template kendoTreeListCellTemplate let-dataItem>
            {{ dataItem.percentage | number:'1.1-3' | suffix:'%' }}
          </ng-template>
        </kendo-treelist-column>

        <kendo-treelist-column
          field="subtract"
          editor="boolean"
          filter="boolean"
          class="text-center"
          [title]="'ADMIN.METERGROUPS.GRID.SUBTRACT' | translate"
          [width]="100"
        >
          <ng-template kendoTreeListCellTemplate let-dataItem let-column="column">
            <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
          </ng-template>
        </kendo-treelist-column>

      </kendo-treelist-column-group>

    </kendo-treelist>
  </column-tooltips>

  <div class="meter-groups-grid-footer">
    <button
      class="button button--primary"
      [disabled]="!editMode || (invalidEditForm$ | async)"
      (click)="onSaveClick()"
    >
      {{ 'SAVE' | translate }}
    </button>

    <button
      class="button button--secondary"
      [disabled]="!editMode"
      (click)="onCancelClick()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
