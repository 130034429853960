import moment from 'moment';

import { momentToString } from '../../../../shared/date.functions';
import ManualQaChangeMeterModalController from './manual-qa-change-meter-modal-controller';

const $inject = ['$modal', 'utils', 'MrcConstants', '$q'];

class ManualQaChangeMeterService {
  static isMeterChangeTooEarly(timestamp, earliestDate) {
    return earliestDate && timestamp.isBefore(earliestDate);
  }

  static hasMeterChangeOnSameHour(history, timestamp) {
    for (const historyItem of history) {
      if (historyItem.date) {
        const date = moment(historyItem.date);
        // History order is such that newest is first.
        // If current history item is before given timestamp there cannot be a meter change
        // on same date as timestamp.
        if (date.isBefore(timestamp, 'day')) {
          return false;
        }

        if (timestamp.isSame(date, 'day') && timestamp.isSame(date, 'hour')) {
          return true;
        }
      }
    }

    return false;
  }

  constructor($modal, utils, MrcConstants, $q) {
    this.$modal = $modal;
    this.utils = utils;
    this.MrcConstants = MrcConstants;
    this.$q = $q;
  }

  /**
   * Returns meter change modal
   *
   * @param {Meter} meter
   * @param {Object} timeStamp
   * @param {Moment[]} effectPeriod
   *
   * @returns {Object}
   */
  getModal(meter, timeStamp, effectPeriod) {
    return this.$modal.open({
      template: `
        <manual-qa-change-meter
          meter="vm.meter" 
          time-stamp="vm.timeStamp"
          effect-period="vm.effectPeriod"
          on-save="vm.changeMeter(meter, timeStamp)" 
          on-close="vm.dismiss()"
        ></manual-qa-change-meter>
      `,
      windowClass: 'tiny',
      controller: ManualQaChangeMeterModalController,
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        meter: () => meter,
        timeStamp: () => timeStamp,
        effectPeriod: () => effectPeriod
      }
    }).result;
  }

  getPreviousValueLabelText(value) {
    const previousValueText = this.utils.localizedString('MQA.METER_CHANGE.PREVIOUS_VALUE_TEXT');
    return `(${previousValueText}: '${value || ''}')`;
  }

  isMeterAutomatic(history) {
    return history[0].meteringType === this.MrcConstants.METERING_TYPE.HOURLY;
  }

  /**
   * Returns earliest possible meter change date
   *
   * @param {Object[]} history meter history
   *
   * @returns {(null|Object)}
   *          null if meter has always been automatic
   *          moment object if meter has been manual and was changed to automatic
   */
  getEarliestAutomaticDate(history) {
    // If earliest history item has meteringType automatic, meter has always been automatic
    // In that case meter change can be done on any timestamp
    if (history[history.length - 1].meteringType === this.MrcConstants.METERING_TYPE.HOURLY) {
      return null;
    }

    let currentHistoryItemDate;

    for (let i = history.length - 1; i >= 0; i--) {
      const historyItem = history[i];

      if (historyItem.meteringType === this.MrcConstants.METERING_TYPE.HOURLY) {
        return moment(currentHistoryItemDate).add(1, 'days');
      }

      currentHistoryItemDate = historyItem.date;
    }

    return null;
  }

  /**
   * Return resolved promise if meter change for meter with meter and for timestamp is possible
   * Otherwise return rejected promise
   *
   * @param {Meter} meter
   * @param {String} timestamp
   *
   * @returns {Object} resolved promise if meter change is possible, rejected promise if not
   *
   * @throws {Error} if timestamp is not given
   */
  isMeterChangePossibleOnTimestamp(history, timestamp) {
    if (!timestamp) {
      throw new Error('Timestamp must be given');
    }

    timestamp = moment(timestamp);
    return this.$q((resolve, reject) => {
      if (!this.isMeterAutomatic(history)) {
        return reject(this.utils.localizedString('MQA.ERRORS.METER_CHANGE_METER_NOT_AUTOMATIC'));
      }

      const earliestDate = this.getEarliestAutomaticDate(history);

      if (ManualQaChangeMeterService.isMeterChangeTooEarly(timestamp, earliestDate)) {
        const tooEarlyErrorMessage = this.utils.localizedString('MQA.ERRORS.METER_CHANGE_TOO_EARLY', {
          time: momentToString(earliestDate)
        });

        return reject(tooEarlyErrorMessage);
      }

      if (ManualQaChangeMeterService.hasMeterChangeOnSameHour(history, timestamp)) {
        return reject(this.utils.localizedString('MQA.ERRORS.METER_CHANGE_ON_SAME_DAY_AND_HOUR'));
      }

      return resolve();
    });
  }

  /**
   * Returns the effect period for a meter change occurring on given timestamp.
   *
   * @param {MeterHistoryItem[]} history
   * @param {moment} timestamp
   *
   * @returns {moment[]}
   */
  getEffectPeriod(history, timestamp) {
    const timestamps = history.map(item => (item.date ? moment(item.date) : null));

    // The first MeterHistoryItem's date is always null, and the latest date is always
    // in the second MeterHistoryItem if it exists.
    const newestTimestamp = timestamps.length > 1 ? timestamps[1] : null;
    if (!newestTimestamp || newestTimestamp.isBefore(timestamp)) {
      return [timestamp, null];
    }

    const oldestTimestamp = timestamps[timestamps.length - 1];
    if (timestamp.isBefore(oldestTimestamp)) {
      return [null, timestamp];
    }

    for (const comparisonTimestamp of timestamps) {
      if (comparisonTimestamp && comparisonTimestamp.isBefore(timestamp)) {
        return [comparisonTimestamp, timestamp];
      }
    }

    return [null, null];
  }
}

ManualQaChangeMeterService.$inject = $inject;

export default ManualQaChangeMeterService;
