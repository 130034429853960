import { ChangeDetectionStrategy, Component, Inject, InjectionToken, OnDestroy } from '@angular/core';
import { from, map, Observable } from 'rxjs';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';

import { QuantityOrMeterReport, ReportModalService } from '../../services/report-modal.service';
import { ReportModalMetersService } from '../../services/report-modal-meters.service';
import { ReportType } from '../../shared/report-type';
import { ReportingSearchService } from '../../services/reporting-search.service';
import { ReportingSearchParams } from '../../shared/reporting-search-params';
import { LegacyFacilityService } from '../../../reportingobjects/models/facilities';
import { UserService } from '../../../../services/user-service';
import { Roles } from '../../../admin/constants/roles';
import { ReportTypeOptionsService } from '../../services/report-type-options.service';
import { ReportTypeOptionsModalService } from '../../services/report-type-options-modal.service';
import { TimePeriodHistoryService } from '../../services/time-period-history.service';
import { MeterTableReportService } from '../../services/meter-table-report.service';

export interface ReportingModalParams {
  facilityId: number;
  meterIds?: number[];
  initialReportType: ReportType;
  initialSearchParams: ReportingSearchParams;
  presentation?: { sections: string[]; charts: boolean; grids: boolean; meterInfo: boolean };
}

export const REPORT_MODAL_PARAMS = new InjectionToken<ReportingModalParams>('Params for report modal');

@Component({
  selector: 'report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ReportingSearchService,
    ReportModalMetersService,
    ReportModalService,
    {
      provide: ReportTypeOptionsService,
      useClass: ReportTypeOptionsModalService,
    },
    TimePeriodHistoryService
  ]
})
@ModalOptions({
  windowClass: 'semiFull modal-dialog-scrollable fixed-height'
})
export class ReportModalComponent extends ModalBase<void> implements OnDestroy {
  public readonly facilityId: number;
  public readonly isMeterReport$: Observable<boolean>;

  public readonly headingText$: Observable<string>;

  public constructor(
    private meterTableReportService: MeterTableReportService,
    reportingSearchService: ReportingSearchService,
    jsFacilities: LegacyFacilityService,
    userService: UserService,
    reportModalService: ReportModalService,
    @Inject(REPORT_MODAL_PARAMS) modalParams: ReportingModalParams,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);

    this.isMeterReport$ = reportModalService.quantityOrMeterReport$.pipe(
      map(t => t === QuantityOrMeterReport.Meter)
    );

    this.headingText$ = from(jsFacilities.getFacility(modalParams.facilityId)).pipe(
      map(facility => {
        if (!userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES)) {
          return `${facility.Name}`;
        }

        return `${facility.Name} (${facility.FacilityInformation.EnegiaId})`;
      })
    );

    this.facilityId = modalParams.facilityId;

    reportingSearchService.setFacilities([modalParams.facilityId]);
    reportingSearchService.search(modalParams.initialSearchParams.formValue);
  }

  public ngOnDestroy(): void {
    this.meterTableReportService.resetGridState();
  }
}
