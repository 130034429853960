import { IAngularStatic } from 'angular';

import LodashModule from '../modules/lodash';
import EnerkeyServicesModule from '../services';
import { consumptionPercent } from './consumption-percent';
import { consumptionValue } from './consumption-value';
import { hasServiceLevelFilter } from './has-service-level';
import { isEmpty } from './is-empty';
import MomentToStringModule from './moment-to-string';
import { reverse } from './reverse';

declare const angular: IAngularStatic;

export default angular.module('EnerKey2.Filters', [
  LodashModule.name,
  MomentToStringModule.name,
  EnerkeyServicesModule.name
])
  .filter('hasServiceLevel', hasServiceLevelFilter)
  .filter('consumptionPercent', consumptionPercent)
  .filter('consumptionValue', consumptionValue)
  .filter('isEmpty', isEmpty)
  .filter('reverse', reverse)
;
