export default function() {
  return {
    scope: {
      documents: '='
    }, //create a new scope
    link: function (scope, el, attrs) {
      el.bind('change', function (event) {
        var files = event.target.files;
        //iterate files since 'multiple' may be specified on the element
        for (var i = 0;i<files.length;i++) {
          //emit event upward
          if (attrs.multiple) {
            scope.documents.push(files[i]);
          }
          else {
            scope.documents = [files[i]];
          }
        }

        scope.$evalAsync();
      });
    }
  };
}
