import ManualQaServiceModule from '../../services';
import ManualQaSearchComponent from './manual-qa-search-component';
import ManualQaInspectModalModule from '../manual-qa-inspect-modal';

const dependencies = [ManualQaServiceModule.name, ManualQaInspectModalModule.name];

const ManualQaSearchModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search', dependencies)
  .component('manualQaSearch', ManualQaSearchComponent)
;

export default ManualQaSearchModule;
