import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { UserService } from '../../../../services/user-service';
import { EnerkeyNg1StateDeclaration, EnerkeyNg2StateDeclaration } from '../../../../shared/routing';
import { ProfileService } from '../../../../shared/services/profile.service';
import {
  facilityManagementState,
  meterGroupsManagementState,
  meterManagementState,
  profileManagementState,
  userManagementState
} from '../../../../modules/admin/admin.states';
import { alarmManagementTabState } from '../../../../modules/alarm-management/alarm-management-topbar-state';
import { contractTabState } from '../../../../modules/contracts/contract-tab-state';
import { contactManagerTabState } from '../../../../modules/contact-manager/contact-manager-tab-state';
import { tenantManagerTabState } from '../../../../modules/tenant-manager/tenant-manager-tab-state';
import { newsTabState } from '../../../../modules/news-management/news-tab-state';
import { powerOfAttorneyTabState } from '../../../../modules/power-of-attorney/power-of-attorney-tab-state';

export const adminTabStates = [
  userManagementState,
  profileManagementState,
  facilityManagementState,
  meterManagementState,
  meterGroupsManagementState,
  contactManagerTabState,
  alarmManagementTabState,
  contractTabState,
  tenantManagerTabState,
  newsTabState,
  powerOfAttorneyTabState
];

@Component({
  selector: 'admin-tabs',
  templateUrl: './admin-tabs.component.html',
  styleUrls: ['./admin-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminTabsComponent implements OnDestroy {
  public readonly tabs$: Observable<(EnerkeyNg2StateDeclaration | EnerkeyNg1StateDeclaration)[]>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    userService: UserService,
    profileService: ProfileService
  ) {
    this.tabs$ = profileService.profile$.pipe(
      map(() => adminTabStates.filter(state => userService.hasAccess(state.data.auth))),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
