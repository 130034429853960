import MetersReportSumController from './meters-report-sum.controller';
import MetersReportSumTemplate from 'raw-loader!./meters-report-sum.html';

export default {
  template: MetersReportSumTemplate,
  controller: MetersReportSumController,
  controllerAs: 'vm',
  bindings: {
    reportParams: '<',
    cache: '<'
  }
};
