const $inject = ['$element', 'ManualQaSearchListEditService'];

class ManualQaSearchListButtonsController {
  constructor($element, ManualQaSearchListEditService) {
    this.editService = ManualQaSearchListEditService;
    this.$element = $element;
  }

  openSaveSearchListModal(searchList) {
    this.editService
      .getModal(angular.copy(searchList))
      .then(result => this.onSearchListChange({ searchList: result }))
    ;
  }

  $postLink() {
    this.$element.find('#mqa-inspect').focus();
  }
}

ManualQaSearchListButtonsController.$inject = $inject;

export default ManualQaSearchListButtonsController;
