import { merge } from 'lodash';

import { $InjectArgs } from '@enerkey/ts-utils';

import { LanguageService } from '../shared/services/language.service';
import { UserService } from './user-service';

export class HttpConfigService {
  public static readonly $inject: $InjectArgs<typeof HttpConfigService> = ['languageService', 'UserService']

  public constructor(
    private readonly languageService: LanguageService,
    private readonly userService: UserService
  ) { }

  /**
   * Creates the default Angular $http request configuration object.
   * @param {object} [extendedOptions] - any custom http request options that need to be included
   * in the request.
   * @returns A configuration object for Angular $http requests. Includes:
   *  - user language url parameter
   *  - current profile's reporting object set id url parameter
   *  - default timeout 2 minutes
   *  - authorization header with the bearer access token
   *  + any custom parameters passed into the function.
   */
  public getExtendedHttpConfig(
    extendedOptions?: Record<string, unknown>
  ): Record<string, unknown> {
    const language = this.languageService.getApiLocale();
    const profileId = this.userService.getCurrentProfile().reportingObjectSetId;

    const options: Record<string, unknown> = {
      params: {
        lang: language,
        ReportingObjectSetId: profileId,
      },
      timeout: 120_000,
      headers: {
        ...this.userService.getAuthorizationHeader(),
        'Accept-Language': language,
        'X-Enerkey-Profile': profileId,
      },
    };

    if (extendedOptions) {
      return merge(options, extendedOptions);
    }

    options.isCancellable = false;
    return options;
  }
}
