import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'abbreviate' })
export class AbbreviatePipe implements PipeTransform {

  public transform(value: string): string {
    return String.abbreviate(value);
  }

}
