toggleGridContent.$inject = ['$parse', '$', '$rootScope', '$compile', '_'];

function toggleGridContent($parse, $, $rootScope, $compile, _) {
  const template =
    '<button class="button icon toggle-grid-content-button" tooltip="{{settings.buttonTooltip}}">' +
    '<i class="fa" ng-class="{\'fa-caret-up\': settings.isHidden, \'fa-caret-down\': !settings.isHidden }"></i>' +
    '</button>';

  return {
    restrict: 'A',
    scope: false,
    link: function(scope, element, attrs) {
      const getter = $parse(attrs.gridContent);
      const setter = getter.assign;
      scope.settings = {};

      angular.element(element).on('click', '.toggle-grid-content-button', () => {
        toggleGridContent(element, true);
      });

      function getColumn(el) {
        return el.find(`thead [data-field=${attrs.field}]`);
      }

      function getButton(el) {
        const column = getColumn(el);
        return column ? column.find('button.toggle-grid-content-button') : void 0;
      }

      function getContentElements(el) {
        return el.find('div[class^="k-grid-content"],div[class*="k-grid-content"]');
      }

      // eslint-disable-next-line no-shadow
      function toggleGridContent(el, modified, forceHide) {
        el = el || element;
        let hideContent = getter(scope).hidden;
        if (_.isBoolean(forceHide)) {
          // force state
          hideContent = forceHide;
        } else {
          // toggle showing content
          hideContent = !hideContent;
        }

        // find content elements
        const contentElements = getContentElements(el);

        // show/hide grid content
        const displayValue = hideContent ? 'none' : '';
        contentElements.each(function() {
          // eslint-disable-next-line no-invalid-this
          angular.element(this).css({ display: displayValue });
        });

        // set active
        const button = getButton(el);
        if (button) {
          if (hideContent) {
            scope.settings.isHidden = true;
          } else {
            scope.settings.isHidden = false;
          }
        }

        setter(scope, { modified: modified, hidden: hideContent });
      }

      function addButton(el) {
        const column = getColumn(el);
        if (column) {
          scope.settings.buttonTooltip = attrs.buttonTooltip;
          column.html($compile(template)(scope));
        }
      }

      attrs.$observe('buttonTooltip', () => {
        addButton(angular.element(`#${attrs.id}`));
      });

      // if enabled toggling of content, initialize once id is defined
      const kendoWidgetCreatedUnbind = $rootScope.$on('kendoWidgetCreated', (event, widget) => {
        scope.$watch(
          () => widget.element ? widget.element[0].id : void 0,
          id => {
            if (id === attrs.id) {
              const el = angular.element(`#${id}`);
              if (el) {
                addButton(el);

                const gridContent = getter(scope);
                if (gridContent && gridContent.hidden) {
                  toggleGridContent(el, gridContent.modified, true);
                }
              }
            }
          }
        );
      });

      scope.$on('$destroy', () => {
        kendoWidgetCreatedUnbind();
      });
    }
  };

}

export default toggleGridContent;
