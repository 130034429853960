import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IncomingMessagesService } from '../../services/incoming-messages.service';

@Component({
  selector: 'incoming-messages',
  templateUrl: './incoming-messages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IncomingMessagesService]
})
export class IncomingMessagesComponent {
}
