import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from '@progress/kendo-angular-editor';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { EmailSendModalComponent } from './components/email-send-modal/email-send-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { ManualQaDefectEmailService } from './services/manual-qa-defect-email.service';
import {
  EmailCommonValuesFormComponent
} from './components/email-common-values-form/email-common-values-form.component';
import {
  EmailFieldSelectionFormComponent
} from './components/email-field-selection-form/email-field-selection-form.component';
import { EmailContentFormComponent } from './components/email-content-form/email-content-form.component';
import { EmailSendProgressComponent } from './components/email-send-progress/email-send-progress.component';
import { EmailSummaryComponent } from './components/email-summary/email-summary.component';
import { EmailResultPipe } from './pipes/email-result.pipe';
import { EmailStatusIconComponent } from './components/email-status-icon/email-status-icon.component';
import { EkFormsModule } from '@enerkey/ek-forms';
import { mqaStates } from './manual-qa.states';
import { ManualQaSearchWrapperComponent } from './components/manual-qa-search-wrapper/manual-qa-search-wrapper.component';
import { ManualQaSearchDirective } from './components/manual-qa-search.directive';
import { ManualQaSearchListsDirective } from './components/manual-qa-search-lists.directive';
import { ManualQaSearchListsWrapperComponent } from './components/manual-qa-search-lists-wrapper/manual-qa-search-lists-wrapper.component';
import { ManualQaIndexWrapperComponent } from './components/manual-qa-index-wrapper/manual-qa-index-wrapper.component';
import { ManualQaIndexDirective } from './components/manual-qa-index.directive';

@NgModule({
  declarations: [
    EmailSendModalComponent,
    EmailCommonValuesFormComponent,
    EmailFieldSelectionFormComponent,
    EmailContentFormComponent,
    EmailSendProgressComponent,
    EmailSummaryComponent,
    EmailResultPipe,
    EmailStatusIconComponent,
    ManualQaSearchWrapperComponent,
    ManualQaSearchDirective,
    ManualQaSearchListsDirective,
    ManualQaSearchListsWrapperComponent,
    ManualQaIndexWrapperComponent,
    ManualQaIndexDirective,
  ],
  imports: [
    UIRouterUpgradeModule.forChild({ states: mqaStates }),
    CommonModule,
    SharedModule,
    EditorModule,
    EkFormsModule,
  ],
  providers: [
    ManualQaDefectEmailService
  ],
  exports: [
    EmailSendModalComponent
  ],
})
export class ManualQaModule { }
