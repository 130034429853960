import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { SortDescriptor } from '@progress/kendo-data-query';
import { PagerSettings } from '@progress/kendo-angular-grid';

import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { FacilityService } from '../../../../shared/services/facility.service';
import { AjsModalService } from '../../../../services/modal/modal.service';
import { AppStatusService } from '../../../../services/app-status-service';
import {
  ReportRow,
  simpleReportKeys,
  simpleReportQuantityIds,
  SimpleReportTimeframeOption,
  SimpleTableReportService
} from '../../services/simple-table-report.service';

@Component({
  selector: 'simple-table-report',
  templateUrl: './simple-table-report.component.html',
  styleUrls: ['./simple-table-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SimpleTableReportService]
})
export class SimpleTableReportComponent implements OnDestroy {
  public readonly quantityIds = simpleReportQuantityIds;
  public readonly valueKey = simpleReportKeys.value;
  public readonly changeKey = simpleReportKeys.change;
  public readonly isIncompleteKey = simpleReportKeys.isIncomplete;
  public readonly facilityNameKey = simpleReportKeys.facilityName;
  public readonly valuesKey = simpleReportKeys.values;

  public sort: SortDescriptor[] = [{
    field: simpleReportKeys.facilityName,
    dir: 'asc'
  }];

  public readonly loading$: Observable<boolean>;

  public readonly isMobileView$: Observable<boolean>;
  public readonly quantityColumnWidth$: Observable<number>;
  public readonly pagerSettings$: Observable<PagerSettings>;

  public readonly timeFrameSelections$: Observable<SimpleReportTimeframeOption[]>;
  public readonly consumptions$: Observable<ReportRow[]>;

  public readonly selectedTimeFrame$: Observable<SimpleReportTimeframeOption>;

  private readonly _selectedTimeFrame$ = new Subject<SimpleReportTimeframeOption>();
  private readonly _loading$ = new LoadingSubject(true);

  public constructor(
    private readonly facilityService: FacilityService,
    private readonly mobileReportService: SimpleTableReportService,
    private readonly angularjsModalService: AjsModalService,
    appStatusService: AppStatusService
  ) {
    this.loading$ = this._loading$.asObservable();
    this.isMobileView$ = appStatusService.isMobileWidth$;
    this.quantityColumnWidth$ = this.isMobileView$.pipe(
      map(isMobile => isMobile ? 80 : 110)
    );

    this.pagerSettings$ = this.isMobileView$.pipe(
      map(
        isMobile => isMobile
          ? { buttonCount: 5, info: false }
          : { buttonCount: 10, info: true }
      )
    );

    this.timeFrameSelections$ = this.mobileReportService.getTimeFrameSelections();

    this.selectedTimeFrame$ = merge(
      this.timeFrameSelections$.pipe(
        map(options => options[0])
      ),
      this._selectedTimeFrame$
    ).pipe(
      distinctUntilKeyChanged('timeFrame'),
      shareReplay(1)
    );

    this.consumptions$ = combineLatest([
      this.selectedTimeFrame$,
      this.facilityService.filteredProfileFacilities$
    ]).pipe(
      switchMap(([timeFrame, facilities]) => this.mobileReportService.getConsumptions(timeFrame, facilities).pipe(
        indicate(this._loading$)
      ))
    );
  }

  public ngOnDestroy(): void {
    this._selectedTimeFrame$.complete();
    this._loading$.complete();
  }

  public openModal(dataItem: ReportRow): void {
    const reportParams = {
      facilityId: [dataItem.facilityId],
      unitKey: 'Default'
    };
    const modalParams = {
      reportParams: reportParams
    };

    this.angularjsModalService.getModalWithComponent('report-modal', modalParams);
  }

  public setTimeFrame(timeFrame: SimpleReportTimeframeOption): void {
    this._selectedTimeFrame$.next(timeFrame);
  }
}
