import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { Quantities } from '@enerkey/clients/metering';
import { AsyncEnumTranslatePipe } from '@enerkey/ek-common';

import { quantityTranslations } from '../../constants/quantity.constant';

@Pipe({ name: 'quantityName' })
export class QuantityNamePipe extends AsyncEnumTranslatePipe<Quantities | string | number> implements PipeTransform {
  protected readonly translations: Record<number, string> = quantityTranslations;

  public constructor(translateService: TranslateService) {
    super(translateService);
  }

  public override transform(value: string | number): Observable<string> {
    // eslint-disable-next-line eqeqeq
    if (value == Quantities.Undefined) {
      return of('');
    }
    return super.transform(value);
  }
}
