import { NoteType, ReportNote } from '../../shared/report-note';
import { AppStatusService } from '../../../../services/app-status-service';

export class GridReportNotesPopupController {

  public static readonly $inject = ['appStatusService']

  public row: any;
  public type: NoteType;

  public notes: ReportNote[];
  public count: number;
  public isMobile: boolean;

  public constructor(
    private readonly appStatusService: AppStatusService
  ) {}

  public $onInit(): void {
    this.count = this.getCount();
    this.notes = Array.from(this.getNoteData());
    this.isMobile = this.appStatusService.isMobile;
  }

  private getCount(): number {
    return this.getNoteData().length;
  }

  private getNoteData(): ArrayLike<ReportNote> {
    switch (this.type) {
      case 'alarm':
        return this.row.allAlarms;
      case 'action':
        return this.row.allActions;
      case 'comment':
        return this.row.allComments;
    }
  }
}
