import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'download-topbar-item'
})
export class DownloadTopbarItemDirective extends UpgradeComponent {
  public constructor(elementRef: ElementRef, injector: Injector) {
    super('downloadTopbarItem', elementRef, injector);
  }
}

@Directive({
  selector: 'inline-indicator'
})
export class InlineIndicatorDirective extends UpgradeComponent {
  @Input() public type: string;
  @Input() public color: string;
  @Input() public visible: boolean;

  public constructor(elementRef: ElementRef, injector: Injector) {
    super('inlineIndicator', elementRef, injector);
  }
}
