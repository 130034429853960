/**
 * Default color in mqa in mqa components. Used in
 * texts, etc..
 *
 * @type {string}
 */
export const DEFAULT = 'rgba(44,44,44,1)';

/**
 * Default background color in mqa components. Used in text
 * backgrounds etc..
 *
 * @type {string}
 */
export const DEFAULT_BACKGROUND = 'rgba(255,255,255,1)';

/**
 * Color for operation period background.
 * @type {string}
 */
export const OPERATION_PERIOD_BACKGROUND = 'rgba(48,176,0,0.5)';

/**
 * Color for background of locked reading
 *
 * @type {string}
 */
export const LOCKED_READING_BACKGROUND = 'rgba(44,44,44,0.5)';

/**
 * Color for preview series that contains consumption values
 *
 * @type {string}
 */
export const SERIES_PREVIEW_CONSUMPTION = 'rgba(236,54,128,1)';

/**
 * Color for model series that contains consumption values
 *
 * @type {string}
 */
export const SERIES_MODEL_CONSUMPTION = 'rgba(0,173,239,1)';

/**
 * Color for model series that contains cumulative values
 *
 * @type {string}
 */
export const SERIES_MODEL_CUMULATIVE = 'rgba(0,173,239,1)';

/**
 * Color for comparison series that contains consumption values.
 *
 * @type {string}
 */
export const SERIES_COMPARISON_CONSUMPTION = 'rgba(158,158,158,1)';

/**
 * Color for actual series that contains consumption values.
 *
 * @type {string}
 */
export const SERIES_ACTUAL_CONSUMPTION = 'rgba(160,80,161,1)';

/**
 * Color for actual series that contains cumulative values.
 *
 * @type {string}
 */
export const SERIES_ACTUAL_CUMULATIVE = 'rgba(160,80,161,1)';

/**
 * Color for defect issue border f.e. in spreadsheet and chart
 *
 * @type {string}
 */
export const DEFECTS_BORDER = 'rgba(205,31,60,0.75)';

/**
 * Color for defect issue background f.e in spreadsheet and chart
 *
 * @type {string}
 */
export const DEFECTS_BACKGROUND = 'rgba(205,31,60,0.25)';

/**
 * Color for fault background f.e in chart.
 *
 * @type {string}
 */
export const FAULT_BACKGROUND = 'rgba(255,255,255,1)';
