<form [formGroup]="formGroup">
  <div>
    <facility-select
      class="searchInput"
      formControlName="facilityId"
      *labelBefore="'FACILITIES.FACILITY' | translate"
    ></facility-select>
  </div>
  <derived-value-single-select
    formControlName="variableId"
    *labelBefore="'FACILITIES.SIDEBAR.RELATIONAL_VALUES.SECTION_RELATIONAL_VALUES' | translate"
  ></derived-value-single-select>
  <value-type-select
    formControlName="valueOption"
    *labelBefore="'FACILITIES_TOOLBAR.VALUE_OPTION' | translate"
  ></value-type-select>
  <quantity-multi-select
    formControlName="selectedQuantities"
    [quantities]="quantities$ | async"
    *labelBefore="'FACILITIES.QUANTITIES' | translate"
  ></quantity-multi-select>
  <unit-select
    formControlName="unitKey"
    *labelBefore="'FACILITIES.UNIT' | translate"
  ></unit-select>
  <widget-timeframe-select
    formControlName="timeFrameOption"
    *labelBefore="'TIMEFRAME_OPTIONS.TITLE' | translate"
  ></widget-timeframe-select>
  <widget-comparison-period-select
    formControlName="comparisonPeriodOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARISON_SERIE' | translate"
    [showNoneOption]="true"
  ></widget-comparison-period-select>
  <comparability-select
    formControlName="comparableOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARABLE' | translate"
  ></comparability-select>
</form>
