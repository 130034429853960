import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { QuantitySeriesByType } from '../shared/table-report-functions';
import { QuantityNamePipe } from '../../../shared/common-pipes/quantity-name.pipe';

@Pipe({
  name: 'quantityTitle'
})
export class QuantityTitlePipe implements PipeTransform {
  private quantityNamePipe: QuantityNamePipe;
  public constructor(private translate: TranslateService) {
    this.quantityNamePipe = new QuantityNamePipe(this.translate);
  }

  public transform(quantity: QuantitySeriesByType): Observable<string> {
    return this.quantityNamePipe.transform(quantity.quantityId).pipe(map((name: string) => {
      let title = `${name} [${quantity.unit}]`;
      if (quantity.isNormalized) {
        title += ` ${this.translate.instant('FACILITIES.NORMALIZED')}`;
      }
      return title;
    }));
  }

}
