import { IAngularStatic } from 'angular';
import MeterCostMassEdit from './meter-cost-mass-edit.component';
import MeterCostMassEditModal from './meter-cost-mass-edit-modal.component';

declare const angular: IAngularStatic;

export default angular.module('app.modules.admin.components.meter-cost-mass-edit', [
])
  .component('meterCostMassEdit', MeterCostMassEdit)
  .component('meterCostMassEditModal', MeterCostMassEditModal);
