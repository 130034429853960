import { $InjectArgs } from '@enerkey/ts-utils';

import { LocalStorageService } from '../shared/services/local-storage.service';

export class StateLocationService {

  public static $inject: $InjectArgs<typeof StateLocationService> = [
    '$window',
    '$timeout',
    'LocalStorageService'
  ];

  public constructor(
    private readonly $window: ng.IWindowService,
    private readonly $timeout: ng.ITimeoutService,
    private readonly localStorageService: LocalStorageService
  ) { }

  // Save target state to local storage and open a new browser tab.
  // Target state is read by EnerKey2.run on init
  public openStateInNewTab(targetStateName: string, params?: unknown): void {
    this.localStorageService.saveValueByKey('initGoToState', {
      name: targetStateName,
      params: params
    });

    // Calling $window.open directly while the page is still loading will throw an error:
    // "$apply already in progress"
    this.$timeout(() => {
      this.$window.open(this.$window.document.location.origin);
    });
  }
}
