<modal-view-header [headingText]="'ADMIN.BULK_MODIFY_COMPANY.MODIFY_COMPANY_BUTTON' | translate"></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="updateForm"
    (ngSubmit)="submit()"
    id="updateForm">
    <div class="formInputs">
      <company-dropdown
        formControlName="companyId"
      ></company-dropdown>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="updateForm"
    >
      {{ 'ADMIN.BULK_MODIFY_COMPANY.SAVE_BUTTON' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>