import moment from 'moment';
import _ from 'lodash';
import { Interval, isWithinInterval, subMinutes } from 'date-fns';

import { ReportSettingsSeries } from '../interfaces/report-settings';
import { ActionSlimViewModel } from '@enerkey/clients/attachments';
import { NoteContainer, NoteDataContainer, ReportNote } from '../shared/report-note';
import { AlarmService } from '../../../shared/services/alarm.service';
import { ErResolutions } from '../constants/er-resolutions';
import { LogLiteDto } from '@enerkey/clients/alarm';
import { ColorService } from '../../../shared/services/color.service';

export interface IActionsToTimeseriesMapper {
  getNotesRelevantToGraph(
    noteData: NoteDataContainer,
    series: ReportSettingsSeries,
    quantityId: number,
    meterId?: number
  ): NoteContainer;
  appendNotesOnTimeline(noteData: NoteContainer, quantityGraph: any, series: ReportSettingsSeries): void;
  getNotesForTable(noteData: NoteContainer): ReportNote[];
  getNotesForPopups(noteData: NoteDataContainer): NoteContainer;
  noteVisualization(e: any): kendo.drawing.Group;
}

export class ActionsToTimeseriesMapper implements IActionsToTimeseriesMapper {

  public static readonly $inject = ['utils', 'alarmService', 'ColorService'];

  private readonly iconSize = 10;
  private readonly marginBottom: number;
  private readonly popupLimit = 10;

  public constructor(
    private readonly utils: any,
    private readonly alarmService: AlarmService,
    private readonly colorService: ColorService
  ) {
    this.marginBottom = 3 * this.iconSize + 10;
  }

  public getNotesRelevantToGraph(
    noteData: NoteDataContainer,
    series: ReportSettingsSeries,
    quantityId: number,
    meterId?: number
  ): NoteContainer {
    const relevantActionsAndComments = this.getActionsRelevantToGraph(noteData.actions, series, quantityId, meterId);
    return {
      alarms: this.getAlarmsRelevantToGraph(noteData.alarms, quantityId, meterId),
      actions: relevantActionsAndComments.filter(note => note.type === 'action'),
      comments: relevantActionsAndComments.filter(note => note.type === 'comment')
    };
  }

  public appendNotesOnTimeline(
    noteData: NoteContainer,
    quantityGraph: any,
    series: ReportSettingsSeries
  ): void {
    const isHourly = series.Resolution === ErResolutions.PT1H;
    const notes = this.mapNotesByChart(noteData, quantityGraph.chartOptions, isHourly);
    const groupedNotes = this.groupNotesByTimeIndex(notes);
    this.appendNotesIntoKendoChart(groupedNotes, quantityGraph.chartOptions);
  }

  public getNotesForTable(noteData: NoteContainer): ReportNote[] {
    const notes = [...noteData.actions, ...noteData.comments];
    return notes.sort((noteA, noteB) => noteA[noteA.matchedDate] > noteB[noteB.matchedDate] ? -1 : 1);
  }

  public getAlarmsForTable(noteData: NoteContainer): ReportNote[] {
    const alarms = [...noteData.alarms];
    return alarms.sort((noteA, noteB) => noteA[noteA.matchedDate] > noteB[noteB.matchedDate] ? -1 : 1);
  }

  public getNotesForPopups(noteData: NoteDataContainer): NoteContainer {
    const alarms = noteData.alarms
      .sortBy('executedAt', 'desc')
      .slice(0, this.popupLimit)
      .map(alarm => ReportNote.parse(alarm));
    const sortedActionNotes = noteData.actions
      .sortBy('updatedAt', 'desc')
      .map(action => ReportNote.parse(action));
    const comments = sortedActionNotes
      .filter(note => note.type === 'comment')
      .slice(0, this.popupLimit);
    const actions = sortedActionNotes
      .filter(note => note.type === 'action')
      .slice(0, this.popupLimit);
    return { alarms, comments, actions };
  }

  public noteVisualization(e: any): kendo.drawing.Group {
    const notes: NoteContainer = e.dataItem.notes;

    const group = new kendo.drawing.Group({ zIndex: 2 } as any);
    const availableSpaceMidPointX = e.rect.size.width / 2;
    const notificationLeft = e.rect.origin.x;
    const notificationTop = _.get(e.sender, 'options.chartArea.height', e.rect.origin.y) - this.marginBottom;
    const iconHorizontalPosition = notificationLeft + availableSpaceMidPointX - this.iconSize / 2;

    if (Array.hasItems(notes.alarms)) {
      const position = new kendo.geometry.Point(iconHorizontalPosition, notificationTop + 2 * this.iconSize);
      group.append(this.getIcon(notes.alarms, position, n => this.alarmTooltipTemplate(n)));
    }

    if (Array.hasItems(notes.comments)) {
      const position = new kendo.geometry.Point(iconHorizontalPosition, notificationTop + this.iconSize);
      group.append(this.getIcon(notes.comments, position, n => this.actionTooltipTemplate(n)));
    }

    if (Array.hasItems(notes.actions)) {
      const position = new kendo.geometry.Point(iconHorizontalPosition, notificationTop);
      group.append(this.getIcon(notes.actions, position, n => this.actionTooltipTemplate(n)));
    }

    return group;
  }

  private getAlarmsRelevantToGraph(alarms: LogLiteDto[], quantityId: number, meterId?: number): ReportNote[] {
    return (alarms || [])
      .filter(alarm => alarm.quantityId === quantityId)
      .filter(alarm => Number(alarm.meterId) === meterId || meterId === undefined)
      .map(alarm => ReportNote.parse(alarm));
  }

  /*
    Returns an array of actions that are relevant for the given graph and time series.

    Relevance is based on:
      - correct quantity (if action defines none, it is suitable for all quantities)
      - correct meter id (when in meter context. If action defines none, it is suitable for all meters)
      - either Effect start or Effect end date is within the time line of one of the given series
      - action type is other than general comment

    Returns the actions sorted by their effect start or stop date, latest first. If both dates
    match the series then sort using start date.
    */
  private getActionsRelevantToGraph(
    actions: ActionSlimViewModel[], series: ReportSettingsSeries, quantityId: number, meterId?: number
  ): ReportNote[] {
    const timeFrameDuration = moment.fromIsodurationCached(series.TimeFrame);

    // The dates in the Start object contain local zone hours, need to get
    // rid of those so do a utcDateToDate on them.
    const periodStartAndEndDates = _.map(series.Start, start => ({
      start: this.utils.utcDateToDate(start.value),
      stop: moment(this.utils.utcDateToDate(start.value))
        .add(timeFrameDuration)
        .subtract(1, 'minute')
        .toDate()
    }));

    return actions
      .filter(action => !Array.hasItems(action.quantityIds) || action.quantityIds.includes(quantityId))
      .filter(action => !Number.isInteger(meterId)
        || !Array.hasItems(action.meterIds) || action.meterIds.includes(meterId))
      .map(action => {
        if (!action.effectStartsAt && !action.effectStopsAt) {
          return;
        }
        let matchedActionNote: ReportNote;
        for (const period of periodStartAndEndDates) {
          const startMatch =
              action.effectStartsAt >= period.start &&
              action.effectStartsAt <= period.stop;
          const stopMatch =
            action.effectStopsAt >= period.start &&
            action.effectStopsAt <= period.stop;

          if (startMatch) {
            matchedActionNote = ReportNote.parse(action, 'effectStartsAt');
            break;
          } else if (stopMatch) {
            matchedActionNote = ReportNote.parse(action, 'effectStopsAt');
            break;
          }
        }
        return matchedActionNote;
      })
      .filter(actionNote => actionNote);
  }

  /*
    Indexes actions and comments to a time line defined by the given chart series.

    The zero-based 'index' tells in which data point the action belongs. An action belongs to a data point
    if either the effect start or stop date is inside the data point interval (or at its first or last day).
    If both start and end dates match then two notes are created referencing the same action.

    Note: if the graph displays hourly data the note indices are offset by 12 hours. This aligns the markers
    properly under the day numbers on the graph area.
    */
  private mapNotesByChart(
    noteData: NoteContainer,
    chartOptions: any,
    isHourly = false
  ): ReportNote[] {
    const hourlyOffset = isHourly ? 12 : 0;
    const notes: ReportNote[] = [];
    const checkedStartIndexes: number[] = [];
    const alarmNotes = noteData.alarms;
    const actionAndCommentNotes = [...noteData.actions, ...noteData.comments];

    if (!chartOptions || !chartOptions.series) {
      return [];
    }
    for (const chartSeries of chartOptions.series) {
      if (chartSeries.startIndex === undefined || checkedStartIndexes.includes(chartSeries.startIndex)) {
        continue;
      }
      let corrIndex = 0;
      checkedStartIndexes.push(chartSeries.startIndex);
      for (const dataItem of chartSeries.data) {
        if (!_.get(dataItem, 'value.timeSeriesObject')) {
          continue;
        }
        const interval: Interval = {
          start: dataItem.value.timeSeriesObject.from,
          end: subMinutes(dataItem.value.timeSeriesObject.to, 1)
        };

        alarmNotes
          .filter(alarmNote => isWithinInterval(alarmNote.effectStartsAt, interval))
          .forEach(alarmNote => {
            notes.push(new ReportNote({ ...alarmNote, index: corrIndex + hourlyOffset }));
          });

        actionAndCommentNotes
          .filter(actionNote => isWithinInterval(actionNote.effectStartsAt, interval))
          .forEach(actionNote => {
            notes.push(new ReportNote({
              ...actionNote,
              matchedDate: 'effectStartsAt',
              index: corrIndex + hourlyOffset
            }));
          });

        actionAndCommentNotes
          .filter(actionNote => isWithinInterval(actionNote.effectStopsAt, interval))
          .forEach(actionNote => {
            notes.push(new ReportNote({
              ...actionNote,
              matchedDate: 'effectStopsAt',
              index: corrIndex + hourlyOffset
            }));
          });

        corrIndex += 1;
      }
    }

    return notes;
  }

  private groupNotesByTimeIndex(notes: ReportNote[]): Map<number, NoteContainer> {
    const notesByTimeIndex = notes.toGroupsBy('index');
    return notesByTimeIndex.getKeys().reduce((sorted, timeIndex) => {
      const currentNotes = notesByTimeIndex.get(timeIndex);
      sorted.set(timeIndex, {
        alarms: currentNotes.filter(({ type }) => type === 'alarm'),
        comments: currentNotes.filter(({ type }) => type === 'comment'),
        actions: currentNotes.filter(({ type }) => type === 'action')
      });
      return sorted;
    }, new Map<number, NoteContainer>());
  }

  private appendNotesIntoKendoChart(notes: Map<number, NoteContainer>, chartOptions: any): void {
    if (!chartOptions) {
      return;
    }
    chartOptions.chartArea.margin = {
      bottom: this.marginBottom
    };

    chartOptions.categoryAxis.notes = {
      data: [],
      visual: (e: any) => this.noteVisualization(e)
    };

    notes.getKeys().forEach(timeIndex => {
      chartOptions.categoryAxis.notes.data.push({
        value: timeIndex,
        notes: notes.get(timeIndex)
      });
    });
  }

  private getIcon(
    notes: ReportNote[],
    position: kendo.geometry.Point,
    callback: (n: ReportNote[]) => () => string
  ): kendo.drawing.Text {
    const font = this.colorService.getCssProperty('--font-stack');
    let character = '▲';
    if (notes[0].type !== 'alarm') {
      character = notes.some(note => note.matchedDate === 'effectStartsAt') ? '▶' : '◀';
    }
    return new kendo.drawing.Text(character, position, {
      fill: {
        color: this.getIconColor(notes)
      },
      font: `${this.iconSize}px ${font}`,
      tooltip: {
        content: callback(notes),
        position: 'bottom'
      }
    });
  }

  /*
    Both alarms and actions/comments use same colors for status
      1: Investigation required: red
      2: Under investigation: yellow
      3: Investigation done: green
    */
  private getIconColor(notes: ReportNote[]): string {
    let highestValue = Math.min(
      ...notes
        .filter(note => note.status > 0)
        .map(note => note.status)
    );
    // If there are no actions with investigation set, Math.min will return Infinity. Use grey color for those.
    highestValue = highestValue > 3 ? null : highestValue;
    let colorName: string;
    switch (highestValue) {
      case 1:
        colorName = '--enerkey-investigation-required';
        break;
      case 2:
        colorName = '--enerkey-under-investigation';
        break;
      case 3:
        colorName = '--enerkey-investigation-done';
        break;
      default:
        colorName = '--enerkey-no-investigation-required';
    }
    return this.colorService.getCssProperty(colorName);

  }

  private alarmTooltipTemplate(notes: ReportNote[]): () => string {
    return () => {
      if (notes.length === 1) {
        const note = notes[0];
        const alarmTypeKey = this.utils.localizedString('FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_ALARM');
        const alarmTypeName = this.alarmService.getAlarmTypeName(note.alarm.alarmTypeId);
        const executeAtKey = this.utils.localizedString('FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ALARM_EXECUTED_AT');

        return `<div class="graph-action-tooltip">
          <div class="ellipsis">
            <span class="tooltip-field top-level-type-and-description">
              ${alarmTypeKey}: ${alarmTypeName}
            </span>
          </div>
          <span class="tooltip-field effect-start-or-stop">${executeAtKey}: ${note.dateName}</span>
        </div>`;
      } else if (notes.length > 1) {
        return this.getNoteCountTooltipTemplate(notes);
      } else {
        return '';
      }
    };
  }

  private actionTooltipTemplate(notes: ReportNote[]): () => string {
    return () => {
      if (notes.length === 1) {
        const note = notes[0];

        const topLevelTypeTranslated = note.type === 'comment'
          ? this.utils.localizedString('FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_COMMENT')
          : this.utils.localizedString('FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE_ACTION');
        const typeNameTranslated = this.utils.localizedString(`ACTIONS.ACTIONTYPE_${note.action.actionType}`);
        const dateTranslated = this.utils.localizedString(`ACTIONS.${note.matchedDate}`);
        const levelTranslated = this.utils.localizedString(note.level);

        return `<div class="graph-action-tooltip">
          <div class="ellipsis">
            <span class="tooltip-field top-level-type-and-description">
              ${topLevelTypeTranslated}: ${note.action.reportedDescription}
            </span>
          </div>
          <span class="tooltip-field specific-type">
            ${this.utils.localizedString('FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_TYPE')}: ${typeNameTranslated}
          </span>
          <span class="tooltip-field effect-start-or-stop">${dateTranslated}: ${note.dateName}</span>
          <span class="tooltip-field">
            ${this.utils.localizedString('FACILITIES_REPORT.GRAPH_ACTIONS_POPUP_LEVEL_COLUMN')}: ${levelTranslated}
          </span>
        </div>`;
      } else if (notes.length > 1) {
        return this.getNoteCountTooltipTemplate(notes);
      } else {
        return '';
      }
    };
  }

  private getNoteCountTooltipTemplate(notes: ReportNote[]): string {
    let noteTypeKey: string;
    switch (notes[0].type) {
      case 'alarm':
        noteTypeKey = 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ALARMS_COUNT';
        break;
      case 'comment':
        noteTypeKey = 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_COMMENTS_COUNT';
        break;
      case 'action':
        noteTypeKey = 'FACILITIES_REPORT.GRAPH_ACTIONS_TOOLTIP_ACTIONS_COUNT';
        break;
    }
    const noteCount = this.utils.localizedString(noteTypeKey, { count: notes.length });
    return `<span>${noteCount}</span>`;
  }
}
