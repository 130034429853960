import { $InjectArgs } from '@enerkey/ts-utils';
import { AugmentedAction } from '../../interfaces/em-augmented-action';
import EmMassEditModalController from './em-mass-edit-modal-controller';
import EmMassEditModalTemplate from 'raw-loader!./em-mass-edit-modal.html';

export class EmMassEditService {
  public static readonly $inject: $InjectArgs<typeof EmMassEditService> = [
    '$modal'
  ];

  public constructor(private readonly $modal: any) {
    this.$modal = $modal;
  }

  public getModal(actions: AugmentedAction[]): Promise<any> {
    return this.$modal.open({
      template: EmMassEditModalTemplate,
      windowClass: 'tiny',
      controller: EmMassEditModalController,
      controllerAs: 'vm',
      bindToController: true,
      backdrop: 'static',
      resolve: {
        actionsList: () => actions
      }
    }).result;
  }
}
