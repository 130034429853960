import NumberInputFieldController from './number-input-field-controller';
import template from 'raw-loader!./number-input-field.html';

const NumberInputFieldComponent = {
  template: template,
  controllerAs: 'vm',
  controller: NumberInputFieldController,
  bindings: {
    id: '@',
    label: '@',
    secondaryLabel: '@',
    model: '<',
    isDisabled: '<',
    onChange: '&',
    required: '<'
  }
};

export default NumberInputFieldComponent;
