KendoFunctions.$inject = ['$timeout', '$window', 'kendo'];

export function KendoFunctions($timeout, $window, kendo) {
  return {
    resizeKendoComponent: resizeKendoComponent,
    getFilteredRows: getFilteredRows,
    fillVerticalSpace: fillVerticalSpace,
    selectAllVisibleItems: selectAllVisibleItems,
    isDataFiltered: isDataFiltered,
    getSelectedRows: getSelectedRows,
    getTreeListItemFromEvent: getTreeListItemFromEvent,
    getTooltipContentFromEvent: getTooltipContentFromEvent
  };

  /**
   * Generic resize function for kendo chart and spreadsheet.
   *
   * If parent height or width is zero retries to resize at most three times.
   *
   * @param {Object}   kendoComponent
   * @param {String}   parentSelector
   * @param {Function} [callback]
   * @param {boolean} [reset] If true, set height and width of kendo component to 0 before resizing
   */
  function resizeKendoComponent(kendoComponent, parentSelector = null, callback = null, reset = false) {
    if (!kendoComponent) {
      return;
    }

    if (reset) {
      kendoComponent.element.width(0);
      kendoComponent.element.height(0);
      kendoComponent.resize();
    }

    $timeout(() => {
      doKendoResize(kendoComponent, parentSelector, callback);
    }, 0);
  }

  function doKendoResize(kendoComponent, parentSelector, callback, retryCount = 0) {
    const parentContainer = kendoComponent
      .element
      .parent(parentSelector)
    ;

    const siblings = kendoComponent
      .element
      .prevUntil(parentSelector)
    ;

    let siblingsHeight = 0;

    siblings.each((key, element) => {
      siblingsHeight += angular.element(element).outerHeight();
    });

    if (parentContainer && parentContainer.width() && parentContainer.height()) {
      kendoComponent.element.width(parentContainer.width());
      kendoComponent.element.height(parentContainer.height() - siblingsHeight);
      kendoComponent.resize();

      callback ? callback(kendoComponent) : null;
    } else if (retryCount < 3) {
      $timeout(() => {
        doKendoResize(kendoComponent, parentSelector, callback, retryCount + 1);
      });
    }
  }

  /**
   * Return grids currently filtered rows
   *
   * @param {Object} dataSource kendo dataSource
   *
   * @returns {Array}
   */
  function getFilteredRows(dataSource) {
    const filters = dataSource.filter();
    const allData = dataSource.data();
    const query = new kendo.data.Query(allData);
    return query.filter(filters).data;
  }

  /**
   * Makes grid fill up vertical empty space on page
   *
   * @param {Object} component kendo component (e.g. grid)
   * @param {Number} offset empty space in pixels below the grid
   */
  // NOTE! This has only been tested with kendo grid so far
  function fillVerticalSpace(component, offset) {
    const belowOffset = angular.isNumber(offset) ? offset : 0;
    const topOffset = component.element[0].getBoundingClientRect().top;
    const pageHeight = $window.innerHeight;
    component.element.height(pageHeight - topOffset - belowOffset);
    component.resize();
  }

  /**
   * Select filtered items or all if no filters are in use
   *
   * @param {kendo.data.DataSource} dataSource
   * @param {boolean} state To select/unselect items
   *
   * @returns {Object}
   */
  function selectAllVisibleItems(dataSource, state) {
    const itemsToSelect = isDataFiltered(dataSource) ? getFilteredRows(dataSource) : dataSource.data();

    itemsToSelect.forEach(item => {
      item.selected = state;
    });

    return itemsToSelect;
  }

  function isDataFiltered(dataSource) {
    const filter = dataSource.filter();
    return filter && filter.filters.length > 0;
  }

  /**
   * Returns selected rows from dataSource
   *
   * @param {kendo.data.DataSource} dataSource
   * @param {string} selectionKey
   *
   * @returns {Array}
   */
  function getSelectedRows(dataSource, selectionKey) {
    const allRows = dataSource.data();
    return allRows.filter(row => row[selectionKey]);
  }

  function getTreeListItemFromEvent(event) {
    const tr = angular.element(event.target).closest('tr');
    const treeList = angular.element(event.target)
      .parentsUntil('.k-treelist')
      .parent()
      .data('kendoTreeList')
    ;

    return treeList ? treeList.dataItem(tr) : null;
  }

  function getTooltipContentFromEvent(event) {
    return angular.element(event.sender.element).data('kendoTooltip').content;
  }
}
