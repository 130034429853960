import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CreateFormGroupArgs } from '@progress/kendo-angular-grid';

import { MeterWithProperties } from '@enerkey/clients/metering';

import { AddMetersGrid } from '../../shared/add-meter-grid';
import { TargetMeter } from '../../shared/target-meter';

@Component({
  selector: 'add-meters-target-grid',
  templateUrl: './add-meters-target-grid.component.html'
})
export class AddMetersTargetGridComponent extends AddMetersGrid<TargetMeter> implements AfterViewInit {
  @ViewChild('targetGrid', { read: ElementRef, static: true }) public targetGridElement: ElementRef;
  public createFormGroupFn = this.createFormGroup.bind(this);

  public facilityNameField: keyof TargetMeter = 'facilityName';
  public nameField: keyof TargetMeter = 'name';
  public enegiaIdField: keyof TargetMeter = 'enegiaId';
  public quantityIdField: keyof TargetMeter = 'quantityId';
  public factorField: keyof TargetMeter = 'formulaFactor';
  public nullToZeroField: keyof TargetMeter = 'nullToZero';
  public resolutionField: keyof MeterWithProperties = 'resolution';
  public targetGridStyle = {};

  public constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  public createFormGroup(args: CreateFormGroupArgs): UntypedFormGroup {
    return this.formBuilder.group(args.dataItem);
  }

  public ngAfterViewInit(): void {
    // Set fixed width to kendo grid after it has been rendered. Otherwise resizing columns resizes grid itself.
    setTimeout(() => {
      const targetGridWidth = this.targetGridElement.nativeElement.offsetWidth;
      if (targetGridWidth) {
        this.targetGridStyle = {
          width: `${targetGridWidth.toString()}px`
        };
      }
    });
  }
}
