import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GridsterComponent, GridsterConfig, GridsterItemComponentInterface } from 'angular-gridster2';
import { BehaviorSubject, forkJoin, from, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, shareReplay, switchMap, take } from 'rxjs/operators';

import {
  ConsumptionsRequest,
  EnergyReportingClient,
  FacilityInformationGroup,
  IReadingSet,
  QuantityItem,
  TimeSerieCollection
} from '@enerkey/clients/energy-reporting';
import { switchJoin } from '@enerkey/rxjs';
import { Quantities } from '@enerkey/clients/metering';
import { ServiceLevel } from '@enerkey/clients/facility';
import { DeepPartial } from '@enerkey/ts-utils';
import { WINDOW } from '@enerkey/angular-utils';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { TimeFrame } from '../../../../services/time-frame-service';
import { QuantityService } from '../../../../shared/services/quantity.service';
import { Consumptions, QuantityChartData } from '../../../../shared/energy-reporting-shared/shared/consumptions';
import { RelationalValueId } from '../../../reportingobjects/constants/facilities-properties';
import { ErUtils } from '../../interfaces/er-utils';
import { StartValue } from '../../services/er-time-frame.service';
import { FacilityInfoPipe } from '../../../../shared/pipes/facility-info.pipe';
import { OverviewActionsAndComments, OverviewReportService } from '../../services/overview-report.service';
import { currentMonthToRequestResolution, sortOverviewQuantities } from './facility-overview.functions';
import { ServiceLevelService } from '../../../../shared/services/service-level.service';
import { FACILITY_REPORT } from '../../constants/er-modal-states.constant';
import { ErReportTypeConfig } from '../../shared/er-report-type-config';
import { ReportSettings } from '../../shared/report-settings';

interface OverviewValue {
  Value: number;
  Difference: number;
  DifferencePercent: number;
  ShowDiff?: boolean;
}

type OverviewValueWithRelational = OverviewValue & {
  Costs?: OverviewValue;
  Emissions?: OverviewValue;
  Incomplete?: boolean;
}

export interface OverviewQuantity {
  ID: number,
  Month: OverviewValueWithRelational,
  Year: OverviewValueWithRelational,
  Values: QuantityChartData,
  Unit: string,
  DecimalsToShow: number,
  x: 0,
  y: 0,
  cols: 1,
  rows: 1,
  expanded$: Observable<boolean>,
  _expanded$: BehaviorSubject<boolean>,
}

const gridsterMargin = 10;

type MonthKey = keyof Pick<OverviewQuantity, 'Month'>;
type YearKey = keyof Pick<OverviewQuantity, 'Year'>;

const gridsterMobileBreakpoint = 500;

@Component({
  selector: 'facility-overview',
  templateUrl: './facility-overview.component.html',
  styleUrls: ['./facility-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public facilityId: number[];
  @Output() public readonly goToReport = new EventEmitter<ErReportTypeConfig & DeepPartial<ReportSettings>>();

  @ViewChild(GridsterComponent) public gridster: GridsterComponent;
  @ViewChild('gridContainer') public gridContainer: ElementRef<HTMLElement>;

  public readonly timeFrameControl = new UntypedFormControl('Month');

  public readonly latestYear$: Observable<number>;
  public readonly latestMonth$: Observable<number>;
  public readonly latestMonthName$: Observable<string>;
  public readonly yearText$: Observable<string>;
  public readonly periodTitles$: Observable<string[]>;
  public actionsAndComments$: Observable<OverviewActionsAndComments>;
  public facility$: Observable<FacilityInformationGroup>;
  public quantityData$: Observable<OverviewQuantity[]>;
  public actionDefaultQuantity$: Observable<Quantities>;

  public readonly ReportingUnit = ReportingUnit;

  public readonly hasServiceLevelM: boolean;

  public readonly yearKey: YearKey = 'Year';
  public readonly monthKey: MonthKey = 'Month';

  public readonly gridOptions: GridsterConfig = {
    gridType: 'verticalFixed',
    setGridSize: true,
    fixedRowHeight: 200,
    outerMarginTop: 0,
    maxItemRows: 2,
    maxItemCols: 2,
    minItemRows: 1,
    minItemCols: 1,
    maxCols: 3,
    minCols: 3,
    margin: gridsterMargin,
    mobileBreakpoint: gridsterMobileBreakpoint
  };

  private readonly relationalUnitIds: RelationalValueId[] = [];
  private readonly widthChange$ = new Subject<number>();
  private costsId: RelationalValueId;
  private emissionsId: RelationalValueId;
  private resizeObserver: ResizeObserver;

  private readonly requestTimeFrame$: Observable<StartValue[]>;

  private readonly staticQueryParams: Partial<ConsumptionsRequest> = {
    Cumulative: false,
    Resolution: 'P1M' as any,
    TimeFrame: 'P1Y',
    Start: null,
    Unit: ReportingUnit.Default as any
  };

  public constructor(
    @Inject('erUtils') private readonly erUtils: ErUtils,
    private readonly erClient: EnergyReportingClient,
    private readonly quantityService: QuantityService,
    private readonly facilityInfoPipe: FacilityInfoPipe,
    private readonly overviewReportService: OverviewReportService,
    serviceLevelService: ServiceLevelService,
    @Inject(WINDOW) window: Window
  ) {
    this.hasServiceLevelM = serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium);

    fromEvent(window, 'beforeprint').subscribe(() => {
      this.gridOptions.maxCols = 2;
      this.gridOptions.minCols = 2;
      this.gridOptions.margin = 0;
      this.gridOptions.api.optionsChanged();
      this.autoPosition();
      this.gridOptions.api.resize();
    });

    this.requestTimeFrame$ = from(this.erUtils.getDefaultTimePeriod()).pipe(
      map(timePeriods => [...timePeriods].reverse()),
      shareReplay(1)
    );
    this.periodTitles$ = this.requestTimeFrame$.pipe(
      map(timeFrame => timeFrame.map(period => new Date(period.value).getFullYear().toString()))
    );
    this.latestYear$ = this.requestTimeFrame$.pipe(
      map(timeFrame => new Date(timeFrame[1].value).getFullYear()),
      shareReplay(1)
    );
    this.latestMonth$ = this.latestYear$.pipe(
      map(year => this.erUtils.getLatestMonthNumeric(year))
    );
    this.latestMonthName$ = this.latestYear$.pipe(
      map(year => this.erUtils.getLatestMonth(year))
    );

    this.yearText$ = this.requestTimeFrame$.pipe(
      map(timeFrame => TimeFrame.timeRangeFormat(
        timeFrame[1].value,
        this.staticQueryParams.TimeFrame as any,
        this.staticQueryParams.Resolution as any
      ))
    );

    const relationalUnitIds = this.overviewReportService.getRelationalUnitIds();
    this.costsId = relationalUnitIds.cost;
    this.emissionsId = relationalUnitIds.emission;

    this.relationalUnitIds = [this.costsId, this.emissionsId].filter(value => value);

    this.widthChange$.pipe(
      debounceTime(50),
      distinctUntilChanged()
    ).subscribe(width => {
      const columns = Math.floor(width / (gridsterMobileBreakpoint / 2));
      this.gridOptions.minCols = columns;
      this.gridOptions.maxCols = columns;
      this.gridOptions.api.optionsChanged();
      this.autoPosition();
      this.gridOptions.api.resize();
    });
  }

  public ngAfterViewInit(): void {
    this.resizeObserver = new ResizeObserver(entries => {
      this.widthChange$.next(entries[0].target.clientWidth);
    });

    this.resizeObserver.observe(this.gridContainer.nativeElement);
  }

  public ngOnInit(): void {
    this.actionsAndComments$ = this.overviewReportService.getActionsAndComments(this.facilityId[0]).pipe(
      shareReplay(1)
    );

    const facilityQuantities = from(this.quantityService.getSignificantQuantitiesForFacility(this.facilityId[0])).pipe(
      shareReplay(1)
    );

    this.actionDefaultQuantity$ = facilityQuantities.pipe(
      map(quantities => quantities[0].ID),
      shareReplay(1)
    );

    this.facility$ = this.facilityInfoPipe.transform(this.facilityId[0]);
    this.quantityData$ = forkJoin([
      this.requestTimeFrame$,
      facilityQuantities.pipe(map(sortOverviewQuantities)),
      this.latestMonth$
    ]).pipe(
      map(([start, quantities, latestMonth]) => ({
        params: this.getParams(start, quantities, latestMonth),
        quantities: quantities
      })),
      switchMap(({ params, quantities }) => this.getFacilityValues(params, quantities))
    );
  }

  public ngOnDestroy(): void {
    this.resizeObserver?.unobserve(this.gridContainer.nativeElement);
    this.widthChange$.complete();
  }

  public moveToReport(quantity: OverviewQuantity): void {
    if (!this.hasServiceLevelM) {
      return;
    }
    this.requestTimeFrame$.pipe(take(1)).subscribe(timeFrame => {
      const stateParams = {
        ...FACILITY_REPORT,
        facilityId: this.facilityId,
        quantityId: [quantity.ID],
        series: {
          Measured: true,
          Normalized: false,
          TimeFrame: 'P1Y',
          Start: timeFrame
        },
        unitKey: 'Default'
      };
      this.goToReport.emit(stateParams);
    });
  }

  public changeCardSize(item: GridsterItemComponentInterface): void {
    const expanded = !(item.item as OverviewQuantity)._expanded$.value;
    this.resizeCard(item, expanded);
    this.autoPosition();
  }

  public expandAll(): void {
    this.gridster.grid.forEach(item => {
      this.resizeCard(item, true);
    });
    this.autoPosition();
  }

  public collapseAll(): void {
    this.gridster.grid.forEach(item => {
      this.resizeCard(item, false);
    });
    this.autoPosition();
  }

  private resizeCard(item: GridsterItemComponentInterface, expanded: boolean): void {
    const size = expanded ? 2 : 1;
    item.$item.rows = size;
    item.$item.cols = size;
    item.item._expanded$.next(expanded);
  }

  private autoPosition(): void {
    this.gridster.grid.forEach(item => {
      item.$item.x = 100000;
      item.$item.y = 100000;
      // Don't ever set cols to larger than gridsters maxCols, that freezes browser
      item.$item.cols = Math.min(item.$item.rows, this.gridOptions.maxCols);
    });
    this.gridster.grid.forEach(item => {
      this.gridster.autoPositionItem(item);
      item.checkItemChanges(item.$item, item.item);
    });
  }

  private getParams(start: StartValue[], quantities: QuantityItem[], latestMonth: number): ConsumptionsRequest {
    return {
      ...this.staticQueryParams,
      TimeFrame: currentMonthToRequestResolution(latestMonth),
      Quantities: quantities.map(quantity => ({
        ID: quantity.ID,
        Normalisation: false,
        RelationalUnitIds: this.relationalUnitIds,
        Flags: true,
        Comparables: 'ByQuantity'
      })),
      FacilityId: this.facilityId,
      Start: start as any
    };
  }

  private getFacilityValues(
    params: ConsumptionsRequest,
    facilityQuantities: QuantityItem[]
  ): Observable<OverviewQuantity[]> {
    return this.erClient.postFacilityConsumptions(params).pipe(
      switchJoin(() => this.requestTimeFrame$.pipe(
        map(start => [...start].reverse()),
        take(1)
      )),
      map(([facilityData, start]) => facilityQuantities.reduce((allQuantities, quantity) => {
        const quantityData = facilityData[this.facilityId[0]].SubSeries[quantity.ID];
        if (quantityData) {
          allQuantities.push(
            this.handleQuantityValues(quantityData, quantity, start)
          );
        }
        return allQuantities;
      }, []))
    );
  }

  private handleQuantityValues(
    quantityData: TimeSerieCollection,
    quantityInfo: QuantityItem,
    start: StartValue[]
  ): OverviewQuantity {
    const inspectionPeriodKey = start[0].key;
    const comparisonPeriodKey = start[1].key;
    const reading = 'Reading';
    const values = quantityData.Values;
    const monthArray = values[inspectionPeriodKey];
    const comparison = values[comparisonPeriodKey];

    const latestMonth = monthArray[monthArray.length - 1];
    const comparisonMonth = comparison[comparison.length - 1];

    const latestMonthValue = latestMonth?.[reading]?.Value ?? null;
    const comparisonMonthValue = comparisonMonth?.[reading]?.Value ?? null;
    const monthDiff = this.erUtils.calculateValueDifferences(latestMonthValue, comparisonMonthValue);

    const aggregates = quantityData?.Aggregates;
    const latestYear = aggregates[inspectionPeriodKey];
    const comparisonYear = aggregates[comparisonPeriodKey];
    const latestYearValue = latestYear?.[reading]?.Value ?? null;
    const previousYearValue = comparisonYear?.[reading]?.Value ?? null;

    const monthlyCostValues = this.costsId
      ? this.getRelationalValueAndChange(
        latestMonth,
        comparisonMonth,
        this.costsId
      )
      : null
    ;

    const monthlyEmissionValues = this.getRelationalValueAndChange(
      latestMonth,
      comparisonMonth,
      this.emissionsId
    );

    const yearDiff = this.erUtils.calculateValueDifferences(latestYearValue, previousYearValue);

    const yearlyCostValues = this.costsId
      ? this.getRelationalValueAndChange(
        latestYear,
        comparisonYear,
        this.costsId
      )
      : null
    ;

    const yearlyEmissionValues = this.getRelationalValueAndChange(
      latestYear,
      comparisonYear,
      this.emissionsId
    );

    const unit = quantityInfo.Units.Default;

    const expanded$ = new BehaviorSubject(false);

    return {
      ID: quantityInfo.ID,
      Month: {
        Difference: monthDiff.Difference,
        DifferencePercent: monthDiff.DifferencePercent,
        Value: latestMonthValue,
        Costs: monthlyCostValues,
        Emissions: monthlyEmissionValues,
        Incomplete: this.isIncomplete(latestMonth, reading),
        ShowDiff: ![latestMonth, comparisonMonth].some(v => this.isIncomplete(v, reading))
      },
      Year: {
        Difference: yearDiff.Difference,
        DifferencePercent: yearDiff.DifferencePercent,
        Value: latestYearValue,
        Costs: yearlyCostValues,
        Emissions: yearlyEmissionValues,
        Incomplete: this.isIncomplete(latestYear, reading),
        ShowDiff: ![latestYear, comparisonYear].some(v => this.isIncomplete(v, reading))
      },
      Values: Consumptions.getWidgetData(
        quantityData,
        quantityInfo.ID,
        reading,
        start,
        this.relationalUnitIds
      ),
      Unit: unit.Unit,
      DecimalsToShow: unit.DecimalsToShow,
      x: 0,
      y: 0,
      cols: 1,
      rows: 1,
      expanded$: expanded$.pipe(
        switchMap(
          exp => exp
            ? of(exp).pipe(delay(300))
            : of(exp)
        )
      ),
      _expanded$: expanded$,
    };
  }

  private isIncomplete(value: IReadingSet, reading: 'Reading' | 'NormalizedReading'): boolean {
    return value?.[reading]?.Flags?.Incomplete;
  }

  private getRelationalValueAndChange(
    period: IReadingSet,
    comparisonPeriod: IReadingSet,
    relationalValueId: RelationalValueId
  ): { Difference: number; DifferencePercent: number; Value: number } {
    const value = this.getPeriodRelationalValue(period, relationalValueId);
    const comparisonValue = this.getPeriodRelationalValue(comparisonPeriod, relationalValueId);
    const diff = this.erUtils.calculateValueDifferences(value, comparisonValue);
    return value
      ? {
        Value: value,
        ...diff
      }
      : null
    ;
  }

  private getPeriodRelationalValue(period: IReadingSet, relationalValueId: RelationalValueId): number {
    return period.RelationalValues?.[relationalValueId]?.Reading?.Value ?? null;
  }
}
