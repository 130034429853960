<modal-view-header
  [headingText]="(existingRow ? 'SUSTAINABILITY.GRI.EDIT_ROW' : 'SUSTAINABILITY.GRI.ADD_ROW') | translate"
></modal-view-header>

<div class="modal-body">
  <form [formGroup]="formGroup">
    <p>
      <kendo-textbox
        *labelBefore="'SUSTAINABILITY.GRI.DESCRIPTION' | translate"
        formControlName="description"
      ></kendo-textbox>
    </p>

    <p>
      <gri-unit-combo
        *labelBefore="'SUSTAINABILITY.GRI.UNIT_NAME' | translate"
        formControlName="rowUnitId"
        [rowUnitTypes]="rowUnitTypes"
        [quantitySorter]="quantitySorter"
      ></gri-unit-combo>

      <button
        kendoButton
        class="add-button"
        fillMode="solid"
        type="button"
        [icon]="'plus'"
        [size]="'small'"
        (click)="addUnit()"
      >
        {{ 'SUSTAINABILITY.GRI.ADD_UNIT' | translate }}
      </button>
    </p>

    <p>
      <ek-filterable-combo
        *labelBefore="'SUSTAINABILITY.GRI.CATEGORY' | translate"
        [items]="categoryOptions$ | async"
        formControlName="categoryId"
        [virtual]="true"
        [filterable]="true"
        [noTranslate]="true"
      ></ek-filterable-combo>
    </p>

  </form>
</div>

<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="button"
      (click)="submit()"
      [disabled]="!formGroup.dirty || !formGroup.valid"
    >
      {{ (existingRow ? 'EDIT' : 'CREATE') | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
