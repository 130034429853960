import { Pipe, PipeTransform } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Returns a property from object using a property path.
 */
@Pipe({ name: 'propertyPath' })
export class PropertyPathPipe implements PipeTransform {

  /**
   * @param value Object to get the value from
   * @param propertyNames Property names, does not support `[]`-syntax, see examples #3 and #4
   * @example
   * pipe.transform(component, 'formGroup', 'controls', 'name', 'id')
   * pipe.transform(component, 'formGroup.controls.name.id')
   * pipe.transform(arrayOfObjects, '0.property')
   * pipe.transform(arrayOfObjects, '0', 'property')
   */
  public transform(value: Record<keyof unknown, unknown>, ...propertyNames: string[]): any {
    return propertyNames.flatMap(property => property.split('.')).reduce(
      (obj: any, prop: string) => obj?.[prop],
      value
    );
  }
}
