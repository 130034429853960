<modal-view-header
  [headingText]="report.displayName"
></modal-view-header>

<ngf-tabset [activeId]="displayMode" [noPadding]="true">
  <ngf-tab
    [title]="'SUSTAINABILITY.GRI.OVERVIEW' | translate"
    id="chart"
  ></ngf-tab>
  <ngf-tab
    [title]="'SUSTAINABILITY.GRI.EDIT_REPORT' | translate"
    id="grid"
  ></ngf-tab>
  <ngf-tab
   [title]="'SUSTAINABILITY.GRI.EDIT_UNITS' | translate"
   id="quantities"
  ></ngf-tab>
</ngf-tabset>

<div class="modal-body">
  <div class="report-header-container">
    <div>
      <h1>{{ report.description }}</h1>
      <h2>
        {{ report.year }}&nbsp;&#x25cf;&nbsp;
        <span [ngfTooltip]="'SETTINGS.PROFILE' | translate">{{ profileName$ | async }}</span>
        <ng-container *ngIf="companyName$ | async as companyName">
          &nbsp;&#x25cf;&nbsp;
          <span [ngfTooltip]="'ORGANIZATION' | translate">{{ companyName }}</span>
        </ng-container>
      </h2>
    </div>
    <div *ngIf="displayMode === 'chart'" class="export-buttons">
      <button
        kendoButton
        themeColor="base"
        icon="image-export"
        (click)="exportChart()"
        [disabled]="loading$ | async"
      >
        {{ 'SUSTAINABILITY.GRI.CHART_EXPORT' | translate }}
      </button>
      <button
        kendoButton
        themeColor="base"
        icon="save"
        (click)="exportSummaryPdf()"
        [disabled]="loading$ | async"
      >
        {{ 'SUSTAINABILITY.GRI.PDF_EXPORT' | translate }}
      </button>
    </div>
  </div>

  <ng-container [ngSwitch]="displayMode">
    <gri-report-container
      *ngSwitchCase="'grid'"
      [report]="report"
      (isModifiedChanged)="isReportModified = $event"
      [(expandedGroupKeys)]="expandedGroupKeys"
    ></gri-report-container>

    <gri-charts
      *ngSwitchCase="'chart'"
      [report]="report"
    ></gri-charts>

    <gri-quantity-grid
      *ngSwitchCase="'quantities'"
      [report]="report"
    ></gri-quantity-grid>
  </ng-container>
</div>

<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--primary"
      type="button"
      [disabled]="!isReportModified"
      [loading]="loading$ | async"
      (click)="saveChanges()"
    >
      {{ 'SUSTAINABILITY.GRI.SAVE_CHANGES' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="closeReport()"
    >
      {{ 'MODALS.CLOSE' | translate }}
    </button>
  </div>
</div>
