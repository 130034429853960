import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { map, shareReplay, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { FileInfo, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';

import { ModalBase, ModalService, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { formGroupFrom, getNumericEnumValues } from '@enerkey/ts-utils';
import {
  AttachmentsClient,
  AttachmentType,
  DocumentCompleteViewModel,
  DocumentType,
  DocumentViewModel,
  PartnerVisibilityType
} from '@enerkey/clients/attachments';
import { FacilityClient } from '@enerkey/clients/facility';

import { UserService } from '../../../../services/user-service';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { AuthenticationService } from '../../../../shared/services/authentication.service';
import { CompaniesService } from '../../../../shared/services/companies.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { DocumentsPreviewComponent } from '../documents-preview/documents-preview.component';
import {
  convertPeriodToBackendFormat,
  getDocumentTypeSelection,
  mapPeriodLengthToFriendlyString
} from '../../../../constants/document.constant';
import { Period } from '../../../../shared/ek-inputs/dateperiod-select/dateperiod-select.component';
import { FileReaderService } from '../../../../shared/services/file-reader.service';

interface IDocumentForm {
  levelTypeSelect: AttachmentType,
  type: number,
  expireDate: Date,
  organization: number,
  partnerVisibility: PartnerVisibilityType,
  facility: number,
  period: string
}

@Component({
  selector: 'documents-operation-modal',
  templateUrl: './documents-operation-modal.component.html',
  styleUrls: ['./documents-operation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsOperationModalComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public selectedDocument: DocumentCompleteViewModel;
  public defaultType: AttachmentType;
  public meter: { id: number; name: string };
  public facilityId: number;
  public formDisabled = false;

  public readonly selectedPartner$: Observable<string>;
  public readonly periodType$: Observable<Period>;
  public readonly selectedOrganization$: Observable<string>;
  public documentTypeItems$: Observable<ComboItem<number>[]>;

  public isExistingOrForSingleFacility: boolean;

  public readonly uploadedFiles: FileInfo[] = [];
  public readonly documentsForm: UntypedFormGroup;
  public readonly AttachmentType = AttachmentType;
  public readonly DocumentType = DocumentType;
  public readonly facilityList$: Observable<ComboItem<number>[]>;
  public readonly facilityLoading$: Observable<boolean>;
  public readonly partnerVisibilityItems: ComboItem<PartnerVisibilityType>[];
  public readonly currentYear = new Date().getFullYear();
  public readonly loading$: Observable<boolean>;
  public readonly isSuperCompanyUser$: Observable<boolean>;

  private readonly _facilityLoading$ = new LoadingSubject();
  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly fileReaderService: FileReaderService,
    private readonly translateService: TranslateService,
    private readonly companiesService: CompaniesService,
    private readonly attachmentClient: AttachmentsClient,
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService,
    private readonly modalService: ModalService,
    userService: UserService,
    facilityClient: FacilityClient,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.facilityLoading$ = this._facilityLoading$.asObservable();
    this.partnerVisibilityItems = getNumericEnumValues(PartnerVisibilityType).map(
      option => ({
        value: option,
        text: translateService.instant(
          `DOCUMENTS.PARTNER_VISIBILITY_TYPE_${PartnerVisibilityType[option].toUpperCase()}`
        )
      })
    );
    this.facilityList$ = facilityClient.getProfileFacilities(userService.profileId).pipe(
      indicate(this._facilityLoading$),
      map(facilities => facilities.map(facility => ({
        value: facility.id,
        text: `${facility.id}: ${facility.displayName}`
      }))),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
    const organizationId = authenticationService.organizationId;
    this.documentsForm = formGroupFrom<IDocumentForm>({
      levelTypeSelect: null,
      organization: organizationId,
      partnerVisibility: null,
      facility: null,
      type: DocumentType.Other,
      expireDate: null,
      period: null
    }, {
      levelTypeSelect: Validators.required,
      type: Validators.required,
      organization: Validators.required,
    });

    this.periodType$ = this.documentsForm.get('type').valueChanges.pipe(
      map(value => [3, 4, 5].includes(value) ? value : null)
    );

    this.selectedOrganization$ = combineLatest([
      this.documentsForm.get('organization').valueChanges.pipe(
        startWith(organizationId)
      ),
      this.companiesService.companies$.pipe(take(1))
    ]).pipe(
      map(([value, companies]) => companies.find(c => c.id === value)?.name)
    );

    this.selectedPartner$ = this.documentsForm.get('partnerVisibility').valueChanges.pipe(
      map(value => this.partnerVisibilityItems.find(p => p.value === value)?.text)
    );
    this.isSuperCompanyUser$ = this.companiesService.isSuperCompanyUser$;
  }

  public ngOnInit(): void {
    this.documentTypeItems$ = this.getTypeDropdownItems();

    this.documentsForm.patchValue({
      levelTypeSelect: this.defaultType,
    });

    this.isExistingOrForSingleFacility = !!(this.selectedDocument || this.facilityId);

    // EDIT MODE
    if (this.selectedDocument) {
      let period = null;
      if (this.selectedDocument?.periodLength) {
        period = mapPeriodLengthToFriendlyString(
          this.selectedDocument?.periodLength,
          this.selectedDocument?.periodStartTime
        ) || null;
      }
      this.documentsForm.patchValue({
        levelTypeSelect: this.defaultType,
        type: this.selectedDocument?.documentType,
        expireDate: this.selectedDocument?.expireDate,
        organization: this.selectedDocument?.organizationId,
        partnerVisibility: this.selectedDocument?.partnerVisibility,
        facility: this.selectedDocument?.reportingObjectId,
        period: period
      });
      if (this.selectedDocument.documentType === DocumentType.ActionAttachment) {
        this.formDisabled = true;
        this.documentsForm.disable();
      }
    }

    if (this.facilityId) {
      this.documentsForm.patchValue({
        facility: this.facilityId
      });
    }
    if (this.meter) {
      this.documentsForm.patchValue({
        type: DocumentType.Map
      });
    }

    this.documentsForm.get('levelTypeSelect').valueChanges.pipe(
      startWith(this.defaultType)
    ).subscribe(value => {
      if (this.defaultType !== value) {
        this.documentsForm.patchValue({ type: DocumentType.Other });
      }
      const facilityControl = this.documentsForm.get('facility');
      if (value === AttachmentType.ReportingObject) {
        facilityControl.setValidators([Validators.required]);
      } else {
        facilityControl.setValidators([]);
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._facilityLoading$.complete();
    this._loading$.complete();
  }

  public selectFileEventHandler(event: SelectEvent): void {
    this.uploadedFiles.push(...event.files);
  }

  public removeFileEventHandler(event: RemoveEvent): void {
    for (const file of event.files) {
      const index = this.uploadedFiles.indexOf(file);
      this.uploadedFiles.splice(index, 1);
    }
  }

  public openFilePreview(): void {
    const modal = this.modalService.open(DocumentsPreviewComponent);
    modal.componentInstance.selectedDocument = this.selectedDocument;
  }

  public submit(): void {
    const data: IDocumentForm = this.documentsForm.value;
    const periodParams = convertPeriodToBackendFormat(data.type, data.period);
    if (data.expireDate) {
      data.expireDate.setUTCHours(23, 59, 59, 0);
    }
    const documentData = new DocumentViewModel({
      reportingObjectId: data.levelTypeSelect === AttachmentType.ReportingObject ||
        data.levelTypeSelect === AttachmentType.Meter
        ? data.facility
        : null,
      documentType: periodParams ? DocumentType.Periodical : data.type,
      expireDate: !periodParams ? data.expireDate : null,
      meterIds: this.meter?.id ? [this.meter.id] : this.selectedDocument?.meterIds ?? null,
      partnerVisibility: data.levelTypeSelect === AttachmentType.Organization
        ? data.partnerVisibility
        : null,
      organizationId: data.levelTypeSelect !== AttachmentType.General
        ? data.organization
        : null,
      periodLength: periodParams ? periodParams.periodLength : null,
      periodStartTime: periodParams ? periodParams.periodStartTime : null,
      createdByOrganizationId: this.authenticationService.organizationId
    });
    const requests: Observable<unknown>[] = this.selectedDocument
      ? this.updateDocumentRequest(documentData)
      : this.addDocumentRequest(documentData);

    forkJoin(requests).pipe(
      indicate(this._loading$)
    ).subscribe({
      next: () => {
        this.toasterService.success(
          this.selectedDocument
            ? 'DOCUMENTS.DOCUMENTUPDATED'
            : 'DOCUMENTS.DOCUMENTCREATED'
        );
        super.closeModal();
      },
      error: () => {
        this.toasterService.error(
          this.selectedDocument
            ? 'DOCUMENTS.DOCUMENTUPDATE_FAILED'
            : 'DOCUMENTS.DOCUMENTCREATED_FAILED'
        );
      }
    });
  }

  public deleteDocument(): void {
    this.dialogService.getConfirmationModal({
      title: 'DOCUMENTS.DELETE_DOCUMENT',
      text: 'DOCUMENTS.DELETE_CONFIRMATION',
      isDelete: true,
      translate: true
    }).pipe(
      switchMap(() => this.attachmentClient.deleteDocument(
        this.selectedDocument.id
      ).pipe(indicate(this._loading$)))
    ).subscribe({
      next: () => {
        this.toasterService.success('DOCUMENTS.DOCUMENTDELETED');
        super.closeModal();
      },
      error: () => this.toasterService.error('DOCUMENTS.DELETE_DOCUMENT_FAILED')
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private addDocumentRequest(data: DocumentViewModel): Observable<DocumentCompleteViewModel[]>[] {
    return this.uploadedFiles.map(file =>
      this.fileReaderService.readFileAsBlob(file.rawFile).pipe(
        switchMap(fileData => this.attachmentClient.addDocument(
          undefined,
          data.reportingObjectId ?? undefined,
          data.documentType ?? undefined,
          data.periodStartTime ?? undefined,
          data.periodLength ?? undefined,
          data.expireDate ?? undefined,
          data.meterIds ?? undefined,
          data.quantities ?? undefined,
          data.organizationId ?? undefined,
          data.partnerVisibility ?? undefined,
          data.createdByOrganizationId ?? undefined,
          [{ data: fileData, fileName: file.name }]
        ))
      ));
  }

  private updateDocumentRequest(data: DocumentViewModel): Observable<DocumentCompleteViewModel>[] {
    return [
      this.attachmentClient.updateDocument(
        this.selectedDocument.id,
        undefined,
        data.reportingObjectId ?? undefined,
        data.documentType ?? undefined,
        data.periodStartTime ?? undefined,
        data.periodLength ?? undefined,
        data.expireDate ?? undefined,
        data.meterIds ?? undefined,
        data.quantities ?? undefined,
        data.organizationId ?? undefined,
        data.partnerVisibility ?? undefined,
        data.createdByOrganizationId ?? undefined
      )
    ];
  }

  private getTypeDropdownItems(): Observable<ComboItem<number>[]> {
    return this.documentsForm.get('levelTypeSelect').valueChanges.pipe(
      startWith(this.defaultType),
      map(value => getDocumentTypeSelection().filterMap(
        item =>
          item.attachmentType === value || item.attachmentType === null,
        item => ({
          value: item.id,
          text: this.translateService.instant(item.translateKey)
        })
      ))
    );
  }
}
