import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

import { WINDOW } from '@enerkey/angular-utils';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  public readonly resize$: Observable<Event>;

  public constructor(@Inject(WINDOW) private readonly windowObject: Window) {
    this.resize$ = fromEvent(this.windowObject, 'resize');
  }

  public navigate(url: string): void {
    this.windowObject.location.href = url;
  }
}
