import { FormulaParen } from './formula-paren';
import { TreeViewItem } from '@enerkey/clients/metering';
import { AddOperationType } from '../../admin/shared/add-operation-type';
import { PlaceToAdd } from './place-to-add';
import { Helpers } from '@enerkey/ts-utils';

export class FormulaHelpers {
  public static isParen(item: TreeViewItem): boolean {
    return Object.values(FormulaParen).includes(item.content as any);
  }

  public static isClosingParen(item: TreeViewItem): boolean {
    return item.content === FormulaParen.ClosedParen;
  }

  public static isOpeningParen(item: TreeViewItem): boolean {
    return item.content === FormulaParen.OpenParen;
  }

  public static getPlaceOfAddition(additionType: AddOperationType): PlaceToAdd {
    switch (additionType) {
      case AddOperationType.ConstantAfter:
      case AddOperationType.OperandAfter:
      case AddOperationType.OperatorAfter:
      case AddOperationType.ParenthesesAfter:
        return PlaceToAdd.After;
      case AddOperationType.OperandBefore:
      case AddOperationType.OperatorBefore:
      case AddOperationType.ParenthesesBefore:
        return PlaceToAdd.Before;
      case AddOperationType.OperandInside:
      case AddOperationType.OperatorInside:
      case AddOperationType.ParenthesesInside:
        return PlaceToAdd.Inside;
      case AddOperationType.ParenthesesAround:
        throw new Error('Adding parentheses around is not implemented yet');
      default:
        Helpers.assertUnreachable(additionType);
    }
  }
}
