import template from 'raw-loader!./dropdown-menu.html';

const DropdownMenuComponent = {
  template: template,
  controllerAs: 'vm',
  bindings: {
    text: '@',
    showOnHover: '<',
    itemsDisabled: '<'
  },
  require: {
    parent: '^dropdown'
  },
  transclude: true
};

export default DropdownMenuComponent;
