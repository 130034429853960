import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { SimpleChangesOf } from '@enerkey/ts-utils';

import { SelectableResolution } from '../../../../modules/reporting/components/reporting-search-form/reporting-search-form.component';

import { PeriodLabelService } from '../../../../modules/reporting/services/period-label.service';
import { ReportSeriesDataPoint } from '../../../../modules/reporting/shared/reporting-series';

export interface ConsumptionPoint {
  series: {
    color: string;
    name?: string;
  };
  dataItem: ReportSeriesDataPoint;
}

@Component({
  selector: 'chart-tooltip',
  templateUrl: './chart-tooltip.component.html',
  styleUrls: ['./chart-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartTooltipComponent implements OnChanges {
  @Input() public points: ConsumptionPoint[];
  @Input() public category: number;
  @Input() public resolution: SelectableResolution;
  @Input() public amountOfPeriods: number;

  public title: string = '';

  public constructor(
    private readonly periodLabelService: PeriodLabelService
  ) { }

  public ngOnChanges(changes: SimpleChangesOf<ChartTooltipComponent>): void {
    if (changes.points?.currentValue) {
      this.points = changes.points.currentValue.sortByMany(
        point => point.dataItem.positionInTooltip ?? 0,
        [point => point.dataItem.timestamp, 'desc']
      );
    }

    if (changes.category) {
      this.title = this.periodLabelService.getChartTooltipHeaderLabel(
        this.points.map(p => p.dataItem),
        this.resolution,
        this.category,
        this.amountOfPeriods
      );
    }
  }
}
