import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

import { ConsumptionsRequest, EnergyReportingClient, TopConsumptionResponse } from '@enerkey/clients/energy-reporting';
import { Quantities } from '@enerkey/clients/metering';
import { ActionsFlatListSearchCriteria, ActionSlimViewModel, AttachmentsClient } from '@enerkey/clients/attachments';

import { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import {
  TopChangedConsumptionsWidgetOptions
} from '../components/top-changed-consumptions-widget/top-changed-consumptions-widget.component';
import { getValueTypeOptions } from '../../energy-reporting/shared/value-type-options';
import { TopChangedConsumptions, TopChangedWidgetRow } from '../shared/top-changed-consumptions';

import TimeFrame from '../../../services/time-frame-service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ChangeWidgetData, ChangeWidgetResponse } from '../components/change-widget-base';
import { ChangeWidgetServiceBase } from './change-widget-service-base';
import { UserService } from '../../../services/user-service';
import { Service } from '../../../constants/service';
import { WidgetQueueService } from './widget-queue.service';

@Injectable()
export class TopChangedConsumptionsService
  extends ChangeWidgetServiceBase<TopChangedConsumptionsWidgetOptions, TopChangedWidgetRow, TopConsumptionResponse>
  implements OnDestroy {

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly erClient: EnergyReportingClient,
    private readonly attachmentClient: AttachmentsClient,
    private readonly toasterService: ToasterService,
    private readonly userService: UserService,
    private readonly widgetQueue: WidgetQueueService
  ) {
    super();
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public getActions(
    widgetRows: ChangeWidgetResponse<TopChangedWidgetRow>,
    start: TimeFrameResult,
    quantityId: Quantities
  ): Observable<Map<number, ActionSlimViewModel[]>> {
    if (!(this.userService.hasService(Service.Actions) || this.userService.hasService(Service.Comments))) {
      return of(new Map());
    }

    const facilityIds = this.getFacilityIds(widgetRows);

    const comparisonTimeFrame = TimeFrame.parse({
      fromDate: start.Start[1].value,
      durationTo: start.TimeFrame
    });
    const inspectionTimeFrame = TimeFrame.parse({
      fromDate: start.Start[0].value,
      durationTo: start.TimeFrame
    });

    return this.attachmentClient.getActionsFlatList(
      this.userService.getCurrentProfile().reportingObjectSetId,
      new ActionsFlatListSearchCriteria({
        facilityIds: facilityIds.unique(),
        beginDate: comparisonTimeFrame.getFromDate().toDate(),
        endDate: inspectionTimeFrame.getToDate().toDate(),
      })
    ).pipe(
      this.widgetQueue.queue('attachments', this._destroy$),
      map(
        actions => actions
          .filter(action => !Array.hasItems(action.quantityIds) || action.quantityIds.includes(quantityId))
          .toGroupsBy('reportingObjectId')
      ),
      catchError(() => {
        this.toasterService.error('TOPCONSUMPTIONS_WIDGET.FAILED_TO_LOAD_ACTIONS');
        return of(new Map());
      }),
      takeUntil(this._destroy$)
    );
  }

  public getDefaultStateParameters(
    dataModelOptions: TopChangedConsumptionsWidgetOptions
  ): Record<string, unknown> {
    const valueOptions = getValueTypeOptions(dataModelOptions.valueOption);

    return {
      quantityId: [this.getQuantityId(dataModelOptions)],
      series: {
        Measured: valueOptions.measured,
        Normalized: valueOptions.normalized,
        RelationalUnitIds: dataModelOptions.variableId ? [dataModelOptions.variableId] : []
      },
      unitKey: dataModelOptions.unitKey,
      changeType: dataModelOptions.changeOption.toLowerCase()
    };
  }

  protected requestData(params: ConsumptionsRequest): Observable<TopConsumptionResponse> {
    return this.erClient.postTopConsumptions(params).pipe(this.widgetQueue.queue('energyreporting', this._destroy$));
  }

  protected transformData(
    consumptions: TopConsumptionResponse,
    valueKey: 'Value' | 'NormalisationValue',
    dataModelOptions: TopChangedConsumptionsWidgetOptions,
    start: TimeFrameResult
  ): ChangeWidgetData<TopChangedWidgetRow> {
    return TopChangedConsumptions.getWidgetRows(
      consumptions,
      this.getQuantityId(dataModelOptions),
      valueKey,
      dataModelOptions.variableId,
      start
    );
  }

  private getFacilityIds(widgetRows: ChangeWidgetResponse<TopChangedWidgetRow>): number[] {
    const measuredFacilityIds = widgetRows.measured
      ? [widgetRows.measured.fallers, widgetRows.measured.gainers].flatMap(
        rows => rows.map(row => row.facilityId)
      )
      : [];
    const normalizedFacilityIds = widgetRows.normalized
      ? [widgetRows.normalized.fallers, widgetRows.normalized.gainers].flatMap(
        rows => rows.map(row => row.facilityId)
      )
      : [];

    return [...measuredFacilityIds, ...normalizedFacilityIds];
  }
}
