<button
  #previousButton
  kendoButton
  [primary]="true"
  [icon]="'arrow-w'"
  [disabled]="disabled || (previousButtonDisabled$ | async)"
  [title]="'TIMESPAN.PREVIOUS' | translate"
>
</button>
<div class="pageInput">
  <kendo-numerictextbox
    [min]="1"
    [max]="pages"
    integerInput
    [formControl]="pageNumberControl"
  ></kendo-numerictextbox>
  <span>&nbsp;/&nbsp;</span>
  <span>{{ this.pages || 0 }}</span>
</div>
<button
  #nextButton
  kendoButton
  [primary]="true"
  [icon]="'arrow-e'"
  [disabled]="disabled || (nextButtonDisabled$ | async)"
  [title]="'TIMESPAN.NEXT' | translate"
>
</button>
