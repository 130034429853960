<ngf-tabset
  [activeId]="reportType$ | async"
  (tabChange)="tabChange($event)"
>
  <ngf-tab
    [title]="'FACILITIES_REPORT.OVERVIEW.NAME' | translate"
    [id]="ReportType.Overview"
  >
    <ng-template ngfTabContent>
      <facility-overview></facility-overview>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES.REPORT' | translate"
    [id]="ReportType.Period"
  >
    <ng-template ngfTabContent>
      <period-report></period-report>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES.TREND' | translate"
    [id]="ReportType.Trend"
  >
    <ng-template ngfTabContent>
      <trend-report></trend-report>
    </ng-template>
  </ngf-tab>
  <ngf-tab
    [title]="'FACILITIES.FORECAST' | translate"
    [id]="ReportType.Forecast"
  >
    <ng-template ngfTabContent>
      <forecast-report></forecast-report>
    </ng-template>
  </ngf-tab>
</ngf-tabset>
