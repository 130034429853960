import _ from 'lodash';
import moment from 'moment';

import { getLatestReportingMonthNumeric } from '../../../../shared/date.functions';

const $inject = ['$state', 'ERReportType', 'utils', 'timeSeries', 'erReportSettingsService'];

function ERUtils($state, ERReportType, utils, timeSeries, erReportSettingsService) {

  // sections (keep in sync with presentation controller)
  function getVisibleSections() {
    const erReportSettings = erReportSettingsService.getInstance();
    let sections = [];

    const sectionsFromOptions = erReportSettings.getSettings().visibleSections;
    if (_.isArray(sectionsFromOptions)) {
      sections = sectionsFromOptions;
    } else {
      switch (_.get($state, 'current.name')) {
        case 'facilities.report':
        case 'facilities.report-trend':
        case 'facilities.report-forecast':
          sections = ['FACILITY_INFO', 'GRAPHS', 'TABLES'];
          break;
        default:
          break;
      }
    }

    return sections;
  }

  const timeFrameResolutionsCount = function(timeFrame, resolution) {
    const timeFrameMs = moment.fromIsodurationCached(timeFrame).asMilliseconds();
    const resolutionMs = moment.fromIsodurationCached(resolution).asMilliseconds();
    return Math.round(timeFrameMs / resolutionMs);
  };

  const resolutionTitle = function(resolution) {
    let result = '';
    const resolutionDuration = moment.fromIsodurationCached(resolution);
    if (resolutionDuration.years()) {
      const years = resolutionDuration.years();
      if (years === 1) {
        result = utils.localizedString('YEAR');
      } else {
        result = utils.localizedString('PERIOD');
      }
    } else if (resolutionDuration.months()) {
      const months = resolutionDuration.months();
      if (months === 1) {
        result = utils.localizedString('MONTH');
      } else {
        result = utils.localizedString('PERIOD');
      }
    } else if (resolutionDuration.days()) {
      const days = resolutionDuration.days();
      if (days === 1) {
        result = utils.localizedString('DAY');
      } else {
        result = utils.localizedString('PERIOD');
      }
    } else if (resolutionDuration.hours()) {
      const hours = resolutionDuration.hours();
      if (hours === 1) {
        result = utils.localizedString('HOUR');
      } else {
        result = utils.localizedString('PERIOD');
      }
    }
    return result;
  };

  const resolutionToString = function(startDate, resolution, index, dayIndex, longFormat) {
    let result = startDate;
    const resolutionDuration = moment.fromIsodurationCached(resolution);

    if (resolutionDuration.years()) {
      const years = resolutionDuration.years();
      if (years === 1) {
        result = kendo.toString(startDate, 'yyyy');
      } else {
        result = index + 1;
      }
    } else if (resolutionDuration.months()) {
      const months = resolutionDuration.months();
      if (months === 1) {
        // NOTE: needs 'M ' and trim due to weirdness of kendo
        result = kendo.toString(startDate, (longFormat ? 'M ' : 'M/yy')).trim();
      } else {
        result = index + 1;
      }
    } else if (resolutionDuration.days()) {
      const days = resolutionDuration.days();
      if (days === 1) {
        result = longFormat ? (utils.localizedString('DAY') + ' ' + dayIndex) : null;
        if (!result) {
          // NOTE: needs 'd ' and trim due to weirdness of kendo
          result = kendo.toString(startDate, (longFormat ? 'd ' : 'd')).trim();
        }
      } else {
        result = index + 1;
      }
    } else if (resolutionDuration.hours()) {
      const hours = resolutionDuration.hours();
      if (hours === 1) {
        const prefix = longFormat ? (utils.localizedString('DAY') + ' ' + dayIndex + ', ') : '';
        result = prefix + kendo.toString(startDate, (prefix ? 'HH:mm' : 'g')).trim();
      } else {
        result = index + 1;
      }
    }

    return result;
  };

  /**
   * Get latest month name
   * @param  {Number} year number
   * @return {String} month name
   */
  const getLatestMonth = function(year) {
    const latestMonth = getLatestReportingMonthNumeric(year);
    // get compared month name
    const monthNames = _.get(kendo.culture(), 'calendar.months.names');
    return _.startCase(monthNames[latestMonth]);
  };

  const dateToString = function(paramDate) {
    return paramDate.getDate() + "." + (paramDate.getMonth() + 1) + "." + paramDate.getFullYear();
  };

  const generateKey = function(fromDate, timeFrame) {
    const toDate = _.cloneDeep(fromDate);
    switch (timeFrame) {
      case "P1Y":
      default: toDate.setFullYear(toDate.getFullYear() + 1);
    }
    // decrease toDate by day
    toDate.setDate(toDate.getDate() - 1);
    return dateToString(fromDate) + " - " + dateToString(toDate);
  };

  const setToYearStart = function(date) {
    date.setMonth(0);
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
  };

  // Get static time period for facility dashboard and facility list in mobile
  const getDefaultTimePeriod = function() {

    // get calendar setting with 0 instead of possible rolling setting
    return timeSeries.getDefaultTimeSeries(false, 0).then(function(res) {

      const date = new Date();

      // check if date is before fifth of february, take earlier year if true,
      // else get start values from timeseries
      if (date.getMonth() === 0 || date.getMonth() === 1 && date.getDate() < 5) {

        date.setFullYear(date.getFullYear() - 1);
        const startDates = [];
        setToYearStart(date);

        // take second last or first value
        const comparisonStart = _.cloneDeep(date);
        comparisonStart.setFullYear(comparisonStart.getFullYear() - 1);

        startDates.push({
          "key": generateKey(date, "P1Y"),
          "value": utils.dateToUtcDate(date).toISOString()
        });

        startDates.push({
          "key": generateKey(comparisonStart, "P1Y"),
          "value": utils.dateToUtcDate(comparisonStart).toISOString()
        });

        return startDates;

      } else {
        return res.Start;
      }

    });

  };

  const calculateValueDifferences = function(value1, value2) {
    return {
      Difference: _.isNumber(value1) && _.isNumber(value2) ? value1 - value2 : null,
      DifferencePercent: value1 === 0 && value2 === 0 ? 0 : utils.percentageChange(value1, value2)
    };
  };

  function extendChartSeriesOptions(callback, chart) {
    return _.chain(chart)
      .toArray()
      .head()
      .get('chartOptions.series')
      .map(function(serie) {
        return callback(serie);
      })
      .value();
  }

  function isMeter() {
    const erReportSettings = erReportSettingsService.getInstance();
    return _.includes($state.$current.name, 'meter') || _.includes(erReportSettings.getSettings().name, 'meter');
  }

  const isYearOrMonthTimeFrame = function(timeFrame) {
    const timeFrameLastChar = timeFrame.slice(-1);
    return (timeFrameLastChar === 'Y' || timeFrameLastChar === 'M');
  };

  function getLocalizedReportNameByReportType(reportType) {
    let tag;

    switch (reportType) {
      case ERReportType.TREND_REPORT:
        tag = 'FACILITIES.TREND_REPORT';
        break;
      case ERReportType.FORECAST_REPORT:
        tag = 'FACILITIES.FORECAST_REPORT';
        break;
      default:
        tag = 'SUM_REPORT';
    }

    return utils.localizedString(tag);
  }

  return {
    getVisibleSections: getVisibleSections,
    timeFrameResolutionsCount: timeFrameResolutionsCount,
    resolutionTitle: resolutionTitle,
    resolutionToString: resolutionToString,
    extendChartSeriesOptions: extendChartSeriesOptions,
    getLocalizedReportNameByReportType: getLocalizedReportNameByReportType,
    getLatestMonth: getLatestMonth,
    getLatestMonthNumeric: getLatestReportingMonthNumeric,
    getDefaultTimePeriod: getDefaultTimePeriod,
    isMeter: isMeter,
    isYearOrMonthTimeFrame: isYearOrMonthTimeFrame,
    calculateValueDifferences: calculateValueDifferences
  };
}

ERUtils.$inject = $inject;

export default ERUtils;
