import SelectPagingController from './select-paging-controller';
import template from 'raw-loader!./select-paging.html';

const SelectPagingComponent = {
  template: template,
  controllerAs: 'vm',
  controller: SelectPagingController,
  transclude: true,
  bindings: {
    title: '@',
    selectedItem: '<',
    items: '<',
    options: '<',
    isDisabled: '<',
    indicator: '<',
    onChange: '<',
    selectFurtherPrevious: '<',
    selectFurtherNext: '<',
  }
};

export default SelectPagingComponent;
