<label for="factorInput">
  {{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.ADD_FACTOR_TO_SELECTED' | translate }}:
</label>
<kendo-numerictextbox
  id="factorInput"
  [decimals]="8"
  [(ngModel)]="factor"
  [format]="'0.########'"
></kendo-numerictextbox>
<button
  class="button"
  translate="ADD"
  [disabled]="buttonDisabled"
  (click)="submitClicked()"
></button>
