<div class="widget">

  <button
    class="button button--link button--external-link float-right"
    (click)="openMeasurementErrorHelpMainPage()"
  >
    {{ 'ERROR_WIDGET.HELP' | translate }}
  </button>

  <form
    class="error-type-select"
    [formGroup]="errorTypeForm"
  >
    <label>
      <input
        kendoRadioButton
        type="radio"
        [value]="ErrorWidgetTicketType.Terminal"
        [formControlName]="errorTypeField"
      >
      {{ 'ERROR_WIDGET.BY_TERMINAL' | translate }}
    </label>
    <label>
      <input
        kendoRadioButton
        type="radio"
        [value]="ErrorWidgetTicketType.Facility"
        [formControlName]="errorTypeField"
      >
      {{ 'ERROR_WIDGET.BY_FACILITY' | translate }}
    </label>
    <label>
      <input
        kendoRadioButton
        type="radio"
        [value]="ErrorWidgetTicketType.FacilityPT15M"
        [formControlName]="errorTypeField"
      >
      {{ 'ERROR_WIDGET.BY_FACILITY_PT15M' | translate }}
    </label>

  </form>
  <ng-container *ngIf="tickets$ | async as tickets">
    <table *ngIf="tickets.length else noErrorTickets">
      <thead>
        <tr>
          <th>{{ 'ERROR_WIDGET.FACILITY' | translate }}</th>
          <th>{{ 'ERROR_WIDGET.FAULT_DATE' | translate }}</th>
          <th>{{ 'ERROR_WIDGET.METER_COUNT' | translate }}</th>
          <th></th>
          <th>
            {{ 'ERROR_WIDGET.ERROR_TYPE_TITLE' | translate }}
          </th>
          <th *ngIf="isTerminalErrorType$ | async">
            {{ 'ERROR_WIDGET.TERMINAL_NAME' | translate }}
          </th>
          <th *ngIf="isTerminalErrorType$ | async">
            {{ 'COMMENT' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ticket of tickets">
          <td>
            <a
              *ngIf="ticket.facilities.length === 1; else multipleFacilities"
              (click)="openFacilityReport(ticket.facilities[0].id)"
            >
              {{ ticket.facilities[0].name }}
            </a>
            <ng-template #multipleFacilities>
              <ek-dropdown>
                <div
                  *ekDropdownToggle="let toggle"
                  (click)="toggle()"
                >
                  <a>
                    {{ ticket.facilities[0].name }}
                    <i class="fa fa-chevron-down"></i>
                  </a>
                </div>
                <ek-dropdown-item
                  *ngFor="let facility of ticket.facilities"
                  (click)="openFacilityReport(facility.id)"
                >
                  <a>{{ facility.name }}</a>
                </ek-dropdown-item>
              </ek-dropdown>
            </ng-template>
          </td>
          <td>
            {{ ticket.errorTicket.created | date:'short' }}
          </td>
          <td>
            <a (click)="openMeterModal(ticket)">
              <span>{{ ticket.errorTicket.meterIds?.length || 0 }}</span>
              <span *ngIf="ticket.errorTicket.facilityMeterCount as facilityMeterCount">/{{ facilityMeterCount }}</span>
            </a>
          </td>
          <td>
            <i
              *ngIf="ticket.status === StatusType.CustomerInformed"
              [ngfTooltip]="'ERROR_WIDGET.CUSTOMER_INFORMED' | translate"
              class="fas fa-envelope customer-informed-icon"
            ></i>
          </td>
          <td>
            <a (click)="openHelpPageForTicket(ticket)">
              {{ ticket.errorTicket.errorType | errorTicketTypeName | translate }}
            </a>
          </td>
          <td *ngIf="isTerminalErrorType$ | async">
            {{ ticket.terminalName }}
          </td>
          <td *ngIf="isTerminalErrorType$ | async">
            <a *ngIf="ticket.terminalComment"
              class="ek-color ek-color--grey icon icon-icon-notes-content"
              (click)="editTerminalComment(ticket)"
              [ngfTooltip]="ticket.terminalComment"
              [placement]="'left'"
            ></a>
            <a *ngIf="!ticket.terminalComment && isUserAllowedToEdit"
              class="ek-color ek-color--primary fa fa-plus"
              (click)="editTerminalComment(ticket)"
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>
  <ng-template #noErrorTickets>
    <div class="no-errors-message-container">
      {{ 'ERROR_WIDGET.NO_ERRORS' | translate }}
    </div>
  </ng-template>
</div>
