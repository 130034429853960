import * as SeriesType from './manual-qa-inspect.constant';

/**
 * Checks if series type is actual
 *
 * @param {number} seriesType
 *
 * @returns {boolean}
 */
export const isSeriesTypeActual = seriesType => seriesType === SeriesType.ACTUAL;

/**
 * Checks if series type is comparison
 *
 * @param {number} seriesType
 *
 * @returns {boolean}
 */
export const isSeriesTypeComparison = seriesType => seriesType === SeriesType.COMPARISON;

/**
 * Checks if series type is both
 *
 * @param {number} seriesType
 *
 * @returns {boolean}
 */
export const isSeriesTypeBoth = seriesType => seriesType === SeriesType.BOTH;

/**
 * Checks if series type is actual or both
 *
 * @param {number} seriesType
 *
 * @returns {boolean}
 */
export const isSeriesTypeActualOrBoth = seriesType =>
  isSeriesTypeActual(seriesType) || isSeriesTypeBoth(seriesType);

/**
 * Checks if series type is comparison or both
 *
 * @param {number} seriesType
 *
 * @returns {boolean}
 */
export const isSeriesTypeComparisonOrBoth = seriesType =>
  isSeriesTypeComparison(seriesType) || isSeriesTypeBoth(seriesType);
