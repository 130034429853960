import { Observable, take } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

/**
 * Represents a cache entry with an expiration time.
 *
 * @template U - The type of the cached value.
 */
export class CacheEntry<U> {

  private readonly expiresAt: number;

  /**
   * Creates an instance of CacheEntry.
   *
   * @param {U} value - The value to be cached.
   * @param {number} expiresInSeconds - The expiration time in seconds for the cache entry.
   */
  public constructor(public readonly value: U, expiresInSeconds: number) {
    this.expiresAt = Date.now() + (expiresInSeconds * 1000);
  }

  /**
   * Checks if the cache entry is expired.
   *
   * @returns {boolean} - True if the cache entry is expired, false otherwise.
   */
  public isExpired(): boolean {
    return Date.now() >= this.expiresAt;
  }
}

/**
 * Manages a cache of key-value pairs with expiration times.
 *
 * @template T - The type of the cache key.
 * @template U - The type of the cache value.
 */
export class CacheManager<T, U> {

  private readonly cache = new Map<T, CacheEntry<U>>();

  /**
   * Creates an instance of CacheManager.
   *
   * @param {number} [expirationInSeconds=600] - The expiration time in seconds for the cache entries.
   */
  public constructor(private expirationInSeconds: number = 60 * 10) {}

  /**
   * Retrieves a value from the cache.
   *
   * @param {T} key - The key to identify the cached entry.
   * @returns {U | undefined} - The cached value or undefined if not present or expired.
   */
  public get(key: T): U | undefined {
    const value = this.cache.get(key);
    const isExpired = value?.isExpired();

    if (isExpired) { this.cache.delete(key); }
    return isExpired || value === undefined ? undefined : value.value;
  }

  /**
   * Adds a value to the cache.
   *
   * @param {T} key - The key to identify the cached entry.
   * @param {U} value - The value to be cached.
   */
  public add(key: T, value: U): void {
    this.cache.set(key, new CacheEntry(value, this.expirationInSeconds));
  }

  /**
   * Clears all entries from the cache.
   */
  public clear(): void {
    this.cache.clear();
  }
}

/**
 * A cache manager that handles caching of observables.
 *
 * @template T - The type of the cache key.
 * @template U - The type of the observable value.
 */
export class CacheManagerObservable<T, U> extends CacheManager<T, Observable<U>> {

  /**
   * Creates an instance of CacheManagerObservable.
   *
   * @param {number} [expirationInSeconds] - The expiration time in seconds for the cache entries.
   */
  public constructor(expirationInSeconds?: number) {
    super(expirationInSeconds);
  }

  /**
   * Retrieves a cached observable or fetches it if not present in the cache.
   *
   * @param {T} key - The key to identify the cached entry.
   * @param {() => Observable<U>} fetch - A function to fetch the observable if not present in the cache.
   * @returns {Observable<U>} - The cached or fetched observable.
   */
  public getOrFetch(key: T, fetch: () => Observable<U>): Observable<U> {
    const cachedEntry = this.get(key);
    if (cachedEntry) { return cachedEntry; }

    const fetchedValue = fetch().pipe(
      take(1),
      shareReplay(1)
    );

    this.add(key, fetchedValue);

    return fetchedValue;
  }
}
