<modal-view-header [headingText]="'SETTINGS.CHANGE_PROFILE' | translate"></modal-view-header>
<div class="modal-body">
  <div class="columns small-12">
    <strong>{{ 'SETTINGS.CURRENT_PROFILE' | translate }}:</strong>
    {{ currentProfileName }}
    <hr>
  </div>
  <div
    *ngIf="hasAccessToAllProfiles"
    class="medium-12 large-5 columns last-ten-profiles-container"
    [loadingSpinner]="lastTenProfilesLoading$ | async"
  >
    <h3>{{ 'SETTINGS.LAST_USED_PROFILES' | translate }}</h3>
    <p *ngFor="let profile of (lastTenProfiles$ | async | reverse)">
      <a
        (click)="changeProfile(profile.id)"
        [title]="'SETTINGS.CLICK_TO_CHANGE_PROFILE' | translate"
      >
        {{ profile.name }}
      </a>
    </p>
  </div>
  <div
    class="columns"
    [ngClass]="{ 'large-7': hasAccessToAllProfiles, 'medium-12': !hasAccessToAllProfiles }"
  >
    <h3 *ngIf="hasAccessToAllProfiles">{{ 'SETTINGS.ALL_PROFILES' | translate }}</h3>
    <form
      *ngIf="hasAccessToAllProfiles"
      id="profile-search-form"
      [formGroup]="profileFilterForm"
    >
      <div class="single-facility-select-container">
        <input
          type="checkbox"
          kendoCheckBox
          *labelWrap="'SETTINGS.SHOW_SINGLE_FACILITY_PROFILES' | translate"
          formControlName="showSingleFacilityProfiles"
        />
      </div>
      <div class="profile-search-criteria">
        <div class="profile-search-criteria-inputs">
          <kendo-textbox
            *labelBefore="'SETTINGS.PROFILE_NAME_FILTER_FIELD_LABEL' | translate"
            formControlName="profileName"
          ></kendo-textbox>
          <kendo-numerictextbox
            *labelBefore="'ENEGIA_ID' | translate"
            integerInput
            formControlName="enegiaId"
          ></kendo-numerictextbox>
        </div>
        <div class="profile-search-criteria-button">
          <button
            *ngIf="!(filterProfiles$ | async)"
            [disabled]="!profileFilterForm.valid"
            class="button"
            type="submit"
            for="profile-search-form"
            (click)="filterProfiles()"
          >{{ 'FILTER' | translate }}</button>
          <button
            *ngIf="filterProfiles$ | async"
            class="button button--negative button--link"
            type="button"
            (click)="clearProfileFilters()"
          >{{ 'CLEAR_FILTER' | translate }}</button>
        </div>
      </div>
    </form>
    <kendo-dropdownlist
      *labelBefore="'SETTINGS.CHOOSE_PROFILE' | translate"
      (open)="loadProfiles()"
      [data]="profiles$ | async"
      [valueField]="'id'"
      [textField]="'name'"
      [(ngModel)]="selectedProfileId"
      [kendoDropDownFilter]="filterSettings"
      [loading]="loading$ | async"
      [valuePrimitive]="true"
      [virtual]="true"
      [popupSettings]="{ animate: false }"
    ></kendo-dropdownlist>
  </div>
  <div class="modal-footer">
    <div class="modal-footer-buttons float-right">
      <button
        class="button"
        type="button"
        [loading]="loading$ | async"
        [disabled]="!(selectedProfileId && selectedProfileId !== currentProfileId)"
        (click)="changeProfile(selectedProfileId)"
      >
        {{ 'SETTINGS.SWITCH' | translate }}
      </button>
      <button
        class="button button--secondary"
        type="button"
        (click)="dismiss()"
      >
        {{ 'CANCEL' | translate }}
      </button>
    </div>
  </div>
</div>
