import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  Address,
  CreateOrUpdateCustomBuildingClass,
  CreateOrUpdateTechnicalSpecifications,
  ExternalIds,
  Facility,
  FacilityClient,
  HeatDistributionSystem,
  TechnicalSpecifications,
  UpdateFacilities,
  UpdateFacility,
  VentilationMethod,
  VentilationSystem
} from '@enerkey/clients/facility';
import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject, ofVoid } from '@enerkey/rxjs';
import { getNumericEnumValues } from '@enerkey/ts-utils';

import {
  electricityTaxClassTranslations,
  facilityEditFacilityInfoFields,
  facilityEditTechnicalSpecsFields,
  FacilityInfoFieldTypes,
  heatDistributionSystemTranslations,
  ownershipTranslations,
  ventilationMethodTranslations,
  ventilationSystemTranslations
} from '../../../../constants/facility.constant';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { UserService } from '../../../../services/user-service';
import { FacilityDataType } from '../facility-property-table/facility-property-table.component';
import { FacilityService } from '../../../../shared/services/facility.service';
import { FacilityEditService } from '../../services/facility-edit.service';
import { ToasterService } from '../../../../shared/services/toaster.service';

type FacilityEditForm = {
  facilityInfo: UpdateFacilities & { externalId: string, externalOrganizationId: string },
  address: Address,
  technicalSpecifications: TechnicalSpecifications
};

@Component({
  selector: 'facility-edit-modal',
  templateUrl: './facility-edit-modal.component.html',
  styleUrls: ['./facility-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FacilityEditService]
})
@ModalOptions({ windowClass: 'fixed-height modal-dialog-scrollable', size: 'large' })
export class FacilityEditModalComponent extends ModalBase<void> implements OnInit, OnDestroy {
  public facilityId: number | undefined;
  public facility: Facility | undefined;

  public readonly dropdownData: Record<string, Observable<ComboItem<number>[]>> = {};
  public readonly autoCompleteData: Record<string, Observable<string[]>> = {};
  public readonly FacilityInfoFields = facilityEditFacilityInfoFields;
  public readonly FacilityTechnicalSpecFields = facilityEditTechnicalSpecsFields;
  public readonly FacilityInfoFieldTypes = FacilityInfoFieldTypes;
  public readonly FacilityDataType = FacilityDataType;
  public readonly facilityEditForm: UntypedFormGroup;
  public readonly loading$: Observable<boolean>;
  public readonly dropdownLoading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _dropdownLoading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly facilityClient: FacilityClient,
    private readonly userService: UserService,
    private readonly translateService: TranslateService,
    private readonly facilityService: FacilityService,
    private readonly facilityEditService: FacilityEditService,
    private readonly toasterService: ToasterService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.dropdownLoading$ = this._dropdownLoading$.asObservable();
    this.facilityEditForm = new UntypedFormGroup({});
    this.generateFormGroup();

    this.getBuildingClasses();
    this.getCustomBuildingClasses();
    this.getElectricityTaxClasses();
    this.getOwnershipTypes();
    this.getLocales();
    this.getManagementResponsibilityTypes();
    this.getVentilationSystemTypes();
    this.getVentilationMethodTypes();
    this.getHeatDistributionTypes();
  }

  public ngOnInit(): void {
    this.facilityEditService.setFacilityId(this.facilityId);
    if (!this.facility) {
      this.facilityClient.getFacilities([this.facilityId]).pipe(
        indicate(this._loading$),
        map(facilities => {
          if (facilities.length) {
            this.patchFormValues(facilities[0]);
            return facilities[0];
          }
          throw facilities;
        }),
        catchError(() => {
          this.toasterService.error('FACILITY.EDIT_FORM.FAILED_TO_LOAD_FACILITY');
          super.dismissModal();
          return of(null);
        }),
        takeUntil(this._destroy$)
      ).subscribe(facility => {
        this.facility = facility;
      });
    } else {
      this.patchFormValues(this.facility);
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._dropdownLoading$.complete();
  }

  public submit(): void {
    const data: FacilityEditForm = this.facilityEditForm.value;
    data.facilityInfo.managementResponsibility = this.numberToBoolean(
      this.facilityEditForm.value.facilityInfo.managementResponsibility
    );

    forkJoin([
      this.getFacilityInfoRequest(data).pipe(
        switchMap(() => this.getCustomBuildingClassRequest(data))
      ),
      this.getExternalIdsRequest(data),
      this.getAddressRequest(data),
      this.facilityEditService.savePropertyChanges(),
      this.facilityEditService.saveUrlChanges(),
      this.facilityEditService.saveFacilityCustomerFieldChanges(),
      this.getTechSpecificationsRequest(data)
    ]).pipe(
      indicate(this._loading$),
      takeUntil(this._destroy$)
    ).subscribe({
      next: () => super.closeModal()
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private numberToBoolean(value: number): boolean {
    if (value === null) {
      return null;
    }
    return value === 1;
  }

  private booleanToNumber(value: boolean): number {
    if (value === null) {
      return null;
    }
    return value === true ? 1 : 0;
  }

  private generateFormGroup(): void {
    const controlList = this.FacilityInfoFields.flatMap(r => r);
    controlList.push(...this.FacilityTechnicalSpecFields.flatMap(r => r));
    controlList.toGroupsBy('formGroupName').forEach(group => {
      const groupFields: Record<string, UntypedFormControl> = {};
      group.forEach(field => {
        groupFields[field.formControlName] = new UntypedFormControl(null, field.validation || null);
      });
      this.facilityEditForm.addControl(
        group[0].formGroupName,
        new UntypedFormGroup(groupFields)
      );
    });
  }

  private patchFormValues(facility: Facility): void {
    this.facilityEditForm.patchValue({
      facilityInfo: {
        ...facility ?? {},
        externalOrganizationId: facility?.externalIds?.externalOrganizationId,
        externalId: facility?.externalIds?.externalId,
        managementResponsibility: this.booleanToNumber(facility.managementResponsibility),
      },
      address: {
        ...facility.firstAddress ?? {}
      },
      technicalSpecifications: {
        ...facility.technicalSpecifications ?? {}
      }
    });
  }

  private getBuildingClasses(): void {
    this.dropdownData['buildingClass'] = this.facilityService.buildingClasses$.pipe(
      take(1),
      indicate(this._dropdownLoading$),
      map(bClasses => bClasses.map(b => ({
        value: b.id,
        text: b.translation
      }))),
      takeUntil(this._destroy$)
    );
  }

  private getCustomBuildingClasses(): void {
    this.autoCompleteData['customBuildingClass'] = this.facilityClient.getProfileFacilities(
      this.userService.getCurrentProfile().id
    ).pipe(
      indicate(this._dropdownLoading$),
      map(facilities => facilities
        .flatMap(f => f.customBuildingClass)
        .filter(c => c !== null)
        .unique()),
      takeUntil(this._destroy$)
    );
  }

  private getElectricityTaxClasses(): void {
    const taxClasses: ComboItem<number>[] = [];
    for (const [key, value] of Object.entries(electricityTaxClassTranslations)) {
      taxClasses.push({
        value: parseInt(key),
        text: value
      });
    }
    this.dropdownData['electricityTaxClass'] = of(taxClasses);
  }

  private getOwnershipTypes(): void {
    const ownershipTypes: ComboItem<number>[] = [];
    for (const [key, value] of Object.entries(ownershipTranslations)) {
      ownershipTypes.push({
        value: parseInt(key),
        text: value
      });
    }
    this.dropdownData['ownershipTypes'] = of(ownershipTypes);
  }

  private getLocales(): void {
    this.dropdownData['locales'] = this.facilityService.locales$.pipe(
      take(1),
      indicate(this._dropdownLoading$),
      map(locales => locales.map(l => ({
        value: l.id,
        text: l.timeZone
      }))),
      takeUntil(this._destroy$)
    );
  }

  private getManagementResponsibilityTypes(): void {
    this.dropdownData['managementResponsibility'] = of([
      {
        value: null,
        text: '-'
      }, {
        value: 1,
        text: this.translateService.instant('YES')
      }, {
        value: 0,
        text: this.translateService.instant('NO')
      }
    ]);
  }

  private getVentilationSystemTypes(): void {
    this.dropdownData['ventilationSystem'] = of(getNumericEnumValues(VentilationSystem)
      .map(option => ({
        value: option,
        text: this.translateService.instant(
          ventilationSystemTranslations[option]
        )
      })));
  }

  private getVentilationMethodTypes(): void {
    this.dropdownData['ventilationMethod'] = of(getNumericEnumValues(VentilationMethod)
      .map(option => ({
        value: option,
        text: this.translateService.instant(
          ventilationMethodTranslations[option]
        )
      })));
  }

  private getHeatDistributionTypes(): void {
    this.dropdownData['heatDistributionSystem'] = of(getNumericEnumValues(HeatDistributionSystem)
      .map(option => ({
        value: option,
        text: this.translateService.instant(
          heatDistributionSystemTranslations[option]
        )
      })));
  }

  private getFacilityInfoRequest(data: FacilityEditForm): Observable<unknown> {
    if (!this.facilityEditForm.get('facilityInfo').dirty) {
      return ofVoid();
    }
    return this.facilityClient.updateFacility(
      this.facilityId, new UpdateFacility({ ...this.facility, ...data.facilityInfo })
    ).pipe(
      tap({
        next: () => this.toasterService.success('FACILITY.FACILITYUPDATED'),
        error: () => this.toasterService.generalError('SAVE', 'FACILITY')
      })
    );
  }

  private getCustomBuildingClassRequest(data: FacilityEditForm): Observable<unknown> {
    if (!this.facilityEditForm.get('facilityInfo').get('customBuildingClass').dirty) {
      return ofVoid();
    }
    const addRequest = this.facilityClient.addCustomBuildingClass(
      this.facilityId,
      new CreateOrUpdateCustomBuildingClass({ name: data.facilityInfo.customBuildingClass })
    ).pipe(
      tap({
        error: () => this.toasterService.generalError('SAVE', 'FACILITY')
      })
    );
    if (this.facility.customBuildingClass) {
      if (data.facilityInfo.customBuildingClass === '') {
        return this.facilityClient.removeCustomBuildingClassFromFacility(
          this.facilityId, this.facility.customBuildingClasses[0].id
        ).pipe(
          tap({
            error: () => this.toasterService.generalError('SAVE', 'FACILITY')
          })
        );
      } else {
        return addRequest;
      }
    } else {
      return addRequest;
    }
  }

  private getExternalIdsRequest(data: FacilityEditForm): Observable<void> {
    if (!this.facilityEditForm.get('facilityInfo').get('externalId').dirty &&
      !this.facilityEditForm.get('facilityInfo').get('externalOrganizationId').dirty) {
      return ofVoid();
    }
    return this.facilityClient.addOrUpdateExternalId(
      this.facilityId, new ExternalIds({
        externalId: data.facilityInfo.externalId,
        externalOrganizationId: data.facilityInfo.externalOrganizationId
      })
    ).pipe(
      tap({
        error: () => this.toasterService.generalError('SAVE', 'FACILITY')
      })
    );
  }

  private getAddressRequest(data: FacilityEditForm): Observable<unknown> {
    if (!this.facilityEditForm.get('address').dirty) {
      return ofVoid();
    }
    const address = new Address({
      streetAddress: data.address.streetAddress ?? '',
      postalCode: data.address.postalCode ?? '',
      city: data.address.city ?? '',
      country: data.address.country ?? '',
      id: 0
    });
    if (this.facility.firstAddress) {
      if (Object.values(data.address).every(x => x === null || x === '')) {
        return this.facilityClient.deleteAddress(
          this.facilityId, this.facility.firstAddress.id
        ).pipe(
          tap({
            next: () => this.toasterService.success('FACILITY.ADDRESS_UPDATED'),
            error: () => this.toasterService.generalError('UPDATE', 'ADDRESS')
          })
        );
      } else {
        return this.facilityClient.updateAddress(
          this.facilityId, this.facility.firstAddress.id, address
        ).pipe(
          tap({
            next: () => this.toasterService.success('FACILITY.ADDRESS_UPDATED'),
            error: () => this.toasterService.generalError('UPDATE', 'ADDRESS')
          })
        );
      }
    } else {
      return this.facilityClient.addAddress(
        this.facilityId, address
      ).pipe(
        tap({
          next: () => this.toasterService.success('FACILITY.ADDRESS_UPDATED'),
          error: () => this.toasterService.generalError('SAVE', 'ADDRESS')
        })
      );
    }
  }

  private getTechSpecificationsRequest(data: FacilityEditForm): Observable<void> {
    if (!this.facilityEditForm.get('technicalSpecifications').dirty) {
      return ofVoid();
    }
    return this.facilityClient.upsertTechnicalSpecifications(
      this.facilityId, new CreateOrUpdateTechnicalSpecifications({ ...data.technicalSpecifications })
    ).pipe(
      tap({
        next: () => this.toasterService.success('FACILITY.TECHNICAL_SPECIFICATIONS_ADDED'),
        error: () => this.toasterService.generalError('SAVE', 'PROPERTIES')
      })
    );
  }
}
