<accordion>
  <accordion-group [isOpen]="true">
    <h2 accordion-heading>{{ 'ADMIN.VIRTUAL_METERS.METER_INFO' | translate }}</h2>
    <div class="info-grid">
      <div class="info-grid-inputs">
        <div class="info-grid-input-group">
          <div class="info-grid-checkbox-container">
            <info-grid-item [title]="'ADMIN.SPREADSHEET.TWO_TIME_MEASUREMENT.HEADING' | translate">
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="meterUpdateProperties.updatedProperties.twoTimeMeasurement"
              />
            </info-grid-item>
            <info-grid-item [title]="'ADMIN.SPREADSHEET.QUALITY_ASSURANCE.HEADING' | translate">
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="meterUpdateProperties.updatedProperties.qualityAssurance"
              />
            </info-grid-item>
            <info-grid-item [title]="'ADMIN.SPREADSHEET.AUTOMATIC_MODELING.HEADING' | translate">
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="meterUpdateProperties.updatedProperties.automaticModeling"
              />
            </info-grid-item>
          </div>
          <div class="info-grid-input-container">
            <info-grid-item [title]="'METER_NAME' | translate">
              <input
                type="text"
                [(ngModel)]="meterUpdateProperties.updatedProperties.name"
              />
            </info-grid-item>
            <info-grid-item [title]="'FACTOR' | translate">
              <kendo-numerictextbox
                [decimals]="9"
                format="#.#########"
                [(ngModel)]="meterUpdateProperties.updatedProperties.factor"
              >
              </kendo-numerictextbox>
            </info-grid-item>
            <info-grid-item [title]="'COMMENT' | translate">
              <textarea [(ngModel)]="meterUpdateProperties.updatedProperties.description"></textarea>
            </info-grid-item>
            <info-grid-item
              [title]="'ADMIN.METER.RESOLUTION' | translate"
            >
              <kendo-combobox
                [data]="meterResolutions"
                [(ngModel)]="meterUpdateProperties.updatedProperties.resolution"
              ></kendo-combobox>
            </info-grid-item>
          </div>
        </div>

        <div class="info-grid-input-group">
          <div class="info-grid-input-container">
            <info-grid-item [title]="'CUSTOMER_METER_IDENTIFIER' | translate">
              <input
                type="text"
                [(ngModel)]="meterUpdateProperties.updatedProperties.customerMeterIdentifier"
              />
            </info-grid-item>
            <info-grid-item [title]="'USAGE_PLACE_NUMBER' | translate">
              <input
                type="text"
                [(ngModel)]="meterUpdateProperties.updatedProperties.usagePlaceNumber"
              />
            </info-grid-item>
            <info-grid-item [title]="'ADMIN.METER.ENERGY_COMPANY_USAGE_PLACE_NUMBER' | translate">
              <input
                type="text"
                [(ngModel)]="meterUpdateProperties.updatedProperties.energyCompanyUsagePlaceNumber"
              />
            </info-grid-item>
            <info-grid-item [title]="'EAN_CODE' | translate">
              <input
                type="text"
                [(ngModel)]="meterUpdateProperties.updatedProperties.eanCode"
              />
            </info-grid-item>
            <info-grid-item [title]="'PROTOCOL_CODE' | translate">
              <input
                type="text"
                [(ngModel)]="meterUpdateProperties.updatedProperties.protocolCode"
              />
            </info-grid-item>
          </div>
        </div>

        <div class="info-grid-input-group">
          <div class="info-grid-input-container">
            <info-grid-item [title]="'ADMIN.SPREADSHEET.AUTOMATIC_START_TIME.HEADING' | translate">
              <kendo-datepicker
                [value]="meterUpdateProperties.updatedProperties.automaticReadingStartTime"
                (valueChange)="changeDateValue($event, 'automaticReadingStartTime')"
              ></kendo-datepicker>
            </info-grid-item>
            <info-grid-item [title]="'ADMIN.SPREADSHEET.AUTOMATIC_END_TIME.HEADING' | translate">
              <kendo-datepicker
                [value]="meterUpdateProperties.updatedProperties.automaticReadingEndTime"
                (valueChange)="changeDateValue($event, 'automaticReadingEndTime')"
              ></kendo-datepicker>
            </info-grid-item>
            <info-grid-item [title]="'ADMIN.SPREADSHEET.DEACTIVATION_TIME.HEADING' | translate">
              <kendo-datepicker
                [value]="meterUpdateProperties.updatedProperties.deactivationTime"
                (valueChange)="changeDateValue($event, 'deactivationTime')"
              ></kendo-datepicker>
            </info-grid-item>
          </div>
        </div>

        <div class="info-grid-input-group">
          <div class="info-grid-checkbox-container">
            <info-grid-item [title]="'ADMIN.SPREADSHEET.COST_METER.HEADING' | translate">
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="meterUpdateProperties.updatedProperties.costMeter"
              />
            </info-grid-item>
            <info-grid-item [title]="'ADMIN.SPREADSHEET.LINK_METER_FIXED_COSTS.HEADING' | translate">
              <input
                type="checkbox"
                kendoCheckBox
                [(ngModel)]="meterUpdateProperties.updatedProperties.linkMeterFixedCosts"
              />
            </info-grid-item>
          </div>
          <div class="info-grid-input-container">
            <info-grid-item [title]="'ADMIN.METER.COST_FACTOR_SOURCE' | translate">
              <kendo-numerictextbox
                [decimals]="0"
                format="#"
                [(ngModel)]="meterUpdateProperties.updatedProperties.costFactorSourceMeterId"
                [disabled]="!meterUpdateProperties.updatedProperties.costMeter"
              >
              </kendo-numerictextbox>
            </info-grid-item>
          </div>
        </div>

        <div class="info-grid-button-container">
          <button
            class="button"
            (click)="save()"
          >
            {{ 'MODALS.SAVE' | translate }}
          </button>
        </div>
      </div>

      <div class="info-grid-info-container">
        <info-grid-item
          [title]="'METER_ID' | translate"
          [data]="meter?.id"
        ></info-grid-item>
        <info-grid-item
          [title]="'FACILITY_NAME' | translate"
          [data]="facility?.displayName"
        ></info-grid-item>
        <info-grid-item [title]="'METER_TYPE' | translate">
          <meter-type [meterType]="meter.meteringType"></meter-type>
        </info-grid-item>
        <info-grid-item [title]="'QUANTITY' | translate">
          {{ meter.quantityId | quantityName | async }}
        </info-grid-item>
      </div>
    </div>
  </accordion-group>

  <accordion-group>
    <h2 accordion-heading>{{ 'ADMIN.METER_HISTORY.TITLE' | translate }}</h2>
    <meter-history [meter]="meter"></meter-history>
  </accordion-group>
</accordion>
