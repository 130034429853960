import { ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { StringKeys } from '@enerkey/ts-utils';

import { SearchComponent } from '../../search/search.component';

@Component({
  selector: 'facility-search',
  templateUrl: '../../search/search.component.html',
  styleUrls: ['../../search/search.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilitySearchComponent),
    multi: true
  }]
})
export class FacilitySearchComponent<B extends boolean = any> extends SearchComponent<Facility, 'id', B> {
  public readonly valueField: 'id' = 'id';
  public override readonly placeHolder = 'FIND_FACILITY_BY_NAME';
  public readonly textField: StringKeys<Facility> = 'displayName';

  public constructor(
    private facilityClient: FacilityClient,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }

  protected fetchItems(): Observable<Facility[]> {
    return this.facilityClient
      .getFacilityIdsByNameContainsCaseInsensitive(this.filterStringPrefix)
      .pipe(concatMap(
        ids => Array.hasItems(ids)
          ? this.facilityClient.getFacilities(ids)
          : of([])
      ));
  }

}
