/**
 * Automatic metering type (hourly)
 *
 * @type {number}
 */
export const AUTOMATIC = 1;

/**
 * Manual reading metering type
 *
 * @type {number}
 */
export const MANUAL_READING = 2;

/**
 * Constant metering type
 *
 * @type {number}
 */
export const CONSTANT = 3;

/**
 * Virtual metering type
 *
 * @type {number}
 */
export const VIRTUAL = 4;

/**
 * Manual consumption type
 *
 * @type {number}
 */
export const MANUAL_CONSUMPTION = 5;
