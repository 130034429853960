/* istanbul ignore next */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

export type ObservableType<T> = T extends import('rxjs').Observable<infer U> ? U : never;

export type Awaited<T> = T extends PromiseLike<infer U> ? U : T

export type Mutable<T> = { -readonly [K in keyof T]: T[K] };

/** Typed key/value pair of `T`'s properties. */
export type ParamOf<T extends Record<keyof any, any>> = { [K in keyof T]: { key: K; value: T[K] } }[keyof T];

export type KeysOfType<T, E> = { [K in keyof T]: T[K] extends E ? K : never }[keyof T];

export type Constructor<T> = new (...args: any) => T;

export type StringKeys<T> = KeysOfType<T, string>;

export type StringTypeKey<T> = Extract<keyof T, string>;

/**
 * Replace properties with certain values with others.
 * @example type OnlyStrings<T> = ValueCast<T, any, string>
 */
export type ValueCast<T, U, R> = { [P in keyof T]: T[P] extends U ? R : T[P] };

/**
 * Pick only properties with desired values.
 * @example type OnlyBooleans<T> = PickTypes<T, boolean>
 */
export type PickTypes<T, P> = Pick<T, KeysOfType<T, P>>;

/**
 * Omit properties with unwanted values.
 * @example type NoStrings<T> = OmitTypes<T, string>
 */
export type OmitTypes<T, O> = Omit<T, KeysOfType<T, O>>;

/** Partial that ensures at least one key is defined in `T`. */
export type RequireOne<T> = {
  [K in keyof T]: Pick<T, K> & Partial<Omit<T, K>>
}[keyof T];

/** Partial that requires only one key of `T`. */
export type PickOne<T extends Record<string, unknown>> = {
  [K in keyof T]: { [X in K]: T[K] }
}[keyof T];

/**
 * Makes all optional properties of `T` required.
 */
export type Complete<T> = {
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : (T[P] | undefined);
}

/**
 * Design-time type guard for complex typings and `as const`-values.
 *
 * @example
 * type Direction = 'left' | 'right' | 'up' | 'down';
 * const allDirections = ['left', 'right', 'up', 'down'] as const;
 * assertType<TypeEq<(typeof allDirections)[number], Direction>>();
 */
export function assertType<_T extends true>(): void { }

/**
 * Asserts that the properties of `as const`-object `T` extends ones on the source type `Base`.
 */
export type TypeExtends<T extends Record<keyof any, any>, Base extends Record<keyof any, any>> = {
  [K in keyof T]: K extends keyof Base ? T[K] extends Base[K] ? true : false : false
}[keyof T];

// Source: https://github.com/kgtkr/typepark/
export type TypeEq<A, B> = (<T>() => T extends A ? 1 : 2) extends (<T>() => T extends B ? 1 : 2)
  ? true
  : false;

export type ValuesExtend<A, B> = TypeEq<A[keyof A], B>;

/**
 * Type guard that ensures that the $inject-property contains the same amount of arguments.
 *
 * @example
 * ```ts
 * class HostClass {
 *   public static readonly $inject: InjectArgs<typeof HostClass> = [...];
 * }
 * ```
 */
export type $InjectArgs<T> = T extends { new(...args: infer U): any }
  ? ([string, ...string[]] & { length: U['length'] })
  : never;

export type InterfaceOf<T> = {
  [K in keyof T]: T[K]
}

export type DeepPartial<T, Depth extends number = 4 > = DP<T, Depth, []>;

/**
 * Makes all properties of `T` required.
 */
export type DeepRequired<T> = { [K in keyof T]: DeepRequired<T[K]>} & Required<T>

type DP<T, D, A extends any[]> =
  A['length'] extends D ? any : T extends Record<keyof any, any> ? {
    [K in keyof T]?: T[K] extends (infer U)[] ? DP<U, D, Prepend<A, any>>[] : DP<T[K], D, Prepend<A, any>>
  } : T;

type Prepend<A extends any[], T> = ((arg: T, ...args: A) => any) extends ((...args: infer U) => any) ? U : never;
