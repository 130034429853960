<label
  #labelElement
  [ngClass]="labelClass"
  (click)="labelClick($event)"
>
  <ng-container *ngTemplateOutlet="wrapTemplate"></ng-container>
  {{ labelText }} <span *ngIf="required" class="required">*</span>
  <ek-tooltip-indicator
    *ngIf="tooltip"
    [tooltip]="tooltip"
    [placement]="'right'"
  ></ek-tooltip-indicator>
</label>
<ng-container *ngTemplateOutlet="beforeTemplate"></ng-container>
