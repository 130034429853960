import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { Observable, of, Subject } from 'rxjs';
import { catchError, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import {
  OutgoingRequestDto,
  OutgoingRequestLogSearchCriteria,
  WebhookClient
} from '@enerkey/clients/webhook';

import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';
import { FileDownloadService } from '../../../../shared/services/file-download.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { OutgoingMessagesService } from '../../services/outgoing-messages.service';

type RowSelectKey = 'id';

@Component({
  selector: 'outgoing-messages-grid',
  templateUrl: './outgoing-messages-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService],
})
export class OutgoingMessagesGridComponent implements AfterViewInit, OnDestroy {
  public readonly messages$: Observable<OutgoingRequestDto[]>;

  public readonly selectKey: RowSelectKey = 'id';

  public selection: OutgoingRequestDto[RowSelectKey][] = [];

  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };

  @ViewChild(GridComponent) private readonly kendoGrid: GridComponent;

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private readonly outgoingMessagesService: OutgoingMessagesService,
    private readonly webhookClient: WebhookClient,
    private readonly gridService: KendoGridService<OutgoingRequestDto, RowSelectKey>,
    private readonly fileDownloadService: FileDownloadService,
    private readonly toasterService: ToasterService
  ) {
    this.messages$ = this.outgoingMessagesService.searchParams$.pipe(
      switchMap(params => this.webhookClient.getOutgoingRequestsBySearchCriteria(params)),
      catchError(() => {
        this.toasterService.error('SUPERVISION.FAILED_TO_GET_MESSAGES');
        return of([]);
      }),
      takeUntil(this.destroy$),
      shareReplay(1)
    );

    this.messages$.subscribe(data => {
      this.gridService.dataChanged(data);
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.gridService.selection$.subscribe(keys => {
      this.selection = keys;
    });
  }

  public retry(): void {
    this.webhookClient.retryOutgoingRequests(new OutgoingRequestLogSearchCriteria({
      outgoingRequestIds: this.selection
    })).subscribe({
      next: retryResults => {
        const failedIds = retryResults.filterMap(
          result => !result.success,
          result => result.requestId
        );
        if (failedIds.length) {
          this.toasterService.warning(
            {
              key: 'SUPERVISION.RETRY_FAILED_FOR_REQUESTS',
              params: { requests: failedIds.join(', ') }
            }
          );
        } else {
          this.toasterService.success('SUPERVISION.RETRY_SUCCESSFUL');
        }
      },
      error: () => {
        this.toasterService.error('SUPERVISION.RETRY_FAILED');
      }
    });
  }

  public downloadContent(request: OutgoingRequestDto): void {
    const fileName = `outgoing-${request.id}.json`;
    this.fileDownloadService.downloadAsJsonFile(JSON.parse(request.content), fileName);
  }
}
