import { IMqaClient } from './mqa-client';

export class MqaClientMock implements IMqaClient {
  public getDefectIssuesFromTimePeriodByMeterIds(): never {
    throw new Error('Method not implemented.');
  }
  public closeDefectIssue(): never {
    throw new Error('Method not implemented.');
  }
  public closeDefectIssues(): never {
    throw new Error('Method not implemented.');
  }
  public getFaultsByDefectIssueId(): never {
    throw new Error('Method not implemented.');
  }
  public getAllFaultTypes(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterAndFacilityByMeterIds(): never {
    throw new Error('Method not implemented.');
  }
  public getReadingSourceTypes(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterHistory(): never {
    throw new Error('Method not implemented.');
  }
  public changeMeter(): never {
    throw new Error('Method not implemented.');
  }
  public removeMeterChange(): never {
    throw new Error('Method not implemented.');
  }
  public updateFactor(): never {
    throw new Error('Method not implemented.');
  }
  public updateMeter(): never {
    throw new Error('Method not implemented.');
  }
  public getQuantities(): never {
    throw new Error('Method not implemented.');
  }
  public combinedRawReadings(): never {
    throw new Error('Method not implemented.');
  }
  public getReadings(): never {
    throw new Error('Method not implemented.');
  }
  public upsertHourlyReadings(): never {
    throw new Error('Method not implemented.');
  }
  public getModelledHourReadings(): never {
    throw new Error('Method not implemented.');
  }
  public lockModeledRawReadings(): never {
    throw new Error('Method not implemented.');
  }
  public unlockModeledRawReadings(): never {
    throw new Error('Method not implemented.');
  }
  public removeNonLockedModeledRawReadings(): never {
    throw new Error('Method not implemented.');
  }
  public removeRawReadings(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemsForEnegiaIds(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemsForFacilityName(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemsForMeasurementPointName(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemsForMeterIds(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemsForProfileIds(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemsForBucketIds(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemsForReaderTypeIds(): never {
    throw new Error('Method not implemented.');
  }
  public insertSearchList(): never {
    throw new Error('Method not implemented.');
  }
  public updateSearchList(): never {
    throw new Error('Method not implemented.');
  }
  public deleteSearchList(): never {
    throw new Error('Method not implemented.');
  }
  public getSearchList(): never {
    throw new Error('Method not implemented.');
  }
  public getSearchLists(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemStatisticsForProfileIds(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemStatisticsForEnegiaIds(): never {
    throw new Error('Method not implemented.');
  }
  public getInspectionItemStatisticsForMeterIds(): never {
    throw new Error('Method not implemented.');
  }
  public getDefectIssuesForEnegiaIds(): never {
    throw new Error('Method not implemented.');
  }
  public getDefectIssuesForFacilityName(): never {
    throw new Error('Method not implemented.');
  }
  public getDefectIssuesForMeasurementPointName(): never {
    throw new Error('Method not implemented.');
  }
  public getDefectIssuesForMeterIds(): never {
    throw new Error('Method not implemented.');
  }
  public getDefectIssuesForProfileIds(): never {
    throw new Error('Method not implemented.');
  }
  public getDefectIssuesForBucketIds(): never {
    throw new Error('Method not implemented.');
  }
  public getDefectIssuesForReaderTypeIds(): never {
    throw new Error('Method not implemented.');
  }
}
