import ManualQaSearchListDeleteController from './manual-qa-search-list-delete-controller';
import template                           from 'raw-loader!./manual-qa-search-list-delete.html';

export default {
  template: template,
  controller: ManualQaSearchListDeleteController,
  controllerAs: 'vm',
  bindings: {
    searchList: '<',
    onDelete: '&',
    onClose: '&'
  }
};
