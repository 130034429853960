import FacilitiesReportType from 'ngtemplate-loader!raw-loader!../templates/facilities-report-type.html';
import _ from 'lodash';
import facilitiesGridDesktopTemplate from 'ngtemplate-loader!raw-loader!../templates/facilities-grid-desktop.html';

import { ServiceLevel } from '@enerkey/clients/facility';
import { DocumentCompleteViewModel } from '@enerkey/clients/attachments';

import { ReportSettings } from '../shared/report-settings';

import { DocumentsPreviewComponent } from '../../documents/components/documents-preview/documents-preview.component';

const beforePrint = function() {
  angular.element('[data-role="chart"]').each(function() {
    const chart = angular.element(this); // eslint-disable-line no-invalid-this
    const chartOptsArea = chart.data('kendoChart').options.chartArea;
    // Only target graphs that are specified as being different
    // print height.
    if (chartOptsArea.printHeight) {
      chart.css('width', '11.5cm');
      chartOptsArea.screenHeight = chartOptsArea.height;
      // printHeight property is just for this purpose
      chartOptsArea.height = chartOptsArea.printHeight;
      chart.data('kendoChart').redraw();
    }
  });
};

const afterPrint = function() {
  angular.element('[data-role="chart"]').each(function() {
    const chart = angular.element(this); // eslint-disable-line no-invalid-this
    const chartOptsArea = chart.data('kendoChart').options.chartArea;
    // Only reset graphs that were specified as being different
    // print height.
    if (chartOptsArea.screenHeight) {
      chart.css('width', '');
      chartOptsArea.height = chartOptsArea.screenHeight;
      chartOptsArea.screenHeight = null;
      chart.data('kendoChart').redraw();
    }
  });
};

const $inject = [
  '$scope', '$state', '$timeout', '$q', 'utils',
  'ERReportType', 'appStatusService', '$transitions', 'erReportSettingsService',
  'modalService', 'erParamsService', 'erCacheService', 'serviceLevelService', 'thresholdService',
  'modalServiceAngular', '$window'
];

function FacilitiesController(
  $scope, $state, $timeout, $q, utils,
  ERReportType, appStatusService, $transitions, erReportSettingsService,
  modalService, erParamsService, erCacheService, serviceLevelService, thresholdService,
  modalServiceAngular, $window
) {
  $scope.facilitiesGridDesktopTemplate = facilitiesGridDesktopTemplate;

  // initialization
  let initialized = false;
  const initDeferred = $q.defer();

  // default timer
  $scope.defaultTimer = 200;

  // detect mobile platform
  $scope.appStatusService = appStatusService;

  $scope.$state = $state;

  $scope.facilitiesReportType = FacilitiesReportType;

  // params (state params will be added to scope)
  erReportSettingsService.setActiveInstance('er-report');
  const erReportSettings = erReportSettingsService.getInstance();
  erReportSettings.setSettings(new ReportSettings($state.params));
  $scope.params = erReportSettings.getSettings();
  let oldParams = _.cloneDeep($scope.params);

  $scope.initialize = function() {
    // Quantity names and types
    $scope.showMobileReport = appStatusService.isMobileBreakpoint ||
      appStatusService.isMobile ||
      !serviceLevelService.hasAtLeastServiceLevel(ServiceLevel.Medium)
    ;
    erCacheService.getCache()
      .then(cache => {
        $scope.cache = cache;
        $scope.cache.appStatus = $scope.appStatus;
      })
      .catch(err => {
        utils.trackError(err, 'FacilitiesController init/erCache error');
        return $scope.handleError(err);
      })
      .finally(() => {
        $timeout(() => {
          initialized = true;
          initDeferred.resolve();
          handleThirdPartyLogin();
          handleModalBookmarks();
        }, $scope.defaultTimer);
      })
    ;
  };

  function handleThirdPartyLogin() {
    if (($state.params.RealEstateId || $state.params.EnegiaId) && $state.params.ReportType) {
      let foundFacility;
      if ($state.params.EnegiaId) {
        foundFacility = Object.values($scope.cache.facilitiesById)
          .find(facility => facility.FacilityInformation.EnegiaId === $state.params.EnegiaId);
      } else {
        foundFacility = Object.values($scope.cache.facilitiesById)
          .find(facility => facility.FacilityInformation.RealEstateId === $state.params.RealEstateId);
      }
      modalService.getModalWithComponent('report-modal', {
        reportType: $state.params.ReportType,
        reportParams: { facilityId: [foundFacility.FacilityId] }
      });
    }
  }

  function handleModalBookmarks() {
    if ($state.params.modalParams) {
      modalService.getModalWithComponent('report-modal', {
        reportType: $state.params.modalParams.reportType,
        reportParams: $state.params.modalParams.reportParams
      });
    }
  }

  $scope.parentInitialized = function() {
    return initDeferred.promise;
  };

  $scope.getQuantities = function() {
    return _.values($scope.cache.quantitiesById);
  };

  $scope.getSelectedFacilities = function(facilityIds) {
    facilityIds = facilityIds || $scope.params.facilityId;
    return _.chain(facilityIds)
      .map(facilityId => $scope.cache.facilitiesById[facilityId])
      .sortBy('Name')
      .value();
  };

  $scope.getQuantity = function(id) {
    return $scope.cache.quantitiesById[id];
  };

  $scope.getRelationalValue = function(id) {
    return $scope.cache.relationalValuesById[id];
  };

  $scope.getDistributionType = function(id) {
    return $scope.cache.distributionTypesById[id];
  };

  $scope.getRelatedValues = function(quantityId) {
    return $scope.cache.relatedValuesByQuantityId[quantityId];
  };

  $scope.filterRelatedValueIdsForQuantity = function(relatedValueIds, quantityId) {
    const relatedValues = $scope.getRelatedValues(quantityId);
    return _.filter(relatedValueIds, relatedValueId => _.find(relatedValues, { Id: relatedValueId }));
  };

  $scope.getErrorCode = function(code) {
    return $scope.cache.errorCodesByCode[code];
  };

  $scope.validateSeries = function(series) {
    return series && series.Resolution && series.TimeFrame && series.Start.length;
  };

  $scope.showMeterMapImage = function(document) {
    const modal = modalServiceAngular.open(DocumentsPreviewComponent);
    modal.componentInstance.selectedDocument = new DocumentCompleteViewModel({
      id: document.Id,
      fileNameOrUrl: document.FileNameOrUrl
    });
  };

  $scope.handleError = function(text, title) {
    if (!title) {
      title = utils.localizedString('FACILITIES.DOWNLOAD_ERROR');
    }
    utils.popUp('error', title, text);
  };

  $scope.getSeriesErrorTextKey = function(series) {
    if (_.get(series, 'ExportOnly')) {
      return 'FACILITIES_REPORT.ONLY_DOWNLOAD';
    } else if (_.get($state, '$current.data.reportType') === ERReportType.TREND_REPORT && series.Resolution !== 'P1M') {
      return 'FACILITIES_REPORT.NO_DATA_FOR_TREND_REPORT';
    }
    return null;
  };

  const debouncedParamsChangedBroadcast = _.debounce(() => {
    const changedParams = [];
    _.each($scope.params, (value, key) => {
      if (angular.toJson($scope.params[key]) !== angular.toJson(oldParams[key])) {
        changedParams.push(key);
      }
    });
    if (changedParams.length) {
      // broadcast change
      if (initialized) {
        $scope.$evalAsync(() => {
          $scope.$broadcast(
            'facilities.paramsChanged',
            { changedParams: changedParams, oldParams: _.cloneDeep(oldParams) }
          );
          oldParams = _.cloneDeep($scope.params);
        });
      } else {
        oldParams = _.cloneDeep($scope.params);
      }
    }
  }, 0.25 * $scope.defaultTimer);

  const stateChangeSuccessUnbind = $transitions.onSuccess({}, () => {
    $scope.params = erReportSettings.getSettings();
  });

  function refreshStateParams() {
    if (initialized) {
      $state.go($state.current.name, angular.copy($scope.params));
    }
  }

  const optionsChangedUnbind = erReportSettings.setCallback(optionsChanged);
  function optionsChanged() {
    debouncedParamsChangedBroadcast();
    refreshStateParams();
  }

  const thresholdSubscription = thresholdService.threshold$.subscribe(() => {
    if (initialized) {
      $scope.$broadcast('facilities.paramsChanged', { forceUpdate: true });
    }
  });

  $scope.$on('$destroy', () => {
    stateChangeSuccessUnbind();
    optionsChangedUnbind();
    erReportSettings.resetSettings();

    thresholdSubscription.unsubscribe();

    // remove leaked kendo calendar containers
    $('.k-calendar-container.k-popup[aria-hidden=true]').remove(); // unopened
    $('.k-animation-container[aria-hidden=true]').remove(); // once opened

    $window.removeEventListener('beforeprint', beforePrint);
    $window.removeEventListener('afterprint', afterPrint);
  });

  // initialize
  $scope.initialize();

  $window.addEventListener('beforeprint', beforePrint);
  $window.addEventListener('afterprint', afterPrint);
}

FacilitiesController.$inject = $inject;

export default FacilitiesController;
