import { ChangeDetectionStrategy, Component, Inject, Injector, OnDestroy } from '@angular/core';
import {
  PagerSettings,
  RowClassArgs,
  SelectableSettings,
  SelectionItem,
} from '@progress/kendo-angular-treelist';
import { CompositeFilterDescriptor, DataResult, filterBy, process } from '@progress/kendo-data-query';
import { BehaviorSubject, combineLatest, Observable, Subject, timer } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { ModalService } from '@enerkey/foundation-angular';
import { MeteringType } from '@enerkey/clients/meter-management';
import { getNumericEnumValues } from '@enerkey/ts-utils';
import { Meter, PoaService } from '@enerkey/clients/metering';

import { QuantityService } from '../../../../shared/services/quantity.service';
import { MeterManagementService } from '../../services/meter-management.service';
import { MeterHierarchyTreelistItem } from '../../shared/meter-hierarchy-factory';
import { MeterEditModalComponent } from '../meter-edit-modal/meter-edit-modal.component';
import { MeterUpdateProperties } from '../../../virtual-meters/shared/meter-update-properties';
import { MeterInterfaceService } from '../../../../services/meter-interface-service';
import { TerminalService } from '../../../../shared/services/terminal.service';
import { AjsModalService } from '../../../../services/modal/modal.service';
import { ManualQaInspectModalService } from '../../../manual-qa/components/manual-qa-inspect-modal/manual-qa-inspect-modal.service';
import { MeterTagEditModalComponent } from '../meter-tag-edit-modal/meter-tag-edit-modal.component';

interface TreelistSelectionItem<T> extends SelectionItem {
  itemKey: T;
}

@Component({
  selector: 'meter-search-treelist',
  templateUrl: './meter-search-treelist.component.html',
  styleUrls: ['./meter-search-treelist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterSearchTreelistComponent implements OnDestroy {
  public readonly meters$: Observable<MeterHierarchyTreelistItem[]>;
  public readonly quantities$: Observable<number[]>;
  public readonly loading$: Observable<boolean>;
  public readonly flatMeters$: Observable<MeterHierarchyTreelistItem[]>;
  public readonly visibleMeters$: Observable<MeterHierarchyTreelistItem[]>;
  public readonly meteringTypes: readonly MeteringType[];
  public readonly uniqueTags$: Observable<{ id: string, tagName: string }[]>;

  public readonly pagerSettings: PagerSettings = {
    pageSizes: [50, 100, 200, 500]
  };

  public readonly flatExportFields = [
    { field: 'facility.enegiaId', title: 'ENEGIA_ID' },
    { field: 'facility.displayName', title: 'FACILITY_NAME' },
    { field: 'facility.businessIdentityCodeId', title: 'BUSINESS_IDENTITY_CODE' },
    { field: 'meter.name', title: 'METER_NAME' },
    { field: 'meter.id', title: 'METER_ID' },
    { field: 'quantityName', title: 'QUANTITY' },
    { field: 'isFloatingMeter', title: 'ADMIN.SPREADSHEET.FLOATING.HEADING' },
    { field: 'isRelatedMeter', title: 'ADMIN.SPREADSHEET.RELATED.HEADING' },
    { field: 'meter.meteringType', title: 'ADMIN.SPREADSHEET.METER_TYPE_NAME.HEADING' },
    { field: 'meter.factor', title: 'FACTOR' },
    { field: 'meter.twoTimeMeasurement', title: 'ADMIN.SPREADSHEET.TWO_TIME_MEASUREMENT.HEADING' },
    { field: 'meter.automaticReadingStartTime', title: 'ADMIN.SPREADSHEET.AUTOMATIC_START_TIME.HEADING' },
    { field: 'meter.automaticReadingEndTime', title: 'ADMIN.SPREADSHEET.AUTOMATIC_END_TIME.HEADING' },
    { field: 'meter.deactivationTime', title: 'ADMIN.SPREADSHEET.DEACTIVATION_TIME.HEADING' },
    { field: 'meter.costMeter', title: 'ADMIN.SPREADSHEET.COST_METER.HEADING' },
    { field: 'meter.customerMeterIdentifier', title: 'ADMIN.SPREADSHEET.CUSTOMER_METER_IDENTIFIER.HEADING' },
    { field: 'meter.linkMeterFixedCosts', title: 'ADMIN.SPREADSHEET.LINK_METER_FIXED_COSTS.HEADING' },
    { field: 'meter.qualityAssurance', title: 'ADMIN.SPREADSHEET.QUALITY_ASSURANCE.HEADING' },
    { field: 'meter.automaticModeling', title: 'ADMIN.SPREADSHEET.AUTOMATIC_MODELING.HEADING' },
    { field: 'meter.terminal', title: 'ADMIN.METER_SEARCH.READER_TERMINAL' },
    { field: 'meter.usagePlaceNumber', title: 'USAGE_PLACE_NUMBER' },
    { field: 'meter.energyCompanyUsagePlaceNumber', title: 'ADMIN.METER.ENERGY_COMPANY_USAGE_PLACE_NUMBER' },
    { field: 'meter.eanCode', title: 'EAN_CODE' },
    { field: 'meter.protocolCode', title: 'PROTOCOL_CODE' },
    { field: 'meter.resolution', title: 'ADMIN.METER.RESOLUTION' },
    { field: 'meter.createdBy', title: 'CREATED_BY' },
    { field: 'meter.created', title: 'CREATED' },
    { field: 'meter.lastModifiedBy', title: 'LAST_MODIFIED_BY' },
    { field: 'meter.lastModified', title: 'LAST_MODIFIED' },
    { field: 'poa.companyName', title: 'CONTACT_MANAGER.COMPANY_NAME' },
    { field: 'poa.companyOvt', title: 'ADMIN.POA.COMPANY_OVT' },
    { field: 'poa.name', title: 'ADMIN.POA.POA_NAME' },
    { field: 'poa.externalLocationLink', title: 'ADMIN.POA.EXTERNAL_LOCATION_LINK' },
    { field: 'poa.powerOfAttorneyId', title: 'ADMIN.POA.POA_ID' },
    { field: 'poa.validFrom', title: 'ADMIN.POA.VALID_FROM' },
    { field: 'poa.validTo', title: 'ADMIN.POA.VALID_TO' },
    { field: 'poa.externalService', title: 'ADMIN.POA.EXTERNAL_SERVICE' },
    { field: 'meter.dataHubCustomerAuthorizationCode', title: 'ADMIN.POA.AUTHORIZATION_STATUS' },
  ];

  public readonly selectableSettings: SelectableSettings = {
    enabled: true,
    mode: 'row',
    multiple: true,
    drag: false,
    readonly: false,
  };

  public selected: TreelistSelectionItem<MeterHierarchyTreelistItem>[] = [];

  public filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [{ field: 'isFloatingMeter', operator: 'eq', value: false }]
  };

  public readonly poaServices = getNumericEnumValues(PoaService);

  private readonly treelistFilter$: BehaviorSubject<CompositeFilterDescriptor>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly meterManagementService: MeterManagementService,
    private readonly modalService: ModalService,
    private readonly terminalService: TerminalService,
    private readonly ajsModalService: AjsModalService,
    private readonly manualQaInspectModalService: ManualQaInspectModalService,
    private readonly injector: Injector,
    @Inject('MeterInterfaceService') private meterInterfaceService: MeterInterfaceService
  ) {
    this.treelistFilter$ = new BehaviorSubject(this.filter);

    this.fetchExcelData = this.fetchExcelData.bind(this);
    this.loading$ = this.meterManagementService.loading$;
    this.getQuantityName = this.getQuantityName.bind(this);

    this.meteringTypes = getNumericEnumValues(MeteringType);

    this.meters$ = this.meterManagementService.currentHierarchy$.pipe(
      tap(() => {
        this.selected = this.meterManagementService.getSelectedMeters().map(meter => ({ itemKey: meter }));
      })
    );

    this.uniqueTags$ = this.meterManagementService.uniqueTags$.pipe(
      filter(tags => tags && tags.length > 0)
    );

    this.flatMeters$ = this.meterManagementService.flatMeters$;

    this.visibleMeters$ = combineLatest([
      this.flatMeters$,
      this.treelistFilter$,
    ]).pipe(
      map(([meters, filters]) => filterBy(meters, filters))
    );

    this.quantities$ = this.flatMeters$.pipe(
      map(meters => meters.unique(m => m.meter.quantityId))
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();

    this.treelistFilter$.complete();
  }

  public getQuantityName(id: number): string {
    return this.quantityService.getQuantityNameForId(id);
  }

  public rowCallback(context: RowClassArgs): Record<string, boolean> {
    const dataItem: MeterHierarchyTreelistItem = context.dataItem;
    if (dataItem.facilityId) {
      return { 'facility-parent': true };
    }
    if (dataItem.quantityId) {
      return {
        'quantity-parent': true,
        [`quantity-parent-${dataItem.quantityId}`]: true
      };
    }
    return {
      'main-meter-parent': dataItem.isMainMeter,
      'floating-parent': dataItem.isFloatingMeter,
      'related-parent': dataItem.isRelatedMeter,
      'highlight-meter': dataItem.isHighlightedMeter
    };
  }

  public openMeterEditModal(item: MeterHierarchyTreelistItem): void {
    const meter = item.meter;
    const meterUpdateProperties = new MeterUpdateProperties(meter);
    const modalRef = this.modalService.open(
      MeterEditModalComponent,
      { injector: this.injector }
    );
    modalRef.componentInstance.meter = meter;
    modalRef.componentInstance.meterUpdateProperties = meterUpdateProperties;

    modalRef.result.finally(() => {
      if (meterUpdateProperties.hasSaved()) {
        this.meterManagementService.repeatSearch();
      }
    });
  }

  public editTags(dataItem: MeterHierarchyTreelistItem): void {
    const modalRef = this.modalService.open(MeterTagEditModalComponent);
    modalRef.componentInstance.selectedMeter = new Meter({
      id: dataItem.meter.id,
      name: dataItem.meter.name,
      reportingObjectId: null,
      meteringType: null,
      quantityId: null,
      factor: null,
      twoTimeMeasurement: null,
      customerMeterIdentifier: null,
    });
  }

  public ccPopUp(item: MeterHierarchyTreelistItem): void {
    this.meterInterfaceService.getModal(
      item.facility.displayName,
      item.meter.id,
      item.meter.name
    );
  }

  public goToFacilityMeterReport(item: MeterHierarchyTreelistItem): void {
    this.ajsModalService.getModalWithComponent('report-modal', {
      reportType: 'modal.metertree',
      reportParams: {
        facilityId: [item.facility.id],
        meterId: [item.meter.id]
      }
    });
  }

  public goToMQA(item: MeterHierarchyTreelistItem): void {
    this.manualQaInspectModalService.getInspectModal(item.meter.id);
  }

  public goToTerminal(item: MeterHierarchyTreelistItem): void {
    this.terminalService.getTerminalStatusModal(item.meter.terminal);
  }

  public selectionChange(): void {
    timer(0).subscribe(() => {
      this.meterManagementService.setSelectedMeters(
        this.selected.filterMap(
          i => !!i.itemKey.meter,
          i => i.itemKey
        )
      );
    });
  }

  public filterChange(filters: CompositeFilterDescriptor): void {
    this.treelistFilter$.next(filters);
  }

  public fetchExcelData(): {
    data: Observable<DataResult>;
    fetchChildren: () => unknown;
    hasChildren: () => unknown;
  } {
    return {
      data: this.visibleMeters$.pipe(
        take(1),
        map(meters => process(
          meters,
          {
            sort: [
              { field: 'facility.enegiaId', dir: 'asc' },
              { field: 'isFloatingMeter', dir: 'asc' },
              { field: 'meter.quantityId', dir: 'asc' },
              { field: 'isRelatedMeter', dir: 'asc' },
              { field: 'meter.name', dir: 'asc' }
            ]
          }
        ))
      ),
      fetchChildren: /* istanbul ignore next */ () => [] as unknown[],
      hasChildren: /* istanbul ignore next */ () => false,
    };
  }
}
