<form [formGroup]="formGroup">
  <emissions-type-select
    formControlName="factorOption"
    *labelBefore="'EMISSIONS_WIDGET.FACTOR_CHANGE' | translate"
  ></emissions-type-select>
  <value-type-select
    formControlName="valueOption"
    *labelBefore="'FACILITIES_TOOLBAR.VALUE_OPTION' | translate"
    [hideBothSelection]="true"
  ></value-type-select>
  <comparability-select
    formControlName="comparableOption"
    *labelBefore="'FACILITIES_TOOLBAR.COMPARABLE' | translate"
  ></comparability-select>
</form>
