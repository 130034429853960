import { Component, Input } from '@angular/core';

import { OverviewQuantity } from '../facility-overview/facility-overview.component';

@Component({
  selector: 'overview-card-content',
  templateUrl: './overview-card-content.component.html',
  styleUrls: ['./overview-card-content.component.scss']
})
export class OverviewCardContentComponent {
  @Input() public item: OverviewQuantity;
  @Input() public timeRange: keyof Pick<OverviewQuantity, 'Month' | 'Year'> = 'Month';
  @Input() public expanded = false;
}
