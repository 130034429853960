export const actionGroups = [
  { id: null, key: 'ACTIONS.NOTSELECTED' },
  { id: 0, key: 'ACTIONS.ACTIONGROUP_0' },
  { id: 1, key: 'ACTIONS.ACTIONGROUP_1' },
  { id: 2, key: 'ACTIONS.ACTIONGROUP_2' },
  { id: 3, key: 'ACTIONS.ACTIONGROUP_3' },
  { id: 4, key: 'ACTIONS.ACTIONGROUP_4' },
  { id: 5, key: 'ACTIONS.ACTIONGROUP_5' },
  { id: 6, key: 'ACTIONS.ACTIONGROUP_6' },
  { id: 7, key: 'ACTIONS.ACTIONGROUP_7' },
  { id: 8, key: 'ACTIONS.ACTIONGROUP_8' },
  { id: 9, key: 'ACTIONS.ACTIONGROUP_9' },
  { id: 10, key: 'ACTIONS.ACTIONGROUP_10' },
  { id: 11, key: 'ACTIONS.ACTIONGROUP_11' },
  { id: 12, key: 'ACTIONS.ACTIONGROUP_12' },
  { id: 13, key: 'ACTIONS.ACTIONGROUP_13' }
];
