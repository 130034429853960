export enum Service {
  Actions = 'Actions',
  Alarms = 'Alarms',
  BenchmarkAnalytics = 'BenchmarkAnalytics',
  Comments = 'Comments',
  ConsumptionTargets = 'ConsumptionTargets',
  CostReportingLocationBased = 'CostReportingLocationBased',
  CostReportingMeterBased = 'CostReportingMeterBased',
  DataAPI = 'DataAPI',
  DataAPIActions = 'DataAPIActions',
  DataAPIAllMeters = 'DataAPIAllMeters',
  DataAPIFacilitiesEdit = 'DataAPIFacilitiesEdit',
  DataAPIImages = 'DataAPIImages',
  DataAPIMainMeters = 'DataAPIMainMeters',
  Documents = 'Documents',
  ElectricityBaseloadOptimisation = 'ElectricityBaseloadOptimisation',
  ElectricityDemandResponseAnalytics = 'ElectricityDemandResponseAnalytics',
  EmissionReporting = 'EmissionReporting',
  EnergyReporting = 'EnergyReporting',
  EnergyReportingBase = 'EnergyReportingBase',
  EnergyReportingIndoorAir = 'EnergyReportingIndoorAir',
  EnergyReportingTransportFuels = 'EnergyReportingTransportFuels',
  EnergyReportingWaste = 'EnergyReportingWaste',
  EtCurveAnalytics = 'EtCurveAnalytics',
  HeatBalanceAnalytics = 'HeatBalanceAnalytics',
  HeatingCooling = 'HeatingCooling',
  HeatingOptimisation = 'HeatingOptimisation',
  HeatingPowerAnalytics = 'HeatingPowerAnalytics',
  InesLiteSummary = 'InesLiteSummary',
  InvoiceArchive = 'InvoiceArchive',
  LocalPowerApi = 'LocalPowerApi',
  ManualMeasurements = 'ManualMeasurements',
  MeterGroups = 'MeterGroups',
  PeakShaving = 'PeakShaving',
  Preview = 'Preview',
  PriceSecuring = 'PriceSecuring',
  ReportingBeta = 'ReportingBeta',
  SolarPowerAnalytics = 'SolarPowerAnalytics',
  Sustainability = 'Sustainability',
  TimeSeriesReporting = 'TimeSeriesReporting',
  VentilationAnalytics = 'VentilationAnalytics',
  Webhooks = 'Webhooks',
}

export const serviceNameTranslations: Readonly<Record<Service, string>> =
  Object.values(Service).toRecord(
    service => service,
    service => `ADMIN.SERVICE_NAME_${service.toUpperCase()}`
  );

type ServiceGroup =
  'DATA_API'
  | 'INES'
  | 'COSTS'
  | 'REPORTING'
  | 'ENERGY_MANAGEMENT'
  | 'SUSTAINABILITY'
  | 'OTHERS'

export function getServiceGroup(service: string): ServiceGroup {
  switch (service) {
    case Service.DataAPI:
    case Service.DataAPIActions:
    case Service.DataAPIMainMeters:
    case Service.DataAPIImages:
    case Service.DataAPIFacilitiesEdit:
    case Service.DataAPIAllMeters:
      return 'DATA_API';

    case Service.VentilationAnalytics:
    case Service.HeatBalanceAnalytics:
    case Service.HeatingPowerAnalytics:
    case Service.BenchmarkAnalytics:
    case Service.ElectricityDemandResponseAnalytics:
    case Service.InesLiteSummary:
    case Service.SolarPowerAnalytics:
    case Service.EtCurveAnalytics:
    case Service.HeatingOptimisation:
    case Service.HeatingCooling:
    case Service.PeakShaving:
    case Service.ElectricityBaseloadOptimisation:
      return 'INES';

    case Service.CostReportingLocationBased:
    case Service.CostReportingMeterBased:
    case Service.InvoiceArchive:
      return 'COSTS';

    case Service.EnergyReporting:
    case Service.ManualMeasurements:
    case Service.EnergyReportingBase:
    case Service.EnergyReportingWaste:
    case Service.EnergyReportingTransportFuels:
    case Service.EnergyReportingIndoorAir:
    case Service.ReportingBeta:
    case Service.MeterGroups:
      return 'REPORTING';

    case Service.Actions:
    case Service.Alarms:
    case Service.ConsumptionTargets:
    case Service.Comments:
      return 'ENERGY_MANAGEMENT';

    case Service.EmissionReporting:
    case Service.LocalPowerApi:
    case Service.Sustainability:
      return 'SUSTAINABILITY';

    case Service.Documents:
    case Service.PriceSecuring:
    case Service.Preview:
    case Service.TimeSeriesReporting:
    case Service.Webhooks:
      return 'OTHERS';

    default:
      return 'OTHERS';
  }
}
