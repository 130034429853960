export default {
  gridState: {
    columns: [
      {
        field: 'selected',
        hidden: false
      },
      {
        field: 'Id',
        hidden: false
      },
      {
        field: 'Name',
        hidden: false
      },
      {
        field: 'FacilityInformation',
        hidden: false,
        columns: [{ field: 'FacilityInformation.RealEstateId', hidden: false }]
      },
      {
        field: 'motivaids',
        hidden: false,
        columns: [
          { field: 'motivaids.MotivaId', hidden: false },
          { field: 'motivaids.MotivaOrganizationId', hidden: false }
        ]
      }
    ]
  }
};
