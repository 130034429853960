import EmInputDropdownController from './em-input-dropdown-controller';
import emInputDropdownTemplate   from 'raw-loader!./em-input-dropdown.html';

export default {
  template: emInputDropdownTemplate,
  controller: EmInputDropdownController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    onChange: '<'
  }
};
