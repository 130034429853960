<span
  *ngIf="status != null"
  [ngfTooltip]="status | logStatusName"
  [placement]="'bottom'"
  class="icon-container"
>
  <alarm-log-status-icon
    *ngFor="let iconStatus of statuses"
    [active]="status === iconStatus"
    [status]="iconStatus"
  ></alarm-log-status-icon>
</span>
