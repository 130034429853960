import { IAttachmentsClient } from './attachments-client';

export class AttachmentsClientMock implements IAttachmentsClient {
  public getActions(): never {
    throw new Error('Method not implemented.');
  }

  public addAction(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsImpact(): never {
    throw new Error('Method not implemented.');
  }

  public getDocumentsForAction(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsFlatListForReportingObject(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsFlatList(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsUsingPost(): never {
    throw new Error('Method not implemented.');
  }

  public importActions(): never {
    throw new Error('Method not implemented.');
  }

  public updateAction(): never {
    throw new Error('Method not implemented.');
  }

  public deleteAction(): never {
    throw new Error('Method not implemented.');
  }

  public getAction(): never {
    throw new Error('Method not implemented.');
  }

  public updateConnectorAction(): never {
    throw new Error('Method not implemented.');
  }

  public updateActionWithFiles(): never {
    throw new Error('Method not implemented.');
  }

  public addActionWithFiles(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsUsingEnegiaIds(): never {
    throw new Error('Method not implemented.');
  }

  public addExternalAction(): never {
    throw new Error('Method not implemented.');
  }

  public updateExternalAction(): never {
    throw new Error('Method not implemented.');
  }

  public deleteExternalAction(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsPotentialSavings(): never {
    throw new Error('Method not implemented.');
  }

  public getActionNoProfile(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsReferences(): never {
    throw new Error('Method not implemented.');
  }

  public getOwners(): never {
    throw new Error('Method not implemented.');
  }

  public getDocuments(): never {
    throw new Error('Method not implemented.');
  }

  public addDocument(): never {
    throw new Error('Method not implemented.');
  }

  public getSingleDocument(): never {
    throw new Error('Method not implemented.');
  }

  public updateDocument(): never {
    throw new Error('Method not implemented.');
  }

  public deleteDocument(): never {
    throw new Error('Method not implemented.');
  }

  public getDocumentPreviewSasUrl(): never {
    throw new Error('Method not implemented.');
  }

  public getDocumentDownloadSasUrl(): never {
    throw new Error('Method not implemented.');
  }

  public getMetermap(): never {
    throw new Error('Method not implemented.');
  }

  public createInvoice(): never {
    throw new Error('Method not implemented.');
  }

  public getInvoices(): never {
    throw new Error('Method not implemented.');
  }

  public getInvoiceXml(): never {
    throw new Error('Method not implemented.');
  }

  public upsertInvoiceXmlData(): never {
    throw new Error('Method not implemented.');
  }

  public getInvoiceDownloadSasUrl(): never {
    throw new Error('Method not implemented.');
  }

  public getActionsFilter(): never {
    throw new Error('Method not implemented.');
  }

}
