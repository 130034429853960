const $inject = ['$window', '$document'];

const SCROLL_SMOOTH = 'smooth';
const TOPBAR_CONTAINER = '#top-bar-container';

class Scroll {
  constructor($window, $document) {
    this.$window = $window;
    this.$document = $document;
  }

  scrollTop() {
    this.doScroll();
  }

  scrollTo(element, options) {
    options = options || {};
    const elementRect = angular.element(element)[0].getBoundingClientRect();
    const topBarHeight = angular.element(TOPBAR_CONTAINER)[0].offsetHeight;
    const scrollPosition = this.$window.pageYOffset;

    const topOffset = options.topOffset || 0;
    const behavior = options.behavior || SCROLL_SMOOTH;
    const scrollContainer = options.scrollContainer ? angular.element(options.scrollContainer)[0] : undefined;

    const top = scrollContainer
      ? elementRect.top - scrollContainer.getBoundingClientRect().top + scrollContainer.scrollTop - topOffset
      : elementRect.top + scrollPosition - topBarHeight - topOffset;

    this.doScroll(top, behavior, scrollContainer);
  }

  doScroll(top = 0, behavior = SCROLL_SMOOTH, scrollContainer) {
    const scrollElement = scrollContainer || this.$window;
    const edgeScrollElement = scrollContainer || this.$document[0].body;

    if (scrollElement.scrollTo) {
      scrollElement.scrollTo({ top, behavior });
    }

    // Special handling for MS Edge, scrollTo doesn't work in it
    if (!this.$document[0].body.scrollTo) {
      edgeScrollElement.scrollTop = top;
    }
  }
}

Scroll.$inject = $inject;

export default Scroll;
