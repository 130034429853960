function WizardController() {
  var vm = this;

  vm.currentStep = null;

  vm.setStep = setStep;
  vm.nextClicked = nextClicked;
  vm.stepNumber = stepNumber;
  vm.isStepDisabled = isStepDisabled;
  vm.isStepVisited = isStepVisited;
  vm.isStepInFuture = isStepInFuture;
  vm.$onInit = onInit;

  function onInit() {
    vm.setStep(vm.steps[0]);
  }

  function isStepDisabled(step) {
    if (step === vm.currentStep) {
      return false;
    } else if (vm.isStepInFuture(step)) {
      return true;
    } else {
      return !step.isReturnable || (step.stepDisabledFn && step.stepDisabledFn());
    }
  }

  function isStepVisited(step) {
    return vm.stepNumber(step) < vm.stepNumber(vm.currentStep);
  }

  function isStepInFuture(step) {
    return vm.stepNumber(step) > vm.stepNumber(vm.currentStep);
  }

  function stepNumber(step) {
    return vm.steps.indexOf(step);
  }

  function setStep(step) {
    if (isStepVisited(step) && step.rollbackFn) {
      step.rollbackFn();
    }
    vm.currentStep = step;
  }

  function nextClicked(step) {
    if (step.clickFn) {
      step.clickFn();
    }
    var nextStep = vm.steps[stepNumber(step) + 1];
    if (nextStep) {
      setStep(nextStep);
    }
  }
}

export default WizardController;