const $inject = [
  'utils',
  'ManualQaChangeMeterService',
  'ManualQaPopupService',
  'MqaSpreadsheetService',
  'ManualQaApiMeterService'
];

class ManualQaMeterChangeCommand {
  public constructor(
    private utils: any,
    private ManualQaChangeMeterService: any,
    private ManualQaPopupService: any,
    private MqaSpreadsheetService: any,
    private ManualQaApiMeterService: any
  ) {}

  public getCommand(controllerInstance: any): any {
    return {
      text: this.utils.localizedString('MQA.INSPECT.SHEET.MENU_ITEM.METER_CHANGE'),
      action: () => {
        const timeFrame = this.MqaSpreadsheetService.getSelectionTimeFrame(
          controllerInstance.spreadsheet,
          controllerInstance.dataSource.data()
        );
        const timestamp = timeFrame.getFromDate();
        const meter = controllerInstance.meter;

        const getModal = (effectPeriod: any) => {
          this.ManualQaChangeMeterService.getModal(meter, timestamp, effectPeriod).then(controllerInstance.getReadings);
        };

        const handleMeterChangeNotPossibleError = (error: any) => {
          const errorMessage = error.status ? this.utils.localizedString('MQA.ERRORS.METER_INFO') : error;
          this.ManualQaPopupService.errorPopUp(errorMessage);
        };

        this.ManualQaApiMeterService.getMeterHistory(meter)
          .then((history: any[]) =>
            this.ManualQaChangeMeterService.isMeterChangePossibleOnTimestamp(history, timestamp).then(() =>
              getModal(this.ManualQaChangeMeterService.getEffectPeriod(history, timestamp))
            )
          )
          .catch(handleMeterChangeNotPossibleError);
      }
    };
  }
}

ManualQaMeterChangeCommand.$inject = $inject;

export default ManualQaMeterChangeCommand;
