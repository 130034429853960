import { IEnergyReportingClient } from './energy-reporting-client';

export class EnergyReportingClientMock implements IEnergyReportingClient {
  public postMetersSlidingMonthlyValues(): never {
    throw new Error('Method not implemented.');
  }
  public getFacilityInformationById(): never {
    throw new Error('Method not implemented.');
  }
  public getTimeseriesConfiguration(): never {
    throw new Error('Method not implemented: getTimeseriesConfiguration');
  }
  public getRelationalValuesConfiguration(): never {
    throw new Error('Method not implemented: getRelationalValuesConfiguration');
  }
  public getDistributionTypesConfiguration(): never {
    throw new Error('Method not implemented: getDistributionTypesConfiguration');
  }
  public getDistributionTypesConfigurationv2(): never {
    throw new Error('Method not implemented: getDistributionTypesConfigurationv2');
  }
  public getNotificationTypesConfiguration(): never {
    throw new Error('Method not implemented: getNotificationTypesConfiguration');
  }
  public getRelatedValuesConfiguration(): never {
    throw new Error('Method not implemented: getRelatedValuesConfiguration');
  }
  public getErrorCodesConfiguration(): never {
    throw new Error('Method not implemented: getErrorCodesConfiguration');
  }
  public getMeteringTypeConfiguration(): never {
    throw new Error('Method not implemented: getMeteringTypeConfiguration');
  }
  public getReportingObjectSetReportingPropertyConfiguration(): never {
    throw new Error('Method not implemented: getReportingObjectSetReportingPropertyConfiguration');
  }
  public getTranslations(): never {
    throw new Error('Method not implemented: getTranslations');
  }
  public consumptionsGroupedResponse(): never {
    throw new Error('Method not implemented: consumptionsGroupedResponse');
  }
  public postTopConsumptions(): never {
    throw new Error('Method not implemented: postTopConsumptions');
  }
  public postMeterConsumptions(): never {
    throw new Error('Method not implemented: postMeterConsumptions');
  }
  public postFacilityConsumptions(): never {
    throw new Error('Method not implemented: postFacilityConsumptions');
  }
  public postQuantitys(): never {
    throw new Error('Method not implemented: postQuantitys');
  }
  public postEmissionsForTimerange(): never {
    throw new Error('Method not implemented: postEmissionsForTimerange');
  }
  public getEnergyTargetsForMetersAndYear(): never {
    throw new Error('Method not implemented: getEnergyTargetsForMetersAndYear');
  }
  public postEnergyTargetsForTimerange(): never {
    throw new Error('Method not implemented: postEnergyTargetsForTimerange');
  }
  public persistEnergyTargetsForMetersAndYear(): never {
    throw new Error('Method not implemented: persistEnergyTargetsForMetersAndYear');
  }
  public getReportingObjectConsumptionsExcel(): never {
    throw new Error('Method not implemented: getReportingObjectConsumptionsExcel');
  }
  public getReportingObjectMeterConsumptionsExcel(): never {
    throw new Error('Method not implemented: getReportingObjectMeterConsumptionsExcel');
  }
  public getMeters(): never {
    throw new Error('Method not implemented: getMeters');
  }
  public getMeter(): never {
    throw new Error('Method not implemented: getMeter');
  }
  public getQuantityForReportObjectSetId(): never {
    throw new Error('Method not implemented: getQuantityForReportObjectSetId');
  }
  public getQuantity(): never {
    throw new Error('Method not implemented: getQuantity');
  }
  public getQuantitiesForReportingObject(): never {
    throw new Error('Method not implemented: getQuantitiesForReportingObject');
  }
  public getReportingObjectsInformation(): never {
    throw new Error('Method not implemented: getReportingObjectsInformation');
  }
  public getFacilityInformation(): never {
    throw new Error('Method not implemented: getFacilityInformation');
  }
  public getFacilityContactsInformation(): never {
    throw new Error('Method not implemented: getFacilityContactsInformation');
  }
  public getFacilityMetersCount(): never {
    throw new Error('Method not implemented: getFacilityMetersCount');
  }
  public getReportingObjectInformation(): never {
    throw new Error('Method not implemented: getReportingObjectInformation');
  }
  public getMeterTree(): never {
    throw new Error('Method not implemented: getMeterTree');
  }
  public getReportingObjectSetContainsReportingObject(): never {
    throw new Error('Method not implemented: getReportingObjectSetContainsReportingObject');
  }
  public getConsumptionWindowFile(): never {
    throw new Error('Method not implemented.');
  }
  public getMeterTreeForMultipleFacilities(): never {
    throw new Error('Method not implemented.');
  }
}
