import { Pipe, PipeTransform } from '@angular/core';
import { NumberPipe } from '@progress/kendo-angular-intl';

import { Co2eq } from '@enerkey/clients/sustainability';

import { getCo2EqName } from './gri-co2factor.pipe';

@Pipe({
  name: 'griCo2total'
})
export class GriCo2totalPipe implements PipeTransform {

  public constructor(
    private readonly numberPipe: NumberPipe
  ) { }

  public transform(co2total: number, targetCo2eq: Co2eq, format?: string): string {
    if (!Number.isFinite(co2total)) {
      return '';
    }
    const unitText = getCo2EqName(targetCo2eq);

    if (co2total === 0) {
      return `0 ${unitText}`;
    }

    if (format) {
      const match = format.match(/\.0+$/);
      if (match && co2total < Number(`${match[0]}5`)) {
        return `> 0 ${unitText}`;
      }
    }
    return `${this.numberPipe.transform(co2total, format)} ${unitText}`;
  }

}
