import _ from 'lodash';

import TimeFrame from '../../../services/time-frame-service';
import { isInTimeFrame } from '../../../shared/date.functions';
import { roundByDecimals } from '@enerkey/ts-utils';
import * as Configs from '../constants/configs';
import { CONSUMPTION_TYPE } from '../constants/consumption-types';
import DefectIssue from './defect-issue';
import Fault from './fault';
import { Reading } from './reading';

export const INFO_STRING_SEPARATOR = ', ';

/**
 * Given a known total consumption, a data set with some null readings and a timeframe,
 * returns values where null readings are substituted with an average consumption such that
 * the sum of all values within the timeframe equals the known consumption.
 *
 * @param {Number} consumption
 * @param {Array} data An array of Reading objects
 * @param {TimeFrame} timeFrame
 *
 * @returns {Object}
 */
export const getAmendedConsumption = (consumption, data, timeFrame) => {
  if (!data || !timeFrame) {
    return {};
  }

  const readings = getReadingsWithinTimeFrame(data, timeFrame);
  const hourlyReadings = getHourlyReadings(readings);
  const { count, existing } = hourlyReadings.reduce(
    (result, reading) => {
      const nullReading = reading.actualConsumption === null;
      return {
        count: result.count + (nullReading ? 1 : 0),
        existing: result.existing + (nullReading ? 0 : reading.actualConsumption)
      };
    },
    { count: 0, existing: 0 }
  );

  const remaining = consumption - existing;
  const average = remaining / count;

  return hourlyReadings.reduce((values, reading) => {
    const row = reading.rowIndex;
    const nullReading = reading.actualConsumption === null;
    return {
      ...values,
      [row]: {
        row: row,
        value: nullReading ? average : null
      }
    };
  }, {});
};

/**
 * Returns average consumption from comparison period where
 * readings are inside of given time frame.
 *
 * @param {Array} readings
 * @param {TimeFrame} timeFrame
 *
 * @returns {Number}
 */
export const getAverageConsumption = (readings, timeFrame) => {
  const { total, count } = readings.reduce(
    (result, reading) => {
      const isInOperationTimeFrame = isInTimeFrame(timeFrame, reading.timestamp);
      const newResult = {
        total: result.total + (reading.comparisonConsumption || 0),
        count: result.count + 1
      };
      return isInOperationTimeFrame ? newResult : result;
    },
    { total: 0, count: 0 }
  );

  return count > 0 ? total / count : 0;
};

/**
 * Returns total consumption from comparison period where
 * readings are inside of given time frame.
 *
 * @param {Array} readings
 * @param {TimeFrame} timeFrame
 *
 * @returns {Number}
 */
export const getTotalConsumption = (readings, timeFrame) =>
  readings.reduce((result, reading) => {
    const isInOperationTimeFrame = isInTimeFrame(timeFrame, reading.timestamp);
    return isInOperationTimeFrame ? result + (reading.comparisonConsumption || 0) : result;
  }, 0);

/**
 * Returns modelled consumption values for given controller instance's timeframe based on
 * given consumption and consumption type.
 *
 * @param {Number} consumption
 * @param {String} consumptionType
 * @param {Object} controllerInstance
 *
 * @returns {Object}
 */
export const getModelledConsumption = (consumption, consumptionType, controllerInstance) => {
  const { dataSource, timeFrame } = controllerInstance;
  const data = dataSource.data();
  const readings = getReadingsWithinTimeFrame(data, timeFrame);
  const hourlyReadings = getHourlyReadings(readings);
  const readingCount = hourlyReadings.length;
  let modeledConsumption = consumption || 0;

  if (consumptionType === CONSUMPTION_TYPE.TOTAL) {
    if (readingCount > 0) {
      modeledConsumption /= readingCount;
    } else {
      return;
    }
  }

  return hourlyReadings.reduce((values, reading) => {
    const row = reading.rowIndex;
    return { ...values, [row]: { row: row, value: modeledConsumption } };
  }, {});
};

/**
 * Returns all cumulative values from given readings array
 *
 * @param {Array} readings
 *
 * @returns {Array}
 */
export const getCumulativeValues = readings => readings.map(reading => getCumulative(reading));

/**
 * Finds previous cumulative value starting from given index
 *
 * @param {Array}  readings
 * @param {Number} index
 *
 * @return {Number}
 */
export const findPreviousCumulative = (readings, index) => {
  try {
    const reading = findPreviousHourlyReading(readings, index - 1);
    const values = getCumulativeValues(readings.slice(reading.rowIndex, index));

    return values.length > 0 ? _.max(values) : 0;
  } catch (exception) {
    return 0;
  }
};

/**
 * Finds next cumulative value starting from given index
 *
 * @param {Array}  readings
 * @param {Number} index
 *
 * @return {Number}
 */
export const findNextCumulative = (readings, index) => {
  try {
    const reading = findNextHourlyReading(readings, index + 1);
    const nextReading = findNextHourlyReading(readings, reading.rowIndex + 1);
    const values = getCumulativeValues(readings.slice(reading.rowIndex, nextReading.rowIndex));

    return values.length > 0 ? _.max(values) : 0;
  } catch (exception) {
    return 0;
  }
};

/**
 * Returns previous hourly reading
 *
 * @param {Array}  readings
 * @param {Number} index
 *
 * @returns {any|null}
 */
export function findPreviousHourlyReading(readings, index) {
  if (index < 0) {
    return null;
  }

  for (let i = index; i >= 0; i--) {
    if (readings[i] && readings[i].isHourly) {
      return readings[i];
    }
  }
}

/**
 * Returns next hourly reading
 *
 * @param {Array}  readings
 * @param {Number} index
 *
 * @returns {any|null}
 */
export function findNextHourlyReading(readings, index) {
  if (index > readings.length - 1) {
    return null;
  }

  for (let i = index; i < readings.length; i++) {
    if (readings[i] && readings[i].isHourly) {
      return readings[i];
    }
  }
}

/**
 * Constructs array of numbers to range array by removing
 * subsequent values from list and leaving start and end values.
 *
 * Where each item contains start and end row:
 *  [
 *   [1, 2],
 *   [4, 10]
 *   [16, 20]
 *  ]
 *
 * @param {Array} numbers
 *
 * @returns {Array}
 */
export const toRangeArrays = numbers => {
  if (numbers.length === 0) {
    return [];
  }

  const numbersTmp = angular.copy(numbers);
  const firstNumber = numbersTmp.shift();
  const result = [[firstNumber]];

  let lastIndex = firstNumber;

  numbersTmp.forEach(rowIndex => {
    if (lastIndex + 1 !== rowIndex) {
      result[result.length - 1].push(lastIndex);
      result.push([rowIndex]);
    }

    lastIndex = rowIndex;
  });

  result[result.length - 1].push(lastIndex);

  return result;
};

/**
 * Returns a single time frame object for first readings range which contains
 * readings that have actual consumption.
 *
 * @param {Array} readings
 *
 * @returns {Object}
 */
export const findEmptyHourlyReadingsTimeFrame = readings => {
  const ranges = toRangeArrays(getHourlyRowIndexesWithNullConsumption(readings));

  if (ranges.length <= 0) {
    throw new Error('MQA.ERRORS.INSPECT.MISSING_READINGS_NOT_FOUND');
  }

  const range = ranges[0];

  const firstReading = findPreviousHourlyReading(readings, range[0]);
  let lastReading = findNextHourlyReading(readings, range[1]);

  if (hasNonHourlyReadings(readings, lastReading)) {
    lastReading = readings[lastReading.rowIndex - 1];
  }

  return new TimeFrame(firstReading.timestamp, lastReading.timestamp);
};

/**
 * Checks if given reading has any non-hourly readings.
 *
 * @param {Array}  readings
 * @param {Object} reading
 *
 * @returns {Boolean}
 */
export function hasNonHourlyReadings(readings, reading) {
  if (!reading.isHourly) {
    // Given reading is not hourly so it indeed has non hourly readings.
    return true;
  }

  const nextHourlyReading = findNextHourlyReading(readings, reading.rowIndex + 1);

  // There isn't any subsequent hourly readings
  if (!nextHourlyReading) {
    // Checks if there is still non-hourly readings (reading is not the last reading in array)
    return reading.rowIndex !== readings.length - 1;
  }

  /*
   * Check if subsequent hourly reading doesn't have next row index. That
   * means it has one or more non-hourly readings between them.
   */
  return reading.rowIndex + 1 !== nextHourlyReading.rowIndex;
}

/**
 * Returns hourly reading row indexes with null consumption in array
 *
 * @param {Array} readings
 *
 * @returns {Array}
 */
export function getHourlyRowIndexesWithNullConsumption(readings) {
  return readings.reduce(
    (result, reading) =>
      reading.isHourly && reading.actualConsumption === null ? result.concat(reading.rowIndex) : result,
    []
  );
}

/**
 * Returns readings that are inside of given time frame
 *
 * @param {Array}  readings
 * @param {TimeFrame} timeFrame
 *
 * @returns {Array}
 */
export function getReadingsWithinTimeFrame(readings, timeFrame) {
  return readings.filter(reading => isInTimeFrame(timeFrame, reading.timestamp));
}

/**
 * Returns all hourly readings from readings array
 *
 * @param {Array} readings
 *
 * @returns {Array}
 */
export function getHourlyReadings(readings) {
  return readings.filter(reading => reading.isHourly);
}

/**
 * Returns consumption value
 *
 * @param {Object} reading
 *
 * @returns {Number}
 */
export const getConsumption = reading =>
  reading.isModelled ? reading.modelConsumption || 0 : reading.actualConsumption || 0;

/**
 * Returns cumulative value
 *
 * @param {Object} reading
 *
 * @returns {Number}
 */
export function getCumulative(reading) {
  return reading.isModelled ? reading.modelCumulative || 0 : reading.actualCumulative || 0;
}

/**
 * Default method to round for manual qa
 *
 * @param {Number} number
 *
 * @returns {Number}
 */
export const round = number => roundByDecimals(number, Configs.NUMBER_OF_DIGITS);

/**
 * Alternative method to round for manual qa
 *
 * @param {Number} number
 *
 * @returns {Number}
 */
export const roundCoarse = number => roundByDecimals(number, Configs.COARSE_NUMBER_OF_DIGITS);

/**
 * Returns all defects that fully intersects with given time frames.
 *
 * @param {TimeFrame[]} timeFrames
 * @param {DefectIssue[]} defects
 */
export const getIntersectingDefects = (timeFrames, defects) =>
  defects.filter(defect => timeFrames.some(timeFrame => timeFrame.contains(defect.getTimeFrame())));

/**
 * Returns all readings that are dirty and hourly
 *
 * @param {Array} readings
 *
 * @returns {Array}
 */
export const getDirtyAndHourlyReadings = readings => readings.filter(
  reading => Reading.isReadingDirtyAndHourly(reading)
);

/**
 * Combines defect issues and faults
 *
 * @param {DefectIssue[]} defectIssues
 * @param {Fault[]} faults
 *
 * @returns {DefectIssue[]}
 */
export const combineDefectIssuesAndFaults = (defectIssues, faults) => {
  const groupedFaults = faults.reduce((result, faultData) => {
    const fault = new Fault(faultData);

    if (!result[fault.getDefectIssueId()]) {
      result[fault.getDefectIssueId()] = [];
    }

    result[fault.getDefectIssueId()].push(fault);

    return result;
  }, {});

  return defectIssues.map(defectIssue => new DefectIssue({ ...defectIssue, faults: groupedFaults[defectIssue.id] }));
};

/**
 * Removes array of defects from given defects array
 *
 * @param {DefectIssue[]} defects
 * @param {DefectIssue[]} defectsToBeRemoved
 *
 * @returns {DefectIssue[]}
 */
export const removeDefectsFromArray = (defects, defectsToBeRemoved) => {
  const idsToDelete = defectsToBeRemoved.map(defect => defect.getId());
  return defects.filter(defect => idsToDelete.indexOf(defect.getId()) < 0);
};

/**
 * Returns time frame that consists of earliest and latest dates from defect array
 *
 * @param {DefectIssue[]} defects
 *
 * @returns {TimeFrame}
 */
export const getMinMaxDatesFromDefectsList = defects => {
  const checkTimeFrameDates = (result, timeFrame) => ({
    min: timeFrame.getFromDate() < result.min ? timeFrame.getFromDate() : result.min,
    max: timeFrame.getToDate() > result.max ? timeFrame.getToDate() : result.max
  });

  const timeFrames = defects.map(defect => defect.getTimeFrame());
  const minMax = timeFrames.reduce(checkTimeFrameDates, { min: Infinity, max: -Infinity });

  return new TimeFrame(minMax.min, minMax.max);
};

export const getInfoStringFromArray = items => items.filter(item => item.length > 0).join(INFO_STRING_SEPARATOR);

export const getTimeFrameQueryParameters = timeFrame => ({
  from: timeFrame.getFromDate().toISOString(),
  to: timeFrame.getToDate().toISOString()
});
