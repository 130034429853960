import { Pipe, PipeTransform } from '@angular/core';

import { Scope } from '@enerkey/clients/sustainability';

import { ColorService } from '../../../shared/services/color.service';

@Pipe({ name: 'griScopeColor' })
export class GriScopeColorPipe implements PipeTransform {

  private readonly scopeColors: Record<Scope, string>;

  public constructor(private readonly colorService: ColorService) {
    this.scopeColors = {
      [Scope.Scope1]: this.colorService.getCssProperty('--enerkey-gri-Scope1'),
      [Scope.Scope2]: this.colorService.getCssProperty('--enerkey-gri-Scope2'),
      [Scope.Scope3]: this.colorService.getCssProperty('--enerkey-gri-Scope3')
    };
  }

  public transform(value: Scope): string {
    if (!(value in this.scopeColors)) {
      return '#fff';
    }
    return this.scopeColors[value];
  }

}
