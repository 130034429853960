import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Validators } from '@angular/forms';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { NoReplyMessage } from '@enerkey/clients/user-management';
import { formGroupFrom, guid } from '@enerkey/ts-utils';

import { UserService } from '../../services/user-service';
import { PackageVersion } from '../package-version';
import { WINDOW } from '@enerkey/angular-utils';
import { ToasterService } from '../services/toaster.service';
import { EmailSendingService } from '../services/email-sending.service';

import { TenantInfoService } from '../services/tenant-info.service';
import { AuthenticationService } from '../services/authentication.service';

interface FeedbackForm {
  feedbackText: string;
  feedbackSubject: string;
  feedbackPhoneNumber: string;
  feedbackEmailAddress: string;
}

@Component({
  templateUrl: './feedback-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackModalComponent extends ModalBase<void> {
  public feedbackContent = formGroupFrom<FeedbackForm>(
    {
      feedbackText: '',
      feedbackSubject: '',
      feedbackPhoneNumber: '',
      feedbackEmailAddress: ''
    },
    {
      feedbackText: Validators.required,
      feedbackSubject: Validators.required
    }
  );

  public constructor(
    private readonly userService: UserService,
    @Inject(WINDOW) private readonly windowObject: Window,
    private readonly toasterService: ToasterService,
    private readonly stateService: StateService,
    private readonly emailSendingService: EmailSendingService,
    private readonly tenantInfoService: TenantInfoService,
    private readonly authenticationService: AuthenticationService,
    ngfActiveModal: NgfActiveModal
  ) {
    super(ngfActiveModal);
  }

  public sendFeedback(): void {
    this.tenantInfoService.getFeedbackEmail().subscribe(feedbackEmail => {
      const bundleVersion = PackageVersion.getVersion();
      const currentProfileName = this.userService.getCurrentProfile()?.name ?? '';
      const userName = this.authenticationService.getUsername();
      const currentState = this.stateService.current.name;
      let emailBody = this.formValue.feedbackText.replace(/(?:\r\n|\r|\n)/g, '<br/>');
      emailBody += `<br/><br/>Profile: ${currentProfileName},  User name: ${userName}`;
      emailBody += `,  Email: ${this.formValue.feedbackEmailAddress}`;
      emailBody += `,  Phone number: ${this.formValue.feedbackPhoneNumber}`;
      emailBody += `<br/><br/>Version: ${bundleVersion},  Location: ${currentState}`;
      emailBody += `<br/>User agent: ${this.windowObject.navigator.userAgent}`;
      const emailSubject = `${this.formValue.feedbackSubject}  (#F${guid(true)})`;

      const emailData = new NoReplyMessage({
        to: feedbackEmail,
        subject: emailSubject,
        body: emailBody,
        replyTo: userName
      });

      this.emailSendingService.sendEmail(emailData).then(() => {
        this.toasterService.success(null, 'TOPBAR.FEEDBACK_EMAIL_SENT');
        super.closeModal();
      });
    });
  }

  private get formValue(): FeedbackForm {
    return this.feedbackContent.value;
  }
}
