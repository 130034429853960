import { ChangeDetectionStrategy, Component, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators
} from '@angular/forms';

import { tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { formControlsFrom } from '@enerkey/ts-utils';

import { EtCurveBase } from '../../../../analytics/models/et-curve-base.model';
import { ETCurveSearchParams } from '../../../../analytics/models/et-curve.model';
import { EtCurveService } from '../../../../analytics/services/et-curve/et-curve.service';

type ChangeFn = (options: FacilityEtCurveWidgetOptions) => void;

export interface FacilityEtCurveWidgetOptions {
  facilityId: number,
  quantity: number
}

@Component({
  selector: 'facility-et-curve-widget-options',
  templateUrl: './facility-et-curve-widget-options.component.html',
  styleUrls: ['./facility-et-curve-widget-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FacilityEtCurveWidgetOptionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => FacilityEtCurveWidgetOptionsComponent),
    }
  ]
})
export class FacilityEtCurveWidgetOptionsComponent extends EtCurveBase<ETCurveSearchParams>
  implements ControlValueAccessor, Validator, OnInit, OnDestroy {

  @Input() public initialState: FacilityEtCurveWidgetOptions;

  private _onChange: ChangeFn;

  public constructor(
    injector: Injector,
    etCurveService: EtCurveService
  ) {
    super(injector, formControlsFrom<ETCurveSearchParams>(
      etCurveService.defaultFormState(),
      {
        facilityId: Validators.required,
        quantity: Validators.required
      }
    ));
  }

  public ngOnInit(): void {
    this.formGroup.patchValue({ ...this.initialState });
    this.disableControls();

    this.formGroup.valueChanges.pipe(
      takeUntil(this._destroy),
      tap((formValue: FacilityEtCurveWidgetOptions) => this._onChange?.(formValue))
    ).subscribe();

    // Workaround for kendo-timepicker inputs not being disabled on init
    this.controls.calculationOpsHours.valueChanges.pipe(
      tap(_ => {
        this.controls.calculationTimeFrom.disable();
        this.controls.calculationTimeTo.disable();
      }),
      takeUntil(this._destroy)
    ).subscribe();
  }

  public writeValue(value: FacilityEtCurveWidgetOptions): void {
    this.formGroup.patchValue({ ...value });
  }

  public registerOnChange(fn: ChangeFn): void { this._onChange = fn; }
  public registerOnTouched(): void {}

  public validate(_: AbstractControl): ValidationErrors | null {
    return this.formGroup.invalid ? { required: true } : null;
  }

  public ngOnDestroy(): void {
    super.onDestroy();
  }

  private disableControls(): void {
    (Object.keys(this.controls) as Array<keyof ETCurveSearchParams>)
      .filter(k => !['facilityId', 'quantity'].includes(k))
      .forEach((controlKey: keyof ETCurveSearchParams) => {
        this.controls[controlKey].disable();
      });
  }

}
