import ManualQaMeterNameQuantityComponent from './manual-qa-meter-name-quantity.component';
import QuantityUnitModule                 from '../../../../components/quantity-unit';

const dependencies = [QuantityUnitModule.name];

const ManualQaMeterNameQuantityModule = angular
  .module('app.components.modules.manual-qa.components.manual-qa-meter-name-quantity', dependencies)
  .component('manualQaMeterNameQuantity', ManualQaMeterNameQuantityComponent);

export default ManualQaMeterNameQuantityModule;
