import _ from 'lodash';

export function getTargetCompareField(data, type) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.startInfo.index +
    (data.normalized ? '$Normalized' : '$') + 'Reading$Value' + type;
}

export function getCompareField(data, type, relationalValueId) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.previousStartInfo.index +
    '$' + data.startInfo.index +
    (relationalValueId ? `$RelationalValues$${relationalValueId}` : '') +
    (data.normalized ? '$Normalized' : '$') + 'Reading$Value' + type;
}

export function getTrendCompareField(options, data, type) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.startInfo.index +
    (data.normalized ? '$Normalized' : '$') + 'Reading$Value' + type;
}

export function getDistributionField(data, type, startInfo) {
  startInfo = startInfo || data.startInfo;
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + startInfo.index +
    '$DistributionValues$' +
    _.get(data, 'propertyData.object.ValueProperty') + '$' + type;
}

export function getDistributionCompareField(data, type) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.previousStartInfo.index +
    '$' + data.startInfo.index +
    '$DistributionValues$' +
    _.get(data, 'propertyData.object.ValueProperty') + '$' + type;
}

export function getDistributionTrendCompareField(data, type) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.startInfo.index +
    '$DistributionValues$' +
    _.get(data, 'propertyData.object.ValueProperty') + '$' + type;
}

export function getFlagField(field, index) {
  let result;
  const splitted = field.split('$');
  if (splitted.length) {
    splitted[splitted.length - 1] = 'Flags';
    result = splitted.join('$');
    result += '$' + (index || 0);
  }
  return result;
}

export function getQuantityCategoryField(data) {
  return data.quantity.Key + (data.normalized ? '_N' : '');
}

export function getWeatherCategoryField() {
  return 'Weather';
}

export function getRelationalValueField(data) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.startInfo.index +
    '$RelationalValues$' +
    _.get(data, 'propertyData.object.Id') +
    (data.normalized ? '$Normalized' : '$') + 'Reading$Value';
}

export function getRelatedValueField(data) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.startInfo.index +
    '$RelatedValues$' +
    _.get(data, 'propertyData.object.Id') + '$Value';
}

export function getTemperatureField(data) {
  return (data.isAggregatedQuantityGrid ? '' : '$' + data.quantityId) +
    '$' + data.apiIndex +
    '$' + data.startInfo.index +
    '$Value';
}
