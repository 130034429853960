import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ProcessStatus } from '@enerkey/clients/provisioning';

const prefix = 'PROVISIONING.PROCESS_STATUS.';

export const processStatusTranslationKeys: Record<ProcessStatus, string> = {
  [ProcessStatus.Running]: `${prefix}RUNNING`,
  [ProcessStatus.Success]: `${prefix}SUCCESS`,
  [ProcessStatus.PartialSuccess]: `${prefix}PARTIAL_SUCCESS`,
  [ProcessStatus.Failed]: `${prefix}FAILED`,
  [ProcessStatus.Unknown]: `${prefix}UNKNOWN`
};

@Pipe({
  name: 'processStatusText'
})
export class ProcessStatusTextPipe implements PipeTransform {
  public constructor(
    private readonly translateService: TranslateService
  ) { }
  public transform(status: ProcessStatus): string {
    return this.translateService.instant(processStatusTranslationKeys[status] ?? `${prefix}UNKNOWN`);
  }
}
