import {
  ComponentRef,
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { OnPropertyChange, OnPropertyChanged } from '@enerkey/angular-utils';

import { LabelDirectiveComponent } from '../components/label-directive.component';

export type LabelPosition = 'before' | 'wrap';

// How extra parameters are implemented:
// https://stackoverflow.com/a/41791130

@Directive()
export abstract class LabelBaseDirective implements OnInit, OnPropertyChanged {

  @OnPropertyChange() public abstract labelText: string;
  @OnPropertyChange() public abstract labelClass: string;
  @OnPropertyChange() public abstract clickTarget: string;
  @OnPropertyChange() public abstract labelTooltip: string;
  @OnPropertyChange() public abstract labelRequired: boolean;

  protected abstract readonly labelPosition: LabelPosition;

  private componentRef: ComponentRef<LabelDirectiveComponent>;

  protected constructor(
    private readonly template: TemplateRef<unknown>,
    private readonly viewContainer: ViewContainerRef
  ) { }

  public ngOnInit(): void {
    this.componentRef = this.viewContainer.createComponent(LabelDirectiveComponent);

    this.componentRef.instance.position = this.labelPosition;
    this.componentRef.instance.template = this.template;

    this.updateBindings();
  }

  public onPropertyChanged(): void {
    this.updateBindings();
  }

  private updateBindings(): void {
    this.componentRef?.instance.setProps({
      labelText: this.labelText,
      labelClass: this.labelClass,
      target: this.clickTarget,
      tooltip: this.labelTooltip,
      required: this.labelRequired,
    });
  }
}
