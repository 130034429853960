import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { formGroupFrom } from '@enerkey/ts-utils';

import { UserService } from '../../../../services/user-service';
import { FacilityManagementService } from '../../services/facility-management.service';

interface FacilitySearchExtraOptions {
  getMeterCount: boolean;
  getContactInformation: boolean;
}

interface FacilityManagementSearchFormValue {
  profileIds: number[];
  profilePickerIds: number[];
  facilityId: number[];
  facilityName: string;
  enegiaId: number[];
  companyId: number;
  realestateId: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
}

@Component({
  selector: 'facility-management-search',
  templateUrl: './facility-management-search.component.html',
  styleUrls: ['./facility-management-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityManagementSearchComponent {

  public readonly loading$: Observable<boolean>;

  public readonly searchForm: UntypedFormGroup;

  public readonly extraOptionsForm = formGroupFrom<FacilitySearchExtraOptions>({
    getMeterCount: false,
    getContactInformation: false
  });

  public constructor(
    private readonly facilityManagementService: FacilityManagementService,
    private readonly userService: UserService
  ) {
    this.loading$ = this.facilityManagementService.loading$;
    this.searchForm = formGroupFrom<FacilityManagementSearchFormValue>({
      profileIds: null,
      profilePickerIds: null,
      facilityId: null,
      facilityName: '',
      enegiaId: null,
      companyId: null,
      realestateId: '',
      address: '',
      postalCode: '',
      city: '',
      country: '',
    });
  }

  public search(): void {
    this.facilityManagementService.search({
      ...this.searchFormValue,
      profileIds: Array.hasItems(this.searchFormValue.profileIds)
        ? this.searchFormValue.profileIds
        : this.searchFormValue.profilePickerIds,
      realestateId: this.searchFormValue.realestateId
        ?.split(',')
        .mapFilter(
          v => v.trim(),
          v => !!v
        ),
      ...this.extraOptionsFormValue
    });
  }

  public getCurrentProfile(): void {
    this.facilityManagementService.search({
      profileIds: [this.userService.profileId],
      ...this.extraOptionsFormValue
    });
  }

  public getWithoutProfile(): void {
    this.facilityManagementService.search({
      getWithoutProfile: true,
      ...this.extraOptionsFormValue
    });
  }

  private get searchFormValue(): FacilityManagementSearchFormValue {
    return this.searchForm.value;
  }

  private get extraOptionsFormValue(): FacilitySearchExtraOptions {
    return this.extraOptionsForm.value;
  }
}
