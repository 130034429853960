const $inject = [
  '$modalInstance', 'utils'
];

class FacilityCreateModalController {
  constructor($modalInstance, utils) {
    this.$modalInstance = $modalInstance;
    this.title = utils.localizedString('ADMIN.CREATE_FACILITIES');
    this.spreadsheetReference = null;
  }

  /**
   * Handles modal dismiss
   */
  dismiss() {
    this.$modalInstance.dismiss();
  }

  /**
   * Handles modal close
   */
  close(enegiaIds) {
    this.$modalInstance.close(enegiaIds);
  }

  /**
   * Set spreadsheet
   */
  setSpreadsheet(spreadsheet) {
    this.spreadsheetReference = spreadsheet;
  }

  /**
   * Downloads excel
   */
  onExcelDownLoad() {
    this.spreadsheetReference.saveAsExcel();
  }
}

FacilityCreateModalController.$inject = $inject;

export default FacilityCreateModalController;
