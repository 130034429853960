const $inject = ['$modalInstance', 'facilityName', 'meterId', 'meteringPointName'];

function MeterInterfaceStatusModalController($modalInstance, facilityName, meterId, meteringPointName) {
  let vm = this;

  vm.meterId = meterId;
  vm.headerText = facilityName + ', ' + meteringPointName;
  vm.dismiss = dismiss;

  /**
   * Handles modal dismiss
   */
  function dismiss() {
    $modalInstance.dismiss();
  }
}

MeterInterfaceStatusModalController.$inject = $inject;

export default MeterInterfaceStatusModalController;
