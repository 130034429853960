<div class="header-group">
  <div class="content-left">
    <input
      id="showOnlyErrorStatusesCheckBox"
      type="checkbox"
      kendoCheckBox
      [ngModel]="showOnlyErrorStatuses"
      (click)="onShowOnlyErrorStatusesChanged()"
    />
    <label for="showOnlyErrorStatusesCheckBox">
      {{ 'CONFIGURATION_CONTROL.TERMINAL.SHOW_ONLY_FAULTY_TERMINALS' | translate }}
    </label>
  </div>
  <div class="content-right button-group">
    <button 
      class="button" [disabled]="!isUserAdministrator || !selection?.length"
      (click)="onSetInspector()"
    >
      {{ 'CONFIGURATION_CONTROL.TERMINAL.SET_INSPECTOR' | translate }}
    </button>
    <button 
      class="button" [disabled]="!isUserAdministrator || !selection?.length"
      (click)="onResolveSelected()"
    >
      {{ 'CONFIGURATION_CONTROL.TERMINAL.RESOLVE_ERROS' | translate }}
    </button>
    <button class="button" (click)="onRefreshGrid()"><i class="fas fa-redo"></i></button>
  </div>
</div>

<ng-template #template let-anchor>
  <span>{{ anchor.nativeElement.innerText }}</span>
</ng-template>

<div kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td" (mouseover)="showTooltip($event)">

<kendo-grid
  [kendoGridBinding]="terminals$ | async"
  [pageSize]="pageSize"
  [pageable]="true"
  [filterable]="'menu'"
  [sortable]="true"
  [groupable]="true"
  [resizable]="true"
  [height]="800"
  [selectable]="selectSettings"
  [kendoGridSelectBy]="selectKey"
  [selectedKeys]="selection"
>
  <kendo-grid-checkbox-column
    *ngIf="showOnlyErrorStatuses"
    [showSelectAll]="true"
    [width]="20"
  ></kendo-grid-checkbox-column>

  <kendo-grid-column
    *ngIf="!showOnlyErrorStatuses"
    [width]="20"
  ></kendo-grid-column>

  <kendo-grid-column
    field="terminalIdentifier"
    title="{{ 'ADMIN.TERMINAL.IDENTIFIER' | translate }}"
    [width]="40"
  >
    <ng-template
      kendoGridFilterMenuTemplate
      let-column="column"
      let-filter="filter"
      let-filterService="filterService"
    >
      <kendo-grid-string-filter-cell
        [column]="column"
        [filter]="filter"
        operator="startswith"
      >
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="terminalName"
    title="{{ 'ADMIN.TERMINAL.NAME' | translate }}"
    [width]="60"
  >
    <ng-template
      kendoGridFilterMenuTemplate
      let-column="column"
      let-filter="filter"
      let-filterService="filterService"
    >
      <kendo-grid-string-filter-cell
        [column]="column"
        [filter]="filter"
      >
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="companyNumbers"
    title="{{ 'CONFIGURATION_CONTROL.TERMINAL.COMPANY_NUMBERS' | translate }}"
    [width]="60"
    kendoTooltip
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="enegiaIds"
    title="{{ 'CONFIGURATION_CONTROL.TERMINAL.ENEGIA_IDS' | translate }}"
    [width]="60"
    filter="text"
    kendoTooltip
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="facilityNames"
    title="{{ 'CONFIGURATION_CONTROL.TERMINAL.FACILITY_NAMES' | translate }}"
    [width]="80"
    filter="text"
    kendoTooltip
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="terminalDescription"
    title="{{ 'ADMIN.TERMINAL.DESCRIPTION' | translate }}"
    [width]="80"
    filter="text"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="readingStatusTextLocalized"
    title="{{ 'CONFIGURATION_CONTROL.TERMINAL.STATUS' | translate }}"
    [width]="50"
    filter="text"
  >
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <span class="{{ dataItem.readingStatusTextIndicator }}">
        {{ dataItem.readingStatusTextLocalized }}
      </span>
    </ng-template>
    <ng-template
      kendoGridFilterMenuTemplate
      let-column="column"
      let-filter="filter"
      let-filterService="filterService"
    >
      <kendo-grid-multi-select-primitive-filter
        [filter]="filter"
        [field]="column.field"
        [filterService]="filterService"
        [data]="terminals$ | async"
      > 
      </kendo-grid-multi-select-primitive-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="terminalFaultDate"
    title="{{ 'CONFIGURATION_CONTROL.TERMINAL.FAULT_DATE' | translate }}"
    [width]="80"
    filter="date"
    format="g"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="terminalComment"
    title="{{ 'COMMENT' | translate }}"
    [width]="80"
    filter="text"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="terminalInspectorName"
    title="{{ 'CONFIGURATION_CONTROL.TERMINAL.INSPECTOR' | translate }}"
    [width]="80"
    filter="text"
  >
  </kendo-grid-column>

  <kendo-grid-column
    field="terminalStatusDescription"
    title="{{ 'CONFIGURATION_CONTROL.TERMINAL.INSPECTOR_COMMENT' | translate }}"
    [width]="80"
    filter="text"
  >
  </kendo-grid-column>

  <kendo-grid-command-column [width]="60">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button
        (click)="onShowTerminalStatus(dataItem)"
        kendoButton
        [iconClass]="'fa-1x fas fa-info-circle'"
        [size]="'small'"
        [primary]="true"
        [fillMode]="'flat'"
      >
      </button>
      <button
        (click)="onGoToBucket(dataItem)"
        kendoButton
        [primary]="true"
        [size]="'small'"
        [fillMode]="'flat'"
      >B</button>
      <button kendoGridRemoveCommand (click)="onRemoveTerminal(dataItem)"></button>
    </ng-template>
  </kendo-grid-command-column>

</kendo-grid>
