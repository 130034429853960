import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';

import { ReadingSourceType, SearchList } from '@enerkey/clients/manual-qa';

@Component({
  selector: 'manual-qa-search-wrapper',
  templateUrl: './manual-qa-search-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualQaSearchWrapperComponent {
  public constructor(
    @Optional() @Inject('searchList') public searchList: SearchList,
    @Inject('readerTypes') public readerTypes: ReadingSourceType[]
  ) {}
}
