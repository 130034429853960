import { extend } from '../extend';
import * as mapExt from './map-extensions';

// Mutating prototype functions
extend(Map.prototype, 'getOrAdd', mapExt.getOrAdd);

// Non-mutating prototype functions
extend(Map.prototype, 'getKeys', mapExt.getKeys);
extend(Map.prototype, 'getEntries', mapExt.getEntries);
extend(Map.prototype, 'toRecord', mapExt.toRecord);
