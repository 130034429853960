import EnerkeyServiceModule                         from '../../../../services';
import ManualQaSpreadsheetFilterTypeSelectComponent from './manual-qa-spreadsheet-filter-type-select-component';
import ManualQaSpreadsheetFilterTypeSelectService   from './manual-qa-spreadsheet-filter-type-select-service';

const dependencies = [EnerkeyServiceModule.name];

const ManualQaSpreadsheetFilterTypeSelectModule = angular
  .module('app.modules.manual-qa.components.manual-qa-spreadsheet-filter-type-select', dependencies)
  .component('manualQaSpreadsheetFilterTypeSelect', ManualQaSpreadsheetFilterTypeSelectComponent)
  .service('ManualQaSpreadsheetFilterTypeSelectService', ManualQaSpreadsheetFilterTypeSelectService)

;

export default ManualQaSpreadsheetFilterTypeSelectModule;
