import { ChangeDetectionStrategy, Component, Host, Optional, SkipSelf, TemplateRef } from '@angular/core';
import { ColumnBase, ColumnComponent } from '@progress/kendo-angular-grid';

/* eslint-disable @typescript-eslint/ban-ts-comment */

/**
 * Column that is created manually with component factories.
 */
@Component({ template: '', changeDetection: ChangeDetectionStrategy.OnPush })
export class KendoGridManualColumnComponent extends ColumnComponent {

  public override get templateRef(): TemplateRef<unknown> {
    return this._cellTemplate;
  }

  public override get filterMenuTemplateRef(): TemplateRef<unknown> {
    return this._filterMenuTemplate;
  }

  public override get groupHeaderTemplateRef(): TemplateRef<unknown> {
    return this._groupHeaderTemplate;
  }

  private _cellTemplate: TemplateRef<unknown>;
  private _filterMenuTemplate: TemplateRef<unknown>;
  private _groupHeaderTemplate: TemplateRef<unknown>;

  public constructor(@Optional() @SkipSelf() @Host() parent: ColumnBase) {
    super(parent);
  }

  public setCellTemplate(template: TemplateRef<unknown>): void {
    this._cellTemplate = template;
  }

  public setFilterMenuTemplate(template: TemplateRef<unknown>): void {
    this._filterMenuTemplate = template;
  }

  public setGroupHeaderTemplate(template: TemplateRef<unknown>): void {
    this._groupHeaderTemplate = template;
  }

}
