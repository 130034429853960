import { AppStatusService } from '../../../../services/app-status-service';
import { ReportNote } from '../../shared/report-note';
import { PeriodReportItem } from '../../interfaces/period-report-item';

export class PeriodReportActionsPopupController {

  public static readonly $inject = ['utils', 'appStatusService'];

  public actionsAllowed: boolean;
  public item: PeriodReportItem;
  public type: 'actions' | 'comments';

  public notes: ReportNote[];
  public toggleIconClassName: string;
  public title: string;
  public isMobile: boolean;

  public constructor(
    private readonly utils: any,
    private readonly appStatusService: AppStatusService
  ) { }

  public $onInit(): void {
    this.notes = this.item.notes[this.type];
    this.toggleIconClassName = this.getToggleIconClassName();
    this.title = this.getTitle();
    this.isMobile = this.appStatusService.isMobile;
  }

  private getToggleIconClassName(): string {
    if (this.type !== 'actions') {
      return 'fa fa-comment';
    }
    return this.notes.length > 0 ? 'icon-icon-notes-content' : 'icon-icon-notes-add';
  }

  private getTitle(): string {
    const meter = this.item.meter ? '_METER' : '';
    const popupType = this.type === 'actions' ? '_ACTIONS' : '_COMMENTS';
    const translationKey = `FACILITIES_REPORT.LATEST${meter}${popupType}_POPUP_TITLE`;
    return this.utils.localizedString(translationKey);
  }
}
