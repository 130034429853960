import { Injectable } from '@angular/core';

/**
 * A configuration service for the [`NgfTabset`](#/components/tabset/api#NgfTabset) component.
 *
 * You can inject this service, typically in your root component, and customize the values of its properties in
 * order to provide default values for all the tabsets used in the application.
 */
@Injectable({ providedIn: 'root' })
export class NgfTabsetConfig {
  public orientation: 'horizontal' | 'vertical' = 'horizontal';
}
