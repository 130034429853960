import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CategoryAxisLabels, LegendLabels, ValueAxisLabels } from '@progress/kendo-angular-charts';

import { Quantities } from '@enerkey/clients/metering';
import { KeysOfType } from '@enerkey/ts-utils';

import { YearReadings } from '../yearly-trend-report/yearly-trend-report.component';
import { RelationalValueId } from '../../../../modules/reportingobjects/constants/facilities-properties';

@Component({
  selector: 'yearly-trend-chart',
  templateUrl: './yearly-trend-chart.component.html',
  styleUrls: ['./yearly-trend-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearlyTrendChartComponent implements OnInit {
  @Input() public consumptions: YearReadings[];
  @Input() public blackColor: string;
  @Input() public quantityId: Quantities;

  public readonly Quantities = Quantities;
  public readonly RelationalValueId = RelationalValueId;

  public readonly font = '10px "Open Sans"';
  public readonly boldFont = 'bold 10px "Open Sans"';
  public valueAxisLabels: ValueAxisLabels;
  public categoryAxisLabels: CategoryAxisLabels;
  public specificConsumptionValueAxisLabels: CategoryAxisLabels;
  public legendLabels: LegendLabels;
  public readonly normalizedBarColor = '#7682a2';
  public readonly specificConsumptionColor = '#38b13d';

  public readonly readingField: KeysOfType<YearReadings, number> = 'reading';
  public readonly normalizedReadingField: KeysOfType<YearReadings, number> = 'normalizedReading';
  public readonly specificConsumptionField: KeysOfType<YearReadings, number> = 'specificConsumption';
  public readonly specificConsumptionAxis = 'specificConsumption';

  public readonly categoryField: keyof YearReadings = 'timestamp';

  public ngOnInit(): void {
    this.valueAxisLabels = {
      color: this.blackColor,
      font: this.font,
      step: 2,
      format: '#,#.#'
    };

    this.categoryAxisLabels = {
      color: this.blackColor,
      font: this.boldFont,
      margin: 0,
      padding: 0
    };

    this.specificConsumptionValueAxisLabels = {
      ...this.valueAxisLabels,
      color: this.specificConsumptionColor
    };

    this.legendLabels = {
      color: this.blackColor,
      font: this.font,
      margin: 0,
      padding: 0
    };
  }
}
