import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';

@Pipe({
  name: 'excelFileName'
})
export class ExcelFileNamePipe implements PipeTransform {
  public transform(prefix: string, date?: Date): string {
    const suffix = date
      ? ` ${format(date, 'yyyy-MM-dd_HH-mm-ss')}`
      : ''
    ;

    return `${prefix}${suffix}.xlsx`;
  }
}
