export const GRID_COLUMNS = {
  FROM: 'from',
  TO: 'to',
  DURATION: 'duration',
  FAULT_TYPES: 'defectTypeIdsString',
  EXCEEDS_INSPECTED_RANGE: 'exceedsInspectedRange',
  DEFECT_STATUS: 'defectStatus',
  ENEGIA_ID: 'enegiaId',
  FACILITY_NAME: 'facilityName',
  METERING_POINT_NAME: 'meteringPointName',
  METER_ID: 'meterId',
  QUANTITY_NAME: 'quantityName',
  READER_TYPE: 'readerTypeName',
  SELECTED: 'selected',
  DEFECT_TYPES: 'defectTypeIdsString',
  USAGEPLACE_NUMBER: 'usageplaceNumber',
  SELLER_USAGEPLACE_NUMBER: 'sellerUsageplaceNumber',
  EAN_CODE: 'eanCode',
  PROTOCOL_CODE: 'protocolCode',
  AUTOMATIC_READING_START_TIME: 'automaticReadingStartTime',
  AUTOMATIC_READING_END_TIME: 'automaticReadingEndTime',
  METER_STORAGE_START_TIME: 'meterStorageStartTime',
  DEACTIVATION_TIME: 'deactivationTime',
  METER_IDENTIFIER: 'meterIdentifier',
  READING_GROUP_IDENTIFIER: 'readingGroupIdentifier',
  BUSINESS_IDENTITY_CODE: 'businessIdentityCode',
  AUTOMATIC_MODELING: 'automaticModeling',
  STATISTICS_TOTAL: 'statistics.total',
  STATISTICS_RESOLVED: 'statistics.resolvedTotal',
  STATISTICS_AUTO_RESOLVED: 'statistics.autoResolved',
  STATISTICS_MANUAL_RESOLVED: 'statistics.manualResolved',
  STATISTICS_OPEN: 'statistics.open',
  STATISTICS_RESOLVED_PERCENTUAL: 'statistics.resolvedTotalPercentual',
  STATISTICS_AUTO_RESOLVED_PERCENTUAL: 'statistics.autoResolvedPercentual',
  STATISTICS_MANUAL_RESOLVED_PERCENTUAL: 'statistics.manualResolvedPercentual'
};

export const COLUMN_AGGREGATES = [
  {
    field: GRID_COLUMNS.STATISTICS_TOTAL,
    aggregate: 'sum'
  },
  {
    field: GRID_COLUMNS.STATISTICS_RESOLVED,
    aggregate: 'sum'
  },
  {
    field: GRID_COLUMNS.STATISTICS_AUTO_RESOLVED,
    aggregate: 'sum'
  },
  {
    field: GRID_COLUMNS.STATISTICS_MANUAL_RESOLVED,
    aggregate: 'sum'
  },
  {
    field: GRID_COLUMNS.STATISTICS_OPEN,
    aggregate: 'sum'
  }
];

export const GRID_SELECTOR = '#grid';

export const COLUMN_WIDTH = 110;

export const groupHeaderTemplate = '#= value #';

export const footerTemplate = '#= sum || 0 #';

export const kendoGridHeaderCheckboxClass = 'kendo-grid-header-checkbox';
