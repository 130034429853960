<kendo-dropdownlist
  [defaultItem]="defaultItem"
  [data]="dataSource"
  [textField]="textField"
  [valueField]="textField"
  (valueChange)="valueChanged($event)"
  [valuePrimitive]="false"
  [value]="value"
  (blur)="touched()"
>
</kendo-dropdownlist>
