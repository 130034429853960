import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of } from 'rxjs';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingDataService } from './reporting-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import { TrendReportBaseService } from './trend-report-base.service';
import { ToasterService } from '../../../shared/services/toaster.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class TrendReportService extends TrendReportBaseService {
  public constructor(
    private readonly reportingDataService: ReportingDataService,
    reportingSeriesService: ReportingSeriesService,
    private readonly toaster: ToasterService
  ) {
    super(reportingSeriesService);
  }

  public getData(
    params: ReportingSearchParams,
    facilityIds: number[]
  ): Observable<ReportingSeriesByFacility> {
    if (this.hasUnsupportedParams(params)) {
      this.toaster.info('REPORTING.ERRORS.UNSUPPORTED_TREND_REPORT_TIMEPARAMS');
      return of(facilityIds.toRecord(fId => fId, () => []));
    }
    const chartOptions = this.getChartOptions(params);
    const modifiedParams = this.getModifiedParams(params);
    const titlePeriods = this.getTitlePeriods(params);
    return forkJoin({
      measured: forkJoin([
        this.reportingDataService.getMeasuredValues(
          ReportType.Trend,
          modifiedParams,
          facilityIds,
          false,
          chartOptions,
          titlePeriods
        ),
        this.reportingDataService.getDistributions(ReportType.Trend, modifiedParams, facilityIds, false, titlePeriods),
        this.reportingDataService.getMeasuredDerivedValues(
          ReportType.Trend,
          modifiedParams,
          facilityIds,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        ),
      ]),
      normalized: forkJoin([
        this.reportingDataService.getNormalizedValues(
          ReportType.Trend,
          modifiedParams,
          facilityIds,
          false,
          chartOptions,
          titlePeriods
        ),
        this.reportingDataService.getNormalizedDerivedValues(
          ReportType.Trend,
          modifiedParams,
          facilityIds,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        ),
      ]),
      temperatures: this.reportingDataService.getTemperature(params, facilityIds),
      nationalCosts: forkJoin([
        this.reportingDataService.getNationalCostsReportingData(
          modifiedParams,
          facilityIds,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        )
      ]),
      meterBasedCosts: forkJoin([
        this.reportingDataService.getMeterBasedCostsReportingData(
          modifiedParams,
          facilityIds,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        )
      ]),
      emissions: forkJoin([
        this.reportingDataService.getMeasuredEmissionsValues(
          ReportType.Trend,
          modifiedParams,
          facilityIds,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        ),
        this.reportingDataService.getNormalizedEmissionsValues(
          ReportType.Trend,
          modifiedParams,
          facilityIds,
          false,
          {
            serieSettings: modifiedParams.periods.map(() => ({
              serieType: 'line'
            })),
          },
          titlePeriods
        )
      ])
    }).pipe(
      map(({ measured, normalized, temperatures, nationalCosts, meterBasedCosts, emissions }) => this.handleResponse(
        facilityIds,
        measured,
        normalized,
        temperatures,
        nationalCosts,
        meterBasedCosts,
        emissions,
        modifiedParams.periods
      ))
    );
  }
}
