import { SeriesType } from '@progress/kendo-angular-charts';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { format, getQuarter } from 'date-fns';

import { Quantities } from '@enerkey/clients/metering';
import {
  Consumption,
  FacilityConsumptions,
  ReportingUnit,
  RequestDuration,
  RequestResolution,
} from '@enerkey/clients/reporting';

import { ColorService } from '../../../shared/services/color.service';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeries } from '../shared/reporting-series';
import { ToasterService } from '../../../shared/services/toaster.service';
import { durationToString } from '../shared/duration-to-string';
import { QuantityService } from '../../../shared/services/quantity.service';
import { ReportType } from '../shared/report-type';

export enum ReportingGraphColorType {
  Primary,
  Alternative,
}

export enum ChartLineOpacity {
  Less = 0.6,
  More = 0.85,
}

export const defaultChartLineWidth = 1.4;

/** Series indexed by facility ID or meter ID */
export type ReportingSeriesById = Record<number, ReportingSeries[]>;

export interface SerieChartOptions {
  readonly serieSettings?: {
    readonly serieType: SeriesType,
    readonly lineOpacity?: number;
  }[],
  readonly positionInTooltip?: number;
  readonly colorType?: ReportingGraphColorType
}

export interface ReportingData {
  series: ReportingSeriesById;
  quantityId: Quantities;
  derivedId?: number;
}

export interface TitleParams {
  duration: Duration;
  periods: Date[];
}

export abstract class ReportingDataServiceBase {
  protected abstract consumptionRequest(
    quantityId: Quantities,
    isNormalized: boolean,
    facilityIds: number[],
    resolution: RequestResolution,
    start: Date,
    duration: RequestDuration,
    unit: ReportingUnit
  ): Observable<{
    unit: string;
    relatedUnit: string,
    values: { [key: string]: FacilityConsumptions }
  }>;

  public constructor(
    protected readonly colorService: ColorService,
    protected readonly translateService: TranslateService,
    protected readonly toasterService: ToasterService,
    protected readonly quantityService: QuantityService
  ) {
  }

  protected getQuantityData({
    reportType,
    params,
    facilityIds,
    quantityId,
    normalization,
    chartOptions,
    isCumulative,
    titleParams,
    meterIds,
  }: {
    reportType: ReportType,
    params: ReportingSearchParams,
    facilityIds: number[],
    quantityId: Quantities,
    normalization: boolean,
    chartOptions?: SerieChartOptions,
    isCumulative?: boolean,
    titleParams?: TitleParams,
    meterIds?: number[]
  }): Observable<ReportingSeriesById> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultSearchPeriods: any = {};
    let selectedSearchPeriods = params.searchPeriods;

    if (params && params.searchPeriods.length === 1 && reportType === ReportType.Forecast) {
      const defaultStartDate = new Date(params.searchPeriods[0].start);
      const previousYearStartDate = new Date(defaultStartDate);
      previousYearStartDate.setFullYear(defaultStartDate.getFullYear() - 1);
      const defaultEndDate = new Date(params.searchPeriods[0].end);
      const previousYearEndDate = new Date(defaultEndDate);
      previousYearEndDate.setFullYear(defaultEndDate.getFullYear() - 1);
      defaultSearchPeriods.start = previousYearStartDate;
      defaultSearchPeriods.end = previousYearEndDate;
      selectedSearchPeriods = [defaultSearchPeriods, ...selectedSearchPeriods];
      const isDuplicate = params.periods.some(res => res.getFullYear() === previousYearStartDate.getFullYear());
      if (!isDuplicate) {
        params.periods.unshift(previousYearStartDate);
      }
    }

    return forkJoin(selectedSearchPeriods.map(period => this.consumptionRequest(
      quantityId,
      normalization,
      facilityIds,
      params.resolution,
      period.start,
      params.duration,
      params.unit
    ))).pipe(
      map(response => {
        const isHourResolution = params.resolution === RequestResolution.PT1H;
        const graphColors = this.getConsumptionGraphColors(
          quantityId,
          response.length,
          isHourResolution ? ReportingGraphColorType.Alternative : chartOptions?.colorType
        );
        const gridColors = this.colorService.getPrimaryGraphColors(quantityId, response.length);
        const idsToMap = meterIds ? meterIds : facilityIds;

        const minTranslation = this.translateService.instant('FACILITIES_REPORT.MIN');
        const maxTranslation = this.translateService.instant('FACILITIES_REPORT.MAX');
        const meanTranslation = this.translateService.instant('FACILITIES_REPORT.AVERAGE');

        return idsToMap.toRecord(
          id => id,
          id => {
            if (
              !response.some(r => r.values[id]?.hasValues) ||
              this.hasFutureTrendPeriods(reportType, params.searchPeriods[0].start)
            ) {
              return null;
            }

            const comparisonPeriodValues = response[response.length - 1].values[id].values;
            const series: ReportingSeries[] = [];

            const relatedSettingsForQuantity = params.minMaxAverageByQuantity[quantityId];

            response.forEach((r, index, array) => {
              const periodName = this.getPeriodName(params, titleParams, index);
              const values = r.values[id].values.map(v => Consumption.fromJS(v));
              const serieSettings = chartOptions?.serieSettings?.[index];

              const isInspectionPeriod = index === array.length - 1;
              const isComparisonPeriod = (index !== array.length - 1) && array.length > 1;
              if (!params.showSummedConsumption) {
                values.forEach(v => {
                  const seriesName = this.getMonthlyAndQuarterlyTitle(v.timestamp, params.resolution);
                  series.push(new ReportingSeries({
                    chartOptions: { serieType: null },
                    gridOptions: {
                      gridTitle: seriesName,
                      gridColor: gridColors[index],
                      comparisonColor: gridColors[gridColors.length - 1],
                      hideInGrid: !params.showConsumption ||
                        (params.showMeasuredValues ?
                          !params.showMeasuredValues :
                          (params.showDistribution && !normalization))
                    },
                    options: {
                      quantityId: quantityId,
                      color: this.colorService.shadeColor(graphColors[index], -10),
                      isComparisonPeriod,
                      isInspectionPeriod,
                      serieTitle: normalization
                        ? `${this.translateService.instant('FACILITIES.NORMALIZED')} ${seriesName}`
                        : seriesName,
                      unit: r.relatedUnit,
                      unitKey: params.unit,
                      isNormalized: normalization,
                      serieType: 'consumption',
                    },
                    consumptions: [v]
                  }));
                });
              } else {
                series.push(new ReportingSeries({
                  chartOptions: {
                    serieType: isHourResolution
                      ? this.getHourSeriesType(isInspectionPeriod)
                      : serieSettings?.serieType,
                    lineOpacity: (isHourResolution && isInspectionPeriod)
                      ? ChartLineOpacity.Less
                      : serieSettings?.lineOpacity,
                    lineWidth: (isHourResolution && isComparisonPeriod) ? defaultChartLineWidth : undefined,
                    hideInChart: !params.showConsumption || (params.showDistribution && !normalization) ||
                    (isComparisonPeriod && reportType === ReportType.Forecast && params.searchPeriods.length === 1),
                  },
                  gridOptions: {
                    gridColor: gridColors[index],
                    comparisonColor: gridColors[gridColors.length - 1],
                    hideInGrid: !params.showConsumption ||
                      (params.showMeasuredValues ?
                        !params.showMeasuredValues :
                        (params.showDistribution && !normalization)) ||
                    (isComparisonPeriod && reportType === ReportType.Forecast && params.searchPeriods.length === 1),
                    gridTitle: periodName,
                  },
                  options: {
                    quantityId: quantityId,
                    color: normalization ? this.colorService.shadeColor(graphColors[index], 10) : graphColors[index],
                    serieTitle: normalization
                      ? `${this.translateService.instant('FACILITIES.NORMALIZED')} ${periodName}`
                      : periodName,
                    unit: r.unit,
                    isComparisonPeriod,
                    isInspectionPeriod,
                    unitKey: params.unit,
                    isNormalized: normalization,
                    serieType: 'consumption',
                  },
                  chartItemOptions: {
                    positionInTooltip: chartOptions?.positionInTooltip,
                    title: normalization ? 'FACILITIES.NORMALIZED' : 'FACILITIES.MEASURED',
                    translateTitle: true,
                  },
                  consumptions: values,
                  comparisonConsumptions: comparisonPeriodValues,
                  comparisonPeriodAsBase: true,
                  isCumulative
                }));
              }

              const relatedSerieOptions = [
                { condition: 'min', serieType: 'relatedMin', field: 'min', translation: minTranslation },
                { condition: 'max', serieType: 'relatedMax', field: 'max', translation: maxTranslation },
                { condition: 'average', serieType: 'relatedAverage', field: 'mean', translation: meanTranslation },
              ] as const;

              for (const relatedSerie of relatedSerieOptions) {
                if (relatedSettingsForQuantity?.includes(relatedSerie.condition)) {
                  series.push(new ReportingSeries({
                    chartOptions: { serieType: 'line' },
                    gridOptions: {
                      gridTitle: `${relatedSerie.translation} ${periodName} [${r.relatedUnit}]`,
                    },
                    options: {
                      quantityId: quantityId,
                      color: this.colorService.shadeColor(graphColors[index], -10),
                      isComparisonPeriod,
                      isInspectionPeriod,
                      serieTitle: `${relatedSerie.translation} ${periodName}`,
                      unit: r.relatedUnit,
                      unitKey: params.unit,
                      isNormalized: normalization,
                      serieType: relatedSerie.serieType,
                    },
                    chartItemOptions: { positionInTooltip: chartOptions?.positionInTooltip },
                    consumptions: values.map(v => ({
                      timestamp: v.timestamp,
                      incomplete: v.incomplete,
                      value: v[relatedSerie.field],
                    })),
                  }));
                }
              }
            });

            return series;
          }
        );
      }),
      catchError(() => {
        this.toasterService.error('FACILITIES.DOWNLOAD_ERROR');
        return this.getEmptyResponse(meterIds ? meterIds : facilityIds);
      })
    );
  }

  protected getEmptyResponse(ids: number[]): Observable<ReportingSeriesById> {
    return of(ids.toRecord(
      id => id,
      () => []
    ));
  }

  protected getPeriodName(
    params: ReportingSearchParams,
    titleParams: TitleParams,
    index: number
  ): string {
    return durationToString(
      titleParams?.periods[index] ?? params.periods[index],
      titleParams?.duration ?? params.duration,
      0,
      true
    );
  }

  protected getMonthlyAndQuarterlyTitle(startDate: Date, resolution: RequestResolution): string {
    if (resolution === RequestResolution.P1M) {
      return format(startDate, 'M/yy');
    } else if (resolution === RequestResolution.P3M) {
      return `Q${getQuarter(startDate)}/${format(startDate, 'yy')}`;
    }
  }

  protected getMeaningfulMeasuredQuantityIds(
    quantityIds: Quantities[],
    isMeasuredDataSelected: boolean
  ): Observable<number[]> {
    return isMeasuredDataSelected
      ? of(quantityIds)
      : this.quantityService.normalizedQuantityIds$.pipe(
        take(1),
        map(normalizedQuantityIds => quantityIds.filter(qId => !normalizedQuantityIds.includes(qId)))
      );
  }

  protected getMeaningfulNormalizedQuantityIds(
    quantityIds: Quantities[]
  ): Observable<number[]> {
    return this.quantityService.normalizedQuantityIds$.pipe(
      take(1),
      map(normalizedQuantityIds => quantityIds.filter(qId => normalizedQuantityIds.includes(qId)))
    );
  }

  protected getConsumptionGraphColors(
    quantityId: Quantities,
    count: number,
    colorType: ReportingGraphColorType
  ): string[] {
    switch (colorType) {
      case ReportingGraphColorType.Alternative:
        return this.colorService.getAlternativeGraphColors(quantityId, count);
      default:
        return this.colorService.getPrimaryGraphColors(quantityId, count);
    }
  }

  protected getHourSeriesType(isInspectionPeriod: boolean): SeriesType {
    return isInspectionPeriod ? 'area' : 'line';
  }

  protected hasFutureTrendPeriods(reportType: ReportType, searchPeriod: Date): boolean {
    return (reportType === ReportType.Trend && searchPeriod.getFullYear() >= new Date().getFullYear());
  }
}
