<div
  *ngIf="(facilityLoading$ | async) as loading"
  class="loading"
  [loadingSpinner]="loading"
></div>
<ng-container *ngIf="(facilityPropertyData$ | async) as data">
  <div *ngFor="let row of data" class="row propertiesRow">
    <div *ngFor="let property of row" class="large-6 column">
      <ng-container *ngIf="property">
        <form class="propertiesCol">
          <div class="propertyName">{{ property?.name }}</div>
          <ng-container *ngIf="(modifiedPropertyIds$ | async) as modifiedIds">
            <div
              class="propertyValue"
              [class.modifiedProperty]="(!property.property?.id || modifiedIds[property.property?.id])"
              [title]="property?.value.length > 15 ? property?.value : ''"
            >
              {{ property?.value }}
            </div>
            <div
              class="propertyDate"
              [class.modifiedProperty]="(!property.property?.id || modifiedIds[property.property?.id])"
            >
              {{ property.property?.fromDate | kendoDate: 'd' }}
            </div>
          </ng-container>
          <div class="propertyActions">
            <button
              type="button" class="button"
              [title]="'FACILITY.EDIT_FORM.EDIT_PROPERTY' | translate"
              (click)="openEditModal(property)"
            >
              <i class="fas fa-pencil-alt"></i>
            </button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
  <div *ngIf="!data.length && !(facilityLoading$ | async)" class="noData">
    <ng-container [ngSwitch]="dataType">
      <ng-container *ngSwitchCase="FacilityDataType.Properties">
        {{ 'FACILITY.EDIT_FORM.NO_FACILITYPROPERTIES' | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="FacilityDataType.CO2Factor">
        {{ 'FACILITY.EDIT_FORM.NO_CO2FACTORS' | translate }}
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<hr>
<div class="row">
  <div class="columns">
    <ng-container [ngSwitch]="dataType">
      <ng-container *ngSwitchCase="FacilityDataType.Properties">
        <label><strong>{{ 'FACILITY.EDIT_FORM.ADDPROPERTY' | translate }}</strong></label>
      </ng-container>
      <ng-container *ngSwitchCase="FacilityDataType.CO2Factor">
        <label><strong>{{ 'FACILITY.EDIT_FORM.ADDCO2PROPERTY' | translate }}</strong></label>
      </ng-container>
    </ng-container>
    <kendo-dropdownlist
      [data]="propertyList$ | async"
      [valuePrimitive]="false"
      [textField]="'text'"
      [valueField]="'value'"
      [loading]="propertiesLoading$ | async"
      [formControl]="propertyListField"
      class="addDropdown"
    ></kendo-dropdownlist>
  </div>
</div>
