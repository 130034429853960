import MeterInterfaceStatusComponent from './meter-interface-status-component';
import ServicesModule from '../../services';

const dependencies = [
  ServicesModule.name,
];

const MeterInterfaceStatusModule = angular
  .module('app.components.meter-interface-status', dependencies)
  .component('meterInterfaceStatus', MeterInterfaceStatusComponent);

export default MeterInterfaceStatusModule;
