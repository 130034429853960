import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { StateService } from '@uirouter/core';

import { ObservedValueOf } from 'rxjs';

import {
  ActionOutViewModel,
  ActionSlimViewModel,
  ActionType as IActionType,
  InesActionSlimViewModel
} from '@enerkey/clients/attachments';
import { AnalyticsType } from '@enerkey/clients/ines';
import { ModalService } from '@enerkey/foundation-angular';

import { LoadingModalComponent } from '../../../../shared/loading-modal/loading-modal.component';
import { InsightService } from '../../../../modules/analytics/services/insight.service';

import EmActionEditService from
  '../../../../modules/energy-management/components/em-action-edit/em-action-edit-service';
import { getNewActionDefaults } from '../../../../modules/energy-management/constants/em-shared.constant';
import { StateLocationService } from '../../../../services/state-location.service';
/* eslint-disable @typescript-eslint/no-explicit-any */

enum ModalInfo {
  INES = 'ines',
  REPORT = 'report',
  ACTION = 'action',
  COMMENT = 'comment'
}

type ActionType = 'action' | 'comment';

type ModuleType = 'ines' | 'report';

@Component({
  selector: 'facilities-popup-actions',
  templateUrl: './facilities-popup-actions.component.html',
  styleUrls: ['./facilities-popup-actions.component.scss'],
  providers: [InsightService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilitiesPopupActionsComponent implements OnInit {
  @Input() public actions: ActionSlimViewModel[] | InesActionSlimViewModel[];

  @Input() public actionType: ActionType = ModalInfo.ACTION;

  @Input() public moduleType?: ModuleType;

  @Input() public analyticsType?: AnalyticsType;

  @Input() public facilityId?: number;

  @Output() public readonly addedAction = new EventEmitter<ActionOutViewModel>();

  public title: string;

  public constructor(
    private readonly insightService: InsightService,
    private readonly modalService: ModalService,
    private readonly stateService: StateService,
    @Inject('emActionEditService') private readonly emActionEditService: EmActionEditService,
    private readonly stateLocationService: StateLocationService
  ) { }

  public ngOnInit(): void {
    this.title = this.getTitle(this.actionType, this.moduleType);
  }

  public toAction(): void {
    if (this.moduleType === 'report') {
      const actionType = this.actionType === 'action' ? IActionType.ES : IActionType.KE;
      const defaults = getNewActionDefaults(actionType);

      defaults.reportingObjectId = this.facilityId;

      this.emActionEditService.getModal(defaults).then((data: any) => {
        this.addedAction.emit(data.action as ActionOutViewModel);
      });

    } else {
      type ActionModel = ObservedValueOf<ReturnType<InsightService['getActionModel']>>;
      type LPC = typeof LoadingModalComponent;
      type TLPC = LoadingModalComponent<ActionModel>;

      const modal = this.modalService.open<LPC, TLPC>(LoadingModalComponent);
      modal.componentInstance.source$ = this.insightService.getActionModel(this.analyticsType, this.facilityId);

      modal.result.resolved(model => {
        this.emActionEditService.getModal(model).then((data: any) => {
          this.addedAction.emit(data.action as ActionOutViewModel);
        });
      });
    }
  }

  public goToEnergyManagement(): void {
    const targetStateName = this.actionType === ModalInfo.ACTION
      ? 'energy-management.actions'
      : 'energy-management.comments';
    const params: any = {
      startDateIndex: 2,
      reportingObjectId: this.facilityId,
      automaticSearch: true
    };
    if (this.moduleType === 'report') {
      this.stateLocationService.openStateInNewTab(targetStateName, params);
    } else {
      this.stateService.go(targetStateName, params);
    }
  }

  private getTitle(actionType: ActionType, moduleType: ModuleType): string {
    if (actionType === ModalInfo.ACTION) {
      return moduleType === ModalInfo.INES
        ? 'INES_REPORT.LATEST_ACTIONS_POPUP_TITLE'
        : 'FACILITIES_REPORT.LATEST_ACTIONS_POPUP_TITLE';
    } else {
      return moduleType !== ModalInfo.INES
        ? 'FACILITIES_REPORT.LATEST_COMMENTS_POPUP_TITLE'
        : '';
    }
  }
}
