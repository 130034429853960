import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { EmissionFactorType } from '../../services/emissions-widget.service';
import { getStringEnumValues } from '@enerkey/ts-utils';
import { EkComboComponent } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

const emissionTypeTranslationKeys: Record<EmissionFactorType, string> = {
  [EmissionFactorType.Facility]: 'EMISSIONS_WIDGET.FACTOR.FACILITY',
  [EmissionFactorType.Location]: 'EMISSIONS_WIDGET.FACTOR.LOCATION',
};

@Component({
  selector: 'emissions-type-select',
  templateUrl: '../../../../shared/ek-inputs/ek-combo/ek-combo.component.html',
  styleUrls: ['../../../../shared/ek-inputs/ek-combo/ek-combo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmissionsTypeSelectComponent),
    multi: true
  }]
})
export class EmissionsTypeSelectComponent extends EkComboComponent<EmissionFactorType> {
  public override items = getStringEnumValues(EmissionFactorType).map(
    option => ({
      value: option,
      text: emissionTypeTranslationKeys[option]
    })
  );
}
