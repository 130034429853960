import { Observable } from 'rxjs';
import {
  FacilityMeterCountsDto,
  IMeterManagementClient,
  MeterHierarchy,
  MeterManagementMeter,
  MeterWithProperties
} from './meter-management-client';

export class MeterManagementClientMock implements IMeterManagementClient {
  public getMetersByFacility(): Observable<{ [key: string]: MeterManagementMeter[] }> {
    throw new Error('Method not implemented.');
  }
  public getMetersById(): Observable<{ [key: string]: MeterManagementMeter[] }> {
    throw new Error('Method not implemented.');
  }
  public getFacilitiesMetersCount(): Observable<FacilityMeterCountsDto[]> {
    throw new Error('Method not implemented.');
  }
  public getMeterHierarchiesForFacilities(): Observable<{ [key: string]: MeterHierarchy[] }> {
    throw new Error('Method not implemented.');
  }
  public upsertMeterHierarchies(): Observable<number[]> {
    throw new Error('Method not implemented.');
  }
  public removeMeterHierarchy(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public createMeters(): Observable<MeterWithProperties[]> {
    throw new Error('Method not implemented.');
  }
  public updateMeters(): Observable<MeterWithProperties[]> {
    throw new Error('Method not implemented.');
  }
  public patchMeterHierarchy(): Observable<MeterHierarchy> {
    throw new Error('Method not implemented.');
  }
  public getMeterHierarchy(): Observable<MeterHierarchy> {
    throw new Error('Method not implemented.');
  }
}
