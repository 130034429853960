import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  finalize,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import {
  ICategory,
  IRow,
  IUpdateRowUnit,
  Report,
  Row,
  RowUnitType,
  RowWithId,
  SustainabilityClient,
} from '@enerkey/clients/sustainability';
import { indicate, LoadingSubject, ofVoid } from '@enerkey/rxjs';

import { GriEditorRow } from '../models/gri-report-row';
import { GRI_REPORT_TOKEN } from '../gri-report-token';
import { ComboItem } from '../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { GriScopeNamePipe } from '../pipes/gri-scope-name.pipe';

export const CATEGORY_MIN_ID = 2000000000;
@Injectable()
export class GriReportService implements OnDestroy {

  public readonly report$: Observable<Report>;
  public readonly rows$: Observable<GriEditorRow[]>;
  public readonly categories$: Observable<ICategory[]>;
  public readonly units$: Observable<IUpdateRowUnit[]>;
  public readonly dataRefreshed$: Observable<void>;
  public readonly targets$: Observable<Record<number, number>>;
  public readonly changes$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;

  public readonly categoryOptions$: Observable<ComboItem<number>[]>;

  private readonly existingRows$: Observable<GriEditorRow[]>;
  private readonly updatedUnits$: Observable<IUpdateRowUnit[]>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();
  private readonly _dataRefreshed$ = new Subject<void>();
  private readonly _addedRows$ = new BehaviorSubject<GriEditorRow[]>([]);
  private readonly _deletedRowsIds$ = new BehaviorSubject<number[]>([]);
  private readonly _unitsChanged$ = new BehaviorSubject<void>(null);
  private readonly _categoriesChanged$ = new BehaviorSubject<void>(null);
  private readonly _targetsChanged$ = new BehaviorSubject<void>(null);
  private readonly _savedChanges$ = new BehaviorSubject<void>(null);

  public constructor(
    private readonly translateService: TranslateService,
    private readonly griScopeName: GriScopeNamePipe,
    private readonly susClient: SustainabilityClient,
    @Inject(GRI_REPORT_TOKEN) _report$: Observable<Report>
  ) {
    this.report$ = _report$.pipe(distinctUntilChanged());

    this.loading$ = this._loading$.asObservable();

    this.dataRefreshed$ = this._dataRefreshed$.asObservable();

    this.categories$ = this._categoriesChanged$.pipe(
      switchMap(() => this.susClient.getCategories().pipe(indicate(this._loading$))),
      map(categories => categories.filter(c => c?.id >= CATEGORY_MIN_ID)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.categoryOptions$ = this.categories$.pipe(
      map(categories => categories.map<ComboItem<number>>(
        c => ({
          value: c.id,
          text: `${this.translateService.instant(c.name)} - (${this.griScopeName.transform(c.scope)})`,
          group: this.griScopeName.transform(c.scope),
        })
      ).sortByMany('group', 'text')),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.updatedUnits$ = this._unitsChanged$.pipe(
      switchMap(() => this.report$),
      filter((report: Report) => report !== null),
      switchMap(report => this.susClient.getRowUnits(report.profileId).pipe(indicate(this._loading$))),
      takeUntil(this._destroy$)
    );

    this.units$ = this.updatedUnits$.pipe(
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.existingRows$ = combineLatest([
      this.report$,
      this._savedChanges$,
    ]).pipe(
      switchMap(([report]) => forkJoin({
        units: this.units$.pipe(take(1)),
        categories: this.categories$.pipe(take(1)),
        rows: report
          ? this.susClient.getRows(report.profileId, report.id).pipe(indicate(this._loading$))
          : of<IRow[]>([]),
      })),
      map(
        ({ rows, units, categories }) => rows.map(
          row => new GriEditorRow(
            row,
            categories.find(c => c.id === row.categoryId),
            units.find(u => u.id === row.rowUnitId)
          )
        ).filter(row => row.category)
      ),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.rows$ = combineLatest([
      this.existingRows$,
      this._addedRows$,
      this._deletedRowsIds$,
      this.units$.pipe(map(units => units.toRecord(u => u.id))),
      this.categories$.pipe(map(categories => categories.toRecord(c => c.id))),
    ]).pipe(
      map(([existing, added, deleted, units, categories]) => existing
        .filter(row => !deleted.some(deletedId => row.id === deletedId))
        .concat(added)
        .map(row => {
          row.unit = units[row.unit?.id] ?? row.unit;
          row.category = categories[row.category?.id] ?? row.category;
          return row;
        })),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.changes$ = combineLatest([this._addedRows$, this._deletedRowsIds$]).pipe(
      map(([a, b]) => !!(a.length || b.length)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.report$.subscribe(report => {
      if (!report) {
        this.resetAllChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
    this._dataRefreshed$.complete();
    this._addedRows$.complete();
    this._deletedRowsIds$.complete();
    this._unitsChanged$.complete();
    this._categoriesChanged$.complete();
    this._targetsChanged$.complete();
    this._savedChanges$.complete();
  }

  public filteredUnits(...rowUnitTypes: RowUnitType[]): Observable<IUpdateRowUnit[]> {
    return this.units$.pipe(
      map(units =>
        rowUnitTypes.length === 0 || rowUnitTypes.includes(RowUnitType.Any) ?
          units :
          units.filter(unit => rowUnitTypes.includes(unit.rowUnitType))),
      takeUntil(this._destroy$)
    );
  }

  /** Adds a new row to be saved. */
  public addRow(row: GriEditorRow): void {
    this._addedRows$.next([...this._addedRows$.value, row]);
  }

  /** Adds new rows to be saved. */
  public addRows(rows: GriEditorRow[]): void {
    this._addedRows$.next([...this._addedRows$.value, ...rows]);
  }

  /** Marks an existing row as deleted, or remove an unsaved row. */
  public removeRow(row: GriEditorRow): void {
    if (row.id) {
      this._deletedRowsIds$.next([...this._deletedRowsIds$.value, row.id]);
    } else {
      this._addedRows$.next(this._addedRows$.value.filter(r => r !== row));
    }
  }

  /** Signals to the service to re-fetch categories from backend. */
  public categoriesChanged(): void {
    this._categoriesChanged$.next();
    this._dataRefreshed$.next();
  }

  /** Signals to the service to re-fetch units from backend. */
  public unitsChanged(): Observable<IUpdateRowUnit[]> {
    this._unitsChanged$.next();
    this._dataRefreshed$.next();
    return this.updatedUnits$;
  }

  /** Signals to the service to re-fetch targets from backend. */
  public targetsChanged(): void {
    this._targetsChanged$.next();
    this._dataRefreshed$.next();
  }

  /** Signals to the service that the row has been modified. */
  public rowChanged(row: GriEditorRow, categoryId: number, unitId: number): void {
    forkJoin({
      units: this.units$.pipe(take(1)),
      categories: this.categories$.pipe(take(1)),
    }).subscribe(({ units, categories }) => {
      row.unit = units.find(u => u.id === unitId);
      row.category = categories.find(c => c.id === categoryId);
      this._dataRefreshed$.next();
    });
  }

  /** Returns an observable that completes when all changes have been saved, or immediately if there are none. */
  public saveAllChanges(): Observable<unknown> {
    return forkJoin({
      report: this.report$.pipe(take(1)),
      existing: this.existingRows$.pipe(take(1)),
    }).pipe(
      switchMap(({ report, existing }) => {
        // TODO: use single upsert endpoint when its implemented to backend
        const added = this._addedRows$.value;
        const createRequest$ = added.length
          ? this.susClient.createRows(
            report.profileId,
            report.id,
            added.map(r => new Row({ ...r.getPayload(), reportId: report.id }))
          )
          : ofVoid();

        const updated = existing.filter(r => r.changes.anyChanges);
        const updateRequest$ = updated.length
          ? this.susClient.updateRows(
            report.profileId,
            report.id,
            updated.map(r => new RowWithId({ ...r.getPayload(), reportId: report.id }))
          )
          : ofVoid();

        const deleteRequest$ = this._deletedRowsIds$.value.length
          ? this.susClient.deleteRows(
            report.profileId,
            report.id,
            this._deletedRowsIds$.value.map(id => id)
          )
          : ofVoid();

        return forkJoin([createRequest$, updateRequest$, deleteRequest$]).pipe(indicate(this._loading$));
      }),
      finalize(() => {
        this.resetAllChanges();
        this._dataRefreshed$.next();
        this._savedChanges$.next();
      })
    );
  }

  public resetAllChanges(): void {
    this._addedRows$.next([]);
    this._deletedRowsIds$.next([]);
  }
}
