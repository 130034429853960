import { Observable } from 'rxjs';

import {
  Bucket,
  BucketContact,
  BucketDocumentation,
  Configuration,
  ConfigurationRelation,
  FunctionConfiguration,
  Heuristics,
  IConfigurationControlClient,
  LegacyMapping,
  MeterReaderInformation,
  PatchReadingParameters,
  ReaderType,
  ReaderTypeInformation,
  ReaderTypeMeterList,
  StatusLog,
  SynchronizeResult,
  Terminal,
  TerminalStatus
} from './configuration-control-client';

export class ConfigurationControlClientMock implements IConfigurationControlClient {

  public addMetersToTerminal(): never {
    throw new Error('Method not implemented.');
  }
  public getTerminalNames(): never {
    throw new Error('Method not implemented.');
  }
  public getTerminalBaseInfoByFacilityTerminalDto(): never {
    throw new Error('Method not implemented.');
  }
  public getReaderTypeByName(): Observable<ReaderType> {
    throw new Error('Method not implemented.');
  }
  public getReaderInformationForMeters(): Observable<{ [key: string]: MeterReaderInformation }> {
    throw new Error('Method not implemented.');
  }
  public getConfigurationsByMeterIds(): Observable<FunctionConfiguration[]> {
    throw new Error('Method not implemented.');
  }
  public synchronizeConfigurations(): Observable<SynchronizeResult> {
    throw new Error('Method not implemented.');
  }
  public getTerminalStatuses(): Observable<TerminalStatus[]> {
    throw new Error('Method not implemented.');
  }
  public getTerminalStatusesByStatusCode(): Observable<TerminalStatus[]> {
    throw new Error('Method not implemented.');
  }
  public updateTerminalStatus(): Observable<TerminalStatus> {
    throw new Error('Method not implemented.');
  }
  public getTerminalStatusesByTerminalIds(): Observable<TerminalStatus[]> {
    throw new Error('Method not implemented.');
  }
  public getAllBucketContacts(): Observable<BucketContact[]> {
    throw new Error('Method not implemented.');
  }
  public updateBucketContact(): Observable<BucketContact> {
    throw new Error('Method not implemented.');
  }
  public createBucketContact(): Observable<BucketContact> {
    throw new Error('Method not implemented.');
  }
  public getBucketContact(): Observable<BucketContact> {
    throw new Error('Method not implemented.');
  }
  public removeBucketContact(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getBucketDocumentation(): Observable<BucketDocumentation> {
    throw new Error('Method not implemented.');
  }
  public removeBucketDocumentation(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public updateBucketDocumention(): Observable<BucketDocumentation> {
    throw new Error('Method not implemented.');
  }
  public createBucketDocumentation(): Observable<BucketDocumentation> {
    throw new Error('Method not implemented.');
  }
  public getBuckets(): Observable<Bucket[]> {
    throw new Error('Method not implemented.');
  }
  public searchBuckets(): Observable<Bucket[]> {
    throw new Error('Method not implemented.');
  }
  public getBucket(): Observable<Bucket> {
    throw new Error('Method not implemented.');
  }
  public updateBucket(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getConfigurationsForBucketId(): Observable<Configuration[]> {
    throw new Error('Method not implemented.');
  }
  public getConfigurationsForMeterIds(): Observable<Configuration[]> {
    throw new Error('Method not implemented.');
  }
  public getPassword(): Observable<string> {
    throw new Error('Method not implemented.');
  }
  public createConfiguration(): Observable<Bucket> {
    throw new Error('Method not implemented.');
  }
  public createConfigurations(): Observable<{ [key: string]: number[] }> {
    throw new Error('Method not implemented.');
  }
  public updateConfig(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public removeConfiguration(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getSchema(): Observable<string> {
    throw new Error('Method not implemented.');
  }
  public getStatusLog(): Observable<StatusLog[]> {
    throw new Error('Method not implemented.');
  }
  public getStatusLogByMeterId(): Observable<StatusLog[]> {
    throw new Error('Method not implemented.');
  }
  public executePatch(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getConfigurations(): Observable<FunctionConfiguration[]> {
    throw new Error('Method not implemented.');
  }
  public updateConfigurationStatusForBucket(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public updateConfigurationStatuses(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getConfigurationsForReaderType(): Observable<FunctionConfiguration[]> {
    throw new Error('Method not implemented.');
  }
  public getHeuristics(): Observable<Heuristics[]> {
    throw new Error('Method not implemented.');
  }
  public getMetersHeuristics(): Observable<Heuristics[]> {
    throw new Error('Method not implemented.');
  }
  public getHeuristicsParameterKeys(): Observable<{ [key: string]: string[] }> {
    throw new Error('Method not implemented.');
  }
  public getMetersHeuristicsAndParameterValues(): Observable<{ [key: string]: { [key: string]: string } }> {
    throw new Error('Method not implemented.');
  }
  public getMappings(): Observable<LegacyMapping[]> {
    throw new Error('Method not implemented.');
  }
  public configurationForMeterExists(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
  public getReaderTypes(): Observable<ReaderType[]> {
    throw new Error('Method not implemented.');
  }
  public updateReaderType(): Observable<ReaderType> {
    throw new Error('Method not implemented.');
  }
  public createReaderType(): Observable<ReaderType> {
    throw new Error('Method not implemented.');
  }
  public getReaderType(): Observable<ReaderType> {
    throw new Error('Method not implemented.');
  }
  public removeReaderType(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getReaderTypeByMeterId(): Observable<ReaderTypeInformation> {
    throw new Error('Method not implemented.');
  }
  public getReaderTypeForMetersWithTerminal(): Observable<{ [key: string]: ReaderTypeInformation }> {
    throw new Error('Method not implemented.');
  }
  public getReaderTypeForMeters(): Observable<ReaderTypeMeterList[]> {
    throw new Error('Method not implemented.');
  }
  public getTerminals(): Observable<Terminal[]> {
    throw new Error('Method not implemented.');
  }
  public updateTerminal(): Observable<Terminal> {
    throw new Error('Method not implemented.');
  }
  public createTerminal(): Observable<Terminal> {
    throw new Error('Method not implemented.');
  }
  public getTerminal(): Observable<Terminal> {
    throw new Error('Method not implemented.');
  }
  public removeTerminal(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getTerminalsById(): Observable<Terminal[]> {
    throw new Error('Method not implemented.');
  }
  public createTerminals(): Observable<Terminal[]> {
    throw new Error('Method not implemented.');
  }
  public getTerminalByName(): Observable<Terminal> {
    throw new Error('Method not implemented.');
  }
  public getTerminalByIdentifier(): Observable<Terminal> {
    throw new Error('Method not implemented.');
  }
  public searchTerminalsByName(): Observable<Terminal[]> {
    throw new Error('Method not implemented.');
  }
  public searchTerminalsByNameList(): Observable<Terminal[]> {
    throw new Error('Method not implemented.');
  }
  public searchTerminalsByIdentifier(): Observable<Terminal[]> {
    throw new Error('Method not implemented.');
  }
  public searchTerminalsByIdentifierList(): Observable<Terminal[]> {
    throw new Error('Method not implemented.');
  }
  public invalidateConfigurationsCache(): Observable<Configuration[]> {
    throw new Error('Method not implemented.');
  }
  public getPatchReadingParameters(): Observable<PatchReadingParameters[]> {
    throw new Error('Method not implemented.');
  }
  public createPatchReadings(): Observable<string[]> {
    throw new Error('Method not implemented.');
  }
  public upsertRecurringJobs(): Observable<string[]> {
    throw new Error('Method not implemented.');
  }
  public updateConfiguration(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public getConfigurationRelations(): Observable<{ [key: string]: ConfigurationRelation[] }> {
    throw new Error('Method not implemented.');
  }
  public invalidateCachedSchema(): Observable<string> {
    throw new Error('Method not implemented.');
  }
  public getConfigurationsForReaderTypeAndExternalIdsUsingPost(): Observable<FunctionConfiguration[]> {
    throw new Error('Method not implemented.');
  }
  public executePatchForMeters(): Observable<void> {
    throw new Error('Method not implemented.');
  }
  public setTerminalComment(): Observable<void> {
    throw new Error('Method not implemented.');
  }
}
