import { SearchList } from '@enerkey/clients/manual-qa';

const $inject = [
  '$modalInstance', 'searchList', 'ManualQaPopupService',
  'mqaClient'
];

function ManualQaSearchListEditModalController(
  $modalInstance, searchList, ManualQaPopupService,
  mqaClient
) {
  const vm = this;

  vm.searchList = angular.copy(searchList);

  vm.close = close;
  vm.dismiss = dismiss;
  vm.saveSearchList = saveSearchList;

  /**
   * Handles modal close
   *
   * @param {SearchList} searchList
   */
  function close(searchList) {
    $modalInstance.close(searchList);
  }

  /**
   * Handles modal dismiss
   */
  function dismiss() {
    $modalInstance.dismiss();
  }

  /**
   * Saves new search list
   *
   * @param {SearchList} searchList
   * @param {boolean} saveAsNew
   *
   * @returns {Object}
   */
  function saveSearchList(searchList, saveAsNew = false) {
    const createNew = searchList.isNew || saveAsNew;
    const request = createNew ?
      mqaClient.insertSearchList(new SearchList({
        description: searchList.description,
        name: searchList.name,
        publicityStatus: searchList.publicityStatus,
        searchCriteria: searchList.searchCriteria
      })) :
      mqaClient.updateSearchList(searchList);

    return request.subscribe({
      next: response => {
        onSuccess();
        close(response || searchList);
      },
      error: () => {
        onError();
      }
    });

    function onSuccess() {
      createNew ?
        ManualQaPopupService.successPopUp('MQA.SUCCESS.SEARCH_LIST_SAVED') :
        ManualQaPopupService.successPopUp('MQA.SUCCESS.SEARCH_LIST_UPDATED')
      ;
    }

    function onError() {
      createNew ?
        ManualQaPopupService.errorPopUp('MQA.ERRORS.SEARCH_LIST_SAVE_ERROR') :
        ManualQaPopupService.errorPopUp('MQA.ERRORS.SEARCH_LIST_UPDATE_ERROR')
      ;
    }
  }
}

ManualQaSearchListEditModalController.$inject = $inject;

export default ManualQaSearchListEditModalController;
