import { AlarmsPopupTableController } from './alarms-popup-table.controller';
import alarmsPopupTableTemplate from 'raw-loader!./alarms-popup-table.html';

export const alarmsPopupTableComponent = {
  template: alarmsPopupTableTemplate,
  controller: AlarmsPopupTableController,
  controllerAs: 'vm',
  bindings: {
    notes: '<',
    limitCount: '<'
  }
};
