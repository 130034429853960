import _ from 'lodash';

import * as PublicityStatus from '../../constants/publicity-status';
import { SearchCriteria } from '../../shared/search-criteria';
import ManualQaSearchListEditModalController from './manual-qa-search-list-edit-modal-controller';

const $inject = ['$modal', 'utils', 'ManualQaViewSettingService', 'TimeFrameRadioService', 'SearchTypeRadioService'];

class ManualQaSearchListEditService {
  constructor($modal, utils, ManualQaViewSettingService, TimeFrameRadioService, SearchTypeRadioService) {
    this.$modal = $modal;
    this.utils = utils;
    this.ManualQaViewSettingService = ManualQaViewSettingService;
    this.TimeFrameRadioService = TimeFrameRadioService;
    this.SearchTypeRadioService = SearchTypeRadioService;
  }

  /**
   * Returns publicity statuses
   *
   * @returns {Array}
   */
  getPublicityStatuses() {
    const self = this;

    return [
      {
        id: PublicityStatus.PRIVATE,
        name: self.utils.localizedString('MQA.SEARCH_LIST.EDIT.SEARCH_LIST_OWN')
      },
      {
        id: PublicityStatus.PUBLIC,
        name: self.utils.localizedString('MQA.SEARCH_LIST.EDIT.SEARCH_LIST_PUBLIC')
      }
    ];
  }

  /**
   * Returns search list name from search criteria
   *
   * @param {Object} searchCriteria
   *
   * @returns {String}
   */
  getSearchListNameFromSearchCriteria(searchCriteria) {
    const nameParts = searchCriteria instanceof SearchCriteria ? [
      this.getViewSettingName(searchCriteria),
      this.getTimeFrameSelectionName(searchCriteria),
      this.getSearchTypeName(searchCriteria),
      this.getSubSearchTypeName(searchCriteria)
    ] : [];

    return _(nameParts)
      .compact()
      .value()
      .join(', ');
  }

  /**
   * Returns name for selected view setting
   *
   * @param {Object} searchCriteria
   *
   * @returns {String}
   */
  getViewSettingName(searchCriteria) {
    const viewSettings = this.ManualQaViewSettingService.getViewSettings();
    const viewSetting = _.find(viewSettings, { id: searchCriteria.getViewSetting() });

    return viewSetting ? this.utils.localizedString(viewSetting.translationKey) : '';
  }

  /**
   * Returns name for selected time frame
   *
   * @param {Object} searchCriteria
   *
   * @returns {String}
   */
  getTimeFrameSelectionName(searchCriteria) {
    const timeFrames = this.TimeFrameRadioService.getTimeFrames();
    const timeFrame = _.find(timeFrames, { id: searchCriteria.getTimeFrameSelection() });

    return timeFrame ? timeFrame.name : '';
  }

  /**
   * Return name for selected search type
   *
   * @param {Object} searchCriteria
   *
   * @returns {String}
   */
  getSearchTypeName(searchCriteria) {
    const searchTypes = this.SearchTypeRadioService.getSearchTypes();
    const searchType = _.find(searchTypes, { id: searchCriteria.getSearchType() });

    return searchType ? searchType.name : '';
  }

  /**
   * Returns name for selected sub search type
   *
   * @param {Object} searchCriteria
   *
   * @returns {String}
   */
  getSubSearchTypeName(searchCriteria) {
    const searchTypes = this.SearchTypeRadioService.getSearchTypes();
    const searchType = _.find(searchTypes, { id: searchCriteria.getSearchType() });

    return _.get(_.find(searchType.subOptions, { id: searchCriteria.getSubSearchType() }), 'name', '');
  }

  /**
   * Returns search list edit modal instance
   *
   * @param {SearchList} searchList
   *
   * @returns {Object}
   */
  getModal(searchList) {
    return this.$modal.open({
      template: `
          <manual-qa-search-list-edit 
            search-list="vm.searchList"
            on-save="vm.saveSearchList" 
            on-close="vm.dismiss"
          ></manual-qa-search-list-edit>
        `,
      controller: ManualQaSearchListEditModalController,
      controllerAs: 'vm',
      bindToController: true,
      resolve: {
        searchList: () => searchList
      }
    });
  }
}

ManualQaSearchListEditService.$inject = $inject;

export default ManualQaSearchListEditService;
