import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgfTooltipModule } from '@enerkey/foundation-angular';

import { EkTooltipIndicatorComponent } from './ek-tooltip-indicator/ek-tooltip-indicator.component';

@NgModule({
  declarations: [
    EkTooltipIndicatorComponent
  ],
  imports: [
    CommonModule,
    NgfTooltipModule,
  ],
  exports: [
    EkTooltipIndicatorComponent
  ]
})
export class EkCommonModule { }
