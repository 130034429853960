import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { map, Observable, shareReplay, Subject, takeUntil } from 'rxjs';

import { CompaniesService } from '../../../../shared/services/companies.service';
import { ProfileService } from '../../../../shared/services/profile.service';

@Component({
  selector: 'gri-organization-info',
  templateUrl: './gri-organization-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GriOrganizationInfoComponent implements OnDestroy {

  @Input() public showProfile: boolean;

  public readonly profileName$: Observable<string>;
  public readonly companyName$: Observable<string>;

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    profileService: ProfileService,
    companyService: CompaniesService
  ) {
    this.profileName$ = profileService.profile$.pipe(
      map(profile => profile.name),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.companyName$ = companyService.userCompany$.pipe(
      map(company => company?.name ?? /* istanbul ignore next */ '-'),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
