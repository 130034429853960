import * as Configs                     from '../constants/configs';
import DefectIssue                      from '../shared/defect-issue';
import DefectType                       from '../shared/defect-type';
import Fault                            from '../shared/fault';
import { combineDefectIssuesAndFaults } from '../shared/utils.functions';

ManualQaApiDefectIssue.$inject = ['ManualQaApiService'];

/**
 * Service that handles all defect issue api calls
 *
 * @returns {Object}
 *
 * @constructor
 */
export function ManualQaApiDefectIssue(ManualQaApiService) {
  return {
    getDefectIssues: getDefectIssues,
    getDefectIssuesWithFaults: getDefectIssuesWithFaults,
    closeDefectIssue: closeDefectIssue,
    closeDefectIssues: closeDefectIssues,
    getFaults: getFaults,
    getDefectTypes: getDefectTypes
  };

  /**
   * Returns defect issues from backend
   *
   * @param {Object} timeFrame
   * @param {Array} meterIds
   *
   * @returns {Object}
   */
  function getDefectIssues(timeFrame, meterIds) {
    return ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}defectissue`,
      data: meterIds,
      params: timeFrame.getQueryParameters()
    }).then(result => result.data.map(defectIssue => new DefectIssue(defectIssue)));
  }

  /**
   * Returns defect issues combined with faults
   *
   * @param {TimeFrame} timeFrame
   * @param {Array} meterIds
   *
   * @returns {Object}
   */
  function getDefectIssuesWithFaults(timeFrame, meterIds) {
    return ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}defectissue`,
      data: meterIds,
      params: timeFrame.getQueryParameters()
    })
      .then(defects => ManualQaApiService.doRequest({
        method: 'POST',
        url: `${Configs.API_URL}defectissue/faults`,
        data: defects.data.map(defect => defect.id)
      })
            .then(faults => combineDefectIssuesAndFaults(defects.data, faults.data)));
  }

  /**
   * Closes given defect issue
   *
   * @param {DefectIssue} defectIssue
   *
   * @returns {Object}
   */
  function closeDefectIssue(defectIssue) {
    return ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}defectissue/close/${defectIssue.getId()}`
    }).then(() => defectIssue);
  }

  /**
   * Closes multiple defect issues at ones
   *
   * @param {DefectIssue[]} defectIssues
   *
   * @returns {Object}
   */
  function closeDefectIssues(defectIssues) {
    return ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}defectissue/close/`,
      data: defectIssues.map(defectIssue => defectIssue.getId())
    })
      .then(result => result.data)
      .then(defectIssueIds => defectIssues.filter(defectIssue => defectIssueIds.includes(defectIssue.getId())));
  }

  /**
   * Returns faults for given defects
   *
   * @param {Array} defects
   *
   * @returns {Object}
   */
  function getFaults(defects) {
    return ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}defectissue/faults`,
      data: defects.map(defect => defect.getId())
    }).then(result => result.data.map(fault => new Fault(fault)));
  }

  /**
   * Returns all existing reader types
   *
   * @returns {Object}
   */
  function getDefectTypes() {
    return ManualQaApiService.doRequest({
      method: 'GET',
      url: `${Configs.API_URL}defectissue/faulttypes`
    }).then(result => result.data.map(type => new DefectType({ id: type.faultType })));
  }
}
