const $inject = ['$filter'];

function MeterDetailsListItemController($filter) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this;

  vm.$onInit = onInit;

  function onInit() {
    vm.title = $filter('translate')(vm.title);

    if (vm.isDate) {
      vm.content = $filter('date')(vm.content);
    }
  }
}

MeterDetailsListItemController.$inject = $inject;

export default MeterDetailsListItemController;
