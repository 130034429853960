import { momentToString } from '../../../../shared/date.functions';
import Meter from '../../shared/meter';

const $inject = ['utils', 'ManualQaChangeMeterService'];

function ManualQaChangeMeterController(utils, ManualQaChangeMeterService) {
  const vm = this;

  vm.$onInit = onInit;
  vm.dataChanged = dataChanged;
  vm.getPreviousValueLabelText = getPreviousValueLabelText;

  function onInit() {
    vm.newMeter = new Meter({ ...vm.meter, description: '' });
    const effectPeriod = vm.effectPeriod.map(timestamp => (timestamp ? momentToString(timestamp) : '')).join(' – ');
    vm.headerText = `${utils.localizedString('MQA.METER_CHANGE.MODAL_HEADER')}: ${effectPeriod}`;
  }

  /**
   * Updates property of newMeter
   *
   * @param {String} key
   * @param {*} value
   */
  function dataChanged(key, value) {
    vm.newMeter[key] = value;
  }

  function getPreviousValueLabelText(value) {
    return ManualQaChangeMeterService.getPreviousValueLabelText(value);
  }
}

ManualQaChangeMeterController.$inject = $inject;

export default ManualQaChangeMeterController;
