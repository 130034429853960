/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

/** Type guard that ensures that string enums cannot be passed */
type NumericEnum<TEnum> = TEnum extends Record<string, number | string>
  ? TEnum extends Record<string, string> ? never : TEnum : never;

/** Type guard that ensures numeric/mixed enums cannot be passed */
type StringEnum<TEnum> = TEnum extends Record<string, string>
  ? TEnum
  : never;

/** Type guard for either/mixed enum */
type EnumCtor = Record<string, string | number>;

const _numericValueCache = new Map<EnumCtor, any[]>();
const _stringValueCache = new Map<EnumCtor, any[]>();
const _keyCache = new Map<EnumCtor, any[]>();
const _entryCache = new Map<EnumCtor, any[]>();

/**
 * Returns all values of a numeric enum.
 */
export function getNumericEnumValues<T>(enumType: NumericEnum<T>): ReadonlyArray<T[keyof T]> {
  return _numericValueCache.getOrAdd(
    enumType,
    () => Object.values(enumType).filter(value => typeof value === 'number')
  );
}

/**
 * Returns all values of a string enum.
 */
export function getStringEnumValues<T>(enumType: StringEnum<T>): ReadonlyArray<T[keyof T]> {
  return _stringValueCache.getOrAdd(
    enumType,
    () => Object.values(enumType).filter(value => typeof value === 'string')
  );
}

/**
 * Returns all keys of an enum.
 */
export function getEnumKeys<T extends EnumCtor>(enumType: T): ReadonlyArray<keyof T> {
  return _keyCache.getOrAdd(
    enumType,
    () => Object.keys(enumType).filter(value => isNaN(value as any))
  );
}

export function getEnumEntries<T extends EnumCtor>(enumType: T): ReadonlyArray<readonly [keyof T, T[keyof T]]> {
  return _entryCache.getOrAdd(
    enumType,
    () => getEnumKeys(enumType).map(key => ([key, enumType[key]]))
  );
}

function getEnumValues<T extends EnumCtor>(enumType: T): ReadonlyArray<T[keyof T]> {
  return _entryCache.getOrAdd(
    enumType,
    () => getEnumKeys(enumType).map(key => (enumType[key]))
  );
}

export function isEnumKey<T extends EnumCtor>(enumType: T, value: any): value is keyof T {
  return getEnumKeys(enumType).includes(value);
}

export function isEnumValue<T extends EnumCtor>(enumType: T, value: any): boolean {
  return getEnumValues(enumType).includes(value);
}

/**
 * Returns true if parameter is one of the enum's values.
 *
 * @param enumType Enum name
 * @param value Value to test
 * @example isNumericEnum(Quantities, meter.quantityId)
 */
export function isNumericEnum<T>(enumType: NumericEnum<T>, value: any): value is T[keyof T] {
  return getNumericEnumValues(enumType).includes(value);
}

/**
 * Returns true if parameter is one of the enum's values.
 * @param enumType Enum name
 * @param value Value to test
 * @example isStringEnum(Roles, user.role)
 */
export function isStringEnum<T>(enumType: StringEnum<T>, value: any): value is T[keyof T] {
  return getStringEnumValues(enumType).includes(value);
}
