import _ from 'lodash';

const $inject = ['utils', 'kendo', 'erReportSettingsService'];

class EREventsService {
  constructor(utils, kendo, erReportSettingsService) {
    this.utils = utils;
    this.kendo = kendo;
    this.erReportSettingsService = erReportSettingsService;
  }

  createCategoryColumn(keyPrefix) {
    const title = this.getCategoryColumnTitle(keyPrefix);
    return {
      title: this.utils.localizedString(title),
      field: `Events_${keyPrefix}`,
      normalized: false,
      groupable: false,
      headerAttributes: { title },
      columns: keyPrefix === 'alarm' ? this.createAlarmChildColumns() : this.createActionChildColumns(keyPrefix)
    };
  }

  createAlarmChildColumns() {
    const columns = [
      {
        field: 'alarm$executedAt',
        title: this.utils.localizedString('FACILITIES_REPORT.OVERVIEW.ALARM_EXECUTED_AT'),
        width: 137,
        format: 'd',
        attributes: {
          style: 'text-align: right; padding-right: 1em;'
        }
      },
      {
        field: 'alarm$type',
        title: this.utils.localizedString('FACILITIES_REPORT.OVERVIEW.ALARM_TYPE'),
        width: 256,
        format: 's'
      },
      {
        field: 'alarm$statusName',
        title: this.utils.localizedString('ACTIONS.INVESTIGATION'),
        width: 160,
        format: 's',
        filterable: {
          multi: true
        }
      },
      {
        field: 'alarm$count',
        title: this.utils.localizedString('FACILITIES_REPORT.OVERVIEW.ALARMS_COUNT'),
        width: 128,
        attributes: {
          style: 'text-align: right; padding-right: 1em;'
        },
        filterable: false,
        actionType: 'alarm'
      }
    ];

    return columns.map(column => {
      const { field, format, actionType } = column;
      column.filterable = column.filterable !== undefined ? column.filterable : true;
      column.sort = 0;
      column.template = row => actionType
        ? this.getCountCellTemplate(actionType)
        : this.getCellTemplate(row, field, format);
      return column;
    });
  }

  createActionChildColumns(type) {
    const countTitle = type === 'action'
      ? 'FACILITIES_REPORT.OVERVIEW.ACTIONS_COUNT'
      : 'FACILITIES_REPORT.OVERVIEW.COMMENTS_COUNT';
    const columns = [
      {
        field: `${type}$effectStartsAt`,
        title: this.utils.localizedString('ACTIONS.EFFECTSTARTSAT'),
        width: 137,
        format: 'd',
        attributes: {
          style: 'text-align: right; padding-right: 1em;'
        }
      },
      {
        field: `${type}$reportedDescription`,
        title: this.utils.localizedString('FACILITIES_REPORT.OVERVIEW.TITLE'),
        width: 256,
        format: 's'
      },
      {
        field: `${type}$investigationName`,
        title: this.utils.localizedString('ACTIONS.INVESTIGATION'),
        width: 160,
        filterable: {
          multi: true
        }
      },
      {
        field: `${type}$count`,
        title: this.utils.localizedString(countTitle),
        width: 128,
        attributes: {
          style: 'text-align: right; padding-right: 1em;'
        },
        filterable: false,
        actionType: type
      }
    ];

    return columns.map(column => {
      const { field, format, actionType } = column;
      column.filterable = column.filterable !== undefined ? column.filterable : true;
      column.sort = 0;
      column.template = row => actionType
        ? this.getCountCellTemplate(actionType)
        : this.getCellTemplate(row, field, format);
      return column;
    });
  }

  hasSelectedOrNoQuantities(event) {
    const quantityIds = this.getSelectedQuantities();
    const showAllEvents = !quantityIds.length;
    const isFacilityEvent = !event.quantityIds.length;
    const hasSelectedQuantities = event.quantityIds.some(quantityId => quantityIds.includes(quantityId));
    return showAllEvents || isFacilityEvent || hasSelectedQuantities;
  }

  getSelectedQuantities() {
    return this.erReportSettingsService.getInstance().params.quantityId;
  }

  getCellTemplate(row, field, format) {
    let value = _.get(row, field);
    if (value && format === 'd') {
      value = new Date(value);
    }
    const kTemplate = this.kendo.template('<span>#= content #</span>');
    return kTemplate({
      content: this.kendo.toString(value || '', format)
    });
  }

  getCountCellTemplate(actionType) {
    // Kendo magic: dataItem links to actual row data
    return `<grid-report-notes-popup
      row="dataItem"
      type="'${actionType}'">
    </grid-report-notes-popup>`;
  }

  getCategoryColumnTitle(type) {
    switch (type) {
      case 'alarm':
        return 'FACILITIES_REPORT.OVERVIEW.ALARMS';
      case 'action':
        return 'FACILITIES_REPORT.OVERVIEW.ACTIONS';
      case 'comment':
        return 'FACILITIES_REPORT.OVERVIEW.COMMENTS';
    }
  }
}

EREventsService.$inject = $inject;

export default EREventsService;
