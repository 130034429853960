<kendo-grid
  [kendoGridBinding]="history"
  [pageSize]="pageSize"
  [loading]="loading"
  [sortable]="false"
  [pageable]="true"
  [resizable]="true"
>
  <kendo-grid-column
    field="from"
    title="{{ 'ADMIN.VIRTUAL_METERS.HISTORY.FROM' | translate }}"
    [width]="100"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <formula-effect-date [formula]="dataItem"></formula-effect-date>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="to"
    title="{{ 'ADMIN.VIRTUAL_METERS.HISTORY.TO' | translate }}"
    [width]="100"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <formula-effect-date [formula]="dataItem" [isToDate]="true"></formula-effect-date>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="lastModified"
    title="{{ 'ADMIN.VIRTUAL_METERS.HISTORY.LAST_MODIFIED' | translate }}"
    [width]="200"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ dataItem.lastModified | todate | date:'short' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="lastModifiedBy"
    title="{{ 'ADMIN.VIRTUAL_METERS.HISTORY.LAST_MODIFIED_BY' | translate }}"
    [width]="200"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="description"
    title="{{ 'ADMIN.VIRTUAL_METERS.HISTORY.EXPLANATION' | translate }}"
  >
  </kendo-grid-column>
  <kendo-grid-column [width]="75">
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <button
        class="button button--link button--no-focus icon"
        (click)="formulaEditClicked(this.dataItem)"
      >
        <i class="fas fa-pencil-alt"></i>
      </button>
      <span>&nbsp;</span>
      <button
        class="button button--negative button--link button--no-focus icon"
        (click)="formulaDeleteClicked(this.dataItem)"
      >
        <i class="fa fa-trash-alt"></i>
      </button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
