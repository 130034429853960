<ng-template #topbarTemplate>
  <div class="content-right float-right">
    <button
      class="button button--primary"
      (click)="openRoadmapEditModal()"
    >
      <i class="fa fa-plus"></i> {{ 'SUSTAINABILITY.TARGETS.CREATE' | translate }}
    </button>
  </div>
</ng-template>

<kendo-grid
  [kendoGridBinding]="roadmaps$ | async"
  [pageSize]="20"
  [pageable]="true"
  [resizable]="true"
  [groupable]="true"
  class="grid-full-height"
  [loadingSpinner]="loading$ | async"
>
  <kendo-grid-column
    [title]="'SUSTAINABILITY.TARGETS.NAME' | translate"
    field="name"
    [width]="400"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <a (click)="openRoadmapVisualizationModal(dataItem)">
        {{ dataItem.name }}
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-command-column [width]="40">
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <button
        kendoGridEditCommand
        (click)="openRoadmapEditModal(dataItem)"
        [ngfTooltip]="'SUSTAINABILITY.TARGETS.EDIT' | translate"
      ></button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column
    [title]="'SUSTAINABILITY.TARGETS.SCENARIO' | translate"
    field="scenario"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ dataItem.scenario | targetScenarioName }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [title]="'SUSTAINABILITY.TARGETS.BASE_YEAR' | translate"
    field="baseYear"
    [width]="150"
  ></kendo-grid-column>
  <kendo-grid-column
    [title]="'SUSTAINABILITY.TARGETS.TARGET_YEAR' | translate"
    field="targetYear"
    [width]="150"
  ></kendo-grid-column>
  <kendo-grid-column
    [title]="'SUSTAINABILITY.TARGETS.BASE_VALUE' | translate"
    field="baseTotal"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ dataItem.baseTotal !== null ? (dataItem.baseTotal | kendoNumber:targetValueFormat) : '' }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [title]="'SUSTAINABILITY.TARGETS.CURRENT' | translate"
    field="currentTotal"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <div class="current-column">
        <button
          type="button"
          class="k-button-icon fa-1x fas fa-pencil-alt"
          [title]="'SUSTAINABILITY.TARGETS.EDIT_CURRENT' | translate"
          (click)="openCurrentEditModal(dataItem)"
        ></button>
        <span *ngIf="dataItem.currentTotal !== null">
          {{ (dataItem.currentTotal | kendoNumber:targetValueFormat) + ' (' + dataItem.currentYear + ', ' }}
          <span [ngClass]="{ 'percentage-negative' : dataItem.currentPerCent > 0 }">{{ dataItem.currentPerCent | kendoNumber:percentageFormat }}</span>)
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [title]="'SUSTAINABILITY.TARGETS.TARGET' | translate"
    field="targetTotal"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      {{ 
        dataItem.targetTotal !== null 
        ? (dataItem.targetTotal | kendoNumber:targetValueFormat) + ' (' + (dataItem.targetPerCent | kendoNumber:percentageFormat) + ')'
        : '' 
      }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
