import * as PublicityStatus from '../../constants/publicity-status';

const $inject = ['ManualQaSearchListEditService', 'utils'];

function ManualQaSearchListEditController(ManualQaSearchListEditService, utils) {
  const vm = this;

  vm.constants = PublicityStatus;
  vm.publicityStatuses = ManualQaSearchListEditService.getPublicityStatuses();
  vm.selectedPublicityStatus = PublicityStatus.PRIVATE;
  vm.headerText = '';

  vm.$onInit = onInit;

  function onInit() {
    vm.searchList = angular.copy(vm.searchList);
    vm.originalSearchList = angular.copy(vm.searchList);

    if (vm.searchList) {
      vm.headerText = vm.searchList.isNew ?
        utils.localizedString('MQA.SEARCH_LIST.EDIT.TITLE_SAVE_SEARCH_LIST') :
        utils.localizedString('MQA.SEARCH_LIST.EDIT.TITLE_EDIT_SEARCH_LIST')
      ;

      if (vm.searchList.isNew) {
        vm.searchList.name = ManualQaSearchListEditService
          .getSearchListNameFromSearchCriteria(vm.searchList.getSearchCriteria())
        ;
      }
    }
  }
}

ManualQaSearchListEditController.$inject = $inject;

export default ManualQaSearchListEditController;
