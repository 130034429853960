<modal-view-header [headingText]="'ADMIN.BULK_MODIFY_METERS.ADD_POA_TO_METERS' | translate"></modal-view-header>
<div class="modal-body">
  <div>
    <h3>{{ 'ADMIN.POA.POA_SEARCH' | translate }}</h3>
    <form
      [formGroup]="poaSearchForm"
      class="bold-labels"
      singleInputEnabled
      (ngSubmit)="searchPoa()"
    >
      <div class="poa-search-form-inputs">
        <kendo-numerictextbox
          integerInput
          *labelBefore="'ADMIN.POA.POA_ID' | translate"
          [formControlName]="'poaId'"
        ></kendo-numerictextbox>
        <input
          type="text"
          kendoTextBox
          *labelBefore="'ADMIN.POA.POA_NAME' | translate"
          [formControlName]="'poaName'"
        >
        <company-search
          *labelBefore="'ADMIN.POA.COMPANY' | translate"
          [formControlName]="'company'"
        ></company-search>
        <input
          type="text"
          kendoTextBox
          *labelBefore="'ADMIN.POA.COMPANY_OVT' | translate"
          [formControlName]="'companyOvt'"
        >
      </div>
      <button
        type="submit"
        class="button"
      >
        {{ 'SEARCH' | translate }}
      </button>
    </form>
  </div>
  <div>
    <h3>{{ 'ADMIN.POA.SELECTED_POA_DETAILS' | translate }}</h3>
    <kendo-combobox
      #poaSelect
      [formControl]="poaControl"
      [data]="poas$ | async"
      [valueField]="'powerOfAttorneyId'"
      [textField]="'name'"
      [loading]="poasLoading$ | async"
    >
    </kendo-combobox>
    <table>
      <tbody>
        <tr>
          <td>{{ 'ADMIN.NAME' | translate  }}:</td>
          <td>{{ selectedPoa?.name }}</td>
        </tr>
        <tr>
          <td>{{ 'ADMIN.POA.EXTERNAL_LOCATION_LINK' | translate  }}:</td>
          <td>{{ selectedPoa?.externalLocationLink }}</td>
        </tr>
        <tr>
          <td>{{ 'ADMIN.POA.VALID_FROM' | translate  }}:</td>
          <td>{{ selectedPoa?.validFrom | kendoDate:'g' }}</td>
        </tr>
        <tr>
          <td>{{ 'ADMIN.POA.VALID_TO' | translate  }}:</td>
          <td>{{ selectedPoa?.validTo | kendoDate:'g' }}</td>
        </tr>
        <tr>
          <td>{{ 'ADMIN.POA.EXTERNAL_SERVICE' | translate  }}:</td>
          <td>{{ selectedPoa?.externalService | datahubServiceName }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <h3>{{ 'ADMIN.POA.COMPANY_SELECT' | translate }}</h3>
    <kendo-multicolumncombobox
      #companySelect
      [data]="selectableCompanies$ | async"
      [valueField]="'poaCompanyId'"
      [textField]="'companyName'"
      [formControl]="poaCompanyControl"
      [valuePrimitive]="true"
      [loading]="companiesLoading$ | async"
    >
      <kendo-combobox-column
        [field]="'companyName'"
        [title]="'ADMIN.NAME' | translate"
      >
      </kendo-combobox-column>
      <kendo-combobox-column
        [field]="'companyOvt'"
        [title]="'ADMIN.POA.COMPANY_OVT' | translate"
      >
      </kendo-combobox-column>
    </kendo-multicolumncombobox>
  </div>
  <div class="modal-footer">
    <div class="modal-footer-buttons float-right">
      <button
        class="button"
        (click)="save()"
        [disabled]="!poaCompanyControl.valid"
      >{{ 'SAVE' | translate }}</button>
    </div>
  </div>
</div>
