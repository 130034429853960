import { RelationalValueId } from '../constants/facilities-properties';

const relationalValueGroupingDefinitions = {
  costs: [
    RelationalValueId.NationalCosts,
    RelationalValueId.NationalRetailerCosts,
    RelationalValueId.NationalDistributionCosts,
    RelationalValueId.Costs,
    RelationalValueId.RetailerCosts,
    RelationalValueId.DistributionCosts,
    RelationalValueId.RetailerMonthlyFees,
    RelationalValueId.DistributionMonthlyFees,
    RelationalValueId.RetailerCombinedCosts,
    RelationalValueId.DistributionCombinedCosts,
    RelationalValueId.DistributionTaxes,
    RelationalValueId.MeterBasedAverageCost,
    RelationalValueId.MeterBasedRetailerAverageCost,
    RelationalValueId.MeterBasedDistributionAverageCost
  ],
  averageCosts: [
    RelationalValueId.MeterBasedAverageCost,
    RelationalValueId.MeterBasedRetailerAverageCost,
    RelationalValueId.MeterBasedDistributionAverageCost
  ],
  emissions: [RelationalValueId.co2Factor, RelationalValueId.co2NationalReference]
};

export function isCost(id: RelationalValueId): boolean {
  return relationalValueGroupingDefinitions.costs.includes(id);
}
export function isEmission(id: RelationalValueId): boolean {
  return relationalValueGroupingDefinitions.emissions.includes(id);
}
export function isAverageCost(id: RelationalValueId): boolean {
  return relationalValueGroupingDefinitions.averageCosts.includes(id);
}
