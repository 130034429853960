configurationApi.$inject = [
  'distributionTypes', 'errorCodes', 'quantities', 'relatedValues',
  'timeSeries', 'weather'
];

function configurationApi(
  distributionTypes, errorCodes, quantities, relatedValues, timeSeries, weather
) {
  return {
    distributionTypes: distributionTypes,
    errorCodes: errorCodes,
    quantities: quantities,
    relatedValues: relatedValues,
    timeSeries: timeSeries,
    weather: weather
  };
}

export default configurationApi;
