import { IAngularStatic } from 'angular';

import KendoModule from '../kendo';
import LodashModule from '../lodash';
import JQueryModule from '../jquery';
import MomentModule from '../moment';
import EnergyReportingModelsModule from '../energy-reporting/models';
import ReportingObjectsServicesModule from '../reportingobjects/services';
import EnerKeyServicesModule from '../../services';
import ConfigurationModelsModule from '../configuration/models';
import EnergyManagementConstantsModule from './constants';
import EnergyManagementComponentsModule from './components';
import EnergyManagementModelsModule from './models';
import EnergyManagementServicesModule from './services';
import config from './config';
import { actionField } from './directives/action-field';

declare const angular: IAngularStatic;

export default angular.module('EnergyManagement', [
  KendoModule.name,
  'kendo.directives',
  'pascalprecht.translate',
  'ngCookies',
  'ui.router',
  LodashModule.name,
  JQueryModule.name,
  MomentModule.name,
  EnergyReportingModelsModule.name,
  ReportingObjectsServicesModule.name,
  EnerKeyServicesModule.name,
  ConfigurationModelsModule.name,
  EnergyManagementConstantsModule.name,
  EnergyManagementComponentsModule.name,
  EnergyManagementModelsModule.name,
  EnergyManagementServicesModule.name
])
  .config(config)
  .directive('actionField', actionField)
;
