import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'email-content-form',
  templateUrl: './email-content-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailContentFormComponent {
  public readonly kendoEditorStyle = { height: '500px' };
  @Input() public form: UntypedFormGroup;
}
