<div>
  <a
    id="enerkey-logo"
    uiSref="dashboard"
    uiSrefActive="is-active"
  >
    <div
      class="enerkey-logo"
      [title]="logoTitle"
      [class.mobile-logo]="isMobile$ | async"
    ></div>
  </a>
</div>
<ng-container *ngIf="!(isMobile$ | async); else mobileTopbar">
  <!-- Tabs must be rendered somewhere as hidden for getting element widths -->
  <topbar-tab
    *ngFor="let tab of tabs$ | async"
    [state]="tab"
    #hiddenTab
    style="visibility: hidden; position: absolute;"
  ></topbar-tab>
  <div #tabContainer class="tab-container">
    <topbar-tab
      *ngFor="let tab of visibleTabs$ | async"
      [state]="tab"
    ></topbar-tab>
    <ng-container
      *ngIf="(overflowTabs$ | async) as overflowTabs"
    >
      <!-- Do not use ngIf, otherwise overflow button width cannot be determined -->
      <ek-dropdown
        [style.visibility]="!overflowTabs.length ? 'hidden' : 'visible'"
        #overflowButton
        [showOnHover]="true"
        class="tabs-overflow"
      >
        <a
          class="topbar-tab"
          *ekDropdownToggle
        >
          {{ 'MORE' | translate }}
          <i class="fas fa-caret-down"></i>
        </a>
        <topbar-tab
          *ngFor="let tab of overflowTabs"
          [state]="tab"
          [isOverflowTab]="true"
        ></topbar-tab>
      </ek-dropdown>
    </ng-container>
  </div>
  <div class="topbar-right">
    <topbar-search></topbar-search>
    <ng-container
      [ngTemplateOutlet]="topbarSettings"
      [ngTemplateOutletContext]="{ isMobile: false }"
    >
    </ng-container>
    <topbar-news></topbar-news>
    <topbar-settings [isMobile]="false"></topbar-settings>
  </div>
</ng-container>
<ng-template #mobileTopbar>
  <div class="topbar-right">
    <topbar-search [isMobile]="true"></topbar-search>
    <topbar-news></topbar-news>
    <a class="topbar-icon-item" (click)="toggleTopbar()">
      <i class="fa fa-bars"></i>
    </a>
  </div>
  <div
    #mobileTopbarOverflow
    class="mobile-topbar-content"
    *ngIf="mobileTopbarVisible$ | async"
  >
    <topbar-tab
      *ngFor="let tab of tabs$ | async"
      [state]="tab"
    >
    </topbar-tab>
    <ng-container
      [ngTemplateOutlet]="topbarSettings"
      [ngTemplateOutletContext]="{ isMobile: true }">
    </ng-container>
    <topbar-settings [isMobile]="true"></topbar-settings>
  </div>
</ng-template>
<ng-template #topbarSettings let-isMobile="isMobile">
  <access-token-copy
    *ngIf="isDev"
    [isMobile]="isMobile"
  ></access-token-copy>
  <topbar-facility-filter
    *ngIf="showFacilityFilter$ | async"
    [isMobile]="isMobile"
  ></topbar-facility-filter>
  <bookmarks-topbar-item
    *ngIf="hasServiceLevelMedium$ | async"
    [isMobile]="isMobile"
  ></bookmarks-topbar-item>
  <download-topbar-item
    *ngIf="!isMobile && (hasServiceLevelMedium$ | async)"
  ></download-topbar-item>
  <help [mobile]="isMobile"></help>
</ng-template>
