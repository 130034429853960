import { HttpParams } from '@angular/common/http';
import { AuthConfig } from 'angular-oauth2-oidc';

const queryParams = new HttpParams({ fromString: window.location.search });

const locale =
  queryParams.get('language') || // backwards compatibility
  queryParams.get('locale') ||
  window.localStorage.getItem('lang');

if (locale) {
  localStorage.setItem('lang', locale);
}

function getIdpParam(): string {
  const idpQueryParam = queryParams.get('idp');
  if (idpQueryParam) {
    return getIdpString(idpQueryParam);
  }

  // Handle possible request for Azure AD login
  if (queryParams.get('o365') !== null) {
    return getIdpString('aad');
  }

  // Handle tenant specific idp param (only works with helen=1 currently)
  const tenantIdp = getIdpProviderFromQueryString();

  return tenantIdp ? getIdpString(tenantIdp) : '';
}

function getIdpProviderFromQueryString(): string {
  const tenantIdps = ['helen'];

  for (const tenantIdp of tenantIdps) {
    if (queryParams.get(tenantIdp) !== null) {
      return tenantIdp;
    }
  }
}

function getIdpString(idpName: string): string {
  // space before word idp is required to separate parameters in acr_values
  return idpName
    ? ` idp:${idpName}`
    : ''
  ;
}

const tenant = window.location.hostname.split('.', 1)[0];
const idp = getIdpParam();

/* eslint-disable @typescript-eslint/naming-convention */
export const authCodeFlowConfig: AuthConfig = {
  issuer: ENERKEY_CONFIG.issuer,
  strictDiscoveryDocumentValidation: false,
  redirectUri: `${window.location.origin}/#/callback?`,
  silentRefreshRedirectUri: `${window.location.origin}/silentCallback.html?`,
  clientId: 'enerkeyportal',
  responseType: 'id_token token',
  scope: 'openid profile enerkeyapi enerkeyid usernotificationservices role introspection',
  customQueryParams: {
    acr_values: `tenant:${tenant}${idp}`,
    ui_locales: locale
  },
};
