import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, takeUntil } from 'rxjs/operators';

import { LoadingSubject } from '@enerkey/rxjs';
import { ModalService } from '@enerkey/foundation-angular';
import { CompanyFlatResponse } from '@enerkey/clients/contact';
import { UserViewModel } from '@enerkey/clients/user-management';

import { KendoGridService } from '../../../../shared/ek-kendo/services/kendo-grid.service';
import { CompaniesService } from '../../../../shared/services/companies.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { Roles } from '../../constants/roles';
import { RoleService } from '../../services/role.service';
import { UserManagementService } from '../../services/user-management.service';
import { UserActionLogModalComponent } from '../user-action-log-modal/user-action-log-modal.component';
import { UserOperationModalComponent } from '../user-operation-modal/user-operation-modal.component';
import { TelemetryService } from '../../../../services/telemetry.service';

interface IRoleColumn {
  field: string,
  title: string,
  abbreviation: string;
}

type RowSelectKey = 'id';

@Component({
  selector: 'user-management-grid',
  templateUrl: './user-management-grid.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KendoGridService],
})
export class UserManagementGridComponent implements AfterViewInit, OnDestroy {

  public readonly users$: Observable<UserViewModel[]>;
  public readonly roleCols$: Observable<IRoleColumn[]>;
  public readonly tenantsDataSource$: Observable<number[]>;
  public selectedUsers: number[] = [];
  public readonly selectKey: RowSelectKey = 'id';
  public readonly gridSelectableSettings: SelectableSettings = {
    checkboxOnly: true,
    enabled: true,
    mode: 'multiple',
  };
  public readonly loading$: Observable<boolean>;
  public readonly currentDate = new Date();

  @ViewChild(GridComponent) private readonly kendoGrid: GridComponent;

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    private readonly roleService: RoleService,
    private readonly toaster: ToasterService,
    private readonly companiesService: CompaniesService,
    private readonly translateService: TranslateService,
    private readonly modalService: ModalService,
    private readonly userManagementService: UserManagementService,
    private readonly telemetry: TelemetryService,
    private readonly gridService: KendoGridService<UserViewModel, RowSelectKey>
  ) {
    this.loading$ = combineLatest([
      this._loading$,
      this.userManagementService.loading$,
    ]).pipe(map(([a, b]) => a || b));

    this.users$ = combineLatest([
      this.userManagementService.searchResult$,
      this.roleService.getRoles(),
      this.companiesService.getCompanies(),
    ]).pipe(
      map(([users, roles, companies]) => {
        const accessToAllProfilesRoleId = roles.find(role => role.name === Roles.HAS_ACCESS_TO_ALL_PROFILES)?.id;
        users.forEach(user => {
          user.companyName = companies.find(c => c.id === user.companyId)?.name;
          user.availableRoles = this.getUserRoles(user, roles);
          user.nameOfFirstProfile = user.roleIds.includes(accessToAllProfilesRoleId)
            ? this.translateService.instant('ADMIN.ACCESS_TO_ALL_PROFILES')
            : user.nameOfFirstProfile;
        });
        if (!users.length) {
          this.toaster.info('ADMIN.NO_RESULTS_WITH_CURRENT_CRITERIA');
        }
        this.gridService.dataChanged(users);
        return users;
      }),
      catchError(err => {
        this.telemetry.trackError(err, 'UserManagementGridComponent.users$');
        this.toaster.info('ADMIN.NO_RESULTS_WITH_CURRENT_CRITERIA');
        return of([]);
      }),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.tenantsDataSource$ = this.users$.pipe(
      map(users => users.flatMap(u => u.tenants).unique())
    );

    this.roleCols$ = this.roleService.getRoles().pipe(
      map(roles =>
        roles.map(role => {
          const name = this.translateService.instant(`ADMIN.ROLE_NAME_${role.name.toUpperCase()}`);
          return {
            field: `availableRoles.${role.id}`,
            title: name,
            abbreviation: String.abbreviate(name),
          };
        })),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngAfterViewInit(): void {
    this.gridService.initialize(this.selectKey, this.kendoGrid);

    this.gridService.selection$.subscribe(keys => {
      this.selectedUsers = keys;
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public editUser(dataItem: UserViewModel): void {
    const modal = this.modalService.open(UserOperationModalComponent);
    modal.componentInstance.selectedUser = dataItem;
    modal.result
      .then(() => this.userManagementService.refresh())
      .catch(() => { });
  }

  public openActionLog(dataItem: UserViewModel): void {
    const modal = this.modalService.open(UserActionLogModalComponent);
    modal.componentInstance.selectedUser = dataItem;
  }

  private getUserRoles(user: UserViewModel, roleList: CompanyFlatResponse[]): Record<number, boolean> {
    return roleList.toRecord(s => s.id, s => user.roleIds.includes(s.id));
  }
}
