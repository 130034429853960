export enum SearchableType {
  Facility = 'FACILITIES.FACILITIES',
  Bookmark = 'BOOKMARK_WIDGET.BOOKMARKS',
}

interface SearchableBase {
  title: string;
  extraText?: string;
  searchableType: SearchableType;
}

export interface SearchableFacility extends SearchableBase {
  searchableType: SearchableType.Facility;
  facilityId: number;
}

export interface SearchableBookmark extends SearchableBase {
  searchableType: SearchableType.Bookmark;
  state: string;
  stateParams: Record<string, unknown>;
  modal: string;
}

export type EnerkeySearchable = SearchableFacility | SearchableBookmark;
