import { TreeViewItem } from '@enerkey/clients/metering';
import { PlaceToAdd } from './place-to-add';
import { Helpers } from '@enerkey/ts-utils';

export class FormulaItemSeeker {
  public static indexForNewItem(
    formula: ReadonlyArray<TreeViewItem>,
    itemAddContext: TreeViewItem,
    placeToAdd: PlaceToAdd
  ): number {
    let newIndex;

    const seeker = new FormulaItemSeeker(formula, itemAddContext);
    switch (placeToAdd) {
      case PlaceToAdd.Before:
        newIndex = seeker.indexForNewItemBefore();
        break;
      case PlaceToAdd.After:
        newIndex = seeker.indexForNewItemAfter();
        break;
      case PlaceToAdd.Inside:
        newIndex = seeker.indexForNewItemInside();
        break;
      default:
        Helpers.assertUnreachable(placeToAdd);
    }

    return newIndex || formula.length;
  }

  public static getDescendantItemIds(formula: TreeViewItem[], item: TreeViewItem): number[] {
    const seeker = new FormulaItemSeeker(formula, item);
    return seeker.findAllDescendantItemIds();
  }

  public static itemIndexInFormula(item: TreeViewItem, formula: ReadonlyArray<TreeViewItem>): number {
    return formula.findIndex(currentItem => currentItem.itemId === item.itemId);
  }

  private knownNonDescendantItems: Set<number> = new Set();
  private knownDescendantItems: Set<number> = new Set();

  private constructor(
    private formula: ReadonlyArray<TreeViewItem>,
    private newOrContextItem: TreeViewItem
  ) {
  }

  private indexForNewItemAfter(): number {
    let newIndex: number;
    for (let index = 0; index < this.formula.length; index++) {
      const currentItem = this.formula[index];
      if (this.isItemDescendantOfContextItem(currentItem)) {
        newIndex = index + 1;
      } else if (newIndex) {
        break;
      }
    }

    return newIndex;
  }

  private indexForNewItemBefore(): number {
    for (let index = 0; index < this.formula.length; index++) {
      const currentItem = this.formula[index];
      if (currentItem.itemId === this.newOrContextItem.itemId) {
        return index;
      }
    }
  }

  private indexForNewItemInside(): number {
    let newIndex: number;
    for (let index = this.formula.length - 1; index >= 0; index--) {
      const currentItem = this.formula[index];
      if (this.isItemDescendantOfContextItem(currentItem)) {
        newIndex = index + 1;
        break;
      }
    }

    return newIndex;
  }

  private findAllDescendantItemIds(): number[] {
    const descendantIds: Set<number> = new Set();
    this.formula.forEach(item => {
      if (this.isItemDescendantOfContextItem(item)) {
        descendantIds.add(item.itemId);
      }
    });

    return [...descendantIds.values()];
  }

  private isItemDescendantOfContextItem(itemToCheck: TreeViewItem): boolean {
    if (!itemToCheck) {
      return false;
    }
    if (this.isDescendantOrKnownDescendant(itemToCheck)) {
      this.knownDescendantItems.add(itemToCheck.itemId);
      return true;
    }

    if (this.isRootOrKnownNotDescendant(itemToCheck)) {
      this.knownNonDescendantItems.add(itemToCheck.itemId);
      return false;
    }

    const nextItemToCheck = this.formula.find(item => item.itemId === itemToCheck.parentId);

    return this.isItemDescendantOfContextItem(nextItemToCheck);
  }

  private isDescendantOrKnownDescendant(itemToCheck: TreeViewItem): boolean {
    return itemToCheck.itemId === this.newOrContextItem.itemId ||
      this.knownDescendantItems.has(itemToCheck.itemId) ||
      this.knownDescendantItems.has(itemToCheck.parentId);
  }

  private isRootOrKnownNotDescendant(itemToCheck: TreeViewItem): boolean {
    return itemToCheck.parentId === null ||
      this.knownNonDescendantItems.has(itemToCheck.itemId) ||
      this.knownNonDescendantItems.has(itemToCheck.parentId);
  }
}
