import moment from 'moment';

import { hasChanges } from '@enerkey/angular-utils';

import TimeFrame from '../../services/time-frame-service';
import { CUSTOM_TIME_SELECTIONS, INTERVAL } from './time-frame-select-constant';

/**
 * TimeFrame select component that handles from - to times using kendo date picker.
 *
 * Usage:
 *  <time-frame-select
 *    time-frame="timeFrameObject"
 *    on-change="vm.onChange(timeFrame)"
 *    include-time="false"
 *    is-disabled="true"
 *    min="date"
 *    max="date"
 *    interval="number"
 *    use-custom-time-selections="true"
 * ></time-frame-select>
 *
 * @constructor
 */
class TimeFrameSelectController {
  constructor() {
    this.timeFrame = null;
    this.options = {
      min: null,
      max: null,
      interval: INTERVAL
    };
    this.min = null;
    this.max = null;
    this.fromDate = null;
    this.toDate = null;
    this.timeBetween = 0;
    this.isLinked = false;
    this.interval = null;
    this.hideToDate = false;
    this.isDisabled = false;
    this.useCustomTimeSelections = true;
  }

  $onInit() {
    this.options.min = this.min ? angular.copy(this.min).toDate() : moment({ year: 1970, month: 1, day: 1 }).toDate();
    this.options.max = this.max ? angular.copy(this.max).toDate() : moment({ year: 2100, month: 1, day: 1 }).toDate();
    this.options.interval = this.interval || INTERVAL;
    if (this.useCustomTimeSelections) {
      this.options.open = this.openPopupWith(CUSTOM_TIME_SELECTIONS);
    }

    this.timeFrame = angular.copy(this.timeFrame);
    if (this.timeFrame) {
      this.fromDate = this.timeFrame.getFromDate().toDate();
      this.toDate = this.timeFrame.getToDate().toDate();
      this.timeBetween = this.timeFrame.getUnitsBetween('seconds');
    }
  }

  $onChanges(changes) {
    if (hasChanges(changes, 'timeFrame')) {
      this.timeFrame = angular.copy(changes.timeFrame.currentValue);
      this.timeBetween = this.timeFrame.getUnitsBetween('seconds');

      this.fromDate = this.timeFrame ? this.timeFrame.getFromDate().toDate() : null;
      this.toDate = this.timeFrame ? this.timeFrame.getToDate().toDate() : null;
    }

    if (hasChanges(changes, 'min')) {
      this.options.min = moment.isMoment(changes.min.currentValue) ? changes.min.currentValue.toDate() : null;

      if (this.fromDate < this.options.min) {
        this.fromDate = this.options.min;
      }

      if (this.toDate < this.options.min) {
        this.toDate = this.options.min;
      }
    }

    if (hasChanges(changes, 'max')) {
      this.options.max = moment.isMoment(changes.max.currentValue) ? changes.max.currentValue.toDate() : null;

      if (this.fromDate > this.options.max) {
        this.fromDate = this.options.max;
      }

      if (this.toDate > this.options.max) {
        this.toDate = this.options.max;
      }
    }

    if (hasChanges(changes, 'interval')) {
      this.options.interval = changes.interval.currentValue || INTERVAL;
    }
  }

  handleFromDateChange() {
    if (this.isLinked) {
      this.toDate = angular
        .copy(moment(this.fromDate))
        .add(this.timeBetween, 'seconds')
        .toDate();
    } else {
      if (this.fromDate > this.toDate) {
        this.toDate = angular.copy(this.fromDate).toDate();
      }
    }

    this.timeFrame = new TimeFrame(this.fromDate, this.toDate);
    this.timeBetween = this.timeFrame.getUnitsBetween('seconds');

    this.onChange({ timeFrame: this.timeFrame });
  }

  handleToDateChange() {
    if (this.isLinked) {
      this.fromDate = angular
        .copy(moment(this.toDate))
        .subtract(this.timeBetween, 'seconds')
        .toDate();
    } else {
      if (this.toDate < this.fromDate) {
        this.fromDate = angular.copy(this.toDate).toDate();
      }
    }

    this.timeFrame = new TimeFrame(this.fromDate, this.toDate);
    this.timeBetween = this.timeFrame.getUnitsBetween('seconds');

    this.onChange({ timeFrame: this.timeFrame });
  }

  handleLinkChange(isLinked) {
    this.isLinked = !!isLinked;
  }

  /**
   * Return a custom function for opening the time popup with custom times.
   *
   * @see https://docs.telerik.com/kendo-ui/controls/editors/datetimepicker/how-to/override-hours
   * @param {Array} times
   */
  openPopupWith(times) {
    return e => {
      if (e.view === 'time') {
        e.sender.timeView.dataBind(times);
      }
    };
  }
}

export default TimeFrameSelectController;
