import { InspectionDefectItem } from '@enerkey/clients/manual-qa';
import { emailInfoProperties, InfoToSendForm } from '../components/email-send-modal/email-functions';

export class BucketDateDefectGroup {
  public static getEmailString(
    dateString: string,
    defects: InspectionDefectItem[],
    info: InfoToSendForm
  ): string {
    return new BucketDateDefectGroup(dateString, defects, info).bucketGroupString;
  }

  private constructor(
    private readonly dateString: string,
    private readonly defects: readonly InspectionDefectItem[],
    private readonly info: InfoToSendForm
  ) {
  }

  public get bucketGroupString(): string {
    const defectRows = this.defects
      .map(defect => this.getSingleDefectRow(defect))
      .join('<br/>')
    ;
    return `${this.dateString}<br/>${defectRows}`;
  }

  private getSingleDefectRow(defect: InspectionDefectItem): string {
    return emailInfoProperties.reduce((emailProperties: string[], property: keyof InfoToSendForm) => {
      let propertyValueAsString: string = '';
      if (this.info[property]) {
        const propertyValue = defect[property as keyof InspectionDefectItem];
        propertyValueAsString = (propertyValue || '').toString().trim();
        return [...emailProperties, propertyValueAsString];
      }
      return emailProperties;
    }, []).join(';').trim();
  }
}
