<kendo-grid
  [kendoGridBinding]="profiles$ | async"
  [pageSize]="30"
  [pageable]="true"
  [sortable]="true"
  [resizable]="true"
  [selectable]="gridSelectableSettings"
  [selectedKeys]="selectedProfiles"
  [kendoGridSelectBy]="selectKey"
  [loading]="loading$ | async"
  [filterable]="'menu'"
  (edit)="editProfile($event)"
  class="grid-full-height"
>
  <kendo-grid-excel
    [fileName]="'ADMIN.PROFILES' | translate | excelFileName:currentDate"
    [excelDataSource]="profiles$ | async"
  ></kendo-grid-excel>

  <ng-template kendoGridToolbarTemplate>
    <button kendoGridExcelCommand>{{ 'ADMIN.GET_EXCEL' | translate }}</button>
  </ng-template>

  <kendo-grid-checkbox-column
    [showSelectAll]="true"
    [locked]="true"
    [width]="28"
  >
    <kendo-grid-checkbox-header *kendoGridHeaderTemplate></kendo-grid-checkbox-header>
  </kendo-grid-checkbox-column>

  <kendo-grid-column
    field="id"
    [title]="'ADMIN.ID' | translate"
    [filterable]="true"
    [locked]="true"
    [width]="100"
    filter="numeric"
  ></kendo-grid-column>

  <kendo-grid-column
    field="name"
    [title]="'ADMIN.NAME' | translate"
    [filterable]="true"
    [locked]="true"
    [width]="500"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <span *ngIf="currentProfileId !== dataItem.id; else currentProfileName">
        {{ dataItem.name }}
      </span>
      <ng-template #currentProfileName>
        <strong>{{ dataItem.name }}</strong>
      </ng-template>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [locked]="true"
    [width]="40"
  >
    <ng-template kendoGridCellTemplate>
      <button kendoGridEditCommand></button>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    [locked]="true"
    [width]="40"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <button
        *ngIf="currentProfileId !== dataItem.id; else isCurrentProfile"
        class="button icon link"
        [title]="'SETTINGS.CLICK_TO_CHANGE_PROFILE' | translate"
        (click)="changeProfile(dataItem)"
      >
        <i class="fa fa-arrow-right"></i>
      </button>
      <ng-template #isCurrentProfile>
        <button
          class="button icon link"
          disabled
          [title]="'SETTINGS.CURRENT_PROFILE' | translate"
        >
          <i class="fa fa-arrow-right"></i>
        </button>
      </ng-template>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="facilityCount"
    [title]="'ADMIN.FACILITY_COUNT' | translate"
    [filterable]="true"
    [locked]="true"
    [width]="100"
    filter="numeric"
  ></kendo-grid-column>

  <kendo-grid-column
    field="companyName"
    [title]="'ADMIN.COMPANY' | translate"
    [filterable]="true"
    [width]="120"
  ></kendo-grid-column>

  <kendo-grid-column-group
    *ngFor="let group of serviceGroups$ | async"
    [title]="group.value"
  >
    <kendo-grid-column
      *ngFor="let col of group.items"
      [field]="col.field"
      [title]="col.title"
      [filterable]="true"
      [width]="100"
      filter="boolean"
    >
      <span
        *kendoGridHeaderTemplate
        [title]="col.title"
      >
        {{ col.abbreviation }}
      </span>
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-column="column"
      >
        <boolean-mark [value]="dataItem | propertyPath:column?.field"></boolean-mark>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid-column-group>

  <kendo-grid-column
    field="description"
    [title]="'ADMIN.TERMINAL.DESCRIPTION' | translate"
    [filterable]="true"
    [width]="100"
  ></kendo-grid-column>

  <kendo-grid-column
    field="externalCustomerId"
    [title]="'ADMIN.PROFILE_CUSTOMER_ID' | translate"
    [filterable]="true"
    [width]="100"
  ></kendo-grid-column>

  <kendo-grid-column
    field="productId"
    [title]="'ADMIN.PRODUCT_ID' | translate"
    [filterable]="true"
    [width]="100"
    filter="numeric"
  ></kendo-grid-column>

  <kendo-grid-column
    field="serviceLevelName"
    [title]="'ADMIN.SERVICE_LEVEL' | translate"
    [filterable]="true"
    [width]="100"
  ></kendo-grid-column>

  <kendo-grid-column
    field="createdBy"
    [title]="'CREATED_BY' | translate"
    [filterable]="true"
    [width]="100"
  ></kendo-grid-column>

  <kendo-grid-column
    field="created"
    format="g"
    [title]="'CREATED' | translate"
    [filterable]="true"
    [width]="130"
    filter="date"
  ></kendo-grid-column>

  <kendo-grid-column
    field="lastModifiedBy"
    [title]="'LAST_MODIFIED_BY' | translate"
    [filterable]="true"
    [width]="100"
  ></kendo-grid-column>

  <kendo-grid-column
    field="lastModified"
    format="g"
    [title]="'LAST_MODIFIED' | translate"
    [filterable]="true"
    [width]="130"
    filter="date"
  ></kendo-grid-column>
</kendo-grid>
