import ManualQaSearchQaComponent from './manual-qa-search-qa-component';

const dependencies = [];

const ManualQaSearchQaModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-qa', dependencies)
  .component('manualQaSearchQa', ManualQaSearchQaComponent)
;

export default ManualQaSearchQaModule;
