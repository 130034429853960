import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { OutgoingRequestLogSearchCriteria } from '@enerkey/clients/webhook';

@Injectable()
export class OutgoingMessagesService implements OnDestroy {
  public readonly searchParams$: Observable<OutgoingRequestLogSearchCriteria>;

  private readonly _searchParams$ = new Subject<OutgoingRequestLogSearchCriteria>();

  public constructor() {
    this.searchParams$ = this._searchParams$.asObservable();
  }

  public ngOnDestroy(): void {
    this._searchParams$.complete();
  }

  public search(params: OutgoingRequestLogSearchCriteria): void {
    this._searchParams$.next(params);
  }
}
