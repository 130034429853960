import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { Transition } from '@uirouter/core';

import { MqaClient, ReadingSourceType, SearchList } from '@enerkey/clients/manual-qa';

import { EnerkeyNg2StateDeclaration } from '../../shared/routing';
import { ManualQaSearchWrapperComponent } from './components/manual-qa-search-wrapper/manual-qa-search-wrapper.component';
import { ManualQaSearchListsWrapperComponent } from './components/manual-qa-search-lists-wrapper/manual-qa-search-lists-wrapper.component';
import { ManualQaIndexWrapperComponent } from './components/manual-qa-index-wrapper/manual-qa-index-wrapper.component';
import { manualQaTopbarState } from './manual-qa-topbar-state';

export const SEARCH_LIST_STATE = 'mqa.search-list';
export const SEARCH_STATE = 'mqa.search';

function resolveSearchLists(mqaClient: MqaClient): Promise<unknown> {
  return firstValueFrom(mqaClient.getSearchLists());
}

function resolveSearchList(transition: Transition): Promise<SearchList> {
  return Promise.resolve(SearchList.fromJS(transition.params().searchList));
}

function resolveReaderTypes(mqaClient: MqaClient): Promise<ReadingSourceType[]> {
  return firstValueFrom(mqaClient.getReadingSourceTypes().pipe(
    map(types => types.sortBy('name'))
  ));
}

type MqaStateDeclaration = EnerkeyNg2StateDeclaration & {
  title?: string;
}

const mqaState: MqaStateDeclaration = {
  ...manualQaTopbarState,
  parent: 'index',
  url: '/mqa',
  views: {
    'app@': {
      component: ManualQaIndexWrapperComponent
    }
  },
  redirectTo: SEARCH_LIST_STATE
};

export const mqaStates: MqaStateDeclaration[] = [
  mqaState,
  {
    name: SEARCH_LIST_STATE,
    url: '/search-list/',
    title: 'MQA.VIEWS.SEARCH_LIST',
    views: {
      content: {
        component: ManualQaSearchListsWrapperComponent
      }
    },
    resolve: [
      {
        provide: 'searchLists',
        deps: [MqaClient],
        useFactory: resolveSearchLists
      }
    ]
  },
  {
    name: SEARCH_STATE,
    url: '/search/',
    title: 'MQA.VIEWS.SEARCH',
    views: {
      content: {
        component: ManualQaSearchWrapperComponent
      }
    },
    resolve: [
      {
        provide: 'readerTypes',
        deps: [MqaClient],
        useFactory: resolveReaderTypes
      },
      {
        provide: 'searchList',
        deps: [Transition],
        useFactory: resolveSearchList
      },
    ],
    params: {
      searchList: null,
      readerTypes: []
    },
    data: {
      export: {
        enabled: true
      }
    }
  }
];
