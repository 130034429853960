import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, shareReplay, takeUntil } from 'rxjs/operators';

import { AlarmClient, AlarmTypeEnum } from '@enerkey/clients/alarm';
import { AttachmentsClient } from '@enerkey/clients/attachments';

import { AlarmLogGridItem } from '../../shared/alarm-log-grid-item';
import { ToasterService } from '../../../services/toaster.service';
import { EmActionEditService } from '../../../../modules/energy-management/components/em-action-edit/em-action-edit-service';
import { UserService } from '../../../../services/user-service';
import { Service } from '../../../../constants/service';
import {
  ACTION_OPERATIONS,
  ActionOperationType
} from '../../../../modules/energy-management/constants/em-actions.constants';

@Component({
  selector: 'alarm-info',
  templateUrl: './alarm-info.component.html',
  styleUrls: ['./alarm-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmInfoComponent implements OnInit, OnDestroy {
  @Input() public alarm: AlarmLogGridItem;

  public image$: Observable<string>;
  public loadingError$ = new Subject<boolean>();
  public isImageShown: boolean;
  public userHasCommentsService: boolean = false;

  private readonly _destroy = new Subject<void>();

  public constructor(
    private readonly alarmClient: AlarmClient,
    private readonly toaster: ToasterService,
    @Inject('emActionEditService') private readonly emActionEditService: EmActionEditService,
    private readonly userService: UserService,
    private readonly attachmentClient: AttachmentsClient,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.userHasCommentsService = this.userService.hasService(Service.Comments);
    this.isImageShown = this.alarm.alarmTypeId !== AlarmTypeEnum.ExternalAlarm;

    if (this.isImageShown) {
      this.image$ = this.alarmClient.getImageForLog(this.alarm.id).pipe(
        catchError(() => {
          this.toaster.error('ALARM.LOG.DETAILS.IMAGE_REQUEST_FAILED');
          this.loadingError$.next(true);
          return of('');
        }),
        shareReplay(1),
        takeUntil(this._destroy)
      );
    } else {
      this.image$ = of('');
    }
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this.loadingError$.complete();
  }

  public toComment(): void {
    this.alarmClient.createEnergyConsumptionComment(
      this.alarm.id,
      this.userService.profileId
    ).subscribe(log => {
      this.alarm.actionId = log.actionId;
      this.changeDetectorRef.detectChanges();
      this.showComment(log.actionId);
    });
  }

  public showComment(actionId: number): void {
    this.attachmentClient.getAction(
      actionId
    ).subscribe(model => {
      const modalRef = this.emActionEditService.getModal(model);
      modalRef
        .then((result: { operation: ActionOperationType }) => {
          if (result.operation === ACTION_OPERATIONS.DELETE) {
            this.alarm.actionId = null;
            this.changeDetectorRef.detectChanges();
          }
        })
        .catch(
          /* istanbul ignore next */
          () => {}
        );
    });
  }
}
