import {
  Component, OnDestroy,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  Observable,
  of as off,
  Subject,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import moment from 'moment';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

import { ModalBase, ModalOptions, ModalService, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate } from '@enerkey/rxjs';
import { formGroupFrom } from '@enerkey/ts-utils';
import { Facility, FacilityClient } from '@enerkey/clients/facility';
import { ContractClient, CreateContractProduct } from '@enerkey/clients/contract';

import { kendoNumericTextboxOptions } from '../../models/constants';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { localToUtc } from '../../../../shared/date.functions';
import {
  ContractFields,
  ContractInputDialogComponent,
} from '../contract-input-dialog/contract-input-dialog.component';

export interface CreateContractForm {
  facilityIds: number[];
  contractId: string;
  productId: string;
  unitPrice: number;
  unitCount: number;
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'contract-create-modal',
  templateUrl: './contract-create-modal.component.html',
  styleUrls: ['./contract-create-modal.component.scss'],
})
@ModalOptions({ size: 'large' })
export class ContractCreateModalComponent extends ModalBase implements OnDestroy {

  public readonly numberInputOpts: Partial<NumericTextBoxComponent> = kendoNumericTextboxOptions;

  public facilities: Facility[] = [];

  public readonly formArray: UntypedFormArray;
  public readonly formGroup: UntypedFormGroup;

  public get isLoading$(): Observable<boolean> {
    return this._isLoading;
  }

  private readonly _isLoading = new Subject<boolean>();

  public constructor(
    activeModal: NgfActiveModal,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modalService: ModalService,
    private readonly contractClient: ContractClient,
    private readonly facilityClient: FacilityClient,
    private readonly toaster: ToasterService
  ) {
    super(activeModal);

    this.formArray = this.formBuilder.array([this.getInputRow()]);
    this.formGroup = this.formBuilder.group({ rows: this.formArray });
  }

  public ngOnDestroy(): void {
    this._isLoading.complete();
  }

  public dismiss(): void {
    super.dismissModal();
  }

  public onSubmit(): void {
    this.getCreateTasks()
      .pipe(indicate(this._isLoading))
      .subscribe(() => super.closeModal());
  }

  public addRow(): void {
    this.formArray.push(this.getInputRow());
  }

  public removeRow(index: number): void {
    this.formArray.removeAt(index);
  }

  public rowEdit(field: keyof ContractFields): void {
    const modal = this.modalService.open(ContractInputDialogComponent);
    modal.componentInstance.editedField = field;
    modal.result.then(
      (value: string | number | Date) => {
        for (let i = 0; i < this.formArray.length; i++) {
          this.formArray.at(i).patchValue({ [field]: value });
        }
      },
      () => { }
    );
  }

  public setFacilities(facilityIds: number[]): void {
    this.facilityClient.getFacilities(facilityIds)
      .pipe(indicate(this._isLoading))
      .subscribe(facilities => {
        this.facilities = facilities;
        this.formArray.controls.forEach(
          ctrl => ctrl.patchValue({ facilityIds: facilities.map(f => f.id) })
        );
      });
  }

  private getCreateTasks(): Observable<boolean> {
    const formValues: CreateContractForm[] = this.formGroup.value.rows;

    const contracts = formValues.flatMap(row => row.facilityIds.map(
      facilityId => new CreateContractProduct({
        facilityId,
        contractId: row.contractId,
        productId: row.productId,
        unitPrice: row.unitPrice,
        unitCount: row.unitCount,
        fromDate: row.fromDate && moment(localToUtc(row.fromDate)),
        toDate: row.toDate && moment(localToUtc(row.toDate)),
      })
    ));

    return this.contractClient.addContracts(contracts).pipe(
      map(() => true),
      catchError(() => {
        this.toaster.error('ADMIN.CONTRACTS.CREATE_FAILED');
        return off(false);
      })
    );
  }

  private getInputRow(): UntypedFormGroup {
    return formGroupFrom<CreateContractForm>({
      facilityIds: this.facilities.map(f => f.id),
      contractId: null,
      productId: null,
      unitPrice: null,
      unitCount: null,
      fromDate: null,
      toDate: null
    }, {
      facilityIds: Validators.required,
      contractId: Validators.required,
      productId: Validators.required,
      fromDate: Validators.required,
      unitCount: Validators.min(0),
      unitPrice: Validators.min(0),
    });
  }
}
