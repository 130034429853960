import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TooltipPlacement } from '@enerkey/foundation-angular';

@Component({
  selector: 'ek-tooltip-indicator',
  templateUrl: './ek-tooltip-indicator.component.html',
  styleUrls: ['./ek-tooltip-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkTooltipIndicatorComponent {
  @Input() public tooltip: string;
  @Input() public placement: TooltipPlacement = 'top';
}
