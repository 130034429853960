import { ErReportTypeConfig, ErReportTypeGroupConfig } from '../shared/er-report-type-config';
import { ErReportType } from './er-report-type';
import { ErReportTypeGroupName, ErReportTypeName } from './er-report-type-name';
import { ErSidebarSection } from './er-sidebar-section';
import { ServiceLevel } from '@enerkey/clients/facility';

export const FACILITY_OVERVIEW: ErReportTypeConfig = {
  name: ErReportTypeName.FacilityOverview,
  titleKey: 'FACILITIES_REPORT.OVERVIEW.NAME',
  download: {
    enabled: false
  }
};

export const FACILITY_REPORT: ErReportTypeConfig = {
  name: ErReportTypeName.FacilityReport,
  titleKey: 'FACILITIES.REPORT',
  download: {
    enabled: true,
    exportType: 'server'
  }
};

export const FACILITY_TREND: ErReportTypeConfig = {
  name: ErReportTypeName.FacilityTrendReport,
  titleKey: 'FACILITIES.TREND',
  reportType: ErReportType.TREND_REPORT,
  requiresServiceLevel: ServiceLevel.Large,
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.TREND_REPORT'
  }
};

export const FACILITY_FORECAST: ErReportTypeConfig = {
  name: ErReportTypeName.FacilityForecastReport,
  titleKey: 'FACILITIES.FORECAST',
  reportType: ErReportType.FORECAST_REPORT,
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.FORECAST_REPORT'
  }
};

export const FACILITY: ErReportTypeGroupConfig = {
  name: ErReportTypeGroupName.Facility,
  visibleSections: [ErSidebarSection.FacilityInfo, ErSidebarSection.Graphs, ErSidebarSection.Tables],
  defaultReport: FACILITY_REPORT,
  reportType: ErReportType.DEFAULT_REPORT,
  reports: [
    FACILITY_OVERVIEW,
    FACILITY_REPORT,
    FACILITY_TREND,
    FACILITY_FORECAST
  ]
};

export const METER_GRID: ErReportTypeConfig = {
  name: ErReportTypeName.MeterTreelist,
  titleKey: 'FACILITIES.GRID',
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.TREELIST'
  }
};

export const METER_REPORT: ErReportTypeConfig = {
  name: ErReportTypeName.MeterReport,
  titleKey: 'FACILITIES.REPORT',
  download: {
    enabled: true,
    exportType: 'server'
  }
};

export const METER_SUM: ErReportTypeConfig = {
  name: ErReportTypeName.MeterSumReport,
  titleKey: 'FACILITIES.SUM',
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.SUM_REPORT'
  }
};

export const METER_TREND: ErReportTypeConfig = {
  name: ErReportTypeName.MeterTrendReport,
  titleKey: 'FACILITIES.TREND',
  reportType: ErReportType.TREND_REPORT,
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.TREND_REPORT'
  }
};

export const METER_TREND_SUM: ErReportTypeConfig = {
  name: ErReportTypeName.MeterTrendSumReport,
  reportType: ErReportType.TREND_REPORT,
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.TREND_REPORT'
  }
};

export const METER_COMPARISON: ErReportTypeConfig = {
  name: ErReportTypeName.MeterComparisonReport,
  titleKey: 'FACILITIES.COMPARISON',
  download: {
    enabled: false
  }
};

export const METER_FORECAST: ErReportTypeConfig = {
  name: ErReportTypeName.MeterForecastReport,
  titleKey: 'FACILITIES.FORECAST',
  reportType: ErReportType.FORECAST_REPORT,
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.FORECAST_REPORT'
  }
};

export const METER_FORECAST_SUM: ErReportTypeConfig = {
  name: ErReportTypeName.MeterForecastSumReport,
  reportType: ErReportType.FORECAST_REPORT,
  download: {
    enabled: true,
    fileNameKey: 'FACILITIES.FORECAST_REPORT'
  }
};

export const METER: ErReportTypeGroupConfig = {
  name: ErReportTypeGroupName.Meter,
  visibleSections: [ErSidebarSection.MeterDetails, ErSidebarSection.Graphs, ErSidebarSection.Tables],
  defaultReport: METER_REPORT,
  reportType: ErReportType.DEFAULT_REPORT,
  reports: [
    METER_GRID,
    METER_REPORT,
    METER_SUM,
    METER_TREND,
    METER_TREND_SUM,
    METER_COMPARISON,
    METER_FORECAST
  ]
};

export const REPORT_GROUPS = [
  FACILITY,
  METER
];

export const ALL_REPORTS = [
  FACILITY_OVERVIEW,
  FACILITY_REPORT,
  FACILITY_TREND,
  FACILITY_FORECAST,
  METER_GRID,
  METER_REPORT,
  METER_SUM,
  METER_TREND,
  METER_COMPARISON,
  METER_FORECAST
];
