import template from 'raw-loader!../templates/sidebar-item-loading.html';

export default function() {
  return {
    restrict: 'A',
    replace: true,
    scope: {
      loading: '=sidebarItemLoading'
    },
    template: template,
  };
}
