const $inject = ['$element', '$timeout'];

function DropdownController($element, $timeout) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  self.defaultSettings = {
    dropdownClass: 'ek-dropdown',
    containerClass: 'ek-dropdown-container',
    menuClass: 'ek-dropdown-menu',
    width: 250
  };

  self.showDropdown = false;

  self.$onInit = onInit;
  self.$postLink = postLink;
  self.onMouseOver = onMouseOver;
  self.onMouseLeave = onMouseLeave;
  self.onMouseClick = onMouseClick;

  function onInit() {
    self.showOnHover = self.showOnHover || false;
    self.settings = self.settings || {};
    self.noOffset = self.noOffset || false;
    self.defaultSettings = { ...self.defaultSettings, ...self.settings };
  }

  function postLink() {
    $timeout(() => {
      const container = $element.find(`.${ self.defaultSettings.containerClass}`);
      const menu = container.find(`.${ self.defaultSettings.menuClass}`);
      const offset = self.noOffset ? 0 : container.width();
      const marginLeft = Math.floor(self.defaultSettings.width - (offset / 2));

      menu.css('width', `${self.defaultSettings.width }px`);
      menu.css('marginLeft', `${-marginLeft }px`);
    }, 0);
  }

  function onMouseOver() {
    if (self.showOnHover) {
      self.showDropdown = true;
    }
  }

  function onMouseLeave() {
    if (self.showOnHover) {
      self.showDropdown = false;
    }
  }

  function onMouseClick() {
    if (self.showOnHover) {
      return;
    }

    self.showDropdown = !self.showDropdown;
  }
}

DropdownController.$inject = $inject;

export default DropdownController;
