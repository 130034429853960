import ManualQaSearchMeteringPointsComponent from './manual-qa-search-metering-points-component';

const dependencies = [];

const ManualQaSearchMeteringPointsModule = angular
  .module('app.modules.manual-qa.components.manual-qa-search-metering-points', dependencies)
  .component('manualQaSearchMeteringPoints', ManualQaSearchMeteringPointsComponent)
;

export default ManualQaSearchMeteringPointsModule;
