import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';

import { getStringEnumValues } from '@enerkey/ts-utils';

import { DashboardOperation } from '../../shared/dashboard-operation';
import { DialogService } from '../../../../shared/services/dialog.service';
import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';

export enum DashboardType {
  EmptyDashboard = 'Empty Dashboard',
  PortfolioDashboard = 'Portfolio Dashboard',
  FacilityDashboard = 'Facility Dashboard'
}

type DashboardTypeOptionsForm = {
  title: FormControl<string>;
  dashboardTypes: FormControl<DashboardType>;
  facilityId: FormControl<number>;
}

const dashboardTypeTranslationKeys: Record<DashboardType, string> = {
  [DashboardType.EmptyDashboard]: 'DASHBOARD_TYPE.EMPTY_DASHBOARD',
  [DashboardType.PortfolioDashboard]: 'DASHBOARD_TYPE.PORTFOLIO_DASHBOARD',
  [DashboardType.FacilityDashboard]: 'DASHBOARD_TYPE.FACILITY_DASHBOARD'
};
@Component({
  selector: 'dashboard-edit-modal',
  templateUrl: './dashboard-edit-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'content', windowClass: 'modal-dialog-scrollable dashboard-edit-modal' })
export class DashboardEditModalComponent extends ModalBase<DashboardOperation> implements OnInit {

  @Input() public title: string;
  @Input() public isSettings: boolean;

  public dashboardTypeOptions: ComboItem<DashboardType>[];
  public formGroup: FormGroup<DashboardTypeOptionsForm>;
  public showFacilityDropdown: boolean = false;
  public constructor(
    activeModal: NgfActiveModal,
    private readonly dialogService: DialogService,
    private readonly fb: FormBuilder
  ) {
    super(activeModal);

    this.formGroup = this.fb.group<DashboardTypeOptionsForm>({
      title: new FormControl('', [Validators.required]),
      dashboardTypes: new FormControl(),
      facilityId: new FormControl()
    });

    this.dashboardTypeOptions = getStringEnumValues(DashboardType)
      .map(value => ({
        value: value,
        text: dashboardTypeTranslationKeys[value]
      }));
  }

  public ngOnInit(): void {
    this.formGroup.patchValue({
      title: this.title,
      dashboardTypes: DashboardType.EmptyDashboard
    });

    this.formGroup.get('dashboardTypes').valueChanges.subscribe(value => {
      if (value === DashboardType.FacilityDashboard) {
        this.showFacilityDropdown = true;
        this.formGroup.get('facilityId').setValidators([Validators.required]);
      } else {
        this.showFacilityDropdown = false;
        this.formGroup.get('facilityId').clearValidators();
        this.formGroup.patchValue({
          facilityId: null
        });
      }
      this.formGroup.get('facilityId').updateValueAndValidity();
    });
  }

  public cancel(): void {
    this.dismissModal();
  }

  public confirmDelete(): void {
    this.dialogService.getConfirmationModal({
      text: 'CONFIRM_DELETE',
      title: 'DELETE',
      isDelete: true,
      translate: true,
    }).subscribe({
      next: () => this.close(true),
      error: () => { }
    });
  }

  public close(deleteDashboard: boolean = false): void {
    this.closeModal({
      deleteDashboard: deleteDashboard,
      title: this.formGroup.get('title').value,
      dashboardTypes: this.formGroup.get('dashboardTypes').value,
      facilityId: this.formGroup.get('facilityId').value
    });
  }
}
