<ngf-tabset [destroyOnHide]="false">
  <ngf-tab [title]="'ADMIN.VIRTUAL_METERS.METER_INFO' | translate">
    <ng-template ngfTabContent>
      <meter-info
        [facility]="facility$ | async"
        [meter]="meter"
        [meterUpdateProperties]="meterUpdateProperties"
      ></meter-info>
    </ng-template>
  </ngf-tab>
  <ngf-tab [title]="'ADMIN.VIRTUAL_METERS.VIRTUAL_METER_FORMULA' | translate">
    <ng-template ngfTabContent>
      <p>{{ virtualMeter.formula }}</p>
      <virtual-meter-history
        [history]="history$ | async"
        [loading]="loading"
        (editFormula)="openModal($event)"
        (deleteFormula)="deleteFormula($event)"
      ></virtual-meter-history>
      <div class="button columns-button" (click)="openModal()">
        <i class="fa fa-plus"></i>
        <span> {{ 'ADMIN.VIRTUAL_METERS.CREATE_NEW_FORMULA' | translate }}</span>
      </div>
    </ng-template>
  </ngf-tab>
</ngf-tabset>
