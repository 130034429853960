import templateModule from 'raw-loader!./mrc-input-history.html';
import { MrcInputHistoryController } from './mrc-input-history-controller';

export const mrcInputHistoryComponent = {
  template: templateModule,
  controllerAs: 'vm',
  controller: MrcInputHistoryController,
  bindings: {
    options: '<'
  }
};
