import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { WINDOW } from '@enerkey/angular-utils';

import { noCustomThemeUrls } from '../../../config/no-custom-theme-urls';
import { tenantPageTitles } from '../../../config/tenant-page-titles';
import { PackageVersion } from '../package-version';
import { FaviconService } from './favicon.service';

export const customThemeElementId = 'enerkeyCustomTheme';

const tenantsUsingVanillaTheme = [
  'eidsiva',
  'raumanenergia',
  'loiste',
  'alva',
  'jamtkraft',
  'enklaelbolaget',
];

const tenantsUsingDefaultFavicon = [
  'sems',
  'jeudan',
];

const tenantCustomThemeNames: Record<string, string> = {
  jeudan: 'sems',
};

const notEnerkeyTenantUrlSuffixes = [
  'eg-omega.dk'
];

@Injectable()
export class TenantService {
  public readonly isCustomTenant: boolean = false;
  public readonly tenantName: string;
  public readonly tenantTitle: string = 'EnerKey';
  /** Some elements are only shown in EnerKey tenants, e.g. dashboard footer text */
  public readonly isEnerkeyBrand: boolean;

  public constructor(
    private readonly faviconService: FaviconService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) windowObject: Window,
    titleService: Title
  ) {
    this.tenantName = this.getTenantName(windowObject.location);
    this.isEnerkeyBrand = !notEnerkeyTenantUrlSuffixes.some(
      suffix => windowObject.location.hostname.endsWith(suffix)
    );
    if (this.tenantName) {
      this.isCustomTenant = true;
    }

    if (this.isCustomTenant) {
      this.tenantTitle = this.getTitle();
      titleService.setTitle(this.tenantTitle);
    }
  }

  /**
   * Load possible tenant specific css
   * Set tenant specific favicon
   */
  public async setTenantSpecificStyles(): Promise<[void, void]> {
    return Promise.all([
      this.loadCustomCssTheme(),
      this.setFavicon()
    ]);
  }

  private getTenantName(location: Location): string {
    const tenant = location.hostname.split('.')[0].toLowerCase();
    return noCustomThemeUrls.includes(tenant) ? '' : tenant.replace(/-(dev|test)$/, '');
  }

  private getTitle(): string {
    return tenantPageTitles[this.tenantName] || this.tenantName.capitalize();
  }

  private async loadCustomCssTheme(): Promise<void> {
    const styleFileName = tenantsUsingVanillaTheme.includes(this.tenantName)
      ? 'vanilla-portal'
      : tenantCustomThemeNames[this.tenantName] ?? this.tenantName
      ;
    const version = PackageVersion.getVersion();
    const promise = this.tenantName
      ? new Promise<void>(resolve => {
        const styleElement = this.document.createElement('link');
        styleElement.rel = 'stylesheet';
        styleElement.type = 'text/css';
        styleElement.href = `${styleFileName}${version}.css`;
        styleElement.onload = () => {
          resolve();
        };
        styleElement.onerror = () => {
          resolve();
        };
        styleElement.id = customThemeElementId;
        this.document.head.appendChild(styleElement);
      })
      : Promise.resolve()
      ;
    return promise;
  }

  private async setFavicon(): Promise<void> {
    return new Promise(resolve => {
      if (this.tenantName && !tenantsUsingDefaultFavicon.includes(this.tenantName)) {
        this.faviconService.setTenantFaviconHrefs(this.tenantName);
      }
      resolve();
    });
  }
}
