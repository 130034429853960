import { ChangeDetectionStrategy, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AlarmClient, LogDto, LogSearchCriteriaDto } from '@enerkey/clients/alarm';
import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { ModalService } from '@enerkey/foundation-angular';

import { WidgetBase } from '../../shared/widget-base.interface';
import { DashboardStateService } from '../../services/dashboard-state.service';
import { UserService } from '../../../../services/user-service';
import { AlarmDetailsModalComponent } from '../../../../shared/alarms-shared/components/alarm-details-modal/alarm-details-modal.component';
import { ALARMS_LOG_GRID_REFRESH } from '../../../alarms-log/shared/alarms-log-grid-refresh';
import { FacilityService } from '../../../../shared/services/facility.service';

export type LatestAlarmsWidgetOptions = {
  numberToShow: number;
};

@Component({
  selector: 'latest-alarms-widget',
  templateUrl: './latest-alarms-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LatestAlarmsWidgetComponent implements WidgetBase<LatestAlarmsWidgetOptions>, OnInit, OnDestroy {

  @Input() public dataModelOptions: LatestAlarmsWidgetOptions;

  public readonly dataModelChange$ = EMPTY;

  public readonly error$: Observable<void>;
  public readonly loading$: Observable<boolean>;
  public alarms$: Observable<LogDto[]>;

  private readonly _loading$ = new LoadingSubject(true);
  private readonly _error$ = new Subject<void>();

  private readonly _initialOptions$ = new ReplaySubject<LatestAlarmsWidgetOptions>(1);

  public constructor(
    private readonly dashboardStateService: DashboardStateService,
    private readonly alarmClient: AlarmClient,
    private readonly userService: UserService,
    private readonly modalService: ModalService,
    private readonly facilityService: FacilityService
  ) {
    this.error$ = this._error$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.alarms$ = combineLatest([
      this._initialOptions$,
      this.facilityService.facilityIdsForSearch$
    ]).pipe(
      switchMap(([options, facilityIds]) => this.alarmClient.getLogsBySearchCriteria(
        new LogSearchCriteriaDto({
          take: options?.numberToShow ?? 20,
          facilityIds: facilityIds
        }),
        this.userService.profileId
      ).pipe(
        indicate(this._loading$)
      )),
      catchError(() => {
        this._error$.next();
        return EMPTY;
      })
    );
  }

  public ngOnInit(): void {
    this._initialOptions$.next(this.dataModelOptions);
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
    this._error$.complete();
    this._initialOptions$.complete();
  }

  public openMeterReport(log: LogDto): void {
    this.dashboardStateService.openMeterReport(log.alarm.meterId, log.facilityId);
  }

  public openFacilityReport(log: LogDto): void {
    this.dashboardStateService.openFacilityReport(log.facilityId);
  }

  public openAlarmModal(log: LogDto): void {
    const refreshToken = new Subject();

    const injector = Injector.create({ providers: [{ provide: ALARMS_LOG_GRID_REFRESH, useValue: refreshToken }] });
    const modalInstance = this.modalService.open(AlarmDetailsModalComponent, { injector });
    modalInstance.componentInstance.getAlarmByLogId(log.id);
  }
}
