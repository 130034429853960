<ek-dropdown
  [width]="320"
  (isVisibleChanged)="visibleChanged($event)"
>
  <button
    *ekDropdownToggle="let toggle"
    (click)="toggle()"
    type="button"
    kendoButton
    [primary]="true"
    [icon]="'columns'"
  >
    {{ 'GRID.COLUMNS' | translate }}
  </button>

  <kendo-treeview
    [nodes]="treeViewColumns"
    textField="text"
    childrenField="children"
    kendoTreeViewExpandable
    kendoTreeViewHierarchyBinding
    [animate]="false"
    [kendoTreeViewCheckable]="checkableSettings"
    [isChecked]="$any(getCheckedState)"
    (checkedChange)="handleChecking($event)"
    (nodeClick)="nodeClicked($event)"
  ></kendo-treeview>

  <div class="k-action-buttons">
    <button
      kendoButton
      [icon]="'cancel-outline'"
      type="button"
      (click)="onCancel()"
    >
      {{ 'CANCEL' | translate }}
    </button>
    <button
      kendoButton
      [primary]="true"
      [icon]="'checkmark-circle'"
      type="button"
      (click)="onApply()"
    >
      {{ 'APPLY' | translate }}
    </button>
  </div>
</ek-dropdown>
