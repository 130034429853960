import { Pipe, PipeTransform } from '@angular/core';
import { from, Observable } from 'rxjs';

import { LegacyFacilityService } from '../../modules/reportingobjects/models/facilities';
import { FacilityInformationGroup } from '@enerkey/clients/energy-reporting';

@Pipe({
  name: 'facilityInfo'
})
export class FacilityInfoPipe implements PipeTransform {
  public constructor(private readonly jsFacilities: LegacyFacilityService) {
  }

  public transform(facilityId: number): Observable<FacilityInformationGroup> {
    return from(this.jsFacilities.getFacility(facilityId));
  }
}
