import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ModalBase } from '@enerkey/foundation-angular';

@Component({
  selector: 'profile-api-keys-modal',
  templateUrl: './profile-api-keys-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileApiKeysModalComponent extends ModalBase<void> {
  public dismiss(): void {
    super.dismissModal();
  }
}
