<div class="widget">
  <form class="et-curve-widget-navbar"
       [formGroup]="formGroup"
  >
    <et-curve-facility-list [showFacilityLink]="false" />
    <et-curve-quantity-list [filterByQuantityIds]="facilityEtCurveQuantities$" />
    <button
      class="button button--link button--external-link"
      (click)="onFacilityButtonClick()"
      *ngIf="controls.facilityId.value !== null"
    >
      {{ 'FACILITIES.SHOW_FACILITY' | translate }}
    </button>
  </form>
  <div class="et-curve-widget-chart">

    <et-curve-chart
      (etCurveLoaded)="onChartLoaded()"
      [hidden]="controls.quantity.value === null"
      #etCurveChart
    ></et-curve-chart>

    <ng-container *ngIf="quantities$ | async as quantities">
      <div
        class="et-curve-widget-message-container"
        *ngIf="quantities !== null && controls.quantity.value === null"
      >
        <ng-container *ngIf="quantities.length === 0">
          <p>{{ 'FACILITY_ETCURVE_WIDGET.NO_ETCURVE_AVAILABLE' | translate }}</p>
          <p>{{ 'FACILITY_ETCURVE_WIDGET.CREATE_ETCURVE_FIRST' | translate }}</p>
        </ng-container>

        <ng-container *ngIf="quantities.length > 0">
          <p>{{ 'FACILITY_ETCURVE_WIDGET.SELECT_QUANTITY' | translate }}</p>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
