import { hasChanges } from '@enerkey/angular-utils';

import TimeFrame from '../../services/time-frame-service';

/**
 * Controller for TimeFrame component that renders TimeFrame object for desired format for ui
 *
 * usage:
 *
 * <time-frame time-frame="InputTimeFrame" format="DD.MM.YYYY"></time-frame>
 *
 * @constructor
 */
function TimeFrameController() {
  let vm = this;

  vm.timeFrameAsString = '';

  vm.$onInit = onInit;
  vm.$onChanges = onChanges;

  function onInit() {
    if (vm.timeFrame instanceof TimeFrame) {
      vm.timeFrameAsString = vm.timeFrame.getAsString(vm.format);
    }
  }

  function onChanges(changes) {
    if (vm.timeFrame instanceof TimeFrame &&
      (
        hasChanges(changes, 'timeFrame') ||
        hasChanges(changes, 'format')
      )) {
      vm.timeFrameAsString = vm.timeFrame.getAsString(vm.format);
    }
  }
}

export default TimeFrameController;
