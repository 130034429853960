import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SubscriptionLike } from 'rxjs';

import { listenToTriggers } from '../util/triggers';
import { ngfAutoClose } from '../util/autoclose';
import { positionElements } from '../util/positioning';
import { PopupService } from '../util/popup';

import { NgfTooltipConfig, TooltipAlignment, TooltipPlacement } from './tooltip-config';

/* eslint-disable @typescript-eslint/no-explicit-any */

let nextId = 0;

@Component({
  selector: 'ngf-tooltip-window',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  template: '<ng-content></ng-content>'
})
export class NgfTooltipWindowComponent {
  @Input() public tooltipClass: string;
  @Input() public placement: TooltipPlacement = 'top';
  @Input() public alignment: string = 'center';

  @HostBinding('attr.id') @Input() public id: string;
  @HostBinding('attr.role') public role = 'tooltip';
  @HostBinding('class') public get hostClasses(): string {
    return `tooltip ${this.placement} align-${this.alignment} ${this.tooltipClass}`;
  }
}

/**
 * A lightweight and extensible directive for fancy tooltip creation.
 */
@Directive({ selector: '[ngfTooltip]', exportAs: 'ngfTooltip' })
export class NgfTooltipDirective implements OnInit, OnDestroy {

  /**
   * Indicates whether the tooltip should be closed on `Escape` key and inside/outside clicks:
   *
   * * `true` - closes on both outside and inside clicks as well as `Escape` presses
   * * `false` - disables the autoClose feature (NB: triggers still apply)
   * * `"inside"` - closes on inside clicks as well as Escape presses
   * * `"outside"` - closes on outside clicks (sometimes also achievable through triggers)
   * as well as `Escape` presses
   *
   * @since 3.0.0
   */
  @Input() public autoClose: boolean | 'inside' | 'outside';

  /**
   * The preferred placement of the tooltip.
   *
   * Possible values are `"top"`, `"bottom"`,`"left"`, `"right"`
   *
   * Accepts an array of strings or a string with space separated possible values.
   *
   * The default order of preference is `"auto"` (same as the sequence above).
   *
   * Please see the [positioning overview](#/positioning) for more details.
   */
  @Input() public placement: TooltipPlacement;

  @Input() public alignment: TooltipAlignment;

  /**
   * Specifies events that should trigger the tooltip.
   *
   * Supports a space separated list of event names.
   * For more details see the [triggers demo](#/components/tooltip/examples#triggers).
   */
  @Input() public triggers: string;

  /**
   * A selector specifying the element the tooltip should be appended to.
   *
   * Currently only supports `"body"`.
   */
  @Input() public container: 'body' | undefined;

  /**
   * Whether anchor element should be parent or not.
   */
  @Input() public anchor: 'parent' | undefined;

  /**
   * If `true`, tooltip is disabled and won't be displayed.
   *
   * @since 1.1.0
   */
  @Input() public disableTooltip: boolean;

  /**
   * An optional class applied to the tooltip window element.
   *
   * @since 3.2.0
   */
  @Input() public tooltipClass: string;

  /**
   * The opening delay in ms. Works only for "non-manual" opening triggers defined by the `triggers` input.
   *
   * @since 4.1.0
   */
  @Input() public openDelay: number;

  /**
   * The closing delay in ms. Works only for "non-manual" opening triggers defined by the `triggers` input.
   *
   * @since 4.1.0
   */
  @Input() public closeDelay: number;

  /**
   * Default context used if tooltip is `TemplateRef`.
   */
  @Input() public context: any;

  /**
   * An event emitted when the tooltip is shown. Contains no payload.
   */
  @Output() public readonly shown = new EventEmitter();
  /**
   * An event emitted when the popover is hidden. Contains no payload.
   */
  @Output() public readonly hidden = new EventEmitter();

  private readonly pipSizeInPx: any;
  private readonly _ngfTooltipWindowId = `ngf-tooltip-${nextId++}`;
  private readonly _popupService: PopupService<NgfTooltipWindowComponent, any>;

  private _ngfTooltip: string | TemplateRef<any>;
  private _windowRef: ComponentRef<NgfTooltipWindowComponent>;
  private _listenersSub?: SubscriptionLike;
  private _repositionSub?: SubscriptionLike;

  public constructor(
    injector: Injector,
    viewContainerRef: ViewContainerRef,
    config: NgfTooltipConfig,
    applicationRef: ApplicationRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2,
    private readonly _ngZone: NgZone,
    private readonly _changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this.autoClose = config.autoClose;
    this.placement = config.placement;
    this.alignment = config.alignment;
    this.triggers = config.triggers;
    this.container = config.container;
    this.disableTooltip = config.disableTooltip;
    this.tooltipClass = config.tooltipClass;
    this.openDelay = config.openDelay;
    this.closeDelay = config.closeDelay;
    this.pipSizeInPx = config.pipSizeInPx;
    this._popupService = new PopupService<NgfTooltipWindowComponent, any>(
      NgfTooltipWindowComponent,
      injector,
      viewContainerRef,
      _renderer,
      applicationRef
    );
  }

  /**
   * The string content or a `TemplateRef` for the content to be displayed in the tooltip.
   *
   * If the content if falsy, the tooltip won't open.
   */
  public get ngfTooltip(): string | TemplateRef<any> {
    return this._ngfTooltip;
  }

  @Input()
  public set ngfTooltip(value: string | TemplateRef<any>) {
    this._ngfTooltip = value;
    if (!value && this._windowRef) {
      this.close();
    }
  }

  /**
   * Opens the tooltip.
   *
   * This is considered to be a "manual" triggering.
   * The `context` is an optional value to be injected into the tooltip template when it is created.
   */
  public open(context?: unknown): void {
    if (!this._windowRef && this._ngfTooltip && !this.disableTooltip) {
      this._windowRef = this._popupService.open(this._ngfTooltip, context ?? this.context);
      this._windowRef.instance.tooltipClass = this.tooltipClass;
      this._windowRef.instance.id = this._ngfTooltipWindowId;
      this._windowRef.instance.placement = this.placement;
      this._windowRef.instance.alignment = this.alignment;

      this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-describedby', this._ngfTooltipWindowId);

      if (this.container === 'body') {
        this._document.body.appendChild(this._windowRef.location.nativeElement);
      }

      // Reposition visible tooltip when the something happens
      this._repositionSub?.unsubscribe();
      this._repositionSub = this._ngZone.onStable.subscribe(() => this._reposition());

      // We need to detect changes, because we don't know where .open() might be called from.
      // Ex. opening tooltip from one of lifecycle hooks that run after the CD
      // (say from ngAfterViewInit) will result in 'ExpressionHasChanged' exception
      this._windowRef.changeDetectorRef.detectChanges();

      // We need to mark for check, because tooltip won't work inside the OnPush component.
      // Ex. when we use expression like `{{ tooltip.isOpen() : 'opened' : 'closed' }}`
      // inside the template of an OnPush component and we change the tooltip from
      // open -> closed, the expression in question won't be updated unless we explicitly
      // mark the parent component to be checked.
      this._windowRef.changeDetectorRef.markForCheck();

      // Register closing
      ngfAutoClose(
        this._ngZone,
        this._document,
        this.autoClose,
        () => this.close(),
        this.hidden,
        [this._windowRef.location.nativeElement]
      );

      this.shown.emit();
    }
  }

  /**
   * Closes the tooltip.
   *
   * This is considered to be a "manual" triggering of the tooltip.
   */
  public close(): void {
    if (this._windowRef) {
      this._repositionSub?.unsubscribe(); // stop listening to zone changes
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'aria-describedby');
      this._popupService.close();
      this._windowRef = null;
      this.hidden.emit();
      this._changeDetector.markForCheck();
    }
  }

  /**
   * Toggles the tooltip.
   *
   * This is considered to be a "manual" triggering of the tooltip.
   */
  public toggle(): void {
    if (this._windowRef) {
      this.close();
    } else {
      this.open();
    }
  }

  public ngOnInit(): void {
    this._listenersSub = listenToTriggers(
      this._renderer,
      this._elementRef.nativeElement,
      this.triggers,
      () => !!this._windowRef,
      () => this.open(),
      () => this.close(),
      +this.openDelay,
      +this.closeDelay
    );
  }

  public ngOnDestroy(): void {
    this.close();

    // ngOnDestroy can be called before ngOnInit under certain conditions
    // see: https://github.com/ng-bootstrap/ng-bootstrap/issues/2199
    this._listenersSub?.unsubscribe();
    this._repositionSub?.unsubscribe();

    this.shown.complete();
    this.hidden.complete();
  }

  private _reposition(): void {
    if (this._windowRef) {
      const host = this._elementRef.nativeElement;

      positionElements(
        this.anchor === 'parent' ? host.parentElement : host,
        this._windowRef.location.nativeElement,
        this.placement,
        this.alignment,
        this.container === 'body',
        this.pipSizeInPx
      );
    }
  }
}
