import { IUserManagementClient } from './user-management-client';

export class UserManagementClientMock implements IUserManagementClient {
  public bulkModifyProfilesCompany(): never {
    throw new Error('Method not implemented.');
  }

  public getAllServiceProducts(): never {
    throw new Error('Method not implemented.');
  }

  public searchServiceProductsByIds(): never {
    throw new Error('Method not implemented.');
  }

  public getServiceProduct(): never {
    throw new Error('Method not implemented.');
  }

  public bulkSetServiceProductsToProfiles(): never {
    throw new Error('Method not implemented.');
  }

  public sendContactingEmail(): never {
    throw new Error('Method not implemented.');
  }

  public createProfileApiKey(): never {
    throw new Error('Method not implemented.');
  }

  public get(): never {
    throw new Error('Method not implemented.');
  }

  public delete(): never {
    throw new Error('Method not implemented.');
  }

  public createTenantApiKey(): never {
    throw new Error('Method not implemented.');
  }

  public getTenantApiKeyInfo(): never {
    throw new Error('Method not implemented.');
  }

  public deleteTenantApiKey(): never {
    throw new Error('Method not implemented.');
  }

  public getCompanyApiKeyInfo(): never {
    throw new Error('Method not implemented.');
  }

  public deleteCompanyApiKey(): never {
    throw new Error('Method not implemented.');
  }

  public createCompanyApiKey(): never {
    throw new Error('Method not implemented.');
  }

  public getApiKeyDetails(): never {
    throw new Error('Method not implemented.');
  }

  public getToken(): never {
    throw new Error('Method not implemented.');
  }

  public getServices(): never {
    throw new Error('Method not implemented.');
  }

  public getRoles(): never {
    throw new Error('Method not implemented.');
  }

  public getCrossTenantProfilesWithService(): never {
    throw new Error('Method not implemented.');
  }

  public getProfilesServices(): never {
    throw new Error('Method not implemented.');
  }

  public getAllProfilesSimple(): never {
    throw new Error('Method not implemented.');
  }

  public getProfileServiceLevel(): never {
    throw new Error('Method not implemented.');
  }

  public getProfile(): never {
    throw new Error('Method not implemented.');
  }

  public getProfilesIfAccessibleToUser(): never {
    throw new Error('Method not implemented.');
  }

  public getUserProfiles(): never {
    throw new Error('Method not implemented.');
  }

  public getLoggedInUsersProfiles(): never {
    throw new Error('Method not implemented.');
  }

  public getUsersProfiles(): never {
    throw new Error('Method not implemented.');
  }

  public updateProfile(): never {
    throw new Error('Method not implemented.');
  }

  public getAllProfiles(): never {
    throw new Error('Method not implemented.');
  }

  public getLoggedInUsersAllowedReportingObjectSetIdsForService(): never {
    throw new Error('Method not implemented.');
  }

  public updateLoggedInUsersDefaultProfile(): never {
    throw new Error('Method not implemented.');
  }

  public bulkModifyProfilesServices(): never {
    throw new Error('Method not implemented.');
  }

  public hasAccessToProfile(): never {
    throw new Error('Method not implemented.');
  }

  public hasAccessToFacilities(): never {
    throw new Error('Method not implemented.');
  }

  public sendMail(): never {
    throw new Error('Method not implemented.');
  }

  public getBasicTenantConfiguration(): never {
    throw new Error('Method not implemented.');
  }

  public getCompleteTenantConfiguration(): never {
    throw new Error('Method not implemented.');
  }

  public editTenantConfiguration(): never {
    throw new Error('Method not implemented.');
  }

  public getCurrentTenantName(): never {
    throw new Error('Method not implemented.');
  }

  public getTenantIds(): never {
    throw new Error('Method not implemented.');
  }

  public getTenantHost(): never {
    throw new Error('Method not implemented.');
  }

  public changeSignedInUserLanguage(): never {
    throw new Error('Method not implemented.');
  }

  public addUsersToTenant(): never {
    throw new Error('Method not implemented.');
  }

  public removeUsersFromTenant(): never {
    throw new Error('Method not implemented.');
  }

  public getTenantIdForUsername(): never {
    throw new Error('Method not implemented.');
  }

  public getUsernameForTenantId(): never {
    throw new Error('Method not implemented.');
  }

  public bulkUpdateUsers(): never {
    throw new Error('Method not implemented.');
  }

  public getAllUsers(): never {
    throw new Error('Method not implemented.');
  }

  public addUser(): never {
    throw new Error('Method not implemented.');
  }

  public getUser(): never {
    throw new Error('Method not implemented.');
  }

  public updateUser(): never {
    throw new Error('Method not implemented.');
  }

  public delete2(): never {
    throw new Error('Method not implemented.');
  }

  public addUserV2(): never {
    throw new Error('Method not implemented.');
  }

  public bulkCreateUsers(): never {
    throw new Error('Method not implemented.');
  }

  public bulkSendWelcomeMail(): never {
    throw new Error('Method not implemented.');
  }

  public bulkUpdateEndDate(): never {
    throw new Error('Method not implemented.');
  }

  public bulkModifyRoles(): never {
    throw new Error('Method not implemented.');
  }

  public bulkModifyProfiles(): never {
    throw new Error('Method not implemented.');
  }

  public getProfilesForUsers(): never {
    throw new Error('Method not implemented.');
  }

  public getUsernamesByIds(): never {
    throw new Error('Method not implemented.');
  }

  public getUsernamesInCertainRole(): never {
    throw new Error('Method not implemented.');
  }

  public getUserNotifications(): never {
    throw new Error('Method not implemented.');
  }

  public postUserNotification(): never {
    throw new Error('Method not implemented.');
  }

  public getTenantIdNamePairs(): never {
    throw new Error('Method not implemented.');
  }
}
