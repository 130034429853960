import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { LanguageService } from '../services/language.service';
import { UserService } from '../../services/user-service';

@Injectable()
export class HeaderParamsInterceptor implements HttpInterceptor {

  public constructor(
    private readonly userService: UserService,
    private readonly languageService: LanguageService
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.setParams(request);

    return next.handle(request);
  }

  /**
   * Sets `lang` and `ReportingObjectSetId`-params of the request if they are not present.
   */
  private setParams<T>(request: HttpRequest<T>): HttpRequest<T> {
    const setParams: Record<string, string> = {};
    const setHeaders: Record<string, string> = {};

    const existingQueryParams = new HttpParams({ fromString: request.url.split('?')[1] });

    // handle language
    if (!request.params.has('lang')) {
      setParams.lang = this.languageService.getApiLocale();
    }

    setHeaders['Accept-Language'] = this.languageService.getApiLocale();

    // handle profile
    const profileId = this.userService.getCurrentProfile().reportingObjectSetId?.toString();

    if (profileId) {
      if (!this.hasProfileIdParameter(request.params, existingQueryParams)) {
        setParams['ReportingObjectSetId'] = profileId;
      }

      setHeaders['X-Enerkey-Profile'] = profileId;
    }

    return request.clone({ setParams, setHeaders });
  }

  private hasProfileIdParameter(...sources: HttpParams[]): boolean {
    return sources.some(params => params.has('ReportingObjectSetId') || params.has('reportingObjectSetId'));
  }
}
