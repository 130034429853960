import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Locale as DateFnsLocale } from 'date-fns';
import { da, de, enGB, fi, nb, sv } from 'date-fns/locale';

export interface LocaleInfo {
  readonly angularLocale: string;
  readonly apiLocale: string;
  readonly kendoAngularLocale: string;
  readonly kendoJqueryLocale: string;
  readonly kendoJqueryTranslations: string;
  readonly isHelpLocale: boolean;
  readonly short: string;
  readonly dateFnsLocale: DateFnsLocale;
}

export type Locale = typeof LanguageService.locales[number];

export interface LanguageSelectItem {
  translation: string;
  language: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public static readonly locales = ['fi-FI', 'en-GB', 'sv-SE', 'nb-NO', 'de-DE', 'da-DK'] as const;

  public static readonly localeMap: Record<Locale, LocaleInfo> = {
    'fi-FI': {
      angularLocale: 'fi',
      apiLocale: 'fi-FI',
      kendoAngularLocale: 'fi',
      kendoJqueryLocale: 'fi-FI',
      kendoJqueryTranslations: 'fi-FI',
      isHelpLocale: true,
      short: 'fi',
      dateFnsLocale: fi
    },
    'en-GB': {
      angularLocale: 'en-GB',
      apiLocale: 'en-GB',
      kendoAngularLocale: 'en-GB',
      kendoJqueryLocale: 'en-GB',
      kendoJqueryTranslations: 'en-GB',
      isHelpLocale: true,
      short: 'en',
      dateFnsLocale: enGB
    },
    'sv-SE': {
      angularLocale: 'sv',
      apiLocale: 'sv-SE',
      kendoAngularLocale: 'sv',
      kendoJqueryLocale: 'sv-SE',
      kendoJqueryTranslations: 'sv-SE',
      isHelpLocale: true,
      short: 'sv',
      dateFnsLocale: sv
    },
    'nb-NO': {
      angularLocale: 'nb',
      apiLocale: 'nb-NO',
      kendoAngularLocale: 'nb',
      kendoJqueryLocale: 'nb-NO',
      kendoJqueryTranslations: 'nb-NO',
      isHelpLocale: true,
      short: 'nb',
      dateFnsLocale: nb
    },
    'de-DE': {
      angularLocale: 'de',
      apiLocale: 'de-DE',
      kendoAngularLocale: 'de',
      kendoJqueryLocale: 'de-DE',
      kendoJqueryTranslations: 'de-DE',
      isHelpLocale: false,
      short: 'de',
      dateFnsLocale: de
    },
    'da-DK': {
      angularLocale: 'da',
      apiLocale: 'da-DK',
      kendoAngularLocale: 'da',
      kendoJqueryLocale: 'da-DK',
      kendoJqueryTranslations: 'da-DK',
      isHelpLocale: true,
      short: 'da',
      dateFnsLocale: da
    }
  };

  private readonly fallbackLocale: Locale = 'en-GB';

  public constructor(
    @Inject(LOCALE_ID) private localeId: Locale,
    private readonly translateService: TranslateService
  ) {
  }

  public setLocale(locale: Locale): void {
    this.localeId = locale;
  }

  public getLocales(): readonly Locale[] {
    return [...LanguageService.locales];
  }

  public getLocale(): Locale {
    return this.localeId;
  }

  public getLanguage(): string {
    return this.getLocaleInfo().short;
  }

  public getShortLanguages(): string[] {
    return Object.values(LanguageService.localeMap)
      .map(locale => locale.short);
  }

  public getHelpLocale(): Locale {
    const localeInfo = this.getLocaleInfo();
    return localeInfo.isHelpLocale ? this.localeId : this.fallbackLocale;
  }

  public getApiLocale(): string {
    return this.getLocaleInfo().apiLocale;
  }

  public getLanguageSelectItems(): LanguageSelectItem[] {
    return this.getLocales().map(language => ({
      language: language,
      translation: this.translateService.instant(`SETTINGS.${language.toUpperCase()}`)
    }));
  }

  public getDateFnsLocale(): DateFnsLocale {
    return this.getLocaleInfo().dateFnsLocale;
  }

  private getLocaleInfo(): LocaleInfo {
    return LanguageService.localeMap[this.localeId];
  }
}
