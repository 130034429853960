import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  AvailableFunctions,
  CreateCompanyFacilityFunctionModel,
  CreateCompanyPersonFunctionModel,
  CreatePersonCompanyFunctionModel,
  CreatePersonFacilityFunctionModel,
  FunctionType,
} from '@enerkey/clients/contact';
import { FunctionTypeMultiselectItem } from './function-type-multiselect-item';

export const functionTypeTranslations: Record<FunctionType, string> = {
  [FunctionType.Superintendent]: 'CONTACTMANAGER.FUNCTION.SUPERINTENDENT',
  [FunctionType.Manager]: 'CONTACTMANAGER.FUNCTION.MANAGER',
  [FunctionType.Service]: 'CONTACTMANAGER.FUNCTION.SERVICE',
  [FunctionType.Owner]: 'CONTACTMANAGER.FUNCTION.OWNER',
  [FunctionType.Alarm]: 'CONTACTMANAGER.FUNCTION.ALARM',
};

export const availableFunctionTypeEnumMap: Record<keyof AvailableFunctions, FunctionType> = {
  superintendentFunction: FunctionType.Superintendent,
  managerFunction: FunctionType.Manager,
  serviceFunction: FunctionType.Service,
  ownerFunction: FunctionType.Owner,
  alarmFunction: FunctionType.Alarm,
};

type FunctionTypeDict = Record<string, FunctionType>;

/* eslint-disable no-bitwise */

// TODO: scope this POS
@Injectable({ providedIn: 'root' })
export class FunctionTypeService {

  public constructor(
    private readonly translateService: TranslateService
  ) { }

  public fromObjects(
    availableFunctions: { value: FunctionType }[]
  ): FunctionType {
    let combinedFunctionType: FunctionType;

    availableFunctions.map(_ => _.value).forEach(functionType => {
      combinedFunctionType |= functionType;
    });

    return combinedFunctionType;
  }

  public valueFromObject(availableFunctions: AvailableFunctions): FunctionType {
    let functionType: FunctionType;

    for (const [key, type] of Object.entries(availableFunctionTypeEnumMap)) {
      if (availableFunctions[key as keyof AvailableFunctions]) {
        functionType |= type;
      }
    }

    return functionType;
  }

  public arrayFromObject(availableFunctions: AvailableFunctions): FunctionType[] {
    const functionTypes: FunctionType[] = [];

    for (const [key, type] of Object.entries(availableFunctionTypeEnumMap)) {
      if (availableFunctions[key as keyof AvailableFunctions]) {
        functionTypes.push(type);
      }
    }

    return functionTypes;
  }

  public getCompanyPersonModels(personFunctionMap: FunctionTypeDict): CreateCompanyPersonFunctionModel[] {
    return this.getModels(personFunctionMap, 'personId');
  }

  public getCompanyFacilityModels(facilityFunctionMap: FunctionTypeDict): CreateCompanyFacilityFunctionModel[] {
    return this.getModels(facilityFunctionMap, 'facilityId');
  }

  public getPersonCompanyModels(companyFunctionMap: FunctionTypeDict): CreatePersonCompanyFunctionModel[] {
    return this.getModels(companyFunctionMap, 'companyId');
  }

  public getPersonFacilityModels(facilityFunctionMap: FunctionTypeDict): CreatePersonFacilityFunctionModel[] {
    return this.getModels(facilityFunctionMap, 'facilityId');
  }

  public containsFunctionType(
    functionTypeDict: FunctionTypeDict,
    functionType: FunctionType
  ): boolean {
    return Object.values(functionTypeDict).some(type => functionType === (type & functionType));
  }

  public listContainsFunctionType<T extends { functionType?: FunctionType }>(
    functions: T[],
    functionType: FunctionType
  ): boolean {
    return !!functions?.some(key => !!(key.functionType & functionType));
  }

  public getSelectItems(
    functionTypes: readonly FunctionType[]
  ): FunctionTypeMultiselectItem[] {
    return functionTypes.map<FunctionTypeMultiselectItem>(type => ({
      text: this.translateService.instant(functionTypeTranslations[type]),
      value: type,
    }));
  }

  private getModels<K extends string>(
    source: FunctionTypeDict,
    keyField: K
  ): ModelBase[] {
    if (!source) {
      return [];
    }

    return Object.entries(source).map(([key, value]) => ({
      [keyField]: Number(key),
      functionType: value,
    }));
  }
}

interface ModelBase {
  functionType: FunctionType;
  [key: string]: number;
}
