import { IWeatherClient } from './weather-client';

export class WeatherClientMock implements IWeatherClient {
  public findMunicipalities(): never {
    throw new Error('Method not implemented.');
  }
  public addNormalizationData(): never {
    throw new Error('Method not implemented.');
  }
  public getNormalizationData(): never {
    throw new Error('Method not implemented.');
  }
  public getCombinedWeatherForLocations(): never {
    throw new Error('Method not implemented.');
  }
  public getTemperaturesForLocations(): never {
    throw new Error('Method not implemented.');
  }
  public getWindSpeedsForLocations(): never {
    throw new Error('Method not implemented.');
  }
  public temperatureAverage(): never {
    throw new Error('Method not implemented.');
  }
  public windAverage(): never {
    throw new Error('Method not implemented.');
  }
  public temperatureSmartQuery(): never {
    throw new Error('Method not implemented.');
  }
  public windSpeedSmartQuery(): never {
    throw new Error('Method not implemented.');
  }
  public temperatureDataByMunicipalityIds(): never {
    throw new Error('Method not implemented.');
  }
  public getWeatherReadings(): never {
    throw new Error('Method not implemented.');
  }
}
