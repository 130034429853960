import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

import { ReportingMeterSelection } from '../shared/reporting-meter-selection';
import { ReportingSearchParams } from '../shared/reporting-search-params';
import { ReportingSeriesByFacility } from '../shared/reporting-series-collection';
import { ReportingMeterDataService } from './reporting-meter-data.service';
import { ReportingSeriesService } from './reporting-series.service';
import {
  getFacilitiesPerQuantity,
  getIncompleteFacilitiesAndQuantities,
  getQuantitySums
} from './sum-report.functions';
import { sumKey } from './sum-report.service';
import { ReportType } from '../shared/report-type';

@Injectable({
  providedIn: 'root'
})
export class SumMeterReportService {
  public constructor(
    private readonly reportingMeterDataService: ReportingMeterDataService,
    private readonly reportingSeriesService: ReportingSeriesService
  ) {
  }

  public getData(
    params: ReportingSearchParams,
    facilityId: number,
    meters: ReportingMeterSelection,
    incompleteThreshold: number
  ): Observable<ReportingSeriesByFacility> {
    return forkJoin({
      measured: forkJoin([
        this.reportingMeterDataService.getMeasuredValues(
          ReportType.Sum,
          params,
          facilityId,
          meters,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'column', lineOpacity: 0.85
            })),
          }
        ),
      ]),
      normalized: forkJoin([
        this.reportingMeterDataService.getNormalizedValues(
          ReportType.Sum,
          params,
          facilityId,
          meters,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'column', lineOpacity: 0.85
            })),
          }
        ),
      ]),
      meterBasedCosts: forkJoin([
        this.reportingMeterDataService.getMeterBasedCosts(
          params,
          [facilityId],
          meters,
          false,
          {
            serieSettings: params.periods.map(() => ({
              serieType: 'line'
            })),
          }
        )
      ]),
      targets: this.reportingMeterDataService.getTargetsForMeter(params, facilityId, meters),
    }).pipe(
      map(({
        measured, normalized, meterBasedCosts, targets
      }) => {
        const incompletes = getIncompleteFacilitiesAndQuantities(
          [...measured.flat(), ...normalized.flat(), ...meterBasedCosts.flat(), ...targets.flat()],
          meters.meterIds,
          incompleteThreshold
        );
        const facilitiesPerQuantity = getFacilitiesPerQuantity(
          [...measured.flat(), ...normalized.flat(), ...meterBasedCosts.flat(), ...targets.flat()],
          meters.meterIds,
          incompletes,
          params.comparability
        );
        return this.reportingSeriesService.mapValuesById({
          ids: [sumKey],
          ...getQuantitySums({
            measured: measured.flat(),
            normalized: normalized.flat(),
            comparability: params.comparability,
            targets: targets.flat(),
            incompletes,
            ids: meters.meterIds,
            meterBasedCosts: meterBasedCosts.flat()
          }),
          facilityAmountsPerQuantity: facilitiesPerQuantity,
          periods: params.periods
        });
      })
    );
  }
}
