import { downgradeInjectable } from '@angular/upgrade/static';

import LodashModule from '../../lodash';
import EnerKeyServicesModule from '../../../services';
import EnergyManagementServicesModule from '../../energy-management/services';
import EnergyManagementComponentsModule from '../../energy-management/components';
import EnergyReportingConstantsModule from '../constants';
import ERDataServiceModule from './er-data-service';
import ERUtilsModule from './er-utils';
import AdditionalSettingService from './additional-settings.service';
import ErCacheService from './er-cache-service';
import EnergyReportingEventsService from './er-events-service';
import ErReportSettingsService from './er-report-settings.service';
import ErStateService from './er-state.service';
import ErTimeFrameService from './er-time-frame.service';
import { ActionsToTimeseriesMapper } from './actions-to-timeseries-mapper';
import { AlarmService } from '../../../shared/services/alarm.service';
import { ConsumptionTargetDataService } from './consumption-target-data-service';
import { ERActionsImpactService } from './er-actions-impact-service';
import { ERDataChartService } from './er-data-chart-service';
import { ErParamsService } from './er-params.service';
import { MetersTreeListService } from './meters-treelist-service';
import { ReportSettingService } from './report-settings';
import { ConsumptionPercentPipe } from '../../../shared/energy-reporting-shared/pipes/consumption-percent.pipe';
import { ConsumptionValuePipe } from '../../../shared/energy-reporting-shared/pipes/consumption-value.pipe';
import { ThresholdService } from '../../../shared/services/threshold.service';

const dependencies = [
  LodashModule.name,
  EnerKeyServicesModule.name,
  EnergyManagementServicesModule.name,
  EnergyManagementComponentsModule.name,
  EnergyReportingConstantsModule.name,
  ERDataServiceModule.name,
  ERUtilsModule.name
];

export default angular
  .module('EnergyReporting.Services', dependencies)
  .service('ActionsToTimeseriesMapper', ActionsToTimeseriesMapper)
  .service('AdditionalSettingService', AdditionalSettingService)
  .factory('ConsumptionTargetDataService', ConsumptionTargetDataService)
  .factory('ERActionsImpactService', ERActionsImpactService)
  .service('erCacheService', ErCacheService)
  .factory('ERDataChartService', ERDataChartService)
  .service('EREventsService', EnergyReportingEventsService)
  .service('erParamsService', ErParamsService)
  .service('erReportSettingsService', ErReportSettingsService)
  .service('erStateService', ErStateService)
  .service('erTimeFrameService', ErTimeFrameService)
  .service('MetersTreeListService', MetersTreeListService)
  .factory('ReportSettingService', ReportSettingService)
  .factory('alarmService', downgradeInjectable(AlarmService))
  .factory('consumptionPercentPipe', downgradeInjectable(ConsumptionPercentPipe))
  .factory('consumptionValuePipe', downgradeInjectable(ConsumptionValuePipe))
  .factory('thresholdService', downgradeInjectable(ThresholdService))
;
