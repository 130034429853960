import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Service } from '../../../constants/service';
import { UserService } from '../../../services/user-service';
import { ActionOutViewModel, ActionType, AttachmentsClient } from '@enerkey/clients/attachments';
import { Roles } from '../../admin/constants/roles';
import { ACTION_TYPE_IDS, COMMENT_TYPE_IDS } from '../../energy-management/constants/em-shared.constant';
import { RelationalValueId } from '../../reportingobjects/constants/facilities-properties';

export interface OverviewActionsAndComments {
  actions: ActionOutViewModel[];
  comments: ActionOutViewModel[];
}

@Injectable({
  providedIn: 'root'
})
export class OverviewReportService {
  public constructor(
    private readonly userService: UserService,
    private readonly attachmentsClient: AttachmentsClient
  ) {
  }

  public getRelationalUnitIds(): { emission: RelationalValueId; cost: RelationalValueId } {
    let costId: RelationalValueId = null;
    if (this.userService.hasRole(Roles.COSTREADER) || this.userService.hasRole(Roles.COSTWRITER)) {
      if (this.userService.hasService(Service.CostReportingMeterBased)) {
        costId = RelationalValueId.Costs;
      } else if (this.userService.hasService(Service.CostReportingLocationBased)) {
        costId = RelationalValueId.NationalCosts;
      }
    }

    let emissionId: RelationalValueId = null;
    if (this.userService.hasService(Service.EmissionReporting)) {
      emissionId = RelationalValueId.co2Factor;
    }
    return {
      emission: emissionId,
      cost: costId
    };
  }

  public getActionsAndComments(facilityId: number): Observable<OverviewActionsAndComments> {
    const hasAccessToActions = this.userService.hasService(Service.Actions);
    const hasAccessToComments = this.userService.hasService(Service.Comments);
    if (!(hasAccessToComments || hasAccessToActions)) {
      return EMPTY;
    }

    return this.attachmentsClient.getActionsFlatListForReportingObject(
      undefined,
      facilityId
    ).pipe(
      map(actions => {
        const onlyActions = hasAccessToActions
          ? this.getActionsOfType(actions, ACTION_TYPE_IDS)
          : null
        ;
        const onlyComments = hasAccessToComments
          ? this.getActionsOfType(actions, COMMENT_TYPE_IDS)
          : null
        ;
        return {
          actions: onlyActions,
          comments: onlyComments
        };
      })
    );
  }

  private getActionsOfType(
    actions: ActionOutViewModel[],
    types: ActionType[]
  ): ActionOutViewModel[] {
    return actions
      .filter(action => types.includes(action.actionType))
      .sortBy('updatedAt', 'desc')
      .slice(0, 4);
  }
}
