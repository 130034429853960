import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationMetadata,
  AnimationPlayer,
  style
} from '@angular/animations';

// todo: add animations when https://github.com/angular/angular/issues/9947 solved
import {
  AfterViewChecked,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  Renderer2
} from '@angular/core';

const COLLAPSE_ANIMATION_TIMING = '400ms cubic-bezier(0.4,0.0,0.2,1)';

const expandAnimation: AnimationMetadata[] = [
  style({ height: 0, visibility: 'hidden' }),
  animate(
    COLLAPSE_ANIMATION_TIMING,
    style({ height: '*', visibility: 'visible' })
  )
];

const collapseAnimation: AnimationMetadata[] = [
  style({ height: '*', visibility: 'visible' }),
  animate(
    COLLAPSE_ANIMATION_TIMING,
    style({ height: 0, visibility: 'hidden' })
  )
];

@Directive({
  selector: '[collapse]',
  exportAs: 'bs-collapse'
})
export class CollapseDirective implements AfterViewChecked {
  /** This event fires as soon as content collapses */
  @Output() public readonly collapsed: EventEmitter<CollapseDirective> = new EventEmitter();

  /** This event fires when collapsing is started */
  @Output() public readonly collapses: EventEmitter<CollapseDirective> = new EventEmitter();

  /** This event fires as soon as content becomes visible */
  @Output() public readonly expanded: EventEmitter<CollapseDirective> = new EventEmitter();

  /** This event fires when expansion is started */
  @Output() public readonly expands: EventEmitter<CollapseDirective> = new EventEmitter();

  @HostBinding('class.collapse') public collapseClass = true;

  // shown
  @HostBinding('class.in')
  @HostBinding('class.show')
  @HostBinding('attr.aria-expanded')
  public isExpanded = true;
  // hidden
  @HostBinding('attr.aria-hidden') public isCollapsed = false;
  // stale state
  @HostBinding('class.collapse') public isCollapse = true;
  // animation state
  @HostBinding('class.collapsing') public isCollapsing = false;

  @Input()
  public set display(value: string) {
    if (!this.isAnimated) {
      this._renderer.setStyle(this._el.nativeElement, 'display', value);

      return;
    }

    this._display = value;

    if (value === 'none') {
      this.hide();

      return;
    }

    this.show();
  }
  /** turn on/off animation */
  @Input() public isAnimated = false;
  /** A flag indicating visibility of content (shown or hidden) */
  @Input()
  public set collapse(value: boolean) {
    this.isExpanded = value;
    this.toggle();
  }

  public get collapse(): boolean {
    return this.isExpanded;
  }

  private _display = 'block';
  private _factoryCollapseAnimation: AnimationFactory;
  private _factoryExpandAnimation: AnimationFactory;
  private _player: AnimationPlayer;
  private _stylesLoaded = false;

  private _COLLAPSE_ACTION_NAME = 'collapse';
  private _EXPAND_ACTION_NAME = 'expand';

  public constructor(
    private _el: ElementRef,
    private _renderer: Renderer2,
    _builder: AnimationBuilder
  ) {
    this._factoryCollapseAnimation = _builder.build(collapseAnimation);
    this._factoryExpandAnimation = _builder.build(expandAnimation);
  }

  public ngAfterViewChecked(): void {
    this._stylesLoaded = true;
  }

  /** allows to manually toggle content visibility */
  public toggle(): void {
    if (this.isExpanded) {
      this.hide();
    } else {
      this.show();
    }
  }

  /** allows to manually hide content */
  public hide(): void {
    this.isCollapsing = true;
    this.isExpanded = false;
    this.isCollapsed = true;
    this.isCollapsing = false;

    this.collapses.emit(this);

    this.animationRun(this.isAnimated, this._COLLAPSE_ACTION_NAME)(() => {
      this.collapsed.emit(this);
      this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
    });
  }
  /** allows to manually show collapsed content */
  public show(): void {
    this._renderer.setStyle(this._el.nativeElement, 'display', this._display);

    this.isCollapsing = true;
    this.isExpanded = true;
    this.isCollapsed = false;
    this.isCollapsing = false;

    this.expands.emit(this);

    this.animationRun(this.isAnimated, this._EXPAND_ACTION_NAME)(() => {
      this.expanded.emit(this);
    });
  }

  public animationRun(isAnimated: boolean, action: string): (callback: () => void) => void {
    if (!isAnimated || !this._stylesLoaded) {
      return (callback: () => void) => callback();
    }

    this._renderer.setStyle(this._el.nativeElement, 'overflow', 'hidden');
    this._renderer.addClass(this._el.nativeElement, 'collapse');

    const factoryAnimation = (action === this._EXPAND_ACTION_NAME)
      ? this._factoryExpandAnimation
      : this._factoryCollapseAnimation;

    if (this._player) {
      this._player.destroy();
    }

    this._player = factoryAnimation.create(this._el.nativeElement);
    this._player.play();

    return callback => this._player.onDone(callback);
  }
}
