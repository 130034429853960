import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { EmissionsWidgetOptions } from '../../services/emissions-widget.service';
import { formControlsFrom } from '@enerkey/ts-utils';

type ChangeFn = (options: EmissionsWidgetOptions) => void;

type FormValues = EmissionsWidgetOptions;

@Component({
  selector: 'emissions-widget-options',
  templateUrl: './emissions-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmissionsWidgetOptionsComponent),
    multi: true,
  }]
})
export class EmissionsWidgetOptionsComponent implements ControlValueAccessor, OnInit {
  @Input() public initialState: EmissionsWidgetOptions;

  public formGroup: UntypedFormGroup;

  private _onChange: ChangeFn;

  public ngOnInit(): void {
    const values = this.optionsToForm(this.initialState);
    const controls = formControlsFrom<FormValues>(
      values,
      Object.keys(values).toRecord(key => key, () => Validators.required)
    );
    this.formGroup = new UntypedFormGroup(controls);

    this.formGroup.valueChanges
      .subscribe((formValue: FormValues) => {
        this._onChange?.(formValue);
      });
  }

  public writeValue(value: EmissionsWidgetOptions): void {
    this.formGroup.setValue(this.optionsToForm(value));
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  private optionsToForm(options: EmissionsWidgetOptions): FormValues {
    return { ...options };
  }
}
