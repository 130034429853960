import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, take, takeUntil } from 'rxjs/operators';

import { indicate, LoadingSubject } from '@enerkey/rxjs';
import { generatePassword } from '@enerkey/ts-utils';
import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import {
  UpsertUserDto,
  UserManagementClient,
  UserViewModel,
} from '@enerkey/clients/user-management';

import { EnvironmentService } from '../../../../services/environment-service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { Roles } from '../../constants/roles';
import { RoleService } from '../../services/role.service';
import { UserManagementService } from '../../services/user-management.service';

type UserForm = Omit<UpsertUserDto, 'isHuman'> & { isMachine: boolean };

const DEFAULT_ROLES: string[] = [
  Roles.DOCUMENT_READ,
  Roles.DOCUMENT_WRITE
];

@Component({
  templateUrl: './user-operation-modal.component.html',
  styleUrls: ['./user-operation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserManagementService]
})
export class UserOperationModalComponent extends ModalBase<void> implements OnInit, OnDestroy {

  public selectedUser: UserViewModel;
  public userForm: UntypedFormGroup;

  public readonly isProduction: boolean;
  public readonly loading$: Observable<boolean>;
  public hasAccessToAllProfiles$: Observable<boolean>;

  private readonly _destroy$ = new Subject<void>();
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    private readonly dialogService: DialogService,
    private readonly userManagementClient: UserManagementClient,
    private readonly toasterService: ToasterService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly roleService: RoleService,
    private readonly environmentService: EnvironmentService,
    currentModal: NgfActiveModal
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.isProduction = this.environmentService.isProduction();
  }

  public ngOnInit(): void {
    this.userForm = new UntypedFormGroup({
      userName: new UntypedFormControl(this.selectedUser?.userName ?? null, [Validators.required]),
      firstName: new UntypedFormControl(null),
      lastName: new UntypedFormControl(null),
      preferredLanguage: new UntypedFormControl(null),
      thirdPartySystemName: new UntypedFormControl(null),
      accountEndDate: new UntypedFormControl(null),
      companyId: new UntypedFormControl(null, Validators.required),
      comment: new UntypedFormControl(null),
      isMachine: new UntypedFormControl(false),
      profileIds: new UntypedFormControl([]),
      roleIds: new UntypedFormControl([], Validators.required),
      password: new UntypedFormControl(generatePassword())
    });

    this.hasAccessToAllProfiles$ = combineLatest([
      this.roleService.getRoles().pipe(
        map(roles => roles.find(r => r.name === Roles.HAS_ACCESS_TO_ALL_PROFILES)?.id)
      ),
      this.userForm.valueChanges.pipe(
        map((value: UserForm) => value.roleIds),
        startWith(this.selectedUser?.roleIds ?? [])
      )
    ]).pipe(
      map(([allProfilesRoleId, userRoleIds]) => userRoleIds.includes(allProfilesRoleId)),
      takeUntil(this._destroy$)
    );

    // EDIT MODE
    if (this.selectedUser) {
      this.userForm.patchValue({
        userName: this.selectedUser.userName,
        firstName: this.selectedUser.firstName,
        lastName: this.selectedUser.lastName,
        preferredLanguage: this.selectedUser.preferredLanguage,
        thirdPartySystemName: this.selectedUser.thirdPartySystemName,
        accountEndDate: this.selectedUser.accountEndDate,
        companyId: this.selectedUser.companyId,
        comment: this.selectedUser.comment,
        profileIds: this.selectedUser.profileIds,
        roleIds: this.selectedUser.roleIds,
        password: null,
      });
    } else {
      // CREATE MODE
      this.roleService.getRoles().pipe(
        map(roles => roles.filterMap(
          r => DEFAULT_ROLES.includes(r.name),
          r => r.id
        ))
      ).subscribe(defaultRoles => {
        this.userForm.patchValue({ roleIds: defaultRoles });
      });
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public submit(dismissModal: boolean): void {
    const data: UserForm = this.userForm.value;
    this.hasAccessToAllProfiles$.pipe(
      take(1)
    ).subscribe(allProfileAccess => {
      if (!Array.hasItems(data.profileIds) && !allProfileAccess) {
        this.dialogService.getConfirmationModal({
          text: 'ADMIN.SAVE_WITHOUT_PROFILE',
          title: 'ADMIN.SAVE_WITHOUT_PROFILE_MODAL_TITLE',
          translate: true
        }).subscribe(() => this.saveChanges(dismissModal));
      } else {
        this.saveChanges(dismissModal);
      }
    });
  }

  public deleteUser(): void {
    this.dialogService.getConfirmationModal({
      text: 'ADMIN.CONFIRM_DELETE_USER',
      title: 'ADMIN.DELETE_USER',
      isDelete: true,
      translate: true
    }).subscribe({
      next: () => {
        this.userManagementClient.delete2(this.selectedUser.id)
          .subscribe({
            next: () => {
              super.closeModal();
              this.toasterService.success('ADMIN.USERDELETED');
            },
            error: () => this.toasterService.error('ADMIN.USERDELETED_ERROR')
          });
      }
    });
  }

  public createNewPassword(): void {
    this.dialogService.getConfirmationModal({
      text: 'ADMIN.RENEWPASSWORD_CONFIRMATION',
      title: 'ADMIN.NEWPASSWORD',
      isDelete: false,
      translate: true
    }).subscribe({
      next: () => {
        this.userForm.patchValue({ password: generatePassword() });
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  public dismiss(): void {
    super.dismissModal();
  }

  private saveChanges(dismissModal: boolean): void {
    const formValues: UserForm = this.userForm.value;
    const data = new UpsertUserDto({
      ...formValues,
      email: formValues.userName,
      isHuman: !formValues.isMachine,
    });

    const request: Observable<unknown> = this.selectedUser
      ? this.userManagementClient.updateUser(
        this.selectedUser.id,
        new UpsertUserDto(data)
      )
      : this.userManagementClient.addUser(
        new UpsertUserDto(data)
      );

    request.pipe(
      indicate(this._loading$)
    ).subscribe({
      next: () => {
        if (this.selectedUser) {
          this.toasterService.success('ADMIN.MODIFY_USERS.SAVE_SUCCESS');
        } else {
          this.toasterService.success('ADMIN.USERCREATED');
        }
        if (dismissModal) {
          super.closeModal();
        } else {
          this.resetForm();
        }
      },
      error: errors => {
        const errorResponse: { errors?: string[] } | { ErrorId?: string }[] = JSON.parse(errors.response);
        if (Array.isArray(errorResponse)) {
          for (const error of errorResponse) {
            if (error.ErrorId) {
              this.toasterService.warning(`ADMIN.BULK_SAVE_ERROR_${error.ErrorId}`);
            } else {
              this.toasterService.error('ADMIN.USERCREATED_ERROR');
            }
          }
        } else if (!Array.isArray(errorResponse) && errorResponse.errors) {
          this.generateErrorMessage(errorResponse.errors);
        }
      }
    });
  }

  private resetForm(): void {
    this.selectedUser = undefined;
    const data: UserForm = this.userForm.value;
    data.userName = null;
    data.firstName = null;
    data.lastName = null;
    data.password = generatePassword();
    this.userForm.reset(data);
  }

  private generateErrorMessage(errors: string[]): void {
    for (const error of errors) {
      if (/already taken/i.test(error)) {
        this.toasterService.error({
          key: 'ADMIN.USER_ALREADY_EXISTS_ERROR',
          params: { username: this.userForm.value.userName }
        });
      } else {
        this.toasterService.error('ADMIN.USERCREATED_ERROR');
      }
    }
  }
}
