<a
  class="topbar-icon-item"
  (click)="openHelpEntry()"
  *ngIf="mobile; else dropdownHelp"
>
  <i class="fa fa-question-circle"></i>
  <span class="topbar-mobile-title">{{ 'TOPBAR.HELP' | translate }}</span>
</a>

<ng-template #dropdownHelp>
  <ek-dropdown [showOnHover]="true" position="left" *ngIf="hasLargeServiceLevel; else simpleHelp">
    <div
      class="topbar-icon-item"
      *ekDropdownToggle
    >
      <i class="fa fa-question-circle"></i>
    </div>
    <div class="ek-dropdown-menu__content">
      <ek-dropdown-heading>
        {{ 'TOPBAR.ENERKEY_HELP' | translate }}
      </ek-dropdown-heading>
      <ek-dropdown-item
        (click)="openHelpEntry()"
      >
        {{ 'TOPBAR.TABLE_OF_CONTENTS' | translate }}
      </ek-dropdown-item>
      <ek-dropdown-item
        *ngFor="let entry of entries"
        (click)="openHelpEntry(entry)"
      >
        {{ entry.title }}
      </ek-dropdown-item>
    </div>
  </ek-dropdown>
</ng-template>

<ng-template #simpleHelp>
  <a
    class="topbar-icon-item"
    (click)="openHelpEntry()"
    [ngfTooltip]="'TOPBAR.HELP' | translate"
    placement="bottom"
    alignment="right"
  >
    <i class="fa fa-question-circle"></i>
  </a>
</ng-template>
