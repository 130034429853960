import * as Configs from '../../constants/configs';
import Meter from '../../shared/meter';

const $inject = ['ManualQaApiService', 'ManualQaApiQuantityService'];

class ManualQaApiInfoService {
  constructor(ManualQaApiService, ManualQaApiQuantityService) {
    this.ManualQaApiService = ManualQaApiService;
    this.ManualQaApiQuantityService = ManualQaApiQuantityService;
  }

  /**
   * Returns meter information from backend.
   *
   * @param {Array} meterIds
   *
   * @returns {Object}
   */
  getMeterInfo(meterIds) {
    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}info/meterfacilitybymeterids`,
      data: meterIds
    })
      .then(result => this.ManualQaApiQuantityService
        .getQuantities()
        .then(quantities => result.data.sortBy(m => m.facility.enegiaId).map((data, index) => {
          const quantity = quantities.find(q => q.id === data.meter.quantityId);
          return new Meter({ ...data.meter, quantity, facility: data.facility, index: index + 1 });
        })));
  }
}

ManualQaApiInfoService.$inject = $inject;

export default ManualQaApiInfoService;
