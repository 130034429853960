import _ from 'lodash';

/**
 * Splits words in string into an array, words are separated with space and/or comma
 *
 * @param {String} input string
 *
 * @returns {[String]} list of strings
 */
function splitStringToArray(input) {
  return input
    .replace(/,+/g, ' ')
    .trim()
    .split(/\s+/);
}

function listPaste() {
  function link(scope, element, attrs, ngModel) {
    function parseData(data, target) {
      const beforeSelection = getItemsBeforeSelection(target);
      const afterSelection = getItemsAfterSelection(target);
      const pastedValue = splitStringToArray(data);

      const newValue = [...beforeSelection, ...pastedValue, ...afterSelection].join(',');
      ngModel.$setViewValue(newValue);
      element.val(newValue);
    }

    function getItemsBeforeSelection(target) {
      let beforeSelection = element.val().substring(0, target.selectionStart);
      beforeSelection = splitStringToArray(beforeSelection);
      return removeEmptyElements(beforeSelection);
    }

    function getItemsAfterSelection(target) {
      let afterSelection = element.val().substring(target.selectionEnd);
      afterSelection = splitStringToArray(afterSelection);
      return removeEmptyElements(afterSelection);
    }

    function removeEmptyElements(array) {
      return _.filter(array);
    }

    element.on('paste', evt => {
      const data = evt.originalEvent.clipboardData.getData('text/plain');
      parseData(data, evt.target);
      return false;
    });

    scope.$on('$destroy', () => {
      element.off('paste');
    });
  }

  return {
    restrict: 'A',
    replace: true,
    require: 'ngModel',
    link: link
  };
}

export default listPaste;
