import template                        from 'raw-loader!./manual-qa-inspect-button.html';
import ManualQaInspectButtonController from './manual-qa-inspect-button.controller';

const ManualQaInspectButtonComponent = {
  template: template,
  controller: ManualQaInspectButtonController,
  controllerAs: 'vm',
  bindings: {
    meterIds: '<',
    timeFrame: '<',
    searchType: '<'
  }
};

export default ManualQaInspectButtonComponent;
