<div
  *ngFor="let group of groupedQuantities"
  class="sidebar-quantity-group"
>
  <div
    class="sidebar-quantity-group-header"
    (click)="collapsible.toggle()"
  >
    <input
      kendoCheckBox
      type="checkbox"
      [indeterminate]="group.selection === undefined"
      [(ngModel)]="group.selection"
      (ngModelChange)="groupToggled(group)"
      (click)="$event.stopPropagation()"
    >
    <label>
      {{ group.key | translate }}
    </label>
    <i class="fa"
      [ngClass]="group.collapsed ? 'fa-chevron-right' : 'fa-chevron-down'"
    ></i>
  </div>
  <div
    [collapse]="group.collapsed"
    #collapsible="bs-collapse"
    class="sidebar-quantity-group-content"
    (collapsed)="group.collapsed = $event.isCollapsed"
    (expanded)="group.collapsed = $event.isCollapsed"
  >
    <div class="sidebar-quantity-group-section" *ngIf="group.quantities.length">
      <div *ngFor="let quantity of group.quantities">
        <ng-container *ngTemplateOutlet="checkboxTemplate; context: { $implicit: quantity }"></ng-container>
      </div>
    </div>
    <div class="sidebar-quantity-group-section" *ngIf="group.sumQuantities.length">
      <div *ngFor="let quantity of group.sumQuantities">
        <ng-container *ngTemplateOutlet="checkboxTemplate; context: { $implicit: quantity, sumQuantity: true }"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template
  #checkboxTemplate
  let-quantity
  let-sumQuantity="sumQuantity"
>
  <label class="sidebar-quantity-select-item">
    <div class="sidebar-quantity-icon-container">
      <input
        kendoCheckBox
        type="checkbox"
        [(ngModel)]="states[quantity]"
        (ngModelChange)="toggled(quantity)"
      >
      <i
        class="icon-span {{ iconClasses[quantity] }} {{ iconClasses[quantity] + '--sidebar' }}"
        [class.sum-quantity]="sumQuantity"
      ></i>
    </div>
    <span [class.quantity-selected]="states[quantity]">{{ quantity | quantityName | async }}</span>
  </label>
</ng-template>
