<div class="consumptions-widget-period-title">
  <span>
    {{ inspectionPeriodTitle }}
    <i class="fas fa-exchange-alt"></i>
    {{ comparisonPeriodTitle }}
  </span>
</div>
<div class="consumptions-chart-grid">
  <consumptions-widget-chart
    *ngFor="let quantity of (data$ | async)"
    (click)="openSumReport(quantity)"
    [quantityData]="quantity"
    [unitKey]="dataModelOptions.unitKey"
    [resolution]="start.Resolution"
    [style.cursor]="hasServiceLevelMedium ? 'pointer' : 'initial'"
  >
  </consumptions-widget-chart>
</div>
