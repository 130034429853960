import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

import { assertType, TypeExtends } from '@enerkey/ts-utils';

export const kendoNumericTextboxOptions = {
  decimals: 2,
  format: '#.##',
  step: 1,
  autoCorrect: true,
  spinners: false,
} as const;

assertType<TypeExtends<typeof kendoNumericTextboxOptions, NumericTextBoxComponent>>();
