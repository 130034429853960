import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MeteringType as MeteringTypeManagement } from '@enerkey/clients/meter-management';
import { MeteringType } from '@enerkey/clients/metering';

@Component({
  selector: 'meter-type',
  templateUrl: './meter-type.component.html',
  styleUrls: ['./meter-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeterTypeComponent {
  @Input() public meterType: MeteringType | MeteringTypeManagement = undefined;
  @Input() public showText = true;
  @Input() public showIcon = true;
  @Input() public iconSize: number = 11.4;
}
