<span
  kendoTooltip
  [attr.title]="!isActive ? ('ADMIN.VIRTUAL_METERS.HISTORY.PASSIVE' | translate) : null"
  [ngClass]="{'passive': !isActive }">
  <span class="bold" *ngIf="isInEffect; else dateTemplate">
    {{'ADMIN.VIRTUAL_METERS.HISTORY.IN_EFFECT_NOW' | translate}}
  </span>
  <ng-template #dateTemplate>
    <ng-container>
      {{ dateToShow | todate | date:'shortDate' }}
    </ng-container>
  </ng-template>
</span>
