import { NgZone } from '@angular/core';
import { fromEvent, Observable, race } from 'rxjs';
import { delay, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { Key } from './key';

function isContainedIn(element: HTMLElement, array?: HTMLElement[]): boolean {
  return !!array?.some(item => item.contains(element));
}

function matchesSelectorIfAny(element: HTMLElement, selector?: string): boolean {
  if (!selector) {
    return true;
  }
  const closestElement = closest(element, selector);
  return closestElement !== null && closestElement !== undefined;
}

function closest(element: HTMLElement, selector: string): Element {
  if (!selector) {
    return null;
  }

  return element.closest(selector);
}

// we'll have to use 'touch' events instead of 'mouse' events on iOS and add a more significant delay
// to avoid re-opening when handling (click) on a toggling element
// TODO: use proper Angular platform detection when NgfAutoClose becomes a service and we can inject PLATFORM_ID
let iOS = false;
if (typeof navigator !== 'undefined') {
  iOS = !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);
}

export function ngfAutoClose(
  zone: NgZone,
  _document: Document,
  type: boolean | 'inside' | 'outside',
  close: () => void,
  closed$: Observable<any>,
  insideElements: HTMLElement[],
  ignoreElements?: HTMLElement[],
  insideSelector?: string
): void {
  if (!type) {
    return;
  }

  // closing on ESC and outside clicks
  zone.runOutsideAngular(() => {
    function shouldCloseOnClick(event: MouseEvent | TouchEvent): boolean {
      const element = event.target as HTMLElement;
      if ((event instanceof MouseEvent && event.button === 2) || isContainedIn(element, ignoreElements)) {
        return false;
      }
      if (type === 'inside') {
        return isContainedIn(element, insideElements) && matchesSelectorIfAny(element, insideSelector);
      } else if (type === 'outside') {
        return !isContainedIn(element, insideElements);
      } else /* if (type === true) */ {
        return matchesSelectorIfAny(element, insideSelector) || !isContainedIn(element, insideElements);
      }
    }

    const escapes$ = fromEvent<KeyboardEvent>(_document, 'keydown').pipe(
      takeUntil(closed$),
      filter(e => e.which === Key.Escape)
    );

    // we have to pre-calculate 'shouldCloseOnClick' on 'mousedown/touchstart',
    // because on 'mouseup/touchend' DOM nodes might be detached
    const mouseDowns$ = fromEvent<MouseEvent>(_document, iOS ? 'touchstart' : 'mousedown').pipe(
      map(shouldCloseOnClick),
      takeUntil(closed$)
    );

    const closeableClicks$ = fromEvent<MouseEvent>(_document, iOS ? 'touchend' : 'mouseup').pipe(
      withLatestFrom(mouseDowns$),
      filter(([_, shouldClose]) => shouldClose),
      delay(iOS ? 16 : 0),
      takeUntil(closed$)
    );

    race([escapes$, closeableClicks$]).subscribe(() => zone.run(close));
  });
}
