import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FacilityService } from '../services/facility.service';

@Pipe({ name: 'localeIdName' })
export class LocaleIdNamePipe implements PipeTransform {

  public constructor(
    private readonly facilityService: FacilityService
  ) { }

  public transform(id: number): Observable<string> {
    return this.facilityService.localeNames$.pipe(map(names => names[id]));
  }
}
