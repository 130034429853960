import { ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { StringKeys } from '@enerkey/ts-utils';

export abstract class SelectComponent<T extends object, K extends keyof T> implements ControlValueAccessor {
  public abstract valueField: K;
  public abstract textField: StringKeys<T>;
  public abstract defaultItem: T;

  public sourceData: T[] = [];
  public disabled = false;
  public value: T[K];
  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  protected updateValue: (param: T[K]) => void;
  private touched: (param: T[K]) => void;

  public constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {
  }

  public valueChanged(item: T[K]): void {
    this.value = item;
    if (this.touched) {
      this.touched(this.value);
    }
    if (this.updateValue) {
      this.updateValue(this.value);
    }
  }

  public writeValue(value: T[K]): void {
    this.value = value;
    this.changeDetectorRef.detectChanges();
  }

  public registerOnChange(fn: (param: T[K]) => void): void {
    this.updateValue = fn;
  }

  public registerOnTouched(fn: (param: T[K]) => void): void {
    this.touched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
