import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { ServiceViewModel, UserManagementClient } from '@enerkey/clients/user-management';

@Injectable({ providedIn: 'root' })
export class ServicesService {
  public readonly serviceNames$: Observable<string[]>;
  public readonly services$: Observable<ServiceViewModel[]>;

  public constructor(userManagementClient: UserManagementClient) {
    this.services$ = userManagementClient.getServices().pipe(
      shareReplay(1)
    );
    this.serviceNames$ = this.services$.pipe(
      map(services => services.map(service => service.name)),
      shareReplay(1)
    );
  }

  public getServiceNames(): Observable<string[]> {
    return this.serviceNames$.pipe(take(1));
  }

  public getServices(): Observable<ServiceViewModel[]> {
    return this.services$.pipe(take(1));
  }
}
