import { Injectable } from '@angular/core';
import { Transition, TransitionService } from '@uirouter/core';
import { BehaviorSubject, combineLatest, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Bookmark, CreateBookmark, RollingDateType, SettingsClient } from '@enerkey/clients/settings';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { TimeFrame } from './time-frame-service';
import { ProfileService } from '../shared/services/profile.service';
import { UserService } from './user-service';

export type State = {
  modal: boolean;
  name: string;
  stateParams: Record<string, unknown>;
}

export type Bookmarks = {
  user: Bookmark[];
  sticky: Bookmark[];
  shared: Bookmark[];
}

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {
  public readonly bookmarks$: Observable<Bookmarks>;
  public readonly currentState$: Observable<State>;
  public readonly variableBookmarkType$: Observable<RollingDateType>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject(false);
  private readonly _variableBookmarkType$ = new ReplaySubject<RollingDateType>(1);
  private readonly _state$ = new ReplaySubject<State>(1);
  private readonly _stickyBookmarks$ = new BehaviorSubject<Bookmark[]>([]);
  private readonly _refresh$ = new Subject<void>();

  private readonly profileBookmarks$: Observable<Bookmark[]>;

  public constructor(
    private readonly profileService: ProfileService,
    private readonly settingsClient: SettingsClient,
    private readonly userService: UserService,
    transitionService: TransitionService
  ) {
    this.loading$ = this._loading$.asObservable();

    this.profileBookmarks$ = merge(
      this.profileService.profileId$,
      this._refresh$.pipe(
        switchMap(() => this.profileService.profileId$)
      )
    ).pipe(
      switchMap(profileId => this.settingsClient.getBookmarks(profileId).pipe(
        indicate(this._loading$)
      )),
      map(bookmarks => bookmarks.sortBy('created', 'desc'))
    );

    this.bookmarks$ = combineLatest([
      this._stickyBookmarks$,
      this.profileBookmarks$
    ]).pipe(
      map(([sticky, bookmarks]) => ({
        sticky: sticky,
        user: bookmarks.filter(b => !b.shared),
        shared: bookmarks.filter(b => b.shared).sortBy('createdByCurrentUser', 'desc')
      })),
      shareReplay(1)
    );

    this.currentState$ = this._state$.asObservable();
    this.variableBookmarkType$ = this._variableBookmarkType$.asObservable();

    transitionService.onSuccess({}, transition => {
      this.handleStateChange(transition);
    });
  }

  public setStickyBookmarks(stickyBookmarks: Bookmark[]): void {
    this._stickyBookmarks$.next(stickyBookmarks);
  }

  public handleDateVariableBookmark(bookmark: Bookmark): Bookmark {
    if (bookmark.rollingDate === RollingDateType.Month) {
      bookmark.stateParams.series.Start = TimeFrame.setMonthVariableDates(
        bookmark.stateParams.series.TimeFrame,
        bookmark.stateParams.series.Resolution,
        bookmark.created.toISOString(), bookmark.stateParams.series.Start
      );
    } else if (bookmark.rollingDate === RollingDateType.Quarter) {
      bookmark.stateParams.series.Start = TimeFrame.setQuarterVariableDates(
        bookmark.stateParams.series.TimeFrame,
        bookmark.stateParams.series.Resolution,
        bookmark.created.toISOString(), bookmark.stateParams.series.Start
      );
    } else if (bookmark.rollingDate === RollingDateType.Year) {
      bookmark.stateParams.series.Start = TimeFrame.setYearVariableDates(
        bookmark.stateParams.series.TimeFrame,
        bookmark.stateParams.series.Resolution,
        bookmark.created.toISOString(), bookmark.stateParams.series.Start
      );
    }
    return bookmark;
  }

  public createBookmark(name: string, state: State, dateAdjustable = false): Observable<unknown> {
    const dateVariableType = dateAdjustable
      ? this.initCurrentDateVariableType(state.stateParams)
      : RollingDateType.None;
    const newBookmark = new CreateBookmark({
      title: name,
      state: !state.modal ? state.name : undefined,
      modal: state.modal ? state.name : undefined,
      stateParams: state.stateParams,
      rollingDate: dateVariableType
    });
    return this.settingsClient.createBookmark(this.userService.profileId, newBookmark).pipe(
      tap(() => this._refresh$.next())
    );
  }

  public setShareStatus(bookmarkId: number, shared: boolean): Observable<unknown> {
    return this.settingsClient.setBookmarkSharing(this.userService.profileId, bookmarkId, shared).pipe(
      tap(() => this._refresh$.next())
    );
  }

  public removeBookmark(bookmarkId: number): Observable<unknown> {
    return this.settingsClient.deleteBookmark(this.userService.profileId, bookmarkId).pipe(
      tap(() => this._refresh$.next())
    );
  }

  public handleModalChange(modal: State): void {
    if (modal.modal) {
      this._state$.next(modal);
      this._variableBookmarkType$.next(
        this.initCurrentDateVariableType(modal.stateParams)
      );
    } else {
      this._state$.next(null);
      this._variableBookmarkType$.next(RollingDateType.None);
    }
  }

  private handleStateChange(transition: Transition): void {
    if (transition.to()?.data?.bookmark?.enabled) {
      this._state$.next({
        modal: false,
        name: transition.to().name,
        stateParams: transition.params()
      });
      this._variableBookmarkType$.next(
        this.initCurrentDateVariableType(transition.params())
      );
    } else {
      this._state$.next(null);
      this._variableBookmarkType$.next(RollingDateType.None);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private initCurrentDateVariableType(stateParams: Record<string, any>): RollingDateType {
    if (!stateParams?.series?.Start || !stateParams?.series?.TimeFrame) {
      return RollingDateType.None;
    }
    const lastIndex = stateParams.series.Start.length - 1;
    if (lastIndex < 0) {
      return RollingDateType.None;
    }
    const lastValue = stateParams.series.Start[lastIndex].value;

    if (TimeFrame.isPreviousMonthStart(stateParams.series.TimeFrame, lastValue)) {
      return RollingDateType.Month;
    }
    if (TimeFrame.isPreviousQuarterStart(stateParams.series.TimeFrame, lastValue)) {
      return RollingDateType.Quarter;
    }
    if (TimeFrame.isPreviousYearStart(stateParams.series.TimeFrame, lastValue)) {
      return RollingDateType.Year;
    }
    return RollingDateType.None;
  }
}
