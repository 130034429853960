<ng-container *ngIf="item">
  <div>
    <overview-card-field
      [value]="item[timeRange]?.Value"
      [title]="item.Unit"
      [decimalsToShow]="item.DecimalsToShow"
      [difference]="item[timeRange]?.Difference"
      [differencePercent]="item[timeRange]?.DifferencePercent"
      [incomplete]="item[timeRange]?.Incomplete"
      [showDiff]="item[timeRange]?.ShowDiff"
    >
    </overview-card-field>
  </div>
  <ng-container *ngIf="!item[timeRange]?.Incomplete" >
    <div *ngIf="item[timeRange]?.Costs">
      <overview-card-field
        [value]="item[timeRange].Costs.Value"
        [title]="'&#8364;'"
        [decimalsToShow]="item.DecimalsToShow"
        [difference]="item[timeRange].Costs.Difference"
        [differencePercent]="item[timeRange].Costs.DifferencePercent"
      >
      </overview-card-field>
    </div>
    <div *ngIf="item[timeRange]?.Emissions && expanded">
      <overview-card-field
        [value]="item[timeRange].Emissions.Value"
        [title]="'tCO&#178;'"
        [decimalsToShow]="item.DecimalsToShow"
        [difference]="item[timeRange].Emissions.Difference"
        [differencePercent]="item[timeRange].Emissions.DifferencePercent"
      >
      </overview-card-field>
    </div>
  </ng-container>
</ng-container>
