'use strict';
import _ from 'lodash';

function MrcFilterService() {
  return {
    getSortedYearsArray: getSortedYearsArray
  };

  /**
   * Returns sorted years array that contains years only.
   *
   * @param {*} readings
   */
  function getSortedYearsArray(readings) {
    var getYears = function(reading) {
      if (reading.Date) {
        return new Date(reading.Date).getFullYear();
      }
      return null;
    };

    return _
      .chain(readings)
      .map(getYears)
      .reject(_.isNull)
      .uniq()
      .value()
      .sort()
    ;
  }
}

export default MrcFilterService;
