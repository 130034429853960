import { Directive, HostBinding, Input } from '@angular/core';

/**
 * Adds a spinner-animation and disables the host button. Requires `.button`-class for styling reasons.
 *
 * Can be used in combination with regular `disabled`-attribute.
 *
 * @example
 * ```html
 * <button class="button" [disabled]="!form.valid" [loading]="loading$">Submit</button>
 * ```
 */
@Directive({ selector: 'button.button[loading]' })
export class ButtonLoadingDirective {

  /** Input that disables the button and adds `.loading`-class. */
  @Input() public loading: boolean;

  /** Input that disables the button, can be used in conjunction with `loading`. */
  @Input() public disabled: boolean;

  /**
   * In case the button isn't disabled already, disables it
   * if `loading` evaluates to true.
   */
  @HostBinding('disabled')
  public get disableButton(): boolean {
    return this.disabled || this.loading;
  }

  @HostBinding('class.loading')
  public get loadingClass(): boolean {
    return this.loading;
  }

}
