import { Component, HostBinding, Input, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'content-loader',
  template: '',
  styleUrls: ['./content-loader.component.scss']
})
export class ContentLoaderComponent {

  /** Spinner width and height in pixels (default: 64) */
  @Input() @HostBinding('style.font-size.px') public size: number = 64;

  @HostBinding('class.k-i-loading') public readonly _loading = true;
  @HostBinding('class.loading-overlay') public hasOverlay: boolean;

  public constructor(
    public readonly vcRef: ViewContainerRef
  ) { }
}
