import { finalize } from 'rxjs/operators';
import { IAngularStatic } from 'angular';
import { InspectionDefectItem, InspectionItem, SearchList } from '@enerkey/clients/manual-qa';
import { ManualQaApiSearchService } from '../../services/manual-qa-api-search-service';
import { NotImplementedError } from '../../shared/not-implemented-error';

declare const angular: IAngularStatic;

const $inject = [
  'ManualQaApiSearchService', 'ManualQaPopupService', 'Scroll', 'ManualQaInspectModalService'
];

class ManualQaSearchController {
  public items: InspectionItem[] | InspectionDefectItem[];
  public searchList: SearchList;
  public loading: boolean;
  public readerTypes: any[];

  public constructor(
    private manualQaApiSearchService: ManualQaApiSearchService,
    private ManualQaPopupService: any,
    private Scroll: any,
    private ManualQaInspectModalService: any
  ) {

    this.items = [];
  }

  public $onInit(): void {
    this.searchList = this.searchList instanceof SearchList ? angular.copy(this.searchList) : new SearchList();

    if (!this.searchList.isNew) {
      this.getData(this.searchList);
    }
  }

  /**
   * Returns items form backend.
   *
   * @param {SearchList} searchList
   */
  public getData(searchList: SearchList): void {
    this.searchList = searchList;
    this.items = [];
    this.loading = true;

    this.manualQaApiSearchService.getInspectionItems(searchList.getSearchCriteria(), this.readerTypes)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        {
          next: result => {
            const { errors, items } = result;

            if (errors.searchParametersYieldedTooLargeResultSet) {
              this.ManualQaPopupService.errorPopUp('MQA.ERRORS.TOO_MANY_SEARCH_RESULTS');
              return;
            }

            if (!Array.hasItems(items)) {
              this.ManualQaPopupService.infoPopUp('MQA.INFO.NO_SEARCH_RESULTS');
              return;
            }

            this.items = items;
          },
          error: error => {
            if (error instanceof NotImplementedError) {
              this.ManualQaPopupService.infoPopUp(error.message);
            } else {
              this.ManualQaPopupService.errorPopUp('MQA.ERRORS.FAILED_LOADING_METERING_POINTS');
            }
          }
        }
      );
  }

  public resetSearch(): void {
    this.searchList = new SearchList();
    this.items = [];
  }

  public onInspect(items: (InspectionItem | InspectionDefectItem)[]): void {
    const timeFrame = this.searchList.getSearchCriteria().getTimeFrame();
    const searchType = this.searchList.getSearchCriteria().getSearchType();
    const meterIds = items.map(item => item.meterId);

    this.ManualQaInspectModalService.getInspectModal(meterIds, timeFrame, searchType);
  }

  public onSearchListChange(searchList: SearchList): void {
    this.searchList = searchList;
  }

  public onEditSearchList(): void {
    this.Scroll.scrollTop();
  }

  public onViewVisuals(): void {
    this.Scroll.scrollTo('#mqa-statistics-visuals');
  }
}

ManualQaSearchController.$inject = $inject;

export default ManualQaSearchController;
