import EmFacilitySelectController from './em-facility-select-controller';
import emFacilitySelectTemplate   from 'raw-loader!./em-facility-select.html';

export default {
  template: emFacilitySelectTemplate,
  controller: EmFacilitySelectController,
  controllerAs: 'vm',
  bindings: {
    facilities: '<',
    selectedFacilityIds: '<',
    selectionMode: '@',
    onChange: '<',
    optionAll: '<'
  }
};
