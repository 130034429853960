import { IMeterManagementMeter } from '@enerkey/clients/meter-management';
import { $InjectArgs } from '@enerkey/ts-utils';

import { Utils } from '../../../../services/utils';

export interface MeterMassEditMeter extends IMeterManagementMeter {
  enegiaId: number;
  tags?: string[];
}

export class MeterMassEditModalController {
  public static $inject: $InjectArgs<typeof MeterMassEditModalController> = [
    '$modalInstance', 'selectedMeters', 'utils'
  ];

  public title: string;
  private spreadsheetReference: kendo.ui.Spreadsheet;
  private editsDone = false;

  public constructor(
    private readonly $modalInstance: any,
    public readonly selectedMeters: MeterMassEditMeter[],
    utils: Utils
  ) {
    this.selectedMeters = selectedMeters;
    this.$modalInstance = $modalInstance;
    this.title = utils.localizedString('ADMIN.BULK_MODIFY_METERS.MASS_EDIT_METERS_TITLE');
  }

  public onExcelDownLoad(): void {
    this.spreadsheetReference.saveAsExcel();
  }

  public setSpreadsheet(spreadsheet: kendo.ui.Spreadsheet): void {
    this.spreadsheetReference = spreadsheet;
  }

  public setEditsDone(value: boolean): void {
    this.editsDone = value;
  }

  public handleModalClose(): void {
    if (this.editsDone) {
      this.$modalInstance.close();
    } else {
      this.$modalInstance.dismiss();
    }
  }
}
