import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormArray, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { debounceTime, merge, Observable, Subject, takeUntil } from 'rxjs';

import { ReportingSearchFormControls } from '../reporting-search-form/reporting-search-form.component';
import { TimePeriodHistory, TimePeriodHistoryService } from '../../services/time-period-history.service';

@Component({
  selector: 'time-period-history-dropdown',
  templateUrl: './time-period-history-dropdown.component.html',
  styleUrls: ['./time-period-history-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class TimePeriodHistoryDropdownComponent implements OnInit, OnDestroy {
  @Input() public debounceTime: number;

  public formGroup: FormGroup<ReportingSearchFormControls>;
  public histories$: Observable<TimePeriodHistory[]>;
  public updating: boolean = false;

  private readonly destroy$ = new Subject<void>();

  public constructor(
    private controlContainer: ControlContainer,
    private changeDetectorRef: ChangeDetectorRef,
    private timePeriodHistoryService: TimePeriodHistoryService
  ) {
    this.histories$ = this.timePeriodHistoryService.histories$.pipe(takeUntil(this.destroy$));
  }

  public ngOnInit(): void {
    this.formGroup = <FormGroup> this.controlContainer.control;
    this.timePeriodHistoryService.addHistoryItem(this.getTimePeriodValue());
    this.listenControlValueChanges();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setTimePeriodValue(history: TimePeriodHistory): void {
    this.updating = true;

    const periods = this.formGroup.get('periods') as FormArray;
    periods.clear();

    history.periods.forEach(() => {
      periods.push(new FormControl());
    });

    this.formGroup.patchValue({
      durationName: history.durationName,
      durationLength: history.durationLength,
      resolution: history.resolution,
      periods: history.periods,
    });
  }

  private getTimePeriodValue(): TimePeriodHistory {
    return {
      durationLength: this.formGroup.value.durationLength,
      durationName: this.formGroup.value.durationName,
      resolution: this.formGroup.value.resolution,
      periods: this.formGroup.value.periods
    };
  }

  private listenControlValueChanges(): void {
    merge(
      this.formGroup.get('durationName')?.valueChanges,
      this.formGroup.get('durationLength')?.valueChanges,
      this.formGroup.get('resolution')?.valueChanges,
      this.formGroup.get('periods')?.valueChanges
    ).pipe(
      debounceTime(this.debounceTime),
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.timePeriodHistoryService.addHistoryItem(this.getTimePeriodValue());
      this.updating = false;
      this.changeDetectorRef.markForCheck();
    });
  }
}
