<div class="report-header">
  <h1>{{ meter.customerMeterIdentifier }} - {{ meter.name }} ({{ meter.id }})</h1>
  <div class="facility-header-buttons">
    <facilities-popup-actions
      [actions]="events?.actions | meterReportActions:meter | slice:0:5"
      [actionType]="'action'"
    ></facilities-popup-actions>
    <facilities-popup-actions
      [actions]="events?.comments | meterReportActions:meter | slice:0:5"
      [actionType]="'comment'"
    ></facilities-popup-actions>
    <ng-container *ngIf="events?.alarms | meterReportAlarms:meter.id as alarms">
      <facilities-popup-alarms
        *ngIf="alarms.length"
        [alarms]="alarms | slice:0:5"
        [facilityId]="facility?.facilityId"
        [meterId]="meter.id"
      ></facilities-popup-alarms>
    </ng-container>
    <facilities-popup-documents
      [facility]="facility"
      [meter]="{ Id: meter.id, Name: meter.name }"
    ></facilities-popup-documents>
    <button *ngFor="let image of meterMapImages$ | async"
      (click)="showMeterImageMap(image)"
      class="button icon"
      title="{{ 'FACILITIES_REPORT.METER_MAP' | translate }}"
    >
      <i class="fa fa-map-marker"></i>
    </button>
    <button
      *ngIf="isMeterManager"
      class="button button--link button--compact"
      (click)="showMeterInterfaceStatus()"
    >{{ 'CC' | translate }}</button>
    <ng-container *ngIf="isQaManager">
      <button
        *ngIf="isMeterManager"
        class="button button--link button--compact"
        (click)="openMqaCurrentTime()"
      >MQA</button>
      <button
        *ngIf="isMeterManager"
        class="button button--link button--compact"
        (click)="openMqaSelectedTimeFrame()"
      >MQA ({{ 'FACILITIES_TOOLBAR.SERIE' | translate | lowercase }})</button>
    </ng-container>
  </div>
</div>
<meter-info
  *ngIf="meterInfoVisible$ | async"
  [meter]="meter"
  [meterTags]="meterTags$ | async"
></meter-info>
