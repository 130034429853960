import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { RequestDuration } from '@enerkey/clients/reporting';

import { DurationName } from '../shared/reporting-search-form-value';
import { SelectableResolution } from '../components/reporting-search-form/reporting-search-form.component';
import { durationToString } from '../shared/duration-to-string';

export type TimePeriodHistory = {
  durationName: DurationName;
  durationLength: number;
  resolution: SelectableResolution;
  periods: Date[];
  periodName?: string;
}

@Injectable()
export class TimePeriodHistoryService {
  public readonly histories$: Observable<TimePeriodHistory[]>;

  private readonly _histories$ = new BehaviorSubject<TimePeriodHistory[]>([]);

  public constructor() {
    this.histories$ = this._histories$.asObservable();
  }

  public addHistoryItem(history: TimePeriodHistory): void {
    const historyItem = {
      ...history,
      periodName: history.periods.map(period =>
        this.getPeriodName(period, history.durationName, history.durationLength)).join(', ')
    };
    const existingIndex = this.getHistoryIndex(historyItem);

    if (existingIndex > -1) {
      this.historyList.splice(existingIndex, 1);
    }

    this.historyList.unshift(historyItem);
    this._histories$.next(this.historyList);
  }

  private get historyList(): TimePeriodHistory[] {
    return this._histories$.getValue();
  }

  private getPeriodName(period: Date, durationName: DurationName, durationLength: number): string {
    return durationToString(period,
      new RequestDuration({ [durationName]: durationLength }),
      0,
      true);
  }

  private getHistoryIndex(history: TimePeriodHistory): number {
    return this.historyList.findIndex(h =>
      h.durationLength === history.durationLength &&
      h.durationName === history.durationName &&
      h.resolution === history.resolution &&
      JSON.stringify(h.periods) === JSON.stringify(history.periods));
  }
}
