import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { isObservable, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';

@Component({
  selector: '',
  templateUrl: './loading-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@ModalOptions({ size: 'tiny' })
export class LoadingModalComponent<T> extends ModalBase<T> implements OnInit, OnDestroy {

  public source$: Observable<T>;

  private readonly _cancel = new Subject<void>();
  private _closed: boolean = false;

  public constructor(currentModal: NgfActiveModal) {
    super(currentModal);
  }

  public ngOnInit(): void {
    if (!isObservable(this.source$)) {
      this._closed = true;
      this.dismiss();
    } else {
      this.source$.pipe(takeUntil(this._cancel)).subscribe({
        next: result => this.close(result),
        error: () => this.dismiss(),
        complete: () => this.dismiss(), // ie. canceled from caller's side
      });
    }
  }

  public ngOnDestroy(): void {
    this._cancel.next();
    this._cancel.complete();
  }

  public dismiss(): void {
    this._cancel.next();

    if (!this._closed) {
      this._closed = true;
      super.dismissModal();
    }
  }

  private close(result: T): void {
    if (!this._closed) {
      this._closed = true;
      super.closeModal(result);
    }
  }
}
