import FacilityCreateComponent from './facility-create-component';
import EnerkeyServices from '../../../../services';
import ReportingObjectsServices from '../../../reportingobjects/services';
import FacilityCreateSpreadsheetService from './facility-create-spreadsheet-service';

export default angular.module('app.modules.admin.components.facility-create', [
  EnerkeyServices.name,
  ReportingObjectsServices.name
])
  .component('facilityCreate', FacilityCreateComponent)
  .service('FacilityCreateSpreadsheetService', FacilityCreateSpreadsheetService)
;
