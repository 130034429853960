import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LogStatus } from '@enerkey/clients/alarm';

@Component({
  selector: 'alarm-log-status-dropdown',
  templateUrl: './alarm-log-status-dropdown.component.html',
  styleUrls: ['./alarm-log-status-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AlarmLogStatusDropdownComponent),
    multi: true,
  }],
})
export class AlarmLogStatusDropdownComponent implements ControlValueAccessor {
  @Input() public loading: boolean = false;
  @Input() public readonly: boolean = false;

  public readonly statuses = [
    LogStatus.NoInvestigationRequired,
    LogStatus.InvestigationRequired,
    LogStatus.UnderInvestigation,
    LogStatus.InvestigationDone,
  ].map(value => ({ value }));

  public disabled: boolean = false;
  public value: LogStatus = null;

  private _onChange: (value: LogStatus) => void;
  private _onTouch: () => void;

  public constructor(
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  public valueChanged(value: LogStatus): void {
    this.value = value;
    this._onChange?.(value);
  }

  public blur(): void {
    this._onTouch?.();
  }

  public writeValue(value: LogStatus): void {
    this.value = value;
  }

  public registerOnChange(fn: (value: LogStatus) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (this.disabled !== !!isDisabled) {
      this.disabled = !!isDisabled;
      this.changeDetector.detectChanges();
    }
  }
}
