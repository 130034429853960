<div class="content-header content-header--no-margin dashboard-header">
  <ul class="tabs">
    <li
      *ngFor="let dashboard of (dashboards$ | async)"
      class="tabs__item"
      [class.tabs__item--active]="(activeDashboard$ | async)?.id === dashboard.id"
    >
      <a
        (click)="selectDashboard(dashboard)"
        class="tabs__item-link"
      >
        {{ dashboard.isDefault ? ('DASHBOARD.DASHBOARD' | translate) : dashboard.title }}
        <ng-container *ngIf="!dashboard.isDefault && (activeDashboard$ | async)?.id === dashboard.id">
          &nbsp;
          <span
            class="edit-dashboard-icon"
            (click)="editDashboard(dashboard)"
            [attr.title]="'WIDGET.SETTINGS' | translate"
          >
            <i class="fa fa-cog"></i>
          </span>
        </ng-container>
      </a>
    </li>
  </ul>
  <div
    *ngIf="showButtons$ | async"
    class="dashboard-buttons"
  >
    <button
      (click)="addDashboard()"
      class="button icon icon--small"
      [attr.title]="'ADD' | translate"
    >
      <i class="fa fa-plus"></i>
    </button>
    <ek-dropdown
      position="left"
      [width]="340"
    >
      <button
        class="button"
        *ekDropdownToggle="let toggle"
        (click)="toggle()"
        [attr.title]="'ADD' | translate"
      >
        <i class="fa fa-plus"></i>
        {{ 'DASHBOARD.ADD_WIDGET' | translate }}
      </button>
      <div *ngFor="let widgetGroup of (widgetGroups$ | async)">
        <ng-container *ngIf="widgetGroup.widgets.length">
          <div class="widget-group-title"> {{widgetGroup.widgetGroupName}} </div>
          <ek-dropdown-item
            *ngFor="let widget of widgetGroup.widgets"
            (click)="addWidget(widget.widgetType)"
          >
            <div class="dropdown-item-container">
              <i
                class="widget-icon"
                [ngClass]="widget.iconClass"
              ></i>
              <span class="add-widget-title">{{ widget.defaultTitle | translate }}</span>
              <span
                *ngIf="(usedWidgetAmounts$ | async)?.[widget.widgetType] as amount"
                class="widgets-in-use-amount"
              >
                {{ amount }} {{ 'DASHBOARD.IN_USE' | translate }}
              </span>
            </div>
          </ek-dropdown-item>
        </ng-container>
      </div>
    </ek-dropdown>
  </div>
</div>
