import { StateProvider } from '@uirouter/angularjs';

import mrcTemplateModule from 'raw-loader!./templates/mrc.html';
import mrcMeterContentConsumptionsTemplateModule from 'raw-loader!./templates/mrc-meter-content-consumptions.html';
import mrcMeterContentReadingsTemplateModule from 'raw-loader!./templates/mrc-meter-content-readings.html';
import mrcReadingsListingViewTemplateModule from 'raw-loader!./templates/mrc-readings-listing-view.html';

import { Service } from '../../constants/service';
import { EnerkeyNg1StateDeclaration, getUIState } from '../../shared/routing';

const mrcState: EnerkeyNg1StateDeclaration = {
  name: 'mrc',
  parent: 'index',
  url: '/mrc',
  views: {
    'app@': {
      template: mrcTemplateModule,
      controller: 'MrcController'
    }
  },
  data: {
    auth: {
      services: [Service.ManualMeasurements]
    },
    translationKey: 'MRC.TITLE'
  },
  redirectTo: 'mrc.readings'
};

const mrcTopbarState = getUIState(mrcState);

export {
  mrcTopbarState
};

config.$inject = ['$translatePartialLoaderProvider', '$stateProvider'];

function config($translatePartialLoaderProvider: any, $stateProvider: StateProvider): void {
  // translates
  $translatePartialLoaderProvider.addPart('mrc');

  // routes
  const mrcStates: { [key: string]: EnerkeyNg1StateDeclaration } = {
    'mrc': mrcState,
    'mrc.consumptions': {
      url: '/consumptions/',
      views: {
        content: {
          template: mrcMeterContentConsumptionsTemplateModule,
          controller: 'MrcConsumptionsController'
        }
      }
    },
    'mrc.mass_import': {
      url: '/mass_import/',
      views: {
        content: {
          template: '<mrc-mass-import></mrc-mass-import>',
        }
      },
      data: {
        export: {
          enabled: true
        }
      }
    },
    'mrc.readings': {
      url: '/readings/',
      views: {
        content: {
          template: mrcMeterContentReadingsTemplateModule
        }
      }
    },
    'mrc.readings_listing': {
      url: '/readings_listing/',
      abstract: true,
      views: {
        content: {
          template: mrcReadingsListingViewTemplateModule,
          controller: 'MrcReadingsListingController'
        }
      },
      data: {
        export: {
          enabled: true
        }
      }
    },
    'mrc.readings_listing.latest_readings': {
      url: 'latest/',
    },
    'mrc.readings_listing.readings_history': {
      url: 'history/',
    }
  };

  Object.keys(mrcStates).forEach(stateName => {
    $stateProvider.state(stateName, mrcStates[stateName]);
  });
}

export default config;
