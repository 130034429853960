import EnerkeyServicesModule from '../../../../services';
import ManualQaActionsSelectComponent from './manual-qa-actions-select-component';
import ManualQaActionsSelectService from './manual-qa-actions-select-service';

const dependencies = [EnerkeyServicesModule.name];

const ManualQaActionsSelectModule = angular
  .module('app.modules.manual-qa.components.manual-qa-actions-select', dependencies)
  .component('manualQaActionsSelect', ManualQaActionsSelectComponent)
  .service('ManualQaActionsSelectService', ManualQaActionsSelectService)
;

export default ManualQaActionsSelectModule;
