import template from 'raw-loader!./closeable.html';

export default {
  template: template,
  transclude: true,
  controllerAs: 'vm',
  bindings: {
    isOpen: '<',
    onChange: '&'
  }
};
