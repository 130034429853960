import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'overview-card-field',
  templateUrl: './overview-card-field.component.html',
  styleUrls: ['./overview-card-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewCardFieldComponent {
  @Input() public title: string;
  @Input() public value: number;
  @Input() public decimalsToShow: number;
  @Input() public difference: number;
  @Input() public differencePercent: number;
  @Input() public incomplete = false;
  @Input() public showDiff = true;
}
