import ManualQaFetchTypeController from './manual-qa-fetch-type.controller';
import template                    from 'raw-loader!./manual-qa-fetch-type.html';

export default {
  template: template,
  controller: ManualQaFetchTypeController,
  controllerAs: 'vm',
  bindings: {
    fetchType: '<',
    onChange: '&'
  }
};
