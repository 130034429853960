<h3>{{ 'PROVISIONING.STATUS.LABEL' | translate }}:</h3>
<p>
  {{ status.operationResult | operationResultStatus | translate }}
  <provisioning-operation-status-icon [status]="status.operationResult"></provisioning-operation-status-icon>
</p>
<label *ngIf="status?.errorMessages?.length">
  <h3>{{ 'PROVISIONING.ERROR_MESSAGES' | translate }}:</h3>
  <textarea
    *ngFor="let error of status.errorMessages"
    readonly
  >{{ error }}</textarea>
</label>
<label *ngIf="status?.infoMessages?.length">
  <h3>{{ 'PROVISIONING.INFO_MESSAGES' | translate }}:</h3>
  <textarea
    *ngFor="let info of status.infoMessages"
    readonly
  >{{ info }}</textarea>
</label>
