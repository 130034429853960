/**
 * Constant for hour fetch type
 *
 * @type {number}
 */
export const HOUR = 1;

/**
 * Constant for cumulative fetch type
 *
 * @type {number}
 */
export const CUMULATIVE = 2;

/**
 * Constant for both hour and cumulative fetch types
 *
 * @type {number}
 */
export const BOTH = 0;
