import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TerminalMeter } from '../terminal-status/terminal-meter';

@Component({
  selector: 'terminal-status-grid',
  templateUrl: './terminal-status-grid.component.html',
})
export class TerminalStatusGridComponent implements OnInit {
  @Input() public terminalName: string;
  @Input() public terminalMeters: TerminalMeter[];
  @Output() public showInterfaceStatus: EventEmitter<TerminalMeter> = new EventEmitter();
  public excelFileName: string;
  public pageSize = 30;

  public constructor() { }

  public showMeterInterfaceStatus(terminalMeter: TerminalMeter): void {
    this.showInterfaceStatus.emit(terminalMeter);
  }

  public ngOnInit(): void {
    this.excelFileName = `${this.terminalName}.xlsx`;
  }
}
