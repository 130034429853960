import ccMassImportTemplate from 'raw-loader!./cc-mass-import.html';
import ccMassImportController from './cc-mass-import-controller';

export default {
  template: ccMassImportTemplate,
  controller: ccMassImportController,
  controllerAs: 'vm',
  bindings: {
    readerTypeId: '<',
    groupIdentifier: '<',
    cronExpression: '<'
  }
};

