import { extend } from '../extend';
import * as arrayExt from './array-extensions';

// Static functions
extend(Array, 'hasItems', arrayExt.hasItemsStatic);

// Mutating prototype functions
extend(Array.prototype, 'clear', arrayExt.clear);
extend(Array.prototype, 'remove', arrayExt.remove);
extend(Array.prototype, 'removeBy', arrayExt.removeBy);
extend(Array.prototype, 'removeAt', arrayExt.removeAt);

// Non-mutating prototype functions
extend(Array.prototype, 'hasItems', arrayExt.hasItems);
extend(Array.prototype, 'sortBy', arrayExt.sortBy);
extend(Array.prototype, 'sortByMany', arrayExt.sortByMany);
extend(Array.prototype, 'unique', arrayExt.unique);
extend(Array.prototype, 'uniqueBy', arrayExt.uniqueBy);
extend(Array.prototype, 'uniqueByMany', arrayExt.uniqueByMany);
extend(Array.prototype, 'filterMap', arrayExt.filterMap);
extend(Array.prototype, 'mapFilter', arrayExt.mapFilter);
extend(Array.prototype, 'toMapBy', arrayExt.toMapBy);
extend(Array.prototype, 'toMap', arrayExt.toMap);
extend(Array.prototype, 'except', arrayExt.except);
// When browsers support new groupByToMap, replace with it
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/groupByToMap
extend(Array.prototype, 'toGroupsBy', arrayExt.toGroupsBy);
extend(Array.prototype, 'count', arrayExt.count);
extend(Array.prototype, 'toRecord', arrayExt.toRecord);
extend(Array.prototype, 'joinLeft', arrayExt.joinLeft);
extend(Array.prototype, 'joinInner', arrayExt.joinInner);
