import _ from 'lodash';

import { getNumericEnumValues } from '@enerkey/ts-utils';
import {
  HeatDistributionSystem,
  VentilationMethod,
  VentilationSystem
} from '@enerkey/clients/facility';

import {
  heatDistributionSystemTranslations,
  ventilationMethodTranslations,
  ventilationSystemTranslations
} from '../../../../constants/facility.constant';
import { DEFAULT_COLOR, DEFAULT_WIDTH, HEADER_BACKGROUND } from '../../../../shared/spreadsheet.functions';
import { getOwnershipSelectItems } from '../../services/ek-ownership-select.service';

AdminFacilitySpreadsheetService.$inject = [
  'SpreadsheetService', 'AdminSpreadsheetTranslationsService', 'facilities',
  'utils', '$q'
];

const setDateObjects = facilityArray => facilityArray.map(facility => {
  facility.created = facility.created ? new Date(facility.created) : null;
  facility.lastModified = facility.lastModifiedBy ? new Date(facility.lastModified) : null;
  facility.reportingEndDate = facility.reportingEndDate ? new Date(facility.reportingEndDate) : null;
  facility.billingEndDate = facility.billingEndDate ? new Date(facility.billingEndDate) : null;
  facility.powerOfAttorneyExpirationDate =
    facility.powerOfAttorneyExpirationDate ? new Date(facility.powerOfAttorneyExpirationDate) : null;
  return facility;
});

export default function AdminFacilitySpreadsheetService(
  SpreadsheetService, AdminSpreadsheetTranslationsService, facilities,
  utils, $q
) {
  const vm = this;
  vm.options = {}; // selected AdminFacilitySpreadsheetSelectionService fields
  vm.defaults = { width: 165 };
  vm.colors = SpreadsheetService.getColors();
  vm.translations = AdminSpreadsheetTranslationsService.getTranslations();
  vm.sheets = {
    buildingClassId: getBuildingClassSheet(),
    localeId: getLocaleSheet(),
    ownership: getOwnershipSheet(),
    electricityTaxClass: getElectricityTaxClassSheet(),
  };

  return {
    getFacilitySpreadsheetOptions: getFacilitySpreadsheetOptions,
  };

  /**
   * Returns spreadsheet options for admin spreadsheet.
   *
   * @returns {*}
   */
  function getFacilitySpreadsheetOptions(options, facilityIds) {
    vm.options = options;
    const deferred = $q.defer();

    $q.all(getSheets(facilityIds)).then(result => {
      const spreadsheetOptions = angular.copy({
        toolbar: false,
        sheetsbar: false,
        activeSheet: vm.translations.sheets.inputPage,
        columns: vm.options.length + 1,
        rows: Math.max(...result.map(sheet => sheet.rows.length)),
        sheets: result
      });
      deferred.resolve(spreadsheetOptions);
    });

    return deferred.promise;
  }

  function getSheets(facilityIds) {
    const chosenArray = [getFirstSheet(facilityIds), getFacilityInformationSheet(facilityIds)];

    let techSpecsInitialized = false;

    vm.options.forEach(option => {
      const sheet = vm.sheets[option.name];
      if (sheet) {
        chosenArray.push(sheet);
      }
      if (!techSpecsInitialized && option.arrayName === 'technicalSpecifications') {
        chosenArray.push(getVentilationMethodSheet());
        chosenArray.push(getVentilationSystemSheet());
        chosenArray.push(getHeatDistributionSheet());
        techSpecsInitialized = true;
      }
    });

    return chosenArray;
  }

  function getElectricityTaxClassSheet() {
    return {
      name: vm.translations.sheets.electricityTaxClassPage,
      columns: [
        { width: vm.defaults.width },
        { width: vm.defaults.width },
      ],
      rows: [{
        cells: [{
          value: vm.translations.electricityTaxClass.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.electricityTaxClassName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }, {
        cells: [{
          value: 0,
          enable: false
        }, {
          value: utils.localizedString('FACILITY.ELECTRICITY_TAX_CLASS.NONE'),
          enable: false
        }]
      }, {
        cells: [{
          value: 1,
          enable: false
        }, {
          value: utils.localizedString('FACILITY.ELECTRICITY_TAX_CLASS.TAX_CLASS_1'),
          enable: false
        }]
      }, {
        cells: [{
          value: 2,
          enable: false
        }, {
          value: utils.localizedString('FACILITY.ELECTRICITY_TAX_CLASS.TAX_CLASS_2'),
          enable: false
        }]
      }]
    };
  }

  function getOwnershipSheet() {
    const deferred = $q.defer();
    const ownershipSheet = {
      name: vm.translations.sheets.ownershipPage,
      columns: [
        { width: vm.defaults.width },
        { width: vm.defaults.width },
      ],
      rows: [{
        cells: [{
          value: vm.translations.ownership.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.ownershipName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }]
    };
    getOwnershipSelectItems().forEach(ownership => {
      ownershipSheet.rows.push({
        cells: [{
          value: ownership.id
        }, {
          value: utils.localizedString(ownership.text)
        }]
      });
    });
    deferred.resolve(ownershipSheet);

    return deferred.promise;
  }

  function getBuildingClassSheet() {
    const deferred = $q.defer();
    const buildingClassSheet = {
      name: vm.translations.sheets.buildingClassPage,
      columns: [
        { width: vm.defaults.width },
        { width: vm.defaults.width },
        { width: vm.defaults.width }
      ],
      rows: [{
        cells: [{
          value: vm.translations.buildingClassId.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.buildingClassNumericName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.buildingClassName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }]
    };
    facilities.getBuildingClassTypes().then(result => {
      result.forEach(buildingClass => {
        buildingClassSheet.rows.push({
          cells: [{
            value: buildingClass.id
          }, {
            value: buildingClass.numericName
          }, {
            value: buildingClass.translation
          }]
        });
      });
      deferred.resolve(buildingClassSheet);
    });
    return deferred.promise;
  }

  function getLocaleSheet() {
    const deferred = $q.defer();
    const localeSheet = {
      name: vm.translations.sheets.localePage,
      columns: [
        { width: vm.defaults.width },
        { width: vm.defaults.width },
        { width: vm.defaults.width },
        { width: vm.defaults.width }
      ],
      rows: [{
        cells: [{
          value: vm.translations.localeId.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.localeTimeZone.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.localeCountryCode.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.localeCurrency.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }]
    };
    facilities.getLocales().then(result => {
      result.forEach(locale => {
        localeSheet.rows.push({
          cells: [{
            value: locale.id
          }, {
            value: locale.timeZone
          }, {
            value: locale.countryCode
          }, {
            value: locale.currency
          }]
        });
      });
      deferred.resolve(localeSheet);
    });
    return deferred.promise;
  }

  function getFacilityInformationSheet(facilityIds) {
    const deferred = $q.defer();
    const facilityData = {
      name: vm.translations.sheets.facilityPage,
      columns: [
        { width: vm.defaults.width },
        { width: vm.defaults.width },
      ],
      rows: [{
        cells: [{
          value: vm.translations.enegiaId.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.facilityName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }]
    };
    facilities.getFacilitiesDirect(facilityIds).then(result => {
      _.forEach(result, facility => {
        facilityData.rows.push({
          cells: [{
            type: 'number',
            value: parseInt(facility.enegiaId)
          }, { value: facility.displayName }]
        });
      });
      deferred.resolve(facilityData);
    });
    return deferred.promise;
  }

  function getHeaderCells() {
    const array = [{
      value: vm.translations.enegiaId.heading,
      background: vm.colors.headerBackground,
      textAlign: 'center',
      color: vm.colors.default,
      enable: false
    }];
    _.forEach(vm.options, result => {
      array.push({
        value: result.text,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      });
    });
    array.push({
      value: vm.translations.status.heading,
      background: vm.colors.headerBackground,
      textAlign: 'center',
      color: vm.colors.default,
      enable: false
    });
    return array;
  }

  function getColumns() {
    // Always have Enegia Id
    const columns = [{ width: vm.defaults.width }];
    // selected columns
    vm.options.forEach(option => {
      columns.push({ width: option.width });
    });
    columns.push({ width: vm.defaults.width });
    return columns;
  }

  function getEnegiaIdValidation() {
    return {
      dataType: 'list',
      showButton: true,
      from: `'${vm.translations.sheets.facilityPage}'!A$2:A`,
      allowNulls: true,
      type: 'reject',
      titleTemplate: vm.translations.enegiaId.titleTemplate,
      messageTemplate: vm.translations.enegiaId.messageTemplate
    };
  }

  function getFirstSheet(facilityIds) {
    const deferred = $q.defer();
    facilities.getFacilitiesDirect(facilityIds).then(result => {
      setDateObjects(result);
      const sheet = {
        name: vm.translations.sheets.inputPage,
        columns: getColumns(),
        rows: [{ cells: getHeaderCells() }]
      };
      result.forEach(facility => {
        const cells = [{
          value: facility.enegiaId,
          validation: getEnegiaIdValidation()
        }];
        vm.options.forEach(option => {
          cells.push({
            format: option.type === 'string' ? '@' : 0,
            value: option.name === 'tags'
              ? _.get(facility, option.name, []).join(', ')
              : _.get(facility, option.name, ''),
            validation: getOptionValidation(option)
          });
        });
        sheet.rows.push({ cells: cells });
      });
      deferred.resolve(sheet);
    });

    return deferred.promise;
  }

  function getOptionValidation(option) {
    if (option.validation) {
      return option.validation;
    } else if (option.validationPage) {
      return {
        dataType: 'list',
        showButton: true,
        from: `'${option.validationPage}'!A$2:A`,
        allowNulls: true,
        type: 'reject',
        titleTemplate: _.get(vm.translations, option.name, '').titleTemplate,
        messageTemplate: _.get(vm.translations, option.name, '').messageTemplate
      };
    }
    return null;
  }

  function getVentilationMethodSheet() {
    const name = utils.localizedString('TECHNICALSPECIFICATIONS.VENTILATIONMETHOD');
    return getEnumSheet(name, VentilationMethod, ventilationMethodTranslations);
  }

  function getVentilationSystemSheet() {
    const name = utils.localizedString('TECHNICALSPECIFICATIONS.PRIMARYVENTILATIONSYSTEM');
    return getEnumSheet(name, VentilationSystem, ventilationSystemTranslations);
  }

  function getHeatDistributionSheet() {
    const name = utils.localizedString('TECHNICALSPECIFICATIONS.HEATDISTRIBUTIONSYSTEM');
    return getEnumSheet(name, HeatDistributionSystem, heatDistributionSystemTranslations);
  }

  function getEnumSheet(sheetName, enums, enumTranslations) {
    const sheet = {
      name: sheetName,
      columns: [
        { width: DEFAULT_WIDTH },
        { width: DEFAULT_WIDTH }
      ],
      rows: [{
        cells: [{
          value: 'value',
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }, {
          value: 'name',
          background: HEADER_BACKGROUND,
          textAlign: 'center',
          color: DEFAULT_COLOR,
          enable: false
        }]
      }]
    };
    const list = getNumericEnumValues(enums).map(value => ({
      value: value,
      translation: utils.localizedString(enumTranslations[value])
    }));
    list.forEach(enumClass => {
      sheet.rows.push({
        cells: [{
          value: enumClass.value
        }, {
          value: enumClass.translation
        }]
      });
    });
    return sheet;
  }
}
