import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { ModalBase } from '@enerkey/foundation-angular';
import { ModalService } from '@enerkey/foundation-angular';
import { Terminal } from '@enerkey/clients/configuration-control';
import { TerminalEditModalComponent } from '../../terminal/terminal-edit-modal/terminal-edit-modal.component';

@Component({
  selector: 'terminal-status-modal',
  templateUrl: './terminal-status-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TerminalStatusModalComponent extends ModalBase implements OnInit {
  @Input() public terminal: Terminal;

  public constructor(
    public readonly activeModal: NgfActiveModal,
    private modalService: ModalService,
    private injector: Injector
  ) {
    super(activeModal);
  }

  public ngOnInit(): void {
  }

  public onEdit(): void {
    const modalInstance = this.modalService.open(TerminalEditModalComponent, { injector: this.injector });
    modalInstance.componentInstance.enegiaId = null;
    modalInstance.componentInstance.terminal = this.terminal;
  }

}

