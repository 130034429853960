<kendo-grid
  #targetGrid
  [ngStyle]="targetGridStyle"
  [kendoGridBinding]="meters"
  [kendoGridInCellEditing]="createFormGroupFn"
  [pageSize]="pageSize"
  [pageable]="true"
  [sortable]="true"
  [selectable]="selectableSettings"
  filterable="menu"
  [resizable]="true"
  [kendoGridSelectBy]="selectByProperty"
  [(selectedKeys)]="selectedMeterIds"
  (selectionChange)="onSelect()"
  [height]="height"
>
  <kendo-grid-checkbox-column
    [showSelectAll]="true"
    [width]="35"
  ></kendo-grid-checkbox-column>
  <kendo-grid-column
    [field]="nameField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.METER_NAME' | translate }}"
    filter="text"
    [editable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="selectByProperty"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.METER_ID' | translate }}"
    [width]="120"
    filter="numeric"
    [editable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="resolutionField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.RESOLUTION' | translate }}"
    [width]="120"
    [groupable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="facilityNameField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.FACILITY_NAME' | translate }}"
    filter="text"
    [editable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="enegiaIdField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.ENEGIA_ID' | translate }}"
    [width]="120"
    filter="numeric"
    [editable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="quantityIdField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.QUANTITY' | translate }}"
    [width]="150"
    filter="text"
    [editable]="false"
  ></kendo-grid-column>
  <kendo-grid-column
    [field]="factorField"
    title="{{ 'ADMIN.VIRTUAL_METERS.ADD_METERS.GRID.FACTOR' | translate }}"
    [width]="100"
    filter="numeric"
    editor="numeric"
    format="{0:0.########}"
  ></kendo-grid-column>
  <kendo-grid-checkbox-column
    title="{{ 'ADMIN.VIRTUAL_METERS.EDITOR.NULL_TO_ZERO' | translate }}"
    [showSelectAll]="true"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <input
        kendoCheckBox
        [(ngModel)]="dataItem[nullToZeroField]"
        type="checkbox"
      >
    </ng-template>
  </kendo-grid-checkbox-column>
</kendo-grid>
