import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Quantities } from '@enerkey/clients/metering';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { TimeFrameString } from '../../../../services/time-frame-service';
import { MonthNamePipe } from '../../../../shared/common-pipes/month-name.pipe';
import { ValuePoint } from '../../shared/consumptions';
import { ConsumptionWidgetChartAxis } from '../consumption-chart/consumption-chart.component';

export interface ConsumptionPoint {
  series: {
    color: string;
    axis: ConsumptionWidgetChartAxis | number;
  };
  dataItem: ValuePoint;
  value: number;
}

@Component({
  selector: 'widget-chart-tooltip',
  templateUrl: './widget-chart-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetChartTooltipComponent implements OnChanges {
  @Input() public points: ConsumptionPoint[];
  @Input() public category: string | number;
  @Input() public resolution: TimeFrameString;
  @Input() public isNormalized: boolean;
  @Input() public quantityId: Quantities;
  @Input() public relationalValueIds: number[];
  @Input() public unitKey: ReportingUnit;

  public readonly ChartAxis: typeof ConsumptionWidgetChartAxis = ConsumptionWidgetChartAxis;

  public title = '';

  public constructor(private readonly monthNamePipe: MonthNamePipe) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.points) {
      this.points = this.points?.sortBy(point => point.series.axis).sortBy(point => point.dataItem.timestamp, 'desc');
    }

    if (changes.category && this.category) {
      if (this.resolution === 'P1M' && typeof this.category === 'number') {
        this.title = this.monthNamePipe.transform(this.category - 1, true).capitalize();
      } else {
        this.title = `${this.category}`;
      }
    }
  }
}
