import ManualQaSearchListController from './manual-qa-search-list-controller';
import template                     from 'raw-loader!./manual-qa-search-list.html';

export default {
  template: template,
  controller: ManualQaSearchListController,
  controllerAs: 'vm',
  bindings: {
    searchLists: '<'
  }
};
