import { ValueTypes } from '../constants/value-types';

export const TIMESTAMP = 'timestamp';
export const COMPARISON_TIMESTAMP = 'comparisonTimestamp';
export const ACTUAL_CONSUMPTION = 'actualConsumption';
export const ACTUAL_CUMULATIVE = 'actualCumulative';
export const COMPARISON_CONSUMPTION = 'comparisonConsumption';
export const MODEL_CONSUMPTION = 'modelConsumption';
export const MODEL_CUMULATIVE = 'modelCumulative';
export const PREVIEW_CONSUMPTION = 'previewConsumption';
export const VALUE_TYPE = 'valueType';
export const IS_LOCKED = 'isLocked';
export const IS_DIRTY = 'isDirty';
export const ROW_INDEX = 'rowIndex';
export const ACTUAL_CONSUMPTION_CHART = 'actualConsumptionChart';
export const MODEL_CONSUMPTION_CHART = 'modelConsumptionChart';
export const PREVIEW_CONSUMPTION_CHART = 'previewConsumptionChart';

export class Reading {
  public static setValueType(reading: Reading, value: string): void {
    reading.valueType = (value === null ? null : parseInt(value, 10));
    reading.isModelled = reading.valueType === ValueTypes.MODELLED;
  }

  public static setPreviewConsumption(reading: Reading, value: number): void {
    const parsedValue = (value === null ? null : parseFloat(value as unknown as string));
    reading.previewConsumption = parsedValue;
    reading.previewConsumptionChart = parsedValue;
  }

  public static getPreviewPayload(reading: Reading): {
    timestampUtc: Date;
    value: number;
    valueType: ValueTypes;
  } {
    return {
      timestampUtc: reading.timestamp,
      value: reading.previewConsumption,
      valueType: reading.valueType
    };
  }

  public static isReadingDirtyAndHourly(reading: Reading): boolean {
    return reading.isDirty && reading.isHourly;
  }

  public timestamp: Date = null;
  public comparisonTimestamp: Date = null;
  public actualConsumption: number = null;
  public actualConsumptionChart: number = null;
  public actualCumulative: number = null;
  public comparisonConsumption: number = null;
  public modelConsumption: number = null;
  public modelConsumptionChart: number = null;
  public modelCumulative: number = null;
  public previewConsumption: number = null;
  public previewConsumptionChart: number = null;
  public valueType: ValueTypes = ValueTypes.RAW_READING;
  public isLocked: boolean = false;
  public isFaulty: boolean = false;
  public isDirty: boolean = false;
  public isModelled: boolean = false;
  public rowIndex: number = null;
  public isHourly: boolean = false;
  public belongsToDefectIssue: boolean = false;
  private faults: unknown[] = [];

  public setFaults(faults: unknown[]): void {
    this.faults = faults;
    this.isFaulty = this.faults ? !!this.faults[0] : false;
  }

  public setBelongsToDefectIssue(value: boolean): void {
    this.belongsToDefectIssue = value;
  }

  public setTimestamp(timestamp: Date): void {
    this.timestamp = timestamp instanceof Date ? timestamp : new Date(timestamp);
  }

  public setComparisonTimestamp(timestamp: Date): void {
    this.comparisonTimestamp = timestamp instanceof Date ? timestamp : new Date(timestamp);
  }

  public setActualConsumption(value: string): void {
    const parsedValue = (value === null ? null : parseFloat(value));
    this.actualConsumption = parsedValue;
    this.actualConsumptionChart = parsedValue;
  }

  public setActualCumulative(value: string): void {
    this.actualCumulative = (value === null ? null : parseFloat(value));
  }

  public setComparisonConsumption(value: string): void {
    this.comparisonConsumption = (value === null ? null : parseFloat(value));
  }

  public setModelConsumption(value: string): void {
    const parsedValue = (value === null ? null : parseFloat(value));
    this.modelConsumption = parsedValue;
    this.modelConsumptionChart = parsedValue;
  }

  public setModelCumulative(value: string): void {
    this.modelCumulative = (value === null ? null : parseFloat(value));
  }

  public setRowIndex(value: string): void {
    this.rowIndex = (value === null ? null : parseInt(value, 10));
  }

  public setDirty(value: boolean): void {
    this.isDirty = value;
  }

  public setHourly(value: boolean): void {
    this.isHourly = value;
  }

  public setLocked(value: boolean): void {
    this.isLocked = value;
  }
}

export default Reading;
