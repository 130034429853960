import ConfigurationServicesModule from './services';
import ConfigurationModelsModule from './models';
import ReportingObjectsServicesModule from '../reportingobjects/services';

import config from './config';
import noComparisonPresets from './directives/no-comparison-presets';

export default angular.module('Configuration', [
  ConfigurationServicesModule.name,
  ConfigurationModelsModule.name,
  ReportingObjectsServicesModule.name
])
  .config(config)
  .directive('noComparisonPresets', noComparisonPresets)
;
