import * as ChartUtils from './manual-qa-chart.functions';

const translations = [
  'MQA.INSPECT.GRAPH.SERIES.ACTUAL_CUMULATIVE',
  'MQA.INSPECT.GRAPH.SERIES.ACTUAL_CONSUMPTION',
  'MQA.INSPECT.GRAPH.SERIES.COMPARISON_CONSUMPTION',
  'MQA.INSPECT.GRAPH.SERIES.MODEL_CUMULATIVE',
  'MQA.INSPECT.GRAPH.SERIES.MODEL_CONSUMPTION',
  'MQA.INSPECT.GRAPH.SERIES.PREVIEW_CONSUMPTION'
];

const $inject = ['utils'];

class ManualQaChartService {
  constructor(utils) {
    this.translations = utils.mapLocalizationTagArrayToLocalizedObject(translations);
  }

  /**
   * Returns customized stock chart options
   *
   * @see https://docs.telerik.com/kendo-ui/api/javascript/dataviz/ui/stock-chart
   *
   * @param {Object} chart
   * @param {Object} dataSource
   * @param {TimeFrame} timeFrame
   * @param {number} fetchType
   * @param {boolean} useComparisonPeriod
   *
   * @returns {Object}
   */
  getStockChartOptions(chart, dataSource, timeFrame, fetchType, useComparisonPeriod) {
    const readings = dataSource.data();
    chart.setDataSource(dataSource);
    const plotBands = ChartUtils.defectIssuesToPlotBands(readings);

    return {
      ...ChartUtils.getStockChartDefaultOptions(),
      ...ChartUtils.getSeriesDefaults(),
      ...ChartUtils.getSeries(this.translations, fetchType, useComparisonPeriod),
      ...ChartUtils.getCategoryAxis(chart, timeFrame, plotBands, fetchType),
      ...ChartUtils.getValueAxis(readings, timeFrame, fetchType),
      ...ChartUtils.getNavigator(readings, timeFrame, plotBands, fetchType)
    };
  }
}

ManualQaChartService.$inject = $inject;

export default ManualQaChartService;
