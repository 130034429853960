import { IPromise } from 'angular';
import { catchError } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

import { NoReplyMessage, UserManagementClient } from '@enerkey/clients/user-management';

import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class EmailSendingService {
  public constructor(
    private readonly userManagementClient: UserManagementClient,
    private readonly toasterService: ToasterService
  ) {
  }

  public sendEmail(email: NoReplyMessage): IPromise<unknown> {
    return firstValueFrom(this.userManagementClient.sendMail(email)
      .pipe(catchError(error => {
        this.toasterService.error('TOPBAR.SENDING_EMAIL_FAILED');
        return error;
      })));
  }
}
