import { Pipe, PipeTransform } from '@angular/core';

import { AlarmService } from '../services/alarm.service';

@Pipe({ name: 'cronFilter' })
export class CronFilterPipe implements PipeTransform {

  public constructor(
    private readonly alarmService: AlarmService
  ) { }

  public transform(cron: string): string {
    return this.alarmService.getHumanReadableCronFilter(cron);
  }
}
