import { InesActionSlimViewModel } from '@enerkey/clients/attachments';
import { IFacility } from '@enerkey/clients/facility';
import {
  AnalyticStatus,
  AnalyticsType,
  ElectricityDemandResponseInsight,
  HeatBalanceInsight,
  HeatingCoolingData,
  HeatingCoolingInsight,
  HeatingOptimizationData,
  HeatingOptimizationInsight,
  HeatingPowerInsight,
  IElectricityDemandResponseData,
  IElectricityDemandResponseInsight,
  IHeatBalanceData,
  IHeatBalanceInsight,
  IHeatingCoolingInsight,
  IHeatingOptimizationInsight,
  IHeatingPowerData,
  IHeatingPowerInsight,
  ISolarPowerInsight,
  IVentilationData,
  IVentilationInsight,
  SolarPowerData,
  SolarPowerInsight,
  State,
  StateReason,
  VentilationInsight,
} from '@enerkey/clients/ines';

import { roundAsIntervals } from '../ines-functions';
/* eslint-disable @typescript-eslint/no-explicit-any */

type InsightShared = {
  analyticsType: AnalyticsType;
} & Omit<Required<IHeatBalanceInsight & IHeatingPowerInsight & IVentilationInsight>, 'data'>;

type AnyInsight<TData> = (IHeatBalanceInsight | IHeatingPowerInsight | IVentilationInsight) & { data?: TData };

enum ActionInfo {
  ACreated = 'INES_GRID_ACTION_CREATED',
  ANotCreated = 'INES_GRID_ACTION_NOTCREATED'
}

export abstract class InsightModelBase<TData = unknown> implements InsightShared {
  public readonly abstract analyticsType: AnalyticsType;
  public abstract data: TData;

  public readonly facilityId: number;
  public analyticStatus: AnalyticStatus;
  public latestState: State;
  public stateReason: StateReason;
  public readonly timestamp: Date | null;

  public isSuccess: boolean;
  public readonly facility: IFacility;

  public actions: InesActionSlimViewModel[];
  public actionCreated: string;

  public constructor(insight: AnyInsight<TData>, facility: IFacility, actions: InesActionSlimViewModel[]) {
    this.facilityId = insight.facilityId;
    this.analyticStatus = insight.analyticStatus;
    this.latestState = insight.latestState;
    this.stateReason = insight.stateReason;
    this.timestamp = insight.timestamp > new Date(0) ? insight.timestamp : null; // filter out 0001-01-01

    this.isSuccess = insight.latestState === State.Succeeded || insight.latestState === State.PartialSuccess;
    this.facility = facility;
    this.actions = actions.filter((ele: any) => (ele.reportingObjectId === insight.facilityId));
    this.actionCreated = this.actions.length > 0 ? ActionInfo.ACreated : ActionInfo.ANotCreated;
  }
}

export class VentilationInsightModel extends InsightModelBase<IVentilationData> {
  public readonly analyticsType = AnalyticsType.Ventilation as const;
  public data: IVentilationData;
  public readonly savingsEur: number;
  public readonly savingsMwh: number;

  public constructor(insight: IVentilationInsight, facility: IFacility, actions: InesActionSlimViewModel[]) {
    super(insight, facility, actions);
    this.data = insight.data;
    this.savingsEur = roundAsIntervals(insight.data?.totalMonetarySavings);
    this.savingsMwh = roundAsIntervals(insight.data?.totalConsumptionSavings);
  }
}

export class HeatBalanceInsightModel extends InsightModelBase<IHeatBalanceData> {
  public readonly analyticsType = AnalyticsType.HeatBalance as const;
  public data: IHeatBalanceData;

  public readonly totalSavings: number;
  public readonly baseLoadSavings: number;
  public readonly ventilationSavings: number;

  public constructor(insight: IHeatBalanceInsight, facility: IFacility, actions: InesActionSlimViewModel[]) {
    super(insight, facility, actions);
    this.data = insight.data;

    this.totalSavings = roundAsIntervals(this.calculateTotal());
    this.baseLoadSavings = roundAsIntervals(insight.data?.baseLoadRefDiffEur);
    this.ventilationSavings = roundAsIntervals(insight.data?.ventilationRefDiffEur);
  }

  private calculateTotal(): number {
    if (Number.isFinite(this.data?.baseLoadRefDiffEur) || Number.isFinite(this.data?.ventilationRefDiffEur)) {
      return (this.data.baseLoadRefDiffEur ?? 0) + (this.data.ventilationRefDiffEur ?? 0);
    }
    return null;
  }
}

export class HeatingPowerInsightModel extends InsightModelBase<IHeatingPowerData> {
  public readonly analyticsType = AnalyticsType.HeatingPower as const;
  public data: IHeatingPowerData;
  public readonly heatingLimitEur: number;
  public readonly powerDemandEur: number;
  public readonly heatingLimitMwh: number;
  public readonly powerDemandMwh: number;
  public readonly heatingLimitWaste: number;
  public readonly powerDemandWaste: number;
  public readonly workingInSummer: boolean | null;
  public readonly workingInWinter: boolean | null;

  public constructor(insight: IHeatingPowerInsight, facility: IFacility, actions: InesActionSlimViewModel[]) {
    super(insight, facility, actions);
    this.data = insight.data;
    this.heatingLimitEur = roundAsIntervals(insight?.data?.heatingLimitSavingsEur);
    this.powerDemandEur = roundAsIntervals(insight?.data?.powerDemandSavingsEur);
    this.heatingLimitMwh = roundAsIntervals(insight?.data?.heatingLimitSavingsMwh);
    this.powerDemandMwh = roundAsIntervals(insight?.data?.powerDemandSavingsMwh);
    this.heatingLimitWaste = roundAsIntervals(insight?.data?.heatingLimitCo2Tonne);
    this.powerDemandWaste = roundAsIntervals(insight?.data?.heatingPowerCo2Tonne);
    this.workingInSummer = insight?.data?.summerIndex === null
      ? null
      : insight?.data?.summerIndex >= 1;
    this.workingInWinter = insight?.data?.winterIndex === null
      ? null
      : insight?.data?.winterIndex >= 1;
  }
}

export class ElectricityDemandResponseInsightModel extends InsightModelBase<IElectricityDemandResponseData> {
  public readonly analyticsType = AnalyticsType.ElectricityDemandResponse as const;
  public data: IElectricityDemandResponseData;
  public readonly coolingCapacity: number;
  public readonly heatingCapacity: number;
  public readonly differenceCapacity: number;
  public readonly coolingEur: number;
  public readonly heatingEur: number;
  public readonly differenceEur: number;
  public readonly eleDrPotentialEur: number;
  /* eslint-disable max-len */
  public constructor(insight: IElectricityDemandResponseInsight, facility: IFacility, actions: InesActionSlimViewModel[]) {
    super(insight, facility, actions);
    this.data = insight.data;
    this.coolingCapacity = roundAsIntervals(insight?.data?.coolingCapacity);
    this.heatingCapacity = roundAsIntervals(insight?.data?.heatingCapacity);
    this.differenceCapacity = roundAsIntervals(insight?.data?.differenceCapacity);
    this.coolingEur = roundAsIntervals(insight?.data?.coolingEur);
    this.heatingEur = roundAsIntervals(insight?.data?.heatingEur);
    this.differenceEur = roundAsIntervals(insight?.data?.differenceEur);
    this.eleDrPotentialEur = roundAsIntervals(insight?.data?.eleDrPotentialEur);
  }
}

export class SolarPowerInsightModel extends InsightModelBase<SolarPowerData> {
  public readonly analyticsType = AnalyticsType.SolarPower as const;
  public data: SolarPowerData;
  public readonly payBackPeriod: number;
  public readonly coolingCapacity: number;
  public readonly plantSize: number;
  public readonly npv10: number;
  public readonly npv20: number;
  public readonly investment: number;

  public constructor(insight: ISolarPowerInsight, facility: IFacility, actions: InesActionSlimViewModel[]) {
    super(insight, facility, actions);
    this.data = insight.data;
    this.payBackPeriod = roundAsIntervals(insight?.data?.payBackPeriod);
    this.coolingCapacity = roundAsIntervals(insight?.data?.coolingCapacity);
    this.plantSize = roundAsIntervals(insight?.data?.plantSize);
    this.npv10 = roundAsIntervals(insight?.data?.npv10);
    this.npv20 = roundAsIntervals(insight?.data?.npv20);
    this.investment = roundAsIntervals(insight?.data?.investment);
  }

}

export class HeatingOptimizationInsightModel extends InsightModelBase<HeatingOptimizationData> {
  public readonly analyticsType = AnalyticsType.HeatingOptimization as const;
  public data: HeatingOptimizationData;
  public readonly predictedSavings: number;
  public readonly confidenceLower: number;
  public readonly heatingMonetarySavings: number;
  public readonly heatingSavingsInMwh: number;
  public readonly minHeatingSavingsInMwh: number;
  public readonly minHeatMonetarySavings: number;

  public constructor(insight: IHeatingOptimizationInsight, facility: IFacility, actions: InesActionSlimViewModel[]) {
    super(insight, facility, actions);
    this.data = insight.data;
    this.predictedSavings = roundAsIntervals(insight?.data?.predictedSavings);
    this.confidenceLower = roundAsIntervals(insight?.data?.confidenceLower);
    this.heatingMonetarySavings = roundAsIntervals(insight?.data?.heatingMonetarySavings);
    this.heatingSavingsInMwh = roundAsIntervals(insight?.data?.heatingSavingsInMwh);
    this.minHeatingSavingsInMwh = roundAsIntervals(insight?.data?.minHeatingSavingsInMwh);
    this.minHeatMonetarySavings = roundAsIntervals(insight?.data?.minHeatMonetarySavings);
  }
}

export class HeatingCoolingInsightModel extends InsightModelBase<HeatingCoolingData> {
  public readonly analyticsType = AnalyticsType.HeatingCooling as const;
  public data: HeatingCoolingData;
  public readonly coolingCount: number;
  public readonly heatingCount: number;
  public readonly overlappingCount: number;
  public readonly summerSlope: number;
  public readonly summerIntercept: number;
  public readonly summerCorrCoef: number;
  public readonly nightMean: number;
  public readonly dayMean: number;
  public readonly tooHighNight: boolean;
  public readonly heatingMonetarySavings: number;
  public readonly heatingSavingsInMwh: number;
  public readonly electricityMonetarySavings: number | null;
  public readonly electricitySavingsInMwh: number | null;
  public readonly districtCoolingSavingsInMwh: number | null;
  public readonly totalMonetarySavings: number;
  public readonly totalConsumptionSavings: number;

  public constructor(insight: IHeatingCoolingInsight, facility: IFacility, actions: InesActionSlimViewModel[]) {
    super(insight, facility, actions);
    this.data = insight.data;
    this.coolingCount = roundAsIntervals(insight?.data?.coolingCount);
    this.heatingCount = roundAsIntervals(insight?.data?.heatingCount);
    this.overlappingCount = roundAsIntervals(insight?.data?.overlappingCount);
    this.summerSlope = roundAsIntervals(insight?.data?.summerSlope);
    this.summerIntercept = roundAsIntervals(insight?.data?.summerIntercept);
    this.summerCorrCoef = roundAsIntervals(insight?.data?.summerCorrCoef);
    this.nightMean = roundAsIntervals(insight?.data?.nightMean);
    this.dayMean = roundAsIntervals(insight?.data?.dayMean);
    this.tooHighNight = insight?.data?.tooHighNight;
    this.heatingMonetarySavings = roundAsIntervals(insight?.data?.heatingMonetarySavings);
    this.heatingSavingsInMwh = roundAsIntervals(insight?.data?.heatingSavingsInMwh);
    this.electricityMonetarySavings = roundAsIntervals(insight?.data?.electricityMonetarySavings);
    this.electricitySavingsInMwh = roundAsIntervals(insight?.data?.electricitySavingsInMwh);
    this.districtCoolingSavingsInMwh = roundAsIntervals(insight?.data?.districtCoolingSavingsInMwh);
    this.totalMonetarySavings = roundAsIntervals(insight?.data?.totalMonetarySavings);
    this.totalConsumptionSavings = roundAsIntervals(insight?.data?.totalConsumptionSavings);
  }
}

export class VentilationInsightWithCurrency extends VentilationInsight {
  public readonly currency: string;
  public override latestState: State;

  public constructor(insight: IVentilationInsight, currency: string) {
    super(insight);
    this.currency = currency;
    this.latestState = insight.latestState;
  }

  public override get isSuccess(): boolean { return this.latestState === State.Succeeded || this.latestState === State.PartialSuccess; }
}

export class HeatBalanceInsightWithCurrency extends HeatBalanceInsight {
  public readonly currency: string;
  public override latestState: State;

  public constructor(insight: IHeatBalanceInsight, currency: string) {
    super(insight);
    this.currency = currency;
    this.latestState = insight.latestState;
  }

  public override get isSuccess(): boolean { return this.latestState === State.Succeeded || this.latestState === State.PartialSuccess; }
}

export class HeatingPowerInsightWithCurrency extends HeatingPowerInsight {
  public readonly currency: string;
  public override latestState: State;

  public constructor(insight: IHeatingPowerInsight, currency: string) {
    super(insight);
    this.currency = currency;
    this.latestState = insight.latestState;
  }

  public override get isSuccess(): boolean { return this.latestState === State.Succeeded || this.latestState === State.PartialSuccess; }
}

export class ElectricityDemandResponseInsightWithCurrency extends ElectricityDemandResponseInsight {
  public readonly currency: string;
  public override latestState: State;

  public constructor(insight: IElectricityDemandResponseInsight, currency: string) {
    super(insight);
    this.currency = currency;
    this.latestState = insight.latestState;
  }

  public override get isSuccess(): boolean { return this.latestState === State.Succeeded || this.latestState === State.PartialSuccess; }
}

export class SolarPowerInsightWithCurrency extends SolarPowerInsight {
  public readonly currency: string;
  public override latestState: State;

  public constructor(insight: ISolarPowerInsight, currency: string) {
    super(insight);
    this.currency = currency;
    this.latestState = insight.latestState;
  }

  public override get isSuccess(): boolean { return this.latestState === State.Succeeded || this.latestState === State.PartialSuccess; }
}

export class HeatingOptimizationInsightWithCurrency extends HeatingOptimizationInsight {
  public readonly currency: string;
  public override latestState: State;

  public constructor(insight: IHeatingOptimizationInsight, currency: string) {
    super(insight);
    this.currency = currency;
    this.latestState = insight.latestState;
  }

  public override get isSuccess(): boolean { return this.latestState === State.Succeeded || this.latestState === State.PartialSuccess; }
}

export class HeatingCoolingInsightWithCurrency extends HeatingCoolingInsight {
  public readonly currency: string;
  public override latestState: State;

  public constructor(insight: IHeatingCoolingInsight, currency: string) {
    super(insight);
    this.currency = currency;
    this.latestState = insight.latestState;
  }

  public override get isSuccess(): boolean { return this.latestState === State.Succeeded || this.latestState === State.PartialSuccess; }
}
