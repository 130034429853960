<div>
  <input
    type="text"
    [formControl]="meterNameFilterControl"
    [placeholder]="'FILTER' | translate"
    kendoTextBox
  >
</div>
<div>
  <div>
    {{ 'FACILITIES_REPORT.FILTER_BY_METER_TYPE' | translate }}:
  </div>
  <div class="meter-type-filter">
    <label class="meter-type-filter-radio-group">
      <input
        type="radio"
        kendoRadioButton
        [formControl]="meterTypeFilterControl"
        [value]="null"
      >
      <span>{{ 'FACILITIES.SELECT_ALL' | translate }}</span>
    </label>

    <div
      class="meter-type-filter-radio-group"
      *ngFor="let meterType of meterTypes$ | async"
    >
      <input
        #radio
        type="radio"
        kendoRadioButton
        [formControl]="meterTypeFilterControl"
        [value]="meterType"
      >
      <meter-type
        [meterType]="meterType"
        [showText]="false"
        (click)="radio.click()"
      ></meter-type>
    </div>

  </div>
</div>
<div class="select-buttons">
  <button
    kendoButton
    [primary]="true"
    [fillMode]="'flat'"
    (click)="selectAll()"
  >
    {{ 'FACILITIES.SELECT_ALL' | translate }}
  </button>
  <button
    kendoButton
    [primary]="true"
    [fillMode]="'flat'"
    (click)="deselectAll()"
  >
    {{ 'FACILITIES.DESELECT_ALL' | translate }}
  </button>
  <button
    kendoButton
    [primary]="true"
    [icon]="'expand'"
    [fillMode]="'flat'"
    (click)="expandAll()"
    [title]="'FACILITIES_REPORT.EXPAND_METERTREE' | translate"
  ></button>
  <button
    kendoButton
    [primary]="true"
    [icon]="'collapse'"
    [fillMode]="'flat'"
    (click)="collapseAll()"
    [title]="'FACILITIES_REPORT.COLLAPSE_METERTREE' | translate"
  ></button>
</div>
<ng-container *ngFor="let quantity of meterTrees$ | async">
  <div>
    <quantity-icon [quantity]="quantity.quantityId"></quantity-icon>
    <span class="quantity-title">{{ quantity.quantityId | quantityName | async }}</span>
  </div>
  <kendo-treeview
    *ngFor="let tree of quantity.trees"
    kendoTreeViewHierarchyBinding
    kendoTreeViewExpandable
    [kendoTreeViewCheckable]="checkableSettings"
    [nodes]="[tree]"
    [childrenField]="'subMeters'"
    [textField]="'name'"
    [checkBy]="'id'"
    [(checkedKeys)]="checkedKeys"
    [expandBy]="'id'"
    [(expandedKeys)]="expandedKeys"
    [animate]="false"
    (checkedKeysChange)="onCheck()"
  >
    <ng-template
      kendoTreeViewNodeTemplate
      let-dataItem
    >
      <input
        [checked]="checkedSubmeterSelectors[dataItem.id]"
        kendoCheckBox
        *ngIf="dataItem.subMeters"
        type="checkbox"
        #checkSubMetersCheckbox
        (change)="toggleSubmetersSelection(dataItem, $event)"
        [ngfTooltip]="selectSubmeterTooltip"
        [openDelay]="500"
        class="select-submeters-checkbox"
      />
      <ng-template #selectSubmeterTooltip>
        <span>{{'ADMIN.METER_SEARCH.SELECT_SUBMETERS' | translate}}</span>
      </ng-template>

      <meter-type
        [meterType]="dataItem.meteringType"
        [showText]="false"
      ></meter-type>
      {{ dataItem.meterTreeVisibleName }}
    </ng-template>
  </kendo-treeview>
</ng-container>
