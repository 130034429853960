/* eslint-disable no-invalid-this */

export function toIntegers(input: string, separator?: string | RegExp): number[] {
  if (typeof input !== 'string') {
    return [];
  }

  return input.split(separator || ',').mapFilter(
    s => Number.parseInt(s, 10),
    i => Number.isInteger(i)
  );
}

export function capitalize(this: string): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
}

export function abbreviate(name: string): string {
  if (!name) {
    return '';
  }
  return name.split(' ').map(item => item[0] ? item[0].toUpperCase() : '').join('');
}
