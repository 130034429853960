import _ from 'lodash';
import meterDetailsTemplateModule from 'ngtemplate-loader!raw-loader!../templates/meter-details.html';
import facilityGraphDataTemplateModule from 'ngtemplate-loader!raw-loader!../templates/facility-graph-data.html';
import facilityTableDataTemplateModule from 'ngtemplate-loader!raw-loader!../templates/facility-table-data.html';
import facilityInfoTemplateModule from 'ngtemplate-loader!raw-loader!../templates/facility-info.html';

const $inject = [
  '$scope', '$rootScope', '$state', '$window', '$timeout', 'LoadingService', 'facilities',
  'ERUtils', 'appStatusService', '$transitions', 'erReportSettingsService', 'erStateService'
];

function FacilitiesSidebarPresentationController(
  $scope, $rootScope, $state, $window, $timeout, LoadingService, facilities,
  ERUtils, appStatusService, $transitions, erReportSettingsService, erStateService
) {
  const vm = this;
  let destroyed = false;

  const erReportSettings = erReportSettingsService.getInstance();

  // sections
  let initialSections;
  if (_inModal_()) {
    initialSections = erReportSettings.getSettings().initialSections;
  } else {
    initialSections = stateHasSections() ? _.cloneDeep($state.params.sections) : void 0;
  }

  vm.data = {
    sections: [
      {
        id: 'FACILITY_INFO',
        titleKey: 'FACILITY_INFO',
        sections: []
      },
      {
        id: 'METER_DETAILS',
        titleKey: 'METER_DETAILS',
        template: meterDetailsTemplateModule,
        selected: Array.hasItems(initialSections) ? _.includes(initialSections, 'METER_DETAILS') : true
      },
      {
        id: 'GRAPHS',
        titleKey: 'GRAPHS',
        template: facilityGraphDataTemplateModule,
        selected: Array.hasItems(initialSections) ? _.includes(initialSections, 'GRAPHS') : true,
      },
      {
        id: 'TABLES',
        titleKey: 'TABLES',
        template: facilityTableDataTemplateModule,
        selected: Array.hasItems(initialSections) ? _.includes(initialSections, 'TABLES') : true,
        action: function() {
          const section = _.find(_.flatten(_.pluck(vm.data.sections, 'sections')), { titleKey: 'SERIES' });
          section.disabled = !this.selected;
        },
        sections: [
          {
            id: 'SERIES',
            titleKey: 'SERIES',
            selected: Array.hasItems(initialSections) ? _.includes(initialSections, 'SERIES') : true,
            disabled: Array.hasItems(initialSections) ? !_.includes(initialSections, 'TABLES') : !_inModal_(),
            action: function() {
              $timeout(() => {
                const selector = angular.element('.toggle-grid-content-button');
                selector.trigger('click');
              });
            },
          }
        ]
      }
    ],
    loading: false
  };

  function stateHasSections() {
    return ERUtils.getVisibleSections().length;
  }

  function _inModal_() {
    // appStatusService.inModal check might be unnecessary in the future
    return erStateService.isModalOpen() || appStatusService.inModal;
  }

  function initialize() {
    vm.data.loading = true;
    getFacilitiesProperties()
      .catch(() => {})
      .finally(() => {
        refreshVisibilities();
        vm.data.loading = false;
        setSections();
        erReportSettings.changeSetting('sectionsConfig', vm.data.sections);
      })
    ;
  }

  function getFacilitiesProperties() {
    return facilities.getFacilityGroupingDataForCurrentProfile()
      .then(result => {
        const section = _.find(vm.data.sections, { titleKey: 'FACILITY_INFO' });
        if (section) {
          section.sections = _.map(result.Groups, group => ({
            id: group.Property,
            title: group.Name,
            template: facilityInfoTemplateModule,
            selected: initialSections ?
              _.includes(initialSections, group.Property) :
              false,
            data: group
          }));
        }
      });
  }

  function refreshVisibilities() {
    const visibleSectionTitleKeys = ERUtils.getVisibleSections();
    _.each(vm.data.sections, section => {
      section.visible = _.includes(visibleSectionTitleKeys, section.titleKey);
      _.each(section.sections || [], childSection => {
        childSection.visible = section.visible;
      });
    });
    const sidebarItem = _.find(_.get($state, '$current.data.sidebar.items'), { titleKey: 'FACILITIES.PRESENTATION' });
    _.set(sidebarItem, 'isHidden', !visibleSectionTitleKeys.length);
  }

  function setSections() {
    if (stateHasSections()) {
      const selectedIds = [];
      _.each(vm.data.sections, section => {
        if (section.selected) {
          selectedIds.push(section.id);
        }
        _.each(section.sections, childSection => {
          if (childSection.selected) {
            selectedIds.push(childSection.id);
          }
        });
      });
      erReportSettings.changeSetting('sections', selectedIds);
    }
  }

  vm.onSectionClicked = function(section) {
    setSections();
    if (angular.isFunction(section.action)) {
      section.action();
    }
  };

  const stateChangeSuccessUnbind = $transitions.onSuccess({}, () => {
    $timeout(() => {
      if (!destroyed) {
        refreshVisibilities();
        setSections();
      }
    });
  });

  const visibleSectionsChangeUnbind = erReportSettings.setCallback(() => {
    refreshVisibilities();
    setSections();
  }, 'visibleSections');

  $scope.$on('$destroy', () => {
    destroyed = true;
    stateChangeSuccessUnbind();
    visibleSectionsChangeUnbind();
  });

  initialize();

}

FacilitiesSidebarPresentationController.$inject = $inject;

export default FacilitiesSidebarPresentationController;
