import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms';

import isMobile from 'ismobilejs';

import { ActionType, ExecutionPhase } from '@enerkey/clients/attachments';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { getExecutionPhaseComboItems } from '../../../energy-management/constants/em-execution-phases';
import { FacilityActionsWidgetOptions } from '../facility-actions-widget/facility-actions-widget.component';

import { ACTION_TYPE_IDS } from '../../../energy-management/constants/em-shared.constant';
import { actionTypeTranslations } from '../../../energy-management/constants/em-action-types';

type ChangeFn = (options: FacilityActionsWidgetOptions) => void;

type FacilityActionsWidgetOptionsForm = {
  actionTypes: FormControl<ActionType[]>;
  executionPhase: FormControl<ExecutionPhase>;
  count: FormControl<number>;
  mobileCount: FormControl<number>;
  facilityId: FormControl<number>;
}

type FacilityActionsWidgetOptionsFormValue = {
  actionTypes: ActionType[];
  executionPhase: ExecutionPhase;
  count: number;
  mobileCount: number;
  facilityId: number;
}
@Component({
  selector: 'facility-actions-widget-options',
  templateUrl: './facility-actions-widget-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FacilityActionsWidgetOptionsComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => FacilityActionsWidgetOptionsComponent)
  }]
})
export class FacilityActionsWidgetOptionsComponent implements ControlValueAccessor, OnInit {
  public readonly executionPhaseOptions: ComboItem<ExecutionPhase>[] = getExecutionPhaseComboItems();
  public readonly actionTypeOptions: ComboItem<ActionType>[];

  public readonly showMobileCount: boolean = isMobile().any;
  public readonly minCount: number = 10;
  public readonly maxCount: number = 200;

  public formGroup: FormGroup<FacilityActionsWidgetOptionsForm>;

  private _onChange: ChangeFn;

  public constructor(
    private readonly fb: FormBuilder
  ) {
    this.actionTypeOptions = ACTION_TYPE_IDS.map(value => ({ value, text: actionTypeTranslations[value] }));
    this.formGroup = this.fb.group<FacilityActionsWidgetOptionsForm>({
      actionTypes: new FormControl([]),
      executionPhase: new FormControl(),
      count: new FormControl(),
      mobileCount: new FormControl(),
      facilityId: new FormControl(null, [Validators.required])
    });
  }

  public ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((formValue: Partial<FacilityActionsWidgetOptionsFormValue>) => {
      this._onChange?.(this.formToOptions(formValue as FacilityActionsWidgetOptionsFormValue));
    });
  }

  public writeValue(value: FacilityActionsWidgetOptions): void {
    this.formGroup.setValue(
      this.optionsToForm(value)
    );
  }

  public registerOnChange(fn: ChangeFn): void {
    this._onChange = fn;
  }

  public registerOnTouched(): void { }
  public setDisabledState(): void { }

  public validate(_: AbstractControl): ValidationErrors | null {
    if (this.formGroup.invalid) {
      return { required: true };
    } return null;
  }

  private optionsToForm(options: FacilityActionsWidgetOptions): FacilityActionsWidgetOptionsFormValue {
    return {
      facilityId: options.facilityId,
      count: options.numberToShow,
      mobileCount: options.numberToShowMobile,
      actionTypes: options.selectedActionTypes.map(x => x.id),
      executionPhase: options.selectedExecutionPhaseId ?? ExecutionPhase.Suggestion,
    };
  }

  private formToOptions(formValue: FacilityActionsWidgetOptionsFormValue): FacilityActionsWidgetOptions {
    return {
      typeToShow: 'actions',
      facilityId: formValue.facilityId,
      numberToShow: formValue.count,
      numberToShowMobile: formValue.mobileCount,
      selectedExecutionPhaseId: formValue.executionPhase,
      selectedActionTypes: formValue.actionTypes.map(id => ({ id }))
    };
  }
}
