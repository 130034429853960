<form [formGroup]="formGroup">
  <div>
    <facility-select
      *labelBefore="'FACILITIES.FACILITY' | translate"
      [defaultItemText]="''"
      formControlName="facilityId"
      class="searchInput"
    ></facility-select>
  </div>

  <div>
    <meter-groups-dropdown-list
      *labelBefore="'ADMIN.METERGROUPS.METER_GROUP' | translate"
      [defaultItemText]="''"
      (meterGroupChange)="onMeterGroupChange($event)"
      formControlName="meterGroupId"
    ></meter-groups-dropdown-list>
  </div>

  <div>
    <textarea
      formControlName="meterGroupDescription"
      kendoTextArea
      rows="5"
      *labelBefore="'ADMIN.METERGROUPS.DESCRIPTION' | translate"
    ></textarea>
  </div>

  <div class="buttons-container">
    <button
      class="button button--primary"
      [disabled]="editMode"
      (click)="searchClick.emit()"
    >
      {{ 'SEARCH' | translate }}
    </button>
  </div>
</form>
