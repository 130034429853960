import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'sidebar-section-header',
  templateUrl: './sidebar-section-header.component.html',
  styleUrls: ['./sidebar-section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSectionHeaderComponent {
  @Input() public title: string;
}
