import _ from 'lodash';

import { SEARCH_STATE } from '../../manual-qa.states';

const $inject = [
  '$element', 'ManualQaSearchListService', '$state',
  'ManualQaSearchListEditService', 'ManualQaSearchListDeleteService', 'KendoFunctions'
];

function ManualQaSearchListController(
  $element, ManualQaSearchListService, $state,
  ManualQaSearchListEditService, ManualQaSearchListDeleteService, KendoFunctions
) {
  const vm = this;

  vm.gridOptions = ManualQaSearchListService.getGridOptions();

  vm.$onInit = onInit;
  vm.$postLink = postLink;
  vm.handleSearchListChange = handleSearchListChange;
  vm.openSaveSearchListModal = openSaveSearchListModal;
  vm.openDeleteSearchListModal = openDeleteSearchListModal;
  vm.makeNewSearch = makeNewSearch;

  function onInit() {
    vm.searchLists = angular.copy(vm.searchLists);
  }

  function postLink() {
    const gridInstance = getGridInstance();

    gridInstance.setDataSource(ManualQaSearchListService.getDataSource(vm.searchLists));

    /*
      Workaround for fixing pager visibility. Required with @progress/kendo-ui@2019.3.927
      and @progress/kendo-theme-default@4.3.3
     */
    KendoFunctions.resizeKendoComponent(gridInstance, null);
  }

  /**
   * Handles search list change
   *
   * @param {Object} searchList
   */
  function handleSearchListChange(searchList) {
    if (!searchList) {
      return;
    }

    $state.go(SEARCH_STATE, { searchList: searchList });
  }

  /**
   * Opens save search list modal
   *
   * @param {Object} searchList
   */
  function openSaveSearchListModal(searchList) {
    const modalInstance = ManualQaSearchListEditService.getModal(angular.copy(searchList));

    function onSuccess(result) {
      const grid = getGridInstance();
      const searchLists = grid.dataSource.data();
      const index = _.findIndex(searchLists, { id: result.id });

      searchLists[index] = result;

      grid.dataSource.data(searchLists);
    }

    modalInstance
      .result
      .then(onSuccess)
    ;
  }

  /**
   * Opens delete search list modal
   *
   * @param {Object} searchList
   */
  function openDeleteSearchListModal(searchList) {
    const modalInstance = ManualQaSearchListDeleteService.getModal(angular.copy(searchList));
    const grid = getGridInstance();

    function onSuccess(result) {
      grid.dataSource.remove(_.find(grid.dataSource.data(), { id: result.id }));
    }

    modalInstance
      .result
      .then(onSuccess)
    ;
  }

  /**
   * Resets search lists and changes state to search view
   */
  function makeNewSearch() {
    $state.go('mqa.search');
  }

  /**
   * Returns kendo grid instance
   *
   * @returns {Object}
   */
  function getGridInstance() {
    return $element
      .find('#search-list-grid')
      .data('kendoGrid');
  }
}

ManualQaSearchListController.$inject = $inject;

export default ManualQaSearchListController;
