import ErModalReportTypeController from './er-modal-report-type.controller';
import ErModalReportTypeTemplate   from 'raw-loader!./er-modal-report-type.html';

export default {
  template: ErModalReportTypeTemplate,
  controller: ErModalReportTypeController,
  controllerAs: 'vm',
  bindings: {
    reportTypeName: '<',
    reportGroup: '<',
    meterCount: '<',
    typeChanged: '&',
    enegiaId: '@'
  }
};
