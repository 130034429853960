import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { roundByDigits } from '@enerkey/ts-utils';

@Pipe({
  name: 'adaptiveDecimal'
})
export class AdaptiveDecimalPipe implements PipeTransform {

  public constructor(private decimalPipe: DecimalPipe) {}

  public transform(value: any, digits?: number, maxDecimals?: number): string {
    if (value === null || value === undefined || value === '') {
      return '';
    }
    if (typeof value === 'string') {
      value = +value;
    }
    if (!Number.isFinite(value)) {
      return 'NaN';
    }
    const rounded = roundByDigits(value, digits, maxDecimals);
    return this.decimalPipe.transform(rounded, '1.0-9');
  }
}
