import moment from 'moment';

import * as Configs from '../constants/configs';
import { MeterHistoryItem } from '../shared/meter-history-item';

const $inject = ['ManualQaApiService'];

/**
 * Service that handles all manual qa server calls.
 *
 * @returns {Object}
 *
 * @constructor
 */
export class ManualQaApiMeterService {
  public constructor(private ManualQaApiService: any) {}
  /**
   * Changes meter factor and/or other info
   *
   * @param {Meter} meter
   * @param {Object} timeStamp
   *
   * @returns {Object}
   */
  public changeMeter(meter: any, timeStamp: any) {
    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}meter/change/${meter.getId()}`,
      data: {
        name: meter.getName(),
        description: meter.getDescription(),
        customerMeterIdentifier: meter.getCustomerMeterIdentifier(),
        factor: meter.getFactor(),
        date: timeStamp.toISOString()
      }
    });
  }

  /**
   * Returns meters history for given time period
   *
   * @param {Meter} meter
   *
   * @returns {MeterHistoryItem[]}
   */
  public getMeterHistory(meter: any) {
    return this.ManualQaApiService.doRequest({
      method: 'GET',
      url: `${Configs.API_URL}meter/history/${meter.getId()}`
    }).then((history: any) => {
      // Generating fromDate from date by copying date + 1 hour from previous history item
      for (let i = 0; i < history.data.length - 1; i++) {
        history.data[i].fromDate = moment(history.data[i + 1].date).add(1, 'hours');
      }
      return history.data.map((value: any) => new MeterHistoryItem({ ...value, meterId: value.id }));
    });
  }

  /**
   * Updates meter change information (other than factor)
   *
   * @param {MeterHistoryItem} meterHistoryItem
   * @returns {Object}
   */
  public updateMeterChange(meterHistoryItem: MeterHistoryItem) {
    const date = meterHistoryItem.date;

    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}meter/update/${meterHistoryItem.meterId}`,
      data: {
        name: meterHistoryItem.name,
        description: meterHistoryItem.description,
        customerMeterIdentifier: meterHistoryItem.customerMeterIdentifier,
        date: date !== null ? date.toISOString() : null
      }
    }).then(() => meterHistoryItem);
  }

  /**
   * Updates meter change factor
   *
   * @param {MeterHistoryItem} meterHistoryItem
   *
   * @returns {Object}
   */
  public updateMeterChangeFactor(meterHistoryItem: MeterHistoryItem) {
    const date = meterHistoryItem.date;

    return this.ManualQaApiService.doRequest({
      method: 'POST',
      url: `${Configs.API_URL}meter/updatefactor/${meterHistoryItem.meterId}`,
      data: {
        factor: meterHistoryItem.factor,
        date: date !== null ? date.toISOString() : null
      }
    }).then(() => meterHistoryItem);
  }

  /**
   * Deletes meter change
   *
   * @param {MeterHistoryItem} meterHistoryItem
   *
   * @returns {MeterHistoryItem}
   */
  public deleteMeterChange(meterHistoryItem: MeterHistoryItem) {
    return this.ManualQaApiService.doRequest({
      method: 'DELETE',
      url: `${Configs.API_URL}meter/change/${meterHistoryItem.meterId}`,
      params: { date: meterHistoryItem.date.toISOString() }
    }).then(() => meterHistoryItem);
  }
}

ManualQaApiMeterService.$inject = $inject;

export default ManualQaApiMeterService;
