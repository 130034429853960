<kendo-multiselect
  [data]="groupedItems || items"
  [kendoMultiSelectSummaryTag]="overflowThreshold - 1"
  [filterable]="filterable"
  [disabled]="disabled || (loading$ | async)"
  [textField]="textField"
  [valueField]="valueField"
  [kendoDropDownFilter]="filterSettings"
  [valuePrimitive]="true"
  [placeholder]="placeholderKey | translate"
  [autoClose]="false"
  (blur)="blur()"
  [value]="value"
  [tagMapper]="tagMapper"
  [loading]="loading$ | async"
  (valueChange)="valueChanged($event)"
  (filterChange)="filterChanged($event)"
>
  <i *kendoMultiSelectGroupTagTemplate="let dataItems">
    {{ dataItems.length }} {{ overflowKey | translate }}
  </i>
  <ng-template
    kendoComboBoxFixedGroupTemplate
    kendoMultiSelectGroupTemplate
    let-groupName
  >
    <span class="bold">
      {{ groupName }}
    </span>
  </ng-template>
  <ng-template kendoMultiSelectHeaderTemplate>
    <div *ngIf="selectAllEnabled" class="multiSelectHeader">
      <button (click)="selectAll()" kendoButton>
        &#10003;&nbsp;{{ 'MULTISELECT.SELECT_ALL' | translate }}
      </button>
    </div>
  </ng-template>
  <ng-template
    kendoMultiSelectItemTemplate
    kendoMultiSelectTagTemplate
    let-dataItem
  >
    <ng-container *ngTemplateOutlet="contentTemplate?.ref || itemTemplate; context: { $implicit: dataItem }"></ng-container>
  </ng-template>
</kendo-multiselect>

<ng-template
  #itemTemplate
  let-dataItem
>
  <span>
    {{ translate ? (dataItem[textField] | translate) : dataItem[textField] }}
  </span>
</ng-template>
