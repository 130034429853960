import { TreeViewItem } from '@enerkey/clients/metering';
import { FormulaParen } from './formula-paren';

export class FormulaParenMatchSeeker {
  public static findMatchingParenIndex(
    item: TreeViewItem,
    formula: ReadonlyArray<TreeViewItem>
  ): number {
    return new FormulaParenMatchSeeker(item, formula).findPairIndex();
  }

  private index: number;
  private readonly indexMutator: () => void;
  private readonly forCondition: () => boolean;
  private readonly startingParen: FormulaParen;

  private constructor(item: TreeViewItem, private formula: ReadonlyArray<TreeViewItem>) {
    const startIndex = this.formula.findIndex(currentItem => currentItem.itemId === item.itemId);
    if (startIndex === -1) {
      throw new Error(`Could not find given item with itemId ${item.itemId} from formula`);
    }
    switch (item.content) {
      case FormulaParen.ClosedParen:
        this.startingParen = FormulaParen.ClosedParen;
        this.index = startIndex - 1;
        this.indexMutator = () => this.index--;
        this.forCondition = () => this.index > 0;
        break;
      case FormulaParen.OpenParen:
        this.startingParen = FormulaParen.OpenParen;
        this.index = startIndex + 1;
        this.indexMutator = () => this.index++;
        this.forCondition = () => this.index < this.formula.length;
        break;
      default:
        throw new Error(`Item content ${item.content} is not a paren`);
    }
  }
  private findPairIndex(): number {
    let matchIndex = -1;
    let unpairedParenAmount = 1;

    for (this.index; this.forCondition(); this.indexMutator()) {
      const currentItem = this.formula[this.index];
      if (this.isOppositeParen(currentItem)) {
        unpairedParenAmount--;
        if (unpairedParenAmount === 0) {
          matchIndex = this.index;
          break;
        }
      } else if (this.isMatchingParen(currentItem)) {
        unpairedParenAmount++;
      }
    }

    return matchIndex;
  }

  private isMatchingParen(item: TreeViewItem): boolean {
    return item.content === this.startingParen;
  }

  private isOppositeParen(item: TreeViewItem): boolean {
    return Object.values(FormulaParen).includes(item.content as any) && item.content !== this.startingParen;
  }
}
