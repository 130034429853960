import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { EkFormsModule } from '@enerkey/ek-forms';

import { TerminalControlComponent } from './components/terminal-control/terminal-control.component';
import { SharedModule } from '../../shared/shared.module';
import {
  TerminalStatusEditModalComponent
} from './components/terminal-status-edit-modal/terminal-status-edit-modal.component';
import { EkKendoModule } from '../../shared/ek-kendo/ek-kendo.module';
import { EditBucketModalComponent } from './edit-bucket-modal/edit-bucket-modal.component';
import { ShowPasswordModalComponent } from './show-password-modal/show-password-modal.component';

@NgModule({
  declarations: [
    TerminalControlComponent,
    TerminalStatusEditModalComponent,
    EditBucketModalComponent,
    ShowPasswordModalComponent,
  ],
  imports: [
    CommonModule,
    GridModule,
    SharedModule,
    EkKendoModule,
    EkFormsModule,
    InputsModule,
    ButtonsModule,
  ],
})
export class ConfigurationControlModule { }
