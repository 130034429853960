import { Directive, Optional } from '@angular/core';
import { SaveCommandDirective } from '@progress/kendo-angular-grid';

import { KendoGridButtonDirective } from './kendo-grid-button.directive';

@Directive({
  selector: '[kendoGridSaveCommand]'
})
export class KendoGridSaveCommandDirective extends KendoGridButtonDirective {
  public constructor(@Optional() saveCommand: SaveCommandDirective) {
    super(saveCommand);
    if (saveCommand) {
      saveCommand.iconClass = 'fa-1x fa fa-check';
    }
  }
}
