import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const contractTabState: UIStateNg2 = {
  name: 'contract',
  data: {
    translationKey: 'ADMIN.TABS.CONTRACTS',
    auth: {
      roles: [Roles.CONTRACT_MANAGER],
    },
  }
};
