import template from 'raw-loader!./manual-qa-inspect-modal.html';

import { $InjectArgs } from '@enerkey/ts-utils';

import SearchType, { StorageSearchType } from '../../constants/search-types';
import ManualQaInspectModalController from './manual-qa-inspect-modal.controller';
import TimeFrame from '../../../../services/time-frame-service';

export class ManualQaInspectModalService {
  public static readonly $inject: $InjectArgs<typeof ManualQaInspectModalService> = ['$modal'];

  public constructor(private readonly $modal: any) {
  }

  public getInspectModal(
    meterIds: number | number[],
    timeFrame = TimeFrame.getCurrentMonth(),
    searchType: StorageSearchType = SearchType.STORAGE_TYPE.DEFECT,
    defaultMeterId?: number
  ): { result: Promise<unknown> } {
    return this.$modal.open({
      template,
      windowClass: 'reveal full manual-qa-inspect',
      controllerAs: 'vm',
      controller: ManualQaInspectModalController,
      resolve: {
        meterIds: () => Array.isArray(meterIds) ? meterIds : [meterIds],
        timeFrame: () => timeFrame,
        searchType: () => searchType,
        defaultMeterId: () => defaultMeterId
      }
    });
  }
}
