import _ from 'lodash';

import templateModule from 'raw-loader!../templates/exportselection-modal.html';

DownloadController.$inject = [
  '$rootScope', '$scope', '$state', 'utils', '$modal', 'printingLimit', 'appStatusService',
  '$transitions'
];

export function DownloadController(
  $rootScope, $scope, $state, utils, $modal, printingLimit, appStatusService,
  $transitions
) {
  const exportOptionKeys = {
    mass: 'massImportFormOptions',
    meter: 'meterInputFormOptions',
  };
  let exportFinishedUnbind;
  let gridsToMerge = [];

  function getGridColumnIndexByName(columns, columnName) {
    return _.findIndex(columns, { field: columnName });
  }

  function customExport(grid, exportOptionsName) {
    const options = _.get(grid, 'options');
    const exportOptions = _.get(options, exportOptionsName);

    const oldOptions = {
      options: _.cloneDeep(options),
      filter: grid.dataSource.filter(),
      group: grid.dataSource.group(),
      sort: grid.dataSource.sort()
    };

    if (exportOptions.fileName) {
      options.excel.fileName = exportOptions.fileName();
    }
    // Set custom column titles
    if (exportOptions.customColumnTitles) {
      _.each(Object.keys(exportOptions.customColumnTitles), columnName => {
        options.columns[getGridColumnIndexByName(options.columns, columnName)].title =
          exportOptions.customColumnTitles[columnName];
      });
    }

    // Set custom column widths
    if (exportOptions.customColumnWidths) {
      _.each(Object.keys(exportOptions.customColumnWidths), columnName => {
        options.columns[getGridColumnIndexByName(options.columns, columnName)].width =
          exportOptions.customColumnWidths[columnName];
      });
    }

    // Set custom group header templates
    if (exportOptions.groupHeaderTemplates) {
      _.each(Object.keys(exportOptions.groupHeaderTemplates), columnName => {
        options.columns[getGridColumnIndexByName(options.columns, columnName)].groupHeaderTemplate =
          exportOptions.groupHeaderTemplates[columnName];
      });
    }
    grid.setOptions(options);
    _.each(grid.columns, column => {
      // Unlock all but "selected" column,
      // locked columns cause problems when rearranging columns
      if (column.field !== 'selected') {
        grid.unlockColumn(column.field);
      }
      grid.hideColumn(column.field);
    });

    // Show only wanted columns
    _.each(exportOptions.shownFields, column => {
      grid.showColumn(column);
      // Set column width to automatic if not disabled
      if (!_.includes(exportOptions.disableAutoFitForColumns, column)) {
        grid.autoFitColumn(column);
      }
    });

    // Set column order to match order in exportOptions.shownFields
    // Start from 1 instead of 0, locked column "selected" must be first
    for (let i = 0; i < exportOptions.shownFields.length; i++) {
      grid.reorderColumn(i + 1, grid.columns[getGridColumnIndexByName(grid.columns, exportOptions.shownFields[i])]);
    }

    if (exportOptions.grouping) {
      grid.dataSource.group(exportOptions.grouping);
    }

    if (exportOptions.sorting) {
      grid.dataSource.sort(exportOptions.sorting);
    }

    if (exportOptions.filtering) {
      grid.dataSource.filter(exportOptions.filtering);
    }

    grid.saveAsExcel();

    // Restore options, sorting ect.
    grid.setOptions(oldOptions.options);
    grid.dataSource.filter(oldOptions.filter);
    grid.dataSource.group(oldOptions.group);
    grid.dataSource.sort(oldOptions.sort);
  }

  function showExportNotSupportedError() {
    utils.popUpGeneralError('DOWNLOAD', 'REPORT', utils.localizedString('GENERAL_ERROR.NOT_SUPPORTED_FOR_THE_PAGE'));
  }

  function doExportWithCustomOptions(customOptions) {
    const grids = findGridsInPage();

    if (!grids.length) {
      showExportNotSupportedError();
    } else {
      // collect grids to be merged into one file
      const gridElement = angular.element(grids[0]);
      const grid = gridElement.data('kendoGrid') ||
        gridElement.data('kendoTreeList') ||
        gridElement.data('kendoSpreadsheet');
      customExport(grid, customOptions);
    }
    // send an event without parameters.
    $rootScope.$emit('export.finished');
  }

  function doExcelExport() {
    let gridCount = 0;

    const grids = findGridsInPage();
    if (!grids.length) {
      showExportNotSupportedError();
    } else {
      gridsToMerge = [];

      // collect grids to be merged into one file
      grids.each(function() {
        /* eslint-disable no-invalid-this */
        const grid = angular.element(this).data('kendoGrid') ||
          angular.element(this).data('kendoTreeList') ||
          angular.element(this).data('kendoSpreadsheet');
        /* eslint-enable no-invalid-this */
        const options = _.get(grid, 'options');
        if (_.isArray(options.hiddenColumnIndicesForExport)) {
          _.each(options.hiddenColumnIndicesForExport, colIdx => {
            grid.showColumn(colIdx);
          });
        }
        if (_.isArray(options.exportExcludedColumnIndices)) {
          _.each(options.exportExcludedColumnIndices, colIdx => {
            grid.hideColumn(colIdx);
          });
        }
        const excelExport = _.get(options, 'excelExport', '').toString();
        const sheetName = _.get(options, 'excel.title', '');
        if (
          excelExport &&
          excelExport.indexOf('preventDefault') !== -1 &&
          excelExport.indexOf('export.finished') !== -1
        ) {
          gridsToMerge.push({ id: grid.$angular_scope.$id, sheetName: sheetName });
        }
      });

      // save as excel
      grids.each(function() {
        let grid;
        let isTreeList = false;
        // eslint-disable-next-line angular/controller-as-vm,no-invalid-this
        const gridElement = angular.element(this);
        if (gridElement.data('kendoTreeList')) {
          isTreeList = true;
          grid = gridElement.data('kendoTreeList');
        } else {
          grid = gridElement.data('kendoGrid') || gridElement.data('kendoSpreadsheet');
        }
        if (grid) {
          if (!isTreeList) {
            grid.saveAsExcel();
            gridCount++;
            const bound = grid.bind('excelExport', callback);

            // eslint-disable-next-line no-inner-declarations
            function callback() {
              checkIfDone();
              bound.unbind('excelExport', callback);
            }

          } else {
            const oldPageSize = grid.dataSource.pageSize();
            const oldPage = grid.dataSource.page();
            const oldColumns = _.cloneDeep(grid.columns);

            if (oldPageSize) {
              grid.dataSource.pageSize(null);
            }

            grid.columns = grid.columns.flatMap(column =>
              Array.hasItems(column.columns)
                ? column.columns
                : [column]);

            grid.saveAsExcel();

            if (oldPageSize) {
              grid.dataSource.pageSize(oldPageSize);
            }
            grid.dataSource.page(oldPage);
            grid.columns = oldColumns;
          }
        }
      });

      grids.each(function() {
        /* eslint-disable no-invalid-this */
        const grid = angular.element(this).data('kendoGrid') ||
          angular.element(this).data('kendoTreeList') ||
          angular.element(this).data('kendoSpreadsheet');
        /* eslint-enable no-invalid-this */
        const options = _.get(grid, 'options');
        if (_.isArray(options.hiddenColumnIndicesForExport)) {
          _.each(options.hiddenColumnIndicesForExport, colIdx => {
            grid.hideColumn(colIdx);
          });
        }
        if (_.isArray(options.exportExcludedColumnIndices)) {
          _.each(options.exportExcludedColumnIndices, colIdx => {
            grid.showColumn(colIdx);
          });
        }
      });
    }

    // eslint-disable-next-line no-inner-declarations
    function checkIfDone() {
      if (--gridCount <= 0) {
        setTimeout(() => $rootScope.$emit('export.finished'), 10);
      }
    }

    // fallback to old if no grids on page
    if (gridCount === 0) {
      // send an event without parameters.
      setTimeout(() => {
        $rootScope.$emit('export.finished');
      }, 250);
    }
  }

  function findGridsInPage() {
    const gridLocation = appStatusService.inModal ? '.reveal' : null;
    const kendoElements = '[kendo-grid], [kendo-treelist], [kendo-spreadsheet], .k-spreadsheet';
    if ($scope.downloadParams && $scope.downloadParams.element) {
      return angular.element($scope.downloadParams.element).find(kendoElements, gridLocation);
    }
    return angular.element(kendoElements, gridLocation);
  }

  function handleClientsideExportFinished() {
    // start listening to the event here. This way we only have one downloadcontroller at a time
    // listening (this way modal situations work).
    if (exportFinishedUnbind) {
      exportFinishedUnbind();
    }
    exportFinishedUnbind = $rootScope.$on('export.finished', (event, grid, workbook) => {

      if (!grid && !workbook) {
        // last one, stop listening.
        exportFinishedUnbind();
        return;
      }

      // store workbook
      const gridData = _.find(gridsToMerge, { id: grid.$angular_scope.$id });
      if (gridData) {
        gridData.workbook = workbook;
      }

      // check if all collected and merge into one file
      const allCollected = _.chain(gridsToMerge)
        .pluck('workbook')
        .compact()
        .value()
        .length === gridsToMerge.length;
      if (allCollected) {
        const combinedWorkbook = new kendo.ooxml.Workbook({
          sheets: _.chain(gridsToMerge)
            .map(gridDataItem =>
              _.extend(gridDataItem.workbook.sheets[0], { title: gridDataItem.sheetName }))
            .value()
        });
        const fileNameKey = getDownloadOptions($state.$current).fileNameKey;
        const fileName = `${fileNameKey ? utils.localizedString(fileNameKey) : 'EnerKey'
        }_${kendo.toString(new Date(), 'd')}.xlsx`;
        combinedWorkbook.toDataURLAsync().then(dataURI => {
          kendo.saveAs({
            dataURI,
            fileName
          });
        });
      }
    });
  }

  function doClientSideOrServersideExcel(exportOptions) {
    if (exportOptions.exportType === 'server') {
      $scope.$emit('export.starting');
    } else {
      handleClientsideExportFinished();

      // emit that export is starting
      $rootScope.$emit('client-side-export.starting');

      exportOptions.exportFunction.apply(null, exportOptions.exportFunctionParams);
    }
  }

  $scope.exportAsMassImportForm = function() {
    $scope.exportAsExcel(doExportWithCustomOptions, [exportOptionKeys.mass]);
  };
  $scope.exportAsMeterInputForm = function() {
    $scope.exportAsExcel(doExportWithCustomOptions, [exportOptionKeys.meter]);
  };
  $scope.exportAsFullExcel = function() {
    $scope.exportAsExcel(doExcelExport);
  };

  $scope.exportAsExcel = function(exportFunction, params) {
    let exportOptions = {
      exportType: 'client',
      askPrintOrExcel: false
    };
    exportOptions = _.extend(exportOptions, getDownloadOptions($state.current));
    exportOptions.exportFunction = exportFunction ? exportFunction : doExcelExport;
    exportOptions.exportFunctionParams = params;

    if (exportOptions.askPrintOrExcel) {
      const modalInstance = $modal.open({
        template: templateModule,
        controller: ['$scope', '$modalInstance', function(scope, $modalInstance) {
          scope.translationData = {
            printingLimit: printingLimit
          };

          scope.close = function() {
            $modalInstance.close();
          };
          scope.cancel = function() {
            $modalInstance.close();
          };
          scope.print = function() {
            $modalInstance.close({ print: true });
          };
          scope.excel = function() {
            $modalInstance.close({ excel: true });
          };
        }]
      });

      modalInstance.result.then(result => {
        if (result) {
          if (result.excel) {
            doClientSideOrServersideExcel(exportOptions);
          }
          if (result.print) {
            $scope.$emit('print.starting');
            return;
          }
        }
      });
    } else {
      doClientSideOrServersideExcel(exportOptions);
    }
  };

  $scope.downloadOptions = {
    fullExcel: {
      enabled: false,
      downloadFunction: $scope.exportAsFullExcel,
      title: 'DOWNLOAD.FULL_REPORT'
    },
    meterInputFormExcel: {
      enabled: false,
      downloadFunction: $scope.exportAsMeterInputForm,
      title: 'DOWNLOAD.METER_INPUT_FORM'
    },
    massImportFormExcel: {
      enabled: false,
      downloadFunction: $scope.exportAsMassImportForm,
      title: 'DOWNLOAD.SHORT_REPORT'
    },
  };

  const stateChangeSuccessUnbind = $transitions.onSuccess({}, transition => {
    setDownloadOptions(transition.to());
  });

  $scope.$watch('downloadParams', () => {
    if ($scope.downloadParams) {
      setDownloadOptions();
    }
  });

  function getDownloadOptions(state) {
    const options = $scope.downloadParams || _.get(state, 'data.export');
    return _.isObject(options) ? options : {};
  }

  function setDownloadOptions(state = {}) {
    $scope.downloadOptions.fullExcel.enabled = getDownloadOptions(state).enabled === true;
    $scope.downloadOptions.meterInputFormExcel.enabled = state.name === 'mrc.readings_listing.latest_readings';
    $scope.downloadOptions.massImportFormExcel.enabled = state.name === 'mrc.readings_listing.latest_readings';

    $scope.enabledDownloadOptions = _.filter($scope.downloadOptions, option => option.enabled);

    $scope.singleDownloadOptionEnabled = $scope.enabledDownloadOptions.length === 1;
    $scope.allDownloadsDisabled = $scope.enabledDownloadOptions.length === 0;

    // Set tooltip to dropdown link only if single or no download option is enabled
    $scope.dropdownLinkTooltip = '';
    if ($scope.singleDownloadOptionEnabled) {
      $scope.dropdownLinkTooltip = utils.localizedString('DOWNLOAD.DOWNLOAD_REPORT');
    } else if ($scope.allDownloadsDisabled) {
      $scope.dropdownLinkTooltip = utils.localizedString('DOWNLOAD.NO_REPORTS_TO_DOWNLOAD');
    }
  }

  /*
    Set download options always when controller is initialized.
    Modal has own instance of download controller and when modal is opened
    stateChangeSuccess event is handled before modals download controller
    exists.
  */
  setDownloadOptions($state.current);

  $scope.$on('$destroy', () => {
    stateChangeSuccessUnbind();

    if (exportFinishedUnbind) {
      exportFinishedUnbind();
    }
  });
}
