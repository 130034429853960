import ManualQaInspectController from './manual-qa-inspect-controller';
import template from 'raw-loader!./manual-qa-inspect.html';

export default {
  template: template,
  controller: ManualQaInspectController,
  controllerAs: 'vm',
  bindings: {
    actualTimeFrame: '<timeFrame',
    searchType: '<',
    meterIds: '<',
    defaultMeterId: '<'
  }
};
