import { Directive, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { catchError, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { ApiKeyInfo, ApiKeyType } from '@enerkey/clients/user-management';
import { ModalService, NgfActiveModal } from '@enerkey/foundation-angular';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { DialogService } from '../../shared/services/dialog.service';
import { ToasterService } from '../../shared/services/toaster.service';

const configurableApiKeys = [
  ApiKeyType.Primary,
  ApiKeyType.Secondary,
  ApiKeyType.Tertiary,
  ApiKeyType.Quaternary,
] as const;

export type ConfigurableApiKey = Extract<ApiKeyType, typeof configurableApiKeys[number]>;

@Directive()
export abstract class ApiKeysComponent<TReturn = void> implements OnDestroy {
  public abstract openCreateModal(keyType: ApiKeyType, renew: boolean): void;

  protected abstract getKeys(): Observable<ApiKeyInfo[]>;
  protected abstract deleteKey(key: ApiKeyInfo): Observable<unknown>;

  public readonly apiKeys$: Observable<Record<ConfigurableApiKey, ApiKeyInfo>>;
  public readonly currentDate = new Date();
  public readonly ApiKeyType = ApiKeyType;
  public readonly configurableapikey = configurableApiKeys;
  public readonly loading$: Observable<boolean>;

  protected readonly _refreshApiKeys$ = new BehaviorSubject<void>(undefined);

  private readonly _loading$ = new LoadingSubject(true);
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService,
    protected readonly modalService: ModalService,
    private readonly currentModal: NgfActiveModal
  ) {
    this.loading$ = this._loading$.asObservable();

    this.apiKeys$ = this._refreshApiKeys$.pipe(
      switchMap(() => this.getKeys().pipe(
        indicate(this._loading$),
        map(keys => {
          const keyMap = keys.toMapBy('keyType');
          keyMap.forEach(key => {
            if (key?.validTo?.getFullYear() === 3000) {
              key.validTo = null;
            }
          });
          return {
            [ApiKeyType.Primary]: keyMap.get(ApiKeyType.Primary),
            [ApiKeyType.Secondary]: keyMap.get(ApiKeyType.Secondary),
            [ApiKeyType.Tertiary]: keyMap.get(ApiKeyType.Tertiary),
            [ApiKeyType.Quaternary]: keyMap.get(ApiKeyType.Quaternary),
          };
        }),
        catchError(() => of({
          [ApiKeyType.Primary]: null,
          [ApiKeyType.Secondary]: null,
          [ApiKeyType.Tertiary]: null,
          [ApiKeyType.Quaternary]: null,
        }))
      )),
      shareReplay(1),
      takeUntil(this._destroy$)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._refreshApiKeys$.complete();
    this._loading$.complete();
  }

  public deleteApiKey(apiKey: ApiKeyInfo): void {
    this.dialogService.getConfirmationModal({
      title: 'DATA_API_KEYS.DELETE',
      text: 'DATA_API_KEYS.CONFIRM_DELETE',
      isDelete: true,
      translate: true
    }).subscribe({
      next: () => {
        this.deleteKey(apiKey)
          .pipe(
            indicate(this._loading$)
          ).subscribe({
            next: () => {
              this.toasterService.info('DATA_API_KEYS.DELETED');
              this._refreshApiKeys$.next();
            },
            error: () => this.toasterService.error('DATA_API_KEYS.ERROR_KEY_DELETE')
          });
      }
    });
  }

  public closeModal(result?: TReturn): void {
    this.currentModal.close(result);
  }
}
