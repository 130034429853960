<modal-view-header
  [headingText]="selectedDocument
    ? selectedDocument.fileNameOrUrl + ' (ID: ' + selectedDocument.id + ')'
    : ('ACTIONS.ADD_ACTION_MODAL_TITLE' | translate)
    "
></modal-view-header>
<div class="modal-body">
  <form
    class="bold-labels"
    [formGroup]="documentsForm"
    (ngSubmit)="submit()"
    id="documentsForm"
  >
    <div class="row">
      <div class="small-2 columns">
        <label class="k-label">
          {{ 'DOCUMENTS.ATTACHMENT_TYPE' | translate }}
        </label>
        <div
          *ngIf="(!isExistingOrForSingleFacility || defaultType === AttachmentType.General) && (isSuperCompanyUser$ | async)"
          class="marginBottom"
        >
          <input
            *labelWrap="'DOCUMENTS.ATTACHMENT_TYPE_4' | translate"
            formControlName="levelTypeSelect"
            type="radio"
            kendoRadioButton
            [value]="AttachmentType.General"
          />
        </div>
        <div
          *ngIf="!isExistingOrForSingleFacility ||
            defaultType === AttachmentType.Organization ||
            defaultType === AttachmentType.ReportingObject"
          class="marginBottom"
        >
          <input
            *labelWrap="'DOCUMENTS.ATTACHMENT_TYPE_0' | translate"
            formControlName="levelTypeSelect"
            type="radio"
            kendoRadioButton
            [value]="AttachmentType.Organization"
          />
        </div>
        <div
          *ngIf="!isExistingOrForSingleFacility ||
            defaultType === AttachmentType.Organization ||
            defaultType === AttachmentType.ReportingObject"
          class="marginBottom"
        >
          <input
            *labelWrap="'DOCUMENTS.ATTACHMENT_TYPE_2' | translate"
            formControlName="levelTypeSelect"
            type="radio"
            kendoRadioButton
            [value]="AttachmentType.ReportingObject"
          />
        </div>
        <div
          *ngIf="isExistingOrForSingleFacility && defaultType === AttachmentType.Meter"
          class="marginBottom"
        >
          <input
            *labelWrap="'DOCUMENTS.ATTACHMENT_TYPE_3' | translate"
            formControlName="levelTypeSelect"
            type="radio"
            kendoRadioButton
            [value]="AttachmentType.Meter"
          />
        </div>
      </div>
      <div class="small-4 columns" [ngSwitch]="documentsForm.value.levelTypeSelect">
        <ng-container *ngSwitchCase="AttachmentType.Organization">
          <company-dropdown
            formControlName="organization"
            class="customWidth marginBottom"
          ></company-dropdown>
          <ek-combo
            formControlName="partnerVisibility"
            class="customWidth marginBottom"
            [items]="partnerVisibilityItems"
            *labelBefore="'DOCUMENTS.PARTNER_VISIBILITY' | translate"
          ></ek-combo>
          <p *ngIf="selectedOrganization$ | async as selectedOrganization">
            {{ 'DOCUMENTS.VISIBILITY_INFO_SINGLE_COMPANY' | translate:{
                company1Name: selectedOrganization
              }
            }}
            <ng-container *ngIf="selectedPartner$ | async as selectedPartner">
              {{ 'DOCUMENTS.VISIBILITY_INFO_PARTNERS' | translate:{
                targetCompanyName: selectedOrganization,
                partnerTypeName: selectedPartner
               }
              }}
            </ng-container>
          </p>
        </ng-container>
        <ng-container
          *ngSwitchCase="AttachmentType.ReportingObject"
          [ngTemplateOutlet]="facilitySelect"
        ></ng-container>
        <ng-container
          *ngSwitchCase="AttachmentType.Meter"
          [ngTemplateOutlet]="facilitySelect"
        ></ng-container>
        <ng-template #facilitySelect>
          <ek-filterable-combo
            formControlName="facility"
            class="customWidth"
            [filterable]="true"
            [items]="facilityList$ | async"
            [loading]="facilityLoading$ | async"
            [readonly]="!!facilityId"
            *labelBefore="'DOCUMENTS.ATTACHMENT_TYPE_2' | translate"
          ></ek-filterable-combo>
        </ng-template>
      </div>
      <div class="small-6 columns">
        <ng-container *ngIf="selectedDocument && defaultType === AttachmentType.Meter">
          <label class="k-label">{{ 'DOCUMENTS.METER' | translate }}</label>
          {{ selectedDocument?.firstMeterName }}
        </ng-container>
        <ng-container *ngIf="facilityId && defaultType === AttachmentType.Meter">
          <label class="k-label">{{ 'DOCUMENTS.METER' | translate }}</label>
          {{ meter?.name }}
        </ng-container>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="small-4 columns">
        <label class="k-label">{{ 'DOCUMENTS.DOCUMENT_TYPE' | translate }}</label>
        <ek-combo
          formControlName="type"
          class="customWidth"
          [items]="documentTypeItems$ | async"
        ></ek-combo>
      </div>
      <div class="small-8 columns">
        <ng-container *ngIf="periodType$ | async as periodType; else validUntil">
          <label class="k-label">{{ 'DOCUMENTS.PERIOD_STARTTIME' | translate }}</label>
          <div class="customWidth">
            <dateperiod-select
              [period]="periodType"
              [minYear]="selectedDocument?.periodStartTime?.getFullYear() || (currentYear - 3)"
              [maxYear]="currentYear"
              formControlName="period"
            ></dateperiod-select>
          </div>
        </ng-container>
        <ng-template #validUntil>
          <label class="k-label">{{ 'DOCUMENTS.VALID_UNTIL_DATE' | translate }}</label>
          <kendo-datepicker
            class="customWidth"
            formControlName="expireDate"
          ></kendo-datepicker>
        </ng-template>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="columns">
        <label class="k-label">{{ 'DOCUMENTS.FILE' | translate }}</label>
        <kendo-fileselect
          *ngIf="!selectedDocument"
          (select)="selectFileEventHandler($event)"
          (remove)="removeFileEventHandler($event)"
        >
          <kendo-fileselect-messages
            [select]="'kendo.upload.select' | translate"
            [remove]="'kendo.upload.remove' | translate"
            [dropFilesHere]="'kendo.upload.dropFilesHere' | translate"
          ></kendo-fileselect-messages>
        </kendo-fileselect>
        <ng-container *ngIf="selectedDocument">
          <button
            type="button button--link"
            class="marginBottom"
            (click)="openFilePreview()"
          >
            <a><i class="far fa-file"></i> {{ selectedDocument.fileNameOrUrl }}</a>
          </button>
        </ng-container>
      </div>
    </div>
    <div class="row" *ngIf="selectedDocument">
      <div class="columns">
        <label class="k-label">{{ 'DOCUMENTS.DOCUMENT_UPDATE_INFO' | translate }}</label>
        <div>
          {{ 'DOCUMENTS.CREATED_AT' | translate }}: {{ selectedDocument?.createdAt | date: 'short' }}
          <ng-container *ngIf="selectedDocument?.creatorName">
            ({{ 'DOCUMENTS.CREATOR_NAME' | translate }} {{ selectedDocument?.creatorName }})
          </ng-container>
        </div>
        <div>
          {{ 'DOCUMENTS.UPDATED_AT' | translate }}: {{ selectedDocument?.updatedAt | date: 'short' }}
          <ng-container *ngIf="selectedDocument?.updaterName">
            ({{ 'DOCUMENTS.UPDATER_NAME' | translate }} {{ selectedDocument?.updaterName }})
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="documentsForm"
      [loading]="loading$ | async"
      [disabled]="!documentsForm.valid ||
        formDisabled ||
        (!uploadedFiles.length && !selectedDocument)
      "
    >
      {{ 'MODALS.SAVE' | translate }}
    </button>
    <button
      *ngIf="selectedDocument"
      class="button button--negative"
      (click)="deleteDocument()"
      [loading]="loading$ | async"
      [disabled]="formDisabled"
    >
      {{ 'DELETE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
