import * as Operations from './operations';

const $inject = [
  'ManualQaActionsOperationService',
  'ManualQaMeterHistoryService',
  'MeterInterfaceService'
];

function ManualQaActionsOperationController(
  ManualQaActionsOperationService,
  ManualQaMeterHistoryService,
  MeterInterfaceService
) {
  const vm = this;

  vm.operations = [];

  vm.$onInit = onInit;
  vm.handleClick = handleClick;

  function onInit() {
    vm.operations = ManualQaActionsOperationService.getOperations();
  }

  /**
   * Generic handler for operations.
   *
   * @param {Object} operation
   */
  function handleClick(operation) {
    switch (operation.id) {
      case Operations.LOCK_MODEL:
        return vm.lockModel({ lockModel: true });
      case Operations.UNLOCK_MODEL:
        return vm.lockModel({ lockModel: false });
      case Operations.DELETE_MODEL:
        return vm.deleteModel();
      case Operations.DELETE_CONSUMPTION:
        return vm.deleteConsumption();
      case Operations.QA_COMMENT:
        return addQAComment();
      case Operations.JIRA_TICKET:
        return addJiraTicket();
      case Operations.METER_HISTORY:
        return meterHistory();
      case Operations.METER_INTERFACE_STATUS:
        return MeterInterfaceService.getModal(vm.meter.getFacility().getName(), vm.meter.getId(), vm.meter.getName());
      default:
        break;
    }
  }

  function addQAComment() {
    // TODO: implement content.
  }

  function addJiraTicket() {
    // TODO: implement content.
  }

  function meterHistory() {
    ManualQaMeterHistoryService.getModal(vm.meter);
  }
}

ManualQaActionsOperationController.$inject = $inject;

export default ManualQaActionsOperationController;
