import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { MultiSelectComponent } from '../multi-select/multi-select.component';

type MultiSelectItem<T> = {
  readonly text: string;
  readonly value: T;
  readonly group?: string,
  readonly groupKey?: string;
};

@Component({
  selector: 'ek-multi',
  templateUrl: '../multi-select/multi-select.component.html',
  styleUrls: ['../multi-select/multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EkMultiComponent),
    multi: true
  }],
})
export class EkMultiComponent extends MultiSelectComponent<MultiSelectItem<unknown>, 'value', 'text'> {

  public overflowKey = '+';
  public valueField = 'value' as const;
  public textField = 'text' as const;

  @Input() public placeholderKey = '';
  @Input() public override items: MultiSelectItem<unknown>[] = [];
  @Input() public translateText: boolean = true;
  @Input() public overflowThreshold = 2;

  public constructor(
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(changeDetectorRef);
  }
}
