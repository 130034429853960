import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';

import { Consumption } from '@enerkey/clients/reporting';

@Component({
  selector: 'value-flag-cell',
  templateUrl: './value-flag-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValueFlagCellComponent {
  @Input({ required: true }) public consumption: Consumption;
  @Input({ required: true }) public visibleValue: number;
  @Input({ required: true }) public format: string;

  public readonly tooltipNumberFormat: NumberFormatOptions = {
    style: 'percent',
    maximumFractionDigits: 2,
  };
}
