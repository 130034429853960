import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { tap } from 'rxjs';

import { ReportingSeriesCollection } from '../../../../modules/reporting/shared/reporting-series-collection';
import { ReportingSearchService } from '../../../../modules/reporting/services/reporting-search.service';
import { ReportingSearchFormValue } from '../../../../modules/reporting/shared/reporting-search-form-value';

type ChangeDatesLimits = {
  inspection: [Date, Date]
  comparison: [Date, Date]
}

@Component({
  selector: 'consumptions-change-tooltip',
  templateUrl: './consumptions-change-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumptionsChangeTooltipComponent {
  @Input() public seriesCollection: ReportingSeriesCollection;
  private durationName: ReportingSearchFormValue['durationName'];

  public constructor(
    public reportingSearchService: ReportingSearchService
  ) {
    this.reportingSearchService.searchParameters$.pipe(tap(v => {
      this.durationName = v.formValue.durationName;
    })).subscribe();
  }

  public get getChangeDates(): ChangeDatesLimits {
    const { inspectionPeriod, comparisonPeriod } = this.seriesCollection;
    if (!inspectionPeriod || !comparisonPeriod) {
      return null;
    }
    const insp = inspectionPeriod.consumptions;
    const comp = comparisonPeriod.consumptions;

    const dates: ChangeDatesLimits = {
      inspection: [
        insp[0].timestamp,
        this.getEndDate(insp[insp.length - 1].timestamp)
      ],
      comparison: [
        comp[0].timestamp,
        this.getEndDate(comp[comp.length - 1].timestamp)
      ]
    };
    return dates;
  }

  private getEndDate(date: Date): Date {
    if (this.durationName === 'years') {
      return new Date(`${date.getFullYear()}-12-31`);
    }
    return date;
  }
}
