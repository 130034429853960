<kendo-grid
  [kendoGridBinding]="gridData"
  [group]="grouping"
  [loading]="loading || (loading$ | async)"
  [rowClass]="rowCallback"
  [class.is-standalone]="isReadOnly"
>
  <ng-template kendoGridToolbarTemplate>
    <ng-container *ngIf="metadata">
      <button
        *ngIf="!isReadOnly"
        kendoButton
        size="small"
        icon="plus"
        (click)="setGroupExpanded(true)"
      >
        {{ 'SHOW' | translate }}
      </button>

      <button
        *ngIf="!isReadOnly"
        kendoButton
        size="small"
        icon="minus"
        (click)="setGroupExpanded(false)"
      >
        {{ 'HIDE' | translate }}
      </button>

      <ng-container *ngIf="quantityId">
        <strong>{{ 'QUANTITY' | translate }}:</strong>
        <quantity-icon [quantity]="quantityId"></quantity-icon>
        {{ quantityId | quantityName | async }}
        &#x25cf;
      </ng-container>
      <strong>{{ 'SUSTAINABILITY.GRI.FETCHED' | translate }}:</strong>
      <span>{{ metadata.importedAt | kendoDate }}</span>
      &#x25cf;
      <strong>
        {{ 'SUSTAINABILITY.GRI.UNIT' | translate }}
        ({{ 'SUSTAINABILITY.GRI.UNIT_SOURCE' | translate | lowercase }}):
      </strong>
      <span>{{ metadata.unit }}</span>

      <div *ngIf="row">
        <button
          kendoButton
          icon="import"
          [disabled]="loading || (loading$ | async)"
          (click)="refreshConsumptions()"
        >
          {{ 'SUSTAINABILITY.GRI.UPDATE_CONSUMPTIONS' | translate }}
        </button>
      </div>

      <ng-container *ngIf="!isReadOnly">
        |&nbsp;
        <strong>{{ 'SUSTAINABILITY.GRI.SHARED_UNIT' | translate }}:</strong>
        <gri-unit-combo
          [formControl]="sharedUnitControl"
          [quantityId]="quantityId"
          [quantitySorter]="{countries: availableFacilityCountryCodes}"
          [quantityFilter]="availableFacilityCountryCodes"
          [rowUnitTypes]="rowUnitTypes"
        ></gri-unit-combo>
      </ng-container>

      <button
        [disabled]="!sharedUnitControl?.value || haveFormControls"
        icon="arrow-seek-down"
        kendoButton
        (click)="sharedUnitToAll()"
        [attr.title]="'SUSTAINABILITY.GRI.SHARED_UNIT_TO_ALL' | translate "
      >
      </button>

    </ng-container>
  </ng-template>

  <kendo-grid-column
    *ngIf="!isReadOnly"
    [title]="''"
    [width]="32"
    field="included"
  >
    <ng-template
      kendoGridCellTemplate
      let-dataItem
    >
      <input
        kendoCheckBox
        type="checkbox"
        [(ngModel)]="dataItem.included"
        (ngModelChange)="refreshAggregates()"
      >
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="meter.facilityName"
    [title]="'FACILITY_NAME' | translate"
    [hidden]="true"
  >
    <ng-template
      kendoGridGroupHeaderTemplate
      let-group
      let-value="value"
    >
      <strong *ngIf="isReadOnly; else editor">
        <strong>{{ value }}</strong>
      </strong>
      <ng-template #editor>
        <gri-metadata-grid-header-checkbox
          [items]="group.items"
          (stateChange)="refreshAggregates()"
        ></gri-metadata-grid-header-checkbox>

        <strong>{{ value }}</strong>

        <ng-container *ngIf="group.items[0].meter.facilityId as facilityId">
          <div
            *ngIf="formControls[facilityId]; else noCo2Factors"
            class="header-editor-container"
          >
            <kendo-dropdownlist
              [formControl]="formControls[facilityId]"
              [data]="(co2factors$ | async)?.[facilityId]"
              [valuePrimitive]="false"
              [textField]="'from'"
              [valueField]="'value'"
              [defaultItem]="{ from: null }"
              [loading]="loading || (loading$ | async)"
              [popupSettings]="{ animate: false }"
            >
              <ng-template
                kendoDropDownListItemTemplate
                kendoDropDownListValueTemplate
                let-dataItem
              >
                <ng-container *ngIf="dataItem.from; else defaultItem">
                  {{ dataItem.from | kendoDate:'d' }}
                  &HorizontalLine;
                  <strong>{{ dataItem.value | kendoNumber: numberFormat }}</strong>
                  {{dataItem.unitScale | griUnitScale}}
                  /
                  {{ (units$ | async)?.default[quantityId] ?? '...' }}
                </ng-container>
                <ng-template #defaultItem>
                  &HorizontalLine;
                  {{ 'SUSTAINABILITY.GRI.SHARED_UNIT' | translate }}
                  &HorizontalLine;
                </ng-template>
              </ng-template>
            </kendo-dropdownlist>
          </div>

          <ng-template #noCo2Factors>
            <strong
              *ngIf="co2factors$ | async"
              class="no-factors"
            >
              {{ 'FACILITY.EDIT_FORM.NO_CO2FACTORS' | translate }}
            </strong>
          </ng-template>
        </ng-container>

      </ng-template>

    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="meter.name"
    [title]="'METER_NAME' | translate"
    [width]="240"
  >
    <ng-template
      *ngIf="gridData?.length"
      kendoGridFooterTemplate
    >
      {{ 'SUSTAINABILITY.GRI.TOTAL' | translate }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngFor="let field of valueFields; let month = index"
    [title]="month | monthName | titlecase"
    [field]="field"
    [format]="numberFormat"
  >
    <ng-template
      kendoGridGroupFooterTemplate
      let-group="group"
    >
      {{ aggregates[group.items?.[0].meter.facilityId][field] | kendoNumber : numberFormat }}
    </ng-template>
    <ng-template
      *ngIf="gridData?.length"
      kendoGridFooterTemplate
    >
      {{ aggregates.total[field] | kendoNumber : numberFormat }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
