export const DONUT_CONFIG = [
  {
    title: 'ACTIONS.GRID.BASIC_INFO',
    columns: [
      { field: 'lifeTime' },
      { field: 'investment' },
      { field: 'paybackTime' }
    ]
  },
  {
    title: 'ACTIONS.GRID.INVESTMENTS_AND_SAVINGS',
    columns: [
      { field: 'electricitySavings' },
      { field: 'heatingSavings' },
      { field: 'fuelSavings' },
      { field: 'waterSavings' }
    ]
  }
];

export const YEARLY_CONFIG = [
  {
    name: 'INVESTMENTS',
    fields: ['investment']
  },
  {
    name: 'SAVINGS',
    fields: ['electricitySavings', 'heatingSavings', 'fuelSavings']
  },
  {
    name: 'WATER_SAVINGS',
    fields: ['waterSavings']
  }
];
