<ng-container *ngIf="editing; then editorTemplate else displayTemplate"></ng-container>

<ng-template #displayTemplate>
  <span>
    <strong>{{ 'ALARM.LOG.STATUS.TITLE' | translate }}:</strong>
    <alarm-log-status-label [status]="logItem.status"></alarm-log-status-label>
    <button
      class="button button--link icon"
      title="{{ 'ADMIN.EDIT' | translate }}"
      (click)="editingChange(true)"
    >
      <i class="fas fa-pencil-alt"></i>
    </button>
  </span>
</ng-template>

<ng-template #editorTemplate>
  <span>
    <alarm-log-status-dropdown
      [(ngModel)]="selectedStatus"
      (ngModelChange)="setStatus($event)"
      [loading]="loading$ | async"
    ></alarm-log-status-dropdown>

    <button
      class="button icon"
      title="{{ 'ADMIN.SAVE' | translate }}"
      [disabled]="(loading$ | async) || (selectedStatus === logItem.status)"
      (click)="saveChanges()"
    >
      <i class="fas fa-check"></i>
    </button>

    <button
      class="button icon"
      title="{{ 'CANCEL' | translate }}"
      [disabled]="loading$ | async"
      (click)="editingChange(false)"
    >
      <i class="fas fa-times"></i>
    </button>
  </span>
</ng-template>
