import { CommonModule, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { LabelModule } from '@progress/kendo-angular-label';
import { IntlModule } from '@progress/kendo-angular-intl';
import { MarkdownModule } from 'ngx-markdown';
import { ButtonsModule } from '@progress/kendo-angular-buttons';

import { NgfModalConfig, NgfModalModule, NgfTooltipModule } from '@enerkey/foundation-angular';
import { EkFormsModule } from '@enerkey/ek-forms';

import { UpgradedProvidersModule } from './ajs-upgraded-providers';

import { EkDropdownModule } from './ek-dropdown/ek-dropdown.module';
import { EkInputsModule } from './ek-inputs/ek-inputs.module';
import { EkKendoModule } from './ek-kendo/ek-kendo.module';

import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { SuffixPipe } from './pipes/suffix/suffix.pipe';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { MeterService } from './services/meter.service';
import { WizardComponent } from './wizard/wizard.component';
import {
  DownloadTopbarItemDirective,
  InlineIndicatorDirective,
} from './ajs-upgraded-components';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CronExecuteNamePipe } from './pipes/cron-execute-name.pipe';
import { CronFilterPipe } from './pipes/cron-filter.pipe';
import { FacilitySelectComponent } from './facility-select/facility-select.component';
import { modalOptions } from './modal-options';
import { TerminalStatusComponent } from './terminal/terminal-status/terminal-status.component';
import { TerminalEditComponent } from './terminal/terminal-edit/terminal-edit.component';
import { TerminalMeterIdsPipe } from './pipes/terminal-meter-ids.pipe';
import { MeterTypeComponent } from './meter-type/meter-type.component';
import { AbbreviatePipe } from './pipes/abbreviate.pipe';
import { TerminalStatusGridComponent } from './terminal/terminal-status-grid/terminal-status-grid.component';
import { FocusOutsideDirective } from './directives/focus-outside.directive';
import { TerminalStatusModalComponent } from './terminal/terminal-status-modal/terminal-status-modal.component';
import { TerminalEditModalComponent } from './terminal/terminal-edit-modal/terminal-edit-modal.component';
import { TerminalCommentModalComponent } from './terminal/terminal-comment-modal/terminal-comment-modal.component';
import { SocialMediaButtonsComponent } from './social-media-buttons/social-media-buttons.component';
import { HelpComponent } from './help/help.component';
import { TerminalAddMeterComponent } from './terminal/terminal-add-meter/terminal-add-meter.component';
import { TerminalRemoveMeterComponent } from './terminal/terminal-remove-meter/terminal-remove-meter.component';
import { HasFacilityAccessPipe } from './pipes/has-facility-access.pipe';
import { FacilityPropertyNamePipe } from './pipes/facility-property-name.pipe';
import { IsoDurationNamePipe } from './pipes/iso-duration-name.pipe';
import { InterpolatePipe } from './pipes/interpolate.pipe';
import { NumberInputModalComponent } from './number-input-modal/number-input-modal.component';
import { FacilitiesStateButtonComponent } from './facilities-state-button/facilities-state-button.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { PageChangerComponent } from './page-changer/page-changer.component';
import { LocaleIdNamePipe } from './pipes/locale-id-name.pipe';
import { KendoGridCustomColumnComponent } from './base-classes/kendo-grid-custom-column.component';
import { FacilityInfoPipe } from './pipes/facility-info.pipe';
import { ValueChangeComponent } from './value-change/value-change.component';
import { ReversePipe } from './pipes/reverse.pipe';
import { ServiceNamePipe } from './pipes/service-name.pipe';
import { NewsModalComponent } from './news-modal/news-modal.component';
import { TopbarNewsComponent } from './topbar-news/topbar-news.component';
import { NewsUnreadComponent } from './news-unread/news-unread.component';
import { LoadingModalComponent } from './loading-modal/loading-modal.component';
import { CommonPipesModule } from './common-pipes/common-pipes.module';
import { EkLoadingModule } from './ek-loading/ek-loading.module';
import { EkModalsModule } from './ek-modals/ek-modals.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DateInputsModule,
    FormsModule,
    DropDownsModule,
    TooltipModule,
    InputsModule,
    UpgradedProvidersModule,
    TranslateModule,
    GridModule,
    ExcelModule,
    NgfTooltipModule,
    TreeViewModule,
    LabelModule,
    IntlModule,
    EkDropdownModule,
    EkFormsModule,
    EkInputsModule,
    EkKendoModule,
    CommonPipesModule,
    EkLoadingModule,
    EkModalsModule,
    ButtonsModule,
    MarkdownModule.forChild()
  ],
  declarations: [
    SiteFooterComponent,
    DownloadTopbarItemDirective,
    ModalFooterComponent,
    WizardComponent,
    ClickOutsideDirective,
    CronExecuteNamePipe,
    CronFilterPipe,
    FacilitySelectComponent,
    TerminalStatusComponent,
    TerminalEditComponent,
    TerminalMeterIdsPipe,
    MeterTypeComponent,
    AbbreviatePipe,
    TerminalStatusGridComponent,
    FocusOutsideDirective,
    TerminalStatusModalComponent,
    TerminalEditModalComponent,
    TerminalCommentModalComponent,
    SocialMediaButtonsComponent,
    HelpComponent,
    TerminalAddMeterComponent,
    TerminalRemoveMeterComponent,
    HasFacilityAccessPipe,
    FacilityPropertyNamePipe,
    IsoDurationNamePipe,
    InterpolatePipe,
    NumberInputModalComponent,
    FacilitiesStateButtonComponent,
    FeedbackModalComponent,
    PageChangerComponent,
    LocaleIdNamePipe,
    KendoGridCustomColumnComponent,
    InlineIndicatorDirective,
    FacilityInfoPipe,
    SuffixPipe,
    ValueChangeComponent,
    ReversePipe,
    ServiceNamePipe,
    NewsModalComponent,
    TopbarNewsComponent,
    NewsUnreadComponent,
    LoadingModalComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    TooltipModule,
    SiteFooterComponent,
    TranslateModule,
    DownloadTopbarItemDirective,
    CommonModule,
    ModalFooterComponent,
    WizardComponent,
    ClickOutsideDirective,
    CronExecuteNamePipe,
    CronFilterPipe,
    FacilitySelectComponent,
    NgfModalModule,
    TerminalStatusComponent,
    TerminalEditComponent,
    TerminalCommentModalComponent,
    MeterTypeComponent,
    AbbreviatePipe,
    TerminalStatusModalComponent,
    TerminalEditModalComponent,
    SocialMediaButtonsComponent,
    HelpComponent,
    TerminalAddMeterComponent,
    TerminalRemoveMeterComponent,
    HasFacilityAccessPipe,
    FacilityPropertyNamePipe,
    IsoDurationNamePipe,
    InterpolatePipe,
    FacilitiesStateButtonComponent,
    FeedbackModalComponent,
    PageChangerComponent,
    LocaleIdNamePipe,
    InlineIndicatorDirective,
    FacilityInfoPipe,
    SuffixPipe,
    ValueChangeComponent,
    ReversePipe,
    ServiceNamePipe,
    TopbarNewsComponent,
    NewsUnreadComponent,
    LoadingModalComponent,
    EkLoadingModule,
    EkModalsModule,
  ],
  providers: [
    MeterService,
    DatePipe,
    DecimalPipe,
    PercentPipe,
    FacilityInfoPipe,
    { provide: NgfModalConfig, useValue: modalOptions },
  ],
})

export class SharedModule { }
