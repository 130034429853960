import { downgradeComponent } from '@angular/upgrade/static';
import { IAngularStatic } from 'angular';

import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'ngtouch';
import ComponentsModule from '../../components';
import EnerKeyServicesModule from '../../services';
import { MeterInterfaceService } from '../../services/meter-interface-service';
import { TerminalStatusComponent } from '../../shared/terminal/terminal-status/terminal-status.component';
import JQueryModule from '../jquery';
import KendoModule from '../kendo';
import LodashModule from '../lodash';
import ManualQaInspectButtonModule from '../manual-qa/components/manual-qa-inspect-button';
import CCMassImportModule from './components/cc-mass-import';
import { TerminalControlComponent } from './components/terminal-control/terminal-control.component';
import config from './config';
import { ConfigurationsController } from './controllers/modal-configurations-controller';
import ConfigurationControlModalService from './services/configuration-control-modal-service';
import { PropertyService } from './services/property-service';
import { ccSpreadsheetService } from './services/spreadsheet-service';
import { configurationControlApi } from './services/configuration-control-api';
import { configurationService } from './services/configuration-service';
import { cronValidation } from '../../shared/alarms-shared/shared/cron-validation';
import { QuantityService } from '../../shared/services/quantity.service';

declare const angular: IAngularStatic;

export default angular.module('ConfigurationControl', [
  EnerKeyServicesModule.name,
  KendoModule.name,
  'kendo.directives',
  'pascalprecht.translate',
  'ngCookies',
  'ui.router',
  LodashModule.name,
  JQueryModule.name,
  ComponentsModule.name,
  ManualQaInspectButtonModule.name,
  CCMassImportModule.name
])
  .directive('terminalStatus', downgradeComponent({ component: TerminalStatusComponent }))
  .directive('terminalControl', downgradeComponent({ component: TerminalControlComponent }))
  .directive('cronValidation', cronValidation)
  .directive('quantityService', downgradeComponent({ component: QuantityService }))
  .config(config)
  .controller('ConfigurationsController', ConfigurationsController)
  .service('ConfigurationControlModalService', ConfigurationControlModalService)
  .service('MeterInterfaceService', MeterInterfaceService)
  .service('PropertyService', PropertyService)
  .factory('ccSpreadsheetService', ccSpreadsheetService)
  .factory('configurationControlApi', configurationControlApi)
  .factory('configurationService', configurationService)
;
