import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {
  public transform<T>(value: T[]): T[] {
    if (!value) {
      return value;
    }
    return [...value].reverse();
  }
}
