import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalService } from '@enerkey/foundation-angular';

import { BookmarksModalComponent } from '../bookmarks-modal/bookmarks-modal.component';

@Component({
  selector: 'bookmarks-topbar-item',
  templateUrl: './bookmarks-topbar-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarksTopbarItemComponent {
  @Input() public isMobile = false;

  public constructor(
    private readonly modalService: ModalService
  ) { }

  public openBookmarkModal(): void {
    this.modalService.open(BookmarksModalComponent);
  }
}
