import { Service } from '../../constants/service';
import { Roles } from './constants/roles';
import { EnerkeyNg2StateDeclaration, RouteAuthLogic } from '../../shared/routing';
import { MeterGroupsComponent } from './components/meter-group-components/meter-groups/meter-groups.component';
import { MeterManagementComponent } from './components/meter-management/meter-management.component';
import { ProfileManagementComponent } from './components/profile-management/profile-management.component';
import { AdminBaseComponent } from './components/admin-base/admin-base.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { FacilityManagementComponent } from './components/facility-management/facility-management.component';

export const adminBaseState: EnerkeyNg2StateDeclaration = {
  parent: 'admin',
  abstract: true,
  name: 'adminBase',
  views: {
    'app@': {
      component: AdminBaseComponent
    }
  }
};

export const userManagementState: EnerkeyNg2StateDeclaration = {
  parent: 'adminBase',
  name: 'users',
  params: {
    queryParams: null,
  },
  url: '/users/',
  views: {
    'admin-content@adminBase': {
      component: UserManagementComponent
    }
  },
  data: {
    auth: {
      roles: [Roles.USER_ADMINSTRATOR],
    },
    translationKey: 'ADMIN.USERS'
  },
};

export const profileManagementState: EnerkeyNg2StateDeclaration = {
  parent: 'adminBase',
  name: 'profile',
  params: {
    queryParams: null
  },
  url: '/profile/',
  views: {
    'admin-content@adminBase': {
      component: ProfileManagementComponent
    }
  },
  data: {
    auth: {
      roles: [Roles.USER_ADMINSTRATOR, Roles.METER_MANAGER_USER],
      roleLogic: RouteAuthLogic.Any,
    },
    translationKey: 'ADMIN.PROFILES',
    export: {
      enabled: true
    }
  }
};

export const facilityManagementState: EnerkeyNg2StateDeclaration = {
  parent: 'adminBase',
  name: 'facility',
  url: '/facility/',
  views: {
    'admin-content@adminBase': {
      component: FacilityManagementComponent
    }
  },
  data: {
    auth: {
      roles: [Roles.METER_MANAGER_USER],
      roleLogic: RouteAuthLogic.Any,
    },
    translationKey: 'ADMIN.FACILITIES'
  }
};

export const meterManagementState: EnerkeyNg2StateDeclaration = {
  parent: 'adminBase',
  name: 'meter',
  url: '/meter',
  params: {
    facilityIds: { array: true },
  },
  data: {
    translationKey: 'ADMIN.METERS_MASS_TOOLS',
    auth: {
      roles: [Roles.METER_MANAGER_USER]
    }
  },
  views: {
    'admin-content@adminBase': {
      component: MeterManagementComponent
    }
  }
};

export const meterGroupsManagementState: EnerkeyNg2StateDeclaration = {
  parent: 'adminBase',
  name: 'meterGroups',
  url: '/metergroups',
  views: {
    'admin-content@adminBase': {
      component: MeterGroupsComponent
    }
  },
  data: {
    translationKey: 'ADMIN.TABS.METER_GROUPS',
    auth: {
      roles: [Roles.FACILITY_UPDATE],
      services: [Service.MeterGroups]
    }
  }
};
