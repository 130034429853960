import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SeriesLabelsVisualArgs } from '@progress/kendo-angular-charts';
import { Element, geometry, Group, Text } from '@progress/kendo-drawing';
import { IntlService } from '@progress/kendo-angular-intl';

import { QuantityService } from '../../../../shared/services/quantity.service';
import { EmissionAggregates } from '../../shared/emissions';

const { Point } = geometry;

@Component({
  selector: 'emissions-widget-pie-chart',
  templateUrl: './emissions-widget-pie-chart.component.html',
  styleUrls: ['./emissions-widget-pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmissionsWidgetPieChartComponent {
  @Input() public data: EmissionAggregates;

  public constructor(
    private readonly intl: IntlService,
    private readonly quantityService: QuantityService
  ) {
    this.contentVisual = this.contentVisual.bind(this);
  }

  public contentVisual(args: SeriesLabelsVisualArgs): Element {
    const icon = this.quantityService.getKendoChartQuantityIcon(
      args.dataItem.quantityId,
      args.dataItem.color,
      args.rect.origin
    );
    const group = new Group();
    const text = new Text(
      this.intl.formatNumber(args.value, '#,#'),
      new Point(
        args.rect.origin.getX() + icon.bbox().getSize().getWidth() + 5,
        args.rect.origin.getY()
      ),
      {
        font: '13px "Open Sans"'
      }
    );
    group.append(icon, text);

    return group;
  }
}
