import { getNumericEnumValues } from '@enerkey/ts-utils';
import { Ownership } from '@enerkey/clients/facility';

import { ownershipTranslations } from '../../../constants/facility.constant';

type OwnershipSelectItem = { id: Ownership; text: string };

export function getOwnershipSelectItems(): OwnershipSelectItem[] {
  return getNumericEnumValues(Ownership).map(value => ({
    id: value,
    text: ownershipTranslations[value]
  }));
}
