<facility-report-header
  *ngIf="facility$ | async as facility"
  [events]="(notes$ | async)?.plain"
  [facility]="facility"
></facility-report-header>
<div class="row">
  <div class="small-12 medium-7 columns">
    <div class="facility-overview-buttons">
      <input
        *labelWrap="latestMonthName + ' ' + currentYear"
        type="radio"
        kendoRadioButton
        name="timeFrame"
        [formControl]="timeFrameControl"
        [value]="monthKey"
      >
      <input
        *labelWrap="yearText"
        type="radio"
        kendoRadioButton
        name="timeFrame"
        [formControl]="timeFrameControl"
        [value]="yearKey"
      >
      <button
        class="button button--circle no-print"
        (click)="expandAll()"
        title="{{ 'FACILITIES_REPORT.OVERVIEW.EXPAND_ALL' | translate }}"
      >
        <i class="fas fa-expand-alt"></i>
      </button>
      <button
        class="button button--circle no-print"
        (click)="collapseAll()"
        title="{{ 'FACILITIES_REPORT.OVERVIEW.COLLAPSE_ALL' | translate }}"
      >
        <i class="fas fa-compress-alt"></i>
      </button>
    </div>
    <div #gridContainer>
      <gridster [options]="gridOptions">
        <gridster-item
          *ngFor="let quantityData of quantityData$ | async"
          [item]="quantityData"
          #gridsterItem
        >
          <div class="overview-quantity-card card">
            <div
              class="header-group"
              [ngClass]="'bg-enerkey-' + (quantityData.quantityId | quantityKey | async)"
            >
              <div class="header-icon header-icon--bg-round header-icon--bg-white">
                <quantity-icon [quantity]="quantityData.quantityId"></quantity-icon>
              </div>
              <h3 class="header-group__label h-secondary">{{ quantityData.quantityId | quantityName | async }}</h3>
              <button
                class="button button--secondary icon card-expand-button no-print"
                (click)="changeCardSize(gridsterItem)"
              >
                <i
                  class="fa"
                  [ngClass]="(quantityData.expanded$ | async) ? 'fa-compress-alt' : 'fa-expand-alt'"
                ></i>
              </button>
            </div>
            <overview-card-content
              [timeRange]="timeFrameControl.value"
              [item]="quantityData"
              [expanded]="quantityData.expanded$ | async"
            ></overview-card-content>
            <reporting-chart
              *ngIf="quantityData.expanded$ | async"
              [series]="quantityData.series"
              [quantityId]="quantityData.quantityId"
              [labelSettings]="quantityData.series | chartCategories:(params$ | async) | async"
              (seriesClick)="moveToReport($event)"
            ></reporting-chart>
          </div>
        </gridster-item>
      </gridster>
    </div>
  </div>
  <div
    class="small-12 medium-5 columns no-print"
    *ngIf="facility$ | async as facility"
  >
    <section *ngIf="(notes$ | async)?.plain as actionsAndComments">
      <h2>{{ 'FACILITIES_REPORT.OVERVIEW.ACTIONS_TITLE' | translate }}</h2>
      <overview-report-actions
        *ngIf="actionsAndComments.actions | slice:0:5 as actions"
        [actions]="actions"
        [facilityId]="facility.FacilityId"
        [defaultActionQuantity]="actionDefaultQuantity$ | async"
      ></overview-report-actions>
      <overview-report-comments
        *ngIf="actionsAndComments.comments | slice:0:5 as comments"
        [actions]="comments"
        [facilityId]="facility.FacilityId"
        [defaultActionQuantity]="actionDefaultQuantity$ | async"
      ></overview-report-comments>
    </section>
    <section>
      <h2>{{ 'FACILITIES_REPORT.OVERVIEW.FACILITY_INFORMATION' | translate }}</h2>
      <overview-report-facility-info
        [facility]="facility"
      ></overview-report-facility-info>
    </section>
  </div>
</div>
