const $inject = ['ManualQaFetchTypeService'];

class ManualQaFetchTypeController {
  constructor(ManualQaFetchTypeService) {
    this.ManualQaFetchTypeService = ManualQaFetchTypeService;
  }

  $onInit() {
    this.items = this.ManualQaFetchTypeService.getItems();
  }
}

ManualQaFetchTypeController.$inject = $inject;

export default ManualQaFetchTypeController;
