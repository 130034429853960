import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import {
  Observable,
  Subject,
} from 'rxjs';

import {
  AlarmClient,
  ILogDto,
  LogStatus,
} from '@enerkey/clients/alarm';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { indicate } from '@enerkey/rxjs';
import { ALARMS_LOG_GRID_REFRESH } from '../../../../modules/alarms-log/shared/alarms-log-grid-refresh';

@Component({
  selector: 'alarm-log-entry-status',
  templateUrl: './alarm-log-entry-status.component.html',
  styleUrls: ['./alarm-log-entry-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmLogEntryStatusComponent implements OnInit, OnDestroy {
  @Input() public logItem: ILogDto;

  public editing: boolean = false;
  public selectedStatus: LogStatus = null;

  public readonly loading$: Observable<boolean>;

  private readonly _loading = new Subject<boolean>();

  public constructor(
    private readonly alarmClient: AlarmClient,
    private readonly toaster: ToasterService,
    @Optional() @Inject(ALARMS_LOG_GRID_REFRESH) private readonly _refresh: Subject<void>
  ) {
    this.loading$ = this._loading.asObservable();
  }

  public ngOnInit(): void {
    if (!this.logItem) {
      throw Error('Alarm log item not found');
    }

    this.selectedStatus = this.logItem.status;
  }

  public ngOnDestroy(): void {
    this._loading.complete();
  }

  public setStatus(value: LogStatus): void {
    this.selectedStatus = value;
  }

  public saveChanges(): void {
    this.alarmClient.updateLogsStatus(this.selectedStatus, [this.logItem.id]).pipe(
      indicate(this._loading)
    ).subscribe({
      next: items => {
        this.toaster.success('ALARM.LOG.STATUS.CHANGE_SUCCESS');
        this.logItem = items[0];
        this.editingChange(false);
        this._refresh?.next();
      },
      error: () => {
        this.toaster.error('ALARM.LOG.STATUS.CHANGE_ERROR');
        this.editingChange(false);
      },
    });
  }

  public editingChange(editing: boolean): void {
    this.editing = editing;

    if (!editing) {
      this.selectedStatus = this.logItem.status;
    }
  }

}
