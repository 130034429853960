import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ActionSlimViewModel } from '@enerkey/clients/attachments';

import { TopChangedWidgetRow } from '../../shared/top-changed-consumptions';
import { ChangeWidgetData } from '../change-widget-base';
import { WidgetChangeOption } from '../../../energy-reporting/shared/widget-constants';

@Component({
  selector: 'top-changed-consumptions-table',
  templateUrl: './top-changed-consumptions-table.component.html',
  styleUrls: ['../change-component-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopChangedConsumptionsTableComponent {
  @Input() public data: ChangeWidgetData<TopChangedWidgetRow>;
  @Input() public comparisonPeriodTitle: string;
  @Input() public inspectionPeriodTitle: string;
  @Input() public format: string;
  @Input() public actions: Map<number, ActionSlimViewModel[]>;
  @Input() public changeType: WidgetChangeOption;

  @Output() public readonly rowClick = new EventEmitter<number>();

  public readonly WidgetChangeOption: typeof WidgetChangeOption = WidgetChangeOption;

  public get dataArray(): TopChangedWidgetRow[][] {
    return [this.data.gainers, this.data.fallers];
  }
}
