import { Pipe, PipeTransform } from '@angular/core';

import { KeysOfType } from '@enerkey/ts-utils';

import { YearReadings } from '../components/yearly-trend-report/yearly-trend-report.component';
import { ConsumptionValuePipe } from './consumption-value.pipe';
import { ConsumptionPercentPipe } from './consumption-percent.pipe';

/**
 * Fields that should use percentage pipe when transforming shown value
 */
const percentageFields: readonly KeysOfType<YearReadings, number>[] = ['diffPercentage'];

@Pipe({
  name: 'yearlyTrendReportTableCellValue'
})
export class YearlyTrendReportTableCellValuePipe implements PipeTransform {

  public constructor(
    private readonly consumptionValuePipe: ConsumptionValuePipe,
    private readonly consumptionPercentPipe: ConsumptionPercentPipe
  ) {
  }

  public transform(value: number, field: KeysOfType<YearReadings, number>): string {
    return percentageFields.includes(field)
      ? this.consumptionPercentPipe.transform(value, 1)
      : this.consumptionValuePipe.transform(value, 2)
    ;
  }
}
