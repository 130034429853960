import { Optional, Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of, take } from 'rxjs';

import { RequestResolution } from '@enerkey/clients/reporting';

import { SelectableResolution } from '../../../modules/reporting/components/reporting-search-form/reporting-search-form.component';
import { ReportingSearchService } from '../../../modules/reporting/services/reporting-search.service';
import { ReportingSearchParams } from '../../../modules/reporting/shared/reporting-search-params';
import { ReportingSeries } from '../../../modules/reporting/shared/reporting-series';

export interface ReportingChartLabelSettings {
  /** Defines which labels and shown in grid, eg. if step is 6 label is shown for every sixth chart item */
  step: number;
  resolution: SelectableResolution;
  amountOfPeriods: number;
  specialDayLabelFormat: boolean;
}

@Pipe({
  name: 'chartCategories'
})
export class ChartCategoriesPipe implements PipeTransform {

  public constructor(
    @Optional() private readonly reportingSearchService: ReportingSearchService
  ) {
  }

  public transform(
    series: ReportingSeries[],
    customParams?: ReportingSearchParams
  ): Observable<ReportingChartLabelSettings> {
    const params$ = customParams
      ? of(customParams)
      : this.reportingSearchService.searchParameters$
      ;
    return params$.pipe(
      take(1),
      map(params => {
        const valuesAmount = Math.max(...series.map(s => s.values.length));
        // For hour and day resolutions and period that is shorter than one months days (1, 2, ...) are shown as labels
        const specialDayLabelFormat = (params.resolution === RequestResolution.P1D && valuesAmount <= 31)
          || (params.resolution === RequestResolution.Pt1H && valuesAmount <= 31 * 24)
          || (params.resolution === RequestResolution.Pt15M && valuesAmount > 4 * 24);
        const periodsAmount = series
          .filter(s => s.isShownInChart)
          .uniqueBy(s => s.serieStart).length;
        const step = this.getStep(valuesAmount, params.resolution, specialDayLabelFormat);
        return {
          step,
          specialDayLabelFormat,
          resolution: params.resolution,
          amountOfPeriods: periodsAmount,
        };
      })
    );
  }

  private getStep(
    valuesAmount: number,
    resolution: SelectableResolution,
    specialDayLabelFormat: boolean
  ): number {
    let step = 1;
    if (resolution === RequestResolution.P1Y) {
      while (valuesAmount / step > 12) {
        step = step + 5;
      }
    } else if (resolution === RequestResolution.P1M || resolution === RequestResolution.P3M) {
      while (valuesAmount / step > 12) {
        step = step + 6;
      }
    } else if (resolution === RequestResolution.P7D) {
      while (valuesAmount / step > 8) {
        step = step + 6;
      }
    } else if (resolution === RequestResolution.P1D) {
      if (specialDayLabelFormat) {
        return 1;
      } else {
        while (valuesAmount / step > 8) {
          step = step + 7;
        }
      }
    } else if (resolution === RequestResolution.Pt1H) {
      if (specialDayLabelFormat) {
        return 24;
      }
      while (valuesAmount / step > 6) {
        step = step + 168;
      }
    } else if (resolution === RequestResolution.Pt15M) {
      return specialDayLabelFormat ? 96 : 12;
    }

    return Math.max(step - 1, 1);
  }
}
