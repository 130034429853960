import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { BulkSendWelcomeMailRequest, UserManagementClient } from '@enerkey/clients/user-management';
import { ModalService } from '@enerkey/foundation-angular';

import { DialogService } from '../../../../shared/services/dialog.service';
import { TemplateLifterService } from '../../../../shared/services/template-lifter.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { UserManagementService } from '../../services/user-management.service';
import { UserManagementGridComponent } from '../user-management-grid/user-management-grid.component';
import { UserMassCreateModalComponent } from '../user-mass-create-modal/user-mass-create-modal.component';
import { UserMassEditModalComponent } from '../user-mass-edit-modal/user-mass-edit-modal.component';
import { UserMassEmailModalComponent } from '../user-mass-email-modal/user-mass-email-modal.component';
import { UserOperationModalComponent } from '../user-operation-modal/user-operation-modal.component';
import { UserProfileMassEditModalComponent } from '../user-profile-mass-edit-modal/user-profile-mass-edit-modal.component';
import { UserRoleEditModalComponent } from '../user-role-edit-modal/user-role-edit-modal.component';
import { UserSettingsCopyModalComponent } from '../user-settings-copy-modal/user-settings-copy-modal.component';

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserManagementService]
})
export class UserManagementComponent implements AfterViewInit, OnDestroy {
  @ViewChild(UserManagementGridComponent) public gridComponent: UserManagementGridComponent;
  @ViewChild('topbarTemplate') private readonly topRightTemplate: TemplateRef<unknown>;

  public constructor(
    private readonly templateLifter: TemplateLifterService,
    private readonly modalService: ModalService,
    private readonly userManagementClient: UserManagementClient,
    private readonly toasterService: ToasterService,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly userManagementService: UserManagementService
  ) { }

  public ngAfterViewInit(): void {
    this.templateLifter.template = this.topRightTemplate;
  }

  public ngOnDestroy(): void {
    this.templateLifter.template = null;
  }

  public createUser(): void {
    this.modalService.open(UserOperationModalComponent);
  }

  public createMultipleUsers(): void {
    this.modalService.open(UserMassCreateModalComponent);
  }

  public sendNewWelcomeEmail(): void {
    this.dialogService.getConfirmationModalPromise({
      text: 'ADMIN.BULK_SEND_WELCOMEMAIL_CONFIRMATION',
      title: this.translateService.instant('ADMIN.BULK_SEND_WELCOMEMAIL'),
      isDelete: false
    }).then(() => {
      this.userManagementClient.bulkSendWelcomeMail(
        new BulkSendWelcomeMailRequest({
          ids: this.gridComponent.selectedUsers
        })
      ).subscribe({
        next: () => this.toasterService.success('ADMIN.BULK_SEND_WELCOMEMAIL_SUCCESS'),
        error: () => this.toasterService.error('ADMIN.BULK_SEND_WELCOMEMAIL_ERROR')
      });
    }).catch(() => { });
  }

  public editRoles(): void {
    const modal = this.modalService.open(UserRoleEditModalComponent);
    modal.componentInstance.selectedUsers = this.gridComponent.selectedUsers;
    modal.result
      .then(() => this.userManagementService.refresh())
      .catch(() => { });
  }

  public editProfiles(): void {
    const modal = this.modalService.open(UserProfileMassEditModalComponent);
    modal.componentInstance.selectedUsers = this.gridComponent.selectedUsers;
    modal.result
      .then(() => this.userManagementService.refresh())
      .catch(() => { });
  }

  public copyUserSettings(): void {
    const modal = this.modalService.open(UserSettingsCopyModalComponent);
    modal.componentInstance.selectedUsers = this.gridComponent.selectedUsers;
  }

  public sendBulkEmail(): void {
    this.gridComponent.users$.subscribe(users => {
      const emails = users.filterMap(
        u => this.gridComponent.selectedUsers.includes(u.id) && u.email !== null,
        u => u.email
      );
      if (emails.length) {
        const modal = this.modalService.open(UserMassEmailModalComponent);
        modal.componentInstance.emails = emails;
      } else {
        this.toasterService.info('ADMIN.BULK_SEND_EMAIL.NO_EMAILS');
      }
    });
  }

  public massEditUsers(): void {
    const modal = this.modalService.open(UserMassEditModalComponent);
    modal.componentInstance.selectedUsers = this.gridComponent.selectedUsers;
    modal.result
      .then(() => this.userManagementService.refresh())
      .catch(() => { });
  }

  public addUsersToTenant(): void {
    this.userManagementClient.addUsersToTenant(
      this.gridComponent.selectedUsers
    ).subscribe({
      next: () => this.toasterService.success('ADMIN.ADD_USERS_TO_TENANT.SAVE_SUCCESS'),
      error: () => this.toasterService.error('ADMIN.ADD_USERS_TO_TENANT.SAVE_ERROR')
    });
  }

  public removeUsersFromTenant(): void {
    this.userManagementClient.removeUsersFromTenant(
      this.gridComponent.selectedUsers
    ).subscribe({
      next: () => this.toasterService.success('ADMIN.REMOVE_USERS_FROM_TENANT.SAVE_SUCCESS'),
      error: () => this.toasterService.error('ADMIN.REMOVE_USERS_FROM_TENANT.SAVE_ERROR')
    });
  }
}
