import { Injectable, OnDestroy } from '@angular/core';
import moment from 'moment';
import { forkJoin, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

import { ConsumptionsRequestQuantity, EnergyReportingClient, QuantityItem } from '@enerkey/clients/energy-reporting';

import { QuantityService } from '../../../shared/services/quantity.service';
import { getValueTypeOptions } from '../../energy-reporting/shared/value-type-options';
import { RelationalValueId } from '../../reportingobjects/constants/facilities-properties';
import { ErTimeFrameService, TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { ColorService } from '../../../shared/services/color.service';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { ConsumptionValueKey } from '../../../shared/energy-reporting-shared/shared/consumptions';
import { TimeFrameOptions } from '../../../constants/time-frame';
import { Emissions, EmissionsWidgetData } from '../shared/emissions';
import { WidgetQueueService } from './widget-queue.service';

export enum EmissionFactorType {
  Facility = 'Facility',
  Location = 'Location'
}

export interface EmissionsWidgetOptions {
  valueOption: ValueType;
  comparableOption: Comparability;
  factorOption: EmissionFactorType;
}

const relationalValueIds: Record<EmissionFactorType, RelationalValueId> = {
  [EmissionFactorType.Facility]: RelationalValueId.co2Factor,
  [EmissionFactorType.Location]: RelationalValueId.co2NationalReference,
};

@Injectable()
export class EmissionsWidgetService implements OnDestroy {

  public readonly emissionTitle$: Observable<string>;
  public readonly relationalValueId$: Observable<RelationalValueId>;
  public readonly years$: Observable<{ current: string; previous: string; first: string }>;
  public readonly isNormalized$: Observable<boolean>;

  private readonly start$: Observable<TimeFrameResult>;
  private readonly dataModelOptions$ = new ReplaySubject<EmissionsWidgetOptions>(1);

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly erClient: EnergyReportingClient,
    private readonly timeFrameService: ErTimeFrameService,
    private readonly colorService: ColorService,
    private readonly widgetQueue: WidgetQueueService
  ) {
    this.start$ = this.dataModelOptions$.pipe(
      map(options => {
        const defaultTimeFrame = this.timeFrameService.getTimeFrameAndResParams(
          TimeFrameOptions.YEAR_BY_YEAR_CALENDAR, 'None'
        ).Start[0].value;
        const comparisonPeriodsToGet = options.comparableOption === Comparability.All
          ? 2
          : 1
        ;

        return this.timeFrameService.getStartFromResolution(
          'P1Y',
          moment(defaultTimeFrame).subtract(comparisonPeriodsToGet, 'years'),
          moment(defaultTimeFrame).add(1, 'years')
        );
      })
    );

    this.years$ = this.start$.pipe(
      map(start => ({
        current: start.Start[start.Start.length - 1].key,
        previous: start.Start[start.Start.length - 2].key,
        first: start.Start[0].key,
      }))
    );

    this.relationalValueId$ = this.dataModelOptions$.pipe(
      map(options => relationalValueIds[options.factorOption]),
      shareReplay(1)
    );

    this.emissionTitle$ = this.relationalValueId$.pipe(
      map(
        relationalValueId => relationalValueId === RelationalValueId.co2Factor
          ? 'EMISSIONS_WIDGET.FACTOR.FACILITY'
          : 'EMISSIONS_WIDGET.FACTOR.LOCATION'
      )
    );

    this.isNormalized$ = this.dataModelOptions$.pipe(
      map(options => getValueTypeOptions(options.valueOption).normalized)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.dataModelOptions$.complete();
  }

  public setDataModelOptions(options: EmissionsWidgetOptions): void {
    this.dataModelOptions$.next(options);
  }

  public getEmissionsData(facilityIds: number[], threshold: number): Observable<EmissionsWidgetData> {
    return forkJoin([
      from(this.quantityService.getSignificantQuantitiesForProfile()).pipe(
        map(quantities => quantities.filter(q => q.Emission && !Array.hasItems(q.SumOf)))
      ),
      this.dataModelOptions$.pipe(take(1)),
      this.start$.pipe(take(1))
    ]).pipe(
      switchMap(([quantities, options, start]) => this.getEmissionData(
        quantities, facilityIds, start, options, threshold
      ))
    );
  }

  private getEmissionData(
    quantities: QuantityItem[],
    facilityIds: number[],
    start: TimeFrameResult,
    options: EmissionsWidgetOptions,
    threshold: number
  ): Observable<EmissionsWidgetData> {
    const valueOptions = getValueTypeOptions(options.valueOption);

    const relationalValue = relationalValueIds[options.factorOption];

    const quantityParams: ConsumptionsRequestQuantity[] = quantities.map(quantity => ({
      Id: quantity.ID,
      Normalisation: valueOptions.normalized && quantity.Normalization,
      Comparables: options.comparableOption,
      RelationalUnitIds: [relationalValue]
    }));

    const valueKey: ConsumptionValueKey = valueOptions.normalized
      ? 'NormalizedReading'
      : 'Reading'
    ;

    const quantityColors = quantities.toMap(
      quantity => quantity.ID,
      quantity => this.colorService.getPrimaryGraphColors(quantity.ID, start.Start.length)
    );

    return this.erClient.postEmissionsForTimerange({
      FacilityId: facilityIds,
      Quantities: quantityParams,
      ThresholdForIncomplete: threshold,
      /* eslint-disable @typescript-eslint/no-explicit-any */
      Resolution: 'P1M' as any,
      TimeFrame: 'P1Y',
      AggregateType: 'sum' as any,
      Start: start.Start as any
      /* eslint-enable @typescript-eslint/no-explicit-any */
    }).pipe(
      this.widgetQueue.queue('energyreporting', this._destroy$),
      map(response => Emissions.getWidgetData(
        response,
        quantities.map(q => q.ID),
        quantityColors,
        valueKey,
        start,
        relationalValue
      ))
    );
  }
}
