import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { MeterGroupDto } from '@enerkey/clients/metering';
import { ControlsOf, formControlsFrom } from '@enerkey/ts-utils';

import { MeterGroup } from '../../../models/meter-groups.model';

@Component({
  selector: 'meter-groups-sidebar',
  templateUrl: './meter-groups-sidebar.component.html',
  styleUrls: ['./meter-groups-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
export class MeterGroupsSidebarComponent implements OnChanges, OnDestroy {
  @Input() public editMode: boolean = false;
  @Output() public readonly meterGroupChange = new EventEmitter<MeterGroupDto>();
  @Output() public readonly facilityChange = new EventEmitter<number>();
  @Output() public readonly searchClick = new EventEmitter<void>();

  public formGroup: FormGroup;
  public controls: ControlsOf<{ facilityId: number, meterGroupId: number, meterGroupDescription: string }>;

  private readonly _destroy$ = new Subject<void>();

  public constructor() {

    this.controls = formControlsFrom(
      {
        facilityId: undefined,
        meterGroupId: undefined,
        meterGroupDescription: undefined
      }
    );

    this.formGroup = new FormGroup(this.controls);
    this.controls.meterGroupDescription.disable();

    this.controls.facilityId.valueChanges.pipe(
      tap(facilityId => this.facilityChange.emit(facilityId)),
      takeUntil(this._destroy$)
    ).subscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.editMode) { return; }
    if (changes.editMode.currentValue === true) {
      this.controls.facilityId.disable();
      this.controls.meterGroupId.disable();
    } else {
      this.controls.facilityId.enable();
      this.controls.meterGroupId.enable();
    }
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onMeterGroupChange(meterGroup: MeterGroup): void {
    this.controls.meterGroupDescription.setValue(meterGroup?.description ?? '');
    this.meterGroupChange.emit(meterGroup ? MeterGroupDto.fromJS(meterGroup) : null);
  }
}
