import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { LoadingSubject } from '@enerkey/rxjs';

import { MeteringClient, MeterUpdateDto } from '@enerkey/clients/metering';

import Meter from '../../../manual-qa/shared/meter';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'enerkey-meter-edit-modal',
  templateUrl: './meter-edit-modal.component.html',
  styleUrls: ['./meter-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({ size: 'tiny' })
export class MeterEditModalComponent extends ModalBase<Partial<Meter>> implements OnInit, OnDestroy {
  @Input() public selectedMeter: Partial<Meter>;

  public readonly loading$: Observable<boolean>;
  public readonly meterEditForm: FormGroup;
  private readonly _loading$ = new LoadingSubject();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly meterClient: MeteringClient,
    private readonly toasterService: ToasterService
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();
    this.meterEditForm = new FormGroup({
      meterNameField: new FormControl('', [Validators.required])
    });
  }

  public ngOnInit(): void {
    const meterNameField = this.meterEditForm.controls.meterNameField;
    meterNameField.setValue(this.selectedMeter.name);
  }

  public ngOnDestroy(): void {
    this._loading$.complete();
  }

  public submit(): void {
    if (this.meterEditForm.valid) {
      this.selectedMeter.name = this.meterEditForm.value.meterNameField;
      const meterUpdate = new MeterUpdateDto({
        id: this.selectedMeter.id,
        name: this.selectedMeter.name
      });

      this.meterClient.update(meterUpdate).subscribe({
        complete: () => {
          this.toasterService.success('METER.EDIT.SUCCESS');
          super.closeModal(this.selectedMeter);
        },
        error: () => {
          this.toasterService.error('METER.EDIT.ERROR');
        }
      });
    }
  }

  public dismiss(): void {
    super.dismissModal();
  }
}
