<modal-view-header [headingText]="title"></modal-view-header>

<div class="modal-body root-container">
  <div class="left-container">
    <div>
      <p>
        <strong>{{ 'SUSTAINABILITY.TARGETS.SCENARIO' | translate }}</strong>
        {{ roadmap.scenario | targetScenarioName }}
      </p>
      <p>
        <strong>{{ 'SUSTAINABILITY.TARGETS.BASE_VALUE' | translate }}</strong>
        {{ roadmap.baseTotal | kendoNumber : targetValueFormat }}
      </p>
      <p>
        <strong>{{ 'SUSTAINABILITY.TARGETS.TARGET' | translate }}</strong>
        {{ roadmap.targetTotal | kendoNumber : targetValueFormat }}
      </p>
    </div>

    <div class="legend">
      <div
        *ngFor="let scope of scopes"
        class="legend-item"
      >
        <div [class]="['rectangle', scope]"></div>
        {{ scope | griScopeName : true }}
      </div>
      <div class="legend-item">
        <div class="circle"></div>
        {{ 'SUSTAINABILITY.TARGETS.CURRENT' | translate }}
      </div>
    </div>

    <div class="progress-selection">
      <target-report-select
        [multiSelect]="true"
        [yearRange]="{ min: roadmap.baseYear, max: roadmap.targetYear }"
        (multiReportSelectionChange)="showProgress($event)"
      ></target-report-select>
    </div>
  </div>

  <div class="right-container">
    <kendo-pdf-export>

      <kendo-chart>
        <kendo-chart-title [text]="title"></kendo-chart-title>
        <kendo-chart-tooltip>
          <ng-template
            kendoChartSeriesTooltipTemplate
            let-value="value"
            let-dataItem="dataItem"
          >
            <strong>{{ dataItem.description }}</strong><br>
            {{ dataItem.scope | griScopeName }}: {{ value.y | kendoNumber:targetValueFormat }}
          </ng-template>
        </kendo-chart-tooltip>
        <kendo-chart-series>
          <kendo-chart-series-item
            *ngFor="let series of roadmapLine"
            type="scatterLine"
            [data]="series.data"
            xField="year"
            yField="value"
            [color]="series.color"
            [markers]="{ visible: false }"
            [width]="2"
          ></kendo-chart-series-item>
          <kendo-chart-series-item
            *ngFor="let point of currentPoints"
            type="scatter"
            [data]="point.data"
            xField="year"
            yField="value"
            [markers]="point.markers"
          ></kendo-chart-series-item>
          <kendo-chart-series-item
            *ngFor="let point of progressPoints"
            type="scatter"
            [data]="point.data"
            xField="year"
            yField="value"
            [markers]="point.markers"
          ></kendo-chart-series-item>
        </kendo-chart-series>
        <kendo-chart-x-axis>
          <kendo-chart-x-axis-item
            type="date"
            baseUnit="years"
            [min]="chartMin"
            [max]="chartMax"
            [labels]="{ skip: 1 }"
          >
          </kendo-chart-x-axis-item>
        </kendo-chart-x-axis>
        <kendo-chart-y-axis>
          <kendo-chart-y-axis-item [labels]="{ format: targetValueFormat }">
          </kendo-chart-y-axis-item>
        </kendo-chart-y-axis>
      </kendo-chart>

      <table>
        <thead>
          <th>{{ 'SUSTAINABILITY.GRI.SCOPE' | translate }}</th>
          <th>
            {{ 'SUSTAINABILITY.TARGETS.BASE_VALUE' | translate }} ({{ roadmap.baseYear }})</th>
          <th *ngFor="let report of progressData">
            <div class="report-header">
              <span class="report-name">{{ report.name }}</span>
              <span>&nbsp;({{ report.year }})</span>
            </div>
          </th>
          <th>
            {{ 'SUSTAINABILITY.TARGETS.CURRENT' | translate }}
            <span *ngIf="roadmap.currentYear !== null">({{ roadmap.currentYear }})</span>
          </th>
          <th>
            {{ 'SUSTAINABILITY.TARGETS.TARGET' | translate }} ({{ roadmap.targetYear }})
          </th>
        </thead>

        <tbody>
          <tr *ngFor="let scope of scopes">
            <td [class]="['scope-cell', scope]">{{ scope | griScopeName:true }}</td>
            <td>{{ roadmap.baseValues[scope] | kendoNumber:targetValueFormat }}</td>
            <td *ngFor="let report of progressData">
              {{ report.values[scope] | kendoNumber:targetValueFormat }}
              <span
                *ngIf="report.reductions[scope] !== null"
                [ngClass]="{ 'percentage-negative' : report.reductions[scope] > 0 }"
              >({{ report.reductions[scope] | kendoNumber:percentageFormat }})</span>
            </td>
            <td>
              {{ roadmap.currentValues[scope] | kendoNumber:targetValueFormat }}
              <span
                *ngIf="reductions.current[scope] !== null"
                [ngClass]="{ 'percentage-negative' : reductions.current[scope] > 0 }"
              >({{ reductions.current[scope] | kendoNumber:percentageFormat }})</span>
            </td>
            <td>
              {{ roadmap.targetValues[scope] | kendoNumber:targetValueFormat }}
              <span
                *ngIf="reductions.target[scope] !== null"
                [ngClass]="{ 'percentage-negative' : reductions.target[scope] > 0 }"
              >({{ reductions.target[scope] | kendoNumber:percentageFormat }})</span>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <td>{{ 'SUSTAINABILITY.GRI.TOTAL' | translate }}</td>
          <td>{{ roadmap.baseTotal | kendoNumber:targetValueFormat }}</td>
          <td *ngFor="let report of progressData">
            {{ report.totalValue | kendoNumber:targetValueFormat }}
            <span
              *ngIf="report.totalReduction !== null"
              [ngClass]="{ 'percentage-negative' : report.totalReduction > 0 }"
            >({{ report.totalReduction | kendoNumber:percentageFormat }})</span>
          </td>
          <td>
            {{ roadmap.currentTotal | kendoNumber:targetValueFormat }}
            <span
              *ngIf="roadmap.currentPerCent !== null"
              [ngClass]="{ 'percentage-negative' : roadmap.currentPerCent > 0 }"
            >
              ({{ roadmap.currentPerCent | kendoNumber:percentageFormat }})
            </span>
          </td>
          <td>
            {{ roadmap.targetTotal | kendoNumber:targetValueFormat }}
            <span
              *ngIf="roadmap.targetPerCent !== null"
              [ngClass]="{ 'percentage-negative' : roadmap.targetPerCent > 0}"
            >
              ({{ roadmap.targetPerCent | kendoNumber:percentageFormat }})
            </span>
          </td>
        </tfoot>
      </table>

    </kendo-pdf-export>
  </div>
</div>

<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--primary"
      (click)="downloadPdf()"
    >
      <i class="far fa-arrow-alt-circle-down"></i>
      {{ 'DOWNLOAD.DOWNLOAD_AS_PDF' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'MODALS.CLOSE' | translate }}
    </button>
  </div>
</div>
