<modal-view-header
  [headingText]="'ADMIN.COPY_USERSETTINGS.BUTTON_TITLE' | translate"
></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="copyUserSettingsForm"
    (ngSubmit)="submit()"
    id="copyUserSettingsForm"
  >
    <div class="row">
      <label class="bold">{{ 'ADMIN.COPY_USERSETTINGS.USER_TO_COPY_FROM' | translate }}</label>
      <div class="large-6 columns">
        <company-dropdown
          formControlName="organization"
        ></company-dropdown>
      </div>
      <div class="large-6 columns">
        <ek-filterable-combo
          formControlName="user"
          [items]="userList$ | async"
          [loading]="loadingUsers$ | async"
          [disableWhenLoading]="true"
          [filterable]="true"
          *labelBefore="'ADMIN.TERMINAL.USERNAME' | translate"
        ></ek-filterable-combo>
      </div>
    </div>
    <form class="row no-border-on-invalid" [formGroupName]="'whatToCopy'">
      <label class="bold">{{ 'ADMIN.COPY_USERSETTINGS.WHAT_TO_COPY' | translate }}</label>
      <div class="columns">
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="dashboards"
          *labelWrap="'ADMIN.COPY_USERSETTINGS.COPY_DASHBOARDSANDWIDGETS' | translate"
        >
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="bookmarks"
          *labelWrap="'ADMIN.COPY_USERSETTINGS.COPY_BOOKMARKS' | translate"
        >
      </div>
    </form>
    <div class="row">
      <div class="columns">
        <ek-combo
          class="customWidth"
          formControlName="sharedProfile"
          [items]="sharedProfileList$ | async"
          [loading]="loadingProfileList$ | async"
          [disableWhenLoading]="true"
          *labelBefore="'ADMIN.COPY_USERSETTINGS.FROM_AND_TO_PROFILE' | translate"
        ></ek-combo>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="copyUserSettingsForm"
      [disabled]="!copyUserSettingsForm.valid"
    >
      {{ 'ADMIN.COPY_USERSETTINGS.SAVE_BUTTON' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
