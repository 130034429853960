import 'angular-cookies';
import 'angular-translate';
import 'angular-translate-loader-partial';

import DocumnetsModelsModule from './models';

import config from './config';

export default angular.module('Documents', [
  'pascalprecht.translate',
  'ngCookies',
  DocumnetsModelsModule.name
])
  .config(config)
;
