<kendo-combobox
  [data]="items"
  [filterable]="true"
  [valueField]="valueField"
  [textField]="textField"
  [loading]="loading$ | async"
  [disabled]="disabled || (disabled$ | async)"
  (filterChange)="filterChanged($event)"
  (valueChange)="valueChanged($event)"
  (blur)="blur()"
  [valuePrimitive]="valuePrimitive"
  [value]="value"
  [placeholder]="placeHolder | translate"
  class="kendo-combobox-search-icon"
></kendo-combobox>
