import template from 'raw-loader!./manual-qa-meter-facility-info.html';
import ManualQaMeterFacilityInfoController from './manual-qa-meter-facility-info.controller';

const ManualQaMeterFacilityInfoComponent = {
  template: template,
  controllerAs: 'vm',
  controller: ManualQaMeterFacilityInfoController,
  bindings: {
    meter: '<',
    timeFrame: '<'
  }
};

export default ManualQaMeterFacilityInfoComponent;
