import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { IncomingRequestSearchCriteria } from '@enerkey/clients/webhook';

@Injectable()
export class IncomingMessagesService implements OnDestroy {
  public readonly searchParams$: Observable<IncomingRequestSearchCriteria>;

  private readonly _searchParams$ = new Subject<IncomingRequestSearchCriteria>();

  public constructor() {
    this.searchParams$ = this._searchParams$.asObservable();
  }

  public ngOnDestroy(): void {
    this._searchParams$.complete();
  }

  public search(params: IncomingRequestSearchCriteria): void {
    this._searchParams$.next(params);
  }
}
