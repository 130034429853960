<modal-view-header [headingText]="'DATA_API_KEYS.TITLE' | translate"></modal-view-header>
<div class="modal-body">
  <profile-api-keys></profile-api-keys>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button button--secondary"
      (click)="dismiss()"
    >
      {{ 'MODALS.CLOSE' | translate }}
    </button>
  </div>
  <div class="modal-footer-buttons float-right">
  </div>
</div>
