import { Quantities } from '@enerkey/clients/metering';

import { ReportingMeterTreeMeter } from '../services/report-modal-meters.service';

export class ReportingMeterSelection {
  public readonly meterIds: number[];

  public readonly meterIdsByQuantities: ReadonlyMap<number, number[]>;
  public readonly quantities: Quantities[];

  public constructor(public readonly meters: Readonly<ReportingMeterTreeMeter>[]) {
    this.meterIds = meters.map(m => m.id);
    const metersById = meters.toRecord(m => m.id);
    this.quantities = meters.unique('quantityId');
    this.meterIdsByQuantities = this.meterIds.toGroupsBy(mId => metersById[mId].quantityId);
  }
}
