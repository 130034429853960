interface CustomCategoryItem {
  id: number;
  key: string;
}

export const customCategories: ReadonlyArray<CustomCategoryItem> = [
  { id: 0, key: 'ACTIONS.CATEGORY_0' },
  { id: 1, key: 'ACTIONS.CATEGORY_1' },
  { id: 2, key: 'ACTIONS.CATEGORY_2' },
  { id: 3, key: 'ACTIONS.CATEGORY_3' },
  { id: 4, key: 'ACTIONS.CATEGORY_4' },
  { id: 5, key: 'ACTIONS.CATEGORY_5' },
  { id: 6, key: 'ACTIONS.CATEGORY_6' },
  { id: 7, key: 'ACTIONS.CATEGORY_7' },
  { id: 8, key: 'ACTIONS.CATEGORY_8' }
];
