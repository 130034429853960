import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalBase, NgfActiveModal } from '@enerkey/foundation-angular';
import { ConfigurationControlClient } from '@enerkey/clients/configuration-control';

@Component({
  selector: 'terminal-comment-modal',
  templateUrl: './terminal-comment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TerminalCommentModalComponent extends ModalBase<void> {
  @Input() public terminalName: string;
  @Input() public terminalId: number;
  @Input() public text: string;
  @Input() public allowEdit: boolean;

  public constructor(
    ngfActiveModal: NgfActiveModal,
    private readonly configurationControlClient: ConfigurationControlClient
  ) {
    super(ngfActiveModal);
  }

  public dismiss(): void {
    super.dismissModal();
  }

  public onSave(): void {
    this.configurationControlClient.setTerminalComment(this.terminalId, this.text)
      .subscribe(() => {
        super.closeModal();
      });
  }
}
