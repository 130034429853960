import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { AxisDefaults, CategoryAxis, SeriesClickEvent } from '@progress/kendo-angular-charts';

import { SimpleChangesOf } from '@enerkey/ts-utils';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { QuantityChartData, ValuePoint } from '../../shared/consumptions';
import { ColorService } from '../../../services/color.service';
import { TimeFrameString } from '../../../../services/time-frame-service';
import { getCategoryLabels } from '../../shared/resolution-to-string';
import { chartLineColor } from '../../../../constants/chart-constants';

type ValueField = keyof Pick<ValuePoint, 'value'>;

export enum ConsumptionWidgetChartAxis {
  Consumption = 'consumption',
  RelationalValue = 'relationalValue'
}

interface AxisMinAndMax {
  min: number;
  max: number;
  relationalMin: Record<number, number>;
  relationalMax: Record<number, number>;
}

@Component({
  selector: 'consumption-chart',
  templateUrl: './consumption-chart.component.html',
  styleUrls: ['./consumption-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumptionChartComponent implements OnInit, OnChanges {
  @Input() public quantityData: QuantityChartData;
  @Input() public unitKey = ReportingUnit.Default;
  @Input() public periodTitles: string[];
  @Input() public resolution: TimeFrameString;
  @Input() public showGridLines: boolean;
  @Input() public showAxis: boolean = false;
  @Input() public showLegend: boolean = false;
  @Input() public showGap: boolean = false;

  @Output() public readonly seriesClick = new EventEmitter<SeriesClickEvent>();

  public readonly blackColor = chartLineColor;
  public readonly comparisonPeriodColor: string;
  public categoryAxis: CategoryAxis;

  public ConsumptionWidgetAxisName = ConsumptionWidgetChartAxis;

  public readonly valueField: ValueField = 'value';

  public relationalValueColors: Record<number, string[]> = {};

  public readonly axisDefaults: AxisDefaults = {
    majorGridLines: { visible: false },
    minorGridLines: { visible: false },
  };

  public valueAxisOptions: AxisMinAndMax = {
    min: null,
    max: null,
    relationalMin: {},
    relationalMax: {}
  };

  public constructor(private readonly colorService: ColorService) {
    this.comparisonPeriodColor = this.colorService.firstComparisonPeriodColor;
  }

  public ngOnInit(): void {
    if (this.showGridLines) {
      this.axisDefaults.majorGridLines.visible = true;
    }

    this.categoryAxis = {
      labels: {
        background: '#ffffffde',
        position: 'onAxis',
        color: '#232323',
        font: '12px "Open Sans"'
      },
      categories: getCategoryLabels(
        this.resolution,
        this.quantityData.values
      ),
      majorTicks: {
        size: 4,
        width: 1,
      },
      line: {
        color: this.blackColor
      },
      axisCrossingValue: [0, 32, 32]
    };

    this.relationalValueColors = this.quantityData.relationalValueIds?.reduce<Record<number, string[]>>(
      (values, relationalValueId) => {
        values[relationalValueId] = this.colorService.colorsForRelationalValue(
          relationalValueId,
          this.quantityData.values.length
        );
        return values;
      }, {}
    ) ?? {};
  }

  public ngOnChanges(changes: SimpleChangesOf<ConsumptionChartComponent>): void {
    if (changes.quantityData?.currentValue) {
      this.setAxisMinAndMaxValues();
    }
  }

  public seriesClicked(event: SeriesClickEvent): void {
    this.seriesClick.emit(event);
  }

  private setAxisMinAndMaxValues(): void {
    const axisMinsAndMaxs: AxisMinAndMax = this.quantityData.values.reduce((options, point) => {
      const values = point.map(v => v.value);
      const relationalValues = this.quantityData.relationalValueIds?.reduce<Record<number, number[]>>(
        (allRelationalValues, relationalValueId) => {
          allRelationalValues[relationalValueId] = point.map(v => v.relationalValues[relationalValueId]);
          return allRelationalValues;
        },
        {}
      ) ?? [];
      options.min = Math.min(options.min, ...values);
      options.max = Math.max(options.max, ...values);
      this.quantityData.relationalValueIds?.forEach(relationalValueId => {
        options.relationalMin[relationalValueId] = Math.min(
          options.relationalMin[relationalValueId] ?? null, ...relationalValues[relationalValueId]
        );
        options.relationalMax[relationalValueId] = Math.max(
          options.relationalMax[relationalValueId] ?? null, ...relationalValues[relationalValueId]
        );
      });
      return options;
    }, {
      min: 0,
      max: 0,
      relationalMin: {} as Record<number, number>,
      relationalMax: {} as Record<number, number>
    });
    this.valueAxisOptions = {
      ...axisMinsAndMaxs,
      max: axisMinsAndMaxs.max * 1.2
    };
  }
}
