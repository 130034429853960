import { Roles } from '../admin/constants/roles';
import { RouteAuthLogic, UIStateNg2 } from '../../shared/routing';
import { Service } from '../../constants/service';

export const analyticsTopbarState: UIStateNg2<'ines'> = {
  name: 'ines',
  data: {
    translationKey: 'ANALYTICS.TITLE',
    auth: {
      logic: RouteAuthLogic.Any,
      roles: [Roles.ENERGY_MANAGER],
      services: [
        Service.ElectricityDemandResponseAnalytics,
        Service.InesLiteSummary,
      ],
      serviceLogic: RouteAuthLogic.Any,
    },
  }
};
