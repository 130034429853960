import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

import { ClipboardService } from '../../shared/services/clipboard.service';
import { ToasterService } from '../../shared/services/toaster.service';

@Component({
  selector: 'access-token-copy',
  templateUrl: './access-token-copy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessTokenCopyComponent {
  @Input() public isMobile: boolean;

  public constructor(
    private readonly oauthService: OAuthService,
    private readonly toasterService: ToasterService,
    private readonly clipboard: ClipboardService
  ) {
  }

  public copyAccessToken(): void {
    const accessToken = this.oauthService.getAccessToken();

    this.clipboard.copy(`Bearer ${accessToken}`).then(
      () => this.toasterService.info('Access token copied to clipboard'),
      err => this.toasterService.error('Copying access token failed', `${err}`)
    );
  }
}
