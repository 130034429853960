import { downgradeComponent } from '@angular/upgrade/static';

import EnerKeyServicesModule from '../../../services';
import KendoModule from '../../kendo';
import EnergyManagementConstantsModule from '../constants';
import EnergyManagementModelsModule from '../models';
import EnergyManagementServicesModule from '../services';
import ConfigurationModelsModule from '../../configuration/models';
import ReportingObjectsServicesModule from '../../reportingobjects/services';
import DonutVisualsModule from '../../../components/donut-visuals';
import emActionEdit from './em-action-edit/em-action-edit-component';
import emFacilitySelect from './em-facility-select/em-facility-select-component';
import emInputDate from './em-input-date/em-input-date-component';
import emInputDropdown from './em-input-dropdown/em-input-dropdown-component';
import emInputFacility from './em-input-facility/em-input-facility-component';
import emInputMultiSelect from './em-input-multi-select/em-input-multi-select-component';
import emInputNumber from './em-input-number/em-input-number-component';
import emInputText from './em-input-text/em-input-text-component';
import emMassEdit from './em-mass-edit/em-mass-edit-component';
import emVisuals from './em-visuals/em-visuals-component';
import emYearlyVisuals from './em-yearly-visuals/em-yearly-visuals-component';
import emActionEditService from './em-action-edit/em-action-edit-service';
import { EmMassEditService } from './em-mass-edit/em-mass-edit-service';
import emYearlyVisualsService from './em-yearly-visuals/em-yearly-visuals-service';
import { EmIndexComponent } from './em-index/em-index.component';

export default angular.module('EnergyManagement.Components', [
  EnerKeyServicesModule.name,
  KendoModule.name,
  EnergyManagementConstantsModule.name,
  EnergyManagementModelsModule.name,
  EnergyManagementServicesModule.name,
  ConfigurationModelsModule.name,
  ReportingObjectsServicesModule.name,
  DonutVisualsModule.name
])
  .component('emActionEdit', emActionEdit)
  .component('emFacilitySelect', emFacilitySelect)
  .component('emInputDate', emInputDate)
  .component('emInputDropdown', emInputDropdown)
  .component('emInputFacility', emInputFacility)
  .component('emInputMultiSelect', emInputMultiSelect)
  .component('emInputNumber', emInputNumber)
  .component('emInputText', emInputText)
  .component('emMassEdit', emMassEdit)
  .component('emVisuals', emVisuals)
  .component('emYearlyVisuals', emYearlyVisuals)
  .directive('emIndex', downgradeComponent({ component: EmIndexComponent }))
  .service('emActionEditService', emActionEditService)
  .service('emMassEditService', EmMassEditService)
  .service('emYearlyVisualsService', emYearlyVisualsService);
