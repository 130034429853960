import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

import { switchJoin } from '@enerkey/rxjs';
import { Dashboard } from '@enerkey/clients/settings';

import { DashboardService } from '../../services/dashboard.service';
import { WidgetType } from '../../shared/widget-type';
import { WidgetDefinition, WidgetDefinitionsService, WidgetGroup } from '../../services/widget-definitions.service';

@Component({
  selector: 'dashboard-tabs',
  templateUrl: './dashboard-tabs.component.html',
  styleUrls: ['./dashboard-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardTabsComponent {
  public readonly widgetGroups$: Observable<{ widgetGroupName: string, widgets: WidgetDefinition<unknown>[] }[]>;
  public readonly activeDashboard$: Observable<Dashboard>;
  public readonly dashboards$: Observable<Dashboard[]>;
  public readonly showButtons$: Observable<boolean>;
  public readonly usedWidgetAmounts$: Observable<Record<string, number>>;

  private readonly _collator = new Intl.Collator(undefined, {
    numeric: false,
    sensitivity: 'base',
    usage: 'sort',
  });

  public constructor(
    private readonly dashboardService: DashboardService,
    widgetDefinitionsService: WidgetDefinitionsService,
    translateService: TranslateService
  ) {
    this.dashboards$ = this.dashboardService.dashboards$;
    this.activeDashboard$ = this.dashboardService.activeDashboard$;
    this.usedWidgetAmounts$ = this.dashboardService.usedWidgetAmounts$;
    this.showButtons$ = this.dashboardService.dashboardsLoading$.pipe(map(loading => !loading));
    this.widgetGroups$ = widgetDefinitionsService.widgetDefinitions$.pipe(
      switchJoin(widgets => translateService.get(widgets.map(w => w.defaultTitle))),
      map(([widgets, translations]: [WidgetDefinition<unknown, WidgetType>[], Record<string, string>]) =>
        [...widgets].sort(
          (a, b) => this._collator.compare(translations[a.defaultTitle], translations[b.defaultTitle])
        )),
      map(widgets =>
        [
          {
            widgetGroupName: translateService.instant('WIDGET_GROUP.GENERAL'),
            widgets: widgets.filter(w => w.widgetGroupType === WidgetGroup.General)
          },
          {
            widgetGroupName: translateService.instant('WIDGET_GROUP.ALL_FACILITIES'),
            widgets: widgets.filter(w => w.widgetGroupType === WidgetGroup.AllFacilities)
          },
          {
            widgetGroupName: translateService.instant('WIDGET_GROUP.ONE_FACILITY'),
            widgets: widgets.filter(w => w.widgetGroupType === WidgetGroup.OneFacility)
          }
        ])
    );
  }

  public selectDashboard(dashboard?: Dashboard): void {
    this.dashboardService.selectDashboard(dashboard.id);
  }

  public editDashboard(dashboard: Dashboard): void {
    this.dashboardService.editDashboard(dashboard.id);
  }

  public addDashboard(): void {
    this.dashboardService.addDashboard();
  }

  public addWidget(widgetType: WidgetType): void {
    this.dashboardService.addWidget(widgetType);
  }
}
