import { Component, Input } from '@angular/core';

import { Terminal } from '@enerkey/clients/configuration-control';

@Component({
  selector: 'terminal-edit',
  templateUrl: './terminal-edit.component.html',
  styleUrls: ['./terminal-edit.component.scss']
})

export class TerminalEditComponent {
  @Input() public enegiaId: number;
  @Input() public enegiaIds: number[];
  @Input() public terminal: Terminal;
}
