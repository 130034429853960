import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { ControlsOf } from '@enerkey/ts-utils';

import { ComboItem } from '../../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ISODuration, isoDurationTranslations } from '../../../../../shared/isoduration';
import { EtCurveAdminParams, ETCurveSearchParams } from '../../../models/et-curve.model';
import { EtCurveService } from '../../../services/et-curve/et-curve.service';

@Component({
  selector: 'et-curve-calculation',
  templateUrl: './et-curve-calculation.component.html',
  styleUrls: ['./et-curve-calculation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class EtCurveCalculationComponent implements OnInit, OnDestroy {
  private static readonly RESOLUTIONS: ISODuration[] = [
    ISODuration.P7D,
    ISODuration.P1D,
    ISODuration.PT1H
  ];

  private static readonly FILTER_WEEKENDS: boolean[] = [
    true,
    false
  ];

  @Input() public header: string = '';
  @Input() public advancedCheckboxEnabled: boolean = true;
  @Input() public activeEtCurve?: Observable<Partial<EtCurveAdminParams>>;
  @Input() public maxDate: Date = this.etCurveService.oneYearAgo;

  public controls: ControlsOf<ETCurveSearchParams>;

  public readonly resolutionOptions: ComboItem<ISODuration>[] =
    EtCurveCalculationComponent.RESOLUTIONS.map(value => ({ value, text: isoDurationTranslations[value] }));

  public readonly filterWeekendOptions: ComboItem<boolean>[] = EtCurveCalculationComponent.FILTER_WEEKENDS
    .map(value => ({
      value,
      text: value ? 'ANALYTICS.ETCURVE.WORKING_DAYS' : 'ANALYTICS.ETCURVE.ALL_DAYS'
    }));

  private readonly _destroy = new Subject<void>();

  public constructor(
    private readonly etCurveService: EtCurveService,
    public readonly formGroup: FormGroupDirective
  ) {}

  public ngOnInit(): void {
    this.controls = this.formGroup.control.controls as ControlsOf<ETCurveSearchParams>;
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}

