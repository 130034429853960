import { Component, Input, OnInit } from '@angular/core';
import { NgfActiveModal } from '@enerkey/foundation-angular';
import { ModalBase } from '@enerkey/foundation-angular';
import { ConfigurationControlClient, Terminal } from '@enerkey/clients/configuration-control';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'terminal-remove-meter',
  templateUrl: './terminal-remove-meter.component.html',
  styleUrls: ['./terminal-remove-meter.component.scss'],
})

export class TerminalRemoveMeterComponent extends ModalBase implements OnInit {
  @Input() public terminalName: string;
  @Input() public meterIds: number[];
  public terminal: Terminal;

  public constructor(
    activeModal: NgfActiveModal,
    private readonly configurationControlClient: ConfigurationControlClient,
    private readonly toasterService: ToasterService
  ) {
    super(activeModal);
  }

  public ngOnInit(): void {
    this.configurationControlClient.getTerminalByName(this.terminalName)
      .subscribe(
        response => {
          this.terminal = response;
          this.updateTerminalMeterIds();
        },
        () => this.toasterService.error('ADMIN.TERMINAL_NOT_FOUND.ERROR')
      );
  }

  public isValid(): boolean {
    return this.terminal !== undefined && this.terminal.name.length > 0;
  }

  public save(): void {
    this.configurationControlClient.updateTerminal(this.terminal)
      .subscribe(
        () => {
          this.toasterService.success('ADMIN.TERMINAL.EDIT_SUCCESS');
          super.closeModal();
        },
        () => this.toasterService.error('ADMIN.TERMINAL.EDIT_FAILED')
      );
  }

  private updateTerminalMeterIds(): void {
    if (this.terminal) {
      this.meterIds.forEach((meterId: number) => {
        this.terminal.terminalMeters = this.terminal.terminalMeters.filter(tm => tm.meterId !== meterId);
      });
    } else {
      this.toasterService.error('ADMIN.TERMINAL.EDIT_FAILED');
    }
  }
}
