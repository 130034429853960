import { UIStateNg2 } from '../../shared/routing';
import { Roles } from '../admin/constants/roles';

export const tenantManagerTabState: UIStateNg2 = {
  name: 'tenant',
  data: {
    translationKey: 'Tenant',
    auth: {
      roles: [Roles.TENANT_MANAGER]
    }
  },
};
