import _ from 'lodash';

import { actionTypes } from '../constants/em-action-types';
import { executionPhases } from '../constants/em-execution-phases';

function arrayToObject(array, key) {
  return array.reduce((result, item) => {
    result[item[key]] = item;
    return result;
  }, {});
}

const emActionTypes = arrayToObject(actionTypes, 'name');
const emExecutionPhases = arrayToObject(executionPhases, 'name');

class EmValidationService {
  static getMandatoryFields(action) {
    // Always mandatory
    let mandatory = [
      'reportingObjectId',
      'actionType',
      'investigation',
      'reportedDescription'
    ];

    // Meter implies Quantity
    if (!_.isEmpty(action.meterIds)) {
      mandatory.push('quantityIds');
    }

    // Depending on action type
    if (this.isComment(action)) {
      if (action.actionType === 5) {
        mandatory.push('effectStartsAt');
      }
    } else {
      mandatory.push('executionPhase');
      // Depending on execution phase
      // Fall through is intentional, these requrements cumulate
      // during the process of phase maturing 1 -> 2 -> 3.
      switch (action.executionPhase) {
        case emExecutionPhases.implemented.id:
          mandatory = mandatory.concat([
            'reportingYear',
            'investment',
            'paybackTime',
            'effectStartsAt'
          ]);
        // eslint-disable-next-line no-fallthrough
        case emExecutionPhases.implementationDecided.id:
          mandatory = mandatory.concat([
            'executionYear',
            'actionBasis'
          ]);
          break;
      }
    }
    return mandatory;
  }

  // Is the given field considered mandatory?  Mandatory states
  // depend on the value of actionType and executionPhase.
  static isMandatory(action, field) {
    return this.getMandatoryFields(action).includes(field);
  }

  static isComment(action) {
    return (action.actionType === emActionTypes.otherComment.id) ||
      (action.actionType === emActionTypes.energyConsumptionComment.id);
  }

  static areAllDependenciesSame(actions, field) {
    const dependencyValue = this.getDependencyValue(actions, field);
    return actions.every(action => action[field.dependsOn] === dependencyValue);
  }

  static getDependencyValue(actions, field) {
    const dependencyField = field.dependsOn;
    return actions[0][dependencyField];
  }

  static areSomeMandatory(actions, field) {
    let someAreMandatory = false;
    let mandatoryVaries = false;
    const firstIsMandatory = this.isMandatory(
      actions[0], field.name
    );
    actions.some(action => {
      const mandatory = this.isMandatory(action, field.name);
      if (mandatory) {
        someAreMandatory = true;
      }
      if (mandatory !== firstIsMandatory) {
        mandatoryVaries = true;
      }
      return someAreMandatory && mandatoryVaries;
    });
    return {
      some: someAreMandatory,
      varies: mandatoryVaries
    };
  }

  static getInvalidActions(actions) {
    return actions.filter(action => !this.isValid(action));
  }

  static isValid(action) {
    const mandatoryFields = this.getMandatoryFields(action);
    return mandatoryFields.every(mandatory => {
      const value = action[mandatory];
      if (angular.isArray(value)) {
        return value.length > 0;
      } else {
        return value !== '' && value !== null && angular.isDefined(value);
      }
    });
  }
}

export default EmValidationService;
