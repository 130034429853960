<kendo-chart
  [transitions]="false"
  [categoryAxis]="{ visible: false }"
  [valueAxis]="{ visible: false }"
  [legend]="{ visible: false }"
  (seriesClick)="seriesClick($event)"
  (seriesOver)="seriesOver($event)"
>
  <kendo-chart-area [margin]="0"></kendo-chart-area>
  <kendo-chart-tooltip>
    <ng-template kendoChartSeriesTooltipTemplate
      let-category="category"
      let-percentage="percentage"
    >
      {{ category | quantityName | async }} - {{ percentage | kendoNumber:'#.0 %' }}
    </ng-template>
  </kendo-chart-tooltip>
  <kendo-chart-series>
    <kendo-chart-series-item
      [autoFit]="true"
      type="donut"
      [data]="data"
      [field]="'inspection.value'"
      [opacity]="0.8"
      [categoryField]="'quantityId'"
      [colorField]="'color'"
    >
      <kendo-chart-series-item-labels
        position="outsideEnd"
        color="#000"
        [font]="'13px Open Sans'"
        [content]="labelContent"
      >
      </kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>
