import { downgradeComponent } from '@angular/upgrade/static';

/* eslint-disable max-len */
import AdminFacilityComponentModule from './admin-facility';
import AdminSpreadsheetComponentModule from './admin-spreadsheet';
import MeterCreateComponentModule from './meter-create';
import FacilityCreateModule from './facility-create';
import { MeterEditComponent } from '../../virtual-meters/components/meter-edit/meter-edit.component';
import ManualQaInspectButtonModule from '../../manual-qa/components/manual-qa-inspect-button';
import FormulaEditorTreelistComponent from './formula-editor-treelist/';
import VirtualMeterEditorAddComponent from './virtual-meter-editor-add/';
import MassAddFacilityPersonsComponent from './mass-add-facility-persons-dialog';
import MassAddPersonsComponent from './mass-add-persons-dialog';
import MassAddCompaniesComponent from './mass-add-companies-dialog';
import MassReassignPersonFacilitiesComponent from './mass-reassign-personfacilities-dialog';
import MeterMassEditModule from './meter-mass-edit';
import MeterCostMassEditModule from './meter-cost-mass-edit';
import ComponentsModule from '../../../components';
import { AdminTabsComponent } from '../../../shared/admin-shared/components/admin-tabs/admin-tabs.component';
/* eslint-enable */

const AdminFacilityModule = angular.module('app.modules.admin.components', [
  AdminFacilityComponentModule.name,
  AdminSpreadsheetComponentModule.name,
  MeterCreateComponentModule.name,
  FacilityCreateModule.name,
  ManualQaInspectButtonModule.name,
  MeterMassEditModule.name,
  MeterCostMassEditModule.name,
  ComponentsModule.name
])
  .directive('meterEdit', downgradeComponent({ component: MeterEditComponent }))
  .directive('adminTabs', downgradeComponent({ component: AdminTabsComponent }))
  .component('virtualMeterEditorAdd', VirtualMeterEditorAddComponent)
  .component('formulaEditorTreelist', FormulaEditorTreelistComponent)
  .component('massAddFacilityPersons', MassAddFacilityPersonsComponent)
  .component('massAddPersons', MassAddPersonsComponent)
  .component('massAddCompanies', MassAddCompaniesComponent)
  .component('massReassignPersonFacilities', MassReassignPersonFacilitiesComponent)
;

export default AdminFacilityModule;
