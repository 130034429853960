import { NoteType, ReportNote } from '../../shared/report-note';

export class GridReportActionsPopupController {

  public row: any;
  public type: NoteType;
  public notes: ReportNote[];

  public title: string;

  public $onInit(): void {
    this.title = this.type === 'action'
      ? 'FACILITIES_REPORT.LATEST_ACTIONS_POPUP_TITLE'
      : 'FACILITIES_REPORT.LATEST_COMMENTS_POPUP_TITLE';
  }
}
