import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'id-input',
  templateUrl: './id-input.component.html'
})
export class IdInputComponent {
  @Input() public id: number;
  @Input() public disabled: boolean = false;
  @Output() public idChange = new EventEmitter<number>();
  @Output() public blurred = new EventEmitter<number>();

  public change(newValue: number): void {
    this.id = newValue;
    this.idChange.emit(newValue);
  }

  public blur(): void {
    this.blurred.emit(this.id);
  }
}
