import moment from 'moment';

import templateModule from 'raw-loader!../templates/mrc-change-meter.html';

export function mrcChangeMeter() {
  return {
    restrict: 'E',
    template: templateModule,
    replace: true,
    scope: {
      options: "=",
      refresh: "=",
      mrcState: "=",
      facility: "=",
      typeChange: "="
    },
    controller: [
      '$scope', '$q', 'utils', 'mrcapi', '$translate', 'mrcDataParser', 'MrcConstants',
      function ($scope, $q, utils, mrcapi, $translate, mrcDataParser, MrcConstants) {
        var steps = [];
        $scope.changeOptions = undefined;
        $scope.MrcConstants = MrcConstants;

        function getMinDate() {
          return $q(function (resolve, reject) {
            var date = undefined;
            var currentDate = new Date();
            if ($scope.options.meter && $scope.options.meter.Readings && $scope.options.meter.Readings[0]) {
              date = new Date(angular.copy($scope.options.meter.Readings[0].Date));
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              date.setMilliseconds(0);
              if($scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING && ($scope.options.meter.Readings[0].Flags !== 2 || $scope.typeChange)) {
                date = moment(date).add(1, 'day').toDate();
              }
              if($scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
                date = moment(date).add(1, 'month').toDate();
              }
              if (currentDate < date) {
                currentDate = angular.copy(date);
              }
              resolve({currentDate: currentDate, minDate: date});
            }
            else {
              if(!$scope.typeChange) {
                resolve({currentDate: currentDate, minDate: currentDate})
              }
              else {
                if(!_.isArray($scope.options.meter.Readings) || $scope.options.meter.Readings.length === 0) {
                  date = moment($scope.mrcState.editableLimitDate).subtract(1, 'day').toDate();
                  resolve({currentDate: currentDate, minDate: date});
                }
                else {
                  mrcapi.getLatestReadingForMeter($scope.facility.FacilityId, $scope.options.meter.Id).then(
                    function(res) {
                      if(angular.isDefined(res) && res.length > 0) {
                        date = res[0].ReadingDate;
                      }
                    },
                    function(err) {

                    }).finally(function() {
                      if(angular.isDefined(date) && date !== null && $scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
                        date = moment(date).add(1, 'month').toDate();
                      }
                      else {
                        date = moment($scope.mrcState.editableLimitDate).subtract(1, 'day').toDate();
                      }
                      if (currentDate < date) {
                        currentDate = angular.copy(date);
                      }
                      resolve({currentDate: currentDate, minDate: date});
                    });
                }
              }
            }
          });
        }

        function init() {
          steps = [];
          if($scope.typeChange) {
            if($scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING) {
              var stepName = $scope.options.meter.WithoutReading ? $translate.instant('MRC.READINGS.WIZARD.STEP_NO_END_READING_REQUIRED') : $translate.instant('MRC.READINGS.WIZARD.STEP_END_READING');
              steps.push({ item: 'old', order: 1, name: stepName, completed: false, active: false, next: 'new', callback: toNextStep, callbackTitle: 'MRC.READINGS.WIZARD.STEP_OLD_CALLBACK' });
            }
            steps.push({ item: 'new', order: $scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING ? 2 : 1, name: 'MRC.READINGS.WIZARD.STEP_INFORMATION', completed: false, active: false, next: 'confirm', callback: toNextStep, callbackTitle: 'MRC.READINGS.WIZARD.STEP_NEW_CALLBACK' });
          }
          else {
            steps.push({ item: 'old', order: 1, name: $scope.mrcState.isMobile ? 'MRC.READINGS.WIZARD.STEP_OLD_SHORT' : 'MRC.READINGS.WIZARD.STEP_OLD', completed: false, active: false, next: 'new', callback: toNextStep, callbackTitle: 'MRC.READINGS.WIZARD.STEP_OLD_CALLBACK' });
            steps.push({ item: 'new', order: 2, name: $scope.mrcState.isMobile ? 'MRC.READINGS.WIZARD.STEP_NEW_SHORT' : 'MRC.READINGS.WIZARD.STEP_NEW', completed: false, active: false, next: 'confirm', callback: toNextStep, callbackTitle: 'MRC.READINGS.WIZARD.STEP_NEW_CALLBACK' });
          }
          steps.push({ item: 'confirm', order: ($scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING || $scope.mrcState.isMobile) ? 3 : 2, name: 'MRC.READINGS.WIZARD.STEP_CONFIRM', completed: false, active: false, next: null, callbacks: {change: changeMeter, reset: init} });

          var stepsToScope = angular.copy(steps);
          stepsToScope[0].active = true;
          $scope.steps = stepsToScope;

          var currentDate = new Date();
          var minDate = undefined;

          var meterCommonData = {
            Name: angular.copy($scope.options.meter.Name),
            Description: angular.copy($scope.options.meter.Description),
            Factor: angular.copy($scope.options.meter.Factor)
          };

          $scope.changeOptions = {
            ReadingDate: currentDate,
            ReadingDateMin: minDate,
            ErrorMessage: '',
            meters: {
              'old': {
                Type: 'old',
                Id: angular.copy($scope.options.meter.Id),
                Name: meterCommonData.Name,
                Description: meterCommonData.Description,
                CustomerMeterIdentifier: angular.copy($scope.options.meter.CustomerMeterIdentifier),
                Factor: meterCommonData.Factor,
                MeteringType: angular.copy($scope.options.meter.MeteringType),
                TwoTimeMeasurement: angular.copy($scope.options.meter.TwoTimeMeasurement),
                Readings: angular.copy($scope.options.meter.Readings) || ($scope.typeChange ? [] : [{ Type: 1, Value: null }]),
                WithoutReading: angular.copy($scope.options.meter.WithoutReading)
              },
              'new': {
                Type: 'new',
                Name: angular.copy(meterCommonData.Name),
                Description: angular.copy(meterCommonData.Description),
                CustomerMeterIdentifier: angular.copy($scope.options.meter.CustomerMeterIdentifier),
                Factor: angular.copy(meterCommonData.Factor),
                MeteringType: $scope.options.meter.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING && $scope.typeChange ? MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION : MrcConstants.METERING_TYPE.MANUAL_READING,
                TwoTimeMeasurement: angular.copy($scope.options.meter.TwoTimeMeasurement),
                Readings: [{
                  Type: 1,
                  Value: 0
                }]
              }
            }
          };


          getMinDate().then(
            function(resolve) {
              currentDate = resolve.currentDate;
              minDate = resolve.minDate;
            },
            function(reject) {

            }).finally(function() {
              if(angular.isUndefined(minDate)) {
                minDate = angular.copy(currentDate);
              }
              $scope.changeOptions.ReadingDate = currentDate;
              if($scope.typeChange) {
                $scope.changeOptions.ReadingDate = moment($scope.changeOptions.ReadingDate).endOf('month').toDate();
              }
              $scope.changeOptions.ReadingDateMin = minDate;
            });
        }

        init();

        $scope.$on('meterDataUpdated', function () {
          init();
        });

        $scope.toThisStep = function (step) {
          $scope.changeOptions.ErrorMessage = '';

          if (!step.active && step.completed) {
            _.each($scope.steps, function (item) {
              item.active = false;
            });

            step.active = true;
          }
        };

        function toNextStep(step) {
          step.active = false;
          step.completed = true;
          _.each($scope.steps, function (item) {
            if (item.item === step.next) {
              item.active = true;
            }
          });
        }

        function changeMeter() {
          var meters = angular.copy($scope.changeOptions.meters);

          var changeDate = moment(angular.copy($scope.changeOptions.ReadingDate)).format('YYYY-MM-DD') + 'T00:00:00Z';
          var initialReadings = _.map(meters.new.Readings, function (readingItem) {
            return { Type: readingItem.Type, Value: readingItem.Value, Date: changeDate, Flags: 0 };
          });
          var finalReadings = _.map(meters.old.Readings, function (readingItem) {
            return { Type: readingItem.Type, Value: readingItem.Value, Date: changeDate, Flags: 0 };
          });

          if($scope.typeChange) {
            if(meters.new.MeteringType === MrcConstants.METERING_TYPE.MANUAL_CONSUMPTION) {
              initialReadings = [];
            }
            else if(meters.new.MeteringType === MrcConstants.METERING_TYPE.MANUAL_READING) {
              finalReadings = [];
            }
          }

          const changeMeterDataObject = {
            InitialReadings: initialReadings,
            FinalReadings: finalReadings,
            Id: angular.copy(meters.old.Id),
            Name: angular.copy(meters.new.Name),
            Factor: angular.copy(meters.new.Factor),
            MeteringType: $scope.typeChange
              ? angular.copy(meters.new.MeteringType)
              : angular.copy(meters.old.MeteringType),
            TwoTimeMeasurement: angular.copy(meters.new.TwoTimeMeasurement),
            CustomerMeterIdentifier: angular.copy(meters.new.CustomerMeterIdentifier),
            Description: angular.copy(meters.new.Description)
          };

          mrcapi.changeMeter($scope.options.facilityId, changeMeterDataObject).then(
            res => {
              if (res.code === '0') {
                utils.popUp('success', '', 'MRC.READINGS.METER_CHANGED', true);
                $scope.refresh();
              } else {
                handleChangeError(res.code);
              }
            },
            err => {
              if (err) {
                utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER_TYPE', true);
              }
            }
          );
        }

        $scope.changeMeter = changeMeter;

        function handleChangeError(errorCode) {
          switch (errorCode) {
            case '1':
              // Final readings not valid
              $scope.changeOptions.ErrorMessage = $translate.instant('MRC_ERRORS.FINAL_READINGS_ARE_INVALID');
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER', true);
              break;
            case '2':
              // Final readings not saved
              $scope.changeOptions.ErrorMessage = $translate.instant('MRC_ERRORS.FAILED_SAVING_FINAL_READINGS');
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER', true);
              break;
            case '3':
              // Initial readings not valid
              $scope.changeOptions.ErrorMessage = $translate.instant('MRC_ERRORS.INITIAL_READINGS_ARE_INVALID');
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER', true);
              break;
            case '4':
              // Initial readings not saved
              $scope.changeOptions.ErrorMessage = $translate.instant('MRC_ERRORS.FAILED_SAVING_INITIAL_READINGS');
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER', true);
              break;
            case '5':
              // Failed changing meter
              $scope.changeOptions.ErrorMessage = $translate.instant('MRC_ERRORS.FAILED_CHANGING_METER');
              utils.popUp('error', 'MRC_ERRORS.ERROR_TITLE', 'MRC_ERRORS.FAILED_CHANGING_METER', true);
              break;
          }
        }

      }
    ]
  };
}
