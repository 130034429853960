import timeFrameRadioDynamicTimeFrameTemplateModule from 'ngtemplate-loader!raw-loader!./time-frame-radio-dynamic-time-frame.html';
import timeFrameRadioFixedTimeFrameTemplateModule   from 'ngtemplate-loader!raw-loader!./time-frame-radio-fixed-time-frame.html';

import timeFrameRadioLastQuarterTemplateModule from 'ngtemplate-loader!raw-loader!./time-frame-radio-last-quarter.html';
import * as TimeFrames                         from './time-frame-radio-constant';

const $inject = ['utils'];

class TimeFrameRadioService {
  constructor(utils) {
    this.utils = utils;
  }

  getTimeFrames() {
    return [
      {
        id: TimeFrames.CURRENT_MONTH,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.ONGOING_MONTH')
      },
      {
        id: TimeFrames.CURRENT_QUARTER,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.ONGOING_QUARTAL')
      },
      {
        id: TimeFrames.CURRENT_YEAR,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.ONGOING_YEAR')
      },
      {
        id: TimeFrames.PREVIOUS_MONTH,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.LAST_MONTH')
      },
      {
        id: TimeFrames.PREVIOUS_TWO_MONTHS,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.LAST_TWO_MONTHS')
      },
      {
        id: TimeFrames.PREVIOUS_YEAR_ONE_MONTH,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.LAST_YEAR_PLUS_MONTH')
      },
      {
        id: TimeFrames.PREVIOUS_QUARTER,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.LAST_QUARTER'),
        customTemplate: timeFrameRadioLastQuarterTemplateModule
      },
      {
        id: TimeFrames.FIXED_TIME_FRAME,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.STATIC_INTERVAL'),
        customTemplate: timeFrameRadioFixedTimeFrameTemplateModule
      },
      {
        id: TimeFrames.DYNAMIC_TIME_FRAME,
        name: this.utils.localizedString('MQA.SEARCH.TIME_FRAME.DYNAMIC_INTERVAL'),
        customTemplate: timeFrameRadioDynamicTimeFrameTemplateModule
      }
    ];
  }
}

TimeFrameRadioService.$inject = $inject;

export default TimeFrameRadioService;
