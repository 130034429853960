<modal-view-header
  [headingText]="'ADMIN.BULK_CREATE' | translate"
></modal-view-header>
<div class="modal-body">
  <div class="stepTitle">
    <button
      class="button button--circle disable-click"
    >1</button>
    <label class="stepText">{{ 'ADMIN.BULK_CREATE_STEP_1' | translate }}</label>
  </div>
  <div class="file-upload">
    <input
      type="file"
      class="file-input"
      accept=".csv"
      (change)="onFileChange($event)"
      #fileUpload
    />
    <button
      class="button uploadButton"
      type="button"
      (click)="fileUpload.click()"
    >
      {{ 'ADMIN.BULK_UPLOAD_SELECT_FILE' | translate }}
    </button>
    {{ fileName || 'ADMIN.BULK_UPLOAD_NO_FILE_SELECTED' | translate}}
  </div>
  <label class="orText">{{ 'ADMIN.BULK_CREATE_OR_TEXT' | translate }}</label>
  <textarea
    type="text"
    kendoTextArea
    (input)="pasteFromExcel($event)"
    *labelBefore="'ADMIN.BULK_CREATE_COPYPASTEFROMEXCEL' | translate"
  ></textarea>
  <kendo-grid
    [kendoGridBinding]="importedUsers"
    [resizable]="true"
  >
    <kendo-grid-column
      field="firstName"
      [title]="'ADMIN.FIRSTNAME' | translate"
    ></kendo-grid-column>
    <kendo-grid-column
      field="lastName"
      [title]="'ADMIN.LASTNAME' | translate"
    ></kendo-grid-column>
    <kendo-grid-column
      field="userName"
      [title]="'ADMIN.EMAIL' | translate"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.userName.includes('@') else invalidEmail">
          {{ dataItem.userName }}
        </ng-container>
        <ng-template #invalidEmail>
          <span [style.color]="'red'">
            {{ 'ADMIN.SPREADSHEET.VALUE.TITLE'  | translate }}
          </span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      [title]="'ADMIN.ORGANIZATION' | translate"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container *ngIf="dataItem.companyId else invalidCompany">
          {{ companyList[dataItem.companyId] }}
        </ng-container>
        <ng-template #invalidCompany>
          <span [style.color]="'red'">
            {{ 'ADMIN.SPREADSHEET.VALUE.TITLE'  | translate }}
          </span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      [title]="'ADMIN.BULK_CREATE_PROFILE_FIELD' | translate"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="dataItem.profileIds !== null && dataItem.profileIds[0] !== 0 else invalidProfile"
        >
            {{ dataItem?.profileIds?.length ? profileList[dataItem.profileIds[0]] : '' }}
        </ng-container>
        <ng-template #invalidProfile>
          <span [style.color]="'red'">
            {{ 'ADMIN.SPREADSHEET.VALUE.TITLE'  | translate }}
          </span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="thirdPartySystemName"
      [title]="'ADMIN.BULK_CREATE_THIRD_PARTY_SYSTEM_FIELD' | translate"
    ></kendo-grid-column>
    <kendo-grid-column
      [title]="'ADMIN.ACCOUNT_END_DATE' | translate"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container
          *ngIf="dataItem?.accountEndDate?.toString() !== 'Invalid Date'
          else invalidDate"
        >
          {{ dataItem?.accountEndDate | kendoDate: 'g' }}
        </ng-container>
        <ng-template #invalidDate>
          <span [style.color]="'red'">
            {{ 'ADMIN.SPREADSHEET.VALUE.TITLE'  | translate }}
          </span>
        </ng-template>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="comment"
      [title]="'ADMIN.COMMENT' | translate"
    ></kendo-grid-column>
  </kendo-grid>
  <div class="stepTitle">
    <button
      class="button button--circle disable-click"
    >2</button>
    <label class="stepText">{{ 'ADMIN.BULK_CREATE_STEP_2' | translate }}</label>
  </div>
  <form
    [formGroup]="sharedInformation"
  >
    <div class="row">
      <div class="small-6 columns">
        <language-select
          formControlName="preferredLanguage"
          *labelBefore="'ADMIN.LANGUAGE' | translate"
        ></language-select>
      </div>
      <div class="small-6 columns">
        <role-multi-select
          class="margin-bottom"
          formControlName="roles"
          *labelBefore="'ADMIN.ROLES' | translate"
        ></role-multi-select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="button"
      [loading]="loading$ | async"
      [disabled]="!dataIsLoaded
                  || validationErrorFound
                  || !sharedInformation.valid"
      (click)="createUsers()"
    >
      {{ 'ADMIN.BULK_SAVE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
