import { FilterService } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Component, Input } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';

import { flattenKendoFilter } from '../../kendo.functions';
import { PropertyPathPipe } from '../../../common-pipes/property-path.pipe';

@Component({
  selector: 'kendo-grid-multi-select-primitive-filter',
  templateUrl: './kendo-grid-multi-select-primitive-filter.component.html'
})
export class KendoGridMultiSelectPrimitiveFilterComponent {
  @Input() public field: string;
  @Input() public filterService: FilterService;
  @Input() public filter: CompositeFilterDescriptor;
  @Input() public set data(newData: any[]) {
    if (newData) {
      const filteredData = this.filterData(newData);
      this.filterDataSource = this.getUniqueDataItems(filteredData);
    } else {
      this.filterDataSource = [];
    }
  }

  public readonly filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains'
  };

  public filterDataSource: (string | number)[] = [];

  public allowCustomValues = false;

  private _collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
    usage: 'sort'
  });

  public constructor(
    private readonly propertyPathPipe: PropertyPathPipe
  ) { }

  public valueChange(values: (string | number)[]): void {
    this.filterService.filter({
      filters: values.map(value => ({
        field: this.field,
        operator: 'eq',
        value: value
      })),
      logic: 'or'
    });
  }

  public getFilterValue(): (string | number)[] {
    return flattenKendoFilter(this.filter).map(filter => {
      const filterValue = filter.value;
      const predefinedFilterValue = this.filterDataSource.find(dataItem => dataItem === filterValue);
      return predefinedFilterValue ? predefinedFilterValue : filterValue;
    });
  }

  protected filterData(data: any[]): any[] {
    return data;
  }

  private getUniqueDataItems(data: any[]): (string | number)[] {
    return data
      .mapFilter(
        item => this.propertyPathPipe.transform(item, this.field) as any,
        value => !(value === undefined || value === null)
      )
      .unique()
      .sort((value1, value2) => this._collator.compare(value1, value2));
  }
}
