import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ActionType } from '@enerkey/clients/attachments';
import { OverviewReportActionsCommentsBaseComponent } from '../overview-report-actions-comments-base.component';

@Component({
  selector: 'overview-report-actions:not(p)',
  templateUrl: '../overview-report-actions-comments-base.component.html',
  styleUrls: ['../overview-report-actions-comments-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewReportActionsComponent extends OverviewReportActionsCommentsBaseComponent {
  public readonly titleTranslationKey = 'FACILITIES_REPORT.OVERVIEW.ACTIONS';
  public readonly iconClass = 'fa fa-comment';
  protected newActionType = ActionType.ES;
  protected actionsStateName = 'energy-management.actions';
}
