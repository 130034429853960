import { __setupObservableTesting } from './observable-matchers';
import { __setupControlValueAccessorTesting } from './cva-matchers';

export {
  __setupObservableTesting as setupObservableTesting,
  __setupControlValueAccessorTesting as setupControlValueAccessorTesting,
};

/**
 * Returns a custom matcher for `toEqual` that compares numbers with given precision.
 *
 * @example jasmine.addCustomEqualityTester(getNumberPrecisionMatcher(0.00001));
 */
export function getNumberPrecisionMatcher(precision: number): jasmine.CustomEqualityTester {
  return function numberPrecisionMatcher(a: unknown, b: unknown): boolean | void {
    if (typeof a === 'number' && typeof b === 'number') {
      return Math.abs(a - b) < precision;
    }
  };
}
