import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';

import { ReportingSearchParams } from '../shared/reporting-search-params';
import { QuantityService } from '../../../shared/services/quantity.service';

@Pipe({ name: 'shouldShowQuantitySection' })
export class ShouldShowQuantitySectionPipe implements PipeTransform {
  public constructor(private quantityService: QuantityService) {
  }

  public transform(
    quantityId: Quantities,
    params: ReportingSearchParams,
    isNormalizedSection: boolean
  ): Observable<boolean> {
    return isNormalizedSection
      ? this.shouldShowNormalizedSection(quantityId, params)
      : this.shouldShowMeasuredSection(quantityId, params);
  }

  private shouldShowMeasuredSection(quantityId: Quantities, params: ReportingSearchParams): Observable<boolean> {
    if (params.measured) {
      return of(true);
    }
    return this.quantityService.isQuantityNormalized(quantityId).pipe(
      map(normalization => !normalization)
    );
  }

  private shouldShowNormalizedSection(quantityId: Quantities, params: ReportingSearchParams): Observable<boolean> {
    if (!params.normalized) {
      return of(false);
    }
    return this.quantityService.isQuantityNormalized(quantityId);
  }
}
