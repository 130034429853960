import { StateProvider } from '@uirouter/angularjs';

import facilitiesTemplateModule from 'raw-loader!./templates/facilities.html';
import facilitiesGridTemplateModule from 'raw-loader!./templates/facilities-grid.html';
import facilitiesReportTemplate from 'raw-loader!./templates/facilities-report.html';
import facilitiesReportSumTemplateModule from 'raw-loader!./templates/facilities-report-sum.html';
import facilitiesReportComparisonTemplateModule from 'raw-loader!./templates/facilities-report-comparison.html';

import * as sidebarConstant from './constants/er-sidebar.constant';
import { Service } from '../../constants/service';
import { ErReportType } from './constants/er-report-type';
import { yearlyTrendReportState } from './energy-reporting.states';
import { EnerkeyNg1StateDeclaration, getUIState } from '../../shared/routing';

config.$inject = ['$translatePartialLoaderProvider', '$stateProvider', '$provide'];

type ErStateDeclaration = EnerkeyNg1StateDeclaration & {
  data?: {
    reportType?: ErReportType;
  };
}

const energyReportingState: ErStateDeclaration = {
  name: 'facilities',
  parent: 'index',
  url: '/facilities',
  params: {
    facilityId: { array: true, dynamic: true },
    quantityId: { array: true, dynamic: true },
    series: { dynamic: true, value: {} },
    unitKey: { value: 'Default', dynamic: true },
    changeType: { value: 'relative', dynamic: true },
    optionsInstanceName: 'er-report',
    modalParams: { dynamic: true, value: null }
  },
  views: {
    'app@': {
      template: facilitiesTemplateModule,
      controller: 'FacilitiesController'
    }
  },
  data: {
    auth: {
      services: [Service.EnergyReporting]
    },
    translationKey: 'FACILITIES.FACILITIES'
  },
  redirectTo: 'facilities.grid'
};

const erTopbarState = getUIState(energyReportingState);

// sidebar items and links
const facilitiesSidebarItems = sidebarConstant.FACILITIES_ITEMS;

// routes
/* NOTE:
  by defining child states to 'facilities' or 'modal' that uses FacilitiesController, the array typed state
  params need to be explicitly set always on state transitions, otherwise they will be set to empty arrays
*/
const energyReportingStates: { [key: string]: ErStateDeclaration } = {
  'facilities': energyReportingState,
  'facilities.grid': {
    url: '/grid/?ReportType&RealEstateId&EnegiaId',
    params: {
      gridState: { dynamic: true, value: null }
    },
    views: {
      'report@facilities': {
        template: facilitiesGridTemplateModule
      },
    },
    data: {
      export: {
        enabled: true,
        fileNameKey: 'FACILITIES.FACILITIES'
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  },
  'facilities.report': {
    url: '/report/',
    params: {
      isEnegiaIdShownInReportArea: true,
      sections: { array: true }
    },
    views: {
      'report@facilities': {
        template: facilitiesReportTemplate,
        controller: 'ReportComponentAdapterController',
        controllerAs: 'vm',
      },
    },
    data: {
      export: {
        enabled: true,
        askPrintOrExcel: true,
        exportType: 'server'
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  },
  'facilities.report-sum': {
    url: '/report/sum/',
    views: {
      'report@facilities': {
        template: facilitiesReportSumTemplateModule,
        controller: 'FacilitiesReportSumController'
      },
    },
    data: {
      export: {
        enabled: true,
        fileNameKey: 'FACILITIES.SUM_REPORT'
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  },
  'facilities.report-trend': {
    url: '/report/trend/',
    params: {
      sections: { array: true },
      view: null
    },
    views: {
      'report@facilities': {
        template: facilitiesReportTemplate,
        controller: 'ReportComponentAdapterController',
        controllerAs: 'vm'
      },
      'report-settings@facilities': {
        template: '<report-trend-settings></report-trend-settings>'
      }
    },
    data: {
      reportType: ErReportType.TREND_REPORT,
      export: {
        enabled: true,
        fileNameKey: 'FACILITIES.TREND_REPORT',
        askPrintOrExcel: true
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  },
  'facilities.report-trend-sum': {
    url: '/report/trend/sum/',
    params: {
      view: null
    },
    views: {
      'report@facilities': {
        template: facilitiesReportSumTemplateModule,
        controller: 'FacilitiesReportSumController'
      },
      'report-settings@facilities': {
        template: '<report-trend-settings></report-trend-settings>'
      }
    },
    data: {
      reportType: ErReportType.TREND_REPORT,
      export: {
        enabled: true,
        fileNameKey: 'FACILITIES.TREND_REPORT'
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  },
  'facilities.report-comparison': {
    url: '/report/comparison/',
    params: {
      sections: { array: true },
      view: null
    },
    views: {
      'report@facilities': {
        template: facilitiesReportComparisonTemplateModule,
        controller: 'FacilitiesReportComparisonController'
      },
    },
    data: {
      export: {
        enabled: true,
        fileNameKey: 'FACILITIES.COMPARISON_REPORT'
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  },
  'facilities.report-forecast': {
    url: '/report/forecast/',
    params: {
      sections: { array: true },
      view: null
    },
    views: {
      'report@facilities': {
        template: facilitiesReportTemplate,
        controller: 'ReportComponentAdapterController',
        controllerAs: 'vm'
      },
      'report-settings@facilities': {
        template: '<report-forecast-settings></report-forecast-settings>'
      }
    },
    data: {
      reportType: ErReportType.FORECAST_REPORT,
      export: {
        enabled: true,
        fileNameKey: 'FACILITIES.FORECAST_REPORT'
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  },
  'facilities.report-forecast-sum': {
    url: '/report/forecast/sum/',
    params: {
      sections: { array: true },
      view: null
    },
    views: {
      'report@facilities': {
        template: facilitiesReportSumTemplateModule,
        controller: 'FacilitiesReportSumController'
      },
      'report-settings@facilities': {
        template: '<report-forecast-settings></report-forecast-settings>'
      }
    },
    data: {
      reportType: ErReportType.FORECAST_REPORT,
      export: {
        enabled: true,
        fileNameKey: 'FACILITIES.FORECAST_REPORT'
      },
      bookmark: {
        enabled: true,
        refresh: true
      },
      sidebar: {
        items: facilitiesSidebarItems
      }
    }
  }
};

export { erTopbarState };

function config(
  $translatePartialLoaderProvider: any,
  $stateProvider: StateProvider,
  $provide: angular.auto.IProvideService
): void {
  // translates
  $translatePartialLoaderProvider.addPart('energy-reporting');

  // create value and states to stateProvider
  $provide.value(
    'energyReportingStates',
    {
      ...energyReportingStates,
      [yearlyTrendReportState.name]: yearlyTrendReportState,
    }
  );
  Object.keys(energyReportingStates).forEach(stateName => {
    $stateProvider.state(stateName, energyReportingStates[stateName]);
  });
}

export default config;
