<form [formGroup]="form">
  <input
    type="checkbox"
    formControlName="facilityName"
    *labelWrap="'FACILITY_NAME' | translate"
  />
  <input
    type="checkbox"
    formControlName="meterId"
    *labelWrap="'METER_ID' | translate"
  />
  <input
    type="checkbox"
    formControlName="meteringPointName"
    *labelWrap="'METER_NAME' | translate"
  />
  <input
    type="checkbox"
    formControlName="usageplaceNumber"
    *labelWrap="'USAGE_PLACE_NUMBER' | translate"
  />
  <input
    type="checkbox"
    formControlName="sellerUsageplaceNumber"
    *labelWrap="'SELLER_USAGE_PLACE_NUMBER' | translate"
  />
  <input
    type="checkbox"
    formControlName="protocolCode"
    *labelWrap="'PROTOCOL_CODE' | translate"
  />
  <input
    type="checkbox"
    formControlName="eanCode"
    *labelWrap="'EAN_CODE' | translate"
  />
</form>
