import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Host,
  Injector,
  Input,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ColumnBase } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';

import { Roles } from '../../../../modules/admin/constants/roles';
import { UserService } from '../../../../services/user-service';
import { FacilityService } from '../../../services/facility.service';
import { CustomColumnGroupBase, ManualColumnDefinition } from '../custom-column-group-base/custom-column-group-base';

@Component({
  selector: 'facility-properties-column-group',
  templateUrl: './facility-properties-column-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: ColumnBase,
    useExisting: forwardRef(() => FacilityPropertiesColumnGroupComponent)
  }],
})
export class FacilityPropertiesColumnGroupComponent extends CustomColumnGroupBase {

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('facilityField') public propertyField: string;

  public localeNames: Readonly<Record<number, string>> = {};
  public buildingClassNames: Readonly<Record<number, string>> = {};

  /** Column definitions indexed by field */
  protected columnDefinitions: Record<string, ManualColumnDefinition>;

  @ViewChild('localeIdTemplate', { read: TemplateRef })
  private readonly _localeIdTemplate: TemplateRef<unknown>;

  @ViewChild('localeIdFilter', { read: TemplateRef })
  private readonly _localeIdFilterTemplate: TemplateRef<unknown>;

  @ViewChild('buildingClassIdTemplate', { read: TemplateRef })
  private readonly _buildingClassIdTemplate: TemplateRef<unknown>;

  @ViewChild('buildingClassIdFilter', { read: TemplateRef })
  private readonly _buildingClassIdFilterTemplate: TemplateRef<unknown>;

  public constructor(
    userService: UserService,
    facilityService: FacilityService,
    translateService: TranslateService,
    viewContainerRef: ViewContainerRef,
    injector: Injector,
    @Optional() @SkipSelf() @Host() parent: ColumnBase
  ) {
    super(translateService, viewContainerRef, injector, parent);

    this.title = translateService.instant('FACILITY_INFO');
    this.columnDefinitions = this.getColumnDefs(userService);

    facilityService.locales$.pipe(takeUntil(super.destroy$))
      .subscribe(result => {
        this.localeNames = result.toRecord(x => x.id, x => x.timeZone);
      });

    facilityService.buildingClasses$.pipe(takeUntil(super.destroy$))
      .subscribe(result => {
        this.buildingClassNames = result.toRecord(x => x.id, x => x.translation);
      });
  }

  private getColumnDefs(userService: UserService): Record<string, ManualColumnDefinition> {
    return {
      ['id']: {
        title: 'FACILITY_ID',
        width: 60,
        hidden: true,
        visiblefn: () => userService.hasRole(Roles.HAS_ACCESS_TO_ALL_PROFILES),
      },
      ['displayName']: {
        title: 'FACILITY_NAME',
        width: 150,
      },
      ['enegiaId']: {
        title: 'ENEGIA_ID',
        width: 80,
      },
      ['externalFacilityId']: {
        title: 'FACILITYINFORMATION.EXTERNALFACILITYID',
        width: 100,
        hidden: true,
      },
      ['customerSpecifiedIdentifier']: {
        title: 'FACILITYINFORMATION.REALESTATEID',
        width: 150,
        hidden: true,
      },
      ['addresses.0.streetAddress']: {
        title: 'FACILITYINFORMATION.STREETADDRESS',
        width: 160,
        hidden: true,
      },
      ['addresses.0.postalCode']: {
        title: 'FACILITYINFORMATION.POSTALCODE',
        width: 120,
        hidden: true,
      },
      ['addresses.0.city']: {
        title: 'FACILITYINFORMATION.CITY',
        width: 130,
        hidden: true,
      },
      ['addresses.0.country']: {
        title: 'FACILITYINFORMATION.COUNTRY',
        width: 130,
        hidden: true,
      },
      ['buildingClassId']: {
        title: 'FACILITYINFORMATION.FACILITYTYPE',
        width: 120,
        hidden: true,
        cellTemplatefn: () => this._buildingClassIdTemplate,
        filterMenuTemplatefn: () => this._buildingClassIdFilterTemplate,
      },
      ['customBuildingClasses.0.name']: {
        title: 'FACILITYINFORMATION.CUSTOMBUILDINGCLASS',
        width: 120,
        hidden: true,
      },
      ['geographicalArea']: {
        title: 'FACILITYINFORMATION.GEOGRAPHICALAREA',
        width: 150,
        hidden: true,
      },
      ['localeId']: {
        title: 'FACILITYINFORMATION.LOCALE',
        width: 150,
        hidden: true,
        cellTemplatefn: () => this._localeIdTemplate,
        filterMenuTemplatefn: () => this._localeIdFilterTemplate,
      },
      ['customerSetDescription']: {
        title: 'FACILITYINFORMATION.CUSTOMERSETDESCRIPTION',
        width: 200,
        hidden: true,
      },
      ['enegiaDescription']: {
        title: 'FACILITYINFORMATION.INTERNALDESCRIPTION',
        width: 200,
        hidden: true,
      },
      ['businessIdentityCodeId']: {
        title: 'FACILITYINFORMATION.BUSINESSIDENTITYCODE',
        width: 150,
        filter: 'numeric',
        hidden: true,
      },
    };
  }
}

