<modal-view-header [headingText]="modalHeaderText"></modal-view-header>
<div class="modal-body">
  <kendo-grid
    [kendoGridBinding]="meters$ | async"
    [loading]="loading$ | async"
    [sortable]="true"
    [filterable]="'menu'"
  >
    <kendo-grid-column
      [field]="'facility.enegiaId'"
      [title]="'ENEGIA_ID' | translate"
      [width]="100"
    ></kendo-grid-column>
    <kendo-grid-column
      [field]="'facility.name'"
      [title]="'FACILITY_NAME' | translate"
      [width]="250"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-column="column"
      >
        <a (click)="openFacilityReport(dataItem)">
          {{ dataItem | propertyPath:column.field }}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      [field]="'meter.id'"
      [title]="'METER_ID' | translate"
      [width]="100"
    ></kendo-grid-column>
    <kendo-grid-column
      [field]="'meter.name'"
      [title]="'METER_NAME' | translate"
      [width]="250"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-column="column"
      >
        <a (click)="openMeterReport(dataItem)">
          {{ dataItem | propertyPath:column.field }}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="isFacilityError"
      [field]="'faultBeginTime'"
      [title]="'ERROR_WIDGET.DEFECTS.FROM' | translate"
      format="yyyy-MM-dd HH:mm"
      filter="date"
      [width]="120"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="isFacilityError"
      [field]="'faultEndTime'"
      [title]="'ERROR_WIDGET.DEFECTS.TO' | translate"
      format="yyyy-MM-dd HH:mm"
      filter="date"
      [width]="120"
    >
    </kendo-grid-column>
    <kendo-grid-column
      *ngIf="isFacilityError"
      [field]="'faultLengthInHours'"
      [title]="'ERROR_WIDGET.DEFECTS.DURATION' | translate"
      format="#"
      filter="numeric"
      [width]="80"
    >
    </kendo-grid-column>
    <kendo-grid-column
      [field]="'quantityName'"
      [title]="'QUANTITY' | translate"
      [width]="150"
    >
    </kendo-grid-column>
  </kendo-grid>
</div>
