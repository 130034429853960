<ek-dropdown
  [width]="580"
  class="latest-actions-container"
  [ngClass]="(moduleType === 'ines') ? 'p-2' : ''"
>
  <button
    *ekDropdownToggle="let toggle"
    (click)="toggle()"
    class="button"
    [ngClass]="(moduleType === 'ines') ? 'button--no-padding button--link button--no-focus' : 'icon'"
    [title]="(moduleType !== 'ines') ? (title | translate) : ''"
    [ngfTooltip]="(moduleType === 'ines') ? (title | translate) : ''"
    [placement]="'bottom'"
  >
    <i
      *ngIf="actionType === 'action'; else commentsIcon"
      [class]="actions?.length > 0 ? 'fas fa-comment-alt' : 'icon-icon-notes-add'"
    ></i>
    <ng-template #commentsIcon>
      <i class="fa fa-comment"></i>
    </ng-template>
  </button>
  <div class="actions-icon-container latest-actions-dropdown">
    <div class="title-row">{{ title | translate }}</div>
    <div class="table-header">
      {{ 'FACILITIES_REPORT.LATEST_ACTIONS_POPUP_TABLE_TITLE' | translate }}
    </div>
    <table class="actions-table">
      <tr>
        <th class="updated">
          {{ 'FACILITIES_REPORT.ACTIONS_POPUP_UPDATED' | translate }}
        </th>
        <th class="reported-description">
          {{ 'ACTIONS.REPORTEDDESCRIPTION' | translate }}
        </th>
        <th class="action-investigation-lights no-mobile">
          {{ 'ACTIONS.INVESTIGATION' | translate }}
        </th>
      </tr>
      <tr *ngFor="let action of actions">
        <td>{{ action.updatedAt | kendoDate:'d' }}</td>
        <td class="ellipsis" title="{{ action.reportedDescription }}">
          <span class="reported-description">{{ action.reportedDescription }}</span>
        </td>
        <td class="no-mobile">
          <investigation-status [status]="action.investigation"></investigation-status>
        </td>
      </tr>
    </table>
  </div>
  <div class="actions-to-perform" *ngIf="moduleType === 'ines' || moduleType === 'report'">
    <div class="action-to-perform">
      <button
        class="button button--link button--external-link"
        (click)="goToEnergyManagement()"
      >
      {{ 'FACILITIES_REPORT.GO_TO_ACTIONS' | translate }}
      </button>
    </div>
    <div class="action-to-perform">
      <button class="button" (click)="toAction()" >
        <i class="fa fa-plus"></i>
        {{ 'FACILITIES_REPORT.ADD_NEW' | translate }}
      </button>
    </div>
  </div>
</ek-dropdown>
