import { MeterHierarchyTypes } from '../constants/meter-hierarchy-types';

const $inject = ['utils'];

class ManualQaMeterHierarchyService {
  constructor(utils) {
    this.utils = utils;
  }

  /**
   * Returns meter hierarchy types (all - main - sub)
   *
   * * @returns {Array}
   */
  getMeterHierarchyTypes() {
    return [
      {
        id: MeterHierarchyTypes.ALL,
        name: this.utils.localizedString('MQA.SEARCH.ADDITIONAL_DEFINING.ALLMETERS')
      },
      {
        id: MeterHierarchyTypes.MAIN,
        name: this.utils.localizedString('MQA.SEARCH.ADDITIONAL_DEFINING.MAINMETERS')
      },
      {
        id: MeterHierarchyTypes.SUB,
        name: this.utils.localizedString('MQA.SEARCH.ADDITIONAL_DEFINING.SUBMETERS')
      },
      {
        id: MeterHierarchyTypes.NOT_FLOATING,
        name: this.utils.localizedString('MQA.SEARCH.ADDITIONAL_DEFINING.NOT_FLOATING')
      },
      {
        id: MeterHierarchyTypes.FLOATING,
        name: this.utils.localizedString('MQA.SEARCH.ADDITIONAL_DEFINING.FLOATING')
      }
    ];
  }
}

ManualQaMeterHierarchyService.$inject = $inject;

export default ManualQaMeterHierarchyService;
