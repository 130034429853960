import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, map, Observable, shareReplay, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { ModalBase, ModalOptions, NgfActiveModal } from '@enerkey/foundation-angular';
import { ReportSummary, Roadmap, Scenario, Scope, SustainabilityClient } from '@enerkey/clients/sustainability';
import { getStringEnumValues } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

import { ComboItem } from '../../../../shared/ek-inputs/ek-combo/ek-combo.component';
import { ProfileService } from '../../../../shared/services/profile.service';
import { ToasterService } from '../../../../shared/services/toaster.service';
import { DialogService } from '../../../../shared/services/dialog.service';
import { TargetScenarioNamePipe } from '../../pipes/target-scenario-name.pipe';

type RoadmapEditForm = {
  name: FormControl<string>;
  scenario: FormControl<Scenario>;
  baseYear: FormControl<number>;
  targetYear: FormControl<number>;
  baseReportId: FormControl<number>;
  baseValues: FormGroup;
  targetValues: FormGroup;
}

@Component({
  selector: 'target-edit-modal',
  templateUrl: './target-edit-modal.component.html',
  styleUrls: ['./target-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TargetScenarioNamePipe]
})
@ModalOptions({
  size: 'small'
})
export class TargetEditModalComponent extends ModalBase implements OnDestroy, OnInit {

  public existingRoadmap: Roadmap;
  public targetYearErrorMsg: { min: string, max: string } = { min: '', max: '' };

  public readonly formGroup: FormGroup<RoadmapEditForm>;
  public readonly baseValueOption: FormControl<'useReport' | 'manual'>;
  public readonly scenarioOptions: ComboItem<Scenario>[];
  public readonly scopes = getStringEnumValues(Scope);

  public useReport$: Observable<boolean>;
  public readonly loading$: Observable<boolean>;

  private readonly _loading$ = new LoadingSubject();
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    currentModal: NgfActiveModal,
    private readonly dialogService: DialogService,
    private readonly profileService: ProfileService,
    private readonly susClient: SustainabilityClient,
    private readonly scenarioNamePipe: TargetScenarioNamePipe,
    private readonly toasterService: ToasterService
  ) {
    super(currentModal);
    this.loading$ = this._loading$.asObservable();

    this.scenarioOptions = getStringEnumValues(Scenario).map<ComboItem<Scenario>>(scenario => ({
      text: this.scenarioNamePipe.transform(scenario),
      value: scenario,
    }));

    this.baseValueOption = new FormControl('useReport');

    this.formGroup = new FormGroup<RoadmapEditForm>({
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(64)]),
      scenario: new FormControl(Scenario._15C, [Validators.required]),
      baseYear: new FormControl(new Date().getFullYear() - 1, [
        Validators.required,
        Validators.max(new Date().getFullYear()),
        Validators.min(2000)
      ]),
      targetYear: new FormControl(null, [Validators.required]),
      baseReportId: new FormControl(null),
      baseValues: new FormGroup(this.scopes.reduce((a, scope) => ({ ...a, [scope]: new FormControl(null) }), {})),
      targetValues: new FormGroup(this.scopes.reduce((a, scope) => ({ ...a, [scope]: new FormControl(null) }), {})),
    });
  }

  public ngOnInit(): void {
    if (this.existingRoadmap) {
      this.formGroup.setValue({
        name: this.existingRoadmap.name,
        scenario: this.existingRoadmap.scenario,
        baseYear: this.existingRoadmap.baseYear,
        targetYear: this.existingRoadmap.targetYear,
        baseReportId: this.existingRoadmap.baseReportId ?? null,
        baseValues: this.scopes.reduce((a, scope) => ({
          ...a,
          [scope]:
            Number.isFinite(this.existingRoadmap.baseValues?.[scope])
              ? Math.round(this.existingRoadmap.baseValues?.[scope])
              : null
        }), {}),
        targetValues: this.scopes.reduce((a, scope) => ({
          ...a,
          [scope]:
            Number.isFinite(this.existingRoadmap.targetValues?.[scope])
              ? Math.round(this.existingRoadmap.targetValues?.[scope])
              : null
        }), {}),
      });

      if (!this.existingRoadmap.baseReportId) {
        this.baseValueOption.patchValue('manual');
      }
    }

    this.useReport$ = this.baseValueOption.valueChanges.pipe(
      tap(option => {
        if (option === 'manual') {
          this.formGroup.patchValue({ baseReportId: null });
        }
      }),
      map(option => option === 'useReport'),
      startWith(!(this.existingRoadmap && !this.existingRoadmap.baseReportId)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    combineLatest([
      this.formGroup.controls.scenario.valueChanges.pipe(
        startWith(this.formGroup.controls.scenario.value)
      ),
      this.formGroup.controls.baseYear.valueChanges.pipe(
        startWith(this.formGroup.controls.baseYear.value)
      )
    ]).pipe(
      takeUntil(this._destroy$)
    ).subscribe(
      ([scenario, baseYear]) => this.setTargetYearLimits(scenario, baseYear)
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._loading$.complete();
  }

  public patchBaseValues(report: ReportSummary): void {
    this.formGroup.patchValue({
      baseReportId: report.id,
      baseYear: report.year,
      baseValues: this.scopes.reduce((a, scope) => ({
        ...a,
        [scope]: Number.isFinite(report.emissions?.[scope])
          ? Math.round(report.emissions?.[scope])
          : null
      }), {})
    });
  }

  public submit(): void {
    let request$: Observable<unknown>;
    const data = this.formGroup.value as Roadmap;

    if (this.existingRoadmap) {
      data.id = this.existingRoadmap.id;
      data.currentYear = this.existingRoadmap.currentYear;
      data.currentValues = this.existingRoadmap.currentValues;
      data.currentReportId = this.existingRoadmap.currentReportId;
      request$ = this.profileService.profileId$.pipe(
        switchMap(id => this.susClient.updateRoadmaps(id, [data]))
      );
    } else {
      data.currentYear = null;
      data.currentReportId = null;
      request$ = this.profileService.profileId$.pipe(
        switchMap(id => this.susClient.addRoadmaps(id, [data]))
      );
    }

    request$.pipe(indicate(this._loading$)).subscribe({
      next: () => {
        this.toasterService.success('SUSTAINABILITY.TARGETS.TARGET_SAVED');
        super.closeModal();
      },
      error: () => this.toasterService.generalError('SAVE', 'TARGET')
    });
  }

  public removeRoadmap(): void {
    this.dialogService.getConfirmationModal({
      title: 'CONFIRM_DELETE',
      text: 'SUSTAINABILITY.TARGETS.CONFIRM_DELETE',
      translate: true,
      isDelete: true,
    })
      .pipe(
        switchMap(() => this.profileService.profileId$.pipe(
          switchMap(id => this.susClient.deleteRoadmaps(id, [this.existingRoadmap.id]).pipe(
            indicate(this._loading$)
          ))
        ))
      ).subscribe({
        next: () => {
          this.toasterService.success('SUSTAINABILITY.TARGETS.TARGET_REMOVED');
          super.closeModal();
        },
        error: () => this.toasterService.generalError('DELETE', 'TARGET')
      });
  }

  public cancelEdit(): void {
    super.dismissModal();
  }

  private setTargetYearLimits(scenario: Scenario, baseYear: number): void {
    if (scenario === Scenario.None) {
      this.formGroup.controls.targetYear.setValidators([
        Validators.required,
        Validators.min(baseYear + 1),
        Validators.max(baseYear + 50)
      ]);
      this.targetYearErrorMsg = {
        min: 'SUSTAINABILITY.TARGETS.ERROR.TARGETYEAR_FROM_BASE',
        max: 'SUSTAINABILITY.TARGETS.ERROR.TARGETYEAR_TOO_LONG'
      };
    } else {
      // SBTi requires target year to be 5-15 years from base year
      this.formGroup.controls.targetYear.setValidators([
        Validators.required,
        Validators.min(baseYear + 5),
        Validators.max(baseYear + 15)
      ]);
      this.targetYearErrorMsg = {
        min: 'SUSTAINABILITY.TARGETS.ERROR.TARGETYEAR_RANGE_SBTI',
        max: 'SUSTAINABILITY.TARGETS.ERROR.TARGETYEAR_RANGE_SBTI'
      };
    }
    this.formGroup.controls.targetYear.updateValueAndValidity();
  }

}
