import EmInputNumberController from './em-input-number-controller';
import emInputNumberTemplate   from 'raw-loader!./em-input-number.html';

export default {
  template: emInputNumberTemplate,
  controller: EmInputNumberController,
  controllerAs: 'vm',
  bindings: {
    config: '<',
    onChange: '<'
  }
};
