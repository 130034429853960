<modal-view-header [headingText]="'SUSTAINABILITY.GRI.IMPORT_CONSUMPTIONS' | translate"></modal-view-header>

<div class="modal-body">
  <div class="search-container">
    <div class="search-quantity">
      <quantity-dropdown
        *labelBefore="'QUANTITY' | translate"
        [formControl]="quantityControl"
        [quantities]="quantities$ | async"
        [loading]="loading$ | async"
        [disableWhenLoading]="true"
        [grouped]="true"
      ></quantity-dropdown>
    </div>

    <div class="search-facility">
      <facility-multi-select
        *labelBefore="'SELECT_FACILITY' | translate"
        [formControl]="facilityControl"
      ></facility-multi-select>
    </div>

    <div class="search-meter">
      <gri-meter-select
        *labelBefore="'FACILITIES_REPORT.CHOOSE_METERS' | translate"
        [formControl]="metersControl"
        [filterable]="true"
        [meters]="meters$ | async"
      >
        <ng-template
          ekComboItemTemplate
          let-dataItem
        >
          {{ dataItem.name }} (ID: {{ dataItem. id }})
        </ng-template>
      </gri-meter-select>
    </div>

    <div class="search-button">
      <button
        kendoButton
        type="button"
        [size]="'large'"
        [primary]="true"
        [disabled]="!metersControl.value?.length || (loading$ | async)"
        (click)="searchConsumptions()"
      >
        {{ 'SEARCH' | translate }}
      </button>
    </div>
  </div>

  <gri-metadata-grid
    [metadata]="metadata$ | async"
    [loading]="loading$ | async"
    [quantityId]="dataQuantityId$ | async"
    (isValidChanged)="updateValidator($event)"
    ></gri-metadata-grid>
  </div>

<div class="modal-footer">
  <div class="modal-footer-buttons">
    <div
      *ngIf="metadata$ | async"
      class="details-selector float-left"
    >
      <strong>{{ 'SUSTAINABILITY.GRI.CATEGORY' | translate }}:</strong>
      <ek-filterable-combo
        [items]="categoryOptions$ | async"
        [formControl]="categoryControl"
        [loading]="loading$ | async"
        [virtual]="true"
        [filterable]="true"
        [noTranslate]="true"
      ></ek-filterable-combo>
    </div>

    <div class="float-right">
      <button
        class="button button--primary"
        type="button"
        (click)="createRows()"
        [disabled]="!isValid || !categoryControl.valid"
        [loading]="loading$ | async"
      >
        {{ 'SUSTAINABILITY.GRI.ADD_ROWS' | translate }}
      </button>
      <button
        class="button button--secondary"
        type="button"
        (click)="dismiss()"
      >
        {{ 'MODALS.CLOSE' | translate }}
      </button>
    </div>
  </div>
</div>
