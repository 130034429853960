import _ from 'lodash';

import { FacilityPropertyEnums, HeatingMethod, WaterHeatingEnergyCalculation } from '@enerkey/clients/facility';
import { getNumericEnumValues } from '@enerkey/ts-utils';

import AdminSpreadsheetConstants from './admin-spreadsheet-constants';
import { registerCustomSpreadsheetEditor } from '../../../../shared/ek-kendo/kendo.functions';
import {
  facilityPropertyEnumTranslations,
  shareOfTemperatureFixingTranslations
} from '../../../../constants/facility.constant';

const propertyEditorName = 'propertyEditor';

AdminSpreadsheetService.$inject = [
  'SpreadsheetService', 'AdminSpreadsheetTranslationsService',
  'facilities', 'utils',
  '$q'
]; 

export default function AdminSpreadsheetService(
  SpreadsheetService, AdminSpreadsheetTranslationsService,
  facilities, utils,
  $q
) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const vm = this;

  vm.colors = SpreadsheetService.getColors();
  vm.translations = AdminSpreadsheetTranslationsService.getTranslations();

  return {
    getSpreadsheetOptions: getSpreadsheetOptions,
    getCo2SpreadsheetOptions: getCo2SpreadsheetOptions,
    getCustomTypeSpreadsheetOptions: getCustomTypeSpreadsheetOptions
  };

  function getCustomTypeSpreadsheetOptions(facilityIds) {
    const deferred = $q.defer();
    getSpreadsheetOptions(facilityIds).then(result => {
      // change propertyId to customType validation
      result.sheets[0].rows.forEach((part, index, theArray) => {
        theArray[index].cells.splice(1, 1, getCustomTypeValidation());
      });
      // change propertyId to customType
      result.sheets[0].rows[0].cells.splice(1, 1, {
        value: vm.translations.shareOfTemperatureFixing.heading,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      });
      // Remove property types
      result.sheets.splice(1, 1);
      // Remove heating method types
      result.sheets.splice(2, 1);
      // Remove heating types
      result.sheets.splice(3, 1);
      // Add CustomTypeSheet
      result.sheets.push(getCustomTypeSheet());
      deferred.resolve(result);
    });
    return deferred.promise;
  }

  function getCo2SpreadsheetOptions(facilityIds) {
    const deferred = $q.defer();
    getSpreadsheetOptions(facilityIds).then(result => {
      // remove propertyId validation
      result.sheets[0].rows.forEach((part, index, theArray) => {
        delete theArray[index].cells[1].validation;
        delete theArray[index].cells[1].editor;
      });
      // change propertyId to QuantityId
      result.sheets[0].rows[0].cells.splice(1, 1, {
        value: vm.translations.quantityId.heading,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      });
      // Change properties to quantity help sheet
      result.sheets[1] = getQuantitySheet();
      // Remove heating types
      delete result.sheets[3];
      deferred.resolve(result);
    });
    return deferred.promise;
  }

  /**
   * Returns spreadsheet options for admin spreadsheet.
   *
   * @returns {*}
   */
  function getSpreadsheetOptions(facilityIds) {
    const deferred = $q.defer();

    vm.maxRowCount = Math.max(facilityIds.length, AdminSpreadsheetConstants.DEFAULT_ROW_COUNT);

    $q.all([getFirstSheet(), getPropertyDataSheet(), getFacilityInformationSheet(facilityIds),
      getFacilityHeatingTypes()]).then(result => {
      const spreadsheetOptions = angular.copy({
        toolbar: false,
        sheetsbar: false,
        activeSheet: vm.translations.sheets.inputPage,
        columns: 4,
        rows: Math.max(...result.map(sheet => sheet.rows.length), AdminSpreadsheetConstants.DEFAULT_ROW_COUNT),
        sheets: result
      });
      deferred.resolve(spreadsheetOptions);
    });

    return deferred.promise;
  }

  function getCustomTypeSheet() {
    const sheet = {
      name: vm.translations.sheets.shareOfTemperatureFixingPage.split(' ').join('_'),
      columns: [
        { width: 165 },
        { width: 165 }
      ],
      rows: [{
        cells: [
          { enable: false, value: 'Id' },
          { enable: false, value: 'Type' }
        ]
      }]
    };

    getNumericEnumValues(WaterHeatingEnergyCalculation).forEach(option => {
      sheet.rows.push({
        cells: [
          { value: option },
          { value: utils.localizedString(shareOfTemperatureFixingTranslations[option]) }
        ]
      });
    });

    return sheet;
  }

  function getQuantitySheet() {
    return {
      name: vm.translations.sheets.quantityPage,
      columns: [
        { width: 900 }
      ],
      rows: [{
        cells: [{
          value: vm.translations.quantityId.message,
          enable: false
        }]
      }]
    };
  }

  function getFacilityInformationSheet(facilityIds) {
    const deferred = $q.defer();
    const facilityData = {
      name: vm.translations.sheets.facilityPage,
      columns: [
        { width: 165 },
        { width: 165 }
      ],
      rows: [{
        cells: [{
          value: vm.translations.enegiaId.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.facilityName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }]
    };
    facilities.getFacilitiesDirect(facilityIds).then(result => {
      _.forEach(result, facility => {
        facilityData.rows.push({
          cells: [{
            type: 'number',
            value: parseInt(facility.enegiaId)
          }, { value: facility.displayName }]
        });
      });
      deferred.resolve(facilityData);
    });
    return deferred.promise;
  }

  function getPropertyDataSheet() {
    const deferred = $q.defer();
    const propertyData = {
      name: vm.translations.sheets.propertyPage,
      columns: [
        { width: 165 },
        { width: 165 },
        { width: 165 }
      ],
      rows: [{
        cells: [{
          value: vm.translations.propertyId.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.propertyName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.propertyType.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }]
    };

    facilities.getFacilityPropertyTypes().then(result => {
      result = result.filter(r => r.id !== FacilityPropertyEnums.ShareOfTemperatureFixing);
      const properties = result.map(property => ({
        value: property.id,
        text: `${property.translation} (${property.id})`
      }));
      registerCustomSpreadsheetEditor({
        editorName: propertyEditorName,
        data: properties,
        valueField: 'value',
        textField: 'text'
      });
      _.forEach(result, property => {
        propertyData.rows
          .push({ cells: [{ value: property.id }, { value: property.translation }, { value: property.type }] });
      });
      deferred.resolve(propertyData);
    });
    return deferred.promise;
  }

  function getFacilityHeatingTypes() {
    const deferred = $q.defer();

    const propertyData = {
      name: vm.translations.sheets.heatingTypesPage,
      columns: [
        { width: 165 },
        { width: 165 }
      ],
      rows: [{
        cells: [{
          value: vm.translations.heatingMethodName.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }, {
          value: vm.translations.heatingMethodId.heading,
          background: vm.colors.headerBackground,
          textAlign: 'center',
          color: vm.colors.default,
          enable: false
        }]
      }]
    };

    const heatingMethods = getNumericEnumValues(HeatingMethod)
      .map(heatingMethod => ({
        translation: utils.localizedString(facilityPropertyEnumTranslations.HeatingMethod[heatingMethod]),
        value: heatingMethod
      }))
      .sortBy('translation');
    heatingMethods.forEach(heatingMethod => {
      propertyData.rows.push({
        cells: [
          { value: heatingMethod.translation },
          { value: heatingMethod.value }
        ]
      });
    });

    deferred.resolve(propertyData);

    return deferred.promise;
  }

  function getHeaderCells() {
    return [
      {
        value: vm.translations.enegiaId.heading,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      },
      {
        value: vm.translations.propertyId.heading,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      },
      {
        value: vm.translations.validFrom.heading,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      },
      {
        value: vm.translations.value.heading,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      },
      {
        value: vm.translations.status.heading,
        background: vm.colors.headerBackground,
        textAlign: 'center',
        color: vm.colors.default,
        enable: false
      }
    ];
  }

  function getRows() {
    const rows = [];
    rows.push({ cells: getHeaderCells() });
    for (let i = 0; i < vm.maxRowCount; i++) {
      rows.push({ cells: getInputPageDataCells(i) });
    }
    return rows;
  }

  function getEnegiaIdValidation() {
    return {
      type: 'number',
      validation: {
        dataType: 'list',
        showButton: true,
        from: `${vm.translations.sheets.facilityPage }!A$2:A`,
        allowNulls: true,
        type: 'reject',
        titleTemplate: vm.translations.enegiaId.titleTemplate,
        messageTemplate: vm.translations.enegiaId.messageTemplate
      }
    };
  }

  function getPropertyIdValidation() {
    return {
      type: 'number',
      validation: {
        dataType: 'list',
        showButton: true,
        from: `${vm.translations.sheets.propertyPage }!A$2:A`,
        allowNulls: true,
        type: 'reject',
        titleTemplate: vm.translations.propertyId.titleTemplate,
        messageTemplate: vm.translations.propertyId.messageTemplate,
      },
      editor: propertyEditorName
    };
  }

  function getCustomTypeValidation() {
    return {
      type: 'number',
      validation: {
        dataType: 'list',
        showButton: true,
        from: `${vm.translations.sheets.shareOfTemperatureFixingPage.split(' ').join('_')}!A$2:A`,
        allowNulls: true,
        type: 'reject',
        titleTemplate: vm.translations.shareOfTemperatureFixing.title,
        messageTemplate: vm.translations.shareOfTemperatureFixing.message
      }
    };
  }

  function getDateValidation() {
    return {
      format: 'd.M.yyyy', // DateOfBirth
      validation: {
        dataType: 'date',
        comparerType: 'greaterThan',
        from: 'DATEVALUE("1.1.1900")',
        allowNulls: true,
        messageTemplate: vm.translations.validFrom.messageTemplate,
        showButton: true
      }
    };
  }

  function getValueValidation() {
    return { format: '@' };
  }

  function getStatusValidation() {
    return {
      enable: false,
    };
  }

  function getInputPageDataCells(row) {
    return [getEnegiaIdValidation(), getPropertyIdValidation(), getDateValidation(), getValueValidation(row),
      getStatusValidation()];
  }

  function getFirstSheet() {
    const deferred = $q.defer();
    deferred.resolve({
      name: vm.translations.sheets.inputPage,
      columns: [
        { width: 165 },
        { width: 165 },
        { width: 165 },
        { width: 165 },
        { width: 165 }
      ],
      rows: getRows()
    });
    return deferred.promise;
  }
}
