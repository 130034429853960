import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({ name: 'todate' })
export class ToDatePipe implements PipeTransform {

  public transform(value: string | number | Date | moment.Moment): Date {
    let date: Date = null;

    if (value instanceof Date) {
      date = value;
    } else if (this.isMoment(value)) {
      date = value.toDate();
    } else if (typeof value === 'number' || typeof value === 'string') {
      date = new Date(value);
    }

    if (!date || Number.isNaN(date.getTime())) {
      return null;
    }

    return date;
  }

  private isMoment(value: unknown): value is moment.Moment {
    return moment.isMoment(value);
  }
}
