export const actionClasses = {
  // Classes for basis == null
  null: [
    { id: null, key: 'ACTIONS.NOTSELECTED' },
    { id: 0, key: 'ACTIONS.ACTIONCLASS_0' },
    { id: 1, key: 'ACTIONS.ACTIONCLASS_1' },
    { id: 2, key: 'ACTIONS.ACTIONCLASS_2' },
    { id: 3, key: 'ACTIONS.ACTIONCLASS_3' },
    { id: 4, key: 'ACTIONS.ACTIONCLASS_4' },
    { id: 5, key: 'ACTIONS.ACTIONCLASS_5' },
    { id: 6, key: 'ACTIONS.ACTIONCLASS_6' },
    { id: 7, key: 'ACTIONS.ACTIONCLASS_7' },
    { id: 8, key: 'ACTIONS.ACTIONCLASS_8' },
    { id: 9, key: 'ACTIONS.ACTIONCLASS_9' },
    { id: 10, key: 'ACTIONS.ACTIONCLASS_10' },
    { id: 11, key: 'ACTIONS.ACTIONCLASS_11' },
    { id: 12, key: 'ACTIONS.ACTIONCLASS_12' },
    { id: 13, key: 'ACTIONS.ACTIONCLASS_13' },
    { id: 14, key: 'ACTIONS.ACTIONCLASS_14' },
    { id: 15, key: 'ACTIONS.ACTIONCLASS_15' },
    { id: 16, key: 'ACTIONS.ACTIONCLASS_16' },
    { id: 17, key: 'ACTIONS.ACTIONCLASS_17' },
    { id: 18, key: 'ACTIONS.ACTIONCLASS_18' },
    { id: 19, key: 'ACTIONS.ACTIONCLASS_19' },
    { id: 20, key: 'ACTIONS.ACTIONCLASS_20' },
    { id: 21, key: 'ACTIONS.ACTIONCLASS_21' },
    { id: 22, key: 'ACTIONS.ACTIONCLASS_22' },
    { id: 23, key: 'ACTIONS.ACTIONCLASS_23' }
  ],
  // Classes for basis == 0, Tekninen (TEK)
  0: [
    { id: null, key: 'ACTIONS.NOTSELECTED' },
    { id: 4, key: 'ACTIONS.ACTIONCLASS_4' },
    { id: 5, key: 'ACTIONS.ACTIONCLASS_5' },
    { id: 6, key: 'ACTIONS.ACTIONCLASS_6' },
    { id: 7, key: 'ACTIONS.ACTIONCLASS_7' },
    { id: 8, key: 'ACTIONS.ACTIONCLASS_8' },
    { id: 9, key: 'ACTIONS.ACTIONCLASS_9' },
    { id: 10, key: 'ACTIONS.ACTIONCLASS_10' },
    { id: 11, key: 'ACTIONS.ACTIONCLASS_11' },
    { id: 12, key: 'ACTIONS.ACTIONCLASS_12' },
    { id: 13, key: 'ACTIONS.ACTIONCLASS_13' },
    { id: 14, key: 'ACTIONS.ACTIONCLASS_14' },
    { id: 15, key: 'ACTIONS.ACTIONCLASS_15' },
    { id: 16, key: 'ACTIONS.ACTIONCLASS_16' },
    { id: 17, key: 'ACTIONS.ACTIONCLASS_17' },
    { id: 18, key: 'ACTIONS.ACTIONCLASS_18' },
    { id: 19, key: 'ACTIONS.ACTIONCLASS_19' },
    { id: 20, key: 'ACTIONS.ACTIONCLASS_20' },
    { id: 21, key: 'ACTIONS.ACTIONCLASS_21' },
    { id: 22, key: 'ACTIONS.ACTIONCLASS_22' },
    { id: 23, key: 'ACTIONS.ACTIONCLASS_23' }
  ],
  // Classes for basis == 1, Käyttötekninen (KTEK)
  1: [
    { id: null, key: 'ACTIONS.NOTSELECTED' },
    { id: 0, key: 'ACTIONS.ACTIONCLASS_0' },
    { id: 1, key: 'ACTIONS.ACTIONCLASS_1' },
    { id: 2, key: 'ACTIONS.ACTIONCLASS_2' },
    { id: 3, key: 'ACTIONS.ACTIONCLASS_3' },
    { id: 5, key: 'ACTIONS.ACTIONCLASS_5' },
    { id: 7, key: 'ACTIONS.ACTIONCLASS_7' },
    { id: 8, key: 'ACTIONS.ACTIONCLASS_8' },
    { id: 12, key: 'ACTIONS.ACTIONCLASS_12' },
    { id: 13, key: 'ACTIONS.ACTIONCLASS_13' },
    { id: 17, key: 'ACTIONS.ACTIONCLASS_17' },
    { id: 18, key: 'ACTIONS.ACTIONCLASS_18' },
    { id: 22, key: 'ACTIONS.ACTIONCLASS_22' },
    { id: 23, key: 'ACTIONS.ACTIONCLASS_23' }
  ]
};
