import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ConnectionType, FacilityLocalPowerConnection, LocalPowerCompany, SustainabilityClient }
  from '@enerkey/clients/sustainability';

import { UserService } from '../../../../services/user-service';
import { Service } from '../../../../constants/service';
import { ToasterService } from '../../../../shared/services/toaster.service';

@Component({
  selector: 'facility-localpower',
  templateUrl: './facility-localpower.component.html',
  styleUrls: ['./facility-localpower.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacilityLocalpowerComponent implements OnInit {
  @Input() public facilityId: number;

  public serviceEnabled: boolean = false;
  public usesAddress: boolean = false;
  public usesCompany: boolean = false;
  public existingCompanyNetwork: string = '';

  public facilityConnection$: Observable<FacilityLocalPowerConnection>;
  public readonly searchResults$: Observable<LocalPowerCompany[]>;
  private readonly companySearch$ = new Subject<string>();

  public constructor(
    private readonly sustainabilityClient: SustainabilityClient,
    private readonly toasterService: ToasterService,
    private readonly userService: UserService
  ) {
    this.serviceEnabled = this.userService.hasService(Service.LocalPowerApi);
    this.searchResults$ = this.companySearch$.pipe(
      switchMap(s => this.sustainabilityClient.searchCompanies(s))
    );
  }

  public ngOnInit(): void {
    if (!this.serviceEnabled) {
      return;
    }

    this.facilityConnection$ = this.sustainabilityClient
      .getLocalPowerFacilityConnection(this.facilityId)
      .pipe(catchError(() => of(new FacilityLocalPowerConnection())));

    this.facilityConnection$
      .subscribe({
        next: result => {
          if (result.connectionType === ConnectionType.WithAddress) {
            this.usesAddress = true;
            this.usesCompany = false;
          }
          if (result.connectionType === ConnectionType.WithCompany) {
            this.usesAddress = false;
            this.usesCompany = true;
          }

          this.setExistingCompany(result.companyName, result.networkName);
        }
      });
  }

  public searchCompany(companySearchString: string): void {
    this.companySearch$.next(companySearchString);
  }

  public useNetwork(companyName: string, networkName: string, networkId: string, facilityId: number): void {
    const newSetup = new FacilityLocalPowerConnection({
      facilityId: facilityId,
      connectionType: ConnectionType.WithCompany,
      networkId: networkId,
      companyName: companyName,
      networkName: networkName
    });
    this.upsert(newSetup);
  }

  public onUseAddress(useAddress: boolean, facilityId: number): void {
    if (useAddress && this.usesCompany) {
      this.usesCompany = false;
    }

    if (!useAddress) {
      this.remove(facilityId);
      return;
    }

    const newSetup = new FacilityLocalPowerConnection({
      facilityId: facilityId,
      connectionType: ConnectionType.WithAddress,
    });
    this.upsert(newSetup);
  }

  public onUseCompany(useCompany: boolean, facilityId: number): void {
    if (useCompany && this.usesAddress) {
      this.usesAddress = false;
    }

    this.remove(facilityId);
  }

  private remove(facilityId: number): void {
    this.sustainabilityClient.removeLocalPowerFacilityConnection(facilityId)
      .subscribe({
        next: () => { this.toasterService.warning('FACILITY.EDIT_FORM.LP_CONN_REMOVED'); },
        error: () => { this.toasterService.generalError('UPDATE', 'FACILITY'); },
      });
    this.setExistingCompany();
  }

  private upsert(connection: FacilityLocalPowerConnection): void {
    this.sustainabilityClient
      .upsertLocalPowerFacilityConnection(connection)
      .subscribe({
        next: () => { this.toasterService.success('FACILITY.EDIT_FORM.LP_CONN_EDITED'); },
        error: () => { this.toasterService.generalError('UPDATE', 'FACILITY'); }
      });
    this.setExistingCompany(connection.companyName, connection.networkName);
  }

  private setExistingCompany(companyName: string = null, networkName: string = null): void {
    if (companyName && networkName) {
      this.existingCompanyNetwork = `(${companyName} - ${networkName})`;
    } else {
      this.existingCompanyNetwork = '';
    }
  }
}
