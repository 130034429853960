import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';

import { SocialMediaLink, SocialMediaService } from '../services/social-media.service';

@Component({
  selector: 'social-media-buttons',
  templateUrl: './social-media-buttons.component.html',
  styleUrls: ['./social-media-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialMediaButtonsComponent {
  public readonly links: Observable<SocialMediaLink[]>;

  public constructor(socialMediaService: SocialMediaService) {
    this.links = socialMediaService.getLinks();
  }
}
