/* eslint-disable @typescript-eslint/no-explicit-any */
import { MeterUpdateWithProperties } from '@enerkey/clients/meter-management';
import { CostFactorDto, CostFactorType } from '@enerkey/clients/cost-factor';
import { getStringEnumValues } from '@enerkey/ts-utils';

import { Utils } from '../../../services/utils';

interface MeterSpreadsheetFieldBase {
  text: string;
  width?: number;
  required?: boolean;
}

export interface MeterSpreadsheetField extends MeterSpreadsheetFieldBase {
  value: keyof MeterUpdateWithProperties | 'enegiaId' | 'tags'|'status';
}

export interface MeterCostSpreadsheetField extends MeterSpreadsheetFieldBase {
  value: keyof CostFactorDto | 'status';
}

export enum CostFieldName {
  MeterId = 'meterId',
  FromDate = 'fromDate',
  MonthlyFeePerDay = 'monthlyFeePerDay',
  Price = 'price'
}

const inject = ['utils'];

export class MeterSpreadsheetFieldsService {
  public readonly CostFieldNames = getStringEnumValues(CostFieldName);

  private columnDefaultWidth = 165;

  public constructor(private readonly utils: Utils) {}

  // Overloaded method for maintaining compatibility and offering different return types.
  public getFields(): MeterSpreadsheetField[];
  public getFields(fieldType: CostFactorType): MeterCostSpreadsheetField[];
  public getFields(fieldType?: any): any {
    if (Object.values(CostFactorType).includes(fieldType)) {
      return this.getCostFields(fieldType);
    }
    return this.getDefaultFields();
  }
  /* eslint-enable */

  public getCostFields(fieldType: CostFactorType): MeterCostSpreadsheetField[] {
    return [
      {
        value: CostFieldName.MeterId,
        text: this.utils.localizedString('ADMIN.SPREADSHEET.METER_ID.HEADING'),
        width: 120,
        required: true
      },
      {
        value: CostFieldName.FromDate,
        text: this.utils.localizedString('ADMIN.SPREADSHEET.VALID_FROM.HEADING'),
        width: 120,
        required: true
      },
      {
        value: CostFieldName.MonthlyFeePerDay,
        text: this.utils.localizedString(`ADMIN.SPREADSHEET.${CostFactorType[fieldType]}_DAILY_PRICE.HEADING`),
        width: 160,
        required: false
      },
      {
        value: CostFieldName.Price,
        text: this.utils.localizedString(`ADMIN.SPREADSHEET.${CostFactorType[fieldType]}_UNIT_PRICE.HEADING`),
        width: 160,
        required: false
      },
      {
        value: 'status',
        text: this.utils.localizedString('ADMIN.SPREADSHEET.STATUS.HEADING'),
        width: this.columnDefaultWidth
      }
    ];
  }

  public getDefaultFields(): MeterSpreadsheetField[] {
    return [{
      value: 'id',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.METER_ID.HEADING'),
      width: 120,
      required: true
    }, {
      value: 'enegiaId',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.ENEGIA_ID.HEADING'),
      width: 120,
      required: true
    }, {
      value: 'name',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.METER_NAME.HEADING'),
      width: this.columnDefaultWidth,
      required: true
    }, {
      value: 'meteringType',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.METER_TYPE_NAME.HEADING'),
      width: this.columnDefaultWidth,
      required: true
    }, {
      value: 'quantityId',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.QUANTITY_ID.HEADING'),
      width: this.columnDefaultWidth,
      required: true
    }, {
      value: 'factor',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.FACTOR.HEADING'),
      width: this.columnDefaultWidth,
      required: true
    }, {
      value: 'usagePlaceNumber',
      text: this.utils.localizedString('USAGE_PLACE_NUMBER'),
      width: this.columnDefaultWidth
    }, {
      value: 'eanCode',
      text: this.utils.localizedString('EAN_CODE'),
      width: this.columnDefaultWidth
    }, {
      value: 'protocolCode',
      text: this.utils.localizedString('PROTOCOL_CODE'),
      width: 200
    }, {
      value: 'customerMeterIdentifier',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.CUSTOMER_METER_IDENTIFIER.HEADING'),
      width: this.columnDefaultWidth
    }, {
      value: 'automaticReadingStartTime',
      text: this.utils.localizedString('AUTOMATIC_READING_START_TIME'),
      width: this.columnDefaultWidth
    }, {
      value: 'automaticReadingEndTime',
      text: this.utils.localizedString('AUTOMATIC_READING_END_TIME'),
      width: this.columnDefaultWidth
    }, {
      value: 'deactivationTime',
      text: this.utils.localizedString('DEACTIVATION_TIME'),
      width: this.columnDefaultWidth
    }, {
      value: 'qualityAssurance',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.QUALITY_ASSURANCE.HEADING'),
      width: this.columnDefaultWidth
    }, {
      value: 'automaticModeling',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.AUTOMATIC_MODELING.HEADING'),
      width: this.columnDefaultWidth
    }, {
      value: 'description',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.METER_DESCRIPTION.HEADING'),
      width: this.columnDefaultWidth
    }, {
      value: 'twoTimeMeasurement',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.TWO_TIME_MEASUREMENT.HEADING'),
      width: this.columnDefaultWidth,
      required: false
    }, {
      value: 'costMeter',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.COST_METER.HEADING'),
      width: this.columnDefaultWidth
    }, {
      value: 'costFactorSourceMeterId',
      text: this.utils.localizedString('ADMIN.METER.COST_FACTOR_SOURCE'),
      width: this.columnDefaultWidth
    }, {
      value: 'linkMeterFixedCosts',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.LINK_METER_FIXED_COSTS.HEADING'),
      width: this.columnDefaultWidth
    }, {
      value: 'energyCompanyUsagePlaceNumber',
      text: this.utils.localizedString('ADMIN.METER.ENERGY_COMPANY_USAGE_PLACE_NUMBER'),
      width: this.columnDefaultWidth
    }, {
      value: 'resolution',
      text: this.utils.localizedString('ADMIN.METER.RESOLUTION'),
      width: this.columnDefaultWidth
    }, {
      value: 'tags',
      text: this.utils.localizedString('TAGS.TITLE'),
      width: 400
    },
    {
      value: 'status',
      text: this.utils.localizedString('ADMIN.SPREADSHEET.STATUS.HEADING'),
      width: this.columnDefaultWidth
    }];
  }
}

MeterSpreadsheetFieldsService.$inject = inject;

export default MeterSpreadsheetFieldsService;
