<div>
  <quantity-dropdown
    *ngIf="(quantities$ | async) as quantities"
    [(ngModel)]="quantityId"
    (ngModelChange)="quantityChange()"
    [quantities]="quantities"
    [grouped]="true"
  ></quantity-dropdown>
  <div class="widget-unit-info">
    <ng-container *ngIf="isRelationalValue; else quantityUnitInfo">
      {{ (dataModelOptions.variableId | relationalValueName) | async }}:
      {{ (dataModelOptions.variableId | relationalValueUnit : (quantity$ | async) : dataModelOptions.unitKey) | async }}
    </ng-container>
    <ng-template #quantityUnitInfo>
      {{ 'FACILITIES_REPORT.OVERVIEW.CONSUMPTION' | translate }}:
      {{ quantity$ | quantityUnit : dataModelOptions.unitKey | async }}
    </ng-template>
  </div>
</div>
<ng-container *ngIf="!(noData$ | async); else noData">
  <grouped-consumptions-table
    *ngIf="measured$ | async as measuredData"
    [data]="measuredData"
    [comparisonPeriodTitle]="comparisonPeriodTitle"
    [inspectionPeriodTitle]="inspectionPeriodTitle"
    [propertyTranslationKey]="propertyTranslationKey"
    [format]="(quantityId | groupedConsumptionWidgetNumberFormat : isRelationalValue : dataModelOptions.unitKey) | async"
    [changeType]="dataModelOptions.changeOption"
    (rowClick)="onRowClick($event)"
  ></grouped-consumptions-table>
  <ng-container *ngIf="normalized$ | async as normalizedData">
    <h3>{{ 'FACILITIES.NORMALIZED' | translate }}:</h3>
    <grouped-consumptions-table
      [data]="normalizedData"
      [comparisonPeriodTitle]="comparisonPeriodTitle"
      [inspectionPeriodTitle]="inspectionPeriodTitle"
      [propertyTranslationKey]="propertyTranslationKey"
      [format]="(quantityId | groupedConsumptionWidgetNumberFormat :isRelationalValue : dataModelOptions.unitKey) | async"
      [changeType]="dataModelOptions.changeOption"
      (rowClick)="onRowClick($event)"
    ></grouped-consumptions-table>
  </ng-container>
</ng-container>
<ng-template #noData>
  <span>{{ 'WIDGET.NO_DATA' | translate }}</span>
</ng-template>
