import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

import { SearchList } from '@enerkey/clients/manual-qa';

@Directive({
  selector: 'manual-qa-search-lists'
})
export class ManualQaSearchListsDirective extends UpgradeComponent {
  @Input() public searchLists: SearchList[];

  public constructor(elementRef: ElementRef, injector: Injector) {
    super('manualQaSearchList', elementRef, injector);
  }
}
