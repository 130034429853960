import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

import { Quantities } from '@enerkey/clients/metering';
import { ConsumptionItem, QuantityItem } from '@enerkey/clients/energy-reporting';
import { ReportingUnit } from '@enerkey/clients/reporting';

import { QuantityService } from '../../../shared/services/quantity.service';
import ErTimeFrameService, { TimeFrameResult } from '../../energy-reporting/services/er-time-frame.service';
import { TimeFrameOptions } from '../../../constants/time-frame';
import { Comparability } from '../../../shared/ek-inputs/comparability-select/comparability-select.component';
import { WidgetChangeOption } from '../../energy-reporting/shared/widget-constants';
import { ValueType } from '../../../shared/ek-inputs/value-type-select/value-type-select.component';
import { FacilityService } from '../../../shared/services/facility.service';
import { ThresholdService } from '../../../shared/services/threshold.service';

export interface ChangeWidgetOptions {
  biggestFallers: number;
  biggestGainers: number;
  changeOption: WidgetChangeOption;
  comparableOption: Comparability;
  comparisonPeriodOption: 'Default' | number;
  selectedQuantity: QuantityItem;
  timeFrameOption: TimeFrameOptions;
  unitKey: ReportingUnit;
  valueOption: ValueType;
  variableId: number;
  quantityId?: Quantities;
}

export type ChangeWidgetData<T> = {
  fallers: T[];
  gainers: T[];
}

export type ChangeWidgetResponse<T> = {
  measured: ChangeWidgetData<T>;
  normalized: ChangeWidgetData<T>;
}

export type ChangeWidgetValueKey = keyof Pick<ConsumptionItem, 'Value' | 'NormalisationValue'>;

@Directive()
export abstract class ChangeWidgetComponentBase<Options extends ChangeWidgetOptions, WidgetRow>
  implements OnInit, OnDestroy {
  protected abstract getData(
    quantity: QuantityItem,
    facilityIds: number[],
    threshold: number
  ): Observable<ChangeWidgetResponse<WidgetRow>>

  @Input() public dataModelOptions: Options;

  public readonly measured$: Observable<ChangeWidgetData<WidgetRow>>;
  public readonly normalized$: Observable<ChangeWidgetData<WidgetRow>>;
  public readonly quantities$: Observable<Quantities[]>;

  public readonly dataModelChange$: Observable<Options>;

  public readonly quantity$: Observable<Quantities>;
  public readonly noData$: Observable<boolean>;

  public propertyTranslationKey: string;
  public comparisonPeriodTitle: string;
  public inspectionPeriodTitle: string;

  protected start: TimeFrameResult;
  protected readonly data$: Observable<ChangeWidgetResponse<WidgetRow>>;

  protected readonly _destroy$ = new Subject<void>();
  private readonly _dataModelChange$ = new Subject<Options>();
  private readonly _quantity$ = new ReplaySubject<Quantities>(1);

  public constructor(
    private readonly quantityService: QuantityService,
    private readonly erTimeFrameService: ErTimeFrameService,
    facilityService: FacilityService,
    thresholdService: ThresholdService
  ) {
    this.quantities$ = from(this.quantityService.getSignificantQuantitiesForProfile()).pipe(
      map(quantityItems => quantityItems.map(q => q.ID)),
      shareReplay(1)
    );

    this.dataModelChange$ = this._dataModelChange$.asObservable();
    this.quantity$ = this._quantity$.asObservable();

    this.data$ = combineLatest([
      this._quantity$.pipe(
        switchMap(quantityId => this.quantityService.getQuantityById(quantityId))
      ),
      facilityService.filteredProfileFacilityIds$,
      thresholdService.threshold$
    ]).pipe(
      switchMap(([quantity, facilityIds, threshold]) => this.getData(quantity, facilityIds, threshold)),
      shareReplay(1),
      takeUntil(this._destroy$)
    );

    this.measured$ = this.data$.pipe(
      map(data => data.measured)
    );

    this.normalized$ = this.data$.pipe(
      map(data => data.normalized)
    );

    this.noData$ = this.data$.pipe(
      map(
        data => [data.measured, data.normalized].every(
          values => !values || (!Array.hasItems(values.fallers) && !Array.hasItems(values.gainers))
        )
      )
    );
  }

  public ngOnInit(): void {
    this.start = this.erTimeFrameService.getTimeFrameAndResParams(
      this.dataModelOptions.timeFrameOption,
      this.dataModelOptions.comparisonPeriodOption as string
    );

    this.comparisonPeriodTitle = this.start.Start[1].key;
    this.inspectionPeriodTitle = this.start.Start[0].key;

    this._quantity$.next(this.quantityId);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._quantity$.complete();
    this._dataModelChange$.complete();
  }

  public get quantityId(): Quantities {
    return this.dataModelOptions.quantityId ?? this.dataModelOptions.selectedQuantity.ID;
  }

  public set quantityId(id: Quantities) {
    this.dataModelOptions.quantityId = id;
  }

  public get isRelationalValue(): boolean {
    return !!this.dataModelOptions.variableId;
  }

  public quantityChange(): void {
    this._quantity$.next(this.quantityId);
    this.quantityService.getQuantityById(this.quantityId).pipe(take(1)).subscribe(quantity => {
      this._dataModelChange$.next({
        ...this.dataModelOptions,
        // Add selectedQuantity property to keep widget compatible with old dashboard. Can be removed later.
        selectedQuantity: quantity
      });
    });
  }
}
