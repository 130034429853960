import { ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ControlValueAccessor } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

import { StringKeys } from '@enerkey/ts-utils';
import { indicate, LoadingSubject } from '@enerkey/rxjs';

/**
 * Value of the search component's form control.
*/
type Value<T, K extends keyof T, B extends boolean> = B extends true ? T[K] : T;

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class SearchComponent<T, K extends keyof T, B extends boolean> implements
  ControlValueAccessor, OnDestroy {

  @Input() public readonly abstract valueField: K;

  /** Which property of object is given to form. If not defined, whole object is given */
  public readonly abstract textField: StringKeys<T>;

  protected abstract fetchItems(): Observable<T[]>;

  @ViewChild(ComboBoxComponent) public comboBox: ComboBoxComponent;

  /** Disabled-state of the `kendo.combobox` */
  @Input() public disabled: boolean = false;

  @Input() public placeHolder: string = '';

  /** Whether the control's value is an identifier property or the whole object. */
  @Input() public valuePrimitive: B = false as B;

  /** Remove matching items from fetched items */
  @Input() public excludedItems: T[K][] = [];

  @Output() public readonly itemSelect: EventEmitter<Value<T, K, B>> = new EventEmitter();

  public readonly loading$: Observable<boolean>;
  public readonly disabled$: Observable<boolean>;

  public sourceData: T[] = [];
  public items: T[] = [];
  public value: Value<T, K, B>;
  protected filterStringPrefix: string = '';

  private readonly minimumFilterLength: number = 3;
  private filterString: string = '';

  private onChange: (param: Value<T, K, B>) => void = undefined;
  private onTouched: () => void = undefined;

  private readonly _loading = new LoadingSubject(false);
  private readonly _destroy = new Subject<void>();
  private readonly _disabled$ = new Subject<boolean>();

  public constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
    this.loading$ = this._loading.asObservable();
    this.disabled$ = this._disabled$.asObservable();
  }

  public ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._loading.complete();
    this._disabled$.complete();
  }

  public filterChanged(filter: string): void {
    this.filterString = filter;
    if (this.shouldFetchItems()) {
      // Get only prefix, needed when user pastes a value into field
      this.filterStringPrefix = this.getFilterStringPrefix();

      this.fetchItems()
        .pipe(
          map(
            items => Array.hasItems(this.excludedItems)
              ? items.filter(item => !this.excludedItems.includes(item[this.valueField]))
              : items
          ),
          indicate(this._loading),
          takeUntil(this._destroy)
        )
        .subscribe(items => this.setItemsList(items));

    } else if (this.filterString.length >= this.minimumFilterLength) {
      this.filterFetchedItems();
    } else {
      this.filterStringPrefix = '';
    }
  }

  public valueChanged(item: Value<T, K, B>): void {
    this.value = item;
    this.onChange?.(item);
    this.itemSelect.emit(item);
  }

  public blur(): void {
    this.onTouched?.();
  }

  public writeValue(value: Value<T, K, B>): void {
    this.value = value;
  }

  public registerOnChange(fn: (param: Value<T, K, B>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled$.next(isDisabled);
  }

  /**
   * Clear visible combobox value without changing FormControl value
   */
  public reset(): void {
    this.comboBox.reset();
  }

  private setItemsList(items: T[]): void {
    this.sourceData = items;
    if (this.filterString === this.filterStringPrefix) {
      this.items = items;
    } else {
      this.filterFetchedItems();
    }
  }

  private shouldFetchItems(): boolean {
    return this.filterString.length >= this.minimumFilterLength &&
      (this.filterStringPrefix.length === 0 || !this.isFilterStringPrefixSameAsPrevious());
  }

  private isFilterStringPrefixSameAsPrevious(): boolean {
    return this.filterString.toLowerCase().startsWith(this.filterStringPrefix);
  }

  private getFilterStringPrefix(): string {
    return this.filterString.substring(0, this.minimumFilterLength).toLowerCase();
  }

  private filterFetchedItems(): void {
    this.items = this.sourceData.filter(
      item => (item[this.textField] as unknown as string)
        .toLowerCase()
        .indexOf(this.filterString.toLowerCase()) > -1
    );
    this.changeDetectorRef.detectChanges();
  }
}
