import forbiddenTemplate from 'raw-loader!./templates/forbidden.html';
import errorTemplate from 'raw-loader!./templates/error.html';

import { defaultTranslationLocale } from './shared/services/language-change.service';

config.$inject = [
  '$provide', '$urlRouterProvider', '$stateProvider', '$locationProvider', '$translateProvider',
  '$translatePartialLoaderProvider', '$httpProvider', '$compileProvider',
  '$translateSanitizationProvider', 'tmhDynamicLocaleProvider'
];

function config(
  $provide, $urlRouterProvider, $stateProvider, $locationProvider, $translateProvider,
  $translatePartialLoaderProvider, $httpProvider, $compileProvider,
  $translateSanitizationProvider, tmhDynamicLocaleProvider
) {
  $urlRouterProvider
    .when('', '/')
    .otherwise('/');

  const appStates = {
    index: {
      url: '',
      sticky: true,
      dsr: true,
    },
    forbidden: {
      parent: 'index',
      url: '/forbidden/',
      views: {
        'app@': {
          template: forbiddenTemplate
        }
      }
    },
    error: {
      parent: 'index',
      url: '/error/',
      params: {
        errorReasonKey: null,
        errorMessage: null,
        showTryAgainButton: true
      },
      views: {
        'app@': {
          template: errorTemplate,
          controllerAs: 'ErrorCtrl',
          controller: ['$stateParams', 'utils', function($stateParams, utils) {
            this.errorReasonKey = $stateParams.errorReasonKey;
            this.showTryAgainButton = $stateParams.showTryAgainButton;

            utils.trackError('Error state shown.', {
              status: $stateParams.errorReasonKey,
              message: $stateParams.errorMessage
            });
          }]
        }
      }
    },
  };

  // create appStates value and add states to stateProvider
  Object.keys(appStates).forEach(stateName => {
    $stateProvider.state(stateName, appStates[stateName]);
  });

  // routing to HTML5 mode
  $locationProvider.html5Mode(true);
  $locationProvider.hashPrefix('');

  // Turn debug off to gain speed
  // https://docs.angularjs.org/guide/production#disabling-debug-data
  $compileProvider.debugInfoEnabled(false);

  // translations
  $translatePartialLoaderProvider.addPart('.');
  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: 'assets/languages/{part}/{lang}.json'
  });
  $translateProvider.preferredLanguage(defaultTranslationLocale);
  $translateProvider.storageKey('lang');
  $translateProvider.useLocalStorage();
  $translateSanitizationProvider.useStrategy('escapeParameters');

  $translatePartialLoaderProvider.addPart('analytics');
  $translatePartialLoaderProvider.addPart('dashboard');
  $translatePartialLoaderProvider.addPart('supervision');
  $translatePartialLoaderProvider.addPart('webhook');
  $translatePartialLoaderProvider.addPart('kendo');

  tmhDynamicLocaleProvider.localeLocationPattern('/assets/languages/angular-locale_{{locale}}.js');

  // http interceptor to check auth failures
  $httpProvider.interceptors.push('authHttpResponseInterceptor');
}

export default config;
