<modal-view-header
  [headingText]="(selectedUser ? 'ADMIN.MODIFY_USERS.MODIFY_USERS_BUTTON' : 'ADMIN.CREATE_NEW_USER') | translate"
></modal-view-header>
<div class="modal-body">
  <form
    [formGroup]="userForm"
    (ngSubmit)="submit(true)"
    id="userForm"
  >
    <div class="row">
      <div class="large-6 columns">
        <div class="row" *ngIf="!selectedUser">
          <div class="small-12 columns">
            <input
              formControlName="isMachine"
              type="checkbox"
              kendoCheckBox
              *labelWrap="'ADMIN.ISMACHINE' | translate"
            >
          </div>
        </div>
        <div class="row">
          <div class="small-12 columns">
            <input
              formControlName="userName"
              type="text"
              [email]="!!(!userForm.controls.isMachine.value && !selectedUser)"
              *labelBefore="'ADMIN.USERNAME' | translate"
            >
          </div>
        </div>
        <div class="row" *ngIf="!userForm.controls.isMachine.value || selectedUser">
          <div class="small-6 columns">
            <input
              formControlName="firstName"
              type="text"
              *labelBefore="'ADMIN.FIRSTNAME' | translate"
            >
          </div>
          <div class="small-6 columns">
            <input
              formControlName="lastName"
              type="text"
              *labelBefore="'ADMIN.LASTNAME' | translate"
            >
          </div>
        </div>
        <div class="row">
          <div
            class="small-6 columns"
            *ngIf="!userForm.controls.isMachine.value || selectedUser else passwordField"
          >
            <language-select
              formControlName="preferredLanguage"
              *labelBefore="'ADMIN.LANGUAGE' | translate"
            >
            </language-select>
          </div>
          <ng-template #passwordField>
            <div class="small-6 columns">
              <input
                formControlName="password"
                type="text"
                *labelBefore="'ADMIN.PASSWORD' | translate"
              >
            </div>
          </ng-template>
          <div class="small-6 columns">
            <input
              formControlName="thirdPartySystemName"
              type="text"
              *labelBefore="'ADMIN.BULK_CREATE_THIRD_PARTY_SYSTEM_FIELD' | translate"
            >
          </div>
        </div>
        <div class="row">
          <div class="small-6 columns">
            <kendo-datepicker
              class="full-width margin-bottom"
              formControlName="accountEndDate"
              *labelBefore="'ADMIN.ACCOUNT_END_DATE' | translate"
            ></kendo-datepicker>
          </div>
          <div class="small-6 columns">
            <company-dropdown
              class="margin-bottom"
              formControlName="companyId"
              [noLabel]="true"
              *labelBefore="'DOCUMENTS.ORGANIZATION_NAME' | translate"
            ></company-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="small-12 columns">
            <textarea
              formControlName="comment"
              type="text"
              kendoTextArea
              rows="5"
              *labelBefore="'ADMIN.COMMENT' | translate"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="small-12 columns" *ngIf="selectedUser && !userForm.controls.password.value">
            <button
              class="button"
              type="button"
              *labelBefore="'ADMIN.PASSWORD' | translate"
              (click)="createNewPassword()"
            >
              {{ 'ADMIN.RENEWPASSWORD' | translate }}
            </button>
          </div>
          <div class="small-12 columns" *ngIf="selectedUser && userForm.controls.password.value">
            <input
              formControlName="password"
              type="text"
              *labelBefore="'ADMIN.PASSWORD' | translate"
            >
          </div>
        </div>
      </div>
      <div class="large-6 columns">
        <div class="row">
          <div class="small-12 columns">
            <role-multi-select
              class="margin-bottom"
              formControlName="roleIds"
              *labelBefore="'ADMIN.ROLES' | translate"
            ></role-multi-select>
          </div>
        </div>
        <div class="row">
          <div class="small-12 columns">
            <ng-container *ngIf="hasAccessToAllProfiles$ | async else profileSelection">
              <span>{{ 'ADMIN.HAS_ACCESS_TO_ALL_PROFILES_VIA_ROLE' | translate }}</span>
            </ng-container>
            <ng-template #profileSelection>
              <profile-search-select
                formControlName="profileIds"
                *labelBefore="'ADMIN.PROFILES' | translate"
              ></profile-search-select>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="modal-footer-buttons float-right">
    <button
      class="button"
      type="submit"
      form="userForm"
      [loading]="loading$ | async"
      [disabled]="!userForm.valid"
    >
      {{ 'ADMIN.SAVE' | translate }}
    </button>
    <button
      class="button"
      (click)="submit(false)"
      [loading]="loading$ | async"
      [disabled]="!userForm.valid"
    >
      {{ 'ADMIN.SAVE_AND_CLONE' | translate }}
    </button>
    <button
      class="button button--secondary"
      type="button"
      (click)="dismiss()"
    >
      {{ 'CANCEL' | translate }}
    </button>
    <button
      *ngIf="selectedUser && !isProduction"
      class="button button--negative"
      (click)="deleteUser()"
    >
      {{ 'DELETE' | translate }}
    </button>
  </div>
</div>
