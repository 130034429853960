import { TooltipAlignment, TooltipPlacement } from '../tooltip/tooltip-config';

export class Positioning {
  public position(element: HTMLElement, round = true): DOMRect {
    let elPosition: DOMRect;
    let parentOffset = new DOMRect(0, 0, 0, 0);

    if (this.getStyle(element, 'position') === 'fixed') {
      elPosition = element.getBoundingClientRect();
      elPosition = new DOMRect(elPosition.left, elPosition.top, elPosition.width, elPosition.height);
    } else {
      const offsetParentEl = this.offsetParent(element);

      elPosition = this.offset(element, false);

      if (offsetParentEl !== document.documentElement) {
        parentOffset = this.offset(offsetParentEl, false);
      }

      parentOffset.y += (offsetParentEl.clientTop - offsetParentEl.scrollTop);
      parentOffset.x += (offsetParentEl.clientLeft - offsetParentEl.scrollLeft);
    }

    elPosition.y -= parentOffset.top;
    elPosition.x -= parentOffset.left;

    if (round) {
      elPosition.y = Math.round(elPosition.top);
      elPosition.x = Math.round(elPosition.left);
    }

    return elPosition;
  }

  public offset(element: HTMLElement, round = true): DOMRect {
    const elBcr = element.getBoundingClientRect();
    const viewportOffset = {
      top: window.pageYOffset - document.documentElement.clientTop,
      left: window.pageXOffset - document.documentElement.clientLeft
    };

    const elOffset = new DOMRect(
      elBcr.left + viewportOffset.left,
      elBcr.top + viewportOffset.top,
      elBcr.width || element.offsetWidth,
      elBcr.height || element.offsetHeight,
    );

    if (round) {
      elOffset.height = Math.round(elOffset.height);
      elOffset.width = Math.round(elOffset.width);
      elOffset.y = Math.round(elOffset.y);
      elOffset.x = Math.round(elOffset.x);
    }

    return elOffset;
  }

  /*
    Return false if the element to position is outside the viewport
  */
  public positionElements(
    hostElement: HTMLElement,
    targetElement: HTMLElement,
    placement: TooltipPlacement,
    alignment: TooltipAlignment,
    appendToBody?: boolean,
    offset = 11
  ): boolean {

    const hostElPosition = appendToBody ? this.offset(hostElement, false) : this.position(hostElement, false);

    const targetElStyles = this.getAllStyles(targetElement);
    const marginTop = parseFloat(targetElStyles.marginTop);
    const marginBottom = parseFloat(targetElStyles.marginBottom);
    const marginLeft = parseFloat(targetElStyles.marginLeft);
    const marginRight = parseFloat(targetElStyles.marginRight);

    // const tipOffsetPrimary = Math.ceil(this.convertRemToPixels(0.75 * 0.886));

    let topVal;
    let leftVal;
    switch (placement) {
      case 'top':
        topVal = hostElPosition.top - (targetElement.offsetHeight + marginTop + marginBottom + offset);
        break;
      case 'bottom':
        topVal = hostElPosition.top + hostElPosition.height + offset;
        break;
      case 'left':
        leftVal = hostElPosition.left - (targetElement.offsetWidth + marginLeft + marginRight + offset);
        break;
      case 'right':
        leftVal = hostElPosition.left + hostElPosition.width + offset;
        break;
    }

    // set alignment related attribute
    switch (placement) {
      case 'top':
      case 'bottom':
        switch (alignment) {
          case 'left':
            leftVal = hostElPosition.left;
            break;
          case 'right':
            leftVal = hostElPosition.left - targetElement.offsetWidth + hostElPosition.width;
            break;
          case 'center':
            leftVal = (hostElPosition.left + (hostElPosition.width / 2)) - (targetElement.offsetWidth / 2);
            break;
        }
        break;
      case 'right':
      case 'left':
        switch (alignment) {
          case 'bottom':
            topVal = hostElPosition.top + hostElPosition.height - targetElement.offsetHeight;
            break;
          case 'top':
            topVal = hostElPosition.top;
            break;
          case 'center':
            topVal = (hostElPosition.top + (hostElPosition.height / 2)) - (targetElement.offsetHeight / 2);
            break;
        }
        break;
    }

    // / The translate3d/gpu acceleration render a blurry text on chrome, the next line is commented until a browser fix
    // targetElement.style.transform = `translate3d(${Math.round(leftPosition)}px, ${Math.floor(topPosition)}px, 0px)`;
    targetElement.style.transform = `translate(${Math.round(leftVal)}px, ${Math.round(topVal)}px)`;

    // Check if the targetElement is inside the viewport
    const targetElBCR = targetElement.getBoundingClientRect();
    const html = document.documentElement;
    const windowHeight = window.innerHeight || html.clientHeight;
    const windowWidth = window.innerWidth || html.clientWidth;

    return targetElBCR.left >= 0 && targetElBCR.top >= 0 && targetElBCR.right <= windowWidth &&
        targetElBCR.bottom <= windowHeight;
  }

  private getAllStyles(element: HTMLElement): CSSStyleDeclaration {
    return window.getComputedStyle(element);
  }

  private getStyle(element: HTMLElement, prop: keyof CSSStyleDeclaration): string {
    return `${this.getAllStyles(element)[prop]}`;
  }

  private isStaticPositioned(element: HTMLElement): boolean {
    return (this.getStyle(element, 'position') || 'static') === 'static';
  }

  private offsetParent(element: HTMLElement): HTMLElement {
    let offsetParentEl = element.offsetParent as HTMLElement || document.documentElement;

    while (offsetParentEl && offsetParentEl !== document.documentElement && this.isStaticPositioned(offsetParentEl)) {
      offsetParentEl = offsetParentEl.offsetParent as HTMLElement;
    }

    return offsetParentEl || document.documentElement;
  }

}

const positionService = new Positioning();

export function positionElements(
  hostElement: HTMLElement, targetElement: HTMLElement, placement: TooltipPlacement,
  alignment: TooltipAlignment, appendToBody?: boolean, offset = 0
): TooltipPlacement {
  // Required for transform:
  const style = targetElement.style;
  style.position = 'absolute';
  style.top = '0';
  style.left = '0';
  (style as unknown as any)['will-change'] = 'transform';

  positionService.positionElements(hostElement, targetElement, placement, alignment, appendToBody, offset);

  return placement;
}
