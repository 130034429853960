import { Injectable } from '@angular/core';
import { combineLatest, map } from 'rxjs';

import { QuantityOrMeterReport, ReportModalService } from './report-modal.service';
import { ReportTypeOptionsService } from './report-type-options.service';

@Injectable()
export class ReportTypeOptionsModalService extends ReportTypeOptionsService {
  public constructor(
    reportModalService: ReportModalService
  ) {
    const reportType$ = combineLatest({
      qOrMReport: reportModalService.quantityOrMeterReport$,
      reportType: reportModalService.reportType$,
      meterReportType: reportModalService.meterReportType$,
    }).pipe(
      map(({ qOrMReport, reportType, meterReportType }) => qOrMReport === QuantityOrMeterReport.Meter
        ? meterReportType
        : reportType)
    );
    super(reportType$);
  }
}
