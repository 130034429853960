import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ModalBase, ModalOptions } from '@enerkey/foundation-angular';
import { MeterManagementMeter } from '@enerkey/clients/meter-management';
import { MeterUpdateProperties } from '../../../virtual-meters/shared/meter-update-properties';
import { WINDOW } from '@enerkey/angular-utils';
import { ModalConfirmDismiss, NgfActiveModal } from '@enerkey/foundation-angular';

@Component({
  selector: 'meter-edit-modal',
  templateUrl: './meter-edit-modal.component.html',
  styleUrls: ['./meter-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@ModalOptions({
  windowClass: 'modal-dialog-scrollable fixed-height',
  size: 'large'
})
export class MeterEditModalComponent extends ModalBase<void> implements ModalConfirmDismiss {
  public meter: MeterManagementMeter;
  public meterUpdateProperties: MeterUpdateProperties;

  public constructor(
    @Inject(WINDOW) private readonly windowObject: Window,
    private readonly translateService: TranslateService,
    ngfActiveModal: NgfActiveModal
  ) {
    super(ngfActiveModal);
  }

  public confirmDismiss(): boolean {
    return this.meterUpdateProperties.hasChanges
      ? this.windowObject.confirm(this.translateService.instant('UNSAVED_CHANGES_CONFIRMATION'))
      : true
    ;
  }
}
