import { IMeter, Meter } from '@enerkey/clients/metering';

interface ITargetMeter {
  nullToZero: boolean;
  facilityName: string;
  enegiaId: number;
  formulaFactor: number;
}

export class TargetMeter extends Meter implements ITargetMeter {
  public nullToZero: boolean;
  public facilityName: string;
  public enegiaId: number;
  public formulaFactor: number;

  public constructor(meter: IMeter, targetMeterProperties?: ITargetMeter) {
    super(meter);

    if (targetMeterProperties) {
      this.nullToZero = targetMeterProperties.nullToZero;
      this.facilityName = targetMeterProperties.facilityName;
      this.enegiaId = targetMeterProperties.enegiaId;
      this.formulaFactor = targetMeterProperties.formulaFactor;
    }
  }
}
