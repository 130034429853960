import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SelectableResolution } from '../../../../modules/reporting/components/reporting-search-form/reporting-search-form.component';

import { ConsumptionPoint } from '../chart-tooltip/chart-tooltip.component';

@Component({
  selector: 'tr[chart-tooltip-row]',
  templateUrl: './chart-tooltip-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartTooltipRowComponent {
  @Input() public resolution: SelectableResolution;
  @Input() public point: ConsumptionPoint;
}
